import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiDelete, ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import {Row, Col} from 'react-bootstrap'

let deleteVendor: any = []

function List() {
    const navigate = useNavigate()
    const [alphabetValue, setAlphabetValue] = useState<any>('')
    const [vendorList, setVendorList] = useState<any>()
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));
    const [pageLimit, setPageLimit] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const Vendors = new Array(5).fill(0);
    const [deleteVendorArray, setDeleteVendorArray] = useState<any>([])
    const [checkedState, setCheckedState] = useState<any>(new Array(vendorList?.length).fill(false))
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [vendorListCount, setVendorListCount] = useState<any>('')
    let length: any = deleteVendorArray?.length;


    const handelFilter = (e: any) => {
        setAlphabetValue(e);
    }
    const getVendorList = () => {
        const body = {
            page: page,
            limit: 10,
            startingWitAlphabet: alphabetValue
        }
        ApiPost('corporate/vendor_list/get', body)
            .then((res) => {
                setVendorList(res?.data?.data?.vendor_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
                setVendorListCount(res?.data?.data?.state?.data_count)
                setCheckedState(new Array(res?.data?.data?.vendor_data?.length).fill(false))
                setDeleteVendorArray([])
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getVendorList()
    }, [page, alphabetValue])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    useEffect(() => {
        handleSelectAllVendor();
    }, [isCheckAll])

    const handleSelectAllVendor = () => {
        if (isCheckAll) {
            setCheckedState(new Array(vendorList?.length).fill(true));
            vendorList.map((item: any, index: any) => {
                let checkId = deleteVendor.filter((i:any) => i  === item?._id)
                if (checkId.length === 0)
                    deleteVendor.push(item?._id)
            })
            length = deleteVendor.length;
            setDeleteVendorArray(deleteVendor)
        } else {
            if (!checkedState.includes(false)) {
                deleteVendor = []
                setDeleteVendorArray(deleteVendor)
                setCheckedState(new Array(vendorList?.length).fill(false));
            }
            length = 0
        }
    }

    const handeleSelectVendor = (id: any, i: any) => {
        if(isCheckAll){
            setIsCheckAll(!isCheckAll)
        }
        const updatedCheckedState = checkedState.map((item: any, index: any) =>
            index === i ? !item : item
        );
        setCheckedState(updatedCheckedState);

        if (deleteVendor.includes(id)) {
            deleteVendor = deleteVendor.filter((item: any) => item !== id)
            setDeleteVendorArray(deleteVendor)
        } else {
            deleteVendor.push(id)
            setDeleteVendorArray(deleteVendor)
        }
    }

    const deleteVendorList = () => {
        deleteVendor.map((i: any) => {
            ApiDelete(`corporate/vendor_list/${i}`)
                .then((res) => {
                    SuccessToast(res?.data.message)
                    getVendorList()
                    setDeleteVendorArray([])
                })
                .catch((err) => ErrorToast(err.message))
        })
    }

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='container-xxl pt-0 mt-0 px-4'>
                <div className='d-flex align-items-center justify-content-center gap-5 mb-10 gap-lg-5'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-list`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                Lists
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-vendors`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                Vendors
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-requests`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                Requests
                            </a>
                        </li>
                    </ul>
                </div>

                <div className='d-flex mb-10 d-none d-sm-none d-md-none d-lg-flex'>
                    <h5 className='gray-500 me-10 mb-0 w-200px'>
                        Total Lists:{' '}
                        <span className='' style={{ color: 'black' }}>
                            {vendorListCount}
                        </span>
                    </h5>
                    <div className='d-flex w-100 justify-content-center'>
                        {alphabet.map((i) => {
                            return (
                                <span className={`px-2 ${alphabetValue === i ? 'active' : ''}`} style={alphabetValue === i ? { cursor: "pointer", background: '#007a59', borderRadius: 5, marginRight: 5, color: 'white' } : { cursor: "pointer", background: '#c7c7c7', borderRadius: 5, marginRight: 5 }} onClick={() => handelFilter(i)}>{i}</span>
                            )
                        })
                        }
                    </div>
                </div>

                <div className='d-flex mb-10 d-flex d-sm-flex d-md-flex d-lg-none'>
                    <h5 className='gray-500 me-10 mb-0 w-200px'>
                        Total Lists:{' '}
                        <span className='' style={{ color: 'black' }}>
                            {vendorListCount}
                        </span>
                    </h5>
                    <div className='row justify-content-center me-1'>
                        {alphabet.map((i) => {
                            return (
                                <div className='col-1'>
                                <span className={`px-2 ${alphabetValue === i ? 'active' : ''}`} style={alphabetValue === i ? { cursor: "pointer", background: '#007a59', borderRadius: 5, marginRight: 5, color: 'white' } : { cursor: "pointer", background: '#c7c7c7', borderRadius: 5, marginRight: 5 }} onClick={() => handelFilter(i)}>{i}</span></div>
                            )
                        })
                        }
                    </div>
                </div>

                <Row className="d-flex d-sm-none">
                    <Col className="ms-auto text-end">
                    <div
                                className='btn btn-sm fw-bold btn-primary btn-green hover-none mb-3'
                                onClick={() => navigate(`/vendor-management/management-list/create-list`)}>
                                + Create New List
                            </div>
                            </Col>
                </Row>


                <Row className='mb-8 w-auto '>
                    <Col className='align-items-center d-flex mt-3 ' xs={8} sm={6} md={6} lg={6}>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isCheckAll} style={{ cursor: 'pointer' }} onChange={() => { setIsCheckAll(!isCheckAll) }} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Select All
                            </label>
                        </div>
                        <h5 className='gray-500 mx-5 mb-0'>
                            Selected:{' '}
                            <span className='' style={{ color: 'black' }}>
                                {length}
                            </span>
                        </h5>
                    </Col>
                    <Col className='align-items-center d-flex mt-3 justify-content-end pe-0' xs={4} sm={6} md={6} lg={6}>
                        <div className='mb-2  d-flex w-auto'>
                            <div
                                className='btn btn-sm fw-bold me-3 btn-primary btn-green hover-none  d-none d-sm-flex'
                                onClick={() => navigate(`/vendor-management/management-list/create-list`)}>
                                + Create New List
                            </div>
                            <div
                                onClick={() => Swal
                                    .fire({
                                        text: ' Are you sure you want to delete the selected vendor list?  ',
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Delete',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                    })
                                    .then((res) => {
                                        if (res.isConfirmed) {
                                            deleteVendorList()
                                        }
                                    })}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                            >
                                Delete
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='card card-flush px-2'>
                    <div className='card-body py-0 table-responsive mt-5 px-3'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-10px'></th>
                                    <th className='min-w-100px'>List Name</th>
                                    <th className='min-w-150px'>Total Vendors</th>
                                    <th className='min-w-100px'>Date Created</th>
                                    <th className='min-w-100px'></th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                { vendorList?.length > 0 ?
                                        vendorList?.map((item: any, i: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                            <input className="form-check-input" type="checkbox" value="" checked={checkedState[i]} id="flexCheckDefault" style={{ cursor: 'pointer' }} onChange={() => handeleSelectVendor(item?._id, i)} />
                                                        </div>
                                                    </td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.totalVendor}</td>
                                                    <td>{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                                                    <td>
                                                        <div className='menu-item px-3'>
                                                            <div
                                                                onClick={() => navigate(`/vendor-management/management-list/view-vendor/${item?._id}`)}
                                                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                            >
                                                                View
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : 
                                        <td colSpan={5} className="text-center" style={{padding: "50px 0"}}><b>No Record Found</b> </td>
                                    }
                            </tbody>
                        </table>
                    </div>
                    { vendorList?.length > 0 &&
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            </div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageClassName="paginate_button page-item +"
                                        pageLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        previousClassName="paginate_button page-item previous disabled"
                                        nextClassName="paginate_button page-item next"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel="<"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div >
    );
}

export default List;
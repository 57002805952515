import React, {useEffect, useState} from 'react'
import {Section} from './EmailBuilder'
import TextEditor from '../../../General/TextEditor'
import {useRef} from 'react'
import ReactQuill from 'react-quill'
import Quill from 'quill'

interface SectionRendererProps {
  section: Section
  isSelected: boolean
  selectedSectionStyling?: any
  setSelectedSectionStyling: (values: any) => void
  isDesktopPreview?: boolean
  // textEditorRef: any
  updateSelectedText?: any
  selectedField: string | null
  selectedDocumentField: string | null
  refreshState?: any
  disableDynamicTextImg: (values: any) => void
  updateDynamicText: (values: any) => void
  disableDynamicDocumentImg: (values: any) => void
  updateDynamicDocument: (values: any) => void
  disableDTextBCZofDocument: (values: any) => void
  toggleSquareBracket: () => void
  updateDisableDTextBCZofDocument: (values: any) => void
}

const SectionRenderer: React.FC<SectionRendererProps> = ({
  section,
  isSelected,
  selectedSectionStyling,
  setSelectedSectionStyling,
  isDesktopPreview,
  // textEditorRef,
  updateSelectedText,
  selectedField,
  refreshState,
  disableDynamicTextImg,
  updateDynamicText,
  disableDynamicDocumentImg,
  updateDynamicDocument,
  selectedDocumentField,
  disableDTextBCZofDocument,
  toggleSquareBracket,
  updateDisableDTextBCZofDocument,
}) => {
  const quillRef = useRef<ReactQuill | null>(null)

  const [highlightedText, setHighlightedText] = useState<string | null>(null)
  const [selectionRange, setSelectionRange] = useState<{index: number; length: number} | null>(null)

  const handleCursorEnter = (val: any) => {
    const quillEditor: any = quillRef?.current?.getEditor()
    const range = val
    if (range) {
      const editorContent = quillEditor?.getText(0, quillEditor.getLength())
      const cursorPosition = range?.index

      const curlyStartIndex = editorContent?.lastIndexOf('{{', cursorPosition)
      const curlyEndIndex = editorContent?.indexOf('}}', cursorPosition)

      const curlyEndIndexBeforeCursor = editorContent?.lastIndexOf('}}', cursorPosition)
      const curlyStartIndexAfterCursor = editorContent?.indexOf('{{', cursorPosition)

      console.log('curlyStartIndexAfterCursor ' + curlyStartIndexAfterCursor)
      console.log('curlyEndIndexBeforeCursor ' + curlyEndIndexBeforeCursor)

      if (
        curlyStartIndex !== -1 &&
        curlyEndIndex !== -1 &&
        cursorPosition > curlyStartIndex &&
        cursorPosition <= curlyEndIndex &&
        (curlyEndIndexBeforeCursor === -1 ||
          curlyEndIndexBeforeCursor == curlyEndIndex ||
          curlyEndIndexBeforeCursor < curlyStartIndex) &&
        (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
      ) {
        // console.log('Inside curly brackets')

        const textInsideCurlyBrackets = editorContent.slice(curlyStartIndex + 2, curlyEndIndex)
        // console.log('Inside curly brackettttts:', textInsideCurlyBrackets)

        updateDynamicText(textInsideCurlyBrackets)
        updateSelectedText('')

        disableDynamicTextImg(true)
      } else {
        // console.log('outside curly brackets')
        // disableDynamicTextImg(false)

        const editor: any = quillRef?.current?.getEditor()
        const range = quillEditor?.getSelection()

        // New condition: Check for square brackets
        const squareStartIndex = editorContent?.lastIndexOf('[', cursorPosition)
        const squareEndIndex = editorContent?.indexOf(']', cursorPosition)

        const squareEndIndexBeforeCursor = editorContent?.lastIndexOf(']', cursorPosition)
        const squareStartIndexAfterCursor = editorContent?.indexOf('[', cursorPosition)

        if (
          squareStartIndex !== -1 &&
          squareEndIndex !== -1 &&
          cursorPosition > squareStartIndex &&
          cursorPosition <= squareEndIndex &&
          (squareEndIndexBeforeCursor === -1 ||
            squareEndIndexBeforeCursor == squareEndIndex ||
            squareEndIndexBeforeCursor < squareStartIndex) &&
          (squareStartIndexAfterCursor === -1 || squareStartIndexAfterCursor > squareEndIndex)
        ) {
          console.log('Inside square brackets')

          // Get the Delta object (contains HTML structure)
          const delta = quillEditor.getContents(
            squareStartIndex + 1,
            squareEndIndex - squareStartIndex - 1
          )
          // console.log(delta)

          // Convert the Delta into HTML
          const quillContainer = document.createElement('div')
          delta?.ops?.forEach((op: any) => {
            if (typeof op.insert === 'string') {
              quillContainer.appendChild(document.createTextNode(op.insert))
            } else if (op.insert?.image) {
              const img = document.createElement('img')
              img.src = op.insert.image
              quillContainer.appendChild(img)
            } else if (op.attributes?.link) {
              const a = document.createElement('a')
              a.href = op.attributes.link
              a.innerText = op.insert
              quillContainer.appendChild(a)
            }
          })

          const textInsideSquareBrackets = editorContent.slice(squareStartIndex + 1, squareEndIndex)
          // console.log('Inside square brackets:', textInsideSquareBrackets)
          const textHighlights: any = editor.getText(squareStartIndex + 1, squareStartIndex - 1)

          const htmlInsideSquareBrackets = quillContainer.innerHTML
          // console.log('HTML inside square brackets:', htmlInsideSquareBrackets)

          // Now extract the <a> tag and modify href if it contains {{ }}
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = htmlInsideSquareBrackets
          const anchorTag = tempDiv.querySelector('a')

          if (anchorTag) {
            let hrefValue = anchorTag.getAttribute('href')
            console.log('Original href:', hrefValue)

            // Check if the href value contains {{ }} and remove them
            if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
              hrefValue = hrefValue.slice(2, -2) // Remove curly braces
              anchorTag.setAttribute('href', hrefValue)
              // console.log('Updated href (without curly brackets):', hrefValue)
            }
          }

          let linkValue = delta?.ops?.[0]?.attributes?.link

          if (linkValue?.startsWith('{{') && linkValue?.endsWith('}}')) {
            // Remove the first two and last two characters (i.e., the curly braces)
            linkValue = linkValue.slice(2, -2)
            delta.ops[0].attributes.link = linkValue // Update the link value without braces
          }

          updateDynamicDocument(
            delta?.ops?.[0]?.attributes?.link != undefined ||
              delta?.ops?.[0]?.attributes?.link != 'undefined'
              ? delta?.ops?.[0]?.attributes?.link
              : null
          )
          console.log(textInsideSquareBrackets + '-----' + delta?.ops?.[0]?.attributes?.link)
          updateSelectedText(textInsideSquareBrackets)
          disableDynamicTextImg(true) // Example action

          saveRangeForSquareBracket()
        } else {
          if (range && range.length > 0) {
            console.log('I am in range')
            // Get the highlighted text and update state
            const selectedText = editor.getText(range.index, range.length)
            setHighlightedText(selectedText) // Store highlighted text
            setSelectionRange({index: range.index, length: range.length}) // Store selection range

            updateSelectedText(selectedText)

            // console.log(selectedText)
            // console.log({index: range.index, length: range.length})

            if (selectedText?.trim()?.length != 0) disableDynamicDocumentImg(false)
            else disableDynamicDocumentImg(true)

            disableDynamicTextImg(false)

            updateDynamicDocument(null)
          } else {
            console.log('I am here---')
            setHighlightedText(null) // Clear selection if no text is highlighted
            setSelectionRange(null) // Clear selection range
            disableDynamicTextImg(false)
            updateDynamicDocument(null)
            disableDynamicDocumentImg(true)
          }
        }
      }
    }

    setSelectedRange(range)
  }

  // replacing highlighted text - text editor
  const replaceHighlightedText = () => {
    if (selectionRange) {
      const editor: any = quillRef?.current?.getEditor()
      const textToDelete: any = editor?.getText(selectionRange.index, selectionRange.length)

      if (selectedField == '[]') {
        editor?.deleteText(selectionRange.index, selectionRange.length) // Delete highlighted text
        editor.insertText(selectionRange.index, `[${textToDelete}]`)

        // Apply bold formatting to the inserted text
        editor.formatText(selectionRange.index, textToDelete.length + 2, 'bold', true)

        // Move the cursor behind the last `[` before the closing `]`
        const newCursorPosition = selectionRange.index + textToDelete.length + 1
        editor.setSelection(newCursorPosition)
        editor.focus()

        updateSelectedText(`${textToDelete}`)
      }
      setHighlightedText(null) // Clear highlighted text after replacement
      setSelectionRange(null) // Clear selection range after replacement
    }
  }

  // replacing highlighted text - editable span
  const replaceHighlightedTextInEditableSpan = () => {
    const span = editSpanRef.current

    if (span && selectionRange) {
      const selection = window.getSelection()

      if (selection && selection.rangeCount > 0) {
        const range: any = selection.getRangeAt(0)
        const textToDelete = range.toString() // Get highlighted text

        if (selectedField === '[]' && highlightedText != null) {
          // Delete the selected text
          range.deleteContents()
          // Insert the text wrapped in []
          const newTextNode = document.createTextNode(`[${textToDelete}]`)
          range.insertNode(newTextNode)

          // Apply bold formatting to the inserted text
          const newRange = document.createRange()
          newRange.setStartBefore(newTextNode)
          newRange.setEndAfter(newTextNode)

          const spanElement: any = document.createElement('a')
          spanElement.style.fontWeight = 'bold'
          spanElement.style.color = 'inherit'
          spanElement.style.textDecoration = 'none'
          spanElement.className = `${textToDelete}`

          newRange.surroundContents(spanElement)

          // Move the cursor to the 2nd last character of the inserted text
          const newRangeForCursor: any = document.createRange()
          const length = spanElement.textContent.length

          // Move cursor to 2nd last character
          newRangeForCursor.setStart(spanElement.firstChild!, length - 1)
          newRangeForCursor.setEnd(spanElement.firstChild!, length - 1)
          selection.removeAllRanges()
          selection.addRange(newRangeForCursor)

          updateSelectedText(`${textToDelete}`)
          span.focus()

          // Save this updated range for future use
          setSelectionRange(newRangeForCursor)

          // disableDynamicDocumentImg(true)
          // disableDynamicTextImg()
          // updateDisableDTextBCZofDocument(true)

          // console.log(editSpanRef.current?.innerHTML)

          const values = {...selectedSectionStyling}

          const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

          if (sectionElement) {
            const btn: NodeListOf<HTMLButtonElement> = sectionElement.querySelectorAll('button')
            console.log(btn)
            if (btn) {
              //
              btn?.forEach((itm: any, ind) => {
                itm.innerHTML = editSpanRef.current?.innerHTML
                itm.style.width = `${span.scrollWidth + 15}px`
                itm.style.height = `${span.scrollHeight + 5}px`
                // values.content.buttonName = buttonName
                values.content.buttonHtml = editSpanRef.current?.innerHTML
              })
            }
          }

          values.content.html = sectionElement.innerHTML
          setSelectedSectionStyling(values)

          saveRangeForEditableSpan()
        }

        // Clear highlighted text after replacement
        setHighlightedText(null)
      }

      toggleSquareBracket()
    }
  }

  //
  useEffect(() => {
    if (section?.type == 'text') replaceHighlightedText()
    else if (section?.type == 'button') replaceHighlightedTextInEditableSpan()
  }, [selectedField, refreshState])

  const [range, setSelectedRange] = useState<any>(null)

  // Working Fine
  useEffect(() => {
    if (section?.type == 'text' && highlightedText == null) {
      console.log('/*********** Section Renderer - Text Editor -  Dynamic Text ************/')

      // console.log(quillRef.current)
      // console.log(refreshState)
      if (selectedField && quillRef.current) {
        const quillEditor = quillRef.current.getEditor()
        // const range = quillEditor.getSelection(); // Get current cursor position
        // console.log(range)
        if (range) {
          // Insert merge field at cursor position

          const editorContent = quillEditor.getText(0, quillEditor.getLength())
          const cursorPosition = range.index

          // Find the nearest '{{' and '}}' around the cursor
          const curlyStartIndex = editorContent.lastIndexOf('{{', cursorPosition)
          const curlyEndIndex = editorContent.indexOf('}}', cursorPosition)

          if (
            (curlyStartIndex !== -1 &&
              curlyEndIndex !== -1 &&
              cursorPosition > curlyStartIndex &&
              cursorPosition <= curlyEndIndex) ||
            selectedField === '{{}}'
          ) {
            const curlyEndIndexBeforeCursor = editorContent?.lastIndexOf('}}', cursorPosition)
            const curlyStartIndexAfterCursor = editorContent?.indexOf('{{', cursorPosition)

            // console.log('curlyStartIndexAfterCursor ' + curlyStartIndexAfterCursor)
            // console.log('curlyEndIndexBeforeCursor ' + curlyEndIndexBeforeCursor)

            if (
              curlyStartIndex !== -1 &&
              curlyEndIndex !== -1 &&
              cursorPosition > curlyStartIndex &&
              cursorPosition <= curlyEndIndex &&
              (curlyEndIndexBeforeCursor === -1 ||
                curlyEndIndexBeforeCursor == curlyEndIndex ||
                curlyEndIndexBeforeCursor < curlyStartIndex) && // No `}}` before cursor
              (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
            ) {
              console.log('{********** I am in IF **********}')
              // Replace text inside curly braces
              quillEditor.deleteText(curlyStartIndex + 2, curlyEndIndex - curlyStartIndex - 2) // Remove content between '{{' and '}}'
              quillEditor.insertText(
                curlyStartIndex + 2,
                selectedField,
                {
                  color: '#ff9a1e', // Apply custom formatting (orange color)
                },
                Quill.sources.USER
              )

              quillEditor.setSelection(
                curlyStartIndex + 2 + selectedField.length,
                0,
                Quill.sources.SILENT
              )

              // const newCursorPosition = range.index + selectedField.length - 2 // Move cursor back by 2 characters
              // quillEditor.setSelection(newCursorPosition, 0, Quill.sources.SILENT)
            } else {
              // console.log('{********** I am in ELSE **********}')
              // Check if the selected field is '{{}}' and move the cursor back by 2 positions
              if (selectedField === '{{}}') {
                quillEditor.insertText(
                  range.index,
                  selectedField,
                  {
                    color: '#000', // Set text color to orange
                  },
                  Quill.sources.USER
                ) // Apply custom formatting
              } else {
                quillEditor.insertText(
                  range.index + 2,
                  selectedField + '',
                  {
                    color: '#ff9a1e', // Set text color to orange
                  },
                  Quill.sources.USER
                ) // Apply custom formatting
                // quillEditor.setSelection(range.index + selectedField.length, 0, Quill.sources.SILENT); // Normal selection after inserting
              }

              // Wrap the inserted text with a span or similar to treat it as a single entity
              // quillEditor.formatText(range.index, selectedField.length, { color: '#ff9a1e', }, Quill.sources.USER);

              if (selectedField === '{{}}') {
                const newCursorPosition = range.index + selectedField.length - 2 // Move cursor back by 2 characters
                quillEditor.setSelection(newCursorPosition, 0, Quill.sources.SILENT) // Set the cursor position without triggering additional events
              } else {
                const newCursorPosition = range.index + selectedField.length + 2 // Move cursor back by 2 characters
                quillEditor.setSelection(newCursorPosition, 0, Quill.sources.SILENT)
              }
            }
          }

          // Ensure the editor retains focus
          // setTimeout(() => {
          quillEditor.focus()
          // }, 0) // Timeout helps ensure focus is set correctly after cursor repositioning

          const updatedContent = quillEditor.root.innerHTML
          let values = {...selectedSectionStyling}
          values.content.html = updatedContent

          // console.log(values)
          setSelectedSectionStyling(values)

          saveRange()
        }
      }
    }
  }, [selectedField, refreshState])

  //
  const saveRange = () => {
    const quillEditor: any = quillRef?.current?.getEditor()
    const range = quillEditor?.getSelection()
    if (range) {
      const editorContent = quillEditor?.getText(0, quillEditor.getLength())
      const cursorPosition = range?.index

      // console.log('cursorPosition ' + cursorPosition)

      const curlyStartIndex = editorContent?.lastIndexOf('{{', cursorPosition)
      const curlyEndIndex = editorContent?.indexOf('}}', cursorPosition)

      // console.log('curlyStartIndex ' + curlyStartIndex)
      // console.log('curlyEndIndex ' + curlyEndIndex)

      const curlyEndIndexBeforeCursor = editorContent?.lastIndexOf('}}', cursorPosition)
      const curlyStartIndexAfterCursor = editorContent?.indexOf('{{', cursorPosition)

      // console.log('curlyStartIndexAfterCursor ' + curlyStartIndexAfterCursor)
      // console.log('curlyEndIndexBeforeCursor ' + curlyEndIndexBeforeCursor)

      if (
        curlyStartIndex !== -1 &&
        curlyEndIndex !== -1 &&
        cursorPosition > curlyStartIndex &&
        cursorPosition <= curlyEndIndex &&
        (curlyEndIndexBeforeCursor === -1 ||
          curlyEndIndexBeforeCursor == curlyEndIndex ||
          curlyEndIndexBeforeCursor < curlyStartIndex) && // No `}}` before cursor
        (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
      ) {
        // console.log('Cursor is inside curly brackets')
        disableDynamicTextImg(true)

        const textInsideCurlyBrackets = editorContent.slice(curlyStartIndex + 2, curlyEndIndex)
        // console.log('Inside curly brackets text :', textInsideCurlyBrackets)

        updateDynamicText(textInsideCurlyBrackets)
      } else {
        // New condition: Check for square brackets
        const squareStartIndex = editorContent?.lastIndexOf('[', cursorPosition)
        const squareEndIndex = editorContent?.indexOf(']', cursorPosition)

        const squareEndIndexBeforeCursor = editorContent?.lastIndexOf(']', cursorPosition)
        const squareStartIndexAfterCursor = editorContent?.indexOf('[', cursorPosition)

        if (
          squareStartIndex !== -1 &&
          squareEndIndex !== -1 &&
          cursorPosition > squareStartIndex &&
          cursorPosition <= squareEndIndex &&
          (squareEndIndexBeforeCursor === -1 ||
            squareEndIndexBeforeCursor == squareEndIndex ||
            squareEndIndexBeforeCursor < squareStartIndex) &&
          (squareStartIndexAfterCursor === -1 || squareStartIndexAfterCursor > squareEndIndex)
        ) {
          const editor: any = quillRef?.current?.getEditor()
          // const range = quillEditor?.getSelection()
          console.log('Inside square brackets --- SAVE RANGE')

          // Get the Delta object (contains HTML structure)
          const delta = quillEditor.getContents(
            squareStartIndex + 1,
            squareEndIndex - squareStartIndex - 1
          )
          // console.log(delta)

          // Convert the Delta into HTML
          const quillContainer = document.createElement('div')
          delta?.ops?.forEach((op: any) => {
            if (typeof op.insert === 'string') {
              quillContainer.appendChild(document.createTextNode(op.insert))
            } else if (op.insert?.image) {
              const img = document.createElement('img')
              img.src = op.insert.image
              quillContainer.appendChild(img)
            } else if (op.attributes?.link) {
              const a = document.createElement('a')
              a.href = op.attributes.link
              a.innerText = op.insert
              quillContainer.appendChild(a)
            }
          })

          const textInsideSquareBrackets = editorContent.slice(squareStartIndex + 1, squareEndIndex)
          // console.log('Inside square brackets:', textInsideSquareBrackets)
          const textHighlights: any = editor.getText(squareStartIndex + 1, squareStartIndex - 1)

          const htmlInsideSquareBrackets = quillContainer.innerHTML
          // console.log('HTML inside square brackets:', htmlInsideSquareBrackets)

          // Now extract the <a> tag and modify href if it contains {{ }}
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = htmlInsideSquareBrackets
          const anchorTag = tempDiv.querySelector('a')

          if (anchorTag) {
            let hrefValue = anchorTag.getAttribute('href')
            // console.log('Original href:', hrefValue)

            // Check if the href value contains {{ }} and remove them
            if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
              hrefValue = hrefValue.slice(2, -2) // Remove curly braces
              anchorTag.setAttribute('href', hrefValue)
              // console.log('Updated href (without curly brackets):', hrefValue)
            }
          }

          let linkValue = delta?.ops?.[0]?.attributes?.link

          if (linkValue?.startsWith('{{') && linkValue?.endsWith('}}')) {
            // Remove the first two and last two characters (i.e., the curly braces)
            linkValue = linkValue.slice(2, -2)
            delta.ops[0].attributes.link = linkValue // Update the link value without braces
          }

          updateDynamicDocument(delta?.ops?.[0]?.attributes?.link)
          // console.log(textInsideSquareBrackets + '-----' + delta?.ops?.[0]?.attributes?.link)
          updateSelectedText(textInsideSquareBrackets)
          disableDynamicTextImg(true) // Example action
        } else {
          console.log('Cursor is outside curly brackets')
          disableDynamicTextImg(false)
        }
      }
    }

    setSelectedRange(range)
  }

  const [buttonName, setButtonName] = useState(section.content.buttonName) // State for button text

  const editSpanRef = useRef<HTMLDivElement>(null)

  {
    /*****************  ********************/
  }
  useEffect(() => {
    if (isSelected && section?.type == 'button') {
      const span: any = editSpanRef.current
      if (span) span.innerHTML = section?.content?.buttonHtml
    }
  }, [isSelected])

  {
    /***************** Dynamic Text in CTA ********************/
  }
  useEffect(() => {
    console.log('Highlighted text ----- CTA-----' + highlightedText)
    if (section?.type == 'button' && highlightedText == null) {
      if (selectedField && editSpanRef.current) {
        const span = editSpanRef.current

        // Get the current selection and text content of the span
        const selection: any = document.getSelection()
        const spanText = span.textContent || ''
        const cursorPosition = range ? range.startOffset : 0

        // Find the nearest '{{' and '}}' around the cursor
        const curlyStartIndex = spanText.lastIndexOf('{{', cursorPosition)
        const curlyEndIndex = spanText.indexOf('}}', cursorPosition)

        const curlyEndIndexBeforeCursor = spanText?.lastIndexOf('}}', cursorPosition)
        const curlyStartIndexAfterCursor = spanText?.indexOf('{{', cursorPosition)

        if (
          (curlyStartIndex !== -1 &&
            curlyEndIndex !== -1 &&
            cursorPosition > curlyStartIndex &&
            cursorPosition <= curlyEndIndex) ||
          selectedField === '{{}}'
        ) {
          if (
            curlyStartIndex !== -1 &&
            curlyEndIndex !== -1 &&
            cursorPosition > curlyStartIndex &&
            cursorPosition <= curlyEndIndex &&
            (curlyEndIndexBeforeCursor === -1 ||
              curlyEndIndexBeforeCursor == curlyEndIndex ||
              curlyEndIndexBeforeCursor < curlyStartIndex) && // No `}}` before cursor
            (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
          ) {
            // Replace text inside curly braces
            const newText =
              spanText.slice(0, curlyStartIndex + 2) + selectedField + spanText.slice(curlyEndIndex)
            span.textContent = newText

            // Move cursor to end of the inserted text
            const newRange = document.createRange()
            newRange.setStart(span.firstChild!, curlyStartIndex + 2 + selectedField.length)
            newRange.setEnd(span.firstChild!, curlyStartIndex + 2 + selectedField.length)
            selection.removeAllRanges()
            selection.addRange(newRange)

            const textInsideCurlyBrackets = spanText
              .slice(curlyStartIndex + 2, curlyEndIndex)
              .trim()
            // console.log('Text inside curly brackets:', textInsideCurlyBrackets);

            updateDynamicText(textInsideCurlyBrackets)
          } else {
            // Insert the field text at the cursor position
            const newText =
              spanText.slice(0, cursorPosition) + selectedField + spanText.slice(cursorPosition)
            span.textContent = newText

            const newRange = document.createRange()
            newRange.setStart(span.firstChild!, cursorPosition + selectedField.length)
            newRange.setEnd(span.firstChild!, cursorPosition + selectedField.length)
            selection.removeAllRanges()
            selection.addRange(newRange)

            // If selectedField is "{{}}", move the cursor 2 points back
            if (selectedField === '{{}}') {
              newRange.setStart(span.firstChild!, cursorPosition + selectedField.length - 2)
              newRange.setEnd(span.firstChild!, cursorPosition + selectedField.length - 2)
              selection.removeAllRanges()
              selection.addRange(newRange)
            }
          }

          // Ensure the span retains focus after the text insertion
          span.focus()

          setButtonName(span.innerHTML)

          saveRangeForEditableSpan() // Save the new range
        }
      }
    }
  }, [selectedField, refreshState]) // Trigger on field selection or state change

  //
  const saveRangeForEditableSpan = () => {
    const span = editSpanRef.current
    if (span) {
      const selection = document.getSelection()
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const cursorPosition = range.startOffset

        // Get the text content of the span
        const spanText = span.textContent || ''

        // Find the nearest '{{' and '}}' around the cursor
        const curlyStartIndex = spanText.lastIndexOf('{{', cursorPosition)
        const curlyEndIndex = spanText.indexOf('}}', cursorPosition)

        const curlyEndIndexBeforeCursor = spanText?.lastIndexOf('}}', cursorPosition)
        const curlyStartIndexAfterCursor = spanText?.indexOf('{{', cursorPosition)

        // console.log('curlyStartIndexAfterCursor ' + curlyStartIndexAfterCursor)
        // console.log('curlyEndIndexBeforeCursor ' + curlyEndIndexBeforeCursor)

        if (
          curlyStartIndex !== -1 &&
          curlyEndIndex !== -1 &&
          cursorPosition > curlyStartIndex &&
          cursorPosition <= curlyEndIndex &&
          (curlyEndIndexBeforeCursor === -1 ||
            curlyEndIndexBeforeCursor == curlyEndIndex ||
            curlyEndIndexBeforeCursor < curlyStartIndex) && // No `}}` before cursor
          (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
        ) {
          // console.log('Inside curly brackets')
          disableDynamicTextImg(true) // Disable dynamic text image button when inside curly braces

          const textInsideCurlyBrackets = spanText.slice(curlyStartIndex + 2, curlyEndIndex).trim()
          // console.log('Text inside curly brackets:', textInsideCurlyBrackets);

          updateDynamicText(textInsideCurlyBrackets)
        } else {
          // Find the nearest '{{' and '}}' around the cursor
          const squareStartIndex = spanText.lastIndexOf('[', cursorPosition)
          const squareEndIndex = spanText.indexOf(']', cursorPosition)

          console.log(squareStartIndex + 'squareStartIndex ')

          const squareEndIndexBeforeCursor = spanText?.lastIndexOf(']', cursorPosition)
          const squareStartIndexAfterCursor = spanText?.indexOf('[', cursorPosition)

          let newCursorPosition = squareStartIndex + cursorPosition

          if (
            newCursorPosition !== -1 &&
            newCursorPosition !== -1 &&
            newCursorPosition > squareStartIndex &&
            newCursorPosition <= squareEndIndex
            // && (squareEndIndexBeforeCursor === -1 ||
            //   curlyEndIndexBeforeCursor == squareEndIndex ||
            //   squareEndIndexBeforeCursor < squareStartIndex) && // No `}}` before cursor
            // (squareStartIndexAfterCursor === -1 || squareStartIndexAfterCursor > squareEndIndex)
          ) {
            console.log('Inside square brackets')
            setHighlightedText(null)
          } else {
            console.log('Outside curly brackets')
            disableDynamicTextImg(false) // Enable dynamic text image button when outside curly braces
          }
        }

        setSelectedRange(range) // Store the current range for future use
      }
    }
  }

  //
  const onCursorEnter = (val: Range) => {
    const span = editSpanRef.current
    if (span) {
      const selection: any = document.getSelection()
      console.log(selection)
      const range = val || (selection ? selection.getRangeAt(0) : null)

      if (range) {
        const cursorPosition = range.startOffset

        console.log('cursorPosition ' + cursorPosition)

        // Get the text content of the span
        const spanText = span.textContent || ''

        // Find the nearest '{{' and '}}' around the cursor
        const curlyStartIndex = spanText.lastIndexOf('{{', cursorPosition)
        const curlyEndIndex = spanText.indexOf('}}', cursorPosition)

        // / Find the last '[' before the cursor

        let newCursorPosition: any = 0

        // Find the first ']' after the cursor
        // const squareBracketEndIndex = spanText.indexOf(']', newCursorPosition);
        // console.log("squareBracketEndIndex" +squareBracketEndIndex)

        // const  squareBracketStartIndex = Number(squareBracketEndIndex)-Number(cursorPosition)

        // const squareBracketStartIndex = spanText.lastIndexOf('[', squareBracketEndIndex);
        // console.log("squareBracketStartIndex " +squareBracketStartIndex)

        //  / newCursorPosition = squareBracketStartIndex + 1

        // Traverse all text nodes before the current cursor node
        const childNodes = span.childNodes

        for (let i = 0; i < childNodes.length; i++) {
          const node = childNodes[i]

          // If we've reached the node that contains the cursor
          if (node === range.startContainer) {
            newCursorPosition += range.startOffset // Add the cursor's offset within this node
            break
          }

          // Sum the length of text in previous nodes
          if (node.nodeType === Node.TEXT_NODE) {
            newCursorPosition += node.textContent?.length || 0
          }
        }
        console.log('newCursorPosition ' + newCursorPosition)

        // Find the nearest '{{' and '}}' around the cursor

        const squareBracketStartIndex = spanText.lastIndexOf('[', newCursorPosition)
        console.log('squareBracketStartIndex ' + squareBracketStartIndex)

        const curlyEndIndexBeforeCursor = spanText?.lastIndexOf('}}', cursorPosition)
        const curlyStartIndexAfterCursor = spanText?.indexOf('{{', cursorPosition)

        console.log(spanText.lastIndexOf(']', newCursorPosition))

        if (newCursorPosition != cursorPosition && spanText.lastIndexOf(']', cursorPosition) == -1)
          newCursorPosition = squareBracketStartIndex + cursorPosition

        const squareBracketEndIndex = spanText.indexOf(']', newCursorPosition)
        console.log('squareBracketEndIndex' + squareBracketEndIndex)

        console.log(document.getSelection())

        // console.log('curlyStartIndexAfterCursor ' + curlyStartIndexAfterCursor)
        // console.log('curlyEndIndexBeforeCursor ' + curlyEndIndexBeforeCursor)

        if (
          curlyStartIndex !== -1 &&
          curlyEndIndex !== -1 &&
          cursorPosition > curlyStartIndex &&
          cursorPosition <= curlyEndIndex &&
          (curlyEndIndexBeforeCursor === -1 ||
            curlyEndIndexBeforeCursor == curlyEndIndex ||
            curlyEndIndexBeforeCursor < curlyStartIndex) && // No `}}` before cursor
          (curlyStartIndexAfterCursor === -1 || curlyStartIndexAfterCursor > curlyEndIndex)
        ) {
          //  was working fine
          // if (
          //   curlyStartIndex !== -1 &&
          //   curlyEndIndex !== -1 &&
          //   cursorPosition > curlyStartIndex &&
          //   cursorPosition <= curlyEndIndex
          //   // && ((spanText.lastIndexOf('}}', cursorPosition) === -1  &&
          //   // spanText.indexOf('{{', cursorPosition) === -1) )
          // ) {
          console.log('Inside curly brackets in Editable Span')
          disableDynamicTextImg(true) // Disable dynamic text image button when inside curly braces

          const textInsideCurlyBrackets = spanText.slice(curlyStartIndex + 2, curlyEndIndex).trim()
          // console.log('Text inside curly brackets:', textInsideCurlyBrackets);

          updateDynamicText(textInsideCurlyBrackets)
          updateSelectedText('')
          updateDynamicDocument(null)
          setHighlightedText(null)
        } else if (
          squareBracketStartIndex !== -1 &&
          squareBracketEndIndex !== -1 &&
          newCursorPosition > squareBracketStartIndex &&
          newCursorPosition <= squareBracketEndIndex

          // && spanText.lastIndexOf(']', cursorPosition) === -1   // Ensure no ] before cursor
          // && spanText.indexOf('[', cursorPosition) === -1
        ) {
          console.log('Cursor is inside square brackets in Editable Span')

          // Disable dynamic text image button when inside square brackets
          disableDynamicTextImg(true)
          // Use innerHTML instead of textContent to get the full HTML inside the span
          const span = editSpanRef.current
          if (!span) return

          const anchorTags = span.querySelectorAll('a') // Get all <a> tags in the span
          let targetAnchorTag: any

          /// Loop through anchor tags to find the one that wraps the square brackets
          anchorTags.forEach((anchorTag) => {
            const anchorHTML = anchorTag.outerHTML
            const anchorText = anchorTag.textContent || ''

            // Check if the anchor tag contains or overlaps with the square brackets
            const anchorStart = spanText.indexOf(anchorText)
            const anchorEnd = anchorStart + anchorText.length

            // If the anchor tag wraps the square brackets, we found our target anchor
            if (anchorStart <= squareBracketStartIndex && anchorEnd >= squareBracketEndIndex) {
              targetAnchorTag = anchorTag
            }
          })

          if (targetAnchorTag) {
            const htmlInsideSquareBrackets = targetAnchorTag.innerHTML
            console.log('HTML inside square brackets:', htmlInsideSquareBrackets)

            // Check if the <a> tag has an href and update the content accordingly
            let hrefValue = targetAnchorTag.getAttribute('href') // Type-safe now
            if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
              hrefValue = hrefValue.slice(2, -2) // Remove curly braces
              // targetAnchorTag.setAttribute('href', hrefValue)
              console.log('Updated href (without curly braces):', hrefValue)
            }

            // Update dynamic document with the extracted href or text
            updateDynamicDocument(hrefValue || htmlInsideSquareBrackets)
          }

          const textInsideSquareBrackets = spanText
            .slice(squareBracketStartIndex + 1, squareBracketEndIndex)
            .trim()
          console.log('Text inside square brackets:', textInsideSquareBrackets)

          updateSelectedText(textInsideSquareBrackets)

          disableDynamicTextImg(true) // Disable dynamic text image button when inside square brackets

          // Ensure the span retains focus
          editSpanRef.current?.focus()
        } else {
          const selection: any = document.getSelection()
          if (selection && selection.rangeCount > 0) {
            // Highlighted text handling in Editable Span
            const range: any = selection.getRangeAt(0)
            const selected = range.toString()

            if (selected) {
              if (selectedSectionStyling?.type == 'button') {
                if (
                  selectedSectionStyling?.content?.html?.includes('[') &&
                  selectedSectionStyling?.content?.html.includes(']')
                ) {
                  // Set your other variable based on the existence of square brackets

                  console.log('Square brackets found in the HTML')
                  updateDisableDTextBCZofDocument(true)
                  disableDynamicDocumentImg(true)
                  
                } else {
                  console.log('Highlighted Text:', selected)

                  if (
                    selectedSectionStyling?.content?.html?.includes('{{') &&
                    selectedSectionStyling?.content?.html.includes('}}')
                  ) {
                    // Set your other variable based on the existence of square brackets

                    console.log('Curly brackets found in the HTML')
                    disableDynamicDocumentImg(true)
                    disableDynamicTextImg(false)
                  } else {
                    setHighlightedText(selected) // Store highlighted text
                    setSelectionRange(range) // Store selection range

                    updateSelectedText(selected)

                    if (selected?.trim()?.length != 0) disableDynamicDocumentImg(false)
                    else disableDynamicDocumentImg(true)

                    disableDynamicTextImg(false)
                    updateDisableDTextBCZofDocument(true)

                    updateDynamicDocument(null)
                    console.log('No square brackets found in the HTML')
                  }
                }
              }
            } else {
              console.log('Outside curly brackets')

              if (
                selectedSectionStyling?.content?.html?.includes('[') &&
                selectedSectionStyling?.content?.html.includes(']')
              ) {
                // Set your other variable based on the existence of square brackets

                console.log('Square brackets found in the HTML')
                updateDisableDTextBCZofDocument(true)
                disableDynamicTextImg(false)
                disableDynamicDocumentImg(true)
              } else if (
                selectedSectionStyling?.content?.html?.includes('{{') &&
                selectedSectionStyling?.content?.html.includes('}}')
              ) {
                // Set your other variable based on the existence of square brackets

                console.log('Curly brackets found in the HTML')
                disableDynamicDocumentImg(true)
                disableDynamicTextImg(false)
              } else {
                updateDisableDTextBCZofDocument(false)
                disableDynamicTextImg(false)
                disableDynamicDocumentImg(true)
              }

              updateSelectedText('')
              updateDynamicDocument(null)
              setHighlightedText(null)
            }
          }
        }

        // Save the current selection range
        setSelectedRange(val)
      }
    }

    toggleSquareBracket()
  }

  //
  useEffect(() => {
    console.log('/*********** Section Renderer Dynamic Document ************/')
    console.log(highlightedText)
    console.log(selectedDocumentField)
    console.log(quillRef?.current)
    console.log(section?.type)

    if (
      selectedDocumentField != null &&
      quillRef?.current &&
      highlightedText == null &&
      section?.type == 'text'
    ) {
      const quillEditor: any = quillRef?.current?.getEditor()
      // const range = quillEditor?.getSelection() // Get current cursor position
      console.log(range)

      if (range) {
        const editorContent = quillEditor.getText(0, quillEditor?.getLength())
        const cursorPosition = range.index

        // Find the nearest '[' and ']' around the cursor
        const bracketStartIndex = editorContent.lastIndexOf('[', cursorPosition)
        const bracketEndIndex = editorContent.indexOf(']', cursorPosition)

        if (
          bracketStartIndex !== -1 &&
          bracketEndIndex !== -1 &&
          cursorPosition > bracketStartIndex &&
          cursorPosition <= bracketEndIndex
        ) {
          // console.log('{********** Inside Square Brackets **********}')

          // Get the text inside the square brackets
          const textInsideBrackets = editorContent.substring(bracketStartIndex + 1, bracketEndIndex)
          // console.log('Text inside brackets:', textInsideBrackets)

          // Delete the content inside the brackets
          quillEditor.deleteText(bracketStartIndex, bracketEndIndex - bracketStartIndex + 1)

          const textToDelete: any = quillEditor?.getText(
            bracketStartIndex,
            bracketEndIndex - bracketStartIndex + 1
          )

          // Insert an <a> tag with href attribute (replace 'selectedDocumentField' with your actual URL or variable)
          const anchorTag = `<a href="{{${selectedDocumentField}}}" style="color: inherit !important; font-style: normal !important; text-decoration: underline !important;">[${textInsideBrackets}]</a>`
          quillEditor.clipboard.dangerouslyPasteHTML(bracketStartIndex, anchorTag)

          // Adjust the cursor position after the inserted <a> tag
          // quillEditor.setSelection(bracketStartIndex + anchorTag.length, 0, Quill.sources.SILENT)

          // Move the cursor behind the last `[` before the closing `]`
          const newCursorPosition = bracketEndIndex // Position after the inserted text but before the `]`
          quillEditor.setSelection(newCursorPosition)

          // Extract the href attribute from the inserted <a> tag
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = anchorTag // Use tempDiv to parse the inserted HTML
          const insertedAnchor = tempDiv.querySelector('a')

          if (insertedAnchor) {
            let hrefValue = insertedAnchor.getAttribute('href')
            console.log('Original href:', hrefValue) // Log the href with curly braces

            // Check if the href contains {{ }} and remove them
            if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
              hrefValue = hrefValue.slice(2, -2) // Remove the curly braces
            }

            console.log('href in a tag', hrefValue)

            updateDynamicDocument(hrefValue)
          }

          // console.log('CURSOR POSITION OF SQUARE BRACKETS')

          // Ensure the editor retains focus
          quillEditor.focus()

          // Update your content as needed
          const updatedContent = quillEditor.root.innerHTML
          let values = {...selectedSectionStyling}
          values.content.html = updatedContent

          // console.log(values)
          setSelectedSectionStyling(values)

          saveRangeForSquareBracket()
        }
      }
    }
  }, [selectedDocumentField, refreshState])

  const saveRangeForSquareBracket = () => {
    const quillEditor: any = quillRef?.current?.getEditor()
    const range = quillEditor?.getSelection()
    if (range) {
      const editorContent = quillEditor?.getText(0, quillEditor.getLength())
      const cursorPosition = range?.index

      const squareStartIndex = editorContent?.lastIndexOf('[', cursorPosition)
      const squareEndIndex = editorContent?.indexOf(']', cursorPosition)

      const squareEndIndexBeforeCursor = editorContent?.lastIndexOf(']', cursorPosition)
      const squareStartIndexAfterCursor = editorContent?.indexOf('[', cursorPosition)

      if (
        squareStartIndex !== -1 &&
        squareEndIndex !== -1 &&
        cursorPosition > squareStartIndex &&
        cursorPosition <= squareEndIndex &&
        (squareEndIndexBeforeCursor === -1 ||
          squareEndIndexBeforeCursor == squareEndIndex ||
          squareEndIndexBeforeCursor < squareStartIndex) &&
        (squareStartIndexAfterCursor === -1 || squareStartIndexAfterCursor > squareEndIndex)
      ) {
        // console.log('Inside square bracket')

        const textInsideSquareBrackets = editorContent.slice(squareStartIndex + 2, squareEndIndex)
        // console.log('Inside squarebrackettttts Text:', textInsideSquareBrackets)

        // Extract the href from the anchor tag inside the square brackets
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = textInsideSquareBrackets
        const anchorTag = tempDiv.querySelector('a')

        if (anchorTag) {
          let hrefValue = anchorTag.getAttribute('href')
          // console.log('Original href:', hrefValue)

          // Check if the href value contains {{ }} and remove them
          if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
            hrefValue = hrefValue.slice(2, -2) // Remove curly braces
            anchorTag.setAttribute('href', hrefValue)
            // console.log('Updated href (without curly brackets):', hrefValue)
          }
        }

        const textHighlights: any = quillEditor.getText(squareStartIndex + 1, squareEndIndex - 1)

        // updateDynamicDocument(textInsideSquareBrackets)
        updateSelectedText(textHighlights)

        setTimeout(() => {
          disableDynamicTextImg(true)
        }, 300)

        // disableDynamicTextImg(true) // Example action

        // Move the cursor one step back before the last ']' character
        // const newCursorPosition = curlyEndIndex - 1; // Directly before the closing ']'
        //  quillEditor.setSelection(newCursorPosition, 0, Quill.sources.SILENT);
      } else {
        // console.log('outside square bracket')
        // disableDynamicTextImg(false)
      }
    }

    setSelectedRange(range)
  }

  //   useEffect(() => {
  //     console.log('/*********** Section Renderer Dynamic Document (Editable Span) ************/');
  //     console.log(highlightedText);
  //     console.log(selectedDocumentField);
  //     console.log(editSpanRef.current)
  //     console.log( section?.type)
  //     console.log(selectedDocumentField != null &&
  //       editSpanRef.current &&
  //       highlightedText == null &&
  //       section?.type == 'button')

  //     if (
  //       selectedDocumentField != null &&
  //       editSpanRef.current &&
  //       highlightedText == null &&
  //       section?.type == 'button'
  //     ) {
  //       const span = editSpanRef.current;

  //       const selection = document.getSelection();
  //       console.log(selection)

  //       if (selection && selection.rangeCount > 0) {
  //         const range = selection.getRangeAt(0);
  //         const spanText = span.textContent || ''; // Get the content of the span
  //         const cursorPosition = range.startOffset;

  //         // Find the nearest '[' and ']' around the cursor
  //         const bracketStartIndex = spanText.lastIndexOf('[', cursorPosition);
  //         const bracketEndIndex = spanText.indexOf(']', cursorPosition);

  //         // if (
  //         //   bracketStartIndex !== -1 &&
  //         //   bracketEndIndex !== -1 &&
  //         //   cursorPosition > bracketStartIndex &&
  //         //   cursorPosition <= bracketEndIndex
  //         // ) {
  //           console.log("Inside square brackets - EDIATABLE SPAN")
  //           // Get the text inside the square brackets
  //           const textInsideBrackets = spanText.substring(bracketStartIndex + 1, bracketEndIndex);

  //           // Remove the content inside the brackets (delete the brackets too)
  //           const newText = spanText?.substring(0, bracketStartIndex) + spanText?.substring(bracketEndIndex + 1);
  //           span.textContent = newText;

  //           // Create an <a> tag to insert with href
  //           const anchorTag = document.createElement('a');
  //           anchorTag.href = `{{${selectedDocumentField}}}`;
  //           anchorTag.style.color = 'inherit';
  //           anchorTag.style.fontStyle = 'normal';
  //           anchorTag.style.textDecoration = 'underline';
  //           anchorTag.textContent = `[${textInsideBrackets}]`;

  //           // Insert the <a> tag in place of the deleted brackets
  // if (span.firstChild && span.firstChild.nodeType === Node.TEXT_NODE) {
  //   // Make sure that the first child is valid and of type Node.TEXT_NODE (common for text within spans)
  //   range.setStart(span.firstChild, bracketStartIndex);
  //   range.deleteContents();
  //   range.insertNode(anchorTag);

  //   // Move the cursor behind the inserted <a> tag
  //   const newRange = document.createRange();
  //   newRange.setStart(anchorTag.firstChild!, anchorTag.textContent?.length || 0);
  //   newRange.setEnd(anchorTag.firstChild!, anchorTag.textContent?.length || 0);
  //   selection?.removeAllRanges();
  //   selection?.addRange(newRange);
  // } else {
  //   console.error('First child is not a valid text node.');
  // }

  //           // Extract href from the inserted <a> tag
  //           const hrefValue = anchorTag.href.replace('{{', '').replace('}}', '');
  //           console.log('Extracted href:', hrefValue);

  //           updateDynamicDocument(hrefValue); // Handle updating dynamic document

  //           // Ensure the span retains focus
  //           span.focus();

  //           // Update the section's styling/content
  //           const updatedContent = span.innerHTML;
  //           let values = { ...selectedSectionStyling };
  //           values.content.html = updatedContent;
  //           setSelectedSectionStyling(values);

  //           saveRangeForSquareBracketEditableSpan();
  //         // }
  //       }
  //     }
  //   }, [selectedDocumentField, refreshState]);

  // useEffect(() => {
  //   console.log('/*********** Section Renderer Dynamic Document (Editable Span) ************/');
  //   console.log(highlightedText);
  //   console.log(selectedDocumentField);
  //   console.log(editSpanRef.current);
  //   console.log(section?.type);

  //   if (
  //     selectedDocumentField != null &&
  //     editSpanRef.current &&
  //     highlightedText == null &&
  //     section?.type === 'button'
  //   ) {
  //     const span = editSpanRef.current;

  //     const selection = document.getSelection();
  //     console.log(selection);

  //     if (selection && selection.rangeCount > 0) {
  //       // const range = selection.getRangeAt(0);
  //       const spanText = span.textContent || ''; // Get the content of the span
  //       console.log(span)
  //       console.log(spanText)
  //       let cursorPosition = range.startOffset;

  //       console.log(cursorPosition)

  //       // Find the nearest '[' and ']' around the cursor
  //       const bracketStartIndex = spanText.lastIndexOf('[', cursorPosition);
  //       const bracketEndIndex = spanText.indexOf(']', cursorPosition);

  //       console.log(bracketStartIndex)
  //       console.log(bracketEndIndex)

  //       // cursorPosition = bracketEndIndex -1

  //       // Only proceed if valid brackets are found
  //       if (bracketStartIndex !== -1 && bracketEndIndex !== -1 && cursorPosition > bracketStartIndex && cursorPosition <= bracketEndIndex) {
  //         console.log("Inside square brackets - EDITABLE SPAN");

  //         // Get the text inside the square brackets
  //         const textInsideBrackets = spanText.substring(bracketStartIndex + 1, bracketEndIndex);

  //         // Remove the content inside the brackets (delete the brackets too)
  //         const newText = spanText?.substring(0, bracketStartIndex) + spanText?.substring(bracketEndIndex + 1);
  //         span.textContent = newText;

  //         // Create an <a> tag to insert with href
  //         const anchorTag = document.createElement('a');
  //         anchorTag.href = `{{${selectedDocumentField}}}`;
  //         anchorTag.style.color = 'inherit';
  //         anchorTag.style.fontStyle = 'normal';
  //         anchorTag.style.textDecoration = 'underline';
  //         anchorTag.textContent = `[${textInsideBrackets}]`;

  //         // Insert the <a> tag in place of the deleted brackets
  //         if (span.firstChild && span.firstChild.nodeType === Node.TEXT_NODE) {
  //           // Move the range to where the brackets were and replace the content
  //           range?.setStart(span.firstChild, bracketStartIndex);
  //           range?.deleteContents();
  //           range?.insertNode(anchorTag);

  //           // Move the cursor behind the inserted <a> tag
  //           const newRange = document.createRange();
  //           newRange?.setStartAfter(anchorTag);
  //           selection?.removeAllRanges();
  //           selection?.addRange(newRange);

  //           console.log('Link inserted successfully');
  //         } else {
  //           console.error('First child is not a valid text node.');
  //         }

  //         // Extract href from the inserted <a> tag
  //         const hrefValue = anchorTag.href.replace('{{', '').replace('}}', '');
  //         console.log('Extracted href:', hrefValue);

  //         updateDynamicDocument(hrefValue); // Handle updating the dynamic document

  //         // Ensure the span retains focus
  //         span.focus();

  //         // Update the section's styling/content
  //         const updatedContent = span.innerHTML;
  //         let values = { ...selectedSectionStyling };
  //         values.content.html = updatedContent;
  //         setSelectedSectionStyling(values);

  //         saveRangeForSquareBracketEditableSpan();
  //       }
  //     }
  //   }
  // }, [selectedDocumentField, refreshState]);

  // was working
  // useEffect(() => {
  //   console.log('/*********** Section Renderer Dynamic Document (Editable Span) ************/')
  //   console.log(highlightedText)
  //   console.log(selectedDocumentField)
  //   console.log(editSpanRef.current)
  //   console.log(section?.type)

  //   if (
  //     selectedDocumentField != null &&
  //     editSpanRef.current &&
  //     highlightedText == null &&
  //     section?.type === 'button'
  //   ) {
  //     const span = editSpanRef.current

  //     // Use the saved selectionRange directly
  //     if (selectionRange) {
  //       const selection: any = window.getSelection()
  //       selection.removeAllRanges()
  //       selection.addRange(selectionRange) // Restore the saved range

  //       console.log(selection)

  //       const rangeNew: any = selectionRange // Use the saved range directly
  //       console.log(rangeNew)
  //       const spanText = span.textContent || '' // Get the content of the span
  //       console.log(span)
  //       console.log('Span Text:', spanText) // Log the full text of the span

  //       let cursorPosition = rangeNew.startOffset
  //       console.log('Cursor Position:', cursorPosition) // Log the cursor position

  //       // Find the last '[' before the cursor
  //       // const bracketStartIndex = spanText.lastIndexOf('[', cursorPosition);

  //       // Find the first ']' after the cursor
  //       const bracketEndIndex = spanText.indexOf(']', cursorPosition)

  //       const bracketStartIndex = Number(bracketEndIndex) - Number(cursorPosition)

  //       console.log('Bracket Start Index:', bracketStartIndex) // Log the start index
  //       console.log('Bracket End Index:', bracketEndIndex) // Log the end index

  //       // Only proceed if valid brackets are found
  //       if (
  //         bracketStartIndex !== -1 &&
  //         bracketEndIndex !== -1 &&
  //         // cursorPosition > bracketStartIndex &&
  //         cursorPosition <= bracketEndIndex
  //       ) {
  //         console.log('Inside square brackets - EDITABLE SPAN')

  //         // Get the text inside the square brackets
  //         const textInsideBrackets = spanText.substring(bracketStartIndex + 1, bracketEndIndex)

  //         // Remove the content inside the brackets (delete the brackets too)
  //         const newText =
  //           spanText.substring(0, bracketStartIndex) + spanText.substring(bracketEndIndex + 1)
  //         span.textContent = newText

  //         // Create an <a> tag to insert with href
  //         const anchorTag = document.createElement('a')
  //         anchorTag.href = `{{${selectedDocumentField}}}`
  //         anchorTag.style.color = 'inherit'
  //         anchorTag.style.fontWeight = 'bold'
  //         anchorTag.style.textDecoration = 'underline'
  //         anchorTag.textContent = `[${textInsideBrackets}]`

  //         // Insert the <a> tag in place of the deleted brackets
  //         if (span.firstChild && span.firstChild.nodeType === Node.TEXT_NODE) {
  //           // Move the range to where the brackets were and replace the content
  //           rangeNew.setStart(span.firstChild, bracketStartIndex)
  //           rangeNew.deleteContents()
  //           rangeNew.insertNode(anchorTag)

  //           // Move the cursor behind the inserted <a> tag
  //           const newRange = document.createRange()
  //           newRange.setStartAfter(anchorTag)
  //           selection.removeAllRanges()
  //           selection.addRange(newRange)

  //           console.log('Link inserted successfully')
  //         } else {
  //           console.error('First child is not a valid text node.')
  //         }

  //         // Extract href from the inserted <a> tag
  //         const hrefValue = anchorTag.href.replace('{{', '').replace('}}', '')
  //         console.log('Extracted href:', hrefValue)

  //         updateDynamicDocument(hrefValue) // Handle updating the dynamic document

  //         // Ensure the span retains focus
  //         span.focus()

  //         // Update the section's styling/content
  //         // const updatedContent = span.innerHTML;
  //         // let values = { ...selectedSectionStyling };
  //         // values.content.html = updatedContent;
  //         // setSelectedSectionStyling(values);

  //         // Save the current range for future use
  //         saveRangeForSquareBracketEditableSpan()
  //       } else {
  //         console.warn('No valid brackets found or cursor not within brackets.')
  //       }
  //     }
  //   }
  // }, [selectedDocumentField, refreshState])

  // const saveRangeForSquareBracketEditableSpan = () => {
  //   const span: any = editSpanRef.current
  //   const selection = document.getSelection()

  //   if (selection && selection.rangeCount > 0) {
  //     const range = selection.getRangeAt(0)
  //     const spanText = span.textContent || '' // Get the content of the span
  //     const cursorPosition = range.startOffset

  //     // Find the nearest '[' and ']' around the cursor
  //     const squareStartIndex = spanText.lastIndexOf('[', cursorPosition)
  //     const squareEndIndex = spanText.indexOf(']', cursorPosition)

  //     if (
  //       squareStartIndex !== -1 &&
  //       squareEndIndex !== -1 &&
  //       cursorPosition > squareStartIndex &&
  //       cursorPosition <= squareEndIndex
  //     ) {
  //       // Extract the text inside the square brackets
  //       const textInsideSquareBrackets = spanText.slice(squareStartIndex + 1, squareEndIndex)
  //       console.log('Text inside square brackets:', textInsideSquareBrackets)

  //       // Check if the text contains an anchor <a> tag and extract its href
  //       const tempDiv = document.createElement('div')
  //       tempDiv.innerHTML = textInsideSquareBrackets
  //       const anchorTag = tempDiv.querySelector('a')

  //       if (anchorTag) {
  //         let hrefValue = anchorTag.getAttribute('href')
  //         console.log('Original href:', hrefValue)

  //         // Remove curly braces if they exist around the href
  //         if (hrefValue?.startsWith('{{') && hrefValue?.endsWith('}}')) {
  //           hrefValue = hrefValue.slice(2, -2) // Remove curly braces
  //           console.log('Updated href (without curly brackets):', hrefValue)
  //           anchorTag.setAttribute('href', hrefValue)
  //         }
  //       }

  //       // Extract the text highlights (between the brackets)
  //       const textHighlights = spanText.slice(squareStartIndex + 1, squareEndIndex)
  //       console.log('Highlighted text:', textHighlights)

  //       // Update the highlighted text and dynamic document based on the extracted values
  //       updateSelectedText(textHighlights)
  //       updateDynamicDocument(textInsideSquareBrackets)

  //       setTimeout(() => {
  //         disableDynamicTextImg(true) // Disable dynamic text image when inside brackets
  //       }, 300)

  //       // Move the cursor one step back before the last ']' character
  //       const newRange = document.createRange()
  //       newRange.setStart(span.firstChild!, squareEndIndex - 1)
  //       newRange.setEnd(span.firstChild!, squareEndIndex - 1)
  //       selection.removeAllRanges()
  //       selection.addRange(newRange)
  //     } else {
  //       // Cursor is outside square brackets, so enable the dynamic text image button
  //       disableDynamicTextImg(false)
  //     }
  //   }

  //   setSelectedRange(selection?.getRangeAt(0)) // Save the current selection range
  // }

  useEffect(() => {
    console.log('/*********** Section Renderer Dynamic Document (Editable Span) ************/')
    console.log(highlightedText)
    console.log(selectedDocumentField)
    console.log(editSpanRef.current)
    console.log(section?.type)
    if (
      selectedDocumentField != null &&
      editSpanRef.current &&
      highlightedText == null &&
      section?.type === 'button'
    ) {
      const span = editSpanRef.current

      const selection = document.getSelection()
      if (selection && selection.rangeCount > 0) {
        // const range :any= (selection ? selection.getRangeAt(0) : null)
        const cursorPosition = range?.startOffset // Cursor position within the selected node
        const editorContent = editSpanRef.current.innerText

        const span = editSpanRef.current
        if (span) {
          // Get the text content of the span
          const spanText = span.textContent || ''
          console.log('Span Text:', spanText)

          console.log(spanText)
          let newCursorPosition: any = selection?.focusOffset || 0

          // Traverse all text nodes before the current cursor node
          const childNodes = span.childNodes

          for (let i = 0; i < childNodes.length; i++) {
            const node = childNodes[i]

            // If we've reached the node that contains the cursor
            if (node === range?.startContainer) {
              newCursorPosition += range?.startOffset // Add the cursor's offset within this node
              break
            }

            // Sum the length of text in previous nodes
            if (node.nodeType === Node.TEXT_NODE) {
              newCursorPosition += node.textContent?.length || 0
            }
          }

          let squareBracketEndIndex = spanText.indexOf(']', newCursorPosition)
          console.log('squareBracketEndIndex' + squareBracketEndIndex)

          let squareBracketStartIndex = spanText.lastIndexOf('[', newCursorPosition)
          console.log('squareBracketStartIndex ' + squareBracketStartIndex)

          newCursorPosition = squareBracketEndIndex - squareBracketStartIndex + cursorPosition + 1
          const textInsideBrackets = editorContent.substring(
            squareBracketStartIndex + 1,
            squareBracketEndIndex
          )

          newCursorPosition = squareBracketStartIndex + textInsideBrackets.length + 1

          // if (newCursorPosition > squareBracketEndIndex) newCursorPosition = squareBracketEndIndex
          // const newCursorPosition = squareBracketStartIndex + textInsideBrackets.length + 1
          console.log('New Cursor Position:', newCursorPosition)
          console.log('New Cursor Position:', newCursorPosition)

          // Find the nearest square brackets around the cursor
          // const bracketStartIndex = editorContent.lastIndexOf('[', cursorPosition);
          // const bracketEndIndex = editorContent.indexOf(']', cursorPosition);

          // console.log(cursorPosition)
          console.log(squareBracketStartIndex)
          console.log(squareBracketEndIndex)

          // squareBracketStartIndex = 6
          // newCursorPosition = 8

          if (
            squareBracketStartIndex !== -1 &&
            squareBracketEndIndex !== -1 &&
            newCursorPosition > squareBracketStartIndex &&
            newCursorPosition <= squareBracketEndIndex
          ) {
            const textInsideBrackets = editorContent.substring(
              squareBracketStartIndex + 1,
              squareBracketEndIndex
            )

            // Delete the content inside the brackets
            // const newContent =
            //   editorContent.slice(0, squareBracketStartIndex) +
            //   editorContent.slice(squareBracketEndIndex + 1)

            // // Insert anchor tag
            // const anchorTag = `<a href="{{${selectedDocumentField}}}" style="color: inherit; font-style: normal; font-weight: bold; text-decoration: underline;">[${textInsideBrackets}]</a>`
            // editSpanRef.current.innerHTML =
            //   newContent.slice(0, squareBracketStartIndex) +
            //   anchorTag +
            //   newContent.slice(squareBracketStartIndex)

            // // Move the cursor after the inserted anchor
            // const newCursorPosition = squareBracketStartIndex + textInsideBrackets.length + 1
            // console.log('New Cursor Position:', newCursorPosition)
            // setCaretPosition(editSpanRef.current, newCursorPosition);

            const anchorTag = Array.from(editSpanRef.current.querySelectorAll('a')).find(
              (anchor: any) => {
                return anchor.textContent === `[${textInsideBrackets}]`
              }
            )

            if (anchorTag) {
              // Update the href attribute
              anchorTag.setAttribute('href', `{{${selectedDocumentField}}}`)

              // Update the styling
              anchorTag.style.color = 'inherit'
              anchorTag.style.fontStyle = 'normal'
              anchorTag.style.fontWeight = 'bold'
              anchorTag.style.textDecoration = 'underline'

              // Optionally log for debugging
              console.log('Anchor tag updated:', anchorTag)
            } else {
              console.log('No anchor tag found with the specified text.')
            }

            // const newRange = document.createRange()
            //   newRange.setStart(span.firstChild!, cursorPosition + selectedDocumentField.length)
            //   newRange.setEnd(span.firstChild!, cursorPosition + selectedDocumentField.length)
            //   selection.removeAllRanges()
            //   selection.addRange(newRange)

            // span?.focus()

            saveRangeForSquareBracketEditable()
          }
        }
      }
    }
  }, [selectedDocumentField, refreshState])

  const saveRangeForSquareBracketEditable = () => {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const cursorPosition = range.startOffset
      console.log(cursorPosition)

      const editorContent = editSpanRef.current?.innerText ?? ''
      const squareStartIndex = editorContent.lastIndexOf('[', cursorPosition)
      const squareEndIndex = editorContent.indexOf(']', cursorPosition)

      if (
        squareStartIndex !== -1 &&
        squareEndIndex !== -1 &&
        cursorPosition > squareStartIndex &&
        cursorPosition <= squareEndIndex
      ) {
        // const textHighlights = editorContent.substring(squareStartIndex + 1, squareEndIndex);
        // updateSelectedText(textHighlights);
        // setTimeout(() => {
        //   disableDynamicTextImg(true);
        // }, 300);
        // setSelectedRange(range);
      }
    }
  }

  const setCaretPosition = (element: HTMLSpanElement | null, position: number) => {
    if (element?.firstChild) {
      // const range = document.createRange();
      // const sel = window.getSelection();
      // range.setStart(element.firstChild, position);
      // range.collapse(true);
      // sel?.removeAllRanges();
      // sel?.addRange(range);
      // element.focus();
    }
  }

  useEffect(() => {
    console.log(highlightedText + 'text hightlighted in Section Renderer')
    // disableDTextBCZofDocument(true)
  }, [highlightedText])

  return (
    <>
      {section.type == 'text' || section.type == 'textImage' || section.type == 'header' ? (
        <div
          style={{
            backgroundColor: section.content.backgroundColor,
            // minHeight: `${section.content.minHeight}px`,
            // height: `${section.content.height ? section.content.height+section.content.sizeType : 'unset'}`,
            height: 'unset',
            // width: `${section.content.width}${section.content.sizeType}`,
            width: '100%',
            color: section.content.color,
            display: section.content.display,
            justifyContent: section.content.justifyContent,
            marginTop: `${section.content.marginTop}px`,
            marginRight: `${section.content.marginRight}px`,
            marginBottom: `${section.content.marginBottom}px`,
            marginLeft: `${section.content.marginLeft}px`,
            paddingTop: `${section.content.paddingTop}px`,
            paddingRight: `${section.content.paddingRight}px`,
            paddingBottom: `${section.content.paddingBottom}px`,
            paddingLeft: `${section.content.paddingLeft}px`,
            // textAlign: section.content.textAlign
          }}
        >
          <div
            className={`${
              !isSelected
                ? 'po-editor-view'
                : `${
                    isDesktopPreview
                      ? 'template-text-editor'
                      : 'template-text-editor template-text-editor-sm'
                  }`
            }`}
            style={{width: '-webkit-fill-available'}}
            id={`${section?.id}`}
            // ref={textEditorRef}
          >
            <TextEditor
              value={section?.content?.html}
              placeholder={''}
              onChange={(e: any) => {
                console.log('ON CHANGE')
                let values = {...selectedSectionStyling}
                if (selectedSectionStyling != null) values.content.html = e
                setSelectedSectionStyling(values)

                saveRange()

                // handleFieldSelect("{{one}}")
              }}
              from={'email-template'}
              ref={isSelected ? quillRef : null}
              onCursorEnter={(val: any) => {
                console.log('ON CURSOR ENTER')
                handleCursorEnter(val)
              }}
            />
          </div>
          {/* <div dangerouslySetInnerHTML={{ __html: section.content.html }} /> */}
        </div>
      ) : section.type == 'button' && isSelected ? (
        <>
          <div
            style={{
              backgroundColor: section.content.backgroundColor,
              // minHeight: `${section.content.minHeight}px`,
              // height: `${
              //   section.content.height ? section.content.height + section.content.sizeType : 'unset'
              // }`,
              // width: `${section.content.width}${section.content.sizeType}`,
              color: section.content.color,
              display: section.content.display,
              justifyContent: section.content.justifyContent,
              marginTop: `${section.content.marginTop}px`,
              marginRight: `${section.content.marginRight}px`,
              marginBottom: `${section.content.marginBottom}px`,
              marginLeft: `${section.content.marginLeft}px`,
              paddingTop: `${section.content.paddingTop}px`,
              paddingRight: `${section.content.paddingRight}px`,
              paddingBottom: `${section.content.paddingBottom}px`,
              paddingLeft: `${section.content.paddingLeft}px`,
              textAlign: `center`,
            }}
          >
            <div
              style={{
                padding: section.content.dynamicStyle?.padding,
                border: section.content.dynamicStyle?.border,
              }}
            >
              <div
                ref={editSpanRef}
                style={{
                  width: 'fit-content',
                  height: 'auto',
                  backgroundColor: section.content.buttonColor,
                  color: section.content.buttonTextColor,
                  border: `${section.content.borderThickness}px ${section.content.borderLine} ${section.content.borderColor}`,
                  borderRadius: `${section.content.borderInnerTagRadius}px`,
                  textAlign: 'center',
                  position: 'relative',
                  zIndex: '11',
                  lineHeight: 'normal',
                  padding: '10px 20px',
                  overflow: 'hidden',
                }}
                contentEditable
                suppressContentEditableWarning // Suppress warning about React managing contentEditable
                onInput={(e: React.FormEvent<HTMLDivElement>) => {
                  setButtonName(e.currentTarget.textContent)

                  saveRangeForEditableSpan()

                  const span: any = editSpanRef.current

                  const values = {...selectedSectionStyling}

                  const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                  if (sectionElement) {
                    const btn: NodeListOf<HTMLButtonElement> =
                      sectionElement.querySelectorAll('button')
                    console.log(btn)
                    if (btn) {
                      //
                      btn?.forEach((itm: any, ind) => {
                        console.log(e.currentTarget.textContent)

                        itm.innerHTML = e.currentTarget.innerHTML
                        itm.style.width = `${span.scrollWidth + 15}px`
                        itm.style.height = `${span.scrollHeight + 5}px`
                        // values.content.buttonName = buttonName
                        values.content.buttonHtml = e.currentTarget.innerHTML
                      })
                    }
                  }

                  values.content.html = sectionElement.innerHTML
                  setSelectedSectionStyling(values)

                  toggleSquareBracket()
                }}
                onBlur={(e: React.FormEvent<HTMLDivElement>) => {
                  const span: any = editSpanRef.current

                  const values = {...selectedSectionStyling}

                  const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

                  if (sectionElement) {
                    const btn: NodeListOf<HTMLButtonElement> =
                      sectionElement.querySelectorAll('button')
                    console.log(btn)
                    if (btn) {
                      //
                      btn?.forEach((itm: any, ind) => {
                        console.log(e.currentTarget.textContent)

                        itm.innerHTML = e.currentTarget.innerHTML
                        itm.style.width = `${span.scrollWidth + 15}px`
                        itm.style.height = `${span.scrollHeight + 5}px`
                        // values.content.buttonName = buttonName
                        values.content.buttonHtml = e.currentTarget.innerHTML
                      })
                    }
                  }

                  values.content.html = sectionElement.innerHTML
                  setSelectedSectionStyling(values)

                  toggleSquareBracket()
                }}
                onClick={() => {
                  console.log('CTA Click')
                  const selection: any = document.getSelection()
                  if (selection && selection.rangeCount > 0) {
                    // // Highlighted text handling in Editable Span
                    // const range: any = selection.getRangeAt(0)
                    // const selected = range.toString()

                    // if (selected) {
                    //   console.log('Highlighted Text:', selected)

                    //   setHighlightedText(selected) // Store highlighted text
                    //   setSelectionRange(range) // Store selection range

                    //   updateSelectedText(selected)

                    //   if (selected?.trim()?.length != 0) disableDynamicDocumentImg(false)
                    //   else disableDynamicDocumentImg(true)

                    //   disableDynamicTextImg(false)

                    //   updateDynamicDocument(null)
                    // } else {
                    onCursorEnter(selection.getRangeAt(0))
                    // }
                  }
                }}
              >
                Click here
              </div>
            </div>

            {/* Hidden div to calculate content size */}
            <div style={{position: 'fixed', opacity: '0'}}>
              <div
                dangerouslySetInnerHTML={{__html: section.content.html}}
                id={`${section?.id}`}
                style={{
                  width: `${
                    section?.content?.imgSrc == 'svg' ? '-webkit-fill-available' : 'unset'
                  }`,
                }}
              />
            </div>
          </div>
        </>
      ) : section.type == 'image' || section.type == 'button' ? (
        <>
          <div className='position-relative'>
            <div
              className='templateButtonBoxOpacity z-index-2 border-radius-5px'
              style={{
                boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
              }}
            ></div>
            <div
              style={{
                backgroundColor: section.content.backgroundColor,
                // minHeight: `${section.content.minHeight}px`,
                // height: `${
                //   section.content.height ? section.content.height + section.content.sizeType : 'unset'
                // }`,
                // width: `${section.content.width}${section.content.sizeType}`,
                color: section.content.color,
                display: section.content.display,
                justifyContent: section.content.justifyContent,
                marginTop: `${section.content.marginTop}px`,
                marginRight: `${section.content.marginRight}px`,
                marginBottom: `${section.content.marginBottom}px`,
                marginLeft: `${section.content.marginLeft}px`,
                paddingTop: `${section.content.paddingTop}px`,
                paddingRight: `${section.content.paddingRight}px`,
                paddingBottom: `${section.content.paddingBottom}px`,
                paddingLeft: `${section.content.paddingLeft}px`,
                // textAlign: section.content.textAlign
              }}
            >
              {section.content.imageUrl ? (
                <img src={section.content.imageUrl} alt='Header' />
              ) : (
                <div
                  dangerouslySetInnerHTML={{__html: section.content.html}}
                  id={`${section?.id}`}
                  style={{
                    width: `${
                      section?.content?.imgSrc == 'svg' ? '-webkit-fill-available' : 'unset'
                    }`,
                  }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SectionRenderer

import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiGetNoAuth, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import AppVendormodal from './AppVendormodal'
import moment from 'moment'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {AiFillDelete} from 'react-icons/ai'

let vendorListArraydata: any = []
const ViewVendor = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [addVendor, setAddVendor] = React.useState<any>('')
  const [country, setCountry] = React.useState([])
  const [vendorData, setVendorData] = React.useState<any>('')
  const [formData, setFormData] = useState<any>({})
  const [showAddVendorModal, setShowAppVendorModal] = React.useState<boolean>(false)
  const [vendorsList, setVendorsList] = React.useState('Vendor List')
  const [vendorList, setVendorList] = React.useState<any>()
  const [vendorListArray, setVendrorListArray] = React.useState<any>()

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const getVendorDataById = async () => {
    await ApiGet(`corporate/vendor/${window.location.pathname?.split('/')[4]}`)
      .then((res) => {
        setVendorData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  React.useEffect(() => {
    getCountry()
  }, [])

  const getvendorList = () => {
    ApiGet('corporate/vendor_list')
      .then((res) => {
        setVendorList(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  React.useEffect(() => {
    getvendorList()
    getVendorDataById()
  }, [])

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setAddVendor({...addVendor, [name]: value})
  }

  const deleteVendorList = (id: any) => {
    const body = {
      vendorId: window.location.pathname?.split('/')[4],
      vendorListId: id,
    }
    ApiPost('corporate/vendor_list/remove_vendor/by_id', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        getVendorDataById()
      })
      .catch((err) => ErrorToast(err.message))
  }

  const deleteTradeLicence = () => {
    const body = {
      url: vendorData?.image,
    }
    ApiPost('upload/delete_file', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        editVendor()
      })
      .catch((err) => ErrorToast(err.message))
  }

  const editVendor = async () => {
    const body = {
      id: window.location.pathname?.split('/')[4],
      image: null,
    }
    await ApiPut(`corporate/vendor`, body)
      .then((res) => {
        getVendorDataById()
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <>
      <div
        className='app-container gap-5 d-flex flex-column container-xxl pt-0 mt-0'
        id='kt_app_main'
      >
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate('/vendor-management/management-list')}
              >
                Vendor
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> View Vendor</a>
            </li>
          </ul>
        </div>
        <div className='card p-5 flex-row justify-content-between'>
          <div className=''>
            <button
              className='btn btn-sm fw-bold btn-primary btn-green w-80px hover-none'
              onClick={() => navigate(`/vendor-management/management-vendors`)}
            >
              Back
            </button>
          </div>
          <div className=''>
            <button
              className='btn btn-sm fw-bold btn-primary btn-green w-80px hover-none'
              onClick={() =>
                navigate(
                  `/vendor-management/management-vendors/view-vendors/edit-vendor/${vendorData?._id}`
                )
              }
            >
              Edit
            </button>
          </div>
        </div>
        <div className='card p-5 text-muted'>
          <div className='d-flex justify-content-between tops'>
            <span className='m-0'>
              <b>Vendor Type: </b>Service Provider
            </span>
            <span className='m-0'>
              <b>Vendor ID: </b>
              {vendorData?.id}
            </span>
            <span className='m-0'>
              <b>Date Added: </b>
              {moment(vendorData?.createdAt).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className='px-5 py-3 side'>
            <h1 className='top green_color'>Company Details</h1>
            <h6 className='mx-0 text-muted align-items-center'>
              Company Name
              <div className='mx-1 value_black'>{vendorData?.companyName}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              Trade License No.
              <div className='mx-1 value_black d-flex gap-2 align-items-center'>
                {vendorData?.tradeLicenseNo}{' '}
                {vendorData?.image && (
                  <div className='d-flex'>
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      href={`${Bucket}${vendorData?.image}`}
                      target='_blank'
                    >
                      Trade License
                    </a>
                    <AiFillDelete
                      style={{cursor: 'pointer'}}
                      onClick={deleteTradeLicence}
                      color='#007a59'
                      fontSize={20}
                    />
                  </div>
                )}
              </div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              TRN
              <div className='mx-1 value_black'>{vendorData?.TRN}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              Country
              <div className='mx-1 value_black'>{vendorData?.country}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              City
              <div className='mx-1 value_black'>{vendorData?.city}</div>
            </h6>
            <h6 className='mx-0 text-muted align-items-center'>
              Vendor List
              <div className='mx-1 value_black'>
                {' '}
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                  onClick={() => setShowAppVendorModal(true)}
                >
                  Add To List
                </button>
              </div>
            </h6>
            <ul className='d-flex flex-wrap'>
              {vendorData?.vendor_list?.map((i: any) => {
                return (
                  <div className='d-flex pe-10'>
                    <li className='pe-2 value_black'>{i?.name}</li>
                    <AiFillDelete
                      color='#007a59'
                      fontSize={20}
                      onClick={() => deleteVendorList(i?._id)}
                    />
                  </div>
                )
              })}
            </ul>
          </div>
          <div className='px-5 py-3 side'>
            <h1 className='top green_color'>Main Contact Detail</h1>
            <h6 className=' text-muted'>
              <h6 className='text-muted tops'>Contract Name</h6>
              <h6 className='text-muted m-0 me-2'>
                First Name
                <div className='mx-1 value_black'>{vendorData?.firstName}</div>
              </h6>
              <h6 className='text-muted m-0 me-2'>
                Last Name
                <div className='mx-1 value_black'>{vendorData?.lastName}</div>
              </h6>
            </h6>
            <h6 className=' mx-0 text-muted align-items-center'>
              Contact Email
              <div className='mx-1 value_black'>{formData?.email}</div>
            </h6>{' '}
            <h6 className=' mx-0 text-muted align-items-center'>
              Contract (Mob No)
              <div className='mx-1 value_black'>{formData?.phoneNumber}</div>
            </h6>{' '}
          </div>
        </div>

        <div className='card card-flush'>
          <Row className='py-2'>
            <div className='d-flex justify-content-between'>
              <span className='mx-10 mt-5'>
                <b>Vendor Type: </b>Service Provider
              </span>
              <span className='mx-10 mt-5'>
                <b>Vendor ID: </b>
                {vendorData?.id}
              </span>
              <span className='mx-10 mt-5'>
                <b>Date Added: </b>
                {moment(vendorData?.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <Col lg={6}>
              <h3 className='mx-10 mb-10 mt-10'>Company Details </h3>
              <div className='min-w-200px mx-10'>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Company Name:</h6>
                  <b>{vendorData?.companyName}</b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Trade License No.:</h6>
                  <b>{vendorData?.tradeLicenseNo}</b>
                  {vendorData?.image && (
                    <div className='d-flex'>
                      {' '}
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        href={`${Bucket}${vendorData?.image}`}
                        target='_blank'
                      >
                        Trade License
                      </a>{' '}
                      <AiFillDelete
                        style={{cursor: 'pointer'}}
                        color='#007a59'
                        fontSize={20}
                        onClick={deleteTradeLicence}
                      />
                    </div>
                  )}
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>TRN:</h6>
                  <b>{vendorData?.TRN}</b>
                </div>
                {errors.companyName && (
                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                )}
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <div className='pe-5 w-50 d-flex align-items-center'>
                    <h6 className='text-muted m-0 me-2'>Country:</h6>
                    <b>{vendorData?.country}</b>
                  </div>
                  <div className='w-50 d-flex align-items-center'>
                    <h6 className='text-muted m-0 me-2'>City:</h6>
                    <b>{vendorData?.city}</b>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <h3 className='mx-10 mb-10 mt-10'>Main Contract Detail </h3>
              <div className='min-w-200px mx-10'>
                <div className='payment-receipt-item mb-5'>
                  <div className='d-flex'>
                    <h6 className='text-muted m-0 me-2 min-w-100px'>Contract Name</h6>
                    <div className='w-50 pe-5 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>First Name:</h6>
                      <b>{vendorData?.firstName}</b>
                    </div>
                    <div className='w-50 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>Last Name:</h6>
                      <b>{vendorData?.lastName}</b>
                    </div>
                  </div>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Contract Email:</h6>
                  <b>{vendorData?.email}</b>
                </div>
                {errors.companyName && (
                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                )}
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Contract (Mob No):</h6>
                  <b>{vendorData?.phoneNumber}</b>
                </div>
              </div>
            </Col>
            <div className='payment-receipt-item mb-5 mx-10'>
              <div className='d-flex align-items-center mb-5'>
                <h6 className='text-muted m-0 me-2'>Vendor List</h6>
                <button
                  className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none'
                  onClick={() => setShowAppVendorModal(true)}
                >
                  Add To List
                </button>
              </div>
              <ul className='d-flex flex-wrap'>
                {vendorData?.vendor_list?.map((i: any) => {
                  return (
                    <div className='d-flex pe-10'>
                      <li className='pe-2'>{i?.name}</li>
                      <AiFillDelete
                        color='#007a59'
                        fontSize={20}
                        onClick={() => deleteVendorList(i?._id)}
                      />
                    </div>
                  )
                })}
              </ul>
            </div>
          </Row>
        </div>
      </div>
      <AppVendormodal
        show={showAddVendorModal}
        handleClose={() => {
          setShowAppVendorModal(false)
        }}
        setFormData={setFormData}
        formData={vendorData}
        vendorsList={vendorsList}
        vendorList={vendorList}
        vendorListArray={vendorListArraydata}
        setVendrorListArray={setVendrorListArray}
        type={'viewVendor'}
        getVendorDataById={getVendorDataById}
      />
    </>
  )
}

export default ViewVendor

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useParams} from 'react-router-dom'
import starYellow from '../../../../img/starnotification.svg'
import noData from '../../../../img/NoData1.svg'
import { formatAmount } from '../../../../Utilities/utils'

interface LeasesOfPropertyProps {
  propData?: any
}

const LeasesOfProperty: React.FC<LeasesOfPropertyProps> = ({propData}) => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  // const [leasesRecord, setPaymentRecords] = useState<any>([
  //   {
  //     _id: '665ccbc5f9e8a71528a128ca',
  //     contractNo: '43242341',
  //     id: 523,
  //     renewalDays: 90,
  //     workspaceId: 'ae7ef7b8-7fa6-4375-9f97-3705a14186a8',
  //     duration: {
  //       start_date: '2023-07-04T00:00:00.000Z',
  //       end_date: '2024-07-03T00:00:00.000Z',
  //       days: 365,
  //       _id: '665ccf1ff9e8a71528a149e9',
  //     },
  //     rating: 0,
  //     totalRent: 16250,
  //     totalSecurityDeposit: 0,
  //     totalOther: 0,
  //     totalOverdue: 0,
  //     totalUpcoming: 0,
  //     totalAmount: 16250,
  //     totalPaid: 16250,
  //     percentageAmountNotPaid: 0,
  //     percentageAmountPaid: 100,
  //     isDraft: false,
  //     isCancel: false,
  //     tenantSource: 0,
  //     renewalStatus: null,
  //     tenancyStatus: 3,
  //     tenancyStatusDateTime: '2024-06-02T19:15:59.227Z',
  //     renewalStatusDateTime: '2024-06-02T19:15:59.227Z',
  //     draftStatusDateTime: '2024-06-02T19:15:59.227Z',
  //     isActive: true,
  //     isCorporateDelete: false,
  //     landlordTenancyApprovalStatus: 1,
  //     isFinancialIncluded: true,
  //     isApprovalEnabled: false,
  //     isLock: false,
  //     unitId: '66547f5c5bca976557080332',
  //     clusterId: null,
  //     unitGroupId: null,
  //     communityId: null,
  //     floorId: '66545af1e18bdc4ecdce1f6a',
  //     buildingId: '66545af1e18bdc4ecdce1f5e',
  //     tenantId: '664f0e69b645e2417cc225ed',
  //     previousTenancyId: null,
  //     tenantRequestId: null,
  //     subTenantIds: [],
  //     subWorkflowListId: '66275beedfa14ca5f48af28e',
  //     createdByRole: 0,
  //     createdBy: [
  //       {
  //         _id: '6582bac50d9a049c6882be03',
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //       },
  //     ],
  //     updatedBy: [
  //       {
  //         _id: '6582bac50d9a049c6882be03',
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //       },
  //     ],
  //     createdAt: '2024-06-02T19:45:09.709Z',
  //     terminatePaymentList: [],
  //     updatedAt: '2024-07-03T00:05:00.264Z',
  //     unit: [
  //       {
  //         _id: '66547f5c5bca976557080332',
  //         unitNo: '0701',
  //         unitType: 0,
  //         propertyType: 0,
  //         otherName: '',
  //         bedrooms: 1,
  //         bathrooms: 1,
  //       },
  //     ],
  //     building: [
  //       {
  //         _id: '66545af1e18bdc4ecdce1f5e',
  //         name: 'Prop Heights',
  //       },
  //     ],
  //     tenant: [
  //       {
  //         _id: '664f0e69b645e2417cc225ed',
  //         firstName: 'ketan',
  //         lastName: 'Vagh',
  //       },
  //     ],
  //     community: [],
  //     approval_workflow: [],
  //     renewal_request: {
  //       _id: '66a252a14f68f82c8278d533',
  //       id: 296,
  //       type: 2,
  //       status: 10,
  //       requestorType: 1,
  //       requestorId: '6582bac50d9a049c6882be03',
  //       requestor: {
  //         _id: '6582bac50d9a049c6882be03',
  //         id: 2,
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //         image: null,
  //       },
  //     },
  //     terminate_request: null,
  //     rate: 4,
  //   },
  //   {
  //     _id: '667929231ff79ed255f635a5',
  //     contractNo: '00076',
  //     id: 619,
  //     renewalDays: 90,
  //     workspaceId: 'ae7ef7b8-7fa6-4375-9f97-3705a14186a8',
  //     duration: {
  //       start_date: '2024-06-01T00:00:00.000Z',
  //       end_date: '2024-09-30T00:00:00.000Z',
  //       days: 121,
  //       _id: '667929231ff79ed255f635a6',
  //     },
  //     rating: 0,
  //     totalRent: 400,
  //     totalSecurityDeposit: 0,
  //     totalOther: 0,
  //     totalOverdue: 400,
  //     totalUpcoming: 0,
  //     totalAmount: 400,
  //     totalPaid: 0,
  //     percentageAmountNotPaid: 100,
  //     percentageAmountPaid: 0,
  //     isDraft: false,
  //     isCancel: false,
  //     tenantSource: 0,
  //     renewalStatus: 2,
  //     tenancyStatus: 2,
  //     tenancyStatusDateTime: '2024-06-24T08:03:16.859Z',
  //     renewalStatusDateTime: '2024-06-24T08:03:16.859Z',
  //     draftStatusDateTime: '2024-06-24T08:03:16.859Z',
  //     isActive: true,
  //     isCorporateDelete: false,
  //     landlordTenancyApprovalStatus: 1,
  //     isFinancialIncluded: false,
  //     isApprovalEnabled: false,
  //     isLock: false,
  //     unitId: '6679267368e2cc0221e8bf2b',
  //     clusterId: null,
  //     unitGroupId: null,
  //     communityId: null,
  //     floorId: '6679263d68e2cc0221e8be18',
  //     buildingId: '6679263d68e2cc0221e8be15',
  //     tenantId: '6650a6c3917a90ef9c23a9eb',
  //     previousTenancyId: null,
  //     tenantRequestId: null,
  //     subTenantIds: [],
  //     subWorkflowListId: '66275beedfa14ca5f48af28e',
  //     createdByRole: 0,
  //     createdBy: [
  //       {
  //         _id: '6582bac50d9a049c6882be03',
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //       },
  //     ],
  //     updatedBy: [],
  //     terminatePaymentList: [],
  //     createdAt: '2024-06-24T08:06:59.138Z',
  //     updatedAt: '2024-06-30T00:05:00.168Z',
  //     unit: [
  //       {
  //         _id: '6679267368e2cc0221e8bf2b',
  //         unitNo: 'P0002',
  //         unitType: 1,
  //         propertyType: 0,
  //         otherName: '',
  //         bedrooms: 2,
  //         bathrooms: 3,
  //       },
  //     ],
  //     building: [
  //       {
  //         _id: '6679263d68e2cc0221e8be15',
  //         name: 'Mukund Building Development',
  //       },
  //     ],
  //     tenant: [
  //       {
  //         _id: '6650a6c3917a90ef9c23a9eb',
  //         firstName: 'Mukund',
  //         lastName: 'Khunt',
  //       },
  //     ],
  //     community: [],
  //     approval_workflow: [],
  //     renewal_request: {
  //       _id: '66a25fe04f68f82c8278f58a',
  //       id: 297,
  //       type: 2,
  //       status: 10,
  //       requestorType: 1,
  //       requestorId: '6582bac50d9a049c6882be03',
  //       requestor: {
  //         _id: '6582bac50d9a049c6882be03',
  //         id: 2,
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //         image: null,
  //       },
  //     },
  //     terminate_request: null,
  //   },
  //   {
  //     _id: '665cd50bf0c97aa475d8322b',
  //     contractNo: '11123417',
  //     id: 534,
  //     renewalDays: 90,
  //     workspaceId: 'ae7ef7b8-7fa6-4375-9f97-3705a14186a8',
  //     duration: {
  //       start_date: '2024-01-01T00:00:00.000Z',
  //       end_date: '2024-12-31T00:00:00.000Z',
  //       days: 365,
  //       _id: '667d3c5139b55fa7f576df66',
  //     },
  //     rating: 0,
  //     totalRent: 1050000,
  //     totalSecurityDeposit: 50000,
  //     totalOther: 0,
  //     totalOverdue: 0,
  //     totalUpcoming: 525000,
  //     totalAmount: 1100000,
  //     totalPaid: 575000,
  //     percentageAmountNotPaid: 48,
  //     percentageAmountPaid: 52,
  //     isDraft: false,
  //     isCancel: false,
  //     tenantSource: 0,
  //     renewalStatus: 10,
  //     tenancyStatus: 1,
  //     tenancyStatusDateTime: '2024-06-02T20:07:27.928Z',
  //     renewalStatusDateTime: '2024-06-02T20:07:27.928Z',
  //     draftStatusDateTime: '2024-06-02T20:07:27.928Z',
  //     isActive: true,
  //     isCorporateDelete: false,
  //     landlordTenancyApprovalStatus: 1,
  //     isFinancialIncluded: true,
  //     isApprovalEnabled: false,
  //     isLock: false,
  //     unitId: '665c62d67adc8d2c9bd01e3d',
  //     clusterId: null,
  //     unitGroupId: null,
  //     communityId: null,
  //     floorId: '665c5cc87adc8d2c9bcfdeb6',
  //     buildingId: '66545af1e18bdc4ecdce1f5e',
  //     tenantId: '664f0e69b645e2417cc225ed',
  //     previousTenancyId: null,
  //     tenantRequestId: null,
  //     subTenantIds: [],
  //     subWorkflowListId: '66275beedfa14ca5f48af28e',
  //     createdByRole: 0,
  //     createdBy: [
  //       {
  //         _id: '6582bac50d9a049c6882be03',
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //       },
  //     ],
  //     updatedBy: [
  //       {
  //         _id: '6582bac50d9a049c6882be03',
  //         firstName: 'Emad',
  //         lastName: 'Test',
  //       },
  //     ],
  //     createdAt: '2024-06-02T20:24:43.079Z',
  //     terminatePaymentList: [],
  //     updatedAt: '2024-06-27T10:17:53.993Z',
  //     unit: [
  //       {
  //         _id: '665c62d67adc8d2c9bd01e3d',
  //         unitNo: 'M101 - Beauty Salon',
  //         unitType: 5,
  //         propertyType: 1,
  //         otherName: 'Beauty Salon',
  //         bedrooms: 3,
  //         bathrooms: 2,
  //       },
  //     ],
  //     building: [
  //       {
  //         _id: '66545af1e18bdc4ecdce1f5e',
  //         name: 'Prop Heights',
  //       },
  //     ],
  //     tenant: [
  //       {
  //         _id: '664f0e69b645e2417cc225ed',
  //         firstName: 'ketan',
  //         lastName: 'Vagh',
  //       },
  //     ],
  //     community: [],
  //     approval_workflow: [],
  //     renewal_request: null,
  //     terminate_request: null,
  //   },
  // ])

  const [leasesRecord, setPaymentRecords] = useState<any>([])

  return (
    <>
      <div className={'card card-flush py-5 mt-4  px-5 mb-5 mx-1'}>
        <div className='table-responsive mt-2' style={{maxHeight: '700px'}}>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px'>Contract No</th>
                <th className='text-center min-w-150px'>Tenancy Status</th>
                <th className='text-start min-w-125px'>Tenant</th>
                <th className='text-start min-w-100px'>Source</th>
                <th className='text-start min-w-125px'>Term</th>
                <th className='text-center min-w-125px'>Renewal Status</th>
                <th className='text-start min-w-125px'>Rent Value</th>
                <th className='text-start min-w-125px'>Security Deposit</th>
                <th className='text-start min-w-125px'>Other Payments</th>
                <th className='text-start min-w-125px'>Paid</th>
                <th className='text-start min-w-125px'>Upcoming</th>
                <th className='text-start min-w-125px'>Overdue</th>
                <th className='text-start min-w-200px'>Tenant Rating</th>
              </tr>
            </thead>

            <tbody className='fw-semibold head-text payment-records'>
              {propData?.length > 0 ? propData?.map((v: any, ind: any) => {
                return (
                  <>
                    <tr>
                      <td className='text-start'> {v?.contractNo ? v?.contractNo : '-'}</td>
                      <td className='text-start'>
                        {' '}
                        <div
                          className={
                            v?.tenancyStatus == 0
                              ? 'status booked-bar me-5 status-w-120'
                              : v?.tenancyStatus == 1
                              ? 'status active-bar me-5 status-w-120'
                              : v?.tenancyStatus == 2
                              ? 'status expiring-bar me-5 status-w-120'
                              : v?.tenancyStatus == 3
                              ? 'status expired-bar me-5 status-w-120'
                              : 'status  no-st-bar me-5 status-w-120'
                          }
                        >
                          {v?.tenancyStatus == 0
                            ? 'Booked'
                            : v?.tenancyStatus == 1
                            ? 'Active'
                            : v?.tenancyStatus == 2
                            ? 'Expiring'
                            : v?.tenancyStatus == 3
                            ? 'Expired'
                            : '-'}
                        </div>
                      </td>
                      <td className='text-start'>
                        {/* {v?.tenant?.[0]?.firstName || v?.tenant?.[0]?.lastName
                          ? `${v?.tenant?.[0]?.firstName} ${v?.tenant?.[0]?.lastName}`
                          : '-'} */}
                          {v?.tenantName ? v?.tenantName : '-'}
                      </td>
                      <td className='text-start'>
                        {v?.source == 0 ? 'Agent' : v?.source == 1 ? 'Direct' : '-'}
                      </td>
                      <td className='text-start'>
                        {v?.start_date
                          ? moment(v?.start_date).format('DD/MM/YYYY')
                          : '-'}{' '}
                        {' - '}
                        {v?.end_date
                          ? moment(v?.end_date).format('DD/MM/YYYY')
                          : '-'}
                      </td>

                      <td className='text-start'>
                        {' '}
                        <div
                          className={
                            v?.renewalStatus == 0
                              ? 'renew-status create-st'
                              : v?.renewalStatus == 1 && v?.tenant_request?.[0]?.requestorType == 0
                              ? 'renew-status requested-st'
                              : v?.renewalStatus == 1 && v?.tenant_request?.[0]?.requestorType == 1
                              ? 'renew-status requested-st'
                              : v?.renewalStatus == 2
                              ? 'renew-status renewed-st '
                              : v?.renewalStatus == 3 ||
                                v?.renewalStatus == 4 ||
                                v?.renewalStatus == 5
                              ? 'renew-status cancelled-st'
                              : v?.renewalStatus == 6 && v?.tenant_request?.[0]?.requestorType == 0
                              ? 'renew-status create-st'
                              : v?.renewalStatus == 6 && v?.tenant_request?.[0]?.requestorType == 1
                              ? 'renew-status create-st'
                              : v?.renewalStatus == 7
                              ? 'renew-status cancelled-st'
                              : v?.renewalStatus == 8 ||
                                v?.renewalStatus == 9 ||
                                v?.renewalStatus == 10
                              ? 'renew-status cancelled-st'
                              : 'renew-status not-saved'
                          }
                        >
                          {v?.renewalStatus == 0
                            ? 'Not Renewed'
                            : v?.renewalStatus == 1 && v?.tenant_request?.[0]?.requestorType == 0
                            ? 'Under Renewal'
                            : v?.renewalStatus == 1 && v?.tenant_request?.[0]?.requestorType == 1
                            ? 'Under Renewal'
                            : v?.renewalStatus == 2
                            ? 'Renewed'
                            : v?.renewalStatus == 3 ||
                              v?.renewalStatus == 4 ||
                              v?.renewalStatus == 5
                            ? 'Renewal Cancelled'
                            : v?.renewalStatus == 6 && v?.tenant_request?.[0]?.requestorType == 0
                            ? 'Termination Requested'
                            : v?.renewalStatus == 6 && v?.tenant_request?.[0]?.requestorType == 1
                            ? 'Termination Submitted'
                            : v?.renewalStatus == 7
                            ? 'Terminated'
                            : v?.renewalStatus == 8 ||
                              v?.renewalStatus == 9 ||
                              v?.renewalStatus == 10
                            ? 'Termination Cancelled' //Termination Cancelled
                            : '-'}
                        </div>
                      </td>

                      <td className='text-start'>
                        {'AED '}
                        {v?.rentValue
                          ? formatAmount(v?.rentValue)
                          : 0}
                      </td>
                      <td className='text-start'>
                        {'AED '}
                        {v?.securityDeposit
                          ? formatAmount(v?.securityDeposit)
                          : 0}
                      </td>

                      <td className='text-start '>
                        {'AED '}
                        {v?.otherPayments
                          ? formatAmount(v?.otherPayments)
                          : 0}
                      </td>
                      <td className='text-start '>
                        {'AED '}
                        {v?.paid
                          ? formatAmount(v?.paid)
                          : 0}
                      </td>
                      <td className='text-start '>
                        {'AED '}
                        {v?.upcoming
                          ? formatAmount(v?.upcoming)
                          : 0}
                      </td>

                      <td className='text-start '>
                        {'AED '}
                        {v?.overdue
                          ? formatAmount(v?.overdue)
                          : 0}
                      </td>
                      <td className='text-start '>
                        <div className='d-flex align-items-center '>
                          <img src={starYellow} height={18} width={18} className='me-1' />
                          {v?.tenantRating != undefined ? v?.tenantRating : '-'}
                        </div>
                      </td>
                    </tr>
                  </>
                )
              }) : (
                <>
                <tr>
                  <td colSpan={10} className='text-center'>
                    <img src={noData} alt='' width={350} />
                  </td>
                </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default LeasesOfProperty

import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {FormProvider, useForm, Controller} from 'react-hook-form'
import Switch from 'react-switch'
import Swal from 'sweetalert2'
import LatePaymentInput from './LatePaymentInput'

import feeTypeIcon from '../../../../img/feeTypeIcon.png'
import deleteIcon from '../../../../img/delete-White.png'
import vatPercentIcon from '../../../../img/vatPercentIcon.png'
import frequencyIcon from '../../../../img/frequencyIcon.png'
import daysDueIcon from '../../../../img/daysDue.png'
import dollarIcon from '../../../../img/dollarBlackIcon.png'
import percentIcon from '../../../../img/percentBlackIcon.png'
import editPen from '../../../../img/pencil.png'
import {capitalizeFirstLetter, formatAmount} from '../../../../Utilities/utils'
import {MultiSelect} from 'react-multi-select-component'
import {ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import moment from 'moment'

interface ButtonProps {
  listItemId: string
  setMode: any
  mode: string
  show: boolean
  handleClose: any
  isEdit?: boolean
}

interface LATEPAYMENTFIELDSI {
  name: string
  description: string
  activityStatus: number
  dayAfterPaymetDue: string
  // frequency: string
  // feeType: string
  VATPercentage: string
  fixedAmount: string
  percentageAmount: string
}

const AddLatePaymentModel = ({
  show,
  handleClose,
  isEdit,
  mode,
  setMode,
  listItemId,
}: ButtonProps) => {
  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   formState: {errors},
  // } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const methods = useForm()

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = methods

  const [latePaymentFields, setLatePaymentFields] = useState<LATEPAYMENTFIELDSI>({
    name: '',
    description: '',
    activityStatus: 1,
    dayAfterPaymetDue: '',
    VATPercentage: '',
    fixedAmount: '',
    percentageAmount: '',
  })

  const [frequencyOptions, setFrequencyOptions] = useState<any>([
    {label: 'Once', value: 0},
    {label: 'Daily', value: 1},
    {label: 'Weekly', value: 2},
    {label: 'Monthly', value: 3},
  ])
  const [selectedFrequency, setSelectedFrequency] = useState<any>([])

  const [feeOptions, setFeeOptions] = useState<any>([
    {label: 'Fixed Amount', value: 0},
    {label: '% of item total', value: 1},
  ])
  const [selectedFee, setSelectedFee] = useState<any>([])
  const [isFilledRequiredFields, setIsFilledRequiredFields] = useState<any>(true)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [userDetails, setUserDetails] = useState<any>()
  const [isChangeHappened, setIsChangeHappened] = useState<any>(false)

  const setFrequency = (frequencyValue: number) => {
    switch (frequencyValue) {
      case 0:
        return frequencyOptions?.[0]
      case 1:
        return frequencyOptions?.[1]
      case 2:
        return frequencyOptions?.[2]
      case 3:
        return frequencyOptions?.[3]
      default:
        return {}
    }
  }
  const setFeeType = (feeValue: number) => {
    switch (feeValue) {
      case 0:
        return feeOptions?.[0]
      case 1:
        return feeOptions?.[1]
      default:
        return {}
    }
  }

  const submitHandler = async () => {
    let isValid: any = true
    isValid = await trigger()

    if (isValid) {
      const body = {
        name: latePaymentFields?.name,
        description: latePaymentFields?.description,
        activityStatus: latePaymentFields?.activityStatus,
        dayAfterPaymentDue: Number(latePaymentFields?.dayAfterPaymetDue),
        frequency: Number(selectedFrequency?.[0]?.value),
        feeType: Number(selectedFee?.[0]?.value),
        VATPercentage: Number(latePaymentFields?.VATPercentage),
        fixedAmount: selectedFee?.[0]?.value === 0 ? Number(latePaymentFields?.fixedAmount) : null,
        percentageAmount: selectedFee?.[0]?.value === 1 ? Number(latePaymentFields?.percentageAmount) : null,
      }

      ApiPost(`corporate/late_payment_item`, body)
        .then((res) => {
          handleClose()
          SuccessToast('Late payment fee added successfully!')
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
        })
    }
  }

  const updateHandler = async () => {
    let isValid: any = true
    isValid = await trigger()

    if (isValid) {
      const body = {
        id: listItemId,
        name: latePaymentFields?.name,
        description: latePaymentFields?.description,
        activityStatus: latePaymentFields?.activityStatus,
        dayAfterPaymentDue: Number(latePaymentFields?.dayAfterPaymetDue),
        frequency: Number(selectedFrequency?.[0]?.value),
        feeType: Number(selectedFee?.[0]?.value),
        VATPercentage: Number(latePaymentFields?.VATPercentage),
        fixedAmount: selectedFee?.[0]?.value === 0 ? Number(latePaymentFields?.fixedAmount) : null,
        percentageAmount: selectedFee?.[0]?.value === 1 ? Number(latePaymentFields?.percentageAmount) : null,
      }

      ApiPut(`corporate/late_payment_item`, body)
        .then((res) => {
          handleClose()
          SuccessToast('Late payment fee updated successfully!')
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
        })
    }
  }

  const getLatePaymentItem = async () => {
    setIsLoading(true)

    await ApiGet(`corporate/late_payment_item/${listItemId}`)
      .then((res) => {
        setValue('frequency', res?.data?.data?.frequency)
        setValue('feeType', res?.data?.data?.feeType)

        const frequency = setFrequency(res?.data?.data?.frequency)
        setSelectedFrequency([frequency])

        const feeType = setFeeType(res?.data?.data?.feeType)
        setSelectedFee([feeType])
        setUserDetails(res?.data?.data)
        setLatePaymentFields({
          name: res?.data?.data?.name,
          activityStatus: res?.data?.data?.activityStatus,
          dayAfterPaymetDue: String(res?.data?.data?.dayAfterPaymentDue),
          description: res?.data?.data?.description,
          fixedAmount: String(res?.data?.data?.fixedAmount),
          percentageAmount: String(res?.data?.data?.percentageAmount),
          VATPercentage: String(res?.data?.data?.VATPercentage),
        })

        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteHandler = () => {
    setIsLoading(true)
    const body = {
      ids: [listItemId],
    }
    ApiPost(`corporate/late_payment_item/delete`, body)
      .then((res: any) => {
        handleClose()
        SuccessToast('Late Payment Fee Deleted')
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const checkIsFilledFields = useCallback(() => {
    const {
      description,
      activityStatus,
      name,
      VATPercentage,
      dayAfterPaymetDue,
      fixedAmount,
      percentageAmount,
    } = latePaymentFields

    if (
      name === '' ||
      dayAfterPaymetDue === '' ||
      VATPercentage === '' ||
      selectedFee?.[0]?.value === 0
        ? fixedAmount === ''
        : percentageAmount === ''
    ) {
      return true
    } else {
      return false
    }
  }, [latePaymentFields, selectedFee, selectedFrequency])

  useEffect(() => {
    if ((mode === 'view' || mode === 'edit') && listItemId !== '') {
      // get data
      getLatePaymentItem()
    }
  }, [mode, listItemId])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-610px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <div
            className='row ms-7 pe-6 py-5 m-0 bg-white bg-dark-modal' 
            style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
          >
            <div
              className={`col-12 ${
                mode === 'create' || mode === 'edit' ? 'px-0' : 'px-6'
              }`}
            >
              <div className='d-flex'>
                <h2 className='head-text mb-0 flex-grow-1'>
                  {mode === 'create' ? (
                    <b>Create a Late Payment Fee</b>
                  ) : mode === 'view' ? (
                    <b>Late Payment Fee</b>
                  ) : mode === 'edit' ? (
                    <b>Edit Late Payment Fee</b>
                  ) : (
                    <b>-</b>
                  )}
                </h2>

                <label
                  className='head-text fs-5 cursor-pointer'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
          </div>

          <FormProvider {...methods}>
            <form>
              {mode === 'create' || mode === 'edit' ? (
                <div className='row ms-7 pe-6 m-0'>
                  <div className='col-12 px-0'>
                    <p className='my-2 fw-bold light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                      Late payment fees will be applied on overdue payments based on the
                      due date selected in the payment or invoice and will continue to
                      generate until the payment or invoice is settled.
                    </p>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className='row ms-7 pe-6 m-0'>
                {mode === 'create' || mode === 'edit' ? (
                  <div className='col-12 px-0'>
                    {/* NAME */}
                    <div className='d-flex align-items-start justify-content-between'>
                      <div style={{width: '60%'}}>
                        <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                          <h4 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                            <label
                              className={
                                mode === 'create' || mode === 'edit' ? 'required' : ''
                              }
                            >
                              {' '}
                              Name{' '}
                            </label>
                          </h4>

                          <h4 style={{width: '100%'}} className='my-2'>
                            <input
                              disabled={isLoading}
                              {...register('name', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest latePaymentFee'
                              style={{backgroundColor: '#fff',color:'#333333'}}
                              autoComplete='off'
                              placeholder='Enter a name for this late payment fee...'
                              name='name'
                              value={latePaymentFields?.name}
                              onChange={(e) => {
                                const captilizeWord = capitalizeFirstLetter(
                                  e.target.value?.trimStart()
                                )
                                setLatePaymentFields({
                                  ...latePaymentFields,
                                  [e.target.name]: captilizeWord,
                                })

                                if (captilizeWord.trim().length === 0) {
                                  setValue('name', captilizeWord, {
                                    shouldValidate: true,
                                  })
                                } else {
                                  setValue('name', captilizeWord, {
                                    shouldValidate: true,
                                  })
                                }

                                setTimeout(() => {
                                  setIsChangeHappened(Date.now())
                                }, 500)
                              }}
                            />

                            {errors.name && (
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0 mt-1'
                              >
                                This field is required
                              </p>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='col-12 px-6'>
                    {/* NAME */}
                    <div className='d-flex align-items-start justify-content-between'>
                      <div style={{width: '60%'}}>
                        <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                          <h4 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                            <label
                              className={
                                mode === 'create' || mode === 'edit' ? 'required' : ''
                              }
                            >
                              {' '}
                              Name{' '}
                            </label>
                          </h4>

                          <h4 className='mb-0 mt-3 light-dark-theme-color'>
                            <b>
                              {latePaymentFields?.name ? latePaymentFields?.name : '-'}
                            </b>
                          </h4>
                        </div>
                      </div>
                      <div style={{width: '40%'}}>
                        <div className='d-flex flex-column my-2 mb-4 align-items-end'>
                          <h4
                            className='my-2 text-end white-dark-theme-color'
                            style={{color: 'black', width: '100%', marginRight: '70px'}}
                          >
                            <label> Status </label>
                          </h4>
                          <div className='d-flex  align-items-center '>
                            <Switch
                              onChange={(e) => {}}
                              handleDiameter={18}
                              height={22}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={
                                latePaymentFields?.activityStatus === 1 ? true : false
                              }
                              onColor='#00cf99'
                              offColor='#b0bec5'
                            />
                            <h5 className=' m-0 head-text ms-3'>
                              {latePaymentFields?.activityStatus === 1
                                ? 'Enabled'
                                : 'Disabled'}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* notes */}
              <div className='row ms-7 pe-6 m-0'>
                {mode === 'create' || mode === 'edit' ? (
                  <div className='col-12 px-0'>
                    <h4 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> Description </label>
                    </h4>
                    <textarea
                      disabled={isLoading}
                      placeholder='Enter a description for this late payment fee…'
                      value={latePaymentFields?.description}
                      name='description'
                      onChange={(e) => {
                        const captilizeWord = capitalizeFirstLetter(
                          e.target.value?.trimStart()
                        )
                        setLatePaymentFields({
                          ...latePaymentFields,
                          [e.target.name]: captilizeWord,
                        })

                        setTimeout(() => {
                          setIsChangeHappened(Date.now())
                        }, 500)
                      }}
                      className='form-control form-control-solid mytest bg-white latePaymentFeeDescription'
                      style={{color:'#333333'}}
                    />
                  </div>
                ) : (
                  <div className='col-12 px-6'>
                    <div className='row m-0 px-0 d-flex align-items-end'>
                      <div className='col-8 px-0'>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                          <label> Description </label>
                        </h4>

                        <h4 className=' mb-0 mt-3 light-dark-theme-color'>
                          <b>
                            {latePaymentFields?.description
                              ? latePaymentFields?.description
                              : '-'}
                          </b>
                        </h4>
                      </div>
                      <div className='col-4 px-0'>
                        <button
                          disabled={isLoading}
                          type='button'
                          className='btn btn-sm fw-bold  px-2 green-hollow-btn renewed-st ms-auto'
                          style={{width: '100px'}}
                          onClick={() => {
                            // handleAddExpenseItems()
                            setMode('edit')
                          }}
                        >
                          <img
                            src={editPen}
                            height={18}
                            width={18}
                            className='ms-2 me-5'
                          />{' '}
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className='d-flex ms-7 pe-6 align-items-center mt-3 pb-5 mb-5  mx-3'
                style={{borderBottom: '0.1rem solid #bec3cb'}}
              ></div>

              {mode === 'create' || mode === 'edit' ? (
                <div className='row ms-7 pe-6 m-0'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> Occurrence </label>
                    </h3>

                    <p className='my-2 fw-bold light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                      Set when and how frequent this late payment fee will be applied:
                    </p>
                  </div>
                </div>
              ) : (
                <div className='row ms-7 pe-6 px-6 m-0'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> Occurrence </label>
                    </h3>
                  </div>
                </div>
              )}

              <div
                className='row ms-7 pe-6 m-0 pt-3 '
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {mode === 'create' || mode === 'edit' ? (
                  <>
                    <div className='col-6 px-0'>
                      <LatePaymentInput
                        isDisabled={isLoading}
                        iconSize={30}
                        required
                        placeholderMargin='64px'
                        type='number'
                        name='dayAfterPaymetDue'
                        value={latePaymentFields?.dayAfterPaymetDue}
                        label='Days after a payment is due'
                        firstPlaceholder='-'
                        secondPlaceHolder='Days'
                        icon={daysDueIcon}
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...latePaymentFields}
                          updateLatePaymentFields.dayAfterPaymetDue = newValue
                          setLatePaymentFields(updateLatePaymentFields)

                          setTimeout(() => {
                            setIsChangeHappened(Date.now())
                          }, 500)
                        }}
                      />
                    </div>
                    <div className='col-6 px-0'>
                      <div className='label-container  ms-12'>
                        <img src={frequencyIcon} height={30} width={30} />
                        <label className={`input-label required white-dark-theme-color`}>{'Frequency'}</label>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <span className='test latePayment jobs'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 me-5   ${
                                selectedFrequency.length === 0
                                  ? 'no-value select '
                                  : 'has-value'
                              }`}
                            >
                              <Controller
                                name='frequency'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <MultiSelect
                                    {...field}
                                    disabled={isLoading}
                                    options={frequencyOptions}
                                    value={selectedFrequency}
                                    onChange={(e: any) => {
                                      let newVal: any = e
                                      if (e?.length > 1) newVal = e.slice(1)
                                      setSelectedFrequency(newVal)

                                      if (e?.length === 0) {
                                        setValue('frequency', '', {
                                          shouldValidate: true,
                                        })
                                      } else {
                                        setValue('frequency', newVal, {
                                          shouldValidate: true,
                                        })
                                      }

                                      setTimeout(() => {
                                        setIsChangeHappened(Date.now())
                                      }, 500)
                                    }}
                                    labelledBy=''
                                    overrideStrings={{
                                      allItemsAreSelected: '',
                                    }}
                                    hasSelectAll={false}
                                    closeOnChangedValue={true}
                                  />
                                )}
                              />
                              <h4>
                                {errors.frequency && (
                                  <p
                                    style={{
                                      color: 'var(--kt-danger)',
                                      gridColumn: '2/2',
                                    }}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </h4>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-6 px-6'>
                      <div className='label-container'>
                        <img src={frequencyIcon} height={30} width={30} />
                        <label className={`input-label white-dark-theme-color`}>
                          {'Days after a payment is due'}
                        </label>
                      </div>
                      <h4 className=' mb-0 mt-3 light-dark-theme-color' style={{marginLeft: '47px'}}>
                        <b>
                          {formatAmount(Number(latePaymentFields?.dayAfterPaymetDue))}
                          {/* {latePaymentFields?.dayAfterPaymetDue
                            ? latePaymentFields?.dayAfterPaymetDue
                            : '-'} */}
                        </b>
                      </h4>
                    </div>

                    <div className='col-6 px-6'>
                      <div className='label-container  ms-12'>
                        <img src={frequencyIcon} height={30} width={30} />
                        <label
                          className={`input-label white-dark-theme-color ${mode === 'view' ? '' : 'required'}`}
                        >
                          {'Frequency'}
                        </label>
                      </div>
                      <h4 className='mb-0 mt-3 light-dark-theme-color' style={{marginLeft: '82px'}}>
                        <b>
                          {selectedFrequency?.length > 0
                            ? selectedFrequency?.[0]?.label
                            : '-'}
                        </b>
                      </h4>
                    </div>
                  </>
                )}
              </div>

              {mode === 'create' || mode === 'edit' ? (
                <div className='row ms-7 pe-6 m-0 mt-7'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> Fee </label>
                    </h3>

                    <p className='my-2 fw-bold light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                      Set the amount you want to charge when this late payment fee
                      applies:
                    </p>
                  </div>
                </div>
              ) : (
                <div className='row ms-7 pe-6 px-6 m-0 mt-7'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> Fee </label>
                    </h3>
                  </div>
                </div>
              )}

              <div className='row ms-7 pe-6 m-0 mt-3'>
                {mode === 'create' || mode === 'edit' ? (
                  <>
                    <div className='col-6 px-0'>
                      <div className='label-container'>
                        <img src={feeTypeIcon} height={30} width={30} />
                        <label
                          className='input-label required white-dark-theme-color'
                          style={{marginLeft: '13px'}}
                        >
                          {'Fee Type'}
                        </label>
                      </div>

                      <div className='d-flex'>
                        <span className='test latePayment jobs'>
                          <div className='property-test d-none-multi-checbox'>
                            <div
                              className={`multi-select-container jobs-property ms-0 me-5   ${
                                selectedFee.length === 0
                                  ? 'no-value select '
                                  : 'has-value'
                              }`}
                            >
                              <Controller
                                name='feeType'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <MultiSelect
                                    {...field}
                                    disabled={isLoading}
                                    options={feeOptions}
                                    value={selectedFee}
                                    onChange={(e: any) => {
                                      let newVal: any = e
                                      if (e?.length > 1) newVal = e.slice(1)
                                      setSelectedFee(newVal)

                                      if (e?.length === 0) {
                                        setValue('feeType', '', {
                                          shouldValidate: true,
                                        })
                                      } else {
                                        setValue('feeType', newVal, {
                                          shouldValidate: true,
                                        })
                                      }

                                      const updateLatePaymentFeilds = {...latePaymentFields}
                                      updateLatePaymentFeilds.fixedAmount = ''
                                      updateLatePaymentFeilds.percentageAmount = ''
                                      reset({fixedAmount:'',percentageAmount:''})

                                      setTimeout(() => {
                                        setIsChangeHappened(Date.now())
                                      }, 500)
                                    }}
                                    labelledBy=''
                                    overrideStrings={{
                                      allItemsAreSelected: '',
                                    }}
                                    hasSelectAll={false}
                                    closeOnChangedValue={true}
                                    // disabled={requestData?.status == 1}
                                  />
                                )}
                              />
                              <h4>
                                {errors.feeType && (
                                  <p
                                    style={{
                                      color: 'var(--kt-danger)',
                                      gridColumn: '2/2',
                                    }}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </h4>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className='col-6'>
                      {selectedFee?.[0]?.value === 0 ? (
                        <div className='row px-0  ms-6'>
                          <LatePaymentInput
                            isDisabled={isLoading}
                            iconSize={30}
                            className='ms-1'
                            placeholderMargin='20px'
                            required
                            icon={dollarIcon}
                            type='number'
                            name='fixedAmount'
                            value={latePaymentFields?.fixedAmount}
                            label='Fee'
                            firstPlaceholder='Enter Amount'
                            secondPlaceHolder='AED'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...latePaymentFields}
                              updateLatePaymentFields.fixedAmount = newValue
                              setLatePaymentFields(updateLatePaymentFields)

                              setTimeout(() => {
                                setIsChangeHappened(Date.now())
                              }, 500)
                            }}
                          />
                        </div>
                      ) : selectedFee?.[0]?.value === 1 ? (
                        <div className='row px-0  ms-6'>
                          <LatePaymentInput
                            isDisabled={isLoading}
                            iconSize={30}
                            className='ms-1'
                            placeholderMargin='20px'
                            required
                            icon={percentIcon}
                            type='number'
                            name='percentageAmount'
                            value={latePaymentFields?.percentageAmount}
                            label='Fee'
                            firstPlaceholder='Enter Percentage'
                            secondPlaceHolder='%'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...latePaymentFields}
                              updateLatePaymentFields.percentageAmount = newValue
                              setLatePaymentFields(updateLatePaymentFields)

                              setTimeout(() => {
                                setIsChangeHappened(Date.now())
                              }, 500)
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-6 px-6'>
                      <div className='label-container'>
                        <img src={feeTypeIcon} height={30} width={30} />
                        <label
                          className='input-label required white-dark-theme-color'
                          style={{marginLeft: '13px'}}
                        >
                          {'Fee Type'}
                        </label>
                      </div>
                      <h4 className='mb-0 mt-3 light-dark-theme-color' style={{marginLeft: '45px'}}>
                        <b>{selectedFee?.length > 0 ? selectedFee?.[0]?.label : '-'}</b>
                      </h4>
                    </div>
                    <div className='col-6 px-6'>
                      <div className='label-container ms-12'>
                        <img
                          src={selectedFee?.[0]?.value === 0 ? dollarIcon : percentIcon}
                          height={30}
                          width={30}
                        />
                        <label className={`input-label white-dark-theme-color`}>Fee</label>
                      </div>
                      <h4 className='mb-0 mt-3 light-dark-theme-color' style={{marginLeft: '82px'}}>
                        <b>
                          {selectedFee?.[0]?.value === 0
                            ? `AED ${formatAmount(
                                Number(latePaymentFields?.fixedAmount)
                              )}`
                            : selectedFee?.[0]?.value === 1
                            ? `${formatAmount(
                                Number(latePaymentFields?.percentageAmount)
                              )} %`
                            : '-'}

                          {/* {selectedFee?.[0]?.value === 0
                            ? `${latePaymentFields?.fixedAmount}`
                            : selectedFee?.[0]?.value === 1
                            ? `${latePaymentFields?.percentageAmount} %`
                            : '-'} */}
                        </b>
                      </h4>
                    </div>
                  </>
                )}
              </div>

              {mode === 'create' || mode === 'edit' ? (
                <div className='row ms-7 pe-6 m-0 mt-7'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> VAT </label>
                    </h3>
                    <p className='my-2 fw-bold light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                      Set the percentage of tax you want to apply on the amount charged
                      from this late payment fee:
                    </p>
                  </div>
                </div>
              ) : (
                <div className='row ms-7 pe-6 px-6 m-0 mt-7'>
                  <div className='col-12 px-0'>
                    <h3 className='my-2 white-dark-theme-color' style={{color: 'black', width: '100%'}}>
                      <label> VAT </label>
                    </h3>
                  </div>
                </div>
              )}

              <div className='row ms-7 pe-6 m-0 mt-7'>
                {mode === 'create' || mode === 'edit' ? (
                  <div className='col-6 px-0'>
                    <LatePaymentInput
                      isDisabled={isLoading}
                      iconSize={30}
                      required
                      placeholderMargin='64px'
                      type='number'
                      name='VATPercentage'
                      value={latePaymentFields?.VATPercentage}
                      label='VAT %'
                      firstPlaceholder='-'
                      secondPlaceHolder='%'
                      icon={vatPercentIcon}
                      onChange={(newValue: any) => {
                        const updateLatePaymentFields = {...latePaymentFields}
                        updateLatePaymentFields.VATPercentage = newValue
                        setLatePaymentFields(updateLatePaymentFields)

                        setTimeout(() => {
                          setIsChangeHappened(Date.now())
                        }, 500)
                      }}
                    />
                  </div>
                ) : (
                  <div className='col-6 px-6'>
                    <div className='label-container'>
                      <img src={feeTypeIcon} height={30} width={30} />
                      <label className='input-label white-dark-theme-color' style={{marginLeft: '13px'}}>
                        {'VAT %'}
                      </label>
                    </div>
                    <h4 className='mb-0 mt-3 light-dark-theme-color' style={{marginLeft: '45px'}}>
                      <b>
                        {`${formatAmount(Number(latePaymentFields?.VATPercentage))} %`}
                        {/* {latePaymentFields?.VATPercentage
                          ? `${latePaymentFields?.VATPercentage} %`
                          : '-'} */}
                      </b>
                    </h4>
                  </div>
                )}
              </div>
              {mode === 'view' && (
                <div
                  className='d-flex ms-7 pe-6 align-items-center mt-3 pb-5 mb-5 mx-3'
                  style={{borderBottom: '0.1rem solid #bec3cb'}}
                ></div>
              )}
              {mode === 'create' ? (
                <div className='row m-0 mt-10 mb-7 pb-10'>
                  <div className='col-5 mx-auto'>
                    <button
                      disabled={checkIsFilledFields()}
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center w-100'
                      onClick={submitHandler}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : mode === 'view' ? (
                <div className='row m-0 mt-10  pb-10'>
                  <div className='label-container'>
                    <label className='input-label white-dark-theme-color' style={{marginLeft: '26px'}}>
                      {'Created'}
                    </label>
                  </div>
                  <div className='label-container'>
                    <h4 style={{color: 'black', width: '100%', marginLeft: '26px'}} className="white-dark-theme-color">
                      <label>
                        {' '}
                        {`${userDetails?.createdByUser?.firstName} ${userDetails?.createdByUser?.lastName} -`}
                      </label>
                      <label className='d-block white-dark-theme-color'>
                        {userDetails?.createdAt
                          ? `${moment(userDetails?.createdAt).format(
                              'DD.MM.YYYY - hh:mm  a'
                            )}`
                          : '-'}
                      </label>
                    </h4>
                  </div>

                  {userDetails?.updatedByUser && (
                    <div className='label-container'>
                      <label className='input-label white-dark-theme-color' style={{marginLeft: '26px'}}>
                        {'Last Updated'}
                      </label>
                    </div>
                  )}
                  {userDetails?.updatedByUser ? (
                    <div className='label-container'>
                      <h4 style={{color: 'black', width: '100%', marginLeft: '26px'}} className="white-dark-theme-color">
                        <label>
                          {' '}
                          {`${userDetails?.updatedByUser?.firstName} ${userDetails?.updatedByUser?.lastName} -`}
                        </label>
                        <label className='d-block white-dark-theme-color'>
                          {userDetails?.updatedAt
                            ? `${moment(userDetails?.updatedAt).format(
                                'DD.MM.YYYY - hh:mm  a'
                              )}`
                            : '-'}
                        </label>
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className='col-5 mx-auto mt-3'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold term-btn px-2 w-100'
                      style={{marginLeft: '26px'}}
                      onClick={() => {
                        Swal.fire({
                          html: ` 
                              <div class='fs-3 pt-4'> <b>Delete Late Payment Items</></div>
                               <div class='fs-5 mt-4'>You are about to delete this late payment fee.</div>
                               <div class='fs-5 mt-4'>Deleting this late payment fee will remove it from all existing payments.</div>
                              <div class='fs-5 mt-4'>All previous charges generated by the late payment fee will not be removed.</div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#d54645',
                          confirmButtonText: 'Delete',
                          showCancelButton: false,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button tst',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup tst',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        }).then((res: any) => {
                          if (res.isConfirmed) {
                            deleteHandler()
                          }
                        })
                      }}
                    >
                      <img
                        src={deleteIcon}
                        height={18}
                        width={18}
                        className='me-6 ms-9'
                      />
                      Delete
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className='row m-0 mt-10 mb-7 pb-10 d-flex flex-column align-items-center '>
                    <div className='col-5 mx-auto'>
                      <button
                        disabled={checkIsFilledFields() || isChangeHappened === false}
                        type='button'
                        className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center mx-auto w-100'
                        onClick={updateHandler}
                      >
                        Update
                      </button>
                    </div>
                    <div className='col-5 mx-auto mt-8'>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold red-hollow-btn px-2 justify-content-center mx-auto'
                        onClick={() => {
                          setMode('view')
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </Modal>
    </>
  )
}

export default AddLatePaymentModel

import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../../../img/NoData1.svg'
import ReactPaginate from 'react-paginate'
import {ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import trashImg from '../../../../../../img/trash.png'
import InvoiceImg from '../../../../../../img/downDoubleArrow.png'
import totalInvoiceImg from '../../../../../../img/totalInvoice.png'
import upcomingExpenseImg from '../../../../../../img/upcoming-expense.png'
import overdueExpenseImg from '../../../../../../img/overdue-expense.png'
import paidExpenseImg from '../../../../../../img/paid-expense.png'
import vatImg from '../../../../../../img/vatIcon.png'
import Filters from '../../Filters'
import {SuccessToast, ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import moment from 'moment'
import InvoiceReceiptPopup from './InvoicesPopup'
import {
  capitalizeFirstLetter,
  formatAmount,
  invertOutstandingAmount,
  getUnitCategory,
} from '../../../../../../Utilities/utils'

function ItemsTab() {
  const [searchPayment, setSearchPayment] = useState<any>('')
  const [searchPayee, setSearchPayee] = useState<any>('')
  const [searchAccount, setSearchAccount] = useState<any>('')
  const [isLoading, setIsLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [modalType, setModalType] = useState<'invoice' | 'receipt'>('invoice')

  const navigate = useNavigate()

  const [payments, setPayments] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)

  useEffect(() => {
    getPaymentsCount()
  }, [])

  useEffect(() => {
    setPage(1)
    getAllPayments(null, 1)
  }, [searchPayment, searchPayee, searchAccount])

  const [incomesCardCount, setIncomesCardCount] = useState<any>()

  const getPaymentsCount = () => {
    ApiPost(`corporate/payment/accounts/get_count`)
      .then((res) => {
        setIncomesCardCount(res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getUnitTypeName = (unitType: any) => {
    const types = [
      'Apartment',
      'Penthouse',
      'Common Area',
      'Town House',
      'Villa',
      'Other',
      'Office',
      'Restaurant',
      'Cafe',
      'Retail',
    ]
    return types[unitType] || 'Unknown'
  }

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [tableData, setTableData] = useState<any>([])

  const [incomeTotalCount, setIncomeTotalCount] = useState<any>(0)

  const getAllPayments = (data: any, pageVal: any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        searchPayment: searchPayment,
        searchPayee: searchPayee,
        searchPaymentAccount: searchAccount,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      values.searchPayment = searchPayment
      values.searchPayee = searchPayee
      values.searchPaymentAccount = searchAccount
      body = values
    }

    ApiPost(`corporate/payment/accounts/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.tenancy_account_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        let values: any = []
        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.tenancy_account_data?.length; i++) {
          values[i] = false
          showValues[i] = false
        }

        setCheckedValues(values)
        setShow(showValues)

        setIncomeTotalCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllPayments(null, event.selected + 1)
  }

  const handleInvoiceClick = (invoices: any) => {
    setModalData(invoices)
    setModalType('invoice')
    setShowModal(true)
  }

  const handleReceiptClick = (receipts: any) => {
    setModalData(receipts)
    setModalType('receipt')
    setShowModal(true)
  }

  useEffect(() => {
    enableScrolling()
  }, [page])

  const enableScrolling = () => {
    document.body.style.overflow = ''
  }

  return (
    <div id='' className=' pt-0 mt-0 no-print'>
      <>
        <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
          {/* Invoices */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Accounts</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={InvoiceImg} height={40} width={40} className='me-3 mt-3' />
              <h1 className='fw-bold ms-auto mt-3'>
                {incomesCardCount ? Number(incomesCardCount?.totalAccounts) : 0}
              </h1>
            </div>
          </div>
          {/* Total */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Total</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={totalInvoiceImg} height={35} width={35} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {incomesCardCount?.totalAmount != 0
                  ? `${incomesCardCount?.totalAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
          {/* Paid */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Paid</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={paidExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {incomesCardCount?.totalPaidAmount != 0
                  ? `${incomesCardCount?.totalPaidAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
          {/* Upcoming */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Upcoming</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={upcomingExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {incomesCardCount?.totalUpcomingAmount != 0
                  ? `${incomesCardCount?.totalUpcomingAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
          {/* Overdue */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Overdue</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={overdueExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {incomesCardCount?.totalOverdueAmount != 0
                  ? `${incomesCardCount?.totalOverdueAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>

          {/* VAT Returns */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>VAT Returns</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img alt='' src={vatImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {incomesCardCount?.totalAmount && incomesCardCount?.totalPaidAmount
                  ? `${(
                      incomesCardCount?.totalAmount - incomesCardCount?.totalPaidAmount
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
        </div>

        <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <label className='head-text' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>
          </div>
          <div className='mb-2 col-md-2 pe-5 property xyz tst'>
            <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
              <InputBase
                placeholder='Item'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchPayment}
                onChange={(e: any) => setSearchPayment(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
            <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
              <InputBase
                placeholder='Payor'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchPayee}
                onChange={(e: any) => setSearchPayee(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
            <div className='d-flex justify-content-end align-items-center'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex '>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>

                      <span className='ms-5 ps-1 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </h4>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  mx-3 red-hollow-btn btn-fit-content'
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Income Payments</></div>
                           <div class='fs-5 mt-4'>You are about to delete the selected income payments.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                          }
                        })
                    }}
                  >
                    <img alt='' src={trashImg} height={20} width={20} className='me-3' /> Delete
                  </button>
                </>
              )}

              <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
                <div
                  className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                  onClick={() => setShowFilter(true)}
                >
                  <svg
                    style={{
                      height: '20px',
                      width: '28px',
                      marginLeft: '3px',
                    }}
                    viewBox='0 0 1024 1024'
                    xmlns='http://www.w3.org/2000/svg'
                    className='me-1'
                  >
                    <path
                      fill='#ffff'
                      d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
          {payments?.length > 0 ? (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0' id='global-table'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Account</th>
                    <th className='min-w-150px '>Total Amount Due</th>
                    <th className='min-w-125px'>Overdue</th>
                    <th className='min-w-100px'>Paid</th>
                    <th className='min-w-125px'>Outstanding</th>
                    <th className='min-w-150px'>Upcoming Payment</th>
                    <th className='min-w-100px'></th>
                    <th className='min-w-150px'>Last Payment</th>
                    <th className='min-w-150px ps-7'></th>
                    <th className='min-w-150px'>Invoices</th>
                    <th className='min-w-150px'>Receipts</th>
                    <th className='min-w-150px'>Contract No</th>
                    <th className='min-w-150px'>Lease Value</th>
                    <th className='min-w-150px'>Development</th>
                    <th className='min-w-150px'>Unit No</th>
                    <th className='min-w-150px'>Unit Category</th>
                    <th className='min-w-150px'>Payment Account</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold head-text'>
                  {' '}
                  {payments.map((pm: any, ind: any) => {
                    return (
                      <>
                        <tr
                          style={
                            show[ind] == true
                              ? {borderBottomStyle: 'none', height: '30px'}
                              : show[ind] == false
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              : pm?.payment_sub_item?.length == 0
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              : {height: '30px'}
                          }
                          className=''
                        >
                          <td className='max-w-150px'>
                            <div className='d-flex'>{capitalizeFirstLetter(pm.tenantName)}</div>
                          </td>
                          <td className='max-w-150px'>
                            AED {formatAmount(pm?.totalAmountDue || 0)}
                          </td>
                          <td style={{color: '#d54645'}}>
                            AED {formatAmount(pm?.totalOverdue || 0)}
                          </td>
                          <td style={{color: '#0d9e91'}}>AED {formatAmount(pm?.totalPaid)}</td>
                          <td>AED {formatAmount(invertOutstandingAmount(pm?.totalOutstanding))}</td>
                          <td style={{color: '#f38513'}}>
                            AED {formatAmount(pm?.upcomingPayment?.totalAmount) || 0}
                          </td>
                          <td>
                            {pm?.upcomingPayment?.dueDate
                              ? moment(pm?.upcomingPayment?.dueDate).format('DD.MM.YYYY')
                              : '-'}
                          </td>
                          <td>AED {formatAmount(pm?.lastPayment?.amountPaid) || 0}</td>
                          <td>
                            {pm?.lastPayment?.datePaid
                              ? moment(pm?.lastPayment?.datePaid).format('DD.MM.YYYY')
                              : '-'}
                          </td>
                          <td
                            className='cursor-pointer text-decoration-underline'
                            onClick={() => handleInvoiceClick(pm.invoices)}
                          >
                            {pm?.invoiceCount}
                          </td>
                          <td
                            className='cursor-pointer text-decoration-underline'
                            onClick={() => handleReceiptClick(pm.receipts)}
                          >
                            {pm.receiptCount}
                          </td>
                          <td>{pm?.contractNo}</td>
                          <td>
                            AED{' '}
                            {parseFloat(pm?.totalOverdue || 0)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            {pm?.community?.length > 0 ? pm?.community?.name : pm?.building?.name}
                          </td>
                          <td>{pm?.unitNo || 0}</td>
                          <td>{getUnitCategory(pm.unitCategory)}</td>
                          <td>
                            {pm?.paymentAccounts?.length > 1
                              ? pm?.paymentAccounts?.length
                              : pm?.paymentAccounts?.length === 1
                              ? pm?.paymentAccounts[0]
                              : '-'}
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Account</th>
                    <th className='min-w-150px '>Total Amount Due</th>
                    <th className='min-w-125px'>Overdue</th>
                    <th className='min-w-100px'>Paid</th>
                    <th className='min-w-125px'>Outstanding</th>
                    <th className='min-w-150px'>Upcoming Payment</th>
                    <th className='min-w-100px'></th>
                    <th className='min-w-150px'>Last Payment</th>
                    <th className='min-w-150px ps-7'></th>
                    <th className='min-w-150px'>Invoices</th>
                    <th className='min-w-150px'>Receipts</th>
                    <th className='min-w-150px'>Contract No</th>
                    <th className='min-w-150px'>Lease Value</th>
                    <th className='min-w-150px'>Development</th>
                    <th className='min-w-150px'>Unit No</th>
                    <th className='min-w-150px'>Unit Category</th>
                    <th className='min-w-150px'>Payment Account</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </>

      <InvoiceReceiptPopup
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={modalData}
        type={modalType}
      />

      {/* Filters */}
      {showFilter && (
        <Filters
          showw={showFilter}
          handleClose={() => setShowFilter(false)}
          updateParent={(data: any, pageVal: any) => {
            getAllPayments(data, 1)
          }}
        />
      )}
    </div>
  )
}
export default ItemsTab

import React, { useState, useEffect } from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import { MultiSelect } from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'


import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, Space } from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../../img/trash.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import { setComefrom } from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from "./AddFloorModel"
import redCross from '../../../../img/remove.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import unitClusterGreenIcon from '../../../../img/unitClusterGreen.png'
import unitClusterWhiteIcon from '../../../../img/unitClusterWhite.png'
import mixedClusterGreenIcon from '../../../../img/mixedClusterGreen.png'
import mixedClusterWhiteIcon from '../../../../img/mixedClusterWhite.png'
import buildingClusterGreenIcon from '../../../../img/buildingClusterGreen.png'
import buildingClusterWhiteIcon from '../../../../img/buildingClusterWhite.png'
import buildingWhiteIcon from '../../../../img/small-building.png'
import buildingGreenIcon from '../../../../img/allBuildingGreen.png'
import communitiesWhiteIcon from '../../../../img/allCommunities.png'
import communitiesGreenIcon from '../../../../img/allCommunitiesGreen.png'

import swal from 'sweetalert2'
import { mixed } from 'yup'

interface ButtonProps {
    unitInputs?: any
    setUnitInput?: any
    handleAddUnitInput: any
    handleRemoveUnitInput: any,
    handleRemoveBackendInput: any,
    index:any,
    setCluster:any,
    allCluster:any,
    setIsCTAHidden:any
}

const EditUnitCluster = ({ handleAddUnitInput, handleRemoveUnitInput,handleRemoveBackendInput, setUnitInput, allCluster,index , setCluster,setIsCTAHidden}: ButtonProps) => {
    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '25px',
        // marginLeft: '10px',
    }
    const inputBaseStyle = {
        flex: 1,
        padding: '2px',
        // backgroundColor: '#fff',
    }

    function capitalizeFirstLetter(word:any) {
        if (!word || typeof word !== 'string') {
            return word;
        }
        const firstLetter = word.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = word.slice(1);
        const capitalizedWord = firstLetterCap + remainingLetters;
        return capitalizedWord;
    }
    return (
        <>
            <div className="row" style={{ marginTop: '50px' }}>
                <div className="col-8 mx-auto text-center mb-2">
                    <h2 className='m-0 head-text'>
                        <b>Unit Clusters</b>
                    </h2>
                </div>
                <div className="col-10 mx-auto text-center">
                    <h4 className='m-0 head-text mb-1'>
                        Set the unit clusters existing within your community
                    </h4>
                    <h4 className='m-0 head-text mb-1'>
                        You will be able to add the properties under each unit cluster once they have been added.
                    </h4>

                    <div className='my-7 d-flex justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-sm fw-bold px-2 green-submit-btn '
                            style={{ width: "fit-content" }}
                            onClick={() => handleAddUnitInput(index)}

                        >
                            <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Unit Cluster
                        </button>
                    </div>
                </div>

                <div className="col-12 mx-auto" style={{ marginTop: '20px' }}>
                    <div className="row d-flex" style={{ rowGap: '10px' }}>
                    {allCluster[index]?.updateUnitCluster?.map((unitInput: any, unitIndex: any) => (
                        <div className="col-4">
                            <div className='card card-flush' style={{ padding: '10px', position: 'relative' }} >
                                {allCluster[index]?.updateUnitCluster?.length >= 1 && <div style={{ position: 'absolute', top: '4px', right: '32px', cursor: 'pointer' }}>
                                    <img src={trashImg} height={14} width={14} onClick={() => handleRemoveBackendInput(index,unitIndex)} />
                                </div>}
                                <div className='me-4'>
                                    <h3 className='m-0 head-text mb-1 ms-1'>
                                        Unit Cluster
                                    </h3>
                                    <div className='wxyz'>
                                        <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                                placeholder='Enter Unit Cluster Name'
                                                style={inputBaseStyle}
                                                inputProps={{ 'aria-label': 'Unit CLuster' }}
                                                value={unitInput?.name}
                                                onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    // const inputValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[index].updateUnitCluster[unitIndex].name = captilizeWord
                                                    setCluster(values)
                                                    setIsCTAHidden(false)
                                                }
                                                }
                                            />
                                        </Paper>

                                    </div>
                                </div>
                            </div>
                        </div>))}
                        {allCluster[index]?.addUnitCluster?.map((unitInput: any, unitIndex: any) => (
                        <div className="col-4">
                            <div className='card card-flush' style={{ padding: '10px', position: 'relative' }} >
                                {allCluster[index]?.addUnitCluster?.length >= 1 && <div style={{ position: 'absolute', top: '4px', right: '32px', cursor: 'pointer' }}>
                                    <img src={redCross} height={14} width={14} onClick={() => handleRemoveUnitInput(index,unitIndex)} />
                                </div>}
                                <div className='me-4'>
                                    <h3 className='m-0 head-text mb-1 ms-1'>
                                        Unit Cluster
                                    </h3>
                                    <div className='wxyz'>
                                        <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                                placeholder='Enter Unit Cluster Name'
                                                style={inputBaseStyle}
                                                inputProps={{ 'aria-label': 'Unit CLuster' }}
                                                value={unitInput?.name}
                                                onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    // const inputValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[index].addUnitCluster[unitIndex].name = captilizeWord
                                                    setCluster(values)
                                                    setIsCTAHidden(false)
                                                }
                                                }
                                            />
                                        </Paper>

                                    </div>
                                </div>
                            </div>
                        </div>))}

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditUnitCluster
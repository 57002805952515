import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import emailTestIcon from '../../../../../../img/emailTestIcon.png'
import atIcon from '../../../../../../img/atIcon.png'
import emailSendIcon from '../../../../../../img/emailLinedIcon.png'
import emailNotSendIcon from '../../../../../../img/remove.png'
import checkMarkGreen from '../../../../../../img/check-mark-green.png'
import {ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import {useParams} from 'react-router-dom'
import {ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import CircularProgress from '@mui/material/CircularProgress'

interface ModelI {
  show: any
  handleClose: any
  emailId: any
}

const SentEmailPopup = ({show, handleClose, emailId}: ModelI) => {
  const {id} = useParams()
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [isBtnDiabled, setBtnDiabled] = useState(false)
  const [isTestInProgress, setIsTestInProgress] = useState(false)
  const [isTestComplete, setIsTestComplete] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [sentEmailTo, setSentEmailTo] = useState('')
  const [reportMessages, setReportMessages] = useState<any>([])

  const validateEmail = (email: any) => {
    // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    const regex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|hotmail\.com|outlook\.com)$/
    return regex.test(email)
  }

  const handleSend = async () => {
    // console.log('email sent')

    const isValidEmail = validateEmail(email)
    if (email && isValidEmail) {
      setIsTestInProgress(true)
      setIsEmailValid(true)
      let body = {
        emailSettingId: `${emailId}`,
        emailTemplateId: '66ba0ee2051d0bfe59e3c6fb',
        email: email,
      }

      await ApiPost('corporate/email_setting/test/outbound', body)
        .then((res) => {
          // setSentEmailTo(res?.data?.data?.emailDebugLogData?.report?.envelope?.to?.[0])

          setEmail('')
          setSuccess(res?.data?.data?.emailDebugLogData?.report?.success)
          setSentEmailTo(res?.data?.data?.emailDebugLogData?.report?.email)
          setReportMessages(res?.data?.data?.emailDebugLogData?.report?.statusList)
          setIsTestInProgress(false)
          setIsEmailSent(true)
        })
        .catch((err) => {
          setIsTestInProgress(false)
          ErrorToast('Test Email sent from connected email address')
          if (err?.message === 'Your account has been suspended!') {
            window.location.pathname = '/auth'
            localStorage.clear()
          }
        })
    } else {
      setIsEmailValid(false)
    }
  }
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-400px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
      >
        <div
          className='modal-body'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '300px',
            maxHeight: '350px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          {isTestInProgress && <div className='layer3'></div>}
          <div className='row m-0'>
            <div className='col-md-12 px-0'>
              <label
                className='head-text fs-5 cursor-pointer d-block text-end'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='row text-center pt-4'>
            <div className='col-12'>
              {!isEmailSent && <img src={emailTestIcon} alt='Email' width={70} />}
              {isEmailSent && success && <img src={emailSendIcon} alt='Email' width={70} />}
              {isEmailSent && !success && <img src={emailNotSendIcon} alt='Email' width={70} />}
            </div>
            <div className='col-12 mt-2'>
              <p
                className='p-0'
                style={{
                  width: '100%',
                  color: '#000000',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}
              >
                {isEmailSent ? 'Test Email has been sent to:' : 'Send a Test Email To:'}
              </p>
            </div>
            {!isEmailSent ? (
              <>
                <div className='col-12'>
                  <div className='d-flex align-items-center justify-content-center' style={{columnGap: '20px'}}>
                    <img src={atIcon} alt='at Icon' width={20} />
                    {/* EMAIL */}
                    <h4 className='my-2 test' style={{width: '60%'}}>
                      <input
                        type='text'
                        className={`form-control form-control-solid mytest ${isEmailValid ? 'inputBorderGreen' : 'inputBorderRed'}`}
                        placeholder='Enter email address'
                        autoComplete='off'
                        style={{backgroundColor: '#fff'}}
                        name='email'
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value.replace(/\s+/g, ''))
                        }}
                      />
                    </h4>
                  </div>

                  <div style={{width: '60%', paddingLeft: '20px', margin: 'auto'}}>
                    {isEmailValid === false && (
                      <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 fs-4'>
                        Please enter a valid Email Address
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='col-12'>
                  <p
                    className='p-0'
                    style={{
                      width: '100%',
                      color: '#000000',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {sentEmailTo ? sentEmailTo : '-'}
                  </p>
                </div>
              </>
            )}
            {/* Diagnostic Report */}
            <div className='col-12 pt-4'>
              {reportMessages?.length > 0 && (
                <>
                  {reportMessages?.map((reportMessage: any) => {
                    return (
                      <div className='row text-start' style={{paddingTop: '4px', paddingBottom: '5px'}}>
                        <div className='col-12'>
                          <div className='d-flex align-items-start justify-content-start ps-4 pe-1'>
                            {reportMessage.success ? (
                              <img src={checkMarkGreen} alt='Report Icon' width={18} className='me-4'/>
                            ) : (
                              <img src={emailNotSendIcon} alt='Report Icon' width={18} className='me-4'/>
                            )}
                            <p
                              className='p-0 m-0'
                              style={{
                                width: '100%',
                                color: '#000000',
                                fontSize: '13px',
                              }}
                            >
                              {reportMessage?.message ? reportMessage?.message + '.' : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            {!isEmailSent && (
              <div className='col-12'>
                <div className='row' style={{paddingTop: '20px', paddingBottom: '0px'}}>
                  <div className='col-4 mx-auto'>
                    <button
                      disabled={isBtnDiabled}
                      onClick={handleSend}
                      type='button'
                      className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                      style={{width: '100%'}}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isTestInProgress && (
          <div
            className='d-flex flex-column justify-content-center align-items-center w-100'
            style={{height: '42vh', zIndex: 200, position: 'absolute'}}
          >
            <CircularProgress style={{color: '#0D9E91'}} />
            <p className='my-2' style={{width: '100%', color: '#0D9E91', fontSize: '13px', textAlign: 'center', fontWeight: 'bold'}}>
              Test In-Progress
            </p>
          </div>
        )}
      </Modal>
    </>
  )
}

export default SentEmailPopup

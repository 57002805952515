import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';

function VendorSuppliersTab() {
    const [formData, setFormData] = useState<any>({})
    const [propertiType, setPropertiType] = useState<any>('Service Providers')
    const navigate = useNavigate()
    const pathName = window.location.pathname;

    const ListNameItem = new Array(10).fill(0);
    const Vendors = new Array(5).fill(0);


    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 min-w-200px'>
                    <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                        Vendor Management
                    </h1>
                </div>
                <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/serviceproviders`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                Service Providers
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/suppliers`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                Suppliers
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='mb-8 min-w-375px d-flex w-auto'>
                    <div
                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                    onClick={() => navigate(`/vendor-management/management-list`)}
                    >
                        Manage Vendors
                    </div>
                </div>
                <div className='overflow-scroll'>
                    <div className='w-100 d-flex'>
                        <Col className='min-w-150px px-5'>
                            <h4>All</h4>
                            <span>##</span>
                        </Col>
                        <Col className='min-w-150px px-5'>
                            <h4>Not Listed</h4>
                            <span>##</span>
                        </Col>
                        {
                            ListNameItem.map(() => {
                                return (
                                    <Col className='min-w-150px px-5'>
                                        <h4>List Name</h4>
                                        <span>##</span>
                                    </Col>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='card-header align-items-center py-5 mt-5 gap-md-2 d-flex'>
                    <h4 className='gray-500'>
                        Total Service Providers:{' '}
                        <span className='' style={{ color: 'black' }}>
                            50
                        </span>
                    </h4>
                </div>
                <div className='card card-flush'>
                    <div className='card-body pt-0 table-responsive mt-5'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-100px'>Vendor Id</th>
                                    <th className='text-center min-w-100px'>Vendor Name</th>
                                    <th className='text-center min-w-100px'>Type</th>
                                    <th className='text-center min-w-70px'>TRN</th>
                                    <th className='text-center min-w-100px'>Trade License</th>
                                    <th className='text-center min-w-100px'>City</th>
                                    <th className='text-center min-w-100px'>Overall Requests</th>
                                    <th className='text-center min-w-100px'>List</th>
                                    <th className='text-center min-w-100px'>Main Contact (Mobile No.)</th>
                                    <th className='text-center min-w-100px'>Main Contact (Email)</th>
                                    <th className='text-center min-w-100px'>Rating</th>
                                    <th className='text-center min-w-100px'>Notifications</th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                {Vendors.map((f, i) => {
                                    return (
                                        <tr>
                                            <td>###</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Service Provider</td>
                                            <td>5427850</td>
                                            <td>1209817</td>
                                            <td>Dubai</td>
                                            <td>1</td>
                                            <td>Electricians</td>
                                            <td>+9710000000</td>
                                            <td>eee@ggg.com</td>
                                            <td>4.5</td>
                                            <td><KTSVG
                                            path='/media/icons/duotune/general/gen007.svg'
                                            className='svg-icon-3 text-primary me-1 ms-2 me-0' />5</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorSuppliersTab;
import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import ownerFilledIconBlue from '../../../../img/owners-profile-blue.png'
import upload from '../../../../img/upload-blue.png'
import trashImg from '../../../../img/trash.png'
import correct from '../../../../img/correct.png'
import folderDocument from '../../../../img/folder-document.svg'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import backArrow from '../../../../img/back-arrow.png'
import pdfIcon from '../../../../img/pdf.svg'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import removeFilled from '../../../../img/remove-filled.png'
import correctFilled from '../../../../img/correct.png'
import folderIcon from '../../../../img/folder.png'
import folderGreenIcon from '../../../../img/folder-green.png'
import pngIcon from '../../../../img/png.png'
import jpgIcon from '../../../../img/jpg-file.png'
import jpegIcon from '../../../../img/jpeg.png'
import docIcon from '../../../../img/doc.svg'
import pptIcon from '../../../../img/ppt.png'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import moment from 'moment'
import Swal from 'sweetalert2'
import {ApiGet, ApiPost, ApiUpload, ApiPut, ApiDelete, ApiGetNoAuth} from '../../../../apiCommon/helpers/API/ApiData'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import axios from 'axios'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import ModalLoader from '../../General/ModalLoader'
import redCross from '../../../../img/remove.png'
import InputField from './InputField'
import { MultiSelect } from 'react-multi-select-component'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const AddOwnerModal = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
      fontSize: '13px !important',
      fontWeight: '400 !important',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [ownerData, setOwnerData] = React.useState<any>({
    // gender: 1,
    DOB: null,
    nationality: [],
    gender:[]
  })

  const [limitFields, setLimitFields] = React.useState<any>({
    firstName:false,
    lastName:false,
    idNo:false,
    passportNo: false
  })
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
  ])
  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(
          res?.data?.data.map((countries: any) => ({
            value: countries?.country,
            label: countries?.country,
          }))
        )
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  const [messageList, setMessageList] = React.useState<any>(false)
  const [propertiType, setPropertiType] = React.useState('Documents')

  /************************* Documents **********************/
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [fileFound, setFileFound] = useState<any>(false)
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  const [folderName, setFolderName] = useState<any>('')
  const [editFile, setEditFile] = useState<any>(-1)
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderFiles, setFolderFiles] = useState<any>()
  const [newName, setNewName] = useState<any>()
  const [index, setIndex] = React.useState(-1)
  const [fileToReplace, setFileToReplace] = useState<any>()
  const [open, setOpen] = React.useState(false)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)
  const [isCTADisabled, setIsCTADisabled] = useState<any>(true)
  const [isFolderName, setIsFolderName] = useState<any>(false)

  const [folders, setFolders] = useState<any>([
    {isSystemGenerated: true, name: 'Passport', fileList: []},
    {isSystemGenerated: true, name: 'Residency', fileList: []},
    {isSystemGenerated: true, name: 'ID', fileList: []},
    {isSystemGenerated: true, name: 'Title Deeds', fileList: []},
  ])
  const [isLimitError,setIsLimitError] = useState<boolean>(false)

  let fileLists_single: any = []
  var replaceFile: any

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName.toLowerCase() === folders[i]?.name.toLowerCase()) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const values = [...folders]
      values[folders?.length] = {
        name: folderName,
        isSystemGenerated: false,
        fileList: [],
      }
      setFolders(values)
      SuccessToast('New Folder has been added successfully!')

      setFolderName('')
      setFolderNameModal(false)

      //   const body = {
      //     name: folderName,
      //     tenantId: `${window.location.pathname.split('/')[2]}`,
      //     fileList: [],
      //   }
      //   ApiPost(`corporate/tenant_folder`, body)
      //     .then((res: any) => {
      //       SuccessToast('New Folder has been added successfully!')
      //       getTenantFolders()
      //       setFolderNameModal(false)
      //     })
      //     .catch((err: any) => {})
    } else {
    }
  }

  const updateFolder = (i:any) => {
    console.log(selectedFolder)
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (newFolderName?.trim()?.toLowerCase() === folders[i]?.name?.toLowerCase() && newFolderName?.trim()?.toLowerCase() !== selectedFolder?.name?.toLowerCase()) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const values = [...folders]
      values[i] = {
        name: newFolderName?.trim(),
        isSystemGenerated: false,
        fileList: selectedFolder?.fileList,
      }
      setFolders(values)
      SuccessToast('Folder has been updated successfully!')

      setNewFolderName('')
      setEditFolderName(-1)
      setSelectedFolder({})

      //   const body = {
      //     name: folderName,
      //     tenantId: `${window.location.pathname.split('/')[2]}`,
      //     fileList: [],
      //   }
      //   ApiPost(`corporate/tenant_folder`, body)
      //     .then((res: any) => {
      //       SuccessToast('New Folder has been added successfully!')
      //       getTenantFolders()
      //       setFolderNameModal(false)
      //     })
      //     .catch((err: any) => {})
    } else {
    }
  }

  useEffect(() => {
    if (folderName?.trim()?.length > 0) {
      setIsFolderName(false)
    }

    if (folderNameModal == false) {
      setFolderName('')
      setIsFolderName(false)
      setIsLimitError(false)
    }

    if(folderName === ''){
      setFileFound(false)
    }

  }, [folderName, folderNameModal])

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    const generateNewFileName = (originalName: any, existingFiles: any) => {
      const nameParts = originalName.split('.')
      const extension = nameParts.pop()
      let baseName = nameParts.join('.')
      let baseNameCaptilize = capitalizeFirstLetter(baseName)

      let counter = 1

      while (existingFiles.some((file: any) => file.fileName?.toLowerCase() === `${baseName}(${counter}).${extension}`)) {
        counter++
      }

      return `${baseNameCaptilize}(${counter}).${extension}`
    }

    if (event.target.files) {
      setShowLoader(true)
      setTotalFiles(event.target.files.length)

      for (let m = 0; m < event.target.files.length; m++) {
        try {
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            console.log('File to replace ' + event.target.files)

            let isFound = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name === folderFiles[i]?.fileName?.toLowerCase()) {
                isFound = true
                break
              }
            }

            if (!isFound) {
              // setOpen(true)

              let formData = new FormData()
              let data = fileLists_single[m]
              let fileURL = URL.createObjectURL(data)
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then((res) => {
                  temp.push({
                    fileName: capitalizeFirstLetter(event?.target?.files[m]?.name),
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  })

                  let values = [...folders]
                  for (let i = 0; i < folders?.length; i++) {
                    if (folders[i]?.name === selectedFolder?.name) {
                      folders[i].fileList = temp
                    }
                  }
                  setUploadProgress(m + 1)
                  setFolders(values)
                  getFolderFiles(selectedFolder)
                })
                .catch((err) => {
                  // console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
            } else {
              // const userChoice = await confirmAction();
              // console.log(userChoice);
              // if (userChoice === 'keep') {
              // setOpen(true)

              let formData = new FormData()
              let data = replaceFile
              // console.log(replaceFile)
              let fileURL = URL.createObjectURL(data)
              data.fileURL = fileURL
              formData.append('image', data)
              console.log('\ntype', replaceFile.type)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then((res) => {
                  let name = Date.now()
                  temp.push({
                    fileName: generateNewFileName(replaceFile.name, temp),
                    fileURL: res?.data?.data?.image,
                    fileSize: replaceFile.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  })

                  let values = [...folders]
                  for (let i = 0; i < folders?.length; i++) {
                    if (folders[i]?.name === selectedFolder?.name) {
                      folders[i].fileList = temp
                    }
                  }
                  setUploadProgress(m + 1)
                  setFolders(values)
                  getFolderFiles(selectedFolder)
                  // setOpen(false)
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
            }
          }
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }

      setTimeout(() => {
        setShowLoader(false)
        setUploadProgress(0) // Reset upload progress
        setTotalFiles(0)
      }, 200)
      event.target.value = null
    } else {
      let temp = [...folderFiles]
      temp.splice(id, 1)
      setFolderFiles(temp)

      let folds = [...folders]
      for (let i = 0; i < folds?.length; i++) {
        if (folds?.[i]?.name === selectedFolder?.name) {
          folds[i].fileList = temp
        }
      }

      setFolders(folds)
    }
  }

  // const getFilename = (file: any, type: any) => {
  //   if (type) return file.split('.')[1]
  //   else return file.split('.')[0]
  // }

  const getFilename = (file: any, type: any) => {
    // console.log(fileUrl)
    let extension: any = file?.split('.').pop()
    // if (type) return `${fileName.split('.')[0]}.${extension}`
    if (type) {
      if(extension?.length > 8) return ''
      return `${extension}`
    }
    else {
    const lastDotIndex = file?.lastIndexOf('.');
    if (lastDotIndex === -1) return file; // No dot found, return full name
    return file.substring(0, lastDotIndex);
    }
  }

  const getFolderFiles = (folder: any) => {
    // ApiGet(`corporate/tenant_folder/${folder?._id}`)
    //   .then((res: any) => {
    setSelectedFolder(folder)
    setViewFolder(true)
    setFolderFiles(folder?.fileList)

    let y: any = []
    for (let i = 0; i < folder?.fileList?.length; i++) {
      y[y.length] = {
        src: Bucket + folder?.fileList?.[i]?.fileURL,
      }
    }
    setSlides(y)
    setTotalImages(folder?.fileList?.length)
    //   })
    //   .catch((err: any) => {})
  }

  const deleteFolder = (ind: any) => {
    const values = [...folders]
    values.splice(ind, 1)
    setFolders(values)
  }

  //
  const handleDownload = async (fileUrl: any,fileName:string) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        'application/msword': 'doc',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.ms-powerpoint': 'ppt',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
        // Add more mappings as needed
      }

      let extension = extensionMap[contentType] || 'file'

      if(contentType === 'application/zip' && fileName){
        const fileNamePart = fileName.split('.')
        const fileExtension = fileNamePart[fileNamePart.length - 1].toLowerCase()
        if(['docx', 'xlsx', 'pptx', 'doc', 'xls', 'ppt'].includes(fileExtension)){
          extension = fileExtension
        }
      }

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const [customErrors, setCustomErrors] = useState<any>({});
  const maxLengths = {
    firstName: 50,
    lastName: 50,
    idNo:50,
    passportNo:50
  };

  const validateFieldLengths = (data:any) => {
    const newErrors : any = {};
    let hasErrors = false;

    for (const [field, maxLength] of Object.entries(maxLengths)) {
      if (data[field] && data[field].length > maxLength) {
        newErrors[field] = true;
        hasErrors = true;
      }
    }

    setCustomErrors(newErrors);
    return hasErrors;
  }

  // add owner
  const addOwner = async (data: any, e: any, key: any) => {
    e.preventDefault()
    const lengthError = validateFieldLengths(data);
    if (lengthError) {
      return;
    }

    const body: any = {
      firstName: ownerData?.firstName,
      lastName: ownerData?.lastName,
      phoneNumber: ownerData?.phoneNumber,
      countryCode: ownerData?.countryCode,
      email: ownerData?.email,
      nationality: ownerData?.nationality?.length > 0 ? ownerData?.nationality?.[0]?.value : null,
      folder_list: folders,
      passportNo: ownerData?.passportNo,
      otherNo: ownerData?.otherNo,
      idNo: ownerData?.idNo,
      gender: ownerData?.gender?.length > 0 ? Number(ownerData?.gender?.[0]?.value) : null,
      // profileImage: profileImg,
    }
   
    if (ownerData?.DOB) {
      body.DOB = ownerData?.DOB
    }
    await ApiPost(`corporate/owner`, body)
      .then((response) => {
        if (key == 1) {
          localStorage.setItem('ownerData', JSON.stringify(ownerData))
          localStorage.setItem('ownerFolders', JSON.stringify(folders))
          SuccessToast('Owner profile created successfully!')
          handleClose(1)
        }
        if (key == 0) {
          SuccessToast('Owner profile created successfully!')
          handleClose(0)
        }
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  const handleSubmitForm = (e: any) => {
    e.preventDefault()
    if (folderName?.trim()?.length > 0 && folderName?.trim()?.length <= 30) {
      addFolder()
    } else if(folderName?.trim()?.length == 0 || folderName == '') {
      setIsFolderName(true)
    }
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  useEffect(() => {
    getCountry()
  }, [])

  useEffect(() => {
    if (
      ownerData?.firstName !== undefined &&
      ownerData?.firstName?.trim() !== '' &&
      ownerData?.lastName !== undefined &&
      ownerData?.lastName?.trim() !== '' &&
      ownerData?.email !== undefined &&
      ownerData?.email?.trim() !== ''
    ) {
      setIsCTADisabled(false)
    } else {
      setIsCTADisabled(true)
    }
  }, [ownerData?.firstName, ownerData?.lastName, ownerData?.email])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [newFolderName, setNewFolderName] = useState<any>('')
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const maxLength = 30

  useEffect(() => {
    if (newFolderName?.trim()?.length > 0) {
      setIsFolderName(false)
    }

    if (editFolderName == -1) {
      setIsFolderName(false)
    }

    if (newFolderName === '') {
      setFileFound(false)
    }
  }, [newFolderName, editFolderName])

  useEffect(() => {
    setIsLimitError(false)
    setIsFolderName(false)
  },[editFolderName])

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose(0)
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div className='row px-7 py-5 m-0 shadow-sm' style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}>
            <div className='col-md-12 px-0'>
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', top: 0, right: 0}}>
                  <label
                    className='head-text fs-5 cursor-pointer blue-dark-text'
                    style={{fontWeight: '700'}}
                    onClick={() => {
                      handleClose(0)
                    }}
                  >
                    X
                  </label>
                </div>
                <h2 className='head-text mb-0 text-center blue-dark-text'>
                  <b>Add Owner</b>
                </h2>
              </div>
            </div>
          </div>

          {/* <form> */}
            <div className='row mx-0 px-0 mt-7'>
              <div className='col-12 px-0'>
                <img src={ownerFilledIconBlue} height={60} width={60} className='m-0 p-0 d-block mx-auto' />
                <h3 className='head-text mb-0 text-center mt-5 mb-5'>
                  <b>Details</b>
                </h3>
              </div>

              <div className='col-12 px-7'>
                <div className='row d-flex justify-content-center' style={{columnGap: '100px'}}>
                  <div style={{width: '40%'}}>
                  {/* FIRST NAME */}
                    <InputField
                      register={register}
                      errors={errors}
                      name="firstName"
                      label="First Name"
                      placeholder="Enter first name"
                      value={ownerData?.firstName}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths.firstName}
                      customError={customErrors?.firstName}
                    />
                    {/* LAST NAME */}
                     <InputField
                      register={register}
                      errors={errors}
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter last name"
                      value={ownerData?.lastName}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths.lastName}
                      customError={customErrors?.lastName}
                    />
                    {/* EMAIL */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='required labl-gry'> Email </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2 test'>
                        <Controller
                          name='email'
                          control={control}
                          rules={{
                            required: 'Email is required',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Invalid email address',
                            },
                          }}
                          render={({field}) => (
                            <input
                              //   {...register('email', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest'
                              placeholder='Enter email address'
                              autoComplete='off'
                              style={{backgroundColor: '#fff'}}
                              name='email'
                              value={ownerData?.email}
                              onChange={(e) => {
                                setValue('email', e.target.value)
                                setOwnerData({
                                  ...ownerData,
                                  [e.target.name]: e.target.value,
                                })
                              }}
                            />
                          )}
                        />

                        {errors.email && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                            Please enter a valid Email Address
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* MOBILE NO */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Mobile No. </label>
                      </h4>

                      <h4 style={{width: '100%', borderRadius: '0.475rem'}} className='my-2 test2 owner'>
                        <Controller
                          name='phoneNumber'
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              //   {...register('phoneNumber', {required: true})}
                              containerClass={ownerData?.countryCode && ownerData?.phoneNumber ? 'isFilled' : ''}
                              placeholder='Enter phone number'
                              value={`${ownerData?.countryCode} ${ownerData?.phoneNumber}`}
                              onChange={(phone: any, e: any) => {
                                let CountryCode: any = e?.dialCode
                                let PhoneNumber: any = phone.split(CountryCode)[1]
                                setOwnerData({
                                  ...ownerData,
                                  ['countryCode']: parseInt(CountryCode),
                                  ['phoneNumber']: PhoneNumber,
                                })

                                setValue('phoneNumber', `${parseInt(CountryCode)}${PhoneNumber}`)
                              }}
                            />
                          )}
                        />

                        {errors.phoneNumber && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                            This field is required
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </h4>
                    </div>
                  </div>
                  <div style={{width: '40%'}}>
                    {/* DOB */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '94%'}}>
                        <label className='labl-gry'> DOB </label>
                      </h4>
                      <h4 className='my-2 add-tenant-date tst owner'>
                        {ownerData?.DOB != null ? (
                          <DatePicker
                            className={`w-100 ${ownerData?.DOB ? 'isFilled' : ''}`}
                            value={moment(ownerData.DOB, 'YYYY-MM-DD')}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setOwnerData({
                                ...ownerData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        ) : (
                          <DatePicker
                          className={`w-100 ${ownerData?.DOB ? 'isFilled' : ''}`}
                            format={'DD.MM.YYYY'}
                            disabledDate={disabledDate}
                            onChange={(date: any) =>
                              setOwnerData({
                                ...ownerData,
                                ['DOB']: date != null ? date.format('YYYY-MM-DD') : null,
                              })
                            }
                            placeholder='Select DOB'
                          />
                        )}

                        {/* <input
                          type='date'
                          {...register('DOB', {required: false})}
                          className='form-control form-control-solid mytest'
                          placeholder='Select DOB'
                          max={new Date().toISOString().split('T')[0]}
                          name='DOB'
                          style={{backgroundColor: '#fff'}}
                          // value={moment(
                          //   ownerData?.DOB && ownerData?.DOB.split('T')[0]
                          // ).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setOwnerData({...ownerData, [e.target.name]: e.target.value})
                          }
                        /> */}

                        {errors.DOB && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                    {/* NATIONALITY */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Nationality </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          // defaultValue={ownerData?.gender == 0 ? genderOptions[0] : ownerData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          styles={{
                            ...customStyles,
                            control: (defaultStyles: any, state: any) => ({
                              ...defaultStyles,
                              backgroundColor: '#fff',
                              boxShadow: 'none',
                              color: 'white',
                              border: ownerData?.nationality ? '0.1rem solid #35475e' : '0.1rem solid #bec3cb',
                              borderRadius: '2px',
                              padding: '0rem',
                              height: '35px',
                              ':hover': {
                                borderColor: '#35475e',
                                backgroundColor: '#fff',
                              },
                              ':focus': {
                                borderColor: '#35475e !important',
                                backgroundColor: '#fff',
                              },
                              '::placeholder': {
                                fontStyle: 'Italic',
                              },
                            }),
                          }}
                          isSearchable={false}
                          options={country}
                          placeholder='Select nationality'
                          name='nationality'
                          onChange={(e: any) =>
                            setOwnerData({
                              ...ownerData,
                              ['nationality']: e?.value,
                            })
                          }
                        /> */}

                      <div className='test jobs owner'>
                        <div className='property-test d-none-multi-checbox'>
                          <div
                            className={`multi-select-container jobs-property ms-0 nationality ${
                              ownerData?.nationality?.length === 0 ? 'no-value' : 'has-value'
                            }`}
                          >
                            <MultiSelect
                              options={country}
                              value={ownerData?.nationality}
                              onChange={(e: any) => {
                                let newVal: any = e
                                if (e?.length > 1) newVal = e.slice(1)
                                {
                                  setOwnerData({
                                    ...ownerData,
                                    ['nationality']: newVal,
                                  })
                                }
                              }}
                              labelledBy=''
                              overrideStrings={{
                                allItemsAreSelected: '',
                              }}
                              hasSelectAll={false}
                              closeOnChangedValue={true}    
                            />
                          </div>
                        </div>
                       </div>
                      </h4>
                    </div>
                    {/* GENDER */}
                    <div className='d-flex flex-column my-2 mb-4 align-items-start xyz tst'>
                      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
                        <label className='labl-gry'> Gender </label>
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        {/* <Select
                          // defaultValue={ownerData?.gender == 0 ? genderOptions[0] : ownerData?.gender == 2 ? genderOptions[2] : genderOptions[1]}
                          // {...register('gender', {required: true})}
                          styles={{
                            ...customStyles,
                            control: (defaultStyles: any, state: any) => ({
                              ...defaultStyles,
                              backgroundColor: '#fff',
                              boxShadow: 'none',
                              color: 'white',
                              border: ownerData?.gender ? '0.1rem solid #384a62 ' : '0.1rem solid #bec3cb',
                              borderRadius: '2px',
                              padding: '0rem',
                              height: '35px',
                              ':hover': {
                                borderColor: '#35475e',
                                backgroundColor: '#fff',
                              },
                              ':focus': {
                                borderColor: '#35475e !important',
                                backgroundColor: '#fff',
                              },
                              '::placeholder': {
                                fontStyle: 'Italic',
                              },
                            }),
                          }}
                          isSearchable={false}
                          options={genderOptions}
                          placeholder='Select gender'
                          name='gender'
                          onChange={(e: any) =>
                            setOwnerData({
                              ...ownerData,
                              ['gender']: e?.value,
                            })
                          }
                        /> */}

                      <div className='test jobs owner'>
                        <div className='property-test d-none-multi-checbox'>
                          <div
                            className={`multi-select-container jobs-property ms-0 gender ${
                              ownerData?.gender?.length === 0 ? 'no-value' : 'has-value'
                            }`}
                          >
                            <MultiSelect
                              options={genderOptions}
                              value={ownerData?.gender}
                              onChange={(e: any) => {
                                let newVal: any = e
                                if (e?.length > 1) newVal = e.slice(1)
                                {
                                  setOwnerData({
                                    ...ownerData,
                                    ['gender']: newVal,
                                  })
                                }
                              }}
                              labelledBy=''
                              overrideStrings={{
                                allItemsAreSelected: '',
                              }}
                              hasSelectAll={false}
                              disableSearch
                              closeOnChangedValue={true}    
                            />
                          </div>
                        </div>
               

                       </div>
                      </h4>
                    </div>
                    {/* ID NO. */}
                    <InputField
                      isRequired={false}
                      register={register}
                      errors={errors}
                      name="idNo"
                      label="ID No."
                      placeholder="Enter ID number"
                      value={ownerData?.idNo}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths?.idNo}
                      customError={customErrors?.idNo}
                    />
                    
                    {/* Passport No. */}
                    <InputField
                      isRequired={false}
                      register={register}
                      errors={errors}
                      name="passportNo"
                      label="Passport No."
                      placeholder="Enter passport number"
                      value={ownerData?.passportNo}
                      onChange={(e:any) => setOwnerData({...ownerData, [e.target.name]: e.target.value})}
                      maxLength={maxLengths?.passportNo}
                      customError={customErrors?.passportNo}
                    />
                  </div>
                </div>

                <div className='d-flex  align-items-center mt-3 pb-5 mb-5  mx-3' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>

                {/* FOLDER */}
                <div className='col-12 pt-2'>
                  {!messageList && (
                    <div className='flex-lg-row-fluid'>
                      <div className='d-flex align-items-center justify-content-end ' style={{position: 'relative', paddingBottom: '50px'}}>
                        <h3 className='head-text m-0 p-0 text-center' style={{position: 'absolute', top: 6, left: '46%', right: '50%'}}>
                          <b>Documents</b>
                        </h3>
                        {!viewFolder && (
                          <div
                            className='text-end'
                            style={{textAlign: 'right'}}
                            onClick={() => {
                              setFileFound(false)
                              setFolderNameModal(true)
                            }}
                          >
                            <button
                              type='button'
                              className='btn btn-sm fw-bold px-2 green-hollow-btn'
                              style={{width: '130px'}}
                              onClick={() => {
                                setFileFound(false)
                                setFolderNameModal(true)
                              }}
                            >
                              <img
                                src={folderGreenIcon}
                                height={18}
                                width={18}
                                style={{
                                  marginRight: '7px',
                                }}
                              />
                              New Folder
                            </button>
                          </div>
                        )}
                      </div>

                      <div>
                        {/* Documents Tab */}
                        {propertiType === 'Documents' && (
                          <>
                            <div className='row g-6 g-xl-9 mb-6 mb-xl-9 '>
                              {/* Add New Folder Card */}
                              {folderNameModal && !viewFolder && (
                                <div className='col-md-6 col-lg-4 col-xl-4'>
                                  <form onSubmit={(e:any) => handleSubmitForm(e)}>
                                  <div style={{height: '30px'}}>
                                    <div className='d-flex mx-1 align-items-center'>
                                      <img
                                        src={redCross}
                                        height={18}
                                        width={18}
                                        className='cursor-pointer'
                                        onClick={() => setFolderNameModal(false)}
                                      />

                                      <img
                                        // style={
                                        //   folderName?.trim()?.length > 0 ? {opacity: '1', cursor: 'pointer'} : {opacity: '0.7', cursor: 'not-allowed'}
                                        // }
                                        style={
                                           {opacity: '1', cursor: 'pointer'}
                                        }
                                        src={correct}
                                        height={24}
                                        width={24}
                                        className='ms-auto'
                                        onClick={() => {
                                          if (folderName?.trim()?.length > 0 && folderName?.trim()?.length <= 30) {
                                            addFolder()
                                          } else if(folderName?.trim()?.length == 0 || folderName == '') {
                                            setIsFolderName(true)
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='card pb-6'>
                                    <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test pb-2'>
                                      <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                        <div className='symbol symbol-60px mb-5'>
                                          <img
                                            src={
                                              ownerData?.document?.contract?.split('.')[1] === 'pdf'
                                                ? pdfDocument
                                                : ownerData?.other?.split('.')[1] === 'doc'
                                                ? docCocument
                                                : folderDocument
                                            }
                                            className='theme-light-show'
                                            alt=''
                                          />
                                          <img src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg' className='theme-dark-show' alt='' />
                                        </div>
                                        <div className='fs-5 fw-bold mb-2 '>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mytest w-75 m-auto'
                                            placeholder='Enter Folder Name'
                                            onChange={(e: any) => {
                                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                              if(e?.target?.value?.length > 30){
                                                setFolderName(e.target.value.slice(0, 30 + 1))
                                                setIsLimitError(true)
                                              }else{
                                                setFolderName(e.target.value)
                                                setIsLimitError(false)
                                              }
                                              setFolderName(captilizeWord)
                                            }}
                                             maxLength={30 + 1}
                                          />
                                          {isFolderName ? (
                                                <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                  This field is required
                                                </p>
                                              ): isLimitError ?  (
                                                <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                  Folder name cannot exceed {30} characters
                                                </p>
                                              ) : fileFound ? (
                                                <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                  Folder name already exists
                                                </p>
                                              ) : null}
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </form>
                                </div>
                              )}

                              {folders?.length > 0 && (
                                <>
                                  {viewFolder ? (
                                    <>
                                      <div className='d-flex align-items-center'>
                                        <span
                                          className='rounded-circle'
                                          style={{cursor: 'pointer'}}
                                          onClick={() => {
                                            setViewFolder(false)
                                            setEditFile(-1)
                                          }}
                                        >
                                          <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
                                        </span>
                                        <h2 className='m-0 head-text ms-2'>{selectedFolder?.name}</h2>

                                        <label title='Add New File' htmlFor='uploadPassport' className='btn btn-sm fw-bold upload-btn ms-auto'>
                                          <img src={upload} height={18} width={18} className='me-3' />
                                          Upload File{' '}
                                        </label>
                                        <input
                                          type='file'
                                          hidden
                                          id='uploadPassport'
                                          className='form-control form-control-solid'
                                          placeholder='upload'
                                          name='passport'
                                          onChange={(e: any) => {
                                            onFilesAddedSingle(e, 0, '')
                                          }}
                                          multiple
                                        />
                                      </div>
                                      <div className='col-md-12 col-lg-12 col-xl-12' style={{padding: '0px 20px'}}>
                                        <div className='card h-100'>
                                          <div className='card-body d-flex justify-content-center flex-column p-8'>
                                            {folderFiles?.length > 0 ? (
                                              <>
                                                <div className='row py-3' style={{color: '#a1a5b7'}}>
                                                  <div className='col-md-7 '>
                                                    <span style={{paddingLeft: '25px'}}>File Name</span>
                                                  </div>
                                                  <div className='col-md-2 '>Date Added</div>
                                                  <div className='col-md-3'></div>
                                                </div>

                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div className='row py-3'>
                                                      {editFile == ind ? (
                                                        <>
                                                          <div className='col-md-7 head-text d-flex align-items-center'>
                                                            <div className='bullet-item me-3 mb-0'></div>
                                                            <img
                                                              src={
                                                                file?.fileURL?.split('.')?.pop() === 'png'
                                                            ? pngIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpg'
                                                            ? jpgIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pdf'
                                                            ? pdfIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpeg'
                                                            ? jpegIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'docx'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'doc'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pptx'
                                                            ? pptIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'ppt'
                                                            ? pptIcon
                                                            : ''
                                                              }
                                                              height={30}
                                                              width={30}
                                                              className='mx-3 cursor-pointer'
                                                              data-fancybox
                                                              data-src={`${Bucket}${file?.fileURL}`}
                                                            />

                                                            <input
                                                              type='text'
                                                              value={newName}
                                                              className='form-control form-control-solid p-2'
                                                              style={{
                                                                backgroundColor: '#fff',
                                                                border: '1px solid #b3b3b3',
                                                              }}
                                                              onChange={(e: any) => {
                                                                // let temp = getFilename(file?.fileName, true)

                                                                setNewName(e.target.value?.slice(0,50))
                                                              }}
                                                            />
                                                            {/* <a
                                                                className='btn btn-sm fw-bold btn-primary btn-green'
                                                                onClick={() => {
                                                                  const values = [...folderFiles]
                                                                  values[ind].fileName = newName
                                                                  setFolderFiles(values)
                                                                  getFolderFiles(selectedFolder)
                                                                  setEditFile(-1)
                                                                }}
                                                              >
                                                                <img src={check} height={18} width={18} />
                                                              </a> */}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div className='col-md-7 head-text d-flex align-items-center'>
                                                          <div className='bullet-item me-3 mb-0'></div>
                                                          <img
                                                            src={
                                                              file?.fileURL?.split('.')?.pop() === 'png'
                                                            ? pngIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpg'
                                                            ? jpgIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pdf'
                                                            ? pdfIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'jpeg'
                                                            ? jpegIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'docx'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'doc'
                                                            ? docIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'pptx'
                                                            ? pptIcon
                                                            : file?.fileURL?.split('.')?.pop() === 'ppt'
                                                            ? pptIcon
                                                            : ''
                                                            }
                                                            height={30}
                                                            width={30}
                                                            className='mx-3 cursor-pointer'
                                                            data-fancybox
                                                            data-src={`${Bucket}${file?.fileURL}`}
                                                          />
                                                          {getFilename(file?.fileName, false)?.length > 30
                                                            ? `${file?.fileName?.slice(0, 30)}...${getFilename(file?.fileName, true)}`
                                                            : file?.fileName}
                                                        </div>
                                                      )}
                                                      <div className='col-md-2 head-text'>{moment(file?.createdAt).format('DD.MM.YYYY')}</div>
                                                      <div className='col-md-3 head-text text-end'>
                                                        {editFile == ind ? (
                                                          <div className='d-flex justify-content-end'>
                                                            <img
                                                              src={removeFilled}
                                                              height={20}
                                                              width={20}
                                                              style={{
                                                                cursor: 'pointer',
                                                                marginRight: '25px',
                                                              }}
                                                              onClick={() => {
                                                                setEditFile(-1)
                                                              }}
                                                            />
                                                            <img
                                                              src={correctFilled}
                                                              height={20}
                                                              width={20}
                                                              className='ms-7'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                if(newName.split('.')?.[0]){
                                                                  const values = [...folderFiles]
                                                                  let temp = getFilename(file?.fileName, true)
                                                                  values[ind].fileName = newName + "." + temp
                                                                  setFolderFiles(values)
                                                                  getFolderFiles(selectedFolder)
                                                                  setEditFile(-1)
                                                                }else{
                                                                  setEditFile(-1)
                                                                }
                                                               
                                                              }}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className='d-flex justify-content-end tst'>
                                                            <img
                                                              src={downloadIcon}
                                                              height={20}
                                                              width={20}
                                                              className='me-5'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                handleDownload(`${Bucket}${file?.fileURL}`,file?.fileName)
                                                              }}
                                                            />
                                                            <img
                                                              src={greenPen}
                                                              height={17}
                                                              width={17}
                                                              style={{cursor: 'pointer'}}
                                                              className='me-5'
                                                              onClick={() => {
                                                                setNewName(getFilename(file?.fileName, false))
                                                                setEditFile(ind)
                                                              }}
                                                            />

                                                            <img
                                                              src={trashImg}
                                                              height={16}
                                                              width={16}
                                                              className='ms-1 cursor-pointer'
                                                              onClick={(e: any) => {
                                                                //
                                                                Swal.fire({
                                                                  html: `
                                                                    <div class='fs-4 mt-4 fw-bold'> 
                                                                    Delete File
                                                                    </div>
                                                                    <div class='fs-5 mt-4'> 
                                                                        Are you sure you want to permanently delete this file?
                                                                    </div>`,
                                                                  showConfirmButton: true,
                                                                  confirmButtonColor: '#d54645',
                                                                  confirmButtonText: 'Yes',
                                                                  showCancelButton: true,
                                                                  cancelButtonColor: '#0d9e91',
                                                                  // width: '205px',
                                                                  padding: '0px',

                                                                  // cancelButtonColor: "transparent",
                                                                  cancelButtonText: 'No',
                                                                }).then((res: any) => {
                                                                  if (res.isConfirmed) {
                                                                    onFilesAddedSingle(e, 1, ind)
                                                                  }
                                                                })
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                })}

                                                {/* <div className='tops mw-350px d-flex gap-5 align-items-start'>
                                    {folderFiles?.map((file: any, ind: any) => {
                                      return (
                                        <div className='imgs-set'>
                                          <div className='d-flex mw-350px me-3'>
                                            <img
                                              src={Bucket + file?.fileURL}
                                              width='80'
                                              height='80'
                                              className='main_img'
                                            />

                                            <img
                                              style={{
                                                marginLeft: '-13px',
                                                marginTop: '-10px',
                                                cursor: 'pointer',
                                              }}
                                              src={closered}
                                           
                                              width='25'
                                              height='25'
                                              onClick={(e) => {
                                                onFilesAddedSingle(e, 1, file?._id)
                                              }}
                                            />
                                          </div>
                                          <p className=''>
                                            {getFilename(file?.fileName, false)
                                              ?.length > 6
                                              ? `${file?.fileName?.slice(
                                                  0,
                                                  6
                                                )}...${getFilename(
                                                  file?.fileName,
                                                  true
                                                )}`
                                              : file?.fileName}
                                          </p>
                                        </div>
                                      )
                                    })}
                                  </div> */}
                                              </>
                                            ) : (
                                              <div className='text-center light-dark-theme-color'> No Files</div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {folders
                                        ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                        ?.map((fl: any, index: any) => {
                                          return (
                                            <div className='col-md-6 col-lg-4 col-xl-4' key={`${fl?.name}${index}`}>
                                              {/* <div style={{height: '30px'}}>
                                                {!fl?.isSystemGenerated && (
                                                  <img
                                                    src={trashImg}
                                                    height={16}
                                                    width={16}
                                                    className='ms-1 cursor-pointer'
                                                    onClick={() => {
                                                      Swal.fire({
                                                        html: `
                                                          <div class='fs-4 mt-4 fw-bold'> 
                                                          Delete Folder
                                                          </div>
                                                          <div class='fs-5 mt-4'> 
                                                            Are you sure you want to permanently delete this folder?
                                                          </div>`,
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#d54645',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        cancelButtonColor: '#0d9e91',
                                                        // width: '205px',
                                                        padding: '0px',

                                                        // cancelButtonColor: "transparent",
                                                        cancelButtonText: 'No',
                                                      }).then((res: any) => {
                                                        if (res.isConfirmed) {
                                                          deleteFolder(index)
                                                        }
                                                      })
                                                    }}
                                                  />
                                                )}
                                              </div> */}
                                                <div style={{height: '30px'}}>
                                                {!fl?.isSystemGenerated && (
                                                  <div className='d-flex align-items-center justify-content-between'>
                                                    {editFolderName !== index ? (
                                                      <>
                                                        <img
                                                          src={trashImg}
                                                          height={16}
                                                          width={16}
                                                          className='ms-1 cursor-pointer'
                                                          onClick={(e: any) => {
                                                            Swal.fire({
                                                              html: `
                                                          <div class='fs-4 mt-4 fw-bold'> 
                                                          Delete Folder
                                                          </div>
                                                          <div class='fs-5 mt-4'> 
                                                            Are you sure you want to permanently delete this folder?
                                                          </div>`,
                                                              showConfirmButton: true,
                                                              confirmButtonColor: '#d54645',
                                                              confirmButtonText: 'Yes',
                                                              showCancelButton: true,
                                                              cancelButtonColor: '#0d9e91',
                                                              // width: '205px',
                                                              padding: '0px',

                                                              // cancelButtonColor: "transparent",
                                                              cancelButtonText: 'No',
                                                            }).then((res: any) => {
                                                              if (res.isConfirmed) {
                                                                deleteFolder(fl?._id)
                                                              }
                                                            })
                                                          }}
                                                        />
                                                        <img
                                                          src={greenPen}
                                                          height={17}
                                                          width={17}
                                                          style={{cursor: 'pointer'}}
                                                          className='me-1'
                                                          onClick={() => {
                                                            setNewFolderName(fl?.name)
                                                            setEditFolderName(index)
                                                            setSelectedFolder(fl)
                                                          }}
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          src={removeFilled}
                                                          className='ms-1'
                                                          height={20}
                                                          width={20}
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={() => {
                                                            setEditFolderName(-1)
                                                          }}
                                                        />
                                                        <img
                                                          src={correctFilled}
                                                          height={20}
                                                          width={20}
                                                          className='me-1'
                                                          style={{cursor: 'pointer'}}
                                                          onClick={() => {
                                                            // getFolderFiles(selectedFolder)
                                                            // setEditFile(-1)
                                                          //   if (newFolderName?.trim()?.length > 0)
                                                          //     updateFolderName()
                                                          // }
                                                          if (newFolderName?.trim()?.length > 0 && newFolderName?.trim()?.length <= maxLength) {
                                                            updateFolder(index)

                                                          } else if(newFolderName?.trim()?.length == 0 || newFolderName == '') {
                                                            setIsFolderName(true)
                                                          }
                                                        }}
                                                        />
                                                      </>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                              <div className='position-relative'>
                                                {fl?.name == 'Title Deeds' && (
                                                  <div
                                                    className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                                                    style={{
                                                      // border: '0.5px solid #ccc8c8',
                                                      boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                                                    }}
                                                  ></div>
                                                )}
                                                <div className='card '>
                                                  <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                    <div className='d-flex justify-content-end'>
                                                      <div
                                                        style={{
                                                          height: '22px',
                                                          width: '22px',
                                                          backgroundColor: '#d9d9d9',
                                                          color: '#000',
                                                          borderRadius: '11px',
                                                        }}
                                                      >
                                                        {fl?.fileList?.length}
                                                      </div>
                                                    </div>
                                                    <a
                                                      // onClick={() => getFolderFiles(fl)}
                                                      onClick={() => (editFolderName == index ? () => {} : getFolderFiles(fl))}
                                                      className='text-gray-800 text-hover-primary d-flex flex-column'
                                                    >
                                                      <div className='symbol symbol-60px mb-5'>
                                                        <img
                                                          src={
                                                            ownerData?.document?.contract?.split('.')[1] === 'pdf'
                                                              ? pdfDocument
                                                              : ownerData?.other?.split('.')[1] === 'doc'
                                                              ? docCocument
                                                              : folderIcon
                                                          }
                                                          className='theme-light-show'
                                                          alt=''
                                                        />
                                                        <img src={folderIcon} className='theme-dark-show' alt='' />
                                                      </div>
                                                      <div className='fs-5 fw-bold mb-2'>
                                                      {editFolderName == index ? (
                                                        <>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid mytest w-75 m-auto bg-white'
                                                            placeholder='Enter Folder Name'
                                                            value={newFolderName}
                                                            // onChange={(e: any) => setNewFolderName(e.target.value)}
                                                            onChange={(e: any) => {
                                                              if(e?.target?.value?.length > 30){
                                                                setNewFolderName(e.target.value.slice(0, maxLength + 1))
                                                                setIsLimitError(true)
                                                              }else{
                                                                setNewFolderName(e.target.value)
                                                                setIsLimitError(false)
                                                              }
                                                            }}
                                                            maxLength={maxLength + 1}
                                                          />
                                                            {/* {fileFound ? (
                                                              <p className='mt-2' style={{color: 'red'}}>
                                                                Folder name already exists
                                                              </p>
                                                            ) : null} */}

                                                            {isFolderName ? (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                This field is required
                                                              </p>
                                                            ): isLimitError ?  (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                Folder name cannot exceed {maxLength} characters
                                                              </p>
                                                            ) : fileFound ? (
                                                              <p className='mt-2 mb-0' style={{color: 'red'}}>
                                                                Folder name already exists
                                                              </p>
                                                            ) : null}
                                                          
                                                        </>
                                                      ) : (
                                                        <h4 className='white-dark-theme-color'>{fl?.name?.length > 15 ? `${fl?.name?.substring(0, 15)}...` : fl?.name}</h4>
                                                      )}
                                                        {/* <h4> {fl?.name?.length > 15 
                                                        ? `${fl?.name?.substring(0, 15)}...` 
                                                        : fl?.name}</h4> */}
                                                      </div>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {fl?.name == 'Title Deeds' && (
                                                <div className='text-center'>
                                                  <h5 className='my-2 light-dark-theme-color'>
                                                    Title Deeds will be added to this folder when adding a property for the owner.
                                                  </h5>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row py-5 m-0 shadow-sm' style={{position: 'sticky', bottom: 0, background: '#fff', zIndex: 2}}>
              <div className='col-12 d-flex justify-content-center align-items-center '>
                <button
                  disabled={isCTADisabled}
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-3 status-w-280'
                  onClick={handleSubmit((data: any, e: any) => addOwner(ownerData, e, 0))}
                >
                  Add Owner
                </button>
              </div>
            </div>
          {/* </form> */}
        </div>
      </Modal>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default AddOwnerModal

import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import { Bucket } from '../../../../../../apiCommon/helpers/API/ApiData'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import { getFileIcon } from '../../../../../../Utilities/utils'

interface ButtonProps {
  show: boolean
  handleClose: () => void
  data: any[]
  type: 'invoice' | 'receipt'
}

const InvoiceReceiptPopup = ({ show, handleClose, data, type }: ButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, []);

  const isInvoice = type === 'invoice'

  const renderSummary = () => {
    const total = data.length;
    const amount = data.reduce((sum, item) => sum + parseFloat(isInvoice ? item.totalAmount : item.amountPaid || 0), 0);
    const lastPaid = isInvoice ? null : (data.length > 0 ? moment(data[0].paidDate).format('DD.MM.YYYY') : '-');

    return (
      <div className='d-flex align-items-center mt-4'>
        <div className='me-5'>
          <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5'>Total</p>
          <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5'>{total}</p>
        </div>
        <div className='me-5'>
          <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5'>Amount</p>
          <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5'>
            AED {amount.toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </p>
        </div>
        {!isInvoice && (
          <div className='me-5'>
            <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5'>Last Paid</p>
            <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5'>{lastPaid}</p>
          </div>
        )}
      </div>
    );
  };

  const renderTable = () => {
    if (isInvoice) {
      return (
        <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
              <th className='text-start min-w-125px'>Invoice No.</th>
              <th className='text-start w-25px ps-0 pe-2'></th>
              <th className='text-start min-w-100px ps-0'>Invoice Date</th>
              <th className='text-start min-w-100px'>Due Date</th>
              <th className='text-start min-w-125px'>Total Amount</th>
              <th className='text-start min-w-100px'>Paid Amount</th>
              <th className='text-center min-w-125px'>Status</th>
            </tr>
          </thead>
          <tbody className='fw-semibold'>
            {data.length > 0 ? (
              data.map((item: any, index: number) => (
                <tr key={index}>
                  <td className='text-decoration-underline'>
                    <b>{item.invoiceNo || '-'}</b>
                  </td>
                  <td className='ps-0 pe-2'>
                    {item.pdfURL && (
                      <img
                        src={getFileIcon(item.pdfURL)}
                        height={30}
                        alt=''
                        width={30}
                        className='me-2 cursor-pointer'
                        data-src={`${Bucket}${item.pdfURL}`}
                        data-fancybox
                        id={`clickedInvoice${index}`}
                      />
                    )}
                  </td>
                  <td className='ps-0'>
                    {item.date ? moment(item.date).format('DD.MM.YYYY') : '-'}
                  </td>
                  <td>
                    {item.dueDate ? moment(item.dueDate).format('DD.MM.YYYY') : '-'}
                  </td>
                  <td>
                    AED {parseFloat(item.totalAmount || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  </td>
                  <td>
                    AED {parseFloat(item.totalPaidAmount || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  </td>
                  <td>
                    <div className={`status-w-100 status mx-auto ${getStatusClass(item.status)}`}>
                      {getStatusText(item.status)}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                {!isLoading ? (
                  <td colSpan={13} className='text-center'>No invoices found</td>
                ) : (
                  <td colSpan={13} className='text-center'>
                    <div className='d-flex justify-content-center align-items-center w-100' style={{height: '25vh'}}>
                      <CircularProgress style={{color: '#007a59'}} />
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      );
    } else {
      return (
        <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
              <th className='text-start min-w-125px'>Receipt No.</th>
              <th className='text-start w-25px ps-0 pe-2'></th>
              <th className='text-start min-w-100px ps-0'>Paid Date</th>
              <th className='text-start min-w-125px'>Paid Amount</th>
            </tr>
          </thead>
          <tbody className='fw-semibold'>
            {data.length > 0 ? (
              data.map((item: any, index: number) => (
                <tr key={index}>
                  <td className='text-decoration-underline'>
                    <b>{item.receiptNo || '-'}</b>
                  </td>
                  <td className='ps-0 pe-2'>
                    {item.pdfURL && (
                      <img
                        src={getFileIcon(item.pdfURL)}
                        height={30}
                        alt=''
                        width={30}
                        className='me-2 cursor-pointer'
                        data-src={`${Bucket}${item.pdfURL}`}
                        data-fancybox
                        id={`clickedReceipt${index}`}
                      />
                    )}
                  </td>
                  <td className='ps-0'>
                    {item.datePaid ? moment(item.datePaid).format('DD.MM.YYYY') : '-'}
                  </td>
                  <td>
                    AED {parseFloat(item.amountPaid || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                {!isLoading ? (
                  <td colSpan={4} className='text-center'>No receipts found</td>
                ) : (
                  <td colSpan={4} className='text-center'>
                    <div className='d-flex justify-content-center align-items-center w-100' style={{height: '25vh'}}>
                      <CircularProgress style={{color: '#007a59'}} />
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      );
    }
  };

  const getStatusClass = (status: number) => {
    switch (status) {
      case 0: return 'cancelled-st';
      case 1: return 'approve-st';
      case 2: return 'pending-approval-st';
      case 3:
      case 4: return 'pend-st';
      default: return 'not-saved';
    }
  };

  const getStatusText = (status: number) => {
    switch (status) {
      case 0: return 'Unpaid';
      case 1: return 'Paid';
      case 2: return 'Partially paid';
      case 3: return 'Void';
      case 4: return 'Draft';
      default: return '-';
    }
  };

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={handleClose}
        backdrop={true}
        size='sm'
      >
        <div className='modal-body py-lg-7 px-lg-7 modal-body-without-header' style={{ maxHeight: '500px', overflow: 'auto' }}>
          <div className='d-flex align-items-center'>
            <h2 className='mb-0 pt-1'><b>{isInvoice ? 'Invoices' : 'Receipts'}</b></h2>
            <label className='head-text fs-2 cursor-pointer ms-auto' style={{ fontWeight: '700' }} onClick={handleClose}>X</label>
          </div>

          {renderSummary()}

          <div className='separator my-3 mt-5'></div>
          <div className='row mt-3'>
            <div className='col-12 pe-0'>
              <div className='table-responsive pb-3' style={{ maxHeight: '380px', overflow: 'auto' }}>
                {renderTable()}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoiceReceiptPopup
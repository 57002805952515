import React from 'react';

interface InputFieldI{
    register : any
    errors: any
    name: string
    label: string
    placeholder: string
    value: any
    onChange: any
    maxLength : number
    isRequired?:boolean,
    customError: any
}

const InputField = ({ 
  isRequired = true,
  register, 
  errors, 
  name, 
  label, 
  placeholder, 
  value = '', 
  onChange, 
  maxLength = 50,
  customError
}:InputFieldI) => {
  const capitalizeFirstLetter = (str:string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
      <h4 className='my-2' style={{color: 'black', width: '100%'}}>
        <label className={`${isRequired ? 'required' : ''} labl-gry`}>{label}</label>
      </h4>
      <h4 style={{width: '100%'}} className='my-2'>
        <input
          {...register(name, isRequired ? {
            required: true,
            maxLength: maxLength + 1
          } : { maxLength: maxLength + 1})}
          type='text'
          className='form-control form-control-solid mytest'
          style={{backgroundColor: '#fff'}}
          autoComplete='off'
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => {
            const capitalizedWord = capitalizeFirstLetter(e.target.value?.trimStart())
            onChange({
              target: {
                name: e.target.name,
                value: capitalizedWord.slice(0, maxLength + 1),
              },
            });
          }}
          maxLength={maxLength + 1}
        />

        {errors[name]?.type === 'required' && (
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
            This field is required
          </p>
        )}
        {value?.length > maxLength && (
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
            {label} cannot exceed {maxLength} characters
          </p>
        )}
        {/* <p className='text-muted mt-1'>
          {value?.length || 0}/{maxLength} characters
        </p> */}
      </h4>
    </div>
  );
};

export default InputField;
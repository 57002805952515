import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../../img/trash.png'
import carrotArrowDown from '../../../../img/caret-arrow-down.png'
import carrotArrowUp from '../../../../img/caret-arrow-up.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import redCross from '../../../../img/remove.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import unitClusterGreenIcon from '../../../../img/unitClusterGreen.png'
import unitClusterWhiteIcon from '../../../../img/unitClusterWhite.png'
import mixedClusterGreenIcon from '../../../../img/mixedClusterGreen.png'
import mixedClusterWhiteIcon from '../../../../img/mixedClusterWhite.png'
import buildingClusterGreenIcon from '../../../../img/buildingClusterGreen.png'
import buildingClusterWhiteIcon from '../../../../img/buildingClusterWhite.png'
import buildingWhiteIcon from '../../../../img/small-building.png'
import buildingGreenIcon from '../../../../img/allBuildingGreen.png'
import communitiesWhiteIcon from '../../../../img/allCommunities.png'
import communitiesGreenIcon from '../../../../img/allCommunitiesGreen.png'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

import swal from 'sweetalert2'
import {mixed} from 'yup'
import cluster from 'cluster'

interface ButtonProps {
  buildingCluster?: any
  setBuildingCluster: any
  handleAddBuildingCluster: any
  handleRemoveBuildingCluster: any
  handleAddBuildingForBuildingCluster: any
  handleRemoveBuildingClusterBuilding: any
  addFloorHandler: any
  floorOptions: any
  allCluster: any
  index: any
  setCluster: any
  handleAddBuildingForBuildingClusterUpdate: any
  setIsCTAHidden:any
}

const EditBuildingCluster = ({
  buildingCluster,
  setBuildingCluster,
  handleAddBuildingCluster,
  handleRemoveBuildingCluster,
  handleAddBuildingForBuildingCluster,
  handleRemoveBuildingClusterBuilding,
  addFloorHandler,
  floorOptions,
  allCluster,
  index,
  setCluster,
  handleAddBuildingForBuildingClusterUpdate,
  setIsCTAHidden,
}: ButtonProps) => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }
  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
  }
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const calculateTotalFloors = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }
  const calculateTotalFloorsForUpdateBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.forEach((floorItem: any) => {
      if(floorItem?.noSubListRowUI){
        if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
          totalFloors += 1
        } else {
          totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
        }
      }else{
        totalFloors += floorItem?.floorList?.length
      }
    })
    return totalFloors
  }

  const floorOption = (
    index: any,
    mixedClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    buildingType: 'addBuilding' | 'updateBuilding',
    floorType: 'addFloor' | 'updateFloor'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'add' ? allCluster[index]?.addBuildingCluster : allCluster[index]?.updateBuildingCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.[floorType]
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionForUpdate = (
    index: any,
    mixedClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    buildingType: 'addBuilding' | 'updateBuilding'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'add' ? allCluster[index]?.addBuildingCluster : allCluster[index]?.updateBuildingCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.floors
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const addFloorHandlerForUpdateBuilding = (index: any, builidngClusterIndex: any, buildingIndex: any) => {
    // console.log('testr')
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateBuildingCluster[builidngClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      sortIndex:updateCluster[index]?.updateBuildingCluster[builidngClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.length + 1,
      index: 1,
      noSubListRowUI:true,
    })
    setCluster(updateCluster)


  }

  const handleRemoveFloorForUpdateBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any, key: any) => {
    // key =  1 ==> delete from addFloor
    // key =  2 ==> delete from updateFloor
    const updatedCluster = [...allCluster]
    if (key === 1) {
      updatedCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor.splice(floorIndex, 1)
      setCluster(updatedCluster)
    }
    if (key === 2) {
      // const floorID = updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?._id
      const floorDeleteListTemp = updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor
      const floorlistIds = updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[
        floorIndex
      ].floorList?.map((item: any) => ({_id: item._id}))

      updatedCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].deleteFloor = [
        ...floorDeleteListTemp,
        ...floorlistIds,
      ]
      // updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor.push({ _id: floorID })
      updatedCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors.splice(floorIndex, 1)
      setCluster(updatedCluster)
    }

    const {updatedFloors1, updatedFloors2} = checkForDuplicates(
      allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor,
      allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors
    )
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2
    setCluster(updatedCluster)
  }

  const handleRemoveClusterForUpdateBuilding = (index: any, buildingClusterIndex: any) => {
    const updatedCluster = [...allCluster]
    const buildingClusterID = updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?._id
    updatedCluster[index].deleteBuildingCluster.push({_id: buildingClusterID})
    updatedCluster[index].updateBuildingCluster.splice(buildingClusterIndex, 1)
    setCluster(updatedCluster)
    setIsCTAHidden(false)
    // console.log(allCluster)
  }

  const handleRemoveBuildingClusterFloor = (index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)

    const updatedFloors = checkForDuplicatesForBuildingCluster(
      updatedCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    )
    updatedCluster[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
    setCluster(updatedCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveBuildingForUpdateBuildingCluster = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    const updatedCluster = [...allCluster]
    const buildingID = updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?._id
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.deleteBuilding?.push({_id: buildingID})
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding.splice(buildingIndex, 1)
    setCluster(updatedCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveFloorListItemForUpdateFloor = (
    index: any,
    buildingClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    listItemIndex: any
  ) => {
    const updatedCluster = [...allCluster]
    const floorID =
      updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList[listItemIndex]
        ?._id
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor.push({_id: floorID})
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList.splice(
      listItemIndex,
      1
    )
    setCluster(updatedCluster)
  }

  const swapItems = (prevInd: any, nextInd: any, index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, itemIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    values[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray[floorIndex].floorList = valuesSections
    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections =
        updatedValues[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList

      // Swap the items
      let temp = updatedValuesSections[prevInd]

      let tempProperties = {
        _id: temp._id,
        prefix: temp.prefix,
        name: temp.name,
        floorType: temp.floorType,
        index: temp.index,
        isDuplicate: temp.isDuplicate,
      }

      updatedValuesSections[prevInd]._id = updatedValuesSections[nextInd]._id
      updatedValuesSections[prevInd].prefix = updatedValuesSections[nextInd].prefix
      updatedValuesSections[prevInd].name = updatedValuesSections[nextInd].name
      updatedValuesSections[prevInd].floorType = updatedValuesSections[nextInd].floorType

      updatedValuesSections[nextInd]._id = tempProperties._id
      updatedValuesSections[nextInd].prefix = tempProperties.prefix
      updatedValuesSections[nextInd].name = tempProperties.name
      updatedValuesSections[nextInd].floorType = tempProperties.floorType

      // Reset animation classes
      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray[floorIndex].floorList = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      setCluster(updatedValues)
      setIsCTAHidden(false)
    }, 200)
  }

  // const checkDuplicatesForMergeArray = (floors: any) => {
  //   const checkInternalDuplicates = (array: any) => {
  //     return array.map((floor: any, i: any) => {
  //       let isDuplicate = false
  //       let errorMessage = ''
  
  //       array.forEach((otherFloor: any, j: any) => {
  //         if (
  //           i !== j &&
  //           floor?.floorType === otherFloor?.floorType &&
  //           floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()
  //         ) {
  //           const isOverlapping = !(
  //             floor?.floorEndRange < otherFloor?.floorStartRange ||
  //             floor?.floorStartRange > otherFloor?.floorEndRange
  //           )
  //           if (isOverlapping) {
  //             isDuplicate = true
  //             errorMessage = 'Floor Name is duplicated'
  //           }
  //         }
  //       })
  
  //       return { ...floor, isDuplicate, errorMessage }
  //     })
  //   }
  
  //   const updatedFloors = checkInternalDuplicates(floors)
  
  //   updatedFloors?.forEach((floor1: any, i: any) => {
  //     if (!floor1?.floorType?.value) {
  //       floor1.isDuplicate = false
  //       floor1.errorMessage = ''
  //       return // Skip this iteration if the floor1 item is empty
  //     }
  
  //     for (let range = floor1.floorStartRange; range <= floor1.floorEndRange; range++) {
  //       updatedFloors.forEach((floor2: any, j: any) => {
  //         console.log(floor2)
  //         if (floor1.floorType.value === floor2.floorType) {
  //           floor2.floorList.forEach((floorListItem: any) => {
  //             if (floorListItem.name?.includes('-')) {
  //               const [baseName, suffix] = floorListItem.name.toLowerCase().split('-')
  //               const isDuplicate = baseName === floor1.name.toLowerCase() && parseInt(suffix, 10) === range
  
  //               if (isDuplicate) {
  //                 updatedFloors[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
  //                 updatedFloors[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
  //               }
  //             } else {
  //               const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`
  //               if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
  //                 updatedFloors[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
  //                 updatedFloors[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
  //               }
  //             }
  //           })
  //         }
  //       })
  //     }
  //   })
  
  //   return updatedFloors
  // }

  const swapRow = (
    prevInd: any,
    nextInd: any,
    index: any,
    buildingClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'addFloor' | 'floors'
  ) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.[type]
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.[type]

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      updatedValuesSections[prevInd].animationClass = ''
      updatedValuesSections[nextInd].animationClass = ''

      // Assign the updated array back to the structure
      if (type === 'addFloor') {
        updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedValuesSections
      } else {
        updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedValuesSections
      }

      // Update state again to reflect the swap and reset animations and red box

      const {updatedFloors1, updatedFloors2} = checkForDuplicates(
        updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor,
        updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors
      )
      updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
      updatedValues[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2
      setCluster(updatedValues)
    }, 100)
  }

  function swapAndReindex(previous: any, next: any) {
    // Step 1: Swap the floorList arrays
    let tempFloorList = previous.floorList
    previous.floorList = next.floorList
    next.floorList = tempFloorList

    // Step 2: Swap the other properties
    let tempProperties = {
      isShowFloors: previous.isShowFloors,
      prefix: previous.prefix,
      floorType: previous.floorType,
      name: previous.name,
      isDuplicate: previous.isDuplicate,
    }

    previous.isShowFloors = next.isShowFloors
    previous.prefix = next.prefix
    previous.floorType = next.floorType
    previous.name = next.name
    previous.isDuplicate = next.isDuplicate

    next.isShowFloors = tempProperties.isShowFloors
    next.prefix = tempProperties.prefix
    next.floorType = tempProperties.floorType
    next.name = tempProperties.name
    next.isDuplicate = tempProperties.isDuplicate

    // Step 3: Determine the new starting indices
    // const previousStartIndex = 3;
    const previousStartIndex = Math.min(...next.floorList.map((floor: any) => floor.index))
    const nextStartIndex = previous.floorList.length + previousStartIndex

    // Reindex the previous object floorList starting from previousStartIndex
    previous.floorList.forEach((floor: any, idx: any) => {
      floor.index = previousStartIndex + idx
    })

    // Reindex the next object floorList starting from nextStartIndex
    next.floorList.forEach((floor: any, idx: any) => {
      floor.index = nextStartIndex + idx
    })

    return {previous, next}
  }

  useEffect((() => {
    console.log(allCluster)
  }),[allCluster])

  const swapRowForUpdatedFloor = (prevInd: any, nextInd: any, index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.floors

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.floors

      swapAndReindex(updatedValuesSections[prevInd], updatedValuesSections[nextInd])

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].floors = updatedValuesSections

      // Update state again to reflect the swap and reset animations and red box
      const {updatedFloors1, updatedFloors2} = checkForDuplicates(
        updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].addFloor,
        updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].floors
      )
      updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
      updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2

      setCluster(updatedValues)
    }, 200)
  }

  const swapRowForForMergeArray = (prevInd: any, nextInd: any, index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    values[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = valuesSections
    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray

      let temp = updatedValuesSections[prevInd].sortIndex;
      updatedValuesSections[prevInd].sortIndex = updatedValuesSections[nextInd].sortIndex;
      updatedValuesSections[nextInd].sortIndex = temp;

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      console.log(updatedValuesSections)
      // Assign the updated array back to the structure
      updatedValues[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValuesSections

      setCluster(updatedValues)
      setIsCTAHidden(false)
    }, 200)
  }

  const swapRowForAdd = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      updatedValuesSections[prevInd].animationClass = ''
      updatedValuesSections[nextInd].animationClass = ''

      // Assign the updated array back to the structure

      updatedValues[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations and red box
      const updateFloors = checkForDuplicatesForBuildingCluster(
        updatedValues[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      updatedValues[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updateFloors
      setCluster(updatedValues)
      setIsCTAHidden(false)
    }, 100)
  }

  const floorOptionsForUpdateBuilding = (
    index: any,
    clusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    clusterType: 'building' | 'mixed'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    const cluster = clusterType === 'building' ? allCluster[index]?.updateBuildingCluster : allCluster[index]?.updateMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentUpdateFloor
    // const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.floors
    // ?.filter((floorItem: any) => [0, 1, 2, 3].includes(floorItem?.floorType))
    // ?.map((floorItem: any) => floorItem?.floorType);

    const selectedFloorTypes = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.floors
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentFloor
    const selectedFloorTypesForAdd = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType?.value))
      ?.map((floorItem: any) => floorItem?.floorType?.value)

    if ((selectedFloorTypes && selectedFloorTypes.length > 0) || (selectedFloorTypesForAdd && selectedFloorTypesForAdd.length > 0)) {
      // Combine both selected floor types into one set to ensure unique values
      const combinedSelectedFloorTypes = new Set([...(selectedFloorTypes || []), ...(selectedFloorTypesForAdd || [])])

      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !combinedSelectedFloorTypes.has(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionsForMergeFloors = (
    index: any,
    clusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    const cluster = allCluster[index]?.updateBuildingCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentUpdateFloor
    // const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.floors
    // ?.filter((floorItem: any) => [0, 1, 2, 3].includes(floorItem?.floorType))
    // ?.map((floorItem: any) => floorItem?.floorType);

    const selectedFloorTypes = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.mergeFloorArray?.filter((floorItem:any) => floorItem?.subListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentFloor
    const selectedFloorTypesForAdd = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.mergeFloorArray?.filter((floorItem:any) => floorItem?.noSubListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType?.value))
      ?.map((floorItem: any) => floorItem?.floorType?.value)

    if ((selectedFloorTypes && selectedFloorTypes.length > 0) || (selectedFloorTypesForAdd && selectedFloorTypesForAdd.length > 0)) {
      // Combine both selected floor types into one set to ensure unique values
      const combinedSelectedFloorTypes = new Set([...(selectedFloorTypes || []), ...(selectedFloorTypesForAdd || [])])

      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !combinedSelectedFloorTypes.has(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  // const checkForDuplicates = (floors1:any, floors2:any) => {
  //     const checkInternalDuplicates = (array:any) => {
  //         return array.map((floor:any, i:any) => {
  //             let isDuplicate = false;
  //             let errorMessage = '';

  //             array.forEach((otherFloor:any, j:any) => {
  //                 if (i !== j && floor.floorType === otherFloor.floorType && floor.name.toLowerCase() === otherFloor.name.toLowerCase()) {
  //                     const isOverlapping = !(floor.floorEndRange < otherFloor.floorStartRange || floor.floorStartRange > otherFloor.floorEndRange);
  //                     if (isOverlapping) {
  //                         isDuplicate = true;
  //                         errorMessage = 'Floor Name is duplicated';
  //                     }
  //                 }
  //             });

  //             return { ...floor, isDuplicate, errorMessage };
  //         });
  //     }

  //     const updatedFloors1 = checkInternalDuplicates(floors1);
  //     const updatedFloors2 = checkInternalDuplicates(floors2);

  //     updatedFloors1.forEach((floor1:any, i:any) => {
  //         if (!floor1?.floorType?.value) {
  //             floor1.isDuplicate = false
  //             floor1.errorMessage = ''
  //             return; // Skip this iteration if the floor1 item is empty
  //         }
  //         updatedFloors2.forEach((floor2:any, j:any) => {
  //             if (floor1.floorType.value === floor2.floorType) {
  //                 const isOverlapping = !(floor1.floorEndRange < floor2.floorStartRange || floor1.floorStartRange > floor2.floorEndRange);
  //                 if (isOverlapping) {
  //                     floor2.floorList.forEach((floorListItem:any,index:any) => {
  //                         // || floor1.name.toLowerCase() === `${floorListItem.name.toLowerCase()}`
  //                         if (floor1.name.toLowerCase() === floorListItem.prefix.toLowerCase() ) {
  //                             updatedFloors1[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
  //                             updatedFloors2[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
  //                         }
  //                     });
  //                 }
  //             }
  //         });
  //     })

  //     return { updatedFloors1, updatedFloors2 };
  // };

  const checkForDuplicates = (floors1: any, floors2: any) => {
    const checkInternalDuplicates = (array: any) => {
      return array.map((floor: any, i: any) => {
        let isDuplicate = false
        let errorMessage = ''

        array.forEach((otherFloor: any, j: any) => {
          if (i !== j && floor?.floorType === otherFloor?.floorType && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
            const isOverlapping = !(floor?.floorEndRange < otherFloor?.floorStartRange || floor?.floorStartRange > otherFloor?.floorEndRange)
            if (isOverlapping) {
              isDuplicate = true
              errorMessage = 'Floor Name is duplicated'
            }
          }
        })

        return {...floor, isDuplicate, errorMessage}
      })
    }

    const updatedFloors1 = checkInternalDuplicates(floors1)
    const updatedFloors2 = checkInternalDuplicates(floors2)

    updatedFloors1.forEach((floor1: any, i: any) => {
      if (!floor1?.floorType?.value) {
        floor1.isDuplicate = false
        floor1.errorMessage = ''
        return // Skip this iteration if the floor1 item is empty
      }

      for (let range = floor1.floorStartRange; range <= floor1.floorEndRange; range++) {
        updatedFloors2.forEach((floor2: any, j: any) => {
          if (floor1.floorType.value === floor2.floorType) {
            floor2.floorList.forEach((floorListItem: any) => {
              if (floorListItem.name?.includes('-')) {
                const [baseName, suffix] = floorListItem.name.toLowerCase().split('-')
                const isDuplicate = baseName === floor1.name.toLowerCase() && parseInt(suffix, 10) === range

                if (isDuplicate) {
                  updatedFloors1[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors2[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              } else {
                const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`
                if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
                  updatedFloors1[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors2[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              }
            })
          }
        })
      }
    })

    return {updatedFloors1, updatedFloors2}
  }

  const checkForDuplicatesForBuildingCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  const handleRemoveDevelopmenetBuilding = (index: any,clusterIndex:any,buildingIndex:any,floorIndex:any, key: any) => {
    //  key = 1 => buildingDevelopmentFloor
    //  key = 2 => buildingDevelopmentUpdateFloor
    const updateCluster = [...allCluster]
    const values = [...updateCluster[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
    if (key === 1) {
      values.splice(floorIndex, 1)
      updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
      setCluster(updateCluster)
      
      // setBuildingDevelopmentFloor(values)
    }
    if (key === 2) {
      const floorDeleteListTemp = updateCluster[index].updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor
      const floorlistIds = updateCluster[index].updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[
        floorIndex
      ].floorList?.map((item: any) => ({_id: item._id}))

       updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].deleteFloor = [
        ...floorDeleteListTemp,
        ...floorlistIds,
      ]


      values.splice(floorIndex, 1)
      updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
      setCluster(updateCluster)
      // setBuildingDevelopmentUpdateFloor(valuesForUpdate)
    }

    // let sortedArr:any = values?.sort((a: any, b: any) => {
    //   if (a.sortIndex < b.sortIndex) return -1
    //   if (a.sortIndex > b.sortIndex) return 1
    //   return 0
    // })

    // const {addFloor,updateFloor} = generateFloorForEditBuilding(sortedArr)
    // updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].addFloor = addFloor
    // updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].updateFloor = updateFloor
    // setCluster(updateCluster)

    const updatedValues = checkDuplicatesForMergeArray(values)
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
    setCluster(updateCluster)
    setIsCTAHidden(false)

    // const {updatedFloors1, updatedFloors2} = checkForDuplicates(values, valuesForUpdate)
    // setBuildingDevelopmentFloor(updatedFloors1)
    // setBuildingDevelopmentUpdateFloor(updatedFloors2)
  }

  
  const generateFloorForEditBuilding = (data:any) =>{
    let newInd = 1;
    const updArray = [];
    const newArray = [];
    for(let i = 0;i<data.length;i++){
      // console.log(updatedValues[i]?.noSubListRowUI)
      if(data[i]?.subListRowUI){
        // console.log('update',true)
        // newArray.push(updatedValues[newInd])
        for(let j = 0; j < data[i]?.floorList?.length;j++){
          updArray.push({
            _id: data[i]?.floorList[j]?._id,
            floorType: data[i]?.floorList[j]?.floorType,
            name: data[i]?.floorList[j]?.name,
            index: newInd,
            prefix: data[i]?.floorList[j]?.prefix,
          })
          newInd += 1
        }
      

        // newArray[newArray?.length-1].index = i
        
      } else if(data[i]?.noSubListRowUI){
        // console.log('add',true)
        // newArray.push(updatedValues[newInd])
        for(let j = data[i]?.floorStartRange; j <= data[i]?.floorEndRange;j++){
          newArray.push({
            floorType: data[i].floorType.value,
            name: data[i].floorType.value === 0 ? data[i].name : data[i].name + ` ${j}`,
            index: newInd,
            prefix: data[i].name,
          })
          newInd += 1
        }

        // newArray[newArray?.length-1].index = i
        
      }
    
    }
    return {addFloor:newArray,updateFloor:updArray}
  }

  const checkDuplicatesForMergeArray = (floors: any) => {
    const checkInternalDuplicates = (array: any) => {
      return array.map((floor: any, i: any) => {
        let isDuplicate = false
        let errorMessage = ''
  
        array.forEach((otherFloor: any, j: any) => {
          if (
            i !== j &&
            floor?.floorType === otherFloor?.floorType &&
            floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()
          ) {
            const isOverlapping = !(
              floor?.floorEndRange < otherFloor?.floorStartRange ||
              floor?.floorStartRange > otherFloor?.floorEndRange
            )
            if (isOverlapping) {
              isDuplicate = true
              errorMessage = 'Floor Name is duplicated'
            }
          }
        })
  
        return { ...floor, isDuplicate, errorMessage }
      })
    }
  
    const updatedFloors = checkInternalDuplicates(floors)
  
    updatedFloors?.forEach((floor1: any, i: any) => {
      if (!floor1?.floorType?.value) {
        floor1.isDuplicate = false
        floor1.errorMessage = ''
        return // Skip this iteration if the floor1 item is empty
      }
  
      for (let range = floor1.floorStartRange; range <= floor1.floorEndRange; range++) {
        updatedFloors.forEach((floor2: any, j: any) => {
          console.log(floor2)
          if (floor1.floorType.value === floor2.floorType) {
            floor2.floorList.forEach((floorListItem: any) => {
              if (floorListItem.name?.includes('-')) {
                const [baseName, suffix] = floorListItem.name.toLowerCase().split('-')
                const isDuplicate = baseName === floor1.name.toLowerCase() && parseInt(suffix, 10) === range
  
                if (isDuplicate) {
                  updatedFloors[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
                  updatedFloors[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
                }
              } else {
                const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`
                if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
                  updatedFloors[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
                  updatedFloors[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' }
                }
              }
            })
          }
        })
      }
    })
  
    return updatedFloors
  }

  const handleRemoveFloorListItem = (
    index:any,
    clusterIndex:any,
    buildingIndex:any,
    floorIndex:any,
    itemIndex:any
  ) => {
    const updateCluster = [...allCluster]
    const values = [...updateCluster[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
    
    const floorlistId = values[floorIndex]?.floorList[itemIndex]?._id
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].deleteFloor.push({_id: floorlistId})
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
    values[floorIndex]?.floorList?.splice(itemIndex, 1)

    setCluster(updateCluster)

    // let sortedArr:any = values?.sort((a: any, b: any) => {
    //   if (a.sortIndex < b.sortIndex) return -1
    //   if (a.sortIndex > b.sortIndex) return 1
    //   return 0
    // })
    // generateFloorForEditBuilding(sortedArr)

          
    const updatedValues = checkDuplicatesForMergeArray(values)
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
    setCluster(updateCluster)
    setIsCTAHidden(false)
  }


  function splitTextAndNumber(input:any) {
    // Regular expression to match text and number parts
    const match = input.match(/^([a-zA-Z]+)(\d+)?$/);
  
    if (match) {
      const textPart = match[1];
      const numberPart = match[2] ? match[2] : null; // numberPart will be null if there's no number
      return { textPart, numberPart };
    } else {
      return null; // Input doesn't match the expected pattern
    }
  }

  const checkBeforeDeleteForBuildingCluster = async (index:any,clusterIndex:any) => {
    const buildingClusterID = allCluster[index].updateBuildingCluster[clusterIndex]?._id
    await ApiGet(`corporate/cluster/deletion_checker/${buildingClusterID}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if(paymentDataStatus || tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
          // ErrorToast('Unable To Delete Cluster. The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
          swal
          .fire({
            html: `<div class='fnt-700'>Unable To Delete Cluster</div>
            <div class='mt-4'>The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
            showConfirmButton: true,
            confirmButtonText: 'Okay',
            showCancelButton: false,
            cancelButtonText: 'No',
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              popup: 'custom-popup',
            },
            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
          })
          .then((res: any) => {
                                                   
          })
        }else{
          swal
              .fire({
                html: `
                    <div class='fs-4 mt-4 fw-bold'> 
                    Delete Building Cluster
                      </div>
                    <div class='fs-5 mt-4'> 
                    You are about to delete this building cluster.
                      </div>
                        <div class='mt-1 fs-5'>
                        Deleting this building cluster will also delete all existing buildings and properties added to it.
                        </div>
                        <div class='mt-4 fs-5 fw-bold'>
                        Are you sure you want to continue?
                        </div>
                      `,
                // icon: 'warning',
                showConfirmButton: true,
                confirmButtonColor: '#fff',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'No',
                cancelButtonColor: '#0d9e91',
                customClass: {
                  confirmButton: 'custom-confirm-button-2',
                  cancelButton: 'custom-cancel-button-2',
                  popup: 'custom-popup',
                },
                reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
              })
              .then((res: any) => {
                if (res.isConfirmed) {
                  handleRemoveClusterForUpdateBuilding(index, clusterIndex)
                }
              })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })

  }
  const checkBeforeDeleteForBuilding = async (index:any,clusterIndex:any,buildingIndex:any) => {
    const buildingID = allCluster[index].updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?._id
    await ApiGet(`corporate/building/deletion_checker/${buildingID}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if(paymentDataStatus || tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
          // ErrorToast('Unable To Delete Building. The properties under this building have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
          swal
          .fire({
            html: `<div class='fnt-700'>Unable To Delete Building</div>
            <div class='mt-4'>The properties under this building have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
            showConfirmButton: true,
            confirmButtonText: 'Okay',
            showCancelButton: false,
            cancelButtonText: 'No',
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              popup: 'custom-popup',
            },
            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
          })
          .then((res: any) => {
                                                   
          })
        }else{
          swal.fire({
                html: `
                      <div class='fs-4 mt-4 fw-bold'> 
                      Delete Building
                        </div>
                      <div class='fs-5 mt-4'> 
                          You are about to delete this building.
                        </div>
                          <div class='mt-1 fs-5'>
                          Deleting this building will also delete all existing floors and properties added to it.
                          </div>
                          <div class='mt-4 fs-5 fw-bold'>
                          Are you sure you want to continue?
                          </div>`,
                                      // icon: 'warning',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#fff',
                                      confirmButtonText: 'Yes',
                                      showCancelButton: true,
                                      cancelButtonText: 'No',
                                      cancelButtonColor: '#0d9e91',
                                      customClass: {
                                        confirmButton: 'custom-confirm-button-2',
                                        cancelButton: 'custom-cancel-button-2',
                                        popup: 'custom-popup',
                                      },
                                      reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
                                    })
                                    .then((res: any) => {
                                      if (res.isConfirmed) {
                                        handleRemoveBuildingForUpdateBuildingCluster(index, clusterIndex, buildingIndex)
                                      }
                                    })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })

  }

  const checkBeforeDeleteForFloorBuildingCluster = async (index:any,clusterIndex:any,buildingIndex:any,floorIndex:any) => {
    const floorlistIds = allCluster[index].updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[
     floorIndex
   ].floorList?.map((item: any) => ({_id: item._id}))

   const countArr : any = []
   
   for(let i=0;i<floorlistIds?.length;i++){
     await ApiGet(`corporate/floor/deletion_checker/${floorlistIds[i]?._id}`)
     .then((res) => {
       let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
       let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
       let isAbleToDelete = res?.data?.data?.isAbleToDelete
       let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
       if(paymentDataStatus || tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
         countArr.push(false)
       }else{
         countArr.push(true)
       }
     })
     .catch((err) => {
       ErrorToast(err.message)
       if (err?.message === 'Your account has been suspended!') {
         window.location.pathname = '/auth'
         localStorage.clear()
       }
     })
   }
   if(countArr?.length == 0) return
   if(countArr.every((item:any) => item === true)){
     swal.fire({
       html: `
       <div class='fs-4 mt-4 fw-bold'> 
       Delete Floor
       </div>
       <div class='fs-5 mt-4'> 
           You are about to delete this floor.
       </div>
           <div class='mt-1 fs-5'>
           Deleting this floor will also delete all existing properties added to it.
           </div>
           <div class='mt-4 fs-5 fw-bold'>
           Are you sure you want to continue?
           </div>`,
       // icon: 'warning',
       showConfirmButton: true,
       confirmButtonColor: '#fff',
       confirmButtonText: 'Yes',
       showCancelButton: true,
       cancelButtonText: 'No',
       cancelButtonColor: '#0d9e91',
       customClass: {
         confirmButton: 'custom-confirm-button-2',
         cancelButton: 'custom-cancel-button-2',
         popup: 'custom-popup',
       },
       reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
     })
     .then((res: any) => {
       if (res.isConfirmed) {
        handleRemoveDevelopmenetBuilding(
          index, clusterIndex, buildingIndex, floorIndex, 2
        )
       }
     })
   }else{
    //  ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
    swal
    .fire({
      html: `<div class='fnt-700'>Unable To Delete Floor</div>
      <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
      showConfirmButton: true,
      confirmButtonText: 'Okay',
      showCancelButton: false,
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        popup: 'custom-popup',
      },
      reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
    })
    .then((res: any) => {
                                             
    })
   }

   console.log(countArr)

 }

 const checkBeforeDeleteForSingleFloor = async (index:any,clusterIndex:any,buildingIndex:any,floorIndex:any,itemIndex:any) => {
  const floorlistIds =  allCluster[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList[itemIndex]?._id

   await ApiGet(`corporate/floor/deletion_checker/${floorlistIds}`)
   .then((res) => {
     let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
     let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
     let isAbleToDelete = res?.data?.data?.isAbleToDelete
     let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
     if(paymentDataStatus || tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
      //  ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
      swal
      .fire({
        html: `<div class='fnt-700'>Unable To Delete Floor</div>
        <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
        showConfirmButton: true,
        confirmButtonText: 'Okay',
        showCancelButton: false,
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'custom-confirm-button',
          cancelButton: 'custom-cancel-button',
          popup: 'custom-popup',
        },
        reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
      })
      .then((res: any) => {
                                               
      })
     }else{
      swal.fire({
        html: `
        <div class='fs-4 mt-4 fw-bold'> 
        Delete Floor
        </div>
        <div class='fs-5 mt-4'> 
            You are about to delete this floor.
        </div>
            <div class='mt-1 fs-5'>
            Deleting this floor will also delete all existing properties added to it.
            </div>
            <div class='mt-4 fs-5 fw-bold'>
            Are you sure you want to continue?
            </div>`,
        // icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#fff',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: '#0d9e91',
        customClass: {
          confirmButton: 'custom-confirm-button-2',
          cancelButton: 'custom-cancel-button-2',
          popup: 'custom-popup',
        },
        reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
      })
      .then((res: any) => {
        if (res.isConfirmed) {
          handleRemoveFloorListItem(
            index,
            clusterIndex,
            buildingIndex,
            floorIndex,
            itemIndex

          )
        }
      })
     }
   })
   .catch((err) => {
     ErrorToast(err.message)
     if (err?.message === 'Your account has been suspended!') {
       window.location.pathname = '/auth'
       localStorage.clear()
     }
   })
}

  return (
    <>
      <div className='row' style={{marginTop: '50px'}}>
        <div className='col-8 mx-auto text-center mb-2'>
          <h2 className='m-0 head-text'>
            <b>Building Clusters</b>
          </h2>
        </div>
        <div className='col-10 mx-auto text-center'>
          <h4 className='m-0 head-text mb-1'>Set the unit clusters existing within your community</h4>
          <h4 className='m-0 head-text mb-1'>You will be able to add the properties under each unit cluster once they have been added.</h4>

          <div className='my-7 d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-submit-btn '
              style={{width: 'fit-content'}}
              onClick={() => handleAddBuildingCluster(index)}
            >
              <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building Cluster
            </button>
          </div>
        </div>

        <div className='col-12 mx-auto' style={{marginTop: '20px'}}>
          <div className='row d-flex' style={{rowGap: '10px'}}>
            {allCluster[index]?.updateBuildingCluster?.map((clusterItem: any, buildingClusterIndex: any) => (
              <div className='col-12'>
                <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '50px'}}>
                  {allCluster[index]?.updateBuildingCluster?.length >= 1 && (
                    <div style={{position: 'absolute', top: '2px', right: '12px'}}>
                      <img
                        style={{cursor: 'pointer'}}
                        src={trashImg}
                        height={14}
                        width={14}
                        onClick={() => {
                          checkBeforeDeleteForBuildingCluster(index,buildingClusterIndex)
                        }}
                      />
                    </div>
                  )}
                  <div className='row'>
                    <div className='text-center col-3 mx-auto'>
                      <h3 className='m-0 head-text mb-1 ms-1'>Building Cluster</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter Building Cluster Name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Building Cluster'}}
                            value={clusterItem?.name}
                            onChange={(e: any) => {
                              const values = [...allCluster]
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              values[index].updateBuildingCluster[buildingClusterIndex].name = captilizeWord
                              setCluster(values)
                              setIsCTAHidden(false)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3 mx-auto text-center  my-7'>
                      <div className='d-flex justify-content-center'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold px-2 green-submit-btn '
                          style={{width: 'fit-content'}}
                          onClick={() => handleAddBuildingForBuildingClusterUpdate(index, buildingClusterIndex)}
                        >
                          <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                        </button>
                      </div>
                      <h4 className='m-0 head-text mt-3 mb-1'>Add a building under this cluster</h4>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      {clusterItem?.updateBuilding?.map((buildingItem: any, buildingIndex: any) => (
                        <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                          {allCluster[index]?.updateBuildingCluster?.length >= 1 && (
                            <div style={{position: 'absolute', top: '2px', right: '12px'}}>
                              <img
                                style={{cursor: 'pointer'}}
                                src={trashImg}
                                height={14}
                                width={14}
                                onClick={() => {
                                  checkBeforeDeleteForBuilding(index, buildingClusterIndex, buildingIndex)
                                }}
                              />
                            </div>
                          )}
                          <div className='row'>
                            <div className='col-8 d-flex align-items-start'>
                              <div className='me-4'>
                                <h3 className='m-0 head-text mb-1 ms-1'>Building</h3>
                                <div className='wxyz'>
                                  <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                      placeholder='Enter Building Name'
                                      style={inputBaseStyle}
                                      inputProps={{'aria-label': 'Building name'}}
                                      value={buildingItem?.name}
                                      onChange={(e: any) => {
                                        const values = [...allCluster]
                                        const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                        values[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].name = captilizeWord
                                        setCluster(values)
                                        setIsCTAHidden(false)
                                      }}
                                    />
                                  </Paper>
                                </div>
                              </div>
                              <div className='text-center'>
                                <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                  {`${calculateTotalFloorsForUpdateBuilding(index, buildingClusterIndex, buildingIndex)}`}
                                </h3>
                              </div>
                            </div>

                            {allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding?.length > 1 && (
                              <div className='col-4 d-flex justify-content-end '>
                                <img
                                  style={{cursor: 'pointer'}}
                                  src={redCross}
                                  height={14}
                                  width={14}
                                  onClick={() => {
                                    handleRemoveBuildingClusterBuilding(index, buildingClusterIndex, buildingIndex)
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          {/* add floor */}
                          <div className='row my-7'>
                            <div className='col-12'>
                              <div className='d-flex align-items-center'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold create-st d-flex justify-content-center px-2 me-4'
                                  style={{width:'120px'}}
                                  onClick={() => addFloorHandlerForUpdateBuilding(index, buildingClusterIndex, buildingIndex)}
                                >
                                  Add Floor
                                </button>

                                <p className='m-0 head-text'>
                                Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12'>
                              <table className='table  gy-5'>
                                <thead>
                                  <tr>
                                    <th className='head-text text-start p-0 pb-2' style={{maxWidth: '20px', minWidth: '20px'}}></th>
                                    <th className='head-text text-start pb-2' style={{minWidth: '170px', maxWidth: '170px'}}>
                                      Floors
                                      <span className='ms-2'>
                                        <OverlayTrigger
                                          placement='right'
                                          delay={{show: 250, hide: 200}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of
                                              each floor added, giving each floor it’s own name. If you do not wish to include a prefix, you may leave
                                              this field blank. You will be able to edit any floor name once it has been added.
                                              <br></br>
                                              <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start
                                              and end based on the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect
                                              the same floor names within your building. If you are adding a single floor your ‘Start Range’ and ‘ End
                                              Range’ should include the same value.
                                              <br></br>
                                              <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’
                                              and ‘End Range’ will be included in the total count.
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineExclamationCircle
                                              color='#146C6A '
                                              fontSize={14}
                                              fontWeight={500}
                                              className='cursor-pointer'
                                              style={{cursor: 'pointer', marginBottom: '2px'}}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </th>
                                    <th className='head-text text-start p-0 ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                                      Floor Name
                                    </th>
                                    <th className='head-text text-start p-0 ps-5 pb-2' style={{minWidth: '220px'}}></th>
                                    <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '50px'}}>
                                      Example
                                    </th>
                                    <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '100px'}}>
                                      Total Floors
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='text-start'>
                                   {allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.sort((a: any, b: any) => {
                              if (a.sortIndex < b.sortIndex) return -1
                              if (a.sortIndex > b.sortIndex) return 1
                              return 0
                            })?.map(
                                  (floorItem: any, floorIndex: any) => {
                                   if(floorItem?.subListRowUI) {
                                    return <tr
                                    key={floorIndex}
                                    className={`${floorItem.animationClass} ${
                                      floorItem.isDuplicate ? 'redBox' : ''
                                    }`}
                                  >
                                    <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                      <span
                                        className={`${
                                          floorItem.floorList.length > 1 ? 'me-3' : ''
                                        }`}
                                      >
                                        {floorIndex === 0 &&
                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                            <img
                                              onClick={() =>
                                                swapRowForForMergeArray(
                                                  floorIndex,
                                                  floorIndex + 1,
                                                  index,
                                                  buildingClusterIndex,
                                                  buildingIndex,
                                                  floorIndex
                                                )

                                              }
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowDown}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                        {floorIndex ===
                                          buildingItem?.mergeFloorArray?.length - 1 &&
                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                            <img
                                              onClick={() =>
                                                swapRowForForMergeArray(
                                                  floorIndex - 1,
                                                  floorIndex,
                                                  index,
                                                  buildingClusterIndex,
                                                  buildingIndex,
                                                  floorIndex
                                                )

                                              }
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowUp}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                        {floorIndex !== 0 &&
                                          floorIndex !==
                                          buildingItem?.mergeFloorArray.length - 1 && (
                                            <span className='d-flex flex-column'>
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowUp}
                                                height={12}
                                                width={12}
                                                onClick={() =>
                                                  swapRowForForMergeArray(
                                                    floorIndex - 1,
                                                    floorIndex,
                                                    index,
                                                    buildingClusterIndex,
                                                    buildingIndex,
                                                    floorIndex
                                                  )

                                                }
                                              />
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowDown}
                                                height={12}
                                                width={12}
                                                onClick={() =>
                                                  swapRowForForMergeArray(
                                                    floorIndex ,
                                                    floorIndex + 1,
                                                    index,
                                                    buildingClusterIndex,
                                                    buildingIndex,
                                                    floorIndex
                                                  )

                                                }
                                              />
                                            </span>
                                          )}
                                      </span>
                                    </td>
                                    <td
                                     className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}
                                    >
                                      <div>
                                        <Select
                                          styles={{
                                            ...customStyles,
                                            container: (defaultStyles: any) => ({
                                              ...defaultStyles,
                                              width: '100%',
                                            }),
                                          }}
                                          isSearchable={false}
                                          // options={floorOptions}
                                          options={floorOptionsForMergeFloors(index,buildingClusterIndex,buildingIndex,floorIndex)}
                                          value={
                                            floorOptions.filter(
                                              (item: any) => item.value == floorItem?.floorType
                                            )?.[0]
                                          }
                                          name='floor'
                                          onChange={(e: any) => {
                                            const updateCluster = [...allCluster]
                                            const values = [...allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                            values[floorIndex].floorType =
                                              e.value
                                              values[floorIndex].floorList =
                                              values[floorIndex]?.floorList?.map((item: any, i: any) => ({
                                                _id: item._id,
                                                floorType: e.value,
                                                name: item.name,
                                                index: item.index,
                                                prefix: item.prefix,
                                              }))
                                            allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                            setCluster(updateCluster)
                                                  
                                            const updatedValues = checkDuplicatesForMergeArray(values)
                                            updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                            setCluster(updateCluster)
                                            setIsCTAHidden(false)
                                          }}
                                          placeholder={'Select Floor Type'}
                                        />
                                      </div>
                                      {floorItem.isDuplicate && (
                                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                                          Floor Name is duplicated
                                        </p>
                                      )}
                                    </td>
                                    <td
                                   className='p-0  py-3' style={{minWidth: '100px', maxWidth: '100px'}}
                                    >
                                      <tr
                                        className='d-flex justify-content-between align-items-center'
                                        style={{columnGap: '10px'}}
                                      >
                                        <td className='p-0 ps-7'>
                                          <div className='wxyz'>
                                            <Paper component='form' style={searchContainerStyle}>
                                              <InputBase
                                                placeholder='Prefix'
                                                style={inputBaseStyle}
                                                inputProps={{'aria-label': 'Prefix'}}
                                                value={floorItem?.prefix}
                                                onChange={(e: any) => {
                                                  const updateCluster = [...allCluster]
                                                  const values = [...allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                                  // buildingDevelopmentUpdateFloor[floorIndex].prefix = e.target.value
                                                  const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                  values[floorIndex].prefix = captilizeWord
                                                  values[floorIndex].floorList = values[floorIndex]?.floorList.map((item: any, i: any) => {
                                                    const floorNumbers  = item.name?.split(' ')[1] || item.name?.split('-')[1] 
                                                    if(item?.name?.match(/^([a-zA-Z]+)(\d+)?$/)){
                                                      return {
                                                        _id: item._id,
                                                        floorType: item.floorType,
                                                        // name: `${e.target.value}-${i + 1}`,
                                                        name:
                                                        values[floorIndex]
                                                            .floorType == 0
                                                            ? `${captilizeWord}`
                                                            : `${captilizeWord}`,
                                                        // name:item.name,
                                                        index: item.index,
                                                        prefix: captilizeWord,
                                                      }
                                                    }else{
                                                      return {
                                                        _id: item._id,
                                                        floorType: item.floorType,
                                                        // name: `${e.target.value}-${i + 1}`,
                                                        name:
                                                        values[floorIndex]
                                                            .floorType == 0
                                                            ? `${captilizeWord}`
                                                            : `${captilizeWord} ${floorNumbers}`,
                                                        // name:item.name,
                                                        index: item.index,
                                                        prefix: captilizeWord,
                                                      }
                                                      
                                                    }
                                                  })
                                                 
                                                  allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                  setCluster(updateCluster)
                                                  // let sortedArr:any = values?.sort((a: any, b: any) => {
                                                  //   if (a.sortIndex < b.sortIndex) return -1
                                                  //   if (a.sortIndex > b.sortIndex) return 1
                                                  //   return 0
                                                  // })
                                                  // generateFloorForEditBuilding(sortedArr)
      
                                                  const updatedValues = checkDuplicatesForMergeArray(values)
                                                  updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                  setCluster(updateCluster)
                                                  setIsCTAHidden(false)
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </td>
                                        <td>
                                          <div className=''>
                                            <div className='br-arr d-flex align-items-center justify-content-center'>
                                              {floorItem.isShowFloors == false ? (
                                                <img
                                                  src={downGreen}
                                                  height={20}
                                                  width={20}
                                                  className='cursor-pointer'
                                                  onClick={() => {
                                                    const updateCluster = [...allCluster]
                                                    const values = [...allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                                    values[floorIndex].isShowFloors = true
                                                    // setMergeBuildingDevelopmenFloor(values)
                                                    allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                    setCluster(updateCluster)
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={upGreen}
                                                  height={20}
                                                  width={20}
                                                  className='cursor-pointer'
                                                  onClick={() => {
                                                    const updateCluster = [...allCluster]
                                                    const values = [...allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                                    values[floorIndex].isShowFloors = false
                                                    allCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                    setCluster(updateCluster)
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <div>
                                        {floorItem.isShowFloors && (
                                          <>
                                            {floorItem?.floorList?.map((item: any, itemIndex: any) => (
                                              <div className={`my-2 d-flex align-items-center `}>
                                                <span
                                                  className={`${
                                                    floorItem?.floorList.length > 1
                                                      ? 'me-3'
                                                      : ''
                                                  }`}
                                                >
                                                  {itemIndex === 0 &&
                                                    floorItem?.floorList.length > 1 && (
                                                      <img
                                                        onClick={() =>
                                                          swapItems(
                                                            itemIndex,
                                                            itemIndex + 1,
                                                            index,
                                                            buildingClusterIndex,
                                                            buildingIndex,
                                                            floorIndex,
                                                            itemIndex
                                                          )

                                                        }
                                                        style={{cursor: 'pointer'}}
                                                        src={carrotArrowDown}
                                                        height={12}
                                                        width={12}
                                                      />
                                                    )}

                                                  {itemIndex ===
                                                    floorItem?.floorList.length -
                                                      1 &&
                                                      floorItem
                                                      ?.floorList.length > 1 && (
                                                      <img
                                                        onClick={() =>
                                                          swapItems(
                                                            itemIndex - 1,
                                                            itemIndex,
                                                            index,
                                                            buildingClusterIndex,
                                                            buildingIndex,
                                                            floorIndex,
                                                            itemIndex
                                                          )

                                                        }
                                                        style={{cursor: 'pointer'}}
                                                        src={carrotArrowUp}
                                                        height={12}
                                                        width={12}
                                                      />
                                                    )}
                                                  {itemIndex !== 0 &&
                                                    itemIndex !==
                                                    floorItem
                                                        ?.floorList.length -
                                                        1 && (
                                                      <span className='d-flex flex-column'>
                                                        <img
                                                          style={{cursor: 'pointer'}}
                                                          src={carrotArrowUp}
                                                          height={12}
                                                          width={12}
                                                          onClick={() =>
                                                            swapItems(
                                                              itemIndex - 1,
                                                              itemIndex,
                                                              index,
                                                              buildingClusterIndex,
                                                              buildingIndex,
                                                              floorIndex,
                                                              itemIndex
                                                            )

                                                          }
                                                        />
                                                        <img
                                                          style={{cursor: 'pointer'}}
                                                          src={carrotArrowDown}
                                                          height={12}
                                                          width={12}
                                                          onClick={() =>
                                                            swapItems(
                                                              itemIndex,
                                                              itemIndex + 1,
                                                              index,
                                                              buildingClusterIndex,
                                                              buildingIndex,
                                                              floorIndex,
                                                              itemIndex
                                                            )

                                                          }
                                                        />
                                                      </span>
                                                    )}
                                                </span>
                                                <div
                                                  className={`wxyz flex-grow-1 ${
                                                    item.animationClass
                                                  } ${
                                                    floorItem
                                                      ?.floorList.length == 1
                                                      ? 'ps-7'
                                                      : ''
                                                  }`}
                                                >
                                                  <Paper
                                                    component='form'
                                                    style={searchContainerStyle}
                                                  >
                                                    <InputBase
                                                      placeholder=''
                                                      style={inputBaseStyle}
                                                      inputProps={{'aria-label': ''}}
                                                      value={item?.name}
                                                      onChange={(e: any) => {
                                                        const updateCluster = [...allCluster]
                                                        const values = [...allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                                        values[floorIndex].floorList[itemIndex].name = e.target.value

                                                        updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                        // setCluster(updateCluster)
                                                        // setIsCTAHidden(false)
                                                        const updatedValues = checkDuplicatesForMergeArray(values)
                                                        updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                        setCluster(updateCluster)
                                                        setIsCTAHidden(false)
                                                      }}
                                                    />
                                                  </Paper>
                                                </div>
                                                <span className='ms-3'>
                                                  <img
                                                    style={{cursor: 'pointer'}}
                                                    src={trashImg}
                                                    height={14}
                                                    width={14}
                                                    onClick={() => {
                                                      checkBeforeDeleteForSingleFloor( index,
                                                        buildingClusterIndex,
                                                        buildingIndex,
                                                        floorIndex,
                                                        itemIndex)
                                                      
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td className='p-0  ps-7 pb-7 ' style={{minWidth: '200px', maxWidth: '220px'}}></td>
                                    <td className='p-0 ps-7 pb-7 ' style={{minWidth: '50px', maxWidth: '50px'}}></td>

                                    <td
                                      className='p-0 ps-5 text-center pb-7 min-w-100px'
                                      style={{maxWidth: '100px'}}
                                    >
                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                        <div className='mt-1'>
                                          <h3 className='m-0 head-text me-7'>
                                            {
                                              floorItem?.floorList?.length
                                            }
                                          </h3>
                                        </div>
                                        {buildingItem?.mergeFloorArray.length >= 1 && (
                                          <img
                                            className=''
                                            style={{cursor: 'pointer'}}
                                            src={trashImg}
                                            height={14}
                                            width={14}
                                           onClick={() => {
                                            checkBeforeDeleteForFloorBuildingCluster(index, buildingClusterIndex, buildingIndex, floorIndex)
                                           }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                   }

                                   if(floorItem?.noSubListRowUI){
                                    return <tr
                                    key={floorIndex}
                                    className={`${floorItem.animationClass} ${
                                      floorItem.isDuplicate ? 'redBox' : ''
                                    }`}
                                  >
                                    <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                      <span
                                        className={`${
                                          floorItem.floorList?.length > 1 ? 'me-3' : ''
                                        }`}
                                      >
                                        {floorIndex === 0 &&
                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                            <img
                                              onClick={() =>
                                                swapRowForForMergeArray(
                                                  floorIndex,
                                                  floorIndex + 1,
                                                  index,
                                                  buildingClusterIndex,
                                                  buildingIndex,
                                                  floorIndex
                                                )
                                              }
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowDown}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                        {floorIndex === buildingItem?.mergeFloorArray?.length - 1 &&
                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                            <img
                                              onClick={() =>
                                                swapRowForForMergeArray(
                                                  floorIndex - 1,
                                                  floorIndex,
                                                  index,
                                                  buildingClusterIndex,
                                                  buildingIndex,
                                                  floorIndex
                                                )
                                              }
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowUp}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                        {floorIndex !== 0 &&
                                          floorIndex !== buildingItem?.mergeFloorArray?.length - 1 && (
                                            <span className='d-flex flex-column'>
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowUp}
                                                height={12}
                                                width={12}
                                                onClick={() =>
                                                  swapRowForForMergeArray(
                                                    floorIndex - 1,
                                                    floorIndex,
                                                    index,
                                                    buildingClusterIndex,
                                                    buildingIndex,
                                                    floorIndex
                                                  )
                                                }
                                              />
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowDown}
                                                height={12}
                                                width={12}
                                                onClick={() =>
                                                  swapRowForForMergeArray(
                                                    floorIndex ,
                                                    floorIndex + 1,
                                                    index,
                                                    buildingClusterIndex,
                                                    buildingIndex,
                                                    floorIndex
                                                  )
                                                }
                                              />
                                            </span>
                                          )}
                                      </span>
                                    </td>
                                    <td
                                     className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}
                                    >
                                      <div>
                                        <Select
                                          styles={{
                                            ...customStyles,
                                            container: (defaultStyles: any) => ({
                                              ...defaultStyles,
                                              width: '100%',
                                            }),
                                          }}
                                          isSearchable={true}
                                          options={floorOptionsForMergeFloors(index,buildingClusterIndex,buildingIndex,floorIndex)}
                                          value={floorItem.floorType}
                                          name='floor'
                                          onChange={(e: any) => {
                                            const updateCluster = [...allCluster]
                                            const values = [...updateCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                            values[floorIndex].floorType = e

                                            if (e.value === 0) {
                                              values[floorIndex].startRangeError = false
                                              values[floorIndex].endRangeError = false
                                              values[floorIndex].floorStartRange = 1
                                              values[floorIndex].floorEndRange = 1
                                            }

                                            updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                            setCluster(updateCluster)
                                            
                                            // let sortedArr:any = values?.sort((a: any, b: any) => {
                                            //   if (a.sortIndex < b.sortIndex) return -1
                                            //   if (a.sortIndex > b.sortIndex) return 1
                                            //   return 0
                                            // })
                                            // generateFloorForEditBuilding(sortedArr)
                                            
                                            const updatedValues = checkDuplicatesForMergeArray(values)
                                            updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                            setCluster(updateCluster)
                                            setIsCTAHidden(false)
                                         
                                          }}
                                          placeholder={'Select Floor Type'}
                                        />
                                      </div>
                                      {floorItem.isDuplicate && (
                                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                                          Floor Name is duplicated
                                        </p>
                                      )}
                                    </td>
                                    <td
                                      className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}
                                    >
                                      <div className='wxyz'>
                                        <Paper component='form' style={searchContainerStyle}>
                                          <InputBase
                                            placeholder='Prefix'
                                            style={inputBaseStyle}
                                            inputProps={{'aria-label': 'Prefix'}}
                                            value={floorItem?.name}
                                            onChange={(e: any) => {
                                              const updateCluster = [...allCluster]
                                              const values = [...updateCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]

                                              const captilizeWord = capitalizeFirstLetter(
                                                e.target.value?.trimStart()
                                              )
                                              values[floorIndex].name = captilizeWord
                                              updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                              setCluster(updateCluster)

                                              // let sortedArr:any = values?.sort((a: any, b: any) => {
                                              //     if (a.sortIndex < b.sortIndex) return -1
                                              //     if (a.sortIndex > b.sortIndex) return 1
                                              //     return 0
                                              //   })
                                              //   generateFloorForEditBuilding(sortedArr)
                                              

                                                  
                                              const updatedValues = checkDuplicatesForMergeArray(values)
                                              updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                              setCluster(updateCluster)
                                              setIsCTAHidden(false)
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </td>
                                    <td
                                     className='p-0 ps-7 py-3 ' style={{minWidth: '200px', maxWidth: '220px'}}
                                    >
                                      <div className='d-flex ' style={{columnGap: '10px'}}>
                                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                          <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                              disabled={floorItem?.floorType?.value === 0}
                                              type={
                                                floorItem?.floorType?.value === 0
                                                  ? 'text'
                                                  : 'number'
                                              }
                                              placeholder='Start Range'
                                              style={inputBaseStyle}
                                              inputProps={{'aria-label': 'Start Range',min:1}}
                                              value={
                                                floorItem?.floorType?.value === 0
                                                  ? '-'
                                                  : floorItem?.floorStartRange
                                              }
                                              onChange={(e: any) => {
                                                if (e.target.value < 0) {
                                                  return;
                                                }
                                                const newValue = e.target.value.replace(
                                                  /^0+(?=\d)/,
                                                  ''
                                                )
                                                const updateCluster = [...allCluster]
                                                const values = [...updateCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]

                                                if (
                                                  floorItem?.floorEndRange !== '' &&
                                                  floorItem?.floorEndRange < Number(e.target.value) &&
                                                  e.target.value != ''
                                                ) {
                                                  values[floorIndex].startRangeError = true
                                                } else {
                                                  values[floorIndex].startRangeError = false
                                                }

                                                if (
                                                  floorItem?.floorEndRange !== '' &&
                                                  floorItem?.floorEndRange >= Number(e.target.value) &&
                                                  e.target.value != ''
                                                ) {
                                                  values[floorIndex].endRangeError = false
                                                }
                                                values[floorIndex].floorStartRange = newValue ? Number(newValue) : ''
                                                updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                setCluster(updateCluster)

                                                // if((!values[floorIndex].endRangeError && values[floorIndex].startRangeError)){
                                                //   let sortedArr:any = values?.sort((a: any, b: any) => {
                                                //     if (a.sortIndex < b.sortIndex) return -1
                                                //     if (a.sortIndex > b.sortIndex) return 1
                                                //     return 0
                                                //   })
                                                //   generateFloorForEditBuilding(sortedArr)
                                                // }

      
                                                const updatedValues = checkDuplicatesForMergeArray(values)
                                                updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                setCluster(updateCluster)
                                                setIsCTAHidden(false)
                                              }}
                                            />
                                          </Paper>
                                          {floorItem?.startRangeError && (
                                                <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                  The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                                </p>
                                              )}
                                        </div>
                                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                          <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                              disabled={floorItem?.floorType?.value === 0}
                                              type={
                                                floorItem?.floorType?.value === 0
                                                  ? 'text'
                                                  : 'number'
                                              }
                                              placeholder='End Range'
                                              style={inputBaseStyle}
                                              inputProps={{'aria-label': 'End Range',min:1}}
                                              value={
                                                floorItem?.floorType?.value === 0
                                                  ? '-'
                                                  : floorItem?.floorEndRange
                                              }
                                              onChange={(e: any) => {
                                                if (e.target.value < 0) {
                                                  return;
                                                }
                                                const newValue = e.target.value.replace(
                                                  /^0+(?=\d)/,
                                                  ''
                                                )
                                                const updateCluster = [...allCluster]
                                            const values = [...updateCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
                                          

                                                if (
                                                  e.target.value != '' &&
                                                  Number(e.target.value) < floorItem.floorStartRange &&
                                                  floorItem.floorStartRange != ''
                                                ) {
                                                  values[floorIndex].endRangeError = true
                                                } else {
                                                  values[floorIndex].endRangeError = false
                                                }

                                                if (
                                                  e.target.value !== '' &&
                                                  Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                  floorItem.floorStartRange != ''
                                                ) {
                                                  values[floorIndex].startRangeError = false
                                                }

                                                values[floorIndex].floorEndRange = newValue ? Number(newValue) : ''
                                                updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
                                                setCluster(updateCluster)

                                                // if(!(values[floorIndex].endRangeError && values[floorIndex].startRangeError)){
                                                //   let sortedArr:any = values?.sort((a: any, b: any) => {
                                                //     if (a.sortIndex < b.sortIndex) return -1
                                                //     if (a.sortIndex > b.sortIndex) return 1
                                                //     return 0
                                                //   })
                                                //   generateFloorForEditBuilding(sortedArr)
                                                // }

                                               
                                                const updatedValues = checkDuplicatesForMergeArray(values)
                                                updateCluster[index].updateBuildingCluster[buildingClusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                setCluster(updateCluster)
                                                setIsCTAHidden(false)
                                              }}
                                            />
                                          </Paper>
                                          {floorItem?.endRangeError && (
                                                <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                  The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                                </p>
                                              )}
                                        </div>
                                      </div>
                                      
                                    </td>

                                    <td
                                     className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}
                                    >
                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                        <div className='mt-2'>
                                          {floorItem?.floorType?.value != 0 ? (
                                            <p className='m-0 head-text '>
                                              <i>
                                                {' '}
                                                {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                              </i>
                                            </p>
                                          ) : (
                                            <p className='m-0 head-text '>
                                              <i> {floorItem?.name}</i>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </td>

                                    <td
                                      className='p-0 ps-3 text-center py-3 min-w-100px'
                                      style={{maxWidth: '100px'}}
                                    >
                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                        <div className='mt-1'>
                                          <h3 className='m-0 head-text '>
                                          {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                          </h3>
                                        </div>
                                        {buildingItem?.mergeFloorArray?.length >= 1 && (
                                          <img
                                            className='ms-7'
                                            style={{cursor: 'pointer'}}
                                            src={redCross}
                                            height={14}
                                            width={14}
                                            onClick={() =>
                                              handleRemoveDevelopmenetBuilding(
                                                index, buildingClusterIndex, buildingIndex, floorIndex, 1
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                   }

                                  
                                  
                                  }
                                )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                      {clusterItem?.addBuilding?.map((buildingItem: any, buildingIndex: any) => (
                        <BuildingCard
                        setIsCTAHidden={setIsCTAHidden}
                          allCluster={allCluster}
                          buildingClusterIndex={buildingClusterIndex}
                          buildingIndex={buildingIndex}
                          clusterIndex={index}
                          buildingItem={buildingItem}
                          floorOptions={floorOptions}
                          setCluster={setCluster}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {allCluster[index]?.addBuildingCluster?.map((clusterItem: any, buildingClusterIndex: any) => (
              <div className='col-12'>
                <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '50px'}}>
                  {allCluster[index]?.addBuildingCluster?.length >= 1 && (
                    <div style={{position: 'absolute', top: '2px', right: '12px'}}>
                      <img
                        style={{cursor: 'pointer'}}
                        src={redCross}
                        height={14}
                        width={14}
                        onClick={() => {
                          handleRemoveBuildingCluster(index, buildingClusterIndex)
                        }}
                      />
                    </div>
                  )}
                  <div className='row'>
                    <div className='text-center col-3 mx-auto'>
                      <h3 className='m-0 head-text mb-1 ms-1'>Building Cluster</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter Building Cluster Name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Building Cluster'}}
                            value={clusterItem?.name}
                            onChange={(e: any) => {
                              const values = [...allCluster]
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              values[index].addBuildingCluster[buildingClusterIndex].name = captilizeWord
                              setCluster(values)
                              setIsCTAHidden(false)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3 mx-auto text-center  my-7'>
                      <div className='d-flex justify-content-center'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold px-2 green-submit-btn '
                          style={{width: 'fit-content'}}
                          onClick={() => handleAddBuildingForBuildingCluster(index, buildingClusterIndex)}
                        >
                          <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                        </button>
                      </div>
                      <h4 className='m-0 head-text mt-3 mb-1'>Add a building under this cluster</h4>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      {clusterItem?.addBuilding?.map((buildingItem: any, buildingIndex: any) => (
                        <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                          <div className='row'>
                            <div className='col-8 d-flex align-items-start'>
                              <div className='me-4'>
                                <h3 className='m-0 head-text mb-1 ms-1'>Building</h3>
                                <div className='wxyz'>
                                  <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                      placeholder='Enter Building Name'
                                      style={inputBaseStyle}
                                      inputProps={{'aria-label': 'Building name'}}
                                      value={buildingItem?.name}
                                      onChange={(e: any) => {
                                        const values = [...allCluster]
                                        const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].name = captilizeWord
                                        setCluster(values)
                                        setIsCTAHidden(false)
                                      }}
                                    />
                                  </Paper>
                                </div>
                              </div>
                              <div className='text-center'>
                                <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                  {`${calculateTotalFloors(index, buildingClusterIndex, buildingIndex)}`}
                                </h3>
                              </div>
                            </div>

                            {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.length >= 1 && (
                              <div className='col-4 d-flex justify-content-end '>
                                <img
                                  style={{cursor: 'pointer'}}
                                  src={redCross}
                                  height={14}
                                  width={14}
                                  onClick={() => {
                                    handleRemoveBuildingClusterBuilding(index, buildingClusterIndex, buildingIndex)
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          {/* add floor */}
                          <div className='row my-7'>
                            <div className='col-12'>
                              <div className='d-flex align-items-center'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4'
                                  style={{width:'120px'}}
                                  onClick={() => addFloorHandler(index, buildingClusterIndex, buildingIndex)}
                                >
                                  Add Floor
                                </button>

                                <p className='m-0 head-text'>
                                Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12'>
                              <table className='table table-borderless'>
                                <thead>
                                  <tr>
                                    <th className='head-text text-start p-0 pb-2' style={{minWidth: '20px', maxWidth: '20px'}}></th>
                                    <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                                      Floors
                                      <span className='ms-2'>
                                        <OverlayTrigger
                                          placement='right'
                                          delay={{show: 250, hide: 200}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of
                                              each floor added, giving each floor it’s own name. If you do not wish to include a prefix, you may leave
                                              this field blank. You will be able to edit any floor name once it has been added.
                                              <br></br>
                                              <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start
                                              and end based on the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect
                                              the same floor names within your building. If you are adding a single floor your ‘Start Range’ and ‘ End
                                              Range’ should include the same value.
                                              <br></br>
                                              <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’
                                              and ‘End Range’ will be included in the total count.
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineExclamationCircle
                                              color='#146C6A '
                                              fontSize={14}
                                              fontWeight={500}
                                              className='cursor-pointer'
                                              style={{cursor: 'pointer', marginBottom: '2px'}}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </th>
                                    <th className='head-text text-start p-0  ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                                      Floor Name
                                    </th>
                                    <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                                      Floor Range
                                    </th>
                                    <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                                      Example
                                    </th>
                                    <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                                      Total Floors
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='text-start'>
                                  {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.map(
                                    (floorItem: any, floorIndex: any) => {
                                      return (
                                        <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                                          <td className='py-3' style={{minWidth: '20px', maxWidth: '20px'}}>
                                            <span
                                              className={`${
                                                allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  ?.length > 1
                                                  ? 'me-3'
                                                  : ''
                                              }`}
                                            >
                                              {floorIndex === 0 &&
                                                allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  ?.length > 1 && (
                                                  <img
                                                    onClick={() =>
                                                      swapRowForAdd(
                                                        floorIndex,
                                                        floorIndex + 1,
                                                        index,
                                                        buildingClusterIndex,
                                                        buildingIndex,
                                                        floorIndex
                                                      )
                                                    }
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowDown}
                                                    height={12}
                                                    width={12}
                                                  />
                                                )}
                                              {floorIndex ===
                                                allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  ?.length -
                                                  1 &&
                                                allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  ?.length > 1 && (
                                                  <img
                                                    onClick={() =>
                                                      swapRowForAdd(
                                                        floorIndex - 1,
                                                        floorIndex,
                                                        index,
                                                        buildingClusterIndex,
                                                        buildingIndex,
                                                        floorIndex
                                                      )
                                                    }
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowUp}
                                                    height={12}
                                                    width={12}
                                                  />
                                                )}
                                              {floorIndex !== 0 &&
                                                floorIndex !==
                                                  allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    ?.length -
                                                    1 && (
                                                  <span className='d-flex flex-column'>
                                                    <img
                                                      style={{cursor: 'pointer'}}
                                                      src={carrotArrowUp}
                                                      height={12}
                                                      width={12}
                                                      onClick={() =>
                                                        swapRowForAdd(
                                                          floorIndex - 1,
                                                          floorIndex,
                                                          index,
                                                          buildingClusterIndex,
                                                          buildingIndex,
                                                          floorIndex
                                                        )
                                                      }
                                                    />
                                                    <img
                                                      style={{cursor: 'pointer'}}
                                                      src={carrotArrowDown}
                                                      height={12}
                                                      width={12}
                                                      onClick={() =>
                                                        swapRowForAdd(
                                                          floorIndex,
                                                          floorIndex + 1,
                                                          index,
                                                          buildingClusterIndex,
                                                          buildingIndex,
                                                          floorIndex
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                )}
                                            </span>
                                          </td>
                                          <td className='p-0  py-3' style={{minWidth: '170px'}}>
                                            <div>
                                              <Select
                                                styles={{
                                                  ...customStyles,
                                                  container: (defaultStyles: any) => ({
                                                    ...defaultStyles,
                                                    width: '100%',
                                                  }),
                                                }}
                                                isSearchable={false}
                                                options={floorOption(
                                                  index,
                                                  buildingClusterIndex,
                                                  buildingIndex,
                                                  floorIndex,
                                                  'add',
                                                  'addBuilding',
                                                  'addFloor'
                                                )}
                                                value={floorItem?.floorType}
                                                name='floor'
                                                onChange={(e: any) => {
                                                  const values = [...allCluster]
                                                  values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                    floorIndex
                                                  ].floorType = e

                                                  if (e.value === 0) {
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].startRangeError = false
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].endRangeError = false
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].floorStartRange = 1
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].floorEndRange = 1
                                                  }

                                                  const updateFloors = checkForDuplicatesForBuildingCluster(
                                                    values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  )
                                                  values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                    updateFloors
                                                  setCluster(values)
                                                  setIsCTAHidden(false)
                                                }}
                                                placeholder={'Select Floor Type'}
                                              />
                                            </div>
                                            {floorItem.isDuplicate && (
                                              <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                Floor Name is duplicated
                                              </p>
                                            )}
                                          </td>
                                          <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                            <div className='wxyz'>
                                              <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                  placeholder='Prefix'
                                                  style={inputBaseStyle}
                                                  inputProps={{'aria-label': 'Prefix'}}
                                                  value={floorItem?.name}
                                                  onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].name = captilizeWord

                                                    const updateFloors = checkForDuplicatesForBuildingCluster(
                                                      values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    )
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                      updateFloors
                                                    setCluster(values)
                                                    setIsCTAHidden(false)
                                                  }}
                                                />
                                              </Paper>
                                            </div>
                                          </td>

                                          <td className='p-0 min-w-100px ps-7 py-3 ' style={{maxWidth: '220px'}}>
                                            <div className='d-flex ' style={{columnGap: '10px'}}>
                                              <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                <Paper component='form' style={searchContainerStyle}>
                                                  <InputBase
                                                    disabled={floorItem?.floorType === 0}
                                                    type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                    placeholder='Start Range'
                                                    style={inputBaseStyle}
                                                    inputProps={{'aria-label': 'Start Range',min:1}}
                                                    value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                    onChange={(e: any) => {
                                                      if (e.target.value < 0) {
                                                        return;
                                                      }
                                                      const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                      const values = [...allCluster]
                                                      // if (floorItem.floorEndRange !== '' && floorItem.floorEndRange < Number(e.target.value) && e.target.value != '') {
                                                      //     ErrorToast("Start Range should not be GREATER than End Range.")
                                                      // }
                                                      // else{
                                                      //     values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorStartRange = newValue ? Number(newValue) : '';
                                                      // }

                                                      if (
                                                        floorItem?.floorEndRange !== '' &&
                                                        floorItem?.floorEndRange < Number(e.target.value) &&
                                                        e.target.value != ''
                                                      ) {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].startRangeError = true
                                                      } else {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].startRangeError = false
                                                      }

                                                      if (
                                                        floorItem?.floorEndRange !== '' &&
                                                        floorItem?.floorEndRange >= Number(e.target.value) &&
                                                        e.target.value != ''
                                                      ) {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].endRangeError = false
                                                      }

                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].floorStartRange = newValue ? Number(newValue) : ''
                                                      const updateFloors = checkForDuplicatesForBuildingCluster(
                                                        values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                      )
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                        updateFloors
                                                      setCluster(values)
                                                      setIsCTAHidden(false)
                                                    }}
                                                  />
                                                </Paper>
                                                {floorItem?.startRangeError && (
                                                  <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                   The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                                  </p>
                                                )}
                                              </div>
                                              <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                <Paper component='form' style={searchContainerStyle}>
                                                  <InputBase
                                                    disabled={floorItem?.floorType === 0}
                                                    placeholder='End Range'
                                                    type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                    style={inputBaseStyle}
                                                    inputProps={{'aria-label': 'End Range',min:1}}
                                                    value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                    onChange={(e: any) => {
                                                      if (e.target.value < 0) {
                                                        return;
                                                      }
                                                      const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                      const values = [...allCluster]
                                                      // if (e.target.value != '' && Number(e.target.value) < floorItem.floorStartRange && floorItem.floorStartRange != '') {
                                                      //     ErrorToast("The floor 'end range' should be equal to or greater than the floor 'start range'.")
                                                      // }
                                                      // else{
                                                      //     values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorEndRange = newValue ? Number(newValue) : '';
                                                      // }

                                                      if (
                                                        e.target.value != '' &&
                                                        Number(e.target.value) < floorItem.floorStartRange &&
                                                        floorItem.floorStartRange != ''
                                                      ) {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].endRangeError = true
                                                      } else {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].endRangeError = false
                                                      }

                                                      if (
                                                        e.target.value !== '' &&
                                                        Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                        floorItem.floorStartRange != ''
                                                      ) {
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].startRangeError = false
                                                      }
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].floorEndRange = newValue ? Number(newValue) : ''
                                                      const updateFloors = checkForDuplicatesForBuildingCluster(
                                                        values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                      )
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                        updateFloors
                                                      setCluster(values)
                                                      setIsCTAHidden(false)
                                                    }}
                                                  />
                                                </Paper>
                                                {floorItem?.endRangeError && (
                                                  <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                    The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                          <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                                            <div className='text-center  d-flex justify-content-center align-items-center'>
                                              <div className='mt-2'>
                                                {floorItem?.floorType?.value != 0 ? (
                                                  <p className='m-0 head-text '>
                                                    <i>
                                                      {' '}
                                                      {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                                    </i>
                                                  </p>
                                                ) : (
                                                  <p className='m-0 head-text '>
                                                    <i> {floorItem?.name}</i>
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </td>
                                          <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                            <div className='text-center  d-flex justify-content-center align-items-center'>
                                              <div className='mt-1'>
                                                <h3 className='m-0 head-text '>
                                                  {/* {`${(floorItem?.floorEndRange - floorItem?.floorStartRange) + 1}`} */}
                                                  {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                                </h3>
                                              </div>
                                              {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                ?.length > 1 && (
                                                <img
                                                  className='ms-7'
                                                  style={{cursor: 'pointer'}}
                                                  src={redCross}
                                                  height={14}
                                                  width={14}
                                                  onClick={() =>
                                                    handleRemoveBuildingClusterFloor(index, buildingClusterIndex, buildingIndex, floorIndex)
                                                  }
                                                />
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default EditBuildingCluster

interface BuildingProps {
  buildingItem: any
  buildingIndex: any
  clusterIndex: any
  buildingClusterIndex: any
  allCluster: any
  setCluster: any
  floorOptions: any
  setIsCTAHidden:any
}

const BuildingCard = ({buildingItem, buildingIndex, clusterIndex, buildingClusterIndex, allCluster, setCluster, floorOptions,setIsCTAHidden}: BuildingProps) => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }
  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
    // backgroundColor: '#fff',
  }
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const calculateTotalFloors = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }

  const floorOption = (
    index: any,
    clusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    buildingType: 'addBuilding' | 'updateBuilding'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'add' ? allCluster[index]?.addBuildingCluster : allCluster[index]?.updateBuildingCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[clusterIndex]?.addBuilding?.[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const addFloorHandlerForAddBuilding = (index: any, builidngClusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateBuildingCluster[builidngClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: updateCluster[index]?.updateBuildingCluster[builidngClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length + 1,
    })
    setCluster(updateCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveFloorForAddBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)

    const updatedFloors = checkForDuplicatesForBuildingCluster(
      updatedCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    )
    updatedCluster[index].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
    setCluster(updatedCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveBuildingForAddBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    const updateCluster = [...allCluster]
    updateCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setCluster(updateCluster)
    setIsCTAHidden(false)
    
  }

  const swapRow = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      updatedValuesSections[prevInd].animationClass = ''
      updatedValuesSections[nextInd].animationClass = ''

      // Assign the updated array back to the structure
      updatedValues[index].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      const updatedFloors = checkForDuplicatesForBuildingCluster(
        updatedValues[index]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      updatedValues[index].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors

      setCluster(updatedValues)
      setIsCTAHidden(false)
    }, 100)
  }

  const checkForDuplicatesForBuildingCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }
  return (
    <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
      <div className='row'>
        <div className='col-8 d-flex align-items-start'>
          <div className='me-4'>
            <h3 className='m-0 head-text text-start mb-1 ms-1'>Building</h3>
            <div className='wxyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Enter Building Name'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'Building name'}}
                  value={buildingItem?.name}
                  onChange={(e) => {
                    const values = [...allCluster]
                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                    values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].name = captilizeWord
                    setCluster(values)
                    setIsCTAHidden(false)
                  }}
                />
              </Paper>
            </div>
          </div>
          <div className='text-center'>
            <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>
            <h3 className='m-0 head-text mt-3 mb-1 ms-2'>{`${calculateTotalFloors(clusterIndex, buildingClusterIndex, buildingIndex)}`}</h3>
          </div>
        </div>
        {allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding?.length >= 1 && (
          <div className='col-4 d-flex justify-content-end'>
            <img
              style={{cursor: 'pointer'}}
              src={redCross}
              height={14}
              width={14}
              onClick={() => {
                handleRemoveBuildingForAddBuilding(clusterIndex, buildingClusterIndex, buildingIndex)
                // setDuplicateIndicesForBuildingClusterAddBuilding([]);
              }}
            />
          </div>
        )}
      </div>

      <div className='row my-7'>
        <div className='col-12'>
          <div className='d-flex align-items-center'>
            <button
              type='button'
              className='btn btn-sm fw-bold create-st d-flex justify-content-center px-2 me-4'
              style={{width:'120px'}}
              onClick={() => addFloorHandlerForAddBuilding(clusterIndex, buildingClusterIndex, buildingIndex)}
            >
              Add Floor
            </button>
            <p className='m-0 head-text'>
            Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
            </p>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <table className='table table-borderless'>
            <thead>
              <tr>
                <th className='head-text text-start p-0 pb-2' style={{minWidth: '20px', maxWidth: '20px'}}></th>
                <th className='head-text text-start p-0  pb-2' style={{minWidth: '10px'}}>
                  Floors
                  <span className='ms-2'>
                    <OverlayTrigger
                      placement='right'
                      delay={{show: 250, hide: 200}}
                      overlay={
                        <Tooltip id='button-tooltip-2'>
                          {' '}
                          <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of each floor added,
                          giving each floor it’s own name. If you do not wish to include a prefix, you may leave this field blank. You will be able to
                          edit any floor name once it has been added.
                          <br></br>
                          <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start and end based on
                          the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect the same floor names within your
                          building. If you are adding a single floor your ‘Start Range’ and ‘ End Range’ should include the same value.
                          <br></br>
                          <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’ and ‘End Range’ will
                          be included in the total count.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#146C6A '
                          fontSize={14}
                          fontWeight={500}
                          className='cursor-pointer'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='head-text text-start p-0  ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                  Floor Name
                </th>
                <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                  Floor Range
                </th>
                <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                  Example
                </th>
                <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                  Total Floors
                </th>
              </tr>
            </thead>
            <tbody className='text-start'>
              {buildingItem?.addFloor?.map((floorItem: any, floorIndex: any) => {
                // const isDuplicate = duplicateIndicesForBuildingClusterAddBuilding.includes(floorIndex)
                return (
                  <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                    <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                      <span
                        className={`${
                          allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1
                            ? 'me-3'
                            : ''
                        }`}
                      >
                        {floorIndex === 0 &&
                          allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1 && (
                            <img
                              onClick={() => swapRow(floorIndex, floorIndex + 1, clusterIndex, buildingClusterIndex, buildingIndex, floorIndex)}
                              style={{cursor: 'pointer'}}
                              src={carrotArrowDown}
                              height={12}
                              width={12}
                            />
                          )}
                        {floorIndex ===
                          allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length - 1 &&
                          allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1 && (
                            <img
                              onClick={() => swapRow(floorIndex - 1, floorIndex, clusterIndex, buildingClusterIndex, buildingIndex, floorIndex)}
                              style={{cursor: 'pointer'}}
                              src={carrotArrowUp}
                              height={12}
                              width={12}
                            />
                          )}
                        {floorIndex !== 0 &&
                          floorIndex !==
                            allCluster[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length -
                              1 && (
                            <span className='d-flex flex-column'>
                              <img
                                style={{cursor: 'pointer'}}
                                src={carrotArrowUp}
                                height={12}
                                width={12}
                                onClick={() => swapRow(floorIndex - 1, floorIndex, clusterIndex, buildingClusterIndex, buildingIndex, floorIndex)}
                              />
                              <img
                                style={{cursor: 'pointer'}}
                                src={carrotArrowDown}
                                height={12}
                                width={12}
                                onClick={() => swapRow(floorIndex, floorIndex + 1, clusterIndex, buildingClusterIndex, buildingIndex, floorIndex)}
                              />
                            </span>
                          )}
                      </span>
                    </td>
                    <td className='p-0  py-3' style={{minWidth: '170px'}}>
                      <Select
                        styles={{
                          ...customStyles,
                          container: (defaultStyles: any) => ({
                            ...defaultStyles,
                            width: '100%',
                          }),
                        }}
                        isSearchable={false}
                        options={floorOption(clusterIndex, buildingClusterIndex, buildingIndex, floorIndex, 'update', 'addBuilding')}
                        value={floorItem?.floorType}
                        name='floor'
                        onChange={(e) => {
                          const values = [...allCluster]
                          values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorType =
                            e

                          if (e.value === 0) {
                            values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                              floorIndex
                            ].startRangeError = false
                            values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                              floorIndex
                            ].endRangeError = false
                            values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                              floorIndex
                            ].floorStartRange = 1
                            values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                              floorIndex
                            ].floorEndRange = 1
                          }

                          const updatedFloors = checkForDuplicatesForBuildingCluster(
                            values[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                          )
                          values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
                          setCluster(values)
                          setIsCTAHidden(false)
                        }}
                        placeholder={'Select Floor Type'}
                      />

                      {floorItem.isDuplicate && (
                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                          Floor Name is duplicated
                        </p>
                      )}
                    </td>
                    <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Prefix'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Prefix'}}
                            value={floorItem?.name}
                            onChange={(e) => {
                              const values = [...allCluster]
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].name =
                                captilizeWord

                              const updatedFloors = checkForDuplicatesForBuildingCluster(
                                values[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                              )
                              values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
                              setCluster(values)
                              setIsCTAHidden(false)
                            }}
                          />
                        </Paper>
                      </div>
                    </td>

                    <td className='p-0 min-w-100px ps-7 py-3 ' style={{maxWidth: '220px'}}>
                      <div className='d-flex' style={{columnGap: '10px'}}>
                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              disabled={floorItem?.floorType === 0}
                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                              placeholder='Start Range'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Start Range',min:1}}
                              value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                              onChange={(e:any) => {
                                if (e.target.value < 0) {
                                  return;
                                }
                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                const values = [...allCluster]
                                // if (floorItem.floorEndRange !== '' && floorItem.floorEndRange < Number(e.target.value) && e.target.value != '') {
                                //     ErrorToast("Start Range should not be GREATER than End Range.")
                                // }
                                // else{
                                //     values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorStartRange = newValue ? Number(newValue) : '';
                                // }

                                if (floorItem?.floorEndRange !== '' && floorItem?.floorEndRange < Number(e.target.value) && e.target.value != '') {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].startRangeError = true
                                } else {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].startRangeError = false
                                }

                                if (floorItem?.floorEndRange !== '' && floorItem?.floorEndRange >= Number(e.target.value) && e.target.value != '') {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].endRangeError = false
                                }

                                values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                  floorIndex
                                ].floorStartRange = newValue ? Number(newValue) : ''

                                const updatedFloors = checkForDuplicatesForBuildingCluster(
                                  values[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                )
                                values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
                                setCluster(values)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                          {floorItem?.startRangeError && (
                            <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                              The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                            </p>
                          )}
                        </div>
                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              disabled={floorItem?.floorType === 0}
                              placeholder='End Range'
                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'End Range',min:1}}
                              value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                              onChange={(e:any) => {
                                if (e.target.value < 0) {
                                  return;
                                }
                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                const values = [...allCluster]
                                // if (e.target.value != '' && Number(e.target.value) < floorItem.floorStartRange && floorItem.floorStartRange != '') {
                                //     ErrorToast("The floor 'end range' should be equal to or greater than the floor 'start range'.")
                                // }
                                // else{
                                //     values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorEndRange = newValue ? Number(newValue) : '';
                                // }

                                if (e.target.value != '' && Number(e.target.value) < floorItem.floorStartRange && floorItem.floorStartRange != '') {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].endRangeError = true
                                } else {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].endRangeError = false
                                }

                                if (
                                  e.target.value !== '' &&
                                  Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                  floorItem.floorStartRange != ''
                                ) {
                                  values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                    floorIndex
                                  ].startRangeError = false
                                }

                                values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                  floorIndex
                                ].floorEndRange = newValue ? Number(newValue) : ''

                                const updatedFloors = checkForDuplicatesForBuildingCluster(
                                  values[clusterIndex]?.updateBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                )
                                values[clusterIndex].updateBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
                                setCluster(values)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                          {floorItem?.endRangeError && (
                            <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                              The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                            </p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                      <div className='text-center  d-flex justify-content-center align-items-center'>
                        <div className='mt-2'>
                          {floorItem?.floorType?.value != 0 ? (
                            <p className='m-0 head-text '>
                              <i>
                                {' '}
                                {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                              </i>
                            </p>
                          ) : (
                            <p className='m-0 head-text '>
                              <i> {floorItem?.name}</i>
                            </p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                      <div className='text-center d-flex justify-content-center align-items-center'>
                        <div className='mt-1'>
                          <h3 className='m-0 head-text'>
                            {/* {`${(floorItem?.floorEndRange - floorItem?.floorStartRange) + 1}`} */}
                            {`${ floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                          </h3>
                        </div>
                        {buildingItem?.addFloor?.length > 1 && (
                          <img
                            className='ms-7'
                            style={{cursor: 'pointer'}}
                            src={redCross}
                            height={14}
                            width={14}
                            onClick={() => handleRemoveFloorForAddBuilding(clusterIndex, buildingClusterIndex, buildingIndex, floorIndex)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

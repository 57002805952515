import React, {useState} from 'react'
import ApexCharts from 'react-apexcharts'
import vacancyRate from '../../../img/vacancyRate.png'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import tenantRetentionImg from '../../../img/tenant-retention.png'


interface vacancyRateProps {
  chartData?: any
}

const TenantRetentionRate: React.FC<vacancyRateProps> = ({chartData}) => {
  const [series, setSeries] = useState<any>(chartData ? [chartData] : [])
  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 400,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: '60%',
          image: tenantRetentionImg,
          imageWidth: 40,
          imageHeight: 40,
          imageClipped: false,
          imageOffsetY: 20, // Offset image to bottom
        },
        dataLabels: {
          // enabled:false,
          name: {
            show: false,
          },
          value: {
            show: true,
            color: '#333',
            offsetY: -20, // Move value to the top
            fontSize: '18px',
            fontWeight: '900',
          },
        },
      },
    },
    fill: {
      colors: ['#00b9a9'],
    },
    stroke: {
      lineCap: 'butt',
      width: 6,
    },
    labels: ['Volatility'],
  }
  return (
    <div className='card card-flush py-5 px-7 h-100' style={{width: '275px'}}>
      <div className='ms-auto'>
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 200}}
          overlay={
            <Tooltip id='button-tooltip-2'>
              {' '}
              The percentage of tenants who remain in the same properties each month. 
            </Tooltip>
          }
        >
          <span>
            {' '}
            <AiOutlineExclamationCircle
              color='#384a62 '
              fontSize={14}
              fontWeight={500}
              className='cursor-pointer'
              style={{cursor: 'pointer', marginBottom: '2px'}}
            />
          </span>
        </OverlayTrigger>
      </div>
      <div id='chart'>
        <ApexCharts
          options={options}
          series={[Number?.isInteger(chartData) ? chartData : chartData?.toFixed(1)]}
          type='radialBar'
          height={260}
        />
      </div>
      <div className='d-flex justify-content-center mt-auto'>
        <p className='fnt-500 fs-3 mb-3'>Tenant Retention Rate
</p>
      </div>
    </div>
  )
}

export default TenantRetentionRate

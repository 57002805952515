import moment from 'moment'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import NewMessage from '../Tenants/NewMessage'
import AssignJob from './AssignJob'
import MediaPopUp from './MediaPopUp'

function ViewRequest() {
  const [showMedia, setShowMedia] = useState<any>(false)
  const [assignJob, setAssignJob] = useState<any>(false)
  const [showMediaType, setShowMediaType] = useState<any>()
  const serviceId = window.location.pathname?.split('/')[6]
  const [serviceData, setServiceData] = useState<any>()
  const [requestData, setRequestData] = useState<any>()
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
  const [tenantData, setTenantData] = React.useState<any>()
  const [jobId, setJobId] = React.useState<any>()
  const[unitNo, setUnitNo] =  useState<any>()
  const [serviceJobId,setServiceJobId] = useState<any>()

  
  const navigate = useNavigate()

  const serviceDetailData = async () => {
    const body = {
      page: 1,
      limit: 10,
      serviceRequestId: serviceId,
    }

    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        setServiceData(res.data.data?.service_job_data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${window.location.pathname?.split('/')[6]}`)
      .then((res) => {
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    serviceDetailData()
    getServiceData()
  }, [serviceId])

  const handleChnage = (e: any) => {
    if (e === 'assign') {
      setAssignJob(true)
    }
  }

  const openModal = (id: any) => {
    setShowMedia(true)
    setJobId(id)
  }

  function timeConvert(n: any) {
    var num = n
    var hours = num / 60
    var rhours = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    return rhours + ':' + rminutes
  }

  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-baseline flex-wrap mr-5'>
            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() => navigate(`/request-management/service`)}
                >
                  Request
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() =>
                    navigate(
                      `/request-management/service/create-request-service/service-detail/${
                        window.location.pathname?.split('/')[6]
                      }`
                    )
                  }
                >
                  {' '}
                  View Service Detail
                </a>
              </li>
              <li className='breadcrumb-item active'>
                <a className='px-2'> Scope Details</a>
              </li>
            </ul>
          </div>
          <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
            <div className='app-container container-xxl d-flex flex-stack pt-3 mt-0 mb-5'>
              <div className=''>
                <a
                  // onClick={() => setShowCreateAppModal(true)}
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app'
                  onClick={() =>
                    navigate(
                      `/request-management/service/create-request-service/service-detail/${serviceId}`
                    )
                  }
                  // onClick={() => {
                  //     navigate(-1)
                  // }}
                >
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className='card card-flush app-container py-5'>
            <div className='d-flex justify-content-between align-items-center mb-10'>
              <div className='d-flex'>
                <p className='me-3 mb-0'>
                  Service Name:{requestData?.main_service[0]?.name}&
                  {requestData?.sub_service[0]?.name}{' '}
                </p>
                <p className='me-3 mb-0'>
                  Development Name : {requestData?.communitiesIds ? 'Community' : 'Building'}{' '}
                </p>
                <p className='me-3 mb-0'>Properties :{requestData?.totalProperties} </p>
                <p className='me-3 mb-0'>
                  Handled By: FM Team - Username : {requestData?.staff[0]?.name}{' '}
                </p>
              </div>
              <div className='d-flex'>
                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>
                  Service Completion Reports
                </button>
                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>
                  Rate Service
                </button>
              </div>
            </div>
            <div className='pt-0 table-responsive mt-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-center min-w-100px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='flexCheckDefault'
                          value=''
                          style={{cursor: 'pointer'}}
                        />
                      </div>
                    </th>
                    <th className='text-center min-w-100px'>Request ID</th>
                    <th className='text-center min-w-100px'>Job ID </th>
                    <th className='text-center min-w-100px'>Service Name</th>
                    <th className='text-center min-w-100px'>Development</th>
                    <th className='text-center min-w-100px'>Area</th>
                    <th className='text-center min-w-100px'>Unit</th>
                    <th className='text-center min-w-100px'>Occupancy</th>
                    <th className='text-center min-w-100px'>Assigned To</th>
                    <th className='text-center min-w-100px'>Scheduled</th>
                    <th className='text-center min-w-100px'>Job Urgency</th>
                    <th className='text-center min-w-100px'>Status</th>
                    <th className='text-center min-w-100px'></th>
                    <th className='text-center min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {serviceData?.map((m: any, i: any) => {
                    return (
                      <tr>
                        <td className='text-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='flexCheckDefault'
                              value=''
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </td>
                        <td className='text-center'>{m?.requestId}</td>
                        <td className='text-center'>{m?.id}</td>
                        <td className='text-center'>
                          {m?.main_service[0]?.name}
                          {m?.sub_service[0]?.name}
                        </td>
                        <td className='text-center'>
                          {m?.community.length > 0 ? 'Community' : 'Building'}
                        </td>
                        <td className='text-center'>
                          {m?.community.length > 0 ? m?.community[0]?.area : m?.building[0]?.area}
                        </td>
                        <td className='text-center'>{m?.unit[0]?.unitNo}</td>
                        <td className='text-center'>
                          {m?.unit[0]?.occupy === 1 ? 'Occupied' : 'Vacant'}
                        </td>
                        <td className='text-center'>
                          <select
                            name='assigned to'
                            aria-label='assigned to'
                            data-control='select2'
                            data-placeholder='date_period'
                            className='form-select form-select-sm form-select-solid'
                            onChange={(e) => handleChnage(e.target.value)}
                          >
                            <option value=''>select</option>
                            <option value='assign'>Assign</option>
                          </select>
                        </td>
                        <td className='text-center'>
                          {m?.schedule[0]?.preferScheduleDate &&
                            moment(m?.schedule[0]?.preferScheduleDate).format('DD/MM/YYYY')}{' '}
                          -{' '}
                          {m?.schedule[0]?.preferScheduleTime &&
                            timeConvert(m?.schedule[0]?.preferScheduleTime)}
                        </td>
                        <td className='text-center'>
                          {' '}
                          <select
                            name='assigned to'
                            aria-label='assigned to'
                            data-control='select2'
                            data-placeholder='date_period'
                            className='form-select form-select-sm form-select-solid'
                          >
                            {m?.jobUrgency === 0 && (
                              <option value={0} selected>
                                Standard
                              </option>
                            )}
                            {m?.jobUrgency === 1 && (
                              <option value={1} selected>
                                Emergency
                              </option>
                            )}
                          </select>
                        </td>
                        <td className='text-center'>
                          {' '}
                          <select
                            name='assigned to'
                            aria-label='assigned to'
                            data-control='select2'
                            data-placeholder='date_period'
                            className='form-select form-select-sm form-select-solid'
                            onChange={(e) => handleChnage(e.target.value)}
                          >
                            <option value=''>Not Started</option>
                            <option value=''>In-Progress</option>
                            <option value=''> Complete</option>
                          </select>
                        </td>
                        <td className='text-center'>
                          <button
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-150px'
                            onClick={() => setNewMessageModal(true)}
                          >
                            Message Tenant
                          </button>
                        </td>
                        <td className='text-center'>
                          <button
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-150px'
                            onClick={() => {
                              openModal(m)
                              setShowMediaType('view-request')
                            }}
                          >
                            Media & Comments
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <MediaPopUp
        unitNo={unitNo}
        show={showMedia}
        handleClose={() => {
          setShowMedia(false)
        }}
        propertyList={serviceData}
        id={jobId}
      />

      <AssignJob
        show={assignJob}
        handleClose={() => {
          setAssignJob(false)
        }}
        handleChnage={handleChnage}
        serviceData={serviceData}
        serviceJobId={serviceJobId}
      />
      <NewMessage
        show={newMessageModal}
        tenantdata={tenantData}
        getMessageList={''}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      />
    </>
  )
}

export default ViewRequest

import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
// import {Row, Col} from 'react-bootstrap'
import plusBlue from '../../../../img/add-blue.png'
import plusWhite from '../../../../img/add-white.png'
import moment, {Moment} from 'moment'
import close from '../../../../img/close.png'
import Select from 'react-select'
import bin from '../../../../img/bin.png'
import Swal from 'sweetalert2'
import invoice from '../../../../img/invoice.png'
import invoiceBlue from '../../../../img/invoice-blue.png'
import reminder from '../../../../img/notification.png'
import reminderBlue from '../../../../img/notification-blue.png'
import reminderWhite from '../../../../img/notification-white.png'
import receipt from '../../../../img/receipt.png'
import receiptBlue from '../../../../img/receipt-blue.png'
import receiptWhite from '../../../../img/receipt-white.png'
import submitImg from '../../../../img/Submit-White.png'
import closered from '../../../../img/closered.svg'
import redCross from '../../../../img/x-mark.png'
import invoiceWhite from '../../../../img/invoice-white.png'
import file from '../../../../img/file.png'
import homeImg from '../../../../img/home.png'
import linkImg from '../../../../img/link.png'

import chequeImg from '../../../../img/cheque.png'
import up from '../../../../img/up-collap.png'
import down from '../../../../img/down-arrow.png'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {AnyAaaaRecord} from 'dns'
import {AiFillDelete, AiFillEdit} from 'react-icons/ai'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import editpen from '../../../../img/pen.png'
import approve from '../../../img/approve.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import {DatePicker, Space} from 'antd'

import Switch from 'react-switch'

interface ButtonProps {
  show: any
  handleClose: any
  incomingPaymentId: any
  id: any
  subItemId: any
  subId: any
  getAllPayments: any
  unitDetails: any
  tnancy:any
}

let selectedProperty: any = []

const {RangePicker} = DatePicker

const IncomingPaymentModal = ({
  handleClose,
  show,
  incomingPaymentId,
  id,
  subItemId,
  subId,
  getAllPayments,
  unitDetails,
  tnancy
}: ButtonProps) => {
  const navigate = useNavigate()

  const [isSelected, setIsSelected] = useState<any>(false)
  const [isEditt, setIsEditt] = useState<any>(false)
  const [collapse, setcollapse] = useState(true)

  const [showLink, setShowLink] = useState<any>(false)
  const [maintenanceReqData, setMaintenaceReqData] = useState<any>([])
  const [mainReqLinkModal, setMainReqLinkModal] = useState<any>(false)

  const [checkedRec, setCheckedRec] = useState<any>(false)

  const [checkVerify, setCheckVerify] = useState<any>(false)

  const [paymentItems, setPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [cardItems, setCardItems] = useState<any>([
    {value: '0', label: 'Card'},
    {value: '1', label: 'Cheque'},
    {value: '2', label: 'Bank'},
    {value: '3', label: 'Cash'},
  ])
  const [bankAccountItems, setBankAccountItems] = useState<any>([
    {value: '0', label: 'Account 1'},
    {value: '1', label: 'Account 2'},
    {value: '2', label: 'Account 3'},
    {value: '3', label: 'Account 4'},
  ])

  const [selectedAccount, setSelectedAccount] = useState<any>(null)
  const [selectedAccountReceipt, setSelectedAccountReceipt] = useState<any>()

  const [chequeBankName, setChequeBankName] = useState<any>('')
  const [receiptChequeBankName, setReceiptChequeBankName] = useState<any>()

  const [showFiltered, setShowFiltered] = useState<any>(false)
  const [filteredData, setFilteredData] = useState<any>()

  const [paymentCategory, setPaymentCategory] = useState<any>(null)
  const [receiptLinkModal, setReceiptLinkModal] = useState<any>(false)

  const [fields, setFields] = useState<any>([
    {
      _id: subItemId,
      // id: `${subId}`,
      id: subId,
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',
      totalWithoutLate: 0,
      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: true,
      lateFeeTotal: 0,
      lateOnce: false,
      isLateItem: false,
      lateItemId: null,
      lateItemIndex: null,
      paymentItemListId: null,
      amountPaid: 0,
      outstanding: 0,
      oldLatePaymentCycleList: [],
      nextLatePaymentCycleList: null,
      serviceRequestId: null,
      serviceJobId: null,
      requestId: null,
      jobId: null,
    },
  ])

  const [indForMaintenance, setIndForMaintenance] = useState<any>(-1)

  // Dynamic Schedule
  const [schedule, setSchedule] = useState([
    {
      id: '',
      days: '',
      date: '',
    },
  ])

  const handleScheduleAddFields = () => {
    const values = [...schedule]
    values.push({
      id: '',
      days: '',
      date: '',
    })
    setSchedule(values)
  }

  var itemInd: any

  const handleScheduleRemoveFields = (index: any, id: any) => {
    const values = [...schedule]
    values.splice(index, 1)
    setSchedule(values)

    // ApiDelete(`corporate/payment_reminder/${id}`)
    //   .then((res) => {
    //     SuccessToast('Reminder deleted successfully..')
    //     const values = [...schedule]
    //     values.splice(index, 1)
    //     setSchedule(values)
    //   })
    //   .catch((err) => console.log('err', err))
  }

  const handleSchedule = (e: any, index: any) => {
    if (e.target.value != '') {
      const values = [...schedule]
      values[index].days = e.target.value
      // if (dueDate !==  && values[index].days) {
      let reminderDate: any = new Date(dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      if (new Date(reminderDate) < new Date()) {
        ErrorToast('Reminder can not be set in a Past Date!!!')
      } else {
        values[index].date = new Date(reminderDate).toLocaleDateString('fr-CA')
        setSchedule(values)
      }
    } else {
      const values = [...schedule]
      values[index].days = e.target.value

      let reminderDate: any = new Date(dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      values[index].date = ''

      setSchedule(values)
    }
  }

  const [scheduleAdded, setScheduleAdded] = useState<any>(false)

  var totalAED: any = 0
  var nonVat: any = 0
  var vatAmount: any = 0
  var totalAmountPaid: any = 0
  var accountInd: any

  const [paymentNameError, setPaymentNameError] = useState<any>(false)
  const [amountPaidError, setAmountPaidError] = useState<any>(false)
  const [datePaidError, setDatePaidError] = useState<any>(false)
  const [dueDateError, setDueDateError] = useState<any>(false)
  const [paymentMethodError, setPaymentMethodError] = useState<any>(false)

  const [paymentName, setPaymentName] = useState<any>('')
  const [paidAmount, setAmountPaid] = useState<any>(0)
  const [dueDate, setDueDate] = useState<any>(null)
  const [dueDateToShow, setDueDateToShow] = useState<any>()
  const [isDueStarted, setIsDueStarted] = useState<any>(false)
  const [datePaid, setDatePaid] = useState<any>()

  const [notes, setNotes] = useState<any>('')
  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const [invoiceNotes, setInvoiceNotes] = useState<any>('')
  const [notesInvCount, setNotesInvCount] = useState<any>(`0/1000`)

  const [invoiceAdded, setInvoiceAdded] = useState<any>(false)
  const [yellowInvoice, setYellowInvoice] = useState<any>(false)
  const [invoiceNo, setInvoiceNo] = useState<any>()
  const [invoiceNoError, setInvoiceNoError] = useState<any>(false)
  const [receiptNoError, setReceiptNoError] = useState<any>(false)

  const [receiptAdded, setReceiptAdded] = useState<any>(false)
  const [receiptNo, setReceiptNo] = useState<any>()

  const [activeLATE, setActiveLATE] = useState<any>(false)
  const [InactiveLATE, setInActiveLATE] = useState<any>(false)

  const [disableSelect, setDisableSelect] = useState<any>(true)
  var fileToRename: any = ''

  const [invoiceUrl, setInvoiceUrl] = useState<any>('')
  const [PaymentInvoiceId, setPaymentInvoiceId] = useState<any>('')
  const [receiptUrl, setReceiptUrl] = useState<any>('')
  const [receiptId, setReceiptId] = useState<any>('')

  const [invoiceDate, setInvoiceDate] = useState<any>()

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const [paymentMethod, setPaymentMethod] = useState<any>(0)
  const [receiptPaymentMethod, setReceiptPaymentMethod] = useState<any>(0)

  const [showAcc, setShowAcc] = useState<any>(false)
  const [showAccReceipt, setShowAccReceipt] = useState<any>(false)

  const [accountDetails, setAccountDetails] = useState({
    name: 'Corporate User Real Estate Services LLC',
    iban: 'AE470900000000056912235',
    acc: '56912235',
    bank_name: 'Abu Dhabi Commercial Bank',
  })

  const [tenantId, setTenantId] = useState<any>()
  const [tenantDetails, setTenantDetails] = useState<any>()

  const [invoiceModal, setInvoiceModal] = useState<any>(false)
  const [receiptModal, setReceiptModal] = useState<any>(false)
  const [scheduleModal, setScheduleModal] = useState<any>(false)

  const [devOptions, setDevOptions] = useState<any>()
  const [clustOptions, setClustOptions] = useState<any>()
  const [UGBOptions, setUGBOptions] = useState<any>()

  const [clustBuildOptions, setClustBuildOptions] = useState<any>()
  const [clustUnitsOptions, setClustUnitsOptions] = useState<any>()
  const [buildUnitsOptions, setBuildUnitsOptions] = useState<any>()

  var developmentOptions: any

  const [chequeMedia, setChequeMedia] = useState<any>([])
  const [receiptChequeMedia, setReceiptChequeMedia] = useState<any>([])

  const [attachedMedia, setAttachedMedia] = useState<any>([])
  const [invoiceAttachedMedia, setInvoiceAttachedMedia] = useState<any>([])
  const [receiptAttachedMedia, setReceiptAttachedMedia] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [radioLinkChecked, setRadioLinkChecked] = useState<any>(-1)

  const [tenancyId, setTenancyId] = useState<any>(null)
  const [unitId, setUnitId] = useState<any>()

  // const handleInputChange = (index: any, event: any) => {
  //   const values = [...fields]
  //   // values[index].value = event.target.value;
  //   // setFields(values);
  // }

  const getStatus = () => {
    if (!dueDate) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = dueDate.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  const getStatuss = (val: any) => {
    if (!val) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = val.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  const handleAddFields = () => {
    const values = [...fields]
    // console.log(values)
    values.push({
      _id: '',
      id: '',
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',
      totalWithoutLate: 0,
      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: true,
      lateFeeTotal: 0,
      lateOnce: false,
      isLateItem: false,
      lateItemId: null,
      lateItemIndex: null,
      paymentItemListId: null,
      amountPaid: 0,
      outstanding: 0,
      oldLatePaymentCycleList: [],
      nextLatePaymentCycleList: null,
    })
    setFields(values)
  }

  const handleRemoveFields = (index: any, item: any) => {
    const values = [...fields]
    values.splice(index, 1)

    if (item.activeLATE == true) {
      if (item.lateItemIndex != '')
        if (item.lateItemIndex != -1) values.splice(item?.lateItemIndex - 1, 1)
    }

    if (invoiceAdded) setYellowInvoice(true)

    setReceiptAdded(false)
    for (let i = 0; i < values?.length; i++) {
      values[i].amountPaid = 0
      values[i].lateItemIndex = values[i].lateItemIndex - 2
    }

    setAmountPaid(0)
    setDatePaid(null)
    setFields(values)
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStyles2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '700'}),
  }

  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStylesInputFilled2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const handleDueDateChange = (date: any) => {
    let temp = getStatuss(date)
    if (temp == 'Upcoming') setPaymentCategory(0)
    else setPaymentCategory(1)

    if (date != null || date !== null) {
      setDueDateError(false)
      setDueDate(date)
      setDueDateToShow(date.format('YYYY-MM-DD'))
    } else {
      setDueDateError(false)
      setDueDate(null)
      setDueDateToShow(null)
    }

    if (isDueStarted) {
      ErrorToast(
        'Changing the Due Date will DISCARD all previously applicable Late Payment Fees, if applied!!!'
      )
      const values: any = [...fields]
      let j: any = 0
      for (let i = fields?.length; i >= 0; i--) {
        if (values[i]?.isLateItem == false) {
          if (values[i].activeLATE == true) {
            if (values[i]?.lateItemIndex != null) {
              // values[i].lateApplied = false
              // values[i].activeLATE = false
              // values[i].InactiveLATE = true
              values[i].lateFeeTotal = 0
              // console.log(values[i]?.lateItemIndex)
              if (values[i]?.lateItemIndex != -1) values.splice(values[i]?.lateItemIndex, 1)
              // console.log(values)
              // j--;
            }
          } else {
            j++
          }
          values[i].lateApplied = false
          values[i].activeLATE = false
          values[i].InactiveLATE = true
        }
      }

      if (invoiceAdded) setYellowInvoice(true)

      setReceiptAdded(false)
      for (let i = 0; i < values?.length; i++) {
        values[i].amountPaid = 0
      }

      setAmountPaid(0)
      setDatePaid(null)

      setFields(values)
    }

    setIsDueStarted(true)
  }

  const handleDatePaidChange = (date: any) => {
    if (date != null || date !== null) {
      setDatePaidError(false)
      setDatePaid(date.format('YYYY-MM-DD'))
    } else {
      setDatePaidError(false)
      setDatePaid(null)
    }
    // submitIncomingPayment()
  }

  const [unitsTenancy, setUnitsTenancy] = useState<any>([])
  const [totalTenancies, setTotalTenancies] = useState<any>(0)

  React.useEffect(() => {}, [])

  //
  const [dragActive, setDragActive] = React.useState(false)
  // ref
  const inputRef = React.useRef(null)

  const nameRef = React.useRef<any>()
  const amountPaidRef = React.useRef<any>()
  const datePaidRef = React.useRef<any>()
  const dueDateRef = React.useRef<any>()
  const invoiceRef = React.useRef<any>()
  const receiptRef = React.useRef<any>()

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = (e: any) => {
    // inputRef.current.click();
  }

  //
  const handleAttachmentChange = async (e: any) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      const values = [...attachedMedia]
      let newVal = values.length - 1

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]
        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/originalname/image/payment_attachment`, formdata)
          .then((res) => {
            newVal = newVal + 1
            let x = res?.data?.data?.image?.split('/')[2]

            values[newVal] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }

            // SuccessToast('Attachment has been uploaded successfully!!!')
          })
          .catch(() => {})
      }
      setAttachedMedia(values)
    }
  }

  // delete attachment
  const deleteAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...attachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const getPaymentItemsList = () => {
    if (unitDetails?.buildingId) {
      ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitDetails?.buildingId)
        .then((res: any) => {
          setPaymentItems(res?.data?.data)
          setFilteredPaymentItems(res?.data?.data)
          // console.log(res?.data?.data?.payment_item_list_data)
        })
        .catch((err: any) => console.log('err', err))
    } else if (unitDetails?.communityId) {
      ApiGet(`corporate/payment_item_list/payment?communityId=` + unitDetails?.communityId)
        .then((res: any) => {
          setPaymentItems(res?.data?.data)
          setFilteredPaymentItems(res?.data?.data)
          // console.log(res?.data?.data?.payment_item_list_data)
        })
        .catch((err: any) => console.log('err', err))
    }
  }

  const getLateFee = (field: any) => {
    // console.log(parseInt(field.amount) + parseInt(field.qty))
    // console.log((parseInt(field.amount) * parseInt(field.qty)) / 100)

    let x: any =
      (field?.lateDetails?.late_payment_item_charge?.[0]?.fixedAmount / 100) *
      parseFloat(field?.lateDetails?.late_payment_item_charge?.[0]?.VATPercentage)

    // console.log(x)

    let y: any = parseFloat(field?.lateDetails?.late_payment_item_charge?.[0]?.fixedAmount) + x

    // console.log(y)
    if (field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 0)
      // totalAED = totalAED + y;
      // console.log(totalAED)
      // console.log(y)

      return y
  }

  const getLateFeePercent = (field: any) => {
    let x: any =
      ((parseFloat(field.amount) * parseFloat(field.qty)) / 100) *
      parseFloat(field?.lateDetails?.late_payment_item_charge?.[0]?.VATPercentage)

    let y: any =
      ((parseFloat(field.amount) * parseFloat(field.qty)) / 100) *
      parseFloat(field?.lateDetails?.late_payment_item_charge?.[0]?.percentageAmount)

    let z = x + y
    // if(field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 1)
    // totalAED = totalAED + z;

    return z
  }

  const getLateFeePercentValue = (field: any) => {
    let y: any =
      ((parseFloat(field.amount) * parseFloat(field.qty)) / 100) *
      parseFloat(field?.lateDetails?.late_payment_item_charge?.[0]?.percentageAmount)

    return y
  }

  const submitIncomingPayment = () => {
    let sJob: any = []
    if (serviceJobId != '') sJob[0] = serviceJobId

    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    // Payment Status
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Dynamic Data
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.lateApplied) {
        // if(fields[i]?.lateDetails?.late_payment_item_charge){
        let x = fields[i]?.lateDetails

        let xId: any = x.late_payment_item_charge[0]?._id
        newObj = {
          _id: xId,
          // id: '' + x.late_payment_item_charge[0]?.id + '',
          dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: x.late_payment_item_charge[0]?.frequency,
          feeType: x.late_payment_item_charge[0]?.feeType,
          percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
          totalAmount: fields[i].lateFeeTotal,
          // }
        }
      }

      let total: any
      // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
      // if (
      //   parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null ||
      //   isNo == true
      // ) {
      //   total = 0
      //   fields[i].total = 0
      // } else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || fields[i].total == '' || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      newData[i] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.lateDetails?.name,
        paymentItemListId: fields[i]?.lateDetails?._id,

        amount: parseFloat(fields[i].amount),
        quantity: parseFloat(fields[i].qty),
        totalAmount: fields[i]?.lateApplied ? total : parseFloat(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount:
          ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
          parseFloat(fields[i].vat),
        VATPercentage: parseFloat(fields[i].vat),
        latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
        latePaymentSelectionStatus:
          fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
      if (isNo) {
        newData[i].totalAmount = 0
      }
    }

    // attachments
    let attachments: any = []
    for (let i = 0; i < attachedMedia?.length; i++) {
      attachments[i] = attachedMedia[i]?.src
    }

    // chqImages
    let chqImages: any = []
    if (paymentMethod == 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.image
      }
    }

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    const body = {
      payment: {
        _id: incomingPaymentId,
        name: paymentName,
        note: notes,
        invoiceURL: invoiceUrl,
        currency: 'AED',
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate,
        paymentStatus: pStatus,
        categories: paymentCategory,
        amountPaid: parseFloat(paidAmount),
        outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
        subTotalAmount: nonVat,
        totalVAT: vatAmount,
        totalAmount: parseFloat(totalAED),
        // paymentType: 0,
        paymentMethod: parseInt(paymentMethod),
        paymentAccountId: paymentAccountId,
        chequeBankName: chqBankName,
        dueDate: dueDateToShow,
        attachments: attachments,
        chequeImages: chqImages,
        tenancyId: tenancyId,
        unitId: unitDetails?._id,
        clusterId: unitDetails?.clusterId,
        unitGroupId: unitDetails?.unitGroupId,
        communityId: unitDetails?.communityId,
        floorId: unitDetails?.floorId,
        buildingId: unitDetails?.buildingId,
        serviceRequestId: serviceRequestId,
        serviceJobIds: sJob,
      },
      // new_payment_sub_item: newData,
      // [
      //   {
      //     name: '',
      //     paymentItemListId: '',
      //     amount: 1000,
      //     quantity: 1,
      //     totalAmount: 1050,
      //     VATAmount: 50,
      //     VATPercentage: 5,
      //     latePaymentFees: 0,
      //     latePaymentSelectionStatus: 0,
      //     paymentId: '',
      //   },
      // ],
      update_payment_sub_item: newData,
      delete_payment_sub_item: [],
    }

    ApiPost(`corporate/payment/update_all_in_one`, body)
      .then((res: any) => {})
      .catch((err: any) => console.log('err', err))
  }
  const submitRecord = () => {
    let sJob: any = []
    if (serviceJobId != '') sJob[0] = serviceJobId

    let fq: any = ''
    if (frequency == 'Once') fq = 0
    else if (frequency == 'Daily') fq = 1
    else if (frequency == 'Weekly') fq = 2
    else if (frequency == 'Monthly') fq = 3
    else console.log()

    let eDate: any = scheduleEndDate
    if (isEndDateChecked) eDate = null

    // Payment Status
    let pStatus: any = 0
    if (paidAmount == 0 || paidAmount == '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Dynamic Data related to latePayment Object and sub-Item
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.lateApplied) {
        // if(fields[i]?.lateDetails?.late_payment_item_charge){
        let x = fields[i]?.lateDetails

        let xId: any = x.late_payment_item_charge[0]?._id
        newObj = {
          _id: xId,
          dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: x.late_payment_item_charge[0]?.frequency,
          feeType: x.late_payment_item_charge[0]?.feeType,
          percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
          // }
        }
      }

      let total: any
      // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
      // if (parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null || isNo == true)
      //   total = 0
      // else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      if (fields[i]?.isLateItem) {
        newData[i] = {
          name: fields[i]?.item,
          paymentItemListId:
            fields[i]?.paymentItemListId != null
              ? fields[i]?.paymentItemListId
              : fields[i]?.lateDetails?._id,

          amount: parseFloat(fields[i].amount),
          quantity: parseFloat(fields[i].qty),
          totalAmount: total,
          // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
          VATAmount: (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat),
          VATPercentage: parseFloat(fields[i].vat),
          latePaymentFees: fields[i].lateFeeTotal,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          paymentId: incomingPaymentId,
          latePaymentObject: newObj,
          amountPaid: parseFloat(fields[i]?.amountPaid),
          outstandingAmount: parseFloat(fields[i].outstanding),
          paymentSubItemStatus: 2,
          serviceRequestId: null,
          serviceJobId: null,
          // previousAmmountPaid: 0,
        }
      } else {
        newData[i] = {
          name: fields[i]?.item,
          paymentItemListId:
            fields[i]?.paymentItemListId != null
              ? fields[i]?.paymentItemListId
              : fields[i]?.lateDetails?._id,

          amount: parseFloat(fields[i].amount),
          quantity: parseFloat(fields[i].qty),
          totalAmount: total,
          // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
          VATAmount:
            ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
            parseFloat(fields[i].vat),
          VATPercentage: parseFloat(fields[i].vat),
          latePaymentFees: fields[i].lateFeeTotal,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          paymentId: incomingPaymentId,
          latePaymentObject: newObj,
          amountPaid: parseFloat(fields[i]?.amountPaid),
          outstandingAmount: parseFloat(fields[i].outstanding),
          // previousAmmountPaid: 0,
          oldLatePaymentCycleList: fields[i]?.oldLatePaymentCycleList,
          nextLatePaymentCycle: fields[i]?.nextLatePaymentCycleList,
          paymentSubItemStatus: 2,
          serviceRequestId: fields[i]?.serviceRequestId,
          serviceJobId: fields[i]?.serviceJobId,
        }
      }
    }

    // attachments
    let attachments: any = []
    for (let i = 0; i < attachedMedia?.length; i++) {
      attachments[i] = attachedMedia[i]?.src
    }

    // chqImages
    let chqImages: any = []
    if (paymentMethod == 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.image
      }
    }

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    // invoice attachments
    let invAttachments: any = []
    for (let i = 0; i < invoiceAttachedMedia?.length; i++) {
      invAttachments[i] = invoiceAttachedMedia[i]?.src
    }

    //  receipt attachments
    let recAttachments: any = []
    for (let i = 0; i < receiptAttachedMedia?.length; i++) {
      recAttachments[i] = receiptAttachedMedia[i]?.src
    }

    // reminders
    let remindersSched: any = []
    if (paymentCategory == 0) {
      let j: any = 0
      for (let i = 0; i < schedule?.length; i++) {
        if (schedule[i].days != '') {
          remindersSched[j] = {
            reminderDate: schedule[i].date,
            daysBefore: schedule[i].days,
          }
          j++
        }
      }
    }

    // payload for receipt
    let receiptItems: any = []
    for (let i = 0; i < fields?.length; i++) {
      receiptItems[i] = {
        name: fields[i]?.item,
        paymentItemListId: fields[i]?.paymentItemListId,
        totalAmount: fields[i]?.total,
        amountPaid: parseFloat(fields[i]?.amountPaid), //paidAmount
        outstandingAmount: parseFloat(fields[i]?.outstanding),
        previousAmmountPaid: 0,
      }
    }

    let paymentAccountIdReceipt: any
    if (receiptPaymentMethod == 2) paymentAccountIdReceipt = selectedAccountReceipt?._id
    else paymentAccountIdReceipt = null

    //  account selected is Cheque
    let chqBankNameReceipt: any
    if (receiptPaymentMethod == 1) chqBankNameReceipt = receiptChequeBankName
    else chqBankNameReceipt = null

    // chqImages
    let chqImagesReceipt: any = []
    if (receiptPaymentMethod == 1) {
      for (let i = 0; i < receiptChequeMedia?.length; i++) {
        chqImagesReceipt[i] = receiptChequeMedia[i]?.image
      }
    }

    //  Payment Receipt
    let prec: any = []
    if (receiptAdded && isReceiptManualPdf) {
      prec = [
        {
          receiptNo: receiptNo,
          invoiceNo: invoiceNo,
          paymentAccountId: paymentAccountIdReceipt,
          datePaid: datePaid,
          amountPaid: parseFloat(paidAmount),
          outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
          paymentMethod: parseInt(receiptPaymentMethod),
          attachments: recAttachments,
          chequeBankName: chqBankNameReceipt,
          chequeImages: chqImagesReceipt,
          paymentSubItems: receiptItems,
          pdfURL: receiptPdfUrl,
          isManualPDF: isReceiptManualPdf,
        },
      ]
    } else if (receiptAdded == true && isReceiptManualPdf == false) {
      prec = [
        {
          receiptNo: receiptNo,
          invoiceNo: invoiceNo,
          paymentAccountId: paymentAccountIdReceipt,
          datePaid: datePaid,
          amountPaid: parseFloat(paidAmount),
          outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
          paymentMethod: parseInt(receiptPaymentMethod),
          attachments: recAttachments,
          chequeBankName: chqBankNameReceipt,
          chequeImages: chqImagesReceipt,
          paymentSubItems: receiptItems,
        },
      ]
    }

    //  Payment Invoice
    let payInv: any = []
    if (isManualPdf) {
      payInv = [
        {
          invoiceNo: invoiceNo,
          currency: 'AED',
          name: paymentName,
          invoiceDate: invoiceDate,
          dueDate: dueDateToShow ? dueDateToShow : null,
          note: invoiceNotes,
          attachments: invAttachments,
          paymentMethod: parseInt(paymentMethod),
          pdfURL: manualPdfUrl,
          isManualPDF: isManualPdf,
          saveStatus: 1,
          isShareToTenant: checkedRec
        },
      ]
    } else {
      payInv = [
        {
          invoiceNo: invoiceNo,
          currency: 'AED',
          name: paymentName,
          invoiceDate: invoiceDate,
          dueDate: dueDateToShow ? dueDateToShow : null,
          note: invoiceNotes,
          attachments: invAttachments,
          paymentMethod: parseInt(paymentMethod),
          saveStatus: 1,
          isShareToTenant: checkedRec
        },
      ]
    }

    const body = {
      payment: {
        name: paymentName,
        note: notes,
        currency: 'AED',
        paymentStatus: pStatus,
        categories: paymentCategory,
        amountPaid: parseFloat(paidAmount),
        outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
        subTotalAmount: nonVat,
        totalVAT: vatAmount,
        totalAmount: parseFloat(totalAED),
        // paymentType: 0,
        paymentMethod: parseInt(paymentMethod),
        paymentAccountId: paymentAccountId,
        chequeBankName: chqBankName,
        isCardVerificationEnable: checkVerify,
        dueDate: dueDateToShow,
        attachments: attachments,
        chequeImages: chqImages,
        tenancyId: tenancyId,
        unitId: unitDetails?._id,
        clusterId: unitDetails?.clusterId,
        unitGroupId: unitDetails?.unitGroupId,
        communityId: unitDetails?.communityId,
        floorId: unitDetails?.floorId,
        buildingId: unitDetails?.buildingId,
        // serviceRequestId: serviceRequestId,
        // serviceJobIds: sJob,
        reminderAfterPayment: {
          startDate: scheduleStartDate,
          endDate: eDate,
          frequency: fq,
          isEndDateChecked: isEndDateChecked,
        },
      },
      new_payment_sub_item: newData,
      new_payment_invoice: payInv,
      new_payment_receipt: prec,
      new_payment_reminder: remindersSched,
    }

    ApiPost(`corporate/payment/add_all_in_one`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getAllPayments()
      })
      .catch((err: any) => console.log('err', err))
  }
  //
  const validateIncomingPayment = () => {
    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    // Payment Status
    let pStatus: any = 0
    if (paidAmount == 0 || paidAmount == '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    let itmMissing: any = false
    let ind: any = false
    let mainReqErr: any = false
    for (let i = 0; i < fields.length; i++) {
      if (
        fields[i].item == '' ||
        fields[i].amount == '' ||
        fields[i].qty == '' ||
        fields[i].vat == ''
      ) {
        itmMissing = true
        ind = i + 1
        i = fields.length
      } else if (fields[i].item == 'Maintenance') {
        if (fields[i].serviceRequestId == null) mainReqErr = true
        ind = i + 1

        i = fields.length
      }
    }
    if (itmMissing == true) ErrorToast(`Item # ${ind} has some missing fields..`)
    else if (mainReqErr) ErrorToast(`Please link Item # ${ind} to some Maintenance Request..`)
    else if (paymentName == '') {
      ErrorToast(`Please Enter Payment Name...`)
      setPaymentNameError(true)
      nameRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (paidAmount === '') {
      ErrorToast(`Please Enter Paid Amount...`)
      setAmountPaidError(true)
      nameRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (pStatus == 1 || pStatus == 2) {
      if (datePaid === null || datePaid == '') {
        setDatePaidError(true)
        ErrorToast(`Please select Date Paid...`)
        nameRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (pStatus == 2 && (dueDate == null || dueDate == '')) {
        setDueDateError(true)
        ErrorToast(`Please select Due Date ...`)
        nameRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paidAmount > totalAED) {
        ErrorToast(`Paid Amount should not exceed the Total Amount..`)
        // setAmountPaidError(true)
        // nameRef.current.scrollIntoView({behavior: 'smooth'})
        setTimeout(() => {
          submitRecord()
        }, 1000)
      } else if (invoiceAdded == false) {
        ErrorToast(`Please Generate an Invoice..`)
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (yellowInvoice) {
        ErrorToast(
          `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
        )
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paidAmount > 0) {
        if (receiptAdded == false) {
          ErrorToast(`Please Generate a Receipt..`)
          receiptRef.current.scrollIntoView({behavior: 'smooth'})
        } else {
          submitRecord()
        }
      } else {
        submitRecord()
      }
    } else if (pStatus == 0) {
      if (dueDate == null || dueDate == '') {
        setDueDateError(true)
        ErrorToast(`Please select Due Date ...`)
        nameRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paymentMethod == 2) {
        if (selectedAccount == null) {
          ErrorToast(`Please select Payment Acccount..`)
        } else if (invoiceAdded == false) {
          ErrorToast(`Please Generate an Invoice..`)
          invoiceRef.current.scrollIntoView({behavior: 'smooth'})
        } else if (yellowInvoice) {
          ErrorToast(
            `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
          )
          invoiceRef.current.scrollIntoView({behavior: 'smooth'})
        } else if (paidAmount > 0) {
          if (receiptAdded == false) {
            ErrorToast(`Please Generate a Receipt..`)
            receiptRef.current.scrollIntoView({behavior: 'smooth'})
          } else {
            submitRecord()
          }
        } else {
          submitRecord()
        }
      } else if (paymentMethod == 1) {
        if (chequeMedia?.length <= 0) {
          ErrorToast(`Please Upload atleast one Cheque..`)
        } else if (invoiceAdded == false) {
          ErrorToast(`Please Generate an Invoice..`)
          invoiceRef.current.scrollIntoView({behavior: 'smooth'})
        } else if (yellowInvoice) {
          ErrorToast(
            `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
          )
          invoiceRef.current.scrollIntoView({behavior: 'smooth'})
        } else if (paidAmount > 0) {
          if (receiptAdded == false) {
            ErrorToast(`Please Generate a Receipt..`)
            receiptRef.current.scrollIntoView({behavior: 'smooth'})
          } else {
            submitRecord()
          }
        } else {
          submitRecord()
        }
      } else if (yellowInvoice) {
        ErrorToast(
          `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
        )
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (invoiceAdded == false) {
        ErrorToast(`Please Generate an Invoice..`)
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paidAmount > 0) {
        if (receiptAdded == false) {
          ErrorToast(`Please Generate a Receipt..`)
          receiptRef.current.scrollIntoView({behavior: 'smooth'})
        } else {
          submitRecord()
        }
      } else if (paidAmount > totalAED) {
        ErrorToast(`Paid Amount should not exceed the Total Amount..`)
        // setAmountPaidError(true)
        // nameRef.current.scrollIntoView({behavior: 'smooth'})
        setTimeout(() => {
          submitRecord()
        }, 1000)
      } else {
        submitRecord()
      }
    } else if (paidAmount > totalAED) {
      ErrorToast(`Paid Amount should not exceed the Total Amount..`)
      setAmountPaidError(true)
      nameRef.current.scrollIntoView({behavior: 'smooth'})
      setTimeout(() => {
        submitRecord()
      }, 1000)
    } else if (paymentMethod == 2) {
      if (selectedAccount == null) {
        ErrorToast(`Please select Payment Acccount..`)
      } else if (invoiceAdded == false) {
        ErrorToast(`Please Generate an Invoice..`)
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (yellowInvoice) {
        ErrorToast(
          `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
        )
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paidAmount > 0) {
        if (receiptAdded == false) {
          ErrorToast(`Please Generate a Receipt..`)
          receiptRef.current.scrollIntoView({behavior: 'smooth'})
        } else {
          submitRecord()
        }
      } else {
        submitRecord()
      }
    } else if (paymentMethod == 1) {
      if (chequeMedia?.length <= 0) {
        ErrorToast(`Please Upload atleast one Cheque..`)
      } else if (invoiceAdded == false) {
        ErrorToast(`Please Generate an Invoice..`)
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (yellowInvoice) {
        ErrorToast(
          `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
        )
        invoiceRef.current.scrollIntoView({behavior: 'smooth'})
      } else if (paidAmount > 0) {
        if (receiptAdded == false) {
          ErrorToast(`Please Generate a Receipt..`)
          receiptRef.current.scrollIntoView({behavior: 'smooth'})
        } else {
          submitRecord()
        }
      } else {
        submitRecord()
      }
    } else if (invoiceAdded == false) {
      ErrorToast(`Please Generate an Invoice..`)
      invoiceRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (yellowInvoice) {
      ErrorToast(
        `Please click on the INVOICE to REVIEW CHANGES and click GENERATE again to confirm the changes!!!`
      )
      invoiceRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (paidAmount > 0) {
      if (receiptAdded == false) {
        ErrorToast(`Please Generate a Receipt..`)
        receiptRef.current.scrollIntoView({behavior: 'smooth'})
      } else {
        submitRecord()
      }
    } else {
      submitRecord()
    }
  }

  const getServiceJobForPayment = () => {
    const body = {
      page: 1,
      limit: 10,
      unitId: unitDetails?._id,
    }
    ApiPost(`corporate/service_job/list/payment`, body)
      .then((res: any) => {
        setRadioLinkChecked(-1)
        setMaintenaceReqData(res?.data?.data?.service_job_data)
        setMainReqLinkModal(true)
      })
      .catch((err: any) => console.log('err', err))
  }

  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [disableLink, setDisableLink] = useState<any>(true)
  const [serviceJobId, setServiceJobId] = useState<any>('')
  const [serviceRequestId, setServiceRequestId] = useState<any>(null)

  const [jobId, setjobId] = useState<any>('')
  const [requestId, setRequestId] = useState<any>('')

  //
  const handleDateRangeChange = (dates: any) => {
    setStartDate(dates?.[0].format('YYYY.MM.DD'))
    setEndDate(dates?.[1].format('YYYY.MM.DD'))

    const body = {
      page: 1,
      limit: 10,
      startDate: dates?.[0].format('YYYY-MM-DD'),
      endDate: dates?.[1].format('YYYY-MM-DD'),
      unitId: unitDetails?._id,
    }
    ApiPost(`corporate/service_job/list/payment`, body).then((res: any) => {
      setRadioLinkChecked(-1)
      setMaintenaceReqData(res?.data?.data?.service_job_data)
      // setMainReqLinkModal(true)
    })
  }

  const applyFilter = (event: any) => {
    setShowFiltered(true)
    if (event.target.value === '') setShowFiltered(false)

    // console.log(event.target.value)

    const filteredItems = unitsTenancy.filter((item: any) =>
      item.contractNo.includes(event.target.value)
    )
    // console.log(filteredItems)
    setFilteredData(filteredItems)
  }

  const addSubItemFunc = () => {
    ApiPost(`corporate/payment_sub_item`)
      .then((res: any) => {
        // setSubItemId(res?.data?.data._id)
        // setItemId(res?.data?.data.id)
        console.log(res?.data?.data.id)
        // setIncomeModal(true)
      })
      .catch((err: any) => console.log('err', err))
  }

  // get tenancy
  const getTenancy = () => {
    if(tnancy == null) {
    const body = {
      unitId: unitDetails?._id,
    }
    ApiPost(`corporate/tenancy/get/payment`, body)
      .then((res) => {
        setUnitsTenancy(res?.data?.data)
        setFilteredData(res?.data?.data)
        setTotalTenancies(res?.data?.data?.length)
        setTenantId(res?.data?.data?.[0]?.tenantId)
        // getTenantDetails(res?.data?.data?.[0]?.tenantId)
      })
      .catch((err) => console.log('err', err))
    }
    else{
      ApiGet(`corporate/tenancy/full_detail/${tnancy}`)
      .then((res) => {
        setTenancyId(res?.data?.data[0]._id)
        setTenantId(res?.data?.data[0]?.tenantId)
        getTenantDetails(res?.data?.data[0]?.tenantId) 
        setIsSelected(true)
      })
      .catch((err) => console.log('err', err))
    }
  }

  const getTenantDetails = async (id: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantDetails(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  useEffect(() => {
    getDetailsForInvoiceAndReceipt()
    setTenancyId(null)
    setShowAcc(false)
    setSelectedAccount(null)
    setShowLink(false)
    setFields([
      {
        _id: subItemId,
        // id: `${subId}`,
        id: subId,
        item: '',
        amount: '0',
        qty: '1',
        vat: '0',
        total: '',
        totalWithoutLate: 0,
        lateApplied: false,
        lateDetails: [],
        activeLATE: false,
        InactiveLATE: true,
        lateFeeTotal: 0,
        lateOnce: false,
        isLateItem: false,
        lateItemId: null,
        lateItemIndex: null,
        paymentItemListId: null,
        amountPaid: 0,
        outstanding: 0,
        oldLatePaymentCycleList: [],
        nextLatePaymentCycleList: null,
      },
    ])
    setIsSelected(false)
    setPaymentName('')
    setAmountPaid(0)
    setDueDate(null)
    setDatePaid(null)
    setPaymentMethod(0)
    setAttachedMedia([])
    setInvoiceAttachedMedia([])
    setUnitsTenancy([])
    // setType('')
    setInvoiceNo('')
    setReceiptNo('')
    setSchedule([
      {
        id: '',
        days: '',
        date: '',
      },
    ])
    setNotes('')
    setInvoiceNotes('')
    setRadioChecked(-1)
    setRadioLinkChecked(-1)

    // setclusterunit([])
    // setSelectedUnitData([])
    setIsEditt(false)
    setDisableSelect(true)
    setInvoiceAdded(false)
    setReceiptAdded(false)
    setInvoiceUrl('')
    setReceiptUrl('')
    getPaymentItemsList()
    getTenancy()
    setIsSelected(false)
    setcollapse(true)
    setChequeMedia([])
    setChequeBankName('')
    setServiceJobId('')
    setjobId('')
    setRequestId('')
    setServiceRequestId(null)
    setDisableLink(true)
  }, [id])

  // calculating late payment fee on an item
  const getLatePaymentFee = (field: any) => {
    const currentDate = moment()
    const dueDatee: any = moment(dueDate)
    // const diffInDays = currentDate.diff(dueDatee, 'days')
    let x = currentDate.diff(dueDatee, 'days')

    const diffInDays = x - field.lateDetails.late_payment_item_charge[0]?.dayAfterPaymentDue
    // console.log(diffInDays)
    switch (field.lateDetails.late_payment_item_charge[0]?.frequency) {
      case 0: {
        if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0)
          return diffInDays > 0
            ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * field?.qty
            : 0
        else {
          let x =
            ((field?.amount * field?.qty) / 100) *
            field.lateDetails.late_payment_item_charge[0]?.percentageAmount
          return diffInDays > 0 ? x * field?.qty : 0
        }
      }
      case 1: {
        if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0)
          return diffInDays > 0
            ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * field?.qty * diffInDays
            : 0
        else {
          let x =
            ((field?.amount * field?.qty) / 100) *
            field.lateDetails.late_payment_item_charge[0]?.percentageAmount
          return diffInDays > 0 ? x * field?.qty * diffInDays : 0
        }
      }

      case 2: {
        if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0) {
          const diffInWeeks = Math.floor(diffInDays / 7)
          return diffInWeeks > 0
            ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * diffInWeeks * field?.qty
            : 0
        } else {
          const diffInWeeks = Math.floor(diffInDays / 7)
          let x =
            ((field?.amount * field?.qty) / 100) *
            field.lateDetails.late_payment_item_charge[0]?.percentageAmount
          return diffInDays > 0 ? x * field?.qty * diffInWeeks : 0
        }
      }

      case 3: {
        if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0) {
          const diffInMonths = Math.floor(diffInDays / 30)
          return diffInMonths > 0
            ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * diffInMonths * field?.qty
            : 0
        } else {
          const diffInMonths = Math.floor(diffInDays / 30)
          let x =
            ((field?.amount * field?.qty) / 100) *
            field.lateDetails.late_payment_item_charge[0]?.percentageAmount
          // console.log(x)
          return diffInDays > 0 ? x * field?.qty * diffInMonths : 0
        }
      }

      default:
        return 0
    }
  }

  // getting list of all bank accounts
  const getBankAccounts = () => {
    const body = {
      limit: 10,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res: any) => {
        setBankAccountItems(res?.data?.data?.payment_account_data)
      })
      .catch((err: any) => console.log('err', err))
  }

  // triggers when file is selected with click
  const handleChange = async (e: any) => {
    e.preventDefault()
    // console.log(e.target.files)
    const values = [...chequeMedia]
    let x = values.length - 1
    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            x = x + 1
            values[x] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))

        // handleFiles(e.target.files);
      }
      setChequeMedia(values)
    }
  }

  const handleReceiptChequeChange = async (e: any) => {
    e.preventDefault()

    const values = [...receiptChequeMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        // uploadCheque(values[values.length], x)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[values.length] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))
      }
      setReceiptChequeMedia(values)
    }
  }

  // set cheque number
  const renameChequeReceipt = async (chq: any, type: any) => {
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]
    // console.log(chq)
    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res) => {
        const values = [...receiptChequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setReceiptChequeMedia(values)
      })
      .catch((err) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
        console.log('err', err)
      })
  }

  // delete cheque
  const deleteChequeReceipt = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        const values = [...receiptChequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }

        SuccessToast('Cheque deleted successfully')
        setReceiptChequeMedia(newData)
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // set cheque number
  const renameCheque = async (chq: any, type: any) => {
    // console.log(chq)
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]
    // console.log(chq)
    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res: any) => {
        const values = [...chequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setChequeMedia(values)
      })
      .catch((err: any) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
        console.log('err', err)
      })
  }

  // delete cheque
  const deleteCheque = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...chequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }
        SuccessToast('Cheque deleted successfully')
        setChequeMedia(newData)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // generate an invoice
  const generateInvoice = async () => {
    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    let attachments: any = []

    for (let i = 0; i < invoiceAttachedMedia?.length; i++) {
      attachments[i] = invoiceAttachedMedia[i]?.src
    }

    let today = new Date()
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Payment Method
    let payMethod: any
    if (paymentMethod === 'Bank') payMethod = 0
    else if (paymentMethod === 'Cheque') payMethod = 1
    else if (paymentMethod === 'Cash') payMethod = 2
    else payMethod = 2
    const body = {
      invoiceNo: invoiceNo,
      currency: 'AED',
      name: paymentName,
      invoiceDate: invoiceDate,
      dueDate: dueDateToShow ? dueDateToShow : null,
      _id: incomingPaymentId,
      unitId: unitId,
      tenancyId: tenancyId,
      tenantId: tenantId,
      invoiceNote: invoiceNotes,
      invoiceAttachments: attachments,
      paymentMethod: parseInt(paymentMethod),
      paymentStatus: pStatus,
      outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
      categories: paymentCategory,
      subTotalAmount: nonVat,
      totalVAT: vatAmount,
      totalAmount: totalAED,
      amountPaid: paidAmount,
    }
    await ApiPost(`corporate/payment/invoice`, body)
      .then((res: any) => {
        setInvoiceUrl(res?.data?.data?.pdfURL)
        setPaymentInvoiceId(res?.data?.data?.paymentInvoiceId)
        setInvoiceAdded(true)
        SuccessToast('Invoice generated successfully..')

        // updatePayment(res?.data?.data?.pdfURL, paymentMethod)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const updatePayment = (url: any, pM: any) => {
    let sJob: any = []
    if (serviceJobId != '') sJob[0] = serviceJobId

    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    // Payment Status
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Dynamic Data
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.lateApplied) {
        // if(fields[i]?.lateDetails?.late_payment_item_charge){
        let x = fields[i]?.lateDetails

        let xId: any = x.late_payment_item_charge[0]?._id
        newObj = {
          _id: xId,
          // id: '' + x.late_payment_item_charge[0]?.id + '',
          dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: x.late_payment_item_charge[0]?.frequency,
          feeType: x.late_payment_item_charge[0]?.feeType,
          percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
          // totalAmount: fields[i].lateFeeTotal,
          // }
        }
      }

      let total: any
      // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
      // if (
      //   parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null ||
      //   isNo == true
      // ) {
      //   total = 0
      //   fields[i].total = 0
      // } else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      newData[i] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.lateDetails?.name,
        paymentItemListId: fields[i]?.lateDetails?._id,

        amount: parseFloat(fields[i].amount),
        quantity: parseFloat(fields[i].qty),
        totalAmount: fields[i]?.lateApplied ? total : parseFloat(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount:
          ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
          parseFloat(fields[i].vat),
        VATPercentage: parseFloat(fields[i].vat),
        latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
        latePaymentSelectionStatus:
          fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
    }

    // attachments
    let attachments: any = []
    for (let i = 0; i < attachedMedia?.length; i++) {
      attachments[i] = attachedMedia[i]?.src
    }

    // chqImages
    let chqImages: any = []
    if (paymentMethod == 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.image
      }
    }

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    const body = {
      payment: {
        _id: incomingPaymentId,
        name: paymentName,
        note: notes,
        invoiceURL: invoiceUrl,
        currency: 'AED',
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate,
        paymentStatus: pStatus,
        categories: paymentCategory,
        amountPaid: parseFloat(paidAmount),
        outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
        subTotalAmount: nonVat,
        totalVAT: vatAmount,
        totalAmount: parseFloat(totalAED),
        // paymentType: 0,
        paymentMethod: parseInt(pM),
        paymentAccountId: paymentAccountId,
        chequeBankName: chqBankName,
        dueDate: dueDateToShow,
        attachments: attachments,
        chequeImages: chqImages,
        tenancyId: tenancyId,
        unitId: unitDetails?._id,
        clusterId: unitDetails?.clusterId,
        unitGroupId: unitDetails?.unitGroupId,
        communityId: unitDetails?.communityId,
        floorId: unitDetails?.floorId,
        buildingId: unitDetails?.buildingId,
        serviceRequestId: serviceRequestId,
        serviceJobIds: sJob,
      },

      update_payment_sub_item: newData,
      delete_payment_sub_item: [],
    }
    ApiPost(`corporate/payment/update_all_in_one`, body)
      .then((res: any) => {})
      .catch((err: any) => console.log('err', err))
  }

  // delete invoice
  const deleteInvoice = () => {
    const body = {
      url: invoiceUrl,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        SuccessToast('Invoice deleted successfully..')
        setInvoiceAdded(false)
        setInvoiceUrl('')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const sentInvoiceToTenant = () => {
    const body = {
      paymentId: incomingPaymentId,
    }
    ApiPost(`corporate/payment/invoice/sent`, body)
      .then((res: any) => {
        SuccessToast('The invoice has been successfully sent to the tenant..')
        setInvoiceModal(false)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const generateReceipt = () => {
    let attachments: any = []

    for (let i = 0; i < receiptAttachedMedia?.length; i++) {
      attachments[i] = receiptAttachedMedia[i]?.src
    }

    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    const body = {
      receiptNo: receiptNo,
      invoiceNo: invoiceNo,
      companyName: 'Corporate User Real Estate Services LLC',
      companyAddress:
        'A-1401 Abhishek building, Nr gokuldham bungalows, Abrama Rd, Mota Varachha, Surat, Gujarat 394105',
      paymentId: incomingPaymentId,
      paymentAccountId: paymentAccountId,
      datePaid: datePaid,
      amountPaid: parseFloat(paidAmount),
      outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
      paymentMethod: parseFloat(paymentMethod),
      attachments: attachments,
    }

    ApiPost(`corporate/payment_receipt`, body)
      .then((res: any) => {
        setReceiptUrl(res?.data?.data?.pdfURL)
        setReceiptId(res?.data?.data?._id)
        setReceiptAdded(true)
        setReceiptModal(false)
        SuccessToast('Receipt generated successfully..')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // // delete receipt
  const deleteReceipt = () => {
    ApiDelete(`corporate/payment_receipt/` + receiptId)
      .then((res: any) => {
        SuccessToast('Receipt deleted successfully..')
        setReceiptAdded(false)
        setInvoiceUrl('')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const handleInvoiceAttachmentChange = async (e: any) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      const values = [...invoiceAttachedMedia]
      let newVal = values.length - 1

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]

        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/originalname/image/invoice_attachment`, formdata)
          .then((res) => {
            newVal = newVal + 1

            let x = res?.data?.data?.image?.split('/')[2]

            values[newVal] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }
          })
          .catch(() => {})
      }
      setInvoiceAttachedMedia(values)
    }
  }

  // delete attachment
  const deleteInvoiceAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...invoiceAttachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setInvoiceAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const handleReceiptAttachmentChange = async (e: any) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      const values = [...receiptAttachedMedia]
      let newVal = values.length - 1

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]

        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/originalname/image/receipt_attachment`, formdata)
          .then((res) => {
            let x = res?.data?.data?.image?.split('/')[2]
            newVal = newVal + 1

            values[newVal] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }
          })
          .catch(() => {})
      }
      setReceiptAttachedMedia(values)
    }
  }

  // delete attachment
  const deleteReceiptAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...receiptAttachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setReceiptAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  const validationforInvoice = () => {
    let itmMissing: any = false
    let ind: any = false

    for (let i = 0; i < fields.length; i++) {
      if (
        fields[i].item == '' ||
        fields[i].amount == '' ||
        fields[i].qty == '' ||
        fields[i].vat == ''
      ) {
        itmMissing = true
        ind = i + 1
        i = fields.length
      }
    }
    if (itmMissing == true) ErrorToast(`Item # ${ind} has some missing fields..`)
    else if (paymentMethod == 2) {
      if (selectedAccount == null) {
        ErrorToast(`Please select Payment Acccount..`)
      } else {
        submitIncomingPayment()
        let today = new Date()
        let x = today.toLocaleDateString('fr-CA')
        // console.log(today.toLocaleDateString('uk-UA'))
        setInvoiceDate(x)

        setInvoiceModal(true)
      }
    } else if (paymentMethod == 1) {
      if (chequeMedia?.length <= 0) {
        ErrorToast(`Please Upload atleast one Cheque..`)
      } else {
        submitIncomingPayment()
        let today = new Date()
        let x = today.toLocaleDateString('fr-CA')
        // console.log(today.toLocaleDateString('uk-UA'))
        setInvoiceDate(x)

        setInvoiceModal(true)
      }
    } else {
      submitIncomingPayment()
      let today = new Date()
      let x = today.toLocaleDateString('fr-CA')
      // console.log(today.toLocaleDateString('uk-UA'))
      setInvoiceDate(x)

      setInvoiceModal(true)
    }
  }

  const deletePayment = async () => {
    await ApiDelete(`corporate/payment/${incomingPaymentId}`)
      .then((res) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getAllPayments()
      })
      .catch((err) => console.log('err', err))
  }

  const deleteSubItem = async (id: any, index: any) => {
    await ApiDelete(`corporate/payment_sub_item/${id}`).then((res) => {
      // SuccessToast(res?.data?.message)
    })
  }

  // CREATING a late payment item
  const getLatePaymentItem = async (item: any, index: any) => {
    const body = {
      payment: {
        dueDate: dueDateToShow,
      },
      payment_sub_item: {
        name: item?.lateDetails?.name,
        paymentItemListId:
          item?.paymentItemListId != null ? item?.paymentItemListId : item?.lateDetails?._id,
        amount: parseFloat(item?.amount),
        quantity: parseFloat(item?.qty),
        totalAmount: item?.total == '' ? 0 : parseFloat(item?.total),
        VATAmount:
          ((parseFloat(item?.amount) * parseFloat(item?.qty)) / 100) * parseFloat(item?.vat),
        VATPercentage: parseFloat(item?.vat),
        latePaymentFees: item?.lateFeeTotal,
        latePaymentSelectionStatus:
          item?.activeLATE === true ? 1 : item?.InactiveLATE === true ? 2 : 0,
        latePaymentObject: {
          _id: item?.lateDetails?.late_payment_item_charge[0]?._id,
          dayAfterPaymentDue: item?.lateDetails?.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: item?.lateDetails?.late_payment_item_charge[0]?.frequency,
          feeType: item?.lateDetails?.late_payment_item_charge[0]?.feeType,
          percentageAmount: item?.lateDetails?.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: item?.lateDetails?.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: item?.lateDetails?.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: item?.lateDetails?.late_payment_item_charge[0]?.VATAmount,
        },
      },
    }

    await ApiPost(`corporate/payment_sub_item/late_payment/calculation`, body)
      .then((res) => {
        const values = [...fields]
        if (res?.data?.data?.name != undefined) {
          values.push({
            // _id: `${res?.data?.data._id}`,
            // id: `${res?.data?.data.id}`,
            item: res?.data?.data?.name,
            amount: res?.data?.data?.amount,
            qty: res?.data?.data?.quantity,
            vat: res?.data?.data?.VATPercentage,
            total: res?.data?.data?.totalAmount,
            lateApplied: false,
            lateDetails: res?.data?.data?.latePaymentObject,
            activeLATE: false,
            InactiveLATE: true,
            lateFeeTotal: 0,
            lateOnce: false,
            isLateItem: true,
            lateItemId: null,
            lateItemIndex: null,
            paymentItemListId: res?.data?.data?.paymentItemListId,
            amountPaid: 0,
            outstanding: res?.data?.data?.totalAmount,
            oldLatePaymentCycleList: [],
            nextLatePaymentCycleList: null,
          })

          values[index].lateItemId = `${res?.data?.data?._id}`
          values[index].lateItemIndex = fields?.length

          values[index].lateFeeTotal = res?.data?.data?.totalAmount
          values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
          values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle
          setFields(values)
        } else {
          values[index].lateFeeTotal = 0
          values[index].lateItemIndex = -1
          setFields(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  //
  const changeFields = async (item: any, index: any) => {
    const body = {
      payment: {
        dueDate: dueDateToShow,
      },
      payment_sub_item: {
        name: item?.lateDetails?.name,
        paymentItemListId:
          item?.paymentItemListId != null ? item?.paymentItemListId : item?.lateDetails?._id,
        amount: parseFloat(item?.amount),
        quantity: parseFloat(item?.qty),
        totalAmount: item?.total == '' ? 0 : parseFloat(item?.total),
        VATAmount:
          ((parseFloat(item?.amount) * parseFloat(item?.qty)) / 100) * parseFloat(item?.vat),
        VATPercentage: parseFloat(item?.vat),
        latePaymentFees: item?.lateFeeTotal,
        latePaymentSelectionStatus:
          item?.activeLATE === true ? 1 : item?.InactiveLATE === true ? 2 : 0,
        latePaymentObject: {
          _id: item?.lateDetails?.late_payment_item_charge[0]?._id,
          dayAfterPaymentDue: item?.lateDetails?.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: item?.lateDetails?.late_payment_item_charge[0]?.frequency,
          feeType: item?.lateDetails?.late_payment_item_charge[0]?.feeType,
          percentageAmount: item?.lateDetails?.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: item?.lateDetails?.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: item?.lateDetails?.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: item?.lateDetails?.late_payment_item_charge[0]?.VATAmount,
        },
      },
    }

    await ApiPost(`corporate/payment_sub_item/late_payment/calculation`, body)
      .then((res) => {
        const values = [...fields]

        if (res?.data?.data?.name != undefined) {
          // console.log(values)
          if (item?.lateItemIndex != -1) {
            values[item?.lateItemIndex] = {
              // _id: `${res?.data?.data._id}`,
              // id: `${res?.data?.data.id}`,
              item: res?.data?.data?.name,
              amount: res?.data?.data?.amount,
              qty: res?.data?.data?.quantity,
              vat: res?.data?.data?.VATPercentage,
              total: res?.data?.data?.totalAmount,
              lateApplied: false,
              lateDetails: res?.data?.data?.latePaymentObject,
              activeLATE: false,
              InactiveLATE: true,
              lateFeeTotal: 0,
              lateOnce: false,
              isLateItem: true,
              lateItemId: null,
              lateItemIndex: null,
              paymentItemListId: res?.data?.data?.paymentItemListId,
              amountPaid: 0,
              outstanding: res?.data?.data?.totalAmount,
              oldLatePaymentCycleList: [],
              nextLatePaymentCycleList: null,
            }
          } else {
            values.push({
              // _id: `${res?.data?.data._id}`,
              // id: `${res?.data?.data.id}`,
              item: res?.data?.data?.name,
              amount: res?.data?.data?.amount,
              qty: res?.data?.data?.quantity,
              vat: res?.data?.data?.VATPercentage,
              total: res?.data?.data?.totalAmount,
              lateApplied: false,
              lateDetails: res?.data?.data?.latePaymentObject,
              activeLATE: false,
              InactiveLATE: true,
              lateFeeTotal: 0,
              lateOnce: false,
              isLateItem: true,
              lateItemId: null,
              lateItemIndex: null,
              paymentItemListId: res?.data?.data?.paymentItemListId,
              amountPaid: 0,
              outstanding: res?.data?.data?.totalAmount,
              oldLatePaymentCycleList: [],
              nextLatePaymentCycleList: null,
            })

            values[index].lateItemId = `${res?.data?.data?._id}`
            values[index].lateItemIndex = fields?.length

            values[index].lateFeeTotal = res?.data?.data?.totalAmount
            values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
            values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle
            setFields(values)
          }

          values[index].lateItemId = `${res?.data?.data?._id}`
          // values[index].lateItemIndex = fields?.length
          values[index].lateFeeTotal = res?.data?.data?.totalAmount
          values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
          values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle

          setFields(values)
        } else {
          values.splice(item?.lateItemIndex, 1)
          values[index].lateFeeTotal = 0
          setFields(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const [uploadInvoiceModal, setUploadInvoiceModal] = useState<any>(false)
  const [isManualPdf, setIsManualPdf] = useState<any>(false)
  const [manualPdfUrl, setManualPdfUrl] = useState<any>('')

  const [invRecDetails, setInvRecDetails] = useState<any>(null)
  const [invoiceCompany, setInvoiceCompany] = useState<any>(null)
  const [receiptCompany, setReceiptCompany] = useState<any>(null)

  //
  const getDetailsForInvoiceAndReceipt = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        setInvoiceCompany(res?.data?.data?.invoiceCompany)
        setReceiptCompany(res?.data?.data?.receiptCompany)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const uploadInvoice = async (e: any) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // let fileURL = URL.createObjectURL(e.target.files[0])
      let fileURL = e.target.files[0]

      var formdata = new FormData()
      formdata.append('image', fileURL)

      await ApiPost(`upload/image/troubleshoot_request`, formdata)
        .then((res) => {
          setManualPdfUrl(res?.data?.data?.image)
          setIsManualPdf(true)
          setUploadInvoiceModal(false)
          setInvoiceModal(false)
          setInvoiceAdded(true)
          setYellowInvoice(false)
          SuccessToast('Invoice has been uploaded successfully!!!')
        })
        .catch(() => {})
    }
  }

  const [isReceiptManualPdf, setIsReceiptManualPdf] = useState<any>(false)
  const [receiptPdfUrl, setReceiptPdfUrl] = useState<any>('')
  const [isReceipt, setIsReceipt] = useState<any>(false)

  const uploadReceipt = async (e: any) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // let fileURL = URL.createObjectURL(e.target.files[0])
      let fileURL = e.target.files[0]

      var formdata = new FormData()
      formdata.append('image', fileURL)

      await ApiPost(`upload/image/troubleshoot_request`, formdata)
        .then((res) => {
          setReceiptPdfUrl(res?.data?.data?.image)
          setIsReceiptManualPdf(true)
          setUploadInvoiceModal(false)
          setReceiptModal(false)
          setReceiptAdded(true)
          setIsReceipt(false)
          SuccessToast('Receipt has been uploaded successfully!!!')
        })
        .catch(() => {})
    }
  }

  const [scheduleStartDate, setScheduleStartDate] = useState<any>(null)
  const [scheduleEndDate, setScheduleEndDate] = useState<any>(null)

  const options = [
    {value: '0', label: 'Once'},
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Weekly'},
    {value: '3', label: 'Monthly'},
  ]

  const optionsChecked = [
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Weekly'},
    {value: '3', label: 'Monthly'},
  ]

  const [generatedDates, setGeneratedDates] = useState<string[]>([])
  const [isEndDateChecked, setIsEndDateChecked] = useState<any>(false)
  const [frequency, setFrequency] = useState<any>(null)

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  //
  const handleStartDateChange = (date: any) => {
    if (date != null || date !== null) {
      setScheduleStartDate(date.format('YYYY-MM-DD'))
      if (frequency != null) {
        const generatedDates: string[] = []
        const currentDate = new Date(date)
        const endDateObj = new Date(scheduleEndDate)

        if (frequency === 'Once') {
          generatedDates.push(currentDate.toDateString())
        } else {
          while (currentDate <= endDateObj) {
            generatedDates.push(currentDate.toDateString())

            if (frequency === 'Daily') {
              currentDate.setDate(currentDate.getDate() + 1)
            } else if (frequency === 'Weekly') {
              currentDate.setDate(currentDate.getDate() + 7)
            } else if (frequency === 'Monthly') {
              currentDate.setMonth(currentDate.getMonth() + 1)
            }
          }
        }

        setGeneratedDates(generatedDates)
      }
    } else {
      setScheduleStartDate(null)
    }
  }

  //
  const handleEndDateChange = (date: any) => {
    if (date != null || date !== null) {
      setScheduleEndDate(date.format('YYYY-MM-DD'))
      if (frequency != null) {
        const generatedDates: string[] = []
        const currentDate = new Date(scheduleStartDate)
        const endDateObj = new Date(date)

        if (frequency === 'Once') {
          generatedDates.push(currentDate.toDateString())
        } else {
          while (currentDate <= endDateObj) {
            generatedDates.push(currentDate.toDateString())

            if (frequency === 'Daily') {
              currentDate.setDate(currentDate.getDate() + 1)
            } else if (frequency === 'Weekly') {
              currentDate.setDate(currentDate.getDate() + 7)
            } else if (frequency === 'Monthly') {
              currentDate.setMonth(currentDate.getMonth() + 1)
            }
          }
        }

        setGeneratedDates(generatedDates)
      }
    } else {
      setScheduleEndDate(null)
    }
  }

  //
  const calculateDates = (e: any) => {
    let frequency = e.label
    setFrequency(e.label)
    const generatedDates: string[] = []
    const currentDate = new Date(scheduleStartDate)
    const endDateObj = new Date(scheduleEndDate)

    if (frequency === 'Once') {
      generatedDates.push(currentDate.toDateString())
    } else {
      while (currentDate <= endDateObj) {
        generatedDates.push(currentDate.toDateString())

        if (frequency === 'Daily') {
          currentDate.setDate(currentDate.getDate() + 1)
        } else if (frequency === 'Weekly') {
          currentDate.setDate(currentDate.getDate() + 7)
        } else if (frequency === 'Monthly') {
          currentDate.setMonth(currentDate.getMonth() + 1)
        }
      }
    }

    setGeneratedDates(generatedDates)
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
          getAllPayments()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Add Payment</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '70px'}}>
              <img src={plusWhite} height='80px' width='80px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        { tnancy == null && (
        <div
          className='card p-5 py-3'
          style={{
            boxShadow: '0px 0px 20px 0px #516176',
            transform: 'translate(0px, -93px)',
            zIndex: '1',
            borderRadius: '0px',
          }}
        >
          {(isSelected ) && (
            <Row className='mx-1 my-2'>
              <Col md={8} className='pe-5 pt-1'>
                <h3 className='head-text' style={{fontWeight: '700'}}>
                  Tenancy
                </h3>
              </Col>
              <Col md={4}>
                <div className='d-flex '>
                  <button
                    className='btn btn-sm ms-auto'
                    style={{height: '25px', width: '20px', backgroundColor: '#6e8aa6'}}
                  >
                    <img
                      src={collapse ? up : down}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginLeft: '-10px',
                        marginTop: '-10px',
                      }}
                      onClick={() => setcollapse(!collapse)}
                      aria-controls='example-collapse-text'
                      aria-expanded={collapse}
                    />
                  </button>
                </div>
              </Col>
            </Row>
          )}
          <Collapse in={collapse}>
            <div id='example-collapse-text' className='px-lg-1'>
              {totalTenancies >= 8 && (
                <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 mt-5 ms-3'>
                  <div className='d-flex align-items-center gap-5'>
                    <input
                      type='text'
                      className='form-control form-control-bg input-style'
                      placeholder='Search Contract Id'
                      name='item_id'
                      onChange={(e) => {
                        applyFilter(e)
                      }}
                    />
                  </div>
                </div>
              )}

              {showFiltered ? (
                <>
                  {filteredData?.length > 0 && (
                    <div className='d-flex mt-3'>
                      <h4 className='head-text mt-3 ms-3' style={{fontWeight: '700'}}>
                        <i>Which Tenancy do you wish to charge this payment to?</i>
                      </h4>
                      <button
                        className='btn btn-sm fw-bold ms-auto ps-2 text-white me-3'
                        style={{
                          fontSize: '1.2rem',
                          paddingRight: '88px',
                          borderRadius: '2px',
                          backgroundColor: '#35475e',
                          boxShadow: '0px 0px 20px 0px #516176',
                        }}
                        onClick={(event) => {
                          event.preventDefault()
                          setIsSelected(true)
                          setcollapse(!collapse)
                          // submitIncomingPayment()
                        }}
                        disabled={disableSelect ? true : false}
                      >
                        <img
                          src={homeImg}
                          style={{
                            height: '20px',
                            width: '20px',
                            marginRight: '32px',
                            marginLeft: '4px',
                            marginTop: '-3px',
                          }}
                        />{' '}
                        {'  '}
                        Select
                      </button>
                    </div>
                  )}
                  <div style={{maxHeight: '140px', overflowY: 'scroll'}}>
                    {filteredData?.length > 0 &&
                      filteredData.map((ut: any, index: any) => {
                        return (
                          <>
                            <div className='d-flex my-3 ms-5'>
                              {radioChecked === index ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#384a62',
                                    borderRadius: '50%',
                                  }}
                                  className='mx-5'
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #384a62',
                                  }}
                                  onClick={() => {
                                    setRadioChecked(index)
                                    setTenancyId(ut._id)
                                    setTenantId(ut.tenantId)
                                    setDisableSelect(false)
                                    getTenantDetails(ut.tenantId)
                                  }}
                                  className='mx-5'
                                ></div>
                              )}
                              {/* <input
                                      className='form-check-input me-5'
                                      type='radio'
                                      // checked={selectedRadio === scg._id}
                                      // onChange={(e: any) => selectSchedule(e, i)}
                                    /> */}
                              <h6 className='m-0 p-0 head-text mx-5' style={{fontWeight: '600'}}>
                                {ut?.contractNo}
                              </h6>
                              <h6 className='m-0 p-0 head-text mx-5' style={{fontWeight: '600'}}>
                                {moment(ut?.duration?.start_date).format('DD.MM.YYYY')} -{' '}
                                {moment(ut?.duration?.end_date).format('DD.MM.YYYY')}
                              </h6>
                              <h6 className='m-0 p-0 head-text ms-5' style={{fontWeight: '600'}}>
                                {ut?.tenancyStatus === 0
                                  ? 'Booked'
                                  : ut?.tenancyStatus === 1
                                  ? 'Active'
                                  : ut?.tenancyStatus === 2
                                  ? 'Expiring'
                                  : ut?.tenancyStatus === 3
                                  ? 'Ended'
                                  : ut?.tenancyStatus === 4
                                  ? 'Renewed'
                                  : ut?.tenancyStatus === 5
                                  ? 'Terminated'
                                  : 'Pending Tenant Approval'}
                              </h6>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </>
              ) : (
                <>
                  {unitsTenancy.length > 0 && (
                    <div className='d-flex mt-3'>
                      <h4 className='head-text mt-3 ms-3' style={{fontWeight: '700'}}>
                        <i>Which Tenancy do you wish to charge this payment to?</i>
                      </h4>
                      <button
                        className='btn btn-sm fw-bold ms-auto ps-2 text-white me-3'
                        style={{
                          fontSize: '1.2rem',
                          paddingRight: '88px',
                          borderRadius: '2px',
                          backgroundColor: '#35475e',
                          boxShadow: '0px 0px 20px 0px #516176',
                        }}
                        onClick={(event) => {
                          event.preventDefault()
                          setIsSelected(true)
                          setcollapse(!collapse)
                        }}
                        disabled={disableSelect ? true : false}
                      >
                        <img
                          src={homeImg}
                          style={{
                            height: '20px',
                            width: '20px',
                            marginRight: '32px',
                            marginLeft: '4px',
                            marginTop: '-3px',
                          }}
                        />{' '}
                        {'  '}
                        Select
                      </button>
                    </div>
                  )}
                  <div style={{maxHeight: '140px', overflowY: 'scroll'}}>
                    {unitsTenancy.length > 0 &&
                      unitsTenancy.map((ut: any, index: any) => {
                        return (
                          <>
                            <div
                              className='d-flex my-3 ms-5'
                              style={{maxHeight: '140px', overflowY: 'scroll'}}
                            >
                              {radioChecked === index ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#384a62',
                                    borderRadius: '50%',
                                  }}
                                  className='mx-5'
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #384a62',
                                  }}
                                  onClick={() => {
                                    setRadioChecked(index)
                                    setTenancyId(ut._id)
                                    setTenantId(ut.tenantId)
                                    setDisableSelect(false)
                                    getTenantDetails(ut.tenantId)
                                  }}
                                  className='mx-5'
                                ></div>
                              )}
                              {/* <input
                                  className='form-check-input me-5'
                                  type='radio'
                                  // checked={selectedRadio === scg._id}
                                  // onChange={(e: any) => selectSchedule(e, i)}
                                /> */}
                              <h6 className='m-0 p-0 head-text mx-5' style={{fontWeight: '600'}}>
                                {ut?.contractNo}
                              </h6>
                              <h6 className='m-0 p-0 head-text mx-5' style={{fontWeight: '600'}}>
                                {moment(ut?.duration?.start_date).format('DD.MM.YYYY')} -{' '}
                                {moment(ut?.duration?.end_date).format('DD.MM.YYYY')}
                              </h6>
                              <h6 className='m-0 p-0 head-text ms-5' style={{fontWeight: '600'}}>
                                {ut?.tenancyStatus === 0
                                  ? 'Booked'
                                  : ut?.tenancyStatus === 1
                                  ? 'Active'
                                  : ut?.tenancyStatus === 2
                                  ? 'Expiring'
                                  : ut?.tenancyStatus === 3
                                  ? 'Ended'
                                  : ut?.tenancyStatus === 4
                                  ? 'Renewed'
                                  : ut?.tenancyStatus === 5
                                  ? 'Terminated'
                                  : 'Pending Tenant Approval'}
                              </h6>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </>
              )}
            </div>
          </Collapse>
        </div>
        )} 
        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{marginBottom: '-86px', maxHeight: '600px'}}
        >
          <Row
            className=''
            style={
              isSelected === false
                ? {opacity: '0.3', transform: 'translate(0px, -87px)'}
                : {opacity: '1', transform: 'translate(0px, -87px)'}
            }
          >
            {/* left column: items details */}
            <Col
              lg={7}
              md={7}
              className='align-items-center pt-5  ps-lg-10 pe-5'
              style={{maxHeight: '500px', overflow: 'scroll'}}
            >
              {fields &&
                fields.map((field: any, index: number) => {
                  return (
                    <>
                      {field?.isLateItem ? (
                        <>
                          <div
                            style={
                              index > 0
                                ? {borderTop: '0px', paddingTop: '10px'}
                                : {borderTop: '0px'}
                            }
                            className='mb-5'
                          >
                            {/* Payment Item Detail */}
                            <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                              <h3 className='head-text' style={{fontWeight: '700'}}>
                                Item Details
                              </h3>
                              <div className='d-flex justify-content-center align-items-center ms-auto me-5 pe-5'>
                                <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                  {/* {index === 0 ? `${id}_${subId}` : `${id}_${field.id}`} */}
                                </h6>
                              </div>
                            </div>

                            <div className='align-items-center d-flex mb-4 ps-3'>
                              <label
                                className='mb-1 me-5 pe-5 d-flex grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Item
                              </label>
                              <div style={{width: '-webkit-fill-available'}}>
                                <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                  {field?.item}
                                </p>
                              </div>
                            </div>

                            <div className='align-items-center d-flex mb-4 ps-3'>
                              <label
                                className='mb-1 me-5 pe-5 d-flex grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Amount
                              </label>
                              <div style={{width: '-webkit-fill-available'}}>
                                <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                  {field?.amount != 0
                                    ? (field?.amount / field?.qty)?.toFixed(2)
                                    : 0}
                                </p>
                              </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                              <div className='d-flex align-items-center justify-content-center'>
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  QTY
                                </label>
                                <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                  {field?.qty}
                                </p>
                              </div>

                              <div className='d-flex align-items-center justify-content-center ps-3 ms-auto me-5 pe-5'>
                                <label
                                  className='mb-1 me-5 ps-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  VAT
                                </label>
                                <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                  {field?.vat}%
                                </p>
                              </div>
                            </div>

                            <div
                              className='d-flex align-items-center mb-4 ps-3 '
                              style={{color: '#384a62', fontWeight: '600'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Total
                              </label>
                              <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                {' '}
                                {field?.total?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={
                              index > 0
                                ? {borderTop: '1px solid lightgray', paddingTop: '10px'}
                                : {borderTop: '0px'}
                            }
                            className='mb-5'
                          >
                            <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                              <h3 className='head-text' style={{fontWeight: '700'}}>
                                Item Details
                              </h3>
                              <div className='d-flex justify-content-center align-items-center ms-auto'>
                                <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                  {/* {index === 0 ? `${id}_${subId}` : `${id}_${field.id}`} */}
                                </h6>
                                <img
                                  src={bin}
                                  alt=''
                                  height='18px'
                                  width='18px'
                                  className='ms-5'
                                  style={{cursor: 'pointer'}}
                                  onClick={() =>
                                    Swal.fire({
                                      html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the item, do you wish to proceed?</p>',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#007a59',
                                      confirmButtonText: 'Confirm',
                                      showCancelButton: true,
                                      cancelButtonText: 'Cancel',
                                    }).then((res) => {
                                      if (res.isConfirmed) {
                                        handleRemoveFields(index, field)
                                      }
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {/* Item Name */}
                            <div className='align-items-center d-flex mb-4 ps-3'>
                              <label
                                className='mb-1 me-5 pe-5 d-flex grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Item
                              </label>
                              <div style={{width: '-webkit-fill-available'}} className='d-flex'>
                                {paymentItems &&
                                  paymentItems.map((pI: any, index: any) => {
                                    if (field?.item == pI.name) itemInd = index
                                    return <></>
                                  })}

                                {field?.item?.length > 0 ? (
                                  <Select
                                    options={
                                      filteredPaymentItems &&
                                      filteredPaymentItems?.map((pItem: any, index: any) => {
                                        return {
                                          label: `${pItem?.name}`,
                                          value: `${pItem?._id}`,
                                          id: pItem,
                                        }
                                      })
                                    }
                                    defaultValue={{
                                      label: filteredPaymentItems[itemInd]?.name,
                                      value: filteredPaymentItems[itemInd]?._id,
                                      id: filteredPaymentItems[itemInd],
                                    }}
                                    value={{
                                      label: filteredPaymentItems[itemInd]?.name,
                                      value: filteredPaymentItems[itemInd]?._id,
                                      id: filteredPaymentItems[itemInd],
                                    }}
                                    styles={customStylesInputFilled}
                                    isSearchable={false}
                                    // defaultValue={paymentItems[itemInd]}
                                    onChange={(event: any) => {
                                      const values = [...fields]
                                      if (event.label == 'Maintenance') {
                                        setShowLink(true)
                                        setIndForMaintenance(index)
                                      } else {
                                        values[index].serviceRequestId = null
                                        values[index].requestId = null
                                        values[index].serviceJobId = null
                                        values[index].jobId = null
                                      }

                                      values[index].item = event.label
                                      values[index].lateDetails = event.id
                                      if (values[index].activeLATE == true) {
                                        if (new Date(dueDate) < new Date())
                                          changeFields(field, index)
                                      }

                                      if (invoiceAdded) setYellowInvoice(true)

                                      setReceiptAdded(false)
                                      for (let i = 0; i < values?.length; i++) {
                                        values[i].amountPaid = 0
                                      }

                                      setAmountPaid(0)
                                      setDatePaid(null)

                                      setFields(values)

                                      // const temp: any = []
                                      // let k: any = 0

                                      // for (let i = 0; i < paymentItems?.length; i++) {
                                      //   let isTrue = true
                                      //   let ind: any = ''
                                      //   for (let j = 0; j < values?.length; j++) {
                                      //     if (paymentItems[i].name == values[j].item) {
                                      //       isTrue = false
                                      //       ind = i
                                      //     }
                                      //   }
                                      //   if (isTrue) {
                                      //     temp[k] = paymentItems[i]
                                      //     k++
                                      //   }
                                      // }

                                      // setFilteredPaymentItems(temp)
                                    }}
                                    isDisabled={isSelected ? false : true}
                                  />
                                ) : (
                                  <Select
                                    options={
                                      filteredPaymentItems &&
                                      filteredPaymentItems?.map((pItem: any, index: any) => {
                                        return {
                                          label: `${pItem?.name}`,
                                          value: `${pItem?._id}`,
                                          id: pItem,
                                        }
                                      })
                                    }
                                    styles={customStyles}
                                    isSearchable={false}
                                    defaultValue={field.item}
                                    onChange={(event) => {
                                      const values = [...fields]

                                      // console.log(event)
                                      if (event.label == 'Maintenance') {
                                        setShowLink(true)
                                        setIndForMaintenance(index)
                                      } else {
                                        values[index].serviceRequestId = null
                                        values[index].requestId = null
                                        values[index].serviceJobId = null
                                        values[index].jobId = null
                                      }

                                      values[index].item = event.label
                                      values[index].lateDetails = event.id
                                      if (values[index].activeLATE == true) {
                                        if (new Date(dueDate) < new Date())
                                          changeFields(field, index)
                                      }

                                      if (invoiceAdded) setYellowInvoice(true)

                                      setReceiptAdded(false)
                                      for (let i = 0; i < values?.length; i++) {
                                        values[i].amountPaid = 0
                                      }

                                      setAmountPaid(0)
                                      setDatePaid(null)

                                      setFields(values)

                                      // const temp: any = []
                                      // let k: any = 0

                                      // for (let i = 0; i < paymentItems?.length; i++) {
                                      //   let isTrue = true
                                      //   let ind: any = ''
                                      //   for (let j = 0; j < values?.length; j++) {
                                      //     if (paymentItems[i].name == values[j].item) {
                                      //       isTrue = false
                                      //       j = values?.length
                                      //     }
                                      //   }
                                      //   if (isTrue) {
                                      //     temp[k] = paymentItems[i]
                                      //     k++
                                      //   }
                                      // }
                                      // setFilteredPaymentItems(temp)
                                    }}
                                    isDisabled={isSelected ? false : true}
                                  />
                                )}

                                {showLink && field?.item == 'Maintenance' && (
                                  <>
                                    {field?.serviceJobId != null ||
                                    field?.serviceRequestId != null ? (
                                      <button
                                        className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 py-0'
                                        style={{
                                          fontSize: '0.7rem',
                                          paddingRight: '15px',
                                          borderRadius: '2px',
                                          backgroundColor: '#35475e',
                                          boxShadow: '0px 0px 20px 0px #516176',
                                        }}
                                        onClick={(event) => {
                                          event.preventDefault()
                                          setIndForMaintenance(index)
                                          setTimeout(()=>{getServiceJobForPayment()}, 1000)
                                        }}
                                        disabled={isSelected ? false : true}
                                      >
                                        <img
                                          src={linkImg}
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            marginRight: '5px',
                                            marginLeft: '5px',
                                            marginTop: '-3px',
                                          }}
                                        />
                                         {field?.jobId}
                                        {'_'}
                                        {field?.requestId}
                                      </button>
                                    ) : (
                                      <button
                                        className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 '
                                        style={{
                                          fontSize: '1rem',
                                          paddingRight: '15px',
                                          borderRadius: '2px',
                                          backgroundColor: '#35475e',
                                          boxShadow: '0px 0px 20px 0px #516176',
                                        }}
                                        onClick={(event) => {
                                          event.preventDefault()
                                          setIndForMaintenance(index)
                                          setTimeout(()=>{getServiceJobForPayment()}, 1000)
                                        }}
                                        disabled={isSelected ? false : true}
                                      >
                                        <img
                                          src={linkImg}
                                          style={{
                                            height: '20px',
                                            width: '20px',
                                            marginRight: '12px',
                                            marginLeft: '4px',
                                            marginTop: '-3px',
                                          }}
                                        />{' '}
                                        {'  '}
                                        Link to Request
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className='d-flex align-items-center mb-4 ps-3'>
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Amount
                              </label>

                              <input
                                type='number'
                                className='form-control form-control-solid-bg mytest'
                                placeholder='Enter Amount'
                                name='item_id'
                                value={field.amount}
                                onChange={(event) => {
                                  const values = [...fields]
                                  values[index].amount = event.target.value

                                  let y: any =
                                    field.amount * field.qty +
                                    ((field.amount * field.qty) / 100) * field.vat
                                  values[index].total = y
                                  values[index].outstanding = y
                                  setFields(values)
                                }}
                                disabled={isSelected ? false : true}
                                style={{
                                  // border: '0.1rem solid #bec3cb',
                                  borderRadius: '2px',
                                  padding: '0.55rem 1rem',
                                  width: '38.8%',
                                }}
                                onBlur={(event: any) => {
                                  const values = [...fields]
                                  if (event.target.value != '') {
                                    if (values[index].activeLATE == true) {
                                      if (new Date(dueDate) < new Date()) changeFields(field, index)
                                    }
                                  }

                                  if (invoiceAdded) setYellowInvoice(true)

                                  setReceiptAdded(false)
                                  for (let i = 0; i < values?.length; i++) {
                                    values[i].amountPaid = 0
                                  }

                                  setAmountPaid(0)
                                  setDatePaid(null)
                                  setFields(values)
                                }}
                              />
                            </div>

                            <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                              <div className='d-flex align-items-center justify-content-center'>
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  QTY
                                </label>

                                <input
                                  type='number'
                                  className='form-control form-control-solid-bg mytest'
                                  placeholder='Enter Qty'
                                  name='item_id'
                                  value={field.qty}
                                  onChange={(event) => {
                                    const values = [...fields]
                                    values[index].qty = event.target.value

                                    let y: any =
                                      field.amount * field.qty +
                                      ((field.amount * field.qty) / 100) * field.vat
                                    values[index].total = y
                                    values[index].outstanding = y

                                    if (invoiceAdded) setYellowInvoice(true)

                                    setReceiptAdded(false)
                                    for (let i = 0; i < values?.length; i++) {
                                      values[i].amountPaid = 0
                                    }

                                    setAmountPaid(0)
                                    setDatePaid(null)

                                    setFields(values)
                                  }}
                                  disabled={isSelected ? false : true}
                                  style={{
                                    // border: '0.1rem solid #bec3cb',
                                    borderRadius: '2px',
                                    padding: '0.55rem 1rem ',
                                  }}
                                />
                              </div>

                              <div className='d-flex align-items-center justify-content-center ps-3'>
                                <label
                                  className='mb-1 me-5 ps-5 grey-label'
                                  style={{minWidth: '60px'}}
                                >
                                  VAT %
                                </label>

                                <input
                                  type='number'
                                  className='form-control form-control-solid-bg mytest '
                                  placeholder='VAT %'
                                  name='item_id'
                                  value={field.vat}
                                  onChange={(event) => {
                                    const values = [...fields]
                                    values[index].vat = event.target.value
                                    setFields(values)
                                    let y: any =
                                      field.amount * field.qty +
                                      ((field.amount * field.qty) / 100) * field.vat
                                    values[index].total = y
                                    values[index].outstanding = y

                                    if (invoiceAdded) setYellowInvoice(true)

                                    setReceiptAdded(false)
                                    for (let i = 0; i < values?.length; i++) {
                                      values[i].amountPaid = 0
                                    }

                                    setAmountPaid(0)
                                    setDatePaid(null)

                                    setFields(values)
                                  }}
                                  disabled={isSelected ? false : true}
                                  style={{
                                    // border: '0.1rem solid #bec3cb',
                                    borderRadius: '2px',
                                    padding: '0.55rem 1rem',
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              className='d-flex align-items-center mb-4 ps-3 '
                              style={{color: '#384a62', fontWeight: '600'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Total
                              </label>
                              {field.lateApplied === false
                                ? (
                                    field.amount * field.qty +
                                    ((field.amount * field.qty) / 100) * field.vat
                                  )?.toFixed(2)
                                : (
                                    field.amount * field.qty +
                                    ((field.amount * field.qty) / 100) * field.vat +
                                    field?.lateFeeTotal
                                  )?.toFixed(2)}
                            </div>

                            <div
                              className='d-flex align-items-center mb-4 ps-3 mt-2'
                              style={{color: '#384a62'}}
                            >
                              <label className='mb-1 me-5 grey-label pe-5'>Late Payment Fees</label>

                              {/* Late Details */}
                              {field?.lateDetails?.latePaymentIsActive === true ? (
                                <>
                                  {field.lateApplied == true && (
                                    <span className='me-5 head-text'>
                                      {field?.lateFeeTotal?.toFixed(2)}
                                    </span>
                                  )}
                                  <div className='d-flex head-text'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                      {field?.activeLATE === true ? (
                                        <div
                                          style={{
                                            height: '18px', 
                                            width: '18px',
                                            backgroundColor: '#384a62',
                                            borderRadius: '50%',
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          style={{
                                            height: '18px',
                                            width: '18px',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                            border: '1px solid #384a62',
                                          }}
                                          onClick={() => {
                                            if (dueDate === null || dueDate === '')
                                              ErrorToast('Please first select the Due Date!!')
                                            else {
                                              const values = [...fields]
                                              values[index].lateApplied = true
                                              values[index].activeLATE = true
                                              values[index].InactiveLATE = false
                                              // values[index].lateFeeTotal = x

                                              if (values[index].lateOnce === false) {
                                                values[index].lateOnce = true
                                                // values[index].total = values[index].total + x
                                              }

                                              if (invoiceAdded) setYellowInvoice(true)

                                              setReceiptAdded(false)
                                              for (let i = 0; i < values?.length; i++) {
                                                values[i].amountPaid = 0
                                              }

                                              setAmountPaid(0)
                                              setDatePaid(null)
                                              setFields(values)

                                              if (new Date(dueDate) < new Date()) {
                                                getLatePaymentItem(field, index)
                                              }
                                            }
                                          }}
                                        ></div>
                                      )}

                                      <label
                                        className='form-check-label'
                                        htmlFor='requestUrgency'
                                        style={{marginRight: '25px'}}
                                      >
                                        Apply
                                      </label>
                                    </div>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                      {field?.InactiveLATE == true ? (
                                        <div
                                          style={{
                                            height: '18px',
                                            width: '18px',
                                            backgroundColor: '#384a62',
                                            borderRadius: '50%',
                                          }}
                                        ></div>
                                      ) : (
                                        <div
                                          style={{
                                            height: '18px',
                                            width: '18px',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                            border: '1px solid #384a62',
                                          }}
                                          onClick={() => {
                                            const values = [...fields]

                                            let lt: any

                                            if (values[index].activeLATE == true) {
                                              if (values[index].lateItemIndex != '') {
                                                lt = values[index].lateItemIndex
                                                if (values[index].lateItemIndex != -1)
                                                  values.splice(values[index]?.lateItemIndex, 1)
                                              }
                                              values[index].lateItemIndex = null
                                            }

                                            if (lt != -1) {
                                              for (let i = index; i < values?.length; i++) {
                                                if (values[i]?.isLateItem == false) {
                                                  if (values[i].activeLATE == true) {
                                                    if (values[i]?.lateItemIndex != null) {
                                                      console.log(values[i]?.lateItemIndex)
                                                      if (values[i]?.lateItemIndex != -1)
                                                        values[i].lateItemIndex =
                                                          values[i]?.lateItemIndex - 1
                                                    }
                                                  }
                                                }
                                              }
                                            }

                                            values[index].lateApplied = false
                                            values[index].activeLATE = false
                                            values[index].InactiveLATE = true

                                            if (values[index].lateOnce === true) {
                                              values[index].lateOnce = false
                                              // values[index].total = values[index].total - x
                                            }

                                            if (invoiceAdded) setYellowInvoice(true)
                                            setReceiptAdded(false)
                                            for (let i = 0; i < values?.length; i++) {
                                              values[i].amountPaid = 0
                                            }

                                            setAmountPaid(0)
                                            setDatePaid(null)

                                            setFields(values)
                                          }}
                                        ></div>
                                      )}
                                      <label
                                        className='form-check-label'
                                        htmlFor='Internally'
                                        style={{marginRight: '6px'}}
                                      >
                                        Do not Apply
                                      </label>
                                    </div>
                                  </div>
                                </>
                              ) : field?.lateDetails?.latePaymentIsActive === false ? (
                                <p className='head-text mb-0'>
                                  <i>Inactive</i>
                                </p>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          {/* // Non Editable late fee items */}
                          {/* {field.lateApplied == true && (
                        <div
                          style={
                            index > 0
                              ? {borderTop: '1px solid lightgray', paddingTop: '10px'}
                              : {borderTop: '0px'}
                          }
                          className='mb-5'
                        >
                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                            <h3 className='head-text' style={{fontWeight: '700'}}>
                              Item Details
                            </h3>
                            <div className='d-flex justify-content-center align-items-center ms-auto'>
                              <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                {index === 0 ? `${id}_${subId}_01` : `${id}_${field.id}_01`}
                              </h6>
                            </div>
                          </div>
                          <p></p>

                          <div className='align-items-center d-flex mb-4 ps-3'>
                            <label
                              className='mb-1 me-5 pe-5 d-flex grey-label'
                              style={{minWidth: '90px'}}
                            >
                              Item
                            </label>
                            <p className='head-text mb-0' style={{width: '50%'}}>
                              Late Payment Fee on{' '}
                              <b> {index === 0 ? `${id}_${subId}` : `${field.id}`}</b>
                            </p>
                          </div>

                          <div className='d-flex align-items-center mb-4 ps-3 head-text'>
                            <div className='d-flex align-items-center  w-50'>
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Amount
                              </label>

                              {field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 0
                                ? field?.lateDetails?.late_payment_item_charge?.[0]?.fixedAmount
                                : `${getLateFeePercentValue(field)} (${
                                    field?.lateDetails?.late_payment_item_charge?.[0]
                                      ?.percentageAmount
                                  }%)`}
                            </div>
                            <div className='d-flex align-items-center  w-50'>
                              <label
                                className='mb-1 me-5 ps-3 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                QTY
                              </label>

                              {field.qty}
                            </div>
                          </div>

                          <div className='d-flex align-items-center  mb-4 ps-3 head-text'>
                            <div className='d-flex align-items-center pe-3 w-50'>
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '50px', maxWidth: '100px'}}
                              >
                                Days After Due Date
                              </label>
                              {
                                field?.lateDetails?.late_payment_item_charge?.[0]
                                  ?.dayAfterPaymentDue
                              }
                            </div>

                            <div className='d-flex align-items-center ps-3 w-50'>
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Frequency
                              </label>

                              {field?.lateDetails?.late_payment_item_charge?.[0]?.frequency === 0
                                ? 'Once'
                                : field?.lateDetails?.late_payment_item_charge?.[0]?.frequency === 1
                                ? 'Daily'
                                : field?.lateDetails?.late_payment_item_charge?.[0]?.frequency === 2
                                ? 'Weekly'
                                : 'Monthly'}
                            </div>
                          </div>

                          <div className='d-flex align-items-center  mb-4 ps-3 head-text'>
                            <div className='d-flex align-items-center pe-3 w-50'>
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '100px'}}
                              >
                                VAT
                              </label>
                              {field?.lateDetails?.late_payment_item_charge?.[0]?.VATPercentage} {'%'}
                            </div>

                            <div
                              className='d-flex align-items-center mb-4 ps-3 head-text w-50'
                              style={{color: '#384a62'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5 grey-label'
                                style={{minWidth: '90px'}}
                              >
                                Total
                              </label>
                              {field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 0
                                ? getLateFee(field)
                                : getLateFeePercent(field)}
                              
                              <span>
                                {field?.lateDetails?.late_payment_item_charge?.[0]?.frequency === 1
                                  ? '  /day'
                                  : field?.lateDetails?.late_payment_item_charge?.[0]?.frequency ===
                                    2
                                  ? '  /week'
                                  : field?.lateDetails?.late_payment_item_charge?.[0]?.frequency ===
                                    3
                                  ? '  /month'
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                      )} */}
                        </>
                      )}
                    </>
                  )
                })}

              <div
                className='d-flex align-items-center justify-content-center my-2 mx-auto'
                onClick={() => handleAddFields()}
                style={{width: 'fit-content'}}
              >
                <img
                  src={plusBlue}
                  style={{
                    height: '28px',
                    width: '28px',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className='align-items-center justify-content-center my-2 text-center'>
                <p className='grey-label mb-2'>
                  {' '}
                  <i>Add another payment item</i>
                </p>
                <p
                  className='grey-label mx-auto'
                  style={{borderBottom: '2px dashed #a0a0b4', width: '60%'}}
                ></p>
              </div>
            </Col>

            {/* Right Column */}
            <Col
              lg={5}
              md={5}
              className='pt-5 px-5'
              // style={{backgroundColor: '#f2f2f2', maxHeight: '400px', overflow: 'scroll'}}
              style={{maxHeight: '500px', overflow: 'scroll', backgroundColor: '#f2f2f2'}}
            >
              <div className='align-items-end d-flex pt-1 pb-2'>
                <div className='d-flex justify-content-center align-items-center ms-auto'>
                  <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                    {id}
                  </h6>
                  <img
                    src={bin}
                    alt=''
                    height='28px'
                    width='28px'
                    className='ms-4'
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the payment record, do you wish to proceed?</p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#007a59',
                        confirmButtonText: 'Confirm',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                      }).then((res: any) => {
                        if (res.isConfirmed) {
                          handleClose()
                        }
                      })
                    }
                  />
                </div>
              </div>

              {fields.map((f: any, ind: any) => {
                totalAED = totalAED + f?.total
                return <></>
              })}
              <h2
                className='head-text'
                style={{fontWeight: '800', textDecoration: 'underline'}}
                ref={nameRef}
              >
                AED {parseFloat(totalAED)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
              </h2>

              <div className='d-flex align-items-center justify-content-center mb-3'>
                <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '90px'}}>
                  Name
                </label>

                <input
                  type='text'
                  className={
                    paymentNameError
                      ? 'form-control form-control-bg input-style border-danger'
                      : 'form-control form-control-bg input-style mytest'
                  }
                  placeholder='Enter Payment Name'
                  name='item_id'
                  value={paymentName}
                  onChange={(event) => {
                    setPaymentName(event?.target.value)
                    setPaymentNameError(false)
                  }}
                  disabled={isSelected ? false : true}
                />
              </div>
              <div className='d-flex align-items-center mb-3 head-text' style={{fontWeight: '700'}}>
                <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '96px'}}>
                  Status
                </label>
                {paidAmount <= 0 || paidAmount === ''
                  ? 'Unpaid'
                  : paidAmount < totalAED && paidAmount > 0
                  ? 'Partially Paid'
                  : paidAmount >= totalAED
                  ? 'Paid'
                  : ''}
              </div>

              <div className='d-flex align-items-center mb-3 head-text' style={{fontWeight: '700'}}>
                <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '96px'}}>
                  Category
                </label>
                {/* {getStatus()} */}
                {paymentCategory == 0 ? 'Upcoming' : paymentCategory == 1 ? 'Overdue' : '-'}
              </div>

              <div
                className='d-flex align-items-center  mb-2 head-text'
                style={{fontWeight: '700'}}
                ref={amountPaidRef}
              >
                <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '100px'}}>
                  Amount Paid
                </label>

                {paidAmount}

                {/* <input
                  type='number'
                  className={
                    amountPaidError
                      ? 'form-control form-control-bg input-style border-danger'
                      : 'form-control form-control-bg input-style mytest'
                  }
                  placeholder='Enter Amount'
                  name='item_id'
                  min={0}
                  max={totalAED}
                  value={paidAmount}
                  onChange={(event) => {
                    setAmountPaid(event?.target.value)
                    setAmountPaidError(false)
                  }}
                  onBlur={(event) => {
                    if (paidAmount > totalAED) {
                      ErrorToast(`Paid Amount should not exceed the Total Amount..`)
                    }
                    if (receiptAdded) {
                      setReceiptAdded(false)
                    }
                  }}
                  disabled={isSelected ? false : true}
                /> */}
              </div>

              <div
                className='d-flex align-items-center mb-3 head-text'
                style={{fontWeight: '700'}}
                ref={datePaidRef}
              >
                <label
                  className='mb-1 me-5 pe-5 blue-label'
                  // className={
                  //   paidAmount == 0 || paidAmount == ''
                  //     ? 'mb-1 me-5 pe-5 disable-label'
                  //     : 'mb-1 me-5 pe-5 blue-label'
                  // }
                  style={{minWidth: '100px'}}
                >
                  Date Paid
                </label>

                {datePaid ? moment(datePaid).format('DD.MM.YYYY') : '-'}
                {/* <div style={{width: '-webkit-fill-available'}}>
                  <DatePicker
                    onChange={handleDatePaidChange}
                    // disabled={paidAmount == 0 || paidAmount == '' ? true : false}
                    className={datePaid != null ? 'mytest' : datePaidError ? 'border-danger' : ''}
                    disabled
                  />
                </div> */}
              </div>

              <div
                className='d-flex align-items-center  mb-3  blue-label'
                style={{fontWeight: '700'}}
              >
                <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '100px'}}>
                  Outstanding Amount
                </label>
                {/* {(totalAED - paidAmount)?.toLocaleString('en-US', {minimumFractionDigits: 2})} */}
                {totalAED - paidAmount > 0
                  ? getNegative(totalAED - paidAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })
                  : getPositive(totalAED - paidAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
              </div>

              {/* Due Date */}
              <div
                className='d-flex align-items-center justify-content-center mb-3 pb-5'
                style={{borderBottom: '2px dashed lightgray'}}
                ref={dueDateRef}
              >
                <label
                  className={
                    paidAmount == totalAED
                      ? 'mb-1 me-5 pe-5 disable-label'
                      : 'mb-1 me-5 pe-5 blue-label'
                  }
                  style={{minWidth: '90px'}}
                >
                  Due
                </label>
                <div style={{width: '-webkit-fill-available'}}>
                  <DatePicker
                    onChange={handleDueDateChange}
                    disabled={paidAmount == totalAED ? true : false}
                    className={dueDate != null ? 'mytest' : dueDateError ? 'border-danger' : ''}
                  />
                </div>
              </div>

              {/* Payment Method */}
              <div className='align-items-center d-flex mb-3'>
                <label
                  className='mb-1 me-3 pe-5 d-flex head-text fs-4'
                  style={{minWidth: '150px', fontWeight: '700'}}
                >
                  Payment Method
                </label>
                <div style={{width: '-webkit-fill-available'}}>
                  <Select
                    options={cardItems}
                    styles={customStylesInputFilled2}
                    isSearchable={false}
                    defaultValue={
                      paymentMethod == 0
                        ? cardItems[0]
                        : paymentMethod == 1
                        ? cardItems[1]
                        : paymentMethod == 2
                        ? cardItems[2]
                        : cardItems[3]
                    }
                    onChange={(event: any) => {
                      setPaymentMethod(event.value)
                      if (event.value == 2) getBankAccounts()
                      else {
                        setShowAcc(false)
                        setSelectedAccount(null)
                      }
                    }}
                    isDisabled={isSelected ? false : true}
                  />
                </div>
              </div>

              {paymentMethod == 0 &&(
                <div className='d-flex align-items-center mt-1 justify-content-start mb-3'>
                   <i className='me-auto me-1 text-dark'>Would you like to enable card verification ?</i>
                <Switch
                  onChange={(e) => {
                    setCheckVerify(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkVerify}
                  onColor='#35475e'
                  offColor='#b0bec5'
                />{' '}
               
              </div>
              )}

              {/* Payment Method: Bank */}
              {paymentMethod == 2 ? (
                <>
                  <div className='align-items-center d-flex mb-3'>
                    <label className='mb-1 me-2 pe-5 d-flex head-text' style={{minWidth: '100px'}}>
                      Bank Account
                    </label>
                    <div style={{width: '-webkit-fill-available'}}>
                      {selectedAccount != null ? (
                        <Select
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAcc(true)
                            setSelectedAccount(event.id)
                          }}
                        />
                      ) : (
                        <Select
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStyles2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAcc(true)
                            setSelectedAccount(event.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {showAcc && (
                    <>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account Name
                        </label>
                        {selectedAccount?.accountHolderName}
                      </div>
                      <div
                        className='d-flex align-items-center my-2 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          IBAN
                        </label>
                        {selectedAccount?.IBAN}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account No.
                        </label>
                        {selectedAccount?.accountNumber}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Bank Name
                        </label>
                        {selectedAccount?.bankName}
                      </div>
                    </>
                  )}
                </>
              ) : paymentMethod == 1 ? (
                <>
                  <div className='d-flex align-items-center justify-content-center mb-2'>
                    <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '90px'}}>
                      Bank Name
                    </label>

                    <input
                      type='text'
                      className='form-control form-control-solid-bg input-style mytest'
                      placeholder='Enter Name'
                      name='item_id'
                      value={chequeBankName}
                      onChange={(event) => {
                        setChequeBankName(event?.target.value)
                      }}
                    />
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                  <form
                      id='form-file-upload'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px'}}
                    >
                      <label
                        id='label-file-upload'
                        htmlFor='input-file-upload'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          // transform: 'translate(-2px, -90px)',
                        }}
                        ref={inputRef}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        type='file'
                        multiple={true} 
                        onChange={handleChange}
                        id='input-file-upload'
                        ref={inputRef}
                        style={{
                          height: '90px',
                          opacity: '-0.9',
                          position: 'relative',
                          transform: 'translate(-2px, -90px)',
                        }}
                      />
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}}
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex '>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                              <div className='h-0'>
                                <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-25px', marginLeft: '5px'}}
                                  onClick={() => deleteCheque(chq)}
                                />
                              </div>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-solid-bg input-style mt-1'
                              placeholder='Cheque No'
                              name='item_id'
                              value={chq.name}
                              onChange={(event) => {
                                fileToRename = chq
                                // console.log(event)
                                const values = [...chequeMedia]
                                // let x = values[ind]?.src?.name?.split('.')[1]
                                values[ind].name = event.target.value

                                setChequeMedia(values)
                              }}
                              onBlur={() => {
                                let fileToRenam = chq

                                const values = [...chequeMedia]
                                let x = values[ind]?.src?.name?.split('.')[1]
                                renameCheque(fileToRenam, x)
                              }}
                              style={{height: '18px', fontSize: '8px', width: '80px'}}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* Invoice */}
              <div
                className='d-flex  align-items-start mb-3 pt-4'
                style={
                  paymentMethod == 1
                    ? {
                        borderTop: '2px dashed lightgray',
                        // transform: 'translate(-2px, -90px)'
                      }
                    : {borderTop: '2px dashed lightgray'}
                }
                ref={invoiceRef}
              >
                <div className=''>
                  <label className='head-text fs-4 mb-4' style={{fontWeight: '700'}}>
                    Invoice
                  </label>
                  {invoiceAdded && (
                    <>
                      {isManualPdf ? (
                        <div className='img-sets mw-350px ms-1'>
                          <div className=''>
                            <a
                              href={`${Bucket}${manualPdfUrl}`}
                              target='_blank'
                              style={{color: '#384a62'}}
                            >
                              <img
                                src={invoiceBlue}
                                width='40'
                                height='50'
                                className='main_img cursor-pointer ms-2'
                                style={{}}
                              />
                              {yellowInvoice ? (
                                <>
                                  <div
                                    className='main_img cursor-pointer'
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      borderRadius: '50%',
                                      backgroundColor: '#ffc100',
                                      border: '2px solid  #516176',
                                      color: '#516176',
                                      // marginTop: '-100px',
                                      // marginLeft: '38px',
                                      fontWeight: '900',
                                      fontSize: '12px',
                                      textAlign: 'center',
                                      transform: 'translate(42px, -61px)',
                                    }}
                                  >
                                    <p style={{marginTop: '-3px'}}>!</p>
                                  </div>

                                  <p
                                    style={{
                                      fontSize: '10px',
                                      maxWidth: '80px',
                                      wordBreak: 'break-all',
                                      marginTop: '-10px',
                                      // marginBottom: '-20px',
                                    }}
                                    className='text-center'
                                  >
                                    {invRecDetails?.invoiceSequence?.activityStatus != 1
                                      ? invoiceNo
                                      : invRecDetails?.invoiceSequence?.prefix}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      fontSize: '10px',
                                      maxWidth: '80px',
                                      wordBreak: 'break-all',
                                      marginBottom: '-20px',
                                    }}
                                    className='text-center mt-1'
                                  >
                                    {invRecDetails?.invoiceSequence?.activityStatus != 1
                                      ? invoiceNo
                                      : invRecDetails?.invoiceSequence?.prefix}
                                  </p>
                                  <img
                                    src={redCross}
                                    width='12'
                                    height='12'
                                    className='main_img cursor-pointer'
                                    style={
                                      invRecDetails?.invoiceSequence?.prefix != null
                                        ? {marginTop: '-120px', marginLeft: '45px'}
                                        : {marginTop: '-90px', marginLeft: '45px'}
                                    }
                                    onClick={() => {
                                      setInvoiceAdded(false)
                                    }}
                                  />
                                </>
                              )}
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className='img-sets mw-350px ms-1'>
                          <div className=''>
                            <a
                              onClick={() => setInvoiceModal(true)}
                              // href={`${Bucket}${invoiceUrl}`} target='_blank'
                              style={{color: '#384a62'}}
                            >
                              <img
                                src={invoiceBlue}
                                width='40'
                                height='50'
                                className='main_img cursor-pointer ms-2'
                                style={{}}
                              />
                              {yellowInvoice ? (
                                <>
                                  <div
                                    className='main_img cursor-pointer'
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      borderRadius: '50%',
                                      backgroundColor: '#ffc100',
                                      border: '2px solid  #516176',
                                      color: '#516176',
                                      // marginTop: '-100px',
                                      // marginLeft: '38px',
                                      fontWeight: '900',
                                      fontSize: '12px',
                                      textAlign: 'center',
                                      transform: 'translate(42px, -61px)',
                                    }}
                                  >
                                    <p style={{marginTop: '-3px'}}>!</p>
                                  </div>

                                  <p
                                    style={{
                                      fontSize: '10px',
                                      maxWidth: '80px',
                                      wordBreak: 'break-all',
                                      marginTop: '-10px',
                                      // marginBottom: '-20px',
                                    }}
                                    className='text-center'
                                  >
                                    {invRecDetails?.invoiceSequence?.activityStatus != 1
                                      ? invoiceNo
                                      : invRecDetails?.invoiceSequence?.prefix}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      fontSize: '10px',
                                      maxWidth: '80px',
                                      wordBreak: 'break-all',
                                      marginBottom: '-20px',
                                    }}
                                    className='text-center mt-1'
                                  >
                                    {invRecDetails?.invoiceSequence?.activityStatus != 1
                                      ? invoiceNo
                                      : invRecDetails?.invoiceSequence?.prefix}
                                  </p>
                                  <img
                                    src={redCross}
                                    width='12'
                                    height='12'
                                    className='main_img cursor-pointer'
                                    style={
                                      invRecDetails?.invoiceSequence?.prefix != null
                                        ? {marginTop: '-120px', marginLeft: '45px'}
                                        : {marginTop: '-90px', marginLeft: '45px'}
                                    }
                                    onClick={() => {
                                      setInvoiceAdded(false)
                                    }}
                                  />
                                </>
                              )}
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='ms-auto mt-2'>
                  <div
                    className='justify-content-center align-items-center ms-auto cursor-pointer'
                    style={{width: '60px'}}
                    onClick={() => {
                      if (paidAmount < 0) {
                        ErrorToast("Can't generate Invoice when Amount Paid is less than 0")
                      } else {
                        let today = new Date()
                        let x = today.toLocaleDateString('fr-CA')
                        // console.log(today.toLocaleDateString('uk-UA'))
                        setInvoiceDate(x)
                        setInvoiceModal(true)
                      }
                    }}
                  >
                    <div className='cursor-pointer' style={{paddingLeft: '18px'}}>
                      {' '}
                      <img src={invoiceBlue} height='35px' width='27px' />
                    </div>

                    <p
                      className='text-center grey-label cursor-pointer'
                      style={{fontSize: '0.8rem'}}
                    >
                      <i>Generate Invoice</i>
                    </p>
                  </div>
                </div>
              </div>

              {/* Receipt */}
              <div
                className='d-flex  justify-content-center align-items-start mb-3 pt-3'
                style={
                  paymentMethod == 1
                    ? {
                        borderTop: '2px dashed lightgray',
                        // transform: 'translate(-2px, -90px)'
                      }
                    : {borderTop: '2px dashed lightgray'}
                }
                ref={receiptRef}
              >
                <div>
                  <label className='head-text fs-4 mb-3' style={{fontWeight: '700'}}>
                    Receipts
                  </label>
                  {receiptAdded && (
                    <>
                      {isReceiptManualPdf ? (
                        <div className='img-sets mw-350px ms-1'>
                          <a
                            href={`${Bucket}${receiptPdfUrl}}`}
                            target='_blank'
                            style={{color: '#384a62'}}
                          >
                            <img
                              src={receiptBlue}
                              width='50'
                              height='50'
                              className='main_img cursor-pointer ms-1'
                              style={{}}
                            />

                            <p
                              style={{height: '15px', fontSize: '10px', maxWidth: '50px'}}
                              className='mt-1 text-center'
                            >
                              {invRecDetails?.receiptSequence?.activityStatus != 1
                                ? receiptNo
                                : invRecDetails?.receiptSequence?.prefix}
                            </p>
                          </a>
                          <img
                            src={redCross}
                            width='12'
                            height='12'
                            className='main_img cursor-pointer'
                            style={{marginTop: '-175px', marginLeft: '53px'}}
                            onClick={() => {
                              setReceiptAdded(false)
                            }}
                          />
                        </div>
                      ) : (
                        <div className='img-sets mw-350px ms-1'>
                          <a
                            onClick={() => setReceiptModal(true)}
                            // href={`${Bucket}${receiptUrl}`}
                            target='_blank'
                            style={{color: '#384a62'}}
                          >
                            <img
                              src={receiptBlue}
                              width='50'
                              height='50'
                              className='main_img cursor-pointer ms-1'
                              style={{}}
                            />

                            <p
                              style={{height: '15px', fontSize: '10px', maxWidth: '50px'}}
                              className='mt-1 text-center'
                            >
                              {invRecDetails?.receiptSequence?.activityStatus != 1
                                ? receiptNo
                                : invRecDetails?.receiptSequence?.prefix}
                            </p>
                          </a>
                          <img
                            src={redCross}
                            width='12'
                            height='12'
                            className='main_img cursor-pointer'
                            style={{marginTop: '-175px', marginLeft: '53px'}}
                            onClick={() => {
                              setReceiptAdded(false)
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className='ms-auto mt-1'>
                  <button
                    className='btn justify-content-center align-items-center ms-auto me-5 pt-1'
                    style={{
                      width: '60px',
                      height: '100px',
                      fontStyle: 'italic',
                      fontWeight: '400',
                      fontSize: '0.8rem',
                      paddingLeft: '10px',
                    }}
                    // disabled={
                    //   paidAmount === 0 || paidAmount === '' || paidAmount === '0' ? true : false
                    // }
                    onClick={
                      () => {
                        // if (paidAmount < 0) {
                        //   // ErrorToast("Can't generate Receipt when Amount Paid is less than 0")
                        // } else {
                        // if (datePaid == null) {
                        //   setDatePaidError(true)
                        //   ErrorToast('Please select Daid Paid..')
                        // }
                        // else
                        if (invoiceAdded === false)
                          ErrorToast('You must Generate an Invoive first!!!')
                        else setReceiptLinkModal(true)
                        // setReceiptModal(true)
                      }
                      // }
                    }
                  >
                    <div className='' style={{paddingLeft: '17px'}}>
                      {' '}
                      <img src={receiptBlue} height='40px' width='40px' />
                    </div>
                    <p
                      className='text-center head-text d-flex'
                      style={{marginLeft: '13px', paddingTop: '2px', fontWeight: '600'}}
                    >
                      Generate Receipt
                    </p>
                  </button>
                </div>
              </div>

              {/* Reminder */}
              <div
                className='d-flex justify-content-center align-items-start my-2 pt-3'
                style={
                  paymentMethod == 1
                    ? {
                        borderTop: '2px dashed lightgray',
                        // transform: 'translate(-2px, -90px)'
                      }
                    : {borderTop: '2px dashed lightgray'}
                }
              >
                <div>
                  <label className='head-text fs-4 mb-3' style={{fontWeight: '700'}}>
                    Reminder
                  </label>

                  {paymentCategory == 0 ? ( //Payment category -> Upcoming
                    <ul
                      style={{
                        listStyle: 'initial',
                        paddingLeft: '1.1rem',
                        maxHeight: '100px',
                        overflow: 'scroll',
                        width: '120px',
                      }}
                    >
                      {scheduleAdded &&
                        schedule.map((sch: any) => (
                          <>
                            {sch.days != '' && sch.date != '' && (
                              <li className='head-text'>
                                <i>{moment(new Date(sch.date)).format("DD-MM-YYYY")}</i>
                              </li>
                            )}
                          </>
                        ))}
                    </ul>
                  ) : (
                    //Payment category -> Overdue
                    <>
                      {scheduleAdded && isEndDateChecked && scheduleStartDate != null ? (
                        <>
                          <ul
                            style={{
                              listStyle: 'initial',
                              paddingLeft: '1.1rem',
                            }}
                          >
                            <li className='head-text'>
                              <i>
                                From{' '}
                                <b>{moment(new Date(scheduleStartDate)).format('DD.MM.YYYY')}</b> ,
                                the Tenant will be notified on <b>{frequency}</b> basis until he/she
                                completes the payment.
                              </i>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <>
                          {scheduleAdded && generatedDates?.length > 0 && (
                            <ul
                              style={{
                                listStyle: 'initial',
                                paddingLeft: '1.1rem',
                                maxHeight: '100px',
                                overflow: 'scroll',
                                width: '120px',
                              }}
                              className='mb-1'
                            >
                              {generatedDates.map((date: any, index: any) => (
                                <li key={index} className='head-text'>
                                  <i>{moment(date).format('DD.MM.YYYY')}</i>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className='ms-auto'>
                  <button
                    className='btn justify-content-center align-items-center ms-auto me-5 pt-0'
                    style={{
                      width: '60px',
                      height: '100px',
                      fontStyle: 'italic',
                      fontWeight: '400',
                      fontSize: '0.8rem',
                      paddingLeft: '12px',
                    }}
                    disabled={paidAmount >= totalAED ? true : false}
                    onClick={() => {
                      if (dueDate == '' || dueDate == null)
                        ErrorToast('You must set the payment due date first!!')
                      else setScheduleModal(true)
                    }}
                  >
                    <div className='' style={{paddingLeft: '17px'}}>
                      {' '}
                      <img src={reminderBlue} height='40px' width='40px' />
                    </div>
                    <p className='text-center grey-label' style={{marginLeft: '13px'}}>
                      Schedule Reminder
                    </p>
                  </button>
                </div>
              </div>

              {/* Attachments*/}
              <div className='d-flex my-2 pt-4' style={{borderTop: '2px dashed lightgray'}}>
                <label className='head-text fs-4' style={{fontWeight: '700'}}>
                  Attachments
                </label>
              </div>

              <div className='d-flex justify-content-center my-4 '>
                <form
                  id='form-file-upload'
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                  style={{height: '90px'}}
                >
                  <input
                    type='file'
                    multiple={true}
                    onChange={handleAttachmentChange}
                    id='mediaAttach'
                    style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
                  />

                  <label
                    id='label-file-upload-attach'
                    htmlFor='mediaAttach'
                    style={{
                      fontSize: '16px',
                      color: 'white',
                      transform: 'translate(0px, -90px)',
                    }}
                    className={
                      dragActive
                        ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                        : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                    }
                  >
                    <div
                      style={{
                        height: '90px',
                        width: '280px',
                        border: '2px dashed #384a62',
                        backgroundColor: '#f5f8fa',
                      }}
                      className=''
                    >
                      <div
                        className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                        style={{minWidth: '90px'}}
                      >
                        Drag & drop your document
                      </div>
                      <div
                        className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                        style={{minWidth: '90px'}}
                      >
                        <div
                          className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1rem',
                            paddingLeft: '15px',
                            borderRadius: '7px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #516176',
                          }}
                        >
                          Browse Files
                        </div>
                      </div>
                    </div>
                  </label>

                  {dragActive && (
                    <div
                      id='drag-file-element'
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </div>
              {attachedMedia.length > 0 && (
                <div
                  className='row align-items-center my-2'
                  style={
                    paymentMethod == 1
                      ? {
                          // transform: 'translate(-2px, -180px)',
                          overflowY: 'scroll',
                          minHeight: '96px',
                          maxHeight: '200px',
                        }
                      : {
                          overflowY: 'scroll',
                          minHeight: '96px',
                          maxHeight: '200px',
                          // transform: 'translate(-2px, -90px)',
                        }
                  }
                >
                  {attachedMedia.map((chq: any, ind: any) => (
                    <>
                      <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                        <div className='d-flex'>
                          <a
                            href={`${Bucket}${chq?.src}`}
                            target='_blank'
                            style={{color: '#384a62'}}
                          >
                            <img
                              src={file}
                              width='42px'
                              height='55px'
                              className='main_img cursor-pointer'
                              alt='image'
                              style={{marginLeft: '26px'}}
                            />
                          </a>
                          <div className='h-0'>
                            <img
                              src={redCross}
                              width='12px'
                              height='12px'
                              className='main_img cursor-pointer'
                              style={{marginTop: '-25px', marginLeft: '2px'}}
                              onClick={() => deleteAttachment(chq)}
                            />
                          </div>
                        </div>

                        <p
                          style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                          className='mt-1'
                        >
                          {chq.name}{' '}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              )}

              {/* Notes */}
              <div className='my-2 pt-3 mb-5' style={{borderTop: '2px dashed lightgray'}}>
                <label className='head-text fs-4' style={{fontWeight: '700'}}>
                  Notes
                </label>
                {notes != '' ? (
                  <textarea
                    className='form-control  form-control-solid-bg py-2 input-style mytest'
                    name='comment'
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                      const target = e.target

                      // Get the `maxlength` attribute
                      const maxLength = target.getAttribute('maxlength')
                      // Count the current number of characters
                      const currentLength = target.value.length

                      setNotesCount(`${currentLength}/${maxLength}`)
                    }}
                    style={{height: '100px'}}
                    maxLength={1000}
                  ></textarea>
                ) : (
                  <textarea
                    className='form-control form-control-solid-bg py-2 input-style'
                    name='comment'
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value)
                      const target = e.target

                      // Get the `maxlength` attribute
                      const maxLength = target.getAttribute('maxlength')
                      // Count the current number of characters
                      const currentLength = target.value.length

                      setNotesCount(`${currentLength}/${maxLength}`)
                    }}
                    style={{height: '100px'}}
                    maxLength={1000}
                  ></textarea>
                )}
                <div className='d-flex align-items-center justify-content-end mb-2'>
                  <label className='mb-1 blue-label fs-7'>{notesCount}</label>
                </div>
              </div>

              {/* Submit*/}
              <div className='d-flex  justify-content-center mt-2 pt-4'>
                <div
                  className='btn btn-sm ps-2 text-white py-3 fs-2'
                  style={{
                    width: '320px',
                    fontSize: '1.2rem',
                    //  paddingRight: '55px',
                    borderRadius: '7px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={(event) => {
                    event.preventDefault()

                    validateIncomingPayment()
                  }}
                >
                  <img
                    src={submitImg}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Submit
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Invoice Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={invoiceModal}
        onHide={() => {
          setInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setInvoiceModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height='80px' width='65px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-7'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          {/* <div className='d-flex my-2 pt-4'> */}
          <Row className='mt-1 mb-4 mx-0'>
            <Col md={8} className='px-0'>
              <div
                className='btn btn-sm fw-bold ps-2 pe-4 text-white'
                style={{width: '140px', backgroundColor: '#008081'}}
                onClick={() => {
                  if (invRecDetails?.invoiceSequence?.activityStatus == 0) {
                    if (invoiceNo == '' || invoiceNo == undefined) setInvoiceNoError(true)
                    else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any previous Invoice? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#008081',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'No, Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setInvoiceModal(false)
                          setInvoiceAdded(true)
                          setYellowInvoice(false)
                          setIsManualPdf(false)
                        }
                      })
                    }
                  } else {
                    Swal.fire({
                      html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any previous Invoice? </p>',
                      showConfirmButton: true,
                      confirmButtonColor: '#008081',
                      confirmButtonText: 'Upload',
                      showCancelButton: true,
                      cancelButtonText: 'No, Continue',
                    }).then((res) => {
                      if (res.isConfirmed) {
                        setUploadInvoiceModal(true)
                        //  setInvoiceModal(false)
                      }
                      if (res.isDismissed) {
                        setInvoiceModal(false)
                        setInvoiceAdded(true)
                        setYellowInvoice(false)
                        setIsManualPdf(false)
                      }
                    })
                  }
                }}
              >
                {'  '}
                Generate
              </div>
            </Col>
            <Col md={4}>
            <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#008081'
                  offColor='#b0bec5'
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>
          <div className='card my-2 mx-0 p-5'>
            <Row className='mt-2'>
              <Col md={5} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>INVOICE</h1>
                  <img
                    src={editpen}
                    alt=''
                    className='ms-2'
                    style={{cursor: 'pointer', height: '14px', width: '14px', marginTop: '-25px'}}
                  />
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                    <>
                      {invRecDetails?.invoiceSequence?.prefix != null && (
                        <>
                          <label
                            className='mb-1 me-5 pe-5 label-color'
                            style={{minWidth: '100px', fontSize: '1rem'}}
                          >
                            Invoice No.
                          </label>

                          <input
                            type='text'
                            className={
                              invoiceNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            // className='form-control form-control-solid-bg input-style'
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={invRecDetails?.invoiceSequence?.prefix}
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <label
                        className='mb-1 me-5 pe-5 label-color'
                        style={{minWidth: '100px', fontSize: '1rem'}}
                      >
                        Invoice No.
                      </label>

                      <input
                        type='text'
                        className={
                          invoiceNoError
                            ? 'form-control form-control-solid-bg input-style border-danger '
                            : 'form-control form-control-solid-bg input-style mytest'
                        }
                        // className='form-control form-control-solid-bg input-style'
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={invoiceNo}
                        onChange={(event) => {
                          setInvoiceNo(event.target.value)
                          setInvoiceNoError(false)
                        }}
                      />
                    </>
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-5 pe-5  label-color'
                    style={{minWidth: '100px', fontSize: '1rem'}}
                  >
                    Invoice Date
                  </label>
                  <div style={{fontWeight: '500'}}>  {moment(new Date(invoiceDate)).format('DD-MM-YYYY')}</div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-5 pe-5  label-color'
                    style={{minWidth: '100px', fontSize: '1rem'}}
                  >
                    Due Date
                  </label>
                  <div style={{fontWeight: '500'}}> {moment(new Date(dueDateToShow)).format('DD-MM-YYYY')}</div>
                </div>
              </Col>
              <Col md={7}>
                <div className='d-flex my-4 '>
                  {invRecDetails?.invoiceLogo != null && (
                    <img
                      src={`${Bucket}${invRecDetails?.invoiceLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='ms-auto'
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row className='mt-3 pb-5'>
              <Col md={6} style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.name != null && invoiceCompany?.name}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.address != null && invoiceCompany?.address}
                </p>

                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.phoneNumber != null &&
                    invoiceCompany?.countryCode + ' ' + invoiceCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.email != null && invoiceCompany?.email}
                </p>
              </Col>

              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.lastName} {tenantDetails?.firstName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>

            <Row className='pb-5' style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={6}>
                <div className='d-flex align-items-start my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{invoiceCompany?.trn != null ? invoiceCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {fields.map((field: any, index: any) => {
                  let y: any = ((field.amount * field.qty) / 100) * field.vat
                  let total: number = parseFloat(y) + field.amount * field.qty
                  return (
                    <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                      <td className='py-2'>{index}</td>
                      <td className='py-2'>{field.item}</td>
                      <td className='py-2'>{field.qty}</td>
                      <td className='py-2'>
                        {field?.isLateItem
                          ? (field?.amount / field?.qty)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : field?.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                      <td className='py-2'>
                        {field?.isLateItem
                          ? ((field.amount / 100) * field.vat)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : (((field.amount * field.qty) / 100) * field.vat)?.toLocaleString(
                              'en-US',
                              {minimumFractionDigits: 2}
                            )}
                      </td>
                      <td className='py-2'>
                        {field?.isLateItem
                          ? (field.amount + (field.amount / 100) * field.vat)?.toLocaleString(
                              'en-US',
                              {minimumFractionDigits: 2}
                            )
                          : total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                    </tr>
                  )
                })}

                {fields.map((f: any, ind: any) => {
                  if (f?.isLateItem) {
                    vatAmount = vatAmount + (f.amount / 100) * f.vat
                    nonVat = nonVat + f.amount
                  } else {
                    vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
                    nonVat = nonVat + f.amount * f.qty
                  }
                  return <></>
                })}

                <tr style={{height: '55px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {' '}
                    {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>AED {totalAED?.toLocaleString('en-US', {minimumFractionDigits: 2})}</b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={7}>
                <div className='row my-2 mb-5'>
                  <div className='col-md-6'>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Payment Method
                    </label>
                  </div>
                  <div className='col-md-6 text-center'>
                    <label
                      className='mb-1 me-1 fs-6 pt-1'
                      style={{minWidth: '160px', fontWeight: '500'}}
                    >
                      {paymentMethod == 0
                        ? 'Card'
                        : paymentMethod == 1
                        ? 'Cheque'
                        : paymentMethod == 2
                        ? 'Bank'
                        : 'Cash'}
                    </label>
                  </div>
                </div>
                {paymentMethod == 2 && (
                  <>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                      >
                        Account Name
                      </label>
                      <b> {selectedAccount?.accountHolderName}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        IBAN
                      </label>
                      <b> {selectedAccount?.IBAN}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Account No.
                      </label>
                      <b> {selectedAccount?.accountNumber}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Bank Name
                      </label>
                      <b> {selectedAccount?.bankName}</b>
                    </div>
                  </>
                )}

                {paymentMethod == 1 && (
                  <>
                    {chequeMedia.length > 0 && (
                      <div
                        className='row align-items-center my-2'
                        style={
                          paymentMethod == 1
                            ? {
                                overflowY: 'scroll',
                                minHeight: '96px',
                                maxHeight: '200px',
                                // transform: 'translate(-2px, -90px)',
                              }
                            : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                        }
                      >
                        {chequeMedia.map((chq: any, ind: any) => (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                              <div className='d-flex justify-content-center'>
                                <a
                                  href={`${Bucket}${chq?.image}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                >
                                  <img
                                    src={chequeImg}
                                    width='80px'
                                    height='40px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{objectFit: 'cover'}}
                                  />
                                </a>
                              </div>
                              <p className='fs-7 ps-1 text-center'>{chq.name}</p>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Col>
              <Col md={5}>
                <div className='my-2'>
                  <label className='mb-0 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Share with Recipients
                  </label>
                </div>
                {/* Notes */}
                <div className='my-2 mb-4 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>
                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={invoiceNotes}
                    onChange={(e) => {
                      setInvoiceNotes(e.target.value)
                      const target = e.target

                      // Get the `maxlength` attribute
                      const maxLength = target.getAttribute('maxlength')
                      // Count the current number of characters
                      const currentLength = target.value.length

                      setNotesInvCount(`${currentLength}/${maxLength}`)
                    }}
                    maxLength={1000}
                  ></textarea>
                  <div className='d-flex align-items-center justify-content-end mb-2'>
                    <label className='mb-1 blue-label fs-7'>{notesInvCount}</label>
                  </div>
                </div>

                <div className='d-flex my-2 pt-0'>
                  <label className='mb-1 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Attachments
                  </label>
                </div>

                <div className='d-flex justify-content-center my-4 '>
                  <form
                    id='form-file-upload-invoice'
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                    style={{height: '90px'}}
                  >
                    <input
                      type='file'
                      multiple={true}
                      onChange={handleInvoiceAttachmentChange}
                      id='mediaAttachInvoice'
                      style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
                    />

                    <label
                      htmlFor='mediaAttachInvoice'
                      className={
                        dragActive
                          ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                          : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                      }
                      style={{
                        fontSize: '16px',
                        color: 'white',
                        transform: 'translate(-2px, -90px)',
                      }}
                    >
                      <div
                        style={{
                          height: '90px',
                          width: '280px',
                          border: '2px dashed #384a62',
                          backgroundColor: '#f5f8fa',
                        }}
                        className=''
                      >
                        <div
                          className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                          style={{minWidth: '90px'}}
                        >
                          Drag & drop your document
                        </div>
                        <div
                          className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                          style={{minWidth: '90px'}}
                        >
                          <div
                            className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1rem',
                              paddingLeft: '15px',
                              borderRadius: '7px',
                              backgroundColor: '#35475e',
                              boxShadow: '0px 0px 20px 0px #516176',
                            }}
                          >
                            Browse Files
                          </div>
                        </div>
                      </div>
                    </label>
                    {dragActive && (
                      <div
                        id='drag-file-element'
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      ></div>
                    )}
                  </form>
                </div>
                {invoiceAttachedMedia.length > 0 && (
                  <div className='row align-items-center mt-2'>
                    {invoiceAttachedMedia.map((chq: any, ind: any) => (
                      <>
                        <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                          <div className='d-flex'>
                            <a
                              href={`${Bucket}${chq?.src}`}
                              target='_blank'
                              style={{color: '#384a62'}}
                              className='d-flex'
                            >
                              <img
                                src={file}
                                width='42px'
                                height='55px'
                                className='main_img cursor-pointer'
                                alt='image'
                                style={{marginLeft: '26px'}}
                              />
                            </a>
                            <div className='h-0'>
                              <img
                                src={redCross}
                                width='12px'
                                height='12px'
                                className='main_img cursor-pointer'
                                style={{marginTop: '-25px', marginLeft: '-3px'}}
                                onClick={() => deleteInvoiceAttachment(chq)}
                              />
                            </div>
                          </div>

                          <p
                            style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                            className='mt-1'
                          >
                            {chq.name}{' '}
                          </p>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/*  Receipt Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={receiptModal}
        onHide={() => {
          if (receiptAdded == false) {
            setAmountPaid(0)
            const values = [...fields]
            for (let i = 0; i < values?.length; i++) {
              let y: any =
                values[i].amount * values[i].qty +
                ((values[i].amount * values[i].qty) / 100) * values[i].vat
              values[i].amountPaid = 0
              values[i].outstanding = y
            }
          }
          setReceiptModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 receipt-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Receipt</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  if (receiptAdded == false) {
                    setAmountPaid(0)
                    const values = [...fields]
                    for (let i = 0; i < values?.length; i++) {
                      let y: any =
                        values[i].amount * values[i].qty +
                        ((values[i].amount * values[i].qty) / 100) * values[i].vat
                      values[i].amountPaid = 0
                      values[i].outstanding = y
                    }
                  }
                  setReceiptModal(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='receipt-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '15px'}}>
              <img src={receiptWhite} height='80px' width='85px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body px-lg-10'
          style={{
            height: '600px',
            transform: 'translate(0px, -94px)',
            maxHeight: '600px',
            overflow: 'scroll',
            marginBottom: '-94px',
          }}
        >
          <div className='d-flex align-items-center justify-content-center my-2'>
            <div
              className='btn btn-sm fw-bold  ps-2 pe-4 text-white'
              style={{width: '275px', backgroundColor: '#2e9a64'}}
              onClick={() => {
                if (invRecDetails?.receiptSequence?.activityStatus == 0) {
                  if (receiptNo == '' || receiptNo == undefined) setReceiptNoError(true)
                  else {
                    if (datePaid == null) setDatePaidError(true)
                    else if (receiptPaymentMethod == 1) {
                      if (receiptChequeBankName == '') {
                        ErrorToast(`Please enter Bank Name!!!!`)
                      } else if (receiptChequeMedia?.length <= 0) {
                        ErrorToast(`Please Upload atleast one Cheque!`)
                      } else {
                        Swal.fire({
                          html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any previous Receipt? </p>',
                          showConfirmButton: true,
                          confirmButtonColor: '#2e9a64',
                          confirmButtonText: 'Upload',
                          showCancelButton: true,
                          cancelButtonText: 'No, Continue',
                        }).then((res) => {
                          if (res.isConfirmed) {
                            setUploadInvoiceModal(true)
                            setIsReceipt(true)
                            //  setInvoiceModal(false)
                          }
                          if (res.isDismissed) {
                            setReceiptModal(false)
                            setReceiptLinkModal(false)
                            setReceiptAdded(true)
                            setIsReceiptManualPdf(false)
                          }
                        })
                      }
                    } else if (receiptPaymentMethod == 2) {
                      if (selectedAccountReceipt == null) {
                        ErrorToast(`Please select Payment Acccount!!!`)
                      } else {
                        Swal.fire({
                          html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                          showConfirmButton: true,
                          confirmButtonColor: '#2e9a64',
                          confirmButtonText: 'Upload',
                          showCancelButton: true,
                          cancelButtonText: 'No, Continue',
                        }).then((res) => {
                          if (res.isConfirmed) {
                            setUploadInvoiceModal(true)
                            setIsReceipt(true)
                            //  setInvoiceModal(false)
                          }
                          if (res.isDismissed) {
                            setReceiptModal(false)
                            setReceiptLinkModal(false)
                            setReceiptAdded(true)
                            setIsReceiptManualPdf(false)
                          }
                        })
                      }
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt ? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'No, Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                        }
                      })

                      // setReceiptModal(false)
                      // setReceiptLinkModal(false)
                      // setReceiptAdded(true)
                    }
                  }
                } else {
                  if (datePaid == null) setDatePaidError(true)
                  else if (receiptPaymentMethod == 1) {
                    if (receiptChequeBankName == '') {
                      ErrorToast(`Please enter Bank Name!!!`)
                    } else if (receiptChequeMedia?.length <= 0) {
                      ErrorToast(`Please Upload atleast one Cheque!!!`)
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any previous Receipt? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'No, Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                        }
                      })
                    }
                  } else if (receiptPaymentMethod == 2) {
                    if (selectedAccountReceipt == null) {
                      ErrorToast(`Please select Payment Acccount!!!`)
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'No, Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                        }
                      })
                    }
                  } else {
                    Swal.fire({
                      html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt ? </p>',
                      showConfirmButton: true,
                      confirmButtonColor: '#2e9a64',
                      confirmButtonText: 'Upload',
                      showCancelButton: true,
                      cancelButtonText: 'No, Continue',
                    }).then((res) => {
                      if (res.isConfirmed) {
                        setUploadInvoiceModal(true)
                        setIsReceipt(true)
                        //  setInvoiceModal(false)
                      }
                      if (res.isDismissed) {
                        setReceiptModal(false)
                        setReceiptLinkModal(false)
                        setReceiptAdded(true)
                        setIsReceiptManualPdf(false)
                      }
                    })

                    // setReceiptModal(false)
                    // setReceiptLinkModal(false)
                    // setReceiptAdded(true)
                  }
                }
                // else {
                //   if (receiptUrl != '') {
                //     Swal.fire({
                //       html: '<p class="text-center m-0" style="font-size: 16px"> Are you sure you want to generate a new receipt. Generating a new receipt will replace the existing receipt. </p>',
                //       showConfirmButton: true,
                //       confirmButtonColor: '#2e9a64',
                //       confirmButtonText: 'Replace',
                //       showCancelButton: true,
                //       cancelButtonText: 'Cancel',
                //     }).then((res) => {
                //       if (res.isConfirmed) {
                //         setReceiptModal(false)
                //         setReceiptAdded(true)
                //       }
                //     })
                //   } else {

                //     setReceiptAdded(true)
                //     setReceiptModal(false)
                //   }
                // }
              }}
            >
              Generate
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center my-2'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Invoice No.
            </label>

            <a
              style={{textDecoration: 'underline', color: 'dodgerblue'}}
              // href={`${Bucket}${invoiceUrl}`}
              // target='_blank'
              onClick={() => setInvoiceModal(true)}
            >
              {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                <>
                  {invRecDetails?.invoiceSequence?.prefix != null && (
                    <>{invRecDetails?.invoiceSequence?.prefix}</>
                  )}
                </>
              ) : (
                <> {invoiceNo} </>
              )}
            </a>
          </div>

          <div className='d-flex align-items-center justify-content-center my-2 '>
            <h1 className=''>RECEIPT</h1>
          </div>

          <div className='d-flex align-items-center justify-content-center my-2'>
            {invRecDetails?.receiptSequence?.activityStatus == 1 ? (
              <>
                {invRecDetails?.receiptSequence?.prefix != null && (
                  <>
                    <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
                      Receipt No.
                    </label>
                    {invRecDetails?.receiptSequence?.prefix}
                  </>
                )}
              </>
            ) : (
              <input
                type='text'
                className={
                  receiptNoError
                    ? 'form-control form-control-solid-bg w-25 input-style border-danger '
                    : 'form-control form-control-solid-bg w-25 input-style mytest'
                }
                placeholder='Receipt No'
                name='item_id'
                value={receiptNo}
                onChange={(event) => {
                  setReceiptNo(event.target.value)
                  setReceiptNoError(false)
                }}
                maxLength={15}
              />
            )}
          </div>

          <div
            className='d-flex align-items-center justify-content-center my-2 mt-4 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {' '}
              {receiptCompany?.name != null && receiptCompany?.name}
            </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {' '}
              {receiptCompany?.address != null && receiptCompany?.address}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {' '}
              {receiptCompany?.phoneNumber != null &&
                receiptCompany?.countryCode + ' ' + receiptCompany?.phoneNumber}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receiptCompany?.email != null && receiptCompany?.email}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-5 fs-3'
            style={{fontWeight: '600'}}
          >
            <label className='me-5 pe-5 ' style={{minWidth: '50px'}}>
              Total
            </label>

            <b style={{fontWeight: '700'}} className='fs-3'>
              AED {totalAED?.toLocaleString('en-US', {minimumFractionDigits: 2})}{' '}
            </b>
          </div>

          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              <label className='mb-1 me-5 pe-5  head-text' style={{minWidth: '150px'}}>
                Amount Paid
              </label>
              {/* {totalAmountPaid} */}
              {paidAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
              <img
                src={editpen}
                alt=''
                height='18'
                width='18'
                className='mb-3 cursor-pointer'
                style={{marginLeft: '50px', marginTop: '6px', cursor: 'pointer'}}
                onClick={() => {
                  setReceiptModal(false)
                  setReceiptLinkModal(true)
                }}
              />
            </div>
          </div>
          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <div className='mx-auto'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Amount Paid
            </label>
            <b>{paidAmount}</b>
          </div>
          </div> */}

          {/* <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              <label className='mb-1 me-5 pe-5 head-text ' style={{minWidth: '150px'}}>
                Date Paid
              </label>
              {datePaid}
            </div>
          </div> */}

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'>
              <label className='mb-1 me-5 head-text' style={{minWidth: '90px'}}>
                Date Paid
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                <DatePicker
                  onChange={handleDatePaidChange}
                  // disabled={paidAmountReceipt == 0 || paidAmountReceipt == '' ? true : false}
                  className={datePaid != null ? 'mytest' : datePaidError ? 'border-danger' : ''}
                  format={'DD-MM-YYYY'}
                />
              </div>
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Date Paid
            </label>
            {datePaid}
          </div> */}

          {/* <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              <label className='mb-1 me-5 pe-5 head-text' style={{minWidth: '150px'}}>
                Payment Method
              </label>
              {paymentMethod == 0
                ? 'Card'
                : paymentMethod == 1
                ? 'Cheque'
                : paymentMethod == 2
                ? 'Bank Transfer'
                : 'Cash'}

             
            </div>
          </div> */}

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Payment method
            </label>

            <b>{paymentMethod}</b>
          </div> */}

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'>
              <label className='mb-1 me-5 head-text' style={{minWidth: 'fit-content'}}>
                Payment Method
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                <Select
                  options={cardItems}
                  styles={customStylesInputFilled2}
                  isSearchable={false}
                  defaultValue={
                    receiptPaymentMethod == 0
                      ? cardItems[0]
                      : receiptPaymentMethod == 1
                      ? cardItems[1]
                      : receiptPaymentMethod == 2
                      ? cardItems[2]
                      : cardItems[3]
                  }
                  onChange={(event: any) => {
                    setReceiptPaymentMethod(event.value)
                    if (event.value == 2) getBankAccounts()
                    else {
                      setShowAccReceipt(false)
                      setSelectedAccountReceipt(null)
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              {/* Payment Method: Bank */}
              {receiptPaymentMethod == 2 ? (
                <>
                  <div className='align-items-center d-flex mb-3'>
                    <label className='mb-1 me-2 d-flex head-text' style={{minWidth: '100px'}}>
                      Bank Account
                    </label>

                    {bankAccountItems &&
                      bankAccountItems.map((bA: any, index: any) => {
                        if (selectedAccountReceipt?._id == bA._id) accountInd = index
                        return <></>
                      })}

                    <div style={{width: '-webkit-fill-available'}}>
                      {accountInd != null ? (
                        <Select
                          // defaultValue={{
                          //   label: bankAccountItems[accountInd]?.accountHolderName,
                          //   value: bankAccountItems[accountInd]?._id,
                          //   id: bankAccountItems[accountInd],
                          // }}
                          // bankAccountItems[accountInd]}
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAccReceipt(true)
                            setSelectedAccountReceipt(event.id)
                            // submitIncomingPayment()
                          }}
                        />
                      ) : (
                        <Select
                          // bankAccountItems[accountInd]}
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStyles2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAccReceipt(true)
                            setSelectedAccountReceipt(event.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {showAccReceipt && (
                    <>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account Name
                        </label>
                        {selectedAccountReceipt?.accountHolderName}
                      </div>
                      <div
                        className='d-flex align-items-center my-2 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          IBAN
                        </label>
                        {selectedAccountReceipt?.IBAN}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account No.
                        </label>
                        {selectedAccountReceipt?.accountNumber}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Bank Name
                        </label>
                        {selectedAccountReceipt?.bankName}
                      </div>
                    </>
                  )}
                </>
              ) : receiptPaymentMethod == 1 ? (
                <>
                  {/* <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
        </div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form> */}

                  <div className='d-flex align-items-center justify-content-center mb-2'>
                    <label className='mb-1 me-5 blue-label' style={{minWidth: '90px'}}>
                      Bank Name
                    </label>

                    <input
                      type='text'
                      className='form-control form-control-solid-bg input-style mytest'
                      placeholder='Enter Name'
                      name='item_id'
                      value={receiptChequeBankName}
                      onChange={(event) => {
                        setReceiptChequeBankName(event?.target.value)
                      }}
                    />
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                    <form
                      id='form-file-upload'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px', marginLeft: '25px'}}
                    >
                      <label
                        id='label-file-upload'
                        htmlFor='input-file-upload'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          // transform: 'translate(-2px, -90px)',
                        }}
                        ref={inputRef}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        type='file'
                        multiple={true}
                        onChange={handleReceiptChequeChange}
                        id='input-file-upload'
                        ref={inputRef}
                        style={{
                          height: '90px',
                          opacity: '-0.9',
                          position: 'relative',
                          transform: 'translate(-2px, -90px)',
                        }}
                      />
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {receiptChequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        receiptPaymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {receiptChequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                  // onClick={() => {
                                  //   let y: any = []
                                  //   for (let i = 0; i < media.concat(mediaPop?.media).length; i++) {
                                  //     y[y.length] = {
                                  //       src: Bucket + media.concat(mediaPop?.media[i]),
                                  //     }
                                  //   }
                                  //   setSlides(y)
                                  //   setIndex(i)
                                  // }}
                                />
                              </a>
                              <div className='h-0'>
                                <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-25px', marginLeft: '5px'}}
                                  onClick={() => deleteChequeReceipt(chq)}
                                />
                              </div>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-solid-bg input-style mt-1 mytest'
                              placeholder='Cheque No'
                              name='item_id'
                              value={chq.name}
                              onChange={(event) => {
                                fileToRename = chq
                                // console.log(event)
                                const values = [...receiptChequeMedia]
                                // let x = values[ind]?.src?.name?.split('.')[1]
                                values[ind].name = event.target.value

                                setReceiptChequeMedia(values)
                              }}
                              onBlur={() => {
                                let fileToRenam = chq

                                const values = [...receiptChequeMedia]
                                let x = values[ind]?.src?.name?.split('.')[1]
                                renameChequeReceipt(fileToRenam, x)
                              }}
                              style={{height: '18px', fontSize: '8px', width: '80px'}}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className='row '>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              <label className='mb-1 me-5 pe-2  head-text' style={{minWidth: '150px'}}>
                Outstanding Amount
              </label>

              {/* {(totalAED - paidAmount)?.toLocaleString('en-US', {minimumFractionDigits: 2})} */}
              {totalAED - paidAmount > 0
                ? getNegative(totalAED - paidAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })
                : getPositive(totalAED - paidAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Outstanding Amount
            </label>

            <b> {totalAED - paidAmount}</b>
          </div> */}

          <div className='align-items-center justify-content-center my-2 text-center'>
            <p
              className='grey-label mx-auto mt-5'
              style={{borderBottom: '2px dashed lightgray', width: '60%'}}
            ></p>
          </div>

          <div className='d-flex align-items-center justify-content-center my-5 mb-2'>
            <label className='head-text fs-4' style={{fontWeight: '700'}}>
              Share with Recipients
            </label>
          </div>

          <div className='d-flex align-items-center justify-content-center my-5 mt-3'>
            <label className='head-text fs-4' style={{fontWeight: '700'}}>
              Attachments
            </label>
          </div>

          <div className='d-flex justify-content-center my-4 '>
            <form
              id='form-file-upload-receipt'
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{height: '90px'}}
            >
              <input
                type='file'
                multiple={true}
                onChange={handleReceiptAttachmentChange}
                id='mediaAttachReceipt'
                style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
              />
              <label
                htmlFor='mediaAttachReceipt'
                className={
                  dragActive
                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                }
                style={{
                  fontSize: '16px',
                  color: 'white',
                  transform: 'translate(-2px, -90px)',
                }}
              >
                <div
                  style={{
                    height: '90px',
                    width: '280px',
                    border: '2px dashed #384a62',
                    backgroundColor: '#f5f8fa',
                  }}
                  className=''
                >
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                    style={{minWidth: '90px'}}
                  >
                    Drag & drop your document
                  </div>
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                    style={{minWidth: '90px'}}
                  >
                    <div
                      className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                      style={{
                        fontSize: '1rem',
                        paddingLeft: '15px',
                        borderRadius: '7px',
                        backgroundColor: '#35475e',
                        boxShadow: '0px 0px 20px 0px #516176',
                      }}
                    >
                      Browse Files
                    </div>
                  </div>
                </div>
              </label>
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
          <div className='row'>
            <div className='col-3'></div>
            <div className='col-6'>
              {receiptAttachedMedia.length > 0 && (
                <div className='row align-items-center my-2'>
                  {receiptAttachedMedia.map((chq: any, ind: any) => (
                    <>
                      <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                        <div className='d-flex'>
                          <a
                            href={`${Bucket}${chq?.src}`}
                            target='_blank'
                            style={{color: '#384a62'}}
                            className='d-flex'
                          >
                            <img
                              src={file}
                              width='42px'
                              height='55px'
                              className='main_img cursor-pointer'
                              alt='image'
                              style={{marginLeft: '26px'}}
                            />
                          </a>
                          <div className='h-0'>
                            <img
                              src={redCross}
                              width='12px'
                              height='12px'
                              className='main_img cursor-pointer'
                              style={{marginTop: '-25px', marginLeft: '-3px'}}
                              onClick={() => deleteReceiptAttachment(chq)}
                            />
                          </div>
                        </div>

                        <p
                          style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                          className='mt-1'
                        >
                          {chq.name}{' '}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/*  Schedule Reminder Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={scheduleModal}
        onHide={() => {
          setScheduleModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: ' #0098cd',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Reminders</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setScheduleModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='schedule-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-1' style={{paddingLeft: '60px'}}>
              <img src={reminderWhite} height='90px' width='90px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        {paymentCategory == 0 ? (
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-10'
            style={{transform: 'translate(0px, -80px)', height: '400px'}}
          >
            <Row className=''>
              <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
                <label className='required mb-2 label-color'>Days before payment is due</label>
              </Col>
              <Col lg={4} className='align-items-center ' style={{paddingLeft: '70px'}}>
                <label className='mb-2 label-color'>Reminder Schedule</label>
              </Col>
              <Col lg={4} md={4} className='align-items-center text-end'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    setScheduleAdded(true)
                    setScheduleModal(false)
                  }}
                >
                  <img
                    src={reminderWhite}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Schedule
                </button>
              </Col>
            </Row>

            {schedule.map((sch: any, index: any) => {
              return (
                <Row>
                  <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
                    {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                    <input
                      type='number'
                      className='form-control form-control-solid-bg mytest'
                      placeholder='Enter Days'
                      name='item_id'
                      value={sch.days}
                      onChange={(e) => {
                        const values = [...schedule]
                        values[index].days = e.target.value
                        setSchedule(values)
                        // handleSchedule(e, index)
                      }}
                      onBlur={(e) => handleSchedule(e, index)}
                      style={{
                        border: '0.1rem solid #bec3cb',
                        borderRadius: '2px',
                        padding: '0.4rem 1rem',
                        width: '80%',
                      }}
                    />
                  </Col>
                  <Col
                    lg={4}
                    className='align-items-center mb-0 mt-2'
                    style={{paddingLeft: '70px'}}
                  >
                    <div className='d-flex align-items-center'>
                    {sch.date ? moment(new Date(sch.date)).format("DD-MM-YYYY"): ''}
                      <img
                        src={redCross}
                        width='16px'
                        height='16px'
                        className='main_img cursor-pointer ms-auto'
                        onClick={() => handleScheduleRemoveFields(index, sch.id)}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} className='align-items-center text-end'></Col>
                </Row>
              )
            })}

            <div
              className='d-flex align-items-center  my-2 cursor-pointer'
              style={{width: 'fit-content'}}
              onClick={() => handleScheduleAddFields()}
            >
              <img
                src={plusBlue}
                style={{
                  height: '16px',
                  width: '16px',
                }}
              />
              <p className='head-text ms-3 mb-0'>
                {' '}
                <i>Add another reminder</i>
              </p>
            </div>
          </div>
        ) : (
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-5'
            style={{transform: 'translate(0px, -80px)', height: '400px'}}
          >
            <Row className='mt-3'>
              <Col lg={3} md={3} className='align-items-center pe-5 mb-2'>
                <label className='mt-3 label-color'>Start Date</label>
              </Col>
              <Col lg={5} className='align-items-center ps-5 ' style={{paddingLeft: '70px'}}>
                <label className='mt-3 label-color'>End Date</label>
              </Col>
              <Col lg={4} md={4} className='align-items-center text-end'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    setScheduleAdded(true)
                    setScheduleModal(false)
                  }}
                >
                  <img
                    src={reminderWhite}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Schedule
                </button>
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col lg={3} md={3} className='align-items-center pe-5 mb-5'>
                {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                <DatePicker
                  onChange={handleStartDateChange}
                  className={
                    scheduleStartDate != null ? 'mytest' : datePaidError ? 'border-danger' : ''
                  }
                  disabledDate={disabledDate}
                  format={'DD-MM-YYYY'}
                />
              </Col>
              <Col lg={3} className='align-items-center mb-0 ps-5' style={{paddingLeft: '70px'}}>
                <DatePicker
                  onChange={handleEndDateChange}
                  className={
                    scheduleEndDate != null ? 'mytest' : datePaidError ? 'border-danger w-75' : ''
                  }
                  disabled={isEndDateChecked}
                  disabledDate={disabledDate}
                  format={'DD-MM-YYYY'}
                />
              </Col>
              <Col lg={6} md={6} className='align-items-center '>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='schedule-check'
                    style={{cursor: 'pointer', marginTop: '-6px'}}
                    defaultChecked={isEndDateChecked}
                    onChange={(e) => {
                      console.log(e)
                      setIsEndDateChecked(e.target.checked)
                    }}

                    // onChange={(e) => selectedUnits(i?._id, f?._id, e)}
                  />
                  <label className='head-text  px-3'>
                    Keep sending reminder, until the Tenant completes the payment
                  </label>
                </div>
              </Col>
            </Row>

            {scheduleStartDate != null && (scheduleEndDate != null || isEndDateChecked) && (
              <div className='row'>
                <div className='col-md-3'>
                  <label className='mb-2 label-color'>Frequency</label>
                  {frequency == null ? (
                    <Select
                      options={isEndDateChecked ? optionsChecked : options}
                      styles={customStyles2}
                      isSearchable={false}
                      onChange={(event) => {
                        calculateDates(event)
                      }}
                    />
                  ) : (
                    <Select
                      options={isEndDateChecked ? optionsChecked : options}
                      styles={customStylesInputFilled2}
                      isSearchable={false}
                      onChange={(event) => {
                        calculateDates(event)
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {isEndDateChecked == false && (
              <>
                {generatedDates?.length > 0 && (
                  <ul
                    style={{
                      listStyle: 'initial',
                      paddingLeft: '1.1rem',
                      maxHeight: '260px',
                      overflow: 'scroll',
                    }}
                    className='mt-5'
                  >
                    {generatedDates.map((date: any, index: any) => (
                      <li key={index} className='head-text'>
                        {moment(date).format('DD.MM.YYYY')}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        )}
      </Modal>

      {/* Maintenance Req Link Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={mainReqLinkModal}
        onHide={() => {
          setMainReqLinkModal(false)
          const values = [...fields]
          values[indForMaintenance].serviceRequestId = null
          values[indForMaintenance].requestId = null
          values[indForMaintenance].serviceJobId = null
          values[indForMaintenance].jobId = null
        }}
        backdrop={true}
        size='sm'
      >
        <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Link a Maintenance Request</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => {setMainReqLinkModal(false)
                    const values = [...fields]
                    values[indForMaintenance].serviceRequestId = null
                    values[indForMaintenance].requestId = null
                    values[indForMaintenance].serviceJobId = null
                    values[indForMaintenance].jobId = null
                  }}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' />
              </Col>
            </Row>
          </div>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 pb-4 mx-2'>
            <Col md={5}>
              <label className='head-text fs-2 ' style={{fontWeight: '600'}}>
                Requests
              </label>
            </Col>
            <Col lg={4}>
              {' '}
              <div className='min-w-200px align-items-center ps-3 ms-5 '>
                <Space direction='vertical' size={12}>
                  <RangePicker onChange={handleDateRangeChange} />
                </Space>
                {/* <Datepicker
              value={value}
              onChange={handleValueChange}
               
                // onChange={handleValueChange}
            /> */}
              </div>
            </Col>
            <Col lg={3} md={3} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={(event) => {
                  event.preventDefault()
                  //submitIncomingPayment()
                  setMainReqLinkModal(false)
                }}
                disabled={disableLink ? true : false}
              >
                <img
                  src={linkImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '12px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '}
                {'  '}
                Link to Request
              </button>
            </Col>
          </Row>

          <div className='my-5 py-5' style={{color: '#007a59'}}>
            {maintenanceReqData?.length > 0 &&
              maintenanceReqData.map((ut: any, index: any) => {
                return (
                  <>
                    <div className='d-flex my-5 row '>
                      <div className='col-1'>
                        {radioLinkChecked === index ? (
                          <div
                            style={{
                              height: '16px',
                              width: '16px',
                              backgroundColor: '#384a62',
                              borderRadius: '50%',
                            }}
                            className='mx-5 '
                          ></div>
                        ) : (
                          <div
                            style={{
                              height: '16px',
                              width: '16px',
                              backgroundColor: 'transparent',
                              borderRadius: '50%',
                              border: '1px solid #384a62',
                            }}
                            onClick={() => {
                              setRadioLinkChecked(index)
                              const values = [...fields]
                              values[indForMaintenance].serviceRequestId =
                                ut.service_request?.[0]?._id
                              values[indForMaintenance].requestId = ut.service_request?.[0]?.id
                              values[indForMaintenance].serviceJobId = ut._id
                              values[indForMaintenance].jobId = ut.id

                              setServiceJobId(ut._id)
                              setjobId(ut.id)

                              setServiceRequestId(ut.service_request?.[0]?._id)
                              setRequestId(ut.service_request?.[0]?.id)
                              setDisableLink(false)
                            }}
                            className='mx-5 '
                          ></div>
                        )}
                      </div>
                      {/* <input
                                      className='form-check-input me-5'
                                      type='radio'
                                      // checked={selectedRadio === scg._id}
                                      // onChange={(e: any) => selectSchedule(e, i)}
                                    /> */}
                      <h6 className='m-0 p-0 col-3' style={{fontWeight: '600', color: '#007a59'}}>
                      {ut?.id} {'_'} {ut?.service_request?.[0]?.id}
                      </h6>
                      <h6 className='m-0 p-0 col-3' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.service_request?.[0]?.main_service?.[0]?.name} {' - '}
                        {ut?.service_request?.[0]?.sub_service?.[0]?.name}
                      </h6>
                      <h6 className='m-0 p-0 col-2' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.schedule?.date != null
                          ? moment(ut?.schedule?.date).format('DD.MM.YYYY')
                          : '-'}
                      </h6>
                      <h6 className='m-0 p-0 col-2' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.schedule?.status === 0
                          ? 'Not Started'
                          : ut?.schedule?.status === 1
                          ? 'Started'
                          : ut?.schedule?.status === 2
                          ? 'Pending Tenant Approval'
                          : ut?.schedule?.status === 3
                          ? 'Incomplete'
                          : ut?.schedule?.status === 4
                          ? 'Complete'
                          : 'Cancelled'}
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* LINK Invoice to Receipt */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={receiptLinkModal}
        onHide={() => {
          setReceiptLinkModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        {/* <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Invoice {invoiceNo}</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setReceiptLinkModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                {/* <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' /> */}
        {/* </Col> */}
        {/* </Row> */}
        {/* </div> */}
        {/* </div> */}
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setAmountPaid(0)
                  const values = [...fields]
                  for (let i = 0; i < values?.length; i++) {
                    let y: any =
                      values[i].amount * values[i].qty +
                      ((values[i].amount * values[i].qty) / 100) * values[i].vat
                    values[i].amountPaid = 0
                    values[i].outstanding = y
                  }

                  setReceiptLinkModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-2'>
            <div className='col-md-6'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Invoice {invoiceNo}
              </label>
              <label className='head-text fs-5 d-block' style={{fontWeight: '700'}}>
                Total {totalAED?.toLocaleString('en-US', {minimumFractionDigits: 2})}
              </label>
            </div>

            <div className='col-md-6 align-items-center text-end'>
              <button
                className='btn btn-md fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: 'inherit',
                }}
                onClick={(event) => {
                  if (paidAmount > 0) {
                    totalAmountPaid = 0
                    const values = [...fields]
                    for (let i = 0; i < values?.length; i++) {
                      // console.log(values[i]?.amountPaid)
                      if (values[i]?.amountPaid != undefined)
                        totalAmountPaid =
                          parseFloat(totalAmountPaid) + parseFloat(values[i]?.amountPaid)
                      // console.log(totalAmountPaid)
                    }
                    setAmountPaid(totalAmountPaid)
                    // setAmountPaid(totalAmountPaid)
                    setReceiptLinkModal(false)
                    setReceiptModal(true)
                    // if (invoiceReceiptNo == '') ErrorToast('Please Select one Invoice!')
                    // else setReceiptModal(true)
                  } else {
                    ErrorToast('Amount Paid must be Greater than 0 !!!')
                  }
                }}
                // disabled={disableLink ? true : false}
              >
                Generate Receipt
              </button>
            </div>
          </div>

          <div className='d-flex my-5 row '>
            <h6
              className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Item Name
            </h6>

            <h6
              className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>
            <h6
              className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Amount Paid
            </h6>
            <h6
              className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Outstanding
            </h6>
          </div>

          <div className='my-5 py-3'>
            {fields?.length > 0 &&
              fields.map((field: any, index: any) => {
                return (
                  <>
                    <div
                      className='d-flex py-3 row align-items-center'
                      style={{borderBottom: '2px solid lightgray'}}
                    >
                      <h6 className='m-0 p-0 col-3 head-text px-3'>{field?.item}</h6>

                      <h6 className='m-0 p-0 col-3 head-text px-3'>
                        {field?.isLateItem
                          ? field?.total?.toLocaleString('en-US', {minimumFractionDigits: 2})
                          : (
                              field.amount * field.qty +
                              ((field.amount * field.qty) / 100) * field.vat
                            )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>
                      <h6 className='m-0 p-0 col-3 head-text px-3'>
                        <input
                          type='number'
                          className='form-control form-control-bg input-style mytest'
                          placeholder='Amount Paid'
                          name='item_id'
                          value={field?.amountPaid}
                          onChange={(event: any) => {
                            const values = [...fields]
                            values[index].amountPaid = event.target.value
                            if (values[index]?.isLateItem)
                              values[index].outstanding =
                                values[index]?.amount +
                                (values[index]?.amount / 100) * values[index]?.vat -
                                parseFloat(event.target.value)
                            else
                              values[index].outstanding =
                                values[index]?.amount * values[index]?.qty +
                                ((values[index]?.amount * values[index]?.qty) / 100) *
                                  values[index]?.vat -
                                parseFloat(event.target.value)

                            if (event.target.value.length == 0)
                              values[index].outstanding = values[index].total

                            setFields(values)

                            totalAmountPaid = 0

                            for (let i = 0; i < values?.length; i++) {
                              if (values[i]?.amountPaid != undefined)
                                totalAmountPaid =
                                  parseFloat(totalAmountPaid) + parseFloat(values[i]?.amountPaid)
                            }
                            setAmountPaid(totalAmountPaid)
                          }}
                          onBlur={(event) => {
                            if (field?.paidAmount > field?.amount * field?.qty) {
                              ErrorToast(`Paid Amount should not exceed the Total Amount..`)
                            }
                          }}
                        />
                      </h6>
                      <h6 className='m-0 p-0 col-3 head-text px-3'>
                        {field?.outstanding > 0
                          ? getNegative(field?.outstanding)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : getPositive(field?.outstanding)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                        {/* {field?.outstanding?.toLocaleString('en-US', {minimumFractionDigits: 2})} */}
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* Manual Pdf File */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={uploadInvoiceModal}
        onHide={() => {
          setUploadInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setUploadInvoiceModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='pb-4 row me-2'>
            <div className='col-md-6'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                {isReceipt ? 'Upload Receipt' : 'Upload Invoice'}
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-center my-4 '>
            <form
              id='form-file-upload-invoice'
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{height: '90px'}}
            >
              <input
                type='file'
                multiple={true}
                onChange={(e: any) => {
                  if (isReceipt) uploadReceipt(e)
                  else uploadInvoice(e)
                }}
                id='input-file-upload-invoice'
                ref={inputRef}
                style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
              />
              <label
                id='label-file-upload'
                htmlFor='input-file-upload-invoice'
                className={
                  dragActive
                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                }
                style={{
                  fontSize: '16px',
                  color: 'white',
                  transform: 'translate(-2px, -90px)',
                }}
                ref={inputRef}
              >
                <div
                  style={{
                    height: '90px',
                    width: '280px',
                    border: '2px dashed #384a62',
                    backgroundColor: '#f5f8fa',
                  }}
                  className=''
                >
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                    style={{minWidth: '90px'}}
                  >
                    Drag & drop your document
                  </div>
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                    style={{minWidth: '90px'}}
                  >
                    <div
                      className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                      style={{
                        fontSize: '1rem',
                        paddingLeft: '15px',
                        borderRadius: '7px',
                        backgroundColor: '#35475e',
                        boxShadow: '0px 0px 20px 0px #516176',
                      }}
                    >
                      Browse Files
                    </div>
                  </div>
                </div>
              </label>
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default IncomingPaymentModal

// import moment from 'moment'
import React, {useState, useEffect, useRef} from 'react'
import {DatePicker, Modal} from 'antd'
import {Col, Container, Row} from 'react-bootstrap'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import NewMessage from '../Tenants/NewMessage'
import AssignJob from './AssignJob'
import MediaPopUp from './ApproveMediaComments'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import leftArrow from '../../../img/arrowleft.svg'
import editName from '../../../img/edit.svg'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import Datechange from './DateChangeVisitation'
import editDate from '../../../img/edit.svg'
import Swal from 'sweetalert2'
import crossbtn from '../../../img/cross-button.png'
import sendBack from '../../../img/share.png'
import approve from '../../../img/approve.png'
import sign from '../../../img/sign.png'
import submit from '../../../img/submit.png'
import chat from '../../../img/chat.png'
import calender from '../../../img/calendar.png'
import clock from '../../../img/clock.png'
import check from '../../../img/check.png'
import deleteImg from '../../../img/delete.png'
import {AiFillDelete} from 'react-icons/ai'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import leftarrow from '../../../img/arrowleft.svg'
import {Form, InputGroup, Button, Card} from 'react-bootstrap'
import userAvatar from '../../../img/user.svg'
import {KTSVG} from '../../../_metronic/helpers'

import clsx from 'clsx'
import noData from '../../../img/NoData1.svg'
import io from 'socket.io-client'
// import {KTSVG, toAbsoluteUrl} from '../../helpers'
// import {useLayout} from '../core'
import bell from '../../../img/bell.svg'
import chatt from '../../../img/chat.svg'
import send from '../../../img/sendwhite.svg'
import plus from '../../../img/plus.svg'
import filter from '../../../img/filter.svg'
import closes from '../../../img/close.svg'

import {onMessageListener} from '../../../apiCommon/helpers/firebase/firebase'

import moment, {Moment} from 'moment'

import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
// import AdapterDateFns from "@mui/lab/AdapterDateFns"
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs, {Dayjs} from 'dayjs'
import {AnyARecord} from 'dns'
import {useDispatch, useSelector} from 'react-redux'

import profileImg from '../../../img/user.jpeg'

let user: any = localStorage.getItem('userData')
user = JSON.parse(user)

const socket = io('https://api.propertise.com/room')

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
interface ChatMessage {
  author: string
  message: string
  createdAt: any
}

function ViewScopeDetails() {
  // const navigate = useNavigate()
  const {state} = useLocation()
  const {status} = state
  const [assignModal, setAssignModal] = useState<any>(false)
  const [showMedia, setShowMedia] = useState<any>(false)
  const [serviceData, setServiceData] = useState<any>([])
  const [requestData, setRequestData] = useState<any>([])
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
  const [tenantData, setTenantData] = React.useState<any>()
  const [jobId, setJobId] = React.useState<any>()
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>('')
  const {serviceId, flg} = useParams()
  const [mediaPop, setMediaPop] = useState<any>()
  const [modalData, setModalData] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()
  const [log, setLog] = useState<any>()
  const [onTime, setOnTime] = useState<any>(0)
  const [late, setLate] = useState<any>(0)
  const [serviceJob, setServiceJob] = useState<any>('')

  const [noShow, setNoShow] = useState<any>(0)
  const [chatSubject, setChatSubject] = useState<any>()
  const [chatServiceJobId, setChatServiceJobId] = useState<any>()
  const [tenanttData, setTenanttData] = useState<any>()
  const [serviceJobId, setServiceJobId] = useState<any>()
  const [isKey, setIsKey] = useState<any>(0)
  const [indexToUpdate, setIndexToUpdate] = useState<any>()
  const [scheduleToUpdate, setScheduleToUpdate] = useState<any>({
    selectedDate: '',
    startTime: '',
    endTime: '',
  })

  var handledBy: any

  const format = 'HH:mm'
  // const [schedule, setSchedule] = useState<any>([
  //   {preferScheduleDate: '', preferScheduleTime: ''},
  //   {preferScheduleDate: '', preferScheduleTime: ''},
  // ])

  const [selectedDate, setSelectedDate] = useState<any>()
  var editSelDate: any
  // let x1:any = timeConvert(modalData?.schedule?.[0]?.startTime)
  // let x2: any = timeConvert(modalData?.schedule?.[0]?.endTime)
  // const [startTime, setStartTime] = useState<any>(x1)
  // const [endTime, setEndTime] = useState<any>(x2)

  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  // const today = new Date()

  var editEndValue: any

  const [startTime, setStartTime] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)

  const [start, setStart] = useState<moment.Moment | null>(null)
  const [end, setEnd] = useState<moment.Moment | null>(null)

  const [isDisable, setIsDisable] = useState<boolean>(true)

  const [startValue, setStartValue] = React.useState<Dayjs | null>()
  const [endValue, setEndValue] = React.useState<Dayjs | null>()

  const [startTimeMinute, setstartTimeMinute] = useState<any>(0)
  const [endTimeMinute, setendTimeMinute] = useState<any>(0)

  const now = moment()
  const hours = now.hours()
  const minutes = now.minutes()
  const disabledHours = useState<any>(null)
  const disabledMinutes = useState<any>(null)
  

  // important

  const [selectedTime, setSelectedTime] = useState<any>('12:30')

  const initialTime = dayjs('12:30', 'HH:mm')

  const serviceDetailData = async () => {
    // console.log("service Detail "+ state.status)
    // ViewAll
    if (state.status === 10) {
      const body = {
        page: 1,
        limit: 10,
        serviceRequestId: serviceId,
      }
      await ApiPost(`corporate/service_job/get`, body)
        .then((res) => {
          setServiceData(res?.data?.data?.service_job_data)
          setLog(res?.data?.data?.service_job_data[0].logs)
          setModalData(res?.data?.data?.service_job_data)

          // time statuses
          var temp = res?.data?.data?.service_job_data[0].schedule
          var onTime: any = 0
          var late: any = 0
          var noShow: any = 0
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].status === 1) onTime = onTime + 1
            else if (temp[i].status === 2) late = late + 1
            else if (temp[i].status === 3) noShow = noShow + 1
            else console.log()
          }
          setNoShow(noShow)
          setOnTime(onTime)
          setLate(late)
        })
        .catch((e) => {
          console.log(e)
        })
    } else if (state.status === 11) {
      const body = {
        page: 1,
        limit: 10,
        serviceRequestId: serviceId,
        communityId: window.location.pathname?.split('/')[5],
      }
      await ApiPost(`corporate/service_job/get`, body)
        .then((res) => {
          setServiceData(res?.data?.data?.service_job_data)
          setLog(res?.data?.data?.service_job_data[0].logs)
          setModalData(res?.data?.data?.service_job_data)

          var temp = res?.data?.data?.service_job_data[0].schedule
          // console.log("temppp"+temp)
          // console.log(temp)
          var onTime: any = 0
          var late: any = 0
          var noShow: any = 0
          for (let i = 0; i < temp.length; i++) {
            // console.log("temp "+i)
            // console.log(temp[i].status)
            if (temp[i].status === 1) onTime = onTime + 1
            else if (temp[i].status === 2) late = late + 1
            else if (temp[i].status === 3) noShow = noShow + 1
            else console.log()
          }
          setNoShow(noShow)
          setOnTime(onTime)
          setLate(late)
        })
        .catch((e) => {
          console.log(e)
        })
    } else if (state.status === 12) {
      const body = {
        page: 1,
        limit: 10,
        serviceRequestId: serviceId,
        buildingId: window.location.pathname?.split('/')[5],
      }
      await ApiPost(`corporate/service_job/get`, body)
        .then((res) => {
          setServiceData(res?.data?.data?.service_job_data)
          setLog(res?.data?.data?.service_job_data[0].logs)
          setModalData(res?.data?.data?.service_job_data)

          var temp = res?.data?.data?.service_job_data[0].schedule
          // console.log("temppp"+temp)
          // console.log(temp)
          var onTime: any = 0
          var late: any = 0
          var noShow: any = 0
          for (let i = 0; i < temp.length; i++) {
            // console.log("temp "+i)
            // console.log(temp[i].status)
            if (temp[i].status === 1) onTime = onTime + 1
            else if (temp[i].status === 2) late = late + 1
            else if (temp[i].status === 3) noShow = noShow + 1
            else console.log()
          }
          setNoShow(noShow)
          setOnTime(onTime)
          setLate(late)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // Counter click
    else {
      const body = {
        page: 1,
        limit: 10,
        serviceRequestId: serviceId,
        serviceJobStatus: state.status,
      }
      await ApiPost(`corporate/service_job/get`, body)
        .then((res) => {
          setServiceData(res?.data?.data?.service_job_data)
          setLog(res?.data?.data?.service_job_data[0].logs)
          setModalData(res?.data?.data?.service_job_data)
          var temp = res?.data?.data?.service_job_data[0].schedule
          // console.log("temppp"+temp)
          var onTime: any = 0
          var late: any = 0
          var noShow: any = 0
          for (let i = 0; i < temp.length; i++) {
            // console.log("temp "+i)
            // console.log(temp[i].status)
            if (temp[i].status === 1) onTime = onTime + 1
            else if (temp[i].status === 2) late = late + 1
            else if (temp[i].status === 3) noShow = noShow + 1
            else console.log()
          }
          setNoShow(noShow)
          setOnTime(onTime)
          setLate(late)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  const getTotalMinutes = (totalMinutes: any) => {
    // Calculate the hours and minutes
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    // Create a moment object with the hours and minutes
    const momentTime = moment().hour(hours).minute(minutes)

    // Format the moment object into a string in the hour:minute format
    const formattedTime = momentTime.format('HH:mm')

    return formattedTime
  }
  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${serviceId}`)
      .then((res) => {
        handledBy = res?.data?.data
        console.log(handledBy)
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // cancelJob
  const cancelJob = async (val: any) => {
    const body = {
      id: val,
      serviceJobStatus: 5,
    }
    await ApiPut(`corporate/service_job`, body)
      .then((res) => {
        SuccessToast(res.data.message)
      })
      .catch((e) => {
        console.log(e)
      })
    navigate(`/request-management/service/create-request-service/service-detail/` + serviceId)
  }

  // complete Job
  const completeJob = async (val: any) => {
    const body = {
      id: val,
      serviceJobStatus: 4,
    }
    await ApiPut(`corporate/service_job`, body)
      .then((res) => {
        SuccessToast(res.data.message)
      })
      .catch((e) => {
        console.log(e)
      })
    navigate(`/request-management/service/create-request-service/service-detail/` + serviceId)
  }

  React.useEffect(() => {
    getServiceData()
    serviceDetailData()
  }, [])

  const handleChnage = (e: any) => {
    if (e === 'assign') {
      setAssignModal(true)
    }
  }

  const openModal = async (ids: any) => {
    setJobId(ids)
    setShowMedia(true)

    await ApiGet(`corporate/service_job/${ids}`)
      .then((res) => {
        setMediaPop(res.data.data[0])
      })
      .catch((err) => ErrorToast(err.message))
  }

  const showVisitation = async (value: any, ind: any, check: any, sched: any) => {
    setScheduleToUpdate(sched)
    setModalData(value)
    setIndexToUpdate(ind)
    setIsKey(check)
    setShowCreateAppModal1(true)
  }

  const showEditVisitation = async (value: any, ind: any, check: any, sched: any) => {
    console.log(sched.date)
    setScheduleToUpdate(sched)
    let m: any = sched.date
    setSelectedDate(m)
    editSelDate = m

    console.log(editSelDate)

    let n: any = timeConvert(sched.startTime)
    setStartValue(n)
    setstartTimeMinute(sched.startTime)

    let z: any = timeConvert(sched.endTime)
    editEndValue = z
    setendTimeMinute(sched.endTime)
    // setEndValue(z)

    setModalData(value)

    setIndexToUpdate(ind)
    setIsKey(check)

    setShowEditModal(true)
  }

  const deleteVisitation = async (ind: any) => {
    setIndexToUpdate(ind)

    let previousData = modalData[0].schedule
    let y: any = []
    for (let i = 0; i < previousData.length; i++) {
      if (i !== ind) {
        y[y.length] = previousData[i]
      }
    }

    let body: any = {
      id: modalData[0]._id,
      schedule: y,
    }
    ApiPut(`corporate/service_job`, body)
      .then((res) => {
        serviceDetailData()
        SuccessToast('Visitation deleted successfully!!')
      })
      .catch((err) => {
        console.log('\nerr', err)
      })
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours: any = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes: any = Math.round(minutes)
    var rh, rm

    if (rhours < 10) rh = '0' + rhours
    else rh = rhours

    if (rminutes < 10) rm = '0' + rminutes
    else rm = rminutes

    return rh + ':' + rm
  }

  /************************** Chat ****************************/
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector(
    (state: any) => state.counterReducer.chatSelectedDataList
  )
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [finalIdData, setFinalIdData] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const navigate = useNavigate()
  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)
  
  const [pageLimit, setPageLimit] = useState<any>(false)
  const [page, setPage] = useState<any>(1)


  useEffect(() => {
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  // useEffect(() => {
  //   socket.once('online', handleOnline)
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   return () => {
  //     socket.off('online', handleOnline)
  //     socket.close()
  //   }
  // }, [])
  // const handleOnline = (data: any) => {
  //   console.log('socket Online event received:', data)
  //   // Update state to reflect online status
  // }
  useEffect(() => {
    socket.on('receive_message', (newMessage: any) => {
      console.log('socket newMessage', newMessage)
      console.log('messages', messages)
      setMessages([
        ...messages,
        {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()},
      ])
    })
    socket.on('new_user', (new_user) => {
      console.log('socket new_user', new_user)
    })
  }, [])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    socket.off('online')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])
  // useEffect(() => {
  //   console.log('messages', messages)
  //   socket.on('receive_message', (newMessage: any) => {
  //     console.log('socket newMessage', newMessage)
  //     console.log('socket messages', messages)
  //     setMessages([
  //       ...messages,
  //       {author: newMessage?.author, message: newMessage?.message, createdAt: new Date()},
  //     ])
  //   })
  //   socket.on('new_user', (new_user) => {
  //     console.log('socket new_user', new_user)
  //   })
  //   // Send a message to the server
  //   // Receive messages from the server
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   socket.on('online', (data) => {
  //     console.log('socket data', data)
  //     // io.emit('post', {userId: user._id})
  //   })
  // }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }

  // const [chatId, setChatId] = useState<any>('')
  // const [msgPage, setMsgPage] = useState<any>(1)
  // const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
        fetchMessage(chat._id, 1, [])
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages(res?.data?.data?.chat_message_data)
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err) => console.log('failed: ', err))
  }
  notificationEvent()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let idsToSend: any = []
    for (let i = 0; i < tenanttData.length; i++) {
      idsToSend[i] = tenanttData[i]._id
    }

    const body = {
      subjectTitle: bodyWithSubject.subjectTitle,
      type: 0,
      serviceJobId: chatServiceJobId,
      tenantIds: idsToSend,
    }

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      await ApiPost(`corporate/chat_room`, body)
        .then((res) => {
          console.log('\nres.data.data', res.data.data)
          handleSendMessage({
            roomId: res.data.data?._id,
            message: message,
            senderId: user._id,
            receiverId: tenanttData._id,
            senderType: 0,
            receiverType: 1,
          })
          openMessage(e, 0, {_id: res.data.data?._id})
          // setMessages([...messages, {author, message, createdAt: new Date()}])
          setAuthor('')
          setMessage('')
        })
        .catch((err) => {
          console.log('res_blob', err)
        })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    // let m = new Date(dd).toLocaleString()
    // let x = moment(m.format("DD-MM-YYYY HH:mm"))
    // return x;
     return new Date(dd).toLocaleString("ro-RO",{
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
 
  const getChatList = async () => {
    // await ApiPost(`corporate/chat_room/get`, {
    //   limit: 10,
    //   page: page,
    // })
    //   .then((res) => {
    //     setChatData([...chatData, ...res?.data?.data?.chat_room_data])
    //     setPage(page + 1)
    //     setPageLimit(res?.data?.data?.state?.page_limit)
    //   })
    //   .catch((err) => {
    //     console.log('res_blob', err)
    //   })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 10,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const gotoChatList = async () => {
    const body = {
      limit: 10,
      page: 1,
      serviceJobId: serviceJob,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        setTenanttData(res.data.data.tenant_data)
        setChatData(res?.data?.data?.chat_room_data)
        setShowChat('message')
      })
      .catch()
  }


//  
  const handleUrgency = async (serId: any, e: any) => {
    const body = {
      id: serId,
      jobUrgency: parseInt(e.target.value),
    }
    await ApiPut(`corporate/service_job`, body)
      .then((res) => {
        getServiceData()
        serviceDetailData()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Edit Visitation
  const handleDateChange = (date: any) => {
    setSelectedDate(date.format('YYYY-MM-DD'))
  }

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  const handleStartValueChange = (value: any) => {
    let temp = new Date(value)
    // console.log(temp)

    let isoStr = temp.toISOString()
    // console.log(isoStr)

    let x = temp.toUTCString()
    // console.log(x)
    // console.log(value.$d)
    setStartValue(value)
    // console.log(startValue)
    // setstartTimeMinute(value.hour() * 60 + value.minute())
    setstartTimeMinute(isoStr)
  }

  const handleEndValueChange = (value: any) => {
    let temp = new Date(value)
    // console.log(temp)

    let isoStr = temp.toISOString()
    // console.log(isoStr)

    let x = temp.toUTCString()
    // console.log(x)

    setEndValue(value)
    // setendTimeMinute(value.hour() * 60 + value.minute())
    setendTimeMinute(isoStr)
  }

  const handleOk = () => {
    let previousData = modalData.schedule

    let y = previousData

    y[indexToUpdate] = {
      date: selectedDate,
      startTime: startTimeMinute,
      endTime: endTimeMinute,
      status: 0,
    }

    let body: any = {
      id: modalData._id,
      schedule: y,
    }
    ApiPut(`corporate/service_job`, body)
      .then((res) => {
        serviceDetailData()
        setShowEditModal(false)
      })
      .catch((err) => {
        console.log('\nerr', err)
      })
  }

  // function called on clicking Message tenant Button
  const getChatRoomforServiceJob = async (val: any) => {
    setServiceJob(val)
    // setChatData([])
    console.log(chatData)

    const body = {
      limit: 10,
      page: 1,
      serviceJobId: val,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        if (res.data.data.tenant_data.length === 0) {
          ErrorToast('Currently, This Service Job has no Tenant!!! ')
        } else {
          showMessagePopUp()
          console.log(res.data.data.isNewChat)
          if (res.data.data.isNewChat === true) {
            setShowChat('newMessage')
          }
          setTenanttData(res.data.data.tenant_data)
          setChatData(res?.data?.data?.chat_room_data)
        }
      })
      .catch()
  }


  return (
    <>
      <div className='grid-out px-0 pb-5 container-xxl'>
        <div className='d-flex align-items-center flex-wrap mr-5'>
          <span
            className='rounded-circle'
            style={{cursor: 'pointer'}}
            onClick={() =>
              navigate(
                `/request-management/service/create-request-service/service-detail/` + serviceId
              )
            }
          >
            <img src={leftArrow} style={{stroke: 'red'}} className='me-2' />
          </span>
          <h3 className='page-heading green_color m-0'>View Request</h3>
        </div>
        <div className='card tops p-5 m-0'>
          <Row>
            <Col col={6} className='ms-2'>
              <h6 style={{color: '#a1a5b7'}}>
                Properties: <b className='value_black ms-2'>{serviceData?.length}</b>
              </h6>
            </Col>
            <Col col={6} className='text-end me-2'>
              <h6 style={{color: '#a1a5b7'}}>
                Handled By:
                <b className='value_black ms-2'>
                  {requestData && requestData?.requestType === 1
                    ? requestData?.vendor[0]?.companyName
                    : 'Internal Team'}
                </b>
              </h6>
            </Col>
          </Row>

          {/* <button className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-130px'>
            Service Completion Reports
          </button> */}
        </div>

        {serviceData?.map((ser: any, i: any) => (
          <>
            <div className='card tops p-5 m-0' style={{justifyContent: 'space-between'}}>
              <div
                className='d-flex align-items-center mb-5'
                style={{justifyContent: 'space-between', flexDirection: 'row'}}
              >
                <div className='d-flex gap-5'>
                  {
                 ser?.tenantId &&
               
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                    onClick={() => {
                      // setNewMessageModal(true)
                      SetBodyWithSubject({subjectTitle: ser.id})
                      setChatSubject(ser.id)
                      setChatServiceJobId(ser._id)
                      getChatRoomforServiceJob(ser._id)
                    }}
                  >
                    <img
                      src={chat}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                      }}
                    />
                    Message Tenant
                  </button>
                   }

                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green ms-1 me-3 '
                    onClick={() => openModal(ser._id)}
                  >
                    Media & Comments
                  </button>
                </div>
                <div className='d-flex gap-5'>
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                    onClick={() => completeJob(ser._id)}
                  >
                    <img
                      src={approve}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                      }}
                    />
                    Complete
                  </button>

                  <button
                    className='btn btn-sm fw-bold ms-1 me-3 text-white'
                    style={{backgroundColor: 'rgb(213, 70, 69)'}}
                    onClick={() =>
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> You are about to cancel the entire job, do you wish to proceed?</p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#007a59',
                        confirmButtonText: 'Confirm',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          cancelJob(ser._id)
                        }
                      })
                    }
                  >
                    <img
                      src={crossbtn}
                      style={{
                        height: '22px',
                        width: '22px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                      }}
                    />
                    Cancel Job
                  </button>
                </div>
              </div>
              <div className='grid-out-diff-gap container-xxl'>
                <div className='p-2 px-0 side'>
                  {/* <h1 className='top green_color'>Service Details</h1> */}
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Request ID
                    <div className='mx-1 value_black'>{ser.requestId}</div>
                  </h6>
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Job ID
                    <div className='mx-1 value_black'>{ser.id}</div>
                  </h6>
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Service Name
                    <div className='mx-1 value_black'>
                      {ser?.main_service[0]?.name} {ser?.sub_service[0]?.name || '-'}
                    </div>
                  </h6>

                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Development
                    <div className='mx-1 value_black'>
                      {ser?.community.length > 0 ? 'Community' : 'Building'}
                    </div>
                  </h6>
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    {ser?.community.length > 0 ? 'Cluster' : 'Floor'}
                    <div className='mx-1 value_black'>
                      {ser?.community.length > 0
                        ? ser?.community[0]?.cluster[0]?.name
                        : ser?.building[0]?.floor[0]?.name}
                    </div>
                  </h6>
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Unit
                    <div className='mx-1 value_black'>{ser?.unit[0]?.unitNo || '-'}</div>
                  </h6>
                  <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                    Occupancy
                    <div className='mx-1 value_black'>
                      {ser?.unit[0]?.occupy === 1 ? 'Occupied' : 'Vacant'}
                    </div>
                  </h6>
                </div>
                <div className='p-2 side'>
                  {/* <h1 className='top green_color'>Property Details</h1> */}
                  <h6
                    className='mx-0 text-muted align-items-center'
                    style={{gridTemplateColumns: '1fr 3fr'}}
                  >
                    Assigned To
                    <div className='mx-1 value_black'>
                      {/* <select
                      name='assigned to'
                      aria-label='assigned to'
                      data-control='select2'
                      data-placeholder='date_period'
                      className='form-select form-select form-select-outline'
                      onChange={(e) => handleChnage(e.target.value)}
                    >
                      <option value=''>select</option>
                      <option value='assign'>Assign</option>
                    </select> */}
                      {ser.staff?.[0]?.firstName} {ser.staff?.[0]?.lastName}{' '}
                      <a
                        onClick={() => {
                          setServiceJobId(ser._id)
                          setAssignModal(true)
                        }}
                        className='m-0 ps-2'
                      >
                        <img src={editName} style={{marginTop: '-6px'}} />
                      </a>
                    </div>
                  </h6>
                  <h6
                    className='mx-0 text-muted align-items-center'
                    style={{gridTemplateColumns: '1fr 3fr'}}
                  >
                    Job Urgency
                    <select
                      className='form-select ms-1'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='tenancyStatus'
                      value={ser?.jobUrgency}
                      style={{width: '72%'}}
                      onChange={(e: any) => handleUrgency(ser?._id, e)}
                    >
                      {/* <option disabled selected>
                      URGENCY
                    </option> */}
                      <option value={0}>Standard</option>
                      <option value={1}>Emergency</option>
                    </select>
                  </h6>
                  <h6
                    className='mx-0 text-muted align-items-center'
                    style={{gridTemplateColumns: '1fr 3fr'}}
                  >
                    Status
                    <div className='mx-1 value_black'>
                      {ser?.serviceJobStatus == 0
                        ? 'Not Started'
                        : ser?.serviceJobStatus == 1
                        ? 'In-Progress'
                        : ser?.serviceJobStatus == 2
                        ? 'Complete'
                        : ser?.serviceJobStatus == 3
                        ? 'Cancelled'
                        : ''}
                    </div>
                  </h6>
                  {ser?.serviceJobStatus == 2 && (
                    <h6 className='m-0 text-muted'>
                      Rating
                      <div className='mx-1 value_black'>###</div>
                    </h6>
                  )}
                </div>
                <div className='p-2 side d-flex flex-column shadow br-5 p-5 '>
                  <Row>
                    <Col md={3}>
                      {' '}
                      <h2 className='m-0 p-0 green_color'>Schedule</h2>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={3} className='text-end'>
                      {' '}
                      <h6 className='' style={{color: '#a1a5b7'}}>
                        On Time <b className='value_black ms-2'>{onTime}</b>
                      </h6>{' '}
                    </Col>
                    <Col md={2} className='text-end'>
                      <h6 className='' style={{color: '#a1a5b7'}}>
                        Late <b className='value_black ms-2'>{late}</b>
                      </h6>
                    </Col>
                    <Col md={3} className='text-end'>
                      <h6 className='' style={{color: '#a1a5b7'}}>
                        No Show <b className='value_black ms-2'>{noShow}</b>
                      </h6>
                    </Col>
                  </Row>
                  {/* <div className='d-flex align-items-center justify-content-between'>
                  <h2 className='m-0 p-0 green_color'>Schedule</h2>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h6 className='m-0 text-muted align-items-center'>
                      On Time : <div className='value_black'>1</div>{' '}
                    </h6>
                    <h6 className='m-0 text-muted align-items-center'>
                      Late : <div className='value_black'>0</div>
                    </h6>
                    <h6 className='m-0 text-muted align-items-center'>
                      No Show : <div className='value_black'>1</div>
                    </h6>
                  </div>
                </div> */}
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green my-3 '
                    style={{maxWidth: 'max-content'}}
                    onClick={() => setShowCreateAppModal1(true)}
                  >
                    <img
                      src={calender}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                      }}
                    />
                    Schedule Another Visitation
                  </a>
                  {ser?.schedule?.map((sg: any, i: any) => (
                    <h6 className='m-0 d-flex align-items-center gap-4 my-1'>
                      {sg.status === 0 ? (
                        <img src={clock} style={{height: '16px', width: '16px'}} />
                      ) : (
                        <img src={check} style={{height: '20px', width: '16px'}} />
                      )}
                      {sg?.date ? moment(sg?.date).format('DD.MM.YYYY') : null} {'  '}
                      {sg?.startTime ? '   ' + timeConvert(sg?.startTime) + ' - ' : null}{' '}
                      {sg?.endTime ? timeConvert(sg?.endTime) : null} {/* <a > */}
                      {sg.status === 0 ? (
                        <div className='d-flex'>
                          <img
                            src={editDate}
                            alt=''
                            className='me-2'
                            // onClick={() => showVisitation(ser, i, 1, sg)}
                            onClick={() => showEditVisitation(ser, i, 1, sg)}
                            style={{cursor: 'pointer'}}
                          />
                          <AiFillDelete
                            color='#D54645'
                            fontSize={20}
                            style={{cursor: 'pointer'}}
                            className='me-2'
                            onClick={() =>
                              Swal.fire({
                                html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the visitation, do you wish to proceed?</p>',
                                showConfirmButton: true,
                                confirmButtonColor: '#007a59',
                                confirmButtonText: 'Confirm',
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                              }).then((res) => {
                                if (res.isConfirmed) {
                                  deleteVisitation(i)
                                }
                              })
                            }

                            // onClick={() => deleteVisitation(i)}
                          />
                            {sg?.date && (
                        <>
                          {/* Same Day */}
                          {new Date(sg?.date).getFullYear() === today.getFullYear() &&
                          new Date(sg?.date).getMonth() === today.getMonth() &&
                          new Date(sg?.date).getDate() === today.getDate() ? (
                            <>
                              {currentHours > parseInt(timeConvert(sg?.startTime).split(':')[0]) ? (
                                <p style={{color: 'rgb(213, 70, 69)', marginTop: '2px'}} className='mb-0'>
                                  {' '}
                                  Schedule has Expired
                                </p>
                              ) : (
                                <>
                                  {parseInt(timeConvert(sg?.startTime).split(':')[0]) -
                                    currentHours <=
                                  6 ? (
                                    <p style={{color: 'rgb(213, 70, 69)', marginTop: '2px'}} className='mb-0'>
                                      {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                      Schedule is Approaching
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          ) : // next Day
                          new Date(sg?.date) > today ? (
                            <>
                              {new Date(sg?.date).getDate() === today.getDate() + 1 ? (
                                <>
                                  {24 -
                                    currentHours +
                                    parseInt(timeConvert(sg?.startTime).split(':')[0]) <=
                                    6 && (
                                    <p style={{color: 'rgb(213, 70, 69)', marginTop: '2px'}} className='mb-0'>
                                      Schedule is Approaching
                                    </p>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <></>
                            </>
                          ) : (
                            <p style={{color: 'rgb(213, 70, 69)', marginTop: '2px'}} className='mb-0'>
                              Schedule has expired
                            </p>
                          )}
                        </>
                      )}
                        </div>
                      ) : (
                        <span>
                          {sg.status === 1 ? (
                            <i style={{color: 'green'}}>ON TIME</i>
                          ) : sg.status === 2 ? (
                            <i style={{color: 'orange'}}>LATE</i>
                          ) : (
                            <i style={{color: 'red'}}>NO SHOW</i>
                          )}
                        </span>
                      )}
                    
                      {/* </a> */}
                    </h6>
                  ))}
                </div>
                <div className='grid p-2 side shadow br-5 p-5'>
                  <Row className='mb-3'>
                    <Col md={6}>
                      {' '}
                      <h2 className='m-0 p-0 green_color'>Job Status Log</h2>
                    </Col>
                    <Col md={6} className='text-end'>
                      {' '}
                      <h6 className='' style={{color: '#a1a5b7'}}>
                        Job Incompleted{' '}
                        <b className='value_black ms-2'>{ser?.totalInCompleteTimes}</b>
                      </h6>
                    </Col>
                  </Row>
                  {log &&
                    log?.map((lg: any, i: any) => (
                      <Row>
                        <Col md={5} className='text-start'>
                          <p style={{fontSize: '1.05rem', fontWeight: '500'}}>{lg.message}</p>
                        </Col>
                        <Col md={3} className='text-center'>
                          {lg.user && (
                            <p style={{fontSize: '1.05rem', fontWeight: '500'}}>
                              {lg.user.firstName} {lg.user.lastName}
                            </p>
                          )}
                        </Col>
                        <Col md={4} className='text-end'>
                          <p style={{fontSize: '1.05rem', fontWeight: '500'}}>
                            {lg.createdAt ? moment(lg.createdAt).format('DD/MM/YYYY HH:mm') : null}{' '}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  {/* <Row>
                  <Col md={5} className='text-start'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>
                      Completion Verfication sent
                    </p>
                  </Col>
                  <Col md={3} className='text-center'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>Username</p>
                  </Col>
                  <Col md={4} className='text-end'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>01/01/2023 - 01:01</p>
                  </Col>
                </Row> */}
                  {/* <Row>
                  <Col md={5} className='text-start'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>
                      Completion Verfication sent
                    </p>
                  </Col>
                  <Col md={3} className='text-center'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>Username</p>
                  </Col>
                  <Col md={4} className='text-end'>
                    <p style={{fontSize: '1.05rem', fontWeight: '500'}}>01/01/2023 - 01:01</p>
                  </Col>
                </Row> */}
                  {/* <div className='grid_3_col_1fr mb-3'>
                  <h6 className='m-0 '>Completion Verfication sent</h6>
                  <h6 className='m-0 '>Username</h6>
                  <h6 className='m-0 '>01/01/2023 - 01:01</h6>
                </div> */}
                </div>
              </div>
              {/* <div
              className='d-flex align-items-center'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            ></div>
            <div
              className='d-flex align-items-center'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            ></div> */}
            </div>
            {/* Message modal */}
            <div
        className={showMessageCard ? 'message' : 'd-none'}
        style={{width: '400px', height: '600px '}}
      >
        <div
          className='card m-0 p-0 br-10'
          style={{
            overflow: 'hidden',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{justifyContent: 'space-between'}}
          >
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5'>
            {showChat == 'chat' ? (
              <>
                <div
                  className='symbol-group symbol-hover py-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  <div className='symbol-group symbol-hover mx-5'>
                    <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoChatList()}
                      style={{cursor: 'pointer'}}
                    />
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                          <img alt='' src={profileImg}></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a
                      href='#'
                      className='symbol symbol-45px symbol-circle'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : <>
                   {tenanttData &&
                              tenanttData.map((tnt: any) => (
                                <h4 className='m-5 '>
                                  {tnt.firstName} {tnt.lastName}
                                </h4>
                              ))}</>}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    style={{backgroundColor: 'white'}}
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  />
                </h6>
                <div
                  style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}}
                  ref={bottomRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                              backgroundColor: 'transparent',
                              boxShadow: 'none' 
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p className='p-0 m-0 small_font' style={{paddingRight: '20px !important'}}>
                              {/* {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName} */}
                            </p>
                            <div className='px-3 pt-0'>
                              <h4 className='m-0 p-0 py-2'>{msg.message}</h4> 
                            </div>
                          </Card.Body>
                          <Card.Footer   className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'p-0 text-start'
                                : 'p-0 text-end'
                            } style={{backgroundColor: 'transparent', borderTop: '0px'}}> <span className='m-0 small_font' style={{fontWeight: '400 !important'}}>{(dateTotime(msg?.createdAt))}</span></Card.Footer>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0'>
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input
                  type='text'
                  placeholder='Search'
                  className='form form-control m-0 msg_search'
                 
                />
                <img
                  src={filter}
                  alt=''
                  width='24px'
                  className='filter'
                  onClick={() => setShowFilterCard(!showFilterCard)}
                />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  {/* <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li> */}
                </ul>
                <div
                  className='d-flex align-items-center my-5'
                  style={{justifyContent: 'space-between'}}
                >
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  {/* <div className='d-flex gap-3'>
                    <p className='m-0 cus-line' onClick={viewall}>
                      View All
                    </p>
                  </div> */}
                </div>
                <ul
                  className='notification min_height p-0 m-0'
                  ref={myDivRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg}></img>
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a
                                href='#'
                                className='symbol symbol-45px symbol-circle'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_view_users'
                              >
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            {/* <img alt='Pic' src='media/avatars/300-23.jpg' /> */}
                             <img alt='' src={profileImg}></img>
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey'>
                              {/* {moment(chat.createdAt).format('HH:MM')} */}
                              {timeConvert(chat?.createdAt)}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div
                  className='symbol-group symbol-hover p-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover mx-5'>
                        <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el.firstName} {el.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImg}/>
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a
                          href='#'
                          className='symbol symbol-40px symbol-circle'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_view_users'
                        >
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center ' >
                      <div
                  className='symbol-group symbol-hover p-5 px-0 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                   <div className='symbol-group symbol-hover me-5 ms-0'>
                      <img
                        src={leftarrow}
                        width='20px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      />
                      </div>
                      {tenanttData &&
                              tenanttData.map((tnt: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {tnt.firstName} {tnt.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImg}/>
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}
                      </div>
                     
                      <h6 className='m-0 p-0'>
                        {/* <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setShowChat('addNew')
                          }}
                        >
                          Select Recipient
                        </a> */}
                          {tenanttData &&
                              tenanttData.map((tnt: any) => (
                                <h4 className='m-5 '>
                                  {tnt.firstName} {tnt.lastName}
                                </h4>
                              ))}
                      </h6>
                    </div>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={bodyWithSubject.subjectTitle}
                    onChange={(e: any) => changeSubject(e)}
                  />
                </h6>
                <div
                  style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}}
                  ref={bottomRef}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any, index: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p className='p-0 m-0 small_font' style={{paddingRight: '20px !important'}}>
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0'>
                              <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div
                  style={{position: 'absolute', bottom: 25, width: '90%'}}
                  className='px-0 mx-auto'
                >
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'All' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'All' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Internal' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Internal' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Tenants' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Tenants' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('all')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
          </>
        ))}
      </div>

      <AssignJob
        show={assignModal}
        handleClose={() => {
          setAssignModal(false)
        }}
        handleChnage={handleChnage}
        serviceData={serviceData}
        getServiceData={getServiceData}
        serviceJobId={serviceJobId}
        serviceDetailData={serviceDetailData}
      />

      <MediaPopUp
        unitNo={unitNo}
        show={showMedia}
        handleClose={() => {
          setShowMedia(false)
        }}
        mediaPop={mediaPop}
        propertyList={serviceData}
        id={jobId}
      />

      <NewMessage
        show={newMessageModal}
        tenantdata={tenantData}
        getMessageList={''}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      />
      <Datechange
        show={showCreateAppModal1}
        scheduleToUpdate={scheduleToUpdate}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        serviceRequestData={serviceDetailData}
        modalData={modalData}
        isKey={isKey}
        indexToUpdate={indexToUpdate}
      />

      {/* Edit Modal */}
      <Modal
        title='Select Date and Times'
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        onOk={handleOk}
        centered
        footer={[
          <button
            type='button'
            className='btn btn-lg btn-light-primary me-3'
            onClick={() => setShowEditModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-3 me-1 ' />{' '}
            Cancel
          </button>,

          <button
            onClick={handleOk}
            className='btn btn-lg btn-primary btn-green'
            // disabled={isDisable || selectedDate === null}
          >
            Submit
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
          </button>,
        ]}
      >
        <h6 className='text-muted align-items-center justify-content-center'>
          Date
          <DatePicker
            defaultValue={moment(new Date(scheduleToUpdate.date), 'YYYY-MM-DD')}
            disabledDate={disabledDate}
            onChange={handleDateChange}
          />
        </h6>

        <h6 className='text-muted align-items-center justify-content-center'>
          Time (From)
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker
                ampm={false}
                defaultValue={startValue}
                value={dayjs(timeConvert(scheduleToUpdate.startTime), 'HH:mm')}
                onChange={(newValue) => handleStartValueChange(newValue)}
                minTime={
                  new Date(selectedDate).getFullYear() === today.getFullYear() &&
                  new Date(selectedDate).getMonth() === today.getMonth() &&
                  new Date(selectedDate).getDate() === today.getDate()
                    ? dayjs(currentTimee)
                    : ''
                }
                maxTime={endValue}
              />
            </DemoContainer>
          </LocalizationProvider>
        </h6>

        <h6 className='text-muted align-items-center justify-content-center'>
          Time (To)
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker
                ampm={false}
                // minTime={startValue}
                defaultValue={editEndValue}
                value={dayjs(timeConvert(scheduleToUpdate.endTime), 'HH:mm')}
                disablePast={
                  new Date(selectedDate).getFullYear() === today.getFullYear() &&
                  new Date(selectedDate).getMonth() === today.getMonth() &&
                  new Date(selectedDate).getDate() === today.getDate()
                    ? true
                    : false
                }
                minTime={dayjs(new Date(scheduleToUpdate.startTime))}
                onChange={(newValue) => handleEndValueChange(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </h6>
      </Modal>
    </>
  )
}

export default ViewScopeDetails

import React, { useState } from 'react'
import ApexCharts from 'react-apexcharts'

interface OccupancyChartProps {
  chartData: DataPoint[]
}

interface DataPoint {
  time: string
  overall: {
    percentage: number
    value: number
  }
  upcoming: {
    percentage: number
    value: number
  }
  overdue: {
    percentage: number
    value: number
  }
  complete: {
    percentage: number
    value: number
  }
}

export const IncomeChartMonthly: React.FC<OccupancyChartProps> = ({ chartData }) => {
  const seriesData = {
    overall: [] as number[],
    upcoming: [] as number[],
    overdue: [] as number[],
    complete: [] as number[],
  }

  const [visibleSeries, setVisibleSeries] = useState({
    // overall: true,
    upcoming: true,
    overdue: true,
    complete: true,
  });

  const seriesColors = {
    overall: '#39394c',
    upcoming: '#0d9e91',
    overdue: '#D54645',
    complete: '#146c6a',
  };

  const handleCheckboxChange = (seriesName: keyof typeof visibleSeries) => {
    setVisibleSeries(prevState => ({
      ...prevState,
      [seriesName]: !prevState[seriesName],
    }));
  };

  const categories: string[] = chartData?.map((dataPoint) => dataPoint?.time)
  chartData?.forEach((dataPoint) => {
    seriesData?.overall?.push(dataPoint?.overall?.percentage)
    seriesData?.upcoming?.push(dataPoint?.upcoming?.percentage)
    seriesData?.overdue?.push(dataPoint?.overdue?.percentage)
    seriesData?.complete?.push(dataPoint?.complete?.percentage)
  })

  const maxPercentage = Math?.max(
    ...seriesData.overall,
    ...seriesData.upcoming,
    ...seriesData.overdue,
    ...seriesData.complete
  );

  const yAxisMax = Math?.ceil(maxPercentage / 10) * 10

  const series: any = [
    {
      name: 'Overall',
      data: seriesData.overall,
      stack: 'background', // Using a separate stack group for overall
      color: seriesColors.overall,
      fillOpacity: 0.2, // Make overall background semi-transparent
    },
    {
      name: 'Upcoming',
      data: seriesData.upcoming,
      stack: 'details', // Stack group for detailed series
      color: seriesColors.upcoming,
    },
    {
      name: 'Overdue',
      data: seriesData.overdue,
      stack: 'details', // Stack group for detailed series
      color: seriesColors.overdue,
    },
    {
      name: 'Complete',
      data: seriesData.complete,
      stack: 'details', // Stack group for detailed series
      color: seriesColors.complete,
    },
  ].filter(s => visibleSeries[s.name.toLowerCase() as keyof typeof visibleSeries]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40px',
      },
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right',
      markers: {
        radius: 30
      },
      offsetY: 0,
      offsetX: 0,
      itemMargin: {
        horizontal: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
    },
    title: {
      text: 'Income (AED)',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#666',
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666',
        },
        offsetY: -20, // Move the title to the top of the y-axis
      },
      labels: {
        formatter: (value: number) => `${value}%`,
      },
      min: 0,
      max: yAxisMax == 0 ? 25 : yAxisMax,
      tickAmount: yAxisMax == 0 ? 5 : yAxisMax > 50 ? yAxisMax / 10 : yAxisMax / 5,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    tooltip: {
      x: {
        format: 'MMM yy',
        show: true,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint :any = chartData?.[dataPointIndex]
        const date = new Date(categories?.[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        //   day: 'numeric',
        })
        const tooltipItems = [
          { name: 'overall', label: 'Overall', color: seriesColors?.overall },
          { name: 'upcoming', label: 'Upcoming', color: seriesColors?.upcoming },
          { name: 'overdue', label: 'Overdue', color: seriesColors?.overdue },
          { name: 'complete', label: 'Complete', color: seriesColors?.complete },
        ]

        return (
          `<div class="apexcharts-tooltip-custom2">` +
          `<div style="text-align:center;" class="text-decoration-underline">${date}</div>` +
          `<div style="display: flex; justify-content: space-between;">` +
          `<div>` +
          tooltipItems?.map((item:any) => {
            return(
            `<div><span style="color: ${item?.name == 'overall' ? '#39394c' : item?.color}">&#9679;</span><span class="xyz-3" style="font-weight:bold">${Number?.isInteger(dataPoint[item.name].percentage) ? dataPoint[item.name].percentage : dataPoint[item.name].percentage?.toFixed(1)}% </span>${dataPoint[item.name].value.toLocaleString('en-US')}</div>`
            )

            if (visibleSeries[item.name as keyof typeof visibleSeries]) {
              return (
                `<div><span style="color: ${item?.name == 'overall' ? '#39394c' : item.color}">&#9679;</span><span class="xyz-3" style="font-weight:bold">${Number?.isInteger(dataPoint[item.name].percentage) ? dataPoint[item.name].percentage : dataPoint[item.name].percentage?.toFixed(1)}% </span>${dataPoint[item.name].value.toLocaleString('en-US')}</div>`
              )
            }
            return ''
          }).join('') +
          `</div>` +
          `</div>` +
          `</div>`
        )
      },
    },
  }

  // Include CSS to style the tooltip as desired
  const styles = `
    .apexcharts-tooltip-custom2 {
      padding: 10px;
      background: #fff;
      border: 1px solid #ccc;
    }
    .apexcharts-tooltip-custom2 span {
      margin: 5px 3px;
    }
  `
  const styleSheet = document.createElement('style')
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)

  return (
    <div className="chart-container">
      <div className="legend-container d-flex w-100">
        <div className=" d-flex align-items ms-auto">
        <label  style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
            
              <span style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: '#39394c',
                marginRight: '5px'
              }}></span>
              {'Overall'}
            </label>

          {Object?.keys(visibleSeries)?.map((seriesName, index) => (
            <label key={seriesName} style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
             <input
                type="checkbox" className="form-check-input me-2"
                checked={visibleSeries[seriesName as keyof typeof visibleSeries]}
                onChange={() => handleCheckboxChange(seriesName as keyof typeof visibleSeries)}
              />
              <span style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: seriesColors[seriesName as keyof typeof seriesColors],
                marginRight: '5px'
              }}></span>
              {seriesName.charAt(0).toUpperCase() + seriesName.slice(1)}
            </label>
          ))}
        </div>
      </div>
      <ApexCharts options={options} series={series} type='bar' height={350} />
    </div>
  )
}

export default IncomeChartMonthly

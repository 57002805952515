import {DatePicker, TimePicker} from 'antd'
import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'

import {Col, Row, OverlayTrigger, Tooltip} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate, useParams} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaPopUp from './MediaPopUp'
import noData from '../../../img/NoData1.svg'
import leftArrow from '../../../img/arrowleft.svg'
import editDate from '../../../img/edit.svg'
import {useDispatch, useSelector} from 'react-redux'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'

import {setCreateNewService} from '../../../redux/counterSlice'
import Datechange from './Datechange'

import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import './style.scss'
import {useForm} from 'react-hook-form'
import {Controller} from 'react-hook-form'
import vendorList from './VendorList'
import plus from '../../../img/plus.svg'
import saveAllImg from '../../../img/save-dark-green.png'

import submitAllImg from '../../../img/Submit-dark-gray.png'

import saveImg from '../../../img/Save-White.png'
import submitImg from '../../../img/Submit-White.png'

import deleteImg from '../../../img/delete-White.png'

import extGray from '../../../img/External-Request-Icon-(Gray).svg'

import {AiOutlineQuestionCircle, AiOutlineUser} from 'react-icons/ai'
import {TeamOutlined} from '@ant-design/icons'
import extImg from '../../../img/External-Request-Icon-green.png'
import {AiFillDelete} from 'react-icons/ai'
import Swal from 'sweetalert2'
// import scheduleTimeLeft from './ScheduleTimeLeft'

interface CreateServiceProps {
  index?: number
  updateParent: (newValue: number) => void
  updateSubmitAll: (newValue: number) => void
}

interface FormData {
  subservice: string
  internalteam: string
  comment: string
  subNewServiceList: string
  internalcomment: string
}

const CreateService = (props: CreateServiceProps) => {
  const {index, updateParent, updateSubmitAll} = props
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const {flg}: any = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({})
  const [requestData, setRequestData] = useState<any>()
  const [mainService, setMainService] = useState<any>()
  const [subService, setSubService] = useState<any>('')
  const [requestTab, setRequestTab] = useState<any>([1])
  const [activeTab, setActiveTab] = useState<any>(1)
  const [time, setTime] = useState<any>()
  const [staffData, setStaffData] = useState<any>()
  const [extStaffData, setExtStaffData] = useState<any>('')

  const [servicedata, setServicedata] = useState<any>()
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [propertyListValidate, setPropertyListValidate] = useState<boolean>(false)
  const [isRequested, setIsRequested] = useState<boolean>(false)

  const currenttab: any = localStorage.getItem('currentTab')
  const [date, setDate] = useState<any>()
  const totalTab: any = localStorage.getItem('totalTab')
  const [requestUrgency, setrequestUrgency]: any = useState(0)
  const userData: any = localStorage.getItem('userData')
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [page, setPage] = React.useState<any>(1)
  const [propertyList, setPropertyList] = React.useState<any>([])
  const [jobId, setJobId] = useState<any>()
  const [mediaPop, setMediaPop] = useState<any>()
  const [modalData, setModalData] = useState<any>()

  const [saveButton, setSaveButton] = useState<any>('Save')
  const [saveStatuss, setSaveStatus] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()
  const [isSave, setIsSave] = useState<any>(0)

  const [intCommentVal, setIntCommentVal] = useState<any>('')
  const [extCommentVal, setExtCommentVal] = useState<any>('')
  const [intExtCommentVal, setIntExtCommentVal] = useState<any>('')

  const [intAssigneVal, setIntAssigneeVal] = useState<any>('')
  const [intExtAssigneVal, setIntExtAssigneeVal] = useState<any>('')

  const [isMainService, setIsMainService] = useState<boolean>(false)
  const [isSubService, setIsSubService] = useState<boolean>(false)
  const [intAssignee, setIntAssignee] = useState<boolean>(false)
  const [intComment, setIntComment] = useState<boolean>(false)
  const [extAssignee, setExtAssignee] = useState<boolean>(false)
  const [extComment, setExtComment] = useState<boolean>(false)
  const [intExtAssignee, setIntExtAssignee] = useState<boolean>(false)
  const [intExtComment, setIntExtComment] = useState<boolean>(false)

  const [pError, setPError] = useState<any>('')
  const [sdError, setSdError] = useState<any>('')
  const [spError, setSpError] = useState<any>('')
  const [spIntError, setSpIntError] = useState<any>('')
  const [intError, setIntError] = useState<any>('')

  const [devplmnt, setdevplmnt] = useState<any>(0)
  const [totalUnits, setTotalUnits] = useState<any>(0)
  const [totalJobs, setTotalJobs] = useState<any>(0)
  const [developmentData, setDevelopmentData] = useState<any>([])
  const [propertyDevelopmentSummary, setPropertyDevelopmentSummary] = useState<any>()

  const myRef: any = useRef()

  let reqUrg: any = 0

  var today: any = new Date()

  // console.log(today.format('YYYY-MM-DD'))

  var timeNow: any = today.getHours() + ':' + today.getMinutes()

  var currentTime: any = moment()

  var time_start: any = new Date()
  var time_end: any = new Date()
  var value_start: any = '06:00:00'.split(':')
  var value_end: any = '23:00:00'.split(':')

  time_start.setHours(value_start[0], value_start[1], value_start[2], 0)
  time_end.setHours(value_end[0], value_end[1], value_end[2], 0)

  let final = time_end - time_start // millisecond

  // console.log(moment('May 25, 2023 19:20:00').diff(currentTime, 'hours'))

  // const [isJobSchedule, setIsJobSchedule] = useState<boolean>(false)

  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  var isJobSchedule: boolean = false

  const [schedule, setSchedule] = useState<any>([
    {preferScheduleDate: '', preferScheduleTime: ''},
    {preferScheduleDate: '', preferScheduleTime: ''},
  ])

  // const requestType = localStorage.getItem('requestType')
  const [requestType, setRequestType] = useState<number>(2)

  const [vendorList, setVendorList] = useState<any>()

  const options = [
    {label: 'Internal', value: '2'},
    {label: 'External', value: '1'},
  ]

  const data = JSON.parse(userData)

  let requestServiceId: any = localStorage.getItem('activeTab')
  let requestorType: any = localStorage.getItem('requestorType')
  const dispatch = useDispatch()
  const createNewService = useSelector((state: any) => state.counterReducer.createNewService)

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  // const renderTooltip = (props: any) => (
  //   <Tooltip id='button-tooltip' {...props}>
  //     Delegates your request to your internal aintenance team
  //   </Tooltip>
  // )

  useEffect(() => {
    // const currentTime: any = new Date()
    // const scheduleTime: any = new Date('2023-05-26T10:20:00.000Z')

    // const timeDifference = scheduleTime - currentTime
    // console.log('timeDifference ' + timeDifference)
    // console.log()

    // let timeLeft: any
    // if (timeDifference > 0) {
    //   const hours = Math.floor(timeDifference / (1000 * 60 * 60))
    //   const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    //   const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

    //   timeLeft = `${hours}:${minutes}:${seconds}`
    // } else {
    //   timeLeft = 'Schedule has already passed'
    // }

    // console.log('timelefttttt')
    // console.log(timeLeft)

    //     const scheduledTime = '2023-05-26T13:33:00.000Z';
    //     // const remainingHours = scheduleTimeLeft(scheduledTime);
    //     var remainingHours: any
    //     const currentTime = new Date().getTime();
    //     const scheduled = new Date(scheduledTime).getTime();
    //     const difference = scheduled - currentTime;

    //     if (difference <= 0) {
    //      remainingHours = null; // The scheduled time has already passed
    //     }

    //     const hoursLeft = Math.floor(difference / (1000 * 60 * 60)) - 4;
    //     remainingHours = hoursLeft

    //     if (remainingHours === null) {
    //       // clearInterval(interval);
    //       return;
    //     }

    // console.log("remaining hours" + remainingHours)
    //     if (remainingHours <= 6) {
    //       // Display a message or perform any other action when time is 6 hours or less
    //       console.log('Schedule is approaching');
    //     }
    //     else{
    //       console.log('Schedule is not approaching');

    //     }

    let firstTime: any = localStorage.getItem('firstTime')
    if (flg == 1) {
      if (requestServiceId) {
        getSummary()
        getServiceJob()
        serviceRequestData()
        getRequestDataById()
      }
    }
    if (createNewService) {
      localStorage.setItem('requestType', '' + requestType + '')
      newServiceRequest()
      // dispatch(setCreateNewService(false))
    } else {
      getRequestDataById()
      serviceRequestData()
      getServiceJob()
      getSummary()
    }
  }, [])

  const removeTab = async (item: any) => {
    const secondIndex = requestTab.findIndex((data: any) => data === item)
    requestTab.splice(secondIndex, 1)
    if (requestTab.length === 0) {
      setRequestTab([1])
      setActiveTab(1)
      localStorage.setItem('totalTab', '1')
    } else {
      setActiveTab(requestTab[0])
      localStorage.setItem('totalTab', requestTab[requestTab.length - 1])
      localStorage.removeItem(activeTab)
    }
    if (requestServiceId) {
      await ApiDelete(`corporate/service_request/${requestServiceId}`)
        .then((res) => {
          localStorage.removeItem(activeTab)
          localStorage.setItem('currenttab', activeTab)
          SuccessToast(res?.data?.message)
          setRequestData({comment: ''})
          setMainService({name: ''})
          setSubService({name: ''})
          setStaffData({name: ''})
          setDate({preferScheduleDate: ''})
          setTime({preferScheduleTime: ''})
          setPropertyList([])
          setServicedata('')
          setrequestUrgency('')
        })
        .catch((e) => {
          console.log(e)
        })
      // getRequestDataById()
      navigate(`/request-management/service`)
    }
  }
  const getTotalMinutes = (totalMinutes: any) => {
    // Calculate the hours and minutes
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    // Create a moment object with the hours and minutes
    const momentTime = moment().hour(hours).minute(minutes)

    // Format the moment object into a string in the hour:minute format
    const formattedTime = momentTime.format('HH:mm')

    return formattedTime
  }
  const getRequestDataById = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    await ApiGet(`corporate/service_request/${temp}`)
      .then((res) => {
        if (res?.data?.data[0]?.requestType === 2) {
          // res?.data?.data[0]?.schedule.forEach((element: any) => {
          //   Object.assign(element, {
          //     time: getTotalMinutes(element?.preferScheduleTime),
          //     date: new Date(element?.preferScheduleDate),
          //   })
          // })
          setRequestData(res?.data?.data[0])
          setSchedule(res?.data?.data[0]?.schedule)
          setMainService(res?.data?.data[0]?.main_service[0])
          setSubService(res?.data?.data[0]?.sub_service[0])
          setStaffData(res?.data?.data[0]?.staff[0])
          setExtStaffData(res?.data?.data[0]?.staff[0])
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          setDate(res?.data?.data[0]?.schedule)
          setTime(res?.data?.data[0]?.schedule)
          localStorage.setItem('requestType', res?.data?.data[0]?.requestType)
          setRequestType(res?.data?.data[0]?.requestType)
          // console.log('RequestTypeee ' + requestType)
          // setSaveStatus(res?.data?.data[0]?.saveStatus)
          setSaveStatus(localStorage.getItem('isSave' + activeTab))
          reqUrg = res?.data?.data[0]?.requestUrgency

          var mainServ = res?.data?.data[0]?.main_service[0]
          var subServ = res?.data?.data[0]?.sub_service[0]
          var staff = res?.data?.data[0]?.staff[0]
          var intC

          // console.log('mainServ ' + mainServ)
          // console.log('subServ ' + subServ)
          // console.log('staff ' + staff)
          // console.log('intComment ' + intCommentVal)

          var temp = localStorage.getItem('serviceRequestId' + requestServiceId)
          if (temp !== null) {
            var x4 = localStorage.getItem('internalComment' + temp)
            if (x4 !== null) {
              setIntCommentVal(x4)
              intC = x4
            } else {
              setIntCommentVal('')
              intC = ''
            }
          }
          var temp1 = localStorage.getItem('fromSubmitAll')
          if (temp1 !== null) {
            if (mainServ === undefined) setIsMainService(true)
            else setIsMainService(false)
            if (subServ === undefined) setIsSubService(true)
            else setIsSubService(false)

            if (staff === undefined) setIntAssignee(true)
            else setIntAssignee(false)

            if (intC === '') setIntComment(true)
            else setIntComment(false)

            //  Service Details Card
            if (mainServ === undefined || subServ === undefined) {
              setSdError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setSdError('')
              }, 8000)
            }

            //  Internal Card
            if (staff === undefined || intC === '') {
              setIntError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setIntError('')
              }, 8000)
            }

            let isProp = localStorage.getItem('isPropertySelected_' + temp)
            if (isProp === null) {
              setPError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setPError('')
              }, 15000)
            } else {
              var isSchedule: any
              const body = {
                page: 1,
                limit: 10,
                serviceRequestId: temp,
              }
              ApiPost(`corporate/service_job/get`, body)
                .then((res) => {
                  if (res.data.data.service_job_data !== '') {
                    let totalProp = res?.data?.data?.service_job_data

                    for (let i = 0; i < totalProp.length; i++) {
                      if (
                        totalProp[i]?.schedule[0]?.date === null ||
                        totalProp[i]?.schedule[0]?.startTime === null ||
                        totalProp[i]?.schedule[0]?.endTime === null
                      ) {
                        // console.log(totalProp[i]?.schedule[0]?.date)
                        // console.log(totalProp[i]?.schedule[0]?.startTime)
                        // console.log(totalProp[i]?.schedule[0]?.endTime)

                        isSchedule = 2
                        i = totalProp.lengt
                      }
                    }
                    if (isSchedule === 2) {
                      setPError('1px solid red')
                      // myRef.current.scrollIntoView({behavior: 'smooth'})
                      setTimeout(() => {
                        setPError('')
                      }, 8000)
                    }
                  }
                })
                .catch()
            }

            // localStorage.removeItem('fromSubmitAll')
          }
        }

        if (res?.data?.data[0]?.requestType === 1) {
          // res?.data?.data[0]?.schedule.forEach((element: any) => {
          //   Object.assign(element, {
          //     time: getTotalMinutes(element?.preferScheduleTime),
          //     date: new Date(element?.preferScheduleDate),
          //   })
          // })

          setRequestData(res?.data?.data[0])
          setSchedule(res?.data?.data[0]?.schedule)

          setMainService(res?.data?.data[0]?.main_service[0])
          setSubService(res?.data?.data[0]?.sub_service[0])
          setStaffData(res?.data?.data[0]?.staff[0])
          setExtStaffData(res?.data?.data[0]?.staff[0])
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)

          setDate(res?.data?.data[0]?.schedule)
          setTime(res?.data?.data[0]?.schedule)
          setVendorList(res?.data?.data[0]?.vendor[0])
          localStorage.setItem('requestType', res?.data?.data[0]?.requestType)
          setRequestType(res?.data?.data[0]?.requestType)
          setSaveStatus(res?.data?.data[0]?.saveStatus)
          reqUrg = res?.data?.data[0]?.requestUrgency

          var mainServ = res?.data?.data[0]?.main_service[0]
          var subServ = res?.data?.data[0]?.sub_service[0]
          var staff = res?.data?.data[0]?.staff[0]
          var extC, intExtC
          var vendor = res?.data?.data[0]?.vendor[0]
          var extStaff = res?.data?.data[0]?.staff[0]

          // console.log('mainServ ' + mainServ)
          // console.log('subServ ' + subServ)
          // console.log('staff ' + staff)
          // console.log('intComment ' + intCommentVal)

          var temp = localStorage.getItem('serviceRequestId' + requestServiceId)
          if (temp !== null) {
            var x3 = localStorage.getItem('externalComment' + temp)
            if (x3 !== null) {
              setExtCommentVal(x3)
              extC = x3
            } else {
              setExtCommentVal('')
              extC = ''
            }

            var x5 = localStorage.getItem('intExtComment' + temp)
            if (x5 !== null) {
              setIntExtCommentVal(x5)
              intExtC = x5
            } else {
              intExtC = ''
            }
          }
          var temp1 = localStorage.getItem('fromSubmitAll')
          if (temp1 !== null) {
            if (mainServ === undefined) setIsMainService(true)
            else setIsMainService(false)
            if (subServ === undefined) setIsSubService(true)
            else setIsSubService(false)

            if (vendor === undefined) setExtAssignee(true)
            else setExtAssignee(false)

            if (extC === '') setExtComment(true)
            else setExtComment(false)

            if (extStaff === undefined) setIntExtAssignee(true)
            else setIntExtAssignee(false)

            if (intExtC === '') setIntExtComment(true)
            else setIntExtComment(false)

            //  Service Details Card
            if (mainServ === undefined || subServ === undefined) {
              setSdError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setSdError('')
              }, 8000)
            }

            //  ServiceProvider Card
            if (vendor === undefined || extC === '') {
              setSpError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setSpError('')
              }, 8000)
            }

            //  Internal Card
            if (extStaff === undefined || intExtC === '') {
              setSpIntError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setSpIntError('')
              }, 8000)
            }

            let isProp = localStorage.getItem('isPropertySelected_' + temp)
            if (isProp === null) {
              setPError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setPError('')
              }, 15000)
            } else {
              var isSchedule: any
              const body = {
                page: 1,
                limit: 10,
                serviceRequestId: temp,
              }
              ApiPost(`corporate/service_job/get`, body)
                .then((res) => {
                  if (res.data.data.service_job_data !== '') {
                    let totalProp = res?.data?.data?.service_job_data

                    for (let i = 0; i < totalProp.length; i++) {
                      if (
                        totalProp[i]?.schedule[0]?.date === null ||
                        totalProp[i]?.schedule[0]?.startTime === null ||
                        totalProp[i]?.schedule[0]?.endTime === null
                      ) {
                        // console.log(totalProp[i]?.schedule[0]?.date)
                        // console.log(totalProp[i]?.schedule[0]?.startTime)
                        // console.log(totalProp[i]?.schedule[0]?.endTime)

                        isSchedule = 2
                        i = totalProp.lengt
                      }
                    }
                    if (isSchedule === 2) {
                      setPError('1px solid red')
                      // myRef.current.scrollIntoView({behavior: 'smooth'})
                      setTimeout(() => {
                        setPError('')
                      }, 8000)
                    }
                  }
                })
                .catch()
            }

            // localStorage.removeItem('fromSubmitAll')
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceJob = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    // console.log(temp)
    const body = {
      serviceRequestId: temp,
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
        setPropertyList(res?.data?.data?.service_development_list)
        setdevplmnt(res?.data?.data?.total_building + res?.data?.data?.total_community)
        setTotalUnits(res?.data?.data?.total_units)
        setTotalJobs(res?.data?.data?.service_development_list?.length)
        // setDevelopmentData(res?.data?.data?.service_development_list)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getSummary = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    const body = {
      serviceRequestId: temp,
    }
    await ApiPost(`corporate/service_request/select_propertise/summery`, body)
      .then((res) => {
        setDevelopmentData(res?.data?.data?.developmentList)
        setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
        // console.log('DevelopmentList')
        // console.log(developmentData)
      })
      .catch()
  }

  const newServiceRequest = () => {
    let x = localStorage.getItem('activeTab')
    let temp = 'serviceRequestId' + x

    const body = {
      requestType: 2,
      saveStatus: 0,
    }

    let isFirstTime = localStorage.getItem('isFirstTime')
    if (isFirstTime !== null && isFirstTime === 'true') {
      localStorage.setItem('isFirstTime', 'false')

      ApiPost(`corporate/service_request`, body)
        .then((res) => {
          console.log('\nres.data.data', res.data.data)
          //   const temp1 = {
          //     isSave : false,
          //     serviceRequestId: res.data.data._id
          //   }

          //   if(index === 0)
          //  setServiceIDs(temp1)
          //  else{
          //   const temp2 = [
          //     ...serviceIDs,
          //     {
          //       isSave : false,
          //       serviceRequestId: res.data.data._id
          //     }

          //   ]
          //   setServiceIDs(temp2)
          //  }

          //   console.log(serviceIDs)

          //   localStorage.removeItem('requestServiceIDs')
          // localStorage.setItem('requestServiceIDs', JSON.stringify(serviceIDs))
          localStorage.setItem(temp, res.data.data._id)
          // localStorage.setItem(requestUrgency+res.data.data._id,res.data.data.requestUrgency)

          // localStorage.setItem()
          dispatch(setCreateNewService(false))
          localStorage.setItem('isFirstTime', 'false')
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  React.useEffect(() => {
    if (flg == 1) {
      if (activeTab === currenttab && localStorage.getItem(currenttab) !== '') {
        getServiceJob()
        getRequestDataById()
      }
    }
    localStorage.setItem('currentTab', activeTab)
    if (totalTab === null) {
      localStorage.setItem('totalTab', activeTab)
    }
    let total = []
    var i
    for (i = 1; i <= Number(totalTab); i++) {
      total.push(i)
    }
    setRequestTab(total)

    var temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    if (temp !== null) {
      var x1 = localStorage.getItem('internalExternalAssigneeName' + temp)
      if (x1 !== null) setIntExtAssigneeVal(x1)

      var x2 = localStorage.getItem('internalAssigneeName' + temp)
      if (x2 !== null) setIntAssigneeVal(x2)

      var x3 = localStorage.getItem('externalComment' + temp)
      if (x3 !== null) setExtCommentVal(x3)

      var x4 = localStorage.getItem('internalComment' + temp)
      if (x4 !== null) setIntCommentVal(x4)

      var x5 = localStorage.getItem('intExtComment' + temp)
      if (x5 !== null) setIntExtCommentVal(x5)
    }

    var temp1 = localStorage.getItem('fromSubmitAll')
    if (temp1 !== null) {
      // if (mainService === '') setIsMainService(true)
      // else setIsMainService(false)
      // console.log("SubbbbsubService")
      // if (subService === '') setIsSubService(true)
      // else setIsSubService(false)
      // if (vendorList === undefined) setExtAssignee(true)
      // else setExtAssignee(false)
      // if (extStaffData === undefined || extStaffData === '') setIntExtAssignee(true)
      // else setIntExtAssignee(false)
      // if (extCommentVal === '') setExtComment(true)
      // else setExtComment(false)
      // if (intExtCommentVal === '') setIntExtComment(true)
      // else setIntExtComment(false)
      // if (mainService === '') setIsMainService(true)
      // else setIsMainService(false)
      // if (subService === '') setIsSubService(true)
      // else setIsSubService(false)
      // if (vendorList === '') setExtAssignee(true)
      // else setExtAssignee(false)
      // if (extStaffData === '') setIntExtAssignee(true)
      // else setIntExtAssignee(false)
      // if (extCommentVal === '') setExtComment(true)
      // else setExtComment(false)
      // if (intExtCommentVal === '') setIntExtComment(true)
      // else setIntExtComment(false)
      // if (staffData === '') setIntAssignee(true)
      // else setIntAssignee(false)
      // if (intCommentVal === '') setIntComment(true)
      // else setIntComment(false)
    }
  }, [activeTab])

  const saveAll = () => {
    SuccessToast('Save all request Successfully')
    localStorage.removeItem('serviceRequest')
    localStorage.removeItem('requestType')
    var i: any
    for (i = 1; i <= totalTab; i++) {
      localStorage.removeItem(i)
    }
    localStorage.setItem('currentTab', '1')
    localStorage.setItem('totalTab', '1')
    navigate('/request-management/service')
  }

  const addComment = async (e: any) => {
    setRequestData({...requestData, comment: e})
    // const body = {
    //   id: requestServiceId,
    //   comment: e,
    //   requestType: 2,
    // }
    // await ApiPut(`corporate/service_request`, body)
    //   .then((res) => {
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  }
  // const addDate = async (e: any) => {
  //   const {name, value} = e.target
  //   setDate({...date, preferScheduleDate: value})
  //   const body = {
  //     id: requestServiceId,
  //     schedule: [
  //       {
  //         preferScheduleDate: value,
  //         preferScheduleTime: date?.preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }
  // const handleChangeTime = async (time: any, timeString: string) => {
  //   let hour = timeString.split(':')[0]
  //   let minute = timeString.split(':')[1]
  //   let replaceZero = minute.replace(/^0+/, '')
  //   let preferScheduleTime = parseInt(hour) * 60 + parseInt(replaceZero)
  //   setDate({...date, preferScheduleTime: preferScheduleTime})
  //   const body = {
  //     id: requestServiceId,
  //     requestType: 2,
  //     schedule: [
  //       {
  //         preferScheduleDate: date?.preferScheduleDate,
  //         preferScheduleTime: preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  //  Saving Request as Draft
  const saveStatus = async () => {
    console.log(propertyList.length)

    // if (propertyList.length !== 0) {
    var vendorID, staffID
    // console.log("Vendor " +vendorList)
    // console.log("Staff " +staffData)

    if (requestType === 1) {
      staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    var temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    if (requestServiceId) {
      const body = {
        serviceRequests: [
          {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 0,
            comment: requestData?.comment,
          },
        ],
        isSave: true,
        isDelete: false,
      }

      const newValToSubmit = {
        _id: temp,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        vendorId: vendorID,
        staffId: staffID,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: requestType,
        saveStatus: 1,
        comment: requestData?.comment,
      }

      const newValToSave = {
        _id: temp,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        vendorId: vendorID,
        staffId: staffID,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: requestType,
        saveStatus: 0,
        comment: requestData?.comment,
      }

      const newValToSaveFirst = [
        {
          _id: temp,
          mainServiceId: mainService?._id,
          subServiceId: subService?._id,
          vendorId: vendorID,
          staffId: staffID,
          requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
          requestType: requestType,
          saveStatus: 0,
          comment: requestData?.comment,
        },
      ]

      const newValToSubmitFirst = [
        {
          _id: temp,
          mainServiceId: mainService?._id,
          subServiceId: subService?._id,
          vendorId: vendorID,
          staffId: staffID,
          requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
          requestType: requestType,
          saveStatus: 1,
          comment: requestData?.comment,
        },
      ]

      await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          // console.log('\nres.data.data 279', res.data.data)

          localStorage.removeItem('serviceRequest')
          localStorage.setItem(activeTab, res?.data?.data?._id)
          SuccessToast(res?.data?.message)
          // localStorage.removeItem('requestType')
          localStorage.setItem('isSave', '1')
          // setSaveButton('Saved as Draft')
          // navigate('/request-management/service')

          var test = localStorage.getItem('isSave' + requestServiceId)
          if (test === undefined || test === null) {
            var x = localStorage.getItem('submitAll')
            if (x !== null) {
              var y = JSON.parse(x)
              // console.log(y)
              // console.log(y.length)
              y[y.length] = newValToSubmit
              // console.log(y)

              localStorage.setItem('submitAll', JSON.stringify(y))
            } else localStorage.setItem('submitAll', JSON.stringify(newValToSubmitFirst))
          }

          // save All Data
          var test1 = localStorage.getItem('isSave' + requestServiceId)
          if (test1 === undefined || test1 === null) {
            var x = localStorage.getItem('saveAll')
            if (x !== null) {
              var y = JSON.parse(x)
              // console.log(y)
              // console.log(y.length)
              y[y.length] = newValToSave
              // console.log(y)
              localStorage.setItem('saveAll', JSON.stringify(y))
            } else localStorage.setItem('saveAll', JSON.stringify(newValToSaveFirst))
          }

          localStorage.setItem('isSave' + requestServiceId, '1')
          // setIsRequested(true)
          setIsSave(0)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: formData.standard === '1' ? 1 : 0,
        requestType: 2,
        preferScheduleDate: date?.preferScheduleDate,
        preferScheduleTime: parseInt(time),
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: flg,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // } else {
    //   ErrorToast('Please Select some Property First!!!')
    // }
  }

  //  deleteRequest
  const deleteStatus = async () => {
    let vendorID, staffID
    // console.log("Vendor " +vendorList)
    // console.log("Staff " +staffData)

    if (requestType === 1) {
      staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    if (requestServiceId) {
      const body = {
        serviceRequests: [
          {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 0,
            comment: requestData?.comment,
          },
        ],
        isSave: false,
        isDelete: true,
      }

      await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          // console.log('\nres.data.data 279', res.data.data)

          localStorage.removeItem('serviceRequest')
          localStorage.setItem(activeTab, res?.data?.data?._id)
          SuccessToast(res?.data?.message)
          // localStorage.removeItem('requestType')
          localStorage.setItem('isSave', '1')
          // setSaveButton('Saved as Draft')
          // navigate('/request-management/service')

          //  delete from requests to be submitted
          var x = localStorage.getItem('submitAll')
          if (x !== null) {
            var y = JSON.parse(x)
            var yLength = y.length
            for (let i = 0; i < y.length; i++)
              if (temp === y[i]._id) {
                const newData = y.filter((number: any) => {
                  return number._id !== temp
                })
                // console.log(newData)
                // console.log(y.length)
                // localStorage.setItem('totalTab',newData.length)
                localStorage.setItem('submitAll', JSON.stringify(newData))

                // localStorage.removeItem('isSave'+(newData.length))
                // navigate(`/request-management/service/create-request-service-main`)
              }

            // y[y.length] = newValToSave

            // localStorage.setItem("submitAll",JSON.stringify(y))
          }

          //  delete from requests to be saved
          var x = localStorage.getItem('saveAll')
          if (x !== null) {
            var y = JSON.parse(x)
            var yLength = y.length
            for (let i = 0; i < y.length; i++)
              if (temp === y[i]._id) {
                const newData = y.filter((number: any) => {
                  return number._id !== temp
                })
                console.log(newData)
                console.log(y.length)
                // localStorage.setItem('totalTab',newData.length)
                localStorage.setItem('saveAll', JSON.stringify(newData))
              }
          }

          var tabToSend = localStorage.getItem('activeTab')
          if (tabToSend !== null) {
            let z = parseInt(tabToSend)
            props.updateParent(z)
          }
          var x = localStorage.getItem('totalTab')
          if (x !== null) {
            y = parseInt(x)
            if (y !== 0) {
              let z = y - 1
              localStorage.setItem('totalTab', '' + z + '')
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: formData.standard === '1' ? 1 : 0,
        requestType: 2,
        preferScheduleDate: date?.preferScheduleDate,
        preferScheduleTime: parseInt(time),
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: flg,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  // const submitRequest = async () =>{
  //   console.log(propertyList)
  // }

  // submitting a single request
  const submitRequest = async () => {
    // setIsJobSchedule(false)
    // console.log(requestData)
    // console.log('mainService ' + mainService)
    // console.log('subService ' + subService)
    // console.log('staff Data' + staffData)
    // console.log('comment ' + intCommentVal + '   ' + intCommentVal.length)

    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    let vendorID, staffID

    if (requestType === 1) {
      if (staffData) staffID = staffData._id
      else staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    // Validations for external request type
    if (requestType === 1) {
      if (
        mainService !== undefined &&
        subService !== undefined &&
        vendorList !== undefined &&
        extStaffData !== undefined
        // && extCommentVal !== '' &&
        // intExtCommentVal !== ''
      ) {
        if (propertyList.length !== 0) {
          var totalProp = propertyList.length
          // console.log('prop length: ' + totalProp)

          for (let i = 0; i < totalProp; i++) {
            if (
              propertyList[i]?.schedule[0]?.date === null ||
              propertyList[i]?.schedule[0]?.startTime === null ||
              propertyList[i]?.schedule[0]?.endTime === null
            ) {
              // console.log(propertyList[i]?.schedule[0]?.date)
              // console.log(propertyList[i]?.schedule[0]?.startTime)
              // console.log(propertyList[i]?.schedule[0]?.endTime)
              isJobSchedule = true
              i = totalProp
            }
          }

          if (isJobSchedule) {
            ErrorToast('Some Job Schedules Missing!!!')
            setPError('1px solid red')
            myRef.current.scrollIntoView({behavior: 'smooth'})
            setTimeout(() => {
              setPError('')
            }, 8000)
          } else {
            if (requestServiceId) {
              const body = {
                // serviceRequests:
                //  [
                //   {
                id: temp,
                // mainServiceId: mainService?._id,
                // subServiceId: subService?._id,
                // vendorId: vendorID,
                // staffId: staffID,
                // requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                // requestType: requestType,
                saveStatus: 1,
                // vendorComment: extCommentVal,
                // comment: intExtCommentVal,
                // }
                // ],
                // isSave: true,
                // isDelete: false,
              }

              await ApiPut(`corporate/service_request`, body)
                .then((res) => {
                  console.log('\nres.data.data 343', res.data.data)

                  localStorage.removeItem('serviceRequest')
                  localStorage.setItem(activeTab, res?.data?.data?._id)
                  SuccessToast(res?.data?.message)
                  // navigate('/request-management/service')
                  localStorage.removeItem('requestType')
                  localStorage.setItem('isSubmit' + requestServiceId, '1')
                  // setIsRequested(true)

                  // After submit close the tab
                  var tabToSend = localStorage.getItem('activeTab')
                  if (tabToSend !== null) {
                    let z = parseInt(tabToSend)
                    props.updateParent(z)
                  }
                  let x = localStorage.getItem('totalTab')
                  let y
                  if (x !== null) {
                    y = parseInt(x)
                    if (y !== 0) {
                      let z = y - 1
                      localStorage.setItem('totalTab', '' + z + '')
                    }
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              const body = {
                mainServiceId: mainService?.name,
                subServiceId: subService?.name,
                requestUrgency: formData.standard === '1' ? 1 : 0,
                requestType: 2,
                preferScheduleDate: date?.preferScheduleDate,
                preferScheduleTime: parseInt(time),
                staffId: requestData?._id,
                comment: formData?.comment,
                service_job: [
                  {
                    media: ['123', '234'],
                    comment: 'Test',
                    communityId: null,
                    clusterId: null,
                    buildingId: '638ca32b3f3c7b26bf2bd922',
                    floorId: '638ca32b3f3c7b26bf2bd992',
                    unitGroupId: null,
                    unitId: '639058f42d31a79780548df2',
                  },
                ],
              }
              await ApiPost(`corporate/service_request`, body)
                .then((res) => {})
                .catch((e) => {
                  console.log(e)
                })
            }
          }

          // navigate(
          //   `/request-management/service/create-request-service/approve-request/${temp}`
          // )
        } else {
          ErrorToast('Please Select some Property First!!!')
          setPError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setPError('')
          }, 8000)
        }
      } else {
        if (mainService === undefined || mainService === '') setIsMainService(true)
        else setIsMainService(false)

        if (subService === undefined || subService === '') setIsSubService(true)
        else setIsSubService(false)

        if (vendorList === undefined) setExtAssignee(true)
        else setExtAssignee(false)

        if (extStaffData === undefined || extStaffData === '') setIntExtAssignee(true)
        else setIntExtAssignee(false)

        // if (extCommentVal === '') setExtComment(true)
        // else setExtComment(false)

        // if (intExtCommentVal === '') setIntExtComment(true)
        // else setIntExtComment(false)

        //  Service Details Card
        if (mainService === undefined || subService === undefined) {
          setSdError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSdError('')
          }, 6000)
        }

        //  ServiceProvider Card
        if (
          vendorList === undefined
          // || extCommentVal === ''
        ) {
          setSpError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSpError('')
          }, 6000)
        }

        //  Internal Card
        if (
          extStaffData === undefined ||
          extStaffData === ''
          // || intExtCommentVal === ''
        ) {
          setSpIntError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSpIntError('')
          }, 6000)
        }
      }
    }

    // Validations for internal request type
    if (requestType === 2) {
      if (
        mainService !== undefined &&
        subService !== undefined &&
        staffData !== undefined
        // && intCommentVal !== ''
      ) {
        if (propertyList.length !== 0) {
          var totalProp = propertyList.length
          // console.log('prop length: ' + totalProp)

          for (let i = 0; i < totalProp; i++) {
            if (
              propertyList[i]?.schedule[0]?.date === null ||
              propertyList[i]?.schedule[0]?.startTime === null ||
              propertyList[i]?.schedule[0]?.endTime === null
            ) {
              // console.log(propertyList[i]?.schedule[0]?.date)
              // console.log(propertyList[i]?.schedule[0]?.startTime)
              // console.log(propertyList[i]?.schedule[0]?.endTime)
              // setIsJobSchedule(true)
              isJobSchedule = true
              // console.log(isJobSchedule)
              i = totalProp
            }
          }
          // console.log(isJobSchedule)

          if (isJobSchedule === true) {
            ErrorToast('Some Job Schedules Missing!!!')
            myRef.current.scrollIntoView({behavior: 'smooth'})
            setPError('1px solid red')
            setTimeout(() => {
              setPError('')
            }, 8000)
          } else {
            if (requestServiceId) {
              const body = {
                // serviceRequests:
                //  [
                //   {
                id: temp,
                // mainServiceId: mainService?._id,
                // subServiceId: subService?._id,
                // vendorId: vendorID,
                // staffId: staffID,
                // requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                // requestType: requestType,
                saveStatus: 1,
                // comment: intCommentVal,
                // vendotComment: null,
                //   }
                // ]
                // isSave: true,
                // isDelete: false,
              }

              await ApiPut(`corporate/service_request`, body)
                .then((res) => {
                  console.log('\nres.data.data 343', res.data.data)

                  localStorage.removeItem('serviceRequest')
                  localStorage.setItem(activeTab, res?.data?.data?._id)
                  SuccessToast(res?.data?.message)
                  // navigate('/request-management/service')
                  localStorage.removeItem('requestType')
                  localStorage.setItem('isSubmit' + requestServiceId, '1')
                  // setIsRequested(true)

                  // After submit close the tab
                  var tabToSend = localStorage.getItem('activeTab')
                  if (tabToSend !== null) {
                    let z = parseInt(tabToSend)
                    props.updateParent(z)
                  }
                  let x = localStorage.getItem('totalTab')
                  let y
                  if (x !== null) {
                    y = parseInt(x)
                    if (y !== 0) {
                      let z = y - 1
                      localStorage.setItem('totalTab', '' + z + '')
                    }
                  }
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              const body = {
                mainServiceId: mainService?.name,
                subServiceId: subService?.name,
                requestUrgency: formData.standard === '1' ? 1 : 0,
                requestType: 2,
                preferScheduleDate: date?.preferScheduleDate,
                preferScheduleTime: parseInt(time),
                staffId: requestData?._id,
                comment: formData?.comment,
                service_job: [
                  {
                    media: ['123', '234'],
                    comment: 'Test',
                    communityId: null,
                    clusterId: null,
                    buildingId: '638ca32b3f3c7b26bf2bd922',
                    floorId: '638ca32b3f3c7b26bf2bd992',
                    unitGroupId: null,
                    unitId: '639058f42d31a79780548df2',
                  },
                ],
              }
              await ApiPost(`corporate/service_request`, body)
                .then((res) => {})
                .catch((e) => {
                  console.log(e)
                })
            }
          }
          // navigate(
          //   `/request-management/service/create-request-service/approve-request/${temp}`
          // )
        } else {
          ErrorToast('Please Select some Property First!!!')
          setPError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setPError('')
          }, 8000)
        }
      } else {
        if (mainService === undefined || mainService === '') setIsMainService(true)
        else setIsMainService(false)

        if (subService === undefined || subService === '') setIsSubService(true)
        else setIsSubService(false)

        if (staffData === undefined || staffData === '') setIntAssignee(true)
        else setIntAssignee(false)

        // if (intCommentVal === '') setIntComment(true)
        // else setIntComment(false)

        //  Service Details Card
        if (mainService === undefined || subService === undefined) {
          setSdError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSdError('')
          }, 6000)
        }

        //  Internal Card
        if (
          staffData === undefined ||
          staffData === ''
          // || intCommentVal === ''
        ) {
          setIntError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setIntError('')
          }, 6000)
        }
      }
    }
  }

  // Submitting All Requests
  const submitAllRequests = async () => {
    var totalTabs = localStorage.getItem('totalTab')
    if (totalTabs != null) {
      var z = parseInt(totalTabs)
      var isSaveTab = localStorage.getItem('isSave' + (z - 1))

      if (isSaveTab == null || isSaveTab === undefined) {
        let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
        let vendorID, staffID
        if (requestType === 1) {
          staffID = null
          if (vendorList) vendorID = vendorList._id
          else vendorID = null
        } else {
          vendorID = null
          if (staffData) staffID = staffData._id
          else staffID = null
        }

        // only one tab not saved yet
        if (totalTabs === '1') {
          const newValToSaveFirst = [
            {
              _id: temp,
              mainServiceId: mainService?._id,
              subServiceId: subService?._id,
              vendorId: vendorID,
              staffId: staffID,
              requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
              requestType: requestType,
              saveStatus: 1,
              comment: requestData?.comment,
            },
          ]
          localStorage.setItem('submitAll', JSON.stringify(newValToSaveFirst))
        }
        // last tab not saved yet
        else {
          const newValToSave = {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 1,
            comment: requestData?.comment,
          }

          var x = localStorage.getItem('submitAll')
          if (x !== null) {
            var y = JSON.parse(x)
            // console.log(y)
            // console.log(y.length)
            y[y.length] = newValToSave
            // console.log(y)

            localStorage.setItem('submitAll', JSON.stringify(y))
          }
        }
      }

      var dataToSend = localStorage.getItem('submitAll')
      if (dataToSend !== null) {
        const body = {
          serviceRequests: JSON.parse(dataToSend),
          isSave: true,
          isDelete: false,
        }

        await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            // console.log('\nres.data.data 343', res.data.data)

            localStorage.removeItem('serviceRequest')
            localStorage.setItem(activeTab, res?.data?.data?._id)
            SuccessToast(res?.data?.message)
            // localStorage.removeItem('requestType')
            localStorage.removeItem('submitAll')

            // Clearing All isSave Statuses
            var x = localStorage.getItem('Tabsss')
            if (x !== null) {
              var y = parseInt(x)
              for (let i = 0; i <= y; i++) {
                var z = localStorage.getItem('isSave' + i)
                if (z !== null) {
                  localStorage.removeItem('isSave' + i)
                }
              }
            }

            // Clearing All serviceRequestIds
            x = localStorage.getItem('Tabsss')
            if (x !== null) {
              var y = parseInt(x)
              for (let i = 0; i <= y; i++) {
                var z = localStorage.getItem('serviceRequestId' + i)
                if (z !== null) {
                  localStorage.removeItem('serviceRequestId' + i)
                }
              }
            }

            localStorage.setItem('Tabsss', '2')
            // navigate(-1)
            navigate('/request-management/service')
          })
          .catch((e) => {
            console.log(e)
          })

        // navigate(
        //   `/request-management/service/create-request-service/approve-request/${temp}`
        // )
      }
    }
  }

  /* When user clicks [Save All] and there are tabs that have not yet been started (no fields filled) 
  then they will be highlighted to the user. */
  const saveAllRequestsUpdated = () => {
    var totalTabs = localStorage.getItem('totalTab')
    let tabb: any = []
    if (totalTabs != null) {
      for (let i = 0; i < parseInt(totalTabs); i++) {
        let service = localStorage.getItem('serviceRequestId' + i)
        if (service !== null) {
          let saved = localStorage.getItem('savedOnce' + service)
          if (saved === null) {
            // alert('tab ' + (i + 1) + ' has no fields saved yet')
            tabb[tabb.length] = i + 1
          }
        }
      }
      var retainTabs = ''
      for (let m = 0; m < tabb.length; m++) {
        if (m === tabb.length - 1) retainTabs = retainTabs + ' ' + tabb[m] + ' '
        else retainTabs = retainTabs + ' ' + tabb[m] + ' , '
      }

      ErrorToast('TAB' + retainTabs + 'have not yet been started (no fields filled).. Please check')
    }
  }

  //
  const submitAllRequestsUpdated = async () => {
    var totalTabs = localStorage.getItem('totalTab')
    let isSchedule: any = 1

    if (totalTabs != null) {
      // console.log(totalTabs)
      let j = parseInt(totalTabs)
      let y = []
      let tabb: any = []
      for (let i = 0; i < j; i++) {
        let service = localStorage.getItem('serviceRequestId' + i)
        isSchedule = 1

        if (service !== null) {
          // console.log(service)
          let saved = localStorage.getItem('savedOnce' + service)

          const body = {
            page: 1,
            limit: 10,
            serviceRequestId: service,
          }
          await ApiPost(`corporate/service_job/get`, body)
            .then((res) => {
              if (res.data.data.service_job_data !== '') {
                let totalProp = res?.data?.data?.service_job_data

                for (let i = 0; i < totalProp.length; i++) {
                  if (
                    totalProp[i]?.schedule[0]?.date === null ||
                    totalProp[i]?.schedule[0]?.startTime === null ||
                    totalProp[i]?.schedule[0]?.endTime === null
                  ) {
                    // console.log(totalProp[i]?.schedule[0]?.date)
                    // console.log(totalProp[i]?.schedule[0]?.startTime)
                    // console.log(totalProp[i]?.schedule[0]?.endTime)

                    isSchedule = 2
                    i = totalProp.lengt
                  }
                }
              }
            })
            .catch()

          // if(saved !== null){
          // console.log(saved)
          // alert("tab "+(i+1) + " has no fields saved yet")
          let reqType = localStorage.getItem('reqType_' + service)
          let mainServ = localStorage.getItem('mainService_' + service)
          if (mainServ !== null) console.log('main ' + mainServ)
          let subServ = localStorage.getItem('subService_' + service)
          if (subServ !== null) console.log('sub ' + subServ)
          let servProv = localStorage.getItem('serviceProvider_' + service)
          if (servProv !== null) console.log('prov ' + servProv)
          let intStaff = localStorage.getItem('internalStaff_' + service)
          if (intStaff !== null) console.log('staff ' + intStaff)
          let intCom = localStorage.getItem('intComment_' + service)
          if (intCom !== null) console.log('intC + ' + intCom)
          let extCom = localStorage.getItem('extComment_' + service)
          if (extCom !== null) console.log('extC ' + extCom)
          let isProp = localStorage.getItem('isPropertySelected_' + service)
          if (isProp !== null) console.log('isP ' + isProp)

          // console.log('reqTypeeeeee ' + reqType)

          if (reqType !== null || reqType !== undefined) {
            // console.log(reqType)
            let x
            // External
            if (reqType === '1') {
              if (
                mainServ !== null &&
                subServ !== null &&
                servProv !== null &&
                intCom !== null &&
                extCom !== null &&
                isProp !== null &&
                isSchedule !== 2
              ) {
                // console.log(mainServ)
                // console.log(subServ)
                // console.log(servProv)
                // console.log(intCom)
                // console.log(extCom)
                // console.log(isProp)

                // x = {
                //   _id: service,
                //   mainServiceId: mainServ,
                //   subServiceId: subServ,
                //   vendorId: servProv,
                //   staffId: intStaff,
                //   requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                //   requestType: reqType,
                //   saveStatus: 1,
                //   comment: intCom,
                //   vendorComment: extCom,
                // }

                const body = {
                  id: service,
                  saveStatus: 1,
                }

                await ApiPut(`corporate/service_request`, body)
                  .then((res) => {
                    console.log('\nres.data.data 343', res.data.data)
                  })
                  .catch((e) => {
                    console.log(e)
                  })

                y[y.length] = x
              }
              //
              else {
                tabb[tabb.length] = i + 1
              }
            }
            // Internal
            else {
              if (
                mainServ !== null &&
                subServ !== null &&
                intStaff !== null &&
                intCom !== null &&
                isProp !== null &&
                isSchedule !== 2
              ) {
                // x = {
                //   _id: service,
                //   mainServiceId: mainServ,
                //   subServiceId: subServ,
                //   vendorId: null,
                //   staffId: intStaff,
                //   requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                //   requestType: reqType,
                //   saveStatus: 1,
                //   comment: intCom,
                //   vendorComment: null,
                // }
                const body = {
                  id: service,
                  saveStatus: 1,
                }

                await ApiPut(`corporate/service_request`, body)
                  .then((res) => {
                    console.log('\nres.data.data 343', res.data.data)
                  })
                  .catch((e) => {
                    console.log(e)
                  })

                y[y.length] = x
              } else {
                tabb[tabb.length] = i + 1
              }
            }
          } else {
            tabb[tabb.length] = i + 1
          }
          // }
        }
      }

      // console.log('yyyyyyy')
      // console.log(y)
      // console.log(tabb)

      var retainTabs = ''
      for (let m = 0; m < tabb.length; m++) {
        retainTabs = retainTabs + ' ' + tabb[m]
      }

      // alert(retainTabs + ' Tabs have missing fields')

      //
      const totalRec = tabb.length

      for (let i = 0; i < tabb.length; i++) {
        let m = tabb[i] - 1
        let a1 = localStorage.getItem('serviceRequestId' + m)

        localStorage.setItem('serviceRequestId' + i, '' + a1 + '')
        let b1 = a1
      }
      if (tabb.length !== 0) {
        localStorage.setItem('fromSubmitAll', '2')
        updateSubmitAll(totalRec)
        ErrorToast('Failed to submit some requests due to some missing fields.. Please review !!')
      } else {
        SuccessToast('All Requests have been Submitted successfully')
        navigate('/request-management/service')
      }

      // const body = {
      //   serviceRequests: y,
      //   isSave: true,
      //   isDelete: false,
      // }

      // await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
      //   .then((res) => {
      //     var retainTabs = ''
      //     for (let m = 0; m < tabb.length; m++) {
      //       retainTabs = retainTabs + ' ' + tabb[m]
      //     }

      //     // alert(retainTabs + ' Tabs have missing fields')

      //     //
      //     const totalRec = tabb.length

      //     for (let i = 0; i < tabb.length; i++) {
      //       let m = tabb[i] - 1
      //       let a1 = localStorage.getItem('serviceRequestId' + m)

      //       localStorage.setItem('serviceRequestId' + i, '' + a1 + '')
      //       let b1 = a1
      //       console.log('new value of tab ' + b1)
      //     }
      //     if (tabb.length !== 0) {
      //       localStorage.setItem('fromSubmitAll', '2')
      //       updateSubmitAll(totalRec)
      //       ErrorToast(
      //         'Failed to submit some requests due to some missing fields.. Please review !!'
      //       )
      //     } else {
      //       SuccessToast('All Requests have been Submitted successfully')
      //       navigate('/request-management/service')
      //     }
      //   })
      //   .catch()

      //
      // const total = localStorage.getItem('totalTab')
      // if(total != null){
      //   let j = parseInt(total) + 1

      // for(let i=index; i < (j); ){
      //   let next = localStorage.getItem('serviceRequestId'+(i+1))
      //   if(next !== null){
      //     console.log("next " + next)
      //     let temp = 'serviceRequestId'+i
      //     localStorage.setItem(temp,next);
      //   }

      // }

      // jkbkj
      // var tabToSend = localStorage.getItem('activeTab')
      // if (tabToSend !== null) {
      //   let z = parseInt(tabToSend)
      //   props.updateParent(z)
      // }
      // var x = localStorage.getItem('totalTab')
      // if (x !== null) {
      //   y = parseInt(x)
      //   if (y !== 0) {
      //     let z = y - 1
      //     localStorage.setItem('totalTab', '' + z + '')
      //   }
      // }
    }
  }

  // Saving All Requests
  const saveAllRequests = async () => {
    var totalTabs = localStorage.getItem('totalTab')
    if (totalTabs != null) {
      var z = parseInt(totalTabs)
      var isSaveTab = localStorage.getItem('isSave' + (z - 1))

      if (isSaveTab == null || isSaveTab === undefined) {
        let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
        let vendorID, staffID
        if (requestType === 1) {
          staffID = null
          if (vendorList) vendorID = vendorList._id
          else vendorID = null
        } else {
          vendorID = null
          if (staffData) staffID = staffData._id
          else staffID = null
        }

        // only one tab not saved yet
        if (totalTabs === '1') {
          const newValToSaveFirst = [
            {
              _id: temp,
              mainServiceId: mainService?._id,
              subServiceId: subService?._id,
              vendorId: vendorID,
              staffId: staffID,
              requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
              requestType: requestType,
              saveStatus: 0,
              comment: requestData?.comment,
            },
          ]

          const newValToSubmitFirst = [
            {
              _id: temp,
              mainServiceId: mainService?._id,
              subServiceId: subService?._id,
              vendorId: vendorID,
              staffId: staffID,
              requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
              requestType: requestType,
              saveStatus: 1,
              comment: requestData?.comment,
            },
          ]
          localStorage.setItem('submitAll', JSON.stringify(newValToSubmitFirst))
          localStorage.setItem('saveAll', JSON.stringify(newValToSaveFirst))
        }
        // last tab not saved yet
        else {
          const newValToSave = {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 0,
            comment: requestData?.comment,
          }
          const newValToSubmit = {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 1,
            comment: requestData?.comment,
          }

          var x = localStorage.getItem('submitAll')
          if (x !== null) {
            var y = JSON.parse(x)
            // console.log(y)
            // console.log(y.length)
            y[y.length] = newValToSubmit
            // console.log(y)

            localStorage.setItem('submitAll', JSON.stringify(y))
          }

          var x1 = localStorage.getItem('saveAll')
          if (x1 !== null) {
            var y = JSON.parse(x1)
            // console.log(y)
            // console.log(y.length)
            y[y.length] = newValToSave
            // console.log(y)

            localStorage.setItem('saveAll', JSON.stringify(y))
          }
        }
      }

      var dataToSend = localStorage.getItem('saveAll')

      if (dataToSend !== null) {
        const body = {
          serviceRequests: JSON.parse(dataToSend),
          isSave: false,
          isDelete: false,
        }

        await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            console.log('\nres.data.data 343', res.data.data)

            localStorage.removeItem('serviceRequest')
            localStorage.setItem(activeTab, res?.data?.data?._id)
            SuccessToast(res?.data?.message)
            // localStorage.removeItem('requestType')
            // localStorage.removeItem('submitAll')

            // Clearing All isSave Statuses
            // var x  =localStorage.getItem('Tabsss')
            // if(x !== null)
            // {
            //   var y = parseInt(x)
            //   for(let i=0; i<=y; i++){
            //     var z = localStorage.getItem('isSave'+i)
            //     if(z !== null){
            //       localStorage.removeItem('isSave'+i)
            //     }
            //   }
            // }

            // Clearing All serviceRequestIds
            // x  =localStorage.getItem('Tabsss')
            // if(x !== null)
            // {
            //   var y = parseInt(x)
            //   for(let i=0; i<=y; i++){
            //     var z = localStorage.getItem('serviceRequestId'+i)
            //     if(z !== null){
            //       localStorage.removeItem('serviceRequestId'+i)
            //     }
            //   }
            // }

            localStorage.setItem('Tabsss', '2')

            navigate(-1)
          })
          .catch((e) => {
            console.log(e)
          })

        // navigate(
        //   `/request-management/service/create-request-service/approve-request/${temp}`
        // )
      }
    }
  }

  const serviceRequestData = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    console.log(temp)
    const body = {
      page: page,
      limit: 5,
      serviceRequestId: temp,
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        // res?.data?.data?.service_job_data.forEach((element: any) => {
        //   Object.assign(element, {
        //     time: getTotalMinutes(element?.schedule?.preferScheduleTime),
        //     date: new Date(element?.schedule?.preferScheduleDate),
        //   })
        // })
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const onSubmit = () => {}

  useEffect(() => {
    if (flg == 1) {
      if (requestServiceId && requestType === 2) {
        getServiceJob()
        serviceRequestData()
        getSummary()
      }
    }
    if (!localStorage.getItem(currenttab)) {
      localStorage.setItem('currentTab', '1')
      // localStorage.setItem('totalTab', '1')
      setRequestTab([1])
      setActiveTab(1)
    }
  }, [requestType, page])

  const openModal = async (id: any) => {
    let temp = id._id

    console.log('temp: ' + temp)

    console.log('id', id)
    Object.assign(id, {jobId: id._id})
    setUnitNo(id.unit[0].unitNo)

    setJobId(id._id)
    console.log('Job ID: ' + jobId)
    setMediaPopup(true)
    await ApiGet(`corporate/service_job/${id?.jobId}`)
      .then((res) => {
        console.log('\nres.data.data', res.data.data[0])
        setMediaPop(res.data.data[0])
      })
      .catch((err) => ErrorToast(err.message))
  }

  const moveVendor = (flg: any) => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', flg)
  }
  const setScheduleEvent = (e: any, int: any, flg: any) => {
    if (flg === 'preferScheduleTime') {
      var hoursInMinutes = e.hours() * 60 // Convert hours to minutes
      const minutes = e.minutes() // Get the remaining minutes
      const totalMinutes = hoursInMinutes + minutes
      schedule[int][flg] = totalMinutes
    } else {
      schedule[int][flg] = e
    }
    setSchedule(schedule)
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  const updateRequestType = (num: number) => {
    setRequestType(num)
    localStorage.setItem('requestType', '' + num + '')
    saveComment()
  }

  // Save Request
  const saveRequest = async () => {
    let rT = localStorage.getItem('requestType')
    let temp2: any
    if (rT !== null) {
      temp2 = rT
    }
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    let intC: any
    let extC: any
    if (requestType === 1) {
      intC = intExtCommentVal
      extC = extCommentVal
    } else {
      intC = intCommentVal
      extC = null
    }

    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + temp)
      .then((res) => {
        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: temp,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: res?.data?.data[0].requestUrgency,
              requestType: rT,
              saveStatus: 0,
              comment: intC,
              vendorComment: extC,
            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            SuccessToast('Your request has been saved!!!')
            localStorage.setItem('savedOnce' + temp, '1')
            localStorage.setItem('reqType_' + temp, '' + requestType + '')

            localStorage.setItem('intComment_' + temp, intC)
            localStorage.setItem('extComment_' + temp, extC)
          })
          .catch()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Save Comment
  const saveComment = async () => {
    let rT = localStorage.getItem('requestType')
    let temp2: any
    if (rT !== null) {
      temp2 = rT
    }
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    let intC: any
    let extC: any
    if (requestType === 1) {
      intC = intExtCommentVal
      extC = extCommentVal
    } else {
      intC = intCommentVal
      extC = null
    }

    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + temp)
      .then((res) => {
        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: temp,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: res?.data?.data[0].requestUrgency,
              requestType: rT,
              saveStatus: 0,
              comment: intC,
              vendorComment: extC,
            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            localStorage.setItem('savedOnce' + temp, '1')
            localStorage.setItem('reqType_' + temp, '' + requestType + '')

            localStorage.setItem('intComment_' + temp, intC)
            localStorage.setItem('extComment_' + temp, extC)
          })
          .catch()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const updateRequestUrgency = async (val: any) => {
    reqUrg = val
    // console.log(event.target.value)
    setrequestUrgency(val)
    // saveComment()
    // const saveComment = async () => {

    let rT = localStorage.getItem('requestType')
    let temp2: any
    if (rT !== null) {
      temp2 = rT
    }
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    let intC: any
    let extC: any
    if (requestType === 1) {
      intC = intExtCommentVal
      extC = extCommentVal
    } else {
      intC = intCommentVal
      extC = null
    }

    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + temp)
      .then((res) => {
        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: temp,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: val,
              requestType: rT,
              saveStatus: 0,
              comment: intC,
              vendorComment: extC,
            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            localStorage.setItem('savedOnce' + temp, '1')
            localStorage.setItem('reqType_' + temp, '' + requestType + '')

            localStorage.setItem('intComment_' + temp, intC)
            localStorage.setItem('extComment_' + temp, extC)
          })
          .catch()
      })
      .catch((e) => {
        console.log(e)
      })
    // }
  }

  const deleteJob = async (id: any) => {
    await ApiDelete(`corporate/service_job/${id}`)
      .then((response) => {
        SuccessToast('Service job has been deleted successfully!!!')
        getServiceJob()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div
          id=''
          className='container-xxl pt-0 mt-2 px-0'
          style={{borderTop: '1px solid darkgray'}}
        >
          {/* <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 px-5'>
            <div className='d-flex align-items-center gap-5'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <img src={leftArrow} style={{stroke: 'red'}} />
              </span>
              <h1 className='page-heading green_color m-0'>Internal Service Request</h1>
            </div>
          </div> */}

          {requestTab.map((item: any) => {
            return (
              <>
                <div className={`${activeTab === item ? 'd-show' : 'd-none'}`}>
                  <>
                    <div className=' p-5 container-xxl px-1'>
                      <div
                        className='tops pe-5'
                        style={{width: 'fit-content', flexDirection: 'row', marginLeft: 'auto'}}
                      >
                        {/* Save All CTA */}
                        <div className='d-none d-md-flex' style={{textAlign: 'end'}} >
                          <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={saveAllRequestsUpdated}
                            style={{
                              transform: 'translate(30px,-63px)',
                              border: '1px solid #007a59',
                              color: '#007a59',
                              width: '115px',
                              fontSize: '14px',
                            }}
                          >
                            <img
                              src={saveAllImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}Save All
                          </a>
                          {/* Submit All CTA */}
                          <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={submitAllRequestsUpdated}
                            style={{
                              transform: 'translate(30px,-63px)',
                              border: '1px solid #48474D',
                              color: '#48474D',
                              width: '115px',
                              fontSize: '14px',
                            }}
                          >
                            <img
                              src={submitAllImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Submit All
                          </a>
                        </div>
                        
                      </div>

                      <div
                        className='tops pe-5'
                        // style={{width: 'fit-content', flexDirection: 'row', justifyContent: 'center'}}
                      >

                        <div className='d-flex d-md-none justify-content-center'  >
                          <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={saveAllRequestsUpdated}
                            style={{
                              border: '1px solid #007a59',
                              color: '#007a59',
                              width: '115px',
                              fontSize: '14px',
                            }}
                          >
                            <img
                              src={saveAllImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}Save All
                          </a>
                          {/* Submit All CTA */}
                          <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={submitAllRequestsUpdated}
                            style={{
                              
                              border: '1px solid #48474D',
                              color: '#48474D',
                              width: '115px',
                              fontSize: '14px',
                            }}
                          >
                            <img
                              src={submitAllImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Submit All
                          </a>
                        </div>
                        </div>

                      {/* RequestType */}
                      <div
                        className='d-flex tops px-5 py-2 mt-0 translateClass'
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          // transform: 'translate(0px,-52px)',
                          background: 'transparent',
                        }}
                      >
                        <div className='d-flex'>
                          <div
                            className='d-flex border-outline mx-0 '
                            style={{
                              alignItems: 'center',
                              paddingTop: '0px',
                              paddingBottom: '0px',
                            }}
                          >
                            {/* <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label required'
                                style={{
                                  fontSize: '18px',
                                  color: '#007a59',
                                  width: '150px',
                                  fontWeight: '800',
                                }}
                              >
                                Request Type
                              </label> */}

                            <div className='d-flex row justify-content-center mt-5'>
                              <div className='form-check form-check-solid form-check-sm py-3 px-5 col-12 col-sm-6'>
                                {requestType === 2 ? (
                                  <>
                                    <div
                                      className=''
                                      style={{
                                        color: '#108B79',
                                        paddingBottom: '5px',
                                        height: '56px',
                                        width: '170px',
                                        fontWeight: '800px',

                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        margin: '0px auto'
                                      }}
                                      onClick={() => updateRequestType(2)}
                                    >
                                      <div className='text-end'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{show: 250, hide: 400}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              Delegates your request to your Internal Maintenance
                                              Team
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineQuestionCircle
                                              color='#108B79'
                                              fontSize={16}
                                              style={{
                                                cursor: 'pointer',
                                                marginRight: '3px',
                                                marginTop: '-2px',
                                              }}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                      <div
                                        className=''
                                        style={{
                                          transform: 'translate(0px, -17px',
                                          fontWeight: '700',
                                          width: 'fit-content',
                                        }}
                                      >
                                        <svg
                                          width='40'
                                          height='49'
                                          xmlns='http://www.w3.org/2000/svg'
                                          overflow='hidden'
                                          style={{marginLeft: '4px', marginRight: '30px'}}
                                        >
                                          <defs>
                                            <clipPath id='clip0'>
                                              <rect x='716' y='384' width='42' height='49' />
                                            </clipPath>
                                            <clipPath id='clip1'>
                                              <rect x='0' y='0' width='209550' height='200025' />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIKUExURQAAAAAAAAD//wBVVQCqqgCAgACZZgCZmQCSbSCAgByOcRqZgBeLdBWAgBWVgBSJdhCPgA+HeA2GeQ2UeQ2Mcw2MgBaFehWKdRSJdhOEexKNexCHeBWHeBWOeBSNeROMeRGLehGIdxGLehCIdw+KehSMeBSJexOJdhGKeRCIdxCMexCKeRCHeBSLeBSJehOLfBKJehKIdxKIehKKeBGKeRGIdxGKeROMeRKKeBGIehGLehGKeBGLehGJeBGLdxOKdxOKehOJeROKeBKJeRKKeBKKehKLeRKKexGJeBOKehOJeBOKehKKehKKeBKKehKJehGKeRGJehOKeROKeRKKeRKLeBKJehKKeRKKeRGJehGKeRGJeBGLehOLehKLehKKeRKKeBKJeRKKeBKLeRGKeROLehKKeRKJeRKKehKLeRKLeROKeRKKeRKJeRKKeRKJeRKKehGKeRGKeBGLehOKeRKKeRKJeBKLeRKKeRKKeRKKeRKJeRGKeRKKeRKLehKJeBKKeRKLeRKKeRKLeRKKeRKKeRKKeRKKehKLeRKKeBKJeRKKeBGLeROKehKKeRKKeRKKeRKKeRKJeRKKeRKKeBKKeRKKehKKeRKKeRKKeRKKeRKKeRKLeRKKeRKKeRKKeRKKeRGKeROKeRKKeRKKeBKKeRKKeRKKeBKLeRKKeRKKeBKKeRKLeRKKedBeYUIAAACtdFJOUwABAQMDBAUFBwgJCgsMDA0QERMTFBQXGBobHSAkJCYoLC0uLzIzNDY7Pj4/QEBBQkVHR0hKS0xQU1hYWVpbXF5eX2BhYmJlZmprbG1vcXFzdnd6fH5/gIGDhIWGhoiKi4yNjpGSlZiam5yepKanqKmsr7CwsbW2uLm6u7y+wsPFxszP0NHT1NbX2Nna29zf4ePl5ufp6evs7e7w8vP09fb3+Pj5+vr7/Pz9/v7+z/xeLgAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAbtJREFUOE9t0/k7VHEUx/EjbdMeypZUlkm0USkl2sWQsqSVkl0iUllqQhhqZgpln5GQ4fM/+rrnTPfOrdcv3/c532eemfvcZ+iv6PLP3iVXQ7KM/7B5gTH3IvByp2wCPYSnNJJoR6YT9u2yM0rHeALXno+o5TIK/orTkhQ64jskqUtBp5SSj6dSuruwSSkx6JbSPcN5KcUCh5TuAbKllDD0SOkyUSWlnEWjlC5sbnKfJNE7XJAyeIH2zZI2OPxpsLsfHVHrYXm04rFqK5OIXsy9Lr1XN4rJU7Iy2XZ/AspiU7QsjPZeLrttoa1J12+dCyGKKilK3SQ3mqA70+qjjiSegvOm1PTlGE+ax/DWXH2D1bdZsRGJhYNYeJLzHr+Oyy1R8sp4nDouOtd/gtJ1RE05cO/SbpUPyNDOjWmVHfa24sPaQI0o5KBYDGyQNDq4OixVgBKpQJ9wgKMJJzhMnuMSRx/Uo//HNZRx/Jzh0+wk6jl+f+PTLAHNHD4Xn2ZxaOX44dnCYXLG/xUtuMJh0owbHEeXZ29aOA3Cq+Hy/wNz/2De5Qz03YexeLknsr4aWZYX5bcwVLFfuyNaA4IAjRiEK3zIAAAAAElFTkSuQmCC'
                                              preserveAspectRatio='none'
                                              id='img2'
                                            ></image>
                                            <clipPath id='clip3'>
                                              <rect x='0' y='0' width='200025' height='200025' />
                                            </clipPath>
                                            <clipPath id='clip4'>
                                              <rect x='0' y='0' width='180975' height='180975' />
                                            </clipPath>
                                            <clipPath id='clip5'>
                                              <rect x='0' y='0' width='180975' height='180975' />
                                            </clipPath>
                                            <clipPath id='clip6'>
                                              <rect x='0' y='0' width='171450' height='180975' />
                                            </clipPath>
                                            <clipPath id='clip7'>
                                              <rect x='0' y='0' width='171450' height='171450' />
                                            </clipPath>
                                          </defs>
                                          <g
                                            clip-path='url(#clip0)'
                                            transform='translate(-716 -384)'
                                          >
                                            <g
                                              clip-path='url(#clip1)'
                                              transform='matrix(0.000104987 0 0 0.000104987 726 385)'
                                            >
                                              <g
                                                clip-path='url(#clip3)'
                                                transform='matrix(1.04762 0 0 1 0 -0.25)'
                                              >
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img2'
                                                  transform='scale(6250.78 6250.78)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip4)'
                                              transform='matrix(0.000104987 0 0 0.000104987 716 413)'
                                            >
                                              <g clip-path='url(#clip5)'>
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img2'
                                                  transform='scale(5655.47 5655.47)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip6)'
                                              transform='matrix(0.000104987 0 0 0.000104987 739 413)'
                                            >
                                              <g
                                                clip-path='url(#clip7)'
                                                transform='matrix(1 0 0 1.05556 -0.5 0)'
                                              >
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img2'
                                                  transform='scale(5357.81 5357.81)'
                                                ></use>
                                              </g>
                                            </g>
                                            <path
                                              d='M735.903 406.5 735.903 410.151 724.5 410.151 724.5 413.802'
                                              stroke='#128A79'
                                              stroke-width='1.33333'
                                              stroke-miterlimit='8'
                                              fill='none'
                                              fill-rule='evenodd'
                                            />
                                            <path
                                              d='M0 0 3.65089 0 3.65089 11.4029 7.30176 11.4029'
                                              stroke='#128A79'
                                              stroke-width='1.33333'
                                              stroke-miterlimit='8'
                                              fill='none'
                                              fill-rule='evenodd'
                                              transform='matrix(1.83697e-16 1 1 -1.83697e-16 736.5 406.5)'
                                            />
                                          </g>
                                        </svg>
                                        {'  '} Internal
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        height: '2px',
                                        width: '170px',
                                        marginTop: '6px',
                                         margin: '0px auto',
                                        backgroundColor: '#108B79',
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className=''
                                      style={{
                                        color: 'dimgray',
                                        paddingBottom: '5px',
                                        height: '56px',
                                        width: '170px',
                                        fontWeight: '800px',

                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        margin: '0px auto'
                                      }}
                                      onClick={() => updateRequestType(2)}
                                    >
                                      <div className='text-end'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{show: 250, hide: 400}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              Delegates your request to your Internal Maintenance
                                              Team
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineQuestionCircle
                                              color='gray'
                                              fontSize={16}
                                              style={{
                                                cursor: 'pointer',
                                                marginRight: '3px',
                                                marginTop: '-2px',
                                              }}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                      <div
                                        className=''
                                        style={{
                                          transform: 'translate(0px, -17px',
                                          fontWeight: '700',
                                          width: 'fit-content',
                                        }}
                                      >
                                        <svg
                                          width='40'
                                          height='49'
                                          xmlns='http://www.w3.org/2000/svg'
                                          style={{marginLeft: '4px', marginRight: '30px'}}
                                          overflow='hidden'
                                        >
                                          <defs>
                                            <clipPath id='clip8'>
                                              <rect x='673' y='384' width='40' height='49' />
                                            </clipPath>
                                            <clipPath id='clip9'>
                                              <rect x='0' y='0' width='152400' height='180975' />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH+UExURQAAAAAAAFVVVUBAQDMzM2YzZklJSUBAQDk5VU1NTUZGRkBAVU5OTlBAUEtLS0NDUU1ATU1NTUNDTkpKSkVFTkxCTEZGT0hIUEdHTkpKUUZGTUtGS0pET0hITkdHTEdHTUZGS0pFTkdHTElFTkZGTkpGTklFTUhITEdHS0lJTUpGTkhIS0dHTkhFTEdHTkZGTUlGTUdHTUhGS0hITkhITUpHTUlGTElGTkhFTkdHTElHTEhGTkhITUdHTElGTklHTklGTkhGTUhITUdHTEdHTklGTUdHTkhGTUdHTkdHTEdHTklHTUdHTUhGTElHTUhGTEhGTkhITkdHTUhITkdHTUlHTUhGTEhGTkdHTUhITkhITUdHTUhHTEhGTUhGTUhITUhITUhGTUdHTEhHTUhITUlHTUhITkhHTUhITEdHTUlHTUhHTUlHTUdHTUhHTUhHTkhGTUdHTUhHTkhGTUlHTUhHTEhHTUhHTUhHTkhHTUdHTEhHTUlHTkdHTEhHTUhHTUlHTUhHTUdHTUhHTUhHTUhHTkdHTUhHTUhHTkhHTUdHTUlHTUhHTkhHTUhHTUhHTUhHTUhITUhHTUhHTEhHTUhHTUhHTUhHTkhITUhHTUhHTUhHTUhITUlITUhHTUhHTUhHTUdGTUhHTUhHTkhHTUhHTUhHTUlITkhHTUhHTYg9BjkAAACpdFJOUwABAwQFBQcICQoLDA0QERMUFBcYGhsdICQmKCwtLi8yMzQ2Oz4+P0BBQkVHSEpLTFBTWFhZWltbXF5eX2BhYmVmamprbG1vcXN2dnd6fH5/gICBg4SFhoaIiouMjY6RkpWYmpucnqSmp6ioqayvsLG1tri5uru8vsLDxcbMz9DR09TW19jZ2tvc3+Hh4+Xm5+nr7O3t7vDw8vP09fb29/j5+vr6+/z9/f4Wq3G7AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuklEQVQ4T23T+TtUcRTH8WOSSZssIUnIkq1NUgrZRslSWQppo6hkK5WlRcYyKCMtWsxIlvv5L33dc8a9c/P65fs+5/vMc2eemaEtUU0fPKvTj1Nk/I/DA7hnVoCOPbLxV4/FGweJdue6MBQsO7NTmI/n2jeIVi4z2yQyJClkVjssaUjFKymlHI1ShmtwSCnReCNluIPTUoodTilDHfKklAN4K2U4h/tSygm0SxlCvd9DJYle4IyUyT30Bko64PSlyd4R9Eduhr1BW0zUVxYR7+Dtul7Z5saPdFlZ7Lr5DcrK0yhZmO0/X1tip6CkgssnQ4gia6oydsiNLuDKT/VSZxJPtrIFNU0k86S7Bc/DSz3Q+i7ERCRcHcXy7aKX8KbKLVGK9jVOHWddm29BeX1ETcWY2frdDCBbP22Zd/uHuqtj9YHaUcFBMfgYIGl2SBuXKkeNlL9hRHM8wXEOixbkcLyH+ujbyEctx5dffFql4RHH3098WsWjk0Ob4tMqDl0cc392clhk+R7xHBc5LDpRwHFs7XehndMk/AGmfP/A0lUsTbv8fdbgPir3RInPZtfli/L5N9Ycpt8RbQAAKYeWyxBRRwAAAABJRU5ErkJggg=='
                                              preserveAspectRatio='none'
                                              id='img3'
                                            ></image>
                                            <clipPath id='clip10'>
                                              <rect x='0' y='0' width='152400' height='177784' />
                                            </clipPath>
                                            <clipPath id='clip4'>
                                              <rect
                                                x='9.31323e-10'
                                                y='0'
                                                width='152400'
                                                height='180975'
                                              />
                                            </clipPath>
                                            <clipPath id='clip11'>
                                              <rect x='0' y='0' width='152400' height='177784' />
                                            </clipPath>
                                            <clipPath id='clip12'>
                                              <rect
                                                x='9.31323e-10'
                                                y='0'
                                                width='180975'
                                                height='200025'
                                              />
                                            </clipPath>
                                            <clipPath id='clip13'>
                                              <rect x='0' y='0' width='171465' height='200025' />
                                            </clipPath>
                                          </defs>
                                          <g
                                            clip-path='url(#clip8)'
                                            transform='translate(-673 -384)'
                                          >
                                            <g
                                              clip-path='url(#clip9)'
                                              transform='matrix(0.000104987 0 0 0.000104987 696 413)'
                                            >
                                              <g
                                                clip-path='url(#clip10)'
                                                transform='scale(1 1.01795)'
                                              >
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img3'
                                                  transform='scale(5555.75 5555.75)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip11)'
                                              transform='matrix(0.000104987 0 0 0.000104987 673 413)'
                                            >
                                              <g
                                                clip-path='url(#clip12)'
                                                transform='scale(1 1.01795)'
                                              >
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img3'
                                                  transform='scale(5555.75 5555.75)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip13)'
                                              transform='matrix(0.000104987 0 0 0.000104987 683 385)'
                                            >
                                              <g
                                                clip-path='url(#clip14)'
                                                transform='matrix(1.05546 0 0 1 0 -0.25)'
                                              >
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img3'
                                                  transform='scale(6250.78 6250.78)'
                                                ></use>
                                              </g>
                                            </g>
                                            <path
                                              d='M692.903 406.5 692.903 410.151 681.5 410.151 681.5 413.802'
                                              stroke='#48474D'
                                              stroke-width='1.33333'
                                              stroke-miterlimit='8'
                                              fill='none'
                                              fill-rule='evenodd'
                                            />
                                            <path
                                              d='M0 0 3.65089 0 3.65089 11.4029 7.30176 11.4029'
                                              stroke='#48474D'
                                              stroke-width='1.33333'
                                              stroke-miterlimit='8'
                                              fill='none'
                                              fill-rule='evenodd'
                                              transform='matrix(1.83697e-16 1 1 -1.83697e-16 692.5 406.5)'
                                            />
                                          </g>
                                        </svg>
                                        {'  '}Internal
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        height: '2px',
                                        width: '170px',
                                        marginTop: '6px',
                                        margin: '0px auto',
                                        backgroundColor: 'gainsboro',
                                      }}
                                    ></div>
                                  </>
                                )}
                                {/* <OverlayTrigger
                                    placement='top'
                                    delay={{show: 250, hide: 400}}
                                    overlay={
                                      <Tooltip id='button-tooltip-2'>
                                        {' '}
                                        Delegates your request to your Internal Maintenance Team
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      {' '}
                                      <AiOutlineQuestionCircle
                                        color='darkgray'
                                        fontSize={20}
                                        style={{cursor: 'pointer', marginRight: '30px'}}
                                      />
                                    </span>
                                  </OverlayTrigger> */}
                              </div>
                              <div className='form-check form-check-solid form-check-sm  py-3 px-5 col-12 col-sm-6'>
                                {requestType === 1 ? (
                                  <>
                                    <div
                                      style={{
                                        color: '#108B79',
                                        paddingBottom: '5px',
                                        height: '56px',
                                        width: '170px',
                                        fontWeight: '800px',

                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        margin: '0px auto'
                                      }}
                                      onClick={() => updateRequestType(1)}
                                    >
                                      <div className='text-end'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{show: 250, hide: 400}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              Outsources your request to your External Maintenance
                                              Provider
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineQuestionCircle
                                              color='#108B79'
                                              fontSize={16}
                                              style={{cursor: 'pointer', marginRight: '3px'}}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                      <div
                                        className=''
                                        style={{
                                          transform: 'translate(0px, -7px',
                                          fontWeight: '700',
                                          width: 'fit-content',
                                        }}
                                      >
                                        <img
                                          src={extImg}
                                          style={{
                                            height: '27px',
                                            width: '68px',
                                            marginLeft: '6px',
                                            marginRight: '7px',
                                          }}
                                        />
                                        {'  '} External
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        height: '2px',
                                        width: '170px',
                                        marginTop: '6px',

                                        backgroundColor: '#108B79',
                                        margin: '0px auto'
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        color: 'dimgray',
                                        paddingBottom: '5px',
                                        height: '56px',
                                        width: '170px',
                                        fontWeight: '800px',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                        margin: '0px auto'
                                      }}
                                      onClick={() => updateRequestType(1)}
                                    >
                                      <div className='text-end'>
                                        <OverlayTrigger
                                          placement='top'
                                          delay={{show: 250, hide: 400}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              Outsources your request to your External Maintenance
                                              Provider
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineQuestionCircle
                                              color='dimgray'
                                              fontSize={16}
                                              style={{cursor: 'pointer', marginRight: '3px'}}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                      <div
                                        className=''
                                        style={{
                                          transform: 'translate(0px, -7px',
                                          fontWeight: '700',
                                          width: 'fit-content',
                                        }}
                                      >
                                        <svg
                                          width='68'
                                          height='28'
                                          xmlns='http://www.w3.org/2000/svg'
                                          overflow='hidden'
                                          style={{marginLeft: '6px', marginRight: '7px'}}
                                        >
                                          <defs>
                                            <clipPath id='clip8'>
                                              <rect x='809' y='387' width='68' height='28' />
                                            </clipPath>
                                            <clipPath id='clip9'>
                                              <rect x='0' y='0' width='209550' height='209550' />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH+UExURQAAAAAAAFVVVUBAQDMzM2YzZklJSUBAQDk5VU1NTUZGRkBAVU5OTlBAUEtLS0NDUU1ATU1NTUNDTkpKSkVFTkxCTEZGT0hIUEdHTkpKUUZGTUtGS0pET0hITkdHTEdHTUZGS0pFTkdHTElFTkZGTkpGTklFTUhITEdHS0lJTUpGTkhIS0dHTkhFTEdHTkZGTUlGTUdHTUhGS0hITkhITUpHTUlGTElGTkhFTkdHTElHTEhGTkhITUdHTElGTklHTklGTkhGTUhITUdHTEdHTklGTUdHTkhGTUdHTkdHTEdHTklHTUdHTUhGTElHTUhGTEhGTkhITkdHTUhITkdHTUlHTUhGTEhGTkdHTUhITkhITUdHTUhHTEhGTUhGTUhITUhITUhGTUdHTEhHTUhITUlHTUhITkhHTUhITEdHTUlHTUhHTUlHTUdHTUhHTUhHTkhGTUdHTUhHTkhGTUlHTUhHTEhHTUhHTUhHTkhHTUdHTEhHTUlHTkdHTEhHTUhHTUlHTUhHTUdHTUhHTUhHTUhHTkdHTUhHTUhHTkhHTUdHTUlHTUhHTkhHTUhHTUhHTUhHTUhITUhHTUhHTEhHTUhHTUhHTUhHTkhITUhHTUhHTUhHTUhITUlITUhHTUhHTUhHTUdGTUhHTUhHTkhHTUhHTUhHTUlITkhHTUhHTYg9BjkAAACpdFJOUwABAwQFBQcICQoLDA0QERMUFBcYGhsdICQmKCwtLi8yMzQ2Oz4+P0BBQkVHSEpLTFBTWFhZWltbXF5eX2BhYmVmamprbG1vcXN2dnd6fH5/gICBg4SFhoaIiouMjY6RkpWYmpucnqSmp6ioqayvsLG1tri5uru8vsLDxcbMz9DR09TW19jZ2tvc3+Hh4+Xm5+nr7O3t7vDw8vP09fb29/j5+vr6+/z9/f4Wq3G7AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuklEQVQ4T23T+TtUcRTH8WOSSZssIUnIkq1NUgrZRslSWQppo6hkK5WlRcYyKCMtWsxIlvv5L33dc8a9c/P65fs+5/vMc2eemaEtUU0fPKvTj1Nk/I/DA7hnVoCOPbLxV4/FGweJdue6MBQsO7NTmI/n2jeIVi4z2yQyJClkVjssaUjFKymlHI1ShmtwSCnReCNluIPTUoodTilDHfKklAN4K2U4h/tSygm0SxlCvd9DJYle4IyUyT30Bko64PSlyd4R9Eduhr1BW0zUVxYR7+Dtul7Z5saPdFlZ7Lr5DcrK0yhZmO0/X1tip6CkgssnQ4gia6oydsiNLuDKT/VSZxJPtrIFNU0k86S7Bc/DSz3Q+i7ERCRcHcXy7aKX8KbKLVGK9jVOHWddm29BeX1ETcWY2frdDCBbP22Zd/uHuqtj9YHaUcFBMfgYIGl2SBuXKkeNlL9hRHM8wXEOixbkcLyH+ujbyEctx5dffFql4RHH3098WsWjk0Ob4tMqDl0cc392clhk+R7xHBc5LDpRwHFs7XehndMk/AGmfP/A0lUsTbv8fdbgPir3RInPZtfli/L5N9Ycpt8RbQAAKYeWyxBRRwAAAABJRU5ErkJggg=='
                                              preserveAspectRatio='none'
                                              id='img4'
                                            ></image>
                                            <clipPath id='clip10'>
                                              <rect
                                                x='-0.5'
                                                y='-0.25'
                                                width='209550'
                                                height='209550'
                                              />
                                            </clipPath>
                                            <clipPath id='clip11'>
                                              <rect x='0' y='0' width='128016' height='128016' />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAMAAABFNRROAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB+UExURQAAAFVVVUBAQDMzMzMzZlVVVVVVVU5OTkNDUUpKSkZGT0pKSk1GTUZGS0tGS0dHTUZGS0ZGT0lFTUZGS0dHTUpHTklHTUlHTUhITUlITEhGTUhFTUhGTUdHTUhHTUdGTUhHTUdGTUdHTUhHTUdGTEdHTEhHTUdGTEdHTEhHTDCgyaYAAAAqdFJOUwADBAUFCQwNExgdJigsLDIzNz9iZG+BgrW5vb/Ezc3Y4OHm6e3x8fL19TueJ/EAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABpSURBVBhXNcnpGoFgAETh06bSpg0l+rRy/zdIGfPrPc+g5eZ1FeHUR6P4XWeXMfzfieQWj2/VwyUpI/OL5oKL3eebrbbifJ+6bH/mgqrZBQeTUrQKbwlJZ0s1BE74lGE9BosvQ70OnggfCaQFmM1YMBsAAAAASUVORK5CYII='
                                              preserveAspectRatio='none'
                                              id='img5'
                                            ></image>
                                            <clipPath id='clip12'>
                                              <rect x='0' y='0' width='128016' height='128016' />
                                            </clipPath>
                                            <clipPath id='clip13'>
                                              <rect x='0' y='0' width='257175' height='257175' />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHgUExURQAAAAAAAIAAgICAgFVVVUBAQDMzZklJSUREREREVUBAUEdHR0NDUU1NTUlJSUZGUUpKSkdHR0dHUkxCTExMTElJSUlJUkZGT0RETUhIUEZGTUtES0dHTkVFTEpKSkhITkZGTUlJT0dHTUdHTElJTkdHTUZGS0pFTkhITUdHTElJTUhITElFTUlJTUlJTUhITEdHS0dHT0ZGTkhIS0dHTkhITEdHTkdHTUlGTUhITEhIT0hITkdHTUhITkhITkhITUlGTElGTkhITklHTEhGTkhITUlGTkdHTElGTkhITUdHTkdHTklHTUhGTUhITUhITEdHTkhITEdHTklHTUhITkdHTUhGTEhITEdHTklHTUdHTUlHTUhGTkdHTUdHTUlHTEhITUlHTUhHTkdHTUlHTEdHTUlHTkhGTUhITUlHTkhHTUhGTUlHTUhHTUhGTUhITUdHTEhHTUlHTUhHTUhGTEhITEhITkdHTUhHTUhHTUhITklHTUhHTUhGTkdHTUhITUdHTUhHTUhGTUdHTUhHTUhHTkhGTUhHTEhHTkhHTUhITUhHTUhHTkhHTUhGTUhITUdHTUlHTUhHTEhHTkhHTUhITUhHTkhHTUhGTUdHTUlHTUhHTUhHTUhHTUhHTUhHTY1MKngAAACgdFJOUwABAgIDBAUHDw8QEhMUFRYYGRkbGxwcHR4gISIkJSYnKCorLzEyMzQ1Njg5Pz9CQ0RERUdISktPUFFRUlNVWFlbW1xeX2BiZWZnaG9wcXFyc3V2d3l6fHx9foGCg4WIiYuMjY+QlpeYmZqbnJ6foKChoqWmp6enqKmqq6ytrq+ys7S1tre4ubu7vL2+vr/AwMHBwsLDxMXGx8jIycrN0NSq0mCDAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABt0lEQVQ4T2MgDbAn9/bEs0I5WIB0sw8jY2S1KJSLAXSmzJRkYFDun6YOFUADolO5pgIp1j7+GTwQETSQra2UDaKrxEwTwAJogG0SQ7IOiGEeyTgdLIIGFNPEO8EMlim8ZUJgFiowap2W6gkGeR2TsTmzy8neDgrsXRuhgsggdvbM6VAwa04QVBAFJKlCGQx6EVAGKkhUgzIY9MOgDFQQbAZlMDh5QBmoQCMNymAolYUy0EAbVFylBkJjALkpvCBKZLoYmIsFdM8wZWS2mV0L5WIAxok8cRP6ozgmQ/mogEXBMqYcwmwPtZBngTDhgCV0/lxf415wFOi2mvjPnxeCqiTTX7KQgYG/M1BKJrKJi4GhgTMgAyoFBto5DCxdQJrVsSDHlgnI6GdgyNUCS0FAhQJYDAGAHMUiKBsIJOqBRCdScueuAxItAhAOEHg5AIl8YIqGAZUUIOHuDOEAgXk0kIjShXBAwMIHSCQZQjhAwJxfqMLg5gLlAYG3FYNmeTojlAcCyrltiZFQNhAkR7dnKUHZMCAQOqXS20BeQFDeyK9mShAfVBgFCNtEZJQUp4dbY0v0OAADAwBPCVTYiotoYwAAAABJRU5ErkJggg=='
                                              preserveAspectRatio='none'
                                              id='img8'
                                            ></image>
                                            <clipPath id='clip14'>
                                              <rect x='-0.5' y='0' width='257175' height='257175' />
                                            </clipPath>
                                            <clipPath id='clip15'>
                                              <rect
                                                x='0'
                                                y='-4.65661e-10'
                                                width='133350'
                                                height='133350'
                                              />
                                            </clipPath>
                                            <image
                                              width='32'
                                              height='32'
                                              href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACrUExURQAAAICAgFVVVUBAQDMzMzMzZlVVVUBAQE1NTUZGUUpKSkJCTExCTExMTEtLS0lJSUlJUEpKSkZGTUdHTUtGS0pESkhITkhITUhIS0hITklGTkhITUdHTEhITEdHTkhITkhITUdHTUlHTUlHTUhHTUlHTUhHTUhHTEhHTUhHTUhGTUhHTUhHTkhITkdHTUlHTUhHTUhHTUhHTUhGTUhHTUhHTUhHTkhHTUhHTfTmsCEAAAA4dFJOUwACAwQFBQYIFBYYGxsbIiMjJigrLC0uNU5OYmprdX2AnKWlqKmsrbG0t9LX2dna2tvf4uTl+fr+ESJPhwAAAAlwSFlzAAAOwwAADsMBx2+oZAAAAN5JREFUOE+1k2kDgiAMhjG1S7uzsuy0+9Kyg///y3IEKKL5oXo/uI09wJCBfqJOmzpE1X6RelSlzcFfaDRAyHlgr059In03U9TlihHOE2N8jRPDrYIQJ8r3MC8S0wl8GWGRvED0bl0wlCjsZcIO4kTNY0QDRol+T5icaMIoESfWemjMs0wMAguM6n4g3mu4czARUYGQyA5aYLQLKc08UWIMEZEIGMckIG5hsAX4FuwYtEg2nxeZd8zEj/p3XrqIrIbhV5nRctFVpzdtlE+2/Uhq+5SH4wv5UDlP7zsh9AIzh0Bd8BWuOQAAAABJRU5ErkJggg=='
                                              preserveAspectRatio='none'
                                              id='img11'
                                            ></image>
                                            <clipPath id='clip16'>
                                              <rect x='-0.5' y='0' width='133350' height='133350' />
                                            </clipPath>
                                          </defs>
                                          <g
                                            clip-path='url(#clip8)'
                                            transform='translate(-809 -387)'
                                          >
                                            <g
                                              clip-path='url(#clip9)'
                                              transform='matrix(0.000104987 0 0 0.000104987 809 390)'
                                            >
                                              <g clip-path='url(#clip10)'>
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img4'
                                                  transform='matrix(6548.44 0 0 6548.44 -0.5 -0.25)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip11)'
                                              transform='matrix(7.42369e-05 -7.42369e-05 7.42369e-05 7.42369e-05 857.468 399.261)'
                                            >
                                              <g clip-path='url(#clip12)'>
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img5'
                                                  transform='scale(4000.5 4000.5)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip13)'
                                              transform='matrix(0.000104987 0 0 0.000104987 841 387)'
                                            >
                                              <g clip-path='url(#clip14)'>
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img8'
                                                  transform='matrix(8036.72 0 0 8036.72 -0.5 0)'
                                                ></use>
                                              </g>
                                            </g>
                                            <g
                                              clip-path='url(#clip15)'
                                              transform='matrix(0.000104987 0 0 0.000104987 830 394)'
                                            >
                                              <g clip-path='url(#clip16)'>
                                                <use
                                                  width='100%'
                                                  height='100%'
                                                  href='#img11'
                                                  transform='matrix(4167.19 0 0 4167.19 -0.5 0)'
                                                ></use>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                        {'  '} External
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        height: '2px',
                                        width: '170px',
                                        marginTop: '6px',
                                        backgroundColor: 'gainsboro',
                                        margin: '0px auto'
                                      }}
                                    ></div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className='d-flex tops p-0 mt-0 translateClass'
                        style={{
                          // justifyContent: 'space-between',
                          // flexDirection: 'row',
                          // transform: 'translate(0px,-60px)',
                        }}
                      >
                        <div className='row w-100 mx-1'>
                          {/* Save Button Animated*/}
                          {/* <button
                              className={
                                isSave === 0
                                  ? 'btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  : 'btn btn-sm fw-bold btn-primary btn-green mx-3 abc'
                              }
                              type='submit'
                              onClick={handleSubmit(saveStatus)}
                            >
                              Save
                            </button> */}
                          {/* Save Button Without Animation */}
                          {/* <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={saveAllRequestsUpdated}
                            style={{transform: 'translate(60px,-59px)', border: '1px solid #007a59', color:'#007a59'}}
                          >
                          </a> */}

                          <button
                            className={
                              isSave === 0
                                ? 'btn btn-sm fw-bold btn-primary btn-green me-3  ps-0 pe-4 col-4 col-md-2'
                                : 'btn btn-sm fw-bold btn-primary btn-green me-3 ps-0 pe-4 col-4 col-md-2'
                            }
                            type='submit'
                            onClick={() => saveRequest()}
                            style={{width: '90px', fontSize: '13px'}}
                          >
                            <img
                              src={saveImg}
                              style={{height: '20px', width: '20px', marginRight: '13px'}}
                            />{' '}
                            {'  '}
                            Save
                          </button>
                          {/* } */}
                          <a
                            className='btn btn-sm fw-bold  mx-3 ps-1 pe-4 col-2'
                            onClick={() => deleteStatus()}
                            style={{
                              width: '90px',
                              backgroundColor: '#D54645',
                              color: 'white',
                              fontSize: '13px',
                            }}
                          >
                            <img
                              src={deleteImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Delete
                          </a>
                          <div className='ms-auto  col-4 col-md-2 pe-0' style={{textAlign: 'end'}}>
                          <button
                            className='btn btn-sm fw-bold  ms-3 ps-2 pe-4'
                            onClick={() => submitRequest()}
                            style={{
                              border: '1px solid #48474D',
                              color: 'white',
                              backgroundColor: '#48474D',
                              width: '90px',
                            }}
                          >
                            <img
                              src={submitImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Submit
                          </button>
                        </div>
                          {/* onClick={() => removeTab(item)} */}
                        </div>
                       
                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-6 mb-5 '>
                          <div
                            className='card side px-5 py-3 transform-2'
                            style={{border: sdError}}
                          >
                            <h1 className='top green_color'>Service Details</h1>
                            {/* Request ID */}
                            <div className=' text-muted grid_big_col py-2'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                Request ID
                              </label>
                              <div className='mx-1 value_black'>{requestData?.id}</div>
                            </div>

                            {/* <h6 className='text-muted border-outline mx-0 my-2'>
                          Request ID <div className='mx-1 value_black'>{requestData?.id}</div>
                        </h6> */}

                            {/* Requestor */}
                            <div className=' text-muted grid_big_col py-2'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                Requestor
                              </label>
                              <div className='mx-1 value_black'>
                                {' '}
                                {data?.firstName} {data?.lastName}
                              </div>
                            </div>
                            {/* <h6 className='text-muted mx-0 my-2'>
                          Requestor{' '}
                          <div className='mx-1 value_black'>
                            {data?.firstName} {data?.lastName}
                          </div>
                        </h6> */}

                            {/* Main Service */}
                            <div className=' text-muted grid_big_col py-2'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Main Service
                              </label>
                              <input
                                type='text'
                                className={
                                  isMainService === true
                                    ? 'form-control form-control-solid  py-2  border-danger'
                                    : 'form-control form-control-solid  py-2 '
                                }
                                placeholder=''
                                name='mainService'
                                value={mainService?.name}
                                // onChange={(e) => {
                                //   setIsMainService(false)
                                //   // if(mainService?.name !== undefined){
                                //   // mainService.name = e.target.value
                                //   // }
                                // }}
                                disabled
                              />
                            </div>

                            {/* <h6
                          className='text-muted border-outline mx-0 '
                          style={{alignItems: 'center'}}
                        >
                          <span className='required'>Main Service</span>
                          <input
                            type='text'
                            className={
                              isMainService === true
                                ? 'form-control form-control-solid py-2 border-danger'
                                : 'form-control form-control-solid py-2'
                            }
                            name='name'
                            value={mainService?.name}
                            onChange={(e) => {
                              setIsMainService(false)
                              // if(mainService?.name !== undefined){
                              // mainService.name = e.target.value
                              // }
                            }}
                            disabled
                          />
                        </h6> */}

                            {/* Sub Service */}
                            <div className=' text-muted grid_big_col py-2'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Sub Service
                              </label>
                              <div className='d-flex'>
                                <input
                                  placeholder=''
                                  name='subService'
                                  type='text'
                                  id='internal-teamm'
                                  className={
                                    isSubService
                                      ? 'form-control form-control-solid border-danger  py-2 '
                                      : 'form-control form-control-solid  py-2'
                                  }
                                  value={subService?.name}
                                />
                                <a
                                  className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                  onClick={() =>
                                    navigate(
                                      '/request-management/service/create-request-service/select-list'
                                    )
                                  }
                                >
                                  Select
                                </a>
                              </div>
                            </div>

                            {/* <h6
                          className='text-muted border-outline mx-0 '
                          style={{alignItems: 'center'}}
                        >
                          <span className='required'>Sub Service</span>
                          <div className='d-flex  m-0 p-0'>
                            <input
                              type='text'
                              id='internal-teamm'
                              className={
                                isSubService
                                  ? 'form-control form-control-solid py-2 border-danger'
                                  : 'form-control form-control-solid py-2'
                              }
                              value={subService?.name}
                            />
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              onClick={() =>
                                navigate(
                                  '/request-management/service/create-request-service/select-list'
                                )
                              }
                            >
                              Select
                            </a>
                          </div>
                          <p></p>
                          {subService === '' && errors.subservice && (
                            <p style={{color: 'var(--kt-danger)'}} className='justify-content-end'>
                              This field is required
                            </p>
                          )}
                        </h6> */}

                            {/* Request Urgency */}

                            <div className=' text-muted grid_big_col py-2 justify-content-center align-items-center'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label pt-1'
                              >
                                Request Urgency
                              </label>

                              <div className='d-flex'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                  {requestUrgency === 0 ? (
                                    <div
                                      style={{
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: '#007a59',
                                        borderRadius: '50%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: '10px',
                                          width: '10px',
                                          backgroundColor: '#eff2f5',
                                          borderRadius: '50%',
                                          margin: '5px',
                                        }}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        height: '21px',
                                        width: '21px',
                                        backgroundColor: '#eff2f5',
                                        borderRadius: '50%',
                                      }}
                                      onClick={() => updateRequestUrgency(0)}
                                    ></div>
                                  )}

                                  {/* <input
                              className='form-check-input '
                              type='radio'
                              value={requestUrgency === 0 ? 0 : 0}
                              id='requestUrgency'
                              name='requestUrgency'
                              // onChange={(e) =>
                              //   setrequestUrgency({
                              //     ...requestUrgency,
                              //     requestUrgency: e.target.value,
                              //   })
                              // }
                              checked
                              onChange={(e)=> updateRequestUrgency(e)
                              }
                             
                            /> */}

                                  <label
                                    className='form-check-label value_black'
                                    htmlFor='requestUrgency'
                                    style={{marginRight: '25px'}}
                                  >
                                    Standard
                                  </label>
                                </div>
                                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                  {/* <input
                              className='form-check-input'
                              id="Internally"
                              type='radio'
                              value={requestUrgency === 1 ? 1 : 1}
                              name='requestUrgency'
                              // onChange={(e) =>
                              //   setrequestUrgency({
                              //     ...requestUrgency,
                              //     requestUrgency: e.target.value,
                              //   })
                              // }
                              onChange={(e)=> updateRequestUrgency(e)
                              }
                            /> */}

                                  {requestUrgency === 1 ? (
                                    <div
                                      style={{
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: '#007a59',
                                        borderRadius: '50%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: '10px',
                                          width: '10px',
                                          backgroundColor: '#eff2f5',
                                          borderRadius: '50%',
                                          margin: '5px',
                                        }}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        height: '21px',
                                        width: '21px',
                                        backgroundColor: '#eff2f5',
                                        borderRadius: '50%',
                                      }}
                                      onClick={() => updateRequestUrgency(1)}
                                    ></div>
                                  )}
                                  <label
                                    className='form-check-label value_black'
                                    htmlFor='Internally'
                                    style={{marginRight: '6px'}}
                                  >
                                    Emergency
                                  </label>
                                </div>
                                {/* <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                              <input
                                className='form-check-input '
                                type='radio'
                                value={requestUrgency === 0 ? 0 : 0}
                                id='requestUrgency'
                                name='requestUrgency'
                                onChange={(e) => {
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }}
                                checked
                              />
                              <label
                                className='form-check-label value_black'
                                htmlFor='requestUrgency'
                                style={{marginRight: '6px'}}
                              >
                                Standard
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value={requestUrgency === 1 ? 1 : 1}
                                id='requestStandard'
                                name='requestUrgency'
                                onChange={(e) => {
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }}
                              />
                              <label
                                className='form-check-label value_black'
                                htmlFor=''
                                style={{marginRight: '6px'}}
                              >
                                Emergency
                              </label>
                            </div> */}
                              </div>
                            </div>
                            {/* <h6
                          className='text-muted border-outline mx-0'
                          style={{alignItems: 'center'}}
                        >
                          Request Urgency
                          <div className='d-flex'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                              <input
                                className='form-check-input '
                                type='radio'
                                value={requestUrgency === 0 ? 0 : 0}
                                id='requestUrgency'
                                name='requestUrgency'
                                onChange={(e) =>
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }
                                checked
                              />
                              <label
                                className='form-check-label required value_black'
                                htmlFor='requestUrgency'
                                style={{marginRight: '6px'}}
                              >
                                Standard
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value={requestUrgency === 1 ? 1 : 1}
                                name='requestUrgency'
                                onChange={(e) =>
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }
                              />
                              <label
                                className='form-check-label required value_black'
                                htmlFor='Internally'
                                style={{marginRight: '6px'}}
                              >
                                Emergency
                              </label>
                            </div>
                          </div>
                        </h6> */}
                            {requestType === 1 && (
                              <>
                                {/* Internal Team for External Request */}
                                {/* <div className='card grid px-5 py-3 side'  style={{transform: 'translate(0px,-52px)'}}> */}

                                {/* <h6
                              className='text-muted border-outline mx-0'
                              style={{alignItems: 'center'}}
                            >
                              <span className='required'>Assigned To</span>
                              <div className='d-flex'>
                                <input
                                  type='text'
                                  {...register('internalteam', {required: true})}
                                  className={
                                    intExtAssignee
                                      ? 'form-control form-control-solid py-2 border-danger'
                                      : 'form-control form-control-solid py-2'
                                  }
                                  onChange={(e) =>
                                    setExtStaffData({...extStaffData, name: e.target.value})
                                  }
                                  // value={
                                  //   staffData?.firstName
                                  //     ? staffData?.firstName + ' ' + staffData?.lastName
                                  //     : ''
                                  // }
                                  value={intExtAssigneVal}
                                  name='internalteam'
                                  disabled={formData?.subServiceList ? true : false}
                                />
                                <a
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  onClick={() =>
                                    navigate(
                                      '/request-management/service/create-request-service/internal-team'
                                    )
                                  }
                                >
                                  Select
                                </a>
                              </div>
                              <p></p>
                              {staffData === '' && errors.internalteam && (
                                <p
                                  style={{color: 'var(--kt-danger)'}}
                                  className='justify-content-end'
                                >
                                  This field is required
                                </p>
                              )}
                            </h6> */}

                                {/* <div>
                              <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                Comments (For Internal Reference)
                              </h6>
                              <textarea
                                {...register('internalcomment', {required: true})}
                                className={
                                  intExtComment
                                    ? 'form-control form-control-solid py-2 border-danger'
                                    : 'form-control form-control-solid py-2'
                                }
                                rows={3}
                                name='internalcomment'
                                onChange={(e) => {
                                  addComment(e.target.value)
                                  var x = localStorage.getItem(
                                    'serviceRequestId' + requestServiceId
                                  )
                                  localStorage.setItem('intExtComment' + x, e.target.value)
                                  setIntExtCommentVal(e.target.value)
                                  setIntExtComment(false)
                                }}
                               
                                value={intExtCommentVal}
                                placeholder='Type comments here'
                              ></textarea>

                              {errors.internalcomment && (
                                <p
                                  style={{color: 'var(--kt-danger)'}}
                                  className='justify-content-end'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>  */}

                                {/* </div> */}
                              </>
                            )}

                            {/* <div className='m-0'>
                        <h6 className='text-muted'>Preferred Schedules For Service</h6>
                        {requestData?.schedule?.map((scg: any, id: any) => (
                          <div className='payment-receipt-item d-flex align-items-center mb-5'>
                            <label className='text-muted  mr-2 required'>Date</label>
                            <DatePicker
                              className='mx-1 form-control form-control-solid'
                              name='fromDate'
                              defaultValue={moment(scg?.date, 'DD/MM/YYYY')}
                              onChange={(e: any) =>
                                setScheduleEvent(new Date(e), id, 'preferScheduleDate')
                              }
                            />
                            <label className='text-muted mr-2 required'>Time</label>
                            <TimePicker
                              className='form-control form-control-solid'
                              size='large'
                              defaultValue={moment(scg?.time, 'HH:mm')}
                              onChange={(e: any) => setScheduleEvent(e, id, 'preferScheduleTime')}
                            />
                          </div>
                        ))}
                      </div> */}
                          </div>
                        </div>

                        {/* Internal Team */}

                        {requestType === 2 && (
                          <div className='col-12 col-md-6 mb-5'>
                            <div
                              className='card px-5 py-3 side transform-2 '
                              style={{ border: intError}}
                            >
                              <h1 className='top green_color'>Internal Team</h1>

                              <div className=' text-muted grid_big_col py-2'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Assigned To
                                </label>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    className={
                                      intAssignee
                                        ? 'form-control form-control-solid py-2 border-danger'
                                        : 'form-control form-control-solid py-2'
                                    }
                                    onChange={(e) =>
                                      setStaffData({...staffData, name: e.target.value})
                                    }
                                    // value={
                                    //   staffData?.firstName
                                    //     ? staffData?.firstName + ' ' + staffData?.lastName
                                    //     : ''
                                    // }
                                    value={intAssigneVal}
                                    name='internalteam'
                                    // disabled={formData?.subServiceList ? true : false}
                                  />
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                    onClick={() =>
                                      navigate(
                                        '/request-management/service/create-request-service/internal-team'
                                      )
                                    }
                                  >
                                    Select
                                  </a>
                                </div>
                              </div>

                              {/* <h6
                            className='text-muted border-outline mx-0'
                            style={{alignItems: 'center'}}
                          >
                            <span className='required form-label'>Assigned To</span>
                            <div className='d-flex'>
                              <input
                                type='text'
                                className={
                                  intAssignee
                                    ? 'form-control form-control-solid py-2 border-danger'
                                    : 'form-control form-control-solid py-2'
                                }
                                onChange={(e) => setStaffData({...staffData, name: e.target.value})}
                                // value={
                                //   staffData?.firstName
                                //     ? staffData?.firstName + ' ' + staffData?.lastName
                                //     : ''
                                // }
                                value={intAssigneVal}
                                name='internalteam'
                                // disabled={formData?.subServiceList ? true : false}
                              />
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() =>
                                  navigate(
                                    '/request-management/service/create-request-service/internal-team'
                                  )
                                }
                              >
                                Select
                              </a>
                            </div>
                            <p></p>
                            {staffData === '' && errors.internalteam && (
                              <p
                                style={{color: 'var(--kt-danger)'}}
                                className='justify-content-end'
                              >
                                This field is required
                              </p>
                            )}
                          </h6> */}

                              <div className=' text-muted grid_big_col py-2'>
                                <label htmlFor='exampleFormControlInput1' className='form-label'>
                                  Comments (For Internal Reference)
                                </label>
                                <textarea
                                  value={intCommentVal}
                                  className={
                                    intComment
                                      ? 'form-control form-control-solid py-2 border-danger'
                                      : 'form-control form-control-solid py-2'
                                  }
                                  rows={4}
                                  name='internalcomment'
                                  onChange={(e) => {
                                    setIntCommentVal(e.target.value)
                                    setIntComment(false)
                                    setRequestData({...requestData, comment: e.target.value})
                                    var x = localStorage.getItem(
                                      'serviceRequestId' + requestServiceId
                                    )
                                    localStorage.setItem('internalComment' + x, e.target.value)
                                  }}
                                  /*                                                 onChange={(e)=>addComment(e.target.value) (e) => setRequestData({ ...requestData, 'comment': e.target.value })}
                                   */
                                  placeholder='Type comments here'
                                  onBlur={() => saveComment()}
                                ></textarea>
                              </div>
                              {/* <div>
                            <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                              Comments (For Internal Reference)
                            </h6>
                            <textarea
                              value={intCommentVal}
                              className={
                                intComment
                                  ? 'form-control form-control-solid py-2 border-danger'
                                  : 'form-control form-control-solid py-2'
                              }
                              rows={3}
                              name='internalcomment'
                              onChange={(e) => {
                                setIntCommentVal(e.target.value)
                                setIntComment(false)
                                setRequestData({...requestData, comment: e.target.value})
                                var x = localStorage.getItem('serviceRequestId' + requestServiceId)
                                localStorage.setItem('internalComment' + x, e.target.value)
                              }}
                            
                              placeholder='Type comments here'
                            ></textarea>

                            {errors.internalcomment && (
                              <p
                                style={{color: 'var(--kt-danger)'}}
                                className='justify-content-end'
                              >
                                This field is required
                              </p>
                            )}
                          </div> */}
                            </div>
                          </div>
                        )}

                        {/* External Team */}

                        {requestType === 1 && (
                          <>
                            <div className='col-12 col-md-6 mb-5'>
                              <div className='side transform-2' >
                                <div className='card px-5 py-3 pb-5' style={{border: spError}}>
                                  <h1 className='top green_color'>Service Provider Details</h1>
                                  <div className=' text-muted grid_big_col py-2'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label'
                                    >
                                      Service Provider
                                    </label>
                                    <div className='d-flex'>
                                      <input
                                        {...register('subNewServiceList', {required: true})}
                                        type='text'
                                        className={
                                          extAssignee
                                            ? 'form-control form-control-solid py-2 border-danger'
                                            : 'form-control form-control-solid py-2'
                                        }
                                        name='subNewServiceList'
                                        onChange={(e) =>
                                          setVendorList({...vendorList, name: e.target.value})
                                        }
                                        value={`${vendorList ? `${vendorList?.companyName}` : ''}`}
                                      />
                                      <a
                                        className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                        onClick={() =>
                                          navigate(
                                            '/request-management/service/service-provider-list'
                                          )
                                        }
                                      >
                                        Select
                                      </a>
                                    </div>
                                  </div>

                                  {/* <div className='d-flex align-items-center me-10 mb-3'>
                              <h6
                                className='text-muted m-0 mr-2 min-w-100px mb-2'
                                style={{alignItems: 'center'}}
                              >
                                <span className='required'>Service Provider</span>
                                <div className='d-flex'>
                                  <input
                                    {...register('subNewServiceList', {required: true})}
                                    type='text'
                                    className={
                                      extAssignee
                                        ? 'form-control form-control-solid py-2 border-danger'
                                        : 'form-control form-control-solid py-2'
                                    }
                                    name='subNewServiceList'
                                    onChange={(e) =>
                                      setVendorList({...vendorList, name: e.target.value})
                                    }
                                    value={`${
                                      vendorList
                                        ? `${vendorList?.firstName || ''} ${vendorList?.lastName}`
                                        : ''
                                    }`}
                                  />
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                    onClick={() =>
                                      navigate('/request-management/service/service-provider-list')
                                    }
                                  >
                                    Select
                                  </a>
                                </div>
                                <p></p>
                                {vendorList === '' && errors.subNewServiceList && (
                                  <p
                                    style={{color: 'var(--kt-danger)'}}
                                    className='justify-content-end'
                                  >
                                    Please select Service Provider
                                  </p>
                                )}
                              </h6>
                            </div> */}

                                  <div className=' text-muted grid_big_col py-2'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='form-label'
                                    >
                                      Request Details (For Selected Vendor)
                                    </label>
                                    <textarea
                                      value={extCommentVal}
                                      {...register('comment', {required: true})}
                                      className={
                                        extComment
                                          ? 'form-control form-control-solid py-2 border-danger'
                                          : 'form-control form-control-solid py-2'
                                      }
                                      rows={4}
                                      name='comment'
                                      onChange={(e: any) => {
                                        handleChnage(e)
                                        setExtCommentVal(e.target.value)
                                        setExtComment(false)
                                        var x = localStorage.getItem(
                                          'serviceRequestId' + requestServiceId
                                        )
                                        localStorage.setItem('externalComment' + x, e.target.value)
                                        setRequestData({
                                          ...requestData,
                                          vendorComment: e.target.value,
                                        })
                                      }}
                                      onBlur={() => saveComment()}
                                    ></textarea>
                                  </div>

                                  {/* <div className='mb-3'>
                              <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                Request Details (For Selected Vendor)
                              </h6>
                              <textarea
                                value={extCommentVal}
                                {...register('comment', {required: true})}
                                className={
                                  extComment
                                    ? 'form-control form-control-solid py-2 border-danger'
                                    : 'form-control form-control-solid py-2'
                                }
                                rows={3}
                                name='comment'
                                onChange={(e: any) => {
                                  handleChnage(e)
                                  setExtCommentVal(e.target.value)
                                  setExtComment(false)
                                  var x = localStorage.getItem(
                                    'serviceRequestId' + requestServiceId
                                  )
                                  localStorage.setItem('externalComment' + x, e.target.value)
                                }}
                              ></textarea>
                            </div> */}
                                </div>

                                <div
                                  className='card px-5 py-3 pb-5 mt-5'
                                  style={{border: spIntError}}
                                >
                                  <h1 className='top green_color'>Internal Team</h1>

                                  {/* Internal Assigned To */}
                                  <div className=' text-muted grid_big_col py-2'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='required form-label'
                                    >
                                      Assigned To
                                    </label>
                                    <div className='d-flex'>
                                      <input
                                        type='text'
                                        {...register('internalteam', {required: true})}
                                        className={
                                          intExtAssignee
                                            ? 'form-control form-control-solid py-2 border-danger'
                                            : 'form-control form-control-solid py-2'
                                        }
                                        onChange={(e) =>
                                          setExtStaffData({...extStaffData, name: e.target.value})
                                        }
                                        // value={
                                        //   staffData?.firstName
                                        //     ? staffData?.firstName + ' ' + staffData?.lastName
                                        //     : ''
                                        // }
                                        value={intExtAssigneVal}
                                        name='internalteam'
                                        disabled={formData?.subServiceList ? true : false}
                                      />
                                      <a
                                        className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                        onClick={() =>
                                          navigate(
                                            '/request-management/service/create-request-service/internal-team'
                                          )
                                        }
                                      >
                                        Select
                                      </a>
                                    </div>
                                  </div>
                                  <div className=' text-muted grid_big_col py-2'>
                                    <label
                                      htmlFor='exampleFormControlInput1'
                                      className='form-label'
                                    >
                                      Comments (For Internal Reference)
                                    </label>
                                    <textarea
                                      {...register('internalcomment', {required: true})}
                                      className={
                                        intExtComment
                                          ? 'form-control form-control-solid py-2 border-danger'
                                          : 'form-control form-control-solid py-2'
                                      }
                                      rows={3}
                                      name='internalcomment'
                                      onChange={(e) => {
                                        addComment(e.target.value)
                                        var x = localStorage.getItem(
                                          'serviceRequestId' + requestServiceId
                                        )
                                        localStorage.setItem('intExtComment' + x, e.target.value)
                                        setIntExtCommentVal(e.target.value)
                                        setIntExtComment(false)
                                        setRequestData({...requestData, comment: e.target.value})
                                      }}
                                      /*                                                 onChange={(e)=>addComment(e.target.value) (e) => setRequestData({ ...requestData, 'comment': e.target.value })}
                                       */
                                      value={intExtCommentVal}
                                      placeholder='Type comments here'
                                      onBlur={() => saveComment()}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div
                        className='card p-5 tops container-xxl transform-2'
                        style={{ border: pError}}
                        ref={myRef}
                        key={index}
                      >
                        <div className=' align-item-center pt-2'>
                          <Row className='mb-3'>
                            <Col xs={6} sm={7} md={7} className='mb-5'>
                              <div className='row'>
                                <div className='col-12 mb-5 col-sm-5 col-md-4'>
                                  <h1 className='m-0 green_color pt-1'> Properties</h1>
                                </div>
                                <div className='col-12  col-sm-7 col-md-6 mb-8'>
                                  <div className=''>
                                    {requestorType !== '1' && (
                                      <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        onClick={() =>
                                          navigate(
                                            '/request-management/service/create-request-service/select-property'
                                          )
                                        }
                                      >
                                        <img src={plus} height={16} width={16} /> Select Properties
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col xs={6} sm={5} md={5} className='mb-5 ms-auto'>
                              <div
                                className='gap-5  align-items-left p-5 br-5'
                                style={{background: '#19ffbe1c'}}
                              >
                                <Row className=''>
                                  <Col md={6}>
                                    <h4 className='' style={{color: 'black'}}>
                                      {' '}
                                      <b>Job Summary</b>
                                    </h4>
                                  </Col>
                                  <Col md={6} className='text-end pr-1'>
                                    <h5 className='my-1'>
                                      Developments <b className='ms-2'>{devplmnt}</b>
                                    </h5>
                                    <h5 className='mb-1'>
                                      Units <b className='ms-2'>{totalUnits}</b>
                                    </h5>
                                    <h5 className='mb-1'>
                                      Jobs <b className='ms-2'>{totalJobs}</b>
                                    </h5>
                                  </Col>
                                </Row>

                                {/* {propertyDevelopmentSummary &&
                  propertyDevelopmentSummary.map((dev: any, index: any) => {
                    let totalUnitss: any = 0
                    return (
                      <div className='row mt-3'>
                     
                        {dev.community ? (
                          <>
                            <h6 className='col-md-7'>
                              <b>
                                {index + 1}
                                {'. '}
                                {dev.community[0].name}
                              </b>
                              <ul>
                                {dev?.community[0]?.cluster.length !== 0 &&
                                  dev?.community[0]?.cluster.map((cl: any) => {
                                    totalUnitss = totalUnitss + cl?.unit?.length
                                    return (
                                      <li>
                                        {cl.name}
                                        <ul>
                                          {cl?.building.length !== 0 ? (
                                            cl?.building.map((bld: any) => (
                                              <li>
                                                {bld.name}
                                                <ul>
                                                  {bld?.floor.length !== 0 &&
                                                    bld?.floor.map((fl: any) => {
                                                      if (fl?.units?.length !== 0)
                                                        totalUnitss = totalUnitss + fl?.unit?.length
                                                      return <li>{fl.name}</li>
                                                    })}
                                                </ul>
                                              </li>
                                            ))
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </li>
                                    )
                                  })}
                              </ul>
                            </h6>
                            <h6 className='col-md-5 text-end'>
                              Total Units <b className='ms-3'>{totalUnitss}</b>
                            </h6>
                           
                          </>
                        ) : (
                          // Building
                          <>
                            <h6 className='col-md-7'>
                              <b>
                                {index + 1}
                                {'. '}
                                {dev.building[0].name}
                                <ul>
                                  {dev.building[0]?.floor?.length !== 0 &&
                                    dev.building[0]?.floor.map((fl: any) => {
                                      if (fl?.unit?.length !== 0)
                                        totalUnitss = totalUnitss + fl?.unit?.length
                                      return <li>{fl?.name}</li>
                                    })}
                                </ul>
                              </b>
                            </h6>
                            <h6 className='col-md-5 text-end'>
                              Total Units <b className='ms-3'>{totalUnitss}</b>
                            </h6>
                          
                          </>
                        )}
                        
                      </div>
                    )
                  })} */}
                              </div>
                            </Col>
                          </Row>

                          {/* <h1 className='m-0 green_color'>
                            Properties
                            {requestorType !== '1' && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() =>
                                  navigate(
                                    '/request-management/service/create-request-service/select-property'
                                  )
                                }
                              >
                                Select Properties
                              </a>
                            )}
                          </h1>
                          {propertyListValidate && (
                            <p style={{color: 'var(--kt-danger)'}} className='justify-content-end'>
                              Please select properties
                            </p>
                          )} */}
                        </div>
                        {/* <div className='row mb-5'>*/}
                        <div className='my-7 table-responsive'>
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>Job Id</th>
                                <th className='min-w-100px'>Development</th>
                                <th className='min-w-100px'>Floor/Cluster</th>
                                <th className='min-w-100px'>Unit</th>
                                <th className='min-w-100px'>Unit Type</th>
                                <th className='min-w-100px'>Bedrooms</th>
                                <th className='min-w-200px'>Job Schedule</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {propertyList.length ? (
                                propertyList?.map((f: any, i: any) => {
                                  return (
                                    <tr className='text-center' style={{verticalAlign: 'baseline'}}>
                                      <td>{f?.id ?? '-'}</td>
                                      <td>
                                        {f?.community.length !== 0 ? 'Community' : 'Building'}
                                      </td>
                                      <td>{f?.community.length !== 0 ? 'Cluster' : 'Floor'}</td>
                                      <td>{f?.unit[0]?.unitNo ?? '-'}</td>
                                      <td>{f?.unit[0]?.unitType ?? '-'}</td>
                                      <td>{f?.unit[0]?.bedrooms ?? '-'}</td>
                                      <td className='align-items-center gap-4 justify-content-center'>
                                        <p
                                          className='d-flex align-items-center gap-4 justify-content-center'
                                          style={{marginBottom: '0em'}}
                                        >
                                          {f?.schedule?.[0]?.date
                                            ? moment(f?.schedule?.[0]?.date).format('DD.MM.YYYY')
                                            : null}{' '}
                                          {f?.schedule?.[0]?.startTime
                                            ? timeConvert(f?.schedule?.[0]?.startTime) + '-'
                                            : null}
                                          {f?.schedule?.[0]?.endTime
                                            ? timeConvert(f?.schedule?.[0]?.endTime)
                                            : null}{' '}
                                          <a
                                            onClick={() => {
                                              setShowCreateAppModal1(true)
                                              setModalData(f)
                                            }}
                                          >
                                            <img
                                              src={editDate}
                                              alt=''
                                              style={{marginTop: '-5px'}}
                                            />
                                          </a>
                                        </p>
                                        {f?.schedule?.[0]?.date === null &&
                                          f?.schedule?.[0]?.startTime === null &&
                                          f?.schedule?.[0]?.endTime === null && (
                                            <p style={{color: 'rgb(213, 70, 69)'}}>
                                              {' '}
                                              Please select job schedule{' '}
                                            </p>
                                          )}

                                        {f?.schedule?.[0]?.date && (
                                          <>
                                            {/* Same Day */}
                                            {new Date(f?.schedule?.[0]?.date).getFullYear() ===
                                              today.getFullYear() &&
                                            new Date(f?.schedule?.[0]?.date).getMonth() ===
                                              today.getMonth() &&
                                            new Date(f?.schedule?.[0]?.date).getDate() ===
                                              today.getDate() ? (
                                              <>
                                                {currentHours >
                                                parseInt(
                                                  timeConvert(f?.schedule?.[0]?.startTime).split(
                                                    ':'
                                                  )[0]
                                                ) ? (
                                                  <p style={{color: 'rgb(213, 70, 69)'}}>
                                                    {' '}
                                                    Schedule has Expired
                                                  </p>
                                                ) : (
                                                  <>
                                                    {parseInt(
                                                      timeConvert(
                                                        f?.schedule?.[0]?.startTime
                                                      ).split(':')[0]
                                                    ) -
                                                      currentHours <=
                                                    6 ? (
                                                      <p style={{color: 'rgb(213, 70, 69)'}}>
                                                        {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                                        Schedule is Approaching
                                                      </p>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : // next Day
                                            new Date(f?.schedule?.[0]?.date) > today ? (
                                              <>
                                                {new Date(f?.schedule?.[0]?.date).getDate() ===
                                                today.getDate() + 1 ? (
                                                  <>
                                                    {24 -
                                                      currentHours +
                                                      parseInt(
                                                        timeConvert(
                                                          f?.schedule?.[0]?.startTime
                                                        ).split(':')[0]
                                                      ) <=
                                                      6 && (
                                                      <p style={{color: 'rgb(213, 70, 69)'}}>
                                                        Schedule is Approaching
                                                      </p>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                                <></>
                                              </>
                                            ) : (
                                              <p style={{color: 'rgb(213, 70, 69)'}}>
                                                Schedule has expired
                                              </p>
                                            )}
                                          </>
                                        )}

                                        {/* { moment(f?.schedule?.[0].startTime).diff(currentTime, 'hours') <= 6 &&
                                       <>fhgfhgfhg</>
                                        } */}
                                        {/* {
                                       ( moment(f?.schedule?.[0]?.date+'' +''+timeConvert(f?.schedule?.[0]?.startTime)).diff(currentTime, 'hours')) < 6 ?
                                       <p style={{color: 'var(--kt-danger)'}}>
                                       {' '}
                                       Schedule is approaching{' '}
                                     </p>
                                     :
                                   
                                    

                                      } */}
                                      </td>
                                      <td>
                                        <button
                                          className='btn btn-sm fw-bold btn-primary btn-green hover-none min-w-150px'
                                          onClick={() => openModal(f)}
                                        >
                                          Media & Comments
                                        </button>
                                      </td>
                                      <td>
                                        <AiFillDelete
                                          color='#D54645'
                                          fontSize={20}
                                          style={{cursor: 'pointer'}}
                                          onClick={() =>
                                            Swal.fire({
                                              html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the service job, do you wish to proceed?</p>',
                                              showConfirmButton: true,
                                              confirmButtonColor: '#007a59',
                                              confirmButtonText: 'Confirm',
                                              showCancelButton: true,
                                              cancelButtonText: 'Cancel',
                                            }).then((res) => {
                                              if (res.isConfirmed) {
                                                deleteJob(f?._id)
                                              }
                                            })
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div> */}
                        <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end'>
                          <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                          >
                            <ReactPaginate
                              breakLabel='...'
                              nextLabel='>'
                              pageClassName='paginate_button page-item +'
                              pageLinkClassName='page-link'
                              containerClassName='pagination'
                              activeClassName='active'
                              previousClassName='paginate_button page-item previous disabled'
                              nextClassName='paginate_button page-item next'
                              previousLinkClassName='page-link'
                              nextLinkClassName='page-link'
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageLimit}
                              previousLabel='<'
                            />
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </>
                </div>
              </>
            )
          })}
        </div>
      </div>

      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        propertyList={propertyList}
        handleClose={() => {
          setMediaPopup(false)
        }}
        id={jobId}
        mediaPop={mediaPop}
      />

      { (showCreateAppModal1 && modalData) && 
      <Datechange
        show={showCreateAppModal1}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        serviceRequestData={serviceRequestData}
        modalData={modalData}
      />
}
    </>
  )
}

export default CreateService

import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import './style.scss'
import {Col, Row} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

interface ButtonProps {
  show: any
  handleSubmitId: (message: string) => void
  handleClose: any
  addedProperties: any
}

const SelectPropertyModal = ({handleClose, show, addedProperties, handleSubmitId}: ButtonProps) => {
  const [searchDevelopment, setSearchDevelopment] = useState<any>()
  const [result, setResult] = useState<any>()
  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>([])

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>([])

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  let totalSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // 
  const getDevelopment = () => {
    const body = {
      search: searchDevelopment,
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setDevelopmentLists(values)
        if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    getDevelopment()
  }, [])

  const getClusters = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      clusterIds: [],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setClustersLists(values)

        if (isFirst) setSelectedClusters(values)
        else {
          // let filteredValues = values
          // selectedClusters?.map((sl:any, ind:any)=>{

          // filteredValues = filteredValues.filter(
          //     (vl:any) => sl?.value == vl?.value
          //   )
          // })

          // setSelectedClusters(filteredValues)
          const filteredValues = selectedClusters.filter((cluster: any) =>
            values.some((value: any) => value.value === cluster.value)
          )

          setSelectedClusters(filteredValues)
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedDevelopments?.length != 0) getClusters()
  }, [selectedDevelopments])

  const getUnitGroups = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      unitGroupIds: [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setUnitGrpsLists(values)

        if (isFirst) setSelectedUnitGrps(values)
        else {
          const filteredValues = selectedUnitGrps.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          setSelectedUnitGrps(filteredValues)
        }

        setIsFirst(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedClusters?.length != 0) getUnitGroups()
  }, [selectedClusters])

  //
  const updateList = async () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitgrpIds: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitgrpIds[ind] = dev?.value
      })

    const body = {
      searchDevelopment: searchDevelopment,
      developmentIds: devIds,
      buildingIds: [],
      clusterIds: clustIds,
      unitGroupIds: unitgrpIds,
    }
    await ApiPost(`corporate/vendor/get/development`, body)
      .then((res) => {
        let values = [...res?.data?.data]
        res?.data?.data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        for (let j = 0; j < res?.data?.data?.length; j++) {
          let isCheckForDev = false
          let isCheckForAll = false
          let isChckforDevClust = false
          let isCheckForDevUnitGrp = false

          if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isCheckForDev = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForAll = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isChckforDevClust = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForDevUnitGrp = true

          // console.log(isCheckForAll)
          // console.log(isChckforDevClust)
          // console.log(isCheckForDevUnitGrp)

          // Dev only
          if (isCheckForDev) {
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?._id) {
                x = true
                return
              }
            })

            if (x) values[j].check = true
          }
          // Dev, Clust and Unit Grouo
          else if (isCheckForAll) {
            // addedProperties?.assignedProperties?.developmentIds?.includes(values[j]?._id)
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?._id) {
                x = true
                return
              }
            })

            // let y = addedProperties?.assignedProperties?.clusterIds?.includes(
            //   values[j]?.cluster?._id
            // )
            let y = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.clusterId == values[j]?.cluster?._id) {
                y = true
                return
              }
            })

            // let z = addedProperties?.assignedProperties?.unitGroupIds?.includes(
            //   values[j]?.unitGroup?._id
            // )
            let z = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.unitGroupId == values[j]?.unitGroup?._id) {
                z = true
                return
              }
            })

            if (x && y && z) values[j].check = true
          }
          // Dev and Clust
          else if (isChckforDevClust) {
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              console.log(prop?.assignedProperties?.developmentId)
              console.log(values[j]?._id)

              if (prop?.assignedProperties?.developmentId == values[j]?._id) {
                x = true
                return
              }
            })

            // let y = addedProperties?.assignedProperties?.clusterIds?.includes(
            //   values[j]?.cluster?._id
            // )
            let y = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.clusterId == values[j]?.cluster?._id) {
                y = true
                return
              }
            })

            if (x && y) values[j].check = true
          }

          // Dev and Unit Group
          else if (isCheckForDevUnitGrp) {
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?._id) {
                x = true
                return
              }
            })

            // let z = addedProperties?.assignedProperties?.unitGroupIds?.includes(
            //   values[j]?.unitGroup?._id
            // )
            let z = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.unitGroupId == values[j]?.unitGroup?._id) {
                z = true
                return
              }
            })
            if (x && z) values[j].check = true
          }
        }

        setResult(values)
      })
      .catch((e) => {
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    updateList()
  }, [searchDevelopment, selectedDevelopments, selectedClusters, selectedUnitGrps])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                Select Property
              </h2>

              <div className='d-flex ms-auto'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  red-hollow-btn px-2 status-w-100'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-4'/> Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn px-2 status-w-100'
                  onClick={() => {
                    handleSubmitId(result)
                    handleClose()
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-4' /> Add
                </button>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Development...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchDevelopment}
                        onChange={(e: any) => {
                          setSearchDevelopment(e.target.value)
                        }}
                        disabled={selectedDevelopments?.length > 0 || selectedClusters?.length > 0 || selectedUnitGrps?.length > 0 }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    {/* <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control'
                      name='contractNo'
                      value={searchDevelopment}
                      onChange={(e: any) => {
                        setSearchDevelopment(e.target.value)
                      }}
                      placeholder={'Development'}
                    />  */}
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Filter
                </label>
              </div>
              <div className='col-11'>
                <>
                  <div className='d-flex test'>
                    {/* Development */}
                    <div
                      className={`multi-select-container property me-5 ${
                        selectedDevelopments.length === 0 ? 'no-value dev' : 'has-value '
                      }`}
                    >
                      <MultiSelect
                        options={developmentLists}
                        value={selectedDevelopments}
                        onChange={(e: any) => {
                          console.log(e)
                          setSelectedDevelopments(e)
                        }}
                        labelledBy={'Select'}
                        overrideStrings={{
                          // selectAll: "Select All",
                          allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>

                    {/* Cluster */}
                    <div
                      className={`multi-select-container property ms-2 me-5 ${
                        selectedClusters.length === 0 ? 'no-value clust' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={clustersLists}
                        value={selectedClusters}
                        onChange={(e: any) => {
                          console.log(e)
                          setSelectedClusters(e)
                        }}
                        labelledBy='Cluster'
                        overrideStrings={{
                          // selectAll: "Select All",
                          allItemsAreSelected: 'All Clusters', // Custom text for when all items are selected
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>

                    {/* Unit Groups */}
                    <div
                      className={`multi-select-container property ms-2 ${
                        selectedUnitGrps.length === 0 ? 'no-value grpunit' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={unitGrpsLists}
                        value={selectedUnitGrps}
                        onChange={(e: any) => {
                          console.log(e)
                          setSelectedUnitGrps(e)
                        }}
                        labelledBy='Unit Groups'
                        overrideStrings={{
                          allItemsAreSelected: 'All Unit Groups', // Custom text for when all items are selected
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            {result?.map((res: any, ind: any) => {
              if (res?.check == true) totalSelected = totalSelected + 1
              return <></>
            })}

            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className='' style={{maxHeight: '380px', overflow: 'auto'}}>
                  <div className='table-responsive '>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='text-start w-25px  test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                              <input
                                className='form-check-input cursor-pointer'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={
                                  totalSelected == result?.length && totalSelected?.length != 0
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  let values: any = [...result]
                                  if (e.target.checked) {
                                    values?.map((list: any) => {
                                      list.check = true
                                    })
                                  } else {
                                    values?.map((list: any) => {
                                      list.check = false
                                    })
                                  }
                                  setResult(values)
                                }}
                              />
                            </div>
                          </th>
                          <th className='text-start min-w-100px'>Development</th>
                          <th className='text-start min-w-100px'>Cluster</th>
                          <th className='text-start min-w-100px'>Group</th>
                        </tr>
                      </thead>

                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {result?.map((rs: any, index: any) => {
                          return (
                            <tr>
                              <td className='text-start w-25px  test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                  <input
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={rs?.check}
                                    onChange={(e: any) => {
                                      const values = [...result]
                                      values[index].check = e.target.checked
                                      setResult(values)
                                    }}
                                  />
                                </div>
                                {/* {radioChecked === index ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#146c6a',
                                    borderRadius: '50%',
                                  }}
                                  className='me-5'
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #146c6a',
                                  }}
                                  onClick={() => {
                                    setRadioChecked(index)
                                    setSelectedId(rs?._id)
                                    setSelectedName(rs?.name)
                                  }}
                                  className='me-5'
                                ></div>
                              )} */}
                              </td>
                              <td className='text-start min-w-100px'>
                                {rs?.name ? `${rs?.name}` : `-`}
                              </td>
                              <td className='text-start min-w-100px'>
                                {rs?.cluster?.name ? `${rs?.cluster?.name}` : `-`}
                              </td>
                              <td className='text-start min-w-100px'>
                                {rs?.unitGroup?.name ? `${rs?.unitGroup?.name}` : `-`}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectPropertyModal

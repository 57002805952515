import toast from "react-hot-toast";

export const SuccessToast = (message) => {
  return toast.success(
    (t) => (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>{message}</span>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="cursor-pointer fs-2 fnt-600 text-dark bg-transparent border-white pe-0"
          style={{
            background: "none",
            border: "none",
            marginLeft: "10px",
            marginRight: '-5px'
          }}
        >
          ✖
        </button>
      </div>
    ),
    {
      style: {
        padding: "16px",
      },
    }
  );
};

export const ErrorToast = (message) => {
  return toast.error(
    (t) => (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>{message}</span>
        <button
          onClick={() => toast.dismiss(t.id)}
          className="cursor-pointer fs-2 fnt-600 text-dark bg-transparent border-white pe-0"
          style={{
            background: "none",
            border: "none",
            marginLeft: "10px",
            marginRight: '-5px'
          }}
        >
          ✖
        </button>
      </div>
    ),
    {
      // duration: Infinity, // Prevents the toast from hiding automatically
      style: {
        padding: "16px",
      },
    }
  );
};


// export const SuccessToast = (message) => {
//   return toast.success(message, {
//     style: {
//       padding: "16px",
//     },
//   });
// };

// export const ErrorToast = (message) => {
//   return toast.error(message, {
//     style: {
//       padding: "16px",
//     },
//   });
// };

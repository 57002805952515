import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import noData from '../../../img/NoData1.svg'
import {Button, Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import backArrow from '../../../img/back-arrow.png'
import submitImg from '../../../img/Submit-White.png'
import saveGreenImg from '../../../img/save-dark-green.png'
import addService from '../../../img/select-list.png'
import redCross from '../../../img/remove.png'
import crossBtn from '../../../img/cross-button.png'
import onboardTick from '../../../img/onboard-tick.png'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import Select from 'react-select'
import './styles.scss'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import sendBackImg from '../../../img/share.png'
import swapImg from '../../../img/swap.png'
import greenSubmit from '../../../img/green-submit-btn.png'
import approvedIcon from '../../../img/approved.png'
import plannedImg from '../../../img/planned.png'
import preventativeImg from '../../../img/preventative.png'
import reactiveImg from '../../../img/reactive.png'
import SelectServiceModal from './SelectServiceModal'
import SelectVendorModal from './SelectVendorModal'
import AddJobsModal from './AddJobsModal'
import Schedule from './Schedule'
import JobDetailsModal from './JobDetailsModal'
import internalTeamWhite from '../../../img/internal-team-white.png'
import internalTeamGreen from '../../../img/internal-team-green.png'
import externalTeamWhite from '../../../img/external-team-white.png'
import externalTeamGreen from '../../../img/external-team-green.png'
import companyImg from '../../../img/vendor-company.png'
import contactsImg from '../../../img/vendor-contacts.png'
import schedule from '../../../img/schedule-green.png'
import addWhite from '../../../img/add-white.png'
import addGreen from '../../../img/add.png'
import leasingImg from '../../../img/all-tenc.png'
import financialsIcon from '../../../img/financials.png'
import vendorImg from '../../../img/vendor-manage.png'
import vendorBlueImg from '../../../img/users-blue.png'
import uploadFileIcon from '../../../img/upload-file-icon.png'
import fileIcon from '../../../img/invoice-blue.png'
import greenPen from '../../../img/pen.png'
import downloadIcon from '../../../img/download.png'
import pdfIcon from '../../../img/pdf.svg'
import correct from '../../../img/correct.png'
import removed from '../../../img/remove-filled.png'
import SingleFileLoader from '../General/SingleFileLoader'
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Theme} from '@mui/material'
import GeneratePOModal from './GeneratePOPopup'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import uploadFileWhite from '../../../img/upload-file-white.png'
import DevClustAddJobsModal from './DevClustAddJobs'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

const CreateRequest = () => {
  const {flg, type, id, tenancyId, unitId, jobType} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [open, setOpen] = React.useState(false)
  let totalSelected: any = 0

  const [isFormDirty, setIsFormDirty] = useState(false)
  const dispatch = useDispatch()

  const [selectedService, setSelectedService] = useState<any>()
  const [selectedVendor, setSelectedVendor] = useState<any>()

  const [isReviewDisabled, setIsReviewDisabled] = useState<any>(true)

  const [selectedVendorId, setSelectedVendorId] = useState<any>('')
  const [showLoader, setShowLoader] = useState<boolean>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  // React Select
  const customStylesApprove = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
      width: '200px',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      width: '200px',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '31px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '31px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const {state} = useLocation()
  const navigate = useNavigate()

  const [isSkeleton, setIsSkeleton] = useState<any>(flg == '1' ? false : true)
  const [activeTab, setActiveTab] = useState<any>(
    state?.from == 'purchase' ? 'purchase' : 'maintenance'
  )

  const [requestData, setRequestData] = useState<any>({
    maintenanceMainServiceId: '',
    maintenanceSubServiceId: '',
    // handlerType: 0,
  })

  const [isLoading, setIsLoading] = useState<any>()
  const [sendError, setSendError] = useState<any>(false)

  const [isPOLoading, setIsPOLoading] = useState<any>(true)

  const [deletedServiceJob, setDeletedServiceJob] = useState<any>([])

  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  setTimeout(() => {
    setOpen(false)
  }, 10000)

  useEffect(() => {
    if (flg == '2') {
      setIsSkeleton(true)
    }
  }, [])

  //
  const deleteRequest = () => {
    setIsLoading(true)
    let ids = [id]
    const body = {
      ids: ids,
    }
    ApiPost(`corporate/maintenance_service_request/delete`, body)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((error: any) => {
        setOpen(false)
        setIsLoading(false)
        ErrorToast(error?.message)
      })
  }

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      getRequestById()
      getApprovalDetails()

      setIsSaveFirst(false)
      setTimeout(() => {
        setOpen(false)
      }, 1000)
    }
  }, [isSaveFirst])

  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      setOpen(true)
      addRequest('', 0)
    }
  }, [autoSaveData])

  const [hideApproval, setHideApproval] = useState<any>(false)
  const [approvalUsers, setApprovalUsers] = useState<any>([])
  const [selectedApprover, setSelectedApprover] = useState<any>(null)

  const [maintenanceDropdownUsers, setMaintenanceDropdownUsers] = useState<any>([])
  const [approvalDropdownUsers, setApprovalDropdownUsers] = useState<any>([])

  const [procurementDropdownUsers, setProcurementDropdownUsers] = useState<any>([])
  const [procurementApproversDropdownUsers, setProcurementApproversDropdownUsers] = useState<any>(
    []
  )

  const [approvalComments, setApprovalComments] = useState<any>('')
  const [isViewMode, setIsViewMode] = useState<any>(false)

  const [isSubmitToMaintenance, setIsSubmitToMaintenance] = useState<any>(false)
  const [isSubmitToApprovar, setIsSubmitToApprovar] = useState<any>(false)

  const [isSubmitToProcurement, setIsSubmitToProcurement] = useState<any>(false)
  const [isSubmitToProcurementApprover, setIsSubmitToProcurementApprover] = useState<any>(false)

  const [isSubmitToVendor, setIsSubmitToVendor] = useState<any>(false)
  const [showApprovarDropdown, setShowApprovarDropdown] = useState<any>(false)
  const [showProcurementDropdown, setShowProcurementDropdown] = useState<any>(false)
  const [showVendorDropdown, setShowVendorDropdown] = useState<any>(false)
  const [leasingDropdownUsers, setLeasingDropdownUsers] = useState<any>([])

  const getViewMode = () => {
    if (user?._id === requestData?.createdBy) {
      setIsViewMode(false)
    } else {
      if (approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id !== user?._id) {
        setIsViewMode(true)
      } else setIsViewMode(false)
    }
  }

  const [lastApproverId, setLastApproverId] = useState<any>()
  const [isAssign, setIsAssign] = useState<any>(false)
  const [assignData, setAssignData] = useState<any>()
  const [requestWithApproval, setRequestWithApproval] = useState<any>()
  const [requestWithPO, setRequestWithPO] = useState<any>(false)
  const [generatePOEnabled, setGeneratePOEnabled] = useState<any>(false)

  const [showLock, setShowLock] = useState<any>(false)

  const [reAssignDropdownUsers, setReAssignDropdownUsers] = useState<any>([])

  //
  const getApprovalDetails = () => {
    const body = {
      maintenanceServiceRequestId: id,
    }
    ApiPost(`corporate/approval_workflow/maintenance/get`, body)
      .then((res: any) => {
        setApprovalUsers(res?.data?.data)

        let lA: any = res?.data?.data?.length - 1

        for (let i = res?.data?.data?.length - 2; i >= 0; i--) {
          if (res?.data?.data?.[i]?.approvalStatus !== 5) {
            setLastApproverId(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            //  console.log(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            break
          }
        }

        getMaintenanceDropdown()
        getProcurementApprovalDropdown()

        getProcurementDropdown()
        getApprovalDropdown()
      })
      .catch((err: any) => {})
  }

  //
  const getApprovalDropdown = () => {
    const body = {
      subWorkflowListName: 'Reactive Maintenance',
    }
    ApiPost(`corporate/user/get/dropdown/maintenance/approval_workflow`, body)
      .then((res: any) => {
        let values: any = []

        res?.data?.data?.map((pr: any, i: any) => {
          values[i] = {
            label: `${pr?.firstName} ${pr?.lastName} - ${pr?.role_and_permission?.name}`,
            value: `${pr?._id}`,
          }
        })

        setApprovalDropdownUsers(values)
        if (approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest)
          setReAssignDropdownUsers(values)
      })
      .catch((err: any) => {})
  }

  //
  const getProcurementDropdown = () => {
    const body = {
      subWorkflowListName: 'Reactive Maintenance',
    }
    ApiPost(`corporate/user/get/dropdown/maintenance/procurement`, body)
      .then((res: any) => {
        let values: any = []

        res?.data?.data?.map((pr: any, i: any) => {
          values[i] = {
            label: `${pr?.firstName} ${pr?.lastName} - ${pr?.role_and_permission?.name}`,
            value: `${pr?._id}`,
          }
        })

        setProcurementDropdownUsers(values)
        if (approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest)
          setReAssignDropdownUsers(values)
      })
      .catch((err: any) => {})
  }

  //
  const getProcurementApprovalDropdown = () => {
    const body = {
      subWorkflowListName: 'Reactive Maintenance',
    }
    ApiPost(`corporate/user/get/dropdown/maintenance/procurement_approval`, body)
      .then((res: any) => {
        let values: any = []

        res?.data?.data?.map((pr: any, i: any) => {
          values[i] = {
            label: `${pr?.firstName} ${pr?.lastName} - ${pr?.role_and_permission?.name}`,
            value: `${pr?._id}`,
          }
        })

        setProcurementApproversDropdownUsers(values)

        if (approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest)
          setReAssignDropdownUsers(values)
      })
      .catch((err: any) => {})
  }

  //
  const getMaintenanceDropdown = () => {
    const body = {
      subWorkflowListName: 'Reactive Maintenance',
    }
    ApiPost(`corporate/user/get/dropdown/maintenance/submitter`, body)
      .then((res: any) => {
        let values: any = []

        res?.data?.data?.map((pr: any, i: any) => {
          values[i] = {
            label: `${pr?.firstName} ${pr?.lastName} - ${pr?.role_and_permission?.name}`,
            value: `${pr?._id}`,
          }
        })

        setMaintenanceDropdownUsers(values)

        if (
          !approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest &&
          !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest &&
          !approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest
        )
          setReAssignDropdownUsers(values)
      })
      .catch((err: any) => {})
  }

  //
  useEffect(() => {
    if (flg === '2') {
      getApprovalDetails()
    }
    if (flg == '1') {
      getApprovalDropdown()
      getProcurementDropdown()
      getMaintenanceDropdown()
      getProcurementApprovalDropdown()
    }
    // getMaintenanceDropdown()
  }, [])

  useEffect(() => {
    if (flg === '2') getViewMode()
  }, [approvalUsers, requestData])

  //
  const approvalFlowSubmission = (event: any) => {
    setIsLoading(true)
    let newServiceJobs: any = []
    let updateServiceJobs: any = []

    let newInd: any = 0
    let updInd: any = 0
    jobs?.map((job: any, ind: any) => {
      let comm: any = []
      job?.comments?.map((cm: any, index: any) => {
        comm[index] = cm
        comm[index].createdBy = cm?.createdBy?._id
      })
      if (job?.jobId == null) {
        newServiceJobs[newInd] = {
          maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
          jobUrgency: job?.jobUrgency,
          schedule: job?.schedule,
          comments: comm,
          attachments: job?.attachments,
          communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
          clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
          tenantId: null,
          buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
          floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
          unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
          unitId: job?._id ? job?._id : null,
          maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
        }
        newInd++
      } else {
        updateServiceJobs[updInd] = {
          _id: job?.jobId,
          maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
          jobUrgency: job?.jobUrgency,
          schedule: job?.schedule,
          comments: comm,
          attachments: job?.attachments,
          communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
          clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
          tenantId: null,
          buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
          floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
          unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
          unitId: job?._id ? job?._id : null,
          maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
        }
        updInd++
      }
    })

    const body = {
      serviceRequest: {
        _id: flg == '2' ? id : undefined,
        requestorType: 0,
        saveStatus: 0,
        maintenanceRequestType: type == 'reactive' ? 0 : type == 'planned' ? 1 : 2,
        maintenanceMainServiceId: selectedService?.maintenance_main_service?._id,
        maintenanceSubServiceId: selectedService?._id,
        buildingId: requestData?.building?.value,
        communityId: requestData?.community?.value,
        staffId: null,
        vendorId: requestData?.handlerType == 1 ? selectedVendor?._id : null,
        subWorkflowListId: subWorkflowListId,
        handlerType: requestData?.handlerType,
        description: requestData?.description,
        isApprovalEnabled: requestWithApproval,
        isPurchaseOrderEnabled: requestWithPO,
        isPurchaseOrderAutoGenerateEnabled: generatePOEnabled,
        maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
      },
      newServiceJobs: newServiceJobs,
      updateServiceJobs: updateServiceJobs,
      deleteServiceJobs: deletedServiceJob,
      newPurchaseOrder: [],
      updatePurchaseOrder: [],
      deletePurchaseOrder: [],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res) => {
        if (flg == '1') {
          const body = {
            maintenanceServiceRequestId: res?.data?.data?._id,
          }
          ApiPost(`corporate/approval_workflow/maintenance/get`, body)
            .then((res: any) => {
              setApprovalUsers(res?.data?.data)

              const body = {
                id: res?.data?.data[res?.data?.data?.length - 1]?._id,
                type: 0,
                tenancyWorkFlowType: 0,
                // approvalStatus: 1,
                approvalStatus:
                  approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 ? 3 : 1,
                comment: approvalComments,

                // approvalUserId: isSubmitToApprovar ? selectedApprover : undefined,
                approvalUserId:
                  dropDownValue == 'maintenance-approver' ? selectedApprover : undefined,

                // maintenanceSubmitterId: isSubmitToMaintenance ? selectedApprover : '',
                maintenanceSubmitterId:
                  dropDownValue == 'maintenance-submitter' ? selectedApprover : '',

                // procurementSubmitterId: isSubmitToProcurement ? selectedApprover : '',
                procurementSubmitterId:
                  dropDownValue == 'procurement-submitter' ? selectedApprover : '',

                maintenanceVendorId: isSubmitToVendor ? selectedVendor?._id : '',

                // procurementApproverId: isSubmitToProcurementApprover ? selectedApprover : undefined,
                procurementApproverId:
                  dropDownValue == 'procurement-approver' ? selectedApprover : undefined,
              }
              ApiPost(`corporate/approval_workflow/maintenance/submission`, body)
                .then((res: any) => {
                  SuccessToast(res?.data?.message)
                  navigate('/maintenance')

                  setTimeout(() => {
                    setIsLoading(false)
                  }, 1000)
                })
                .catch((err) => {
                  setOpen(false)
                  setIsLoading(false)
                  ErrorToast(err.message)
                })
            })
            .catch((err) => {
              setOpen(false)
              setIsLoading(false)
              ErrorToast(err.message)
            })
        } else {
          const body = {
            id: approvalUsers[approvalUsers?.length - 1]?._id,
            type: 1,
            tenancyWorkFlowType: 0,
            approvalStatus: approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 ? 3 : 1,
            // approvalStatus: approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 ? 3 : 1,
            comment: approvalComments,
            approvalUserId: dropDownValue == 'maintenance-approver' ? selectedApprover : undefined,
            maintenanceSubmitterId:
              dropDownValue == 'maintenance-submitter' ? selectedApprover : '',
            procurementSubmitterId:
              dropDownValue == 'procurement-submitter' ? selectedApprover : '',
            maintenanceVendorId: isSubmitToVendor ? selectedVendor?._id : '',
            procurementApproverId:
              dropDownValue == 'procurement-approver' ? selectedApprover : undefined,
          }
          ApiPost(`corporate/approval_workflow/maintenance/submission`, body)
            .then((res: any) => {
              SuccessToast(res?.data?.message)
              navigate('/maintenance')
              setTimeout(() => {
                setIsLoading(false)
              }, 1000)
            })
            .catch((err) => {
              setOpen(false)
              setIsLoading(false)
              ErrorToast(err.message)
            })
        }
      })
      .catch((err) => {
        setOpen(false)
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  //
  const sendBack = () => {
    setIsLoading(true)
    const body = {
      approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
      comment: approvalComments,
    }
    ApiPost(`corporate/approval_workflow/maintenance/sent_back`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  //
  const reAssign = () => {
    setIsLoading(true)
    const body = {
      approvalWorkflowId: assignData?._id,
      approvalUserId: approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest
        ? selectedApprover
        : undefined,
      maintenanceSubmitterId:
        !approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest
          ? selectedApprover
          : undefined,
      procurementSubmitterId: approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
        ? selectedApprover
        : undefined,
      procurementApproverId: approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest
        ? selectedApprover
        : undefined,
      comment: approvalComments,
    }
    ApiPost(`corporate/approval_workflow/maintenance/reassign`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        navigate('/maintenance')
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  const [subWorkflowListId, setSubWorkflowListId] = useState<any>()

  const getWorkFlowListByName = () => {
    let name: any = ''
    let subName: any = 'Residential Leasing Maintenance'
    if (type == 'reactive') name = 'Reactive Maintenance'

    ApiGet(`corporate/sub_workflow_list/get?name=${name}&subWorkflowName=${subName}`)
      .then((res: any) => {
        if (jobType == 'unit') {
          setRequestWithApproval(res?.data?.data?.maintenanceObject?.unit?.isApprovalEnabled)
          setRequestWithPO(res?.data?.data?.maintenanceObject?.unit?.isPurchaseOrderInclude)
        } else if (jobType == 'common-area') {
          setRequestWithApproval(res?.data?.data?.maintenanceObject?.commonArea?.isApprovalEnabled)
          setRequestWithPO(res?.data?.data?.maintenanceObject?.commonArea?.isPurchaseOrderInclude)
        } else if (jobType == 'dev-clust') {
          setRequestWithApproval(res?.data?.data?.maintenanceObject?.development?.isApprovalEnabled)
          setRequestWithPO(res?.data?.data?.maintenanceObject?.development?.isPurchaseOrderInclude)
        }
        setSubWorkflowListId(res?.data?.data?._id)
      })
      .catch((err: any) => {})
  }

  //
  const getPODetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setGeneratePOEnabled(res?.data?.data?.purchaseOrderCompany?.isPurchaseOrderActive)
        setTimeout(() => {
          setIsPOLoading(false)
        }, 500)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (flg == '1') {
      getWorkFlowListByName()
      getPODetails()
    }
  }, [])

  let isViewOnly =
    approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id != user?._id && flg === '2'

  const isInitiator =
    user?._id == requestData?.createdBy &&
    user?._id !== approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id

  const [showSelectService, setShowSelectService] = useState<any>(false)
  const [showSelectVendor, setShowSelectVendor] = useState<any>(false)
  const [showSelectJob, setShowSelectJob] = useState<any>(false)
  const [showSchedule, setShowSchedule] = useState<any>(false)

  const [showSelectJobDevClust, setShowSelectJobDevClust] = useState<any>(false)

  const [scheduleToAddEdit, setScheduleToAddEdit] = useState<any>('')
  const [scheduleIndToAddEdit, setScheduleIndToAddEdit] = useState<any>(-1)

  const [showJobDetails, setShowJobDetails] = useState<any>(false)
  const [jobsToAddEdit, setJobsToAddEdit] = useState<any>('')
  const [jobsIndToAddEdit, setJobsIndToAddEdit] = useState<any>(-1)

  const [jobs, setJobs] = useState<any>([])
  const [serviceError, setServiceError] = useState<any>(false)
  const [jobsAddedError, setJobsAddedError] = useState<any>(false)
  const [scheduleError, setSchedule] = useState<any>(false)
  const [scheduleErrorInd, setScheduleErrorInd] = useState<any>(-1)
  const [handlingTeamError, setHandlingTeamError] = useState<any>(false)
  const [vendorError, setVendorError] = useState<any>(false)

  const jobRef = React.useRef<any>()
  const jobRefInd = React.useRef<any>()
  const vendorRef = React.useRef<any>()
  const serviceRef = React.useRef<any>()

  const [pError, setPError] = useState<any>('')

  //
  const addRequest = (e: any, isDraft: any) => {
    setScheduleErrorInd(-1)

    let jbErr: any = false
    let schedInd: any = -1
    let schedIndErr: any = false
    let handleErr: any = false
    let vdErr: any = false
    let serveError: any = false

    if (isDraft == 1) {
      if (selectedService == undefined) {
        serveError = true
      }

      if (jobs?.length == 0) {
        jbErr = true
      }

      jobs?.map((jb: any, ind: any) => {
        if (
          jb?.schedule.date == null ||
          jb?.schedule.startTime == null ||
          jb?.schedule.endTime == null
        ) {
          schedIndErr = true
          schedInd = ind
        }
      })

      if (requestData?.handlerType == undefined) {
        handleErr = true
      }

      if (requestData?.handlerType == 1 && selectedVendor == undefined) {
        vdErr = true
      }
    }

    if (!jbErr && !schedIndErr && !handleErr && !vdErr && !serveError) {
      setIsLoading(true)
      let newServiceJobs: any = []
      let updateServiceJobs: any = []

      let newInd: any = 0
      let updInd: any = 0
      jobs?.map((job: any, ind: any) => {
        let comm: any = []
        job?.comments?.map((cm: any, index: any) => {
          comm[index] = cm
          comm[index].createdBy = cm?.createdBy?._id
        })
        if (job?.jobId == null) {
          newServiceJobs[newInd] = {
            maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
            jobUrgency: job?.jobUrgency,
            schedule: job?.schedule,
            comments: comm,
            attachments: job?.attachments,
            communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
            clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
            tenantId: null,
            buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
            floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
            unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
            unitId: job?._id ? job?._id : null,
            maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
          }
          newInd++
        } else {
          updateServiceJobs[updInd] = {
            _id: job?.jobId,
            maintenanceJobStatus: job?.maintenanceJobStatus ? job?.maintenanceJobStatus : 0,
            jobUrgency: job?.jobUrgency,
            schedule: job?.schedule,
            comments: comm,
            attachments: job?.attachments,
            communityId: job?.community?.length > 0 ? job?.community?.[0]?._id : null,
            clusterId: job?.cluster?.length > 0 ? job?.cluster?.[0]?._id : null,
            tenantId: null,
            buildingId: job?.building?.length > 0 ? job?.building?.[0]?._id : null,
            floorId: job?.floor?.length > 0 ? job?.floor?.[0]?._id : null,
            unitGroupId: job?.unit_group?.length > 0 ? job?.unit_group?.[0]?._id : null,
            unitId: job?._id ? job?._id : null,
            maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
          }
          updInd++
        }
      })

      let upPOS = [...uploadedPOs]
      upPOS?.map((vl: any, ind: any) => {
        upPOS[ind].isEdit = undefined
        upPOS[ind].createdAt = new Date()
      })

      const body = {
        serviceRequest: {
          _id: flg == '2' ? id : undefined,
          requestorType: 0,
          saveStatus: isDraft,
          maintenanceRequestType: type == 'reactive' ? 0 : type == 'planned' ? 1 : 2,
          maintenanceMainServiceId: selectedService?.maintenance_main_service?._id,
          maintenanceSubServiceId: selectedService?._id,
          buildingId: requestData?.building?.value,
          communityId: requestData?.community?.value,
          staffId: null,
          vendorId: requestData?.handlerType == 1 ? selectedVendor?._id : null,
          subWorkflowListId: subWorkflowListId,
          handlerType: requestData?.handlerType,
          description: requestData?.description,
          isApprovalEnabled: requestWithApproval,
          isPurchaseOrderEnabled: requestWithPO,
          isPurchaseOrderAutoGenerateEnabled: generatePOEnabled,
          purchaseOrderList: upPOS,
          maintenanceJobType: jobType == 'dev-clust' ? 0 : jobType == 'common-area' ? 1 : 2,
        },
        newServiceJobs: newServiceJobs,
        updateServiceJobs: updateServiceJobs,
        deleteServiceJobs: deletedServiceJob,
        newPurchaseOrder: [],
        updatePurchaseOrder: [],
        deletePurchaseOrder: [],
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          if (requestWithApproval) {
            // Save as Draft
            if (flg == '1') {
              if (isDraft == 1) {
                const body = {
                  maintenanceServiceRequestId: res?.data?.data?._id,
                }
                ApiPost(`corporate/approval_workflow/maintenance/get`, body)
                  .then((res: any) => {
                    const body = {
                      approvalWorkflowId: res?.data?.data?.[res?.data?.data?.length - 1]?._id,
                      comment: approvalComments,
                    }
                    ApiPost(`corporate/approval_workflow/maintenance/approve`, body)
                      .then((res: any) => {
                        SuccessToast(res?.data?.message)
                        navigate('/maintenance')
                      })
                      .catch((err: any) => {
                        ErrorToast(err?.message)
                        setOpen(false)
                        setIsLoading(false)
                      })
                  })
                  .catch((err: any) => {
                    ErrorToast(err?.message)
                    setOpen(false)
                    setIsLoading(false)
                  })
              } else {
                if (e == 'purchase_order_internal')
                  navigate(
                    `/maintenance/create-request/purchase-order/${type}/1/${res?.data?.data?._id}/0`
                  )
                else if (e == 'purchase_order_external')
                  navigate(
                    `/maintenance/create-request/purchase-order/${type}/1/${res?.data?.data?._id}/1`
                  )
                else if (e == 'purchase_order_other')
                  navigate(
                    `/maintenance/create-request/purchase-order/${type}/1/${res?.data?.data?._id}/2`
                  )
                else {
                  navigate(
                    `/maintenance/create-request/reactive/2/${jobType}/${res?.data?.data?._id}`
                  )

                  setTimeout(() => {
                    setIsSaveFirst(true)
                  }, 1000)
                }
              }
            }
            // Create Request
            else {
              if (isDraft == 1) {
                const body = {
                  approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
                  comment: approvalComments,
                }
                ApiPost(`corporate/approval_workflow/maintenance/approve`, body)
                  .then((res: any) => {
                    SuccessToast(res?.data?.message)
                    navigate('/maintenance')
                  })
                  .catch((err: any) => {
                    ErrorToast(err?.message)
                    setOpen(false)
                    setIsLoading(false)
                  })
              }
            }
          } else {
            if (isDraft == 1) {
              SuccessToast(res?.data?.message)
              navigate('/maintenance')
            } else {
              navigate(`/maintenance/create-request/reactive/2/${jobType}/${res?.data?.data?._id}`)
              if (flg == '1') {
                setTimeout(() => {
                  setIsSaveFirst(true)
                }, 1000)
              }
            }
          }

          setTimeout(() => {
            setOpen(false)
            setIsLoading(false)
          }, 2500)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    } else {
      if (serveError) {
        ErrorToast('Please select Services..')
        setServiceError(true)
        setActiveTab('maintenance')

        setTimeout(() => {
          serviceRef.current.scrollIntoView({behavior: 'smooth'})
        }, 200)
      } else if (jbErr) {
        ErrorToast('Please add atleast one job..')
        setJobsAddedError(true)
        setActiveTab('maintenance')

        setTimeout(() => {
          jobRef.current.scrollIntoView({behavior: 'smooth'})
        }, 200)
        setPError('2px solid red')
        setTimeout(() => {
          setPError('')
        }, 10000)
      } else if (schedIndErr) {
        ErrorToast('Job Schedule is Missing..')
        setScheduleErrorInd(schedInd)
        setActiveTab('maintenance')
        setTimeout(() => {
          jobRefInd.current.scrollIntoView({behavior: 'smooth'})
        }, 200)

        setPError('2px solid red')
        setTimeout(() => {
          setPError('')
        }, 10000)
      } else if (handleErr) {
        ErrorToast('Please select handling team..')
        setHandlingTeamError(true)
        setActiveTab('team')
      } else if (vdErr) {
        ErrorToast('Please select a vendor..')
        setActiveTab('team')
        setVendorError(true)
        setTimeout(() => {
          vendorRef.current.scrollIntoView({behavior: 'smooth'})
        }, 500)
      }
    }
  }

  //
  const getVendorById = (val: any) => {
    ApiGet(`corporate/vendor/${val}`)
      .then((res: any) => {
        setSelectedVendor(res?.data?.data)
        setShowSelectVendor(false)
        setVendorError(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getRequestById = () => {
    ApiGet(`corporate/maintenance_service_request/${id}`)
      .then((res: any) => {
        let values = {
          building:
            res?.data?.data?.maintenance_service_job?.[0]?.building?.length > 0
              ? {
                  value: res?.data?.data?.maintenance_service_job?.[0]?.building?.[0]?._id,
                  label: res?.data?.data?.maintenance_service_job?.[0]?.building?.[0]?.name,
                }
              : undefined,
          community:
            res?.data?.data?.maintenance_service_job?.[0]?.community?.length > 0
              ? {
                  value: res?.data?.data?.maintenance_service_job?.[0]?.community?.[0]?._id,
                  label: res?.data?.data?.maintenance_service_job?.[0]?.community?.[0]?.name,
                }
              : null,
          staffId: res?.data?.data?.staffId ? res?.data?.data?.staffId : null,
          vendorId: res?.data?.data?.vendor?.[0]?._id ? res?.data?.data?.vendor?.[0]?._id : null,
          subWorkflowListId: null,
          handlerType: res?.data?.data?.handlerType,
          description: res?.data?.data?.description,
          createdBy: res?.data?.data?.createdBy?._id,
          saveStatus: res?.data?.data?.saveStatus,
          saveStatusDateTime: res?.data?.data?.saveStatusDateTime,
          id: res?.data?.data?.id,
        }

        setRequestData(values)
        setAddedPOs(res?.data?.data?.maintenance_purchase_order)

        setUploadedPOs(res?.data?.data?.purchaseOrderList)

        if (res?.data?.data?.vendor?.[0]?._id != undefined)
          getVendorsDetails(res?.data?.data?.vendor?.[0]?._id)
        if (res?.data?.data?.maintenance_sub_service?.[0]?._id)
          getServiceDetails(res?.data?.data?.maintenance_sub_service?.[0]?._id)
        if (res?.data?.data?.maintenance_service_job?.length > 0 && jobType != 'dev-clust')
          getServiceJobs(res?.data?.data?.maintenance_service_job)
        else {
          let values: any = []
          res?.data?.data?.maintenance_service_job?.map((job: any, ind: any) => {
            values[ind] = {
              comments: job?.comments,
              schedule: {
                startTime: job?.maintenance_service_job_schedule?.[0]?.startTime,
                endTime: job?.maintenance_service_job_schedule?.[0]?.endTime,
                date: job?.maintenance_service_job_schedule?.[0]?.date,
                status: 0,
              },
              jobUrgency: job?.jobUrgency,
              maintenanceJobStatus: job?.maintenanceJobStatus,
              attachments: job?.attachments,
              jobId: job?._id,
              jobIdNo: job?.id,
              check: false,
              isDisable: false,
              building: job?.building,
              community: job?.community,
              cluster: job?.cluster,
              unit_group: job?.unit_group,
            }
          })
          setJobs(values)
        }

        setRequestWithApproval(res?.data?.data?.isApprovalEnabled)
        setRequestWithPO(res?.data?.data?.isPurchaseOrderEnabled)
        setGeneratePOEnabled(res?.data?.data?.isPurchaseOrderAutoGenerateEnabled)
        setSubWorkflowListId(res?.data?.data?.subWorkflowListId)

        setIsLoading(false)
        setIsSkeleton(false)

        setTimeout(() => {
          setIsPOLoading(false)
        }, 500)

        setOpen(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
        setIsSkeleton(false)
      })
  }

  //
  const getVendorsDetails = async (id: any) => {
    const body = {
      page: 1,
      limit: 50,
      searchVendorList: '',
      searchVendor: '',
      vendorListIds: '',
      vendorIds: '',
    }
    await ApiPost(`corporate/vendor/get/maintenance`, body)
      .then((res) => {
        res?.data?.data?.vendor_data?.map((data: any, ind: any) => {
          if (data?._id == id) {
            getVendorById(id)
          }
        })
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  //
  const getServiceDetails = async (id: any) => {
    const body = {
      page:1,
      limit: 10000,
      listSearch: '',
      mainServiceSearch: '',
      subServiceSearch: '',
      maintenanceServiceListIds: [],
      maintenanceMainServiceIds: [],
      maintenanceSubServiceIds: [],
    }
    await ApiPost(`corporate/maintenance_sub_service/get`, body)
      .then((res) => {
        res?.data?.data?.maintenance_sub_service_data?.map((data: any, ind: any) => {
          if (data?._id == id) setSelectedService(data)
        })

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  const getServiceJobs = async (data: any) => {
    const body = {
      searchDevelopment: '',
      unitSearch: '',
      developmentIds: [],
      clusterIds: [],
      unitGroupIds: [],
      floorIds: [],
      unitIds: [],
    }
    await ApiPost(`corporate/maintenance_service_request/get/development`, body)
      .then((res) => {
        let values: any = []
        let vlInd: any = 0
        // res?.data?.data?.map((res: any, ind: any) => {
        //   values[ind].check = false
        // })

        // console.log(data)

        data?.map((dt: any, ind: any) => {
          res?.data?.data?.map((rs: any, index: any) => {
            if (dt?.unit?.[0]?._id == rs?._id) {
              values[vlInd] = rs
              values[vlInd].check = false
              values[vlInd].jobId = dt?._id
              values[vlInd].jobIdNo = dt?.id
              values[vlInd].attachments = dt?.attachments
              values[vlInd].jobUrgency = dt?.jobUrgency
              values[vlInd].comments = dt?.comments
              values[vlInd].schedule = {
                startTime: dt?.maintenance_service_job_schedule?.[0]?.startTime,
                endTime: dt?.maintenance_service_job_schedule?.[0]?.endTime,
                date: dt?.maintenance_service_job_schedule?.[0]?.date,
                status: 0,
              }

              vlInd++
            }
          })
        })

        // console.log(values)

        setJobs(values)

        let clk: any = []
        values?.map((jb: any, ind: any) => {
          clk[ind] = false
        })
        setClicked(clk)

        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    if (flg == '2') getRequestById()
  }, [])

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('HH:mm')
  }

  // useEffect(()=>{
  //   if(!requestWithApproval && (user?._id == requestData?.createdBy))
  //     isViewOnly = false

  // },[requestWithApproval, requestData])

  isViewOnly = !requestWithApproval && user?._id == requestData?.createdBy ? false : isViewOnly

  isViewOnly = user?.isSubUser == false ? false : isViewOnly

  const [clicked, setClicked] = useState<any>([])

  // Drag and Drop

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  const [uploadedPOs, setUploadedPOs] = useState<any>([
    // {
    //   documentName: '',
    //   purchaseOrderNo: '',
    //   totalAmount: 0,
    //   singedCopyURL: '',
    //   pdfURL: '',
    // },
  ])

  const [addedPOs, setAddedPOs] = useState<any>([])

  const handleAddUploadedPOs = (type: any) => {
    const values = [...uploadedPOs]
    values.push({
      documentName: '',
      purchaseOrderNo: '',
      totalAmount: '',
      singedURL: '',
      pdfURL: '',
      vendorName: type == 1 ? selectedVendor?.companyName : '',
      maintenancePurchaseOrderType: type,
      createdAt: '',
      isEdit: true,
    })
    setUploadedPOs(values)
    setShowGeneratePOModel(false)
  }

  const handleRemoveUploadedPOs = (ind: any) => {
    const values = [...uploadedPOs]
    values.slice(ind, 1)
    setUploadedPOs(values)
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  let isProcurementSubmitted =
    (approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest ||
      approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest) &&
    approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id == user?._id &&
    !isSubmitToVendor

  const deletePO = (val: any) => {
    setIsLoading(true)

    const body = {
      serviceRequest: {
        _id: id,
      },
      newPurchaseOrder: [],
      updatePurchaseOrder: [],
      deletePurchaseOrder: [{_id: val}],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        SuccessToast('Purchase Order has been deleted successfully...')

        getRequestById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  //
  const handleUploadSignedCopies = async (e: any, ind: any, pO: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        if (flg == '2') setOpen(true)
        setShowLoader(true)

        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...addedPOs]
            values[ind].singedURL = res?.data?.data?.image

            setAddedPOs(values)

            if (flg == '2') {
              const body = {
                serviceRequest: {
                  _id: id,
                },

                updatePurchaseOrder: [
                  {
                    _id: pO?._id,
                    singedURL: res?.data?.data?.image,
                  },
                ],
              }

              ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
                .then((res: any) => {
                  getRequestById()
                  setShowLoader(false)
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                  setOpen(false)
                  setShowLoader(false)
                })
            }
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  //
  const handleUploadPO = async (e: any, ind: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        setShowLoader(true)
        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...uploadedPOs]
            values[ind].pdfURL = res?.data?.data?.image

            values[ind].isEdit = true
            values[ind].documentName = res?.data?.data?.image?.split('/').pop()

            setUploadedPOs(values)

            setShowLoader(false)
          })
          .catch((err) => {
            setShowLoader(false)
            ErrorToast(err?.message)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  //
  const deletePOSignedCopy = (pO: any, isUpload: any, ind: any) => {
    setIsLoading(true)
    if (flg == '2') setOpen(true)

    let values: any = []
    if (isUpload) values = [...uploadedPOs]
    else values = [...addedPOs]

    values[ind].singedURL = ''

    values?.map((vl: any, ind: any) => {
      values[ind].isEdit = undefined
    })

    let body: any

    if (isUpload) setUploadedPOs(values)

    if (flg == '2') {
      if (isUpload) {
        body = {
          serviceRequest: {
            _id: id,
            purchaseOrderList: values,
          },
        }
      } else {
        body = {
          serviceRequest: {
            _id: id,
          },

          updatePurchaseOrder: [
            {
              _id: pO?._id,
              singedURL: '',
            },
          ],
        }
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          // SuccessToast('Signed Copy has been deleted successfully...')
          getRequestById()
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    }
  }

  //
  const addUpdatePOs = () => {
    setIsLoading(true)

    let values = [...uploadedPOs]
    values?.map((vl: any, ind: any) => {
      values[ind].isEdit = undefined
      values[ind].createdAt = new Date()
    })

    if (flg == '1') setUploadedPOs(values)
    else {
      setOpen(true)
      const body = {
        serviceRequest: {
          _id: id,
          purchaseOrderList: values,
        },
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          getRequestById()
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    }
  }

  //
  const deleteUploadPO = (val: any, ind: any) => {
    setIsLoading(true)

    let values = [...uploadedPOs]
    values.splice(ind, 1)
    if (flg == '1') setUploadedPOs(values)
    else {
      setOpen(true)

      const body = {
        serviceRequest: {
          _id: id,
          purchaseOrderList: values,
        },
      }

      ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
        .then((res: any) => {
          getRequestById()
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setOpen(false)
          setIsLoading(false)
        })
    }
  }

  //
  const handleUploadSignedCopiesOfUploadedPO = async (e: any, ind: any, pO: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]?.type === 'application/pdf') {
        setShowLoader(true)
        if (flg == '2') setOpen(true)

        var formdata = new FormData()
        formdata.append('image', e.target.files[0])

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let name: any = e.target.files[0]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            let values = [...uploadedPOs]
            values[ind].singedURL = res?.data?.data?.image

            setUploadedPOs(values)

            if (flg == '2' && !pO?.isEdit) {
              values?.map((vl: any) => {
                vl.isEdit = undefined
              })

              const body = {
                serviceRequest: {
                  _id: id,
                  purchaseOrderList: values,
                },
              }
              ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
                .then((res: any) => {
                  getRequestById()
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                  setOpen(false)
                })
            }

            setShowLoader(false)
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
          })
      } else {
        ErrorToast('Files other than PDF are not allowed..')
      }
    }
  }

  //
  const [submitValue, setSubmitValue] = useState<any>('')
  const [dropDownValue, setDropdownValue] = useState<any>('')

  let proInd: any
  let mainInd: any

  let proAppInd: any
  let mainAppInd: any

  const [showGeneratePOModel, setShowGeneratePOModel] = useState<any>(false)

  //
  const CustomOptionForReactSelect = (props: any) => {
    const {data, innerRef, innerProps, isFocused, isSelected} = props
    const [firstName, lastName, role] = data.label.split(' - ')

    const parts = data?.label.split(' - ')
    const beforeDash = parts[0] // "John Doe"
    const afterDash = parts[1] //

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          ...innerProps.style,
          backgroundColor: isSelected ? '#c2d8d9' : isFocused ? '#e0f5f0' : '',
          color: '#000',
          fontWeight: '500',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        {beforeDash}{' '}
        {afterDash != undefined && afterDash != 'undefined' && afterDash?.trim()?.length != 0 ? (
          <>
            - <i style={{color: 'gray'}}>{afterDash}</i>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  }

  let showCluster: any = false
  let showFloor: any = false
  let showUnitGroup: any = false
  let showBuilding: any = false

  const handleImageClick = (val: any, img: any) => {
    const groupName = `group_${val}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', img)
    }
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    return () => {
      fancybox.destroy();
    };
  }, []);

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
                {' '}
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => navigate('/maintenance')}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>
                    {`Maintenance Request - ${type}`}
                  </h2>
                </div>
                <div className=' d-flex'>
                  {flg == '2' && (
                    <>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                        onClick={() => deleteRequest()}
                        disabled={isLoading || isViewOnly}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                      </button>
                    </>
                  )}

                  {/* Save CTA */}
                  {flg === '1' && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                      onClick={(e) => {
                        addRequest(e, 0)
                      }}
                      disabled={isLoading || sendError}
                    >
                      <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}Save
                    </button>
                  )}

                  {/* Send Back CTA */}
                  {/* {!isViewOnly &&
                    flg === '2' &&
                    approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 && ( */}
                  {!isViewOnly &&
                    flg === '2' &&
                    approvalUsers?.length > 1 &&
                    user?._id !== requestData?.createdBy && (
                      // && user?._id !== requestData?.createdBy
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 send-back-btn pe-2 ps-3'
                        onClick={(e) => {
                          sendBack()
                        }}
                        disabled={isLoading || isViewOnly}
                      >
                        <img src={sendBackImg} height={20} width={20} className='me-4' /> {'  '}Send
                        Back
                      </button>
                    )}

                  {requestWithApproval ? (
                    <>
                      {/* Submit CTA */}
                      {(((((user?._id ==
                        approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ||
                        flg == '1' ||
                        lastApproverId === user?._id) &&
                        !isSubmitToVendor) ||
                        !user?.isSubUser) &&
                        (submitValue == 'submitForInput' || submitValue == 'submitForApproval')) ||
                        isAssign) && (
                        <button
                          type='button'
                          className={
                            // approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                            // approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                            //   user?._id
                            //   ? 'btn btn-sm fw-bold ms-3 green-hollow-btn ps-3' :
                            'btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                          }
                          onClick={(e: any) => {
                            if (isAssign) reAssign()
                            else approvalFlowSubmission(e)

                            // addRequest(e, 1)
                          }}
                          disabled={
                            isLoading ||
                            // || isViewOnly)
                            selectedApprover == null
                          }
                        >
                          <img
                            src={
                              // approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                              // approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                              // user?._id
                              // ? greenSubmit :
                              submitImg
                            }
                            height={19}
                            width={19}
                            className='me-4'
                          />{' '}
                          {'  '}
                          {isAssign
                            ? 'Reassign'
                            : !isAssign &&
                              approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                            ? 'Approve'
                            : 'Submit'}
                        </button>
                      )}

                      {/* Create CTA */}
                      {((approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                        approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                          user?._id &&
                        approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest) ||
                        isSubmitToVendor ||
                        !user?.isSubUser) &&
                        submitValue == 'submitToVendor' && (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                            onClick={(e: any) => {
                              addRequest(e, 1)
                            }}
                            disabled={isLoading || isViewOnly}
                          >
                            <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                            Create
                          </button>
                        )}
                    </>
                  ) : (
                    <button
                      type='button'
                      className={'btn btn-sm fw-bold ms-3 green-submit-btn ps-3'}
                      onClick={(e: any) => {
                        addRequest(e, 1)
                      }}
                      disabled={isLoading || isViewOnly}
                    >
                      <img src={submitImg} height={19} width={19} className='me-4' /> Create
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* Approval Workflow */}
            {requestWithApproval && (
              <div
                className='card card-flush py-5 mt-5 px-7'
                style={sendError ? {opacity: '0.6'} : {opacity: '1'}}
              >
                <div className='d-flex'>
                  <h2 className='top ps-0 white-dark-theme-color'>
                    <b>Approval Workflow</b>
                  </h2>
                  <p className='ms-auto head-text pe-0 fnt-500'>
                    Action Required{' '}
                    <span
                      className={
                        approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                          ? 'send-back-color ms-5 fnt-700'
                          : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
                          ? 'red-color ms-5 fnt-700'
                          : 'labl-gry ms-5 fnt-700'
                      }
                    >
                      {flg === '1'
                        ? 'Pending Submission'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0
                        ? 'Pending Submission'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 1
                        ? 'Submitted'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                        ? 'Pending Approval'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                        ? 'Approved'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 4
                        ? 'Sent Back'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 5
                        ? 'Reassigned'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
                        ? 'Locked'
                        : ''}
                    </span>
                  </p>
                </div>

                {hideApproval && (
                  <>
                    {flg === '1' ? (
                      <div className='table-responsive' style={{maxHeight: '400px'}}>
                        <table
                          className='table align-start fs-6 gy-5 '
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <th className='text-start min-w-100px test'>User</th>
                            <th className='text-start min-w-100px'>Role</th>
                            <th className='text-start min-w-100px'>Status</th>
                            <th className='text-start min-w-300px'>Comments</th>
                          </thead>
                          <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                            <tr>
                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p
                                    className='user-head head-text pe-2 mb-0'
                                    style={{fontWeight: '600'}}
                                  >
                                    {user?.firstName} {user?.lastName}
                                  </p>
                                  <p className='labl-gry mb-2'>({user?.email})</p>
                                </div>
                              </td>
                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p
                                    className='user-head pe-2 mb-0 ps-1'
                                    style={{fontWeight: '600'}}
                                  >
                                    {user?.isRootUser &&
                                    !user?.isApprovalRequest &&
                                    !user?.isProcurementRequest &&
                                    !user?.isProcurementApprovalRequest
                                      ? 'Root (Maintenance Officer)'
                                      : !user?.isRootUser &&
                                        !user?.isApprovalRequest &&
                                        !user?.isProcurementRequest &&
                                        !user?.isProcurementApprovalRequest
                                      ? 'Maintenance Officer'
                                      : user?.isRootUser && user?.isApprovalRequest
                                      ? 'Root (Maintenance Manager)'
                                      : user?.isRootUser && user?.isProcurementRequest
                                      ? 'Root (Procurement Officer)'
                                      : user?.isRootUser && user?.isProcurementApprovalRequest
                                      ? 'Root (Procurement Manager)'
                                      : !user?.isRootUser && user?.isApprovalRequest
                                      ? 'Maintenance Manager'
                                      : !user?.isRootUser && user?.isProcurementRequest
                                      ? 'Procurement Officer'
                                      : !user?.isRootUser && user?.isProcurementApprovalRequest
                                      ? 'Procurement Manager'
                                      : user?.isMaintenanceVendorSubmitRequest
                                      ? 'vendor'
                                      : user?.isRootUser
                                      ? 'Root User'
                                      : ''}
                                  </p>
                                </div>
                              </td>
                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p
                                    className='user-head pe-2 mb-0 ps-1'
                                    style={{fontWeight: '600'}}
                                  >
                                    Pending Submission
                                  </p>
                                </div>
                              </td>
                              <td className='text-start ps-0'>
                                <textarea
                                  className='form-control form-control-solid mytest'
                                  placeholder='Enter comments...'
                                  name='description'
                                  value={approvalComments}
                                  onChange={(e: any) => {
                                    let newVal: any =
                                      e.target.value != ''
                                        ? e.target.value.charAt(0).toUpperCase() +
                                          e.target.value.slice(1)
                                        : e.target.value
                                    setApprovalComments(newVal)
                                  }}
                                  onBlur={() => {}}
                                />{' '}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        {approvalUsers?.length > 0 && (
                          <div className='table-responsive' style={{maxHeight: '400px'}}>
                            <table
                              className='table align-start fs-6 gy-5 '
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <th className='text-start min-w-100px test'>User</th>
                                <th className='text-start min-w-100px'>Role</th>
                                <th className='text-start min-w-100px'>Status</th>
                                <th className='text-start min-w-300px'>Comments</th>
                              </thead>
                              <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                                {approvalUsers?.length > 0 &&
                                  approvalUsers?.map((app: any, ind: any) => {
                                    return (
                                      <>
                                        <tr>
                                          <td className='text-start ps-0'>
                                            <div className='d-flex'>
                                              <div className='head-text'>
                                                <p
                                                  className='user-head head-text pe-2 mb-0'
                                                  style={{fontWeight: '600'}}
                                                >
                                                  {app?.createdBy?.[0]?.firstName}{' '}
                                                  {app?.createdBy?.[0]?.lastName}
                                                </p>
                                                <p className='labl-gry mb-2'>
                                                  ({app?.createdBy?.[0]?.email})
                                                </p>
                                              </div>
                                              {ind == approvalUsers?.length - 1 &&
                                                lastApproverId === user?._id && (
                                                  // requestData?.createdBy === user?._id && (
                                                  <div className='ms-5'>
                                                    <img
                                                      src={swapImg}
                                                      height={20}
                                                      width={20}
                                                      className='cursor-pointer'
                                                      onClick={() => {
                                                        setIsAssign(true)
                                                        setAssignData(app)
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          </td>
                                          <td className='text-start ps-0'>
                                            <div className='head-text'>
                                              <p
                                                className='user-head pe-2 mb-0 ps-1'
                                                style={{fontWeight: '600'}}
                                              >
                                                {app?.isRootUser &&
                                                !app?.isApprovalRequest &&
                                                !app?.isProcurementRequest &&
                                                !app?.isProcurementApprovalRequest
                                                  ? 'Root (Maintenance Officer)'
                                                  : !app?.isRootUser &&
                                                    !app?.isApprovalRequest &&
                                                    !app?.isProcurementRequest &&
                                                    !app?.isProcurementApprovalRequest
                                                  ? 'Maintenance Officer'
                                                  : app?.isRootUser && app?.isApprovalRequest
                                                  ? 'Root (Maintenance Manager)'
                                                  : app?.isRootUser && app?.isProcurementRequest
                                                  ? 'Root (Procurement Officer)'
                                                  : app?.isRootUser &&
                                                    app?.isProcurementApprovalRequest
                                                  ? 'Root (Procurement Manager)'
                                                  : !app?.isRootUser && app?.isApprovalRequest
                                                  ? 'Maintenance Manager'
                                                  : !app?.isRootUser && app?.isProcurementRequest
                                                  ? 'Procurement Officer'
                                                  : !app?.isRootUser &&
                                                    app?.isProcurementApprovalRequest
                                                  ? 'Procurement Manager'
                                                  : app?.isMaintenanceVendorSubmitRequest
                                                  ? 'vendor'
                                                  : app?.isRootUser
                                                  ? 'Root User'
                                                  : ''}
                                              </p>
                                            </div>
                                          </td>
                                          <td className='text-start ps-0'>
                                            <div className='head-text'>
                                              <p
                                                className='user-head pe-2 mb-0 ps-1'
                                                style={{fontWeight: '600'}}
                                              >
                                                {app?.approvalStatus === 0
                                                  ? 'Pending Submission'
                                                  : app?.approvalStatus === 1
                                                  ? 'Submitted'
                                                  : app?.approvalStatus === 2
                                                  ? 'Pending Approval'
                                                  : app?.approvalStatus === 3
                                                  ? 'Approved'
                                                  : app?.approvalStatus === 4
                                                  ? 'Sent Back'
                                                  : app?.approvalStatus === 5
                                                  ? 'Reassigned'
                                                  : ''}
                                              </p>
                                              <p className='mb-2 ps-1'>
                                                <i>
                                                  {app?.approvalStatusAt
                                                    ? `${moment
                                                        .utc(app?.approvalStatusAt)
                                                        .local()
                                                        .format('DD.MM.YYYY - hh:mm A')} `
                                                    : ''}
                                                </i>
                                              </p>
                                            </div>
                                          </td>
                                          <td className='text-start ps-0 pe-3'>
                                            <textarea
                                              className='form-control form-control-solid mytest'
                                              placeholder='Enter comments...'
                                              name='description'
                                              value={app?.comment}
                                              onChange={(e: any) => {
                                                let values = [...approvalUsers]
                                                values[ind].comment = e.target.value
                                                setApprovalComments(e.target.value)
                                              }}
                                              onBlur={() => {}}
                                              disabled={
                                                ind == approvalUsers?.length - 1 &&
                                                lastApproverId === user?._id
                                                  ? false
                                                  : // ind !== approvalUsers?.length - 1 ||
                                                  sendError ||
                                                    app?.createdBy?.[0]?._id != user?._id ||
                                                    app?.approvalStatus === 1 ||
                                                    app?.approvalStatus === 5 ||
                                                    showLock
                                                  ? true
                                                  : false
                                                //  || (app?.createdBy?.[0]?._id == user?._id &&
                                                //     ind != app?.length - 1)
                                              }
                                            />{' '}
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    )}

                    {/* Reassign User */}
                    {isAssign && (
                      <div className='row tst mb-4'>
                        <div className='col-4 col-md-2'>
                          <Select
                            styles={customStylesApprove}
                            isSearchable={false}
                            options={reAssignDropdownUsers}
                            onChange={(e: any) => {
                              setSelectedApprover(e.value)
                            }}
                            isDisabled={sendError ? true : false}
                            placeholder={'Reassign...'}
                          />
                        </div>
                      </div>
                    )}

                    {/* New Flow after purchase order */}
                    {((!isViewOnly && !isInitiator) || !user?.isSubUser) && (
                      <div className='row ms-1 main-po'>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={submitValue} // Set the value of the RadioGroup
                            onChange={(e: any) => {
                              setSubmitValue(e.target.value)
                              if (e.target.value == 'submitToVendor') setIsSubmitToVendor(true)
                              else {
                                setIsSubmitToVendor(false)
                              }
                              setDropdownValue('')
                              setSelectedApprover(null)
                              //   setIsSubmitToVendor(true)
                              // if (e.target.value == 'submitForInput') {
                              //   if (
                              //     approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest ||
                              //     approvalUsers[approvalUsers?.length - 1]
                              //       ?.isProcurementApprovalRequest
                              //   )
                              //     setIsSubmitToMaintenance(true)
                              //   else setIsSubmitToProcurement(true)
                              // } else if (e.target.value == 'submitForApproval') {
                              //   setIsSubmitToApprovar(true)
                              // } else if (e.target.value == 'submitToVendor') {
                              //   setIsSubmitToVendor(true)
                              // }
                             
                            }}
                          >
                            <FormControlLabel
                              value={'submitForInput'}
                              control={<Radio style={{color: '#35475e'}} />}
                              label='Submit for Input'
                              className='me-5'
                              disabled={
                                // submitValue == 'submitForApproval' ||
                                // submitValue == 'submitToVendor' ||
                                (isViewOnly || isInitiator) && user?.isSubUser
                              }
                            />
                            <>
                              <FormControlLabel
                                value={'submitForApproval'}
                                control={<Radio />}
                                label='Submit for Approval'
                                className='mx-5'
                                disabled={
                                  // submitValue == 'submitForInput' ||
                                  // submitValue == 'submitToVendor' ||
                                  (isViewOnly || isInitiator) && user?.isSubUser
                                }
                              />
                              {/* {(
                              approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest ||
                              (approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest &&
                                (addedPOs?.length > 0 || uploadedPOs?.length > 0)
                              ) ||
                              !requestWithPO || flg == '1') && ( */}
                              {(approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest ||
                                !requestWithPO ||
                                flg == '1' ||
                                !user?.isSubUser) && (
                                <FormControlLabel
                                  value={'submitToVendor'}
                                  control={<Radio />}
                                  label={
                                    requestData?.handlerType == 0
                                      ? 'Create Request'
                                      : 'Submit to Vendor'
                                  }
                                  className='mx-5'
                                  disabled={
                                    // submitValue == 'submitForInput' ||
                                    // submitValue == 'submitForApproval' ||
                                    (isViewOnly || isInitiator) && user?.isSubUser
                                  }
                                />
                              )}
                            </>
                          </RadioGroup>
                        </FormControl>
                      </div>
                    )}

                    {maintenanceDropdownUsers &&
                      maintenanceDropdownUsers?.map((pro: any, index: any) => {
                        if (dropDownValue == 'maintenance-submitter') {
                          if (selectedApprover == pro?.value) mainInd = index
                        } else {
                          mainInd = undefined
                        }
                        return <></>
                      })}

                    {procurementDropdownUsers &&
                      procurementDropdownUsers?.map((pro: any, index: any) => {
                        if (dropDownValue == 'procurement-submitter') {
                          if (selectedApprover == pro?.value) proInd = index
                        } else {
                          proInd = undefined
                        }
                        return <></>
                      })}

                    {submitValue == 'submitForInput' && (
                      <div className='d-flex mt-4 tst '>
                        {/* {approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest ||
                        approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest ? ( */}
                        <div className='position-relative'>
                          {isSubmitToProcurement && <div className='requestBoxOpacity'></div>}
                          <div className='me-7'>
                            <p className='user-head pe-2 mb-1 ps-0 white-dark-theme-color fnt-600'>
                              Maintenance Team
                            </p>
                            <Select
                              defaultValue={
                                mainInd != undefined ? maintenanceDropdownUsers[mainInd] : []
                              }
                              value={mainInd != undefined ? maintenanceDropdownUsers[mainInd] : []}
                              styles={customStylesApprove}
                              isSearchable={false}
                              options={maintenanceDropdownUsers}
                              onChange={(e: any) => {
                                setDropdownValue('maintenance-submitter')
                                // setIsSubmitToMaintenance(true)

                                setSelectedApprover(e.value)
                              }}
                              placeholder={' Select Inputter'}
                              isDisabled={
                                // isSubmitToProcurement ||
                                isViewOnly || isInitiator
                              }
                              components={{Option: CustomOptionForReactSelect}}
                            />
                          </div>
                        </div>
                        {/* ) : ( */}
                        {requestWithPO && (
                          <div className='position-relative'>
                            {isSubmitToMaintenance && <div className='requestBoxOpacity'></div>}
                            <div className='ms-1 me-5'>
                              <p className='user-head pe-2 mb-1 ps-0 white-dark-theme-color fnt-600'>
                                Procurement Team
                              </p>
                              <Select
                                defaultValue={
                                  proInd != undefined ? procurementDropdownUsers[proInd] : []
                                }
                                value={proInd != undefined ? procurementDropdownUsers[proInd] : []}
                                styles={customStylesApprove}
                                isSearchable={false}
                                // options={
                                //   procurementDropdownUsers &&
                                //   procurementDropdownUsers?.map((i: any, index: any) => {
                                //     return {
                                //       label: `${i?.firstName} ${i?.lastName}`,
                                //       value: `${i?._id}`,
                                //     }
                                //   })
                                // }

                                options={procurementDropdownUsers}
                                onChange={(e: any) => {
                                  if (requestData?.handlerType == undefined) {
                                    ErrorToast('Please select the handling team first..')
                                  } else if (
                                    requestData?.handlerType == 1 &&
                                    selectedVendor == undefined
                                  ) {
                                    ErrorToast('Please select vendor first..')
                                  } else {
                                    setDropdownValue('procurement-submitter')
                                    // setIsSubmitToProcurement(true)
                                    setSelectedApprover(e.value)
                                  }
                                }}
                                placeholder={'Select Inputter'}
                                isDisabled={
                                  // isSubmitToMaintenance ||
                                  isViewOnly || isInitiator
                                }
                                components={{Option: CustomOptionForReactSelect}}
                              />
                            </div>
                          </div>
                        )}
                        {/* )} */}
                      </div>
                    )}

                    {approvalDropdownUsers &&
                      approvalDropdownUsers?.map((app: any, index: any) => {
                        if (dropDownValue == 'maintenance-approver') {
                          if (selectedApprover == app?.value) mainAppInd = index
                        } else {
                          mainAppInd = undefined
                        }
                        return <></>
                      })}

                    {procurementApproversDropdownUsers &&
                      procurementApproversDropdownUsers?.map((pro: any, index: any) => {
                        if (dropDownValue == 'procurement-approver') {
                          if (selectedApprover == pro?.value) proAppInd = index
                        } else {
                          proAppInd = undefined
                        }
                        return <></>
                      })}

                    {submitValue == 'submitForApproval' && (
                      <div className='d-flex mt-4 tst'>
                        {/* {approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest ||
                        approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest ? ( */}

                        {/* ) : ( */}
                        <div className='position-relative'>
                          {isSubmitToProcurementApprover && (
                            <div className='requestBoxOpacity'></div>
                          )}
                          <div className='me-5'>
                            <p className='user-head pe-2 mb-1 ps-1 white-dark-theme-color fnt-600'>
                              Maintenance Team
                            </p>
                            <Select
                              defaultValue={
                                mainAppInd != undefined ? approvalDropdownUsers[mainAppInd] : []
                              }
                              value={
                                mainAppInd != undefined ? approvalDropdownUsers[mainAppInd] : []
                              }
                              styles={customStylesApprove}
                              isSearchable={false}
                              options={approvalDropdownUsers}
                              onChange={(e: any) => {
                                setDropdownValue('maintenance-approver')
                                // setIsSubmitToApprovar(true)
                                setSelectedApprover(e.value)
                              }}
                              placeholder={'Select Approver'}
                              isDisabled={
                                // isSubmitToProcurementApprover ||
                                isViewOnly || isInitiator
                              }
                              components={{Option: CustomOptionForReactSelect}}
                            />
                          </div>
                        </div>

                        {(approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest ||
                          approvalUsers[approvalUsers?.length - 1]?.isProcurementApprovalRequest ||
                          approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest ||
                          !user?.isSubUser) &&
                          requestWithPO && (
                            <>
                              <div className='position-relative'>
                                {isSubmitToApprovar && <div className='requestBoxOpacity'></div>}
                                <div className='mx-3'>
                                  <p
                                    className='user-head pe-2 mb-1 ps-1 white-dark-theme-color fnt-600'
                                  >
                                    Procurement Team
                                  </p>
                                  <Select
                                    defaultValue={
                                      proAppInd != undefined
                                        ? procurementApproversDropdownUsers[proAppInd]
                                        : []
                                    }
                                    value={
                                      proAppInd != undefined
                                        ? procurementApproversDropdownUsers[proAppInd]
                                        : []
                                    }
                                    styles={customStylesApprove}
                                    isSearchable={false}
                                    options={procurementApproversDropdownUsers}
                                    onChange={(e: any) => {
                                      setDropdownValue('procurement-approver')
                                      // setIsSubmitToProcurementApprover(true)
                                      setSelectedApprover(e.value)
                                    }}
                                    placeholder={'Select Approver'}
                                    isDisabled={
                                      // isSubmitToApprovar ||
                                      isViewOnly || isInitiator
                                    }
                                    components={{Option: CustomOptionForReactSelect}}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        {/* )} */}
                      </div>
                    )}

                    {/* {(user?._id == requestData?.createdBy || flg == '1') && ( */}
                    {/* {(user?._id == approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ||
                      flg == '1') && (
                      <div className='row mb-5 mt-3 tst mt-20'>
                        <div className='col-4 col-md-2'>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold blue-btn status-w-200 me-4 mb-5 ps-3'
                            onClick={() => {
                              setIsSubmitToApprovar(true)
                              setShowApprovarDropdown(true)
                            }}
                            disabled={
                              isViewOnly ||
                              isInitiator ||
                              isSubmitToProcurement ||
                              isSubmitToVendor ||
                              isSubmitToMaintenance
                            }
                          >
                            <img src={approvedIcon} height={17} width={17} className='me-3' />{' '}
                            Submit for Approval
                          </button>
                          {showApprovarDropdown && (
                            <Select
                              styles={customStylesApprove}
                              isSearchable={false}
                              options={
                                approvalDropdownUsers &&
                                approvalDropdownUsers?.map((i: any, index: any) => {
                                  return {
                                    label: `${i?.firstName} ${i?.lastName}`,
                                    value: `${i?._id}`,
                                  }
                                })
                              }
                              onChange={(e: any) => {
                                setSelectedApprover(e.value)
                              }}
                              placeholder={'Select Approver'}
                              isDisabled={
                                isSubmitToProcurement ||
                                isSubmitToVendor ||
                                isViewOnly ||
                                isInitiator
                              }
                            />
                          )}
                        </div>

                        {requestWithPO && (
                          <div className='col-4 col-md-2'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold blue-btn status-w-200 mb-5 ps-3'
                              onClick={() => {
                                if (requestData?.handlerType == undefined) {
                                  ErrorToast('Please select the handling team first..')
                                } else if (
                                  requestData?.handlerType == 1 &&
                                  selectedVendor == undefined
                                ) {
                                  ErrorToast('Please select vendor first..')
                                } else {
                                  if (
                                    approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                  ) {
                                    setIsSubmitToMaintenance(true)
                                  } else {
                                    setIsSubmitToProcurement(true)
                                  }
                                  setShowProcurementDropdown(true)
                                }
                              }}
                              disabled={
                                isSubmitToApprovar || isSubmitToVendor || isViewOnly || isInitiator
                              }
                            >
                              <img
                                src={
                                  !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                    ? financialsIcon
                                    : leasingImg
                                }
                                height={
                                  !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                    ? 20
                                    : 17
                                }
                                width={
                                  !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                    ? 19
                                    : 17
                                }
                                className='me-3'
                              />{' '}
                              {!approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                ? 'Submit to Procurement'
                                : 'Submit to Maintenance'}
                            </button>
                            {showProcurementDropdown && (
                              <Select
                                styles={customStylesApprove}
                                isSearchable={false}
                                options={
                                  !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                    ? procurementDropdownUsers &&
                                      procurementDropdownUsers?.map((i: any, index: any) => {
                                        return {
                                          label: `${i?.firstName} ${i?.lastName}`,
                                          value: `${i?._id}`,
                                        }
                                      })
                                    : maintenanceDropdownUsers &&
                                      maintenanceDropdownUsers?.map((i: any, index: any) => {
                                        return {
                                          label: `${i?.firstName} ${i?.lastName}`,
                                          value: `${i?._id}`,
                                        }
                                      })
                                }
                                onChange={(e: any) => {
                                  setSelectedApprover(e.value)
                                }}
                                placeholder={
                                  !approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest
                                    ? 'Select Procurement'
                                    : 'Select Maintenance'
                                }
                                isDisabled={
                                  isSubmitToApprovar ||
                                  isSubmitToVendor ||
                                  isViewOnly ||
                                  isInitiator
                                }
                              />
                            )}
                          </div>
                        )}

                        {requestWithPO &&
                          approvalUsers[approvalUsers?.length - 1]?.isProcurementRequest && (
                            <div className='col-4 col-md-2'>
                              <button
                                type='button'
                                className={`btn btn-sm fw-bold ${
                                  isSubmitToVendor
                                    ? 'custom-cancel-button h-31 d-flex align-items-center'
                                    : 'blue-btn'
                                } status-w-200 mb-5 ps-3`}
                                onClick={() => {
                                  setIsSubmitToVendor(true)
                                  setShowVendorDropdown(true)
                                }}
                                disabled={
                                  isSubmitToApprovar ||
                                  isSubmitToProcurement ||
                                  isViewOnly ||
                                  isInitiator ||
                                  isSubmitToMaintenance
                                }
                              >
                                <img
                                  src={isSubmitToVendor ? vendorBlueImg : vendorImg}
                                  height={20}
                                  width={20}
                                  className='me-3'
                                />{' '}
                                {'Submit to Vendor'}
                              </button>
                              {/* {showVendorDropdown && (
                            <Select
                              styles={customStylesApprove}
                              isSearchable={false}
                              options={
                                !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                  ? maintenanceDropdownUsers&&
                                    financeDropdownUsers?.map((i: any, index: any) => {
                                      return {
                                        label: `${i?.firstName} ${i?.lastName}`,
                                        value: `${i?._id}`,
                                      }
                                    })
                                  : leasingDropdownUsers &&
                                    leasingDropdownUsers?.map((i: any, index: any) => {
                                      return {
                                        label: `${i?.firstName} ${i?.lastName}`,
                                        value: `${i?._id}`,
                                      }
                                    })
                              }
                              onChange={(e: any) => {
                                setSelectedApprover(e.value)
                              }}
                              placeholder={'Select Vendor'}
                              isDisabled={
                                isSubmitToApprovar ||
                                isSubmitToProcurement ||
                                isViewOnly ||
                                isInitiator
                              }
                            />
                          )} */}
                    {/* </div>
                          )}
                      </div>
                    )}  */}
                  </>
                )}

                <div className='ms-auto mt-5'>
                  <div className='br-arr d-flex align-items-center justify-content-center'>
                    {hideApproval ? (
                      <img
                        src={upGreen}
                        height={20}
                        width={20}
                        className='cursor-pointer'
                        onClick={() => {
                          if (!sendError) setHideApproval(false)
                        }}
                      />
                    ) : (
                      <img
                        src={downGreen}
                        height={20}
                        width={20}
                        className='cursor-pointer'
                        onClick={() => {
                          if (!sendError) setHideApproval(true)
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Details Card */}
            <div className='card card-flush py-5 mt-5' style={{minHeight: '75vh'}}>
              <div className='row mx-1'>
                {/* Tabs */}
                <div className='col-7 px-5'>
                  <div className='d-flex row ms-3'>
                    {/* Maintenance */}
                    <div className='col-3 px-0'>
                      <div className='d-flex align-items-center'>
                        <div
                          className={
                            activeTab == 'maintenance'
                              ? 'tb-bg-active d-flex align-items-center justify-content-center cursor-pointer'
                              : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                          }
                          onClick={() => {
                            setActiveTab('maintenance')
                          }}
                        >
                          <img src={companyImg} height={30} width={30} />
                        </div>

                        <div className='connector'></div>
                      </div>
                    </div>

                    {/* Handling Team */}
                    <div className='col-3 px-0'>
                      <div className='d-flex align-items-center'>
                        <div
                          className={
                            activeTab == 'team'
                              ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                              : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                          }
                          onClick={() => {
                            setActiveTab('team')
                          }}
                        >
                          <img src={contactsImg} height={30} width={30} className='ms-1' />
                        </div>

                        <div className='connector'></div>
                      </div>
                    </div>

                    {/* Purchase Order */}
                    {requestWithPO && (
                      <div className='col-3 px-0 position-relative'>
                        {(requestData?.handlerType == undefined ||
                          (requestData?.handlerType == 1 && selectedVendor == undefined)) && (
                          <div className='requestBoxOpacity' style={{cursor: 'not-allowed'}}></div>
                        )}
                        <div className='d-flex align-items-center' style={(requestData?.handlerType == undefined ||
                          (requestData?.handlerType == 1 && selectedVendor == undefined)) ?  {opacity: '0.5'} : {opacity: '1'}}>
                          <div
                            className={`d-flex align-items-center justify-content-center cursor-pointer ${activeTab === 'purchase' ? 'tb-bg-active' : 'tb-bg'}`}
                            onClick={() => {setActiveTab('purchase')}}
                          >
                            <img src={companyImg} height={30} width={30} className='ms-1' />
                          </div>

                          <div className='connector'></div>
                        </div>
                      </div>
                    )}

                    {/* Review */}
                    <div className='col-3 px-0 position-relative'>
                      {(selectedService == undefined ||
                        jobs?.length == 0 ||
                        requestData?.handlerType == undefined ||
                        (requestData?.handlerType == 1 && selectedVendor == undefined)) && (
                        <div className='requestBoxOpacity' style={{cursor: 'not-allowed'}}></div>
                      )}
                      <div className='d-flex align-items-center' 
                        style={(selectedService == undefined ||
                        jobs?.length == 0 ||
                        requestData?.handlerType == undefined ||
                        (requestData?.handlerType == 1 && selectedVendor == undefined)) ?  {opacity: '0.5'} : {opacity: '1'}}>
                        <div
                          className={
                            activeTab == 'review'
                              ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                              : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                          }
                          onClick={() => {
                            setActiveTab('review')
                          }}
                        >
                          <img src={onboardTick} height={24} width={24} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex row mt-2 ms-2'>
                    {/* Maintenance */}
                    <div className='px-0 ps-1 col-3 '>
                      <h4
                        className={`mt-1 cursor-pointer ${activeTab === 'maintenance' ? 'tb-active' : 'white-dark-theme-color'}`}
                        onClick={() => {setActiveTab('maintenance')}}
                        style={{marginLeft: '-10px'}}
                      >
                        Maintenance
                      </h4>
                    </div>

                    {/* Handled By */}
                    <div className='px-0  col-3 cursor-pointer'>
                      <h4
                        className={`mt-1 cursor-pointer ${activeTab === 'team' ? 'tb-active' : 'white-dark-theme-color'}`}
                        onClick={() => { setActiveTab('team')}}
                        style={{marginLeft: '-10px'}}
                      >
                        Handling Team
                      </h4>
                    </div>

                    {/* Purchase Order */}
                    {requestWithPO && (
                      <div className='px-0 col-3 position-relative'>
                        {(requestData?.handlerType == undefined ||
                          (requestData?.handlerType == 1 && selectedVendor == undefined)) && (
                          <div className='requestBoxOpacity' style={{marginLeft: '-28px'}}></div>
                        )}
                        <h4
                          className={`mt-1 cursor-pointer ${activeTab === 'purchase' ? 'tb-active' : 'white-dark-theme-color'}`}
                          onClick={() => { setActiveTab('purchase')}}
                          style={{marginLeft: '-10px'}}
                        >
                          Purchase Order
                        </h4>
                      </div>
                    )}

                    {/* Review */}
                    <div className='mp-5 col-3 position-relative'>
                      {(selectedService == undefined ||
                        jobs?.length == 0 ||
                        requestData?.handlerType == undefined ||
                        (requestData?.handlerType == 1 && selectedVendor == undefined)) && (
                        <div className='requestBoxOpacity' style={{marginLeft: '-28px'}}></div>
                      )}
                      <h4
                        className={`mt-1 cursor-pointer ${activeTab === 'review' ? 'tb-active' : 'white-dark-theme-color'}`}
                        onClick={() => { setActiveTab('review') }}
                        style={{marginLeft: '-28px'}}
                      >
                        Review & Submit
                      </h4>
                    </div>
                  </div>
                </div>
                <div className='col-5 px-5 text end'>
                  <div className='d-flex align-items-center'>
                    <img
                      src={
                        type == 'reactive'
                          ? reactiveImg
                          : type == 'planned'
                          ? plannedImg
                          : preventativeImg
                      }
                      height={55}
                      width={55}
                      className=' ms-auto me-5'
                    />
                    <h3 className='text-capitalize me-5 white-dark-theme-color'>
                      <b>{type}</b>
                    </h3>

                    {requestData?.saveStatus != undefined ? (
                      <div className='d-block'>
                        <div
                          className={
                            requestData?.saveStatus == 0
                              ? 'status draft-st'
                              : 'status create-st'
                          }
                        >
                          {requestData?.saveStatus == 0 ? 'Draft' : 'Created'}
                        </div>
                        <i>
                          {' '}
                          {requestData?.saveStatusDateTime
                            ? `${moment
                                .utc(requestData?.saveStatusDateTime)
                                .local()
                                .format('DD.MM.YYYY - hh:mm A')} `
                            : ''}
                        </i>
                      </div>
                    ) : (
                      <div className='status not-saved ms-5'>-</div>
                    )}
                  </div>
                </div>
              </div>

              {/* Maintenance Tab */}
              {activeTab == 'maintenance' && (
                <>
                  <div className='row mx-4 mt-5'>
                    <div className='col-12 px-5 d-flex align-items-center pe-0'>
                      <h2 className='top ps-0 white-dark-theme-color'>
                        <b>Service Details</b>
                      </h2>
                      {flg != '1' && (
                        <div className='d-flex  ms-auto' style={{minWidth: '140px'}}>
                          <h4 className='my-2 text-dark'>
                            <label className='btn-fit-content pe-4'> Request ID:</label>
                            <span className='ms-1 '>
                              <b>{requestData?.id ? requestData?.id : '-'}</b>
                            </span>
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mx-4 mt-5'>
                    <div className='col-7 px-5 mt-4'>
                      <div className='d-flex my-4' ref={serviceRef}>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry status-w-130'> Main Service</label>
                        </h4>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest tenancy-control mb-3'
                          placeholder={'-'}
                          name='mainService'
                          value={selectedService?.maintenance_main_service?.name}
                          disabled
                          style={{height: '35px', width: '40%'}}
                        />{' '}
                      </div>

                      <div className='d-flex my-4'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry status-w-130'> Sub Service</label>
                        </h4>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest tenancy-control mb-3'
                          name='subService'
                          value={selectedService?.name}
                          disabled
                          style={{height: '35px', width: '40%'}}
                          placeholder={'-'}
                        />{' '}
                      </div>
                      <div className='d-flex my-4'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry status-w-130'> </label>
                        </h4>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  me-3  select-btn btn-fit-content ps-2'
                          onClick={() => {
                            setShowSelectService(true)
                          }}
                          disabled={isViewOnly || isProcurementSubmitted}
                        >
                          <img src={addService} height={18} width={18} className='me-3' /> Select
                          Service
                        </button>
                      </div>
                    </div>
                    <div className='col-5 px-5 mt-4'>
                      <h4 className='my-2 ms-1 mb-3' style={{color: 'black'}}>
                        <label className={`labl-gry`}> Service Description</label>
                      </h4>

                      <h4>
                        <textarea
                          className='form-control form-control-solid mytest ms-1'
                          name='message'
                          value={requestData?.description}
                          onChange={(e) => {
                            let values = {...requestData}
                            values.description = e.target.value
                            setRequestData(values)
                          }}
                          onBlur={() => {
                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                          style={{height: '200px'}}
                          placeholder={'Describe the service required for this request…'}
                        />
                      </h4>
                    </div>
                  </div>

                  {/* Jobs Card */}
                  <div
                    className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 '}
                    // style={{position: 'relative', zIndex: '10'}}
                    style={{border: pError}}
                    ref={jobRef}
                  >
                    <div className='d-flex  align-items-center'>
                      <h2 className='white-dark-theme-color fnt-700'>
                        {'Jobs'}
                      </h2>
                      <h5 className='user-head mb-2 ms-auto fnt-500 white-dark-theme-color'>
                        <i>
                          Total: <b>{jobs?.length}</b>
                        </i>
                      </h5>
                    </div>

                    {jobs?.map((jb: any, ind: any) => {
                      showFloor = false
                      showCluster = false
                      showUnitGroup = false
                      showBuilding = false
                    })}

                    {jobs?.map((jb: any, ind: any) => {
                      if (jb?.check == true) totalSelected = totalSelected + 1
                      if (jb?.floor?.length > 0) showFloor = true
                      if (jb?.community?.length > 0) showCluster = true
                      if (jb?.unit_group?.length > 0 || jb?.cluster?.[0]?.type == 2)
                        showUnitGroup = true
                      if (jb?.cluster?.[0]?.type == 1) showBuilding = true

                      return <></>
                    })}

                    <div className='d-flex align-items-center flex-wrap mt-4'>
                      <div className='d-block'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  me-3  select-btn btn-fit-content ps-2 '
                          onClick={() => {
                            // console.log(requestData)
                            if (jobType == 'dev-clust') setShowSelectJobDevClust(true)
                            else setShowSelectJob(true)
                          }}
                          disabled={isViewOnly || isProcurementSubmitted}
                        >
                          <img src={addService} height={18} width={18} className='me-3' /> Add Job
                        </button>

                        {jobsAddedError && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            Please add atleast one job
                          </p>
                        )}
                      </div>

                      {totalSelected > 0 && (
                        <>
                          <div className='d-flex ms-auto'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label className='labl-gry '>
                                {' '}
                                <i>Total Selected </i>
                              </label>
                            </h4>
                            <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                              <b>{totalSelected}</b>
                            </span>
                          </div>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold  ms-3 term-btn btn-fit-content ps-2'
                            onClick={() => {
                              let values = [...jobs]

                              // Filter out the Properties with checkboxes checked
                              const filteredProperties = values.filter((job) => !job.check)
                              setJobs(filteredProperties)

                              setScheduleErrorInd(-1)

                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }}
                          >
                            <img src={crossBtn} height={18} width={18} className='me-3' /> Remove
                          </button>
                        </>
                      )}
                    </div>

                    <div className='table-responsive mt-4'>
                      <table className='table align-start  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                        <thead>
                          <tr
                            className='text-start text-gray-400 fw-bold fs-7 gs-0'
                            ref={jobRefInd}
                          >
                            {!isViewOnly && (
                              <th className='text-start w-25px  test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={
                                      totalSelected == jobs?.length && jobs?.length != 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      let values: any = [...jobs]
                                      if (e.target.checked) {
                                        values?.map((list: any) => {
                                          list.check = true
                                        })
                                      } else {
                                        values?.map((list: any) => {
                                          list.check = false
                                        })
                                      }
                                      setJobs(values)
                                    }}
                                  />
                                </div>
                              </th>
                            )}

                            <th className='text-start min-w-75px'>Job ID</th>

                            {jobType != 'dev-clust' && jobs?.length > 0 && (
                              <th
                                className={`text-start ${
                                  jobType == 'unit' ? 'min-w-100px' : 'min-w-175px'
                                }`}
                              >
                                {jobType == 'unit' ? 'Unit' : 'Common Area'}
                              </th>
                            )}
                            {jobType == 'unit' && jobs?.length > 0 && (
                              <th className='text-start min-w-100px pt-2'>Unit Type</th>
                            )}

                            {showFloor && (jobType == 'unit' || jobType == 'common-area') && (
                              <th className='text-start min-w-75px'>Floor</th>
                            )}
                            {showBuilding && <th className='text-start min-w-125px'>Building</th>}

                            {showUnitGroup && <th className='text-start min-w-100px'>Group</th>}

                            {showCluster && <th className='text-start min-w-100px'>Cluster</th>}

                            <th className='text-start min-w-100px'>Development</th>
                            {jobType == 'unit' && (
                              <th className='text-start min-w-100px  pt-2'>Occupancy</th>
                            )}

                            <th className='text-center min-w-125px'>Urgency</th>
                            <th className='text-center min-w-225px'>Schedule</th>
                            <th className='text-center min-w-150px'>Job Details</th>
                            {!isViewOnly && <th className='text-start min-w-25px'></th>}
                          </tr>
                        </thead>

                        <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                          {jobs
                            ?.sort((a: any, b: any) => {
                              if (a.unitNo < b.unitNo) return -1
                              if (a.unitNo > b.unitNo) return 1
                              return 0
                            })
                            ?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  {!isViewOnly && (
                                    <td className='text-start   test'>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='vacant0'
                                          name='none'
                                          checked={rs?.check}
                                          onChange={(e: any) => {
                                            const values = [...jobs]
                                            values[index].check = e.target.checked
                                            setJobs(values)
                                          }}
                                        />
                                      </div>
                                    </td>
                                  )}

                                  <td className='text-start '>
                                    <div className='green_color'>
                                      <b>{rs?.jobIdNo != null ? rs?.jobIdNo : '-'}</b>
                                    </div>
                                  </td>

                                  {jobType != 'dev-clust' && (
                                    <td className='text-start '>
                                      {jobType == 'unit' ? (
                                        <>{rs?.unitNo ? `${rs?.unitNo}` : `-`}</>
                                      ) : (
                                        <>
                                          {' '}
                                          {rs?.unitNo
                                            ? `${rs?.unitNo} - ${rs?.commonAreaName}`
                                            : `-`}
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {jobType == 'unit' && (
                                    <td className='text-start '>
                                      {rs?.unitType === 0
                                        ? 'Apartment'
                                        : rs?.unitType === 1
                                        ? 'Penthouse'
                                        : rs?.unitType === 2
                                        ? 'Common Area'
                                        : rs?.unitType === 3
                                        ? 'Townhouse'
                                        : rs?.unitType === 4
                                        ? 'Villa'
                                        : rs?.unitType === 5
                                        ? `Other - ${rs?.otherName}`
                                        : rs?.unitType === 6
                                        ? 'Office'
                                        : rs?.unitType === 7
                                        ? 'Restaurant'
                                        : rs?.unitType === 8
                                        ? 'Cafe'
                                        : rs?.unitType === 9
                                        ? 'Retail'
                                        : '-'}
                                    </td>
                                  )}

                                  {showFloor && (jobType == 'unit' || jobType == 'common-area') && (
                                    <td className='text-start '>
                                      {rs?.floor?.length > 0 ? `${rs?.floor?.[0]?.name}` : `-`}
                                    </td>
                                  )}

                                  {showBuilding && (
                                    <td className='text-start '>
                                      {rs?.building?.length > 0
                                        ? `${rs?.building?.[0]?.name}`
                                        : `-`}
                                    </td>
                                  )}

                                  {showUnitGroup && (
                                    <td className='text-start'>
                                      {rs?.unit_group?.length > 0
                                        ? `${rs?.unit_group?.[0]?.name}`
                                        : `-`}
                                    </td>
                                  )}

                                  {showCluster && (
                                    <td className='text-start '>
                                      {rs?.cluster?.length > 0 ? `${rs?.cluster?.[0]?.name}` : `-`}
                                    </td>
                                  )}

                                  <td className='text-start'>
                                    {rs?.community?.length > 0
                                      ? `${rs?.community?.[0]?.name}`
                                      : rs?.building.length > 0
                                      ? `${rs?.building?.[0]?.name}`
                                      : `-`}
                                  </td>
                                  {jobType == 'unit' && (
                                    <td className='text-start'>
                                      {Number(rs?.unitType) !== 2 && (
                                        <>
                                          {rs?.occupy == 1 ? (
                                            <span className='text-success '>
                                              <b>Occupied</b>
                                            </span>
                                          ) : rs?.occupy == 0 ? (
                                            <span className='text-danger '>
                                              <b>Vacant</b>
                                            </span>
                                          ) : (
                                            '-'
                                          )}
                                        </>
                                      )}
                                    </td>
                                  )}

                                  <td className='text-center '>
                                    <div className='d-flex justify-content-center'>
                                      <button
                                        className={`${
                                          rs?.jobUrgency == 0
                                            ? 'urgency-stats urgencyStandardStatus cursor-pointer btn'
                                            : 'urgency-stats urgencyEmergencyStatus cursor-pointer btn'
                                        }`}
                                        onClick={() => {
                                          let clk: any = [...clicked]
                                          clicked?.map((cl: any, i: any) => {
                                            if (index == i) clk[i] = 1
                                            else clk[i] = 0
                                          })
                                          setClicked(clk)

                                          let values = [...jobs]
                                          if (values[index].jobUrgency == 0)
                                            values[index].jobUrgency = 1
                                          else values[index].jobUrgency = 0
                                          setJobs(values)

                                          setTimeout(() => {
                                            clk[index] = false
                                            setClicked(clk)
                                          }, 100)

                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 700)

                                          // setTimeout(() => {
                                          //   clk[index] = false
                                          //   setClicked(clk)
                                          // }, 100);
                                        }}
                                        disabled={isLoading}
                                      >
                                        <div
                                          className={`${
                                            clicked[index] == 1 ? 'slide-from-bottom' : ''
                                          }`}
                                        >
                                          {rs?.jobUrgency == 0 ? 'Standard' : 'Emergency'}
                                        </div>
                                      </button>
                                      {/* {!isViewOnly && (
                                      <span>
                                        <svg
                                          width='24'
                                          height='24'
                                          fill='none'
                                          stroke='currentColor'
                                          strokeWidth='2'
                                          className='dropdown-heading-dropdown-arrow gray cursor-pointer'
                                          onClick={() => {
                                            let values = [...jobs]
                                            if (values[index].jobUrgency == 0)
                                              values[index].jobUrgency = 1
                                            else values[index].jobUrgency = 0
                                            setJobs(values)

                                            setTimeout(() => {
                                              setAutoSaveData(Date.now())
                                            }, 700)
                                          }}
                                        >
                                          <path d='M6 9L12 15 18 9'></path>
                                        </svg>
                                      </span>
                                    )} */}
                                    </div>
                                  </td>
                                  <td className='text-center '>
                                    <div className='d-flex align-items-center justify-content-center'>
                                      {rs?.schedule?.date != null &&
                                        rs?.schedule?.startTime != null &&
                                        rs?.schedule?.endTime != null && (
                                          <>
                                            <img
                                              src={trashImg}
                                              height={16}
                                              width={16}
                                              className='cursor-pointer me-3'
                                              onClick={() => {
                                                let values = [...jobs]
                                                values[index].schedule = {
                                                  date: null,
                                                  startTime: null,
                                                  endTime: null,
                                                  status: 0,
                                                }
                                                setJobs(values)

                                                setTimeout(() => {
                                                  setAutoSaveData(Date.now())
                                                }, 300)
                                              }}
                                            />
                                            <div className='me-5 text-decoration-underline'>
                                              {`${moment
                                                .utc(rs?.schedule?.date)
                                                .local()
                                                .format('DD.MM.YYYY')} - 
                                              ${moment
                                                .utc(rs?.schedule?.startTime)
                                                .local()
                                                .format('HH:mm')}-${moment
                                                .utc(rs?.schedule?.endTime)
                                                .local()
                                                .format('HH:mm')}`}
                                            </div>
                                          </>
                                        )}
                                      {!isViewOnly && (
                                        <img
                                          src={schedule}
                                          height={
                                            rs?.schedule?.date == null &&
                                            rs?.schedule?.startTime == null &&
                                            rs?.schedule?.endTime == null
                                              ? 35
                                              : 25
                                          }
                                          width={
                                            rs?.schedule?.date == null &&
                                            rs?.schedule?.startTime == null &&
                                            rs?.schedule?.endTime == null
                                              ? 35
                                              : 25
                                          }
                                          className='cursor-pointer'
                                          onClick={() => {
                                            setScheduleIndToAddEdit(index)
                                            setScheduleToAddEdit(rs?.schedule)
                                            setShowSchedule(true)
                                          }}
                                        />
                                      )}
                                    </div>
                                    {index == scheduleErrorInd && (
                                      <p
                                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                        className='m-0 mt-1'
                                      >
                                        Please add job schedule
                                      </p>
                                    )}
                                  </td>
                                  <td className='text-center'>
                                    <div className='d-flex justify-content-center'>
                                      <button
                                        type='button'
                                        className={`btn btn-sm fw-bold d-flex justify-content-center ps-2 btn-fit-content ${
                                          rs?.comments?.length > 0 || rs?.attachments?.length > 0
                                            ? 'green-submit-btn'
                                            : 'green-hollow-btn'
                                        }`}
                                        onClick={() => {
                                          let isUpdate: any =
                                            rs?.comments?.length > 0 || rs?.attachments?.length > 0
                                          localStorage.setItem('isJobDetails', isUpdate)

                                          setJobsIndToAddEdit(index)
                                          setJobsToAddEdit(rs)
                                          setShowJobDetails(true)
                                        }}
                                        disabled={isViewOnly}
                                      >
                                        <img
                                          src={`${
                                            rs?.comments?.length > 0 || rs?.attachments?.length > 0
                                              ? addWhite
                                              : addGreen
                                          }`}
                                          height={17}
                                          width={17}
                                          className='me-3'
                                        />
                                        Add Details
                                      </button>
                                    </div>
                                  </td>
                                  {!isViewOnly && (
                                    <td className='text-center ps-0'>
                                      <img
                                        src={redCross}
                                        height={16}
                                        width={16}
                                        className='me-4 cursor-pointer mt-2'
                                        onClick={() => {
                                          let values = [...jobs]
                                          values.splice(index, 1)
                                          setJobs(values)

                                          if (values?.length == 0) {
                                            let values = {...requestData}
                                            values.community = null
                                            values.building = null
                                            setRequestData(values)
                                          }

                                          let temp = [...deletedServiceJob]
                                          if (rs?.jobId != null)
                                            temp[temp?.length] = {
                                              _id: rs?.jobId,
                                            }

                                          setDeletedServiceJob(temp)

                                          setScheduleErrorInd(-1)

                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 700)
                                        }}
                                      />
                                    </td>
                                  )}
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              {/* Handled By Tab */}
              {activeTab == 'team' && (
                <>
                  <div className='row mx-4 mt-5'>
                    <div className='col-12 px-5 text-center mt-3'>
                      <h1 className='top ps-0 white-dark-theme-color'>
                        <b>Select The Team Who Will Handle The Request</b>
                      </h1>
                    </div>
                  </div>
                  <div className='row mx-4 mb-5'>
                    <div className='d-flex justify-content-evenly mt-5'>
                      <div className='text-center me-5 status-w-150 position-relative'>
                        {(isViewOnly || isProcurementSubmitted) && (
                          <div className='requestBoxOpacity'></div>
                        )}
                        <div
                          className={`${
                            requestData?.handlerType == 0
                              ? 'mainRequestBoxColor'
                              : 'mainRequestBox '
                          } mt-3 status-w-150 main-status-150`}
                          onClick={() => {
                            const values = {...requestData}
                            values.handlerType = 0
                            setRequestData(values)

                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }}
                          style={{width: '150px'}}
                        >
                          <h2
                            className={`head-text mb-3 ${
                              requestData?.handlerType == 0 ? 'headTextWhite' : ''
                            }`}
                          >
                            <b>Internal Team</b>
                          </h2>
                          <img
                            src={
                              requestData?.handlerType == 0 ? internalTeamWhite : internalTeamGreen
                            }
                            height={80}
                            width={80}
                            className='me-3 d-block mt-3'
                          />
                        </div>
                      </div>
                      <div
                        className='text-center ms-5 status-w-150 position-relative'
                        ref={vendorRef}
                      >
                        {(isViewOnly || isProcurementSubmitted) && (
                          <div className='requestBoxOpacity'></div>
                        )}
                        <div
                          className={`${
                            requestData?.handlerType == 1
                              ? 'mainRequestBoxColor'
                              : 'mainRequestBox '
                          } mt-3 status-w-150`}
                          onClick={() => {
                            const values = {...requestData}
                            values.handlerType = 1
                            setRequestData(values)

                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }}
                        >
                          <h2
                            className={`head-text mb-3 ${
                              requestData?.handlerType == 1 ? 'headTextWhite' : ''
                            }`}
                          >
                            <b>Vendor</b>
                          </h2>
                          <img
                            src={
                              requestData?.handlerType == 1 ? externalTeamWhite : externalTeamGreen
                            }
                            height={80}
                            width={80}
                            className='me-3 d-block mt-3'
                          />
                        </div>
                        {requestData?.handlerType == 1 && (
                          <>
                            <button
                              type='button'
                              className='mt-7 btn btn-sm fw-bold select-btn w-100 d-flex justify-content-center btn-fit-content ps-2 ms-4'
                              onClick={() => {
                                setShowSelectVendor(true)
                              }}
                              disabled={isViewOnly}
                            >
                              <img src={addService} height={18} width={18} className='me-3' />
                              Select Vendor
                            </button>

                            {vendorError && (
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0 mt-1'
                              >
                                Please select a vendor
                              </p>
                            )}
                          </>
                        )}

                        {requestData?.handlerType == 1 && selectedVendor && (
                          <h3 className='text-decoration-underline mt-5 white-dark-theme-color'>
                            <b>{selectedVendor?.companyName ? selectedVendor?.companyName : '-'}</b>
                          </h3>
                        )}

                        {requestData?.handlerType == 1 && (
                          <div className='ps-0 my-5'>
                            <p className='fs-4' style={{color: '#9797ae', fontWeight: '500'}}>
                              {' '}
                              <i>
                                The request will be submitted to the vendor’s portal registered
                                under the main contact email. An email will also be sent to the main
                                contact notifying them of the request.
                              </i>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Purchase Order Tab */}
              {activeTab == 'purchase' && (
                <>
                  {!isPOLoading ? (
                    <>
                      {generatePOEnabled ? (
                        <div className='row mx-4 mt-10'>
                          <div className='d-flex  justify-content-center'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center purchase-order-btn mt-5'
                              // onClick={(e: any) => {
                              //   if (flg == '1')
                              //     swal
                              //       .fire({
                              //         html: `
                              //         <div class='fs-3'> <b>  Save Content Confirmation</b></div>
                              //         <div class='fs-5 mt-4'> Before proceeding to the next screen, please ensure to save the current content as it will be utilized in the upcoming screen. </div>`,
                              //         // icon: 'warning',
                              //         showConfirmButton: true,
                              //         confirmButtonColor: '#35475e',
                              //         confirmButtonText: 'Save & Continue',
                              //         showCancelButton: true,
                              //         cancelButtonText: 'Cancel',
                              //         cancelButtonColor: '#fff',
                              //         customClass: {
                              //           confirmButton:
                              //             'custom-cancel-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                              //           cancelButton:
                              //             'custom-confirm-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                              //           popup: 'custom-popup',
                              //         },
                              //         // reverseButtons: true,
                              //       })
                              //       .then((res: any) => {
                              //         if (res.isConfirmed) {
                              //           addRequest('purchase_order', 0)
                              //         }
                              //       })
                              //   else
                              //     navigate(
                              //       `/maintenance/create-request/purchase-order/${type}/1/${id}`
                              //     )
                              // }}

                              onClick={() => {
                                // Internal Team
                                if (requestData?.handlerType == 0) {
                                  if (flg == '1') {
                                    swal
                                      .fire({
                                        html: ` 
                                      <div class='fs-3'> <b>  Save Content Confirmation</b></div>
                                      <div class='fs-5 mt-4'> Before proceeding to the next screen, please ensure to save the current content as it will be utilized in the upcoming screen. </div>`,
                                        // icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#35475e',
                                        confirmButtonText: 'Save & Continue',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                        cancelButtonColor: '#fff',
                                        customClass: {
                                          confirmButton:
                                            'custom-cancel-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                                          cancelButton:
                                            'custom-confirm-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                                          popup: 'custom-popup',
                                        },
                                        // reverseButtons: true,
                                      })
                                      .then((res: any) => {
                                        if (res.isConfirmed) {
                                          addRequest('purchase_order_internal', 0)
                                        }
                                      })
                                  } else {
                                    navigate(
                                      `/maintenance/create-request/purchase-order/${type}/1/${id}/0`
                                    )
                                  }
                                } else {
                                  setShowGeneratePOModel(true)
                                }
                              }}
                              disabled={isLoading || isViewOnly}
                            >
                              {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
                              Generate Purchase Order
                            </button>
                          </div>
                          <div className='px-5'>
                            <div
                              className='card card-flush po-table-card mt-10 p-5 pt-0 pe-0 mb-10'
                              style={addedPOs?.length == 0 ? {minHeight: '400px'} : {}}
                            >
                              <div
                                className='table-responsive mt-1 pe-5'
                                style={{maxHeight: '400px', overflow: 'auto'}}
                              >
                                <table
                                  className='table align-middle table-row-dashed  fs-6 gy-5'
                                  id='kt_ecommerce_sales_table'
                                >
                                  <thead>
                                    <th className='text-start min-w-100px ps-0'>Purchase Order</th>
                                    <th className='text-start  ps-0 status-w-50'></th>
                                    <th className='text-start  ps-0 min-w-50px'></th>
                                    <th className='text-start min-w-100px ps-0'>Amount</th>
                                    <th className='text-center min-w-150px ps-0'>Signed Copy</th>
                                    <th className='text-start min-w-100px ps-0'>Vendor</th>
                                    <th className='text-start min-w-100px ps-0'>Date Issued</th>
                                    {!isViewOnly && (
                                      <th className='text-start min-w-25px ps-0'></th>
                                    )}
                                  </thead>

                                  <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                                    {addedPOs?.length > 0 ? (
                                      <>
                                        {addedPOs?.map((ad: any, index: any) => {
                                          return (
                                            <tr className=''>
                                              <td
                                                className='text-start min-w-100px ps-0'
                                                onClick={() => {
                                                  if (ad?.saveStatus == 0)
                                                    navigate(
                                                      `/maintenance/create-request/purchase-order/${type}/2/${id}/${ad?._id}/${ad?.maintenancePurchaseOrderType}`
                                                    )
                                                  else
                                                    navigate(
                                                      `/maintenance/view-purchase-order/${type}/2/${id}/${ad?._id}`
                                                    )
                                                }}
                                              >
                                                <div className='green_color text-decoration-underline cursor-pointer'>
                                                  <b>
                                                    {ad?.purchaseOrderNo
                                                      ? ad?.purchaseOrderNo
                                                      : '-'}
                                                  </b>
                                                </div>
                                              </td>
                                              <td className='text-start  ps-0'>
                                                <div className='d-flex align-items-center my-3'>
                                                  {ad?.saveStatus == 0 ? (
                                                    <p className='mb-0 ps-1'>Draft</p>
                                                  ) : (
                                                    // <a
                                                    //   href={`${Bucket}${ad?.pdfURL}`}
                                                    //   target='_blank'
                                                    // >
                                                      <img
                                                        src={pdfIcon}
                                                        height={40}
                                                        width={40}
                                                        className='me-4 cursor-pointer'
                                                        id={`addedPos${index}`}
                                                        onClick={() =>
                                                        handleImageClick(
                                                          `addedPos${index}`,
                                                          `${Bucket}${ad?.pdfURL}`
                                                        )
                                                      }
                                                      />
                                                    // </a>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start  ps-0'>
                                                <div>
                                                  {ad?.maintenancePurchaseOrderType == 1 && (
                                                    <span>
                                                      <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 200}}
                                                        overlay={
                                                          <Tooltip
                                                            id='button-tooltip-2'
                                                            className='custom-tooltip'
                                                          >
                                                            {' '}
                                                            PO for the External Handling Team and
                                                            will be shared with them as part of the
                                                            request via the vendor portal.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <span>
                                                          {' '}
                                                          <AiOutlineExclamationCircle
                                                            color='#384a62'
                                                            fontSize={15}
                                                            fontWeight={700}
                                                            className='cursor-pointer'
                                                            style={{
                                                              cursor: 'pointer',
                                                              marginBottom: '2px',
                                                            }}
                                                          />
                                                        </span>
                                                      </OverlayTrigger>
                                                    </span>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start min-w-100px ps-0'>
                                                {'AED'}{' '}
                                                {ad?.totalAmount
                                                  ? ad?.totalAmount?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })
                                                  : '-'}
                                              </td>
                                              <td className='text-center min-w-100px ps-0'>
                                                {ad?.saveStatus == 1 ? (
                                                  <>
                                                    {ad?.singedURL?.trim()?.length > 0 ? (
                                                      <div className='d-flex align-items-start justify-content-center my-3'>
                                                        {/* <a
                                                          href={`${Bucket}${ad?.singedURL}`}
                                                          target='_blank'
                                                        > */}
                                                          <img
                                                            src={pdfIcon}
                                                            height={40}
                                                            width={40}
                                                            className='mx-4 cursor-pointer'
                                                            id={`addedSignedPos${index}`}
                                                            onClick={() =>
                                                              handleImageClick(
                                                                `addedSignedPos${index}`,
                                                                `${Bucket}${ad?.singedURL}`
                                                              )
                                                            }
                                                          />
                                                        {/* </a> */}
                                                        {!isViewOnly && (
                                                          <img
                                                            src={redCross}
                                                            height={16}
                                                            width={16}
                                                            className='me-4 cursor-pointer'
                                                            onClick={() => {
                                                              swal
                                                                .fire({
                                                                  html: ` 
                                                              <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                              <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                                  // icon: 'warning',
                                                                  showConfirmButton: true,
                                                                  confirmButtonColor: '#35475e',
                                                                  confirmButtonText: 'Yes',
                                                                  showCancelButton: true,
                                                                  cancelButtonText: 'No',
                                                                  cancelButtonColor: '#fff',
                                                                  customClass: {
                                                                    confirmButton:
                                                                      'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                                    cancelButton:
                                                                      'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                                    popup: 'custom-popup',
                                                                  },
                                                                  // reverseButtons: true,
                                                                })
                                                                .then((res: any) => {
                                                                  if (res.isConfirmed) {
                                                                    deletePOSignedCopy(
                                                                      ad,
                                                                      false,
                                                                      index
                                                                    )
                                                                  }
                                                                })
                                                            }}
                                                          />
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div className='d-flex align-items-center justify-content-center'>
                                                        <form
                                                          id='form-file-upload'
                                                          onDragEnter={handleDrag}
                                                          onSubmit={(e) => e.preventDefault()}
                                                        >
                                                          <label
                                                            id='label-file-upload'
                                                            htmlFor='input-file-upload'
                                                            className={
                                                              dragActive
                                                                ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                                : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                            }
                                                            style={{
                                                              fontSize: '16px',
                                                              color: 'white',
                                                            }}
                                                            ref={inputRef}
                                                          >
                                                            <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                              <img
                                                                src={uploadFileWhite}
                                                                height={20}
                                                                width={18}
                                                                className='me-4'
                                                              />{' '}
                                                              {'  '}Upload
                                                            </div>
                                                          </label>
                                                          <input
                                                            type='file'
                                                            // multiple={true}
                                                            onChange={(e: any) => {
                                                              handleUploadSignedCopies(e, index, ad)
                                                            }}
                                                            id='input-file-upload'
                                                            ref={inputRef}
                                                            name='input-file-upload'
                                                            disabled={isLoading || isViewOnly}
                                                            accept='application/pdf'
                                                            style={{
                                                              display: 'none',
                                                              visibility: 'hidden',
                                                            }}
                                                          />
                                                          {dragActive && (
                                                            <div
                                                              id='drag-file-element'
                                                              onDragEnter={handleDrag}
                                                              onDragLeave={handleDrag}
                                                              onDragOver={handleDrag}
                                                              onDrop={handleDrop}
                                                            ></div>
                                                          )}
                                                        </form>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </td>
                                              <td className='text-start min-w-100px ps-0'>
                                                {ad?.vendorAddress?.name
                                                  ? ad?.vendorAddress?.name
                                                  : '-'}
                                              </td>
                                              <td className='text-start min-w-100px ps-0'>
                                                {ad?.createdAt && ad?.saveStatus == 1
                                                  ? `${moment
                                                      .utc(ad?.createdAt)
                                                      .local()
                                                      .format('DD.MM.YYYY')} `
                                                  : '-'}
                                              </td>
                                              {!isViewOnly && (
                                                <td className='text-start min-w-25px ps-0'>
                                                  <img
                                                    src={trashImg}
                                                    height={18}
                                                    width={18}
                                                    className='mx-5 cursor-pointer'
                                                    onClick={() => {
                                                      swal
                                                        .fire({
                                                          html: ` 
                                                          <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                          
                                                            <div class='fs-5 mt-4'> You are about to delete this purchase order. </div>
                                                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                          // icon: 'warning',
                                                          showConfirmButton: true,
                                                          confirmButtonColor: '#35475e',
                                                          confirmButtonText: 'Yes',
                                                          showCancelButton: true,
                                                          cancelButtonText: 'No',
                                                          cancelButtonColor: '#fff',
                                                          customClass: {
                                                            confirmButton:
                                                              'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                            cancelButton:
                                                              'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                            popup: 'custom-popup',
                                                          },
                                                          // reverseButtons: true,
                                                        })
                                                        .then((res: any) => {
                                                          if (res.isConfirmed) {
                                                            deletePO(ad?._id)
                                                          }
                                                        })
                                                    }}
                                                  />
                                                </td>
                                              )}
                                            </tr>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='row mx-4 mt-10'>
                          <div className='d-flex justify-content-center'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center purchase-order-btn mt-5'
                              disabled={isLoading || isViewOnly}
                              onClick={() => {
                                if (
                                  uploadedPOs?.length == 0 ||
                                  (uploadedPOs?.length > 0 &&
                                    uploadedPOs[uploadedPOs?.length - 1]?.isEdit != true)
                                ) {
                                  if (requestData?.handlerType == 0) handleAddUploadedPOs(0)
                                  else setShowGeneratePOModel(true)
                                } else {
                                  ErrorToast('Please first add the previous record..')
                                }

                                // handleAddUploadedPOs()
                              }}
                            >
                              Add Purchase Order
                            </button>
                          </div>
                          <div className='px-5'>
                            <div
                              className='card card-flush po-table-card mt-10 p-5 pt-0 pe-0 mb-10'
                              style={uploadedPOs?.length == 0 ? {minHeight: '400px'} : {}}
                            >
                              <div
                                className='table-responsive mt-1 pe-5'
                                style={{maxHeight: '400px', overflow: 'auto'}}
                              >
                                <table
                                  className='table align-middle table-row-dashed  fs-6 gy-5'
                                  id='kt_ecommerce_sales_table'
                                >
                                  <thead>
                                    <th className='text-start min-w-100px ps-0'>Purchase Order</th>
                                    <th className='text-start  ps-0 status-w-50'></th>
                                    <th className='text-start  ps-0 min-w-50px'></th>
                                    <th className='text-start min-w-100px ps-0'>Amount</th>
                                    <th className='text-center min-w-150px ps-0'>Signed Copy</th>
                                    <th className='text-start min-w-100px ps-0'>Vendor</th>
                                    <th className='text-start min-w-100px ps-0'>Date Issued</th>
                                    {!isViewOnly && (
                                      <th className='text-start min-w-25px ps-0'></th>
                                    )}
                                  </thead>

                                  <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                                    {uploadedPOs?.length > 0 ? (
                                      <>
                                        {uploadedPOs?.map((up: any, index: any) => {
                                          return (
                                            <tr className=''>
                                              <td
                                                className='text-start min-w-100px ps-0 pe-10'
                                                onClick={() => {}}
                                              >
                                                <div
                                                  className={``}
                                                >
                                                  {up?.isEdit ? (
                                                    <input
                                                      type='text'
                                                      className='form-control form-control-solid mytest tenancy-control me-10'
                                                      placeholder={'Enter PO #'}
                                                      name='mainService'
                                                      value={up?.purchaseOrderNo}
                                                      style={{height: '35px'}}
                                                      onChange={(e: any) => {
                                                        let values = [...uploadedPOs]
                                                        values[index].purchaseOrderNo =
                                                          e.target.value
                                                        setUploadedPOs(values)
                                                      }}
                                                    />
                                                  ) : (
                                                    <b>
                                                      {up?.purchaseOrderNo
                                                        ? up?.purchaseOrderNo
                                                        : '-'}
                                                    </b>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start  ps-0 '>
                                                <div className='d-flex align-items-center'>
                                                  {up?.pdfURL?.trim()?.length == 0 ? (
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <form
                                                        id='form-file-upload'
                                                        onDragEnter={handleDrag}
                                                        onSubmit={(e) => e.preventDefault()}
                                                      >
                                                        <label
                                                          id='label-file-upload'
                                                          htmlFor='input-file-upload'
                                                          className={
                                                            dragActive
                                                              ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                              : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                          }
                                                          style={{
                                                            fontSize: '16px',
                                                            color: 'white',
                                                          }}
                                                          ref={inputRef}
                                                        >
                                                          <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                            <img
                                                              src={uploadFileWhite}
                                                              height={20}
                                                              width={18}
                                                              className='me-4'
                                                            />{' '}
                                                            {'  '}Upload
                                                          </div>
                                                        </label>
                                                        <input
                                                          type='file'
                                                          // multiple={true}
                                                          onChange={(e: any) => {
                                                            handleUploadPO(e, index)
                                                          }}
                                                          id='input-file-upload'
                                                          ref={inputRef}
                                                          name='input-file-upload'
                                                          disabled={isLoading || isViewOnly}
                                                          accept='application/pdf'
                                                          style={{
                                                            display: 'none',
                                                            visibility: 'hidden',
                                                          }}
                                                        />
                                                        {dragActive && (
                                                          <div
                                                            id='drag-file-element'
                                                            onDragEnter={handleDrag}
                                                            onDragLeave={handleDrag}
                                                            onDragOver={handleDrag}
                                                            onDrop={handleDrop}
                                                          ></div>
                                                        )}
                                                      </form>
                                                    </div>
                                                  ) : (
                                                    // <a
                                                    //   href={`${Bucket}${up?.pdfURL}`}
                                                    //   target='_blank'
                                                    // >
                                                      <img
                                                        src={pdfIcon}
                                                        height={40}
                                                        width={40}
                                                        className='me-4 cursor-pointer'
                                                        id={`uploadedPos${index}`}
                                                        onClick={() =>
                                                          handleImageClick(
                                                            `uploadedPos${index}`,
                                                            `${Bucket}${up?.pdfURL}`
                                                          )
                                                        }
                                                      />
                                                    // </a>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start  ps-0'>
                                                <div>
                                                  {up?.maintenancePurchaseOrderType == 1 && (
                                                    <span>
                                                      <OverlayTrigger
                                                        placement='top'
                                                        delay={{show: 250, hide: 200}}
                                                        overlay={
                                                          <Tooltip
                                                            id='button-tooltip-2'
                                                            className='custom-tooltip'
                                                          >
                                                            {' '}
                                                            PO for the External Handling Team and
                                                            will be shared with them as part of the
                                                            request via the vendor portal.
                                                          </Tooltip>
                                                        }
                                                      >
                                                        <span>
                                                          {' '}
                                                          <AiOutlineExclamationCircle
                                                            color='#384a62'
                                                            fontSize={15}
                                                            fontWeight={700}
                                                            className='cursor-pointer'
                                                            style={{
                                                              cursor: 'pointer',
                                                              marginBottom: '2px',
                                                            }}
                                                          />
                                                        </span>
                                                      </OverlayTrigger>
                                                    </span>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start min-w-100px ps-0 pe-10'>
                                                {up?.isEdit ? (
                                                  <input
                                                    type='number'
                                                    className='form-control form-control-solid mytest tenancy-control mb-3'
                                                    placeholder={'Enter PO Amount'}
                                                    name='mainService'
                                                    value={up?.totalAmount}
                                                    style={{height: '35px'}}
                                                    onChange={(e: any) => {
                                                      const newValue = e.target.value.replace(
                                                        /^0+/,
                                                        ''
                                                      )
                                                      let values = [...uploadedPOs]
                                                      values[index].totalAmount = newValue
                                                      setUploadedPOs(values)
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    {'AED'}{' '}
                                                    {up?.totalAmount
                                                      ? up?.totalAmount?.toLocaleString('en-US', {
                                                          minimumFractionDigits: 2,
                                                        })
                                                      : '-'}
                                                  </>
                                                )}
                                              </td>
                                              <td className='text-center min-w-100px ps-0'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                  {up?.singedURL?.trim()?.length == 0 ? (
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <form
                                                        id='form-file-upload-po-copy'
                                                        onDragEnter={handleDrag}
                                                        onSubmit={(e) => e.preventDefault()}
                                                      >
                                                        <label
                                                          id='form-file-upload-po-copy'
                                                          htmlFor='form-file-upload-po-copyyy'
                                                          className={
                                                            dragActive
                                                              ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                              : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                          }
                                                          style={{
                                                            fontSize: '16px',
                                                            color: 'white',
                                                          }}
                                                          // ref={inputRef}
                                                        >
                                                          <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                            <img
                                                              src={uploadFileWhite}
                                                              height={20}
                                                              width={18}
                                                              className='me-4'
                                                            />{' '}
                                                            {'  '}Upload
                                                          </div>
                                                        </label>
                                                        <input
                                                          type='file'
                                                          // multiple={true}
                                                          onChange={(e: any) => {
                                                            handleUploadSignedCopiesOfUploadedPO(
                                                              e,
                                                              index,
                                                              up
                                                            )
                                                          }}
                                                          id='form-file-upload-po-copyyy'
                                                          // ref={inputRef}
                                                          name='form-file-upload-po-copy'
                                                          disabled={isLoading || isViewOnly}
                                                          accept='application/pdf'
                                                          style={{
                                                            display: 'none',
                                                            visibility: 'hidden',
                                                          }}
                                                        />
                                                        {dragActive && (
                                                          <div
                                                            id='drag-file-element'
                                                            onDragEnter={handleDrag}
                                                            onDragLeave={handleDrag}
                                                            onDragOver={handleDrag}
                                                            onDrop={handleDrop}
                                                          ></div>
                                                        )}
                                                      </form>
                                                    </div>
                                                  ) : (
                                                    <div className='d-flex justify-content-center'>
                                                      {/* <a
                                                        href={`${Bucket}${up?.singedURL}`}
                                                        target='_blank'
                                                      > */}
                                                        <img
                                                          src={pdfIcon}
                                                          height={40}
                                                          width={40}
                                                          className='cursor-pointer'
                                                          id={`uploadedSignedPos${index}`}
                                                          onClick={() =>
                                                            handleImageClick(
                                                              `uploadedSignedPos${index}`,
                                                              `${Bucket}${up?.singedURL}`
                                                            )
                                                          }
                                                        />
                                                      {/* </a> */}
                                                      {!isViewOnly && !up?.isEdit && (
                                                        <img
                                                          src={redCross}
                                                          height={16}
                                                          width={16}
                                                          className='ms-3 cursor-pointer'
                                                          onClick={() => {
                                                            swal
                                                              .fire({
                                                                html: ` 
                                                                <div class='fs-3'> <b>Delete Signed Copy </b></div>
                                                                <div class='fs-5 mt-4'> Are you want to delete this purchase order's signed copy? </div>`,
                                                                // icon: 'warning',
                                                                showConfirmButton: true,
                                                                confirmButtonColor: '#35475e',
                                                                confirmButtonText: 'Yes',
                                                                showCancelButton: true,
                                                                cancelButtonText: 'No',
                                                                cancelButtonColor: '#fff',
                                                                customClass: {
                                                                  confirmButton:
                                                                    'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                                  cancelButton:
                                                                    'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                                  popup: 'custom-popup',
                                                                },
                                                                // reverseButtons: true,
                                                              })
                                                              .then((res: any) => {
                                                                if (res.isConfirmed) {
                                                                  deletePOSignedCopy(
                                                                    up,
                                                                    true,
                                                                    index
                                                                  )
                                                                }
                                                              })
                                                          }}
                                                        />
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </td>
                                              <td className='text-start min-w-100px ps-0 pe-10'>
                                                {up?.isEdit ? (
                                                  <>
                                                    {up?.maintenancePurchaseOrderType == 1 ? (
                                                      <>{up?.vendorName ? up?.vendorName : '-'}</>
                                                    ) : (
                                                      <input
                                                        type='text'
                                                        className='form-control form-control-solid mytest tenancy-control me-10'
                                                        placeholder={'Enter Vendor Name'}
                                                        name='mainService'
                                                        value={up?.vendorName}
                                                        style={{height: '35px'}}
                                                        onChange={(e: any) => {
                                                          let values = [...uploadedPOs]
                                                          values[index].vendorName = e.target.value
                                                          setUploadedPOs(values)
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                ) : (
                                                  <>{up?.vendorName ? up?.vendorName : '-'}</>
                                                )}
                                              </td>
                                              <td className='text-start min-w-100px ps-0'>
                                                {!up?.isEdit
                                                  ? `${moment
                                                      .utc(up?.createdAt)
                                                      .local()
                                                      .format('DD.MM.YYYY')} `
                                                  : '-'}
                                              </td>
                                              {!isViewOnly && (
                                                <td className='text-start min-w-25px ps-0'>
                                                  {up?.isEdit ? (
                                                    <div
                                                      className='d-flex'
                                                      style={{width: 'max-content'}}
                                                    >
                                                      <img
                                                        src={removed}
                                                        height={20}
                                                        width={20}
                                                        className='mx-5 cursor-pointer'
                                                        onClick={(e: any) => {
                                                          let values = [...uploadedPOs]

                                                          values.splice(index, 1)

                                                          setUploadedPOs(values)
                                                        }}
                                                      />
                                                      <img
                                                        src={correct}
                                                        height={20}
                                                        width={20}
                                                        className='mx-5 ms-0 cursor-pointer'
                                                        onClick={() => {
                                                          let values = [...uploadedPOs]
                                                          if (
                                                            values[index].vendorName?.trim()
                                                              ?.length == 0 ||
                                                            values[index].purchaseOrderNo?.trim()
                                                              ?.length == 0 ||
                                                            values[index].totalAmount <= 0 ||
                                                            //  values[index].totalAmount?.trim()?.length == 0  ||

                                                            up?.singedURL?.trim()?.length == 0 ||
                                                            up?.pdfURL?.trim()?.length == 0
                                                          )
                                                            ErrorToast(
                                                              'Please ensure all fields are completed accurately.'
                                                            )
                                                          else addUpdatePOs()
                                                        }}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <img
                                                      src={trashImg}
                                                      height={18}
                                                      width={18}
                                                      className='mx-5 cursor-pointer'
                                                      onClick={() => {
                                                        swal
                                                          .fire({
                                                            html: ` 
                                                            <div class='fs-3'> <b>Delete Purchase Order</b></div>
                                                            <div class='fs-5 mt-4'> You are about to delete this purchase order. </div>
                                                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                                            // icon: 'warning',
                                                            showConfirmButton: true,
                                                            confirmButtonColor: '#35475e',
                                                            confirmButtonText: 'Yes',
                                                            showCancelButton: true,
                                                            cancelButtonText: 'No',
                                                            cancelButtonColor: '#fff',
                                                            customClass: {
                                                              confirmButton:
                                                                'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                              cancelButton:
                                                                'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                                                              popup: 'custom-popup',
                                                            },
                                                            // reverseButtons: true,
                                                          })
                                                          .then((res: any) => {
                                                            if (res.isConfirmed) {
                                                              deleteUploadPO(up, index)
                                                            }
                                                          })
                                                      }}
                                                    />
                                                  )}
                                                </td>
                                              )}
                                            </tr>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className='d-flex justify-content-center align-items-center w-100'
                      style={{height: '75vh'}}
                    >
                      <CircularProgress style={{color: '#007a59'}} />
                    </div>
                  )}
                </>
              )}

              {/* Handled By Tab */}
              {activeTab == 'review' && (
                <>
                  <div className='row mx-0 mt-5'>
                    {/* Service Details */}
                    <div className='col-6 pe-5 ps-0 mt-3'>
                      <div className='row mx-4 mt-5'>
                        <div className='col-12 px-5'>
                          <h2 className='top ps-0 white-dark-theme-color'>
                            <b>Service Details</b>
                          </h2>
                        </div>
                      </div>
                      <div className='row mx-4 mt-5'>
                        <div className='col-12 px-5 mt-4'>
                          <div className='d-flex my-4'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label  className='labl-gry light-dark-theme-color status-w-130'>
                                {' '}
                                Main Service
                              </label>
                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {selectedService?.maintenance_main_service?.name
                                    ? selectedService?.maintenance_main_service?.name
                                    : '-'}
                                </b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-4'>
                          <div className='d-flex my-4'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label className='labl-gry light-dark-theme-color status-w-130'>
                                {' '}
                                Sub Service
                              </label>
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{selectedService?.name ? selectedService?.name : '-'}</b>
                              </span>
                            </h4>
                          </div>
                        </div>
                        <div className='col-12 px-5 mt-4'>
                          <h4 className='my-2 mb-3' style={{color: 'black'}}>
                            <label className={`labl-gry light-dark-theme-color`}> Service Description</label>
                          </h4>

                          <h4>
                            <textarea
                              className='form-control form-control-solid mytest'
                              placeholder='---'
                              name='message'
                              value={requestData?.description}
                              style={{minHeight: '150px'}}
                              disabled
                            />
                          </h4>
                        </div>
                      </div>
                    </div>
                    {/* Team Card */}
                    <div className='col-6 px-5 mt-3'>
                      <div
                        className={'card card-flush p-5 px-7 mt-2 mb-5'}
                        style={{position: 'relative', zIndex: '10'}}
                      >
                        <div className='d-flex'>
                          <div className='d-block px-3 ps-0 py-2'>
                            <h2 className='top ps-0 white-dark-theme-color'>
                              <b>Handled By</b>
                            </h2>
                            {requestData?.handlerType == 1 ? (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label className='labl-gry light-dark-theme-color status-w-130'>
                                    {' '}
                                    Vendor Name
                                  </label>
                                  <span className='ms-1 white-dark-theme-color'>
                                    <b>
                                      {selectedVendor?.companyName
                                        ? selectedVendor?.companyName
                                        : '-'}
                                    </b>
                                  </span>
                                </h4>
                              </div>
                            ) : (
                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label className='labl-gry light-dark-theme-color status-w-130'>
                                    {' '}
                                    Team Name
                                  </label>
                                  <span className='ms-1 white-dark-theme-color'>
                                    <b>{'Internal Team'}</b>
                                  </span>
                                </h4>
                              </div>
                            )}

                            {requestData?.handlerType == 1 && (
                              <>
                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label className='labl-gry light-dark-theme-color status-w-130'>
                                      {' '}
                                      Main Contact
                                    </label>
                                    <span className='ms-1 white-dark-theme-color'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.firstName !=
                                          null ||
                                        selectedVendor?.contactInformation?.[0]?.lastName != null
                                          ? `${selectedVendor?.contactInformation?.[0]?.firstName} ${selectedVendor?.contactInformation?.[0]?.lastName}`
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label className='labl-gry light-dark-theme-color status-w-130'>
                                      {' '}
                                    </label>
                                    <span className='ms-1 white-dark-theme-color'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.email
                                          ? selectedVendor?.contactInformation?.[0]?.email
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>

                                <div className='d-flex my-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label className='labl-gry light-dark-theme-color status-w-130'>
                                      {' '}
                                    </label>
                                    <span className='ms-1 white-dark-theme-color'>
                                      <b>
                                        {selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          ? selectedVendor?.contactInformation?.[0]?.phoneNumber
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              </>
                            )}
                          </div>

                          <div className={`mainRequestBox mt-3 ms-auto`} style={{height: '150px'}}>
                            <h2 className={`head-text mb-3`}>
                              <b>
                                {requestData?.handlerType == 0
                                  ? 'Internal Team'
                                  : requestData?.handlerType == 1
                                  ? 'Vendor'
                                  : '-'}{' '}
                              </b>
                            </h2>
                            <img
                              src={
                                requestData?.handlerType == 0
                                  ? internalTeamGreen
                                  : requestData?.handlerType == 1
                                  ? externalTeamGreen
                                  : ''
                              }
                              height={80}
                              width={80}
                              className='me-3 d-block mt-3'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {jobs?.map((jb: any, ind: any) => {
                    showFloor = false
                    showCluster = false
                    showUnitGroup = false
                  })}

                  {jobs?.map((jb: any, ind: any) => {
                    // console.log(jb)
                    if (jb?.check == true) totalSelected = totalSelected + 1
                    if (jb?.floor?.length > 0) showFloor = true
                    if (jb?.community?.length > 0) showCluster = true
                    if (jb?.unit_group?.length > 0 || jb?.cluster?.[0]?.type == 2)
                      showUnitGroup = true
                    if (jb?.cluster?.[0]?.type == 1) showBuilding = true
                    return <></>
                  })}

                  <div
                    className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 '}
                    style={{position: 'relative', zIndex: '10'}}
                  >
                    <div className='d-flex  align-items-center'>
                      <h2 className='white-dark-theme-color fnt-700'>
                        {'Jobs'}
                      </h2>
                      <h5 className='user-head mb-2 ms-auto white-dark-theme-color fnt-500'>
                        <i>
                          Total: <b>{jobs?.length}</b>
                        </i>
                      </h5>
                    </div>

                    <div className='table-responsive mt-4'>
                      <table className='table align-start  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                        <thead>
                          <tr
                            className='text-start text-gray-400 fw-bold fs-7 gs-0'
                            ref={jobRefInd}
                          >
                            <th className='text-start min-w-75px'>Job ID</th>

                            {jobType != 'dev-clust' && (
                              <th
                                className={`text-start ${
                                  jobType == 'unit' ? 'min-w-100px' : 'min-w-175px'
                                }`}
                              >
                                {jobType == 'unit' ? 'Unit' : 'Common Area'}
                              </th>
                            )}
                            {jobType == 'unit' && (
                              <th className='text-start min-w-100px pt-2'>Unit Type</th>
                            )}

                            {showFloor && (jobType == 'unit' || jobType == 'common-area') && (
                              <th className='text-start min-w-100px'>Floor</th>
                            )}
                            {showBuilding && <th className='text-start min-w-125px'>Building</th>}

                            {showUnitGroup && <th className='text-start min-w-100px'>Group</th>}

                            {showCluster && <th className='text-start min-w-100px'>Cluster</th>}

                            <th className='text-start min-w-100px'>Development</th>
                            {jobType == 'unit' && (
                              <th className='text-start min-w-100px  pt-2'>Occupancy</th>
                            )}

                            <th className='text-center min-w-125px'>Urgency</th>
                            <th className='text-center min-w-200px'>Schedule</th>
                            <th className='text-center min-w-150px'>Job Details</th>
                            {!isViewOnly && <th className='text-start min-w-25px'></th>}
                          </tr>
                        </thead>

                        <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                          {jobs
                            ?.sort((a: any, b: any) => {
                              if (a.unitNo < b.unitNo) return -1
                              if (a.unitNo > b.unitNo) return 1
                              return 0
                            })
                            ?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  <td className='text-start '>
                                    <div className='green_color'>
                                      <b>{rs?.jobIdNo != null ? rs?.jobIdNo : '-'}</b>
                                    </div>
                                  </td>

                                  {jobType != 'dev-clust' && (
                                    <td className='text-start '>
                                      {jobType == 'unit' ? (
                                        <>{rs?.unitNo ? `${rs?.unitNo}` : `-`}</>
                                      ) : (
                                        <>
                                          {' '}
                                          {rs?.unitNo
                                            ? `${rs?.unitNo} - ${rs?.commonAreaName}`
                                            : `-`}
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {jobType == 'unit' && (
                                    <td className='text-start '>
                                      {rs?.unitType === 0
                                        ? 'Apartment'
                                        : rs?.unitType === 1
                                        ? 'Penthouse'
                                        : rs?.unitType === 2
                                        ? 'Common Area'
                                        : rs?.unitType === 3
                                        ? 'Townhouse'
                                        : rs?.unitType === 4
                                        ? 'Villa'
                                        : rs?.unitType === 5
                                        ? `Other - ${rs?.otherName}`
                                        : rs?.unitType === 6
                                        ? 'Office'
                                        : rs?.unitType === 7
                                        ? 'Restaurant'
                                        : rs?.unitType === 8
                                        ? 'Cafe'
                                        : rs?.unitType === 9
                                        ? 'Retail'
                                        : '-'}
                                    </td>
                                  )}

                                  {showFloor && (jobType == 'unit' || jobType == 'common-area') && (
                                    <td className='text-start '>
                                      {rs?.floor?.length > 0 ? `${rs?.floor?.[0]?.name}` : `-`}
                                    </td>
                                  )}

                                  {showBuilding && (
                                    <td className='text-start '>
                                      {rs?.building?.length > 0
                                        ? `${rs?.building?.[0]?.name}`
                                        : `-`}
                                    </td>
                                  )}

                                  {showUnitGroup && (
                                    <td className='text-start'>
                                      {rs?.unit_group?.length > 0
                                        ? `${rs?.unit_group?.[0]?.name}`
                                        : `-`}
                                    </td>
                                  )}

                                  {showCluster && (
                                    <td className='text-start '>
                                      {rs?.cluster?.length > 0 ? `${rs?.cluster?.[0]?.name}` : `-`}
                                    </td>
                                  )}

                                  <td className='text-start'>
                                    {rs?.community?.length > 0
                                      ? `${rs?.community?.[0]?.name}`
                                      : rs?.building.length > 0
                                      ? `${rs?.building?.[0]?.name}`
                                      : `-`}
                                  </td>

                                  {jobType == 'unit' && (
                                    <td className='text-start'>
                                      {Number(rs?.unitType) !== 2 && (
                                        <>
                                          {rs?.occupy == 1 ? (
                                            <span className='text-success '>
                                              <b>Occupied</b>
                                            </span>
                                          ) : rs?.occupy == 0 ? (
                                            <span className='text-danger '>
                                              <b>Vacant</b>
                                            </span>
                                          ) : (
                                            '-'
                                          )}
                                        </>
                                      )}
                                    </td>
                                  )}
                                  <td className='text-center min-w-100px ps-0'>
                                    <div className='d-flex justify-content-center'>
                                      <div
                                        className={
                                          rs?.jobUrgency == 0
                                            ? 'urgency-stats urgencyStandardStatus '
                                            : 'urgency-stats urgencyEmergencyStatus'
                                        }
                                      >
                                        {rs?.jobUrgency == 0 ? 'Standard' : 'Emergency'}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-center ps-0'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                      {rs?.schedule?.date != null &&
                                        rs?.schedule?.startTime != null &&
                                        rs?.schedule?.endTime != null && (
                                          <div className='me-5 text-decoration-underline'>
                                            {`${moment(rs?.schedule?.date).format('DD/MM/YYYY')} - 
                                            ${convertUTCToLocalTime(
                                              rs?.schedule?.startTime
                                            )}-${convertUTCToLocalTime(rs?.schedule?.endTime)}`}
                                          </div>
                                        )}
                                    </div>
                                  </td>
                                  <td className='text-center min-w-100px ps-0'>
                                    <div className='d-flex justify-content-center'>
                                      <button
                                        type='button'
                                        className={`btn btn-sm fw-bold d-flex justify-content-center ${
                                          rs?.comments?.length > 0 || rs?.attachments?.length > 0
                                            ? 'green-submit-btn'
                                            : 'green-hollow-btn'
                                        }`}
                                        onClick={() => {
                                          setJobsIndToAddEdit(index)
                                          setJobsToAddEdit(rs)
                                          setShowJobDetails(true)
                                        }}
                                      >
                                        Job Details
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {requestWithPO && (
                    <>
                      <div
                        className='card card-flush mb-10 mt-5  p-5 pe-0 mx-7'
                        // style={addedPOs?.length == 0 ? {minHeight: '400px'} : {}}
                      >
                        <div className='d-flex  align-items-center'>
                          <h2 className='' style={{fontWeight: '700'}}>
                            {'Purchase Orders'}
                          </h2>
                        </div>
                        <div
                          className='table-responsive mt-1 pe-5'
                          style={{maxHeight: '400px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-middle table-row-dashed  fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <th className='text-start min-w-100px ps-0'>Purchase Order</th>
                              <th className='text-start  ps-0 status-w-50'></th>
                              <th className='text-start  ps-0 min-w-50px'></th>
                              <th className='text-start min-w-100px ps-0'>Amount</th>
                              <th className='text-center min-w-150px ps-0'>Signed Copy</th>
                              <th className='text-start min-w-100px ps-0'>Vendor</th>
                              <th className='text-start min-w-100px ps-0'>Date Issued</th>
                            </thead>

                            {generatePOEnabled ? (
                               <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                                {addedPOs?.length > 0 ? (
                                  <>
                                    {addedPOs?.map((ad: any, index: any) => {
                                      return (
                                        <tr className=''>
                                          <td
                                            className='text-start min-w-100px ps-0 '
                                            onClick={() => {
                                              if (ad?.saveStatus == 0)
                                                navigate(
                                                  `/maintenance/create-request/purchase-order/${type}/2/${id}/${ad?._id}/${ad?.maintenancePurchaseOrderType}`
                                                )
                                              else
                                                navigate(
                                                  `/maintenance/view-purchase-order/${type}/2/${id}/${ad?._id}`
                                                )
                                            }}
                                          >
                                            <div className='green_color text-decoration-underline cursor-pointer'>
                                              <b>
                                                {ad?.purchaseOrderNo ? ad?.purchaseOrderNo : '-'}
                                              </b>
                                            </div>
                                          </td>
                                          <td className='text-start  ps-0'>
                                            <div className='d-flex align-items-center my-3'>
                                              {ad?.saveStatus == 0 ? (
                                                <p className=' mb-0 ps-1'>Draft</p>
                                              ) : (
                                                // <a href={`${Bucket}${ad?.pdfURL}`} target='_blank'>
                                                  <img
                                                    src={pdfIcon}
                                                    height={40}
                                                    width={40}
                                                    className='me-4 cursor-pointer'
                                                    id={`addedPos1${index}`}
                                                    onClick={() =>
                                                      handleImageClick(
                                                        `addedPos1${index}`,
                                                        `${Bucket}${ad?.pdfURL}`
                                                      )
                                                    }
                                                  />
                                                // </a>
                                              )}
                                            </div>
                                          </td>
                                          <td className='text-start  ps-0'>
                                            <div>
                                              {ad?.maintenancePurchaseOrderType == 1 && (
                                                <span>
                                                  <OverlayTrigger
                                                    placement='top'
                                                    delay={{show: 250, hide: 200}}
                                                    overlay={
                                                      <Tooltip
                                                        id='button-tooltip-2'
                                                        className='custom-tooltip'
                                                      >
                                                        {' '}
                                                        PO for the External Handling Team and will
                                                        be shared with them as part of the request
                                                        via the vendor portal.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      {' '}
                                                      <AiOutlineExclamationCircle
                                                        color='#384a62'
                                                        fontSize={15}
                                                        fontWeight={700}
                                                        className='cursor-pointer'
                                                        style={{
                                                          cursor: 'pointer',
                                                          marginBottom: '2px',
                                                        }}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </span>
                                              )}
                                            </div>
                                          </td>
                                          <td className='text-start min-w-100px ps-0'>
                                            {'AED'}{' '}
                                            {ad?.totalAmount
                                              ? ad?.totalAmount?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })
                                              : '-'}
                                          </td>
                                          <td className='text-center min-w-100px ps-0'>
                                            {ad?.saveStatus == 1 ? (
                                              <>
                                                {ad?.singedURL?.trim()?.length > 0 ? (
                                                  <div className='d-flex align-items-start justify-content-center my-3'>
                                                    {/* <a
                                                      href={`${Bucket}${ad?.singedURL}`}
                                                      target='_blank'
                                                    > */}
                                                      <img
                                                        src={pdfIcon}
                                                        height={40}
                                                        width={40}
                                                        className='mx-4 cursor-pointer'
                                                        id={`addedPos1Signed${index}`}
                                                        onClick={() =>
                                                            handleImageClick(
                                                              `addedPos1Signed${index}`,
                                                              `${Bucket}${ad?.singedURL}`
                                                            )
                                                          }
                                                      />
                                                    {/* </a> */}
                                                  </div>
                                                ) : (
                                                  <div className='d-flex align-items-center justify-content-center position-relative'>
                                                    <div className='requestBoxOpacity'></div>
                                                    <form
                                                      id='form-file-upload'
                                                      onDragEnter={handleDrag}
                                                      onSubmit={(e) => e.preventDefault()}
                                                    >
                                                      <label
                                                        id='label-file-upload'
                                                        htmlFor='input-file-upload'
                                                        className={
                                                          dragActive
                                                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                        }
                                                        style={{
                                                          fontSize: '16px',
                                                          color: 'white',
                                                        }}
                                                        ref={inputRef}
                                                      >
                                                        <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                          <img
                                                            src={uploadFileWhite}
                                                            height={20}
                                                            width={18}
                                                            className='me-4'
                                                          />{' '}
                                                          {'  '}Upload
                                                        </div>
                                                      </label>
                                                      <input
                                                        type='file'
                                                        // multiple={true}
                                                        onChange={(e: any) => {
                                                          handleUploadSignedCopies(e, index, ad)
                                                        }}
                                                        id='input-file-upload'
                                                        ref={inputRef}
                                                        name='input-file-upload'
                                                        disabled={isLoading || isViewOnly}
                                                        accept='application/pdf'
                                                        style={{
                                                          display: 'none',
                                                          visibility: 'hidden',
                                                        }}
                                                      />
                                                      {dragActive && (
                                                        <div
                                                          id='drag-file-element'
                                                          onDragEnter={handleDrag}
                                                          onDragLeave={handleDrag}
                                                          onDragOver={handleDrag}
                                                          onDrop={handleDrop}
                                                        ></div>
                                                      )}
                                                    </form>
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                          <td className='text-start min-w-100px ps-0'>
                                            {ad?.vendorAddress?.name
                                              ? ad?.vendorAddress?.name
                                              : '-'}
                                          </td>
                                          <td className='text-start min-w-100px ps-0'>
                                            {ad?.createdAt && ad?.saveStatus == 1
                                              ? `${moment
                                                  .utc(ad?.createdAt)
                                                  .local()
                                                  .format('DD.MM.YYYY')} `
                                              : '-'}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            ) : (
                              <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                                {uploadedPOs?.length > 0 ? (
                                  <>
                                    {uploadedPOs?.map((up: any, index: any) => {
                                      return (
                                        <tr className=''>
                                          <td
                                            className='text-start min-w-100px ps-0 pe-10'
                                            onClick={() => {}}
                                          >
                                            <div
                                              className={`green_color ${
                                                !up?.isEdit ? 'text-decoration-underline' : ''
                                              } cursor-pointer`}
                                            >
                                              <b>
                                                {up?.purchaseOrderNo ? up?.purchaseOrderNo : '-'}
                                              </b>
                                            </div>
                                          </td>
                                          <td className='text-start  ps-0 '>
                                            <div className='d-flex align-items-center'>
                                              {up?.pdfURL?.trim()?.length == 0 ? (
                                                <div className='d-flex align-items-center justify-content-center position-relative'>
                                                  <div className='requestBoxOpacity'></div>
                                                  <form
                                                    id='form-file-upload'
                                                    onDragEnter={handleDrag}
                                                    onSubmit={(e) => e.preventDefault()}
                                                  >
                                                    <label
                                                      id='label-file-upload'
                                                      htmlFor='input-file-upload'
                                                      className={
                                                        dragActive
                                                          ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                          : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                      }
                                                      style={{
                                                        fontSize: '16px',
                                                        color: 'white',
                                                      }}
                                                      ref={inputRef}
                                                    >
                                                      <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                        <img
                                                          src={uploadFileWhite}
                                                          height={20}
                                                          width={18}
                                                          className='me-4'
                                                        />{' '}
                                                        {'  '}Upload
                                                      </div>
                                                    </label>
                                                    <input
                                                      type='file'
                                                      // multiple={true}
                                                      onChange={(e: any) => {
                                                        handleUploadPO(e, index)
                                                      }}
                                                      id='input-file-upload'
                                                      ref={inputRef}
                                                      name='input-file-upload'
                                                      disabled={isLoading || isViewOnly}
                                                      accept='application/pdf'
                                                      style={{
                                                        display: 'none',
                                                        visibility: 'hidden',
                                                      }}
                                                    />
                                                    {dragActive && (
                                                      <div
                                                        id='drag-file-element'
                                                        onDragEnter={handleDrag}
                                                        onDragLeave={handleDrag}
                                                        onDragOver={handleDrag}
                                                        onDrop={handleDrop}
                                                      ></div>
                                                    )}
                                                  </form>
                                                </div>
                                              ) : (
                                                // <a href={`${Bucket}${up?.pdfURL}`} target='_blank'>
                                                  <img
                                                    src={pdfIcon}
                                                    height={40}
                                                    width={40}
                                                    className='me-4 cursor-pointer'
                                                    id={`uploadedPos1${index}`}
                                                    onClick={() =>
                                                      handleImageClick(
                                                        `uploadedPos1${index}`,
                                                        `${Bucket}${up?.pdfURL}`
                                                      )
                                                    }
                                                  />
                                                // </a>
                                              )}
                                            </div>
                                          </td>
                                          <td className='text-start  ps-0'>
                                            <div>
                                              {up?.maintenancePurchaseOrderType == 1 && (
                                                <span>
                                                  <OverlayTrigger
                                                    placement='top'
                                                    delay={{show: 250, hide: 200}}
                                                    overlay={
                                                      <Tooltip
                                                        id='button-tooltip-2'
                                                        className='custom-tooltip'
                                                      >
                                                        {' '}
                                                        PO for the External Handling Team and will
                                                        be shared with them as part of the request
                                                        via the vendor portal.
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span>
                                                      {' '}
                                                      <AiOutlineExclamationCircle
                                                        color='#384a62'
                                                        fontSize={15}
                                                        fontWeight={700}
                                                        className='cursor-pointer'
                                                        style={{
                                                          cursor: 'pointer',
                                                          marginBottom: '2px',
                                                        }}
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                </span>
                                              )}
                                            </div>
                                          </td>
                                          <td className='text-start min-w-100px ps-0 pe-10'>
                                            {'AED'}{' '}
                                            {up?.totalAmount
                                              ? up?.totalAmount?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })
                                              : '-'}
                                          </td>
                                          <td className='text-center min-w-100px ps-0'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                              {up?.singedURL?.trim()?.length == 0 ? (
                                                <div className='d-flex align-items-center justify-content-center position-relative'>
                                                  <div className='requestBoxOpacity'></div>
                                                  <form
                                                    id='form-file-upload-po-copy'
                                                    onDragEnter={handleDrag}
                                                    onSubmit={(e) => e.preventDefault()}
                                                  >
                                                    <label
                                                      id='form-file-upload-po-copy'
                                                      htmlFor='form-file-upload-po-copyyy'
                                                      className={
                                                        dragActive
                                                          ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                          : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                                      }
                                                      style={{
                                                        fontSize: '16px',
                                                        color: 'white',
                                                      }}
                                                      // ref={inputRef}
                                                    >
                                                      <div className='btn btn-sm fw-bold blue-btn ps-3 mx-auto my-3'>
                                                        <img
                                                          src={uploadFileWhite}
                                                          height={20}
                                                          width={18}
                                                          className='me-4'
                                                        />{' '}
                                                        {'  '}Upload
                                                      </div>
                                                    </label>
                                                    <input
                                                      type='file'
                                                      // multiple={true}
                                                      onChange={(e: any) => {
                                                        handleUploadSignedCopiesOfUploadedPO(
                                                          e,
                                                          index,
                                                          up
                                                        )
                                                      }}
                                                      id='form-file-upload-po-copyyy'
                                                      // ref={inputRef}
                                                      name='form-file-upload-po-copy'
                                                      disabled={isLoading || isViewOnly}
                                                      accept='application/pdf'
                                                      style={{
                                                        display: 'none',
                                                        visibility: 'hidden',
                                                      }}
                                                    />
                                                    {dragActive && (
                                                      <div
                                                        id='drag-file-element'
                                                        onDragEnter={handleDrag}
                                                        onDragLeave={handleDrag}
                                                        onDragOver={handleDrag}
                                                        onDrop={handleDrop}
                                                      ></div>
                                                    )}
                                                  </form>
                                                </div>
                                              ) : (
                                                <div className='d-flex justify-content-center'>
                                                  {/* <a
                                                    href={`${Bucket}${up?.singedURL}`}
                                                    target='_blank'
                                                  > */}
                                                    <img
                                                      src={pdfIcon}
                                                      height={40}
                                                      width={40}
                                                      className='cursor-pointer'
                                                      id={`uploadedPos1Singed${index}`}
                                                       onClick={() =>
                                                         handleImageClick(
                                                           `uploadedPos1Singed${index}`,
                                                           `${Bucket}${up?.singedURL}`
                                                       )
                                                       }
                                                    />
                                                  {/* </a> */}
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                          <td className='text-start min-w-100px ps-0 pe-10'>
                                            <>{up?.vendorName ? up?.vendorName : '-'}</>
                                          </td>
                                          <td className='text-start min-w-100px ps-0'>
                                            {!up?.isEdit
                                              ? `${moment
                                                  .utc(up?.createdAt)
                                                  .local()
                                                  .format('DD.MM.YYYY')} `
                                              : '-'}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>

      {/* Select Services Modal */}
      {showSelectService && (
        <SelectServiceModal
          show={showSelectService}
          handleClose={() => {
            setShowSelectService(false)
          }}
          updateService={(data: any) => {
            setSelectedService(data)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
          addedService={selectedService}
        />
      )}

      {/* Select Vendor Modal */}
      {showSelectVendor && (
        <SelectVendorModal
          show={showSelectVendor}
          handleClose={() => {
            setShowSelectVendor(false)
          }}
          updateVendor={(data: any) => {
            getVendorById(data?._id)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
          addedVendor={selectedVendor}
        />
      )}

      {/* Add Jobs Modal for Unit-Type == 'unit' */}
      {showSelectJob && (
        <AddJobsModal
          show={showSelectJob}
          handleClose={() => {
            setShowSelectJob(false)
          }}
          handleSubmitId={(data: any, jobstoDelete: any) => {
            setDeletedServiceJob(jobstoDelete)
            let values: any = []
            let vlInd: any = 0

            let dataExist : any = false
            data?.map((vl: any, ind: any) => {
              if (vl?.check) {
                dataExist = true
                values[vlInd] = vl

                values[vlInd].check = false
                vlInd++
              }
            })
            setJobs(values)

            let clk: any = []
            values?.map((jb: any, ind: any) => {
              clk[ind] = false
            })
            setClicked(clk)

            if (values?.length > 0 && dataExist) {
              let values = {...requestData}
              if (data?.[0]?.community?.length > 0) {
                values.community = {
                  value: data?.[0]?.community?.[0]?._id,
                  label: data?.[0]?.community?.[0]?.name,
                }
              } else {
                values.building = {
                  value: data?.[0]?.building?.[0]?._id,
                  label: data?.[0]?.building?.[0]?.name,
                }
              }

              setRequestData(values)

              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
            else{
              let values = {...requestData}
              values.community = null
              values.building = null
              setRequestData(values)

              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)

            }
          }}
          addedJobs={jobs}
          development={
            requestData?.community
              ? {
                  label: requestData?.community?.label,
                  value: requestData?.community?.value,
                  devType: 'comm',
                  clusterId: jobs?.[0]?.cluster?.[0]?._id,
                }
              : {
                  label: requestData?.building?.label,
                  value: requestData?.building?.value,
                  devType: 'build',
                }
          }
        />
      )}

      {/* Add Jobs Modal for Dev Clust UnitType */}
      {showSelectJobDevClust && (
        <DevClustAddJobsModal
          show={showSelectJobDevClust}
          handleClose={() => {
            setShowSelectJobDevClust(false)
          }}
          handleSubmitId={(data: any, jobstoDelete: any) => {
            setDeletedServiceJob(jobstoDelete)
            // console.log(data)
            let values: any = []
            let vlInd: any = 0
            let dataExist : any = false
            data?.map((vl: any, ind: any) => {
              if (vl?.check) {
                dataExist = true
                values[vlInd] = vl

                values[vlInd].check = false
                vlInd++
              }
            })
            setJobs(values)

            let clk: any = []
            values?.map((jb: any, ind: any) => {
              clk[ind] = false
            })
            setClicked(clk)

            if (values?.length > 0 && dataExist) {
              let values = {...requestData}
              if (data?.[0]?.community?.length > 0) {
                values.community = {
                  value: data?.[0]?.community?.[0]?._id,
                  label: data?.[0]?.community?.[0]?.name,
                }
              } else {
                values.building = {
                  value: data?.[0]?.building?.[0]?._id,
                  label: data?.[0]?.building?.[0]?.name,
                }
              }

              setRequestData(values)

              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
            else{
              let values = {...requestData}
              values.community = null
              values.building = null
              setRequestData(values)

              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)

            }
          }}
          addedJobs={jobs}
          development={
            requestData?.community
              ? {
                  label: requestData?.community?.label,
                  value: requestData?.community?.value,
                  devType: 'comm',
                  clusterId: jobs?.[0]?.cluster?.[0]?._id,
                }
              : {
                  label: requestData?.building?.label,
                  value: requestData?.building?.value,
                  devType: 'build',
                }
          }
        />
      )}

      {/* Schedule */}
      {showSchedule && (
        <Schedule
          show={showSchedule}
          handleClose={() => {
            setShowSchedule(false)
          }}
          modalData={scheduleToAddEdit}
          dataInd={scheduleIndToAddEdit}
          updateSchedule={(data: any, ind: any) => {
            let values = [...jobs]
            values[ind].schedule = data
            setJobs(values)

            setShowSchedule(false)

            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }}
        />
      )}

      {/* Job Details Modal */}
      {showJobDetails && (
        <JobDetailsModal
          show={showJobDetails}
          handleClose={() => {
            setShowJobDetails(false)
          }}
          modalData={jobsToAddEdit}
          dataInd={jobsIndToAddEdit}
          updateJobDetails={(comments: any, attachments: any, ind: any) => {
            let values = [...jobs]
            values[ind].comments = comments
            values[ind].attachments = attachments
            setJobs(values)

            setShowJobDetails(false)

            if (activeTab != 'review' && !isViewOnly) {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          tab={activeTab}
          isViewMode={isViewOnly}
        />
      )}

      <SingleFileLoader isVisible={showLoader} />

      {showGeneratePOModel && (
        <GeneratePOModal
          show={showGeneratePOModel}
          handleClose={() => {
            setShowGeneratePOModel(false)
          }}
          generatePOEnabled={generatePOEnabled}
          updateRequest={(val: any, draft: any) => addRequest(val, 0)}
          addUploads={(val: any) => {
            handleAddUploadedPOs(val)
          }}
        />
      )}
    </>
  )
}

export default CreateRequest

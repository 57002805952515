import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import emailTestIcon from '../../../../../../img/emailTestIcon.png'
import crmTicketTestIcon from '../../../../../../img/crmTicketTestIcon.png'
import atIcon from '../../../../../../img/atIcon.png'
import emailSendIcon from '../../../../../../img/emailLinedIcon.png'
import emailNotSendIcon from '../../../../../../img/remove.png'
import {ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import {useParams} from 'react-router-dom'
import {ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import CircularProgress from '@mui/material/CircularProgress'
import TestCard from './TestCard'
import SentEmailPopup from './SenEmailPopup'

interface ModelI {
  show: any
  handleClose: any
  emailId: any
}

const TestPopup = ({show, handleClose, emailId}: ModelI) => {
//   console.log(emailId)
  const [isEmailPopup, setIsEmailPopup] = useState<any>(false)

  const handleEmailTest = () => {
    setIsEmailPopup(true)
  }

  const handleCrmTicketRasiseTest = () => {
    console.log(' handleCrmTicketRasiseTest')
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
      >
        <div
          className='modal-body'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '450px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div className='row m-0'>
            <div className='col-md-12 px-0'>
              <label
                className='head-text fs-5 cursor-pointer d-block text-end'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          <Row style={{paddingBottom: '20px', position: 'relative'}}>
            {/* {!isVerified && <div className='layer2'></div>} */}
            {/* <div className='d-flex  align-items-center mt-7 pb-5 mb-7  mx-3' style={{borderBottom: '0.1rem solid #bec3cb'}}></div> */}
            <Row className='d-flex flex-column mt-4'>
              <div className='col-md-5 px-0 mx-auto text-center'>
                <h4 className='my-2 head-text ' style={{width: '100%', fontWeight: 'bold'}}>
                  Run Test
                </h4>
              </div>
              <div className='col-10 mx-auto text-center mt-7'>
                <Row>
                  <TestCard
                    cardIcon={emailTestIcon}
                    cardHeading='Email'
                    cardSubHeading='Run a test to receive a ticket…'
                    handleClick={handleEmailTest}
                  />
                  <TestCard
                    cardIcon={crmTicketTestIcon}
                    cardHeading='Raise Ticket'
                    cardSubHeading='Run a test to receive a ticket as if one of your tenants has raised a request by emailing you on your email address.'
                    handleClick={handleCrmTicketRasiseTest}
                  />
                </Row>
              </div>
            </Row>
          </Row>
        </div>
      </Modal>
      {isEmailPopup && (
        <SentEmailPopup
        emailId={emailId}
          show={isEmailPopup}
          handleClose={() => {
            setIsEmailPopup(false)
          }}
        />
      )}
    </>
  )
}

export default TestPopup

// import moment from 'moment'
import moment from 'moment/moment.js'
import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {AiFillDownSquare, AiFillUpSquare} from 'react-icons/ai'
import ReactPaginate from 'react-paginate'
import {useNavigate, useParams} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaPopUp from './ApproveMediaComments'
import SignaturePopup from './SignaturePopup'
import WrittenSignaturePopup from './WrittenSignaturePopup'
import noData from '../../../img/NoData1.svg'
import leftArrow from '../../../img/arrowleft.svg'
import './style.scss'
import crossbtn from '../../../img/cross-button.png'
import sendBack from '../../../img/share.png'
import approve from '../../../img/approve.png'
import view from '../../../img/view.png'
import sign from '../../../img/sign.png'
import submit from '../../../img/submit.png'
import greenSign from '../../../img/sign-green.png'
import Swal from 'sweetalert2'

const ApproveRequest = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const Vendors = new Array(2).fill(0)
  const [signatureModal, setSignatureModal] = React.useState<boolean>(false)
  const [writtenSignatureModal, setWrittenSignatureModal] = React.useState<boolean>(false)

  const [formData, setFormData] = React.useState<any>({})
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [hide, setHide] = React.useState<any>(false)
  const [requestData, setRequestData] = React.useState<any>()
  const [serviceData, setServicedata] = React.useState<any>()
  const [approvalRequest, setApprovalRequest] = React.useState<any>()
  const [listData, setListData] = React.useState<any>()
  const [propertyList, setPropertyList] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [jobId, setJobId] = React.useState<any>()
  const [submitButton, setSubmitButton] = React.useState<any>()
  const [approvalStatus, setApprovalStatus] = React.useState<any>()
  const [uploadedSign, setUploadedSign] = React.useState<any>('')
  const [checkType, setCheckType] = React.useState<any>('')
  const [idToSend, setIdToSend] = React.useState<any>('')

  const userData: any = localStorage.getItem('userData')
  const data = JSON.parse(userData)

  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const [mediaPop, setMediaPop] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()

  const [comment, setComment] = useState<any>('')

  const [isDisable, setIsDisable] = useState<any>(false)

  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${window.location.pathname?.split('/')[5]}`)
      .then((res) => {
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceJob = async () => {
    const body = {
      serviceRequestId: window.location.pathname?.split('/')[5],
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Approval WorkFlow Card
  const getApprovalServiceRequest = async () => {
    const body = {
      serviceRequestId: window.location.pathname?.split('/')[5],
    }
    await ApiPost(`corporate/approval_request/get`, body)
      .then((res) => {
        setApprovalRequest(res?.data?.data?.serviceApprovalRequest)
        for (let i = 0; i < res?.data?.data?.serviceApprovalRequest.length; i++) {
          if (res?.data?.data?.serviceApprovalRequest[i]?.corporate.length !== 0) {
            if (res?.data?.data?.serviceApprovalRequest[i].corporate[0]._id === data._id) {
              setIdToSend(res?.data?.data?.serviceApprovalRequest[i]._id)
              //  console.log(idToSend)
              if (
                res?.data?.data?.serviceApprovalRequest[i].eSignature === null &&
                res?.data?.data?.serviceApprovalRequest[i].writtenSignature === null
              ) {
                setIsDisable(true)
              } else {
                setIsDisable(false)
              }
            }
          }
        }
        setApprovalStatus(res?.data?.data?.serviceApprovalRequest[0].approvalStatus)
        setListData(res?.data?.data)
        setSubmitButton(res?.data?.data?.viewSubmitVendorButton)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Property details card
  const serviceRequestData = async () => {
    const body = {
      page: page,
      limit: 10,
      serviceRequestId: window.location.pathname?.split('/')[5],
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getServiceData()
    getServiceJob()
    getApprovalServiceRequest()
    serviceRequestData()
  }, [window.location.pathname?.split('/')[4]])

  // Send back Approval
  const sendBackApproval = () => {
    const body = {
      id: idToSend,
      approvalStatus: 4,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/request-management/service`)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // Reject Approval
  const rejectApproval = () => {
    const body = {
      id: idToSend,
      approvalStatus: 3,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/request-management/service`)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // Submit to vendor
  const submitToVendor = async () => {
    const body = {
      id: window.location.pathname?.split('/')[5],
      requestStatus: 6,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        SuccessToast(res.data.message)
      })
      .catch((e) => {
        console.log(e)
      })
    navigate('/request-management/service')
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const openModal = async (ids: any, unitN: any) => {
    setUnitNo(unitN)

    await ApiGet(`corporate/service_job/${ids}`)
      .then((res) => {
        setMediaPop(res.data.data[0])
        setJobId(ids)
        setMediaPopup(true)
      })
      .catch((err) => ErrorToast(err.message))
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  const submitApproval = () => {
    const body = {
      id: id,
      requestStatus: 5,
    }

    ApiPut('corporate/service_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const updateSignature = (newValue: string, checkType: number) => {
    setUploadedSign(newValue)
    setCheckType(checkType)
    setWrittenSignatureModal(false)
    setSignatureModal(false)
  }

  const ApproveRequest = () => {
    var eTemp, writtenTemp
    if (checkType === 1) {
      eTemp = uploadedSign
      writtenTemp = ''
    } else {
      eTemp = ''
      writtenTemp = uploadedSign
    }
    const body = {
      id: idToSend,
      approvalStatus: 2,
      comment: comment,
      eSignature: eTemp,
      writtenSignature: writtenTemp,
    }
    // console.log('\nlistData', listData)
    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        // getApprovalServiceRequest()
        // handleClose()
        navigate(`/request-management/service`)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // To close Both Modals at a time
  const closeBoth = () => {
    setWrittenSignatureModal(false)
    setSignatureModal(false)
  }

  return (
    <div className='app-main flex-column flex-row-fluid print-approve' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0 d-flex flex-column gap-5'>
        {/* <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3 back-btn'> */}
        <div className='row align-items-center'>
          <Col md={8}>
            <div className='d-flex align-items-center'>
              <span
                className='rounded-circle me-4'
                style={{cursor: 'pointer'}}
                onClick={() => navigate(-1)}
              >
                <img src={leftArrow} style={{stroke: 'red'}} />
              </span>
              <h2 className='page-heading green_color m-0'>Service Request</h2>
            </div>
          </Col>
          {requestData?.tenantRequestIds[0] && (
            <Col md={4} className='text-end'>
              <button
                onClick={() =>
                  navigate(
                    `/request-management/service/tenant-request-details/${requestData?.tenantRequestIds[0]}`
                  )
                }
                className='btn btn-sm fw-bold  p-auto btn-primary btn-green hover-none text-white'
              >
                <img
                  src={view}
                  style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
                />
                View Tenant Request
              </button>
            </Col>
          )}
        </div>
        {/* </div> */}

        {/* <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service/create-request-service`)}
              >
                {' '}
                Internal Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Approved Request</a>
            </li>
          </ul>
        </div> */}
        {/* <div className='card p-5 align-items-center flex-row justify-content-between '>
          <button
            className='btn btn-sm fw-bold btn-primary btn-green  hover-none'
            onClick={() => navigate(`/request-management/service/create-request-service`)}
          >
            Back
          </button>
          <div className='d-flex gap-5'>
            <h6 className='p-0 m-0 text-muted align-items-center'>
              Request Status :
              <div className='mx-1 value_black'>
                <div className='d-flex gap-1 align-items-center'>
                  <input
                    className='form-check-input'
                    type='radio'
                    checked={listData?.isUnderApproval}
                    disabled
                  />
                  Under Approval
                </div>
              </div>
            </h6>
          </div>
          <div>
            <h6 className='p-0 m-0 text-muted align-items-center'>
              Pending With :
              <div className='mx-1 value_black'>
                {listData?.pendingWith[0]?.corporate[0]?.firstName}
                {listData?.pendingWith[0]?.corporate[0]?.lastName}{' '}
                {moment(listData?.pendingWith[0]?.corporate[0]?.updatedAt).format(
                  'DD/MM/YYYY - HH:mm'
                )}
              </div>
            </h6>
          </div>
          <div className='d-flex align-items-center gap-5'>
            <button
              onClick={() => setSignatureModal(true)}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Approve
            </button>
            <button
              onClick={() => sendBackApproval()}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Send Back
            </button>
            <button
              onClick={() => rejectApproval()}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Reject
            </button>
            {submitButton === true && (
              <button
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                onClick={() => submitApproval()}
              >
                Submit To Vendor
              </button>
            )}
          </div>
        </div> */}

        <div className='card p-5 mb-5'>
          <h1 className='green_color mx-0'>Approval Workflow</h1>
          <div className='card p-5 align-items-center flex-row justify-content-between '>
            <div className='d-flex gap-5'>
              <h6 className='p-0 m-0 align-items-center' style={{color: '#a1a5b7'}}>
                <>Request Status: </>
                {/* <div className='mx-1 value_black'>
                <div className='d-flex align-items-center'>
                  <input
                    className='form-check-input'
                    type='radio'
                    checked={listData?.isUnderApproval}
                    disabled
                  /> */}
                {approvalStatus === 0 ? (
                  <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                    Pending
                  </span>
                ) : approvalStatus === 1 ? (
                  <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                    Requested
                  </span>
                ) : approvalStatus === 2 ? (
                  <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                    Approved
                  </span>
                ) : approvalStatus === 3 ? (
                  <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                    Rejected
                  </span>
                ) : (
                  <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                    Send Back
                  </span>
                )}

                {/* </div> */}
                {/* </div> */}
              </h6>
            </div>
            <div>
              <h6 className='p-0 m-0 align-items-center' style={{color: '#a1a5b7'}}>
                <>Pending With: </>
                {/* <div className='mx-1 value_black'> */}
                <span style={{color: 'black', fontWeight: '600'}} className='ms-1'>
                  {listData?.pendingWith[0]?.corporate[0]?.firstName}
                  {listData?.pendingWith[0]?.corporate[0]?.lastName}{' '}
                  {moment(listData?.pendingWith[0]?.corporate[0]?.updatedAt).format(
                    'DD.MM.YYYY - HH:mm'
                  )}
                </span>
                {/* </div> */}
              </h6>
            </div>
            <div className='d-flex align-items-center gap-5 approval-flow'>
              <button
                onClick={() => ApproveRequest()}
                className='btn btn-sm fw-bold  p-auto btn-primary btn-green hover-none text-white'
                disabled={isDisable === true && uploadedSign === '' ? true : false}
              >
                <img
                  src={approve}
                  style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
                />
                Approve
              </button>
              <button
                onClick={() => sendBackApproval()}
                className='btn btn-sm fw-bold p-auto hover-none text-white'
                style={{backgroundColor: '#ffc700'}}
              >
                <img
                  src={sendBack}
                  style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
                />
                Send Back
              </button>
              <button
                // onClick={() => rejectApproval()}
                className='btn btn-sm fw-bold p-auto hover-none text-white'
                style={{backgroundColor: 'rgb(213, 70, 69)'}}
                onClick={() =>
                  Swal.fire({
                    html: '<p class="text-center m-0" style="font-size: 16px"> You are about to reject the entire request, do you wish to proceed?</p>',
                    showConfirmButton: true,
                    confirmButtonColor: '#007a59',
                    confirmButtonText: 'Confirm',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                  }).then((res) => {
                    if (res.isConfirmed) {
                      rejectApproval()
                    }
                  })
                }
              >
                <img
                  src={crossbtn}
                  style={{height: '22px', width: '22px', marginRight: '7px', marginLeft: '-10px'}}
                />
                Reject
              </button>
              {/* {submitButton === true && ( */}
              {/* {requestData?.requestType === 1 && (
                <button
                  className='btn btn-sm fw-bold p-auto hover-none text-white'
                  onClick={() => submitToVendor()}
                  style={{backgroundColor: 'rgb(72, 71, 77)'}}
                >
                <img src={submit} style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}} />

                  Submit To Vendor
                </button>
              )} */}
            </div>
          </div>
          {hide !== true && (
            <div className='my-7 mb-2 table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-150px'>User</th>
                    <th className='min-w-150px'>Updated</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-150px'>Signature</th>
                    <th className='min-w-250px'>Comments</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {approvalRequest?.length ? (
                    approvalRequest.map((f: any, i: any) => {
                      return (
                        <tr className='text-center'>
                          <td>
                            {f?.type == 1
                              ? `${f?.corporate[0]?.firstName || ''} ${
                                  f?.corporate[0]?.lastName || ''
                                }`
                              : `${f?.vendor[0]?.companyName || ''}`}
                          </td>
                          {f?.corporate[0] ? (
                            <td>
                              {moment(f?.updatedAt.split('T')[0]).format('DD/MM/YYYY')} -{' '}
                              {moment(f?.updatedAt).format('HH:mm')}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {f?.corporate[0] ? (
                            <td>
                              {f?.approvalStatus === 2
                                ? 'Approved'
                                : f?.approvalStatus === 3
                                ? 'Rejected'
                                : f?.approvalStatus === 1
                                ? 'Requested'
                                : f?.approvalStatus === 0
                                ? 'Pending'
                                : 'Send Back'}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          {f?.corporate[0] ? (
                            <td className='approval-flow '>
                              {f?.corporate.length !== 0 && (
                                <>
                                  {f?.corporate?.[0]?._id === data._id ? (
                                    <>
                                      {f?.eSignature !== null ||
                                      f?.writtenSignature !== null ||
                                      uploadedSign !== '' ? (
                                        <span>Signed</span>
                                      ) : (
                                        ''
                                      )}

                                      {f?.eSignature !== null ||
                                      f?.writtenSignature !== null ||
                                      uploadedSign !== '' ? (
                                        // <button

                                        //   className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none ms-3'
                                        // >
                                        <img
                                          src={greenSign}
                                          style={{height: '20px', width: '20px', cursor: 'pointer'}}
                                          onClick={() => {
                                            setIdToSend(f?._id)
                                            setSignatureModal(true)
                                            setWrittenSignatureModal(true)
                                          }}
                                          className='ms-3'
                                        />
                                      ) : (
                                        //   Update Signature
                                        // </button>
                                        <button
                                          onClick={() => {
                                            setIdToSend(f?._id)
                                            setSignatureModal(true)
                                            setWrittenSignatureModal(true)
                                          }}
                                          className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none ms-3'
                                        >
                                          <img
                                            src={sign}
                                            style={{
                                              height: '20px',
                                              width: '20px',
                                              marginRight: '7px',
                                              marginLeft: '-10px',
                                            }}
                                          />
                                          Add Signature
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {f?.eSignature !== null ||
                                      f?.writtenSignature !== null ||
                                      uploadedSign !== '' ? (
                                        <span>Signed</span>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {/* {f?.eSignature && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                href={`${Bucket}${f?.eSignature}`}
                                target='_blank'
                              >
                                e signature
                              </a>
                            )}
                            {f?.writtenSignature && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                href={`${Bucket}${f?.writtenSignature}`}
                                target='_blank'
                              >
                                written signature
                              </a>
                            )} */}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                          <td className='show-line-sign'>________________</td>
                          {f?.corporate[0] ? (
                            <td>
                              {/* {f.comment} */}
                              {f?.corporate.length !== 0 && (
                                <>
                                  {f?.corporate?.[0]?._id === data._id ? (
                                    <textarea
                                      value={comment}
                                      className='form-control form-control-solid py-2 approval-flow'
                                      name='comment'
                                      onChange={(e: any) => {
                                        setComment(e.target.value)
                                      }}
                                    ></textarea>
                                  ) : (
                                    <> {f.comment}</>
                                  )}
                                </>
                              )}
                              {/* {f.comment !== null ? (
                              f?.comment
                            ) : (
                              <input
                                className='form-check-input'
                                type='radio'
                                checked={listData?.isUnderApproval}
                                disabled
                              />
                            )} */}
                            </td>
                          ) : (
                            <td>-</td>
                          )}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={16} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div
            className='d-flex justify-content-center arrow_approval_request toggle-btn'
            style={{cursor: 'pointer'}}
          >
            {hide === false && (
              <AiFillUpSquare color='#007a59' fontSize={40} onClick={() => setHide(true)} />
            )}
            {hide === true && (
              <AiFillDownSquare color='#007a59' fontSize={40} onClick={() => setHide(false)} />
            )}
          </div>
        </div>

        {/* <div className='card card-flush p-5 arrow_approval_request_bottom'> */}
        <div className='grid-out container-xxl px-0 approval-flow'>
          {/* Request Type */}
          <div
            className='card tops p-5 mt-0'
            style={{justifyContent: 'space-between', flexDirection: 'row'}}
          >
            <div className='payment-receipt-item d-flex align-items-center justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Request Type
              </h6>
              <h6 style={{color: 'black'}}>
                {requestData?.requestType == 1
                  ? 'External'
                  : requestData?.requestType == 2
                  ? 'Internal'
                  : 'Tenant'}
              </h6>
            </div>
          </div>
          {/* Service Details */}
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Service Details</h1>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Request ID:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.id}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Requestor:
              </h6>
              <h6 style={{color: 'black'}}>
                {' '}
                {data?.firstName} {data?.lastName}
              </h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Main Service:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.main_service[0]?.name}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Sub Service:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.sub_service[0]?.name}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Request Urgency:
              </h6>
              <h6 style={{color: 'black'}}>
                {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
              </h6>
            </div>
          </div>

          {/* External Service Provider */}
          {requestData?.requestType === 1 && (
            <div className='side'>
              <div className='card grid px-5 py-3'>
                <h1 className='top green_color'>Service Provider Details</h1>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                    Service Provider:
                  </h6>
                  <h6 style={{color: 'black', paddingLeft: '6px'}}>
                    {requestData?.vendor[0]?.firstName} {requestData?.vendor[0]?.lastName}
                  </h6>
                </div>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                    Request Details (For Selected Vendor):
                  </h6>
                  <h6 style={{color: 'black'}}>{requestData?.vendorComment}</h6>
                </div>
              </div>
              {/* Internal Team Card */}
              <div className='card grid px-5 py-3 mt-4'>
                <h1 className='top green_color'>Internal Team</h1>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                    Assigned To:
                  </h6>
                  <h6 style={{color: 'black'}}>
                    {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                  </h6>
                </div>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                    Comments (For Internal Reference):{' '}
                  </h6>
                  <h6 style={{color: 'black'}}>{requestData?.comment}</h6>
                </div>
              </div>
            </div>
          )}

          {requestData?.requestType === 2 && (
            <div className='card grid px-5 py-3 side '>
              <h1 className='top green_color'>Internal Team</h1>
              <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                  Assigned To:
                </h6>
                <h6 style={{color: 'black'}}>
                  {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                </h6>
              </div>
              <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                <h6 className='m-0 me-2 min-w-250px' style={{color: '#a1a5b7'}}>
                  Comments (For Internal Reference):{' '}
                </h6>
                <h6 style={{color: 'black'}}>{requestData?.comment}</h6>
              </div>
            </div>
          )}
        </div>

        {/* print view */}

        {/* Service Details */}
        <div className='grid-out container-xxl px-0 print-view'>
          {/* Request Type */}
          <div
            className='card tops p-5 mt-0'
            style={{justifyContent: 'space-between', flexDirection: 'row'}}
          >
            <div className='payment-receipt-item d-flex align-items-center justify-content-start'>
              <h6 className='m-0 me-2 min-w-200px' style={{color: '#a1a5b7'}}>
                Request Type
              </h6>
              <h6 style={{color: 'black'}}>
                {requestData?.requestType == 1
                  ? 'External'
                  : requestData?.requestType == 2
                  ? 'Internal'
                  : 'Tenant'}
              </h6>
            </div>
          </div>
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Service Details</h1>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                Request ID:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.id}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                Requestor:
              </h6>
              <h6 style={{color: 'black'}}>
                {' '}
                {data?.firstName} {data?.lastName}
              </h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                Main Service:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.main_service[0]?.name}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
              <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                Sub Service:
              </h6>
              <h6 style={{color: 'black'}}>{requestData?.sub_service[0]?.name}</h6>
            </div>

            <div className='payment-receipt-item d-flex mb-5'>
              <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                Request Urgency:
              </h6>
              <h6 style={{color: 'black'}}>
                {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
              </h6>
            </div>
          </div>

          {/* External Service Provider */}
          {requestData?.requestType === 1 && (
            <div className='side'>
              <div className='card grid px-5 py-3'>
                <h1 className='top green_color'>Service Provider Details</h1>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                    Service Provider:
                  </h6>
                  <h6 style={{color: 'black', paddingLeft: '6px'}}>
                    {requestData?.vendor[0]?.firstName} {requestData?.vendor[0]?.lastName}
                  </h6>
                </div>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6
                    className='m-0 me-2 min-w-150px'
                    style={{color: '#a1a5b7', maxWidth: '150px'}}
                  >
                    Request Details (For Selected Vendor):
                  </h6>
                  <h6 style={{color: 'black'}}>{requestData?.vendorComment}</h6>
                </div>
              </div>
              {/* Internal Team Card */}
              <div className='card grid px-5 py-3 mt-4'>
                <h1 className='top green_color'>Internal Team</h1>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                    Assigned To:
                  </h6>
                  <h6 style={{color: 'black'}}>
                    {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                  </h6>
                </div>
                <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                  <h6
                    className='m-0 me-2 min-w-150px'
                    style={{color: '#a1a5b7', maxWidth: '150px'}}
                  >
                    Comments (For Internal Reference):{' '}
                  </h6>
                  <h6 style={{color: 'black'}}>{requestData?.comment}</h6>
                </div>
              </div>
            </div>
          )}

          {requestData?.requestType === 2 && (
            <div className='card grid px-5 py-3 side '>
              <h1 className='top green_color'>Internal Team</h1>
              <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7'}}>
                  Assigned To:
                </h6>
                <h6 style={{color: 'black'}}>
                  {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                </h6>
              </div>
              <div className='payment-receipt-item d-flex mb-5 justify-content-start'>
                <h6 className='m-0 me-2 min-w-150px' style={{color: '#a1a5b7', maxWidth: '150px'}}>
                  Comments (For Internal Reference):{' '}
                </h6>
                <h6 style={{color: 'black'}}>{requestData?.comment}</h6>
              </div>
            </div>
          )}
        </div>

        {/* Properties */}
        <div className='card p-5 mt-3'>
          <h1 className='mx-0 green_color'>Properties</h1>
          <Row className=''>
            <div className='table-responsive mb-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Job Id</th>
                    <th className='min-w-100px'>Development</th>
                    <th className='min-w-70px'>Floor/Cluster</th>
                    <th className='min-w-100px'>Unit</th>
                    <th className='min-w-100px'>Unit Type </th>
                    <th className='min-w-100px'>Bedrooms </th>
                    <th className='min-w-100px'>Job Schedule </th>
                    <th className='media-comments'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {propertyList?.map((f: any, i: any) => {
                    return (
                      <tr className='text-center'>
                        <td>{f?.id}</td>
                        <td>{f?.communityId ? 'Community' : 'Building'}</td>
                        <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                        <td>{f?.unit[0]?.unitNo}</td>
                        <td>{f?.unit[0]?.unitType}</td>
                        <td>{f?.unit[0]?.bedrooms}</td>
                        <td>
                          {' '}
                          {f?.schedule?.[0]?.date
                            ? moment(f?.schedule?.[0]?.date).format('DD.MM.YYYY')
                            : null}{' '}
                          {f?.schedule?.[0]?.startTime
                            ? timeConvert(f?.schedule?.[0]?.startTime) + '-'
                            : null}
                          {f?.schedule?.[0]?.endTime
                            ? timeConvert(f?.schedule?.[0]?.endTime)
                            : null}{' '}
                          {f?.schedule?.[0]?.date && (
                            <>
                              {/* Same Day */}
                              {new Date(f?.schedule?.[0]?.date).getFullYear() ===
                                today.getFullYear() &&
                              new Date(f?.schedule?.[0]?.date).getMonth() === today.getMonth() &&
                              new Date(f?.schedule?.[0]?.date).getDate() === today.getDate() ? (
                                <>
                                  {currentHours >
                                  parseInt(
                                    timeConvert(f?.schedule?.[0]?.startTime).split(':')[0]
                                  ) ? (
                                    <p style={{color: 'rgb(213, 70, 69)'}} className='mb-0'>
                                      {' '}
                                      Schedule has Expired
                                    </p>
                                  ) : (
                                    <>
                                      {parseInt(
                                        timeConvert(f?.schedule?.[0]?.startTime).split(':')[0]
                                      ) -
                                        currentHours <=
                                      6 ? (
                                        <p style={{color: 'rgb(213, 70, 69)'}} className='mb-0'>
                                          {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                          Schedule is Approaching
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : // next Day
                              new Date(f?.schedule?.[0]?.date) > today ? (
                                <>
                                  {new Date(f?.schedule?.[0]?.date).getDate() ===
                                  today.getDate() + 1 ? (
                                    <>
                                      {24 -
                                        currentHours +
                                        parseInt(
                                          timeConvert(f?.schedule?.[0]?.startTime).split(':')[0]
                                        ) <=
                                        6 && (
                                        <p style={{color: 'rgb(213, 70, 69)'}}>
                                          Schedule is Approaching
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <></>
                                </>
                              ) : (
                                <p style={{color: 'rgb(213, 70, 69)'}} className='mb-0'>
                                  Schedule has expired
                                </p>
                              )}
                            </>
                          )}
                        </td>
                        <td className='media-comments'>
                          <button
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                            onClick={() => openModal(f?._id, f?.unit[0]?.unitNo)}
                          >
                            Media & Comments
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className='row mb-5 approval-flow'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous disabled'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </Row>
        </div>

        {/* <Row className='py-2'>
            <Col lg={6}>
              <div className='d-flex align-items-center justify-content-between'>
                <span className=''>
                  <b>Request ID : </b>
                  {requestData?.id}
                </span>
                <span className=''>
                  <b>Request Source : </b>
                  {requestData?.requestType == 1
                    ? 'External'
                    : requestData?.requestType == 2
                    ? 'Internal'
                    : 'Tenant'}
                </span>
              </div>
            </Col>

            <Col lg={6}></Col>
            <Col lg={6}>
              <h2 className='py-5 green_color'>Request Summary </h2>
              <div className='min-w-200px mx-0'>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                  <h6 className='text-muted m-0 me-2'>Service</h6>
                  <b>
                    {requestData?.main_service[0]?.name} - {requestData?.sub_service[0]?.name}
                  </b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                  <h6 className='text-muted m-0 me-2'>Assigned To</h6>
                  <b>
                    {requestData?.requestType == 1
                      ? 'External Team'
                      : requestData?.requestType == 2
                      ? 'Internal Team'
                      : 'Tenant'}{' '}
                    - {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                  </b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                  <h6 className='text-muted m-0 me-2'>Total Buildings/Communities</h6>
                  <b>{serviceData?.total_building}</b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                  <h6 className='text-muted m-0 me-2'>Total Units</h6>
                  <b>{serviceData?.total_units}</b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                  <h6 className='text-muted m-0 me-2'>Total Jobs</h6>
                  <b>static</b>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className='min-w-200px right-card-approve'>
                <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-betwee'>
                  <h6 className='text-muted m-0 me-2'>Request Urgency:</h6>
                  <b>{requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}</b>
                </div>

                <div className='payment-receipt-item mb-5'>
                  <h6 className='text-muted m-0 me-2'>Preferred Schedules For Service</h6>
                  {requestData?.schedule?.map((el: any, i: any) => (
                    <div className='d-flex gap-5 align-items-center'>
                      <b className='p-3'>{i + 1} . </b>
                      <b className='p-3'>
                        {moment(el?.preferScheduleDate).format('DD/MM/YYYY')} -{' '}
                        {timeConvert(el?.preferScheduleTime)}
                      </b>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row> */}
        {/* </div> */}
      </div>
      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        handleClose={() => {
          setMediaPopup(false)
        }}
        propertyList={propertyList}
        mediaPop={mediaPop}
        id={jobId}
      />
      {/* E-signature Popup */}
      <SignaturePopup
        show={signatureModal}
        handleClose={() => {
          setSignatureModal(false)
        }}
        setFormData={setFormData}
        formData={approvalRequest?.map((f: any) => f)}
        getApprovalServiceRequest={getApprovalServiceRequest}
        listData={listData}
        updateSignature={updateSignature}
        closeBoth={closeBoth}
      />
      {/* Written Signature Popup */}
      <WrittenSignaturePopup
        show={writtenSignatureModal}
        handleClose={() => {
          setWrittenSignatureModal(false)
        }}
        setFormData={setFormData}
        formData={approvalRequest?.map((f: any) => f)}
        getApprovalServiceRequest={getApprovalServiceRequest}
        listData={listData}
        updateSignature={updateSignature}
        closeBoth={closeBoth}
      />
    </div>
  )
}

export default ApproveRequest

import React, { useState } from 'react';
import ApexCharts from 'react-apexcharts';

interface vacancyRateProps {
  chartData?: any;
}

interface DataPoint {
  time: string;
  overall: {
    value: number;
  };
  paid: {
    value: number;
  };
  unpaid: {
    value: number;
  };
}

const RevenueChartMonthly: React.FC<vacancyRateProps> = ({ chartData }) => {
  const seriesData = {
    overall: [] as number[],
    paid: [] as number[],
    unpaid: [] as number[],
  };

  const [isSkeleton, setIsSkeleton]= useState<any>(true)

  const [visibleSeries, setVisibleSeries] = useState({
    overall: true,
    paid: true,
    unpaid: true,
  });

  const seriesColors = {
    overall: '#39394c',
    paid: '#00b9a9',
    unpaid: '#F38513',
  };

  const series = [
    {
      name: 'Overall',
      data: seriesData?.overall,
      color: seriesColors.overall, // Use the color from seriesColors
      strokeDashArray: 4,
    },
    {
      name: 'Paid',
      data: seriesData?.paid,
      color: seriesColors.paid, // Use the color from seriesColors
      strokeDashArray: 0,
    },
    {
      name: 'Unpaid',
      data: seriesData?.unpaid,
      color: seriesColors.unpaid, // Use the color from seriesColors
      strokeDashArray: 0,
    },
  ].filter(s => visibleSeries?.[s.name?.toLowerCase() as keyof typeof visibleSeries]);

  const handleCheckboxChange = (seriesName: keyof typeof visibleSeries) => {
    setIsSkeleton(true)

    setVisibleSeries(prevState => ({
      ...prevState,
      [seriesName]: !prevState[seriesName],
    }));

    setTimeout(() => {
      setIsSkeleton(false)
    }, 300)
  };

  const categories: string[] = chartData?.map((dataPoint: any) => dataPoint?.time);

  chartData?.forEach((dataPoint: any) => {
    seriesData.overall.push(dataPoint.overall.value);
    seriesData.paid.push(dataPoint.paid.value);
    seriesData.unpaid.push(dataPoint.unpaid.value);
  });

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true,
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    grid: {
      show: false,
    },
    title: {
      text: 'Total Revenue (AED)',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '800',
      },
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        format: 'MMM-yy',
      },
      tickAmount: categories?.length - 1,
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return `${value?.toLocaleString('en-US')}`;
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = chartData?.[dataPointIndex];
        const date = new Date(categories[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        });
        return (
          `<div class="apexcharts-tooltip-custom">` +
          `<div style="text-align:center;" class="text-decoration-underline">${date}</div>` +
          `<div style="display: flex; justify-content: space-between;">` +
          `<div>` +
          (visibleSeries.overall
            ? `<div><span style="color: ${seriesColors.overall}">&#9679;</span> AED ${seriesData?.overall?.[dataPointIndex]?.toLocaleString('en-US')}</div>`
            : ``) +
          (visibleSeries.paid
            ? `<div><span style="color: ${seriesColors.paid}">&#9679;</span> AED ${seriesData?.paid?.[dataPointIndex]?.toLocaleString('en-US')}</div>`
            : ``) +
          (visibleSeries.unpaid
            ? `<div><span style="color: ${seriesColors.unpaid}">&#9679;</span> AED ${seriesData?.unpaid?.[dataPointIndex]?.toLocaleString('en-US')}</div>`
            : ``) +
          `</div>` +
          `</div>` +
          `</div>`
        );
      },
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'butt',
    },
    series: series,
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 0,
      offsetX: 0,
    },
  };

  return (
    <div className="chart-container">
      <div className="legend-container d-flex w-100">
        <div className="d-flex align-items ms-auto">
          {Object?.keys(visibleSeries)?.map(seriesName => (
            <label key={seriesName} style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox" className="form-check-input me-2"
                checked={visibleSeries?.[seriesName as keyof typeof visibleSeries]}
                onChange={() => handleCheckboxChange(seriesName as keyof typeof visibleSeries)}
              />
              <span style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: seriesColors?.[seriesName as keyof typeof seriesColors],
                marginRight: '5px'
              }}></span>
              {seriesName?.charAt(0)?.toUpperCase() + seriesName?.slice(1)}
            </label>
          ))}
        </div>
      </div>
      <ApexCharts options={options} series={options?.series ? options?.series : []} type='line' height={350} />
    </div>
  );
};

export default RevenueChartMonthly;

import React, {useState, useEffect, useRef} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../../img/trash.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import redCross from '../../../../img/remove.png'
import syncIcon from '../../../../img/sync.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import carrotArrowDown from '../../../../img/caret-arrow-down.png'
import carrotArrowUp from '../../../../img/caret-arrow-up.png'
import unitClusterGreenIcon from '../../../../img/unitClusterGreen.png'
import unitClusterWhiteIcon from '../../../../img/unitClusterWhite.png'
import mixedClusterGreenIcon from '../../../../img/mixedClusterGreen.png'
import mixedClusterWhiteIcon from '../../../../img/mixedClusterWhite.png'
import buildingClusterGreenIcon from '../../../../img/buildingClusterGreen.png'
import buildingClusterWhiteIcon from '../../../../img/buildingClusterWhite.png'
import buildingWhiteIcon from '../../../../img/small-building.png'
import buildingGreenIcon from '../../../../img/allBuildingGreen.png'
import communitiesWhiteIcon from '../../../../img/allCommunities.png'
import communitiesGreenIcon from '../../../../img/allCommunitiesGreen.png'
import CircularProgress from '@mui/material/CircularProgress'

import swal from 'sweetalert2'
import {mixed} from 'yup'
import AddUnitCluster from './AddUnitCluster'
import AddBuildingCluster from './AddBuildingCluster'
import EditUnitCluster from './EditUnitCluster'
import EditBuildingCluster from './EditBuildingCluster'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {mergeDateIntoReferenceDate} from '@mui/x-date-pickers/internals/hooks/useField/useField.utils'

interface SquareHandleProps {
  value: number
}

const EditPropertyDevelopment = () => {
  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>('allUnits')

  const [searchCommonArea, setSearchCommonArea] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const location: any = useLocation()

  // const fromBuilding: any = location?.state?.fromBuilding
  const fromGlobal: any = location?.state?.fromGlobal

  const goToMiantenance = (v: any) => {
    if (subTabListItem === 'floors') {
      navigate(`/properties/building-details/floors/1`, {
        state: {key: 'floors', name: 'Floor 1', developmentName: 'Al Rowdah Tower'},
      })
    }
  }

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const handleDatesChange = ({startDate, endDate}: {startDate: moment.Moment | null; endDate: moment.Moment | null}) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  const unitOptions = [
    {value: 'apartment', label: 'Apartment'},
    {value: 'pent_house', label: 'Penthouse'},
    {value: 'town_house', label: 'Townhouse'},
    {value: 'villa', label: 'Villa'},
    {value: 'common_area', label: 'Common Area'},
    {value: 'other', label: 'Other'},
  ]

  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ]
  const bathroomOptions = [
    {
      value: 1,
      label: '1 Bath',
    },
    {value: 2, label: '2 Bath'},
    {value: 3, label: '3 Bath'},
    {value: 4, label: '4 Bath'},
    {value: 5, label: '5 Bath'},
    {value: 6, label: '6 Bath'},
    {value: 7, label: '7 Bath'},
    {value: 8, label: '8 Bath'},
    {value: 9, label: '9 Bath'},
    {value: 10, label: '10 Bath'},
  ]
  const floorsOptions: any = []
  const developmentOptions: any = []
  const clusterOptions: any = []
  const groupOptions: any = []
  const buildingOptions: any = []
  const propertyTypeOptions: any = []

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    // padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
    // backgroundColor: '#fff',
  }
  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const [selectBuildingCircle, setSelectBuildingCircle] = useState(location?.state?.isBuilding ? true : false)
  const [selectCommunityCircle, setSelectCommunityCircle] = useState(location?.state?.isCommunity ? true : false)
  const [unitClusterCircle, setUnitClusterCircle] = useState(false)
  const [buildingClusterCircle, setBuildingClusterCircle] = useState(false)
  const [mixedClusterCircle, setMixedClusterCircle] = useState(false)
  const [isCTAHidden, setIsCTAHidden] = useState(true)

  const [propertyUnitNo, setPropertyUnitNo] = useState('')
  const [propertyUnitType, setPropertyUnitType] = useState('')
  const [propertyCommonAreaNo, setPropertyCommonAreaNo] = useState('')
  const [propertySize, setPropertySize] = useState('')
  const [propertySizeType, setPropertySizeType] = useState(0)
  const [sizeOptions, setSizeOptions] = useState([
    {value: 0, label: 'Sq ft'},
    {value: 1, label: 'Sq mt'},
  ])
  const [propertyBedroom, setPropertyBedroom] = useState<any>('')
  const [propertyBathrooms, setPropertyBathroom] = useState<any>('')
  const [propertyType, setPropertyType] = useState<any>('')
  const [propertyFloors, setPropertyFloors] = useState<any>('')
  const [propertyDevelopment, setPropertyDevelopment] = useState<any>('')
  const [propertyCluster, setPropertyCluster] = useState<any>('')
  const [propertyGroup, setPropertyGroup] = useState<any>('')
  const [propertyBuilding, setPropertyBuilding] = useState<any>('')

  const [communityName, setCommunityName] = useState<any>('')
  const [buildingName, setBuildingName] = useState<any>('')
  const [selectCity, setSelectCity] = useState<any>('')
  const [streetName, setStreetName] = useState<any>('')
  const [selectArea, setSelectArea] = useState<any>('')

  const [selectBuildingCity, setSelectBuildingCity] = useState<any>('')
  const [streetBuildingName, setStreetBuildingName] = useState<any>('')
  const [selectBuildingArea, setSelectBuildingArea] = useState<any>('')
  const [unitInputs, setUnitInput] = useState<any>([{name: ''}])
  const [buildingCluster, setBuildingCluster] = useState<any>([
    {
      name: '',
      addBuildingCluster: [
        {
          name: '',
          addFloor: [
            {
              floorType: '',
              name: '',
              floorStartRange: 1,
              floorEndRange: 1,
              index: 1,
            },
          ],
        },
      ],
    },
  ])
  const [mixedCluster, setMixedCluster] = useState<any>([
    {
      mixedClusterName: '',
      unitGroup: [{unitInputValue: ''}],
      buildings: [
        {
          buildingName: '',
          floors: [
            {
              floorType: '',
              floorPrefix: '',
              floorStartRange: '',
              floorEndRange: '',
            },
          ],
        },
      ],
    },
  ])

  const [buildingDevelopmentFloor, setBuildingDevelopmentFloor] = useState<any>([])
  const [buildingDevelopmentUpdateFloor, setBuildingDevelopmentUpdateFloor] = useState<any>([])
  const [mergeBuildingDevelopmenFloor, setMergeBuildingDevelopmenFloor] = useState<any>([])
  const [buildingDevelopmentDeleteFloor, setBuildingDevelopmentDeleteFloor] = useState<any>([])
  const [showBuildingDevCta, setShowBuildingDevCta] = useState(false)

  useEffect(() => {
    // console.log(JSON.stringify(mergeBuildingDevelopmenFloor))
  }, [mergeBuildingDevelopmenFloor, streetBuildingName, selectBuildingArea, selectBuildingCity, buildingName])

  const handleAddMixedCluster = (index: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster?.push({
      name: '',
      addUnitGroup: [{name: ''}],
      addBuilding: [
        {
          name: '',
          addFloor: [
            {
              floorType: '',
              prefix: '',
              name: '',
              floorStartRange: 1,
              floorEndRange: 1,
              index: 1,
            },
          ],
        },
      ],
    })
    setAllCluster(updateMixedCluster)
  }

  const handleAddBuildingForMixedCluster = (index: any, clusterIndex: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateMixedCluster)
  }
  const handleAddBuildingForUpdateMixedCluster = (index: any, clusterIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleAddUnitGroup = (index: any, clusterIndex: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster[clusterIndex]?.addUnitGroup?.push({name: ''})
    setAllCluster(updateMixedCluster)
    setIsCTAHidden(false)
  }
  const handleAddUnitGroupForUpdateCluster = (index: any, clusterIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[clusterIndex]?.addUnitGroup?.push({name: ''})
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }

  const handleAddBuildingCluster = (index: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster?.push({
      name: '',
      addBuilding: [
        {
          name: '',
          addFloor: [
            {
              floorType: '',
              name: '',
              floorStartRange: 1,
              floorEndRange: 1,
              index: 1,
            },
          ],
        },
      ],
    })

    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleAddBuildingForBuildingCluster = (index: any, builidngClusterIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[builidngClusterIndex]?.addBuilding?.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleAddBuildingForBuildingClusterUpdate = (index: any, builidngClusterIndex: any) => {
    // console.log('test2')
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateBuildingCluster[builidngClusterIndex]?.addBuilding?.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }

  const addFloorHandler = (index: any, builidngClusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[builidngClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: 1,
    })

    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const addFloorHandlerForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any, key: any) => {
    if (key === 1) {
      let updateCluster = [...allCluster]
      updateCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor.push({
        floorType: '',
        name: '',
        floorStartRange: 1,
        floorEndRange: 1,
        index: 1,
      })
      setAllCluster(updateCluster)
    }
    if (key === 2) {
      let updateCluster = [...allCluster]
      updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.addFloor.push({
        floorType: '',
        name: '',
        floorStartRange: 1,
        floorEndRange: 1,
        index: 1,
      })
      setAllCluster(updateCluster)
    }
    setIsCTAHidden(false)
  }
  const addFloorHandlerForMergeArray = (index: any, clusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      sortIndex: updateCluster[index]?.updateBuildingCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray?.length + 1,
      index: 1,
      noSubListRowUI: true,
    })
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const addFloorHandlerForUpdateMixedCluster = (index: any, clusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.addFloor.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: 1,
    })

    setAllCluster(updateCluster)
  }
  const handleAddUnitInput = (index: any) => {
    // console.log(index)
    let values = [...allCluster]
    values[index]?.addUnitCluster?.push({
      name: '',
    })
    setAllCluster(values)
    setIsCTAHidden(false)
  }

  // const addFloorHandlerForBuildingDevelopment = () => {
  //   const updatedDevelopmentBuilding = [...buildingDevelopmentFloor]
  //   let valueItems: any = []
  //   valueItems.push({
  //     floorType: '',
  //     name: '',
  //     floorStartRange: 1,
  //     floorEndRange: 1,
  //     index: buildingDevelopmentFloor?.length + 1,
  //   })
  //   setBuildingDevelopmentFloor([...updatedDevelopmentBuilding, ...valueItems])
  // }

  const addFloorHandlerForBuildingDevelopment = () => {
    const updatedDevelopmentBuilding = [...mergeBuildingDevelopmenFloor]
    let valueItems: any = []
    updatedDevelopmentBuilding.push({
      noSubListRowUI: true,
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      sortIndex: mergeBuildingDevelopmenFloor?.length,
      index: mergeBuildingDevelopmenFloor?.length + 1,
    })
    setMergeBuildingDevelopmenFloor(updatedDevelopmentBuilding)

    let sortedArr: any = updatedDevelopmentBuilding?.sort((a: any, b: any) => {
      if (a.sortIndex < b.sortIndex) return -1
      if (a.sortIndex > b.sortIndex) return 1
      return 0
    })

    generateFloorForEditBuilding(sortedArr)
    setIsCTAHidden(false)
  }

  // useEffect(() => {
  //   console.log(mergeBuildingDevelopmenFloor)

  //   console.log(JSON.stringify(mergeBuildingDevelopmenFloor))
  // },[mergeBuildingDevelopmenFloor])

  const handleRemoveDevelopmenetBuilding = (index: any, key: any) => {
    //  key = 1 => buildingDevelopmentFloor
    //  key = 2 => buildingDevelopmentUpdateFloor
    let values = [...mergeBuildingDevelopmenFloor]
    if (key === 1) {
      values.splice(index, 1)
      setMergeBuildingDevelopmenFloor(values)
      // setBuildingDevelopmentFloor(values)
    }
    if (key === 2) {
      let buildingDevelopmentDeleteFloorTemp = [...buildingDevelopmentDeleteFloor]
      const floorlistIds = values[index]?.floorList?.map((item: any) => ({
        _id: item._id,
      }))
      setBuildingDevelopmentDeleteFloor([...buildingDevelopmentDeleteFloorTemp, ...floorlistIds])

      values.splice(index, 1)
      setMergeBuildingDevelopmenFloor(values)
      // setBuildingDevelopmentUpdateFloor(valuesForUpdate)
    }

    let sortedArr: any = values?.sort((a: any, b: any) => {
      if (a.sortIndex < b.sortIndex) return -1
      if (a.sortIndex > b.sortIndex) return 1
      return 0
    })

    generateFloorForEditBuilding(sortedArr)

    const updatedValues = checkDuplicatesForMergeArray(values)
    setMergeBuildingDevelopmenFloor(updatedValues)

    // const {updatedFloors1, updatedFloors2} = checkForDuplicates(values, valuesForUpdate)
    // setBuildingDevelopmentFloor(updatedFloors1)
    // setBuildingDevelopmentUpdateFloor(updatedFloors2)
  }
  const handleRemoveFloorListItem = (index: any, itemIndex: any) => {
    let values: any = [...mergeBuildingDevelopmenFloor]
    const floorlistId = values[index]?.floorList[itemIndex]?._id
    buildingDevelopmentDeleteFloor.push({
      _id: floorlistId,
    })
    values[index]?.floorList?.splice(itemIndex, 1)
    // console.log('floor',values)
    setMergeBuildingDevelopmenFloor(values)

    let sortedArr: any = values?.sort((a: any, b: any) => {
      if (a.sortIndex < b.sortIndex) return -1
      if (a.sortIndex > b.sortIndex) return 1
      return 0
    })
    generateFloorForEditBuilding(sortedArr)

    const updatedValues = checkDuplicatesForMergeArray(values)
    setMergeBuildingDevelopmenFloor(updatedValues)
    setIsCTAHidden(false)
  }
  const handleRemoveFloorListItemForMergeArray = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, itemIndex: any) => {
    const updateCluster = [...allCluster]
    const values = [...updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]

    const floorlistId = values[floorIndex]?.floorList[itemIndex]?._id
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].deleteFloor.push({_id: floorlistId})
    updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
    values[floorIndex]?.floorList?.splice(itemIndex, 1)

    setAllCluster(updateCluster)

    // let sortedArr:any = values?.sort((a: any, b: any) => {
    //   if (a.sortIndex < b.sortIndex) return -1
    //   if (a.sortIndex > b.sortIndex) return 1
    //   return 0
    // })
    // generateFloorForEditBuilding(sortedArr)

    const updatedValues = checkDuplicatesForMergeArray(values)
    updateCluster[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveUnitInput = (index: any, unitIndex: any) => {
    let values = [...allCluster]
    values[index]?.addUnitCluster.splice(unitIndex, 1)
    setAllCluster(values)
    setIsCTAHidden(false)
  }
  const handleRemoveBackendInput = (index: any, unitIndex: any) => {
    let values = [...allCluster]
    const unitClusterID = values[index].updateUnitCluster[unitIndex]?._id
    values[index].deleteUnitCluster.push({_id: unitClusterID})
    values[index]?.updateUnitCluster.splice(unitIndex, 1)
    setAllCluster(values)
    setIsCTAHidden(false)
  }
  const handleRemoveBuildingClusterFloor = (index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)
    setAllCluster(updatedCluster)
  }

  const handleRemoveFloorForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updateCluster = [...allCluster]
    updateCluster[index].addMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)
    // setAllCluster(updateCluster)

    // const duplicates = checkForDuplicatesForMixedCluster(allCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor)
    // setDuplicateIndicesForMixedCluster(duplicates);

    //   setAllCluster(updateCluster)

    const updatedFloor = checkForDuplicatesForMixedCluster(updateCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor)
    updateCluster[index].addMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor

    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveUnitGroupForMixedCluster = (index: any, clusterIndex: any, groupIndex: any) => {
    const updatedMixedCluster = [...allCluster]
    updatedMixedCluster[index]?.addMixedCluster[clusterIndex]?.addUnitGroup?.splice(groupIndex, 1)
    setAllCluster(updatedMixedCluster)
  }
  const handleRemoveUnitGroupForUpdateMixedCluster = (index: any, clusterIndex: any, groupIndex: any, key: any) => {
    if (key === 1) {
      const updateCluster = [...allCluster]
      updateCluster[index]?.updateMixedCluster[clusterIndex]?.addUnitGroup?.splice(groupIndex, 1)
      setAllCluster(updateCluster)
    }
    if (key === 2) {
      const updateCluster = [...allCluster]
      const unitGroupID = updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateUnitGroup[groupIndex]?._id
      updateCluster[index]?.updateMixedCluster[clusterIndex]?.deleteUnitGroup.push({
        _id: unitGroupID,
      })
      updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateUnitGroup?.splice(groupIndex, 1)
      setAllCluster(updateCluster)
      setIsCTAHidden(false)
    }
  }

  const handleRemoveBuildingClusterBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    const updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveBuilingForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any) => {
    const updatedBuildingCluster = [...allCluster]
    updatedBuildingCluster[index].addMixedCluster[clusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setAllCluster(updatedBuildingCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveBuilingForUpdateMixedCluster = (index: any, clusterIndex: any, buildingIndex: any) => {
    const updateCluster = [...allCluster]
    const buildingGroupID = updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?._id
    updateCluster[index]?.updateMixedCluster[clusterIndex]?.deleteBuilding.push({
      _id: buildingGroupID,
    })
    updateCluster[index].updateMixedCluster[clusterIndex]?.updateBuilding?.splice(buildingIndex, 1)
    setAllCluster(updateCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveBuildingCluster = (index: any, clusterIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index]?.addBuildingCluster?.splice(clusterIndex, 1)
    setBuildingCluster(updatedCluster)
    setIsCTAHidden(false)
  }
  const handleRemoveMixedCluster = (index: any, clusterIndex: any, key: any) => {
    if (key === 1) {
      const updatedMixedCluster = [...allCluster]
      updatedMixedCluster[index]?.addMixedCluster?.splice(clusterIndex, 1)
      setAllCluster(updatedMixedCluster)
    }
    if (key === 2) {
      const updatedCluster = [...allCluster]
      const clusterID = updatedCluster[index].updateMixedCluster[clusterIndex]?._id
      updatedCluster[index]?.deleteMixedCluster?.push({_id: clusterID})
      updatedCluster[index]?.updateMixedCluster?.splice(clusterIndex, 1)
      setAllCluster(updatedCluster)
      // console.log('update', allCluster)
    }
    setIsCTAHidden(false)
  }

  const handleRemoveClusterForUpdateBuilding = (index: any, mixedClusterIndex: any) => {
    const updatedCluster = [...allCluster]
    const mixedClusterID = updatedCluster[index].updateMixedCluster[mixedClusterIndex]?._id
    updatedCluster[index].deleteMixedCluster.push({_id: mixedClusterID})
    updatedCluster[index].updateMixedCluster.splice(mixedClusterIndex, 1)
    setAllCluster(updatedCluster)
  }

  const handleRemoveFloorListItemForUpdateFloor = (index: any, mixedClusterIndex: any, buildingIndex: any, floorIndex: any, listItemIndex: any) => {
    const updatedCluster = [...allCluster]
    const floorID =
      updatedCluster[index].updateMixedCluster[mixedClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList[listItemIndex]?._id
    updatedCluster[index].updateMixedCluster[mixedClusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor.push({_id: floorID})
    updatedCluster[index].updateMixedCluster[mixedClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList.splice(listItemIndex, 1)
    setAllCluster(updatedCluster)
  }

  const handleRemoveFloorForUpdateBuilding = (index: any, mixedClusterIndex: any, buildingIndex: any, floorIndex: any, key: any) => {
    // key =  1 ==> delete from addFloor
    // key =  2 ==> delete from updateFloor
    const updatedCluster = [...allCluster]
    if (key === 1) {
      const updatedCluster = [...allCluster]
      updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].addFloor.splice(floorIndex, 1)
      // setAllCluster(updatedCluster)
    }
    if (key === 2) {
      const updatedCluster = [...allCluster]
      const floorDeleteListTemp = updatedCluster[index].updateMixedCluster[mixedClusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor
      const floorlistIds = updatedCluster[index].updateMixedCluster[mixedClusterIndex]?.updateBuilding[buildingIndex]?.floors[
        floorIndex
      ].floorList?.map((item: any) => ({
        _id: item._id,
      }))

      updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].deleteFloor = [
        ...floorDeleteListTemp,
        ...floorlistIds,
      ]
      updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].floors.splice(floorIndex, 1)
      // setAllCluster(updatedCluster)
    }

    const {updatedFloors1, updatedFloors2} = checkForDuplicates(
      updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].addFloor,
      allCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].floors
    )
    updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
    updatedCluster[index].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2
    setAllCluster(updatedCluster)
  }

  const calculateTotalFloorsForUpdateBuilding = (index: any, clusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.floors?.forEach((floorItem: any) => {
      if (floorItem?.noSubListRowUI) {
        if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
          totalFloors += 1
        } else {
          totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
        }
      } else {
        totalFloors += floorItem?.floorList?.length
      }
    })
    return totalFloors
  }

  // const swapItems = (
  //   prevInd: any,
  //   nextInd: any,
  //   index: any,
  //   buildingClusterIndex: any,
  //   buildingIndex: any,
  //   floorIndex: any,
  //   itemIndex: any,
  //   floorType: 'addFloor' | 'floorList'
  // ) => {
  //   const values = [...allCluster]

  //   // Navigate to the specific array
  //   const valuesSections =
  //     floorType === 'floorList'
  //       ? values[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList
  //       : values[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

  //   if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
  //     // console.error("Invalid indices");
  //     return
  //   }

  //   // // Add animation classes
  //   valuesSections[prevInd].animationClass = 'move-down'
  //   valuesSections[nextInd].animationClass = 'move-up'

  //   setAllCluster(values)

  //   setTimeout(() => {
  //     // Clone the updated structure again to avoid direct mutation
  //     const updatedValues = [...values]
  //     const updatedValuesSections =
  //       floorType === 'floorList'
  //         ? updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors[floorIndex]?.floorList
  //         : updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

  //     // Swap the items
  //     if (floorType === 'floorList') {
  //       let temp = updatedValuesSections[prevInd]
  //       // updatedValuesSections[prevInd] = updatedValuesSections[nextInd];
  //       // updatedValuesSections[nextInd] = temp;

  //       let tempProperties = {
  //         _id: temp._id,
  //         prefix: temp.prefix,
  //         name: temp.name,
  //         floorType: temp.floorType,
  //         index: temp.index,
  //       }

  //       updatedValuesSections[prevInd]._id = updatedValuesSections[nextInd]._id
  //       updatedValuesSections[prevInd].prefix = updatedValuesSections[nextInd].prefix
  //       updatedValuesSections[prevInd].name = updatedValuesSections[nextInd].name
  //       updatedValuesSections[prevInd].floorType = updatedValuesSections[nextInd].floorType
  //       //   updatedValuesSections[prevInd].index = updatedValuesSections[nextInd].index;

  //       updatedValuesSections[nextInd]._id = tempProperties._id
  //       updatedValuesSections[nextInd].prefix = tempProperties.prefix
  //       updatedValuesSections[nextInd].name = tempProperties.name
  //       updatedValuesSections[nextInd].floorType = tempProperties.floorType
  //     } else {
  //       let temp = updatedValuesSections[prevInd]
  //       updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
  //       updatedValuesSections[nextInd] = temp
  //     }

  //     // Reset animation classes
  //     // updatedValuesSections[prevInd].animationClass = '';
  //     // updatedValuesSections[nextInd].animationClass = '';

  //     delete updatedValuesSections[prevInd].animationClass
  //     delete updatedValuesSections[nextInd].animationClass

  //     // Assign the updated array back to the structure
  //     if (floorType === 'floorList') {
  //       updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors[floorIndex].floorList =
  //         updatedValuesSections
  //       setAllCluster(updatedValues)
  //     } else {
  //       updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedValuesSections

  //       const {updatedFloors1, updatedFloors2} = checkForDuplicates(
  //         allCluster[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor,
  //         allCluster[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors
  //       )
  //       updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
  //       updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2
  //       setAllCluster(updatedValues)
  //     }

  //     // Update state again to reflect the swap and reset animations
  //     // setAllCluster(updatedValues);
  //   }, 200)
  // }

  const swapItems = (prevInd: any, nextInd: any, index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, itemIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    values[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray[floorIndex].floorList = valuesSections
    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections =
        updatedValues[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList

      // Swap the items
      let temp = updatedValuesSections[prevInd]

      let tempProperties = {
        _id: temp._id,
        prefix: temp.prefix,
        name: temp.name,
        floorType: temp.floorType,
        index: temp.index,
        isDuplicate: temp.isDuplicate,
      }

      updatedValuesSections[prevInd]._id = updatedValuesSections[nextInd]._id
      updatedValuesSections[prevInd].prefix = updatedValuesSections[nextInd].prefix
      updatedValuesSections[prevInd].name = updatedValuesSections[nextInd].name
      updatedValuesSections[prevInd].floorType = updatedValuesSections[nextInd].floorType

      updatedValuesSections[nextInd]._id = tempProperties._id
      updatedValuesSections[nextInd].prefix = tempProperties.prefix
      updatedValuesSections[nextInd].name = tempProperties.name
      updatedValuesSections[nextInd].floorType = tempProperties.floorType

      // Reset animation classes
      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      updatedValues[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray[floorIndex].floorList =
        updatedValuesSections

      // Update state again to reflect the swap and reset animations
      setAllCluster(updatedValues)
      setIsCTAHidden(false)
    }, 200)
  }

  const swapRow = (
    prevInd: any,
    nextInd: any,
    index: any,
    buildingClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'addFloor' | 'floors'
  ) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.[type]
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // console.log(valuesSections[prevInd])
    // console.log(valuesSections[nextInd])
    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.[type]

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      if (type === 'addFloor') {
        updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedValuesSections
      } else {
        updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedValuesSections
      }

      // Update state again to reflect the swap and reset animations
      setAllCluster(updatedValues)
    }, 200)
  }

  const swapRowForUpdatedFloor = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // console.log(valuesSections[prevInd])
    // console.log(valuesSections[nextInd])
    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.floors
      // Swap the items
      // let temp = updatedValuesSections[prevInd];
      // updatedValuesSections[prevInd] = updatedValuesSections[nextInd];
      // updatedValuesSections[nextInd] = temp;

      swapAndReindex(updatedValuesSections[prevInd], updatedValuesSections[nextInd])

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      // setAllCluster(updatedValues);
      // console.log(updatedValues)

      const {updatedFloors1, updatedFloors2} = checkForDuplicates(
        allCluster[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor,
        allCluster[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors
      )
      updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].addFloor = updatedFloors1
      updatedValues[index].updateMixedCluster[buildingClusterIndex].updateBuilding[buildingIndex].floors = updatedFloors2
      setAllCluster(updatedValues)
    }, 200)
  }

  const swapRowForAdd = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      // updatedValuesSections[prevInd].animationClass = '';
      // updatedValuesSections[nextInd].animationClass = '';

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure

      updatedValues[index].addMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      // setAllCluster(updatedValues);

      // const duplicates = checkForDuplicatesForMixedCluster(allCluster[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor)
      // setDuplicateIndicesForMixedCluster(duplicates);

      //   setAllCluster(updatedValues)

      const updatedFloor = checkForDuplicatesForMixedCluster(
        updatedValues[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      updatedValues[index].addMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor

      setAllCluster(updatedValues)
      setIsCTAHidden(false)
    }, 100)
  }

  function swapAndReindex(previous: any, next: any) {
    // Step 1: Swap the floorList arrays
    let tempFloorList = previous.floorList
    previous.floorList = next.floorList
    next.floorList = tempFloorList
    // previous.name = next.name
    // previous.isDuplicate = next.isDuplicate

    // Step 2: Swap the other properties
    let tempProperties = {
      isShowFloors: previous.isShowFloors,
      prefix: previous.prefix,
      floorType: previous.floorType,
      name: previous.name,
      // floorStartRange:previous.floorStartRange,
      // floorEndRange:previous.floorEndRange,
      isDuplicate: previous.isDuplicate,
    }

    previous.isShowFloors = next.isShowFloors
    previous.prefix = next.prefix
    previous.floorType = next.floorType
    previous.name = next.name
    previous.isDuplicate = next.isDuplicate
    // previous.floorStartRange = next.floorStartRange
    // previous.floorEndRange = next.floorEndRange

    next.isShowFloors = tempProperties.isShowFloors
    next.prefix = tempProperties.prefix
    next.floorType = tempProperties.floorType
    next.name = tempProperties.name
    next.isDuplicate = tempProperties.isDuplicate
    // next.floorStartRange = next.floorStartRange
    // next.floorEndRange = next.floorStartRange

    // Step 3: Determine the new starting indices
    // const previousStartIndex = 3;
    const previousStartIndex = Math.min(...next.floorList.map((floor: any) => floor.index))
    const nextStartIndex = previous.floorList.length + previousStartIndex

    // Reindex the previous object floorList starting from previousStartIndex
    previous.floorList.forEach((floor: any, idx: any) => {
      floor.index = previousStartIndex + idx
    })

    // Reindex the next object floorList starting from nextStartIndex
    next.floorList.forEach((floor: any, idx: any) => {
      floor.index = nextStartIndex + idx
    })

    return {previous, next}
  }
  const swapRowForUpdateBuilding = (prevInd: any, nextInd: any, index: any) => {
    const values = [...buildingDevelopmentUpdateFloor]

    if (!values || prevInd >= values.length || nextInd >= values.length) {
      // console.error("Invalid indices");
      return
    }

    // console.log('prev',JSON.stringify(values[prevInd]))
    // console.log('next',JSON.stringify(values[nextInd]))

    // // Add animation classes
    values[prevInd].animationClass = 'move-down'
    values[nextInd].animationClass = 'move-up'

    setBuildingDevelopmentUpdateFloor(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]

      // Swap the items
      // let temp = updatedValues[prevInd];
      // updatedValues[prevInd] = updatedValues[nextInd];
      // updatedValues[nextInd] = temp;

      // Reset animation classes
      // updatedValues[prevInd].animationClass = '';
      // updatedValues[nextInd].animationClass = '';

      // const {previous,next} =
      swapAndReindex(updatedValues[prevInd], updatedValues[nextInd])
      // console.log(previous)
      // console.log(next)

      delete updatedValues[prevInd].animationClass
      delete updatedValues[nextInd].animationClass

      setBuildingDevelopmentUpdateFloor(updatedValues)

      const {updatedFloors1, updatedFloors2} = checkForDuplicates(buildingDevelopmentFloor, buildingDevelopmentUpdateFloor)
      setBuildingDevelopmentFloor(updatedFloors1)
      setBuildingDevelopmentUpdateFloor(updatedFloors2)
      // console.log(buildingDevelopmentUpdateFloor)
    }, 200)
  }

  const [addBuilding, setAddBuilding] = useState<any>([])
  const [updateBuilding, setUpdateBuilding] = useState<any>([])

  const generateFloorForEditBuilding = (data: any) => {
    let newInd = 1
    const updArray = []
    const newArray = []
    for (let i = 0; i < data.length; i++) {
      // console.log(updatedValues[i]?.noSubListRowUI)
      if (data[i]?.subListRowUI) {
        // console.log('update',true)
        // newArray.push(updatedValues[newInd])
        for (let j = 0; j < data[i]?.floorList?.length; j++) {
          updArray.push({
            _id: data[i]?.floorList[j]?._id,
            floorType: data[i]?.floorList[j]?.floorType,
            name: data[i]?.floorList[j]?.name,
            index: newInd,
            prefix: data[i]?.floorList[j]?.prefix,
          })
          newInd += 1
        }

        // newArray[newArray?.length-1].index = i
      } else if (data[i]?.noSubListRowUI) {
        // console.log('add',true)
        // newArray.push(updatedValues[newInd])
        for (let j = data[i]?.floorStartRange; j <= data[i]?.floorEndRange; j++) {
          newArray.push({
            floorType: data[i].floorType.value,
            name: data[i].floorType.value === 0 ? data[i].name : data[i].name + ` ${j}`,
            index: newInd,
            prefix: data[i].name,
          })
          newInd += 1
        }

        // newArray[newArray?.length-1].index = i
      }

      // console.log(newArray)
      // console.log(updArray)
      setAddBuilding(newArray)
      setUpdateBuilding(updArray)
    }
  }

  const generateFloorForEditBuildingCluster = (data: any) => {
    let newInd = 1
    const updArray = []
    const newArray = []
    for (let i = 0; i < data.length; i++) {
      // console.log(updatedValues[i]?.noSubListRowUI)
      if (data[i]?.subListRowUI) {
        // console.log('update',true)
        // newArray.push(updatedValues[newInd])
        for (let j = 0; j < data[i]?.floorList?.length; j++) {
          updArray.push({
            _id: data[i]?.floorList[j]?._id,
            floorType: data[i]?.floorList[j]?.floorType,
            name: data[i]?.floorList[j]?.name,
            index: newInd,
            prefix: data[i]?.floorList[j]?.prefix,
          })
          newInd += 1
        }

        // newArray[newArray?.length-1].index = i
      } else if (data[i]?.noSubListRowUI) {
        // console.log('add',true)
        // newArray.push(updatedValues[newInd])
        for (let j = data[i]?.floorStartRange; j <= data[i]?.floorEndRange; j++) {
          newArray.push({
            floorType: data[i].floorType.value,
            name: data[i].floorType.value === 0 ? data[i].name : data[i].name + ` ${j}`,
            index: newInd,
            prefix: data[i].name,
          })
          newInd += 1
        }

        // newArray[newArray?.length-1].index = i
      }
    }
    return {addFloor: newArray, updateFloor: updArray}
  }

  const swapRowForMergedBuilding = (prevInd: any, nextInd: any) => {
    const values = [...mergeBuildingDevelopmenFloor]

    // console.log(values)

    if (!values || prevInd >= values.length || nextInd >= values.length) {
      // console.error("Invalid indices");
      return
    }

    // Add animation classes
    values[prevInd].animationClass = 'move-down'
    values[nextInd].animationClass = 'move-up'

    setMergeBuildingDevelopmenFloor(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]

      let temp = updatedValues[prevInd].sortIndex
      updatedValues[prevInd].sortIndex = updatedValues[nextInd].sortIndex
      updatedValues[nextInd].sortIndex = temp

      // Reset animation classes
      delete updatedValues[prevInd].animationClass
      delete updatedValues[nextInd].animationClass

      setMergeBuildingDevelopmenFloor(updatedValues)
      // console.log(updatedValues)
      // console.log(updatedValues)
      // console.log(mergeBuildingDevelopmenFloor)

      let sortedArr: any = updatedValues?.sort((a: any, b: any) => {
        if (a.sortIndex < b.sortIndex) return -1
        if (a.sortIndex > b.sortIndex) return 1
        return 0
      })

      generateFloorForEditBuilding(sortedArr)
      setIsCTAHidden(false)
      // console.log(addBuilding)
      // console.log(updateBuilding)

      // Additional logic if required to check for duplicates, etc.
      // const { updatedFloors1, updatedFloors2 } = checkForDuplicates(buildingDevelopmentFloor, mergeBuildingDevelopmentFloor);
      // setBuildingDevelopmentFloor(updatedFloors1);
      // setMergeBuildingDevelopmentFloor(updatedFloors2);
    }, 200)
  }

  const swapRowForAddBuilding = (prevInd: any, nextInd: any, index: any) => {
    const values = [...buildingDevelopmentFloor]

    if (!values || prevInd >= values.length || nextInd >= values.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    values[prevInd].animationClass = 'move-down'
    values[nextInd].animationClass = 'move-up'

    setBuildingDevelopmentFloor(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]

      // Swap the items
      let temp = updatedValues[prevInd]
      updatedValues[prevInd] = updatedValues[nextInd]
      updatedValues[nextInd] = temp

      // Reset animation classes
      // updatedValues[prevInd].animationClass = '';
      // updatedValues[nextInd].animationClass = '';

      delete updatedValues[prevInd].animationClass
      delete updatedValues[nextInd].animationClass

      setBuildingDevelopmentFloor(updatedValues)
    }, 100)
  }

  const swapRowUpdateList = (prevInd: any, nextInd: any, index: any) => {
    const values = [...mergeBuildingDevelopmenFloor]

    // Navigate to the specific array
    const valuesSections = values[index]?.floorList
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setMergeBuildingDevelopmenFloor(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.floorList

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      // updatedValuesSections[prevInd] = updatedValuesSections[nextInd];
      // updatedValuesSections[nextInd] = temp;

      let tempProperties = {
        _id: temp._id,
        prefix: temp.prefix,
        name: temp.name,
        floorType: temp.floorType,
        index: temp.index,
      }

      updatedValuesSections[prevInd]._id = updatedValuesSections[nextInd]._id
      updatedValuesSections[prevInd].prefix = updatedValuesSections[nextInd].prefix
      updatedValuesSections[prevInd].name = updatedValuesSections[nextInd].name
      updatedValuesSections[prevInd].floorType = updatedValuesSections[nextInd].floorType
      //   updatedValuesSections[prevInd].index = updatedValuesSections[nextInd].index;

      updatedValuesSections[nextInd]._id = tempProperties._id
      updatedValuesSections[nextInd].prefix = tempProperties.prefix
      updatedValuesSections[nextInd].name = tempProperties.name
      updatedValuesSections[nextInd].floorType = tempProperties.floorType
      //   updatedValuesSections[nextInd].index = tempProperties.index;

      // Reset animation classes
      // updatedValuesSections[prevInd].animationClass = '';
      // updatedValuesSections[nextInd].animationClass = '';

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure

      updatedValues[index].floorList = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      setMergeBuildingDevelopmenFloor(updatedValues)
      let sortedArr: any = updatedValues?.sort((a: any, b: any) => {
        if (a.sortIndex < b.sortIndex) return -1
        if (a.sortIndex > b.sortIndex) return 1
        return 0
      })
      generateFloorForEditBuilding(sortedArr)
      // console.log(buildingDevelopmentUpdateFloor)
    }, 200)
  }

  const resetPropertyType = () => {
    setUnitClusterCircle(false)
    setBuildingClusterCircle(false)
    setMixedClusterCircle(false)
  }

  useEffect(() => {
    resetPropertyType()
    // resetBuildingDevelopmentFloor()
    // resetCommunityDevelopment()
  }, [selectBuildingCircle, selectCommunityCircle])

  const [allCluster, setAllCluster] = useState<any>([
    {
      unitCircle: false,
      buildingCircle: false,
      mixedCircle: false,
      unitBlur: false,
      buildingBlur: false,
      mixedBlur: false,
      clusterType: '',
      updateUnitCluster: [
        // { name: '', _id: '' }
      ],
      deleteUnitCluster: [],
      addUnitCluster: [],
      deleteBuildingCluster: [],
      addBuildingCluster: [],
      updateBuildingCluster: [
        // {
        //     _id: '',
        //     name: '',
        //     addBuilding: [{
        //         _id: '',
        //         name: '',
        //         addFloor: [{
        //             floorType: '',
        //             name: '',
        //             floorStartRange: 1,
        //             floorEndRange: 1,
        //             index: 1,
        //         }]
        //     }]
        // }
      ],
      addMixedCluster: [
        // {
        //     name: '',
        //     addUnitGroup: [{ name: '' }],
        //     addBuilding: [{
        //         name: '',
        //         addFloor: [{
        //             floorType: '',
        //             name: '',
        //             floorStartRange: 1,
        //             floorEndRange: 1,
        //             index: 1
        //         }]
        //     }]
        // }
      ],
      updateMixedCluster: [
        // {
        //     _id: "",
        //     name: '',
        //     addUnitGroup: [{ name: '' }],
        //     updateUnitGroup: [{ _id: "", name: '' }],
        //     deleteUnitGroup: [{ _id: "" }],
        //     addBuilding: [{
        //         name: '',
        //         addFloor: [{
        //             floorType: '',
        //             name: '',
        //             floorStartRange: 1,
        //             floorEndRange: 1,
        //             index: 1
        //         }]
        //     }],
        //     updateBuilding: [{
        //         _id: "",
        //         name: '',
        //         addFloor: [{
        //             floorType: '',
        //             name: '',
        //             floorStartRange: 1,
        //             floorEndRange: 1,
        //             index: 1
        //         }],
        //         updateFloor: [{
        //             _id: "",
        //             floorType: '',
        //             name: '',
        //             index: 1,
        //             prefix: ""
        //         }],
        //         deleteFloor: [{ _id: "" }]
        //     }],
        //     deleteBuilding: [{
        //         _id: '',
        //     }]
        // }
      ],
      deleteMixedCluster: [],
      selectedColumn: '',
      selected: false,
      clusterIndex: 1,
    },
  ])

  const [clusterCounter, setClusterCouter] = useState(0)
  const handleAllCluster = () => {
    const clusterTemp = [...allCluster]
    const newCluster: any = []

    if (clusterTemp[clusterCounter]?.clusterType === 'unit') {
      clusterTemp[clusterCounter].buildingBlur = true
      clusterTemp[clusterCounter].mixedBlur = true
    }
    if (clusterTemp[clusterCounter]?.clusterType === 'building') {
      clusterTemp[clusterCounter].unitBlur = true
      clusterTemp[clusterCounter].mixedBlur = true
    }
    if (clusterTemp[clusterCounter]?.clusterType === 'mixed') {
      clusterTemp[clusterCounter].unitBlur = true
      clusterTemp[clusterCounter].buildingBlur = true
    }

    newCluster.push({
      unitCircle: false,
      buildingCircle: false,
      mixedCircle: false,
      unitBlur: false,
      buildingBlur: false,
      mixedBlur: false,
      clusterType: '',
      addUnitCluster: [],
      addBuildingCluster: [
        {
          name: '',
          addBuilding: [],
        },
      ],
      addMixedCluster: [],
      selectedColumn: '',
      selected: false,
      clusterIndex: allCluster?.length + 1,
    })

    setClusterCouter(clusterCounter + 1)
    setAllCluster([...clusterTemp, ...newCluster])
  }

  const handleRemoveAllCluster = (index: any) => {
    // console.log(index)
    const clusterTemp = [...allCluster]

    if (index === 1) {
      clusterTemp.splice(index)
      setClusterCouter(0)
      clusterTemp[0].unitCircle = false
      clusterTemp[0].mixedCircle = false
      clusterTemp[0].buildingCircle = false
      clusterTemp[0].unitBlur = false
      clusterTemp[0].buildingBlur = false
      clusterTemp[0].mixedBlur = false
    }

    if (index === 2) {
      clusterTemp.splice(index, 1)
      setClusterCouter(clusterCounter - 1)
      clusterTemp[index - 1].unitCircle = false
      clusterTemp[index - 1].mixedCircle = false
      clusterTemp[index - 1].buildingCircle = false
      clusterTemp[index - 1].unitBlur = false
      clusterTemp[index - 1].buildingBlur = false
      clusterTemp[index - 1].mixedBlur = false
    }
    setAllCluster(clusterTemp)
  }

  useEffect(() => {
    const clusterTemp = [...allCluster]
    if (clusterCounter == 1 && allCluster?.length === 2) {
      if (allCluster[0]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (clusterTemp[0]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[0]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
    }
    if (clusterCounter == 2 && allCluster?.length === 3) {
      if (allCluster[0]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (allCluster[1]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (clusterTemp[0]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[1]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[0]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
      if (clusterTemp[1]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
    }

    if (clusterCounter == 2) {
      if (clusterTemp[2].unitBlur === true && clusterTemp[2].buildingBlur) {
        clusterTemp[2].mixedCircle = true
      }
      if (clusterTemp[2].unitBlur === true && clusterTemp[2].mixedBlur) {
        clusterTemp[2].buildingCircle = true
      }
      if (clusterTemp[2].buildingBlur === true && clusterTemp[2].mixedBlur) {
        clusterTemp[2].unitCircle = true
      }
    }
    setAllCluster(clusterTemp)
  }, [clusterCounter])

  const [cityOptions, setCityOptions] = useState<any>([
    {
      label: 'Dubai',
      value: 'Dubai',
    },
    {
      label: 'Abu Dhabi',
      value: 'Abu Dhabi',
    },
    {
      label: 'Sharjah',
      value: 'Sharjah',
    },
    {
      label: 'Ajman',
      value: 'Ajman',
    },
    {
      label: 'Umm Al Quwain',
      value: 'Umm Al Quwain',
    },
    {
      label: 'Ras Al Khaimah',
      value: 'Ras Al Khaimah',
    },
    {
      label: 'Fujairah',
      value: 'Fujairah',
    },
  ])

  const [floorOptions, setFloorOptions] = useState<any>([
    {
      label: 'Ground Floor',
      value: 0,
    },
    {
      label: 'Lobby',
      value: 1,
    },
    {
      label: 'Mezzanine',
      value: 2,
    },
    {
      label: 'Parking',
      value: 3,
    },
    {
      label: 'Upper Level Unit',
      value: 4,
    },
  ])
  const [floorOptionTemp, setFloorOptionTemp] = useState<any>([
    {
      label: 'Ground Floor',
      value: 0,
    },
    {
      label: 'Lobby',
      value: 1,
    },
    {
      label: 'Mezzanine',
      value: 2,
    },
    {
      label: 'Parking',
      value: 3,
    },
    {
      label: 'Upper Level Unit',
      value: 4,
    },
  ])

  const [areaOptions, setAreaOptions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isDevelopmentBtnDisabled, setIsDevelopmentBtnDisabled] = useState(true)
  const [unitClusterFilled, setUnitClusterFilled] = useState(false)
  const [buildingClusterFilled, setBuildingClusterFilled] = useState(false)
  const [mixedClusterFilled, setMixedClusterFilled] = useState(false)
  const [totalBuildingFloors, setTotalBuildingFloors] = useState<any>(1)

  const generateFloorForAPI = () => {
    let i = 0
    let value: any = []
    const rowIndexTemp = buildingDevelopmentUpdateFloor?.reduce((acc: any, floorItem: any) => {
      return (acc += floorItem?.floorList.length)
    }, 0)
    while (i < buildingDevelopmentFloor.length) {
      let rowIndex = rowIndexTemp + 1
      const range = buildingDevelopmentFloor[i]?.floorEndRange - buildingDevelopmentFloor[i]?.floorStartRange + 1
      for (let j = buildingDevelopmentFloor[i]?.floorStartRange; j <= buildingDevelopmentFloor[i]?.floorEndRange; j++) {
        value.push({
          floorType: buildingDevelopmentFloor[i].floorType.value,
          name: buildingDevelopmentFloor[i].floorType.value === 0 ? buildingDevelopmentFloor[i].name : buildingDevelopmentFloor[i].name + ` ${j}`,
          index: rowIndex,
          prefix: buildingDevelopmentFloor[i].name,
        })
        rowIndex += 1
      }
      i++
    }
    return value
  }

  const resetBuildingDevelopmentFloor = () => {
    setStreetBuildingName('')
    setSelectBuildingArea('')
    setSelectBuildingCity('')
    setBuildingName('')
    setBuildingDevelopmentFloor([
      {
        floorType: '',
        name: '',
        floorStartRange: 1,
        floorEndRange: 1,
        index: 1,
      },
    ])
  }

  const resetBuildingCluster = () => {
    setBuildingCluster([
      {
        name: '',
        addBuildingCluster: [
          {
            name: '',
            addFloor: [
              {
                floorType: '',
                name: '',
                floorStartRange: 1,
                floorEndRange: 1,
                index: 1,
              },
            ],
          },
        ],
      },
    ])
  }

  const resetUnitCluster = () => {
    setUnitInput([
      {
        name: '',
      },
    ])
  }

  const resetAllCluster = () => {
    setAllCluster([
      {
        // unitCircle: false,
        // buildingCircle: false,
        // mixedCircle: false,
        unitBlur: false,
        buildingBlur: false,
        mixedBlur: false,
        clusterType: '',
        updateUnitCluster: [],
        deleteUnitCluster: [],
        addUnitCluster: [],
        deleteBuildingCluster: [],
        addBuildingCluster: [],
        updateBuildingCluster: [],
        addMixedCluster: [],
        updateMixedCluster: [],
        deleteMixedCluster: [],
        selectedColumn: '',
        selected: false,
        clusterIndex: 1,
      },
    ])
  }

  const resetCommunityDevelopment = () => {
    setCommunityName('')
    setSelectCity('')
    setSelectArea('')
    setStreetName('')
    // resetAllClusterUI()
  }

  // return area options based on selected city for both building and community
  useEffect(() => {
    let values: any = []
    if (selectBuildingCity?.value == 'Dubai' || selectCity?.value == 'Dubai') {
      values = [
        {
          label: 'Al Jaddaf',
          value: 'Al Jaddaf',
        },
        {
          label: 'Barsha',
          value: 'Barsha',
        },
        {
          label: 'Culture Village',
          value: 'Culture Village',
        },
        {
          label: 'Jumeriah Lake Towers',
          value: 'Jumeriah Lake Towers',
        },
      ]
    } else if (selectBuildingCity?.value == 'Abu Dhabi' || selectCity?.value == 'Abu Dhabi') {
      values = [
        {
          label: 'Al Bateen',
          value: 'Al Bateen',
        },
        {
          label: 'Al Saadah',
          value: 'Al Saadah',
        },
        {
          label: 'Corniche',
          value: 'Corniche',
        },
        {
          label: 'Yas Island',
          value: 'Yas Island',
        },
      ]
    } else if (selectBuildingCity?.value == 'Sharjah' || selectCity?.value == 'Sharjah') {
      values = [
        {
          label: 'Al Qasimia',
          value: 'Al Qasimia',
        },
        {
          label: 'Halwan',
          value: 'Halwan',
        },
        {
          label: 'Industrial Area',
          value: 'Industrial Area',
        },
        {
          label: 'Muwaileh',
          value: 'Muwaileh',
        },
      ]
    } else if (selectBuildingCity?.value == 'Ajman' || selectCity?.value == 'Ajman') {
      values = [
        {
          label: 'Al Bustan',
          value: 'Al Bustan',
        },
        {
          label: 'Al Hamidiya',
          value: 'Al Hamidiya',
        },
        {
          label: 'Al Jerf 1',
          value: 'Al Jerf 1',
        },
        {
          label: 'Al Jerf 2',
          value: 'Al Jerf 2',
        },
        {
          label: 'Al Rawda',
          value: 'Al Rawda',
        },
      ]
    } else if (selectBuildingCity?.value == 'Umm Al Quwain' || selectCity?.value == 'Umm Al Quwain') {
      values = [
        {
          label: 'Al Butain',
          value: 'Al Butain',
        },
        {
          label: 'Al Neefah',
          value: 'Al Neefah',
        },
        {
          label: 'Al Raas',
          value: 'Al Raas',
        },
        {
          label: 'Al Raudah',
          value: 'Al Raudah',
        },
        {
          label: 'Al Salamah',
          value: 'Al Salamah',
        },
      ]
    } else if (selectBuildingCity?.value == 'Ras Al Khaimah' || selectCity?.value == 'Ras Al Khaimah') {
      values = [
        {
          label: 'Al Dhait South',
          value: 'Al Dhait South',
        },
        {
          label: 'Al Kharran',
          value: 'Al Kharran',
        },
        {
          label: 'Al Sewaan',
          value: 'Al Sewaan',
        },
        {
          label: 'Al Digdaga',
          value: 'Al Digdaga',
        },
        {
          label: 'Dafn Al Khor',
          value: 'Dafn Al Khor',
        },
      ]
    } else if (selectBuildingCity?.value == 'Fujairah' || selectCity?.value == 'Fujairah') {
      values = [
        {
          label: 'Al Faseel',
          value: 'Al Faseel',
        },
        {
          label: 'Al Gurfah',
          value: 'Al Gurfah',
        },
        {
          label: 'Al Sharyah',
          value: 'Al Sharyah',
        },
        {
          label: 'Al Righailat',
          value: 'Al Righailat',
        },
        {
          label: 'Safad',
          value: 'Safad',
        },
      ]
    }
    setAreaOptions(values)
  }, [selectBuildingCity, selectCity])

  // disable add development Button for buildingDevelopmentFloor & Community
  useEffect(() => {
    if (selectBuildingCircle) {
      let isBuildingDevelopmentFieldsFilled = streetBuildingName && selectBuildingArea && selectBuildingCity && buildingName
      const addBuildingDevelopmentFieldFilled = buildingDevelopmentFloor?.every(
        (floorItem: any) =>
          floorItem.floorType !== '' && floorItem.name?.trim() !== '' && floorItem.floorStartRange !== null && floorItem.floorEndRange !== null
      )
      const updateBuildingDevelopmentFieldFilled = buildingDevelopmentUpdateFloor?.every(
        (floorItem: any) => floorItem.floorType !== '' && floorItem.prefix !== ''
      )
      setIsDevelopmentBtnDisabled(
        isBuildingDevelopmentFieldsFilled && addBuildingDevelopmentFieldFilled && updateBuildingDevelopmentFieldFilled ? false : true
      )
    }
  }, [streetBuildingName, selectBuildingArea, selectBuildingCity, buildingName, buildingDevelopmentFloor])

  const isFloorFilled = (floor: any) => {
    return floor.name?.trim() !== '' && floor.floorType !== '' && floor.floorStartRange !== null && floor.floorEndRange !== null
  }

  // Function to check if a building cluster object is filled
  const isBuildingClusterFilled = (buildingCluster: any) => {
    return (
      buildingCluster.name?.trim() !== '' &&
      buildingCluster?.addBuilding?.every((cluster: any) => {
        return cluster.name !== '' && cluster.addFloor.every(isFloorFilled)
      })
    )
  }

  const isAllItemsFilled = (index: any) => {
    return allCluster[index].addBuildingCluster.every(isBuildingClusterFilled)
  }

  const isAllMixedItemsFilled = (index: any) => {
    return allCluster[index].addMixedCluster.every(isBuildingClusterFilled)
  }

  useEffect(() => {
    if (selectCommunityCircle) {
      let isCommunityDevelopmentFieldsFilled = streetName && communityName && selectCity && selectArea
      let i = 0
      while (i < allCluster?.length) {
        if (allCluster[i]?.buildingCircle) {
          const isFilledAll = isAllItemsFilled(i)
          const isFilledAllForUpdateCluster = allCluster[i]?.updateBuildingCluster.every((buildingCluster: any) => {
            const isFilledForUpdateBuilding = buildingCluster?.updateBuilding?.every((cluster: any) => {
              const isFilledUpdatedFloor = cluster.floors.every((floorItem: any) => floorItem?.prefix?.trim() !== '' && floorItem?.floorType !== '')
              const isFilledAddFloor = cluster.addFloor.every(isFloorFilled)
              return cluster.name?.trim() !== '' && isFilledAddFloor && isFilledUpdatedFloor
            })
            const isFilledForAddBuilding = buildingCluster?.addBuilding?.every((cluster: any) => {
              const isFilledAddFloor = cluster.addFloor.every(isFloorFilled)
              return cluster.name?.trim() !== '' && isFilledAddFloor
            })
            return isFilledForUpdateBuilding && isFilledForAddBuilding && buildingCluster?.name?.trim() !== ''
          })

          setBuildingClusterFilled(isCommunityDevelopmentFieldsFilled && isFilledAll && isFilledAllForUpdateCluster ? false : true)
        }
        if (allCluster[i]?.unitCircle) {
          const isFilledAll = allCluster[i]?.addUnitCluster?.every((inputItem: any) => inputItem?.name?.trim() !== '')
          setUnitClusterFilled(isCommunityDevelopmentFieldsFilled && isFilledAll ? false : true)
        }
        if (allCluster[i]?.mixedCircle) {
          const isFilledAllBuildingUnits = isAllMixedItemsFilled(i)
          const isFilledAllUnits = allCluster[i]?.addMixedCluster?.every((inputItem: any) => {
            return inputItem?.addUnitGroup?.every((item: any) => item?.name?.trim() !== '')
          })
          const isFilledAllForUpdateCluster = allCluster[i]?.updateMixedCluster?.every((mixedCluster: any) => {
            const isFilledForUpdateBuilding = mixedCluster?.updateBuilding?.every((cluster: any) => {
              const isFilledUpdatedFloor = cluster?.floors?.every((floorItem: any) => floorItem?.prefix?.trim() !== '' && floorItem?.floorType !== '')
              const isFilledAddFloor = cluster?.addFloor?.every(isFloorFilled)
              return cluster?.name?.trim() !== '' && isFilledAddFloor && isFilledUpdatedFloor
            })
            const isFilledForAddBuilding = mixedCluster?.addBuilding?.every((cluster: any) => {
              const isFilledAddFloor = cluster?.addFloor?.every(isFloorFilled)
              return cluster?.name?.trim() !== '' && isFilledAddFloor
            })
            const isFilledAddUnitGroup = mixedCluster?.addUnitGroup?.every((item: any) => item?.name?.trim() !== '')
            const isFilledUpdateUnitGroup = mixedCluster?.updateUnitGroup?.every((item: any) => item?.name?.trim() !== '')
            return (
              isFilledAddUnitGroup &&
              isFilledUpdateUnitGroup &&
              isFilledForUpdateBuilding &&
              isFilledForAddBuilding &&
              buildingCluster?.name?.trim() !== ''
            )
          })
          setMixedClusterFilled(
            isCommunityDevelopmentFieldsFilled && isFilledAllBuildingUnits && isFilledAllUnits && isFilledAllForUpdateCluster ? false : true
          )
        }
        if (!allCluster[i]?.buildingCircle && !allCluster[i]?.unitCircle && !allCluster[i]?.mixedCircle) {
          setBuildingClusterFilled(isCommunityDevelopmentFieldsFilled ? false : true)
          setMixedClusterFilled(isCommunityDevelopmentFieldsFilled ? false : true)
          setUnitClusterFilled(isCommunityDevelopmentFieldsFilled ? false : true)
        }
        i++
      }
    }
  }, [buildingCluster, streetName, communityName, selectCity, selectArea, allCluster, unitInputs])

  // filter ground floor if selected in any row for buildingDevelopmentFloor
  useEffect(() => {
    if (selectBuildingCircle) {
      let updatedOptions = floorOptions
      const isSelectGroundFloor = buildingDevelopmentFloor?.some((floorItem: any) => floorItem?.floorType === 0)
      if (isSelectGroundFloor) {
        updatedOptions = updatedOptions.filter((floorItem: any) => floorItem?.value !== 0)
        setFloorOptions(updatedOptions)
      } else {
        setFloorOptions(floorOptionTemp)
      }
    }
  }, [buildingDevelopmentFloor])

  // total floor for buildingDevelopmentFloor
  useEffect(() => {
    let totalFloors = 0
    buildingDevelopmentFloor?.forEach((floorItem: any) => {
      totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
    })
    setTotalBuildingFloors(totalFloors)
  }, [buildingDevelopmentFloor])

  const isUnitCircle = allCluster[0]?.unitCircle
  const isBuildingCircle = allCluster[0]?.buildingCircle
  const isMixedCircle = allCluster[0]?.mixedCircle

  // useEffect(() => {
  //     if (allCluster.length !== 1) return;
  //     setAllCluster((prevClusters: any) => {
  //         return prevClusters.map((cluster: any) => {
  //             if (prevClusters.length === 1) {
  //                 const isUnitClusterSelected = cluster.unitCircle;
  //                 const isBuildingClusterSelected = cluster.buildingCircle;
  //                 const isMixedClusterSelected = cluster.mixedCircle;

  //                 if (isUnitClusterSelected) {
  //                     return {
  //                         ...cluster,
  //                         addBuildingCluster: [{
  //                             name: '',
  //                             addBuilding: [{
  //                                 name: '',
  //                                 addFloor: [{
  //                                     floorType: '',
  //                                     name: '',
  //                                     floorStartRange: 1,
  //                                     floorEndRange: 1,
  //                                     index: 1,
  //                                 }]
  //                             }]
  //                         }],
  //                         addMixedCluster: [{
  //                             name: '',
  //                             addUnitGroup: [{ name: '' }],
  //                             addBuilding: [{
  //                                 name: '',
  //                                 addFloor: [{
  //                                     floorType: '',
  //                                     name: '',
  //                                     floorStartRange: 1,
  //                                     floorEndRange: 1,
  //                                     index: 1
  //                                 }]
  //                             }]
  //                         }]
  //                     };
  //                 } else if (isBuildingClusterSelected) {
  //                     return {
  //                         ...cluster,
  //                         addUnitCluster: [{ name: '' }],
  //                         addMixedCluster: [{
  //                             name: '',
  //                             addUnitGroup: [{ name: '' }],
  //                             addBuilding: [{
  //                                 name: '',
  //                                 addFloor: [{
  //                                     floorType: '',
  //                                     name: '',
  //                                     floorStartRange: 1,
  //                                     floorEndRange: 1,
  //                                     index: 1
  //                                 }]
  //                             }]
  //                         }]
  //                     };
  //                 } else if (isMixedClusterSelected) {
  //                     return {
  //                         ...cluster,
  //                         addUnitCluster: [{ name: '' }],
  //                         addBuildingCluster: [{
  //                             name: '',
  //                             addBuilding: [{
  //                                 name: '',
  //                                 addFloor: [{
  //                                     floorType: '',
  //                                     name: '',
  //                                     floorStartRange: 1,
  //                                     floorEndRange: 1,
  //                                     index: 1,
  //                                 }]
  //                             }]
  //                         }]
  //                     };
  //                 }
  //             }
  //             return cluster;
  //         });
  //     });
  // }, [isMixedCircle, isBuildingCircle, isUnitCircle])

  const generateBuildingFloorForAPI = (i: any) => {
    let addBuildingCluster: any = JSON.parse(JSON.stringify(allCluster[i]?.addBuildingCluster))

    let buildingClusterIndex = 0
    while (buildingClusterIndex < allCluster[i]?.addBuildingCluster?.length) {
      addBuildingCluster[buildingClusterIndex].name = allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.name
      let buildingIndex = 0
      while (buildingIndex < allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.length) {
        addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].name =
          allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.name
        let floorIndex = 0
        while (floorIndex < allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length) {
          const range =
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange -
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange +
            1
          addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = []
          for (let j = 1; j <= range; j++) {
            addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
              floorType: allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType,
              name:
                allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name.replace(/\s+/g, '-') +
                `-${j}`,
              index: allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.index,
            })
          }
          floorIndex++
        }
        buildingIndex++
      }

      buildingClusterIndex++
    }
    return addBuildingCluster
  }

  const generateMixedClusterAPIData = (i: any) => {
    let addMixedCluster: any = JSON.parse(JSON.stringify(allCluster[i]?.addMixedCluster))

    let mixedClusterIndex = 0
    while (mixedClusterIndex < allCluster[i]?.addMixedCluster?.length) {
      addMixedCluster[mixedClusterIndex].name = allCluster[i]?.addMixedCluster[mixedClusterIndex]?.name
      let buildingIndex = 0
      while (buildingIndex < allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.length) {
        addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].name =
          allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.name
        let floorIndex = 0
        while (floorIndex < allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length) {
          const range =
            allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange -
            allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange +
            1
          addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].addFloor = []
          for (let j = 1; j <= range; j++) {
            addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
              floorType: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType,
              name:
                allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name.replace(/\s+/g, '-') +
                `-${j}`,
              index: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.index,
            })
          }
          floorIndex++
        }
        buildingIndex++
      }

      mixedClusterIndex++
    }
    return addMixedCluster
  }

  const calculateTotalFloors = (index: any, mixedClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }

  // const floorOption = (index: any, mixedClusterIndex: any, buildingIndex: any, floorIndex: any) => {
  //     let options: any = []
  //     let updatedOptions = floorOptions;
  //     const isSelectGroundFloor = allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.some((floorItem: any) => floorItem?.floorType === 0)
  //     const isSelectLobby = allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.some((floorItem: any) => floorItem?.floorType === 1)
  //     const isSelectMezzanine = allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.some((floorItem: any) => floorItem?.floorType === 2)
  //     const isSelectParking = allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.some((floorItem: any) => floorItem?.floorType === 3)
  //     if (isSelectGroundFloor) {
  //         options = updatedOptions.filter((floorItem: any) => floorItem?.value !== 0)
  //     } else if (isSelectLobby) {
  //         options = updatedOptions.filter((floorItem: any) => floorItem?.value !== 1)
  //     } else if (isSelectMezzanine) {
  //         options = updatedOptions.filter((floorItem: any) => floorItem?.value !== 2)
  //     }else  if (isSelectParking) {
  //         options = updatedOptions.filter((floorItem: any) => floorItem?.value !== 3)
  //     } else {
  //         options = updatedOptions
  //     }
  //     return options
  // }
  const floorOption = (
    index: any,
    mixedClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    buildingType: 'addBuilding' | 'updateBuilding',
    floorType: 'addFloor' | 'updateFloor'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'add' ? allCluster[index]?.addMixedCluster : allCluster[index]?.updateMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.[floorType]
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionForUpdate = (
    index: any,
    mixedClusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    buildingType: 'addBuilding' | 'updateBuilding'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'add' ? allCluster[index]?.addMixedCluster : allCluster[index]?.updateMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.floors
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionsForBuilding = (floorIndex: any) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentUpdateFloor
    const selectedFloorTypes = mergeBuildingDevelopmenFloor
      ?.filter((floorItem: any) => floorItem?.subListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentFloor
    const selectedFloorTypesForAdd = mergeBuildingDevelopmenFloor
      ?.filter((floorItem: any) => floorItem?.noSubListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if ((selectedFloorTypes && selectedFloorTypes.length > 0) || (selectedFloorTypesForAdd && selectedFloorTypesForAdd.length > 0)) {
      // Combine both selected floor types into one set to ensure unique values
      const combinedSelectedFloorTypes = new Set([...(selectedFloorTypes || []), ...(selectedFloorTypesForAdd || [])])

      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !combinedSelectedFloorTypes.has(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionsForUpdateBuilding = (
    index: any,
    clusterIndex: any,
    buildingIndex: any,
    floorIndex: any,
    type: 'add' | 'update',
    clusterType: 'building' | 'mixed'
  ) => {
    let options: any = []
    let updatedOptions = floorOptions

    const cluster = clusterType === 'building' ? allCluster[index]?.updateBuildingCluster : allCluster[index]?.updateMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentUpdateFloor
    // const selectedFloorTypes = cluster?.[mixedClusterIndex]?.[buildingType]?.[buildingIndex]?.floors
    // ?.filter((floorItem: any) => [0, 1, 2, 3].includes(floorItem?.floorType))
    // ?.map((floorItem: any) => floorItem?.floorType);

    const selectedFloorTypes = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.floors
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentFloor
    const selectedFloorTypesForAdd = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType?.value))
      ?.map((floorItem: any) => floorItem?.floorType?.value)

    if ((selectedFloorTypes && selectedFloorTypes.length > 0) || (selectedFloorTypesForAdd && selectedFloorTypesForAdd.length > 0)) {
      // Combine both selected floor types into one set to ensure unique values
      const combinedSelectedFloorTypes = new Set([...(selectedFloorTypes || []), ...(selectedFloorTypesForAdd || [])])

      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !combinedSelectedFloorTypes.has(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const addDevelopment = async () => {
    let floors: any = []

    let isDuplicationInUpdateBuildingCluster = false
    let startRangeErrorInUpdateBuildingCluster = false
    let endRangeErrorInUpdateBuildingCluster = false
    let isDuplicationInUpdateBuildingClusterForAddBuilding = false
    let startRangeErrorInUpdateBuildingClusterForAddBuilding = false
    let endRangeErrorInUpdateBuildingClusterForAddBuilding = false
    let isDuplicationInAddBuildingCluster = false
    let startRangeErrorInAddBuildingCluster = false
    let endRangeErrorInAddBuildingCluster = false
    for (let {updateBuildingCluster, addBuildingCluster} of allCluster) {
      updateBuildingCluster?.forEach((buildingCluster: any, index: any) => {
        buildingCluster?.updateBuilding?.forEach((building: any) => {
          // startRangeErrorInUpdateBuildingCluster = building?.addFloor?.some((floor: any) => floor.startRangeError)
          // endRangeErrorInUpdateBuildingCluster = building?.addFloor?.some((floor: any) => floor.endRangeError)
          startRangeErrorInUpdateBuildingCluster = building?.mergeFloorArray?.some((floor: any) => floor.startRangeError)
          endRangeErrorInUpdateBuildingCluster = building?.mergeFloorArray?.some((floor: any) => floor.endRangeError)
          isDuplicationInUpdateBuildingCluster = building?.mergeFloorArray?.some((floor: any) => floor.isDuplicate)
          // building?.floors?.some((floor: any) => floor.isDuplicate) || building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })

        buildingCluster?.addBuilding?.forEach((building: any) => {
          startRangeErrorInUpdateBuildingClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.startRangeError)
          endRangeErrorInUpdateBuildingClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.endRangeError)
          isDuplicationInUpdateBuildingClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })
      })

      addBuildingCluster?.forEach((buildingCluster: any, index: any) => {
        buildingCluster?.addBuilding?.forEach((building: any) => {
          startRangeErrorInAddBuildingCluster = building?.addFloor?.some((floor: any) => floor.startRangeError)
          endRangeErrorInAddBuildingCluster = building?.addFloor?.some((floor: any) => floor.endRangeError)
          isDuplicationInAddBuildingCluster = building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })
      })
    }
    let isDuplicationInUpdateMixedCluster = false
    let startRangeErrorInUpdateMixedCluster = false
    let endRangeErrorInUpdateMixedCluster = false
    let isDuplicationInUpdateMixedClusterForAddBuilding = false
    let startRangeErrorInUpdateMixedClusterForAddBuilding = false
    let endRangeErrorUpdateMixedClusterForAddBuilding = false
    let isDuplicationInAddMixedCluster = false
    let startRangeErrorInAddMixedCluster = false
    let endRangeErrorInAddMixedCluster = false
    for (let {updateMixedCluster, addMixedCluster} of allCluster) {
      // check duplication for update mixed cluster
      updateMixedCluster?.forEach((buildingCluster: any, index: any) => {
        buildingCluster?.updateBuilding?.forEach((building: any) => {
          // startRangeErrorInUpdateMixedCluster = building?.addFloor?.some((floor: any) => floor.startRangeError)
          // endRangeErrorInUpdateMixedCluster = building?.addFloor?.some((floor: any) => floor.endRangeError)
          startRangeErrorInUpdateMixedCluster = building?.mergeFloorArray?.some((floor: any) => floor.startRangeError)
          endRangeErrorInUpdateMixedCluster = building?.mergeFloorArray?.some((floor: any) => floor.endRangeError)
          isDuplicationInUpdateMixedCluster = building?.mergeFloorArray?.some((floor: any) => floor.isDuplicate)
          // building?.floors?.some((floor: any) => floor.isDuplicate) || building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })

        buildingCluster?.addBuilding?.forEach((building: any) => {
          startRangeErrorInUpdateMixedClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.startRangeError)
          endRangeErrorUpdateMixedClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.endRangeError)
          isDuplicationInUpdateMixedClusterForAddBuilding = building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })
      })
      // check duplication for add mixed cluster
      addMixedCluster?.forEach((buildingCluster: any, index: any) => {
        buildingCluster?.addBuilding?.forEach((building: any) => {
          startRangeErrorInAddMixedCluster = building?.addFloor?.some((floor: any) => floor.startRangeError)
          endRangeErrorInAddMixedCluster = building?.addFloor?.some((floor: any) => floor.endRangeError)
          isDuplicationInAddMixedCluster = building?.addFloor?.some((floor: any) => floor.isDuplicate)
        })
      })
    }

    const startRangeErrorUpdateBuildingFloors = mergeBuildingDevelopmenFloor?.some((floor: any) => floor?.startRangeError)
    const endRangeErrorUpdateBuildingFloors = mergeBuildingDevelopmenFloor?.some((floor: any) => floor?.endRangeError)

    if (
      startRangeErrorInAddBuildingCluster ||
      startRangeErrorInUpdateBuildingClusterForAddBuilding ||
      startRangeErrorInUpdateBuildingCluster ||
      startRangeErrorInAddMixedCluster ||
      startRangeErrorInUpdateMixedClusterForAddBuilding ||
      startRangeErrorInUpdateMixedCluster ||
      startRangeErrorUpdateBuildingFloors
    ) {
      ErrorToast("The floor 'Start Range' should be equal to or less than the floor 'End Range'.")
      return
    }

    if (
      endRangeErrorInAddBuildingCluster ||
      endRangeErrorInUpdateBuildingClusterForAddBuilding ||
      endRangeErrorInUpdateBuildingCluster ||
      endRangeErrorInAddMixedCluster ||
      endRangeErrorUpdateMixedClusterForAddBuilding ||
      endRangeErrorInUpdateMixedCluster ||
      endRangeErrorUpdateBuildingFloors
    ) {
      ErrorToast("The floor 'End Range' should be equal to or greater than the floor 'Start Range'.")
      return
    }

    if (
      isDuplicationInUpdateBuildingCluster ||
      isDuplicationInUpdateBuildingClusterForAddBuilding ||
      isDuplicationInAddBuildingCluster ||
      isDuplicationInUpdateMixedCluster ||
      isDuplicationInUpdateMixedClusterForAddBuilding ||
      isDuplicationInAddMixedCluster
    ) {
      ErrorToast('Floor Names are duplicated')
      return
    }

    // const isDuplicateInUpdateBuildingFloors = buildingDevelopmentUpdateFloor?.some((floor: any) => floor.isDuplicate)
    // const isDuplicateInUpdateBuildingAddFloors = buildingDevelopmentFloor?.some((floor: any) => floor.isDuplicate)
    const isDuplicateInMergeBuildingArray = mergeBuildingDevelopmenFloor?.some((floor: any) => floor.isDuplicate)

    if (isDuplicateInMergeBuildingArray) {
      ErrorToast('Floor Names are duplicated')
      return
    }

    // if (selectBuildingCircle) {
    //   floors = generateFloorForAPI()
    // }

    const unitClusterArray = allCluster?.reduce((acc: any, clusterItem: any) => {
      const {addUnitCluster, updateUnitCluster, deleteUnitCluster} = clusterItem
      return {
        ...acc,
        addUnitCluster: addUnitCluster,
        updateUnitCluster: updateUnitCluster?.map((unitCluster: any) => {
          const {_id, name, ...rest} = unitCluster
          return {
            _id,
            name,
          }
        }),
        deleteUnitCluster: deleteUnitCluster,
      }
    }, {})

    const buildingClusterArray = allCluster?.reduce((acc: any, clusterItem: any) => {
      const {addBuildingCluster, updateBuildingCluster, deleteBuildingCluster} = clusterItem
      return {
        ...acc,
        deleteBuildingCluster,
        addBuildingCluster: addBuildingCluster?.map((building: any) => {
          let rowIndex = 1
          return {
            ...building,
            addBuilding: building?.addBuilding.map((building: any) => {
              return {
                ...building,
                addFloor: building?.addFloor?.flatMap((floor: any) => {
                  const range = floor.floorEndRange - floor.floorStartRange + 1
                  const floorStartRange = floor.floorStartRange
                  const floorEndRange = floor.floorEndRange
                  // return Array.from({ length: range }, (_, index) => {
                  //     const temp = {
                  //         floorType: floor.floorType.value,
                  //         name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${rowIndex}`,
                  //         index: rowIndex,
                  //         prefix: floor.name
                  //     }
                  //     rowIndex += 1
                  //     return temp
                  // });
                  const floorArrTemp: any = []
                  for (let i = floorStartRange; i <= floorEndRange; i++) {
                    const temp = {
                      floorType: floor.floorType.value,
                      name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${i}`,
                      index: rowIndex,
                      prefix: floor.name,
                    }
                    floorArrTemp?.push(temp)
                    rowIndex += 1
                  }
                  return floorArrTemp
                }),
              }
            }),
          }
        }),
        updateBuildingCluster: updateBuildingCluster?.map((building: any) => {
          const {...rest} = building
          return {
            ...rest,
            addBuilding: building?.addBuilding.map((building: any) => {
              let rowIndex = 1
              return {
                ...building,
                addFloor: building?.addFloor?.flatMap((floor: any) => {
                  const range = floor.floorEndRange - floor.floorStartRange + 1
                  const floorStartRange = floor.floorStartRange
                  const floorEndRange = floor.floorEndRange
                  // return Array.from({ length: range }, (_, index) => {
                  //     const temp = {
                  //         floorType: floor.floorType.value,
                  //         name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${rowIndex}`,
                  //         index: rowIndex,
                  //         prefix: floor.name
                  //     }
                  //     rowIndex += 1
                  //     return temp
                  // });
                  const floorArrTemp: any = []
                  for (let i = floorStartRange; i <= floorEndRange; i++) {
                    const temp = {
                      floorType: floor.floorType.value,
                      name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${i}`,
                      index: rowIndex,
                      prefix: floor.name,
                    }
                    floorArrTemp?.push(temp)
                    rowIndex += 1
                  }
                  return floorArrTemp
                }),
              }
            }),
            updateBuilding: building?.updateBuilding.map((buildingItem: any) => {
              const {floors, mergeFloorArray, ...rest} = buildingItem
              const {addFloor, updateFloor} = generateFloorForEditBuildingCluster(
                mergeFloorArray?.sort((a: any, b: any) => {
                  if (a.sortIndex < b.sortIndex) return -1
                  if (a.sortIndex > b.sortIndex) return 1
                  return 0
                })
              )
              const rowIndexTemp = buildingItem?.floors?.reduce((acc: any, floorItem: any) => {
                return (acc += floorItem?.floorList.length)
              }, 0)
              let rowIndex = rowIndexTemp + 1
              return {
                ...rest,
                addFloor: addFloor,
                updateFloor: updateFloor,
              }
            }),
          }
        }),
      }
    }, {})

    const mixedClusterArray = allCluster?.reduce((acc: any, clusterItem: any) => {
      const {addMixedCluster, updateMixedCluster, deleteMixedCluster} = clusterItem
      return {
        ...acc,
        deleteMixedCluster,
        addMixedCluster: addMixedCluster?.map((cluster: any) => {
          const {addBuilding, ...rest} = cluster
          return {
            ...rest,
            addBuilding: cluster?.addBuilding.map((building: any) => {
              let rowIndex = 1
              return {
                ...building,
                addFloor: building?.addFloor?.flatMap((floor: any) => {
                  const range = floor.floorEndRange - floor.floorStartRange + 1
                  const floorStartRange = floor.floorStartRange
                  const floorEndRange = floor.floorEndRange
                  // return Array.from({ length: range }, (_, index) => {
                  //     const temp = {
                  //         floorType: floor.floorType.value,
                  //         name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${rowIndex}`,
                  //         index: rowIndex,
                  //         prefix: floor.name
                  //     }
                  //     rowIndex += 1
                  //     return temp
                  // });
                  const floorArrTemp: any = []
                  for (let i = floorStartRange; i <= floorEndRange; i++) {
                    const temp = {
                      floorType: floor.floorType.value,
                      name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${i}`,
                      index: rowIndex,
                      prefix: floor.name,
                    }
                    floorArrTemp?.push(temp)
                    rowIndex += 1
                  }
                  return floorArrTemp
                }),
              }
            }),
          }
        }),
        updateMixedCluster: updateMixedCluster?.map((cluster: any) => {
          const {addBuilding, updateBuilding, ...rest} = cluster
          return {
            ...rest,
            addBuilding: cluster?.addBuilding.map((building: any) => {
              let rowIndex = 1
              return {
                ...building,
                addFloor: building?.addFloor?.flatMap((floor: any) => {
                  const range = floor.floorEndRange - floor.floorStartRange + 1
                  const floorStartRange = floor.floorStartRange
                  const floorEndRange = floor.floorEndRange
                  // return Array.from({ length: range }, (_, index) => {
                  //     const temp = {
                  //         floorType: floor.floorType.value,
                  //         name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${rowIndex}`,
                  //         index: rowIndex,
                  //         prefix: floor.name
                  //     }
                  //     rowIndex += 1
                  //     return temp
                  // });
                  const floorArrTemp: any = []
                  for (let i = floorStartRange; i <= floorEndRange; i++) {
                    const temp = {
                      floorType: floor.floorType.value,
                      name: floor.floorType.value === 0 ? floor.name : `${floor.name} ${i}`,
                      index: rowIndex,
                      prefix: floor.name,
                    }
                    floorArrTemp?.push(temp)
                    rowIndex += 1
                  }
                  return floorArrTemp
                }),
              }
            }),
            updateBuilding: cluster?.updateBuilding.map((buildingItem: any) => {
              const {floors, mergeFloorArray, ...rest} = buildingItem
              const {addFloor, updateFloor} = generateFloorForEditBuildingCluster(
                mergeFloorArray?.sort((a: any, b: any) => {
                  if (a.sortIndex < b.sortIndex) return -1
                  if (a.sortIndex > b.sortIndex) return 1
                  return 0
                })
              )
              const rowIndexTemp = buildingItem?.floors?.reduce((acc: any, floorItem: any) => {
                return (acc += floorItem?.floorList.length)
              }, 0)
              let rowIndex = rowIndexTemp + 1

              return {
                ...rest,
                addFloor: addFloor,
                updateFloor: updateFloor,
              }
            }),
          }
        }),
      }
    }, {})

    setIsSkeleton(true)

    let body: any = {
      buildingDevelopments: {},
      communityDevelopments: {},
    }

    if (location?.state?.from !== undefined && location?.state?.from === 'buildingDetail') {
      body = {
        buildingDevelopments: [
          {
            _id: buildingId,
            name: buildingName?.trim(),
            city: selectBuildingCity?.value,
            area: selectBuildingArea?.value,
            street: streetBuildingName?.trim(),
            // addFloor: floors,
            addFloor: addBuilding,
            updateFloor: updateBuilding,
            // updateFloor: buildingDevelopmentUpdateFloor?.flatMap((floorItem: any) => {
            //   const {floorList, ...rest} = floorItem
            //   return floorList.map((listItem: any) => {
            //     const {id, ...rest} = listItem
            //     return {
            //       ...rest,
            //     }
            //   })
            // }),
            deleteFloor: buildingDevelopmentDeleteFloor,
          },
        ],
      }
    }

    if (location?.state?.from !== undefined && location?.state?.from === 'communityDetail') {
      body = {
        communityDevelopments: [
          {
            _id: location?.state?.communityId,
            name: communityName?.trim(),
            city: selectCity?.value,
            area: selectArea?.value,
            street: streetName?.trim(),
            ...unitClusterArray,
            ...buildingClusterArray,
            ...mixedClusterArray,
          },
        ],
      }
    }

    await ApiPost('corporate/development', body)
      .then((res) => {
        resetAllCluster()
        setIsSkeleton(false)
        setIsCTAHidden(true)
        if (location?.state?.from !== undefined && location?.state?.from === 'buildingDetail') {
          getBuildingDevelopment()
        } else {
          getCommunityDevelopment()
        }
      })
      .catch((err) => {
        setIsSkeleton(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const transformApiResponse = (apiResponse: any) => {
    return apiResponse.map((cluster: any) => {
      // if (cluster.type === 1) { // Building cluster type
      return {
        _id: cluster._id,
        name: cluster.name,
        addBuilding: [],
        deleteBuilding: [],
        updateBuilding: cluster?.building?.map((building: any) => ({
          _id: building._id,
          name: building.name,
          addFloor: [],
          deleteFloor: [],
          mergeFloorArray: building?.floor?.map((floor: any, ind: any) => ({
            sections: [{}],
            subListRowUI: true,
            sortIndex: ind,
            isShowFloors: true,
            // floorStartRange: 1,
            name: floor.prefix,
            // floorEndRange: floor?.floorList?.length,
            ...floor,
          })),
          floors: building?.floor?.map((floor: any) => ({
            sections: [{}],
            isShowFloors: true,
            floorStartRange: 1,
            name: floor.prefix,
            floorEndRange: floor?.floorList?.length,
            ...floor,
          })),
          updateFloor: [],
        })),
      }
      // }
    })
  }

  const transformApiResponseForMixedCluster = (apiResponse: any) => {
    return apiResponse.map((cluster: any) => {
      return {
        _id: cluster._id,
        name: cluster.name,
        addUnitGroup: [],
        updateUnitGroup: cluster?.unit_group?.map((unit: any) => ({
          _id: unit._id,
          name: unit.name,
        })),
        deleteUnitGroup: [
          // { _id: "" }
        ],
        addBuilding: [],
        deleteBuilding: [
          //     {
          //     _id: '',
          // }
        ],
        updateBuilding: cluster?.building?.map((building: any) => ({
          _id: building._id,
          name: building.name,
          addFloor: [],
          deleteFloor: [],
          floors: building?.floor?.map((floor: any) => ({
            sections: [],
            isShowFloors: true,
            floorStartRange: 1,
            name: floor.prefix,
            floorEndRange: floor?.floorList?.length,
            ...floor,
          })),
          mergeFloorArray: building?.floor?.map((floor: any, ind: any) => ({
            sections: [{}],
            subListRowUI: true,
            sortIndex: ind,
            isShowFloors: true,
            // floorStartRange: 1,
            name: floor.prefix,
            // floorEndRange: floor?.floorList?.length,
            ...floor,
          })),
          updateFloor: [],
        })),
      }
    })
  }

  const getCommunityDevelopment = async () => {
    resetAllCluster()
    setIsSkeleton(true)
    await ApiGet(`corporate/communities/${location?.state?.communityId}?floorFormat=true`)
      .then((res: any) => {
        // console.log(res?.data?.data)
        setCommunityId(res?.data?.data?._id)
        const city = cityOptions?.filter((option: any) => option?.value?.toLowerCase() == res?.data?.data.city?.toLowerCase())
        setSelectCity(city?.[0])
        setSelectArea({label: res?.data?.data.area, value: res?.data?.data.area})
        setCommunityName(res?.data?.data.name)
        setStreetName(res?.data?.data.street)
        const updateCluster = [...allCluster]

        const buildingClusters = transformApiResponse(res?.data?.data?.cluster?.filter((clusterItem: any) => clusterItem.type === 1))
        const mixedClusters = transformApiResponseForMixedCluster(res?.data?.data?.cluster?.filter((clusterItem: any) => clusterItem.type === 2))
        const unitClusterTemp = res?.data?.data?.cluster?.filter((unitCluster: any, index: any) => {
          if (unitCluster?.type === 0) {
            return {
              name: unitCluster?.name,
              _id: unitCluster?._id,
            }
          }
        })

        setAllCluster([
          {
            unitCircle: true,
            buildingCircle: true,
            mixedCircle: true,
            unitBlur: false,
            buildingBlur: false,
            mixedBlur: false,
            clusterType: '',
            updateUnitCluster: unitClusterTemp,
            deleteUnitCluster: [],
            addUnitCluster: [],
            deleteBuildingCluster: [],
            addBuildingCluster: [],
            updateBuildingCluster: buildingClusters,
            addMixedCluster: [],
            updateMixedCluster: mixedClusters,
            deleteMixedCluster: [],
            selectedColumn: '',
            selected: false,
            clusterIndex: 1,
          },
        ])
        setIsSkeleton(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsSkeleton(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const [buildingId, setBuildingId] = useState<any>('')
  const [communityId, setCommunityId] = useState<any>('')

  const getBuildingDevelopment = async () => {
    setBuildingDevelopmentUpdateFloor([])
    setBuildingDevelopmentDeleteFloor([])
    setBuildingDevelopmentFloor([])
    setMergeBuildingDevelopmenFloor([])
    setIsSkeleton(true)
    await ApiGet(`corporate/building/${location?.state?.buildingId}?floorFormat=true`)
      .then((res: any) => {
        // console.log(res?.data?.data)
        setBuildingId(res?.data?.data?._id)
        const city = cityOptions?.filter((option: any) => option?.value?.toLowerCase() == res?.data?.data.city?.toLowerCase())
        setSelectBuildingCity(city?.[0])
        setSelectBuildingArea({label: res?.data?.data.area, value: res?.data?.data.area})
        setBuildingName(res?.data?.data?.name)
        setStreetBuildingName(res?.data?.data?.street)

        // const floors = res?.data?.data?.floor?.map((floor: any) => ({
        //     isShowFloors: true,
        //     name:floor.prefix,
        //     floorStartRange: 1,
        //     floorEndRange: floor?.floorList?.length,
        //     ...floor
        // }))

        const floors = res?.data?.data?.floor?.map((floor: any, ind: any) => {
          const floorNumbers = floor?.floorList.map((floor2: any) => {
            return {
              numericName: floor2.name.split(' ')[1] || floor2.name.split('-')[1],
            }
          })
          // const minFloor = floorNumbers.reduce((min:any, current:any) => (current.numericName < min.numericName ? current : min));
          // const maxFloor = floorNumbers.reduce((max:any, current:any) => (current.numericName > max.numericName ? current : max));
          // console.log(JSON.stringify(floorNumbers))
          let numericValues = floorNumbers.map((item: any) => (item.numericName === undefined ? 1 : parseInt(item.numericName)))

          // Finding the minimum and maximum values
          let minValue = Math.min(...numericValues)
          let maxValue = Math.max(...numericValues)
          // console.log(minValue,maxValue)
          return {
            subListRowUI: true,
            isShowFloors: true,
            name: floor.prefix,
            sortIndex: ind,
            // floorStartRange: minValue,
            // floorEndRange: maxValue,
            ...floor,
          }
        })

        // setBuildingDevelopmentUpdateFloor(floors)
        setMergeBuildingDevelopmenFloor(floors)
        // console.log(mergeBuildingDevelopmenFloor)

        setIsSkeleton(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsSkeleton(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const [isAllClusterClicked, setIsAllClusterClicked] = useState<any>(true)

  useEffect(() => {
    const updateCluster = [...allCluster]
    let i = 0
    while (i < updateCluster.length) {
      updateCluster[i].unitCircle = location?.state?.isCommunity !== undefined && location?.state?.isCommunity == true ? true : false
      updateCluster[i].buildingCircle = location?.state?.isCommunity !== undefined && location?.state?.isCommunity == true ? true : false
      updateCluster[i].mixedCircle = location?.state?.isCommunity !== undefined && location?.state?.isCommunity == true ? true : false
      i++
    }
  }, [])

  const [isDeleteItem, setIsDeleteItem] = useState<any>(false)

  useEffect(() => {
    if (location?.state?.from !== undefined && location?.state?.from === 'communityDetail') {
      getCommunityDevelopment()
    }
    if (location?.state?.from !== undefined && location?.state?.from === 'buildingDetail') {
      getBuildingDevelopment()
    }
  }, [])

  let calculateTotalFloorForUpdateBuiding = 0
  const calculateTotalUpdateFloorLength = () => {
    mergeBuildingDevelopmenFloor?.forEach((building: any) => {
      if (building?.noSubListRowUI) {
        if (building?.floorStartRange > building?.floorEndRange) {
          calculateTotalFloorForUpdateBuiding += 1
        } else {
          calculateTotalFloorForUpdateBuiding += building.floorEndRange - building.floorStartRange + 1
        }
      } else {
        calculateTotalFloorForUpdateBuiding += building?.floorList?.length
      }
    })
  }
  calculateTotalUpdateFloorLength()

  const handleRemoveDevelopment = async () => {
    setIsSkeleton(true)
    const developmentURL = location?.state?.buildingId
      ? `corporate/building/${location?.state?.buildingId}`
      : `corporate/communities/${location?.state?.communityId}`
    await ApiDelete(developmentURL)
      .then((res) => {
        setIsSkeleton(false)
        navigate('/properties')
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsSkeleton(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const checkForDuplicates = (floors1: any, floors2: any) => {
    const checkInternalDuplicates = (array: any) => {
      return array.map((floor: any, i: any) => {
        let isDuplicate = false
        let errorMessage = ''

        array.forEach((otherFloor: any, j: any) => {
          if (i !== j && floor?.floorType === otherFloor?.floorType && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
            const isOverlapping = !(floor?.floorEndRange < otherFloor?.floorStartRange || floor?.floorStartRange > otherFloor?.floorEndRange)
            if (isOverlapping) {
              isDuplicate = true
              errorMessage = 'Floor Name is duplicated'
            }
          }
        })

        return {...floor, isDuplicate, errorMessage}
      })
    }

    const updatedFloors1 = checkInternalDuplicates(floors1)
    const updatedFloors2 = checkInternalDuplicates(floors2)

    // updatedFloors1?.forEach((floor1:any, i:any) => {
    //     if (!floor1?.floorType?.value) {
    //         floor1.isDuplicate = false
    //         floor1.errorMessage = ''
    //         return; // Skip this iteration if the floor1 item is empty
    //     }

    //     updatedFloors2?.forEach((floor2:any, j:any) => {
    //         if (!floor1?.floorType?.value) {
    //             floor1.isDuplicate = false
    //             floor1.errorMessage = ''
    //             return; // Skip this iteration if the floor1 item is empty
    //         }
    //         if (floor1?.floorType?.value === floor2?.floorType) {
    //             const isOverlapping = !(floor1?.floorEndRange < floor2?.floorStartRange || floor1?.floorStartRange > floor2?.floorEndRange);

    //             if (isOverlapping) {
    //                 floor2?.floorList?.forEach((floorListItem:any,index:any) => {
    //                     // || floor1.name.toLowerCase() === `${floorListItem.name.toLowerCase()}`
    //                     if (floor1?.name?.toLowerCase() === floorListItem?.prefix?.toLowerCase() ) {
    //                         updatedFloors1[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
    //                         updatedFloors2[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
    //                     }
    //                 });
    //             }
    //         }
    //     });
    // })

    updatedFloors1.forEach((floor1: any, i: any) => {
      if (!floor1?.floorType?.value) {
        floor1.isDuplicate = false
        floor1.errorMessage = ''
        return // Skip this iteration if the floor1 item is empty
      }

      for (let range = floor1.floorStartRange; range <= floor1.floorEndRange; range++) {
        updatedFloors2.forEach((floor2: any, j: any) => {
          if (floor1.floorType.value === floor2.floorType) {
            // const isOverlapping = !(floor1.floorEndRange < floor2.floorStartRange || floor1.floorStartRange > floor2.floorEndRange);
            // if (isOverlapping) {
            // floor2.floorList.forEach((floorListItem:any) => {
            //     const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`;
            //     if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
            //         updatedFloors1[i] = { ...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
            //         updatedFloors2[j] = { ...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated' };
            //     }
            // });
            // }

            floor2.floorList.forEach((floorListItem: any) => {
              if (floorListItem.name?.includes('-')) {
                const [baseName, suffix] = floorListItem.name.toLowerCase().split('-')
                const isDuplicate = baseName === floor1.name.toLowerCase() && parseInt(suffix, 10) === range

                if (isDuplicate) {
                  updatedFloors1[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors2[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              } else {
                const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`
                if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
                  updatedFloors1[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors2[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              }
            })
          }
        })
      }
    })

    return {updatedFloors1, updatedFloors2}
  }

  const checkDuplicatesForMergeArray = (floors: any) => {
    const checkInternalDuplicates = (array: any) => {
      return array.map((floor: any, i: any) => {
        let isDuplicate = false
        let errorMessage = ''

        array.forEach((otherFloor: any, j: any) => {
          if (i !== j && floor?.floorType === otherFloor?.floorType && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
            const isOverlapping = !(floor?.floorEndRange < otherFloor?.floorStartRange || floor?.floorStartRange > otherFloor?.floorEndRange)
            if (isOverlapping) {
              isDuplicate = true
              errorMessage = 'Floor Name is duplicated'
            }
          }
        })

        return {...floor, isDuplicate, errorMessage}
      })
    }

    const updatedFloors = checkInternalDuplicates(floors)

    updatedFloors?.forEach((floor1: any, i: any) => {
      if (!floor1?.floorType?.value) {
        floor1.isDuplicate = false
        floor1.errorMessage = ''
        return // Skip this iteration if the floor1 item is empty
      }

      for (let range = floor1.floorStartRange; range <= floor1.floorEndRange; range++) {
        updatedFloors.forEach((floor2: any, j: any) => {
          // console.log(floor2)
          if (floor1.floorType.value === floor2.floorType) {
            floor2.floorList.forEach((floorListItem: any) => {
              if (floorListItem.name?.includes('-')) {
                const [baseName, suffix] = floorListItem.name.toLowerCase().split('-')
                const isDuplicate = baseName === floor1.name.toLowerCase() && parseInt(suffix, 10) === range

                if (isDuplicate) {
                  updatedFloors[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              } else {
                const floorNameWithIndex = `${floor1.name.toLowerCase()} ${range}`
                if (floorNameWithIndex === floorListItem.name.toLowerCase()) {
                  updatedFloors[i] = {...floor1, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                  updatedFloors[j] = {...floor2, isDuplicate: true, errorMessage: 'Floor Name is duplicated'}
                }
              }
            })
          }
        })
      }
    })

    return updatedFloors
  }

  // const checkDuplicatesForMergeArray = (floors: any[]) => {
  //   return floors.map((floor: any, i: number) => {
  //     let isDuplicate = false;
  //     let errorMessage = '';

  //     floors.forEach((otherFloor: any, j: number) => {
  //       if (i !== j) {
  //         if (floor?.subListRowUI && otherFloor?.subListRowUI) {
  //           // Compare subListRowUI items
  //           if (floor?.floorType === otherFloor?.floorType && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
  //             const isOverlapping = !(floor?.floorEndRange < otherFloor?.floorStartRange || floor?.floorStartRange > otherFloor?.floorEndRange);
  //             if (isOverlapping) {
  //               isDuplicate = true;
  //               errorMessage = 'Floor Name is duplicated';
  //             }
  //           }
  //         } else if (!floor?.subListRowUI && !otherFloor?.subListRowUI) {
  //           // Compare noSubListRowUI items
  //           if (floor?.floorType.value === otherFloor?.floorType?.value && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
  //             const isOverlapping = !(floor?.floorEndRange < otherFloor?.floorStartRange || floor?.floorStartRange > otherFloor?.floorEndRange);
  //             if (isOverlapping) {
  //               isDuplicate = true;
  //               errorMessage = 'Floor Name is duplicated';
  //             }
  //           }
  //         } else if (floor?.subListRowUI && !otherFloor?.subListRowUI) {
  //           // Compare subListRowUI item with noSubListRowUI item
  //           if (floor?.floorType === otherFloor?.floorType?.value && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
  //             for (let range = otherFloor.floorStartRange; range <= otherFloor.floorEndRange; range++) {
  //               const floorNameWithIndex = `${otherFloor.name.toLowerCase()} ${range}`;
  //               if (floorNameWithIndex === floor?.name?.toLowerCase()) {
  //                 isDuplicate = true;
  //                 errorMessage = 'Floor Name is duplicated';
  //                 break;
  //               }
  //             }
  //           }
  //         } else if (!floor?.subListRowUI && otherFloor?.subListRowUI) {
  //           // Compare noSubListRowUI item with subListRowUI item
  //           if (floor?.floorType?.value === otherFloor?.floorType && floor?.name?.toLowerCase() === otherFloor?.name?.toLowerCase()) {
  //             console.log('run')
  //             for (let range = floor.floorStartRange; range <= floor.floorEndRange; range++) {
  //               const floorNameWithIndex = `${floor.name.toLowerCase()} ${range}`;
  //               if (floorNameWithIndex === otherFloor?.name?.toLowerCase()) {
  //                 isDuplicate = true;
  //                 errorMessage = 'Floor Name is duplicated';
  //                 break;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     });

  //     return { ...floor, isDuplicate, errorMessage };
  //   });
  // };

  const checkForDuplicatesForMixedCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  const swapRowForForMergeArray = (prevInd: any, nextInd: any, index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    values[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = valuesSections
    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray

      let temp = updatedValuesSections[prevInd].sortIndex
      updatedValuesSections[prevInd].sortIndex = updatedValuesSections[nextInd].sortIndex
      updatedValuesSections[nextInd].sortIndex = temp

      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // console.log(updatedValuesSections)
      // Assign the updated array back to the structure
      updatedValues[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValuesSections

      setAllCluster(updatedValues)
      setIsCTAHidden(false)
    }, 200)
  }

  const floorOptionsForMergeFloors = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    let options: any = []
    let updatedOptions = floorOptions

    const cluster = allCluster[index]?.updateMixedCluster

    const selectedFloorTypes = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.mergeFloorArray
      ?.filter((floorItem: any) => floorItem?.subListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType))
      ?.map((floorItem: any) => floorItem?.floorType)

    // Check if there are selected floors with floorType 0, 1, 2, or 3 in buildingDevelopmentFloor
    const selectedFloorTypesForAdd = cluster?.[clusterIndex]?.updateBuilding?.[buildingIndex]?.mergeFloorArray
      ?.filter((floorItem: any) => floorItem?.noSubListRowUI)
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType?.value))
      ?.map((floorItem: any) => floorItem?.floorType?.value)

    if ((selectedFloorTypes && selectedFloorTypes.length > 0) || (selectedFloorTypesForAdd && selectedFloorTypesForAdd.length > 0)) {
      // Combine both selected floor types into one set to ensure unique values
      const combinedSelectedFloorTypes = new Set([...(selectedFloorTypes || []), ...(selectedFloorTypesForAdd || [])])

      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !combinedSelectedFloorTypes.has(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const handleRemoveMergeRow = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, key: any) => {
    //  key = 1 => buildingDevelopmentFloor
    //  key = 2 => buildingDevelopmentUpdateFloor
    const updateCluster = [...allCluster]
    const values = [...updateCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray]
    if (key === 1) {
      values.splice(floorIndex, 1)
      updateCluster[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
      setAllCluster(updateCluster)
      // setBuildingDevelopmentFloor(values)
    }
    if (key === 2) {
      const floorDeleteListTemp = updateCluster[index].updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.deleteFloor
      const floorlistIds = updateCluster[index].updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[
        floorIndex
      ].floorList?.map((item: any) => ({_id: item._id}))

      updateCluster[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].deleteFloor = [...floorDeleteListTemp, ...floorlistIds]

      values.splice(floorIndex, 1)
      updateCluster[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = values
      setAllCluster(updateCluster)
      // setBuildingDevelopmentUpdateFloor(valuesForUpdate)
    }

    // let sortedArr:any = values?.sort((a: any, b: any) => {
    //   if (a.sortIndex < b.sortIndex) return -1
    //   if (a.sortIndex > b.sortIndex) return 1
    //   return 0
    // })

    // const {addFloor,updateFloor} = generateFloorForEditBuilding(sortedArr)
    // updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].addFloor = addFloor
    // updateCluster[index].updateBuildingCluster[clusterIndex].updateBuilding[buildingIndex].updateFloor = updateFloor
    // setCluster(updateCluster)

    const updatedValues = checkDuplicatesForMergeArray(values)
    updateCluster[index].updateMixedCluster[clusterIndex].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
    setAllCluster(updateCluster)
    setIsCTAHidden(false)

    // const {updatedFloors1, updatedFloors2} = checkForDuplicates(values, valuesForUpdate)
    // setBuildingDevelopmentFloor(updatedFloors1)
    // setBuildingDevelopmentUpdateFloor(updatedFloors2)
  }

  function splitTextAndNumber(input: any) {
    // Regular expression to match text and number parts
    const match = input.match(/^([a-zA-Z]+)(\d+)?$/)

    if (match) {
      const textPart = match[1]
      const numberPart = match[2] ? match[2] : null // numberPart will be null if there's no number
      return {textPart, numberPart}
    } else {
      return null // Input doesn't match the expected pattern
    }
  }

  const checkBeforeDelete = async () => {
    const developmentURL = location?.state?.buildingId
      ? `corporate/building/deletion_checker/${location?.state?.buildingId}`
      : `corporate/communities/deletion_checker/${location?.state?.communityId}`
    setIsLoading(true)
    await ApiGet(developmentURL)
      .then((res) => {
        setIsLoading(false)
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy: any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        // let paymentStatus = res?.data?.data?.validation?.paymentData?.[0]?.paymentStatus
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if (tenancyStatus.includes(0) || tenancyStatus.includes(1) || (tenancyStatus.includes(2) && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false)) {
          // ErrorToast(
          //   'Unable To Delete Development. The properties under this development have ongoing activity. To delete it you must ensure all activities are cancelled or completed.'
          // )
          swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Development</div>
          <div class='mt-4'>The properties under this development have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
        } else {
          swal
            .fire({
              html: `
          <div class='fs-4 mt-4 fw-bold'>
          Delete Development
           </div>
              <div class='fs-5 mt-4'>
              You are about to delete this entire development. Deleting this development will also delete all existing properties added to it.
              </div>
              <div class='mt-4 fs-5 fw-bold'>
              Are you sure you want to continue?
              </div>
  `,
              // icon: 'warning',
              showConfirmButton: true,
              confirmButtonColor: '#fff',
              confirmButtonText: 'Yes',
              showCancelButton: true,
              cancelButtonText: 'No',
              cancelButtonColor: '#0d9e91',
              customClass: {
                confirmButton: 'custom-confirm-button-2',
                cancelButton: 'custom-cancel-button-2',
                popup: 'custom-popup',
              },
              reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
            })
            .then((res: any) => {
              if (res.isConfirmed) {
                handleRemoveDevelopment()
              }
            })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //   const checkBeforeDeleteForBuildingDevelpomentFloor = async (floorId:any) => {
  //     const developmentURL = `corporate/floor/deletion_checker/${location?.state?.buildingId}`
  //       setIsLoading(true)
  //     await ApiGet(developmentURL)
  //       .then((res) => {
  //         setIsLoading(false)
  //         let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
  //         // let paymentStatus = res?.data?.data?.validation?.paymentData?.[0]?.paymentStatus
  //         let isAbleToDelete = res?.data?.data?.isAbleToDelete
  //         if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2) && isAbleToDelete === false) {
  //           ErrorToast('Unable To Delete Development. The properties under this development have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
  //         }else{
  //           swal.fire({
  //             html: `
  //         <div class='fs-4 mt-4 fw-bold'>
  //         Delete Development
  //          </div>
  //             <div class='fs-5 mt-4'>
  //             You are about to delete this entire development. Deleting this development will also delete all existing properties added to it.
  //             </div>
  //             <div class='mt-4 fs-5 fw-bold'>
  //             Are you sure you want to continue?
  //             </div>
  // `,
  //             // icon: 'warning',
  //             showConfirmButton: true,
  //             confirmButtonColor: '#fff',
  //             confirmButtonText: 'Yes',
  //             showCancelButton: true,
  //             cancelButtonText: 'No',
  //             cancelButtonColor: '#0d9e91',
  //             customClass: {
  //                 confirmButton: 'custom-confirm-button-2',
  //                 cancelButton: 'custom-cancel-button-2',
  //                 popup: 'custom-popup',
  //             },
  //             reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
  //         })
  //             .then((res: any) => {
  //                 if (res.isConfirmed) {
  //                   handleRemoveDevelopment()

  //                 }
  //             })
  //         }
  //       })
  //       .catch((err) => {
  //         ErrorToast(err.message)
  //         if (err?.message === 'Your account has been suspended!') {
  //           window.location.pathname = '/auth'
  //           localStorage.clear()
  //         }
  //       })

  //   }

  const checkBeforeDeleteForUnitCluster = async (index: any, unitIndex: any) => {
    const clusterID = allCluster[index].updateUnitCluster[unitIndex]?._id
    setIsLoading(true)
    await ApiGet(`corporate/cluster/deletion_checker/${clusterID}`)
      .then((res) => {
        setIsLoading(false)
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy: any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if (tenancyStatus.includes(0) || tenancyStatus.includes(1) || (tenancyStatus.includes(2)   && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false)) {
          // ErrorToast(
          //   'Unable To Delete Cluster. The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.'
          // )
          swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Cluster</div>
          <div class='mt-4'>The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
        } else {
          swal
            .fire({
              html: `
                      <div class='fs-4 mt-4 fw-bold'> 
                      Delete Unit Cluster
                        </div>
                      <div class='fs-5 mt-4'> 
                          You are about to delete this unit cluster.
                        </div>
                          <div class='mt-1 fs-5'>
                          Deleting this unit cluster will also delete all existing properties added to it.
                          </div>
                          <div class='mt-4 fs-5 fw-bold'>
                          Are you sure you want to continue?
                          </div>
                              `,
              // icon: 'warning',
              showConfirmButton: true,
              confirmButtonColor: '#fff',
              confirmButtonText: 'Yes',
              showCancelButton: true,
              cancelButtonText: 'No',
              cancelButtonColor: '#0d9e91',
              customClass: {
                confirmButton: 'custom-confirm-button-2',
                cancelButton: 'custom-cancel-button-2',
                popup: 'custom-popup',
              },
              reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
            })
            .then((res: any) => {
              if (res.isConfirmed) {
                handleRemoveBackendInput(index, unitIndex)
              }
            })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const checkBeforeDeleteForMixedCluster = async (index: any, clusterIndex: any) => {
    const clusterID = allCluster[index].updateMixedCluster[clusterIndex]?._id
    await ApiGet(`corporate/cluster/deletion_checker/${clusterID}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy: any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if (tenancyStatus.includes(0) || tenancyStatus.includes(1) || (tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false)) {
          // ErrorToast(
          //   'Unable To Delete Cluster. The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.'
          // )
          swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Cluster</div>
          <div class='mt-4'>The properties under this cluster have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
        } else {
          swal
            .fire({
              html: `
                                                          <div class='fs-4 mt-4 fw-bold'> 
                                                          Delete Mixed Cluster
                                                            </div>
                                                          <div class='fs-5 mt-4'> 
                                                          You are about to delete this mixed cluster.
                                                            </div>
                                                              <div class='mt-1 fs-5'>
                                                              Deleting this mixed cluster will also delete all existing unit groups, buildings and properties added to it.
                                                              </div>
                                                              <div class='mt-4 fs-5 fw-bold'>
                                                              Are you sure you want to continue?
                                                              </div>
                                                                    `,
              // icon: 'warning',
              showConfirmButton: true,
              confirmButtonColor: '#fff',
              confirmButtonText: 'Yes',
              showCancelButton: true,
              cancelButtonText: 'No',
              cancelButtonColor: '#0d9e91',
              customClass: {
                confirmButton: 'custom-confirm-button-2',
                cancelButton: 'custom-cancel-button-2',
                popup: 'custom-popup',
              },
              reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
            })
            .then((res: any) => {
              if (res.isConfirmed) {
                handleRemoveMixedCluster(index, clusterIndex, 2)
              }
            })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const checkBeforeDeleteForBuilding = async (index:any,clusterIndex:any,buildingIndex:any) => {
    const buildingID = allCluster[index].updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?._id
    await ApiGet(`corporate/building/deletion_checker/${buildingID}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
          // ErrorToast('Unable To Delete Building. The properties under this building have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
          swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Building</div>
          <div class='mt-4'>The properties under this building have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
        }else{
          swal
          .fire({
            html: `
                <div class='fs-4 mt-4 fw-bold'> 
                Delete Building
                  </div>
                <div class='fs-5 mt-4'> 
                You are about to delete this building.
                  </div>
                    <div class='mt-1 fs-5'>
                    Deleting this building will also delete all existing floors and properties added to it.
                    </div>
                    <div class='mt-4 fs-5 fw-bold'>
                    Are you sure you want to continue?
                    </div>
                                      `,
            // icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#fff',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            cancelButtonColor: '#0d9e91',
            customClass: {
              confirmButton: 'custom-confirm-button-2',
              cancelButton: 'custom-cancel-button-2',
              popup: 'custom-popup',
            },
            reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
          })
          .then((res: any) => {
            if (res.isConfirmed) {
              handleRemoveBuilingForUpdateMixedCluster(index, clusterIndex, buildingIndex)
            }
          })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })

  }

  const checkBeforeDeleteForUnitGroup = async (index:any,clusterIndex:any,groupIndex:any) => {
    const unitGroupID = allCluster[index]?.updateMixedCluster[clusterIndex]?.updateUnitGroup[groupIndex]?._id
    await ApiGet(`corporate/97240658/deletion_checker/${unitGroupID}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
          // ErrorToast('Unable To Delete Unit Group. The properties under this unit group have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
          swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Unit Group</div>
          <div class='mt-4'>The properties under this unit group have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
        }else{
          swal.fire({
                      html: `
                            <div class='fs-4 mt-4 fw-bold'> 
                            Delete Unit Group
                              </div>
                            <div class='fs-5 mt-4'> 
                            You are about to delete this unit group.
                              </div>
                                <div class='mt-1 fs-5'>
                                Deleting this unit group will also delete all existing properties added to it.
                                </div>
                                <div class='mt-4 fs-5 fw-bold'>
                                Are you sure you want to continue?
                                </div>
                                                                                    `,
                                                              // icon: 'warning',
                                                              showConfirmButton: true,
                                                              confirmButtonColor: '#fff',
                                                              confirmButtonText: 'Yes',
                                                              showCancelButton: true,
                                                              cancelButtonText: 'No',
                                                              cancelButtonColor: '#0d9e91',
                                                              customClass: {
                                                                confirmButton: 'custom-confirm-button-2',
                                                                cancelButton: 'custom-cancel-button-2',
                                                                popup: 'custom-popup',
                                                              },
                                                              reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
                                                            })
                                                            .then((res: any) => {
                                                              if (res.isConfirmed) {
                                                                handleRemoveUnitGroupForUpdateMixedCluster(index, clusterIndex, groupIndex, 2)
                                                              }
                                                            })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })

  }

  const checkBeforeDeleteForFloorMixedCluster = async (index:any,clusterIndex:any,buildingIndex:any,floorIndex:any) => {
     const floorlistIds = allCluster[index].updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[
      floorIndex
    ].floorList?.map((item: any) => ({_id: item._id}))

    const countArr : any = []
    
    for(let i=0;i<floorlistIds?.length;i++){
      await ApiGet(`corporate/floor/deletion_checker/${floorlistIds[i]?._id}`)
      .then((res) => {
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
        let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
        if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
          // ErrorToast('Unable To Delete Unit Group. The properties under this unit group have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
          countArr.push(false)
        }else{
          countArr.push(true)
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
    }
    if(countArr?.length == 0) return
    if(countArr.every((item:any) => item === true)){
      swal.fire({
        html: `
        <div class='fs-4 mt-4 fw-bold'> 
        Delete Floor
        </div>
        <div class='fs-5 mt-4'> 
            You are about to delete this floor.
        </div>
            <div class='mt-1 fs-5'>
            Deleting this floor will also delete all existing properties added to it.
            </div>
            <div class='mt-4 fs-5 fw-bold'>
            Are you sure you want to continue?
            </div>`,
        // icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#fff',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: '#0d9e91',
        customClass: {
          confirmButton: 'custom-confirm-button-2',
          cancelButton: 'custom-cancel-button-2',
          popup: 'custom-popup',
        },
        reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
      })
      .then((res: any) => {
        if (res.isConfirmed) {
          handleRemoveMergeRow(
            index,
            clusterIndex,
            buildingIndex,
            floorIndex,
            2
          )
        }
      })
    }else{
      // ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
      swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Floor</div>
          <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
    }

    // console.log(countArr)

  }

  const checkBeforeDeleteForSingleFloor = async (index:any,clusterIndex:any,buildingIndex:any,floorIndex:any,itemIndex:any) => {
    const floorlistIds =  allCluster[index]?.updateMixedCluster[clusterIndex]?.updateBuilding[buildingIndex]?.mergeFloorArray[floorIndex]?.floorList[itemIndex]?._id

     await ApiGet(`corporate/floor/deletion_checker/${floorlistIds}`)
     .then((res) => {
       let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
       let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
       let isAbleToDelete = res?.data?.data?.isAbleToDelete
       let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
       if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
        //  ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
        swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Floor</div>
          <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
       }else{
        swal.fire({
          html: `
          <div class='fs-4 mt-4 fw-bold'> 
          Delete Floor
          </div>
          <div class='fs-5 mt-4'> 
              You are about to delete this floor.
          </div>
              <div class='mt-1 fs-5'>
              Deleting this floor will also delete all existing properties added to it.
              </div>
              <div class='mt-4 fs-5 fw-bold'>
              Are you sure you want to continue?
              </div>`,
          // icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#fff',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonText: 'No',
          cancelButtonColor: '#0d9e91',
          customClass: {
            confirmButton: 'custom-confirm-button-2',
            cancelButton: 'custom-cancel-button-2',
            popup: 'custom-popup',
          },
          reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
          if (res.isConfirmed) {
            handleRemoveFloorListItemForMergeArray(
              index,
              clusterIndex,
              buildingIndex,
              floorIndex,
              itemIndex
            )
          }
        })
       }
     })
     .catch((err) => {
       ErrorToast(err.message)
       if (err?.message === 'Your account has been suspended!') {
         window.location.pathname = '/auth'
         localStorage.clear()
       }
     })
 }

 const checkBeforeDeleteFloorForBuildingDev = async (floorIndex:any) => {
  const floorlistIds = mergeBuildingDevelopmenFloor[floorIndex]?.floorList?.map((item: any) => ({_id: item._id}))

 const countArr : any = []
 
 for(let i=0;i<floorlistIds?.length;i++){
   await ApiGet(`corporate/floor/deletion_checker/${floorlistIds[i]?._id}`)
   .then((res) => {
     let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
     let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
     let isAbleToDelete = res?.data?.data?.isAbleToDelete
     let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
     if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
       // ErrorToast('Unable To Delete Unit Group. The properties under this unit group have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
       countArr.push(false)
     }else{
       countArr.push(true)
     }
   })
   .catch((err) => {
     ErrorToast(err.message)
     if (err?.message === 'Your account has been suspended!') {
       window.location.pathname = '/auth'
       localStorage.clear()
     }
   })
 }
 if(countArr?.length == 0) return
 if(countArr.every((item:any) => item === true)){
   swal.fire({
     html: `
     <div class='fs-4 mt-4 fw-bold'> 
     Delete Floor
     </div>
     <div class='fs-5 mt-4'> 
         You are about to delete this floor.
     </div>
         <div class='mt-1 fs-5'>
         Deleting this floor will also delete all existing properties added to it.
         </div>
         <div class='mt-4 fs-5 fw-bold'>
         Are you sure you want to continue?
         </div>`,
     // icon: 'warning',
     showConfirmButton: true,
     confirmButtonColor: '#fff',
     confirmButtonText: 'Yes',
     showCancelButton: true,
     cancelButtonText: 'No',
     cancelButtonColor: '#0d9e91',
     customClass: {
       confirmButton: 'custom-confirm-button-2',
       cancelButton: 'custom-cancel-button-2',
       popup: 'custom-popup',
     },
     reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
   })
   .then((res: any) => {
     if (res.isConfirmed) {
      handleRemoveDevelopmenetBuilding(floorIndex, 2)
     }
   })
 }else{
  //  ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
  swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Floor</div>
          <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup custom-popup-error',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
 }

}

const checkBeforeDeleteForSingleFloorForDev = async (floorIndex:any,itemIndex:any) => {
  const floorlistIds =  mergeBuildingDevelopmenFloor[floorIndex]?.floorList[itemIndex]?._id

   await ApiGet(`corporate/floor/deletion_checker/${floorlistIds}`)
   .then((res) => {
     let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy:any) => tenancy?.tenancyStatus)
     let maintenanceServiceRequestData = res?.data?.data?.validation?.maintenanceServiceRequestData?.map((tenancy: any) => tenancy?.maintenanceRequestStatus)
     let isAbleToDelete = res?.data?.data?.isAbleToDelete
     let paymentDataStatus = res?.data?.data?.validation?.paymentData?.length > 0
     if(tenancyStatus.includes(0) || tenancyStatus.includes(1) || tenancyStatus.includes(2)  && maintenanceServiceRequestData.includes(0) || paymentDataStatus || maintenanceServiceRequestData.includes(1) && isAbleToDelete === false) {
      //  ErrorToast('Unable To Delete Floor. The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.')
      swal
        .fire({
          html: `<div class='fnt-700'>Unable To Delete Floor</div>
          <div class='mt-4'>The properties under this floor have ongoing activity. To delete it you must ensure all activities are cancelled or completed.</div>`,
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-popup',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        })
        .then((res: any) => {
                                                 
        })
     }else{
      swal.fire({
        html: `
        <div class='fs-4 mt-4 fw-bold'> 
        Delete Floor
        </div>
        <div class='fs-5 mt-4'> 
            You are about to delete this floor.
        </div>
            <div class='mt-1 fs-5'>
            Deleting this floor will also delete all existing properties added to it.
            </div>
            <div class='mt-4 fs-5 fw-bold'>
            Are you sure you want to continue?
            </div>`,
        // icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#fff',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
        cancelButtonColor: '#0d9e91',
        customClass: {
          confirmButton: 'custom-confirm-button-2',
          cancelButton: 'custom-cancel-button-2',
          popup: 'custom-popup',
        },
        reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
      })
      .then((res: any) => {
        if (res.isConfirmed) {
          handleRemoveFloorListItem(floorIndex, itemIndex)
        }
      })
     }
   })
   .catch((err) => {
     ErrorToast(err.message)
     if (err?.message === 'Your account has been suspended!') {
       window.location.pathname = '/auth'
       localStorage.clear()
     }
   })
}



 

  return (
    <>
      {!isSkeleton ? (
        <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
          <div className='row d-flex align-items-center'>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (location?.state.from === 'communityDetail') {
                          navigate(`/properties/community-details/${location?.state?.communityId}`, {
                            state: {
                              communityName: location?.state?.communityName,
                              area: location?.state?.area,
                              city: location?.state?.city,
                            },
                          })
                        }
                        if (location?.state.from === 'buildingDetail') {
                          navigate(`/properties/building-details/${location?.state?.buildingId}`, {
                            state: {
                              buildingName: location?.state?.buildingName,
                              area: location?.state?.area,
                              city: location?.state?.city,
                            },
                          })
                        }
                      }}
                    >
                      <img src={backArrow} height='14' width='14' className='me-2' />
                    </span>
                    <h2 className='m-0 head-text ms-2'>
                      {!location?.state?.isEdit && <b> Add a Development</b>}
                      {location?.state?.isEdit && <b> Edit Development</b>}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='row'>
                {!isCTAHidden && (
                  <div className='col-12 d-flex align-items-center justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold red-hollow-btn ps-2 ms-auto mt-2'
                      onClick={() => {
                        if (location?.state?.from === 'global') {
                          navigate('/properties')
                        } else if (location?.state?.from === 'building') {
                          navigate(`/properties/building-details/${location?.state?.buildingId}`, {
                            state: {area: location?.state?.area, city: location?.state?.city},
                          })
                        } else if (location?.state?.from === 'communityDetail') {
                          navigate(`/properties/community-details/${location?.state?.communityId}`, {
                            state: {
                              communityName: location?.state?.communityName,
                              area: location?.state?.area,
                              city: location?.state?.city,
                            },
                          })
                        } else if (location?.state.from === 'buildingDetail') {
                          navigate(`/properties/building-details/${location?.state?.buildingId}`, {
                            state: {
                              buildingName: location?.state?.buildingName,
                              area: location?.state?.area,
                              city: location?.state?.city,
                            },
                          })
                        }
                      }}
                    >
                      <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                    </button>
                    <button
                      disabled={selectBuildingCircle ? isDevelopmentBtnDisabled : unitClusterFilled || buildingClusterFilled || mixedClusterFilled}
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn ms-4 ps-2 mt-2'
                      onClick={() => {
                        addDevelopment()
                      }}
                    >
                      <img src={syncIcon} height={18} width={18} className='me-3' /> Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card card-flush  px-7' style={{paddingBottom: '80px', paddingTop: '30px'}}>
            <div className='row' style={{position: 'relative'}}>
              <div className='col-12 d-flex justify-content-end'>
                {/* <img
                  style={{cursor: 'pointer'}}
                  src={trashImg}
                  height={14}
                  width={14}
                  onClick={() => {
                    swal
                      .fire({
                        html: `
                              <div class='fs-4 mt-4 fw-bold'> 
                              Delete Development
                                </div>
                              <div class='fs-5 mt-4'> 
                              You are about to delete this development.
                                </div>
                                  <div class='mt-1 fs-5'>
                                  Deleting the development will also delete all clusters and properties added to it.
                                  </div>
                                  <div class='mt-4 fs-5 fw-bold'>
                                  Are you sure you want to continue?
                                  </div> `,
                        // icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonColor: '#fff',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        cancelButtonColor: '#0d9e91',
                        customClass: {
                          confirmButton: 'custom-confirm-button-2',
                          cancelButton: 'custom-cancel-button-2',
                          popup: 'custom-popup',
                        },
                        reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
                      })
                      .then((res: any) => {
                        if (res.isConfirmed) {
                          handleRemoveDevelopment()
                        }
                      })
                  }}
                /> */}
              </div>
              <div className='col-10 mx-auto'>
                {/* select category */}
                <div className='row mt-7'>
                  <div className='col-12 mx-auto text-center mt-7'>
                    <h2 className='m-0 head-text mb-7'>
                      <b>Development Details</b>
                    </h2>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    {location?.state?.isBuilding !== undefined && location?.state?.isBuilding === true && (
                      <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{position: 'relative'}}>
                          {location?.state?.isCommunity == true && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                              }}
                            ></div>
                          )}
                          <div className={`${selectBuildingCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3 `}>
                            <img src={selectBuildingCircle ? buildingWhiteIcon : buildingGreenIcon} height={80} width={80} className='d-block' />
                            <h2 className={`head-text mt-3 ${selectBuildingCircle ? 'headTextWhite' : ''}`}>
                              <b>Building</b>
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                    {location?.state?.isCommunity !== undefined && location?.state?.isCommunity === true && (
                      <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{position: 'relative'}}>
                          {location?.state?.isBuilding == true && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                              }}
                            ></div>
                          )}
                          <div className={` ${selectCommunityCircle ? 'requestBoxColor box-circle ' : 'requestBox box-circle'} mt-3 `}>
                            <img
                              src={selectCommunityCircle ? communitiesWhiteIcon : communitiesGreenIcon}
                              height={80}
                              width={80}
                              className='d-block'
                            />
                            <h2 className={`head-text mt-3 ${selectCommunityCircle ? 'headTextWhite' : ''}`}>
                              <b>Community</b>
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {selectCommunityCircle && (
                  <div className='row my-7'>
                    <div className='col-10 mx-auto d-flex align-items-center justify-content-between'>
                      <div className='me-4'>
                        <h3 className='m-0 head-text mb-1 ms-1'>Community Name</h3>
                        <div className='wxyz'>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              placeholder='Enter community name'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Community Name'}}
                              value={communityName}
                              onChange={(e: any) => {
                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                setCommunityName(captilizeWord)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                        </div>
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>City</h3>

                        <Select
                          styles={customStyles}
                          isSearchable={false}
                          options={cityOptions}
                          name='city'
                          value={selectCity}
                          onChange={(e: any) => {
                            setSelectCity(e)
                            setIsCTAHidden(false)
                          }}
                          placeholder={'Select City'}
                        />
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>Area</h3>

                        <Select
                          styles={customStyles}
                          isSearchable={false}
                          options={areaOptions}
                          name='area'
                          value={selectArea}
                          onChange={(e: any) => {
                            setSelectArea(e)
                            setIsCTAHidden(false)
                          }}
                          placeholder={'Select Area'}
                        />
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>Street</h3>
                        <div className='wxyz'>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              placeholder='Enter street name'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Street name'}}
                              value={streetName}
                              onChange={(e: any) => {
                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                setStreetName(captilizeWord)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectBuildingCircle && (
                  <div className='row my-7'>
                    <div className='col-10 mx-auto d-flex align-items-center justify-content-between'>
                      <div className='me-4'>
                        <h3 className='m-0 head-text mb-1 ms-1'>Building Name</h3>
                        <div className='wxyz'>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              placeholder='Enter building name'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Building Name'}}
                              value={buildingName}
                              onChange={(e: any) => {
                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                setBuildingName(captilizeWord)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                        </div>
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>City</h3>

                        <Select
                          styles={customStyles}
                          isSearchable={true}
                          options={cityOptions}
                          value={selectBuildingCity}
                          name='city'
                          onChange={(e: any) => {
                            setSelectBuildingCity(e)
                            setIsCTAHidden(false)
                          }}
                          placeholder={'Select City'}
                        />
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>Area</h3>

                        <Select
                          styles={customStyles}
                          isSearchable={true}
                          options={areaOptions}
                          value={selectBuildingArea}
                          name='area'
                          onChange={(e: any) => {
                            setSelectBuildingArea(e)
                            setIsCTAHidden(false)
                          }}
                          placeholder={'Select Area'}
                        />
                      </div>

                      <div className='me-4 '>
                        <h3 className='m-0 head-text mb-1 ms-1'>Street</h3>
                        <div className='wxyz'>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              placeholder='Enter street name'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Street name'}}
                              value={streetBuildingName}
                              onChange={(e: any) => {
                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                setStreetBuildingName(captilizeWord)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* select community cluster */}
                {selectCommunityCircle && (
                  <div className='row mt-7'>
                    <div className='col-12 mx-auto text-center mt-7'>
                      <h2 className='m-0 head-text mb-2'>
                        <b>Community Clusters</b>
                      </h2>
                    </div>
                    {allCluster?.map((item: any, ind: any) => (
                      <>
                        <div
                          className='row d-flex flex-column justify-content-center align-items-center'
                          style={{marginTop: '50px', position: 'relative'}}
                        >
                          <div
                            className='col-4'
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            {allCluster[ind].unitBlur === true && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  backgroundColor: 'rgba(255,255,255,0.5)',
                                }}
                              ></div>
                            )}
                            <div className={`${item.unitCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}>
                              <img src={item.unitCircle ? unitClusterWhiteIcon : unitClusterGreenIcon} height={80} width={80} className='d-block' />
                              <h2 className={`head-text mt-3 ${item.unitCircle ? 'headTextWhite' : ''}`}>
                                <b>Unit Clusters</b>
                              </h2>
                            </div>
                          </div>
                          <div style={{marginBottom: '50px'}}>
                            {allCluster[ind].unitCircle && (
                              <EditUnitCluster
                                setIsCTAHidden={setIsCTAHidden}
                                allCluster={allCluster}
                                setCluster={setAllCluster}
                                index={ind}
                                handleAddUnitInput={handleAddUnitInput}
                                handleRemoveUnitInput={handleRemoveUnitInput}
                                // handleRemoveBackendInput={(index: any, unitIndex: any) => {
                                //   swal
                                //     .fire({
                                //       html: `
                                //                                 <div class='fs-4 mt-4 fw-bold'>
                                //                                 Delete Unit Cluster
                                //                                  </div>
                                //                                 <div class='fs-5 mt-4'>
                                //                                     You are about to delete this unit cluster.
                                //                                  </div>
                                //                                     <div class='mt-1 fs-5'>
                                //                                     Deleting this unit cluster will also delete all existing properties added to it.
                                //                                     </div>
                                //                                     <div class='mt-4 fs-5 fw-bold'>
                                //                                     Are you sure you want to continue?
                                //                                     </div>
                                //                     `,
                                //       // icon: 'warning',
                                //       showConfirmButton: true,
                                //       confirmButtonColor: '#fff',
                                //       confirmButtonText: 'Yes',
                                //       showCancelButton: true,
                                //       cancelButtonText: 'No',
                                //       cancelButtonColor: '#0d9e91',
                                //       customClass: {
                                //         confirmButton: 'custom-confirm-button-2',
                                //         cancelButton: 'custom-cancel-button-2',
                                //         popup: 'custom-popup',
                                //       },
                                //       reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
                                //     })
                                //     .then((res: any) => {
                                //       if (res.isConfirmed) {
                                //         handleRemoveBackendInput(index, unitIndex)
                                //       }
                                //     })
                                // }}
                                handleRemoveBackendInput={(index: any, unitIndex: any) => {
                                  checkBeforeDeleteForUnitCluster(index, unitIndex)
                                }}
                              />
                            )}
                          </div>
                          <div
                            className='col-4'
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            {allCluster[ind].buildingBlur === true && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  backgroundColor: 'rgba(255,255,255,0.5)',
                                }}
                              ></div>
                            )}
                            <div className={`${item.buildingCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}>
                              <img
                                src={item.buildingCircle ? buildingClusterWhiteIcon : buildingClusterGreenIcon}
                                height={80}
                                width={80}
                                className='d-block'
                              />
                              <h2 className={`head-text mt-3 ${item.buildingCircle ? 'headTextWhite' : ''}`}>
                                <b>Building Clusters</b>
                              </h2>
                            </div>
                          </div>
                          <div style={{marginBottom: '50px'}}>
                            {allCluster[ind].buildingCircle && (
                              <EditBuildingCluster
                                setIsCTAHidden={setIsCTAHidden}
                                allCluster={allCluster}
                                index={ind}
                                setCluster={setAllCluster}
                                floorOptions={floorOptions}
                                buildingCluster={buildingCluster}
                                setBuildingCluster={setBuildingCluster}
                                addFloorHandler={addFloorHandler}
                                handleAddBuildingCluster={handleAddBuildingCluster}
                                handleAddBuildingForBuildingCluster={handleAddBuildingForBuildingCluster}
                                handleAddBuildingForBuildingClusterUpdate={handleAddBuildingForBuildingClusterUpdate}
                                handleRemoveBuildingCluster={handleRemoveBuildingCluster}
                                handleRemoveBuildingClusterBuilding={handleRemoveBuildingClusterBuilding}
                              />
                            )}
                          </div>
                          <div
                            className='col-4'
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            {allCluster[ind].mixedBlur === true && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  backgroundColor: 'rgba(255,255,255,0.5)',
                                }}
                              ></div>
                            )}
                            <div className={`${item.mixedCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}>
                              <img
                                src={item.mixedCircle ? mixedClusterWhiteIcon : mixedClusterGreenIcon}
                                height={80}
                                width={80}
                                className='d-block'
                              />
                              <h2 className={`head-text mt-3 ${item.mixedCircle ? 'headTextWhite' : ''}`}>
                                <b>Mixed Clusters</b>
                              </h2>
                            </div>
                          </div>
                        </div>

                        {allCluster[ind].mixedCircle && (
                          <div className='row' style={{marginTop: '50px'}}>
                            <div className='col-10 mx-auto text-center mb-2'>
                              <h2 className='m-0 head-text'>
                                <b>Mixed Clusters</b>
                              </h2>
                            </div>
                            <div className='col-12 mx-auto text-center'>
                              <h4 className='m-0 head-text mb-1'>Set the unit clusters existing within your community</h4>
                              <h4 className='m-0 head-text mb-1'>
                                You will be able to add the properties under each unit cluster once they have been added.
                              </h4>

                              <div className='my-7 d-flex justify-content-center'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold px-2 green-submit-btn '
                                  style={{width: 'fit-content'}}
                                  onClick={() => handleAddMixedCluster(ind)}
                                >
                                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Mixed Cluster
                                </button>
                              </div>

                              <div className='col-12 mx-auto' style={{marginTop: '20px'}}>
                                <div className='row d-flex' style={{rowGap: '10px'}}>
                                  {allCluster[ind]?.updateMixedCluster?.map((clusterItem: any, mixedClusterIndex: any) => (
                                    <div className='col-12'>
                                      <div
                                        className='card card-flush'
                                        style={{
                                          padding: '10px',
                                          position: 'relative',
                                          marginBottom: '50px',
                                        }}
                                      >
                                        {allCluster[ind]?.updateMixedCluster?.length >= 1 && (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              top: '2px',
                                              right: '12px',
                                            }}
                                          >
                                            <img
                                              style={{cursor: 'pointer'}}
                                              src={trashImg}
                                              height={14}
                                              width={14}
                                              onClick={() => {
                                                checkBeforeDeleteForMixedCluster(ind, mixedClusterIndex)
                                              }}
                                            />
                                          </div>
                                        )}
                                        <div className='row'>
                                          <div className='text-center col-3 mx-auto'>
                                            <h3 className='m-0 head-text mb-1 ms-1'>Mixed Cluster</h3>
                                            <div className='wxyz'>
                                              <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                  placeholder='Enter Mixed Cluster Name'
                                                  style={inputBaseStyle}
                                                  inputProps={{'aria-label': 'Mixed Cluster'}}
                                                  value={clusterItem?.name}
                                                  onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[ind].updateMixedCluster[mixedClusterIndex].name = captilizeWord
                                                    setAllCluster(values)
                                                    setIsCTAHidden(false)
                                                  }}
                                                />
                                              </Paper>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='row'>
                                          <div
                                            className='text-center my-7  d-flex justify-content-center align-items-start'
                                            style={{columnGap: '20px', padding: '2rem'}}
                                          >
                                            <div className='status-w-280'>
                                              <div className='d-flex justify-content-center align-item'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                  style={{width: 'fit-content'}}
                                                  onClick={() => handleAddUnitGroupForUpdateCluster(ind, mixedClusterIndex)}
                                                >
                                                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Unit Group
                                                </button>
                                              </div>
                                              <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                                Edit existing unit groups or add new unit groups to this cluster. You will be able to add the
                                                properties for each new unit group after they have been added.
                                              </p>
                                            </div>
                                            <div className='status-w-280'>
                                              <div className='d-flex justify-content-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                  style={{width: 'fit-content'}}
                                                  onClick={() => handleAddBuildingForUpdateMixedCluster(ind, mixedClusterIndex)}
                                                >
                                                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                                                </button>
                                              </div>
                                              <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                                Edit existing buildings added to this cluster or add new buildings to this cluster. You will be able
                                                to add the properties for each new building after they have been added.
                                              </p>
                                            </div>
                                          </div>

                                          <div className='col-12'>
                                            {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.updateUnitGroup?.map(
                                              (groupItem: any, groupIndex: any) => (
                                                <div
                                                  className='card card-flush'
                                                  style={{
                                                    padding: '10px',
                                                    position: 'relative',
                                                    marginBottom: '20px',
                                                  }}
                                                >
                                                  {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.updateUnitGroup?.length >= 1 && (
                                                    <div
                                                      style={{
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '32px',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <img
                                                        src={trashImg}
                                                        height={14}
                                                        width={14}
                                                        onClick={() => {
                                                          checkBeforeDeleteForUnitGroup(ind, mixedClusterIndex, groupIndex)
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                  <div className='row'>
                                                    <div className='col-12'>
                                                      <div className='me-4'>
                                                        <h3 className='m-0 head-text mb-1 ms-1 text-start'>Unit Group</h3>
                                                        <div className='wxyz' style={{width: '200px'}}>
                                                          <Paper component='form' style={searchContainerStyle}>
                                                            <InputBase
                                                              placeholder='Enter Unit Group Name'
                                                              style={inputBaseStyle}
                                                              inputProps={{
                                                                'aria-label': 'Unit Group',
                                                              }}
                                                              value={groupItem?.name}
                                                              onChange={(e: any) => {
                                                                const values = [...allCluster]
                                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                values[ind].updateMixedCluster[mixedClusterIndex].updateUnitGroup[groupIndex].name =
                                                                  captilizeWord
                                                                setAllCluster(values)
                                                                setIsCTAHidden(false)
                                                              }}
                                                            />
                                                          </Paper>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.updateBuilding?.map(
                                              (buildingItem: any, buildingIndex: any) => (
                                                <div
                                                  className='card card-flush'
                                                  style={{
                                                    padding: '10px',
                                                    position: 'relative',
                                                    marginBottom: '20px',
                                                  }}
                                                >
                                                  <div className='row'>
                                                    <div className='col-12  d-flex align-items-start'>
                                                      <div className='me-4' style={{width: '200px'}}>
                                                        <h3 className='m-0 head-text mb-1 ms-1 text-start'>Building</h3>
                                                        <div className='wxyz'>
                                                          <Paper component='form' style={searchContainerStyle}>
                                                            <InputBase
                                                              placeholder='Enter Building Name'
                                                              style={inputBaseStyle}
                                                              inputProps={{
                                                                'aria-label': 'Building name',
                                                              }}
                                                              value={buildingItem?.name}
                                                              onChange={(e: any) => {
                                                                const values = [...allCluster]
                                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                values[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[buildingIndex].name =
                                                                  captilizeWord
                                                                setAllCluster(values)
                                                                setIsCTAHidden(false)
                                                              }}
                                                            />
                                                          </Paper>
                                                        </div>
                                                      </div>
                                                      <div className='text-center'>
                                                        <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                                        <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                                          {`${calculateTotalFloorsForUpdateBuilding(ind, mixedClusterIndex, buildingIndex)}`}
                                                        </h3>
                                                      </div>
                                                      {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.updateBuilding?.length >= 1 && (
                                                        <div className='d-flex justify-content-end flex-grow-1'>
                                                          <img
                                                            style={{cursor: 'pointer'}}
                                                            src={trashImg}
                                                            height={14}
                                                            width={14}
                                                            onClick={() => {
                                                              checkBeforeDeleteForBuilding(ind,mixedClusterIndex,buildingIndex)
                                                            }}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>

                                                  {/* add floor */}
                                                  <div className='row my-7'>
                                                    <div className='col-12'>
                                                      <div className='d-flex align-items-center'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4 px-2'
                                                          onClick={() => addFloorHandlerForMergeArray(ind, mixedClusterIndex, buildingIndex)}
                                                          style={{width: '120px'}}
                                                        >
                                                          Add Floor
                                                        </button>

                                                        <p className='m-0 head-text text-start'>
                                                          Position each floor in the order you would like them to be sorted in your property tables.
                                                          Once added, the tables will be sorted based on the order of each floor set below along with
                                                          the properties added under each floor.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* floor start */}
                                                  <div className='row'>
                                                    <div className='col-12'>
                                                      <table className='table table-borderless'>
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              className='head-text text-start p-0 pb-2'
                                                              style={{maxWidth: '20px', minWidth: '20px'}}
                                                            ></th>
                                                            <th className='head-text text-start pb-2' style={{minWidth: '170px', maxWidth: '170px'}}>
                                                              Floors
                                                              <span className='ms-2'>
                                                                <OverlayTrigger
                                                                  placement='right'
                                                                  delay={{show: 250, hide: 200}}
                                                                  overlay={
                                                                    <Tooltip id='button-tooltip-2'>
                                                                      {' '}
                                                                      <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be
                                                                      included at the beginning of each floor added, giving each floor it’s own name.
                                                                      If you do not wish to include a prefix, you may leave this field blank. You will
                                                                      be able to edit any floor name once it has been added.
                                                                      <br></br>
                                                                      <b>Floor Range</b>: Set the range of floors within the selected floor type. The
                                                                      floor numbers will start and end based on the values included in the ‘Start
                                                                      Range’ and ‘End Range. This will allow you to reflect the same floor names
                                                                      within your building. If you are adding a single floor your ‘Start Range’ and ‘
                                                                      End Range’ should include the same value.
                                                                      <br></br>
                                                                      <b>Total Floors</b>: Calculates the total number of floors set in the floor
                                                                      range. Both ‘Start Range’ and ‘End Range’ will be included in the total count.
                                                                    </Tooltip>
                                                                  }
                                                                >
                                                                  <span>
                                                                    {' '}
                                                                    <AiOutlineExclamationCircle
                                                                      color='#146C6A '
                                                                      fontSize={14}
                                                                      fontWeight={500}
                                                                      className='cursor-pointer'
                                                                      style={{cursor: 'pointer', marginBottom: '2px'}}
                                                                    />
                                                                  </span>
                                                                </OverlayTrigger>
                                                              </span>
                                                            </th>
                                                            <th
                                                              className='head-text text-start p-0 ps-7 pb-2'
                                                              style={{minWidth: '100px', maxWidth: '100px'}}
                                                            >
                                                              Floor Name
                                                            </th>
                                                            <th className='head-text text-start p-0 ps-5 pb-2' style={{minWidth: '220px'}}></th>
                                                            <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '50px'}}>
                                                              Example
                                                            </th>
                                                            <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '100px'}}>
                                                              Total Floors
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className='text-start'>
                                                          {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.updateBuilding[
                                                            buildingIndex
                                                          ]?.mergeFloorArray
                                                            ?.sort((a: any, b: any) => {
                                                              if (a.sortIndex < b.sortIndex) return -1
                                                              if (a.sortIndex > b.sortIndex) return 1
                                                              return 0
                                                            })
                                                            ?.map((floorItem: any, floorIndex: any) => {
                                                              if (floorItem?.subListRowUI) {
                                                                return (
                                                                  <tr
                                                                    key={floorIndex}
                                                                    className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}
                                                                  >
                                                                    <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                                                      <span className={`${floorItem.floorList.length > 1 ? 'me-3' : ''}`}>
                                                                        {floorIndex === 0 && buildingItem?.mergeFloorArray?.length > 1 && (
                                                                          <img
                                                                            onClick={() =>
                                                                              swapRowForForMergeArray(
                                                                                floorIndex,
                                                                                floorIndex + 1,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                            style={{cursor: 'pointer'}}
                                                                            src={carrotArrowDown}
                                                                            height={12}
                                                                            width={12}
                                                                          />
                                                                        )}
                                                                        {floorIndex === buildingItem?.mergeFloorArray?.length - 1 &&
                                                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                                                            <img
                                                                              onClick={() =>
                                                                                swapRowForForMergeArray(
                                                                                  floorIndex - 1,
                                                                                  floorIndex,
                                                                                  ind,
                                                                                  mixedClusterIndex,
                                                                                  buildingIndex,
                                                                                  floorIndex
                                                                                )
                                                                              }
                                                                              style={{cursor: 'pointer'}}
                                                                              src={carrotArrowUp}
                                                                              height={12}
                                                                              width={12}
                                                                            />
                                                                          )}
                                                                        {floorIndex !== 0 && floorIndex !== buildingItem?.mergeFloorArray.length - 1 && (
                                                                          <span className='d-flex flex-column'>
                                                                            <img
                                                                              style={{cursor: 'pointer'}}
                                                                              src={carrotArrowUp}
                                                                              height={12}
                                                                              width={12}
                                                                              onClick={() =>
                                                                                swapRowForForMergeArray(
                                                                                  floorIndex - 1,
                                                                                  floorIndex,
                                                                                  ind,
                                                                                  mixedClusterIndex,
                                                                                  buildingIndex,
                                                                                  floorIndex
                                                                                )
                                                                              }
                                                                            />
                                                                            <img
                                                                              style={{cursor: 'pointer'}}
                                                                              src={carrotArrowDown}
                                                                              height={12}
                                                                              width={12}
                                                                              onClick={() =>
                                                                                swapRowForForMergeArray(
                                                                                  floorIndex,
                                                                                  floorIndex + 1,
                                                                                  ind,
                                                                                  mixedClusterIndex,
                                                                                  buildingIndex,
                                                                                  floorIndex
                                                                                )
                                                                              }
                                                                            />
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    </td>
                                                                    <td className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}>
                                                                      <div>
                                                                        <Select
                                                                          styles={{
                                                                            ...customStyles,
                                                                            container: (defaultStyles: any) => ({
                                                                              ...defaultStyles,
                                                                              width: '100%',
                                                                            }),
                                                                          }}
                                                                          isSearchable={false}
                                                                          // options={floorOptions}
                                                                          options={floorOptionsForMergeFloors(
                                                                            ind,
                                                                            mixedClusterIndex,
                                                                            buildingIndex,
                                                                            floorIndex
                                                                          )}
                                                                          value={
                                                                            floorOptions.filter(
                                                                              (item: any) => item.value == floorItem?.floorType
                                                                            )?.[0]
                                                                          }
                                                                          name='floor'
                                                                          onChange={(e: any) => {
                                                                            const updateCluster = [...allCluster]
                                                                            const values = [
                                                                              ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                            ]
                                                                            values[floorIndex].floorType = e.value
                                                                            values[floorIndex].floorList = values[floorIndex]?.floorList?.map(
                                                                              (item: any, i: any) => ({
                                                                                _id: item._id,
                                                                                floorType: e.value,
                                                                                name: item.name,
                                                                                index: item.index,
                                                                                prefix: item.prefix,
                                                                              })
                                                                            )
                                                                            allCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                              buildingIndex
                                                                            ].mergeFloorArray = values
                                                                            setAllCluster(updateCluster)

                                                                            const updatedValues = checkDuplicatesForMergeArray(values)
                                                                            updateCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                              buildingIndex
                                                                            ].mergeFloorArray = updatedValues
                                                                            setAllCluster(updateCluster)
                                                                            setIsCTAHidden(false)
                                                                          }}
                                                                          placeholder={'Select Floor Type'}
                                                                        />
                                                                      </div>
                                                                      {floorItem.isDuplicate && (
                                                                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                                          Floor Name is duplicated
                                                                        </p>
                                                                      )}
                                                                    </td>
                                                                    <td className='p-0  py-3' style={{minWidth: '100px', maxWidth: '100px'}}>
                                                                      <tr
                                                                        className='d-flex justify-content-between align-items-center'
                                                                        style={{columnGap: '10px'}}
                                                                      >
                                                                        <td className='p-0 ps-7'>
                                                                          <div className='wxyz'>
                                                                            <Paper component='form' style={searchContainerStyle}>
                                                                              <InputBase
                                                                                placeholder='Prefix'
                                                                                style={inputBaseStyle}
                                                                                inputProps={{'aria-label': 'Prefix'}}
                                                                                value={floorItem?.prefix}
                                                                                onChange={(e: any) => {
                                                                                  const updateCluster = [...allCluster]
                                                                                  const values = [
                                                                                    ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                      ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                  ]
                                                                                  // buildingDevelopmentUpdateFloor[floorIndex].prefix = e.target.value
                                                                                  const captilizeWord = capitalizeFirstLetter(
                                                                                    e.target.value?.trimStart()
                                                                                  )
                                                                                  values[floorIndex].prefix = captilizeWord
                                                                                  values[floorIndex].floorList = values[floorIndex]?.floorList.map(
                                                                                    (item: any, i: any) => {
                                                                                      const floorNumbers =
                                                                                        item.name?.split(' ')[1] || item.name?.split('-')[1]
                                                                                      if (item?.name?.match(/^([a-zA-Z]+)(\d+)?$/)) {
                                                                                        return {
                                                                                          _id: item._id,
                                                                                          floorType: item.floorType,
                                                                                          // name: `${e.target.value}-${i + 1}`,
                                                                                          name: item.name,
                                                                                          // name:item.name,
                                                                                          index: item.index,
                                                                                          prefix: captilizeWord,
                                                                                        }
                                                                                      } else {
                                                                                        return {
                                                                                          _id: item._id,
                                                                                          floorType: item.floorType,
                                                                                          // name: `${e.target.value}-${i + 1}`,
                                                                                          name:
                                                                                            values[floorIndex].floorType == 0
                                                                                              ? `${captilizeWord}`
                                                                                              : `${captilizeWord} ${floorNumbers}`,
                                                                                          // name:item.name,
                                                                                          index: item.index,
                                                                                          prefix: captilizeWord,
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  )

                                                                                  allCluster[ind].updateMixedCluster[
                                                                                    mixedClusterIndex
                                                                                  ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                  setAllCluster(updateCluster)

                                                                                  const updatedValues = checkDuplicatesForMergeArray(values)
                                                                                  updateCluster[ind].updateMixedCluster[
                                                                                    mixedClusterIndex
                                                                                  ].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                                                  setAllCluster(updateCluster)
                                                                                  setIsCTAHidden(false)
                                                                                }}
                                                                              />
                                                                            </Paper>
                                                                          </div>
                                                                        </td>
                                                                        <td>
                                                                          <div className=''>
                                                                            <div className='br-arr d-flex align-items-center justify-content-center'>
                                                                              {floorItem.isShowFloors == false ? (
                                                                                <img
                                                                                  src={downGreen}
                                                                                  height={20}
                                                                                  width={20}
                                                                                  className='cursor-pointer'
                                                                                  onClick={() => {
                                                                                    const updateCluster = [...allCluster]
                                                                                    const values = [
                                                                                      ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                        ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                    ]
                                                                                    values[floorIndex].isShowFloors = true
                                                                                    // setMergeBuildingDevelopmenFloor(values)
                                                                                    updateCluster[ind].updateMixedCluster[
                                                                                      mixedClusterIndex
                                                                                    ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                    setAllCluster(updateCluster)
                                                                                  }}
                                                                                />
                                                                              ) : (
                                                                                <img
                                                                                  src={upGreen}
                                                                                  height={20}
                                                                                  width={20}
                                                                                  className='cursor-pointer'
                                                                                  onClick={() => {
                                                                                    const updateCluster = [...allCluster]
                                                                                    const values = [
                                                                                      ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                        ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                    ]
                                                                                    values[floorIndex].isShowFloors = false
                                                                                    updateCluster[ind].updateMixedCluster[
                                                                                      mixedClusterIndex
                                                                                    ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                    setAllCluster(updateCluster)
                                                                                  }}
                                                                                />
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                      <div>
                                                                        {floorItem.isShowFloors && (
                                                                          <>
                                                                            {floorItem?.floorList?.map((item: any, itemIndex: any) => (
                                                                              <div className={`my-2 d-flex align-items-center `}>
                                                                                <span className={`${floorItem?.floorList.length > 1 ? 'me-3' : ''}`}>
                                                                                  {itemIndex === 0 && floorItem?.floorList.length > 1 && (
                                                                                    <img
                                                                                      onClick={() =>
                                                                                        swapItems(
                                                                                          itemIndex,
                                                                                          itemIndex + 1,
                                                                                          ind,
                                                                                          mixedClusterIndex,
                                                                                          buildingIndex,
                                                                                          floorIndex,
                                                                                          itemIndex
                                                                                        )
                                                                                      }
                                                                                      style={{cursor: 'pointer'}}
                                                                                      src={carrotArrowDown}
                                                                                      height={12}
                                                                                      width={12}
                                                                                    />
                                                                                  )}

                                                                                  {itemIndex === floorItem?.floorList.length - 1 &&
                                                                                    floorItem?.floorList.length > 1 && (
                                                                                      <img
                                                                                        onClick={() =>
                                                                                          swapItems(
                                                                                            itemIndex - 1,
                                                                                            itemIndex,
                                                                                            ind,
                                                                                            mixedClusterIndex,
                                                                                            buildingIndex,
                                                                                            floorIndex,
                                                                                            itemIndex
                                                                                          )
                                                                                        }
                                                                                        style={{cursor: 'pointer'}}
                                                                                        src={carrotArrowUp}
                                                                                        height={12}
                                                                                        width={12}
                                                                                      />
                                                                                    )}
                                                                                  {itemIndex !== 0 && itemIndex !== floorItem?.floorList.length - 1 && (
                                                                                    <span className='d-flex flex-column'>
                                                                                      <img
                                                                                        style={{cursor: 'pointer'}}
                                                                                        src={carrotArrowUp}
                                                                                        height={12}
                                                                                        width={12}
                                                                                        onClick={() =>
                                                                                          swapItems(
                                                                                            itemIndex - 1,
                                                                                            itemIndex,
                                                                                            ind,
                                                                                            mixedClusterIndex,
                                                                                            buildingIndex,
                                                                                            floorIndex,
                                                                                            itemIndex
                                                                                          )
                                                                                        }
                                                                                      />
                                                                                      <img
                                                                                        style={{cursor: 'pointer'}}
                                                                                        src={carrotArrowDown}
                                                                                        height={12}
                                                                                        width={12}
                                                                                        onClick={() =>
                                                                                          swapItems(
                                                                                            itemIndex,
                                                                                            itemIndex + 1,
                                                                                            ind,
                                                                                            mixedClusterIndex,
                                                                                            buildingIndex,
                                                                                            floorIndex,
                                                                                            itemIndex
                                                                                          )
                                                                                        }
                                                                                      />
                                                                                    </span>
                                                                                  )}
                                                                                </span>
                                                                                <div
                                                                                  className={`wxyz flex-grow-1 ${item.animationClass} ${
                                                                                    floorItem?.floorList.length == 1 ? 'ps-7' : ''
                                                                                  }`}
                                                                                >
                                                                                  <Paper component='form' style={searchContainerStyle}>
                                                                                    <InputBase
                                                                                      placeholder=''
                                                                                      style={inputBaseStyle}
                                                                                      inputProps={{'aria-label': ''}}
                                                                                      value={item?.name}
                                                                                      onChange={(e: any) => {
                                                                                        const updateCluster = [...allCluster]
                                                                                        const values = [
                                                                                          ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                            ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                        ]
                                                                                        values[floorIndex].floorList[itemIndex].name = e.target.value

                                                                                        updateCluster[ind].updateMixedCluster[
                                                                                          mixedClusterIndex
                                                                                        ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                        // setAllCluster(updateCluster)
                                                                                        // setIsCTAHidden(false)

                                                                                        const updatedValues = checkDuplicatesForMergeArray(values)
                                                                                  updateCluster[ind].updateMixedCluster[
                                                                                    mixedClusterIndex
                                                                                  ].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                                                  setAllCluster(updateCluster)
                                                                                  setIsCTAHidden(false)
                                                                                      }}
                                                                                    />
                                                                                  </Paper>
                                                                                </div>
                                                                                <span className='ms-3'>
                                                                                  <img
                                                                                    style={{cursor: 'pointer'}}
                                                                                    src={trashImg}
                                                                                    height={14}
                                                                                    width={14}
                                                                                    onClick={() => {
                                                                                      checkBeforeDeleteForSingleFloor(ind,
                                                                                        mixedClusterIndex,
                                                                                        buildingIndex,
                                                                                        floorIndex,
                                                                                        itemIndex)
                                                                                      
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                              </div>
                                                                            ))}
                                                                          </>
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      className='p-0  ps-7 pb-7 '
                                                                      style={{minWidth: '200px', maxWidth: '220px'}}
                                                                    ></td>
                                                                    <td className='p-0 ps-7 pb-7 ' style={{minWidth: '50px', maxWidth: '50px'}}></td>

                                                                    <td className='p-0 ps-5 text-center pb-7 min-w-100px' style={{maxWidth: '100px'}}>
                                                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                        <div className='mt-1'>
                                                                          <h3 className='m-0 head-text me-7'>{floorItem?.floorList?.length}</h3>
                                                                        </div>
                                                                        {buildingItem?.mergeFloorArray.length >= 1 && (
                                                                          <img
                                                                            className=''
                                                                            style={{cursor: 'pointer'}}
                                                                            src={trashImg}
                                                                            height={14}
                                                                            width={14}
                                                                            onClick={() => {
                                                                              checkBeforeDeleteForFloorMixedCluster(ind,mixedClusterIndex,buildingIndex,floorIndex)
                                                                            }
                                                                          }
                                                                          />
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              }

                                                              if (floorItem?.noSubListRowUI) {
                                                                return (
                                                                  <tr
                                                                    key={floorIndex}
                                                                    className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}
                                                                  >
                                                                    <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                                                      <span className={`${floorItem.floorList?.length > 1 ? 'me-3' : ''}`}>
                                                                        {floorIndex === 0 && buildingItem?.mergeFloorArray?.length > 1 && (
                                                                          <img
                                                                            onClick={() =>
                                                                              swapRowForForMergeArray(
                                                                                floorIndex,
                                                                                floorIndex + 1,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                            style={{cursor: 'pointer'}}
                                                                            src={carrotArrowDown}
                                                                            height={12}
                                                                            width={12}
                                                                          />
                                                                        )}
                                                                        {floorIndex === buildingItem?.mergeFloorArray?.length - 1 &&
                                                                          buildingItem?.mergeFloorArray?.length > 1 && (
                                                                            <img
                                                                              onClick={() =>
                                                                                swapRowForForMergeArray(
                                                                                  floorIndex - 1,
                                                                                  floorIndex,
                                                                                  ind,
                                                                                  mixedClusterIndex,
                                                                                  buildingIndex,
                                                                                  floorIndex
                                                                                )
                                                                              }
                                                                              style={{cursor: 'pointer'}}
                                                                              src={carrotArrowUp}
                                                                              height={12}
                                                                              width={12}
                                                                            />
                                                                          )}
                                                                        {floorIndex !== 0 &&
                                                                          floorIndex !== buildingItem?.mergeFloorArray?.length - 1 && (
                                                                            <span className='d-flex flex-column'>
                                                                              <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={carrotArrowUp}
                                                                                height={12}
                                                                                width={12}
                                                                                onClick={() =>
                                                                                  swapRowForForMergeArray(
                                                                                    floorIndex - 1,
                                                                                    floorIndex,
                                                                                    ind,
                                                                                    mixedClusterIndex,
                                                                                    buildingIndex,
                                                                                    floorIndex
                                                                                  )
                                                                                }
                                                                              />
                                                                              <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={carrotArrowDown}
                                                                                height={12}
                                                                                width={12}
                                                                                onClick={() =>
                                                                                  swapRowForForMergeArray(
                                                                                    floorIndex,
                                                                                    floorIndex + 1,
                                                                                    ind,
                                                                                    mixedClusterIndex,
                                                                                    buildingIndex,
                                                                                    floorIndex
                                                                                  )
                                                                                }
                                                                              />
                                                                            </span>
                                                                          )}
                                                                      </span>
                                                                    </td>
                                                                    <td className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}>
                                                                      <div>
                                                                        <Select
                                                                          styles={{
                                                                            ...customStyles,
                                                                            container: (defaultStyles: any) => ({
                                                                              ...defaultStyles,
                                                                              width: '100%',
                                                                            }),
                                                                          }}
                                                                          isSearchable={true}
                                                                          options={floorOptionsForMergeFloors(
                                                                            ind,
                                                                            mixedClusterIndex,
                                                                            buildingIndex,
                                                                            floorIndex
                                                                          )}
                                                                          value={floorItem.floorType}
                                                                          name='floor'
                                                                          onChange={(e: any) => {
                                                                            const updateCluster = [...allCluster]
                                                                            const values = [
                                                                              ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                            ]
                                                                            values[floorIndex].floorType = e

                                                                            if (e.value === 0) {
                                                                              values[floorIndex].startRangeError = false
                                                                              values[floorIndex].endRangeError = false
                                                                              values[floorIndex].floorStartRange = 1
                                                                              values[floorIndex].floorEndRange = 1
                                                                            }

                                                                            updateCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                              buildingIndex
                                                                            ].mergeFloorArray = values
                                                                            setAllCluster(updateCluster)

                                                                            const updatedValues = checkDuplicatesForMergeArray(values)
                                                                            updateCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                              buildingIndex
                                                                            ].mergeFloorArray = updatedValues
                                                                            setAllCluster(updateCluster)
                                                                            setIsCTAHidden(false)
                                                                          }}
                                                                          placeholder={'Select Floor Type'}
                                                                        />
                                                                      </div>
                                                                      {floorItem.isDuplicate && (
                                                                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                                          Floor Name is duplicated
                                                                        </p>
                                                                      )}
                                                                    </td>
                                                                    <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                                                      <div className='wxyz'>
                                                                        <Paper component='form' style={searchContainerStyle}>
                                                                          <InputBase
                                                                            placeholder='Prefix'
                                                                            style={inputBaseStyle}
                                                                            inputProps={{'aria-label': 'Prefix'}}
                                                                            value={floorItem?.name}
                                                                            onChange={(e: any) => {
                                                                              const updateCluster = [...allCluster]
                                                                              const values = [
                                                                                ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                  ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                              ]

                                                                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                              values[floorIndex].name = captilizeWord
                                                                              updateCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                                buildingIndex
                                                                              ].mergeFloorArray = values
                                                                              setAllCluster(updateCluster)

                                                                              const updatedValues = checkDuplicatesForMergeArray(values)
                                                                              updateCluster[ind].updateMixedCluster[mixedClusterIndex].updateBuilding[
                                                                                buildingIndex
                                                                              ].mergeFloorArray = updatedValues
                                                                              setAllCluster(updateCluster)
                                                                              setIsCTAHidden(false)
                                                                            }}
                                                                          />
                                                                        </Paper>
                                                                      </div>
                                                                    </td>
                                                                    <td className='p-0 ps-7 py-3 ' style={{minWidth: '200px', maxWidth: '220px'}}>
                                                                      <div className='d-flex ' style={{columnGap: '10px'}}>
                                                                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                          <Paper component='form' style={searchContainerStyle}>
                                                                            <InputBase
                                                                              disabled={floorItem?.floorType?.value === 0}
                                                                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                              placeholder='Start Range'
                                                                              style={inputBaseStyle}
                                                                              inputProps={{'aria-label': 'Start Range', min: 1}}
                                                                              value={
                                                                                floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange
                                                                              }
                                                                              onChange={(e: any) => {
                                                                                if (e.target.value < 0) {
                                                                                  return
                                                                                }
                                                                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                                const updateCluster = [...allCluster]
                                                                                const values = [
                                                                                  ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                    ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                ]

                                                                                if (
                                                                                  floorItem?.floorEndRange !== '' &&
                                                                                  floorItem?.floorEndRange < Number(e.target.value) &&
                                                                                  e.target.value != ''
                                                                                ) {
                                                                                  values[floorIndex].startRangeError = true
                                                                                } else {
                                                                                  values[floorIndex].startRangeError = false
                                                                                }

                                                                                if (
                                                                                  floorItem?.floorEndRange !== '' &&
                                                                                  floorItem?.floorEndRange >= Number(e.target.value) &&
                                                                                  e.target.value != ''
                                                                                ) {
                                                                                  values[floorIndex].endRangeError = false
                                                                                }
                                                                                values[floorIndex].floorStartRange = newValue ? Number(newValue) : ''
                                                                                updateCluster[ind].updateMixedCluster[
                                                                                  mixedClusterIndex
                                                                                ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                setAllCluster(updateCluster)

                                                                                const updatedValues = checkDuplicatesForMergeArray(values)
                                                                                updateCluster[ind].updateMixedCluster[
                                                                                  mixedClusterIndex
                                                                                ].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                                                setAllCluster(updateCluster)
                                                                                setIsCTAHidden(false)
                                                                              }}
                                                                            />
                                                                          </Paper>
                                                                          {floorItem?.startRangeError && (
                                                                            <p
                                                                              className='d-flex flex-row mt-1'
                                                                              style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                            >
                                                                              The floor 'Start Range' should be equal to or less than the floor 'End
                                                                              Range'.
                                                                            </p>
                                                                          )}
                                                                        </div>
                                                                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                          <Paper component='form' style={searchContainerStyle}>
                                                                            <InputBase
                                                                              disabled={floorItem?.floorType?.value === 0}
                                                                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                              placeholder='End Range'
                                                                              style={inputBaseStyle}
                                                                              inputProps={{'aria-label': 'End Range', min: 1}}
                                                                              value={
                                                                                floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange
                                                                              }
                                                                              onChange={(e: any) => {
                                                                                if (e.target.value < 0) {
                                                                                  return
                                                                                }
                                                                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                                const updateCluster = [...allCluster]
                                                                                const values = [
                                                                                  ...updateCluster[ind]?.updateMixedCluster[mixedClusterIndex]
                                                                                    ?.updateBuilding[buildingIndex]?.mergeFloorArray,
                                                                                ]

                                                                                if (
                                                                                  e.target.value != '' &&
                                                                                  Number(e.target.value) < floorItem.floorStartRange &&
                                                                                  floorItem.floorStartRange != ''
                                                                                ) {
                                                                                  values[floorIndex].endRangeError = true
                                                                                } else {
                                                                                  values[floorIndex].endRangeError = false
                                                                                }

                                                                                if (
                                                                                  e.target.value !== '' &&
                                                                                  Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                                                  floorItem.floorStartRange != ''
                                                                                ) {
                                                                                  values[floorIndex].startRangeError = false
                                                                                }

                                                                                values[floorIndex].floorEndRange = newValue ? Number(newValue) : ''
                                                                                updateCluster[ind].updateMixedCluster[
                                                                                  mixedClusterIndex
                                                                                ].updateBuilding[buildingIndex].mergeFloorArray = values
                                                                                setAllCluster(updateCluster)

                                                                                const updatedValues = checkDuplicatesForMergeArray(values)
                                                                                updateCluster[ind].updateMixedCluster[
                                                                                  mixedClusterIndex
                                                                                ].updateBuilding[buildingIndex].mergeFloorArray = updatedValues
                                                                                setAllCluster(updateCluster)
                                                                                setIsCTAHidden(false)
                                                                              }}
                                                                            />
                                                                          </Paper>
                                                                          {floorItem?.endRangeError && (
                                                                            <p
                                                                              className='d-flex flex-row mt-1'
                                                                              style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                            >
                                                                              The floor 'End Range' should be equal to or greater than the floor
                                                                              'Start Range'.
                                                                            </p>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    </td>

                                                                    <td
                                                                      className='p-0 ps-5 text-center py-3'
                                                                      style={{minWidth: '50px', maxWidth: '50px'}}
                                                                    >
                                                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                        <div className='mt-2'>
                                                                          {floorItem?.floorType?.value != 0 ? (
                                                                            <p className='m-0 head-text '>
                                                                              <i>
                                                                                {' '}
                                                                                {floorItem?.name}{' '}
                                                                                {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                                              </i>
                                                                            </p>
                                                                          ) : (
                                                                            <p className='m-0 head-text '>
                                                                              <i> {floorItem?.name}</i>
                                                                            </p>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    </td>

                                                                    <td className='p-0 ps-3 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                                                      <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                        <div className='mt-1'>
                                                                          <h3 className='m-0 head-text '>
                                                                            {`${
                                                                              floorItem?.floorStartRange > floorItem?.floorEndRange
                                                                                ? '-'
                                                                                : floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                                                            }`}
                                                                          </h3>
                                                                        </div>
                                                                        {buildingItem?.mergeFloorArray?.length >= 1 && (
                                                                          <img
                                                                            className='ms-7'
                                                                            style={{cursor: 'pointer'}}
                                                                            src={redCross}
                                                                            height={14}
                                                                            width={14}
                                                                            onClick={() =>
                                                                              handleRemoveMergeRow(
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex,
                                                                                1
                                                                              )
                                                                            }
                                                                          />
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              }
                                                            })}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  {/* floor end */}
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div className='col-12'>
                                            {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.addUnitGroup?.map(
                                              (groupItem: any, groupIndex: any) => (
                                                <div
                                                  className='card card-flush'
                                                  style={{
                                                    padding: '10px',
                                                    position: 'relative',
                                                    marginBottom: '20px',
                                                  }}
                                                >
                                                  {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.addUnitGroup?.length >= 1 && (
                                                    <div
                                                      style={{
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '32px',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <img
                                                        src={redCross}
                                                        height={14}
                                                        width={14}
                                                        onClick={() =>
                                                          handleRemoveUnitGroupForUpdateMixedCluster(ind, mixedClusterIndex, groupIndex, 1)
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                  <div className='row'>
                                                    <div className='col-12'>
                                                      <div className='me-4'>
                                                        <h3 className='m-0 head-text mb-1 ms-1 text-start'>Unit Group</h3>
                                                        <div className='wxyz' style={{width: '200px'}}>
                                                          <Paper component='form' style={searchContainerStyle}>
                                                            <InputBase
                                                              placeholder='Enter Unit Group Name'
                                                              style={inputBaseStyle}
                                                              inputProps={{
                                                                'aria-label': 'Unit Group',
                                                              }}
                                                              value={groupItem?.name}
                                                              onChange={(e: any) => {
                                                                const values = [...allCluster]
                                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                values[ind].updateMixedCluster[mixedClusterIndex].addUnitGroup[groupIndex].name =
                                                                  captilizeWord
                                                                setAllCluster(values)
                                                                setIsCTAHidden(false)
                                                              }}
                                                            />
                                                          </Paper>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                            {allCluster[ind]?.updateMixedCluster[mixedClusterIndex]?.addBuilding?.map(
                                              (buildingItem: any, buildingIndex: any) => (
                                                <BuildingCard
                                                  setIsCTAHidden={setIsCTAHidden}
                                                  allCluster={allCluster}
                                                  clusterIndex={mixedClusterIndex}
                                                  buildingIndex={buildingIndex}
                                                  index={ind}
                                                  buildingItem={buildingItem}
                                                  floorOptions={floorOptions}
                                                  setCluster={setAllCluster}
                                                />
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {/* add new mixed cluster */}
                                  {allCluster[ind]?.addMixedCluster?.map((clusterItem: any, mixedClusterIndex: any) => (
                                    <div className='col-12'>
                                      <div
                                        className='card card-flush'
                                        style={{
                                          padding: '10px',
                                          position: 'relative',
                                          marginBottom: '50px',
                                        }}
                                      >
                                        {allCluster[ind]?.addMixedCluster?.length >= 1 && (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              top: '2px',
                                              right: '12px',
                                            }}
                                          >
                                            <img
                                              style={{cursor: 'pointer'}}
                                              src={redCross}
                                              height={14}
                                              width={14}
                                              onClick={() => {
                                                handleRemoveMixedCluster(ind, mixedClusterIndex, 1)
                                              }}
                                            />
                                          </div>
                                        )}
                                        <div className='row'>
                                          <div className='text-center col-3 mx-auto'>
                                            <h3 className='m-0 head-text mb-1 ms-1'>Mixed Cluster</h3>
                                            <div className='wxyz'>
                                              <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                  placeholder='Enter Mixed Cluster Name'
                                                  style={inputBaseStyle}
                                                  inputProps={{'aria-label': 'Mixed Cluster'}}
                                                  value={clusterItem?.name}
                                                  onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[ind].addMixedCluster[mixedClusterIndex].name = captilizeWord
                                                    setAllCluster(values)
                                                    setIsCTAHidden(false)
                                                  }}
                                                />
                                              </Paper>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='row'>
                                          <div
                                            className='text-center my-7 d-flex justify-content-center align-items-start'
                                            style={{columnGap: '20px', padding: '2rem'}}
                                          >
                                            <div className='status-w-280'>
                                              <div className='d-flex justify-content-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                  style={{width: 'fit-content'}}
                                                  onClick={() => handleAddUnitGroup(ind, mixedClusterIndex)}
                                                >
                                                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Unit Group
                                                </button>
                                              </div>
                                              <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                                Edit existing unit groups or add new unit groups to this cluster. You will be able to add the
                                                properties for each new unit group after they have been added.
                                              </p>
                                            </div>
                                            <div className='status-w-280'>
                                              <div className='d-flex justify-content-center'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                  style={{width: 'fit-content'}}
                                                  onClick={() => handleAddBuildingForMixedCluster(ind, mixedClusterIndex)}
                                                >
                                                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                                                </button>
                                              </div>
                                              <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                                Edit existing buildings added to this cluster or add new buildings to this cluster. You will be able
                                                to add the properties for each new building after they have been added.
                                              </p>
                                            </div>
                                          </div>

                                          <div className='col-12'>
                                            {allCluster[ind]?.addMixedCluster[mixedClusterIndex].addUnitGroup?.map(
                                              (groupItem: any, groupIndex: any) => (
                                                <div
                                                  className='card card-flush'
                                                  style={{
                                                    padding: '10px',
                                                    position: 'relative',
                                                    marginBottom: '20px',
                                                  }}
                                                >
                                                  {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addUnitGroup?.length >= 1 && (
                                                    <div
                                                      style={{
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '32px',
                                                        cursor: 'pointer',
                                                      }}
                                                    >
                                                      <img
                                                        src={redCross}
                                                        height={14}
                                                        width={14}
                                                        onClick={() => handleRemoveUnitGroupForMixedCluster(ind, mixedClusterIndex, groupIndex)}
                                                      />
                                                    </div>
                                                  )}
                                                  <div className='row'>
                                                    <div className='col-12'>
                                                      <div className='me-4'>
                                                        <h3 className='m-0 head-text mb-1 ms-1 text-start'>Unit Group</h3>
                                                        <div className='wxyz' style={{width: '200px'}}>
                                                          <Paper component='form' style={searchContainerStyle}>
                                                            <InputBase
                                                              placeholder='Enter Unit Group Name'
                                                              style={inputBaseStyle}
                                                              inputProps={{
                                                                'aria-label': 'Unit Group',
                                                              }}
                                                              value={groupItem?.name}
                                                              onChange={(e: any) => {
                                                                const values = [...allCluster]
                                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                values[ind].addMixedCluster[mixedClusterIndex].addUnitGroup[groupIndex].name =
                                                                  captilizeWord
                                                                setAllCluster(values)
                                                                setIsCTAHidden(false)
                                                              }}
                                                            />
                                                          </Paper>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div className='col-12'>
                                            {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.map(
                                              (buildingItem: any, buildingIndex: any) => (
                                                <div
                                                  className='card card-flush '
                                                  style={{
                                                    padding: '10px',
                                                    position: 'relative',
                                                    marginBottom: '20px',
                                                  }}
                                                >
                                                  <div className='row'>
                                                    <div className='col-12  d-flex align-items-start'>
                                                      <div className='me-4' style={{width: '200px'}}>
                                                        <h3 className='m-0 head-text mb-1 ms-1 text-start'>Building</h3>
                                                        <div className='wxyz'>
                                                          <Paper component='form' style={searchContainerStyle}>
                                                            <InputBase
                                                              placeholder='Enter Building Name'
                                                              style={inputBaseStyle}
                                                              inputProps={{
                                                                'aria-label': 'Building name',
                                                              }}
                                                              value={buildingItem?.name}
                                                              onChange={(e: any) => {
                                                                const values = [...allCluster]
                                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                values[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].name =
                                                                  captilizeWord
                                                                setAllCluster(values)
                                                                setIsCTAHidden(false)
                                                              }}
                                                            />
                                                          </Paper>
                                                        </div>
                                                      </div>
                                                      <div className='text-center'>
                                                        <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                                        <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                                          {`${calculateTotalFloors(ind, mixedClusterIndex, buildingIndex)}`}
                                                        </h3>
                                                      </div>
                                                      {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.length >= 1 && (
                                                        <div className='d-flex justify-content-end flex-grow-1'>
                                                          <img
                                                            style={{cursor: 'pointer'}}
                                                            src={redCross}
                                                            height={14}
                                                            width={14}
                                                            onClick={() => {
                                                              handleRemoveBuilingForMixedCluster(ind, mixedClusterIndex, buildingIndex)
                                                            }}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>

                                                  {/* add floor */}
                                                  <div className='row my-7'>
                                                    <div className='col-12'>
                                                      <div className='d-flex align-items-center'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4 px-2'
                                                          onClick={() => addFloorHandlerForMixedCluster(ind, mixedClusterIndex, buildingIndex, 1)}
                                                          style={{width: '120px'}}
                                                        >
                                                          Add Floor
                                                        </button>

                                                        <p className='m-0 head-text text-start'>
                                                          Position each floor in the order you would like them to be sorted in your property tables.
                                                          Once added, the tables will be sorted based on the order of each floor set below along with
                                                          the properties added under each floor.
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {/* floor start */}
                                                  <div className='row'>
                                                    <div className='col-12'>
                                                      <table className='table table-borderless'>
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              className='head-text text-start p-0 pb-2'
                                                              style={{minWidth: '20px', maxWidth: '20px'}}
                                                            ></th>
                                                            <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                                                              Floors
                                                              <span className='ms-2'>
                                                                <OverlayTrigger
                                                                  placement='right'
                                                                  delay={{show: 250, hide: 200}}
                                                                  overlay={
                                                                    <Tooltip id='button-tooltip-2'>
                                                                      {' '}
                                                                      <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be
                                                                      included at the beginning of each floor added, giving each floor it’s own name.
                                                                      If you do not wish to include a prefix, you may leave this field blank. You will
                                                                      be able to edit any floor name once it has been added.
                                                                      <br></br>
                                                                      <b>Floor Range</b>: Set the range of floors within the selected floor type. The
                                                                      floor numbers will start and end based on the values included in the ‘Start
                                                                      Range’ and ‘End Range. This will allow you to reflect the same floor names
                                                                      within your building. If you are adding a single floor your ‘Start Range’ and ‘
                                                                      End Range’ should include the same value.
                                                                      <br></br>
                                                                      <b>Total Floors</b>: Calculates the total number of floors set in the floor
                                                                      range. Both ‘Start Range’ and ‘End Range’ will be included in the total count.
                                                                    </Tooltip>
                                                                  }
                                                                >
                                                                  <span>
                                                                    {' '}
                                                                    <AiOutlineExclamationCircle
                                                                      color='#146C6A '
                                                                      fontSize={14}
                                                                      fontWeight={500}
                                                                      className='cursor-pointer'
                                                                      style={{cursor: 'pointer', marginBottom: '2px'}}
                                                                    />
                                                                  </span>
                                                                </OverlayTrigger>
                                                              </span>
                                                            </th>
                                                            <th
                                                              className='head-text text-start p-0  ps-7 pb-2'
                                                              style={{minWidth: '100px', maxWidth: '100px'}}
                                                            >
                                                              Floor Name
                                                            </th>
                                                            <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                                                              Floor Range
                                                            </th>
                                                            <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                                                              Example
                                                            </th>
                                                            <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                                                              Total Floors
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody className='text-start'>
                                                          {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                            buildingIndex
                                                          ]?.addFloor?.map((floorItem: any, floorIndex: any) => {
                                                            return (
                                                              <tr
                                                                key={floorIndex}
                                                                className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}
                                                              >
                                                                <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                                                                  <span
                                                                    className={`${
                                                                      allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1
                                                                        ? 'me-3'
                                                                        : ''
                                                                    }`}
                                                                  >
                                                                    {floorIndex === 0 &&
                                                                      allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1 && (
                                                                        <img
                                                                          onClick={() =>
                                                                            swapRowForAdd(
                                                                              floorIndex,
                                                                              floorIndex + 1,
                                                                              ind,
                                                                              mixedClusterIndex,
                                                                              buildingIndex,
                                                                              floorIndex
                                                                            )
                                                                          }
                                                                          style={{
                                                                            cursor: 'pointer',
                                                                          }}
                                                                          src={carrotArrowDown}
                                                                          height={12}
                                                                          width={12}
                                                                        />
                                                                      )}
                                                                    {floorIndex ===
                                                                      allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                        ?.addFloor?.length -
                                                                        1 &&
                                                                      allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1 && (
                                                                        <img
                                                                          onClick={() =>
                                                                            swapRowForAdd(
                                                                              floorIndex - 1,
                                                                              floorIndex,
                                                                              ind,
                                                                              mixedClusterIndex,
                                                                              buildingIndex,
                                                                              floorIndex
                                                                            )
                                                                          }
                                                                          style={{
                                                                            cursor: 'pointer',
                                                                          }}
                                                                          src={carrotArrowUp}
                                                                          height={12}
                                                                          width={12}
                                                                        />
                                                                      )}
                                                                    {floorIndex !== 0 &&
                                                                      floorIndex !==
                                                                        allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                          buildingIndex
                                                                        ]?.addFloor?.length -
                                                                          1 && (
                                                                        <span className='d-flex flex-column'>
                                                                          <img
                                                                            style={{
                                                                              cursor: 'pointer',
                                                                            }}
                                                                            src={carrotArrowUp}
                                                                            height={12}
                                                                            width={12}
                                                                            onClick={() =>
                                                                              swapRowForAdd(
                                                                                floorIndex - 1,
                                                                                floorIndex,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                          />
                                                                          <img
                                                                            style={{
                                                                              cursor: 'pointer',
                                                                            }}
                                                                            src={carrotArrowDown}
                                                                            height={12}
                                                                            width={12}
                                                                            onClick={() =>
                                                                              swapRowForAdd(
                                                                                floorIndex,
                                                                                floorIndex + 1,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                          />
                                                                        </span>
                                                                      )}
                                                                  </span>
                                                                </td>
                                                                <td className='p-0  py-3' style={{minWidth: '170px'}}>
                                                                  <div>
                                                                    <Select
                                                                      styles={{
                                                                        ...customStyles,
                                                                        container: (defaultStyles: any) => ({
                                                                          ...defaultStyles,
                                                                          width: '100%',
                                                                        }),
                                                                      }}
                                                                      isSearchable={false}
                                                                      options={floorOption(
                                                                        ind,
                                                                        mixedClusterIndex,
                                                                        buildingIndex,
                                                                        floorIndex,
                                                                        'add',
                                                                        'addBuilding',
                                                                        'addFloor'
                                                                      )}
                                                                      name='floor2'
                                                                      value={floorItem.floorType}
                                                                      onChange={(e: any) => {
                                                                        const values = [...allCluster]
                                                                        values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                          buildingIndex
                                                                        ].addFloor[floorIndex].floorType = e

                                                                        if (e.value === 0) {
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].startRangeError = false
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].endRangeError = false
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].floorStartRange = 1
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].floorEndRange = 1
                                                                        }

                                                                        const updatedFloor = checkForDuplicatesForMixedCluster(
                                                                          values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                            ?.addFloor
                                                                        )
                                                                        values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                          buildingIndex
                                                                        ].addFloor = updatedFloor
                                                                        setAllCluster(values)
                                                                        setIsCTAHidden(false)
                                                                      }}
                                                                      placeholder={'Select Floor Type'}
                                                                    />
                                                                  </div>
                                                                  {floorItem.isDuplicate && (
                                                                    <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                                      Floor Name is duplicated
                                                                    </p>
                                                                  )}
                                                                </td>
                                                                <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                                                  <div className='wxyz'>
                                                                    <Paper component='form' style={searchContainerStyle}>
                                                                      <InputBase
                                                                        placeholder='Prefix'
                                                                        style={inputBaseStyle}
                                                                        inputProps={{
                                                                          'aria-label': 'Prefix',
                                                                        }}
                                                                        value={floorItem?.name}
                                                                        onChange={(e: any) => {
                                                                          const values = [...allCluster]
                                                                          const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].name = captilizeWord

                                                                          const updatedFloor = checkForDuplicatesForMixedCluster(
                                                                            values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                              buildingIndex
                                                                            ]?.addFloor
                                                                          )
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor = updatedFloor
                                                                          setAllCluster(values)
                                                                          setIsCTAHidden(false)
                                                                        }}
                                                                      />
                                                                    </Paper>
                                                                  </div>
                                                                </td>

                                                                <td className='p-0 min-w-100px ps-7 py-3' style={{maxWidth: '220px'}}>
                                                                  <div className='d-flex ' style={{columnGap: '10px'}}>
                                                                    <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                      <Paper component='form' style={searchContainerStyle}>
                                                                        <InputBase
                                                                          disabled={floorItem?.floorType === 0}
                                                                          type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                          placeholder='Start Range'
                                                                          style={inputBaseStyle}
                                                                          inputProps={{
                                                                            'aria-label': 'Start Range',
                                                                            min: 1,
                                                                          }}
                                                                          value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                                          onChange={(e: any) => {
                                                                            if (e.target.value < 0) {
                                                                              return
                                                                            }

                                                                            const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                            const values = [...allCluster]

                                                                            if (
                                                                              floorItem?.floorEndRange !== '' &&
                                                                              floorItem?.floorEndRange < Number(e.target.value) &&
                                                                              e.target.value != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = true
                                                                            } else {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = false
                                                                            }

                                                                            if (
                                                                              floorItem?.floorEndRange !== '' &&
                                                                              floorItem?.floorEndRange >= Number(e.target.value) &&
                                                                              e.target.value != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = false
                                                                            }

                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor[floorIndex].floorStartRange = newValue ? Number(newValue) : ''

                                                                            const updatedFloor = checkForDuplicatesForMixedCluster(
                                                                              allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                                buildingIndex
                                                                              ]?.addFloor
                                                                            )
                                                                            allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor = updatedFloor
                                                                            setAllCluster(values)
                                                                            setIsCTAHidden(false)
                                                                          }}
                                                                        />
                                                                      </Paper>
                                                                      {floorItem?.startRangeError && (
                                                                        <p
                                                                          className='d-flex flex-row mt-1'
                                                                          style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                        >
                                                                          The floor 'Start Range' should be equal to or less than the floor 'End
                                                                          Range'.
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                    <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                      <Paper component='form' style={searchContainerStyle}>
                                                                        <InputBase
                                                                          disabled={floorItem?.floorType === 0}
                                                                          type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                          placeholder='End Range'
                                                                          style={inputBaseStyle}
                                                                          inputProps={{
                                                                            'aria-label': 'End Range',
                                                                            min: 1,
                                                                          }}
                                                                          value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                                          onChange={(e: any) => {
                                                                            if (e.target.value < 0) {
                                                                              return
                                                                            }
                                                                            const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                            const values = [...allCluster]

                                                                            if (
                                                                              e.target.value != '' &&
                                                                              Number(e.target.value) < floorItem.floorStartRange &&
                                                                              floorItem.floorStartRange != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = true
                                                                            } else {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = false
                                                                            }

                                                                            if (
                                                                              e.target.value !== '' &&
                                                                              Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                                              floorItem.floorStartRange != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = false
                                                                            }

                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor[floorIndex].floorEndRange = newValue ? Number(newValue) : ''

                                                                            const updatedFloor = checkForDuplicatesForMixedCluster(
                                                                              values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                                buildingIndex
                                                                              ]?.addFloor
                                                                            )
                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor = updatedFloor
                                                                            setAllCluster(values)
                                                                            setIsCTAHidden(false)
                                                                          }}
                                                                        />
                                                                      </Paper>
                                                                      {floorItem?.endRangeError && (
                                                                        <p
                                                                          className='d-flex flex-row mt-1'
                                                                          style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                        >
                                                                          The floor 'End Range' should be equal to or greater than the floor 'Start
                                                                          Range'.
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  className='p-0 ps-5 text-center py-3'
                                                                  style={{minWidth: '50px', maxWidth: '50px'}}
                                                                >
                                                                  <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                    <div className='mt-2'>
                                                                      {floorItem?.floorType?.value != 0 ? (
                                                                        <p className='m-0 head-text '>
                                                                          <i>
                                                                            {' '}
                                                                            {floorItem?.name}{' '}
                                                                            {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                                          </i>
                                                                        </p>
                                                                      ) : (
                                                                        <p className='m-0 head-text '>
                                                                          <i> {floorItem?.name}</i>
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>

                                                                <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                                                  <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                    <div className=' mt-1'>
                                                                      <h3 className='m-0 head-text '>
                                                                        {`${
                                                                          floorItem?.floorStartRange > floorItem?.floorEndRange
                                                                            ? '-'
                                                                            : floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                                                        }`}
                                                                      </h3>
                                                                    </div>
                                                                    {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                      ?.addFloor?.length > 1 && (
                                                                      <img
                                                                        className='ms-7'
                                                                        style={{
                                                                          cursor: 'pointer',
                                                                        }}
                                                                        src={redCross}
                                                                        height={14}
                                                                        width={14}
                                                                        onClick={() =>
                                                                          handleRemoveFloorForMixedCluster(
                                                                            ind,
                                                                            mixedClusterIndex,
                                                                            buildingIndex,
                                                                            floorIndex
                                                                          )
                                                                        }
                                                                      />
                                                                    )}
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            )
                                                          })}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  {/* floor end */}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}

                {selectBuildingCircle && (
                  <div className='row' style={{marginTop: '50px'}}>
                    <div className='col-10 mx-auto text-center mb-2'>
                      <h2 className='m-0 head-text'>
                        <b>Set Building Floors</b>
                      </h2>
                    </div>

                    <div className='col-12 mx-auto text-center'>
                      <h4 className='m-0 head-text mb-1'>Set the number and sorting of floor within your building</h4>
                    </div>

                    <div className='col-11 mt-5 mx-auto'>
                      <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                        {/* add floor */}
                        <div className='row my-7'>
                          <div className='col-12 mx-auto'>
                            <div className='text-center'>
                              <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                              <h3 className='m-0 head-text mt-3 mb-1 ms-2'>{calculateTotalFloorForUpdateBuiding}</h3>
                            </div>
                            <div className='d-flex flex-column align-items-center my-7'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4 px-2'
                                style={{width: '120px'}}
                                onClick={addFloorHandlerForBuildingDevelopment}
                              >
                                Add Floor
                              </button>

                              <p className='m-0 head-text text-center mt-5 px-4'>
                                Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will
                                be sorted based on the order of each floor set below along with the properties added under each floor.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className='row ps-4'>
                          <div className='col-12'>
                            <table className='table table-borderless'>
                              <thead>
                                <tr>
                                  <th className='head-text text-start p-0 pb-2' style={{maxWidth: '20px', minWidth: '20px'}}></th>
                                  <th className='head-text text-start pb-2' style={{minWidth: '170px', maxWidth: '170px'}}>
                                    Floors
                                    <span className='ms-2'>
                                      <OverlayTrigger
                                        placement='right'
                                        delay={{show: 250, hide: 200}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2'>
                                            {' '}
                                            <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of
                                            each floor added, giving each floor it’s own name. If you do not wish to include a prefix, you may leave
                                            this field blank. You will be able to edit any floor name once it has been added.
                                            <br></br>
                                            <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start
                                            and end based on the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect
                                            the same floor names within your building. If you are adding a single floor your ‘Start Range’ and ‘ End
                                            Range’ should include the same value.
                                            <br></br>
                                            <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’ and
                                            ‘End Range’ will be included in the total count.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle
                                            color='#146C6A '
                                            fontSize={14}
                                            fontWeight={500}
                                            className='cursor-pointer'
                                            style={{cursor: 'pointer', marginBottom: '2px'}}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                  <th className='head-text text-start p-0 ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                                    Floor Name
                                  </th>
                                  <th className='head-text text-start p-0 ps-5 pb-2' style={{minWidth: '220px'}}></th>
                                  <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '50px'}}>
                                    Example
                                  </th>
                                  <th className='head-text text-center p-0 ps-5 pb-2' style={{minWidth: '100px'}}>
                                    Total Floors
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='text-start'>
                                {mergeBuildingDevelopmenFloor
                                  ?.sort((a: any, b: any) => {
                                    if (a.sortIndex < b.sortIndex) return -1
                                    if (a.sortIndex > b.sortIndex) return 1
                                    return 0
                                  })
                                  ?.map((floorItem: any, floorIndex: any) => {
                                    if (floorItem?.subListRowUI) {
                                      return (
                                        <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                                          <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                            <span className={`${floorItem.floorList.length > 1 ? 'me-3' : ''}`}>
                                              {floorIndex === 0 && mergeBuildingDevelopmenFloor?.length > 1 && (
                                                <img
                                                  onClick={() => swapRowForMergedBuilding(floorIndex, floorIndex + 1)}
                                                  style={{cursor: 'pointer'}}
                                                  src={carrotArrowDown}
                                                  height={12}
                                                  width={12}
                                                />
                                              )}
                                              {floorIndex === mergeBuildingDevelopmenFloor?.length - 1 &&
                                                mergeBuildingDevelopmenFloor?.length > 1 && (
                                                  <img
                                                    onClick={() => swapRowForMergedBuilding(floorIndex - 1, floorIndex)}
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowUp}
                                                    height={12}
                                                    width={12}
                                                  />
                                                )}
                                              {floorIndex !== 0 && floorIndex !== mergeBuildingDevelopmenFloor.length - 1 && (
                                                <span className='d-flex flex-column'>
                                                  <img
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowUp}
                                                    height={12}
                                                    width={12}
                                                    onClick={() => swapRowForMergedBuilding(floorIndex - 1, floorIndex)}
                                                  />
                                                  <img
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowDown}
                                                    height={12}
                                                    width={12}
                                                    onClick={() => swapRowForMergedBuilding(floorIndex, floorIndex + 1)}
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                          <td className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}>
                                            <div>
                                              <Select
                                                styles={{
                                                  ...customStyles,
                                                  container: (defaultStyles: any) => ({
                                                    ...defaultStyles,
                                                    width: '100%',
                                                  }),
                                                }}
                                                isSearchable={false}
                                                // options={floorOptions}
                                                options={floorOptionsForBuilding(floorIndex)}
                                                value={floorOptions.filter((item: any) => item.value == floorItem?.floorType)?.[0]}
                                                name='floor'
                                                onChange={(e: any) => {
                                                  const values = [...mergeBuildingDevelopmenFloor]
                                                  values[floorIndex].floorType = e.value
                                                  values[floorIndex].floorList = values[floorIndex]?.floorList?.map((item: any, i: any) => ({
                                                    _id: item._id,
                                                    floorType: e.value,
                                                    name: item.name,
                                                    index: item.index,
                                                    prefix: item.prefix,
                                                  }))
                                                  setMergeBuildingDevelopmenFloor(values)
                                                  let sortedArr: any = values?.sort((a: any, b: any) => {
                                                    if (a.sortIndex < b.sortIndex) return -1
                                                    if (a.sortIndex > b.sortIndex) return 1
                                                    return 0
                                                  })
                                                  generateFloorForEditBuilding(sortedArr)

                                                  const updatedValues = checkDuplicatesForMergeArray(values)
                                                  setMergeBuildingDevelopmenFloor(updatedValues)
                                                  setIsCTAHidden(false)
                                                }}
                                                placeholder={'Select Floor Type'}
                                              />
                                            </div>
                                            {floorItem.isDuplicate && (
                                              <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                Floor Name is duplicated
                                              </p>
                                            )}
                                          </td>
                                          <td className='p-0  py-3' style={{minWidth: '100px', maxWidth: '100px'}}>
                                            <tr className='d-flex justify-content-between align-items-center' style={{columnGap: '10px'}}>
                                              <td className='p-0 ps-7'>
                                                <div className='wxyz'>
                                                  <Paper component='form' style={searchContainerStyle}>
                                                    <InputBase
                                                      placeholder='Prefix'
                                                      style={inputBaseStyle}
                                                      inputProps={{'aria-label': 'Prefix'}}
                                                      value={floorItem?.prefix}
                                                      onChange={(e: any) => {
                                                        const values = [...mergeBuildingDevelopmenFloor]
                                                        // buildingDevelopmentUpdateFloor[floorIndex].prefix = e.target.value
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                        values[floorIndex].prefix = captilizeWord
                                                        values[floorIndex].floorList = values[floorIndex]?.floorList.map((item: any, i: any) => {
                                                          const floorNumbers = item.name?.split(' ')[1] || item.name?.split('-')[1]
                                                          if (item?.name?.match(/^([a-zA-Z]+)(\d+)?$/)) {
                                                            return {
                                                              _id: item._id,
                                                              floorType: item.floorType,
                                                              // name: `${e.target.value}-${i + 1}`,
                                                              name: item.name,
                                                              // name:item.name,
                                                              index: item.index,
                                                              prefix: captilizeWord,
                                                            }
                                                          } else {
                                                            return {
                                                              _id: item._id,
                                                              floorType: item.floorType,
                                                              // name: `${e.target.value}-${i + 1}`,
                                                              name:
                                                                values[floorIndex].floorType == 0
                                                                  ? `${captilizeWord}`
                                                                  : `${captilizeWord} ${floorNumbers}`,
                                                              // name:item.name,
                                                              index: item.index,
                                                              prefix: captilizeWord,
                                                            }
                                                          }

                                                          // return {
                                                          //   _id: item._id,
                                                          //   floorType: item.floorType,
                                                          //   // name: `${e.target.value}-${i + 1}`,
                                                          //   name:
                                                          //   values[floorIndex]
                                                          //       .floorType == 0
                                                          //       ? `${captilizeWord}`
                                                          //       : `${captilizeWord} ${floorNumbers}`,
                                                          //   // name:item.name,
                                                          //   index: item.index,
                                                          //   prefix: captilizeWord,
                                                          // }
                                                        })

                                                        setMergeBuildingDevelopmenFloor(values)
                                                        let sortedArr: any = values?.sort((a: any, b: any) => {
                                                          if (a.sortIndex < b.sortIndex) return -1
                                                          if (a.sortIndex > b.sortIndex) return 1
                                                          return 0
                                                        })
                                                        generateFloorForEditBuilding(sortedArr)

                                                        const updatedValues = checkDuplicatesForMergeArray(values)
                                                        setMergeBuildingDevelopmenFloor(updatedValues)
                                                        setIsCTAHidden(false)
                                                      }}
                                                    />
                                                  </Paper>
                                                </div>
                                              </td>
                                              <td>
                                                <div className=''>
                                                  <div className='br-arr d-flex align-items-center justify-content-center'>
                                                    {floorItem.isShowFloors == false ? (
                                                      <img
                                                        src={downGreen}
                                                        height={20}
                                                        width={20}
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                          const values = [...mergeBuildingDevelopmenFloor]
                                                          values[floorIndex].isShowFloors = true
                                                          setMergeBuildingDevelopmenFloor(values)
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={upGreen}
                                                        height={20}
                                                        width={20}
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                          const values = [...mergeBuildingDevelopmenFloor]
                                                          values[floorIndex].isShowFloors = false
                                                          setMergeBuildingDevelopmenFloor(values)
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <div>
                                              {mergeBuildingDevelopmenFloor[floorIndex]?.isShowFloors && (
                                                <>
                                                  {mergeBuildingDevelopmenFloor[floorIndex]?.floorList?.map((item: any, itemIndex: any) => (
                                                    <div className={`my-2 d-flex align-items-center `}>
                                                      <span
                                                        className={`${mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length > 1 ? 'me-3' : ''}`}
                                                      >
                                                        {itemIndex === 0 && mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length > 1 && (
                                                          <img
                                                            onClick={() => swapRowUpdateList(itemIndex, itemIndex + 1, floorIndex)}
                                                            style={{cursor: 'pointer'}}
                                                            src={carrotArrowDown}
                                                            height={12}
                                                            width={12}
                                                          />
                                                        )}

                                                        {itemIndex === mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length - 1 &&
                                                          mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length > 1 && (
                                                            <img
                                                              onClick={() => swapRowUpdateList(itemIndex - 1, itemIndex, floorIndex)}
                                                              style={{cursor: 'pointer'}}
                                                              src={carrotArrowUp}
                                                              height={12}
                                                              width={12}
                                                            />
                                                          )}
                                                        {itemIndex !== 0 &&
                                                          itemIndex !== mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length - 1 && (
                                                            <span className='d-flex flex-column'>
                                                              <img
                                                                style={{cursor: 'pointer'}}
                                                                src={carrotArrowUp}
                                                                height={12}
                                                                width={12}
                                                                onClick={() => swapRowUpdateList(itemIndex - 1, itemIndex, floorIndex)}
                                                              />
                                                              <img
                                                                style={{cursor: 'pointer'}}
                                                                src={carrotArrowDown}
                                                                height={12}
                                                                width={12}
                                                                onClick={() => swapRowUpdateList(itemIndex, itemIndex + 1, floorIndex)}
                                                              />
                                                            </span>
                                                          )}
                                                      </span>
                                                      <div
                                                        className={`wxyz flex-grow-1 ${item.animationClass} ${
                                                          mergeBuildingDevelopmenFloor[floorIndex]?.floorList.length == 1 ? 'ps-7' : ''
                                                        }`}
                                                      >
                                                        <Paper component='form' style={searchContainerStyle}>
                                                          <InputBase
                                                            placeholder=''
                                                            style={inputBaseStyle}
                                                            inputProps={{'aria-label': ''}}
                                                            value={item?.name}
                                                            onChange={(e: any) => {
                                                              const values = [...mergeBuildingDevelopmenFloor]
                                                              values[floorIndex].floorList[itemIndex].name = e.target.value
                                                              let sortedArr: any = values?.sort((a: any, b: any) => {
                                                                if (a.sortIndex < b.sortIndex) return -1
                                                                if (a.sortIndex > b.sortIndex) return 1
                                                                return 0
                                                              })
                                                              generateFloorForEditBuilding(sortedArr)
                                                              setMergeBuildingDevelopmenFloor(values)
                                                              // setIsCTAHidden(false)
      
                                                              const updatedValues = checkDuplicatesForMergeArray(values)
                                                              setMergeBuildingDevelopmenFloor(updatedValues)
                                                              setIsCTAHidden(false)
                                                            }}
                                                          />
                                                        </Paper>
                                                      </div>
                                                      <span className='ms-3'>
                                                        <img
                                                          style={{cursor: 'pointer'}}
                                                          src={trashImg}
                                                          height={14}
                                                          width={14}
                                                          onClick={() => {
                                                            checkBeforeDeleteForSingleFloorForDev(floorIndex, itemIndex)
                                                            
                                                            
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  ))}
                                                </>
                                              )}
                                            </div>
                                          </td>
                                          <td className='p-0  ps-7 pb-7 ' style={{minWidth: '200px', maxWidth: '220px'}}></td>
                                          <td className='p-0 ps-7 pb-7 ' style={{minWidth: '50px', maxWidth: '50px'}}></td>

                                          <td className='p-0 ps-5 text-center pb-7 min-w-100px' style={{maxWidth: '100px'}}>
                                            <div className='text-center  d-flex justify-content-center align-items-center'>
                                              <div className='mt-1'>
                                                <h3 className='m-0 head-text me-7'>{mergeBuildingDevelopmenFloor[floorIndex]?.floorList?.length}</h3>
                                              </div>
                                              {mergeBuildingDevelopmenFloor.length >= 1 && (
                                                <img
                                                  className=''
                                                  style={{cursor: 'pointer'}}
                                                  src={trashImg}
                                                  height={14}
                                                  width={14}
                                                  onClick={() => {
                                                    checkBeforeDeleteFloorForBuildingDev(floorIndex)
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }
                                    if (floorItem?.noSubListRowUI) {
                                      return (
                                        <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                                          <td className='py-3' style={{maxWidth: '20px', minWidth: '20px'}}>
                                            <span className={`${floorItem.floorList?.length > 1 ? 'me-3' : ''}`}>
                                              {floorIndex === 0 && mergeBuildingDevelopmenFloor?.length > 1 && (
                                                <img
                                                  onClick={() => swapRowForMergedBuilding(floorIndex, floorIndex + 1)}
                                                  style={{cursor: 'pointer'}}
                                                  src={carrotArrowDown}
                                                  height={12}
                                                  width={12}
                                                />
                                              )}
                                              {floorIndex === mergeBuildingDevelopmenFloor?.length - 1 &&
                                                mergeBuildingDevelopmenFloor?.length > 1 && (
                                                  <img
                                                    onClick={() => swapRowForMergedBuilding(floorIndex - 1, floorIndex)}
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowUp}
                                                    height={12}
                                                    width={12}
                                                  />
                                                )}
                                              {floorIndex !== 0 && floorIndex !== mergeBuildingDevelopmenFloor.length - 1 && (
                                                <span className='d-flex flex-column'>
                                                  <img
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowUp}
                                                    height={12}
                                                    width={12}
                                                    onClick={() => swapRowForMergedBuilding(floorIndex - 1, floorIndex)}
                                                  />
                                                  <img
                                                    style={{cursor: 'pointer'}}
                                                    src={carrotArrowDown}
                                                    height={12}
                                                    width={12}
                                                    onClick={() => swapRowForMergedBuilding(floorIndex, floorIndex + 1)}
                                                  />
                                                </span>
                                              )}
                                            </span>
                                          </td>
                                          <td className='p-0 py-3' style={{minWidth: '170px', maxWidth: '170px'}}>
                                            <div>
                                              <Select
                                                styles={{
                                                  ...customStyles,
                                                  container: (defaultStyles: any) => ({
                                                    ...defaultStyles,
                                                    width: '100%',
                                                  }),
                                                }}
                                                isSearchable={true}
                                                options={floorOptionsForBuilding(floorIndex)}
                                                value={floorItem.floorType}
                                                name='floor'
                                                onChange={(e: any) => {
                                                  const values = [...mergeBuildingDevelopmenFloor]
                                                  values[floorIndex].floorType = e

                                                  if (e.value === 0) {
                                                    values[floorIndex].startRangeError = false
                                                    values[floorIndex].endRangeError = false
                                                    values[floorIndex].floorStartRange = 1
                                                    values[floorIndex].floorEndRange = 1
                                                  }

                                                  setMergeBuildingDevelopmenFloor(values)

                                                  let sortedArr: any = values?.sort((a: any, b: any) => {
                                                    if (a.sortIndex < b.sortIndex) return -1
                                                    if (a.sortIndex > b.sortIndex) return 1
                                                    return 0
                                                  })
                                                  generateFloorForEditBuilding(sortedArr)

                                                  const updatedValues = checkDuplicatesForMergeArray(values)

                                                  setMergeBuildingDevelopmenFloor(updatedValues)
                                                  setIsCTAHidden(false)
                                                }}
                                                placeholder={'Select Floor Type'}
                                              />
                                            </div>
                                            {floorItem.isDuplicate && (
                                              <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                Floor Name is duplicated
                                              </p>
                                            )}
                                          </td>
                                          <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                            <div className='wxyz'>
                                              <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                  placeholder='Prefix'
                                                  style={inputBaseStyle}
                                                  inputProps={{'aria-label': 'Prefix'}}
                                                  value={floorItem?.name}
                                                  onChange={(e: any) => {
                                                    const values = [...mergeBuildingDevelopmenFloor]

                                                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                    values[floorIndex].name = captilizeWord
                                                    setMergeBuildingDevelopmenFloor(values)
                                                    setIsCTAHidden(false)

                                                    let sortedArr: any = values?.sort((a: any, b: any) => {
                                                      if (a.sortIndex < b.sortIndex) return -1
                                                      if (a.sortIndex > b.sortIndex) return 1
                                                      return 0
                                                    })
                                                    generateFloorForEditBuilding(sortedArr)

                                                    const updatedValues = checkDuplicatesForMergeArray(values)
                                                    // console.log(updatedValues)
                                                    setMergeBuildingDevelopmenFloor(updatedValues)
                                                    setIsCTAHidden(false)
                                                  }}
                                                />
                                              </Paper>
                                            </div>
                                          </td>
                                          <td className='p-0 ps-7 py-3 ' style={{minWidth: '200px', maxWidth: '220px'}}>
                                            <div className='d-flex ' style={{columnGap: '10px'}}>
                                              <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                <Paper component='form' style={searchContainerStyle}>
                                                  <InputBase
                                                    disabled={floorItem?.floorType?.value === 0}
                                                    type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                    placeholder='Start Range'
                                                    style={inputBaseStyle}
                                                    inputProps={{'aria-label': 'Start Range', min: 1}}
                                                    value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                    onChange={(e: any) => {
                                                      if (e.target.value < 0) {
                                                        return
                                                      }
                                                      const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                      const values = [...mergeBuildingDevelopmenFloor]
                                                      // if (
                                                      //   values[floorIndex].floorEndRange !== '' &&
                                                      //   values[floorIndex].floorEndRange <
                                                      //     Number(e.target.value) &&
                                                      //   e.target.value != ''
                                                      // ) {
                                                      //   ErrorToast(
                                                      //     'Start Range should not be GREATER than End Range.'
                                                      //   )
                                                      // } else {
                                                      //   values[floorIndex].floorStartRange = newValue
                                                      //     ? Number(newValue)
                                                      //     : ''
                                                      // }
                                                      if (
                                                        floorItem?.floorEndRange !== '' &&
                                                        floorItem?.floorEndRange < Number(e.target.value) &&
                                                        e.target.value != ''
                                                      ) {
                                                        values[floorIndex].startRangeError = true
                                                      } else {
                                                        values[floorIndex].startRangeError = false
                                                      }

                                                      if (
                                                        floorItem?.floorEndRange !== '' &&
                                                        floorItem?.floorEndRange >= Number(e.target.value) &&
                                                        e.target.value != ''
                                                      ) {
                                                        values[floorIndex].endRangeError = false
                                                      }
                                                      values[floorIndex].floorStartRange = newValue ? Number(newValue) : ''
                                                      setMergeBuildingDevelopmenFloor(values)

                                                      generateFloorForEditBuilding(values)

                                                      if (!values[floorIndex].endRangeError && values[floorIndex].startRangeError) {
                                                        let sortedArr: any = values?.sort((a: any, b: any) => {
                                                          if (a.sortIndex < b.sortIndex) return -1
                                                          if (a.sortIndex > b.sortIndex) return 1
                                                          return 0
                                                        })
                                                        generateFloorForEditBuilding(sortedArr)
                                                      }

                                                      const updatedValues = checkDuplicatesForMergeArray(values)
                                                      setMergeBuildingDevelopmenFloor(updatedValues)
                                                      setIsCTAHidden(false)
                                                    }}
                                                  />
                                                </Paper>
                                                {floorItem?.startRangeError && (
                                                  <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                    The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                                  </p>
                                                )}
                                              </div>
                                              <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                <Paper component='form' style={searchContainerStyle}>
                                                  <InputBase
                                                    disabled={floorItem?.floorType?.value === 0}
                                                    type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                    placeholder='End Range'
                                                    style={inputBaseStyle}
                                                    inputProps={{'aria-label': 'End Range', min: 1}}
                                                    value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                    onChange={(e: any) => {
                                                      if (e.target.value < 0) {
                                                        return
                                                      }
                                                      const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                      const values = [...mergeBuildingDevelopmenFloor]
                                                      // if (
                                                      //   e.target.value != '' &&
                                                      //   Number(e.target.value) <
                                                      //     values[floorIndex].floorStartRange &&
                                                      //   values[floorIndex].floorStartRange != ''
                                                      // ) {
                                                      //   ErrorToast(
                                                      //     "The floor 'end range' should be equal to or greater than the floor 'start range'."
                                                      //   )
                                                      // } else {
                                                      //   values[floorIndex].floorEndRange = newValue
                                                      //     ? Number(newValue)
                                                      //     : ''
                                                      // }

                                                      if (
                                                        e.target.value != '' &&
                                                        Number(e.target.value) < floorItem.floorStartRange &&
                                                        floorItem.floorStartRange != ''
                                                      ) {
                                                        values[floorIndex].endRangeError = true
                                                      } else {
                                                        values[floorIndex].endRangeError = false
                                                      }

                                                      if (
                                                        e.target.value !== '' &&
                                                        Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                        floorItem.floorStartRange != ''
                                                      ) {
                                                        values[floorIndex].startRangeError = false
                                                      }

                                                      values[floorIndex].floorEndRange = newValue ? Number(newValue) : ''
                                                      setMergeBuildingDevelopmenFloor(values)

                                                      if (!(values[floorIndex].endRangeError && values[floorIndex].startRangeError)) {
                                                        let sortedArr: any = values?.sort((a: any, b: any) => {
                                                          if (a.sortIndex < b.sortIndex) return -1
                                                          if (a.sortIndex > b.sortIndex) return 1
                                                          return 0
                                                        })
                                                        generateFloorForEditBuilding(sortedArr)
                                                      }

                                                      const updatedValues = checkDuplicatesForMergeArray(values)
                                                      setMergeBuildingDevelopmenFloor(updatedValues)
                                                      setIsCTAHidden(false)
                                                    }}
                                                  />
                                                </Paper>
                                                {floorItem?.endRangeError && (
                                                  <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                    The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </td>

                                          <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                                            <div className='text-center  d-flex justify-content-center align-items-center'>
                                              <div className='mt-2'>
                                                {floorItem?.floorType?.value != 0 ? (
                                                  <p className='m-0 head-text '>
                                                    <i>
                                                      {' '}
                                                      {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                    </i>
                                                  </p>
                                                ) : (
                                                  <p className='m-0 head-text '>
                                                    <i> {floorItem?.name}</i>
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </td>

                                          <td className='p-0 ps-3 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                            <div className='text-center  d-flex justify-content-center align-items-center'>
                                              <div className='mt-1'>
                                                <h3 className='m-0 head-text '>
                                                  {`${
                                                    floorItem?.floorStartRange > floorItem?.floorEndRange
                                                      ? '-'
                                                      : floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                                  }`}
                                                </h3>
                                              </div>
                                              {mergeBuildingDevelopmenFloor?.length >= 1 && (
                                                <img
                                                  className='ms-7'
                                                  style={{cursor: 'pointer'}}
                                                  src={redCross}
                                                  height={14}
                                                  width={14}
                                                  onClick={() => handleRemoveDevelopmenetBuilding(floorIndex, 1)}
                                                />
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    }
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-6 mx-auto ' style={{marginTop: '120px'}}>
              <button
                type='button'
                className='btn btn-sm fw-bold delete-btn w-100 d-flex justify-content-center '
                onClick={() => {
                  checkBeforeDelete()
                }}
              >
                Delete Development
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
    </>
  )
}

export default EditPropertyDevelopment

interface BuildingProps {
  buildingItem: any
  buildingIndex: any
  index: any
  clusterIndex: any
  allCluster: any
  setCluster: any
  floorOptions: any
  setIsCTAHidden: any
}

const BuildingCard = ({buildingItem, buildingIndex, clusterIndex, index, allCluster, setCluster, floorOptions, setIsCTAHidden}: BuildingProps) => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }
  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
  }
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const calculateTotalFloors = (index: any, mixedClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }

  const floorOptionForUpdateCluster = (index: any, mixedClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    let options: any = []
    let updatedOptions = floorOptions

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = allCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const addFloorHandlerForAddBuilding = (index: any, mixedClusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: updateCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length + 1,
    })
    setCluster(updateCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveFloorForAddBuilding = (index: any, mixedClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index].updateMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)

    const updatedFloor = checkForDuplicatesForBuildingCluster(
      updatedCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    )
    updatedCluster[index].updateMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
    setCluster(updatedCluster)
    setIsCTAHidden(false)
  }

  const handleRemoveBuildingForAddBuilding = (index: any, mixedClusterIndex: any, buildingIndex: any) => {
    const updateCluster = [...allCluster]
    updateCluster[index]?.updateMixedCluster[mixedClusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setCluster(updateCluster)
    setIsCTAHidden(false)
  }

  const swapRow = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.updateMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      delete updatedValuesSections[prevInd].animationClass
      delete updatedValuesSections[nextInd].animationClass

      // Assign the updated array back to the structure
      updatedValues[index].updateMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations and red box
      const updatedFloor = checkForDuplicatesForBuildingCluster(
        updatedValues[index]?.updateMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      updatedValues[index].updateMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
      setCluster(updatedValues)
      setIsCTAHidden(false)
    }, 100)
  }

  const checkForDuplicatesForBuildingCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }
  return (
    <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
      <div className='row'>
        <div className='col-8 d-flex align-items-start'>
          <div className='me-4'>
            <h3 className='m-0 head-text text-start mb-1 ms-1'>Building</h3>
            <div className='wxyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Enter Building Name'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'Building name'}}
                  value={buildingItem?.name}
                  onChange={(e) => {
                    const values = [...allCluster]
                    const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                    values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].name = captilizeWord
                    setCluster(values)
                    setIsCTAHidden(false)
                  }}
                />
              </Paper>
            </div>
          </div>
          <div className='text-center'>
            <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>
            <h3 className='m-0 head-text mt-3 mb-1 ms-2'>{`${calculateTotalFloors(index, clusterIndex, buildingIndex)}`}</h3>
          </div>
        </div>
        {allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding?.length >= 1 && (
          <div className='col-4 d-flex justify-content-end'>
            <img
              style={{cursor: 'pointer'}}
              src={redCross}
              height={14}
              width={14}
              onClick={() => {
                handleRemoveBuildingForAddBuilding(index, clusterIndex, buildingIndex)
              }}
            />
          </div>
        )}
      </div>

      <div className='row my-7'>
        <div className='col-12'>
          <div className='d-flex align-items-center'>
            <button
              type='button'
              className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4 px-2'
              style={{width: '120px'}}
              onClick={() => addFloorHandlerForAddBuilding(index, clusterIndex, buildingIndex)}
            >
              Add Floor
            </button>
            <p className='m-0 head-text text-start'>
              Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based
              on the order of each floor set below along with the properties added under each floor.
            </p>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <table className='table table-borderless'>
            <thead>
              <tr>
                <th className='head-text text-start p-0 pb-2' style={{minWidth: '20px', maxWidth: '20px'}}></th>
                <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                  Floors
                  <span className='ms-2'>
                    <OverlayTrigger
                      placement='right'
                      delay={{show: 250, hide: 200}}
                      overlay={
                        <Tooltip id='button-tooltip-2'>
                          {' '}
                          <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of each floor added,
                          giving each floor it’s own name. If you do not wish to include a prefix, you may leave this field blank. You will be able to
                          edit any floor name once it has been added.
                          <br></br>
                          <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start and end based on
                          the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect the same floor names within your
                          building. If you are adding a single floor your ‘Start Range’ and ‘ End Range’ should include the same value.
                          <br></br>
                          <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’ and ‘End Range’ will
                          be included in the total count.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#146C6A '
                          fontSize={14}
                          fontWeight={500}
                          className='cursor-pointer'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                <th className='head-text text-start p-0  ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                  Floor Name
                </th>
                <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                  Floor Range
                </th>
                <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                  Example
                </th>
                <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                  Total Floors
                </th>
              </tr>
            </thead>
            <tbody className='text-start'>
              {buildingItem?.addFloor?.map((floorItem: any, floorIndex: any) => {
                //   const isDuplicate = duplicateIndicesForMixedClusterAddBuilding.includes(floorIndex)
                return (
                  <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                    <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                      <span
                        className={`${
                          allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1 ? 'me-3' : ''
                        }`}
                      >
                        {floorIndex === 0 &&
                          allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1 && (
                            <img
                              onClick={() => swapRow(floorIndex, floorIndex + 1, index, clusterIndex, buildingIndex, floorIndex)}
                              style={{cursor: 'pointer'}}
                              src={carrotArrowDown}
                              height={12}
                              width={12}
                            />
                          )}
                        {floorIndex === allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length - 1 &&
                          allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length > 1 && (
                            <img
                              onClick={() => swapRow(floorIndex - 1, floorIndex, index, clusterIndex, buildingIndex, floorIndex)}
                              style={{cursor: 'pointer'}}
                              src={carrotArrowUp}
                              height={12}
                              width={12}
                            />
                          )}
                        {floorIndex !== 0 &&
                          floorIndex !== allCluster[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length - 1 && (
                            <span className='d-flex flex-column'>
                              <img
                                style={{cursor: 'pointer'}}
                                src={carrotArrowUp}
                                height={12}
                                width={12}
                                onClick={() => swapRow(floorIndex - 1, floorIndex, index, clusterIndex, buildingIndex, floorIndex)}
                              />
                              <img
                                style={{cursor: 'pointer'}}
                                src={carrotArrowDown}
                                height={12}
                                width={12}
                                onClick={() => swapRow(floorIndex, floorIndex + 1, index, clusterIndex, buildingIndex, floorIndex)}
                              />
                            </span>
                          )}
                      </span>
                    </td>

                    <td className='p-0  py-3' style={{minWidth: '170px'}}>
                      <Select
                        styles={{
                          ...customStyles,
                          container: (defaultStyles: any) => ({
                            ...defaultStyles,
                            width: '100%',
                          }),
                        }}
                        isSearchable={false}
                        options={floorOptionForUpdateCluster(index, clusterIndex, buildingIndex, floorIndex)}
                        value={floorItem?.floorType}
                        name='floor'
                        onChange={(e) => {
                          const values = [...allCluster]
                          values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorType = e

                          if (e.value === 0) {
                            values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].startRangeError = false
                            values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].endRangeError = false
                            values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorStartRange = 1
                            values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorEndRange = 1
                          }

                          const updatedFloor = checkForDuplicatesForBuildingCluster(
                            values[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor
                          )
                          values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
                          setCluster(values)
                          setIsCTAHidden(false)
                        }}
                        placeholder={'Select Floor Type'}
                      />
                      {floorItem.isDuplicate && (
                        <p className='pb-0 mb-0' style={{color: 'red'}}>
                          Floor Name is duplicated
                        </p>
                      )}
                    </td>
                    <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Prefix'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Prefix'}}
                            value={floorItem?.name}
                            onChange={(e) => {
                              const values = [...allCluster]
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].name = captilizeWord

                              const updatedFloor = checkForDuplicatesForBuildingCluster(
                                values[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor
                              )
                              values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
                              setCluster(values)
                              setIsCTAHidden(false)
                            }}
                          />
                        </Paper>
                      </div>
                    </td>
                    <td className='p-0 min-w-100px ps-7 py-3' style={{maxWidth: '220px'}}>
                      <div className='d-flex ' style={{columnGap: '10px'}}>
                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              disabled={floorItem?.floorType === 0}
                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                              placeholder='Start Range'
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'Start Range', min: 1}}
                              value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                              onChange={(e: any) => {
                                if (e.target.value < 0) {
                                  return
                                }
                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                const values = [...allCluster]

                                if (floorItem?.floorEndRange !== '' && floorItem?.floorEndRange < Number(e.target.value) && e.target.value != '') {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].startRangeError =
                                    true
                                } else {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].startRangeError =
                                    false
                                }

                                if (floorItem?.floorEndRange !== '' && floorItem?.floorEndRange >= Number(e.target.value) && e.target.value != '') {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].endRangeError = false
                                }

                                values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorStartRange =
                                  newValue ? Number(newValue) : ''

                                const updatedFloor = checkForDuplicatesForBuildingCluster(
                                  values[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                )
                                values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
                                setCluster(values)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                          {floorItem?.startRangeError && (
                            <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                              The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                            </p>
                          )}
                        </div>
                        <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                          <Paper component='form' style={searchContainerStyle}>
                            <InputBase
                              disabled={floorItem?.floorType === 0}
                              placeholder='End Range'
                              type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                              style={inputBaseStyle}
                              inputProps={{'aria-label': 'End Range', min: 1}}
                              value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                              onChange={(e: any) => {
                                if (e.target.value < 0) {
                                  return
                                }
                                const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                const values = [...allCluster]

                                if (e.target.value != '' && Number(e.target.value) < floorItem.floorStartRange && floorItem.floorStartRange != '') {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].endRangeError = true
                                } else {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].endRangeError = false
                                }

                                if (
                                  e.target.value !== '' &&
                                  Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                  floorItem.floorStartRange != ''
                                ) {
                                  values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].startRangeError =
                                    false
                                }

                                values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor[floorIndex].floorEndRange =
                                  newValue ? Number(newValue) : ''

                                const updatedFloor = checkForDuplicatesForBuildingCluster(
                                  values[index]?.updateMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                )
                                values[index].updateMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloor
                                setCluster(values)
                                setIsCTAHidden(false)
                              }}
                            />
                          </Paper>
                          {floorItem?.endRangeError && (
                            <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                              The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                            </p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                      <div className='text-center  d-flex justify-content-center align-items-center'>
                        <div className='mt-2'>
                          {floorItem?.floorType?.value != 0 ? (
                            <p className='m-0 head-text '>
                              <i>
                                {' '}
                                {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                              </i>
                            </p>
                          ) : (
                            <p className='m-0 head-text '>
                              <i> {floorItem?.name}</i>
                            </p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                      <div className='text-center d-flex justify-content-center align-items-center'>
                        <div className='mt-1'>
                          <h3 className='m-0 head-text'>
                            {/* {`${floorItem?.floorEndRange - floorItem?.floorStartRange + 1}`} */}
                            {`${
                              floorItem?.floorStartRange > floorItem?.floorEndRange ? '-' : floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                            }`}
                          </h3>
                        </div>
                        {buildingItem?.addFloor?.length > 1 && (
                          <img
                            className='ms-7'
                            style={{cursor: 'pointer'}}
                            src={redCross}
                            height={14}
                            width={14}
                            onClick={() => handleRemoveFloorForAddBuilding(index, clusterIndex, buildingIndex, floorIndex)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import trashImg from '../../../../img/trash.png'
import AddAttachments from './AddAttachments'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import invoiceGreen from '../../../../img/invoice-green.png'
import pdfIcon from '../../../../img/pdf.svg'
import axios from 'axios'
import AttachmentPreview from '../../General/AttachmentsPreview'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'

interface OtherAttachmentsProps {
  showw?: any
  handleClose?: any
  expenseData?: any
  updateParent?: any
}

const OtherAttachments: React.FC<OtherAttachmentsProps> = ({
  showw,
  handleClose,
  expenseData,
  updateParent,
}) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [showAttachAdd, setShowAttachAdd] = React.useState<any>(false)

  let [expData, setExpData] = useState<any>(expenseData)

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})

  useEffect(() => {
    setExpData(expenseData)
  }, [expenseData])

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const [attachmentsForPreview, setAttachmentsForPreview] = useState<any>([])

  useEffect(() => {
    let values: any = []
    {
      expData.attachments?.map((inv: any, index: any) => {
        values[index] = {
          type: '',
          url: `${Bucket}${inv?.URL}`,
          thumbnail: '',
        }

        if (inv?.URL?.split('.').pop() === 'pdf') values[index].type = 'pdf'
        else if (inv?.URL?.split('.').pop() === 'doc' || inv?.URL?.split('.').pop() === 'docx')
          values[index].type = 'doc'
        else if (
          inv?.URL?.split('.').pop() === 'jpeg' ||
          inv?.URL?.split('.').pop() === 'jpg' ||
          inv?.URL?.split('.').pop() === 'png' ||
          inv?.URL?.split('.').pop() === 'svg' ||
          inv?.URL?.split('.').pop() === 'gif'
        )
          values[index].type = 'image'
        else if (
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
        )
          values[index].type = 'video'
        else values[index].type = 'file'
      })
    }
    // console.log('***********Attachments***************')
    // console.log(values)
    setAttachmentsForPreview(values)
  }, [expData])

  const handleImageClick = () => {
    const groupName = `group_attachments_others`

      expData.attachments?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedAttachments${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
          imgeNext.setAttribute('data-fancybox', groupName)
         
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }

         // Trigger the fancybox on the clicked image
        // if (imgeNext) {
        //   imgeNext.click()
        // }
      })
  }

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 me-3 min-hg-300'}>
        <div className='d-flex  align-items-center mb-5'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Other Attachments'}
          </h2>
          <button
            type='button'
            className='btn btn-sm fw-bold px-3 green-hollow-btn ms-auto btn-fit-content '
            onClick={() => {
              setMode('add')
              setSelectedRecord({
                name: '',
                URL: '',
              })
              setTimeout(() => {
                setShowAttachAdd(true)
              }, 300)
            }}
          >
            <img src={invoiceGreen} height={18} width={14} className='me-4' /> Add Attachment
          </button>
        </div>

        <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
          <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
            {/* <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px'></th>
                <th className='text-start min-w-150px'></th>
              </tr>
            </thead> */}

            <tbody className='fw-semibold text-gray-600'>
              {expData.attachments?.map((inv: any, index: any) => {
                return (
                  <tr className='cursor-pointer'>
                    <td className='text-start ps-0'>
                      <div className='d-flex align-items-center'>
                        <div className='bullet-item me-3 mb-0 ms-1'></div>
                        <p className='mb-0'>{inv?.name}</p>
                      </div>
                    </td>
                    <td className='text-start min-w-100px'>
                      <div className='d-flex align-items-center'>
                        <img
                         src={
                          inv?.URL?.split('.')[1] === 'pdf'
                            ? pdfDocument
                            : inv?.URL?.split('.').pop() === 'doc' ||
                              inv?.URL?.split('.').pop() === 'docx'
                            ? docCocument
                            : inv?.URL?.split('.').pop() === 'jpeg' ||
                              inv?.URL?.split('.').pop() === 'jpg' ||
                              inv?.URL?.split('.').pop() === 'png' ||
                              inv?.URL?.split('.').pop() === 'svg' ||
                              inv?.URL?.split('.').pop() === 'gif'
                            ? pngImg
                            : inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                              inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                            ? ``
                            : fileIcon
                        }
                          height={30}
                          width={30}
                          className='ms-3'
                          id={`clickedAttachments${index}`}
                          onClick={()=>{handleImageClick()}}
                          // data-fancybox
                          data-src={`${Bucket}${inv?.URL}`}
                        />
                        {/* <a href={`${Bucket}${inv?.URL}`} target='_blank'> */}
                        <img
                          src={downloadIcon}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            handleDownload(`${Bucket}${inv?.URL}`)
                          }}
                        />
                        {/* </a> */}
                        <img
                          src={greenPen}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            setMode('edit')
                            setSelectedInd(index)
                            setSelectedRecord(inv)
                            setShowAttachAdd(true)
                          }}
                        />
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            let values = {...expData}
                            values.attachments.splice(index, 1)

                            setExpData(values)
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        {/* <h1>Attachment Previews</h1> */}
        {attachmentsForPreview && <AttachmentPreview attachments={attachmentsForPreview} />}
      </div>

      {showAttachAdd && mode && (
        <AddAttachments
          show={showAttachAdd}
          handleClose={() => setShowAttachAdd(false)}
          mode={mode}
          updateData={(data: any) => {
            let values = {...expData}
            // console.log(values)
            if (mode == 'add') values.attachments.push(data)
            else values.attachments[selectedInd] = data
            setExpData(values)

            updateParent(values.attachments)
            setShowAttachAdd(false)
          }}
          selectedItem={selectedRecord}
        />
      )}
    </>
  )
}

export default OtherAttachments

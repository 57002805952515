import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import {useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import swal from 'sweetalert2'

interface ButtonProps {
  show: any
  handleClose?: any
  generatePOEnabled?: any
  updateRequest?: any
  addUploads?: any
  from?: any
}

const GeneratePOModal = ({
  show,
  handleClose,
  generatePOEnabled,
  updateRequest,
  addUploads,
  from,
}: ButtonProps) => {
  const {flg, type, id} = useParams()

  const navigate = useNavigate()

  const [selectedOption, setSelectedOption] = useState<any>('')

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-12'>
              <div className='d-flex align-items-center'>
                <h2 className='mb-0 flex-grow-1 mt-3 white-dark-theme-color'>
                  <b>Generate Purchase Order</b>
                </h2>

                <label
                  className='head-text fs-2 cursor-pointer ms-auto'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
            <div className='col-md-12 mt-5 ps-0'>
              <div className='d-flex align-items-center'>
                {selectedOption == 1 ? (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#146C6A ',
                      borderRadius: '50%',
                    }}
                    className='me-5 cursor-pointer'
                  ></div>
                ) : (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      border: '1px solid #146C6A ',
                    }}
                    onClick={() => {
                      setSelectedOption(1)
                    }}
                    className='me-5 cursor-pointer'
                  ></div>
                )}
                <p className='mb-0 white-dark-theme-color'>
                  Raise a PO for the vendor selected as your External Handling Team.
                </p>
              </div>
            </div>

            <div className='col-md-12 mt-5 ps-0'>
              <div className='d-flex align-items-center'>
                {selectedOption == 2 ? (
                  <div className='me-5 green-radio-filled cursor-pointer'></div>
                ) : (
                  <div
                    onClick={() => {
                      setSelectedOption(2)
                    }}
                    className='me-5 green-radio-hollow cursor-pointer'
                  ></div>
                )}
                <p className='mb-0 white-dark-theme-color'>Raise a PO For Another Vendor.</p>
              </div>
            </div>

            <div className='col-md-12 mt-4'>
              <div className='d-flex justify-content-center'>
                <button
                  type='button'
                  className=' mt-4 btn btn-sm fw-bold green-submit-btn d-flex justify-content-center status-w-120'
                  disabled={selectedOption == ''}
                  onClick={() => {
                    if (generatePOEnabled) {
                      if (flg == '1') {
                        swal
                          .fire({
                            html: ` 
                          <div class='fs-3'> <b>  Save Content Confirmation</b></div>
                          <div class='fs-5 mt-4'> Before proceeding to the next screen, please ensure to save the current content as it will be utilized in the upcoming screen. </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Save & Continue',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton:
                                'custom-cancel-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                              cancelButton:
                                'custom-confirm-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                              popup: 'custom-popup',
                            },
                            // reverseButtons: true,
                          })
                          .then((res: any) => {
                            if (res.isConfirmed) {
                              if (selectedOption == 1) updateRequest('purchase_order_external', 0)
                              else updateRequest('purchase_order_other', 0)
                            }
                          })
                      } else {
                        if (selectedOption == 1)
                          navigate(`/maintenance/create-request/purchase-order/${type}/1/${id}/1`, {
                            state: {
                              from: from,
                            },
                          })
                        else
                          navigate(`/maintenance/create-request/purchase-order/${type}/1/${id}/2`, {
                            state: {
                              from: from,
                            },
                          })
                      }
                    } else {
                      addUploads(selectedOption)
                    }
                  }}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default GeneratePOModal

import React from 'react'
import Chart from 'react-apexcharts'

interface OccupancyChartProps {
  chartData: DataPoint[]
}

interface DataPoint {
  time: string
  overall: {
    percentage: number
    responses: number
  }
}

// const chartData: DataPoint[] = [
//   {
//     time: '2024-05-01T00:00:00Z',
//     overall: {percent: 75, responses: 111},
//   },
//   {
//     time: '2024-05-02T00:00:00Z',
//     overall: {percent: 80, responses: 120},
//   },
//   {
//     time: '2024-05-03T00:00:00Z',
//     overall: {percent: 78, responses: 118},
//   },

//   {
//     time: '2024-05-04T00:00:00Z',
//     overall: {percent: 75, responses: 111},
//   },
//   {
//     time: '2024-05-05T00:00:00Z',
//     overall: {percent: 80, responses: 120},
//   },
//   {
//     time: '2024-05-06T00:00:00Z',
//     overall: {percent: 78, responses: 118},
//   },
//   {
//     time: '2024-05-07T00:00:00Z',
//     overall: {percent: 75, responses: 111},
//   },
//   {
//     time: '2024-05-08T00:00:00Z',
//     overall: {percent: 80, responses: 120},
//   },
//   {
//     time: '2024-05-09T00:00:00Z',
//     overall: {percent: 78, responses: 118},
//   },
//   {
//     time: '2024-05-10T00:00:00Z',
//     overall: {percent: 75, responses: 111},
//   },
//   {
//     time: '2024-05-11T00:00:00Z',
//     overall: {percent: 80, responses: 120},
//   },
//   {
//     time: '2024-05-12T00:00:00Z',
//     overall: {percent: 78, responses: 118},
//   },
//   // more data points
// ]

const SatisfactionTenantMonthly: React.FC<OccupancyChartProps> = ({ chartData }) => {
  const seriesData = {
    overall: [] as number[],
  }

  const categories: string[] = chartData?.map((dataPoint) => dataPoint?.time)

  chartData?.forEach((dataPoint) => {
    seriesData.overall.push(dataPoint?.overall?.percentage)
  })

  // Find the maximum percentage value in the data
  const maxPercentage = Math?.max(
    ...chartData?.map((dataPoint) => dataPoint?.overall?.percentage)
);

const yAxisMax = Math?.ceil(maxPercentage / 10) * 10
console.log(yAxisMax)

  const series = [
    {
      name: 'Overall',
      data: seriesData?.overall,
      color: '#FFC107', // Customize the color for the overall line
      strokeDashArray: 4,
    },
  ]

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },
    stroke: {
      curve: 'smooth', // This makes the lines smooth,
      lineCap:'round',
    },
    title: {
      text:'Tenant Satisfaction',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '800',
      },
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 0,
      offsetX: 0,
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        format: 'MMM-yy',
        rotateAlways:true,
        minHeight:70
      },
      // tickAmount: categories?.length - 1, // Adjust to show every day
      
    },
    yaxis: {
      title: {
        // text: '',
        // align,'top',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666',
        },
        offsetY: -20, // Move the title to the top of the y-axis
      },
      labels: {
        formatter: function (value: number) {
            return `${Number.isInteger(value) ? value : value?.toFixed(0)}%`;
          },
      },
      min: 0,
      max: yAxisMax == 0 ? 25 : yAxisMax,
      tickAmount: yAxisMax == 0 ? 5 : yAxisMax > 50 ? yAxisMax/10 : yAxisMax / 5,
    },

    tooltip: {
      x: {
        format: 'MMM yyyy',
        show:false,
      },
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const date = new Date(categories[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        //   day: 'numeric',
        })
        return (
          `<div class="apexcharts-tooltip-custom">` +
          `<div style="text-align:center;" class="text-decoration-underline">${date}</div>` +
          `<div style="display: flex; justify-content: space-between;">`+
              `<div style="font-weight:bold"><span style="color: #FFC107">&#9679;</span> ${Number?.isInteger(chartData?.[dataPointIndex]?.overall?.percentage) ? chartData?.[dataPointIndex]?.overall?.percentage : chartData?.[dataPointIndex]?.overall?.percentage?.toFixed(1)}% </div>`+
              `<div>(${Number?.isInteger(chartData?.[dataPointIndex]?.overall?.responses) ? chartData?.[dataPointIndex]?.overall?.responses : chartData?.[dataPointIndex]?.overall?.responses?.toFixed(1)} Responses)</div>`+
          `<div>`+
          `</div>`
        )
      },
    },
  }

  return (
    // <div className='row'>
    //   <div className='col-6'>
        <div className='card card-flush py-4 px-2'>
          <div id='chart'>
            <Chart options={options} series={series ? series : []} type='line' height={350} />
          </div>
        </div>
    //   </div>
    // </div>
  )
}

export default SatisfactionTenantMonthly

import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import './style.scss'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import redCross from '../../../../img/remove.png'
import addWhite from '../../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import sync from '../../../../img/sync.png'

interface ButtonProps {
  show: any
  handleSubmitId: (message: string) => void
  handleClose: any
  addedProperties: any
}

const SelectPropertyModal = ({handleClose, show, addedProperties, handleSubmitId}: ButtonProps) => {
  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [result, setResult] = useState<any>()
  const [searchUnitNo, setSearchUnitNo] = useState<any>()
  const [searchTenant, setSearchTenant] = useState<any>()
  const [selectedName, setSelectedName] = useState<any>()
  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>([])

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>([])

  const [building, setBuilding] = useState<any>(false)
  const [community, setCommunity] = useState<any>(false)

  const [occupancyGrpsLists, setOccupancyGrpsLists] = useState<any>([
    {
      name: 'Occupied',
      label: 'Occupied',
      occupy: 1,
      value: 1,
    },
    {name: 'Vacant', label: 'Vacant', occupy: 0, value: 2},
  ])
  const [selectedOccupancyGrps, setSelectedOccupancyGrps] = useState<any>([])

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  const [selectedFloorGrps, setSelectedFloorGrps] = useState<any>([])
  const [floorGrpsLists, setFloorGrpsLists] = useState<any>([])

  let totalSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const setDefaultOccupancy = () => {
    setSelectedOccupancyGrps(occupancyGrpsLists)
  }

  // Development
  const getDevelopment = () => {
    const body = {
      search: searchDevelopment,
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }
          })

        setDevelopmentLists(values)

        // if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {})
  }

  React.useEffect(() => {
    getDevelopment()
    // getFloor()
    // setDefaultOccupancy()
  }, [])

  React.useEffect(() => {
    if (selectedDevelopments?.length > 0) setIsFirst(false)
  }, [selectedDevelopments])

  // Clusters
  const getClusters = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      clusterIds: [],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setClustersLists(values)

        if (selectedClusters?.length == 0 && selectedDevelopments?.length != 0)
          setSelectedClusters(values)
        else {
          const filteredValues = selectedClusters.filter((cluster: any) =>
            values.some((value: any) => value.value === cluster.value)
          )
          if (selectedDevelopments?.length == 0) {
            setClustersLists([])
            setSelectedClusters([])
          } else setSelectedClusters(filteredValues)
        }

        // if (isFirst) setSelectedClusters(values)
        // else {
        //   const filteredValues = selectedClusters.filter((cluster: any) =>
        //     values.some((value: any) => value.value === cluster.value)
        //   )

        //   setSelectedClusters(filteredValues)
        // }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst) getClusters()
  }, [selectedDevelopments, isFirst])

  //  Unit Groups
  const getUnitGroups = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      unitGroupIds: [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setUnitGrpsLists(values)

        if (selectedUnitGrps?.length == 0 && selectedDevelopments?.length != 0)
          setSelectedUnitGrps(values)
        else {
          const filteredValues = selectedUnitGrps.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setUnitGrpsLists([])
            setSelectedUnitGrps([])
          } else setSelectedUnitGrps(filteredValues)
        }  

        if(clustIds?.length == 0){
          setUnitGrpsLists([])
          setSelectedUnitGrps([])
        }

        // if (isFirst) setSelectedUnitGrps(values)
        // else {
        //   const filteredValues = selectedUnitGrps.filter((grp: any) =>
        //     values.some((value: any) => value.value === grp.value)
        //   )

        //   setSelectedUnitGrps(filteredValues)
        // }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst) getUnitGroups()
  }, [selectedClusters, isFirst])

  //
  const getFloor = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
    }
    
    ApiPost(`corporate/development/floor/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setFloorGrpsLists(values)

        if (selectedFloorGrps?.length == 0 && selectedDevelopments?.length != 0)
          setSelectedFloorGrps(values)
        else {
          const filteredValues = selectedFloorGrps.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setFloorGrpsLists([])
            setSelectedFloorGrps([])
          } else setSelectedFloorGrps(filteredValues)
        }

        // if (isFirst) setSelectedFloorGrps(values)
        // else {
        //   const filteredValues = selectedFloorGrps.filter((grp: any) =>
        //     values.some((value: any) => value.value === grp.value)
        //   )

        //   setSelectedFloorGrps(filteredValues)
        // }

        setIsFirst(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst) getFloor()
  }, [selectedDevelopments, isFirst])

  //
  const updateList = async () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitgrpIds: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitgrpIds[ind] = dev?.value
      })

    let floorsIDs: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorsIDs[ind] = dev?.value
      })

    let occupancyStatus: any =
      selectedOccupancyGrps?.length > 0 &&
      selectedOccupancyGrps.map((occupancyItem: any) => occupancyItem.occupy)

    const body = {
      searchDevelopment: searchDevelopment,
      searchUnitNo: searchUnitNo,
      searchTenant: searchTenant,
      // communityId: comm,
      developmentIds: devIds,
      clusterIds: clustIds,
      unitGroupIds: unitgrpIds,
      floorId: floorsIDs,
      occupy: occupancyStatus,
      unitTypes:[0,1,3,4,5,6,7,8,9]
    }
    await ApiPost(`corporate/announcement/get/development`, body)
      .then((res) => {
        const isNotHaveData = res?.data?.data?.development_data.length === 0
        if (isNotHaveData) {
          setResult([])
          return
        }
        let values = [...res?.data?.data?.development_data]

        // console.log(addedProperties)

        res?.data?.data?.development_data?.map((res: any, ind: any) => {
          values[ind].check = false
        })
        for (let j = 0; j < res?.data?.data?.development_data?.length; j++) {
          // let isCheckForDev = false
          let isCheckForAll = false
          let isChckforTenancy = false
          let isCheckForTenant = false
          let isCheckForUnit = false
          // let isCheckForTenancy = false
          // console.log('community',values[j]?.community?._id == undefined);

          if (
            // values[j]?.building[0]?._id != undefined &&
            values[j]?._id != undefined &&
            (values[j]?.community?.[0]?._id != undefined ||
              values[j]?.building?.[0]?._id != undefined) &&
            values[j]?.tenancy?.tenant?._id != undefined
          ) {
            isCheckForAll = true
          } else if (
            // values[j]?.building[0]?._id != undefined &&
            values[j]?._id != undefined &&
            (values[j]?.community?.[0]?._id != undefined ||
              values[j]?.building?.[0]?._id != undefined) &&
            values[j]?.tenancy?.tenant?._id == undefined
          )
            isCheckForTenant = true

          // if (
          //   // values[j]?.building[0]?._id != undefined &&
          //   values[j]?._id != undefined &&
          //   values[j]?.tenancy?._id == undefined &&
          //   values[j]?.tenancy?.tenant?._id == undefined
          // )
          //   isCheckForUnit = true
          // else if (
          //   // values[j]?.building[0]?._id != undefined &&
          //   values[j]?._id != undefined &&
          //   values[j]?.tenancy?._id != undefined &&
          //   values[j]?.tenancy?.tenant?._id != undefined
          // )
          //   isCheckForAll = true
          // else if (
          //   values[j]?._id != undefined &&
          //   values[j]?.tenancy?._id != undefined &&
          //   values[j]?.tenancy?.tenant?._id == undefined
          // )
          //   isChckforTenancy = true
          // else if (
          //   // values[j]?.building[0]?._id != undefined &&
          //   values[j]?._id != undefined &&
          //   values[j]?.tenancy?._id == undefined &&
          //   values[j]?.tenancy?.tenant?._id != undefined
          // )
          //   isCheckForTenant = true
          // else if (
          //   values[j]?.building[0]?._id != undefined &&
          //   // values[j]?.tenancy?._id == undefined &&
          //   values[j]?.tenancy?.tenant?._id == undefined &&
          //   values[j]?._id != undefined
          // )
          //   isCheckForUnit = true
          // else if (
          //   values[j]?._id != undefined &&
          //   values[j]?.community?._id != undefined &&
          //   values[j]?.building?._id != undefined &&
          //   values[j]?.tenancy?.tenant?._id != undefined
          // )
          //   isCheckForTenancy = true

          // console.log('dev',isCheckForDev)
          // console.log('all',isCheckForAll)
          // console.log('comm',isChckforComm)
          // console.log('buildijng',isCheckForBuild)
          // console.log('tenancy',isCheckForTenancy)

          // Dev only
          // if (isCheckForDev) {
          //   console.log('dev')

          //   let x = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.developmentId == values[j]?.building[0]?._id) {
          //       x = true
          //       return
          //     }
          //   })

          //   if (x) values[j].check = true
          // }
          // if (isCheckForUnit) {
          //   console.log('dev')

          //   let x = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.unitIds == values[j]?._id) {
          //       x = true
          //       return
          //     }
          //   })

          //   if (x) values[j].check = true
          // }
          // Dev, tenancy and tenant
          // else
          if (isCheckForAll) {
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.unitIds == values[j]?._id) {
                x = true
                return
              }
            })

            let m = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?.building?.[0]?._id) {
                m = true
                return
              }
            })

            let n = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?.community?.[0]?._id) {
                n = true
                return
              }
            })

            let z = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.tenant == values[j]?.tenancy?.tenant?._id) {
                z = true
                return
              }
            })

            if (x && (m || n) && z) values[j].check = true

            // // addedProperties?.assignedProperties?.developmentIds?.includes(values[j]?._id)
            // let w = false
            // addedProperties?.map((prop: any, ind: any) => {
            //   if (prop?.assignedProperties?.unitIds == values[j]?._id) {
            //     w = true
            //     return
            //   }
            // })
            // let y = false
            // addedProperties?.map((prop: any, ind: any) => {
            //   if (
            //     prop?.assignedProperties?.tenancy?.tenant?._id == values[j]?.tenancy?.tenant?._id
            //   ) {
            //     y = true
            //     return
            //   }
            // })

            // let x = false
            // addedProperties?.map((prop: any, ind: any) => {
            //   if (prop?.assignedProperties?.developmentId == values[j]?.building[0]?._id) {
            //     x = true
            //     return
            //   }
            // })

            // let z = false
            // addedProperties?.map((prop: any, ind: any) => {
            //   if (prop?.assignedProperties?.tenancy == values[j]?.tenancy?._id) {
            //     z = true
            //     return
            //   }
            // })

            // if (w && z && y ) values[j].check = true
          } else if (isCheckForTenant) {
            let x = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.unitIds == values[j]?._id) {
                x = true
                return
              }
            })

            let m = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?.building?.[0]?._id) {
                m = true
                return
              }
            })

            let n = false
            addedProperties?.map((prop: any, ind: any) => {
              if (prop?.assignedProperties?.developmentId == values[j]?.community?.[0]?._id) {
                n = true
                return
              }
            })

            if (x && (m || n)) values[j].check = true
          }
          // Dev and Tenancy
          // else if (isChckforTenancy) {
          //   console.log('isChckforTenancy')
          //   let x = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.tenancy == values[j]?.tenancy?._id) {
          //       x = true
          //       return
          //     }
          //   })

          //   let y = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.unitIds == values[j]?._id) {
          //       y = true
          //       return
          //     }
          //   })
          //   // let y = false
          //   // addedProperties?.map((prop: any, ind: any) => {
          //   //   if (prop?.assignedProperties?.developmentId == values[j]?.building[0]?._id) {
          //   //     y = true
          //   //     return
          //   //   }
          //   // })

          //   if (x && y) values[j].check = true
          // }

          // Dev and tenant
          // else if (isCheckForTenant) {
          //   console.log('build')
          //   // let x = false
          //   // addedProperties?.map((prop: any, ind: any) => {
          //   //   if (prop?.assignedProperties?.developmentId == values[j]?.building[0]?._id) {
          //   //     x = true
          //   //     return
          //   //   }
          //   // })
          //   let x = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.unitIds == values[j]?._id) {
          //       x = true
          //       return
          //     }
          //   })

          //   let z = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.tenant == values[j]?.tenancy.tenant?._id) {
          //       z = true
          //       return
          //     }
          //   })
          //   if (x && z) values[j].check = true
          // }
          // Dev and unit
          // else if (isCheckForUnit) {
          //   console.log('unit')
          //   let x = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.developmentId == values[j]?.building[0]?._id) {
          //       x = true
          //       return
          //     }
          //   })

          //   let z = false
          //   addedProperties?.map((prop: any, ind: any) => {
          //     if (prop?.assignedProperties?.unitIds == values[j]?._id) {
          //       z = true
          //       return
          //     }
          //   })
          //   if (x && z) values[j].check = true
          // }
        }

        setResult(values)
      })
      .catch((e) => {
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    updateList()
  }, [
    searchDevelopment,
    selectedDevelopments,
    searchUnitNo,
    selectedClusters,
    selectedUnitGrps,
    searchTenant,
    selectedFloorGrps,
    selectedOccupancyGrps,
  ])

  useEffect(() => {
    console.log(selectedDevelopments)
    let isBuild: any = false
    let isComm: any = false

    if (selectedDevelopments?.length == 0) {
      // setClustersLists([])
      // setSelectedClusters([])
      // setUnitGrpsLists([])
      // setSelectedUnitGrps([])
      // setFloorGrpsLists([])
      // setSelectedFloorGrps([])
      setCommunity(true)
      setBuilding(true)
    } else {
      selectedDevelopments?.map((sl: any) => {
        if (sl?.devType == 'comm') isComm = true

        if (sl?.devType == 'build') isBuild = true
      })

      setCommunity(isComm)
      setBuilding(isBuild)
    }
  }, [selectedDevelopments])

  

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark flex-column justify-content-center '>
                <b>Add Recipients</b>
              </h2>

              <div className='d-flex ms-auto'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3 red-hollow-btn status-w-100 ps-2'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-4' /> Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn status-w-100 ps-2'
                  onClick={() => {
                    handleSubmitId(result)
                    handleClose()
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-4'/> Add
                </button>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Tenant...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchTenant}
                        onChange={(e: any) => {
                          setSearchTenant(e.target.value)
                        }}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>

                    {/* <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control'
                      name='contractNo'
                      value={searchTenant}
                      onChange={(e: any) => {
                        setSearchTenant(e.target.value)
                      }}
                      placeholder={'Tenants'}
                    /> */}
                  </Col>
                  <Col md={3} className='mb-5 property xyz tst ps-0 pe-8'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchDevelopment}
                        onChange={(e: any) => {
                          setSearchDevelopment(e.target.value)
                        }}
                        placeholder={'Development...'}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                    {/* <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control'
                      name='contractNo'
                      value={searchDevelopment}
                      onChange={(e: any) => {
                        setSearchDevelopment(e.target.value)
                      }}
                      placeholder={'Development'}
                    /> */}
                  </Col>
                  <Col md={3} className='mb-5 property xyz tst ps-0 pe-7'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder={'Unit...'}
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchUnitNo}
                        onChange={(e: any) => {
                          setSearchUnitNo(e.target.value)
                        }}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>

                    {/* <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control'
                      name='contractNo'
                      value={searchUnitNo}
                      onChange={(e: any) => {
                        setSearchUnitNo(e.target.value)
                      }}
                      placeholder={'Units'}
                    /> */}
                  </Col>

                  <Col md={3} className='mb-5 property xyz tst ps-0 pe-7'>
                    <div
                      className='filter-div d-flex align-items-center justify-content-center cursor-pointer mt-1 ms-auto'
                      onClick={() => {
                        setSearchTenant('')
                        setSearchDevelopment('')
                        setSearchUnitNo('')
                        setSelectedDevelopments([])
                        setSelectedClusters([])
                        setSelectedUnitGrps([])
                        setSelectedFloorGrps([])
                      }}
                    >
                      <img src={sync} height={20} width={22} className='cursor-pointer' />
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-3 '>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Filter
                </label>
              </div>
              <div className='col-11'>
                <>
                  <div className='d-flex test flex-wrap'>
                    {/* Development */}
                    <div
                      className={`multi-select-container property me-3 mb-5  ${
                        selectedDevelopments.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={developmentLists}
                        value={selectedDevelopments}
                        onChange={(e: any) => {
                          setSelectedDevelopments(e)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Developments',
                        }}
                        disabled={
                          searchDevelopment?.trim()?.length > 0 ||
                          searchTenant?.trim()?.length > 0 ||
                          searchUnitNo?.trim()?.length > 0
                        }
                      />
                    </div>

                    {community && (
                      <>
                        {/* Cluster */}
                        <div
                          className={`multi-select-container property ms-2 me-3 mb-5  ${
                            selectedClusters.length === 0 ? 'no-value clust ' : 'has-value '
                          }`}
                        >
                          <MultiSelect
                            options={clustersLists}
                            value={selectedClusters}
                            onChange={(e: any) => {
                              setSelectedClusters(e)
                              if(e?.length == 0){
                              setUnitGrpsLists([])
                              setSelectedUnitGrps([])
                              setResult([])
                              }
                            }}
                            labelledBy='Cluster'
                            overrideStrings={{
                              allItemsAreSelected: 'All Clusters',
                            }}
                            disabled={
                              searchDevelopment?.trim()?.length > 0 ||
                              searchTenant?.trim()?.length > 0 ||
                              searchUnitNo?.trim()?.length > 0
                            }
                          />
                        </div>

                        {/* Unit Groups */}
                        <div
                          className={`multi-select-container property ms-2 me-3 mb-5  ${
                            selectedUnitGrps.length === 0 ? 'no-value grpunit ' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={unitGrpsLists}
                            value={selectedUnitGrps}
                            onChange={(e: any) => {
                              setSelectedUnitGrps(e)
                            }}
                            labelledBy='Unit Groups'
                            overrideStrings={{
                              allItemsAreSelected: 'All Unit Groups',
                            }}
                            disabled={
                              searchDevelopment?.trim()?.length > 0 ||
                              searchTenant?.trim()?.length > 0 ||
                              searchUnitNo?.trim()?.length > 0
                            }
                          />
                        </div>
                      </>
                    )}

                    {building && (
                      <>
                        {/* Floor Status */}
                        <div
                          className={`multi-select-container property ms-2 me-5 mb-5  ${
                            selectedFloorGrps.length === 0 ? 'no-value floor' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={floorGrpsLists}
                            value={selectedFloorGrps}
                            onChange={(e: any) => {
                              setSelectedFloorGrps(e)
                            }}
                            labelledBy='Floor '
                            overrideStrings={{
                              allItemsAreSelected: 'All Floors',
                            }}
                            disabled={
                              searchDevelopment?.trim()?.length > 0 ||
                              searchTenant?.trim()?.length > 0 ||
                              searchUnitNo?.trim()?.length > 0
                            }
                          />
                        </div>
                      </>
                    )}

                    {/* Occupancy Status */}
                    <div
                      className={`multi-select-container property me-5 mb-3   ${
                        selectedOccupancyGrps.length === 0 ? 'no-value occupancy' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={occupancyGrpsLists}
                        value={selectedOccupancyGrps}
                        onChange={(e: any) => {
                          setSelectedOccupancyGrps(e)
                        }}
                        labelledBy='Occupancy Status'
                        overrideStrings={{
                          allItemsAreSelected: 'All Occupancies',
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            {result?.map((res: any, ind: any) => {
              if (res?.check == true) totalSelected = totalSelected + 1
              return <></>
            })}

            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className='table-responsive ' style={{maxHeight: '400px'}}>
                  <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='text-start w-25px  test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                totalSelected == result?.length && result?.length != 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                let values: any = [...result]
                                if (e.target.checked) {
                                  values?.map((list: any) => {
                                    list.check = true
                                  })
                                } else {
                                  values?.map((list: any) => {
                                    list.check = false
                                  })
                                }
                                setResult(values)
                              }}
                            />
                          </div>
                        </th>
                        <th className='text-start min-w-100px'>Tenant Name</th>
                        <th className='text-start min-w-100px'>Development</th>
                        <th className='text-start min-w-100px'>Unit</th>
                        <th className='text-start min-w-100px'>Occupancy Status</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                      {result?.map((rs: any, index: any) => {
                        return (
                          <tr>
                            <td className='text-start w-25px  test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={rs?.check}
                                  onChange={(e: any) => {
                                    const values = [...result]
                                    values[index].check = e.target.checked
                                    setResult(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td className='text-start min-w-100px'>
                              {rs?.tenancy
                                ? `${rs?.tenancy?.tenant?.firstName} ${rs?.tenancy?.tenant?.lastName}`
                                : `-`}
                            </td>
                            <td className='text-start min-w-100px'>
                              {rs?.community?.length > 0
                                ? `${rs?.community?.[0]?.name}`
                                : rs?.building.length > 0
                                ? `${rs?.building?.[0]?.name}`
                                : `-`}
                            </td>
                            <td className='text-start min-w-100px'>
                              {rs?.unitNo ? `${rs?.unitNo}` : `-`}
                            </td>
                            <td className='text-start min-w-100px'>
                              {rs?.occupy == 1 ? (
                                <span className='text-success '>
                                  <b>Occupied</b>
                                </span>
                              ) : rs?.occupy == 0 ? (
                                <span className='text-danger '>
                                  <b>Vacant</b>
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectPropertyModal

import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import close from '../../../img/close.png'
import submitImg from '../../../img/Submit-White.png'
import './style.scss'
import Select from 'react-select'
import {PhoneInput} from 'react-international-phone'
import 'react-international-phone/style.css'
import {ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import { capitalizeFirstLetter } from '../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  companyDetails: any
  getDetails: any
  type: any
  logo: any
}

const EditCorporateDetails = ({
  handleClose,
  show,
  companyDetails,
  getDetails,
  type,
  logo,
}: ButtonProps) => {
  const [name, setName] = useState<any>(companyDetails?.name)
  const [address, setAddress] = useState<any>(companyDetails?.address)
  const [email, setEmail] = useState<any>(companyDetails?.email)
  const [phone, setPhone] = useState<any>(companyDetails?.countryCode + companyDetails?.phoneNumber)

  const [nameError, setNameError] = useState<any>(false)
  const [addressError, setAddressError] = useState<any>(false)
  const [emailError, setEmailError] = useState<any>(false)
  const [isValidEmail, setIsValidEmail] = useState<any>(true)

  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailPattern.test(email)
  }

  //
  const updateCompanyDetails = () => {
    let ph1: any = phone?.split(' ')[0]
    let ind: any = phone?.indexOf(' ')
    let ph2: any = phone?.slice(ind + 1, phone?.length)

    let temp: any = validateEmail(email)

    if (name != '' && address != ''  && email != '' && temp) {
      let body: any

      if (type == 'invoice') {
        body = {
          isInvoice: true,
          invoiceLogo: logo,
          invoiceCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      } else  if (type == 'receipt') {
        body = {
          isReceipt: true,
          receiptLogo: logo,
          receiptCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      }
      else if (type == 'creditnote') {
        body = {
          isCreditNote: true,
          creditNoteLogo: logo,
          creditNoteCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      }
      else if (type == 'refund') {
        body = {
          isRefund: true,
          refundLogo: logo,
          refundCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      }
      else if(type == 'debitnote') {
        body = {
          isDebitNote: true,
          debitNoteLogo: logo,
          debitNoteCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      }
      else if(type == 'purchaseOrder') {
        body = {
          isPurchaseOrder: true,
          purchaseOrderLogo: logo,
          purchaseOrderCompany: {
            trn: companyDetails?.trn,
            name: name,
            address: address,
            countryCode: ph1,
            phoneNumber: ph2,
            email: email,
          },
        }
      }

      ApiPut('corporate/corporate_setting', body)
        .then((res) => {
          handleClose()
          SuccessToast('Corporate Details have been generated successfully!!!')
          getDetails()
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      if (name == '') setNameError(true)
      if (address == '') setAddressError(true)
      if (ind == '') console.log()
      if (email == '') setEmailError(true)
      if (temp == false && email != '') {
        setIsValidEmail(temp)
        setEmailError(true)
      }
    }
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Corporate Details</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className=''>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-2' >
              {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end'>
           
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center text-end pe-3'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-4 text-white generate-btn status-w-180 ms-auto'
                onClick={() => updateCompanyDetails()}
              >
                <img
                  src={submitImg}
                  height={20}
                  width={20}
                  className='ms-1 me-5'
                />{' '}
                Submit
              </button>
            </Col>
          </Row>
          <Row className='align-items-end mt-5'>
            <Col  xs={12} sm={6} md={6} lg={6} className='align-items-center  px-3 mb-5'>
              <label className='required mb-2 label-color light-dark-theme-color'>Name</label>
              <input
                type='text'
                className={
                  nameError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Name'
                name='item_id'
                value={name}
                onChange={(event: any) => {
                  let newVal:any = capitalizeFirstLetter(event?.target.value)
                  setName(newVal)
                  setNameError(false)
                }}
                autoComplete='off'
              />
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className='align-items-center px-3  mb-5'>
              <label className='required mb-2 label-color light-dark-theme-color'>Address</label>
              <input
                type='text'
                className={
                  addressError
                    ? 'form-control form-control-solid-bg  border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Address'
                name='item_id'
                value={address}
                onChange={(event: any) => {
                  let newVal:any = capitalizeFirstLetter(event?.target.value)
                  setAddress(newVal)
                  setAddressError(false)
                }}
                autoComplete='off'
              />
            </Col>
          </Row>
          <Row>
            <Col  xs={12} sm={6} md={6} lg={6} className='align-items-center  px-3  mb-5'>
              <label className='required mb-2 label-color light-dark-theme-color'>Contact No.</label>
              <PhoneInput
                defaultCountry='ua'
                value={phone}
                onChange={(phone) => {
                  setPhone(phone)
                }}
              />
            </Col>

            <Col  xs={12} sm={6} md={6} lg={6} className='align-items-center px-3 mb-5'>
              <label className='required mb-2 label-color light-dark-theme-color'>Email</label>
              <input
                type='email'
                className={
                  emailError
                    ? 'form-control form-control-solid-bg  border-danger'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Email'
                name='item_id'
                value={email}
                onChange={(event: any) => {
                  setEmail(event?.target.value)
                  setEmailError(false)
                  if (!isValidEmail) {
                    setIsValidEmail(validateEmail(event.target.value))
                  }
                }}
                autoComplete='off'
              />
              {!isValidEmail && <p className='text-danger'>Please Enter a valid Email Address</p>}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default EditCorporateDetails

import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import noData from '../../../img/NoData1.svg'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
// import TenanciesFilter from './TenanciesFilter'
// import ReactPaginate from 'react-paginate'
// import NewMessage from './NewMessage'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
// import MoveInOutTable from '../Tenancies/Tables/MoveInOutTable'
// import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import leftArrow from '../../../img/arrowleft.svg'
import zoom from '../../../img/zoom.svg'
import {setComefrom, setRequestorId} from '../../../redux/counterSlice'
import backArrow from '../../../img/back-arrow.png'



function RequestView() {
  const navigate = useNavigate()
  const {state} = useLocation()
  const [count, setCount] = useState<any>([])
  const [byiddata, setbyiddata] = useState<any>(false)

  const [propertiType, setPropertiType] = useState<any>('Overview')
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [date, setDate] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>('')
  const [type, setType] = useState('')
  const [page, setPage] = useState<any>(1)
  const [tableData, setTableData] = useState([])
  const [newMessageModal, setNewMessageModal] = useState(false)
  const intl = useIntl()
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertiTypeTH, setPropertiTypeTH] = useState('Tenants')
  const [filter, setfilter] = useState<any>([])
  const [datefilter, setdatefilter] = useState<any>({})
  const [typetable, settypetable] = useState<any>()
  const [showTable, setShowTable] = useState<any>(1)
  const [page1, setPage1] = useState<any>(1)
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [tabledata1, settabledata1] = useState<any>([])
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)

  const [moveinview, setmoveinview] = useState<any>(false)
  const [moveoutview, setmoveoutview] = useState<any>(false)
  const [genralview, setgenralview] = useState<any>(false)
  const [otherview, setotherview] = useState<any>(false)
  const [renewalview, setrenewalview] = useState<boolean>(false)
  const [terminationview, setterminationview] = useState<boolean>(false)
  const [show_status_btn, setShow_status_btn] = useState<boolean>(true)
  const [show_img, setShow_img] = useState<any>(false)
  const [formData, setFormData] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
  })
  const dispatch = useDispatch()

  

  const handlePageClick1 = (event: any) => {
    setPage1(event.selected + 1)
  }

  //////////////////
  const {unitId, requestId, reqType} = useParams()

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  useEffect(() => {
    dispatch(setComefrom('tenant-req'))
    calldatabyid()
  }, [])

  const calldatabyid = async () => {
    await ApiGet(`corporate/tenant_request?tenantRequestId=${requestId}&type=${reqType}`)
      .then((res: any) => {
        setbyiddata(res?.data?.data[0])
        //  setUnitDataApi(res?.data?.data)
        dispatch(setRequestorId(requestId))
        // dispatch(setComefrom('tenant'))
        if (reqType === '0') setPropertiTypeTH('MoveIn')
        else if (reqType === '1') setPropertiTypeTH('MoveOut')
        else if (reqType === '2') setPropertiTypeTH('TenancyRenewals')
        else if (reqType === '3') setPropertiTypeTH('TenancyTerminations')
        else if (reqType === '4') setPropertiTypeTH('GeneralComplaints')
        else setPropertiTypeTH('OtherRequests')
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
    setType(formData?.unitType)
  }
  const getCount = () => {
    ApiGet('corporate/tenant/count')
      .then((res: any) => {
        setCount(res?.data?.data)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }
  const tenantData = () => {
    let body = {
      page: page,
      limit: 10,
      searchTenant: formData?.searchTenant,
      searchContractNo: formData?.searchContractNo,
      tenancyStatus: Number(formData?.tenancyStatus),
      remainingDays: Number(formData?.remainingDays),
    }
    ApiPost('corporate/tenant/overview', body)
      .then((res: any) => {
        setTableData(res?.data?.data?.tenant_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  useEffect(() => {
    getCount()
    tenantData()
  }, [
    page,
    formData?.searchTenant,
    formData?.searchContractNo,
    formData?.tenancyStatus,
    formData?.remainingDays,
  ])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const requeststatuschange = async (id: any, type: any, state: any) => {
    console.log(id, type)
    let body = {
      id: id,
      status: type,
    }
    ApiPut(`corporate/tenant_request/status`, body)
      .then((res: any) => {
        SuccessToast(res.data.message)
        if (state == 0) {
          setPropertiTypeTH('MoveIn')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 0)
          settypetable(0)
          setmoveinview(false)
        } else if (state == 1) {
          setPropertiTypeTH('MoveOut')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 1)
          settypetable(1)
          setmoveoutview(false)
        } else if (state == 2) {
          setPropertiTypeTH('TenancyRenewals')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 2)
          settypetable(2)
          setrenewalview(false)
        } else if (state == 3) {
          setPropertiTypeTH('TenancyTerminations')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 3)
          settypetable(3)
          setterminationview(false)
        } else if (state == 5) {
          setPropertiTypeTH('GeneralComplaints')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 4)
          settypetable(5)
          setgenralview(false)
        } else if (state == 6) {
          setPropertiTypeTH('OtherRequests')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 5)
          settypetable(6)
          setotherview(false)
        }
        navigate(-1)

        // getTenancyPayment()
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const callmoveindata = (page: any, unitids: any, date: any, typetable: any) => {
    setShowTable(1)
    let body: any = {
      page: page,
      limit: 10,
      type: typetable,
      unitIds: [],
    }
    if (unitids?.length > 0) body.unitIds = unitids
    if (date?.startdate && date?.enddate) {
      body.startDate = date?.startdate
      body.endDate = date?.enddate
    }

    ApiPost('corporate/tenant_request/get', body)
      .then((res: any) => {
        console.log(res)
        settabledata1(res?.data?.data?.tenant_request_data)
        setPage1(res?.data?.data?.state?.page)
        // setPageLimit1(res?.data?.data?.state?.page_limit)

        // setPreviousTenancy(res?.data?.data)
      })
      .catch((err: any) => console.log('err', err))
  }

  const callextrapagere = () => {
    setShowCreateAppModal1(false)
    if (propertiTypeTH == 'MoveIn') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 0)
      settypetable(0)
      setmoveinview(false)
    } else if (propertiTypeTH == 'MoveOut') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 1)
      settypetable(1)
      setmoveoutview(false)
    }
    // setPropertiTypeTH('MoveIn')
  }

  const setradiobut = async (state: any) => {
    let body = {
      id: byiddata?._id,
      priority: state,
    }
    ApiPut(`corporate/tenant_request`, body)
      .then((res: any) => {
        SuccessToast(res.data.message)
        if (propertiTypeTH == 'GeneralComplaints') {
          //   calldatabyid(byiddata?._id, 4)
        } else if (propertiTypeTH == 'OtherRequests') {
          //   calldatabyid(byiddata?._id, 5)
        }
        // getTenancyPayment()
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const handleClose = () => {
    setShowCreateAppModal(false)
    setShowModal('')
    setDate(false)
    console.log('\nclicked', 'clicked')
  }

  const handleData = (newData: any) => {
    console.log(newData, 'jayrak')
    // if(newData?.length>0){
    setfilter(newData)
    callmoveindata(page1, newData, datefilter, typetable)
    // }
  }
  const handleData1 = (newData: any) => {
    console.log(newData, 'sandip')
    // if(newData){
    setdatefilter(newData)
    callmoveindata(page1, filter, newData, typetable)
    // }

    // setdatefilter(newData)
  }

  const tenantNamesearch = (e: any, type: any) => {
    // console.log('\ntabledata1', tabledata1)

    let body: any = {
      page: 1,
      limit: 10,
      type: type,
      unitIds: [],
      searchTenantName: e.target.value,
    }
    // if (unitids?.length > 0) body.unitIds = unitids
    // if (date?.startdate && date?.enddate) {
    //   body.startDate = date?.startdate
    //   body.endDate = date?.enddate
    // }

    ApiPost('corporate/tenant_request/get', body)
      .then((res: any) => {
        console.log(res)
        settabledata1(res?.data?.data?.tenant_request_data)
        setPage1(res?.data?.data?.state?.page)
        // setPageLimit1(res?.data?.data?.state?.page_limit)

        // setPreviousTenancy(res?.data?.data)
      })
      .catch((err: any) => console.log('err', err))

    // if (data.length > 0) {
    //   settabledata1(
    //     tabledata1?.filter((flg: any) =>
    //       flg?.tenant?.filter((tan: any) =>
    //         tan.firstName?.toLowerCase().includes(data.toLowerCase())
    //       )
    //     )
    //   )
    // } else if (data.length == 0) {
    //   console.log('\na', '00000000000000000000')
    //   let body: any = {
    //     page: page,
    //     limit: 10,
    //     type: typetable,
    //     unitIds: [],
    //   }
    //   ApiPost('corporate/tenant_request/get', body)
    //     .then((res:any) => {
    //       settabledata1(res?.data?.data?.tenant_request_data)
    //     })
    //     .catch((err:any) => console.log('err', err))
    // }
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }
  return (
    <>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 ms-4 '>
          <div className='d-flex align-items-center gap-5 mt-2 mb-4'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigate(-1)
              }}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='18px' width='18px' />
            </span>
            <h1 className='page-heading m-0 head-text'>Request </h1>
          </div>
        </div>
      </div>
      {propertiTypeTH === 'MoveIn' && (
        <div className='grid-out p-5 container-xxl'>
          <div
            className='card tops p-5  mt-0'
            style={{justifyContent: 'space-between', flexDirection: 'row'}}
          >
            <div className='d-flex align-items-center gap-5'>
              <h3 className='m-0'>Move In Request</h3>
            </div>

            {byiddata?.status === 1 ||
            byiddata?.status === 2 ||
            byiddata?.status === 3 ||
            byiddata?.status === 4 ? (
              <></>
            ) : (
              <div className=''>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 1, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Approve
                </a>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 3, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Reject
                </a>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 4, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Cancel Request
                </a>
              </div>
            )}
          </div>
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Request Summary</h1>
            <h6 className='text-muted border-outline mx-0 my-2 '>
              <div className='d-flex align-items-center' style={{justifyContent: 'space-between'}}>
                Status
                <div
                  className={
                    byiddata?.status === 3 || byiddata?.status === 4 ? 'dot reds' : 'dot greens'
                  }
                ></div>
              </div>
              <div className='mx-1 value_black'>
                {byiddata?.status === 0
                  ? 'Received'
                  : byiddata?.status === 1
                  ? 'Approved'
                  : byiddata?.status === 2
                  ? 'Approved (Rescheduled)'
                  : byiddata?.status === 3
                  ? 'Rejected'
                  : byiddata?.status === 4
                  ? 'Cancelled'
                  : byiddata?.status === 5
                  ? 'Awaiting Vendor Confirmation'
                  : byiddata?.status === 6
                  ? 'Accepted'
                  : byiddata?.status === 7
                  ? 'Booked'
                  : byiddata?.status === 8
                  ? 'Variation Request'
                  : 'Canceled'}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Request ID
              <div className='mx-1 value_black'>{byiddata?.id}</div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Request Date
              <div className='mx-1 value_black'>
                {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Requestor
              <div className='mx-1 value_black'>
                {byiddata?.requestor?.length > 0
                  ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                  : 'N/A'}
              </div>
            </h6>
          </div>
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Property Details</h1>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Development
              {byiddata?.unit?.length > 0 ? (
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.[0]?.community?.length > 0
                    ? byiddata?.unit?.[0]?.community[0]?.name
                    : byiddata?.unit?.[0]?.building[0]?.name}
                </div>
              ) : (
                'N/A'
              )}
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2 '>
              Unit
              <div className='mx-1 value_black'>
                {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Current Contract ID
              <div className='mx-1 value_black'>
                {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
              </div>
            </h6>
          </div>
          <div className='card tops text-muted '>
            <div className='px-5 py-3 mt-0'>
              <h1 className='top green_color'>Request Details</h1>
              <h6 className='text-muted mx-0 my-2 align-items-center'>
                Move In Schedule
                <div className='d-flex align-items-center gap-5 '>
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.scheduleDate).format('DD-MM-YYYY - HH:mm')}
                  </div>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green py-2'
                    onClick={() => setShowCreateAppModal1(true)}
                  >
                    Reschedule
                  </a>
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
            </div>
            <div className='px-5 py-3 mt-0 mh-100'>
              <h6 className='text-muted border-outline mx-0 my-2 comment'>
                Tenant Comments
                <textarea
                  className='mx-1 p-3'
                  style={{height: '150px', borderRadius: '7px'}}
                  disabled
                  value={byiddata?.comment}
                ></textarea>
              </h6>
            </div>
          </div>
        </div>
      )}
      {propertiTypeTH === 'MoveOut' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Move Out Request</h3>
              </div>

              {byiddata?.status === 1 ||
              byiddata?.status === 2 ||
              byiddata?.status === 3 ||
              byiddata?.status === 4 ? (
                <></>
              ) : (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 1, 1)}
                  >
                    Approve
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 3, 1)}
                  >
                    Reject
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 4, 1)}
                  >
                    Cancel Request
                  </a>
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 || byiddata?.status === 4 ? 'dot reds' : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'Approved'
                    : byiddata?.status === 2
                    ? 'Approved (Rescheduled)'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Cancelled'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted '>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Request Details</h1>
                <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Move Out Schedule
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {moment(byiddata?.scheduleDate).format('DD-MM-YYYY HH:mm')}
                    </div>
                    <div className='col-md-4'>
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-10'
                        onClick={() => setShowCreateAppModal1(true)}
                      >
                        Reschedule
                      </a>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0 mh-100'>
                <h6 className='text-muted border-outline mx-0 my-2 comment'>
                  Tenant Comments
                  <textarea
                    className='mx-1 p-3'
                    style={{height: '150px', borderRadius: '7px'}}
                    disabled
                    value={byiddata?.comment}
                  ></textarea>
                </h6>
              </div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'TenancyRenewals' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Renewal Request</h3>
              </div>

              {byiddata?.status === 4 || byiddata?.status === 6 || byiddata?.status === 5 ? (
                <></>
              ) : (
                <div className=''>
                  {byiddata?.status === 0 && (
                    <>
                      {byiddata?.tenancy?.[0]?.previousTenancyId === null ||
                      byiddata?.tenancy?.length == 0 ? (
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          // onClick={() => requeststatuschange(byiddata?._id, 1, 2)}
                          onClick={() => {
                            localStorage.setItem('page', 'tenants')
                            navigate(
                              `/tenancy/draft/4/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]._id}`,
                              {
                                state: {
                                  tenantReqId: byiddata?._id,
                                },
                              }
                            )
                            // navigate(
                            //   `/renew-tenancy/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]._id}`,
                            //   {
                            //     state: {
                            //       tenantReqId: byiddata?._id,
                            //     },
                            //   }
                            // )
                            
                            // navigate(
                            //   `/create-tenant/2/${byiddata?.tenant[0]?._id}/${byiddata?.unit?.[0]?.tenancy?.[0]?._id}`
                            // )

                           
                          }}
                        >
                          Renew Tenancy
                        </a>
                      ) : (
                        <>
                          {byiddata?.tenancy?.[0]?.isDraft === true && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                              // style={{border:'2px solid #b89714', color: '#b89714'}}
                              // onClick={() => requeststatuschange(byiddata?._id, 1, 2)}
                              onClick={() => {
                                localStorage.setItem('page', 'tenants')
                                // navigate(
                                //   `/create-tenant/2/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                                // )
                                navigate(
                                  `/tenancy/draft/2/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                                )
                              }}
                            >
                              Renew Tenancy
                            </a>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 2)}
                  >
                    {}
                    Cancel
                  </a>
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 || byiddata?.status === 4 || byiddata?.status === 5
                        ? 'dot reds'
                        : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {
                    byiddata?.status === 0
                      ? 'Received'
                      : byiddata?.status === 1
                      ? 'Tenant Pending Approval'
                      : byiddata?.status === 2
                      ? 'Tenant Approved'
                      : byiddata?.status === 3 || byiddata?.status === 3
                      ? 'Tenant Rejected'
                      : byiddata?.status === 4
                      ? 'Cancelled'
                      : byiddata?.status === 5
                      ? 'Cancelled'
                      : '-'
                    // : byiddata?.status === 6
                    // ? 'Accepted'
                    // : byiddata?.status === 7
                    // ? 'Booked'
                    // : byiddata?.status === 8
                    // ? 'Variation Request'
                    // : 'Canceled'}
                  }
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Status Date & Time
                <div className='mx-1 value_black'>
                  {' '}
                  {byiddata?.statusDateTime
                    ? `${moment(byiddata?.statusDateTime).format('DD/MM/YYYY')} - ${timeConvert(
                        byiddata?.statusDateTime
                      )}`
                    : '-'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD/MM/YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {Object?.keys(byiddata?.requestor)?.length != 0
                    ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <p className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </p>
              </h6>
            </div>
            <div className='card tops text-muted '>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Renewal Details</h1>

                <h6 className='mx-0 text-muted'>
                  Start
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.startDate).format('DD-MM-YYYY')}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  End
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.endDate).format('DD-MM-YYYY')}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Duration
                  <div className='mx-1 value_black'>
                    {byiddata?.tenancy?.length > 0
                      ? `${byiddata?.tenancy?.[0]?.duration?.days} Days`
                      : '-'}{' '}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Remaining
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.length > 0
                      ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                      : 'N/A'}{' '}
                    Days
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0'></div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'TenancyTerminations' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Termination Request</h3>
              </div>

              {byiddata?.status === 4 || byiddata?.status === 5 || byiddata?.status === 6 ? (
                <></>
              ) : byiddata?.status === 10 ? (
                <div className='d-flex'>
                  <a
                    className='btn btn-sm fw-bold terminate-btn mx-3'
                    onClick={() => {
                      navigate(
                        `/tenancy/create/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                      )
                    }}
                  >
                    Continue Termination
                  </a>

                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                  >
                    Cancel
                  </a>
                </div>
              ) : byiddata?.status === 2 || byiddata?.status === 1 ? (
                <>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                  >
                    Cancel
                  </a>
                </>
              ) : (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() =>
                      
                      // navigate(
                      //   `/create-tenant/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`,
                         navigate(`/tenancy/create/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`,
                        {state: {terminationDate: byiddata?.terminationDate}}
                      )
                    }
                  >
                    Terminate Tenancy
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                  >
                    Cancel
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>

            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 || byiddata?.status === 5 || byiddata?.status === 4
                        ? 'dot reds'
                        : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Requested'
                    : byiddata?.status === 1
                    ? 'Pending Tenant Approval'
                    : byiddata?.status === 2
                    ? 'Tenant Approved'
                    : byiddata?.status === 3
                    ? 'Tenant Rejected'
                    : byiddata?.status == 4 || byiddata?.status == 6
                    ? 'Cancelled'
                    : byiddata?.status === 5
                    ? 'Tenant Rejected'
                    : byiddata?.status === 10
                    ? 'Draft'
                    : '-'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Status Date & Time
                <div className='mx-1 value_black'>
                  {' '}
                  {byiddata?.status === 10 ? (
                    <>
                      {byiddata?.tenancy?.[0]?.draftStatusDateTime
                        ? `${moment(byiddata?.tenancy?.[0]?.draftStatusDateTime).format(
                            'DD/MM/YYYY'
                          )} - ${timeConvert(byiddata?.tenancy?.[0]?.draftStatusDateTime)}`
                        : '-'}
                    </>
                  ) : (
                    <>
                      {byiddata?.statusDateTime
                        ? `${moment(byiddata?.statusDateTime).format('DD/MM/YYYY')} - ${timeConvert(
                            byiddata?.statusDateTime
                          )}`
                        : '-'}
                    </>
                  )}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {Object?.keys(byiddata?.requestor)?.length != 0
                    ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Termination Details</h1>
                <h6 className='mx-0 text-muted'>
                  Termination Date
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.terminationDate).format('DD-MM-YYYY')}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Remaining
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.length > 0
                      ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                      : 'N/A'}{' '}
                    Days
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0'></div>
            </div>
          </div>
        </>
      )}

      {propertiTypeTH === 'TenancyUpdate' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Termination Request</h3>
              </div>

              {byiddata?.status === 2 || byiddata?.status === 3 ? (
                <></>
              ) : (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 3)}
                  >
                    Terminate Tenancy
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 3, 3)}
                  >
                    Reject
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 ||
                      byiddata?.status === 5 ||
                      byiddata?.status === 4 ||
                      byiddata?.status === 6
                        ? 'dot reds'
                        : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Request'
                    : byiddata?.status === 1
                    ? 'Termination submitted'
                    : byiddata?.status === 2
                    ? 'Tenant approved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Tenant cancelled'
                    : byiddata?.status === 5
                    ? 'Tenant rejected'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Termination Details</h1>
                <h6 className='mx-0 text-muted'>
                  Termination Date
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.terminationDate).format('DD-MM-YYYY')}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Remaining
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.length > 0
                      ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                      : 'N/A'}{' '}
                    Days
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0'></div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'GeneralComplaints' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>General Complaint Request</h3>
              </div>

              {byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className=''>
                  {byiddata?.status === 1 ? (
                    <></>
                  ) : (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 1, 5)}
                    >
                      In Progress
                    </a>
                  )}
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 5)}
                  >
                    Resolved
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div className={byiddata?.status === 3 ? 'dot reds' : 'dot greens'}></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'In Progress'
                    : byiddata?.status === 2
                    ? 'Resolved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Approved'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>

              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>General Complaint Details</h1>
                <h6 className='mx-0 text-muted'>
                  Complaint Name
                  <div className='mx-1 value_black '>{byiddata?.name}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Complaint Details
                  <div className='mx-1 value_black'>{byiddata?.comment}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Priority
                  <div className='d-flex gap-5 align-items-center'>
                    <div>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input  '
                          type='radio'
                          value='high'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='high'
                          checked={byiddata?.priority == 2 ? true : false}
                          onChange={() => setradiobut(2)}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label '
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          High
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input '
                          type='radio'
                          value='medium'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='medium'
                          checked={byiddata?.priority == 1 ? true : false}
                          onChange={() => setradiobut(1)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label '
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Medium
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input pr-5'
                          type='radio'
                          value='low'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='low'
                          checked={byiddata?.priority == 0 ? true : false}
                          onChange={() => setradiobut(0)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Low
                        </label>
                      </span>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0 d-flex flex-column gap-3'>
                <h6 className='mx-0 text-muted'>Tenant Uploaded Media</h6>
                {byiddata?.media?.length > 0 &&
                  byiddata?.media?.map((v: any, i: any) => (
                    <>
                      <a
                        className='mx-0 img_link'
                        // style={{width: '85px'}}
                        href={`${Bucket}${v}`}
                        target='_blank'
                      >
                        {/* {v.split('.')[v.split('.')?.length - 1] ? 'Image' : 'Video'} */}
                        <img
                          src={`${Bucket}${v}`}
                          alt=''
                          className='big_screen_img'
                          // onMouseEnter={() => setShow_img(true)}
                          // onMouseLeave={() => setShow_img(false)}
                        />
                        {/* <img
                            src={zoom}
                            className={show_img ? 'view_btn' : ' d-none'}
                            // onClick={() => navigate(`${Bucket}${v}`)}
                            onMouseEnter={() => setShow_img(true)}
                            onMouseLeave={() => setShow_img(true)}
                          /> */}
                      </a>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'OtherRequests' && (
        <>
          <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Other Request</h3>
              </div>

              {byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className=''>
                  {byiddata?.status === 1 ? (
                    <></>
                  ) : (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 1, 6)}
                    >
                      In Progress
                    </a>
                  )}
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 6)}
                  >
                    Resolved
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div className={byiddata?.status === 3 ? 'dot reds' : 'dot greens'}></div>
                </div>
                <div className='mx-1  value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'In Progress'
                    : byiddata?.status === 2
                    ? 'Resolved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Approved'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Properties Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.[0]?.community?.length > 0
                      ? byiddata?.unit?.[0]?.community[0]?.name
                      : byiddata?.unit?.[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Request Details</h1>
                <h6 className='mx-0 text-muted'>
                  Request Name
                  <div className='mx-1 value_black'>{byiddata?.name}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Request Details
                  <div className='mx-1 value_black'>{byiddata?.comment}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Priority
                  <div className='d-flex gap-5 align-items-center'>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2 '
                          type='radio'
                          value='high'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='high'
                          checked={byiddata?.priority == 2 ? true : false}
                          onChange={() => setradiobut(2)}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          High
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2'
                          type='radio'
                          value='medium'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='medium'
                          checked={byiddata?.priority == 1 ? true : false}
                          onChange={() => setradiobut(1)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Medium
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2'
                          type='radio'
                          value='low'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='low'
                          checked={byiddata?.priority == 0 ? true : false}
                          onChange={() => setradiobut(0)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Low
                        </label>
                      </span>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 d-flex flex-column gap-3'>
                <h6 className='m-0 text-muted'>Tenant Uploaded Media</h6>
                {byiddata?.media?.length > 0 &&
                  byiddata?.media?.map((v: any, i: any) => (
                    <>
                      <a className='mx-0 img_link' href={`${Bucket}${v}`} target='_blank'>
                        {/* {v.split('.')[v.split('.')?.length - 1] ? 'Image' : 'Video'} */}
                        <img
                          src={`${Bucket}${v}`}
                          alt=''
                          className='big_screen_img'
                          // onMouseEnter={() => setShow_img(true)}
                          // onMouseLeave={() => setShow_img(false)}
                        />
                        {/* <img
                            src={zoom}
                            className={show_img ? 'view_btn' : ' d-none'}
                            // onClick={() => navigate(`${Bucket}${v}`)}
                            onMouseEnter={() => setShow_img(true)}
                            onMouseLeave={() => setShow_img(true)}
                          /> */}
                      </a>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default RequestView

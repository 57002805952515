import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import plus from '../../../../img/add-white.png'
import open from '../../../../img/payment-col-open.png'
import close from '../../../../img/payment-col-close.png'
import userImg from '../../../../img/user.png'
import {
  ApiPost,
  Bucket,
  ApiGet,
  ApiGetNoAuth,
  ApiPut,
  ApiDelete,
  ApiUpload,
} from '../../../../apiCommon/helpers/API/ApiData'
import Select from 'react-select'
import {DatePicker, Space} from 'antd'
import closes from '../../../../img/close.svg'
import window from '../../../../img/window.png'
import ReactPaginate from 'react-paginate'
import {CarTwoTone} from '@ant-design/icons'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import {AnyIfEmpty} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'

import file from '../../../../img/file.svg'
import pen from '../../../../img/pen.png'
import {SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {AiFillDelete} from 'react-icons/ai'
import swal from 'sweetalert2'
import TenancyDetails from '../../Tenancies/TenancyDetails'
import Swal from 'sweetalert2'
import chat from '../../../../img/chat.svg'
import '../style.scss'
import {Row, Col} from 'react-bootstrap'
import AddSubTenant from './AddSubTenant'

import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import leftarrow from '../../../../img/arrowleft.svg'
import profileImg from '../../../../img/user.jpeg'
import {useDispatch, useSelector} from 'react-redux'

import send from '../../../../img/sendwhite.svg'

import filter from '../../../../img/filter.svg'

import {onMessageListener} from '../../../../apiCommon/helpers/firebase/firebase'
import io from 'socket.io-client'
import {set} from 'date-fns'

let user: any = localStorage.getItem('userData')
user = JSON.parse(user)

const socket = io('https://api.propertise.com/room')

const Tenants = () => {
  const [formData, setFormData] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
    // propertyAreaId: window.location.pathname?.split('/')[3],
  })

  const {
    register,
    control,
    //  handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const navigate = useNavigate()
  const {state} = useLocation()

  const {unitId} = useParams()

  const [showAdd, setShowAdd] = useState<any>(false)

  const [page, setPage] = useState<any>(1)
  const [tenancyId, setTenancyId] = useState<any>(null)
  const [unitData, setUnitData] = useState<any>()

  const [tenancy, setTenancy] = useState<any>()
  const [tenantDetails, setTenantDetails] = useState<any>()
  const [isEditTenant, setIsEditTenant] = useState<any>(false)
  const [isEditDocuments, setIsEditDocuments] = useState<any>(false)

  const [country, setCountry] = useState([])
  const [phone, setPhone] = useState('')

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode: any = e?.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]
    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
    setPhone(Phone)
  }

  //
  const updateTenant = (data: any, event: any) => {
    const body = {
      id: tenantDetails?._id,
      firstName: formData?.firstName ? formData?.firstName : null,
      lastName: formData?.lastName ? formData?.lastName : null,
      phoneNumber: formData?.phoneNumber?.toString() ? formData?.phoneNumber?.toString() : null,
      countryCode: formData?.countryCode?.toString() ? formData?.countryCode?.toString() : null,
      email: formData?.email ? formData?.email : null,
      nationality: formData?.nationality ? formData?.nationality : null,
      tenantSource: formData?.tenantSource ? parseInt(formData?.tenantSource) : 0,
      DOB: formData?.DOB,
      tenantType: 0,
      unitId: unitData?._id,
      tenantId: null,
      communityId: unitData?.communityId,
      buildingId: unitData?.buildingId,
    }

    ApiPut('corporate/tenant', body)
      .then((res: any) => {
        getDetails()
        setIsEditTenant(false)
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  //
  const updateDocument = () => {
    const body = {
      id: tenantDetails?._id,
      document: {
        passport: tenantDetails?.document?.passport,
        residency: tenantDetails?.document?.residency,
        id: tenantDetails?.document?.id,
        other: tenantDetails?.document?.other,
      },
    }

    ApiPut('corporate/tenant', body)
      .then((res: any) => {
        getDetails()
        setIsEditTenant(false)
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  // get unit details
  const getDetails = () => {
    ApiGet(`corporate/unit/${unitId}`)
      .then((res) => {
        setUnitData(res?.data?.data)
      })
      .catch((err) => console.log('err', err))

    const body = {
      unitId: unitId,
    }
    ApiPost(`corporate/tenancy/unitId`, body)
      .then((res) => {
        setTenancy(res?.data?.data?.[0])
        if (res?.data?.data[0]?.tenantId) {
          ApiGet(`corporate/tenant/${res?.data?.data[0]?.tenantId}`)
            .then((res) => {
              setTenancyId(res?.data?.data?.tenancy?._id)
              setTenantDetails(res?.data?.data)
              setFormData(res?.data?.data)
              getSubTenants(res?.data?.data?._id)
            })
            .catch((err) => console.log('err', err))
        }
      })
      .catch((err) => console.log('err', err))
  }

  //
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  useEffect(() => {
    setTimeout(() => {
      // getDetails()
      // getCountry()
    }, 1500)
  }, [page])

  const [create, setCreate] = useState<any>(false)
  const [subTenant, setSubTenant] = useState<any>({})
  const [editTenant, setEditTenant] = useState<any>(-1)
  const [subTenantData, setSubTenantData] = useState<any>([])
  const [subTenantId, setSubTenantId] = useState<any>(null)

  const createTenant = (val: any) => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID', res?.data?.data?._id)
        navigate('/create-tenancy/1', {
          state: {tenancyData: val, tenantData: res?.data?.data},
        })
      })
      .catch((err) => ErrorToast(err.message))
  }

  const createTenacy = () => {
    ApiPost('corporate/tenancy')
      .then((res) => {
        localStorage.setItem('newTenancyID', res?.data?.data?._id)
        createTenant(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const addSubTenant = () => {
    // setSubTenantData([...subTenantData, subTenant])
    setSubTenant({})
    setCreate(false)
    getSubTenants(tenantDetails?._id)
  }

  const deleteTenant = (id: any) => {
    ApiDelete(`corporate/tenant/${id}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        getSubTenants(tenantDetails?._id)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const updateSubTenant = (subTen: any) => {
    autoSaveTenant(1, subTenantId)
    setTimeout(() => {
      const body = {
        id: subTen?._id,
        firstName: subTen?.firstName ? subTen?.firstName : null,
        lastName: subTen?.lastName ? subTen?.lastName : null,
        phoneNumber: subTen?.phoneNumber?.toString() ? subTen?.phoneNumber?.toString() : null,
        countryCode: subTen?.countryCode?.toString() ? subTen?.countryCode?.toString() : null,
        email: subTen?.email ? subTen?.email : null,
        tenantType: subTen?.tenantType,
        tenantId: subTen?.tenantId,
      }

      ApiPut('corporate/tenant', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
    }, 1500)
  }

  const getSubTenants = (id: any) => {
    const body = {
      page: page,
      limit: 10,
      mainTenantId: '',
      tenancyId: tenancyId,
    }
    ApiPost('corporate/tenant/sub_tenant/get', body)
      .then((res) => {
        setSubTenantData(res?.data?.data?.tenant_data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const handleChnageSubTenant = (e: any) => {
    const {name, value} = e.target
    setSubTenant({...subTenant, [name]: value})
  }

  const handleNumber1 = (Phone: any, e: any) => {
    let CountryCode: any = e.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]

    setSubTenant({
      ...subTenant,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }

  const autoSaveTenant = (type: any, id: any) => {
    let body: any

    if (type == 1) {
      body = {
        id: id,
        firstName: subTenant?.firstName ? subTenant?.firstName : null,
        lastName: subTenant?.lastName ? subTenant?.lastName : null,
        phoneNumber: subTenant?.phoneNumber?.toString() ? subTenant?.phoneNumber?.toString() : null,
        countryCode: subTenant?.countryCode?.toString() ? subTenant?.countryCode?.toString() : null,
        email: subTenant?.email ? subTenant?.email : null,
        tenantType: type,
        unitId: unitData?._id,
        tenantId: tenantDetails?._id,
        communityId: unitData?.communityId,
        buildingId: unitData?.buildingId,
      }
    }

    ApiPut('corporate/tenant', body)
      .then((res) => {})
      .catch((err) => ErrorToast(err.message))
  }

  let fileLists_single: any = []

  const onFilesAddedSingle = async (event: any, type: any, isTenancy: any) => {
    const files = event.target.files
    fileLists_single = Array.from(files)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = ''
      if (type == 'tenant') {
        url = 'upload/image/tenant_other'
      } else if (type == 'tenancy') {
        url = 'upload/image/tenancy_other'
      } else if (type == 'uploadId') {
        url = 'upload/image/tenant_id'
      } else if (type == 'uploadResidency') {
        url = 'upload/image/tenant_residency'
      } else if (type == 'uploadPassport') {
        url = 'upload/image/tenant_passport'
      } else if (type == 'uploadContract') {
        url = 'upload/image/tenancy_contract'
      }

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          if (type == 'uploadId') {
            const values = {...tenantDetails}
            values.document.id = res?.data?.data?.image
            setTenantDetails(values)
            // setuploadIdTenant([res?.data?.data?.image])
          } else if (type == 'uploadResidency') {
            const values = {...tenantDetails}
            values.document.residency = res?.data?.data?.image
            setTenantDetails(values)
            // setuploadresidencyTenant([res?.data?.data?.image])
          } else if (type == 'uploadPassport') {
            const values = {...tenantDetails}
            values.document.passport = res?.data?.data?.image
            setTenantDetails(values)
            // tenantDetails.document.passport = res?.data?.data?.image
            // setuploadPassportTenant([res?.data?.data?.image])
          } else if (type == 'uploadContract') {
            // setuploadContractTenancy([res?.data?.data?.image])
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const [fileFound, setFileFound] = useState<any>(false)
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])

  let fileLists: any = []

  // const handleShow = () => setShowImageName(true)

  const onFilesAdded = async (event: any, type: any) => {
    setFileFound(false)
    const files = event.target.files
    fileLists = Array.from(files)
    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = type == 'tenancy' || type == 'tenant' ? '' : element.name
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    if (type == 'tenancy' || type == 'tenant') {
      //  handleShow()
    } else {
      // saveImageFileName()
    }
  }

  const [previousTenants, setPreviousTenants] = useState<any>()
  const [currentTenants, setCurrentTenants] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [tenId, setTenId] = useState<any>()
  const [tentId, setTentId] = useState<any>()
 

  // New Flow
  const getPreviousTenants = (val: any) => {
    let body: any

    if (val.trim().length === 0) {
      body = {
        page: page,
        limit: 10,
        unitId: unitId,
      }
    } else {
      body = {
        page: page,
        limit: 10,
        unitId: unitId,
        search: val.trim(),
      }
    }

    ApiPost(`corporate/tenant/previous/get`, body)
      .then((res) => {
        setPreviousTenants(res?.data?.data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  const getCurrentTenants = () => {
    ApiGet(`corporate/tenancy/property_profile?unitId=${unitId}`)
      .then((res) => {
        setTenId(res?.data?.data?._id)
        setTentId(res?.data?.data?.tenantId)
        setCurrentTenants(res?.data?.data?.tenant)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  const getTenantsByTenancyId = (val: any) => {
    const body = {
      tenancyId: val,
    }
    ApiPost(`corporate/tenant/get/tenancyId`, body)
      .then((res) => {
        setCurrentTenants(res?.data?.data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  const getTenancyByID = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}`)
      .then((res) => {
        setTenancy(res?.data?.data[0])
    
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    if (state?.tenancyId != undefined) {
      getTenantsByTenancyId(state?.tenancyId)
      getTenancyByID(state?.tenancyId)
    } else {
      getPreviousTenants('')
      getCurrentTenants()
    }
  }, [])

  /* ---------------- Message ------------------*/
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)
  const [chatServiceJobId, setChatServiceJobId] = useState<any>()
  const [tenanttData, setTenanttData] = useState<any>()
  const [serviceJobId, setServiceJobId] = useState<any>()

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector(
    (state: any) => state.counterReducer.chatSelectedDataList
  )
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [finalIdData, setFinalIdData] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)

  const [serviceJob, setServiceJob] = useState<any>('')

  const [noShow, setNoShow] = useState<any>(0)
  const [chatSubject, setChatSubject] = useState<any>()

  const [chatRoomId, setChatRoomId] = useState<any>(null)
  const [roomData, setRoomData] = useState<any>(null)

  // const [pageLimit, setPageLimit] = useState<any>(false)
  // const [page, setPage] = useState<any>(1)

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours: any = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes: any = Math.round(minutes)
    var rh, rm

    if (rhours < 10) rh = '0' + rhours
    else rh = rhours

    if (rminutes < 10) rm = '0' + rminutes
    else rm = rminutes

    return rh + ':' + rm
  }

  useEffect(() => {
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  // useEffect(() => {
  //   socket.once('online', handleOnline)
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   return () => {
  //     socket.off('online', handleOnline)
  //     socket.close()
  //   }
  // }, [])
  // const handleOnline = (data: any) => {
  //   console.log('socket Online event received:', data)
  //   // Update state to reflect online status
  // }
  useEffect(() => {
    socket.on('receive_message', (newMessage: any) => {
      console.log('socket newMessage', newMessage)
      console.log('messages', messages)
      setMessages([
        ...messages,
        {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()},
      ])
    })
    socket.on('new_user', (new_user) => {
      console.log('socket new_user', new_user)
    })
  }, [])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    socket.off('online')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])
  // useEffect(() => {
  //   console.log('messages', messages)
  //   socket.on('receive_message', (newMessage: any) => {
  //     console.log('socket newMessage', newMessage)
  //     console.log('socket messages', messages)
  //     setMessages([
  //       ...messages,
  //       {author: newMessage?.author, message: newMessage?.message, createdAt: new Date()},
  //     ])
  //   })
  //   socket.on('new_user', (new_user) => {
  //     console.log('socket new_user', new_user)
  //   })
  //   // Send a message to the server
  //   // Receive messages from the server
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   socket.on('online', (data) => {
  //     console.log('socket data', data)
  //     // io.emit('post', {userId: user._id})
  //   })
  // }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }

  // const [chatId, setChatId] = useState<any>('')
  // const [msgPage, setMsgPage] = useState<any>(1)
  // const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    // await ApiGet(`corporate/chat_room/${chat._id}`)
    //   .then((res) => {
    setDefaultRoomData(roomData)
    fetchMessage(chat._id, 1, [])
    // })
    // .catch((err) => {
    //   console.log('res_blob', err)
    // })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages(res?.data?.data?.chat_message_data)
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err) => console.log('failed: ', err))
  }
  notificationEvent()

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   await ApiPost(`corporate/chat_message/get`, {
  //   limit: 10,
  //   page: 1,
  //   chatRoomId: chatRoomId,
  // })
  //   .then((res) => {
  //     setMessages(res?.data?.data?.chat_message_data)
  //     setMsgPage(msgPage + 1)
  //     setMsgPageLimit(res?.data?.data?.state?.page_limit)
  //   })
  //   .catch((err) => {
  //     console.log('res_blob', err)
  //   })
  // }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let idsToSend: any = []
    for (let i = 0; i < tenanttData?.length; i++) {
      idsToSend[i] = tenanttData[i]._id
    }

    // const body = {
    //   subjectTitle: bodyWithSubject.subjectTitle,
    //   type: 0,
    //   serviceJobId: chatServiceJobId,
    //   tenantIds: idsToSend,
    // }

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      // await ApiPost(`corporate/chat_room`, body)
      //   .then((res) => {
      //     console.log('\nres.data.data', res.data.data)
      handleSendMessage({
        roomId: chatRoomId,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      openMessage(e, 0, {_id: chatRoomId})
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
      // })
      // .catch((err) => {
      //   console.log('res_blob', err)
      // })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    // let m = new Date(dd).toLocaleString()
    // let x = moment(m.format("DD-MM-YYYY HH:mm"))
    // return x;
    return new Date(dd).toLocaleString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const getChatList = async () => {
    // await ApiPost(`corporate/chat_room/get`, {
    //   limit: 10,
    //   page: page,
    // })
    //   .then((res) => {
    //     setChatData([...chatData, ...res?.data?.data?.chat_room_data])
    //     setPage(page + 1)
    //     setPageLimit(res?.data?.data?.state?.page_limit)
    //   })
    //   .catch((err) => {
    //     console.log('res_blob', err)
    //   })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 10,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const gotoChatList = async () => {
    const body = {
      limit: 10,
      page: 1,
      serviceJobId: serviceJob,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        setTenanttData(res.data.data.tenant_data)
        setChatData(res?.data?.data?.chat_room_data)
        setShowChat('message')
      })
      .catch()
  }

  // function called on clicking Message tenant Button
  const getChatRoomForTenant = async (val: any) => {
    let tenant: any = []
    tenant[0] = val
    setServiceJob(val)
    await ApiGet(`corporate/chat_room/personal_chat?tenantId=${val?._id}`)
      .then((res) => {
        SetBodyWithSubject({subjectTitle: res?.data?.data?.subjectTitle})
        showMessagePopUp()

        setTimeout(() => {
          fetchMessage(res?.data?.data?._id, 1, [])
        }, 300)

        // if (res.data.data.isNewChat === true)
        setShowChat('newMessage')

        setTenanttData(tenant)

        setChatRoomId(res.data.data._id)
        setRoomData(res?.data?.data)

        setDefaultRoomData(res?.data?.data)

        handleJoinRoom({
          roomId: res?.data?.data?._id,
          userId: user._id,
        })

        // setChatData(res?.data?.data?.chat_room_data)
      })
      .catch()
  }

  return (
    <>
      <div
        id=''
        className='pt-0 mt-0 no-print px-2'
        style={{padding: '0px 0px 0px 14px'}}
      >
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if(tenancy != null)
                  navigate('/tenancies')
                  else{
                    if(comefrom == 'properties')
                    navigate('/dashboard')
                    else if(comefrom == 'build')
                    navigate('/building-dashboard')
                    else 
                    navigate('/community-dashboard')
                  }
                }
              }
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            {/* {tenancy !== null  ? (
              <h1 className='page-heading m-0 head-text'>
                Property Profile - {tenancy?.contractNo}
              </h1>
            ) : ( */}
              <h1 className='page-heading m-0 head-text'>Property Profile</h1>
            {/* )} */}
          </div>
          {/* Propert Profile Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mx-5 ps-5'
            style={{marginBottom: '28px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 me-5 px-5 e'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}`, {
                      state: {
                        details: state.details,
                        id: state.id,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Overview
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 '
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenancy`, {
                      state: {
                        details: state.details,
                        id: state.id,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenancy
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 active'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenant`, {
                      state: {
                        details: state.details,
                        id: state.id,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenants
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/financials/income`, {
                      state: {
                        details: state.details,
                        id: state.id,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Financials
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/annoucements`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Annoucements
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Requests
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Current Tenants */}
        <div className='my-5' style={{backgroundColor: 'none'}}>
          <div className='card card-flush px-5 py-4 h-100'>
            <div className='d-flex '>
              {state?.tenancyId != undefined ? (
                <h2 className='mx-0 green_color mt-2  '>Tenants</h2>
              ) : (
                <h2 className='mx-0 green_color mt-2  '>Current Tenants</h2>
              )}

              {state?.tenancyId == undefined && (
                <a
                  onClick={() => {
                    setShowAdd(true)
                  }}
                  className='btn btn-sm fw-bold btn-primary btn-green ms-auto'
                >
                  Add Sub Tenant
                </a>
              )}
            </div>
            <div
              className='table-responsive mb-5'
              // onWheel={handleMouseWheel}
              // ref={tableRef}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-start min-w-50px'>ID.</th>
                    <th className='text-start min-w-150px'>Name</th>
                    <th className='text-start min-w-100px'>Type</th>
                    <th className='text-start min-w-100px'>Registration Status</th>
                    {/* <th className='text-start min-w-100px'>Rating</th> */}
                    <th className='text-start min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {currentTenants?.length > 0 ? (
                    <>
                      {currentTenants?.map((tn: any, i: any) => {
                        return (
                          <>
                            <tr>
                              <td data-kt-ecommerce-order-filter='order_id' className='text-start'>
                                {tn?.id}
                              </td>
                              <td className='text-start'>
                                {tn?.firstName} {tn?.lastName}
                              </td>
                              <td className='text-start'>
                                {tn?.tenantType == 0 ? 'Main Tenant' : 'Sub Tenant'}
                              </td>
                              <td className='text-start' data-order='Denied'>
                                <div className='d-flex align-items-center'>
                                  {tn?.registrationStatus == 0 ? (
                                    <div className='red-dot me-1'></div>
                                  ) : (
                                    <div className='green-dot me-1'></div>
                                  )}

                                  <span
                                    className={
                                      tn?.registrationStatus == 0 ? 'red-clr' : 'green-clr'
                                    }
                                  >
                                    {' '}
                                    {tn?.registrationStatus == 0 ? 'Not Registered' : 'Registered'}
                                  </span>
                                </div>
                              </td>

                              {/* <td className='text-start' data-order='2022-09-11'>
                                <span className='svg-icon svg-icon-2' style={{color: '#ffad0f'}}>
                                  <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                      fill='currentColor'
                                    ></path>
                                  </svg>
                                </span>{' '}
                                {tn?.rating}
                              </td> */}

                              <td className='text-start'>
                                <div className='menu-item px-3'>
                                  <img
                                    src={chat}
                                    height='20'
                                    width='20'
                                    className='cursor-pointer mx-3'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      // setNewMessageModal(true)
                                      SetBodyWithSubject({subjectTitle: tn.id})
                                      setChatSubject(tn.id)
                                      setChatServiceJobId(tn._id)
                                      getChatRoomForTenant(tn)
                                    }}
                                  />

                                  <img
                                    src={userImg}
                                    height='18'
                                    width='18'
                                    className='cursor-pointer mx-3'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => navigate(`/tenant/${tn?._id}`)}
                                  />

                                  {tn?.tenantType == 1 && (
                                    <AiFillDelete
                                      color='#D72852'
                                      fontSize={20}
                                      // onClick={() => deleteRow(i)}
                                      onClick={() => {
                                        Swal.fire({
                                          text: 'Are you sure you want to permanently delete this Tenant ?',

                                          icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#D72852',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          // cancelButtonColor: "transparent",
                                          cancelButtonText: 'Cancel',
                                        }).then((res: any) => {
                                          if (res.isConfirmed) {
                                            deleteTenant(tn?._id)
                                          }
                                        })
                                      }}
                                      style={{cursor: 'pointer'}}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={15} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Previous Tenants */}
        { previousTenants?.length > 0 &&
        <div className='my-5' style={{backgroundColor: 'none'}}>
          <div className='card card-flush px-5 py-4 h-100'>
            <Row>
              <Col md={9}>
                <h2 className='mx-0 green_color mt-2  '>Previous Tenants</h2>
              </Col>
              <Col md={3}>
                <input
                  type='text'
                  className='form-control form-control-settings ms-auto'
                  placeholder='Search by Name...'
                  name='item_id'
                  onChange={(e: any) => getPreviousTenants(e.target.value)}
                />
              </Col>
            </Row>
            <div
              className='table-responsive mb-5 mt-3'
              // onWheel={handleMouseWheel}
              // ref={tableRef}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-start min-w-50px'>ID.</th>
                    <th className='text-start min-w-150px'>Name</th>
                    <th className='text-start min-w-100px'>Type</th>
                    <th className='text-start min-w-100px'>Registration Status</th>
                    <th className='text-start min-w-100px'>Tenancy Period</th>

                    <th className='text-start min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {previousTenants?.length > 0 ? (
                    <>
                      {previousTenants?.map((tn: any, i: any) => {
                        return (
                          <>
                            <tr>
                              <td data-kt-ecommerce-order-filter='order_id' className='text-start'>
                                {tn?.id}
                              </td>
                              <td className='text-start'>
                                {tn?.firstName} {tn?.lastName}
                              </td>
                              <td className='text-start'>
                                {tn?.tenantType == 0 ? 'Main Tenant' : 'Sub Tenant'}
                              </td>
                              <td className='text-start' data-order='Denied'>
                                <div className='d-flex align-items-center'>
                                  {tn?.registrationStatus == 0 ? (
                                    <div className='red-dot me-1'></div>
                                  ) : (
                                    <div className='green-dot me-1'></div>
                                  )}

                                  <span
                                    className={
                                      tn?.registrationStatus == 0 ? 'red-clr' : 'green-clr'
                                    }
                                  >
                                    {' '}
                                    {tn?.registrationStatus == 0 ? 'Not Registered' : 'Registered'}
                                  </span>
                                </div>
                              </td>

                              <td className='text-start' data-order='2022-09-11'>
                                {tn?.duration?.start_date
                                  ? moment(tn?.duration?.start_date).format('YYYY.MM.DD')
                                  : '-'}
                                {' - '}{' '}
                                {tn?.duration?.end_date
                                  ? moment(tn?.duration?.end_date).format('YYYY.MM.DD')
                                  : '-'}
                              </td>

                              <td className='text-start'>
                                <div className='menu-item px-3'>
                                  <img
                                    src={chat}
                                    height='20'
                                    width='20'
                                    className='cursor-pointer mx-3'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      // setNewMessageModal(true)
                                      SetBodyWithSubject({subjectTitle: tn.id})
                                      setChatSubject(tn.id)
                                      setChatServiceJobId(tn._id)
                                      getChatRoomForTenant(tn)
                                    }}
                                  />

                                  <img
                                    src={userImg}
                                    height='18'
                                    width='18'
                                    className='cursor-pointer mx-3'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => navigate(`/tenant/${tn?._id}`)}
                                  />

                                  {tn?.tenantType == 1 && (
                                    <AiFillDelete
                                      color='#D72852'
                                      fontSize={20}
                                      // onClick={() => deleteRow(i)}
                                      onClick={() => {
                                        Swal.fire({
                                          text: 'Are you sure you want to permanently delete this Annoncements Draft?',
                                          icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#D72852',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          // cancelButtonColor: "transparent",
                                          cancelButtonText: 'Cancel',
                                        }).then((res: any) => {
                                          if (res.isConfirmed) {
                                            // deleteAnnoucements(an?._id)
                                          }
                                        })
                                      }}
                                      style={{cursor: 'pointer'}}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={15} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        }
      </div>

      <AddSubTenant
        show={showAdd}
        handleClose={() => {
          setShowAdd(false)
          getCurrentTenants()
        }}
        tenancyId={tenId}
        tenantId={tentId}
      />

      {/* Message modal */}
      <div
        className={showMessageCard ? 'message' : 'd-none'}
        style={{width: '400px', height: '600px '}}
      >
        <div
          className='card m-0 p-0 br-10'
          style={{
            overflow: 'hidden',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{justifyContent: 'space-between'}}
          >
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5'>
            {showChat == 'chat' ? (
              <>
                <div
                  className='symbol-group symbol-hover py-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  <div className='symbol-group symbol-hover mx-5'>
                    {/* <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoChatList()}
                      style={{cursor: 'pointer'}}
                    /> */}
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                          <img alt='' src={profileImg}></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a
                      href='#'
                      className='symbol symbol-45px symbol-circle'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : (
                    <>
                      {tenanttData &&
                        tenanttData?.map((tnt: any) => (
                          <h4 className='m-5 '>
                            {tnt.firstName} {tnt.lastName}
                          </h4>
                        ))}
                    </>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {defaultRoomData?.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    style={{backgroundColor: 'white'}}
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}}
                  ref={bottomRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {/* {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName} */}
                            </p>
                            <div className='px-3 pt-0'>
                              <h4 className='m-0 p-0 py-2'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <Card.Footer
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'p-0 text-start'
                                : 'p-0 text-end'
                            }
                            style={{backgroundColor: 'transparent', borderTop: '0px'}}
                          >
                            {' '}
                            <span className='m-0 small_font' style={{fontWeight: '400 !important'}}>
                              {dateTotime(msg?.createdAt)}
                            </span>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0'>
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input
                  type='text'
                  placeholder='Search'
                  className='form form-control m-0 msg_search'
                />
                <img
                  src={filter}
                  alt=''
                  width='24px'
                  className='filter'
                  onClick={() => setShowFilterCard(!showFilterCard)}
                />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  {/* <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li> */}
                </ul>
                <div
                  className='d-flex align-items-center my-5'
                  style={{justifyContent: 'space-between'}}
                >
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  {/* <div className='d-flex gap-3'>
                    <p className='m-0 cus-line' onClick={viewall}>
                      View All
                    </p>
                  </div> */}
                </div>
                <ul
                  className='notification min_height p-0 m-0'
                  ref={myDivRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg}></img>
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a
                                href='#'
                                className='symbol symbol-45px symbol-circle'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_view_users'
                              >
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            {/* <img alt='Pic' src='media/avatars/300-23.jpg' /> */}
                            <img alt='' src={profileImg}></img>
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey'>
                              {/* {moment(chat.createdAt).format('HH:MM')} */}
                              {timeConvert(chat?.createdAt)}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div
                  className='symbol-group symbol-hover p-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover mx-5'>
                        {/* <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        /> */}
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el.firstName} {el.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImg} />
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a
                          href='#'
                          className='symbol symbol-40px symbol-circle'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_view_users'
                        >
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div
                        className='symbol-group symbol-hover p-5 px-0 m-0'
                        style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                      >
                        <div className='symbol-group symbol-hover me-5 ms-0'>
                          {/* <img
                        src={leftarrow}
                        width='20px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      /> */}
                        </div>
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <div className='symbol-group symbol-hover m-0'>
                              <div className='symbol symbol-40px symbol-circle '>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {tnt.firstName} {tnt.lastName}
                                    </Tooltip>
                                  }
                                  placement='bottom'
                                >
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg} />
                                </OverlayTrigger>
                              </div>
                            </div>
                          ))}
                      </div>

                      <h6 className='m-0 p-0'>
                        {/* <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setShowChat('addNew')
                          }}
                        >
                          Select Recipient
                        </a> */}
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <h4 className='m-5 '>
                              {tnt.firstName} {tnt.lastName}
                            </h4>
                          ))}
                      </h6>
                    </div>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {bodyWithSubject.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={bodyWithSubject.subjectTitle}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}}
                  ref={bottomRef}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any, index: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0'>
                              <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div
                  style={{position: 'absolute', bottom: 25, width: '90%'}}
                  className='px-0 mx-auto'
                >
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    {/* <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    /> */}
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'All' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'All' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Internal' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Internal' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Tenants' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Tenants' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('all')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default Tenants

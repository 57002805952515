import React from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import file from '../../../img/file.png'
import redCross from '../../../img/x-mark.png'
import clip from '../../../img/link.png'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'

interface ButtonProps {
  show: any
  handleClose: any
  media: any
  from: any
}

const ViewAllAttachments = ({handleClose, show, media, from}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Attachments
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '45px'}}>
              <img src={clip} height={80} width={80} className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3'>
            {media?.length > 0 && (
              <>
                <div
                  className='row align-items-center my-2'
                  style={{
                    minHeight: '96px',
                  }}
                >
                  {media.map((chq: any, ind: any) => {
                    let fileName: any
                    let temp = chq?.src?.split('.')[1]
                    let x = chq?.src?.split('/')[2]
                    fileName = x + '.' + temp
                    return (
                      <>
                        <div className='img-sets mw-350px col-md-3 px-2 py-3'>
                          <div className='d-flex justify-content-center'>
                            <a
                              href={
                                (from == 'tenancy' &&
                                  ((user?.isSubUser &&
                                    role?.leasing_residential?.attachments?.view_details) ||
                                    !user?.isSubUser)) ||
                                from == 'propertyProfile' ||
                                from == 'terminate-tenancy' ||
                                from == 'tenant' ||
                                from == 'financials'
                                  ? `${Bucket}${chq?.src}`
                                  : `${Bucket}aaa`
                              }
                              target='_blank'
                              style={{color: '#384a62'}}
                            >
                              <img
                                src={file}
                                width={42}
                                height={55}
                                className='main_img cursor-pointer'
                                alt='image'
                              />
                            </a>
                          </div>

                          <p
                            style={{
                              fontSize: '12px',
                              wordBreak: 'break-all',
                              padding: '0px 5px',
                            }}
                            className='mt-1 text-center'
                          >
                            {fileName}
                          </p>
                        </div>
                      </>
                    )
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ViewAllAttachments

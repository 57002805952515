import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import {Col, Row} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost, ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import sync from '../../../img/sync.png'
import {CircularProgress} from '@mui/material'
import noData from '../../../img/NoData1.svg'

interface ButtonProps {
  show: any
  updateService: (data: string) => void
  handleClose: any
  addedService: any
}

const SelectServiceModal = ({handleClose, show, addedService, updateService}: ButtonProps) => {
  const [searchList, setSearchList] = useState<any>()
  const [searchMain, setSearchMain] = useState<any>()
  const [searchSub, setSearchSub] = useState<any>()

  const [result, setResult] = useState<any>()
  const [isFirst, setIsFirst] = useState<any>(true)

  const [isLoading, setIsLoading] = useState<any>(true)

  let totalSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [servicesList, setServicesList] = useState<any>([])
  const [servicesListOptions, setServicesListOptions] = useState<any>([])

  const [mainServices, setMainServices] = useState<any>([])
  const [mainServicesOptions, setMainServicesOptions] = useState<any>([])

  const [subServices, setSubServices] = useState<any>([])
  const [subServicesOptions, setSubServicesOptions] = useState<any>([])

  // console.log(addedService)

  const [radioChecked, setRadioChecked] = useState<any>(
    addedService?._id != undefined ? addedService?._id : ''
  )
  const [selectedService, setSelectedService] = useState<any>(addedService)

  //
  const getServicesListForDropdown = async () => {
    await ApiGet(`corporate/maintenance_service_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setServicesListOptions(values)

        setServicesList([])
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getMainServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_main_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getSubServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_sub_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setSubServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getServicesListForDropdown()
    getMainServicesForDropdown()
    getSubServicesForDropdown()
  }, [])

  //
  const updateList = async () => {
    let lst: any = []
    servicesList?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    let main: any = []
    mainServices?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let sub: any = []
    subServices?.map((v: any, ind: any) => {
      sub[ind] = v?.value
    })

    const body = {
      listSearch: searchList,
      mainServiceSearch: searchMain,
      subServiceSearch: searchSub,
      maintenanceServiceListIds: lst,
      maintenanceMainServiceIds: main,
      maintenanceSubServiceIds: sub,
      page: 1,
      limit: 10000
    }
    await ApiPost(`corporate/maintenance_sub_service/get`, body)
      .then((res) => {
        let values = [...res?.data?.data?.maintenance_sub_service_data]
        res?.data?.data?.maintenance_sub_service_data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        setResult(values)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    updateList()
  }, [searchList, searchMain, searchSub, servicesList, mainServices, subServices])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                Select Service
              </h2>

              <div className='d-flex ms-auto align-items-center'>
                {/* <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  red-hollow-btn ps-4'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-4' /> Cancel
                </button> */}

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn ps-3 status-w-100'
                  onClick={() => {
                    updateService(selectedService)
                    handleClose()
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-4'/> Add
                </button>

                <label
                className='head-text fs-3 cursor-pointer ms-3'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='text-dark fs-5 fnt-500 mt-9'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                      List
                    </label>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='List...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchList}
                        onChange={(e: any) => {
                          setSearchList(e.target.value)
                        }}
                        disabled={
                          servicesList?.length > 0 ||
                          mainServices?.length > 0 ||
                          subServices?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                      Main Service
                    </label>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Main Service...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchMain}
                        onChange={(e: any) => {
                          setSearchMain(e.target.value)
                        }}
                        disabled={
                          servicesList?.length > 0 ||
                          mainServices?.length > 0 ||
                          subServices?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Col md={3} className='mb-5 property xyz tst pe-6'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                      Sub Service
                    </label>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Sub Service...'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchSub}
                        onChange={(e: any) => {
                          setSearchSub(e.target.value)
                        }}
                        disabled={
                          servicesList?.length > 0 ||
                          mainServices?.length > 0 ||
                          subServices?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Col md={3} className='mb-5 property xyz tst ps-0 pe-7'>
                    <div
                      className='filter-div d-flex align-items-center justify-content-center cursor-pointer mt-1 ms-auto'
                      onClick={() => {
                        setSearchList('')
                        setSearchMain('')
                        setSearchSub('')
                        setServicesList([])
                        setMainServices([])
                        setSubServices([])
                      }}
                    >
                      <img src={sync} height={20} width={22} className='cursor-pointer' />
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-1'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='text-dark fs-5 fnt-500 mt-9'>
                  {' '}
                  Filter
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-start row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='test'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                      List
                    </label>
                    <div className=' multi-select-white-bg'>
                    <div
                      className={`multi-select-container property me-7 ${
                        servicesList.length === 0 ? 'no-value list' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={servicesListOptions}
                        value={servicesList}
                        onChange={setServicesList}
                        labelledBy='List'
                        overrideStrings={{
                          allItemsAreSelected: 'All Lists',
                        }}
                        disabled={
                          searchList?.trim()?.length > 0 ||
                          searchMain?.trim()?.length > 0 ||
                          searchSub?.trim()?.length > 0
                        }
                      />
                    </div>
                    </div>
                  </Col>

                  {/* Main Service */}
                  <Col md={3} className='test'>
                    <label className='text-dark fs-5 fnt-500 mb-1'>
                      Main Service
                    </label>
                    <div className=' multi-select-white-bg'>
                    <div
                      className={`multi-select-container property  ${
                        mainServices.length === 0 ? 'no-value main-service' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={mainServicesOptions}
                        value={mainServices}
                        onChange={setMainServices}
                        labelledBy='Main Service'
                        overrideStrings={{
                          allItemsAreSelected: 'All Main Service',
                        }}
                        disabled={
                          searchList?.trim()?.length > 0 ||
                          searchMain?.trim()?.length > 0 ||
                          searchSub?.trim()?.length > 0
                        }
                      />
                    </div>
                    </div>
                  </Col>

                  {/* Sub Services */}

                  <Col md={3} className='test'>
                  <div className=' multi-select-white-bg'>
                    <div
                      className={`multi-select-container property me-7 ${
                        subServices.length === 0 ? 'no-value sub-service' : 'has-value'
                      }`}
                    >
                      <label className='text-dark fs-5 fnt-500 mb-1'>
                        Sub Service
                      </label>
                      <MultiSelect
                        options={subServicesOptions}
                        value={subServices}
                        onChange={setSubServices}
                        labelledBy='Sub Service'
                        overrideStrings={{
                          allItemsAreSelected: 'All Sub Service',
                        }}
                        disabled={
                          searchList?.trim()?.length > 0 ||
                          searchMain?.trim()?.length > 0 ||
                          searchSub?.trim()?.length > 0
                        }
                      />
                    </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            {result?.map((res: any, ind: any) => {
              if (res?.check == true) totalSelected = totalSelected + 1
              return <></>
            })}

            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className='' style={{maxHeight: '380px', overflow: 'auto'}}>
                  <div className='table-responsive '>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='text-start w-25px test'></th>
                          <th className='text-start min-w-100px'>Sub Service</th>
                          <th className='text-start min-w-100px'>Main Service</th>
                          <th className='text-start min-w-100px'>List</th>
                        </tr>
                      </thead>

                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {result?.length > 0 ? (
                          <>
                            {result?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  <td className='text-start test'>
                                    {radioChecked === rs?._id ? (
                                      <div className='me-5 green-radio-filled cursor-pointer'></div>
                                    ) : (
                                      <div className='me-5 green-radio-hollow cursor-pointer'
                                        onClick={() => {
                                          setRadioChecked(rs?._id)
                                          setSelectedService(rs)
                                          // setSelectedName(rs?.name)
                                        }}
                                      ></div>
                                    )}
                                  </td>
                                  <td className='text-start'>
                                    {rs?.id ? rs?.id : '-'}
                                    {'. '}
                                    {rs?.name ? rs?.name : '-'}
                                  </td>
                                  <td className='text-start'>
                                    {rs?.maintenance_main_service?.name
                                      ? rs?.maintenance_main_service?.name
                                      : '-'}
                                  </td>
                                  <td className='text-start'>
                                    {rs?.maintenance_service_list?.name
                                      ? rs?.maintenance_service_list?.name
                                      : '-'}
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          <tr>
                            {!isLoading ? (
                              <td colSpan={5} className='text-center'>
                                <img src={noData} alt='' width={350} />
                              </td>
                            ) : (
                              <td colSpan={4} className='text-center'>
                                <div
                                  className='d-flex justify-content-center align-items-center w-100'
                                  style={{height: '25vh'}}
                                >
                                  <CircularProgress style={{color: '#007a59'}} />
                                </div>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectServiceModal

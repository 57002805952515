import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
// import { Col, Row } from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import redCross from '../../../../img/remove.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import unitClusterGreenIcon from '../../../../img/unitClusterGreen.png'
import unitClusterWhiteIcon from '../../../../img/unitClusterWhite.png'
import mixedClusterGreenIcon from '../../../../img/mixedClusterGreen.png'
import mixedClusterWhiteIcon from '../../../../img/mixedClusterWhite.png'
import buildingClusterGreenIcon from '../../../../img/buildingClusterGreen.png'
import buildingClusterWhiteIcon from '../../../../img/buildingClusterWhite.png'
import buildingWhiteIcon from '../../../../img/small-building.png'
import buildingGreenIcon from '../../../../img/allBuildingGreen.png'
import communitiesWhiteIcon from '../../../../img/allCommunities.png'
import communitiesGreenIcon from '../../../../img/allCommunitiesGreen.png'
import carrotArrowDown from '../../../../img/caret-arrow-down.png'
import carrotArrowUp from '../../../../img/caret-arrow-up.png'

import swal from 'sweetalert2'
import {mixed} from 'yup'
import cluster from 'cluster'

interface ButtonProps {
  handleAddBuildingCluster: any
  handleRemoveBuildingCluster: any
  handleAddBuildingForBuildingCluster: any
  handleRemoveBuildingClusterBuilding: any
  addFloorHandler: any
  floorOptions: any
  allCluster: any
  index: any
  setCluster: any
}

const AddBuildingCluster = ({
  handleAddBuildingCluster,
  handleRemoveBuildingCluster,
  handleAddBuildingForBuildingCluster,
  handleRemoveBuildingClusterBuilding,
  addFloorHandler,
  floorOptions,
  allCluster,
  index,
  setCluster,
}: ButtonProps) => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }
  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
    // backgroundColor: '#fff',
  }
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const handleRemoveBuildingClusterFloor = (index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)
    // setCluster(updatedCluster)

    const updatedFloors = checkForDuplicatesForBuildingCluster(
      updatedCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    )
    updatedCluster[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
    setCluster(updatedCluster)
  }

  const calculateTotalFloors = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }

  const floorOption = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, type: 'building' | 'mixed') => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'building' ? allCluster[index]?.addBuildingCluster : allCluster[index]?.addMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = allCluster[index]?.addBuildingCluster?.[clusterIndex]?.addBuilding?.[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }
  // useEffect(() => {
  //     if (selectBuildingCircle) {
  //         let updatedOptions = floorOptions;
  //         const isSelectGroundFloor = buildingDevelopmentFloor?.some((floorItem: any) => floorItem?.floorType === 0)
  //         if (isSelectGroundFloor) {
  //             updatedOptions = updatedOptions.filter((floorItem: any) => floorItem?.value !== 0)
  //             setFloorOptions(updatedOptions)
  //         } else {
  //             setFloorOptions(floorOptionTemp)
  //         }
  //     }
  // }, [buildingDevelopmentFloor])

  const swapRowForAdd = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      updatedValuesSections[prevInd].animationClass = ''
      updatedValuesSections[nextInd].animationClass = ''

      // Assign the updated array back to the structure

      updatedValues[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      // setCluster(updatedValues);

      // update the red box
      const updatedFloors = checkForDuplicatesForBuildingCluster(
        updatedValues[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      updatedValues[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
      setCluster(updatedValues)
    }, 100)
  }

  // const checkForDuplicatesForBuildingCluster = (floors:any) => {
  //     const duplicates :any = [];
  //     const floorMap = new Map();

  //     floors.forEach((floor:any, index:any) => {
  //         const key = `${floor.floorType.value}-${floor.name.toLowerCase()}`;
  //         if (floorMap.has(key)) {
  //             const existingIndices = floorMap.get(key);
  //             for (const existingIndex of existingIndices) {
  //                 const existingFloor = floors[existingIndex];
  //                 if ((floor.floorStartRange >= existingFloor.floorStartRange && floor.floorStartRange <= existingFloor.floorEndRange) ||
  //                     (floor.floorEndRange >= existingFloor.floorStartRange && floor.floorEndRange <= existingFloor.floorEndRange) ||
  //                     (existingFloor.floorStartRange >= floor.floorStartRange && existingFloor.floorStartRange <= floor.floorEndRange) ||
  //                     (existingFloor.floorEndRange >= floor.floorStartRange && existingFloor.floorEndRange <= floor.floorEndRange)) {
  //                     if (!duplicates.includes(existingIndex)) {
  //                         duplicates.push(existingIndex);
  //                     }
  //                     duplicates.push(index);
  //                     break;
  //                 }
  //             }
  //             floorMap.get(key).push(index);

  //         } else {
  //             floorMap.set(key, [index]);
  //         }
  //     });

  //     return duplicates;
  // }

  const checkForDuplicatesForBuildingCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  return (
    <>
      <div className='row' style={{marginTop: '50px'}}>
        <div className='col-8 mx-auto text-center mb-2'>
          <h2 className='m-0 head-text'>
            <b>Building Clusters</b>
          </h2>
        </div>
        <div className='col-10 mx-auto text-center'>
          <h4 className='m-0 head-text mb-1'>Set the unit clusters existing within your community</h4>
          <h4 className='m-0 head-text mb-1'>You will be able to add the properties under each unit cluster once they have been added.</h4>

          <div className='my-7 d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-submit-btn '
              style={{width: 'fit-content'}}
              onClick={() => handleAddBuildingCluster(index)}
            >
              <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building Cluster
            </button>
          </div>
        </div>

        <div className='col-12 mx-auto' style={{marginTop: '20px'}}>
          <div className='row d-flex' style={{rowGap: '10px'}}>
            {allCluster[index]?.addBuildingCluster?.map((clusterItem: any, buildingClusterIndex: any) => (
              <div className='col-12'>
                <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '50px'}}>
                  {allCluster[index]?.addBuildingCluster?.length >= 1 && (
                    <div style={{position: 'absolute', top: '2px', right: '12px'}}>
                      <img
                        style={{cursor: 'pointer'}}
                        src={redCross}
                        height={14}
                        width={14}
                        onClick={() => handleRemoveBuildingCluster(index, buildingClusterIndex)}
                      />
                    </div>
                  )}
                  <div className='row'>
                    <div className='text-center col-3 mx-auto'>
                      <h3 className='m-0 head-text mb-1 ms-1'>Building Cluster</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter Building Cluster Name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Building Cluster'}}
                            value={clusterItem?.name}
                            onChange={(e: any) => {
                              const values = [...allCluster]
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              values[index].addBuildingCluster[buildingClusterIndex].name = captilizeWord
                              setCluster(values)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3 mx-auto text-center  my-7'>
                      <div className='d-flex justify-content-center'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold px-2 green-submit-btn '
                          style={{width: 'fit-content'}}
                          onClick={() => handleAddBuildingForBuildingCluster(index, buildingClusterIndex)}
                        >
                          <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                        </button>
                      </div>
                      <h4 className='m-0 head-text mt-3 mb-1'>Add a building under this cluster</h4>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      {clusterItem?.addBuilding?.map((buildingItem: any, buildingIndex: any) => (
                        <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                          <div className='row'>
                            <div className='col-8 d-flex align-items-start'>
                              <div className='me-4'>
                                <h3 className='m-0 head-text mb-1 ms-1'>Building</h3>
                                <div className='wxyz'>
                                  <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                      placeholder='Enter Building Name'
                                      style={inputBaseStyle}
                                      inputProps={{'aria-label': 'Building name'}}
                                      value={buildingItem?.name}
                                      onChange={(e: any) => {
                                        const values = [...allCluster]
                                        const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].name = captilizeWord
                                        setCluster(values)
                                      }}
                                    />
                                  </Paper>
                                </div>
                              </div>
                              <div className='text-center'>
                                <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                  {`${calculateTotalFloors(index, buildingClusterIndex, buildingIndex)}`}
                                </h3>
                              </div>
                            </div>

                            {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.length >= 1 && (
                              <div className='col-4 d-flex justify-content-end '>
                                <img
                                  style={{cursor: 'pointer'}}
                                  src={redCross}
                                  height={14}
                                  width={14}
                                  onClick={() => handleRemoveBuildingClusterBuilding(index, buildingClusterIndex, buildingIndex)}
                                />
                              </div>
                            )}
                          </div>

                          {/* add floor */}
                          <div className='row my-7'>
                            <div className='col-12'>
                              <div className='d-flex align-items-center'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold create-st d-flex justify-content-center px-2 me-4'
                                  style={{width:'120px'}}
                                  onClick={() => addFloorHandler(index, buildingClusterIndex, buildingIndex)}
                                >
                                  Add Floor
                                </button>

                                <p className='m-0 head-text'>
                                Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12'>
                              <table className='table table-borderless'>
                                <thead>
                                  <tr>
                                    <th className='head-text text-start p-0 pb-2' style={{minWidth: '20px', maxWidth: '20px'}}></th>
                                    <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                                      Floors
                                      <span className='ms-2'>
                                        <OverlayTrigger
                                          placement='right'
                                          delay={{show: 250, hide: 200}}
                                          overlay={
                                            <Tooltip id='button-tooltip-2'>
                                              {' '}
                                              <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of
                                              each floor added, giving each floor it’s own name. If you do not wish to include a prefix, you may leave
                                              this field blank. You will be able to edit any floor name once it has been added.
                                              <br></br>
                                              <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start
                                              and end based on the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect
                                              the same floor names within your building. If you are adding a single floor your ‘Start Range’ and ‘ End
                                              Range’ should include the same value.
                                              <br></br>
                                              <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’
                                              and ‘End Range’ will be included in the total count.
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {' '}
                                            <AiOutlineExclamationCircle
                                              color='#146C6A '
                                              fontSize={14}
                                              fontWeight={500}
                                              className='cursor-pointer'
                                              style={{cursor: 'pointer', marginBottom: '2px'}}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </span>
                                    </th>
                                    <th className='head-text text-start p-0  ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                                      Floor Name
                                    </th>
                                    <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                                      Floor Range
                                    </th>
                                    <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                                      Example
                                    </th>
                                    <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                                      Total Floors
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className='text-start'>
                                  {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.map(
                                    (floorItem: any, floorIndex: any) => {
                                      return (
                                        <>
                                          <tr
                                            key={floorIndex}
                                            className={`ps-3 ${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}
                                          >
                                            <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                                              <span
                                                className={`${
                                                  allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    ?.length > 1
                                                    ? 'me-3'
                                                    : ''
                                                }`}
                                              >
                                                {floorIndex === 0 &&
                                                  allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    ?.length > 1 && (
                                                    <img
                                                      onClick={() =>
                                                        swapRowForAdd(
                                                          floorIndex,
                                                          floorIndex + 1,
                                                          index,
                                                          buildingClusterIndex,
                                                          buildingIndex,
                                                          floorIndex
                                                        )
                                                      }
                                                      style={{cursor: 'pointer'}}
                                                      src={carrotArrowDown}
                                                      height={12}
                                                      width={12}
                                                    />
                                                  )}
                                                {floorIndex ===
                                                  allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    ?.length -
                                                    1 &&
                                                  allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    ?.length > 1 && (
                                                    <img
                                                      onClick={() =>
                                                        swapRowForAdd(
                                                          floorIndex - 1,
                                                          floorIndex,
                                                          index,
                                                          buildingClusterIndex,
                                                          buildingIndex,
                                                          floorIndex
                                                        )
                                                      }
                                                      style={{cursor: 'pointer'}}
                                                      src={carrotArrowUp}
                                                      height={12}
                                                      width={12}
                                                    />
                                                  )}
                                                {floorIndex !== 0 &&
                                                  floorIndex !==
                                                    allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                      ?.length -
                                                      1 && (
                                                    <span className='d-flex flex-column'>
                                                      <img
                                                        style={{cursor: 'pointer'}}
                                                        src={carrotArrowUp}
                                                        height={12}
                                                        width={12}
                                                        onClick={() =>
                                                          swapRowForAdd(
                                                            floorIndex - 1,
                                                            floorIndex,
                                                            index,
                                                            buildingClusterIndex,
                                                            buildingIndex,
                                                            floorIndex
                                                          )
                                                        }
                                                      />
                                                      <img
                                                        style={{cursor: 'pointer'}}
                                                        src={carrotArrowDown}
                                                        height={12}
                                                        width={12}
                                                        onClick={() =>
                                                          swapRowForAdd(
                                                            floorIndex,
                                                            floorIndex + 1,
                                                            index,
                                                            buildingClusterIndex,
                                                            buildingIndex,
                                                            floorIndex
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  )}
                                              </span>
                                            </td>

                                            <td className='p-0  py-3' style={{minWidth: '170px'}}>
                                              <div>
                                                <Select
                                                  styles={{
                                                    ...customStyles,
                                                    container: (defaultStyles: any) => ({
                                                      ...defaultStyles,
                                                      width: '100%',
                                                    }),
                                                  }}
                                                  isSearchable={false}
                                                  options={floorOption(index, buildingClusterIndex, buildingIndex, floorIndex, 'building')}
                                                  defaultValue={''}
                                                  value={floorItem?.floorType}
                                                  name='floor'
                                                  onChange={(e: any) => {
                                                    const values = [...allCluster]
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                      floorIndex
                                                    ].floorType = e

                                                    if (e.value === 0) {
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].startRangeError = false
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].endRangeError = false
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].floorStartRange = 1
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].floorEndRange = 1
                                                    }

                                                    const updatedFloors = checkForDuplicatesForBuildingCluster(
                                                      values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                    )
                                                    values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                      updatedFloors
                                                    setCluster(values)
                                                  }}
                                                  placeholder={'Select Floor Type'}
                                                />
                                              </div>

                                              {floorItem.isDuplicate && (
                                                <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                  Floor Name is duplicated
                                                </p>
                                              )}
                                            </td>
                                            <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                              <div className='wxyz'>
                                                <Paper component='form' style={searchContainerStyle}>
                                                  <InputBase
                                                    placeholder='Prefix'
                                                    style={inputBaseStyle}
                                                    inputProps={{'aria-label': 'Prefix'}}
                                                    value={floorItem?.name}
                                                    onChange={(e: any) => {
                                                      const values = [...allCluster]
                                                      const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                        floorIndex
                                                      ].name = captilizeWord

                                                      const updatedFloors = checkForDuplicatesForBuildingCluster(
                                                        values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                      )
                                                      values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                        updatedFloors
                                                      setCluster(values)
                                                    }}
                                                  />
                                                </Paper>
                                              </div>
                                            </td>

                                            <td className='p-0 min-w-100px ps-7 py-3' style={{maxWidth: '220px'}}>
                                              <div className='d-flex ' style={{columnGap: '10px'}}>
                                                <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                  <Paper component='form' style={searchContainerStyle}>
                                                    <InputBase
                                                      disabled={floorItem?.floorType === 0}
                                                      type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                      value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                      placeholder='Start Range'
                                                      style={inputBaseStyle}
                                                      inputProps={{'aria-label': 'Start Range',min:1}}
                                                      onChange={(e: any) => {
                                                        if (e.target.value < 0) {
                                                          return;
                                                        }
                                                        const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                        const values = [...allCluster]

                                                        if (
                                                          floorItem?.floorEndRange !== '' &&
                                                          floorItem?.floorEndRange < Number(e.target.value) &&
                                                          e.target.value != ''
                                                        ) {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].startRangeError = true
                                                        } else {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].startRangeError = false
                                                        }

                                                        if (
                                                          floorItem?.floorEndRange !== '' &&
                                                          floorItem?.floorEndRange >= Number(e.target.value) &&
                                                          e.target.value != ''
                                                        ) {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].endRangeError = false
                                                        }

                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].floorStartRange = newValue ? Number(newValue) : ''
                                                        const updatedFloors = checkForDuplicatesForBuildingCluster(
                                                          values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]
                                                            ?.addFloor
                                                        )
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                          updatedFloors
                                                        setCluster(values)
                                                      }}
                                                    />
                                                  </Paper>
                                                  {floorItem?.startRangeError && (
                                                    <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                      The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                                    </p>
                                                  )}
                                                </div>
                                                <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                  <Paper component='form' style={searchContainerStyle}>
                                                    <InputBase
                                                      disabled={floorItem?.floorType === 0}
                                                      placeholder='End Range'
                                                      type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                      style={inputBaseStyle}
                                                      inputProps={{'aria-label': 'End Range',min:1}}
                                                      value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                      onChange={(e: any) => {
                                                        if (e.target.value < 0) {
                                                          return;
                                                        }
                                                        const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                        const values = [...allCluster]
                                                        if (
                                                          e.target.value != '' &&
                                                          Number(e.target.value) < floorItem.floorStartRange &&
                                                          floorItem.floorStartRange != ''
                                                        ) {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].endRangeError = true
                                                        } else {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].endRangeError = false
                                                        }

                                                        if (
                                                          e.target.value !== '' &&
                                                          Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                          floorItem.floorStartRange != ''
                                                        ) {
                                                          values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                            floorIndex
                                                          ].startRangeError = false
                                                        }
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor[
                                                          floorIndex
                                                        ].floorEndRange = newValue ? Number(newValue) : ''
                                                        const updatedFloors = checkForDuplicatesForBuildingCluster(
                                                          values[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]
                                                            ?.addFloor
                                                        )
                                                        values[index].addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor =
                                                          updatedFloors
                                                        setCluster(values)
                                                      }}
                                                    />
                                                  </Paper>
                                                  {floorItem?.endRangeError && (
                                                    <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                      The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                                              <div className='text-center  d-flex justify-content-center align-items-center'>
                                                <div className='mt-2'>
                                                  {floorItem?.floorType?.value != 0 ? (
                                                    <p className='m-0 head-text '>
                                                      <i>
                                                        {' '}
                                                        {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                      </i>
                                                    </p>
                                                  ) : (
                                                    <p className='m-0 head-text '>
                                                      <i> {floorItem?.name}</i>
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                              <div className='text-center  d-flex justify-content-center align-items-center'>
                                                <div className='mt-1'>
                                                  <h3 className='m-0 head-text '>
                                                    {/* {`${(floorItem?.floorEndRange - floorItem?.floorStartRange) + 1}`} */}
                                                    {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                                  </h3>
                                                </div>
                                                {allCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
                                                  ?.length > 1 && (
                                                  <img
                                                    className='ms-7'
                                                    style={{cursor: 'pointer'}}
                                                    src={redCross}
                                                    height={14}
                                                    width={14}
                                                    onClick={() =>
                                                      handleRemoveBuildingClusterFloor(index, buildingClusterIndex, buildingIndex, floorIndex)
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBuildingCluster

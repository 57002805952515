import React, {useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import {AiFillDelete} from 'react-icons/ai'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {ApiPost, ApiPut, ApiGet} from '../../../apiCommon/helpers/API/ApiData'

const CustomList = () => {
  const navigate = useNavigate()
  const [customService, setCustomService] = useState<any>()
  const [mainService, setMainService] = useState<any>()
  const [subService, setSubService] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>()
  const [page, setPage] = useState<any>(1)
  const [checked, setChecked] = useState<any>()
  const [selectedItem, setSelectedItem] = useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  // const requestServiceId: any = localStorage.getItem(currentTab)
  const activeTab: any = localStorage.getItem('activeTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId'+activeTab)

  const getRequestDataById = async () => {
    // console.log(requestServiceId)
    // let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    // console.log(temp)

   let mainService:any
   let subService : any
   let staff : any
   let vendor: any
    await ApiGet(`corporate/service_request/`+requestServiceId)
      .then((res) => {

        // main service 
        if(res?.data?.data[0].main_service.length === 0){
          mainService = null
        }
        else{
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service 
        if(res?.data?.data[0].sub_service.length === 0){
          subService = null
        }
        else{
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }
 
        // staff
        if(res?.data?.data[0].staff.length === 0){
          staff = null
        }
        else{
          staff = res?.data?.data[0]?.staff[0]?._id
        }

         // vendor
        if(res?.data?.data[0].vendor.length === 0){
          vendor = null
        }
        else{
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: requestServiceId,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: res.data.data[0].requestUrgency,
              requestType: res.data.data[0].requestType,
              saveStatus: 0,
              comment: res.data.data[0].comment,
              vendorComment: res.data.data[0].vendorComment

            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          localStorage.setItem('savedOnce'+requestServiceId, '1')
          localStorage.setItem('mainService_'+requestServiceId,mainService)
          localStorage.setItem('subService_'+requestServiceId,subService)

          navigate(`/request-management/service/create-request-service-main`)
        })
        .catch()
 
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getCustomServiceList = async () => {
    const body = {
      page: page,
      limit: 10,
      mainServiceName: mainService ? mainService : '',
      subServiceName: subService ? subService : '',
    }
    await ApiPost(`corporate/main_service/overview`, body)
      .then((res) => {
        // setTableData(res?.data?.data)
        setCustomService(res?.data?.data?.services_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 2,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem('1', res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }
    // getCustomServiceList()
    getInitialData()
  }, [mainService, subService])

  const handleSelectService = (item: any, index: any) => {
    setChecked(index)
    setSelectedItem(item)
  }

  const removeServices = () => {
    setChecked('')
    setSelectedItem('')
  }

  const addService = async () => {
    const body = {
      id: requestServiceId,
      mainServiceId: selectedItem?._id,
      subServiceId: selectedItem?.sub_service?._id,
      requestType: 2,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        // navigate(`/request-management/service/create-request-service-internal/1`)
        getRequestDataById()
       
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const getInitialData = async () => {
    setChecked('')
    // console.log(requestServiceId)
    // let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    // console.log(temp)
   let mainServ:any
   let subServ : any
   let staff : any
   let vendor: any
    await ApiGet(`corporate/service_request/`+requestServiceId)
      .then((res) => {
        
        // main service 
        if(res?.data?.data[0].main_service.length === 0){
          mainServ = null
        }
        else{
          mainServ = res?.data?.data[0].main_service[0]?._id
        }

        // sub service 
        if(res?.data?.data[0].sub_service.length === 0){
          subServ = null
        }
        else{
          subServ = res?.data?.data[0]?.sub_service[0]?._id
        }
 
        // staff
        // if(res?.data?.data[0].staff.length === 0){
        //   staff = null
        // }
        // else{
        //   staff = res?.data?.data[0]?.staff[0]?._id
        // }

         // vendor
        // if(res?.data?.data[0].vendor.length === 0){
        //   vendor = null
        // }
        // else{
        //   vendor = res?.data?.data[0]?.vendor[0]?._id
        // }

        const body = {
          page: page,
          limit: 10,
          mainServiceName: mainService ? mainService : '',
          subServiceName: subService ? subService : '',
        }
        ApiPost(`corporate/main_service/overview`, body)
          .then((res) => {
            // setTableData(res?.data?.data)
            setCustomService(res?.data?.data?.services_data)
            setPageLimit(res?.data?.data?.state?.page_limit)

            let temp = res?.data?.data?.services_data
            for(let i=0; i< temp.length; i++){
              if(temp[i]._id === mainServ && temp[i].sub_service._id === subServ){
                setSelectedItem(temp[i])
                setChecked(i)
              }
            }
          })
          .catch((e) => {
            console.log(e)
          })

      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        {/* <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service/create-request-service`)}
              >
                Internal Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Custom List</a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service/create-request-service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                // navigate(`/request-management/quotation`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li className='nav-item' onClick={() => moveVendor()}>
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div> */}

        <div className='card card-flush app-container py-5'>
          <Row>
            <div className='col-6 col-md-6'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                onClick={() =>
                  // navigate(`/request-management/service/create-request-service-internal/1`)
                  navigate(`/request-management/service/create-request-service-main`)
                }
              >
                Back
              </a>
            </div>
            
            <div className='col-6 col-md-6' style={{textAlign: 'end'}}>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                onClick={() => addService()}
              >
                Add
              </a>
            </div>
          </Row>
          <div className='mb-10 mt-10'>
            <Row>
              <Col lg={6} md={6}></Col>
              <Col lg={6} md={6}>
                <div className='select-box p-3'>
                <h3 className='page-heading mt-2 text-dark fw-bold fs-3 mb-5'>Service Selected</h3>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-center min-w-100px'>Main Service Name</th>
                      <th className='text-center min-w-100px'>Sub Service Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    <tr>
                      <td className='text-center min-w-100px'>{selectedItem?.name}</td>
                      <td className='text-center min-w-100px'>{selectedItem?.sub_service?.name}</td>
                      <td>
                        <AiFillDelete
                          style={{cursor: 'pointer'}}
                          onClick={() => removeServices()}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </Col>
            </Row>
          </div>
          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/request-management/service/create-request-service/custom-list`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                  Custom List
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/request-management/service/create-request-service/select-list`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                  Propertise List
                </a>
              </li>
            </ul>
          </div>
          <Col md={8}>
            <h3 className='page-heading text-dark fw-bold fs-3 mb-5'>Select Service</h3>
            <div className='mb-10 d-flex align-items-center row'>
              <div className='d-flex align-items-center mb-3 col-12 col-sm-6'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0 min-w-150px'>
                  Search Main Service
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='subNewServiceList'
                  onChange={(e) => setMainService(e.target.value)}
                />
              </div>
              <div className='d-flex align-items-center mb-3 col-12 col-sm-6'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0 min-w-150px'>
                  Search Sub-Service
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='subNewServiceList'
                  onChange={(e) => setSubService(e.target.value)}
                />
              </div>
            </div>
            <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-10px'></th>
                  <th className='min-w-100px'>Main Service</th>
                  <th className='min-w-10px'>Sub Service</th>
                  <th className=' min-w-10px'>Notes</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {customService?.map((item: any, index: any) => {
                  return (
                    <tr style={{height: '40px'}}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={checked === index}
                            style={{cursor: 'pointer'}}
                            onChange={() => handleSelectService(item, index)}
                          />
                        </div>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.sub_service?.name}</td>
                      <td>{item?.sub_service?.note}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            </div>
          </Col>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomList

import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import {KTSVG} from '../../../_metronic/helpers'

const DateFilter = ({
  show,
  handleClose,
  date,
  onApply,
}: any) => {
  const [formData1, setFormData1] = useState<any>({})

  //
  const handleSubmit1 = async () => {
    onApply(formData1?.startdate, formData1?.enddate)
    // setFormData1({})
    handleClose()
  }

  //
  const handleChnage1 = (e: any) => {
    const {name, value} = e.target
    setFormData1({...formData1, [name]: value})
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div className='modal-header'>
          <h2>Select Date Range</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        {date && (
          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div
                className='card-header align-items-center gap-md-2'
                style={{justifyContent: 'flex-start'}}
              >
                <div className='mb-10 min-w-200px '>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    From
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='e.g. - abc'
                    name='startdate'
                    value={formData1?.startdate}
                    onChange={handleChnage1}
                  />
                </div>
                <div className='mb-10 min-w-200px '>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    To
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='e.g. - abc'
                    name='enddate'
                    value={formData1?.enddate}
                    onChange={handleChnage1}
                  />
                </div>
              </div>

              {/*end::Content */}
            </div>
            {/* end::Stepper */}
          </div>
        )}

        {/* Filter box button set */}
        <div className='modal-footer'>
          <div className='flex-row-fluid '>
            <div className='d-flex justify-content-end '>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 me-1 '
                  />{' '}
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-lg btn-primary btn-green'
                  data-kt-stepper-action='submit'
                  onClick={handleSubmit1}
                >
                  Apply{' '}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </button>
              </div>
            </div>
            {/*end::Form */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DateFilter

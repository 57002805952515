import React from 'react'
import {useState} from 'react'
import './style.scss'
import {CirclePicker} from 'react-color'
import correct from '../../../img/correct.png'
import saveWhiteImg from '../../../img/Save-White.png'

interface ButtonProps {
  show: any
  handleClose: any
  selectedVL: any
  index: any
  allVL: any
  updateList: any
  position: any
  from:any
}

const ColorPickerModal = ({
  show,
  handleClose,
  selectedVL,
  index,
  allVL,
  updateList,
  position,
  from
}: ButtonProps) => {
  const [color, setColor] = useState(selectedVL?.colorCode) // Set initial color

  const handleChange = (newColor: any) => {
    if(from == 'addVL'){
      updateList(newColor.hex)
      setColor(newColor.hex)
    }
    else{
    let values = [...allVL]
    values[index].colorCode = newColor.hex

    updateList(values[index], values)
    setColor(newColor.hex)
    }
  }


  const customColors = [
    '#9F0500', '#B80000', '#DB3E00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#E91E63', '#9C27B0', 
    '#0a1247', '#556b2f', '#0047ab', '#af4424', '#fd8f52', '#cd9b9b', '#666666', '#8a9d96', '#607D8B', '#fb9062', 
    '#c73866', '#673AB7', '#2196F3', '#03A9F4', '#FF9800', '#FF5722', '#795548', '#fe676e', '#260b2c', '#66023c', 
    '#4c0020', '#451327', '#581e35', '#caa6ed', '#009688', '#194D33', '#417505', '#0C797D', '#8fbc8f', '#4CAF50', 
    '#a6b5a2', '#6b0103', '#6a76a0', '#73859f', '#FF0000', '#0000FF', '#FF00FF',
  ]

  return (
    <>
      {/* <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      > */}

      <div
        className='message bg-white'
        style={
          show
            ? {
                display: 'flex',
                width: '320px',
                maxHeight: '300px',
                position: 'absolute',
                ...position,
                zIndex: 1111,
                minHeight: '300px',
              }
            : {
                display: 'none',
              }
        }
      >
        <div
          className='modal-body ps-5 pe-3 pt-lg-3 pb-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '300px',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-3 cursor-pointer'
                style={{fontWeight: '700', position: 'relative', zIndex: '2'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div
            style={{
              transform: 'translate(0px, -25px)',
            }}
          >
            <div className='d-flex align-items-baseline mb-3'>
              <h3 className='mb-3'>Pick a Color</h3>
              {/* <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ms-auto px-3 me-3'
                style={{width: 'fit-content'}}
                onClick={(e) => {}}
              >
                Update
              </button> */}
            </div>
            <CirclePicker
              color={color}
              onChange={handleChange}
              colors={customColors} // Set the color picker to show your custom colors
            />
          </div>
        </div>
      </div>
      {/* </Modal> */}
    </>
  )
}

export default ColorPickerModal

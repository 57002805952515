import React, {useEffect, useState} from 'react'
import {Modal, Button, DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import dayjs, {Dayjs} from 'dayjs'
import redCross from '../../../../img/remove.png'
import addWhite from '../../../../img/add-white.png'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import { useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

interface ButtonProps {
  show: any
  handleClose: any
  updateData?: any
  modalData?: any
  dataInd?: any
  mode: any
}

const ScheduleVisitation = ({
  show,
  handleClose,
  updateData,
  modalData,
  dataInd,
  mode,
}: ButtonProps) => {
  const format = 'HH:mm'

  const {id} = useParams()

  const [selectedDate, setSelectedDate] = useState<any>(
    modalData?.date ? new Date(modalData?.date) : new Date()
  )

  const currentTime = new Date()
  const today = new Date()

  const [startValue, setStartValue] = React.useState<Dayjs | null>(
    modalData?.startTime ? dayjs(modalData?.startTime) : null
  )
  const [endValue, setEndValue] = React.useState<Dayjs | null>(
    modalData?.endTime ? dayjs(modalData?.endTime) : null
  )

  const [isLoading, setIsLoading] = useState<any>(false)

  const [startTimeMinute, setstartTimeMinute] = useState<any>(
    modalData?.startTime ? modalData?.startTime : null
  )
  const [endTimeMinute, setendTimeMinute] = useState<any>(
    modalData?.endTime ? modalData?.endTime : null
  )

  const handleStartValueChange = (value: any) => {
    let temp = new Date(value)

    const utcTime = temp.toISOString()
    let x = temp.toUTCString()
    setStartValue(value)

    // Format the selected time to 'YYYY-MM-DD HH:mm' before saving
    const formattedTime = value ? utcTime : null
    setstartTimeMinute(formattedTime)

    // setstartTimeMinute(isoStr)
  }

  const handleEndValueChange = (value: any) => {
    let temp = new Date(value)

    const utcTime = temp.toISOString()
    let x = temp.toUTCString()

    setEndValue(value)
    // Format the selected time to 'YYYY-MM-DD HH:mm' before saving
    const formattedTime = value ? utcTime : null
    setendTimeMinute(formattedTime)

    //  setendTimeMinute(isoStr)
  }

  const handleDateChange = (date: any) => {
    if (date != null) setSelectedDate(date.format('YYYY-MM-DD'))
    else setSelectedDate(null)
    console.log(selectedDate)
  }

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  useEffect(() => {}, [])

  // add a schedule
  const addScheduleVisitation = () => {
    setIsLoading(true)
    const body = {
      date: selectedDate,
      startTime: startTimeMinute,
      endTime: endTimeMinute,
      // visitStartTime: '',
      // visitEndTime: '',
      scheduleStatus: 0,
      // visitStatus: 0,
      totalDuration: 0,
      vendorConfirmationStatus: 0,
      tenantConfirmationStatus: 0,
      maintenanceServiceJobId: id,
    }
    ApiPost(`corporate/maintenance_service_job_schedule`, body)
      .then((res: any) => {
        updateData()
        setIsLoading(false)
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  // reschedule
  const reScheduleVisitation = () => {
    setIsLoading(true)
    const body = {
      maintenanceServiceJobScheduleId: modalData?._id,
      date: selectedDate,
      startTime: startTimeMinute,
      endTime: endTimeMinute,  
    }
    ApiPost(`corporate/maintenance_service_job_schedule/reschedule`, body)
      .then((res: any) => {
        updateData()
        setIsLoading(false)
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  return (
    <div className='schedule'>
      <Modal
        title={[
          <div
            className='d-flex align-items-center pb-4 mt-2'
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <h2 className='pt-0 mb-0 white-dark-theme-color'>
              <b>{mode == 'add' ? 'Set Schedule' : 'Reschedule'}</b>
            </h2>
          </div>,
        ]}
        visible={show}
        centered
        onCancel={() => handleClose()}
        footer={[
          <div className='d-flex mx-3 justify-content-center mt-0 mb-10'>
            <button
              type='button'
              className='btn btn-sm fw-bold  mx-3 red-hollow-btn status-w-140 ps-2'
              onClick={() => {
                handleClose()
              }}
              disabled={isLoading}
            >
              <img src={redCross} height={18} width={18} className='me-7' /> Cancel
            </button>

            <button
              type='button'
              className={`btn btn-sm fw-bold  me-0 green-submit-btn  ps-2  status-w-140`}
              onClick={() => {
               
                // let schedule = {
                //   date: selectedDate,
                //   startTime: startTimeMinute,
                //   endTime: endTimeMinute,
                //   status: 0,
                // }

                if (mode == 'add') addScheduleVisitation()
                else reScheduleVisitation()

                // updateData(schedule, dataInd)
              }}
              disabled={selectedDate == null || startTimeMinute == null || endTimeMinute == null || isLoading}
            >
              <img src={addWhite} height={18} width={18} className='me-2' />
              {mode == 'add' ? 'Set Schedule' : 'Reschedule Visit'}
            </button>
          </div>,
        ]}
        className='schedule modal-dark-bg-theme'
      >
        <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-0 mx-auto'>
            <div className='d-flex '>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Date
                </label>
              </h4>
              <h4 style={{width: '-webkit-fill-available'}}>
                <DatePicker
                  defaultValue={moment(selectedDate, 'YYYY-MM-DD')}
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                />
              </h4>
            </div>
          </div>
        </div>

        {/*  */}
        <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-3 mx-auto'>
            <div className='d-flex '>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Time (From)
                </label>
              </h4>
              <div className='d-flex align-items-center w-100'>
              <h4 style={{width: '-webkit-fill-available'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']}>
                    <TimePicker
                      ampm={false}
                      value={startValue}
                      onChange={(newValue) => handleStartValueChange(newValue)}
                      minTime={
                        new Date(selectedDate).getFullYear() === today.getFullYear() &&
                        new Date(selectedDate).getMonth() === today.getMonth() &&
                        new Date(selectedDate).getDate() === today.getDate()
                          ? dayjs(currentTime)
                          : startValue
                      }
                      maxTime={endValue}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </h4>

              {startValue && ( // Show clear icon only if there's a value
                  <IconButton
                    onClick={() => {
                      setStartValue(null)
                      setstartTimeMinute(null)
                    }}
                    size='small'
                    className='clear-pick'
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-3 mx-auto'>
            <div className='d-flex '>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Time (To)
                </label>
              </h4>
              <div className='d-flex align-items-center w-100'>
              <h4 style={{width: '-webkit-fill-available'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']}>
                    <TimePicker
                      minutesStep={1}
                      ampm={false}
                      // defaultValue={startValue}
                      value={endValue}
                      // onChange={(newValue) => handleEndValueChange(newValue)}
                      disablePast={
                        new Date(selectedDate).getFullYear() === today.getFullYear() &&
                        new Date(selectedDate).getMonth() === today.getMonth() &&
                        new Date(selectedDate).getDate() === today.getDate()
                          ? true
                          : false
                      }
                      minTime={startValue}
                      onAccept={(newValue) => handleEndValueChange(newValue)}

                      // onClose={handleClose}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </h4>

              {endValue && ( // Show clear icon only if there's a value
                  <IconButton
                    onClick={() => {
                      setEndValue(null)
                      setendTimeMinute(null)
                    }}
                    size='small'
                    className='clear-pick'
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ScheduleVisitation

import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import noData from '../../../img/NoData1.svg'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import OutstandingBalance from './OutstandingBalance'
import ReactPaginate from 'react-paginate'
import FinancialsFilter from './FinancialsFilter'
import ReminderFilter from './ReminderFilter'
import SendReminderModal from './SendReminderModal'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import plus from '../../../img/add-white.png'

function PaymentReminders() {
  const navigate = useNavigate()
  return (
    <>
      <div id='' className='container-xxl pt-0 mt-0' style={{padding: '0px 0px 0px 14px'}}>
        <div className='page-title d-flex flex-wrap mb-5 min-w-200px '>
          <h1 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0'>
            Financials
          </h1>
        </div>
        {/* Tabs */}
        <div className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mb-5'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
          <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2  me-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-income')}
              >
                Income
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-expenses')}
              >
                Expenses
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-statement-reports')}
              >
                Statement Reports
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-payment-reminders')}
              >
                Payment Reminders
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default PaymentReminders

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiGet, ApiPost, ApiUpload, ApiPut, ApiDelete, ApiGetNoAuth} from '../../../../apiCommon/helpers/API/ApiData'
import img from '../../../../../img/300-1.jpg'
import folderDocument from '../../../../../img/folder-document.svg'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
// import NewMessage from '../../TenantNewMessage'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import backArrow from '../../../../img/back-arrow.png'
import {Modal} from 'react-bootstrap'
import send from '../../../../img/sendwhite.svg'
import closered from '../../../../../img/closered.svg'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import {AiFillDelete} from 'react-icons/ai'
import {truncate} from 'fs'
import lock from '../../../../img/padlock.png'
import ReactPaginate from 'react-paginate'
import noData from '../../../../img/NoData1.svg'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import {setSelectedUnit} from '../../../../redux/counterSlice'
import openImg from '../../../../img/payment-col-open.png'
import close from '../../../../img/payment-col-close.png'
// import EditPaymentModal from '../../../Financials/EditPaymentModal'
import pencil from '../../../../img/pen.png'
import eye from '../../../../img/eye-blue.png'
import trash from '../../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../../img/check-white.png'
import download from '../../../../img/download-white.png'
import bin from '../../../../img/bin.png'
import {useDispatch, useSelector} from 'react-redux'
import {setComefrom, setUnitIDfromProperties} from '../../../../redux/counterSlice'
import plusBlue from '../../../../img/add-blue.png'
import trashImg from '../../../../img/trash.png'
import correct from '../../../../img/correct.png'
import upload from '../../../../img/upload-blue.png'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import redCross from '../../../../img/remove.png'
import Select from 'react-select'
import editpen from '../../../../img/pen.png'
import deleteImg from '../../../../img/delete.png'
import downloadImg from '../../../../img/download-img.png'
import axios from 'axios'
import addWhite from '../../../../img/add-white.png'
// import IncomingPaymentModal from './IncomingPaymentModal'
import userGrayIcon from '../../../../img/user-gray.png'
import folderIcon from '../../../../img/folder.png'
import folderGreenIcon from '../../../../img/folder-green.png'
import removeFilled from '../../../../img/remove-filled.png'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import pngIcon from '../../../../img/png.png'
import jpgIcon from '../../../../img/jpg-file.png'
import jpegIcon from '../../../../img/jpeg.png'
import pdfIcon from '../../../../img/pdf.svg'
import correctFilled from '../../../../img/correct.png'
import fileIcon from '../../../../img/invoice-white.png'
import chatGreen from '../../../../img/chat-green.png'
import chatWhite from '../../../../img/chat.png'
import closes from '../../../../img/close.svg'
import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import leftarrow from '../../../../img/arrowleft.svg'
import plus from '../../../../img/plus.svg'
import filter from '../../../../img/filter.svg'
import profileImgStatic from '../../../../img/user.jpeg'
import lockIcon from '../../../../img/lock.png'
import cancelWhite from '../../../../img/cancel-white.png'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import addWhiteIcon from '../../../../img/add-white.png'
import './style.scss'

const ViewUser = () => {
  const [isFormDirty, setIsFormDirty] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [idTenancy, setIdTenancy] = useState<any>(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])
  const [paymentData, setPaymentData] = React.useState<any>({})
  const [propertiType, setPropertiType] = React.useState('propertiseAssigned')
  const [messageData, setMessageData] = React.useState<any>([])
  const [messagechat, setmessagechat] = React.useState<any>([])
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
  const [messageList, setMessageList] = React.useState<any>(false)
  const [tenantId, setTenantId] = React.useState<any>('')
  const [sendMessage, setSendMessage] = React.useState<any>('')
  const [roomId, setRoomId] = React.useState<any>()
  const [open, setOpen] = React.useState(false)

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
    {value: '2', label: 'Other'},
  ])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res: any) => {
        setTenantData(res?.data?.data)
        console.log('\nres?.data?.data', res?.data?.data)
        setValue('email', res?.data?.data?.email)
        setValue('phoneNumber', `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`)
        setProfileImg(res?.data?.data?.profileImage)
        //  setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const getmessageDataById = async (id: any) => {
    const body = {
      limit: 10,
      page: 1,
      chatRoomId: id,
    }
    await ApiPost(`corporate/chat_message/get`, body)
      .then((res: any) => {
        setmessagechat(res?.data?.data?.tenancy_payment_data)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message) {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }



  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  // console.log('folderfile',folderFiles)
  // console.log('folders',folders)
  const getTenantFolders = () => {
    ApiGet(`corporate/tenant_folder?tenantId=${window.location.pathname.split('/')[2]}`)
      .then((res: any) => {
        setFolders(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setSelectedFolder(folder)
        setViewFolder(true)
        setFolderFiles(res?.data?.data?.fileList)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }
        console.log(y)
        console.log(res?.data?.data?.fileList?.length)
        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
        setEditFolderName(-1)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  //
  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  const getUniqueFilename = (filename: any, folderFiles: any) => {
    const fileExtension = filename.split('.').pop()
    const fileNameWithoutExtension = filename.replace(`.${fileExtension}`, '')
    let newFilename = filename
    let counter = 1

    while (folderFiles.some((file: any) => file.fileName === newFilename)) {
      newFilename = `${fileNameWithoutExtension}(${counter}).${fileExtension}`
      counter++
    }

    return newFilename
  }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const body = {
        name: folderName,
        tenantId: `${window.location.pathname.split('/')[2]}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          getTenantFolders()
          setFolderNameModal(false)
        })
        .catch((err: any) => {})
    } else {
    }
  }

  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: selectedFolder?.name,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        getFolderFiles(selectedFolder)
        setEditFile(-1)
      })
      .catch((err: any) => {})
  }

  const updateFolderName = () => {
    const body = {
      id: selectedFolder?._id,
      name: newFolderName,
      fileList: selectedFolder?.fileList,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        setEditFolderName(-1)
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  const deleteFolder = (id: any) => {
    console.log(id)
    ApiDelete(`corporate/tenant_folder/${id}`)
      .then((res: any) => {
        SuccessToast('Folder has been deleted successfully!')
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  /*********************** Requests ************************/
  const [page, setPage] = useState<any>(1)
  const [requests, setRequests] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  /************************ Payments  ********************/
  const [page1, setPage1] = useState<any>(1)
  const [payments, setPayments] = useState<any>()
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [show, setShow] = useState<any>([])
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [profileImg, setProfileImg] = useState<any>(null)

  const handlePageClick1 = (event: any) => {
    setPage(event.selected + 1)
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  /************************Unit Dropdown ********************/
  const [tenantUnits, setTenantUnits] = useState<any>()
  const [tenantSelectedUnit, setTenantSelectedUnit] = useState<any>()
  const [tenancies, setTenancies] = useState<any>()
  const [selectedTenancy, setSelectedTenancy] = useState<any>()
  const [editTenantModal, setEditTenantModel] = useState<any>(false)
  const [addInviteModal, setAddInviteModal] = useState<any>(false)
  const [requestModal, setRequestModal] = useState<any>(false)

  const getTenantUnits = () => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res: any) => {
        setTenantUnits(res?.data?.data)
        setTenantSelectedUnit(res?.data?.data?.[0])
        if (res?.data?.data?.[0]) getTenantTenancies(res?.data?.data?.[0])
      })
      .catch((err: any) => console.log('err', err))
  }

  const getTenantTenancies = (unit: any) => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
      unitId: unit?._id,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res: any) => {
        setTenancies(res?.data?.data)
        setSelectedTenancy(res?.data?.data?.[0])
      })
      .catch((err: any) => console.log('err', err))
  }

  const [newName, setNewName] = useState<any>()
  const [newFolderName, setNewFolderName] = useState<any>()

  //
  const renameFile = async (file: any, type: any) => {
    const body = {
      newFileName: newName + '.' + type,
      image: file,
    }

    await ApiPost('upload/rename_file', body)
      .then((res: any) => {
        setTimeout(() => {
          setEditFile(-1)
          getFolderFiles(selectedFolder)
        }, 1500)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const [isEditTenant, setIsEditTenant] = useState<any>(false)



  // get unit details
  const getUnitDetails = () => {
    if (tenantSelectedUnit?._id != undefined) {
      ApiGet(`corporate/unit/${tenantSelectedUnit?._id}`)
        .then((res: any) => {
          setUnitDetails(res?.data?.data)
        })
        .catch((err: any) => console.log('err', err))
    }
  }


  const deleteTenant = () => {
    ApiDelete(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then(() => {
        SuccessToast('Tenant has been deleted successfully..')
        navigate(-1)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res: any) => {
        setCountry(res?.data?.data)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }



  // delete logo
  const deleteProfileImg = () => {
    const body = {
      url: profileImg,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        setProfileImg(null)
        SuccessToast('Profile picture has been removed successfully!!!')
        const body = {}

        // ApiPut('corporate/corporate_setting', body)
        //   .then((res) => {
        //     getDetails()
        //   })
        //   .catch((err) => ErrorToast(err.message))
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }



  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      // const type = response?.data?.type?.split('/')?.[1]
      // console.log(type)

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: response.headers['content-type']})

      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }
      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  const checkBeforeDeleteFolder = async () => {
    await ApiGet(`corporate/tenant_folder/deletion_checker/${selectedFolder?._id}`)
      .then((res: any) => {
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        if (!isAbleToDelete) {
          ErrorToast('Unable To Delete Folder.')
        } else {
          Swal.fire({
            html: `
<div class='fs-4 mt-4 fw-bold'> 
Delete Folder
</div>
<div class='fs-5 mt-4'> 
   Are you sure you want to permanently delete this folder?
</div>`,
            showConfirmButton: true,
            confirmButtonColor: '#d54645',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: '#0d9e91',
            width: '205px',
            padding: '0px',

            // cancelButtonColor: "transparent",
            cancelButtonText: 'No',
          }).then((res: any) => {
            if (res.isConfirmed) {
              // console.log(fl)
              deleteFolder(selectedFolder?._id)
              setViewFolder(false)
              setEditFile(-1)
            }
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const checkBeforeDeleteTenant = async () => {
    await ApiGet(`corporate/tenant/deletion_checker/${tenantData?._id}`)
      .then((res: any) => {
        console.log(res?.data?.data?.isAbleToDelete)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy: any) => tenancy?.tenancyStatus)
        if (
          tenancyStatus.includes(0) ||
          tenancyStatus.includes(1) ||
          tenancyStatus.includes(2) ||
          (tenancyStatus.includes(3) && isAbleToDelete === false)
        ) {
          ErrorToast(
            'Cannot delete Tenant. It seems the tenant has an ongoing lease or unsettled payments. To delete this tenant, you must first cancel their lease and settle their due payments.'
          )
        } else {
          Swal.fire({
            html: `
     <div class='fs-4 mt-4 fw-bold'> 
     You are about to delete this tenant.
     </div>
     <div class='fs-5 mt-4'> 
         Deleting this tenant will prevent you from interacting and managing the tenant
     </div>
      <div class='fs-5 mt-4'> 
         Are you sure you want to continue?
     </div>
     `,
            showConfirmButton: true,
            confirmButtonColor: '#D72852',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: '#0d9e91',
            // cancelButtonColor: "transparent",
            cancelButtonText: 'No',
          }).then((res: any) => {
            if (res.isConfirmed) {
              deleteTenant()
            }
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, [])

  

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <form>
          <div className='d-flex flex-column flex-column-fluid'>
            <div id='kt_app_content' className='app-content flex-column-fluid pt-0'>
              <div className='px-2 pt-0 mt-0'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                    <span
                        className='rounded-circle cursor-pointer'
                        onClick={() => {
                        navigate('/settings/users')
                        }}
                    >
                        <img src={backArrow} height={14} width={14} />
                    </span>
                    <h2 className='m-0 ms-2'>{'View User'}</h2>
                    </div>
                    <div className='d-flex align-items-center' style={{columnGap:'10px'}}>
                        <button
                        type='button'
                        className='btn btn-sm fw-bold  px-2 red-hollow-btn btn-fit-content'
                        // onClick={() => {
                        //   Swal
                        //     .fire({
                        //       text: 'Are you sure you want to delete the selected users ?',
                        //       icon: 'warning',
                        //       showConfirmButton: true,
                        //       confirmButtonColor: '#D72852',
                        //       confirmButtonText: 'Yes',
                        //       showCancelButton: true,
                        //       // cancelButtonColor: "transparent",
                        //       cancelButtonText: 'Cancel',
                        //     })
                        //     .then((res) => {
                        //       if (res.isConfirmed) {
                        //       }
                        //     })
                        // }}
                    >
                        <img src={trashImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                        Delete Account
                    </button>
                    <button
                    disabled={true}
                        type='button'
                        className='btn btn-sm fw-bold  px-2 grey-submit-btn btn-fit-content'
                        // onClick={() => {
                        //   Swal
                        //     .fire({
                        //       text: 'Are you sure you want to delete the selected users ?',
                        //       icon: 'warning',
                        //       showConfirmButton: true,
                        //       confirmButtonColor: '#D72852',
                        //       confirmButtonText: 'Yes',
                        //       showCancelButton: true,
                        //       // cancelButtonColor: "transparent",
                        //       cancelButtonText: 'Cancel',
                        //     })
                        //     .then((res) => {
                        //       if (res.isConfirmed) {
                        //       }
                        //     })
                        // }}
                    >
                        <img src={cancelWhite} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                        Disable Account
                    </button>
                    </div>
                </div>
                
              </div>
              <div id='kt_app_content_container' className='px-2 mt-8'>
                <div className='d-flex flex-column flex-lg-row'>
                  <div className='flex-column flex-lg-row-auto w-lg-300px w-xl-400px mb-10'>
                    <div className='card mb-5 mb-xl-8'>
                      <div className='card-body py-3 px-5'>
                        <div id='kt_user_view_details' className='collapse show'>
                          <div className='pb-5 fs-6'>
                            <div className='row mt-4'>
                              {!isEditTenant && (
                                <>
                                  <div className='col-12 symbol symbol-100px symbol  mt-5'>
                                    {/* Logo */}
                                    <div className='d-flex mb-3 align-items-center w-full'>
                                      {profileImg == null ? (
                                        <>
                                          <img src={userGrayIcon} height={80} width={80} />
                                        </>
                                      ) : (
                                        <div className='d-flex'>
                                          <img
                                            src={`${Bucket}${profileImg}`}
                                            height='160'
                                            width='160'
                                            style={{
                                              borderRadius: '7px',
                                              border: '1.5px solid black',
                                              objectFit: 'contain',
                                            }}
                                          />

                                          {/* <div> */}
                                          <label
                                            htmlFor='media'
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid #48a794 ',
                                              borderRadius: '4px',
                                              transform: 'translate(-45px,-10px)',
                                            }}
                                          >
                                            <img
                                              src={editpen}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                              }}
                                            />
                                          </label>

                                          <input
                                            type='file'
                                            hidden
                                            id='media'
                                            className='form-control form-control-solid'
                                            name='passport'
                                            // onChange={onSelectFile}
                                          />
                                          {/* </div> */}
                                          {/* <div> */}
                                          <div
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid crimson',
                                              borderRadius: '4px',
                                              transform: 'translate(-38px,-10px)',
                                            }}
                                            onClick={() => deleteProfileImg()}
                                          >
                                            <img
                                              src={deleteImg}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                                marginLeft: '2px',
                                              }}
                                            />
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      )}
                                      <div className='ps-4 w-full flex-grow-1'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <h3 className='m-0 ms-1 fnt-600'>{`Mason Wilson`}</h3>
                                          <img
                                            src={pencil}
                                            alt=''
                                            height={18}
                                            width={18}
                                            className='me-5 cursor-pointer'
                                            onClick={() => {
                                              // setEditTenantModel(true)
                                            }}
                                          />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          <div className='light-green-dot mt-1 ms-1 me-2'></div>
                                          <p className='head-text user-head pe-2 mb-0'>Online</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className=' mt-7'>
                              {/* First Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> First Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                  Mason
                                    {/* <b>{tenantData?.firstName}</b> */}
                                  </span>
                                </h4>
                              </div>

                              {/* Last Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Last Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    Wilson
                                    {/* <b>{tenantData?.lastName}</b> */}
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Mobile No. </label>
                                </h4>

                                <h4 style={{width: '65%', borderRadius: '0.475rem'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    +971 503671892
                                    {/* <b>{tenantData?.phoneNumber ? `${tenantData?.countryCode} ${tenantData?.phoneNumber}` : '-'}</b> */}
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Email </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    finance.manager@mailinator.com
                                    {/* <b>{tenantData?.email ? tenantData?.email : '-'}</b> */}
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Password </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    *******
                                    {/* <b>{tenantData?.email ? tenantData?.email : '-'}</b> */}
                                  </span>
                                  <button type='button' className='btn btn-sm fw-bold px-2 green-submit-btn status-w-130' onClick={() => {}}>
                                    <img src={lockIcon} height={18} width={18} className='me-2' />
                                    Reset Password
                                  </button>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Role </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <div className='ps-1 d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='brown-dot mt-1 ms-1 me-2'></div>
                                      <p className='head-text user-head pe-2 mb-0'>Finance Manager</p>
                                    </div>

                                    <div className='ms-4'>
                                      <img
                                        src={pencil}
                                        alt=''
                                        height={18}
                                        width={18}
                                        className='me-5 cursor-pointer'
                                        onClick={() => {
                                          // setEditTenantModel(true)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Properties </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>1,789 Units</span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Account Status </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>Disabled</span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Created </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1'>
                                    {/* `${moment?.utc(tenantData?.updatedAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}` */}
                                    24.12.2023 – 12:30pm
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex-lg-row-fluid ms-lg-6'>
                          <div className='card py-3 px-5' style={{minHeight: '80vh'}}>
                            <div className='d-flex justify-content-between' style={{paddingTop: '40px'}}>
                              <ul
                                className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-1 xyz ms-1'
                                role='tablist'
                              >
                                <li className='nav-item' style={{paddingRight: '40px'}} role='presentation'>
                                  <a
                                    className={
                                      propertiType === 'propertiseAssigned'
                                        ? 'nav-link text-active-primary pb-0 active'
                                        : 'nav-link text-active-primary  pb-0'
                                    }
                                    data-bs-toggle='tab'
                                    aria-selected='false'
                                    role='tab'
                                    tabIndex={-1}
                                    onClick={() => {
                                      setPropertiType('propertiseAssigned')
                                    }}
                                  >
                                    Propertise Assigned
                                  </a>
                                </li>
                                <li className='nav-item' role='presentation' style={{paddingRight: '40px'}}>
                                  <a
                                    className={
                                      propertiType === 'loginSession'
                                        ? 'nav-link text-active-primary pb-0 active'
                                        : 'nav-link text-active-primary  pb-0'
                                    }
                                    data-bs-toggle='tab'
                                    aria-selected='false'
                                    role='tab'
                                    tabIndex={-1}
                                    onClick={() => {
                                      setPropertiType('loginSession')
                                    }}
                                  >
                                    Login Session
                                  </a>
                                </li>
                                <li className='nav-item' role='presentation' style={{paddingRight: '40px'}}>
                                  <a
                                    className={
                                      propertiType === 'logs' ? 'nav-link text-active-primary pb-0 active' : 'nav-link text-active-primary  pb-0'
                                    }
                                    data-bs-toggle='tab'
                                    aria-selected='false'
                                    role='tab'
                                    tabIndex={-1}
                                    onClick={() => {
                                      setPropertiType('logs')
                                    }}
                                  >
                                    Logs
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className='row'>
                              <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
                                <thead>
                                  <tr className='text-start text-gray-500 fw-bold  gs-0'>
                                    <th className='min-w-200px'>
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold px-6 green-submit-btn justify-content-center btn-fit-content'
                                        onClick={() => {
                                        //   setAddTenantModal(true)
                                        }}
                                      >
                                        <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add
                                      </button>
                                    </th>
                                    <th className='min-w-75px'></th>
                                    <th className='min-w-75px'></th>
                                    <th className='min-w-50px text-center'>Units</th>
                                    <th className='min-w-50px text-center text-decoration-gray' >
                                        Assigned
                                    </th>
                                    <th className='min-w-75px text-center'>
                                    <img
                                            src={pencil}
                                            alt=''
                                            height={18}
                                            width={18}
                                            className='cursor-pointer'
                                            onClick={() => {
                                              // setEditTenantModel(true)
                                            }}
                                          />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style={{fontWeight: '500',fontSize:'12px',}} className='table-body'>
                                  <tr className='cursor-pointer'>
                                    <td style={{}}> Al Rawdah Tower 1</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td className='text-center'>320</td>
                                    <td className='text-center ' style={{textDecoration:'underline'}}>143</td>
                                    <td className='text-decoration-green' style={{color:'#146c6a'}}>
                                        Select Units
                                    </td>
                                  </tr>
                                  {/* 2 */}
                                  <tr className='cursor-pointer'>
                                    <td style={{}}> Al Jowhara Tower A</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td className='text-center'>210</td>
                                    <td className='text-center ' style={{textDecoration:'underline'}}>50</td>
                                    <td className='text-decoration-green' style={{color:'#146c6a'}}>
                                        Select Units
                                    </td>
                                  </tr>
                                  {/* 3 */}
                                  <tr className='cursor-pointer'>
                                    <td style={{}}> Manazel Community</td>
                                    <td>Cluster 1</td>
                                    <td>Unit Group A</td>
                                    <td className='text-center'>679</td>
                                    <td className='text-center ' style={{textDecoration:'underline'}}>20</td>
                                    <td className='text-decoration-green' style={{color:'#146c6a'}}>
                                        Select Units
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                         

                          </div>
                        </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <NewMessage
        show={newMessageModal}
        tenantdata={tenantData}
        getMessageList={getMessageList}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      /> */}

        {/* <Modal show={folderNameModal} onHide={() => setFolderNameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Folder </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
              Input Folder Name
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              onChange={(e: any) => setFolderName(e.target.value)}
            />
            {fileFound ? (
              <p className='mt-2' style={{color: 'red'}}>
                Folder name already exists
              </p>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => addFolder()}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={folderName?.trim()?.length == 0 ? true : false}
          >
            Add {''}
          </Button>
        </Modal.Footer>
      </Modal> */}

        {/* {dataToEdit && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={tenantSelectedUnit}
          detailsToEdit={dataToEdit}
          type={'tenant'}
        />
      )} */}

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
          carousel={{finite: slides.length <= 1}}
          render={{
            buttonPrev: slides.length <= 1 ? () => null : undefined,
            buttonNext: slides.length <= 1 ? () => null : undefined,
          }}
        />

        {/* {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => {
            setIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={unitDetails}
          tnancy={selectedTenancy?._id}
        />
      )} */}

        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
          <CircularProgress style={{color: '#007a59'}} />
        </Backdrop>
      </div>
    </>
  )
}

export default ViewUser

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiDelete, ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import AppVendormodal from './AppVendormodal';
// import Cities from '../../cities.json'

let deleteVendor: any = []
let vendorListArrayData: any = []
let vendorListArray: any = []
// let cities: any = Cities

function ManagementVendors() {
    const navigate = useNavigate()
    const [alphabetValue, setAlphabetValue] = useState<any>('')
    const [formData, setFormData] = useState<any>({})
    const [list, setlist] = useState<any>()
    const [pageLimit, setPageLimit] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const [vendorList, setVendorList] = useState<any>()
    const [deleteVendorArray, setDeleteVendorArray] = useState<any>([])
    const [checkedState, setCheckedState] = useState<any>(new Array(vendorList?.length).fill(false))
    const [showAddVendorModal, setShowAppVendorModal] = useState<any>(false)
    const [vendorsList, setVendorsList] = useState<any>()
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [vendorCount, setVendorCount] = useState<any>()
    const [vendorListArray,setVendrorListArray] =React.useState<any>()


    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));
    let length: any = deleteVendorArray?.length;

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const getVendorList = () => {
        const body = {
            page: page,
            limit: 10,
            searchVendorName: formData?.vendorName?formData?.vendorName:'',
            searchTRN: formData?.TRN?formData?.TRN:'',
            searchTradeLicenseNo: formData?.tradeLicense?formData?.tradeLicense:'',
            city: "",
            startingWitAlphabet: alphabetValue
        }
        ApiPost('corporate/vendor/get', body)
            .then((res) => {
                setVendorList(res?.data?.data?.vendor_data)
                setCheckedState(new Array(res?.data?.data?.vendor_data?.length).fill(false))
                setPageLimit(res?.data?.data?.state?.page_limit)
                setVendorCount(res?.data?.data?.state?.data_count)
                setDeleteVendorArray([])
            })
            .catch((err) => ErrorToast(err.message))
    }

    const vendorLisData = ()=>{
        ApiGet('corporate/vendor_list')
        .then((res) => {
            setVendorsList(res?.data?.data)
        })
        .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getVendorList()
        vendorLisData()
    }, [page, alphabetValue])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    const handeleSelectVendor = (id: any, i: any) => {
        if(isCheckAll){
            setIsCheckAll(!isCheckAll)
        }
        const updatedCheckedState = checkedState.map((item: any, index: any) =>
            index === i ? !item : item
        );
        setCheckedState(updatedCheckedState);

        if (deleteVendor.includes(id)) {
            deleteVendor = deleteVendor.filter((item: any) => item !== id)
            setDeleteVendorArray(deleteVendor)
        } else {
            deleteVendor.push(id)
            setDeleteVendorArray(deleteVendor)
        }
    }

    useEffect(() => {
        length = deleteVendorArray?.length;
    }, [deleteVendorArray])

    const deleteVendorData = () => {
        deleteVendor.map((i: any) => {
            ApiDelete(`corporate/vendor/${i}`)
                .then((res) => {
                    SuccessToast(res?.data.message)
                    getVendorList()
                    setDeleteVendorArray([])
                })
                .catch((err) => ErrorToast(err.message))
        })
    }


    useEffect(() => {
        handleSelectAllVendor();
    }, [isCheckAll])

    const handleSelectAllVendor = () => {
        if (isCheckAll) {
            setCheckedState(new Array(vendorList?.length).fill(true));
            vendorList.map((item: any, index: any) => {
                deleteVendor.push(item?._id)
            })
            setDeleteVendorArray(deleteVendor)
        } else {
            if(!checkedState.includes(false)){
                deleteVendor = []
                setDeleteVendorArray(deleteVendor)
                setCheckedState(new Array(vendorList?.length).fill(false));
            }
        }
    }

    const addTolist = (v:any) =>{
        setlist(v)
        setShowAppVendorModal(true)
    }

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/vendor-management/management-list`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    Lists
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/vendor-management/management-vendors`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                    Vendors
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/vendor-management/management-requests`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                    Requests
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='d-flex mb-10 d-none d-sm-none d-md-none d-lg-flex'>
                        <h5 className='gray-500 me-10 mb-0 w-200px'>
                            Total Vendors:{' '}
                            <span className='' style={{ color: 'black' }}>
                                {vendorCount}
                            </span>
                        </h5>
                        <div className='d-flex w-100 justify-content-center'>
                            {alphabet.map((i) => {
                                return (
                                    <span className={`px-2 ${alphabetValue === i ? 'active' : ''}`} style={alphabetValue === i ? { cursor: "pointer", background: '#007a59', borderRadius: 5, marginRight: 5, color: 'white' } : { cursor: "pointer", background: '#c7c7c7', borderRadius: 5, marginRight: 5 }} onClick={() => setAlphabetValue(i)}>{i}</span>
                                )
                            })
                            }
                        </div>
                    </div>

                    <div className='d-flex mb-10 d-flex d-sm-flex d-md-flex d-lg-none'>
                    <h5 className='gray-500 me-10 mb-0 w-200px'>
                    Total Vendors:{' '}
                        <span className='' style={{ color: 'black' }}>
                        {vendorCount}
                        </span>
                    </h5>
                    <div className='row justify-content-center '>
                        {alphabet.map((i) => {
                            return (
                                <div className='col-1'>
                                <span className={`px-2 ${alphabetValue === i ? 'active' : ''}`} style={alphabetValue === i ? { cursor: "pointer", background: '#007a59', borderRadius: 5, marginRight: 5, color: 'white' } : { cursor: "pointer", background: '#c7c7c7', borderRadius: 5, marginRight: 5 }} onClick={() => setAlphabetValue(i)}>{i}</span></div>
                            )
                        })
                        }
                    </div>
                </div>

                <Row className="d-flex d-sm-none">
                    <Col className="ms-auto text-end pe-0">
                   <div
                                    className='btn btn-sm fw-bold btn-primary btn-green hover-none mb-3'
                                    onClick={() => navigate(`/vendor-management/management-vendors/add-vendors`)}>
                                    + Add New Vendor
                                </div>
                            </Col>
                </Row>

                <Row className='mb-8 w-auto'>
                    <Col className='align-items-center d-flex mt-3' xs={8} sm={6} md={6} lg={6}>
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isCheckAll} style={{ cursor: 'pointer' }} onChange={() => { setIsCheckAll(!isCheckAll) }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Select All
                                </label>
                            </div>
                            <h5 className='gray-500 mx-10 mb-0'>
                                Selected:{' '}
                                <span className='' style={{ color: 'black' }}>
                                    {length}
                                </span>
                            </h5>
                            </Col>
                    <Col className='align-items-center d-flex mt-3 justify-content-end pe-0' xs={4} sm={6} md={6} lg={6}>
                            <div className='mb-2 d-flex w-auto'>
                                <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none d-none d-sm-flex'
                                    onClick={() => navigate(`/vendor-management/management-vendors/add-vendors`)}>
                                    + Add New Vendor
                                </div>
                                <a
                                    onClick={() => Swal
                                        .fire({
                                            text: ' Are you sure you want to delete the selected vendor?  ',
                                            icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#D72852',
                                            confirmButtonText: 'Delete',
                                            showCancelButton: true,
                                            cancelButtonText: 'Cancel',
                                        })
                                        .then((res) => {
                                            if (res.isConfirmed) {
                                                deleteVendorData()
                                            }
                                        })}
                                    className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_create_app'
                                >
                                    Delete
                                </a>
                            </div>
                            </Col>
                </Row>
                    <Row className='mb-5'>
                    <div className='mb-2 min-w-200px px-5 w-auto'>
                            <div className='d-flex align-items-center'>
                                <input
                                    type='text'
                                    className='form-control w-auto'
                                    placeholder='vendor name'
                                    name='vendorName'
                                    // onChange={imageChange}
                                    value={formData?.vendorName}
                                    onChange={handleChnage}
                                />
                                <div className='menu-item px-3'>
                                    <div
                                        onClick={() => getVendorList()}
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    >
                                        Search
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-2 min-w-200px px-5 w-auto'>
                            <div className='d-flex align-items-center'>
                                <input
                                    type='text'
                                    className='form-control w-auto'
                                    placeholder='TRN'
                                    name='TRN'
                                    // onChange={imageChange}
                                    value={formData?.TRN}
                                    onChange={handleChnage}
                                />
                                <div className='menu-item px-3'>
                                    <div
                                        onClick={() => getVendorList()}
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    >
                                        Search
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-2 min-w-200px px-5 w-auto'>
                            <div className='d-flex align-items-center'>
                                <input
                                    type='text'
                                    className='form-control w-auto'
                                    placeholder='Trade License'
                                    name='tradeLicense'
                                    // onChange={imageChange}
                                    value={formData?.tradeLicense}
                                    onChange={handleChnage}
                                />
                                <div className='menu-item px-3'>
                                    <div
                                        onClick={() => getVendorList()}
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    >
                                        Search
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-2 min-w-200px px-5 w-auto'>
                            <select
                                name='city'
                                className='form-select'
                                onChange={handleChnage}
                            >
                                <option selected value=''>
                                    Select city
                                </option>
                                {/* {cities?.map((item:any) => (
                                    <option value="##">{item?.city}</option>
                                ))} */}
                            </select>
                        </div>
                    </Row>
                    <div className='card card-flush px-2'>
                    <div className='card-body pt-0 table-responsive px-3'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-10px'></th>
                                    <th className='min-w-100px'>Vendor ID </th>
                                    <th className='min-w-100px'>Vendor Name</th>
                                    <th className='min-w-70px'>TRN</th>
                                    <th className='min-w-150px'>Trade License</th>
                                    <th className='min-w-70px'>City</th>
                                    <th className='min-w-70px'>Main Contact (Email)</th>
                                    <th className='min-w-200px'>Main Contact (Mob. No.)</th>
                                    <th className='min-w-100px'>Date Added</th>
                                    <th className='min-w-100px'></th>
                                    <th className='min-w-100px'></th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                {vendorList?.map((v: any, i: any) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checkedState[i]} style={{ cursor: 'pointer' }} onChange={() => handeleSelectVendor(v?._id, i)} />
                                                </div>
                                            </td>
                                            <td>{v?.id}</td>
                                            <td>{v?.firstName}</td>
                                            <td>{v?.TRN}</td>
                                            <td>{v?.tradeLicenseNo}</td>
                                            <td>{v?.city}</td>
                                            <td>{v?.email}</td>
                                            <td>{v?.phoneNumber}</td>
                                            <td>{moment(v?.createdAt).format('DD/MM/YYYY')}</td>
                                            <td>
                                                <div className='menu-item px-3'>
                                                    <div
                                                        onClick={() => navigate(`/vendor-management/management-vendors/view-vendors/${v?._id}`)}
                                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                    >
                                                        View
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='menu-item px-3'>
                                                    <div
                                                        onClick={() => addTolist(v)}
                                                        className='btn btn-sm fw-bold btn-primary btn-green min-w-100px hover-none'
                                                    >
                                                        Add To List
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                        </div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div
                                className='dataTables_paginate paging_simple_numbers'
                                id='kt_ecommerce_sales_table_paginate'
                            >
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    pageClassName="paginate_button page-item +"
                                    pageLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    previousClassName="paginate_button page-item previous disabled"
                                    nextClassName="paginate_button page-item next"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageLimit}
                                    previousLabel="<"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
            </div >
            <AppVendormodal
                show={showAddVendorModal}
                handleClose={() => {
                    setShowAppVendorModal(false)
                }}
                setFormData={setFormData}
                formData={list}
                vendorsList={vendorsList}
                vendorList={vendorsList}
                vendorListArray={vendorListArrayData}
                setVendrorListArray={setVendrorListArray}
                type ={'manageVendor'}
                getVendorDataById={''}
            />
        </>
    );
}

export default ManagementVendors;
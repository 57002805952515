import React, {useState, useEffect} from 'react'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import receiptWhite from '../../../img/receipt-white.png'
import receiptBlue from '../../../img/receipt-blue.png'
import redCross from '../../../img/x-mark.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import ViewReceipt from './ViewReceipt'
import { ErrorToast } from '../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose: any
  receipts: any
  bankAccountItems: any
  paymentId: any
  from: any
}

const ViewAllReceipts = ({
  handleClose,
  show, 
  receipts,
  bankAccountItems,
  paymentId,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewReceipt, setViewReceipt] = useState<any>(false)
  const [receiptToShowEdit, setReceiptToShowEdit] = useState<any>()
  const [selectedAccountReceipt, setSelectedAccountReceipt] = useState<any>()

  const getReceiptById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_receipt/${id}?isCloneRecord=true`
    else url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setReceiptToShowEdit(res?.data?.data)
        for (let i = 0; i < bankAccountItems?.length; i++) {
          if (bankAccountItems[i]?._id == res?.data?.data?.paymentAccountId) {
            setSelectedAccountReceipt(bankAccountItems[i])
          }
        }
        setViewReceipt(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Receipts
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '45px'}}>
              <img src={receiptWhite} height={80} width={80} className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3'>
            {receipts?.length > 0 &&
              receipts.map((pm: any, index: any) => (
                <div className='img-sets mw-350px col-2 mt-2 ps-2 text-center'>
                  <a
                    onClick={() => {
                      if (
                        ((from == 'tenancy' || from == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.receipts?.view_details) ||
                            !user?.isSubUser)) ||
                        from == 'propertyProfile' ||
                        from == 'tenant' ||
                        from == 'financials'
                      ) {
                        getReceiptById(pm?._id)
                      } else {
                        ErrorToast(`You don't have the permissions to view receipt...`)
                      }
                    }}
                    style={{color: '#384a62'}}
                  >
                    <img
                      src={receiptBlue}
                      width={50}
                      height={50}
                      className='main_img cursor-pointer ms-1'
                      style={{}}
                    />

                    <p
                      style={{height: '15px', fontSize: '0.9rem', wordBreak: 'break-all'}}
                      className='mt-1 text-center'
                    >
                      {pm?.receiptNo}{' '}
                    </p>
                  </a>

                  {/* <img
                        src={redCross}
                        width='12'
                        height='12'
                        className='main_img cursor-pointer'
                        style={{marginTop: '-170px', marginLeft: '50px'}}
                        onClick={() => {
                          // setInvoiceAdded(false)
                          deleteInvoice()
                        }}
                      /> */}
                  {/* <input
                        type='text'
                        className='form-control form-control-solid-bg input-style mt-2'
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={receiptNo}
                        onChange={(event) => {
                          setReceiptNo(event.target.value)
                        }}
                        style={{height: '15px', fontSize: '8px', width: '50px'}}
                      /> */}
                  {/* <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16px'
                        height='16px'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='red'
                        className='w-6 h-6 closered cursor-pointer'
                        onClick={() => setInvoiceAdded(false)}
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M6 18L18 6M6 6l12 12'
                        />
                      </svg> */}
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {/* View Single Receipt */}
      {viewReceipt && (
        <ViewReceipt
          show={viewReceipt}
          handleClose={() => {
            setViewReceipt(false)
          }}
          receipt={receiptToShowEdit}
          paymentId={paymentId}
          selectedAccountReceipt={selectedAccountReceipt}
          tenantDetails={''}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewAllReceipts

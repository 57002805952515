import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import noData from '../../../img/NoData1.svg'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import allTen from '../../../img/all-tenc.png'
import bookTen from '../../../img/book-tenc.png'
import activeTen from '../../../img/active-tenc.png'
import expngTen from '../../../img/expng-tenc.png'
import terminTen from '../../../img/expired-tenc.png'
import trash from '../../../img/trash-white.png'
import create from '../../../img/create.png'
import closes from '../../../img/close.svg'
import windowImg from '../../../img/window.png'
import sync from '../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../img/expired-tab.png'
import SelectProperty from './SelectRenewTermProperty'
import {setComefrom} from '../../../redux/counterSlice'
import maintenance from '../../../img/maintenanceWhite.png'
import CRMResidents from './CRM/CRMResidents'
import { MultiSelect } from 'react-multi-select-component'

interface SquareHandleProps {
  value: number
}

function Tenancies() {
  const {state} = useLocation()
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const filters: any= localStorage.getItem('leaseFilters')
  const parsedFilters = JSON.parse(filters)

  const [isRequestList, setIsRequestList] = useState<any>(parsedFilters?.isRequestList ? parsedFilters?.isRequestList : false)

  const selectedUnit = useSelector((state: any) => state.counterReducer.selectedUnit)
  const dispatch = useDispatch()
  const [count, setCount] = useState<any>([])
  const [tableData, setTableData] = useState([])
  const [tableDataRequest, setTableDataRequest] = useState([])
  const [filteredData, setFilteredData] = useState<any>([])
  const [page, setPage] = useState<any>(parsedFilters?.page ? parsedFilters?.page : 1)
  const [limit, setLimit] = useState<any>(25)
  const [scrollStatus, setScrollStatus] = useState<any>(false)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const intl = useIntl()
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState<any>(parsedFilters?.isActive ? parsedFilters?.isActive : true)
  const [formData, setFormData] = useState<any>({})
  const [checkedValues, setCheckedValues] = useState<any>([])
  const [temp_data, setTemp_data] = useState<any>({
    contractno: '',
    unitno: '',
    // tenancyStatus: '',
    occupy: '',
    unitType: '',
    bedrooms: '',
  })

  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ]

  const unitOptions = [
    {value: 0, label: 'Apartment'},
    {value: 1, label: 'Penthouse'},
    {value: 2, label: 'Common Area'},
    {value: 3, label: 'Townhouse'},
    {value: 4, label: 'Villa'},
    {value: 5, label: 'Other'},
    {value: 6, label: 'Office'},
    {value: 7, label: 'Restaurant'},
    {value: 8, label: 'Cafe'},
    {value: 9, label: 'Retail'},
  ]

  let totalSelected: any = 0

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#000;',
      backgroundColor: state.isSelected ? '#007a59;' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '1000',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
      minWidth: '55px',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      // display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multiValue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
      minWidth: '55px',
    }),
  }

  useEffect(() => {
    if (state?.from === 'requests') {
      setIsActive(true)
      setFormStatus([])
      setTenancyStatus([])
      setRenewalStatus([0, 2, 7])
      setPage(1)
      setActiveTab('requests')
      setIsRequestList(true)
    }
  }, [])

  useEffect(() => {
    getCount()
    // window.addEventListener('scroll', handleScroll)
  }, [scrollStatus])

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const body = document.body,
      html = document.documentElement

    let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    let tenPercent = (height - window.innerHeight) * 0.9
    if (scrollTop > tenPercent) {
      if (scrollStatus) {
        setLimit(limit + 10)
        tenancyData(limit + 10)
        setScrollStatus(false)
      }
    } else {
      // console.log('true')
      setScrollStatus(false)
    }
  }

  const getCount = async () => {
    await ApiGet('corporate/tenancy/count')
      .then((res) => {
        setCount(res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  const [startDate, setStartDate] = useState<any>(parsedFilters?.startDate ? parsedFilters?.startDate : null)
  const [endDate, setEndDate] = useState<any>(parsedFilters?.endDate ? parsedFilters?.endDate : null)
  const [formStatus, setFormStatus] = useState<any>(parsedFilters?.formStatus ? parsedFilters?.formStatus  :  ['draft', 'created', 'cancelled'])
  const [tenancyStatus, setTenancyStatus] = useState<any>(parsedFilters?.tenancyStatus ? parsedFilters?.tenancyStatus  :[])
  const [tenantSource, setTenantSource] = useState<any>(parsedFilters?.tenantSource ? parsedFilters?.tenantSource : [0, 1])
  const [financial, setFinancial] = useState<any>(parsedFilters?.financial ? parsedFilters?.financial : {
    min: null,
    max: null,
  })
  const [paidPercentage, setPaidPercentage] = useState<any>(parsedFilters?.paidPercentage ? parsedFilters?.paidPercentage : null)
  const [paid, setPaid] = useState<any>(null)

  const [notPaidPercentage, setNotPaidPercentage] = useState<any>(parsedFilters?.notPaidPercentage ? parsedFilters?.notPaidPercentage : null)
  const [notPaid, setNotPaid] = useState<any>(null)

  const [contractNo, setContractNo] = useState<any>(parsedFilters?.contractNo ? parsedFilters?.contractNo : '')
  const [bedrooms, setBedrooms] = useState<any>(parsedFilters?.bedrooms ? parsedFilters?.bedrooms : [])
  const [unitType, setUnitType] = useState<any>(parsedFilters?.unitType ? parsedFilters?.unitType : [])

  const [activeTab, setActiveTab] = useState<any>(parsedFilters?.activeTab ? parsedFilters?.activeTab : 'all')
  const [renewalStatus, setRenewalStatus] = useState<any>(parsedFilters?.renewalStatus ? parsedFilters?.renewalStatus : [0, 2, 7])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    if (!isRequestList) {
      tenancyData(event.selected + 1)
    }else{
      getRequestsData(event.selected + 1)
    }
  }

  const [selectModal, setSelectModal] = useState<any>(false)

  // const searchByContractNo = (e: any, type: any) => {
  //   let text: any = e.target.value

  //   setTemp_data({...temp_data, [type]: text})
  // }

  // const goToTenancies = (tenantId: any, tenanacyId: any, unitId: any) => {
  //   console.log('\nunitId', unitId)
  //   localStorage.setItem('UnitId', unitId)
  //   dispatch(setComefrom('tenancy'))
  //   navigate(`/tenancy-details/${tenantId}/${tenanacyId}`)
  // }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const deleteTenancy = () => {
    if (activeTab == 'deleted') {
      tableData?.map((tb: any, ind: any) => {
        if (checkedValues[ind] == true) {
          ApiDelete(`corporate/tenancy/final/${tb?._id}`)
            .then((response: any) => {})
            .catch((error: any) => {
              console.log(error)
            })
        }
        return <></>
      })
    } else {
      tableData?.map((tb: any, ind: any) => {
        if (checkedValues[ind] == true) {
          ApiDelete(`corporate/tenancy/${tb?._id}`)
            .then((response: any) => {})
            .catch((error: any) => {
              console.log(error)
            })
        }
        return <></>
      })
    }

    setTimeout(() => {
      SuccessToast('Selected tenacies have been deleted successfully')
      tenancyData(page)
      tenancyCountSummary()
    }, 1500)
  }

  const [tenancyCount, setTenancyCount] = useState<any>()
  const [searchTenant, setSearchTenant] = useState<any>(parsedFilters?.searchTenant ? parsedFilters?.searchTenant : '')
  const [searchDevelopment, setSearchDevelopment] = useState<any>(parsedFilters?.searchDevelopment ? parsedFilters?.searchDevelopment : '')
  const [searchUnit, setSearchUnit] = useState<any>(parsedFilters?.searchUnit ? parsedFilters?.searchUnit : '')
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [hideContract, setHideContract] = React.useState<any>(true)
  const [hideStatus, setHideStatus] = React.useState<any>(true)
  const [hideTenant, setHideTenant] = React.useState<any>(true)
  const [hideProperty, setHideProperty] = React.useState<any>(true)
  const [hideFinancials, setHideFinancials] = React.useState<any>(true)

  const [termStartDate, setTermStartDate] = useState<any>()
  const [termEndDate, setTermEndDate] = useState<any>()

  const tenancyCountSummary = () => {
    ApiGet('corporate/tenancy/overview_count')
      .then((res) => {
        setTenancyCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const handleTermStartDateChange = (date: any) => {
    if (date != null) setStartDate(date.format('YYYY-MM-DD'))
    else setStartDate(null)
  }

  const handleTermEndDateChange = (date: any) => {
    if (date != null) setEndDate(date.format('YYYY-MM-DD'))
    else setEndDate(null)
  }

  // useEffect(() => {
  //   tenancyCountSummary()
  // }, [])

  const SquareHandle: React.FC<SquareHandleProps> = ({value}) => (
    <div
      style={{
        width: '35px',
        height: '35px',
        fontSize: '12px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#146C6A',
        marginLeft: `${value}%`,
        border: '2px solid #146C6A',
        transform: 'translate(0px, -32px)',
        position: 'relative',
        zIndex: '10',
        backgroundColor: 'white',
        fontWeight: '700',
      }}
    >
      {value}%
    </div>
  )

  const SquareHandleRed: React.FC<SquareHandleProps> = ({value}) => (
    <div
      style={{
        width: '35px',
        height: '35px',
        fontSize: '12px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#d54645',
        marginLeft: `${value}%`,
        border: '2px solid #d54645',
        transform: 'translate(0px, -32px)',
        position: 'relative',
        zIndex: '10',
        backgroundColor: 'white',
        fontWeight: '700',
      }}
    >
      {value}%
    </div>
  )

  const resetFilters = () => {
    setSearchDevelopment('')
    setSearchUnit('')
    setSearchTenant('')
    setFinancial({
      min: 0,
      max: null,
    })
    setStartDate(null)
    setEndDate(null)
    setContractNo('')
    setBedrooms([])
    setUnitType([])
    setTenancyStatus([])
    setFormStatus(['draft', 'created', 'cancelled'])
    setRenewalStatus([0, 2, 7])
    setPaidPercentage(null)
    setPaid(null)
    setNotPaidPercentage(null)
    setNotPaid(null)
    // setIsRequestList(false)
  }

  const tenancyData = async (pageVal: any) => {
    let uTypes:any = []
    unitType?.map((ut:any, ind:any)=>{
      uTypes[ind] = ut?.value
    })

    let bdRooms:any = []
    bedrooms?.map((ut:any, ind:any)=>{
      bdRooms[ind] = ut?.value
    })

    setTableDataRequest([])
    let body = {
      page: pageVal,
      limit: limit,
      searchContractNo: contractNo,
      // searchUnitNo: temp_data.unitno,
      unitType: uTypes,
      tenancyStatus: !isActive
        ? []
        : tenancyStatus?.length == 4
        ? undefined
        : tenancyStatus?.length == 0
        ? undefined
        : tenancyStatus,
      // occupy: temp_data.occupy != '' ? Number(temp_data.occupy) : null,
      bedrooms: bdRooms,
      searchDevelopment: searchDevelopment,
      searchUnit: searchUnit,
      searchTenant: searchTenant,
      startDate: startDate,
      endDate: endDate,
      formStatus:
        formStatus?.length == 3 ? undefined : formStatus?.length == 0 ? undefined : formStatus,
      tenantSource: tenantSource,
      financial: financial,
      paidPercentage: paidPercentage == null ? null : Number(paidPercentage),
      notPaidPercentage: notPaidPercentage == null ? null : Number(notPaidPercentage),
      renewalStatus: !isActive ? [] : renewalStatus?.length == 3 ? undefined : renewalStatus,
      isActive: isActive ? undefined : isActive,
      isRequestList: isRequestList,
    }
    // if (selectedUnit) {
    //   body['unitIds'] = [selectedUnit]
    // }
    await ApiPost('corporate/tenancy/overview', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.tenancy_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setTableData(res?.data?.data?.tenancy_data)
        setFilteredData(res?.data?.data?.tenancy_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    const filtersVal: any= localStorage.getItem('leaseFilters')
    const parsedFiltersVal = JSON.parse(filters)
    if (!isRequestList) {
      setPage(1)
      tenancyCountSummary()
      tenancyData(1)
    }
  }, [
    temp_data,
    selectedUnit,
    // page,
    paidPercentage,
    notPaidPercentage,
    searchDevelopment,
    searchTenant,
    searchUnit,
    financial,
    startDate,
    endDate,
    tenantSource,
    contractNo,
    bedrooms,
    unitType,
    renewalStatus,
    tenancyStatus,
    formStatus,
    isActive,
    isRequestList
  ])

  const getRequestsData = async (pageVal: any) => {
    let uTypes:any = []
    unitType?.map((ut:any, ind:any)=>{
      uTypes[ind] = ut?.value
    })

    let bdRooms:any = []
    bedrooms?.map((ut:any, ind:any)=>{
      bdRooms[ind] = ut?.value
    })

    setTableData([])
    let body = {
      page: pageVal,
      limit: limit,
      searchContractNo: contractNo,
      unitType: uTypes,
      tenancyStatus: !isActive
        ? []
        : tenancyStatus?.length == 4
        ? undefined
        : tenancyStatus?.length == 0
        ? undefined
        : tenancyStatus,
      bedrooms: bdRooms,
      searchDevelopment: searchDevelopment,
      searchUnit: searchUnit,
      searchTenant: searchTenant,
      startDate: startDate,
      endDate: endDate,
      formStatus:
        formStatus?.length == 3 ? undefined : formStatus?.length == 0 ? undefined : formStatus,
      tenantSource: tenantSource?.length == 2 ? undefined : tenantSource,
      financial: financial,
      paidPercentage: paidPercentage == null ? null : Number(paidPercentage),
      notPaidPercentage: notPaidPercentage == null ? null : Number(notPaidPercentage),
      renewalStatus: !isActive ? [] : renewalStatus?.length == 3 ? undefined : renewalStatus,
      isActive: isActive ? undefined : isActive,
      isRequestList: isRequestList,
    }

    await ApiPost('corporate/tenancy/request_list/overview', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.tenancy_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setTableDataRequest(res?.data?.data?.tenancy_data)
        setFilteredData(res?.data?.data?.tenancy_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (isRequestList) {
      setPage(1)
      getRequestsData(1)
      tenancyCountSummary()
    }
  }, [isRequestList,
    selectedUnit,
    // page,
    paidPercentage,
    notPaidPercentage,
    searchDevelopment,
    searchTenant,
    searchUnit,
    financial,
    startDate,
    endDate,
    tenantSource,
    contractNo,
    bedrooms,
    unitType,
    renewalStatus,
    tenancyStatus,
    formStatus,
    isActive,

  ])

  const goToTenancy = (v: any) => {
    if (
      (user?.isSubUser && role?.leasing_residential?.lease_agreements?.view_details) ||
      !user?.isSubUser
    ) {
      if (activeTab != 'deleted') {
        if (v?.isDraft) {
          localStorage.setItem('leaseWithFinancials', v?.isFinancialIncluded)
          if (v?.isApprovalEnabled) navigate(`/create-approve-tenancy/2/${v?.unitId}/${v?._id}`)
          else navigate(`/tenancy/create/2/${v?.unitId}/${v?._id}`)
        } else {
          localStorage.setItem('tenancyFlow', 'tenancies')
          localStorage.setItem('tenancyContract', v?.contractNo)
          navigate(`/tenancy-view/2/${v?.unitId}/${v?._id}`)
        }
      }
    } else {
      ErrorToast("You don't have the permissions to view details...")
    }
  }

  const goToRequest = (v: any) => {
    dispatch(setComefrom('tenancies'))
    navigate(
      `/lease-request/${v?._id}/${v?.tenancy?.[0]?.unitId}/${v?.tenancy?.[0]?._id}/${v?.type}`
    )
  }

  const handleSubmitId = (value: any) => {}

  const [tenantReqCount, setTenantReqCount] = useState<any>()

  const updateTenantReqCount = (cnt: any) => {
    setTenantReqCount(cnt)
  }

  //
  const getCRMTickets = () => {
    const body = {
      limit: 25,
      page: 1,
      buildingIds: [],
      communityIds: [],
      unitIds: [],
      tenantIds: [],
      crmTicketTypes: [],
      crmTicketStatus: [],
      crmTicketPriorities: [],
      crmAssignRoleTypes:[3]
    }
    ApiPost(`corporate/crm_ticket/get`, body)
      .then((res: any) => {
        setTenantReqCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getCRMTickets()
  }, [])

  useEffect(() => {
    let leaseFilters : any = {
      activeTab: activeTab,
      selectedUnit: selectedUnit,
      page: page, 
      paidPercentage: paidPercentage,
      notPaidPercentage: notPaidPercentage,
      searchDevelopment: searchDevelopment,
      searchTenant:searchTenant,
      searchUnit: searchUnit,
      financial: financial,
      startDate: startDate,
      endDate: endDate,
      tenantSource: tenantSource,
      contractNo: contractNo,
      bedrooms: bedrooms,
      unitType:  unitType,
      renewalStatus: renewalStatus,
      tenancyStatus: tenancyStatus,
      formStatus: formStatus,
      isActive: isActive,
      isRequestList: isRequestList
    }

    localStorage?.setItem('leaseFilters',JSON.stringify(leaseFilters))
    
  }, [activeTab,
    temp_data,
    selectedUnit,
    page,
    paidPercentage,
    notPaidPercentage,
    searchDevelopment,
    searchTenant,
    searchUnit,
    financial,
    startDate,
    endDate,
    tenantSource,
    contractNo,
    bedrooms,
    unitType,
    renewalStatus,
    tenancyStatus,
    formStatus,
    isActive,
    isRequestList,
  ])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  return (
    <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
      {' '}
      {/* container-xxl */}
      <div className='row'>
        <div className='col-12'>
          <h1 className='page-heading  m-0'>
            <b>Leases</b>
          </h1>
        </div>
      </div>
      <div className='d-flex flex-wrap maintenance justify-content-between'>
        {/* All tab */}
        {/* <div className='d-flex flex-wrap'> */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'all'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setActiveTab('all')
              setPage(1)
              setRenewalStatus([0, 2, 7])
              setTenancyStatus([])
              setFormStatus([])
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={allTen}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalAll ? tenancyCount?.totalAll?.toLocaleString('en-US', {}) : 0}
              </h2>
              <h5 className='mb-0 text-white'>All</h5>
            </div>
          </div>
          {activeTab == 'all' && <div className='active-filter'></div>}
        </div>
        {/* Booked Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'booked'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setActiveTab('booked')
              setPage(1)
              setRenewalStatus([0, 2, 7])
              setTenancyStatus([0])
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={bookTen}
                height={21}
                width={21}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalBooked
                  ? tenancyCount?.totalBooked?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Booked</h5>
            </div>
          </div>
          {activeTab == 'booked' && <div className='active-filter'></div>}
        </div>

        {/* Active Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'active'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setActiveTab('active')
              setFormStatus([])
              setPage(1)
              setRenewalStatus([0, 2, 7])
              setTenancyStatus([1])
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={activeTen}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalActive
                  ? tenancyCount?.totalActive?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Active</h5>
            </div>
          </div>
          {activeTab == 'active' && <div className='active-filter'></div>}
        </div>

        {/* Expiring Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'expiring'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setActiveTab('expiring')
              setFormStatus([])
              setPage(1)
              setRenewalStatus([0, 2, 7])
              setTenancyStatus([2])
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={expngTen}
                height={23}
                width={23}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalExpiring
                  ? tenancyCount?.totalExpiring?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Expiring</h5>
            </div>
          </div>
          {activeTab == 'expiring' && <div className='active-filter'></div>}
        </div>
        {/* Expired Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'expired'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setActiveTab('expired')
              setFormStatus([])
              setPage(1)
              setRenewalStatus([0, 2, 7])
              setTenancyStatus([3])
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={expiredTen}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalExpired
                  ? tenancyCount?.totalExpired?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Expired</h5>
            </div>
          </div>
          {activeTab == 'expired' && <div className='active-filter'></div>}
        </div>

        {/* Terminated Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'terminated'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setPage(1)
              setTenancyStatus([0, 1, 2, 3])
              setRenewalStatus([7])
              setActiveTab('terminated')
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={terminTen}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalTerminated
                  ? tenancyCount?.totalTerminated?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Terminated</h5>
            </div>
          </div>
          {activeTab == 'terminated' && <div className='active-filter'></div>}
        </div>

        {/* Draft Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'draft'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setFormStatus(['draft'])
              setTenancyStatus([])
              setRenewalStatus([0, 2, 7])
              setPage(1)
              setActiveTab('draft')
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={draftTen}
                height={26}
                width={26}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalDraft
                  ? tenancyCount?.totalDraft?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Draft</h5>
            </div>
          </div>
          {activeTab == 'draft' && <div className='active-filter'></div>}
        </div>

        {/* Cancelled Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'cancelled'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setFormStatus(['cancelled'])
              setTenancyStatus([])
              setRenewalStatus([0, 2, 7])
              setPage(1)
              setActiveTab('cancelled')
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={cancelledTen}
                height={22}
                width={24}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalCanceled
                  ? tenancyCount?.totalCanceled?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Cancelled</h5>
            </div>
          </div>
          {activeTab == 'cancelled' && <div className='active-filter'></div>}
        </div>

        {/* Requests Tab */}
        <div className='me-5 mb-5'>
          <div
            className={
              activeTab == 'requests'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(true)
              setFormStatus([])
              setTenancyStatus([])
              setRenewalStatus([0, 2, 7])
              setPage(1)
              setActiveTab('requests')
              setIsRequestList(true)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={draftTen}
                height={26}
                width={26}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalRequest
                  ? tenancyCount?.totalRequest?.toLocaleString('en-US', {})
                  : 0}
              </h2>

              <h5 className='mb-0 text-white'>Requests</h5>
            </div>
          </div>
          {activeTab == 'requests' && <div className='active-filter'></div>}
        </div>

        {/* Deleted Tab */}
        <div className='me-3 mb-5'>
          <div
            className={
              activeTab == 'deleted'
                ? 'tenancy-active-card d-flex align-items-center'
                : 'tenancy-green-card d-flex align-items-center'
            }
            onClick={() => {
              setIsActive(false)
              setFormStatus([])
              setPage(1)
              setActiveTab('deleted')
              setIsRequestList(false)
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={trash}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenancyCount?.totalDeleted
                  ? tenancyCount?.totalDeleted?.toLocaleString('en-US', {})
                  : '0'}
              </h2>

              <h5 className='mb-0 text-white'>Deleted</h5>
            </div>
          </div>
          {activeTab == 'deleted' && <div className='active-filter'></div>}
        </div>
        {/* </div> */}

        {/* Tenant Requests Tab */}
        <div className='me-3 mb-5 ms-auto'>
          <div
            className={
              activeTab == 'tenant-requests'
                ? 'tenancy-active-card d-flex align-items-center gray-card'
                : 'tenancy-green-card d-flex align-items-center gray-card'
            }
            onClick={() => {
              setActiveTab('tenant-requests')
              setPage(1)
              // setAnnouncementStatus([0])
            }}
          >
            <div
              className=''
              style={{
                fontWeight: '700',
                width: 'fit-content',
              }}
            >
              <img
                src={maintenance}
                height={26}
                width={26}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              />
            </div>
            <div>
              <h2 className='mb-0 text-white'>
                {tenantReqCount ? tenantReqCount?.toLocaleString('en-US', {}) : 0}
              </h2>

              <h5 className='mb-0 text-white'>Tenant Requests</h5>
            </div>
          </div>
          {activeTab == 'tenant-requests' && <div className='active-filter gray-card'></div>}
        </div>
      </div>
      {activeTab != 'tenant-requests' && (
        <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <label className='head-text' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Tenant...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchTenant}
                onChange={(e: any) => setSearchTenant(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div className='mb-2 col-md-2 ps-5 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Development...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchDevelopment}
                onChange={(e: any) => setSearchDevelopment(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className='mb-2 col-md-2 ps-5 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Unit...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchUnit}
                onChange={(e: any) => setSearchUnit(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <div
              className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
              onClick={() => setShowFilter(true)}
            >
              <svg
                style={{
                  height: '23px',
                  width: '23px',
                  marginLeft: '3px',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
            </div>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='mb-2 col-md-4 xyz ms-auto pe-0 text-end'>
            <div className='d-flex justify-content-end'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex '>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                    onClick={() => {
                      swal
                        .fire({
                          text: 'Are you sure you want to delete the selected tenancies ?',
                          icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#D72852',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          // cancelButtonColor: "transparent",
                          cancelButtonText: 'Cancel',
                        })
                        .then((res) => {
                          if (res.isConfirmed) {
                            deleteTenancy()
                          }
                        })
                    }}
                  >
                    <img src={trashImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                    Delete
                  </button>
                </>
              )}

              {activeTab == 'requests' ? (
                <>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold term-btn me-0 mt-2 ps-2  pe-0 mt-4  ten-renew-term status-w-150'
                    style={{width: ''}}
                    onClick={() => {
                      localStorage.setItem('requestDropdown', 'termination')
                      setSelectModal(true)
                    }}
                  >
                    <img src={terminTen} height={18} width={18} className='cursor-pointer me-4' />
                    Terminate Lease
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold renew-btn me-0 mt-2 ps-2  pe-0 mt-4 ten-renew-term ms-4 status-w-150'
                    onClick={() => {
                      localStorage.setItem('requestDropdown', 'renewal')
                      setSelectModal(true)
                    }}
                  >
                    <img src={sync} height={20} width={20} className='cursor-pointer me-4' />
                    Renew Lease
                  </button>
                </>
              ) : (
                <>
                  {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.create) ||
                    !user?.isSubUser) && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn '
                      onClick={() => {
                        ApiGet(`corporate/sub_workflow_list/get?name=New Lease&subWorkflowName=Residential Leasing`)
                          .then((res: any) => {
                            localStorage.setItem(
                              'leaseWithFinancials',
                              res?.data?.data?.isFinancialIncluded
                            )
                            if (res?.data?.data?.isApprovalEnabled)
                              navigate('/create-approve-tenancy/1')
                            else navigate('/tenancy/create/1')
                          })
                          .catch((err: any) => {})
                      }}
                    >
                      <img src={create} height={20} width={20} className='me-5' /> Create
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {activeTab == 'tenant-requests' ? (
        <div className='row'>
          <div className='col-12'>
            <CRMResidents
              updateCount={(val: any) => {
                updateTenantReqCount(val)
              }}
            />
          </div>
        </div>
      ) : (
        <div className='card card-flush py-5  px-7'>
          {' '}
          {/* container-xxl */}
          <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
            <div className='table-responsive '>
              {/*  */}
              {activeTab === 'requests' ? (
                <table
                  className='table align-middle table-row-dashed fs-8 gy-5 '
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.delete) ||
                      !user?.isSubUser) && (
                      <>
                        {/* <th className='text-start min-w-25px test'>
                       <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                         <input
                           className='form-check-input'
                           type='checkbox'
                           id='vacant0'
                           name='none'
                           checked={
                             totalSelected == tableData?.length && tableData?.length != 0
                               ? true
                               : false
                           }
                           onChange={(e: any) => {
                             let values = [...checkedValues]
                             if (e.target.checked) {
                               for (let i = 0; i < tableData?.length; i++) {
                                 values[i] = true
                               }
                             } else {
                               for (let i = 0; i < tableData?.length; i++) {
                                 values[i] = false
                               }
                             }
                             setCheckedValues(values)
                           }}
                         />
                       </div>
                     </th> */}
                      </>
                    )}
                    <th className='text-start min-w-125px ps-2'>Contract No.</th>
                    <th className='text-start min-w-125px ps-2'>Tenant Name</th>
                    <th className='text-start min-w-125px ps-2'>Unit No.</th>
                    <th className='text-start min-w-150px ps-2'>Development</th>
                    <th className='text-start min-w-100px ps-2'>Unit Type </th>
                    <th className='text-start min-w-100px ps-2'>Bedrooms </th>
                    <th className='text-center min-w-150px'>Lease Status</th>
                    <th className='text-start min-w-125px ps-2'>Type</th>

                    <th className='text-center min-w-250px'>Request Status</th>
                    <th className='text-center min-w-150px'>Action Required </th>
                    <th className='text-start min-w-125px ps-2'>Requestor</th>

                    <th className='text-start min-w-125px ps-2'>Remaining Days</th>
                  </thead>
                  <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                    {tableDataRequest?.length ? (
                      tableDataRequest?.map((v: any, i: any) => {
                        return (
                          <tr>
                            {((user?.isSubUser &&
                              role?.leasing_residential?.lease_agreements?.delete) ||
                              !user?.isSubUser) && (
                              <>
                                {/* <td className='text-start ps-0 test'>
                               <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                 <input
                                   className='form-check-input'
                                   type='checkbox'
                                   id='vacant0'
                                   name='none'
                                   checked={checkedValues[i] == true ? true : false}
                                   onChange={(e: any) => {
                                     const values = [...checkedValues]
                                     values[i] = !values[i]
                                     setCheckedValues(values)
                                   }}
                                 />
                               </div>
                             </td> */}
                              </>
                            )}

                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='2022-09-09'
                            >
                              {v?.tenancy?.[0]?.contractNo ? v?.tenancy?.[0]?.contractNo : '-'}
                            </td>

                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='2022-09-11'
                            >
                              {v?.tenancy?.[0]?.tenant?.[0]?.firstName ||
                              v?.tenancy?.[0]?.tenant?.[0]?.lastName
                                ? `${v?.tenancy?.[0]?.tenant?.[0]?.firstName} ${v?.tenancy?.[0]?.tenant?.[0]?.lastName}`
                                : '-'}
                            </td>
                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              {v?.tenancy?.[0]?.unit?.[0]?.unitNo
                                ? v?.tenancy?.[0]?.unit?.[0]?.unitNo
                                : '-'}
                            </td>

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              {v?.tenancy?.[0]?.community?.[0]?.name
                                ? v?.tenancy?.[0]?.community?.[0]?.name
                                : v?.tenancy?.[0]?.building?.[0]?.name}
                            </td>

                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='Denied'
                            >
                              {v?.tenancy?.[0]?.unit?.[0]?.unitType == 0
                                ? 'Apartment'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 1
                                ? 'Penthouse'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 2
                                ? 'Common Area'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 3
                                ? 'TownHouse'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 4
                                ? 'Villa'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 5
                                ? 'Other'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 6
                                ? 'Office'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 7
                                ? 'Restaurant'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 8
                                ? 'Cafe'
                                : v?.tenancy?.[0]?.unit?.[0]?.unitType == 9
                                ? 'Retail'
                                : '-'}
                            </td>

                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              {v?.tenancy?.[0]?.unit?.[0]?.bedrooms
                                ? v?.tenancy?.[0]?.unit?.[0]?.bedrooms
                                : '-'}
                            </td>

                            <td
                              className='text-center ps-7 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='2022-09-11'
                            >
                              <div
                                className={
                                  v?.tenancy?.[0]?.tenancyStatus == 0
                                    ? 'status booked-bar me-5 status-w-120'
                                    : v?.tenancy?.[0]?.tenancyStatus == 1
                                    ? 'status active-bar me-5 status-w-120'
                                    : v?.tenancy?.[0]?.tenancyStatus == 2
                                    ? 'status expiring-bar me-5 status-w-120'
                                    : v?.tenancy?.[0]?.tenancyStatus == 3
                                    ? 'status expired-bar me-5 status-w-120'
                                    : 'status  no-st-bar me-5 status-w-120'
                                }
                              >
                                {v?.tenancy?.[0]?.tenancyStatus == 0
                                  ? 'Booked'
                                  : v?.tenancy?.[0]?.tenancyStatus == 1
                                  ? 'Active'
                                  : v?.tenancy?.[0]?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : v?.tenancy?.[0]?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                            </td>

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              <div className='d-flex align-items-center'>
                                {' '}
                                <div
                                  className={
                                    v?.type === 2
                                      ? 'select-dot me-2'
                                      : v?.type === 3
                                      ? 'red-dot me-2'
                                      : '-'
                                  }
                                ></div>
                                {/* <p className='head-text user-head pe-2 mb-0'> */}
                                {v?.type === 2 ? 'Renewal' : v?.type === 3 ? 'Termination' : '-'}
                                {/* </p> */}
                              </div>
                            </td>

                            <td
                              className='text-center cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              <div
                                className={
                                  v?.status === 0
                                    ? 'status create-st status-w-230 px-1'
                                    : v?.status === 1
                                    ? 'status pending-approval-st status-w-230 px-1'
                                    : v?.status === 2
                                    ? 'status tenant-approve-st  status-w-230 px-1'
                                    : v?.status === 4 || v?.status === 6 || v?.status === 5
                                    ? 'status cancelled-st status-w-230 px-1'
                                    : v?.status === 10
                                    ? 'status pend-st status-w-230 px-1'
                                    : v?.status === 7
                                    ? 'status draft-st status-w-230 px-1'
                                    : ''
                                }
                              >
                                {v?.status === 0
                                  ? 'Requested'
                                  : v?.status === 1
                                  ? 'Submitted - Pending Tenant Approval'
                                  : v?.status === 2
                                  ? 'Tenant Approved'
                                  : v?.status === 4 || v?.status === 6
                                  ? 'Cancelled'
                                  : v?.status === 5
                                  ? 'Tenant Rejected'
                                  : v?.status === 10
                                  ? 'Draft'
                                  : v?.status === 7
                                  ? 'Under Approval'
                                  : '-'}
                              </div>
                            </td>

                            <td
                              className='text-center cursor-pointer'
                              onClick={() => goToRequest(v)}
                            >
                              <div
                                className={
                                  v?.status === 4 || v?.status === 6
                                    ? ''
                                    : v?.approval_workflow?.[0]?.approvalStatus === 0
                                    ? 'tenancy-stats pend-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 1
                                    ? 'tenancy-stats create-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 2
                                    ? 'tenancy-stats pending-approval-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 3
                                    ? 'tenancy-stats approve-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 4
                                    ? 'tenancy-stats sent-back-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 5
                                    ? 'tenancy-stats draft-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 6
                                    ? 'tenancy-stats cancelled-st px-1 status-w-140'
                                    : ''
                                }
                              >
                                {v?.approval_workflow?.length > 0 &&
                                v?.status !== 4 &&
                                v?.status !== 6 ? (
                                  <>
                                    {v?.approval_workflow?.[0]?.approvalStatus === 0
                                      ? 'Pending Submission'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 1
                                      ? 'Submitted'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 2
                                      ? 'Pending Approval'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 3
                                      ? 'Approved'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 4
                                      ? 'Sent Back'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 5
                                      ? 'Reassigned'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 6
                                      ? 'Locked'
                                      : '-'}
                                  </>
                                ) : (
                                  <div className='tenancy-stats not-saved px-1 status-w-140'>-</div>
                                )}
                              </div>
                            </td>
                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='2022-09-11'
                            >
                              {v?.requestor?.firstName || v?.requestor?.lastName
                                ? `${v?.requestor?.firstName} ${v?.requestor?.lastName}`
                                : '-'}
                            </td>

                            <td
                              className='text-start ps-2 cursor-pointer'
                              onClick={() => goToRequest(v)}
                              data-order='2022-09-09'
                            >
                              {v?.tenancy?.[0]?.remainingDays
                                ? v?.tenancy?.[0]?.remainingDays
                                : '-'}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <table
                  className='table align-middle table-row-dashed fs-8 gy-5 '
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.delete) ||
                      !user?.isSubUser) && (
                      <>
                        {(activeTab === 'cancelled' ||
                          activeTab == 'draft' ||
                          activeTab == 'expired' ||
                          (activeTab === 'deleted' &&
                            ((user?.isSubUser &&
                              role?.leasing_residential?.lease_deletion?.allow) ||
                              !user?.isSubUser))) && (
                          <th className='text-start min-w-25px test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={
                                  totalSelected == tableData?.length && tableData?.length != 0
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  let values = [...checkedValues]
                                  if (e.target.checked) {
                                    for (let i = 0; i < tableData?.length; i++) {
                                      values[i] = true
                                    }
                                  } else {
                                    for (let i = 0; i < tableData?.length; i++) {
                                      values[i] = false
                                    }
                                  }
                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </th>
                        )}
                      </>
                    )}
                    <th className='text-start min-w-125px'>Contract No.</th>
                    <th className='text-start min-w-125px'>Tenant Name</th>
                    <th className='text-start min-w-125px'>Unit No.</th>
                    <th className='text-start min-w-150px'>Development</th>
                    <th className='text-start min-w-100px'>Unit Type </th>
                    <th className='text-start min-w-100px'>Bedrooms </th>
                    {activeTab == 'all' && (
                      <th className='text-center min-w-150px'>Lease Status </th>
                    )}
                    <th className='text-start min-w-100px'>Term </th>
                    <th className='text-start min-w-100px'>Source </th>
                    <th className='text-center min-w-175px'>Renewal Status </th>
                    <th className='text-start min-w-100px'>Rent Value</th>
                    <th className='text-start min-w-150px'>Security Deposits</th>
                    <th className='text-start min-w-150px'>Other Payments</th>
                    <th className='text-start min-w-100px'>Paid</th>
                    <th className='text-start min-w-100px'>Upcoming</th>
                    <th className='text-start min-w-100px'>Overdue</th>
                    <th className='text-center min-w-150px'>Action Required </th>
                    <th className='text-center min-w-125px'>Form Status</th>
                    <th className='text-start min-w-150px'>Created By</th>
                    <th className='text-start min-w-150px'>Last Updated</th>
                  </thead>
                  <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                    {tableData?.length ? (
                      tableData?.map((v: any, i: any) => {
                        return (
                          <tr>
                            {((user?.isSubUser &&
                              role?.leasing_residential?.lease_agreements?.delete) ||
                              !user?.isSubUser) && (
                              <>
                                {(activeTab === 'cancelled' ||
                                  activeTab == 'draft' ||
                                  activeTab == 'expired' ||
                                  (activeTab === 'deleted' &&
                                    ((user?.isSubUser &&
                                      role?.leasing_residential?.lease_deletion?.allow) ||
                                      !user?.isSubUser))) && (
                                  <td className='text-start ps-0 test'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='vacant0'
                                        name='none'
                                        checked={checkedValues[i] == true ? true : false}
                                        onChange={(e: any) => {
                                          const values = [...checkedValues]
                                          values[i] = !values[i]
                                          setCheckedValues(values)
                                        }}
                                      />
                                    </div>
                                  </td>
                                )}
                              </>
                            )}

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.contractNo ? v?.contractNo : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-11'
                            >
                              {v?.tenant?.[0]?.firstName || v?.tenant?.[0]?.lastName
                                ? `${v?.tenant?.[0]?.firstName} ${v?.tenant?.[0]?.lastName}`
                                : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                            >
                              {v?.unit?.[0]?.unitNo ? v?.unit?.[0]?.unitNo : '-'}
                            </td>

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                            >
                              {/* {(page - 1) * 10 + (i + 1)} */}
                              {v?.community?.[0]?.name
                                ? v?.community?.[0]?.name
                                : v?.building?.[0]?.name}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='Denied'
                            >
                              {v?.unit?.[0]?.unitType == 0
                                ? 'Apartment'
                                : v?.unit?.[0]?.unitType == 1
                                ? 'Penthouse'
                                : v?.unit?.[0]?.unitType == 2
                                ? 'Common Area'
                                : v?.unit?.[0]?.unitType == 3
                                ? 'TownHouse'
                                : v?.unit?.[0]?.unitType == 4
                                ? 'Villa'
                                : v?.unit?.[0]?.unitType == 5
                                ? 'Other'
                                : v?.unit?.[0]?.unitType == 6
                                ? 'Office'
                                : v?.unit?.[0]?.unitType == 7
                                ? 'Restaurant'
                                : v?.unit?.[0]?.unitType == 8
                                ? 'Cafe'
                                : v?.unit?.[0]?.unitType == 9
                                ? 'Retail'
                                : '-'}
                            </td>
                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                            >
                              {v?.unit?.[0]?.bedrooms ? v?.unit?.[0]?.bedrooms : '-'}
                            </td>

                            {activeTab == 'all' && (
                              <td
                                className='text-center ps-7 cursor-pointer'
                                onClick={() => goToTenancy(v)}
                                data-order='2022-09-11'
                              >
                                <div
                                  className={
                                    v?.tenancyStatus == 0
                                      ? 'status booked-bar me-5 status-w-120'
                                      : v?.tenancyStatus == 1
                                      ? 'status active-bar me-5 status-w-120'
                                      : v?.tenancyStatus == 2
                                      ? 'status expiring-bar me-5 status-w-120'
                                      : v?.tenancyStatus == 3
                                      ? 'status expired-bar me-5 status-w-120'
                                      : 'status  no-st-bar me-5 status-w-120'
                                  }
                                >
                                  {v?.tenancyStatus == 0
                                    ? 'Booked'
                                    : v?.tenancyStatus == 1
                                    ? 'Active'
                                    : v?.tenancyStatus == 2
                                    ? 'Expiring'
                                    : v?.tenancyStatus == 3
                                    ? 'Expired'
                                    : '-'}
                                </div>
                              </td>
                            )}

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-11'
                            >
                              {v?.duration?.start_date
                                ? moment(v?.duration?.start_date).format('DD/MM/YYYY')
                                : '-'}{' '}
                              {' - '}
                              {v?.duration?.end_date
                                ? moment(v?.duration?.end_date).format('DD/MM/YYYY')
                                : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-11'
                            >
                              {v?.tenant?.[0]?.tenantSource == 0 ? 'Agent' : 'Direct'}
                            </td>

                            <td
                              className='text-center cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-11'
                            >
                              <div
                                className={
                                  v?.renewalStatus == 0
                                    ? 'renew-status create-st'
                                    : v?.renewalStatus == 1 &&
                                      v?.tenant_request?.[0]?.requestorType == 0
                                    ? 'renew-status requested-st'
                                    : v?.renewalStatus == 1 &&
                                      v?.tenant_request?.[0]?.requestorType == 1
                                    ? 'renew-status requested-st'
                                    : v?.renewalStatus == 2
                                    ? 'renew-status renewed-st '
                                    : v?.renewalStatus == 3 ||
                                      v?.renewalStatus == 4 ||
                                      v?.renewalStatus == 5
                                    ? 'renew-status cancelled-st'
                                    : v?.renewalStatus == 6 &&
                                      v?.tenant_request?.[0]?.requestorType == 0
                                    ? 'renew-status create-st'
                                    : v?.renewalStatus == 6 &&
                                      v?.tenant_request?.[0]?.requestorType == 1
                                    ? 'renew-status create-st'
                                    : v?.renewalStatus == 7
                                    ? 'renew-status cancelled-st'
                                    : v?.renewalStatus == 8 ||
                                      v?.renewalStatus == 9 ||
                                      v?.renewalStatus == 10
                                    ? 'renew-status cancelled-st'
                                    : 'renew-status not-saved'
                                }
                              >
                                {v?.renewalStatus == 0
                                  ? 'Not Renewed'
                                  : v?.renewalStatus == 1 &&
                                    v?.tenant_request?.[0]?.requestorType == 0
                                  ? 'Under Renewal'
                                  : v?.renewalStatus == 1 &&
                                    v?.tenant_request?.[0]?.requestorType == 1
                                  ? 'Under Renewal'
                                  : v?.renewalStatus == 2
                                  ? 'Renewed'
                                  : v?.renewalStatus == 3 ||
                                    v?.renewalStatus == 4 ||
                                    v?.renewalStatus == 5
                                  ? 'Renewal Cancelled'
                                  : v?.renewalStatus == 6 &&
                                    v?.tenant_request?.[0]?.requestorType == 0
                                  ? 'Termination Requested'
                                  : v?.renewalStatus == 6 &&
                                    v?.tenant_request?.[0]?.requestorType == 1
                                  ? 'Termination Submitted'
                                  : v?.renewalStatus == 7
                                  ? 'Terminated'
                                  : v?.renewalStatus == 8 ||
                                    v?.renewalStatus == 9 ||
                                    v?.renewalStatus == 10
                                  ? 'Termination Cancelled' //Termination Cancelled
                                  : '-'}
                              </div>
                            </td>
                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.totalRent
                                ? v?.totalRent?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.totalSecurityDeposit
                                ? v?.totalSecurityDeposit?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.totalOther
                                ? v?.totalOther?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>

                            <td className='text-start ps-0' data-order='2022-09-09'>
                              {v?.totalPaid
                                ? v?.totalPaid?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.totalUpcoming
                                ? v?.totalUpcoming?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-09'
                            >
                              {v?.totalOverdue
                                ? v?.totalOverdue?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : 0}
                            </td>
                            <td
                              className='text-center cursor-pointer'
                              onClick={() => goToTenancy(v)}
                            >
                              <div
                                className={
                                  v?.approval_workflow?.[0]?.approvalStatus === 0
                                    ? 'tenancy-stats pend-st px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 1
                                    ? 'tenancy-stats create-st  px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 2
                                    ? 'tenancy-stats pending-approval-st  px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 3
                                    ? 'tenancy-stats approve-st  px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 4
                                    ? 'tenancy-stats sent-back-st  px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 5
                                    ? 'tenancy-stats draft-st  px-1 status-w-140'
                                    : v?.approval_workflow?.[0]?.approvalStatus === 6
                                    ? 'tenancy-stats cancelled-st  px-1 status-w-140'
                                    : ''
                                }
                              >
                                {v?.approval_workflow?.length > 0 ? (
                                  <>
                                    {v?.approval_workflow?.[0]?.approvalStatus === 0
                                      ? 'Pending Submission'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 1
                                      ? 'Submitted'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 2
                                      ? 'Pending Approval'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 3
                                      ? 'Approved'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 4
                                      ? 'Sent Back'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 5
                                      ? 'Reassigned'
                                      : v?.approval_workflow?.[0]?.approvalStatus === 6
                                      ? 'Locked'
                                      : '-'}
                                  </>
                                ) : (
                                  <div className='tenancy-stats not-saved px-1 status-w-140'>-</div>
                                )}
                              </div>
                            </td>
                            <td
                              className='text-center ps-7 cursor-pointer'
                              onClick={() => goToTenancy(v)}
                              data-order='2022-09-11'
                            >
                              {activeTab == 'deleted' ? (
                                <>
                                  <div className='status cancelled-st me-5 status-w-120'>
                                    Deleted
                                  </div>
                                </>
                              ) : (
                                <>
                                  {v?.isCancel ? (
                                    <>
                                      <div className='status cancelled-st me-5 status-w-120'>
                                        Cancelled
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      className={
                                        v?.isDraft
                                          ? 'status draft-st me-5 status-w-120'
                                          : 'status create-st me-5 status-w-120'
                                      }
                                    >
                                      {v?.isDraft ? 'Draft' : 'Created'}
                                    </div>
                                  )}
                                </>
                              )}
                            </td>

                            <td className='py-3 ps-0 cursor-pointer' onClick={() => goToTenancy(v)}>
                              <p className='my-0'>
                                {v?.createdBy?.[0] &&
                                  `${v?.createdBy?.[0]?.firstName} ${v?.createdBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0'>
                                {v?.createdAt
                                  ? `${moment(v?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '-'}
                              </p>
                            </td>

                            <td className='py-3 ps-0 cursor-pointer' onClick={() => goToTenancy(v)}>
                              <p className='my-0'>
                                {v?.updatedBy?.[0] &&
                                  `${v?.updatedBy?.[0]?.firstName} ${v?.updatedBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0'>
                                {v?.updatedAt
                                  ? ` ${moment(v?.updatedAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '- -'}
                              </p>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={20} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Filters */}
      <div
        className={showFilter == true ? 'filter-payments test ' : 'd-none'}
        style={{
          width: '420px',
          backgroundColor: 'white',
          zIndex: '999',
          boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
          paddingBottom: '50px',
        }}
      >
        <div
          className='card m-0 p-0 '
          style={{
            overflow: 'hidden',
            borderRadius: '0px',
            boxShadow: 'none',
          }}
        >
          {/* header */}
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{
              justifyContent: 'space-between',
              borderRadius: '0px',
              zIndex: '1',
              opacity: '0.85',
            }}
          >
            Filter
            <span className='mx-0' style={{cursor: 'pointer', marginTop: '-20px'}}>
              <img src={closes} alt='' onClick={() => setShowFilter(false)} />
            </span>
          </h3>

          <h3
            className='m-0 d-flex align-items-center '
            style={{
              justifyContent: 'flex-end',
              borderRadius: '0px',
              transform: 'translate(0px, -50px)',
              color: 'white',
              backgroundColor: '#146C6A',
              paddingBottom: '5px',
              background:
                'linear-gradient(41deg, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 0%)',
            }}
          >
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <svg
                style={{
                  height: '40px',
                  width: '38px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '5px',
                  transform: 'translate(-30px, 0px)',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
            </span>
          </h3>
        </div>

        <div
          style={{
            height: '100%',
            overflow: 'scroll',
            transform: 'translate(0px, -50px)',
            overflowX: 'hidden',
          }}
        >
          {/* Contract Details */}
          <div className='min-w-200px  ps-3 pt-1 card' style={{borderRadius: '0px'}}>
            <div className='ms-auto mt-3 me-5'>
              <div
                className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                onClick={() => resetFilters()}
              >
                <img src={sync} height={22} width={24} className='cursor-pointer' />
              </div>
            </div>
            <label className='mb-2 ms-2' style={{fontWeight: '700', fontSize: '14px'}}>
              {' '}
              Contract Details
            </label>

            {hideContract !== true && (
              <>
                {/* Contract No */}
                <div className='d-flex align-items-center mb-2 xyz'>
                  <img src={windowImg} height='14' width='14' className='me-4 ms-2 ' />

                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Contract No...'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={contractNo}
                      onChange={(e: any) => setContractNo(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
                <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>
                {/* Term Date */}
                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{fontWeight: '600'}}
                  >
                    Term Date
                  </label>
                </div>
                <div className='d-flex mb-2 ms-2 ' style={{paddingLeft: '27px'}}>
                  {startDate == null ? (
                    <DatePicker
                      onChange={handleTermStartDateChange}
                      style={{width: '75%'}}
                      className='me-5 bg-white'
                      placeholder='From'
                    />
                  ) : (
                    <DatePicker
                      value={moment(startDate, 'YYYY-MM-DD')}
                      onChange={handleTermStartDateChange}
                      style={{width: '75%'}}
                      className='me-5 bg-white'
                      placeholder='From'
                      format={'DD-MM-YYYY'}

                    /> 
                  )}
                  {endDate == null ? (
                    <DatePicker
                      onChange={handleTermEndDateChange}
                      style={{width: '75%'}}
                      className='me-5 bg-white'
                      placeholder='To'
                    />
                  ) : (
                    <DatePicker
                      value={moment(endDate, 'YYYY-MM-DD')}
                      onChange={handleTermEndDateChange}
                      style={{width: '75%'}}
                      className='me-5 bg-white'
                      placeholder='To'
                      format={'DD-MM-YYYY'}
                    />
                  )}
                </div>
              </>
            )}

            <div className='ms-auto mt-3 mb-3 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideContract == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideContract(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideContract(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Status */}
          <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{borderRadius: '0px'}}>
            <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
              {' '}
              Status
            </label>

            {hideStatus == false && (
              <>
                {activeTab != 'booked' &&
                  activeTab != 'active' &&
                  activeTab != 'expiring' &&
                  activeTab != 'expired' && (
                    <>
                      <div className='d-flex align-items-baseline ms-2 '>
                        <img src={windowImg} height='14' width='14' className='me-4' />

                        <label
                          className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                          style={{fontWeight: '600'}}
                        >
                          Lease Status
                        </label>
                      </div>
                      <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                        <div className='col-3 d-flex align-items-center xyz'>
                          <input
                            className='form-check-input me-3 cursor-pointer'
                            type='radio'
                            checked={
                              tenancyStatus.length == 4 || tenancyStatus.length == 0 ? true : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked == true) {
                                let values = [0, 1, 2, 3]
                                setTenancyStatus(values)
                              } else {
                                setTenancyStatus([])
                              }
                            }}
                          />{' '}
                          All
                        </div>
                      </div>

                      <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                        <div className='col-3 d-flex align-items-center test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                (tenancyStatus[0] == 0 ||
                                  tenancyStatus[1] == 0 ||
                                  tenancyStatus[2] == 0 ||
                                  tenancyStatus[3] == 0) &&
                                tenancyStatus.length != 4
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  if (tenancyStatus.length == 4) {
                                    let values = [0]
                                    setTenancyStatus(values)
                                  } else {
                                    let values = [...tenancyStatus]
                                    values[values.length] = 0
                                    setTenancyStatus(values)
                                  }
                                } else {
                                  let values = [...tenancyStatus]
                                  if (values[0] == 0) values.splice(0, 1)
                                  else if (values[1] == 0) values.splice(1, 1)
                                  else if (values[2] == 0) values.splice(2, 1)
                                  else values.splice(3, 1)

                                  setTenancyStatus(values)
                                }
                              }}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Booked
                            </p>
                          </div>
                        </div>

                        <div className='col-3 d-flex align-items-center'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                (tenancyStatus[0] == 1 ||
                                  tenancyStatus[1] == 1 ||
                                  tenancyStatus[2] == 1 ||
                                  tenancyStatus[3] == 1) &&
                                tenancyStatus.length != 4
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  if (tenancyStatus.length == 4) {
                                    let values = [1]
                                    setTenancyStatus(values)
                                  } else {
                                    let values = [...tenancyStatus]
                                    values[values.length] = 1
                                    setTenancyStatus(values)
                                  }
                                } else {
                                  let values = [...tenancyStatus]
                                  if (values[0] == 1) values.splice(0, 1)
                                  else if (values[1] == 1) values.splice(1, 1)
                                  else if (values[2] == 1) values.splice(2, 1)
                                  else values.splice(3, 1)

                                  setTenancyStatus(values)
                                }
                              }}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Active
                            </p>
                          </div>
                        </div>
                        <div className='col-3 d-flex align-items-center test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                (tenancyStatus[0] == 2 ||
                                  tenancyStatus[1] == 2 ||
                                  tenancyStatus[2] == 2 ||
                                  tenancyStatus[3] == 2) &&
                                tenancyStatus.length != 4
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  if (tenancyStatus.length == 4) {
                                    let values = [2]
                                    setTenancyStatus(values)
                                  } else {
                                    let values = [...tenancyStatus]
                                    values[values.length] = 2
                                    setTenancyStatus(values)
                                  }
                                } else {
                                  let values = [...tenancyStatus]
                                  if (values[0] == 2) values.splice(0, 1)
                                  else if (values[1] == 2) values.splice(1, 1)
                                  else if (values[2] == 2) values.splice(2, 1)
                                  else values.splice(3, 1)

                                  setTenancyStatus(values)
                                }
                              }}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Expiring
                            </p>
                          </div>
                        </div>
                        <div className='col-3 d-flex align-items-center test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                (tenancyStatus[0] == 3 ||
                                  tenancyStatus[1] == 3 ||
                                  tenancyStatus[2] == 3 ||
                                  tenancyStatus[3] == 3) &&
                                tenancyStatus.length != 4
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  if (tenancyStatus.length == 4) {
                                    let values = [3]
                                    setTenancyStatus(values)
                                  } else {
                                    const values = [...tenancyStatus]

                                    values[values.length] = 3
                                    setTenancyStatus(values)
                                  }
                                } else {
                                  const values = [...tenancyStatus]
                                  if (values[0] == 3) values.splice(0, 1)
                                  else if (values[1] == 3) values.splice(1, 1)
                                  else if (values[2] == 3) values.splice(2, 1)
                                  else values.splice(3, 1)

                                  setTenancyStatus(values)
                                }
                              }}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Expired
                            </p>
                          </div>
                        </div>
                      </div>

                      <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>
                    </>
                  )}

                {activeTab != 'terminated' && (
                  <>
                    <div className='d-flex align-items-baseline mt-2 ms-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Renewal Status
                      </label>
                    </div>
                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-3 d-flex align-items-center xyz'>
                        <input
                          className='form-check-input me-3 cursor-pointer'
                          type='radio'
                          checked={
                            renewalStatus.length == 3 || renewalStatus.length == 0 ? true : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked == true) {
                              let values = [0, 2, 7]
                              setRenewalStatus(values)
                            } else {
                              setRenewalStatus([])
                            }
                          }}
                        />{' '}
                        All
                      </div>
                    </div>

                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-4 d-flex align-items-center test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (renewalStatus[0] == 0 ||
                                renewalStatus[1] == 0 ||
                                renewalStatus[2] == 0) &&
                              renewalStatus.length != 3
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (renewalStatus.length == 3) {
                                  let values = [0]
                                  setRenewalStatus(values)
                                } else {
                                  let values = [...renewalStatus]
                                  values[values.length] = 0
                                  setRenewalStatus(values)
                                }
                              } else {
                                let values = [...renewalStatus]
                                if (values[0] == 0) values.splice(0, 1)
                                else if (values[1] == 0) values.splice(1, 1)
                                else values.splice(2, 1)
                                setRenewalStatus(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Not Renewed
                          </p>
                        </div>
                      </div>
                      <div className='col-4 d-flex align-items-center test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (renewalStatus[0] == 2 ||
                                renewalStatus[1] == 2 ||
                                renewalStatus[2] == 2) &&
                              renewalStatus.length != 3
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (renewalStatus.length == 3) {
                                  let values = [2]
                                  setRenewalStatus(values)
                                } else {
                                  let values = [...renewalStatus]
                                  values[values.length] = 2
                                  setRenewalStatus(values)
                                }
                              } else {
                                let values = [...renewalStatus]
                                if (values[0] == 2) values.splice(0, 1)
                                else if (values[1] == 2) values.splice(1, 1)
                                else values.splice(2, 1)
                                setRenewalStatus(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Renewed
                          </p>
                        </div>
                      </div>

                      <div className='col-4 d-flex align-items-center test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (renewalStatus[0] == 7 ||
                                renewalStatus[1] == 7 ||
                                renewalStatus[2] == 7) &&
                              renewalStatus.length != 3
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (renewalStatus.length == 3) {
                                  let values = [7]
                                  setRenewalStatus(values)
                                } else {
                                  let values = [...renewalStatus]
                                  values[values.length] = 7
                                  setRenewalStatus(values)
                                }
                              } else {
                                let values = [...renewalStatus]
                                if (values[0] == 7) values.splice(0, 1)
                                else if (values[1] == 7) values.splice(1, 1)
                                else values.splice(2, 1)
                                setRenewalStatus(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Terminated
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>
                  </>
                )}

                {/* Form Status */}

                <div className='d-flex align-items-baseline mt-2 ms-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{fontWeight: '600'}}
                  >
                    Form Status
                  </label>
                </div>
                <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={formStatus.length == 3 || formStatus.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = ['draft', 'created', 'cancelled']
                          setFormStatus(values)
                        } else {
                          setFormStatus([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (formStatus[0] == 'draft' ||
                            formStatus[1] == 'draft' ||
                            formStatus[2] == 'draft') &&
                          formStatus.length != 3
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (formStatus.length == 3) {
                              let values = ['draft']
                              setFormStatus(values)
                            } else {
                              let values = [...formStatus]
                              values[values.length] = 'draft'
                              setFormStatus(values)
                            }
                          } else {
                            let values = [...formStatus]
                            if (values[0] == 'draft') values.splice(0, 1)
                            else if (values[1] == 'draft') values.splice(1, 1)
                            else values.splice(2, 1)
                            setFormStatus(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                        {' '}
                        Draft
                      </p>
                    </div>
                  </div>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (formStatus[0] == 'created' ||
                            formStatus[1] == 'created' ||
                            formStatus[2] == 'created') &&
                          formStatus.length != 3
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (formStatus.length == 3) {
                              let values = ['created']
                              setFormStatus(values)
                            } else {
                              let values = [...formStatus]
                              values[values.length] = 'created'
                              setFormStatus(values)
                            }
                          } else {
                            let values = [...formStatus]
                            if (values[0] == 'created') values.splice(0, 1)
                            else if (values[1] == 'created') values.splice(1, 1)
                            else values.splice(2, 1)
                            setFormStatus(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                        {' '}
                        Created
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (formStatus[0] == 'cancelled' ||
                            formStatus[1] == 'cancelled' ||
                            formStatus[2] == 'cancelled') &&
                          formStatus.length != 3
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (formStatus.length == 3) {
                              let values = ['cancelled']
                              setFormStatus(values)
                            } else {
                              let values = [...formStatus]
                              values[values.length] = 'cancelled'
                              setFormStatus(values)
                            }
                          } else {
                            let values = [...formStatus]
                            if (values[0] == 'cancelled') values.splice(0, 1)
                            else if (values[1] == 'cancelled') values.splice(1, 1)
                            else values.splice(2, 1)
                            setFormStatus(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                        {' '}
                        Cancelled
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='ms-auto mt-3 mb-3 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideStatus == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideStatus(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideStatus(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Tenant */}
          <div className='min-w-200px card ps-3  pt-1  mt-1' style={{borderRadius: '0px'}}>
            <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
              {' '}
              Tenant
            </label>
            {!hideTenant && (
              <>
                <div className='d-flex align-items-baseline ms-2 '>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{fontWeight: '600'}}
                  >
                    Source
                  </label>
                </div>
                <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={tenantSource.length == 2 || tenantSource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = [0, 1]
                          setTenantSource(values)
                        } else {
                          setTenantSource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (tenantSource[0] == 0 || tenantSource[1] == 0) && tenantSource.length != 2
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (tenantSource.length == 2) {
                              // setTenantSource([])
                              let values = [0]
                              setTenantSource(values)
                            } else {
                              let values = [...tenantSource]
                              values[values.length] = 0
                              setTenantSource(values)
                            }
                          } else {
                            let values = [...tenantSource]
                            if (values[0] == 0) values.splice(0, 1)
                            else values.splice(1, 1)
                            setTenantSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                        {' '}
                        Agent
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (tenantSource[1] == 1 || tenantSource[0] == 1) && tenantSource.length != 2
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (tenantSource.length == 2) {
                              // setTenantSource([])
                              let values = [1]
                              setTenantSource(values)
                            } else {
                              let values = [...tenantSource]
                              values[values.length] = 1
                              setTenantSource(values)
                            }
                          } else {
                            let values = [...tenantSource]
                            if (values[0] == 1) values.splice(0, 1)
                            else values.splice(1, 1)
                            setTenantSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                        {' '}
                        Direct
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='ms-auto mt-3 mb-3 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideTenant == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideTenant(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideTenant(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Property */}
          <div className='min-w-200px ps-3  pt-1  mt-1 card' style={{borderRadius: '0px'}}>
            <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
              {' '}
              Property
            </label>
            {!hideProperty && (
              <>
                <div className='d-flex align-items-baseline ms-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{fontWeight: '600'}}
                  >
                    Type
                  </label>
                </div>
                <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                  <div className='col-12'>
                    {/* <Select
                      styles={customStyles}
                      isSearchable={true}
                      options={unitOptions}
                      onChange={(e: any) => {
                        const values = [...unitType]
                        values[0] = e.value
                        setUnitType(values)
                      }}
                      placeholder={'Search...'}
                    /> */}
                    <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          unitType.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                          <MultiSelect
                            options={unitOptions}
                            value={unitType ? unitType : []}
                            onChange={(e: any) => {
                              const values = [...unitType]
                              e?.map((el:any, ind:any)=>{
                                values[ind] = el.value
                              })
                              setUnitType(e)
                            }}
                            labelledBy='UnitType'
                            valueRenderer={CustomValueRenderer}
                          />
                          </div>
                  </div>
                </div>

                <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{fontWeight: '600'}}
                  >
                    Bedrooms
                  </label>
                </div>
                <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                  <div className='col-12'>
                    {/* <Select
                      styles={customStyles}
                      isSearchable={true}
                      options={bedroomsOptions}
                      onChange={(e: any) => {
                        let values: any = []
                        for (let i = 0; i < e?.length; i++) {
                          values[i] = Number(e[i]?.value)
                        }
                        setBedrooms(values)
                      }}
                      placeholder={'Search..'}
                      isMulti
                    /> */}
                    <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          bedrooms.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={bedroomsOptions}
                          value={bedrooms}
                          onChange={(e: any) => {
                            setBedrooms(e)
                          }}
                          labelledBy='Bedrooms'

                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                  </div>
                </div>
              </>
            )}
            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideProperty == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideProperty(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideProperty(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Financials */}
          <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{borderRadius: '0px'}}>
            <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
              {' '}
              Financials
            </label>

            {!hideFinancials && (
              <>
                {/* Rent Value */}
                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{fontWeight: '600'}}
                  >
                    Rent Value
                  </label>
                </div>
                <div className='d-flex mb-2 ms-2 xyz' style={{paddingLeft: '27px'}}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From (Amount)'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={financial.min == null ? 0 : financial.min}
                      onChange={(e: any) => {
                        const values = {...financial}
                        if (e.target.value.trim()?.length > 0) {
                          values.min = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.min = null
                          setFinancial(values)
                        }
                      }}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='Up to (Amount)'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={financial.max}
                      onChange={(e: any) => {
                        const values = {...financial}
                        if (e.target.value.trim()?.length > 0) {
                          values.max = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.max = null
                          setFinancial(values)
                        }
                      }}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>

                <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{fontWeight: '600'}}
                  >
                    Paid
                  </label>
                </div>
                <div className='row mb-2 ms-2 xyz mt-2' style={{paddingLeft: '27px', width: '95%'}}>
                  <div className='col-12' style={{height: '30px'}}>
                    <input
                      className='progress-filter mt-3'
                      type='range'
                      min='0'
                      max='100'
                      value={paid}
                      onMouseUp={() => {
                        setPaidPercentage(paid)
                      }}
                      onChange={(e: any) => {
                        setPaid(Number(e.target.value))
                      }}
                    />

                    <SquareHandle value={paid} />

                    <div
                      style={{
                        width: `100%`,
                        height: '10px',
                        backgroundColor: '#146c6a',
                        borderRadius: '10px',
                        transform: 'translate(0px, -54px)',
                      }}
                    ></div>
                  </div>
                </div>

                <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{fontWeight: '600'}}
                  >
                    Not Paid
                  </label>
                </div>
                <div className='row mb-2 ms-2 xyz mt-2' style={{paddingLeft: '27px', width: '95%'}}>
                  <div className='col-12' style={{height: '30px'}}>
                    <input
                      className='progress-filter mt-3'
                      type='range'
                      min='0'
                      max='100'
                      value={notPaid}
                      onMouseUp={() => {
                        setNotPaidPercentage(notPaid)
                      }}
                      onChange={(e) => {
                        setNotPaid(Number(e.target.value))
                      }}
                    />

                    <SquareHandleRed value={notPaid} />

                    <div
                      style={{
                        width: `100%`,
                        height: '10px',
                        backgroundColor: '#d54645 ',
                        borderRadius: '10px',
                        transform: 'translate(0px, -54px)',
                      }}
                    ></div>

                    {/* You can display the progress bar visually if needed */}
                  </div>
                </div>
                <div className='d-flex mb-2 ms-2 xyz' style={{paddingLeft: '27px'}}></div>
              </>
            )}

            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideFinancials == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideFinancials(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideFinancials(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectModal && (
        <SelectProperty
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={handleSubmitId}
        />
      )}
    </div>
  )
}

export default Tenancies

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, Bucket, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import Select from 'react-select'
import backArrow from '../../../img/back-arrow.png'
import download from '../../../img/download.png'
import eye from '../../../img/eye-blue.png'
import trash from '../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import camera from '../../../img/camera.png'
import pencil from '../../../img/pencil-blue.png'
import Swal from 'sweetalert2'

function ViewFeedBack() {
  const navigate = useNavigate()

  const {id} = useParams()

  const [feedbackData, setFeedbackData] = useState<any>()
  const [title, setTitle] = useState<any>()
  const [description, setDescription] = useState<any>()
  const [images, setImages] = useState<any>([])

  const options: any = [
    {value: '0', label: 'Pending'},
    {value: '1', label: 'Waqar In-Progress'},
    {value: '2', label: 'Waqar Completed'},
    {value: '3', label: 'Mukund In-Progress'},
    {value: '4', label: 'Mukund Completed'},
    {value: '5', label: 'Ketan In-Progress'},
    {value: '6', label: 'Ketan Completed'},
    {value: '7', label: 'Emad Confirmed'},
    {value: '8', label: 'User Confirmed'},
  ]

  const [status, setStatus] = useState<any>()
  const [propComments, setPropComments] = useState<any>()

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  const [isEdit, setIsEdit] = useState<any>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const onFilesAddedSingle = async (event: any) => {
    let values = [...images]
    let ind: any = images?.length

    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        let fileLists_single: any = []
        const files = event.target.files
        fileLists_single = Array.from(files)

        let formData: any = new FormData()
        let data: any = fileLists_single[i]
        let fileURL: any = URL.createObjectURL(data)
        let filetypes: any = fileLists_single[i].type
        data.fileURL = fileURL
        formData.append('image', data)

        let url = 'upload/originalname/image/feedback'

        await ApiPost(`${url}`, formData)
          .then((res) => {
            values[ind] = res?.data?.data?.image

            // const body = {
            //   id: id,
            //   name: title,
            //   description: description,
            //   images: values,
            // }

            // ApiPut('corporate/feedback', body)
            //   .then((res: any) => {
            //     getFeedbackById(id)
            //   })
            //   .catch((err: any) => ErrorToast(err.message))
          })
          .catch((err) => {
            console.log('res_blob', err)
            ErrorToast(err?.message)
          })
        ind = ind + 1
      }
      setImages(values)

      let y: any = []
      for (let i = 0; i < values?.length; i++) {
        y[i] = {
          src: Bucket + values[i],
        }
      }

      setSlides(y)
      setTotalImages(y?.length)
    }
  }

  //
  const getFeedbackById = async (id: any) => {
    ApiGet(`corporate/feedback/${id}`)
      .then(async (res) => {
        setIsEdit(false)
        setFeedbackData(res?.data?.data?.[0])
        setTitle(res?.data?.data?.[0]?.name)
        setDescription(res?.data?.data?.[0]?.description)
        setStatus(res?.data?.data?.[0]?.status)
        setPropComments(res?.data?.data?.[0]?.propertiseComment)
        setImages(res?.data?.data?.[0]?.images)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.[0]?.images?.length; i++) {
          y[i] = {
            src: Bucket + res?.data?.data?.[0]?.images[i],
          }
        }

        setSlides(y)
        setTotalImages(y?.length)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const deleteFeedBack = () => {
    ApiDelete(`corporate/feedback/${id}`)
      .then(() => {
        SuccessToast('Feedback has been deleted successfully..')
        navigate(-1)
      })
      .catch(() => {})
  }

  //
  const updateFeedBack = () => {
    let current: any = new Date()
    const body = {
      id: id,
      name: title,
      description: description,
      images: images,
      status: parseInt(status),
      propertiseComment: propComments,
      statusDateTime: current,
    }

    ApiPut('corporate/feedback', body)
      .then((res: any) => {
        getFeedbackById(id)
        setIsEdit(false)
        SuccessToast('Feedback has been updated successfully!!!')
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  useEffect(() => {
    getFeedbackById(id)
  }, [])

  return (
    <>
      <div
        id=''
        className='container-xxl pt-0 mt-0 no-print px-4'
        style={{padding: '0px 0px 0px 14px'}}
      >
        <div className='d-flex'>
          <div className='page-title d-flex flex-wrap mb-5 min-w-200px '>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                navigate(-1)
              }}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='18px' width='18px' />
            </span>
            <h1 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0 ms-5 head-text'>
              Feedback
            </h1>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 mt-5'>
          <Row className='align-items-end mt-5 mx-6'>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center  px-3 mb-5'>
              <h1 className='head-text'>
                # <b>{feedbackData?.id}.</b>
              </h1>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center  px-3 mb-5'>
              {isEdit == false ? (
                <div className='d-flex justify-content-end'>
                  <img
                    src={pencil}
                    height={17}
                    width={17}
                    style={{cursor: 'pointer'}}
                    className='me-5'
                    onClick={() => {
                      setIsEdit(true)
                    }}
                  />
                  <img
                    src={trash}
                    height={18}
                    width={18}
                    className=''
                    style={{cursor: 'pointer'}}
                    onClick={(e) => {
                      Swal.fire({
                        text: 'Are you sure you want to permanently delete this feedback?',
                        icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonColor: '#D72852',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        // cancelButtonColor: "transparent",
                        cancelButtonText: 'Cancel',
                      }).then((res: any) => {
                        if (res.isConfirmed) {
                          deleteFeedBack()
                        }
                      })
                    }}
                  />
                </div>
              ) : (
                <div className='d-flex justify-content-end'>
                  <a
                    className='btn btn-sm fw-bold  ms-auto text-white'
                    style={{backgroundColor: '#35475e'}}
                    onClick={() => updateFeedBack()}
                  >
                    Update
                  </a>
                  <button
                    className='btn btn-sm fw-bold  ms-3 text-white'
                    style={{
                      backgroundColor: '#D54645',
                      boxShadow: '0px 0px 20px 0px #6e8aa6',
                    }}
                    onClick={() => {
                      getFeedbackById(id)
                      // setShowEdit(false)
                    }}
                  >
                    {/* <img
                          src={crossbtn}
                          height='20'
                          width='20'
                          style={{
                            marginRight: '21px',
                            marginLeft: '12px',
                          }}
                        />{' '}
                        {'  '} */}
                    <p className='mb-0'>Cancel</p>
                  </button>
                </div>
              )}
            </Col>
          </Row>
          <Row className='mt-5 mx-6'>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center  px-3 mb-5'>
              {isEdit ? (
                <>
                  <label className=' mb-2 label-color required'>Title</label>
                  <input
                    type='text'
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Title...'
                    name='item_id'
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.7rem 1rem',
                    }}
                    value={title}
                    onChange={(event: any) => {
                      setTitle(event?.target.value)
                    }}
                  />
                </>
              ) : (
                <>
                  <h2 className='my-2' style={{color: '#a1a5b7'}}>
                    <label style={{width: '75px'}}>Title</label>
                    <span className='ms-5 ps-1 head-text'>
                      <b> {title}</b>
                    </span>
                  </h2>
                </>
              )}
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center px-3  mb-5 '>
              {isEdit ? (
                <>
                  <div className='ms-5'>
                    <label className='required mb-2 label-color '>Status</label>
                    <Select
                      options={options}
                      defaultValue={
                        status == 0
                          ? options[0]
                          : status == 1
                          ? options[1]
                          : status == 2
                          ? options[2]
                          : status == 3
                          ? options[3]
                          : status == 4
                          ? options[4]
                          : status == 5
                          ? options[5]
                          : status == 6
                          ? options[6]
                          : status == 7
                          ? options[7]
                          : status == 8
                          ? options[8]
                          : '-'
                      }
                      styles={customStyles}
                      isSearchable={false}
                      onChange={(e: any) => {
                        setStatus(e.value)
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 className='my-2' style={{color: '#a1a5b7'}}>
                    <label className='ms-5 mb-4'>Status</label>
                    <span className='ms-5 ps-1 head-text'>
                      <b className='p-5'>
                        {status == 0
                          ? 'Pending'
                          : status == 1
                          ? 'Waqar In Progress'
                          : status == 2
                          ? 'Waqar Completed'
                          : status == 3
                          ? 'Mukund In Progress'
                          : status == 4
                          ? 'Mukund Completed'
                          : status == 5
                          ? 'Ketan In Progress'
                          : status == 6
                          ? 'Ketan Completed'
                          : status == 7
                          ? 'Emad Confirmed'
                          : status == 8
                          ? 'User Confirmed'
                          : '-'}
                      </b>
                    </span>
                  </h2>
                  <h3>
                    <label className='ms-5 ps-1 head-text'>
                      <i>
                        {feedbackData?.corporate?.[0] &&
                          `${feedbackData?.corporate?.[0]?.firstName} ${feedbackData?.corporate?.[0]?.lastName} - `}
                        {feedbackData?.updatedAt
                          ? ` ${moment(feedbackData?.updatedAt).format('DD/MM/YYYY hh:mm A')}`
                          : '-'}
                      </i>
                    </label>
                  </h3>
                </>
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center px-3  mb-5'>
              {isEdit ? (
                <>
                  <label className='required mb-2 label-color'>Description</label>
                  <textarea
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Desciption...'
                    name='item_id'
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.7rem 1rem',
                      minHeight: '100px',
                      maxHeight: '200px',
                    }}
                    value={description}
                    onChange={(event: any) => {
                      setDescription(event?.target.value)
                    }}
                  />
                </>
              ) : (
                <h2 className='my-2' style={{color: '#a1a5b7'}}>
                  <label style={{width: '100px'}} className='mb-4'>
                    Description
                  </label>
                  <span className='ms-5 ps-1 head-text'>
                    <textarea
                      className='form-control form-control-solid-bg'
                      placeholder='Enter Desciption...'
                      name='item_id'
                      style={{
                        border: '0.1rem solid #bec3cb',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                        minHeight: '100px',
                        maxHeight: '300px',
                      }}
                      value={description}
                      onChange={(event: any) => {
                        setDescription(event?.target.value)
                      }}
                      disabled
                    />
                  </span>
                </h2>
              )}
            </Col>
            {isEdit && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className='d-flex mb-4'>
                  <div className=''>
                    <label
                      title='Add New File'
                      htmlFor='uploadPassport'
                      className='btn btn-sm fw-bold '
                      style={{backgroundColor: '', border: '2px solid #35475e', color: '#35475e'}}
                    >
                      <img src={download} height={15} width={15} className='me-3' />
                      Upload File{' '}
                    </label>
                    <input
                      type='file'
                      hidden
                      id='uploadPassport'
                      className='form-control form-control-solid'
                      placeholder='upload'
                      name='passport'
                      onChange={(e: any) => {
                        onFilesAddedSingle(e)
                      }}
                      multiple
                    />
                  </div>
                </div>
              </Col>
            )}
            <Row style={{}} className='mt-3 mb-5'>
              {images?.length > 0 && (
                <>
                  {/* <div className='mw-350px d-flex align-items-start'> */}
                  {images?.map((img: any, ind: any) => {
                    return (
                      <div className='imgs-set d-flex col-md-2 mb-4'>
                        <div className='d-flex mw-350px me-3'>
                          <img src={Bucket + img} width='80' height='80' />
                          {/* <div
                            style={{height: '90px', width: '90px', backgroundColor: '#35475e'}}
                            className='d-flex align-items-center justify-content-center'
                          >
                            <img src={camera} width='40' height='40' className='main_img' />
                          </div> */}
                        </div>
                        <div className='d-grid'>
                          <img
                            style={{
                              cursor: 'pointer',
                            }}
                            src={eye}
                            width='23'
                            height='23'
                            onClick={() => setIndex(ind)}
                          />
                          {isEdit && (
                            <img
                              style={{
                                // marginLeft: '-13px',
                                marginTop: '-25px',
                                cursor: 'pointer',
                              }}
                              src={trash}
                              width='22'
                              height='22'
                              onClick={(e) => {
                                const values = [...images]
                                values.splice(ind, 1)
                                setImages(values)

                                // const body = {
                                //   id: id,
                                //   name: title,
                                //   description: description,
                                //   images: values,
                                // }

                                // ApiPut('corporate/feedback', body)
                                //   .then((res: any) => {
                                //     getFeedbackById(id)
                                //   })
                                //   .catch((err: any) => ErrorToast(err.message))
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                  {/* </div> */}
                </>
              )}
            </Row>
            {/* <Row className="px-0"> */}
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center px-3  mb-5'>
              {isEdit ? (
                <>
                  <label className='mb-2 label-color'>Propertise Comments</label>
                  <textarea
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Comments...'
                    name='item_id'
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.7rem 1rem',
                      minHeight: '100px',
                    }}
                    value={propComments}
                    onChange={(event: any) => {
                      setPropComments(event?.target.value)
                    }}
                  />
                </>
              ) : (
                <h2 className='my-2' style={{color: '#a1a5b7'}}>
                  <label className='mb-4'>Propertise Comments</label>
                  <span className='ms-5 ps-1 head-text'>
                    <textarea
                      className='form-control form-control-solid-bg'
                      name='item_id'
                      style={{
                        border: '0.1rem solid #bec3cb',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                      }}
                      value={propComments}
                      disabled
                    />
                  </span>
                </h2>
              )}
            </Col>

            {/* </Row> */}
          </Row>
        </div>
      </div>

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />
    </>
  )
}

export default ViewFeedBack

import React, {useState, useEffect} from 'react'
import {
  Col,
  Dropdown,
  Form,
  Row,
  Collapse,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import close from '../../../img/close.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import send from '../../../img/sendwhite.svg'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import download from '../../../img/download-white.png'
import chequeImg from '../../../img/cheque.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import recipient from '../../../img/recipient.png'
import './style.scss'

import debitNoteWhite from '../../../img/debit-white..png'
import editpen from '../../../img/pen.png'

import Switch from 'react-switch'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  closeEdit: any
  debitFields: any
  invoiceData: any
  refetch: any
  from:any
}

const DebitNote = ({
  show,
  handleClose,
  paymentId,
  tenantDetails,
  closeEdit,
  debitFields,
  invoiceData,
  refetch,
  from
}: ButtonProps) => {
  const [debitDetails, setDebitDetails] = useState<any>([])
  const [debitNoteCompany, setDebitNoteCompany] = useState<any>()
  const [debitNoteNo, setDebitNoteNo] = useState<any>('')
  const [debitNoteNoError, setDebitNoteNoError] = useState<any>(false)
  const [debitNotes, setDebitNotes] = useState<any>('')
  const [checkedRec, setCheckedRec] = useState<any>(false)

  var vatAmount: any = 0
  var nonVat: any = 0

  useEffect(() => {
    getDetailsForDebitNote()
  }, [])

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getSum = (numb1: any, numb2: any) => {
    return numb1 + numb2
  }

  //
  const getDetailsForDebitNote = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setDebitDetails(res?.data?.data)
        setDebitNoteCompany(res?.data?.data?.debitNoteCompany)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const generateDebitNote = () => {
    // total VAT to Credit
    let totVatDebit: any = 0
    for (let i = 0; i < debitFields?.length; i++) {
      totVatDebit = totVatDebit + getPositive(parseFloat(debitFields[i]?.debitVat))
    }

    // total NonVat to Credit
    let totalSubDebit: any = 0
    for (let i = 0; i < debitFields?.length; i++) {
      totalSubDebit = totalSubDebit + getPositive(parseFloat(debitFields[i]?.debitAmount))
    }

    // subItems
    let subItems: any = []
    let j: any = 0
    for (let i = 0; i < invoiceData?.paymentSubItems?.length; i++) {
      if (getPositive(debitFields[i]?.debitAmount) > 0) {
        subItems[j] = {
          _id: invoiceData?.paymentSubItems[i]?._id,
          id: invoiceData?.paymentSubItems[i]?.id,
          workspaceId: invoiceData?.paymentSubItems[i]?.workspaceId,
          name: invoiceData?.paymentSubItems[i]?.name,
          creditAmount: invoiceData?.paymentSubItems[i]?.creditAmount,
          creditVATAmount: invoiceData?.paymentSubItems[i]?.creditVATAmount,
          debitAmount: parseFloat(getPositive(debitFields[i]?.debitAmount)),
          debitVATAmount: parseFloat(getPositive(debitFields[i]?.debitVat)),
          amount: invoiceData?.paymentSubItems[i]?.amount,
          quantity: invoiceData?.paymentSubItems[i]?.quantity,
          totalAmount: invoiceData?.paymentSubItems[i]?.totalAmount,
          VATPercentage: invoiceData?.paymentSubItems[i]?.VATPercentage,
          amountPaid: invoiceData?.paymentSubItems[i]?.amountPaid,
          outstandingAmount: invoiceData?.paymentSubItems[i]?.outstandingAmount,
          VATAmount: invoiceData?.paymentSubItems[i]?.VATAmount,
          latePaymentFees: invoiceData?.paymentSubItems[i]?.latePaymentFee,
          latePaymentObject: invoiceData?.paymentSubItems[i]?.latePaymentObject,
          latePaymentSelectionStatus: invoiceData?.paymentSubItems[i]?.latePaymentSelectionStatus,
          paymentStatus: invoiceData?.paymentSubItems[i]?.paymentStatus,
          isActive: invoiceData?.paymentSubItems[i]?.isActive,
          isBlock: invoiceData?.paymentSubItems[i]?.isBlock,
          isLatePaymentFeesRecord: invoiceData?.paymentSubItems[i]?.isLatePaymentFeesRecord,
          nextLatePaymentCycle: invoiceData?.paymentSubItems[i]?.nextLatePaymentFeesRecord,
          datePaid: invoiceData?.paymentSubItems[i]?.datePaid,
          oldLatePaymentCycleList: invoiceData?.paymentSubItems[i]?.oldLatePaymentCycleList,
          paymentId: invoiceData?.paymentSubItems[i]?.paymentId,
          paymentSubItemId: invoiceData?.paymentSubItems[i]?.paymentSubItemId,
          latePaymentSubItemId: invoiceData?.paymentSubItems[i]?.latePaymentSubItemId,
          paymentItemListId: invoiceData?.paymentSubItems[i]?.paymentItemListId,
          createdBy: invoiceData?.paymentSubItems[i]?.createdBy,
          createdAt: invoiceData?.paymentSubItems[i]?.createdAt,
          updatedAt: invoiceData?.paymentSubItems[i]?.updatedAt,
          __v: 0,
        }
        j++
      }
    }

    const body = {
      debitNoteNo: debitNoteNo,
      invoiceNo: invoiceData?.invoiceNo,
      paymentId: paymentId,
      paymentInvoiceId: invoiceData?._id,
      note: debitNotes,
      totalDebitAmount: totVatDebit + totalSubDebit,
      totalDebitVAT: totVatDebit,
      subTotalDebitAmount: totalSubDebit,
      paymentSubItems: subItems,
      isManualPDF: false,
      isShareToTenant: checkedRec,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_debit_note?isCloneRecord=true`
    else url = `corporate/payment_debit_note`

    ApiPost(`${url}`, body)
      .then((res) => {
        SuccessToast('Debit Note has been successfully generated!!!')
        handleClose()
        refetch()
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <>
      {/* Debit Note Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto custom-modal'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 debit-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Debit Note</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  handleClose()
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='debit-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={debitNoteWhite} height='80px' width='80px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-4'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row>
            <Col md={8}>
              <div className='d-flex'>
                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 text-white debit-color'
                  style={{
                    width: '120px',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    generateDebitNote()
                  }}
                >
                  Generate
                </button>
              </div>
            </Col>
            <Col md={4} className='py-3 '>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#6560ff'
                  offColor='#b0bec5'
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>
          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important'}}
          >
            <Row className='mt-2'>
              <Col md={5} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>TAX DEBIT NOTE</h1>

                  <img
                    src={editpen}
                    alt=''
                    className='ms-2'
                    style={{cursor: 'pointer', height: '14px', width: '14px', marginTop: '-25px'}}
                    // onClick={() => {
                    // }}
                  />
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  {debitDetails?.debitNoteSequence?.activityStatus == 1 ? (
                    <>
                      {debitDetails?.debitNoteSequence?.prefix != null && (
                        <>
                          <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                            Debit Note No.
                          </label>

                          <input
                            type='text'
                            className={
                              debitNoteNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={debitDetails?.debitNoteSequence?.prefix}
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                        Debit Note No.
                      </label>

                      <input
                        type='text'
                        className={
                          debitNoteNoError
                            ? 'form-control form-control-solid-bg input-style border-danger '
                            : 'form-control form-control-solid-bg input-style mytest'
                        }
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={debitNoteNo}
                        onChange={(event) => {
                          setDebitNoteNo(event.target.value)
                          setDebitNoteNoError(false)
                        }}
                      />
                    </>
                  )}
                </div>
                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Issue Date
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {moment(new Date()).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Invoice No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {invoiceData?.invoiceNo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className='d-flex my-4 justify-content-end'>
                  {debitDetails?.debitNoteLogo && (
                    <img
                      src={`${Bucket}${debitDetails?.debitNoteLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                    />
                  )}
                </div>
                {/* )} */}
              </Col>
            </Row>

            <Row className='mt-5 pb-3'>
              <Col md={6} className='text-start' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>{debitNoteCompany?.name}</p>
                <p style={{marginBottom: '0.1rem'}}>{debitNoteCompany?.address}</p>

                <p style={{marginBottom: '0.1rem'}}>
                  {debitNoteCompany?.countryCode} {debitNoteCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{debitNoteCompany?.email}</p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}} className='ms-auto'>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}} className='ms-auto'>
                  {tenantDetails?.email}
                </p>
              </Col>
            </Row>

            <Row style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={4} className='pe-4 pb-5'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{debitNoteCompany?.trn ? debitNoteCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {debitFields?.map((field: any, index: any) => {
                  if (getPositive(field?.debitAmount) > 0) {
                    return (
                      <tr style={{borderBottom: '1px solid lightgray', height: '45px'}} key={index}>
                        <td className='py-2'>{index + 1}</td>
                        <td className='py-2'>{field.name}</td>
                        <td className='py-2'>{field.qty}</td>
                        <td className='py-2'>
                          {parseFloat(field?.debitAmount)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {field?.debitVat?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {getSum(
                            getPositive(parseFloat(field?.debitAmount)),
                            getPositive(parseFloat(field?.debitVat))
                          )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                        </td>
                      </tr>
                    )
                  }
                })}

                <>
                  {debitFields?.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>

                {debitFields?.map((f: any, ind: any) => {
                  nonVat = nonVat + getPositive(parseFloat(f?.debitAmount))
                  vatAmount = vatAmount + getPositive(parseFloat(f?.debitVat))

                  return <></>
                })}

                <tr style={{height: '55px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4 fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {(nonVat + vatAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={6}>
                {/* Notes */}
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={debitNotes}
                    onChange={(e) => {
                      setDebitNotes(e.target.value)
                    }}
                    maxLength={1000}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DebitNote

import React, {useEffect} from 'react'
import {useState, useRef} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import './style.scss'
import {Tabs, Tab, Paper} from '@mui/material'
import LeasingCommercial from './LeasingCommercial'
import LeasingResidential from './LeasingResedential'
import Tenants from './TenantsTab'
import PropertyPortfolio from './PropertyPortfolio'
import Maintenance from './Maintenance'
import Communications from './Communications'
import editpen from '../../../img/pen.png'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import HelpAndSupport from './HelpAndSupport'
import AccountManagement from './AccountManagement'
import UserGuide from './UserGuide'

const ViewRole = () => {
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>()
  const [tabValue, setTabValue] = useState(0)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const {id} = useParams()

  const [tabs, setTabs] = useState<any>([
    {
      name: 'Help And Support',
      value: 0,
    },
    {
      name: 'Account Management',
      value: 1,
    },
    {
      name: 'User Guide',
      value: 2,
    },
  ])

  const [sortedTabs, setSortedTabs] = useState<any>([])

  const [tabName, setTabName] = useState('Account Management')

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue)
    setTabName(event.target.innerText)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

   //
   const getRoleById = async () => {
    await ApiGet(`corporate/role_permission/${id}`)
      .then((res: any) => {
        let values: any = res?.data?.data

        if (
          values?.featureList?.leasing_residential?.lease_agreements?.view_list ||
          values?.featureList?.leasing_residential?.lease_agreements?.view_details ||
          values?.featureList?.leasing_residential?.lease_agreements?.create ||
          values?.featureList?.leasing_residential?.lease_agreements?.update ||
          values?.featureList?.leasing_residential?.lease_agreements?.delete ||
          values?.featureList?.leasing_residential?.lease_agreements?.approve
        )
          values.featureList.leasing_residential.lease_agreements.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.renewals?.view_list ||
          values?.featureList?.leasing_residential?.renewals?.view_details ||
          values?.featureList?.leasing_residential?.renewals?.create ||
          values?.featureList?.leasing_residential?.renewals?.update ||
          values?.featureList?.leasing_residential?.renewals?.delete ||
          values?.featureList?.leasing_residential?.renewals?.approve
        )
          values.featureList.leasing_residential.renewals.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.terminations?.view_list ||
          values?.featureList?.leasing_residential?.terminations?.view_details ||
          values?.featureList?.leasing_residential?.terminations?.create ||
          values?.featureList?.leasing_residential?.terminations?.update ||
          values?.featureList?.leasing_residential?.terminations?.delete ||
          values?.featureList?.leasing_residential?.terminations?.approve
        )
          values.featureList.leasing_residential.terminations.isRemoved = undefined

        if (values?.featureList?.leasing_residential?.cancellation?.allow)
          values.featureList.leasing_residential.cancellation.isRemoved = undefined

        if (values?.featureList?.leasing_residential?.lease_deletion?.allow)
          values.featureList.leasing_residential.lease_deletion.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.lease_financial?.view_list ||
          values?.featureList?.leasing_residential?.lease_financial?.view_details ||
          values?.featureList?.leasing_residential?.lease_financial?.create ||
          values?.featureList?.leasing_residential?.lease_financial?.update ||
          values?.featureList?.leasing_residential?.lease_financial?.delete ||
          values?.featureList?.leasing_residential?.lease_financial?.approve
        )
          values.featureList.leasing_residential.lease_financial.isRemoved = undefined

        setRoleData(values)
       
        let temp = [...tabs]
        if (res?.data?.data?.featureList?.leasing_residential != undefined) {
          let newTab = {
            name: 'Leasing (Residential)',
            value: temp?.length,
          }
          temp[temp?.length] = newTab
        }
        setTabs(temp)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getRoleById()
  }, [])

  useEffect(() => {
    let values = tabs.slice().sort((a: any, b: any) => a.name.localeCompare(b.name))
    setSortedTabs(values)

    setTabValue(values[0]?.name)
    setTabName(values[0]?.name)
  }, [tabs])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/settings/roles-and-permissions')}
            >
              <img src={backArrow} height={14} width={14} />
            </span>
            <h2 className='page-heading m-0 head-text'>{roleData?.name} </h2>
          </div>
          <div className='d-flex ms-auto'>
            <a
              className='text-decoration-underline'
              style={{color: '#000'}}
              onClick={() => navigate(`/settings/view-role/${roleData?._id}/users`)}
            >
              <h3 className='page-heading m-0 head-text'>
                <b>Total Users {roleData?.userIds?.length}</b>
              </h3>
            </a>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <img
            src={editpen}
            alt=''
            className='ms-auto cursor-pointer'
            height={20}
            width={20}
            onClick={() => {
              navigate(`/settings/edit-role/${id}`)
            }}
          />
        </div>

        <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-5 px-0 card-onboard-shad mx-0'>
          <div className='card-body pt-0 mt-0 py-3 px-0 pb-0'>
            <Row>
              <Col md={2} className='test'>
                <Paper style={{display: 'flex', height: '100%'}}>
                  <Tabs
                    orientation='vertical'
                    // variant='scrollable'
                    value={tabValue}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        display: 'none', // This will hide the indicator line
                      },
                    }}
                    sx={{
                      borderRight: `1px solid divider`,
                      paddingTop: '15px',
                      paddingBottom: '20px',

                      '& .Mui-selected': {
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        border: 'none',
                        color: '#000',
                      },
                    }}
                  >
                    {sortedTabs.map((tab: any, index: any) => {
                      return (
                        <Tab
                          label={tab?.name}
                          value={tab?.name}
                          sx={{
                            fontWeight: tabValue === index ? 'bold' : 'normal',
                            textDecoration: tabValue === index ? 'underline' : 'none',
                            border: 'none',
                            color: '#000',
                          }}
                        />
                      )
                    })}
                    {/* <Tab
                      label='Leasing (Residential)'
                      sx={{
                        fontWeight: tabValue === 0 ? 'bold' : 'normal',
                        textDecoration: tabValue === 0 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Leasing (Commercial)'
                      sx={{
                        fontWeight: tabValue === 1 ? 'bold' : 'normal',
                        textDecoration: tabValue === 1 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Tenants'
                      sx={{
                        fontWeight: tabValue === 2 ? 'bold' : 'normal',
                        textDecoration: tabValue === 2 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Property Portfolio'
                      sx={{
                        fontWeight: tabValue === 3 ? 'bold' : 'normal',
                        textDecoration: tabValue === 3 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Maintenance'
                      sx={{
                        fontWeight: tabValue === 4 ? 'bold' : 'normal',
                        textDecoration: tabValue === 4 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Communications'
                      sx={{
                        fontWeight: tabValue === 5 ? 'bold' : 'normal',
                        textDecoration: tabValue === 5 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Help & Support'
                      sx={{
                        fontWeight: tabValue === 6 ? 'bold' : 'normal',
                        textDecoration: tabValue === 6 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Account Management'
                      sx={{
                        fontWeight: tabValue === 7 ? 'bold' : 'normal',
                        textDecoration: tabValue === 7 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='User Guide'
                      sx={{
                        fontWeight: tabValue === 8 ? 'bold' : 'normal',
                        textDecoration: tabValue === 8 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Data Export'
                      sx={{
                        fontWeight: tabValue === 9 ? 'bold' : 'normal',
                        textDecoration: tabValue === 9 ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    /> */}
                  </Tabs>
                  {/* Content for each tab */}
                </Paper>
              </Col>
              <Col md={10} className='px-0'>
                <div
                  style={{flex: 1, padding: '10px 0px'}}
                  //   className='feature-tab-details'
                  ref={contentRef}
                >
                  {(tabName === 'Leasing (Residential)' && roleData) && (
                    <LeasingResidential
                      roleData={roleData}
                      from={'view'}
                      updateRoleDate={(val: any) => {}}
                    />
                  )}
                  {(tabName === 'Leasing (Commercial)' && roleData) && <LeasingCommercial />}
                  {tabName === 'Tenants' && <Tenants />}
                  {tabName === 'PropertyPortfolio' && <PropertyPortfolio />}
                  {tabName === 'Maintenance' && <Maintenance />}
                  {tabName === 'Communications' && <Communications />}
                  {tabName === 'Help And Support' && roleData && (
                    <HelpAndSupport
                      roleData={roleData}
                      from={'view'}
                      updateRoleDate={(val: any) => {}}
                    />
                  )}
                  {(tabName === 'Account Management' && roleData) && (
                    <AccountManagement
                      roleData={roleData}
                      from={'view'}
                      updateRoleDate={(val: any) => {}}
                    />
                  )}
                  {tabName === 'User Guide' && (
                    <UserGuide
                      roleData={roleData}
                      from={'view'}
                      updateRoleDate={(val: any) => {}}
                    />
                  )}
                  {tabName === 'Data Export' && <div>{'Data Export'}</div>}

                  {/* {tabValue === 0 && roleData && (
                    <LeasingResidential
                      roleData={roleData}
                      from={'view'}
                      updateRoleDate={(val: any) => {}}
                    />
                  )}
                  {tabValue === 1 && <LeasingCommercial />}
                  {tabValue === 2 && <Tenants />}
                  {tabValue === 3 && <PropertyPortfolio />}
                  {tabValue === 4 && <Maintenance />}
                  {tabValue === 5 && <Communications />}
                  {tabValue === 6 && <div>{'Help and Support'}</div>}
                  {tabValue === 7 && <div>{'Account Management'}</div>}
                  {tabValue === 8 && <div>{'User Guide'}</div>}
                  {tabValue === 9 && <div>{'Data Export'}</div>} */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewRole

import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import addWhite from '../../../img/add-white.png'
import './style.scss'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import noData from '../../../img/NoData1.svg'

const RolesAndPermissions = () => {
  const navigate = useNavigate()

  const [roleData, setRoleData] = useState<any>([])
  const [usersCount, setUsersCount] = useState<any>(0)

  const getAllRoles = () => {
    ApiGet('corporate/role_permission')
      .then((res: any) => {
        setRoleData(res?.data?.data)
      })
      .catch(() => {})
  }

  const getUsersCount = () => {
    ApiGet('corporate/user/count')
      .then((res: any) => {
        setUsersCount(res?.data?.data)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getAllRoles()
    getUsersCount()
  }, [])
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/settings')}
            >
              <img src={backArrow}  height='14px' width='14px'  />
            </span>
            <h2 className='page-heading m-0 head-text'>Roles and Permissions</h2>
          </div>

          <div className='ms-auto me-1'>
            <h3 className='page-heading m-0 head-text'>
              Total Users <b>{usersCount}</b>
            </h3>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10'
              style={{width: 'fit-content'}}
              onClick={() => navigate('/settings/add-role')}
            >
              <img src={addWhite} height={17} width={17} style={{marginRight: '10px'}} /> Add Role
            </button>
          </div>
        </div>
        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '70vh'}}>
          <div className='card-body pt-0 mt-5 py-3 px-3'>
            <Row className='mt-2'>
              {roleData?.length > 0 ? (
                <div className='d-flex flex-wrap'>
                  {roleData?.map((role: any, ind: any) => {
                    return (
                      <>
                        <Col md={4} lg={4} className='pe-4 mb-15 d-flex flex-column'>
                          <div className='card mx-5 py-5 px-6 card-onboard-shad flex-fill'>
                            <h3 className='page-heading m-0 head-text mb-3'>
                              <b>{role?.name}</b>
                            </h3>
                            <p className='head-text mb-5 role-text'>{role?.description}</p>
                            <div className='d-flex justify-content-center mb-5 mt-auto'>
                              <h2 className='page-heading m-0 head-text'>
                                <b>Users: {role?.userCount}</b>
                              </h2>
                            </div>
                            <div className='d-flex justify-content-center mb-5'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  me-3  role-btn'
                                onClick={() => {
                                  navigate(`/settings/view-role/${role?._id}`)
                                }}
                              >
                                View Role
                              </button>
                            </div>
                          </div>
                        </Col>
                      </>
                    )
                  })}
                </div>
              ) : (
                <>
                  <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350}  />
                    </Col>
                  </Row>
                </>
              )}

              {/* <Col md={4} lg={4} className='pe-4 mb-15'>
                <div className='card mx-5 py-5 px-6 card-onboard-shad'>
                  <h3 className='page-heading m-0 head-text mb-3'>
                    <b>Administrator</b>
                  </h3>
                  <p className='head-text mb-5 role-text'>
                    Allows full access to the system… For users who are account administrators,
                    installing, configuring, and maintaining all software applications and
                    infrastructure in a SaaS environment.
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <h2 className='page-heading m-0 head-text'>
                      <b>Users: 4</b>
                    </h2>
                  </div>
                  <div className='d-flex justify-content-center mb-5'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  me-3  role-btn'
                      onClick={() => {}}
                    >
                     
                      View Role
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={4} className='pe-4 mb-15'>
                <div className='card mx-5 py-5 px-6 card-onboard-shad'>
                  <h3 className='page-heading m-0 head-text mb-3'>
                    <b>Administrator</b>
                  </h3>
                  <p className='head-text mb-5 role-text'>
                    Allows full access to the system… For users who are account administrators,
                    installing, configuring, and maintaining all software applications and
                    infrastructure in a SaaS environment.
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <h2 className='page-heading m-0 head-text'>
                      <b>Users: 4</b>
                    </h2>
                  </div>
                  <div className='d-flex justify-content-center mb-5'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  me-3  role-btn'
                      onClick={() => {}}
                    >
                      
                      View Role
                    </button>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default RolesAndPermissions

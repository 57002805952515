import React, {useEffect, useState} from 'react'
import TenantArrow from '../../../../img/tenant-arrow.png'
import addWhiteIcon from '../../../../img/add-white.png'
import emailIcon from '../../../../img/email.png'
import trashImg from '../../../../img/trash.png'
import noData from '../../../../img/NoData1.svg'
import tenantUserHollowIconWhite from '../../../../img/tenant-user-hollow-icon-white.png'
import tenantUserFilledIconBlue from '../../../../img/tenant-user-filled-blue.png'
import './styles.scss'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import InviteModel from './components/InviteModel'
import AddTenantModel from './components/AddTenantModel'
import AddInviteModel from './components/AddInviteModel'
import { setComefrom } from '../../../../redux/counterSlice'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RequestProfileUpdateModel from './components/RequestProfileUpdateModel'

const Tenant = () => {
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getTenantFilters: any = localStorage.getItem('tenantFilters')
  const tenantFilters = JSON.parse(getTenantFilters)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  let totalSelected: any = 0
  const [tenantName, setTenantName] = useState<any>(tenantFilters?.searchTenantName ? tenantFilters?.searchTenantName : '')
  const [email, setEmail] = useState<any>(tenantFilters?.searchEmail ? tenantFilters?.searchEmail : '')
  const [mobileNo, setMobileNo] = useState<any>(tenantFilters?.searchMobileNo ? tenantFilters?.searchMobileNo : '')
  const [inviteModal, setInviteModal] = useState<any>(false)
  const [addTenantModal, setAddTenantModal] = useState<any>(false)
  const [addInviteModal, setAddInviteModal] = useState<any>(false)
  const [requestProfileModal, setRequestProfileModal] = useState<any>(false)

  const [tenantTableData, setTenantTableData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [checkedValues, setCheckedValues] = useState<any>([])
  const [tenantCountSummary, setTenantCountSummary] = useState<any>()

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getTenants(event.selected + 1)
    // setTenantPage(event.selected + 1)
  }



  const getTenantCountSummary = async () => {
    let body: any = {
      searchTenantName: tenantName,
      searchEmail: email,
      searchMobileNo: mobileNo,
    }
    await ApiPost('corporate/tenant/count', body)
      .then((res) => {
        setTenantCountSummary(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getTenants = async (pg = 1) => {
    let body: any = {
      page: pg,
      limit: limit,
      searchTenantName: tenantName,
      searchEmail: email,
      searchMobileNo: mobileNo,
    }
    await ApiPost('corporate/tenant/overview', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_service_request_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setTenantTableData(res?.data?.data?.tenant_data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const deleteTenants = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = tenantTableData[ind]?._id
        i++
      }
    })

    const body = {
      tenantIds: ids,
    }

    let url: any = `corporate/tenant/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        getTenantCountSummary()
        getTenants()
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getTenantCountSummary()
    setPage(1)
    getTenants(1)
 
  }, [tenantName, email, mobileNo])

  // useEffect(() => {
  //   getTenantCountSummary()
  //   if(tenantName || email || mobileNo){
  //     setPage(1)
  //     getTenants(1)
  //   }else{
  //     const getTenantPage: any = localStorage.getItem('tenantPage')
  //     const tenantPage = JSON.parse(getTenantPage ?? 1)

  //     setPage(tenantPage)
  //     getTenants(tenantPage)
  //   }
  // }, [tenantName, email, mobileNo])


  useEffect(() => {
    const filters = {
      searchTenantName: tenantName,
      searchEmail: email,
      searchMobileNo: mobileNo,
    }
    localStorage.setItem('tenantFilters', JSON.stringify(filters))
  }, [tenantName, email, mobileNo])

  const setTenantPage = (page:number) => {
    localStorage.setItem('tenantPage', JSON.stringify(page))
  }

  const goToTenant = (tnt:any) => {
    dispatch(setComefrom('tenants'))
    navigate(`/tenant-profile/${tnt?._id}`)
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Tenants</b>
            </h1>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
              <div className='customCard py-5 px-7 shadow' style={{width: '11rem', height: '100px'}}>
                <h4 className='mb-4'>Total Tenants</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={TenantArrow} height={35} width={35} className='me-7' />
                  <h4 className='fs-2'>{tenantCountSummary?.totalTenant}</h4>
                </div>
              </div>
              <div className='customCard py-5 px-7 shadow' style={{width: '11rem', height: '100px'}}>
                <h4 className=' mb-4'>Active</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={TenantArrow} height={35} width={35} className='me-7' />
                  <h4 className='fs-2'>{tenantCountSummary?.totalActiveTenant}</h4>
                </div>
              </div>
              <div className='customCard py-5 px-7 shadow' style={{width: '11rem', height: '100px'}}>
                <h4 className='mb-4'>Inactive</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={TenantArrow} height={35} width={35} className='me-7' />
                  <h4 className='fs-2'>{tenantCountSummary?.totalInactiveTenant}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5'>
            <div className='d-flex justify-content-end align-items-end'>
              {checkedValues?.length > 0 && (
                <>
                  {checkedValues?.map((ch: any, ind: any) => {
                    if (ch == true) totalSelected = totalSelected + 1
                    return <></>
                  })}
                </>
              )}

              {totalSelected > 0 && (
                <>
                  <div className='d-flex ms-auto'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry light-dark-theme-color'>
                        {' '}
                        <i>Total Selected </i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold red-hollow-btn background-white m-0 ps-2 me-7'
                    onClick={() => {
                      swal
                        .fire({
                          text: 'Are you sure you want to delete the selected tenants?',
                          icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#D72852',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          // cancelButtonColor: "transparent",
                          cancelButtonText: 'Cancel',
                        })
                        .then((res) => {
                          if (res.isConfirmed) {
                            deleteTenants()
                          }
                        })
                    }}
                    // disabled={isLoading}
                  >
                    <img src={trashImg} height={18} width={18} className='me-5' /> Delete
                  </button>

                  <button type='button' className='btn btn-sm fw-bold px-2 blue-btn status-w-170 ' onClick={() => {
                    let emails : any = []
                    let i: any = 0
                    checkedValues?.map((ch: any, ind: any) => {
                      if (ch == true) {
                        emails[i] = tenantTableData[ind]?.email
                        i++
                      }
                    })

                    localStorage.setItem('requestProfileEmails', JSON.stringify(emails))
                    setRequestProfileModal(true)

                  }}>
                    <img src={tenantUserHollowIconWhite} height={18} width={18} className='me-2' /> Request Profile Update
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='col-12 d-flex justify-content-between align-items-center py-5 gy-3 mx-0' style={{gap: '0px'}}>
            {/* search fields */}
            <div className={`d-flex align-items-center  px-0 col-8`}>
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Tenant Name'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'tenantName'}}
                    value={tenantName}
                    onChange={(e: any) => setTenantName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='tenantName'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Email'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'email'}}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='email'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Mobile No.'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'mobileNo'}}
                    value={mobileNo}
                    onChange={(e: any) => {
                      const value = e.target.value
                      const numericValue = value.replace(/\D/g, '')
                      setMobileNo(numericValue)
                    }}
                  />
                  <IconButton style={searchIconStyle} aria-label='mobileNo'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
            {/* add and invite buttons */}
            <div className={`px-0 col-4`}>
              <div className='d-flex align-items-center justify-content-end' style={{columnGap: '20px'}}>
                <button type='button' className='btn btn-sm fw-bold px-2 green-submit-btn' onClick={() => {
                     setAddTenantModal(true)
                }}>
                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Tenant
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn'
                  onClick={() => {
                    setInviteModal(true)
                  }}
                >
                  <img src={emailIcon} height={18} width={18} className='me-2' /> Invite Tenant
                </button>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <div className='card card-flush py-5 px-7 mt-5'>
              <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-dashed fs-8 gy-5 ' id='kt_ecommerce_sales_table'>
                    <thead className='fs-7'>
                      <th className='text-start min-w-25px test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm  py-0 ps-0 pe-5'>
                          <input
                            className='form-check-input cursor-pointer'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={totalSelected == tenantTableData?.length && tenantTableData?.length != 0 ? true : false}
                            onChange={(e: any) => {
                              let values = [...checkedValues]
                              if (e.target.checked) {
                                for (let i = 0; i < tenantTableData?.length; i++) {
                                  values[i] = true
                                }
                              } else {
                                for (let i = 0; i < tenantTableData?.length; i++) {
                                  values[i] = false
                                }
                              }
                              setCheckedValues(values)
                            }}
                          />
                        </div>
                      </th>
                      <th className='text-center min-w-50px ps-0'></th>
                      <th className='text-start min-w-75px ps-5'>ID</th>
                      <th className='text-start min-w-100px ps-0'>First Name</th>
                      <th className='text-start min-w-100px ps-0'>Last Name</th>
                      <th className='text-center min-w-125px ps-0'>Connect Status</th>
                      <th className='text-center min-w-125px ps-5'>Tenant Status</th>
                      <th className='text-start min-w-150px ps-5'>Email</th>
                      <th className='text-start min-w-100px ps-0'>Mobile No</th>
                      <th className='text-start min-w-150px ps-5'>Added</th>
                      <th className='text-start min-w-150px ps-5'>Last Updated</th>
                    </thead>
                    <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                      {tenantTableData?.length > 0 ? (
                        tenantTableData?.map((teanantItem: any, i: any) => {
                          return (
                            <tr>
                              <td className='text-start ps-0 test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                  <input
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={checkedValues[i] == true ? true : false}
                                    onChange={(e: any) => {
                                      const values = [...checkedValues]
                                      values[i] = !values[i]
                                      setCheckedValues(values)
                                    }}
                                  />
                                </div>
                              </td>
                              <td className={`text-center cursor-pointer ps-0 m-0 p-0 `} onClick={() =>goToTenant(teanantItem)}>
                                <img src={tenantUserFilledIconBlue} height={30} width={30} className='m-0 p-0' />
                              </td>
                              <td
                                data-kt-ecommerce-order-filter='order_id'
                                className='text-start cursor-pointer ps-5'
                                onClick={() =>goToTenant(teanantItem)}
                              >
                                {teanantItem?.id != undefined ? teanantItem?.id : '-'}
                              </td>
                              <td className='text-start cursor-pointer ps-0' onClick={() =>goToTenant(teanantItem)}>
                              {teanantItem?.firstName?.length > 25 ? `${teanantItem?.firstName?.substring(0,25)}...`: (teanantItem?.firstName || '-')}
                              </td>
                              <td className='text-start cursor-pointer ps-0' onClick={() =>goToTenant(teanantItem)}>
                              {teanantItem?.lastName?.length > 25 ? `${teanantItem?.lastName?.substring(0,25)}...`: (teanantItem?.lastName || '-')}
                              </td>

                              <td className='text-center cursor-pointer min-w-125px ps-5' onClick={() =>goToTenant(teanantItem)}>
                                <div
                                  className={
                                    teanantItem?.invitationStatus === 0
                                      ? 'status requested-st'
                                      : teanantItem?.invitationStatus === 1
                                      ? 'status draft-st'
                                      : teanantItem?.invitationStatus === 2
                                      ? 'status renewed-st'
                                      : teanantItem?.invitationStatus === 3
                                      ? 'status cancelled-st'
                                      : ''
                                  }
                                >
                                  {teanantItem?.invitationStatus === 0
                                    ? 'Not Invited'
                                    : teanantItem?.invitationStatus === 1
                                    ? 'Invited'
                                    : teanantItem?.invitationStatus === 2
                                    ? 'Connected'
                                    : teanantItem?.invitationStatus === 3
                                    ? 'Rejected'
                                    : ''}
                                </div>
                              </td>
                              <td className='text-center cursor-pointer min-w-125px ps-5' onClick={() =>goToTenant(teanantItem)}>
                                <div
                                  className={
                                    teanantItem?.accountStatus === 0 ? 'status req-bar' : teanantItem?.accountStatus === 1 ? 'status active-bar' : ''
                                  }
                                >
                                  {teanantItem?.accountStatus === 0 ? 'Inactive' : teanantItem?.accountStatus === 1 ? 'Active' : ''}
                                </div>
                              </td>
                              <td className='text-start cursor-pointer ps-5' onClick={() =>goToTenant(teanantItem)}>
                                  {teanantItem?.email?.length > 30 ? `${teanantItem?.email?.substring(0,30)}...`: (teanantItem?.email || '-')}
                              </td>
                              <td className='text-start cursor-pointer ps-0' onClick={() =>goToTenant(teanantItem)}>
                                {teanantItem?.countryCode && teanantItem?.phoneNumber ? `+${teanantItem?.countryCode?.toString()}${teanantItem?.phoneNumber}` :'-'}
                              </td>

                              <td className='text-start cursor-pointer ps-5' onClick={() =>goToTenant(teanantItem)} data-order='2022-09-11'>
                                <p className='my-0'>
                                  {teanantItem?.createdByUser != undefined &&
                                    `${teanantItem?.createdByUser?.firstName} ${teanantItem?.createdByUser?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {teanantItem?.createdAt ? `${moment?.utc(teanantItem?.createdAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}` : '-'}
                                </p>
                              </td>
                              <td className='text-start cursor-pointer ps-5' onClick={() =>goToTenant(teanantItem)} data-order='2022-09-11'>
                                <p className='my-0'>
                                  {teanantItem?.updatedByUser != undefined &&
                                    `${teanantItem?.updatedByUser?.firstName} ${teanantItem?.updatedByUser?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {teanantItem?.updatedAt ? `${moment?.utc(teanantItem?.updatedAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}` : '-'}
                                </p>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <img src={noData} alt='' width={350} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination start*/}
                <div className='row mb-5'>
                  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                  <div className='row mt-5'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                      <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          pageClassName='paginate_button page-item +'
                          pageLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                          previousClassName='paginate_button page-item previous'
                          nextClassName='paginate_button page-item next'
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          forcePage={page - 1}
                          previousLabel='<'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* pagination end*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {inviteModal && (
        <InviteModel
          show={inviteModal}
          handleClose={() => {
            setInviteModal(false)
            getTenantCountSummary()
            getTenants()
          }}
        />
      )}

      {addTenantModal && (
        <AddTenantModel
          show={addTenantModal}
          handleClose={(key:any) => {
            if(key === 0){
              setAddTenantModal(false)
              getTenantCountSummary()
              getTenants()
            }
            if(key === 1){
              setAddTenantModal(false)
              setAddInviteModal(true)
            }
            
          }}
        />
      )}

{addInviteModal && (
        <AddInviteModel
          show={addInviteModal}
          isEdit={false}
          handleClose={() => {
            setAddInviteModal(false)
            getTenantCountSummary()
            getTenants()
          }}
        />
      )}
      {requestProfileModal && (
        <RequestProfileUpdateModel
          show={requestProfileModal}
          handleClose={() => {
            setRequestProfileModal(false)
            getTenantCountSummary()
            getTenants()
          }}
        />
      )}
    </>
  )
}

export default Tenant

import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiUpload,
  ApiPut,
  ApiDelete,
  ApiGetNoAuth,
} from '../../../apiCommon/helpers/API/ApiData'
import img from '../../../img/300-1.jpg'
import folderDocument from '../../../img/folder-document.svg'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import NewMessage from './NewMessage'
import io from 'socket.io-client'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import backArrow from '../../../img/back-arrow.png'
import {Modal, Button} from 'react-bootstrap'
import closered from '../../../img/closered.svg'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import {AiFillDelete} from 'react-icons/ai'
import {truncate} from 'fs'
import lock from '../../../img/padlock.png'
import ReactPaginate from 'react-paginate'
import noData from '../../../img/NoData1.svg'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import {setSelectedUnit} from '../../../redux/counterSlice'
import openImg from '../../../img/payment-col-open.png'
import close from '../../../img/payment-col-close.png'
import EditPaymentModal from '../Financials/EditPaymentModal'
import pencil from '../../../img/pencil-blue.png'
import eye from '../../../img/eye-blue.png'
import trash from '../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../img/check-white.png'
import download from '../../../img/download-white.png'
import bin from '../../../img/bin.png'
import {useDispatch, useSelector} from 'react-redux'
import {setComefrom, setUnitIDfromProperties} from '../../../redux/counterSlice'
import plusBlue from '../../../img/add-blue.png'
import trashImg from '../../../img/trash.png'
import correct from '../../../img/correct.png'
import upload from '../../../img/upload-blue.png'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import redCross from '../../../img/remove.png'
import Select from 'react-select'
import editpen from '../../../img/pen.png'
import deleteImg from '../../../img/delete.png'
import downloadImg from '../../../img/download-img.png'
import axios from 'axios'
import addWhite from '../../../img/add-white.png'
import IncomingPaymentModal from './IncomingPaymentModal'

// const SERVER_URL: any = process.env.REACT_APP_SERVER_URL
// const socket = io(SERVER_URL);
const ViewTenant = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [idTenancy, setIdTenancy] = useState<any>(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])
  const [paymentData, setPaymentData] = React.useState<any>({})
  const [propertiType, setPropertiType] = React.useState('')
  const [messageData, setMessageData] = React.useState<any>([])
  const [messagechat, setmessagechat] = React.useState<any>([])
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
  const [messageList, setMessageList] = React.useState<any>(false)
  const [tenantId, setTenantId] = React.useState<any>('')
  const [sendMessage, setSendMessage] = React.useState<any>('')
  const [roomId, setRoomId] = React.useState<any>()
  const [open, setOpen] = React.useState(false)

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
    {value: '2', label: 'Other'},
  ])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setTenantData(res?.data?.data)
        console.log('\nres?.data?.data', res?.data?.data)
        setValue('email', res?.data?.data?.email)
        setValue(
          'phoneNumber',
          `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`
        )
        setProfileImg(res?.data?.data?.profileImage)
        //  setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getPaymentData = async () => {
    await ApiGet(`corporate/tenant/payment_and_rent/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setPaymentData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getmessageDataById = async (id: any) => {
    const body = {
      limit: 10,
      page: 1,
      chatRoomId: id,
    }
    await ApiPost(`corporate/chat_message/get`, body)
      .then((res) => {
        setmessagechat(res?.data?.data?.tenancy_payment_data)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message) {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getMessageList = async () => {
    let values: any = []
    values[0] = window.location.pathname.split('/')[2]

    const body = {
      limit: 10,
      page: 1,
      tenantIds: values,
    }

    await ApiPost(`corporate/chat_room/get`, body)
      .then((res) => {
        setMessageData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getTenantData()
    // getPaymentData()
    getMessageList()
  }, [window.location.pathname.split('/')[2]])

  const [isConnected, setIsConnected] = React.useState<any>(/* socket.connected */)
  const [lastPong, setLastPong] = React.useState<any>(null)

  // React.useEffect(() => {
  //   if(window.location.pathname.split('/')[1] === 'tenant'){
  //     socket.on('connect', () => {
  //       setIsConnected(true);
  //     });

  //     socket.on('disconnect', () => {
  //       setIsConnected(false);
  //     });

  //     socket.on('pong', () => {
  //       setLastPong(new Date().toISOString());
  //     });

  //     socket.on('online', (data) => {
  //       console.log("DAta:", data)
  //     })

  //     return () => {
  //       socket.off('connect');
  //       socket.off('disconnect');
  //       socket.off('pong');
  //     };
  //   }

  // },[window.location.pathname.split('/')[1]])

  // React.useEffect(() => {
  //   socket.emit('online', {
  //     "userId": "634697421bd4e8bf314c3a74"
  //   })
  // }, [])

  // const handleViewMessage = (id: any, tenantId: any) => {
  //   setMessageList(!messageList)
  //   setRoomId(id)
  //   setTenantId(tenantId)
  //   socket.emit(`join_room`, {
  //     roomId: id,
  //     userId: "634697421bd4e8bf314c3a74",
  //   });
  //   getmessageDataById(id)
  // }

  // const handleSendMessage = () => {
  //   if (sendMessage !== '') {
  //     socket.emit('send_message', {
  //       roomId: roomId,
  //       message: sendMessage,
  //       senderId: "634697421bd4e8bf314c3a74",
  //       receiverId: tenantId,
  //       isCooperate: true
  //     });
  //   }
  //   setSendMessage('');
  // }

  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  const getTenantFolders = () => {
    ApiGet(`corporate/tenant_folder?tenantId=${window.location.pathname.split('/')[2]}`)
      .then((res: any) => {
        setFolders(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setSelectedFolder(folder)
        setViewFolder(true)
        setFolderFiles(res?.data?.data?.fileList)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }
        console.log(y)
        console.log(res?.data?.data?.fileList?.length)
        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
        setEditFolderName(-1)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  //
  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  //
  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name == folderFiles[i]?.fileName) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              setOpen(true)

              // for (let i = 0; i < fileLists_single.length; i++) {
              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  // let temp = [...folderFiles]
                  temp[temp?.length] = {
                    fileName: event?.target?.files[m]?.name,
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                })
              // }
            } else {
              const userChoice = await confirmAction()
              console.log(userChoice)
              if (userChoice == 'keep') {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then(async (res) => {
                    // let temp = [...folderFiles]

                    var name = Date.now()
                    temp[temp?.length] = {
                      fileName: `${getFilename(filename, false)}-${name}.${getFilename(
                        filename,
                        true
                      )}`,
                      fileURL: res?.data?.data?.image,
                      fileSize: replaceFile.size,
                      createdAt: moment(new Date()).format('YYYY-MM-DD'),
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    await ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFiles(selectedFolder) /********************** */
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('keep')
              else {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then((res) => {
                    // let temp = [...folderFiles]

                    for (let j = 0; j < folderFiles?.length; j++) {
                      if (filename == folderFiles[j]?.fileName) {
                        temp[j] = {
                          fileName: filename,
                          fileURL: res?.data?.data?.image,
                          fileSize: replaceFile.size,
                          createdAt: moment(new Date()).format('YYYY-MM-DD'),
                        }
                      }
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFiles(selectedFolder) /********************* */
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('replace')
            }
            setOpen(false)
          }
          // }, 1000)
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
    }

    // delete file
    if (val == 1) {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) temp.splice(i, 1)
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
          setOpen(false)
        })
        .catch((err: any) => {})
    }
  }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const body = {
        name: folderName,
        tenantId: `${window.location.pathname.split('/')[2]}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          getTenantFolders()
          setFolderNameModal(false)
        })
        .catch((err: any) => {})
    } else {
    }
  }

  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: selectedFolder?.name,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        getFolderFiles(selectedFolder)
        setEditFile(-1)
      })
      .catch((err: any) => {})
  }

  const updateFolderName = () => {
    const body = {
      id: selectedFolder?._id,
      name: newFolderName,
      fileList: selectedFolder?.fileList,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        setEditFolderName(-1)
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  const deleteFolder = (id: any) => {
    ApiDelete(`corporate/tenant_folder/${id}`)
      .then((res: any) => {
        SuccessToast('Folder has been deleted successfully!')
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  // Replace OR Keep File
  const replaceKeepFile = async (val: any) => {
    const files = replaceFile
    fileLists_single = Array.from(files)

    setOpen(true)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      console.log(fileLists_single[i])
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      let filename: any = fileLists_single[i].name
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = 'upload/image/tenant_folder'

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          let temp = [...folderFiles]
          if (val == 'replace') {
            for (let j = 0; j < folderFiles?.length; j++) {
              if (filename == folderFiles[j]?.fileName) {
                temp[j] = {
                  fileName: filename,
                  fileURL: res?.data?.data?.image,
                  fileSize: fileLists_single[i].size,
                  createdAt: moment(new Date()).format('YYYY-MM-DD'),
                }
              }
            }
          } else {
            temp[folderFiles?.length] = {
              fileName: `${getFilename(filename, false)}-copy.${getFilename(filename, true)}`,
              fileURL: res?.data?.data?.image,
              fileSize: fileLists_single[i].size,
              createdAt: moment(new Date()).format('YYYY-MM-DD'),
            }
          }

          const body = {
            id: selectedFolder?._id,
            name: selectedFolder?.name,
            fileList: temp,
          }
          ApiPut(`corporate/tenant_folder`, body)
            .then((res: any) => {
              getFolderFiles(selectedFolder)
              setOpen(false)
            })
            .catch((err: any) => {})
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  /*********************** Requests ************************/
  const [page, setPage] = useState<any>(1)
  const [requests, setRequests] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)

    setTimeout(() => {
      getRequests()
    }, 1000)
  }

  const getRequests = () => {
    const body = {
      page: page,
      limit: 25,
      unitId: tenantSelectedUnit?._id,
      tenancyId: selectedTenancy?._id,
    }

    ApiPost(`corporate/tenant_request/property_file/get`, body)
      .then((res) => {
        setRequests(res?.data?.data?.tenant_request_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  /************************ Payments  ********************/
  const [page1, setPage1] = useState<any>(1)
  const [payments, setPayments] = useState<any>()
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [show, setShow] = useState<any>([])
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [profileImg, setProfileImg] = useState<any>(null)

  const handlePageClick1 = (event: any) => {
    setPage(event.selected + 1)

    setTimeout(() => {
      getPayments()
      getPaymentSummary()
    }, 1000)
  }

  const getPayments = () => {
    const body = {
      limit: 25,
      page: page1,
      unitId: tenantSelectedUnit?._id ? tenantSelectedUnit?._id : undefined,
      tenancyId: selectedTenancy?._id ? selectedTenancy?._id : undefined,
    }

    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit1(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then((res) => {
        setDataToEdit(res?.data?.data)
        setEditIncomeModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  const getPaymentSummary = () => {
    if (selectedTenancy?._id != undefined) {
      ApiGet(`corporate/payment/overall?tenancyId=${selectedTenancy?._id}`)
        .then((res) => {
          setPaymentData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    }
  }

  /************************Unit Dropdown ********************/
  const [tenantUnits, setTenantUnits] = useState<any>()
  const [tenantSelectedUnit, setTenantSelectedUnit] = useState<any>()
  const [tenancies, setTenancies] = useState<any>()
  const [selectedTenancy, setSelectedTenancy] = useState<any>()

  const getTenantUnits = () => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res) => {
        setTenantUnits(res?.data?.data)
        setTenantSelectedUnit(res?.data?.data?.[0])
        if (res?.data?.data?.[0]) getTenantTenancies(res?.data?.data?.[0])
      })
      .catch((err) => console.log('err', err))
  }

  const getTenantTenancies = (unit: any) => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
      unitId: unit?._id,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res) => {
        setTenancies(res?.data?.data)
        setSelectedTenancy(res?.data?.data?.[0])
      })
      .catch((err) => console.log('err', err))
  }

  const [newName, setNewName] = useState<any>()
  const [newFolderName, setNewFolderName] = useState<any>()

  //
  const renameFile = async (file: any, type: any) => {
    const body = {
      newFileName: newName + '.' + type,
      image: file,
    }

    await ApiPost('upload/rename_file', body)
      .then((res) => {
        setTimeout(() => {
          setEditFile(-1)
          getFolderFiles(selectedFolder)
        }, 1500)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const [isEditTenant, setIsEditTenant] = useState<any>(false)

  React.useEffect(() => {
    getTenantFolders()
    getTenantUnits()
  }, [])

  // get unit details
  const getUnitDetails = () => {
    if(tenantSelectedUnit?._id != undefined){
    ApiGet(`corporate/unit/${tenantSelectedUnit?._id}`)
      .then((res) => {
        setUnitDetails(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
    }
  }

  React.useEffect(() => {
    getUnitDetails()
  }, [tenantSelectedUnit])

  // React.useEffect(() => {
  //   if(selectedTenancy || tenantSelectedUnit)
  //   getRequests()
  // }, [selectedTenancy, tenantSelectedUnit])

  // React.useEffect(() => {
  //   if(selectedTenancy || tenantSelectedUnit){
  //   getPayments()
  //   getPaymentSummary()
  //   }
  // }, [selectedTenancy, tenantSelectedUnit])

  React.useEffect(() => {
    getmessageDataById(roomId)
  }, [sendMessage])

  React.useEffect(() => {
    console.log(comefrom)
    if (comefrom == 'tenant-req') {
      getRequests()
      setPropertiType('Requests')
    } else setPropertiType('Documents')
  }, [])

  const deleteTenant = () => {
    ApiDelete(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then(() => {
        SuccessToast('Tenant has been deleted successfully..')
        navigate(-1)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res: any) => {
        setCountry(res?.data?.data)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  useEffect(() => {
    getCountry()
  }, [])

  const updateTenant = async (data: any, e: any) => {
    e.preventDefault()
    const body = {
      _id: tenantData?._id,
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality,
      // tenantType: tenantData?.tenantType,
      DOB: tenantData?.DOB,
      passportNo: tenantData?.passportNo,
      otherNo: tenantData?.otherNo,
      idNo: tenantData?.idNo,
      // folder_list: folders,
      gender: Number(tenantData?.gender),
      profileImage: profileImg,
    }
    console.log(body)
    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast(response?.data?.message)
        setIsEditTenant(false)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  const onSelectFile = async (e: any) => {
    e.preventDefault()
    let fileURL = e.target.files[0]
    var formdata = new FormData()
    formdata.append('image', fileURL)

    await ApiPost(`upload/compress_image/tenant_profile`, formdata)
      .then((res) => {
        e.preventDefault()
        const body = {
          _id: tenantData?._id,
          firstName: tenantData?.firstName,
          lastName: tenantData?.lastName,
          phoneNumber: tenantData?.phoneNumber,
          countryCode: tenantData?.countryCode,
          email: tenantData?.email,
          nationality: tenantData?.nationality,
          // tenantType: tenantData?.tenantType,
          DOB: tenantData?.DOB,
          passportNo: tenantData?.passportNo,
          otherNo: tenantData?.otherNo,
          idNo: tenantData?.idNo,
          // folder_list: folders,
          gender: Number(tenantData?.gender),
          profileImage: res?.data?.data?.image,
        }

        ApiPut(`corporate/tenant`, body)
          .then((response) => {
            SuccessToast(response?.data?.message)
            setIsEditTenant(false)
          })
          .catch((error) => {
            console.log(error)
            ErrorToast(error?.message)
          })
      })
      .catch(() => {})
  }

  // delete logo
  const deleteProfileImg = () => {
    const body = {
      url: profileImg,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setProfileImg(null)
        SuccessToast('Profile picture has been removed successfully!!!')
        const body = {}

        // ApiPut('corporate/corporate_setting', body)
        //   .then((res) => {
        //     getDetails()
        //   })
        //   .catch((err) => ErrorToast(err.message))
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  useEffect(() => {
    if(tenantSelectedUnit?._id != undefined || selectedTenancy?._id != undefined){
    getRequests()
    getPayments()
    getPaymentSummary()
    }
  }, [tenantSelectedUnit, selectedTenancy])

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], {type: response.headers['content-type']})

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'downloaded_image.png'

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <form onSubmit={handleSubmit((data: any, e: any) => updateTenant(tenantData, e))}>
        <div className='d-flex flex-column flex-column-fluid'>
          {/* <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                View Tenant Details
              </h1>
             
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <div className='text-muted cursor-pointer' onClick={() => navigate('/tenants')}>
                    Tenant
                  </div>
                </li>
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                <li className='breadcrumb-item active'>
                  <a className='px-2'>Tenant details</a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
          <div id='kt_app_content' className='app-content flex-column-fluid pt-0'>
            <div className='px-2 pt-0 mt-0'>
              <div className='d-flex align-items-center'>
                <span
                  className='rounded-circle cursor-pointer'
                  onClick={() => {
                    navigate('/tenants')
                  }}
                >
                  <img src={backArrow} style={{stroke: 'red'}} height='16' width='16' />
                </span>
                <h1 className='m-0 head-text ms-2'>
                  {tenantData?.firstName || tenantData?.lastName
                    ? `${tenantData?.firstName} ${tenantData?.lastName}`
                    : 'Tenant Profile'}
                </h1>
              </div>
            </div>
            <div id='kt_app_content_container' className='px-2 mt-8'>
              <div className='d-flex flex-column flex-lg-row'>
                <div className='flex-column flex-lg-row-auto w-lg-300px w-xl-400px mb-10'>
                  <div className='card mb-5 mb-xl-8'>
                    <div className='card-body py-3 px-5'>
                      {/* <div className='d-flex flex-stack fs-4 py-3'>
                        <div
                          className='fw-bold rotate collapsible'
                          data-bs-toggle='collapse'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_user_view_details'
                        ></div>
                      </div> */}
                      <div id='kt_user_view_details' className='collapse show'>
                        <div className='pb-5 fs-6'>
                          {isEditTenant && (
                            <>
                              <div className='d-flex align-items-center justify-content-center mb-5 mt-2'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold red-hollow-btn'
                                  onClick={() => {
                                    getTenantData()
                                    getTenantFolders()
                                    setTimeout(() => {
                                      setIsEditTenant(false)
                                    }, 500)
                                  }}
                                >
                                  <img
                                    src={redCross}
                                    height={18}
                                    width={18}
                                    style={{marginRight: '7px'}}
                                  />{' '}
                                  Cancel
                                </button>
                                <button
                                  className='btn btn-sm fw-bold select-btn ms-3'
                                  type='submit'
                                >
                                  <img src={check} height={18} width={18} className='me-5' />
                                  Update
                                </button>
                              </div>
                            </>
                          )}
                          <div className='row mt-4'>
                            {!isEditTenant && (
                              <div className='col-lg-8 col-md-8 symbol symbol-100px symbol d-flex align-items-center'>
                                {/* Logo */}
                                <div className='d-flex mb-3 '>
                                  {profileImg == null ? (
                                    <>
                                      <label
                                        htmlFor='media'
                                        className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                                        style={{
                                          height: '160px',
                                          width: '160px',
                                          backgroundColor: '#8a8aa3',
                                          fontSize: '20px',
                                          // paddingLeft: '40px',
                                          color: 'white',
                                        }}
                                      >
                                        <img />
                                        <b
                                          style={{
                                            fontSize: '22px',
                                            display: 'block',
                                            marginTop: '-25px',
                                            fontWeight: '700',
                                            marginBottom: '5px',
                                          }}
                                        >
                                          PROFILE PHOTO
                                        </b>
                                        Click to upload
                                      </label>

                                      <input
                                        type='file'
                                        hidden
                                        id='media'
                                        className='form-control form-control-solid'
                                        name='passport'
                                        onChange={onSelectFile}
                                      />
                                    </>
                                  ) : (
                                    <div className='d-flex'>
                                      <img
                                        src={`${Bucket}${profileImg}`}
                                        height='160'
                                        width='160'
                                        style={{
                                          borderRadius: '7px',
                                          border: '2px solid black',
                                          objectFit: 'contain',
                                        }}
                                      />

                                      {/* <div> */}
                                      <label
                                        htmlFor='media'
                                        className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                        style={{
                                          height: '25px',
                                          width: '25px',
                                          border: '1px solid #48a794 ',
                                          borderRadius: '4px',
                                          transform: 'translate(-45px,-10px)',
                                        }}
                                      >
                                        <img
                                          src={editpen}
                                          alt=''
                                          className=''
                                          style={{cursor: 'pointer', height: '18px', width: '18px'}}
                                        />
                                      </label>

                                      <input
                                        type='file'
                                        hidden
                                        id='media'
                                        className='form-control form-control-solid'
                                        name='passport'
                                        onChange={onSelectFile}
                                      />
                                      {/* </div> */}
                                      {/* <div> */}
                                      <div
                                        className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                        style={{
                                          height: '25px',
                                          width: '25px',
                                          border: '1px solid crimson',
                                          borderRadius: '4px',
                                          transform: 'translate(-38px,-10px)',
                                        }}
                                        onClick={() => deleteProfileImg()}
                                      >
                                        <img
                                          src={deleteImg}
                                          alt=''
                                          className=''
                                          style={{
                                            cursor: 'pointer',
                                            height: '18px',
                                            width: '18px',
                                            marginLeft: '2px',
                                          }}
                                        />
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  )}
                                </div>
                                {/* <img src={img} alt='image' /> */}
                              </div>
                            )}
                            <div className='col-lg-4 col-md-4 pe-5' style={{textAlign: 'right'}}>
                              {!isEditTenant && (
                                <div className='d-flex align-items-center justify-content-end'>
                                  <img
                                    src={bin}
                                    alt=''
                                    height='24px'
                                    width='24px'
                                    className='ms-4'
                                    style={{cursor: 'pointer'}}
                                    onClick={() =>
                                      Swal.fire({
                                        html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the tenant, do you wish to proceed?</p>',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#007a59',
                                        confirmButtonText: 'Confirm',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                      }).then((res: any) => {
                                        if (res.isConfirmed) {
                                          deleteTenant()
                                        }
                                      })
                                    }
                                  />
                                  <img
                                    src={pencil}
                                    alt=''
                                    height='22px'
                                    width='22px'
                                    className='ms-4'
                                    style={{cursor: 'pointer'}}
                                    // onClick={() => {
                                    //   navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                    // }}
                                    onClick={() => setIsEditTenant(true)}
                                  />

                                  {/* <a
                               
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                
                                onClick={() => {
                                  navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                }}
                              >
                                Edit
                              </a> */}
                                </div>
                              )}
                            </div>
                            {!isEditTenant && (
                              <div className='col-12 mt-3 '>
                                <span
                                  className='value_black'
                                  style={{
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                    fontWeight: 600,
                                    color: 'black',
                                  }}
                                >
                                  {tenantData?.firstName} {tenantData?.lastName}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className=''>
                            {isEditTenant && (
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label
                                    className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                  >
                                    {' '}
                                    First Name{' '}
                                  </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2'>
                                  <input
                                    {...register('firstName', {required: true})}
                                    type='text'
                                    className='form-control form-control-solid mytest'
                                    placeholder=''
                                    name='firstName'
                                    value={tenantData?.firstName}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />

                                  {errors.firstName && isEditTenant && (
                                    <p
                                      style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                </h4>
                              </div>
                            )}

                            {isEditTenant && (
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label
                                    className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                  >
                                    {' '}
                                    Last Name{' '}
                                  </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2'>
                                  <input
                                    type='text'
                                    {...register('lastName', {required: true})}
                                    className='form-control form-control-solid mytest'
                                    placeholder=''
                                    name='lastName'
                                    value={tenantData?.lastName}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />

                                  {errors.lastName && isEditTenant && (
                                    <p
                                      style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                </h4>
                              </div>
                            )}

                            {!isEditTenant && (
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label
                                    className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                  >
                                    {' '}
                                    ID
                                  </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2'>
                                  <span className='ps-1'>
                                    <b>{tenantData?.id}</b>
                                  </span>
                                </h4>
                              </div>
                            )}

                            {/* <h6 className='my-3 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> ID</label>
                            <span className='ms-5 ps-1 head-text'>
                              <b> {tenantData?.id}</b>
                            </span>
                          </h6> */}

                            {!isEditTenant && (
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label
                                    className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                  >
                                    {' '}
                                    Type
                                  </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2'>
                                  <span className='ps-1'>
                                    <b>
                                      {tenantData?.tenantType === 0
                                        ? 'Main'
                                        : tenantData?.tenantType === 1
                                        ? 'Sub'
                                        : ''}
                                    </b>
                                  </span>
                                </h4>
                              </div>
                            )}

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className={isEditTenant ? 'required labl-gry' : 'labl-gry'}>
                                  {' '}
                                  Gender{' '}
                                </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <Select
                                    defaultValue={
                                      tenantData?.gender == 0
                                        ? genderOptions[0]
                                        : tenantData?.gender == 1
                                        ? genderOptions[1]
                                        : genderOptions[2]
                                    }
                                    // {...register('gender', {required: true})}
                                    styles={customStyles}
                                    isSearchable={false}
                                    options={genderOptions}
                                    name='gender'
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        ['gender']: e.value,
                                      })
                                    }
                                    // onChange={(e) => {

                                    //   // let temp = {...tenantData}
                                    //   // temp.gender = e.value
                                    //   // setTenantData(temp)
                                    // }}
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>
                                      {tenantData?.gender == 0
                                        ? 'Female'
                                        : tenantData?.gender == 1
                                        ? 'Male'
                                        : 'Other'}
                                    </b>
                                  </span>
                                )}
                                {/* {errors.gender && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}  */}
                              </h4>
                            </div>

                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> Type </label>
                            <span className='ms-5 ps-1 head-text'>
                              <b>
                                {' '}
                                {tenantData?.tenantType === 0
                                  ? 'Main'
                                  : tenantData?.tenantType === 1
                                  ? 'Sub'
                                  : ''}
                              </b>
                            </span>
                          </h6> */}

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className={isEditTenant ? 'required labl-gry' : 'labl-gry'}>
                                  {' '}
                                  DOB{' '}
                                </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <input
                                    type='date'
                                    {...register('DOB', {required: true})}
                                    className='form-control form-control-solid mytest'
                                    placeholder='Select date'
                                    max={new Date().toISOString().split('T')[0]}
                                    name='DOB'
                                    value={moment(
                                      tenantData?.DOB && tenantData?.DOB.split('T')[0]
                                    ).format('YYYY-MM-DD')}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>
                                      {moment(
                                        tenantData?.DOB && tenantData?.DOB.split('T')[0]
                                      ).format('DD/MM/YYYY')}
                                    </b>
                                  </span>
                                )}
                                {errors.DOB && isEditTenant && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </h4>
                            </div>

                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> DOB </label>
                            <span className='ms-5 ps-1 head-text '>
                              <b>
                                {' '}
                                {tenantData?.DOB
                                  ? moment(tenantData?.DOB).format('DD/MM/YYYY')
                                  : '-'}
                              </b>
                            </span>
                          </h6> */}

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className={isEditTenant ? 'required labl-gry' : 'labl-gry'}>
                                  {' '}
                                  Nationality{' '}
                                </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <select
                                    {...register('nationality', {required: true})}
                                    className='form-select form-select-solid mytest'
                                    name='nationality'
                                    value={tenantData?.nationality}
                                    // style={{width: '150px'}}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  >
                                    <option value='' disabled selected>
                                      Select country
                                    </option>
                                    {country?.map((v: any) => (
                                      <option value={v.country}>{v.country}</option>
                                    ))}
                                  </select>
                                ) : (
                                  <span className='ps-1'>
                                    <b>{tenantData?.nationality ? tenantData?.nationality : '-'}</b>
                                  </span>
                                )}
                                {errors.nationality && isEditTenant && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </h4>
                            </div>

                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> Nationality </label>
                            <span className='ms-5 ps-1 head-text'>
                              <b> {tenantData?.nationality || '-'}</b>
                            </span>
                          </h6> */}
                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className={isEditTenant ? 'required labl-gry' : 'labl-gry'}>
                                  {' '}
                                  Email{' '}
                                </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2 test'>
                                {isEditTenant ? (
                                  <Controller
                                    name='email'
                                    control={control}
                                    rules={{
                                      required: 'Email is required',
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address',
                                      },
                                    }}
                                    render={({field}) => (
                                      <input
                                        //   {...register('email', {required: true})}
                                        type='email'
                                        className='form-control form-control-solid mytest'
                                        placeholder=''
                                        name='email'
                                        value={tenantData?.email}
                                        onChange={(e) => {
                                          setValue('email', e.target.value) // Update the form value
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>{tenantData?.email ? tenantData?.email : '-'}</b>
                                  </span>
                                )}
                                {errors.email && isEditTenant && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    Please enter a valid Email Address
                                    {/* {errors.email.message} */}
                                  </p>
                                )}
                              </h4>
                            </div>
                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> Email </label>
                            <span className='ms-5 ps-1 head-text'>
                              <b> {tenantData?.email || '-'}</b>
                            </span>
                          </h6> */}

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className={isEditTenant ? 'required labl-gry' : 'labl-gry'}>
                                  {' '}
                                  Mobile No.{' '}
                                </label>
                              </h4>

                              <h4
                                style={{width: '65%', borderRadius: '0.475rem'}}
                                className='my-2 test'
                              >
                                {isEditTenant ? (
                                  <Controller
                                    name='phoneNumber'
                                    control={control}
                                    rules={{
                                      required: 'This field is required',
                                    }}
                                    render={({field}) => (
                                      <PhoneInput
                                        //   {...register('phoneNumber', {required: true})}
                                        placeholder='Enter phone number'
                                        value={`${tenantData?.countryCode} ${tenantData?.phoneNumber}`}
                                        onChange={(phone: any, e: any) => {
                                          let CountryCode: any = e?.dialCode
                                          let PhoneNumber: any = phone.split(CountryCode)[1]
                                          setTenantData({
                                            ...tenantData,
                                            ['countryCode']: parseInt(CountryCode),
                                            ['phoneNumber']: PhoneNumber,
                                          })

                                          setValue(
                                            'phoneNumber',
                                            `${parseInt(CountryCode)}${PhoneNumber}`
                                          )
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>
                                      {tenantData?.phoneNumber
                                        ? `${tenantData?.countryCode} ${tenantData?.phoneNumber}`
                                        : '-'}
                                    </b>
                                  </span>
                                )}

                                {errors.phoneNumber && isEditTenant && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                    {/* {errors.email.message} */}
                                  </p>
                                )}
                              </h4>
                            </div>
                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '80px'}}> Mobile No. </label>
                            <span className='ms-5 ps-1 head-text'>
                              <b>
                                {' '}
                                +{tenantData?.countryCode} {tenantData?.phoneNumber}
                              </b>
                            </span>
                          </h6> */}

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className='labl-gry'> ID No. </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <input
                                    type='text'
                                    className='form-control form-control-solid mytest '
                                    placeholder=''
                                    name='idNo'
                                    value={tenantData?.idNo}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>{tenantData?.idNo ? `${tenantData?.idNo}` : '-'}</b>
                                  </span>
                                )}
                              </h4>
                            </div>

                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className='labl-gry'> Passport No. </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <input
                                    type='text'
                                    className='form-control form-control-solid mytest'
                                    placeholder=''
                                    name='passportNo'
                                    value={tenantData?.passportNo}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>
                                      {tenantData?.passportNo ? `${tenantData?.passportNo}` : '-'}
                                    </b>
                                  </span>
                                )}
                              </h4>
                            </div>
                            <div className='d-flex my-2 mb-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                <label className='labl-gry'> Other No. </label>
                              </h4>
                              <h4 style={{width: '65%'}} className='my-2'>
                                {isEditTenant ? (
                                  <input
                                    type='text'
                                    className='form-control form-control-solid mytest'
                                    placeholder=''
                                    name='otherNo'
                                    value={tenantData?.otherNo}
                                    onChange={(e) =>
                                      setTenantData({
                                        ...tenantData,
                                        [e.target.name]: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <span className='ps-1'>
                                    <b>{tenantData?.otherNo ? `${tenantData?.otherNo}` : '-'}</b>
                                  </span>
                                )}
                              </h4>
                            </div>
                            {/* <h6 className='my-2 mb-4' style={{color: '#a1a5b7'}}>
                            <label style={{width: '100px'}}> Source </label>
                            <span className='ms-5 ps-1 head-text'>
                              <b>
                                {' '}
                                {tenantData?.tenantSource === 1
                                  ? 'Direct'
                                  : tenantData?.tenantSource === 0
                                  ? 'Agent'
                                  : ''}
                              </b>
                            </span>
                          </h6> */}
                          </div>

                          {/* <div className='text-muted'>
                          <div className='fw-bold '> Tenancy Status </div>
                          <div className='d-flex gap-3 align-items-left flex-column'>
                            <span className='value_black'>
                              {tenantData[0]?.tenancy?.tenancyStatus === 0
                                ? 'Booked'
                                : tenantData[0]?.tenancy?.tenancyStatus === 1
                                ? 'Active'
                                : 'Expiring'}
                            </span>
                            <div>
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green '
                                onClick={() => {
                                  navigate(`/tenancy-details/${tenantData?.tenancy?._id}`)
                                  // navigate(`tenancy-details`)
                                  // console.log('`tenancy-details/${tenantData[0]?.teancy?._id}`', `tenancy-details/${tenantData[0]?.tenancy?._id}`)
                                }}
                              >
                                View Tenancy
                              </a>
                            </div>
                          </div>
                        </div> */}

                          {/* {tenantData?.tenancy?.unit[0]?.floor[0]?.name ? (
                          <div className='fw-bold text-muted'>
                            Floor
                            <span className='value_black'>
                              {tenantData[0]?.tenancy?.unit[0]?.floor[0]?.name}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}

                        {tenantData?.tenancy?.community[0]?.name ? (
                          <div className='fw-bold text-muted'>
                            Development Name
                            <span className='value_black'>
                              {tenantData[0]?.tenancy?.community[0]?.name}
                            </span>
                          </div>
                        ) : (
                          ''
                        )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {!messageList && (
                    <div className='flex-lg-row-fluid ms-lg-10'>
                      <ul
                        className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8'
                        role='tablist'
                      >
                        {/* {tenantData[0]?.tenantType === 0 && ( */}
                        <li className='nav-item' role='presentation'>
                          <a
                            className={
                              propertiType === 'Documents'
                                ? 'nav-link text-active-primary pb-4 active head-text'
                                : 'nav-link text-active-primary pb-4 head-text'
                            }
                            data-bs-toggle='tab'
                            aria-selected='false'
                            role='tab'
                            tabIndex={-1}
                            onClick={() => {
                              setPropertiType('Documents')
                            }}
                          >
                            Documents
                          </a>
                        </li>
                        {/* )} */}
                        {/* {tenantData[0]?.tenantType === 0 && ( */}
                        <li className='nav-item' role='presentation'>
                          <a
                            //   className='nav-link text-active-primary pb-4'
                            className={
                              propertiType === 'Payments & Rent'
                                ? 'nav-link text-active-primary pb-4 active head-text'
                                : 'nav-link text-active-primary pb-4 head-text'
                            }
                            data-kt-countup-tabs='true'
                            data-bs-toggle='tab'
                            data-kt-initialized='1'
                            aria-selected='false'
                            role='tab'
                            tabIndex={-1}
                            onClick={() => {
                              setPropertiType('Payments & Rent')
                              if (selectedTenancy || tenantSelectedUnit) {
                                getPayments()
                                getPaymentSummary()
                              }
                            }}
                          >
                            Payments
                          </a>
                        </li>
                        {/* )} */}
                        <li className='nav-item' role='presentation'>
                          <a
                            className={
                              propertiType === 'Messages'
                                ? 'nav-link text-active-primary pb-4 active head-text'
                                : 'nav-link text-active-primary pb-4 head-text'
                            }
                            data-bs-toggle='tab'
                            aria-selected='true'
                            role='tab'
                            onClick={() => setPropertiType('Messages')}
                          >
                            Messages
                          </a>
                        </li>
                        <li className='nav-item' role='presentation'>
                          <a
                            className={
                              propertiType === 'Requests'
                                ? 'nav-link text-active-primary pb-4 active head-text'
                                : 'nav-link text-active-primary pb-4 head-text'
                            }
                            data-bs-toggle='tab'
                            aria-selected='true'
                            role='tab'
                            onClick={() => {
                              setPropertiType('Requests')
                              if (selectedTenancy || tenantSelectedUnit) {
                                getRequests()
                              }
                            }}
                          >
                            Requests
                          </a>
                        </li>
                      </ul>
                      <div>
                        {/* Documents Tab */}
                        {propertiType === 'Documents' && (
                          <>
                            <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                              {!viewFolder && (
                                <div
                                  className='col-lg-12 col-md-12 text-end'
                                  style={{textAlign: 'right'}}
                                >
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold blue-hollow-btn ms-auto'
                                    style={{width: '130px'}}
                                    onClick={() => {
                                      setFileFound(false)
                                      setFolderNameModal(true)
                                    }}
                                  >
                                    <img
                                      src={plusBlue}
                                      height={18}
                                      width={18}
                                      style={{
                                        marginRight: '7px',
                                      }}
                                    />
                                    New Folder
                                  </button>
                                </div>
                              )}

                              {/* Add New Folder Card */}
                              {folderNameModal && (
                                <div className='col-md-6 col-lg-4 col-xl-4'>
                                  <div className='card h-100'>
                                    <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test'>
                                      <div className='d-flex'>
                                        <img
                                          src={trashImg}
                                          height={18}
                                          width={18}
                                          className='cursor-pointer'
                                          onClick={() => setFolderNameModal(false)}
                                        />

                                        <img
                                          style={
                                            folderName?.trim()?.length > 0
                                              ? {opacity: '1', cursor: 'pointer'}
                                              : {opacity: '0.7', cursor: 'not-allowed'}
                                          }
                                          src={correct}
                                          height={30}
                                          width={30}
                                          className='ms-auto '
                                          onClick={() => {
                                            if (folderName?.trim()?.length > 0) addFolder()
                                          }}
                                        />
                                      </div>

                                      <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                        <div className='symbol symbol-60px mb-5'>
                                          <img
                                            src={
                                              tenantData?.document?.contract?.split('.')[1] ===
                                              'pdf'
                                                ? pdfDocument
                                                : tenantData?.other?.split('.')[1] === 'doc'
                                                ? docCocument
                                                : folderDocument
                                            }
                                            className='theme-light-show'
                                            alt=''
                                          />
                                          <img
                                            src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                            className='theme-dark-show'
                                            alt=''
                                          />
                                        </div>
                                        <div className='fs-5 fw-bold mb-2 '>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mytest w-75 m-auto'
                                            placeholder='Enter Folder Name'
                                            onChange={(e: any) => setFolderName(e.target.value)}
                                          >
                                            {fileFound ? (
                                              <p className='mt-2' style={{color: 'red'}}>
                                                Folder name already exists
                                              </p>
                                            ) : null}
                                          </input>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {folders?.length > 0 && (
                                <>
                                  {viewFolder ? (
                                    <>
                                      <div className='d-flex align-items-center'>
                                        <span
                                          className='rounded-circle cursor-pointer'
                                          onClick={() => {
                                            setViewFolder(false)
                                            setEditFile(-1)
                                          }}
                                        >
                                          <img
                                            src={backArrow}
                                            style={{stroke: 'red'}}
                                            height='14'
                                            width='14'
                                          />
                                        </span>
                                        <h2 className='m-0 head-text ms-2'>
                                          {selectedFolder?.name}
                                        </h2>

                                        <label
                                          title='Add New File'
                                          htmlFor='uploadPassport'
                                          className='btn btn-sm fw-bold upload-btn ms-auto'
                                        >
                                          <img
                                            src={upload}
                                            height={18}
                                            width={18}
                                            className='me-3'
                                          />
                                          Upload File{' '}
                                        </label>
                                        <input
                                          type='file'
                                          hidden
                                          id='uploadPassport'
                                          className='form-control form-control-solid'
                                          placeholder='upload'
                                          name='passport'
                                          onChange={(e: any) => {
                                            onFilesAddedSingle(e, 0, '')
                                          }}
                                          multiple
                                        />
                                      </div>
                                      <div className='col-md-12 col-lg-12 col-xl-12'>
                                        <div className='card h-100'>
                                          <div className='card-body d-flex justify-content-center flex-column p-8'>
                                            {folderFiles?.length > 0 ? (
                                              <>
                                                <div
                                                  className='row py-3'
                                                  style={{color: '#a1a5b7'}}
                                                >
                                                  <div className='col-md-6 '>File</div>
                                                  <div className='col-md-2 '>Date Added</div>
                                                  <div className='col-md-4'></div>
                                                </div>

                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div
                                                      className='row py-3'
                                                      style={{borderBottom: '1px solid lightgrey'}}
                                                    >
                                                      {editFile == ind ? (
                                                        <>
                                                          <div className='col-md-6 head-text pe-5'>
                                                            <div className='d-flex'>
                                                              <input
                                                                type='text'
                                                                value={getFilename(
                                                                  file?.fileName,
                                                                  false
                                                                )}
                                                                className='form-control form-control-solid p-2'
                                                                onChange={(e: any) => {
                                                                  let temp = getFilename(
                                                                    file?.fileName,
                                                                    true
                                                                  )
                                                                  const values = [...folderFiles]
                                                                  values[ind].fileName =
                                                                    e.target.value + '.' + temp
                                                                  setFolderFiles(values)
                                                                }}
                                                              />
                                                              <a
                                                                className='btn btn-sm fw-bold btn-primary btn-green'
                                                                onClick={() => updateName()}
                                                              >
                                                                <img
                                                                  src={check}
                                                                  height={18}
                                                                  width={18}
                                                                />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div className='col-md-6 head-text'>
                                                          {getFilename(file?.fileName, false)
                                                            ?.length > 30
                                                            ? `${file?.fileName?.slice(
                                                                0,
                                                                30
                                                              )}...${getFilename(
                                                                file?.fileName,
                                                                true
                                                              )}`
                                                            : file?.fileName}
                                                        </div>
                                                      )}
                                                      <div className='col-md-2 head-text'>
                                                        {moment(file?.createdAt).format(
                                                          'DD.MM.YYYY'
                                                        )}
                                                      </div>
                                                      <div className='col-md-4 head-text text-end'>
                                                        <div className='d-flex justify-content-end align-items-center'>
                                                          <img
                                                            src={eye}
                                                            height={20}
                                                            width={20}
                                                            className='me-3 cursor-pointer'
                                                            onClick={() => {
                                                              setIndex(ind)
                                                            }}
                                                          />
                                                          <img
                                                            src={downloadImg}
                                                            height={22}
                                                            width={22}
                                                            className='me-10 cursor-pointer'
                                                            onClick={() => {
                                                              if (!isDisable)
                                                                handleDownload(
                                                                  Bucket + file?.fileURL
                                                                )
                                                            }}
                                                            style={
                                                              isDisable
                                                                ? {opacity: '0.6'}
                                                                : {opacity: '1'}
                                                            }
                                                          />
                                                          <img
                                                            src={pencil}
                                                            height={16}
                                                            width={16}
                                                            className='me-3 cursor-pointer'
                                                            onClick={() => {
                                                              setNewName(
                                                                getFilename(file?.fileName, false)
                                                              )
                                                              setEditFile(ind)
                                                            }}
                                                          />
                                                          <img
                                                            src={trash}
                                                            height={17}
                                                            width={17}
                                                            className='me-5 cursor-pointer'
                                                            onClick={(e) => {
                                                              Swal.fire({
                                                                text: 'Are you sure you want to permanently delete this file?',
                                                                icon: 'warning',
                                                                showConfirmButton: true,
                                                                confirmButtonColor: '#D72852',
                                                                confirmButtonText: 'Yes',
                                                                showCancelButton: true,
                                                                // cancelButtonColor: "transparent",
                                                                cancelButtonText: 'Cancel',
                                                              }).then((res: any) => {
                                                                if (res.isConfirmed) {
                                                                  onFilesAddedSingle(
                                                                    e,
                                                                    1,
                                                                    file?._id
                                                                  )
                                                                }
                                                              })
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                })}

                                                {/* <div className='tops mw-350px d-flex gap-5 align-items-start'>
                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='80'
                                                          height='80'
                                                          className='main_img'
                                                        />

                                                        <img
                                                          style={{
                                                            marginLeft: '-13px',
                                                            marginTop: '-10px',
                                                            cursor: 'pointer',
                                                          }}
                                                          src={closered}
                                                       
                                                          width='25'
                                                          height='25'
                                                          onClick={(e) => {
                                                            onFilesAddedSingle(e, 1, file?._id)
                                                          }}
                                                        />
                                                      </div>
                                                      <p className=''>
                                                        {getFilename(file?.fileName, false)
                                                          ?.length > 6
                                                          ? `${file?.fileName?.slice(
                                                              0,
                                                              6
                                                            )}...${getFilename(
                                                              file?.fileName,
                                                              true
                                                            )}`
                                                          : file?.fileName}
                                                      </p>
                                                    </div>
                                                  )
                                                })}
                                              </div> */}
                                              </>
                                            ) : (
                                              <div className='text-center'> No Files</div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {folders
                                        ?.sort((a: any, b: any) => {
                                          // Sort by createdAt, assuming createdAt is a valid date string
                                          let dateA: any = new Date(a?.createdAt)
                                          let dateB: any = new Date(b?.createdAt)
                                          return dateB - dateA // Change to dateA - dateB for ascending order
                                        })
                                        ?.map((fl: any, index: any) => {
                                          return (
                                            <div className='col-md-6 col-lg-4 col-xl-4'>
                                              <div className='card h-100'>
                                                <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                  {!fl?.isSystemGenerated ? (
                                                    <div className='d-flex'>
                                                      {editFolderName != index ? (
                                                        <>
                                                          <img
                                                            src={trashImg}
                                                            height={18}
                                                            width={18}
                                                            className='cursor-pointer ms-auto'
                                                            onClick={() => {
                                                              Swal.fire({
                                                                text: 'Are you sure you want to permanently delete this folder?',
                                                                icon: 'warning',
                                                                showConfirmButton: true,
                                                                confirmButtonColor: '#D72852',
                                                                confirmButtonText: 'Yes',
                                                                showCancelButton: true,
                                                                // cancelButtonColor: "transparent",
                                                                cancelButtonText: 'Cancel',
                                                              }).then((res: any) => {
                                                                if (res.isConfirmed) {
                                                                  deleteFolder(fl?._id)
                                                                }
                                                              })
                                                            }}
                                                          />
                                                          <img
                                                            src={pencil}
                                                            alt=''
                                                            height={18}
                                                            width={18}
                                                            className='ms-4 cursor-pointer'
                                                            // onClick={() => {
                                                            //   navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                                            // }}
                                                            onClick={() => {
                                                              setNewFolderName(fl?.name)
                                                              setEditFolderName(index)
                                                              setSelectedFolder(fl)
                                                            }}
                                                          />
                                                        </>
                                                      ) : (
                                                        <>
                                                          <img
                                                            src={trashImg}
                                                            height={18}
                                                            width={18}
                                                            className='cursor-pointer'
                                                            onClick={() => setEditFolderName(-1)}
                                                          />

                                                          <img
                                                            style={
                                                              newFolderName?.trim()?.length > 0
                                                                ? {
                                                                    opacity: '1',
                                                                    cursor: 'pointer',
                                                                  }
                                                                : {
                                                                    opacity: '0.7',
                                                                    cursor: 'not-allowed',
                                                                  }
                                                            }
                                                            src={correct}
                                                            height={30}
                                                            width={30}
                                                            className='ms-auto'
                                                            onClick={() => {
                                                              if (newFolderName?.trim()?.length > 0)
                                                                updateFolderName()
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <img
                                                      src={lock}
                                                      height='20px'
                                                      width='20px'
                                                      className='ms-auto'
                                                    />
                                                  )}
                                                  <a
                                                    // href={`${Bucket}${tenantData?.document?.contract}`}
                                                    // target='_blank'

                                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                                  >
                                                    <div className='symbol symbol-60px mb-5'>
                                                      <img
                                                        src={
                                                          tenantData?.document?.contract?.split(
                                                            '.'
                                                          )[1] === 'pdf'
                                                            ? pdfDocument
                                                            : tenantData?.other?.split('.')[1] ===
                                                              'doc'
                                                            ? docCocument
                                                            : folderDocument
                                                        }
                                                        className='theme-light-show'
                                                        alt=''
                                                        onClick={() => {
                                                          getFolderFiles(fl)
                                                        }}
                                                      />
                                                      <img
                                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                                        className='theme-dark-show'
                                                        alt=''
                                                        onClick={() => {
                                                          getFolderFiles(fl)
                                                          setEditFolderName(-1)
                                                        }}
                                                      />
                                                    </div>
                                                    <div className='fs-5 fw-bold mb-2'>
                                                      {editFolderName == index ? (
                                                        <>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid mytest w-75 m-auto'
                                                            placeholder='Enter Folder Name'
                                                            value={newFolderName}
                                                            onChange={(e: any) =>
                                                              setNewFolderName(e.target.value)
                                                            }
                                                          >
                                                            {fileFound ? (
                                                              <p
                                                                className='mt-2'
                                                                style={{color: 'red'}}
                                                              >
                                                                Folder name already exists
                                                              </p>
                                                            ) : null}
                                                          </input>
                                                        </>
                                                      ) : (
                                                        <h4>{fl?.name}</h4>
                                                      )}
                                                    </div>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            {/* <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.contract}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.contract?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.other?.split('.')[1] === 'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Contract</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.other?.[0]}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.other?.[0]?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.other?.[0]?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Other</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.passport}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.passport?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.passport?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Passport</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.residency}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.residency?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.residency?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Residency</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.id}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.id?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.id?.split('.')[1] === 'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View ID</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.other?.[0]}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.other?.[0]?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.other?.[0]?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Other</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          </>
                        )}

                        {/* Property and Rent Tab */}
                        {propertiType === 'Payments & Rent' && (
                          <>
                            {/* <div className='card pt-4 mb-6 mb-xl-9'>
                            <div className='card-header border-0'>
                              <div className='card-title d-flex justify-content-between w-100'>
                                <h2></h2>
                                <a
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  onClick={() =>
                                    navigate(
                                      `/tenancy-details/${tenantData[0]?.tenancy?._id}/payment-rent`
                                    )
                                  }
                                >
                                  View Payments
                                </a>
                              </div>
                            </div>
                            <div className='card-body pt-0 pb-5'>
                              <div className='row gy-4 my-5'>
                                <div className='col-lg-4 fs-6 fw-semibold text-gray-600'>
                                  Total Payments{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.total_payments}
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Paid{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.paid_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Overdue{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.overdue_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Outstanding Balance{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.outstanding_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Rent{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.rent_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Security Deposit{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.security_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Booking{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.booking_count} AED
                                  </span>
                                </div>
                                {paymentData?.other?.map((v: any) => {
                                  return (
                                    <div className='col-lg-4 fw-semibold text-gray-600'>
                                      {v?._id ? v?._id : 'Other'}{' '}
                                      <span style={{color: 'black'}}>
                                        {v?.count ? v?.count : 0} AED
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div> */}

                            <div className='card card-flush gap-5 p-5 pt-1 '>
                              <div className='row mt-5'>
                                <div className='mb-2 col-md-1 pe-5 xyz'>
                                  <label
                                    className='fs-7 fw-semibold text-gray-600 pt-3'
                                    style={{fontWeight: '600'}}
                                  >
                                    {' '}
                                    Filter{' '}
                                  </label>
                                </div>
                                <div className='col-md-3'>
                                  <h6>
                                    <select
                                      className='form-select form-select-solid'
                                      value={
                                        tenantSelectedUnit?._id + '.' + tenantSelectedUnit?.unitNo
                                      }
                                      onChange={(e) => {
                                        let values = {...tenantSelectedUnit}

                                        values = {
                                          _id: e.target.value.split('.')[0],
                                          unitNo: e.target.value.split('.')[1],
                                        }

                                        setTenantSelectedUnit(values)

                                        // setTimeout(() => {
                                        //   getRequests()
                                        //   getPayments()
                                        //   getPaymentSummary()
                                        // }, 2500)
                                      }}
                                      name='units'
                                    >
                                      {tenantUnits?.length > 0 ? (
                                        <>
                                          {tenantUnits?.map((unit: any, index: any) => {
                                            return (
                                              <option value={unit?._id + '.' + unit?.unitNo}>
                                                {unit?.unitNo}
                                              </option>
                                            )
                                          })}
                                        </>
                                      ) : (
                                        <option value='' selected disabled>
                                          No Record
                                        </option>
                                      )}
                                    </select>
                                  </h6>
                                </div>

                                <div className='col-md-3'>
                                  <h6>
                                    <select
                                      className='form-select form-select-solid'
                                      value={
                                        selectedTenancy?._id + '.' + selectedTenancy?.contractNo
                                      }
                                      onChange={(e) => {
                                        let values = {...selectedTenancy}

                                        values = {
                                          _id: e.target.value.split('.')[0],
                                          contractNo: e.target.value.split('.')[1],
                                        }

                                        setSelectedTenancy(values)

                                        // setTimeout(() => {
                                        //   getRequests()
                                        //   getPayments()
                                        //   getPaymentSummary()
                                        // }, 2500)
                                      }}
                                      name='tenancies'
                                    >
                                      {tenancies?.length > 0 ? (
                                        <>
                                          {tenancies?.map((tn: any, index: any) => {
                                            // console.log(tn)
                                            return (
                                              <option value={tn?._id + '.' + tn?.contractNo}>
                                                {tn?.contractNo}
                                              </option>
                                            )
                                          })}
                                        </>
                                      ) : (
                                        <option value='' selected disabled>
                                          No Record
                                        </option>
                                      )}
                                    </select>
                                  </h6>
                                </div>
                              </div>
                              <div
                                className='row gy-4 my-5 mt-0'
                                style={{justifyContent: 'space-between'}}
                              >
                                <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                  Total Payments{' '}
                                  <span className='ms-3 head-text' style={{color: 'black'}}>
                                    <b>{paymentData?.totalPayments ? paymentData?.totalPayments : '0'}</b>
                                  </span>
                                </div>
                                <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                  Paid{' '}
                                  <span style={{color: 'black'}} className='ms-3 head-text'>
                                    <b>
                                      {paymentData?.paidAmount
                                        ? parseFloat(paymentData?.paidAmount)?.toLocaleString(
                                            'en-US',
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )
                                        : '0.00 '}
                                      AED
                                    </b>
                                  </span>
                                </div>
                                <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                  Overdue{' '}
                                  <span style={{color: 'black'}} className='ms-3 head-text'>
                                    <b>
                                      {paymentData?.overdueAmount
                                        ? parseFloat(paymentData?.overdueAmount)?.toLocaleString(
                                            'en-US',
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )
                                        : '0.00 '}
                                      AED
                                    </b>
                                  </span>
                                </div>
                                <div className='col-lg-4  fs-5 fw-semibold text-gray-600'>
                                  Outstanding Balance{' '}
                                  <span style={{color: 'black'}} className='ms-3 head-text'>
                                    <b>
                                      {paymentData?.outstandingAmount
                                        ? parseFloat(
                                            paymentData?.outstandingAmount
                                          )?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })
                                        : '0.00 '}
                                      AED
                                    </b>
                                  </span>
                                </div>
                              </div>

                              <div className='d-flex '>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold green-submit-btn ms-auto'
                                  onClick={() => {
                                    if (tenantSelectedUnit?._id && selectedTenancy?._id)
                                      setIncomeModal(true)
                                    else ErrorToast("Can't generate a payment...")
                                  }}
                                  style={{width: '200px'}}
                                  disabled={(tenantSelectedUnit?._id != undefined || selectedTenancy?._id != undefined) ? false : true }
                                >
                                  <img
                                    src={addWhite}
                                    height={16}
                                    width={16}
                                    style={{marginRight: '10px'}}
                                  />{' '}
                                  Add Incoming Payment
                                </button>
                              </div>

                              {payments?.length > 0 ? (
                                <div
                                  className='card-body pt-0 table-responsive mt-1 pb-4 px-1'
                                  id='global-table'
                                  // onWheel={handleMouseWheel}
                                  // ref={tableRef}
                                  // onMouseEnter={handleMouseEnter}
                                  // onMouseLeave={handleMouseLeave}
                                >
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-75px'>#</th>
                                        <th className='min-w-150px '>Payment</th>
                                        <th className='min-w-150px'>Amount</th>
                                        <th className='min-w-125px'>Date Created</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Category</th>
                                        <th className='min-w-100px'>Due</th>
                                        <th className='min-w-100px'>Reminder</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-100px'>Date Paid</th>
                                        <th className='min-w-150px'>Oustanding</th>
                                        <th className='min-w-100px'>Method</th>
                                        {/* <th className='min-w-100px'>Invoice</th> */}
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      {payments.map((pm: any, ind: any) => {
                                        let x = pm?.invoiceURL?.split('.')[1]
                                        let y = pm?.invoiceURL?.split('/')[2]
                                        // let inv: any = y + '.' + x
                                        let inv: any = y

                                        return (
                                          <>
                                            <tr
                                              style={
                                                show[ind] == true
                                                  ? {borderBottomStyle: 'none', height: '30px'}
                                                  : show[ind] == false
                                                  ? {borderBottomStyle: 'dashed', height: '30px'}
                                                  : pm?.payment_sub_item?.length == 0
                                                  ? {borderBottomStyle: 'dashed', height: '30px'}
                                                  : {height: '30px'}
                                              }
                                            >
                                              <td>
                                                <div className='d-flex align-items-center'>
                                                  {pm?.payment_sub_item?.length > 0 && (
                                                    <img
                                                      src={show[ind] ? close : openImg}
                                                      height='15'
                                                      width='15'
                                                      className='cursor-pointer'
                                                      style={{
                                                        marginLeft: '-30px',
                                                        marginRight: '15px',
                                                      }}
                                                      onClick={() => {
                                                        if (show[ind] == true) {
                                                          let values = [...show]
                                                          values[ind] = false
                                                          setShow(values)
                                                        } else {
                                                          let values = [...show]

                                                          values[ind] = true

                                                          setShow(values)
                                                        }
                                                      }}
                                                    />
                                                  )}
                                                  <a
                                                    // href={`${Bucket}${pm?.invoiceURL}`}
                                                    // target='_blank'
                                                    style={{
                                                      textDecoration: 'underline',
                                                      color: '#007a59',
                                                      marginLeft: '-3px',
                                                    }}
                                                    onClick={() => getPaymentById(pm?._id)}
                                                  >
                                                    {' '}
                                                    {pm.id}
                                                  </a>
                                                </div>
                                              </td>
                                              <td className='max-w-150px'>
                                                <div className='d-flex'>
                                                  {pm?.isLock && (
                                                    <img
                                                      src={lock}
                                                      height={16}
                                                      width={16}
                                                      className='me-2'
                                                    />
                                                  )}
                                                  {pm.name}
                                                </div>
                                              </td>
                                              <td>
                                                {parseFloat(pm?.totalAmount)?.toLocaleString(
                                                  'en-US',
                                                  {
                                                    minimumFractionDigits: 2,
                                                  }
                                                )}
                                              </td>
                                              <td>
                                                {pm?.createdAt
                                                  ? moment(pm?.createdAt).format('DD.MM.YYYY')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm.paymentStatus === 0
                                                  ? 'Unpaid'
                                                  : pm.paymentStatus === 1
                                                  ? 'Paid'
                                                  : pm.paymentStatus === 2
                                                  ? 'Partially paid'
                                                  : pm.paymentStatus === 3
                                                  ? 'Void'
                                                  : 'Draft'}
                                              </td>
                                              <td>
                                                {pm.categories == 0
                                                  ? 'Upcoming'
                                                  : pm.categories == 1
                                                  ? 'Overdue'
                                                  : 'Deferred'}
                                              </td>
                                              <td>
                                                {pm?.dueDate
                                                  ? moment(pm?.dueDate).format('DD.MM.YYYY')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.payment_reminder?.length > 0
                                                  ? moment(
                                                      pm?.payment_reminder[0]?.reminderDate
                                                    ).format('DD.MM.YYYY')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.amountPaid?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                              <td>
                                                {pm?.datePaid
                                                  ? moment(pm?.datePaid).format('DD.MM.YYYY')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.outstandingAmount <= 0 ? '' : '-'}
                                                {parseFloat(
                                                  getPositive(pm?.outstandingAmount)
                                                )?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                              <td>
                                                {pm?.paymentMethod == 0
                                                  ? 'Card'
                                                  : pm?.paymentMethod == 1
                                                  ? 'Cheque'
                                                  : pm?.paymentMethod == 2
                                                  ? 'Bank'
                                                  : 'Cash'}
                                              </td>
                                            </tr>

                                            {pm?.payment_sub_item?.length > 0 &&
                                            show[ind] == true ? (
                                              pm?.payment_sub_item.map((pmSub: any, index: any) => {
                                                return (
                                                  <>
                                                    <tr
                                                      style={{
                                                        borderBottomStyle: 'none',
                                                        height: '30px',
                                                      }}
                                                    >
                                                      <td style={{paddingLeft: '5px'}}>
                                                        {pm.id}
                                                        {'_'}
                                                        {pmSub?.id}
                                                      </td>
                                                      <td>{pmSub?.name}</td>
                                                      <td>
                                                        {pmSub?.isLatePaymentFeesRecord
                                                          ? pmSub?.amount?.toLocaleString('en-US', {
                                                              minimumFractionDigits: 2,
                                                            })
                                                          : (
                                                              pmSub?.amount * pmSub?.quantity
                                                            )?.toLocaleString('en-US', {
                                                              minimumFractionDigits: 2,
                                                            })}
                                                      </td>
                                                      <td></td>
                                                      <td>
                                                        {pmSub.paymentStatus === 0
                                                          ? 'Unpaid'
                                                          : pmSub.paymentStatus === 1
                                                          ? 'Paid'
                                                          : pmSub.paymentStatus === 2
                                                          ? 'Partially paid'
                                                          : pmSub.paymentStatus === 3
                                                          ? 'Void'
                                                          : 'Draft'}
                                                      </td>
                                                      <td></td>
                                                      <td></td>
                                                      <td></td>

                                                      <td>
                                                        {pmSub?.amountPaid?.toLocaleString(
                                                          'en-US',
                                                          {
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </td>
                                                      <td>
                                                        {pmSub?.datePaid
                                                          ? moment(pmSub?.datePaid).format(
                                                              'DD.MM.YYYY'
                                                            )
                                                          : '-'}
                                                      </td>
                                                      <td>
                                                        {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                                        {parseFloat(
                                                          getPositive(pmSub?.outstandingAmount)
                                                        )?.toLocaleString('en-US', {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                    </tr>
                                                    {index == pm?.payment_sub_item?.length - 1 && (
                                                      <tr style={{height: '30px'}}>
                                                        <td></td>
                                                        <td>{' Total VAT'}</td>
                                                        <td>
                                                          {pm?.totalVAT?.toLocaleString('en-US', {
                                                            minimumFractionDigits: 2,
                                                          })}
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </>
                                                )
                                              })
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-100px'>#</th>
                                        <th className='min-w-150px '>Payment</th>
                                        <th className='min-w-150px'>Amount</th>
                                        <th className='min-w-150px'>Date Created</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Category</th>
                                        <th className='min-w-150px'>Due</th>
                                        <th className='min-w-150px'>Reminder</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-100px'>Date Paid</th>
                                        <th className='min-w-150px'>Oustanding</th>
                                        <th className='min-w-100px'>Method</th>
                                        {/* <th className='min-w-100px'>Invoice</th> */}
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      <tr>
                                        <td colSpan={10} className='text-center'>
                                          <img src={noData} alt='' width={350} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              {/* Pagination */}
                              <div className='row mb-5'>
                                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
                                  <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                  >
                                    <ReactPaginate
                                      breakLabel='...'
                                      nextLabel='>'
                                      pageClassName='paginate_button page-item +'
                                      pageLinkClassName='page-link'
                                      containerClassName='pagination'
                                      activeClassName='active'
                                      previousClassName='paginate_button page-item previous'
                                      nextClassName='paginate_button page-item next'
                                      previousLinkClassName='page-link'
                                      nextLinkClassName='page-link'
                                      onPageChange={handlePageClick1}
                                      pageRangeDisplayed={5}
                                      pageCount={pageLimit1}
                                      previousLabel='<'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Messages Tab */}
                        {propertiType === 'Messages' && (
                          <>
                            <div className='card pt-0 mb-6 mb-xl-9'>
                              <div className='card-header border-0 px-3'>
                                <div className='card-title d-flex justify-content-between w-100'>
                                  <h2></h2>
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                    onClick={() => setNewMessageModal(true)}
                                  >
                                    New Message
                                  </a>
                                </div>
                              </div>
                              <div className='card-body pt-0 pb-5 px-3'>
                                <div className='table-responsive'>
                                  <table
                                    className='table align-middle table-row-dashed gy-5'
                                    id='kt_table_users_login_session'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className=' min-w-75px'>ID </th>
                                        <th className=' min-w-100px'>Subject</th>
                                        <th className=' min-w-100px'>Date</th>
                                        <th className=' min-w-150px'>Messages Received</th>
                                        <th className=' min-w-100px'></th>
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      {messageData?.chat_room_data?.map((i: any) => {
                                        return (
                                          <tr>
                                            <td>{i?.id}</td>
                                            <td>{i?.subjectTitle}</td>
                                            <td>{moment(i?.date).format('YYYY-MM-DD')}</td>
                                            <td>{i?.messageReceived}</td>
                                            <td>
                                              <BsArrowRightSquareFill
                                                color='#007a59'
                                                fontSize={18}
                                                className='mx-3 cursor-pointer'
                                              />
                                              {/* <a
                                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                              // onClick={() => handleViewMessage(i?._id, i?.tenantId)}
                                              // onClick={() => {
                                              //   navigate(-1)
                                              // }}
                                            >
                                              View
                                            </a> */}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Requests tab */}
                        {propertiType === 'Requests' && (
                          <>
                            <div className='p-0 ' style={{backgroundColor: 'none'}}>
                              <div className='my-5' style={{backgroundColor: 'none'}}>
                                <div className='card card-flush px-5 py-2 h-100'>
                                  <div className='row mt-5'>
                                    <div className='mb-2 col-md-1 pe-5 xyz'>
                                      <label
                                        className='fs-7 fw-semibold text-gray-600 pt-3'
                                        style={{fontWeight: '600'}}
                                      >
                                        {' '}
                                        Filter{' '}
                                      </label>
                                    </div>
                                    <div className='col-md-3'>
                                      <h6>
                                        <select
                                          className='form-select form-select-solid'
                                          value={
                                            tenantSelectedUnit?._id +
                                            '.' +
                                            tenantSelectedUnit?.unitNo
                                          }
                                          placeholder={'Select Unit...'}
                                          onChange={(e) => {
                                            let values = {...tenantSelectedUnit}

                                            values = {
                                              _id: e.target.value.split('.')[0],
                                              unitNo: e.target.value.split('.')[1],
                                            }

                                            setTenantSelectedUnit(values)

                                            // setTimeout(() => {
                                            //   getRequests()
                                            //   getPayments()
                                            //   getPaymentSummary()
                                            // }, 2500)
                                          }}
                                          name='units'
                                        >
                                          {tenantUnits?.length > 0 ? (
                                            <>
                                              {tenantUnits?.map((unit: any, index: any) => {
                                                return (
                                                  <option value={unit?._id + '.' + unit?.unitNo}>
                                                    {unit?.unitNo}
                                                  </option>
                                                )
                                              })}
                                            </>
                                          ) : (
                                            <option value='' selected disabled>
                                              No Record
                                            </option>
                                          )}
                                        </select>
                                      </h6>
                                    </div>

                                    <div className='col-md-3'>
                                      <h6>
                                        <select
                                          className='form-select form-select-solid'
                                          value={
                                            selectedTenancy?._id + '.' + selectedTenancy?.contractNo
                                          }
                                          onChange={(e) => {
                                            console.log(e.target.value)

                                            let values = {...selectedTenancy}

                                            values = {
                                              _id: e.target.value.split('.')[0],
                                              contractNo: e.target.value.split('.')[1],
                                            }
                                            console.log(values)

                                            setSelectedTenancy(values)

                                            // setTimeout(() => {
                                            //   getRequests()
                                            //   getPayments()
                                            //   getPaymentSummary()
                                            // }, 2500)
                                          }}
                                          name='tenancies'
                                        >
                                          {tenancies?.length > 0 ? (
                                            <>
                                              {tenancies?.map((tn: any, index: any) => {
                                                // console.log(tn)
                                                return (
                                                  <option value={tn?._id + '.' + tn?.contractNo}>
                                                    {tn?.contractNo}
                                                  </option>
                                                )
                                              })}
                                            </>
                                          ) : (
                                            <option value='' selected disabled>
                                              No Record
                                            </option>
                                          )}
                                        </select>
                                      </h6>
                                    </div>
                                  </div>

                                  <div className='table-responsive mb-5'>
                                    <table
                                      className='table align-middle table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                          <th className='text-start min-w-50px'>ID</th>
                                          <th className='text-start min-w-100px'>Type</th>
                                          <th className='text-start min-w-100px'>Date</th>
                                          <th className='text-start min-w-100px'>Status</th>
                                          <th className='text-start min-w-100px'></th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold text-gray-600'>
                                        {requests?.length > 0 ? (
                                          <>
                                            {requests?.map((rq: any, i: any) => {
                                              return (
                                                <>
                                                  <tr style={{height: '35px'}}>
                                                    <td
                                                      data-kt-ecommerce-order-filter='order_id'
                                                      className='text-start'
                                                    >
                                                      {rq?.id}
                                                    </td>
                                                    <td className='text-start'>
                                                      {' '}
                                                      <span
                                                      // style= {{padding: '5px'}}
                                                      // className={
                                                      //    rq?.type == 0
                                                      //     ? 'active-color'
                                                      //     :  rq?.type == 5
                                                      //     ? 'ended-color'
                                                      //     : rq?.type == 2
                                                      //     ? 'renewed-color'
                                                      //     :  rq?.type == 1
                                                      //     ? 'terminated-color'
                                                      //     : ''
                                                      // }
                                                      ></span>
                                                      <span>
                                                        {' '}
                                                        {/* <b className='my-2'> */}
                                                        {rq?.type == 0
                                                          ? 'Move In'
                                                          : rq?.type == 1
                                                          ? 'Move Out'
                                                          : rq?.type == 2
                                                          ? 'Tenancy Renewal'
                                                          : rq?.type == 3
                                                          ? 'Tenancy Termination'
                                                          : rq?.type == 4
                                                          ? 'General Complaint'
                                                          : rq?.type == 5
                                                          ? 'Other'
                                                          : rq?.type == 6
                                                          ? 'Maintenance'
                                                          : rq?.type == 7
                                                          ? 'Corporate Maintenance'
                                                          : ''}
                                                        {/* </b> */}
                                                      </span>
                                                    </td>
                                                    <td
                                                      data-kt-ecommerce-order-filter='order_id'
                                                      className='text-start'
                                                    >
                                                      {rq?.createdAt
                                                        ? moment(rq?.createdAt).format('DD/MM/YYYY')
                                                        : '-'}
                                                    </td>

                                                    <td className='text-start'>
                                                      {rq?.status === 0
                                                        ? 'Received'
                                                        : rq?.status === 1
                                                        ? 'Tenant pending approval'
                                                        : rq?.status === 2
                                                        ? 'Tenant Approved'
                                                        : rq?.status === 3
                                                        ? 'Rejected'
                                                        : rq?.status === 4
                                                        ? 'Tenant Cancelled'
                                                        : rq?.status === 5
                                                        ? 'Tenant Rejected'
                                                        : rq?.status === 6
                                                        ? 'Accepted'
                                                        : rq?.status === 7
                                                        ? 'Booked'
                                                        : rq?.status === 8
                                                        ? 'Variation Request'
                                                        : 'Canceled'}
                                                    </td>

                                                    <td
                                                      className='text-start'
                                                      data-order='2022-09-11'
                                                    >
                                                      <BsArrowRightSquareFill
                                                        color='#007a59'
                                                        fontSize={18}
                                                        className='mx-3 cursor-pointer'
                                                        onClick={() => {
                                                          dispatch(setComefrom('tenant-req'))
                                                          navigate(
                                                            `/view-request/${rq?._id}/${rq?.type}`
                                                          )
                                                        }}
                                                      />
                                                    </td>
                                                  </tr>
                                                </>
                                              )
                                            })}
                                          </>
                                        ) : (
                                          <tr>
                                            <td colSpan={15} className='text-center'>
                                              <img src={noData} alt='' width={350} />
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end mt-3'>
                                    <div
                                      className='dataTables_paginate paging_simple_numbers'
                                      id='kt_ecommerce_sales_table_paginate'
                                    >
                                      <ReactPaginate
                                        breakLabel='...'
                                        nextLabel='>'
                                        pageClassName='paginate_button page-item +'
                                        pageLinkClassName='page-link'
                                        containerClassName='pagination'
                                        activeClassName='active'
                                        previousClassName='paginate_button page-item previous'
                                        nextClassName='paginate_button page-item next'
                                        previousLinkClassName='page-link'
                                        nextLinkClassName='page-link'
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel='<'
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {messageList && (
                    <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                      <div className='card' id='kt_chat_messenger'>
                        <div className='card-header' id='kt_chat_messenger_header'>
                          <div className='card-title'>
                            <div className='d-flex justify-content-center flex-column me-3'>
                              <a
                                href='#'
                                className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                              >
                                Brian Cox
                              </a>
                              <div className='mb-0 lh-1'>
                                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                                {isConnected && (
                                  <span className='fs-7 fw-semibold text-muted'>Active</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='card-toolbar'>
                            <div className='me-n3'>
                              <button
                                className='btn btn-sm btn-icon btn-active-light-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                              >
                                <i className='bi bi-three-dots fs-2'></i>
                              </button>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                                data-kt-menu='true'
                              >
                                <div className='menu-item px-3'>
                                  <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                                    Contacts
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <a href='#' className='menu-link px-3'>
                                    Add Contact
                                  </a>
                                </div>
                                <div className='menu-item px-3'>
                                  <a href='#' className='menu-link flex-stack px-3'>
                                    Invite Contacts
                                    <i
                                      className='fas fa-exclamation-circle ms-2 fs-7'
                                      data-bs-toggle='tooltip'
                                      aria-label='Specify a contact email to send an invitation'
                                      data-bs-original-title='Specify a contact email to send an invitation'
                                      data-kt-initialized='1'
                                    ></i>
                                  </a>
                                </div>
                                <div
                                  className='menu-item px-3'
                                  data-kt-menu-trigger='hover'
                                  data-kt-menu-placement='right-start'
                                >
                                  <a href='#' className='menu-link px-3'>
                                    <span className='menu-title'>Groups</span>
                                    <span className='menu-arrow'></span>
                                  </a>
                                  <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                                    <div className='menu-item px-3'>
                                      <a
                                        href='#'
                                        className='menu-link px-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-original-title='Coming soon'
                                        data-kt-initialized='1'
                                      >
                                        Create Group
                                      </a>
                                    </div>
                                    <div className='menu-item px-3'>
                                      <a
                                        href='#'
                                        className='menu-link px-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-original-title='Coming soon'
                                        data-kt-initialized='1'
                                      >
                                        Invite Members
                                      </a>
                                    </div>
                                    <div className='menu-item px-3'>
                                      <a
                                        href='#'
                                        className='menu-link px-3'
                                        data-bs-toggle='tooltip'
                                        data-bs-original-title='Coming soon'
                                        data-kt-initialized='1'
                                      >
                                        Settings
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className='menu-item px-3 my-1'>
                                  <a
                                    href='#'
                                    className='menu-link px-3'
                                    data-bs-toggle='tooltip'
                                    data-bs-original-title='Coming soon'
                                    data-kt-initialized='1'
                                  >
                                    Settings
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body' id='kt_chat_messenger_body'>
                          <div
                            className='scroll-y me-n5 pe-5 h-300px h-lg-auto'
                            data-kt-element='messages'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                            data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
                            data-kt-scroll-offset='5px'
                            style={{maxHeight: '335px'}}
                          >
                            {messagechat.map((item: any) => (
                              <div className='d-flex justify-content-end mb-10'>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex align-items-center mb-2'>
                                    <div className='me-3'>
                                      <span className='text-muted fs-7 mb-1'>2 Hours</span>
                                      <a
                                        href='#'
                                        className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                                      >
                                        You
                                      </a>
                                    </div>
                                    <div className='symbol symbol-35px symbol-circle'>
                                      <img
                                        alt='Pic'
                                        src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg'
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                                    data-kt-element='message-text'
                                  >
                                    {item?.message}
                                  </div>
                                </div>
                              </div>
                            ))}
                            {/* // <div className="d-flex justify-content-start mb-10">
                        //   <div className="d-flex flex-column align-items-start">
                        //     <div className="d-flex align-items-center mb-2">
                        //       <div className="symbol symbol-35px symbol-circle">
                        //         <img alt="Pic" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg" />
                        //       </div>
                        //       <div className="ms-3">
                        //         <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                        //         <span className="text-muted fs-7 mb-1">2 mins</span>
                        //       </div>
                        //     </div>
                        //     <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                        //   </div>
                        // </div> */}
                          </div>
                        </div>

                        <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
                          <form>
                            <textarea
                              className='form-control form-control-flush mb-3'
                              rows={1}
                              data-kt-element='input'
                              placeholder='Type a message'
                              onChange={(e) => setSendMessage(e.target.value)}
                            ></textarea>
                            <div className='d-flex flex-stack'>
                              <div></div>
                              <input
                                type='reset'
                                // onClick={() => setShowCreateAppModal(true)}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                // onClick={handleSendMessage}
                                value='Send'
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
      <NewMessage
        show={newMessageModal}
        tenantdata={tenantData}
        getMessageList={getMessageList}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      />

      {/* <Modal show={folderNameModal} onHide={() => setFolderNameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Folder </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
              Input Folder Name
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              onChange={(e: any) => setFolderName(e.target.value)}
            />
            {fileFound ? (
              <p className='mt-2' style={{color: 'red'}}>
                Folder name already exists
              </p>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => addFolder()}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={folderName?.trim()?.length == 0 ? true : false}
          >
            Add {''}
          </Button>
        </Modal.Footer>
      </Modal> */}

      {dataToEdit && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={tenantSelectedUnit}
          detailsToEdit={dataToEdit}
          type={'tenant'}
        />
      )}

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => {
            setIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={unitDetails}
          tnancy={selectedTenancy?._id}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </div>
  )
}

export default ViewTenant

import React, {useEffect, useState} from 'react'

const LabelTitle = (props: any) => {
  return (
    <>
      <label className='required gray_color'>{props.title}</label>
    </>
  )
}

export default LabelTitle

import {DatePicker, TimePicker} from 'antd'
import moment from 'moment'
import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaPopUp from './MediaPopUp'
import noData from '../../../img/NoData1.svg'
import leftArrow from '../../../img/arrowleft.svg'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import editDate from '../../../img/edit.svg'
import Datechange from './Datechange'

const VendorList = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({})
  const [requestData, setRequestData] = useState<any>({})
  const [mainService, setMainService] = useState<any>()
  const [subService, setSubService] = useState<any>()
  const [requestTab, setRequestTab] = useState<any>([1])
  const [activeTab, setActiveTab] = useState<any>(1)
  const [time, setTime] = useState<any>(null)
  const [staffData, setStaffData] = useState<any>()
  const [vendorList, setVendorList] = useState<any>()
  const [servicedata, setServicedata] = useState<any>()
  const currenttab: any = localStorage.getItem('currentTab')
  const [date, setDate] = useState<any>()
  const totalTab: any = localStorage.getItem('totalTab')
  const [requestUrgency, setrequestUrgency]: any = useState()
  const userData: any = localStorage.getItem('userData')
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [page, setPage] = React.useState<any>(1)
  const [propertyList, setPropertyList] = React.useState<any>([])
  const [newDate, setNewDate] = React.useState<any>(null)
  const [jobId, setJobId] = React.useState<any>()
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [modalData, setModalData] = useState<any>()
  const[unitNo, setUnitNo] =  useState<any>()

  const [schedule, setSchedule] = useState<any>([
    {preferScheduleDate: '', preferScheduleTime: ''},
    {preferScheduleDate: '', preferScheduleTime: ''},
  ])
  const data = JSON.parse(userData)
  const requestType = localStorage.getItem('requestType')
  // const requestServiceId: any = localStorage.getItem(activeTab)
  const requestServiceId: any = localStorage.getItem('serviceRequestId')
  let requestorType: any = localStorage.getItem('requestorType')

  let inter: any = localStorage.getItem('inter')
  const format = 'HH:mm'

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  // const handleChangeTime = async (time: any, timeString: any) => {
  //   let hour = timeString.split(':')[0]
  //   let minute = timeString.split(':')[1]
  //   let replaceZero = minute.replace(/^0+/, '')
  //   let preferScheduleTime = parseInt(hour) * 60 + parseInt(replaceZero)

  //   setDate({...date, preferScheduleTime: preferScheduleTime})
  //   const body = {
  //     id: requestServiceId,
  //     requestType: 1,
  //     schedule: [
  //       {
  //         preferScheduleDate: date?.preferScheduleDate,
  //         preferScheduleTime: preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  // const addDate = async (e: any) => {
  //   const {name, value} = e.target
  //   setDate({...date, preferScheduleDate: value})
  //   setNewDate(value)
  //   const body = {
  //     id: requestServiceId,
  //     schedule: [
  //       {
  //         preferScheduleDate: value,
  //         preferScheduleTime: date?.preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }
  // const addComment = async (e: any) => {
  //   const body = {
  //     id: requestServiceId,
  //     comment: e,
  //   }
  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }
  const removeTab = async (item: any) => {
    const secondIndex = requestTab.findIndex((data: any) => data === item)
    requestTab.splice(secondIndex, 1)
    if (requestTab.length === 0) {
      setRequestTab([1])
      setActiveTab(1)
      localStorage.setItem('totalTab', '1')
    } else {
      setActiveTab(requestTab[0])
      localStorage.setItem('totalTab', requestTab[requestTab.length - 1])
      localStorage.removeItem(activeTab)
    }
    if (requestServiceId) {
      await ApiDelete(`corporate/service_request/${requestServiceId}`)
        .then((res) => {
          SuccessToast(res?.data?.message)
          localStorage.removeItem(activeTab)
          localStorage.setItem('currenttab', activeTab)
          setRequestData({comment: ''})
          setMainService({name: ''})
          setSubService({name: ''})
          setStaffData({name: ''})
          setDate({preferScheduleDate: ''})
          setTime({preferScheduleTime: ''})
          setServicedata('')
          setrequestUrgency('')
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // getRequestDataById()
    navigate(`/request-management/service`)
  }
  const getTotalMinutes = (totalMinutes: any) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    const momentTime = moment().hour(hours).minute(minutes)
    const formattedTime = momentTime.format('HH:mm')
    return formattedTime
  }
  const getRequestDataById = async () => {
    await ApiGet(`corporate/service_request/${requestServiceId}`)
      .then((res) => {
        if (res?.data?.data[0]?.requestType === 1) {
          // res?.data?.data[0]?.schedule.forEach((element: any) => {
          //   Object.assign(element, {
          //     time: getTotalMinutes(element?.preferScheduleTime),
          //     date: new Date(element?.preferScheduleDate),
          //   })
          // })

          setRequestData(res?.data?.data[0])
          setSchedule(res?.data?.data[0]?.schedule)

          setMainService(res?.data?.data[0]?.main_service[0])
          setSubService(res?.data?.data[0]?.sub_service[0])
          setStaffData(res?.data?.data[0]?.staff[0])
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          setDate(res?.data?.data[0]?.schedule)
          setTime(res?.data?.data[0]?.schedule)
          setVendorList(res?.data?.data[0]?.vendor[0])
          localStorage.setItem('requestType', res?.data?.data[0]?.requestType)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getServiceJob = async () => {
    const body = {
      serviceRequestId: requestServiceId,
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    if (requestServiceId) {
      getRequestDataById()
      getServiceJob()
      serviceRequestData()
    }
  }, [])

  React.useEffect(() => {
    if (activeTab === currenttab && localStorage.getItem(currenttab) !== '') {
      getRequestDataById()
    }
    localStorage.setItem('currentTab', activeTab)
    if (totalTab === null) {
      localStorage.setItem('totalTab', activeTab)
    }
    let total = []
    var i
    for (i = 1; i <= Number(totalTab); i++) {
      total.push(i)
    }
    setRequestTab(total)
  }, [activeTab])

  const saveAll = () => {
    SuccessToast('Save all request Successfully')
    localStorage.removeItem('serviceRequest')
    localStorage.removeItem('requestType')
    var i: any
    for (i = 1; i <= totalTab; i++) {
      localStorage.removeItem(i)
    }
    localStorage.setItem('currentTab', '1')
    localStorage.setItem('totalTab', '1')
    navigate('/request-management/service')
  }

  const addMoreService = () => {
    setRequestTab([...requestTab, requestTab[requestTab.length - 1] + 1])
    setActiveTab(requestTab[requestTab.length - 1] + 1)
    setRequestData('')
    setMainService('')
    setSubService('')
    setStaffData('')
    setServicedata('')
    setrequestUrgency('')
    setDate({preferScheduleDate: ''})
    setTime({preferScheduleTime: ''})
    setPropertyList([])
    setVendorList({firstName: '', lastName: ''})
    localStorage.setItem('currentTab', requestTab[requestTab.length - 1] + 1)
    localStorage.setItem('totalTab', requestTab[requestTab.length - 1] + 1)
  }

  const setTab = (item: any) => {
    setRequestData({comment: ''})
    setMainService({name: ''})
    setSubService({name: ''})
    setStaffData({name: ''})
    setDate({preferScheduleDate: ''})
    setTime({preferScheduleTime: ''})
    setServicedata('')
    setVendorList({firstName: '', lastName: ''})
    setrequestUrgency('')
    setActiveTab(item)
    setPropertyList([])
  }

  const saveStatus = async () => {
    if (requestServiceId) {
      const body = {
        id: requestServiceId,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: 1,
        saveStatus: 0,
        comment: formData?.comment,
        vendorId: vendorList?._id,
        schedule,
      }
      await ApiPut(`corporate/service_request`, body)
        .then((res) => {
          SuccessToast(res.data.message)
          localStorage.removeItem('serviceRequest')
          localStorage.removeItem('requestType')
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: 1,
        preferScheduleDate: date?.preferScheduleDate,
        preferScheduleTime: parseInt(time),
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: 1,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  const submitRequest = async () => {
    if (requestServiceId) {
      const body = {
        id: requestServiceId,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: 1,
        saveStatus: 1,
        comment: formData?.comment,
        vendorId: vendorList?._id,
        schedule,
      }
      await ApiPut(`corporate/service_request`, body)
        .then((res) => {
          SuccessToast(res.data.message)
          localStorage.removeItem('serviceRequest')
          localStorage.removeItem('requestType')
          navigate(
            `/request-management/service/create-request-service/approve-request/${requestServiceId}`
          )
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: 1,
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: 1,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(
            `/request-management/service/create-request-service/approve-request/${requestServiceId}`
          )
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // navigate(`/request-management/service/external-service/${requestServiceId}`)
  }

  const serviceRequestData = async () => {
    const body = {
      page: page,
      limit: 5,
      serviceRequestId: requestServiceId,
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        // navigate(-1)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  React.useEffect(() => {
    if (requestServiceId && requestType === '1') {
      // getServiceJob()
      serviceRequestData()
    }

    if (!localStorage.getItem(currenttab)) {
      localStorage.setItem('currentTab', '1')
      localStorage.setItem('totalTab', '1')
      setRequestTab([1])
      setActiveTab(1)
    }
  }, [requestType, page])
  const [mediaPop, setMediaPop] = useState<any>()
  const openModal = async (f: any) => {
    setMediaPopup(true)
    setJobId(f)

    await ApiGet(`corporate/service_job/${f?._id}`)
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMediaPop(res.data.data[0])
      })
      .catch((err) => ErrorToast(err.message))
  }
  const checkDate = (val: any) => {
    if (val) {
      val = Number(val)
      // let date: any = moment(val).format('HH:mm')
      const hours: any = moment.duration(val, 'minutes').hours().toString()
      const minutes: any = moment.duration(val, 'minutes').minutes().toString()
      return `${hours}:${minutes}`
    } else {
      return ''
    }
  }
  const setScheduleEvent = (e: any, int: any, flg: any) => {
    if (flg === 'preferScheduleTime') {
      var hoursInMinutes = e.hours() * 60 // Convert hours to minutes
      const minutes = e.minutes() // Get the remaining minutes
      const totalMinutes = hoursInMinutes + minutes
      schedule[int][flg] = totalMinutes
    } else {
      schedule[int][flg] = e
    }
    setSchedule(schedule)
  }

  function timeConvert(n: any) {
    var num = n
    var hours = num / 60
    var rhours = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    return rhours + ':' + rminutes
  }
  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 px-5'>
            <div className='d-flex align-items-center gap-5'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <img src={leftArrow} style={{stroke: 'red'}} />
              </span>
              <h1 className='page-heading green_color m-0'>External Service Request</h1>
            </div>
          </div>
          {/* <div className='grid-out p-5 container-xxl'>
            <div
              className='card tops p-5 mt-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex m-0'>
                <div className='py-2'>
                  <a
                    // onClick={() => setShowCreateAppModal(true)}
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    onClick={() => addMoreService()}
                  >
                    +
                  </a>
                </div>
                <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                  {requestTab.map((item: any) => {
                    return (
                      <li
                        className='nav-item'
                        onClick={() => setTab(item)}
                        // onClick={() => {
                        //     navigate(`/request-management/service/create-request-service`)
                        // }}
                      >
                        <a
                          className={`${
                            activeTab === item ? 'active' : ''
                          } nav-link text-active-primary pb-4`}
                          data-bs-toggle='tab'
                        >
                          Request {item}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className='d-flex flex-stack m-0'>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app'
                  // onClick={() => navigate('/request-management/service')}
                  onClick={saveAll}
                >
                  Save All
                </a>
              </div>
            </div>
          </div> */}

          {requestTab.map((item: any) => {
            return (
              <>
                <div className={`${activeTab === item ? 'd-show' : 'd-none'}`}>
                  <div className='grid-out p-5 container-xxl'>
                    <div
                      className='card tops p-5 mt-0'
                      style={{justifyContent: 'space-between', flexDirection: 'row'}}
                    >
                      <div className='d-flex'>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                          onClick={() => saveStatus()}
                        >
                          Save
                        </a>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          onClick={() => removeTab(item)}
                        >
                          Delete
                        </a>
                        {inter == 'true' ? (
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => {
                              navigate(
                                `/request-management/service/create-request-service-internal/0`
                              )
                            }}
                          >
                            Internal
                          </a>
                        ) : null}
                      </div>
                      <div className='' style={{textAlign: 'end'}}>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                          onClick={() => submitRequest()}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                    <div className='card grid px-5 py-3 side'>
                      <h1 className='top green_color'>Service Details</h1>
                      <h6 className='text-muted border-outline mx-0 my-2'>
                        Request ID <div className='mx-1 value_black'>{requestData?.id}</div>
                      </h6>
                      <h6 className='text-muted mx-0 my-2'>
                        Requestor <div className='mx-1 value_black'>{data?.fullName}</div>
                      </h6>
                      <h6
                        className='text-muted border-outline mx-0 '
                        style={{alignItems: 'center'}}
                      >
                        <span className='required'>Main Service</span>
                        <input
                          type='text'
                          className='form-control form-control-solid py-2'
                          name='name'
                          value={mainService?.name}
                          disabled
                          onChange={(e) => setMainService({...mainService, name: e.target.value})}
                        />
                      </h6>

                      <h6
                        className='text-muted border-outline mx-0 '
                        style={{alignItems: 'center'}}
                      >
                        <span className='required'>Sub Service</span>
                        <div className='d-flex m-0 p-0'>
                          <input
                            type='text'
                            className='form-control form-control-solid py-2'
                            name='subService'
                            value={subService?.name}
                            onChange={(e) => setSubService({...subService, name: e.target.value})}
                          />
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            onClick={() =>
                              navigate(
                                '/request-management/service/vendor-list/propertise-service-list'
                              )
                            }
                          >
                            Select
                          </a>
                        </div>
                      </h6>
                      <h6 className='text-muted border-outline mx-0' style={{alignItems: 'center'}}>
                        Request Urgency
                        <div className='d-flex'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                            <input
                              className='form-check-input '
                              type='radio'
                              value={requestUrgency === 0 ? 0 : 0}
                              id='requestUrgency'
                              name='requestUrgency'
                              onChange={(e) =>
                                setrequestUrgency({
                                  ...requestUrgency,
                                  requestUrgency: e.target.value,
                                })
                              }
                              checked
                            />
                            <label
                              className='form-check-label required value_black'
                              htmlFor='requestUrgency'
                              style={{marginRight: '6px'}}
                            >
                              Standard
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={requestUrgency === 1 ? 1 : 1}
                              name='requestUrgency'
                              onChange={(e) =>
                                setrequestUrgency({
                                  ...requestUrgency,
                                  requestUrgency: e.target.value,
                                })
                              }
                            />
                            <label
                              className='form-check-label required value_black'
                              htmlFor='Internally'
                              style={{marginRight: '6px'}}
                            >
                              Emergency
                            </label>
                          </div>
                        </div>
                      </h6>
                      {/* <div className='m-0'>
                        <h6 className='text-muted'>Preferred Schedules For Service</h6>
                        {requestData?.schedule?.map((scg: any, id: any) => (
                          <div className='payment-receipt-item d-flex align-items-center mb-5'>
                            <label className='text-muted  mr-2 required'>Date</label>
                            <DatePicker
                              className='mx-1 form-control form-control-solid'
                              name='fromDate'
                              defaultValue={moment(scg?.date, 'DD/MM/YYYY')}
                              onChange={(e: any) =>
                                setScheduleEvent(new Date(e), id, 'preferScheduleDate')
                              }
                            />
                            <label className='text-muted mr-2 required'>Time</label>
                            <TimePicker
                              className='form-control form-control-solid'
                              size='large'
                              defaultValue={moment(scg?.time, 'HH:mm')}
                              onChange={(e: any) => setScheduleEvent(e, id, 'preferScheduleTime')}
                            />
                          </div>
                        ))}
                      </div> */}
                    </div>
                    <div className='card grid px-5 py-3 side'>
                      <h1 className='top green_color'>Service Provider Details</h1>
                      <div className='d-flex align-items-center me-10 mb-3'>
                        <h6
                          className='text-muted m-0 mr-2 min-w-100px mb-2'
                          style={{alignItems: 'center'}}
                        >
                          <span className='required'>Service Provider</span>
                          <div className='d-flex'>
                            <input
                              type='text'
                              className='form-control form-control-solid py-2 '
                              name='subNewServiceList'
                              onChange={(e) => setVendorList({...vendorList, name: e.target.value})}
                              value={`${
                                vendorList
                                  ? `${vendorList?.firstName || ''} ${vendorList?.lastName}`
                                  : ''
                              }`}
                            />
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              onClick={() =>
                                navigate('/request-management/service/service-provider-list')
                              }
                            >
                              Select
                            </a>
                          </div>
                        </h6>
                      </div>

                      <div className='mb-3'>
                        <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                          Request Details (For Selected Vendor)
                        </h6>
                        <textarea
                          className='form-control form-control-solid mb-8'
                          rows={3}
                          name='comment'
                          onChange={(e: any) => handleChnage(e)}
                        ></textarea>
                      </div>
                    </div>
                    <div className='card p-5 tops container-xxl'>
                      <div
                        className='d-flex align-item-center'
                        style={{justifyContent: 'space-between'}}
                      >
                        <h1 className='m-0 green_color'>
                          Properties
                          {requestorType !== '1' && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              onClick={() =>
                                navigate(
                                  '/request-management/service/create-request-service/select-property'
                                )
                              }
                            >
                              Select Properties
                            </a>
                          )}
                        </h1>
                        <h2
                          className='d-flex gap-5 flex-column align-items-left p-5 br-5'
                          style={{background: '#19ffbe1c'}}
                        >
                          Properties Selected
                          <div className='d-flex gap-5 align-items-center'>
                            <h5 className='m-0'>
                              Total Buildings : {servicedata?.total_building || 0}
                            </h5>
                            <h5 className='m-0'>
                              Total Communities : {servicedata?.total_community || 0}
                            </h5>
                            <h5 className='m-0'>Total Units : {servicedata?.total_units || 0}</h5>
                          </div>
                        </h2>
                      </div>
                      <div className='row mb-5'>
                        <div className='my-7 table-responsive'>
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>Job Id</th>
                                <th className='min-w-100px'>Development</th>
                                <th className='min-w-100px'>Floor/Cluster</th>
                                <th className='min-w-100px'>Unit</th>
                                <th className='min-w-100px'>Unit Type </th>
                                <th className='min-w-100px'>Unit Type </th>
                                <th className='min-w-200px'>Job Schedule</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {propertyList.length ? (
                                propertyList?.map((f: any, i: any) => {
                                  return (
                                    <tr className='text-center'>
                                      <td>{f?.id ?? '-'}</td>
                                      <td>{f?.communityId ? 'Community' : 'Building'}</td>
                                      <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                                      <td>{f?.unit[0]?.unitNo ?? '-'}</td>
                                      <td>{f?.unit[0]?.unitType ?? '-'}</td>
                                      <td>{f?.unit[0]?.bedrooms ?? '-'}</td>
                                      <td className='d-flex align-items-center gap-4 justify-content-center'>
                                        {f?.schedule?.[0]?.date
                                          ? moment(f?.schedule?.[0]?.date).format('DD/MM/YYYY')
                                          : null}{' '}
                                        {f?.schedule?.[0]?.startTime
                                          ? timeConvert(f?.schedule?.[0]?.startTime)
                                          : null}{' '}
                                        {f?.schedule?.[0]?.endTime
                                          ? timeConvert(f?.schedule?.[0]?.endTime)
                                          : null}{' '}
                                        <a
                                          onClick={() => {
                                            setShowCreateAppModal1(true)
                                            setModalData(f)
                                          }}
                                        >
                                          <img src={editDate} alt='' />
                                        </a>
                                      </td>
                                      <td>
                                        <button
                                          className='btn btn-sm fw-bold btn-primary btn-green hover-none min-w-150px'
                                          onClick={() => openModal(f)}
                                        >
                                          Media & Comments
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                          <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                          >
                            <ReactPaginate
                              breakLabel='...'
                              nextLabel='>'
                              pageClassName='paginate_button page-item +'
                              pageLinkClassName='page-link'
                              containerClassName='pagination'
                              activeClassName='active'
                              previousClassName='paginate_button page-item previous disabled'
                              nextClassName='paginate_button page-item next'
                              previousLinkClassName='page-link'
                              nextLinkClassName='page-link'
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageLimit}
                              previousLabel='<'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        propertyList={propertyList}
        handleClose={() => {
          setMediaPopup(false)
        }}
        id={jobId}
        // setFormData={setFormData}
        mediaPop={mediaPop}
      />
      <Datechange
        show={showCreateAppModal1}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        serviceRequestData={serviceRequestData}
        modalData={modalData}
      />
    </>
  )
}

export default VendorList

import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AssignJob from './AssignJob';
import MediaPopUp from './MediaPopUp';

function ScopeDetails() {
    const navigate = useNavigate()
    const [assignModal, setAssignModal] = useState<any>(false);
    const [formData, setFormData] = React.useState<any>({})
    const [showMedia, setShowMedia] = useState<any>(false)
    const [propertyList, setPropertyList] = React.useState<any>()

    const ScopeArr = new Array(2).fill(0);

    const handleChnage = (e:any) => { 
        if(e === 'assign'){
            setShowMedia(true)
        }
    }


    return (
        <>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>

                    <div className='card card-flush app-container py-5'>
                        <Row>
                            <div className='d-flex mb-5'>
                                <p className='m-0 me-10'>Service Name</p>
                                <p className='m-0 me-10'>Building/Community Name</p>
                                <p className='m-0 me-10'>## Properties</p>
                                <p className='m-0 me-10'>Handled By: Vendor Name</p>
                                <p className='m-0 me-10'>Building/Community Name</p>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-130px'>Message Vendor</button>
                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-130px'>Service Completion Reports</button>
                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-130px'>Rate Service</button>
                            </div>
                        </Row>

                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-center min-w-100px'>
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" value="" style={{ cursor: "pointer" }} />
                                            </div>
                                        </th>
                                        <th className='text-center min-w-100px'>Request ID</th>
                                        <th className='text-center min-w-100px'>Job ID </th>
                                        <th className='text-center min-w-100px'>Service Name</th>
                                        <th className='text-center min-w-100px'>Building Commty</th>
                                        <th className='text-center min-w-100px'>Area</th>
                                        <th className='text-center min-w-100px'>Unit</th>
                                        <th className='text-center min-w-100px'>Occupancy</th>
                                        <th className='text-center min-w-100px'>Assigned To</th>
                                        <th className='text-center min-w-100px'>Scheduled</th>
                                        <th className='text-center min-w-100px'>Job Urgency</th>
                                        <th className='text-center min-w-100px'>Status</th>
                                        <th className='text-center min-w-100px'></th>
                                        <th className='text-center min-w-100px'></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {ScopeArr.map((f, i) => {
                                        return (
                                            <tr>
                                                <td className='text-center min-w-100px'>
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" value="" style={{ cursor: "pointer" }} />
                                                    </div>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <select
                                                        name='assigned to'
                                                        aria-label='assigned to'
                                                        data-control='select2'
                                                        data-placeholder='date_period'
                                                        className='form-select form-select-sm form-select-solid'
                                                        onChange={(e) => handleChnage(e.target.value)}
                                                    >
                                                        <option value=''>select</option>
                                                        <option value='assign'>Assign</option>
                                                    </select>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <a
                                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-150px'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_create_app'
                                                        onClick={() => {
                                                            // navigate(`/request-management/service/scope-detail`)
                                                        }}
                                                    >
                                                        Message Tenant
                                                    </a>
                                                </td>
                                                <td>
                                                    <a
                                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3 min-w-150px'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_create_app'
                                                        onClick={() => setShowMedia(true)}
                                                    >
                                                        Media & Comments
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
{/* 
            <AssignJob
                show={assignModal}
                handleClose={() => {
                    setAssignModal(false)
                }}
                handleChnage={handleChnage}
            /> */}
{/* 
            <MediaPopUp
                show={showMedia}
                handleClose={() => {
                    setShowMedia(false)
                }}
                propertyList={propertyList}

            /> */}
        </>
    );
}

export default ScopeDetails;
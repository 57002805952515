import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation, useParams} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import backArrow from '../../../../img/back-arrow.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import unitClusterIcon from '../../../../img/unitClusterIcon.png'
import buildingClusterIcon from '../../../../img/buildingCluster.png'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import ClusterList from './ClusterList'
import CommonAreaList from './CommonAreaList'

interface SquareHandleProps {
  value: number
}

const CommunityDetailList = () => {
  const getAllBuildingDetailFilter: any = localStorage.getItem('communityDetailFilters')
  const buildingDetailFilter = JSON.parse(getAllBuildingDetailFilter)

  const getAllPropertiesSubTab: any = localStorage.getItem('subTabListItemForCommunityDetails')
  const subTab = JSON.parse(getAllPropertiesSubTab)

  const getAllPropertiesResidentialDate: any = localStorage.getItem(
    'communityDetailResidentialDateFilter'
  )
  const allPropertiesResidentialDate = JSON.parse(getAllPropertiesResidentialDate) ?? {}

  const getAllPropertiesAllUnitsDate: any = localStorage.getItem(
    'communityDetailAllUnitsDateFilter'
  )
  const allPropertiesAllUnitsDate = JSON.parse(getAllPropertiesAllUnitsDate) ?? {}

  const getAllPropertiesCommercialDate: any = localStorage.getItem(
    'communityDetailCommercialDateFilter'
  )
  const allPropertiesCommercialDate = JSON.parse(getAllPropertiesCommercialDate) ?? {}

  const getAllPropertiesCommonAreaDate: any = localStorage.getItem(
    'communityDetailCommonAreaDateFilter'
  )
  const allPropertiesCommonAreaDate = JSON.parse(getAllPropertiesCommonAreaDate) ?? {}

  const getCommunityDetailMixedClusterDate: any = localStorage.getItem(
    'communityDetailMixedClusterDateFilter'
  )
  const communityDetailMixedClusterDate = JSON.parse(getCommunityDetailMixedClusterDate) ?? {}

  const getCommunityDetailBuildingClusterDate: any = localStorage.getItem(
    'communityDetailBuildingClusterDateFilter'
  )
  const communityDetailBuildingClusterDate = JSON.parse(getCommunityDetailBuildingClusterDate) ?? {}

  const getCommunityDetailUnitClusterDate: any = localStorage.getItem(
    'communityDetailUnitClusterDateFilter'
  )
  const communityDetailUnitClusterDate = JSON.parse(getCommunityDetailUnitClusterDate) ?? {}

  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>(subTab ? subTab : 'allUnits')

  const [searchCommonArea, setSearchCommonArea] = useState<any>(
    buildingDetailFilter?.searchCommonArea ?? ''
  )
  
  const [searchUnitCluster, setSearchUnitCluter] = useState<any>(
    buildingDetailFilter?.filters?.searchUnitCluster ?? ''
  )
  const [searchBuildingCluster, setSearchBuildingCluter] = useState<any>(
    buildingDetailFilter?.filters?.searchBuildingCluster ?? ''
  )
  const [searchMixedCluster, setSearchMixedCluter] = useState<any>(
    buildingDetailFilter?.filters?.searchMixedCluster ?? ''
  )
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const {pathname, state}: any = useLocation()
  const {id} = useParams()

  // const isBuilding: any = location.state ? location?.state?.key === 'buildings' : false
  // const buildingDetail: any = location?.state

  const goToMiantenance = (v: any) => {
    if (subTabListItem === 'unitCluster') {
      navigate(`/properties/community-details/${id}/unit-cluster/${v?._id}`, {
        state: {
          type: v?.type,
          clusterName: v?.name,
          communityId: id,
          clusterId: v?._id,
          communityName: state?.communityName,
          area: state?.area,
          city: state?.city,
          from:'unitCluster',
          isCommonArea:v?.unitType == 2 ? true : false
         
        },
      })
    }
    if (subTabListItem === 'buildingCluster') {
      navigate(`/properties/community-details/${id}/building-cluster/${v?._id}`, {
        state: {
          type: v?.type,
          clusterName: v?.name,
          communityId: id,
          clusterId: v?._id,
          communityName: state?.communityName,
          area: state?.area,
          city: state?.city,
           from:'buildingCluster',
           isCommonArea:v?.unitType == 2 ? true : false
        },
      })
    }
    if (subTabListItem === 'mixedCluster') {
      navigate(`/properties/community-details/${id}/mixed-cluster/${v?._id}`, {
        state: {
          type: v?.type,
          clusterName: v?.name,
          communityId: id,
          clusterId: v?._id,
          communityName: state?.communityName,
          area: state?.area,
          city: state?.city,
           from:'mixedCluster',
           isCommonArea:v?.unitType == 2 ? true : false
        },
      })
    }
    if(subTabListItem === 'allUnits' ||
      subTabListItem === 'residentialUnits' ||
      subTabListItem === 'commercialUnits' ||
      subTabListItem === 'commonAreas'){
      navigate(`/property-profile/${v?._id}`,
        {
          state: {
          from: 'communityDetail', 
          communityName: state?.communityName,
          communityId: id,
          area:v?.community[0]?.area,
          city:v?.community[0]?.city,
          street:v?.community[0]?.street,
          isCommonArea:v?.unitType === 2 ? true : false
          },
        }
      )
    }
  }

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  function formatDate(date: any) {
    const day = ('0' + date.getDate()).slice(-2) // Get day with leading zero if needed
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Get month with leading zero if needed
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  function generateDates() {
    // Create start date for the year (1st January)
    const currentDate = new Date()
    const year: any = currentDate.getFullYear()
    const startDate: any = new Date(year, 0, 1)

    // Create end date for the year (31st December)
    const endDate = new Date(year, 11, 31)

    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate)

    if (subTabListItem === 'allUnits' && Object.keys(allPropertiesAllUnitsDate).length > 0) {
      if (Object?.values(allPropertiesAllUnitsDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesAllUnitsDate?.startDate))
        setEnd(moment(allPropertiesAllUnitsDate?.endDate))
        setStartDatePayload(moment(allPropertiesAllUnitsDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesAllUnitsDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'residentialUnits' &&
      Object.keys(allPropertiesResidentialDate).length > 0
    ) {
      if (Object?.values(allPropertiesResidentialDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesResidentialDate?.startDate))
        setEnd(moment(allPropertiesResidentialDate?.endDate))
        setStartDatePayload(moment(allPropertiesResidentialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesResidentialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'commercialUnits' &&
      Object.keys(allPropertiesCommercialDate).length > 0
    ) {
      if (Object?.values(allPropertiesCommercialDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesCommercialDate?.startDate))
        setEnd(moment(allPropertiesCommercialDate?.endDate))
        setStartDatePayload(moment(allPropertiesCommercialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesCommercialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'commonAreas' &&
      Object.keys(allPropertiesCommonAreaDate).length > 0
    ) {
      if (Object?.values(allPropertiesCommonAreaDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesCommonAreaDate?.startDate))
        setEnd(moment(allPropertiesCommonAreaDate?.endDate))
        setStartDatePayload(moment(allPropertiesCommonAreaDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesCommonAreaDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'unitCluster' &&
      Object.keys(communityDetailUnitClusterDate).length > 0
    ) {
      if (Object?.values(communityDetailUnitClusterDate).every((x) => x !== null || x !== '')) {
        setStart(moment(communityDetailUnitClusterDate?.startDate))
        setEnd(moment(communityDetailUnitClusterDate?.endDate))
        setStartDatePayload(moment(communityDetailUnitClusterDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(communityDetailUnitClusterDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'buildingCluster' &&
      Object.keys(communityDetailBuildingClusterDate).length > 0
    ) {
      if (Object?.values(communityDetailBuildingClusterDate).every((x) => x !== null || x !== '')) {
        setStart(moment(communityDetailBuildingClusterDate?.startDate))
        setEnd(moment(communityDetailBuildingClusterDate?.endDate))
        setStartDatePayload(
          moment(communityDetailBuildingClusterDate?.startDate)?.format('YYYY-MM-DD')
        )
        setEndDatePayload(moment(communityDetailBuildingClusterDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (
      subTabListItem === 'mixedCluster' &&
      Object.keys(communityDetailMixedClusterDate).length > 0
    ) {
      if (Object?.values(communityDetailMixedClusterDate).every((x) => x !== null || x !== '')) {
        setStart(moment(communityDetailMixedClusterDate?.startDate))
        setEnd(moment(communityDetailMixedClusterDate?.endDate))
        setStartDatePayload(
          moment(communityDetailMixedClusterDate?.startDate)?.format('YYYY-MM-DD')
        )
        setEndDatePayload(moment(communityDetailMixedClusterDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else {
      setStart(moment(formattedStartDate, 'DD/MM/YYYY'))
      setEnd(moment(formattedEndDate, 'DD/MM/YYYY'))
      setStartDatePayload(moment(formattedStartDate)?.format('YYYY-MM-DD'))
      setEndDatePayload(moment(formattedEndDate)?.format('YYYY-MM-DD'))
    }
  }

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [allPropertiesTableData, setAllPropertiesTableData] = useState<any>([])
  const [allClustersTableData, setAllClustersTableData] = useState<any>([])
  // const [allPropertiesTableData, setAllPropertiesTableData] = useState<any>([])
  const [limit, setLimit] = useState<any>(25)
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [unitCountSummery, setUnitCountSummary] = useState<any>()

  const [searchUnit, setSearchUnit] = useState<any>(buildingDetailFilter?.filters?.searchUnit ?? '')

  const [searchResidentialUnit, setSearcResidentialhUnit] = useState<any>(
    buildingDetailFilter?.searchResidentialUnit ?? ''
  )
  const [searchCommercialUnit, setSearchCommercialUnit] = useState<any>(
    buildingDetailFilter?.searchCommercialUnit ?? ''
  )

  const [searchValue, setSearchValue] = useState<any>(buildingDetailFilter?.searchUnit ?? '')
  const [searchValueResidential, setSearchValueResidential] = useState<any>(buildingDetailFilter?.searchResidentialUnit  ?? '')
  const [searchValueCommercial, setSearchValueCommercial] = useState<any>(buildingDetailFilter?.searchCommercialUnit  ?? '')
  const [searchValueCommonArea, setSearchValueCommonArea] = useState<any>(buildingDetailFilter?.searchCommonAreaUnit  ?? '')



  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    if (
      subTabListItem === 'unitCluster' ||
      subTabListItem === 'buildingCluster' ||
      subTabListItem === 'mixedCluster'
    ) {
      getAllClustersTableData(event.selected + 1)
    }
    if (
      ['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some(
        (unit: string) => unit === subTabListItem
      )
    ) {
      getAllPropertiesTableData(event.selected + 1)
    }
  }

  const unitCountSummary = async () => {
    const body = {
      isAllPropertise: false,
      isForBuildingDevelopment: false,
      isForCommunityDevelopment: true,
      unit: {
        propertyType:
          subTabListItem === 'allUnits'
            ? [0, 1]
            : subTabListItem === 'residentialUnits'
            ? [0]
            : subTabListItem === 'commercialUnits'
            ? [1]
            : subTabListItem === 'commonAreas'
            ? [0, 1]
            : [],
        unitType:
          subTabListItem === 'allUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'residentialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'commercialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem == 'commonAreas'
            ? [2]
            : [],
            searchUnitNo:
            subTabListItem === 'allUnits'
              ? searchUnit
              : subTabListItem === 'residentialUnits'
              ? searchResidentialUnit
              : subTabListItem === 'commercialUnits'
              ? searchCommercialUnit
              : subTabListItem === 'commonAreas'
              ? searchCommonArea : '',
      },
      cluster: {
        type:
          subTabListItem === 'unitCluster'
            ? [0]
            : subTabListItem === 'buildingCluster'
            ? [1]
            : subTabListItem === 'mixedCluster'
            ? [2]
            : [],
            searchClusterName: 
            subTabListItem === 'unitCluster' ? searchUnitCluster:
            subTabListItem === 'buildingCluster' ? searchBuildingCluster:
            subTabListItem === 'mixedCluster' ? searchMixedCluster  : ''
      },
      communityDevelopmentIds: [id],
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
    }
    await ApiPost('corporate/development/properties/unit_count', body)
      .then((res) => {
        setUnitCountSummary(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getAllPropertiesTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllPropertiesTableData([])

    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
      searchUnitNo:
        subTabListItem === 'allUnits'
          ? searchUnit
          : subTabListItem === 'residentialUnits'
          ? searchResidentialUnit
          : subTabListItem === 'commercialUnits'
          ? searchCommercialUnit
          : subTabListItem === 'commonAreas'
          ? searchCommonArea
          : '',
      propertyTypes:
        subTabListItem === 'allUnits'
          ? [0, 1]
          : subTabListItem === 'residentialUnits'
          ? [0]
          : subTabListItem === 'commercialUnits'
          ? [1]
          : [0, 1],
      unitTypes:
        subTabListItem === 'allUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'residentialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'commercialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem == 'commonAreas'
          ? [2]
          : [],
      buildingIds: [],
      communityIds: [id],
      floorIds: [],
      clusterIds: [],
      unitGroupIds: [],
    }

    await ApiPost('corporate/unit/get_properties', body)
      .then((res) => {
        setAllPropertiesTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getAllClustersTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllClustersTableData([])

    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
      search:
        subTabListItem === 'unitCluster'
          ? searchUnitCluster
          : subTabListItem === 'buildingCluster'
          ? searchBuildingCluster
          : subTabListItem === 'mixedCluster'
          ? searchMixedCluster
          : '',
      communityIds: [id],
    }

    await ApiPost('corporate/cluster/get_properties', body)
      .then((res) => {
        setAllClustersTableData(res?.data?.data?.cluster_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const handleEdit = () => {
    navigate('/properties/edit-development', {
      state: {
        isCommunity: true,
        communityId: id,
        isEdit: true,
        from: 'communityDetail',
        communityName: state?.communityName,
        area:state?.area,city:state?.city
      },
    })
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if( subTabListItem == 'allUnits') {
        setSearchUnit(searchValue);
      }
      if( subTabListItem == 'residentialUnits'){
        setSearcResidentialhUnit(searchValueResidential)
      }
      if(subTabListItem === 'commercialUnits'){
        setSearchCommercialUnit(searchValueCommercial)
      }
      if(subTabListItem == 'commonAreas'){
        setSearchCommonArea(searchValueCommonArea)
      }
    }, 500); // delay time in milliseconds
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue,searchValueResidential,searchValueCommercial,searchValueCommonArea]);

  useEffect(() => {
    unitCountSummary()
  },[startDatePayload,
    endDatePayload, 
    subTabListItem,
    searchMixedCluster,
    searchBuildingCluster,
    searchUnitCluster, 
     searchUnit,
    searchResidentialUnit,
    searchCommercialUnit,
    searchCommonArea,])

  useEffect(() => {
    generateDates()
    setPage(1)
  }, [subTabListItem])

  useEffect(() => {
    if (
      subTabListItem === 'unitCluster' ||
      subTabListItem === 'buildingCluster' ||
      subTabListItem === 'mixedCluster'
    ) {
      setPage(1)
      getAllClustersTableData(1)
    }
    if (
      ['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some(
        (unit: string) => unit === subTabListItem
      )
    ) {
      setPage(1)
      getAllPropertiesTableData(1)
    }
  }, [
    searchUnit,
    searchBuildingCluster,
    searchMixedCluster,
    searchUnitCluster,
    searchResidentialUnit,
    searchCommercialUnit,
    searchCommonArea,
    startDatePayload,
    endDatePayload,
    subTabListItem,
  ])

  useEffect(() => {
    // if (pathname === `/properties/community-details/${id}`) {
      const filters = {
        searchUnit: searchUnit,
        searchResidentialUnit: searchResidentialUnit,
        searchCommercialUnit: searchCommercialUnit,
        searchCommonAreaUnit: searchCommonArea,
        searchUnitCluster: searchUnitCluster,
        searchBuildingCluster: searchBuildingCluster,
        searchMixedCluster: searchMixedCluster,
        searchCommonArea: searchCommonArea,
        // searchFloor: searchFloor,
      }
      if (subTabListItem === 'residentialUnits' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailResidentialDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'allUnits' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailAllUnitsDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'commercialUnits' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailCommercialDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'commonAreas' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailCommonAreaDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'unitCluster' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailUnitClusterDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'buildingCluster' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailBuildingClusterDateFilter', JSON.stringify(dateRange))
      }
      if (subTabListItem === 'mixedCluster' && start_date !== null && end_date !== null) {
        const dateRange = {
          startDate: start_date,
          endDate: end_date,
        }
        localStorage.setItem('communityDetailMixedClusterDateFilter', JSON.stringify(dateRange))
      }
      localStorage.setItem('communityDetailFilters', JSON.stringify(filters))
      localStorage.setItem('subTabListItemForCommunityDetails', JSON.stringify(subTabListItem))
    // }
  }, [
    subTabListItem,
    searchCommercialUnit,
    searchResidentialUnit,
    searchBuildingCluster,
    searchMixedCluster,
    searchUnitCluster,
    searchUnit,
    start_date,
    end_date,
    searchCommonArea,
  ])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const goToEdit = (listItem: any) => {
    navigate(`/properties/community-details/${id}/edit-property/${listItem._id}`, {
      state: {from: 'communityDetail', communityName: state?.communityName, communityId: id,
      area:listItem?.community[0]?.area,
      city:listItem?.community[0]?.city,
      street:listItem?.community[0]?.street,
      },
    })
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle'
                    style={{cursor: 'pointer'}}
                    onClick={() => navigate('/properties')}
                  >
                    <img src={backArrow} height='14' width='14' className='me-3' />
                    <img src={communityBlue} width='40' />
                  </span>
                  <h2 className='m-0 head-text ms-3'>
                    {state?.communityName ? state?.communityName : '-'}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-end'>
                <span className='ms-0 fw-bold white-dark-theme-color'> {state?.area ? `${state?.area}` : `-`}</span>
                <span className='ms-5 fw-bold white-dark-theme-color'>{state?.city ? `${state?.city}` : `-`}</span>
                <img
                  src={editPen}
                  width='20'
                  className='mx-6'
                  onClick={handleEdit}
                  style={{cursor: 'pointer'}}
                />
                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn'
                  onClick={() => {
                    navigate(`/properties/community-details/${id}/add-property`, {
                      state: {
                        communityId: id,
                        communityName: state?.communityName,
                        area: state?.area,
                        city: state?.city,
                      },
                    })
                  }}
                >
                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className='d-flex align-items-start pb-5 mb-5'
          style={{borderBottom: '0.1rem solid #bec3cb'}}
        ></div>

        {/* sub tabs start */}

        <div className=' ps-0 row mt-2'>
          <div className='col-12'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'allUnits' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('allUnits')
                    // localStorage.setItem('vendorActiveTab', 'info')
                  }}
                >
                  All Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'residentialUnits' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('residentialUnits')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Residential Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'commercialUnits' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commercialUnits')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Commercial Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'commonAreas' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commonAreas')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Common Areas
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'unitCluster' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('unitCluster')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Unit Clusters
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'buildingCluster' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('buildingCluster')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Building Clusters
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    subTabListItem === 'mixedCluster' && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('mixedCluster')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Mixed Clusters
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* sub tabs end */}

        {/* ingights start and filter */}
        <div className='row mt-7'>
        <div className='col-12'>
          <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{ gap: '20px' }}>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Units</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={unitsBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{((unitCountSummery?.occupiedUnitCount ? unitCountSummery?.occupiedUnitCount : 0)  + (unitCountSummery?.vacantUnitCount ? unitCountSummery?.vacantUnitCount :0))}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Tenants</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={tenantBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenantCount ?? 0}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Leases</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={leasesBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenancyCount ?? 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Occupancy</h4>
              <div className='d-flex justify-content-start align-items-top'>
                <img
                  src={occupancyBlue}
                  height={45}
                  width={45}
                  className='me-3'
                />
                <h4 className='fw-bold ms-2' style={{ marginTop: '15px' }}>{unitCountSummery?.occupancyRate >= 0 ? `${Math.round(unitCountSummery?.occupancyRate)}%` : 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Income</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#00b8a9' }}>AED</h4>
                <h4 className='fw-bold'>
                {`${parseFloat(unitCountSummery?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                </h4>
                
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Expense</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#e46464 ' }}>AED</h4>
                <h4 className='fw-bold'>
                  {/* {unitCountSummery?.expensePaymentCount ?? 0} */}
                  {`${parseFloat(unitCountSummery?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}

                  </h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px'}}>
              <h4 className='text-gray-500'>Rating</h4>
              <div className='d-flex justify-content-between align-items-end'>
                <img
                  src={yellowStar}
                  height={35}
                  width={35}
                  className='me-3 mt-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.ratingCount ?? 0}</h4>
              </div>

            </div>
          </div>
        </div>
          <div
            className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5'
            style={{gap: '0px'}}
          >
            <div className={`d-flex align-items-center  px-0 col-10`}>
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>
              </div>

              {subTabListItem === 'allUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValue}
                  onChange={(e: any) => {
                    setSearchValue(e.target.value)
                    // setSearchUnit(e.target.value)
                  }}
                />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}

              {subTabListItem === 'residentialUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueResidential}
                  onChange={(e: any) => setSearchValueResidential(e.target.value)}
                />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'commercialUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueCommercial}
                  onChange={(e: any) => setSearchValueCommercial(e.target.value)}
                />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}

              {subTabListItem === 'commonAreas' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueCommonArea}
                  onChange={(e: any) => setSearchValueCommonArea(e.target.value)}
                />
                    <IconButton style={searchIconStyle} aria-label='Common Area'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'unitCluster' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Unit Cluster'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit Cluster'}}
                      value={searchUnitCluster}
                      onChange={(e: any) => setSearchUnitCluter(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Unit Cluster'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'mixedCluster' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Mixed Cluster'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Mixed Cluster'}}
                      value={searchMixedCluster}
                      onChange={(e: any) => setSearchMixedCluter(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Mixed Cluster'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'buildingCluster' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Building Cluster'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Building Cluster'}}
                      value={searchBuildingCluster}
                      onChange={(e: any) => setSearchBuildingCluter(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Building Cluster'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              <div className='test property me-4'>
                <CustomDateRangePicker
                  startDate={start_date}
                  endDate={end_date}
                  dateStatuses={[]}
                  onDatesChange={handleDatesChange}
                  //  datesToCompare={datesToCompare}
                  autoSave={() => {}}
                  from={'create'}
                  disabled={false}
                />
              </div>
            </div>

            <div className={`px-0 col-2 d-flex justify-content-end`}>
              {(subTabListItem === 'unitCluster' ||
                subTabListItem === 'buildingCluster' ||
                subTabListItem === 'mixedCluster') && (
                <div className=''>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                    onClick={() => {
                      setAddFloorModel(true)
                    }}
                    style={{width: 'fit-content'}}
                  >
                    <img src={addWhiteIcon} height={18} width={18} className='me-2' />{' '}
                    {`${
                      subTabListItem === 'unitCluster'
                        ? 'Add Unit Cluster'
                        : subTabListItem === 'buildingCluster'
                        ? 'Add Building Cluster'
                        : subTabListItem === 'mixedCluster'
                        ? 'Add Mixed Cluster'
                        : '-'
                    }`}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ingights end and fitlers*/}

        {(subTabListItem === 'allUnits' ||
          subTabListItem === 'residentialUnits' ||
          subTabListItem === 'commercialUnits') && (
          <PropertiesList
            goToEdit={goToEdit}
            isFloorCol={true}
            isEditBtn={true}
            isCommunity={true}
            listData={allPropertiesTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
          />
        )}

        {subTabListItem === 'commonAreas' && (
          <CommonAreaList
            goToEdit={goToEdit}
            isEditBtn={true}
            isFloorCol={true}
            listData={allPropertiesTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
          />
        )}

        {subTabListItem === 'unitCluster' && (
          <ClusterList
            listData={allClustersTableData.filter((filterItem: any) => filterItem?.type === 0)}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
            mixedCluster={false}
            buildingCluster={false}
            unitCluster={true}
          />
        )}
        {subTabListItem === 'buildingCluster' && (
          <ClusterList
            listData={allClustersTableData.filter((filterItem: any) => filterItem?.type === 1)}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
            mixedCluster={false}
            buildingCluster={true}
            unitCluster={false}
          />
        )}
        {subTabListItem === 'mixedCluster' && (
          <ClusterList
            listData={allClustersTableData.filter((filterItem: any) => filterItem?.type === 2)}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
            mixedCluster={true}
            buildingCluster={false}
            unitCluster={false}
          />
        )}
      </div>

      {addFloorModel && (
        <AddFloorModel
          show={addFloorModel}
          communityId={id}
          communityName={state?.communityName}
          isBuilding={false}
          isUnitCluster={subTabListItem === 'unitCluster' ? true : false}
          isMixedCluster={subTabListItem === 'mixedCluster' ? true : false}
          isBuildingCluster={subTabListItem === 'buildingCluster' ? true : false}
          handleClose={() => {
            setAddFloorModel(false)
            getAllClustersTableData()
          }}
        />
      )}
    </>
  )
}

export default CommunityDetailList

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import reactiveImg from '../../../../img/reactive.png'
import reactiveWhiteImg from '../../../../img/reactiveWhite.png'
import plannedImg from '../../../../img/planned.png'
import plannedWhiteImg from '../../../../img/plannedWhite.png'
import preventativeImg from '../../../../img/preventative.png'
import preventativeWhiteImg from '../../../../img/preventativeWhite.png'
import informationImg from '../../../../img/information.png'
import { useNavigate } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import floorsBlue from '../../../../img/floorsBlue.png'
import unitClusterIcon from '../../../../img/unitClusterIcon.png'
import buildingClusterIcon from '../../../../img/buildingCluster.png'
import mixedClusterIcon from '../../../../img/mixedCluster.png'

import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import addWhiteIcon from '../../../../img/add-white.png'
import redCross from '../../../../img/remove.png'
import updateIcon from '../../../../img/sync.png'
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import CircularProgress from '@mui/material/CircularProgress'

interface ButtonProps {
    show: any
    handleClose?: any
    updateLists?: any
    addedLists?: any
    isEdit?: any
    modelData?: any
    updateModelData?: any
    isBuilding?: any,
    isUnitCluster?: any,
    isMixedCluster?: any,
    isBuildingCluster?: any
    buildingId?: any,
    communityId?: any,
    communityName?: any,
    clusterId?: any,
    buildingName?: any
    floorId?:any
    floorName?:any,
    isGroup?:any
}

const AddFloorModel = ({
    show,
    handleClose,
    updateLists,
    addedLists,
    isEdit,
    modelData,
    updateModelData,
    isBuilding,
    isMixedCluster,
    isUnitCluster,
    isBuildingCluster,
    buildingId,
    communityId,
    communityName,
    clusterId,
    buildingName,
    floorId,
    floorName,
    isGroup
}: ButtonProps) => {
    const [selectReactiveBox, setReactiveBox] = useState(false)
    const [selectPlannedBox, setPlannedBox] = useState(false)
    const [selectPreventativeBox, setPreventativeBox] = useState(false)
    const [floor, setFloor] = useState<any>(modelData?.floorName ?? '')

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<any>(false)

    const handleAddFloor = async () => {
        setIsLoading(true)
        let body : any = {
            name: floor,
            buildingId: `${buildingId}`,
        }

        await ApiPost('corporate/floor', body)
            .then((res) => {
                // setAllBuildingTableData(res?.data?.data?.building_data)
                console.log(res)
                setIsLoading(false)
                handleClose()
           
            })
            .catch((err) => {
                ErrorToast(err.message)
                setIsLoading(false)
                if (err?.message === 'Your account has been suspended!') {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }

    const handleAddCluster = async () => {
        setIsLoading(true)
        let body : any = {
            name: floor,
            type: isUnitCluster ? 0 :isBuildingCluster ? 1 : isMixedCluster ? 2: '',
            communityId:`${communityId}`,
        }


        await ApiPost('corporate/cluster', body)
            .then((res) => {
                // setAllBuildingTableData(res?.data?.data?.building_data)
                console.log(res)
                setIsLoading(false)
                handleClose()
           
            })
            .catch((err) => {
                ErrorToast(err.message)
                setIsLoading(false)
                if (err?.message === 'Your account has been suspended!') {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }

    const handleUpdateFloor = async () => {
        setIsLoading(true)
        let body = {
            name: floor,
            id: `${modelData?.floorId}`,
      
        }
        await ApiPut('corporate/floor', body)
            .then((res) => {
                // setAllBuildingTableData(res?.data?.data?.building_data)
                setIsLoading(false)
                updateModelData({floorId:modelData?.floorId,floorName: floor})
                handleClose()
           
            })
            .catch((err) => {
                ErrorToast(err.message)
                setIsLoading(false)
                if (err?.message === 'Your account has been suspended!') {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }
    const handleUpdateCluster = async () => {
        setIsLoading(true)
        let body = {
            name: floor,
            id: `${modelData?.floorId}`,
            type: isUnitCluster ? 0 :isBuildingCluster ? 1 : isMixedCluster ? 2: '',
            communityId:`${communityId}`,
      
        }
        await ApiPut('corporate/cluster', body)
            .then((res) => {
                // setAllBuildingTableData(res?.data?.data?.building_data)
                setIsLoading(false)
                updateModelData({floorId:modelData?.floorId,floorName: floor})
                handleClose()
           
            })
            .catch((err) => {
                ErrorToast(err.message)
                setIsLoading(false)
                if (err?.message === 'Your account has been suspended!') {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }


    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '25px',
        // marginLeft: '10px',
    }

    const searchIconStyle = {
        padding: '10px',
        color: 'gray',
    }

    const inputBaseStyle = {
        flex: 1,
        padding: '5px',
    }

    function capitalizeFirstLetter(word: any) {
        if (!word || typeof word !== 'string') {
          return word
        }
        const firstLetter = word.charAt(0)
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLetters = word.slice(1)
        const capitalizedWord = firstLetterCap + remainingLetters
        return capitalizedWord
      }

    return (
        <>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-350 mx-auto'
                show={show}
                onHide={() => {
                    handleClose()
                }}
                backdrop={true}
                size='sm'
            >
             
                    <div
                        className='modal-body py-lg-7 px-lg-7 pt-lg-3'
                        style={{
                            transform: 'translate(0px, -70px)',
                            top: '70px',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 20px 0px #516176',
                            minHeight: '350px',
                            maxHeight: '400px',
                            overflow: 'hidden',
                        }}
                    >
                        <div className='my-1 pb-1 row mt-3'>
                            <div className='col-md-12'>
                                <div className='d-flex justify-content-end'>

                                    <label
                                        className='head-text fs-5 cursor-pointer'
                                        style={{ fontWeight: '700' }}
                                        onClick={() => {
                                            handleClose()
                                        }}
                                    >
                                        X
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='row mx-0 px-0 mt-5'>
                            <div className="col-12 mx-auto text-center">
                                <img src={isBuilding ? floorsBlue : isUnitCluster ? unitClusterIcon : isBuildingCluster ? buildingClusterIcon : isMixedCluster ? mixedClusterIcon : isGroup ? unitClusterIcon : ''}
                                    height={40}
                                    className='m-0 p-0'
                                />

                                {isBuilding && <h2 className='head-text mt-4 mb-3'>
                                    {!isEdit && <b>Add a floor in</b>}
                                    {isEdit && <b>Edit Floor</b>}
                                </h2>}
                                {isUnitCluster && <h2 className='head-text mt-4 mb-3'>
                                    {!isEdit && <b>Add a Unit Cluster in</b>}
                                    {isEdit && <b>Edit Unit Cluster</b>}
                                </h2>}
                                {isBuildingCluster && <h2 className='head-text mt-4 mb-3'>
                                    {!isEdit && <b>Add a Building Cluster in</b>}
                                    {isEdit && <b>Edit Building Cluster</b>}
                                </h2>}
                                {isMixedCluster && <h2 className='head-text mt-4 mb-3'>
                                    {!isEdit && <b>Add a Mixed Cluster in</b>}
                                    {isEdit && <b>Edit Mixed Cluster</b>}
                                </h2>}
                                {isGroup && <h2 className='head-text mt-4 mb-3'>
                                    {!isEdit && <b>Add a Unit Group in</b>}
                                    {isEdit && <b>Edit Unit Group</b>}
                                </h2>}
                                <h3 className='head-text mb-0'>
                                    {`${(!isEdit && isBuilding) ? buildingName : (!isEdit && isUnitCluster) ? communityName : (!isEdit && isBuildingCluster) ? communityName : (!isEdit && isMixedCluster) ? communityName : ''}`}
                                </h3>

                                {isEdit && <h3 className='head-text mb-0'>
                                    {modelData?.floorName ?? '-'}
                                </h3>}
                                <div className="row">
                                    <div className="col-8 mx-auto mt-7">
                                        <div className='xyz'>

                                            {isGroup && <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                    placeholder='Enter new group name '
                                                    style={inputBaseStyle}
                                                    inputProps={{ 'aria-label': 'Group' }}
                                                    value={floor}
                                                    onChange={(e: any) => {
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                                        setFloor(captilizeWord)
                                                     }}
                                                />
                                            </Paper>}
                                            {isBuilding && <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                    placeholder='Enter floor'
                                                    style={inputBaseStyle}
                                                    inputProps={{ 'aria-label': 'Floor' }}
                                                    value={floor}
                                                    onChange={(e: any) => {
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                                        setFloor(captilizeWord)
                                                     }}
                                                />
                                            </Paper>}
                                            {isUnitCluster && <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                    placeholder='Enter unit cluster name'
                                                    style={inputBaseStyle}
                                                    inputProps={{ 'aria-label': 'cluster name' }}
                                                    value={floor}
                                                    onChange={(e: any) => {
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                                        setFloor(captilizeWord)
                                                     }}
                                                />
                                            </Paper>}
                                            {isBuildingCluster && <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                    placeholder='Enter building cluster name'
                                                    style={inputBaseStyle}
                                                    inputProps={{ 'aria-label': 'building name' }}
                                                    value={floor}
                                                    onChange={(e: any) => {
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                                        setFloor(captilizeWord)
                                                     }}
                                                />
                                            </Paper>}
                                            {isMixedCluster && <Paper component='form' style={searchContainerStyle}>
                                                <InputBase
                                                    placeholder='Enter mixed cluster name'
                                                    style={inputBaseStyle}
                                                    inputProps={{ 'aria-label': 'mixed name' }}
                                                    value={floor}
                                                    onChange={(e: any) => {
                                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                                        setFloor(captilizeWord)
                                                     }}
                                                />
                                            </Paper>}

                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-center  mt-6" style={{ gap: '10px' }}>
                                            {!isEdit && <button
                                                disabled={!floor || isLoading}
                                                type='button'
                                                className='btn btn-sm fw-bold px-2 green-submit-btn d-flex justify-content-center'
                                                onClick={() => {
                                                    if(isBuilding){
                                                        handleAddFloor()
                                                    }else if(isUnitCluster || isBuildingCluster || isMixedCluster){
                                                        handleAddCluster()
                                                    }
                                                }}
                                            >
                                                <img src={addWhiteIcon} height={18} width={18} className='me-3' /> Add
                                            </button>}
                                            {isEdit && <button
                                                disabled={isLoading}
                                                type='button'
                                                className='btn btn-sm fw-bold red-hollow-btn ps-2 ms-auto mt-2'
                                                onClick={() => handleClose() }
                                            >
                                                <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                                            </button>}
                                            {isEdit && <button
                                                disabled={!floor || isLoading}
                                                type='button'
                                                className='btn btn-sm fw-bold green-submit-btn ps-2 ms-auto mt-2'
                                                onClick={() => {
                                                    if(isBuilding){
                                                        handleUpdateFloor()
                                                    }else if(isUnitCluster || isBuildingCluster || isMixedCluster){
                                                        handleUpdateCluster()
                                                    }
                                                }}
                                            >
                                                <img src={updateIcon} height={18} width={18} className='me-3' /> Update
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </Modal>
        </>
    )
}

export default AddFloorModel

import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import toast from "react-hot-toast";
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import addIcon from '../../../../../../../img/add.png'
import SelectInvoice from '../../Receipts/SelectInvoice'
import SelectedInvoicesCard from '../../Receipts/SelectedInvoices'
import SelectAccount from '../components/SelectAccount'
import RecordPayment from '../components/RecordPayment'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {
  ApiGet,
  ApiPost,
  ApiDelete,
} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import ConfirmationPopup from '../../ConfirmationPopup'
import LeaseSelection from '../components/LeaseSelection';

enum ActionType {
  CreateAndShare = 'CS',
  Create = 'CR',
  UpdateAndShare = 'US',
  Update = 'UP',
  None = '-',
}
// let updateSelectedInvoicesCount = 0;

const IssueDebitNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [validateCreditNote, setValidateCreditNote] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])
  const [selectedInvoicesTemp, setSelectedInvoicesTemp] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'DEBIT NOTE',
    dueDate:null,
    debitNoteNo: '',
    note: '',
    payorData: {},
    tenancyData: {},
    payment_sub_item: [],
    // items: [],
    debitNoteCompany: {},
    debitNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
    frontendStorage: {
      noteShowHide: false,
    },
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }


  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  // Function to call the API
  const addDebitNote = async (saveStatus: number, from: any) => {
    let isValid: any = true;
  
    // If saveStatus is 0, or valid items were processed, directly call the API
    if (saveStatus === 0 || saveStatus === 1 && isValid) {
      setIsLoading(true)
      setOpen(true)
      callApi();
    }
  
    // Function to call the API
    function callApi() {
      let newItems: any = [];
  
      let invoiceRecords: any = [];
      selectedInvoices?.map((sldInv: any, ind: number) => {
        invoiceRecords[ind] = {
          invoiceId: sldInv?._id,
          paymentId: sldInv?.paymentId,
          paymentAccountId: sldInv?.payment_account?.[0]?._id,
          paymentSubItems: sldInv?.paymentSubItems?.map((paymentItem: any, pyInd: any) => {
            return {
              _id: paymentItem?._id,
              name: paymentItem?.name,
              paymentItemListId: paymentItem?.paymentItemListId,
              amount: paymentItem?.amount === null ? 0 : paymentItem?.amount,
              quantity: paymentItem?.quantity === null ? 0 : paymentItem?.quantity,
              totalAmount: paymentItem?.totalAmount === null ? 0 : paymentItem?.totalAmount,
              amountPaid: paymentItem?.amountPaid === null ? 0 : paymentItem?.amountPaid,
              outstandingAmount: paymentItem?.outstandingAmount,
              VATAmount: paymentItem?.VATAmount === null ? 0 : paymentItem?.VATAmount,
              VATPercentage: paymentItem?.VATPercentage === null ? 0 : paymentItem?.VATPercentage,
              totalDebitAmount: paymentItem?.totalDebitAmount === null ? 0 : paymentItem?.totalDebitAmount,
              // debitVATAmount: paymentItem?.debitVATAmount === null ? 0 : paymentItem?.debitVATAmount,
              // debitAmount: paymentItem?.debitAmount === null ? 0 : paymentItem?.debitAmount,
              isSelected: paymentItem?.isSelected,
              addedDebit: {
                debitAmount: Number(paymentItem?.addedDebit?.debitRateAmount),
                debitRateAmount: Number(paymentItem?.addedDebit?.debitRateAmount),
                quantity: Number(paymentItem?.addedDebit?.quantity),
                debitVATAmount: Number(paymentItem?.addedDebit?.debitVATAmount),
                totalDebitAmount: Number(paymentItem?.addedDebit?.totalDebitAmount),
              },
              frontendStorage: paymentItem?.frontendStorage
            };
          }),
        };
      });
  
      newItems = [...creditNoteData?.payment_sub_item]?.map((itm: any, ind: any) => {
        let { addedDebit, ...rest } = itm;
        return {
          ...rest,
          quantity:  Number(addedDebit?.quantity),
          amount: Number(addedDebit?.debitRateAmount),
          VATPercentage: Number(addedDebit?.debitVATAmount) ,
          totalAmount: Number(addedDebit?.totalDebitAmount),
        };
      });
  
      let subTotalAmount = 0;
      let totalVAT = 0;
      let nonVATAmount = 0;
  
      if (creditNoteData?.payment_sub_item?.length > 0) {
        creditNoteData?.payment_sub_item?.map((f: any, ind: any) => {
          if (f?.frontendStorage?.isLatePaymentItem) {
            totalVAT = totalVAT + (Number(f?.addedDebit?.debitRateAmount) / 100) * Number(f?.addedDebit?.quantity);
            nonVATAmount = nonVATAmount + f.amount;
          } else {
            totalVAT =
              totalVAT +
              ((Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity)) / 100) *
                Number(f?.addedDebit?.debitVATAmount);
            nonVATAmount =
              nonVATAmount + Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity);
          }
        });
      }
  
      const body = {
        _id: flg == '1' ? undefined : id,
        note: creditNoteData?.note ? creditNoteData?.note : '',
        tenantId: creditNoteData?.payorData?._id,
        datePaid: '',
        dueDate: creditNoteData?.dueDate ? creditNoteData?.dueDate : null,
        referenceNo: creditNoteData?.debitNoteNo,
        debitNoteNo: creditNoteData?.debitNoteNo,
        debitNoteTemplateHeader: creditNoteData?.name,
        formStatus: saveStatus,
        // creditCategory: existingInvoice,
        currency: 'AED',
        subTotalAmount: Number(nonVATAmount),
        totalVAT: Number(totalVAT),
        totalAmount: Number(totalVAT) + Number(nonVATAmount),
        invoiceRecords: invoiceRecords?.length > 0 ? invoiceRecords : [],
        paymentSubItems: newItems?.length > 0 ? newItems : [],
        itemDescriptions: creditNoteData?.itemDescriptions,
        frontendStorage: {
          noteShowHide : creditNoteData?.showNotes
        },
        tenancyId: creditNoteData?.tenancyData?._id || null,
      };
  
      ApiPost(`corporate/payment_debit_note/add_update`, body)
        .then((res) => {
          // Handle success
          if (saveStatus == 0 ) {
            if (flg == '1') {
              navigate(`/finance-income/issue-debit-note/2/${res?.data?.data?._id}`)
              setTimeout(() => {
                setIsSaveFirst(true)
              }, 1000)
            }else {
              getDebitNoteById()
            }
            setTimeout(() => {
              setIsLoading(false)
              setOpen(false)
            }, 1500)
          }else if(saveStatus == 1 && from  === ActionType.Create){
            setAskForConfirmation(false)
            SuccessToast('Debit Note has been added successfully..')
            navigate('/finance-income')
          }else if(saveStatus == 1 && from === ActionType.CreateAndShare){
            setAskForConfirmation(false)
            navigate(`/finance-income/share/issue-debit-note/${id}`)
          }else{
            setAskForConfirmation(false)
            SuccessToast('Debit Note has been added successfully..')
            navigate('/finance-income')
              
          }
        })
        .catch((err: any) => {
          ErrorToast(err?.message);
          setTimeout(() => {
            setIsLoading(false)
            setOpen(false)
          }, 1500)
        });
    }
  }

  //
  const getDebitNoteById = async () => {
    let url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then(async (res: any) => {
        setValue('documentName', res?.data?.data?.debitNoteTemplateHeader)
        setValue('documentReceiptNo', res?.data?.data?.debitNoteNo)
        setValue('dueDate', res?.data?.data?.dueDate);

        let paymentItemsTemp: any = []
        res?.data?.data?.paymentSubItems?.forEach((pyItem: any, pyInd: number) => {
          paymentItemsTemp[pyInd] = {
            ...pyItem,
            outstandingAmount: pyItem?.totalAmount,
            amountPaid: pyItem?.amountPaid,
            addedDebit: {
              debitRateAmount: pyItem?.amount,
              quantity: pyItem?.quantity,
              debitAmount: pyItem?.amount,
              debitVATAmount: pyItem?.VATPercentage,
              totalDebitAmount: pyItem?.totalAmount,
            },
          }
        })

        let values: any = {
          name: res?.data?.data?.debitNoteTemplateHeader,
          debitNoteNo: res?.data?.data?.debitNoteNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          dueDate: res?.data?.data?.dueDate,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          debitNoteCompany: res?.data?.data?.debitNoteCompany,
          debitNoteLogo: res?.data?.data?.debitNoteLogo,
          payment_sub_item: paymentItemsTemp,
          itemDescriptions: res?.data?.data?.itemDescriptions ? res?.data?.data?.itemDescriptions : [],
          formStatusDateTime: res?.data?.data?.createdAt,
          formStatus: res?.data?.data?.formStatus,
          showNotes: res?.data?.data?.frontendStorage?.noteShowHide,
          tenancyData: {
            _id: res?.data?.data?.tenancyId
          }
        }

        if (flg === '2' && res?.data?.data?.tenancyId != null && res?.data?.data?.tenancyId != undefined) {
          const tenancyDetails = await getTenancyDetails(res?.data?.data?.tenancyId);
          let valuesTen = { ...values };

          console.log(tenancyDetails)

          valuesTen.tenancyData  = tenancyDetails?.[0]
          values = valuesTen
        }

        setCreditNoteData(values)
        console.log(res?.data?.data?.invoiceRecords)
        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          const {tenant,...rest} = inv?.payment_invoice
          invValues?.push(rest)

          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            (tenant !== undefined && tenant !== null)
              ? [tenant]
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          // invValues[index].paymentAccountId = inv?.payment_invoice?.payment_account?.length > 0 
          //     ? inv?.payment_invoice?.payment_account?.[0]?._id 
          //     : null
          invValues[index].paymentId = inv?.paymentId
          invValues[index].building = inv?.payment_invoice?.building !== undefined && inv?.payment_invoice?.building !== null  ? [inv?.payment_invoice?.building] : []
          invValues[index].floor = inv?.payment_invoice?.floor !== undefined && inv?.payment_invoice?.floor !== null ? [inv?.payment_invoice?.floor] : []
          invValues[index].community = inv?.payment_invoice?.community !== undefined && inv?.payment_invoice?.community !== null ? [inv?.payment_invoice?.community] :[]
          invValues[index].unit = inv?.payment_invoice?.unit !== undefined && inv?.payment_invoice?.unit !== null ? [inv?.payment_invoice?.unit] : []
          invValues[index].tenant = inv?.payment_invoice?.tenant !== undefined && inv?.payment_invoice?.tenant !== null ? [inv?.payment_invoice?.tenant] : []
          invValues[index].tenancy = inv?.payment_invoice?.tenancy !== undefined && inv?.payment_invoice?.tenancy !== null ? [inv?.payment_invoice?.tenancy] :[]

          // setValue(
          //   `accountNumber${index}`,
          //   invValues[index].paymentAccountId != null ? `${invValues[index].paymentAccountId}` : ''
          // )
        })

        console.log(invValues)
        setSelectedInvoices(invValues)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const getTenancyDetails = async (tenId:any) => {
    try {
      const res = await ApiGet(`corporate/tenancy/${tenId}`);
      console.log(res)
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getTenancyDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };

  useEffect(() => {
    const shouldEnableButton = selectedInvoices?.some((sldInv: any) =>
      sldInv?.paymentSubItems?.some((paymentItem: any) => {
        const debitRateAmount = Number(paymentItem?.addedDebit?.debitRateAmount);
        const quantity = Number(paymentItem?.addedDebit?.quantity);
        const totalDebitAmount = Number(paymentItem?.addedDebit?.totalDebitAmount);
        const debitVATAmount =  Number(paymentItem?.addedDebit?.debitVATAmount);
        const totalAmount = Number(paymentItem?.totalAmount);
        const isSelected = paymentItem?.isSelected;

        // Check if creditRateAmount and quantity are above 0, and totalCreditAmount is not greater than totalAmount
        return debitRateAmount > 0 && quantity > 0 && debitVATAmount >= 0  && isSelected;
      })
    );

    // Enable the button if the conditions are met, otherwise disable it
    setValidateCreditNote(!shouldEnableButton);
  }, [selectedInvoices]);

  //
  useEffect(() => {
    if (isSaveFirst || flg == '2') getDebitNoteById()
  }, [isSaveFirst])

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addDebitNote(0, '')
    }
  }, [autoSaveData])

  // delete credit note
  const deleteCreditNote = () => {
    ApiDelete(`corporate/payment_debit_note/${id}`)
      .then((res) => {
        SuccessToast('Payment Debit note has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const getDebitSettingDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting');
      console.log(res)
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getDebitNoteDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };
  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isShare, setIsShare] = useState<any>(true)
  const [hasUpdated, setHasUpdated] = useState(false)

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          // onSubmit={handleSubmit((data: any, e: any) => {
          //   addDebitNote(1, '')
          // })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      if (goToNext) setGoToNext(false)
                      else navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Issue Debit Note`}</h2>
                </div>

                {/* CTAs */}
                {selectedInvoices?.length > 0 && (
                <div className='d-flex ms-auto'>
                  {flg != '1' ? (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                      disabled={isLoading || isViewOnly}
                      onClick={() => {
                        Swal.fire({
                          html: ` 
                            <div class='fs-3'> <b>Delete Draft Debit Note</></div>
                            <div class='fs-5 mt-4'> Are you sure want to delete this draft debit note? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        }).then((res: any) => {
                          if (res.isConfirmed) {
                            deleteCreditNote()
                          }
                        })
                      }}
                    >
                      <img src={trashImg} height={18} width={18} className='me-4' />{' '}
                      Delete
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                      onClick={() => {
                        addDebitNote(0, '')
                      }}
                      disabled={isLoading}
                    >
                      <img src={saveGreenImg} height={18} width={18} className='me-4' />{' '}
                      {'  '}
                      Save
                    </button>
                  )}

                  <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                    onClick={ async () => {
                      let isValid = false
                      isValid = await trigger();

                      if(!creditNoteData?.name?.trim()){
                        ErrorToast('Debit note name is required.');
                        return
                      }
                      
                      const invoiceDetails = await getDebitSettingDetails();
                      if(invoiceDetails?.debitNoteSequence?.activityStatus === 0 && !creditNoteData?.debitNoteNo?.trim()){
                        ErrorToast('Debit note number is required.');
                        return
                      }else if(!creditNoteData?.dueDate){
                        ErrorToast('Due date is required.');
                        return
                      }

                      let addSectionErr : boolean = false
                        if(creditNoteData?.itemDescriptions?.length > 0  && creditNoteData?.itemDescriptions?.some((itm:any) => {
                          const tempElement = document.createElement('div');
                          tempElement.innerHTML = itm?.html;
                          return ((!tempElement?.textContent?.trim() || !tempElement?.innerText?.trim()) || !itm?.title?.trim() || itm?.html === undefined);
                        })){
                          addSectionErr = true
                        }else{
                          addSectionErr = false
                        }

                        if(addSectionErr){
                          ErrorToast('User cannot create debit note with empty title or content in additional sections.')
                          return
                        }


                      if(isValid){

                        let validItems: any = [];
                        let shouldEnableButton = false;
                    
                          selectedInvoices?.forEach((sldInv: any,invInd:any) => {
                            sldInv?.paymentSubItems?.forEach((paymentItem: any,pyInd:any) => {
                              const creditRateAmount = Number(paymentItem?.addedDebit?.debitRateAmount);
                              const quantity = Number(paymentItem?.addedDebit?.quantity);
                              const totalCreditAmount = Number(paymentItem?.addedDebit?.totalDebitAmount);
                              const totalAmount = Number(paymentItem?.totalAmount);
                    
                              // Check if the item is valid and not already part of the credit note
                              if (creditRateAmount > 0 && quantity > 0  && totalAmount >= 0 && !paymentItem?.isSelected) {
                                paymentItem.isSelected = true;
                                paymentItem.frontendStorage = {
                                 ...paymentItem.frontendStorage,
                                 invoiceDate: sldInv[invInd]?.date,
                                }
                                validItems.push(paymentItem);
                                shouldEnableButton = true;
                              }
                            });
                          });
                        // });
                    
                        // Show the popup if valid items are found
                        if (shouldEnableButton) {
                          Swal.fire({
                            html: ` 
                              <div class='fs-3'> <b>Items Missing</b></div>
                              <div class='fs-5 mt-4 fw-bold'> There are some items you recorded debit details for that were not included in the Debit Note.</div>
                              <div class='fs-5 mt-4 fw-bold'> Would you like to add them before creating? </div>
                              <p class='fs-5 mt-4 text-gray-400 fw-bold'> This only applies to items that have complete and correct debit details but were not included.</p>
                              `,
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true,
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              console.log(validItems);
                              setAskForConfirmation(true)
                              toast('Items added successfully to your debit note!', {
                                icon: <img src={addIcon} alt="icon" width={14} height={14}/>,
                                style: {
                                  padding: "16px",
                                },
                              })

                              const updateCreditNote = {...creditNoteData}
                              validItems?.forEach((subItem:any) => {
                                subItem.frontendStorage = {
                                  ...subItem.frontendStorage,
                                  addedAt : new Date().getTime()
                                }
                              })
                              updateCreditNote.payment_sub_item = [...updateCreditNote.payment_sub_item,...validItems]
                              setCreditNoteData(updateCreditNote)

                            } else {
                              setAskForConfirmation(true)
                              selectedInvoices?.forEach((sldInv: any) => {
                                sldInv?.paymentSubItems?.forEach((paymentItem: any) => {
                                  if (validItems.includes(paymentItem)) {
                                    paymentItem.isSelected = false;
                                  }
                                });
                              });
                              validItems = [];
                            }
                          });
                    
                        }else{
                          setAskForConfirmation(true)
                        }
                      }
                      
                      
                    }}
                    disabled={isLoading || !goToNext || validateCreditNote}
                  >
                    <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                    Create
                  </button>
                </div>
               )} 
              </div>
            </div>

            {!goToNext ? (
              <>
                {/* Select Account Card */}
                <SelectAccount
                 from={'debit note'}
                  slctdInvoices={selectedInvoices}
                  updateSelectedPayor={(data: any) => {
                    let values: any = {...creditNoteData}
                    values.payorData = data
                    values.tenancyData = {}
                    setCreditNoteData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  rcptData={creditNoteData}
                  updateSelectedInvoices={(data: any, tenancyData:any) => {
                    let values: any = {...creditNoteData}
                    values.payorData = data
                    values.tenancyData = tenancyData

                    values.payment_sub_item = []
                    setCreditNoteData(values)

                    setSelectedInvoices([])

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />

                 {/* Select Lease Card */}
                 <LeaseSelection
                  from={'debit note'}
                  rcptData={creditNoteData}
                  slctdInvoices={selectedInvoices}
                  updateSelectedLease={(data: any) => {
                    let values: any = {...creditNoteData}
                    
                    values.tenancyData = data
                    setCreditNoteData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}

                  updateSelectedInvoices={(data: any, invs: any) => {
                    let values: any = {...creditNoteData}
                    values.tenancyData = data
                    values.payment_sub_item = []
                    setCreditNoteData(values)

                    setSelectedInvoices([])

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />


                {/* Select invoice Card */}
                  <div className='position-relative'>
                    {creditNoteData?.payorData?._id == undefined && (
                      <div className='requestBoxOpacity border-radius-5px z-index-10'></div>
                    )}
                    <div>
                      <SelectInvoice
                        slctdInvoices={selectedInvoices}
                        updateSelectedInvoice={(data: any) => {
                          if (flg == '2' && !hasUpdated) {
                            setSelectedInvoices(data)
                            setHasUpdated(true)
                          }
                        }}
                        updateCheckedInvoices={(data: any) => {
                          updateSelectedInvoices(data)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        rcptData={creditNoteData}
                        from={'debit note'}
                      />
                    </div>
                  </div>

                {/* Selected invoices */}
                  <div className='position-relative'>
                    {creditNoteData?.payorData?._id == undefined && (
                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                    )}
                    <div>
                      <SelectedInvoicesCard
                        creditNoteData={creditNoteData}
                        slctdInvoices={selectedInvoices}
                        updateCreditNote={(updatedCredit:any,updatedSelectedInvoices:any) => {
                          const creditNoteTemp = {...creditNoteData}
                          creditNoteTemp.payment_sub_item = updatedCredit

                          setSelectedInvoices(updatedSelectedInvoices)
                          setCreditNoteData(creditNoteTemp)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        updateCheckedInvs={(data: any) => {
                          setSelectedInvoices(data)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        from={'debit note'}
                      />{' '}
                    </div>
                  </div>

                {/* Next CTA */}
                <div className={`d-flex justify-content-center my-20 pt-20`}>
                  <div
                    className={`w-fit-content ${
                      selectedInvoices?.length == 0 ? 'bg-white' : ''
                    }`}
                    style={{borderRadius: '5px'}}
                  >
                    <button
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn ps-3 d-flex justify-content-center purchase-order-btn'
                      disabled={selectedInvoices?.length == 0}
                      onClick={() => {
                        setGoToNext(true)
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <RecordPayment
                  slctdInvoices={selectedInvoices}
                  register={register}
                  errors={errors}
                  notePaymentData={creditNoteData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updatePaymentRecord={(data: any) => {
                    const updateCreditNote: any = {...creditNoteData}
                    // push selected item to items if the item total amount is >= totatAmountOfItem
                    const items: any = []
                    data?.map((inv: any) => {
                      inv?.paymentSubItems?.map((itm: any) => {
                        let totalAmountOfItem: any = 0
                        let vatAmnt: any =
                          ((Number(itm?.addedDebit?.debitRateAmount) *
                            Number(itm?.addedDebit?.quantity)) /
                            100) *
                          Number(itm?.addedDebit?.debitVATAmount)
                        totalAmountOfItem =
                          Number(itm?.addedDebit?.debitRateAmount) *
                            Number(itm?.addedDebit?.quantity) +
                          vatAmnt
                        if (itm?.isSelected && Number(itm?.addedDebit?.debitRateAmount) > 0 && Number(itm?.addedDebit?.quantity) > 0 && itm?.addedDebit?.debitVATAmount !== '') {
                          items?.push(itm)
                        }else if(Number(itm?.addedDebit?.debitRateAmount) <= 0 || Number(itm?.addedDebit?.quantity) <= 0 || !itm?.addedDebit?.debitVATAmount || Number(totalAmountOfItem) > Number(itm?.totalAmount)){
                          itm.isSelected = false
                        }
                      })
                    })

                    // updateCreditNote.payorData = data?.[0]?.tenant
                    updateCreditNote.payment_sub_item = items
                    setCreditNoteData(updateCreditNote)
                    setSelectedInvoices(data)
                  }}
                  autoSaveParent={() => {
                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  editMode={true}
                  from={'debit note'}
                />

                {/* Credit Note Details Card */}
                <NoteDetailsCard
                  register={register}
                  errors={errors}
                  noteCardData={creditNoteData}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                  updateNoteData={(data: any) => {
                    setCreditNoteData(data)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  updateNote={(data: any) => {
                    setCreditNoteData(data)
                  }}
                  slctdInvoices={selectedInvoices}
                  editMode={true}
                  from={'debit note'}
                />
              </>
            )}
          </div>
        </form>
      </div>

      <ConfirmationPopup
        from='debit'
        show={askForConfirmation}
        isUpdate={false}
        isCreate={true}
        label={'Create'}
        type={'Debit Note'}
        payor={
          creditNoteData?.payorData?.firstName && creditNoteData?.payorData?.lastName ? `${creditNoteData?.payorData?.firstName} ${creditNoteData?.payorData?.lastName}` :'-'
        }
        typeOfNo={creditNoteData?.debitNoteNo}
        handleClose={() => {
          setAskForConfirmation(false)
        }}
        updateData={(actionType: string) => {
          console.log(actionType)

          if (actionType === ActionType.Create) {
            // run create fun
            addDebitNote(1, actionType)
          } else if (actionType === ActionType.CreateAndShare) {
            // run create and share
            addDebitNote(1, actionType)
          } else if (actionType === ActionType.Update) {
            // run update fun
          } else if (actionType === ActionType.UpdateAndShare) {
            // run update and share
          } else {
            // nothing
          }
        }}
        receiptData={[]}
        isShare={isShare}
        setIsShare={(val: any) => {
          setIsShare(val)
        }}
      />

      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={open}
      >
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default IssueDebitNote

import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import plus from '../../../img/add-white.png'
import close from '../../../img/close.png'
import './style.scss'
import download from '../../../img/download-white.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import {useNavigate, useLocation, useParams} from 'react-router-dom'

interface ButtonProps {
  show: any
  handleClose: any
  paymentItemsList: any
}

const AddItem = ({handleClose, show, paymentItemsList}: ButtonProps) => {
  const [name, setName] = useState<any>('')
  const [nameError, setNameError] = useState<any>(false)

  const {state} = useLocation()
  const {development} = state

  const params = useParams()

  const submitItem = () => {
    // console.log(state.development)
    if (name === '') setNameError(true)

    if (name !== '') {
      let body: any
      if (state.development === 'community') {
        body = {
          name: name,
          type: 1,
          communityId: params?.id,
        }
      } else {
        body = {
          name: name,
          type: 1,
          buildingId: params?.id,
        }
      }

      ApiPost(`corporate/payment_item_list`, body)
        .then((res) => {
          paymentItemsList()
          handleClose()
          SuccessToast('Item has been added successfully!!')
          setNameError(false)
          setName('')
        })
        .catch((err) => console.log('err', err))
    }
  }
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Add Item</h2>
            </Col>
            <Col  xs={4} sm={4} md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} sm={9} md={9}></Col>
            <Col xs={3} sm={3} md={3} className='pt-5'>
              <img src={download} height='65px' width='50px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end'>
            <Col xs={12} sm={6} md={6} className='align-items-center mt-3' >
              <label className='required mb-2 label-color'>Item Name</label>
              <input
                style={
                  name == ''
                    ? {
                        border: '0.1rem solid #bec3cb',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                      }
                    : {
                        border: '0.1rem solid #384a62 ',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                      }
                }
                value={name}
                type='text'
                placeholder='Enter Name'
                name='item_id'
                onChange={(e) => {
                  setName(e.target.value)
                  setNameError(false)
                }}
                className={
                  nameError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg'
                }
              />
            </Col>
            <Col  xs={12} sm={6} md={6} className='align-items-center text-end mt-3'>
              <button
                className='btn btn-sm fw-bold text-white'
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => submitItem()}
              >
                <img
                  src={plus}
                  style={{
                    height: '18px',
                    width: '18px',
                    marginRight: '20px',
                    marginLeft: '-7px',
                    marginTop: '-3px',
                  }}
                />
                Add
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default AddItem

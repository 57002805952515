import React, { useState, useEffect } from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import { MultiSelect } from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'


import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, Space } from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import { setComefrom } from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import backArrow from '../../../../img/back-arrow.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import PropertiesList from './PropertiesList'
import AddFloorModel from "./AddFloorModel"
import { Bucket } from '../../../../apiCommon/helpers/API/ApiData'

interface SquareHandleProps {
    value: number
}


const BuildingFloorDetailList = () => {
    const [activeTab, setActiveTab] = useState<any>('all-properties')
    const [propertiesCount, setPropertiesCount] = useState<any>()
    const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
    const [subTabListItem, setSubTabListItem] = useState<any>('allUnits')

    const [searchCommonArea, setSearchCommonArea] = useState<any>('')
    const [searchUnit, setSearchUnit] = useState<any>('')
    const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
    const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
    const [showFilter, setShowFilter] = useState<any>(false)

    const [requestTabTableData, setRequestTabTableData] = useState<any>([])
    const [requestTabItem] = useState<any>([])
    const navigate = useNavigate()
    const location: any = useLocation()

    const isBuilding: any = location.state ? location?.state?.key === 'floors' : false
    const floorDetail: any = location?.state

    const goToMiantenance = (v: any) => {
        // if (activeTab == 'requests' || activeTab == 'maintenance-reactive') {
        //   let type: any
        //   if (v?.maintenanceRequestType == 0)
        //     type = 'reactive'
        //   else if (v?.maintenanceRequestType == 1)
        //     type = 'planned'
        //   else if (v?.maintenanceRequestType == 2)
        //     type = 'preventative'

        //   if (v?.saveStatus == 0) navigate(`/maintenance/create-request/${type}/2/${v?._id}`)
        //   else navigate(`/maintenance/request/${type}/${v?._id}`, {
        //     state: {
        //       activeTab: activeTab
        //     }
        //   })
        // }
        // else if (activeTab == 'maintenance-jobs') {
        //   navigate(`/maintenance/request/${v?.maintenance_service_requests?.[0]?._id}/job/${v?._id}`)
        // }
    }

    // models
    const [addFloorModel, setAddFloorModel] = useState<any>(false)
    const [isEditFloorModel, setIsEditFloorModel] = useState<any>(false)
    const [modelData,setModelData] = useState<any>({
        floorName:'Floor 1'

    })

    // date range
    const [start_date, setStart] = useState<any>(null)
    const [end_date, setEnd] = useState<any>(null)
    const [startDatePayload, setStartDatePayload] = useState<any>(null)
    const [endDatePayload, setEndDatePayload] = useState<any>(null)
    const [isFormDirty, setIsFormDirty] = useState(false)

    const handleDatesChange = ({
        startDate,
        endDate,
    }: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }) => {
        if (startDate == null && endDate == null) {
            setStart(null)
            setStartDatePayload(null)
            setEnd(null)
            setEndDatePayload(null)
        } else {
            setStart(startDate)
            setStartDatePayload(startDate?.format('YYYY-MM-DD'))
            setEnd(endDate)
            setEndDatePayload(endDate?.format('YYYY-MM-DD'))

            setIsFormDirty(true)
            if (startDatePayload && endDatePayload) {
            }
        }
    }

    // filter
    const [hideContract, setHideContract] = React.useState<any>(true)
    const [hideStatus, setHideStatus] = React.useState<any>(true)
    const [hideTenant, setHideTenant] = React.useState<any>(true)
    const [hideProperty, setHideProperty] = React.useState<any>(true)
    const [hideFinancials, setHideFinancials] = React.useState<any>(true)

    const [contractNo, setContractNo] = useState<any>('')
    const [bedrooms, setBedrooms] = useState<any>([])
    const [unitType, setUnitType] = useState<any>([])
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)


    const [renewalStatus, setRenewalStatus] = useState<any>([0, 2, 7])
    const [formStatus, setFormStatus] = useState<any>(['draft', 'created', 'cancelled'])
    const [tenancyStatus, setTenancyStatus] = useState<any>([])
    const [tenantSource, setTenantSource] = useState<any>([0, 1])

    const [financial, setFinancial] = useState<any>({
        min: null,
        max: null,
    })
    const [paidPercentage, setPaidPercentage] = useState<any>(null)
    const [paid, setPaid] = useState<any>(null)
    const [notPaidPercentage, setNotPaidPercentage] = useState<any>(null)
    const [notPaid, setNotPaid] = useState<any>(null)

    const unitOptions = [
        { value: 'apartment', label: 'Apartment' },
        { value: 'pent_house', label: 'Penthouse' },
        { value: 'town_house', label: 'Townhouse' },
        { value: 'villa', label: 'Villa' },
        { value: 'common_area', label: 'Common Area' },
        { value: 'other', label: 'Other' },
    ]

    const bedroomsOptions = [
        {
            value: 1,
            label: '1 BR',
        },
        { value: 2, label: '2 BR' },
        { value: 3, label: '3 BR' },
        { value: 4, label: '4 BR' },
        { value: 5, label: '5 BR' },
        { value: 6, label: '6 BR' },
        { value: 7, label: '7 BR' },
        { value: 8, label: '8 BR' },
        { value: 9, label: '9 BR' },
        { value: 10, label: '10 BR' },
    ]


    const handleTermStartDateChange = (date: any) => {
        if (date != null) setStartDate(date.format('YYYY-MM-DD'))
        else setStartDate(null)
    }

    const handleTermEndDateChange = (date: any) => {
        if (date != null) setEndDate(date.format('YYYY-MM-DD'))
        else setEndDate(null)
    }


    const resetFilters = () => {
        // setSearchDevelopment('')
        // setSearchUnit('')
        // setSearchTenant('')
        // setFinancial({
        //   min: 0,
        //   max: null,
        // })
        // setStartDate(null)
        // setEndDate(null)
        // setContractNo('')
        // setBedrooms([])
        // setUnitType([])
        // setTenancyStatus([])
        // setFormStatus(['draft', 'created', 'cancelled'])
        // setRenewalStatus([0, 2, 7])
        // setPaidPercentage(null)
        // setPaid(null)
        // setNotPaidPercentage(null)
        // setNotPaid(null)
        // setIsRequestList(false)
    }

    // pagination
    const [page, setPage] = useState<any>(1)
    const [pageLimit, setPageLimit] = useState<any>(1)
    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)

    }


    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '25px',
        // marginLeft: '10px',
    }

    const searchIconStyle = {
        padding: '10px',
        color: 'gray',
    }

    const inputBaseStyle = {
        flex: 1,
        padding: '5px',
    }

    // React Select
    const customStyles = {
        container: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '50%',
        }),

        option: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            color: state.isSelected ? '#fff' : '#000;',
            backgroundColor: state.isSelected ? '#007a59;' : '',
            ':hover': {
                backgroundColor: '#e0f5f0',
                color: '#000',
            },

            fontWeight: '500',
        }),

        menu: (defaultStyles: any) => ({
            ...defaultStyles,
            border: '0.1rem solid #007a59 ',
            marginTop: '0px',
            borderRadius: '0px',
            zIndex: '1000',
        }),

        placeholder: (defaultStyles: any) => ({
            ...defaultStyles,
            fontStyle: 'Italic',
            zIndex: '1',
        }),

        input: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            backgroundColor: '#f5f8fa',
            color: ' #b5b5c3',
            fontWeight: '500',
            minWidth: '55px',
        }),

        control: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            backgroundColor: '#f5f8fa',
            boxShadow: 'none',
            color: 'white',
            border: '0.1rem solid #bec3cb',
            borderRadius: '2px',
            padding: '0rem',
            ':hover': {
                borderColor: '#007a59',
                backgroundColor: '#fff',
            },
            ':focus': {
                borderColor: '#007a59 !important',
                backgroundColor: '#fff',
            },
            '::placeholder': {
                fontStyle: 'Italic',
            },
        }),
        singleValue: (defaultStyles: any) => ({
            ...defaultStyles,
            color: '#5e6278',
            fontWeight: '600',
            zIndex: '1',
        }),
        valueContainer: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            // display: 'flex',
            flexWrap: 'nowrap',
            maxWidth: '100%',
            overflowX: 'scroll',
        }),
        multiValue: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            whiteSpace: 'nowrap',
            marginRight: '4px',
            minWidth: '55px',
        }),
    }

    const SquareHandle: React.FC<SquareHandleProps> = ({ value }) => (
        <div
            style={{
                width: '35px',
                height: '35px',
                fontSize: '12px',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#146C6A',
                marginLeft: `${value}%`,
                border: '2px solid #146C6A',
                transform: 'translate(0px, -32px)',
                position: 'relative',
                zIndex: '10',
                backgroundColor: 'white',
                fontWeight: '700',
            }}
        >
            {value}%
        </div>
    )

    const SquareHandleRed: React.FC<SquareHandleProps> = ({ value }) => (
        <div
            style={{
                width: '35px',
                height: '35px',
                fontSize: '12px',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#d54645',
                marginLeft: `${value}%`,
                border: '2px solid #d54645',
                transform: 'translate(0px, -32px)',
                position: 'relative',
                zIndex: '10',
                backgroundColor: 'white',
                fontWeight: '700',
            }}
        >
            {value}%
        </div>
    )


    return (
        <>
            <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
                <div className='row d-flex align-items-center'>
                    <div className='col-lg-6'>
                        <div className="row d-flex align-items-center">
                            <div className='col-7'>
                                <div className='d-flex align-items-center mb-1'>
                                    <span
                                        className='rounded-circle'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                                navigate('/properties/community-details/building-cluster/1/building/1')
                                            
                                        }}
                                    >
                                        <img src={backArrow} height='14' width='14' className='me-2' />
                                        {/* <img src={allBuildingBlue} width='40' /> */}
                                        <img src={floorsBlue} width='40' />
                                    </span>
                                    <h2 className='m-0 head-text ms-2'>
                                        {floorDetail?.name ? `${floorDetail.name}` : `-`}
                                    </h2>

                                </div>
                            </div>
                            <div className="col-5">
                                <img  src={editPen} width='20' onClick={() => {
                                    setAddFloorModel(true)
                                    setIsEditFloorModel(true)
                                }}/>
                            </div>

                        </div>

                    </div>
                    <div className='col-lg-6 d-flex justify-content-end'>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <img src={allBuildingBlue} width='40' className='me-2' />
                                <h2 className='m-0 head-text ms-2'>
                                    {floorDetail?.developmentName ? `${floorDetail?.developmentName}` : `-`}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='d-flex align-items-start pb-5 mb-5'
                    style={{ borderBottom: '0.1rem solid #bec3cb' }}
                ></div>

                {/* sub tabs start */}

                <div className=' ps-0 row mt-2'>
                    <div className='col-9'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
                            <li className='nav-item'>
                                <a
                                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'allUnits' && 'active'
                                        }`}
                                    data-bs-toggle='tab'
                                    onClick={() => {
                                        setSubTabListItem('allUnits')
                                        // localStorage.setItem('vendorActiveTab', 'info')
                                    }}
                                >
                                    All Units
                                </a>
                            </li>


                            <li className='nav-item'>
                                <a
                                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commonAreas' && 'active'
                                        }`}
                                    data-bs-toggle='tab'
                                    onClick={() => {
                                        setSubTabListItem('commonAreas')
                                        // localStorage.setItem('vendorActiveTab', 'requests')
                                    }}
                                >
                                    Common Areas
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className='col-lg-3'>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-end">
                                <button
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                                    onClick={() => {

                                        // setRequestModal(true)
                                        
                                            // navigate(`/properties/building-details/floors/1/add-property`)

                                        
                                        
                                    }}
                                >
                                    <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* sub tabs end */}

                {/* ingights start and filter */}
                <div className='row mt-7'>
                    <div className='col-12'>
                        <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{ gap: '20px' }}>
                            <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>{`${subTabListItem === 'commonAreas' ? 'Common Area' : 'Units'}`}</h4>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img
                                        src={unitsBlue}
                                        height={50}
                                        width={50}
                                        className='me-3'
                                    />
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>
                            
                            {(subTabListItem !== 'commonAreas') && <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>Tenants</h4>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img
                                        src={tenantBlue}
                                        height={50}
                                        width={50}
                                        className='me-3'
                                    />
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>
                            }
                            {(subTabListItem !== 'commonAreas') && <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>Leases</h4>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img
                                        src={leasesBlue}
                                        height={50}
                                        width={50}
                                        className='me-3'
                                    />
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>}
                            {(subTabListItem !== 'commonAreas') && <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>Occupancy</h4>
                                <div className='d-flex justify-content-start align-items-end'>
                                    <img
                                        src={occupancyBlue}
                                        height={50}
                                        width={50}
                                        className='me-3'
                                    />
                                    <h4 className='fw-bold ms-2'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>}
                            {(subTabListItem !== 'commonAreas') && <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>Income</h4>
                                <div className=''>
                                    <h4 className='fw-bold' style={{color:'#00b8a9'}}>AED</h4>
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>}
                            <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500  mb-4'>Expense</h4>
                                <div className=''>
                                    <h4 className='fw-bold' style={{color:'#e46464 '}}>AED</h4>
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>
                            <div className='customCard py-5 px-7 shadow' style={{ width: '12rem', height: '100px' }}>
                                <h4 className='text-gray-500 mb-4'>Rating</h4>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <img
                                        src={yellowStar}
                                        height={35}
                                        width={35}
                                        className='me-3'
                                    />
                                    <h4 className='fw-bold'>{propertiesSubCount?.maintenanceServiceJobCount ? propertiesSubCount?.maintenanceServiceJobCount : 0}</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5' style={{ gap: '0px' }}>
                        <div className={`d-flex align-items-center  px-0 col-10`} >
                            <div className='xyz me-5 pe-3'>
                                <label className='head-text' style={{ fontWeight: '600' }}>
                                    {' '}
                                    Search{' '}
                                </label>
                            </div>


                            {(subTabListItem !== 'commonAreas') && <div className='xyz me-4'>

                                <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                        placeholder='Unit'
                                        style={inputBaseStyle}
                                        inputProps={{ 'aria-label': 'Unit' }}
                                        value={searchUnit}
                                        onChange={(e: any) => setSearchUnit(e.target.value)}
                                    />
                                    <IconButton style={searchIconStyle} aria-label='Unit'>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>

                            </div>}

                            {(subTabListItem === 'commonAreas') && <div className='xyz me-4'>

                                <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                        placeholder='Common Area'
                                        style={inputBaseStyle}
                                        inputProps={{ 'aria-label': 'Common Area' }}
                                        value={searchCommonArea}
                                        onChange={(e: any) => setSearchCommonArea(e.target.value)}
                                    />
                                    <IconButton style={searchIconStyle} aria-label='Common Area'>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>

                            </div>}
                            <div className='test property me-4'>
                                <CustomDateRangePicker
                                    startDate={start_date}
                                    endDate={end_date}
                                    dateStatuses={[]}
                                    onDatesChange={handleDatesChange}
                                    //  datesToCompare={datesToCompare}
                                    autoSave={() => {


                                    }}
                                    from={'create'}
                                    disabled={false}
                                />
                            </div>
                            {/* filters icon */}
                            <div className='mb-2 col-md-1 pe-5 xyz'>
                                <div
                                    className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                                    onClick={() => setShowFilter(true)}
                                >
                                    <svg
                                        style={{
                                            height: '23px',
                                            width: '23px',
                                            marginLeft: '3px',
                                        }}
                                        viewBox='0 0 1024 1024'
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='me-1'
                                    >
                                        <path
                                            fill='#ffff'
                                            d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className={`px-0 col-2 d-flex justify-content-end`}>
                            {subTabListItem === 'floors' && <div className=''>
                                <button
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                                    onClick={() => {
                                        setAddFloorModel(true)
                                    }}
                                >
                                    <img src={addWhiteIcon} height={18} width={18} className='me-5' /> Add Floor
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
                {/* ingights end and fitlers*/}

                {(subTabListItem === 'allUnits') && <div className='card card-flush py-5  px-7'>
                    <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                        <div className='table-responsive'>
                            <table
                                className='table align-start table-row-dashed fs-8 gy-5 '
                                id='kt_ecommerce_sales_table'
                            >
                                <thead className='fs-7'>
                                    <th className='text-start min-w-50px ps-0'></th>
                                    <th className='text-start min-w-100px ps-0'>Unit No.</th>
                                    <th className='text-start min-w-100px ps-0'>Category</th>
                                    <th className='text-start min-w-125px ps-0'>Development</th>
                                    <th className='text-start min-w-100px ps-0'>Type</th>
                                    <th className='text-start min-w-100px ps-0'>Bedrooms</th>
                                    <th className='text-start min-w-100px ps-0'>Bathrooms</th>
                                    <th className='text-start min-w-75px ps-0 '>Size</th>
                                    <th className='text-center min-w-100px ps-0'>Lease Status</th>
                                    <th className='text-start min-w-125px ps-0 ms-5'>Occupancy Status</th>
                                    <th className='text-center min-w-125px ps-0 '>Occupancy Rate</th>
                                    <th className='text-start min-w-100px ps-0 '>Total Income</th>
                                    <th className='text-start min-w-100px ps-0'>Total Expanses</th>
                                    <th className='text-start min-w-100px ps-0'></th>
                                    <th className='text-start min-w-125px ps-0 '>Upcoming Income</th>
                                    <th className='text-start min-w-100px ps-0 '>Rating</th>
                                    <th className='text-start min-w-100px ps-0 '>Assigned</th>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {/* {requestTabTableData?.length > 0 ? ( */}
                                    {/* requestTabTableData?.map((requestTabItem: any, i: any) => { */}
                                    {/* return ( */}
                                    <tr>
                                        <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`}>
                                            <img src={
                                                 requestTabItem?.images?.length > 0
                                                 ? `${Bucket}${requestTabItem?.images?.[0]}` 
                                                  : requestTabItem?.unitType === 'town_house'
                                                    ? townhouse
                                                    : requestTabItem?.unitType === 'other'
                                                        ? other
                                                        : requestTabItem?.unitType === 'common_area'
                                                            ? common_area
                                                            : requestTabItem?.unitType === 'villa'
                                                                ? villa
                                                                : requestTabItem?.unitType === 'apartment'
                                                                    ? appartment
                                                                    : requestTabItem?.unitType === 'penthouse'
                                                                        ? penthouse
                                                                        : ''
                                            }
                                                height={30}
                                                className='m-0 p-0'
                                            />
                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {requestTabItem?.unitNo ? requestTabItem?.unitNo : '-'}

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {/* {requestTabItem?.unitCategory > 0 ? requestTabItem?.unitCategory : '-'} */}
                                            Residential
                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {/* {requestTabItem?.maintenance_main_service.length > 0 ? requestTabItem?.maintenance_main_service[0]?.name : '-'} */}
                                            Al Rowdah Tower 2

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.maintenance_sub_service.length > 0 ? requestTabItem?.maintenance_sub_service[0]?.name : '-'} */}
                                            <td className='text-start'>
                                                {requestTabItem?.unitType === 'town_house'
                                                    ? 'TownHouse'
                                                    : requestTabItem?.unitType === 'other'
                                                        ? 'Other'
                                                        : requestTabItem?.unitType === 'common_area'
                                                            ? 'Common Area'
                                                            : requestTabItem?.unitType === 'villa'
                                                                ? 'Villa'
                                                                : requestTabItem?.unitType === 'apartment'
                                                                    ? 'Apartment'
                                                                    : requestTabItem?.unitType === 'penthouse'
                                                                        ? 'Penthouse'
                                                                        : '-'}
                                            </td>

                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-125'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.community?.[0]?.name
                        ? requestTabItem?.community?.[0]?.name
                        : requestTabItem?.building?.[0]?.name ? requestTabItem?.building?.[0]?.name : '-'} */}
                                            {requestTabItem?.bedrooms ? `${requestTabItem?.bedrooms} BR` : '-'}

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 '
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}

                                            {/* {requestTabItem?.totalJobs != undefined ? requestTabItem?.totalJobs : '-'} */}
                                            {requestTabItem?.bathrooms ? `${requestTabItem?.bathrooms} Bath` : '-'}

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-75px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}

                                            {/* {requestTabItem?.totalJobs != undefined ? requestTabItem?.totalJobs : '-'} */}
                                            767 Sq ft

                                        </td>
                                        <td
                                            className='text-center cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            <div
                                                className={
                                                    requestTabItem?.tenancyStatus == 0
                                                        ? 'status booked-bar'
                                                        : requestTabItem?.tenancyStatus == 1
                                                            ? 'status active-bar'
                                                            : requestTabItem?.tenancyStatus == 2
                                                                ? 'status expiring-bar'
                                                                : requestTabItem?.tenancyStatus == 3
                                                                    ? 'status expired-bar'
                                                                    : 'status  no-st-bar'
                                                }
                                            >
                                                {requestTabItem?.tenancyStatus == 0
                                                    ? 'Booked'
                                                    : requestTabItem?.tenancyStatus == 1
                                                        ? 'Active'
                                                        : requestTabItem?.tenancyStatus == 2
                                                            ? 'Expiring'
                                                            : requestTabItem?.tenancyStatus == 3
                                                                ? 'Expired'
                                                                : '-'}
                                            </div>

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-125px ms-5'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {requestTabItem?.occupy === 0 ? 'Vacant ' : requestTabItem?.occupy === 1 ? 'Occupied' : '-'}
                                        </td>
                                        <td
                                            className='text-center cursor-pointer ps-0 min-w-125px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.handlerType == 0 ? 'Internal Team' : requestTabItem?.handlerType == 1 ? 'External Team' : '-'} */}
                                            12%
                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-125px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.vendorStatus == 0 ? "Not Submitted" : requestTabItem?.vendorStatus == 1 ? "Submitted" : '-'} */}
                                            AED  12,345,324.23

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-125px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.totalAmount != undefined ? `AED ${requestTabItem?.totalAmount}` : ' AED-'} */}
                                            AED  12,345,324.23


                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}

                                            30%
                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-125px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            AED  12,345,324.23
                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-100px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            <div className='d-flex align-items-center '>
                                                <img src={yellowStar} height={18} width={18} className='me-1' />
                                                {requestTabItem?.rating != undefined ? requestTabItem?.rating : '-'}
                                            </div>
                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-100px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            1
                                        </td>

                                    </tr>
                                    {/* ) */}
                                    {/* }) */}
                                    {/* ) : ( */}
                                    {/* <tr>
                <td colSpan={10} className='text-center'>
                  <img src={noData} alt='' width={350} />
                </td>
              </tr> */}
                                    {/* )} */}
                                </tbody>
                            </table>
                        </div>

                        {/* pagination start*/}
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='row mt-5'>
                                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                                    <div
                                        className='dataTables_paginate paging_simple_numbers'
                                        id='kt_ecommerce_sales_table_paginate'
                                    >
                                        <ReactPaginate
                                            breakLabel='...'
                                            nextLabel='>'
                                            pageClassName='paginate_button page-item +'
                                            pageLinkClassName='page-link'
                                            containerClassName='pagination'
                                            activeClassName='active'
                                            previousClassName='paginate_button page-item previous'
                                            nextClassName='paginate_button page-item next'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageLimit}
                                            forcePage={page - 1}
                                            previousLabel='<'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* pagination end*/}
                    </div>
                </div>}
                {(subTabListItem === 'commonAreas') && <div className='card card-flush py-5  px-7'>
                    <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                        <div className='table-responsive'>
                            <table
                                className='table align-start table-row-dashed fs-8 gy-5 '
                                id='kt_ecommerce_sales_table'
                            >
                                <thead className='fs-7'>
                                    <th className='text-start min-w-50px ps-0'></th>
                                    <th className='text-start min-w-100px ps-0'>Common Area No.</th>
                                    <th className='text-start min-w-100px ps-0'>Floor</th>
                                    <th className='text-start min-w-100px ps-0'>Type</th>
                                    <th className='text-start min-w-100px ps-0'>Total Expanses</th>
                                    <th className='text-start min-w-100px ps-0 '>Rating</th>
                                    <th className='text-start min-w-100px ps-0 '>Assigned</th>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {/* {requestTabTableData?.length > 0 ? ( */}
                                    {/* requestTabTableData?.map((requestTabItem: any, i: any) => { */}
                                    {/* return ( */}
                                    <tr>
                                        <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`}>
                                            <img src={common_area}
                                                height={30}
                                                className='m-0 p-0'
                                            />
                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {requestTabItem?.unitNo ? requestTabItem?.unitNo : '-'}

                                        </td>
                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {/* {requestTabItem?.unitCategory > 0 ? requestTabItem?.unitCategory : '-'} */}
                                            P1
                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {/* {requestTabItem?.maintenance_main_service.length > 0 ? requestTabItem?.maintenance_main_service[0]?.name : '-'} */}
                                            Parking

                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-100px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            {/* {requestTabItem?.totalAmount != undefined ? `AED ${requestTabItem?.totalAmount}` : ' AED-'} */}
                                            AED  12,345,324.23


                                        </td>



                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-100px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            <div className='d-flex align-items-center '>
                                                <img src={yellowStar} height={18} width={18} className='me-1' />
                                                {requestTabItem?.rating != undefined ? requestTabItem?.rating : '-'}
                                            </div>
                                        </td>

                                        <td
                                            className='text-start cursor-pointer ps-0 min-w-100px'
                                            onClick={() => goToMiantenance(requestTabItem)}
                                        >
                                            {' '}
                                            1
                                        </td>

                                    </tr>
                                    {/* ) */}
                                    {/* }) */}
                                    {/* ) : ( */}
                                    {/* <tr>
                <td colSpan={10} className='text-center'>
                  <img src={noData} alt='' width={350} />
                </td>
              </tr> */}
                                    {/* )} */}
                                </tbody>
                            </table>
                        </div>

                        {/* pagination start*/}
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='row mt-5'>
                                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                                    <div
                                        className='dataTables_paginate paging_simple_numbers'
                                        id='kt_ecommerce_sales_table_paginate'
                                    >
                                        <ReactPaginate
                                            breakLabel='...'
                                            nextLabel='>'
                                            pageClassName='paginate_button page-item +'
                                            pageLinkClassName='page-link'
                                            containerClassName='pagination'
                                            activeClassName='active'
                                            previousClassName='paginate_button page-item previous'
                                            nextClassName='paginate_button page-item next'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageLimit}
                                            forcePage={page - 1}
                                            previousLabel='<'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* pagination end*/}
                    </div>
                </div>}





                {/* Filters */}
                <div
                    className={showFilter == true ? 'filter-payments test ' : 'd-none'}
                    style={{
                        width: '420px',
                        backgroundColor: 'white',
                        zIndex: '999',
                        boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
                        paddingBottom: '50px',
                    }}
                >
                    <div
                        className='card m-0 p-0 '
                        style={{
                            overflow: 'hidden',
                            borderRadius: '0px',
                            boxShadow: 'none',
                        }}
                    >
                        {/* header */}
                        <h3
                            className='m-0 d-flex align-items-center msg_title'
                            style={{
                                justifyContent: 'space-between',
                                borderRadius: '0px',
                                zIndex: '1',
                                opacity: '0.85',
                            }}
                        >
                            Filter
                            <span className='mx-0' style={{ cursor: 'pointer', marginTop: '-20px' }}>
                                <img src={closes} alt='' onClick={() => setShowFilter(false)} />
                            </span>
                        </h3>

                        <h3
                            className='m-0 d-flex align-items-center '
                            style={{
                                justifyContent: 'flex-end',
                                borderRadius: '0px',
                                transform: 'translate(0px, -50px)',
                                color: 'white',
                                backgroundColor: '#146C6A',
                                paddingBottom: '5px',
                                background:
                                    'linear-gradient(41deg, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 0%)',
                            }}
                        >
                            <span className='mx-1' style={{ cursor: 'pointer' }}>
                                <svg
                                    style={{
                                        height: '40px',
                                        width: '38px',
                                        marginRight: '7px',
                                        marginLeft: '-10px',
                                        marginTop: '5px',
                                        transform: 'translate(-30px, 0px)',
                                    }}
                                    viewBox='0 0 1024 1024'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='me-1'
                                >
                                    <path
                                        fill='#ffff'
                                        d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                                    />
                                </svg>
                            </span>
                        </h3>
                    </div>

                    <div
                        style={{
                            height: '100%',
                            overflow: 'scroll',
                            transform: 'translate(0px, -50px)',
                            overflowX: 'hidden',
                        }}
                    >
                        {/* Contract Details */}
                        <div className='min-w-200px  ps-3 pt-1 card' style={{ borderRadius: '0px' }}>
                            <div className='ms-auto mt-3 me-5'>
                                <div
                                    className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                                    onClick={() => resetFilters()}
                                >
                                    <img src={sync} height={22} width={24} className='cursor-pointer' />
                                </div>
                            </div>
                            <label className='mb-2 ms-2' style={{ fontWeight: '700', fontSize: '14px' }}>
                                {' '}
                                Contract Details
                            </label>

                            {hideContract !== true && (
                                <>
                                    {/* Contract No */}
                                    <div className='d-flex align-items-center mb-2 xyz'>
                                        <img src={windowImg} height='14' width='14' className='me-4 ms-2 ' />

                                        <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                                placeholder='Contract No...'
                                                style={inputBaseStyle}
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={contractNo}
                                                onChange={(e: any) => setContractNo(e.target.value)}
                                            />
                                            <IconButton style={searchIconStyle} aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>
                                    <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-3 mx-4'></div>
                                    {/* Term Date */}
                                    <div className='d-flex align-items-center ms-2 mt-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Term Date
                                        </label>
                                    </div>
                                    <div className='d-flex mb-2 ms-2 ' style={{ paddingLeft: '27px' }}>
                                        {startDate == null ? (
                                            <DatePicker
                                                onChange={handleTermStartDateChange}
                                                style={{ width: '75%' }}
                                                className='me-5'
                                                placeholder='From'
                                            />
                                        ) : (
                                            <DatePicker
                                                defaultValue={moment(startDate, 'DD-MM-YYYY')}
                                                onChange={handleTermStartDateChange}
                                                style={{ width: '75%' }}
                                                className='me-5'
                                                placeholder='From'
                                            />
                                        )}
                                        {endDate == null ? (
                                            <DatePicker
                                                onChange={handleTermEndDateChange}
                                                style={{ width: '75%' }}
                                                className='me-5'
                                                placeholder='To'
                                            />
                                        ) : (
                                            <DatePicker
                                                defaultValue={moment(endDate, 'DD-MM-YYYY')}
                                                onChange={handleTermEndDateChange}
                                                style={{ width: '75%' }}
                                                className='me-5'
                                                placeholder='To'
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            <div className='ms-auto mt-3 mb-3 me-5'>
                                <div className='br-arr d-flex align-items-center justify-content-center'>
                                    {hideContract == false ? (
                                        <img
                                            src={upGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideContract(true)}
                                        />
                                    ) : (
                                        <img
                                            src={downGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideContract(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Status */}
                        <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{ borderRadius: '0px' }}>
                            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
                                {' '}
                                Status
                            </label>

                            {hideStatus == false && (
                                <>
                                    {activeTab != 'booked' &&
                                        activeTab != 'active' &&
                                        activeTab != 'expiring' &&
                                        activeTab != 'expired' && (
                                            <>
                                                <div className='d-flex align-items-baseline ms-2 '>
                                                    <img src={windowImg} height='14' width='14' className='me-4' />

                                                    <label
                                                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                                        style={{ fontWeight: '600' }}
                                                    >
                                                        Lease Status
                                                    </label>
                                                </div>
                                                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                                    <div className='col-3 d-flex align-items-center xyz'>
                                                        <input
                                                            className='form-check-input me-3 cursor-pointer'
                                                            type='radio'
                                                            checked={
                                                                tenancyStatus.length == 4 || tenancyStatus.length == 0 ? true : false
                                                            }
                                                            onChange={(e: any) => {
                                                                if (e.target.checked == true) {
                                                                    let values = [0, 1, 2, 3]
                                                                    setTenancyStatus(values)
                                                                } else {
                                                                    setTenancyStatus([])
                                                                }
                                                            }}
                                                        />{' '}
                                                        All
                                                    </div>
                                                </div>

                                                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                                    <div className='col-3 d-flex align-items-center test'>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                id='vacant0'
                                                                name='none'
                                                                checked={
                                                                    (tenancyStatus[0] == 0 ||
                                                                        tenancyStatus[1] == 0 ||
                                                                        tenancyStatus[2] == 0 ||
                                                                        tenancyStatus[3] == 0) &&
                                                                        tenancyStatus.length != 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        if (tenancyStatus.length == 4) {
                                                                            let values = [0]
                                                                            setTenancyStatus(values)
                                                                        } else {
                                                                            let values = [...tenancyStatus]
                                                                            values[values.length] = 0
                                                                            setTenancyStatus(values)
                                                                        }
                                                                    } else {
                                                                        let values = [...tenancyStatus]
                                                                        if (values[0] == 0) values.splice(0, 1)
                                                                        else if (values[1] == 0) values.splice(1, 1)
                                                                        else if (values[2] == 0) values.splice(2, 1)
                                                                        else values.splice(3, 1)

                                                                        setTenancyStatus(values)
                                                                    }
                                                                }}
                                                            />
                                                            <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                                {' '}
                                                                Booked
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className='col-3 d-flex align-items-center'>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                id='vacant0'
                                                                name='none'
                                                                checked={
                                                                    (tenancyStatus[0] == 1 ||
                                                                        tenancyStatus[1] == 1 ||
                                                                        tenancyStatus[2] == 1 ||
                                                                        tenancyStatus[3] == 1) &&
                                                                        tenancyStatus.length != 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        if (tenancyStatus.length == 4) {
                                                                            let values = [1]
                                                                            setTenancyStatus(values)
                                                                        } else {
                                                                            let values = [...tenancyStatus]
                                                                            values[values.length] = 1
                                                                            setTenancyStatus(values)
                                                                        }
                                                                    } else {
                                                                        let values = [...tenancyStatus]
                                                                        if (values[0] == 1) values.splice(0, 1)
                                                                        else if (values[1] == 1) values.splice(1, 1)
                                                                        else if (values[2] == 1) values.splice(2, 1)
                                                                        else values.splice(3, 1)

                                                                        setTenancyStatus(values)
                                                                    }
                                                                }}
                                                            />
                                                            <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                                {' '}
                                                                Active
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 d-flex align-items-center test'>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                id='vacant0'
                                                                name='none'
                                                                checked={
                                                                    (tenancyStatus[0] == 2 ||
                                                                        tenancyStatus[1] == 2 ||
                                                                        tenancyStatus[2] == 2 ||
                                                                        tenancyStatus[3] == 2) &&
                                                                        tenancyStatus.length != 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        if (tenancyStatus.length == 4) {
                                                                            let values = [2]
                                                                            setTenancyStatus(values)
                                                                        } else {
                                                                            let values = [...tenancyStatus]
                                                                            values[values.length] = 2
                                                                            setTenancyStatus(values)
                                                                        }
                                                                    } else {
                                                                        let values = [...tenancyStatus]
                                                                        if (values[0] == 2) values.splice(0, 1)
                                                                        else if (values[1] == 2) values.splice(1, 1)
                                                                        else if (values[2] == 2) values.splice(2, 1)
                                                                        else values.splice(3, 1)

                                                                        setTenancyStatus(values)
                                                                    }
                                                                }}
                                                            />
                                                            <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                                {' '}
                                                                Expiring
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='col-3 d-flex align-items-center test'>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                            <input
                                                                className='form-check-input'
                                                                type='checkbox'
                                                                id='vacant0'
                                                                name='none'
                                                                checked={
                                                                    (tenancyStatus[0] == 3 ||
                                                                        tenancyStatus[1] == 3 ||
                                                                        tenancyStatus[2] == 3 ||
                                                                        tenancyStatus[3] == 3) &&
                                                                        tenancyStatus.length != 4
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        if (tenancyStatus.length == 4) {
                                                                            let values = [3]
                                                                            setTenancyStatus(values)
                                                                        } else {
                                                                            const values = [...tenancyStatus]

                                                                            values[values.length] = 3
                                                                            setTenancyStatus(values)
                                                                        }
                                                                    } else {
                                                                        const values = [...tenancyStatus]
                                                                        if (values[0] == 3) values.splice(0, 1)
                                                                        else if (values[1] == 3) values.splice(1, 1)
                                                                        else if (values[2] == 3) values.splice(2, 1)
                                                                        else values.splice(3, 1)

                                                                        setTenancyStatus(values)
                                                                    }
                                                                }}
                                                            />
                                                            <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                                {' '}
                                                                Expired
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                                            </>
                                        )}

                                    {activeTab != 'terminated' && (
                                        <>
                                            <div className='d-flex align-items-baseline mt-2 ms-2'>
                                                <img src={windowImg} height='14' width='14' className='me-4' />

                                                <label
                                                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                                    style={{ fontWeight: '600' }}
                                                >
                                                    Renewal Status
                                                </label>
                                            </div>
                                            <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                                <div className='col-3 d-flex align-items-center xyz'>
                                                    <input
                                                        className='form-check-input me-3 cursor-pointer'
                                                        type='radio'
                                                        checked={
                                                            renewalStatus.length == 3 || renewalStatus.length == 0 ? true : false
                                                        }
                                                        onChange={(e: any) => {
                                                            if (e.target.checked == true) {
                                                                let values = [0, 2, 7]
                                                                setRenewalStatus(values)
                                                            } else {
                                                                setRenewalStatus([])
                                                            }
                                                        }}
                                                    />{' '}
                                                    All
                                                </div>
                                            </div>

                                            <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                                <div className='col-4 d-flex align-items-center test'>
                                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            id='vacant0'
                                                            name='none'
                                                            checked={
                                                                (renewalStatus[0] == 0 ||
                                                                    renewalStatus[1] == 0 ||
                                                                    renewalStatus[2] == 0) &&
                                                                    renewalStatus.length != 3
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e: any) => {
                                                                if (e.target.checked) {
                                                                    if (renewalStatus.length == 3) {
                                                                        let values = [0]
                                                                        setRenewalStatus(values)
                                                                    } else {
                                                                        let values = [...renewalStatus]
                                                                        values[values.length] = 0
                                                                        setRenewalStatus(values)
                                                                    }
                                                                } else {
                                                                    let values = [...renewalStatus]
                                                                    if (values[0] == 0) values.splice(0, 1)
                                                                    else if (values[1] == 0) values.splice(1, 1)
                                                                    else values.splice(2, 1)
                                                                    setRenewalStatus(values)
                                                                }
                                                            }}
                                                        />
                                                        <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                            {' '}
                                                            Not Renewed
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='col-4 d-flex align-items-center test'>
                                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            id='vacant0'
                                                            name='none'
                                                            checked={
                                                                (renewalStatus[0] == 2 ||
                                                                    renewalStatus[1] == 2 ||
                                                                    renewalStatus[2] == 2) &&
                                                                    renewalStatus.length != 3
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e: any) => {
                                                                if (e.target.checked) {
                                                                    if (renewalStatus.length == 3) {
                                                                        let values = [2]
                                                                        setRenewalStatus(values)
                                                                    } else {
                                                                        let values = [...renewalStatus]
                                                                        values[values.length] = 2
                                                                        setRenewalStatus(values)
                                                                    }
                                                                } else {
                                                                    let values = [...renewalStatus]
                                                                    if (values[0] == 2) values.splice(0, 1)
                                                                    else if (values[1] == 2) values.splice(1, 1)
                                                                    else values.splice(2, 1)
                                                                    setRenewalStatus(values)
                                                                }
                                                            }}
                                                        />
                                                        <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                            {' '}
                                                            Renewed
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='col-4 d-flex align-items-center test'>
                                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                        <input
                                                            className='form-check-input'
                                                            type='checkbox'
                                                            id='vacant0'
                                                            name='none'
                                                            checked={
                                                                (renewalStatus[0] == 7 ||
                                                                    renewalStatus[1] == 7 ||
                                                                    renewalStatus[2] == 7) &&
                                                                    renewalStatus.length != 3
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e: any) => {
                                                                if (e.target.checked) {
                                                                    if (renewalStatus.length == 3) {
                                                                        let values = [7]
                                                                        setRenewalStatus(values)
                                                                    } else {
                                                                        let values = [...renewalStatus]
                                                                        values[values.length] = 7
                                                                        setRenewalStatus(values)
                                                                    }
                                                                } else {
                                                                    let values = [...renewalStatus]
                                                                    if (values[0] == 7) values.splice(0, 1)
                                                                    else if (values[1] == 7) values.splice(1, 1)
                                                                    else values.splice(2, 1)
                                                                    setRenewalStatus(values)
                                                                }
                                                            }}
                                                        />
                                                        <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                            {' '}
                                                            Terminated
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                                        </>
                                    )}

                                    {/* Form Status */}

                                    <div className='d-flex align-items-baseline mt-2 ms-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Form Status
                                        </label>
                                    </div>
                                    <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-3 d-flex align-items-center xyz'>
                                            <input
                                                className='form-check-input me-3 cursor-pointer'
                                                type='radio'
                                                checked={formStatus.length == 3 || formStatus.length == 0 ? true : false}
                                                onChange={(e: any) => {
                                                    if (e.target.checked == true) {
                                                        let values = ['draft', 'created', 'cancelled']
                                                        setFormStatus(values)
                                                    } else {
                                                        setFormStatus([])
                                                    }
                                                }}
                                            />{' '}
                                            All
                                        </div>
                                    </div>

                                    <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-4 d-flex align-items-center test'>
                                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant0'
                                                    name='none'
                                                    checked={
                                                        (formStatus[0] == 'draft' ||
                                                            formStatus[1] == 'draft' ||
                                                            formStatus[2] == 'draft') &&
                                                            formStatus.length != 3
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            if (formStatus.length == 3) {
                                                                let values = ['draft']
                                                                setFormStatus(values)
                                                            } else {
                                                                let values = [...formStatus]
                                                                values[values.length] = 'draft'
                                                                setFormStatus(values)
                                                            }
                                                        } else {
                                                            let values = [...formStatus]
                                                            if (values[0] == 'draft') values.splice(0, 1)
                                                            else if (values[1] == 'draft') values.splice(1, 1)
                                                            else values.splice(2, 1)
                                                            setFormStatus(values)
                                                        }
                                                    }}
                                                />
                                                <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                    {' '}
                                                    Draft
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-4 d-flex align-items-center test'>
                                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant0'
                                                    name='none'
                                                    checked={
                                                        (formStatus[0] == 'created' ||
                                                            formStatus[1] == 'created' ||
                                                            formStatus[2] == 'created') &&
                                                            formStatus.length != 3
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            if (formStatus.length == 3) {
                                                                let values = ['created']
                                                                setFormStatus(values)
                                                            } else {
                                                                let values = [...formStatus]
                                                                values[values.length] = 'created'
                                                                setFormStatus(values)
                                                            }
                                                        } else {
                                                            let values = [...formStatus]
                                                            if (values[0] == 'created') values.splice(0, 1)
                                                            else if (values[1] == 'created') values.splice(1, 1)
                                                            else values.splice(2, 1)
                                                            setFormStatus(values)
                                                        }
                                                    }}
                                                />
                                                <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                    {' '}
                                                    Created
                                                </p>
                                            </div>
                                        </div>

                                        <div className='col-4 d-flex align-items-center test'>
                                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant0'
                                                    name='none'
                                                    checked={
                                                        (formStatus[0] == 'cancelled' ||
                                                            formStatus[1] == 'cancelled' ||
                                                            formStatus[2] == 'cancelled') &&
                                                            formStatus.length != 3
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            if (formStatus.length == 3) {
                                                                let values = ['cancelled']
                                                                setFormStatus(values)
                                                            } else {
                                                                let values = [...formStatus]
                                                                values[values.length] = 'cancelled'
                                                                setFormStatus(values)
                                                            }
                                                        } else {
                                                            let values = [...formStatus]
                                                            if (values[0] == 'cancelled') values.splice(0, 1)
                                                            else if (values[1] == 'cancelled') values.splice(1, 1)
                                                            else values.splice(2, 1)
                                                            setFormStatus(values)
                                                        }
                                                    }}
                                                />
                                                <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                    {' '}
                                                    Cancelled
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className='ms-auto mt-3 mb-3 me-5'>
                                <div className='br-arr d-flex align-items-center justify-content-center'>
                                    {hideStatus == false ? (
                                        <img
                                            src={upGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideStatus(true)}
                                        />
                                    ) : (
                                        <img
                                            src={downGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideStatus(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Tenant */}
                        <div className='min-w-200px card ps-3  pt-1  mt-1' style={{ borderRadius: '0px' }}>
                            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
                                {' '}
                                Tenant
                            </label>
                            {!hideTenant && (
                                <>
                                    <div className='d-flex align-items-baseline ms-2 '>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Source
                                        </label>
                                    </div>
                                    <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-3 d-flex align-items-center xyz'>
                                            <input
                                                className='form-check-input me-3 cursor-pointer'
                                                type='radio'
                                                checked={tenantSource.length == 2 || tenantSource.length == 0 ? true : false}
                                                onChange={(e: any) => {
                                                    if (e.target.checked == true) {
                                                        let values = [0, 1]
                                                        setTenantSource(values)
                                                    } else {
                                                        setTenantSource([])
                                                    }
                                                }}
                                            />{' '}
                                            All
                                        </div>
                                    </div>

                                    <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-4 d-flex align-items-center test'>
                                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant0'
                                                    name='none'
                                                    checked={
                                                        (tenantSource[0] == 0 || tenantSource[1] == 0) && tenantSource.length != 2
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            if (tenantSource.length == 2) {
                                                                // setTenantSource([])
                                                                let values = [0]
                                                                setTenantSource(values)
                                                            } else {
                                                                let values = [...tenantSource]
                                                                values[values.length] = 0
                                                                setTenantSource(values)
                                                            }
                                                        } else {
                                                            let values = [...tenantSource]
                                                            if (values[0] == 0) values.splice(0, 1)
                                                            else values.splice(1, 1)
                                                            setTenantSource(values)
                                                        }
                                                    }}
                                                />
                                                <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                    {' '}
                                                    Agent
                                                </p>
                                            </div>
                                        </div>

                                        <div className='col-4 d-flex align-items-center'>
                                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant0'
                                                    name='none'
                                                    checked={
                                                        (tenantSource[1] == 1 || tenantSource[0] == 1) && tenantSource.length != 2
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={(e: any) => {
                                                        if (e.target.checked) {
                                                            if (tenantSource.length == 2) {
                                                                // setTenantSource([])
                                                                let values = [1]
                                                                setTenantSource(values)
                                                            } else {
                                                                let values = [...tenantSource]
                                                                values[values.length] = 1
                                                                setTenantSource(values)
                                                            }
                                                        } else {
                                                            let values = [...tenantSource]
                                                            if (values[0] == 1) values.splice(0, 1)
                                                            else values.splice(1, 1)
                                                            setTenantSource(values)
                                                        }
                                                    }}
                                                />
                                                <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                                                    {' '}
                                                    Direct
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className='ms-auto mt-3 mb-3 me-5'>
                                <div className='br-arr d-flex align-items-center justify-content-center'>
                                    {hideTenant == false ? (
                                        <img
                                            src={upGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideTenant(true)}
                                        />
                                    ) : (
                                        <img
                                            src={downGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideTenant(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Property */}
                        <div className='min-w-200px ps-3  pt-1  mt-1 card' style={{ borderRadius: '0px' }}>
                            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
                                {' '}
                                Property
                            </label>
                            {!hideProperty && (
                                <>
                                    <div className='d-flex align-items-baseline ms-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Type
                                        </label>
                                    </div>
                                    <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-12'>
                                            <Select
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={unitOptions}
                                                onChange={(e: any) => {
                                                    const values = [...unitType]
                                                    values[0] = e.value
                                                    setUnitType(values)
                                                }}
                                                placeholder={'Search...'}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Bedrooms
                                        </label>
                                    </div>
                                    <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                                        <div className='col-12'>
                                            <Select
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={bedroomsOptions}
                                                onChange={(e: any) => {
                                                    let values: any = []
                                                    for (let i = 0; i < e?.length; i++) {
                                                        values[i] = Number(e[i]?.value)
                                                    }
                                                    setBedrooms(values)
                                                }}
                                                placeholder={'Search..'}
                                                isMulti
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className='ms-auto mt-3 mb-5 me-5'>
                                <div className='br-arr d-flex align-items-center justify-content-center'>
                                    {hideProperty == false ? (
                                        <img
                                            src={upGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideProperty(true)}
                                        />
                                    ) : (
                                        <img
                                            src={downGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideProperty(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Financials */}
                        <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{ borderRadius: '0px' }}>
                            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
                                {' '}
                                Financials
                            </label>

                            {!hideFinancials && (
                                <>
                                    {/* Rent Value */}
                                    <div className='d-flex align-items-center ms-2 mt-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Rent Value
                                        </label>
                                    </div>
                                    <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                                        <Paper component='form' style={searchContainerStyle} className='me-5'>
                                            <InputBase
                                                type='number'
                                                placeholder='From (Amount)'
                                                style={inputBaseStyle}
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={financial.min == null ? 0 : financial.min}
                                                onChange={(e: any) => {
                                                    const values = { ...financial }
                                                    if (e.target.value.trim()?.length > 0) {
                                                        values.min = parseInt(e.target.value)
                                                        setFinancial(values)
                                                    } else {
                                                        values.min = null
                                                        setFinancial(values)
                                                    }
                                                }}
                                            />
                                            <IconButton style={searchIconStyle} aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>

                                        <Paper component='form' style={searchContainerStyle} className='me-5'>
                                            <InputBase
                                                type='number'
                                                placeholder='Up to (Amount)'
                                                style={inputBaseStyle}
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={financial.max}
                                                onChange={(e: any) => {
                                                    const values = { ...financial }
                                                    if (e.target.value.trim()?.length > 0) {
                                                        values.max = parseInt(e.target.value)
                                                        setFinancial(values)
                                                    } else {
                                                        values.max = null
                                                        setFinancial(values)
                                                    }
                                                }}
                                            />
                                            <IconButton style={searchIconStyle} aria-label='search'>
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>

                                    <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-3 mx-4'></div>

                                    <div className='d-flex align-items-center ms-2 mt-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Paid
                                        </label>
                                    </div>
                                    <div className='row mb-2 ms-2 xyz mt-2' style={{ paddingLeft: '27px', width: '95%' }}>
                                        <div className='col-12' style={{ height: '30px' }}>
                                            <input
                                                className='progress-filter mt-3'
                                                type='range'
                                                min='0'
                                                max='100'
                                                value={paid}
                                                onMouseUp={() => {
                                                    setPaidPercentage(paid)
                                                }}
                                                onChange={(e: any) => {
                                                    setPaid(Number(e.target.value))
                                                }}
                                            />

                                            <SquareHandle value={paid} />

                                            <div
                                                style={{
                                                    width: `100%`,
                                                    height: '10px',
                                                    backgroundColor: '#146c6a',
                                                    borderRadius: '10px',
                                                    transform: 'translate(0px, -54px)',
                                                }}
                                            ></div>
                                        </div>
                                    </div>

                                    <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-3 mx-4'></div>

                                    <div className='d-flex align-items-center ms-2 mt-2'>
                                        <img src={windowImg} height='14' width='14' className='me-4' />

                                        <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                                            style={{ fontWeight: '600' }}
                                        >
                                            Not Paid
                                        </label>
                                    </div>
                                    <div className='row mb-2 ms-2 xyz mt-2' style={{ paddingLeft: '27px', width: '95%' }}>
                                        <div className='col-12' style={{ height: '30px' }}>
                                            <input
                                                className='progress-filter mt-3'
                                                type='range'
                                                min='0'
                                                max='100'
                                                value={notPaid}
                                                onMouseUp={() => {
                                                    setNotPaidPercentage(notPaid)
                                                }}
                                                onChange={(e) => {
                                                    setNotPaid(Number(e.target.value))
                                                }}
                                            />

                                            <SquareHandleRed value={notPaid} />

                                            <div
                                                style={{
                                                    width: `100%`,
                                                    height: '10px',
                                                    backgroundColor: '#d54645 ',
                                                    borderRadius: '10px',
                                                    transform: 'translate(0px, -54px)',
                                                }}
                                            ></div>

                                            {/* You can display the progress bar visually if needed */}
                                        </div>
                                    </div>
                                    <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}></div>
                                </>
                            )}

                            <div className='ms-auto mt-3 mb-5 me-5'>
                                <div className='br-arr d-flex align-items-center justify-content-center'>
                                    {hideFinancials == false ? (
                                        <img
                                            src={upGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideFinancials(true)}
                                        />
                                    ) : (
                                        <img
                                            src={downGreen}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer'
                                            onClick={() => setHideFinancials(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {addFloorModel && (
                <AddFloorModel
                    show={addFloorModel}
                    isEdit={isEditFloorModel}
                    modelData={modelData}
                    isBuilding={true}
                    isUnitCluster={false}
                    isMixedCluster={false}
                    isBuildingCluster={false}
                    handleClose={() => {
                        setAddFloorModel(false)
                    }}
                    updateModelData={(updatedData:any) => {
                        setModelData({...modelData,floorName:updatedData?.floorName})
                    }}
                />
            )}

        </>

    )
}

export default BuildingFloorDetailList

import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import reactiveImg from '../../../../../img/reactive.png'
import reactiveWhiteImg from '../../../../../img/reactiveWhite.png'
import plannedImg from '../../../../../img/planned.png'
import plannedWhiteImg from '../../../../../img/plannedWhite.png'
import preventativeImg from '../../../../../img/preventative.png'
import preventativeWhiteImg from '../../../../../img/preventativeWhite.png'
import informationImg from '../../../../../img/information.png'
import switchOffIcon from '../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../img/switch-on.png'
import removeIcon from '../../../../../img/x-mark.png'
import tenantUserHollowIconGreen from '../../../../../img/tenant-user-hollow-icon-green.png'

import {useNavigate} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import {ApiPost} from '../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {IoMdInformationCircleOutline} from 'react-icons/io'

interface ButtonProps {
  show: any
  handleClose?: any
  updateLists?: any
  addedLists?: any
}

const InviteModel = ({show, handleClose, updateLists, addedLists}: ButtonProps) => {
  const navigate = useNavigate()
  let totalValid: any = 0

  //TENANT DETAIL SECTION (CHECKBOXES)
  const [detailSection, setDetailSection] = useState(true)
  const labels: any = {
    firstName: 'First Name',
    lastName: 'Last Name',
    // email: 'Email',
    phoneNumber: 'Phone Number',
    DOB: 'Date of Birth',
    gender: 'Gender',
  }
  const [checkboxesState, setCheckboxesState] = useState<any>({
    firstName: true,
    lastName: true,
    // email: false,
    phoneNumber: false,
    DOB: false,
    gender: false,
  })
  const handleCheckboxChange = (key: any) => {
    setCheckboxesState((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }))
  }

  const renderCheckboxes = () => {
    return Object.keys(checkboxesState).map((key: any) => (
      <div key={key} className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0' style={{paddingRight: '20px'}}>
        <input
          className='form-check-input'
          type='checkbox'
          id={`checkbox-${key}`}
          name={key}
          checked={checkboxesState[key]}
          onChange={() => handleCheckboxChange(key)}
        />
        <label htmlFor={`checkbox-${key}`} style={{marginLeft: '10px'}}>
          {labels[key]}
        </label>
      </div>
    ))
  }

  //DOCUMENT DETAIL SECTION
  const [documentSection, setDocumentSection] = useState(true)
  const [documentDetails, setDocumentDetails] = useState<any>([
    {
      name: 'Passport',
      isCheck: false,
    },
    {
      name: 'Residency',
      isCheck: false,
    },
    {
      name: 'ID',
      isCheck: false,
    },
  ])

  const addDocument = () => {
    if (documentDetails?.some((inputObj: any) => inputObj?.name?.trim() === '')) {
      return
    }
    setDocumentDetails((prevDetails: any) => [...prevDetails, {name: '', isCheck: false}])
  }

  const deleteDocument = (index: any) => {
    setDocumentDetails((prevDetails: any) => {
      const newDetails = prevDetails.filter((item: any, i: any) => i !== index)
      return newDetails
    })
  }

  const handleNameChange = (index: any, newName: any) => {
    setDocumentDetails((prevDetails: any) => {
      const newDetails = [...prevDetails]
      const captilizeWord = capitalizeFirstLetter(newName?.trimStart())
      newDetails[index].name = captilizeWord
      return newDetails
    })
  }

  const handleChangeDocumentCheckbox = (index: any) => {
    setDocumentDetails((prevDetails: any) => {
      const newDetails = [...prevDetails]
      newDetails[index].isCheck = !newDetails[index].isCheck
      return newDetails
    })
  }

  const renderDocumentDetailsCheckboxes = () => {
    return documentDetails.map((doc: any, index: any) => (
      <div key={index} className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 mb-5' style={{paddingRight: '50px'}}>
        <input className='form-check-input' type='checkbox' checked={doc.isCheck} onChange={() => handleChangeDocumentCheckbox(index)} />
        {index >= 3 ? (
          <>
            <input
              type='text'
              placeholder='Enter Document Name'
              value={doc.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
              style={{marginLeft: '10px', borderRadius: '5px', outline: 'none', border: '1px solid  #a6a6a6', paddingLeft: '10px'}}
            />
            <img onClick={() => deleteDocument(index)} src={removeIcon} height={14} width={14} className='ms-2 cursor-pointer' />
          </>
        ) : (
          <label style={{marginLeft: '10px'}}>{doc.name}</label>
        )}
      </div>
    ))
  }

  //NOTE DETAIL SECTION
  const [noteSection, setNoteSection] = useState(true)
  const [note, setNote] = useState<any>('')
  const handleChangeNote = (noteValue: any) => {
    const captilizeWord = capitalizeFirstLetter(noteValue?.trimStart())
    setNote(captilizeWord)
  }

  const renderNote = () => {
    return <textarea placeholder='Enter Notes' value={note} onChange={(e) => handleChangeNote(e.target.value)} className='custom-textarea' />
  }

  //  ADD INVITEES
  const [emailList, setEmailList] = useState<any>([{email: '', isValid: true, isDuplicate: false}])

  const addEmailInput = () => {
    setEmailList((prevDetails: any) => [...prevDetails, {email: '', isValid: true, isDuplicate: false}])
  }

  //   const handleChangeEmail = (index: any, newEmail: any) => {
  //     setEmailList((prevDetails: any) => {
  //       const newDetails = [...prevDetails]
  //       newDetails[index].email = newEmail
  //       return newDetails
  //     })

  //     debouncedValidateEmail(index, newEmail)
  //   }

  const handleChangeEmail = (index: any, newEmail: any) => {
    setEmailList((prevDetails: any) => {
      const newDetails = [...prevDetails]
      newDetails[index].email = newEmail
      newDetails[index].isValid = true // Reset validation state
      newDetails[index].isDuplicate = false // Reset duplication state
      return newDetails
    })
  }

  const deleteEmailInput = (index: any) => {
    setEmailList((prevDetails: any) => {
      const newDetails = prevDetails.filter((item: any, i: any) => i !== index)
      return newDetails
    })
  }

  const validateEmail = (email: any) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    return regex.test(email)
  }

  const checkForDuplicate = (email: any) => {
    return emailList.filter((item: any) => item.email === email).length > 1
  }

  //   const handleBlur = (index: any) => {
  //     debouncedValidateEmail(index, emailList[index].email)
  //   }

  //   const debounce = (func: any, delay: any) => {
  //     let timeoutId: any
  //     return (...args: any) => {
  //       if (timeoutId) clearTimeout(timeoutId)
  //       timeoutId = setTimeout(() => {
  //         func(...args)
  //       }, delay)
  //     }
  //   }

  //   const debouncedValidateEmail = useCallback(
  //     debounce((index: any, newEmail: any) => {
  //       setEmailList((prevDetails: any) => {
  //         const newDetails = [...prevDetails]
  //         newDetails[index].isValid = validateEmail(newEmail)
  //         if (newDetails[index].isValid) {
  //           newDetails[index].isDuplicate = checkForDuplicate(newEmail)
  //         } else {
  //           newDetails[index].isDuplicate = false // Reset duplicate status if not valid
  //         }
  //         return newDetails
  //       })
  //     }, 1000),
  //     [emailList]
  //   )

  const renderEmailInputs = () => {
    return emailList?.map((emailInput: any, index: any) => {
      return (
        <div key={`emailInput${index}`} className='d-flex align-items-center mb-5'>
          <img src={tenantUserHollowIconGreen} height={20} width={20} className='me-3' />
          <input
            className={`${!emailInput.isValid || emailInput.isDuplicate ? 'input-invalid' : 'input-normal'}`}
            type='text'
            placeholder='Enter email address'
            value={emailInput.email}
            onChange={(e) => handleChangeEmail(index, e.target.value)}
          />
          {emailList?.length > 1 && (
            <img onClick={() => deleteEmailInput(index)} src={removeIcon} height={14} width={14} className='ms-2 cursor-pointer' />
          )}
          {!emailInput.isValid && <span style={{color: '#cc0000', marginLeft: '10px'}}>Invalid email address</span>}
          {emailInput.isDuplicate && <span style={{color: '#cc0000', marginLeft: '10px'}}>Email is duplicated</span>}
        </div>
      )
    })
  }

  const countValidEmails = () => {
    return emailList.filter((emailInput: any) => validateEmail(emailInput.email.trim())).length
  }

  const disableSendInvitation = () => {
    return emailList?.some((emailInput: any) => validateEmail(emailInput.email.trim()))
  }

  const validateInvitation = async () => {
    setEmailList((prevDetails: any) => {
      const newDetails = [...prevDetails]
      let isValid = true

      newDetails.forEach((item, index) => {
        if (item.email.trim() !== '') {
          const emailValid = validateEmail(item.email)
          const emailDuplicate = emailValid && checkForDuplicate(item.email)

          newDetails[index].isValid = emailValid
          newDetails[index].isDuplicate = emailDuplicate

          if (!emailValid || emailDuplicate) {
            isValid = false
          }
        } else {
          newDetails[index].isValid = true // Reset to true if the email field is empty
          newDetails[index].isDuplicate = false
        }
      })

      if (isValid) {
        // Proceed with sending invitation
        submitInvitation()
      } else {
        // Optionally, show an error message to the user
        // alert('Please correct the highlighted errors before sending.');
      }

      return newDetails
    })
  }

  const filterEmail = emailList.filter((emailInput: any) => {
    if (emailInput?.email?.trim() !== '') {
      return emailInput.email
    }
  })

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  const submitInvitation = async () => {
    let body = {
      registrationDetails: {...checkboxesState, email: true},
      documentDetails: documentDetails,
      isIncludedRegistration: detailSection,
      isIncludedDocument: documentSection,
      isIncludedNote: noteSection,
      note: note,
      emailList: filterEmail.map((emailInput: any) => emailInput.email),
      type: 0,
    }

    await ApiPost('corporate/tenant_invite', body)
      .then((res) => {
        handleClose()
        SuccessToast(`${countValidEmails()} Tenant invitations sent successfully! (${countValidEmails()} emails sent).`)
        
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div className='row px-7 py-5 m-0' style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}>
            <div className='col-md-12 px-0'>
              <div className='d-flex'>
                <h2 className='head-text mb-0 flex-grow-1'>
                  <b>Invite Tenants</b>
                </h2>

                <label
                  className='head-text fs-5 cursor-pointer'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
          </div>

          <div className='row px-7 mx-0 px-0 mt-7' style={{position: 'relative'}}>
            <div className='col-12'>
              <div className='row px-0 d-flex align-items-center'>
                <div className='col-6 px-0' style={{position: 'relative'}}>
                  {!detailSection && (
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 5,
                        background: 'rgba(255,255,255,0.6)',
                      }}
                    ></div>
                  )}
                  <h3 className='m-0 head-text'>
                    <b>Tenant Details</b>
                  </h3>
                </div>
                <div className='col-6 px-0 d-flex justify-content-end align-items-center'>
                  <div className='d-flex align-items-center'>
                    <span className='me-2'>
                      <OverlayTrigger
                        placement='right'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2'> Tenant Details section will be included or removed from the tenant's invitation form.</Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#35475e '
                            fontSize={16}
                            fontWeight={500}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <h5 className='m-0 p-0'>Include Section</h5>
                    <img
                      onClick={() => setDetailSection(!detailSection)}
                      src={detailSection ? switchOnIcon : switchOffIcon}
                      height={35}
                      width={35}
                      className='ms-5 cursor-pointer'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 px-0' style={{position: 'relative'}}>
              {!detailSection && (
                <div
                  style={{width: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 5, background: 'rgba(255,255,255,0.6)'}}
                ></div>
              )}
              <h5 className='m-0 p-0'>
                Please select which details you would like the tenant to fill out in the registration form they will submit back to you:
              </h5>
              <div className='row mt-5' style={{position: 'relative'}}>
                {!detailSection && (
                  <div
                    style={{
                      width: '100%',
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 5,
                      background: 'rgba(255,255,255,0.6)',
                    }}
                  ></div>
                )}
                <div className='col-12'>
                  <div className='d-flex justify-content-between align-items-center test'>{renderCheckboxes()}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='row px-7 mx-0 px-0 mt-7'>
            <div className='col-12'>
              <div className='row px-0 d-flex align-items-center'>
                <div className='col-6 px-0' style={{position: 'relative'}}>
                  {!documentSection && (
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 5,
                        background: 'rgba(255,255,255,0.6)',
                      }}
                    ></div>
                  )}
                  <h3 className='m-0 head-text'>
                    <b>Required Documents</b>
                  </h3>
                </div>
                <div className='col-6 px-0 d-flex justify-content-end align-items-center'>
                  <div className='d-flex align-items-center'>
                  <span className='me-2'>
                      <OverlayTrigger
                        placement='right'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2'> Required Documents section will be included or removed from the tenant's invitation form.</Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#35475e '
                            fontSize={16}
                            fontWeight={500}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <h5 className='m-0 p-0'>Include Section</h5>
                    <img
                      onClick={() => setDocumentSection(!documentSection)}
                      src={documentSection ? switchOnIcon : switchOffIcon}
                      height={35}
                      width={35}
                      className='ms-5 cursor-pointer'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 px-0' style={{position: 'relative'}}>
              {!documentSection && (
                <div
                  style={{width: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 5, background: 'rgba(255,255,255,0.6)'}}
                ></div>
              )}
              <h5 className='m-0 p-0'>
                Please select the documents you would like the tenant to provide during their registration. You can add and request custom documents
                from the tenant. Once the tenant provides the documents, each requested document will be placed in a separate folder named after the
                document you requested:
              </h5>
              <div className='row mt-5' style={{position: 'relative'}}>
                {!documentSection && (
                  <div
                    style={{
                      width: '100%',
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 5,
                      background: 'rgba(255,255,255,0.6)',
                    }}
                  ></div>
                )}
                <div className='col-12'>
                  <button type='button' className='btn btn-sm fw-bold px-2 green-hollow-btn justify-content-center' onClick={addDocument}>
                    Add Document
                  </button>
                </div>

                <div className='col-12 mt-5'>
                  <div className={`d-flex align-items-center flex-wrap test`}>{renderDocumentDetailsCheckboxes()}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='row px-7 mx-0 px-0 mt-7'>
            <div className='col-12'>
              <div className='row px-0 d-flex align-items-center'>
                <div className='col-6 px-0' style={{position: 'relative'}}>
                  {!noteSection && (
                    <div
                      style={{
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 5,
                        background: 'rgba(255,255,255,0.6)',
                      }}
                    ></div>
                  )}
                  <h3 className='m-0 head-text'>
                    <b>Notes to Invitees</b>
                  </h3>
                </div>
                <div className='col-6 px-0 d-flex justify-content-end align-items-center'>
                  <div className='d-flex align-items-center'>
                  <span className='me-2'>
                      <OverlayTrigger
                        placement='right'
                        delay={{show: 250, hide: 200}}
                        overlay={
                          <Tooltip id='button-tooltip-2'> Notes section will be included or removed from the tenant's invitation form.</Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <IoMdInformationCircleOutline
                            color='#35475e '
                            fontSize={16}
                            fontWeight={500}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <h5 className='m-0 p-0'>Include Section</h5>
                    <img
                      onClick={() => setNoteSection(!noteSection)}
                      src={noteSection ? switchOnIcon : switchOffIcon}
                      height={35}
                      width={35}
                      className='ms-5 cursor-pointer'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 px-0' style={{position: 'relative'}}>
              {!noteSection && (
                <div
                  style={{width: '100%', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 5, background: 'rgba(255,255,255,0.6)'}}
                ></div>
              )}
              <h5 className='m-0 p-0'>Add any notes or instructions relevant to your invitees during their registration process:</h5>
              <div className='row mt-5'>
                <div className='col-12'>
                  <div className='d-flex w-full'>{renderNote()}</div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex px-7 align-items-center mt-3 pb-5 mb-5  mx-3' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>

          <div className='row px-7 mx-0 px-0 mt-7' style={{paddingBottom: '100px'}}>
            <div className='col-12 px-0'>
              <h3 className='m-0 head-text'>
                <b>Add Invitees</b>
              </h3>
              <h5 className='m-0 p-0 mt-3'>Add the email addresses of the tenants you would like to invite and connect with on Propertise:</h5>
            </div>
            <div className='col-12 px-0 '>
              <div className='row mt-5'>
                <div className='col-12'>
                  <button type='button' className='btn btn-sm fw-bold px-2 green-hollow-btn justify-content-center' onClick={addEmailInput}>
                    <img src={tenantUserHollowIconGreen} height={18} width={18} className='px-0 me-2' />
                    Add Invitee
                  </button>
                </div>

                <div className='col-12 mt-7'>{renderEmailInputs()}</div>
              </div>
            </div>
          </div>

          <div className='row py-5 m-0 shadow-sm' style={{position: 'sticky', bottom: 0, background: '#fff', zIndex: 2}}>
            <div className='col-12 px-0 d-flex justify-content-end align-items-center '>
              <h4 className='my-2' style={{color: 'black'}}>
                Total:
              </h4>
              <span className='ms-1 ps-1'>
                <b>{countValidEmails()}</b>
              </span>
              <button
                disabled={!disableSendInvitation()}
                type='button'
                className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center ms-7 me-7'
                style={{width: '140px'}}
                onClick={validateInvitation}
              >
                Send Invitation
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default InviteModel

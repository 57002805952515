import React from 'react'
import {useState, useEffect, useRef} from 'react'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import close from '../../../../../img/close.png'
import backArrow from '../../../../../img/back-arrow.png'
import invoiceImg from '../../../../../img/invoice-blue.png'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import submitImg from '../../../../../img/Submit-White.png'
import editpen from '../../../../../img/pen.png'
import EditCorporateDetails from '../../EditCorporateDetailsModal'
import deleteImg from '../../../../../img/delete.png'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import Switch from 'react-switch'
import swal from 'sweetalert2'
import { capitalizeFirstLetter } from '../../../../../Utilities/utils'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import {canvasPreview} from '../../CanvasPreview'
import 'react-image-crop/dist/ReactCrop.css'
import {useDebounceEffect} from '../../UseDebounceEffect'
import SelectTemplate from '../../../General/SelectTemplate'
import template1 from '../../../../../img/PO-template-1.jpeg'
import template2 from '../../../../../img/PO-template-2.png'

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: 180,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const PODesign = () => {
  const navigate = useNavigate()

  const [editDetails, setEditDetails] = useState<any>(false)
  const [trnModal, setTRNModal] = useState<any>(false)
  const [trn, setTRN] = useState<any>('1234567890')
  const [trnError, setTRNError] = useState<any>(false)
  const [logo, setLogo] = useState<any>(null)
  const [poCompany, setPOCompany] = useState<any>(null)

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1)
  const [generatePO, setGeneratePo] = useState<any>(false)

  const [showCropped, setShowCropped] = useState<any>(false)

  const [showTemplateModal, setShowTemplateModal] = useState<any>(false)
  const [templates, setTemplates] = useState<any>([
    {
      name: 'template 1',
      src: template1,
    },
    {
      name: 'template 2',
      src: template2,
    },
  ])

  const [selectedTemplate, setSelectedTemplate] = useState<any>('template 1')

  //
  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
    setShowCropped(true)
  }

  //
  const onImageLoad = (e: any) => {
    if (aspect) {
      const {width, height} = e.currentTarget
      setCrop(centerAspectCrop(180, 180, aspect))
    }
  }

  // upload Cropped LOGO
  const getCroppedImage = () => {
    // console.log(previewCanvasRef.current)
    if (previewCanvasRef.current) {
      const croppedImage = previewCanvasRef.current.toDataURL('image/jpeg')
      // console.log(croppedImage)

      const body = {
        base64Image: croppedImage,
      }

      ApiPost('upload/base64/image/logo', body)
        .then((res) => {
          const body = {
            isPurchaseOrder: true,
            purchaseOrderLogo: res?.data?.data?.image,
            purchaseOrderCompany: {
              trn: poCompany?.trn,
              name: poCompany?.name,
              address: poCompany?.address,
              countryCode: poCompany?.countryCode,
              phoneNumber: poCompany?.phoneNumber,
              email: poCompany?.email,
            },
          }

          ApiPut('corporate/corporate_setting', body)
            .then((res) => {
              setShowCropped(false)
              SuccessToast('Company Purchase Order Logo has been updated successfully!!!')
              getDetails()
            })
            .catch((err) => ErrorToast(err.message))
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  // delete logo
  const deleteLogo = () => {
    const body = {
      url: logo,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setLogo(null)
        SuccessToast('Company PURCHASE ORDER LOGO has been removed successfully!!!')
        const body = {
          isPurchaseOrder: true,
          purchaseOrderLogo: null,
          purchaseOrderCompany: {
            trn: poCompany?.trn,
            name: poCompany?.name,
            address: poCompany?.address,
            countryCode: poCompany?.countryCode,
            phoneNumber: poCompany?.phoneNumber,
            email: poCompany?.email,
          },
        }

        ApiPut('corporate/corporate_setting', body)
          .then((res) => {
            getDetails()
          })
          .catch((err) => ErrorToast(err.message))
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setPOCompany(res?.data?.data?.purchaseOrderCompany)
        setLogo(res?.data?.data?.purchaseOrderLogo)
        setTRN(res?.data?.data?.purchaseOrderCompany?.trn)
        setGeneratePo(res?.data?.data?.purchaseOrderCompany?.isPurchaseOrderActive)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  // update TRN
  const updateTRN = () => {
    if (trn != '') {
      const body = {
        isPurchaseOrder: true,
        purchaseOrderLogo: logo,
        purchaseOrderCompany: {
          trn: trn,
          name: poCompany?.name,
          address: poCompany?.address,
          countryCode: poCompany?.countryCode,
          phoneNumber: poCompany?.phoneNumber,
          email: poCompany?.email,
        },
      }

      ApiPut('corporate/corporate_setting', body)
        .then((res) => {
          setTRNModal(false)
          SuccessToast('TRN has been updated successfully!!!')
          getDetails()
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      setTRNError(true)
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  //
  const updateGenerateStatus = (val: any) => {
    let status: any
    if (val) status = 1
    else status = 0

    const body = {
      isPurchaseOrder: true,
      purchaseOrderLogo: logo,
      purchaseOrderCompany: {
        trn: trn,
        name: poCompany?.name,
        address: poCompany?.address,
        countryCode: poCompany?.countryCode,
        phoneNumber: poCompany?.phoneNumber,
        email: poCompany?.email,
        isPurchaseOrderActive: val,
      },
    }

    ApiPut('corporate/corporate_setting', body)
      .then((res) => {
        getDetails()
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className=' pt-0 mt-0 px-2'>
        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 '>
          <div className='d-flex align-items-center gap-3'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} />
            </span>
            <h2 className='page-heading m-0 head-text text-capitalize'>Purchase Order</h2>
          </div>
          <div className='ms-auto'></div>
          <div className='ms-5'>
            <img src={invoiceImg} height={40} width={30} />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5 light-dark-theme-color'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/procurement/purchase-order')}
              >
                Purchase Order No. Generator
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/procurement/purchase-order-design')}
              >
                Purchase Order Design
              </a>
            </li>
          </ul>
        </div>

        {/*  */}
        <Row className='mb-2 d-none d-md-flex'>
          <Col xs={7} sm={7} md={7} lg={6}></Col>
          <Col xs={5} sm={5} md={5} lg={6} className='text-center d-flex align-items-center'>
            <h2 className='page-heading m-0 head-text'>Purchase Order Preview</h2>
            <button
              type='button'
              className='btn btn-sm fw-bold  select-btn btn-fit-content px-2 ms-auto'
              onClick={() => {
                setShowTemplateModal(true)
              }}
            >
              {/* <img src={addService} height={18} width={18} className='me-3' />  */}
              Select Template
            </button>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={6} className='pe-5 mt-3'>
            <Row className='mb-3'>
              <Col xs={12} sm={12} md={12} lg={12}>
                {/* Logo */}
                <div className='d-flex mb-3 '>
                  {logo == null ? (
                    <>
                      <label
                        htmlFor='media'
                        className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5 me-7 logo-placeholder-bg'
                      >
                        <b className='logo-text mt-7'>LOGO</b>
                        Click to upload
                      </label>

                      <input
                        type='file'
                        hidden
                        id='media'
                        className='form-control form-control-solid'
                        name='passport'
                        onChange={onSelectFile}
                      />
                    </>
                  ) : (
                   <div className='d-flex white-dark-theme-color'>
                      <img
                        src={`${Bucket}${logo}`}
                        height='180'
                        width='180'
                        style={{
                          borderRadius: '7px',
                          border: '2px solid black',
                          objectFit: 'contain',
                        }}
                      />

                      {/* <div> */}
                      <label
                        htmlFor='media'
                        className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                        style={{
                          height: '25px',
                          width: '25px',
                          border: '1px solid #48a794 ',
                          borderRadius: '4px',
                          transform: 'translate(-45px,-10px)',
                        }}
                      >
                        <img
                          src={editpen}
                          alt=''
                          className=''
                          style={{cursor: 'pointer', height: '18px', width: '18px'}}
                        />
                      </label>

                      <input
                        type='file'
                        hidden
                        id='media'
                        className='form-control form-control-solid'
                        name='passport'
                        onChange={onSelectFile}
                      />
                      {/* </div> */}
                      {/* <div> */}
                      <div
                        className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                        style={{
                          height: '25px',
                          width: '25px',
                          border: '1px solid crimson',
                          borderRadius: '4px',
                          transform: 'translate(-38px,-10px)',
                        }}
                        onClick={() => deleteLogo()}
                      >
                        <img
                          src={deleteImg}
                          alt=''
                          className=''
                          style={{
                            cursor: 'pointer',
                            height: '18px',
                            width: '18px',
                            marginLeft: '2px',
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  )}

                  <div className='card px-5 py-5 w-100'>
                    <div className='lease mt-3'>
                      <h5 className=' m-0 lease-grey-label mb-3 d-flex justify-content-center light-dark-theme-color'>
                        Generate Purchase Order
                        <span>
                          <OverlayTrigger
                            placement='top'
                            delay={{show: 250, hide: 400}}
                            overlay={
                              <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                {'Purchase Order'}
                              </Tooltip>
                            }
                          >
                            <span>
                              {' '}
                              <AiOutlineExclamationCircle className=' cursor-pointer exclam-circle ms-5' />
                            </span>
                          </OverlayTrigger>
                        </span>
                      </h5>
                      <div className='d-flex  align-items-center  justify-content-center'>
                        <Switch
                          onChange={(e) => {
                            swal
                              .fire({
                                html: ` 
                                  <div class='fs-3'> <b>Switch ${
                                    e ? 'ON' : 'OFF'
                                  } Generate Purchase Order </b></div>
                                  <div class='mt-4'>You are about to switch ${
                                    e ? 'ON' : 'OFF'
                                  }  the generation of purchase order for maintenance flow. </div>
                                  <div class='mt-4'>This will be applied on new maintenance requests generated.
                                  This update will not be applied to existing incomplete/ draft requests. </div>
                                  <div class='mt-4'> Are you sure you want to continue? </div>`,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton:
                                    'custom-confirm-button d-flex align-items-center justify-content-center h-31',
                                  cancelButton:
                                    'custom-cancel-button d-flex align-items-center justify-content-center h-31',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  updateGenerateStatus(e)
                                } else if (res.isDenied) {
                                } else {
                                }
                              })
                          }}
                          defaultChecked={generatePO}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          checked={generatePO}
                          onColor='#00cf99'
                          offColor='#b0bec5'
                        />
                        <h5 className=' m-0 head-text ms-3'>{generatePO ? 'Yes' : 'No'}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* TRN */}
            <div className='card px-5 py-5 mt-5'>
             <div className='d-flex white-dark-theme-color'>
                <h2 className='top head-text '>Tax Registration Number (TRN)</h2>
                <img
                  src={editpen}
                  alt=''
                  className='ms-auto me-2'
                  style={{cursor: 'pointer', height: '20px', width: '20px'}}
                  onClick={() => {
                    setTRNModal(true)
                  }}
                />
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7 light-dark-theme-color'
                  style={{minWidth: '105px'}}
                >
                  TRN
                </label>
                <div className='d-flex white-dark-theme-color'> {poCompany?.trn != null ? poCompany?.trn : '-'}</div>
              </div>
            </div>

            {/* Corporate Details */}
            <div className='card px-5 py-5 mt-5'>
             <div className='d-flex white-dark-theme-color'>
                <h2 className='top head-text'>Corporate Details</h2>
                <img
                  src={editpen}
                  alt=''
                  className='ms-auto me-2 cursor-pointer'
                  style={{height: '20px', width: '20px'}}
                  onClick={() => {
                    setEditDetails(true)
                  }}
                />
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7 light-dark-theme-color'
                  style={{minWidth: '115px'}}
                >
                  Name
                </label>
               <div className='d-flex white-dark-theme-color'>{poCompany?.name != null ? poCompany?.name : '-'}</div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7 light-dark-theme-color'
                  style={{minWidth: '115px'}}
                >
                  Address
                </label>
               <div className='d-flex white-dark-theme-color'>
                  {poCompany?.address != null ? poCompany?.address : '-'}
                </div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7 light-dark-theme-color'
                  style={{minWidth: '115px'}}
                >
                  Contact No.
                </label>
               <div className='d-flex white-dark-theme-color'>
                  {poCompany?.phoneNumber != null
                    ? poCompany?.countryCode + ' ' + poCompany?.phoneNumber
                    : '-'}
                </div>
              </div>

              <div className='d-flex grid_big_col py-2 fs-7'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label blue-label fs-7 light-dark-theme-color'
                  style={{minWidth: '115px'}}
                >
                  Email
                </label>
                <div className='d-flex white-dark-theme-color'> {poCompany?.email != null ? poCompany?.email : '-'}</div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className='mt-3 ps-6 pe-7'>
            <Row>
              <Col xs={12} className='card py-4'>
                {/* Template 01 */}
                {selectedTemplate == 'template 1' ? (
                  <Row className=''>
                    <Col xs={6} sm={6} md={8} lg={8}>
                      <div className='d-flex mb-3 mt-2 align-items-center'>
                        {logo == null ? (
                          <></>
                        ) : (
                          <img
                            src={`${Bucket}${logo}`}
                            height={110}
                            width={110}
                            style={{borderRadius: '7px'}}
                          />
                        )}
                        <div className='text-start ms-4 me-6 fnt-600'>
                          <p style={{marginBottom: '0.1rem'}} className='white-dark-theme-color'>
                            {' '}
                            <b>{poCompany?.name != null && poCompany?.name}</b>
                          </p>
                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {' '}
                            {poCompany?.address != null && poCompany?.address}
                          </p>

                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {poCompany?.phoneNumber != null &&
                              poCompany?.countryCode + ' ' + poCompany?.phoneNumber}
                          </p>
                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {' '}
                            {poCompany?.email != null && poCompany?.email}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} className='pe-4'>
                      <div className='d-flex align-items-center my-2 '>
                        <h1 className='ms-auto mb-0 white-dark-theme-color'>PURCHASE ORDER</h1>
                      </div>

                      {/*  */}
                      <div className='row my-3'>
                        <Col md={6}>
                          <div className='status-w-80 ms-auto'>
                            <h4 className='my-2 fs-7' style={{color: 'black'}}>
                              <label style={{width: '90px'}} className='labl-gry ms-15 me-auto light-dark-theme-color'>
                                {' '}
                                PO #
                              </label>
                            </h4>
                          </div>
                        </Col>
                        <Col md={6} className='text-end'>
                          <h4 className='my-2 fs-7' style={{color: 'black'}}>
                            <label className='ms-7 white-dark-theme-color'>
                              <b>1234567</b>
                            </label>
                          </h4>
                        </Col>
                      </div>

                      <div className='row my-3'>
                        <Col md={6}>
                          <div className='status-w-80 ms-auto'>
                            <h4 className='my-2 fs-7' style={{color: 'black'}}>
                              <label style={{width: '90px'}} className='labl-gry ms-15 me-auto light-dark-theme-color'>
                                {' '}
                                Issued Date
                              </label>
                            </h4>
                          </div>
                        </Col>
                        <Col md={6} className='text-end'>
                          <h4 className='my-2 fs-7' style={{color: 'black'}}>
                            <label className='ms-7 white-dark-theme-color'>
                              <b>22-08-2023</b>
                            </label>
                          </h4>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                ) : // Template 02
                selectedTemplate == 'template 2' ? (
                  <Row className=''>
                    <Col xs={6} sm={6} md={4} lg={4} className='pe-4'>
                      <div className='d-flex align-items-center my-2 '>
                        <h1 className='mb-0 white-dark-theme-color'>PURCHASE ORDER</h1>
                      </div>

                      {/*  */}
                      <div className='d-flex my-3'>
                        <h4 className='my-2 fs-7' style={{color: 'black'}}>
                          <label style={{width: '90px'}} className='labl-gry light-dark-theme-color'>
                            {' '}
                            PO #
                          </label>
                          <span className='ms-1 white-dark-theme-color'>
                            <b>1234567</b>
                          </span>
                        </h4>
                      </div>

                      {/*  */}
                      <div className='d-flex my-3'>
                        <h4 className='my-2 fs-7' style={{color: 'black'}}>
                          <label style={{width: '90px'}} className='labl-gry light-dark-theme-color'>
                            {' '}
                            Issued Date
                          </label>
                          <span className='ms-1 white-dark-theme-color'>
                            <b>22-08-2023</b>
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} md={8} lg={8}>
                      <div className='d-flex mb-3 mt-2 align-items-center justify-content-end pe-2'>
                        <div className='text-end ms-4 me-6' style={{fontWeight: '600'}}>
                          <p style={{marginBottom: '0.1rem'}} className='white-dark-theme-color'>
                            {' '}
                            <b>{poCompany?.name != null && poCompany?.name}</b>
                          </p>
                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {' '}
                            {poCompany?.address != null && poCompany?.address}
                          </p>

                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {poCompany?.phoneNumber != null &&
                              poCompany?.countryCode + ' ' + poCompany?.phoneNumber}
                          </p>
                          <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                            {' '}
                            {poCompany?.email != null && poCompany?.email}
                          </p>
                        </div>
                        {logo == null ? (
                          <></>
                        ) : (
                          <img
                            src={`${Bucket}${logo}`}
                            height='110'
                            width='110'
                            style={{borderRadius: '7px'}}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row></Row>
                )}

                <Row className='mt-5 pb-5 d-flex' style={{borderBottom: '2px dashed lightgray'}}>
                  <Col
                    md={12}
                    style={{fontWeight: '600'}}
                    className={`pe-5 ${
                      selectedTemplate == 'template 1' ? 'text-start' : 'text-end'
                    }`}
                  >
                    <div
                      className={`d-flex align-items-center ? ${
                        selectedTemplate == 'template 1'
                          ? 'justify-content-start'
                          : 'justify-content-end'
                      }`}
                    >
                      <p className='fs-7 mb-0 white-dark-theme-color'>
                        <b>Vendor</b>
                      </p>
                    </div>

                    <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                      <b> Handy Tech LLC.</b>
                    </p>
                    <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                      test address 1
                    </p>
                    <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                      +92 333 1112221
                    </p>
                    <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                      muhammadwakkar777@gmail.com{' '}
                    </p>
                  </Col>
                </Row>
                <table
                  className='table align-middle fs-6 gy-5 mt-3 pb-5'
                  id='kt_ecommerce_sales_table'
                  style={{borderBottom: '1.5px dashed lightgray'}}
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='min-w-25px'></th>
                      <th className='min-w-50px'>Description</th>
                      <th className='min-w-5px'>Qty</th>
                      <th className='min-w-50px'>Unit Price</th>
                      <th className='min-w-50px'>Total Price</th>
                    </tr>
                  </thead>
                  <tbody style={{fontWeight: '500'}} className='head-text'>
                    <tr>
                      <td className='py-2'>1</td>
                      <td className='py-2'>Piping works</td>
                      <td className='py-2'>5</td>
                      <td className='py-2'>320</td>

                      <td className='py-2'>
                        <b>AED 1,600</b>
                      </td>
                    </tr>

                    <tr style={{height: '50px'}}>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td className='py-2 ps-3 head-text'>Total Amount</td>
                      <td className='py-2'>
                        <b>AED 1,600</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className='d-flex my-2 mb-2'>
                  <div className=''>
                    <h4 className='mb-1 me-1 white-dark-theme-color'>
                      <b>Payment Terms</b>
                    </h4>
                  </div>
                </div>
                <div>
                  <p style={{marginBottom: '0.1rem'}} className='fs-6 white-dark-theme-color'>
                    The points below will constitute as the payment terms within this agreement and
                    both parties hereby agree to:
                  </p>
                </div>
                <ul>
                  <li>
                    <p style={{marginBottom: '0.1rem'}} className='fs-6 white-dark-theme-color'>
                      1. First payment of 10% will be made upon start of the job.
                    </p>
                  </li>
                  <li>
                    <p style={{marginBottom: '0.1rem'}} className='fs-6 white-dark-theme-color'>
                      2. Once job is 50% complete 60% of the job will be paid.
                    </p>
                  </li>
                  <li>
                    <p style={{marginBottom: '0.1rem'}} className='fs-6 white-dark-theme-color'>
                      3. Allow 5-6 days for every payment to process.
                    </p>
                  </li>
                  <li>
                    <p style={{marginBottom: '0.1rem'}} className='fs-6 white-dark-theme-color'>
                      4. All invoices submitted must be include the purchase order number.
                    </p>
                  </li>
                </ul>

                <div className='d-flex my-3'>
                  <h4 className='my-2 fs-7' style={{color: 'black'}}>
                    <label style={{width: '100px'}} className='labl-gry '>
                      {' '}
                      Authorized By
                    </label>
                    <span className='labl-gry'>
                      <b>_________________________</b>
                    </span>
                  </h4>
                </div>
                <Row>
                  <Col md={6}>
                    <div className='d-flex my-3'>
                      <h4 className='my-2 fs-7' style={{color: 'black'}}>
                        <label style={{width: '100px'}} className='labl-gry '>
                          {' '}
                          Signatre
                        </label>
                        <span className='labl-gry'>
                          <b>_________________________</b>
                        </span>
                      </h4>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='d-flex my-3'>
                      <h4 className='my-2 fs-7' style={{color: 'black'}}>
                        <label style={{width: '100px'}} className='labl-gry '>
                          {' '}
                          Date
                        </label>
                        <span className='labl-gry'>
                          <b>_________________________</b>
                        </span>
                      </h4>
                    </div>
                  </Col>
                </Row>

                <div className='d-flex my-2 mb-2 mt-4'>
                  <div className=''>
                    <h4 className='mb-1 me-1 light-dark-theme-color'>
                      <b>Vendor</b>
                    </h4>
                  </div>
                </div>

                <Row>
                  <Col md={6}>
                    <div className='d-flex my-3'>
                      <h4 className='my-2 fs-7' style={{color: 'black'}}>
                        <label style={{width: '100px'}} className='labl-gry '>
                          {' '}
                          Signatre
                        </label>
                        <span className='labl-gry'>
                          <b>_________________________</b>
                        </span>
                      </h4>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='d-flex my-3'>
                      <h4 className='my-2 fs-7' style={{color: 'black'}}>
                        <label style={{width: '100px'}} className='labl-gry '>
                          {' '}
                          Date
                        </label>
                        <span className='labl-gry'>
                          <b>_________________________</b>
                        </span>
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/*  */}
      {editDetails && (
        <EditCorporateDetails
          show={editDetails}
          handleClose={() => {
            setEditDetails(false)
          }}
          companyDetails={poCompany}
          type={'purchaseOrder'}
          getDetails={getDetails}
          logo={logo}
        />
      )}

      {/*  */}
      {trnModal && (
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
          show={trnModal}
          onHide={() => setTRNModal(false)}
          backdrop={true}
          size='sm'
        >
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
          >
            <Row className='w-100'>
              <Col xs={8} sm={8} md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Tax Registration Number </h2>
              </Col>
              <Col xs={4} sm={4} md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setTRNModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '2.8rem'}}>
                {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-7'
            style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
          >
            <Row className='align-items-end'>
              <Col xs={12} sm={6} md={6} className='align-items-center mt-3'>
                <label className='required mb-2 label-color'>TRN</label>
                <input
                  style={
                    trn == ''
                      ? {
                          border: '0.1rem solid #bec3cb',
                          borderRadius: '2px',
                          padding: '0.7rem 1rem',
                        }
                      : {
                          border: '0.1rem solid #384a62 ',
                          borderRadius: '2px',
                          padding: '0.7rem 1rem',
                        }
                  }
                  value={trn}
                  type='text'
                  placeholder='Enter TRN'
                  name='item_id'
                  onChange={(e) => {
                    let newVal : any = capitalizeFirstLetter(e.target.value)
                    setTRN(newVal)
                    setTRNError(false)
                  }}
                  className={
                    trnError
                      ? 'form-control form-control-solid-bg border-danger'
                      : 'form-control form-control-solid-bg'
                  }
                />
              </Col>
              <Col xs={12} sm={6} md={6} className='align-items-center text-end mt-3'>
                <button
                  className='btn btn-sm fw-bold text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    updateTRN()
                  }}
                >
                  <img
                    src={submitImg}
                    style={{
                      height: '18px',
                      width: '18px',
                      marginRight: '20px',
                      marginLeft: '-7px',
                      marginTop: '-3px',
                    }}
                  />
                  Update
                </button>
              </Col>
            </Row>
          </div>
        </Modal>
      )}

      {/* MODAL TO CROP LOGO */}
      {showCropped && (
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
          show={showCropped}
          onHide={() => setShowCropped(false)}
          backdrop={true}
          size='sm'
        >
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
          >
            <Row className='w-100'>
              <Col xs={8} sm={8} md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Purchase Order Logo </h2>
              </Col>
              <Col xs={4} sm={4} md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setShowCropped(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '2.8rem'}}>
                {/* <img src={latePayment} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-7'
            style={{transform: 'translate(0px, -80px)', marginBottom: '-80px'}}
          >
            <Row className='align-items-end'>
              <Col lg={12} md={12} className='align-items-center text-center'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    width: '185px',
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    getCroppedImage()
                  }}
                >
                  <img
                    src={submitImg}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Upload
                </button>
              </Col>
            </Row>

            <Row className='mt-4'>
              <div className='Crop-Controls'>
                {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
                {/* <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div> */}
              </div>
              {!!imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  locked={true}
                  className='p-0'
                >
                  <img
                    ref={imgRef}
                    alt='Crop me'
                    src={imgSrc}
                    // style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              {!!completedCrop && (
                <>
                  <div className='d-none'>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  </div>
                  {/* <div>
                    <button onClick={onDownloadCropClick}>Download Crop</button>
                    <a
                      ref={hiddenAnchorRef}
                      download
                      style={{
                        position: 'absolute',
                        top: '-200vh',
                        visibility: 'hidden',
                      }}
                    >
                      Hidden download
                    </a>
                  </div> */}
                </>
              )}
            </Row>
          </div>
        </Modal>
      )}

      <SelectTemplate
        isVisible={showTemplateModal}
        handleClose={() => {
          setShowTemplateModal(false)
        }}
        temps={templates}
        selectedTemplate={selectedTemplate}
        updateTemplate={(val: any) => {
          setSelectedTemplate(val)
        }}
      />
    </>
  )
}

export default PODesign

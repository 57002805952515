import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col, Modal, FormCheck} from 'react-bootstrap'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import add from '../../../img/add-black.png'
import close from '../../../img/close.png'
import Switch from 'react-switch'
import latePayment from '../../../img/overdue.png'
import backArrow from '../../../img/back-arrow.png'
import pen from '../../../img/pen.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import submitImg from '../../../img/Submit-White.png'
import Select from 'react-select'
import './style.scss'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import latePaymentWhite from '../../../img/overdue-white.png'
import noData from '../../../img/NoData1.svg'
import {truncate} from 'graceful-fs'
import moment from 'moment'

const BuildingsLatePaymentFee = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {development, devName} = state

  const params = useParams()
  const [paymentItems, setPaymentItems] = useState<any>([])

  const [latePaymentModal, setLatePaymentModal] = useState<any>(false)
  const [editModal, setEditModal] = useState<any>(false)
  const [confirmationModal, setConfirmationModal] = useState<any>(false)
  const [selectedPaymentsModal, setSelectedPaymentsModal] = useState<any>(false)
  const [statusUpd, setStatusUpd] = useState<any>(false)
  const [updItem, setUpdItem] = useState<any>()

  const [itemToEdit, setItemToEdit] = useState<any>()

  const [checked, setChecked] = useState(false)

  const [itemName, setItemName] = useState<any>()
  const [dueDays, setDueDays] = useState<any>('')
  const [fq, setFq] = useState<any>()
  const [fType, setFTyp] = useState<any>()
  const [fee, setFee] = useState<any>('')
  const [vatPer, setVatPer] = useState<any>(0)

  const [checkStatus, setCheckStatus] = useState<any>([
    {
      id: '',
      status: '',
    },
  ])

  const options = [
    {value: '0', label: 'Once'},
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Weekly'},
    {value: '3', label: 'Monthly'},
  ]

  const feeType = [
    {value: '0', label: 'Fixed Amount'},
    {value: '1', label: '% of Item Total'},
  ]

  const [dueDaysError, setDueDaysError] = useState<any>(false)
  const [dueValError, setDueValError] = useState<any>(false)
  const [feeError, setFeeError] = useState<any>(false)
  const [vatError, setVatError] = useState<any>(false)

  const [payments, setPayments] = useState<any>([])

  const customStyles = {
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',

      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0.12rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '500'}),
  }

  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const handleChange = (e: any) => {
    setChecked(e.target.checked)
  }

  // list of payment items
  const getLatePaymentFee = () => {
    let body: any
    if (state.development == 'community') {
      body = {
        limit: 10,
        page: 1,
        communityId: params?.id,
      }
    } else {
      body = {
        limit: 10,
        page: 1,
        buildingId: params?.id,
      }
    }

    ApiPost(`corporate/late_payment_item_charge/get`, body)
      .then((res) => {
        setPaymentItems(res?.data?.data?.late_payment_item_charge)
        // console.log(res?.data?.data?.payment_item_list_data)
      })
      .catch((err) => console.log('err', err))
  }

  // edit Details
  const editDetails = (item: any) => {
    setItemToEdit(item)
    setItemName(item?.payment_item_list[0]?.name)
    setDueDays(item?.dayAfterPaymentDue)
    setFq(item?.frequency)
    setFTyp(item?.feeType)

    if (item?.feeType === 0) setFee(item?.fixedAmount)
    else setFee(item?.percentageAmount)

    setLatePaymentModal(true)
  }

  const validate = () => {
    if (dueDays !== '' && fee !== '' && vatPer !== '' && dueDays >= 1) {
      if (itemToEdit?.activityStatus === 1) {
        confirmPop(itemToEdit, 0)
        // setConfirmationModal(true)
      } else updateLatePaymentFee()
    } else {
      if (fee === '') setFeeError(true)
      if (vatPer === '') setVatError(true)
      if (dueDays === '') setDueDaysError(true)
      else {
        if (dueDays < 1) setDueValError(true)
      }
    }
  }

  // update payment items
  const updateLatePaymentFee = () => {
    let fixed: any
    let percent: any
    let fetyp: any
    let freq: any

    if (parseInt(fType) === 0) {
      fixed = fee
      percent = 0
      fetyp = 0
    } else if (parseInt(fType) === 1) {
      fixed = 0
      percent = fee
      fetyp = 1
    } else {
      fixed = fee
      percent = 0
      fetyp = 0
    }
    //
    if (fq == null) freq = 0
    else freq = fq

    let body: any
    if (state.development == 'community') {
      body = {
        id: itemToEdit?._id,
        dayAfterPaymentDue: parseInt(dueDays),
        frequency: parseInt(freq),
        feeType: parseInt(fetyp),
        VATPercentage: parseInt(vatPer),
        fixedAmount: parseInt(fixed),
        percentageAmount: parseInt(percent),
        // communityId: params?.id,
      }
    } else {
      body = {
        id: itemToEdit?._id,
        dayAfterPaymentDue: parseInt(dueDays),
        frequency: parseInt(freq),
        feeType: parseInt(fetyp),
        VATPercentage: parseInt(vatPer),
        fixedAmount: parseInt(fixed),
        percentageAmount: parseInt(percent),
        // buildingId: params?.id,
      }
    }

    ApiPut(`corporate/late_payment_item_charge`, body)
      .then((res) => {
        getLatePaymentFee()
        setLatePaymentModal(false)
        SuccessToast('Late Payment Fee has been updated successfully!!!')
      })
      .catch((err) => console.log('err', err))
  }

  // update status

  const updateStatus = (p: any) => {
    let st: any
    if (p.activityStatus === 0) st = 1
    else st = 0

    let body: any
    if (state.development == 'community') {
      body = {
        id: p._id,
        activityStatus: st,
        // communityId: params?.id,
      }
    } else {
      body = {
        id: p._id,
        activityStatus: st,
        // buildingId: params?.id,
      }
    }

    ApiPut(`corporate/late_payment_item_charge`, body)
      .then((res) => {
        getLatePaymentFee()
        setLatePaymentModal(false)
        setStatusUpd(false)
      })
      .catch((err) => console.log('err', err))
  }

  const confirmPop = (p: any, isStatus: any) => {
    ApiGet(`corporate/late_payment_item_charge/payment/popup/checker/${p?._id}`)
      .then((res) => {
        // console.log(statusUpd)
        if (res?.data?.data?.isOpenPopup === true) setConfirmationModal(true)
        else {
          if (isStatus === 1) updateStatus(p)
          else updateLatePaymentFee()
        }
      })
      .catch((err) => console.log('err', err))
  }

  //
  const applyToAll = () => {
    let fixed: any
    let percent: any
    let fetyp: any
    let freq: any

    if (parseInt(fType) === 0) {
      fixed = fee
      percent = 0
      fetyp = 0
    } else if (parseInt(fType) === 1) {
      fixed = 0
      percent = fee
      fetyp = 1
    } else {
      fixed = fee
      percent = 0
      fetyp = 0
    }
    //
    if (fq == null) freq = 0
    else freq = fq

    const body = {
      isAllPayment: true,
      // paymentSubItemIds: [itemToEdit?.payment_item_list?.[0]?._id],
      updatedLatePaymentObject: {
        _id: itemToEdit?._id,
        dayAfterPaymentDue: parseInt(dueDays),
        frequency: parseInt(freq),
        feeType: parseInt(fetyp),
        VATPercentage: parseInt(vatPer),
        fixedAmount: parseInt(fixed),
        percentageAmount: parseInt(percent),
      },
    }

    ApiPost(`corporate/late_payment_item_charge/update_payment`, body)
      .then((res) => {
        getLatePaymentFee()
        setLatePaymentModal(false)
        SuccessToast('Late Payment fee has been updated successfully!!')
      })
      .catch((err) => console.log('err', err))
  }

  const updateStatusOfAll = (p: any) => {
    setStatusUpd(false)
    let st: any
    if (p.activityStatus === 0) st = 1
    else st = 0

    const body = {
      isAllPayment: true,
      // paymentSubItemIds: [itemToEdit?._id],
      updatedLatePaymentObject: {
        _id: itemToEdit?._id,
        activityStatus: st,
        dayAfterPaymentDue: itemToEdit?.dayAfterPaymentDue,
        frequency: itemToEdit?.frequency,
        feeType: itemToEdit?.feeType,
        VATPercentage: itemToEdit?.VATPercentage,
        fixedAmount: itemToEdit?.fixedAmount,
        percentageAmount: itemToEdit?.percentageAmount,
      },
    }

    ApiPost(`corporate/late_payment_item_charge/update_payment`, body)
      .then((res) => {
        getLatePaymentFee()
        setLatePaymentModal(false)
        setStatusUpd(false)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getListOfAssociatedPayments = async () => {
    const body = {
      limit: 10,
      page: 1,
      paymentItemListId: null,
      latePaymentChargeId: itemToEdit?._id,
    }

    await ApiPost(`corporate/payment_sub_item/associate/item_and_charge`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_sub_item_data)
        console.log(payments)

        let py: any = res?.data?.data?.payment_sub_item_data?.length

        let newData: any = []
        for (let i = 0; i < py; i++) {
          newData.push({
            id: res?.data?.data?.payment_sub_item_data[i]._id,
            status: false,
          })
        }
        console.log(newData)
        setCheckStatus(newData)

        setSelectedPaymentsModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const applyToSelected = () => {
    let idsToSend: any = []
    let j: any = 0
    for (let i = 0; i < checkStatus?.length; i++) {
      if (checkStatus[i].status == true) {
        idsToSend[j] = checkStatus[i]?.id
        j++
      }
    }
    let fixed: any
    let percent: any
    let fetyp: any
    let freq: any

    if (parseInt(fType) === 0) {
      fixed = fee
      percent = 0
      fetyp = 0
    } else if (parseInt(fType) == 1) {
      fixed = 0
      percent = fee
      fetyp = 1
    } else {
      fixed = fee
      percent = 0
      fetyp = 0
    }
    //
    if (fq == null) freq = 0
    else freq = fq

    const body = {
      isAllPayment: false,
      paymentSubItemIds: idsToSend,
      updatedLatePaymentObject: {
        _id: itemToEdit?._id,
        dayAfterPaymentDue: parseInt(dueDays),
        frequency: parseInt(freq),
        feeType: parseInt(fetyp),
        VATPercentage: parseInt(vatPer),
        fixedAmount: parseInt(fixed),
        percentageAmount: parseInt(percent),
        activityStatus: itemToEdit?.activityStatus,
      },
    }

    ApiPost(`corporate/late_payment_item_charge/update_payment`, body)
      .then((res) => {
        setLatePaymentModal(false)
        setSelectedPaymentsModal(false)
        getLatePaymentFee()
        SuccessToast('Late Payment fee has been updated successfully!!')
      })
      .catch((err) => console.log('err', err))
  }

  const updateStatusOfSelected = (p: any) => {
    let idsToSend: any = []
    let j: any = 0
    for (let i = 0; i < checkStatus?.length; i++) {
      if (checkStatus[i].status == true) {
        idsToSend[j] = checkStatus[i]?.id
        j++
      }
    }

    setStatusUpd(false)
    let st: any
    if (p.activityStatus === 0) st = 1
    else st = 0

    const body = {
      isAllPayment: true,
      paymentSubItemIds: idsToSend,
      updatedLatePaymentObject: {
        _id: itemToEdit?._id,
        activityStatus: st,
        dayAfterPaymentDue: itemToEdit?.dayAfterPaymentDue,
        frequency: itemToEdit?.frequency,
        feeType: itemToEdit?.feeType,
        VATPercentage: itemToEdit?.VATPercentage,
        fixedAmount: itemToEdit?.fixedAmount,
        percentageAmount: itemToEdit?.percentageAmount,
      },
    }

    ApiPost(`corporate/late_payment_item_charge/update_payment`, body)
      .then((res) => {
        getLatePaymentFee()
        setLatePaymentModal(false)
        setSelectedPaymentsModal(false)
        setStatusUpd(false)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getLatePaymentFee()
  }, [])

  useEffect(() => {}, [checkStatus])

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='px-2'>
        <div className='d-flex align-items-center mb-10  '>
          <div className='d-flex align-items-center '>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(-1)}
            >
               <img src={backArrow} height={14} width={14} className='me-2'/>
            </span>
            <h2 className='page-heading m-0 head-text'>Late Payment Fee - {state.devName}</h2>
          </div>

          <div className='ms-auto'>
            <img src={latePayment} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0'>
          <div className='card-body pt-0 table-responsive mt-5 pb-4 px-1'>
            <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-150px'>Income Items</th>
                  <th className='min-w-100px'>Fee Type</th>
                  <th className='min-w-100px'>Fee</th>
                  <th className='min-w-200px'>Days After Due Date</th>
                  <th className='min-w-100px'>Frequency</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
                {/* <tr>
                  <td className='d-flex'>Rent</td>
                  <td>Fixed Amount</td>
                  <td>250</td>
                  <td>1</td>
                  <td>Once</td>
                  <td className='d-flex align-items-center'><Switch onChange={(e)=> handleChange} defaultChecked={checked}  uncheckedIcon={false} checkedIcon={false} checked={true}    onColor='#00cf99'
                      offColor='#b0bec5'/>
                       <p className='ms-4 pt-4'>
                      <i>Active</i>
                    </p>
                      </td>

                  <td>
                    <img
                      src={pen}
                      height="19px" width="19px"
                      alt=''
                      className='me-4'
                      style={{cursor: 'pointer', marginTop: '-4px'}}
                      onClick={() => setLatePaymentModal(true)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='d-flex'>Security Deposit</td>
                  <td>% of item total</td>
                  <td>5%</td>
                  <td>1</td>
                  <td>Weekly</td>
                  <td className='d-flex align-items-center'><Switch onChange={(e)=> handleChange} defaultChecked={checked}  uncheckedIcon={false} checkedIcon={false} checked={true}    onColor='#00cf99'
                      offColor='#b0bec5'/>
                       <p className='ms-4 pt-4'>
                      <i>Active</i>
                    </p>
                      </td>

                  <td>
                    <img
                      src={pen}
                      height="19px" width="19px"
                      alt=''
                      className='me-4'
                      style={{cursor: 'pointer', marginTop: '-4px'}}
                      onClick={() => setLatePaymentModal(true)}
                    />
                  </td>
                </tr> */}

                {paymentItems.length > 0 ? (
                  paymentItems.map((pItem: any) => {
                    return (
                      <tr className='align-items-center'>
                        <td>{pItem?.payment_item_list[0]?.name}</td>

                        <td>
                          {pItem?.feeType === null
                            ? '-'
                            : pItem?.feeType === 0
                            ? 'Fixed Amount'
                            : '% of item total'}
                        </td>
                        <td>
                          {pItem?.feeType === null && pItem?.percentageAmount === null
                            ? '-'
                            : pItem?.feeType === 0
                            ? pItem?.fixedAmount
                            : `${pItem?.percentageAmount} %`}
                        </td>
                        <td>
                          {pItem?.dayAfterPaymentDue === null ? '-' : pItem?.dayAfterPaymentDue}
                        </td>
                        <td>
                          {pItem?.frequency === null
                            ? '-'
                            : pItem?.frequency === 0
                            ? 'Once'
                            : pItem?.frequency === 1
                            ? 'Daily'
                            : pItem?.frequency === 2
                            ? 'Weekly'
                            : pItem?.frequency === 3
                            ? 'Monthly'
                            : ''}
                        </td>
                        <td className='d-flex'>
                          <Switch
                            onChange={(e) => {
                              setChecked(e)
                              setStatusUpd(true)
                              setUpdItem(pItem)
                              if (
                                pItem.fixedAmount === null &&
                                pItem.dayAfterPaymentDue === null &&
                                pItem.frequency === null &&
                                pItem.feeType === null
                              ) {
                                setStatusUpd(false)
                                ErrorToast('Please add some values first!!!')
                              } else {
                                if (pItem?.activityStatus === 0) updateStatus(pItem)
                                else {
                                  confirmPop(pItem, 1)
                                }
                              }
                            }}
                            // defaultChecked={checked}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={pItem.activityStatus === 1 ? true : false}
                            onColor='#00cf99'
                            offColor='#b0bec5'
                          />
                          <p className='ms-4 pt-1'>
                            <i>{pItem.activityStatus === 0 ? 'Inactive' : 'Active'}</i>
                          </p>
                        </td>

                        <td>
                          {' '}
                          {pItem.fixedAmount === null &&
                          pItem.dayAfterPaymentDue === null &&
                          pItem.frequency === null &&
                          pItem.feeType === null &&
                          pItem.activityStatus === 0 ? (
                            <div className='d-flex'>
                              <img
                                src={add}
                                height='21px'
                                width='21px'
                                alt=''
                                className='me-4'
                                style={{cursor: 'pointer', marginTop: '3px'}}
                                onClick={() => editDetails(pItem)}
                              />
                            </div>
                          ) : (
                            <div className='d-flex'>
                              <img
                                src={pen}
                                height='19px'
                                width='19px'
                                alt=''
                                className='me-4'
                                style={{cursor: 'pointer', marginTop: '3px'}}
                                onClick={() => editDetails(pItem)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Edit Late Payment fee */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={latePaymentModal}
        onHide={() => {
          setLatePaymentModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col xs={8} md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Late Payment Fee</h2>
            </Col>
            <Col xs={4} md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setLatePaymentModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} md={9}></Col>
            <Col xs={3} md={3} className='pt-2 text-end'>
              <img src={latePaymentWhite} height='80px' width='80px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-50px'}}
        >
          <div className='d-flex align-items-center'>
            <div>
              <h3 className='head-text' style={{fontWeight: '800'}}>
                {itemName}
              </h3>
            </div>
            <div className='align-items-center text-end ms-auto'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => validate()}
              >
                <img
                  src={submitImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '15px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '}
                {'  '}
                Submit
              </button>
            </div>
          </div>
          <Row className='mt-5'>
            <Col sm={6} md={6} lg={4} className='align-items-center mb-5'>
              <label className='required mb-2 label-color'>Days after payment is due</label>
              <input
                type='number'
                className={
                  dueDaysError
                    ? 'form-control form-control-solid-bg border-danger mytest'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Days'
                name='item_id'
                value={dueDays}
                min='1'
                onChange={(e) => {
                  setDueDaysError(false)
                  setDueValError(false)
                  setDueDays(e.target.value)
                }}
                style={{borderRadius: '2px', padding: '0.54rem 1rem'}}
              />
              {dueValError && <p className='text-danger'>This field should not be 0 or less</p>}
            </Col>
            <Col sm={6} md={6} lg={4} className='align-items-center mb-5'>
              <label className='required mb-2 label-color'>Frequency</label>
              <Select
                options={options}
                styles={customStylesInputFilled}
                isSearchable={false}
                defaultValue={
                  fq === 0
                    ? options[0]
                    : fq === 1
                    ? options[1]
                    : fq === 2
                    ? options[2]
                    : fq === 2
                    ? options[3]
                    : options[0]
                }
                onChange={(e: any) => {
                  setFq(e.value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={6} lg={4} className='align-items-center mb-5'>
              <label className='required mb-2 label-color'>Fee Type</label>

              <Select
                options={feeType}
                styles={customStylesInputFilled}
                isSearchable={false}
                defaultValue={fType === 1 ? feeType[2] : feeType[0]}
                onChange={(e: any) => {
                  setFTyp(e.value)
                }}
              />
            </Col>
            <Col sm={6} md={6} lg={4} className='align-items-center mb-5'>
              <label className='required mb-2 label-color'>Fee</label>
              <input
                type='number'
                className={
                  feeError
                    ? 'form-control form-control-solid-bg border-danger mytest'
                    : 'form-control form-control-solid-bg mytest'
                }
                placeholder='Enter Amount'
                name='item_id'
                style={{borderRadius: '2px', padding: '0.54rem 1rem'}}
                value={fee}
                onChange={(e) => {
                  setFeeError(false)
                  setFee(e.target.value)
                }}
              />
            </Col>

            <Col sm={6} md={6} lg={4} className='align-items-center mb-5'>
              <label className='required mb-2 label-color'>VAT (%)</label>
              <input
                className={
                  vatError
                    ? 'form-control form-control-solid-bg border-danger mytest'
                    : 'form-control form-control-solid-bg mytest'
                }
                type='number'
                placeholder='Enter %'
                name='item_id'
                style={{borderRadius: '2px', padding: '0.51rem 1rem'}}
                // style={
                //   vatPer == ''
                //     ? {
                //         border: '0.1rem solid #bec3cb',
                //         borderRadius: '2px',
                //         padding: '0.51rem 1rem',
                //       }
                //     : {
                //         border: '0.1rem solid #384a62 ',
                //         borderRadius: '2px',
                //         padding: '0.51rem 1rem',
                //       }
                // }
                value={vatPer}
                onChange={(e) => {
                  setVatError(false)
                  setVatPer(e.target.value)
                }}
              />
            </Col>
          </Row>
        </div>
        <div style={{backgroundColor: '#f2f2f2'}}>
          <Row className='mx-4 pt-2'>
            <p className='' style={{color: '#9797ae', fontWeight: '500'}}>
              <i>
                Applied to payments that have passed their due date and that are ‘unpaid’ ,
                ‘partially paid’ and ‘overdue’. Will not be applied to ‘deferred’ payments or
                payments that have an updated due date.
              </i>
            </p>
          </Row>
        </div>
      </Modal>

      {/* Confirmation modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-550px mx-auto'
        show={confirmationModal}
        onHide={() => {
          setConfirmationModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', minHeight: '65px', boxShadow: 'none', borderBottom: '0px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Apply Changes</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                style={{marginTop: '-10px'}}
                onClick={() => setConfirmationModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 mt-2'>
            <Col>
              <label className='head-text fs-4' style={{fontWeight: '600'}}>
                Do you wish to apply this change on existing payments?
              </label>
            </Col>
          </Row>

          <Row className='align-items-center justify-content-center mb-5 mt-2'>
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setLatePaymentModal(false)
                  setConfirmationModal(false)
                  if (statusUpd === true) updateStatus(updItem)
                  else updateLatePaymentFee()
                }}
              >
                {'  '}
                Do Not Apply
              </button>
            </Col>
          </Row>
          <Row
            className='align-items-center justify-content-center mb-5'
            style={{marginTop: '40px'}}
          >
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setLatePaymentModal(false)
                  setConfirmationModal(false)
                  if (statusUpd === true) updateStatusOfAll(updItem)
                  else applyToAll()
                }}
              >
                Apply to All Payments
              </button>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center mb-3'>
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                // onClick={() => updateLatePaymentFee()}
                onClick={() => {
                  setConfirmationModal(false)
                  getListOfAssociatedPayments()
                }}
              >
                Apply to Selected Payments
              </button>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Selected Payments */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={selectedPaymentsModal}
        onHide={() => {
          setSelectedPaymentsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', minHeight: '65px', boxShadow: 'none', borderBottom: '0px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Payments</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                style={{marginTop: '-10px'}}
                onClick={() => setSelectedPaymentsModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 me-3'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <label className='head-text fs-3 ' style={{fontWeight: '600'}}>
                Which payments do you wish to apply this change to?
              </label>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '110px',
                  //   paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  if (statusUpd === true) updateStatusOfSelected(updItem)
                  else applyToSelected()
                }}
              >
                Apply
              </button>
            </Col>
          </Row>

          <div className='table-responsive'>
            <table
              className='table align-start fs-6 gy-5 table-responsive'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className=''>
                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexCheckDefault'
                        style={{cursor: 'pointer'}}
                        onChange={(e: any) => {
                          console.log(e.target.checked)
                          if (e.target.checked === true) {
                            for (let i = 0; i < payments?.length; i++) {
                              const values = [...checkStatus]
                              values[i].status = true
                              setCheckStatus(values)
                            }

                            console.log(checkStatus)
                          } else {
                            console.log('I am in else')
                            for (let i = 0; i < payments?.length; i++) {
                              const values = [...checkStatus]
                              values[i].status = false
                              setCheckStatus(values)
                            }
                            console.log(checkStatus)
                          }
                        }}
                      />
                    </div>
                  </th>
                  <th className='min-w-75px'>#</th>
                  <th className='min-w-100px'>Unit No.</th>
                  <th className='min-w-100px'>Payment</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px'>Category</th>
                  <th className='min-w-100px'>Due</th>
                  <th className='min-w-125px'>Previous Fee</th>
                  <th className='min-w-175px'>Previous Frequency</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
                {payments?.length > 0 ? (
                  payments?.map((pay: any, i: any) => {
                    return (
                      <tr>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexCheckDefault'
                              style={{cursor: 'pointer'}}
                              defaultChecked={checkStatus[i]?.status}
                              onChange={(e) => {
                                console.log(e.target.checked)
                                const values = [...checkStatus]
                                values[i].status = e.target.checked
                                setCheckStatus(values)
                              }}
                              // onChange={(e) => selectedUnits(i?._id, f?._id, e)}
                            />
                          </div>
                        </td>
                        <td className=''>{pay?.id}</td>
                        <td className=''>{pay?.payment?.unit?.[0]?.unitNo}</td>
                        <td className=''>{pay?.name}</td>
                        <td className=''> {pay?.totalAmount}</td>
                        <td className=''>
                          {pay?.payment?.paymentStatus == 0
                            ? 'Unpaid'
                            : pay?.payment?.paymentStatus == 1
                            ? 'Paid'
                            : 'Partially Paid'}{' '}
                        </td>
                        <td className=''>
                          {pay?.payment?.categories == 0
                            ? 'Upcoming'
                            : pay?.payment?.categories == 1
                            ? 'Overdue'
                            : 'Deferred'}{' '}
                        </td>

                        <td className=''>{moment(pay?.payment?.dueDate).format('YYYY.MM.DD')} </td>
                        <td className=''>
                          {pay?.latePaymentObject?.feeType == 0
                            ? pay?.latePaymentObject?.fixedAmount
                            : pay?.latePaymentObject?.percentageAmount}{' '}
                        </td>
                        <td className=''>
                          {' '}
                          {pay?.latePaymentObject?.frequency == 0
                            ? 'Once'
                            : pay?.latePaymentObject?.frequency == 1
                            ? 'Daily'
                            : pay?.latePaymentObject?.frequency == 2
                            ? 'Weekly'
                            : 'Monthly'}{' '}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default BuildingsLatePaymentFee

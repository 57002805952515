import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, Col, Dropdown, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {AiFillDelete} from 'react-icons/ai'
import {MultiSelect} from 'react-multi-select-component'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import PropertyFilter from './PropertyFilter'

let selectedProperty: any = []

let unitArray: any = []

const SelectProperties = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
    reset,
  } = useForm<any>()
  const [type, setType] = useState('')
  const [devlopment, setdevlopment] = useState<any>([])
  const [formData1, setFormData1] = useState<any>({})
  const [date, setdate] = useState()
  const [peopertyUnit, setPropertyUnit] = useState<any>([])
  const [cluster, setcluster] = useState([])
  const [flour, setflour] = useState<any>({})
  const [clusterflour, setclusterflour] = useState([])
  const [unit, setunit] = useState([])
  const [clusterunit, setclusterunit] = useState([])
  const [clusterunitgroup, setclusterunitgroup] = useState([])
  const [clustertype, setclustertype] = useState()
  const [clusterbuilding, setclusterbuilding] = useState([])
  const [ChnageUGB, setChnageUGB] = useState()
  const [selected, setSelected] = useState([])
  const [communityId, setCommunityId] = useState<any>()
  const [mixedClusterData, setMixedClusterData] = useState<any>()
  const [unitGroupData, setUnitGroupData] = useState<any>()
  const [floorName, setFloorName] = useState<any>()
  const [developmentName, setDevelopmentName] = useState<any>()
  const [buildingId, setBuildingId] = useState<any>()
  const [selectedUnitData, setSelectedUnitData] = useState<any>([])
  const currentTab: any = localStorage.getItem('currentTab')
  const requestServiceId: any = localStorage.getItem(currentTab)

  const pathName = window.location.pathname

  const handleChnage12 = (e: any) => {
    const {name, value} = e.target
    setFormData1({...formData1, [name]: value.split('/')[0]})
    setType(value.split('/')[1].split('-')[0])
    setDevelopmentName(value.split('/')[2])

    if (value.split('/')[1].split('-')[0] == 'C') {
      setclusterunit([])
      setclusterflour([])
      setclusterbuilding([])
      setclusterunitgroup([])
      callcluster(value.split('/')[0])
      setCommunityId(value.split('/')[0])
    } else if (value.split('/')[1].split('-')[0] == 'B') {
      callflour(value.split('/')[0])
      setunit([])
      setflour([])
      setBuildingId(value.split('/')[0])
    }
  }

  const handleChnageCluster = (e: any) => {
    const {name, value} = e.target
    setFormData1({...formData1, [name]: value})
    let dte: any = cluster.filter((v: any, index: any) => v._id == value)
    // setcluster([])
    setclustertype(dte[0].type)
    if (dte[0].type == 0) {
      callunitbyclusterid(value)
    } else if (dte[0].type == 1) {
      callclusterbuilding(value)
    } else if (dte[0].type == 2) {
      callclusterunitgroup(value)
    }
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
  }
  const handleChnageclusterbuilding = (e: any) => {
    const {name, value} = e.target
    setclusterunit([])
    setclusterflour([])
    // setclusterbuilding([])
    setBuildingId(value)
    callflour(value)
    setFormData1({...formData1, [name]: value})
  }
  const handleChnageclusterunit = (e: any) => {
    const {name, value} = e.target
    setclusterunit([])
    callclusterunitbyclusterflour(value)
    setFormData1({...formData1, [name]: value})
  }
  const handleChnageUGB = (e: any) => {
    const {name, value} = e.target
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
    setChnageUGB(value.split('/')[1]?.split(' ')[0])
    if (value.split('/')[1]?.split(' ')[0] == 'Building') {
      callclusterflour(value.split('/')[0])
      setFormData1({...formData1, ['Building']: value.split('/')[0]})
    } else if (value.split('/')[1]?.split(' ')[0] == 'Unit') {
      callunitbyunitgroupid(value.split('/')[0])
      setFormData1({...formData1, ['Unit']: value.split('/')[0]})
    }
  }

  const callunitbyunitgroupid = (value: any) => {
    ApiGet(`corporate/unit?unitGroupId=${value}`)
      .then((res) => {
        setclusterunit(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const callclusterunitgroup = (value: any) => {
    const body = {
      communityId: communityId,
      clusterId: value,
    }
    ApiPost(`corporate/service_request/get_unit_list`, body)
      .then((res) => {
        setMixedClusterData(res?.data?.data?.communityData[0]?.cluster[0]?.building)
        setUnitGroupData(res?.data?.data?.communityData[0]?.cluster[0]?.unit_group)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callunitbyclusterid = (value: any) => {
    ApiGet(`corporate/unit?clusterId=${value}`)
      .then((res) => {
        setclusterunit(res?.data?.data)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterunitbyclusterflour = (value: any) => {
    ApiGet(`corporate/unit?floorId=${value}`)
      .then((res) => {
        setclusterunit(res?.data?.data)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterflour = (value: any) => {
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        setclusterflour(res?.data?.data?.map((i: any) => ({...i, label: i?.name, value: i?._id})))
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterbuilding = (value: any) => {
    ApiGet(`corporate/building?clusterId=${value}`)
      .then((res) => {
        setclusterbuilding(res?.data?.data)
        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const callunitbyflour = () => {
    setSelectedUnitData([])
    selected?.map((i: any, index: any) => {
      const body = {
        buildingId: buildingId,
        floorId: i?._id,
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      }
      bodyFunction(body, index)
    })
  }

  const bodyFunction = async (body: any, index: any) => {
    if (type === 'C') {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          const newData = res?.data?.data?.communityData[0]?.cluster[0]?.building[0]?.floor
          setSelectedUnitData((prev: any) => [...prev, newData[0]])
        })
        .catch((err) => console.log('err', err))
    } else {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          const newData = res?.data?.data?.buildingData[0]?.floor
          setSelectedUnitData((prev: any) => [...prev, newData[0]])
        })
        .catch((err) => console.log('err', err))
    }
  }

  useEffect(() => {
    callunitbyflour()
  }, [selected])

  useEffect(() => {
    setSelected([])
    setclusterunit([])
  }, [developmentName])

  const callcluster = (value: any) => {
    ApiGet(`corporate/cluster?communityId=${value}`)
      .then((res) => {
        setcluster(res?.data?.data)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callflour = (value: any) => {
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        // setflour(res?.data?.data)
        setflour(
          res?.data?.data?.map((i: any) => ({...i, label: i?.name, value: i?._id, checked: true}))
        )

        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callDevelopment = () => {
    ApiGet(`corporate/communities/get_development`)
      .then((res) => {
        setdevlopment(res?.data?.data)
        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  React.useEffect(() => {
    callDevelopment()
  }, [])

  const addServiceRequest = async () => {
    const body = {
      requestType: 1,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }
  }, [requestServiceId])

  const addProperty = async () => {
    const body = {
      serviceRequestId: requestServiceId,
      service_job: selectedProperty,
    }
    await ApiPost(`corporate/service_job/multiple_add`, body)
      .then((res) => {})
      .catch((e) => {
        console.log(e)
      })
    moveVendor()
  }

  const selectedUnits = (id: any, floorId: any) => {
    if (selectedProperty.includes(id)) {
      selectedProperty = selectedProperty.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: floorId ? floorId : null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }
  }
  const unitData = (id: any) => {
    if (selectedProperty.includes(id)) {
      selectedProperty = selectedProperty.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }
  }

  const setPropertyUnitData = () => {
    setPropertyUnit({developmentName: developmentName, totalunit: selectedProperty.length})
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }
  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer' onClick={() => moveVendor()}>
                External Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Select Properties</a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service/create-request-service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                // navigate(`/request-management/quotation`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li className='nav-item' onClick={() => moveVendor()}>
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div>

        <div className='card card-flush app-container py-5'>
          <Row>
            <div className='col-md-6'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => moveVendor()}
              >
                Back
              </a>
            </div>

            <div className='col-md-6 d-flex justify-content-center'>
              <button
                className='btn w-50 fw-bold btn-primary btn-green'
                onClick={() => addProperty()}
              >
                Add
              </button>
            </div>
          </Row>
          <Row className='align-items-center'>
            <div className='col-md-8'>
              <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                Select Properties
              </h3>
              <form>
                <div className='mb-5'>
                  <div
                    className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                    id='kt_modal_create_app_stepper'
                  >
                    <div
                      className='card-header align-items-center gap-md-2'
                      style={{justifyContent: 'flex-start'}}
                    >
                      <div className='mb-10 min-w-200px '>
                        <label htmlFor='exampleFormControlInput1' className='required form-label'>
                          Development
                        </label>
                        <select
                          {...register('unitType', {required: true})}
                          name='unitType'
                          className='form-select form-select-solid'
                          onChange={handleChnage12}
                        >
                          <option value=''>Select</option>
                          {devlopment.length > 0 &&
                            devlopment.map((data: any, i: any) => (
                              <option value={`${data._id}/${data?.id}/${data.name}`}>
                                {data.name}
                              </option>
                            ))}
                        </select>
                        {errors.unitType && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )}
                      </div>
                      {type === 'C' && (
                        <>
                          <div className='mb-10 min-w-200px '>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Cluster
                            </label>
                            <select
                              {...register('Cluster', {required: true})}
                              name='Cluster'
                              className='form-select form-select-solid'
                              value={formData1?.Cluster}
                              onChange={handleChnageCluster}
                            >
                              <option selected value=''>
                                Select
                              </option>
                              {cluster.length > 0 &&
                                cluster.map((data: any, i) => (
                                  <option value={data._id}>{data.name}</option>
                                ))}
                            </select>
                            {errors.Cluster && (
                              <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                            )}
                          </div>
                          {clustertype == 2 && mixedClusterData ? (
                            <div className='mb-10 min-w-200px '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Select UG/B
                              </label>
                              <select
                                {...register('clusterType', {required: true})}
                                className='form-select form-select-solid'
                                onChange={handleChnageUGB}
                              >
                                <option selected value=''>
                                  Select
                                </option>
                                {[...mixedClusterData, ...unitGroupData]?.map(
                                  (data: any, i: any) => {
                                    return (
                                      <option value={`${data._id}/${data?.name}`}>
                                        {data.name}
                                      </option>
                                    )
                                  }
                                )}
                              </select>
                              {errors.clusterType && (
                                <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                              )}
                            </div>
                          ) : null}
                          {clustertype == 1 ? (
                            <div className='mb-10 min-w-200px '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Building
                              </label>
                              <select
                                {...register('building', {required: true})}
                                name='building'
                                className='form-select form-select-solid'
                                value={formData1?.building}
                                onChange={handleChnageclusterbuilding}
                              >
                                <option selected value=''>
                                  Select
                                </option>
                                {clusterbuilding.length > 0 &&
                                  clusterbuilding.map((data: any, i) => (
                                    <option value={data._id}>{data.name}</option>
                                  ))}
                                {errors.building && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )}
                              </select>
                            </div>
                          ) : null}
                          {formData1?.building || (clustertype == 2 && ChnageUGB == 'Building') ? (
                            <div className='mb-10 min-w-200px w-200px'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Floor
                              </label>
                              <MultiSelect
                                options={flour}
                                value={selected}
                                onChange={setSelected}
                                labelledBy='Select'
                              />
                              {errors.floor && (
                                <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                              )}
                            </div>
                          ) : null}
                          <div className='row gy-2'></div>
                        </>
                      )}
                      {type === 'B' && (
                        <>
                          <div className='mb-10 min-w-200px w-200px'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Floor
                            </label>
                            <MultiSelect
                              options={flour}
                              value={selected}
                              onChange={setSelected}
                              labelledBy='Select'
                            />

                            {errors.flour && (
                              <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <a
                  className='btn w-50 fw-bold btn-primary btn-green mx-3'
                  onClick={setPropertyUnitData}
                >
                  Add
                </a>
              </div>
            </div>
            <div className='col-md-4 mt-5 select-box'>
              <div className='mb-10 service_list  mx-10'>
                <Row>
                  <Col md={6}>
                    <h5 className='mb-5 mt-5' style={{color: '#007a59'}}>
                      {' '}
                      Properties Selected
                    </h5>
                  </Col>
                  <Col md={6}>
                    <h5 className='mb-2 mt-5'> Total Buildings/Total Communities :</h5>
                    <h5 className='mb-5 '> Total Units : {peopertyUnit?.totalunit}</h5>
                  </Col>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='kt_ecommerce_sales_table'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='text-center min-w-100px'>Development name</th>
                        <th className='text-center min-w-100px'>Total Units</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold text-gray-600'>
                      {peopertyUnit?.developmentName && (
                        <tr>
                          <td className='text-center min-w-100px'>
                            {peopertyUnit?.developmentName}
                          </td>
                          <td className='text-center min-w-100px'>{peopertyUnit?.totalunit}</td>
                          <td>
                            <AiFillDelete />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Row>
              </div>
            </div>
          </Row>
          <Row>
            <Col md={3}>
              <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                {developmentName}
              </h3>
            </Col>
            <Col md={3}>
              <div className='d-flex align-items-center mb-5 mt-5'>
                <div className='d-flex align-items-center me-7'>
                  <div className='green-dot'></div> <h5 style={{marginBottom: '0px'}}>Vacant</h5>
                </div>
                <div className='d-flex align-items-center'>
                  {' '}
                  <div className='gray-dot'></div> <h5 style={{marginBottom: '0px'}}>Occupied</h5>
                </div>
              </div>
            </Col>
          </Row>

          {selectedUnitData?.map((f: any, i: any) => {
            return (
              <Row>
                <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                  {f?.name}
                </h3>
                {f?.unit?.length > 0 &&
                  f?.unit?.map((i: any) => (
                    <Col md={2}>
                      <div className='d-flex align-items-center'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexCheckDefault'
                            style={{cursor: 'pointer'}}
                            onChange={() => selectedUnits(i?._id, f?._id)}
                          />
                        </div>
                        <h6 style={{marginRight: '2px'}}>Unit :{i?.unitNo}</h6>
                        {i?.occupy === 1 ? (
                          <div className='green-dot' style={{marginBottom: '7px'}}></div>
                        ) : (
                          <div className='gray-dot' style={{marginBottom: '7px'}}></div>
                        )}
                      </div>
                    </Col>
                  ))}
              </Row>
            )
          })}
          <Row className='Data'>
            {clusterunit &&
              clusterunit.map((f: any, i) => {
                return (
                  <Col md={2}>
                    <div className='d-flex align-items-center'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault'
                          style={{cursor: 'pointer'}}
                          onChange={() => unitData(f?._id)}
                        />
                      </div>
                      <h6 style={{marginRight: '2px'}}>Unit :{f?.unitNo}</h6>
                      {/* <div style={{ marginBottom: '7px' }} className='green-dot'></div> */}
                    </div>
                  </Col>
                )
              })}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default SelectProperties

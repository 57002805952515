import React, {useState, useEffect} from 'react'
import SliderComponent from './SliderComponent'
import RangePicker from './RangePicker'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import moment from 'moment'
import OccupancyChart from './OccupancyChart'
import UnitTypeOccupancy from './UnitTypeOccupancy'
import BedroomSizeOccupancy from './BedroomSizeOccupancy'
import VacancyRate from './VacancyRate'
import AverageVacantDays from './AverageVacantDays'
import AverageRent from './AverageRent'
import RentCollectionRate from './RentCollectionRate'
import {MultiSelect} from 'react-multi-select-component'
import {LeaseChart} from './LeaseChart'
import TenantChart from './TenantChart'
import Lease3 from './LeaseNewChart'
import TenantTurnOverRate from './TenantTurnOverRate'
import TenantRetentionRate from './TenantRetentionRate'
import TenantComplaintChart from './TenantComplaintChart'
import TenantSatisfactionChart from './TenantSatisfactionChart'
import OverAllTenantSatisfaction from './OverAllTenantSatisfaction'
import RevenueChart from './RevenueChart'
import IncomeChart from './IncomeChart'
import IncomeVsExpanseChart from './IncomeVSexpense'
import IncomeExpenseBreakdownChart from './IncomeExpenseBreakdownChart'
import IncomeBreakdownChart from './IncomeBreakdownChart'
import ExpenseBreakdownChart from './ExpenseBreakdownChart'
import allPropImg from '../../../img/allProperties.png'
import NewLeaseChart from './NewLeaseChart'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {DateRangePicker} from 'react-dates'
import LeaseMonthWiseChart from './LeaseMonthWise'
import OccupancyChartMonthly from './Monthly/OccupancyChartData'
import TenantChartMonthly from './Monthly/TenantChartMonthwise'
import SatisfactionTenantMonthly from './Monthly/SatisfactionTenant'
import RevenueChartMonthly from './Monthly/RevenueChartMonthly'
import IncomeChartMonthly from './Monthly/IncomeChart'
import IncomeVsExpanseChartMonthly from './Monthly/IncomeVSExpenseMonthly'
import IncomeExpenseBreakdownChartMonthly from './Monthly/IncomeExpenseBreakdownMonthly'
import TenantComplaintChartMonthly from './Monthly/TenantComplaintChartMonthly'
import ExpenseBreakdownChartMonthly from './Monthly/ExpenseBreakDownMonthly'
import IncomeBreakdownChartMonthly from './Monthly/IncomeBreakdownMonthly'

const Dashboard = () => {
  // Create start date for the year (1st January)
  const currentDate = new Date()
  const year: any = currentDate.getFullYear()
  const start: any = new Date(year, 0, 1)

  const [open, setOpen] = React.useState(false)

  let startDateStored: any = localStorage?.getItem('dashboardStartDate')
  let endDateStored: any = localStorage?.getItem('dashboardEndDate')

  const [startDate, setStartDate] = useState<any>(
    startDateStored != null && startDateStored != undefined && startDateStored != 'null'
      ? moment(JSON.parse(startDateStored))
      : start
      ? moment(start)
      : null
  )
  const [endDate, setEndDate] = useState<any>(
    endDateStored != null && endDateStored != undefined && endDateStored != 'null'
      ? moment(JSON.parse(endDateStored))
      : moment(new Date().toISOString())
  )

  const [startDatePayload, setStartDatePayload] = useState<any>(
    startDateStored != null && startDateStored != undefined && startDateStored != 'null'
      ? moment(JSON.parse(startDateStored)).format('YYYY-MM-DD')
      : start
      ? moment(start)?.format('YYYY-MM-DD')
      : null
  )
  const [endDatePayload, setEndDatePayload] = useState<any>(
    endDateStored != null && endDateStored != undefined && endDateStored != 'null'
      ? moment(JSON.parse(endDateStored)).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD')
  )

  const [filteredData, setFilteredData] = useState<any>()

  const [refreshChild, setRefreshChild] = useState<any>(new Date())

  let unitsStored: any = localStorage?.getItem('dashboardSelectedUnits')

  const [unitsList, setUnitsList] = useState<any>([])
  const [selectedUnits, setSelectedUnits] = useState<any>(
    unitsStored ? JSON.parse(unitsStored) : []
  )

  const [selectedUnitsOfDropdown, setSelectedUnitsOfDropdown] = useState<any>([])

  //
  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    if (startDate == null && endDate == null) {
      setStartDate(null)
      setStartDatePayload(null)
      setEndDate(null)
      setEndDatePayload(null)
    } else {
      setStartDate(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEndDate(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))
    }

    localStorage?.setItem('dashboardStartDate', JSON.stringify(startDate))
    localStorage?.setItem('dashboardEndDate', JSON.stringify(endDate))
  }

  const [occupancyChartData, setOccupancyChartData] = useState<any>()
  const [tenantGrowthData, setTenantGrowthData] = useState<any>()
  const [tenantTurnOverRate, setTenantTurnOverRate] = useState<any>()
  const [tenantRetentionRate, setTenantRetentionRate] = useState<any>()
  const [satisfactionChart, setSatisfactionChart] = useState<any>()
  const [overallTenantSatisfied, setOverallTenantSatisfied] = useState<any>()
  const [revenueChartData, setRevenueChartData] = useState<any>()
  const [incomeChartData, setIncomeChartData] = useState<any>()
  const [overAllGreenCardCalculations, setOverAllGreenCardCalculations] = useState<any>()
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState<any>()
  const [incomeExpenseBreakdownData, setIncomeExpenseBreakdownData] = useState<any>()
  const [tenantComplaintData, setTenantComplaintData] = useState<any>()

  const getMonthlyData = () => {
    const body = {
      year: 2024,
      unitIds: selectedUnits,
    }
    ApiPost(`corporate/dashboard/chart/monthly`, body)
      .then((res: any) => {
        setOverAllGreenCardCalculations(res?.data?.data)
        setOccupancyChartData(res?.data?.data?.occupancyChartData)
        setTenantGrowthData(res?.data?.data?.tenantGrowthData)
        setTenantTurnOverRate(res?.data?.data?.averageTurnoverRate)
        setTenantRetentionRate(res?.data?.data?.averageRetentionRate)
        setSatisfactionChart(res?.data?.data?.tenantSatisfactionData)
        setOverallTenantSatisfied(res?.data?.data?.overallTenantSatisfaction)
        setRevenueChartData(res?.data?.data?.paymentRevenueData)
        setIncomeChartData(res?.data?.data?.paymentIncomeData)
        setIncomeVsExpenseData(res?.data?.data?.incomeExpenseChartData)
        setIncomeExpenseBreakdownData(res?.data?.data?.incomeExpenseChartBreakdownData)
        setTenantComplaintData(res?.data?.data?.tenantComplaintData)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getMonthlyData()
  }, [selectedUnits])

  //
  const filterDataForDashboard = () => {
    setOpen(true)
    const body = {
      startDate: startDatePayload,
      endDate: endDatePayload,
      unitIds: selectedUnits,
      year: 2024,
    }

    ApiPost(`corporate/dashboard/analytic`, body)
      .then((res: any) => {
        setFilteredData(res?.data?.data)

        // setTimeout(() => {
        setOpen(false)
        // }, 200)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
      })
  }

  let devStored: any = localStorage?.getItem('dashboardSelectedDevelopments')
  console.log(devStored)

  const [developmentsList, setDevelopmentsList] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>(
    devStored ? JSON.parse(devStored) : []
  )

  //
  const developmentListForDashboard = () => {
    const body = {
      startDate: startDatePayload,
      endDate: endDatePayload,
      year: 2024,
    }

    ApiPost(`corporate/dashboard/development/list`, body)
      .then((res: any) => {
        setDevelopmentsList(res?.data?.data)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    filterDataForDashboard()
  }, [startDatePayload, endDatePayload, selectedUnits, selectedDevelopments])

  useEffect(() => {
    developmentListForDashboard()
  }, [])

  //
  const reFilterData = (devVal: any, vals: any, chk: any) => {
    let values: any = [...selectedUnits]
    let devValues: any = [...selectedDevelopments]

    if (chk == 0) {
      // console.log(selectedDevelopments)
      // console.log("************* Add in  Parent ************")
      // console.log(devVal)

      devValues?.push(devVal)
      // console.log(devValues)
      setSelectedDevelopments(devValues)

      vals?.map((vl: any) => {
        values.push(vl)
      })

      // console.log(values)

      setSelectedUnits(values)
    } else {
      selectedDevelopments?.map((dl: any, ind: any) => {
        // console.log(selectedDevelopments)
        // console.log("************* Remove in  Parent ************")
        // console.log(devVal)
        if (devVal?.data?._id == dl) {
          devValues.splice(ind, 1)

          devVal?.data?.unitIds?.map((dUnt: any, index: any) => {
            selectedUnits?.map((sl: any, ind: any) => {
              if (sl == dUnt) {
                let temp = values?.splice(ind, 1)
                values = temp
              }
            })
          })
        }
      })

      setSelectedDevelopments(devValues)
      setSelectedUnits(values)
    }

    localStorage.setItem('dashboardSelectedUnits', JSON.stringify(values))
    localStorage.setItem('dashboardSelectedDevelopments', JSON.stringify(devValues))
  }

  //
  const getUnitsList = () => {
    const body = {
      unitsIds: [],
      allowChartParent: true,
    }

    ApiPost(`corporate/development/unit/get`, body)
      .then((res: any) => {
        let values: any = []

        res?.data?.data?.map((dt: any, ind: any) => {
          values[ind] = {
            label: `${dt?.unitNo} - ${
              dt?.community?.length > 0 ? dt?.community?.[0]?.name : dt?.building?.[0]?.name
            }`,
            value: dt._id,
          }
        })
        setUnitsList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getUnitsList()
  }, [selectedDevelopments])

  useEffect(() => {
    let values: any = []
    let indexOfValues: any = 0
    unitsList?.map((ul: any, ind: any) => {
      selectedUnits?.map((sl: any, index: any) => {
        // console.log(sl)
        // console.log(ul?.value)
        if (sl == ul?.value) {
          values[indexOfValues] = ul
          indexOfValues++
        }
      })
    })

    // console.log(values)
    setSelectedUnitsOfDropdown(values)
  }, [selectedUnits, unitsList])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Dashboard </b>
            </h1>
          </div>
        </div>

        <div className='d-flex justify-content-end test '>
          <div className=''>
            <label className='fnt-400 ps-3'>Properties</label>
            <div className='dashboard-multi-select'>
              <div
                className={`multi-select-container dashboard-units-list me-5 mb-5  ${
                  selectedUnits.length === 0 ? 'no-value floor' : 'has-value'
                }`}
              >
                <MultiSelect
                  options={unitsList}
                  value={selectedUnitsOfDropdown}
                  onChange={(e: any) => {
                    setSelectedUnitsOfDropdown(e)

                    let values: any = []
                    e?.map((el: any, ind: any) => {
                      values[ind] = el?.value
                    })

                    setSelectedUnits(values)
                    localStorage.setItem('dashboardSelectedUnits', JSON.stringify(values))
                  }}
                  labelledBy='Units '
                  overrideStrings={{
                    allItemsAreSelected: 'All Units',
                  }}
                />
              </div>
            </div>
          </div>

          <div className='test property me-4 dashboard'>
            <div className=''>
              <label className='fnt-400 ps-3'>Date Range</label>
              <RangePicker
                startDate={startDate}
                endDate={endDate}
                dateStatuses={[]}
                onDatesChange={handleDatesChange}
                autoSave={() => {}}
                from={'create'}
                disabled={false}
              />
            </div>
          </div>
          <div></div>
        </div>
        {selectedDevelopments && (
          <div className='row mt-5'>
            <SliderComponent
              devData={developmentsList}
              updateParent={(dev: any, units: any) => {
                reFilterData(dev, units, 0)
              }}
              selectedDev={selectedDevelopments}
              removeFromParent={(dev: any, units: any) => {
                // console.log(dev)
                // console.log(units)
                reFilterData(dev, units, 1)
              }}
            />
          </div>
        )}

        {/* Green Cards */}
        <div className='d-flex flex-wrap mt-10 mx-1' style={{justifyContent: 'space-between'}}>
          {/* Properties */}
          <div>
            {/* <div className='status-w-220 text-end'>
              <p className='ms-auto pe-2 me-1 mb-1'>
                {filteredData?.overallPropertise ? filteredData?.overallPropertise?.percentage : 0}%
              </p>
            </div> */}
            <div className='dash-green-card d'>
              <h1 className='text-white'>Properties</h1>
              <div className='d-flex align-items-center'>
                <h1 className='text-white fnt-700'>
                  {filteredData?.overallPropertise
                    ? filteredData?.overallPropertise?.value?.toLocaleString('en-US')
                    : 0}
                </h1>
                <img src={allPropImg} height={40} width={40} className='ms-auto' />
              </div>
            </div>
          </div>

          {/* Tenants */}
          <div>
            {/* <div className='status-w-220 text-end'>
              <p className='ms-auto pe-2 me-1 mb-1'>
                {filteredData?.overallTenant ? filteredData?.overallPropertise?.percentage : 0}%
              </p>
            </div> */}
            <div className='dash-green-card d'>
              <h1 className='text-white'>Tenants</h1>
              <div className='d-flex align-items-center'>
                <h1 className='text-white fnt-700'>
                  {filteredData?.overallTenant
                    ? filteredData?.overallTenant?.value?.toLocaleString('en-US')
                    : 0}
                </h1>
                <img src={allPropImg} height={40} width={40} className='ms-auto' />
              </div>
            </div>
          </div>

          {/* Occupancy*/}
          <div>
            {/* <div className='status-w-220 text-end'>
              <p className='ms-auto pe-2 me-1 mb-1'>
                {filteredData?.occupancyRate ? filteredData?.overallPropertise?.percentage : 0}%
              </p>
            </div> */}
            <div className='dash-green-card d'>
              <h1 className='text-white'>Occupancy</h1>
              <div className='d-flex align-items-center'>
                <h1 className='text-white fnt-700'>
                  {Number?.isInteger(filteredData?.occupancyRate?.value)
                    ? filteredData?.occupancyRate?.value
                    : filteredData?.occupancyRate?.value?.toFixed(1)}
                  %
                </h1>
                <img src={allPropImg} height={40} width={40} className='ms-auto' />
              </div>
            </div>
          </div>

          {/* Revenue */}
          <div>
            {/* <div className='status-w-220 text-end'>
              <p className='ms-auto pe-2 me-1 mb-1'>
                {filteredData?.overallRevenueAmount
                  ? filteredData?.overallRevenueAmount?.percentage
                  : 0}
                %
              </p>
            </div> */}
            <div className='dash-green-card d'>
              <h1 className='text-white'>Revenue</h1>
              <div className='d-flex align-items-center'>
                <h3 className='text-white fnt-500'>{'AED '}</h3>
                <h1 className='text-white fnt-700 ps-2'>
                  {Number.isInteger(overAllGreenCardCalculations?.overallRevenueAmount?.value)
                    ? overAllGreenCardCalculations?.overallRevenueAmount?.value?.toLocaleString(
                        'en-US'
                      )
                    : overAllGreenCardCalculations?.overallRevenueAmount?.value?.toLocaleString(
                        'en-US',
                        {
                          minimumFractionDigits: 1,
                        }
                      )}
                </h1>
                <img src={allPropImg} height={40} width={40} className='ms-auto' />
              </div>
            </div>
          </div>

          {/* Forecasted Revenue */}
          <div>
            {/* <div className='status-w-220 text-end'>
              <p className='ms-auto pe-2 me-1 mb-1'>
                {filteredData?.overallForecastingAmount
                  ? filteredData?.overallForecastingAmount?.percentage
                  : 0}
                %
              </p>
            </div> */}
            <div className='dash-green-card d'>
              <h1 className='text-white'>Forecasted Revenue</h1>
              <div className='d-flex align-items-center'>
                <h3 className='text-white fnt-500'>{'AED '}</h3>
                <h1 className='text-white fnt-700 ps-2'>
                  {Number.isInteger(overAllGreenCardCalculations?.overallForecastingAmount?.value)
                    ? overAllGreenCardCalculations?.overallForecastingAmount?.value?.toLocaleString(
                        'en-US'
                      )
                    : overAllGreenCardCalculations?.overallForecastingAmount?.value?.toLocaleString(
                        'en-US',
                        {
                          minimumFractionDigits: 1,
                        }
                      )}
                </h1>
                <img src={allPropImg} height={40} width={40} className='ms-auto' />
              </div>
            </div>
          </div>
        </div>

        {/* Occupany Chart - Daily */}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1 min-h-350'>
          <div className='col-12 py-3'> */}
        {/* {filteredData && <OccupancyChart chartData={filteredData?.occupancyChartData} />} */}
        {/* {filteredData && <D3LineChart chartData={filteredData?.occupancyChartData} />} */}
        {/* {filteredData && <BarChart data={filteredData?.unitData?.residentialUnitData}/>} */}

        {/* </div>
        </div> */}

        {/* Occupancy Chart Data - Monthly */}
        <div className='row mt-10 card p-2 dashboard-card-shadow mx-1 min-h-350'>
          <div className='col-12 py-3 ps-1'>
            {occupancyChartData && <OccupancyChartMonthly chartData={occupancyChartData} />}
          </div>
        </div>

        {/* UnitTypeOccupancy ---  BedroomSizeOccupancy*/}
        <div className='row mx-1 mt-10 d-flex'>
          <div className='col-12 col-md-6 ps-0' style={{display: 'flex', flexDirection: 'column'}}>
            <div className='card dashboard-card-shadow  py-3 px-2 min-h-350'>
              {' '}
              {filteredData != undefined && (
                <UnitTypeOccupancy
                  chartData={
                    filteredData?.unitData?.residentialUnitData != undefined
                      ? filteredData?.unitData?.residentialUnitData
                      : []
                  }
                />
              )}
            </div>
          </div>

          <div className='col-12 col-md-6 pe-0' style={{display: 'flex', flexDirection: 'column'}}>
            <div className='card dashboard-card-shadow py-3 px-2 min-h-350'>
              {filteredData != undefined && (
                <BedroomSizeOccupancy
                  chartData={
                    filteredData?.unitData?.bedroomWiseData != undefined
                      ? filteredData?.unitData?.bedroomWiseData
                      : []
                  }
                />
              )}
            </div>
          </div>
        </div>

        {/* VacancyRate ---  AverageVacantDays --- AverageRent --- RentCollectionRate */}

        <div className=' mx-1 mt-10 d-flex' style={{justifyContent: 'space-between'}}>
          <div className=' ps-0' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && <VacancyRate chartData={filteredData?.vacancyRate} />}
          </div>
          <div className='' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <AverageVacantDays chartData={filteredData?.averageVacancyData} />
            )}
          </div>
          <div className='' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && <AverageRent chartData={filteredData?.averageUnit} />}
          </div>
          <div className=' p-0' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <RentCollectionRate chartData={filteredData?.rentCollectionRate} />
            )}
          </div>
        </div>

        {/* lease chart - discard */}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3'>
            <LeaseChart />
          </div>
        </div> */}

        {/*lease chart - applicable - daily*/}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3 px-0'>
            {filteredData != undefined && <NewLeaseChart chartData={filteredData?.tenancyChartData} />}
          </div>
        </div>  */}

        <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3 px-0'>
            {filteredData != undefined && selectedUnits && (
              <LeaseMonthWiseChart selectedUnits={selectedUnits} />
            )}
          </div>
        </div>

        {/* TenantChart ---  TenantTurnOverRate --- TenantRetentionRate */}
        <div className='d-flex mt-10' style={{justifyContent: 'space-between'}}>
          {/* Daily */}
          {/* {filteredData != undefined && <TenantChart chartData={filteredData?.tenantGrowthData} />} */}
          {/* {filteredData != undefined && <TenantsNewChart chartData={filteredData?.tenantGrowthData} />} */}
          {/* <div className=' ps-0 my-9' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && <TenantTurnOverRate chartData={filteredData?.averageTurnoverRate} />}
          </div>
          <div className=' ps-0 my-9' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && <TenantRetentionRate chartData={filteredData?.averageRetentionRate} />}
          </div> */}

          {tenantGrowthData && <TenantChartMonthly chartData={tenantGrowthData} />}

          <div className=' ps-0 my-9' style={{display: 'flex', flexDirection: 'column'}}>
            {tenantTurnOverRate && <TenantTurnOverRate chartData={tenantTurnOverRate} />}
          </div>
          <div className=' ps-0 my-9' style={{display: 'flex', flexDirection: 'column'}}>
            {tenantRetentionRate && <TenantRetentionRate chartData={tenantRetentionRate} />}
          </div>
        </div>

        {/* TenantComplaintChart --- Daily */}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3 px-0'>
            {filteredData != undefined && <TenantComplaintChart chartData={filteredData?.tenantComplaintData} />}
          </div>
        </div> */}

         {/* TenantComplaintChart --- Monthly */}
        <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3 px-0'>
            {tenantComplaintData && <TenantComplaintChartMonthly chartData={tenantComplaintData} />}
          </div>
        </div>

        {/* TenantSatisfactionChart ---  OverAllTenantSatisfaction - Daily*/}
        {/* <div
          className='row mt-10 d-flex align-items-center'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-8'>
            {filteredData != undefined && (
              <TenantSatisfactionChart chartData={filteredData?.tenantSatisfactionData} />
            )}
          </div>

          <div className='col-4 text-end'>
            {filteredData != undefined && (
              <OverAllTenantSatisfaction chartData={filteredData?.overallTenantSatisfaction} />
            )}
          </div>
        </div> */}

        {/* TenantSatisfactionChart ---  OverAllTenantSatisfaction - Monthly */}
        <div
          className='row mt-10 d-flex align-items-center'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-8'>
            {satisfactionChart && <SatisfactionTenantMonthly chartData={satisfactionChart} />}
          </div>

          <div className='col-4 text-end'>
            {overallTenantSatisfied != undefined && (
              <OverAllTenantSatisfaction chartData={overallTenantSatisfied} />
            )}
          </div>
        </div>

        {/* Revenue - Daily*/}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3'>
            {filteredData != undefined && <RevenueChart chartData={filteredData?.paymentRevenueData} />}
          </div>
        </div> */}

        {/* Revenue - Monthly */}
        <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3'>
            {revenueChartData && <RevenueChartMonthly chartData={revenueChartData} />}
          </div>
        </div>

        {/* Income Chart = Daily*/}
        {/* <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3'>
            {filteredData != undefined && <IncomeChart chartData={filteredData?.paymentIncomeData} />}
          </div>
        </div> */}

        {/* Income Chart = Monthly*/}
        <div className='row mt-10 card p-2 dashboard-card-shadow mx-1'>
          <div className='col-12 py-3'>
            {incomeChartData && <IncomeChartMonthly chartData={incomeChartData} />}
          </div>
        </div>

        {/* IncomeVsExpanseChart ---  IncomeExpenseBreakdownChart --- daily*/}
        {/* <div
          className='row mt-10 d-flex align-items-center'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <IncomeVsExpanseChart chartData={filteredData?.incomeExpenseChartData} />
            )}
          </div>

          <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <IncomeExpenseBreakdownChart
                chartData={filteredData?.incomeExpenseChartBreakdownData}
              />
            )}
          </div>
        </div> */}

        {/* IncomeVsExpanseChart ---  IncomeExpenseBreakdownChart --- Monthly*/}
        <div
          className='row mt-10 d-flex align-items-center'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
            {incomeVsExpenseData && (
              <IncomeVsExpanseChartMonthly chartData={incomeVsExpenseData} />
            )}
          </div>

          <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
            {incomeExpenseBreakdownData && (
              <IncomeExpenseBreakdownChartMonthly chartData={incomeExpenseBreakdownData} />
            )}
          </div>
        </div>

        {/* IncomeBreakdownChart ---  ExpenseBreakdownChart*/}
        <div
          className='row mt-10 d-flex align-items-center mx-1'
          style={{justifyContent: 'space-between'}}
        >
          <div className='col-6 ps-0' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <IncomeBreakdownChartMonthly chartData={filteredData?.incomeBreakdownData} />
            )}
          </div>

          <div className='col-6 pe-0' style={{display: 'flex', flexDirection: 'column'}}>
            {filteredData != undefined && (
              <ExpenseBreakdownChartMonthly chartData={filteredData?.expenseBreakdownData} />
            )}
          </div>
        </div>
      </div>

      {/* <Lease3 /> */}
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default Dashboard

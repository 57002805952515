import React from 'react'
import {Modal} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import Typography from '@material-ui/core/Typography'

interface ModalLoaderProps {
  isVisible: boolean
  progress: number
  totalFiles: number
  uploadingText: any
  percentageProgress: any
}

const ModalLoader: React.FC<ModalLoaderProps> = ({
  isVisible,
  progress,
  totalFiles,
  uploadingText,
  percentageProgress,
}) => {
  const progressText = `${progress}/${totalFiles}`

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={isVisible}
        onHide={() => {}}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-3 px-lg-7'
          style={{
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          {uploadingText == 'Video Compressing' ? (
            <div className='d-flex justify-content-center mt-5'>
              <div style={{position: 'relative', display: 'inline-block'}}>
                <CircularProgress
                  variant='determinate'
                  value={percentageProgress}
                  size={50}
                  thickness={3}
                  style={{color: '#007a59'}}
                />
                <Typography
                  variant='body2'
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -55%)',
                  }}
                >
                  {Math.round(percentageProgress)}%
                </Typography>
              </div>
            </div>
          ) : (
            <div className='d-flex justify-content-center mt-4'>
              <CircularProgress style={{color: '#007a59'}} />
            </div>
          )}
          <div className='d-flex justify-content-center mt-5 white-dark-theme-color'>
            <p>
              <b>
                {uploadingText} {progressText}
              </b>
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalLoader

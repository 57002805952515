import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  handleChnage: any
  providerFilterType?: any
}

function ServiceProviderFilter({show, handleClose, handleChnage, providerFilterType}: ButtonProps) {
  const Assign = new Array(2).fill(0)

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <h2>{providerFilterType === 'sort' ? 'Sort By' : 'Filter'}</h2>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        {providerFilterType === 'sort' ? (
          <>
            <div className='mb-2 min-w-200px px-5 w-auto'>
              <h5>Rating</h5>
              <select name='city' className='form-select'>
                <option value=''>Highest - Lowest</option>
              </select>
            </div>
            <div className='d-flex justify-content-center my-3'>
              <button className='btn btn-sm fw-bold btn-primary btn-green'>Apply</button>
            </div>
          </>
        ) : (
          <>
            <div className='mb-2 min-w-200px px-5 w-auto'>
              <h5>City</h5>
              <select name='city' className='form-select'>
                <option value=''>Select city</option>
              </select>
            </div>
            <div className='d-flex justify-content-center my-3'>
              <button className='btn btn-sm fw-bold btn-primary btn-green'>Apply</button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ServiceProviderFilter

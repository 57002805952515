import React from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './style.scss'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

const Communications = () => {
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>()

  const handleChangeRoleData = () => {}
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-0'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <th className='text-start min-w-25px'></th>
              <th className='text-start min-w-300px m-w-300'></th>

              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Allow{' '}
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can sent a message.
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle
                        color='#35475e '
                        fontSize={14}
                        fontWeight={600}
                        className='ms-0 cursor-pointer'
                        style={{cursor: 'pointer', marginBottom: '2px'}}
                      />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-125px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-75px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
            </thead>
            <tbody className='fw-semibold text-gray-600 role-test'>
              {/* Instant Messaging */}
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0 white-dark-theme-color fnt-700' >
                    Instant Messaging
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>Exchange real-time messages with users.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>
              {/* - Tenants*/}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3 white-dark-theme-color fnt-600'>
                    {' '}
                    - Tenants
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3 light-dark-theme-color'>
                    <i>
                      Engage and manage instant conversations with tenants through direct messages.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              {/* - Collegues*/}
              <tr>
                <td className='text-start ps-0'></td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-sub2 pe-2 mb-0 ps-5 ms-3 white-dark-theme-color fnt-600'>
                    {' '}
                    - Colleagues
                  </p>
                  <p className='user-sub-head mb-2 ps-5 ms-3 light-dark-theme-color'>
                    <i>
                      Engage and manage instant conversations with colleague users on your portal.
                    </i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              {/*Annoucements  */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can view the list of annoucements.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can access and view details of the annoucements.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can create an annoucement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can edit an annoucement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can delete an annoucement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can approve an annoucement.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0 white-dark-theme-color fnt-700'>
                    Announcements
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>Manage announcements sent to tenants.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>

              {/* SMS & WhataApp */}
              <tr
                className='user-th'
                style={{
                  borderBottom: 'none',
                  height: '35px',
                }}
              >
                <td className='text-start min-w-25px user-th'></td>
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  View List{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td
                  className='text-start min-w-125px text-capitalize user-th ps-0'
                  style={{paddingTop: '1.25rem', paddingBottom: '1.25rem'}}
                >
                  {' '}
                  View Details{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Create{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-75px text-capitalize user-th ps-0'>
                  {' '}
                  Edit{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Delete{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Approve{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          SMS & WhataApp
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle
                          color='#35475e '
                          fontSize={14}
                          fontWeight={600}
                          className='ms-2 cursor-pointer mb-1'
                          style={{cursor: 'pointer', marginBottom: '2px'}}
                        />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
              </tr>
              <tr>
                <td className='text-start ps-0'>
                  <img src={redCross} height={16} width={16} className='me-2' />
                </td>
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-0 white-dark-theme-color fnt-700'>
                    SMS & WhatsApp
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>----</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={false}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={true}
                      onChange={(e: any) => {}}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Communications

import React from 'react';
import {MultiSelect,Option} from 'react-multi-select-component'


interface MultiSelectSearchI{
    label:string
    statusOptions:  Option[]
    statusDropdown:Option[]
    setStatusDropdown: React.Dispatch<React.SetStateAction<Option[]>>
    CustomValueRenderer: (selected: Option[], options: Option[]) => React.ReactNode;
}


const MultiSelectSearch = ({ statusOptions, statusDropdown,label, setStatusDropdown,CustomValueRenderer }: MultiSelectSearchI) => {

  return (
        <div className={`multi-select-container jobs-property ${
          statusDropdown.length === 0 ? `no-value latePayment${label}` : 'has-value'
        }`}>
          <MultiSelect
            options={statusOptions}
            value={statusDropdown}
            onChange={setStatusDropdown}
            labelledBy={label}
            overrideStrings={{
              allItemsAreSelected: `Select ${label}`,
            }}
            valueRenderer={CustomValueRenderer}
          />
        </div>
  );
};

export default MultiSelectSearch;
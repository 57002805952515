import React from 'react'
import {IFIlterProps, ICountProps} from '../DashboardWrapper'
import {useLocation} from 'react-router-dom'

interface IProps {
  onApply: () => void
  onReset: () => void
  onManageProperties: () => void
  handleRoutes: (routes: string) => void
  handleChange: (name: string, value: any) => void
  state: IFIlterProps
}

const FilterSection = ({
  onApply,
  onReset,
  onManageProperties,
  handleChange,
  handleRoutes,
  state,
}: IProps) => {
  const {pathname} = useLocation()

  const isROutes = (routes: string) => {
    if (pathname === routes) {
      return true
    } else {
      return false
    }
  }
  return (
    <>
      <div className='d-flex align-items-center gap-5 justify-content-center mb-5'>
        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
          <li className='nav-item' onClick={() => handleRoutes('/dashboard')}>
            <a
              className={`nav-link cursor-pointer text-active-primary pb-4 ${
                isROutes('/dashboard') ? 'active' : ''
              }`}
            >
              Overview
            </a>
          </li>
          <li className='nav-item' onClick={() => handleRoutes('/building-dashboard')}>
            <a
              className={`nav-link cursor-pointer text-active-primary pb-4 ${
                isROutes('/building-dashboard') ? 'active' : ''
              }`}
            >
              Buildings
            </a>
          </li>
          <li className='nav-item' onClick={() => handleRoutes('/community-dashboard')}>
            <a
              className={`nav-link cursor-pointer text-active-primary pb-4 ${
                isROutes('/community-dashboard') ? 'active' : ''
              }`}
            >
              Communities
            </a>
          </li>
        </ul>
      </div>
      <div className='card p-5'>
        <div className='d-flex align-items-center ' style={{justifyContent: 'space-between'}}>
          <div
            className='d-flex align-items-center gap-5'
            style={{justifyContent: 'space-between'}}
          >
            {(isROutes('/building-dashboard') || isROutes('/dashboard')) && (
              <h6 className='text-muted m-0'>
                Buildings
                <div className='mx-1 value_black'>{state.count?.building_count}</div>
              </h6>
            )}
            {(isROutes('/community-dashboard') || isROutes('/dashboard')) && (
              <h6 className='text-muted m-0'>
                Communities
                <div className='mx-1 value_black'>{state.count?.community_count}</div>
              </h6>
            )}
            <h6 className='text-muted m-0'>
              Units
              <div className='mx-1 value_black'>{state.count?.unit_count}</div>
            </h6>
          </div>

          <div className='d-flex gap-3 align-items-center'>
            <div className='m-0 me-5'>
              <a
                href='#'
                className='btn btn-sm fw-bold btn-primary btn-green filters_hover '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <span className=' m-0 p-0'>
                  <svg
                    width='16'
                    height='14'
                    viewBox='0 0 24 24'
                    fill='#fff'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                      fill='currentColor'
                    ></path>
                  </svg>{' '}
                  Filter
                </span>
              </a>
              <div
                className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                data-kt-menu='true'
                id='kt_menu_63479aee8def1'
              >
                <div className='px-7 py-5'>
                  <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                </div>
                <div className='separator border-gray-200'></div>

                <>
                  <div className='mb-5 px-5 mt-5'>
                    {/* <label className='form-label fw-semibold'>Property Rating:</label> */}
                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        onChange={(e) =>
                          handleChange('bedrooms', e.target.value && parseInt(e.target.value))
                        }
                        value={state.bedrooms}
                      >
                        <option value='' selected>
                          Bedrooms
                        </option>
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </select>
                    </div>
                  </div>
                  <div className='mb-5 px-5'>
                    {/* <label className='form-label fw-semibold'>Property Rating:</label> */}
                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        onChange={(e) =>
                          handleChange('occupy', e.target.value && parseInt(e.target.value))
                        }
                        value={state.occupy}
                      >
                        <option disabled selected>
                          Occupancy
                        </option>
                        <option value={1}>Occupied</option>
                        <option value={0}>Vaccant</option>
                      </select>
                    </div>
                  </div>
                  <div className='mb-5 px-5'>
                    {/* <label className='form-label fw-semibold'>Property Rating:</label> */}
                    <div>
                      <select
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        onChange={(e) =>
                          handleChange('rating', e.target.value && parseInt(e.target.value))
                        }
                        value={state.rating}
                      >
                        <option value='' selected>
                          Rating
                        </option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </div>
                  </div>
                </>

                {(isROutes('/community-dashboard') || isROutes('/building-dashboard')) && (
                  <>
                    <div className='mb-5 px-5'>
                      {/* <label className='form-label fw-semibold'>Property Rating:</label> */}
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-dropdown-parent='#kt_menu_631f08e971923'
                          data-allow-clear='true'
                          value={state.buildingManager}
                          onChange={(e) => handleChange('buildingManager', e.target.value)}
                        >
                          <option value='' selected>
                            {isROutes('/community-dashboard')
                              ? 'Community Manager'
                              : 'Building Manager'}
                          </option>
                          <option value='1'>John</option>
                          <option value='2'>Max</option>
                          <option value='2'>Root</option>
                        </select>
                      </div>
                    </div>
                    <div className='mb-5 px-5'>
                      {/* <label className='form-label fw-semibold'>Property Rating:</label> */}
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-dropdown-parent='#kt_menu_631f08e971923'
                          data-allow-clear='true'
                          onChange={(e) => handleChange('propertyManager', e.target.value)}
                          value={state.propertyManager}
                        >
                          <option value='' selected>
                            Property Manager
                          </option>
                          <option value='1'>John</option>
                          <option value='2'>Max</option>
                          <option value='2'>Root</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}

                <div className='d-flex justify-content-end px-5 mb-5'>
                  <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-primary me-2'
                    data-kt-menu-dismiss='true'
                    onClick={onReset}
                  >
                    Reset
                  </button>
                  <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    data-kt-menu-dismiss='true'
                    style={{backgroundColor: '#007a59'}}
                    onClick={onApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <a
              onClick={onManageProperties}
              className='btn btn-sm fw-bold btn-primary btn-green'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_create_app'
            >
              Manage Properties
            </a>
          </div>
        </div>
        <div
          className='d-flex gap-5 align-items-center py-5'
          style={{justifyContent: 'space-between'}}
        >
          <div className='m-0 p-0 min-w-200px'>
            {/* <label className='form-label fw-semibold'>Development</label> */}
            <div>
              <select
                className='form-select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-dropdown-parent='#kt_menu_631f08e971923'
                data-allow-clear='true'
                name='developmentType'
                onChange={(e) => handleChange('developmentType', e.target.value)}
                value={state.developmentType}
              >
                <option value='' selected>
                  Development
                </option>
                {isROutes('/building-dashboard') ? (
                  state.devList?.map((v: any) => {
                    return <option value={v?._id}>{v?.name}</option>
                  })
                ) : isROutes('/community-dashboard') ? (
                  state.devList?.map((v: any) => {
                    return <option value={v?._id}>{v?.name}</option>
                  })
                ) : (
                  <>
                    <option value={2}>Building</option>
                    <option value={1}>Community</option>
                  </>
                )}
              </select>
            </div>
          </div>
          <div className='m-0 p-0 min-w-200px'>
            <div>
              <input
                type='text'
                className='form-control'
                placeholder='Unit ID'
                name='unitId'
                onChange={(e) => handleChange('unitId', e.target.value)}
                value={state.unitId}
              />
            </div>
          </div>
          <div className='m-0 p-0 min-w-200px'>
            {/* <label className='form-label fw-semibold'> Unit Type</label> */}
            <div>
              <select
                className='form-select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-dropdown-parent='#kt_menu_631f08e971923'
                data-allow-clear='true'
                name='unitType'
                onChange={(e) => handleChange('unitType', e.target.value)}
                value={state.unitType}
              >
                <option value={0} selected>
                  Unit Type
                </option>
                <option value={"apartment"}>Apartment</option>
                <option value={"penthouse"}>Penthouse</option>
                <option value={"town_house"}>Townhouse</option>
                <option value={"villa"}>Villa</option>
                <option value={"common_area"}>Common Area</option>
                <option value={"other"}>Other</option>
              </select>
            </div>
          </div>
          <div className='m-0 p-0 min-w-200px'>
            {/* <label className='form-label fw-semibold'>Unit No.</label> */}
            <div>
              <input
                type='text'
                className='form-control'
                placeholder='Unit No.'
                name='unitNo'
                onChange={(e) => handleChange('unitNo', e.target.value)}
                value={state.unitNo}
              />
            </div>
          </div>
          {/* <div className='mb-2 min-w-200px col-md-3 px-10'>
              <select
                className='form-select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-dropdown-parent='#kt_menu_631f08e971923'
                data-allow-clear='true'
                onChange={(e) => setBedrooms(e.target.value)}
              >
                <option value='' selected>
                  Bedrooms
                </option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'> 3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
              </select>
            </div>
            <div className='mb-2 min-w-200px col-md-3 px-10'>
              <div>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setOccupy(e.target.value)}
                >
                  <option value='' selected>
                    Occupancy
                  </option>
                  <option value='1'>Occupied</option>
                  <option value='0'>Vaccant</option>
                </select>
              </div>
            </div>
            <div className='mb-2 min-w-200px col-md-3 px-10'>
              <div>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value='' selected>
                    Rating
                  </option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                </select>
              </div>
            </div> */}
        </div>
      </div>
    </>
  )
}

export default FilterSection

import React, {useEffect, useState, useMemo} from 'react'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {FaTimesCircle} from 'react-icons/fa' // Import the clear icon
// import './style.scss'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'

interface DateStatus {
  date: moment.Moment
  status: string
}

interface DateRangePickerProps {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  onDatesChange: ({startDate, endDate}: {startDate: any; endDate: any}) => void
  autoSave: () => void
  from: any
  dateStatuses: DateStatus[]
  disabled:boolean
  isLinked?: boolean; // New prop to indicate if the DateRangePicker is linked
  linkedValue?: moment.Moment; // New prop for the linked value
  onUpdateLinkedValue?: (value: moment.Moment) => void; // Callback to update linked value
}

const CustomDateRangePicker : React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onDatesChange,
  dateStatuses,
  autoSave,
  from,
  disabled,
  isLinked = false,
  linkedValue,
  onUpdateLinkedValue,
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<any>(null)
  const {flg, unitId, id} = useParams()
  const [key, setKey] = useState(0)
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  useEffect(() => {
    if (isLinked && linkedValue) {
      setCurrentMonth(linkedValue.clone());
      setSelectedMonth(linkedValue.month());
      setSelectedYear(linkedValue.year());
    }
  }, [isLinked, linkedValue]);

  const getStatusForDate = (day: moment.Moment): string | null => {
    const backendDateFormatted = day.format('YYYY-MM-DD');
    const foundDate = dateStatuses?.find((date) => date.date.format('YYYY-MM-DD') === backendDateFormatted);

    return foundDate ? foundDate.status : 'default';
  };
  // 
  const handleDateChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    onDatesChange({ startDate, endDate });

    if (startDate !== null && endDate !== null) {
      autoSave();
    }
  };

  // 
  const isOutsideRange = (day: moment.Moment) => {
    if (from === 'renew' && day.isBefore(moment(), 'day')) {
      return true;
    }

    const status = getStatusForDate(day);
    const isInRange = startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]');

    if (!isInRange) {
      const isDisabled =
        status === 'booked' || status === 'active' || status === 'expiring' || status === 'draft';

      return isDisabled;
    }
  };
  // 
  const clearDates = () => {
    setFocusedInput(null)
    onDatesChange({startDate: null, endDate: null})
    autoSave()
  }

  // 
  const returnYears = (selectedYear: number) => {
    const years = [];
    for (let i = selectedYear - 5; i <= selectedYear + 5; i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const renderMonthElement: React.FC<any> = ({ month, onMonthSelect, onYearSelect }) => {
    const selectedMonth = month.month();
    const selectedYear = month.year();
  
    return (
      <div style={{ display: "flex", justifyContent: "center" }} className="month-year-selector">
        <div style={{ fontSize: '14px' }} className="mb-1 selectpicker">
          <select
            className="custom-select"
            value={selectedMonth}
            onChange={(e) => {
              onMonthSelect(month, parseInt(e.target.value));
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div style={{ fontSize: '14px' }} className="mb-1">
          <select
            className="custom-select"
            value={selectedYear}
            onChange={(e) => {
              onYearSelect(month, parseInt(e.target.value));
            }}
          >
            {returnYears(selectedYear)}
          </select>
        </div>
      </div>
    );
  };
  

  const dayRender = (day: moment.Moment): React.ReactNode => {
    const isPastDate = day.isBefore(moment(), 'day');

    const status = getStatusForDate(day);
    const isInRange = startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]');

    let backgroundColor;
    let color;

    if (!isPastDate && !isInRange) {
      switch (status) {
        case 'booked':
          backgroundColor = '#0099cc';
          color = 'white';
          break;
        case 'active':
          backgroundColor = '#00b8a9';
          color = 'white';
          break;
        case 'expiring':
          backgroundColor = '#f38513 ';
          color = 'white';
          break;
        case 'expired':
          backgroundColor = '#e46464 ';
          color = 'white';
          break;
        case 'draft':
          backgroundColor = '#b0b7c2 ';
          color = 'black';
          break;
        default:
          backgroundColor = 'inherit';
          break;
      }
    }

    return (
      <div style={{ backgroundColor, color }} className="day-render">
        {day.format('D')}
      </div>
    );
  };

  const handlePrevYear = () => {
    const newMonth = currentMonth.clone().subtract(1, 'year');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handleNextYear = () => {
    const newMonth = currentMonth.clone().add(1, 'year');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handlePrevMonth = () => {
    const newMonth = currentMonth.clone().subtract(1, 'month');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handleNextMonth = () => {
    const newMonth = currentMonth.clone().add(1, 'month');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  return (
    <div className='custom-date-range-picker'>
       <DateRangePicker
        key={key}
        numberOfMonths={2}
        renderDayContents={(day: any) => dayRender(day)}
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => {
          setFocusedInput(focusedInput);
        }}
        displayFormat="DD/MM/YYYY"
        isOutsideRange={isOutsideRange}
        customInputIcon={startDate && endDate && <FaTimesCircle onClick={clearDates} />}
        className="form-control form-control-solid mytest"
        readOnly
        disabled={disabled}
        // initialVisibleMonth={() => currentMonth}
        // renderMonthElement={renderMonthElement}
        renderMonthElement={(props:any) => renderMonthElement({ ...props, selectedMonth, selectedYear })}
      />
      {/* <div style={focusedInput == null ? {display: 'none'} : {marginTop: '10px', display: 'flex'}}>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#b0b7c2 '}}></div>
          <span style={{marginLeft: '5px'}}>Draft</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#0099cc'}}></div>
          <span style={{marginLeft: '5px'}}>Booked</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#00b8a9'}}></div>
          <span style={{marginLeft: '5px'}}>Active</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#f38513'}}></div>
          <span style={{marginLeft: '5px'}}>Expiring</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#e46464'}}></div>
          <span style={{marginLeft: '5px'}}>Expired</span>
        </div>
      </div> */}
    </div>
  )
}

export default CustomDateRangePicker

import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiGet, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

const TenatsFilter = ({
  show,
  handleClose,
  showModal,
  // type,
  date,
  onData,
  onData1,
  data,
  callhandle,
  building,
  updateDataId,
  propertiType,
  isEdit,
  setAdd,
  formData,
  imageChange,
  handleChnage,
  handleSubmit,
}: any) => {
  const [type, setType] = useState('')
  const [communityData, setcommunityData] = useState([])
  const [buildingData, setbuildingData] = useState([])
  const [clusterData, setclusterData] = useState([])
  const [typecluster, settypecluster] = useState()
  const [unitgroupData, setunitgroupData] = useState([])
  const [clusterbuildingData, setclusterbuildingData] = useState([])
  const [floorData, setfloorData] = useState([])
  const [count, setcount] = useState(0)

  const [formData1, setFormData1] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
  })
  const callcommunity = async () => {
    await ApiGet(`corporate/communities`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setcommunityData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const callbuilding = async () => {
    await ApiGet(`corporate/building`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setbuildingData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const callclusterbycommunity = async (id: any) => {
    await ApiGet(`corporate/cluster?communityId=` + id)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.log(res?.data?.data)
        setclusterData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const callunitgroupbyclusterid = async () => {
    await ApiGet(`corporate/unit_group?clusterId=` + formData1?.Cluster)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.log(res?.data?.data)
        setunitgroupData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const callbuildingbyclusterid1 = async (value: any) => {
    console.log(value, 'dfssdfsdf')
    await ApiGet(`corporate/building?clusterId=` + value)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.log(res?.data?.data)
        setclusterbuildingData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const callbuildingbyclusterid = async () => {
    console.log(formData1?.Cluster, 'aaaaaa')

    await ApiGet(`corporate/building?clusterId=` + formData1?.Cluster)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.log(res?.data?.data)
        setclusterbuildingData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const callFloorbybildingid = async (value: any) => {
    await ApiGet(`corporate/floor?buildingId=` + value)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.log(res?.data?.data)
        setfloorData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handleSubmit1 = async () => {
    console.log(data)
    let body = {
      id: data?._id,
      status: 2,
      scheduleDate: formData1?.startdate,
    }
    ApiPut(`corporate/tenant_request/status`, body)
      .then((res) => {
        callhandle()
        // getTenancyPayment()
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err) => ErrorToast(err.message))
  }
  const handleChnage1 = (e: any) => {
    const {name, value} = e.target

    if (name == 'MixCluster') {
      setFormData1({
        ...formData1,
        floor: null,
        BuildingCluster: null,
        UnitGroup: null,
        [name]: value,
      })
    } else if (name == 'Cluster') {
      setFormData1({
        ...formData1,
        MixCluster: null,
        floor: null,
        BuildingCluster: null,
        UnitGroup: null,
        [name]: value,
      })
    } else if (name == 'Community') {
      setFormData1({
        ...formData1,
        Cluster: null,
        MixCluster: null,
        floor: null,
        BuildingCluster: null,
        UnitGroup: null,
        [name]: value,
      })
    } else if (name == 'DevelopmentType') {
      setFormData1({
        ...formData1,
        Community: null,
        Cluster: null,
        MixCluster: null,
        floor: null,
        BuildingCluster: null,
        UnitGroup: null,
        [name]: value,
      })
    } else {
      setFormData1({...formData1, [name]: value})
    }
    if (name === 'DevelopmentType') {
      setType(e.target.value)
      if (e.target.value == 'Community') {
        callcommunity()
      } else {
        callbuilding()
      }
    }
    if (name == 'Community') {
      callclusterbycommunity(value)
    }
    if (name == 'Cluster') {
      let dte: any = clusterData.filter((v: any, index: any) => v._id == value)
      console.log(dte)
      settypecluster(dte[0]?.type)
      // if(dte[0]?.type==0){
      //   callunitgroupbyclusterid(value)

      // }
      if (dte[0]?.type == 1) {
        callbuildingbyclusterid1(value)
      }
    }
    if (name == 'MixCluster') {
      // setFormData1({ ...formData1, floor: "", BuildingCluster: "", UnitGroup: "" })
      if (e.target.value == 'UnitGroup') {
        callunitgroupbyclusterid()
      } else {
        callbuildingbyclusterid()
      }
    }
    if (name == 'BuildingCluster') {
      callFloorbybildingid(value)
    }
    setcount(count + 1)
  }
  // const handleChnage2 = (e: any) => {
  //   const { name, value } = e.target
  //   setFormData1({ ...formData1, [name]: value })
  //   setType(e.target.value)
  // }
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={() => {
          handleClose()
          setType('')
        }}
        backdrop={true}
        size='sm'
      >
        <div className='modal-header'>
          <h2>{'Select Date & Time'}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div
              className='card-header align-items-center gap-md-2'
              style={{justifyContent: 'flex-start'}}
            >
              <div className='mb-10 min-w-200px '>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Select Date and Time
                </label>
                <input
                  type='datetime-local'
                  className='form-control form-control-solid'
                  placeholder='e.g. - abc'
                  name='startdate'
                  value={formData1?.startdate}
                  onChange={handleChnage1}
                />
              </div>
            </div>

            {/*end::Content */}
          </div>
          {/* end::Stepper */}
        </div>
        {/* reschedule modal button set */}
        <div className='modal-footer'>
          <div className='flex-row-fluid '>
            <div className='d-flex justify-content-end '>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                  onClick={() => {
                    handleClose()
                    setType('')
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className=' me-1  hover-none'
                  />
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='btn btn-lg btn-primary btn-green'
                  data-kt-stepper-action='submit'
                  onClick={handleSubmit1}
                >
                  Apply
                  <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className=' ms-2 me-0' />
                </button>
              </div>
            </div>
            {/*end::Form */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default TenatsFilter

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiGet, ApiPost, ApiUpload, ApiPut, ApiDelete, ApiGetNoAuth} from '../../../../../apiCommon/helpers/API/ApiData'
import img from '../../../../../img/300-1.jpg'
import folderDocument from '../../../../../img/folder-document.svg'
import pdfDocument from '../../../../../img/pdf.svg'
import docCocument from '../../../../../img/doc.svg'
import {Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
// import NewMessage from '../../TenantNewMessage'
import io from 'socket.io-client'
import {ErrorToast, SuccessToast} from '../../../../../apiCommon/helpers/Toast'
import backArrow from '../../../../../img/back-arrow.png'
import {Modal} from 'react-bootstrap'
import send from '../../../../../img/sendwhite.svg'
import closered from '../../../../../img/closered.svg'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import {AiFillDelete} from 'react-icons/ai'
import {truncate} from 'fs'
import lock from '../../../../../img/padlock.png'
import ReactPaginate from 'react-paginate'
import noData from '../../../../../img/NoData1.svg'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import {setSelectedUnit} from '../../../../../redux/counterSlice'
import openImg from '../../../../../img/payment-col-open.png'
import close from '../../../../../img/payment-col-close.png'
// import EditPaymentModal from '../../../Financials/EditPaymentModal'
import pencil from '../../../../../img/pen.png'
import eye from '../../../../../img/eye-blue.png'
import trash from '../../../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../../../img/check-white.png'
import download from '../../../../../img/download-white.png'
import bin from '../../../../../img/bin.png'
import {useDispatch, useSelector} from 'react-redux'
import {setComefrom, setUnitIDfromProperties} from '../../../../../redux/counterSlice'
import plusBlue from '../../../../../img/add-blue.png'
import trashImg from '../../../../../img/trash.png'
import correct from '../../../../../img/correct.png'
import upload from '../../../../../img/upload-blue.png'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import redCross from '../../../../../img/remove.png'
import Select from 'react-select'
import editpen from '../../../../../img/pen.png'
import deleteImg from '../../../../../img/delete.png'
import downloadImg from '../../../../../img/download-img.png'
import axios from 'axios'
import addWhite from '../../../../../img/add-white.png'
// import IncomingPaymentModal from './IncomingPaymentModal'
import tenantUserFilledIconBlue from '../../../../../img/tenant-user-filled-blue.png'
import folderIcon from '../../../../../img/folder.png'
import folderGreenIcon from '../../../../../img/folder-green.png'
import removeFilled from '../../../../../img/remove-filled.png'
import downloadIcon from '../../../../../img/download.png'
import greenPen from '../../../../../img/pen.png'
import pngIcon from '../../../../../img/png.png'
import jpgIcon from '../../../../../img/jpg-file.png'
import jpegIcon from '../../../../../img/jpeg.png'
import pdfIcon from '../../../../../img/pdf.svg'
import correctFilled from '../../../../../img/correct.png'
import fileIcon from '../../../../../img/invoice-white.png'
import EditTenantModel from './EditTenantModel'
import AddInviteModel from './AddInviteModel'
import RequestProfileUpdateModel from './RequestProfileUpdateModel'
import chatGreen from '../../../../../img/chat-green.png'
import chatWhite from '../../../../../img/chat.png'
import {onMessageListener} from '../../../../../apiCommon/helpers/firebase/firebase'
import closes from '../../../../../img/close.svg'
import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import leftarrow from '../../../../../img/arrowleft.svg'
import plus from '../../../../../img/plus.svg'
import filter from '../../../../../img/filter.svg'
import profileImgStatic from '../../../../../img/user.jpeg'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import ModalLoader from '../../../General/ModalLoader'
import { capitalizeFirstLetter } from '../../../../../Utilities/utils'

const socket = io('https://api.propertise.com/room')

// const SERVER_URL: any = process.env.REACT_APP_SERVER_URL
// const socket = io(SERVER_URL);
const ViewTenantNew = () => {
  const [isFormDirty, setIsFormDirty] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [idTenancy, setIdTenancy] = useState<any>(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])
  const [paymentData, setPaymentData] = React.useState<any>({})
  const [propertiType, setPropertiType] = React.useState('')
  const [messageData, setMessageData] = React.useState<any>([])
  const [messagechat, setmessagechat] = React.useState<any>([])
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
  const [messageList, setMessageList] = React.useState<any>(false)
  const [tenantId, setTenantId] = React.useState<any>('')
  const [sendMessage, setSendMessage] = React.useState<any>('')
  const [roomId, setRoomId] = React.useState<any>()
  const [open, setOpen] = React.useState(false)

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)
  const [country, setCountry] = useState<any>([])
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
    {value: '2', label: 'Other'},
  ])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setTenantData(res?.data?.data)
        console.log('\nres?.data?.data', res?.data?.data)
        setValue('email', res?.data?.data?.email)
        setValue('phoneNumber', `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`)
        setProfileImg(res?.data?.data?.profileImage)
        //  setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getPaymentData = async () => {
    await ApiGet(`corporate/tenant/payment_and_rent/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        setPaymentData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getmessageDataById = async (id: any) => {
    const body = {
      limit: 10,
      page: 1,
      chatRoomId: id,
    }
    await ApiPost(`corporate/chat_message/get`, body)
      .then((res) => {
        setmessagechat(res?.data?.data?.tenancy_payment_data)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message) {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getMessageList = async () => {
    let values: any = []
    values[0] = window.location.pathname.split('/')[2]

    const body = {
      limit: 10,
      page: 1,
      tenantIds: values,
    }

    await ApiPost(`corporate/chat_room/get`, body)
      .then((res) => {
        setMessageData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getTenantData()
    // getPaymentData()
    getMessageList()
  }, [window.location.pathname.split('/')[2]])

  const [isConnected, setIsConnected] = React.useState<any>(/* socket.connected */)
  const [lastPong, setLastPong] = React.useState<any>(null)

  // React.useEffect(() => {
  //   if(window.location.pathname.split('/')[1] === 'tenant'){
  //     socket.on('connect', () => {
  //       setIsConnected(true);
  //     });

  //     socket.on('disconnect', () => {
  //       setIsConnected(false);
  //     });

  //     socket.on('pong', () => {
  //       setLastPong(new Date().toISOString());
  //     });

  //     socket.on('online', (data) => {
  //       console.log("DAta:", data)
  //     })

  //     return () => {
  //       socket.off('connect');
  //       socket.off('disconnect');
  //       socket.off('pong');
  //     };
  //   }

  // },[window.location.pathname.split('/')[1]])

  // React.useEffect(() => {
  //   socket.emit('online', {
  //     "userId": "634697421bd4e8bf314c3a74"
  //   })
  // }, [])

  // const handleViewMessage = (id: any, tenantId: any) => {
  //   setMessageList(!messageList)
  //   setRoomId(id)
  //   setTenantId(tenantId)
  //   socket.emit(`join_room`, {
  //     roomId: id,
  //     userId: "634697421bd4e8bf314c3a74",
  //   });
  //   getmessageDataById(id)
  // }

  // const handleSendMessage = () => {
  //   if (sendMessage !== '') {
  //     socket.emit('send_message', {
  //       roomId: roomId,
  //       message: sendMessage,
  //       senderId: "634697421bd4e8bf314c3a74",
  //       receiverId: tenantId,
  //       isCooperate: true
  //     });
  //   }
  //   setSendMessage('');
  // }

  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)
  // console.log('folderfile',folderFiles)
  // console.log('folders',folders)
  const getTenantFolders = () => {
    ApiGet(`corporate/tenant_folder?tenantId=${window.location.pathname.split('/')[2]}`)
      .then((res: any) => {
        setFolders(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setSelectedFolder(folder)
        setViewFolder(true)
        setFolderFiles(res?.data?.data?.fileList)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }
        console.log(y)
        console.log(res?.data?.data?.fileList?.length)
        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
        setEditFolderName(-1)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  //
  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const getUniqueFilename = (filename: any, folderFiles: any) => {
    const fileExtension = filename.split('.').pop()
    const fileNameWithoutExtension = filename.replace(`.${fileExtension}`, '')
    let newFilename = filename
    let counter = 1

    while (folderFiles.some((file: any) => file.fileName === newFilename)) {
      newFilename = `${fileNameWithoutExtension}(${counter}).${fileExtension}`
      counter++
    }

    return newFilename
  }

  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      setShowLoader(true)
      setTotalFiles(event.target.files.length)

      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name?.toLowerCase() === folderFiles[i]?.fileName?.toLowerCase()) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              // setOpen(true)

              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  temp[temp?.length] = {
                    fileName: capitalizeFirstLetter(event?.target?.files[m]?.name),
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }
                  setUploadProgress(m + 1)

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      getTenantFolders()
                      // setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  // console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
            } else {
              // const userChoice = await confirmAction();
              // console.log(userChoice);
              // if (userChoice == 'keep') {
              // setOpen(true)

              let formData: any = new FormData()
              let data: any = replaceFile

              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = replaceFile.type
              let filename: any = capitalizeFirstLetter(replaceFile.name)

              // Generate unique filename
              filename = getUniqueFilename(filename, folderFiles)

              data.fileURL = fileURL
              formData.append('image', data)
              console.log('\ntype', filetypes)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  var name = Date.now()
                  temp[temp?.length] = {
                    fileName: `${filename}`,
                    fileURL: res?.data?.data?.image,
                    fileSize: replaceFile.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }
                  setUploadProgress(m + 1)

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      getTenantFolders()
                      // setOpen(false);
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                  setShowLoader(false)
                  setUploadProgress(0) // Reset upload progress
                  setTotalFiles(0)
                })
              // }
              // else {
              //   setOpen(true);

              //   let formData: any = new FormData();
              //   let data: any = replaceFile;
              //   console.log(replaceFile);
              //   let fileURL: any = URL.createObjectURL(data);
              //   let filetypes: any = replaceFile.type;
              //   let filename: any = replaceFile.name;

              //   data.fileURL = fileURL;
              //   formData.append('image', data);
              //   console.log('\ntype', filetypes);

              //   let url = 'upload/image/tenant_folder';

              //   await ApiUpload(`${url}`, formData)
              //     .then((res) => {
              //       for (let j = 0; j < folderFiles?.length; j++) {
              //         if (filename == folderFiles[j]?.fileName) {
              //           temp[j] = {
              //             fileName: filename,
              //             fileURL: res?.data?.data?.image,
              //             fileSize: replaceFile.size,
              //             createdAt: moment(new Date()).format('YYYY-MM-DD'),
              //           };
              //         }
              //       }

              //       const body = {
              //         id: selectedFolder?._id,
              //         name: selectedFolder?.name,
              //         fileList: temp,
              //       };
              //       ApiPut(`corporate/tenant_folder`, body)
              //         .then((res: any) => {
              //           getFolderFiles(selectedFolder);
              //           // setOpen(false);
              //         })
              //         .catch((err: any) => {});
              //     })
              //     .catch((err) => {
              //       console.log('res_blob', err);
              //       ErrorToast(err?.message);
              //     });
              // }
            }
            // setOpen(false)
          }
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)
      event.target.value = null
    }

    // delete file
    if (val == 1) {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) temp.splice(i, 1)
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
          getTenantFolders()
          setOpen(false)
        })
        .catch((err: any) => {})
    }
  }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const body = {
        name: folderName,
        tenantId: `${window.location.pathname.split('/')[2]}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          getTenantFolders()
          setFolderNameModal(false)
        })
        .catch((err: any) => {})
    } else {
    }
  }

  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: selectedFolder?.name,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        getFolderFiles(selectedFolder)
        setEditFile(-1)
      })
      .catch((err: any) => {})
  }

  const updateFolderName = () => {
    const body = {
      id: selectedFolder?._id,
      name: newFolderName,
      fileList: selectedFolder?.fileList,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        setEditFolderName(-1)
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  const deleteFolder = (id: any) => {
    console.log(id)
    ApiDelete(`corporate/tenant_folder/${id}`)
      .then((res: any) => {
        SuccessToast('Folder has been deleted successfully!')
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  // Replace OR Keep File
  const replaceKeepFile = async (val: any) => {
    const files = replaceFile
    fileLists_single = Array.from(files)

    setOpen(true)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      console.log(fileLists_single[i])
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      let filename: any = fileLists_single[i].name
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = 'upload/image/tenant_folder'

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          let temp = [...folderFiles]
          if (val == 'replace') {
            for (let j = 0; j < folderFiles?.length; j++) {
              if (filename == folderFiles[j]?.fileName) {
                temp[j] = {
                  fileName: filename,
                  fileURL: res?.data?.data?.image,
                  fileSize: fileLists_single[i].size,
                  createdAt: moment(new Date()).format('YYYY-MM-DD'),
                }
              }
            }
          } else {
            temp[folderFiles?.length] = {
              fileName: `${getFilename(filename, false)}-copy.${getFilename(filename, true)}`,
              fileURL: res?.data?.data?.image,
              fileSize: fileLists_single[i].size,
              createdAt: moment(new Date()).format('YYYY-MM-DD'),
            }
          }

          const body = {
            id: selectedFolder?._id,
            name: selectedFolder?.name,
            fileList: temp,
          }
          ApiPut(`corporate/tenant_folder`, body)
            .then((res: any) => {
              getFolderFiles(selectedFolder)
              setOpen(false)
            })
            .catch((err: any) => {})
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  /*********************** Requests ************************/
  const [page, setPage] = useState<any>(1)
  const [requests, setRequests] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)

    setTimeout(() => {
      getRequests()
    }, 1000)
  }

  const getRequests = () => {
    const body = {
      page: page,
      limit: 25,
      unitId: tenantSelectedUnit?._id,
      tenancyId: selectedTenancy?._id,
    }

    ApiPost(`corporate/tenant_request/property_file/get`, body)
      .then((res) => {
        setRequests(res?.data?.data?.tenant_request_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  /************************ Payments  ********************/
  const [page1, setPage1] = useState<any>(1)
  const [payments, setPayments] = useState<any>()
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [show, setShow] = useState<any>([])
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [profileImg, setProfileImg] = useState<any>(null)

  const handlePageClick1 = (event: any) => {
    setPage(event.selected + 1)

    setTimeout(() => {
      getPayments()
      getPaymentSummary()
    }, 1000)
  }

  const getPayments = () => {
    const body = {
      limit: 25,
      page: page1,
      unitId: tenantSelectedUnit?._id ? tenantSelectedUnit?._id : undefined,
      tenancyId: selectedTenancy?._id ? selectedTenancy?._id : undefined,
    }

    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit1(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then((res) => {
        setDataToEdit(res?.data?.data)
        setEditIncomeModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  const getPaymentSummary = () => {
    if (selectedTenancy?._id != undefined) {
      ApiGet(`corporate/payment/overall?tenancyId=${selectedTenancy?._id}`)
        .then((res) => {
          setPaymentData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    }
  }

  /************************Unit Dropdown ********************/
  const [tenantUnits, setTenantUnits] = useState<any>()
  const [tenantSelectedUnit, setTenantSelectedUnit] = useState<any>()
  const [tenancies, setTenancies] = useState<any>()
  const [selectedTenancy, setSelectedTenancy] = useState<any>()
  const [editTenantModal, setEditTenantModel] = useState<any>(false)
  const [addInviteModal, setAddInviteModal] = useState<any>(false)
  const [requestModal, setRequestModal] = useState<any>(false)

  const getTenantUnits = () => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res) => {
        setTenantUnits(res?.data?.data)
        setTenantSelectedUnit(res?.data?.data?.[0])
        if (res?.data?.data?.[0]) getTenantTenancies(res?.data?.data?.[0])
      })
      .catch((err) => console.log('err', err))
  }

  const getTenantTenancies = (unit: any) => {
    const body = {
      tenantId: `${window.location.pathname.split('/')[2]}`,
      unitId: unit?._id,
    }

    ApiPost(`corporate/tenancy/get/by_tenant`, body)
      .then((res) => {
        setTenancies(res?.data?.data)
        setSelectedTenancy(res?.data?.data?.[0])
      })
      .catch((err) => console.log('err', err))
  }

  const [newName, setNewName] = useState<any>()
  const [newFolderName, setNewFolderName] = useState<any>()

  //
  const renameFile = async (file: any, type: any) => {
    const body = {
      newFileName: newName + '.' + type,
      image: file,
    }

    await ApiPost('upload/rename_file', body)
      .then((res) => {
        setTimeout(() => {
          setEditFile(-1)
          getFolderFiles(selectedFolder)
        }, 1500)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const [isEditTenant, setIsEditTenant] = useState<any>(false)

  React.useEffect(() => {
    getTenantFolders()
    getTenantUnits()
  }, [])

  // get unit details
  const getUnitDetails = () => {
    if (tenantSelectedUnit?._id != undefined) {
      ApiGet(`corporate/unit/${tenantSelectedUnit?._id}`)
        .then((res) => {
          setUnitDetails(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    }
  }

  React.useEffect(() => {
    getUnitDetails()
  }, [tenantSelectedUnit])


  React.useEffect(() => {
    getmessageDataById(roomId)
  }, [sendMessage])

  React.useEffect(() => {
    console.log(comefrom)
    if (comefrom == 'tenant-req') {
      getRequests()
      setPropertiType('Requests')
    } else setPropertiType('Documents')
  }, [])

  const deleteTenant = () => {
    ApiDelete(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then(() => {
        SuccessToast('Tenant has been deleted successfully..')
        navigate(-1)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res: any) => {
        setCountry(res?.data?.data)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  useEffect(() => {
    getCountry()
  }, [])

  const updateTenant = async (data: any, e: any) => {
    e.preventDefault()
    const body = {
      _id: tenantData?._id,
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality,
      // tenantType: tenantData?.tenantType,
      DOB: tenantData?.DOB === null ? '' : tenantData?.DOB,
      passportNo: tenantData?.passportNo,
      otherNo: tenantData?.otherNo,
      idNo: tenantData?.idNo,
      // folder_list: folders,
      gender: Number(tenantData?.gender),
      profileImage: profileImg,
    }
    console.log(body)
    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast(response?.data?.message)
        setIsEditTenant(false)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  const onSelectFile = async (e: any) => {
    e.preventDefault()
    let fileURL = e.target.files[0]
    var formdata = new FormData()
    formdata.append('image', fileURL)

    await ApiPost(`upload/compress_image/tenant_profile`, formdata)
      .then((res) => {
        e.preventDefault()
        const body = {
          _id: tenantData?._id,
          firstName: tenantData?.firstName,
          lastName: tenantData?.lastName,
          phoneNumber: tenantData?.phoneNumber,
          countryCode: tenantData?.countryCode,
          email: tenantData?.email,
          nationality: tenantData?.nationality,
          // tenantType: tenantData?.tenantType,
          DOB: tenantData?.DOB === null ? '' : tenantData?.DOB,
          passportNo: tenantData?.passportNo,
          otherNo: tenantData?.otherNo,
          idNo: tenantData?.idNo,
          // folder_list: folders,
          gender: Number(tenantData?.gender),
          profileImage: res?.data?.data?.image,
        }

        ApiPut(`corporate/tenant`, body)
          .then((response) => {
            SuccessToast(response?.data?.message)
            setIsEditTenant(false)
          })
          .catch((error) => {
            console.log(error)
            ErrorToast(error?.message)
          })
      })
      .catch(() => {})
  }

  // delete logo
  const deleteProfileImg = () => {
    const body = {
      url: profileImg,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setProfileImg(null)
        SuccessToast('Profile picture has been removed successfully!!!')
        const body = {}

        // ApiPut('corporate/corporate_setting', body)
        //   .then((res) => {
        //     getDetails()
        //   })
        //   .catch((err) => ErrorToast(err.message))
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (tenantSelectedUnit?._id != undefined || selectedTenancy?._id != undefined) {
      getRequests()
      getPayments()
      getPaymentSummary()
    }
  }, [tenantSelectedUnit, selectedTenancy])

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      // const type = response?.data?.type?.split('/')?.[1]
      // console.log(type)

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: response.headers['content-type']})

      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }
      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  const checkBeforeDeleteFolder = async () => {
    await ApiGet(`corporate/tenant_folder/deletion_checker/${selectedFolder?._id}`)
      .then((res) => {
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        if (!isAbleToDelete) {
          ErrorToast('Unable To Delete Folder.')
        } else {
          Swal.fire({
            html: `
<div class='fs-4 mt-4 fw-bold'> 
Delete Folder
</div>
<div class='fs-5 mt-4'> 
   Are you sure you want to permanently delete this folder?
</div>`,
            showConfirmButton: true,
            confirmButtonColor: '#d54645',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: '#0d9e91',
            width:'205px',
            padding:'0px',
            
            // cancelButtonColor: "transparent",
            cancelButtonText: 'No',
          }).then((res: any) => {
            if (res.isConfirmed) {
              // console.log(fl)
              deleteFolder(selectedFolder?._id)
              setViewFolder(false)
              setEditFile(-1)
            }
          })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const checkBeforeDeleteTenant = async () => {
    await ApiGet(`corporate/tenant/deletion_checker/${tenantData?._id}`)
      .then((res) => {
        console.log(res?.data?.data?.isAbleToDelete)
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.map((tenancy: any) => tenancy?.tenancyStatus)
        if (
          tenancyStatus.includes(0) ||
          tenancyStatus.includes(1) ||
          tenancyStatus.includes(2) ||
          (tenancyStatus.includes(3) && isAbleToDelete === false)
        ) {
          ErrorToast(
            'Cannot delete Tenant. It seems the tenant has an ongoing lease or unsettled payments. To delete this tenant, you must first cancel their lease and settle their due payments.'
          )
        } else {
          Swal.fire({
            html: `
     <div class='fs-4 mt-4 fw-bold'> 
     You are about to delete this tenant.
     </div>
     <div class='fs-5 mt-4'> 
         Deleting this tenant will prevent you from interacting and managing the tenant
     </div>
      <div class='fs-5 mt-4'> 
         Are you sure you want to continue?
     </div>
     `,
            showConfirmButton: true,
            confirmButtonColor: '#D72852',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonColor: '#0d9e91',
            // cancelButtonColor: "transparent",
            cancelButtonText: 'No',
          }).then((res: any) => {
            if (res.isConfirmed) {
              deleteTenant()
            }
          })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);

  // **********************

  /* ---------------- Message ------------------*/
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)
  const [chatServiceJobId, setChatServiceJobId] = useState<any>()
  const [tenanttData, setTenanttData] = useState<any>()
  const [serviceJobId, setServiceJobId] = useState<any>()

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector((state: any) => state.counterReducer.chatSelectedDataList)
  // const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [finalIdData, setFinalIdData] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)

  const [serviceJob, setServiceJob] = useState<any>('')

  const [noShow, setNoShow] = useState<any>(0)
  const [chatSubject, setChatSubject] = useState<any>()

  const [chatRoomId, setChatRoomId] = useState<any>(null)
  const [roomData, setRoomData] = useState<any>(null)

  // const [pageLimit, setPageLimit] = useState<any>(false)
  // const [page, setPage] = useState<any>(1)

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours: any = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes: any = Math.round(minutes)
    var rh, rm

    if (rhours < 10) rh = '0' + rhours
    else rh = rhours

    if (rminutes < 10) rm = '0' + rminutes
    else rm = rminutes

    return rh + ':' + rm
  }

  useEffect(() => {
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])


  useEffect(() => {
    socket.on('receive_message', (newMessage: any) => {
      console.log('socket newMessage', newMessage)
      console.log('messages', messages)
      setMessages([...messages, {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()}])
    })
    socket.on('new_user', (new_user: any) => {
      console.log('socket new_user', new_user)
    })
  }, [])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    socket.off('online')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }


  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    // await ApiGet(`corporate/chat_room/${chat._id}`)
    //   .then((res) => {
    setDefaultRoomData(roomData)
    fetchMessage(chat._id, 1, [])
    // })
    // .catch((err) => {
    //   console.log('res_blob', err)
    // })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages(res?.data?.data?.chat_message_data)
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err: any) => console.log('failed: ', err))
  }
  notificationEvent()

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   await ApiPost(`corporate/chat_message/get`, {
  //   limit: 10,
  //   page: 1,
  //   chatRoomId: chatRoomId,
  // })
  //   .then((res) => {
  //     setMessages(res?.data?.data?.chat_message_data)
  //     setMsgPage(msgPage + 1)
  //     setMsgPageLimit(res?.data?.data?.state?.page_limit)
  //   })
  //   .catch((err) => {
  //     console.log('res_blob', err)
  //   })
  // }

  const handleSubmitt = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit')
    e.preventDefault()
    let idsToSend: any = []
    for (let i = 0; i < tenanttData?.length; i++) {
      idsToSend[i] = tenanttData[i]._id
    }

    // const body = {
    //   subjectTitle: bodyWithSubject.subjectTitle,
    //   type: 0,
    //   serviceJobId: chatServiceJobId,
    //   tenantIds: idsToSend,
    // }

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      // await ApiPost(`corporate/chat_room`, body)
      //   .then((res) => {
      //     console.log('\nres.data.data', res.data.data)
      handleSendMessage({
        roomId: chatRoomId,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      openMessage(e, 0, {_id: chatRoomId})
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
      // })
      // .catch((err) => {
      //   console.log('res_blob', err)
      // })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    // let m = new Date(dd).toLocaleString()
    // let x = moment(m.format("DD-MM-YYYY HH:mm"))
    // return x;
    return new Date(dd).toLocaleString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const getChatList = async () => {
    // await ApiPost(`corporate/chat_room/get`, {
    //   limit: 10,
    //   page: page,
    // })
    //   .then((res) => {
    //     setChatData([...chatData, ...res?.data?.data?.chat_room_data])
    //     setPage(page + 1)
    //     setPageLimit(res?.data?.data?.state?.page_limit)
    //   })
    //   .catch((err) => {
    //     console.log('res_blob', err)
    //   })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 10,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const gotoChatList = async () => {
    const body = {
      limit: 10,
      page: 1,
      serviceJobId: serviceJob,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        setTenanttData(res.data.data.tenant_data)
        setChatData(res?.data?.data?.chat_room_data)
        setShowChat('message')
      })
      .catch()
  }

  // function called on clicking Message tenant Button
  const getChatRoomForTenant = async (val: any) => {
    let tenant: any = []
    tenant[0] = val
    setServiceJob(val)
    await ApiGet(`corporate/chat_room/personal_chat?tenantId=${val?._id}`)
      .then((res) => {
        SetBodyWithSubject({subjectTitle: res?.data?.data?.subjectTitle})
        showMessagePopUp()

        setTimeout(() => {
          fetchMessage(res?.data?.data?._id, 1, [])
        }, 300)

        // if (res.data.data.isNewChat === true)
        setShowChat('newMessage')

        setTenanttData(tenant)

        setChatRoomId(res.data.data._id)
        setRoomData(res?.data?.data)

        setDefaultRoomData(res?.data?.data)

        handleJoinRoom({
          roomId: res?.data?.data?._id,
          userId: user._id,
        })

        // setIsEdit(false)

        // setChatData(res?.data?.data?.chat_room_data)
      })
      .catch()
  }

  //
  // const generateTicketForTerminate = () => {
  //   const body = {
  //     unitId: unitData?._id,
  //     tenancyId: tenancyData?._id,
  //     type: 3,
  //   }
  //   ApiPost(`corporate/tenant_request`, body)
  //     .then((res) => {

  //       navigate(
  //         `/lease-request/${res?.data?.data?._id}/${tenancyData?.unitId}/${tenancyData?._id}/3`
  //       )

  //       // navigate(`/view-request/${res?.data?.data?._id}/2`)
  //       // navigate(`/tenants`, {
  //       //   state: {
  //       //     requestId: res?.data?.data?._id,
  //       //   },
  //       // })
  //     })
  //     .catch((err: any) => {
  //       ErrorToast(err?.data?.message)
  //     })
  // }

  /************************ */
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    const message = 'Are you sure you want to leave?'

    event.returnValue = message
  }

  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     if (isFormDirty) {
  //       const message = 'You have unsaved changes. Do you really want to leave?'
  //       event.returnValue = message // Standard for most browsers
  //       return message // For some older browsers
  //     }
  //   }

  //   const handlePopstate = (event: PopStateEvent) => {
  //     if (isFormDirty) {
  //       const userChoice = window.confirm(
  //         'You are about to leave this page. Do you wish to save your updates?'
  //       )

  //       if (userChoice) {
  //         console.log('User chose to save updates')
  //         onSubmitNewFlow(formData, 'exit', true)
  //       } else {
  //         console.log('User chose not to save updates')
  //       }

  //       setIsFormDirty(false)
  //     }
  //   }

  //   const handleHashChange = () => {
  //     if (isFormDirty) {
  //       const userChoice = window.confirm(
  //         'You are about to leave this page. Do you wish to save your updates?'
  //       )

  //       if (userChoice) {
  //         console.log('User chose to save updates')
  //         onSubmitNewFlow(formData, 'exit', true)
  //       } else {
  //         console.log('User chose not to save updates')
  //       }

  //       setIsFormDirty(false)
  //     }
  //   }

  //   window.addEventListener('beforeunload', handleBeforeUnload)
  //   window.addEventListener('popstate', handlePopstate)
  //   window.addEventListener('hashchange', handleHashChange)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //     window.removeEventListener('popstate', handlePopstate)
  //     window.removeEventListener('hashchange', handleHashChange)
  //   }
  // }, [isFormDirty])
  // **********************

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => updateTenant(tenantData, e))}>
          <div className='d-flex flex-column flex-column-fluid'>
            {/* <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                View Tenant Details
              </h1>
             
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <div className='text-muted cursor-pointer' onClick={() => navigate('/tenants')}>
                    Tenant
                  </div>
                </li>
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                <li className='breadcrumb-item active'>
                  <a className='px-2'>Tenant details</a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
            <div id='kt_app_content' className='app-content flex-column-fluid pt-0'>
              <div className='px-2 pt-0 mt-0'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/tenants-new')
                    }}
                  >
                    <img src={backArrow} height={14} width={14} />
                  </span>
                  <h2 className='m-0 ms-2 white-dark-theme-color'>{'Tenant Profile'}</h2>
                </div>
              </div>
              <div id='kt_app_content_container' className='px-2 mt-8'>
                <div className='d-flex flex-column flex-lg-row'>
                  <div className='flex-column flex-lg-row-auto w-lg-300px w-xl-400px mb-10'>
                    <div className='card mb-5 mb-xl-8'>
                      <div className='card-body py-3 px-5'>
                        {/* <div className='d-flex flex-stack fs-4 py-3'>
                        <div
                          className='fw-bold rotate collapsible'
                          data-bs-toggle='collapse'
                          role='button'
                          aria-expanded='false'
                          aria-controls='kt_user_view_details'
                        ></div>
                      </div> */}
                        <div id='kt_user_view_details' className='collapse show'>
                          <div className='pb-5 fs-6'>
                            <div className='row mt-4'>
                              {!isEditTenant && (
                                <>
                                  <div className='col-12'>
                                    <h3 className='m-0 ms-1 fnt-600 white-dark-theme-color'>{`${tenantData?.firstName} ${tenantData?.lastName}`}</h3>
                                  </div>
                                  <div className='col-12 symbol symbol-100px symbol d-flex align-items-center mt-5'>
                                    {/* Logo */}
                                    <div className='d-flex mb-3 '>
                                      {profileImg == null ? (
                                        <>
                                          <img src={tenantUserFilledIconBlue} height={80} width={80} />
                                        </>
                                      ) : (
                                        <div className='d-flex'>
                                          <img
                                            src={`${Bucket}${profileImg}`}
                                            height='160'
                                            width='160'
                                            style={{
                                              borderRadius: '7px',
                                              border: '1.5px solid black',
                                              objectFit: 'contain',
                                            }}
                                          />

                                          {/* <div> */}
                                          <label
                                            htmlFor='media'
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid #48a794 ',
                                              borderRadius: '4px',
                                              transform: 'translate(-45px,-10px)',
                                            }}
                                          >
                                            <img
                                              src={editpen}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                              }}
                                            />
                                          </label>

                                          <input
                                            type='file'
                                            hidden
                                            id='media'
                                            className='form-control form-control-solid'
                                            name='passport'
                                            onChange={onSelectFile}
                                          />
                                          {/* </div> */}
                                          {/* <div> */}
                                          <div
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid crimson',
                                              borderRadius: '4px',
                                              transform: 'translate(-38px,-10px)',
                                            }}
                                            onClick={() => deleteProfileImg()}
                                          >
                                            <img
                                              src={deleteImg}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                                marginLeft: '2px',
                                              }}
                                            />
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      )}
                                    </div>
                                    {/* <img src={img} alt='image' /> */}
                                  </div>
                                  <div className='col-12 d-flex align-items-center  mt-5'>
                                    <div
                                      className={
                                        tenantData?.accountStatus === 0
                                          ? 'status req-bar me-5'
                                          : tenantData?.accountStatus === 1
                                          ? 'status active-bar me-5'
                                          : ''
                                      }
                                    >
                                      {tenantData?.accountStatus === 0 ? 'Inactive' : tenantData?.accountStatus === 1 ? 'Active' : ''}
                                    </div>

                                    <div
                                      className={
                                        tenantData?.invitationStatus === 0
                                          ? 'status requested-st'
                                          : tenantData?.invitationStatus === 1
                                          ? 'status draft-st'
                                          : tenantData?.invitationStatus === 2
                                          ? 'status renewed-st'
                                          : tenantData?.invitationStatus === 3
                                          ? 'status cancelled-st'
                                          : ''
                                      }
                                    >
                                      {tenantData?.invitationStatus === 0
                                        ? 'Not Invited'
                                        : tenantData?.invitationStatus === 1
                                        ? 'Invited'
                                        : tenantData?.invitationStatus === 2
                                        ? 'Connected'
                                        : tenantData?.invitationStatus === 3
                                        ? 'Rejected'
                                        : ''}
                                    </div>

                                    <img
                                      src={pencil}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-auto me-5 cursor-pointer'
                                      onClick={() => {
                                        setEditTenantModel(true)
                                      }}

                                      // onClick={() => setIsEditTenant(true)}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className=' mt-7'>
                              {/* First Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> First Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.firstName || '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              {/* Last Name */}
                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Last Name </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.lastName || '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Email </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.email ? tenantData?.email : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Mobile No. </label>
                                </h4>

                                <h4 style={{width: '65%', borderRadius: '0.475rem'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.phoneNumber ? `${tenantData?.countryCode} ${tenantData?.phoneNumber}` : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Date of Birth </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.DOB ? moment(tenantData?.DOB && tenantData?.DOB.split('T')[0]).format('DD/MM/YYYY') : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Nationality </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.nationality ? tenantData?.nationality : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Gender </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.gender == 0 ? 'Female' : tenantData?.gender == 1 ? 'Male' : 'Other'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> ID Number </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.idNo ? `${tenantData?.idNo}` : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Passport Number </label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.passportNo ? `${tenantData?.passportNo}` : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-2 mb-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                  <label className='tenant-gray-label'> Tenant ID</label>
                                </h4>
                                <h4 style={{width: '65%'}} className='my-2 fnt-500'>
                                  <span className='ps-1 white-dark-theme-color'>
                                    <b>{tenantData?.id}</b>
                                  </span>
                                </h4>
                              </div>

                              {(tenantData?.invitationStatus == 0 || tenantData?.invitationStatus == 3) && (
                                <div className='d-flex my-2 mb-4 align-items-center justify-content-center mt-10'>
                                  <button
                                    type='button'
                                    className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 select-btn status-w-200 '
                                    onClick={() => {
                                      localStorage.setItem('tenantData', JSON.stringify(tenantData))
                                      localStorage.setItem('tenantFolders', JSON.stringify(folders))
                                      setRequestModal(false)
                                      setAddInviteModal(true)
                                    }}
                                  >
                                    Invite Tenant
                                  </button>
                                </div>
                              )}

                              <div className='d-flex my-2 mb-4 align-items-center justify-content-center mt-5'>
                                <button
                                  disabled={tenantData?.invitationStatus !== 2}
                                  type='button'
                                  className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 green-submit-btn status-w-200 text-center'
                                  onClick={() => {
                                    localStorage.setItem('tenantData', JSON.stringify(tenantData))
                                    localStorage.setItem('tenantFolders', JSON.stringify(folders))
                                    setRequestModal(true)
                                    setAddInviteModal(true)
                                  }}
                                >
                                  Request Profile Update
                                </button>
                              </div>

                              <div className='d-flex my-2 align-items-center justify-content-center mt-10'>
                                <h4>
                                  <label className='tenant-gray-label'> Profile Last Updated</label>
                                </h4>
                              </div>

                              <div className='d-flex my-2 align-items-center justify-content-center mt-1'>
                                <h4>
                                  <label className='tenant-gray-label'>
                                    <i className='tenant-gray-label'>
                                      {' '}
                                      <p className='my-0'>
                                        {tenantData?.updatedByUser != undefined
                                          ? `${tenantData?.updatedByUser?.firstName} ${tenantData?.updatedByUser?.lastName}`
                                          : '-'}
                                      </p>
                                    </i>
                                  </label>
                                </h4>
                              </div>

                              <div className='d-flex my-2  align-items-center justify-content-center mt-1'>
                                <h4>
                                  <label className='tenant-gray-label'>
                                    <i className='tenant-gray-label'>
                                      {' '}
                                      {tenantData?.updatedAt ? `${moment?.utc(tenantData?.updatedAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}` : '-'}
                                    </i>
                                  </label>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex my-2 mb-4 align-items-center justify-content-center mt-5'>
                      <button
                        type='button'
                        className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 term-btn status-w-200 text-center'
                        onClick={() => {
                          checkBeforeDeleteTenant()
                        }}
                      >
                        Delete Account
                      </button>
                    </div>
                  </div>
                  <>
                    {!messageList && (
                      <>
                        <div className='flex-lg-row-fluid ms-lg-6'>
                          <div className='d-flex justify-content-between'>
                            <ul
                              className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-1 xyz ms-1'
                              role='tablist'
                              style={{transform: 'translate(0px, -40px)'}}
                            >
                              <li className='nav-item' role='presentation'>
                                <a
                                  className={
                                    propertiType === 'Documents' ? 'nav-link text-active-primary pb-0 active' : 'nav-link text-active-primary  pb-0'
                                  }
                                  data-bs-toggle='tab'
                                  aria-selected='false'
                                  role='tab'
                                  tabIndex={-1}
                                  onClick={() => {
                                    setPropertiType('Documents')
                                  }}
                                >
                                  Documents
                                </a>
                              </li>
                            </ul>

                            <div
                              onClick={() => {
                                SetBodyWithSubject({subjectTitle: tenantData?._id})
                                // setChatSubject(tenantData?._id)
                                // setChatServiceJobId(tenantData?._id)
                                getChatRoomForTenant(tenantData)
                              }}
                              className='cursor-pointer green-bg-circle-chat me-2'
                              style={{
                                transform: 'translate(0px, -35px)',
                              }}
                            >
                              <img
                                src={chatWhite}
                                height={17}
                                width={17}
                                className='cursor-pointer p-0 m-0'
                              />
                            </div>
                          </div>

                          <div className='card py-3 px-5' style={{transform: 'translate(0px, -30px)', minHeight: '80vh'}}>
                            {/* Documents Tab */}
                            {propertiType === 'Documents' && (
                              <>
                                <div className='row g-6 g-xl-9 mb-6 pt-2 mb-xl-9'>
                                  {!viewFolder && (
                                    <div className='col-lg-12 col-md-12 text-end' style={{textAlign: 'right'}}>
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold green-hollow-btn ms-auto btn-fit-content px-2'
                                        style={{width: '130px'}}
                                        onClick={() => {
                                          setFileFound(false)
                                          setFolderNameModal(true)
                                        }}
                                      >
                                        <img
                                          src={folderGreenIcon}
                                          height={18}
                                          width={18}
                                          style={{
                                            marginRight: '7px',
                                          }}
                                        />
                                        New Folder
                                      </button>
                                    </div>
                                  )}

                                  {/* Add New Folder Card */}
                                  {folderNameModal && !viewFolder && (
                                    <div className='col-md-6 col-lg-4 col-xl-4'>
                                      <div className='d-flex mx-2 mb-2'>
                                        <img
                                          src={redCross}
                                          height={18}
                                          width={18}
                                          className='cursor-pointer'
                                          onClick={() => setFolderNameModal(false)}
                                        />

                                        <img
                                          style={
                                            folderName?.trim()?.length > 0
                                              ? {opacity: '1', cursor: 'pointer'}
                                              : {opacity: '0.7', cursor: 'not-allowed'}
                                          }
                                          src={correct}
                                          height={24}
                                          width={24}
                                          className='ms-auto '
                                          onClick={() => {
                                            if (folderName?.trim()?.length > 0) addFolder()
                                          }}
                                        />
                                      </div>
                                      <div className='card mx-2 pb-6'>
                                        <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test pb-6'>
                                          <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                            <div className='symbol symbol-60px mb-5'>
                                              <img src={folderIcon} className='theme-light-show' alt='' />
                                              <img src={folderIcon}  className='theme-dark-show' alt='' />
                                            </div>
                                            <div className='fs-5 fw-bold mb-2 '>
                                              <input
                                                type='text'
                                                className='form-control form-control-solid mytest w-75 m-auto bg-white'
                                                placeholder='Enter Folder Name'
                                                onChange={(e: any) => setFolderName(e.target.value)}
                                              >
                                                {fileFound ? (
                                                  <p className='mt-2' style={{color: 'red'}}>
                                                    Folder name already exists
                                                  </p>
                                                ) : null}
                                              </input>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {folders?.length > 0 && (
                                    <>
                                      {viewFolder ? (
                                        <>
                                          <div className='d-flex align-items-center '>
                                            <span
                                              className='rounded-circle'
                                              style={{cursor: 'pointer'}}
                                              onClick={() => {
                                                setViewFolder(false)
                                                setEditFile(-1)
                                              }}
                                            >
                                              <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
                                            </span>
                                            {/* <h2 className='m-0 head-text ms-2'>
                                            {selectedFolder?.name}
                                          </h2> */}

                                            <ul
                                              className='ms-2 nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-5 fw-semibold mb-1 xyz ms-1'
                                              role='tablist'
                                              style={{transform: 'translate(0px, -2px)'}}
                                            >
                                              <li className='nav-item' role='presentation'>
                                                <a
                                                  className={'nav-link text-active-primary pb-0 active'}
                                                  data-bs-toggle='tab'
                                                  aria-selected='false'
                                                  role='tab'
                                                  tabIndex={-1}
                                                >
                                                  {selectedFolder?.name}
                                                </a>
                                              </li>
                                            </ul>

                                            <button
                                              type='button'
                                              className='d-flex align-items-center justify-content-center btn btn-sm fw-bold px-2 term-btn-sm text-center ms-auto'
                                              onClick={() => {
                                                checkBeforeDeleteFolder()
                                              }}
                                            >
                                              Delete Folder
                                            </button>
                                          </div>
                                          <label
                                            title='Add New File'
                                            htmlFor='uploadPassport'
                                            className='ms-5 btn btn-sm fw-bold px-2 py-5 blue-btn '
                                          >
                                            <img src={fileIcon} width={13} className='me-3' />
                                            Add File{' '}
                                          </label>
                                          <input
                                            type='file'
                                            hidden
                                            id='uploadPassport'
                                            className='form-control form-control-solid'
                                            placeholder='upload'
                                            name='passport'
                                            onChange={(e: any) => {
                                              onFilesAddedSingle(e, 0, '')
                                            }}
                                            multiple
                                          />
                                          <div className='col-md-12 col-lg-12 col-xl-12' style={{padding:'0px 20px'}}>
                                            {folderFiles?.length > 0 ? (
                                              <>
                                                <div className='row py-3' style={{color: '#a1a5b7'}}>
                                                  <div className='col-md-7 '><span style={{paddingLeft:'25px'}}>File Name</span></div>
                                                  <div className='col-md-2 '>Date Added</div>
                                                  <div className='col-md-3'></div>
                                                </div>

                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div className='row py-3'>
                                                      {editFile == ind ? (
                                                        <>
                                                          <div className='col-md-7 head-text d-flex align-items-center'>
                                                            <div className='bullet-item me-3 mb-0'></div>
                                                            <img
                                                              src={
                                                                file?.fileName?.split('.')[1] === 'png'
                                                                  ? pngIcon
                                                                  : file?.fileName?.split('.')[1] === 'jpg'
                                                                  ? jpgIcon
                                                                  : file?.fileName?.split('.')[1] === 'pdf'
                                                                  ? pdfIcon
                                                                  : file?.fileName?.split('.')[1] === 'jpeg'
                                                                  ? jpegIcon
                                                                  : pdfIcon
                                                              }
                                                              height={30}
                                                              width={30}
                                                              className='mx-3 cursor-pointer'
                                                              data-fancybox
                                                              data-src={`${Bucket}${file?.fileURL}`}
                                                            />

                                                            <input
                                                              type='text'
                                                              value={getFilename(newName, false)}
                                                              className='form-control form-control-solid p-2'
                                                              style={{backgroundColor:'#fff', border:'1px solid #b3b3b3'}}
                                                              onChange={(e: any) => {
                                                                let temp = getFilename(file?.fileName, true)

                                                                setNewName(e.target.value + '.' + temp)
                                                              }}
                                                            />
                                                            {/* <a
                                                                className='btn btn-sm fw-bold btn-primary btn-green'
                                                                onClick={() => {
                                                                  const values = [...folderFiles]
                                                                  values[ind].fileName = newName
                                                                  setFolderFiles(values)
                                                                  getFolderFiles(selectedFolder)
                                                                  setEditFile(-1)
                                                                }}
                                                              >
                                                                <img src={check} height={18} width={18} />
                                                              </a> */}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div className='col-md-7 head-text d-flex align-items-center'>
                                                          <div className='bullet-item me-3 mb-0'></div>
                                                          <img
                                                            src={
                                                              file?.fileName?.split('.')[1] === 'png'
                                                                ? pngIcon
                                                                : file?.fileName?.split('.')[1] === 'jpg'
                                                                ? jpgIcon
                                                                : file?.fileName?.split('.')[1] === 'pdf'
                                                                ? pdfIcon
                                                                : file?.fileName?.split('.')[1] === 'jpeg'
                                                                ? jpegIcon
                                                                : pdfIcon
                                                            }
                                                            height={30}
                                                            width={30}
                                                            className='mx-3 cursor-pointer'
                                                            data-fancybox
                                                            data-src={`${Bucket}${file?.fileURL}`}
                                                          />
                                                          {getFilename(file?.fileName, false)?.length > 30
                                                            ? `${file?.fileName?.slice(0, 30)}...${getFilename(file?.fileName, true)}`
                                                            : file?.fileName}
                                                        </div>
                                                      )}
                                                      <div className='col-md-2 head-text'>{moment(file?.createdAt).format('DD.MM.YYYY')}</div>
                                                      <div className='col-md-3 head-text text-end'>
                                                        {editFile == ind ? (
                                                          <div className='d-flex justify-content-end'>
                                                            <img
                                                              src={removeFilled}
                                                              height={20}
                                                              width={20}
                                                              style={{cursor: 'pointer',marginRight:'25px'}}
                                                              onClick={() => {
                                                                setEditFile(-1)
                                                              }}
                                                            />
                                                            <img
                                                              src={correctFilled}
                                                              height={20}
                                                              width={20}
                                                              className='ms-7'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                const values = [...folderFiles]
                                                                values[ind].fileName = newName
                                                                setFolderFiles(values)
                                                                updateName()
                                                                // getFolderFiles(selectedFolder)
                                                                // setEditFile(-1)
                                                              }}
                                                              // onClick={() => updateName()}
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className='d-flex justify-content-end tst'>
                                                            <img
                                                              src={downloadIcon}
                                                              height={20}
                                                              width={20}
                                                              className='me-5'
                                                              style={{cursor: 'pointer'}}
                                                              onClick={() => {
                                                                handleDownload(`${Bucket}${file?.fileURL}`)
                                                              }}
                                                            />
                                                            <img
                                                              src={greenPen}
                                                              height={17}
                                                              width={17}
                                                              style={{cursor: 'pointer'}}
                                                              className='me-5'
                                                              onClick={() => {
                                                                setNewName(getFilename(file?.fileName, false))
                                                                setEditFile(ind)
                                                              }}
                                                            />

                                                            <img
                                                              src={trashImg}
                                                              height={16}
                                                              width={16}
                                                              className='ms-1 cursor-pointer '
                                                              onClick={(e: any) => {
                                                                Swal.fire({
                                                                  html: `
                                                      <div class='fs-4 mt-4 fw-bold'> 
                                                      Delete File
                                                      </div>
                                                      <div class='fs-5 mt-4'> 
                                                          Are you sure you want to permanently delete this file?
                                                      </div>`,
                                                                  showConfirmButton: true,
                                                                  confirmButtonColor: '#d54645',
                                                                  confirmButtonText: 'Yes',
                                                                  showCancelButton: true,
                                                                  cancelButtonColor: '#0d9e91',
                                                                  width:'205px',
                                                                  padding:'0px',
                                                                  
                                                                  // cancelButtonColor: "transparent",
                                                                  cancelButtonText: 'No',
                                                                }).then((res: any) => {
                                                                  if (res.isConfirmed) {
                                                                    onFilesAddedSingle(e, 1, file?._id)
                                                                  }
                                                                })
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                })}

                                                {/* <div className='tops mw-350px d-flex gap-5 align-items-start'>
                                    {folderFiles?.map((file: any, ind: any) => {
                                      return (
                                        <div className='imgs-set'>
                                          <div className='d-flex mw-350px me-3'>
                                            <img
                                              src={Bucket + file?.fileURL}
                                              width='80'
                                              height='80'
                                              className='main_img'
                                            />

                                            <img
                                              style={{
                                                marginLeft: '-13px',
                                                marginTop: '-10px',
                                                cursor: 'pointer',
                                              }}
                                              src={closered}
                                           
                                              width='25'
                                              height='25'
                                              onClick={(e) => {
                                                onFilesAddedSingle(e, 1, file?._id)
                                              }}
                                            />
                                          </div>
                                          <p className=''>
                                            {getFilename(file?.fileName, false)
                                              ?.length > 6
                                              ? `${file?.fileName?.slice(
                                                  0,
                                                  6
                                                )}...${getFilename(
                                                  file?.fileName,
                                                  true
                                                )}`
                                              : file?.fileName}
                                          </p>
                                        </div>
                                      )
                                    })}
                                  </div> */}
                                              </>
                                            ) : (
                                              <div className='text-center light-dark-theme-color'> No Files</div>
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {folders
                                            ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                                            ?.map((fl: any, index: any) => {
                                              console.log(fl?.fileList)
                                              return (
                                                <div className='col-md-6 col-lg-4 col-xl-4 tst'>
                                                  <div style={{height: '30px'}}>
                                                    {!fl?.isSystemGenerated && (
                                                      <img
                                                        src={trashImg}
                                                        height={16}
                                                        width={16}
                                                        className='ms-1 cursor-pointer'
                                                      onClick={(e: any) => {
                                                        Swal.fire({
                                                          html: `
                                              <div class='fs-4 mt-4 fw-bold'> 
                                              Delete Folder
                                              </div>
                                              <div class='fs-5 mt-4'> 
                                                 Are you sure you want to permanently delete this folder?
                                              </div>`,
                                                          showConfirmButton: true,
                                                          confirmButtonColor: '#d54645',
                                                          confirmButtonText: 'Yes',
                                                          showCancelButton: true,
                                                          cancelButtonColor: '#0d9e91',
                                                          width:'205px',
                                                          padding:'0px',
                                                          
                                                          // cancelButtonColor: "transparent",
                                                          cancelButtonText: 'No',
                                                        }).then((res: any) => {
                                                          if (res.isConfirmed) {
                                                            deleteFolder(fl?._id)
                                                          }
                                                        })
                                                      }}
                                                      />
                                                    )}
                                                  </div>
                                                  <div className='card '>
                                                    <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                      <div className='d-flex justify-content-end'>
                                                        <div
                                                          className='gray-count-circle'
                                                        >
                                                          {fl?.fileList?.length}
                                                        </div>
                                                      </div>
                                                      <a
                                                        onClick={() => getFolderFiles(fl)}
                                                        className='text-gray-800 text-hover-primary d-flex flex-column'
                                                      >
                                                        <div className='symbol symbol-60px mb-5'>
                                                          <img
                                                            src={
                                                              tenantData?.document?.contract?.split('.')[1] === 'pdf'
                                                                ? pdfDocument
                                                                : tenantData?.other?.split('.')[1] === 'doc'
                                                                ? docCocument
                                                                : folderIcon
                                                            }
                                                            className='theme-light-show'
                                                            alt=''
                                                          />
                                                          <img src={folderIcon} className='theme-dark-show' alt='' />
                                                        </div>
                                                        <div className='fs-5 fw-bold mb-2'>
                                                          <h4 className='white-dark-theme-color'>{fl?.name}</h4>
                                                        </div>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            })}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                                {/* <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.contract}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.contract?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.other?.split('.')[1] === 'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Contract</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.other?.[0]}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.other?.[0]?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.other?.[0]?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Other</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.passport}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.passport?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.passport?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Passport</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.residency}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.residency?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.residency?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Residency</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.id}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.id?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.id?.split('.')[1] === 'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View ID</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-xl-4'>
                              <div className='card h-100'>
                                <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
                                  <a
                                    href={`${Bucket}${tenantData?.document?.other?.[0]}`}
                                    target='_blank'
                                    className='text-gray-800 text-hover-primary d-flex flex-column'
                                  >
                                    <div className='symbol symbol-60px mb-5'>
                                      <img
                                        src={
                                          tenantData?.document?.other?.[0]?.split('.')[1] === 'pdf'
                                            ? pdfDocument
                                            : tenantData?.document?.other?.[0]?.split('.')[1] ===
                                              'doc'
                                            ? docCocument
                                            : folderDocument
                                        }
                                        className='theme-light-show'
                                        alt=''
                                      />
                                      <img
                                        src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                        className='theme-dark-show'
                                        alt=''
                                      />
                                    </div>
                                    <div className='fs-5 fw-bold mb-2'>View Other</div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                              </>
                            )}

                            {/* Property and Rent Tab */}
                            {propertiType === 'Payments & Rent' && (
                              <>
                                {/* <div className='card pt-4 mb-6 mb-xl-9'>
                            <div className='card-header border-0'>
                              <div className='card-title d-flex justify-content-between w-100'>
                                <h2></h2>
                                <a
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  onClick={() =>
                                    navigate(
                                      `/tenancy-details/${tenantData[0]?.tenancy?._id}/payment-rent`
                                    )
                                  }
                                >
                                  View Payments
                                </a>
                              </div>
                            </div>
                            <div className='card-body pt-0 pb-5'>
                              <div className='row gy-4 my-5'>
                                <div className='col-lg-4 fs-6 fw-semibold text-gray-600'>
                                  Total Payments{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.total_payments}
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Paid{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.paid_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Overdue{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.overdue_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Outstanding Balance{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.outstanding_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Rent{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.rent_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Security Deposit{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.security_count} AED
                                  </span>
                                </div>
                                <div className='col-lg-4 fw-semibold text-gray-600'>
                                  Booking{' '}
                                  <span style={{color: 'black'}}>
                                    {paymentData?.booking_count} AED
                                  </span>
                                </div>
                                {paymentData?.other?.map((v: any) => {
                                  return (
                                    <div className='col-lg-4 fw-semibold text-gray-600'>
                                      {v?._id ? v?._id : 'Other'}{' '}
                                      <span style={{color: 'black'}}>
                                        {v?.count ? v?.count : 0} AED
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div> */}

                                <div className='card card-flush gap-5 p-5 pt-1 '>
                                  <div className='row mt-5'>
                                    <div className='mb-2 col-md-1 pe-5 xyz'>
                                      <label className='fs-7 fw-semibold text-gray-600 pt-3' style={{fontWeight: '600'}}>
                                        {' '}
                                        Filter{' '}
                                      </label>
                                    </div>
                                    <div className='col-md-3'>
                                      <h6>
                                        <select
                                          className='form-select form-select-solid'
                                          value={tenantSelectedUnit?._id + '.' + tenantSelectedUnit?.unitNo}
                                          onChange={(e) => {
                                            let values = {...tenantSelectedUnit}

                                            values = {
                                              _id: e.target.value.split('.')[0],
                                              unitNo: e.target.value.split('.')[1],
                                            }

                                            setTenantSelectedUnit(values)

                                            // setTimeout(() => {
                                            //   getRequests()
                                            //   getPayments()
                                            //   getPaymentSummary()
                                            // }, 2500)
                                          }}
                                          name='units'
                                        >
                                          {tenantUnits?.length > 0 ? (
                                            <>
                                              {tenantUnits?.map((unit: any, index: any) => {
                                                return <option value={unit?._id + '.' + unit?.unitNo}>{unit?.unitNo}</option>
                                              })}
                                            </>
                                          ) : (
                                            <option value='' selected disabled>
                                              No Record
                                            </option>
                                          )}
                                        </select>
                                      </h6>
                                    </div>

                                    <div className='col-md-3'>
                                      <h6>
                                        <select
                                          className='form-select form-select-solid'
                                          value={selectedTenancy?._id + '.' + selectedTenancy?.contractNo}
                                          onChange={(e) => {
                                            let values = {...selectedTenancy}

                                            values = {
                                              _id: e.target.value.split('.')[0],
                                              contractNo: e.target.value.split('.')[1],
                                            }

                                            setSelectedTenancy(values)

                                            // setTimeout(() => {
                                            //   getRequests()
                                            //   getPayments()
                                            //   getPaymentSummary()
                                            // }, 2500)
                                          }}
                                          name='tenancies'
                                        >
                                          {tenancies?.length > 0 ? (
                                            <>
                                              {tenancies?.map((tn: any, index: any) => {
                                                // console.log(tn)
                                                return <option value={tn?._id + '.' + tn?.contractNo}>{tn?.contractNo}</option>
                                              })}
                                            </>
                                          ) : (
                                            <option value='' selected disabled>
                                              No Record
                                            </option>
                                          )}
                                        </select>
                                      </h6>
                                    </div>
                                  </div>
                                  <div className='row gy-4 my-5 mt-0' style={{justifyContent: 'space-between'}}>
                                    <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                      Total Payments{' '}
                                      <span className='ms-3 head-text' style={{color: 'black'}}>
                                        <b>{paymentData?.totalPayments ? paymentData?.totalPayments : '0'}</b>
                                      </span>
                                    </div>
                                    <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                      Paid{' '}
                                      <span style={{color: 'black'}} className='ms-3 head-text'>
                                        <b>
                                          {paymentData?.paidAmount
                                            ? parseFloat(paymentData?.paidAmount)?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })
                                            : '0.00 '}
                                          AED
                                        </b>
                                      </span>
                                    </div>
                                    <div className='col-lg-4 fs-5 fw-semibold text-gray-600'>
                                      Overdue{' '}
                                      <span style={{color: 'black'}} className='ms-3 head-text'>
                                        <b>
                                          {paymentData?.overdueAmount
                                            ? parseFloat(paymentData?.overdueAmount)?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })
                                            : '0.00 '}
                                          AED
                                        </b>
                                      </span>
                                    </div>
                                    <div className='col-lg-4  fs-5 fw-semibold text-gray-600'>
                                      Outstanding Balance{' '}
                                      <span style={{color: 'black'}} className='ms-3 head-text'>
                                        <b>
                                          {paymentData?.outstandingAmount
                                            ? parseFloat(paymentData?.outstandingAmount)?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })
                                            : '0.00 '}
                                          AED
                                        </b>
                                      </span>
                                    </div>
                                  </div>

                                  <div className='d-flex '>
                                    <button
                                      type='button'
                                      className='btn btn-sm fw-bold green-submit-btn ms-auto'
                                      onClick={() => {
                                        if (tenantSelectedUnit?._id && selectedTenancy?._id) setIncomeModal(true)
                                        else ErrorToast("Can't generate a payment...")
                                      }}
                                      style={{width: '200px'}}
                                      disabled={tenantSelectedUnit?._id != undefined || selectedTenancy?._id != undefined ? false : true}
                                    >
                                      <img src={addWhite} height={16} width={16} style={{marginRight: '10px'}} /> Add Incoming Payment
                                    </button>
                                  </div>

                                  {payments?.length > 0 ? (
                                    <div
                                      className='card-body pt-0 table-responsive mt-1 pb-4 px-1'
                                      id='global-table'
                                      // onWheel={handleMouseWheel}
                                      // ref={tableRef}
                                      // onMouseEnter={handleMouseEnter}
                                      // onMouseLeave={handleMouseLeave}
                                    >
                                      <table className='table align-start table-row-dashed fs-6 gy-5' id='kt_ecommerce_sales_table'>
                                        <thead>
                                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                            <th className='min-w-75px'>#</th>
                                            <th className='min-w-150px '>Payment</th>
                                            <th className='min-w-150px'>Amount</th>
                                            <th className='min-w-125px'>Date Created</th>
                                            <th className='min-w-100px'>Status</th>
                                            <th className='min-w-100px'>Category</th>
                                            <th className='min-w-100px'>Due</th>
                                            <th className='min-w-100px'>Reminder</th>
                                            <th className='min-w-150px'>Amount Paid</th>
                                            <th className='min-w-100px'>Date Paid</th>
                                            <th className='min-w-150px'>Oustanding</th>
                                            <th className='min-w-100px'>Method</th>
                                            {/* <th className='min-w-100px'>Invoice</th> */}
                                          </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                          {payments.map((pm: any, ind: any) => {
                                            let x = pm?.invoiceURL?.split('.')[1]
                                            let y = pm?.invoiceURL?.split('/')[2]
                                            // let inv: any = y + '.' + x
                                            let inv: any = y

                                            return (
                                              <>
                                                <tr
                                                  style={
                                                    show[ind] == true
                                                      ? {borderBottomStyle: 'none', height: '30px'}
                                                      : show[ind] == false
                                                      ? {borderBottomStyle: 'dashed', height: '30px'}
                                                      : pm?.payment_sub_item?.length == 0
                                                      ? {borderBottomStyle: 'dashed', height: '30px'}
                                                      : {height: '30px'}
                                                  }
                                                >
                                                  <td>
                                                    <div className='d-flex align-items-center'>
                                                      {pm?.payment_sub_item?.length > 0 && (
                                                        <img
                                                          src={show[ind] ? close : openImg}
                                                          height='15'
                                                          width='15'
                                                          className='cursor-pointer'
                                                          style={{
                                                            marginLeft: '-30px',
                                                            marginRight: '15px',
                                                          }}
                                                          onClick={() => {
                                                            if (show[ind] == true) {
                                                              let values = [...show]
                                                              values[ind] = false
                                                              setShow(values)
                                                            } else {
                                                              let values = [...show]

                                                              values[ind] = true

                                                              setShow(values)
                                                            }
                                                          }}
                                                        />
                                                      )}
                                                      <a
                                                        // href={`${Bucket}${pm?.invoiceURL}`}
                                                        // target='_blank'
                                                        style={{
                                                          textDecoration: 'underline',
                                                          color: '#007a59',
                                                          marginLeft: '-3px',
                                                        }}
                                                        onClick={() => getPaymentById(pm?._id)}
                                                      >
                                                        {' '}
                                                        {pm.id}
                                                      </a>
                                                    </div>
                                                  </td>
                                                  <td className='max-w-150px'>
                                                    <div className='d-flex'>
                                                      {pm?.isLock && <img src={lock} height={16} width={16} className='me-2' />}
                                                      {pm.name}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {parseFloat(pm?.totalAmount)?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                  <td>{pm?.createdAt ? moment(pm?.createdAt).format('DD.MM.YYYY') : '-'}</td>
                                                  <td>
                                                    {pm.paymentStatus === 0
                                                      ? 'Unpaid'
                                                      : pm.paymentStatus === 1
                                                      ? 'Paid'
                                                      : pm.paymentStatus === 2
                                                      ? 'Partially paid'
                                                      : pm.paymentStatus === 3
                                                      ? 'Void'
                                                      : 'Draft'}
                                                  </td>
                                                  <td>{pm.categories == 0 ? 'Upcoming' : pm.categories == 1 ? 'Overdue' : 'Deferred'}</td>
                                                  <td>{pm?.dueDate ? moment(pm?.dueDate).format('DD.MM.YYYY') : '-'}</td>
                                                  <td>
                                                    {pm?.payment_reminder?.length > 0
                                                      ? moment(pm?.payment_reminder[0]?.reminderDate).format('DD.MM.YYYY')
                                                      : '-'}
                                                  </td>
                                                  <td>
                                                    {pm?.amountPaid?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                  <td>{pm?.datePaid ? moment(pm?.datePaid).format('DD.MM.YYYY') : '-'}</td>
                                                  <td>
                                                    {pm?.outstandingAmount <= 0 ? '' : '-'}
                                                    {parseFloat(getPositive(pm?.outstandingAmount))?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </td>
                                                  <td>
                                                    {pm?.paymentMethod == 0
                                                      ? 'Card'
                                                      : pm?.paymentMethod == 1
                                                      ? 'Cheque'
                                                      : pm?.paymentMethod == 2
                                                      ? 'Bank'
                                                      : 'Cash'}
                                                  </td>
                                                </tr>

                                                {pm?.payment_sub_item?.length > 0 && show[ind] == true ? (
                                                  pm?.payment_sub_item.map((pmSub: any, index: any) => {
                                                    return (
                                                      <>
                                                        <tr
                                                          style={{
                                                            borderBottomStyle: 'none',
                                                            height: '30px',
                                                          }}
                                                        >
                                                          <td style={{paddingLeft: '5px'}}>
                                                            {pm.id}
                                                            {'_'}
                                                            {pmSub?.id}
                                                          </td>
                                                          <td>{pmSub?.name}</td>
                                                          <td>
                                                            {pmSub?.isLatePaymentFeesRecord
                                                              ? pmSub?.amount?.toLocaleString('en-US', {
                                                                  minimumFractionDigits: 2,
                                                                })
                                                              : (pmSub?.amount * pmSub?.quantity)?.toLocaleString('en-US', {
                                                                  minimumFractionDigits: 2,
                                                                })}
                                                          </td>
                                                          <td></td>
                                                          <td>
                                                            {pmSub.paymentStatus === 0
                                                              ? 'Unpaid'
                                                              : pmSub.paymentStatus === 1
                                                              ? 'Paid'
                                                              : pmSub.paymentStatus === 2
                                                              ? 'Partially paid'
                                                              : pmSub.paymentStatus === 3
                                                              ? 'Void'
                                                              : 'Draft'}
                                                          </td>
                                                          <td></td>
                                                          <td></td>
                                                          <td></td>

                                                          <td>
                                                            {pmSub?.amountPaid?.toLocaleString('en-US', {
                                                              minimumFractionDigits: 2,
                                                            })}
                                                          </td>
                                                          <td>{pmSub?.datePaid ? moment(pmSub?.datePaid).format('DD.MM.YYYY') : '-'}</td>
                                                          <td>
                                                            {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                                            {parseFloat(getPositive(pmSub?.outstandingAmount))?.toLocaleString('en-US', {
                                                              minimumFractionDigits: 2,
                                                            })}
                                                          </td>
                                                        </tr>
                                                        {index == pm?.payment_sub_item?.length - 1 && (
                                                          <tr style={{height: '30px'}}>
                                                            <td></td>
                                                            <td>{' Total VAT'}</td>
                                                            <td>
                                                              {pm?.totalVAT?.toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                              })}
                                                            </td>
                                                          </tr>
                                                        )}
                                                      </>
                                                    )
                                                  })
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
                                      <table className='table align-start table-row-dashed fs-6 gy-5' id='kt_ecommerce_sales_table'>
                                        <thead>
                                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                            <th className='min-w-100px'>#</th>
                                            <th className='min-w-150px '>Payment</th>
                                            <th className='min-w-150px'>Amount</th>
                                            <th className='min-w-150px'>Date Created</th>
                                            <th className='min-w-100px'>Status</th>
                                            <th className='min-w-100px'>Category</th>
                                            <th className='min-w-150px'>Due</th>
                                            <th className='min-w-150px'>Reminder</th>
                                            <th className='min-w-150px'>Amount Paid</th>
                                            <th className='min-w-100px'>Date Paid</th>
                                            <th className='min-w-150px'>Oustanding</th>
                                            <th className='min-w-100px'>Method</th>
                                            {/* <th className='min-w-100px'>Invoice</th> */}
                                          </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                          <tr>
                                            <td colSpan={10} className='text-center'>
                                              <img src={noData} alt='' width={350} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  )}

                                  {/* Pagination */}
                                  <div className='row mb-5'>
                                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
                                      <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                                        <ReactPaginate
                                          breakLabel='...'
                                          nextLabel='>'
                                          pageClassName='paginate_button page-item +'
                                          pageLinkClassName='page-link'
                                          containerClassName='pagination'
                                          activeClassName='active'
                                          previousClassName='paginate_button page-item previous'
                                          nextClassName='paginate_button page-item next'
                                          previousLinkClassName='page-link'
                                          nextLinkClassName='page-link'
                                          onPageChange={handlePageClick1}
                                          pageRangeDisplayed={5}
                                          pageCount={pageLimit1}
                                          previousLabel='<'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Messages Tab */}
                            {propertiType === 'Messages' && (
                              <>
                                <div className='card pt-0 mb-6 mb-xl-9'>
                                  <div className='card-header border-0 px-3'>
                                    <div className='card-title d-flex justify-content-between w-100'>
                                      <h2></h2>
                                      <a className='btn btn-sm fw-bold btn-primary btn-green mx-3' onClick={() => setNewMessageModal(true)}>
                                        New Message
                                      </a>
                                    </div>
                                  </div>
                                  <div className='card-body pt-0 pb-5 px-3'>
                                    <div className='table-responsive'>
                                      <table className='table align-middle table-row-dashed gy-5' id='kt_table_users_login_session'>
                                        <thead>
                                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                            <th className=' min-w-75px'>ID </th>
                                            <th className=' min-w-100px'>Subject</th>
                                            <th className=' min-w-100px'>Date</th>
                                            <th className=' min-w-150px'>Messages Received</th>
                                            <th className=' min-w-100px'></th>
                                          </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                          {messageData?.chat_room_data?.map((i: any) => {
                                            return (
                                              <tr>
                                                <td>{i?.id}</td>
                                                <td>{i?.subjectTitle}</td>
                                                <td>{moment(i?.date).format('YYYY-MM-DD')}</td>
                                                <td>{i?.messageReceived}</td>
                                                <td>
                                                  <BsArrowRightSquareFill color='#007a59' fontSize={18} className='mx-3 cursor-pointer' />
                                                  {/* <a
                                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                              // onClick={() => handleViewMessage(i?._id, i?.tenantId)}
                                              // onClick={() => {
                                              //   navigate(-1)
                                              // }}
                                            >
                                              View
                                            </a> */}
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* Requests tab */}
                            {propertiType === 'Requests' && (
                              <>
                                <div className='p-0 ' style={{backgroundColor: 'none'}}>
                                  <div className='my-5' style={{backgroundColor: 'none'}}>
                                    <div className='card card-flush px-5 py-2 h-100'>
                                      <div className='row mt-5'>
                                        <div className='mb-2 col-md-1 pe-5 xyz'>
                                          <label className='fs-7 fw-semibold text-gray-600 pt-3' style={{fontWeight: '600'}}>
                                            {' '}
                                            Filter{' '}
                                          </label>
                                        </div>
                                        <div className='col-md-3'>
                                          <h6>
                                            <select
                                              className='form-select form-select-solid'
                                              value={tenantSelectedUnit?._id + '.' + tenantSelectedUnit?.unitNo}
                                              placeholder={'Select Unit...'}
                                              onChange={(e) => {
                                                let values = {...tenantSelectedUnit}

                                                values = {
                                                  _id: e.target.value.split('.')[0],
                                                  unitNo: e.target.value.split('.')[1],
                                                }

                                                setTenantSelectedUnit(values)

                                                // setTimeout(() => {
                                                //   getRequests()
                                                //   getPayments()
                                                //   getPaymentSummary()
                                                // }, 2500)
                                              }}
                                              name='units'
                                            >
                                              {tenantUnits?.length > 0 ? (
                                                <>
                                                  {tenantUnits?.map((unit: any, index: any) => {
                                                    return <option value={unit?._id + '.' + unit?.unitNo}>{unit?.unitNo}</option>
                                                  })}
                                                </>
                                              ) : (
                                                <option value='' selected disabled>
                                                  No Record
                                                </option>
                                              )}
                                            </select>
                                          </h6>
                                        </div>

                                        <div className='col-md-3'>
                                          <h6>
                                            <select
                                              className='form-select form-select-solid'
                                              value={selectedTenancy?._id + '.' + selectedTenancy?.contractNo}
                                              onChange={(e) => {
                                                console.log(e.target.value)

                                                let values = {...selectedTenancy}

                                                values = {
                                                  _id: e.target.value.split('.')[0],
                                                  contractNo: e.target.value.split('.')[1],
                                                }
                                                console.log(values)

                                                setSelectedTenancy(values)

                                                // setTimeout(() => {
                                                //   getRequests()
                                                //   getPayments()
                                                //   getPaymentSummary()
                                                // }, 2500)
                                              }}
                                              name='tenancies'
                                            >
                                              {tenancies?.length > 0 ? (
                                                <>
                                                  {tenancies?.map((tn: any, index: any) => {
                                                    // console.log(tn)
                                                    return <option value={tn?._id + '.' + tn?.contractNo}>{tn?.contractNo}</option>
                                                  })}
                                                </>
                                              ) : (
                                                <option value='' selected disabled>
                                                  No Record
                                                </option>
                                              )}
                                            </select>
                                          </h6>
                                        </div>
                                      </div>

                                      <div className='table-responsive mb-5'>
                                        <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_sales_table'>
                                          <thead>
                                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                              <th className='text-start min-w-50px'>ID</th>
                                              <th className='text-start min-w-100px'>Type</th>
                                              <th className='text-start min-w-100px'>Date</th>
                                              <th className='text-start min-w-100px'>Status</th>
                                              <th className='text-start min-w-100px'></th>
                                            </tr>
                                          </thead>
                                          <tbody className='fw-semibold text-gray-600'>
                                            {requests?.length > 0 ? (
                                              <>
                                                {requests?.map((rq: any, i: any) => {
                                                  return (
                                                    <>
                                                      <tr style={{height: '35px'}}>
                                                        <td data-kt-ecommerce-order-filter='order_id' className='text-start'>
                                                          {rq?.id}
                                                        </td>
                                                        <td className='text-start'>
                                                          {' '}
                                                          <span
                                                          // style= {{padding: '5px'}}
                                                          // className={
                                                          //    rq?.type == 0
                                                          //     ? 'active-color'
                                                          //     :  rq?.type == 5
                                                          //     ? 'ended-color'
                                                          //     : rq?.type == 2
                                                          //     ? 'renewed-color'
                                                          //     :  rq?.type == 1
                                                          //     ? 'terminated-color'
                                                          //     : ''
                                                          // }
                                                          ></span>
                                                          <span>
                                                            {' '}
                                                            {/* <b className='my-2'> */}
                                                            {rq?.type == 0
                                                              ? 'Move In'
                                                              : rq?.type == 1
                                                              ? 'Move Out'
                                                              : rq?.type == 2
                                                              ? 'Tenancy Renewal'
                                                              : rq?.type == 3
                                                              ? 'Tenancy Termination'
                                                              : rq?.type == 4
                                                              ? 'General Complaint'
                                                              : rq?.type == 5
                                                              ? 'Other'
                                                              : rq?.type == 6
                                                              ? 'Maintenance'
                                                              : rq?.type == 7
                                                              ? 'Corporate Maintenance'
                                                              : ''}
                                                            {/* </b> */}
                                                          </span>
                                                        </td>
                                                        <td data-kt-ecommerce-order-filter='order_id' className='text-start'>
                                                          {rq?.createdAt ? moment(rq?.createdAt).format('DD/MM/YYYY') : '-'}
                                                        </td>

                                                        <td className='text-start'>
                                                          {rq?.status === 0
                                                            ? 'Received'
                                                            : rq?.status === 1
                                                            ? 'Tenant pending approval'
                                                            : rq?.status === 2
                                                            ? 'Tenant Approved'
                                                            : rq?.status === 3
                                                            ? 'Rejected'
                                                            : rq?.status === 4
                                                            ? 'Tenant Cancelled'
                                                            : rq?.status === 5
                                                            ? 'Tenant Rejected'
                                                            : rq?.status === 6
                                                            ? 'Accepted'
                                                            : rq?.status === 7
                                                            ? 'Booked'
                                                            : rq?.status === 8
                                                            ? 'Variation Request'
                                                            : 'Canceled'}
                                                        </td>

                                                        <td className='text-start' data-order='2022-09-11'>
                                                          <BsArrowRightSquareFill
                                                            color='#007a59'
                                                            fontSize={18}
                                                            className='mx-3 cursor-pointer'
                                                            onClick={() => {
                                                              dispatch(setComefrom('tenant-req'))
                                                              navigate(`/view-request/${rq?._id}/${rq?.type}`)
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                })}
                                              </>
                                            ) : (
                                              <tr>
                                                <td colSpan={15} className='text-center'>
                                                  <img src={noData} alt='' width={350} />
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end mt-3'>
                                        <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                                          <ReactPaginate
                                            breakLabel='...'
                                            nextLabel='>'
                                            pageClassName='paginate_button page-item +'
                                            pageLinkClassName='page-link'
                                            containerClassName='pagination'
                                            activeClassName='active'
                                            previousClassName='paginate_button page-item previous'
                                            nextClassName='paginate_button page-item next'
                                            previousLinkClassName='page-link'
                                            nextLinkClassName='page-link'
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageLimit}
                                            previousLabel='<'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {messageList && (
                      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                        <div className='card' id='kt_chat_messenger'>
                          <div className='card-header' id='kt_chat_messenger_header'>
                            <div className='card-title'>
                              <div className='d-flex justify-content-center flex-column me-3'>
                                <a href='#' className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                                  Brian Cox
                                </a>
                                <div className='mb-0 lh-1'>
                                  <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                                  {isConnected && <span className='fs-7 fw-semibold text-muted'>Active</span>}
                                </div>
                              </div>
                            </div>
                            <div className='card-toolbar'>
                              <div className='me-n3'>
                                <button
                                  className='btn btn-sm btn-icon btn-active-light-primary'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                >
                                  <i className='bi bi-three-dots fs-2'></i>
                                </button>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                                  data-kt-menu='true'
                                >
                                  <div className='menu-item px-3'>
                                    <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>Contacts</div>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a href='#' className='menu-link px-3'>
                                      Add Contact
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a href='#' className='menu-link flex-stack px-3'>
                                      Invite Contacts
                                      <i
                                        className='fas fa-exclamation-circle ms-2 fs-7'
                                        data-bs-toggle='tooltip'
                                        aria-label='Specify a contact email to send an invitation'
                                        data-bs-original-title='Specify a contact email to send an invitation'
                                        data-kt-initialized='1'
                                      ></i>
                                    </a>
                                  </div>
                                  <div className='menu-item px-3' data-kt-menu-trigger='hover' data-kt-menu-placement='right-start'>
                                    <a href='#' className='menu-link px-3'>
                                      <span className='menu-title'>Groups</span>
                                      <span className='menu-arrow'></span>
                                    </a>
                                    <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                                      <div className='menu-item px-3'>
                                        <a
                                          href='#'
                                          className='menu-link px-3'
                                          data-bs-toggle='tooltip'
                                          data-bs-original-title='Coming soon'
                                          data-kt-initialized='1'
                                        >
                                          Create Group
                                        </a>
                                      </div>
                                      <div className='menu-item px-3'>
                                        <a
                                          href='#'
                                          className='menu-link px-3'
                                          data-bs-toggle='tooltip'
                                          data-bs-original-title='Coming soon'
                                          data-kt-initialized='1'
                                        >
                                          Invite Members
                                        </a>
                                      </div>
                                      <div className='menu-item px-3'>
                                        <a
                                          href='#'
                                          className='menu-link px-3'
                                          data-bs-toggle='tooltip'
                                          data-bs-original-title='Coming soon'
                                          data-kt-initialized='1'
                                        >
                                          Settings
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='menu-item px-3 my-1'>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-bs-toggle='tooltip'
                                      data-bs-original-title='Coming soon'
                                      data-kt-initialized='1'
                                    >
                                      Settings
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='card-body' id='kt_chat_messenger_body'>
                            <div
                              className='scroll-y me-n5 pe-5 h-300px h-lg-auto'
                              data-kt-element='messages'
                              data-kt-scroll='true'
                              data-kt-scroll-activate='{default: false, lg: true}'
                              data-kt-scroll-max-height='auto'
                              data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                              data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
                              data-kt-scroll-offset='5px'
                              style={{maxHeight: '335px'}}
                            >
                              {messagechat.map((item: any) => (
                                <div className='d-flex justify-content-end mb-10'>
                                  <div className='d-flex flex-column align-items-end'>
                                    <div className='d-flex align-items-center mb-2'>
                                      <div className='me-3'>
                                        <span className='text-muted fs-7 mb-1'>2 Hours</span>
                                        <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'>
                                          You
                                        </a>
                                      </div>
                                      <div className='symbol symbol-35px symbol-circle'>
                                        <img alt='Pic' src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg' />
                                      </div>
                                    </div>
                                    <div
                                      className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                                      data-kt-element='message-text'
                                    >
                                      {item?.message}
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* // <div className="d-flex justify-content-start mb-10">
                        //   <div className="d-flex flex-column align-items-start">
                        //     <div className="d-flex align-items-center mb-2">
                        //       <div className="symbol symbol-35px symbol-circle">
                        //         <img alt="Pic" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg" />
                        //       </div>
                        //       <div className="ms-3">
                        //         <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Brian Cox</a>
                        //         <span className="text-muted fs-7 mb-1">2 mins</span>
                        //       </div>
                        //     </div>
                        //     <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">How likely are you to recommend our company to your friends and family ?</div>
                        //   </div>
                        // </div> */}
                            </div>
                          </div>

                          <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
                            <form>
                              <textarea
                                className='form-control form-control-flush mb-3'
                                rows={1}
                                data-kt-element='input'
                                placeholder='Type a message'
                                onChange={(e) => setSendMessage(e.target.value)}
                              ></textarea>
                              <div className='d-flex flex-stack'>
                                <div></div>
                                <input
                                  type='reset'
                                  // onClick={() => setShowCreateAppModal(true)}
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  // onClick={handleSendMessage}
                                  value='Send'
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <NewMessage
        show={newMessageModal}
        tenantdata={tenantData}
        getMessageList={getMessageList}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      /> */}

        {/* <Modal show={folderNameModal} onHide={() => setFolderNameModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Folder </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
              Input Folder Name
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              onChange={(e: any) => setFolderName(e.target.value)}
            />
            {fileFound ? (
              <p className='mt-2' style={{color: 'red'}}>
                Folder name already exists
              </p>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => addFolder()}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={folderName?.trim()?.length == 0 ? true : false}
          >
            Add {''}
          </Button>
        </Modal.Footer>
      </Modal> */}

        {/* {dataToEdit && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={tenantSelectedUnit}
          detailsToEdit={dataToEdit}
          type={'tenant'}
        />
      )} */}

        <Lightbox
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          slides={slides}
          carousel={{finite: slides.length <= 1}}
          render={{
            buttonPrev: slides.length <= 1 ? () => null : undefined,
            buttonNext: slides.length <= 1 ? () => null : undefined,
          }}
        />

        {/* {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => {
            setIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getPayments}
          unitDetails={unitDetails}
          tnancy={selectedTenancy?._id}
        />
      )} */}

        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
          <CircularProgress style={{color: '#007a59'}} />
        </Backdrop>
      </div>
      {editTenantModal && (
        <EditTenantModel
          show={editTenantModal}
          // tenantData={tenantData}
          // folders={folders}
          handleClose={(key: any) => {
            if (key === 0) {
              setEditTenantModel(false)
            }
            if (key === 1) {
              setEditTenantModel(false)
              getTenantData()
              getMessageList()
              getTenantFolders()
              getCountry()
              getTenantUnits()
              // getmessageDataById()
            }
            // getTenantCountSummary()
            // getTenants()
          }}
        />
      )}

      {addInviteModal && (
        <AddInviteModel
          show={addInviteModal}
          isEdit={requestModal}
          handleClose={(key: any) => {
            setAddInviteModal(false)
            getTenantData()
            // navigate(-1)
          }}
        />
      )}
      {/* Message modal */}
      <div className={showMessageCard ? 'message' : 'd-none'} style={{width: '400px', height: '600px', zIndex: '20', right: '0px'}}>
        <div
          className='card m-0 p-0 br-10 light-gry-dark-shadow'
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3 className='m-0 d-flex align-items-center msg_title' style={{justifyContent: 'space-between'}}>
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5'>
            {showChat == 'chat' ? (
              <>
                <div className='symbol-group symbol-hover py-5 m-0' style={{background: 'transparent', borderRadius: '10px 10px 0 0'}}>
                  <div className='symbol-group symbol-hover mx-5'>
                    {/* <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoChatList()}
                      style={{cursor: 'pointer'}}
                    /> */}
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                          <img alt='' src={profileImgStatic}></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a href='#' className='symbol symbol-45px symbol-circle' data-bs-toggle='modal' data-bs-target='#kt_modal_view_users'>
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : (
                    <>
                      {tenanttData &&
                        tenanttData?.map((tnt: any) => (
                          <h4 className='m-5 '>
                            {tnt.firstName} {tnt.lastName}
                          </h4>
                        ))}
                    </>
                  )}
                </div>
                <h6 className='d-flex gap-5 mx-5 align-items-center pb-5' style={{borderBottom: '1px solid #aaa'}}>
                  <label htmlFor='subject' className='white-dark-theme-color'>Subject</label>
                  {defaultRoomData?.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    style={{backgroundColor: 'white'}}
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}} ref={bottomRef} onScroll={(e: any) => userScroll(e)}>
                  {messages
                    ?.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                    ?.map((msg: any) => (
                      <div className={`${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'}>
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf: `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          }}
                        >
                          <Card.Body className={`${msg.senderId}` !== `${user._id}` ? 'rec_msg br-5 p-0 m-0' : 'send_msg br-5 p-0 m-0'}>
                            <p className='p-0 m-0 small_font' style={{paddingRight: '20px !important'}}>
                              {/* {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName} */}
                            </p>
                            <div className='px-3 pt-0'>
                              <h4 className='m-0 p-0 py-2'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <Card.Footer
                            className={`${msg.senderId}` !== `${user._id}` ? 'p-0 text-start' : 'p-0 text-end'}
                            style={{backgroundColor: 'transparent', borderTop: '0px'}}
                          >
                            {' '}
                            <span className='m-0 small_font' style={{fontWeight: '400 !important'}}>
                              {dateTotime(msg?.createdAt)}
                            </span>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0'>
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control type='text' placeholder='Type a message' value={message} onChange={(e) => setMessage(e.target.value)} />
                      <Button variant='primary' type='submit' className='btn-green' disabled={!message}>
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImgStatic}></img>
                              </div>

                              <div className='d-flex align-items-center ' style={{justifyContent: 'space-between'}}>
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>{el.role}</span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input type='text' placeholder='Search' className='form form-control m-0 msg_search' />
                <img src={filter} alt='' width='24px' className='filter' onClick={() => setShowFilterCard(!showFilterCard)} />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  {/* <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li> */}
                </ul>
                <div className='d-flex align-items-center my-5' style={{justifyContent: 'space-between'}}>
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  {/* <div className='d-flex gap-3'>
                    <p className='m-0 cus-line' onClick={viewall}>
                      View All
                    </p>
                  </div> */}
                </div>
                <ul className='notification min_height p-0 m-0' ref={myDivRef} onScroll={(e: any) => userScroll(e)}>
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImgStatic}></img>
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a href='#' className='symbol symbol-45px symbol-circle' data-bs-toggle='modal' data-bs-target='#kt_modal_view_users'>
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            {/* <img alt='Pic' src='media/avatars/300-23.jpg' /> */}
                            <img alt='' src={profileImgStatic}></img>
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey'>
                              {/* {moment(chat.createdAt).format('HH:MM')} */}
                              {timeConvert(chat?.createdAt)}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImgStatic}></img>
                              </div>

                              <div className='d-flex align-items-center ' style={{justifyContent: 'space-between'}}>
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>{el.role}</span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div className='symbol-group symbol-hover p-5 m-0' style={{background: 'transparent', borderRadius: '10px 10px 0 0'}}>
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover mx-5'>
                        {/* <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        /> */}
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el.firstName} {el.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImgStatic} />
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a href='#' className='symbol symbol-40px symbol-circle' data-bs-toggle='modal' data-bs-target='#kt_modal_view_users'>
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div className='symbol-group symbol-hover p-5 px-0 m-0' style={{background: 'transparent', borderRadius: '10px 10px 0 0'}}>
                        <div className='symbol-group symbol-hover me-5 ms-0'>
                          {/* <img
                        src={leftarrow}
                        width='20px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      /> */}
                        </div>
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <div className='symbol-group symbol-hover m-0'>
                              <div className='symbol symbol-40px symbol-circle '>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {tnt.firstName} {tnt.lastName}
                                    </Tooltip>
                                  }
                                  placement='bottom'
                                >
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImgStatic} />
                                </OverlayTrigger>
                              </div>
                            </div>
                          ))}
                      </div>

                      <h6 className='m-0 p-0'>
                        {/* <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setShowChat('addNew')
                          }}
                        >
                          Select Recipient
                        </a> */}
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <h4 className='m-5 white-dark-theme-color'>
                              {tnt.firstName} {tnt.lastName}
                            </h4>
                          ))}
                      </h6>
                    </div>
                  )}
                </div>
                <h6 className='d-flex gap-5 mx-5 align-items-center pb-5 light-dark-theme-color' style={{borderBottom: '1px solid #aaa'}}>
                  <label htmlFor='subject' className='white-dark-theme-color'>Subject</label>
                  {bodyWithSubject.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={bodyWithSubject.subjectTitle}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}} ref={bottomRef}>
                  {messages
                    ?.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                    ?.map((msg: any, index: any) => (
                      <div className={`${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'}>
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf: `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body className={`${msg.senderId}` !== `${user._id}` ? 'rec_msg br-5 p-0 m-0' : 'send_msg br-5 p-0 m-0'}>
                            <p className='p-0 m-0 small_font white-dark-theme-color' style={{paddingRight: '20px !important'}}>
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0 ps-3'>
                              <h4 className='m-0 p-0 pt-5 white-dark-theme-color'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font white-dark-theme-color'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0 mx-auto'>
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control type='text' placeholder='Type a message' value={message} onChange={(e) => setMessage(e.target.value)} />
                      <Button variant='primary' type='submit' className='btn-green' disabled={!message}>
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    {/* <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    /> */}
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImgStatic}></img>
                              </div>

                              <div className='d-flex align-items-center ' style={{justifyContent: 'space-between'}}>
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>{el.role}</span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      <li
                        className={`nav-item cursor-pointer text-center min-w-75px ` + (chatTab === 'All' ? 'active' : '')}
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={`nav-link text-active-primary pb-4 text-center mx-2 ` + (chatTab === 'All' ? 'active' : '')}
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li>
                      <li
                        className={`nav-item cursor-pointer text-center min-w-75px ` + (chatTab === 'Internal' ? 'active' : '')}
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={`nav-link text-active-primary pb-4 text-center mx-2 ` + (chatTab === 'Internal' ? 'active' : '')}
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li>
                      <li
                        className={`nav-item cursor-pointer text-center min-w-75px ` + (chatTab === 'Tenants' ? 'active' : '')}
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={`nav-link text-active-primary pb-4 text-center mx-2 ` + (chatTab === 'Tenants' ? 'active' : '')}
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table className='table align-middle table-row-dashed fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input className='form-check-input' type='checkbox' onChange={(e: any) => makeGroup(e, i)} />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a className='btn btn-sm fw-bold btn-primary btn-green m-0' onClick={() => goToNewMessage('all')}>
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table className='table align-middle table-row-dashed fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input className='form-check-input' type='checkbox' onChange={(e: any) => makeGroup(e, i)} />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a className='btn btn-sm fw-bold btn-primary btn-green m-0' onClick={() => goToNewMessage('internal')}>
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table className='table align-middle table-row-dashed fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input className='form-check-input' type='checkbox' onChange={(e: any) => makeGroup(e, i)} />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a className='btn btn-sm fw-bold btn-primary btn-green m-0' onClick={() => goToNewMessage('internal')}>
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default ViewTenantNew

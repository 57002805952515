import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import schedule from '../../../img/schedule-blue.png'
import closure from '../../../../../img/job-closure.png'
import correct from '../../../../img/correct.png'
import removed from '../../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import Switch from 'react-switch'
import noData from '../../../../img/NoData1.svg'
import trashImg from '../../../../img/trash.png'
import {capitalizeFirstLetter} from '../../../../Utilities/utils'
import swal from 'sweetalert2'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPaginate from 'react-paginate'

const TenantSubRequest = () => {
  const navigate = useNavigate()
  const {mainId} = useParams()

  const [requestTableData, setRequestTableData] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')
  const [showAddNew, setShowAddNew] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [newError, setNewError] = useState<any>(false)

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const goToRequest = (listItem: any) => {
    navigate(`/settings/tenant-request/${listItem}/sub-category`)
  }

  const getRequestTableData = async () => {
    setRequestTableData([])

    let body = {
      page: page,
      limit: limit,
      tenantRequestCategoryId: `${mainId}`,
    }

    await ApiPost('corporate/tenant_request_sub_category/get', body)
      .then((res) => {
        setRequestTableData(res?.data?.data?.data)
        setPageLimit(res?.data?.data?.stage?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateReqestData = async (id: any, toggleValue: any, index: any) => {
    let body = {
      id: id,
      isEnable: toggleValue,
      tenantRequestCategoryId: `${mainId}`,
    }

    await ApiPut('corporate/tenant_request_sub_category', body)
      .then((res) => {
        const updateRequestData = [...requestTableData]
        updateRequestData[index].isEnable = res?.data?.data?.isEnable
        setRequestTableData(updateRequestData)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteSingleRequest = (v: any) => {
    setIsLoading(true)

    ApiDelete(`corporate/tenant_request_sub_category/${v?._id}`)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getRequestTableData()

        // let values = []
        // for (let i = 0; i < requestTableData?.length; i++) {
        //   values[i] = false
        // }
        // setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const updateName = (data: any) => {
    if (newName?.trim()?.length > 0) {
      setIsLoading(true)

      let name = newName.charAt(0).toUpperCase() + newName.slice(1)

      const body = {
        id: data?._id,
        name: name,
        tenantRequestCategoryId: `${mainId}`,
      }
      ApiPut(`corporate/tenant_request_sub_category`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          getRequestTableData()
          setNewName('')
          setIsLoading(false)

          setTimeout(() => {
            setEditFile(-1)
          }, 100)
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    } else {
      setNewError(true)
    }
  }

  const addRequest = () => {
    if (newName?.trim()?.length > 0) {
      setNewError(false)
      setIsLoading(true)

      let name = newName.charAt(0).toUpperCase() + newName.slice(1)

      const body = {
        name: name,
        tenantRequestCategoryId: `${mainId}`,
      }
      ApiPost(`corporate/tenant_request_sub_category`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          setIsLoading(false)
          getRequestTableData()
          setNewName('')

          setTimeout(() => {
            setShowAddNew(false)
          }, 200)
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    } else {
      setNewError(true)
    }
  }

  useEffect(() => {
    getRequestTableData()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 gap-lg-3' style={{paddingBottom: '40px'}}>
          <div className='d-flex align-items-center gap-3'>
            <span className='rounded-circle' style={{cursor: 'pointer'}} onClick={() => navigate(`/settings/tenant-request`)}>
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>{'Tenant Requests'}</h2>
          </div>
        </div>

        <div className='card card-flush gap-5 px-5 pb-0 tst'>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <Row className='pb-2'>
              <div className='d-flex align-items-center justify-content-between  mb-5 '>
                <h4 className='mt-5 mb-1' style={{color: 'black', width: '100%', fontWeight: 'bold'}}>
                  Request Category: {requestTableData?.[0]?.tenant_request_category?.[0]?.name ?? '-'}
                </h4>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold green-submit-btn  px-2 justify-content-center status-w-180 '
                    onClick={() => {
                      setShowAddNew(true)
                    }}
                  >
                    Add Sub Request Category
                  </button>
                </div>
              </div>
            </Row>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-200px px-0'>Request Sub Category</th>
                  <th className='min-w-150px px-0'>Assignment Rule Status</th>
                  <th className='min-w-150px px-0'>Ticket Assignment Rule</th>
                  <th className='min-w-150px px-0'>Tenant Portal Status</th>
                  <th className='text-start min-w-150px px-0'></th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
                {showAddNew && (
                  <tr>
                    <td className='text-start px-0'>
                      <div className='d-flex head-text align-items-center'>
                        {/* <p className='user-head pe-2 mb-0 ps-0'>{'-'}</p> */}
                        <div className='d-flex  vendor me-5 pe-2' style={{width: '-webkit-fill-available'}}>
                          <input
                            type='text'
                            value={newName}
                            className='form-control form-control-solid mytest'
                            onChange={(e: any) => {
                              let newVal: any = capitalizeFirstLetter(e.target.value)
                              setNewName(newVal)
                            }}
                            disabled={isLoading}
                            placeholder='Request Sub Category Name'
                          />
                        </div>
                      </div>
                      {newError && (
                        <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 ms-9'>
                          Please enter request sub category name
                        </p>
                      )}
                    </td>

                    <td className=' py-3 px-0'>
                      <p className='my-0 user-head'>
                        <p className='my-0'>{'-'}</p>
                      </p>
                    </td>
                    <td className=' py-3 px-0'>
                      <p className='my-0 user-head'>
                        <p className='my-0'>{'-'}</p>
                      </p>
                    </td>
                    <td className='py-3 px-0'>
                      <p className='my-0 user-head'>
                        <p className='my-0'>{'-'}</p>
                      </p>
                    </td>
                    <td className='py-3'>
                      <div className='d-flex align-items-center'>
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='cursor-pointer me-5'
                          onClick={(e) => {
                            setShowAddNew(false)
                          }}
                        />
                        <img src={correct} height={20} width={20} className='cursor-pointer me-5' onClick={() => addRequest()} />
                      </div>
                    </td>
                  </tr>
                )}
                {requestTableData?.length > 0 ? (
                  requestTableData?.map((listItem: any, ind: any) => {
                    return (
                      <tr>
                        <td className='cursor-pointer px-0'>
                          {editFile == ind ? (
                            <div>
                              <div className='d-flex  vendor me-5 pe-2' style={{width: '-webkit-fill-available'}}>
                                <input
                                  type='text'
                                  value={newName}
                                  className='form-control form-control-solid mytest'
                                  onChange={(e: any) => {
                                    let newVal: any = capitalizeFirstLetter(e.target.value)
                                    setNewName(newVal)
                                  }}
                                  disabled={isLoading}
                                  placeholder='Request Sub Category Name'
                                />
                              </div>
                              {newError && (
                                <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                                  Please enter sub request category name
                                </p>
                              )}
                            </div>
                          ) : (
                            <p className='user-head pe-2 mb-0'>{listItem?.name ? listItem?.name : '-'}</p>
                          )}
                        </td>
                        <td className='cursor-pointer px-0'>{listItem?.isAttachAssignment ? 'Applied' : 'Not Applied'}</td>
                        <td className='cursor-pointer px-0'>{'-'}</td>
                        <td className='cursor-pointer px-0'>
                          <div className='d-flex  align-items-center '>
                            <Switch
                              disabled={editFile == ind}
                              onChange={(e) => {
                                updateReqestData(listItem?._id, !requestTableData[ind].isEnable, ind)
                              }}
                              handleDiameter={20}
                              height={24}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={listItem?.isEnable}
                              onColor='#00cf99'
                              offColor='#b0bec5'
                            />
                            <h5 className=' m-0 head-text ms-3'>{listItem?.isEnable === true ? 'Enabled' : 'Disabled'}</h5>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <img
                              src={pencil}
                              height={16}
                              width={16}
                              style={editFile == ind ? {opacity: '0.4', cursor: 'not-allowed'} : {opacity: '1', cursor: 'pointer'}}
                              className='me-5 '
                              onClick={() => {
                                if (editFile !== ind) {
                                  setNewName(listItem?.name)
                                  setShowAddNew(false)
                                  setEditFile(ind)
                                }
                              }}
                            />
                            <img
                              src={trashImg}
                              height={18}
                              width={18}
                              className='me-5'
                              style={editFile == ind ? {opacity: '0.4', cursor: 'not-allowed'} : {opacity: '1', cursor: 'pointer'}}
                              onClick={(e) => {
                                if (editFile !== ind) {
                                  swal
                                    .fire({
                                      html: ` 
                                        <div class='fs-3'> <b>Delete Sub Request Category</div>
                                        <div class='fs-5 mt-4'>You are about to delete the selected sub request category.</div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                      // icon: 'warning',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#35475e',
                                      confirmButtonText: 'Yes',
                                      showCancelButton: true,
                                      cancelButtonText: 'No',
                                      cancelButtonColor: '#fff',
                                      customClass: {
                                        confirmButton: 'custom-confirm-button',
                                        cancelButton: 'custom-cancel-button',
                                        popup: 'custom-popup',
                                      },
                                      reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                    })
                                    .then((res: any) => {
                                      if (res.isConfirmed) {
                                        deleteSingleRequest(listItem)
                                      }
                                    })
                                }
                              }}
                            />
                            {editFile == ind && (
                              <img src={correct} height={20} width={20} className='ms-5 cursor-pointer' onClick={() => updateName(listItem)} />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    {!isLoading ? (
                      <td colSpan={5} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    ) : (
                      <td colSpan={6} className='text-center'>
                        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '25vh'}}>
                          <CircularProgress style={{color: '#007a59'}} />
                        </div>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* pagination start*/}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row mt-5'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* pagination end*/}
        </div>
      </div>
    </>
  )
}

export default TenantSubRequest

import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import {ApiPost, ApiGet, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import Select from 'react-select'

interface EditAnItemProps {
  show: boolean
  handleClose: any
  from: any
  updateRecord: any
  selectedItem?: any
}

const EditAnItem: React.FC<EditAnItemProps> = ({show, handleClose, from, updateRecord, selectedItem}) => {
  const [isLoading, setIsLoading] = useState<any>(false)
  const [open, setOpen] = useState<any>(false)

  const [list, setList] = useState<any>([])

  const [itemName, setItemName] = useState<any>(selectedItem?.name)
  const [itemMainId, setItemMainId] = useState<any>(selectedItem?.paymentMainItemListId)
  
  let listInd : any = -1

  //
  const updateItems = async () => {
    setIsLoading(true)
    const body = {
      paymentMainItemListId: itemMainId,
      id: selectedItem?._id,
      name: itemName
    }
    ApiPut(`corporate/payment_sub_item_list`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
        updateRecord()
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
    }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '250px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

   // React Select Filled
   const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '250px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getListForDropdown = async () => {
    await ApiGet(`corporate/payment_main_item_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getListForDropdown()
  }, [])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-9'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              // minHeight: '450px',
            }}
          >
            <div className='d-flex align-items-start'>
              <div className='d-block'>
                <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center white-dark-theme-color'>
                  Edit Item
                </h2>{' '}
                <p className='light-dark-theme-color'>Edit the item name or the item list it belongs to.</p>
              </div>

              <div className='d-flex ms-auto align-items-center'>
                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            {list &&
            list.map((ls: any, index: any) => {
              if (itemMainId == ls?.value) listInd = index
              return <></>
            })}

            <div className='d-flex align-items-start justify-content-center my-3'>
              <h3 className='white-dark-theme-color'>
                <b> Edit Item List</b>
              </h3>
            </div>
            <div className='d-flex justify-content-center xyz mb-3'>
              <Select
               styles={
                 listInd != -1
                  ? customStylesInputFilled
                  : customStyles
              }
                isSearchable={false}
                options={list}
                name='sortType'
                onChange={(e: any) => {
                  setItemMainId(e.value)
                }}
                defaultValue={listInd != -1 ? list[listInd] : []}
                    value={ listInd != -1 ? list[listInd] : []}
                placeholder={'Select Item List'}
              />
            </div>

            <div className='separator my-4'> </div>

            <div className='d-flex align-items-start justify-content-center mb-2'>
              <h2 className='white-dark-theme-color'>
                <b>Edit Item</b>
              </h2>
            </div>

            <div className='d-flex align-items-start justify-content-center mb-2'>
              <input
                type='text'
                className='form-control form-control-solid mytest mt-3 mx-0'
                placeholder='Item Name'
                name='itemName'
                value={itemName}
                onChange={(e) => {
                  let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                  setItemName(newValue)
                }}
                style={{width: '250px'}}
              />
            </div>

            <div className='d-flex align-items-center justify-content-center my-5'>
              <button
                type='button'
                className='btn btn-sm fw-bold  green-submit-btn ps-3 status-w-230 d-flex justify-content-center mt-10 mb-5'
                onClick={() => {
                  updateItems()
                }}
                disabled={itemName?.trim()?.length == 0}
              >
                {/* <img src={plusWhite} height={18} width={18} className='me-4' />  */}
                Update
              </button>
            </div>
          </div>
        </Row>
      </Modal>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </div>
  )
}

export default EditAnItem

import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import uploadWhite from '../../../../img/upload.svg'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import ModalLoader from '../../General/ModalLoader'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import tick from '../../../../img/onboard-tick.png'
import {CircularProgress} from '@mui/material'
import noData from '../../../../img/NoData1.svg'
import {MultiSelect} from 'react-multi-select-component'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { IoMdInformationCircleOutline } from "react-icons/io";

interface ButtonProps {
  show?: any
  handleClose?: any
  updateDetails?: any
  expData?: any
  selectedDev?: any
}

const SelectProperty = ({show, handleClose, updateDetails, expData, selectedDev}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [selectedLevel, setSelectedLevel] = useState<any>()
  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [buildingUnitList, setBuildingUnitList] = useState<any>([])

  const [selectedFloorGrps, setSelectedFloorGrps] = useState<any>([])
  const [floorGrpsLists, setFloorGrpsLists] = useState<any>([])

  const [selectedProperty, setSelectedProperty] = useState<any>('')
  const [selectedUnits, setSelectedUnits] = useState<any>([])
  const [unitsLists, setUnitsLists] = useState<any>([])
  const [searchUnit, setSearchUnit] = useState<any>('')

  // console.log(expData)

  const getBuildingsUnit = () => {
    // console.log(selectedDev)

    let unitIds: any = []
    selectedUnits?.length > 0 &&
      selectedUnits?.map((dev: any, ind: any) => {
        unitIds[ind] = dev?.value
      })

    let floorIds: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorIds[ind] = dev?.value
      })

    setIsLoading(true)
    const body = {
      floorIds: floorIds,
      clusterIds: [],
      unitGroupIds: [],
      unitTypes: selectedLevel == 2 ? [0, 1, 3, 4, 5] : [2],
      search: searchUnit,
      buildingIds: selectedDev?.[0]?.devType == 'build' ? [selectedDev?.[0]?.value] : [],
      communityIds: selectedDev?.[0]?.devType == 'comm' ? [selectedDev?.[0]?.value] : [],
      ids: unitIds,
      maintenanceServiceRequestId: expData?.requestDetail ? expData?.requestDetail?._id : '',
    }
    ApiPost(`corporate/expense_development/get/unit`, body)
      .then((res: any) => {
        setBuildingUnitList(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    width: '180px !important',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  useEffect(() => {
    if (selectedLevel != 1 && selectedLevel != undefined && selectedDev?.[0]?.devType == 'build') {
      getBuildingsUnit()
      getFloor()
    }
  }, [selectedLevel, selectedFloorGrps, selectedUnits, searchUnit])

  const getFloor = () => {
    const body = {
      search: '',
      developmentIds: [selectedDev?.[0]?.value],
      unitGroupIds: [],
      clusterIds: [],
      unitTypes: selectedLevel == 2 ? [0, 1, 3, 4, 5] : [2],
      maintenanceServiceRequestId: expData?.requestDetail ? expData?.requestDetail?._id : '',
    }

    ApiPost(`corporate/development/floor/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setFloorGrpsLists(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getUnits = () => {
    let floorIds: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorIds[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: [selectedDev?.[0]?.value],
      unitGroupIds: [],
      clusterIds: [],
      floorIds: floorIds,
      unitTypes: selectedLevel == 2 ? [0, 1, 3, 4, 5] : [2],
      maintenanceServiceRequestId: expData?.requestDetail ? expData?.requestDetail?._id : '',
    }

    ApiPost(`corporate/development/unit/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.unitNo}`,
              value: `${val?._id}`,
            }
          })

        setUnitsLists(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedDev?.[0]?.devType == 'build') getUnits()
  }, [selectedFloorGrps, selectedLevel])

  // useEffect(() => {
  //   getBuildingsUnit()
  // }, [selectedFloorGrps, selectedUnits, searchUnit])

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>([])

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  // Clusters
  const getClusters = () => {
    const body = {
      search: '',
      developmentIds: [selectedDev?.[0]?.value],
      clusterIds: [],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setClustersLists(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedLevel != 1 && selectedLevel != undefined && selectedDev?.[0]?.devType == 'comm') {
      getClusters()
    }
  }, [selectedLevel])

  const [clustersForList, setClustersForLists] = useState<any>([])

  const getClustersForList = () => {
    setIsLoading(true)
    const body = {
      communityIds: selectedDev?.[0]?.devType == 'comm' ? [selectedDev?.[0]?.value] : [],
    }
    ApiPost(`corporate/expense_development/get/cluster`, body)
      .then((res: any) => {
        setClustersForLists(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    if (selectedLevel != 1 && selectedLevel != undefined && selectedDev?.[0]?.devType == 'comm') {
      getClustersForList()
    }
  }, [selectedLevel, selectedClusters, selectedFloorGrps])

  //  Unit Groups
  const getUnitGroups = () => {
    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: [selectedDev?.[0]?.value],
      unitGroupIds: [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setUnitGrpsLists(values)

        if (clustIds?.length == 0) {
          setUnitGrpsLists([])
          setSelectedUnitGrps([])
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    getUnitGroups()
  }, [selectedClusters])

  const [goToNext, setGoToNext] = useState<any>(false)

  const getUnitsForSpecificCluster = (clusterId: any, unitGPId: any) => {
    setIsLoading(true)
    const body = {
      clusterIds: [clusterId],
      unitGroupIds: [unitGPId],
      unitTypes: selectedLevel == 2 ? [0, 1, 3, 4, 5] : [2],
      communityIds: selectedDev?.[0]?.devType == 'comm' ? [selectedDev?.[0]?.value] : [],
      maintenanceServiceRequestId: expData?.requestDetail ? expData?.requestDetail?._id : '',
    }
    ApiPost(`corporate/expense_development/get/unit`, body)
      .then((res: any) => {
        setBuildingUnitList(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  const [clusterName, setClusterName] = useState<any>('')
  const [ugPName, setUgPName] = useState<any>('')

  console.log(expData)

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body px-lg-7 py-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '600px',
            maxHeight: '650px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className=' pt-5 mb-0'>
              <b>Select Property</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          <div className='d-flex justify-content-start mt-5'>
            <p className='top ps-0'>Which level is this expense item being recorded on?</p>
          </div>

          <div className='row mt-2'>
            <div className='col-12 col-md-4'>
              <div className='d-flex align-items-center'>
                {selectedLevel === 1 ? (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#146c6a',
                      borderRadius: '50%',
                    }}
                    className='me-5'
                  ></div>
                ) : (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      border: '1px solid #146c6a',
                    }}
                    onClick={() => {
                      setRadioChecked(-1)
                      setSelectedLevel(1)
                    }}
                    className='me-5'
                  ></div>
                )}
                <p className='me-2 mb-0'>Development Expense</p>
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                        {'Record your expense item on an overall development level.'}
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='d-flex align-items-center'>
                {selectedLevel === 2 ? (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#146c6a',
                      borderRadius: '50%',
                    }}
                    className='me-5'
                  ></div>
                ) : (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      border: '1px solid #146c6a',
                    }}
                    onClick={() => {
                      setRadioChecked(-1)
                      setSelectedLevel(2)
                    }}
                    className='me-5'
                  ></div>
                )}
                <p className='me-2 mb-0'> Property Expense</p>
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                        {'Record your expense item on a single unit level.'}
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='d-flex align-items-center'>
                {selectedLevel === 3 ? (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: '#146c6a',
                      borderRadius: '50%',
                    }}
                    className='me-5'
                  ></div>
                ) : (
                  <div
                    style={{
                      height: '16px',
                      width: '16px',
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      border: '1px solid #146c6a',
                    }}
                    onClick={() => {
                      setRadioChecked(-1)
                      setSelectedLevel(3)
                    }}
                    className='me-5'
                  ></div>
                )}

                <p className='me-2 mb-0'>Common Area Expense </p>
                <span>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                        {'Record your expense item on a common area.'}
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                    </span>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
          </div>

          {(selectedLevel == 2 || selectedLevel == 3) && selectedDev?.[0]?.devType == 'build' && (
            <div className='d-flex align-items-center px-0 mt-7'>
              <label className='head-text status-w-100' style={{fontWeight: '600'}}>
                {' '}
                Search{' '}
              </label>

              <div className='input-containerproperty xyz tst ps-0 me-3 job-modl'>
                <Paper component='form' style={searchContainerStyle} className='input-bg-grey'>
                  <InputBase
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchUnit}
                    onChange={(e: any) => {
                      setSearchUnit(e.target.value)
                    }}
                    placeholder={selectedLevel == '2' ? 'Unit' : 'Common Area'}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
          )}

          {(selectedLevel == 2 || selectedLevel == 3) && selectedDev?.[0]?.devType == 'build' ? (
            <div className='d-flex align-items-center px-0 mt-5'>
              <label className='head-text status-w-100' style={{fontWeight: '600'}}>
                {' '}
                Filter{' '}
              </label>

              {/* Item */}
              <div className='test property me-4 '>
                <div
                  className={`multi-select-container property ${
                    selectedFloorGrps.length === 0 ? 'no-value floor' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={floorGrpsLists}
                    value={selectedFloorGrps}
                    onChange={setSelectedFloorGrps}
                    labelledBy='Floow'
                    overrideStrings={{
                      allItemsAreSelected: 'All Floors', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>
              <div className='test property me-4 '>
                {selectedLevel == 2 ? (
                  <div
                    className={`multi-select-container property me-5 mb-0   ${
                      selectedUnits.length === 0 ? 'no-value unit' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={unitsLists}
                      value={selectedUnits}
                      onChange={(e: any) => {
                        setSelectedUnits(e)
                      }}
                      labelledBy='Units'
                      overrideStrings={{
                        allItemsAreSelected: 'All Units',
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className={`multi-select-container property me-5 mb-0   ${
                      selectedUnits.length === 0 ? 'no-value common-area' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={unitsLists}
                      value={selectedUnits}
                      onChange={(e: any) => {
                        setSelectedUnits(e)
                      }}
                      labelledBy='Units'
                      overrideStrings={{
                        allItemsAreSelected: 'All Common Areas',
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Item LIST */}
              {/* <div className='test property me-4 '>
            <div
              className={`multi-select-container ${
                selectedList.length === 0 ? 'no-value expense-items-list' : 'has-value'
              }`}
            >
              <MultiSelect
                options={listOptions}
                value={selectedList}
                onChange={setSelectedList}
                labelledBy='List'
                overrideStrings={{
                  allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                }}
              />
            </div>
          </div> */}
            </div>
          ) : (selectedLevel == 2 || selectedLevel == 3) && selectedDev?.[0]?.devType == 'comm' ? (
            <div className='d-flex align-items-center px-0 mt-7'>
              <label className='head-text status-w-100' style={{fontWeight: '600'}}>
                {' '}
                Filter{' '}
              </label>

              {/* Item */}
              <div className='test property me-4 '>
                {/* Cluster */}
                <div
                  className={`multi-select-container property me-5   ${
                    selectedClusters.length === 0 ? 'no-value clust ' : 'has-value '
                  }`}
                >
                  <MultiSelect
                    options={clustersLists}
                    value={selectedClusters}
                    onChange={(e: any) => {
                      setSelectedClusters(e)
                      if (e?.length == 0) {
                        setUnitGrpsLists([])
                        setSelectedUnitGrps([])
                      }
                    }}
                    labelledBy='Cluster'
                    overrideStrings={{
                      allItemsAreSelected: 'All Clusters',
                    }}
                  />
                </div>
              </div>
              <div className='test property me-4 '>
                {/* Unit Groups */}
                <div
                  className={`multi-select-container property me-5  ${
                    selectedUnitGrps.length === 0 ? 'no-value grpunit ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={unitGrpsLists}
                    value={selectedUnitGrps}
                    onChange={(e: any) => {
                      setSelectedUnitGrps(e)
                    }}
                    labelledBy='Unit Groups'
                    overrideStrings={{
                      allItemsAreSelected: 'All Unit Groups',
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          ></div>
          <div className='d-flex justify-content-center'>
            {(selectedLevel == 1 ||
              ((selectedLevel == 2 || selectedLevel == 3) &&
                radioChecked != -1 &&
                selectedDev?.[0]?.devType == 'build')) && (
              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 select-btn ps-3 d-flex justify-content-center status-w-200  mt-4'
                onClick={(e: any) => {
                  updateDetails(selectedLevel, selectedProperty)
                }}
                // disabled={radioChecked == -1}
              >
                <img src={tick} height={19} width={19} className='me-5' /> {'  '}
                Select
              </button>
            )}
          </div> 
          {(selectedLevel == 2 || selectedLevel == 3) && selectedDev?.[0]?.devType == 'build' ? (
            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className=''>
                  <div className='table-responsive' style={{maxHeight: '330px', overflow: 'auto'}}>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                          <th className='text-start w-25px test'></th>
                          {selectedLevel == 2 && <th className='text-start min-w-125px'>Unit</th>}
                          {selectedLevel == 2 && (
                            <th className='text-start min-w-125px'>Unit Type</th>
                          )}
                          {selectedLevel == 3 && (
                            <th className='text-start min-w-125px'>Common Area</th>
                          )}
                          {selectedLevel == 3 && <th className='text-start min-w-125px'>Type</th>}
                          <th className='text-start min-w-125px'>Floor</th>
                          <th className='text-start min-w-125px'>Development</th>
                        </tr>
                      </thead>
                      <tbody className='fw-semibold text-gray-600'>
                        {buildingUnitList?.length > 0 ? (
                          <>
                            {buildingUnitList?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  <td className='text-start w-25px  test'>
                                    {radioChecked === rs?._id ? (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          backgroundColor: '#146c6a',
                                          borderRadius: '50%',
                                        }}
                                        className='me-5'
                                      ></div>
                                    ) : (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          backgroundColor: 'transparent',
                                          borderRadius: '50%',
                                          border: '1px solid #146c6a',
                                        }}
                                        onClick={() => {
                                          setRadioChecked(rs?._id)
                                          setSelectedProperty(rs)
                                          // setSelectedName(rs?.name)
                                        }}
                                        className='me-5'
                                      ></div>
                                    )}
                                  </td>
                                  {selectedLevel == 2 && (
                                    <td className='text-start min-w-100px'>
                                      {rs?.unitNo ? `${rs?.unitNo}` : '-'}
                                    </td>
                                  )}

                                  {selectedLevel == 2 && (
                                    <td className='text-start min-w-100px'>
                                      {rs?.unitType === 0
                                        ? 'Apartment'
                                        : rs?.unitType === 1
                                        ? 'Penthouse'
                                        : rs?.unitType === 2
                                        ? 'Common Area'
                                        : rs?.unitType === 3
                                        ? 'Townhouse'
                                        : rs?.unitType === 4
                                        ? 'Villa'
                                        : rs?.unitType === 5
                                        ? `Other - ${rs?.otherName}`
                                        : rs?.unitType === 6
                                        ? 'Office'
                                        : rs?.unitType === 7
                                        ? 'Restaurant'
                                        : rs?.unitType === 8
                                        ? 'Cafe'
                                        : rs?.unitType === 9
                                        ? 'Retail'
                                        : '-'}
                                    </td>
                                  )}

                                  {selectedLevel == 3 && (
                                    <td className='text-start min-w-100px'>
                                      {rs?.unitNo ? `${rs?.unitNo}` : '-'}
                                    </td>
                                  )}
                                  {selectedLevel == 3 && (
                                    <td className='text-start min-w-100px'>
                                      {rs?.commonAreaName ? `${rs?.commonAreaName}` : '-'}
                                    </td>
                                  )}
                                  <td className='text-start min-w-100px'>
                                    {rs?.floor?.length > 0 ? `${rs?.floor?.[0]?.name}` : '-'}
                                  </td>
                                  <td className='text-start min-w-100px'>
                                    {/* {expData?.development?.length > 0
                                      ? expData?.development?.[0]?.label
                                      : '-'} */}

                                    {selectedDev?.length > 0 ? selectedDev?.[0]?.label : '-'}
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          <tr>
                            {!isLoading ? (
                              <td colSpan={7} className='text-center'>
                                <img src={noData} alt='' width={350} />
                              </td>
                            ) : (
                              <td colSpan={7} className='text-center'>
                                <div
                                  className='d-flex justify-content-center align-items-center w-100'
                                  style={{height: '25vh'}}
                                >
                                  <CircularProgress style={{color: '#007a59'}} />
                                </div>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (selectedLevel == 2 || selectedLevel == 3) && selectedDev?.[0]?.devType == 'comm' ? (
            <>
              {!goToNext ? (
                <div className='row mt-3'>
                  <div className='col-12 pe-0'>
                    <div className=''>
                      <div
                        className='table-responsive'
                        style={{maxHeight: '300px', overflow: 'auto'}}
                      >
                        <table
                          className='table align-middle  fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                              <th className='text-start w-25px test'></th>
                              <th className='text-start min-w-100px'>Cluster</th>
                              <th className='text-start min-w-100px'>Group</th>
                              <th className='text-start min-w-100px'></th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {clustersForList?.length > 0 ? (
                              <>
                                {clustersForList?.map((rs: any, index: any) => {
                                  return (
                                    <tr>
                                      <td className='text-start w-25px  test'>
                                        {radioChecked === rs?._id ? (
                                          <div
                                            style={{
                                              height: '16px',
                                              width: '16px',
                                              backgroundColor: '#146c6a',
                                              borderRadius: '50%',
                                            }}
                                            className='me-5'
                                          ></div>
                                        ) : (
                                          <div
                                            style={{
                                              height: '16px',
                                              width: '16px',
                                              backgroundColor: 'transparent',
                                              borderRadius: '50%',
                                              border: '1px solid #146c6a',
                                            }}
                                            onClick={() => {
                                              setRadioChecked(rs?._id)
                                              setSelectedProperty(rs)
                                              // setSelectedName(rs?.name)
                                            }}
                                            className='me-5'
                                          ></div>
                                        )}
                                      </td>
                                      <td className='text-start min-w-100px'>
                                        {rs?.name ? `${rs?.name}` : '-'}
                                      </td>
                                      <td className='text-start min-w-100px'>
                                        {rs?.group?.name ? `${rs?.group?.name}` : '-'}
                                      </td>
                                      {selectedLevel == 2 && (
                                        <td className='text-start min-w-100px'>
                                          <button
                                            type='button'
                                            className='btn btn-sm fw-bold  px-2 green-hollow-btn  d-flex justify-content-center'
                                            onClick={() => {
                                              getUnitsForSpecificCluster(rs?._id, rs?._id)
                                              setClusterName(rs?.name)
                                              setUgPName(rs?.group?.name)
                                              setGoToNext(true)
                                            }}
                                            style={{width: '120px'}}
                                          >
                                            Units
                                          </button>
                                        </td>
                                      )}

                                      {selectedLevel == 3 && (
                                        <td className='text-start min-w-100px'>
                                          <button
                                            type='button'
                                            className='btn btn-sm fw-bold  px-2 green-hollow-btn  d-flex justify-content-center'
                                            onClick={() => {
                                              getUnitsForSpecificCluster(rs?._id, rs?.group?._id)
                                              setClusterName(rs?.name)
                                              setUgPName(rs?.group?.name)
                                              setGoToNext(true)
                                            }}
                                            style={{width: '120px'}}
                                          >
                                            Common Areas
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              <tr>
                                {!isLoading ? (
                                  <td colSpan={4} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                ) : (
                                  <td colSpan={4} className='text-center'>
                                    <div
                                      className='d-flex justify-content-center align-items-center w-100'
                                      style={{height: '25vh'}}
                                    >
                                      <CircularProgress style={{color: '#007a59'}} />
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className='d-flex align-items-center mt-3 mb-2'>
                    <div className='d-block'>
                      <h3
                        className='pt-5 mb-0 cursor-pointer'
                        onClick={() => {
                          setGoToNext(false)
                        }}
                      >
                        <b>{'<'} Back</b>
                      </h3>
                    </div>
                  </div>

                  <div className='d-flex align-items-center mt-3 mb-2'>
                    <h3 className='pt-5 mb-0 ps-1'>
                      <b>
                        {clusterName} {' - '} {ugPName} {radioChecked}
                      </b>
                    </h3>
                  </div>

                  <div className='d-flex justify-content-center'>
                    {(selectedLevel == 2 || selectedLevel == 3) &&
                      radioChecked != -1 &&
                      selectedDev?.[0]?.devType == 'comm' && (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold ms-3 select-btn ps-3 d-flex justify-content-center status-w-200  mt-4'
                          onClick={(e: any) => {
                            updateDetails(selectedLevel, selectedProperty)
                          }}
                          // disabled={radioChecked == -1}
                        >
                          <img src={tick} height={19} width={19} className='me-5' /> {'  '}
                          Select
                        </button>
                      )}
                  </div>

                  <div className='row mt-3'>
                    <div className='col-12 pe-0'>
                      <div className=''>
                        <div
                          className='table-responsive'
                          style={{maxHeight: '300px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-middle  fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <tbody className='fw-semibold text-gray-600'>
                              {buildingUnitList?.length > 0 ? (
                                <>
                                  <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                                      <th className='text-start w-25px test'></th>
                                      {selectedLevel == 2 && (
                                        <th className='text-start min-w-100px'>Unit</th>
                                      )}
                                      {selectedLevel == 3 && (
                                        <th className='text-start min-w-100px'>Common Areas</th>
                                      )}
                                    </tr>
                                  </thead>
                                  {buildingUnitList?.map((rs: any, index: any) => {
                                    return (
                                      <tr>
                                        <td className='text-start w-25px  test'>
                                          {radioChecked === rs?._id ? (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: '#146c6a',
                                                borderRadius: '50%',
                                              }}
                                              className='me-5'
                                            ></div>
                                          ) : (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: 'transparent',
                                                borderRadius: '50%',
                                                border: '1px solid #146c6a',
                                              }}
                                              onClick={() => {
                                                setRadioChecked(rs?._id)
                                                setSelectedProperty(rs)
                                              }}
                                              className='me-5'
                                            ></div>
                                          )}
                                        </td>

                                        {selectedLevel == 2 && (
                                          <td className='text-start min-w-100px'>
                                            {rs?.unitNo ? `${rs?.unitNo}` : '-'}
                                          </td>
                                        )}

                                        {selectedLevel == 3 && (
                                          <td className='text-start min-w-100px'>
                                            {rs?.commonAreaName ? `${rs?.commonAreaName}` : '-'}
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                <tr>
                                  {!isLoading ? (
                                    <td colSpan={2} className='text-center'>
                                      <img src={noData} alt='' width={350} />
                                    </td>
                                  ) : (
                                    <td colSpan={2} className='text-center'>
                                      <div
                                        className='d-flex justify-content-center align-items-center w-100'
                                        style={{height: '25vh'}}
                                      >
                                        <CircularProgress style={{color: '#007a59'}} />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default SelectProperty

import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import payIncome from '../../../img/download.png'
import backArrow from '../../../img/back-arrow.png'
import greenPlus from '../../../img/add.png'
import './../settings/style.scss'
import moment from 'moment'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'

const PropertyIncomeItemsComm = () => {
  const navigate = useNavigate()

  const [communities, setCommunities] = useState<any>()

  // list of communities
  const getCommunities = () => {
    const body = {
      limit: 10,
      page: 1,
    }
    ApiPost(`corporate/communities/payment_item_list`, body)
      .then((res) => {
        setCommunities(res?.data?.data?.community_data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getCommunities()
  }, [])
  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='container-xxl pt-0 mt-0 px-4' style={{padding: '0px 14px'}}>
        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/settings')}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='18px' width='18px' />
            </span>
            <h1 className='page-heading m-0 head-text'>Payment Items (Income)</h1>
          </div>

          <div className='ms-auto me-1'>
            <img src={payIncome} height='35px' width='30px' />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/buildings-income-items')}
              >
                Buildings
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/communities-income-items')}
              >
                Communities
              </a>
            </li>
          </ul>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 mt-5 px-3'>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0 fs-5'>
                  <th className='min-w-100px'>Development Name</th>

                  <th className='min-w-100px'>Total Properties</th>
                  <th className='min-w-100px'>Available Items</th>
                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
                {communities &&
                  communities.map((build: any, index: any) => {
                    return (
                      <tr>
                        <td className=''>{build?.name}</td>

                        <td className=''>{build?.totalProperties}</td>
                        <td className=''>{build?.totalPaymentSubList}</td>

                        <td className=''>
                          {' '}
                          <div
                            onClick={() =>
                              navigate('/settings/property-income-items/' + build._id, {
                                state: {development: 'community', devName: build.name},
                              })
                            }
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                          >
                            View
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyIncomeItemsComm

import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import './style.scss'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

interface ButtonProps {
  roleData: any
  from: any
  updateRoleDate: any
}

const AccountManagement = ({roleData, from, updateRoleDate}: ButtonProps) => {
  const [accManage, setAccManage] = useState<any>(roleData?.featureList?.account_management)

  useEffect(() => {
    let values = {...roleData}
    values.featureList.account_management = accManage
    updateRoleDate(values)
  }, [accManage])
  
  return (
    <>
      <div id='' className='pt-0 mt-0 px-5'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead>
              {/* <th className='text-start min-w-25px'></th> */}
              <th className='text-start min-w-300px m-w-300'></th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Allow
                <span>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can disable/enable 2-step Auth from their user profile/settings
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
            </thead>

            <tbody className='fw-semibold text-gray-600 role-test'>
              <tr>
                {/* <td className='text-start ps-0'>
                  <img
                    src={redCross}
                    height={16}
                    width={16}
                    className='me-2 cursor-pointer'
                    onClick={() => {}}
                  />
                </td> */}

                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1 white-dark-theme-color' style={{fontWeight: '700'}}>
                    2-Step Authentication{' '}
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>Enable/disable 2-step Auth from profile/settings.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={accManage?.two_step_verification}
                      onChange={(e: any) => {
                        if (from !== 'view') {
                          const values = {...accManage}
                          values.two_step_verification = e.target.checked
                          setAccManage(values)
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>

              <tr style={{borderBottom: 'none', height: '35px'}}>
                {/* <td className='text-start min-w-25px user-th'></td> */}
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can request to re-set their password at any time from their user
                          profile/settings.
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                {/* <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                          }}
                        />
                      )}
                    </td> */}
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1 white-dark-theme-color' style={{fontWeight: '700'}}>
                    Reset Password{' '}
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>Reset password from user profile/settings.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={accManage?.reset_password}
                      onChange={(e: any) => {
                        if (from !== 'view') {
                          const values = {...accManage}
                          values.reset_password = e.target.checked
                          setAccManage(values)
                        }
                      }}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>

              <tr style={{borderBottom: 'none', height: '35px'}}>
                {/* <td className='text-start min-w-25px user-th'></td> */}
                <td className='text-start min-w-300px m-w-300 user-th'></td>

                <td className='text-start min-w-100px text-capitalize user-th ps-0'>
                  {' '}
                  Allow{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                          {' '}
                          User can re-set and change/update their Email ID at anytime from their
                          user profile/settings
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <AiOutlineExclamationCircle className='ms-2 cursor-pointer mb-1 exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td className='text-start min-w-125px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-75px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
                <td className='text-start min-w-100px text-capitalize user-th'></td>
              </tr>
              <tr>
                {/* <td className='text-start ps-0'>
                      {from != 'add' && from != 'view' && (
                        <img
                          src={redCross}
                          height={16}
                          width={16}
                          className='me-2 cursor-pointer'
                          onClick={() => {
                          }}
                        />
                      )}
                    </td> */}
                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1 white-dark-theme-color' style={{fontWeight: '700'}}>
                    Reset Email ID{' '}
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i> Re-set and change/update Email ID.</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={accManage?.reset_email_id}
                      onChange={(e: any) => {
                        if (from !== 'view') {
                          const values = {...accManage}
                          values.reset_email_id = e.target.checked
                          setAccManage(values)
                        }
                      }}
                    />
                  </div>
                </td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
                <td className='text-center ps-0'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* <div id='' className='pt-5 mt-0 px-5'>
        <h3>Account Management</h3>
        <p>
          - 2-Step Authentication - 'Allow' Permission (will allow the user to disable/enable 2-step
          Auth from their user profile/settings)
        </p>
        <p>
          - Reset Password - 'Allow' Permission (Will allow the user to request to re-set their
          password at any time from their user profile/settings [Reset Password] CTA)
        </p>
        <p>
          - Reset Email ID - 'Allow' Permission (Will allow the user to re-set and change/update
          their Email ID at anytime from their user profile/settings)
        </p>
      </div> */}
    </>
  )
}

export default AccountManagement

import React, {useState} from 'react'
import ApexCharts from 'react-apexcharts'
import averageRent from '../../../img/rentAverage.png'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

interface vacancyRateProps {
  chartData?: any
}

const AverageRent: React.FC<vacancyRateProps> = ({chartData}) => {
  const overAllCost = '45000'
  const costPerSqFt = '12'
  const costPerSqMt = '20'
  return (
    <div className='card card-flush py-5 px-7 h-100' style={{width: '275px'}}>
      <div className='ms-auto'>
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 200}}
          overlay={
            <Tooltip id='button-tooltip-2'>
              {' '}
              The average rent per square foot & square meter of your rental properties.
            </Tooltip>
          }
        >
          <span>
            {' '}
            <AiOutlineExclamationCircle
              color='#35475e'
              fontSize={14}
              fontWeight={500}
              className='cursor-pointer'
              style={{cursor: 'pointer', marginBottom: '2px'}}
            />
          </span>
        </OverlayTrigger>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-between h-100 text-center'>
        <div style={{marginTop: '5px'}}>
          <span style={{fontSize: '16px', margin: '0px'}}>AED</span>
          <span style={{fontSize: '18px', margin: '0px', fontWeight: 'bolder', marginLeft: '3px'}}>
            {/* {Number.isInteger(chartData?.averagePrice) ? 
                  parseFloat(chartData?.averagePrice)?.toLocaleString('en-US')
                : parseFloat(chartData?.averagePrice)?.toLocaleString('en-US', {
                  minimumFractionDigits: 1,
                })} */}

            {!isNaN(parseFloat(chartData?.averagePrice))
              ? Number.isInteger(chartData?.averagePrice)
                ? parseFloat(chartData?.averagePrice)?.toLocaleString('en-US')
                : parseFloat(chartData?.averagePrice)?.toLocaleString('en-US', {
                    maximumFractionDigits: 1,
                  })
              : ''}
          </span>
          <p style={{fontSize: '16px', margin: '0px'}}>Overall</p>
          <div className='d-flex justify-content-between mt-2' style={{columnGap: '15px'}}>
            <div>
              <span style={{fontSize: '16px', margin: '0px'}}>AED</span>
              <span
                style={{fontSize: '18px', margin: '0px', fontWeight: 'bolder', marginLeft: '3px'}}
              >
                {!isNaN(parseFloat(chartData?.averageAreaSqft))
                  ? Number.isInteger(chartData?.averageAreaSqft)
                    ? parseFloat(chartData?.averageAreaSqft)?.toLocaleString('en-US')
                    : parseFloat(chartData?.averageAreaSqft)?.toLocaleString('en-US', {
                        maximumFractionDigits: 1,
                      })
                  : ''}
              </span>
              <p className='text-end'>Per Sqft</p>
            </div>
            <div>
              <span style={{fontSize: '16px', margin: '0px'}}>AED</span>
              <span
                style={{fontSize: '18px', margin: '0px', fontWeight: 'bolder', marginLeft: '3px'}}
              >
                {!isNaN(parseFloat(chartData?.averageAreaSqm))
                  ? Number.isInteger(chartData?.averageAreaSqm)
                    ? parseFloat(chartData?.averageAreaSqm)?.toLocaleString('en-US')
                    : parseFloat(chartData?.averageAreaSqm)?.toLocaleString('en-US', {
                        maximumFractionDigits: 1,
                      })
                  : ''}
              </span>
              <p className='text-end'>
                Per m<sup>2</sup>
              </p>
            </div>
          </div>
          <div>
            <img src={averageRent} width={45} height={45} className='mt-2' />
          </div>
        </div>
        <div className='d-flex justify-content-center mt-auto'>
          <p className='fnt-500 fs-3 mb-3'>Average Rent</p>
        </div>
      </div>
    </div>
  )
}

export default AverageRent

import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import backArrow from '../../../../img/back-arrow.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'
import unitClusterIcon from '../../../../img/unitClusterIcon.png'
import buildingClusterIcon from '../../../../img/buildingCluster.png'
import mixedClusterIcon from '../../../../img/mixedCluster.png'
import communityBlue from '../../../../img/communityBlue.png'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import GroupList from './GroupList'
import CommonAreaList from './CommonAreaList'
import AllUnitList from './AllUnitList'
import BuildingSubList from './BuildingSubList'
import BuildingList from './BuildingList'

interface ButtonProps {
  type: any
}

const UnitClusterDetailList = ({type}: ButtonProps) => {
  const getUnitClusterAllFilter: any = localStorage.getItem('unitClusterAllFilters')
  const unitClusterAllFilter = JSON.parse(getUnitClusterAllFilter)

  const getBuildingClusterAllFilter: any = localStorage.getItem('buildingClusterAllFilters')
  const buildingClusterAllFilter = JSON.parse(getBuildingClusterAllFilter)

  const getMixedClusterAllFilter: any = localStorage.getItem('mixedClusterAllFilters')
  const mixedClusterAllFilter = JSON.parse(getMixedClusterAllFilter)

  const getSubTabListItemForUnitCluster: any = localStorage.getItem('subTabListItemForunitCluster')
  const unitClusterTab = JSON.parse(getSubTabListItemForUnitCluster)

  const getSubTabListItemForBuildingCluster: any = localStorage.getItem('subTabListItemForbuildingCluster')
  const buildingClusterTab = JSON.parse(getSubTabListItemForBuildingCluster)

  const getSubTabListItemForMixedCluster: any = localStorage.getItem('subTabListItemFormixedCluster')
  const mixedClusterTab = JSON.parse(getSubTabListItemForMixedCluster)

  const getUnitClusterResidentialDateFilter: any = localStorage.getItem('unitClusterResidentialDateFilter')
  const unitClusterResidentialDateFilter = JSON.parse(getUnitClusterResidentialDateFilter) ?? {}

  const getBuildingClusterResidentialDate: any = localStorage.getItem('buildingClusterResidentialDateFilter')
  const buildingClusterResidentialDateFilter = JSON.parse(getBuildingClusterResidentialDate) ?? {}

  const getMixedClusterResidentialDate: any = localStorage.getItem('mixedClusterResidentialDateFilter')
  const mixedClusterResidentialDateFilter = JSON.parse(getMixedClusterResidentialDate) ?? {}

  const getUnitClusterAllUnitsDateFilter: any = localStorage.getItem('unitClusterAllUnitsDateFilter')
  const unitClusterAllUnitsDateFilter = JSON.parse(getUnitClusterAllUnitsDateFilter) ?? {}

  const getBuildingClusterAllUnitsDateFilter: any = localStorage.getItem('buildingClusterAllUnitsDateFilter')
  const buildingClusterAllUnitsDateFilter = JSON.parse(getBuildingClusterAllUnitsDateFilter) ?? {}

  const getMixedClusterAllUnitsDateFilter: any = localStorage.getItem('mixedClusterAllUnitsDateFilter')
  const mixedClusterAllUnitsDateFilter = JSON.parse(getMixedClusterAllUnitsDateFilter) ?? {}

  const getUnitClusterCommercialDateFilter: any = localStorage.getItem('unitClusterCommercialDateFilter')
  const unitClusterCommercialDateFilter = JSON.parse(getUnitClusterCommercialDateFilter) ?? {}

  const getBuildingClusterCommercialDateFilter: any = localStorage.getItem('buildingClusterCommercialDateFilter')
  const buildingClusterCommercialDateFilter = JSON.parse(getBuildingClusterCommercialDateFilter) ?? {}

  const getMixedClusterCommercialDateFilter: any = localStorage.getItem('mixedClusterCommercialDateFilter')
  const mixedClusterCommercialDateFilter = JSON.parse(getMixedClusterCommercialDateFilter) ?? {}

  const getUnitClusterCommonAreaDateFilter: any = localStorage.getItem('unitClusterCommonAreaDateFilter')
  const unitClusterCommonAreaDateFilter = JSON.parse(getUnitClusterCommonAreaDateFilter) ?? {}

  const getBuildingClusterCommonAreaDateFilter: any = localStorage.getItem('buildingClusterCommonAreaDateFilter')
  const buildingClusterCommonAreaDateFilter = JSON.parse(getBuildingClusterCommonAreaDateFilter) ?? {}

  const getMixedClusterCommonAreaDateFilter: any = localStorage.getItem('mixedClusterCommonAreaDateFilter')
  const mixedClusterCommonAreaDateFilter = JSON.parse(getMixedClusterCommonAreaDateFilter) ?? {}

  const getBuildingClusterBuildingDateFilter: any = localStorage.getItem('buildingClusterBuildingDateFilter')
  const buildingClusterBuildingDateFilter = JSON.parse(getBuildingClusterBuildingDateFilter) ?? {}

  const getMixedClusterGroupDateFilter: any = localStorage.getItem('mixedClusterGroupDateFilter')
  const mixedClusterGroupDateFilter = JSON.parse(getMixedClusterGroupDateFilter) ?? {}

  const tab =
    type === 'unitCluster' ? unitClusterTab : type === 'buildingCluster' ? buildingClusterTab : type === 'mixedCluster' ? mixedClusterTab : ''
  const searchU =
    type === 'unitCluster'
      ? unitClusterAllFilter?.searchUnit
      : type === 'buildingCluster'
      ? buildingClusterAllFilter?.searchUnit
      : type === 'mixedCluster'
      ? mixedClusterAllFilter?.searchUnit
      : ''
  const searchR =
    type === 'unitCluster'
      ? unitClusterAllFilter?.searchResidentialUnit
      : type === 'buildingCluster'
      ? buildingClusterAllFilter?.searchResidentialUnit
      : type === 'mixedCluster'
      ? mixedClusterAllFilter?.searchResidentialUnit
      : ''
  const searchComm =
    type === 'unitCluster'
      ? unitClusterAllFilter?.searchCommercialUnit
      : type === 'buildingCluster'
      ? buildingClusterAllFilter?.searchCommercialUnit
      : type === 'mixedCluster'
      ? mixedClusterAllFilter?.searchCommercialUnit
      : ''
  const searchCA =
    type === 'unitCluster'
      ? unitClusterAllFilter?.searchCommonAreaUnit
      : type === 'buildingCluster'
      ? buildingClusterAllFilter?.searchCommonAreaUnit
      : type === 'mixedCluster'
      ? mixedClusterAllFilter?.searchCommonAreaUnit
      : ''
  const searchG = type === 'mixedCluster' ? mixedClusterAllFilter?.searchGroup : ''
  const searchD = type === 'buildingCluster' ? buildingClusterAllFilter?.searchDevelopment : ''

  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>(tab ? tab : 'allUnits')

  const [searchUnit, setSearchUnit] = useState<any>(searchU ?? '')
  const [searchDevelopment, setSearchDevelopment] = useState<any>(searchD ?? '')
  const [searchGroup, setSearchGroup] = useState<any>(searchG ?? '')
  const [searchResidentialUnit, setSearcResidentialhUnit] = useState<any>(searchR ?? '')
  const [searchCommercialUnit, setSearchCommercialUnit] = useState<any>(searchComm ?? '')
  const [searchCommonAreaUnit, setSearchCommonAreaUnit] = useState<any>(searchCA ?? '')
  const [searchValue, setSearchValue] = useState<any>(searchU ?? '')
  const [searchValueResidential, setSearchValueResidential] = useState<any>(searchR ?? '')
  const [searchValueCommercial, setSearchValueCommercial] = useState<any>(searchComm ?? '')
  const [searchValueCommonArea, setSearchValueCommonArea] = useState<any>(searchCA ?? '')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const location: any = useLocation()

  const isBuilding: any = location.state ? location?.state?.key === 'floors' : false
  const floorDetail: any = location?.state

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)
  const [isEditFloorModel, setIsEditFloorModel] = useState<any>(false)

  const [modelData, setModelData] = useState<any>({
    floorName: location?.state?.clusterName,
    floorId: location?.state?.clusterId,
  })

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const setDatesBasedOnSubTab = (datesObject: any, formattedStartDate: any, formattedEndDate: any) => {
    if (Object.keys(datesObject).length > 0) {
      if (Object.values(datesObject).every((x) => x !== null || x !== '')) {
        setStart(moment(datesObject?.startDate))
        setEnd(moment(datesObject?.endDate))
        setStartDatePayload(moment(datesObject?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(datesObject?.endDate)?.format('YYYY-MM-DD'))
        return
      }
    }

    // If the conditions above are not met, set default start and end dates
    setStart(moment(formattedStartDate, 'DD/MM/YYYY'))
    setEnd(moment(formattedEndDate, 'DD/MM/YYYY'))
    setStartDatePayload(moment(formattedStartDate)?.format('YYYY-MM-DD'))
    setEndDatePayload(moment(formattedEndDate)?.format('YYYY-MM-DD'))
  }

  function formatDate(date: any) {
    const day = ('0' + date.getDate()).slice(-2) // Get day with leading zero if needed
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Get month with leading zero if needed
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  function generateDates() {
    // Create start date for the year (1st January)
    const currentDate = new Date()
    const year: any = currentDate.getFullYear()
    const startDate: any = new Date(year, 0, 1)

    // Create end date for the year (31st December)
    const endDate = new Date(year, 11, 31)

    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate)

    if (type === 'unitCluster') {
      switch (subTabListItem) {
        case 'residentialUnits':
          setDatesBasedOnSubTab(unitClusterResidentialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'allUnits':
          setDatesBasedOnSubTab(unitClusterAllUnitsDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commercialUnits':
          setDatesBasedOnSubTab(unitClusterCommercialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commonAreas':
          setDatesBasedOnSubTab(unitClusterCommonAreaDateFilter, formattedStartDate, formattedEndDate)
          break
        default:
          // Handle default case
          break
      }
    }
    if (type === 'buildingCluster') {
      switch (subTabListItem) {
        case 'residentialUnits':
          setDatesBasedOnSubTab(buildingClusterResidentialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'allUnits':
          setDatesBasedOnSubTab(buildingClusterAllUnitsDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commercialUnits':
          setDatesBasedOnSubTab(buildingClusterCommercialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commonAreas':
          setDatesBasedOnSubTab(buildingClusterCommonAreaDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'buildings':
          setDatesBasedOnSubTab(buildingClusterBuildingDateFilter, formattedStartDate, formattedEndDate)
          break
        default:
          // Handle default case
          break
      }
    }
    if (type === 'mixedCluster') {
      switch (subTabListItem) {
        case 'residentialUnits':
          setDatesBasedOnSubTab(mixedClusterResidentialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'allUnits':
          setDatesBasedOnSubTab(mixedClusterAllUnitsDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commercialUnits':
          setDatesBasedOnSubTab(mixedClusterCommercialDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'commonAreas':
          setDatesBasedOnSubTab(mixedClusterCommonAreaDateFilter, formattedStartDate, formattedEndDate)
          break
        case 'group':
          setDatesBasedOnSubTab(mixedClusterGroupDateFilter, formattedStartDate, formattedEndDate)
          break
        default:
          // Handle default case
          break
      }
    }
  }

  const handleDatesChange = ({startDate, endDate}: {startDate: moment.Moment | null; endDate: moment.Moment | null}) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  const goToMiantenance = (v: any) => {
    //  type 1 = building cluster
    if (type === 'buildingCluster' && subTabListItem === 'buildings') {
      navigate(`/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${v?._id}`, {
        state: {
          type: location?.state?.type,
          clusterName: location?.state?.clusterName,
          communityId: location?.state?.communityId,
          clusterId: location?.state?.clusterId,
          communityName: location?.state?.communityName,
          buildingName: v?.name,
          buildingId: v?._id,
          area: location?.state?.area,
          city: location?.state?.city,
          from:'buildingCluster'
        },
      })
    }
    //  type 2 = mixed cluster
    if (type === 'mixedCluster' && subTabListItem === 'group') {
      if (v?.isBuilding == 'true') {
        navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${v?._id}`, {
          state: {
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            groupName: v?.name,
            groupId: v?._id,
            groupType: 'building',
            area: location?.state?.area,
            city: location?.state?.city,
            from:'buildingGroup'
          },
        })
      }
      if (v?.isUnitGroup == 'true') {
        navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${v?._id}`, {
          state: {
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            groupName: v?.name,
            groupId: v?._id,
            groupType: 'unitGroup',
            area: location?.state?.area,
            city: location?.state?.city,
             from:'unitGroup'
          },
        })
      }
      
      
  }

  if(subTabListItem === 'allUnits' ||
    subTabListItem === 'residentialUnits' ||
    subTabListItem === 'commercialUnits' ||
    subTabListItem === 'commonAreas'){
    navigate(`/property-profile/${v?._id}`,
      {
        state: {
          from:location?.state.from !== undefined ? location?.state.from : '',
          type: location?.state?.type,
          clusterName: location?.state?.clusterName,
          communityId: location?.state?.communityId,
          clusterId: location?.state?.clusterId,
          communityName: location?.state?.communityName,
          area: v?.community[0]?.area,
          city: v?.community[0]?.city,
          street: v?.community[0]?.street,
          isCommonArea:v?.unitType == 2 ? true : false
        }
      }
    )
  }
}

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [allPropertiesTableData, setAllPropertiesTableData] = useState<any>([])
  const [allBuildingTableData, setAllBuildingTableData] = useState<any>([])
  const [unitGroupTableData, setUnitGroupTableData] = useState<any>([])
  const [limit, setLimit] = useState<any>(25)
  const [unitCountSummery, setUnitCountSummary] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>(false)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    if (subTabListItem === 'buildings') {
        getAllBuildingTableData(event.selected + 1)
      }
      if (subTabListItem === 'group') {
        getUnitGroupTableData(event.selected + 1)
      }
      if (['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some((unit: string) => unit === subTabListItem)) {
        getAllPropertiesTableData(event.selected + 1)
      }
  }

  const unitCountSummary = async () => {
    const body = {
      isAllPropertise: false,
      isForBuildingDevelopment: false,
      isForCommunityDevelopment: true,
      unit: {
        propertyType:
          subTabListItem === 'allUnits'
            ? [0, 1]
            : subTabListItem === 'residentialUnits'
            ? [0]
            : subTabListItem === 'commercialUnits'
            ? [1]
            : subTabListItem === 'commonAreas'
            ? [0, 1]
            : [],
        unitType:
          subTabListItem === 'allUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'residentialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'commercialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem == 'commonAreas'
            ? [2]
            : subTabListItem === 'group' ? [0,1,2,3,4,5]
            : [],
        searchUnitNo:
          subTabListItem === 'allUnits'
            ? searchUnit
            : subTabListItem === 'residentialUnits'
            ? searchResidentialUnit
            : subTabListItem === 'commercialUnits'
            ? searchCommercialUnit
            : subTabListItem == 'commonAreas'
            ? searchCommonAreaUnit
            : subTabListItem === 'buildings'
            ? searchDevelopment
            : subTabListItem === 'group'
            ? searchGroup
            : '',
      },
      cluster: {
        type: type === 'unitCluster' ? [0] : type === 'buildingCluster' ? [1] : type === 'mixedCluster' ? [2] : [],
      },
      communityDevelopmentIds: [location?.state?.communityId],
      clusterId: [location?.state?.clusterId],
      startDate: startDatePayload,
      endDate: endDatePayload,
    }
    await ApiPost('corporate/development/properties/unit_count', body)
      .then((res) => {
        setUnitCountSummary(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getUnitGroupTableData = async (pg = 1) => {
    setIsLoading(true)
    setUnitGroupTableData([])
    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload,
      endDate: endDatePayload,
      search: searchGroup,
      communityIds: [location?.state?.communityId],
      clusterIds: [location?.state?.clusterId],
    }

    await ApiPost('corporate/unit_group/get_properties', body)
      .then((res) => {
        setUnitGroupTableData(res?.data?.data?.unit_group_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getAllBuildingTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllBuildingTableData([])
    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload,
      endDate: endDatePayload,
      search: searchDevelopment,
      buildingIds: [],
      communityIds: [location?.state?.communityId],
      clusterIds: [location?.state?.clusterId],
    }

    await ApiPost('corporate/building/get_properties', body)
      .then((res) => {
        setAllBuildingTableData(res?.data?.data?.building_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getAllPropertiesTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllPropertiesTableData([])

    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload,
      endDate: endDatePayload,
      searchUnitNo:
        subTabListItem === 'allUnits'
          ? searchUnit
          : subTabListItem === 'residentialUnits'
          ? searchResidentialUnit
          : subTabListItem === 'commercialUnits'
          ? searchCommercialUnit
          : subTabListItem == 'commonAreas'
          ? searchCommonAreaUnit
          : '',
      propertyTypes:
        subTabListItem === 'allUnits' ? [0, 1] : subTabListItem === 'residentialUnits' ? [0] : subTabListItem === 'commercialUnits' ? [1] : [0, 1],
      unitTypes:
        subTabListItem === 'allUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'residentialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'commercialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem == 'commonAreas'
          ? [2]
          : [],
      buildingIds: [],
      communityIds: [location?.state?.communityId],
      clusterIds: [location?.state?.clusterId],
    }

    await ApiPost('corporate/unit/get_properties', body)
      .then((res) => {
        setAllPropertiesTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    unitCountSummary()
  }, [
    startDatePayload,
    endDatePayload,
    subTabListItem,
    searchCommercialUnit,
    searchCommonAreaUnit,
    searchResidentialUnit,
    searchUnit,
    searchDevelopment,
    searchGroup,
  ])

  useEffect(() => {
    generateDates()
    setPage(1)
  }, [subTabListItem])

  useEffect(() => {
    if (subTabListItem === 'buildings') {
        setPage(1)
      getAllBuildingTableData(1)
    }
    if (subTabListItem === 'group') {
        setPage(1)
      getUnitGroupTableData(1)
    }
    if (['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some((unit: string) => unit === subTabListItem)) {
        setPage(1)
      getAllPropertiesTableData(1)
    }
  }, [
    searchUnit,
    searchResidentialUnit,
    searchGroup,
    searchCommercialUnit,
    searchCommonAreaUnit,
    start_date,
    end_date,
    startDatePayload,
    endDatePayload,
    subTabListItem,
    searchDevelopment,
  ])

  const dateRange =
    start_date !== null && end_date !== null
      ? {
          startDate: start_date,
          endDate: end_date,
        }
      : null

  const setLocalStorageItem = (keyPrefix: any, subTabListItem: any) => {
    if (dateRange !== null) {
      localStorage.setItem(`${keyPrefix}${subTabListItem}DateFilter`, JSON.stringify(dateRange))
    }
  }

  const setLocalStorageForCluster = (type: any, filters: any, subTabListItem: any) => {
    switch (subTabListItem) {
      case 'residentialUnits':
        setLocalStorageItem(type, 'Residential')
        break
      case 'allUnits':
        setLocalStorageItem(type, 'AllUnits')
        break
      case 'commercialUnits':
        setLocalStorageItem(type, 'Commercial')
        break
      case 'commonAreas':
        setLocalStorageItem(type, 'CommonArea')
        break
      case 'buildings':
        setLocalStorageItem(type, 'Building')
        break
      case 'group':
        setLocalStorageItem(type, 'Group')
        break
      default:
        // Handle default case
        break
    }

    localStorage.setItem(`${type}AllFilters`, JSON.stringify(filters))
    localStorage.setItem(`subTabListItemFor${type}`, JSON.stringify(subTabListItem))
  }

  useEffect(() => {
    if (type === 'unitCluster' || type === 'buildingCluster' || type === 'mixedCluster') {
      const filters = {
        searchUnit: searchUnit,
        searchResidentialUnit: searchResidentialUnit,
        searchCommercialUnit: searchCommercialUnit,
        searchCommonAreaUnit: searchCommonAreaUnit,
        searchDevelopment: searchDevelopment,
        searchGroup: searchGroup,
      }
      setLocalStorageForCluster(
        type === 'unitCluster' ? 'unitCluster' : type === 'buildingCluster' ? 'buildingCluster' : 'mixedCluster',
        filters,
        subTabListItem
      )
    }
  }, [
    subTabListItem,
    searchCommercialUnit,
    searchCommonAreaUnit,
    searchResidentialUnit,
    searchUnit,
    start_date,
    end_date,
    searchDevelopment,
    searchGroup,
  ])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const goToEdit = (listItem: any) => {
    if (type == 'unitCluster') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}/edit-property/${listItem._id}`,
        {
          state: {
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            area: listItem?.community[0]?.area,
            city: listItem?.community[0]?.city,
            street: listItem?.community[0]?.street,
          },
        }
      )
    }
    if (type == 'buildingCluster') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/edit-property/${listItem._id}`,
        {
          state: {
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            area: listItem?.community[0]?.area,
            city: listItem?.community[0]?.city,
            street: listItem?.community[0]?.street,
          },
        }
      )
    }
    if (type == 'mixedCluster') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/edit-property/${listItem._id}`,
        {
          state: {
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            area: listItem?.community[0]?.area,
            city: listItem?.community[0]?.city,
            street: listItem?.community[0]?.street,
          },
        }
      )
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (activeTab === 'all-properties' && subTabListItem == 'allUnits') {
        setSearchUnit(searchValue)
      }
      if (activeTab === 'all-properties' && subTabListItem == 'residentialUnits') {
        setSearcResidentialhUnit(searchValueResidential)
      }
      if (activeTab === 'all-properties' && subTabListItem === 'commercialUnits') {
        setSearchCommercialUnit(searchValueCommercial)
      }
      if (activeTab === 'all-properties' && subTabListItem == 'commonAreas') {
        setSearchCommonAreaUnit(searchValueCommonArea)
      }
    }, 500) // delay time in milliseconds

    return () => {
      clearTimeout(handler)
    }
  }, [searchValue, searchValueResidential, searchValueCommercial, searchValueCommonArea])

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-6'>
            <div className='row d-flex align-items-center'>
              <div className='col-7'>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle'
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      navigate(`/properties/community-details/${location?.state?.communityId}`, {
                        state: {
                          type: location?.state?.type,
                          clusterName: location?.state?.clusterName,
                          communityId: location?.state?.communityId,
                          clusterId: location?.state?.clusterId,
                          communityName: location?.state?.communityName,
                          area: location?.state?.area,
                          city: location?.state?.city,
                        },
                      })
                    }
                  >
                    <img src={backArrow} height='14' width='14' className='me-2' />
                    <img
                      src={
                        type === 'unitCluster'
                          ? unitClusterIcon
                          : type === 'buildingCluster'
                          ? buildingClusterIcon
                          : type === 'mixedCluster'
                          ? mixedClusterIcon
                          : ''
                      }
                      width='40'
                    />
                  </span>
                  <h2 className='m-0 head-text ms-3'>{modelData?.floorName ? `${modelData?.floorName}` : `-`}</h2>
                </div>
              </div>
              <div className='col-5'>
                {/* <img src={editPen} style={{ cursor: 'pointer' }} width='20' onClick={() => {
                                    setAddFloorModel(true)
                                    setIsEditFloorModel(true)
                                }} /> */}
              </div>
            </div>
          </div>
          <div className='col-lg-6 d-flex justify-content-end'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center'>
                <img src={communityBlue} width='40' className='me-2' />
                <h2 className='m-0 head-text ms-2'>
                  {location?.state?.communityName ? location?.state?.communityName : '-'}
                  <div className='d-flex justify-content-between mt-2'>
                    <h4 className='m-0 head-text ms-2'>{location?.state?.area ? location?.state?.area : '-'}</h4>
                    <h4 className='m-0 head-text ms-2'>{location?.state?.city ? location?.state?.city : '-'}</h4>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-start pb-5 mb-5' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>

        {/* sub tabs start */}

        <div className=' ps-0 row mt-2'>
          <div className='col-9'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'allUnits' && 'active'}`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('allUnits')
                  }}
                >
                  All Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'residentialUnits' && 'active'}`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('residentialUnits')
                  }}
                >
                  Residential Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commercialUnits' && 'active'}`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commercialUnits')
                  }}
                >
                  Commercial Units
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commonAreas' && 'active'}`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commonAreas')
                  }}
                >
                  Common Areas
                </a>
              </li>
              {type === 'buildingCluster' && (
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'buildings' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('buildings')
                    }}
                  >
                    Buildings
                  </a>
                </li>
              )}
              {type === 'mixedCluster' && (
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'group' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('group')
                    }}
                  >
                    Group
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className='col-lg-3'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-end'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn'
                  onClick={() => {
                    if (type === 'unitCluster') {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}/add-property`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area: location?.state?.area,
                            city: location?.state?.city,
                          },
                        }
                      )
                    } else if (type === 'buildingCluster') {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/add-property`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area: location?.state?.area,
                            city: location?.state?.city,
                          },
                        }
                      )
                    } else {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/add-property`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area: location?.state?.area,
                            city: location?.state?.city,
                          },
                        }
                      )
                    }
                  }}
                >
                  <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* sub tabs end */}

        {/* ingights start and filter */}
        <div className='row mt-7'>
          <div className='col-12'>
            <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500 '>Units</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={unitsBlue} height={50} width={50} className='me-3' />
                  <h4 className='fw-bold'>
                    {(unitCountSummery?.occupiedUnitCount ? unitCountSummery?.occupiedUnitCount : 0) +
                      (unitCountSummery?.vacantUnitCount ? unitCountSummery?.vacantUnitCount : 0)}
                  </h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500'>Tenants</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={tenantBlue} height={50} width={50} className='me-3' />
                  <h4 className='fw-bold'>{unitCountSummery?.tenantCount ?? 0}</h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500 '>Leases</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={leasesBlue} height={50} width={50} className='me-3' />
                  <h4 className='fw-bold'>{unitCountSummery?.tenancyCount ?? 0}</h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500'>Occupancy</h4>
                <div className='d-flex justify-content-start align-items-top'>
                  <img src={occupancyBlue} height={45} width={45} className='me-3' />
                  <h4 className='fw-bold ms-2' style={{marginTop: '15px'}}>
                    {unitCountSummery?.occupancyRate >= 0 ? `${Math.round(unitCountSummery?.occupancyRate)}%` : 0}
                  </h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500'>Income</h4>
                <div style={{marginTop: '18px'}}>
                  <h4 className='fw-bold' style={{color: '#00b8a9'}}>
                    AED
                  </h4>
                  <h4 className='fw-bold'>
                    {`${parseFloat(unitCountSummery?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`}
                  </h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500'>Expense</h4>
                <div style={{marginTop: '18px'}}>
                  <h4 className='fw-bold' style={{color: '#e46464 '}}>
                    AED
                  </h4>
                  <h4 className='fw-bold'>
                    {/* {unitCountSummery?.expensePaymentCount ?? 0} */}
                    {`${parseFloat(unitCountSummery?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`}
                  </h4>
                </div>
              </div>
              <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                <h4 className='text-gray-500'>Rating</h4>
                <div className='d-flex justify-content-between align-items-end'>
                  <img src={yellowStar} height={35} width={35} className='me-3 mt-3' />
                  <h4 className='fw-bold'>{unitCountSummery?.ratingCount ?? 0}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5' style={{gap: '0px'}}>
            <div className={`d-flex align-items-center  px-0 col-10`}>
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>
              </div>
              {subTabListItem === 'buildings' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Building'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'development'}}
                      value={searchDevelopment}
                      onChange={(e: any) => setSearchDevelopment(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Development'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'group' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Group'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'development'}}
                      value={searchGroup}
                      onChange={(e: any) => setSearchGroup(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Development'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}

              {subTabListItem === 'allUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Unit'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit'}}
                      value={searchValue}
                      onChange={(e: any) => {
                        setSearchValue(e.target.value)
                        // setSearchUnit(e.target.value)
                      }}
                    />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'residentialUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Unit'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit'}}
                      value={searchValueResidential}
                      onChange={(e: any) => setSearchValueResidential(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'commercialUnits' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Unit'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit'}}
                      value={searchValueCommercial}
                      onChange={(e: any) => setSearchValueCommercial(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              {subTabListItem === 'commonAreas' && (
                <div className='xyz me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Unit'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit'}}
                      value={searchValueCommonArea}
                      onChange={(e: any) => setSearchValueCommonArea(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='Unit'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              )}
              <div className='test property me-4'>
                <CustomDateRangePicker
                  startDate={start_date}
                  endDate={end_date}
                  dateStatuses={[]}
                  onDatesChange={handleDatesChange}
                  autoSave={() => {}}
                  from={'create'}
                  disabled={false}
                />
              </div>
            </div>

            <div className={`px-0 col-2 d-flex justify-content-end`}>
              {subTabListItem === 'floors' && (
                <div className=''>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                    onClick={() => {
                      setAddFloorModel(true)
                    }}
                  >
                    <img src={addWhiteIcon} height={18} width={18} className='me-5' /> Add Floor
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ingights end and fitlers*/}

        {(subTabListItem === 'allUnits' || subTabListItem === 'residentialUnits' || subTabListItem === 'commercialUnits') && (
          <PropertiesList
            isFloorCol={type === 'unitCluster' ? false : true}
            goToEdit={goToEdit}
            isEditBtn={true}
            listData={allPropertiesTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
          />
        )}
        {subTabListItem === 'commonAreas' && (
          <CommonAreaList
            goToEdit={goToEdit}
            isEditBtn={true}
            isFloorCol={type === 'unitCluster' ? false : true}
            listData={allPropertiesTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
          />
        )}

        {subTabListItem === 'buildings' && (
          <BuildingList
            listData={allBuildingTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
          />
        )}
        {subTabListItem === 'group' && (
          <GroupList
            listData={unitGroupTableData}
            goToMiantenance={goToMiantenance}
            handlePageClick={handlePageClick}
            page={page}
            pageLimit={pageLimit}
            mixedCluster={false}
            buildingCluster={false}
            unitCluster={true}
          />
        )}
      </div>

      {addFloorModel && (
        <AddFloorModel
          show={addFloorModel}
          isEdit={isEditFloorModel}
          modelData={modelData}
          isBuilding={false}
          communityId={location?.state?.communityId}
          isUnitCluster={type === 'unitCluster' ? true : false}
          isMixedCluster={type === 'mixedCluster' ? true : false}
          isBuildingCluster={type === 'buildingCluster' ? true : false}
          handleClose={() => {
            setAddFloorModel(false)
          }}
          updateModelData={(updatedData: any) => {
            setModelData(updatedData)
          }}
        />
      )}
    </>
  )
}

export default UnitClusterDetailList

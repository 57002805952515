import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import trashImg from '../../../../img/trash.png'
import uploadFileIcon from '../../../../img/upload-file-icon.png'
import pdfIcon from '../../../../img/pdf.svg'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import axios from 'axios'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'
import ModalLoader from '../../General/ModalLoader'

interface ButtonProps {
  show?: any
  handleClose?: any
  updateData?: any
  tenantData?: any
  expData?: any
  mode?: any
  selectedItem?: any
  updateItems?: any
  slctdDev?: any
}

const RecordAPayment = ({
  show,
  handleClose,
  updateData,
  tenantData,
  expData,
  mode,
  selectedItem,
  updateItems,
  slctdDev,
}: ButtonProps) => {
  const {id} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [goToNext, setGoToNext] = useState<any>(false)

  const [checkedValues, setCheckedValues] = useState<any>([])

  let totalSelected: any = 0

  let totalAmountPaid: any = 0

  const [recordItems, setRecordItems] = useState<any>(expData?.items)
  // console.log('********* Record a Payment ************')
  // console.log(expData)

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const [paymentRecord, setPaymentRecord] = useState<any>({
    items: [],
    datePaid: null,
    totalAmountPaid: '',
    receipts: [
      //   {
      //   name: '',
      //   URL: '',
      //  }
    ],
    attachments: [],
    notes: '',
  })

  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...paymentRecord}
      values.datePaid = date.format('YYYY-MM-DD')
      setPaymentRecord(values)
    } else {
      let values = {...paymentRecord}
      values.datePaid = null
      setPaymentRecord(values)
    }

    // if (flg == '2') {
    //   setTimeout(() => {
    //     setAutoSaveData(Date.now())
    //   }, 700)
    // }
  }

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [isLoading, setIsLoading] = useState<any>(false)
  // const [showLoader, setShowLoader] = useState<boolean>(false)

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  //
  // const handleUploadReceipt = async (e: any) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     setShowLoader(true)

  //     let userData: any = localStorage.getItem('userData')
  //     const data = JSON.parse(userData)

  //     let uploadPromises = []

  //     for (let i = 0; i < e.target.files.length; i++) {
  //       let file = e.target.files[i]

  //       // Uncomment the below lines if you want to restrict file types
  //       // if (file.type !== 'application/pdf') {
  //       //   ErrorToast('Files other than PDF are not allowed.');
  //       //   setShowLoader(false);
  //       //   return;
  //       // }

  //       var formdata = new FormData()
  //       formdata.append('image', file)

  //       let name: any = file.name

  //       let uploadPromise = ApiPost(
  //         `upload/image/params?fileType=receipt&databaseId=${data._id}&fileName=${name}`,
  //         formdata
  //       )
  //         .then((res) => {
  //           return res?.data?.data?.image
  //         })
  //         .catch((err) => {
  //           ErrorToast(err?.message)
  //           return null
  //         })

  //       uploadPromises.push(uploadPromise)
  //     }

  //     try {
  //       let uploadedFiles = await Promise.all(uploadPromises)

  //       let updatedPaymentRecord = {...paymentRecord}
  //       updatedPaymentRecord.receipt = updatedPaymentRecord.receipt || []

  //       uploadedFiles.forEach((fileURL) => {
  //         if (fileURL) {
  //           updatedPaymentRecord.receipt.push({URL: fileURL})
  //         }
  //       })

  //       setPaymentRecord(updatedPaymentRecord)
  //     } catch (error) {
  //       ErrorToast('An error occurred while uploading the files.')
  //     } finally {
  //       setShowLoader(false)
  //     }
  //   }
  // }

  const handleUploadReceipt = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesReceipt = [...values.receipts]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (valuesReceipt.some((receipt) => receipt.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            valuesReceipt.push({
              URL: res?.data?.data?.image,
              name: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            })

            // update the upload progress
            setUploadProgress(i + 1)

            // console.log(values);
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      values.receipts = valuesReceipt
      setPaymentRecord(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  //
  // const handleUploadAttchment = async (e: any) => {
  //   if (e.target.files && e.target.files[0]) {
  //     // if (e.target.files[0]?.type === 'application/pdf') {
  //     setShowLoader(true)
  //     var formdata = new FormData()
  //     formdata.append('image', e.target.files[0])

  //     let userData: any = localStorage.getItem('userData')
  //     const data = JSON.parse(userData)

  //     let name: any = e.target.files[0]?.name

  //     await ApiPost(
  //       `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
  //       formdata
  //     )
  //       .then((res) => {
  //         let values = {...paymentRecord}
  //         values.attachment.URL = res?.data?.data?.image
  //         setPaymentRecord(values)

  //         setShowLoader(false)
  //       })
  //       .catch((err) => {
  //         setShowLoader(false)
  //         ErrorToast(err?.message)
  //       })
  //     // } else {
  //     //   ErrorToast('Files other than PDF are not allowed..')
  //     // }
  //   }
  // }

  const handleUploadAttchment = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesAttachments = [...values.attachments]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (
          valuesAttachments.some((attachment) => attachment.name === `${uniqueName}.${extension}`)
        ) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            valuesAttachments.push({
              URL: res?.data?.data?.image,
              name: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            })

            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      values.attachments = valuesAttachments

      // console.log(values);
      setPaymentRecord(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  const [receiptData, setReceiptData] = useState<any>({
    name: '',
    URL: '',
  })

  const [attchmentData, setAttachmentData] = useState<any>({
    name: '',
    URL: '',
  })

  const [selectedDevelopment, setSelectedDevelopment] = useState<any>()

  useEffect(() => {
    // console.log(slctdDev)
    setSelectedDevelopment(slctdDev)
  }, [slctdDev])

  useEffect(() => {
    // let values = [...expData?.items]
    // expData?.items?.map((itm:any, ind:any)=>{
    //   values[ind].totalAmountPaid =  Number(values[ind].amountPaid)
    //   values[ind].amountPaid = 0
    //   values[ind].outstandingAmount = Number(values[ind].totalAmount)- Number(values[ind].totalAmountPaid)
    // })
    // setPaymentRecord(values)
  }, [expData])

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const truncateFileName = (name: any, length: any) => {
    if (name.length <= length) return name

    const parts = name.split('.')
    const extension = parts.length > 1 ? '.' + parts.pop() : ''
    const truncatedName = parts.join('.').slice(0, length - 3 - extension.length) + '...'

    return truncatedName + extension
  }

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  // const handleImageClick = (val:any, length:any) => {
  //   // Add data-fancybox attribute to the clicked image
  //   for(let i=0; i<length; i++){
  //   const image = document.getElementById(val+i);
  //   if (image) {
  //     image.setAttribute("data-fancybox", "");
  //   }
  // }
  // }

  //
  const handleImageClick = (val: any, valSrc: any, type: any) => {
    const groupName = `group_${type}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', valSrc)
    }

    // Add data-fancybox attribute to the related receipts
    if (type == 'receipts') {
      paymentRecord?.receipts?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedReceipt${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    } else {
      paymentRecord?.attachments?.map((attch: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedAttachment${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${attch?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    }

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  useEffect(() => {
    let values = [...checkedValues]

    expData?.items?.map((itm: any, ind: any) => {
      values[ind] = false
    })
    setCheckedValues(values)
  }, [expData])

  const [isAmountExceeded, setIsAmountExceeded] = useState<any>(false)

  useEffect(()=>{
    let isExceeded:any = false
    expData?.items?.map((itm: any, ind: any) => {
      if(itm?.amountPaid > itm?.outstandingAmount)
        isExceeded = true
    })

    setIsAmountExceeded(isExceeded)

  },[recordItems])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '350px',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='d-flex align-items-top'>
            <h2 className=' pt-5 mb-0'>
              <b>Record a Payment</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          {!goToNext ? (
            <div className='d-flex align-items-center'>
              <h4 className=' pt-2 mb-0'>
                Select the expense items you wish to record a payment for:
              </h4>

              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-150 ms-auto'
                onClick={(e: any) => {
                  let values: any = [...recordItems]
                  recordItems?.map((rc: any, ind: any) => {
                    values[ind].amountPaid = 0
                  })
                  setRecordItems(values)
                  setGoToNext(true)
                }}
                disabled={totalSelected == 0}
              >
                {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
                Next
              </button>
            </div>
          ) : (
            <div className='d-flex align-items-center mt-2'>
              <div className='d-block'>
                <h4
                  className=' pt-2 mb-0 cursor-pointer'
                  onClick={() => {
                    setGoToNext(false)
                  }}
                >
                  {'< Back'}
                </h4>
                <h4 className=' pt-2 mb-0'>
                  {'Enter the details of your payment for the selected expense items:'}
                </h4>
              </div>

              {recordItems?.map((itm: any, ind: any) => {
                totalAmountPaid = Number(totalAmountPaid) + Number(itm?.amountPaid)
                return <></>
              })}

              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-150 ms-auto'
                onClick={(e: any) => {
                  let itms: any = []
                  let itmInd: any = 0
                  recordItems?.map((rt: any, ind: any) => {
                    if (checkedValues[ind]) {
                      itms[itmInd] = rt
                      itmInd++
                    }

                    return <></>
                  })
                  // console.log('************itms**************')
                  // console.log(recordItems)

                  let values = {...paymentRecord}
                  values.items = itms
                  values.totalAmountPaid = totalAmountPaid

                  let valuesItems = [...expData?.items]

                  // console.log(expData?.items)
                  expData?.items?.map((itm: any, ind: any) => {
                    // valuesItems[ind].totalAmountPaid = valuesItems[ind].totalAmountPaid +  Number(valuesItems[ind].amountPaid)
                    let x: any = Number(valuesItems[ind].amountPaid)
                    valuesItems[ind].totalAmountPaid = Number(valuesItems[ind].amountPaid)

                    valuesItems[ind].amountPaid = 0
                    valuesItems[ind].outstandingAmount =
                      Number(valuesItems[ind].outstandingAmount) -
                      Number(valuesItems[ind].totalAmountPaid)

                    valuesItems[ind].expenseStatus =
                      valuesItems[ind].outstandingAmount == 0
                        ? 1
                        : x > 0 && valuesItems[ind].outstandingAmount > 0
                        ? 2
                        : 0
                  })
                  updateData(values, recordItems)
                  updateItems(valuesItems)
                }}
                disabled={(totalAmountPaid == 0 || paymentRecord?.datePaid == null || isAmountExceeded == true) ? true : false}
              >
                {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
                Submit
              </button>
            </div>
          )}

          <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
            <table className='table align-start  fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                  {!goToNext && (
                    <th className='text-start min-w-25px ps-0'>
                      {recordItems?.length > 0 && (
                        <>
                          <td className='text-start ps-0 test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={
                                  totalSelected == recordItems?.length && recordItems?.length != 0
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  let values: any = []
                                  if (e.target.checked) {
                                    for (let i = 0; i < checkedValues?.length; i++) {
                                      values[i] = true
                                    }
                                  } else {
                                    for (let i = 0; i < checkedValues?.length; i++) {
                                      values[i] = false
                                    }
                                  }

                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </td>
                        </>
                      )}
                    </th>
                  )}

                  <th className='text-start min-w-150px ps-0'>Item</th>
                  <th className='text-start min-w-75px ps-0'>QTY </th>
                  <th className='text-start min-w-100px ps-0'>Total Amount </th>
                  <th className='text-start min-w-100px ps-0'>Outstanding </th>
                  <th className='text-start min-w-150px ps-0'>Property </th>
                  {goToNext && <th className='text-start min-w-150px ps-0'>Amount Paid </th>}
                </tr>
              </thead>

              <tbody className='fw-semibold text-gray-600'>
                {recordItems?.map((itm: any, ind: any) => {
                  // console.log(itm)
                  let totalAmountOfItem: any = 0
                  let vatAmnt: any =
                    ((Number(itm?.amount) * Number(itm?.quantity)) / 100) *
                    Number(itm?.VATPercentage)
                  totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

                  return (
                    <>
                      {((checkedValues[ind] && goToNext) || !goToNext) && (
                        <tr className=''>
                          {!goToNext && (
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                          )}
                          <td className='text-start  ps-0'>{itm?.name ? itm?.name : '-'}</td>
                          <td className='text-start  ps-0'>
                            {itm?.quantity ? itm?.quantity : '-'}
                          </td>
                          <td className='text-start  ps-0'>
                            {totalAmountOfItem
                              ? `AED ${totalAmountOfItem?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}`
                              : 'AED -'}
                          </td>
                          <td className='text-start  ps-0'>
                            {itm?.outstandingAmount <= totalAmountOfItem
                              ? `AED ${itm?.outstandingAmount?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}`
                              : itm?.outstandingAmount > totalAmountOfItem
                              ? `AED ${totalAmountOfItem?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}`
                              : 'AED -'}
                          </td>

                          <td className='text-start  ps-0'>
                            {itm?.property != undefined ? (
                              <>
                                <div className='d-flex ms-1'>
                                  <span className=''>
                                    {/* <b> */}
                                    {Number(itm?.property?.level) == 1
                                      ? itm?.property?.details
                                      : itm?.property?.details?.unitNo}
                                    {/* </b> */}
                                  </span>
                                </div>
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          {goToNext && (
                            <td className='text-start  ps-0'>
                              <input
                                //   {...register('documentName', {required: true})}
                                type='number'
                                className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                  itm?.amountPaid > itm?.outstandingAmount ? 'border-danger' : ''
                                }`}
                                name='documentName'
                                value={itm?.amountPaid}
                                style={{height: '35px'}}
                                placeholder={'AED'}
                                onChange={(e: any) => {
                                  const newValue = e.target.value.replace(/^0+/, '')
                                  let values = [...recordItems]
                                  values[ind].amountPaid = newValue != '' ? newValue : ''

                                  setRecordItems(values)
                                }}
                                autoComplete='off'
                              />
                              {itm?.amountPaid > itm?.outstandingAmount && (
                                <p
                                  className='text-danger mb-0 ms-0'
                                  style={{maxWidth: '200px', wordBreak: 'break-word'}}
                                >
                                  {' '}
                                  Amount paid cannot exceed the outstanding amount of your expense
                                  item. Edit the total amount of your expense item to be able to
                                  enter a greater amount paid.
                                </p>
                              )}
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          {goToNext && (
            <>
              <div className='separator my-5 m'></div>

              <div className='d-flex align-items-center mt-10'>
                <div className='d-block'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry '>
                      {' '}
                      Date Paid
                    </label>
                  </h4>
                  <div className='expense-date'>
                    {paymentRecord?.datePaid != null ? (
                      <DatePicker
                        name='deliveryDate'
                        defaultValue={moment(paymentRecord?.datePaid, 'YYYY-MM-DD')}
                        disabledDate={disabledDate}
                        onChange={handleDateChange}
                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                        placeholder='Select Payment Date'
                      />
                    ) : (
                      <DatePicker
                        name='deliveryDate'
                        disabledDate={disabledDate}
                        onChange={handleDateChange}
                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                        placeholder='Select Payment Date'
                      />
                    )}
                  </div>
                </div>
                <div className='d-block ms-auto text-end'>
                  <h2 className=' pt-2 mb-0 ms-auto'>
                    <b> Total Amount Paid</b>
                  </h2>
                  <h2 className=' pt-2 mb-0 '>
                    <b>
                      {totalAmountPaid > 0
                        ? `AED ${totalAmountPaid?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}`
                        : 'AED -'}
                    </b>
                  </h2>

                  <h3 className=' pt-2 mb-0'></h3>
                </div>
              </div>

              <div className='row mx-0 d-flex mt-10 payment-record'>
                <div
                  className='col-12 col-md-6 ps-0 '
                  style={{display: 'flex', flexDirection: 'column'}}
                >
                  <div className='card p-5 px-9 h-100' style={{}}>
                    <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                      <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                        Receipt
                      </h2>{' '}
                    </div>
                    <div className='d-flex justify-content-center xyz my-2 mt-0'>
                      <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center  mb-0'>
                        Add the receipt details for your payment:
                      </p>{' '}
                    </div>
                    {/* <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                      <input
                        //   {...register('documentName', {required: true})}
                        type='text'
                        className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                        name='documentName'
                        value={paymentRecord?.receipt?.name}
                        style={{height: '35px', width: '300px'}}
                        placeholder={'Receipt No.'}
                        onChange={(e: any) => {
                          let values = {...paymentRecord}
                          values.receipt.name = e.target.value
                          setPaymentRecord(values)
                        }}
                      />
                    </div> */}

                    <div className='mt-5 '>
                      {/* {paymentRecord?.receipt?.URL?.trim()?.length == 0 ? ( */}
                      <div className='d-flex align-items-start justify-content-center'>
                        <form
                          id='form-file-upload-po'
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                          style={{height: '110px'}}
                        >
                          <label
                            id='form-file-upload-po'
                            htmlFor='form-file-upload-po-receipt'
                            className={
                              dragActive
                                ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                            }
                            style={{
                              fontSize: '16px',
                              color: 'white',
                            }}
                            ref={inputRef}
                          >
                            <div
                              className={`d-flex justify-content-center px-8 align-items-center ${
                                isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                              }`}
                            >
                              <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                style={{minWidth: '90px'}}
                              >
                                <b>Drag & drop your file </b> or{' '}
                              </div>
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                style={{minWidth: '90px'}}
                              >
                                <div
                                  className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                  style={{}}
                                >
                                  Browse Files
                                </div>
                              </div>
                            </div>
                          </label>
                          <input
                            type='file'
                            multiple={true}
                            onChange={(e: any) => {
                              handleUploadReceipt(e)
                            }}
                            id='form-file-upload-po-receipt'
                            ref={inputRef}
                            className='file-input-drag cursor-pointer'
                            // accept='application/pdf'
                          />
                          {dragActive && (
                            <div
                              id='drag-file-element'
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                      </div>
                      {/* // ) : ( */}
                      <div style={{maxHeight: '175px', overflow: 'auto'}}>
                        {paymentRecord?.receipts?.map((prRcpt: any, ind: any) => {
                          return (
                            <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center px-2 pe-7'>
                              <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                              {editFile == `Receipt${ind}` ? (
                                <div
                                  className='d-flex  vendor me-5 pe-2'
                                  style={{width: '-webkit-fill-available'}}
                                >
                                  <input
                                    type='text'
                                    value={newName}
                                    placeholder='Enter receipt name'
                                    className='form-control form-control-solid mytest ms-3'
                                    onChange={(e: any) => {
                                      let newValue: any =
                                        e.target.value != ''
                                          ? e.target.value.charAt(0).toUpperCase() +
                                            e.target.value.slice(1)
                                          : e.target.value
                                      setNewName(newValue)
                                    }}
                                    disabled={isLoading}
                                    autoComplete='off'
                                  />
                                </div>
                              ) : (
                                <p className='mb-0 ms-2  me-7 fnt-500'>
                                  {truncateFileName(prRcpt?.name, 35)}
                                </p>
                              )}

                              <div className='d-flex align-items-center ms-auto'>
                                <img
                                  src={
                                    prRcpt?.URL?.split('.')[1] === 'pdf'
                                      ? pdfDocument
                                      : prRcpt?.URL?.split('.').pop() === 'doc' ||
                                        prRcpt?.URL?.split('.').pop() === 'docx'
                                      ? docCocument
                                      : prRcpt?.URL?.split('.').pop() === 'jpeg' ||
                                        prRcpt?.URL?.split('.').pop() === 'jpg' ||
                                        prRcpt?.URL?.split('.').pop() === 'png' ||
                                        prRcpt?.URL?.split('.').pop() === 'svg' ||
                                        prRcpt?.URL?.split('.').pop() === 'gif'
                                      ? pngImg
                                      : prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                        prRcpt?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                                      ? ``
                                      : fileIcon
                                  }
                                  height={30}
                                  width={30}
                                  className='cursor-pointer'
                                  // data-fancybox
                                  // data-src={`${Bucket}${prRcpt?.URL}`}
                                  style={
                                    editFile == `Receipt${ind}`
                                      ? {opacity: '0.5', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  id={`clickedReceipt${ind}`}
                                  onClick={() =>
                                    handleImageClick(
                                      `clickedReceipt${ind}`,
                                      `${Bucket}${prRcpt?.URL}`,
                                      'receipts'
                                    )
                                  }
                                />
                                {editFile == `Receipt${ind}` ? (
                                  <>
                                    <img
                                      src={correct}
                                      height={28}
                                      width={28}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        let values = {...paymentRecord}
                                        values.receipts[ind].name = newName
                                        setPaymentRecord(values)
                                        setEditFile(-1)
                                      }}
                                    />
                                    <img
                                      src={removed}
                                      height={28}
                                      width={28}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        setEditFile(-1)
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={greenPen}
                                      height={18}
                                      width={18}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        setNewName(prRcpt?.name)
                                        setEditFile(`Receipt${ind}`)
                                      }}
                                    />
                                    <img
                                      src={downloadIcon}
                                      height={18}
                                      width={18}
                                      className='ms-4 cursor-pointer'
                                      onClick={() => {
                                        handleDownload(`${Bucket}${prRcpt?.URL}`)
                                      }}
                                    />
                                    <img
                                      src={trashImg}
                                      height={16}
                                      width={16}
                                      onClick={() => {
                                        let values = {...paymentRecord}
                                        let receiptValues = [...values.receipts]
                                        receiptValues.splice(ind, 1)
                                        values.receipts = receiptValues
                                        setPaymentRecord(values)
                                      }}
                                      className='ms-4 cursor-pointer'
                                    />{' '}
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <div
                  className='col-12 col-md-6 pe-0'
                  style={{display: 'flex', flexDirection: 'column'}}
                >
                  <div className='card p-5 px-9 h-100'>
                    <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                      <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                        Other Attachments
                      </h2>{' '}
                    </div>

                    <div className='d-flex justify-content-center xyz my-2 mt-0'>
                      <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center mb-0'>
                        Attach any other document related to your payment
                      </p>{' '}
                    </div>

                    {/* <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                      <input
                        //   {...register('documentName', {required: true})}
                        type='text'
                        className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                        name='documentName'
                        value={paymentRecord?.attachment?.name}
                        style={{height: '35px', width: '300px'}}
                        placeholder={'Attachment Name'}
                        onChange={(e: any) => {
                          let values = {...paymentRecord}
                          values.attachment.name = e.target.value
                          setPaymentRecord(values)
                        }}
                      />
                    </div> */}

                    <div className='mt-5'>
                      <div className='d-flex align-items-start justify-content-center'>
                        <form
                          id='form-file-upload-po'
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                          style={{height: '110px'}}
                        >
                          <label
                            id='form-file-upload-po'
                            htmlFor='form-file-upload-poo'
                            className={
                              dragActive
                                ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                            }
                            style={{
                              fontSize: '16px',
                              color: 'white',
                            }}
                            ref={inputRef}
                          >
                            <div
                              className={`d-flex justify-content-center px-8 align-items-center ${
                                isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                              }`}
                            >
                              <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                style={{minWidth: '90px'}}
                              >
                                <b>Drag & drop your file </b> or{' '}
                              </div>
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                style={{minWidth: '90px'}}
                              >
                                <div
                                  className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                  style={{}}
                                >
                                  Browse Files
                                </div>
                              </div>
                            </div>
                          </label>
                          <input
                            type='file'
                            multiple={true}
                            onChange={(e: any) => {
                              handleUploadAttchment(e)
                            }}
                            id='form-file-upload-po'
                            ref={inputRef}
                            className='file-input-drag cursor-pointer'
                            // accept='application/pdf'
                          />
                          {dragActive && (
                            <div
                              id='drag-file-element'
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                      </div>

                      <div style={{maxHeight: '175px', overflow: 'auto'}}>
                        {paymentRecord?.attachments?.map((prAtch: any, ind: any) => {
                          return (
                            <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center px-2 pe-7'>
                              <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                              {editFile == ind ? (
                                <div
                                  className='d-flex  vendor me-5 pe-2'
                                  style={{width: '-webkit-fill-available'}}
                                >
                                  <input
                                    type='text'
                                    value={newName}
                                    placeholder={'Enter attachment name'}
                                    className='form-control form-control-solid mytest ms-3'
                                    onChange={(e: any) => {
                                      let newValue: any =
                                        e.target.value != ''
                                          ? e.target.value.charAt(0).toUpperCase() +
                                            e.target.value.slice(1)
                                          : e.target.value
                                      setNewName(newValue)
                                    }}
                                    disabled={isLoading}
                                    autoComplete='off'
                                  />
                                </div>
                              ) : (
                                <p className='mb-0 ms-2  me-7 fnt-500'>
                                  {truncateFileName(prAtch?.name, 35)}
                                </p>
                              )}

                              <div className='d-flex align-items-center ms-auto'>
                                <img
                                  src={
                                    prAtch?.URL?.split('.')[1] === 'pdf'
                                      ? pdfDocument
                                      : prAtch?.URL?.split('.').pop() === 'doc' ||
                                        prAtch?.URL?.split('.').pop() === 'docx'
                                      ? docCocument
                                      : prAtch?.URL?.split('.').pop() === 'jpeg' ||
                                        prAtch?.URL?.split('.').pop() === 'jpg' ||
                                        prAtch?.URL?.split('.').pop() === 'png' ||
                                        prAtch?.URL?.split('.').pop() === 'svg' ||
                                        prAtch?.URL?.split('.').pop() === 'gif'
                                      ? pngImg
                                      : prAtch?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                        prAtch?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                                      ? ``
                                      : fileIcon
                                  }
                                  height={30}
                                  width={30}
                                  className=''
                                  // data-fancybox
                                  // data-src={`${Bucket}${prAtch?.URL}`}
                                  style={
                                    editFile == ind
                                      ? {opacity: '0.5', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  id={`clickedAttachment${ind}`}
                                  onClick={() =>
                                    handleImageClick(
                                      `clickedAttachment${ind}`,
                                      `${Bucket}${prAtch?.URL}`,
                                      'attachments'
                                    )
                                  }
                                />
                                {editFile == ind ? (
                                  <>
                                    <img
                                      src={correct}
                                      height={28}
                                      width={28}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        let values = {...paymentRecord}
                                        values.attachments[ind].name = newName
                                        setPaymentRecord(values)
                                        setEditFile(-1)
                                      }}
                                    />
                                    <img
                                      src={removed}
                                      height={28}
                                      width={28}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        setEditFile(-1)
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={greenPen}
                                      height={18}
                                      width={18}
                                      className='ms-5 cursor-pointer'
                                      onClick={() => {
                                        setNewName(prAtch?.name)
                                        setEditFile(ind)
                                      }}
                                    />
                                    <img
                                      src={downloadIcon}
                                      height={18}
                                      width={18}
                                      className='ms-4 cursor-pointer'
                                      onClick={() => {
                                        handleDownload(`${Bucket}${prAtch?.URL}`)
                                      }}
                                    />
                                    <img
                                      src={trashImg}
                                      height={16}
                                      width={16}
                                      onClick={() => {
                                        let values = {...paymentRecord}
                                        let attachValues = [...values.attachments]
                                        attachValues.splice(ind, 1)
                                        values.attachments = attachValues
                                        setPaymentRecord(values)
                                      }}
                                      className='ms-4 cursor-pointer'
                                    />{' '}
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mx-0 mt-10'>
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-3 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Notes
                    </h2>{' '}
                  </div>
                  <textarea
                    className='form-control form-control-solid mytest bg-white'
                    placeholder='Enter notes related to this payment'
                    name='message'
                    style={{minHeight: '100px'}}
                    value={paymentRecord?.notes}
                    onChange={(e: any) => {
                      let values = {...paymentRecord}
                      let newValue: any =
                        e.target.value != ''
                          ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                          : e.target.value
                      values.notes = newValue
                      setPaymentRecord(values)
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      {/* <SingleFileLoader isVisible={showLoader} /> */}
      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default RecordAPayment

import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'
import CircularProgress from '@mui/material/CircularProgress'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation, useParams} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import backArrow from '../../../../img/back-arrow.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import CommonAreaList from './CommonAreaList'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'

interface SquareHandleProps {
  value: number
}

const PropertiesDetailList = () => {
  const getAllBuildingDetailFilter: any = localStorage.getItem('allBuildingDetailFilters')
  const buildingDetailFilter = JSON.parse(getAllBuildingDetailFilter)

  const getActiveTab: any = localStorage.getItem('subTabListItemForBuildingDetails')
  const tab = JSON.parse(getActiveTab)

  const getAllPropertiesResidentialDate: any = localStorage.getItem('buildingDetailResidentialDateFilter')
  const allPropertiesResidentialDate = JSON.parse(getAllPropertiesResidentialDate) ?? {}

  const getAllPropertiesAllUnitsDate: any = localStorage.getItem('buildingDetailAllUnitsDateFilter')
  const allPropertiesAllUnitsDate = JSON.parse(getAllPropertiesAllUnitsDate) ?? {}

  const getAllPropertiesCommercialDate: any = localStorage.getItem('buildingDetailCommercialDateFilter')
  const allPropertiesCommercialDate = JSON.parse(getAllPropertiesCommercialDate) ?? {}

  const getAllPropertiesCommonAreaDate: any = localStorage.getItem('buildingDetailCommonAreaDateFilter')
  const allPropertiesCommonAreaDate = JSON.parse(getAllPropertiesCommonAreaDate) ?? {}

  const getAllPropertiesFloorDate: any = localStorage.getItem('buildingDetailFloorDateFilter')
  const allPropertiesFloorDate = JSON.parse(getAllPropertiesFloorDate) ?? {}

  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>(tab ? tab : 'allUnits')

  const [searchUnit, setSearchUnit] = useState<any>(buildingDetailFilter?.searchUnit ?? '')
  const [searchResidentialUnit, setSearcResidentialhUnit] = useState<any>(buildingDetailFilter?.searchResidentialUnit ?? '')

  const [searchCommercialUnit, setSearchCommercialUnit] = useState<any>(buildingDetailFilter?.searchCommercialUnit ?? '')

  const [searchValue, setSearchValue] = useState<any>(buildingDetailFilter?.searchUnit ?? '')
  const [searchValueResidential, setSearchValueResidential] = useState<any>(buildingDetailFilter?.searchResidentialUnit ?? '')
  const [searchValueCommercial, setSearchValueCommercial] = useState<any>(buildingDetailFilter?.searchCommercialUnit ?? '')
  const [searchValueCommonArea, setSearchValueCommonArea] = useState<any>(buildingDetailFilter?.searchCommonAreaUnit ?? '')

  const [searchCommonAreaUnit, setSearchCommonAreaUnit] = useState<any>(buildingDetailFilter?.searchCommonAreaUnit ?? '')

  const [searchFloor, setSearchFloor] = useState<any>(buildingDetailFilter?.searchFloor ?? '')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  // const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const {pathname, state}: any = useLocation()
  const {id} = useParams()
  const buildingDetail: any = {}

  const goToMiantenance = (listItem: any) => {
    if (subTabListItem === 'floors') {
      navigate(`/properties/building-details/${id}/floors/${listItem?._id}`, {
        state: {
          key: 'floors',
          fromBuilding: true,
          buildingName: allBuildingTableData?.[0]?.name,
          floorName: listItem?.name,
          buildingId: id,
          floorId: listItem?._id,
          area: state?.area,
          city: state?.city,
        },
      })
    } else if (subTabListItem === 'allUnits' ||
      subTabListItem === 'residentialUnits' ||
      subTabListItem === 'commercialUnits' ||
      subTabListItem === 'commonAreas') {
      navigate(`/property-profile/${listItem?._id}`,{
        state: {
          from: 'buildingDetail',
          buildingName: allBuildingTableData?.[0]?.name,
          buildingId: id,
          area: listItem?.building[0]?.area ? listItem?.building[0]?.area : '-',
          city: listItem?.building[0]?.city ? listItem?.building[0]?.city : '-',
          isCommonArea:listItem?.unitType == 2 ? true : false
        }
      })
    }
  }

  function formatDate(date: any) {
    const day = ('0' + date.getDate()).slice(-2) // Get day with leading zero if needed
    const month = ('0' + (date.getMonth() + 1)).slice(-2) // Get month with leading zero if needed
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  function generateDates() {
    // Create start date for the year (1st January)
    const currentDate = new Date()
    const year: any = currentDate.getFullYear()
    const startDate: any = new Date(year, 0, 1)

    // Create end date for the year (31st December)
    const endDate = new Date(year, 11, 31)

    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatDate(endDate)

    if (subTabListItem === 'residentialUnits' && Object.keys(allPropertiesResidentialDate).length > 0) {
      if (Object?.values(allPropertiesResidentialDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesResidentialDate?.startDate))
        setEnd(moment(allPropertiesResidentialDate?.endDate))
        setStartDatePayload(moment(allPropertiesResidentialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesResidentialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (subTabListItem === 'allUnits' && Object.keys(allPropertiesAllUnitsDate).length > 0) {
      if (Object?.values(allPropertiesAllUnitsDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesAllUnitsDate?.startDate))
        setEnd(moment(allPropertiesAllUnitsDate?.endDate))
        setStartDatePayload(moment(allPropertiesAllUnitsDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesAllUnitsDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (subTabListItem === 'commercialUnits' && Object.keys(allPropertiesCommercialDate).length > 0) {
      if (Object?.values(allPropertiesCommercialDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesCommercialDate?.startDate))
        setEnd(moment(allPropertiesCommercialDate?.endDate))
        setStartDatePayload(moment(allPropertiesCommercialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesCommercialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (subTabListItem === 'commonAreas' && Object.keys(allPropertiesCommonAreaDate).length > 0) {
      if (Object?.values(allPropertiesCommonAreaDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesCommonAreaDate?.startDate))
        setEnd(moment(allPropertiesCommonAreaDate?.endDate))
        setStartDatePayload(moment(allPropertiesCommonAreaDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesCommonAreaDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (subTabListItem === 'floors' && Object.keys(allPropertiesFloorDate).length > 0) {
      if (Object?.values(allPropertiesFloorDate).every((x) => x !== null || x !== '')) {
        setStart(moment(allPropertiesFloorDate?.startDate))
        setEnd(moment(allPropertiesFloorDate?.endDate))
        setStartDatePayload(moment(allPropertiesFloorDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayload(moment(allPropertiesFloorDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else {
      setStart(moment(formattedStartDate, 'DD/MM/YYYY'))
      setEnd(moment(formattedEndDate, 'DD/MM/YYYY'))
      setStartDatePayload(moment(formattedStartDate)?.format('YYYY-MM-DD'))
      setEndDatePayload(moment(formattedEndDate)?.format('YYYY-MM-DD'))
    }
  }

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const handleDatesChange = ({startDate, endDate}: {startDate: moment.Moment | null; endDate: moment.Moment | null}) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [limit, setLimit] = useState<any>(25)
  const [allPropertiesTableData, setAllPropertiesTableData] = useState<any>([])
  const [allFloorTableData, setAllFloorTableData] = useState<any>([])
  const [allBuildingTableData, setAllBuildingTableData] = useState<any>([])
  const [unitCountSummery, setUnitCountSummary] = useState<any>()
  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    if (
      subTabListItem == 'allUnits' ||
      subTabListItem == 'residentialUnits' ||
      subTabListItem == 'commonAreas' ||
      subTabListItem === 'commercialUnits'
    ) {
      getAllPropertiesTableData(event.selected + 1)
    }
    if (subTabListItem === 'floors') {
      getAllFloorTableData(event.selected + 1)
    }
  }

  const countSummary = async () => {
    const body : any = {
      isAllPropertise: false,
      isForBuildingDevelopment: true,
      isForCommunityDevelopment: false,
      buildingDevelopmentIds: [`${id}`],
      unit: {
        propertyType:
          subTabListItem === 'allUnits'
            ? [0, 1]
            : subTabListItem === 'residentialUnits'
            ? [0]
            : subTabListItem === 'commercialUnits'
            ? [1]
            : subTabListItem === 'commonAreas'
            ? [0, 1]
            : [],
        unitType:
          subTabListItem === 'allUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'residentialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'commercialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem == 'commonAreas'
            ? [2]
            : [],
      },
      floor:{},
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
    }
    if (subTabListItem === 'floors') {
      body.floor = {
       searchFloorName : searchFloor
      }
   }
   if (['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some((unit: string) => unit === subTabListItem)) {
    body.unit.searchUnitNo = subTabListItem === 'allUnits' ? searchUnit : 
                         subTabListItem === 'residentialUnits' ? searchResidentialUnit
                         : subTabListItem === 'commercialUnits' ? searchCommercialUnit
                         : subTabListItem === 'commonAreas' ? searchCommonAreaUnit
                         : ''
 }
    await ApiPost('corporate/development/properties/unit_count', body)
      .then((res) => {
        setUnitCountSummary(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getAllPropertiesTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllPropertiesTableData([])

    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
      searchUnitNo:
        subTabListItem === 'allUnits'
          ? searchUnit
          : subTabListItem === 'residentialUnits'
          ? searchResidentialUnit
          : subTabListItem === 'commercialUnits'
          ? searchCommercialUnit
          : subTabListItem === 'commonAreas'
          ? searchCommonAreaUnit
          : '',
      propertyTypes:
        subTabListItem === 'allUnits'
          ? [0, 1]
          : subTabListItem === 'residentialUnits'
          ? [0]
          : subTabListItem === 'commercialUnits'
          ? [1]
          : subTabListItem === 'floors'
          ? []
          : [0, 1],
      unitTypes:
        subTabListItem === 'allUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'residentialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem === 'commercialUnits'
          ? [0, 1, 3, 4, 5]
          : subTabListItem == 'commonAreas'
          ? [2]
          : subTabListItem === 'floors'
          ? []
          : [],
      buildingIds: [id],
      communityIds: [],
      floorIds: [],
      clusterIds: [],
      unitGroupIds: [],
    }

    await ApiPost('corporate/unit/get_properties', body)
      .then((res) => {
        setAllPropertiesTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getSingleBuildingData = async () => {
    setIsLoading(true)
    setIsSkeleton(true)
    setAllBuildingTableData([])
    let body = {
      page: page,
      limit: limit,
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
      search: '',
      buildingIds: [id],
      communityIds: [],
    }

    await ApiPost('corporate/building/get_properties', body)
      .then((res) => {
        setAllBuildingTableData(res?.data?.data?.building_data)
        // setPageLimit(res?.data?.data?.state?.page_limit)
        setIsSkeleton(false)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getAllFloorTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllFloorTableData([])
    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayload ? startDatePayload : null,
      endDate: endDatePayload ? endDatePayload : null,
      search: searchFloor,
      buildingIds: [id],
      communityIds: [],
    }

    await ApiPost('corporate/floor/get_properties', body)
      .then((res) => {
        setAllFloorTableData(res?.data?.data?.floor_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const handleEdit = () => {
    navigate('/properties/edit-development', {
      state: {
        isBuilding: true,
        buildingId: id,
        isEdit: true,
        from: 'buildingDetail',
        area: state?.area,
        city: state?.city,
      },
    })
  }

  useEffect(() => {
    getSingleBuildingData()
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (activeTab === 'all-properties' && subTabListItem == 'allUnits') {
        setSearchUnit(searchValue)
      }
      if (activeTab === 'all-properties' && subTabListItem == 'residentialUnits') {
        setSearcResidentialhUnit(searchValueResidential)
      }
      if (activeTab === 'all-properties' && subTabListItem === 'commercialUnits') {
        setSearchCommercialUnit(searchValueCommercial)
      }
      if (activeTab === 'all-properties' && subTabListItem == 'commonAreas') {
        setSearchCommonAreaUnit(searchValueCommonArea)
      }
    }, 500) // delay time in milliseconds

    return () => {
      clearTimeout(handler)
    }
  }, [searchValue, searchValueResidential, searchValueCommercial, searchValueCommonArea])

  useEffect(() => {
    if (subTabListItem === 'floors') {
      setPage(1)
      getAllFloorTableData(1)
    }
    if (['allUnits', 'residentialUnits', 'commercialUnits', 'commonAreas'].some((unit: string) => unit === subTabListItem)) {
      setPage(1)
      getAllPropertiesTableData(1)
    }
  }, [
    searchUnit,
    searchResidentialUnit,
    searchCommercialUnit,
    searchCommonAreaUnit,
    start_date,
    end_date,
    startDatePayload,
    endDatePayload,
    searchFloor,
    subTabListItem,
  ])

  useEffect(() => {
    countSummary()
  }, [startDatePayload, endDatePayload, subTabListItem, searchUnit, searchCommercialUnit, searchResidentialUnit, searchCommonAreaUnit, searchFloor])

  useEffect(() => {
    generateDates()
    setPage(1)
  }, [subTabListItem])

  useEffect(() => {
    // if (pathname === `/properties/building-details/${id}`) {
    const filters = {
      searchUnit: searchUnit,
      searchResidentialUnit: searchResidentialUnit,
      searchCommercialUnit: searchCommercialUnit,
      searchCommonAreaUnit: searchCommonAreaUnit,
      searchFloor: searchFloor,
    }
    if (subTabListItem === 'residentialUnits' && start_date !== null && end_date !== null) {
      const dateRange = {
        startDate: start_date,
        endDate: end_date,
      }
      localStorage.setItem('buildingDetailResidentialDateFilter', JSON.stringify(dateRange))
    }
    if (subTabListItem === 'allUnits' && start_date !== null && end_date !== null) {
      const dateRange = {
        startDate: start_date,
        endDate: end_date,
      }
      localStorage.setItem('buildingDetailAllUnitsDateFilter', JSON.stringify(dateRange))
    }
    if (subTabListItem === 'commercialUnits' && start_date !== null && end_date !== null) {
      const dateRange = {
        startDate: start_date,
        endDate: end_date,
      }
      localStorage.setItem('buildingDetailCommercialDateFilter', JSON.stringify(dateRange))
    }
    if (subTabListItem === 'commonAreas' && start_date !== null && end_date !== null) {
      const dateRange = {
        startDate: start_date,
        endDate: end_date,
      }
      localStorage.setItem('buildingDetailCommonAreaDateFilter', JSON.stringify(dateRange))
    }
    if (subTabListItem === 'floors' && start_date !== null && end_date !== null) {
      const dateRange = {
        startDate: start_date,
        endDate: end_date,
      }
      localStorage.setItem('buildingDetailFloorDateFilter', JSON.stringify(dateRange))
    }
    localStorage.setItem('allBuildingDetailFilters', JSON.stringify(filters))
    localStorage.setItem('subTabListItemForBuildingDetails', JSON.stringify(subTabListItem))
    // }
  }, [subTabListItem, searchCommercialUnit, searchCommonAreaUnit, searchResidentialUnit, searchUnit, start_date, end_date])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#000;',
      backgroundColor: state.isSelected ? '#007a59;' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '1000',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
      minWidth: '55px',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      // display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multiValue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
      minWidth: '55px',
    }),
  }

  const goToEdit = (listItem: any) => {
    navigate(`/properties/building-details/${id}/edit-property/${listItem._id}`, {
      state: {
        from: 'buildingDetail',
        buildingName: allBuildingTableData?.[0]?.name,
        buildingId: id,
        area: listItem?.building[0]?.area ? listItem?.building[0]?.area : '-',
        city: listItem?.building[0]?.city ? listItem?.building[0]?.city : '-',
      },
    })
  }

  return (
    <>
      {!isSkeleton ? (
        <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
          <div className='row d-flex align-items-center'>
            <div className='col-lg-7'>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex align-items-center mb-1'>
                    <span className='rounded-circle' style={{cursor: 'pointer'}} onClick={() => navigate('/properties')}>
                      <img src={backArrow} height='14' width='14' className='me-2' />
                      <img src={allBuildingBlue} width='40' />
                    </span>
                    <h2 className='m-0 head-text ms-2'>
                      {/* {location?.state?.buildingDetails?.name ? `${location?.state?.buildingDetails?.name}` : `-`} */}
                      {allBuildingTableData?.[0]?.name ? allBuildingTableData?.[0]?.name : '-'}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='row'>
                <div className='col-12 d-flex align-items-center justify-content-end'>
                  <span className='ms-0 fw-bold white-dark-theme-color'> {state?.area ? `${state?.area}` : `-`}</span>
                  <span className='ms-5 fw-bold white-dark-theme-color'>{state?.city ? `${state?.city}` : `-`}</span>
                  <img src={editPen} width='20' className='mx-6' onClick={handleEdit} style={{cursor: 'pointer'}} />
                  {!(subTabListItem === 'commonAreas' || subTabListItem === 'floors') && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold px-2 green-submit-btn'
                      onClick={() => {
                        navigate(`/properties/building-details/${id}/add-property`, {
                          state: {
                            key: 'buildings',
                            fromBuilding: true,
                            fromGlobal: false,
                            buildingId: id,
                            buildingName: allBuildingTableData?.[0]?.name ? allBuildingTableData?.[0]?.name : '',
                            area: state?.area,
                            city: state?.city,
                          },
                        })
                      }}
                    >
                      <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-start pb-5 mb-5' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>

          {/* sub tabs start */}

          <div className=' ps-0 row mt-2'>
            <div className='col-9'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'allUnits' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('allUnits')
                    }}
                  >
                    All Units
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'residentialUnits' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('residentialUnits')
                    }}
                  >
                    Residential Units
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commercialUnits' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('commercialUnits')
                    }}
                  >
                    Commercial Units
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commonAreas' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('commonAreas')
                    }}
                  >
                    Common Areas
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'floors' && 'active'}`}
                    data-bs-toggle='tab'
                    onClick={() => {
                      setSubTabListItem('floors')
                    }}
                  >
                    Floors
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-lg-3'>
              <div className='row'>
                <div className='col-12 d-flex align-items-center justify-content-end'>
                  {(subTabListItem === 'commonAreas' || subTabListItem === 'floors') && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold px-2 green-submit-btn'
                      onClick={() => {
                        navigate(`/properties/building-details/${id}/add-property`, {
                          state: {
                            key: 'buildings',
                            fromBuilding: true,
                            fromGlobal: false,
                            buildingId: id,
                            buildingName: allBuildingTableData?.[0]?.name ? allBuildingTableData?.[0]?.name : '',
                            area: state?.area,
                            city: state?.city,
                          },
                        })
                      }}
                    >
                      <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* sub tabs end */}

          {/* ingights start and filter */}
          <div className='row mt-7'>
            <div className='col-12'>
              <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Units</h4>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img src={unitsBlue} height={50} width={50} className='me-3' />
                    <h4 className='fw-bold'>
                      {(unitCountSummery?.occupiedUnitCount ? unitCountSummery?.occupiedUnitCount : 0) +
                        (unitCountSummery?.vacantUnitCount ? unitCountSummery?.vacantUnitCount : 0)}
                    </h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Tenants</h4>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img src={tenantBlue} height={50} width={50} className='me-3' />
                    <h4 className='fw-bold'>{unitCountSummery?.tenantCount ?? 0}</h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Leases</h4>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img src={leasesBlue} height={50} width={50} className='me-3' />
                    <h4 className='fw-bold'>{unitCountSummery?.tenancyCount ?? 0}</h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Occupancy</h4>
                  <div className='d-flex justify-content-start align-items-top'>
                    <img src={occupancyBlue} height={45} width={45} className='me-3' />
                    <h4 className='fw-bold ms-2' style={{marginTop: '15px'}}>
                      {unitCountSummery?.occupancyRate >= 0 ? `${Math.round(unitCountSummery?.occupancyRate)}%` : 0}
                    </h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Income</h4>
                  <div style={{marginTop: '18px'}}>
                    <h4 className='fw-bold' style={{color: '#00b8a9'}}>
                      AED
                    </h4>
                    <h4 className='fw-bold'>
                      {`${parseFloat(unitCountSummery?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                    </h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Expense</h4>
                  <div style={{marginTop: '18px'}}>
                    <h4 className='fw-bold' style={{color: '#e46464 '}}>
                      AED
                    </h4>
                    <h4 className='fw-bold'>
                      {`${parseFloat(unitCountSummery?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                    </h4>
                  </div>
                </div>
                <div className='customCard py-4 px-7 shadow' style={{width: '12rem', height: '90px'}}>
                  <h4 className='text-gray-500'>Rating</h4>
                  <div className='d-flex justify-content-between align-items-end'>
                    <img src={yellowStar} height={35} width={35} className='me-3 mt-3' />
                    <h4 className='fw-bold'>{unitCountSummery?.ratingCount ?? 0}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5' style={{gap: '0px'}}>
              <div className={`d-flex align-items-center  px-0 col-10`}>
                <div className='xyz me-5 pe-3'>
                  <label className='head-text' style={{fontWeight: '600'}}>
                    {' '}
                    Search{' '}
                  </label>
                </div>

                {subTabListItem === 'floors' && (
                  <div className='xyz me-4'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Floor'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'Floor'}}
                        value={searchFloor}
                        onChange={(e: any) => setSearchFloor(e.target.value)}
                      />
                      <IconButton style={searchIconStyle} aria-label='Floor'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                )}

                {subTabListItem === 'allUnits' && (
                  <div className='xyz me-4'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Unit'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'Unit'}}
                        value={searchValue}
                        onChange={(e: any) => {
                          setSearchValue(e.target.value)
                          // setSearchUnit(e.target.value)
                        }}
                      />
                      <IconButton style={searchIconStyle} aria-label='Unit'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                )}
                {subTabListItem === 'residentialUnits' && (
                  <div className='xyz me-4'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Unit'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'Unit'}}
                        value={searchValueResidential}
                        onChange={(e: any) => setSearchValueResidential(e.target.value)}
                      />
                      <IconButton style={searchIconStyle} aria-label='Unit'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                )}
                {subTabListItem === 'commercialUnits' && (
                  <div className='xyz me-4'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Unit'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'Unit'}}
                        value={searchValueCommercial}
                        onChange={(e: any) => setSearchValueCommercial(e.target.value)}
                      />
                      <IconButton style={searchIconStyle} aria-label='Unit'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                )}
                {subTabListItem === 'commonAreas' && (
                  <div className='xyz me-4'>
                    <Paper component='form' style={searchContainerStyle}>
                      <InputBase
                        placeholder='Unit'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'Unit'}}
                        value={searchValueCommonArea}
                        onChange={(e: any) => setSearchValueCommonArea(e.target.value)}
                      />
                      <IconButton style={searchIconStyle} aria-label='Unit'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                )}

                <div className='test property me-4'>
                  <CustomDateRangePicker
                    startDate={start_date}
                    endDate={end_date}
                    dateStatuses={[]}
                    onDatesChange={handleDatesChange}
                    //  datesToCompare={datesToCompare}
                    autoSave={() => {}}
                    from={'create'}
                    disabled={false}
                  />
                </div>
              </div>

              {/* <div className={`px-0 col-2 d-flex justify-content-end`}>
                {subTabListItem === 'floors' && (
                  <div className=''>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold px-2 green-submit-btn'
                      onClick={() => {
                        setAddFloorModel(true)
                      }}
                    >
                      <img src={addWhiteIcon} height={18} width={18} className='me-5' /> Add Floor
                    </button>
                  </div>
                )}
              </div> */}
            </div>
          </div>
          {/* ingights end and fitlers*/}
          {(subTabListItem === 'allUnits' || subTabListItem === 'residentialUnits' || subTabListItem === 'commercialUnits') && (
            <PropertiesList
              isFloorCol={true}
              goToEdit={goToEdit}
              isEditBtn={true}
              listData={allPropertiesTableData}
              goToMiantenance={goToMiantenance}
              handlePageClick={handlePageClick}
              page={page}
              pageLimit={pageLimit}
            />
          )}

          {subTabListItem === 'commonAreas' && (
            <CommonAreaList
              goToEdit={goToEdit}
              isEditBtn={true}
              isFloorCol={true}
              listData={allPropertiesTableData}
              goToMiantenance={goToMiantenance}
              handlePageClick={handlePageClick}
              page={page}
              pageLimit={pageLimit}
            />
          )}

          {subTabListItem === 'floors' && (
            <div className='card card-flush py-5  px-7'>
              <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-dashed fs-8 gy-5 ' id='kt_ecommerce_sales_table'>
                    <thead className='fs-7'>
                      <th className='text-start min-w-50px ps-0'></th>
                      <th className='text-start min-w-100px ps-0'>Floor</th>
                      <th className='text-start min-w-100px ps-0'>Units</th>
                      <th className='text-start min-w-100px ps-0'>Common Areas</th>
                      <th className='text-center min-w-125px ps-0 '>Occupancy Rate</th>
                      <th className='text-start min-w-100px ps-0'>Occupied</th>
                      <th className='text-start min-w-100px ps-0'>Vacant</th>
                      <th className='text-start min-w-100px ps-0'>Total Income</th>
                      <th className='text-start min-w-100px ps-0'>Total Expenses</th>
                      <th className='text-start min-w-100px ps-0 '>Rating</th>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {allFloorTableData?.length > 0 ? (
                        allFloorTableData?.map((listItem: any, i: any) => {
                          return (
                            <tr>
                              <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`} onClick={() => goToMiantenance(listItem)}>
                                <img
                                  src={listItem?.images?.length > 0 ? `${Bucket}${listItem?.images?.[0]}` : floorsBlue}
                                  height={30}
                                  className='m-0 p-0'
                                />
                              </td>
                              <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                                {listItem?.name ? listItem?.name : '-'}
                              </td>
                              <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                                {listItem?.totalUnit ?? '-'}
                              </td>

                              <td className='text-start cursor-pointer ps-0' onClick={() => goToMiantenance(listItem)}>
                                {listItem?.totalCommonAreaUnit ?? '-'}
                              </td>
                              <td
                                className={`text-center p-0 cursor-pointer d-flex justify-content-center`}
                                onClick={() => goToMiantenance(listItem)}
                              >
                                {' '}
                                <div
                                  className={` status ${
                                    Math.round(listItem?.occupancyRate) <= 50 && Math.round(listItem?.occupancyRate) > 0
                                      ? 'cancelled-st'
                                      : Math.round(listItem?.occupancyRate) <= 75 && Math.round(listItem?.occupancyRate) > 50
                                      ? 'requested-st'
                                      : Math.round(listItem?.occupancyRate) <= 99 && Math.round(listItem?.occupancyRate) > 75
                                      ? 'draft-st'
                                      : Math.round(listItem?.occupancyRate) >= 100
                                      ? 'create-st'
                                      : 'not-saved'
                                  }`}
                                >
                                  {listItem?.occupancyRate ? `${Math.round(listItem?.occupancyRate)}%` : '-'}
                                </div>
                              </td>

                              <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                                {listItem?.totalOccupiedUnit ?? '-'}
                              </td>
                              <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                                {listItem?.totalVacantUnit ?? '-'}
                              </td>

                              <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                                {' '}
                                {/* {`AED ${listItem?.incomePaymentCount ?? '-'}`} */}
                                {`AED ${parseFloat(listItem?.incomePaymentCount)?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}`}
                              </td>

                              <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                                {' '}
                                {/* {`AED ${listItem?.expensePaymentCount ?? '-'}`} */}
                                {`AED ${parseFloat(listItem?.expensePaymentCount)?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}`}
                              </td>

                              <td className='text-start cursor-pointer ps-0 min-w-100px' onClick={() => goToMiantenance(listItem)}>
                                {' '}
                                <div className='d-flex align-items-center '>
                                  <img src={yellowStar} height={18} width={18} className='me-1' />
                                  {listItem?.rating != undefined ? listItem?.rating : '-'}
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <img src={noData} alt='' width={350} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination start*/}
                <div className='row mb-5'>
                  <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                  <div className='row mt-5'>
                    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                      <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          pageClassName='paginate_button page-item +'
                          pageLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                          previousClassName='paginate_button page-item previous'
                          nextClassName='paginate_button page-item next'
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          forcePage={page - 1}
                          previousLabel='<'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* pagination end*/}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {addFloorModel && (
        <AddFloorModel
          buildingName={allBuildingTableData?.[0]?.name}
          buildingId={id}
          show={addFloorModel}
          isBuilding={true}
          isUnitCluster={false}
          isMixedCluster={false}
          handleClose={(e: any) => {
            setAddFloorModel(false)
            getAllFloorTableData()
          }}
        />
      )}
    </>
  )
}

export default PropertiesDetailList

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import {MultiSelect} from 'react-multi-select-component'

interface FiltersProps {
  showw?: any
  handleClose?: any
  updateParent?: any
}

const Filters: React.FC<FiltersProps> = ({showw, handleClose, updateParent}) => {
  const {state} = useLocation()
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [tenancyStatus, setTenancyStatus] = useState<any>([])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [hideContract, setHideContract] = React.useState<any>(true)
  const [hideStatus, setHideStatus] = React.useState<any>(true)
  const [hideTenant, setHideTenant] = React.useState<any>(true)
  const [hideProperty, setHideProperty] = React.useState<any>(true)
  const [hideFinancials, setHideFinancials] = React.useState<any>(true)

  //
  const handleTermStartDateChange = (date: any) => {
    if (date != null) setStartDate(date.format('YYYY-MM-DD'))
    else setStartDate(null)
  }

  const handleTermEndDateChange = (date: any) => {
    if (date != null) setEndDate(date.format('YYYY-MM-DD'))
    else setEndDate(null)
  }

  const resetFilters = () => {
    setSelectedExpenseItems([])
    setSelectedPaymentAccount([])
    setSelectedVendor([])
    setSelectedTenant([])
    setDueStartDate(null)
    setDueEndDate(null)
    setPaidStartDate(null)
    setPaidStartDate(null)
    setExpenseFromAmount('')
    setExpenseToAmount('')
    setPaymentStatus([])
    setPaymentCategory([])
    setSelectedPaymentMethod([])
    setSelectedCommercialUnitType([])
    setSelectedResidentialUnitType([])
    setSelectedBedrooms([])
    setSelectedBathCommercial([])
    setSelectedBathResidential([])
    setResidentialStartSize(null)
    setResidentialEndSize(null)
    setCommercialStartSize(null)
    setCommercialEndSize(null)
    setSelectedResidentialCommonAreas([])
    setSelectedCommercialCommonAreas([])
    setOccupancyStatus([])
    setTenancyStatus([])
    setSelectedContractNo([])
    setStartDate(null)
    setEndDate(null)
    setSelectedDevelopments([])
    setSelectedClusters([])
    setSelectedUnitGrps([])
    setSelectedFloorGrps([])
    setSelectedUnits([])
  }

  const [expenseItemsList, setExpenseItemsList] = useState<any>([])
  const [selectedExpenseItems, setSelectedExpenseItems] = useState<any>([])

  const [paymentAccountList, setPaymentAccountList] = useState<any>([])
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<any>([])

  const [paymentMethodList, setPaymentMethodList] = useState<any>([
    {
      label: 'Bank Transfer',
      value: '2',
    },
    {
      label: 'Cash',
      value: '3',
    },
    {
      label: 'Cheque',
      value: '1',
    },
  ])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])

  const [dueStartDate, setDueStartDate] = useState<any>(null)
  const [dueEndDate, setDueEndDate] = useState<any>(null)

  const [paidStartDate, setPaidStartDate] = useState<any>(null)
  const [paidEndDate, setPaidEndDate] = useState<any>(null)

  const [expenseFromAmount, setExpenseFromAmount] = useState<any>('')
  const [expenseToAmount, setExpenseToAmount] = useState<any>('')

  const [paymentStatus, setPaymentStatus] = useState<any>([])
  const [paymentCategory, setPaymentCategory] = useState<any>([])

  const [vendorsList, setVendorsList] = useState<any>([])
  const [selectedVendor, setSelectedVendor] = useState<any>([])

  const [tenantsList, setTenantsList] = useState<any>([])
  const [selectedTenant, setSelectedTenant] = useState<any>([])

  const [residentialCommonAreasList, setResidentialCommonAreasList] = useState<any>([])
  const [selectedResidentialCommonAreas, setSelectedResidentialCommonAreas] = useState<any>([])

  const [commercialCommonAreasList, setCommercialCommonAreasList] = useState<any>([])
  const [selectedCommercialCommonAreas, setSelectedCommercialCommonAreas] = useState<any>([])

  const [othersList, setOthersList] = useState<any>([])
  const [otherSelectedResidential, setOtherSelectedResidential] = useState<any>([])
  const [otherSelectedCommercial, setOtherSelectedCommercial] = useState<any>([])

  const [selectedBedrooms, setSelectedBedrooms] = useState<any>([])
  const [selectedBathResidential, setSelectedBathResidential] = useState<any>([])
  const [selectedBathCommercial, setSelectedBathCommercial] = useState<any>([])

  const bathroomOptions = [
    {
      value: 1,
      label: '1 Bath',
    },
    {value: 2, label: '2 Bath'},
    {value: 3, label: '3 Bath'},
    {value: 4, label: '4 Bath'},
    {value: 5, label: '5 Bath'},
    {value: 6, label: '6 Bath'},
    {value: 7, label: '7 Bath'},
    {value: 8, label: '8 Bath'},
    {value: 9, label: '9 Bath'},
    {value: 10, label: '10 Bath'},
  ]

  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ]

  //
  const getVendorsForDropdown = async () => {
    await ApiGet(`corporate/vendor`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.companyName}`,
            value: `${val?._id}`,
          }
        })
        setVendorsList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getTenantsForDropdown = async () => {
    await ApiGet(`corporate/tenant?isNullFilter=true`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.firstName} ${val?.lastName}`,
            value: `${val?._id}`,
          }
        })
        setTenantsList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const getTenancyForDropdown = async () => {
    await ApiGet(`corporate/tenancy`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.contractNo}`,
            value: `${val?._id}`,
          }
        })
        setContractNoList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getItemsForDropdown = async () => {
    await ApiGet(`corporate/payment_sub_item_list?activityStatus=1`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setExpenseItemsList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const getAccounts = () => {
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
          }
        })

        setPaymentAccountList(values)
      })
      .catch((err) => console.log('err', err))
  }

  const getCommonTypes = async () => {
    await ApiGet(`test/common/type`)
      .then((res: any) => {
        const commonAreas = res?.data?.data?.databaseTypes?.commonAreasList
          ?.filter((commonAreaItem: any, i: any) => commonAreaItem !== 'Other')
          .map((item: any) => ({value: item, label: item}))
        setResidentialCommonAreasList(commonAreas)
        setCommercialCommonAreasList(commonAreas)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  const getOtherUnitTypes = async () => {
    await ApiGet(`corporate/unit?getArrayJsonOtherType=true`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.otherName}`,
            value: `${val?._id}`,
          }
        })
        setOthersList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getItemsForDropdown()
    getAccounts()
    getVendorsForDropdown()
    getTenantsForDropdown()
    getCommonTypes()
    getOtherUnitTypes()
    getTenancyForDropdown()
  }, [])

  const [commercialStartSize, setCommercialStartSize] = useState<any>(null)
  const [commercialEndSize, setCommercialEndSize] = useState<any>(null)

  const [residentialStartSize, setResidentialStartSize] = useState<any>(null)
  const [residentialEndSize, setResidentialEndSize] = useState<any>(null)

  const [commercialUnitType, setSelectedCommercialUnitType] = useState<any>([])
  const [residentialUnitType, setSelectedResidentialUnitType] = useState<any>([])

  const [occupancyStatus, setOccupancyStatus] = useState<any>([])
  const [contractNoList, setContractNoList] = useState<any>([])
  const [selectedContractNo, setSelectedContractNo] = useState<any>([])

  //
  let setFilterData = () => {
    let tIds: any = []
    selectedTenant?.map((tnt: any, ind: any) => {
      tIds[ind] = tnt.value
    })

    let vIds: any = []
    selectedVendor?.map((vnd: any, ind: any) => {
      vIds[ind] = vnd.value
    })

    let accIds: any = []
    selectedPaymentAccount?.map((acc: any, ind: any) => {
      accIds[ind] = acc.value
    })

    let expMethod: any = []
    selectedPaymentMethod?.map((acc: any, ind: any) => {
      expMethod[ind] = acc.value
    })

    let pIds: any = []
    selectedExpenseItems?.map((acc: any, ind: any) => {
      pIds[ind] = acc.value
    })

    let otherRes: any = []
    otherSelectedResidential?.map((acc: any, ind: any) => {
      otherRes[ind] = acc.label
    })

    let otherComm: any = []
    otherSelectedCommercial?.map((acc: any, ind: any) => {
      otherComm[ind] = acc.label
    })

    let bathComm: any = []
    selectedBathCommercial?.map((acc: any, ind: any) => {
      bathComm[ind] = acc.value
    })

    let bathRes: any = []
    selectedBathResidential?.map((acc: any, ind: any) => {
      otherComm[ind] = acc.value
    })

    let beds: any = []
    selectedBedrooms?.map((acc: any, ind: any) => {
      beds[ind] = acc.value
    })

    let commUnitType: any = []
    commercialUnitType?.map((acc: any, ind: any) => {
      commUnitType[ind] = acc
    })

    let resUnitType: any = []
    residentialUnitType?.map((acc: any, ind: any) => {
      resUnitType[ind] = acc
    })

    let contract: any = []
    selectedContractNo?.map((acc: any, ind: any) => {
      contract[ind] = acc.value
    })

    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitGrp[ind] = dev?.value
      })

    let floorIds: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorIds[ind] = dev?.value
      })

    let unIds: any = []
    selectedUnits?.length > 0 &&
      selectedUnits?.map((dev: any, ind: any) => {
        unIds[ind] = dev?.value
      })

    let resComm:any = []
    selectedResidentialCommonAreas?.length > 0 &&
      selectedResidentialCommonAreas?.map((cmm: any, ind: any) => {
        resComm[ind] = cmm?.value
      })

    let commercialComm:any = []
    selectedCommercialCommonAreas?.length > 0 &&
      selectedCommercialCommonAreas?.map((cmm: any, ind: any) => {
        commercialComm[ind] = cmm?.value
      })

    const body = {
      page: 1,
      limit: 10,
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
      floorIds: floorIds,
      unitIds: unIds,
      tenantPayeeIds: tIds,
      vendorPayeeIds: vIds,
      paymentAccountIds: accIds,
      expenseStatus: paymentStatus,
      categories: paymentCategory,
      expenseMethod: expMethod,
      expensePaymentItemIds: pIds,
      expenseDueDate: {
        startDate: dueStartDate,
        endDate: dueEndDate,
      },
      expenseDatePaid: {
        startDate: paidStartDate,
        endDate: paidEndDate,
      },
      searchExpense: '',
      searchPayee: '',
      searchPaymentAccount: '',
      commercialProperty: {
        unitType: commUnitType,
        otherNameList: otherComm,
        bathrooms: bathComm,
        size: {
          start: commercialStartSize,
          end: commercialEndSize,
        },
        commonAreaNameList: commercialComm
        
      },
      residentialProperty: {
        unitType: resUnitType,
        otherNameList: otherRes,
        bedrooms: bathRes,
        bathrooms: beds,
        size: {
          start: residentialStartSize,
          end: residentialEndSize,
        },
        commonAreaNameList: resComm
      },
      leaseObject: {
        tenancyStatus: tenancyStatus,
        occupancyStatus: occupancyStatus,
        // searchContractNo: '',
        contractNoList: contract,
        duration: {
          startDate: startDate,
          endDate: endDate,
        },
      },
    }

    updateParent(body)
  }

  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>([])

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>([])

  const [building, setBuilding] = useState<any>(false)
  const [community, setCommunity] = useState<any>(false)

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  const [selectedFloorGrps, setSelectedFloorGrps] = useState<any>([])
  const [floorGrpsLists, setFloorGrpsLists] = useState<any>([])

  const [selectedUnits, setSelectedUnits] = useState<any>([])
  const [unitsLists, setUnitsLists] = useState<any>([])

  // Development
  const getDevelopment = () => {
    const body = {
      search: searchDevelopment,
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }
          })

        setDevelopmentLists(values)

        // if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {})
  }

  React.useEffect(() => {
    getDevelopment()
    // getFloor()
    // setDefaultOccupancy()
  }, [])

  React.useEffect(() => {
    if (selectedDevelopments?.length > 0) {
      setIsFirst(false)
    } else {
      setSelectedUnits([])
      setSelectedFloorGrps([])
      setSelectedUnitGrps([])
      setSelectedClusters([])
    }
  }, [selectedDevelopments])

  // Clusters
  const getClusters = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      clusterIds: [],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setClustersLists(values)

        // if (selectedClusters?.length == 0 && selectedDevelopments?.length != 0)
        //   setSelectedClusters(values)
        // else {
        //   const filteredValues = selectedClusters.filter((cluster: any) =>
        //     values.some((value: any) => value.value === cluster.value)
        //   )
        //   if (selectedDevelopments?.length == 0) {
        //     setClustersLists([])
        //     setSelectedClusters([])
        //   } else setSelectedClusters(filteredValues)
        // }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getClusters()
  }, [selectedDevelopments, isFirst])

  //  Unit Groups
  const getUnitGroups = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      unitGroupIds: [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setUnitGrpsLists(values)

        // if (selectedUnitGrps?.length == 0 && selectedDevelopments?.length != 0)
        //   setSelectedUnitGrps(values)
        // else {
        //   const filteredValues = selectedUnitGrps.filter((grp: any) =>
        //     values.some((value: any) => value.value === grp.value)
        //   )

        //   if (selectedDevelopments?.length == 0) {
        //     setUnitGrpsLists([])
        //     setSelectedUnitGrps([])
        //   } else setSelectedUnitGrps(filteredValues)
        // }

        // if (clustIds?.length == 0) {
        //   setUnitGrpsLists([])
        //   setSelectedUnitGrps([])
        // }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getUnitGroups()
  }, [selectedClusters, isFirst])

  //
  const getFloor = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitGrp[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/floor/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setFloorGrpsLists(values)

        // if (selectedFloorGrps?.length == 0 && selectedDevelopments?.length != 0)
        //   setSelectedFloorGrps(values)
        // else {
        //   const filteredValues = selectedFloorGrps.filter((grp: any) =>
        //     values.some((value: any) => value.value === grp.value)
        //   )

        //   if (selectedDevelopments?.length == 0) {
        //     setFloorGrpsLists([])
        //     setSelectedFloorGrps([])
        //   } else setSelectedFloorGrps(filteredValues)
        // }

        setIsFirst(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getFloor()
  }, [selectedDevelopments, isFirst])

  //
  const getUnits = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitGrp[ind] = dev?.value
      })

    let floorIds: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorIds[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
      floorIds: floorIds,
    }

    ApiPost(`corporate/development/unit/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.unitNo}`,
              value: `${val?._id}`,
            }
          })

        setUnitsLists(values)

        // if (selectedUnits?.length == 0 && selectedDevelopments?.length != 0)
        //   setSelectedUnits(values)
        // else {
        //   const filteredValues = selectedUnits.filter((grp: any) =>
        //     values.some((value: any) => value.value === grp.value)
        //   )

        //   if (selectedDevelopments?.length == 0) {
        //     setSelectedUnits([])
        //     setSelectedFloorGrps([])
        //     setSelectedUnitGrps([])
        //     setSelectedClusters([])
        //   } else setSelectedUnits(filteredValues)

        //   selectedDevelopments?.map((sl: any) => {
        //     if (sl?.devType == 'build') {
        //       if (selectedFloorGrps?.length == 0) {
        //         setSelectedUnits([])
        //         setUnitsLists([])
        //         return
        //       }
        //     } else {
        //       if (selectedClusters?.length == 0) {
        //         setSelectedUnits([])
        //         setUnitsLists([])
        //         return
        //       }
        //     }
        //   })
        // }

        setIsFirst(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getUnits()
  }, [selectedDevelopments, selectedClusters, selectedUnitGrps, selectedFloorGrps])

  useEffect(() => {
    let isBuild: any = false
    let isComm: any = false

    if (selectedDevelopments?.length == 0) {
      setCommunity(true)
      setBuilding(true)
    } else {
      selectedDevelopments?.map((sl: any) => {
        if (sl?.devType == 'comm') isComm = true

        if (sl?.devType == 'build') isBuild = true
      })

      setCommunity(isComm)
      setBuilding(isBuild)
    }
  }, [selectedDevelopments])

  useEffect(() => {
    setFilterData()
  }, [
    selectedExpenseItems,
    selectedPaymentAccount,
    selectedVendor,
    selectedTenant,
    dueStartDate,
    dueEndDate,
    paidStartDate,
    paidStartDate,
    expenseFromAmount,
    expenseToAmount,
    paymentStatus,
    paymentCategory,
    selectedPaymentMethod,
    commercialUnitType,
    residentialUnitType,
    selectedBedrooms,
    selectedBathCommercial,
    selectedBathResidential,
    residentialStartSize,
    residentialEndSize,
    commercialStartSize,
    commercialEndSize,
    selectedResidentialCommonAreas,
    selectedCommercialCommonAreas,
    occupancyStatus,
    tenancyStatus,
    selectedContractNo,
    startDate,
    endDate,
    selectedDevelopments,
    selectedClusters,
    selectedUnitGrps,
    selectedFloorGrps,
    selectedUnits,
    otherSelectedCommercial,
    otherSelectedResidential
  ])

  const [devType, setDevType] = useState<any>([])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.single-select .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2'>
        {/* Filters */}
        <div
          className={showw == true ? 'filter-payments test ' : 'd-none'}
          style={{
            width: '420px',
            backgroundColor: 'white',
            zIndex: '999',
            boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
            paddingBottom: '50px',
          }}
        >
          <div
            className='card m-0 p-0 '
            style={{
              overflow: 'hidden',
              borderRadius: '0px',
              boxShadow: 'none',
            }}
          >
            {/* header */}
            <h3
              className='m-0 d-flex align-items-center msg_title'
              style={{
                justifyContent: 'space-between',
                borderRadius: '0px',
                zIndex: '1',
                opacity: '0.85',
              }}
            >
              Filter
              <span className='mx-0' style={{cursor: 'pointer', marginTop: '-20px'}}>
                <img src={closes} alt='' onClick={() => handleClose()} />
              </span>
            </h3>

            <h3
              className='m-0 d-flex align-items-center '
              style={{
                justifyContent: 'flex-end',
                borderRadius: '0px',
                transform: 'translate(0px, -50px)',
                color: 'white',
                backgroundColor: '#146C6A',
                paddingBottom: '5px',
                background:
                  'linear-gradient(41deg, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 0%)',
              }}
            >
              <span className='mx-1' style={{cursor: 'pointer'}}>
                <svg
                  style={{
                    height: '40px',
                    width: '38px',
                    marginRight: '7px',
                    marginLeft: '-10px',
                    marginTop: '5px',
                    transform: 'translate(-30px, 0px)',
                  }}
                  viewBox='0 0 1024 1024'
                  xmlns='http://www.w3.org/2000/svg'
                  className='me-1'
                >
                  <path
                    fill='#ffff'
                    d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                  />
                </svg>
              </span>
            </h3>
          </div>

          <div
            style={{
              height: '100%',
              overflow: 'scroll',
              transform: 'translate(0px, -50px)',
              overflowX: 'hidden',
            }}
          >
            {/* Expenses */}
            <div className='min-w-200px  ps-3 pt-1 card' style={{borderRadius: '0px'}}>
              <div className='ms-auto mt-3 me-5'>
                <div
                  className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                  onClick={() => resetFilters()}
                >
                  <img src={sync} height={22} width={24} className='cursor-pointer' />
                </div>
              </div>
              <label className='mb-2 ms-2' style={{fontWeight: '700', fontSize: '14px'}}>
                {' '}
                Expenses
              </label>

              {hideContract !== true && (
                <>
                  {/* Expense Items */}
                  <div className='d-flex align-items-baseline ms-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Expense Items
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedExpenseItems.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={expenseItemsList}
                        value={selectedExpenseItems}
                        onChange={(e: any) => {
                          // console.log(e)
                          let newVal: any = e

                          setSelectedExpenseItems(newVal)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Items',
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payment Account */}
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Payment Account
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedPaymentAccount.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={paymentAccountList}
                        value={selectedPaymentAccount}
                        onChange={(e: any) => {
                          // console.log(e)
                          let newVal: any = e

                          setSelectedPaymentAccount(newVal)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Accounts',
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payee */}
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Payee
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div className='d-flex'>
                      <div
                        className={`multi-select-container property-filter-vendor  ms-0 me-5  ${
                          selectedVendor.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={vendorsList}
                          value={selectedVendor}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedVendor(newVal)
                          }}
                          labelledBy='Vendors'
                          overrideStrings={{
                            allItemsAreSelected: 'All Vendors',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                      <div
                        className={`multi-select-container property-filter-tenant  ms-0 mx-5  ${
                          selectedTenant.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={tenantsList}
                          value={selectedTenant}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedTenant(newVal)
                          }}
                          labelledBy='Tenants'
                          overrideStrings={{
                            allItemsAreSelected: 'All Tenants',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payment Due Date */}
                  <div className='d-flex align-items-center ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                      style={{fontWeight: '600'}}
                    >
                      Payment Due Date
                    </label>
                  </div>
                  <div className='d-flex mb-2 ms-2 ' style={{paddingLeft: '27px'}}>
                    {dueStartDate == null ? (
                      <DatePicker
                        onChange={(date: any) => {
                          if (date != null) setDueStartDate(date.format('YYYY-MM-DD'))
                          else setDueStartDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(dueStartDate, 'DD-MM-YYYY')}
                        onChange={(date: any) => {
                          if (date != null) setDueStartDate(date.format('YYYY-MM-DD'))
                          else setDueStartDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    )}
                    {dueEndDate == null ? (
                      <DatePicker
                        onChange={(date: any) => {
                          if (date != null) setDueEndDate(date.format('YYYY-MM-DD'))
                          else setDueEndDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(dueEndDate, 'DD-MM-YYYY')}
                        onChange={(date: any) => {
                          if (date != null) setDueEndDate(date.format('YYYY-MM-DD'))
                          else setDueEndDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    )}
                  </div>
                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Date Paid */}
                  <div className='d-flex align-items-center ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                      style={{fontWeight: '600'}}
                    >
                      Date Paid
                    </label>
                  </div>
                  <div className='d-flex mb-2 ms-2 ' style={{paddingLeft: '27px'}}>
                    {paidStartDate == null ? (
                      <DatePicker
                        onChange={(date: any) => {
                          if (date != null) setPaidStartDate(date.format('YYYY-MM-DD'))
                          else setPaidStartDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(paidStartDate, 'DD-MM-YYYY')}
                        onChange={(date: any) => {
                          if (date != null) setPaidStartDate(date.format('YYYY-MM-DD'))
                          else setPaidStartDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    )}
                    {paidEndDate == null ? (
                      <DatePicker
                        onChange={(date: any) => {
                          if (date != null) setPaidEndDate(date.format('YYYY-MM-DD'))
                          else setPaidEndDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(paidEndDate, 'DD-MM-YYYY')}
                        onChange={(date: any) => {
                          if (date != null) setPaidEndDate(date.format('YYYY-MM-DD'))
                          else setPaidEndDate(null)
                        }}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    )}
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Expense Amount */}
                  <div className='d-flex align-items-center ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                      style={{fontWeight: '600'}}
                    >
                      Expense Amount
                    </label>
                  </div>
                  <div className='d-flex mb-2 ms-2 xyz' style={{paddingLeft: '27px'}}>
                    <Paper component='form' style={searchContainerStyle} className='me-5'>
                      <InputBase
                        type='number'
                        placeholder='From (AED)'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={expenseFromAmount}
                        onChange={(e: any) => {
                          setExpenseFromAmount(e.target.value)
                        }}
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>

                    <Paper component='form' style={searchContainerStyle} className='me-5'>
                      <InputBase
                        type='number'
                        placeholder='To (AED)'
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={expenseToAmount}
                        onChange={(e: any) => {
                          setExpenseToAmount(e.target.value)
                        }}
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payment Status */}
                  <div className='d-flex align-items-baseline mt-2 ms-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Payment Status
                    </label>
                  </div>
                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center xyz'>
                      <input
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        checked={
                          paymentStatus.length == 3 || paymentStatus.length == 0 ? true : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked == true) {
                            let values: any = []
                            setPaymentStatus(values)
                          } else {
                            setPaymentStatus([])
                          }
                        }}
                      />{' '}
                      All
                    </div>
                  </div>

                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentStatus[0] == 0 ||
                              paymentStatus[1] == 0 ||
                              paymentStatus[2] == 0) &&
                            paymentStatus.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentStatus.length == 3) {
                                let values = [0]
                                setPaymentStatus(values)
                              } else {
                                let values = [...paymentStatus]
                                values[values.length] = 0
                                setPaymentStatus(values)
                              }
                            } else {
                              let values = [...paymentStatus]
                              if (values[0] == 0) values.splice(0, 1)
                              else if (values[1] == 0) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Unpaid
                        </p>
                      </div>
                    </div>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentStatus[0] == 2 ||
                              paymentStatus[1] == 2 ||
                              paymentStatus[2] == 2) &&
                            paymentStatus.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentStatus.length == 3) {
                                let values = [2]
                                setPaymentStatus(values)
                              } else {
                                let values = [...paymentStatus]
                                values[values.length] = 2
                                setPaymentStatus(values)
                              }
                            } else {
                              let values = [...paymentStatus]
                              if (values[0] == 2) values.splice(0, 1)
                              else if (values[1] == 2) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Partially Paid
                        </p>
                      </div>
                    </div>

                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentStatus[0] == 1 ||
                              paymentStatus[1] == 1 ||
                              paymentStatus[2] == 1) &&
                            paymentStatus.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentStatus.length == 3) {
                                let values = [1]
                                setPaymentStatus(values)
                              } else {
                                let values = [...paymentStatus]
                                values[values.length] = 1
                                setPaymentStatus(values)
                              }
                            } else {
                              let values = [...paymentStatus]
                              if (values[0] == 1) values.splice(0, 1)
                              else if (values[1] == 1) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Paid
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payment Category */}
                  <div className='d-flex align-items-baseline mt-2 ms-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Payment Category
                    </label>
                  </div>
                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center xyz'>
                      <input
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        checked={
                          paymentCategory.length == 3 || paymentCategory.length == 0 ? true : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked == true) {
                            let values = [0, 1, 2]
                            setPaymentCategory(values)
                          } else {
                            setPaymentCategory([])
                          }
                        }}
                      />{' '}
                      All
                    </div>
                  </div>

                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentCategory[0] == 0 ||
                              paymentCategory[1] == 0 ||
                              paymentCategory[2] == 0) &&
                            paymentCategory.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentCategory.length == 3) {
                                let values = [0]
                                setPaymentCategory(values)
                              } else {
                                let values = [...paymentCategory]
                                values[values.length] = 0
                                setPaymentCategory(values)
                              }
                            } else {
                              let values = [...paymentCategory]
                              if (values[0] == 0) values.splice(0, 1)
                              else if (values[1] == 0) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentCategory(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Uncoming
                        </p>
                      </div>
                    </div>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentCategory[0] == 1 ||
                              paymentCategory[1] == 1 ||
                              paymentCategory[2] == 1) &&
                            paymentCategory.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentCategory.length == 3) {
                                let values = [1]
                                setPaymentCategory(values)
                              } else {
                                let values = [...paymentCategory]
                                values[values.length] = 1
                                setPaymentCategory(values)
                              }
                            } else {
                              let values = [...paymentCategory]
                              if (values[0] == 1) values.splice(0, 1)
                              else if (values[1] == 1) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentCategory(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Overdue
                        </p>
                      </div>
                    </div>

                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (paymentCategory[0] == 2 ||
                              paymentCategory[1] == 2 ||
                              paymentCategory[2] == 2) &&
                            paymentCategory.length != 3
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (paymentCategory.length == 3) {
                                let values = [2]
                                setPaymentCategory(values)
                              } else {
                                let values = [...paymentCategory]
                                values[values.length] = 2
                                setPaymentCategory(values)
                              }
                            } else {
                              let values = [...paymentCategory]
                              if (values[0] == 2) values.splice(0, 1)
                              else if (values[1] == 2) values.splice(1, 1)
                              else values.splice(2, 1)
                              setPaymentCategory(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Complete
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Payment Method */}
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Payment Method
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedPaymentMethod.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={paymentMethodList}
                        value={selectedPaymentMethod}
                        onChange={(e: any) => {
                          // console.log(e)
                          let newVal: any = e

                          setSelectedPaymentMethod(newVal)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Payment Methods',
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className='ms-auto mt-3 mb-3 me-5'>
                <div className='br-arr d-flex align-items-center justify-content-center'>
                  {hideContract == false ? (
                    <img
                      src={upGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideContract(true)}
                    />
                  ) : (
                    <img
                      src={downGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideContract(false)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Development */}
            <div className='min-w-200px card ps-3  pt-1  mt-1' style={{borderRadius: '0px'}}>
              <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
                {' '}
                Development
              </label>
              {!hideTenant && (
                <>
                  <div className='d-flex align-items-baseline ms-2 '>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Type
                    </label>
                  </div>
                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center xyz'>
                      <input
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        checked={
                          devType.length == 2 || devType.length == 0 ? true : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked == true) {
                            let values = [0, 1]
                            setDevType(values)
                          } else {
                            setDevType([])
                          }
                        }}
                      />{' '}
                      All
                    </div>
                  </div>

                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (devType[0] == 0 || devType[1] == 0) &&
                            devType.length != 2
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (devType.length == 2) {
                                // setDevType([])
                                let values = [0]
                                setDevType(values)
                              } else {
                                let values = [...devType]
                                values[values.length] = 0
                                setDevType(values)
                              }
                            } else {
                              let values = [...devType]
                              if (values[0] == 0) values.splice(0, 1)
                              else values.splice(1, 1)
                              setDevType(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Building
                        </p>
                      </div>
                    </div>

                    <div className='col-4 d-flex align-items-center'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (devType[1] == 1 || devType[0] == 1) &&
                            devType.length != 2
                              ? true
                              : false
                          }
                          onClick={(e: any) => {
                            if (e.target.checked) {
                              if (devType.length == 2) {
                                // setDevType([])
                                let values = [1]
                                setDevType(values)
                              } else {
                                let values = [...devType]
                                values[values.length] = 1
                                setDevType(values)
                              }
                            } else {
                              let values = [...devType]
                              if (values[0] == 1) values.splice(0, 1)
                              else values.splice(1, 1)
                              setDevType(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Community
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  {/* Development Dropdown*/}
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Development
                    </label>
                  </div>

                  <div className='row mb-5 single-select' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedDevelopments.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={developmentLists}
                        value={selectedDevelopments}
                        onChange={(e: any) => {
                          // console.log(e)
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setSelectedDevelopments(newVal)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Developments',
                        }}
                        hasSelectAll={false}
                      />
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>
                  {community && (
                    <>
                      {/* Cluster */}
                      <div className='d-flex align-items-baseline ms-2 mt-2'>
                        <img src={windowImg} height='14' width='14' className='me-4' />

                        <label
                          className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                          style={{fontWeight: '600'}}
                        >
                          Cluster
                        </label>
                      </div>

                      <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                        <div
                          className={`multi-select-container filter-select  ms-0 me-5  ${
                            selectedClusters.length === 0 ? 'no-value dev ' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={clustersLists}
                            value={selectedClusters}
                            onChange={(e: any) => {
                              setSelectedClusters(e)
                              if (e?.length == 0) {
                                setUnitGrpsLists([])
                                setSelectedUnitGrps([])
                              }
                            }}
                            labelledBy='Cluster'
                            overrideStrings={{
                              allItemsAreSelected: 'All Clusters',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div>
                      </div>

                      <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                      {/* Group */}

                      <div className='d-flex align-items-baseline ms-2 mt-2'>
                        <img src={windowImg} height='14' width='14' className='me-4' />

                        <label
                          className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                          style={{fontWeight: '600'}}
                        >
                          Group
                        </label>
                      </div>

                      <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                        <div
                          className={`multi-select-container filter-select  ms-0 me-5  ${
                            selectedUnitGrps.length === 0 ? 'no-value dev ' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={unitGrpsLists}
                            value={selectedUnitGrps}
                            onChange={(e: any) => {
                              setSelectedUnitGrps(e)
                            }}
                            labelledBy='Unit Groups'
                            overrideStrings={{
                              allItemsAreSelected: 'All Unit Groups',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div>
                      </div>

                      <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>
                    </>
                  )}

                  {/* Floor*/}
                  {building && (
                    <>
                      <div className='d-flex align-items-baseline ms-2 mt-2'>
                        <img src={windowImg} height='14' width='14' className='me-4' />

                        <label
                          className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                          style={{fontWeight: '600'}}
                        >
                          Floor
                        </label>
                      </div>

                      <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                        <div
                          className={`multi-select-container filter-select  ms-0 me-5  ${
                            selectedFloorGrps.length === 0 ? 'no-value dev ' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={floorGrpsLists}
                            value={selectedFloorGrps}
                            onChange={(e: any) => {
                              setSelectedFloorGrps(e)
                            }}
                            labelledBy='Floor '
                            overrideStrings={{
                              allItemsAreSelected: 'All Floors',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div>
                      </div>

                      <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>
                    </>
                  )}

                  {/* Unit*/}
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Unit
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedUnits.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={unitsLists}
                        value={selectedUnits}
                        onChange={(e: any) => {
                          setSelectedUnits(e)
                        }}
                        labelledBy='Units'
                        overrideStrings={{
                          allItemsAreSelected: 'All Units',
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>
                </>
              )}

              <div className='ms-auto mt-3 mb-3 me-5'>
                <div className='br-arr d-flex align-items-center justify-content-center'>
                  {hideTenant == false ? (
                    <img
                      src={upGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideTenant(true)}
                    />
                  ) : (
                    <img
                      src={downGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideTenant(false)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Residential Property */}
            <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{borderRadius: '0px'}}>
              <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
                {' '}
                Residential Property
              </label>

              {hideStatus == false && (
                <>
                  <>
                    <div className='d-flex align-items-baseline ms-2 '>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Property Type
                      </label>
                    </div>
                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-3 d-flex align-items-center xyz'>
                        <input
                          className='form-check-input me-3 cursor-pointer'
                          type='radio'
                          checked={
                            residentialUnitType.length == 4 ||residentialUnitType.length == 0 ? true : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked == true) {
                              let values = [0, 1, 3, 4]
                              setSelectedResidentialUnitType(values)
                            } else {
                              setSelectedResidentialUnitType([])
                            }
                          }}
                        />{' '}
                        All
                      </div>
                    </div>

                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-3 d-flex align-items-center test mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (residentialUnitType[0] == 0 ||
                                residentialUnitType[1] == 0 ||
                                residentialUnitType[2] == 0 ||
                                residentialUnitType[3] == 0) &&
                              residentialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (residentialUnitType.length == 4) {
                                  let values = [0]
                                  setSelectedResidentialUnitType(values)
                                } else {
                                  let values = [...residentialUnitType]
                                  values[values.length] = 0
                                  setSelectedResidentialUnitType(values)
                                }
                              } else {
                                let values = [...residentialUnitType]
                                if (values[0] == 0) values.splice(0, 1)
                                else if (values[1] == 0) values.splice(1, 1)
                                else if (values[2] == 0) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedResidentialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Apartment
                          </p>
                        </div>
                      </div>

                      <div className='col-3 d-flex align-items-center  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (residentialUnitType[0] == 1 ||
                                residentialUnitType[1] == 1 ||
                                residentialUnitType[2] == 1 ||
                                residentialUnitType[3] == 1) &&
                              residentialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (residentialUnitType.length == 4) {
                                  let values = [1]
                                  setSelectedResidentialUnitType(values)
                                } else {
                                  let values = [...residentialUnitType]
                                  values[values.length] = 1
                                  setSelectedResidentialUnitType(values)
                                }
                              } else {
                                let values = [...residentialUnitType]
                                if (values[0] == 1) values.splice(0, 1)
                                else if (values[1] == 1) values.splice(1, 1)
                                else if (values[2] == 1) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedResidentialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Penthouse
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (residentialUnitType[0] == 3 ||
                                residentialUnitType[1] == 3 ||
                                residentialUnitType[2] == 3 ||
                                residentialUnitType[3] == 3) &&
                              residentialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (residentialUnitType.length == 4) {
                                  let values = [3]
                                  setSelectedResidentialUnitType(values)
                                } else {
                                  let values = [...residentialUnitType]
                                  values[values.length] = 3
                                  setSelectedResidentialUnitType(values)
                                }
                              } else {
                                let values = [...residentialUnitType]
                                if (values[0] == 3) values.splice(0, 1)
                                else if (values[1] == 3) values.splice(1, 1)
                                else if (values[2] == 3) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedResidentialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Townhouse
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (residentialUnitType[0] == 4 ||
                                residentialUnitType[1] == 4 ||
                                residentialUnitType[2] == 4 ||
                                residentialUnitType[3] == 4) &&
                              residentialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (residentialUnitType.length == 4) {
                                  let values = [4]
                                  setSelectedResidentialUnitType(values)
                                } else {
                                  const values = [...residentialUnitType]

                                  values[values.length] = 4
                                  setSelectedResidentialUnitType(values)
                                }
                              } else {
                                const values = [...residentialUnitType]
                                if (values[0] == 4) values.splice(0, 1)
                                else if (values[1] == 4) values.splice(1, 1)
                                else if (values[2] == 4) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedResidentialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Villa
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-1'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (residentialUnitType[0] == 5 ||
                                residentialUnitType[1] == 5 ||
                                residentialUnitType[2] == 5 ||
                                residentialUnitType[3] == 5) &&
                              residentialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (residentialUnitType.length == 4) {
                                  let values = [5]
                                  setSelectedResidentialUnitType(values)
                                } else {
                                  const values = [...residentialUnitType]

                                  values[values.length] = 5
                                  setSelectedResidentialUnitType(values)
                                }
                              } else {
                                const values = [...residentialUnitType]
                                if (values[0] == 5) values.splice(0, 1)
                                else if (values[1] == 5) values.splice(1, 1)
                                else if (values[2] == 5) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedResidentialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Other
                          </p>
                        </div>
                      </div>
                      <div className='col-6  mb-1'>
                        <div
                          className={`multi-select-container filter-select  ms-0 me-5  ${
                            otherSelectedResidential.length === 0 ? 'no-value dev ' : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={othersList}
                            value={otherSelectedResidential}
                            onChange={(e: any) => {
                              // console.log(e)
                              let newVal: any = e

                              setOtherSelectedResidential(newVal)
                            }}
                            labelledBy='Development'
                            overrideStrings={{
                              allItemsAreSelected: 'All Others',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>

                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Bedrooms
                      </label>
                    </div>

                    <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                      <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          selectedBedrooms.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={bedroomsOptions}
                          value={selectedBedrooms}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedBedrooms(newVal)
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: 'All Bedrooms',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Bathrooms
                      </label>
                    </div>

                    <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                      <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          selectedBathResidential.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={bathroomOptions}
                          value={selectedBathResidential}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedBathResidential(newVal)
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: 'All Bathrooms',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                    {/* Rent Value */}
                    <div className='d-flex align-items-center ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                        style={{fontWeight: '600'}}
                      >
                        Size
                      </label>
                    </div>
                    <div className='d-flex mb-2 ms-2 xyz' style={{paddingLeft: '27px'}}>
                      <Paper component='form' style={searchContainerStyle} className='me-5'>
                        <InputBase
                          type='number'
                          placeholder='From (Amount)'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={residentialStartSize}
                          onChange={(e: any) => {
                            setResidentialStartSize(e.target.value)
                          }}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>

                      <Paper component='form' style={searchContainerStyle} className='me-5'>
                        <InputBase
                          type='number'
                          placeholder='Up to (Amount)'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={residentialEndSize}
                          onChange={(e: any) => {
                            setResidentialEndSize(e.target.value)
                          }}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Common Area
                      </label>
                    </div>

                    <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                      <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          selectedResidentialCommonAreas.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={residentialCommonAreasList}
                          value={selectedResidentialCommonAreas}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedResidentialCommonAreas(newVal)
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: 'All Others',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </>
                </>
              )}

              <div className='ms-auto mt-3 mb-3 me-5'>
                <div className='br-arr d-flex align-items-center justify-content-center'>
                  {hideStatus == false ? (
                    <img
                      src={upGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideStatus(true)}
                    />
                  ) : (
                    <img
                      src={downGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideStatus(false)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Property */}
            <div className='min-w-200px ps-3  pt-1  mt-1 card' style={{borderRadius: '0px'}}>
              <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
                {' '}
                Commercial Property
              </label>
              {!hideProperty && (
                <>
                  <>
                    <div className='d-flex align-items-baseline ms-2 '>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Property Type
                      </label>
                    </div>
                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-3 d-flex align-items-center xyz'>
                        <input
                          className='form-check-input me-3 cursor-pointer'
                          type='radio'
                          checked={
                            commercialUnitType.length == 4 || commercialUnitType.length == 0 ? true : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked == true) {
                              let values = [6, 7, 8, 9]
                              setSelectedCommercialUnitType(values)
                            } else {
                              setSelectedCommercialUnitType([])
                            }
                          }}
                        />{' '}
                        All
                      </div>
                    </div>

                    <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                      <div className='col-3 d-flex align-items-center test mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                                (commercialUnitType[0] == 6 ||
                                commercialUnitType[1] == 6 ||
                                commercialUnitType[2] == 6 ||
                                commercialUnitType[3] == 6) &&
                              commercialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (commercialUnitType.length == 4) {
                                  let values = [6]
                                  setSelectedCommercialUnitType(values)
                                } else {
                                  let values = [...commercialUnitType]
                                  values[values.length] = 6
                                  setSelectedCommercialUnitType(values)
                                }
                              } else {
                                let values = [...commercialUnitType]
                                if (values[0] == 6) values.splice(0, 1)
                                else if (values[1] == 6) values.splice(1, 1)
                                else if (values[2] == 6) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedCommercialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Office
                          </p>
                        </div>
                      </div>

                      <div className='col-3 d-flex align-items-center  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (commercialUnitType[0] == 7 ||
                                commercialUnitType[1] == 7 ||
                                commercialUnitType[2] == 7 ||
                                commercialUnitType[3] == 7) &&
                              commercialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (commercialUnitType.length == 4) {
                                  let values = [7]
                                  setSelectedCommercialUnitType(values)
                                } else {
                                  let values = [...commercialUnitType]
                                  values[values.length] = 7
                                  setSelectedCommercialUnitType(values)
                                }
                              } else {
                                let values = [...commercialUnitType]
                                if (values[0] == 7) values.splice(0, 1)
                                else if (values[1] == 7) values.splice(1, 1)
                                else if (values[2] == 7) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedCommercialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Restaurant
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (commercialUnitType[0] == 8 ||
                                commercialUnitType[1] == 8 ||
                                commercialUnitType[2] == 8 ||
                                commercialUnitType[3] == 8) &&
                              commercialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (commercialUnitType.length == 4) {
                                  let values = [8]
                                  setSelectedCommercialUnitType(values)
                                } else {
                                  let values = [...commercialUnitType]
                                  values[values.length] = 8
                                  setSelectedCommercialUnitType(values)
                                }
                              } else {
                                let values = [...commercialUnitType]
                                if (values[0] == 8) values.splice(0, 1)
                                else if (values[1] == 8) values.splice(1, 1)
                                else if (values[2] == 8) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedCommercialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Cafe
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-2'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (commercialUnitType[0] == 9 ||
                                commercialUnitType[1] == 9 ||
                                commercialUnitType[2] == 9 ||
                                commercialUnitType[3] == 9) &&
                              commercialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (commercialUnitType.length == 4) {
                                  let values = [9]
                                  setSelectedCommercialUnitType(values)
                                } else {
                                  const values = [...commercialUnitType]

                                  values[values.length] = 9
                                  setSelectedCommercialUnitType(values)
                                }
                              } else {
                                const values = [...commercialUnitType]
                                if (values[0] == 9) values.splice(0, 1)
                                else if (values[1] == 9) values.splice(1, 1)
                                else if (values[2] == 9) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedCommercialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Retail
                          </p>
                        </div>
                      </div>
                      <div className='col-3 d-flex align-items-center test  mb-1'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (commercialUnitType[0] == 5 ||
                                commercialUnitType[1] == 5 ||
                                commercialUnitType[2] == 5 ||
                                commercialUnitType[3] == 5) &&
                              commercialUnitType.length != 4
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (commercialUnitType.length == 4) {
                                  let values = [5]
                                  setSelectedCommercialUnitType(values)
                                } else {
                                  const values = [...commercialUnitType]

                                  values[values.length] = 5
                                  setSelectedCommercialUnitType(values)
                                }
                              } else {
                                const values = [...commercialUnitType]
                                if (values[0] == 5) values.splice(0, 1)
                                else if (values[1] == 5) values.splice(1, 1)
                                else if (values[2] == 5) values.splice(2, 1)
                                else values.splice(3, 1)

                                setSelectedCommercialUnitType(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Other
                          </p>
                        </div>
                      </div>
                      <div className='col-6  mb-1'>
                        <div
                          className={`multi-select-container filter-select  ms-0 me-5  ${
                            otherSelectedCommercial.length === 0
                              ? 'no-value dev '
                              : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={othersList}
                            value={otherSelectedCommercial}
                            onChange={(e: any) => {
                              // console.log(e)
                              let newVal: any = e
                              setOtherSelectedCommercial(newVal)
                            }}
                            labelledBy='Development'
                            overrideStrings={{
                              allItemsAreSelected: 'All Others',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>

                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Bathrooms
                      </label>
                    </div>

                    <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                      <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          selectedBathCommercial.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={bathroomOptions}
                          value={selectedBathCommercial}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedBathCommercial(newVal)
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: 'All Bathrooms',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                    {/* Size */}
                    <div className='d-flex align-items-center ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                        style={{fontWeight: '600'}}
                      >
                        Size
                      </label>
                    </div>
                    <div className='d-flex mb-2 ms-2 xyz' style={{paddingLeft: '27px'}}>
                      <Paper component='form' style={searchContainerStyle} className='me-5'>
                        <InputBase
                          type='number'
                          placeholder='From (Amount)'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={commercialStartSize}
                          onChange={(e: any) => {
                            setCommercialStartSize(e.target.value)
                          }}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>

                      <Paper component='form' style={searchContainerStyle} className='me-5'>
                        <InputBase
                          type='number'
                          placeholder='Up to (Amount)'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={commercialEndSize}
                          onChange={(e: any) => {
                            setCommercialEndSize(e.target.value)
                          }}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                    <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                    <div className='d-flex align-items-baseline ms-2 mt-2'>
                      <img src={windowImg} height='14' width='14' className='me-4' />

                      <label
                        className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                        style={{fontWeight: '600'}}
                      >
                        Common Area
                      </label>
                    </div>

                    <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                      <div
                        className={`multi-select-container filter-select  ms-0 me-5  ${
                          selectedCommercialCommonAreas.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={commercialCommonAreasList}
                          value={selectedCommercialCommonAreas}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e

                            setSelectedCommercialCommonAreas(newVal)
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: 'All Others',
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </>
                </>
              )}
              <div className='ms-auto mt-3 mb-5 me-5'>
                <div className='br-arr d-flex align-items-center justify-content-center'>
                  {hideProperty == false ? (
                    <img
                      src={upGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideProperty(true)}
                    />
                  ) : (
                    <img
                      src={downGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideProperty(false)}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Occupancy & Lease */}
            <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{borderRadius: '0px'}}>
              <label className='mb-2 ms-2 mt-3' style={{fontWeight: '700', fontSize: '14px'}}>
                {' '}
                Occupancy & Lease
              </label>

              {!hideFinancials && (
                <>
                  <div className='d-flex align-items-baseline ms-2 '>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Occupancy
                    </label>
                  </div>
                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center xyz'>
                      <input
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        checked={
                          occupancyStatus.length == 2 || occupancyStatus.length == 0 ? true : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked == true) {
                            let values = [0, 1]
                            setDevType(values)
                          } else {
                            setDevType([])
                          }
                        }}
                      />{' '}
                      All
                    </div>
                  </div>

                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-4 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (occupancyStatus[0] == 0 || occupancyStatus[1] == 0) &&
                            occupancyStatus.length != 2
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (occupancyStatus.length == 2) {
                                // setDevType([])
                                let values = [0]
                                setOccupancyStatus(values)
                              } else {
                                let values = [...occupancyStatus]
                                values[values.length] = 0
                                setOccupancyStatus(values)
                              }
                            } else {
                              let values = [...occupancyStatus]
                              if (values[0] == 0) values.splice(0, 1)
                              else values.splice(1, 1)
                              setOccupancyStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Vacant
                        </p>
                      </div>
                    </div>

                    <div className='col-4 d-flex align-items-center'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (occupancyStatus[1] == 1 || occupancyStatus[0] == 1) &&
                            occupancyStatus.length != 2
                              ? true
                              : false
                          }
                          onClick={(e: any) => {
                            if (e.target.checked) {
                              if (occupancyStatus.length == 2) {
                                // setDevType([])
                                let values = [1]
                                setOccupancyStatus(values)
                              } else {
                                let values = [...occupancyStatus]
                                values[values.length] = 1
                                setOccupancyStatus(values)
                              }
                            } else {
                              let values = [...occupancyStatus]
                              if (values[0] == 1) values.splice(0, 1)
                              else values.splice(1, 1)
                              setOccupancyStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Occupied
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 mx-4'></div>

                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Lease Status
                    </label>
                  </div>
                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center xyz'>
                      <input
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        checked={
                          tenancyStatus.length == 4 || tenancyStatus.length == 0 ? true : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked == true) {
                            let values = [0, 1, 2, 3]
                            setTenancyStatus(values)
                          } else {
                            setTenancyStatus([])
                          }
                        }}
                      />{' '}
                      All
                    </div>
                  </div>

                  <div className='row mb-2 ' style={{paddingLeft: '27px'}}>
                    <div className='col-3 d-flex align-items-center'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (tenancyStatus[0] == 1 ||
                              tenancyStatus[1] == 1 ||
                              tenancyStatus[2] == 1 ||
                              tenancyStatus[3] == 1) &&
                            tenancyStatus.length != 4
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (tenancyStatus.length == 4) {
                                let values = [1]
                                setTenancyStatus(values)
                              } else {
                                let values = [...tenancyStatus]
                                values[values.length] = 1
                                setTenancyStatus(values)
                              }
                            } else {
                              let values = [...tenancyStatus]
                              if (values[0] == 1) values.splice(0, 1)
                              else if (values[1] == 1) values.splice(1, 1)
                              else if (values[2] == 1) values.splice(2, 1)
                              else values.splice(3, 1)

                              setTenancyStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Active
                        </p>
                      </div>
                    </div>
                    <div className='col-3 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (tenancyStatus[0] == 2 ||
                              tenancyStatus[1] == 2 ||
                              tenancyStatus[2] == 2 ||
                              tenancyStatus[3] == 2) &&
                               tenancyStatus.length != 4
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (tenancyStatus.length == 4) {
                                let values = [2]
                                setTenancyStatus(values)
                              } else {
                                let values = [...tenancyStatus]
                                values[values.length] = 2
                                setTenancyStatus(values)
                              }
                            } else {
                              let values = [...tenancyStatus]
                              if (values[0] == 2) values.splice(0, 1)
                              else if (values[1] == 2) values.splice(1, 1)
                              else if (values[2] == 2) values.splice(2, 1)
                              else values.splice(3, 1)

                              setTenancyStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Expiring
                        </p>
                      </div>
                    </div>
                    <div className='col-3 d-flex align-items-center test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            (tenancyStatus[0] == 3 ||
                              tenancyStatus[1] == 3 ||
                              tenancyStatus[2] == 3 ||
                              tenancyStatus[3] == 3) &&
                            tenancyStatus.length != 4
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              if (tenancyStatus.length == 4) {
                                let values = [3]
                                setTenancyStatus(values)
                              } else {
                                const values = [...tenancyStatus]

                                values[values.length] = 3
                                setTenancyStatus(values)
                              }
                            } else {
                              const values = [...tenancyStatus]
                              if (values[0] == 3) values.splice(0, 1)
                              else if (values[1] == 3) values.splice(1, 1)
                              else if (values[2] == 3) values.splice(2, 1)
                              else values.splice(3, 1)

                              setTenancyStatus(values)
                            }
                          }}
                        />
                        <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                          {' '}
                          Expired
                        </p>
                      </div>
                    </div>
                  </div>

                  <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-2 mx-4'></div>

                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{fontWeight: '600'}}
                    >
                      Contract No.
                    </label>
                  </div>

                  <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
                    <div
                      className={`multi-select-container filter-select  ms-0 me-5  ${
                        selectedContractNo.length === 0 ? 'no-value dev ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={contractNoList}
                        value={selectedContractNo}
                        onChange={(e: any) => {
                          // console.log(e)
                          let newVal: any = e

                          setSelectedContractNo(newVal)
                        }}
                        labelledBy='Development'
                        overrideStrings={{
                          allItemsAreSelected: 'All Contracts',
                        }}
                        valueRenderer={CustomValueRenderer}
                      />
                    </div>
                  </div>

                  {/* Size */}
                  <div className='d-flex align-items-center ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                      style={{fontWeight: '600'}}
                    >
                      Lease Term
                    </label>
                  </div>
                  <div className='d-flex mb-2 ms-2 ' style={{paddingLeft: '27px'}}>
                    {startDate == null ? (
                      <DatePicker
                        onChange={handleTermStartDateChange}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(startDate, 'DD-MM-YYYY')}
                        onChange={handleTermStartDateChange}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='From'
                      />
                    )}
                    {endDate == null ? (
                      <DatePicker
                        onChange={handleTermEndDateChange}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    ) : (
                      <DatePicker
                        defaultValue={moment(endDate, 'DD-MM-YYYY')}
                        onChange={handleTermEndDateChange}
                        style={{width: '75%'}}
                        className='me-5 bg-white'
                        placeholder='To'
                      />
                    )}
                  </div>
                </>
              )}

              <div className='ms-auto mt-3 mb-5 me-5'>
                <div className='br-arr d-flex align-items-center justify-content-center'>
                  {hideFinancials == false ? (
                    <img
                      src={upGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideFinancials(true)}
                    />
                  ) : (
                    <img
                      src={downGreen}
                      height={20}
                      width={20}
                      className='cursor-pointer'
                      onClick={() => setHideFinancials(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filters

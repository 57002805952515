import React, {useState, useEffect, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import sync from '../../../img/sync.png'
import noData from '../../../img/NoData1.svg'
import {CircularProgress} from '@mui/material'
import swal from 'sweetalert2'
import {useParams} from 'react-router-dom'

interface ButtonProps {
  show: any
  handleSubmitId: (message: string, deleteMessage: any) => void
  handleClose: any
  addedJobs: any
  development: any
}

const AddJobsModal = ({handleClose, show, addedJobs, handleSubmitId, development}: ButtonProps) => {
  const {jobType} = useParams()

  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [result, setResult] = useState<any>()
  const [searchUnitNo, setSearchUnitNo] = useState<any>('')
  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  // console.log(development)
  // console.log(addedJobs)
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>(
    development?.value != undefined ? [development] : []
  )

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0
      ? [
          {
            label: addedJobs?.[0]?.cluster?.[0]?.name,
            value: addedJobs?.[0]?.cluster?.[0]?._id,
            type: addedJobs?.[0]?.cluster?.[0]?.type,
          },
        ]
      : []
  )

  const [refreshForClust, setRefreshForClust] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0 ? new Date() : false
  )

  const [building, setBuilding] = useState<any>(false)
  const [community, setCommunity] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0 ? true : false
  )

  const [isResidential, setIsResidential] = useState<any>(true)
  const [isCommercial, setIsCommercial] = useState<any>(true)

  const [buildingLists, setBuildingLists] = useState<any>([])
  const [selectedBuildings, setSelectedBuildings] = useState<any>([])

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  const [selectedFloorGrps, setSelectedFloorGrps] = useState<any>([])
  const [floorGrpsLists, setFloorGrpsLists] = useState<any>([])

  const [selectedUnits, setSelectedUnits] = useState<any>([])
  const [unitsLists, setUnitsLists] = useState<any>([])

  const [showBuildingCluster, setShowBuildingCluster] = useState<any>(
    addedJobs?.length > 0 &&
      addedJobs?.[0]?.community?.length > 0 &&
      addedJobs?.[0]?.cluster?.[0]?.type == 1
      ? true
      : false
  )
  const [showUnitsForCluster, setShowUnitsForCluster] = useState<any>(false)
  const [showIsUnitGroup, setShowIsUnitGroup] = useState<any>(false)
  const [showBuildingUnitGroup, setShowBuildingUnitGroup] = useState<any>(false)
  const [showMixedCluster, setShowMixedCluster] = useState<any>(
    addedJobs?.length > 0 &&
      addedJobs?.[0]?.community?.length > 0 &&
      addedJobs?.[0]?.cluster?.[0]?.type == 2
      ? true
      : false
  )

  const [showFloorsForBuildingCluster, setShowFloorsForBuildingCluster] = useState<any>(false)

  const [selectedPropertyType, setSelectedPropertyType] = useState<any>([
    {
      label: 'Residential Units',
      value: 0,
    },
    {
      label: 'Commercial Units',
      value: 1,
    },
    {
      label: 'Common Areas',
      value: 2,
    },
  ])

  const [propertyTypeList, setPropertyTypeList] = useState<any>([
    {
      label: 'Residential Units',
      value: 0,
    },
    {
      label: 'Commercial Units',
      value: 1,
    },
    {
      label: 'Common Areas',
      value: 2,
    },
  ])

  const [isLoading, setIsLoading] = useState<any>(true)

  const [isBuildingChanged, setIsBuildingChanged] = useState<any>(true)
  const [isFloorChanged, setIsFloorChanged] = useState<any>(true)


  let totalSelected: any = 0
  let totalNotSelectedInCurrentBatch: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    width: '180px !important',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // Development
  const getDevelopment = () => {
    const body = {
      search: searchDevelopment,
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }
          })

        setDevelopmentLists(values)

        // if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {})
  }

  React.useEffect(() => {
    getDevelopment()
    // getFloor()
    // setDefaultOccupancy()
  }, [])

  React.useEffect(() => {
    if (selectedDevelopments?.length > 0) {
      setIsFirst(false)
    } else {
      setSelectedUnits([])
      setSelectedFloorGrps([])
      setSelectedUnitGrps([])
      setSelectedClusters([])
    }
  }, [selectedDevelopments])

  // Clusters
  const getClusters = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      clusterIds: [],
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              type: `${val?.type}`,
            }
          })

        setClustersLists(values)

        let isBuildingCluster: any = false
        let isUnitCluster: any = false
        let isMixedCluster: any = false

        if (selectedClusters?.length > 0) {
          // e?.map((el:any, ind:any)=>{
          // console.log(el)
          if (Number(selectedClusters?.[0]?.type) == 1) isBuildingCluster = true

          if (Number(selectedClusters?.[0]?.type) == 0) isUnitCluster = true

          if (Number(selectedClusters?.[0]?.type) == 2) isMixedCluster = true

          // })
        }
        setShowBuildingCluster(isBuildingCluster)
        setShowUnitsForCluster(isUnitCluster)
        setShowMixedCluster(isMixedCluster)

        // setShowFloorsForBuildingCluster(false)
        //                             setSelectedFloorGrps([])
        //                             setSelectedUnitGrps([])
        //                             setSelectedUnits([])
        //                             setSelectedBuildings([])

        // let isUnitGrp:any = false
        //   let isUnitCluster:any = false
        //   let isMixedCluster: any = false
        //   values?.map((el:any, ind:any)=>{
        //     console.log(el)
        //     if(Number(el?.type) == 1 )  // building cluster
        //       isUnitGrp = true

        //     if(Number(el?.type) == 0)   //unit cluster
        //       isUnitCluster = true

        //     if( Number(el?.type) == 2)   // Mixed Cluster
        //     isMixedCluster = true

        //   })
        //   setShowBuildingCluster(isUnitGrp)
        //   setShowUnitsForCluster(isUnitCluster)
        //   setShowMixedCluster(isMixedCluster)

        if (
          (selectedClusters?.length == 0 && selectedDevelopments?.length != 0) ||
          isBuildingChanged
        ) {
          // setSelectedClusters(values)
        } else {
          const filteredValues = selectedClusters.filter((cluster: any) =>
            values.some((value: any) => value.value === cluster.value)
          )

          if (selectedDevelopments?.length == 0) {
            setClustersLists([])
            setSelectedClusters([])
          } else setSelectedClusters(filteredValues)
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getClusters()
  }, [selectedDevelopments, isFirst, refreshForClust])

  //  Buildings
  const getBuildings = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      buildingIds:
        selectedDevelopments?.[0]?.devType == 'build' ? [selectedDevelopments?.[0]?.value] : [],
      communityIds:
        selectedDevelopments?.[0]?.devType == 'comm' ? [selectedDevelopments?.[0]?.value] : [],
      clusterIds: clustIds,
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
    }

    ApiPost(`corporate/development/building/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              // type: val?.isUnitGroup ? 0 : 1, // 0 for unitGroup , 1 for building
            }
          })

        setBuildingLists(values)

        if (Number(selectedClusters?.[0]?.type) == 1) {
          if (
            (selectedBuildings?.length == 0 && selectedDevelopments?.length != 0) ||
            isBuildingChanged
          ) {
            setSelectedBuildings(values)
            if (values?.length > 0) setShowFloorsForBuildingCluster(true)
            else setShowFloorsForBuildingCluster(false)
          } else {
            const filteredValues = selectedBuildings.filter((grp: any) =>
              values.some((value: any) => value.value === grp.value)
            )

            if (selectedDevelopments?.length == 0) {
              setBuildingLists([])
              setSelectedBuildings([])
            } else {
              setSelectedBuildings(filteredValues)
              if (filteredValues?.length > 0) setShowFloorsForBuildingCluster(true)
              else setShowFloorsForBuildingCluster(false)
            }
          }
        }

        if (clustIds?.length == 0) {
          setBuildingLists([])
          setSelectedBuildings([])
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedClusters?.length > 0) getBuildings()
  }, [selectedClusters])

  //  Unit Groups
  const getUnitGroups = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      unitGroupIds: [],
      clusterIds: clustIds,
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              type: val?.isUnitGroup ? 0 : 1, // 0 for unitGroup , 1 for building
            }
          })

        setUnitGrpsLists(values)

        let isUnitGrp: any = false
        let isBuildGroup: any = false

        values?.map((el: any, ind: any) => {
          if (Number(el?.type) == 0)
            // building cluster
            isUnitGrp = true

          if (Number(el?.type) == 1)
            //unit cluster
            isBuildGroup = true
        })
        setShowIsUnitGroup(isUnitGrp)
        setShowBuildingUnitGroup(isBuildGroup)

        if (
          (selectedUnitGrps?.length == 0 && selectedDevelopments?.length != 0) ||
          isBuildingChanged
        )
          setSelectedUnitGrps(values)
        else {
          const filteredValues = selectedUnitGrps.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setUnitGrpsLists([])
            setSelectedUnitGrps([])
          } else setSelectedUnitGrps(filteredValues)
        }

        if (clustIds?.length == 0) {
          setUnitGrpsLists([])
          setSelectedUnitGrps([])
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedClusters?.length > 0) getUnitGroups()
  }, [selectedClusters])

  //
  const getFloor = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitGrp[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
    }

    ApiPost(`corporate/development/floor/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
            }
          })

        setFloorGrpsLists(values)

        if (
          (selectedFloorGrps?.length == 0 && selectedDevelopments?.length != 0) ||
          isBuildingChanged || isFloorChanged
        ) {
          if (
            selectedDevelopments?.[0]?.devType == 'build' ||
            (selectedDevelopments?.[0]?.devType == 'comm' &&
              selectedClusters?.length > 0 &&
              (showBuildingUnitGroup || showBuildingCluster))
          )
            setSelectedFloorGrps(values)
        } else {
          const filteredValues = selectedFloorGrps.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setFloorGrpsLists([])
            setSelectedFloorGrps([])
          } else setSelectedFloorGrps(filteredValues)
        }

        setIsFirst(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getFloor()
  }, [selectedDevelopments, isFirst, selectedBuildings])

  //
  const getUnits = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitGrp: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitGrp[ind] = dev?.value
      })

    let floorIds: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorIds[ind] = dev?.value
      })

    const body = {
      search: '',
      developmentIds: devIds,
      unitGroupIds: unitGrp,
      clusterIds: clustIds,
      floorIds: floorIds,
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
    }

    ApiPost(`corporate/development/unit/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: jobType == 'unit' ? `${val?.unitNo}` : `${val?.unitNo} - ${val?.commonAreaName}`,
              value: `${val?._id}`,
            }
          })

        setUnitsLists(values)

        if ((selectedUnits?.length == 0 && selectedDevelopments?.length != 0) || isBuildingChanged || isFloorChanged)
          setSelectedUnits(values)
        else {
          const filteredValues = selectedUnits.filter((grp: any) =>
            values.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setSelectedUnits([])
            setSelectedFloorGrps([])
            setSelectedUnitGrps([])
            setSelectedClusters([])
          } else setSelectedUnits(filteredValues)

          selectedDevelopments?.map((sl: any) => {
            if (sl?.devType == 'build') {
              if (selectedFloorGrps?.length == 0) {
                setSelectedUnits([])
                setUnitsLists([])
                return
              }
            } else {
              if (selectedClusters?.length == 0) {
                setSelectedUnits([])
                setUnitsLists([])
                return
              }
            }
          })
        }

        setIsFirst(false)

        setIsBuildingChanged(false)
        setIsFloorChanged(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getUnits()
  }, [selectedDevelopments, selectedClusters, selectedUnitGrps, selectedFloorGrps])

  //
  const updateList = async () => {
    setIsLoading(true)
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    let unitgrpIds: any = []
    selectedUnitGrps?.length > 0 &&
      selectedUnitGrps?.map((dev: any, ind: any) => {
        unitgrpIds[ind] = dev?.value
      })

    let floorsIDs: any = []
    selectedFloorGrps?.length > 0 &&
      selectedFloorGrps?.map((dev: any, ind: any) => {
        floorsIDs[ind] = dev?.value
      })

    let unitIDs: any = []
    selectedUnits?.length > 0 &&
      selectedUnits?.map((dev: any, ind: any) => {
        unitIDs[ind] = dev?.value
      })

    let isCommonArea: any = false
    // let propType: any = []
    // let propTypeInd: any = 0
    // selectedPropertyType?.length > 0 &&
    //   selectedPropertyType?.map((prp: any, ind: any) => {
    //     if (Number(prp?.value) != 2) {
    //       propType[propTypeInd] = prp?.value
    //       propTypeInd = propTypeInd + 1
    //     } else isCommonArea = true
    //   })

    let propTypeToSend: any = []
    if (isResidential) propTypeToSend?.push(0)
    if (isCommercial) propTypeToSend?.push(1)

    const body = {
      searchDevelopment: searchDevelopment,
      unitSearch: searchUnitNo,
      developmentIds: devIds,
      clusterIds: clustIds,
      unitGroupIds: unitgrpIds,
      floorIds: floorsIDs,
      unitIds: unitIDs,
      propertyTypes: jobType == 'unit' ? propTypeToSend : [],
      unitTypes: jobType == 'common-area' ? [2] : [0, 1, 3, 4, 5, 6, 7, 8, 9],
      isFloorWiseSort: true,
    }
    await ApiPost(`corporate/maintenance_service_request/get/development`, body)
      .then((res) => {
        const isNotHaveData = res?.data?.data?.length === 0
        if (isNotHaveData) {
          setResult([])
          setIsLoading(false)
          return
        }
        let values = [...res?.data?.data]

        // console.log(addedJobs)

        res?.data?.data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        for (let j = 0; j < res?.data?.data?.length; j++) {
          values[j].comments = []
          values[j].schedule = {
            date: null,
            startTime: null,
            endTime: null,
            status: 0,
          }
          values[j].jobUrgency = 0
          values[j].maintenanceJobStatus = 0
          values[j].attachments = []
          values[j].jobId = null
          values[j].jobIdNo = null
          values[j].isDisable = false

          let isExist = false

          addedJobs?.map((job: any, ind: any) => {
            if (job?._id == res?.data?.data?.[j]?._id) {
              isExist = true
              values[j].comments = job?.comments
              values[j].schedule = job?.schedule
              values[j].jobUrgency = job?.jobUrgency
              values[j].maintenanceJobStatus = job?.maintenanceJobStatus
              values[j].attachments = job?.attachments
              values[j].jobId = job?.jobId
              values[j].jobIdNo = job?.jobIdNo
            }
          })
          if (isExist) {
            values[j].check = true
            values[j].isDisable = true
          }
        }

        setResult(values)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    if (!isFirst || searchDevelopment != '' || searchUnitNo != '') updateList()
    else {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [
    searchDevelopment,
    selectedDevelopments,
    searchUnitNo,
    selectedClusters,
    selectedUnitGrps,
    selectedFloorGrps,
    selectedUnits,
    // selectedPropertyType,
    isCommercial,
    isResidential,
  ])

  useEffect(() => {
    let isBuild: any = false
    let isComm: any = false

    if (selectedDevelopments?.length == 0) {
      setCommunity(true)
      setBuilding(true)
    } else {
      selectedDevelopments?.map((sl: any) => {
        if (sl?.devType == 'comm') isComm = true

        if (sl?.devType == 'build') isBuild = true
      })

      setCommunity(isComm)
      setBuilding(isBuild)
    }
  }, [selectedDevelopments])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  useEffect(() => {}, [selectedDevelopments])

  // setTimeout(()=>{

  //   const checkboxes = document.querySelectorAll('.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]');
  //     console.log(checkboxes)
  //   checkboxes.forEach(function(checkbox) {
  //       checkbox.setAttribute('type', 'radio');
  //     });

  // }, 200)

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-7'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: `${
                selectedDevelopments?.length == 0 ||
                result?.length == 0 ||
                (selectedDevelopments?.[0]?.devType == 'comm' && selectedClusters?.length == 0)
                  ? '600px'
                  : 'unset'
              }`,
            }}
          >
            {result?.map((res: any, ind: any) => {
              if (res?.check == true && res?.isDisable == false) totalSelected = totalSelected + 1
              return <></>
            })}

            {result?.map((res: any, ind: any) => {
              if (res?.isDisable == false)
                totalNotSelectedInCurrentBatch = totalNotSelectedInCurrentBatch + 1
              return <></>
            })}

            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark flex-column justify-content-center ps-1'>
                <b>Add Jobs </b>
              </h2>

              <div className='d-flex ms-auto align-items-center '>
                {/* <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3 red-hollow-btn ps-4'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-4'/> Cancel
                </button> */}

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn ps-3  status-w-100'
                  onClick={() => {

                    let noJobAdded:any = true
                    result?.map((rs:any, ind:any)=>{
                      if(rs?.check)
                        noJobAdded = false
                      
                    })

                    if (
                      selectedDevelopments?.[0]?.value != development?.value &&
                      development?.value != undefined && noJobAdded
                    ){

                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>You selected a different development, but no job has been added. It will discard all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (
                                  development?._id != rs?.community?.[0]?._id &&
                                  development?._id != rs?.building?.[0]?._id
                                ) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            // console.log(addedJobs)
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)
                            handleClose()
                          }})

                    } else if (
                      selectedDevelopments?.[0]?.value == development?.value &&
                      development?.value != undefined &&
                      selectedDevelopments?.[0]?.devType == 'comm' &&
                      selectedClusters?.[0]?.value != development?.clusterId && noJobAdded
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>You selected a different cluster, but no job has been added. It will discard all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (selectedClusters?.[0]?.value != rs?.cluster?.[0]?._id) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)

                            handleClose()
                          }
                        })
                    }
                    else if (
                      selectedDevelopments?.[0]?.value != development?.value &&
                      development?.value != undefined
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>Your selection falls under a different development. By adding it you will replace all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (
                                  development?._id != rs?.community?.[0]?._id &&
                                  development?._id != rs?.building?.[0]?._id
                                ) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            // console.log(addedJobs)
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)
                            handleClose()
                          }
                        })
                    } else if (
                      selectedDevelopments?.[0]?.value == development?.value &&
                      development?.value != undefined &&
                      selectedDevelopments?.[0]?.devType == 'comm' &&
                      selectedClusters?.[0]?.value != development?.clusterId
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>Your selection falls under a different cluster. By adding it you will replace all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (selectedClusters?.[0]?.value != rs?.cluster?.[0]?._id) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)

                            handleClose()
                          }
                        })
                    } else {
                      handleSubmitId(result, [])
                      handleClose()
                    }
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-4' /> Add
                </button>

                <label
                  className='head-text fs-2 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div className='d-flex align-items-center mt-2'>
              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-700 ps-1'>
                Select Development
              </h4>
            </div>

            {/* <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11 ps-0'>
                <div
                  className='d-flex flex-wrap '
                  style={{
                   
                    borderBottom: '0px',
                  }}
                >
                  <div className='input-container mb-5 property xyz tst ps-0 me-3 job-modl'>
               
                    <Paper component='form' style={searchContainerStyle} className='input-bg-grey'>
                      <InputBase
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchDevelopment}
                        onChange={(e: any) => {
                          setSearchDevelopment(e.target.value)
                        }}
                        placeholder={'Development...'}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0 ||
                          selectedUnits?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  <div className='input-container mb-5 property xyz tst ms-2 job-modl'>
                    <Paper component='form' style={searchContainerStyle} className='input-bg-grey'>
                      <InputBase
                        placeholder={'Unit...'}
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchUnitNo}
                        onChange={(e: any) => {
                          setSearchUnitNo(e.target.value)
                        }}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0 ||
                          selectedUnits?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>

                  <div className='mb-5 property xyz tst ps-0 pe-3 ms-auto'>
                    <div
                      className='filter-div d-flex align-items-center justify-content-center cursor-pointer mt-1 ms-auto'
                      onClick={() => {
                        setSearchDevelopment('')
                        setSearchUnitNo('')
                        setSelectedDevelopments([])
                        setSelectedClusters([])
                        setSelectedUnitGrps([])
                        setSelectedFloorGrps([])
                        setSelectedUnits([])
                      }}
                    >
                      <img src={sync} height={20} width={22} className='cursor-pointer' />
                    </div>
                  </div >
                </div>
              </div>
            </div> */}

            <div className='row mt-3 mx-1'>
              {/* <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Filter
                </label>
              </div> */}
              <div className='col-12 px-0'>
                <>
                  <div className='d-flex test flex-wrap jobs'>
                    {/* Development */}
                    <div className='property-test'>
                      <div
                        className={`multi-select-container jobs-property  ms-0 me-5 mb-5  ${
                          selectedDevelopments.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                          Development
                        </h4>

                        <MultiSelect
                          options={developmentLists}
                          value={selectedDevelopments}
                          onChange={(e: any) => {
                            let newVal: any = e
                            setIsBuildingChanged(true)

                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedDevelopments(newVal)

                            setSelectedClusters([])
                            setSelectedFloorGrps([])
                            setShowFloorsForBuildingCluster(false)
                            setSelectedFloorGrps([])
                            setSelectedUnitGrps([])
                            setSelectedUnits([])
                            setSelectedBuildings([])
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: `${selectedDevelopments?.[0]?.label}`,
                          }}
                          disabled={
                            searchDevelopment?.trim()?.length > 0 ||
                            searchUnitNo?.trim()?.length > 0
                          }
                          hasSelectAll={false}
                        />
                      </div>
                    </div>

                    {selectedDevelopments?.length > 0 && (
                      <>
                        {community && (
                          <>
                            {/* Cluster */}
                            <div className='property-test'>
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedClusters.length === 0 ? 'no-value clust ' : 'has-value '
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Cluster
                                </h4>
                                <MultiSelect
                                  options={clustersLists}
                                  value={selectedClusters}
                                  onChange={(e: any) => {
                                    let newVal: any = e
                                    if (e?.length > 1) newVal = e.slice(1)
                                    setSelectedClusters(newVal)

                                    if (e?.length == 0) {
                                      setUnitGrpsLists([])
                                      setSelectedUnitGrps([])
                                      setResult([])
                                    }

                                    let isBuildingCluster: any = false
                                    let isUnitCluster: any = false
                                    let isMixedCluster: any = false
                                    // e?.map((el:any, ind:any)=>{
                                    // console.log(el)
                                    if (Number(newVal?.[0]?.type) == 1) isBuildingCluster = true

                                    if (Number(newVal?.[0]?.type) == 0) isUnitCluster = true

                                    if (Number(newVal?.[0]?.type) == 2) isMixedCluster = true

                                    // })
                                    setShowBuildingCluster(isBuildingCluster)
                                    setShowUnitsForCluster(isUnitCluster)
                                    setShowMixedCluster(isMixedCluster)

                                    setShowFloorsForBuildingCluster(false)
                                    setSelectedFloorGrps([])
                                    setSelectedUnitGrps([])
                                    setSelectedUnits([])
                                    setSelectedBuildings([])
                                  }}
                                  labelledBy='Cluster'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedClusters?.[0]?.label}`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  // valueRenderer={CustomValueRenderer}
                                  hasSelectAll={false}
                                />
                              </div>
                            </div>

                            {/* Building Cluster --- Building */}
                            {showBuildingCluster && selectedClusters?.length > 0 && (
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedBuildings.length === 0
                                    ? 'no-value building-clust'
                                    : 'has-value'
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Buildings
                                </h4>
                                <MultiSelect
                                  options={buildingLists}
                                  value={selectedBuildings}
                                  onChange={(e: any) => {
                                    setSelectedBuildings(e)
                                    if (e?.length > 0) setShowFloorsForBuildingCluster(true)
                                    else setShowFloorsForBuildingCluster(false)

                                    let isUnitGrp: any = false
                                    let isBuildGroup: any = false

                                    // e?.map((el: any, ind: any) => {
                                    //   console.log(el)
                                    //   if (Number(el?.type) == 0)
                                    //     // building cluster
                                    //     isUnitGrp = true

                                    //   if (Number(el?.type) == 1)
                                    //     //unit cluster
                                    //     isBuildGroup = true
                                    // })
                                    // setShowIsUnitGroup(isUnitGrp)
                                    // setShowBuildingUnitGroup(isBuildGroup)
                                  }}
                                  labelledBy='Buildings'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedBuildings?.length} Selected`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  valueRenderer={CustomValueRenderer}
                                />
                              </div>
                            )}

                            {/* Mixed Cluster --- Unit Groups */}
                            {showMixedCluster && selectedClusters?.length > 0 && (
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedUnitGrps.length === 0 ? 'no-value grpunit ' : 'has-value'
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Group
                                </h4>
                                <MultiSelect
                                  options={unitGrpsLists}
                                  value={selectedUnitGrps}
                                  onChange={(e: any) => {
                                    setSelectedUnitGrps(e)

                                    let isUnitGrp: any = false
                                    let isBuildGroup: any = false

                                    e?.map((el: any, ind: any) => {
                                      if (Number(el?.type) == 0)
                                        // building cluster
                                        isUnitGrp = true

                                      if (Number(el?.type) == 1)
                                        //unit cluster
                                        isBuildGroup = true
                                    })
                                    setShowIsUnitGroup(isUnitGrp)
                                    setShowBuildingUnitGroup(isBuildGroup)
                                  }}
                                  labelledBy='Unit Groups'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedUnitGrps?.length} Selected`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  valueRenderer={CustomValueRenderer}
                                />
                              </div>
                            )}
                          </>
                        )}

                        {(building ||
                          ((showBuildingUnitGroup || showFloorsForBuildingCluster) &&
                            selectedClusters?.length > 0)) && (
                          <>
                            {/* Floor Status */}
                            <div
                              className={`multi-select-container jobs-property  me-5 mb-5  ${
                                selectedFloorGrps.length === 0 ? 'no-value floor' : 'has-value'
                              }`}
                            >
                              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                Floor
                              </h4>
                              <MultiSelect
                                options={floorGrpsLists}
                                value={selectedFloorGrps}
                                onChange={(e: any) => {
                                  setIsFloorChanged(true)

                                  setSelectedFloorGrps(e)
                                }}
                                labelledBy='Floor '
                                overrideStrings={{
                                  // selectSomeItems: selectedFloorGrps.length > 0 ? `${selectedFloorGrps.length} Selected` : '',
                                  allItemsAreSelected: `${selectedFloorGrps?.length} Selected`,
                                }}
                                disabled={
                                  searchDevelopment?.trim()?.length > 0 ||
                                  searchUnitNo?.trim()?.length > 0
                                }
                                valueRenderer={CustomValueRenderer}
                              />
                            </div>
                          </>
                        )}

                        {/* Units */}
                        {(building ||
                          ((showUnitsForCluster ||
                            showBuildingUnitGroup ||
                            showIsUnitGroup ||
                            (showFloorsForBuildingCluster && selectedFloorGrps?.length > 0)) &&
                            selectedClusters?.length > 0)) && (
                          <div
                            className={`multi-select-container jobs-property  me-5 mb-3 ${
                              selectedUnits?.length == 0 && jobType == 'unit'
                                ? 'unit'
                                : selectedUnits?.length == 0 && jobType == 'common-area'
                                ? 'common-area-unit'
                                : ''
                            }   ${selectedUnits.length === 0 ? 'no-value ' : 'has-value'}`}
                          >
                            <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                              {jobType == 'unit' ? 'Unit' : 'Common Area'}
                            </h4>
                            <MultiSelect
                              options={unitsLists}
                              value={selectedUnits}
                              onChange={(e: any) => {
                                setSelectedUnits(e)
                              }}
                              labelledBy='Units'
                              overrideStrings={{
                                allItemsAreSelected: `${selectedUnits?.length} Selected`,
                              }}
                              valueRenderer={CustomValueRenderer}
                            />
                          </div>
                        )}
                        {/* PropertyType */}
                        {/* <div
                          className={`multi-select-container jobs-property  me-5 mb-3   ${
                            selectedPropertyType.length === 0
                              ? 'no-value propertyType'
                              : 'has-value'
                          }`}
                        >
                          <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                            Property Type
                          </h4>
                          <MultiSelect
                            options={propertyTypeList}
                            value={selectedPropertyType}
                            onChange={(e: any) => {
                              setSelectedPropertyType(e)
                            }}
                            labelledBy='Property Tyoe'
                            overrideStrings={{
                              allItemsAreSelected: 'All Property Types',
                            }}
                            valueRenderer={CustomValueRenderer}
                          />
                        </div> */}
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            <div className='d-flex align-items-center mt-4'>
              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-700 pt-2'>
                Select Unit Jobs
              </h4>

              {jobType == 'unit' &&
              <div className='d-flex align-item-center ms-auto pt-2 me-3'>     
                <div className='d-flex align-item-center me-2'>
                  {isResidential ? (
                    <div
                      style={{}}
                      className='me-2 cursor-pointer green-radio-filled'
                      onClick={() => {
                        if(isCommercial)
                        setIsResidential(false)
                      }}
                    ></div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsResidential(true)
                      }}
                      className='me-2 cursor-pointer green-radio-hollow'
                    ></div>
                  )}
                  <h4>Residential</h4>
                </div>
                

                <div className='d-flex align-item-center ms-5'>
                  {isCommercial ? (
                    <div
                      onClick={() => {
                        if(isResidential)
                        setIsCommercial(false)
                      }}
                      className='me-2 cursor-pointer green-radio-filled'
                    ></div>
                  ) : (
                    <div
                      onClick={() => {
                        setIsCommercial(true)
                      }}
                      className='me-2 cursor-pointer green-radio-hollow'
                    ></div>
                  )}
                  <h4>Commercial</h4>
                </div>
              </div>
              }

              <div className={`d-flex ms-5 align-items-center ${jobType == 'common-area' ? 'ms-auto' : ''} `}>
                <h4 className='user-head ms-auto me-5 mb-0 white-dark-theme-color fnt-500'>
                  <i>
                    Total Selected <b>{totalSelected > 0 ? totalSelected : '-'} </b>
                  </i>
                </h4>
              </div>
            </div>

            {selectedDevelopments?.length > 0 &&
              (selectedDevelopments?.[0]?.devType == 'build' ||
                (selectedDevelopments?.[0]?.devType == 'comm' && selectedClusters?.length > 0)) && (
                <div className='row mt-3'>
                  <div className='col-12 px-0'>
                    <div className='table-responsive' style={{maxHeight: '400px'}}>
                      <table
                        className='table align-middle fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='text-start w-25px  test  pt-2'>
                              {result?.length > 0 && (
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={
                                      totalSelected == totalNotSelectedInCurrentBatch &&
                                      totalSelected != 0 &&
                                      result?.length != 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      let values: any = [...result]
                                      if (e.target.checked) {
                                        values?.map((list: any) => {
                                          list.check = true
                                        })
                                      } else {
                                        values?.map((list: any) => {
                                          if (!list?.isDisable) list.check = false
                                        })
                                      }
                                      setResult(values)
                                    }}
                                  />
                                </div>
                              )}
                            </th>

                            <th className='text-start min-w-100px pt-2'>
                              {jobType == 'unit' ? 'Unit' : 'Common Area'}
                            </th>
                            <th className='text-start min-w-100px pt-2'>
                              {jobType == 'unit' ? 'Unit Type' : 'Type'}
                            </th>
                            {(building ||
                              showBuildingUnitGroup ||
                              showMixedCluster ||
                              showBuildingCluster) && (
                              <th className='text-start min-w-100px  pt-2'>Floor</th>
                            )}

                            {community && showBuildingCluster && (
                              <th className='text-start min-w-100px  pt-2'>Building</th>
                            )}
                            {community && (showBuildingUnitGroup || showMixedCluster) && (
                              <th className='text-start min-w-100px  pt-2'>Group</th>
                            )}
                            {community && <th className='text-start min-w-100px  pt-2'>Cluster</th>}

                            <th className='text-start min-w-100px  pt-2'>Development</th>

                            {/* {community && (
                         <th className='text-start min-w-100px'>Cluster</th>)} */}

                            {jobType == 'unit' && (
                              <th className='text-start min-w-100px  pt-2'>Occupancy</th>
                            )}
                          </tr>
                        </thead>

                        <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                          {result?.length > 0 &&
                          (selectedDevelopments?.length > 0 ||
                            searchDevelopment != '' ||
                            searchUnitNo != '') ? (
                            <>
                              {result?.map((rs: any, index: any) => {
                                return (
                                  <tr>
                                    <td className='text-start test'>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 cursor-pointer'>
                                        <input
                                          className='form-check-input cursor-pointer'
                                          type='checkbox'
                                          id='vacant0'
                                          name='none'
                                          checked={rs?.check}
                                          onChange={(e: any) => {
                                            const values = [...result]
                                            values[index].check = e.target.checked
                                            setResult(values)
                                          }}
                                          disabled={rs?.isDisable}
                                        />
                                      </div>
                                    </td>
                                    <td
                                      className={`text-start ${
                                        rs?.isDisable ? 'disable-opacity' : ''
                                      }`}
                                    >
                                      {rs?.unitNo ? `${rs?.unitNo}` : `-`}
                                    </td>
                                    <td
                                      className={`text-start ${
                                        rs?.isDisable ? 'disable-opacity' : ''
                                      }`}
                                    >
                                      {jobType == 'unit' ? (
                                        <>
                                          {rs?.unitType === 0
                                            ? 'Apartment'
                                            : rs?.unitType === 1
                                            ? 'Penthouse'
                                            : rs?.unitType === 2
                                            ? 'Common Area'
                                            : rs?.unitType === 3
                                            ? 'Townhouse'
                                            : rs?.unitType === 4
                                            ? 'Villa'
                                            : rs?.unitType === 5
                                            ? `Other - ${rs?.otherName}`
                                            : rs?.unitType === 6
                                            ? 'Office'
                                            : rs?.unitType === 7
                                            ? 'Restaurant'
                                            : rs?.unitType === 8
                                            ? 'Cafe'
                                            : rs?.unitType === 9
                                            ? 'Retail'
                                            : '-'}
                                        </>
                                      ) : (
                                        <>{rs?.commonAreaName}</>
                                      )}
                                    </td>
                                    {(building ||
                                      showBuildingUnitGroup ||
                                      showMixedCluster ||
                                      showBuildingCluster) && (
                                      <td
                                        className={`text-start ${
                                          rs?.isDisable ? 'disable-opacity' : ''
                                        }`}
                                      >
                                        {rs?.floor?.length > 0 ? `${rs?.floor?.[0]?.name}` : `-`}
                                      </td>
                                    )}

                                    {community && showBuildingCluster && (
                                      <td
                                        className={`text-start ${
                                          rs?.isDisable ? 'disable-opacity' : ''
                                        }`}
                                      >
                                        {rs?.building?.length > 0
                                          ? `${rs?.building?.[0]?.name}`
                                          : `-`}
                                      </td>
                                    )}

                                    {community && (showMixedCluster || showBuildingUnitGroup) && (
                                      <td
                                        className={`text-start ${
                                          rs?.isDisable ? 'disable-opacity' : ''
                                        }`}
                                      >
                                        {rs?.unit_group?.length > 0
                                          ? `${rs?.unit_group?.[0]?.name}`
                                          : `-`}
                                      </td>
                                    )}

                                    {community && (
                                      <td
                                        className={`text-start ${
                                          rs?.isDisable ? 'disable-opacity' : ''
                                        }`}
                                      >
                                        {rs?.cluster?.length > 0
                                          ? `${rs?.cluster?.[0]?.name}`
                                          : `-`}
                                      </td>
                                    )}

                                    <td
                                      className={`text-start ${
                                        rs?.isDisable ? 'disable-opacity' : ''
                                      }`}
                                    >
                                      {rs?.community?.length > 0
                                        ? `${rs?.community?.[0]?.name}`
                                        : rs?.building.length > 0
                                        ? `${rs?.building?.[0]?.name}`
                                        : `-`}
                                    </td>
                                    {/* {community && (
                                <td className='text-start min-w-100px'>
                                  {rs?.cluster?.length > 0 ? `${rs?.cluster?.[0]?.name}` : `-`}
                                </td>)} */}

                                    {jobType == 'unit' && (
                                      <td
                                        className={`text-start ${
                                          rs?.isDisable ? 'disable-opacity' : ''
                                        }`}
                                      >
                                        {Number(rs?.unitType) !== 2 && (
                                          <>
                                            {rs?.occupy == 1 ? (
                                              <span className='text-success '>
                                                <b>Occupied</b>
                                              </span>
                                            ) : rs?.occupy == 0 ? (
                                              <span className='text-danger '>
                                                <b>Vacant</b>
                                              </span>
                                            ) : (
                                              '-'
                                            )}
                                          </>
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <>
                              <tr>
                                {!isLoading ? (
                                  <td colSpan={7} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                ) : (
                                  <td colSpan={7} className='text-center'>
                                    <div
                                      className='d-flex justify-content-center align-items-center w-100'
                                      style={{height: '25vh'}}
                                    >
                                      <CircularProgress style={{color: '#007a59'}} />
                                    </div>
                                  </td>
                                )}
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default AddJobsModal

import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import submitWhite from '../../../../img/Submit-White.png'
import {useParams} from 'react-router-dom'
import ModalLoader from '../../General/ModalLoader'
import moment from 'moment'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import trashImg from '../../../../img/trash.png'
import uploadFileIcon from '../../../../img/upload-file-icon.png'
import redCross from '../../../../img/remove.png'
import saveGreenImg from '../../../../img/save-dark-green.png'
import greenPen from '../../../../img/pen.png'
import pdfIcon from '../../../../img/pdf.svg'
import downloadIcon from '../../../../img/download.png'
import axios from 'axios'
import AttachmentPreview from '../../General/AttachmentsPreview'
import swal from 'sweetalert2'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'

interface ButtonProps {
  show: any
  handleClose: any
  updateData?: any
  updateDataAfterDelete: any
  expData?: any
  mode?: any
  selectedItem?: any
  slctdDev?: any
}

const ViewEditPaymentRecord = ({
  show,
  handleClose,
  updateData,
  updateDataAfterDelete,
  expData,
  mode,
  selectedItem,
  slctdDev,
}: ButtonProps) => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  // console.log('********* View Payment Record  ************')
  // console.log(expData)
  // console.log(selectedItem)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  let [outstanding, setOutstanding] = useState<any>([])

  const [goToNext, setGoToNext] = useState<any>(false)

  const [checkedValues, setCheckedValues] = useState<any>([])
  const [isEdit, setIsEdit] = useState<any>(false)

  const [showLoader, setShowLoader] = useState<boolean>(false) 
  const [uploadProgress, setUploadProgress] = useState<number>(0) 
  const [totalFiles, setTotalFiles] = useState<number>(0) 

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  let totalSelected: any = 0

  let totalAmountPaid: any = 0

  const [recordItems, setRecordItems] = useState<any>(selectedItem?.items)
  const [savedRecord, setSavedRecord] = useState<any>(selectedItem?.items)

  const [paymentRecord, setPaymentRecord] = useState<any>()

  const [selectedDevelopment, setSelectedDevelopment] = useState<any>()

  useEffect(()=>{

    let selectedVal:any = {...recordItems}
    selectedVal = {
      items: selectedItem?.items
    }

    // console.log(selectedItem?.items)
    expData?.items?.map((expItm:any, expInd:any)=>{

      selectedItem?.items?.map((slItm:any, slInd:any)=>{
       
        let previousAmount:any = Number(slItm?.amount)
        let previousQty:any = Number(slItm?.quantity)
        let previousTotalAmount:any = slItm?.totalAmount
        
        if(expItm?.paymentItemListId == slItm?.paymentItemListId && expItm?.frontendStorage?.itemUniqueId ==  slItm?.frontendStorage?.itemUniqueId)
        {
          
          selectedVal.items[slInd].amount = Number(expItm?.amount)
          selectedVal.items[slInd].quantity = Number(expItm?.quantity)
          selectedVal.items[slInd].VATPercentage = Number(expItm?.VATPercentage)
          selectedVal.items[slInd].VATAmount = ((Number(expItm?.amount)* Number(expItm?.quantity))/100)*Number(expItm?.VATPercentage)
          selectedVal.items[slInd].totalAmount =  (Number(expItm?.amount)*Number(expItm?.quantity)) + Number(expItm?.VATAmount)
          selectedVal.items[slInd].outstandingAmount = (Number(slItm?.outstandingAmount) + (Number(selectedVal.items[slInd]?.totalAmount)-Number(previousTotalAmount)))
        }

      })
    })

    setRecordItems(selectedVal?.items)

    setSavedRecord(selectedVal?.items)


  }, [expData])

  useEffect(() => {
    // console.log(slctdDev)
    setSelectedDevelopment(slctdDev)
  }, [slctdDev])

  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...paymentRecord}
      values.datePaid = date.format('YYYY-MM-DD')
      setPaymentRecord(values)
    } else {
      let values = {...paymentRecord}
      values.datePaid = null
      setPaymentRecord(values)
    }

    // if (flg == '2') {
    //   setTimeout(() => {
    //     setAutoSaveData(Date.now())
    //   }, 700)
    // }
  }

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [isLoading, setIsLoading] = useState<any>(false)

  // const [showLoader, setShowLoader] = useState<boolean>(false)

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  //
  // const hanldeUploadReceipt = async (e: any) => {
  //   if (e.target.files && e.target.files[0]) {
  //     // if (e.target.files[0]?.type === 'application/pdf') {
  //     setShowLoader(true)
  //     var formdata = new FormData()
  //     formdata.append('image', e.target.files[0])

  //     let userData: any = localStorage.getItem('userData')
  //     const data = JSON.parse(userData)

  //     let name: any = e.target.files[0]?.name

  //     await ApiPost(
  //       `upload/image/params?fileType=receipt&databaseId=${data._id}&fileName=${name}`,
  //       formdata
  //     )
  //       .then((res) => {
  //         let values = {...paymentRecord}
  //         values.receipt.URL = res?.data?.data?.image
  //         setPaymentRecord(values)

  //         setShowLoader(false)
  //       })
  //       .catch((err) => {
  //         setShowLoader(false)
  //         ErrorToast(err?.message)
  //       })
  //     // } else {
  //     //   ErrorToast('Files other than PDF are not allowed..')
  //     // }
  //   }
  // }

  const handleUploadReceipt = async (e: any) => {
    e.preventDefault();
  
    const values = { ...paymentRecord };
    const valuesReceipt = [...values.receipts];
  
    if (e.target.files) {
      setShowLoader(true);
      setTotalFiles(e.target.files.length)
  
      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i];
  
        let userData: any = localStorage.getItem('userData');
        const data = JSON.parse(userData);
  
        var formdata = new FormData();
        formdata.append('image', fileURL);

        let name: any =  (e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)) ;
        const nameParts = name.split('.');
        const extension = nameParts.pop();
        let baseName = nameParts.join('.');
  
        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName;
        let counter = 1;
        while (valuesReceipt.some(receipt => receipt.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`;
          counter++;
        }
        name = `${uniqueName}.${extension}`;
  
        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/');
            const fileName = name[name.length - 1];
  
            valuesReceipt.push({
              URL: res?.data?.data?.image,
              name: (fileName?.charAt(0).toUpperCase() + fileName?.slice(1)) 
            });
  
            // console.log(values);
             // update the upload progress
             setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message);
            setShowLoader(false);
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          });
      }
      values.receipts = valuesReceipt;
      setPaymentRecord(values);
      setShowLoader(false);
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null;
    }
  };

  //
  // const handleUploadAttchment = async (e: any) => {
  //   if (e.target.files && e.target.files[0]) {
  //     // if (e.target.files[0]?.type === 'application/pdf') {
  //     setShowLoader(true)
  //     var formdata = new FormData()
  //     formdata.append('image', e.target.files[0])

  //     let userData: any = localStorage.getItem('userData')
  //     const data = JSON.parse(userData)

  //     let name: any = e.target.files[0]?.name

  //     await ApiPost(
  //       `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
  //       formdata
  //     )
  //       .then((res) => {
  //         let values = {...paymentRecord}
  //         values.attachment.URL = res?.data?.data?.image
  //         setPaymentRecord(values)

  //         setShowLoader(false)
  //       })
  //       .catch((err) => {
  //         setShowLoader(false)
  //         ErrorToast(err?.message)
  //       })
  //     // } else {
  //     //   ErrorToast('Files other than PDF are not allowed..')
  //     // }
  //   }
  // }

  const handleUploadAttchment = async (e: any) => {
    e.preventDefault();
  
    const values = { ...paymentRecord };
    const valuesAttachments = [...values.attachments];
  
    if (e.target.files) {
      setShowLoader(true);
      setTotalFiles(e.target.files.length)
  
      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i];
  
        let userData: any = localStorage.getItem('userData');
        const data = JSON.parse(userData);
  
        var formdata = new FormData();
        formdata.append('image', fileURL);
  
        let name: any =  (e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)) ;
        const nameParts = name.split('.');
        const extension = nameParts.pop();
        let baseName = nameParts.join('.');
  
        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName;
        let counter = 1;
        while (valuesAttachments.some(attachment => attachment.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`;
          counter++;
        }
        name = `${uniqueName}.${extension}`;
  
        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/');
            const fileName = name[name.length - 1];
  
            valuesAttachments.push({
              URL: res?.data?.data?.image,
              name: (fileName?.charAt(0).toUpperCase() + fileName?.slice(1)) 
            });

            setUploadProgress(i + 1)

  
          })
          .catch((err: any) => {
            ErrorToast(err?.message);
            setShowLoader(false);
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          });
      }
      values.attachments = valuesAttachments;
  
      // console.log(values);
      setPaymentRecord(values);
      setShowLoader(false);

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null;
    }
  };
  

  const [receiptData, setReceiptData] = useState<any>({
    name: '',
    URL: '',
  })

  const [attchmentData, setAttachmentData] = useState<any>({
    name: '',
    URL: '',
  })

  useEffect(() => {
    // console.log(selectedItem)
    setPaymentRecord(selectedItem)
  }, selectedItem)

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const deletePaymentRecord = (val: any) => {
    const body = {
      ids: [val],
      expenseId: id,
    }
    ApiPost(`corporate/expense_payment_record/delete`, body)
      .then((res: any) => {
        SuccessToast('Payment Record has been deleted successfully')
        updateDataAfterDelete()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    let values: any = []
    selectedItem?.items?.map((itm: any, ind: any) => {
      // console.log(itm)
      if (flg == '1') values[ind] = Number(itm?.outstandingAmount) + Number(itm?.totalAmountPaid)
      else values[ind] = Number(itm?.outstandingAmount) + Number(itm?.amountPaid)
    
    })
    // console.log(values)
    setOutstanding(values)
  }, [selectedItem])

  const truncateFileName = (name: any, length: any) => {
    if (name.length <= length) return name

    const parts = name.split('.')
    const extension = parts.length > 1 ? '.' + parts.pop() : ''
    const truncatedName = parts.join('.').slice(0, length - 3 - extension.length) + '...'

    return truncatedName + extension
  }

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  //
  const handleImageClick = (val: any, valSrc: any, type: any) => {
    const groupName = `group_${type}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', valSrc)
    }

    // Add data-fancybox attribute to the related receipts
    if (type == 'receipts') {
      paymentRecord?.receipts?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedReceipt${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    } else {
      paymentRecord?.attachments?.map((attch: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedAttachment${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${attch?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    }

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  const [isAmountExceeded, setIsAmountExceeded] = useState<any>(false)

  useEffect(()=>{
    let isExceeded:any = false
    expData?.items?.map((itm: any, ind: any) => {
      if(((flg == '1' &&
        Number(itm?.totalAmountPaid) > Number(outstanding[ind])) ||
        (flg == '2' &&
          Number(itm?.amountPaid) > Number(outstanding[ind]))))
        isExceeded = true
    })

    setIsAmountExceeded(isExceeded)

  },[recordItems, outstanding])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={show}
        onHide={() => {
          let record:any = localStorage?.getItem('ViewPaymentRecordItem')
          let recordItems : any  = JSON.parse(record)
          // console.log(recordItems?.items)
          setRecordItems(recordItems?.items)
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '350px',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center mt-3'>
              <h2 className='  mb-0'>
                <b>{isEdit ? 'Edit ' : ''} Payment Record</b>
              </h2>

              {!isEdit && (
                <img
                  src={greenPen}
                  height={18}
                  width={18}
                  className='mx-6 cursor-pointer'
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>

            <div className='d-flex align-items-center ms-auto'>
              {!isEdit && (
                <>
                  <img
                    src={trashImg}
                    height={18}
                    width={18}
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Payment Record</></div>
                           <div class='fs-5 mt-4'>You are about to delete the payment record.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            if (flg == '1') {
                              updateDataAfterDelete()
                            } else deletePaymentRecord(paymentRecord?._id)
                          }
                        })
                    }}
                    className='me-5 cursor-pointer'
                  />{' '}
                  {paymentRecord?.updatedAt ? (
                    <p className='mb-0 me-5 fnt-500'>
                      Last Updated{' '}
                      {`${moment
                        ?.utc(paymentRecord?.updatedAt)
                        ?.local()
                        ?.format('DD.MM.YYYY - hh:mm A')}`}
                    </p>
                  ) : (
                    <p className='mb-0 me-5 fnt-500'>
                      Created{' '}
                      {`${moment
                        ?.utc(paymentRecord?.createdAt)
                        ?.local()
                        ?.format('DD.MM.YYYY - hh:mm A')}`}
                    </p>
                  )}
                </>
              )}
              <label
                className='head-text fs-2 cursor-pointer ms-7'
                style={{fontWeight: '700'}}
                onClick={() => {
                  let record:any = localStorage?.getItem('ViewPaymentRecordItem')
                  let recordItems : any  = JSON.parse(record)
                  // console.log(recordItems?.items)
                  setRecordItems(recordItems?.items)
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>
          
          {recordItems?.map((itm: any, ind: any) => {
            totalAmountPaid = Number(totalAmountPaid) + Number(itm?.amountPaid)
            return <></>
          })}

          {isEdit && (
            <div className='d-flex align-items-center mt-2 justify-content-end me-3'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  red-hollow-btn ps-4'
                onClick={() => {
                  let record:any = localStorage?.getItem('ViewPaymentRecordItem')
                  let recordItems : any  = JSON.parse(record)
                  // console.log(recordItems?.items)
                  setRecordItems(recordItems?.items)
                  setIsEdit(false)
                }}
              >
                <img src={redCross} height={18} width={18} className='me-4' /> Cancel
              </button>

              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  green-hollow-btn ps-4'
                onClick={() => {
                  let values = {...paymentRecord}
                  values.totalAmountPaid = totalAmountPaid

                  updateData(values)
                  setIsEdit(false)
                }}
                disabled={(totalAmountPaid == 0 || paymentRecord?.datePaid == null || isAmountExceeded == true) ? true : false}
              >
                <img src={saveGreenImg} height={18} width={18} className='me-4' /> Save
              </button>
            </div>
          )}

          <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
            <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                  <th className='text-start min-w-150px ps-0'>Item</th>
                  <th className='text-start min-w-75px ps-0'>QTY </th>
                  <th className='text-start min-w-100px ps-0'>Total Amount </th>
                  <th className='text-start min-w-100px ps-0'>Outstanding </th>
                  <th className='text-start min-w-150px ps-0'>Property </th>
                  <th className='text-start min-w-150px ps-0'>Amount Paid </th>
                </tr>
              </thead>

              <tbody className='fw-semibold text-gray-600'>
                {recordItems?.map((itm: any, ind: any) => {
                  return (
                    <>
                      <tr className=''>
                        <td className='text-start  ps-0'>{itm?.name ? itm?.name : '-'}</td>
                        <td className='text-start  ps-0'>{itm?.quantity ? itm?.quantity : '-'}</td>
                        <td className='text-start  ps-0'>
                          {itm?.amount
                            ? `AED ${(
                                Number(itm?.amount) * Number(itm?.quantity) +
                                itm?.VATAmount
                              )?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })}`
                            : 'AED -'}
                        </td>
                        <td className='text-start  ps-0'>
                          {itm?.outstandingAmount != null
                            ? `AED ${Number(outstanding[ind])?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })}`
                            : 'AED -'}
                          {/* {flg == '1' ? (
                            <>
                            
                              {itm?.outstandingAmount
                                ? `AED ${(
                                    Number(itm?.outstandingAmount) + Number(itm?.totalAmountPaid)
                                  )?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </>
                          ) : (
                            <>
                              {itm?.outstandingAmount
                                ? `AED ${(
                                    Number(itm?.outstandingAmount) + Number(itm?.amountPaid)
                                  )?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </>
                          )} */}
                        </td>

                        <td className='text-start  ps-0'>
                          {itm?.property ? (
                            <>
                              <div className='d-flex ms-1'>
                                <span className=''>
                                  {/* <b> */}
                                  {/* {itm?.property?.level == 1
                                    ? selectedDevelopment?.[0]?.label
                                    : itm?.property?.details?.unitNo} */}

                                  {itm?.property?.level == 1
                                    ? itm?.property?.details
                                    : itm?.property?.details?.unitNo}
                                  {/* </b> */}
                                </span>
                              </div>
                            </>
                          ) : (
                            '-'
                          )}
                        </td>

                        {isEdit ? (
                          <td className='text-start  ps-0'>
                            <input
                              //   {...register('documentName', {required: true})}
                              type='number'
                              className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                flg == '1' &&
                                Number(itm?.totalAmountPaid) > Number(outstanding[ind])
                                  ? 'border-danger'
                                  : flg == '2' && Number(itm?.amountPaid) > Number(outstanding[ind])
                                  ? 'border-danger'
                                  : ''
                              }`}
                              name='documentName'
                              value={flg == '1' ? itm?.totalAmountPaid : itm?.amountPaid}
                              style={{height: '35px'}}
                              placeholder={'AED'}
                              onChange={(e: any) => {
                                let values = [...recordItems]
                                values[ind].amountPaid = e.target.value?.trim()?.length != 0 ? Number(e.target.value) : ''
                                values[ind].totalAmountPaid = e.target.value?.trim()?.length != 0 ? Number(e.target.value) : ''

                                // console.log(Number(outstanding[ind]))
                                // console.log(values[ind].totalAmountPaid)
                                // console.log(values[ind].amountPaid)

                                // values[ind].outstandingAmount = (
                                //   Number( values[ind]?.amount) * Number( values[ind]?.quantity) +
                                //   values[ind]?.VATAmount
                                // ) - Number(e.target.value)

                                setRecordItems(values)
                              }}
                               autoComplete="off"
                            />
                            {((flg == '1' &&
                              Number(itm?.totalAmountPaid) > Number(outstanding[ind])) ||
                              (flg == '2' &&
                                Number(itm?.amountPaid) > Number(outstanding[ind]))) && (
                              <p
                                className='text-danger mb-0 ms-0'
                                style={{maxWidth: '200px', wordBreak: 'break-word'}}
                              >
                                {' '}
                                Amount paid cannot exceed the outstanding amount of your expense
                                item. Edit the total amount of your expense item to be able to enter
                                a greater amount paid.
                              </p>
                            )}
                          </td>
                        ) : (
                          <td className='text-start  ps-0'>
                            {flg == '1' ? (
                              <>
                                {itm?.totalAmountPaid >= 0
                                  ? `AED ${itm?.totalAmountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : 'AED -'}
                              </>
                            ) : (
                              <>
                                {itm?.amountPaid >= 0
                                  ? `AED ${itm?.amountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : 'AED -'}
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='separator my-5 m'></div>

          <div className='d-flex align-items-center mt-10'>
            <div className='d-block'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className=''>
                  {' '}
                  Date Paid
                </label>
              </h4>
              {isEdit ? (
                <div className='expense-date'>
                  {paymentRecord?.datePaid != null ? (
                    <DatePicker
                      name='deliveryDate'
                      defaultValue={moment(paymentRecord?.datePaid, 'YYYY-MM-DD')}
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                      placeholder='Select Payment Date'
                    />
                  ) : (
                    <DatePicker
                      name='deliveryDate'
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                      placeholder='Select Payment Date'
                    />
                  )}
                </div>
              ) : (
                <>
                  <p className='mt-5'>
                    {' '}
                    <b>
                      {paymentRecord?.datePaid != null
                        ? `${moment?.utc(paymentRecord?.datePaid)?.local()?.format('DD.MM.YYYY')}`
                        : '-'}
                    </b>
                  </p>
                </>
              )}
            </div>
            <div className='d-block ms-auto text-end'>
              <h2 className=' pt-2 mb-0 ms-auto'>
                <b> Total Amount Paid</b>
              </h2>
              <h2 className=' pt-2 mb-0 '>
                <b>
                  {totalAmountPaid > 0
                    ? `AED ${totalAmountPaid?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`
                    : 'AED -'}
                </b>
              </h2>

              <h3 className=' pt-2 mb-0'></h3>
            </div>
          </div>

          <div className='row mx-0 d-flex mt-10 payment-record'>
            <div
              className='col-12 col-md-6 ps-0 '
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {isEdit ? ( 
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Receipt
                    </h2>{' '}
                  </div>
                  <div className='d-flex justify-content-center xyz my-2 mt-0'>
                    <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center  mb-0'>
                      Add the receipt details for your payment:
                    </p>{' '}
                  </div>
                  {/* <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                    <input
                      //   {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='documentName'
                      value={paymentRecord?.receipt?.name}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Receipt No.'}
                      onChange={(e: any) => {
                        let values = {...paymentRecord}
                        values.receipt.name = e.target.value
                        setPaymentRecord(values)
                      }}
                    />
                  </div> */}

                  <div className='mt-5 '>
                    {/* {paymentRecord?.receipt?.URL?.trim()?.length == 0 ? ( */}
                    <div className='d-flex align-items-start justify-content-center'>
                      <form
                        id='form-file-upload-po'
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                        style={{height: '110px'}}
                      >
                        <label
                          id='form-file-upload-po'
                          htmlFor='form-file-upload-po-receipt'
                          className={
                            dragActive
                              ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                              : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                          }
                          style={{
                            fontSize: '16px',
                            color: 'white',
                          }}
                          ref={inputRef}
                        >
                          <div
                            className={`d-flex justify-content-center px-8 align-items-center ${
                              isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                            }`}
                          >
                            <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                              style={{minWidth: '90px'}}
                            >
                              <b>Drag & drop your file </b> or{' '}
                            </div>
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                              style={{minWidth: '90px'}}
                            >
                              <div
                                className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                style={{}}
                              >
                                Browse Files
                              </div>
                            </div>
                          </div>
                        </label>
                        <input
                          type='file'
                          multiple={true}
                          onChange={(e: any) => {
                            handleUploadReceipt(e)
                          }}
                          id='form-file-upload-po-receipt'
                          ref={inputRef}
                          className='file-input-drag cursor-pointer'
                          // accept='application/pdf'
                        />
                        {dragActive && (
                          <div
                            id='drag-file-element'
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </form>
                    </div>
                    {/* // ) : ( */}
                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.receipts?.map((prRcpt: any, ind: any) => {
                        return (
                          <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center px-2 pe-7'>
                            <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                            {editFile == `Receipt${ind}` ? (
                              <div
                                className='d-flex  vendor me-5 pe-2'
                                style={{width: '-webkit-fill-available'}}
                              >
                                <input
                                  type='text'
                                  value={newName}
                                  placeholder={'Enter receipt name'}
                                  className='form-control form-control-solid mytest ms-3'
                                  onChange={(e: any) => {
                                    let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                    setNewName(newValue)
                                  }}
                                  disabled={isLoading}
                                   autoComplete="off"
                                /> 
                              </div>
                            ) : (
                              <p className='mb-0 ms-2  me-7 fnt-500'>
                                {truncateFileName(prRcpt?.name, 35)}
                              </p>
                            )}

                            <div className='d-flex align-items-center ms-auto'>
                              <img
                                 src={
                                  prRcpt?.URL?.split('.')[1] === 'pdf'
                                    ? pdfDocument
                                    : prRcpt?.URL?.split('.').pop() === 'doc' ||
                                      prRcpt?.URL?.split('.').pop() === 'docx'
                                    ? docCocument
                                    : prRcpt?.URL?.split('.').pop() === 'jpeg' ||
                                      prRcpt?.URL?.split('.').pop() === 'jpg' ||
                                      prRcpt?.URL?.split('.').pop() === 'png' ||
                                      prRcpt?.URL?.split('.').pop() === 'svg' ||
                                      prRcpt?.URL?.split('.').pop() === 'gif'
                                    ? pngImg
                                    : prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mp4' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'webm' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'avi' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mkv' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mov' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'flv' ||
                                      prRcpt?.URL?.split('.').pop()?.toLowerCase() ===
                                        'wmv'
                                    ? ``
                                    : fileIcon
                                }
                                
                                height={30}
                                width={30}
                                className='cursor-pointer'
                                id={`clickedReceipt${ind}`}
                                // data-fancybox
                                // data-src={`${Bucket}${prRcpt?.URL}`}
                                style={
                                  editFile == `Receipt${ind}`
                                    ? {opacity: '0.5', cursor: 'not-allowed'}
                                    : {opacity: '1', cursor: 'pointer'}
                                }
                                onClick={() =>
                                  handleImageClick(
                                    `clickedReceipt${ind}`,
                                    `${Bucket}${prRcpt?.URL}`,
                                    'receipts'
                                  )
                                }
                              />
                              {editFile == `Receipt${ind}` ? (
                                <>
                                  <img
                                    src={correct}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      let values = {...paymentRecord}
                                      values.receipts[ind].name = newName
                                      setPaymentRecord(values)
                                      setEditFile(-1)
                                    }}
                                  />
                                  <img
                                    src={removed}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      setEditFile(-1)
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={greenPen}
                                    height={18}
                                    width={18}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      setNewName(prRcpt?.name)
                                      setEditFile(`Receipt${ind}`)
                                    }}
                                  />
                                  <img
                                    src={downloadIcon}
                                    height={18}
                                    width={18}
                                    className='ms-4 cursor-pointer'
                                    onClick={() => {
                                      handleDownload(`${Bucket}${prRcpt?.URL}`)
                                    }}
                                  />
                                  <img
                                    src={trashImg}
                                    height={16}
                                    width={16}
                                    onClick={() => {
                                      let values = {...paymentRecord}
                                      let receiptValues = [...values.receipts]
                                      receiptValues.splice(ind, 1)
                                      values.receipts = receiptValues
                                      setPaymentRecord(values)
                                    }}
                                    className='ms-4 cursor-pointer'
                                  />{' '}
                                </>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-5'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Receipt
                    </h2>{' '}
                  </div>
                  {paymentRecord?.receipts?.length > 0 ? (
                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.receipts?.map((rs: any, ind: any) => {
                        return (
                          <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center pe-7'>
                            <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                            <p className='mb-0 ms-2  me-7 fnt-500'>
                              {truncateFileName(rs?.name, 35)}
                            </p>
                            {/* <a href={`${Bucket}${paymentRecord?.receipt?.URL}`} target='_blank'> */}
                            <img
                             src={
                              rs?.URL?.split('.')[1] === 'pdf'
                                ? pdfDocument
                                : rs?.URL?.split('.').pop() === 'doc' ||
                                  rs?.URL?.split('.').pop() === 'docx'
                                ? docCocument
                                : rs?.URL?.split('.').pop() === 'jpeg' ||
                                  rs?.URL?.split('.').pop() === 'jpg' ||
                                  rs?.URL?.split('.').pop() === 'png' ||
                                  rs?.URL?.split('.').pop() === 'svg' ||
                                  rs?.URL?.split('.').pop() === 'gif'
                                ? pngImg
                                : rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mp4' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'webm' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'avi' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mkv' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mov' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'flv' ||
                                  rs?.URL?.split('.').pop()?.toLowerCase() ===
                                    'wmv'
                                ? ``
                                : fileIcon
                              }
                              height={30}
                              width={30}
                              className='mx-4 ms-auto cursor-pointer'
                              // data-fancybox
                              // data-src={`${Bucket}${rs?.URL}`}
                              id={`clickedReceipt${ind}`}
                              onClick={() =>
                                handleImageClick(
                                  `clickedReceipt${ind}`,
                                  `${Bucket}${rs?.URL}`,
                                  'receipts'
                                )
                              }
                            />
                            {/* </a> */}
                            <img
                              src={downloadIcon}
                              height={18}
                              width={18}
                              className='ms-5 cursor-pointer'
                              onClick={() => {
                                handleDownload(`${Bucket}${rs?.URL}`)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='d-flex justify-content-center'>
                      <p>No Record</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className='col-12 col-md-6 pe-0'
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {isEdit ? (
                <div className='card p-5 px-9 h-100'>
                  <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Other Attachments
                    </h2>{' '}
                  </div>

                  <div className='d-flex justify-content-center xyz my-2 mt-0'>
                    <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center mb-0'>
                      Attach any other document related to your payment
                    </p>{' '}
                  </div>

                  {/* <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                    <input
                      //   {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='documentName'
                      value={paymentRecord?.attachment?.name}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Attachment Name'}
                      onChange={(e: any) => {
                        let values = {...paymentRecord}
                        values.attachment.name = e.target.value
                        setPaymentRecord(values)
                      }}
                    />
                  </div> */}

                  <div className='mt-5'>
                    <div className='d-flex align-items-start justify-content-center'>
                      <form
                        id='form-file-upload-po'
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                        style={{height: '110px'}}
                      >
                        <label
                          id='form-file-upload-po'
                          htmlFor='form-file-upload-poo'
                          className={
                            dragActive
                              ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                              : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                          }
                          style={{
                            fontSize: '16px',
                            color: 'white',
                          }}
                          ref={inputRef}
                        >
                          <div
                            className={`d-flex justify-content-center px-8 align-items-center ${
                              isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                            }`}
                          >
                            <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                              style={{minWidth: '90px'}}
                            >
                              <b>Drag & drop your file </b> or{' '}
                            </div>
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                              style={{minWidth: '90px'}}
                            >
                              <div
                                className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                style={{}}
                              >
                                Browse Files
                              </div>
                            </div>
                          </div>
                        </label>
                        <input
                          type='file'
                          multiple={true}
                          onChange={(e: any) => {
                            handleUploadAttchment(e)
                          }}
                          id='form-file-upload-po'
                          ref={inputRef}
                          className='file-input-drag cursor-pointer'
                          // accept='application/pdf'
                        />
                        {dragActive && (
                          <div
                            id='drag-file-element'
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </form>
                    </div>

                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.attachments?.map((prAtch: any, ind: any) => {
                        return (
                          <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center px-2 pe-7'>
                            <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                            {editFile == ind ? (
                              <div
                                className='d-flex  vendor me-5 pe-2'
                                style={{width: '-webkit-fill-available'}}
                              >
                                <input
                                  type='text'
                                  value={newName}
                                  placeholder={'Enter attachment name'}
                                  className='form-control form-control-solid mytest ms-3'
                                  onChange={(e: any) => {
                                    let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                    setNewName(newValue)
                                  }}
                                  disabled={isLoading}
                                   autoComplete="off"
                                />
                              </div>
                            ) : (
                              <p className='mb-0 ms-2  me-7 fnt-500'>
                                {truncateFileName(prAtch?.name, 35)}
                              </p>
                            )}

                            <div className='d-flex align-items-center ms-auto'>
                              <img
                               src={
                                  prAtch?.URL?.split('.')[1] === 'pdf'
                                  ? pdfDocument
                                  :   prAtch?.URL?.split('.').pop() === 'doc' ||
                                      prAtch?.URL?.split('.').pop() === 'docx'
                                  ? docCocument
                                  :   prAtch?.URL?.split('.').pop() === 'jpeg' ||
                                      prAtch?.URL?.split('.').pop() === 'jpg' ||
                                      prAtch?.URL?.split('.').pop() === 'png' ||
                                      prAtch?.URL?.split('.').pop() === 'svg' ||
                                    prAtch?.URL?.split('.').pop() === 'gif'
                                  ? pngImg
                                  : prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'mp4' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'webm' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'avi' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'mkv' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'mov' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'flv' ||
                                    prAtch?.URL?.split('.').pop()?.toLowerCase() ===
                                      'wmv'
                                  ? ``
                                  : fileIcon
                                }
                                height={30}
                                width={30}
                                className=''
                                // data-fancybox
                                // data-src={`${Bucket}${prAtch?.URL}`}
                                id={`clickedAttachment${ind}`}
                                style={
                                  editFile == ind
                                    ? {opacity: '0.5', cursor: 'not-allowed'}
                                    : {opacity: '1', cursor: 'pointer'}
                                }
                                onClick={() =>
                                  handleImageClick(
                                    `clickedAttachment${ind}`,
                                    `${Bucket}${prAtch?.URL}`,
                                    'attachments'
                                  )
                                }
                              />
                              {editFile == ind ? (
                                <>
                                  <img
                                    src={correct}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      let values = {...paymentRecord}
                                      values.attachments[ind].name = newName
                                      setPaymentRecord(values)
                                      setEditFile(-1)
                                    }}
                                  />
                                  <img
                                    src={removed}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      setEditFile(-1)
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={greenPen}
                                    height={18}
                                    width={18}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      setNewName(prAtch?.name)
                                      setEditFile(ind)
                                    }}
                                  />
                                  <img
                                    src={downloadIcon}
                                    height={18}
                                    width={18}
                                    className='ms-4 cursor-pointer'
                                    onClick={() => {
                                      handleDownload(`${Bucket}${prAtch?.URL}`)
                                    }}
                                  />
                                  <img
                                    src={trashImg}
                                    height={16}
                                    width={16}
                                    onClick={() => {
                                      let values = {...paymentRecord}
                                      let attachValues = [...values.attachments]
                                      attachValues.splice(ind, 1)
                                      values.attachments = attachValues
                                      setPaymentRecord(values)
                                    }}
                                    className='ms-4 cursor-pointer'
                                  />{' '}
                                </>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-5'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Other Attachments
                    </h2>{' '}
                  </div>
                  {paymentRecord?.attachments?.length > 0 ? (
                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.attachments?.map((pAttach: any, ind: any) => {
                        return (
                          <>
                            <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center pe-7'>
                              <div className='bullet-item-dark-green  me-3 mb-0 ms-1'></div>
                              <p className='mb-0 ms-2 me-7 fnt-500'>
                                {truncateFileName(pAttach?.name, 35)}
                              </p>
                              {/* <a href={`${Bucket}${paymentRecord?.attachment?.URL}`} target='_blank'> */}
                              <img
                               src={
                                pAttach?.URL?.split('.')[1] === 'pdf'
                                ? pdfDocument
                                : pAttach?.URL?.split('.').pop() === 'doc' ||
                                  pAttach?.URL?.split('.').pop() === 'docx'
                                ? docCocument
                                : pAttach?.URL?.split('.').pop() === 'jpeg' ||
                                  pAttach?.URL?.split('.').pop() === 'jpg' ||
                                  pAttach?.URL?.split('.').pop() === 'png' ||
                                  pAttach?.URL?.split('.').pop() === 'svg' ||
                                  pAttach?.URL?.split('.').pop() === 'gif'
                                ? pngImg
                                : pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mp4' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'webm' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'avi' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mkv' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'mov' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'flv' ||
                                  pAttach?.URL?.split('.').pop()?.toLowerCase() ===
                                    'wmv'
                                ? ``
                                : fileIcon
                              }
                               height={30}
                                width={30}
                                className='mx-4 ms-auto cursor-pointer'
                                // data-fancybox
                                // data-src={`${Bucket}${pAttach?.URL}`}

                                id={`clickedAttachment${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedAttachment${ind}`,
                                    `${Bucket}${pAttach?.URL}`,
                                    'attachments'
                                  )
                                }
                              />

                              {/* </a> */}
                              <img
                                src={downloadIcon}
                                height={18}
                                width={18}
                                className='ms-5 cursor-pointer'
                                onClick={() => {
                                  handleDownload(`${Bucket}${pAttach?.URL}`)
                                }}
                              />
                            </div>
                          </>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='d-flex justify-content-center'>
                      <p>No Record</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='row mx-0 mt-10'>
            <div className='card p-5 px-9 h-100' style={{}}>
              <div className='d-flex justify-content-center xyz mb-3 mt-5'>
                <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                  Notes
                </h2>{' '}
              </div>
              {!isEdit ? (
                <div className='d-flex justify-content-center xyz mb-3 mt-2'>
                  <p className=' d-flex text-dark flex-column justify-content-center '>
                    {paymentRecord?.notes ? paymentRecord?.notes : '-'}
                  </p>{' '}
                </div>
              ) : (
                <textarea
                  className='form-control form-control-solid mytest bg-white'
                  placeholder='Enter notes related to this payment'
                  name='message'
                  style={{minHeight: '100px'}}
                  value={paymentRecord?.notes}
                  onChange={(e: any) => {
                    let values = {...paymentRecord}
                    let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;

                    values.notes = newValue
                    setPaymentRecord(values)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* <SingleFileLoader isVisible={showLoader} /> */}

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default ViewEditPaymentRecord

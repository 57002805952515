import React from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  handleChnage: any
  serviceData: any
  getServiceData?: any
  serviceDetailData?: any
  serviceJobId : any
}

function AssignJob({show, handleClose, handleChnage, serviceData, getServiceData, serviceJobId, serviceDetailData}: ButtonProps) {
  const [internalStaff, setInternalStaff] = React.useState<any>()
  const [searchStaff, setSearchStaff] = React.useState<any>()
  const [searchDepartMentName, setSearchDepartMent] = React.useState<any>()
  const [searchTitle, setSearchTitle] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [checked, setChecked] = React.useState<any>()
  const [selectedItem, setSelectedItem] = React.useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  const requestServiceId: any = localStorage.getItem(currentTab)
  const navigate = useNavigate()

  var selectedStaff: any 

  const getInternalStaff = async () => {
    const body = {
      page: page,
      limit: 10,
      searchName: searchStaff ? searchStaff : '',
      searchDepartment: searchDepartMentName ? searchDepartMentName : '',
      searchTitle: searchTitle ? searchTitle : '',
    }
    await ApiPost(`corporate/staff/get`, body)
      .then((res) => {
        // setTableData(res?.data?.data)
        setInternalStaff(res?.data?.data?.staff_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getInternalStaff()
  }, [searchDepartMentName, searchTitle])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const handleSelectTeam = (item: any, index: any) => {
    setSelectedItem(item._id)
    setChecked(index)
   
    console.log("selected item")
    console.log(item)
    selectedStaff = item._id
    console.log(selectedStaff)
  }

  const addStaff = async () => {
    const body = {
      id: serviceJobId,
      staffId: selectedItem,
    }
    await ApiPut(`corporate/service_job`, body)
      .then((res) => {
        getServiceData()
        serviceDetailData()
      })
      .catch((e) => {
        console.log(e)
      })
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <h2>Assign Job </h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <Row>
          <Col lg={4} md={4} className='d-flex align-items-center'>
            <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
              Search Staff
            </label>
            <input
              type='text'
              name='sub_service'
              className='form-control form-control-solid solid'
              onChange={(e) => setSearchStaff(e.target.value)}
            />
            <button
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() => getInternalStaff()}
              style={{marginLeft: '10px'}}
            >
              Search
            </button>
          </Col>
          <Col lg={3} md={3} className='d-flex align-items-center'>
            <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
              Department
            </label>
            <select
              name='request_source'
              className='form-select form-select-solid'
              onChange={(e) => setSearchDepartMent(e.target.value)}
            >
              <option selected value=''>
                Select
              </option>
              {internalStaff?.map((item: any) => {
                return <option>{item?.department[0]?.name}</option>
              })}
            </select>
          </Col>
          <Col lg={3} md={3} className='d-flex align-items-center'>
            <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
              Title
            </label>
            <select
              name='request_status '
              className='form-select form-select-solid'
              onChange={(e) => setSearchTitle(e.target.value)}
            >
              <option selected value=''>
                Select
              </option>
              {internalStaff?.map((item: any) => {
                return <option>{item?.title}</option>
              })}
            </select>
          </Col>
        </Row>
        <div className='d-flex justify-content-center my-3'>
          <button className='btn btn-sm fw-bold btn-primary btn-green' onClick={addStaff}>
            ASSIGN
          </button>
        </div>

        <div className='pt-0 table-responsive'>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='text-center min-w-10px'></th>
                <th className='text-center min-w-100px'>Staff Name</th>
                <th className='text-center min-w-100px'>Departmet</th>
                <th className='text-center min-w-100px'>Title</th>
                <th className='text-center min-w-100px'></th>
                <th className='text-center min-w-100px'></th>
              </tr>
            </thead>
            <tbody className='fw-semibold text-gray-600'>
              {internalStaff?.map((m: any, i: any) => {
                return (
                  <tr className='text-center'>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={checked === i}
                          style={{cursor: 'pointer'}}
                          onChange={() => handleSelectTeam(m, i)}
                        />
                      </div>
                    </td>
                    <td>
                      {m?.firstName} {m?.lastName}
                    </td>
                    <td>{m?.department[0]?.name}</td>
                    <td>{m?.title}</td>
                    <td>{m?.expertise}</td>
                    <td>{m?.availability === 1 ? 'Availabel' : 'Unavailabel'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}

export default AssignJob

import {useEffect, useState} from 'react'

import SelectRecipientProperty from './SelectRecipientProperty'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Select from '@mui/material/Select'
import trashImg from '../../../../img/trash.png'
import create from '../../../../img/create.png'
import addWhite from '../../../../img/add-white.png'
import trashWhite from '../../../../img/cross-button.png'
import xMark from '../../../../img/x-mark.png'
import swal from 'sweetalert2'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
// import Tenants from '../Tenants/Tenats'
import {useParams} from 'react-router-dom'

interface RecipientProps {
  sendDataToParent?: any
  setAdded: any
  added: any
  addedProperties: any
  setAddedProperties: any
  properties: any
  setProperties: any
  autoSave: () => void
  editMode: any
}

const Recipient = ({
  setAdded,
  added,
  addedProperties,
  setAddedProperties,
  properties,
  setProperties,
  autoSave,
  editMode,
}: RecipientProps) => {
  // const [recipients, setRecipients] = useState<any>()
  const {flg} = useParams()
  const [selectModal, setSelectModal] = useState<any>(false)
  const [searchTenant, setSearchTenant] = useState<any>('')
  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')
  const [checkedValues, setCheckedValues] = useState<any>([])
  // const [properties, setProperties] = useState<any>([])
  const [defaultProperties, setDefaultProperties] = useState<any>([])

  // const [addedProperties, setAddedProperties] = useState<any>([])
  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  // const [added, setAdded] = useState<any>(false)

  // useEffect(() => {
  //   sendDataToParent(properties)
  // }, [properties])

  // useEffect(() => {
  //   if (!searchTenant && !searchDevelopment && !searchUnit) {
  //     setProperties(defaultProperties)
  //   } else if (searchTenant && !searchDevelopment && !searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchFirstName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchLastName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1

  //       if (isMatchFirstName || isMatchLastName) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   } else if (!searchTenant && !searchDevelopment && searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchForUnitNo =
  //         propertyItem?.unitNo?.toLowerCase().indexOf(searchUnit.toLowerCase()) !== -1

  //       if (isMatchForUnitNo) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   } else if (!searchTenant && searchDevelopment && !searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any, i: any) => {
  //       const isMatchForDevelopment =
  //         (propertyItem?.building?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1 ||  propertyItem?.community?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1)

  //       if (isMatchForDevelopment) {
  //         return propertyItem
  //       }
  //     })

  //     setProperties(filterProperties)
  //   } else if (searchTenant && !searchDevelopment && searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchFirstName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchLastName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1

  //       const isMatchForUnitNo =
  //         propertyItem?.unitNo?.toLowerCase().indexOf(searchUnit.toLowerCase()) !== -1

  //       if ((isMatchFirstName || isMatchLastName) && isMatchForUnitNo) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   } else if (searchTenant && searchDevelopment && !searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchFirstName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchLastName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchForDevelopment =
  //       (propertyItem?.building?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1 ||  propertyItem?.community?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1)

  //       if ((isMatchFirstName || isMatchLastName) && isMatchForDevelopment) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   } else if (!searchTenant && searchDevelopment && searchUnit) {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchForUnitNo =
  //         propertyItem?.unitNo?.toLowerCase().indexOf(searchUnit.toLowerCase()) !== -1

  //       const isMatchForDevelopment =
  //       (propertyItem?.building?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1 ||  propertyItem?.community?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1)

  //       if (isMatchForUnitNo && isMatchForDevelopment) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   } else {
  //     const filterProperties = defaultProperties?.filter((propertyItem: any) => {
  //       const isMatchFirstName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchLastName =
  //         propertyItem?.tenancy?.tenant?.firstName
  //           ?.toLowerCase()
  //           .indexOf(searchTenant.toLowerCase()) !== -1
  //       const isMatchForUnitNo =
  //         propertyItem?.unitNo?.toLowerCase().indexOf(searchUnit.toLowerCase()) !== -1

  //       const isMatchForDevelopment =
  //       (propertyItem?.building?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1 ||  propertyItem?.community?.name?.toLowerCase().indexOf(searchDevelopment.toLowerCase()) !== -1)

  //       if ((isMatchFirstName || isMatchLastName) && isMatchForUnitNo && isMatchForDevelopment) {
  //         return propertyItem
  //       }
  //     })
  //     setProperties(filterProperties)
  //   }
  // }, [searchTenant, searchDevelopment, searchUnit])

  useEffect(() => {
    console.log(defaultProperties)

    const filterProperties = defaultProperties.filter((item: any) => {
      // all
      if (
        searchTenant?.trim()?.length != 0 &&
        searchDevelopment?.trim()?.length != 0 &&
        searchUnit?.trim()?.length != 0
      )
        return (
          item?.unitNo?.toLowerCase()?.includes(searchUnit?.toLowerCase()) &&
          (item?.building?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase()) ||
            item?.community?.[0]?.name
              ?.toLowerCase()
              ?.includes(searchDevelopment?.toLowerCase())) &&
          (item?.tenancy?.tenant?.firstName?.toLowerCase()?.includes(searchTenant?.toLowerCase()) ||
            item?.tenancy?.tenant?.lastName?.toLowerCase()?.includes(searchTenant?.toLowerCase()))
        )
      // unit & dev
      else if (
        searchTenant?.trim()?.length == 0 &&
        searchDevelopment?.trim()?.length != 0 &&
        searchUnit?.trim()?.length != 0
      )
        return (
          item?.unitNo?.toLowerCase()?.includes(searchUnit?.toLowerCase()) &&
          (item?.building?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase()) ||
            item?.community?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase()))
        )

      // unit & tenant
      if (
        searchTenant?.trim()?.length != 0 &&
        searchDevelopment?.trim()?.length == 0 &&
        searchUnit?.trim()?.length != 0
      )
        return (
          item?.unitNo?.toLowerCase()?.includes(searchUnit?.toLowerCase()) &&
          (item?.tenancy?.tenant?.firstName?.toLowerCase()?.includes(searchTenant?.toLowerCase()) ||
            item?.tenancy?.tenant?.lastName?.toLowerCase()?.includes(searchTenant?.toLowerCase()))
        )

      // dev & tenant
      if (
        searchTenant?.trim()?.length != 0 &&
        searchDevelopment?.trim()?.length != 0 &&
        searchUnit?.trim()?.length == 0
      )
        return (
          (item?.building?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase()) ||
            item?.community?.[0]?.name
              ?.toLowerCase()
              ?.includes(searchDevelopment?.toLowerCase())) &&
          (item?.tenancy?.tenant?.firstName?.toLowerCase()?.includes(searchTenant?.toLowerCase()) ||
            item?.tenancy?.tenant?.lastName?.toLowerCase()?.includes(searchTenant?.toLowerCase()))
        )
      // only unit
      else if (
        searchTenant?.trim()?.length == 0 &&
        searchDevelopment?.trim()?.length == 0 &&
        searchUnit?.trim()?.length != 0
      )
        return item?.unitNo?.toLowerCase()?.includes(searchUnit?.toLowerCase())
      // only development
      else if (
        searchTenant?.trim()?.length == 0 &&
        searchDevelopment?.trim()?.length != 0 &&
        searchUnit?.trim()?.length == 0
      )
        return (
          item?.building?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase()) ||
          item?.community?.[0]?.name?.toLowerCase()?.includes(searchDevelopment?.toLowerCase())
        )
      //  Only Tenant
      else if (
        searchTenant?.trim()?.length != 0 &&
        searchDevelopment?.trim()?.length == 0 &&
        searchUnit?.trim()?.length == 0
      )
        return (
          item?.tenancy?.tenant?.firstName?.toLowerCase()?.includes(searchTenant?.toLowerCase()) ||
          item?.tenancy?.tenant?.lastName?.toLowerCase()?.includes(searchTenant?.toLowerCase())
        )
    })

    if (
      searchTenant?.trim()?.length == 0 &&
      searchDevelopment?.trim()?.length == 0 &&
      searchUnit?.trim()?.length == 0
    )
      setProperties(defaultProperties)
    else setProperties(filterProperties)
  }, [searchTenant, searchDevelopment, searchUnit])

  let propertiesSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }
  const selectContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }
  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }
  const selectBaseStyle = {
    flex: 1,
    border: 0,
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const handlePropertySelection = (data: any, isRefresh: any) => {
    console.log(data)
    // debugger;
    let values: any = []
    let i: any = 0
    data?.map((dt: any, ind: any) => {
      if (dt?.check) {
        values[i] = {
          building: dt?.building,
          unitNo: dt?.unitNo,
          tenancy: dt?.tenancy,
          check: false,
          occupy: dt?.occupy,
          community: dt?.community,
          buildingName: dt?.building?.length !== 0 ? dt?.building[0].name : undefined,
          assignedProperties: {
            developmentId:
              dt?.community?.length !== 0
                ? dt?.community?.[0]?._id
                : dt?.building?.length !== 0
                ? dt?.building?.[0]?._id
                : undefined,
            tenancy: dt?.tenancy?.length !== 0 ? dt?.tenancy?._id : undefined,
            tenant: dt?.tenancy?.length !== 0 ? dt?.tenancy?.tenant?._id : undefined,
            unitIds: dt?._id,
            hierarchy: [
              {
                unitId: dt?._id,
                buildingId: dt?.building?.length !== 0 ? dt?.building[0]._id : undefined,
                communityId: dt?.community?._id ? dt?.community?._id : undefined,
              },
            ],
          },
        }
        i++
      }
    })

    setProperties(values)
    setDefaultProperties(values)

    let recipientLists: any = {
      developmentIds: [],
      tenanciesIds: [],
      tenantIds: [],
      unitIds: [],
      hierarchy: [],
    }

    properties?.map((property: any) => {
      if (property?.assignedProperties?.developmentId != undefined)
        recipientLists.developmentIds[recipientLists.developmentIds?.length] =
          property?.assignedProperties?.developmentId

      if (property?.assignedProperties?.tenancy != undefined)
        recipientLists.tenanciesIds[recipientLists.tenanciesIds?.length] =
          property?.assignedProperties?.tenancy

      if (property?.assignedProperties?.tenant != undefined)
        recipientLists.tenantIds[recipientLists.tenantIds?.length] =
          property?.assignedProperties?.tenant

      if (property?.assignedProperties?.unitIds != undefined)
        recipientLists.unitIds[recipientLists.unitIds?.length] =
          property?.assignedProperties?.unitIds
    })

    const newHierarchy = properties.flatMap(
      (property: any) => property?.assignedProperties?.hierarchy
    )

    setAddedProperties([{...recipientLists, hierarchy: newHierarchy}])

    if (flg == '2') autoSave()
  }

  //
  const updatePropertyList = async () => {
    const body = {
      developmentIds: [],
      tenanciesIds: [],
      tenantIds: [],
      unitIds: [],
      hierarchy: [],
      unitTypes:[0,1,3,4,5,6,7,8,9]
    }
    await ApiPost(`corporate/announcement/get/development`, body)
      .then((res) => {
        let values = [...res?.data?.data?.development_data]
        res?.data?.data?.development_data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        for (let j = 0; j < res?.data?.data?.development_data?.length; j++) {
          let isCheckForAll = false

          let isCheckForTenantDevUnit = false

          if (
            values[j]?._id != undefined &&
            values[j]?.tenancy?.tenant?._id != undefined &&
            (values[j]?.community?.[0]?._id != undefined ||
              values[j]?.building?.[0]?._id != undefined)
          )
            isCheckForAll = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.tenancy?.tenant?._id == undefined &&
            (values[j]?.community?.[0]?._id != undefined ||
              values[j]?.building?.[0]?._id != undefined)
          )
            isCheckForTenantDevUnit = true

          //
          if (isCheckForAll) {
            let x = addedProperties?.unitIds?.includes(values[j]?._id)

            let m = addedProperties?.developmentIds.includes(values[j]?.building?.[0]?._id)
            let n = addedProperties?.developmentIds.includes(values[j]?.community?.[0]?._id)

            let z = addedProperties?.tenantIds.includes(values[j]?.tenancy?.tenant?._id)

            if (x && (m || n) && z) values[j].check = true
          } else if (isCheckForTenantDevUnit) {
            let x = addedProperties?.unitIds?.includes(values[j]?._id)

            let m = addedProperties?.developmentIds.includes(values[j]?.building?.[0]?._id)
            let n = addedProperties?.developmentIds.includes(values[j]?.community?.[0]?._id)
            if (x && (m || n)) values[j].check = true
          }
        }

        handlePropertySelection(values, false)
      })
      .catch((e) => {
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    if (added) updatePropertyList()
  }, [added])

  return (
    <div>
      {/* table */}
      <div className='card card-flush  py-5  px-7 myCard card-flush-dark'>
        <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
          <div className='d-flex flex-column myCardHeaderBorder mb-4 py-4'>
            <div className='d-flex align-items-center'>
              <div className='col-md-8'>
                <h4 className='m-0  d-flex gap-4 light-dark-theme-color'>
                  Total Recipients <h3 className='fw-bold white-dark-theme-color'>{properties?.length}</h3>
                </h4>
              </div>
              <div className='col-md-4 '>
                {properties?.map((user: any, ind: any) => {
                  if (user?.check == true) propertiesSelected = propertiesSelected + 1
                  return <></>
                })}

                {editMode && (
                  <div className=''>
                    <div className='d-flex justify-content-end gap-4'>
                      {propertiesSelected > 0 && (
                        <>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold red-hollow-btn red-filled-btn m-0 ps-2 status-w-100'
                            onClick={() => {
                              let values = [...properties]

                              // Filter out the Properties with checkboxes checked
                              const filteredProperties = values.filter((user) => !user.check)
                              setProperties(filteredProperties)
                              if (flg == '2') autoSave()
                              setDefaultProperties(filteredProperties)
                            }}
                          >
                            <img
                              src={trashWhite}
                              height={18}
                              width={18}
                              className='me-5'
                            />{' '}
                            Remove
                          </button>
                        </>
                      )}
                      <button
                        type='button'
                        className='btn btn-sm fw-bold select-btn ps-2 status-w-100'
                        onClick={() => setSelectModal(true)}
                      >
                        <img src={addWhite} height={18} width={18} className='me-5' /> Add
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className='col-md-8 '>
                {/* filters */}
                {editMode && (
                  <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
                    <div className='mb-2 col-md-1 pe-5 xyz ps-0'>
                      <label className='head-text' style={{fontWeight: '600'}}>
                        {' '}
                        Search{' '}
                      </label>
                    </div>

                    <div className='mb-2 col-md-3 ps-5 pe-5 xyz'>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Tenant'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'Announcement'}}
                          value={searchTenant}
                          onChange={(e: any) => setSearchTenant(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='Announcement'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>

                    <div className='mb-2 col-md-3 ps-5 pe-5 xyz'>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Development'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={searchDevelopment}
                          onChange={(e: any) => setSearchDevelopment(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>

                    <div className='mb-2 col-md-3 ps-5 pe-5 xyz'>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Unit'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={searchUnit}
                          onChange={(e: any) => setSearchUnit(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-4'></div>
            </div>
          </div>
          <div className='col-md-12 ' style={{maxHeight: '500px', overflow: 'scroll'}}>
            <div className='table-responsive recipient'>
              <table
                className='table align-middle table-row-dashed fs-8 gy-5 '
                id='kt_ecommerce_sales_table'
              >
                <thead className='fs-7'>
                  {editMode && (
                    <th className='text-start test' style={{width: '15px'}}>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            propertiesSelected == properties?.length && properties?.length != 0
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            let values: any = [...properties]
                            if (e.target.checked) {
                              values?.map((user: any) => {
                                user.check = true
                              })
                            } else {
                              values?.map((user: any) => {
                                user.check = false
                              })
                            }
                            setProperties(values)
                          }}
                        />
                      </div>
                    </th>
                  )}

                  <th className='text-start min-w-150px'>Tenant Name</th>
                  <th className='text-start min-w-150px'>Development</th>
                  <th className='text-start min-w-150px'>Unit</th>
                  <th className='text-start min-w-150px '>Occupancy Status</th>
                  {editMode && <th className='text-start min-w-25px'></th>}
                </thead>
                <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                  {properties?.length > 0 &&
                    properties?.map((prop: any, ind: any) => {
                      return (
                        <>
                          <tr>
                            {editMode && (
                              <td className='text-start ps-0 test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={properties[ind]?.check}
                                    onChange={(e: any) => {
                                      const values = [...properties]
                                      values[ind].check = e.target.checked
                                      setProperties(values)
                                    }}
                                  />
                                </div>
                              </td>
                            )}

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0 cursor-pointer'
                            >
                              {ind + 1}.{' '}
                              {prop?.tenancy
                                ? `  ${prop?.tenancy?.tenant?.firstName} ${prop?.tenancy?.tenant?.lastName}`
                                : `  -`}
                            </td>
                            <td className='text-start ps-0 cursor-pointer'>
                              {prop?.community.length !== 0
                                ? `${prop?.community[0]?.name}`
                                : prop?.building.length !== 0
                                ? `${prop?.building[0]?.name}`
                                : `-`}
                            </td>
                            <td className='text-start ps-0 cursor-pointer'>
                              {prop?.unitNo ? `${prop?.unitNo}` : `-`}
                            </td>
                            <td className='text-start min-w-100px'>
                              {prop?.occupy == 1 ? (
                                <span className='text-success '>
                                  <b>Occupied</b>
                                </span>
                              ) : prop?.occupy == 0 ? (
                                <span className='text-danger '>
                                  <b>Vacant</b>
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>
                            {editMode && (
                              <td className='text-start ps-0 cursor-pointer'>
                                <button
                                  type='button'
                                  className='btn'
                                  style={{border: 'none', background: 'transparent'}}
                                  onClick={(e: any) => {
                                    let values = [...properties]

                                    values.splice(ind, 1)

                                    setProperties(values)
                                    if (flg == '2') autoSave()
                                    setDefaultProperties(values)
                                  }}
                                >
                                  <img src={xMark} height={16} width={16} />
                                </button>
                              </td>
                            )}
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {selectModal && (
        <SelectRecipientProperty
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={(data: any) => {
            handlePropertySelection(data, true)
          }}
          addedProperties={properties}
        />
      )}
    </div>
  )
}

export default Recipient

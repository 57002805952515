import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import {Col, Row} from 'react-bootstrap'
import TenanciesFilter from './TenanciesFilter'
import 'antd/dist/antd.css'
import appartment from '../../../img/Apartment.svg'
import penthouse from '../../../img/PentHouse.svg'
import common_area from '../../../img/common_area.svg'
import other from '../../../img/other.svg'
import townhouse from '../../../img/TownHouse.svg'
import villa from '../../../img/Villa.svg'
import zoom from '../../../img/zoom.svg'
import {getEmptyImage} from 'react-dnd-html5-backend'
import backArrow from '../../../img/back-arrow.png'
import {useDispatch, useSelector} from 'react-redux'

const PropertyDetails = () => {
  const navigate = useNavigate()
  const {state} = useLocation()

  let propertyId: any = window.location.pathname?.split('/')[2]

  const [tenancy, setTenancy] = useState<any>(null)

  const [unitDetails, setUnitDetails] = useState<any>()
 
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  //
  const getTenancyByID = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}`)
      .then((res) => {
        setTenancy(res?.data?.data[0])
    
      })
      .catch((err) => console.log('err', err))
  }

  // get unit details
  const getUnitDetails = () => {
    ApiGet(`corporate/unit/${propertyId}`)
      .then((res) => {
        setUnitDetails(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    if(state?.tenancyId != undefined)
    getTenancyByID(state?.tenancyId)
  
    setTimeout(() => {
      getUnitDetails()
    }, 1500)
    
    console.log(state?.details)
  
  }, [])
  

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2' style={{padding: '0px 14px'}}>
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if(tenancy !== null)
                  navigate('/tenancies')
                else{
                  if(comefrom == 'properties')
                  navigate('/dashboard')
                  else if(comefrom == 'build')
                  navigate('/building-dashboard')
                  else 
                  navigate('/community-dashboard')
                }
                }
              }
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            {tenancy !== null  ? (
              <h1 className='page-heading m-0 head-text'>
                Property Profile - {tenancy?.contractNo}
              </h1>
            ) : (
              <h1 className='page-heading m-0 head-text'>Property Profile</h1>
            )}
          </div>

          {/* Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mx-5 ps-5'
            style={{marginBottom: '35px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 me-5 active px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${propertyId}`, {
                      state: {
                        details: state,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Overview
                </a>
              </li>
              { state?.details?.unitType != 'common_area' && (
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${propertyId}/tenancy`, {
                      state: {
                        details: state,
                        id: propertyId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenancy
                </a>
              </li>
              )}

            { state?.details?.unitType != 'common_area' && (
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${propertyId}/tenant`, {
                      state: {
                        details: state,
                        id: propertyId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenants
                </a>
              </li>
            )}

              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${propertyId}/financials/income`, {
                      state: {
                        details: state,
                        id: propertyId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Financials
                </a>
              </li>
              { state?.details?.unitType != 'common_area' && (
              <li className='nav-item'>
                <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'
                 onClick={() =>
                  navigate(`/overViewData/${propertyId}/annoucements`, {
                    state: {
                      details: state,
                      id: propertyId,
                      tenancyId: state?.tenancyId
                    },
                  })
                }
                >
                  Annoucements
                </a>
              </li>
              )}
              <li className='nav-item'>
                <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'
                onClick={() =>
                  navigate(`/overViewData/${propertyId}/requests`, {
                    state: {
                      details: state,
                      id: propertyId,
                      tenancyId: state?.tenanacyId
                    },
                  })
                }>
                  Requests
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/*  */}
        <div className='p-0 ' style={{backgroundColor: 'none'}}>
          <Row>
            <Col md={9} lg={10}>
              <div className='card card-flush px-3 py-2 h-100'>
                <Row className='m-0 mb-1'>
                  <Col md={6} className='px-3 py-4'>
                    <div className='d-flex align-items-center'>
                      <h2 className='m-0 head-text'>Property Details</h2>
                    </div>
                  </Col>
                  <Col md={6} className='text-end px-3 py-4'>
                    <div className='d-flex '>
                      <span className='svg-icon svg-icon-2 ms-auto' style={{color: '#ffad0f'}}>
                        <svg
                          width='32'
                          height='32'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                            fill='currentColor'
                          ></path>
                        </svg>
                      </span>
                      <h2 className='mx-1 head-text' style={{color: 'black'}}>
                        {unitDetails?.rating}
                      </h2>
                    </div>
                  </Col>
                </Row>

                <Row className='mx-2'>
                  <Col md={3} className='mb-3'>
                    <h5 className='' style={{color: '#a1a5b7'}}>
                      Unit No.
                      <span className='ms-5 ps-1 head-text'>
                        <b>{unitDetails?.unitNo ? unitDetails?.unitNo : '-'}</b>
                      </span>
                    </h5>
                  </Col>
                  <Col md={3} className='mb-3'>
                    <h5 className='' style={{color: '#a1a5b7'}}>
                      Type
                      <span className='ms-5 ps-1 head-text'>
                        <b>
                          {unitDetails?.unitType === 'town_house'
                            ? 'TownHouse'
                            : unitDetails?.unitType === 'other'
                            ? 'Other'
                            : unitDetails?.unitType === 'common_area'
                            ? 'Common Area'
                            : unitDetails?.unitType === 'villa'
                            ? 'Villa'
                            : unitDetails?.unitType === 'apartment'
                            ? 'Apartment'
                            : unitDetails?.unitType === 'penthouse'
                            ? 'Penthouse'
                            : ''}
                        </b>
                      </span>
                    </h5>
                  </Col>
                  <Col md={3} className='mb-3'>
                    <h5 className='' style={{color: '#a1a5b7'}}>
                      Bedrooms
                      <span className='ms-5 ps-1 head-text'>
                        <b>{unitDetails?.bedrooms ? unitDetails?.bedrooms : '-'}</b>
                      </span>
                    </h5>
                  </Col>
                  <Col md={3} className='mb-3'>
                    <h5 className='' style={{color: '#a1a5b7'}}>
                      Occupancy
                      <span className='ms-5 ps-1 head-text'>
                        <b>{unitDetails?.occupy === 1 ? 'Vacant' : 'Occupied'}</b>
                      </span>
                    </h5>
                  </Col>

                  <Col md={3} className='mb-3'>
                    <h5 className='' style={{color: '#a1a5b7'}}>
                      {/* Development Name */}
                      <span className='head-text'>
                        <b>
                          {unitDetails?.communityId
                            ? unitDetails?.community?.[0]?.name
                            : unitDetails?.building?.[0]?.name}
                        </b>
                      </span>
                    </h5>
                  </Col>

                  {unitDetails?.clusterId && (
                    <Col md={3} className='mb-3'>
                      <h5 className='' style={{color: '#a1a5b7'}}>
                        {/* Cluster Name */}
                        <span className='head-text'>
                          <b>{unitDetails?.clusterId && unitDetails?.cluster?.[0]?.name}</b>
                        </span>
                      </h5>
                    </Col>
                  )}

                  {unitDetails?.communityId && unitDetails?.buildingId && (
                    <Col md={3} className='mb-3'>
                      <h5 className='' style={{color: '#a1a5b7'}}>
                        {/* Building Name */}
                        <span className='head-text'>
                          <b>{unitDetails?.buildingId && unitDetails?.building?.[0]?.name}</b>
                        </span>
                      </h5>
                    </Col>
                  )}

                  {unitDetails?.floorId && (
                    <Col md={3} className='mb-3'>
                      <h5 className='' style={{color: '#a1a5b7'}}>
                        {/* Floor Name */}
                        <span className='head-text'>
                          <b>{unitDetails?.floorId && unitDetails?.floor?.[0]?.name}</b>
                        </span>
                      </h5>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={3} lg={2}>
              <div className='card card-flush px-3 py-2 h-100'>
                <Row className='mx-1'>
                  <Col md={12} className='px-3 py-4'>
                    <h4 className='' style={{color: '#a1a5b7'}}>
                      ID
                      <span className='ms-5 ps-1 head-text'>
                        <b>{unitDetails?.id ? unitDetails?.id : '-'}</b>
                      </span>
                    </h4>
                  </Col>
                </Row>

                <img
                  src={
                    unitDetails?.unitType === 'town_house'
                      ? townhouse
                      : unitDetails?.unitType === 'other'
                      ? other
                      : unitDetails?.unitType === 'common_area'
                      ? common_area
                      : unitDetails?.unitType === 'villa'
                      ? villa
                      : unitDetails?.unitType === 'apartment'
                      ? appartment
                      : unitDetails?.unitType === 'penthouse'
                      ? penthouse
                      : ''
                  }
                  height={80}
                  className='mb-2'
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default PropertyDetails

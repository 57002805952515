import React from 'react'
import {
  UseFormRegister,
  FieldValues,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import {useState} from 'react'

interface InputProps {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  incomeData: {name: string}
  setIncomeData: (data: {name: string}) => void
  setValue: UseFormSetValue<FieldValues>
  trigger: UseFormTrigger<FieldValues> // trigger validation manually
  setAutoSaveData: (time: number) => void
  flg: any,
  isAlreadySaveData?:any,
  setIsAlreadySaveData?: any
}

const Input: React.FC<InputProps> = ({
  register,
  errors,
  incomeData,
  setIncomeData,
  setValue,
  trigger,
  setAutoSaveData,
  isAlreadySaveData,
  setIsAlreadySaveData = () => {},
  flg,
}) => {
  const [patternMismatched, setPatternMismatched] = useState(false)
  const [limitError, setLimitError] = useState(false)

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAlreadySaveData(Date?.now())
    let newValue = e.target.value

    let pattern: any = false

    // Remove newline characters
    if (newValue.includes('\n')) {
      newValue = newValue.replace(/\n/g, '')
      setPatternMismatched(true)
      pattern = true
    } else {
      setPatternMismatched(!/^[A-Za-z0-9._ \-]*$/.test(newValue))
      pattern = false
    }

    // Check length constraints
    setLimitError(newValue.length > 50)

    // Update incomeData and trigger validation if input is valid
    if (!pattern && newValue.length <= 50) {
      let values = {...incomeData}
      values.name =
        newValue !== '' ? newValue.charAt(0).toUpperCase() + newValue.slice(1) : newValue

      setIncomeData(values)
      setValue('paymentName', newValue, {shouldValidate: true})
    }

    // Manually trigger validation after the value change
    await trigger('paymentName')
  }

  return (
    <div className='row me-4 ms-0 mt-4'>
      <div className='col-12 px-5 d-flex'>
        <input
          {...register('paymentName', {
            required: 'This field is required',
            maxLength: {
              value: 50,
              message: 'Maximum 50 characters allowed',
            },
            pattern: {
              value: /^[A-Za-z0-9._ \-]*$/, // Updated regex to allow spaces and no newlines
              message:
                'Only letters, numbers, periods, underscores, hyphens, and spaces are allowed',
            },
          })}
          type='text'
          className='form-control form-control-solid mytest tenancy-control bg-white'
          name='paymentName'
          value={incomeData?.name}
          style={{height: '35px', width: '300px'}}
          placeholder='Enter Payment Name'
          onChange={handleInputChange}
          onBlur={() => {
            if (flg === '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          autoComplete='off'
        />
        <h3 className='text-danger mb-0 ms-2'>
          <b>*</b>
        </h3>
      </div>

      {/* react-hook-form error messages */}
      {errors.paymentName && typeof errors.paymentName.message === 'string' && (
        <div className='col-12 px-5 d-flex'>
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
            {errors.paymentName.message}
          </p>
        </div>
      )}

      {/* Custom Error Handling */}
      {/* {patternMismatched && !errors.paymentName && (
        <div className='col-12 px-5 d-flex'>
          <p style={{ color: 'var(--kt-danger)', gridColumn: '2/2' }} className='m-0 mt-1'>
            {'Only letters, numbers, periods, underscores, hyphens, and spaces are allowed'}
          </p>
        </div>
      )} */}

      {limitError && !errors.paymentName && (
        <div className='col-12 px-5 d-flex'>
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
            {'Maximum 50 characters allowed'}
          </p>
        </div>
      )}
    </div>
  )
}

export default Input

import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete, Bucket} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import downloadImg from '../../../../../../../img/download-img.png'
import share from '../../../../../../../img/email.png'
import print from '../../../../../../../img/printer.png'
import editPen from '../../../../../../../img/edit-pen.png'
import {useReactToPrint} from 'react-to-print'
import moment from 'moment'
import { downloadFile, formatAmount } from '../../../../../../../Utilities/utils'

const ViewDebitNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'DEBIT NOTE',
    dueDate:null,
    debitNoteNo: '',
    note: '',
    payorData: {},
    payment_sub_item: [],
    // items: [],
    debitNoteCompany: {},
    debitNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
    frontendStorage: {
      noteShowHide: false,
    },
  })


  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  const getDebitNoteById = async () => {
    let url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then((res: any) => {
        setValue('documentName', res?.data?.data?.debitNoteTemplateHeader)
        setValue('documentReceiptNo', res?.data?.data?.debitNoteNo)
        setValue('dueDate', res?.data?.data?.dueDate);

        let paymentItemsTemp: any = []
        res?.data?.data?.paymentSubItems?.forEach((pyItem: any, pyInd: number) => {
          paymentItemsTemp[pyInd] = {
            ...pyItem,
            outstandingAmount: pyItem?.totalAmount,
            amountPaid: pyItem?.amountPaid,
            addedDebit: {
              debitRateAmount: pyItem?.amount,
              quantity: pyItem?.quantity,
              debitAmount: pyItem?.amount,
              debitVATAmount: pyItem?.VATPercentage,
              totalDebitAmount: pyItem?.totalAmount,
            },
          }
        })

        const values: any = {
          name: res?.data?.data?.debitNoteTemplateHeader,
          debitNoteNo: res?.data?.data?.debitNoteNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          dueDate: res?.data?.data?.dueDate,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          debitNoteCompany: res?.data?.data?.debitNoteCompany,
          debitNoteLogo: res?.data?.data?.debitNoteLogo,
          payment_sub_item: paymentItemsTemp,
          itemDescriptions: res?.data?.data?.itemDescriptions ? res?.data?.data?.itemDescriptions : [],
          formStatusDateTime: res?.data?.data?.createdAt,
          formStatus: res?.data?.data?.formStatus,
          showNotes: res?.data?.data?.frontendStorage?.noteShowHide,
          createdAt: res?.data?.data?.createdAt,
          pdfURL:res?.data?.data?.pdfURL,
          totalAmount: res?.data?.data?.totalAmount
        }


        setCreditNoteData(values)
        console.log(res?.data?.data?.invoiceRecords)
        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          const {tenant,...rest} = inv?.payment_invoice
          invValues?.push(rest)
          console.log(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            (tenant !== undefined && tenant !== null)
              ? [tenant]
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          invValues[index].paymentId = inv?.paymentId
          invValues[index].building = inv?.payment_invoice?.building !== undefined && inv?.payment_invoice?.building !== null  ? [inv?.payment_invoice?.building] : []
          invValues[index].floor = inv?.payment_invoice?.floor !== undefined && inv?.payment_invoice?.floor !== null ? [inv?.payment_invoice?.floor] : []
          invValues[index].community = inv?.payment_invoice?.community !== undefined && inv?.payment_invoice?.community !== null ? [inv?.payment_invoice?.community] :[]
          invValues[index].unit = inv?.payment_invoice?.unit !== undefined && inv?.payment_invoice?.unit !== null ? [inv?.payment_invoice?.unit] : []
          invValues[index].tenant = inv?.payment_invoice?.tenant !== undefined && inv?.payment_invoice?.tenant !== null ? [inv?.payment_invoice?.tenant] : []
          invValues[index].tenancy = inv?.payment_invoice?.tenancy !== undefined && inv?.payment_invoice?.tenancy !== null ? [inv?.payment_invoice?.tenancy] :[]
        })


        setSelectedInvoices(invValues)
        setTimeout(() => {
            setIsSkeleton(false)
          }, 100)
      })
      .catch((err: any) => {
        console.log('err', err)
        setIsSkeleton(false)
      })
  }

  //
  useEffect(() => {
    setIsSkeleton(true)
    getDebitNoteById()
  }, [])


  // delete credit note
  const deleteCreditNote = () => {
    ApiDelete(`corporate/payment_debit_note/${id}`)
      .then((res) => {
        SuccessToast('Payment Debit note has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const creditNoteRef = useRef(null)
  const handlePrint = useReactToPrint({
    onAfterPrint: () => {
      setPrint(false)
    },
    pageStyle: `
      @page {
        size: A4;
        margin: 0;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
        html, body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
        }
         .invoice-content {
          padding: 1mm 10mm !important;
        }

      }
    `,
    content: () => creditNoteRef.current,
  })
  const [isPrint, setPrint] = useState<boolean>(false)
  const onPrintButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setPrint(true)
      setTimeout(() => {
        handlePrint()
      }, 300)
    },
    [handlePrint]
  )

  const checkIsDeletable = async () => {
    try {
      const res = await ApiGet(`corporate/payment_debit_note/deletion_checker/${id}`)
      const {paymentReceiptData = [] } = res?.data?.data?.validation
      if (
        res?.data?.data?.isAbleToDelete &&
        paymentReceiptData?.length === 0
      ) {
        Swal.fire({
          html: ` 
                <div class='fs-3'> <b>Delete Debit Note</b></div>
                 <div class='fs-4 fw-bold mt-4'>You are about to delete this debit note.</div>
                <div class='fs-4 fw-bold mt-5'> Deleting this debit note will reduce the tenant’s outstanding balance.</div>
                 <div class='fs-4 fw-bold mt-5'>Are you sure you want to continue?</div>
                `,

          // icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#d54645',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b0b7c2',
          customClass: {
            // confirmButton: 'custom-confirm-button',
            // cancelButton: 'custom-cancel-button',
            popup: 'custom-popup test',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        }).then((res: any) => {
          if (res.isConfirmed) {
            deleteCreditNote()
          }
        })
      } else {
        let noteLine = 'It seems that this debit note has a paid amount recorded to it and cannot be deleted. To correct this, you will need to issue a refund to return the amount that was collected for this debit note.'

        Swal.fire({
          html: ` 
                  <div class='fs-3'> <b>Unable To Delete Debit Note!</b></div>
                  <div class='fs-4 fw-bold mt-5'>${noteLine}</div>
                  `,

          // icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#d54645',
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b0b7c2',
          customClass: {
            confirmButton: 'custom-confirm-button',
            // cancelButton: 'custom-cancel-button',
            popup: 'custom-popup test',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        }).then((res: any) => {
          if (res.isConfirmed) {

          }
        })
      }
    } catch (error: any) {
      ErrorToast(error.message)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  return (
    <>
      {!isSkeleton ? 
      (<div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          // onSubmit={handleSubmit((data: any, e: any) => {
          //   addCreditNote(1)
          // })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap mb-4'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/finance-income')
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Debit Note`}</h2>
                </div>
              </div>
            </div>

            {/*  Details Card */}
            <div className='card card-flush py-7 px-5'>
              <div className='d-flex my-2 align-items-start'>
                <div className=''>
                  <h4 className='text-dark'>
                    <label className='labl-gry status-w-150'> Debit Note No.</label>

                    <span className='ms-1'>
                      <b>{creditNoteData?.debitNoteNo ? creditNoteData?.debitNoteNo : '-'}</b>
                    </span>
                  </h4>

                  <h4 className='text-dark pt-4'>
                    <label className='labl-gry status-w-150'> Debit Amount</label>

                    <span className='ms-1'>
                    <b>{`AED ${formatAmount(creditNoteData?.totalAmount)}`}</b>
                    </span>
                  </h4>
                </div>
                <div className='me-2 ms-auto'>
                  <div
                    className={
                      creditNoteData?.formStatus == 0
                        ? 'status draft-st ms-5 status-w-120'
                        : 'status create-st ms-5 status-w-120'
                    }
                  >
                    {creditNoteData?.formStatus == 0 ? 'Draft' : 'Created'}
                  </div>
                  <i className="text-dark white-dark-theme-color">
                    {' '}
                    {creditNoteData?.formStatusDateTime
                      ? `${moment
                          .utc(creditNoteData?.formStatusDateTime)
                          .local()
                          .format('DD.MM.YYYY - hh:mm A')}`
                      : ''}
                  </i>
                </div>
              </div>


              {/* Payor */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Payor</label>

                  <span className='ms-1'>
                    <b>{creditNoteData?.payorData?.firstName && creditNoteData?.payorData?.lastName ? `${creditNoteData?.payorData?.firstName} ${creditNoteData?.payorData?.lastName}` :'-'}</b>
                  </span>
                </h4>
              </div>

              {/* Account ID */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Account ID</label>

                  <span className='ms-1'>
                    <b>{'101'}</b>
                  </span>
                </h4>
              </div>

              {/* Tenant ID */}
              <div className='d-flex my-2 align-items-center'>
                <h4 className='text-dark'>
                  <label className='labl-gry status-w-150'> Tenant ID</label>

                  <span className='ms-1'>
                    <b>{creditNoteData?.payorData?.id ? creditNoteData?.payorData?.id : '-'}</b>
                  </span>
                </h4>
              </div>
            </div>

            {/* CTAs */}
            <div className='d-flex align-items-center px-2 mt-13'>
              {/* Share */}
              <button
                className='btn btn-sm fw-bold ps-3 pe-4 text-white status-w-110 blue-btn'
                onClick={() => {
                  navigate(`/finance-income/share/view-debit-note/${id}`)
                }}
              >
                <img
                  src={share}
                  height={18}
                  width={18}
                 className='me-4'
                />{' '}
                {'  '}
                Share
              </button>

              <button
                  className='btn btn-sm fw-bold px-4 ms-3 text-white status-w-110 print-btn'
                  onClick={onPrintButtonClick} >
                 



                  

                  <img
                    src={print}
                    height={18}
                    width={18}
                    style={{
                      marginRight: '12px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  {'  '}
                  Print
                </button>


              {/* Delete */}
              <button
                type='button'
                className='btn btn-sm fw-bold  ms-auto red-hollow-btn ps-3 '
                disabled={isLoading || isViewOnly}
                onClick={checkIsDeletable}
              >
                <img src={trashImg} height={18} width={18} className='me-4' /> Delete
              </button>

              {/* Download */}
              <button
                type='button'
                className='btn btn-sm fw-bold blue-hollow-btn ms-3 btn-fit-content ps-2'
                style={{width: 'fit-content'}}
                onClick={() => {
                  downloadFile(
                    `${Bucket}${creditNoteData?.pdfURL}`,
                    `${creditNoteData?.debitNoteNo}.${creditNoteData?.pdfURL?.split('.').pop()}`
                  )
                }}
              >
                <img src={downloadImg} height={18} width={18} className='me-3' />
                Download
              </button>

              {/* Edit */}
              <img src={editPen} height={18} width={18} className='ms-4 cursor-pointer' onClick={() => {
                navigate(`/finance-income/edit-debit-note/3/${id}`)
              }} />
            </div>

            {/* Credit Note Details Card */}
            <NoteDetailsCard
              isPrint={isPrint}
              innerRef={creditNoteRef}
              register={register}
              errors={errors}
              noteCardData={creditNoteData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateNoteData={(data: any) => {
                setCreditNoteData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              updateNote={(data: any) => {
                setCreditNoteData(data)
              }}
              slctdInvoices={selectedInvoices}
              editMode={false}
              from={'debit note'}
            />
          </div>
        </form>
      </div>) : ( <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>)}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ViewDebitNote

import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import moment from 'moment'
import {formatAmount, getFileIcon} from '../../../../../../Utilities/utils'
import {Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'

import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
  show: any
  handleClose: any
  receiptData: any
}

const ReceiptsPopup = ({show, handleClose, receiptData}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  let totalInvoiceAmount: any = 0
  let totalPaidAmount: any = 0

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 modal-body-without-header'
          style={{
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className='mb-0 pt-1 white-dark-theme-color'>
              <b>Receipts</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          {receiptData?.payment_receipt?.map((rcp: any, index: any) => {
            totalPaidAmount = Number(totalPaidAmount) + Number(rcp?.amountPaid)
          })}

          <Row className='mt-4 '>
            <Col sm={12} md={6} className='right-separator'>
              <div className='d-flex align-items-center'>
                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5 light-dark-theme-color'>Total</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700  text-start fs-5 white-dark-theme-color'>
                    {formatAmount(receiptData?.payment_receipt?.length) || 0}
                  </p>
                </div>

                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5 light-dark-theme-color'>Total Paid Amount</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5 white-dark-theme-color'>
                    {totalPaidAmount != undefined ? (
                      <>AED {formatAmount(totalPaidAmount)}</>
                    ) : (
                      <>AED 0</>
                    )}
                  </p>
                </div>

                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5 light-dark-theme-color'>Last Paid</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5 white-dark-theme-color'>
                    {receiptData?.lastPaidDate
                      ? moment(receiptData?.lastPaidDate).format('DD.MM.YYYY')
                      : '-'}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className='d-flex align-items-center justify-content-end'>
                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5 light-dark-theme-color'>Invoice</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5 white-dark-theme-color'>
                    {receiptData?.invoiceNo || '-'}
                  </p>
                </div>

                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500 text-start mb-0 fs-5 light-dark-theme-color'>Amount</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700 text-start fs-5 white-dark-theme-color'>
                    {receiptData?.totalAmount != undefined ? (
                      <>AED {formatAmount(receiptData?.totalAmount)}</>
                    ) : (
                      <>AED 0</>
                    )}
                  </p>
                </div>

                <div className='me-5'>
                  <p className='mb-1 ms-3 pt-2 fnt-500  text-start mb-0 fs-5 light-dark-theme-color'>Due Date</p>
                  <p className='mb-1 ms-3 pt-2 fnt-700  text-start fs-5 white-dark-theme-color'>
                    {receiptData?.dueDate ? moment(receiptData?.dueDate).format('DD.MM.YYYY') : '-'}
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <>
              <div className='separator my-3 mt-5'></div>
              <div className='row mt-3'>
                <div className='col-12 pe-0'>
                  <div className=''>
                    <div
                      className='table-responsive pb-3'
                      style={{maxHeight: '380px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                            <th className='text-start min-w-125px'>Receipt</th>
                            <th className='text-start min-w-100px ps-0 pe-2'></th>
                            <th className='text-start min-w-100px ps-0'>Paid Date</th>
                            <th className='text-start min-w-100px'>Paid Amount</th>
                          </tr>
                        </thead>

                        <tbody className='fw-semibold white-dark-theme-color'>
                          {receiptData?.payment_receipt?.length > 0 ? (
                            <>
                              {receiptData?.payment_receipt?.map((rcp: any, index: any) => {
                                return (
                                  <tr>
                                    <td className='text-decoration-underline'>
                                      <b>{rcp?.receiptNo ? rcp?.receiptNo : '-'}</b>
                                    </td>
                                    <td className='ps-0 pe-2'>
                                      {rcp?.formStatus == 1 && (
                                        <img
                                          src={getFileIcon(rcp?.pdfURL)}
                                          height={30}
                                          width={30}
                                          className='me-2 cursor-pointer'
                                          data-src={`${Bucket}${rcp?.pdfURL}`}
                                          data-fancybox
                                          id={`clickedInvoice${index}`}
                                        />
                                      )}
                                    </td>
                                    <td className='ps-0'>
                                      {rcp?.datePaid
                                        ? moment(rcp?.datePaid).format('DD.MM.YYYY')
                                        : '-'}
                                    </td>

                                    <td>
                                      {rcp?.amountPaid > 0 ? (
                                        <>AED {formatAmount(rcp?.amountPaid)}</>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <tr>
                              {!isLoading ? (
                                <td colSpan={4} className='text-center'></td>
                              ) : (
                                <td colSpan={4} className='text-center'>
                                  <div
                                    className='d-flex justify-content-center align-items-center w-100'
                                    style={{height: '25vh'}}
                                  >
                                    <CircularProgress style={{color: '#007a59'}} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ReceiptsPopup

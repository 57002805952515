import React, {useState, useEffect} from 'react'
import {Col, Row, Collapse, Card, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import redCross from '../../../img/x-mark.png'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import debitNoteWhite from '../../../img/debit-white..png'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import recipient from '../../../img/recipient.png'
import './style.scss'
import Switch from 'react-switch'
import ViewInvoice from './ViewInvoice'
import download from '../../../img/download-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  debitNote: any
  dueDate: any
  from: any
}

const ViewDebitNote = ({
  handleClose,
  show,
  debitNote,
  paymentId,
  tenantDetails,
  dueDate,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [debitNoteNo, setDebitNoteNo] = useState<any>('')
  const [recipientsModal, setRecipientsModal] = useState(false)
  const [checkedRec, setCheckedRec] = useState<any>(debitNote?.isShareToTenant)
  const [viewInvoice, setViewInvoice] = useState<any>(false)
  const [invoiceToView, setInvoiceToView] = useState<any>()

  const [recipientsEmails, setRecipientsEmails] = useState<any>([
    {
      email: '',
    },
  ])

  //
  const handleEmailsAddFields = () => {
    const values = [...recipientsEmails]
    values.push({
      email: '',
    })
    setRecipientsEmails(values)
  }

  //
  const handleEmailRemoveFields = (index: any) => {
    const values = [...recipientsEmails]
    values.splice(index, 1)
    setRecipientsEmails(values)
  }

  //
  const shareWithRecipients = async () => {
    let emailsList: any = []
    let j = 0
    for (let i = 0; i < recipientsEmails?.length; i++) {
      if (recipientsEmails[i]?.email?.length != 0) {
        emailsList[j] = recipientsEmails[i]?.email
        j++
      }
    }

    const body = {
      paymentId: paymentId,
      emails: emailsList,
      paymentDebitNoteId: debitNote?._id,
    }

    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_debit_note/sent/other?isCloneRecord=true`
    else url = `corporate/payment_debit_note/sent/other`

    await ApiPost(`${url}`, body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        setRecipientsModal(false)
        setRecipientsEmails([
          {
            email: '',
          },
        ])
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getSum = (numb1: any, numb2: any) => {
    return numb1 + numb2
  }

  //
  const getPaymentInvoiceById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_invoice/${id}?isCloneRecord=true`
    else url = `corporate/payment_invoice/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setInvoiceToView(res?.data?.data)
        setViewInvoice(true)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const updateShareStatus = (val: any) => {
    const body = {
      id: debitNote?._id,
      isShareToTenant: val,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_debit_note?isCloneRecord=true`
    else url = 'corporate/payment_debit_note'

    ApiPut(`${url}`, body)
      .then((res) => {})
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <div>
      {/* Debit Note Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto custom-modal no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 debit-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Debit Note</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  handleClose()
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={debitNoteWhite} height={80} width={80} className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-4'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row>
            <Col md={8}>
              <div className='d-flex'>
                {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                  ((user?.isSubUser &&
                    role?.leasing_residential?.lease_financial?.debit_notes?.update) ||
                    !user?.isSubUser)) ||
                  from == 'propertyProfile' ||
                  from == 'tenant' ||
                  from == 'financials') && (
                  <button
                    className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 text-white status-w-110'
                    style={{
                      backgroundColor: '#384a62',
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      setRecipientsModal(true)
                    }}
                  >
                    <img
                      src={share}
                      height={18}
                      width={18}
                      style={{
                        marginRight: '12px',
                        marginTop: '-2px',
                      }}
                    />{' '}
                    {'  '}
                    Share
                  </button>
                )}

                <a
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 debit-color text-white status-w-110'
                  style={{
                    fontSize: '13px',
                  }}
                  href={`${Bucket}${debitNote?.pdfURL}`}
                  target='_blank'
                >
                  <img
                    src={download}
                    height={18}
                    width={18}
                    style={{
                      marginRight: '3px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  Download
                </a>

                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 text-white status-w-110'
                  style={{
                    backgroundColor: '#8a8aa3',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    window.print()
                  }}
                >
                  <img
                    src={print}
                    height={18}
                    width={18}
                    style={{
                      marginRight: '12px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  {'  '}
                  Print
                </button>
                {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                  ((user?.isSubUser &&
                    role?.leasing_residential?.lease_financial?.invoicing?.view_list) ||
                    !user?.isSubUser)) ||
                  from == 'propertyProfile' ||
                  from == 'tenant' ||
                  from == 'financials') && (
                  <button
                    className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 text-white status-w-110'
                    style={{
                      backgroundColor: '#008081',
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      if (
                        ((from == 'tenancy' || from == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.invoicing?.view_details) ||
                            !user?.isSubUser)) ||
                        from == 'propertyProfile' ||
                        from == 'tenant' ||
                        from == 'financials'
                      )
                        getPaymentInvoiceById(debitNote?.paymentInvoiceId)
                      else ErrorToast("You don't have the permissions to view Invoice details...")
                    }}
                  >
                    {'  '}
                    Invoice
                  </button>
                )}
              </div>
            </Col>
            <Col md={4} className='py-3 '>
              {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.debit_notes?.update) ||
                  !user?.isSubUser)) ||
                from == 'propertyProfile' ||
                from == 'tenant' ||
                from == 'financials') && (
                <div className='d-flex align-items-center mt-1 justify-content-end'>
                  <Switch
                    onChange={(e) => {
                      setCheckedRec(e)
                      updateShareStatus(e)
                    }}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={checkedRec}
                    onColor='#6560ff'
                    offColor='#b0bec5'
                  />{' '}
                  <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
                </div>
              )}
            </Col>
          </Row>
          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            <Row className='mt-2'>
              <Col md={8} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>TAX DEBIT NOTE</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Debit Note No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {debitNote?.debitNoteNo}
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Issue Date
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {moment(debitNote?.date).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Invoice No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {debitNote?.invoiceNo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className='d-flex my-4 ms-auto'>
                  {debitNote?.debitNoteLogo && (
                    <img
                      src={`${Bucket}${debitNote?.debitNoteLogo}`}
                      height={140}
                      width={140}
                      style={{borderRadius: '7px'}}
                      className='ms-auto'
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row className='mt-5 pb-2'>
              <Col md={6} className='text-start' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.name}</p>
                <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.address}</p>

                <p style={{marginBottom: '0.1rem'}}>
                  {debitNote?.debitNoteCompany?.countryCode}{' '}
                  {debitNote?.debitNoteCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.email}</p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>

            <Row style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={4} className='pe-4 pb-5'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{debitNote?.debitNoteCompany?.trn ? debitNote?.debitNoteCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {debitNote?.paymentSubItems?.map((field: any, index: any) => {
                  return (
                    <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                      <td className='py-2'>{index + 1}</td>
                      <td className='py-2'>{field.name}</td>
                      <td className='py-2'>{field.quantity}</td>
                      <td className='py-2'>
                        {getPositive(field?.debitAmount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td className='py-2'>
                        {getPositive(field?.debitVATAmount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td className='py-2'>
                        {getPositive(
                          getSum(
                            getPositive(parseFloat(field?.debitAmount)),
                            getPositive(parseFloat(field?.debitVATAmount))
                          )
                        )?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  )
                })}

                <tr style={{height: '55px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {getPositive(debitNote?.subTotalDebitAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>

                <tr style={{height: '30px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {getPositive(debitNote?.totalDebitVAT)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4 fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {getPositive(debitNote?.totalDebitAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            {debitNote?.note && (
              <Row>
                <Col md={8}>
                  {/* Notes */}
                  <div className='my-2 mb-5 h-10'>
                    <label
                      className='mb-4 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Notes
                    </label>

                    {debitNote?.note}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Modal>

      {/* Share with recipients */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={recipientsModal}
        onHide={() => {
          setRecipientsModal(false)
          setRecipientsEmails([
            {
              email: '',
            },
          ])
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: '#384a62 ',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Recipients</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross me-1'
                onClick={() => {
                  setRecipientsModal(false)
                  setRecipientsEmails([
                    {
                      email: '',
                    },
                  ])
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg-edit' style={{backgroundColor: '#384a62'}}>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2'>
              <img
                src={recipient}
                height={85}
                width={85}
                className='me-3 ms-5'
                style={{transform: 'translate(10px, -2px)'}}
              />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            marginBottom: '-93px',
            maxHeight: '500px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
          }}
        >
          <div className='d-flex mt-5 mx-3'>
            <h3 className='head-text mt-5' style={{fontWeight: '700'}}>
              Recipients Emails
            </h3>
            <button
              className='btn btn-sm fw-bold ps-1 pe-4 mt-3 ms-auto text-white status-w-110'
              style={{
                width: '150px',
                backgroundColor: '#384a62',
                fontSize: '13px',
              }}
              onClick={() => shareWithRecipients()}
            >
              <img
                src={share}
                height={18}
                width={18}
                style={{marginRight: '12px', marginTop: '-2px'}}
              />{' '}
              {'  '}
              Share
            </button>
          </div>

          {recipientsEmails.map((rec: any, index: any) => {
            return (
              <Row className='mx-1 mt-4'>
                <Col lg={4} md={4} className='align-items-center pe-5'>
                  {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                  <input
                    type='text'
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Recepient Email..'
                    name='item_id'
                    value={rec.email}
                    onChange={(e) => {
                      const values = [...recipientsEmails]
                      values[index].email = e.target.value
                      setRecipientsEmails(values)
                    }}
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.4rem 1rem',
                    }}
                  />
                </Col>
                <Col lg={4} className='align-items-center mb-0  m-2'>
                  <div className='d-flex align-items-center mt-0'>
                    <img
                      src={redCross}
                      width={18}
                      height={18}
                      className='main_img cursor-pointer '
                      onClick={() => handleEmailRemoveFields(index)}
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} className='align-items-center text-end'></Col>
              </Row>
            )
          })}

          <div
            className='d-flex align-items-center cursor-pointer m-5 ms-0'
            onClick={() => handleEmailsAddFields()}
            style={{marginTop: '30px'}}
          >
            <img src={plusBlue} className='ms-4' width={16} height={16} />
            <p className='head-text ms-3 mb-0'>
              {' '}
              <i>Add another Email</i>
            </p>
          </div>
        </div>
      </Modal>

      {/* View Single Invoice */}
      {viewInvoice && (
        <ViewInvoice
          show={viewInvoice}
          handleClose={() => {
            setViewInvoice(false)
          }}
          invoice={invoiceToView}
          dueDate={dueDate}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          paymentMethod={''}
          getPaymentInvoices={'getPaymentById'}
          invRecDetails={'invRecDetails'}
          from={from}
        />
      )}

      {/* Screen for Print */}
      <div className='app-main print-invoice' id='kt_app_main'>
        <div id='' className=''>
          <div className='d-flex mt-3'>
            <div className=''>
              <div className='d-flex align-items-center my-2 '>
                <h1 className=''>TAX DEBIT NOTE</h1>
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5 label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Debit Note No.
                </label>

                <div style={{fontWeight: '500'}}> {debitNote?.debitNoteNo}</div>
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Issue Date
                </label>
                <div style={{fontWeight: '500'}}>
                  {' '}
                  {moment(debitNote?.date).format('DD-MM-YYYY')}
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Invoice No.
                </label>
                <div style={{fontWeight: '500'}}>{debitNote?.invoiceNo}</div>
              </div>
            </div>
            <div className='text-end ms-auto'>
              {debitNote?.debitNoteLogo && (
                <img
                  src={`${Bucket}${debitNote?.debitNoteLogo}`}
                  height={140}
                  width={140}
                  style={{borderRadius: '7px'}}
                  className='ms-auto'
                />
              )}
            </div>
          </div>

          <div className='mt-5 pb-5 d-flex'>
            <div className='text-start pe-3' style={{fontWeight: '600'}}>
              <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.name}</p>
              <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.address}</p>

              <p style={{marginBottom: '0.1rem'}}>
                {debitNote?.debitNoteCompany?.countryCode}{' '}
                {debitNote?.debitNoteCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{debitNote?.debitNoteCompany?.email}</p>
            </div>
            <div style={{fontWeight: '600'}} className='text-end ms-auto'>
              <h5>Recipient</h5>

              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.lastName} {tenantDetails?.firstName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
            </div>
          </div>

          <div
            className='d-flex align-items-center mb-5 pb-5'
            style={{borderBottom: '2px dashed lightgray'}}
          >
            <label
              className='mb-1 me-5 pe-5 head-text'
              style={{minWidth: '50px', maxWidth: '50px'}}
            >
              <b>TRN</b>
            </label>
            <b>{debitNote?.debitNoteCompany?.trn ? debitNote?.debitNoteCompany?.trn : '-'}</b>
          </div>

          <table
            className='table align-middle fs-6 gy-5 mt-3 pb-5'
            id='kt_ecommerce_sales_table'
            style={{borderBottom: '1.5px dashed lightgray'}}
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-50px'>#</th>
                <th className='min-w-100px'>Item</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-100px'>Rate</th>
                <th className='min-w-100px'>Vat</th>
                <th className='min-w-100px'>Amount</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}}>
              {debitNote?.paymentSubItems?.map((field: any, index: any) => {
                return (
                  <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                    <td className='py-2'>{index + 1}</td>
                    <td className='py-2'>{field.name}</td>
                    <td className='py-2'>{field.quantity}</td>
                    <td className='py-2'>
                      {getPositive(field?.debitAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className='py-2'>
                      {getPositive(field?.debitVATAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className='py-2'>
                      {getPositive(
                        getSum(
                          getPositive(parseFloat(field?.debitAmount)),
                          getPositive(parseFloat(field?.debitVATAmount))
                        )
                      )?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                )
              })}

              <tr style={{height: '55px'}} className='fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2'>
                  {getPositive(debitNote?.subTotalDebitAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>

              <tr style={{height: '30px'}} className='fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2'>
                  {getPositive(debitNote?.totalDebitVAT)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr style={{height: '55px'}} className='pb-4 fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3'>Total</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>
                    AED{' '}
                    {getPositive(debitNote?.totalDebitAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          {debitNote?.note && (
            <div className='d-flex'>
              <div style={{width: '50%'}}>
                {/* Notes */}
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <p style={{marginBottom: '0.1rem'}}> {debitNote?.note}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewDebitNote

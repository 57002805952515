import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../img/back-arrow.png'
import editPen from '../../../../../../img/edit-pen.png'
import trashIcon from '../../../../../../img/trash.png'
import checkMarkGreen from '../../../../../../img/check-mark-green.png'
import emailNotSendIcon from '../../../../../../img/remove.png'
import emailTemplateBlue from '../../../../../../img/emailTemplateBlue.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../../../apiCommon/helpers/Toast'
import noData from '../../../../../../img/NoData1.svg'
import {capitalizeFirstLetter} from '../../../../../../Utilities/utils'
import ConnectEmailsDomain from './ConnectEmailDomain'
import Switch from 'react-switch'
import ReactPaginate from 'react-paginate'
import CircularProgress from '@mui/material/CircularProgress'
import TestPopup from './TestPopup'
import {useGoogleLogin} from '@react-oauth/google'
import axios from 'axios'
import swal from 'sweetalert2'
import { useMsal, } from '@azure/msal-react'

const EmailsChannels = () => {
  const navigate = useNavigate()
  const { instance } = useMsal();
  const [inProgress,setInProgress] = useState(false)

  const [requestTableData, setRequestTableData] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')
  const [showAddNew, setShowAddNew] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [newError, setNewError] = useState<any>(false)
  const [connectEmailModal, setConnectEmailModal] = useState<any>(false)
  const [isTestPopup, setIsTestPopup] = useState<any>(false)
  const [testEmailId, setTestEmailId] = useState<any>('')
  let id = ''
  let email = ''

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }
  const handleConnectEmailModel = () => {
    setConnectEmailModal(true)
  }
  const goToEmailSetting = (emailId: any) => {
    navigate(`/settings/crm-residents/channels/emails/connect-email-domain/${emailId}`)
  }

  const deleteEmailSetting = async (emailId: any) => {
    setIsLoading(true)
    await ApiDelete(`corporate/email_setting/${emailId}`)
      .then((res) => {
        getRequestTableData()
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getRequestTableData = async () => {
    setIsLoading(true)
    setRequestTableData([])

    let body = {
      page: page,
      limit: limit,
    }

    await ApiPost('corporate/email_setting/get', body)
      .then((res) => {
        setRequestTableData(res?.data?.data?.email_setting)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateEmailSettings = async (id: any, toggleValue: any, index: any) => {
    let body = {
      id: id,
      isEnable: toggleValue,
    }

    await ApiPut('corporate/email_setting', body)
      .then((res) => {
        const updateRequestData = [...requestTableData]
        // console.log(updateRequestData)
        updateRequestData[index].isEnable = res?.data?.data?.isEnable
        // console.log(updateRequestData)
        setRequestTableData(updateRequestData)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const handleTestPopup = (emailObj: any) => {
    if (emailObj?.isEmailVerified === true) {
      setTestEmailId(emailObj?._id)
      setIsTestPopup(true)
    }
  }

  const handleCompleteConnection = (emailObj: any) => {
    // auth
    if (!emailObj?.isEmailVerified) {
      ErrorToast('You have to verify email first.')
      return
    }
    id = emailObj?._id
    email = emailObj?.email
    if (emailObj?.emailProviderType === 0) {
      googleLogin()
    }
    if(emailObj?.emailProviderType === 1){
      handleMicrosoftLogin(emailObj?.email)
    }
  }

  const checkIsValidOathUser = (tokenResponse: any) => {
    axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`)
      .then((res) => {
        if (res?.data?.email === email) {
          updateAuth(id, tokenResponse, true)
        } else {
          ErrorToast('You have choose the wrong email address. Please choose the correct email address.')
        }
      })
      .catch((err) => console.log(err))
  }
  // flow: 'auth-code',
  const googleLogin = useGoogleLogin({
    hint:`${email}`,
    prompt: "consent",
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
    onSuccess: (tokenResponse: any) => {
      // console.log(tokenResponse)
      // Pass the id and tokenResponse to the updatePasswordStatus function
      checkIsValidOathUser(tokenResponse)
    },
    onError: () => {
      ErrorToast('Google Login Failed.')
    },
    onNonOAuthError: (othererror: any) => {
      ErrorToast(othererror?.message)
    },
  })

  
  const convertMiliSToSeconds = (time:any) => {
    const currentTime = new Date().getTime(); // get current time in miliseconds
    const expiryDateInSeconds = time ? time.getTime() : 3600; // get time since 1970
    const actualTime = expiryDateInSeconds - currentTime;
    return Math.floor(actualTime / 1000);
  }


  const handleMicrosoftLogin = async (email:any) => {
    try {
      
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read"],
        prompt: "consent",
        loginHint: email, 
      });
      setIsLoading(true)
      const { accessToken, idToken, scopes, tokenType, expiresOn,extExpiresOn } = loginResponse;

      const expiryDateInSeconds = convertMiliSToSeconds(expiresOn)
      const extExpiresOnInSeconds = convertMiliSToSeconds(extExpiresOn)

      const tokenData = {
        access_token: accessToken,
        id_token: idToken,
        scope: scopes.join(' '),
        token_type: tokenType,
        expiry_date: expiryDateInSeconds,
        ext_expires_in: extExpiresOnInSeconds
      };

      // callMsGraph(loginResponse.accessToken)
      if(loginResponse.account.username === email){
        updateAuth(id, tokenData, true)
        // console.log(loginResponse)
      }else{
        ErrorToast('You have choose the wrong email address. Please choose the correct email address.')
      }
    } catch (error:any) {
      // console.log(error.message);
      setInProgress(false)
      if(error?.message?.includes('user_cancelled')){
        ErrorToast('Popup window closed.')
      }else{
        ErrorToast(error?.message?.split(':')?.[1])

      }
    }
  };

  async function callMsGraph(accessToken:any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch('https://graph.microsoft.com/v1.0/me', options)
        .then(async response => {
          const res = await response.json()
          console.log(res)
        })
        .catch(error => console.log(error));
}

  const updateAuth = async (id: any, authObj: any, isConnected: any) => {
    setIsLoading(true)

    let body = {
      id: id,
      token: authObj,
      isConnected,
    }

    await ApiPut('corporate/email_setting', body)
      .then((res) => {
        // emailVerification()
        setIsLoading(false)
        getRequestTableData()
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getRequestTableData()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center mb-5' style={{position: 'relative'}}>
          <div className='d-flex align-items-start'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate(`/settings/crm-residents/channels`)}>
              <img src={backArrow} height={14} width={14} className='me-4' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text'>{'Emails'}</h2>
              <p className='labl-gry fs-4 mb-0'>Connect your email domains to start receiving tickets in your CRM</p>
            </div>
          </div>
          <div className='' style={{position:'absolute',right:0,top:30}}>
            <div className=' position-relative'>
              <img src={emailTemplateBlue} width={50} className='position-absolute' style={{zIndex: -1, top: -30, right: 20}} />
              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-180'
                onClick={() => {
                  navigate('/settings/crm-residents/channels/emails/connect-email-domain')
                }}
              >
                Connect Email Domain
              </button>
            </div>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 tst'>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-200px'>Email Address</th>
                  <th className='min-w-125px'>Provider</th>
                  <th className='min-w-150px'>Application Password</th>
                  <th className='min-w-150px'>Verification</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='text-start min-w-25px ps-0 '></th>
                  <th className='text-start min-w-25px ps-0 '></th>
                </tr>
              </thead>
              <tbody className='fw-semibold head-text'>
                {' '}
                {/* className='fw-semibold text-gray-600'*/}
                {requestTableData?.length > 0 ? (
                  requestTableData?.map((listItem: any, ind: any) => {
                    return (
                      <tr>
                        <td className='cursor-pointer' onClick={() => handleTestPopup(listItem)}>
                          {listItem?.email ? listItem?.email : '-'}
                        </td>
                        <td className='cursor-pointer'>
                          {listItem?.emailProviderType === 0 ? 'Gmail' : listItem?.emailProviderType === 1 ? 'Outlook' : '-'}
                        </td>
                        <td className='cursor-pointer'>{listItem?.appPassword && listItem?.isPasswordVerified ? 'Linked' : 'Not Linked'}</td>
                        {/* <td className='cursor-pointer'>{listItem?.appPassword ? listItem?.appPassword : '-'}</td> */}
                        <td className={`cursor-pointer`} style={{color: `${listItem?.isEmailVerified === true ? '#146C6A' : '#d54645'}`}}>
                          <div className='d-flex align-items-center'>
                            <img src={listItem?.isEmailVerified ? checkMarkGreen : emailNotSendIcon} alt='Email' width={16} className='me-3' />
                            {listItem?.isEmailVerified === true ? 'Verified' : listItem?.isEmailVerified === false ? 'Not Verified' : '-'}
                          </div>
                        </td>
                        <td className='cursor-pointer'>
                          {listItem?.isConnected === true ? (
                            'Connected'
                          ) : listItem?.isConnected === false ? (
                            <>
                              {/* <GoogleOAuthProvider clientId="1025655301741-4alshu0snk89h3kvsfd5pfvibufft34l.apps.googleusercontent.com"> */}
                              <button
                                onClick={() => handleCompleteConnection(listItem)}
                                type='button'
                                className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center'
                                style={{width: '100%'}}
                              >
                                {'Complete Connection'}
                              </button>
                              {/* </GoogleOAuthProvider> */}
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className='cursor-pointer'>
                          <div className='d-flex  align-items-center '>
                            <Switch
                              disabled={editFile == ind}
                              onChange={(e) => {
                                updateEmailSettings(listItem?._id, !requestTableData[ind].isEnable, ind)
                              }}
                              handleDiameter={20}
                              height={24}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={listItem?.isEnable}
                              onColor='#00cf99'
                              offColor='#b0bec5'
                            />
                            <h5 className=' m-0 head-text ms-3'>{listItem?.isEnable === true ? 'Enabled' : 'Disabled'}</h5>
                          </div>
                        </td>
                        <td>
                          <img
                            src={editPen}
                            width='12'
                            className='mx-0'
                            onClick={() => goToEmailSetting(listItem?._id)}
                            style={{cursor: 'pointer'}}
                          />
                        </td>
                        <td>
                          <img
                            src={trashIcon}
                            width='16'
                            className='mx-0'
                            onClick={() => {
                              swal
                                .fire({
                                  html: `<div class='fnt-700'>Delete Email Setting</div>
                                  <div class='mt-4'>Area you sure you want to delete the email setting?</div>`,
                                  showConfirmButton: true,
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    deleteEmailSetting(listItem?._id)
                                  }
                                })
                            }}
                            style={{cursor: 'pointer'}}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    {!isLoading ? (
                      <td colSpan={5} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    ) : (
                      <td colSpan={6} className='text-center'>
                        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '25vh'}}>
                          <CircularProgress style={{color: '#007a59'}} />
                        </div>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* pagination start*/}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row mt-5'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* pagination end*/}
        </div>
      </div>
      {isTestPopup && (
        <TestPopup
          emailId={testEmailId}
          show={isTestPopup}
          handleClose={() => {
            setIsTestPopup(false)
          }}
        />
      )}
    </>
  )
}

export default EmailsChannels

import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react'
import GenerateInvoice from './GenerateInvoice'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {Backdrop, CircularProgress} from '@mui/material'

import backArrow from '../../../../../../img/back-arrow.png'
import removeIcon from '../../../../../../img/remove.png'
import refreshIcon from '../../../../../../img/refreshIcon.png'
import {ApiGet, ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../../../apiCommon/helpers/Toast'
import ConfirmationPopup from '../ConfirmationPopup'

enum ActionType {
  CreateAndShare = 'CS',
  Create = 'CR',
  UpdateAndShare = 'US',
  Update = 'UP',
  None = '-'
}

const EditInvoice = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const navigate = useNavigate()
  const {id, flg} = useParams()

  const [isPrint, setPrint] = useState<boolean>(false)
  const invoiceRef = useRef(null)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([
    {
      label: 'Bank Transfer',
      value: '2',
    },
    {
      label: 'Card',
      value: '0',
    },
    {
      label: 'Cash',
      value: '3',
    },
    {
      label: 'Cheque',
      value: '1',
    },
  ])
  const [chargeTenant, setChargeTenant] = useState<any>(null)
  const [chequeImages, setChequeImages] = useState<any>([])
  const [idToDelete, setIDToDelete] = useState<any>([])

  const [incomeAPIDetails, setIncomeAPIDetails] = useState<any>() // use less i think check it

  const [incomeData, setIncomeData] = useState<any>({
    selectedAccount: [],
    payorData: {},
    paymentMethod: null,
    paymentAccountId: '',
    notes: '',
    development: '',
    invoices: [],
    items: [
      {
        id: '',
        name: '',
        paymentItemListId: '',
        amount: '',
        quantity: '',
        VATAmount: '',
        VATPercentage: '',
        totalAmount: '',
        outstandingAmount: 0,
        amountPaid: 0,
        totalAmountPaid: 0,
        frontendStorage: {
          isList: true,
          latePaymentObject: {},
          isLatePaymentItem: false,
          itemUniqueId: new Date().getTime(),
          paymentItemId: null,
          isLatePaymentItemAdded: false,
        },
        isLatePaymentItem: false,
        latePaymentFees: 0,
        latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
        latePaymentObject: {},
        oldLatePaymentCycleList: [],
        nextLatePaymentCycle: null,
        // paymentSubItemStatus:2
      },
    ],
    attachments: [],
    reminders: [],
    paymentRecords: [],
    category: 0,
    categoryDateTime: null,
    latePaymentType: null,
    frontendStorage: {lateFeeForAll: {}},
    invoiceName: 'INVOICE',
    invoiceNo: '',
    date: '',
    dueDate: null,
    invoiceLogo: '',
    invoiceCompany: {
      trn: '',
      name: '',
      address: '',
      countryCode: '',
      phoneNumber: '',
      email: '',
      _id: '',
    },
    note: '',
    paymentSubItems: [],
    totalAmount: 0,
    saveStatus: 0,
    totalVAT: 0,
    subTotalAmount: 0,
    paymentId: null,
    status: 0,
    outstandingAmount: 0,
    itemDescriptions: [],
    showAccountDetails: true,
    showNotes: true,
    // frontendStorage: {
    // },
  })
  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isShare, setIsShare] = useState<any>(true)

  const getAccounts = (val: any) => {
    // console.log(incomeData?.paymentAccountId)
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
            IBAN: acc?.IBAN,
            accountHolderName: acc?.accountHolderName,
            accountNumber: acc?.accountNumber,
            bankName: acc?.bankName,
            swiftCode: acc?.swiftCode,
          }

          if (val == acc?._id) setSelectedAccount([values[ind]])
        })

        setAccountOptions(values)
      })
      .catch((err) => console.log('err', err))
  }

  const [receiptData, setReceiptData] = useState<any>([])
  const getInvoiceById = async () => {
    try {
      reset()
      const res = await ApiGet(`corporate/payment_invoice/${id}`);
      
      setReceiptData(res?.data?.data?.payment_receipt)
      setChargeTenant(res?.data?.data?.cardChargePayBy)
      setChequeImages(res?.data?.data?.chequeImages)
      setValue('paymentName', res?.data?.data?.paymentName)
      setValue('invoiceNo', res?.data?.data?.invoiceNo)
      setValue('dueDate', res?.data?.data?.dueDate)
      setValue(
        'accountNumber',
        res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : ''
      )

      setValue(
        'paymentMethod',
        res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
      )

      

      if (res?.data?.data?.paymentAccountId) {
        await getAccounts(res?.data?.data?.paymentAccountId)
      } else {
        await getAccounts('')
      }
    
      let values: any = {
        invoiceName:  res?.data?.data?.invoiceTemplateHeader || 'INVOICE',
        payorData: {},
        dueDate: res?.data?.data?.dueDate,
        categories: res?.data?.data?.categories,
        paymentMethod: (res?.data?.data?.paymentMethod == null || res?.data?.data?.paymentMethod == '') ? '' : res?.data?.data?.paymentMethod,
        paymentAccountId: res?.data?.data?.paymentAccountId,
        note: res?.data?.data?.note,
        items: res?.data?.data?.paymentSubItems,
        attachments: res?.data?.data?.attachments,
        reminders: res?.data?.data?.payment_reminder,
        latePaymentType: res?.data?.data?.latePaymentType,
        frontendStorage: res?.data?.data?.frontendStorage ? res?.data?.data?.frontendStorage : {lateFeeForAll: {}},
        payment_invoice: res?.data?.data?.payment_invoice,
        payment_receipt: res?.data?.data?.payment_receipt,
        invoiceCompany: {
          trn: res?.data?.data?.invoiceCompany?.trn,
          name: res?.data?.data?.invoiceCompany?.name,
          address: res?.data?.data?.invoiceCompany?.address,
          countryCode: res?.data?.data?.invoiceCompany?.countryCode,
          phoneNumber: res?.data?.data?.invoiceCompany?.phoneNumber,
          email: res?.data?.data?.invoiceCompany?.email,
          _id: res?.data?.data?.invoiceCompany?._id,
        },
        _id: res?.data?.data?._id,
        date: res?.data?.data?.date ? res?.data?.data?.date : '',
        name: res?.data?.data?.paymentName,
        invoiceNo: res?.data?.data?.invoiceNo || '',
        invoiceLogo: res?.data?.data?.invoiceCompany?.invoiceLogo,
        totalAmount: res?.data?.data?.totalAmount,
        totalVAT: res?.data?.data?.totalVAt,
        subTotalAmount: res?.data?.data?.subTotalAmount,
        saveStatus: res?.data?.data?.saveStatus,
        status: 0,
        outstandingAmount: res?.data?.data?.outstandingAmount,
        itemDescriptions: res?.data?.data?.itemDescriptions,
        selectedAccount:res?.data?.data?.payment_account,
        showAccountDetails:res?.data?.data?.frontendStorage?.showAccountDetails,
        showNotes: res?.data?.data?.frontendStorage?.showNotes,
        formStatus:res?.data?.data?.formStatus,
        tenant: res?.data?.data?.tenant
        
      };

      setChequeImages(Number(res?.data?.data?.paymentMethod) == 1 ? res?.data?.data?.chequeImages : [])
  
      values?.items?.forEach((vl: any, ind: any) => {
        values.items[ind].outstandingAmount = vl.totalAmount - vl?.amountPaid;
        if(!(vl?.name?.trim()?.length === 0 || vl?.name == '' ||
          vl?.amount === '' ||
          vl?.amount === undefined ||
          Number(vl?.amount) == 0 ||
          vl?.quantity === '' ||
          vl?.quantity === undefined ||
          Number(vl?.quantity) == 0)
        ){
          values.items[ind].VATPercentage = values.items[ind].VATPercentage == "" ? 0 : values.items[ind].VATPercentage
        }
      });
  
      let tenancyIdToCompare: any = res?.data?.data?.tenancyId;
  
      switch (res?.data?.data?.paymentMethod) {
        case 1:
          setSelectedPaymentMethod([{ value: '1', label: 'Cheque' }]);
          break;
        case 2:
          setSelectedPaymentMethod([{ value: '2', label: 'Bank Transfer' }]);
          break;
        case 3:
          setSelectedPaymentMethod([{ value: '3', label: 'Cash' }]);
          break;
        case 0:
          setSelectedPaymentMethod([{ value: '0', label: 'Card' }]);
          break;
        default:
          setSelectedPaymentMethod([]);
      }
  
      if (res?.data?.data?.tenancyId != null) {
        const body = {
          page: 1,
          limit: 10000,
          searchTenant: '',
          searchDevelopment: '',
          searchContractNo: '',
          searchUnitNo: '',
        };
  
        try {
          const tenancyRes = await ApiPost('corporate/tenancy/overview', body);
          tenancyRes?.data?.data?.tenancy_data?.forEach((rs: any) => {
            if (rs?._id === tenancyIdToCompare) {
              values.payorData = rs;
            }
          });
        } catch (err) {
          console.error('Error fetching tenancy data:', err);
        }
      }

  
      setIncomeAPIDetails(res?.data?.data);
  
      // if (flg === '2') {
      //   const invoiceDetails = await getInvoiceDetails();
      //   let valuesInv = { ...values };
  
      //   valuesInv.invoiceCompany = invoiceDetails?.invoiceCompany;
      //   valuesInv.invoiceLogo = invoiceDetails?.invoiceLogo;
      //   values = valuesInv;
      // }
      
      setIncomeData(values);
      // setIsSaveFirst(false);
      // setRefreshChild(Date.now());
  
      setTimeout(() => {
        setIsSkeleton(false);
      }, 100);
  
    } catch (err:any) {
      console.error('Error:', err);
      ErrorToast(err?.message);
      setIsSkeleton(false);
    }
  };

  let grandTotalAmount: any = 0
  let grandPaidAmount: any = 0

  const updateIncomePayment = async (saveStatus: any,from:string) => {
      let errors: any = false
      let amountLessErr: any = false
      let addSectionErr : boolean = false
    
        // setIsLoading(true)
        // setOpen(true)

        // Payment Items
        let newItems: any = []
        let newItemInd: any = 0
        let updateItems: any = []
        let updateItemInd: any = 0

        let invoicePaymentItems: any = []

        var nonVat: any = 0
        var vatAmount: any = 0
        

        incomeData?.items?.map((itm: any, ind: any) => {
          let totalAmountOfItem: any = 0
          let vatAmnt: any =
            ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
          totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

          let totalAmountPaidOfItem: any = 0


          let paidAmountOfItems: any = []

          incomeData?.items?.map((incItm: any, indOut: number) => {
            let paidAmount: any = 0
  
            receiptData?.map((rcpt: any, index: any) => {
              rcpt?.paymentSubItems?.map((rcptItm: any, indIn: number) => {
                if (incItm?.frontendStorage?.itemUniqueId == rcptItm?.frontendStorage?.itemUniqueId)
                  paidAmount = paidAmount + rcptItm?.amountPaid
              })
            })
  
            paidAmountOfItems?.push(paidAmount)
          })
         

          if (itm?.id == '') {
            newItems[newItemInd] = {
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              name: itm?.name ? itm?.name : '',
              amount: itm?.amount == 0 ? '' : Number(itm?.amount),
              quantity: itm?.quantity == 0 ? '' : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? '': Number(totalAmountOfItem),
              // amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
              amountPaid: Number(paidAmountOfItems[ind]),
              // outstandingAmount: isNaN(itm?.outstandingAmount)
              //   ? undefined
              //   : Number(itm?.outstandingAmount),
              outstandingAmount: Number(totalAmountOfItem) - Number(paidAmountOfItems[ind]),
              VATAmount: isNaN(itm?.VATAmount) ? '' : Number(vatAmnt) ,
              VATPercentage: itm?.VATPercentage == 0 ? '' : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
              creditAmount:0,
              creditVATAmount:0,
              totalCreditAmount:0,
              debitAmount:0,
              debitVATAmount:0,
              totalDebitAmount:0,
            }

            invoicePaymentItems?.push(newItems[newItemInd])
            newItemInd++
          } else {
            updateItems[updateItemInd] = {
              _id: itm?._id,
              name: itm?.name ? itm?.name : '',
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              amount: itm?.amount == 0 ? '' : Number(itm?.amount),
              quantity: itm?.quantity == 0 ? '' : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? '' : Number(totalAmountOfItem),
              // amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
              
              // outstandingAmount: isNaN(itm?.outstandingAmount)
              //   ? undefined
              //   : Number(itm?.outstandingAmount),
              outstandingAmount: Number(totalAmountOfItem) - Number(paidAmountOfItems[ind]),
              VATAmount: isNaN(itm?.VATAmount) ? '' : Number(vatAmnt) ,
              VATPercentage: itm?.VATPercentage == 0 ? '' : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
              creditAmount:0,
              creditVATAmount:0,
              totalCreditAmount:0,
              debitAmount:0,
              debitVATAmount:0,
              totalDebitAmount:0,
            }

            invoicePaymentItems?.push(updateItems[updateItemInd])
            updateItemInd++
          }
        })

        const body = {
            _id: flg == '1' ? undefined : id,
            invoiceNo: incomeData?.invoiceNo ? incomeData?.invoiceNo : '',
            invoiceTemplateHeader: incomeData?.invoiceName ? incomeData?.invoiceName : '',
            paymentName: incomeData?.name ? incomeData?.name : '',
            note: incomeData?.note ? incomeData?.note : '',
            dueDate:incomeData?.dueDate ? incomeData?.dueDate : '',
            invoiceLogo:incomeData?.invoiceLogo ? incomeData?.invoiceLogo : '',
            invoiceCompany: incomeData?.invoiceCompany ? incomeData?.invoiceCompany : {},
            paymentMethod:
            selectedPaymentMethod?.length > 0
              ? Number(selectedPaymentMethod?.[0]?.value)
              : '',
              formStatus: saveStatus,
              totalAmount: incomeData?.totalAmount,
              totalVAT:incomeData?.totalVAT,
              subTotalAmount: incomeData?.subTotalAmount,
              paymentAccountId: selectedAccount?.length > 0 ? selectedAccount?.[0]?.value : null,
              paymentId:null,
              status:0, 
              outstandingAmount: Number(grandTotalAmount) - Number(grandPaidAmount),
              itemDescriptions: incomeData?.itemDescriptions?.length > 0 ? incomeData?.itemDescriptions :[],
              formStatusDateTime: new Date(),
              isCardVerificationEnable: false,
              isLock: false,
              chequeBankName: null,
              chequeImages: Number(selectedPaymentMethod?.[0]?.value) == 1 ? chequeImages : [],
              tenant: incomeData?.tenant,
            
            latePaymentType:
              incomeData?.latePaymentType != undefined ? incomeData?.latePaymentType : '',
              paymentSubItems: [...newItems,...updateItems],
              frontendStorage: {
                showAccountDetails:incomeData?.showAccountDetails,
                showNotes: incomeData?.showNotes,
                lateFeeForAll: incomeData?.frontendStorage?.lateFeeForAll
              },
            
            tenantId:
              incomeData?.payorData?.tenant?.length > 0
                ? incomeData?.payorData?.tenant?.[0]?._id
                : null,
            tenancyId: incomeData?.payorData?._id ? incomeData?.payorData?._id : null,
            unitId: incomeData?.payorData?.unit?.[0]?._id
              ? incomeData?.payorData?.unit?.[0]?._id
              : null,
            clusterId: incomeData?.payorData?.cluster?.[0]?._id
              ? incomeData?.payorData?.cluster?.[0]?._id
              : null,
            unitGroupId: incomeData?.payorData?.unit_group?.[0]?._id
              ? incomeData?.payorData?.unit_group?.[0]?._id
              : null,
            floorId: incomeData?.payorData?.floor?.[0]?._id
              ? incomeData?.payorData?.floor?.[0]?._id
              : null,
            communityId: incomeData?.payorData?.community?.[0]?._id
              ? incomeData?.payorData?.community?.[0]?._id
              : null,
            buildingId: incomeData?.payorData?.building?.[0]?._id
              ? incomeData?.payorData?.building?.[0]?._id
              : null,
            tenantCardId: null,
            cardChargePayBy: chargeTenant,
            createdOriginType:1
        }
        ApiPost(`corporate/payment_invoice`, body)
          .then((res) => {
            if(from === ActionType.Update){
              setAskForConfirmation(false)
              navigate(`/finance-income/view-invoice/${id}`)
            }else if(from === ActionType.UpdateAndShare){
              navigate(`/finance-income/share/view-invoice/${id}`)
            }

          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setTimeout(() => {
              // setIsLoading(false)
              // setOpen(false)
            }, 1500)
          })
      
    
  }

  useEffect(() => {
    setIsSkeleton(true)
    getInvoiceById()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      console.log(errors)
      console.log(incomeData)
    }, 1000)
  }, [incomeData])

  const getInvoiceDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting');
      console.log(res)
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getInvoiceDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap px-2'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => navigate(`/finance-income/view-invoice/${id}`)}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Edit Invoice`}</h2>
                </div>

                <div className='d-flex ms-auto'>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 red-hollow-btn btn-fit-content ps-3'
                    // disabled={isLoading}
                    onClick={() => {
                      navigate(`/finance-income/view-invoice/${id}`)
                    }}
                  >
                    <img src={removeIcon} height={18} width={18} className='me-4' />{' '}
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm ms-3 fw-bold green-submit-btn btn-fit-content ps-3'
                    onClick={async () => {
                      
                      let isValid: any = true
                      isValid = await trigger()

                      const invoiceDetails = await getInvoiceDetails();

                      if(incomeData?.invoiceName === ''){
                        ErrorToast('Invoice name is required.')
                        return
                      }else if(invoiceDetails?.invoiceSequence?.activityStatus == 0 && incomeData?.invoiceNo === ''){
                        ErrorToast('Invoice number is required.')
                        return
                      }else if(incomeData?.dueDate === '' || incomeData?.dueDate === null){
                        ErrorToast('Due date is required.')
                        return
                      }

                      let errors: any = false
                      incomeData?.items?.map((itm: any, ind: any) => {
                        let totalAmountOfItem: any = 0
                        let vatAmnt: any =
                          ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
                        totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt
                        console.log(itm)
                        if (itm?.frontendStorage?.isLatePaymentItem == false && 
                          (itm?.name?.trim()?.length === 0 || itm?.name == '' ||
                          itm?.amount === '' ||
                          itm?.amount === undefined ||
                          Number(itm?.amount) == 0 ||
                          itm?.quantity === '' ||
                          itm?.quantity === undefined ||
                          Number(itm?.quantity) == 0 ||
                          itm?.VATPercentage === '' ||
                          itm?.VATPercentage === undefined)
                        ) {
                            errors = true
                        }
                      })

                      if(errors){
                        ErrorToast('User cannot create Invoice with no payment item details.')
                        return
                      }
                      
                      let addSectionErr : boolean = false
                      if(incomeData?.itemDescriptions?.length > 0  && incomeData?.itemDescriptions?.some((itm:any) => {
                        const tempElement = document.createElement('div');
                        tempElement.innerHTML = itm?.html;
                        return ((!tempElement?.textContent?.trim() || !tempElement?.innerText?.trim()) || !itm?.title?.trim() || itm?.html === undefined);
                      })){
                        addSectionErr = true
                      }else{
                        addSectionErr = false
                      }   
                    
                    if(addSectionErr){
                      ErrorToast('User cannot create Invoice with empty title or content in additional sections.')
                      return
                    }

                    setAskForConfirmation(true)
                    }}
                  >
                    <img src={refreshIcon} height={18} width={18} className='me-4' />{' '}
                    Update
                  </button>
                </div>
              </div>
            </div>
             {/* GRAND TOTAL AMOUNT */}
             {incomeData?.items?.map((itm: any, ind: any) => {
                grandTotalAmount = Number(grandTotalAmount) + Number(itm?.totalAmount)
              })}

               {/* PAID AND OUTSTANDING AMOUNT */}
               {incomeData?.payment_receipt?.map((rc: any, ind: any) => {
                grandPaidAmount = Number(grandPaidAmount) + Number(rc?.amountPaid)
              })}
            <div className=''>
              <GenerateInvoice
                isEditAfterCreate={true}
                isPrint={isPrint}
                innerRef={invoiceRef}
                incomeData={incomeData}
                incomeAPIDetails={incomeAPIDetails}
                setIncomeAPIDetails={setIncomeAPIDetails}
                idToDelete={idToDelete}
                setIDToDelete={setIDToDelete}
                updateIncomeData={(updatedData: any) => setIncomeData(updatedData)}
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                autoSaveData={autoSaveData}
                setAutoSaveData={setAutoSaveData}
                // open={open}
                // setOpen={setOpen}
                editMode={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
      <ConfirmationPopup
      from='invoice'
        show={askForConfirmation}
        isUpdate={true}
        isCreate={false}
        label={'Update'}
        type={'Invoice'}
        payor={
          flg === '1'
            ? `${incomeData?.payorData?.tenant?.[0]?.firstName} ${incomeData?.payorData?.tenant?.[0]?.lastName}`
            : flg === '3'
            ? `${incomeData?.tenant?.[0]?.firstName} ${incomeData?.tenant?.[0]?.lastName}`
            : ''
        }
        typeOfNo={incomeData?.invoiceNo}
        handleClose={() => {
          setAskForConfirmation(false)
        }}
        updateData={(actionType:string) => {
          console.log(actionType)
          
          if(actionType === ActionType.Create){
            // run create fun
        }else if(actionType === ActionType.CreateAndShare){
           // run create and share
        }else if(actionType === ActionType.Update) {
           // run update fun
         
           updateIncomePayment(1,actionType)
        }else if(actionType === ActionType.UpdateAndShare){
            // run update and share
            updateIncomePayment(1,actionType)
        }else{
             // nothing
        }
        }}
        receiptData={[]}
        isShare={isShare}
        setIsShare={(val: any) => {
          setIsShare(val)
        }}
      />
    </>
  )
}

export default EditInvoice

import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import EmailBuilder from '../EmailBuilder'
import {Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'
import transparentHeader from '../../../../../../img/top-banner.svg'

const Template001 = () => {
  const imageUploaderSmall = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-small`
  const imageUploader = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-large`
  const imageUploaderSquare = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-square`
  const topHeaderPng = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/top-banner`
  const bottomBannerPng = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/bottom-banner`

  const [sections, setSections] = useState<any>([
    {
      id: `${Date.now()}1`,
      type: 'image',
      content: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        backgroundColor: '#fff',
        height: 60,
        width: 180,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '0',
        paddingRight: '0',
        paddingBottom: '30',
        paddingLeft: '0',
        imageUrl: '',
        sizeType: 'px',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        imgSrc: 'svg',
        shapeColor: '#6254FF',
        shapeHeight: 100,
        svgSrc: `<svg  viewBox="0 0 2048 704" height="100" width="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                 <path transform="translate(0)" d="m0 0h2048v587l-28 3-84 8-618 58-290 27-21-1-97-9-103-10-98-9-524-49-179-17-6-1z" fill="#6254FF"></path>
                 </svg>`,
        // borderInnerTagRadius: '5',
        // html: `
        //     <div class="header">
        //     <div style="height: 100px; background-color: #6254FF; clip-path: polygon(100% 78%, 48% 100%, 0 78%, 0 0, 100% 0)" class="top-banner">
        //     </div></div>`,

        //  html: `
        //     <div class="header">
        //     <svg height="260" width="100%" xmlns="http://www.w3.org/2000/svg">
        //     <polygon points="100,78, 48, 100, 0, 78, 0, 0, 100, 0" style="fill:lime;stroke:purple;stroke-width:0" />
        //     </svg>
        //     </div>`,

        // html: `
        // <div style="width: 100%; background-color: #fff">
        // <div >
        // <img src=${transparentHeader} height="100" width="100%"></div>
        // </div>`,

        html: ` 
              <div>
                <div style="height: 0px;">
                  <img src=${topHeaderPng} height="100" width="100%" class="svg-img" /> 
                </div>
                <div class="svg-section" style="height: 0px;" >
                  <svg  viewBox="0 0 2048 704" height="100" width="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <path transform="translate(0)" d="m0 0h2048v587l-28 3-84 8-618 58-290 27-21-1-97-9-103-10-98-9-524-49-179-17-6-1z" fill="#6254FF"></path>
                  </svg> 
                </div>
                <div style="width: fit-content; padding: 15px" id="img-in-svg">
                  <a href= "" target="_blank">
                    <img src="${imageUploaderSmall}" alt="Header Image" style="width: 180px; height: 60px; border-radius: 5px; max-width: 100%;  padding: 0px 10px" />
                  </a>
                </div>
              </div>`,

        // html: `
        // <div style="background-image: url('6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/top-banner')">
        // <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KIDxzdmcgIHZpZXdCb3g9IjAgMCAyMDQ4IDcwNCIgIHdpZHRoPSIxMDAlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8cGF0aCB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSIgZD0ibTAgMGgyMDQ4djU4N2wtMjggMy04NCA4LTYxOCA1OC0yOTAgMjctMjEtMS05Ny05LTEwMy0xMC05OC05LTUyNC00OS0xNzktMTctNi0xeiIgZmlsbD0iIzYyNTRGRiI+PC9wYXRoPiAKIDwvc3ZnPgo=" alt="SVG Image" height="100" width="100%" preserveAspectRatio="none" />
        // </div>`,
        image: [
          {
            display: 1,
          },
        ],
        link: [
          {
            display: 1,
          },
        ],
      },
    },

    {
      id: `${Date.now()}2`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '25',
        paddingRight: '20',
        paddingBottom: '25',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="background-color: #FFFFFF;  text-align: center; border-radius: 8px; margin-top: 10px;">
              <h1 style="font-size: 24px; margin-bottom: 20px; color: #000;">Header Title</h1></div>`,
      },
    },
    {
      id: `${Date.now()}3`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '10',
        paddingRight: '20',
        paddingBottom: '10',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<p style="font-size: 12px; line-height: 1.6; color: #000;">Here’s what you need to do:</p>
                <ol style="font-size: 12px; line-height: 1.6; color: #000;">
                <li>Click ‘Get Started’ to download Propertise and complete your account.</li>
                <li>Complete and submit your landlord’s registration form.</li>
                <li>Connect instantly with your landlord and start managing your lease, rental payments, requests, and more.</li>
            </ol>`,
      },
    },

    {
      id: `${Date.now()}4`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '5',
        paddingRight: '20',
        paddingBottom: '50',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: ` <p style="font-size: 12px; line-height: 1.6; color: #000;">
          If you have any questions or need assistance, our support team is here to help. Feel free to reach out to us at 
          <a href="mailto:support@propertise.com" style="color: #4A90E2;">support@propertise.com</a>.
          </p>`,
      },
    },

    {
      id: `${Date.now()}5`,
      type: 'image',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: '60',
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '15',
        paddingRight: '0',
        paddingBottom: '0',
        paddingLeft: '0',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        imgSrc: 'svg',
        shapeColor: '#6254FF',
        shapeHeight: 100,
        svgSrc: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="100%" height="60" viewBox="0 0 576.000000 76.000000"
            preserveAspectRatio="none">
            <g transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
            fill="#6254FF" stroke="none">
            <path d="M2820 733 c-33 -5 -2787 -263 -2805 -263 -13 0 -15 -35 -15 -230 l0
            -230 2880 0 2880 0 0 230 c0 170 -3 230 -12 230 -6 0 -658 61 -1448 135 -791
            74 -1441 134 -1446 134 -5 -1 -20 -4 -34 -6z"/>
            </g>
            </svg>`,
        html: `<img src=${bottomBannerPng} height="60" width="100%" class="svg-img"/> 
                  <div class="svg-section"style="height: 0px; transform: translate(0px, -60px)">
                   <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="100%" height="60" viewBox="0 0 576.000000 76.000000"
            preserveAspectRatio="none">
            <g transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
            fill="#6254FF" stroke="none">
            <path d="M2820 733 c-33 -5 -2787 -263 -2805 -263 -13 0 -15 -35 -15 -230 l0
            -230 2880 0 2880 0 0 230 c0 170 -3 230 -12 230 -6 0 -658 61 -1448 135 -791
            74 -1441 134 -1446 134 -5 -1 -20 -4 -34 -6z"/>
            </g>
            </svg>
                </div>`,
      },
    },
    {
      id: `${Date.now()}6`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '30',
        paddingRight: '20',
        paddingBottom: '40',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="text-align: center;">
          <p style="font-size: 12px; color: #000;">This is a system generated email, please do not respond to it.</p>
          <p style="font-size: 12px; color: #000; margin-top: 5px;">
            <a href="https://www.propertise.com/" style="color: #000; text-decoration: none; margin-right: 15px;">About Us</a> | 
            <a href="mailto:info@propertise.com" style="color: #000; text-decoration: none; margin-left: 15px;">Contact Us</a>
          </p>
          <p style="font-size: 12px; color: #000; margin-top: 10px;">Copyright@2024, Propertise FZCO, All rights reserved</p>
        </div>`,
      },
    },
  ])
  return (
    <>
      <EmailBuilder template={sections} />
    </>
  )
}

export default Template001

import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import {ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import schedule from '../../../img/schedule-blue.png'
import closure from '../../../../../img/job-closure.png'
import correct from '../../../../../img/correct.png'
import removed from '../../../../../img/remove-filled.png'
import pencil from '../../../../../img/edit-pen.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import Switch from 'react-switch'
import ReactPaginate from 'react-paginate'

const TicketAssignments = () => {
  const navigate = useNavigate()
  const [requestTableData, setRequestTableData] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const goToRequest = (listItem: any) => {
    // navigate(`/settings/tenant-request/${listItem}/sub-category`)
  }

  const getAssignmentTableData = async () => {
    setRequestTableData([])

    let body = {
      page: page,
      limit: limit,
    }

    await ApiPost('corporate/tenant_request_assignment_rule/get', body)
      .then((res) => {
        console.log(res?.data?.data?.data)
        setRequestTableData(res?.data?.data?.data)
        setPageLimit(res?.data?.data?.stage?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateReqestData = async (id:any,toggleValue:any,index:any) => {
    
    let body = {
     id:id,
     isEnable: toggleValue
    }

    await ApiPut('corporate/tenant_request_assignment_rule', body)
      .then((res) => {
        const updateRequestData = [...requestTableData]
         updateRequestData[index].isEnable = res?.data?.data?.isEnable
         setRequestTableData(updateRequestData)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getAssignmentTableData()
  }, [])
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span className='rounded-circle' style={{cursor: 'pointer'}} onClick={() => navigate(`/settings`)}>
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>{'Ticket Assignments'}</h2>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-end gap-2 mb-5 gap-lg-3'>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn status-w-140 px-2 justify-content-center'
            onClick={() => {
              navigate('/settings/ticket-assignment/add-rule')
            }}
          >
            Add Assignment Rule
          </button>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 tst'>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-200px'>Assignment Rule</th>
                  <th className='min-w-150px'>Request Category</th>
                  <th className='min-w-150px'>Request Sub Category</th>
                  <th className='min-w-150px'>Status</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
              {requestTableData?.length > 0 ? (
                  requestTableData?.map((listItem: any, i: any) => {
                    return (
                      <tr>
                        <td className='cursor-pointer' onClick={() => goToRequest(listItem?._id)}>{listItem?.name ? listItem?.name :'-'}</td>
                        <td className='cursor-pointer' onClick={() => goToRequest(listItem?._id)}>{listItem?.tenant_request_category?.length > 0 ? listItem?.tenant_request_category?.[0]?.name :'-'}</td>
                        <td className='cursor-pointer' onClick={() => goToRequest(listItem?._id)}>{listItem?.tenant_request_sub_category?.length > 0 ? listItem?.tenant_request_sub_category?.[0]?.name :'-'}</td>
                        <td className='cursor-pointer' >
                          <div className='d-flex  align-items-center '>
                            <Switch
                              onChange={(e) => {
                                updateReqestData(listItem?._id,!requestTableData[i].isEnable,i)
                              }}
                              handleDiameter={20}
                              height={24}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={listItem?.isEnable}
                              onColor='#00cf99'
                              offColor='#b0bec5'
                            />
                            <h5 className=' m-0 head-text ms-3'>{listItem?.isEnable === true ? 'Enabled' : 'Disabled'}</h5>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}

              </tbody>
            </table>
          </div>
           {/* pagination start*/}
           <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row mt-5'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                <div className='dataTables_paginate paging_simple_numbers' id='kt_ecommerce_sales_table_paginate'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* pagination end*/}
        </div>
      </div>
    </>
  )
}

export default TicketAssignments

import React, {useEffect, useState, useMemo} from 'react'
import {DateRangePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {FaTimesCircle} from 'react-icons/fa' // Import the clear icon
// import './style.scss'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'

interface DateStatus {
  date: moment.Moment
  status: string
}

interface DateRangePickerProps {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  onDatesChange: ({startDate, endDate}: {startDate: any; endDate: any}) => void
  autoSave: () => void
  from: any
  dateStatuses: DateStatus[]
  disabled:boolean
}

const RangePicker = ({
  startDate,
  endDate,
  onDatesChange,
  dateStatuses,
  autoSave,
  from,
  disabled
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<any>(null)
  const {flg, unitId, id} = useParams()
  const [key, setKey] = useState(0)

  const getStatusForDate = (day: moment.Moment): string | null => {
    const backendDateFormatted = day.format('YYYY-MM-DD')
    const foundDate = dateStatuses?.find(
      (date) => date.date.format('YYYY-MM-DD') === backendDateFormatted
    )

    if (foundDate) {
      return foundDate.status
      // logic here
      // if (foundDate.status == null) {
      //   return 'draft'
      // } else if (Number(foundDate.status) === 0) {
      //   return 'booked'
      // } else if (Number(foundDate.status) === 1) {
      //   return 'active'
      // } else if (Number(foundDate.status) === 2) {
      //   return 'expiring'
      // } else if (Number(foundDate.status) === 3) {
      //   return 'expired'
      // } else {
      //   return null // No special status
      // }
    } else {
      return 'default'
    }

    // return 'draft'; // Default status if not found
  }

  // 
  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    onDatesChange({startDate, endDate})
    setFocusedInput(null)

    if (startDate !== null && endDate !== null) {
      autoSave()
    }

    // if (startDate !== null && endDate !== null) {
    // let body: any
    //   if (flg == '1') {
    //     body = {
    //       startDate: moment(startDate).format('YYYY-MM-DD'),
    //       endDate:  moment(endDate).format('YYYY-MM-DD'),
    //       unitId: unitId,
    //     }
    //   } else {
    //     body = {
    //       startDate: moment(startDate).format('YYYY-MM-DD'),
    //       endDate:  moment(endDate).format('YYYY-MM-DD'),
    //       unitId: unitId,
    //       tenancyId: id,
    //     }
    //   }

    //   ApiPost(`corporate/tenancy/term/validator`, body)
    //     .then((res) => {
    //       if (res?.data?.data?.isAlreadyExist) {
    //         ErrorToast(
    //           'This unit is currently occupied during the selected term, please select another term date'
    //         )
    //         onDatesChange({ startDate: null, endDate: null});
    //         autoSave();

    //       } else {
    //         onDatesChange({ startDate, endDate });
    //         if (startDate !== null && endDate !== null) {
    //           autoSave();
    //          }
    //       }
    //     })
    //     .catch((err) => console.log('err', err))
    //     setFocusedInput(null); // Close the date picker after selecting start and end dates
    //   }
  }

  // 
  const isOutsideRange = (day: moment.Moment) => {
    // Disable past dates
    if (from === 'renew') {
      if (day.isBefore(moment(), 'day')) {
        return true
      }
    }

    // Disable active, booked, and expiring
    const status = getStatusForDate(day)

    const isInRange =
      startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]')
    if (!isInRange) {
      const isDisabled =
        status == 'booked' || status == 'active' || status == 'expiring' || status == 'draft'

      return isDisabled
    }
  }

  // 
  const clearDates = () => {
    setFocusedInput(null)
    onDatesChange({startDate: null, endDate: null})
    autoSave()
  }

  // 
  const dayRender = useMemo(() => {
    return (day: moment.Moment): React.ReactNode => {
      const isDisabled = isOutsideRange(day)
      const status = getStatusForDate(day)
      // console.log(status)
      const isInRange =
        startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]')

      let backgroundColor
      let color

      if (!isInRange) {
        switch (status) {
          case 'booked':
            backgroundColor = '#0099cc'
            color = 'white'
            break
          case 'active':
            backgroundColor = '#00b8a9'
            color = 'white'
            break
          case 'expiring':
            backgroundColor = '#f38513 '
            color = 'white'
            break
          case 'expired':
            backgroundColor = '#e46464 '
            color = 'white'
            break
          case 'draft':
            backgroundColor = '#b0b7c2 '
            color = 'black'
            break
          default:
            backgroundColor = 'inherit'
            break
        }
      }

      return (
        <div style={{backgroundColor, color, opacity: isDisabled ? 0.7 : 1}} className='day-render'>
          {day.format('D')}
        </div>
      )
    }
  }, [dateStatuses])

  useEffect(() => {}, []) 

  const isBothFieldsFilled = startDate !== null && endDate !== null

  return (
    <div className='custom-date-range-picker'>
      <DateRangePicker
        key={key}
        numberOfMonths={2}
        renderDayContents={(day: any) => dayRender(day)}
        startDate={startDate}
        startDateId='start_date_id'
        endDate={endDate}
        endDateId='end_date_id'
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        displayFormat='DD/MM/YYYY'
        isOutsideRange={isOutsideRange}
        // customInputIcon={isBothFieldsFilled && <FaTimesCircle onClick={clearDates} />}
        // onClose={clearDates}
        className='form-control form-control-solid mytest'
        readOnly
        disabled={disabled}
      />
      {/* <div style={focusedInput == null ? {display: 'none'} : {marginTop: '10px', display: 'flex'}}>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#b0b7c2 '}}></div>
          <span style={{marginLeft: '5px'}}>Draft</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#0099cc'}}></div>
          <span style={{marginLeft: '5px'}}>Booked</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#00b8a9'}}></div>
          <span style={{marginLeft: '5px'}}>Active</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#f38513'}}></div>
          <span style={{marginLeft: '5px'}}>Expiring</span>
        </div>
        <div style={{display: 'flex', marginRight: '20px'}}>
          <div style={{width: '20px', height: '20px', backgroundColor: '#e46464'}}></div>
          <span style={{marginLeft: '5px'}}>Expired</span>
        </div>
      </div> */}
    </div>
  )
}

export default RangePicker

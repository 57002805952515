import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import './style.scss'
import redCross from '../../../img/remove.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

interface ButtonProps {
  roleData: any
  from: any
  updateRoleDate: any
}

const HelpAndSupport = ({roleData, from, updateRoleDate}: ButtonProps) => {
  const [helpAndSupport, setHelpAndSupport] = useState<any>(roleData?.featureList?.help_support)

  useEffect(() => {
    let values = {...roleData}
    values.featureList.help_support = helpAndSupport
    updateRoleDate(values)
  }, [helpAndSupport])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-5'>
        <div className='table-responsive '>
          <table
            className='table align-start table-row-dashed fs-5 gy-5 '
            id='kt_ecommerce_sales_table'
          >
            <thead>
              {/* <th className='text-start min-w-25px'></th> */}
              <th className='text-start min-w-300px m-w-300'></th>
              <th className='text-start min-w-100px text-capitalize'>
                {' '}
                Allow
                <span>
                  <OverlayTrigger
                    placement='bottom'
                    delay={{show: 250, hide: 400}}
                    overlay={
                      <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                        {' '}
                        User can access the Help & Support Page from the main menu and raise a
                        support request ticket to Propertise Team (Same like the Tenant App)
                      </Tooltip>
                    }
                  >
                    <span>
                      {' '}
                      <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                    </span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
              <th className='text-start min-w-100px text-capitalize'></th>
            </thead>

            <tbody className='fw-semibold text-gray-600 role-test'>
              <tr>
                {/* <td className='text-start ps-0'>
                  <img
                    src={redCross}
                    height={16}
                    width={16}
                    className='me-2 cursor-pointer'
                    onClick={() => {}}
                  />
                </td> */}

                <td className='text-start ps-0 pe-5'>
                  <p className='user-main-head pe-2 mb-1 white-dark-theme-color fnt-700'>
                    Support Ticket{' '}
                  </p>
                  <p className='user-sub-head mb-2 light-dark-theme-color'>
                    <i>Raise a support request ticket to Propertise Team</i>
                  </p>
                </td>
                <td className='text-center ps-0'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={helpAndSupport?.support_ticket}
                      onChange={(e: any) => {
                        if (from !== 'view') {
                          const values = {...helpAndSupport}
                          values.support_ticket = e.target.checked
                          setHelpAndSupport(values)
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* <div id='' className='pt-5 mt-0 px-5'>
        <h3>Help & Support</h3>
        <p>
          - Support Ticket - 'Allow' Permission (Will allow the user to access the Help & Support
          Page from the main menu and raise a support request ticket to Propertise Team (Same like
          the Tenant App))
        </p>
      </div> */}
    </>
  )
}

export default HelpAndSupport

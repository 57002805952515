import React from 'react'
import {Modal} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'

interface SingleFileLoaderProps {
  isVisible: boolean
}

const SingleFileLoader: React.FC<SingleFileLoaderProps> = ({isVisible}) => {
  

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={isVisible}
        onHide={() => {}}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-3 px-lg-7'
          style={{
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='d-flex justify-content-center mt-4'>
            <CircularProgress style={{color: '#007a59'}} />
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <p className='white-dark-theme-color'>
              <b>Uploading</b>
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SingleFileLoader

import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../img/trash.png'
import greenEditPen from '../../../../../../img/edit-pen.png'
import {useForm} from 'react-hook-form'
import Swal from 'sweetalert2'
import moment from 'moment'
import {ErrorToast, SuccessToast} from '../../../../../../apiCommon/helpers/Toast'
import {Backdrop, CircularProgress} from '@mui/material'
import {ApiGet, ApiPost, Bucket, ApiDelete} from '../../../../../../apiCommon/helpers/API/ApiData'
import SelectPayorModal from '../../SelectPayorModal'
import {formatAmount} from '../../../../../../Utilities/utils'
import {downloadFile} from '../../../../../../Utilities/utils'
import GenerateInvoice from './GenerateInvoice'
import AttachmentModel from './AttachmentModel'
import share from '../../../../../../img/email.png'
import print from '../../../../../../img/printer.png'
import {useReactToPrint} from 'react-to-print'
import downloadImg from '../../../../../../img/download-img.png'

const ViewInvoice = () => {
  const navigate = useNavigate()
  const {flg, type, id, tenancyId, unitId}: any = useParams()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [showSelectPayor, setShowSelectPayor] = useState<any>(false)
  const [refreshChild, setRefreshChild] = useState<any>(true)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  const [incomeAPIDetails, setIncomeAPIDetails] = useState<any>()
  const [idToDelete, setIDToDelete] = useState<any>([])

  const [incomeData, setIncomeData] = useState<any>({
    selectedAccount: [],
    payorData: {},
    paymentMethod: null,
    paymentAccountId: '',
    notes: '',
    development: '',
    invoices: [],
    items: [
      {
        id: '',
        name: '',
        paymentItemListId: '',
        amount: '',
        quantity: '',
        VATAmount: '',
        VATPercentage: '',
        totalAmount: '',
        outstandingAmount: 0,
        amountPaid: 0,
        totalAmountPaid: 0,
        frontendStorage: {
          isList: true,
          latePaymentObject: {},
          isLatePaymentItem: false,
          itemUniqueId: new Date().getTime(),
          paymentItemId: null,
          isLatePaymentItemAdded: false,
        },
        isLatePaymentItem: false,
        latePaymentFees: 0,
        latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
        latePaymentObject: {},
        oldLatePaymentCycleList: [],
        nextLatePaymentCycle: null,
        // paymentSubItemStatus:2
      },
    ],
    attachments: [],
    reminders: [],
    paymentRecords: [],
    category: 0,
    categoryDateTime: null,
    latePaymentType: null,
    frontendStorage: {lateFeeForAll: {}},
    invoiceName: 'INVOICE',
    invoiceNo: '',
    date: '',
    dueDate: null,
    invoiceLogo: '',
    invoiceCompany: {
      trn: '',
      name: '',
      address: '',
      countryCode: '',
      phoneNumber: '',
      email: '',
      _id: '',
    },
    note: '',
    paymentSubItems: [],
    totalAmount: 0,
    saveStatus: 0,
    totalVAT: 0,
    subTotalAmount: 0,
    paymentId: null,
    status: 0,
    outstandingAmount: 0,
    itemDescriptions: [],
    showAccountDetails: true,
    showNotes: true,
  })

  const [chequeImages, setChequeImages] = useState<any>([])
  const [chargeTenant, setChargeTenant] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  setTimeout(() => {
    setIsLoading(false)
  }, 2000)

  setTimeout(() => {
    setOpen(false)
  }, 4000)

  let grandTotalAmount: any = 0
  let grandPaidAmount: any = 0
  let grandOutstandingAmount: any = 0

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  const addInvoice = async () => {
    setIsLoading(true)
    setOpen(true)

    const body = {
      _id: flg == '1' ? undefined : id,
      invoiceNo: incomeData?.invoiceNo ? incomeData?.invoiceNo : '',
      invoiceTemplateHeader: incomeData?.invoiceName ? incomeData?.invoiceName : '',
      paymentName: incomeData?.name ? incomeData?.name : '',
      note: incomeData?.note ? incomeData?.note : '',
      dueDate: incomeData?.dueDate ? incomeData?.dueDate : '',
      invoiceLogo: incomeData?.invoiceLogo ? incomeData?.invoiceLogo : '',
      invoiceCompany: incomeData?.invoiceCompany ? incomeData?.invoiceCompany : {},
      paymentMethod:
        selectedPaymentMethod?.length > 0 ? Number(selectedPaymentMethod?.[0]?.value) : '',

      formStatus: 1,
      totalAmount: incomeData?.totalAmount,
      totalVAT: incomeData?.totalVAT,
      subTotalAmount: incomeData?.subTotalAmount,
      paymentAccountId: incomeAPIDetails?.paymentAccountId,
      paymentId: incomeData?.paymentId,
      status: 0,
      outstandingAmount: Number(grandTotalAmount) - Number(grandPaidAmount),
      itemDescriptions:
        incomeData?.itemDescriptions?.length > 0 ? incomeData?.itemDescriptions : [],
      formStatusDateTime: new Date(),
      isCardVerificationEnable: false,
      isLock: false,
      chequeBankName: incomeData?.chequeBankName,
      chequeImages: Number(selectedPaymentMethod?.[0]?.value) == 1 ? chequeImages : [],

      latePaymentType: incomeData?.latePaymentType != undefined ? incomeData?.latePaymentType : '',
      paymentSubItems: incomeAPIDetails?.paymentSubItems,
      frontendStorage: {
        showAccountDetails: incomeData?.showAccountDetails,
        showNotes: incomeData?.showNotes,
        lateFeeForAll: incomeData?.frontendStorage?.lateFeeForAll,
      },

      tenantId:
        incomeData?.payorData?.tenant?.length > 0 ? incomeData?.payorData?.tenant?.[0]?._id : null,
      tenancyId: incomeData?.payorData?._id ? incomeData?.payorData?._id : null,
      unitId: incomeData?.payorData?.unit?.[0]?._id ? incomeData?.payorData?.unit?.[0]?._id : null,
      clusterId: incomeData?.payorData?.cluster?.[0]?._id
        ? incomeData?.payorData?.cluster?.[0]?._id
        : null,
      unitGroupId: incomeData?.payorData?.unit_group?.[0]?._id
        ? incomeData?.payorData?.unit_group?.[0]?._id
        : null,
      floorId: incomeData?.payorData?.floor?.[0]?._id
        ? incomeData?.payorData?.floor?.[0]?._id
        : null,
      communityId: incomeData?.payorData?.community?.[0]?._id
        ? incomeData?.payorData?.community?.[0]?._id
        : null,
      buildingId: incomeData?.payorData?.building?.[0]?._id
        ? incomeData?.payorData?.building?.[0]?._id
        : null,
      tenantCardId: incomeData?.tenantCardId,
      cardChargePayBy: chargeTenant,
      createdOriginType: 1,
    }
    ApiPost(`corporate/payment_invoice`, body)
      .then((res) => {
        SuccessToast('Invoice has been updated successfully..')
        getInvoiceById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
  }

  const getInvoiceById = async () => {
    try {
      const res = await ApiGet(`corporate/payment_invoice/${id}`)

      setChargeTenant(res?.data?.data?.cardChargePayBy)
      setChequeImages(res?.data?.data?.chequeImages)
      setValue('paymentName', res?.data?.data?.paymentName)
      setValue('invoiceNo', res?.data?.data?.invoiceNo)
      setValue('dueDate', res?.data?.data?.dueDate)
      setValue(
        'accountNumber',
        res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : ''
      )

      setValue(
        'paymentMethod',
        res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
      )

      let values: any = {
        // paymentName:  res?.data?.data?.paymentName,
        pdfURL: res?.data?.data?.pdfURL,
        pdfName: res?.data?.data?.invoiceTemplateHeader || 'INVOICE',
        invoiceName: res?.data?.data?.invoiceTemplateHeader || '',
        payorData: {},
        dueDate: res?.data?.data?.dueDate,
        categories: res?.data?.data?.categories,
        paymentMethod:
          res?.data?.data?.paymentMethod == null || res?.data?.data?.paymentMethod == ''
            ? ''
            : res?.data?.data?.paymentMethod,
        paymentAccountId: res?.data?.data?.paymentAccountId,
        note: res?.data?.data?.note,
        items: res?.data?.data?.paymentSubItems,
        attachments: res?.data?.data?.attachments,
        reminders: res?.data?.data?.payment_reminder,
        latePaymentType: res?.data?.data?.latePaymentType,
        frontendStorage: res?.data?.data?.frontendStorage
          ? res?.data?.data?.frontendStorage
          : {lateFeeForAll: {}},
        payment_invoice: res?.data?.data?.payment_invoice,
        payment_receipt: res?.data?.data?.payment_receipt,
        invoiceCompany: {
          trn: res?.data?.data?.invoiceCompany?.trn,
          name: res?.data?.data?.invoiceCompany?.name,
          address: res?.data?.data?.invoiceCompany?.address,
          countryCode: res?.data?.data?.invoiceCompany?.countryCode,
          phoneNumber: res?.data?.data?.invoiceCompany?.phoneNumber,
          email: res?.data?.data?.invoiceCompany?.email,
          _id: res?.data?.data?.invoiceCompany?._id,
        },
        _id: res?.data?.data?._id,
        date: res?.data?.data?.date ? res?.data?.data?.date : '',
        name: res?.data?.data?.paymentName,
        invoiceNo: res?.data?.data?.invoiceNo || '',
        invoiceLogo: res?.data?.data?.invoiceLogo,
        paymentSubItems: res?.data?.data?.paymentSubItems || '',
        totalAmount: res?.data?.data?.totalAmount,
        totalVAT: res?.data?.data?.totalVAt,
        subTotalAmount: res?.data?.data?.subTotalAmount,
        saveStatus: res?.data?.data?.saveStatus,
        status: 0,
        outstandingAmount: res?.data?.data?.outstandingAmount,
        itemDescriptions: res?.data?.data?.itemDescriptions,
        selectedAccount: res?.data?.data?.payment_account,
        showAccountDetails: res?.data?.data?.frontendStorage?.showAccountDetails,
        showNotes: res?.data?.data?.frontendStorage?.showNotes,
        formStatus: res?.data?.data?.formStatus,
        tenant: res?.data?.data?.tenant,
        paymentId: res?.data?.data?.paymentId,
        tenantCardId: res?.data?.data?.tenantCardId,
      }

      values?.items?.forEach((vl: any, ind: any) => {
        values.items[ind].outstandingAmount = vl.totalAmount - vl?.amountPaid
        values.items[ind].VATPercentage =
          values.items[ind].VATPercentage == '' ? 0 : values.items[ind].VATPercentage
      })

      let tenancyIdToCompare: any = res?.data?.data?.tenancyId

      switch (res?.data?.data?.paymentMethod) {
        case 1:
          setSelectedPaymentMethod([{value: '1', label: 'Cheque'}])
          break
        case 2:
          setSelectedPaymentMethod([{value: '2', label: 'Bank Transfer'}])
          break
        case 3:
          setSelectedPaymentMethod([{value: '3', label: 'Cash'}])
          break
        case 0:
          setSelectedPaymentMethod([{value: '0', label: 'Card'}])
          break
        default:
          setSelectedPaymentMethod([])
      }

      if (res?.data?.data?.tenancyId != null) {
        const body = {
          page: 1,
          limit: 10000,
          searchTenant: '',
          searchDevelopment: '',
          searchContractNo: '',
          searchUnitNo: '',
        }

        try {
          const tenancyRes = await ApiPost('corporate/tenancy/overview', body)
          tenancyRes?.data?.data?.tenancy_data?.forEach((rs: any) => {
            if (rs?._id === tenancyIdToCompare) {
              values.payorData = rs
            }
          })
        } catch (err) {
          console.error('Error fetching tenancy data:', err)
        }
      }

      setIncomeAPIDetails(res?.data?.data)

      

      setIncomeData(values)
      setIsSaveFirst(false)
      setRefreshChild(Date.now())

      setTimeout(() => {
        setIsSkeleton(false)
      }, 100)
    } catch (err: any) {
      console.error('Error:', err)
      ErrorToast(err?.message)
      setIsSkeleton(false)
    }
  }

  // 
  const deleteInvoice = async () => {
    setIsLoading(true)
    let url = `corporate/payment_invoice/${id}`

    await ApiDelete(`${url}`)
      .then((res: any) => {
        SuccessToast('Invoice has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsSkeleton(true)
    getInvoiceById()
  }, [])

  useEffect(() => {
    if (isSaveFirst) getInvoiceById()
  }, [isSaveFirst])

  useEffect(() => {
    if (autoSaveData != false) {
      addInvoice()
    }
  }, [autoSaveData])

  const invoiceRef = useRef(null)
  const handlePrint = useReactToPrint({
    onAfterPrint: () => {
      setPrint(false)
    },
    pageStyle: `
      @page {
        size: A4;
        margin: 0;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
        html, body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
        }
         .invoice-content {
          padding: 1mm 10mm !important;
        }

      }
    `,
    content: () => invoiceRef.current,
  })
  const [isPrint, setPrint] = useState<boolean>(false)
  const onPrintButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setPrint(true)
      setTimeout(() => {
        handlePrint()
      }, 300)
    },
    [handlePrint]
  )

  const checkIsDeletable = async () => {
    try {
      const res = await ApiGet(`corporate/payment_invoice/deletion_checker/${id}`)
      const {
        paymentCreditNoteData = [],
        paymentDebitNoteData = [],
        paymentReceiptData = [],
      } = res?.data?.data?.validation
      if (
        res?.data?.data?.isAbleToDelete &&
        paymentCreditNoteData?.length === 0 &&
        paymentReceiptData?.length === 0 &&
        paymentDebitNoteData?.length === 0
      ) {
        Swal.fire({
          html: ` 
                <div class='fs-3'> <b>Delete Invoice</b></div>
                 <div class='fs-4 fw-bold mt-4'>You are about to delete the invoice.</div>
                <div class='fs-4 fw-bold mt-5'> Deleting this invoice will remove it from the tenant’s records and will reduce the tenant's overall amount due, however, the financial record this invoice is linked to will remain.</div>
                 <div class='fs-4 fw-bold mt-5'>Are you sure you want to continue?</div>
                `,

          // icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#d54645',
          confirmButtonText: 'Delete',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b0b7c2',
          customClass: {
            // confirmButton: 'custom-confirm-button',
            // cancelButton: 'custom-cancel-button',
            popup: 'custom-popup test',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        }).then((res: any) => {
          if (res.isConfirmed) {
            deleteInvoice()
          }
        })
      } else {
        let noteLine = ''
        if (
          paymentCreditNoteData?.length !== 0 &&
          paymentReceiptData?.length !== 0 &&
          paymentDebitNoteData?.length !== 0
        ) {
          noteLine =
            'It seems that this invoice has a receipt, credit note, debit note linked to it and cannot be deleted. To delete this invoice you must first delete the receipt, credit note, debit note.'
        } else if (
          paymentCreditNoteData?.length == 0 &&
          paymentReceiptData?.length !== 0 &&
          paymentDebitNoteData?.length !== 0
        ) {
          noteLine =
            'It seems that this invoice has a receipt, debit note linked to it and cannot be deleted. To delete this invoice you must first delete the receipt, debit note.'
        } else if (
          paymentCreditNoteData?.length == 0 &&
          paymentReceiptData?.length == 0 &&
          paymentDebitNoteData?.length !== 0
        ) {
          noteLine =
            'It seems that this invoice has a debit note linked to it and cannot be deleted. To delete this invoice you must first delete the debit note.'
        } else if (
          paymentCreditNoteData?.length !== 0 &&
          paymentReceiptData?.length == 0 &&
          paymentDebitNoteData?.length !== 0
        ) {
          noteLine =
            'It seems that this invoice has a credit note, debit note linked to it and cannot be deleted. To delete this invoice you must first delete the credit note, debit note.'
        } else if (
          paymentCreditNoteData?.length !== 0 &&
          paymentReceiptData?.length !== 0 &&
          paymentDebitNoteData?.length == 0
        ) {
          noteLine =
            'It seems that this invoice has a receipt, credit note linked to it and cannot be deleted. To delete this invoice you must first delete the receipt, credit note.'
        } else if (
          paymentCreditNoteData?.length == 0 &&
          paymentReceiptData?.length !== 0 &&
          paymentDebitNoteData?.length == 0
        ) {
          noteLine =
            'It seems that this invoice has a receipt linked to it and cannot be deleted. To delete this invoice you must first delete the receipt.'
        }

        Swal.fire({
          html: ` 
                  <div class='fs-3'> <b>Unable To Delete Invoice!</b></div>
                  <div class='fs-4 fw-bold mt-5'>${noteLine}</div>
                  `,

          // icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#d54645',
          confirmButtonText: 'Okay',
          showCancelButton: false,
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b0b7c2',
          customClass: {
            confirmButton: 'custom-confirm-button',
            // cancelButton: 'custom-cancel-button',
            popup: 'custom-popup test',
          },
          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        })
      }
    } catch (error: any) {
      ErrorToast(error.message)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <form>
            <div id='' className='pt-0 mt-0'>
              {/* Header */}
              <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
                {' '}
                <div className='d-flex align-items-center mb-1'>
                  <div className='d-flex align-items-center'>
                    <span
                      className='rounded-circle cursor-pointer'
                      onClick={() => navigate('/finance-income')}
                    >
                      <img src={backArrow} height='14' width='14' />
                    </span>
                    <h2 className='m-0 head-text ms-2 text-capitalize'>{`Invoice`}</h2>
                  </div>
                </div>
              </div>

              {/* GRAND TOTAL AMOUNT */}
              {incomeData?.items?.map((itm: any, ind: any) => {
                grandTotalAmount = Number(grandTotalAmount) + Number(itm?.totalAmount)
              })}

              {/* PAID AND OUTSTANDING AMOUNT */}
              {incomeData?.payment_receipt?.map((rc: any, ind: any) => {
                grandPaidAmount = Number(grandPaidAmount) + Number(rc?.amountPaid)
              })}

              <div className='row mt-5'>
                <div className='col-6 px-5 text end'>
                  <div
                    className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 '}
                    style={{minHeight: '310px'}}
                  >
                    <div className='row'>
                      {/* Payment Details */}
                      <div className='col-12 px-0 mt-3'>
                        <div className='row me-4 ms-0 mt-0 '>
                          {/* invoice name */}
                          <div className='col-8'>
                            <span className='ms-3 white-dark-theme-color'>
                              <b>{incomeData?.name?.trim()?.length > 0 ? incomeData?.name : '-'}</b>
                            </span>
                          </div>
                          {/* form status */}
                          {/* {flg != '1' && ( */}
                          <div className='col-4 mt-0'>
                            {incomeAPIDetails?.formStatusDateTime ? (
                              <div className=' me-2'>
                                <div
                                  className={
                                    incomeAPIDetails?.formStatus == 0
                                      ? 'status draft-st ms-5 status-w-120'
                                      : 'status create-st ms-5 status-w-120'
                                  }
                                >
                                  {incomeAPIDetails?.formStatus == 0 ? 'Draft' : 'Created'}
                                </div>
                                <i className="text-dark white-dark-theme-color" style={{paddingRight: '3px', paddingLeft: '18px'}}>
                                  {' '}
                                  {incomeAPIDetails?.formStatusDateTime
                                    ? `${moment
                                        .utc(incomeAPIDetails?.formStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : ''}
                                </i>
                              </div>
                            ) : (
                              <div className='status not-saved ms-5 status-w-120 white-dark-theme-color'>-</div>
                            )}
                          </div>
                          {/* )} */}
                        </div>

                        <div className='row me-4 ms-0 mt-0'>
                          {/* Select Payee */}
                          <div className='col-12 px-5 mt-4'>
                            <div className='d-flex my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                  {' '}
                                  Payor
                                </label>
                              </h4>

                              <div className='d-flex expense-date'>
                                <span className='ms-1 white-dark-theme-color'>
                                  <b>{`${incomeData?.payorData?.tenant?.[0]?.firstName} ${incomeData?.payorData?.tenant?.[0]?.lastName}`}</b>
                                </span>
                              </div>
                            </div>
                          </div>

                          {/* Receiving Account */}
                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                  {' '}
                                  Receiving Account
                                </label>
                              </h4>

                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {incomeData?.selectedAccount?.length > 0
                                    ? `${incomeData?.selectedAccount?.[0]?.accountNumber} - ${incomeData?.selectedAccount?.[0]?.bankName}`
                                    : `-`}
                                </b>
                              </span>
                            </div>
                          </div>

                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                  {' '}
                                  Payment Method
                                </label>
                              </h4>

                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {selectedPaymentMethod ? selectedPaymentMethod?.[0]?.label : `-`}
                                </b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 '>
                  <div className='row'>
                    {/* Property & Lease Card*/}
                    <div className='col-12 px-5'>
                      <div
                        className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-4 px-6'}
                        style={{minHeight: '310px'}}
                      >
                        <div className='d-flex mt-2'>
                          <h2 className='top ps-0 white-dark-theme-color'>
                            <b>Property & Lease</b>
                          </h2>
                        </div>

                        <div className='row mx-1' style={{marginTop: '30px'}}>
                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                  {' '}
                                  Unit
                                </label>
                              </h4>

                              <span className='white-dark-theme-color'>
                                <b>
                                  {incomeData?.payorData?.unit?.[0]?.unitNo
                                    ? incomeData?.payorData?.unit?.[0]?.unitNo
                                    : '-'}
                                </b>
                              </span>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                  {' '}
                                  Development
                                </label>
                              </h4>

                              <span className='white-dark-theme-color'>
                                <b>
                                  {incomeData?.payorData?.community?.length > 0
                                    ? `${incomeData?.payorData?.community?.[0]?.name}`
                                    : incomeData?.payorData?.building?.length > 0
                                    ? `${incomeData?.payorData?.building?.[0]?.name}`
                                    : '-'}
                                </b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row mx-1'>
                          <div className='col-4 px-0 mt-4'>
                            <div className='mt-4 mb-2 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                    {' '}
                                    Contract No.
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className='white-dark-theme-color'>
                                    <b>
                                      {' '}
                                      {incomeData?.payorData?.contractNo
                                        ? `${incomeData?.payorData?.contractNo}`
                                        : '-'}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                    {' '}
                                    Lease Value
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className='white-dark-theme-color'>
                                    <b>
                                      {Number(incomeData?.payorData?.totalAmount) > 0 ? (
                                        <>AED {formatAmount(incomeData?.payorData?.totalAmount)}</>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-4 px-0 mt-4'>
                            <div className='my-4 d-flex align-items-end'>
                              <div>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                                    {' '}
                                    Payments Created
                                  </label>
                                </h4>

                                <div className='d-flex align-items-center'>
                                  <span className='white-dark-theme-color'>
                                    <b>
                                      {Number(incomeData?.payment?.length) > 0 ? (
                                        <>AED {formatAmount(incomeData?.payment?.length)}</>
                                      ) : (
                                        <>AED 0</>
                                      )}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Number(selectedPaymentMethod?.[0]?.value) == 1 && (
                <AttachmentModel
                  isEdit={false}
                  expenseData={{attachments: chequeImages}}
                  updateParent={(val: any) => {
                    console.log(val)
                    let values = {...incomeData}
                    values.attachments = val
                    setIncomeData(values)

                    if (flg != '1') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />
              )}

              {Number(selectedPaymentMethod?.[0]?.value) == 0 && (
                <div className='row'>
                  <div className='col-12 px-5'>
                    <div className={'card card-flush py-5 mt-2  pe-5 mb-5 px-6 '}>
                      {Number(selectedPaymentMethod?.[0]?.value) == 0 && (
                        <div className='d-flex  align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Charge From:
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {chargeTenant == 1
                                ? 'Charge Tenant'
                                : chargeTenant == 0
                                ? 'Deduct card fees from the amount received from the tenant'
                                : '-'}
                            </b>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className={'card card-flush py-5 mt-2  pe-5 mb-5 px-6 mx-2'}>
                <div className='d-flex my-4 align-items-center'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                      {' '}
                      Late Payment Fees
                    </label>
                  </h4>

                  <span className='ms-1 white-dark-theme-color'>
                    <b>
                      {incomeData?.latePaymentType == 0
                        ? 'Applied to selected Items'
                        : incomeData?.latePaymentType == 1
                        ? 'Applied to all items'
                        : incomeData?.latePaymentType == 2
                        ? 'Not Applied'
                        : `-`}
                    </b>
                  </span>
                </div>
              </div>

              <div className='d-flex align-items-center px-2'>
                <button
                  className='btn btn-sm fw-bold ps-3 pe-4 text-white status-w-110 blue-btn'
                  onClick={() => {
                    navigate(`/finance-income/share/view-invoice/${id}`)
                  }}
                >
                  <img
                    src={share}
                    height={18}
                    width={18}
                    className="me-4"
                  />{' '}
                  {'  '}
                  Share
                </button>

                <button
                  className='btn btn-sm fw-bold px-4 ms-3 text-white status-w-110 print-btn'
                  onClick={onPrintButtonClick}
                >
                  <img
                    src={print}
                    height={18}
                    width={18}
                    className="me-4"
                  />{' '}
                  {'  '}
                  Print
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold  ms-auto red-hollow-btn ps-3 '
                  disabled={isLoading || isViewOnly}
                  onClick={checkIsDeletable}
                >
                  <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold blue-hollow-btn ms-5 btn-fit-content ps-2'
                  style={{width: 'fit-content'}}
                  onClick={() => {
                    downloadFile(
                      `${Bucket}${incomeData?.pdfURL}`,
                      `${incomeData?.invoiceNo}.${incomeData?.pdfURL?.split('.').pop()}`
                    )
                  }}
                >
                  <img src={downloadImg} height={18} width={18} className='me-3' />
                  Download
                </button>
                <img
                  src={greenEditPen}
                  height={18}
                  width={18}
                  className='ms-5 cursor-pointer'
                  onClick={() => {
                    navigate(`/finance-income/edit-invoice/3/${id}`)
                  }}
                />
              </div>

              <div className='invoice-container'>
                <GenerateInvoice
                  isPrint={isPrint}
                  innerRef={invoiceRef}
                  incomeData={incomeData}
                  incomeAPIDetails={incomeAPIDetails}
                  setIncomeAPIDetails={setIncomeAPIDetails}
                  idToDelete={idToDelete}
                  setIDToDelete={setIDToDelete}
                  updateIncomeData={(updatedData: any) => setIncomeData(updatedData)}
                  register={register}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  trigger={trigger}
                  autoSaveData={autoSaveData}
                  setAutoSaveData={setAutoSaveData}
                  open={open}
                  setOpen={setOpen}
                  editMode={false}
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {showSelectPayor && incomeData && (
        <SelectPayorModal
          show={showSelectPayor}
          handleClose={() => {
            setShowSelectPayor(false)
          }}
          updateDetails={(data: any) => {
            let values = {...incomeData}
            values.payorData = data

            setIncomeData(values)

            setRefreshChild(Date.now())

            if (flg == '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          expData={incomeData}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ViewInvoice

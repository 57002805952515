import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import noData from '../../../../img/NoData1.svg'
import announce from '../../../../img/annouce.png'
import active from '../../../../img/active.png'
import draftTen from '../../../../img/draft.png'
import inActive from '../../../../img/expired.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import trashImg from '../../../../img/trash.png'
import create from '../../../../img/create.png'
import swal from 'sweetalert2'
import { ApiDelete, ApiGet, ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { MultiSelect } from 'react-multi-select-component'

const Announcement = () => {
  const getActiveTab: any = localStorage.getItem('announcementActiveTab')
  const tab = JSON.parse(getActiveTab)

  const getAnnouncementStatus: any = localStorage.getItem('announcementStatus')
  const announcementSt = JSON.parse(getAnnouncementStatus)

  const getAllTabAnncouncemtnFilter: any = localStorage.getItem('announcementAllFilters')
  const allTabFilters = JSON.parse(getAllTabAnncouncemtnFilter)

  const getActiveTabAnncouncemtnFilter: any = localStorage.getItem('announcementActiveFilters')
  const activeTabFilters = JSON.parse(getActiveTabAnncouncemtnFilter)

  const getDraftTabAnncouncemtnFilter: any = localStorage.getItem('announcementDraftFilters')
  const draftTabFilters = JSON.parse(getDraftTabAnncouncemtnFilter)

  const getInactiveTabAnncouncemtnFilter: any = localStorage.getItem('announcementInactiveFilters')
  const inactiveTabFilters = JSON.parse(getInactiveTabAnncouncemtnFilter)

  const getAnnouncementTablePage: any = localStorage.getItem('announcementTablePage')
  const announcementTablePage = JSON.parse(getAnnouncementTablePage)

  const [activeTab, setActiveTab] = useState<any>(tab ? tab : 'all')
  const {pathname} = useLocation()

  const [allAnnouncement, setAllAnnouncement] = useState<any>(allTabFilters?.announcement ? allTabFilters?.announcement : '')
  const [activeAnnouncement, setActiveAnnouncement] = useState<any>(activeTabFilters?.announcement ? activeTabFilters?.announcement :'')
  const [draftAnnouncement, setDraftAnnouncement] = useState<any>(draftTabFilters?.announcement ? draftTabFilters?.announcement :'')
  const [inactiveAnnouncement, setInactiveAnnouncement] = useState<any>(inactiveTabFilters?.announcement ? inactiveTabFilters?.announcement :'')

  const [allSearchDevelopment, setAllSearchDevelopment] = useState<any>(activeTabFilters?.serachDevelopment ? activeTabFilters?.serachDevelopment :'')
  const [activeSearchDevelopment, setActiveSearchDevelopment] = useState<any>(activeTabFilters?.serachDevelopment ? activeTabFilters?.serachDevelopment :'')
  const [draftSearchDevelopment, setDraftSearchDevelopment] = useState<any>(activeTabFilters?.serachDevelopment ? activeTabFilters?.serachDevelopment :'')
  const [inactiveSearchDevelopment, setInactiveSearchDevelopment] = useState<any>(activeTabFilters?.serachDevelopment ? activeTabFilters?.serachDevelopment :'')

  const [checkedValues, setCheckedValues] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(announcementTablePage ? announcementTablePage : 1)
  const [announcementCount, setAannouncementCount] = useState<any>()
  const [limit, setLimit] = useState<any>(25)
  const [announcementStatus, setAnnouncementStatus] = useState<any>(announcementSt ? announcementSt : null)
  const [statusOptions, setStatusOptions] = useState<any>([
    { value: 0, label: 'Draft' },
    { value: 1, label: 'Active' },
    { value: 2, label: 'In-active' },
  ])

  const [annouceDropDownStatus, setAnnounceDropdownStatus] = useState<any>(allTabFilters?.status ? allTabFilters?.status :[])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any>(false)

  // 
  const goToAnnouncement = (v: any) => {
    if (v?.saveStatus == 0) navigate(`/create-announcement/2/${v?._id}`)
    else if (v?.saveStatus == 1 || v?.saveStatus == 2) navigate(`/create-announcement/3/${v?._id}`)
  }

  let totalSelected: any = 0

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    announcementCountSummary()
    announcementData(event.selected + 1)
  }

  // 
  const announcementCountSummary = () => {
    ApiGet('corporate/announcement/count')
      .then((res) => {
        setAannouncementCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // 
  const announcementData = async (pageVal:any) => {
    let status: any = []

    annouceDropDownStatus?.map((ann: any, ind: any) => {
      status[ind] = ann?.value
    })

    let body = {
      page: pageVal,
      limit: limit,
      searchDevelopment: activeTab === 'all' ? allSearchDevelopment : activeTab === 'active' ? activeSearchDevelopment : activeTab === 'draft' ? draftSearchDevelopment : activeTab === 'in-active' ? inactiveSearchDevelopment : '',
      searchAnnouncement: activeTab === 'all' ? allAnnouncement : activeTab === 'active' ? activeAnnouncement : activeTab === 'draft' ? draftAnnouncement : activeTab === 'in-active' ? inactiveAnnouncement : '',
      saveStatus: activeTab == 'all' ? status : announcementStatus,
    }

    await ApiPost('corporate/announcement/overview', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.announcement_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setTableData(res?.data?.data?.announcement_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteAnnouncement = () => {
    setIsLoading(true)
    let annIds: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        annIds[i] = tableData[ind]?._id
        i++
      }
    })

    const body = {
      announcementIds: annIds,
    }

    ApiPost(`corporate/announcement/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        announcementData(page)
        announcementCountSummary()

        let values = [...checkedValues]

        for (let i = 0; i < tableData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    setPage(1)
    announcementCountSummary()
    announcementData(1)
  }, [allSearchDevelopment, activeSearchDevelopment, draftSearchDevelopment, inactiveAnnouncement, allAnnouncement, activeAnnouncement, draftAnnouncement, inactiveAnnouncement, announcementStatus, annouceDropDownStatus])

  useEffect(() => {
    localStorage.setItem('announcementActiveTab', JSON.stringify(activeTab))
    localStorage.setItem('announcementStatus', JSON.stringify(announcementStatus))
    localStorage.setItem('announcementTablePage', JSON.stringify(page))
  }, [activeTab,announcementStatus, page])

  useEffect(() => {
    if (pathname === '/announcements' && activeTab === 'all') {
      const filters = {
        announcement: allAnnouncement,
        serachDevelopment: allSearchDevelopment,
        status:annouceDropDownStatus,
      }
      localStorage.setItem('announcementAllFilters', JSON.stringify(filters))

    } else if (pathname === '/announcements' && activeTab === 'in-active') {
      const filters = {
        announcement: inactiveAnnouncement,
        serachDevelopment: inactiveSearchDevelopment,
      }
      localStorage.setItem('announcementInactiveFilters', JSON.stringify(filters))

    } else if (pathname === '/announcements' && activeTab === 'active') {
      const filters = {
        announcement: activeAnnouncement,
        serachDevelopment: activeSearchDevelopment,
      }
      localStorage.setItem('announcementActiveFilters', JSON.stringify(filters))

    } else if (pathname === '/announcements' && activeTab === 'draft') {
      const filters = {
        announcement: draftAnnouncement,
        serachDevelopment: draftSearchDevelopment,
      }
      localStorage.setItem('announcementDraftFilters', JSON.stringify(filters))

    }

  }, [
    activeTab,
    annouceDropDownStatus,
    allAnnouncement,
    activeAnnouncement,
    draftAnnouncement,
    inactiveAnnouncement,
    allSearchDevelopment,
    activeSearchDevelopment,
    draftSearchDevelopment,
    inactiveSearchDevelopment,
  ])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    width: '180px'
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  return (
    <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
      <div className='row'>
        <div className='col-12'>
          <h1 className='page-heading  m-0'>
            <b>Announcements</b>
          </h1>
        </div>
      </div>

      <div className='d-flex flex-lg-row flex-column w-100 align-items-lg-end '>
        <div className='col-lg-10 d-flex' style={{ gap: '20px' }}>

          {/* All tab */}
          <div className=''>
            <div
              className={
                activeTab == 'all'
                  ? 'tenancy-active-card d-flex align-items-center'
                  : 'tenancy-green-card d-flex align-items-center'
              }
              onClick={() => {
                setActiveTab('all')
                // setPage(1)
                setAnnouncementStatus(null)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={announce}
                  height={22}
                  width={22}
                  className='mx-3'
                />
              </div>
              <div>
                <h2 className='mb-0 text-white'>
                  {announcementCount?.all_count
                    ? announcementCount?.all_count?.toLocaleString('en-US', {})
                    : 0}
                </h2>
                <h5 className='mb-0 text-white'>All</h5>
              </div>
            </div>
            {activeTab == 'all' && <div className='active-filter'></div>}
          </div>
          {/* Active Tab */}
          <div className=''>
            <div
              className={
                activeTab == 'active'
                  ? 'tenancy-active-card d-flex align-items-center'
                  : 'tenancy-green-card d-flex align-items-center'
              }
              onClick={() => {
                setActiveTab('active')
                // setPage(1)
                setAnnouncementStatus([1])
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={active}
                  height={21}
                  width={21}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                <h2 className='mb-0 text-white'>
                  {announcementCount?.active_count
                    ? announcementCount?.active_count?.toLocaleString('en-US', {})
                    : 0}
                </h2>

                <h5 className='mb-0 text-white'>Active</h5>
              </div>
            </div>
            {activeTab == 'active' && <div className='active-filter'></div>}
          </div>
          {/* Draft Tab */}
          <div className=''>
            <div
              className={
                activeTab == 'draft'
                  ? 'tenancy-active-card d-flex align-items-center'
                  : 'tenancy-green-card d-flex align-items-center'
              }
              onClick={() => {
                setActiveTab('draft')
                // setPage(1)
                setAnnouncementStatus([0])
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={draftTen}
                  height={26}
                  width={26}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                <h2 className='mb-0 text-white'>
                  {announcementCount?.draft_count
                    ? announcementCount?.draft_count?.toLocaleString('en-US', {})
                    : 0}
                </h2>

                <h5 className='mb-0 text-white'>Draft</h5>
              </div>
            </div>
            {activeTab == 'draft' && <div className='active-filter'></div>}
          </div>
          {/* Inactive Tab */}
          <div className=''>
            <div
              className={
                activeTab == 'in-active'
                  ? 'tenancy-active-card d-flex align-items-center '
                  : 'tenancy-green-card d-flex align-items-center'
              }
              onClick={() => {
                // setPage(1)
                setActiveTab('in-active')
                setAnnouncementStatus([2])
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={inActive}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                <h2 className='mb-0 text-white'>
                  {announcementCount?.inactive_count
                    ? announcementCount?.inactive_count?.toLocaleString('en-US', {})
                    : 0}
                </h2>

                <h5 className='mb-0 text-white'>Inactive</h5>
              </div>
            </div>
            {activeTab == 'in-active' && <div className='active-filter'></div>}
          </div>
        </div>
        <div className='col-lg-2 mt-2-lg d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn ps-2'
            onClick={() => {
              navigate('/create-announcement/1')
            }}
          >
            <img src={create} height={20} width={20} className='me-5' /> Create
          </button>
        </div>
      </div>
      <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
        <div className='mb-2 col-md-1 pe-5 xyz'>
          <label className='head-text' style={{ fontWeight: '600' }}>
            {' '}
            Search{' '}
          </label>
        </div>

        {activeTab == 'all' && (
          <>
            <div className='mb-2 col-md-2 pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Announcement'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Announcement' }}
                  value={allAnnouncement}
                  onChange={(e: any) => setAllAnnouncement(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Announcement'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2 pe-5  xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'search' }}
                  value={allSearchDevelopment}
                  onChange={(e: any) => setAllSearchDevelopment(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className='mb-2 col-md-2 pe-5 test property multi-select-white-bg'>
              <div
                className={`multi-select-container ${annouceDropDownStatus.length === 0 ? 'no-value announce' : 'has-value'
                  }`}
              >
                <MultiSelect
                  options={statusOptions}
                  value={annouceDropDownStatus}
                  onChange={setAnnounceDropdownStatus}
                  labelledBy='Status'
                  overrideStrings={{
                    allItemsAreSelected: 'All Statuses', // Custom text for when all items are selected
                  }}
                  valueRenderer={CustomValueRenderer}
                />
              </div>
            </div>
          </>

        )}

        {activeTab == 'active' && (
          <>
            <div className='mb-2 col-md-2 pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Announcement'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Announcement' }}
                  value={activeAnnouncement}
                  onChange={(e: any) => setActiveAnnouncement(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Announcement'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2  pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'search' }}
                  value={activeSearchDevelopment}
                  onChange={(e: any) => setActiveSearchDevelopment(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </>

        )}

        {activeTab == 'draft' && (
          <>
            <div className='mb-2 col-md-2 pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Announcement'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Announcement' }}
                  value={draftAnnouncement}
                  onChange={(e: any) => setDraftAnnouncement(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Announcement'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2 pe-5  xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'search' }}
                  value={draftSearchDevelopment}
                  onChange={(e: any) => setDraftSearchDevelopment(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </>

        )}

        {activeTab == 'in-active' && (
          <>
            <div className='mb-2 col-md-2 pe05 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Announcement'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Announcement' }}
                  value={inactiveAnnouncement}
                  onChange={(e: any) => setInactiveAnnouncement(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Announcement'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2 pe-5  xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'search' }}
                  value={inactiveSearchDevelopment}
                  onChange={(e: any) => setInactiveSearchDevelopment(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </>

        )}

        <div className={activeTab == 'all' ? 'col-md-5 my-0 px-0' : 'col-md-7 my-0 px-0'}>
          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className=''>
            <div className='d-flex justify-content-end align-items-end'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex ms-auto'>
                    <h4 className='my-2' style={{ color: 'black' }}>
                      <label className='labl-gry light-dark-theme-color'>
                        {' '}
                        <i>Total Selected </i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold red-hollow-btn m-0 ps-2'
                    onClick={() => {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Delete Announcements </b></div>
                            <div class='fs-5 mt-4'> Are you sure you want to delete the selected announcements? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          // reverseButtons: true, 
                        })
                        .then((res) => {
                          if (res.isConfirmed) {
                            deleteAnnouncement()
                          }
                        })
                    }}
                    disabled={isLoading}
                  >
                    <img src={trashImg} height={20} width={20} className='me-5' />{' '}
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div className='card card-flush py-5  px-7'>
        <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
          <div className='table-responsive '>
            <table
              className='table align-middle table-row-dashed fs-8 gy-5 '
              id='kt_ecommerce_sales_table'
            >
              <thead className='fs-7'>
                <th className='text-start min-w-25px test'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                    <input
                      className='form-check-input cursor-pointer'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={
                        totalSelected == tableData?.length && tableData?.length != 0 ? true : false
                      }
                      onChange={(e: any) => {
                        let values = [...checkedValues]
                        console.log(e)
                        if (e.target.checked) {
                          for (let i = 0; i < tableData?.length; i++) {
                            values[i] = true
                          }
                        } else {
                          for (let i = 0; i < tableData?.length; i++) {
                            values[i] = false
                          }
                        }
                        setCheckedValues(values)
                      }}
                    />
                  </div>
                </th>
                <th className='text-start min-w-125px ps-0'>Announcement</th>
                {activeTab == 'all' && (
                  <th className='text-center min-w-125px'>
                    Status
                  </th>
                )}
                <th className='text-start min-w-125px ps-0'>Development</th>
                <th className='text-start min-w-125px ps-0'>Recipients</th>
                {activeTab !== 'draft' && <th className='text-start min-w-100px ps-0'>Viewed</th>}
                <th className='text-start min-w-125px ps-0'>Validity</th>
                <th className='text-start min-w-150px ps-0'>Created By</th>
                <th className='text-start min-w-100px ps-0'>Last Updated</th>
              </thead>
              <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                {tableData?.length ? (
                  tableData?.map((announcementItem: any, i: any) => {
                    return (
                      <tr>
                        <td className='text-start ps-0 test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                            <input
                              className='form-check-input cursor-pointer'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={checkedValues[i] == true ? true : false}
                              onChange={(e: any) => {
                                const values = [...checkedValues]
                                values[i] = !values[i]
                                setCheckedValues(values)
                              }}
                            />
                          </div>
                        </td>
                        <td
                          data-kt-ecommerce-order-filter='order_id'
                          className='text-start cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                        >
                        {announcementItem?.title?.length > 40 ? `${announcementItem?.title?.substring(0,40)}...`: (announcementItem?.title ||'-')}
                        </td>
                        {activeTab == 'all' && (
                          <td
                            className='text-center cursor-pointer'
                            onClick={() => goToAnnouncement(announcementItem)}
                          >
                            <div
                              className={
                                announcementItem?.saveStatus === 0
                                  ? 'status booked-bar status-w-110 mx-auto'
                                  : announcementItem?.saveStatus === 1
                                    ? 'status active-bar status-w-110 mx-auto'
                                    : announcementItem?.saveStatus === 2
                                      ? 'status expired-bar status-w-110 mx-auto'
                                      : ''
                              }
                            >
                              {announcementItem?.saveStatus === 0
                                ? 'Draft'
                                : announcementItem?.saveStatus === 1
                                  ? 'Active'
                                  : announcementItem?.saveStatus === 2
                                    ? 'Inactive'
                                    : ''}
                            </div>
                          </td>
                        )}
                        <td
                          className='text-start cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                        >
                          {announcementItem?.development[0]?.name
                            ? announcementItem?.development[0]?.name
                            : '-'}
                        </td>
                        <td
                          className='text-start cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                        >
                          {' '}
                          {announcementItem?.tenantAppUserIdCount != undefined
                            ? announcementItem?.tenantAppUserIdCount
                            : '-'}
                        </td>
                        {activeTab !== 'draft' && (
                          <td
                            className='text-start cursor-pointer ps-0'
                            onClick={() => goToAnnouncement(announcementItem)}
                          >
                            {' '}
                            {announcementItem?.viewTenantAppUserIdCount != undefined
                              ? announcementItem?.viewTenantAppUserIdCount
                              : '-'}
                          </td>
                        )}
                        <td
                          className='text-start  cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                          data-order='2022-09-11'
                        >
                          {announcementItem?.startDate
                            ? moment(announcementItem?.startDate).format('DD.MM.YYYY')
                            : '-'}{' '}
                          {' - '}
                          {announcementItem?.endDate
                            ? moment(announcementItem?.endDate).format('DD.MM.YYYY')
                            : '-'}
                        </td>
                        <td
                          className='py-3 ps-0 cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                        >
                          <p className='my-0'>
                            {announcementItem?.createdBy?.[0] &&
                              `${announcementItem?.createdBy?.[0]?.firstName} ${announcementItem?.createdBy?.[0]?.lastName} - `}
                          </p>
                          <p className='my-0'>
                            {announcementItem?.createdAt
                              ? `${moment(announcementItem?.createdAt).format(
                                'DD.MM.YYYY hh:mm A'
                              )}`
                              : '-'}
                          </p>
                        </td>
                        <td
                          className='py-3 ps-0 cursor-pointer ps-0'
                          onClick={() => goToAnnouncement(announcementItem)}
                        >
                          <p className='my-0'>
                            {announcementItem?.updatedBy?.[0] &&
                              `${announcementItem?.updatedBy?.[0]?.firstName} ${announcementItem?.updatedBy?.[0]?.lastName} - `}
                          </p>
                          <p className='my-0'>
                            {announcementItem?.updatedAt
                              ? ` ${moment(announcementItem?.updatedAt).format(
                                'DD.MM.YYYY hh:mm A'
                              )}`
                              : '- -'}
                          </p>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* pagination start*/}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row mt-5'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* pagination end*/}
        </div>
      </div>
    </div>
  )
}

export default Announcement

import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'

const SubHead = (props: any) => {
  return (
    <>
      <div className='card p-5 mt-5'>
        <Row>
          <Col md={4}>
            <div className=''>{bodyData(props?.one)}</div>
          </Col>
          <Col md={4}>
            <div className=''>{bodyData(props?.two)}</div>
          </Col>
          <Col md={4}>
            <div className=''>{bodyData(props?.three)}</div>
          </Col>
        </Row>
      </div>
    </>
  )
}

function bodyData(props: any) {
  return (
    <>
      <h5 className='text-muted m-0'>
        {props?.title}
        <span className='fw-semibold mx-5 text-uppercase' style={{color: 'black'}}>
          {props?.data}
        </span>
      </h5>
    </>
  )
}

export default SubHead

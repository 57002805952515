import React, {useEffect} from 'react'
import {useState} from 'react'
import './style.scss'
import {Modal} from 'react-bootstrap'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {ApiPost, ApiGet, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import redCross from '../../../../../img/remove.png'
import arrow from '../../../../../img/circular-left-.png'
import Select from 'react-select'

interface ButtonProps {
  show: any
  handleClose: any
  updateList: any
  totalSelectedRecords: any
  selectedRecords: any
  records: any
  type: any
}

const ChangeListModal = ({
  show,
  handleClose,
  updateList,
  selectedRecords,
  records,
  totalSelectedRecords,
  type,
}: ButtonProps) => {
  const [selectedList, setSelectedList] = useState<any>('')

  const [isLoading, setIsLoading] = useState<any>(false)
  const [list, setList] = useState<any>([])

  const [selectedMain, setSelectedMain] = useState<any>('')
  const [mainServicesOptions, setMainServicesOptions] = useState<any>([])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      // width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getServicesListForDropdown = async () => {
    await ApiGet(`corporate/maintenance_service_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (type == 'main') getServicesListForDropdown()
  }, [])

  //
  const getMainServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_main_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (type == 'sub') getMainServicesForDropdown()
  }, [])

  const updateServices = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    selectedRecords?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = records[ind]?._id
        i++
      }
    })

    let body: any
    let url: any = ''
    if (type == 'main') {
      body = {
        maintenanceServiceListId: selectedList,
        maintenanceMainServiceIds: ids,
      }

      url = `corporate/maintenance_main_service/services`
    } else {
      body = {
        maintenanceMainServiceId: selectedMain,
        maintenanceSubServiceIds: ids,
      }
      url = `corporate/maintenance_sub_service/services`
    }

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        updateList()
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body px-7 pt-lg-3 pb-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div
            className='d-flex align-items-center pb-4 mt-2'
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <h2 className='pt-2 mb-0'>
              <b>{type == 'main' ? 'Change List' : 'Change Main Service'}</b>
            </h2>
            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                onClick={() => {
                  handleClose()
                }}
                disabled={isLoading}
              >
                <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} /> Cancel
              </button>
            </div>
          </div>

          <div className='row mx-1'>
            <div className='col-12 px-5  ps-0 my-5'>
              <h3 className='pt-2 mb-0'>
                <b>Total Selected: {totalSelectedRecords} </b>
              </h3>
            </div>
          </div>

          <div className='row mx-1'>
            <div className='col-12 px-5  ps-0 mb-5 mt-0'>
              <p className='fs-4' style={{color: '#9797ae', fontWeight: '500'}}>
                {' '}
                <i>
                  {`Select the ${
                    type == 'main' ? 'list' : 'main service'
                  } you want to add these services to. Once selected, all past ${
                    type == 'main' ? 'lists' : 'services'
                  }
                  will be replaced with the selected one.`}
                </i>
              </p>
            </div>
          </div>

          {type == 'main' ? (
            <div className='row mx-3'>
              <div className='col-10 px-5  ps-0 mb-5 mt-0'>
                <div className='d-flex '>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry required'>
                      {' '}
                      List
                    </label>
                  </h4>
                  <h4 style={{width: '-webkit-fill-available'}}>
                    <Select
                      styles={customStyles}
                      isSearchable={false}
                      options={list}
                      name='sortType'
                      onChange={(e: any) => {
                        setSelectedList(e.value)
                      }}
                      placeholder={'Select List'}
                    />
                  </h4>
                </div>
              </div>
            </div>
          ) : (
            <div className='row mx-3'>
              <div className='col-10 px-5  ps-0 mb-5 mt-0'>
                <div className='d-flex '>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry required'>
                      {' '}
                      Main Service
                    </label>
                  </h4>
                  <h4 style={{width: '-webkit-fill-available'}}>
                    <Select
                      styles={customStyles}
                      isSearchable={false}
                      options={mainServicesOptions}
                      name='sortType'
                      onChange={(e: any) => {
                        setSelectedMain(e.value)
                      }}
                      placeholder={'Select Main Service'}
                    />
                  </h4>
                </div>
              </div>
            </div>
          )}

          <div className='d-flex mx-3 justify-content-center mt-5 mb-10'>
            <button
              type='submit'
              className={`btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10 status-w-250 justify-content-center`}
              disabled={
                isLoading ||
                (type == 'main' && selectedList == '') ||
                (type == 'sub' && selectedMain == '')
              }
              onClick={() => updateServices()}
            >
              <img src={arrow} height={17} width={17} style={{marginRight: '10px'}} />
              {type == 'main' ? 'Update List' : 'Update Main Service'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ChangeListModal

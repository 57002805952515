import React, {useState, useCallback, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import noData from '../../../../img/NoData1.svg'
import ownerIcon from '../../../../img/owners-profile-blue.png'

interface ButtonProps {
  show: any
  handleClose?: any
  listData?: any
}

const OwnerListModel = ({show, handleClose, listData}: ButtonProps) => {
  console.log(listData)
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        dialogClassName='modal-dialog modal-dialog-centered mw-650px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
      >
        <div
          className='modal-body p-0'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 15px 0px #516176',
            minHeight: '200px',
            // minHeight:'auto',
            maxHeight: '550px',
            overflow: 'auto',
            position: 'relative',
            // paddingBottom:'100px',
          }}
        >
          <div className='row px-7 py-5 m-0 pb-7' style={{position: 'sticky', top: 0, background: '#fff', zIndex: 2}}>
            <div className='col-md-12 px-0'>
              <div className='d-flex'>
                <h2 className='head-text mb-0 flex-grow-1'>
                  <b>Owner List</b>
                </h2>

                <label
                  className='head-text fs-5 cursor-pointer'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
          </div>

          <div className='row px-7 m-0 mb-3'>
            <div className='col-md-12 px-0'>
              <h4 className='head-text mb-0'>Total: {listData?.length}</h4>
            </div>
          </div>

          <div className='row px-7 mx-0 px-0 mt-0' style={{position: 'relative'}}>
            <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
              <div className='table-responsive'>
                <table className='table align-middle table-row-dashed fs-8 gy-5 ' id='kt_ecommerce_sales_table'>
                  <thead className='fs-7'>
                    <th className='text-start min-w-50px ps-0'></th>
                    <th className='text-start min-w-100px ps-0'>ID</th>
                    <th className='text-start min-w-100px ps-3'>First Name</th>
                    <th className='text-start min-w-100px ps-3'>Last Name</th>
                    <th className='text-start min-w-100px ps-3'>Ownership</th>
                    <th className='text-start min-w-100px ps-3'>Email</th>
                    <th className='text-start min-w-100px ps-3 '>Mobile No.</th>
                  </thead>
                  <tbody className='fw-semibold head-text'>
                    {listData?.length > 0 ? (
                      listData?.sort((a:any,b:any) => a.id - b.id).map((listItem: any, i: any) => {
                        return (
                          <tr>
                            <td className={`text-start cursor-pointer ps-0 m-0 p-0 min-w-50px`}>
                              <img src={ownerIcon} height={30} width={30} className='m-0 p-0' />
                            </td>
                            <td className='text-start cursor-pointer ps-0'>{listItem?.id ? listItem?.id : '-'}</td>
                            <td className='text-start cursor-pointer ps-3'>{listItem?.firstName ? listItem?.firstName : '-'}</td>
                            <td className='text-start cursor-pointer ps-3'>{listItem?.lastName ? listItem?.lastName : '-'}</td>
                            <td className='text-start cursor-pointer ps-3'>
                              {listItem?.ownershipPercentage ? `${listItem?.ownershipPercentage}%` : '-'}
                            </td>
                            <td className='text-start cursor-pointer ps-3'>{listItem?.email ? listItem?.email : '-'}</td>
                            <td className='text-start cursor-pointer ps-3'>{listItem?.countryCode && listItem?.phoneNumber ? `+${listItem?.countryCode}${listItem?.phoneNumber}` : '-'}</td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default OwnerListModel

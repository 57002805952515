import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast } from '../../../apiCommon/helpers/Toast';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

function Facility() {
    // const [formData, setFormData] = useState<any>({})
    const [propertiType, setPropertiType] = useState<any>('servicesList')
    const [facilityData, setFacilityData] = useState<any>()
    const [count, setCount] = useState<any>()
    const [pageLimit, setPageLimit] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const [mainServiceData, setMainServiceData] = useState<any>()
    const [subServiceData, setSubServiceData] = useState<any>()
    const [mainServiceSearch, setMainServiceSearch] = useState<String>('')
    const [subServiceSearch, setSubServiceSearch] = useState<String>('')

    const navigate = useNavigate()

    const getmainServiceList = () => {
        ApiGet(`corporate/main_service`)
            .then((res) => {
                setMainServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getSubServiceData = () => {
        ApiGet(`corporate/sub_service`)
            .then((res) => {
                setSubServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getmainServiceList()
        getSubServiceData()
    }, [])

    const getCount = () => {
        ApiGet('corporate/main_service/count')
            .then((res) => {
                setCount(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getFacilityList = () => {
        const body = {
            page: page,
            limit: 10,
            mainServiceName: mainServiceSearch !== '' ? mainServiceSearch : '',
            subServiceName: subServiceSearch !== '' ? subServiceSearch : ''
        }
        ApiPost('corporate/main_service/overview', body)
            .then((res) => {
                setFacilityData(res?.data?.data?.services_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((err) => {
                ErrorToast(err.message)
                if (err?.message) {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }

    useEffect(() => {
        getCount()
        getFacilityList()
    }, [page])


    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 min-w-200px'>
                        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                            Facilities Management
                        </h1>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-2 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                            // onClick={() => {
                            //     navigate(`/facility-maintenancePlanner`)
                            // }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' aria-disabled data-bs-toggle='tab'>
                                    Maintenance Planner
                                </a>
                            </li>
                            <li
                                className='nav-item'
                            // onClick={() => {
                            //     navigate(`/facility-team`)
                            // }}
                            >
                                <a className={`${propertiType === 'Team' ? 'active' : ''} nav-link text-active-primary pb-4`} data-bs-toggle='tab'>
                                    Team
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/facility`)
                                }}
                            >
                                <a className={`${propertiType === 'servicesList' ? 'active' : ''} nav-link text-active-primary pb-4`} data-bs-toggle='tab'>
                                    Services List
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id='kt_app_content' className='app-content flex-column-fluid'>
                <div id='kt_app_content_container' className='app-container container-xxl'>
                    <Row>
                        <Col lg={6} md={6} className="d-flex align-items-center">
                            <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                Search Main Service
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-solid solid'
                                placeholder='Main Service'
                                name='main_service'
                                value={`${mainServiceSearch}`}
                                onChange={(e) => setMainServiceSearch(e.target.value)}
                                style={{ backgroundColor: "#fff" }}
                            />

                            <button className="btn btn-sm fw-bold btn-primary btn-green hover-none" style={{ marginLeft: '10px' }} onClick={() => getFacilityList()}>Search</button>
                        </Col>
                        <Col lg={6} md={6} className="d-flex align-items-center">
                            <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                Search Sub-Service
                            </label>
                            <input
                                type='text'
                                name='sub_service'
                                className='form-control form-control-solid solid'
                                placeholder='Sub-Service'
                                value={`${subServiceSearch}`}
                                onChange={(e) => setSubServiceSearch(e.target.value)}
                                style={{ backgroundColor: "#fff" }}
                            />
                            <button className="btn btn-sm fw-bold btn-primary btn-green hover-none" style={{ marginLeft: '10px' }} onClick={() => getFacilityList()}>Search</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className='mb-2 min-w-375px px-5 d-flex w-auto' style={{ marginTop: '10px' }}>
                                <div
                                    className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    onClick={() => navigate(`/create-facility`)}>
                                    Add New Service
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='card-header align-items-center py-5  gap-md-2 d-flex'>
                                <div className='mb-2 min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500 mx-10'>
                                        Total Main Services:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.main_service_count}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='card-header align-items-center py-5  gap-md-2 d-flex'>
                                <div className='mb-2 min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500 mx-10'>
                                        Total Sub-Services:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.sub_service_count}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='card card-flush'>
                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='servicesList_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-center min-w-100px'>Id</th>
                                        <th className='text-center min-w-100px'>Main Service</th>
                                        <th className='text-center min-w-100px'>Id</th>
                                        <th className='text-center min-w-70px'>Sub-Service</th>
                                        <th className='text-center min-w-100px'>Notes</th>
                                        <th className='text-center min-w-100px'>Date Added</th>
                                        <th className='text-center min-w-100px'>Overall Requests</th>
                                        <th className='text-center min-w-100px'></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {facilityData?.length ? facilityData.map((v: any,) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{v?.id}</td>
                                                <td className='text-center'>{v?.name}</td>
                                                <td className='text-center'>{v?.sub_service?.id}</td>
                                                <td className='text-center'>{v?.sub_service?.name}</td>
                                                <td className='text-center'><p>{v?.sub_service?.note}</p></td>
                                                <td className='text-center'>{moment(v?.sub_service?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td className='text-center'>{v?.sub_service?.overallRequest}</td>
                                                <td>
                                                    <div className='menu-item px-3'>
                                                        <div
                                                            onClick={() => navigate(`/edit-facility/${v?._id}`)}
                                                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                        >
                                                            Edit
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <tr><td className="100"> No Data Found</td></tr>

                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            </div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageClassName="paginate_button page-item +"
                                        pageLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        previousClassName="paginate_button page-item previous disabled"
                                        nextClassName="paginate_button page-item next"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel="<"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Facility;
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiGet, ApiPut, Bucket, ApiGetNoAuth} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import backArrow from '../../../img/back-arrow.png'
import DatePicker from 'antd/es/date-picker'

const EditTenant = () => {
  const navigate = useNavigate()

  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])

  const [country, setCountry] = useState<any>([])

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setTenantData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    getTenantData()
  }, [window.location.pathname.split('/')[2]])

  const editTenanntData = async () => {
    const body = {
      id: tenantData?._id,
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality,
      // tenantType: tenantData?.tenantType,
      // tenantSource: tenantData?.tenantSource,
      unitId: '',
      communityId: '',
      buildingId: '',
      DOB: tenantData?.DOB,
      status: 0,
      // document: {
      //   passport: tenantData?.document?.passport,
      //   residency: tenantData?.document?.residency,
      //   id: tenantData?.document?.id,
      //   other: tenantData?.document?.other,
      // },
    }
    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast(response?.data?.message)
      })
      .catch((error) => {
        console.log(error)
      })
    navigate(`/tenant/${[window.location.pathname.split('/')[2]]}`)
  }
  console.info('tenantData++', tenantData)

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  useEffect(() => {
    getCountry()
  }, [])

  return (
    <div>
      <div id='kt_app_content_container' className='container-xxl mt-0'>
        <div className='d-flex align-items-center mb-5'>
          <span
            className='rounded-circle'
            style={{cursor: 'pointer'}}
            onClick={() => {
              navigate(`/tenant/${[window.location.pathname.split('/')[2]]}`, {
                state: {newTenantId: state?.newTenantId},
              })
            }}
          >
            <img src={backArrow} style={{stroke: 'red'}} height='16' width='16' />
          </span>
          <h1 className='m-0 head-text ms-2'>
            {tenantData?.firstName || tenantData?.lastName
              ? `${tenantData?.firstName} ${tenantData?.lastName}`
              : 'Tenant Profile'}
          </h1>
        </div>
        {/* <a
          style={{marginBottom: '10px'}}
          className='btn btn-sm fw-bold btn-primary btn-green me-3'
          // onClick={handleSubmit}
          onClick={() => {
            navigate(-1)
          }}
        >
          Back
        </a> */}
        <div className='card card-flush mb-10 mt-8'>
          <div
            className='d-flex align-items-center'
            style={{justifyContent: 'space-between', marginLeft: '5px'}}
          >
            <h3 className='ms-5 me-10 mb-5 mt-10'>Main Tenant Details </h3>
            <a className='btn btn-sm fw-bold btn-primary btn-green me-5' onClick={editTenanntData}>
              Save
            </a>
          </div>
          <div
            className='card-header align-items-center ps-3 row mx-3 mt-3'
            style={{justifyContent: 'flex-start'}}
          >
            {/* <div className='mb-10 min-w-200px col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Type
              </label>

              <select
                name='tenantSource'
                className='form-select form-select-solid'
                value={
                  tenantData?.tenantType === 0
                    ? 'Main'
                    : tenantData?.tenantSource === 1
                    ? 'Sub'
                    : ''
                }
                onChange={(e) => setTenantData({...tenantData, tenantSource: e.target.value})}
              >
                <option disabled selected>
                  Select
                </option>
                <option value={0}>Main</option>
                <option value={1}>Sub</option>
              </select>
            </div> */}
            <div className='mb-10 min-w-200px col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                First Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder=''
                name='firstName'
                value={tenantData?.firstName}
                onChange={(e) => setTenantData({...tenantData, [e.target.name]: e.target.value})}
              />
            </div>
            <div className='mb-10 min-w-200px col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Last Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder=''
                name='lastName'
                value={tenantData?.lastName}
                onChange={(e) => setTenantData({...tenantData, [e.target.name]: e.target.value})}
              />
            </div>
            <div className='mb-10 min-w-200px col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Date of Birth 
              </label>
              <input
                type='date'
                className='form-control form-control-solid'
                placeholder=''
                // max={new Date().toISOString().split('T')[0]}
                name='DOB'
                value={moment(tenantData?.DOB && tenantData?.DOB.split('T')[0]).format(
                  'YYYY-MM-DD'
                )}
                onChange={(e) => setTenantData({...tenantData, [e.target.name]: e.target.value})}
              />
            </div>

            <div className='mb-10 min-w-200px col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Nationality
              </label>

              <select
                name='nationality'
                className='form-select form-select-solid'
                value={tenantData?.nationality}
                // style={{width: '150px'}}
                onChange={(e) => setTenantData({...tenantData, [e.target.name]: e.target.value})}
              >
                <option value='' disabled selected>
                  Select country
                </option>
                {country?.map((v: any) => (
                  <option value={v.country}>{v.country}</option>
                ))}
              </select>
            </div>
            <div className='mb-10 min-w-200px  col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Email
              </label>
              <input
                type='email'
                className='form-control form-control-solid'
                placeholder=''
                name='email'
                value={tenantData?.email}
                onChange={(e) => setTenantData({...tenantData, [e.target.name]: e.target.value})}
              />
            </div>
            <div className='mb-10 min-w-200px  col-3 pe-5'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Mobile No.
              </label>
              {/* <div className='d-flex'> */}
              <PhoneInput
                placeholder='Enter phone number'
                value={`${tenantData?.countryCode} ${tenantData?.phoneNumber}`}
                onChange={(phone:any, e:any) => {
                  let CountryCode: any = e?.dialCode
    let PhoneNumber: any = phone.split(CountryCode)[1]
    setTenantData({
      ...tenantData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: PhoneNumber,
    })
   
                }}

                // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
              />
              {/* <select
                          name='Mobile'
                          className='form-select form-select-solid fst-italic'
                          value={formData?.Mobile}
                          onChange={handleChnage}
                          style={{width: '100px'}}
                        >
                          <option className='fst-italic' disabled selected>
                            Select
                          </option>
                          <option className='fst-italic' value={971}>
                            +971
                          </option>
                          <option className='fst-italic' value={91}>
                            +91
                          </option>
                          <option className='fst-italic' value={1}>
                            +1
                          </option>
                          <option className='fst-italic' value={74}>
                            +74
                          </option>
                          <option className='fst-italic' value={48}>
                            +48
                          </option>
                        </select>
                        <input
                          type='number'
                          className='form-control form-control-solid mx-1'
                          placeholder=''
                          name='number'
                          value={formData?.number}
                          onChange={handleChnage}
                          style={{width: '150px'}}
                        /> */}
              {/* </div> */}
            </div>
            {/* <div className='d-flex'>
              <div className=''>
                <div className='mb-10 min-w-200px mx-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Upload Passport
                  </label>
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='Passport'
                  />
                  {tenantData?.document?.passport && (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green me-3 mt-2'
                      href={`${Bucket}${tenantData?.document?.passport}`}
                      target='_blank'
                    >
                      View passport
                    </a>
                  )}
                </div>
                <div className='mb-10 min-w-200px mx-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Upload Residency
                  </label>
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='Residency'
                  />
                  {tenantData?.document?.residency && (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green me-3 mt-2'
                      href={`${Bucket}${tenantData?.document?.residency}`}
                      target='_blank'
                    >
                      View Residency
                    </a>
                  )}
                </div>
              </div>
              <div className='mb-10 min-w-200px mx-10'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Upload ID
                </label>
                <input
                  type='file'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='ID'
                />
                {tenantData?.document?.id && (
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green me-3 mt-2'
                    href={`${Bucket}${tenantData?.document?.id}`}
                    target='_blank'
                  >
                    Upload Id
                  </a>
                )}
              </div>
              <div className='mb-10 min-w-200px mx-10'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Upload Other
                </label>
                <input
                  type='file'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='Other'
                />
                {tenantData?.document?.other && (
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green me-3 mt-2'
                    href={`${Bucket}${tenantData?.document?.other}`}
                    target='_blank'
                  >
                    Upload Other
                  </a>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditTenant

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../img/NoData1.svg'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import plus from '../../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import trashImg from '../../../../img/trash.png'
import incomesImg from '../../../../img/expense-insight.png'
import totalIncomesImg from '../../../../img/total-expense.png'
import upcomingIncomesImg from '../../../../img/upcoming-expense.png'
import overdueIncomesImg from '../../../../img/overdue-expense.png'
import paidIncomesImg from '../../../../img/paid-expense.png'
import unpaidIncomesImg from '../../../../img/unpaid-expense.png'
import Filters from './Filters'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import openImg from '../../../../img/black-collap-down.png'
import closeImg from '../../../../img/black-collap-up.png'
import maintenance from '../../../../img/maintenanceWhite.png'
import lock from '../../../../img/padlock.png'
import InvoicesTab from './SubTabs/Invoices'
import ReceiptsTab from './SubTabs/Receipts'
import CreditNotesTab from './SubTabs/CreditDebitNotes/CreditNote/CreditNotes'
import DebitNotesTab from './SubTabs/CreditDebitNotes/DebitNote/DebitNotes'
import AccountsTab from './SubTabs/Accounts/Accounts'
import ItemsTab from './SubTabs/Items'
import CustomCard from '../../General/CustomCard'
import {formatAmount, invertOutstandingAmount, getUnitCategory} from '../../../../Utilities/utils'

function Incomes() {
  const [searchPayment, setSearchPayment] = useState<any>('')
  const [searchPayee, setSearchPayee] = useState<any>('')
  const [searchAccount, setSearchAccount] = useState<any>('')
  const [isLoading, setIsLoading] = useState<any>(false)

  const navigate = useNavigate()

  const [payments, setPayments] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)

  useEffect(() => {
    getPaymentsCount()
  }, [])

  useEffect(() => {
    setPage(1)
    getAllPayments(null, 1)
  }, [searchPayment, searchPayee, searchAccount])

  const [incomesCardCount, setIncomesCardCount] = useState<any>()

  // const getPaymentsCount = () => {
  //   ApiPost(`corporate/payment/get_count`)
  //     .then((res) => {
  //       setIncomesCardCount(res?.data?.data)
  //     })
  //     .catch((err) => {
  //       console.log('err', err)
  //       ErrorToast(err?.message)
  //     })
  // }

  const [tabsInsights, setTabsInsights] = useState<any>()

  //
  const getPaymentsCount = async () => {
    const body = {
      searchInvoice: '',
      searchTenant: '',
    }

    try {
      const res = await ApiPost(`corporate/payment/get_count`, body)

      const {
        totalPayments,
        totalAmount,
        totalPaidAmount,
        totalUpcomingAmount,
        totalOverdueAmount,
        totalVATAmount,
      } = res?.data?.data || {}

      const createInsight = (title: string, icon: string, value: number, isAmount: boolean) => ({
        title,
        icon,
        value,
        currency: 'AED',
        isAmount,
      })

      const values = [
        createInsight('Incomes', incomesImg, totalPayments, false),
        createInsight('Total', totalIncomesImg, totalAmount, true),
        createInsight('Upcoming', upcomingIncomesImg, totalUpcomingAmount, true),
        createInsight('Overdue', overdueIncomesImg, totalOverdueAmount, true),
        createInsight('Paid', paidIncomesImg, totalPaidAmount, true),
        createInsight('Unpaid', unpaidIncomesImg, totalAmount - totalPaidAmount, true),
      ]

      setTabsInsights(values)
    } catch (err) {
      console.error('Failed to fetch invoice counts', err)
    }
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [tableData, setTableData] = useState<any>([])

  //
  const goToIncomePayment = (data: any) => {
    if (data?.formStatus == 0) navigate(`/financials/add-income-payment/2/${data?._id}`)
    else navigate(`/finance-income/view-income-payment/${data?._id}`)
  }

  //
  const deleteIncomePayments = () => {
    setIsLoading(true)
    let eIds: any = []

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        eIds?.push(payments[ind]?._id)
      }
    })

    const body = {
      paymentIds: eIds,
    }
    ApiPost(`corporate/payment/delete`, body)
      .then((res: any) => {
        SuccessToast('Payments has been deleted successfully')
        getAllPayments(null, 1)
        getPaymentsCount()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  //
  const getAllPayments = (data: any, pageVal: any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        searchPayment: searchPayment,
        searchPayee: searchPayee,
        searchPaymentAccount: searchAccount,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      values.searchPayment = searchPayment
      values.searchPayee = searchPayee
      values.searchPaymentAccount = searchAccount
      body = values
    }

    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        let values: any = []
        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
          values[i] = false
          showValues[i] = false
        }

        setCheckedValues(values)
        setShow(showValues)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllPayments(null, event.selected + 1)
  }

  //
  // const getUnitTypeName = (unitType: any, unit:any) => {
  //   const types = [
  //     'Apartment',
  //     'Penthouse',
  //     'Common Area',
  //     'Town House',
  //     'Villa',
  //     `Other${unit?.otherName ? ` - ${unit?.otherName}` : ''}`,
  //     'Office',
  //     'Restaurant',
  //     'Cafe',
  //     'Retail',
  //   ]
  //   return types[unitType] || '-'
  // }

  const tabs = [
    'Payments',
    'Invoices',
    'Receipts',
    'Credit Notes',
    'Debit Notes',
    'Refunds',
    'Accounts',
    'Items',
  ]

  const getActiveTab: any = localStorage.getItem('financialIncomesActiveTab')
  const tab = JSON.parse(getActiveTab)

  const [selectedTab, setSelectedTab] = useState<any>(tab ? tab : 'Payments')

  useEffect(() => {
    localStorage.setItem('financialIncomesActiveTab', JSON.stringify(selectedTab))
  }, [selectedTab])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Financials </b>
            </h1>
          </div>
        </div>
        <div className='d-flex flex-wrap mt-5 financials'>
          {/* Old Income Tab */}
          <div className='me-3 mb-3'>
            <div
              className={`d-flex align-items-center tenancy-green-card  h-45px`}
              onClick={() => {
                navigate(`/financials-income`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income-01</h4>
              </div>
              {/* </div> */}
            </div>
          </div>

          {/* New Incomes Tab */}
          <div className='me-3 mb-3'>
            <div
              className={`d-flex align-items-center tenancy-green-card tenancy-active-blue-card h-45px`}
              onClick={() => {
                navigate(`/financials-income`)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income</h4>
              </div>
            </div>
            <div className='blue-active-filter '></div>
          </div>

          {/* Expenses Tab */}
          <div className='me-3 mb-3'>
            <div
              className={`d-flex align-items-center tenancy-green-card h-45px`}
              onClick={() => {
                navigate(`/financials-expenses`)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={bookTen}
                  height={21}
                  width={21}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {expensesTotalCount ? expensesTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}

                <h4 className='mb-0 text-white'>Expenses</h4>
              </div>
            </div>
          </div>

          {/* Tenant Requests*/}
          <div className='ms-auto mb-3'>
            <div
              className={'tenancy-green-card d-flex align-items-center gray-card h-45px'}
              onClick={() => {
                navigate(`/finance-crm-residents`)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={maintenance}
                  height={26}
                  width={26}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                    {tenantReqCount ? tenantReqCount?.toLocaleString('en-US', {}) : 0}
                  </h2> */}

                <h4 className='mb-0 text-white'>Tenant Requests</h4>
              </div>
            </div>
          </div>
        </div>

        {/* <TabsFinancials value={'expenses'} count={expensesTotalCount}/> */}

        {/* Sub Tabs */}
        <div className='row mt-2 mb-5'>
          <div className='col-12'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              {tabs?.map((tb: any, ind: any) => {
                return (
                  <>
                    <li className='nav-item'>
                      <a
                        className={`nav-link text-active-primary pb-0 me-5 fs-4 ${
                          selectedTab == tb ? 'active ' : 'fnt-400'
                        }`}
                        data-bs-toggle='tab'
                        onClick={() => {
                          setSelectedTab(tb)
                        }}
                      >
                        {tb}
                      </a>
                    </li>
                  </>
                )
              })}
            </ul>
          </div>
        </div>

        {selectedTab == 'Payments' ? (
          <>
            <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
              {tabsInsights?.map((tb: any, ind: number) => {
                return (
                  <CustomCard
                    title={tb?.title}
                    currency={tb?.currency}
                    imageUrl={tb?.icon}
                    totalAmount={tb?.value}
                    isAmount={tb?.isAmount}
                  />
                )
              })}

              {/* Expenses */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Incomes</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={incomesImg} height={40} width={40} className='me-3 mt-3' />
                  <h1 className='fw-bold ms-auto mt-3'>
                    {incomesCardCount ? Number(incomesCardCount?.totalPayments) : 0}
                  </h1>
                </div>
              </div> */}
              {/* Total */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Total</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={totalIncomesImg} height={35} width={35} className='me-3' />
                  <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                  <h3 className='fnt-500 ms-auto mt-1'>
                    {incomesCardCount?.totalAmount != 0
                      ? `${incomesCardCount?.totalAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`
                      : 0}
                  </h3>
                </div>
              </div> */}
              {/* Upcoming */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Upcoming</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={upcomingIncomesImg} height={40} width={40} className='me-3' />
                  <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                  <h3 className='fnt-500 ms-auto mt-1'>
                    {incomesCardCount?.totalUpcomingAmount != 0
                      ? `${incomesCardCount?.totalUpcomingAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`
                      : 0}
                  </h3>
                </div>
              </div> */}
              {/* Overdue */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Overdue</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={overdueIncomesImg} height={40} width={40} className='me-3' />
                  <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                  <h3 className='fnt-500 ms-auto mt-1'>
                    {incomesCardCount?.totalOverdueAmount != 0
                      ? `${incomesCardCount?.totalOverdueAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`
                      : 0}
                  </h3>
                </div>
              </div> */}

              {/* Paid */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Paid</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={paidIncomesImg} height={40} width={40} className='me-3' />
                  <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                  <h3 className='fnt-500 ms-auto mt-1'>
                    {incomesCardCount?.totalPaidAmount != 0
                      ? `${incomesCardCount?.totalPaidAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`
                      : 0}
                  </h3>
                </div>
              </div> */}

              {/* Unpaid */}
              {/* <div
                className='customCard py-4 px-7 shadow'
                style={{width: '13.5rem', height: '100px'}}
              >
                <h4 className='text-dark-500  mb-3'>Unpaid</h4>
                <div className='d-flex justify-content-start align-items-center'>
                  <img src={unpaidIncomesImg} height={40} width={40} className='me-3' />
                  <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
                </div>
                <div className='d-flex justify-content-start align-items-center'>
                  <h3 className='fnt-500 ms-auto mt-1'>
                    {incomesCardCount?.totalAmount && incomesCardCount?.totalPaidAmount
                      ? `${(
                          incomesCardCount?.totalAmount - incomesCardCount?.totalPaidAmount
                        ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`
                      : 0}
                  </h3>
                </div>
              </div> */}
            </div>

            <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
              <div className='mb-2 col-md-1 pe-5 xyz'>
                <label className='head-text' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>
              </div>
              <div className='mb-2 col-md-2 pe-5 property xyz tst'>
                <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                  <InputBase
                    placeholder='Payment'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchPayment}
                    onChange={(e: any) => setSearchPayment(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
                <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                  <InputBase
                    placeholder='Payor'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchPayee}
                    onChange={(e: any) => setSearchPayee(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
                <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                  <InputBase
                    placeholder='Account'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchAccount}
                    onChange={(e: any) => setSearchAccount(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              {checkedValues?.length > 0 && (
                <>
                  {checkedValues?.map((ch: any, ind: any) => {
                    if (ch == true) totalSelected = totalSelected + 1
                    return <></>
                  })}
                </>
              )}

              <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
                <div className='d-flex justify-content-end align-items-center'>
                  {totalSelected > 0 && (
                    <>
                      <div className='d-flex '>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry '>
                            {' '}
                            <i>Total Selected </i>
                          </label>

                          <span className='ms-5 ps-1 me-4'>
                            <b>{totalSelected}</b>
                          </span>
                        </h4>
                      </div>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  mx-3 red-hollow-btn btn-fit-content'
                        onClick={() => {
                          swal
                            .fire({
                              html: ` 
                          <div class='fs-3'> <b>Delete Income Payments</></div>
                           <div class='fs-5 mt-4'>You are about to delete the selected income payments.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                              // icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                            })
                            .then((res: any) => {
                              if (res.isConfirmed) {
                                deleteIncomePayments()
                              }
                            })
                        }}
                      >
                        <img src={trashImg} height={20} width={20} className='me-3' /> Delete
                      </button>
                    </>
                  )}

                  <button
                    type='button'
                    className='btn btn-sm fw-bold green-submit-btn btn-fit-content ps-2'
                    onClick={() => {
                      navigate('/financials/add-income-payment/1')
                    }}
                  >
                    <img src={plus} height={18} width={18} className='me-3' /> Add Incoming Payment
                  </button>

                  <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
                    <div
                      className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                      onClick={() => setShowFilter(true)}
                    >
                      <svg
                        style={{
                          height: '20px',
                          width: '28px',
                          marginLeft: '3px',
                        }}
                        viewBox='0 0 1024 1024'
                        xmlns='http://www.w3.org/2000/svg'
                        className='me-1'
                      >
                        <path
                          fill='#ffff'
                          d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
              <div
                className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0'
                id='global-table'
              >
                <table
                  className='table align-start table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-start min-w-25px test px-0'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              totalSelected == tableData?.length && tableData?.length != 0
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              let values = [...checkedValues]
                              if (e.target.checked) {
                                for (let i = 0; i < tableData?.length; i++) {
                                  values[i] = true
                                }
                              } else {
                                for (let i = 0; i < tableData?.length; i++) {
                                  values[i] = false
                                }
                              }
                              setCheckedValues(values)
                            }}
                          />
                        </div>
                      </th>
                      <th className='min-w-75px'>ID</th>
                      <th className='min-w-150px '>Payment</th>
                      <th className='min-w-150px '>Type</th>
                      <th className='min-w-150px '>Payor</th>
                      <th className='min-w-125px'>Amount</th>
                      <th className='min-w-150px'>Development</th>
                      <th className='min-w-100px'>Unit No.</th>
                      <th className='min-w-150px ps-7'>Unit Category</th>
                      <th className='min-w-100px text-center'>Status</th>
                      <th className='min-w-100px text-center'>Category</th>
                      <th className='min-w-100px'>Due</th>
                      <th className='min-w-150px'>Paid Amount</th>
                      <th className='min-w-100px'>Date Paid</th>
                      <th className='min-w-150px'>Oustanding</th>
                      <th className='min-w-100px'>Method</th>
                      <th className='min-w-150px'>Payment Account</th>
                      <th className='text-center min-w-150px px-3'>Form Status</th>
                      <th className='text-start min-w-150px px-3'>Created By</th>
                      <th className='text-start min-w-150px px-3'>Last Updated</th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold head-text'>
                    {' '}
                    {payments?.length > 0 ? (
                      <>
                        {payments.map((pm: any, ind: any) => {
                          return (
                            <>
                              <tr
                                style={
                                  show[ind] == true
                                    ? {borderBottomStyle: 'none', height: '30px'}
                                    : show[ind] == false
                                    ? {borderBottomStyle: 'dashed', height: '30px'}
                                    : pm?.payment_sub_item?.length == 0
                                    ? {borderBottomStyle: 'dashed', height: '30px'}
                                    : {height: '30px'}
                                }
                                className='cursor-pointer'
                              >
                                <td className='text-start px-0 test'>
                                  <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      checked={checkedValues[ind] == true ? true : false}
                                      onChange={(e: any) => {
                                        const values = [...checkedValues]
                                        values[ind] = !values[ind]
                                        setCheckedValues(values)
                                      }}
                                    />
                                    {pm?.payment_sub_item?.length > 0 && (
                                      <img
                                        src={show[ind] ? closeImg : openImg}
                                        height='15'
                                        width='15'
                                        className='cursor-pointer ms-3'
                                        // style={{marginLeft: '-30px', marginRight: '15px'}}
                                        onClick={() => {
                                          if (show[ind] == true) {
                                            let values = [...show]
                                            values[ind] = false
                                            setShow(values)
                                          } else {
                                            let values = [...show]

                                            values[ind] = true

                                            setShow(values)
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td
                                  className='ps-3'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div className='d-flex align-items-center'>
                                      {pm.id}
                                   
                                  </div>
                                </td>
                                <td
                                  className='max-w-150px'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div className='d-flex'>
                                    {pm?.isLock && (
                                      <img src={lock} height={16} width={16} className='me-2' />
                                    )}
                                    {pm.name}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div>
                                    {pm.paymentType === 0
                                      ? 'Tenancy payment'
                                      : pm.paymentType === 1
                                      ? 'Maintenance'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='max-w-150px'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div className='d-flex'>
                                    {pm?.isLock && (
                                      <img src={lock} height={16} width={16} className='me-2' />
                                    )}
                                    {pm.tenant?.[0]?.firstName} {pm.tenant?.[0]?.lastName}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  AED {formatAmount(pm?.totalAmount)}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.community?.length > 0
                                    ? pm?.community?.[0]?.name
                                    : pm?.building?.[0]?.name}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.unit?.length > 0 ? pm?.unit?.[0]?.unitNo : '-'}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                  className='ps-7'
                                >
                                    {getUnitCategory(pm?.unit?.[0]?.propertyType)}
                                </td>

                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div
                                    className={`status-w-100 status
                                    ${
                                      pm?.paymentStatus === 2
                                        ? 'pending-approval-st '
                                        : pm?.paymentStatus === 1
                                        ? 'approve-st'
                                        : pm?.paymentStatus === 0
                                        ? 'cancelled-st'
                                        : pm?.paymentStatus === 3 || pm?.paymentStatus === 4
                                        ? 'pend-st'
                                        : 'not-saved'
                                    }`}
                                  >
                                    {pm?.paymentStatus === 0
                                      ? 'Unpaid'
                                      : pm?.paymentStatus === 1
                                      ? 'Paid'
                                      : pm?.paymentStatus === 2
                                      ? 'Partially paid'
                                      : pm?.paymentStatus === 3
                                      ? 'Void'
                                      : pm?.paymentStatus === 4
                                      ? 'Draft'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div
                                    className={`status status-w-100
                                ${
                                  pm?.categories == 0
                                    ? 'active-bar '
                                    : pm?.categories == 1
                                    ? 'expired-bar'
                                    : pm?.categories == 3
                                    ? 'complete-bar'
                                    : pm?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                                `}
                                  >
                                    {pm?.categories == 0
                                      ? 'Upcoming'
                                      : pm?.categories == 1
                                      ? 'Overdue'
                                      : pm?.categories == 3
                                      ? 'Complete'
                                      : pm?.categories == 2
                                      ? 'Deferred'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.dueDate ? moment(pm?.dueDate).format('DD.MM.YYYY') : '-'}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  AED {formatAmount(pm?.amountPaid)}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.datePaid ? moment(pm?.datePaid).format('DD.MM.YYYY') : '-'}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  AED {formatAmount(invertOutstandingAmount(pm?.outstandingAmount))}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.paymentMethod === 0
                                    ? 'Card'
                                    : pm?.paymentMethod === 1
                                    ? 'Cheque'
                                    : pm?.paymentMethod === 2
                                    ? 'Bank'
                                    : 'Cash'}
                                </td>
                                <td
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.payment_account?.[0]?.accountNumber || '-'}
                                </td>
                                <td
                                  className='text-center  cursor-pointer px-3'
                                  data-order='2022-09-11'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <div
                                    className={`status status-w-100 mx-auto
                                    ${
                                      pm?.formStatus == 0
                                        ? 'draft-st '
                                        : pm?.formStatus == 1
                                        ? 'create-st'
                                        : 'not-saved'
                                    }
                              `}
                                  >
                                    {pm?.formStatus == 0
                                      ? 'Draft'
                                      : pm?.formStatus == 1
                                      ? 'Created'
                                      : '-'}
                                  </div>
                                </td>

                                <td
                                  className='py-3 cursor-pointer px-3'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  <p className='my-0'>
                                    {pm?.createdByUser?.[0] &&
                                      `${pm?.createdByUser?.[0]?.firstName} ${pm?.createdByUser?.[0]?.lastName} - `}
                                  </p>
                                  <p className='my-0'>
                                    {pm?.createdAt
                                      ? `${moment(pm?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                                      : '-'}
                                  </p>
                                </td>

                                <td
                                  className='py-3 cursor-pointer pe-5'
                                  onClick={() => {
                                    goToIncomePayment(pm)
                                  }}
                                >
                                  {pm?.updatedByUser?.length > 0 ? (
                                    <>
                                      <p className='my-0'>
                                        {pm?.updatedByUser?.[0] &&
                                          `${pm?.updatedByUser?.[0]?.firstName} ${pm?.updatedByUser?.[0]?.lastName} - `}
                                      </p>
                                      <p className='my-0'>
                                        {pm?.updatedAt
                                          ? ` ${moment(pm?.updatedAt).format(
                                              'DD.MM.YYYY - hh:mm a'
                                            )}`
                                          : '- -'}
                                      </p>
                                    </>
                                  ) : (
                                    <>{'-'}</>
                                  )}
                                </td>
                              </tr>

                              {pm?.payment_sub_item?.length > 0 && show[ind] == true ? (
                                pm?.payment_sub_item.map((pmSub: any, index: any) => {
                                  return (
                                    <>
                                      <tr style={{borderBottomStyle: 'none', height: '30px'}}>
                                        <td></td>
                                        <td className='ps-3'>
                                          {pm.id}
                                          {'_'}
                                          {pmSub?.id}
                                        </td>
                                        <td>{pmSub?.name}</td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          AED {' '}{pmSub?.isLatePaymentFeesRecord
                                            ? formatAmount(pmSub?.amount)
                                            : formatAmount(pmSub?.totalAmount)}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-center'>
                                          <div
                                            style={
                                              pmSub?.paymentStatus == 2
                                                ? {color: '#ff9a1e'}
                                                : pmSub?.paymentStatus == 1
                                                ? {color: '#0d9e91'}
                                                : pmSub?.paymentStatus == 0
                                                ? {color: '#d54645 '}
                                                : {color: 'dimgray'}
                                            }
                                          >
                                            {pmSub?.paymentStatus == 2
                                              ? 'Partially Paid'
                                              : pmSub?.paymentStatus == 1
                                              ? 'Paid'
                                              : pmSub?.paymentStatus == 0
                                              ? 'Unpaid'
                                              : '-'}
                                          </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          AED{' '}
                                          {formatAmount(pmSub?.amountPaid)}
                                        </td>
                                        <td>
                                          {pmSub?.datePaid
                                            ? moment(pmSub?.datePaid).format('DD.MM.YYYY')
                                            : '-'}
                                        </td>
                                        <td>
                                          AED {' '}
                                          {formatAmount(invertOutstandingAmount(pmSub?.outstandingAmount))}
                                        </td>
                                      </tr>
                                      {index === pm?.payment_sub_item?.length - 1 && (
                                        <tr >
                                          <td></td>
                                          <td></td>
                                          <td>{' Total VAT'}</td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {'AED '}
                                            {formatAmount(pm?.totalVAT)}
                                           
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  )
                                })
                              ) : (
                                <></>
                              )}
                            </>
                            //   )}
                            // </>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <img src={noData} alt='' width={350} />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className='row mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous disabled'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : selectedTab == 'Invoices' ? (
          <InvoicesTab />
        ) : selectedTab == 'Receipts' ? (
          <ReceiptsTab />
        ) : selectedTab == 'Credit Notes' ? (
            <CreditNotesTab />
        )
          : selectedTab == 'Debit Notes' ? (
              <DebitNotesTab />
        ) : selectedTab == 'Accounts' ? (
          <AccountsTab />
        ) : selectedTab == 'Items' ? (
          <ItemsTab />
        ) : (
          <></>
        )}

        {/* Filters */}
        {showFilter && (
          <Filters
            showw={showFilter}
            handleClose={() => setShowFilter(false)}
            updateParent={(data: any, pageVal: any) => {
              getAllPayments(data, 1)
            }}
          />
        )}
      </div>
    </>
  )
}

export default Incomes

import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import imageUploaderSquare from '../../../../../img/Email-Template-02/Image Uploader - Square.png'
import emailDesignerImg from '../../../../../img/email-designer.png'
import emailDynamicSettingImg from '../../../../../img/email-dynamic-settings.png'
import desktopImg from '../../../../../img/desktop-tab.png'
import phoneImg from '../../../../../img/phone-tab.png'
import SectionRenderer from './SectionRenderer'
import SectionControls from './SectionControls'
import SectionEditor from './SectionEditor'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd-next'
import './styles.scss'
import plusGreen from '../../../../../img/add-green-filled.png'
import addImage from '../../../../../img/image.png'
import addText from '../../../../../img/text-area.png'
import addCTA from '../../../../../img/button.png'
import HTMLExport from './HTMLExport'
import TestEmailTemplate from './TestEmailTemplate'
import {capitalizeFirstLetter, getEmailHTMLFile} from '../../../../../Utilities/utils'
import {ApiGet, ApiPost, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {getEmailHTMLContent, convertSvgToPng} from '../../../../../Utilities/utils'
import EmailAttachments from './EmailAttachments'
import {MultiSelect} from 'react-multi-select-component'
import {Controller, useForm} from 'react-hook-form'
import InputNotRequired from '../../../General/InputNotRequired'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

// SectionContent interface
export interface SectionContent {
  backgroundColor: string
  height: number
  width: number
  imageUrl?: string
  title?: string
  [key: string]: any // Allow any additional properties
}

// Section interface
export interface Section {
  id: string
  type: string
  content: SectionContent
}

//
interface EmailBuilderProps {
  template?: any
}

const EmailBuilder: React.FC<EmailBuilderProps> = ({template}) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {id, type} = useParams()

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [templateData, setTemplateData] = useState<any>({
    name: '',
    subject: '',
    otherAttachments: [],
    dynamicAttachments: [],
    html: '',
    thumbnailURL: '',
    frontendObject: {},
    emailSettingId: '',
    templateType: ['invoice_share_via_email'],
    builindingId: [],
    communityId: [],
    isAllDevelopment: false,
    attachmentFiles: [],
    cc: '',
  })

  const [selectedTemplate, setSelectedTemplate] = useState<any>(-1)
  const [selectedSection, setSelectedSection] = useState<any>(null)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<any>(null)

  const [open, setOpen] = React.useState(false)

  const [selectedSectionStyling, setSelectedSectionStyling] = useState<any>({
    backgroundColor: '#6254ff',
    height: '',
    width: '',
    link: [{value: '', display: 0}],
    image: [{value: '', display: 0}],
    content: {
      html: '',
    },
  })

  const [isDynamicActive, setIsDynamicActive] = useState<any>(false)
  const [isDesktop, setIsDesktop] = useState<any>(true)

  const [sections, setSections] = useState<any>([]) // Store sections

  // Load default template as the first section
  useEffect(() => {
    setSections(template)
  }, [template])

  //
  const handleSectionClick = (sect: any) => {
    if (sect != null) {
      setSelectedSection(sect.id)
      setSelectedSectionStyling(sect)
    } else {
      setSelectedSection(null)
      setSelectedSectionStyling(null)
    }

    setShowMergeStyling(false)
    setDisableDynamicDocumentImg(true)
  }

  //
  const handleSectionDelete = (id: any) => {
    setSections(sections.filter((section: any) => section.id !== id))
    setSelectedSection(null)
    setSelectedSectionIndex(null)
  }

  //
  const handleSectionDuplicate = (id: string) => {
    const index = sections.findIndex((section: any) => section.id === id)
    if (index === -1) return

    const newSection: Section = {
      ...sections[index],
      id: `${Date.now()}`, // Assign a unique ID
    }

    const newSections = [...sections]
    newSections.splice(index + 1, 0, newSection) // Insert the duplicate right after the original
    setSections(newSections)
  }

  //
  const handleDragEnd = (result: any) => {
    if (!result.destination) return

    const items = Array.from(sections)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setSections(items)
  }

  //
  const handleDynamicText = () => {
    // console.log('/********* Dynamic Text **********/')
    setShowMergeStyling(true)
    handleFieldSelect('{{}}')
  }

  const handleDynamicDocument = () => {
    // console.log('/********* Dynamic Document **********/')
    setShowMergeStyling(true)
    handleFieldSelect('[]')
  }

  //
  const handleAddSection = (val: any) => {
    const newSections = [...sections]
    let newVal: any = {
      id: `${Date.now()}`,
      type: val == 'text' ? 'text' : val == 'image' ? 'image' : 'button',
      content: {
        display: 'flex',
        justifyContent: val == 'text' ? 'left' : 'center',
        backgroundColor: '#f5f8fa',
        height: val == 'image' ? 80 : 35,
        width: val == 'image' ? 80 : 120,
        color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '20',
        paddingRight: '20',
        paddingBottom: '20',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        buttonName: 'Click here',
        buttonHtml: 'Click here',
        buttonColor: '#6254FF',
        buttonTextColor: '#fff',
        buttonLink: '',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: '#000',
        borderInnerTagRadius: '5',
        html:
          val == 'text'
            ? `<p style="font-size: 16px; line-height: 1.6; color: #000;">
              your content will go here
              </p>`
            : val == 'image'
            ? `<div style="background-color: transparent; padding: 30px; text-align: center; border-radius: 8px;">
                <img src="${imageUploaderSquare}" alt="Main Content Image" style="width: 80px; height: 80px; max-width: 100%;  padding: 0px 10p">
              </div>`
            : `<div style="background-color: transparent; text-align: center; border-radius: 10px; max-width: 100%; box-sizing: border-box;">
               <div id="borderOfDynamicButton" style="border: 0px dashed transparent">
                <a href="" target="_blank" style="max-width: 100%; box-sizing: border-box;">
                  <button style="width: 120px; height: 30px; background-color: #6254FF; border: 1px solid #000; border-radius: 5px; color: #fff; max-width: 100%; box-sizing: border-box; cursor: pointer;"> Click here</button>
                </a>
                </div>
                </div>
              `,
        image: [
          {
            display: 1,
          },
        ],
        link: [
          {
            display: 1,
          },
        ],
        staticLink: null,
        dynamicStyle: {
          border: '',
          padding: '',
        },
        dynamicDocument: [],
      },
    }

    newSections?.push(newVal)
    setSections(newSections)
  }

  const emailRef = useRef<HTMLDivElement>(null)

  const [showHTMLSection, setShowHTMLSection] = useState<any>(false)
  const [showEmailTestPopup, setShowEmailTestPopup] = useState<any>(false)
  const [htmlContentToSend, setHtmlContentToSend] = useState<any>('')

  const exportHTML = () => {
    if (emailRef.current) {
      const htmlContent = emailRef?.current
      // const htmlContent:any = document.getElementById('email-builder-main')

      const htmlFile: any = getEmailHTMLFile(htmlContent)
      setHtmlContentToSend(htmlContent)
      setSelectedSection(null)
      setShowEmailTestPopup(true)
    }
  }

  const [isDisabled, setIsDisabled] = useState<any>(false)

  //
  const addEmailTemplate = async () => {
    setIsDisabled(true)
    setOpen(true)

    if (sections?.length > 0) {
      setShowHTMLSection(true)

      setTimeout(async () => {
        let getHtml: any = emailRef?.current

        const htmlContent: string = getEmailHTMLContent(getHtml)

        const dmAttach: string[] =
          templateData?.dynamicAttachments?.map((atch: {value: string}) => atch?.value) || []

        try {
          // const htmlFile: any = getEmailHTMLFile(getHtml)

          // console.log(htmlFile)

          const pngBlob = await convertSvgToPng(getHtml)
          // console.log(pngBlob)

          // Now upload the PNG if the conversion was successful
          if (pngBlob) {
            var formdata = new FormData()
            formdata.append('image', pngBlob)

            let userData: any = localStorage.getItem('userData')
            const data = JSON.parse(userData)

            let name: any = Date.now()
            await ApiPost(
              `upload/compress_image/params?fileType=email_template&databaseId=${data._id}&fileName=${name}`,
              formdata
            )
              .then((res) => {
                const body = {
                  id: id || undefined,
                  name: templateData?.name,
                  subject: templateData?.subject,
                  from: selectedFromEmail?.[0]?.label,
                  to: selectedTo?.length > 0 ? selectedTo?.[0]?.label : '',
                  otherAttachments: [],
                  dynamicAttachments: dmAttach,
                  thumbnailURL: res?.data?.data?.image,
                  html: htmlContent,
                  emailSettingId:
                    templateData?.emailSettingId?.trim()?.length > 0
                      ? templateData?.emailSettingId
                      : null,
                  templateType: templateData?.templateType,
                  builindingId: templateData?.builindingId,
                  communityId: templateData?.communityId,
                  isAllDevelopment: templateData?.isAllDevelopment,
                  attachmentFiles: templateData?.attachmentFiles,
                  frontendObject: {
                    sections: sections,
                  },
                }

                const apiCall = id ? ApiPut : ApiPost

                apiCall(`corporate/email_template`, body)
                  .then((res: {data: {message: string}}) => {
                    SuccessToast(res?.data?.message)
                    navigate(`/settings/communications/${type}`)
                    setShowHTMLSection(false)
                    setOpen(false)
                    setIsDisabled(false)
                  })
                  .catch((err: {message: string}) => {
                    ErrorToast(err?.message)
                    setShowHTMLSection(false)
                    setOpen(false)
                    setIsDisabled(false)
                  })
                  .finally(() => {
                    setIsDisabled(false)
                    setShowHTMLSection(false)
                    setOpen(false)
                  })
              })

              .catch((err) => {
                setShowHTMLSection(false)
                setOpen(false)
                setIsDisabled(false)
              })
          }
        } catch (error) {
          console.error('Error during PNG conversion or upload:', error)
          setShowHTMLSection(false)
          setOpen(false)
          setIsDisabled(false)
        }
      }, 200)
    } else {
      ErrorToast('Please add content to the email body')
      setShowHTMLSection(false)
      setOpen(false)
      setIsDisabled(false)
    }
  }

  const [selectedText, setSelectedText] = useState<any>('')

  const [selectedField, setSelectedField] = useState<string | null>(null)
  const [updateCount, setUpdateCount] = useState(0)

  const [selectedDocumentField, setSelectedDocumentField] = useState<string | null>(null)

  // Function to update the selected merge field
  const handleFieldSelect = (field: string) => {
    // console.log('/*********** Email Builder File************/')
    // console.log(field)
    setSelectedField(field)

    setUpdateCount((prevCount) => prevCount + 1)
  }

  // Function to update the selected document field
  const handleDocumentSelect = (field: any) => {
    // console.log('/*********** Email Builder File************/')
    // console.log(field)
    setSelectedDocumentField(field)

    setUpdateCount((prevCount) => prevCount + 1)
  }

  const [showMergeStyling, setShowMergeStyling] = useState<boolean>(false)
  const [disableDynamicTextImg, setDisableDynamicTextImg] = useState<any>(false)
  const [disableDynamicDocumentImg, setDisableDynamicDocumentImg] = useState<any>(true)

  const [selectedDynamicText, setSelectedDynamicText] = useState<any>([])
  const [selectedDynamicDocument, setSelectedDynamicDocument] = useState<any>([])

  const [isSquareBracket, setIsSquareBracket] = useState<any>(new Date())
  const [disableDTextBCZofDocument, setDisableDTextBCZofDocument] = useState<any>(false)

  const [fromEmailOptions, setFromEmailOptions] = useState<any>([
    // {
    //   label: 'mukund.khunt@gmail.com',
    //   value: 'mukund.khunt@gmail.com',
    // },
    // {
    //   label: 'muhammad.waqar@gmail.com',
    //   value: 'muhammad.waqar@gmail.com',
    // },
  ])

  const [selectedFromEmail, setSelectedFromEmail] = useState<any>([])

  const [toOptions, setToOptions] = useState<any>([
    {
      label: 'mukund.khunt@gmail.com',
      value: 'mukund.khunt@gmail.com',
    },
    {
      label: 'muhammad.waqar@gmail.com',
      value: 'muhammad.waqar@gmail.com',
    },
  ])

  const [selectedTo, setSelectedTo] = useState<any>([])

  //
  const customOptionRenderer = ({checked, option, onClick}: any) => {
    return (
      <div className='d-flex align-items-center' onClick={onClick}>
        <div style={{width: '30px'}}>
          <input
            type='radio'
            className='me-3 cursor-pointer form-check-input'
            checked={checked}
            readOnly
          />
        </div>
        <div className='mb-1'>{option?.label}</div>
      </div>
    )
  }

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
        ;(checkbox as HTMLElement).style.transform = 'translate(0px, 4px)' // Set the CSS style
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  //
  const getCorporateFromEmails = () => {
    ApiGet(`corporate/email_setting `)
      .then((res: any) => {
        const values =
          res?.data?.data?.map((em: any) => ({
            label: em?.email,
            value: em?._id,
          })) || []
        setFromEmailOptions(values)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getCorporateFromEmails()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <form
          onSubmit={handleSubmit((data: any, e: any) => {
            addEmailTemplate()
          })}
        >
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-start'>
              <span className='rounded-circle cursor-pointer' onClick={() => navigate(-1)}>
                <img src={backArrow} height={14} width={14} className='me-2' />
              </span>
              <div>
                <h2 className='page-heading m-0 head-text'>Build Email</h2>
              </div>
            </div>

            <button
              type='submit'
              className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-120 mt-2 ms-auto'
              disabled={isDisabled}
            >
              Create
            </button>
          </div>
          <div className='card card-flush gap-5 p-5 py-0 tst mt-8' style={{minHeight: '75vh'}}>
            <div className='card-body  py-3 px-3'>
              <div className='d-flex mt-5'>
                <p className='fs-3 fnt-600 '> Email Settings</p>
              </div>
              <div className='row mx-3 mt-2'>
                <div className='col-4 ps-0'>
                  <p className='fs-4 fnt-500 '> From</p>
                  <div className='d-flex tst' style={{width: '85%'}}>
                    <div
                      className='property-test d-none-multi-checbox text-editor mb-2'
                      style={{width: '-webkit-fill-available'}}
                    >
                      <div
                        className={`multi-select-container jobs-property ${
                          selectedFromEmail?.length === 0 ? 'no-value select ' : 'has-value'
                        }`}
                      >
                        <Controller
                          name='fromEmail'
                          control={control}
                          rules={{
                            required: 'This field is required',
                          }}
                          render={({field}) => (
                            <MultiSelect
                              {...field}
                              options={fromEmailOptions}
                              value={selectedFromEmail}
                              onChange={(e: any) => {
                                let newVal: any = e

                                if (e?.length > 1) newVal = e.slice(1)
                                setSelectedFromEmail(newVal)
                                setValue('fromEmail', newVal, {
                                  shouldValidate: true,
                                }) // Trigger validation
                              }}
                              labelledBy='' // Empty as we are overriding the label below
                              overrideStrings={{
                                allItemsAreSelected:
                                  selectedFromEmail?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                              }}
                              hasSelectAll={false}
                              closeOnChangedValue={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </div>
                  {errors.fromEmail && (
                    <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                      This field is required
                    </p>
                  )}
                </div>

                <div className='col-4'>
                  <p className='fs-4 fnt-500 '> Reply To</p>
                  <div className='d-flex tst' style={{width: '85%'}}>
                    <div
                      className='property-test d-none-multi-checbox text-editor mb-3'
                      style={{width: '-webkit-fill-available'}}
                    >
                      <div
                        className={`multi-select-container jobs-property ${
                          selectedTo?.length === 0 ? 'no-value select ' : 'has-value'
                        }`}
                      >
                        <Controller
                          name='toEmail'
                          control={control}
                          rules={{
                            required: 'This field is required',
                          }}
                          render={({field}) => (
                            <MultiSelect
                              options={toOptions}
                              value={selectedTo}
                              // ItemRenderer={customOptionRenderer}
                              onChange={(e: any) => {
                                let newVal: any = e

                                if (e?.length > 1) newVal = e.slice(1)
                                setSelectedTo(newVal)

                                setValue('toEmail', newVal, {
                                  shouldValidate: true,
                                }) // Trigger validation
                              }}
                              labelledBy='' // Empty as we are overriding the label below
                              overrideStrings={{
                                allItemsAreSelected: selectedTo?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                              }}
                              hasSelectAll={false}
                              closeOnChangedValue={true}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </div>
                  {errors.toEmail && (
                    <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                      This field is required
                    </p>
                  )}
                </div>

                <div className='col-4'>
                  <p className='fs-4 fnt-500 '> Subject Line</p>
                  <div className='d-flex'>
                    <div className='w-100'>
                      <InputNotRequired
                        required={true}
                        register={register}
                        errors={errors}
                        value={templateData?.subject}
                        setValue={setValue}
                        trigger={trigger}
                        onBlur={() => {}}
                        onChange={(val: any) => {
                          setTemplateData({...templateData, ['subject']: val})
                        }} // Pass the callback function to handle input changes
                        placeholder='Enter Subject Line'
                        name='emailSubject'
                        pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                        maxLength={50} // Maximum length of 50 characters
                        className=''
                      />
                    </div>
                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </div>
                </div>
              </div>

              {/* <div className='d-flex align-items-center my-5 mb-10'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz mx-auto'>
                <li
                  className='nav-item text-center me-10 cursor-pointer'
                  onClick={() => {
                    setIsDynamicActive(false)
                  }}
                >
                  <img src={emailDesignerImg} height={40} width={40} />

                  <a
                    className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                      !isDynamicActive ? 'active ' : 'fnt-400'
                    }`}
                    data-bs-toggle='tab'
                  >
                    Designer
                  </a>
                </li>
                <li
                  className='nav-item text-center cursor-pointer'
                  onClick={() => {
                    setIsDynamicActive(true)
                  }}
                >
                  <img src={emailDynamicSettingImg} height={40} width={40} />
                  <a
                    className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                      isDynamicActive ? 'active ' : 'fnt-400'
                    }`}
                    data-bs-toggle='tab'
                  >
                    Dynamic Settings
                  </a>
                </li>
              </ul>
            </div> */}

              <div className='separator mx-2 mt-7'></div>

              <div className='row me-4 ms-0 mt-7'>
                <div className='col-6 ps-0'>
                  <p className='fs-4 fnt-600 '> Template Name</p>
                  <div className='d-flex'>
                    <div className='w-100'>
                      <InputNotRequired
                        required={true}
                        register={register}
                        errors={errors}
                        value={templateData?.name}
                        setValue={setValue}
                        trigger={trigger}
                        onBlur={() => {}}
                        onChange={(val: any) => {
                          setTemplateData({...templateData, ['name']: val})
                        }} // Pass the callback function to handle input changes
                        placeholder='Enter a name for this template'
                        name='templateName'
                        pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                        maxLength={50} // Maximum length of 50 characters
                        className=''
                      />
                    </div>

                    <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                  </div>
                </div>

                <div className='col-6 ps-0 text-center'>
                  <div className='d-flex justify-content-center mt-7'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz '>
                      <li className='nav-item text-center me-10'>
                        <a
                          className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                            isDesktop ? 'active ' : 'fnt-400'
                          }`}
                          data-bs-toggle='tab'
                          onClick={() => {
                            setIsDesktop(true)
                          }}
                        >
                          <img src={desktopImg} height={30} width={30} />
                        </a>
                      </li>
                      <li className='nav-item text-center '>
                        <a
                          className={`nav-link text-active-primary pb-0 mx-0 fs-4 ${
                            !isDesktop ? 'active ' : 'fnt-400'
                          }`}
                          data-bs-toggle='tab'
                          onClick={() => {
                            setIsDesktop(false)
                          }}
                        >
                          <img src={phoneImg} height={30} width={20} />
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div
                    className='d-flex justify-content-end'
                    style={{height: '0px', transform: 'translate(0px, -30px)'}}
                  >
                    <button
                      type='button'
                      className='btn btn-sm fw-bold select-btn px-2 justify-content-center status-w-120 mt-2 ms-auto'
                      onClick={() => {
                        setShowHTMLSection(true)
                        setTimeout(() => {
                          exportHTML()
                        }, 500)
                      }}
                    >
                      Preview & Send
                    </button>
                  </div>
                </div>
              </div>

              <div className='row mt-7' style={{marginLeft: '-5px', marginRight: '-5px'}}>
                {/* Editor Styling */}
                <div
                  className='col-6 card card-flush px-0 py-5 position-relative'
                  style={{
                    maxHeight: '700px',
                    overflow: selectedSection == null ? 'hidden' : 'auto',
                  }}
                >
                  {selectedSection == null && (
                    <div
                      className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                      style={{
                        border: '0.5px solid #ccc8c8',
                        boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                        marginBottom: '-155px',
                      }}
                    >
                      <h4 className='labl-gry' style={{marginTop: '-120px'}}>
                        <i>
                          <b>"Select a section to unlock editing options."</b>
                        </i>
                      </h4>
                    </div>
                  )}

                  <SectionEditor
                    // key={String(refreshMergeStyle)}
                    selectedSectionStyling={selectedSectionStyling}
                    setSelectedSectionStyling={(data: any) => {
                      const updatedSections = sections.map((section: any) =>
                        section.id === selectedSection ? data : section
                      )

                      setSections(updatedSections)
                    }}
                    selectedSectInd={selectedSectionIndex}
                    selectedText={selectedText}
                    onFieldSelect={handleFieldSelect}
                    onDocumentSelect={handleDocumentSelect}
                    showMergeStyle={showMergeStyling}
                    selectedDynamicText={selectedDynamicText}
                    selectedDynamicDocument={selectedDynamicDocument}
                    isSquareBracket={isSquareBracket}
                  />
                </div>

                {/* Preview */}
                <div
                  className='col-6 card card-flush px-0'
                  style={{backgroundColor: 'rgb(245, 248, 250)'}}
                >
                  <div style={{position: 'relative'}}>
                    {sections && (
                      <div
                        className={`'builder-preview px-7 ps-8 pb-5 h-100' ${
                          !isDesktop ? 'wd-320px mx-auto' : ''
                        }`}
                        style={{
                          backgroundColor: '#f5f8fa',
                          maxHeight: '700px',
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          paddingTop:
                            selectedSectionIndex != null && selectedSectionStyling?.type === 'text'
                              ? '60px'
                              : '20px',
                        }}
                        // onClick={() => handleSectionClick(null)}
                      >
                        {/* Render all sections here */}
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='sections'>
                            {(provided: any) => (
                              <>
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                  <div>
                                    {sections.map((section: any, index: any) => (
                                      <Draggable draggableId={section.id} index={index} key={index}>
                                        {(provided: any) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            // {...provided.dragHandleProps}
                                            // onClick={() => {
                                            //   setSelectedSectionIndex(index)
                                            //   handleSectionClick(section)
                                            // }}
                                            className={`cursor-pointer d-flex`}
                                          >
                                            {/* Render section based on its type */}
                                            <div
                                              className={`${
                                                section.id === selectedSection ? 'border' : ''
                                              }`}
                                              style={{
                                                width:
                                                  selectedSectionIndex == null ? '100%' : '98%',
                                              }}
                                              onClick={() => {
                                                if (selectedSectionIndex != index) {
                                                  setSelectedSectionIndex(index)
                                                  handleSectionClick(section)
                                                }
                                              }}
                                            >
                                              <SectionRenderer
                                                section={section}
                                                isSelected={section.id === selectedSection}
                                                selectedSectionStyling={selectedSectionStyling}
                                                setSelectedSectionStyling={(data: any) => {
                                                  console.log(data)

                                                  const updatedSections = sections.map((sec: any) =>
                                                    sec.id === selectedSection
                                                      ? {...sec, ...data}
                                                      : sec
                                                  )

                                                  setSections(updatedSections)
                                                }}
                                                isDesktopPreview={isDesktop}
                                                updateSelectedText={(val: any) => {
                                                  setSelectedText(val)
                                                }}
                                                selectedField={selectedField}
                                                selectedDocumentField={selectedDocumentField}
                                                refreshState={updateCount}
                                                disableDynamicTextImg={(val: boolean) => {
                                                  setShowMergeStyling(val)
                                                  setDisableDynamicTextImg(val)
                                                }}
                                                updateDynamicText={setSelectedDynamicText}
                                                updateDynamicDocument={(val: any) => {
                                                  setSelectedDynamicDocument(val)
                                                  // if(val == null)
                                                  // setSelectedDocumentField(null)
                                                }}
                                                disableDynamicDocumentImg={(val: boolean) => {
                                                  console.log(val)

                                                  setShowMergeStyling(!val)

                                                  setDisableDynamicDocumentImg(val)
                                                  // setTimeout(()=>{
                                                  //   // setDisableDynamicTextImg(!val)
                                                  // }, 200)
                                                }}
                                                disableDTextBCZofDocument={setDisableDynamicTextImg}
                                                toggleSquareBracket={() => {
                                                  setIsSquareBracket(new Date())
                                                }}
                                                updateDisableDTextBCZofDocument={(val: any) => {
                                                  setDisableDTextBCZofDocument(val)
                                                }}
                                              />
                                            </div>
                                            {/* {section.id === selectedSection && ( */}
                                            <div style={{width: '0px', height: '0px'}}>
                                              <SectionControls
                                                sectionId={section.id}
                                                onDuplicate={handleSectionDuplicate}
                                                onDelete={handleSectionDelete}
                                                dragHandleProps={provided.dragHandleProps}
                                                selectedSection={selectedSection}
                                                onShowingDynamicText={handleDynamicText}
                                                onShowingDynamicDocument={handleDynamicDocument}
                                                disableDynamicTextIcon={disableDynamicTextImg}
                                                disableDynamicDocumentIcon={
                                                  disableDynamicDocumentImg
                                                }
                                                selectedSectionStyling={selectedSectionStyling}
                                                disableDTextBCZofDocument={
                                                  disableDTextBCZofDocument
                                                }
                                              />
                                            </div>
                                            {/* )}  */}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className='add-new-section '
                                  style={{width: selectedSectionIndex == null ? '100%' : '98%'}}
                                >
                                  <div className='d-flex justify-content-center align-items-center mt-5'>
                                    <img src={plusGreen} height={18} width={18} />
                                    <h4 className='text-white ms-3 mb-0'>Add New Section</h4>
                                  </div>
                                  <div className='d-flex justify-content-center align-items-center mt-5 mb-7'>
                                    <div className='me-7'>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                            {'Add Image'}
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <img
                                            src={addImage}
                                            height={24}
                                            width={28}
                                            className='cursor-pointer'
                                            onClick={() => {
                                              handleAddSection('image')
                                            }}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    </div>
                                    <div className='me-7'>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                            {'Add Text'}
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <img
                                            src={addText}
                                            height={24}
                                            width={28}
                                            className='cursor-pointer'
                                            onClick={() => {
                                              handleAddSection('text')
                                            }}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    </div>

                                    <div className=''>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                            {'Add CTA'}
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <img
                                            src={addCTA}
                                            height={26}
                                            width={30}
                                            className='cursor-pointer'
                                            onClick={() => {
                                              handleAddSection('button')
                                            }}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}
                  </div>
                  {/* for HTML export */}
                  {sections && showHTMLSection && (
                    <div
                      className='builder-preview px-7 py-5 h-100'
                      style={{backgroundColor: '#f5f8fa'}}
                      id='email-builder-main'
                      ref={emailRef}
                    >
                      <>
                        <div>
                          {sections?.map((section: any, index: any) => (
                            <div className={`d-flex`}>
                              {/* Render section based on its type */}
                              <div
                                className={`w-100 ${
                                  section.id === selectedSection ? 'border' : ''
                                }`}
                              >
                                <HTMLExport
                                  section={section}
                                  isSelected={section.id === selectedSection}
                                  selectedSectionStyling={selectedSectionStyling}
                                  setSelectedSectionStyling={(data: any) => {
                                    // let values = [...sections]
                                    // sections.map((section: any, index: any) => {
                                    //   if (selectedSection == section.id) {
                                    //     values[index] = data
                                    //   }
                                    // })
                                    // setSections(values)
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    </div>
                  )}
                </div>
              </div>
              <div className='row my-7' style={{marginLeft: '-5px', marginRight: '-5px'}}>
                <EmailAttachments
                  attachments={templateData?.attachmentFiles}
                  setAttachments={(vals: any) =>
                    setTemplateData({...templateData, attachmentFiles: vals})
                  }
                  dynamicAttachments={templateData?.dynamicAttachments}
                  setDynamicAttachments={(vals: any) =>
                    setTemplateData({...templateData, dynamicAttachments: vals})
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {sections && htmlContentToSend && showEmailTestPopup && (
        <TestEmailTemplate
          show={showEmailTestPopup}
          handleClose={() => {
            setShowHTMLSection(false)
            setShowEmailTestPopup(false)
          }}
          htmlContent={htmlContentToSend}
          htmlSections={sections}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default EmailBuilder

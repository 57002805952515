import React, { useEffect, useState, lazy, Suspense } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';
import RadialBarChart from './RadialBarChart';

// Lazy load the RadialBarChart component
// const RadialBarChart = lazy(() => import('./RadialBarChart'));

interface devCardsProps {
  devData?: any;
  updateParent?: any;
  selectedDev?: any;
  removeFromParent?: any;
}

const SliderComponent: React.FC<devCardsProps> = ({
  devData,
  updateParent,
  selectedDev,
  removeFromParent,
}) => {
  const settings = {
    infinite: false,
    lazyLoad: 'ondemand' as LazyLoadTypes, // Use the correct type for lazyLoad
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isPresent, setIsPresent] = useState<boolean[]>([]);

  useEffect(() => {
    let isCheck: boolean[] = devData?.map(() => false) || [];

    selectedDev?.forEach((selectedId: any) => {
      devData?.forEach((dev: any, index: number) => {
        if (selectedId === dev?.data?._id) isCheck[index] = true;
      });
    });
    setIsPresent(isCheck);
  }, [devData, selectedDev]);

  return (
    <>
      {devData?.length > 0 ? (
        <div className='slider-container'>
          <Slider {...settings}>
            {devData?.map((dev: any, ind: number) => (
              <div key={ind} className='ps-0' style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className='card p-2 radial-card cursor-pointer h-100'
                  onClick={() => {
                    if (!isPresent[ind]) {
                      updateParent(dev?.data?._id, dev?.data?.unitIds);
                    } else {
                      removeFromParent(dev, []);
                    }
                  }}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <RadialBarChart developmentDetails={dev} />
                  </Suspense>
                </div>
                {isPresent[ind] && <div className='dashboard-active-filter'></div>}
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SliderComponent;

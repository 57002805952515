import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import backArrow from '../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../img/trash.png'
import RecordPayment from './RecordPayment'
import OtherAttachments from '../../OtherAttachments'
import ReceiptCard from './ReceiptCard'
import {ApiGet, ApiPost, ApiDelete, Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import greenEditPen from '../../../../../../img/edit-pen.png'
import share from '../../../../../../img/email.png'
import print from '../../../../../../img/printer.png'
import downloadImg from '../../../../../../img/download-img.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { downloadFile } from '../../../../../../Utilities/utils'
import removeIcon from '../../../../../../img/remove.png'
import refreshIcon from '../../../../../../img/refreshIcon.png'
import {useReactToPrint} from 'react-to-print'
import ConfirmationPopup from '../ConfirmationPopup'

enum ActionType {
    CreateAndShare = 'CS',
    Create = 'CR',
    UpdateAndShare = 'US',
    Update = 'UP',
    None = '-'
  }

const EditReceipt = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [receiptData, setReceiptData] = useState<any>({
    name: 'RECEIPT',
    receiptNo: '',
    note: '',
    attachments: [],
    payorData: {},
    datePaid: null,
    referenceNo: '',
    receiptCompany: {},
    receiptLogo: null,
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  // const [isLoading, setIsLoading] = useState<any>(false)

  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [open, setOpen] = React.useState(false)

  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isShare, setIsShare] = useState<any>(true)

  //
  const getReceiptById = async () => {
    let url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setValue('documentName', res?.data?.data?.receiptTemplateHeader)
        setValue('documentReceiptNo', res?.data?.data?.receiptNo)
        setValue(
          'paymentMethod',
          res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
        )
        setValue('paymentDate', res?.data?.data?.datePaid)

        const values: any = {
          name: res?.data?.data?.receiptTemplateHeader,
          pdfURL: res?.data?.data?.pdfURL,
          receiptNo: res?.data?.data?.receiptNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          receiptCompany: res?.data?.data?.receiptCompany,
          receiptLogo: res?.data?.data?.receiptLogo,
          paymentMethod: [],
          createdAt: res?.data?.data?.createdAt,
        }

        switch (res?.data?.data?.paymentMethod) {
          case 1:
            values.paymentMethod = [{value: 1, label: 'Cheque'}]
            break
          case 2:
            values.paymentMethod = [{value: 2, label: 'Bank Transfer'}]
            break
          case 3:
            values.paymentMethod = [{value: 3, label: 'Cash'}]
            break
          case 0:
            values.paymentMethod = [{value: 0, label: 'Card'}]
            break
          default:
            values.paymentMethod = []
        }

        setReceiptData(values)
        console.log(values)

        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          invValues?.push(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            inv?.tenant?.length > 0
              ? inv?.tenant
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          invValues[index].paymentAccountId =
            inv?.payment_invoice?.payment_account?.length > 0
              ? inv?.payment_invoice?.payment_account?.[0]?._id
              : null
          invValues[index].paymentId = inv?.paymentId

          setValue(
            `accountNumber${index}`,
            invValues[index].paymentAccountId != null ? `${invValues[index].paymentAccountId}` : ''
          )
        })

        setSelectedInvoices(invValues)

        setNotesCount(`${res?.data?.data?.note?.length}/${1000}`)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const raiseAReceipt = async (saveStatus: number,from:string) => {

    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }


    if (isValid  ) {

    setIsLoading(true)
    setOpen(true)

    let invRecords: any = []

    let totalAmountOfReceipt: any = 0
    selectedInvoices?.map((slInv: any, ind: number) => {
      invRecords?.push({
        invoiceId: slInv?._id,
        paymentId: slInv?.paymentId,
        paymentAccountId: slInv?.paymentAccountId ? slInv?.paymentAccountId : null,
        paymentSubItems: slInv?.paymentSubItems,
      })

      slInv?.paymentSubItems?.map((invItm: any, indIn: number) => {
        totalAmountOfReceipt = Number(totalAmountOfReceipt) + Number(invItm?.amountPaid)
      })
    })

    const body = {
      createdOriginType: 1,
      invoiceRecords: invRecords,
      note: receiptData?.note,
      otherAttachment: receiptData?.attachments,
      receiptNo: receiptData?.receiptNo,
      tenantId: receiptData?.payorData?._id,
      datePaid: receiptData?.datePaid,
      amountPaid: totalAmountOfReceipt,
      paymentMethod: Number(receiptData?.paymentMethod?.[0]?.value),
      referenceNo: receiptData?.referenceNo,
      receiptTemplateHeader: receiptData?.name,
      _id: id,
      formStatus: saveStatus,
      receiptCompany: receiptData?.receiptCompany,
      receiptLogo: receiptData?.receiptLogo,
    }

    ApiPost(`corporate/payment_receipt/add_update`, body)
      .then((res) => {
        if(from === ActionType.Update){
            setAskForConfirmation(false)
            navigate(`/finance-income/view-receipt/${id}`)
            SuccessToast('Payment receipt has been updated successfully')
          }else if(from === ActionType.UpdateAndShare){
            navigate(`/finance-income/share/view-receipt/${id}`)
          }
        //   SuccessToast('Payment receipt has been updated successfully')
        //   navigate('/finance-income')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)

        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
    }
    else{
      
    }
  }

  useEffect(() => {
    getReceiptById()
  }, [])

  // delete receipt
  const deleteReceipt = () => {
    ApiDelete(`corporate/payment_receipt/${id}`)
      .then((res) => {
        SuccessToast('Payment receipt has been deleted successfully..')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const recipentRef = useRef(null)
  const handlePrint = useReactToPrint({
    onAfterPrint: () => {
      setPrint(false)
    },
    pageStyle: `
      @page {
        size: A4;
        margin: 0;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
        html, body {
          height: 100%;
          margin: 0 !important;
          padding: 0 !important;
        }
         .invoice-content {
          padding: 1mm 10mm !important;
        }

      }
    `,
    content: () => recipentRef.current,
  })
  const [isPrint, setPrint] = useState<boolean>(false)
  const onPrintButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      setPrint(true)
      setTimeout(() => {
        handlePrint()
      }, 300)
    },
    [handlePrint]
  )
  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap px-2 mb-2'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => navigate(`/finance-income/view-receipt/${id}`)}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Edit Receipt`}</h2>
                </div>

                <div className='d-flex ms-auto'>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 red-hollow-btn btn-fit-content ps-3'
                    // disabled={isLoading}
                    onClick={() => {
                      navigate(`/finance-income/view-receipt/${id}`)
                    }}
                  >
                    <img src={removeIcon} height={18} width={18} className='me-4' />{' '}
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm ms-3 fw-bold green-submit-btn btn-fit-content ps-3'
                    onClick={() => {
                      // navigate('/finance-income/add-invoice/1')
                      setAskForConfirmation(true)
                    }}
                  >
                    <img src={refreshIcon} height={18} width={18} className='me-4' />{' '}
                    Update
                  </button>
                </div>
              </div>
            </div>

            {/* Receipt Card */}
            <ReceiptCard
              innerRef={recipentRef}
              register={register}
              errors={errors}
              rcptData={receiptData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateReceipt={(data: any) => {
                console.log(data)
                setReceiptData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              slctdInvoices={selectedInvoices}
              editMode={true}
            />
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>

      <ConfirmationPopup
      from="receipt"
        show={askForConfirmation}
        isUpdate={true}
        isCreate={false}
        label={'Update'}
        type={'Receipt'}
        payor={`${receiptData?.payorData?.firstName} ${receiptData?.payorData?.lastName}`}
        typeOfNo={receiptData?.receiptNo}
        handleClose={() => {
          setAskForConfirmation(false)
        }}
        updateData={(actionType:string) => {
          console.log(actionType)
          
          if(actionType === ActionType.Create){
            // run create fun
        }else if(actionType === ActionType.CreateAndShare){
           // run create and share
        }else if(actionType === ActionType.Update) {
           // run update fun
         
           raiseAReceipt(1,actionType)
        }else if(actionType === ActionType.UpdateAndShare){
            // run update and share
            raiseAReceipt(1,actionType)
        }else{
             // nothing
        }
        }}
        receiptData={[]}
        isShare={isShare}
        setIsShare={(val: any) => {
          setIsShare(val)
        }}
      />
    </>
  )
}

export default EditReceipt

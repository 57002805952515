import React, {useState, useEffect} from 'react'
import {Col, Row, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import Select from 'react-select'

import close from '../../../img/close.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import send from '../../../img/sendwhite.svg'
import download from '../../../img/download-white.png'
import chequeImg from '../../../img/cheque.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import recipient from '../../../img/recipient.png'
import './style.scss'
import refundWhite from '../../../img/refund-white.png'
import editpen from '../../../img/pen.png'
import Switch from 'react-switch'
import redCross from '../../../img/x-mark.png'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  closeEdit: any
  receiptFields: any
  receiptData: any
  refetch: any
  invoiceId: any
  from:any
}

const Refund = ({ 
  show,
  handleClose,
  paymentId,
  tenantDetails,
  closeEdit,
  receiptFields,
  receiptData,
  refetch,
  invoiceId,
  from
}: ButtonProps) => {
  const [refundDetails, setRefundDetails] = useState<any>([])
  const [refundCompany, setRefundCompany] = useState<any>()
  const [refundNo, setRefundNo] = useState<any>('')
  const [refundNoError, setRefundNoError] = useState<any>(false)
  const [refundNotes, setRefundNotes] = useState<any>('')
  const [checkedRec, setCheckedRec] = useState<any>(false)
  const [showAcc, setShowAcc] = useState<any>(false)
  const [selectedAccount, setSelectedAccount] = useState<any>(null)

  const [cardItems, setCardItems] = useState<any>([
    {value: '0', label: 'Card'},
    {value: '1', label: 'Cheque'},
    {value: '2', label: 'Bank'},
    {value: '3', label: 'Cash'},
  ])
  const [paymentMethod, setPaymentMethod] = useState<any>(0)

  var vatAmount: any = 0
  var nonVat: any = 0

  const customStylesInputFilled2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStyles2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '4',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e ',
        backgroundColor: '#fff',
      },
      ':placeholder-shown': {
        border: '0.1rem solid #bec3cb',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '700'}),
  }

  useEffect(() => {
    getDetailsForRefund()
  }, [])

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getSum = (numb1: any, numb2: any) => {
    return numb1 + numb2
  }

  //
  const getDetailsForRefund = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setRefundDetails(res?.data?.data)
        setRefundCompany(res?.data?.data?.refundCompany)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const generateRefund = () => {
    // total VAT to Credit
    let totVatRefund: any = 0
    for (let i = 0; i < receiptFields?.length; i++) {
      totVatRefund = totVatRefund + getPositive(parseFloat(receiptFields[i]?.refundVat))
    }

    // total NonVat to Credit
    let totalSubRefund: any = 0
    for (let i = 0; i < receiptFields?.length; i++) {
      totalSubRefund = totalSubRefund + getPositive(parseFloat(receiptFields[i]?.refundAmount))
    }

    // chqImages
    let chqImages: any = []
    if (paymentMethod == 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.image
      }
    }

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    // subItems
    let subItems: any = []
    let j: any = 0
    for (let i = 0; i < receiptData?.paymentSubItems?.length; i++) {
      if (getPositive(receiptFields[i]?.refundAmount) > 0) {
        subItems[j] = {
          _id: receiptData?.paymentSubItems[i]?._id,
          id: receiptData?.paymentSubItems[i]?.id,
          workspaceId: receiptData?.paymentSubItems[i]?.workspaceId,
          name: receiptData?.paymentSubItems[i]?.name,
          creditAmount: receiptData?.paymentSubItems[i]?.creditAmount,
          creditVATAmount: receiptData?.paymentSubItems[i]?.creditVATAmount,
          refundAmount: parseFloat(getPositive(receiptFields[i]?.refundAmount)),
          refundVATAmount: parseFloat(getPositive(receiptFields[i]?.refundVat)),
          amount: receiptData?.paymentSubItems[i]?.amount,
          quantity: receiptData?.paymentSubItems[i]?.quantity,
          totalAmount: receiptData?.paymentSubItems[i]?.totalAmount,
          VATPercentage: receiptData?.paymentSubItems[i]?.VATPercentage,
          amountPaid: receiptData?.paymentSubItems[i]?.amountPaid,
          outstandingAmount: receiptData?.paymentSubItems[i]?.outstandingAmount,
          VATAmount: receiptData?.paymentSubItems[i]?.VATAmount,
          latePaymentFees: receiptData?.paymentSubItems[i]?.latePaymentFee,
          latePaymentObject: receiptData?.paymentSubItems[i]?.latePaymentObject,
          latePaymentSelectionStatus: receiptData?.paymentSubItems[i]?.latePaymentSelectionStatus,
          paymentStatus: receiptData?.paymentSubItems[i]?.paymentStatus,
          isActive: receiptData?.paymentSubItems[i]?.isActive,
          isBlock: receiptData?.paymentSubItems[i]?.isBlock,
          isLatePaymentFeesRecord: receiptData?.paymentSubItems[i]?.isLatePaymentFeesRecord,
          nextLatePaymentCycle: receiptData?.paymentSubItems[i]?.nextLatePaymentFeesRecord,
          datePaid: receiptData?.paymentSubItems[i]?.datePaid,
          oldLatePaymentCycleList: receiptData?.paymentSubItems[i]?.oldLatePaymentCycleList,
          paymentId: receiptData?.paymentSubItems[i]?.paymentId,
          paymentSubItemId: receiptData?.paymentSubItems[i]?.paymentSubItemId,
          latePaymentSubItemId: receiptData?.paymentSubItems[i]?.latePaymentSubItemId,
          paymentItemListId: receiptData?.paymentSubItems[i]?.paymentItemListId,
          createdBy: receiptData?.paymentSubItems[i]?.createdBy,
          createdAt: receiptData?.paymentSubItems[i]?.createdAt,
          updatedAt: receiptData?.paymentSubItems[i]?.updatedAt,
          __v: 0,
        }
        j++
      }
    }

    const body = {
      refundNo: refundNo,
      receiptNo: receiptData?.receiptNo,
      paymentReceiptId: receiptData?._id,
      paymentId: paymentId,
      paymentInvoiceId: invoiceId,
      note: refundNotes,
      totalRefundAmount: totVatRefund + totalSubRefund,
      totalRefundVAT: totVatRefund,
      subTotalRefundAmount: totalSubRefund,
      paymentSubItems: subItems,
      isManualPDF: false,
      isShareToTenant: checkedRec,
      paymentMethod: parseInt(paymentMethod),
      chequeImages: chqImages,
      paymentAccountId: paymentAccountId,
      chequeBankName: chqBankName,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_refund?isCloneRecord=true`
    else url = `corporate/payment_refund`

    ApiPost(`${url}`, body)
      .then((res) => {
        SuccessToast('Refund Receipt has been successfully generated!!!')
        handleClose()
        refetch()
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const [bankAccountItems, setBankAccountItems] = useState<any>([
    {value: '0', label: 'Account 1'},
    {value: '1', label: 'Account 2'},
    {value: '2', label: 'Account 3'},
    {value: '3', label: 'Account 4'},
  ])

  //
  const [dragActive, setDragActive] = React.useState(false)
  // ref
  const inputRef = React.useRef(null)
  const [chequeMedia, setChequeMedia] = useState<any>([])
  const [chequeBankName, setChequeBankName] = useState<any>('')
  var fileToRename: any

  // getting list of all bank accounts
  const getBankAccounts = () => {
    const body = {
      limit: 10,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        setBankAccountItems(res?.data?.data?.payment_account_data)
      })
      .catch((err) => console.log('err', err))
  }

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    console.log('I am in drag drop')
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    // if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    //   // handleFiles(e.dataTransfer.files);
    // }
  }

  // triggers the input when the button is clicked
  const onButtonClick = (e: any) => {
    // inputRef.current.click();
  }

  // triggers when file is selected with click
  const handleChange = async (e: any) => {
    e.preventDefault()
    const values = [...chequeMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))
      }
      setChequeMedia(values)
    }
  }

  // set cheque number
  const renameCheque = async (chq: any, type: any) => {
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]
    // console.log(chq)
    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res) => {
        const values = [...chequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setChequeMedia(values)
      })
      .catch((err) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
        console.log('err', err)
      })
  }

  // delete cheque
  const deleteCheque = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        const values = [...chequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }

        setChequeMedia(newData)
        SuccessToast('Cheque deleted successfully')
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  return (
    <>
      {/* Debit Note Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto custom-modal'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 refund-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Refund</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  handleClose()
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='refund-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '65px'}}>
              <img src={refundWhite} height='80px' width='80px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-4'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row>
            <Col md={8}>
              <div className='d-flex'>
                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2'
                  style={{
                    width: '120px',
                    backgroundColor: '#ffb100',
                    color: 'white',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    generateRefund()
                  }}
                >
                  Generate
                </button>
              </div>
            </Col>
            <Col md={4} className='py-3 '>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                    // setItemToUpdate(pItem)
                    // // updateStatus(pItem)

                    // if (pItem?.activityStatus === 0) updateStatus(pItem)
                    // else {
                    //   // setStatusUpd(true)
                    //   confirmPop(pItem)

                    //   // setConfirmationModal(true)
                    // }
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#ffb100'
                  offColor='#b0bec5'
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>
          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important'}}
          >
            <Row className='mt-2'>
              <Col md={4} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>REFUND RECIPIENT</h1>

                  <img
                    src={editpen}
                    alt=''
                    className='ms-2'
                    style={{cursor: 'pointer', height: '14px', width: '14px', marginTop: '-25px'}}
                    // onClick={() => {
                    // }}
                  />
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  {refundDetails?.refundSequence?.activityStatus == 1 ? (
                    <>
                      {refundDetails?.refundSequence?.prefix != null && (
                        <>
                          <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                            Refund No.
                          </label>

                          <input
                            type='text'
                            className={
                              refundNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            // className='form-control form-control-solid-bg input-style'
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={refundDetails?.refundSequence?.prefix}
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                        Refund No.
                      </label>

                      <input
                        type='text'
                        className={
                          refundNoError
                            ? 'form-control form-control-solid-bg input-style border-danger '
                            : 'form-control form-control-solid-bg input-style mytest'
                        }
                        // className='form-control form-control-solid-bg input-style'
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={refundNo}
                        onChange={(event) => {
                          setRefundNo(event.target.value)
                          setRefundNoError(false)
                        }}
                      />
                    </>
                  )}
                </div>
                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Issue Date
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {moment(new Date()).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Receipt No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {receiptData?.receiptNo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className='d-flex my-4 justify-content-end'>
                  {refundDetails?.refundLogo && (
                    <img
                      src={`${Bucket}${refundDetails?.refundLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                    />
                  )}
                </div>
                {/* )} */}
              </Col>
            </Row>

            <Row className='mt-5 pb-3'>
              <Col md={6} className='text-start' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>{refundCompany?.name}</p>
                <p style={{marginBottom: '0.1rem'}}>{refundCompany?.address}</p>

                <p style={{marginBottom: '0.1rem'}}>
                  {refundCompany?.countryCode} {refundCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{refundCompany?.email}</p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}} className='ms-auto'>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}} className='ms-auto'>
                  {tenantDetails?.email}
                </p>
              </Col>
            </Row>

            <Row style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={4} className='pe-4 pb-5'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{refundCompany?.trn ? refundCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount Paid</th>
                  <th className='min-w-100px'>Refund Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {receiptFields?.map((field: any, index: any) => {
                  if (getPositive(field?.refundAmount) > 0) {
                    return (
                      <tr style={{borderBottom: '1px solid lightgray', height: '45px'}} key={index}>
                        <td className='py-2'>{index + 1}</td>
                        <td className='py-2'>{field.name}</td>
                        <td className='py-2'>{field.qty}</td>
                        <td className='py-2'>
                          {field?.isLatePaymentFeeRecord ? getPositive(parseFloat(field.amount)/parseFloat(field.qty))?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          }) : parseFloat(field.amount)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {field?.vatAmount?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                        {field?.amountPaid?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {getSum(
                            getPositive(parseFloat(field?.refundAmount)),
                            getPositive(parseFloat(field?.refundVat))
                          )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                        </td>
                      </tr>
                    )
                  }
                })}

                <>
                  {receiptFields?.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>

                {receiptFields?.map((f: any, ind: any) => {
                  nonVat = nonVat + getPositive(parseFloat(f?.refundAmount))
                  vatAmount = vatAmount + getPositive(parseFloat(f?.refundVat))

                  return <></>
                })}

                <tr style={{height: '55px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4 fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {(nonVat + vatAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={6} className='pe-5'>
                <div className='d-flex my-2 mb-5'>
                  <label
                    className='mb-1 me-3 pe-5 d-flex head-text fs-4'
                    style={{minWidth: '150px', fontWeight: '700'}}
                  >
                    Payment Method
                  </label>
                  <div style={{width: '-webkit-fill-available'}}>
                    <Select
                      options={cardItems}
                      styles={customStylesInputFilled2}
                      isSearchable={false}
                      defaultValue={
                        paymentMethod == 0
                          ? cardItems[0]
                          : paymentMethod == 1
                          ? cardItems[1]
                          : paymentMethod == 2
                          ? cardItems[2]
                          : cardItems[3]
                      }
                      onChange={(event: any) => {
                        setPaymentMethod(event.value)
                        if (event.value == 2) {
                          getBankAccounts()
                        } else {
                          setShowAcc(false)
                          setSelectedAccount(null)
                        }
                      }}
                    />
                  </div>
                </div>
                {/* Payment Method: Bank */}
              {paymentMethod == 2 ? (
                <>
                  <div className='align-items-center d-flex mb-3'>
                    <label className='mb-1 me-2 pe-5 d-flex head-text' style={{minWidth: '100px'}}>
                      Bank Account
                    </label>
                    <div style={{width: '-webkit-fill-available'}}>
                      {selectedAccount != null ? (
                        <Select
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAcc(true)
                            setSelectedAccount(event.id)
                          }}
                        />
                      ) : (
                        <Select
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStyles2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAcc(true)
                            setSelectedAccount(event.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {showAcc && (
                    <>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account Name
                        </label>
                        {selectedAccount?.accountHolderName}
                      </div>
                      <div
                        className='d-flex align-items-center my-2 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          IBAN
                        </label>
                        {selectedAccount?.IBAN}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account No.
                        </label>
                        {selectedAccount?.accountNumber}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Bank Name
                        </label>
                        {selectedAccount?.bankName}
                      </div>
                    </>
                  )}
                </>
              ) : paymentMethod == 1 ? (
                <>
                  <div className='d-flex align-items-center justify-content-center mb-2'>
                    <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '90px'}}>
                      Bank Name
                    </label>

                    <input
                      type='text'
                      className='form-control form-control-solid-bg input-style mytest'
                      placeholder='Enter Name'
                      name='item_id'
                      value={chequeBankName}
                      onChange={(event) => {
                        setChequeBankName(event?.target.value)
                      }}
                    />
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                    <form
                      id='form-file-upload'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px'}}
                    >
                      <label
                        id='label-file-upload'
                        htmlFor='input-file-upload'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          // transform: 'translate(-2px, -90px)',
                        }}
                        ref={inputRef}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        type='file'
                        multiple={true}
                        onChange={handleChange}
                        id='input-file-upload'
                        ref={inputRef}
                        style={{
                          height: '90px',
                          opacity: '-0.9',
                          position: 'relative',
                          transform: 'translate(-2px, -90px)',
                        }}
                      />
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        paymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex '>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                              <div className="h-0">
                              <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-25px', marginLeft: '5px'}}
                                  onClick={() => deleteCheque(chq)}
                                />
                              </div>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-solid-bg input-style mt-1'
                              placeholder='Cheque No'
                              name='item_id'
                              value={chq.name}
                              onChange={(event) => {
                                fileToRename = chq
                                // console.log(event)
                                const values = [...chequeMedia]
                                // let x = values[ind]?.src?.name?.split('.')[1]
                                values[ind].name = event.target.value

                                setChequeMedia(values)
                              }}
                              onBlur={() => {
                                let fileToRenam = chq

                                const values = [...chequeMedia]
                                let x = values[ind]?.src?.name?.split('.')[1]
                                renameCheque(fileToRenam, x)
                              }}
                              style={{height: '18px', fontSize: '8px', width: '80px'}}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              </Col>
              <Col md={6} className='ps-5'>
                {/* Notes */}
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={refundNotes}
                    onChange={(e) => {
                      setRefundNotes(e.target.value)
                    }}
                    maxLength={1000}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Refund

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../../../../img/NoData1.svg'
import {ApiGet, ApiPost} from '../../../../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import plus from '../../../../../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InvoiceImg from '../../../../../../../img/downDoubleArrow.png'
import totalInvoicesImg from '../../../../../../../img/totalInvoice.png'
import upcomingImg from '../../../../../../../img/upcoming-expense.png'
import overdueImg from '../../../../../../../img/overdue-expense.png'
import paidImg from '../../../../../../../img/paid-expense.png'
import vatImg from '../../../../../../../img/vatIcon.png'
import Filters from '../../../Filters'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import openImg from '../../../../../../../img/black-collap-down.png'
import closeImg from '../../../../../../../img/black-collap-up.png'
import CustomCard from '../../../../../General/CustomCard'
import {formatAmount, invertOutstandingAmount} from '../../../../../../../Utilities/utils'
import InvoicesPopup from '../components/InvoicesPopup'

function CreditNotesTab() {
  const [searchInvoice, setSearchInvoice] = useState<any>('')
  const [searchTenant, setSearchTenant] = useState<any>('')
  const [searchAccount, setSearchAccount] = useState<any>('')

  const navigate = useNavigate()

  const [creditNotes, setCreditNotes] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)

  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const [incomesCardCount, setIncomesCardCount] = useState<any>()

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  //
  const goToCreditNote = (data: any) => {
    if (data?.formStatus == 0) navigate(`/finance-income/issue-credit-note/2/${data?._id}`)
    else navigate(`/finance-income/view-credit-note/${data?._id}`)
  }

  //
  const getAllCreditNotes = (data: any, pageVal: any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        // searchInvoice: searchInvoice,
        // searchTenant: searchTenant,
        // searchPaymentAccount: searchAccount,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      // values.searchInvoice = searchInvoice
      // values.searchTenant = searchTenant
      // values.searchPaymentAccount = searchAccount
      body = values
    }

    ApiPost(`corporate/payment_credit_note/get`, body)
      .then((res) => {
        setCreditNotes(res?.data?.data?.payment_credit_note_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        let values: any = []
        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.payment_invoice_data?.length; i++) {
          values[i] = false
          showValues[i] = false
        }

        setCheckedValues(values)
        setShow(showValues)
      })
      .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllCreditNotes(null, event.selected + 1)
  }

  //
  const [tabsInsights, setTabsInsights] = useState<any>([
    {
      title: 'Invoices',
      icon: InvoiceImg,
      value: 0,
      currency: 'AED',
      isAmount: false,
    },
    {
      title: 'Total',
      icon: totalInvoicesImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Paid',
      icon: paidImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Upcoming',
      icon: upcomingImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Overdue',
      icon: overdueImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'VAT Returns',
      icon: vatImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
  ])

  //
  const getInvoicesCount = async () => {
    const body = {
      searchInvoice: '',
      searchTenant: '',
    }

    try {
      const res = await ApiPost(`corporate/payment_invoice/get_count`, body)

      const {
        totalInvoices,
        totalAmount,
        totalPaidAmount,
        totalUpcomingPayment,
        totalOverdueAmount,
        totalVATAmount,
      } = res?.data?.data || {}

      const createInsight = (title: string, icon: string, value: number, isAmount: boolean) => ({
        title,
        icon,
        value,
        currency: 'AED',
        isAmount,
      })

      const values = [
        createInsight('Credit Notes', InvoiceImg, totalInvoices, false),
        createInsight('Total', totalInvoicesImg, totalAmount, true),
        createInsight('Paid', paidImg, totalPaidAmount, true),
        createInsight('Upcoming', upcomingImg, totalUpcomingPayment, true),
        createInsight('Overdue', overdueImg, totalOverdueAmount, true),
        createInsight('VAT Returns', vatImg, totalVATAmount, true),
      ]

      setTabsInsights(values)
    } catch (err) {
      console.error('Failed to fetch invoice counts', err)
    }
  }

  //
  useEffect(() => {
    setPage(1)
    getAllCreditNotes(null, 1)
    // getInvoicesCount()
  }, [searchInvoice, searchTenant, searchAccount])

  const [showInvoices, setShowInvoices] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print'>
        <>
          {/* Insights */}
          <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
            {tabsInsights?.map((tb: any, ind: number) => {
              return (
                <CustomCard
                  title={tb?.title}
                  currency={tb?.currency}
                  imageUrl={tb?.icon}
                  totalAmount={tb?.value}
                  isAmount={tb?.isAmount}
                />
              )
            })}
          </div>

          <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
            <div className='mb-2 col-md-1 pe-5 xyz'>
              <label className='head-text' style={{fontWeight: '600'}}>
                {' '}
                Search{' '}
              </label>
            </div>
            <div className='mb-2 col-md-2 pe-5 property xyz tst'>
              <Paper
                component='form'
                style={searchContainerStyle}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputBase
                  placeholder='Credit Note'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchInvoice}
                  onChange={(e: any) => setSearchInvoice(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
              <Paper
                component='form'
                style={searchContainerStyle}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputBase
                  placeholder='Payor'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchTenant}
                  onChange={(e: any) => setSearchTenant(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
              <Paper
                component='form'
                style={searchContainerStyle}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputBase
                  placeholder='Account'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchAccount}
                  onChange={(e: any) => setSearchAccount(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            {checkedValues?.length > 0 && (
              <>
                {checkedValues?.map((ch: any, ind: any) => {
                  if (ch == true) totalSelected = totalSelected + 1
                  return <></>
                })}
              </>
            )}

            <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
              <div className='d-flex justify-content-end align-items-center'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold green-submit-btn btn-fit-content ps-2'
                  onClick={() => {
                    navigate('/finance-income/issue-credit-note/1') // 1 for no status
                  }}
                >
                  <img src={plus} height={18} width={18} className='me-3' /> Issue Credit Note
                </button>

                <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
                  <div
                    className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                    // onClick={() => setShowFilter(true)}
                  >
                    <svg
                      style={{
                        height: '20px',
                        width: '28px',
                        marginLeft: '3px',
                      }}
                      viewBox='0 0 1024 1024'
                      xmlns='http://www.w3.org/2000/svg'
                      className='me-1'
                    >
                      <path
                        fill='#ffff'
                        d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0' id='global-table'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='w-25px ps-0'></th>
                    <th className='min-w-125px ps-0'>Credit Note No.</th>
                    <th className='min-w-125px'>Credit Amount</th>
                    <th className='min-w-100px'>Issued</th>
                    <th className='min-w-100px text-center'>Category</th>
                    <th className='min-w-100px text-start ps-7 '>Invoices</th>
                    <th className='min-w-125px'>Invoice Amount</th>
                    <th className='min-w-125px'>Paid Amount</th>
                    <th className='min-w-125px'>Outstanding</th>
                    <th className='min-w-125px '>Payor</th>
                    <th className='text-center min-w-150px px-3'>Form Status</th>
                    <th className='text-start min-w-150px px-3'>Created By</th>
                    <th className='text-start min-w-150px px-3'>Last Updated</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold head-text'>
                  {' '}
                  {/* className='fw-semibold text-gray-600'*/}
                  {creditNotes?.length > 0 ? (
                    <>
                      {creditNotes.map((inv: any, ind: any) => {
                        return (
                          <>
                            <tr
                              style={{
                                borderBottomStyle:
                                  show[ind] === true
                                    ? 'none'
                                    : show[ind] === false || inv?.paymentSubItems?.length === 0
                                    ? 'dashed'
                                    : undefined,
                              }}
                              className='cursor-pointer'
                            >
                              <td className='ps-0'>
                                <div className='d-flex text-decoration-underline'>
                                  {inv?.paymentSubItems?.length > 0 && (
                                    <img
                                      src={show[ind] ? closeImg : openImg}
                                      height='15'
                                      width='15'
                                      className='cursor-pointer me-3'
                                      // style={{marginLeft: '-30px', marginRight: '15px'}}
                                      onClick={() => {
                                        if (show[ind] == true) {
                                          let values = [...show]
                                          values[ind] = false
                                          setShow(values)
                                        } else {
                                          let values = [...show]

                                          values[ind] = true

                                          setShow(values)
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td
                                className='ps-0 text-decoration-underline cursor-pointer'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                <b>{inv?.creditNoteNo ? inv?.creditNoteNo : '-'}</b>
                              </td>
                              <td
                                className='cursor-pointer'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.totalAmount > 0 ? (
                                  <>AED {formatAmount(inv?.totalAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>
                              <td
                                className=''
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.date ? moment(inv?.date).format('DD.MM.YYYY') : '-'}
                              </td>

                              <td
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                <div
                                  className={`status status-w-100
                                ${
                                  inv?.creditCategory == 0
                                    ? 'booked-bar'
                                    : inv?.creditCategory == 1
                                     ? 'closed-bar'
                                    : 'no-st-bar'
                                }
                              `}
                                >
                                  {inv?.creditCategory == 0
                                    ? 'Open'
                                    : inv?.creditCategory == 1
                                    ? 'Closed'
                                    : '-'}
                                </div>
                              </td>
                                
                              <td
                            className={` ps-7 ${
                              inv?.invoices?.length > 0 ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              setSelectedInvoices(inv?.invoices)
                              setShowInvoices(true)
                            }}
                          >
                            <div className='d-flex text-decoration-underline'>
                              <b>
                                {inv?.invoices?.length > 0 ? inv?.invoices?.length : 0}
                              </b>
                            </div>
                          </td>

                              <td
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.totalInvoicesAmount > 0 ? (
                                  <>AED {formatAmount(inv?.totalInvoicesAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>

                              
                              <td
                                className=''
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.totalPaidAmount != undefined ? (
                                  <>AED {formatAmount(inv?.totalPaidAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>
                              <td
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.totalOutstandingAmount != undefined ? (
                                  <>AED {formatAmount(invertOutstandingAmount(inv?.totalOutstandingAmount))}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>

                              <td
                                className='cursor-pointer'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {(inv?.payor?.firstName && inv?.payor?.lastName)? `${inv?.payor?.firstName} ${inv?.payor?.lastName}`
                                  : '-'}
                              </td>
                            
                              <td
                                className='text-center  cursor-pointer px-3'
                                data-order='2022-09-11'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                <div
                                  className={`status status-w-100 mx-auto
                                ${
                                  inv?.formStatus == 0
                                    ? 'draft-st '
                                    : inv?.formStatus == 1
                                    ? 'create-st'
                                    : 'not-saved'
                                }
                              `}
                                >
                                  {inv?.formStatus == 0
                                    ? 'Draft'
                                    : inv?.formStatus == 1
                                    ? 'Created'
                                    : '-'}
                                </div>
                              </td>

                              <td
                                className='py-3 cursor-pointer px-3'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                <p className='my-0'>
                                  {inv?.createdByUser &&
                                    `${inv?.createdByUser?.firstName} ${inv?.createdByUser?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {inv?.createdAt
                                    ? `${moment(inv?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                                    : '-'}
                                </p>
                              </td>

                              <td
                                className='py-3 cursor-pointer pe-5'
                                onClick={() => {
                                  goToCreditNote(inv)
                                }}
                              >
                                {inv?.updatedByUser ? (
                                  <>
                                    <p className='my-0'>
                                      {inv?.updatedByUser &&
                                        `${inv?.updatedByUser?.firstName} ${inv?.updatedByUser?.lastName} - `}
                                    </p>
                                    <p className='my-0'>
                                      {inv?.updatedAt
                                        ? ` ${moment(inv?.updatedAt).format(
                                            'DD.MM.YYYY - hh:mm a'
                                          )}`
                                        : '- -'}
                                    </p>
                                  </>
                                ) : (
                                  <>{'-'}</>
                                )}
                              </td>
                            </tr>

                            {inv?.paymentSubItems?.length > 0 && show[ind] == true ? (
                              inv?.paymentSubItems?.map((invSub: any, index: any) => {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        borderBottomStyle:
                                          inv?.paymentSubItems?.length === index - 1
                                            ? 'none'
                                            : 'dashed',
                                      }}
                                    >
                                      <td></td>
                                      <td className='ps-0'>{invSub?.name}</td>
                                      <td></td>
                                      <td>
                                        {invSub?.totalAmount != undefined ? (
                                          <>AED {formatAmount(invSub?.totalAmount)}</>
                                        ) : (
                                          <>AED -</>
                                        )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        {invSub?.amountPaid != undefined ? (
                                          <>AED {formatAmount(invSub?.amountPaid)}</>
                                        ) : (
                                          <>AED -</>
                                        )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </>
                                )
                              })
                            ) : (
                              <></>
                            )}
                          </>
                          //   )}
                          // </>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={21} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous disabled'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </>

        {/* Filters */}
        {showFilter && (
          <Filters
            showw={showFilter}
            handleClose={() => setShowFilter(false)}
            updateParent={(data: any, pageVal: any) => {
              getAllCreditNotes(data, 1)
            }}
          />
        )}

        {/*  */}
        <InvoicesPopup
        show={showInvoices}
        handleClose={() => {
          setShowInvoices(false)
        }}
        invoicesData={selectedInvoices}
      />
      </div>
    </>
  )
}

export default CreditNotesTab

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Col, Row} from 'react-bootstrap'
import TenanciesFilter from './TenanciesFilter'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import {AiFillDelete, AiFillDownCircle} from 'react-icons/ai'
import swal from 'sweetalert2'
import {DatePicker} from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import noData from '../../../img/NoData1.svg'
import AddSubTenant from './AddSubTenant'
import Chart from './Chart'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import Swal from 'sweetalert2'
import file from '../../../img/file.svg'

import GeneratePayment from '../Payments/GeneratePayment'
import SendReminder from '../Payments/SendReminder'
import ViewReminder from '../Payments/ViewReminder'
import appartment from '../../../img/Apartment.svg'
import penthouse from '../../../img/PentHouse.svg'
import common_area from '../../../img/common_area.svg'
import other from '../../../img/other.svg'
import townhouse from '../../../img/TownHouse.svg'
import villa from '../../../img/Villa.svg'
import zoom from '../../../img/zoom.svg'
import ViewReceipt from '../Payments/ViewReceipt'
import EditPaymentReceipt from '../Payments/EditPaymentReceipt'
import {getEmptyImage} from 'react-dnd-html5-backend'
import PhoneInput from 'react-phone-input-2'
import ChequeData from '../Payments/ChequeData'
import BankData from '../Payments/BankData'
import ReactPaginate from 'react-paginate'
import TenatsFilter from '../Tenants/TenatsFilter'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import MoveInOutTable from './Tables/MoveInOutTable'
import {useSelector} from 'react-redux'
import leftarrow from '../../../img/arrowleft.svg'
import PaymentErrorModal from './PaymentErrorModal'
import pen from '../../../img/pen.png'
import info from '../../../img/info.svg'

let arry: any = []

const TenancyDetails = () => {
  const {flg, tenantId, tenancyId} = useParams()
  const unitIDfromProperties = useSelector(
    (state: any) => state.counterReducer.unitIDfromProperties
  )
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)
  const UnitId = localStorage.getItem('UnitId')
  const [showGenRecModal, setShowGenRecModal] = useState<boolean>(false)
  const [showChequeData, setShowChequeData] = useState<any>()
  const [showBankData, setShowBankData] = useState<any>()
  const [showSubTenantModal, setShowSubTenantModal] = useState<boolean>(false)
  const [showSendRemModal, setShowSendRemModal] = useState<boolean>(false)
  const [showviewRemModal, setShowviewRemModal] = useState<boolean>(false)
  const [showPaymentReceipt, setShowPaymentReceipt] = useState<boolean>(false)
  const [showEditReceipt, setShowEditReceipt] = useState<boolean>(false)
  const [showCheange, setShowCheange] = useState<boolean>(false)
  const [tenancyPayement, setTenancyPayement] = useState<any>([])
  const [previousTenancy, setPreviousTenancy] = useState([])
  const [previousTenants, setPreviousTenants] = useState([])
  const [paymentMethod, setPaymentMethod] = useState('')
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [paymentpageLimit, setPaymentPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [page1, setPage1] = useState<any>(1)
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [typetable, settypetable] = useState<any>()
  const [moveinview, setmoveinview] = useState<any>(false)
  const [moveoutview, setmoveoutview] = useState<any>(false)
  const [genralview, setgenralview] = useState<any>(false)
  const [otherview, setotherview] = useState<any>(false)
  const [renewalview, setrenewalview] = useState<boolean>(false)
  const [terminationview, setterminationview] = useState<boolean>(false)
  const [byiddata, setbyiddata] = useState<any>(false)
  const [modelindex, setmodelindex] = useState<any>()
  const [popUpData, setpopUpData] = useState<any>({})
  const [popUpReceiptData, setpopUpReceiptData] = useState<any>()
  const [showPaid, setShowPaid] = useState<boolean>(false)
  const [cardA, setCardA] = useState<any>([])
  const [count, setcount] = useState<any>(0)
  const [payment, setPayment] = useState<number>(0)
  const [totalpayment, settotalpayment] = useState<any>(0)
  const [card, setCard] = useState('')
  const [paymentData, setPaymentData] = useState<any>({})
  const [tenancyReminder, setTenancyReminder] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [showModal, setShowModal] = useState<any>('Filter')
  const [type, setType] = useState('')
  const [totalDays, setTotalDays] = useState('0 Days')
  const [propertiType, setPropertiType] = useState(
    window.location.pathname?.split('/')[3] === 'payment-rent' ? 'Payments' : 'Overview'
  )
  const [modeldata, setmodeldata] = useState<any>()
  const [modelData, setmodelData] = useState<any>({})
  const [modelchequeData, setmodelchequeData] = useState<any>()
  const [totalamount, settotalamount] = useState<any>(0)
  const [propertiTypeT, setPropertiTypeT] = useState('Tenancy')
  const [propertiTypeTH, setPropertiTypeTH] = useState('Tenants')
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [date, setDate] = useState<boolean>(false)
  const [filter, setfilter] = useState<any>([])
  const [datefilter, setdatefilter] = useState<any>({})
  const [show_img, setShow_img] = useState<any>(false)
  const [data_count, setData_count] = useState<any>(0)
  const [tabledata1, settabledata1] = useState<any>([])
  // const [radiobut,setradiobut] =useState<any>()

  const [isEditPaymentId, setIsEditPaymentId] = useState<any>()
  const [paymentId, setPaymentId] = useState<any>()
  const [edit, setEdit] = useState<any>()
  const [editData, setEditData] = useState<any>()
  const [imgContract, setImgContract] = useState('')
  const [imgOtherT, setImgOtherT] = useState('')
  const [tenancy, setTenancy] = useState<any>([])
  const [tenantID, setTenantID] = useState<any>()
  const [isEdit, setIsEdit] = useState(false)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  const [Title, setTitle] = useState('Occupancy Graph')

  const [tenancyData, setTenancyData] = useState<any>({})
  const [subTenantData, setSubTenantData] = useState<any>([])
  const [subTenant, setSubTenant] = useState<any>({})
  const [reminderId, setReminderId] = useState<any>('')
  const [unitDataApi, setUnitDataApi] = useState<any>({})
  const [selectedPayments, setSelectedPayments] = useState<any>([])
  const [generateReceipt, setGenerateReceipt] = useState<any>([])
  const [recieptList, setRecieptList] = useState<any>()
  const [showData, setShowData] = useState<any>(false)
  const [cashshowData, setcashShowData] = useState<any>(false)
  const [receiptId, setReceiptId] = useState<any>()
  const [announcementData, setAnnouncementData] = useState<any>()
  const [showTable, setShowTable] = useState<any>(1)
  const [errorModal, setErrorModal] = useState<any>(false)
  const [addRow, setAddRow] = useState<any>(0)
  const [error, setError] = useState<any>(false)
  const [Data, setData] = useState([
    {
      date: 'Page A',
      uv: 4000,
      USD: 2400,
      amt: 2400,
    },
    {
      date: 'Page B',
      USD: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      date: 'Page C',
      USD: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      date: 'Page D',
      USD: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      date: 'Page E',
      USD: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      date: 'Page F',
      USD: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      day: 'Page G',
      USD: 3490,
      pv: 4300,
      amt: 2100,
    },
  ])

  const permission: any = localStorage.getItem('permission')
  const data = JSON.parse(permission)
  function add() {
    setData([
      {
        date: 'Page A',
        uv: 4000,
        USD: 2400,
        amt: 2400,
      },
      {
        date: 'Page B',
        USD: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        date: 'Page C',
        USD: 10000,
        pv: 9800,
        amt: 2290,
      },
      {
        date: 'Page D',
        USD: 3000,
        pv: 3908,
        amt: 2000,
      },
      {
        date: 'Page E',
        USD: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        date: 'Page F',
        USD: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        day: 'Page G',
        USD: 3490,
        pv: 4300,
        amt: 2100,
      },
    ])
  }
  const {RangePicker} = DatePicker
  const dateFormat = 'YYYY-MM-DD'
  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const handleShowData = (value: any) => {
    setShowData(!showData)
    setShowChequeData(value)
  }

  const handleCashshowData = (value: any, id: any) => {
    setcashShowData(!cashshowData)
    setShowBankData(value)
    setPaymentId(id)
  }
  React.useEffect(() => {
    
    getUnit()
  }, [])

  const getUnit = async () => {
    // if (comefrom == 'properties') {
    //   await ApiGet(`corporate/unit/${unitIDfromProperties}`)
    //     .then((res) => {
    //       setUnitDataApi(res?.data?.data)
    //       if (Object.keys(res?.data?.data?.tenancy).length) {
    //         building(res?.data?.data?.tenancy?._id)
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e)
    //     })
    // } else if (comefrom == 'tenancy') {
    await ApiGet(`corporate/unit/${UnitId}`)
      .then((res) => {
        setUnitDataApi(res?.data?.data)
        if (!tenancyId) {
          if (Object.keys(res?.data?.data?.tenancy).length) {
            building(res?.data?.data?.tenancy?._id)
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
    // }
  }
  useEffect(() => {
    if (tenancyId) {
      building(tenancyId)
    }
  }, [])

  const [contractDetails, setContractDetails] = useState<any>()

  const building = async (id: string) => {
    let tenancyunitId: any
    await ApiGet(`corporate/tenancy/full_detail/${id}`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setTenancy(res?.data?.data[0])
        const values: any = {
          contractNo: res?.data?.data[0]?.contractNo,
          tenancyStatus: res?.data?.data[0]?.tenancyStatus,
          start_date: res?.data?.data[0]?.duration?.start_date,
          end_date: res?.data?.data[0]?.duration?.end_date,
          days: res?.data?.data[0]?.duration?.days,
          benefits: res?.data?.data[0]?.benefits,
        }
        setContractDetails(values)
        if(res?.data?.data[0]?.benefits?.other?.length > 0)
          setShowOther(true)
        else
          setShowOther(false)

        setTenantID(res?.data?.data[0]?.tenant[0]?._id)
        tenancyunitId = res?.data?.data[0]?.unitId
        // localStorage.setItem('UnitId', tenancyunitId)
      })
      .catch((e) => {
        console.log(e)
      })
    if (tenancyunitId?.length > 0) {
      // await ApiGet(`corporate/unit/${tenancyunitId}`)
      //   .then((res) => {
      //     // setTableData(res?.data?.data)
      //     setUnitDataApi(res?.data?.data)
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   })
    }
  }
  const handleData = (newData: any) => {
    // if(newData?.length>0){
    setfilter(newData)
    callmoveindata(page1, newData, datefilter, typetable)
    // }
  }
  const handleData1 = (newData: any) => {
    // if(newData){
    setdatefilter(newData)
    callmoveindata(page1, filter, newData, typetable)
    // }

    // setdatefilter(newData)
  }

  // React.useEffect(() => {
  //   if (window.location.pathname.split('/')[1] === 'overViewData') getUnitData()
  // }, [])

  // const getUnitData = async () => {
  //   await ApiGet(`corporate/unit/${tenancyId}`)
  //     .then((res) => {
  //       setUnitDataApi(res?.data?.data)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  // building()

  const getTenancyPayment = (page: any) => {
    let body = {
      page: page,
      limit: 10,
      tenancyId: tenancyId,
    }
    ApiPost('corporate/tenancy_payment/get', body)
      .then((res) => {
        setTenancyPayement(res?.data?.data?.tenancy_payment_data)
        setData_count(res?.data?.data?.state?.data_count)
        setPaymentPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  const handleChangeCheckbox = (id: string) => {
    setSelectedPayments((prev: string[]) => {
      if (prev.find((item: string) => item === id)) {
        return prev.filter((item: string) => item !== id)
      } else {
        return [...prev, id]
      }
    })
  }
  const callmoveindata = (page: any, unitids: any, date: any, typetable: any) => {
    setShowTable(1)
    let body: any = {
      page: page,
      limit: 10,
      type: typetable,
      unitIds: [tenancyId],
    }
    if (unitids?.length > 0) body.unitIds = unitids
    if (date?.startdate && date?.enddate) {
      body.startDate = date?.startdate
      body.endDate = date?.enddate
    }

    ApiPost('corporate/tenant_request/get', body)
      .then((res) => {
        console.log(res)
        settabledata1(res?.data?.data?.tenant_request_data)
        setPage1(res?.data?.data?.state?.page)
        setPageLimit1(res?.data?.data?.state?.page_limit)

        // setPreviousTenancy(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    const selectedPaymentData = selectedPayments.map((item: any) => {
      if (tenancyPayement.find((it: any) => it._id === item)) {
        return tenancyPayement.find((it: any) => it._id === item)
      }
    })
    setGenerateReceipt(selectedPaymentData)
  }, [selectedPayments])

  const getPreviousTenancy = () => {
    let body = {
      page: 1,
      limit: 10,
      unitId: tenancy?.unitId,
    }
    ApiPost('corporate/tenancy/previous/get', body)
      .then((res) => {
        setPreviousTenancy(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }
  const getPreviousTenant = () => {
    let body = {
      page: 1,
      limit: 10,
      tenantId: tenancy?.tenantId,
    }
    ApiPost('corporate/tenant/previous/get', body)
      .then((res) => {
        setPreviousTenants(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }
  const getTenancyReminder = () => {
    let body = {
      page: 1,
      limit: 10,
      tenancyId: tenancyId,
    }
    ApiPost('corporate/reminder/get', body)
      .then((res) => {
        setTenancyReminder(res?.data?.data?.remainder_data)
      })
      .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getTenancyPayment(event.selected + 1)
  }
  // const handlePageClick1 = (event: any) => {
  //   setPage1(event.selected + 1)
  // }

  // const deleteTenancy = () => {
  //   ApiDelete(`corporate/tenancy/${tenancyId}`)
  //     .then((res) => {
  //       SuccessToast(res?.data?.message)
  //       navigate(-1)
  //     })
  //     .catch((err) => console.log('err', err))
  // }
  const calldatabyid = async (id: any, type: any) => {
    console.log(id, type)
    await ApiGet(`corporate/tenant_request?tenantRequestId=${id}&type=${type}`)
      .then((res) => {
        setbyiddata(res?.data?.data[0])
        //  setUnitDataApi(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const requeststatuschange = async (id: any, type: any, state: any) => {
    let body = {
      id: id,
      status: type,
    }
    ApiPut(`corporate/tenant_request/status`, body)
      .then((res) => {
        SuccessToast(res.data.message)
        if (state == 0) {
          setPropertiTypeTH('MoveIn')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 0)
          settypetable(0)
          setmoveinview(false)
        } else if (state == 1) {
          setPropertiTypeTH('MoveOut')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 1)
          settypetable(1)
          setmoveoutview(false)
        } else if (state == 2) {
          setPropertiTypeTH('TenancyRenewals')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 2)
          settypetable(2)
          setrenewalview(false)
        } else if (state == 3) {
          setPropertiTypeTH('TenancyTerminations')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 3)
          settypetable(3)
          setterminationview(false)
        } else if (state == 5) {
          setPropertiTypeTH('GeneralComplaints')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 5)
          settypetable(5)
          setgenralview(false)
        } else if (state == 6) {
          setPropertiTypeTH('OtherRequests')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 6)
          settypetable(6)
          setotherview(false)
        }

        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err) => ErrorToast(err.message))
  }
  const setradiobut = async (state: any) => {
    let body = {
      id: byiddata?._id,
      priority: state,
    }
    ApiPut(`corporate/tenant_request`, body)
      .then((res) => {
        SuccessToast(res.data.message)
        if (propertiTypeTH == 'GeneralComplaints') {
          calldatabyid(byiddata?._id, 5)
        } else if (propertiTypeTH == 'OtherRequests') {
          calldatabyid(byiddata?._id, 6)
        }
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err) => ErrorToast(err.message))
  }
  const callextrapagere = () => {
    setShowCreateAppModal1(false)
    if (propertiTypeTH == 'MoveIn') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 0)
      settypetable(0)
      setmoveinview(false)
    } else if (propertiTypeTH == 'MoveOut') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 1)
      settypetable(1)
      setmoveoutview(false)
    }
    // setPropertiTypeTH('MoveIn')
  }
  const handleSubmit = (type: any) => {
    if (type == 'renew_tenancy') {
      const body = {
        unitId: tenancy?.unitId,
        communityId: tenancy?.communityId,
        buildingId: tenancy?.buildingId,
        isDraft: true,
        tenantId: tenancy?.tenant[0]?._id,
        // tenant: {
        //   firstName: formData?.First,
        //   lastName: formData?.Last,
        //   phoneNumber: formData?.number,
        //   countryCode: formData?.Mobile,
        //   email: formData?.email,
        //   nationality: formData?.nationality,
        //   tenantSource: formData?.Tenant,

        //   DOB: formData?.dob,
        //   document: {
        //     passport: imgPassport ? imgPassport : null,
        //     residency: imgResidency ? imgResidency : "",
        //     id: imgId,
        //     other: imgOther,
        //   },
        // },
        subTenant: subTenantData,
        tenancy: {
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: tenancy?.duration?.start_date,
            end_date: tenancy?.duration?.end_date,
            days: totalDays.split(' ')[0],
          },
          // duration: totalDays,
          // end: end,
          benefits: {
            none: true,
            chillerFree: true,
            maintenanceFree: true,
            other: tenancyData?.other ? tenancyData?.other : [],
          },
          // start: start,
          tenancyStatus: tenancyData?.tenancyStatus,
          totalAmounts: tenancyData?.totalamount,
          totalPayments: tenancyData?.totalpayment,
          document: {
            contract: imgContract,
            other: imgOtherT,
          },
        },
        payment: [
          // {
          //   payement: paymentData?.name,
          //   amount: paymentData?.amount,
          //   method: paymentData?.method,
          //   reminder: paymentData?.reminder,
          //   schedule: paymentData?.schedule,
          //   status: paymentData?.status,
          //   type: paymentData?.type,
          // }
        ],
      }
      // console.log('\nbody', body)
      // const body: any = tenancy;
      ApiPost('corporate/tenancy/renew', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building(unitDataApi?.tenancy?._id)
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      ApiGet(`corporate/tenancy/terminate/${tenancyId}`)
        .then((res) => {
          SuccessToast(res?.data?.message)
          navigate(-1)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const getPaymentRecieptData = async () => {
    const body = {
      limit: 10,
      page: 1,
      tenancyId: tenancyId,
    }

    await ApiPost(`corporate/payment_receipt/get`, body)
      .then((res) => {
        setRecieptList(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }
  useEffect(() => {
    getPaymentRecieptData()
  }, [])

  useEffect(() => {
    // building()
    getTenancyPayment(1)
    getPreviousTenancy()
    getTenancyReminder()
    getPreviousTenant()
    // getUnitById()
    // getCommunityById()
    // getClusterById()
    if (isEdit) {
      // ApiGet(`corporate/unit/${updateDataId}`)
      //   .then((response) => {
      //     setFormData(response?.data?.data)
      //     building()
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    }
  }, [isEdit])

  const navigate = useNavigate()

  const callmodel = (data: any, index: any) => {
    setmodeldata(data)
    setmodelindex(index)
    setcount(count + 1)
    setShowCheange(true)
  }

  const [popUpTitle, setPopUpTitle] = useState<any>(0)
  const callpopUp = (data: any, index: any) => {
    // setmodeldata(data)
    setPopUpTitle(data)
    setmodelindex(index)
    setcount(count + 1)
    setShowPaid(true)
    if (arry[index]) {
      setpopUpData({amount: arry[index].amount})
    }
  }
  const [popupindex, setpopupindex] = useState<any>()

  const popUpSubmit = (event: any, index: any) => {
    event.preventDefault()
    let data: any
    setmodelindex(index)
    if (paymentMethod === '0') {
      data = {
        card: {},
      }
      // data.card.image = popUpReceiptData
    } else if (paymentMethod === '1') {
      data = {
        cheque: {
          chequeNo: popUpData?.chequeNo,
          bankName: popUpData?.bankName,
          image: modelchequeData,
        },
      }
      // data.cheque.receiptImage = popUpReceiptData
      // data.cheque.chequeImage = modelchequeData
    } else if (paymentMethod === '2') {
      data = {
        bank: {
          IBAN: popUpData?.IBAN,
          accountNo: popUpData?.accountNo,
          bankName: popUpData?.bankName,
        },
      }
      // data.bank.image = popUpReceiptData
    } else {
      data = {
        cash: {},
      }
      // data.cash.image = popUpReceiptData
    }
    let receiptd: any = {
      referenceNo: popUpData?.referenceNo,
      receiptDate: popUpData?.receiptDate,
      image: popUpReceiptData,
    }

    let temp: any = {...popUpData, paymentMethod}
    delete temp['bankName']
    delete temp['chequeNo']

    // arry[popupindex].amount = popUpData?.amount || temp.amountPaid
    // arry[popupindex].amountPaid = popUpData?.amount || temp.amountPaid
    // arry[popupindex].amountReceived = temp.amountReceived
    // arry[popupindex].outstandingBalance =
    //   (popUpData?.amount || popUpData?.amountPaid) -
    //   popUpData?.amountReceived -
    //   popUpData?.serviceFee

    // arry[popupindex].serviceFee = temp.serviceFee
    // arry[popupindex].paymentValue = temp.paymentValue
    arry[popupindex].paymentValue = data
    // arry[popupindex].paymentDate = temp.paymentDate
    arry[popupindex].paymentMethod = temp.paymentMethod
    if (temp.receiptDate) {
      arry[popupindex].receiptDate = temp.receiptDate
    }
    if (popUpReceiptData) {
      arry[popupindex].receiptURL = popUpReceiptData
    }
    // if (arry[popupindex].status == '3') {
    //   arry.push({
    //     paymentNo: (Number(arry[popupindex]?.paymentNo) + 0.1).toFixed(1).toString(),
    //     currency: 'AED',
    //     // amount: arry[popupindex].amount - arry[popupindex].outstandingBalance,
    //     amount: arry[popupindex].outstandingBalance
    //       ? arry[popupindex].outstandingBalance
    //       : arry[popupindex].amount,
    //   })
    // }

    if (popUpData.status == '3') {
      arry = arry.map((el: any) => el.status == '3')
    }
    setpopUpData({})
    setmodelchequeData('')
    setpopUpReceiptData('')

    setcount(count + 1)
    setShowPaid(false)
  }
  const varificationCodeValidation = (obj: any) => {
    let errorMsg: any = {
      error: {},
      isValid: true,
    }
    // tenant
    if (obj.outstandingBalance == 0) {
      errorMsg.isValid = false
      errorMsg.error['amountPaid'] = 'On Partial Paid Payment Outstanding balance not 0'
    }

    return errorMsg
  }

  {
    error.amountPaid && (
      <p style={{color: 'var(--kt-danger)'}} className='m-0'>
        {error.amountPaid}
      </p>
    )
  }

  const addNewPartialPayment = (obj: any) => {
    let validationCall: any = varificationCodeValidation(obj)
    setError(validationCall.error)

    if (Object.keys(validationCall.error).length == 0) {
      let key: any = parseInt(obj?.paymentNo || 0)
      arry = arry.map((ele: any) => {
        if (parseInt(ele.paymentNo) === key) {
          ele.is_disable = true
        }
        return ele
      })
      arry.push({
        paymentNo: (Number(obj?.paymentNo) + 0.1).toFixed(1).toString(),
        currency: 'AED',
        amount: obj?.outstandingBalance ? obj?.outstandingBalance : obj?.amount,
        rentType: obj?.rentType,
        is_disable: false,
        key_desable: true,
      })
      setAddRow(addRow + 1)
    } else {
      setErrorModal(true)
    }
  }
  console.log('\narry universal', arry)
  const editNewPartialPayment = (obj: any, i: any) => {
    let key: any = parseInt(obj.paymentNo)
    let temp: any = Number(obj.paymentNo)
    arry = arry.filter((ele: any) => {
      const current_key = parseInt(ele.paymentNo)
      const flag = Number(ele.paymentNo)
      if (current_key === key && flag > temp) {
        return false
      }
      return true
    })
    setAddRow(addRow + 1)
    obj.is_disable = false
  }

  const calculateOutstanding = (bal: any) => {
    const return_cal = Number(
      (bal?.amountPaid || bal?.amount) - bal?.amountReceived - bal?.serviceFee
    )
    return return_cal
  }

  const modelchange = (e: any) => {
    let {name, value} = e.target

    setmodelData({...modelData, [name]: value})
  }
  const modelSubmit = () => {
    let data: any
    if (modeldata == '2') {
      data = {
        cheque: modelData,
      }
      data.cheque.image = modelchequeData
    } else if (modeldata == '3') {
      data = {
        bank: modelData,
      }
    } else if (modeldata == '0') {
      data = {
        card: {},
      }
    } else if (modeldata == '1') {
      data = {
        card: {},
      }
    }
    arry = arry.map((q: any, i: any) => {
      if (i === modelindex) {
        // 👇️ change value of name property
        return {...q, ['paymentValue']: data}
      }
      return q
    })

    setcount(count + 1)
    setShowCheange(false)
  }

  const popUpchange1 = (e: any) => {
    let {name, value} = e.target

    setpopUpData({...popUpData, [name]: value})
  }

  const popUpchange = (name: string, value: any) => {
    // let {name, value} = e.target
    setpopUpData({...popUpData, [name]: value})
    // setpopUpData({...popUpData, [name]: isNaN(value) ? value : +value})
  }

  const handleChnage12 = (e: any) => {
    const {name, value} = e.target
    setPaymentMethod(e.target.value)
  }

  const imagerecipt = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => setpopUpReceiptData(res?.data?.data?.image))
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const imageCheque = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => setmodelchequeData(res?.data?.data?.image))
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const handleRemoveSubTenant = () => {
    ApiDelete(`corporate/tenant/${tenancy?.subTenantIds[0]}`)
      .then((response) => {
        SuccessToast(response?.data?.message)
        // navigate(-1)
        building(unitDataApi?.tenancy?._id)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleTenancy = () => {
    if (tenancy?.tenancyStatus === 0) {
      SuccessToast(
        'Unit is currently Booked. To create a new tenancy must be completed or terminated'
      )
    } else if (tenancy?.tenancyStatus === 1 || tenancy?.tenancyStatus === 2) {
      SuccessToast(
        'Tenancy is currently Active. To create a new tenancy must be completed or terminated'
      )
    } else if (tenancy?.tenancyStatus === 3 || tenancy?.tenancyStatus === undefined) {
      navigate('/create-tenant/1')
    }
  }
  const viewReminder = (id: any) => {
    setReminderId(id)
    setShowviewRemModal(true)
  }
  const unitData = (tanancy: any, unitDataApi: any) => {
    if ((tanancy && unitDataApi) || unitDataApi) {
      if (tanancy?.communityId || unitDataApi) {
        if (unitDataApi?.cluster._id && unitDataApi?.cluster.type === 0) {
          return unitDataApi.cluster.name
        } else if (unitDataApi?.cluster._id && unitDataApi?.cluster.type === 1) {
          return unitDataApi?.cluster.name
        } else if (unitDataApi?.cluster._id && unitDataApi?.cluster.type === 2) {
          if (unitDataApi?.unitGroupId) {
            return unitDataApi?.unitGroupId
          } else {
            return unitDataApi?.cluster.name
          }
        }
      } else {
        return unitDataApi?.buildingId
      }
    } else {
      return '-'
    }
  }

  const unitHeaderData = (tanancy: any, unitDataApi: any) => {
    if ((tanancy && unitDataApi) || unitDataApi) {
      if (tanancy?.communityId || unitDataApi?.communityId) {
        if (unitDataApi?.cluster?._id && unitDataApi?.cluster?.type === 0) {
          return 'Cluster Name'
        } else if (unitDataApi?.cluster?._id && unitDataApi?.cluster?.type === 1) {
          return 'Buildin Cluster'
        } else if (unitDataApi?.cluster?._id && unitDataApi?.cluster?.type === 2) {
          if (unitDataApi?.unitGroupId) {
            return 'Mixed Cluster'
          } else {
            return 'Unit Cluster'
          }
        }
      } else {
        return 'Floor'
      }
    } else {
      return
    }
  }
  const viewTenantProfile = (id: any) => {
    navigate(`/tenant/${id}`)
  }

  const deleteRow = (id: any, data: any) => {
    ApiDelete(`corporate/tenancy_payment/${id}`)
      .then((response) => {
        SuccessToast(response?.data?.message)

        let paymentNo = (Number(data?.paymentNo) + 0.1).toFixed(1)
        const nextDelete: any = tenancyPayement.find((e: any) => e.paymentNo == paymentNo)
        if (nextDelete) {
          deleteRow(nextDelete._id, nextDelete)
        }
        getTenancyPayment(page)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addFileds = () => {
    setPayment(payment + 1)
    settotalpayment(totalpayment + 1)

    if (arry.length) {
      arry.push({
        paymentNo: (Math.max(...arry.map((ele: any) => Number(ele.paymentNo))) + 1).toFixed(),
        currency: 'AED',
        amount: '',
        is_disable: false,
        // paymentMethod: 3,
        // rentType: 0,
        // paymentRemainder: null,
        // paymentSchedule: null,
        // status: 0,
        // amountPaid: 0,
        // outstandingBalance: 0,
        // amountReceived: 0,
        // serviceFee: 0,
      })
    } else {
      arry.push({
        paymentNo: (data_count + 1).toString(),
        currency: 'AED',
        amount: '',
        is_disable: false,
      })
    }
  }

  const checkDate = (val: any) => {
    if (val) {
      let date: any = moment(val).format('YYYY-MM-DD')
      return date
    } else {
      return ''
    }
  }
  const handleChnagePayment = (e: any, i: any, v: any) => {
    let {name, value} = e.target
    if (
      name == 'amount' ||
      name == 'amountPaid' ||
      name == 'paymentMethod' ||
      name == 'rentType' ||
      name == 'serviceFee' ||
      name == 'status'
    ) {
      value = parseInt(value)
    }
    if (name == 'amount') {
      let dat = arry[i].amount
      if (!value) {
        value = 0
      }
      if (dat) {
        settotalamount(totalamount - dat + value)
      } else {
        settotalamount(totalamount + value)
      }
    }
    if (name == 'paymentSchedule') {
      var d = new Date(value)
      arry = arry.map((q: any, j: any) => {
        if (j === i) {
          // 👇️ change value of name property
          return {...q, ['paymentRemainder']: moment(d).format('YYYY-MM-DD')}
        }
        return q
      })
    }
    if (name == 'status' && value == 3) {
      settotalpayment(totalpayment + 1)
      // arry.push({
      //   paymentNo: (Number(v?.paymentNo) + 0.1).toFixed(1).toString(),
      //   currency: 'AED',
      //   amount: `${v?.amount} - ${v?.outstandingBalance}`,
      // })
    }
    arry = arry.map((q: any, k: any) => {
      if (k === i) {
        // 👇️ change value of name property
        if (name == 'serviceFee') {
          return {
            ...q,
            [name]: value,
            outstandingBalance:
              q?.amountPaid == 0
                ? Number(q?.amount)
                : Number(q?.amount) - Number(q?.amountPaid) || 0,

            amountReceived:
              q?.serviceFee == 0 ? Number(q?.amount) || 0 : Number(q?.amount) - Number(value) || 0,
          }
        } else if (name == 'amountPaid') {
          return {
            ...q,
            [name]: value,
            outstandingBalance:
              q?.amountPaid == 0 ? Number(q?.amount) || 0 : Number(q?.amount) - Number(value) || 0,

            amountReceived:
              q?.serviceFee == 0
                ? Number(q?.amount) || 0
                : Number(q?.amount) - Number(q?.serviceFee) || 0,
          }
        } else {
          return {
            ...q,
            [name]: value,
          }
        }
      }
      return q
    })
    arry = arry.map((q: any, k: any) => {
      // if (q.paymentNo == Number(arry[i]?.paymentNo) + 0.1) {
      if (q.paymentNo == (Number(arry[`${k - 1}`]?.paymentNo) + 0.1).toFixed(1)) {
        if (name == 'amountPaid') {
          return {
            ...q,
            // [name]: value,
            // amount: q.amount - q.amountPaid,
            amount: arry[`${k - 1}`].amount - arry[`${k - 1}`].amountPaid,
          }
        }
        if (name == 'status' && value == 3) {
          return {
            ...q,
            status: 3,
          }
        }
      }
      return q
    })

    setcount(count + 1)
  }
  function isFloat(n: any) {
    return n % 1 === 0
  }
  const deleteRowTable = (i: any, amount: any) => {
    setAddRow(addRow + 1)

    let obj: any = arry[i]
    let key: any = parseInt(obj.paymentNo)
    let temp: any = Number(obj.paymentNo)
    arry = arry.filter((ele: any) => {
      const current_key = parseInt(ele.paymentNo)
      const flag = Number(ele.paymentNo)
      if (current_key === key && flag >= temp) {
        return false
      }
      return true
    })
    if (isFloat(obj.paymentNo)) {
      arry = arry.map((newel: any, j: any) => {
        if (obj.paymentNo < newel.paymentNo) {
          newel.paymentNo = (Number(newel.paymentNo) - 1).toString()
        }

        return newel
      })
    }

    // arry = arry.filter((v: any, index: any) => index !== i)
    if (amount == undefined) {
      amount = 0
    }
    settotalamount(totalamount - amount)
    settotalpayment(totalpayment - 1)
    setcount(count + 1)
    if (arry.length === 0) {
      setCard('')
    }
    let a: any = []
    arry.map((v: any) => {
      if (v?.paymentMethod === 0) {
        a.push(v?.paymentMethod)
      }
      if (v?.paymentMethod === 1) {
        a.push(v?.paymentMethod)
      }
    })
    const ages = a
    const uniqueAges = ages.filter((x: any, i: any, a: any) => a.indexOf(x) == i)
    if (uniqueAges.length === 2) {
      setCard('2')
    }
    if (uniqueAges.length === 1) {
      if (uniqueAges[0] === 0) {
        setCard('0')
      } else {
        setCard('1')
      }
    }
    if (uniqueAges.length === 0) {
      if (a.length === 0) {
        setCard('')
      }
    }

    // setArry(arry)
  }

  const handleAddPayment = () => {
    arry.map((i: any, index: any) => {
      const cheque = i.paymentValue?.cheque
      const bank = i?.paymentValue?.bank
      const receipt = i?.receipt
      const card = i?.paymentValue?.card

      let paymentValue: any = {}
      if (cheque) {
        paymentValue['cheque'] = cheque
      }
      if (bank) {
        paymentValue['bank'] = bank
      }
      if (card) {
        paymentValue['card'] = card
      }
      const body = {
        paymentNo: i?.paymentNo,
        tenancyId: tenancyId ? tenancyId : tenancy._id,
        tenantId: tenantId ? tenantId : tenantID,
        comment: 'Test',
        paymentSchedule: i?.paymentSchedule || null,
        paymentRemainder: i?.paymentRemainder || null,
        currency: 'AED',
        paymentMethod: i?.paymentMethod,
        status: i?.status,
        rentType: i?.rentType,
        amount: i?.amount,
        amountPaid: i?.status == 1 ? i?.amount : i?.amountPaid,
        amountReceived: i?.paymentMethod !== '0' ? i?.amount : i?.amountReceived,
        serviceFee: i?.paymentMethod !== '0' ? 0 : i?.serviceFee,
        outstandingBalance: i?.status == 1 ? 0 : i?.outstandingBalance,
        receiptDate: i?.receiptDate,
        paymentDate: i?.paymentDate,
        paymentValue,
      }
      console.log('\nbody', body)
      ApiPost('corporate/tenancy_payment', body)
        .then((res) => {
          SuccessToast(res.data.message)
          getTenancyPayment(page)
          arry = []
        })
        .catch((err) => ErrorToast(err?.message))
    })
  }

  const EditFileds = async (id: any) => {
    setEdit(true)
    setIsEditPaymentId(id)
    await ApiGet(`corporate/tenancy_payment/${id}`)
      .then((res) => {
        setPayment(res?.data?.data[0]?.paymentNo)
        settotalpayment(1)
        arry.push({...res?.data?.data[0], is_disable: false})
        setEditData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleEditPayment = () => {
    setEdit(false)

    arry.map((i: any) => {
      const cheque = i?.paymentValue?.cheque
      const bank = i?.paymentValue?.bank
      const body = {
        id: i?._id,
        paymentNo: i?.paymentNo,
        tenancyId: i?.tenancyId,
        tenantId: i?.tenantId,
        comment: '',
        paymentSchedule: i?.paymentSchedule,
        paymentRemainder: i?.paymentRemainder,
        currency: i?.currency,
        paymentMethod: i?.paymentMethod,
        rentType: i?.rentType,
        rentOtherName: i?.rentOtherName,
        amount: i?.amount,
        amountPaid: i?.amountPaid,
        amountReceived: i?.amountReceived,
        outstandingBalance: i?.outstandingBalance,
        status: i?.status,
        paymentValue: {
          bank: {
            bank,
          },
          cheque: {
            cheque,
          },
        },
      }
      ApiPut(`corporate/tenancy_payment`, body)
        .then((res) => {
          SuccessToast(res.data.message)
          getTenancyPayment(page)
          arry = []
          setIsEditPaymentId('')
        })
        .catch((err) => ErrorToast(err.message))
    })
  }

  const handleSubmitViewReceipt = (id: any) => {
    setShowPaymentReceipt(true)
    setReceiptId(id)
  }
  const handleSubmitEditReceipt = (id: any) => {
    setShowEditReceipt(true)
    setReceiptId(id)
  }

  const getImage: any = () => {
    const imgType = tenancy?.unit?.[0]?.unitType
    if (tenancy?.unit?.[0]?.images?.[0]?.length > 0) {
      return (
        <img src={`${Bucket}${tenancy?.unit?.[0]?.images[0]}`} alt='' width={170} height={150} />
      )
    } else if (imgType === 'town_house') {
      return <img src={townhouse} alt='' width={170} height={150} />
    } else if (imgType === 'other') {
      return <img src={other} alt='' width={170} height={150} />
    } else if (imgType === 'common_area') {
      return <img src={common_area} alt='' width={170} height={150} />
    } else if (imgType === 'villa') {
      return <img src={villa} alt='' width={170} height={150} />
    } else if (imgType === 'apartment') {
      return <img src={appartment} alt='' width={170} height={150} />
    } else {
      return <img src={penthouse} alt='' width={170} height={150} />
    }
  }

  const addSubTenant = async () => {
    setSubTenant({})
    setShowSubTenantModal(false)
    const body = {
      firstName: subTenantData?.firstName,
      lastName: subTenantData?.lastName,
      phoneNumber: subTenant?.phoneNumber,
      countryCode: subTenant?.countryCode,
      email: subTenantData?.email,
      tenantType: 1,
    }
    await ApiPost(`corporate/tenant`, body)
      .then((res) => {
        SuccessToast(res.data.data.message)
      })
      .catch((err) => ErrorToast(err?.message))
  }

  const handleNumber1 = (Phone: any, e: any) => {
    let CountryCode = e.dialCode
    let PhoneNumber = Phone.split(CountryCode)[1]

    setSubTenant({
      ...subTenant,
      ['countryCode']: CountryCode,
      ['phoneNumber']: PhoneNumber,
    })
  }
  const getAnnouncementData = () => {
    let body = {
      page: page,
      limit: 10,
      search: '',
      clusterId: null,
      buildingId: null,
      communityId: null,
      floorId: null,
      unitGroupId: null,
    }
    ApiPost('corporate/announcement/overview', body)
      .then((res) => {
        setAnnouncementData(res?.data?.data?.announcement_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getAnnouncementData()
  }, [])

  const editTenanct = () => {
    if (window.location.pathname?.split('/')[1] == 'tenancy-details') {
      navigate(`/create-tenant/3/${tenantId}/${tenancyId}`)
      // navigate(`/create-tenant/3/${tenantId}/${tenancyId}`)
    } else if (window.location.pathname?.split('/')[1] == 'overViewData') {
      // navigate(`/create-tenant/3/${tenantID}/${tenancyId}`)
      navigate(`/create-tenant/3/${tenantID}/${tenancy?._id}`)
    }
  }

  const [isEditContract, setIsEditContract] = useState<any>(false)
  const [showOther, setShowOther] = useState<any>(false)

  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })

  const updateContractDetails = () => {
    const body = {
      id: tenancy?._id,
      contractNo: contractDetails?.contractNo,
      duration: {
        start_date: contractDetails?.start_date,
        end_date: contractDetails?.end_date,
        days: parseInt(contractDetails?.days),
        // _id: newTenancyData?.duration?._id,
      },
      tenancyStatus: contractDetails?.tenancyStatus,
      benefits: contractDetails?.benefits
    }

    ApiPut('corporate/tenancy', body)
      .then((res) => {
        setIsEditContract(false)
        SuccessToast('Tenancy Contract Details has been updated successfully..')
        building(tenancy?._id)
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        {/* <div className='d-flex flex-column flex-column-fluid'> */}
        {/* <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'> */}
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          {/* <div className='d-flex align-items-center flex-wrap mr-1 '>
            <div className='d-flex align-items-baseline flex-wrap mr-5'>
              <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                <li className='breadcrumb-item '>
                  <a
                    className='text-muted px-2 cursor-pointer'
                    onClick={() => navigate('/tenancies')}
                  >
                    tenancies
                  </a>
                </li>
                <li className='breadcrumb-item active'>
                  <a className='px-2'>Property details</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className='card card-flush mt-8'>
            <Row className='m-0'>
              <Col md={3} className='p-5'>
                <div className='d-flex gap-5 align-items-center'>
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    <img src={leftarrow} />
                  </span>
                  <h2 className='m-0'>Property Details</h2>
                </div>
              </Col>
              <Col md={9} className='p-5'>
                <div className='d-flex justify-content-end'>
                  <button className='btn btn-sm fw-bold btn-primary btn-green hover-none'>
                    Edit
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div className='m-5'>{getImage()}</div>
              </Col>
              <Col md={10} className='m-auto'>
                <div className='card-header align-items-center py-5  gap-md-2 d-flex border-0 p-0'>
                  <Row>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Unit No
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.unitNo
                            ? tenancy?.unit?.[0]?.unitNo
                            : unitDataApi?.unitNo}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        {unitDataApi.length > 0 && unitHeaderData(tenancy, unitDataApi)}
                        <span className='mx-1' style={{color: 'black'}}>
                          {unitDataApi.length > 0 && unitData(tenancy, unitDataApi)}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Bedrooms{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.bedrooms
                            ? tenancy?.unit?.[0]?.bedrooms
                            : unitDataApi?.bedrooms}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Tenant Name{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenant?.[0]?.firstName} {tenancy?.tenant?.[0]?.lastName}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Rent Status
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenancy_payment?.[0]?.status === 0
                            ? 'Pending'
                            : tenancy?.tenancy_payment?.[0]?.status === 1
                            ? 'Paid'
                            : 'Overdue'}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Building Name{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.cluster?.[0]?.name
                            ? tenancy?.unit?.[0]?.cluster?.[0]?.name
                            : unitDataApi?.cluster?.name}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Size{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.size ? tenancy?.unit?.[0]?.size : unitDataApi?.size}{' '}
                          {tenancy?.unit?.[0]?.sizeType
                            ? tenancy?.unit?.[0]?.sizeType == '0'
                              ? 'Sq ft'
                              : 'sq m'
                            : unitDataApi?.sizeType == '0'
                            ? 'Sq ft'
                            : 'sq m'}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Tenancy Start Date - End Date{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')} -{' '}
                          {moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Rent Amount{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenancy_payment?.[0]?.amount} AED
                        </span>
                      </h6>
                    </Col>
                    {tenancy?.unit?.[0]?.cluster?.area ? (
                      <Col md={6} className='min-w-300px'>
                        <h6 className='mx-10 text-muted'>
                          Area{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.unit?.[0]?.cluster?.area}
                          </span>
                        </h6>
                      </Col>
                    ) : (
                      <Col md={6} className='min-w-300px'>
                        <h6 className='mx-10 text-muted'>
                          Area{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {unitDataApi?.cluster?.area}
                          </span>
                        </h6>
                      </Col>
                    )}
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Occupancy{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.occupy
                            ? tenancy?.unit?.[0]?.occupy === 1
                              ? 'Vacant'
                              : 'Occupied'
                            : unitDataApi?.occupy === 1
                            ? 'Vacant'
                            : 'Occupied'}
                        </span>
                      </h6>
                    </Col>
                    {/* <Col md={6} className='min-w-300px' >
                    <h6 className='mx-10 text-muted'>
                      No. of Payments <span className='mx-1' style={{ color: 'black' }}></span>
                    </h6>
                  </Col> */}
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Last Rent Received{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenancy_payment?.[0]?.paymentSchedule
                            ? moment(tenancy?.tenancy_payment?.[0]?.paymentSchedule).format(
                                'DD/MM/YYYY'
                              )
                            : '-'}
                        </span>
                      </h6>
                    </Col>
                    {tenancy?.unit?.[0]?.unitType ? (
                      <Col md={6} className='min-w-300px'>
                        <h6 className='mx-10 text-muted'>
                          Property Type{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.unit?.[0]?.unitType
                              ? tenancy?.unit?.[0]?.unitType
                              : unitDataApi?.unitType === 'town_house'
                              ? 'TownHouse'
                              : tenancy?.unit?.[0]?.unitType === 'other'
                              ? 'Other'
                              : tenancy?.unit?.[0]?.unitType === 'common_area'
                              ? 'Common Area'
                              : tenancy?.unit?.[0]?.unitType === 'villa'
                              ? 'Villa'
                              : tenancy?.unit?.[0]?.unitType === 'apartment'
                              ? 'Apartment'
                              : tenancy?.unit?.[0]?.unitType === 'penthouse'
                              ? 'Penthouse'
                              : ''}
                          </span>
                        </h6>
                      </Col>
                    ) : (
                      <Col md={6} className='min-w-300px'>
                        <h6 className='mx-10 text-muted'>
                          Property Type{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {unitDataApi?.unitType === 'town_house'
                              ? 'TownHouse'
                              : unitDataApi?.unitType === 'other'
                              ? 'Other'
                              : unitDataApi?.unitType === 'common_area'
                              ? 'Common Area'
                              : unitDataApi?.unitType === 'villa'
                              ? 'Villa'
                              : unitDataApi?.unitType === 'apartment'
                              ? 'Apartment'
                              : unitDataApi?.unitType === 'penthouse'
                              ? 'Penthouse'
                              : ''}
                          </span>
                        </h6>
                      </Col>
                    )}
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Property Manager{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.unit?.[0]?.managerId
                            ? tenancy?.unit?.[0]?.managerId
                            : unitDataApi?.managerId}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Property ID{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.id ? tenancy?.id : unitDataApi?.id}
                        </span>
                      </h6>
                    </Col>
                    <Col md={6} className='min-w-300px'>
                      <h6 className='mx-10 text-muted'>
                        Rating{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          <span className='svg-icon svg-icon-2' style={{color: '#ffad0f'}}>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                fill='currentColor'
                              ></path>
                            </svg>
                          </span>
                          {tenancy?.unit?.[0]?.rating
                            ? tenancy?.unit?.[0]?.rating
                            : unitDataApi?.rating}
                        </span>
                      </h6>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className='d-flex align-items-center gap-2 gap-lg-3 mt-8 justfy-content-center'
            style={{justifyContent: 'center'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              {data.property === true && (
                <li
                  className={
                    `nav-item cursor-pointer ` + (propertiType === 'Overview' ? 'active' : '')
                  }
                  onClick={() => {
                    setPropertiType('Overview')
                  }}
                >
                  <a
                    className={
                      `nav-link text-active-primary pb-4 text-center mx-10 ` +
                      (propertiType === 'Overview' ? 'active' : '')
                    }
                    data-bs-toggle='tab'
                  >
                    Overview
                  </a>
                </li>
              )}
              {data.tenancy === true && (
                <li
                  className={
                    `nav-item cursor-pointer` + (propertiType === 'Tenancy' ? 'active' : '')
                  }
                  onClick={() => {
                    setPropertiType('Tenancy')
                  }}
                >
                  <a
                    className={
                      `nav-link text-active-primary pb-4 text-center mx-10 ` +
                      (propertiType === 'Tenancy' ? 'active' : '')
                    }
                    data-bs-toggle='tab'
                  >
                    {/* <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                    <img src={penthouse} alt='' width='30px' />{' '}
                  </div> */}
                    Tenancy
                  </a>
                </li>
              )}
              {/* {data.tenancy === true && (
                <li
                  className={
                    `nav-item cursor-pointer` + (propertiType === 'Tenants' ? 'active' : '')
                  }
                  onClick={() => {
                    // setPropertiType('Tenants')
                    // 123
                    navigate(`/tenants/${data.id}`)
                  }}
                >
                  <a
                    className={
                      `nav-link text-active-primary pb-4 text-center mx-10 ` +
                      (propertiType === 'Tenants' ? 'active' : '')
                    }
                    data-bs-toggle='tab'
                  >
                    
                    Tenants
                  </a>
                </li>
              )} */}
              {data.tenancy === true && (
                <li
                  className={
                    `nav-item cursor-pointer ` + (propertiType === 'Payments' ? 'active' : '')
                  }
                  onClick={() => {
                    setPropertiType('Payments')
                  }}
                >
                  <a
                    className={
                      `nav-link text-active-primary pb-4 text-center mx-10 ` +
                      (propertiType === 'Payments' ? 'active' : '')
                    }
                    data-bs-toggle='tab'
                  >
                    {/* <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                    <img src={penthouse} alt='' width='30px' />{' '}
                  </div> */}
                    Payments & Rent
                  </a>
                </li>
              )}
              {data.announcement === true && (
                <li
                  className={
                    `nav-item cursor-pointer ${propertiType}` +
                    (propertiType === 'Announcements' ? 'active' : '')
                  }
                  onClick={() => {
                    setPropertiType('Announcements')
                  }}
                >
                  <a
                    className={
                      `nav-link text-active-primary pb-4 text-center mx-10 ` +
                      (propertiType === 'Announcements' ? 'active' : '')
                    }
                    data-bs-toggle='tab'
                  >
                    {/* <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                    <img src={penthouse} alt='' width='30px' />{' '}
                  </div> */}
                    Announcements
                  </a>
                </li>
              )}

              {data.externalServiceRequest === true ||
                (data.internalServiceRequest === true && (
                  <li
                    className={
                      `nav-item cursor-pointer ${propertiType}` +
                      (propertiType === 'Requests' ? 'active' : '')
                    }
                    onClick={() => {
                      setPropertiType('Requests')
                    }}
                  >
                    <a
                      className={
                        `nav-link text-active-primary pb-4 text-center mx-10 ` +
                        (propertiType === 'Requests' ? 'active' : '')
                      }
                      data-bs-toggle='tab'
                    >
                      {/* <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                    <img src={penthouse} alt='' width='30px' />{' '}
                  </div> */}
                      Requests
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {/* </div> */}

        <div
          id='kt_app_content'
          // className='app-content flex-column-fluid pt-0'
          style={{paddingTop: '0px'}}
        >
          <div id='kt_app_content_container' className='app-container container-xxl mt-10'>
            <div className=' card-flush mb-10' style={{background: 'transparent'}}>
              {propertiType === 'Overview' && (
                <div className='card card-flush mb-10'>
                  <Row>
                    <Col md={6} className='p-10'>
                      <div id='pie' className='m-0'>
                        {/* <button onClick={() => add()}> Click </button> */}
                        <Chart />
                        <h1 className='top green_color mb-8'>{Title}</h1>
                        <LineChart
                          width={500}
                          height={300}
                          data={Data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray='3 3' />
                          <XAxis dataKey='date' />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line type='monotone' dataKey='USD' stroke='#007a59' />
                        </LineChart>
                        <Chart />
                      </div>
                    </Col>
                    <Col md={6} className='mt-10 m-auto'>
                      <div className='mb-10  min-w-200px'>
                        <h6 className='mx-10 text-muted'>
                          Average Occupancy Rate{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            57%
                          </span>
                        </h6>
                      </div>
                      <div className='mb-10  min-w-200px'>
                        <h6 className='mx-10 text-muted'>
                          Total Service Requests{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            15
                          </span>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              {propertiType === 'Tenancy' && (
                <>
                  <div className='grid-out p-0 container-xxl' style={{backgroundColor: 'none'}}>
                    <div className='card tops p-5 m-0 green_color '>
                      {tenancy?.duration?.end_date ? (
                        <div
                          className='container-xxl d-flex  m-0'
                          style={{justifyContent: 'right'}}
                        >
                          <div className=''>
                            {/* <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              // onClick={() => navigate(`/edit-tenancy/${tenancy?._id}`)}
                              onClick={() => editTenanct()}
                            >
                              Edit Tenancy
                            </a> */}
                            {/* {(unitDataApi.tenancyStatus === 2 || unitDataApi.tenancyStatus === 3) && */}
                            {(tenancy.tenancyStatus === 1 ||
                              tenancy.tenancyStatus === 2 ||
                              tenancy.tenancyStatus === 3) && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                // onClick={() => handleSubmit('renew_tenancy')}
                                onClick={() => {
                                  navigate(`/create-tenant/2/${tenantId}/${tenancyId}`)
                                  localStorage.setItem('isDraft', '2')
                                }}
                              >
                                Renew Tenancy
                              </a>
                            )}

                            {(tenancy.tenancyStatus === 1 || tenancy.tenancyStatus === 2) && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() => handleSubmit('terminate_tenancy')}
                              >
                                Terminate Tenancy
                              </a>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className=' container-xxl d-flex flex-stack p-0 m-0'
                          style={{justifyContent: 'left'}}
                        >
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            onClick={() => handleTenancy()}
                          >
                            Create Tenancy
                          </a>
                        </div>
                      )}
                    </div>

                    {isEditContract ? (
                      <div className='card side px-5 py-3'>
                        <div className='d-flex mb-3'>
                          <h2 className='mx-0 green_color '>Contract Details</h2>

                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3 ms-auto'
                            onClick={() => updateContractDetails()}
                          >
                            Update
                          </a>

                          <a
                            className='btn btn-sm fw-bold ms-3 text-white'
                            onClick={() => {
                              const values: any = {
                                contractNo: tenancy?.contractNo,
                                tenancyStatus: tenancy?.tenancyStatus,
                                start_date: tenancy?.duration?.start_date,
                                end_date: tenancy?.duration?.end_date,
                                days: tenancy?.duration?.days,
                                benefits: tenancy?.benefits
                              }
                              setContractDetails(values)
                              setIsEditContract(false)
                            }}
                            style={{
                              backgroundColor: '#D54645',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                            }}
                          >
                            Cancel
                          </a>
                        </div>

                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Contract No.
                          </label>
                          <input
                            type='number'
                            // {...register('contractNo', {required: true})}
                            className='form-control form-control-solid'
                            placeholder=''
                            name='contractNo'
                            value={contractDetails?.contractNo}
                            onChange={(e) => {
                              const values = {...contractDetails}
                              values.contractNo = e.target.value
                              setContractDetails(values)
                            }}
                          />
                          {/* {errors.contractNo && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )} */}
                        </div>

                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Tenancy Status
                          </label>
                          <select
                            className='form-select form-select-solid'
                            // {...register('tenancyStatus', {required: true})}
                            value={Number(contractDetails?.tenancyStatus)}
                            onChange={(e) => {
                              const values = {...contractDetails}
                              values.tenancyStatus = e.target.value
                              setContractDetails(values)
                            }}
                            name='tenancyStatus'
                          >
                            <option selected value=''>
                              Select
                            </option>
                            <option value={0}>Booked</option>
                            <option value={1}>Active</option>
                            <option value={4}>Renewed</option>
                          </select>
                          {/* {errors.tenancyStatus && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )} */}
                        </div>

                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Start - End
                          </label>
                          {/* <Controller
                          control={control}
                          name='start'
                          render={({
                            field: {onChange, onBlur, value, name, ref},
                            fieldState: {invalid, isTouched, isDirty, error},
                            formState,
                          }) => ( */}

                          <RangePicker
                            value={[
                              moment(
                                tenancy?.contractDetails?.start_date
                                  ? tenancy?.contractDetails?.start_date
                                  : new Date(),
                                dateFormat
                              ),
                              moment(
                                tenancy?.contractDetails?.end_date
                                  ? tenancy?.contractDetails?.end_date
                                  : new Date(),
                                dateFormat
                              ),
                            ]}
                            // value={[moment(start_date), moment(end_date)]}
                            defaultValue={[
                              moment(
                                tenancy?.contractDetails?.start_date
                                  ? tenancy?.contractDetails?.start_date
                                  : new Date(),
                                dateFormat
                              ),
                              moment(
                                tenancy?.contractDetails?.end_date
                                  ? tenancy?.contractDetails?.end_date
                                  : new Date(),
                                dateFormat
                              ),
                            ]}
                            format={dateFormat}
                            className='form-control form-control-solid'
                            onChange={(dates: any) => {
                              const values = {...contractDetails}
                              values.start_date = dates?.[0].format('YYYY-MM-DD')
                              values.end_date = dates?.[1].format('YYYY-MM-DD')

                              var start = moment(dates?.[0])
                              var end = moment(dates?.[1])

                              values.days = end.diff(start, 'days', true)
                              setContractDetails(values)
                            }}
                          />
                          {/* )}
                        /> */}
                          {/* {errors.start && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )} */}
                        </div>

                        <div
                          className='text-muted grid_big_col py-2 '
                          style={{alignItems: 'flex-start'}}
                        >
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Duration
                          </label>
                          <div className='form-control form-control-solid'>
                            {' '}
                            {contractDetails?.days}
                            {' days'}{' '}
                          </div>
                          {/* <div className='form-control form-control-solid'>  {tenancy?.remainingDays === 1
                              ? tenancy?.remainingDays + ' Day'
                              : tenancy?.remainingDays >= 2
                              ? tenancy?.remainingDays + ' Days'
                              : tenancy?.remainingDays <= 0
                              ? 0 + ' Days'
                              : ''} </div> */}
                          {/* {errors.start && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>} */}
                        </div>

                         <div
                        className='d-flex align-items-left py-2 gap-5 align-items-center'
                        // style={{flexDirection: 'column'}}
                      >
                        <label className='required form-label'>
                          <span className=''>Benefits</span>
                        </label>
                        <div className='d-flex align-items-center py-2 gap-5 mx-2'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={contractDetails?.benefits?.none === true ? true : false}
                              onChange={ (e:any) => {
                                const values = {...contractDetails}
                                values.benefits.none =  !values.benefits.none
                                values.benefits.chillerFree =  false
                                values.benefits.maintenanceFree =  false
                                setContractDetails(values)
                              }}
                            />
                            <label className='form-check-label' htmlFor='vacant0'>
                              None
                            </label>
                          </div>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied1'
                              name='chillerFree'
                              checked={contractDetails?.benefits?.chillerFree === true ? true : false}
                              disabled={
                                contractDetails?.benefits?.none === true ? true : false
                              }
                              onChange={ (e:any) => {
                                const values = {...contractDetails}
                                values.benefits.chillerFree =  !values.benefits.chillerFree
                                setContractDetails(values)
                              }}
                            
                            />
                            <label className='form-check-label' htmlFor='occupied1'>
                              Chiller Free
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied2'
                              name='maintenanceFree'
                              checked={contractDetails?.benefits?.maintenanceFree === true ? true : false}
                              disabled={
                                contractDetails?.benefits?.none === true ? true : false
                              }
                              onChange={ (e:any) => {
                                const values = {...contractDetails}
                                values.benefits.maintenanceFree =  !values.benefits.maintenanceFree
                                setContractDetails(values)
                              }}
                            />
                            <label className='form-check-label' htmlFor='occupied2'>
                              Maintenance Free
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied3'
                              name='other'
                              checked={(contractDetails?.benefits?.other?.length > 0 || showOther == true) ? true : false}
                              onChange={(e) => {
                                setShowOther(!showOther)
                              
                                if(e.target.checked ==  false){
                                const values = {...contractDetails}
                                values.benefits.other = []
                                setContractDetails(values)
                                }

                              }}
                            />
                            <div className=''>
                              <label className='form-check-label' htmlFor='occupied3'>
                                Other
                              </label>
                            </div>
                          </div>
                          </div>

                          {showOther && (
                             <>
                             <div className='d-flex gap-3'>
                               <img src={info} width='24px' height='24px' />
                               <p className='m-0 mb-4'>Please enter comma separated values</p>
                             </div>
                             <input
                               type='other'
                               className='form-control form-control-solid'
                               placeholder=''
                               name='other'
                               value={contractDetails?.benefits?.other}
                               onChange={(e:any)=> {
                                  // let {name, checked} = e.target
                                  // console.log('e.target.value', e.target.value)
                                  // if (name == 'other') {
                                  //   setOptionArry({...optionArry, [name]: [e.target.value]})
                                  // }

                                  const values = {...contractDetails}
                                  values.benefits.other =  e.target.value
                                  setContractDetails(values)

                               }}
                             />
                           </>
                          )}
                      </div>
                    ) : (
                      <div className='card side px-5 py-3'>
                        <div className='d-flex'>
                          <h2 className='mx-0 green_color '>Contract Details</h2>

                          {/* <img
                            src={pen}
                            height='18'
                            width='18'
                            className='cursor-pointer ms-auto'
                            onClick={() => {
                              setIsEditContract(true)
                            }}
                            style={{cursor: 'pointer'}}
                          /> */}
                        </div>
                        <h6 className='mx-0 text-muted'>
                          Contract ID
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?._id}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          Contract No.{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.contractNo}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          Tenancy Status{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.tenancyStatus === 0
                              ? 'Booked'
                              : tenancy?.tenancyStatus === 1
                              ? 'Active'
                              : tenancy?.tenancyStatus === 2
                              ? 'Expiring'
                              : tenancy?.tenancyStatus === 3
                              ? 'Ended'
                              : tenancy?.tenancyStatus === 4
                              ? 'Renewed'
                              : tenancy?.tenancyStatus === 5
                              ? 'Terminated'
                              : tenancy?.tenancyStatus === 6
                              ? 'Tenant pending approval'
                              : '-'}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          Remaining{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.remainingDays === 1
                              ? tenancy?.remainingDays + ' Day'
                              : tenancy?.remainingDays >= 2
                              ? tenancy?.remainingDays + ' Days'
                              : tenancy?.remainingDays <= 0
                              ? 0 + ' Days'
                              : ''}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          Start{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.duration?.start_date
                              ? moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')
                              : 'No Date'}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          End{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.duration?.end_date
                              ? moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')
                              : 'No Date'}
                          </span>
                        </h6>
                        <h6 className='mx-0 text-muted'>
                          Duration{' '}
                          <span className='mx-1' style={{color: 'black'}}>
                            {tenancy?.duration?.days === 1
                              ? tenancy?.duration?.days + ' Day'
                              : tenancy?.duration?.days >= 2
                              ? tenancy?.duration?.days + ' Days'
                              : tenancy?.duration?.days <= 0
                              ? 0 + ' Days'
                              : ''}
                          </span>
                        </h6>
                        {(tenancy?.benefits?.chillerFree ||
                          tenancy?.benefits?.maintenanceFree ||
                          tenancy?.benefits?.other) && (
                          <div className='min-w-200px'>
                            <h6 className='mx-0 text-muted'>
                              Benefits{' '}
                              <span className='mx-1' style={{color: 'black'}}>
                                {tenancy?.benefits?.chillerFree === true ? (
                                  <>
                                    <span className='dot'></span> - Chiller Free{' '}
                                  </>
                                ) : (
                                  ''
                                )}
                                {tenancy?.benefits?.maintenanceFree ? (
                                  <>
                                    <span className='dot'></span> - Maintenance Free{' '}
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* {tenancy?.benefits?.other
                                  ? ' - ' + tenancy?.benefits?.other.join('- ')
                                  : ''} */}
                                {/* {tenancy?.benefits.values().join(', ')} */}
                              </span>
                            </h6>
                          </div>
                        )}
                      </div>
                    )}
                    <div className='card side px-5 py-3 gap-5 '>
                       <div className='d-flex'>
                          <h2 className='mx-0 green_color '>Documents</h2>

                          {/* <img
                            src={pen}
                            height='18'
                            width='18'
                            className='cursor-pointer ms-auto'
                            onClick={() => {
                              // setIsEditContract(true)
                            }}
                            style={{cursor: 'pointer'}}
                          /> */}
                        </div>
                      <div className='img_link_big'>
                        {tenancy?.document?.contract ? (
                          <a
                            className='mx-0 '
                            href={`${Bucket}${tenancy?.document?.contract}`}
                            target='_blank'
                          >
                            <h6 className='mx-0 text-muted'>View Contract</h6>
                            {tenancy?.document?.contract?.slice(-3) == 'jpg' ? (
                              <img
                                src={`${Bucket}${tenancy?.document?.contract}`}
                                alt='Img'
                                className='img_preview_small card m-0'
                              />
                            ) : (
                              <img src={file} width='80px' height='80px' className='main_img' />
                            )}
                          </a>
                        ) : (
                          <a className='btn btn-sm fw-bold btn-primary btn-green mx-3 mw-20'>
                            View Contract
                          </a>
                        )}
                      </div>
                      <div className='img_link_big'>
                        {tenancy?.document?.other?.length ? (
                          <>
                            <h6 className='mx-0 text-muted'>View other</h6>

                            {tenancy?.document?.other?.map((el: any) => (
                              <a className='mx-0' href={`${Bucket}${el}`} target='_blank'>
                                <img
                                  src={Bucket + el}
                                  alt='Img'
                                  className='img_preview_small card mb-2'
                                />
                              </a>
                            ))}
                          </>
                        ) : (
                          <a className='btn btn-sm fw-bold btn-primary btn-green mx-3 mw-20'>
                            View Contract
                          </a>
                        )}
                      </div>
                      {/* <div>
                          {tenancy?.document?.other ? (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3 mw-20'
                              href={`${Bucket}${tenancy?.document?.other}`}
                              target='_blank'
                            >
                              View Other
                            </a>
                          ) : (
                            <a className='btn btn-sm fw-bold btn-primary btn-green mx-3 mw-20'>
                              View Other
                            </a>
                          )}
                        </div> */}
                    </div>
                    <div className='card p-5 tops container-xxl '>
                      <h1 className='top green_color'>Financials</h1>

                      <div className='text-muted'>
                        {tenancy?.totalPayments && tenancy?.totalPayments !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Total No. of Payments
                            <div className='mx-1 value_black'>{tenancy?.totalPayments}</div>
                          </h6>
                        ) : null}
                        {tenancy?.rentAmounts !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Rent Amount
                            <div className='mx-1 value_black'>{tenancy?.rentAmounts} AED</div>
                          </h6>
                        ) : null}
                        {tenancy?.securityAmounts !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Security Deposit
                            <div className='mx-1 value_black'>{tenancy?.securityAmounts} AED</div>
                          </h6>
                        ) : null}
                        {tenancy?.otherAmounts !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Other - Parking
                            <div className='mx-1 value_black'>{tenancy?.otherAmounts} AED</div>
                          </h6>
                        ) : null}
                        {tenancy?.bookingAmounts !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Booking Amount
                            <div className='mx-1 value_black'>{tenancy?.bookingAmounts} AED</div>
                          </h6>
                        ) : null}
                        {tenancy?.totalAmounts !== 0 ? (
                          <h6 className='text-muted mx-0 my-2'>
                            Total Amount
                            <div className='mx-1 value_black'>{tenancy?.totalAmounts} AED</div>
                          </h6>
                        ) : null}
                      </div>
                      {tenancy?.totalPayments == 0 &&
                      tenancy?.rentAmounts == 0 &&
                      tenancy?.securityAmounts == 0 &&
                      tenancy?.otherAmounts == 0 &&
                      tenancy?.bookingAmounts == 0 &&
                      tenancy?.totalAmounts == 0 ? (
                        <div className='d-flex' style={{justifyContent: 'center'}}>
                          <img src={noData} alt='' width={350} />
                        </div>
                      ) : null}
                    </div>

                    <div className='card p-5 tops container-xxl min_height'>
                      <h1 className='top green_color'>Previous Tenancy</h1>
                      <div className='table-responsive py-5'>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className=' min-w-100px'>Contract No. </th>
                              <th className=' min-w-100px'>Tenancy Status</th>
                              <th className=' min-w-100px'>Tenant Source</th>
                              <th className=' min-w-100px'>Duration</th>
                              <th className=' min-w-100px'>Start - End</th>
                              <th className=' min-w-100px'>Rating</th>
                              <th className=' min-w-100px'></th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {previousTenancy?.length ? (
                              previousTenancy?.map((v: any, i: any) => {
                                return (
                                  <tr>
                                    <td>{v?.contractNo}</td>
                                    <td>
                                      {v?.tenancyStatus === 0
                                        ? 'Booked '
                                        : v?.tenancyStatus === 1
                                        ? 'Active'
                                        : 'Renewed'}
                                    </td>
                                    <td>
                                      {v?.tenant?.[0]?.tenantSource === 0 ? 'Agent' : 'Direct'}
                                    </td>
                                    <td>{v?.duration?.days} Days</td>
                                    <td>
                                      {moment(v?.duration?.start_date).format('DD/MM/YYYY')} -{' '}
                                      {moment(v?.duration?.end_date).format('DD/MM/YYYY')}
                                    </td>
                                    <td>
                                      <span
                                        className='svg-icon svg-icon-2'
                                        style={{color: '#ffad0f'}}
                                      >
                                        <svg
                                          width='24'
                                          height='24'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                            fill='currentColor'
                                          ></path>
                                        </svg>
                                      </span>{' '}
                                      {v?.rating}
                                    </td>
                                    <td>
                                      <a
                                        // onClick={() => setShowCreateAppModal(true)}
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        // onClick={handleSubmit}
                                        onClick={() => {
                                          navigate(`/viewprevioustenancy/${v?._id}`)
                                        }}
                                      >
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {propertiType === 'Payments' && (
                <div>
                  <div className='grid-out p-5 container-xxl'>
                    <div className='card tops p-5 m-0'>
                      <h6 className='mx-0 text-muted'>
                        Total Payments
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.totalPayments}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        Contract No.{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.contractNo}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        Tenancy Status{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenancyStatus === 0
                            ? 'Booked'
                            : tenancy?.tenancyStatus === 1
                            ? 'Active'
                            : tenancy?.tenancyStatus === 2
                            ? 'Expiring'
                            : tenancy?.tenancyStatus === 3
                            ? 'Ended'
                            : tenancy?.tenancyStatus === 4
                            ? 'Renewed'
                            : tenancy?.tenancyStatus === 5
                            ? 'Terminated'
                            : ''}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        Start
                        <span className='mx-1' style={{color: 'black'}}>
                          {moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        End{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        Duration
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.duration?.days === 1
                            ? tenancy?.duration?.days + ' Day'
                            : tenancy?.duration?.days >= 2
                            ? tenancy?.duration?.days + ' Days'
                            : tenancy?.duration?.days <= 0
                            ? 0 + ' Days'
                            : ''}
                        </span>
                      </h6>
                      <h6 className='mx-0 text-muted'>
                        Tenant{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          {tenancy?.tenant?.[0]?.firstName} {tenancy?.tenant?.[0]?.lastName}
                        </span>
                      </h6>
                    </div>
                    <div className='card tops p-5 m-0'>
                      <h1 className='top green_color'>Payments</h1>
                      <div className='d-flex flex-stack pt-3 mt-0'>
                        <div className='d-flex align-items-center'>
                          {edit !== true && (
                            <div className=''>
                              <a
                                onClick={addFileds}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                // onClick={handleSubmit}
                              >
                                Add Payment
                              </a>
                            </div>
                          )}
                          {/* {arry.length !== 0 && edit !== true && (
                            <a
                              // onClick={() => setShowCreateAppModal(true)}
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              onClick={handleAddPayment}
                            >
                              Apply
                            </a>
                          )} */}
                          {/* {arry.length !== 0 && edit === true && (
                            <a
                              // onClick={() => setShowCreateAppModal(true)}
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              onClick={handleEditPayment}
                            >
                              Edit
                            </a>
                          )} */}
                        </div>
                        <div className=''>
                          <a
                            onClick={() => setShowGenRecModal(true)}
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                          >
                            Generate Receipt
                          </a>
                          <a
                            // onClick={() => setShowCreateAppModal(true)}
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            onClick={() => setShowSendRemModal(true)}
                          >
                            Send Reminder
                          </a>
                        </div>
                      </div>
                      <div className='d-flex flex-column  gap-5'>
                        {arry.length !== 0 ? (
                          <div
                            className='p-5 mt-5'
                            style={{
                              background: '#f5f8fa',
                              boxShadow:
                                'rgba(50, 50, 93, 0.25) 0px 3px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
                            }}
                          >
                            <div className='card-body p-0 table-responsive'>
                              <table
                                className='table align-middle table-row-dashed fs-6 gy-5 add_payment_table'
                                id='kt_ecommerce_sales_table'
                              >
                                <thead>
                                  <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    {/* <th className='w-10px pe-2'>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        data-kt-check='true'
                                        data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                                        value='1'
                                      />
                                    </div>
                                  </th> */}

                                    <>
                                      <th className=' min-w-100px'>Payment</th>
                                      <th className=' min-w-100px'>Type</th>
                                      <th className=' min-w-150px'>Amount</th>
                                      <th className=' min-w-150px'>Status</th>

                                      <th className=' min-w-150px'>
                                        {paymentData?.status === '0'
                                          ? 'Payment Date'
                                          : 'PAYMENT SCHEDULE'}
                                      </th>
                                      <th className=' min-w-200px'>
                                        {paymentData?.status === '0'
                                          ? ' Invoice Ref. No.'
                                          : 'PAYMENT METHOD'}
                                      </th>
                                      <th className=' min-w-150px'>
                                        {paymentData?.status === '0'
                                          ? 'Upload Invoice'
                                          : 'Payment Reminder'}
                                      </th>
                                      <th className=' min-w-150px'>PAYMENT DATE</th>
                                      <th className=' min-w-150px'>AMOUNT PAID</th>
                                      <th className=' min-w-150px'>AMOUNT RECEIVED</th>
                                      <th className=' min-w-150px'>SERVICE FEE</th>
                                      <th className=' min-w-200px'>Outstanding balance</th>
                                      <th className=' min-w-70px'></th>
                                    </>
                                  </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                  {payment
                                    ? arry
                                        .sort(
                                          (a: any, b: any) =>
                                            Number(a.paymentNo) - Number(b.paymentNo)
                                        )
                                        .map((v: any, i: any) => {
                                          return (
                                            <>
                                              <tr key={i} className='text-center'>
                                                {/* {payment && ( */}
                                                <>
                                                  <td
                                                    data-kt-ecommerce-order-filter='order_id'
                                                    className=''
                                                  >
                                                    <input
                                                      type='text'
                                                      className='form-control'
                                                      placeholder=''
                                                      name={`First`}
                                                      value={Number(v?.paymentNo)}
                                                      // onChange={(e)=>handleChnagePayment(e,i,v)}
                                                      disabled={true}
                                                      style={{width: '100px'}}
                                                    />
                                                  </td>
                                                  <td className='' data-order='2022-09-11'>
                                                    <select
                                                      name={`rentType`}
                                                      className='form-select fst-italic'
                                                      value={v?.rentType}
                                                      onChange={(e) => handleChnagePayment(e, i, v)}
                                                      style={{width: '100px'}}
                                                      disabled={v?.is_disable || v?.key_desable}
                                                    >
                                                      <option
                                                        className='fst-italic'
                                                        disabled
                                                        selected
                                                      >
                                                        Select
                                                      </option>
                                                      <option className='fst-italic' value={0}>
                                                        Rent
                                                      </option>
                                                      <option className='fst-italic' value={1}>
                                                        Security Deposit
                                                      </option>
                                                      <option className='fst-italic' value={2}>
                                                        Booking
                                                      </option>
                                                      <option className='fst-italic' value={3}>
                                                        Other
                                                      </option>
                                                    </select>
                                                    {v?.rentType === 3 ? (
                                                      <input
                                                        type='text'
                                                        className='form-control'
                                                        placeholder=''
                                                        // name='amount'
                                                        name={`rentOtherName`}
                                                        // name={`amount ${i}`}
                                                        value={v?.rentOtherName}
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    ) : null}
                                                  </td>
                                                  <td style={{border: 'none'}}>
                                                    <div className='d-flex gap-3 my-auto align-items-center'>
                                                      <span>AED </span>
                                                      <input
                                                        type='number'
                                                        className='form-control mx-2'
                                                        placeholder=''
                                                        // name='amount'
                                                        name={`amount`}
                                                        // name={`amount ${i}`}
                                                        value={v?.amount}
                                                        min='0'
                                                        disabled={v?.is_disable || v?.key_desable}
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    </div>
                                                    {/* <input
                                                type='number'
                                                className='form-control'
                                                placeholder=''
                                                name={`amount${i}`}
                                                value={formData?.amount}
                                                onChange={handleChnage}
                                                style={{width: '100px'}}
                                              /> */}
                                                  </td>
                                                  <td
                                                    className='text-center pe-0 '
                                                    data-order='Denied'
                                                  >
                                                    <select
                                                      // name='status'
                                                      name={`status`}
                                                      className='form-select mx-auto'
                                                      value={v?.status}
                                                      disabled={v?.is_disable}
                                                      onChange={(e) => {
                                                        handleChnagePayment(e, i, v)
                                                        if (
                                                          e.target.value === '1' ||
                                                          e.target.value === '3'
                                                        ) {
                                                          setpopupindex(i)
                                                          callpopUp(e.target.value, i)
                                                        }
                                                      }}
                                                      style={{width: '100px'}}
                                                    >
                                                      <option
                                                        className='fst-italic'
                                                        disabled
                                                        selected
                                                      >
                                                        Select
                                                      </option>
                                                      <option className='fst-italic' value={0}>
                                                        Upcoming
                                                      </option>
                                                      <option className='fst-italic' value={1}>
                                                        Paid
                                                      </option>
                                                      <option className='fst-italic' value={2}>
                                                        Overdue
                                                      </option>
                                                      <option className='fst-italic' value={3}>
                                                        Partially Paid
                                                      </option>
                                                    </select>
                                                  </td>
                                                  <td className='text-center pe-0'>
                                                    <input
                                                      type='date'
                                                      className='form-control mx-auto'
                                                      placeholder=''
                                                      min={
                                                        v?.status === 2
                                                          ? undefined
                                                          : new Date().toISOString().split('T')[0]
                                                      }
                                                      max={
                                                        v?.status === 2
                                                          ? new Date().toISOString().split('T')[0]
                                                          : undefined
                                                      }
                                                      // name='schedule'
                                                      name={`paymentSchedule`}
                                                      value={checkDate(v?.paymentSchedule)}
                                                      // value={moment(v?.paymentSchedule).format(
                                                      //   'YYYY-MM-DD'
                                                      // )}
                                                      onChange={(e) => handleChnagePayment(e, i, v)}
                                                      disabled={
                                                        v?.status === 1 ||
                                                        v?.status === 3 ||
                                                        v?.is_disable
                                                          ? true
                                                          : false
                                                      }
                                                      style={{width: '100px'}}
                                                    />
                                                  </td>
                                                  <td
                                                    className='text-center'
                                                    data-order='2022-09-09'
                                                  >
                                                    <select
                                                      // name='method'
                                                      name={`paymentMethod`}
                                                      className='form-select fst-italic mx-auto'
                                                      value={v?.paymentMethod}
                                                      disabled={
                                                        v?.is_disable ||
                                                        v?.status == 3 ||
                                                        v?.status == 1
                                                      }
                                                      onChange={(e) => {
                                                        handleChnagePayment(e, i, v)
                                                        if (e.target.value === '1') {
                                                          // setCard('')
                                                          // callmodel(e.target.value, i)
                                                        }
                                                        if (e.target.value === '2') {
                                                          // setCard('')
                                                          // callmodel(e.target.value, i)
                                                        }
                                                        if (e.target.value === '3') {
                                                          // setCard('')
                                                          // callmodel(e.target.value, i)
                                                        }
                                                        if (arry.length === 1) {
                                                          if (e.target.value === '0') {
                                                            setCard('2')
                                                            // setCardA([...cardA,e.target.value])
                                                          }
                                                          if (e.target.value === '1') {
                                                            setCard('')
                                                            // setCardA([...cardA,e.target.value])
                                                          }
                                                          if (e.target.value === '2') {
                                                            setCard('')
                                                          }
                                                          if (e.target.value === '3') {
                                                            setCard('')
                                                          }
                                                        }

                                                        let a: any = []
                                                        if (arry.length >= 2) {
                                                          // a.push(String(arry[0].paymentMethod))
                                                          arry.map((v: any) => {
                                                            if (v?.paymentMethod === 0) {
                                                              a.push(v?.paymentMethod)
                                                            }
                                                            if (v?.paymentMethod === 1) {
                                                              a.push(v?.paymentMethod)
                                                            }
                                                          })
                                                          // if (e.target.value === '0') {
                                                          //   a.push('0')
                                                          // }
                                                          // if (e.target.value === '1') {
                                                          //   a.push('1')
                                                          // }
                                                          arry.map((v: any) => {
                                                            if (
                                                              e.target.value === '0' ||
                                                              e.target.value === '1'
                                                            ) {
                                                              setCardA([...cardA, e.target.value])
                                                            }
                                                          })
                                                        }
                                                        const ages = a
                                                        const uniqueAges = ages.filter(
                                                          (x: any, i: any, a: any) =>
                                                            a.indexOf(x) == i
                                                        )
                                                        if (uniqueAges.length === 2) {
                                                          setCard('2')
                                                        }
                                                        if (uniqueAges.length === 1) {
                                                          if (uniqueAges[0] === 0) {
                                                            setCard('0')
                                                          } else {
                                                            setCard('1')
                                                          }
                                                        }
                                                        if (
                                                          arry.length !== 1 &&
                                                          uniqueAges.length === 0
                                                        ) {
                                                          if (a.length === 0) {
                                                            setCard('')
                                                          }
                                                        }
                                                      }}
                                                      style={{width: '100px'}}
                                                    >
                                                      <option
                                                        className='fst-italic'
                                                        disabled
                                                        selected
                                                      >
                                                        Select
                                                      </option>
                                                      <option className='fst-italic' value={0}>
                                                        Card Payments
                                                      </option>
                                                      <option className='fst-italic' value={1}>
                                                        Cheque
                                                      </option>
                                                      <option className='fst-italic' value={2}>
                                                        Bank Transfer
                                                      </option>
                                                      <option className='fst-italic' value={3}>
                                                        Cash
                                                      </option>
                                                    </select>
                                                  </td>
                                                  <td
                                                    className='text-center'
                                                    data-order='2022-09-11'
                                                  >
                                                    <input
                                                      type='date'
                                                      className='form-control mx-auto'
                                                      placeholder=''
                                                      min={new Date().toISOString().split('T')[0]}
                                                      // name='reminder'
                                                      name={`paymentRemainder`}
                                                      value={checkDate(v?.paymentRemainder)}
                                                      // value={moment(v?.paymentRemainder).format(
                                                      //   'YYYY-MM-DD'
                                                      // )}
                                                      disabled={
                                                        v?.status === 1 ||
                                                        v?.status === 3 ||
                                                        v?.is_disable
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => handleChnagePayment(e, i, v)}
                                                      style={{width: '100px'}}
                                                    />
                                                  </td>
                                                  <td
                                                    className='text-center'
                                                    data-order='2022-09-11'
                                                  >
                                                    <input
                                                      type='date'
                                                      className='form-control mx-auto'
                                                      placeholder=''
                                                      max={new Date().toISOString().split('T')[0]}
                                                      name={`paymentDate`}
                                                      value={checkDate(v?.paymentDate)}
                                                      // value={moment(v?.paymentDate).format(
                                                      //   'YYYY-MM-DD'
                                                      // )}
                                                      disabled={
                                                        v?.status === 0 ||
                                                        v?.status === 2 ||
                                                        v?.is_disable
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) => handleChnagePayment(e, i, v)}
                                                      style={{width: '100px'}}
                                                    />
                                                  </td>
                                                  <td
                                                    className=' m-auto p-0'
                                                    style={{border: 'none'}}
                                                  >
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <span>AED </span>
                                                      <input
                                                        type='number'
                                                        className='form-control mx-2'
                                                        placeholder=''
                                                        name={`amountPaid`}
                                                        value={
                                                          v?.status === 1
                                                            ? v?.amount
                                                            : v?.amountPaid
                                                        }
                                                        min='0'
                                                        // value={moment(v?.amountPaid).format(
                                                        //   'YYYY-MM-DD'
                                                        // )}
                                                        disabled={
                                                          v?.status === 0 ||
                                                          v?.status === 2 ||
                                                          v?.status === 1 ||
                                                          v?.is_disable
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <span>AED</span>
                                                      <input
                                                        type='number'
                                                        className='form-control mx-2'
                                                        placeholder=''
                                                        // name='amount'
                                                        min='0'
                                                        name={`amountReceived`}
                                                        // name={`amount ${i}`}
                                                        value={
                                                          v?.paymentMethod !== '0'
                                                            ? Number(v?.amount)
                                                            : v?.serviceFee == 0
                                                            ? Number(v?.amount)
                                                            : Number(v?.amount) -
                                                                Number(v?.serviceFee) || 0
                                                        }
                                                        disabled
                                                        // disabled={
                                                        //   v?.status === 1 || v?.status === 3
                                                        //     ? true
                                                        //     : false
                                                        // }
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <span>AED </span>
                                                      <input
                                                        type='number'
                                                        min='0'
                                                        className='form-control mx-2'
                                                        placeholder=''
                                                        name={`serviceFee`}
                                                        value={v?.serviceFee || 0}
                                                        disabled={
                                                          v?.paymentMethod !== '0' || v?.is_disable
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                      <span>AED </span>
                                                      <input
                                                        type='number'
                                                        className='form-control mx-2'
                                                        placeholder=''
                                                        name={`outstandingBalance`}
                                                        disabled
                                                        // disabled={
                                                        //   v?.status === 1 || v?.status === 3
                                                        //     ? true
                                                        //     : false
                                                        // }
                                                        value={
                                                          v?.status === 1
                                                            ? 0
                                                            : v?.amountPaid == 0
                                                            ? Number(v?.amount)
                                                            : Number(v?.amount) -
                                                                Number(v?.amountPaid || 0) || 0
                                                        }
                                                        onChange={(e) =>
                                                          handleChnagePayment(e, i, v)
                                                        }
                                                        style={{width: '100px'}}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <>
                                                      {v?.is_disable == false &&
                                                      v?.status == '3' &&
                                                      v?.paymentDate &&
                                                      v?.amount > 0 &&
                                                      v?.amountPaid > 0 &&
                                                      v?.rentType !== '' ? (
                                                        <button
                                                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                          onClick={() => addNewPartialPayment(v)}
                                                        >
                                                          Add
                                                        </button>
                                                      ) : null}
                                                      {v?.is_disable ? (
                                                        <button
                                                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                          onClick={() =>
                                                            editNewPartialPayment(v, i)
                                                          }
                                                        >
                                                          Edit
                                                        </button>
                                                      ) : null}
                                                    </>
                                                  </td>

                                                  {edit !== true && (
                                                    <td>
                                                      <AiFillDelete
                                                        color='#007a59'
                                                        fontSize={20}
                                                        // onClick={() => deleteRow(i)}
                                                        onClick={() => {
                                                          swal
                                                            .fire({
                                                              text: 'Are you sure you want to permanently delete this ?',
                                                              icon: 'warning',
                                                              showConfirmButton: true,
                                                              confirmButtonColor: '#D72852',
                                                              confirmButtonText: 'Yes',
                                                              showCancelButton: true,
                                                              // cancelButtonColor: "transparent",
                                                              cancelButtonText: 'Cancel',
                                                            })
                                                            .then((res) => {
                                                              if (res.isConfirmed) {
                                                                deleteRowTable(i, v.amount)
                                                              }
                                                            })
                                                        }}
                                                        style={{cursor: 'pointer'}}
                                                      />
                                                    </td>
                                                  )}
                                                </>
                                                {/* )} */}
                                              </tr>
                                              {/* <Modal
                                                id='kt_modal_create_app'
                                                tabIndex={-1}
                                                aria-hidden='true'
                                                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                                                show={showCheange}
                                                // backdrop={true}
                                                size='sm'
                                              ></Modal> */}
                                              <Modal
                                                id='kt_modal_create_app'
                                                tabIndex={-1}
                                                aria-hidden='true'
                                                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                                                show={showCheange}
                                                onHide={() => {
                                                  setShowCheange(false)
                                                  setType('')
                                                  setCard('')
                                                }}
                                                // backdrop={true}
                                                size='sm'
                                              >
                                                <div className='modal-header'>
                                                  <h2>
                                                    {modeldata === '0'
                                                      ? 'Card Payments'
                                                      : modeldata === '1'
                                                      ? 'Cheque'
                                                      : modeldata === '2'
                                                      ? 'Bank Transfer'
                                                      : 'Cash'}
                                                  </h2>
                                                  <div
                                                    className='btn btn-sm btn-icon btn-active-color-primary'
                                                    onClick={() => {
                                                      setShowCheange(false)
                                                      setCard('')
                                                    }}
                                                  >
                                                    <KTSVG
                                                      className='svg-icon-1'
                                                      path='/media/icons/duotune/arrows/arr061.svg'
                                                    />
                                                  </div>
                                                </div>
                                                {modeldata === '0' && (
                                                  <div className='modal-body py-lg-10 px-lg-10'>
                                                    <div
                                                      className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                                                      id='kt_modal_create_app_stepper'
                                                    >
                                                      <div
                                                        className='card-header align-items-center gap-md-2'
                                                        style={{justifyContent: 'flex-start'}}
                                                      >
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Email
                                                          </label>
                                                          <input
                                                            type='email'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - abc@gmail.com'
                                                            name='name'
                                                            // value={formData?.name}
                                                            // onChange={handleChnage}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {/* {modeldata === '1' && (
                                          <div className='modal-body py-lg-10 px-lg-10'>
                                            <div
                                              className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                                              id='kt_modal_create_app_stepper'
                                            >
                                              <div
                                                className='card-header align-items-center gap-md-2'
                                                style={{justifyContent: 'flex-start'}}
                                              >
                                                <div className='mb-10 min-w-200px '>
                                                  <label
                                                    htmlFor='exampleFormControlInput1'
                                                    className='required form-label'
                                                  >
                                                    Email
                                                  </label>
                                                  <input
                                                    type='email'
                                                    className='form-control form-control-solid'
                                                    placeholder='e.g. - abc@gmail.com'
                                                    name='name'
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )} */}
                                                {modeldata === '1' && (
                                                  <div className='modal-body py-lg-10 px-lg-10'>
                                                    <div
                                                      className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                                                      id='kt_modal_create_app_stepper'
                                                    >
                                                      <div
                                                        className='card-header align-items-center gap-md-2'
                                                        style={{justifyContent: 'flex-start'}}
                                                      >
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Cheque No.
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - 0045236541'
                                                            name='chequeNo'
                                                            value={modelData?.chequeNo}
                                                            onChange={modelchange}
                                                          />
                                                        </div>
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Bank Name
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - Abc Bank'
                                                            name='bankName'
                                                            value={modelData?.bankName}
                                                            onChange={modelchange}
                                                          />
                                                        </div>
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Upload Cheque
                                                          </label>
                                                          <input
                                                            type='file'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - Abc Bank'
                                                            name='image'
                                                            // value={modelData?.image}
                                                            onChange={imageCheque}
                                                          />
                                                        </div>
                                                      </div>

                                                      {/*end::Content */}
                                                    </div>
                                                    {/* end::Stepper */}
                                                  </div>
                                                )}
                                                {modeldata === '2' && (
                                                  <div className='modal-body py-lg-10 px-lg-10'>
                                                    <div
                                                      className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                                                      id='kt_modal_create_app_stepper'
                                                    >
                                                      <div
                                                        className='card-header align-items-center gap-md-2'
                                                        style={{justifyContent: 'flex-start'}}
                                                      >
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            IBAN
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - 0045236541'
                                                            name='IBAN'
                                                            value={modelData?.IBAN}
                                                            onChange={modelchange}
                                                          />
                                                        </div>
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Account No.
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - 110025365412'
                                                            name='accountNo'
                                                            value={modelData?.accountNo}
                                                            onChange={modelchange}
                                                          />
                                                        </div>
                                                        <div className='mb-10 min-w-200px '>
                                                          <label
                                                            htmlFor='exampleFormControlInput1'
                                                            className='required form-label'
                                                          >
                                                            Bank Name
                                                          </label>
                                                          <input
                                                            type='text'
                                                            className='form-control form-control-solid'
                                                            placeholder='e.g. - Abc Bank'
                                                            name='bankName'
                                                            value={modelData?.bankName}
                                                            onChange={modelchange}
                                                          />
                                                        </div>
                                                      </div>

                                                      {/*end::Content */}
                                                    </div>
                                                    {/* end::Stepper */}
                                                  </div>
                                                )}
                                                <div className='modal-footer'>
                                                  <div className='flex-row-fluid '>
                                                    <div className='d-flex justify-content-end '>
                                                      <div className='me-2'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-lg btn-light-primary me-3'
                                                          data-kt-stepper-action='previous'
                                                          onClick={() => {
                                                            setShowCheange(false)
                                                            // setCard('')
                                                          }}
                                                        >
                                                          <KTSVG
                                                            path='/media/icons/duotune/arrows/arr063.svg'
                                                            className='svg-icon-3 me-1 text-green'
                                                          />{' '}
                                                          Cancel
                                                        </button>
                                                      </div>
                                                      <div>
                                                        <button
                                                          type='button'
                                                          className='btn btn-lg btn-primary btn-green'
                                                          data-kt-stepper-action='submit'
                                                          onClick={() => {
                                                            modelSubmit()
                                                            // setCard('')
                                                          }}
                                                        >
                                                          Submit{' '}
                                                          <KTSVG
                                                            path='/media/icons/duotune/arrows/arr064.svg'
                                                            className='svg-icon-3 ms-2 me-0'
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                    {/*end::Form */}
                                                  </div>
                                                </div>
                                              </Modal>
                                              <Modal
                                                id='kt_modal_create_app'
                                                tabIndex={-1}
                                                aria-hidden='true'
                                                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                                                show={showPaid}
                                                onHide={() => {
                                                  setShowPaid(false)
                                                  setPaymentMethod('')
                                                  // setCard('')
                                                }}
                                                // backdrop={true}
                                                size='sm'
                                              >
                                                <div className='modal-header'>
                                                  <h2 className='p-0 m-0 green_color'>
                                                    {popUpTitle == 1 ? 'Paid' : 'Partial Paid'}
                                                  </h2>
                                                  <div
                                                    className='btn btn-sm btn-icon btn-active-color-primary'
                                                    onClick={() => {
                                                      setShowCheange(false)
                                                      setPaymentMethod('')
                                                    }}
                                                  >
                                                    <KTSVG
                                                      className='svg-icon-1'
                                                      path='/media/icons/duotune/arrows/arr061.svg'
                                                    />
                                                  </div>
                                                </div>

                                                <div className='modal-body py-lg-10 px-lg-10'>
                                                  <div
                                                    className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                                                    id='kt_modal_create_app_stepper'
                                                  >
                                                    <div
                                                      className='card-header align-items-center gap-md-2'
                                                      style={{justifyContent: 'flex-start'}}
                                                    >
                                                      <div className='mb-10 min-w-200px '>
                                                        <label
                                                          htmlFor='exampleFormControlInput1'
                                                          className='required form-label'
                                                        >
                                                          Payment Method
                                                        </label>
                                                        <select
                                                          name='paymentmethod'
                                                          className='form-select form-select-solid'
                                                          // value={formData?.unitType}
                                                          onChange={handleChnage12}
                                                        >
                                                          <option disabled selected>
                                                            Select
                                                          </option>
                                                          <option className='fst-italic' value={0}>
                                                            Card Payments
                                                          </option>

                                                          <option className='fst-italic' value={1}>
                                                            Cheque
                                                          </option>
                                                          <option className='fst-italic' value={2}>
                                                            Bank Transfer
                                                          </option>
                                                          <option className='fst-italic' value={3}>
                                                            Cash
                                                          </option>
                                                        </select>
                                                      </div>
                                                      {paymentMethod === '0' && (
                                                        <>
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Amount Paid
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountPaid'
                                                        value={
                                                          popUpData?.amount || popUpData?.amountPaid
                                                        }
                                                        // value={popUpData?.amountPaid}
                                                        disabled={popUpData?.amount}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountPaid',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>

                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Received Amount
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountReceived'
                                                        value={popUpData?.amountReceived}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountReceived',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Service Fee
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='serviceFee'
                                                        value={popUpData?.serviceFee}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'serviceFee',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Outstanding Balance{' '}
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='outstandingBalance'
                                                        // value={popUpData?.outstandingBalance}
                                                        value={calculateOutstanding(popUpData)}
                                                        disabled
                                                        // onChange={(e: any) =>
                                                        //   popUpchange(
                                                        //     'outstandingBalance',
                                                        //     parseFloat(
                                                        //       `${
                                                        //         popUpData?.amountPaid ||
                                                        //         popUpData?.amount
                                                        //       }-${popUpData?.amountReceived} - ${
                                                        //         popUpData?.serviceFee
                                                        //       }`
                                                        //     )
                                                        //     // e.target.value &&
                                                        //     //   parseFloat(e.target.value)
                                                        //   )
                                                        // }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Payment Date
                                                      </label>
                                                      <input
                                                        type='date'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='paymentDate'
                                                        max={new Date().toISOString().split('T')[0]}
                                                        value={popUpData?.paymentDate}
                                                        onChange={(e: any) =>
                                                          popUpchange('paymentDate', e.target.value)
                                                        }
                                                      />
                                                    </div> */}
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Ref. No.
                                                            </label>
                                                            <input
                                                              type='number'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='referenceNo'
                                                              value={popUpData?.referenceNo}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'referenceNo',
                                                                  e.target.value &&
                                                                    parseFloat(e.target.value)
                                                                )
                                                              }
                                                              min='0'
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Date
                                                            </label>
                                                            <input
                                                              type='date'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptDate'
                                                              max={
                                                                new Date()
                                                                  .toISOString()
                                                                  .split('T')[0]
                                                              }
                                                              value={popUpData?.receiptDate}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'receiptDate',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Upload Receipt
                                                            </label>
                                                            <input
                                                              type='file'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptURL'
                                                              // value={popUpReceiptData}
                                                              onChange={imagerecipt}
                                                            />
                                                          </div>
                                                        </>
                                                      )}

                                                      {paymentMethod === '1' && (
                                                        <>
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Amount Paid
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountPaid'
                                                        value={
                                                          popUpData?.amount || popUpData?.amountPaid
                                                        }
                                                        // value={popUpData?.amountPaid}
                                                        disabled={popUpData?.amount}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountPaid',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                    <label
                                                      htmlFor='exampleFormControlInput1'
                                                      className='required form-label'
                                                    >
                                                      Outstanding Balance
                                                    </label>
                                                    <input
                                                      type='number'
                                                      className='form-control form-control-solid'
                                                      placeholder=''
                                                      name='outstandingBalance'
                                                      value={popUpData?.outstandingBalance}
                                                      onChange={(e: any) =>
                                                        popUpchange(
                                                          'outstandingBalance',
                                                          e.target.value &&
                                                            parseFloat(e.target.value)
                                                        )
                                                      }
                                                    />
                                                  </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Received Amount
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountReceived'
                                                        value={popUpData?.amountReceived}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountReceived',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Service Fee
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='serviceFee'
                                                        value={popUpData?.serviceFee}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'serviceFee',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Outstanding Balance{' '}
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='outstandingBalance'
                                                        // value={popUpData?.outstandingBalance}
                                                        value={calculateOutstanding(popUpData)}
                                                        disabled
                                                        // onChange={(e: any) =>
                                                        //   popUpchange(
                                                        //     'outstandingBalance',
                                                        //     parseFloat(
                                                        //       `${
                                                        //         popUpData?.amountPaid ||
                                                        //         popUpData?.amount
                                                        //       }-${popUpData?.amountReceived} - ${
                                                        //         popUpData?.serviceFee
                                                        //       }`
                                                        //     )
                                                        //     // e.target.value &&
                                                        //     //   parseFloat(e.target.value)
                                                        //   )
                                                        // }
                                                      />
                                                    </div> */}
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Cheque No.
                                                            </label>
                                                            <input
                                                              type='number'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='chequeNo'
                                                              value={popUpData?.chequeNo}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'chequeNo',
                                                                  e.target.value &&
                                                                    parseFloat(e.target.value)
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Bank Name
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='bankName'
                                                              value={modelData?.bankName}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'bankName',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Payment Date
                                                      </label>
                                                      <input
                                                        type='date'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='paymentDate'
                                                        max={new Date().toISOString().split('T')[0]}
                                                        value={popUpData?.paymentDate}
                                                        onChange={(e: any) =>
                                                          popUpchange('paymentDate', e.target.value)
                                                        }
                                                      />
                                                    </div> */}
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Ref. No.
                                                            </label>
                                                            <input
                                                              type='number'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='referenceNo'
                                                              value={popUpData?.referenceNo}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'referenceNo',
                                                                  e.target.value &&
                                                                    parseFloat(e.target.value)
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Date
                                                            </label>
                                                            <input
                                                              type='date'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptDate'
                                                              max={
                                                                new Date()
                                                                  .toISOString()
                                                                  .split('T')[0]
                                                              }
                                                              value={popUpData?.receiptDate}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'receiptDate',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Upload Receipt
                                                            </label>
                                                            <input
                                                              type='file'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptURL'
                                                              // value={popUpReceiptData}
                                                              onChange={imagerecipt}
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Upload Cheque
                                                            </label>
                                                            <input
                                                              type='file'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='image'
                                                              // value={popUpReceiptData}
                                                              onChange={imageCheque}
                                                            />
                                                          </div>
                                                        </>
                                                        // <>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Amount Paid
                                                        //     </label>
                                                        //     <input
                                                        //       type='number'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='amountPaid'
                                                        //       value={popUpData?.amountPaid}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Cheque No.
                                                        //     </label>
                                                        //     <input
                                                        //       type='number'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='chequeNo'
                                                        //       value={popUpData?.chequeNo}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Bank Name
                                                        //     </label>
                                                        //     <input
                                                        //       type='text'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='bankName'
                                                        //       value={modelData?.bankName}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Payment Date
                                                        //     </label>
                                                        //     <input
                                                        //       type='date'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='paymentDate'
                                                        //       max={new Date().toISOString().split('T')[0]}
                                                        //       value={popUpData?.paymentDate}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Receipt Ref. No.
                                                        //     </label>
                                                        //     <input
                                                        //       type='number'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='referenceNo'
                                                        //       value={popUpData?.referenceNo}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Receipt Date
                                                        //     </label>
                                                        //     <input
                                                        //       type='date'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='receiptDate'
                                                        //       max={new Date().toISOString().split('T')[0]}
                                                        //       value={popUpData?.receiptDate}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Upload Receipt
                                                        //     </label>
                                                        //     <input
                                                        //       type='file'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='image'
                                                        //       // value={popUpReceiptData}
                                                        //       onChange={imagerecipt}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Upload Cheque
                                                        //     </label>
                                                        //     <input
                                                        //       type='file'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='image'
                                                        //       // value={popUpReceiptData}
                                                        //       onChange={imageCheque}
                                                        //     />
                                                        //   </div>
                                                        // </>
                                                      )}
                                                      {paymentMethod === '2' && (
                                                        <>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              IBAN
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control form-control-solid'
                                                              placeholder='e.g. - 0045236541'
                                                              name='IBAN'
                                                              value={popUpData?.IBAN}
                                                              onChange={(e: any) =>
                                                                popUpchange('IBAN', e.target.value)
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Account No.
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control form-control-solid'
                                                              placeholder='e.g. - 110025365412'
                                                              name='accountNo'
                                                              value={popUpData?.accountNo}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'accountNo',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Bank Name
                                                            </label>
                                                            <input
                                                              type='text'
                                                              className='form-control form-control-solid'
                                                              placeholder='e.g. - Abc Bank'
                                                              name='bankName'
                                                              value={popUpData?.bankName}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'bankName',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          {/* <>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        IBAN
                                                      </label>
                                                      <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='e.g. - 0045236541'
                                                        name='IBAN'
                                                        value={popUpData?.IBAN}
                                                        onChange={popUpchange}
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Account No.
                                                      </label>
                                                      <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='e.g. - 110025365412'
                                                        name='accountNo'
                                                        value={popUpData?.accountNo}
                                                        onChange={popUpchange}
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Bank Name
                                                      </label>
                                                      <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        placeholder='e.g. - Abc Bank'
                                                        name='bankName'
                                                        value={popUpData?.bankName}
                                                        onChange={popUpchange}
                                                      />
                                                    </div>
                                                  </> */}
                                                        </>
                                                      )}
                                                      {paymentMethod === '3' && (
                                                        // <>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Amount Paid
                                                        //     </label>
                                                        //     <input
                                                        //       type='number'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='amountPaid'
                                                        //       value={popUpData?.amountPaid}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Payment Date
                                                        //     </label>
                                                        //     <input
                                                        //       type='date'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='paymentDate'
                                                        //       max={new Date().toISOString().split('T')[0]}
                                                        //       value={popUpData?.paymentDate}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Receipt Ref. No.
                                                        //     </label>
                                                        //     <input
                                                        //       type='number'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='referenceNo'
                                                        //       value={popUpData?.referenceNo}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Receipt Date
                                                        //     </label>
                                                        //     <input
                                                        //       type='date'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='receiptDate'
                                                        //       max={new Date().toISOString().split('T')[0]}
                                                        //       value={popUpData?.receiptDate}
                                                        //       onChange={popUpchange}
                                                        //     />
                                                        //   </div>
                                                        //   <div className='mb-10 min-w-200px '>
                                                        //     <label
                                                        //       htmlFor='exampleFormControlInput1'
                                                        //       className='required form-label'
                                                        //     >
                                                        //       Upload Receipt
                                                        //     </label>
                                                        //     <input
                                                        //       type='file'
                                                        //       className='form-control form-control-solid'
                                                        //       placeholder=''
                                                        //       name='image'
                                                        //       // value={popUpReceiptData}
                                                        //       onChange={imagerecipt}
                                                        //     />
                                                        //   </div>
                                                        // </>

                                                        <>
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Amount Paid
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountPaid'
                                                        value={
                                                          popUpData?.amount || popUpData?.amountPaid
                                                        }
                                                        // value={popUpData?.amountPaid}
                                                        disabled={popUpData?.amount}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountPaid',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                    <label
                                                      htmlFor='exampleFormControlInput1'
                                                      className='required form-label'
                                                    >
                                                      Outstanding Balance
                                                    </label>
                                                    <input
                                                      type='number'
                                                      className='form-control form-control-solid'
                                                      placeholder=''
                                                      name='outstandingBalance'
                                                      value={popUpData?.outstandingBalance}
                                                      onChange={(e: any) =>
                                                        popUpchange(
                                                          'outstandingBalance',
                                                          e.target.value &&
                                                            parseFloat(e.target.value)
                                                        )
                                                      }
                                                    />
                                                  </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Received Amount
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='amountReceived'
                                                        value={popUpData?.amountReceived}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'amountReceived',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Service Fee
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='serviceFee'
                                                        value={popUpData?.serviceFee}
                                                        onChange={(e: any) =>
                                                          popUpchange(
                                                            'serviceFee',
                                                            e.target.value &&
                                                              parseFloat(e.target.value)
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Outstanding Balance{' '}
                                                      </label>
                                                      <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='outstandingBalance'
                                                        // value={popUpData?.outstandingBalance}
                                                        value={calculateOutstanding(popUpData)}
                                                        disabled
                                                        // onChange={(e: any) =>
                                                        //   popUpchange(
                                                        //     'outstandingBalance',
                                                        //     parseFloat(
                                                        //       `${
                                                        //         popUpData?.amountPaid ||
                                                        //         popUpData?.amount
                                                        //       }-${popUpData?.amountReceived} - ${
                                                        //         popUpData?.serviceFee
                                                        //       }`
                                                        //     )
                                                        //     // e.target.value &&
                                                        //     //   parseFloat(e.target.value)
                                                        //   )
                                                        // }
                                                      />
                                                    </div> */}
                                                          {/* <div className='mb-10 min-w-200px '>
                                                      <label
                                                        htmlFor='exampleFormControlInput1'
                                                        className='required form-label'
                                                      >
                                                        Payment Date
                                                      </label>
                                                      <input
                                                        type='date'
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        name='paymentDate'
                                                        max={new Date().toISOString().split('T')[0]}
                                                        value={popUpData?.paymentDate}
                                                        onChange={(e: any) =>
                                                          popUpchange('paymentDate', e.target.value)
                                                        }
                                                      />
                                                    </div> */}
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Ref. No.
                                                            </label>
                                                            <input
                                                              type='number'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='referenceNo'
                                                              value={popUpData?.referenceNo}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'referenceNo',
                                                                  e.target.value &&
                                                                    parseFloat(e.target.value)
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Receipt Date
                                                            </label>
                                                            <input
                                                              type='date'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptDate'
                                                              max={
                                                                new Date()
                                                                  .toISOString()
                                                                  .split('T')[0]
                                                              }
                                                              value={popUpData?.receiptDate}
                                                              onChange={(e: any) =>
                                                                popUpchange(
                                                                  'receiptDate',
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className='mb-10 min-w-200px '>
                                                            <label
                                                              htmlFor='exampleFormControlInput1'
                                                              className='required form-label'
                                                            >
                                                              Upload Receipt
                                                            </label>
                                                            <input
                                                              type='file'
                                                              className='form-control form-control-solid'
                                                              placeholder=''
                                                              name='receiptURL'
                                                              // value={popUpReceiptData}
                                                              onChange={imagerecipt}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className='modal-footer'>
                                                  <div className='flex-row-fluid '>
                                                    <div className='d-flex justify-content-end '>
                                                      <div className='me-2'>
                                                        <button
                                                          type='button'
                                                          className='btn btn-lg btn-light-primary me-3'
                                                          data-kt-stepper-action='previous'
                                                          onClick={() => {
                                                            setShowPaid(false)
                                                            setCard('')
                                                            setPaymentMethod('')
                                                          }}
                                                        >
                                                          <KTSVG
                                                            path='/media/icons/duotune/arrows/arr063.svg'
                                                            className='svg-icon-3 me-1 text-green'
                                                          />{' '}
                                                          Cancel
                                                        </button>
                                                      </div>
                                                      <div>
                                                        <button
                                                          type='button'
                                                          className='btn btn-lg btn-primary btn-green'
                                                          data-kt-stepper-action='submit'
                                                          onClick={(e: any) => {
                                                            popUpSubmit(e, i)
                                                            setCard('')
                                                            setPaymentMethod('')
                                                          }}
                                                        >
                                                          Submit{' '}
                                                          <KTSVG
                                                            path='/media/icons/duotune/arrows/arr064.svg'
                                                            className='svg-icon-3 ms-2 me-0'
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Modal>
                                            </>
                                          )
                                        })
                                    : null}
                                </tbody>
                              </table>

                              {card === '0' && arry.length !== 0 && (
                                <div
                                  className='card card-flush mb-10'
                                  style={{backgroundColor: '#befc9f'}}
                                >
                                  <h3 className='mx-10 mb-10 mt-10'>
                                    Card (Recurring Payments) - DISCLAIMER
                                  </h3>
                                  <ul className='mx-10 mb-10'>
                                    <li>
                                      {' '}
                                      You've selected 'Card (Recurring Payments)' in one or more
                                      payments, please read the below before continuing:
                                    </li>
                                    <li>
                                      Recurring payments will be scheduled on the date entered in
                                      the 'Payment Schedule'.
                                    </li>
                                    <li>Recurring payments will only work on verified cards.</li>
                                    <li>
                                      Propertise will deduct a service fee for each payment using
                                      this service. By using this service, tenants will also be
                                      rewarded a cashback amount. Please refer to the below table to
                                      review the service fees:{' '}
                                    </li>
                                  </ul>
                                  <div
                                    className='card-body pt-0 table-responsive'
                                    style={{padding: '0rem 2.5rem'}}
                                  >
                                    <table
                                      className='table align-middle table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead style={{color: 'black'}}>
                                        <tr
                                          className='text-start fw-bold fs-7 text-uppercase gs-0'
                                          style={{borderBottomColor: 'black'}}
                                        >
                                          <th className='text-center min-w-100px'>Payment</th>
                                          <th className='text-center min-w-100px'>
                                            Total Amount Charged
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            {' '}
                                            Amount Received
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            Online Payment Service Fee
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold'>
                                        <tr>
                                          <td className='text-center'>1</td>
                                          <td className='text-center'>1000 AED</td>
                                          <td className='text-center'>900 AED</td>
                                          <td className='text-center'>100 AED</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                              {card === '1' && arry.length !== 0 && (
                                <div
                                  className='card card-flush mb-10'
                                  style={{backgroundColor: '#befc9f'}}
                                >
                                  <h3 className='mx-10 mb-10 mt-10'>
                                    Card (Individual Payments) - DISCLAIMER
                                  </h3>
                                  <ul className='mx-10 mb-10'>
                                    <li>
                                      You've selected 'Card (Individual Payments)' in one or more
                                      payments, please read the below before continuing:{' '}
                                    </li>
                                    <li>
                                      Main tenant will be able to make the payment directly from
                                      their accounts.{' '}
                                    </li>
                                    <li>
                                      Propertise will deduct a service fee for each payment using
                                      this service. By using this service, tenants will also be
                                      rewarded a cashback amount. Please refer to the below table to
                                      review the service fees:{' '}
                                    </li>
                                  </ul>
                                  <div
                                    className='card-body pt-0 table-responsive'
                                    style={{padding: '0rem 2.5rem'}}
                                  >
                                    <table
                                      className='table align-middle table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead style={{color: 'black'}}>
                                        <tr
                                          className='text-start fw-bold fs-7 text-uppercase gs-0'
                                          style={{borderBottomColor: 'black'}}
                                        >
                                          <th className='text-center min-w-100px'>Payment</th>
                                          <th className='text-center min-w-100px'>
                                            Total Amount Charged
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            {' '}
                                            Amount Received
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            Online Payment Service Fee
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold'>
                                        <tr>
                                          <td className='text-center'>1</td>
                                          <td className='text-center'>1000 AED</td>
                                          <td className='text-center'>900 AED</td>
                                          <td className='text-center'>100 AED</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                              {card === '2' && arry.length !== 0 && (
                                <div
                                  className='card card-flush mb-10'
                                  style={{backgroundColor: '#befc9f'}}
                                >
                                  <h3 className='mx-10 mb-10 mt-10'>
                                    Card (Recurring Payments) - DISCLAIMER
                                  </h3>
                                  <ul className='mx-10 mb-10'>
                                    <li>
                                      {' '}
                                      You've selected 'Card (Recurring Payments)' in one or more
                                      payments, please read the below before continuing:
                                    </li>
                                    <li>
                                      Recurring payments will be scheduled on the date entered in
                                      the 'Payment Schedule'.
                                    </li>
                                    <li>Recurring payments will only work on verified cards.</li>
                                    <li>
                                      Propertise will deduct a service fee for each payment using
                                      this service. By using this service, tenants will also be
                                      rewarded a cashback amount. Please refer to the below table to
                                      review the service fees:{' '}
                                    </li>
                                  </ul>
                                  <h3 className='mx-10 mb-10 mt-10'>
                                    Card (Individual Payments) - DISCLAIMER
                                  </h3>
                                  <ul className='mx-10 mb-10'>
                                    <li>
                                      You've selected 'Card (Individual Payments)' in one or more
                                      payments, please read the below before continuing:{' '}
                                    </li>
                                    <li>
                                      Main tenant will be able to make the payment directly from
                                      their accounts.{' '}
                                    </li>
                                    <li>
                                      Propertise will deduct a service fee for each payment using
                                      this service. By using this service, tenants will also be
                                      rewarded a cashback amount. Please refer to the below table to
                                      review the service fees:{' '}
                                    </li>
                                  </ul>
                                  <div
                                    className='card-body pt-0 table-responsive'
                                    style={{padding: '0rem 2.5rem'}}
                                  >
                                    <table
                                      className='table align-middle table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead style={{color: 'black'}}>
                                        <tr
                                          className='text-center fw-bold fs-7 text-uppercase gs-0'
                                          style={{borderBottomColor: 'black'}}
                                        >
                                          <th className='text-center min-w-100px'>Payment</th>
                                          <th className='text-center min-w-100px'>
                                            Total Amount Charged
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            {' '}
                                            Amount Received
                                          </th>
                                          <th className='text-center min-w-100px'>
                                            Online Payment Service Fee
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold'>
                                        <tr>
                                          <td className='text-center'>1</td>
                                          <td className='text-center'>1000 AED</td>
                                          <td className='text-center'>900 AED</td>
                                          <td className='text-center'>100 AED</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* {newPay/ment.length !== 0 && edit !== true && ( */}
                            {arry.length !== 0 && edit !== true && (
                              // <div className='apply_btn'>
                              <div
                                className='d-flex align-items-center w-100 pt-5'
                                style={{justifyContent: 'right'}}
                              >
                                <button
                                  // onClick={() => setShowCreateAppModal(true)}
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_create_app'
                                  onClick={handleAddPayment}
                                  // disabled={submitDisable}
                                >
                                  Submit
                                </button>
                              </div>
                            )}
                            {arry.length !== 0 && edit === true && (
                              <div
                                className='d-flex align-items-center w-100 pt-5'
                                style={{justifyContent: 'right'}}
                              >
                                <a
                                  // onClick={() => setShowCreateAppModal(true)}
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_create_app'
                                  onClick={handleEditPayment}
                                >
                                  Apply
                                </a>
                              </div>
                            )}
                          </div>
                        ) : null}
                        <div className='card-body pt-0 table-responsive mt-5'>
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-40px text-center'></th>
                                <th className=' min-w-100px text-center'>#</th>
                                <th className=' min-w-100px text-center'>Type</th>
                                <th className=' min-w-100px text-center'>Amount</th>
                                <th className=' min-w-150px text-center'>Status </th>
                                <th className=' min-w-150px text-center'>Payment Schedule</th>
                                <th className=' min-w-150px text-center'>Payment Method</th>
                                <th className=' min-w-150px text-center'>Payment Reminder</th>
                                <th className=' min-w-150px text-center'>Payment Date</th>
                                <th className=' min-w-150px text-center'>Amount Paid</th>
                                <th className=' min-w-150px text-center'>Amount Received</th>
                                <th className=' min-w-150px text-center'>Service Fee</th>
                                <th className=' min-w-200px text-center'>Outstanding Balance</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenancyPayement.length ? (
                                tenancyPayement
                                  ?.sort(
                                    (a: any, b: any) => Number(a?.paymentNo) - Number(b?.paymentNo)
                                  )
                                  .map((v: any, i: any) => (
                                    <tr className='text-center'>
                                      <td>
                                        <div className='w-10px pe-2'>
                                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              data-kt-check='true'
                                              data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                                              value='1'
                                              onChange={handleChangeCheckbox.bind(null, v._id)}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td>{v?.paymentNo}</td>
                                      <td>
                                        {v?.rentType === 0
                                          ? 'Rent'
                                          : v?.rentType === 1
                                          ? 'Security Deposite'
                                          : v?.rentType === 2
                                          ? 'Booking'
                                          : v?.rentType === 3
                                          ? 'Others'
                                          : ''}
                                      </td>
                                      <td>{v?.amount}</td>
                                      <td>
                                        {v?.status === 0
                                          ? 'Upcoming'
                                          : v?.status === 1
                                          ? 'Paid'
                                          : v?.status === 3
                                          ? 'Partially Paid'
                                          : 'Overdue'}
                                      </td>
                                      <td>
                                        {v?.paymentSchedule
                                          ? moment(v?.paymentSchedule).format('DD/MM/YYYY')
                                          : '-'}
                                      </td>
                                      <td style={{position: 'relative'}}>
                                        <div>
                                          {v?.paymentMethod === 0
                                            ? 'Card Payments'
                                            : v?.paymentMethod === 1
                                            ? 'Cheque'
                                            : v?.paymentMethod === 2
                                            ? 'Bank'
                                            : v?.paymentMethod === 3
                                            ? 'Cash'
                                            : ''}
                                          {v?.paymentMethod === 1 && (
                                            <>
                                              <span
                                                onClick={() => {
                                                  handleShowData(v?.paymentValue?.cheque)
                                                }}
                                                style={{padding: '5px'}}
                                              >
                                                <AiFillDownCircle />
                                              </span>
                                            </>
                                          )}
                                          {v?.paymentMethod === 2 && (
                                            <>
                                              <span
                                                onClick={() => {
                                                  handleCashshowData(v?.paymentValue, v?._id)
                                                }}
                                                style={{padding: '5px'}}
                                              >
                                                <AiFillDownCircle />
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        {v?.paymentRemainder
                                          ? moment(v?.paymentRemainder).format('DD/MM/YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {v?.paymentDate
                                          ? moment(v?.paymentDate).format('DD/MM/YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {v?.paymentMethod !== '0' ? v?.amountPaid : v?.amount} AED
                                      </td>
                                      <td>{v?.amountReceived} AED</td>
                                      <td>
                                        {v?.serviceFee} AED{/*  {v?.serviceFee} */}
                                      </td>
                                      <td>{v?.outstandingBalance} AED</td>
                                      <td>
                                        {' '}
                                        <div className=''>
                                          {isEditPaymentId !== v?._id && (
                                            <a
                                              onClick={() => EditFileds(v?._id)}
                                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                              // onClick={handleSubmit}
                                            >
                                              Edit
                                            </a>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        {v?.status !== 1 && (
                                          <AiFillDelete
                                            color='#007a59'
                                            fontSize={20}
                                            // onClick={() => deleteRow(i)}
                                            onClick={() => {
                                              swal
                                                .fire({
                                                  text: 'Are you sure you want delete this payment?',
                                                  icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#D72852',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  // cancelButtonColor: "transparent",
                                                  cancelButtonText: 'Cancel',
                                                })
                                                .then((res) => {
                                                  if (res.isConfirmed) {
                                                    deleteRow(v?._id, v)
                                                  }
                                                })
                                            }}
                                            style={{cursor: 'pointer'}}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td colSpan={16} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className='row mb-5'>
                          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div
                              className='dataTables_paginate paging_simple_numbers'
                              id='kt_ecommerce_sales_table_paginate'
                            >
                              <ReactPaginate
                                breakLabel='...'
                                nextLabel='>'
                                pageClassName='paginate_button page-item +'
                                pageLinkClassName='page-link'
                                containerClassName='pagination'
                                activeClassName='active'
                                previousClassName='paginate_button page-item previous disabled'
                                nextClassName='paginate_button page-item next'
                                previousLinkClassName='page-link'
                                nextLinkClassName='page-link'
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={paymentpageLimit}
                                previousLabel='<'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card tops p-5 m-0'>
                      <h1 className='top green_color'>Reminders</h1>
                      <div className='table-responsive p-5'>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className=' min-w-70px'>#</th>
                              <th className=' min-w-100px'>Reminder Date</th>
                              <th className=' min-w-100px'>Status</th>
                              <th className=' min-w-100px'>Payment </th>
                              <th className=' min-w-100px'>Type</th>
                              <th className=' min-w-100px'>Scheduled Date </th>
                              <th className=' min-w-70px'></th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {tenancyReminder.length ? (
                              tenancyReminder.map((item: any, key: any) => (
                                <tr key={key} className='text-center'>
                                  <td>{key + 1}</td>
                                  <td>{moment(item?.reminderDate).format('DD/MM/YYYY')}</td>
                                  <td>
                                    {item?.reminderStatus === 1
                                      ? 'paid'
                                      : item?.reminderStatus === 2
                                      ? 'Overdue'
                                      : item?.reminderStatus === 0
                                      ? 'Upcoming'
                                      : ''}
                                  </td>
                                  <td>{item?.paymentIds.length}</td>
                                  <td>
                                    {item?.reminderType === 0
                                      ? 'Rent'
                                      : item?.reminderType === 1
                                      ? 'Security Deposit'
                                      : item?.reminderType === 2
                                      ? 'Booking'
                                      : item?.reminderType === 3
                                      ? 'Other'
                                      : ''}
                                  </td>
                                  <td>{moment(item?.scheduledDate).format('DD/MM/YYYY')}</td>
                                  <td>
                                    <a
                                      // onClick={() => setShowCreateAppModal(true)}
                                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                      // data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_create_app'
                                      onClick={() => viewReminder(item?._id)}

                                      // onClick={() => {
                                      //   navigate(-1)
                                      // }}
                                    >
                                      View Reminder
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={16} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='card tops p-5 m-0'>
                      <h1 className='top green_color'>Receipts</h1>
                      <div className='table-responsive p-5'>
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className=' min-w-100px'>Reference No.</th>
                              <th className=' min-w-100px'>Created Date</th>
                              <th className=' min-w-100px'>Amount Received</th>
                              <th className=' min-w-100px'>Total Payments</th>
                              <th className=' min-w-100px'>Status</th>
                              <th className=' min-w-100px'> Date Submitted</th>
                              {/* <th className=' min-w-100px'></th> */}
                              <th className=' min-w-100px'></th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {recieptList?.payment_receipt_data?.length ? (
                              recieptList?.payment_receipt_data?.map((data: any, i: number) => (
                                <tr className='text-center'>
                                  <td>{i + 1}</td>
                                  <td>
                                    {data?.createdAt !== null
                                      ? moment(data?.createdAt).format('DD/MM/YYYY')
                                      : '-'}
                                  </td>
                                  <td>{data?.payment_receipt[0]?.totalAmountReceived}</td>
                                  <td>{data?.tenancyPaymentIds?.length}</td>
                                  <td>{data?.status === 0 ? 'Sent' : 'Draft'}</td>
                                  <td>
                                    {data?.submissionDate !== null
                                      ? moment(data?.submissionDate).format('DD/MM/YYYY')
                                      : '-'}
                                  </td>
                                  <td>
                                    {data?.status === 1 ? (
                                      <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={() => handleSubmitEditReceipt(data._id)}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={() => handleSubmitViewReceipt(data?._id)}
                                        // onClick={() => {
                                        //   navigate(-1)
                                        // }}
                                      >
                                        View Receipt
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={16} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {propertiType === 'Announcements' && (
                <div className='card card-flush mb-10'>
                  <div className='card-header align-items-center py-5  gap-md-2 d-flex border-0 p-0'>
                    <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                      <h6 className='mx-10 text-muted'>
                        Announcements Sent
                        <span className='mx-1' style={{color: 'black'}}>
                          2
                        </span>
                      </h6>
                    </div>
                    <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                      <h6 className='mx-10 text-muted'>
                        Announcements Draft{' '}
                        <span className='mx-1' style={{color: 'black'}}>
                          1
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className='card-body pt-0 table-responsive mt-5'>
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5'
                      id='kt_ecommerce_sales_table'
                    >
                      <thead>
                        <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='min-w-100px'>ID </th>
                          <th className='min-w-100px'>Title</th>
                          <th className='min-w-100px'>Status</th>
                          <th className='min-w-100px'>Development </th>
                          <th className='min-w-100px'>Total units</th>
                          <th className='min-w-100px'>Total Tenants</th>
                        </tr>
                      </thead>
                      <tbody className='fw-semibold text-gray-600'>
                        {announcementData?.length ? (
                          announcementData?.map((v: any, i: any) => {
                            return (
                              <tr>
                                <td className='text-center'> {v?.id}</td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-center'
                                >
                                  {v?.title}
                                </td>
                                <td className='text-center '>
                                  {v?.saveStatus === 0 ? 'Draft' : 'Save'}
                                </td>
                                <td className='text-center' data-order='2022-09-11'>
                                  {v?.announcement_recipient?.community[0]?.name
                                    ? v?.announcement_recipient?.community[0]?.name
                                    : v?.announcement_recipient?.building[0]?.name}
                                </td>
                                <td className='text-center' data-order='Denied'>
                                  {v?.announcement_recipient?.totalUnits}
                                </td>
                                <td className='text-center'>
                                  {v?.announcement_recipient?.totalTenants}
                                </td>

                                <td className='text-center pe-0'>
                                  {v?.saveStatus === 1 ? (
                                    <div className='menu-item px-3'>
                                      <div
                                        onClick={() => navigate(`/viewannouncement/${v?._id}`)}
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                      >
                                        View
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='menu-item px-3'>
                                      <div
                                        onClick={() =>
                                          navigate(`/edit-announcement/${v?._id}`, {
                                            state: {id: v?.announcement_recipient?._id},
                                          })
                                        }
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                      >
                                        Edit
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className='text-center pe-0'>
                                  <div className='menu-item px-3'></div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={16} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='kt_ecommerce_sales_table_paginate'
                      >
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel='>'
                          pageClassName='paginate_button page-item +'
                          pageLinkClassName='page-link'
                          containerClassName='pagination'
                          activeClassName='active'
                          previousClassName='paginate_button page-item previous disabled'
                          nextClassName='paginate_button page-item next'
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          previousLabel='<'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='card-body pt-0 table-responsive mt-5'></div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <GeneratePayment
        show={showGenRecModal}
        handleClose={() => {
          setShowGenRecModal(false)
        }}
        showModal={showModal}
        tenancy={tenancy}
        selectedPayments={generateReceipt}
        selectedIds={selectedPayments}
        getPaymentRecieptData={getPaymentRecieptData}
      />
      <TenatsFilter
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
          setShowModal('')
          setDate(false)
        }}
        showModal={showModal}
        type={type}
        date={date}
        onData={handleData}
        onData1={handleData1}
        // building={building}
        // updateDataId={updateDataId}
        // isEdit={isEdit}
        // formData={formData}
        // propertiType={propertiType}
        // handleSubmit={handleSubmit}
        handleChnage={handleChnage}
        // imageChange={imageChange}
      />
      <TenatsFilter1
        show={showCreateAppModal1}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        showModal={showModal}
        data={byiddata}
        callhandle={() => callextrapagere()}
      />

      <SendReminder
        show={showSendRemModal}
        handleClose={() => {
          setShowSendRemModal(false)
        }}
        showModal={showModal}
        tenancyPayement={tenancyPayement}
        tenancy={tenancy}
        getTenancyReminder={getTenancyReminder}
        totalPayments={tenancy?.totalPayments}
        selectedPayments={generateReceipt}
      />
      <ViewReminder
        show={showviewRemModal}
        handleClose={() => {
          setShowviewRemModal(false)
        }}
        showModal={showModal}
        reminderId={reminderId}
      />
      <ViewReceipt
        show={showPaymentReceipt}
        handleClose={() => {
          setShowPaymentReceipt(false)
        }}
        showModal={showModal}
        receiptId={receiptId}
        tenancy={tenancy}
      />
      <EditPaymentReceipt
        show={showEditReceipt}
        handleClose={() => {
          setShowEditReceipt(false)
        }}
        showModal={showModal}
        receiptId={receiptId}
        tenancy={tenancy}
        selectedPayments={generateReceipt}
        selectedIds={selectedPayments}
        getPaymentRecieptData={getPaymentRecieptData}
      />
      <ChequeData
        show={showData}
        handleClose={() => {
          setShowData(false)
        }}
        showChequeData={showChequeData}
      />
      <BankData
        show={cashshowData}
        handleClose={() => {
          setcashShowData(false)
        }}
        showBankData={showBankData}
        paymentId={paymentId}
      />
      <PaymentErrorModal
        show={errorModal}
        handleClose={() => {
          setErrorModal(false)
        }}
        errorType={123}
      />
    </>
  )
}

export default TenancyDetails

import React, {useState, useEffect} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
// import { Col, Row } from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import redCross from '../../../../img/remove.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import unitClusterGreenIcon from '../../../../img/unitClusterGreen.png'
import unitClusterWhiteIcon from '../../../../img/unitClusterWhite.png'
import mixedClusterGreenIcon from '../../../../img/mixedClusterGreen.png'
import mixedClusterWhiteIcon from '../../../../img/mixedClusterWhite.png'
import buildingClusterGreenIcon from '../../../../img/buildingClusterGreen.png'
import buildingClusterWhiteIcon from '../../../../img/buildingClusterWhite.png'
import buildingWhiteIcon from '../../../../img/small-building.png'
import buildingGreenIcon from '../../../../img/allBuildingGreen.png'
import communitiesWhiteIcon from '../../../../img/allCommunities.png'
import communitiesGreenIcon from '../../../../img/allCommunitiesGreen.png'
import carrotArrowDown from '../../../../img/caret-arrow-down.png'
import carrotArrowUp from '../../../../img/caret-arrow-up.png'

import swal from 'sweetalert2'
import {mixed} from 'yup'
import AddUnitCluster from './AddUnitCluster'
import AddBuildingCluster from './AddBuildingCluster'

interface SquareHandleProps {
  value: number
}

const AddPropertyDevelopment = () => {
  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>('allUnits')

  const [searchCommonArea, setSearchCommonArea] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const location: any = useLocation()

  // const fromBuilding: any = location?.state?.fromBuilding
  const fromGlobal: any = location?.state?.fromGlobal

  const goToMiantenance = (v: any) => {
    if (subTabListItem === 'floors') {
      navigate(`/properties/building-details/floors/1`, {state: {key: 'floors', name: 'Floor 1', developmentName: 'Al Rowdah Tower'}})
    }
  }

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const handleDatesChange = ({startDate, endDate}: {startDate: moment.Moment | null; endDate: moment.Moment | null}) => {
    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  const unitOptions = [
    {value: 'apartment', label: 'Apartment'},
    {value: 'pent_house', label: 'Penthouse'},
    {value: 'town_house', label: 'Townhouse'},
    {value: 'villa', label: 'Villa'},
    {value: 'common_area', label: 'Common Area'},
    {value: 'other', label: 'Other'},
  ]

  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ]
  const bathroomOptions = [
    {
      value: 1,
      label: '1 Bath',
    },
    {value: 2, label: '2 Bath'},
    {value: 3, label: '3 Bath'},
    {value: 4, label: '4 Bath'},
    {value: 5, label: '5 Bath'},
    {value: 6, label: '6 Bath'},
    {value: 7, label: '7 Bath'},
    {value: 8, label: '8 Bath'},
    {value: 9, label: '9 Bath'},
    {value: 10, label: '10 Bath'},
  ]
  const floorsOptions: any = []
  const developmentOptions: any = []
  const clusterOptions: any = []
  const groupOptions: any = []
  const buildingOptions: any = []
  const propertyTypeOptions: any = []

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    // padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
    // backgroundColor: '#fff',
  }
  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0.45rem',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.45rem',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const [selectBuildingCircle, setSelectBuildingCircle] = useState(location?.state?.isBuilding ? true : false)
  const [selectCommunityCircle, setSelectCommunityCircle] = useState(location?.state?.isCommunity ? true : false)
  const [unitClusterCircle, setUnitClusterCircle] = useState(false)
  const [buildingClusterCircle, setBuildingClusterCircle] = useState(false)
  const [mixedClusterCircle, setMixedClusterCircle] = useState(false)

  const [propertyUnitNo, setPropertyUnitNo] = useState('')
  const [propertyUnitType, setPropertyUnitType] = useState('')
  const [propertyCommonAreaNo, setPropertyCommonAreaNo] = useState('')
  const [propertySize, setPropertySize] = useState('')
  const [propertySizeType, setPropertySizeType] = useState(0)
  const [sizeOptions, setSizeOptions] = useState([
    {value: 0, label: 'Sq ft'},
    {value: 1, label: 'Sq mt'},
  ])
  const [propertyBedroom, setPropertyBedroom] = useState<any>('')
  const [propertyBathrooms, setPropertyBathroom] = useState<any>('')
  const [propertyType, setPropertyType] = useState<any>('')
  const [propertyFloors, setPropertyFloors] = useState<any>('')
  const [propertyDevelopment, setPropertyDevelopment] = useState<any>('')
  const [propertyCluster, setPropertyCluster] = useState<any>('')
  const [propertyGroup, setPropertyGroup] = useState<any>('')
  const [propertyBuilding, setPropertyBuilding] = useState<any>('')

  const [communityName, setCommunityName] = useState<any>('')
  const [buildingName, setBuildingName] = useState<any>('')
  const [selectCity, setSelectCity] = useState<any>('')
  const [streetName, setStreetName] = useState<any>('')
  const [selectArea, setSelectArea] = useState<any>('')

  const [selectBuildingCity, setSelectBuildingCity] = useState<any>('')
  const [streetBuildingName, setStreetBuildingName] = useState<any>('')
  const [selectBuildingArea, setSelectBuildingArea] = useState<any>('')
  const [unitInputs, setUnitInput] = useState<any>([{name: ''}])

  const [buildingDevelopmentFloor, setBuildingDevelopmentFloor] = useState<any>([
    {
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: 1,
    },
  ])

  const handleAddMixedCluster = (index: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster?.push({
      name: '',
      addUnitGroup: [{name: ''}],
      addBuilding: [
        {
          name: '',
          addFloor: [
            {
              floorType: '',
              name: '',
              floorStartRange: 1,
              floorEndRange: 1,
              index: 1,
            },
          ],
        },
      ],
    })
    setAllCluster(updateMixedCluster)
  }

  const handleAddBuildingForMixedCluster = (index: any, clusterIndex: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateMixedCluster)
  }
  const handleAddUnitGroup = (index: any, clusterIndex: any) => {
    let updateMixedCluster = [...allCluster]
    updateMixedCluster[index]?.addMixedCluster[clusterIndex]?.addUnitGroup?.push({name: ''})
    setAllCluster(updateMixedCluster)
  }

  const handleAddBuildingCluster = (index: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster?.push({
      name: '',
      addBuilding: [
        {
          name: '',
          addFloor: [
            {
              floorType: '',
              name: '',
              floorStartRange: 1,
              floorEndRange: 1,
              index: 1,
              prefix: '',
            },
          ],
        },
      ],
    })

    setAllCluster(updateCluster)
  }
  const handleAddBuildingForBuildingCluster = (index: any, builidngClusterIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[builidngClusterIndex]?.addBuilding?.push({
      name: '',
      addFloor: [
        {
          floorType: '',
          name: '',
          floorStartRange: 1,
          floorEndRange: 1,
          index: 1,
        },
      ],
    })
    setAllCluster(updateCluster)
  }

  const addFloorHandler = (index: any, builidngClusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[builidngClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: updateCluster[index]?.addBuildingCluster[builidngClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length + 1,
    })
    setAllCluster(updateCluster)
  }
  const addFloorHandlerForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any) => {
    let updateCluster = [...allCluster]
    updateCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: updateCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding?.length + 1,
    })
    setAllCluster(updateCluster)
  }
  const handleAddUnitInput = (index: any) => {
    let values = [...allCluster]
    values[index]?.addUnitCluster?.push({
      name: '',
    })
    setAllCluster(values)
  }

  const addFloorHandlerForBuildingDevelopment = () => {
    const updatedDevelopmentBuilding = [...buildingDevelopmentFloor]
    let valueItems: any = []
    valueItems.push({
      floorType: '',
      name: '',
      floorStartRange: 1,
      floorEndRange: 1,
      index: buildingDevelopmentFloor?.length + 1,
    })
    setBuildingDevelopmentFloor([...updatedDevelopmentBuilding, ...valueItems])
  }
  const handleRemoveDevelopmenetBuilding = (index: any) => {
    let values = [...buildingDevelopmentFloor]
    values.splice(index, 1)
    setBuildingDevelopmentFloor(values)
  }
  const handleRemoveUnitInput = (index: any, unitIndex: any) => {
    let values = [...allCluster]
    values[index]?.addUnitCluster.splice(unitIndex, 1)
    setAllCluster(values)
  }

  const handleRemoveFloorForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const updatedMixedCluster = [...allCluster]
    updatedMixedCluster[index].addMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor.splice(floorIndex, 1)

    const updatedFloors = checkForDuplicatesForMixedCluster(
      updatedMixedCluster[index]?.addMixedCluster[clusterIndex]?.addBuilding[buildingIndex]?.addFloor
    )
    updatedMixedCluster[index].addMixedCluster[clusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
    setAllCluster(updatedMixedCluster)
  }
  const handleRemoveUnitGroupForMixedCluster = (index: any, clusterIndex: any, groupIndex: any) => {
    const updatedMixedCluster = [...allCluster]
    updatedMixedCluster[index]?.addMixedCluster[clusterIndex]?.addUnitGroup?.splice(groupIndex, 1)
    setAllCluster(updatedMixedCluster)
  }

  const handleRemoveBuildingClusterBuilding = (index: any, buildingClusterIndex: any, buildingIndex: any) => {
    const updateCluster = [...allCluster]
    updateCluster[index]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setAllCluster(updateCluster)
  }
  const handleRemoveBuilingForMixedCluster = (index: any, clusterIndex: any, buildingIndex: any) => {
    const updatedBuildingCluster = [...allCluster]
    updatedBuildingCluster[index].addMixedCluster[clusterIndex]?.addBuilding?.splice(buildingIndex, 1)
    setAllCluster(updatedBuildingCluster)
  }
  const handleRemoveBuildingCluster = (index: any, clusterIndex: any) => {
    const updatedCluster = [...allCluster]
    updatedCluster[index]?.addBuildingCluster?.splice(clusterIndex, 1)
    setAllCluster(updatedCluster)
  }
  const handleRemoveMixedCluster = (index: any, clusterIndex: any) => {
    const updatedMixedCluster = [...allCluster]
    updatedMixedCluster[index]?.addMixedCluster?.splice(clusterIndex, 1)
    setAllCluster(updatedMixedCluster)
  }

  const resetPropertyType = () => {
    setUnitClusterCircle(false)
    setBuildingClusterCircle(false)
    setMixedClusterCircle(false)
  }

  useEffect(() => {
    resetPropertyType()
    resetBuildingDevelopmentFloor()
    resetCommunityDevelopment()
  }, [selectBuildingCircle, selectCommunityCircle])

  const [allCluster, setAllCluster] = useState<any>([
    {
      unitCircle: false,
      buildingCircle: false,
      mixedCircle: false,
      unitBlur: false,
      buildingBlur: false,
      mixedBlur: false,
      clusterType: '',
      addUnitCluster: [{name: ''}],
      addBuildingCluster: [
        {
          name: '',
          addBuilding: [
            {
              name: '',
              addFloor: [
                {
                  floorType: '',
                  name: '',
                  floorStartRange: 1,
                  floorEndRange: 1,
                  index: 1,
                },
              ],
            },
          ],
        },
      ],
      addMixedCluster: [
        {
          name: '',
          addUnitGroup: [{name: ''}],
          addBuilding: [
            {
              name: '',
              addFloor: [
                {
                  floorType: '',
                  name: '',
                  floorStartRange: 1,
                  floorEndRange: 1,
                  index: 1,
                },
              ],
            },
          ],
        },
      ],
      selectedColumn: '',
      selected: false,
      clusterIndex: 1,
    },
  ])

  const [clusterCounter, setClusterCouter] = useState(0)
  const handleAllCluster = () => {
    const clusterTemp = [...allCluster]
    const newCluster: any = []

    if (clusterTemp[clusterCounter]?.clusterType === 'unit') {
      clusterTemp[clusterCounter].buildingBlur = true
      clusterTemp[clusterCounter].mixedBlur = true
    }
    if (clusterTemp[clusterCounter]?.clusterType === 'building') {
      clusterTemp[clusterCounter].unitBlur = true
      clusterTemp[clusterCounter].mixedBlur = true
    }
    if (clusterTemp[clusterCounter]?.clusterType === 'mixed') {
      clusterTemp[clusterCounter].unitBlur = true
      clusterTemp[clusterCounter].buildingBlur = true
    }

    newCluster.push({
      unitCircle: false,
      buildingCircle: false,
      mixedCircle: false,
      unitBlur: false,
      buildingBlur: false,
      mixedBlur: false,
      clusterType: '',
      addUnitCluster: [{name: ''}],
      addBuildingCluster: [
        {
          name: '',
          addBuilding: [
            {
              name: '',
              addFloor: [
                {
                  floorType: '',
                  name: '',
                  floorStartRange: 1,
                  floorEndRange: 1,
                  index: 1,
                },
              ],
            },
          ],
        },
      ],
      addMixedCluster: [
        {
          name: '',
          addUnitGroup: [{name: ''}],
          addBuilding: [
            {
              name: '',
              addFloor: [
                {
                  floorType: '',
                  name: '',
                  floorStartRange: 1,
                  floorEndRange: 1,
                  index: 1,
                },
              ],
            },
          ],
        },
      ],
      selectedColumn: '',
      selected: false,
      clusterIndex: allCluster?.length + 1,
    })

    setClusterCouter(clusterCounter + 1)
    setAllCluster([...clusterTemp, ...newCluster])
  }

  const handleRemoveAllCluster = (index: any) => {
    // console.log(index)
    const clusterTemp = [...allCluster]

    if (index === 1) {
      clusterTemp.splice(index)
      setClusterCouter(0)
      clusterTemp[0].unitCircle = false
      clusterTemp[0].mixedCircle = false
      clusterTemp[0].buildingCircle = false
      clusterTemp[0].unitBlur = false
      clusterTemp[0].buildingBlur = false
      clusterTemp[0].mixedBlur = false
    }

    if (index === 2) {
      clusterTemp.splice(index, 1)
      setClusterCouter(clusterCounter - 1)
      clusterTemp[index - 1].unitCircle = false
      clusterTemp[index - 1].mixedCircle = false
      clusterTemp[index - 1].buildingCircle = false
      clusterTemp[index - 1].unitBlur = false
      clusterTemp[index - 1].buildingBlur = false
      clusterTemp[index - 1].mixedBlur = false
    }
    setAllCluster(clusterTemp)
  }

  useEffect(() => {
    const clusterTemp = [...allCluster]
    if (clusterCounter == 1 && allCluster?.length === 2) {
      if (allCluster[0]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (clusterTemp[0]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[0]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
    }
    if (clusterCounter == 2 && allCluster?.length === 3) {
      if (allCluster[0]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (allCluster[1]?.unitCircle === true) {
        clusterTemp[clusterCounter].unitBlur = true
      }
      if (clusterTemp[0]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[1]?.buildingCircle === true) {
        clusterTemp[clusterCounter].buildingBlur = true
      }
      if (clusterTemp[0]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
      if (clusterTemp[1]?.mixedCircle === true) {
        clusterTemp[clusterCounter].mixedBlur = true
      }
    }

    if (clusterCounter == 2) {
      if (clusterTemp[2].unitBlur === true && clusterTemp[2].buildingBlur) {
        clusterTemp[2].mixedCircle = true
      }
      if (clusterTemp[2].unitBlur === true && clusterTemp[2].mixedBlur) {
        clusterTemp[2].buildingCircle = true
      }
      if (clusterTemp[2].buildingBlur === true && clusterTemp[2].mixedBlur) {
        clusterTemp[2].unitCircle = true
      }
    }
    setAllCluster(clusterTemp)
  }, [clusterCounter])

  const [cityOptions, setCityOptions] = useState<any>([
    {
      label: 'Dubai',
      value: 'Dubai',
    },
    {
      label: 'Abu Dhabi',
      value: 'Abu Dhabi',
    },
    {
      label: 'Sharjah',
      value: 'Sharjah',
    },
    {
      label: 'Ajman',
      value: 'Ajman',
    },
    {
      label: 'Umm Al Quwain',
      value: 'Umm Al Quwain',
    },
    {
      label: 'Ras Al Khaimah',
      value: 'Ras Al Khaimah',
    },
    {
      label: 'Fujairah',
      value: 'Fujairah',
    },
  ])

  const [floorOptions, setFloorOptions] = useState<any>([
    {
      label: 'Ground Floor',
      value: 0,
    },
    {
      label: 'Lobby',
      value: 1,
    },
    {
      label: 'Mezzanine',
      value: 2,
    },
    {
      label: 'Parking',
      value: 3,
    },
    {
      label: 'Upper Level Unit',
      value: 4,
    },
  ])
  const [floorOptionTemp, setFloorOptionTemp] = useState<any>([
    {
      label: 'Ground Floor',
      value: 0,
    },
    {
      label: 'Lobby',
      value: 1,
    },
    {
      label: 'Mezzanine',
      value: 2,
    },
    {
      label: 'Parking',
      value: 3,
    },
    {
      label: 'Upper Level Unit',
      value: 4,
    },
  ])

  const [areaOptions, setAreaOptions] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isDevelopmentBtnDisabled, setIsDevelopmentBtnDisabled] = useState(true)
  const [totalBuildingFloors, setTotalBuildingFloors] = useState<any>(1)

  const resetBuildingDevelopmentFloor = () => {
    setStreetBuildingName('')
    setSelectBuildingArea('')
    setSelectBuildingCity('')
    setBuildingName('')
    setBuildingDevelopmentFloor([
      {
        floorType: '',
        name: '',
        floorStartRange: 1,
        floorEndRange: 1,
        index: 1,
      },
    ])
  }

  const resetAllClusterUI = () => {
    setAllCluster([
      {
        unitCircle: false,
        buildingCircle: false,
        mixedCircle: false,
        unitBlur: false,
        buildingBlur: false,
        mixedBlur: false,
        clusterType: '',
        addUnitCluster: [{name: ''}],
        addBuildingCluster: [
          {
            name: '',
            addBuilding: [
              {
                name: '',
                addFloor: [
                  {
                    floorType: '',
                    name: '',
                    floorStartRange: 1,
                    floorEndRange: 1,
                    index: 1,
                  },
                ],
              },
            ],
          },
        ],
        addMixedCluster: [
          {
            name: '',
            addUnitGroup: [{name: ''}],
            addBuilding: [
              {
                name: '',
                addFloor: [
                  {
                    floorType: '',
                    name: '',
                    floorStartRange: 1,
                    floorEndRange: 1,
                    index: 1,
                  },
                ],
              },
            ],
          },
        ],
        selectedColumn: '',
        selected: false,
        clusterIndex: 1,
      },
    ])
  }

  const resetCommunityDevelopment = () => {
    setCommunityName('')
    setSelectCity('')
    setSelectArea('')
    setStreetName('')
    resetAllClusterUI()
  }

  // return area options based on selected city for both building and community
  useEffect(() => {
    let values: any = []
    if (selectBuildingCity?.value == 'Dubai' || selectCity?.value == 'Dubai') {
      values = [
        {
          label: 'Al Jaddaf',
          value: 'Al Jaddaf',
        },
        {
          label: 'Barsha',
          value: 'Barsha',
        },
        {
          label: 'Culture Village',
          value: 'Culture Village',
        },
        {
          label: 'Jumeriah Lake Towers',
          value: 'Jumeriah Lake Towers',
        },
      ]
    } else if (selectBuildingCity.value == 'Abu Dhabi' || selectCity?.value == 'Abu Dhabi') {
      values = [
        {
          label: 'Al Bateen',
          value: 'Al Bateen',
        },
        {
          label: 'Al Saadah',
          value: 'Al Saadah',
        },
        {
          label: 'Corniche',
          value: 'Corniche',
        },
        {
          label: 'Yas Island',
          value: 'Yas Island',
        },
      ]
    } else if (selectBuildingCity.value == 'Sharjah' || selectCity?.value == 'Sharjah') {
      values = [
        {
          label: 'Al Qasimia',
          value: 'Al Qasimia',
        },
        {
          label: 'Halwan',
          value: 'Halwan',
        },
        {
          label: 'Industrial Area',
          value: 'Industrial Area',
        },
        {
          label: 'Muwaileh',
          value: 'Muwaileh',
        },
      ]
    } else if (selectBuildingCity.value == 'Ajman' || selectCity?.value == 'Ajman') {
      values = [
        {
          label: 'Al Bustan',
          value: 'Al Bustan',
        },
        {
          label: 'Al Hamidiya',
          value: 'Al Hamidiya',
        },
        {
          label: 'Al Jerf 1',
          value: 'Al Jerf 1',
        },
        {
          label: 'Al Jerf 2',
          value: 'Al Jerf 2',
        },
        {
          label: 'Al Rawda',
          value: 'Al Rawda',
        },
      ]
    } else if (selectBuildingCity.value == 'Umm Al Quwain' || selectCity?.value == 'Umm Al Quwain') {
      values = [
        {
          label: 'Al Butain',
          value: 'Al Butain',
        },
        {
          label: 'Al Neefah',
          value: 'Al Neefah',
        },
        {
          label: 'Al Raas',
          value: 'Al Raas',
        },
        {
          label: 'Al Raudah',
          value: 'Al Raudah',
        },
        {
          label: 'Al Salamah',
          value: 'Al Salamah',
        },
      ]
    } else if (selectBuildingCity.value == 'Ras Al Khaimah' || selectCity?.value == 'Ras Al Khaimah') {
      values = [
        {
          label: 'Al Dhait South',
          value: 'Al Dhait South',
        },
        {
          label: 'Al Kharran',
          value: 'Al Kharran',
        },
        {
          label: 'Al Sewaan',
          value: 'Al Sewaan',
        },
        {
          label: 'Al Digdaga',
          value: 'Al Digdaga',
        },
        {
          label: 'Dafn Al Khor',
          value: 'Dafn Al Khor',
        },
      ]
    } else if (selectBuildingCity.value == 'Fujairah' || selectCity?.value == 'Fujairah') {
      values = [
        {
          label: 'Al Faseel',
          value: 'Al Faseel',
        },
        {
          label: 'Al Gurfah',
          value: 'Al Gurfah',
        },
        {
          label: 'Al Sharyah',
          value: 'Al Sharyah',
        },
        {
          label: 'Al Righailat',
          value: 'Al Righailat',
        },
        {
          label: 'Safad',
          value: 'Safad',
        },
      ]
    }
    setAreaOptions(values)
  }, [selectBuildingCity, selectCity])

  // disable add development Button for buildingDevelopmentFloor & Community
  useEffect(() => {
    if (selectBuildingCircle) {
      let isBuildingDevelopmentFieldsFilled = streetBuildingName && selectBuildingArea && selectBuildingCity && buildingName
      const isBuildingFloorFilled = [...buildingDevelopmentFloor]?.every(
        (floorItem: any) =>
          floorItem?.floorType !== '' && floorItem?.name?.trim() !== '' && floorItem?.floorStartRange !== null && floorItem?.floorEndRange !== null
      )
      setIsDevelopmentBtnDisabled(isBuildingDevelopmentFieldsFilled && isBuildingFloorFilled ? false : true)
    }
  }, [streetBuildingName, selectBuildingArea, selectBuildingCity, buildingName, buildingDevelopmentFloor])

  const isFloorFilled = (floor: any) => {
    return floor.name.trim() !== '' && floor.floorType !== '' && floor.floorStartRange !== null && floor.floorEndRange !== null
  }

  // Function to check if a building cluster object is filled
  const isBuildingClusterFilled = (buildingCluster: any) => {
    return (
      buildingCluster.name.trim() !== '' &&
      buildingCluster?.addBuilding?.every((cluster: any) => {
        return cluster.name.trim() !== '' && cluster.addFloor.every(isFloorFilled)
      })
    )
  }

  const isAllItemsFilled = (index: any) => {
    return allCluster[index].addBuildingCluster?.every(isBuildingClusterFilled)
  }

  const isAllMixedItemsFilled = (index: any) => {
    return allCluster[index]?.addMixedCluster?.every(isBuildingClusterFilled)
  }

  useEffect(() => {
    if (selectCommunityCircle) {
      let isUnitCluster = allCluster.some((clusterItem: any) => clusterItem?.unitCircle)
      let isBuildingCluster = allCluster.some((clusterItem: any) => clusterItem?.buildingCircle)
      let isMixedCluster = allCluster.some((clusterItem: any) => clusterItem?.mixedCircle)
      let isCommunityDevelopmentFieldsFilled = streetName && communityName && selectCity && selectArea
      let i = 0
      while (i < allCluster?.length) {
        if (allCluster[i]?.buildingCircle) {
          const isFilledAll = isAllItemsFilled(i)
          setIsDevelopmentBtnDisabled(isCommunityDevelopmentFieldsFilled && isFilledAll ? false : true)
        } else if (allCluster[i]?.unitCircle) {
          const isFilledAll = allCluster[i]?.addUnitCluster?.every((inputItem: any) => inputItem?.name?.trim() !== '')
          setIsDevelopmentBtnDisabled(isCommunityDevelopmentFieldsFilled && isFilledAll ? false : true)
        } else if (allCluster[i]?.mixedCircle) {
          const isFilledAllBuildingUnits = isAllMixedItemsFilled(i)
          const isFilledAllUnits = allCluster[i]?.addMixedCluster?.every((inputItem: any) => {
            return inputItem?.addUnitGroup?.every((item: any) => item?.name?.trim() !== '')
          })
          setIsDevelopmentBtnDisabled(isCommunityDevelopmentFieldsFilled && isFilledAllBuildingUnits && isFilledAllUnits ? false : true)
        } else if (!allCluster[i]?.buildingCircle && !allCluster[i]?.unitCircle && !allCluster[i]?.mixedCircle) {
          setIsDevelopmentBtnDisabled(isCommunityDevelopmentFieldsFilled ? false : true)
        } else {
          setIsDevelopmentBtnDisabled(true)
        }
        i++
      }
    }
  }, [allCluster, streetName, communityName, selectCity, selectArea, allCluster, unitInputs])

  // filter ground floor if selected in any row for buildingDevelopmentFloor
  useEffect(() => {
    if (selectBuildingCircle) {
      let updatedOptions = floorOptions
      const isSelectGroundFloor = buildingDevelopmentFloor?.some((floorItem: any) => floorItem?.floorType === 0)
      if (isSelectGroundFloor) {
        updatedOptions = updatedOptions.filter((floorItem: any) => floorItem?.value !== 0)
        setFloorOptions(updatedOptions)
      } else {
        setFloorOptions(floorOptionTemp)
      }
    }
  }, [buildingDevelopmentFloor])

  // total floor for buildingDevelopmentFloor
  useEffect(() => {
    let totalFloors = 0
    buildingDevelopmentFloor?.forEach((floorItem: any) => {
      // totalFloors += (floorItem.floorEndRange - floorItem.floorStartRange) + 1
      totalFloors +=
        floorItem?.floorStartRange > floorItem?.floorEndRange
          ? 1 : floorItem?.floorEndRange - floorItem?.floorStartRange + 1
    })
    setTotalBuildingFloors(totalFloors)
  }, [buildingDevelopmentFloor])

  const isUnitCircle1 = allCluster[0]?.unitCircle
  const isBuildingCircle1 = allCluster[0]?.buildingCircle
  const isMixedCircle1 = allCluster[0]?.mixedCircle
  const isUnitCircle2 = allCluster[1]?.unitCircle
  const isBuildingCircle2 = allCluster[1]?.buildingCircle
  const isMixedCircle2 = allCluster[1]?.mixedCircle
  const isUnitCircle3 = allCluster[2]?.unitCircle
  const isBuildingCircle3 = allCluster[2]?.buildingCircle
  const isMixedCircle3 = allCluster[2]?.mixedCircle

  useEffect(() => {
    // if (allCluster.length !== 1) return;
    if (allCluster.length < 1 || allCluster.length > 3) return
    setAllCluster((prevClusters: any) => {
      return prevClusters.map((cluster: any) => {
        if (prevClusters.length === 1) {
          const isUnitClusterSelected = cluster.unitCircle
          const isBuildingClusterSelected = cluster.buildingCircle
          const isMixedClusterSelected = cluster.mixedCircle

          if (isUnitClusterSelected) {
            return {
              ...cluster,
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isBuildingClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isMixedClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          }
        } else if (prevClusters.length === 2) {
          // console.log('length 2')
          const isUnitClusterSelected = cluster.unitCircle
          const isBuildingClusterSelected = cluster.buildingCircle
          const isMixedClusterSelected = cluster.mixedCircle

          if (isUnitClusterSelected) {
            return {
              ...cluster,
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isBuildingClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isMixedClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          }
        } else if (prevClusters.length === 3) {
          const isUnitClusterSelected = cluster.unitCircle
          const isBuildingClusterSelected = cluster.buildingCircle
          const isMixedClusterSelected = cluster.mixedCircle

          if (isUnitClusterSelected) {
            return {
              ...cluster,
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isBuildingClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addMixedCluster: [
                {
                  name: '',
                  addUnitGroup: [{name: ''}],
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          } else if (isMixedClusterSelected) {
            return {
              ...cluster,
              addUnitCluster: [{name: ''}],
              addBuildingCluster: [
                {
                  name: '',
                  addBuilding: [
                    {
                      name: '',
                      addFloor: [
                        {
                          floorType: '',
                          name: '',
                          floorStartRange: 1,
                          floorEndRange: 1,
                          index: 1,
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          }
        }
        return cluster
      })
    })
  }, [
    isUnitCircle1,
    isBuildingCircle1,
    isMixedCircle1,
    isUnitCircle2,
    isBuildingCircle2,
    isMixedCircle2,
    isUnitCircle3,
    isBuildingCircle3,
    isMixedCircle3,
  ])

  const generateBuildingFloorForAPI = (i: any) => {
    let addBuildingCluster: any = JSON.parse(JSON.stringify(allCluster[i]?.addBuildingCluster))

    let buildingClusterIndex = 0
    while (buildingClusterIndex < allCluster[i]?.addBuildingCluster?.length) {
      addBuildingCluster[buildingClusterIndex].name = allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.name
      let buildingIndex = 0
      while (buildingIndex < allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding?.length) {
        addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].name =
          allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.name
        let floorIndex = 0
        let rowIndex = 1
        addBuildingCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = []
        while (floorIndex < allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length) {
          const range =
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange -
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange +
            1
          const floorStartRange =
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange
          const floorEndRange =
            allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange
          for (let j = floorStartRange; j <= floorEndRange; j++) {
            addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
              floorType: allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType?.value,
              name:
                allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType?.value === 0
                  ? allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name
                  : allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name + ` ${j}`,
              index: rowIndex,
              prefix: allCluster[i]?.addBuildingCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name,
            })
            rowIndex += 1
          }
          floorIndex++
        }
        buildingIndex++
      }

      buildingClusterIndex++
    }
    return addBuildingCluster
  }

  const generateMixedClusterAPIData = (i: any) => {
    let addMixedCluster: any = JSON.parse(JSON.stringify(allCluster[i]?.addMixedCluster))

    let mixedClusterIndex = 0
    while (mixedClusterIndex < allCluster[i]?.addMixedCluster?.length) {
      addMixedCluster[mixedClusterIndex].name = allCluster[i]?.addMixedCluster[mixedClusterIndex]?.name
      let buildingIndex = 0
      while (buildingIndex < allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.length) {
        addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].name =
          allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.name
        let floorIndex = 0
        let rowIndex = 1
        addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].addFloor = []
        while (floorIndex < allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.length) {
          const range =
            allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange -
            allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange +
            1
          const floorStartRange = allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorStartRange
          const floorEndRange = allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorEndRange
          for (let j = floorStartRange; j <= floorEndRange; j++) {
            addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.push({
              floorType: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType.value,
              // name: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name
              //     .replace(/\s+/g, '-') + `-${rowIndex}`,
              name:
                allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.floorType?.value === 0
                  ? allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name
                  : allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name + ` ${j}`,
              index: rowIndex,
              // prefix: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name
              //     .replace(/\s+/g, '-')
              prefix: allCluster[i]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor[floorIndex]?.name,
            })
            rowIndex += 1
          }
          floorIndex++
        }
        buildingIndex++
      }

      mixedClusterIndex++
    }
    return addMixedCluster
  }

  const calculateTotalFloors = (index: any, mixedClusterIndex: any, buildingIndex: any) => {
    let totalFloors = 0
    allCluster[index]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]?.addFloor?.forEach((floorItem: any) => {
      if (floorItem?.floorStartRange > floorItem?.floorEndRange) {
        totalFloors += 1
      } else {
        totalFloors += floorItem.floorEndRange - floorItem.floorStartRange + 1
      }
    })
    return totalFloors
  }

  const floorOption = (index: any, clusterIndex: any, buildingIndex: any, floorIndex: any, type: 'building' | 'mixed') => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    const cluster = type === 'building' ? allCluster[index]?.addBuilidngCluster : allCluster[index]?.addMixedCluster

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = cluster?.[clusterIndex]?.addBuilding?.[buildingIndex]?.addFloor
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const floorOptionForAddBuilding = (floorIndex: any, type: 'building' | 'mixed') => {
    let options: any = []
    let updatedOptions = floorOptions

    // Determine which cluster to use based on the type parameter
    // const cluster = type === 'building' ? allCluster[index]?.addBuilidngCluster : allCluster[index]?.addMixedCluster;

    // Check if there are selected floors with floorType 0, 1, 2, or 3
    const selectedFloorTypes = buildingDevelopmentFloor
      // ?.filter((floorItem: any) => [0, 1, 2, 3].includes(floorItem?.floorType.value))
      ?.filter((floorItem: any) => [0].includes(floorItem?.floorType.value))
      ?.map((floorItem: any) => floorItem?.floorType.value)

    if (selectedFloorTypes && selectedFloorTypes.length > 0) {
      // Filter out only the selected floor types from updatedOptions
      options = updatedOptions.filter((floorItem: any) => !selectedFloorTypes.includes(floorItem?.value))
    } else {
      options = updatedOptions
    }

    return options
  }

  const generateFloorForAPI = () => {
    let i = 0
    let value: any = []
    let rowIndex = 1
    while (i < buildingDevelopmentFloor.length) {
      const range = buildingDevelopmentFloor[i]?.floorEndRange - buildingDevelopmentFloor[i]?.floorStartRange + 1
      for (let j = buildingDevelopmentFloor[i]?.floorStartRange; j <= buildingDevelopmentFloor[i]?.floorEndRange; j++) {
        value.push({
          floorType: buildingDevelopmentFloor[i].floorType.value,
          name: buildingDevelopmentFloor[i].floorType.value === 0 ? buildingDevelopmentFloor[i].name : buildingDevelopmentFloor[i].name + ` ${j}`,
          index: rowIndex,
          prefix: buildingDevelopmentFloor[i].name,
        })

        rowIndex += 1
      }
      i++
    }
    return value
  }

  const addDevelopment = async () => {
    setIsLoading(true)
    let floors: any = []
    let addBuildingCluster: any = []
    let addMixedCluster: any = []
    let addUnitCluster: any = []
    // selectCommunityCircle
    if (selectBuildingCircle) {
      if (buildingDevelopmentFloor?.some((floor: any) => floor.startRangeError)) {
        ErrorToast("The floor 'Start Range' should be equal to or less than the floor 'End Range'.")
        return
      }

      if(buildingDevelopmentFloor?.some((floor: any) => floor.endRangeError)){
        ErrorToast("The floor 'End Range' should be equal to or greater than the floor 'Start Range'.")
        return
      }
      
      if (buildingDevelopmentFloor?.some((floor: any) => floor.isDuplicate)) {
        ErrorToast('Floor Names are duplicated')
        return
      }
      floors = generateFloorForAPI()
    }

    let isDuplicatedInBuildingCluster: any = []
    let isDuplicatedInMixedCluster: any = []
    let startRangeErrorInBuildingCluster: any = []
    let endRangeErrorInBuildingCluster: any = []
    let startRangeErrorInMixedCluster: any = []
    let endRangeErrorInMixedCluster: any = []
    let j = 0
    while (j < allCluster.length) {
      allCluster[j]?.addBuildingCluster?.forEach((building: any) => {
        building.addBuilding.forEach((item: any, index: any) => {
          const isStartRangeError = item?.addFloor?.some((floor: any, i: any) =>  floor.startRangeError)
          if (isStartRangeError) {
            startRangeErrorInBuildingCluster.push(index)
          }
          const isEndRangeError = item?.addFloor?.some((floor: any, i: any) => floor.endRangeError)
          if (isEndRangeError) {
            endRangeErrorInBuildingCluster.push(index)
          }
          const isDuplicate = item?.addFloor?.some((floor: any, i: any) => floor.isDuplicate)
          if (isDuplicate) {
            isDuplicatedInBuildingCluster.push(index)
          }
        })
      })

      allCluster[j]?.addMixedCluster?.forEach((building: any) => {
        building.addBuilding.forEach((item: any, index: any) => {
          const isStartRangeError = item?.addFloor?.some((floor: any, i: any) =>  floor.startRangeError)
          if (isStartRangeError) {
            startRangeErrorInMixedCluster.push(index)
          }
          const isEndRangeError = item?.addFloor?.some((floor: any, i: any) => floor.endRangeError)
          if (isEndRangeError) {
            endRangeErrorInBuildingCluster.push(index)
          }
          const isDuplicate = item?.addFloor?.some((floor: any, i: any) => floor.isDuplicate)
          if (isDuplicate) {
            isDuplicatedInMixedCluster.push(index)
          }
        })
      })
      j++
    }

    if (startRangeErrorInMixedCluster.length >= 1 || startRangeErrorInBuildingCluster.length >= 1) {
      ErrorToast("The floor 'Start Range' should be equal to or less than the floor 'End Range'.")
      return
    }
    if (endRangeErrorInMixedCluster.length >= 1 || endRangeErrorInMixedCluster.length >= 1) {
      ErrorToast("The floor 'End Range' should be equal to or greater than the floor 'Start Range'.")
      return
    }

    if (isDuplicatedInBuildingCluster.length >= 1 || isDuplicatedInMixedCluster.length >= 1) {
      ErrorToast('Floor Names are duplicated')
      return
    }

    let i = 0
    while (i < allCluster.length) {
      if (allCluster[i]?.buildingCircle) {
        addBuildingCluster = generateBuildingFloorForAPI(i)
      }
      if (allCluster[i]?.mixedCircle) {
        addMixedCluster = generateMixedClusterAPIData(i)
      }
      if (allCluster[i]?.unitCircle) {
        addUnitCluster = allCluster[i]?.addUnitCluster
      }
      i++
    }
    let body : any = {}
  

    if (location?.state?.isBuilding !== undefined && location?.state?.isBuilding === true || selectBuildingCircle) {
      body = {
        buildingDevelopments: [
          {
            name: buildingName,
            city: selectBuildingCity?.value,
            area: selectBuildingArea?.value,
            street: streetBuildingName,
            addFloor: floors,
          },
        ],
      }
    }

    if (location?.state?.isCommunity !== undefined && location?.state?.isCommunity === true || selectCommunityCircle) {
      body = {
        communityDevelopments: [
          {
            name: communityName,
            city: selectCity?.value,
            area: selectArea?.value,
            street: streetName,
            addUnitCluster: addUnitCluster,
            addBuildingCluster: addBuildingCluster,
            addMixedCluster: addMixedCluster,
          },
        ],
      }
    }

    await ApiPost('corporate/development', body)
      .then((res) => {
        resetBuildingDevelopmentFloor()
        resetCommunityDevelopment()
        setIsLoading(false)
        navigate('/properties')
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const swapRowForAdd = (prevInd: any, nextInd: any, index: any, buildingClusterIndex: any, buildingIndex: any, floorIndex: any) => {
    const values = [...allCluster]

    // Navigate to the specific array
    const valuesSections = values[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
    // allCluster[index]?.updateBuildingCluster[buildingClusterIndex]?.updateBuilding[buildingIndex]?.addFloor

    if (!valuesSections || prevInd >= valuesSections.length || nextInd >= valuesSections.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setAllCluster(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]
      const updatedValuesSections = updatedValues[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor

      // Swap the items
      let temp = updatedValuesSections[prevInd]
      updatedValuesSections[prevInd] = updatedValuesSections[nextInd]
      updatedValuesSections[nextInd] = temp

      // Reset animation classes
      updatedValuesSections[prevInd].animationClass = ''
      updatedValuesSections[nextInd].animationClass = ''

      // Assign the updated array back to the structure

      updatedValues[index].addMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedValuesSections

      // Update state again to reflect the swap and reset animations
      setAllCluster(updatedValues)

      // update red box
      const updatedFloors = checkForDuplicatesForMixedCluster(
        updatedValues[index]?.addMixedCluster[buildingClusterIndex]?.addBuilding[buildingIndex]?.addFloor
      )
      // setDuplicateIndicesForMixedCluster(duplicates);

      updatedValues[index].addMixedCluster[buildingClusterIndex].addBuilding[buildingIndex].addFloor = updatedFloors
      setAllCluster(updatedValues)
    }, 100)
  }
  const swapRowForAddBuilding = (prevInd: any, nextInd: any, index: any) => {
    const values = [...buildingDevelopmentFloor]

    if (!values || prevInd >= values.length || nextInd >= values.length) {
      // console.error("Invalid indices");
      return
    }

    // // Add animation classes
    values[prevInd].animationClass = 'move-down'
    values[nextInd].animationClass = 'move-up'

    setBuildingDevelopmentFloor(values)

    setTimeout(() => {
      // Clone the updated structure again to avoid direct mutation
      const updatedValues = [...values]

      // Swap the items
      let temp = updatedValues[prevInd]
      updatedValues[prevInd] = updatedValues[nextInd]
      updatedValues[nextInd] = temp

      // Reset animation classes
      updatedValues[prevInd].animationClass = ''
      updatedValues[nextInd].animationClass = ''

      setBuildingDevelopmentFloor(updatedValues)

      //  const updatedFloors = checkForDuplicates([...updatedValues]);
      //   setBuildingDevelopmentFloor(updatedFloors);
    }, 100)
  }

  const [duplicateIndices, setDuplicateIndices] = useState<any>([])

  useEffect(() => {
    const updatedFloors = checkForDuplicates([...buildingDevelopmentFloor])
    setBuildingDevelopmentFloor(updatedFloors)
  }, [buildingDevelopmentFloor.length])

  const checkForDuplicates = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      // const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`;
      // floor.isDuplicate = false;  // Initialize as not duplicate
      // floor.errorMessage = '';  // Initialize error message

      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  const [duplicateIndicesForBuildingCluster, setDuplicateIndicesForBuildingCluster] = useState<any>([])
  const [duplicateIndicesForMixedCluster, setDuplicateIndicesForMixedCluster] = useState<any>([])

  const checkForDuplicatesForMixedCluster = (floors: any) => {
    const floorMap = new Map()

    floors.forEach((floor: any, index: any) => {
      if (!floor.floorType?.value) {
        floor.isDuplicate = false
        floor.errorMessage = ''
        return
      }

      const key = `${floor.floorType?.value}-${floor.name.toLowerCase()}`
      floor.isDuplicate = false // Initialize as not duplicate
      floor.errorMessage = '' // Initialize error message

      if (floorMap.has(key)) {
        const existingIndices = floorMap.get(key)
        for (const existingIndex of existingIndices) {
          const existingFloor = floors[existingIndex]
          const isOverlapping = !(floor.floorEndRange < existingFloor.floorStartRange || floor.floorStartRange > existingFloor.floorEndRange)

          if (isOverlapping) {
            floor.isDuplicate = true
            floor.errorMessage = 'Floor Name is duplicated'
            floors[existingIndex].isDuplicate = true
            floors[existingIndex].errorMessage = 'Floor Name is duplicated'
            break
          }
        }
        floorMap.get(key).push(index)
      } else {
        floorMap.set(key, [index])
      }
    })

    return floors
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center mb-1'>
                  <h2 className='m-0 head-text ms-2'>
                    {!location?.state?.isEdit && <b> Add a Development</b>}
                    {location?.state?.isEdit && <b> Edit Development</b>}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-between'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold red-hollow-btn ps-2 ms-auto mt-2'
                  onClick={() => {
                    if (location?.state?.from === 'global') {
                      navigate('/properties')
                    } else if (location?.state?.from === 'building') {
                      navigate(`/properties/building-details/${location?.state?.buildingId}`)
                    } else if (location?.state?.from === 'community') {
                      navigate(`/properties/community-details/${location?.state?.communityId}`, {
                        state: {communityName: location?.state?.communityName},
                      })
                    }
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush  px-7' style={{paddingBottom: '80px', paddingTop: '30px'}}>
          <div className='row'>
            <div className='col-10 mx-auto'>
              {/* select category */}
              <div className='row mt-7'>
                <div className='col-12 mx-auto text-center mt-7'>
                  <h2 className='m-0 head-text mb-2'>
                    <b>Select Development Type</b>
                  </h2>
                  <h4 className='m-0 head-text mb-7'>Select the type of development that your properties fall under.</h4>
                </div>
                <div className='row d-flex justify-content-center'>
                  <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{position: 'relative'}}>
                      {location?.state?.isCommunity == true && (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(255,255,255,0.5)',
                          }}
                        ></div>
                      )}
                      <div
                        className={`${selectBuildingCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3 `}
                        onClick={() => {
                          if (selectBuildingCircle || selectCommunityCircle) {
                            swal
                              .fire({
                                html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                                <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                            `,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  setSelectBuildingCircle(!selectBuildingCircle)
                                  setSelectCommunityCircle(false)
                                  // resetPropertyType()
                                }
                              })
                          } else {
                            setSelectBuildingCircle(!selectBuildingCircle)
                            setSelectCommunityCircle(false)
                          }
                        }}
                      >
                        <img src={selectBuildingCircle ? buildingWhiteIcon : buildingGreenIcon} height={80} width={80} className='d-block' />
                        <h2 className={`head-text mt-3 ${selectBuildingCircle ? 'headTextWhite' : ''}`}>
                          <b>Building</b>
                        </h2>
                      </div>
                    </div>

                    <p className='m-0 fs-5 fw-bold  mb-1 mt-5 text-center' style={{color: '#7f7f7f'}}>
                      Standalone building
                    </p>
                  </div>
                  <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{position: 'relative'}}>
                      {location?.state?.isBuilding == true && (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgba(255,255,255,0.5)',
                          }}
                        ></div>
                      )}
                      <div
                        className={` ${selectCommunityCircle ? 'requestBoxColor box-circle ' : 'requestBox box-circle'} mt-3 `}
                        onClick={() => {
                          if (selectBuildingCircle || selectCommunityCircle) {
                            swal
                              .fire({
                                html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                    <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                `,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  setSelectCommunityCircle(!selectCommunityCircle)
                                  setSelectBuildingCircle(false)
                                  // resetPropertyType()
                                }
                              })
                          } else {
                            setSelectCommunityCircle(!selectCommunityCircle)
                            setSelectBuildingCircle(false)
                          }
                        }}
                      >
                        <img src={selectCommunityCircle ? communitiesWhiteIcon : communitiesGreenIcon} height={80} width={80} className='d-block' />
                        <h2 className={`head-text mt-3 ${selectCommunityCircle ? 'headTextWhite' : ''}`}>
                          <b>Community</b>
                        </h2>
                      </div>
                    </div>

                    <p className='m-0 fs-5 fw-bold  mb-1 mt-5 text-center ' style={{color: '#7f7f7f'}}>
                      Communities that include multiple properties across different zones, groups or buildings within the same area
                    </p>
                  </div>
                </div>
              </div>

              {selectCommunityCircle && (
                <div className='row my-7'>
                  <div className='col-10 mx-auto d-flex align-items-center justify-content-between'>
                    <div className='me-4'>
                      <h3 className='m-0 head-text mb-1 ms-1'>Community Name</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter community name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Community Name'}}
                            value={communityName}
                            onChange={(e: any) => {
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              setCommunityName(captilizeWord)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>City</h3>

                      <Select
                        styles={customStyles}
                        isSearchable={false}
                        options={cityOptions}
                        name='city'
                        value={selectCity}
                        onChange={(e: any) => {
                          setSelectCity(e)
                        }}
                        placeholder={'Select City'}
                      />
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>Area</h3>

                      <Select
                        styles={customStyles}
                        isSearchable={false}
                        options={areaOptions}
                        name='area'
                        value={selectArea}
                        onChange={(e: any) => {
                          setSelectArea(e)
                        }}
                        placeholder={'Select Area'}
                      />
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>Street</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter street name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Street name'}}
                            value={streetName}
                            onChange={(e: any) => {
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              setStreetName(captilizeWord)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectBuildingCircle && (
                <div className='row my-7'>
                  <div className='col-10 mx-auto d-flex align-items-center justify-content-between'>
                    <div className='me-4'>
                      <h3 className='m-0 head-text mb-1 ms-1'>Building Name</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter building name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Building Name'}}
                            value={buildingName}
                            onChange={(e: any) => {
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              setBuildingName(captilizeWord)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>City</h3>

                      <Select
                        styles={customStyles}
                        isSearchable={true}
                        options={cityOptions}
                        value={selectBuildingCity}
                        name='city'
                        onChange={(e: any) => {
                          setSelectBuildingCity(e)
                        }}
                        placeholder={'Select City'}
                      />
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>Area</h3>

                      <Select
                        styles={customStyles}
                        isSearchable={true}
                        options={areaOptions}
                        value={selectBuildingArea}
                        name='area'
                        onChange={(e: any) => {
                          setSelectBuildingArea(e)
                        }}
                        placeholder={'Select Area'}
                      />
                    </div>

                    <div className='me-4 '>
                      <h3 className='m-0 head-text mb-1 ms-1'>Street</h3>
                      <div className='wxyz'>
                        <Paper component='form' style={searchContainerStyle}>
                          <InputBase
                            placeholder='Enter street name'
                            style={inputBaseStyle}
                            inputProps={{'aria-label': 'Street name'}}
                            value={streetBuildingName}
                            onChange={(e: any) => {
                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                              setStreetBuildingName(captilizeWord)
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* select community cluster */}
              {selectCommunityCircle && (
                <div className='row'>
                  <div className='col-12 mx-auto text-center mt-7'>
                    <h2 className='m-0 head-text mb-2'>
                      <b>Select Community Clusters</b>
                    </h2>
                    <h4 className='m-0 head-text mb-7'>Select the type of cluster that your properties fall under.</h4>
                  </div>
                  {allCluster?.map((item: any, ind: any) => (
                    <>
                      <div className='row d-flex justify-content-center ' style={{marginTop: '50px', position: 'relative'}}>
                        {ind !== 0 && (
                          <div className='d-flex justify-content-end' style={{position: 'absolute', top: '20px', right: 0, zIndex: 99}}>
                            <img style={{cursor: 'pointer'}} src={redCross} height={14} width={14} onClick={() => handleRemoveAllCluster(ind)} />
                          </div>
                        )}
                        <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
                          {allCluster[ind].unitBlur === true && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                              }}
                            ></div>
                          )}
                          <div
                            className={`${item.unitCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}
                            // onClick={() => handleColumnSelect(ind, 'unit')}
                            onClick={() => {
                              const allClusterTemp = [...allCluster]
                              allClusterTemp[ind].clusterType = 'unit'
                              allClusterTemp[ind].unitCircle = true
                              allClusterTemp[ind].buildingCircle = false
                              allClusterTemp[ind].mixedCircle = false
                              allClusterTemp[ind].selected = true
                              setAllCluster(allClusterTemp)
                            }}
                          >
                            <img src={item.unitCircle ? unitClusterWhiteIcon : unitClusterGreenIcon} height={80} width={80} className='d-block' />
                            <h2 className={`head-text mt-3 ${item.unitCircle ? 'headTextWhite' : ''}`}>
                              <b>Unit Clusters</b>
                            </h2>
                          </div>
                          <p className='m-0 fs-5 fw-bold mb-1 mt-5 text-center' style={{color: '#7f7f7f', width: '160px'}}>
                            A group of units (villas/ townhouses) located in close proximity to each other within the community
                          </p>
                        </div>
                        <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
                          {allCluster[ind].buildingBlur === true && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                              }}
                            ></div>
                          )}
                          <div
                            className={`${item.buildingCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}
                            onClick={() => {
                              const allClusterTemp = [...allCluster]
                              allClusterTemp[ind].clusterType = 'building'
                              allClusterTemp[ind].unitCircle = false
                              allClusterTemp[ind].buildingCircle = true
                              allClusterTemp[ind].mixedCircle = false
                              allClusterTemp[ind].selected = true
                              setAllCluster(allClusterTemp)
                            }}
                          >
                            <img
                              src={item.buildingCircle ? buildingClusterWhiteIcon : buildingClusterGreenIcon}
                              height={80}
                              width={80}
                              className='d-block'
                            />
                            <h2 className={`head-text mt-3 ${item.buildingCircle ? 'headTextWhite' : ''}`}>
                              <b>Building Clusters</b>
                            </h2>
                          </div>
                          <p className='m-0 fs-5 fw-bold  mb-1 mt-5 text-center' style={{color: '#7f7f7f', width: '160px'}}>
                            Multiple buildings located in close proximity to each other within the community
                          </p>
                        </div>
                        <div className='col-4' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
                          {allCluster[ind].mixedBlur === true && (
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                backgroundColor: 'rgba(255,255,255,0.5)',
                              }}
                            ></div>
                          )}
                          <div
                            className={`${item.mixedCircle ? 'requestBoxColor box-circle' : 'requestBox box-circle'} mt-3`}
                            onClick={() => {
                              const allClusterTemp = [...allCluster]
                              allClusterTemp[ind].clusterType = 'mixed'
                              allClusterTemp[ind].unitCircle = false
                              allClusterTemp[ind].buildingCircle = false
                              allClusterTemp[ind].mixedCircle = true
                              allClusterTemp[ind].selected = true
                              setAllCluster(allClusterTemp)
                            }}
                          >
                            <img src={item.mixedCircle ? mixedClusterWhiteIcon : mixedClusterGreenIcon} height={80} width={80} className='d-block' />
                            <h2 className={`head-text mt-3 ${item.mixedCircle ? 'headTextWhite' : ''}`}>
                              <b>Mixed Clusters</b>
                            </h2>
                          </div>

                          <p className='m-0 fs-5 fw-bold mb-1 mt-5 text-center' style={{color: '#7f7f7f', width: '160px'}}>
                            Consists of both individual buildings and unit groups (villas/ townhouses) located in close proximity to each other within
                            the community
                          </p>
                        </div>
                      </div>

                      {allCluster[ind].unitCircle && (
                        <AddUnitCluster
                          allCluster={allCluster}
                          setCluster={setAllCluster}
                          index={ind}
                          handleAddUnitInput={handleAddUnitInput}
                          handleRemoveUnitInput={handleRemoveUnitInput}
                        />
                      )}

                      {allCluster[ind].buildingCircle && (
                        <AddBuildingCluster
                          allCluster={allCluster}
                          index={ind}
                          setCluster={setAllCluster}
                          floorOptions={floorOptions}
                          addFloorHandler={addFloorHandler}
                          handleAddBuildingCluster={handleAddBuildingCluster}
                          handleAddBuildingForBuildingCluster={handleAddBuildingForBuildingCluster}
                          handleRemoveBuildingCluster={handleRemoveBuildingCluster}
                          handleRemoveBuildingClusterBuilding={handleRemoveBuildingClusterBuilding}
                        />
                      )}

                      {allCluster[ind].mixedCircle && (
                        <div className='row' style={{marginTop: '50px'}}>
                          <div className='col-10 mx-auto text-center mb-2'>
                            <h2 className='m-0 head-text'>
                              <b>Mixed Clusters</b>
                            </h2>
                          </div>
                          <div className='col-12 mx-auto text-center'>
                            <h4 className='m-0 head-text mb-1'>Set the unit clusters existing within your community</h4>
                            <h4 className='m-0 head-text mb-1'>
                              You will be able to add the properties under each unit cluster once they have been added.
                            </h4>

                            <div className='my-7 d-flex justify-content-center'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold px-2 green-submit-btn '
                                style={{width: 'fit-content'}}
                                onClick={() => handleAddMixedCluster(ind)}
                              >
                                <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Mixed Cluster
                              </button>
                            </div>

                            <div className='col-12 mx-auto' style={{marginTop: '20px'}}>
                              <div className='row d-flex' style={{rowGap: '10px'}}>
                                {allCluster[ind].addMixedCluster?.map((clusterItem: any, mixedClusterIndex: any) => (
                                  <div className='col-12'>
                                    <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '50px'}}>
                                      {allCluster[ind].addMixedCluster?.length >= 1 && (
                                        <div style={{position: 'absolute', top: '2px', right: '12px'}}>
                                          <img
                                            style={{cursor: 'pointer'}}
                                            src={redCross}
                                            height={14}
                                            width={14}
                                            onClick={() => handleRemoveMixedCluster(ind, mixedClusterIndex)}
                                          />
                                        </div>
                                      )}
                                      <div className='row'>
                                        <div className='text-center col-3 mx-auto'>
                                          <h3 className='m-0 head-text mb-1 ms-1'>Mixed Cluster</h3>
                                          <div className='wxyz'>
                                            <Paper component='form' style={searchContainerStyle}>
                                              <InputBase
                                                placeholder='Enter Mixed Cluster Name'
                                                style={inputBaseStyle}
                                                inputProps={{'aria-label': 'Mixed Cluster'}}
                                                value={clusterItem?.name}
                                                onChange={(e: any) => {
                                                  const values = [...allCluster]
                                                  const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                  values[ind].addMixedCluster[mixedClusterIndex].name = captilizeWord
                                                  setAllCluster(values)
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row'>
                                        <div className='text-center my-7 d-flex justify-content-center align-items-center'>
                                          <div className='me-6'>
                                            <div className='d-flex justify-content-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                style={{width: 'fit-content'}}
                                                onClick={() => handleAddUnitGroup(ind, mixedClusterIndex)}
                                              >
                                                <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Unit Group
                                              </button>
                                            </div>
                                            <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                              Add a unit group under this cluster
                                            </p>
                                          </div>
                                          <div className='me-6'>
                                            <div className='d-flex justify-content-center'>
                                              <button
                                                type='button'
                                                className='btn btn-sm fw-bold px-2 green-submit-btn '
                                                style={{width: 'fit-content'}}
                                                onClick={() => handleAddBuildingForMixedCluster(ind, mixedClusterIndex)}
                                              >
                                                <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Building
                                              </button>
                                            </div>
                                            <p className='m-0 fs-5 fw-bold mt-3 mb-1' style={{color: '#7f7f7f'}}>
                                              Add a building under this cluster
                                            </p>
                                          </div>
                                        </div>

                                        <div className='col-12'>
                                          {allCluster[ind]?.addMixedCluster[mixedClusterIndex].addUnitGroup?.map(
                                            (groupItem: any, groupIndex: any) => (
                                              <div className='card card-flush' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                                                {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addUnitGroup?.length >= 1 && (
                                                  <div style={{position: 'absolute', top: '4px', right: '12px', cursor: 'pointer'}}>
                                                    <img
                                                      src={redCross}
                                                      height={14}
                                                      width={14}
                                                      onClick={() => handleRemoveUnitGroupForMixedCluster(ind, mixedClusterIndex, groupIndex)}
                                                    />
                                                  </div>
                                                )}
                                                <div className='row'>
                                                  <div className='col-12'>
                                                    <div className='me-4'>
                                                      <h3 className='m-0 head-text mb-1 ms-1 text-start'>Unit Group</h3>
                                                      <div className='wxyz' style={{width: '200px'}}>
                                                        <Paper component='form' style={searchContainerStyle}>
                                                          <InputBase
                                                            placeholder='Enter Unit Group Name'
                                                            style={inputBaseStyle}
                                                            inputProps={{'aria-label': 'Unit Group'}}
                                                            value={groupItem?.name}
                                                            onChange={(e: any) => {
                                                              const values = [...allCluster]
                                                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                              values[ind].addMixedCluster[mixedClusterIndex].addUnitGroup[groupIndex].name =
                                                                captilizeWord
                                                              setAllCluster(values)
                                                            }}
                                                          />
                                                        </Paper>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div className='col-12'>
                                          {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.map(
                                            (buildingItem: any, buildingIndex: any) => (
                                              <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                                                <div className='row'>
                                                  <div className='col-12  d-flex align-items-start'>
                                                    <div className='me-4' style={{width: '200px'}}>
                                                      <h3 className='m-0 head-text mb-1 ms-1 text-start'>Building</h3>
                                                      <div className='wxyz'>
                                                        <Paper component='form' style={searchContainerStyle}>
                                                          <InputBase
                                                            placeholder='Enter Building Name'
                                                            style={inputBaseStyle}
                                                            inputProps={{'aria-label': 'Building name'}}
                                                            value={buildingItem?.name}
                                                            onChange={(e: any) => {
                                                              const values = [...allCluster]
                                                              const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex].name =
                                                                captilizeWord
                                                              setAllCluster(values)
                                                            }}
                                                          />
                                                        </Paper>
                                                      </div>
                                                    </div>
                                                    <div className='text-center'>
                                                      <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                                                      <h3 className='m-0 head-text mt-3 mb-1 ms-2'>
                                                        {`${calculateTotalFloors(ind, mixedClusterIndex, buildingIndex)}`}
                                                      </h3>
                                                    </div>
                                                    {allCluster[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding?.length >= 1 && (
                                                      <div className='d-flex justify-content-end flex-grow-1'>
                                                        <img
                                                          style={{cursor: 'pointer'}}
                                                          src={redCross}
                                                          height={14}
                                                          width={14}
                                                          onClick={() => handleRemoveBuilingForMixedCluster(ind, mixedClusterIndex, buildingIndex)}
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>

                                                {/* add floor */}
                                                <div className='row my-7'>
                                                  <div className='col-12'>
                                                    <div className='d-flex align-items-center'>
                                                      <button
                                                        type='button'
                                                        className='btn btn-sm fw-bold create-st d-flex justify-content-center me-4 px-2'
                                                        onClick={() => addFloorHandlerForMixedCluster(ind, mixedClusterIndex, buildingIndex)}
                                                        style={{width: '120px'}}
                                                      >
                                                        Add Floor
                                                      </button>

                                                      <p className='m-0 head-text text-start'>
                                                      Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className='row'>
                                                  <div className='col-12'>
                                                    <table className='table table-borderless'>
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            className='head-text text-start p-0 pb-2'
                                                            style={{minWidth: '20px', maxWidth: '20px'}}
                                                          ></th>
                                                          <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                                                            Floors
                                                            <span className='ms-2'>
                                                              <OverlayTrigger
                                                                placement='right'
                                                                delay={{show: 250, hide: 200}}
                                                                overlay={
                                                                  <Tooltip id='button-tooltip-2'>
                                                                    {' '}
                                                                    <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included
                                                                    at the beginning of each floor added, giving each floor it’s own name. If you do
                                                                    not wish to include a prefix, you may leave this field blank. You will be able to
                                                                    edit any floor name once it has been added.
                                                                    <br></br>
                                                                    <b>Floor Range</b>: Set the range of floors within the selected floor type. The
                                                                    floor numbers will start and end based on the values included in the ‘Start Range’
                                                                    and ‘End Range. This will allow you to reflect the same floor names within your
                                                                    building. If you are adding a single floor your ‘Start Range’ and ‘ End Range’
                                                                    should include the same value.
                                                                    <br></br>
                                                                    <b>Total Floors</b>: Calculates the total number of floors set in the floor range.
                                                                    Both ‘Start Range’ and ‘End Range’ will be included in the total count.
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                <span>
                                                                  {' '}
                                                                  <AiOutlineExclamationCircle
                                                                    color='#146C6A '
                                                                    fontSize={14}
                                                                    fontWeight={500}
                                                                    className='cursor-pointer'
                                                                    style={{cursor: 'pointer', marginBottom: '2px'}}
                                                                  />
                                                                </span>
                                                              </OverlayTrigger>
                                                            </span>
                                                          </th>
                                                          <th
                                                            className='head-text text-start p-0  ps-7 pb-2'
                                                            style={{minWidth: '100px', maxWidth: '100px'}}
                                                          >
                                                            Floor Name
                                                          </th>
                                                          <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                                                            Floor Range
                                                          </th>
                                                          <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                                                            Example
                                                          </th>
                                                          <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                                                            Total Floors
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className='text-start'>
                                                        {allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]?.addFloor?.map(
                                                          (floorItem: any, floorIndex: any) => {
                                                            const isDuplicate = duplicateIndicesForMixedCluster.includes(floorIndex)
                                                            return (
                                                              <tr
                                                                key={floorIndex}
                                                                className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}
                                                              >
                                                                <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                                                                  <span
                                                                    className={`${
                                                                      allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1
                                                                        ? 'me-3'
                                                                        : ''
                                                                    }`}
                                                                  >
                                                                    {floorIndex === 0 &&
                                                                      allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1 && (
                                                                        <img
                                                                          onClick={() =>
                                                                            swapRowForAdd(
                                                                              floorIndex,
                                                                              floorIndex + 1,
                                                                              ind,
                                                                              mixedClusterIndex,
                                                                              buildingIndex,
                                                                              floorIndex
                                                                            )
                                                                          }
                                                                          style={{cursor: 'pointer'}}
                                                                          src={carrotArrowDown}
                                                                          height={12}
                                                                          width={12}
                                                                        />
                                                                      )}
                                                                    {floorIndex ===
                                                                      allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                        ?.addFloor?.length -
                                                                        1 &&
                                                                      allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                        ?.addFloor?.length > 1 && (
                                                                        <img
                                                                          onClick={() =>
                                                                            swapRowForAdd(
                                                                              floorIndex - 1,
                                                                              floorIndex,
                                                                              ind,
                                                                              mixedClusterIndex,
                                                                              buildingIndex,
                                                                              floorIndex
                                                                            )
                                                                          }
                                                                          style={{cursor: 'pointer'}}
                                                                          src={carrotArrowUp}
                                                                          height={12}
                                                                          width={12}
                                                                        />
                                                                      )}
                                                                    {floorIndex !== 0 &&
                                                                      floorIndex !==
                                                                        allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                          ?.addFloor?.length -
                                                                          1 && (
                                                                        <span className='d-flex flex-column'>
                                                                          <img
                                                                            style={{cursor: 'pointer'}}
                                                                            src={carrotArrowUp}
                                                                            height={12}
                                                                            width={12}
                                                                            onClick={() =>
                                                                              swapRowForAdd(
                                                                                floorIndex,
                                                                                floorIndex - 1,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                          />
                                                                          <img
                                                                            style={{cursor: 'pointer'}}
                                                                            src={carrotArrowDown}
                                                                            height={12}
                                                                            width={12}
                                                                            onClick={() =>
                                                                              swapRowForAdd(
                                                                                floorIndex,
                                                                                floorIndex + 1,
                                                                                ind,
                                                                                mixedClusterIndex,
                                                                                buildingIndex,
                                                                                floorIndex
                                                                              )
                                                                            }
                                                                          />
                                                                        </span>
                                                                      )}
                                                                  </span>
                                                                </td>
                                                                <td className='p-0  py-3' style={{minWidth: '170px'}}>
                                                                  <div>
                                                                    <Select
                                                                      styles={{
                                                                        ...customStyles,
                                                                        container: (defaultStyles: any) => ({
                                                                          ...defaultStyles,
                                                                          width: '100%',
                                                                        }),
                                                                      }}
                                                                      isSearchable={false}
                                                                      options={floorOption(
                                                                        ind,
                                                                        mixedClusterIndex,
                                                                        buildingIndex,
                                                                        floorIndex,
                                                                        'mixed'
                                                                      )}
                                                                      name='floor'
                                                                      value={floorItem?.floorType}
                                                                      onChange={(e: any) => {
                                                                        const values = [...allCluster]
                                                                        values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                          buildingIndex
                                                                        ].addFloor[floorIndex].floorType = e

                                                                        if (e.value === 0) {
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].startRangeError = false
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].startRangeError = false
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].floorStartRange = 1
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].floorEndRange = 1
                                                                        }

                                                                        const updatedFloors = checkForDuplicatesForMixedCluster(
                                                                          values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[buildingIndex]
                                                                            ?.addFloor
                                                                        )
                                                                        values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                          buildingIndex
                                                                        ].addFloor = updatedFloors
                                                                        setAllCluster(values)
                                                                      }}
                                                                      placeholder={'Select Floor Type'}
                                                                    />
                                                                  </div>
                                                                  {floorItem.isDuplicate && (
                                                                    <p className='pb-0 mb-0' style={{color: 'red'}}>
                                                                      Floor Name is duplicated
                                                                    </p>
                                                                  )}
                                                                </td>
                                                                <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                                                  <div className='wxyz'>
                                                                    <Paper component='form' style={searchContainerStyle}>
                                                                      <InputBase
                                                                        placeholder='Prefix'
                                                                        style={inputBaseStyle}
                                                                        inputProps={{'aria-label': 'Prefix'}}
                                                                        value={floorItem?.name}
                                                                        onChange={(e: any) => {
                                                                          const values = [...allCluster]
                                                                          const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor[floorIndex].name = captilizeWord

                                                                          const updatedFloors = checkForDuplicatesForMixedCluster(
                                                                            values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                              buildingIndex
                                                                            ]?.addFloor
                                                                          )
                                                                          values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                            buildingIndex
                                                                          ].addFloor = updatedFloors
                                                                          setAllCluster(values)
                                                                        }}
                                                                      />
                                                                    </Paper>
                                                                  </div>
                                                                </td>

                                                                <td className='p-0 min-w-100px ps-7 py-3' style={{maxWidth: '220px'}}>
                                                                  <div className='d-flex ' style={{columnGap: '10px'}}>
                                                                    <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                      <Paper component='form' style={searchContainerStyle}>
                                                                        <InputBase
                                                                          disabled={floorItem?.floorType?.value === 0}
                                                                          type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                          placeholder='Start Range'
                                                                          style={inputBaseStyle}
                                                                          inputProps={{'aria-label': 'Start Range',min:1}}
                                                                          value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                                          onChange={(e: any) => {
                                                                            if (e.target.value < 0) {
                                                                              return;
                                                                            }
                                                                            const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                            const values = [...allCluster]

                                                                            if (
                                                                              floorItem?.floorEndRange !== '' &&
                                                                              floorItem?.floorEndRange < Number(e.target.value) &&
                                                                              e.target.value != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = true
                                                                            } else {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = false
                                                                            }

                                                                            if (
                                                                              floorItem?.floorEndRange !== '' &&
                                                                              floorItem?.floorEndRange >= Number(e.target.value) &&
                                                                              e.target.value != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = false
                                                                            }

                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor[floorIndex].floorStartRange = newValue ? Number(newValue) : ''

                                                                            const updatedFloors = checkForDuplicatesForMixedCluster(
                                                                              values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                                buildingIndex
                                                                              ]?.addFloor
                                                                            )
                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor = updatedFloors
                                                                            setAllCluster(values)
                                                                          }}
                                                                        />
                                                                      </Paper>
                                                                      {floorItem?.startRangeError && (
                                                                        <p
                                                                          className='d-flex flex-row mt-1'
                                                                          style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                        >
                                                                          The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                    <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                                                      <Paper component='form' style={searchContainerStyle}>
                                                                        <InputBase
                                                                          disabled={floorItem?.floorType?.value === 0}
                                                                          type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                                          placeholder='End Range'
                                                                          style={inputBaseStyle}
                                                                          inputProps={{'aria-label': 'End Range',min:1}}
                                                                          value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                                          onChange={(e: any) => {
                                                                            if (e.target.value < 0) {
                                                                              return;
                                                                            }
                                                                            const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                                            const values = [...allCluster]

                                                                            if (
                                                                              e.target.value != '' &&
                                                                              Number(e.target.value) < floorItem.floorStartRange &&
                                                                              floorItem.floorStartRange != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = true
                                                                            } else {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].endRangeError = false
                                                                            }

                                                                            if (
                                                                              e.target.value !== '' &&
                                                                              Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                                              floorItem.floorStartRange != ''
                                                                            ) {
                                                                              values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                                buildingIndex
                                                                              ].addFloor[floorIndex].startRangeError = false
                                                                            }

                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor[floorIndex].floorEndRange = newValue ? Number(newValue) : ''
                                                                            const updatedFloors = checkForDuplicatesForMixedCluster(
                                                                              values[ind]?.addMixedCluster[mixedClusterIndex]?.addBuilding[
                                                                                buildingIndex
                                                                              ]?.addFloor
                                                                            )
                                                                            values[ind].addMixedCluster[mixedClusterIndex].addBuilding[
                                                                              buildingIndex
                                                                            ].addFloor = updatedFloors
                                                                            setAllCluster(values)
                                                                          }}
                                                                        />
                                                                      </Paper>
                                                                      {floorItem?.endRangeError && (
                                                                        <p
                                                                          className='d-flex flex-row mt-1'
                                                                          style={{fontSize: '10px', textAlign: 'left', color: 'red'}}
                                                                        >
                                                                          The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  className='p-0 ps-5 text-center py-3'
                                                                  style={{minWidth: '50px', maxWidth: '50px'}}
                                                                >
                                                                  <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                    <div className='mt-2'>
                                                                      {floorItem?.floorType?.value != 0 ? (
                                                                        <p className='m-0 head-text '>
                                                                          <i>
                                                                            {' '}
                                                                            {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                                          </i>
                                                                        </p>
                                                                      ) : (
                                                                        <p className='m-0 head-text '>
                                                                          <i> {floorItem?.name}</i>
                                                                        </p>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </td>
                                                                <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                                                  <div className='text-center  d-flex justify-content-center align-items-center'>
                                                                    <div className=' mt-1'>
                                                                      <h3 className='m-0 head-text '>
                                                                        {/* {`${(floorItem?.floorEndRange - floorItem?.floorStartRange) + 1}`} */}
                                                                        {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                                                      </h3>
                                                                    </div>
                                                                    {allCluster[ind].addMixedCluster[mixedClusterIndex].addBuilding[buildingIndex]
                                                                      .addFloor?.length > 1 && (
                                                                      <img
                                                                        className='ms-7'
                                                                        style={{cursor: 'pointer'}}
                                                                        src={redCross}
                                                                        height={14}
                                                                        width={14}
                                                                        onClick={() =>
                                                                          handleRemoveFloorForMixedCluster(
                                                                            ind,
                                                                            mixedClusterIndex,
                                                                            buildingIndex,
                                                                            floorIndex
                                                                          )
                                                                        }
                                                                      />
                                                                    )}
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            )
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}

              {selectBuildingCircle && (
                <div className='row' style={{marginTop: '50px'}}>
                  <div className='col-10 mx-auto text-center mb-2'>
                    <h2 className='m-0 head-text'>
                      <b>Set Building Floors</b>
                    </h2>
                  </div>

                  <div className='col-12 mx-auto text-center'>
                    <h4 className='m-0 head-text mb-1'>Set the number and sorting of floor within your building</h4>
                  </div>

                  <div className='col-11 mt-5 mx-auto'>
                    <div className='card card-flush ' style={{padding: '10px', position: 'relative', marginBottom: '20px'}}>
                      {/* add floor */}
                      <div className='row my-7'>
                        <div className='col-12 mx-auto'>
                          <div className='text-center'>
                            <h3 className='m-0 head-text mb-1 ms-2'>Total Floors</h3>

                            <h3 className='m-0 head-text mt-3 mb-1 ms-2'>{totalBuildingFloors}</h3>
                          </div>
                          <div className='d-flex flex-column align-items-center my-7'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold create-st d-flex justify-content-center px-2 me-4'
                              style={{width:'120px'}}
                              onClick={addFloorHandlerForBuildingDevelopment}
                            >
                              Add Floor
                            </button>

                            <p className='m-0 head-text text-center mt-5 px-4'>
                            Position each floor in the order you would like them to be sorted in your property tables. Once added, the tables will be sorted based on the order of each floor set below along with the properties added under each floor.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className='row ps-4'>
                        <div className='col-12'>
                          <table className='table table-borderless'>
                            <thead>
                              <tr>
                                <th className='head-text text-start p-0 pb-2' style={{minWidth: '20px', maxWidth: '20px'}}></th>
                                <th className='head-text text-start p-0  pb-2' style={{minWidth: '170px'}}>
                                  Floors
                                  <span className='ms-2'>
                                    <OverlayTrigger
                                      placement='right'
                                      delay={{show: 250, hide: 200}}
                                      overlay={
                                        <Tooltip id='button-tooltip-2'>
                                          {' '}
                                          <b>Prefix</b>: Add a prefix (word, letter, number or symbol) that will be included at the beginning of each
                                          floor added, giving each floor it’s own name. If you do not wish to include a prefix, you may leave this
                                          field blank. You will be able to edit any floor name once it has been added.
                                          <br></br>
                                          <b>Floor Range</b>: Set the range of floors within the selected floor type. The floor numbers will start and
                                          end based on the values included in the ‘Start Range’ and ‘End Range. This will allow you to reflect the
                                          same floor names within your building. If you are adding a single floor your ‘Start Range’ and ‘ End Range’
                                          should include the same value.
                                          <br></br>
                                          <b>Total Floors</b>: Calculates the total number of floors set in the floor range. Both ‘Start Range’ and
                                          ‘End Range’ will be included in the total count.
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {' '}
                                        <AiOutlineExclamationCircle
                                          color='#146C6A '
                                          fontSize={14}
                                          fontWeight={500}
                                          className='cursor-pointer'
                                          style={{cursor: 'pointer', marginBottom: '2px'}}
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </th>
                                <th className='head-text text-start p-0  ps-7 pb-2' style={{minWidth: '100px', maxWidth: '100px'}}>
                                  Floor Name
                                </th>
                                <th className='head-text text-start p-0  ps-5 pb-2' style={{minWidth: '220px'}}>
                                  Floor Range
                                </th>
                                <th className='head-text text-center p-0  ps-5 pb-2' style={{minWidth: '50px'}}>
                                  Example
                                </th>
                                <th className='head-text text-center p-0 ps-5 pb-2 ' style={{minWidth: '100px'}}>
                                  Total Floors
                                </th>
                              </tr>
                            </thead>
                            <tbody className='text-start'>
                              {buildingDevelopmentFloor.map((floorItem: any, floorIndex: any) => {
                                return (
                                  <>
                                    <tr key={floorIndex} className={`${floorItem.animationClass} ${floorItem.isDuplicate ? 'redBox' : ''}`}>
                                      <td className='py-3 px-1' style={{minWidth: '20px', maxWidth: '20px'}}>
                                        <span className={`${floorItem.floorList?.length > 1 ? 'me-3' : ''}`}>
                                          {floorIndex === 0 && buildingDevelopmentFloor?.length > 1 && (
                                            <img
                                              onClick={() => swapRowForAddBuilding(floorIndex, floorIndex + 1, floorIndex)}
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowDown}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                          {floorIndex === buildingDevelopmentFloor?.length - 1 && buildingDevelopmentFloor?.length > 1 && (
                                            <img
                                              onClick={() => swapRowForAddBuilding(floorIndex - 1, floorIndex, floorIndex)}
                                              style={{cursor: 'pointer'}}
                                              src={carrotArrowUp}
                                              height={12}
                                              width={12}
                                            />
                                          )}
                                          {floorIndex !== 0 && floorIndex !== buildingDevelopmentFloor.length - 1 && (
                                            <span className='d-flex flex-column'>
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowUp}
                                                height={12}
                                                width={12}
                                                onClick={() => swapRowForAddBuilding(floorIndex - 1, floorIndex, floorIndex)}
                                              />
                                              <img
                                                style={{cursor: 'pointer'}}
                                                src={carrotArrowDown}
                                                height={12}
                                                width={12}
                                                onClick={() => swapRowForAddBuilding(floorIndex, floorIndex + 1, floorIndex)}
                                              />
                                            </span>
                                          )}
                                        </span>
                                      </td>
                                      <td className='p-0  py-3' style={{minWidth: '170px'}}>
                                        <div>
                                          <Select
                                            styles={{
                                              ...customStyles,
                                              container: (defaultStyles: any) => ({
                                                ...defaultStyles,
                                                width: '100%',
                                              }),
                                            }}
                                            isSearchable={true}
                                            value={floorItem.floorType}
                                            options={floorOptionForAddBuilding(floorIndex, 'building')}
                                            name='floor2'
                                            onChange={(e: any) => {
                                              const values = [...buildingDevelopmentFloor]
                                              values[floorIndex].floorType = e

                                              if (e.value === 0) {
                                                values[floorIndex].startRangeError = false
                                                values[floorIndex].endRangeError = false
                                                values[floorIndex].floorStartRange = 1
                                                values[floorIndex].floorEndRange = 1
                                              }

                                              setBuildingDevelopmentFloor(values)

                                              const updatedFloors = checkForDuplicates([...buildingDevelopmentFloor])
                                              setBuildingDevelopmentFloor(updatedFloors)
                                            }}
                                            placeholder={'Select Floor Type'}
                                          />
                                        </div>
                                        {floorItem.isDuplicate && (
                                          <p className='pb-0 mb-0' style={{color: 'red'}}>
                                            Floor Name is duplicated
                                          </p>
                                        )}
                                      </td>
                                      <td className='p-0 py-3 ps-7' style={{minWidth: '100px', maxWidth: '100px'}}>
                                        <div className='wxyz'>
                                          <Paper component='form' style={searchContainerStyle}>
                                            <InputBase
                                              placeholder='Prefix'
                                              style={inputBaseStyle}
                                              inputProps={{'aria-label': 'Prefix'}}
                                              value={floorItem?.name}
                                              onChange={(e: any) => {
                                                const values = [...buildingDevelopmentFloor]

                                                const captilizeWord = capitalizeFirstLetter(e.target.value?.trimStart())

                                                values[floorIndex].name = captilizeWord
                                                setBuildingDevelopmentFloor(values)

                                                const updatedFloors = checkForDuplicates([...buildingDevelopmentFloor])
                                                setBuildingDevelopmentFloor(updatedFloors)
                                              }}
                                            />
                                          </Paper>
                                        </div>
                                      </td>
                                      <td className='p-0 min-w-100px ps-7 py-3 ' style={{maxWidth: '220px'}}>
                                        <div className='d-flex ' style={{columnGap: '10px'}}>
                                          <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                            <Paper component='form' style={searchContainerStyle}>
                                              <InputBase
                                                disabled={floorItem?.floorType?.value === 0}
                                                type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                placeholder='Start Range'
                                                style={inputBaseStyle}
                                                inputProps={{'aria-label': 'Start Range',min: 1}}
                                                value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorStartRange}
                                                onChange={(e: any) => {
                                                  if (e.target.value < 0) {
                                                    return;
                                                  }
                                                  const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                  const values = [...buildingDevelopmentFloor]

                                                  // if (values[floorIndex].floorEndRange !== '' && values[floorIndex].floorEndRange < Number(e.target.value) && e.target.value != '') {
                                                  //     ErrorToast("Start Range should not be GREATER than End Range.")
                                                  // }
                                                  // else{
                                                  //     values[floorIndex].floorStartRange = newValue ? Number(newValue) : '';
                                                  // }

                                                  if (
                                                    floorItem?.floorEndRange !== '' &&
                                                    floorItem?.floorEndRange < Number(e.target.value) &&
                                                    e.target.value != ''
                                                  ) {
                                                    values[floorIndex].startRangeError = true
                                                  } else {
                                                    values[floorIndex].startRangeError = false
                                                  }

                                                  if (
                                                    floorItem?.floorEndRange !== '' &&
                                                    floorItem?.floorEndRange >= Number(e.target.value) &&
                                                    e.target.value != ''
                                                  ) {
                                                    values[floorIndex].endRangeError = false
                                                  }

                                                  // if (floorItem?.floorEndRange != '' && Number(floorItem?.floorEndRange) < floorItem.floorStartRange && floorItem.floorStartRange != ''){
                                                  //     values[floorIndex].endRangeError = true
                                                  // }else{
                                                  //     values[floorIndex].endRangeError = false
                                                  // }

                                                  values[floorIndex].floorStartRange = newValue ? Number(newValue) : ''
                                                  setBuildingDevelopmentFloor(values)

                                                  const updatedFloors = checkForDuplicates([...buildingDevelopmentFloor])
                                                  setBuildingDevelopmentFloor(updatedFloors)
                                                }}
                                              />
                                            </Paper>

                                            {floorItem?.startRangeError && (
                                              <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                The floor 'Start Range' should be equal to or less than the floor 'End Range'.
                                              </p>
                                            )}
                                          </div>
                                          <div className='wxyz' style={{minWidth: '100px', maxWidth: '110px'}}>
                                            <Paper component='form' style={{...searchContainerStyle}}>
                                              <InputBase
                                                disabled={floorItem?.floorType?.value === 0}
                                                type={floorItem?.floorType?.value === 0 ? 'text' : 'number'}
                                                placeholder='End Range'
                                                style={inputBaseStyle}
                                                // inputProps={{'aria-label': 'End Range'}}
                                                inputProps={{ 'aria-label': 'End Range', min: 1 }}
                                                value={floorItem?.floorType?.value === 0 ? '-' : floorItem?.floorEndRange}
                                                onChange={(e: any) => {
                                                  console.log(e.target.value)
                                                  if (e.target.value < 0) {
                                                    return;
                                                  }
                                                  const newValue = e.target.value.replace(/^0+(?=\d)/, '')
                                                  const values = [...buildingDevelopmentFloor]

                                                  if (
                                                    e.target.value != '' &&
                                                    Number(e.target.value) < floorItem.floorStartRange &&
                                                    floorItem.floorStartRange != ''
                                                  ) {
                                                    values[floorIndex].endRangeError = true
                                                  } else {
                                                    values[floorIndex].endRangeError = false
                                                  }

                                                  if (
                                                    e.target.value !== '' &&
                                                    Number(e.target.value) >= Number(floorItem.floorStartRange) &&
                                                    floorItem.floorStartRange != ''
                                                  ) {
                                                    values[floorIndex].startRangeError = false
                                                  }

                                                  values[floorIndex].floorEndRange = newValue ? Number(newValue) : ''
                                                  setBuildingDevelopmentFloor(values)

                                                  const updatedFloors = checkForDuplicates([...buildingDevelopmentFloor])
                                                  setBuildingDevelopmentFloor(updatedFloors)
                                                }}
                                              />
                                            </Paper>

                                            {floorItem?.endRangeError && (
                                              <p className='d-flex flex-row mt-1' style={{fontSize: '10px', textAlign: 'left', color: 'red'}}>
                                                The floor 'End Range' should be equal to or greater than the floor 'Start Range'.
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td className='p-0 ps-5 text-center py-3' style={{minWidth: '50px', maxWidth: '50px'}}>
                                        <div className='text-center  d-flex justify-content-center align-items-center'>
                                          <div className='mt-2'>
                                            {floorItem?.floorType?.value != 0 ? (
                                              <p className='m-0 head-text '>
                                                <i>
                                                  {' '}
                                                  {floorItem?.name} {floorItem?.floorStartRange == 0 ? 1 : floorItem?.floorStartRange}
                                                </i>
                                              </p>
                                            ) : (
                                              <p className='m-0 head-text '>
                                                <i> {floorItem?.name}</i>
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </td>

                                      <td className='p-0 ps-5 text-center py-3 min-w-100px' style={{maxWidth: '100px'}}>
                                        <div className='text-center  d-flex justify-content-center align-items-center'>
                                          <div className='mt-2'>
                                            <h3 className='m-0 head-text '>
                                              {`${
                                                floorItem?.floorStartRange > floorItem?.floorEndRange
                                                  ? '-': floorItem?.floorEndRange - floorItem?.floorStartRange + 1
                                              }`}
                                            </h3>
                                          </div>
                                          {buildingDevelopmentFloor?.length > 1 && (
                                            <img
                                              className='ms-7'
                                              style={{cursor: 'pointer'}}
                                              src={redCross}
                                              height={14}
                                              width={14}
                                              onClick={() => handleRemoveDevelopmenetBuilding(floorIndex)}
                                            />
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {[...allCluster].some((clusterItem: any) => {
                if (clusterItem.unitCircle === true || clusterItem.buildingCircle === true || clusterItem.mixedCircle === true) {
                  return true
                } else {
                  return false
                }
              }) &&
                selectCommunityCircle && (
                  <>
                    <div className='row' style={{marginTop: '50px'}}>
                      <div className='col-6 mx-auto'>
                        <button
                          disabled={allCluster?.length === 3}
                          type='button'
                          className='btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center '
                          onClick={handleAllCluster}
                        >
                          <img src={addWhiteIcon} height={18} width={18} className='me-2' />
                          Add Another Cluster
                        </button>
                      </div>
                    </div>
                  </>
                )}

              {(selectCommunityCircle || selectBuildingCircle) && (
                <div className='row' style={{marginTop: '50px'}}>
                  <div className='col-8 mx-auto'>
                    <button
                      disabled={isDevelopmentBtnDisabled}
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn  w-100 d-flex justify-content-center '
                      onClick={addDevelopment}
                    >
                      <img src={addWhiteIcon} height={18} width={18} className='me-2' />
                      Add Development
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPropertyDevelopment

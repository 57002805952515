import React, {useEffect, useState, useMemo, useCallback} from 'react'
import ApexCharts from 'react-apexcharts'
import CircularProgress from '@mui/material/CircularProgress'
import debounce from 'lodash.debounce'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'

interface vacancyRateProps {
  selectedUnits?: any
}

interface Obj {
  active: number
  booked: number
  expired: number
  expiring: number
}

interface DataObj {
  time: string
  activeLease: number
  bookedLease: number
  expiredLease: number
  expiringLease: number
  renewal: Obj
  terminated: Obj
}

const LeaseMonthWiseChart: React.FC<vacancyRateProps> = ({selectedUnits}) => {
  let [chartData, setChartData] = useState<any>([])
  const [seriesData, setSeriesData] = useState<any>({
    bookedLease: [] as number[],
    activeLease: [] as number[],
    expiredLease: [] as number[],
    expiringLease: [] as number[],
    renewalBooked: [] as number[],
    renewalActive: [] as number[],
    renewalExpired: [] as number[],
    renewalExpiring: [] as number[],
    terminatedBooked: [] as number[],
    terminatedActive: [] as number[],
    terminatedExpired: [] as number[],
    terminatedExpiring: [] as number[],
  })

  const [categories, setCategories] = useState<string[]>([])

  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const [visibleSeries, setVisibleSeries] = useState({
    booked: true,
    active: true,
    expiring: true,
    expired: true,
    'renewed 1': true,
    'renewed 2': true,
    'renewed 3': true,
    'renewed 4': true,
    'terminated 1': true,
    'terminated 2': true,
    'terminated 3': true,
    'terminated 4': true,
  })

  const seriesColors: any = {
    Booked: '#0070C0',
    Active: '#0D9E91',
    Expiring: '#F38513',
    Expired: '#D54645',
    'Renewed 1': 'transparent',
    'Renewed 2': 'transparent',
    'Renewed 3': 'transparent',
    'Renewed 4': 'transparent',
    'Terminated 1': 'transparent',
    'Terminated 2': 'transparent',
    'Terminated 3': 'transparent',
    'Terminated 4': 'transparent',
  }

  const handleCheckboxChange = useCallback(
    debounce((seriesName: keyof typeof visibleSeries) => {
      setIsSkeleton(true)
      setVisibleSeries((prevState) => ({
        ...prevState,
        [seriesName]: !prevState[seriesName],
      }))

      setTimeout(() => {
        setIsSkeleton(false)
      }, 300)
    }, 300),
    []
  )

  const getTenancyChartData = () => {
    const body = {
      year: 2024,
      unitIds: selectedUnits,
    }
    ApiPost(`corporate/dashboard/chart/monthly`, body)
      .then((res: any) => {
        setChartData(res?.data?.data?.tenancyChartData || [])

        let values: any = {
          bookedLease: [],
          activeLease: [],
          expiredLease: [],
          expiringLease: [],
          renewalBooked: [],
          renewalActive: [],
          renewalExpired: [],
          renewalExpiring: [],
          terminatedBooked: [],
          terminatedActive: [],
          terminatedExpired: [],
          terminatedExpiring: [],
        }

        res?.data?.data?.tenancyChartData?.forEach((dataPoint: any) => {
          values?.bookedLease?.push(dataPoint?.bookedLease)
          values?.activeLease?.push(dataPoint?.activeLease)
          values?.expiredLease?.push(dataPoint?.expiredLease)
          values?.expiringLease?.push(dataPoint?.expiringLease)

          values?.renewalBooked?.push(dataPoint?.renewal?.bookedLease)
          values?.renewalActive?.push(dataPoint?.renewal?.activeLease)
          values?.renewalExpired?.push(dataPoint?.renewal?.expiredLease)
          values?.renewalExpiring?.push(dataPoint?.renewal?.expiringLease)

          values?.terminatedBooked?.push(dataPoint?.terminated?.bookedLease)
          values?.terminatedActive?.push(dataPoint?.terminated?.activeLease)
          values?.terminatedExpired?.push(dataPoint?.terminated?.expiredLease)
          values?.terminatedExpiring?.push(dataPoint?.terminated?.expiringLease)
        })

        setSeriesData(values)
        setCategories(
          res?.data?.data?.tenancyChartData?.map((dataPoint: any) => dataPoint?.time) || []
        )
        setIsSkeleton(false)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getTenancyChartData()
  }, [selectedUnits])

  const series = [
    {
      name: 'Booked',
      data: seriesData?.bookedLease,
      group: 'booked',
    },
    {
      name: 'Active',
      data: seriesData?.activeLease,
      group: 'active',
    },
    {
      name: 'Expiring',
      data: seriesData?.expiringLease,
      group: 'expiring',
    },
    {
      name: 'Expired',
      data: seriesData?.expiredLease,
      group: 'expired',
    },
    {
      name: 'Renewed 1',
      data: seriesData?.renewalBooked,
      group: 'booked',
    },
    {
      name: 'Renewed 2',
      data: seriesData?.renewalActive,
      group: 'active',
    },
    {
      name: 'Renewed 3',
      data: seriesData?.renewalExpiring,
      group: 'expiring',
    },
    {
      name: 'Renewed 4',
      data: seriesData?.renewalExpired,
      group: 'expired',
    },
    {
      name: 'Terminated 1',
      data: seriesData?.terminatedBooked,
      group: 'booked',
    },
    {
      name: 'Terminated 2',
      data: seriesData?.terminatedActive,
      group: 'active',
    },
    {
      name: 'Terminated 3',
      data: seriesData?.terminatedExpiring,
      group: 'expiring',
    },
    {
      name: 'Terminated 4',
      data: seriesData?.terminatedExpired,
      group: 'expired',
    },
  ].filter((s) => visibleSeries[s.name.toLowerCase() as keyof typeof visibleSeries])

  const getColorForSeries = (seriesName: string) => {
    return seriesColors?.[seriesName] || '#000000'
  }

  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        selection: {
          enabled: true,
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      title: {
        text: 'Leases Month Wise',
        align: 'left',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            hideOverflowingLabels: true,
          },
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      dataLabels: {
        enabled: false,
        formatter: (val: number) => val.toString(),
        style: {
          fontSize: '10px',
        },
        textAnchor: 'middle',
        offsetY: 1,
        distributed: true,
      },
      xaxis: {
        categories: categories,
        type: 'datetime',
        labels: {
          format: 'MMM-yy',
        },
      },
      fill: {
        opacity: 1,
      },
      colors: series?.map((s) => getColorForSeries(s.name)),
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          formatter: (val: any) => val.toString(),
        },
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          height: 0,
          width: 0,
          radius: 0,
          onClick: () => {},
        },
        formatter: (seriesName, opts) => {
          const seriesIndex = opts.seriesIndex
          const color = seriesColors?.[seriesName]
          return seriesIndex < 4
            ? `<div class="myClass" style="display:flex;align-items:center;position:relative;top:-25px">
                <span style="font-size:25px;color:${seriesColors?.[seriesName]}">&#9679;</span>
              <span style="margin-top:4px"> ${seriesName}</span>
            </div>`
            : ''
        },
      },
      grid: {
        show: false,
      },
      tooltip: {

        
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          console.log(w.config.series[seriesIndex].group)
          const date = new Date(categories?.[dataPointIndex])?.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
          });
          let tooltipContent = `<div class="apexcharts-tooltip-custom">`;
          
          tooltipContent += `<div style="text-align:center;" class="text-decoration-underline">${date}</div>`;
          tooltipContent += `<div style="display: flex; justify-content: space-between;">`;
          tooltipContent += `<div>`;

          const hoveredCategory = categories?.[dataPointIndex];
          const groupSeries = w.config.series.filter((s:any) => s.group.split(' ')[0].toLowerCase() === w.config.series[seriesIndex].group);

          groupSeries.forEach((s:any) => {
            console.log(s)
            tooltipContent += `<div style="color:${seriesColors[s.group.split(' ')[0].toLowerCase()]}"><span style="color: ${s.name.split(' ')[0] == 'Renewed' ? '#00B8A9' : s.name.split(' ')[0] == 'Terminated' ? '#E46464' :seriesColors[s.name]}">&#9679;</span> ${s.name.split(' ')[0]} : ${s.data[dataPointIndex] ? s.data[dataPointIndex] : 0}</div>`;
          });

          tooltipContent += `</div>`;
          tooltipContent += `</div>`;
          tooltipContent += `</div>`;
          return tooltipContent;
        },
      
      
        // custom: ({series, seriesIndex, dataPointIndex, w}) => {
        //   const date = new Date(categories?.[dataPointIndex])?.toLocaleDateString('en-US', {
        //     year: 'numeric',
        //     month: 'long',
        //   })
        //   return (
        //     `<div class="apexcharts-tooltip-custom">` +
        //     `<div style="text-align:center;">${date}</div>` +
        //     `<div style="display: flex; justify-content: space-between;">` +
        //     `<div>` +
        //     `<div><span style="color:${seriesColors?.[series[seriesIndex].name]}">&#9679;</span> ${
        //       w.config.series[seriesIndex].name
        //     }: ${series[seriesIndex][dataPointIndex]}</div>` +
        //     `</div>` +
        //     `</div>` +
        //     `</div>`
        //   )
        // },
      },
    }),
    [categories, series, seriesColors]
  )

  const styles = `
    .apexcharts-chart {
      overflow-x: scroll;
    }
    .apexcharts-tooltip-custom {
      padding: 5px 10px;
      background: #fff;
      border: 1px solid #ccc;
      min-width: 120px
    }
    .apexcharts-tooltip-custom div {
      margin: 1px 3px 1px 1px;
    }
    .apexcharts-tooltip-custom span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  `

  const styleSheet = document?.createElement('style')
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)

  return (
    <div>
      <div id='chart'>
        {seriesData && series && (
          <div className='chart-container'>
            <div className='legend-container d-flex w-100'>
              <div className=' d-flex align-items ms-auto'>
                {Object?.keys(visibleSeries)?.map((seriesName, index) => {
                  // console.log("Series Name:", seriesName) // Add this line for debugging
                  // console.log("Color:", seriesColors[seriesName.charAt(0).toUpperCase() + seriesName.slice(1)]) // Add this line for debugging

                  return (
                    <span key={seriesName}>
                      {index < 4 && (
                        <label
                          key={seriesName}
                          style={{marginRight: '15px', display: 'flex', alignItems: 'center'}}
                        >
                          <input
                            type='checkbox'
                            className='form-check-input me-2'
                            checked={visibleSeries[seriesName as keyof typeof visibleSeries]}
                            onChange={() =>
                              handleCheckboxChange(seriesName as keyof typeof visibleSeries)
                            }
                          />
                          <span
                            style={{
                              width: '12px',
                              height: '12px',
                              borderRadius: '50%',
                              backgroundColor:
                                seriesColors[
                                  seriesName?.charAt(0)?.toUpperCase() + seriesName?.slice(1)
                                ],
                              marginRight: '5px',
                            }}
                          ></span>
                          {seriesName.charAt(0).toUpperCase() + seriesName.slice(1)}
                        </label>
                      )}
                    </span>
                  )
                })}
              </div>
            </div>
            {!isSkeleton ? (
              <ApexCharts options={options} series={series ? series : []} type='bar' height={350} />
            ) : (
              <div
                className='d-flex justify-content-center align-items-center w-100'
                style={{height: '350px'}}
              >
                <CircularProgress style={{color: '#007a59'}} />
              </div>
            )}
          </div>
        )}
      </div>

      <div id='html-dist'></div>
    </div>
  )
}

export default LeaseMonthWiseChart

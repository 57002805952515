import {TimePicker, DatePicker} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut, ApiUpload, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import Label from '../../../app/modules/comman/Label'
import closered from '../../../img/closered.svg'
import fileicon from '../../../img/file.svg'
import leftArrow from '../../../img/arrowleft.svg'
import buildings from '../../../img/buildings.svg'
import crossbtn from '../../../img/cross-button.png'
import Swal from 'sweetalert2'
import intReq from '../../../img/internal-request-icon-white.png'
import extReq from '../../../img/external-request-icon-white.png'
import MediaPopUp from './TenantMedia'
import LoadMoreMedia from './TenantMoreMedia'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import loadMore from '../../../img/discover.png'

let dateValue: any = ''

function CreateServiceRequest() {
  const {id} = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({})
  const [mainServiceData, setMainServiceData] = useState<any>()
  const [subServiceData, setSubServiceData] = useState<any>()
  const [maintanceData, setMaintanceData] = useState<any>()
  const [date, setDate] = useState<any>()
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [mediaUploaded, setMediaUploaded] = useState<any>([])
  const [mediaUploadedRes, setMediaUploadedRes] = useState<any>([])
  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()
  const [development, setDevelopment] = useState<any>('-')
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [mediaPop, setMediaPop] = useState<any>()
  const [loadMorePopup, setLoadMorePopup] = useState<any>(false)
  const [unitNo, setUnitNo] = useState<any>()
  const [jobId, setJobId] = React.useState<any>()
  const [isSchedule, setIsSchedule] = React.useState<any>(true)

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  useEffect(() => {
    // getmainServiceList()
    // getSubServiceData()
    getMaintananceData(id)
  }, [])

  const getmainServiceList = () => {
    ApiGet(`corporate/main_service`)
      .then((res) => {
        setMainServiceData(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getSubServiceData = () => {
    ApiGet(`corporate/sub_service`)
      .then((res) => {
        setSubServiceData(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const getTotalMinutes = (totalMinutes: any) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    const momentTime = moment().hour(hours).minute(minutes)
    const formattedTime = momentTime.format('HH:mm')
    return formattedTime
  }

  const getMaintananceData = async (id: any) => {
    await ApiGet(`corporate/tenant_request?tenantRequestId=${id}&type=6`)
      .then((res) => {
        setDevelopment(
          res?.data?.data[0]?.unit[0]?.building?.[0]?.name ||
            res?.data?.data[0]?.unit[0]?.cluster?.[0]?.name
        )
        // res?.data?.data[0]?.schedule.forEach((element: any) => {
        //   Object.assign(element, {
        //     time: getTotalMinutes(element?.preferScheduleTime),
        //     date: new Date(element?.preferScheduleDate),
        //   })
        // })
        setMaintanceData(res?.data?.data[0])
        setMediaUploaded(res?.data?.data[0]?.media)
        
        let y: any = []
        for (let i = 0; i < res?.data?.data[0]?.media.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data[0]?.media[i],
          }
        }
        setSlides(y)
        setTotalImages(res?.data?.data[0]?.media.length)

        // for(let j=0; j<res?.data?.data[0]?.serviceJob?.[0]?.schedule.length; j++){
        //   if(res?.data?.data[0]?.serviceJob?.[0]?.schedule[j].isSelected === true){
        //     console.log(res?.data?.data[0]?.serviceJob?.[0]?.schedule[j]?._id)
        //     setSelectedRadio(res?.data?.data[0]?.serviceJob?.[0]?.schedule[j]._id)
        //     setIsSchedule(false)
        //   }
        // }

        res?.data?.data[0]?.schedule.map((sch: any, i: any)=>{
          console.log("schhfghsdgfh")
          console.log(sch.isSelected)
          if(sch.isSelected === true){
            setSelectedRadio(sch._id)
          setIsSchedule(false)
          console.log("sel radio "+sch._id)
          }
        })

        let schedule: any = res?.data?.data?.[0]?.serviceJob?.[0]?.schedule.find(
          (ele: any, j: any) => ele.isSelected == true
        )
        // setSelectedRadio(schedule._id)
        // localStorage.setItem('serviceRequestId', res?.data?.data[0].serviceRequestId)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // useEffect(() => {
  //   selectSchedule(0)
  // }, [])
  const [selectedRadio, setSelectedRadio] = useState<any>(false)
  const selectSchedule = async (e: any, i: any) => {
   
    console.log('\ne.target.value', e.target.checked, i)

    let schedule: any = ''
    maintanceData.serviceJob?.[0]?.schedule.forEach((ele: any, j: any) => {
      if (i == j) {
        Object.assign(ele, {isSelected: e.target.checked})
        schedule = ele
        // console.log(schedule)
      } else {
        Object.assign(ele, {isSelected: !e.target.checked})
      }
    })
    setSelectedRadio(schedule._id)
    // console.log('maintanceData.schedule', maintanceData.schedule)

    let y: any = []
    y[y.length] = schedule
    let body: any = {
      id: id,
      schedule: maintanceData.serviceJob?.[0]?.schedule,
    }
    await ApiPut(`corporate/tenant_request`, body)
      .then((res) => {
        setIsSchedule(false)
      })
      .catch((err) => ErrorToast(err.message))

    body['id'] = maintanceData?.serviceJobId
    body['schedule'] = maintanceData.serviceJob?.[0]?.schedule

    await ApiPut(`corporate/service_job`, body)
      .then((res) => {
        setIsSchedule(false)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const handleAddServices = () => {
    if (formData?.mainServiceList !== undefined) {
      const data = {
        name: formData?.subServiceList ? formData?.subServiceList : formData?.subNewServiceList,
        note: formData?.note,
        serviceIds: [formData?.mainServiceList],
      }
      ApiPost(`corporate/sub_service`, data)
        .then((res) => {
          SuccessToast(res?.data.message)
          navigate('/facility')
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      const body = {
        name: formData?.mainServiceList
          ? formData?.mainServiceList
          : formData?.mainNewServiceList
          ? formData?.mainNewServiceList
          : '',
        note: formData?.note,
        type: 0,
      }
      ApiPost(`corporate/main_service`, body)
        .then((res) => {
          if (
            (res?.data?.data?._id && formData?.subServiceList !== undefined) ||
            formData?.subNewServiceList !== undefined
          ) {
            const data = {
              name: formData?.subServiceList
                ? formData?.subServiceList
                : formData?.subNewServiceList,
              note: formData?.note,
              serviceIds: [res?.data?.data?._id],
            }
            ApiPost(`corporate/sub_service`, data)
              .then((res) => {
                SuccessToast(res?.data.message)
                navigate('/facility')
              })
              .catch((err) => ErrorToast(err.message))
          } else {
            SuccessToast(res?.data.message)
            navigate('/facility')
          }
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  useEffect(() => {
    setRandomDatabaseId(new Date().getTime())
  }, [])
  const handleClose = () => setShowImageName(false)
  const handleShow = () => setShowImageName(true)

  let fileLists: any = []

  const onFilesAdded = async (event: any, type: any) => {
    const files = event.target.files
    fileLists = Array.from(files)

    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = ''
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    handleShow()
  }

  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }

  const saveImageFileName = async () => {
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i].selctedfile
      let fileURL: any = URL.createObjectURL(data)
      let extensionType = data?.name?.split('.')[1]
      // let type: any = finalFileUpload[i].types
      setMediaUploaded((prev: any) => [...prev, fileURL])
      data.fileURL = fileURL
      formData.append('image', data)
      await ApiUpload(
        `upload/image/params?fileType=request&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then((res) => {
          setMediaUploadedRes(res?.data?.data?.image)
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
    handleClose()
  }

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const deleteUploadImg = async (e: any, type: any, ele: any) => {
    const url = ele.replace(`${Bucket}`, '')
    await ApiPost('upload/delete_file', {url})
      .then(() => {
        setMediaUploaded(mediaUploaded.filter((flg: any, j: any) => j !== e))
      })

      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {}, [])

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const getStatus = (status: number) => {
    if (status === 0) {
      return 'Received'
    } else if (status === 1) {
      return 'In progress'
    } else if (status === 2) {
      return 'tenant verification'
    } else if (status === 3) {
      return 'Complete'
    } else if (status === 4) {
      return 'Incomplete'
    } else {
      return 'Invalid'
    }
  }
  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  //  Cancel request
  const cancelRequest = async () => {
    const body = {
      id: window.location.pathname?.split('/')[4],
      requestStatus: 4,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        SuccessToast(res.data.message)
      })
      .catch((e) => {
        console.log(e)
      })
    navigate('/request-management/service')
  }

  const openModal = async (ids: any, unitN: any) => {
    setUnitNo(unitN)

    await ApiGet(`corporate/service_job/${ids}`)
      .then((res) => {
        setMediaPop(res.data.data[0])
        setJobId(ids)
        setMediaPopup(true)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const openModalForMore = async (ids: any, unitN: any) => {
    setUnitNo(unitN)

    await ApiGet(`corporate/service_job/${ids}`)
      .then((res) => {
        setMediaPop(res.data.data[0])
        setJobId(ids)
        setLoadMorePopup(true)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getRefreshedMedia = async (id: any) => {
    await ApiGet(`corporate/tenant_request?tenantRequestId=${id}&type=6`)
      .then((res) => {
        setDevelopment(
          res?.data?.data[0]?.unit[0]?.building?.[0]?.name ||
            res?.data?.data[0]?.unit[0]?.cluster?.[0]?.name
        )
        setMaintanceData(res?.data?.data[0])
        setMediaUploaded(res?.data?.data[0]?.media)
        let schedule: any = res?.data?.data?.[0]?.serviceJob?.[0]?.schedule.find(
          (ele: any, j: any) => ele.isSelected == true
        )
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0 '>
        {/* Back button */}
        <div className='d-flex align-items-center'>
          <span
            className='rounded-circle me-2'
            style={{cursor: 'pointer'}}
            onClick={() => {
              navigate('/request-management/service')
            }}
          >
            <img src={leftArrow} style={{stroke: 'red'}} />
          </span>
          <h2 className='page-heading green_color m-0'>Tenant Maintenance Request</h2>
        </div>

        <div className='grid-out px-5 pt-4 pb-0 container-xxl'>
          <div
            className='tops py-3 px-0 mt-0 row'
            style={{justifyContent: 'space-between', flexDirection: 'row'}}
          >
            {/* <a
              // onClick={() => setShowCreateAppModal(true)}
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() => navigate('/request-management/service')}
              // onClick={() => {
              //     navigate(-1)
              // }}
            >
              Back
            </a> */}
            <div className='col-md-6 ms-auto text-end pe-1'>
              {maintanceData && maintanceData.serviceRequestType === 0 ? (
                <>
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                    onClick={() => {
                      // navigate('/request-management/service/create-request-service-internal/1')
                      localStorage.setItem('tenantRequestId', maintanceData._id)
                      localStorage.setItem('requestType', '2')
                      localStorage.setItem(
                        'serviceRequestId' + maintanceData.serviceRequestId,
                        maintanceData.serviceRequestId
                      )
                      navigate(
                        '/request-management/service/handle-tenant-request-internal/' +
                          maintanceData.serviceRequestId,
                          {state: {status: maintanceData?.requestUrgency}}
                      )
                    }}
                    style={{width: '180px', height: '38px'}}
                    disabled={isSchedule === true ? true : false}
                  >
                    <img
                      src={intReq}
                      style={{
                        height: '30px',
                        width: '25px',
                        marginRight: '16px',
                        marginLeft: '-20px',
                        marginTop: '-4px',
                      }}
                    />
                    Handle Internally
                  </button>
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => {
                      // moveVendor()
                      localStorage.setItem('tenantRequestId', maintanceData._id)
                      localStorage.setItem('requestType', '1')

                      localStorage.setItem(
                        'serviceRequestId' + maintanceData.serviceRequestId,
                        maintanceData.serviceRequestId
                      )
                      navigate(
                        '/request-management/service/handle-tenant-request-external/' +
                          maintanceData.serviceRequestId,
                          {state: {status: maintanceData?.requestUrgency}}
                      )
                    }}
                    style={{
                      height: '38px',
                    }}
                    disabled={isSchedule === true ? true : false}
                  >
                    <img
                      src={extReq}
                      style={{
                        height: '25px',
                        width: '45px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                        marginTop: '-2px',
                      }}
                    />
                    Handle Externally
                  </button>
                </>
              ) : maintanceData && maintanceData.serviceRequestType === 2 ? (
                <>
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => {
                      // moveVendor()
                      localStorage.setItem('tenantRequestId', maintanceData._id)
                      localStorage.setItem('requestType', '1')

                      localStorage.setItem(
                        'serviceRequestId' + maintanceData.serviceRequestId,
                        maintanceData.serviceRequestId
                      )
                      navigate(
                        '/request-management/service/handle-tenant-request-external/' +
                          maintanceData.serviceRequestId,
                          {state: {status: 2}}
                      )
                    }}
                    style={{
                      height: '38px',
                    }}
                    
                  >
                    <img
                      src={extReq}
                      style={{
                        height: '25px',
                        width: '45px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                        marginTop: '-2px',
                      }}
                    />
                    Switch to External
                  </button>
                <div
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                  onClick={() => {
                    // navigate('/request-management/service/create-request-service-internal/1')
                    localStorage.setItem('tenantRequestId', maintanceData._id)
                    localStorage.setItem('requestType', '2')

                    localStorage.setItem(
                      'serviceRequestId' + maintanceData.serviceRequestId,
                      maintanceData.serviceRequestId
                    )
                    navigate(
                      '/request-management/service/handle-tenant-request-internal/' +
                        maintanceData.serviceRequestId,
                        {state: {status: 2}}
                    )
                  }}
                  style={{
                    height: '38px',
                  }}
                >
                  <img
                    src={intReq}
                    style={{
                      height: '30px',
                      width: '25px',
                      marginRight: '8px',
                      marginLeft: '-11px',
                      marginTop: '-4px',
                    }}
                  />
                  Continue
                </div>
                </>
              ) : (
                <>
                 <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3 '
                    onClick={() => {
                      // navigate('/request-management/service/create-request-service-internal/1')
                      localStorage.setItem('tenantRequestId', maintanceData._id)
                      localStorage.removeItem('requestType')
                      localStorage.setItem('requestType', '2')
                      localStorage.setItem(
                        'serviceRequestId' + maintanceData.serviceRequestId,
                        maintanceData.serviceRequestId
                      )
                      navigate(
                        '/request-management/service/handle-tenant-request-internal/' +
                          maintanceData.serviceRequestId,
                          {state: {status: 2}}
                      )
                    }}
                    style={{width: '180px', height: '38px'}}
                    
                  >
                    <img
                      src={intReq}
                      style={{
                        height: '30px',
                        width: '25px',
                        marginRight: '16px',
                        marginLeft: '-20px',
                        marginTop: '-4px',
                      }}
                    />
                    Switch to Internal
                  </button>
                <div
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    // moveVendor()
                    localStorage.setItem('tenantRequestId', maintanceData._id)
                    localStorage.setItem('requestType', '1')
                    localStorage.setItem(
                      'serviceRequestId' + maintanceData.serviceRequestId,
                      maintanceData.serviceRequestId
                    )
                    navigate(
                      '/request-management/service/handle-tenant-request-external/' +
                        maintanceData.serviceRequestId,
                        {state: {status: 2}}
                    )
                  }}
                  style={{
                    height: '38px',
                  }}
                >
                  <img
                    src={extReq}
                    style={{
                      height: '25px',
                      width: '45px',
                      marginRight: '7px',
                      marginLeft: '-10px',
                      marginTop: '-2px',
                    }}
                  />
                  Continue
                </div>
                </>
              )}

              <div
                className='btn btn-sm fw-bold ms-3 text-white'
                style={{backgroundColor: 'rgb(213, 70, 69)', height: '38px', paddingTop: '9px'}}
                onClick={() =>
                  Swal.fire({
                    html: '<p class="text-center m-0" style="font-size: 16px"> You are about to reject the entire request, do you wish to proceed?</p>',
                    showConfirmButton: true,
                    confirmButtonColor: '#007a59',
                    confirmButtonText: 'Confirm',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                  }).then((res) => {
                    if (res.isConfirmed) {
                      cancelRequest()
                    }
                  })
                }
              >
                <img
                  src={crossbtn}
                  style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
                />
                Reject
              </div>
            </div>
            {/* <a
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
            >
              Submit
            </a> */}
          </div>
        </div>

        <div className='grid-out py-5 container-xxl'>
          <div className='side'>
            {/* Request Details */}
            <div className='card px-5 py-5 mt-2'>
              <h1 className='top green_color'>Request Details</h1>
              <Row className=''>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Request ID{' '}
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData && maintanceData?.id) ?? '---'}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Requestor
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData &&
                        maintanceData?.requestor[0]?.firstName +
                          ' ' +
                          maintanceData?.requestor[0]?.lastName) ??
                        '---'}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Request Urgency
                    <span className='mx-1' style={{color: 'black'}}>
                      {maintanceData && maintanceData.requestUrgency === 0
                        ? 'Standard'
                        : 'Emergency'}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Status
                    <span className='mx-1' style={{color: 'black'}}>
                      {getStatus(maintanceData && maintanceData?.status) ?? ''}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Request Name
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData && maintanceData?.name) ?? '---'}
                    </span>
                  </h6>
                </Col>
              </Row>
              {/* <h6 className='mx-0 text-muted'>
              Status
              <div className='mx-1 value_black'>
                {getStatus(maintanceData && maintanceData?.status) ?? ''}
              </div>
            </h6> */}
              {/* <h6 className='text-muted border-outline mx-0 my-2'>
              Request ID{' '}
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.id) ?? '---'}
              </div>
            </h6> */}
              {/* <h6 className='text-muted mx-0 my-2'>
              Request Name{' '}
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.name) ?? '---'}
              </div>
            </h6> */}
              {/* <h6 className='text-muted mx-0 my-2'>
              Requestor{' '}
              <div className='mx-1 value_black'>
                {(maintanceData &&
                  maintanceData?.requestor[0]?.firstName +
                    ' ' +
                    maintanceData?.requestor[0]?.lastName) ??
                  '---'}
              </div>
            </h6> */}

              {/* <h6 className='text-muted border-outline mx-0' style={{alignItems: 'center'}}>
              Request Urgency
              <div className='d-flex'>
                <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Internally'
                    id='Internally'
                    // {...register("clusterunitNo", { required: true })}
                    name='servicehandle'
                    checked={maintanceData?.requestUrgency === 0 ? true : false}
                    // checked={formData1?.clusterunitNo == data._id ? true : false}
                    // onChange={handleChnageunit}
                  />
                  <label
                    className='form-check-label required value_black'
                    htmlFor='requestUrgency'
                    style={{marginRight: '6px'}}
                  >
                    Standard
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='Internally'
                    id='Internally'
                    // {...register("clusterunitNo", { required: true })}
                    name='servicehandle'
                    checked={maintanceData?.requestUrgency === 1 ? true : false}
                    // onChange={handleChnageunit}
                  />
                  <label
                    className='form-check-label required value_black'
                    htmlFor='Internally'
                    style={{marginRight: '6px'}}
                  >
                    Emergency
                  </label>
                </div>
              </div>
            </h6> */}

              {/* {maintanceData?.schedule.map((scg: any) => (
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <label className='text-muted  mr-2 required'>Date</label>
                  <DatePicker
                    className='mx-1 form-control form-control-solid'
                    name='fromDate'
                    disabled
                    defaultValue={moment(scg?.date, 'DD/MM/YYYY')}
                  />
                  <label className='text-muted mr-2 required'>Time</label>
                  <TimePicker
                    disabled
                    className='form-control form-control-solid'
                    size='large'
                    defaultValue={moment(scg?.time, 'HH:mm')}
                  />
                </div>
              ))} */}
            </div>

            {/* Property Details */}
            <div className='card  px-5 py-5 mt-5' style={{marginBottom: '-9px'}}>
              <h1 className='top green_color'>Property Details</h1>
              <Row>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Development
                    <span className='mx-1' style={{color: 'black'}}>
                      {maintanceData?.unit?.[0]?.community.length > 0
                        ? maintanceData?.unit?.[0]?.community?.[0].name
                        : maintanceData?.unit?.[0]?.building?.[0].name}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    {maintanceData?.unit?.[0]?.community.length > 0 ? 'Cluster' : 'Floor'}
                    <span className='mx-1' style={{color: 'black'}}>
                      {maintanceData?.unit?.[0]?.community.length > 0
                        ? maintanceData?.unit?.[0]?.cluster?.[0].name
                        : maintanceData?.unit?.[0]?.floor?.[0].name}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Unit
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData && maintanceData?.unit?.[0]?.unitNo) ?? '---'}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Unit Type
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData && maintanceData?.unit?.[0]?.unitType) ?? '---'}
                    </span>
                  </h6>
                </Col>
                <Col md={6}>
                  <h6 className='text-muted'>
                    Bedrooms
                    <span className='mx-1' style={{color: 'black'}}>
                      {(maintanceData && maintanceData?.unit?.[0]?.bedrooms) ?? '---'}
                    </span>
                  </h6>
                </Col>
              </Row>

              {/* <h6 className='mx-0 text-muted'>
              Development
              <div className='mx-1 value_black'>{development || '---'}</div>
            </h6> */}

              {/* <h6 className='mx-0 text-muted'>
              Unit
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.unit?.[0]?.unitNo) ?? '---'}
              </div>
            </h6> */}
              {/* <h6 className='mx-0 text-muted'>
              Unit type
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.unit?.[0]?.unitType) ?? '---'}
              </div>
            </h6> */}
              {/* <h6 className='mx-0 text-muted'>
              Floor
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.unit?.[0]?.floor?.[0]?.name) ?? '---'}
              </div>
            </h6> */}
              {/* <h6 className='mx-0 text-muted'>
              Bedrooms
              <div className='mx-1 value_black'>
                {(maintanceData && maintanceData?.unit?.[0]?.bedrooms) ?? '---'}
              </div>
            </h6> */}
            </div>
          </div>
          <div className='side card px-5 py-5 mt-5'>
            {/* Maintenance Requirement */}

            {/* <div className='mx-5 px-5'>
              <input
                className='form-check-input me-4'
                type='radio'
                id='requestUrgency'
                name='requestType'
              />
              <label
                className='form-check-label value_black'
                htmlFor='requestUrgency'
                style={{marginRight: '6px'}}
              >
                DD.MM.YYYY - 10:10 AM
              </label>
            </div>
            <div className='mx-5 px-5'>
              <input
                className='form-check-input me-4'
                type='radio'
                id='requestUrgency'
                name='requestType'
              />
              <label
                className='form-check-label value_black'
                htmlFor='requestUrgency'
                style={{marginRight: '6px'}}
              >
                DD.MM.YYYY - 9:10 AM
              </label>
            </div> */}

            <Row>
              <Col md={10}>
                <h1 className='top green_color'>Tenant Request</h1>
              </Col>
              {/* <Col md={2} className='text-end'>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green '
                  onClick={() =>
                    openModal(maintanceData?.serviceJobId, maintanceData?.unit[0]?.unitNo)
                  }
                >
                  {' '}
                  Media
                </a>
              </Col> */}
            </Row>
            <Row>
              <Col md={12}>
                <p className='top' style={{fontSize: '14px'}}>  {(maintanceData && maintanceData?.name) ?? '---'}</p>
              </Col>
            </Row>
            <Row className=''>
              <Col md={10}>
                <h4 className='top green_color'>Maintenance Requirement</h4>
              </Col>
              {/* <Col md={2} className='text-end'>
                  <a className='btn btn-sm fw-bold btn-primary btn-green '> Media</a>
                </Col> */}
            </Row>
            <div className=''>
              {/* <h1 className='text-muted m-0 mr-2 min-w-100px mb-2 green_color'>Maintenance Requirement</h1>  */}
              <p>{(maintanceData && maintanceData?.comment) ?? '---'}</p>
              {/* <textarea
              className='form-control form-control-solid m-0'
              rows={3}
              name='comment'
              disabled
              // onChange={handleChnage}
              placeholder=' '
              value="Lorem ipsum dolor sit amet. Est eveniet esse et quia quidem ea maiores voluptatem sed corrupti quos sit voluptatum galisum et esse velit et asperiores consequatur. Et fuga sint ad facilis molestias eos iusto eveniet et dolor dolorem nam commodi sapiente et dicta omnis. Eum alias explicabo id dolorem maxime est soluta eaque? Qui voluptas incidunt ea voluptatum quidem ab pariatur nihil ad incidunt earum sed exercitationem laboriosam."
            ></textarea> */}
            </div>
            <h4 className='mx-0 text-muted align-items-center green_color'>
              {/* <Label title='Media' htmlFor='request' /> */}
              {/* <input
                type='file'
                hidden
                // {...register('contract')}
                className='form-control form-control-outline'
                placeholder=''
                name='request'
                id='request'
                onChange={(e: any) => onFilesAdded(e, 'request')}
                multiple
              /> */}
              Media
              {/* <div className='tops'>
              <img src={fileicon} width='80px' height='80px' className='main_img' />
              <img src={fileicon} width='80px' height='77px' className='main_img' />
              <img src={fileicon} width='80px' height='77px' className='main_img' />
              <img src={fileicon} width='80px' height='80px' className='main_img' />
              <img src={fileicon} width='80px' height='77px' className='main_img' />
            </div> */}
              <div className='tops d-flex align-items-center'>
                {mediaUploaded?.length > 0
                  ? mediaUploaded?.map((el: any, i: any) => {
                      if (i < 5) {
                        return (
                          <>
                            <div className='text-auto-fr me-2'>
                              <div className='img-sets mw-350px'>
                                {checkIsImage(el) ? (
                                  <img
                                    src={Bucket + el}
                                    width='83px'
                                    height='83px'
                                    className='main_img cursor-pointer'
                                    onClick={() => setIndex(i)}
                                    style={{objectFit: 'cover'}}
                                  />
                                ) : (
                                  <img
                                    src={fileicon}
                                    width='80px'
                                    height='80px'
                                    className='main_img'
                                  />
                                )}
                                {/* <img
                          src={closered}
                          className='closered'
                          width='25px'
                          height='25px'
                          onClick={() => deleteUploadImg(i, 'tenancy', el)}
                        />  */}
                              </div>
                            </div>
                          </>
                        )
                      } else if (i === 5) {
                        return (
                          <div
                            style={{
                              backgroundColor: '#007a59',
                              height: '35px',
                              width: '35px',
                              borderRadius: '50%',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              openModalForMore(maintanceData?.serviceJobId, maintanceData?.unit[0]?.unitNo)
                            }
                          >
                            <img
                              src={loadMore}
                              style={{height: '23px', width: '23px', margin: '6px'}}
                            />
                          </div>
                        )
                      }
                    })
                  : null}
              </div>
              <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
                carousel={{ finite: slides.length <= 1 }}
                render={{
                  buttonPrev: slides.length <= 1 ? () => null : undefined,
                  buttonNext: slides.length <= 1 ? () => null : undefined,
                }}
              />
              <Modal show={showImageName} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedUploadFile?.files.map((el: any, i: any) => (
                    <>
                      <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                        Input File Name
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        onChange={(e: any) => uploadImageName(e, el, i)}
                      />
                    </>
                  ))}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='primary'
                    onClick={saveImageFileName}
                    className='btn btn-sm fw-bold btn-primary btn-green '
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </h4>

            <h4 className='mx-0 me-5 pe-5 green_color'>Preferred Schedules For Property Visitation</h4>

            {maintanceData?.schedule.map((scg: any, i: any) => (
              <div className=' d-flex align-items-center gap-5 m-1'>
                <input
                  className='form-check-input me-2'
                  type='radio'
                  checked={selectedRadio === scg._id}
                  onChange={(e: any) => selectSchedule(e, i)}
                />
                <h6 className='m-0 p-0'>
                  {moment(scg?.date).format('DD.MM.YYYY')} - {timeConvert(scg?.startTime)} -{' '}
                  {timeConvert(scg?.endTime)}
                </h6>

                {scg?.date && (
                  <>
                    {/* Same Day */}
                    {new Date(scg?.date).getFullYear() === today.getFullYear() &&
                    new Date(scg?.date).getMonth() === today.getMonth() &&
                    new Date(scg?.date).getDate() === today.getDate() ? (
                      <>
                        {currentHours > parseInt(timeConvert(scg?.startTime).split(':')[0]) ? (
                          <p
                            style={{color: 'rgb(213, 70, 69)', fontWeight: '600'}}
                            className='m-0 p-0'
                          >
                            {' '}
                            Schedule has Expired
                          </p>
                        ) : (
                          <>
                            {parseInt(timeConvert(scg?.startTime).split(':')[0]) - currentHours <=
                            6 ? (
                              <p
                                style={{color: 'rgb(213, 70, 69)', fontWeight: '600'}}
                                className='m-0 p-0'
                              >
                                {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                Schedule is Approaching
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : // next Day
                    new Date(scg?.date) > today ? (
                      <>
                        {new Date(scg?.date).getDate() === today.getDate() + 1 ? (
                          <>
                            {24 -
                              currentHours +
                              parseInt(timeConvert(scg?.startTime).split(':')[0]) <=
                              6 && (
                              <p
                                style={{color: 'rgb(213, 70, 69)', fontWeight: '600'}}
                                className='m-0 p-0'
                              >
                                Schedule is Approaching
                              </p>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <></>
                      </>
                    ) : (
                      <p style={{color: 'rgb(213, 70, 69)', fontWeight: '600'}} className='m-0 p-0'>
                        Schedule has expired
                      </p>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        handleClose={() => {
          setMediaPopup(false)
        }}
        mediaList={getRefreshedMedia}
        mediaPop={mediaPop}
        id={jobId}
        reqId={id}
      />

      <LoadMoreMedia
        unitNo={unitNo}
        show={loadMorePopup}
        handleClose={() => {
         setLoadMorePopup(false)
        }}
        mediaList={getRefreshedMedia}
        mediaPop={mediaPop}
        id={jobId}
        reqId={id}
      />
    </div>
  )
}

export default CreateServiceRequest

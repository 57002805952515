import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import addIcon from '../../../../img/add.png'
import {ApiGet, ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import schedule from '../../../img/schedule-blue.png'
import closure from '../../../../../img/job-closure.png'
import correct from '../../../../../img/correct.png'
import removed from '../../../../../img/remove-filled.png'
import pencil from '../../../../../img/edit-pen.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import Switch from 'react-switch'
import {Controller, useForm} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import Select from 'react-select'
import CircularProgress from '@mui/material/CircularProgress'
import './styles.scss'

const AddAssignmentRule = () => {
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    watch,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [status, setStatus] = useState<boolean>(false)
  const [assignmentData, setAssignmentData] = React.useState<any>({
    tenantRequestSubCategoryIds: [],
    tenantRequestCategoryId: [],
  })
  const [mainServiceCategory, setMainServiceCategory] = useState<any>([])
  const [subServiceCategory, setSubServiceCategory] = useState<any>([])
  const [assignTo, setAssignTo] = useState<any>()
  const [andOrList, setAndOrList] = useState<any>([
    {label: 'AND', value: 'AND'},
    {label: 'OR', value: 'OR'},
  ])
  const [ticketLoad, setTicketLoad] = useState<any>([])
  const [andOR, setAndOr] = useState<any>([])
  const [propertyProfile, setPropertyProfile] = useState<any>([])
  const [propertyProfileList, setPropertyProfileList] = useState<any>([
    {label: 'Mukhand', value: 'Kund'},
    {label: 'Muhammad', value: 'Waqar'},
  ])

  const [mainServiceLists, setMainServiceLists] = useState<any>([
    {label: 'Maintenance', value: 'Maintenance'},
    {label: 'Complaints', value: 'Complaints'},
  ])
  const [subServiceLists, setSubServiceLists] = useState<any>([
    {label: 'Pipe Leak', value: 'Pipe Leak'},
    {label: 'Pipe Burst', value: 'Pipe Burst'},
    {label: 'AC Filter Change', value: 'AC Filter Change'},
  ])
  const [ticketLists, setTicketList] = useState<any>([
    {label: 'Ticket 1', value: '1'},
    {label: 'Ticket 2', value: '2'},
    {label: 'Ticket 3', value: '3'},
  ])

  const [priorityLists, setPriorityLists] = useState<any>([
    {label: 'Low', value: 0},
    {label: 'Medium', value: 1},
    {label: 'High', value: 2},
  ])
  const [selectedPriority, setSelectedPriority] = useState<any>([])

  const [assignRoleTypesLists, setAssignRoleTypesLists] = useState<any>([
    {label: 'Customer Support', value: 0},
    {label: 'Finance', value: 2},
    {label: 'Leasing', value: 3},
    {label: 'Maintenance', value: 1},
  ])
  const [selectedRoleType, setSelectedRoleType] = useState<any>([])

  const [selectedCriteria, setCriteria] = useState<any>([])
  const [selectedCriteriaList, setCriteriaList] = useState<any>([
    {label: 'Criteria A', value: 'Criteria A'},
    {label: 'Criteria B', value: 'Criteria B'},
    {label: 'Criteria C', value: 'Criteria C'},
  ])
  const [businessGroup, setBusinessGroup] = useState<any>([])
  const [businessGroupList, setBusinessGroupList] = useState<any>([
    {label: 'Business Group A', value: 'Business Group A'},
    {label: 'Business Group B', value: 'Business Group B'},
    {label: 'Business Group C', value: 'Business Group C'},
  ])
  const [description, setDescription] = useState<any>('')
  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select'
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  const addRule = async (data: any, e: any) => {
    e?.preventDefault()
    setIsSkeleton(true)
    
    const body: any = {
      name: assignmentData?.ruleName,
      description: assignmentData?.description,
      tenantRequestSubCategoryIds: assignmentData?.tenantRequestSubCategoryIds?.map((item: any) => item?.value),
      tenantRequestCategoryId: assignmentData?.tenantRequestCategoryId?.map((item: any) => item?.value)?.[0],
      criteriaType: 0,
    }
    

    await ApiPost(`corporate/tenant_request_assignment_rule`, body)
      .then((response) => {
        setIsSkeleton(false)
        navigate(-1)
      })
      .catch((error) => {
        setIsSkeleton(false)
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  const [assignToAgent, setAssignToAgent] = useState<any>([])
  const addCriteria = () => {
    if (assignTo === 'agent') {
      const updateAssignToAgent = [...assignToAgent]
      updateAssignToAgent?.push({
        ticketLoad: [],
        andOrValue: [],
        priorityValue: [],
        andOrValue2: [],
        propertyProfile: [],
        roleType: [],
        criteria: [],
      })

      console.log(updateAssignToAgent)

      setAssignToAgent(updateAssignToAgent)
    }
  }

  const [mainRequestCategory, setMainRequestCategory] = useState<any>([])
  const getRequestMainCategory = async () => {
    setMainRequestCategory([])
    setIsSkeleton(true)

    await ApiGet('corporate/tenant_request_category')
      .then((res) => {
        setIsSkeleton(false)
        const mainCategory = res?.data?.data.map((item: any) => ({value: item?._id, label: item?.name}))
        setMainRequestCategory(mainCategory)
      })
      .catch((err) => {
        setIsSkeleton(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const [subRequestCategory, setSubRequestCategory] = useState<any>([])
  const getRequestSubCategory = async () => {
    setSubRequestCategory([])
    setIsSkeleton(true)
    
    await ApiGet('corporate/tenant_request_sub_category')
      .then((res) => {
        setIsSkeleton(false)
        const subCategory = res?.data?.data.map((item: any) => ({value: item?._id, label: item?.name}))
        setSubRequestCategory(subCategory)
      })
      .catch((err) => {
        setIsSkeleton(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getRequestMainCategory()
    getRequestSubCategory()
  }, [])

  return (
    <>
      {!isSkeleton ? (
        <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span className='rounded-circle' style={{cursor: 'pointer'}} onClick={() => navigate('/settings/ticket-assignment')}>
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>{'Add Assignment Rule'}</h2>
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-end gap-2 mb-5 gap-lg-3'>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn status-w-140 px-2 justify-content-center'
            onClick={handleSubmit((data: any, e: any) => addRule(assignmentData, e))}
          >
            Save
          </button>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0  py-0 px-3'>
            <form>
              <Row className='mt-5'>
                <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                  {/* assignment rule name */}
                  <div style={{width: '60%'}}>
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%', fontWeight: 'bold'}}>
                        Assignment Rule Name
                      </h4>
                      <h4 style={{width: '100%'}} className='my-2'>
                        <input
                          {...register('ruleName', {required: true})}
                          type='text'
                          className='form-control form-control-solid mytest'
                          style={{backgroundColor: '#fff'}}
                          autoComplete='off'
                          placeholder=''
                          name='ruleName'
                          value={assignmentData?.ruleName}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value)
                            setAssignmentData({
                              ...assignmentData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                        />

                        {errors.ruleName && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                            This field is required
                          </p>
                        )}
                      </h4>
                    </div>
                  </div>
                  {/* Request Category */}
                  <div style={{width: '100%'}}>
                    <div className='d-flex flex-column my-2 mb-4 align-items-start'>
                      <h4 className='my-2' style={{color: 'black', width: '100%', fontWeight: 'bold'}}>
                        Request Category
                      </h4>
                      <h4 className='my-2' style={{color: '#7f7f7f', width: '100%'}}>
                        <label>Select the request categories that this assignment rule will apply to:</label>
                      </h4>
                      <div className='mt-2 d-flex'>
                        <div className='test property me-4 crm mt-2'>
                        <div className='property-test d-none-multi-checbox me-4 '>
                        <div
                            className={`multi-select-container  jobs-property crmDropdown mainCategory ${
                              assignmentData?.tenantRequestCategoryId?.length === 0 ? 'no-value' : 'has-value '
                            }`}
                          >
                            <Controller
                              name='mainService'
                              control={control}
                              render={({field}) => (
                                <MultiSelect
                                  options={mainRequestCategory}
                                  value={assignmentData?.tenantRequestCategoryId}
                                  onChange={(e: any) => {
                                    const updateAssignmentData = {...assignmentData}
                                    let newVal: any = e
                                    if (e?.length > 1) newVal = e.slice(1)
                                    updateAssignmentData['tenantRequestCategoryId'] = newVal
                                    setAssignmentData(updateAssignmentData)
                                  }}
                                  labelledBy='Main Service'
                                  // valueRenderer={CustomValueRenderer}
                                  hasSelectAll={false}
                                />
                              )}
                            />
                          </div>
                        </div>
                         
                        </div>
                        {/* Sub Category */}
                        <div className='ms-7 test property me-4 crm mt-2'>
                          <div
                            className={`multi-select-container  jobs-property crmDropdown subCategory ${
                              assignmentData?.tenantRequestSubCategoryIds?.length === 0 ? 'no-value' : 'has-value '
                            }`}
                          >
                            <Controller
                              name='subService'
                              control={control}
                              render={({field}) => (
                                <MultiSelect
                                  options={subRequestCategory}
                                  value={assignmentData?.tenantRequestSubCategoryIds}
                                  onChange={(e: any) => {
                                    const updateAssignmentData = {...assignmentData}
                                    updateAssignmentData['tenantRequestSubCategoryIds'] = e
                                    setAssignmentData(updateAssignmentData)
                                    
                                  }}
                                  labelledBy='Sub Service'
                                  valueRenderer={CustomValueRenderer}
                                  overrideStrings={{
                                    allItemsAreSelected: `${assignmentData?.tenantRequestSubCategoryIds?.length} Selected`,
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                  <h4 className='mt-5 mb-1' style={{color: 'black', width: '100%', fontWeight: 'bold'}}>
                    Description
                  </h4>
                  <h4 style={{width: '100%'}} className=' mt-5'>
                    <div className='row'>
                      <div className='col-8'>
                        <textarea
                          {...register('description', {required: true})}
                          placeholder=''
                          name='description'
                          value={assignmentData?.description}
                          onChange={(e) => {
                            const captilizeWord = capitalizeFirstLetter(e.target.value)
                            setAssignmentData({
                              ...assignmentData,
                              [e.target.name]: captilizeWord,
                            })
                          }}
                          className='custom-textarea-lg'
                        />
                        {errors.description && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                            This field is required
                          </p>
                        )}
                      </div>
                    </div>
                  </h4>
                </Col>
                <div className='d-flex align-items-center px-6' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>
              </Row>
            </form>
            <Row className='mt-5'>
              <div className='d-flex align-items-center justify-content-between'>
                <div>
                  <h4 className='my-2' style={{color: 'black', width: '100%', fontWeight: 'bold'}}>
                    Criteria
                  </h4>
                  <h4 className='my-2' style={{color: '#7f7f7f', width: '100%'}}>
                    <label>Set the criteria for assigning the tickets to the above selected request categories:</label>
                  </h4>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold px-2 green-hollow-btn justify-content-center btn-fit-content'
                    onClick={() => addCriteria()}
                  >
                    <img
                      src={addIcon}
                      height={16}
                      width={16}
                      style={{
                        marginRight: '7px',
                      }}
                    />
                    Add Criteria
                  </button>
                </div>
              </div>
              {/* radio btns */}
              <Row className='mt-5'>
                <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                  <div>
                    <div className='d-flex align-items-center tst'>
                      <input
                        id='agent'
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        name='assignTo'
                        value='agent'
                        checked={assignTo === 'agent'}
                        onChange={() => setAssignTo('agent')}
                      />
                      <label htmlFor='agent'>Assign to CRM Agent before assigning to business group</label>
                    </div>
                    <h4 className='my-2 ms-7' style={{color: '#7f7f7f', width: '100%'}}>
                      <i>
                        Once tickets are received they will be assigned to an agent who will then assign the business group for the ticket. Once agent
                        selects the business group, business group users will be assigned as per the criteria set for business groups.
                      </i>
                    </h4>
                  </div>
                </Col>
                <Col md={6} className='mb-5' style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{paddingLeft: '120px'}}>
                    <div className='d-flex align-items-center tst'>
                      <input
                        id='businessGroup'
                        className='form-check-input me-3 cursor-pointer'
                        type='radio'
                        name='assignTo'
                        value='businessGroup'
                        checked={assignTo === 'businessGroup'}
                        onChange={() => setAssignTo('businessGroup')}
                      />
                      <label htmlFor='businessGroup'>Assign directly to business group</label>
                    </div>
                    <h4 className='my-2 ms-7' style={{color: '#7f7f7f', width: '100%'}}>
                      <i>
                        Once tickets are received they will be assigned directly to the business group as per the criteria set for business groups.
                      </i>
                    </h4>
                  </div>
                </Col>
              </Row>
            </Row>
            <Row className='my-5' style={{paddingBottom: '100px'}}>
              <div className='mt-2 d-flex flex-wrap'>
              {assignTo === 'businessGroup' && (
                  <>
                  <span className='test property'>
                  <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdown businessGroup ${
                          ticketLoad?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={businessGroupList}
                          value={businessGroup}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setBusinessGroup(newVal)
                          }}
                          labelledBy='Business Group'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                  </span>
                   
                  <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdownSmall andOr ${andOR?.length === 0 ? 'no-value' : 'has-value '}`}
                      >
                        <MultiSelect
                          options={andOrList}
                          value={andOR}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            console.log(newVal)
                            setAndOr(newVal)
                          }}
                          labelledBy='Ticket Load'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container jobs-property ticketPriority ms-0   ${
                          selectedPriority.length === 0 ? 'no-value select ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={priorityLists}
                          value={selectedPriority}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedPriority(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdownSmall andOr ${andOR?.length === 0 ? 'no-value' : 'has-value '}`}
                      >
                        <MultiSelect
                          options={andOrList}
                          value={andOR}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            console.log(newVal)
                            setAndOr(newVal)
                          }}
                          labelledBy='Ticket Load'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdown propertyProfile ${
                          andOR?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={propertyProfileList}
                          value={propertyProfile}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setPropertyProfile(newVal)
                          }}
                          labelledBy='Property Profile'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox mt-2'>
                      <div
                        className={`multi-select-container jobs-property ms-0 me-5  ${
                          selectedRoleType?.length === 0 ? 'no-value userRole ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={assignRoleTypesLists}
                          value={selectedRoleType}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedRoleType(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: 'All',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox mt-2'>
                      <div
                        className={`multi-select-container jobs-property ms-0 me-5  ${
                          selectedCriteria?.length === 0 ? 'no-value selectCriteria ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={selectedCriteriaList}
                          value={selectedCriteria}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setCriteria(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: 'All',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                  </>
                )}

                {assignTo === 'agent' && (
                  <>
                   <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdown ticketLoad ${
                          ticketLoad?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={ticketLists}
                          value={ticketLoad}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            console.log(newVal)
                            setTicketLoad(newVal)
                          }}
                          labelledBy='Ticket Load'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdownSmall andOr ${andOR?.length === 0 ? 'no-value' : 'has-value '}`}
                      >
                        <MultiSelect
                          options={andOrList}
                          value={andOR}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            console.log(newVal)
                            setAndOr(newVal)
                          }}
                          labelledBy='Ticket Load'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container jobs-property ticketPriority ms-0   ${
                          selectedPriority.length === 0 ? 'no-value select ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={priorityLists}
                          value={selectedPriority}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedPriority(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdownSmall andOr ${andOR?.length === 0 ? 'no-value' : 'has-value '}`}
                      >
                        <MultiSelect
                          options={andOrList}
                          value={andOR}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            console.log(newVal)
                            setAndOr(newVal)
                          }}
                          labelledBy='Ticket Load'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox me-4 mt-2'>
                      <div
                        className={`multi-select-container  jobs-property crmDropdown propertyProfile ${
                          andOR?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={propertyProfileList}
                          value={propertyProfile}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setPropertyProfile(newVal)
                          }}
                          labelledBy='Property Profile'
                          overrideStrings={{
                            allItemsAreSelected: '',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox mt-2'>
                      <div
                        className={`multi-select-container jobs-property ms-0 me-5  ${
                          selectedRoleType?.length === 0 ? 'no-value userRole ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={assignRoleTypesLists}
                          value={selectedRoleType}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedRoleType(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: 'All',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                    <span className='test property'>
                    <div className='property-test d-none-multi-checbox mt-2'>
                      <div
                        className={`multi-select-container jobs-property ms-0 me-5  ${
                          selectedCriteria?.length === 0 ? 'no-value selectCriteria ' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={selectedCriteriaList}
                          value={selectedCriteria}
                          onChange={(e: any) => {
                            let newVal: any = e
                            if (e?.length > 1) newVal = e.slice(1)
                            setCriteria(newVal)
                          }}
                          labelledBy=''
                          overrideStrings={{
                            allItemsAreSelected: 'All',
                          }}
                          hasSelectAll={false}
                          closeOnChangedValue={true}
                        />
                      </div>
                    </div>
                    </span>
                  </>
                )}
              </div>
            </Row>
          </div>
        </div>
      </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
    </>
  )
}

export default AddAssignmentRule

import moment from 'moment';
import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ApiGet, ApiPost, ApiPut } from '../../../apiCommon/helpers/API/ApiData';
import { SuccessToast } from '../../../apiCommon/helpers/Toast';

let arry: any = [{ id: (1).toString() }]
const ExternalServiceDetails = () => {
    const navigate = useNavigate()
    const Vendors = new Array(2).fill(0);
    const [addrow, setAddRow] = React.useState<any>(0)
    const [serviceDetail, setServiceDetail] = React.useState<any>()
    const serviceId = window.location.pathname?.split('/')[4]
    const [requestData, setRequestData] = React.useState<any>()

    const getServiceDetail = async () => {
        const body = {
            serviceRequestId: serviceId
        }
        await ApiPost(`corporate/service_request/get_details`, body)
            .then((res) => {
                setServiceDetail(res?.data?.data?.service_request_list)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getServiceData = async () => {
        await ApiGet(`corporate/service_request/${window.location.pathname?.split('/')[4]}`)
            .then((res) => {
                setRequestData(res?.data?.data[0])
            })
            .catch((e) => {
                console.log(e)
            })
    }

    React.useEffect(() => {
        getServiceDetail()
        getServiceData()
    }, [])

    const canselRequest = async () => {
        const body = {
            id: window.location.pathname?.split('/')[4],
            requestStatus: 9,
        }
        await ApiPut(`corporate/service_request`, body)
            .then((res) => {
                SuccessToast(res.data.message)
            })
            .catch((e) => {
                console.log(e)
            })
        navigate('/request-management/service')
    }

    const serviceComplition = async () => {
        const body = {
            id: window.location.pathname?.split('/')[4],
            requestType: 9,
        }
        await ApiPut(`corporate/service_request`, body)
            .then((res) => {
                SuccessToast(res.data.message)
            })
            .catch((e) => {
                console.log(e)
            })
        navigate('/request-management/service')

    }

    const variationRequest = async () =>{
        const body = {
            id: window.location.pathname?.split('/')[4],
            requestStatus: 8,
        }
        await ApiPut(`corporate/service_request`, body)
            .then((res) => {
                SuccessToast(res.data.message)
            })
            .catch((e) => {
                console.log(e)
            })
        navigate('/request-management/service')

    }

    const handleAddRow = () => {
        setAddRow(addrow + 1)
        arry.push({ id: (arry.length + 1).toString() })
    }

    const requestAgain =async () =>{
        const body = {
            id: window.location.pathname?.split('/')[4],
            requestStatus: 2,
        }
        await ApiPut(`corporate/service_request`, body)
            .then((res) => {
                SuccessToast(res.data.message)
            })
            .catch((e) => {
                console.log(e)
            })
        navigate('/request-management/service')
    }

    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                    <div className='app-container container-xxl d-flex flex-stack pt-3 mt-0 mb-5'>
                        <div className=''>
                            <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                                onClick={() => navigate(`/request-management/service`)}
                            >
                                Back
                            </a>
                        </div>

                    </div>
                </div>
                <div className='card card-flush app-container py-5'>
                    <Row >
                        <div className='col-md-3'>
                            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>Message Vendor</button>
                        </div>
                        <div className='col-md-3'>

                        </div>
                        <div className='col-md-3'></div>
                        <div className='col-md-3' style={{ textAlign: 'end' }}>
                            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() => Swal
                                    .fire({
                                        html: '<p class="text-center m-0">You are about to cancel this request, do you wish to proceed? <div class="form-check justify-content-center form-check-sm form-check-custom form-check-solid mt-3"><input class="form-check-input me-3" type="checkbox" id="flexCheckDefault" value="" style="cursor: pointer;" />Notify the vendor</div></p>',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Cancel Request',
                                        showCancelButton: true,
                                        cancelButtonText: 'Back',
                                        width: "450"
                                    })
                                    .then((res) => {
                                        if (res.isConfirmed) {
                                            canselRequest()
                                        }
                                    })
                                }
                            >
                                Cancel Request
                            </button>
                            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3' onClick={()=>requestAgain()}>
                                Request Again
                            </button>
                        </div>
                    </Row>
                    <div className='container-xxl d-flex flex-stack pt-3 mt-0'>
                        <div className='card-header align-items-center justify-content-start py-5  gap-md-2 d-flex border-0 p-0 contact-details-wrapper'>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Request Type:
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        External
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Date Requested:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {moment(requestData?.createdAt).format('DD/MM/YYYY')}
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Request ID:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {requestData?.id}
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Quotation:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>

                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Handled By:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {requestData?.requestType === 1 ? requestData?.vendor[0]?.companyName:'Intrenal Team'}
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Assigned To:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {requestData?.staff[0]?.name}
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Overall Status:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {requestData?.serviceOverallStatus === 0 ? 'Not Started' :
                                            requestData?.serviceOverallStatus === 1 ? 'Pending' :
                                                requestData?.serviceOverallStatus === 2 ? 'Started' :
                                                    requestData?.serviceOverallStatus === 3 ? 'Completed' : ''
                                        }
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Request Urgency:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>
                                        {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-200px'>
                                <h6 className='me-5 text-muted'>
                                    Total Request Amount:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>

                                    </span>
                                </h6>
                            </div>
                            <div className='min-w-50px'>
                                <h6 className='me-5 text-muted'>
                                    Invoices:{' '}
                                    <span className='mx-1' style={{ color: 'black' }}>

                                    </span>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/request-management/service/external-service-details`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                    Service
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    // navigate(`/request-management/service/create-request-service/payment-tab`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    Payment
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/request-management/service/external-request-log-tab/${serviceId}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    Request Log
                                </a>
                            </li>
                        </ul>
                    </div>

                    <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
                        Scope
                    </h3>
                    <div className='d-flex align-items-center justify-content-end gap-2 mb-10 gap-lg-3'>
                        <button className='btn btn-sm fw-bold btn-primary btn-green mx-3' onClick={variationRequest}>
                            Variation Request
                        </button>
                        <button className='btn btn-sm fw-bold btn-primary btn-green mx-3' /* onClick={serviceComplition} */>
                            Service Completion Reports
                        </button>
                        <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>
                            Media
                        </button>
                    </div>
                    <div className='card-body pt-0 table-responsive mt-5'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-100px'>Service Name</th>
                                    <th className='text-center min-w-100px'>Development</th>
                                    <th className='text-center min-w-100px'>Total properties </th>
                                    <th className='text-center min-w-100px'>Not Started</th>
                                    <th className='text-center min-w-100px'>In Progress</th>
                                    <th className='text-center min-w-100px'>Cancelled</th>
                                    <th className='text-center min-w-100px'>Last Update</th>
                                    <th className='text-center min-w-100px'>Invoices</th>
                                    <th className='text-center min-w-100px'>Invoice Amount</th>
                                    <th className='text-center min-w-100px'>Payment Status</th>
                                    <th className='text-center min-w-100px'></th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                {serviceDetail?.map((f: any, i: any) => {
                                    return (
                                        <tr key={i}>
                                            <td className='text-center min-w-100px'>{f?.main_service[0]?.name}</td>
                                            <td className='text-center min-w-100px'>{f?.building?.name ? f?.building?.name : f?.community?.name}</td>
                                            <td className='text-center min-w-100px'>{f?.totalProperties}</td>
                                            <td className='text-center min-w-100px'>{f?.totalNotStarted}</td>
                                            <td className='text-center min-w-100px'>{f?.totalInProgress}</td>
                                            <td className='text-center min-w-100px'>{f?.totalComplete}</td>
                                            <td className='text-center min-w-100px'>{moment(f?.updatedAt).format('DD/MM/YYYY')}</td>
                                            <td className='text-center min-w-100px'>#</td>
                                            <td className='text-center min-w-100px'>AED ####</td>
                                            <td className='text-center min-w-100px'>Status</td>
                                            <td>
                                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                    onClick={() => {
                                                        navigate(`/request-management/service/external-service-details/${f?._id}`)
                                                    }}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>

                    <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
                        Consumption
                    </h3>
                    <div className='card-body pt-0 table-responsive mt-5'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-100px'>Asset</th>
                                    <th className='text-center min-w-100px'>Quantity</th>
                                    <th className='text-center min-w-100px'>Consumption Date </th>
                                    <th className='text-center min-w-100px'>Property</th>
                                    <th className='text-center min-w-100px'>Job ID</th>
                                    <th className='text-center min-w-100px'> Entered By</th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                {Vendors.map((f, i) => {
                                    return (
                                        <tr>
                                            <td className='text-center min-w-100px'>###</td>
                                            <td className='text-center min-w-100px'>service</td>
                                            <td className='text-center min-w-100px'>Lorem Ipsum</td>
                                            <td className='text-center min-w-100px'>Service Provider</td>
                                            <td className='text-center min-w-100px'>5427850</td>
                                            <td className='text-center min-w-100px'>5427850</td>
                                            <td>
                                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='card-body pt-0 table-responsive mt-5'>
                        {arry.map(() => {
                            return <div className='mb-5'>
                                <td>
                                    <select
                                        name={`rentType`}
                                        className='form-select fst-italic'
                                        style={{ width: '100px' }}
                                    >
                                        <option className='fst-italic' disabled selected>
                                            Select
                                        </option>
                                        <option className='fst-italic' value={0}>
                                            Rent
                                        </option>
                                        <option className='fst-italic' value={1}>
                                            Security Deposit
                                        </option>
                                        <option className='fst-italic' value={2}>
                                            Booking
                                        </option>
                                        <option className='fst-italic' value={3}>
                                            Other
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        className='form-control mx-2'
                                        placeholder=''
                                        // name='amount'
                                        name={`amount`}
                                        // name={`amount ${i}`}
                                        style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='date'
                                        className='form-control mx-2'
                                        placeholder=''
                                        // name='amount'
                                        name={`amount`}
                                        // name={`amount ${i}`}
                                        style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <select
                                        name={`rentType`}
                                        className='form-select fst-italic'
                                        style={{ width: '100px' }}
                                    >
                                        <option className='fst-italic' disabled selected>
                                            Select
                                        </option>
                                        <option className='fst-italic' value={0}>
                                            Rent
                                        </option>
                                        <option className='fst-italic' value={1}>
                                            Security Deposit
                                        </option>
                                        <option className='fst-italic' value={2}>
                                            Booking
                                        </option>
                                        <option className='fst-italic' value={3}>
                                            Other
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        className='form-control mx-2'
                                        placeholder=''
                                        // name='amount'
                                        name={`amount`}
                                        // name={`amount ${i}`}

                                        style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                    >
                                        Submit
                                    </a>
                                </td>
                            </div>
                        })}
                        <div className='mt-2'>
                            <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                                onClick={handleAddRow}
                            >
                                + Add
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ExternalServiceDetails

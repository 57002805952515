import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import creditNoteImg from '../../../img/credit-note-blue.png'
import {ApiGet,ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import editpen from '../../../img/pen.png'
import InvConfigureModal from './InvConfigureModal'
import Switch from 'react-switch'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'

const CreditNote = () => {
  const navigate = useNavigate()
  const [invNoModal, setInvNoModal] = useState<any>(false)
  const [creditNoteSeq, setCreditNoteSeq] = useState<any>(null)

  //
  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setCreditNoteSeq(res?.data?.data?.creditNoteSequence)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const updateActivityStatus = (val: any) => {
    let status: any
    if (val) status = 1
    else status = 0

    const body = {
      activityStatus: status,
    }

    ApiPut('corporate/corporate_setting/credit_note_activity', body)
      .then((res) => {
        getDetails()
      })
      .catch((err) => ErrorToast(err.message))
  }

  useEffect(() => {
    getDetails()
  }, [])

  const renderLabel = (label: string, value: any) => (
    <Col xs={6} sm={6} md={4} lg={4}>
      <div className='d-flex grid_big_col py-2 fs-7'>
        <label
          className='form-label blue-label fs-7 light-dark-theme-color'
          style={{minWidth: '115px'}}
        >
          {label}
        </label>
        <div className='d-flex white-dark-theme-color'>{value ?? '-'}</div>
      </div>
    </Col>
  )

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='px-2'>
        <div className='d-flex align-items-center mb-10'>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <h2 className='page-heading m-0 head-text'>Credit Note</h2>
          </div>
          <div className='ms-auto'></div>
          <div className='ms-5'>
            <img src={creditNoteImg} height={40} width={40} />
          </div>
        </div>

        {/* Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center'
          style={{marginBottom: '35px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 me-5 active'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/credit-note')}
              >
                Credite Note No. Generator
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 white-dark-theme-color'
                data-bs-toggle='tab'
                onClick={() => navigate('/settings/credit-note-design')}
              >
                Credit Note Design
              </a>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={12} className='pe-5'>
            {/* Invoice No Configuration */}
            <div className='card px-5 py-5 mt-5'>
              <Row>
                <Col md={12} className='pe-5'>
                  {/* Invoice No Configuration */}

                  <div className='d-flex'>
                    <div className='d-flex ms-auto'>
                      <Switch
                        onChange={updateActivityStatus}
                        checked={creditNoteSeq?.activityStatus === 1}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor='#00cf99'
                        offColor='#b0bec5'
                        className='ms-auto me-5'
                      />
                      <img
                        src={editpen}
                        alt='Edit'
                        className='ms-4 me-2'
                        style={{cursor: 'pointer', height: '22px', width: '22px', marginTop: '3px'}}
                        onClick={() => setInvNoModal(true)}
                      />
                    </div>
                  </div>

                  <Row className='mt-3'>
                    {renderLabel('Start No.', creditNoteSeq?.startNumber)}
                    {renderLabel('Increment By', creditNoteSeq?.increment)}
                    {renderLabel('No. of Digits', creditNoteSeq?.noOfDigits)}
                    {renderLabel('Prefix', creditNoteSeq?.prefix)}
                    {renderLabel('Suffix', creditNoteSeq?.suffix)}
                    {renderLabel('Last Count', creditNoteSeq?.lastCount)}
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {/*  */}
      {invNoModal && (
        <InvConfigureModal
          show={invNoModal}
          handleClose={() => {
            setInvNoModal(false)
          }}
          type={'creditnote'}
          invSeq={creditNoteSeq}
          getDetails={getDetails}
        />
      )}
    </>
  )
}

export default CreditNote

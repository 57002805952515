import React, {useRef, useState} from 'react'
import {Modal, Col, Row} from 'react-bootstrap'
import {ApiGet, ApiPost, ApiUpload} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../../_metronic/helpers'
import {DatePicker, Space} from 'antd'
import moment, {Moment} from 'moment'
import { useNavigate } from 'react-router-dom'

interface ButtonProps {
  show: any
  handleClose: any
  id: any
  startDate: any
  endDate: any
  fetchData:any
  unitId:any
}
const TerminateTenancy = ({show, handleClose, id, startDate, endDate, fetchData, unitId}: ButtonProps) => {
  const navigate = useNavigate()
  const [termDate, setTermDate] = useState(null)

  const handleDateChange = (date: any) => {
    if (date != null) setTermDate(date.format('YYYY-MM-DD'))
    else setTermDate(null)
  }

  const disabledDate = (current: Moment) => {
    const start = moment(startDate)
    const end = moment(endDate)
    const today = moment()

    // Disable dates that are before the start date or after the end date
    return current && (current < start || current > end || current < today.startOf('day'))
  }

  const terminate = () => {
    let body = {
      tenancyId: id,
      terminationDate: termDate,
    }
    ApiPost('corporate/tenancy/terminate', body)
      .then((res) => {
        // navigate(`/create-tenant/3/${unitId}/${id}`, 
        navigate(`/tenancy/create/3/${unitId}/${id}`,
        {state: {terminationDate: termDate}})
        // SuccessToast(res?.data?.message)
        // fetchData()
        // handleClose()

      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
    >
      <div className='modal-header'>
        <h1 className='head-text'>Tenancy Termination</h1>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body' style={{minWidth: '200px'}}>
        <Row className='mb-5'>
          <Col md={8} className='mb-5'>
            <div className='payment-receipt-item mt-5 d-flex align-items-center'>
              <label style={{width: '200px'}} className='head-text fs-5'>
                <b>Termination Date</b>
              </label>
              <DatePicker onChange={handleDateChange} disabledDate={disabledDate} />
            </div>
          </Col>

          <Col md={4} className='text-center mt-5 mb-5'>
            <button
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-kt-stepper-action='previous'
              onClick={() => {
                terminate()
              }}
              style={{width: '120px'}}
              disabled={termDate == null ? true : false}
            >
              Continue
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default TerminateTenancy

import React, {useEffect, useState} from 'react'
import redCross from '../../../../img/remove.png'
import correct from '../../../../img/correct.png'
import folderIcon from '../../../../img/folder.png'

import {useForm} from 'react-hook-form'

interface FolderCreationI {
  folderNameModal: any
  setFolderNameModal: any
  addFolder: any
  setFolderName: any
  folderName: any
  fileFound: any
  isFolderName: any
  setIsFolderName: any
  folderBtnDisable: boolean
}

const FolderCreation = ({
  folderNameModal,
  setFolderNameModal,
  addFolder,
  setFolderName,
  folderName,
  fileFound,
  isFolderName,
  setIsFolderName,
  folderBtnDisable,
}: FolderCreationI) => {
  const {handleSubmit} = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const maxLength = 30
  const [isLimitError,setIsLimitError] = useState<boolean>(false)

  const handleSubmitForm = (e: any) => {
    e.preventDefault()
    if (folderBtnDisable) return
    if (folderName?.trim()?.length > 0 && folderName?.trim()?.length <= maxLength) {
      addFolder()
    } else if(folderName?.trim()?.length == 0 || folderName == '') {
      setIsFolderName(true)
    }
  }

  useEffect(() => {
    setIsLimitError(false)
  },[folderNameModal])

  return folderNameModal ? (
    <div className='col-md-6 col-lg-4 col-xl-4'>
      <form onSubmit={handleSubmit((data: any, e: any) => handleSubmitForm(e))}>
        <div className='d-flex mx-2 mb-2 align-items-center'>
          <img src={redCross} height={18} width={18} className='cursor-pointer' onClick={() => setFolderNameModal(false)} alt='Close' />
          <img
            // style={{opacity: '1', cursor: 'pointer'}}
            style={folderBtnDisable == false ? {opacity: '1', cursor: 'pointer'} : {opacity: '0.7', cursor: 'not-allowed'}}
            src={correct}
            height={24}
            width={24}
            className='ms-auto'
            onClick={() => {
              if (folderName?.trim()?.length > 0 && folderName?.trim()?.length <= maxLength) {
                addFolder()
              } else if(folderName?.trim()?.length == 0 || folderName == '') {
                setIsFolderName(true)
              }
            }}
            alt='Add folder'
          />
        </div>
        <div className='card mx-2 pb-6'>
          <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test pb-2'>
            <a className='text-gray-800 text-hover-primary d-flex flex-column'>
              <div className='symbol symbol-60px mb-5'>
                <img src={folderIcon} className='theme-light-show' alt='Folder icon' />
                <img src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg' className='theme-dark-show' alt='Dark folder icon' />
              </div>
              <div className='fs-5 fw-bold mb-2'>
                <input
                  type='text'
                  className='form-control form-control-solid mytest w-75 m-auto bg-white'
                  placeholder='Enter Folder Name'
                  onChange={(e) => {
                    if(e?.target?.value?.length > 30){
                      setFolderName(e.target.value.slice(0, maxLength + 1))
                      setIsLimitError(true)
                    }else{
                      setFolderName(e.target.value)
                      setIsLimitError(false)
                    }
                  }}
                  maxLength={maxLength + 1}
                  // onKeyUp={handleKeyPress}
                />
                {isFolderName ? (
                  <p className='mt-2 mb-0' style={{color: 'red'}}>
                    This field is required
                  </p>
                ): isLimitError ?  (
                  <p className='mt-2 mb-0' style={{color: 'red'}}>
                    Folder name cannot exceed {maxLength} characters
                  </p>
                ) : fileFound ? (
                  <p className='mt-2 mb-0' style={{color: 'red'}}>
                    Folder name already exists
                  </p>
                ) : null}

              </div>
            </a>
          </div>
        </div>
      </form>
    </div>
  ) : null
}

export default FolderCreation

import React, {useState} from 'react'
import moment, {Moment} from 'moment'
import addWhite from '../../../img/add-white.png'
import './styles.scss'
import {Modal} from 'react-bootstrap'
import uploadWhite from '../../../img/upload.svg'
import {ApiPost, Bucket, ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import fileIcon from '../../../img/file.svg'
import correct from '../../../img/correct.png'
import removed from '../../../img/remove-filled.png'
import pencil from '../../../img/edit-pen.png'
import trashImg from '../../../img/trash.png'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import ModalLoader from '../General/ModalLoader'
import videoImg from '../../../img/video.png'

import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import submitWhite from '../../../img/Submit-White.png'
import redCross from '../../../img/remove.png'
import { getFileIcon } from '../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  updateJobDetails?: any
  modalData?: any
  dataInd?: any
  tab?: any
  isViewMode: any
}

const JobDetailsModal = ({
  show,
  handleClose,
  updateJobDetails,
  modalData,
  dataInd,
  tab,
  isViewMode,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  let showUpd: any = localStorage.getItem('isJobDetails')
  const showUpdateBtn = showUpd ?  JSON.parse(showUpd) : false

  console.log(showUpdateBtn)

  const [comment, setComment] = useState<any>('')
  const [commentToEdit, setCommentToEdit] = useState<any>('')

  const [disableCancel, setDisableCancel] = useState<any>(true)

  const [attachments, setAttachments] = useState<any>(
    modalData?.attachments ? modalData?.attachments : []
  )
  const [comments, setComments] = useState<any>(modalData?.comments ? modalData?.comments : [])
  const [commentError, setCommentError] = useState<any>(false)

  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const [showLoader, setShowLoader] = useState<boolean>(false) // State to control the visibility of the modal loader
  const [uploadProgress, setUploadProgress] = useState<number>(0) // State to track the upload progress
  const [totalFiles, setTotalFiles] = useState<number>(0) // State to track the total number of files being uploaded

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  // const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault()
  //   setShowLoader(true) // Show the modal loader when file uploading starts

  //   const values: any[] = [...attachments]
  //   let newVal: number = values?.length - 1

  //   if (e.target.files && e.target.files[0]) {
  //     setTotalFiles(e.target.files.length)

  //     for (let i = 0; i < e.target.files.length; i++) {
  //       // file upload logic here

  //       let userData: any = localStorage.getItem('userData')
  //       const data = JSON.parse(userData)

  //       var formdata = new FormData()
  //       formdata.append('image', e.target.files[i])

  //       var videoFormdata = new FormData()
  //       videoFormdata.append('video', e.target.files[i])

  //       let name: any = e.target.files[i]?.name

  //       if (e?.target?.files[i]?.type?.split('/')[0] == 'video') {
  //         setUploadingText('Video Uploading')
  //         await ApiPost(`upload/video/maintenance_service_job`, videoFormdata).then(
  //           async (resUpload: any) => {
  //             setUploadingText('Video Compressing')

  //             let compressionProgress = 0;

  //             const eventSource = new EventSource(`https://api.propertise.com/upload/video/get_stream?videoPath=${resUpload?.data?.data?.url}`);

  //             eventSource.onmessage = (event) => {
  //               const eventData = JSON.parse(event.data);
  //               const { percentageOfCompleted } = eventData;

  //               console.log(`Compression progress: ${percentageOfCompleted}%`);
  //               setPercentProgress(percentageOfCompleted)
  //               // setUploadProgress(percentageOfCompleted);

  //               newVal = newVal + 1
  //               values[newVal] = resUpload?.data?.data?.url

  //               setUploadProgress(i + 1)

  //            };

  //           }
  //         )

  //       } else {
  //         setUploadingText('Image Uploading')
  //         await ApiPost(
  //           `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
  //           formdata
  //         )
  //           .then((res) => {
  //             newVal = newVal + 1
  //             values[newVal] = res?.data?.data?.image

  //             // update the upload progress
  //             setUploadProgress(i + 1)
  //           })
  //           .catch((err) => ErrorToast(err?.message))
  //       }
  //     }
  //   }

  //   setShowLoader(false) // Hide the modal loader when file uploading finishes
  //   setUploadProgress(0) // Reset upload progress
  //   setTotalFiles(0) // Reset total files count
  //   setAttachments(values)
  // }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setShowLoader(true) // Show the modal loader when file uploading starts

    const values: any[] = [...attachments]
    let newVal: number = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // file upload logic here

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', e.target.files[i])

        var videoFormdata = new FormData()
        videoFormdata.append('video', e.target.files[i])

        let name: any = e.target.files[i]?.name

        if (e?.target?.files[i]?.type?.split('/')[0] == 'video') {
          setUploadingText('Video Uploading')

          try {
            const resUpload = await ApiPost(`upload/video/maintenance_service_job`, videoFormdata)
            setUploadingText('Video Compressing')

            await new Promise<void>((resolve, reject) => {
              const eventSource = new EventSource(
                `https://api.propertise.com/upload/video/get_stream?videoPath=${resUpload?.data?.data?.url}`
              )
              eventSource.onmessage = (event) => {
                const eventData = JSON.parse(event.data)
                const {percentageOfCompleted} = eventData
                // console.log(`Compression progress: ${percentageOfCompleted}%`)
                setPercentProgress(percentageOfCompleted)



                if (Math.round(percentageOfCompleted) == 100) {
                  eventSource.close()
                  setDisableCancel(false)
                  resolve()
                }
              }
            })

            // Compression completed, move to next iteration
            newVal = newVal + 1
            values[newVal] = resUpload?.data?.data?.url
            setUploadProgress(i + 1)
          } catch (error) {
            console.error('Error while uploading video:', error)
            // Handle error
          }
        } else {
          setUploadingText('Image Uploading')

          try {
            const res = await ApiPost(
              `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
              formdata
            )
            newVal = newVal + 1
            values[newVal] = res?.data?.data?.image

            // update the upload progress
            setUploadProgress(i + 1)
            setDisableCancel(false)
          } catch (error) {
            console.error('Error while uploading image:', error)
            // Handle error
          }
        }
      }
    }

    setShowLoader(false) // Hide the modal loader when file uploading finishes
    setUploadProgress(0) // Reset upload progress
    setTotalFiles(0) // Reset total files count
    setAttachments(values)
  }

  //
  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('YYYY-MM-DD HH:mm')
  }


  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          // updateJobDetails(comments, attachments, dataInd)
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className='head-text pt-3 mb-0'>
              <b>Jobs</b>
            </h2>

            <div className='d-flex ms-auto'>

            {showUpdateBtn &&
               <button
               type='button'
               className='btn btn-sm fw-bold  mx-3 red-hollow-btn status-w-100 ps-3 mt-3 '
               onClick={() => {
                setComment('')
                setCommentError(false)
                setAttachments(modalData?.attachments)
                setComments(modalData?.comments)

                setSelectedInd(-1)
                setDisableCancel(true)
               }}
              disabled={disableCancel}
             >
               <img src={redCross} height={18} width={18} className='me-4' /> Cancel
             </button>
              }
             
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn ps-3 status-w-100 mt-3 me-5'
                  onClick={() => {
                    updateJobDetails(comments, attachments, dataInd)
                  }}
                >
                  <img src={submitWhite} height={18} width={18} className='me-4'/> {showUpdateBtn ? 'Update' : 'Submit'}
                </button>
               


            <label
              className='head-text fs-2 cursor-pointer '
              style={{fontWeight: '700', marginRight: '-5px'}}
              onClick={() => {
                handleClose()
                // updateJobDetails(comments, attachments, dataInd)
              }}
            >
              X
            </label>
            </div>
          </div>

          {modalData?.jobIdNo && (
            <div className='d-flex align-items-center'>
              <h2 className='pt-1 mb-1' style={{color: '#146c6a', fontWeight: '600'}}>
                <b>{modalData?.jobIdNo ? modalData?.jobIdNo : '-'}</b>
              </h2>
            </div>
          )}

          {/* Attachments */}
          <div className='card card-flush py-5 mt-5 px-7'>
            <div className='d-flex'>
              <h3 className='top ps-0 head-text'>
                <b>Attachments</b> <i className='light-dark-theme-color'> (Attach images or videos of the issues)</i>
              </h3>

              {tab != 'review' && !isViewMode && (
                <>
                  <label
                    className='btn btn-sm fw-bold  me-0 blue-btn ps-3 pe-10 ms-auto btn-fit-content'
                    onClick={() => {}}
                    style={{width: 'fit-content'}}
                    htmlFor='uploadNew'
                  >
                    <img src={uploadWhite} height={18} width={18} className='me-3' />
                    Upload
                  </label>
                  <input
                    type='file'
                    hidden
                    id='uploadNew'
                    className='form-control form-control-solid'
                    placeholder='upload'
                    name='newTenant'
                    onChange={(e: any) => {
                      handleChange(e)
                    }}
                    multiple
                  />
                </>
              )}
            </div>

            <div className='d-flex flex-wrap mt-5' style={{maxHeight: '250px', overflow: 'auto'}}>
              {attachments?.length > 0 && (
                <div className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'>
                  {attachments?.map((file: any, ind: any) => {
                    const videoUrl = file
                    const filename = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
                    const filenameWithoutExtension = filename.substring(
                      0,
                      filename.lastIndexOf('.')
                    )
                    const thumbnailUrl = videoUrl.replace(
                      filename,
                      `thumbnail_${filenameWithoutExtension}.png`
                    )

                    return (
                      <>
                        <div className='imgs-set'>
                          <div className='d-flex mw-350px me-3 '>
                            {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                              <img
                                src={
                                  file?.split('.').pop() === 'jpeg' ||
                                      file?.split('.').pop() === 'jpg' ||
                                      file?.split('.').pop() === 'png' ||
                                      file?.split('.').pop() === 'svg' ||
                                      file?.split('.').pop() === 'gif'
                                    ? `${Bucket}${file}`
                                    : file?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                      file?.split('.').pop()?.toLowerCase() === 'webm' ||
                                      file?.split('.').pop()?.toLowerCase() === 'avi' ||
                                      file?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                      file?.split('.').pop()?.toLowerCase() === 'mov' ||
                                      file?.split('.').pop()?.toLowerCase() === 'flv' ||
                                      file?.split('.').pop()?.toLowerCase() === 'wmv'
                                    ? `${Bucket}${thumbnailUrl}`
                                    : getFileIcon(file)
                                }
                                // src={Bucket + file}
                                width='100'
                                height='100'
                                className='main_img mb-5 cursor-pointer'
                                data-fancybox
                                data-src={`${Bucket}${file}`}
                              />
                            {/* </a> */}
                            {tab != 'review' && !isViewMode && (
                              <img
                                src={trashImg}
                                height={15}
                                width={15}
                                className='cursor-pointer ms-2 mt-1'
                                onClick={() => {
                                  const values = [...attachments]
                                  values.splice(ind, 1)
                                  setAttachments(values)

                                  setDisableCancel(false)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </div>

          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          ></div>

          {/* Comments */}
          <div className='card card-flush py-5 mt-5 px-7'>
            <div className='d-flex'>
              <h3 className='top ps-0 head-text'>
                <b>Comments</b>{' '}
                <i className='light-dark-theme-color'> (Include job specific comments shared with the handling team)</i>
              </h3>
            </div>
            {tab != 'review' && !isViewMode && (
              <div className='d-flex mt-3'>
                <textarea
                  className='form-control form-control-solid m-0 mytest'
                  rows={3}
                  name='comment'
                  placeholder='Enter your comments here'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            )}

            {commentError && (
              <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1'>
                Please enter comment
              </p>
            )}

            {tab != 'review' && !isViewMode && (
              <div className='d-flex justify-content-center mt-5'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10 btn-fit-content'
                  onClick={() => {
                    if (comment?.trim()?.length == 0) {
                      setCommentError(true)
                    } else {
                      const userData: any = localStorage.getItem('userData')
                      const user = JSON.parse(userData)

                      let values = [...comments]
                      values[values?.length] = {
                        createdBy: {
                          _id: user?._id,
                          firstName: user?.firstName,
                          lastName: user?.lastName,
                        },
                        createdByType: 0,
                        comment: comment,
                        date: new Date(),
                      }
                      setComments(values)
                      setCommentError(false)
                      setComment('')
                      setDisableCancel(false)
                    }
                  }}
                  style={{width: 'fit-content'}}
                >
                  <img src={addWhite} height={17} width={17} className='me-3' /> Add Comment
                </button>
              </div>
            )}

            {comments?.length > 0 && (
              <>
                {comments?.map((comm: any, ind: any) => {
                  return (
                    <>
                      <div className='d-flex mt-4'>
                        <h5 className='top ps-1 head-text mb-0'>
                          <b>
                            {comm?.createdBy?.firstName} {comm?.createdBy?.lastName}
                          </b>
                        </h5>

                        <h5 className='top ps-0 ms-auto mb-0 white-dark-theme-color'>
                          {/* <b> */}
                          {comm?.date ? `${convertUTCToLocalTime(comm?.date)}` : ''}
                          {/* </b> */}
                        </h5>
                      </div>
                      <div className='d-flex mt-2'>
                        <textarea
                          className={`form-control form-control-solid m-0 ${
                            selectedInd == ind ? 'jobs-comments-edit' : 'jobs-comments'
                          } `}
                          rows={3}
                          name='comment'
                          placeholder='Enter your comments here'
                          value={selectedInd != ind ? comm?.comment : commentToEdit}
                          onChange={(e) => {setCommentToEdit(e.target.value)  
                            setDisableCancel(false)}}
                          disabled={selectedInd != ind}
                        ></textarea>
                      </div>
                      {tab != 'review' && !isViewMode && (
                        <div className='mt-2 mb-5'>
                          {selectedInd == ind ? (
                            <div className='d-flex me-1 mb-2'>
                              <img
                                src={removed}
                                height={18}
                                width={18}
                                className='ms-auto cursor-pointer'
                                onClick={() => {
                                  setSelectedInd(-1)
                                }}
                              />
                              <img
                                src={correct}
                                height={18}
                                width={18}
                                className='ms-3 cursor-pointer'
                                onClick={() => {
                                  let values = [...comments]
                                  values[ind].comment = commentToEdit
                                  setComments(values)
                                  setSelectedInd(-1)
                                  setDisableCancel(false)
                                }}
                              />
                            </div>
                          ) : (
                            <div className='me-1 mb-2 text-end'>
                              <img
                                src={pencil}
                                alt=''
                                height={18}
                                width={18}
                                className='ms-4  cursor-pointer'
                                onClick={() => {
                                  setSelectedInd(ind)
                                  setCommentToEdit(comm?.comment)
                                  setDisableCancel(false)
                                }}
                              />
                              <img
                                src={trashImg}
                                alt=''
                                height={18}
                                width={18}
                                className='ms-3 cursor-pointer'
                                onClick={() => {
                                  let values = [...comments]
                                  values.splice(ind, 1)
                                  setComments(values)
                                  setDisableCancel(false)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </Modal>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default JobDetailsModal

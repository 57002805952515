import {FC, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import chat from '../../../../../img/chat.png'
import settingIcon from '../../../../../img/settings-white.png'
import starIcon from '../../../../../img/star.png'
import {useEffect} from 'react'
import { ApiPost } from '../../../../../apiCommon/helpers/API/ApiData'
import dashboardIcon from '../../../../../img/dashboard.png'


type Props = {
  to?: any
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  message?: any
  setting?: any
  feedback?: any
  facility?: any
  clickHandler?: any
  dashboard?:any
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  message,
  setting,
  feedback,
  facility,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  clickHandler = () => {},
  dashboard
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const [disableSideBar, setDisableSideBar] = useState<any>(false)

  useEffect(() => {
    localStorage.setItem('fb_page', '1')
    let temp = localStorage.getItem('DeleteClone')
    if (temp != null) {
      let val = JSON.parse(temp)
      const body = {
        tenancyId: val?.tenancyId,
      }

      ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
        .then(() => {
          localStorage.removeItem('DeleteClone')
        })
        .catch((err: any) => {})
    }
  }, [isActive])

  const refresh = () => {
    let temp = localStorage.getItem('isFormDirty')
    // console.log("temp "+temp)

    if (temp != null) {
      if (JSON.parse(temp) == true) {
        setDisableSideBar(true)
      }
    }
    else{
      setDisableSideBar(false)

    }
  }

  useEffect(() => {
    refresh()
  }, [])

  setInterval(refresh, 5000)

  return (
    <>
      {!disableSideBar ? (
        <div className='menu-item'>
          <Link onClick={clickHandler} className={clsx('menu-link without-sub', {active: isActive})} to={to}>
            {hasBullet && (
              <span className='menu-bullet' style={{marginLeft: '1.5rem'}}>
                <span
                  className='bullet bullet-circle'
                  style={{
                    height: '7px',
                    width: '7px',
                    backgroundColor: 'transparent',
                    border: '1px solid #fff',
                  }}
                ></span>
              </span>
            )}
             {dashboard && (
              <span className='menu-icon'>
                <img src={dashboardIcon} alt='chat' width='20px' height='20px' />
              </span>
            )}
            {message && (
              <span className='menu-icon'>
                <img src={chat} alt='chat' width='20px' height='20px' />
              </span>
            )}
            {setting && (
              <span className='menu-icon'>
                <img src={settingIcon} alt='chat' width='21px' height='21px' />
              </span>
            )}
            {feedback && (
              <span className='menu-icon'>
                <img src={starIcon} alt='chat' width='20px' height='20px' />
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title text-white'>{title}</span>
          </Link>
          {children}
        </div>
      ) : (
        <div className='menu-item'>
          <div
            className={clsx('menu-link without-sub')}
            style={{opacity: '0.5', cursor: 'not-allowed'}}
          >
            {hasBullet && (
              <span className='menu-bullet' style={{marginLeft: '1.5rem'}}>
                <span
                  className='bullet bullet-circle'
                  style={{
                    height: '7px',
                    width: '7px',
                    backgroundColor: 'transparent',
                    border: '1px solid #fff',
                  }}
                ></span>
              </span>
            )}
            {message && (
              <span className='menu-icon'>
                <img src={chat} alt='chat' width='20px' height='20px' />
              </span>
            )}
            {setting && (
              <span className='menu-icon'>
                <img src={settingIcon} alt='chat' width='21px' height='21px' />
              </span>
            )}
            {feedback && (
              <span className='menu-icon'>
                <img src={starIcon} alt='chat' width='20px' height='20px' />
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title text-white'>{title}</span>
          </div>
          {children}
        </div>
      )}
    </>
  )
}

export {SidebarMenuItem}

import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import moment from 'moment'
import {formatAmount, getFileIcon} from '../../../../../Utilities/utils'
import {Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import emailImg from '../../../../../img/mailnotification.svg'

import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {useNavigate, useParams} from 'react-router-dom'

enum ActionType {
  CreateAndShare = 'CS',
  Create = 'CR',
  UpdateAndShare = 'US',
  Update = 'UP',
  None = '-',
}

interface ButtonProps {
  show: any
  handleClose: any
  receiptData: any
  isShare?: boolean
  setIsShare?: any
  isUpdate: boolean
  isCreate: boolean
  label: string
  type: string
  payor: string
  typeOfNo: string
  updateData: any
  from: string
}

const ConfirmationPopup = ({
  show,
  handleClose,
  receiptData,
  isShare,
  setIsShare,
  isCreate,
  isUpdate,
  label,
  type,
  payor = '',
  typeOfNo,
  from = 'invoice',
  updateData = () => {},
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)
  const navigate = useNavigate()
  const {id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 modal-body-without-header'
          style={{
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center justify-content-center'>
            <h2 className='mb-0 pt-1 white-dark-theme-color'>
              <b>{isCreate ? `Create ${type}` : isUpdate ? `Update ${type}` : '-'}</b>
            </h2>
          </div>

          <div
            className='d-flex align-items-center'
            style={{height: '0px', marginTop: '-10px'}}
          >
            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          <div className='d-flex align-items-center justify-content-center text-center mx-3 mt-7'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500 light-dark-theme-color'>
              {/* {`Once the ${type?.toLowerCase()} is ${
                isCreate ? 'created' : 'updated'
              }, the credit amount will directly reflect in
              your tenant’s outstanding balance.`} */}
              {from === 'invoice' && isCreate
                ? 'Once the invoice is created, a payment will be created and your tenant will be charged with the amount entered in the invoice.'
                : from === 'invoice' && isUpdate
                ? 'Once the invoice is updated, the updated details will directly reflect on your tenant’s account on the Tenant Portal.'
                : from === 'receipt' && isCreate
                ? 'Once the receipt is issued, a payment will be recorded against the invoice that was selected and will reflect In your tenant outstanding balance.'
                : from === 'receipt' && isUpdate
                ? 'Once the receipt is updated, the updated details will directly reflect on your tenant’s account on the Tenant Portal.'
                : from === 'credit' && isCreate ? "Once the credit note is created, the credit amount will directly reflect in your tenant's outstanding balance."
                : from === 'credit' && isUpdate ? "Once the credit note is updated, the updated details will directly reflect on your tenant's account on the Tenant Portal."
                : from === 'debit' && isCreate ? "Once the debit note is created, the debit amount will directly reflect in your tenant’s outstanding balance."
                : from === 'debit' && isUpdate ? "Once the debit note is created, the debit amount will directly reflect in your tenant’s outstanding balance."
                : ''}
            </p>
          </div>

          <div className='d-flex align-items-center justify-content-center text-center mx-5 mt-4'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500 light-dark-theme-color'>
              {/* {` A copy of your ${type?.toLowerCase()} will be shared with your tenant on the tenant
              portal.`} */}
              {from === 'invoice' && isCreate
                ? 'A copy of your invoice will be shared with your tenant on the tenant portal.'
                : from === 'invoice' && isUpdate
                ? 'Would you like to notify the recipient and share a copy of the updated invoice via email?'
                : from === 'receipt' && isCreate
                ? 'A copy of the receipt will be shared with your tenant on the tenant portal.'
                : from === 'receipt' && isUpdate
                ? 'Would you like to notify the recipient and share a copy of the updated receipt via email?'
                : from === 'credit' && isCreate ? "A copy of your credit note will be shared with your tenant on the tenant portal."
                : from === 'credit' && isUpdate ? "Would you like to notify the recipient and share a copy of the updated credit note via email?"
                : from === 'debit' && isCreate ? "A copy of your debit note will be shared with your tenant on the tenant portal."
                : from === 'debit' && isUpdate ? "A copy of your debit note will be shared with your tenant on the tenant portal."
                : ''}
            </p>
          </div>

          <div className='d-flex  align-items-center mt-7 mx-7'>
            <p className='mb-0 fnt-500 fs-4 light-dark-theme-color'>
              {'Payor:   '}
              <span className='mb-0 ms-2 fnt-700 white-dark-theme-color'> {payor ? payor : '-'}</span>
            </p>{' '}
            <p className='mb-0 fnt-500 fs-4 ms-auto light-dark-theme-color'>
              {`${from === 'invoice' ? 'Invoice' : from === 'receipt' ? 'Receipt' : from === 'credit' ? 'Credit Note' : from === 'debit' ? 'Debit Note' : ''}:   `}
              <span className='mb-0 ms-2 fnt-700 white-dark-theme-color'> {typeOfNo ? typeOfNo : '-'}</span>
            </p>{' '}
          </div>

          <div className='separator my-3 mt-5'></div>

          {/*  */}
          <div className='d-flex align-items-center  mx-5 mt-4'>
            <p className='mb-0 pt-1 labl-gry fs-4 fnt-500'>Share {type} via:</p>
          </div>

          {/*  */}
          <div className='d-flex  align-items-start mt-7 mx-5 test'>
            <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2 mt-2'>
              <input
                className='form-check-input me-3 cursor-pointer'
                type='checkbox'
                id='vacant0'
                name='none'
                checked={isShare}
                onChange={(e: any) => {
                  setIsShare(!isShare)
                }}
              />
            </div>

            <div className='position-relative'>
              {!isShare && (
                <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
              )}
              <div>
                <p className='mb-0 fnt-500 fs-4 mt-2 white-dark-theme-color'>{'Email'}</p>{' '}
                <p className='mb-0 pt-2 labl-gry fs-4 mt-4 light-dark-theme-color'>
                  <i>
                    Email will be sent from <u>(finance@harmonyre.ae)</u>.
                  </i>
                </p>
              </div>
            </div>
            <div className='position-relative ms-auto'>
              {!isShare && (
                <div className='requestBoxOpacity z-index-999 border-radius-5px'></div>
              )}

              <div className='ms-auto text-center'>
                <img src={emailImg} height={32} width={32} />
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn px-5 btn-fit-content mt-2'
                  onClick={() => {
                    // navigate(`/finance-income/share/${type.toLowerCase()}-template/${id}`)
                    const newWindowUrl = `/finance-income/share/${type.toLowerCase()}-template/${id}`;
                    window.open(newWindowUrl, '_blank', 'noopener,noreferrer');
                  }}
                  disabled={false}
                >
                  View Email
                </button>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='d-flex  align-items-center justify-content-center text-center my-15 mx-7'>
            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 status-w-200  d-flex  align-items-center justify-content-center text-center'
              onClick={() => {
                if (isCreate && isShare) {
                  updateData(ActionType.CreateAndShare)
                } else if (isCreate && !isShare) {
                  updateData(ActionType.Create)
                } else if (isUpdate && isShare) {
                  updateData(ActionType.UpdateAndShare)
                } else if (isUpdate && !isShare) {
                  updateData(ActionType.Update)
                } else {
                  updateData(ActionType.None)
                }
              }}
              disabled={false}
            >
              {isCreate && isShare
                ? 'Create & Share'
                : isCreate && !isShare
                ? 'Create'
                : isUpdate && isShare
                ? 'Update & Share'
                : isUpdate && !isShare
                ? 'Update'
                : '-'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ConfirmationPopup

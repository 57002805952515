import React, {useState} from 'react'
import { Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import creditNoteBlue from '../../../img/credit-note-blue.png'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import creditNoteWhite from '../../../img/credit-note-white.png'
import ViewCreditNote from './ViewCreditNote'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  creditNotes: any
  from: any
}

const ViewAllCreditNotes = ({
  handleClose,
  show,
  paymentId,
  tenantDetails,
  creditNotes,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewCreditNote, setViewCreditNote] = useState<any>(false)
  const [creditNoteToView, setCreditNoteToView] = useState<any>()

  const getCreditNoteById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_credit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_credit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setCreditNoteToView(res?.data?.data)
        setViewCreditNote(true)
      })
      .catch((err: any) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Credit Notes
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '45px'}}>
              <img src={creditNoteWhite} height={80} width={80} className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3 pt-2'>
            {creditNotes?.length > 0 &&
              creditNotes.map((cr: any, index: any) => (
                <div className='img-sets mw-350px col-2 mt-2 ps-2 text-center'>
                  <div className='text-center'>
                    <a
                      onClick={() => {
                        if (
                          ((from == 'tenancy' || from == 'terminate-tenancy') &&
                            ((user?.isSubUser &&
                              role?.leasing_residential?.lease_financial?.credit_notes
                                ?.view_details) ||
                              !user?.isSubUser)) ||
                          from == 'propertyProfile' ||
                          from == 'tenant' ||
                          from == 'financials'
                        )
                          getCreditNoteById(cr?._id)
                        else
                          ErrorToast(
                            `You don't have the permissions to view Credit Note details...`
                          )
                      }}
                      style={{color: '#384a62'}}
                      className='d-flex justify-content-center'
                    >
                      <img
                        src={creditNoteBlue}
                        width={50}
                        height={50}
                        className='main_img cursor-pointer ms-2'
                      />
                    </a>
                    <p
                      style={{
                        height: '15px',
                        fontSize: '0.9rem',
                        wordBreak: 'break-all',
                      }}
                      className='mt-1 text-center'
                    >
                      {cr?.creditNoteNo}{' '}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {/* View Single Credit Note */}
      {viewCreditNote && (
        <ViewCreditNote
          show={viewCreditNote}
          handleClose={() => {
            setViewCreditNote(false)
          }}
          creditNote={creditNoteToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={''}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewAllCreditNotes

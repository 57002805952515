import React, { useState, useEffect } from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import { MultiSelect } from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'


import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import { SuccessToast, ErrorToast } from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, Space } from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import { setComefrom } from '../../../../redux/counterSlice'

import townhouse from '../../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import villa from '../../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import backArrow from '../../../../img/back-arrow.png'
import allBuildingBlue from '../../../../img/allBuildingBlue.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import PropertiesList from './PropertiesList'
import AddFloorModel from "./AddFloorModel"
import CommonAreaList from './CommonAreaList'

interface SquareHandleProps {
    value: number
}
interface ButtonProps {
    type?: any
}

const CommunityGroupFloorDetailList = ({ type }: ButtonProps) => {
    const getAllBuildingDetailSingleFloorUnitFilter: any = localStorage.getItem('allBuildingDetailSingleFloorUnitFiltersForCommunityGroupFloor')
    const buildingDetailSingleFloorUnitFilter = JSON.parse(getAllBuildingDetailSingleFloorUnitFilter)

    const getSubActiveTab: any = localStorage.getItem('subTabListItemForSingleUnitForCommunityGroupFloor')
    const subTab = JSON.parse(getSubActiveTab)

    const getFloorDetailAllUnitsDate: any = localStorage.getItem('floorDetailAllUnitsDateFilterForCommunityGroupFloor')
    const floorDetailAllUnitsDate = JSON.parse(getFloorDetailAllUnitsDate) ?? {}

    const getFloorDetailCommonAreaDate: any = localStorage.getItem('floorDetailCommonAreaDateFilterForCommunityGroupFloor')
    const floorDetailCommonAreaDate = JSON.parse(getFloorDetailCommonAreaDate) ?? {}

    const [activeTab, setActiveTab] = useState<any>('all-properties')
    const [propertiesCount, setPropertiesCount] = useState<any>()
    const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
    const [subTabListItem, setSubTabListItem] = useState<any>(subTab ? subTab : 'allUnits')

    const [searchCommonArea, setSearchCommonArea] = useState<any>(buildingDetailSingleFloorUnitFilter?.searchCommonArea ? buildingDetailSingleFloorUnitFilter?.searchCommonArea : '')
    const [searchUnit, setSearchUnit] = useState<any>(buildingDetailSingleFloorUnitFilter?.searchUnit ? buildingDetailSingleFloorUnitFilter?.searchUnit : '')

    const [searchValue, setSearchValue] = useState<any>(buildingDetailSingleFloorUnitFilter?.searchUnit?? '')
    const [searchValueCommonArea, setSearchValueCommonArea] = useState<any>(buildingDetailSingleFloorUnitFilter?.searchCommonAreaUnit  ?? '')
    
    const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
    const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
    const [showFilter, setShowFilter] = useState<any>(false)

    const [requestTabTableData, setRequestTabTableData] = useState<any>([])
    const [requestTabItem] = useState<any>([])
    const navigate = useNavigate()
    const location: any = useLocation()

    const isBuilding: any = location.state ? location?.state?.key === 'floors' : false
    const floorDetail: any = location?.state

    const goToMiantenance = (v: any) => {
        if(subTabListItem === 'allUnits' ||
            subTabListItem === 'residentialUnits' ||
            subTabListItem === 'commercialUnits' ||
            subTabListItem === 'commonAreas'){
            navigate(`/property-profile/${v?._id}`,
              {
                state: {
                  from:`buildingGroupBuildingFloor`,
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  buildingName: location?.state.buildingName,
                  buildingId: location?.state.buildingId,
                  area: v?.community[0]?.area,
                  city: v?.community[0]?.city,
                  street: v?.community[0]?.street,
                  isCommonArea:v?.unitType == 2 ? true : false
                },
              }
            )
          }
    }

    // models
    const [addFloorModel, setAddFloorModel] = useState<any>(false)
    const [isEditFloorModel, setIsEditFloorModel] = useState<any>(false)
    const [modelData, setModelData] = useState<any>({
        floorName: location?.state?.floorName,
        floorId: location?.state?.floorId
    })

    // date range
    const [start_date, setStart] = useState<any>(null)
    const [end_date, setEnd] = useState<any>(null)
    const [startDatePayload, setStartDatePayload] = useState<any>(null)
    const [endDatePayload, setEndDatePayload] = useState<any>(null)
    const [isFormDirty, setIsFormDirty] = useState(false)


    function formatDate(date: any) {
        const day = ('0' + date.getDate()).slice(-2); // Get day with leading zero if needed
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get month with leading zero if needed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function generateDates() {
        // Create start date for the year (1st January)
        const currentDate = new Date();
        const year: any = currentDate.getFullYear();
        const startDate: any = new Date(year, 0, 1);

        // Create end date for the year (31st December)
        const endDate = new Date(year, 11, 31);

        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        if (subTabListItem === 'allUnits' && Object.keys(floorDetailAllUnitsDate).length > 0) {
            if (Object?.values(floorDetailAllUnitsDate).every(x => x !== null || x !== '')) {

                setStart(moment(floorDetailAllUnitsDate?.startDate));
                setEnd(moment(floorDetailAllUnitsDate?.endDate));
                setStartDatePayload(moment(floorDetailAllUnitsDate?.startDate)?.format('YYYY-MM-DD'))
                setEndDatePayload(moment(floorDetailAllUnitsDate?.endDate)?.format('YYYY-MM-DD'))
            }
        } else if (subTabListItem === 'commonAreas' && Object.keys(floorDetailCommonAreaDate).length > 0) {
            if (Object?.values(floorDetailCommonAreaDate).every(x => x !== null || x !== '')) {

                setStart(moment(floorDetailCommonAreaDate?.startDate));
                setEnd(moment(floorDetailCommonAreaDate?.endDate));
                setStartDatePayload(moment(floorDetailCommonAreaDate?.startDate)?.format('YYYY-MM-DD'))
                setEndDatePayload(moment(floorDetailCommonAreaDate?.endDate)?.format('YYYY-MM-DD'))
            }
        } else {
            setStart(moment(formattedStartDate, 'DD/MM/YYYY'));
            setEnd(moment(formattedEndDate, 'DD/MM/YYYY'));
            setStartDatePayload(moment(formattedStartDate)?.format('YYYY-MM-DD'))
            setEndDatePayload(moment(formattedEndDate)?.format('YYYY-MM-DD'))
        }
        // setStart(moment(formattedStartDate, 'DD/MM/YYYY'));
        // setEnd(moment(formattedEndDate, 'DD/MM/YYYY'));
        // setStartDatePayload(moment(startDate)?.format('YYYY-MM-DD'))
        // setEndDatePayload(moment(endDate)?.format('YYYY-MM-DD'))

    }

    const handleDatesChange = ({
        startDate,
        endDate,
    }: {
        startDate: moment.Moment | null
        endDate: moment.Moment | null
    }) => {
        if (startDate == null && endDate == null) {
            setStart(null)
            setStartDatePayload(null)
            setEnd(null)
            setEndDatePayload(null)
        } else {
            setStart(startDate)
            setStartDatePayload(startDate?.format('YYYY-MM-DD'))
            setEnd(endDate)
            setEndDatePayload(endDate?.format('YYYY-MM-DD'))

            setIsFormDirty(true)
        }
    }



    // pagination
    const [page, setPage] = useState<any>(1)
    const [pageLimit, setPageLimit] = useState<any>(1)
    const [isSkeleton, setIsSkeleton] = useState<any>(false)
    const [isLoading, setIsLoading] = useState<any>(false)
    const [singleFloorDetail, setSingleFloorDetail] = useState<any>([])
    const [allUnitTableData, setAllUnitTableData] = useState<any>([])
    const [limit, setLimit] = useState<any>(25)
    const [unitCountSummery, setUnitCountSummary] = useState<any>()

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
        getAllUnitsTableData(event.selected + 1)
    }

    const unitCountSummary = async () => {
        const body: any = {
            isAllPropertise: false,
            isForBuildingDevelopment: false,
            isForCommunityDevelopment: true,
            unit: {
                propertyType: subTabListItem === 'allUnits' ? [0, 1] : subTabListItem === 'residentialUnits' ? [0] : subTabListItem === 'commercialUnits' ? [1] : subTabListItem === 'commonAreas' ? [0, 1] : [],
                unitType: subTabListItem === 'allUnits' ? [0, 1, 3, 4, 5] : subTabListItem === 'residentialUnits' ? [0, 1, 3, 4, 5] : subTabListItem === 'commercialUnits' ? [0, 1, 3, 4, 5] : subTabListItem == 'commonAreas' ? [2] : [],
                searchUnitNo: subTabListItem === 'allUnits' ? searchUnit : subTabListItem == 'commonAreas' ? searchCommonArea : '',
            },
            cluster: {
                type: [2],
            },
            clusterIds:[location?.state?.clusterId],
            communityDevelopmentIds: [location?.state?.communityId],
            buildingIds: [location?.state?.groupId],
            unitGroupIds : [],
            floorIds: [location?.state?.floorId],
            startDate: startDatePayload ? startDatePayload : null,
            endDate: endDatePayload ? endDatePayload : null,
        }

        await ApiPost('corporate/development/properties/unit_count', body).then((res) => {
            setUnitCountSummary(res?.data?.data)
        })
            .catch((err) => ErrorToast(err.message))
    }
    const getAllUnitsTableData = async (pg = 1) => {
        setIsLoading(true)
        setAllUnitTableData([])

        let body = {
            page: pg,
            limit: limit,
            startDate: startDatePayload,
            endDate: endDatePayload,
            searchUnitNo: subTabListItem === 'allUnits' ? searchUnit : subTabListItem == 'commonAreas' ? searchCommonArea : '',
            propertyTypes: [0, 1],
            unitTypes: subTabListItem === 'allUnits' ? [0, 1, 3, 4, 5] : subTabListItem == 'commonAreas' ? [2] : [],
            buildingIds: [`${location?.state?.groupId}`],
            communityIds: [`${location?.state?.communityId}`],
            floorIds: [`${location?.state?.floorId}`],
            clusterIds: [`${location?.state?.clusterId}`],
            unitGroupIds: [],
        }

        await ApiPost('corporate/unit/get_properties', body)
            .then((res) => {
                setAllUnitTableData(res?.data?.data?.unit_data)
                setPageLimit(res?.data?.data?.state?.page_limit)

                setTimeout(() => {
                    setIsLoading(false)
                }, 2000)
            })
            .catch((err) => {
                setIsLoading(false)
                ErrorToast(err.message)
                if (err?.message === 'Your account has been suspended!') {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }

    useEffect(() => {
        unitCountSummary()
      },[startDatePayload,endDatePayload, subTabListItem,searchUnit,searchCommonArea])

    useEffect(() => {
        setPage(1)
        generateDates()
    }, [subTabListItem])

    useEffect(() => {
        setPage(1)
        getAllUnitsTableData(1)
    }, [ searchUnit, searchCommonArea, startDatePayload, endDatePayload, activeTab, subTabListItem])

    useEffect(() => {
        const filters = {
            searchUnit: searchUnit,
            searchCommonArea: searchCommonArea
        }
        if (subTabListItem === 'allUnits' && start_date !== null && end_date !== null) {
            const dateRange = {
                startDate: start_date,
                endDate: end_date,
            }
            localStorage.setItem('floorDetailAllUnitsDateFilterForCommunityGroupFloor', JSON.stringify(dateRange))
        }
        if (subTabListItem === 'commonAreas' && start_date !== null && end_date !== null) {
            const dateRange = {
                startDate: start_date,
                endDate: end_date,
            }
            localStorage.setItem('floorDetailCommonAreaDateFilterForCommunityGroupFloor', JSON.stringify(dateRange))
        }
        localStorage.setItem('allBuildingDetailSingleFloorUnitFiltersForCommunityGroupFloor', JSON.stringify(filters))
        localStorage.setItem('subTabListItemForSingleUnitForCommunityGroupFloor', JSON.stringify(subTabListItem))

    }, [
        subTabListItem,
        searchCommonArea,
        searchUnit,
        start_date,
        end_date

    ])

    const searchContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        borderRadius: '25px',
        // marginLeft: '10px',
    }

    const searchIconStyle = {
        padding: '10px',
        color: 'gray',
    }

    const inputBaseStyle = {
        flex: 1,
        padding: '5px',
    }

    // React Select
    const customStyles = {
        container: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '50%',
        }),

        option: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            color: state.isSelected ? '#fff' : '#000;',
            backgroundColor: state.isSelected ? '#007a59;' : '',
            ':hover': {
                backgroundColor: '#e0f5f0',
                color: '#000',
            },

            fontWeight: '500',
        }),

        menu: (defaultStyles: any) => ({
            ...defaultStyles,
            border: '0.1rem solid #007a59 ',
            marginTop: '0px',
            borderRadius: '0px',
            zIndex: '1000',
        }),

        placeholder: (defaultStyles: any) => ({
            ...defaultStyles,
            fontStyle: 'Italic',
            zIndex: '1',
        }),

        input: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            backgroundColor: '#f5f8fa',
            color: ' #b5b5c3',
            fontWeight: '500',
            minWidth: '55px',
        }),

        control: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            backgroundColor: '#f5f8fa',
            boxShadow: 'none',
            color: 'white',
            border: '0.1rem solid #bec3cb',
            borderRadius: '2px',
            padding: '0rem',
            ':hover': {
                borderColor: '#007a59',
                backgroundColor: '#fff',
            },
            ':focus': {
                borderColor: '#007a59 !important',
                backgroundColor: '#fff',
            },
            '::placeholder': {
                fontStyle: 'Italic',
            },
        }),
        singleValue: (defaultStyles: any) => ({
            ...defaultStyles,
            color: '#5e6278',
            fontWeight: '600',
            zIndex: '1',
        }),
        valueContainer: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            // display: 'flex',
            flexWrap: 'nowrap',
            maxWidth: '100%',
            overflowX: 'scroll',
        }),
        multiValue: (defaultStyles: any, state: any) => ({
            ...defaultStyles,
            whiteSpace: 'nowrap',
            marginRight: '4px',
            minWidth: '55px',
        }),
    }

    
    const goToEdit = (listItem:any) => {  
        navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state?.floorId}/edit-property/${listItem?._id}`,
        {
            state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                groupName: location?.state?.groupName,
                groupId: location?.state?.groupId,
                groupType: location?.state?.groupType,
                floorName:location?.state?.floorName,
                floorId:location?.state?.floorId,
                area:location?.state?.area,
                city:location?.state?.city
            }
        }
    )     
      }

    //   console.log(location?.state?.area)

    useEffect(() => {
        const handler = setTimeout(() => {
          if(subTabListItem == 'allUnits') {
            setSearchUnit(searchValue);
          }
          if(subTabListItem == 'commonAreas'){
            setSearchCommonArea(searchValueCommonArea)
          }
        }, 500); // delay time in milliseconds
      
        return () => {
          clearTimeout(handler);
        };
      }, [searchValue,searchValueCommonArea]);

    return (
        <>
            <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
                <div className='row d-flex align-items-center'>
                    <div className='col-lg-6'>
                        <div className="row d-flex align-items-center">
                            <div className='col-7'>
                                <div className='d-flex align-items-center mb-1'>
                                    <span
                                        className='rounded-circle'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}`,
                                                {
                                                    state: {
                                                        type: location?.state?.type,
                                                        clusterName: location?.state?.clusterName,
                                                        communityId: location?.state?.communityId,
                                                        clusterId: location?.state?.clusterId,
                                                        communityName: location?.state?.communityName,
                                                        groupName: location?.state?.groupName,
                                                        groupId: location?.state?.groupId,
                                                        groupType: location?.state?.groupType,
                                                        floorName: location?.state?.floorName,
                                                        floorId: location?.state?.floorId,
                                                        area:location?.state?.area,
                                                        city:location?.state?.city
                                                    }
                                                }
                                            )
                                        }}
                                    >
                                        <img src={backArrow} height='14' width='14' className='me-2' />
                                        {/* <img src={allBuildingBlue} width='40' /> */}
                                        <img src={floorsBlue} width='40' />
                                    </span>
                                    <h2 className='m-0 head-text ms-2'>
                                        {modelData?.floorName ? `${modelData?.floorName}` : `-`}
                                    </h2>

                                </div>
                            </div>
                            {/* <div className="col-5">
                                <img style={{cursor:'pointer'}} src={editPen} width='20' onClick={() => {
                                    setAddFloorModel(true)
                                    setIsEditFloorModel(true)
                                }} />
                            </div> */}

                        </div>

                    </div>
                    <div className='col-lg-6 d-flex justify-content-end'>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center">
                                <img src={allBuildingBlue} width='40' className='me-2' />
                                <h2 className='m-0 head-text ms-2'>
                                    {location?.state?.groupName ? `${location?.state?.groupName}` : `-`}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className='d-flex align-items-start pb-5 mb-5'
                    style={{ borderBottom: '0.1rem solid #bec3cb' }}
                ></div>

                {/* sub tabs start */}

                <div className=' ps-0 row mt-2'>
                    <div className='col-9'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
                            <li className='nav-item'>
                                <a
                                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'allUnits' && 'active'
                                        }`}
                                    data-bs-toggle='tab'
                                    onClick={() => {
                                        setSubTabListItem('allUnits')
                                        // localStorage.setItem('vendorActiveTab', 'info')
                                    }}
                                >
                                    All Units
                                </a>
                            </li>


                            <li className='nav-item'>
                                <a
                                    className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commonAreas' && 'active'
                                        }`}
                                    data-bs-toggle='tab'
                                    onClick={() => {
                                        setSubTabListItem('commonAreas')
                                        // localStorage.setItem('vendorActiveTab', 'requests')
                                    }}
                                >
                                    Common Areas
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className='col-lg-3'>
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-end">
                                <button
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                                    onClick={() => {

                                        navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state?.floorId}/add-property`,
                                        {
                                            state: {
                                                type: location?.state?.type,
                                                clusterName: location?.state?.clusterName,
                                                communityId: location?.state?.communityId,
                                                clusterId: location?.state?.clusterId,
                                                communityName: location?.state?.communityName,
                                                groupName: location?.state?.groupName,
                                                groupId: location?.state?.groupId,
                                                groupType: location?.state?.groupType,
                                                floorName: location?.state?.floorName,
                                                floorId: location?.state?.floorId,
                                                area:location?.state?.area,
                                                city:location?.state?.city
                                            }
                                        }
                                    )
                                    }}
                                >
                                    <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* sub tabs end */}

                {/* ingights start and filter */}
                <div className='row mt-7'>
                <div className='col-12'>
          <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{ gap: '20px' }}>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Units</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={unitsBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{((unitCountSummery?.occupiedUnitCount ? unitCountSummery?.occupiedUnitCount : 0)  + (unitCountSummery?.vacantUnitCount ? unitCountSummery?.vacantUnitCount :0))}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Tenants</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={tenantBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenantCount ?? 0}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Leases</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={leasesBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenancyCount ?? 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Occupancy</h4>
              <div className='d-flex justify-content-start align-items-top'>
                <img
                  src={occupancyBlue}
                  height={45}
                  width={45}
                  className='me-3'
                />
                <h4 className='fw-bold ms-2' style={{ marginTop: '15px' }}>{unitCountSummery?.occupancyRate >= 0 ? `${Math.round(unitCountSummery?.occupancyRate)}%` : 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Income</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#00b8a9' }}>AED</h4>
                <h4 className='fw-bold'>
                {`${parseFloat(unitCountSummery?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                </h4>
                
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Expense</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#e46464 ' }}>AED</h4>
                <h4 className='fw-bold'>
                  {/* {unitCountSummery?.expensePaymentCount ?? 0} */}
                  {`${parseFloat(unitCountSummery?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}

                  </h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px'}}>
              <h4 className='text-gray-500'>Rating</h4>
              <div className='d-flex justify-content-between align-items-end'>
                <img
                  src={yellowStar}
                  height={35}
                  width={35}
                  className='me-3 mt-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.ratingCount ?? 0}</h4>
              </div>

            </div>
          </div>
        </div>
                    <div className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5' style={{ gap: '0px' }}>
                        <div className={`d-flex align-items-center  px-0 col-10`} >
                            <div className='xyz me-5 pe-3'>
                                <label className='head-text' style={{ fontWeight: '600' }}>
                                    {' '}
                                    Search{' '}
                                </label>
                            </div>


                            {(subTabListItem !== 'commonAreas') && <div className='xyz me-4'>

                                <Paper component='form' style={searchContainerStyle}>
                                <InputBase
                      placeholder='Unit'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Unit'}}
                      value={searchValue}
                      onChange={(e: any) => setSearchValue(e.target.value)}
                    />
                                    <IconButton style={searchIconStyle} aria-label='Unit'>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>

                            </div>}

                            {(subTabListItem === 'commonAreas') && <div className='xyz me-4'>

                                <Paper component='form' style={searchContainerStyle}>
                                <InputBase
                      placeholder='Common Area'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'Common Area'}}
                      value={searchValueCommonArea}
                      onChange={(e: any) => setSearchValueCommonArea(e.target.value)}
                    />
                                    <IconButton style={searchIconStyle} aria-label='Common Area'>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>

                            </div>}
                            <div className='test property me-4'>
                                <CustomDateRangePicker
                                    startDate={start_date}
                                    endDate={end_date}
                                    dateStatuses={[]}
                                    onDatesChange={handleDatesChange}
                                    //  datesToCompare={datesToCompare}
                                    autoSave={() => {


                                    }}
                                    from={'create'}
                                    disabled={false}
                                />
                            </div>
                        </div>

                        <div className={`px-0 col-2 d-flex justify-content-end`}>
                            {subTabListItem === 'floors' && <div className=''>
                                <button
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn'
                                    onClick={() => {
                                        setAddFloorModel(true)
                                    }}
                                >
                                    <img src={addWhiteIcon} height={18} width={18} className='me-5' /> Add Floor
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
                {/* ingights end and fitlers*/}

                {(subTabListItem == 'allUnits') && (
                    <PropertiesList isFloorCol={true} goToEdit={goToEdit} isEditBtn={true} listData={allUnitTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
                )}

                {(subTabListItem === 'commonAreas') && <CommonAreaList goToEdit={goToEdit} isEditBtn={true} listData={allUnitTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />}

            </div>

            {addFloorModel && (
                <AddFloorModel
                    // floorName={location?.state?.floorName}
                    // floorId={location?.state?.buildingId}
                    buildingId={location?.state?.groupId}
                    isBuilding={true}
                    buildingName={location?.state?.groupName}
                    show={addFloorModel}
                    isEdit={isEditFloorModel}
                    modelData={modelData}
                    isUnitCluster={false}
                    isMixedCluster={false}
                    handleClose={() => {
                        setAddFloorModel(false)
                        getAllUnitsTableData()
                    }}
                    updateModelData={(updatedData: any) => {
                        setModelData(updatedData)
                    }}
                />
            )}

        </>

    )
}

export default CommunityGroupFloorDetailList

import React, {useState, useEffect, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import DatePicker from 'antd/es/date-picker'
import {MultiSelect} from 'react-multi-select-component'
import {CustomValueRenderer, formatAmount, invertOutstandingAmount} from '../../../../../../Utilities/utils'
import {CircularProgress} from '@mui/material'

interface SelectInvoiceProps {
  slctdInvoices: any
  updateCheckedInvoices: (val: any) => void // Function type, not void
  updateSelectedInvoice?: (val: any) => void // Function type, not void
  rcptData?: any
  from?: string
}

const SelectInvoice: React.FC<SelectInvoiceProps> = ({
  slctdInvoices,
  updateCheckedInvoices,
  updateSelectedInvoice = () => {},
  rcptData,
  from
}) => {
  const  {flg} = useParams()
  const navigate = useNavigate()
  const [searchInvoice, setSearchInvoice] = useState<any>('')
  const [searchPayor, setSearchPayor] = useState<any>('')
  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')
  const [searchContractNo, setSearchContractNo] = useState<any>('')
  const [invoices, setInvoices] = useState<any>([])
  const [checkedInvoices, setCheckedInvoices] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])
  const [invoiceDate, setInvoiceDate] = useState<any>(null)

  const [isLoading, setIsLoading] = useState<any>(false)

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(10)

  const observerRef = useRef<IntersectionObserver | null>(null)
  const endOfListRef = useRef<HTMLDivElement | null>(null)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<any>([])
  const [paymentStatusOptions, setPaymentStatusOptions] = useState<any>([
    {
      label: 'Unpaid',
      value: '0',
    },
    {
      label: 'Partially Paid',
      value: '2',
    },
    {
      label: 'Paid',
      value: '1',
    },
  ])

  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState<any>([])
  const [paymentCategoryOptions, setPaymentCategoryOptions] = useState<any>([
    {
      label: 'Overdue',
      value: '1',
    },
    {
      label: 'Upcoming',
      value: '0',
    },
    {
      label: 'Deferred',
      value: '2',
    },
    // {
    //   label: 'Complete',
    //   value: '3',
    // },
  ])

  // 
  const getAllInvoices = () => {
    setIsLoading(true)
    let categories: any = []
    selectedPaymentCategory?.map((ct: any, index: number) => {
      categories?.push(Number(ct?.value))
    })

    let paymentStatus: any = []
    selectedPaymentStatus.map((ps: any, index: number) => {
      paymentStatus?.push(Number(ps?.value))
    })

    let body: any = {
      // page: page,
      // limit: limit,
      searchInvoice: searchInvoice,
      // searchTenant: searchPayor,
      // searchContractNo: searchContractNo,
      // searchDevelopment: searchDevelopment,
      // searchUnitNo: searchUnit,
      // isForReceipt: true,
      invoiceDate: invoiceDate,
      categories: categories?.length == 0 ? [0,1,2] : categories,
      status: paymentStatus,
      tenantIds: [rcptData?.payorData?._id],
      tenancyIds: (from == 'credit note' || from == 'debit note') ? [rcptData?.tenancyData?._id] : undefined,
      formStatus: [1]
    }

    ApiPost(`corporate/payment_invoice/get`, body)
      .then((res) => {
        if(from == 'credit note' ){
          res?.data?.data.payment_invoice_data?.map((inv:any) => {
            inv?.paymentSubItems?.map((itm:any) => {
              itm.addedCredit = {
                creditRateAmount : null,
                quantity : null,
                creditVATAmount:null,
                totalCreditAmount: null
  
              }
              // itm.creditAmount = null
              itm.isSelected = false
            
            })
          })
        }else if(from === 'debit note'){
          res?.data?.data.payment_invoice_data?.map((inv:any) => {
            inv?.paymentSubItems?.map((itm:any) => {
              itm.addedDebit = {
                debitRateAmount : null,
                quantity : null,
                debitAmount: null,
                debitVATAmount:null,
                totalDebitAmount: null
  
              }
              // itm.debitAmount = null
              itm.isSelected = false
            })
          })
        }
        
        // console.log(res?.data?.data?.payment_invoice_data)
        setInvoices(res?.data?.data?.payment_invoice_data)

        let invVal: any = res?.data?.data?.payment_invoice_data
        let chkVal: any = []

        res?.data?.data?.payment_invoice_data?.map(() => {
          chkVal?.push(false)
        })

        let newInvoices : any = []
        

        slctdInvoices?.map((sl: any, indOut: number) => {
          res?.data?.data?.payment_invoice_data?.map((inv: any, indIn: any) => {
            if (sl?._id == inv?._id) 
            {
              invVal?.splice(indIn, 1)
            }

          })
        })


        setInvoices(invVal)
        setCheckedValues(chkVal)

        setIsLoading(false)
        
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (rcptData?.payorData?._id?.trim()?.length != 0 && from == 'receipt') 
      getAllInvoices()

    if((rcptData?.tenancyData?._id?.trim()?.length != 0 && rcptData?.tenancyData?._id?.trim()?.length != undefined)  && (from == 'credit note' || from == 'debit note'))
      getAllInvoices()
    else
      setInvoices([])
  }, [
    rcptData,
    searchInvoice,
    invoiceDate,
    selectedPaymentStatus,
    selectedPaymentCategory,
    slctdInvoices,
  ])

  const handleDateChange = (date: any) => {
    if (date != null) {
      setInvoiceDate(date.format('YYYY-MM-DD'))
    } else {
      setInvoiceDate(null)
    }
  }

  const [totalSelected, setTotalSelected] = useState<any>(0)

  return (
    <>
      {/* Select Invoices Card */}
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 min-h-350'}>
        <div className='d-flex  align-items-start'>
          <div>
            <h2 className='white-dark-theme-color' style={{fontWeight: '700'}}>
              {'Select Invoice'}
            </h2>

            <div className='d-flex  align-items-center'>
              <h3 className='mb-0 fnt-500 me-5 light-dark-theme-color' >
                {'Payor:   '}
                <span className='mb-0 ms-2 fnt-700 white-dark-theme-color'>
                  {' '}
                  {rcptData?.payorData?.firstName} {rcptData?.payorData?.lastName}
                </span>
              </h3>{' '}

              {from != 'receipt' &&
              <h3 className='mb-0 fnt-500 light-dark-theme-color'>
                {'Contract No. :   '}
                <span className='mb-0 ms-2 fnt-700 white-dark-theme-color'>
                  {' '}
                  {rcptData?.tenancyData?.contractNo} 
                </span>
              </h3>
               }
            </div>

            <p className='user-head mb-2 fs-5 pt-2 light-dark-theme-color'>
              {' '}
              {`Select the invoice/s that you want to ${from == 'credit note' ? ' apply the credit note on' : from == 'debit note' ? ' apply the debit note on': ' generate the receipt for'}:`}
            </p>
          </div>

          <button
            type='button'
            className='btn btn-sm fw-bold px-2 green-submit-btn ms-auto pe-5 d-flex justify-content-center status-w-150'
            onClick={() => {
              let values: any = [...checkedInvoices]
              checkedValues?.map((chk: any, index: number) => {
                if (chk == true) values?.push(invoices[index])
              })

              updateCheckedInvoices(values)

              setTotalSelected(0)
            }}
            disabled={totalSelected == 0}
          >
            Select
          </button>
        </div>

        {/* Search Filters */}
        <div className='d-flex align-items-start py-5 pt-2 gy-3 mx-0 mt-4'>
          <label className='head-text me-7 pt-2 fnt-600'> Search </label>

          {/* INVOICE NO */}
          <div className='mb-2 pe-5 xyz property xyz tst status-w-200'>
            <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
              <InputBase
                placeholder='Invoice'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchInvoice}
                onChange={(e: any) => setSearchInvoice(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          {/* INVOICE DATE */}
          <div className='receipt-date select-invoice-date pe-5'>
            {invoiceDate != null ? (
              <DatePicker
                name='deliveryDate'
                defaultValue={moment(invoiceDate, 'YYYY-MM-DD')}
                onChange={handleDateChange}
                // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                placeholder='Invoice Date'
              />
            ) : (
              <DatePicker
                name='deliveryDate'
                onChange={handleDateChange}
                // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                placeholder='Invoice Date'
              />
            )}
          </div>

          {/* PAYMENT CATEGORY */}
          <div className='test expense property-test tst select-invoice-date me-5'>
            <div
              className={`multi-select-container ${
                selectedPaymentCategory?.length === 0 ? 'no-value payment-category' : 'has-value'
              }`}
            >
              <MultiSelect
                options={paymentCategoryOptions}
                value={selectedPaymentCategory}
                onChange={(e: any) => {
                  let newVal: any = e
                  setSelectedPaymentCategory(newVal)
                }}
                labelledBy='Type'
                hasSelectAll={true}
                overrideStrings={{
                  allItemsAreSelected: `${selectedPaymentCategory?.length} Selected`,
                }}
                // closeOnChangedValue={true}
                valueRenderer={CustomValueRenderer}
              />
            </div>
          </div>

          {/* PAYMENT STATUS */}
          <div className='test expense property-test tst select-invoice-date'>
            <div
              className={`multi-select-container ${
                selectedPaymentStatus?.length === 0 ? 'no-value announce' : 'has-value'
              }`}
            >
              <MultiSelect
                options={paymentStatusOptions}
                value={selectedPaymentStatus}
                onChange={(e: any) => {
                  let newVal: any = e
                  setSelectedPaymentStatus(newVal)
                }}
                labelledBy='Type'
                hasSelectAll={true}
                overrideStrings={{
                  allItemsAreSelected: `${selectedPaymentStatus?.length} Selected`,
                }}
                // closeOnChangedValue={true}
                valueRenderer={CustomValueRenderer}
              />
            </div>
          </div>

          {/* <div className='mb-2  ps-5 pe-5 property xyz tst status-w-200'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Payor'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchPayor}
                onChange={(e: any) => setSearchPayor(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}

          {/* <div className='mb-2  ps-5 pe-5 property xyz tststatus-w-200'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Contract no.'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchContractNo}
                onChange={(e: any) => setSearchContractNo(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}

          {/* <div className='mb-2  ps-5 pe-5 property xyz tststatus-w-200'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Development'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchDevelopment}
                onChange={(e: any) => setSearchDevelopment(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}

          {/* <div className='mb-2  ps-5 pe-5 property xyz tststatus-w-200'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Unit'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchUnit}
                onChange={(e: any) => setSearchUnit(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}
        </div>

        {/* Table Listing */}
        <div className='table-responsive pb-8' id='global-table'  style={{maxHeight: '350px', overflowY: 'scroll'}}>
          <table
            className='table align-start fs-6 gy-5'
            id='kt_ecommerce_sales_table'
           
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              {invoices?.length > 0 && (<th className='text-start min-w-25px test px-0'></th>)}
                <th className='min-w-125px'>Invoice No.</th>
                <th className='min-w-125px'>Amount</th>
                <th className='min-w-100px text-center'>Category</th>
                <th className='min-w-125px ps-7 pe-5'>Due Date</th>
                <th className='min-w-125px text-center'>Status</th>
                <th className='min-w-150px'>Outstanding</th>
                {from == 'credit note' && <th className='min-w-125px'>Credit</th> }
                {/* <th className='min-w-150px '>Payor</th> */}
                <th className='min-w-150px'>Development</th>
                <th className='min-w-100px'>Unit No.</th>
                <th className='min-w-125px text-center'>Lease Status</th>
                <th className='min-w-150px'>Lease Term</th>
                <th className='text-start min-w-150px px-3'>Contract No.</th>
              </tr>
            </thead>
            <tbody className='fw-semibold head-text'>
              {invoices?.length > 0 ? (
                <>
                  {invoices?.map((rcp: any, ind: number) => {
                    let outstandingAmount: any =
                      Number(rcp?.totalAmount) - Number(rcp?.totalPaidAmount)
                    return (
                      <>
                        <tr>
                          <td className='text-start px-0 test'>
                            <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2'>
                              <input
                                className='form-check-input cursor-pointer'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={checkedValues[ind] == true ? true : false}
                                onChange={(e: any) => {
                                  const values = [...checkedValues]
                                  values[ind] = !values[ind]
                                  setCheckedValues(values)

                                  let total:any = 0
                                  values?.map((vl:any, index:number)=>{
                                    if(vl)
                                      total = total + 1
                                  })

                                  setTotalSelected(total)
                                }}
                              />
                            </div>
                          </td>
                          <td className=''>
                            <div className='d-flex text-decoration-underline'>
                              <b>{rcp?.invoiceNo ? rcp?.invoiceNo : '-'}</b>
                            </div>
                          </td>
                          <td>
                            {rcp?.totalAmount > 0 ? (
                              <>
                                AED{' '}
                                {formatAmount(rcp?.totalAmount)}
                              </>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          <td>
                            <div
                              className={`status status-w-100
                                ${
                                  rcp?.categories == 0
                                    ? 'active-bar '
                                    : rcp?.categories == 1
                                    ? 'expired-bar'
                                    : rcp?.categories == 3
                                    ? 'complete-bar'
                                    : rcp?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                              `}
                            >
                              {rcp?.categories == 0
                                ? 'Upcoming'
                                : rcp?.categories == 1
                                ? 'Overdue'
                                : rcp?.categories == 3
                                ? 'Complete'
                                : rcp?.categories == 2
                                ? 'Deferred'
                                : '-'}
                            </div>
                          </td>
                          <td className='ps-7 pe-5'>
                            {rcp?.dueDate ? moment(rcp?.dueDate).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            <div
                              className={`status-w-100 status
                                ${
                                  rcp?.status === 2
                                    ? 'pending-approval-st '
                                    : rcp?.status === 1
                                    ? 'approve-st'
                                    : rcp?.status === 0
                                    ? 'cancelled-st'
                                    : rcp?.status === 3 || rcp?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                            >
                              {rcp?.status === 0
                                ? 'Unpaid'
                                : rcp?.status === 1
                                ? 'Paid'
                                : rcp?.status === 2
                                ? 'Partially paid'
                                : rcp?.status === 3
                                ? 'Void'
                                : rcp?.status === 4
                                ? 'Draft'
                                : '-'}
                            </div>
                          </td>
                          <td>
                          {outstandingAmount != undefined ? (
                              <>AED {formatAmount(invertOutstandingAmount(outstandingAmount))}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>
                          {from == 'credit note' && 
                           <td>
                           {rcp?.creditAmount != undefined ? (
                             <>
                               AED{' '} {formatAmount(rcp?.creditAmount)}
                             </>
                           ) : (
                             <>AED 0</>
                           )}
                         </td>
                          }
                          {/* <td className=''>
                            {rcp?.tenant?.[0]?.firstName
                              ? `${rcp?.tenant?.[0]?.firstName} ${rcp?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td> */}

                          <td>
                            {rcp?.community?.length > 0
                              ? rcp?.community?.[0]?.name
                              : rcp?.building?.[0]?.name}
                          </td>
                          <td>{rcp?.unit?.length > 0 ? rcp?.unit?.[0]?.unitNo : '-'}</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div
                                className={
                                  rcp?.tenancy?.[0]?.tenancyStatus == 0
                                    ? 'status booked-bar'
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 1
                                    ? 'status active-bar  '
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 2
                                    ? 'status expiring-bar'
                                    : rcp?.tenancy?.[0]?.tenancyStatus == 3
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {rcp?.tenancy?.[0]?.tenancyStatus == 0
                                  ? 'Booked'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 1
                                  ? 'Active'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : rcp?.tenancy?.[0]?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                            </div>
                          </td>

                          <td className='text-start '>
                            {rcp?.tenancy?.[0]?.duration?.start_date
                              ? `${moment
                                  .utc(rcp?.tenancy?.[0]?.duration?.start_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                            {'-'}
                            {rcp?.tenancy?.[0]?.duration?.end_date
                              ? `${moment
                                  .utc(rcp?.tenancy?.[0]?.duration?.end_date)
                                  .local()
                                  .format('DD.MM.YYYY')} `
                              : '-'}
                          </td>

                          <td className=''>
                            {rcp?.tenancy?.[0]?.contractNo > 0
                              ? rcp?.tenancy?.[0]?.contractNo
                              : '-'}
                          </td>
                        </tr>
                      </>
                    )
                  })}

                  {/* This div will trigger the observer when it comes into view */}
                  <tr>
                    <td colSpan={12}>
                      <div ref={endOfListRef} style={{height: '1px'}} />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  {isLoading && (
                    <td colSpan={12} className='text-center'>
                      <div
                        className='d-flex justify-content-center align-items-center w-100'
                        style={{height: '150px'}}
                      >
                        <CircularProgress style={{color: '#007a59'}} />
                      </div>
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default SelectInvoice

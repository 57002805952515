import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
// import './style.scss'
import {ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import leasingImg from '../../../../img/leasing.png'
import resImg from '../../../../img/residential.png'
import commImg from '../../../../img/commercial.png'
import noData from '../../../../img/NoData1.svg'
import settingsIcon from '../../../../img/settings-black.png'

const NewTypeMaintenance = () => {
  const navigate = useNavigate()
  const {id, flg, mainId} = useParams()

  const [subWorkflows, setSubWorkflows] = useState<any>()
  const [subWorkflowList, setSubWorkflowList] = useState<any>()

   //
   const getSubWorkFlowsList = () => {
    const body = {
      subWorkflowId: id,
    }
    ApiPost('corporate/sub_workflow_list/get', body)
      .then((res: any) => {
        setSubWorkflowList(res?.data?.data)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getSubWorkFlowsList()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/settings/workflows/maintenance/${mainId}`)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>  {flg == '1'
                ? 'Residential Maintenance Workflows'
                : 'Commercial Maintenance Workflows'} </h2>
          </div>
          <div className='ms-auto'>
            <img src={flg == '1' ? resImg : commImg} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {subWorkflowList?.length > 0 ? (
                <>
                  {subWorkflowList?.map((workflow: any, index: any) => {
                    return (
                      <Col md={6} className='mb-5'>
                        <div
                          className='card card-flush gap-5 p-3 cursor-pointer'
                          onClick={() => {
                            let type: any
                            navigate(`/settings/workflows/maintenance/sub-workflows/${mainId}/${flg}/${id}/${workflow?.name}`)
                          }}
                        >
                          <div className='card-body p-3'>
                            <div className='d-flex align-items-center'>
                              <img
                                 src={settingsIcon}
                                height={35}
                                width={35}
                              />
                              <h4 className='page-heading m-0 head-text ps-4'>
                                <b>{workflow?.name}</b>
                              </h4>
                              <div className='ms-auto text-center'>
                                <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                                <h4 className=' m-0 head-text'>
                                  <b>{Object?.keys(workflow?.maintenanceObject)?.length}</b>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </>
              ) : (
                <>
                <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350}  />
                    </Col>
                  </Row></>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewTypeMaintenance

import {FC, useState} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../../helpers'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setComefrom} from '../../../../../redux/counterSlice'
import {ApiPost} from '../../../../../apiCommon/helpers/API/ApiData'

type Props = {
  paths: any
  to: any
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}

const MenuItem: FC<Props> = ({
  paths,
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()

  // Convert dynamic paths to regular expressions
  const pathToRegex = (path: string) => {
    const escapedPath = path.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    const regexPath = escapedPath.replace(/\\:([^/]+)/g, '([^/]+)');
    return new RegExp(`^${regexPath}$`);
  };

  // const isActive = paths.includes(pathname)
  const isActive = paths.some((path:any) => pathToRegex(path).test(pathname));

  const dispatch = useDispatch()

  useEffect(() => {
    localStorage.setItem('fb_page', '1')
    // console.log(pathname)

    if (pathname == '/tenants') dispatch(setComefrom('tenants'))
    // if (pathname?.slice(0, 21) == 'tenancy-termination/3') {
    // }
    // else{
    localStorage.removeItem('isFormDirty')
    // }

    let temp = localStorage.getItem('DeleteClone')
    if (temp != null) {
      let val = JSON.parse(temp)
      const body = {
        tenancyId: val?.tenancyId,
      }

      ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
        .then(() => {
          localStorage.removeItem('DeleteClone')
        })
        .catch((err: any) => {})
    }
  }, [isActive])

  const [disableSideBar, setDisableSideBar] = useState<any>(false)

  const refresh = () => {
    let temp = localStorage.getItem('isFormDirty')
    if (temp != null) {
      if (JSON.parse(temp) == true) {
        // setDisableSideBar(true)
      }
    } else {
      setDisableSideBar(false)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  setInterval(refresh, 5000)

  return (
    <>
      {disableSideBar ? (
        <div
          className={`menu-item me-lg-1 ${title !== 'Properties' && 'cursor-none'}`}
          style={{opacity: '0.5', cursor: 'not-allowed'}}
        >
          <div
            className={clsx('py-3 px-5 ', {
              'active menu-here': isActive,
            })}
            style={{color: 'black'}}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            {icon && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
          </div>
        </div>
      ) : (
        <div className={`menu-item me-lg-1 ${title !== 'Properties' && 'cursor-none'}`}>
          <Link
            className={clsx('menu-link py-3 pe-2 ', {
              'active menu-here': isActive,
            })}
            style={{color: 'black'}}
            // onClick={(event) => event.preventDefault()}
            to={to}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            {icon && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
        </div>
      )}
    </>
  )
}

export {MenuItem}

import React from 'react'
import {Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import noData from '../../../img/NoData1.svg'

function PaymentTab() {
  const navigate = useNavigate()
  const serviceId = window.location.pathname.split('/')[5]

  const Invoices = new Array(2).fill(0)
  const PaymentRequests = new Array(2).fill(0)

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='card card-flush app-container p-5'>
          <Row>
            <div className='col-md-3'>
              <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>
                Message Vendor
              </button>
            </div>
            <div className='col-md-3'></div>
            <div className='col-md-3'></div>
            <div className='col-md-3' style={{textAlign: 'end'}}>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={
                  () =>
                    Swal.fire({
                      html: '<p class="text-start m-0">You are about to cancel this request, do you wish to proceed?</p>',
                      showConfirmButton: true,
                      confirmButtonColor: '#D72852',
                      confirmButtonText: 'Cancel Request',
                      showCancelButton: true,
                      cancelButtonText: 'Back',
                    })
                  // .then((res) => {
                  //     if (res.isConfirmed) {
                  //     }
                  // })
                }
              >
                Cancel Request
              </a>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Request Again
              </a>
            </div>
          </Row>
          <div className='grid-out p-5 container-xxl'>
            <div className='side'>
              <h6 className='mx-10 text-muted'>
                Request Type
                <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
              <h6 className='mx-10 text-muted'>
                Request ID <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
              <h6 className='mx-10 text-muted'>
                Date Requested <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
              <h6 className='mx-10 text-muted'>
                Request Urgency <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
            </div>
            <div className='side'>
              <h6 className='mx-10 text-muted'>
                Handled By <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
              <h6 className='mx-10 text-muted'>
                Assigned To <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
              <h6 className='mx-10 text-muted'>
                Overall Status <span className='mx-1' style={{color: 'black'}}></span>
              </h6>
            </div>
          </div>
        </div>

        <div className='d-flex align-items-center justify-content-center gap-2 m-7 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/service-detail/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Service
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/payment-tab/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Payment
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/request-log-tab/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Request Log
              </a>
            </li>
          </ul>
        </div>
        <div className='card card-flush app-container p-5'>
          <h6 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
            Invoince tenant
          </h6>
          <div className='d-flex align-items-center justify-content-between gap-2 mb-10 gap-lg-3'>
            <div className='d-flex align-items-center'>
              <h3 className='page-heading d-flex text-dark fw-bold m-0 me-5 fs-3 flex-column justify-content-center'>
                Invoices
              </h3>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Request Invoice
              </a>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Upload Invoice
              </a>
            </div>
            <div>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Request Payment
              </a>
            </div>
          </div>
          <div className='pt-0 table-responsive mt-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center'></th>
                  <th className='text-center min-w-100px'>Invoice #</th>
                  <th className='text-center min-w-100px'>Amount</th>
                  <th className='text-center min-w-100px'>Purchase Order #</th>
                  <th className='text-center min-w-100px'>Selected Jobs</th>
                  <th className='text-center min-w-100px'>Payment Milestone</th>
                  <th className='text-center min-w-100px'>Date Received</th>
                  <th className='text-center min-w-100px'>Payment Status</th>
                  <th className='text-center min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {Invoices?.length ? (
                  Invoices?.map((f: any, i: any) => {
                    return (
                      <tr>
                        <td className='text-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='flexCheckDefault'
                              value=''
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </td>
                        <td className='text-center min-w-100px'>###</td>
                        <td className='text-center min-w-100px'>AED ###</td>
                        <td className='text-center min-w-100px'>PO_S_####_#####</td>
                        <td className='text-center min-w-100px'>##</td>
                        <td className='text-center min-w-100px'>Payment Milestone</td>
                        <td className='text-center min-w-100px'>Date Received</td>
                        <td className='text-center min-w-100px'>Payment Status</td>
                        <td>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='d-flex align-items-center gap-5 gap-lg-3 mt-5'>
            <h3 className='page-heading m-0 d-flex text-dark fw-bold m-0 me-5 fs-3 flex-column justify-content-center'>
              Payment Requests
            </h3>
          </div>
          <div className='pt-0 table-responsive mt-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center'></th>
                  <th className='text-center min-w-150px'>Amount Requested</th>
                  <th className='text-center min-w-150px'>Invoice Amount</th>
                  <th className='text-center min-w-100px'>PO #</th>
                  <th className='text-center min-w-100px'>PO Amount</th>
                  <th className='text-center min-w-150px'>Requested By</th>
                  <th className='text-center min-w-150px'>Completed By </th>
                  <th className='text-center min-w-150px'>Pending With</th>
                  <th className='text-center min-w-150px'>Request Status</th>
                  <th className='text-center min-w-150px'>Payment Status</th>
                  <th className='text-center min-w-100px'></th>
                  <th className='text-center min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {PaymentRequests?.length ? (
                  PaymentRequests.map((f: any, i: any) => {
                    return (
                      <tr>
                        <td className='text-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='flexCheckDefault'
                              value=''
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </td>
                        <td className='text-center min-w-100px'>Amnt Requstd</td>
                        <td className='text-center min-w-100px'>Inv. Amnt</td>
                        <td className='text-center min-w-100px'>PO #</td>
                        <td className='text-center min-w-100px'>PO Amnt</td>
                        <td className='text-center min-w-100px'>Requested By</td>
                        <td className='text-center min-w-100px'>Completed By</td>
                        <td className='text-center min-w-100px'>Pending With</td>
                        <td className='text-center min-w-100px'>Request Status</td>
                        <td className='text-center min-w-100px'>Paym Status</td>
                        <td>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                          >
                            View
                          </a>
                        </td>
                        <td>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                          >
                            Approve
                          </a>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentTab

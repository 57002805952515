import React from 'react'
import {Section} from './EmailBuilder'
import imageUploader from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Large.png'
import imageUploaderSmall from '../../../../../img/Email-Template-02/Image Uploader - Rectangle Small.png'
import TextEditor from '../../../General/TextEditor'

interface HTMLExportProps {
  section: Section
  isSelected?: boolean
  selectedSectionStyling: any
  setSelectedSectionStyling: (values: any) => void
}

const HTMLExport: React.FC<HTMLExportProps> = ({
  section,
  isSelected,
  selectedSectionStyling,
  setSelectedSectionStyling,
}) => {
  return (
    <>
      <div
        style={{
          backgroundColor: section.content.backgroundColor,
          // minHeight: `${section.content.minHeight}px`,
        //   height: `${
        //     section.content.height ? section.content.height + section.content.sizeType : 'unset'
        //   }`,
        //   width: `${section.content.width}${section.content.sizeType}`,
          color: section.content.color,
          display: section.content.display,
          justifyContent: section.content.justifyContent,
          marginTop: `${section.content.marginTop}px`,
          marginRight: `${section.content.marginRight}px`,
          marginBottom: `${section.content.marginBottom}px`,
          marginLeft: `${section.content.marginLeft}px`,
          paddingTop: `${section.content.paddingTop}px`,
          paddingRight: `${section.content.paddingRight}px`,
          paddingBottom: `${section.content.paddingBottom}px`,
          paddingLeft: `${section.content.paddingLeft}px`,
          // textAlign: section.content.textAlign
        }}
      >
          <div dangerouslySetInnerHTML={{__html: section.content.html}} id={`${section?.id}`} style={{ width: '100%'}}/>
      </div>
    </>
  )
}

export default HTMLExport

import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import invoiceBlue from '../../../img/invoice-blue.png'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import ViewInvoice from './ViewInvoice'
import { ErrorToast } from '../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose: any
  invoices: any
  dueDate: any
  paymentId: any
  tenantDetails: any
  paymentMethod: any
  selectedAccount: any
  chequeMedia: any
  getPaymentInvoices: any
  invRecDetails: any
  from: any
}

const ViewAllInvoices = ({
  handleClose,
  show,
  invoices,
  dueDate,
  paymentId,
  tenantDetails,
  paymentMethod,
  selectedAccount,
  chequeMedia,
  getPaymentInvoices,
  invRecDetails,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewInvoice, setViewInvoice] = useState<any>(false)
  const [invoiceToShowEdit, setInvoiceToShowEdit] = useState<any>()

  const getInvoiceById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_invoice/${id}?isCloneRecord=true`
    else url = `corporate/payment_invoice/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setInvoiceToShowEdit(res?.data?.data)
        setViewInvoice(true)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Invoices
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '45px'}}>
              <img src={invoiceWhite} height={80} width={70} className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3 pt-2'>
            {invoices?.length > 0 &&
              invoices.map((pi: any, index: any) => (
                <div className='img-sets mw-350px col-2 mt-2 ps-2 text-center'>
                  <div className='text-center'>
                    <a
                      onClick={() => {
                        if (
                          ((from == 'tenancy' || from == 'terminate-tenancy') &&
                            ((user?.isSubUser &&
                              role?.leasing_residential?.lease_financial?.invoicing
                                ?.view_details) ||
                              !user?.isSubUser)) ||
                          from == 'propertyProfile' ||
                          from == 'tenant' ||
                          from == 'financials'
                        )
                          getInvoiceById(pi?._id)
                        else ErrorToast(`You don't have the permissions to view Invoice...`)
                      }}
                      style={{color: '#384a62'}}
                      className='d-flex justify-content-center'
                    >
                      <img
                        src={invoiceBlue}
                        width='40'
                        height='50'
                        className='main_img cursor-pointer ms-2'
                      />
                      {(pi?.invoiceNo == '' || pi?.invoiceNo == null) && (
                        <div
                          className=''
                          style={{
                            height: '20px',
                            width: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#ffc100',
                            border: '2px solid  #516176',
                            color: '#516176',
                            marginTop: '-10px',
                            marginLeft: '2px',
                            fontWeight: '900',
                            fontSize: '14px',
                          }}
                        >
                          <p style={{marginTop: '-3px'}}>!</p>
                        </div>
                      )}
                    </a>
                  </div>
                  {pi?.invoiceNo == '' || pi?.invoiceNo == null ? (
                    <input
                      type='text'
                      className='form-control form-control-solid-bg input-style mt-1'
                      placeholder=''
                      value='-'
                      name='item_id'
                      style={{
                        height: '20px',
                        width: '80px',
                        border: '2px solid #516176',
                        fontSize: '12px',
                        fontWeight: '900',
                        textAlign: 'center',
                      }}
                      disabled
                    />
                  ) : (
                    <p
                      style={{
                        height: '15px',
                        fontSize: '0.9rem',
                        wordBreak: 'break-all',
                      }}
                      className='mt-1 text-center'
                    >
                      {pi?.invoiceNo}{' '}
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {viewInvoice && (
        <ViewInvoice
          show={viewInvoice}
          handleClose={() => {
            setViewInvoice(false)
          }}
          invoice={invoiceToShowEdit}
          dueDate={dueDate}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          paymentMethod={paymentMethod}
          getPaymentInvoices={getPaymentInvoices}
          invRecDetails={invRecDetails}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewAllInvoices

import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import reminderWhite from '../../../img/notification-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  media: any
  from:any
}

const ViewAllReminders = ({handleClose, show, media, from}: ButtonProps) => {
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: ' #0098cd',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>Reminders</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => handleClose()}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>
        <div className='schedule-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-1' style={{paddingLeft: '60px'}}>
              <img src={reminderWhite} height={90} width={90} className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-6'
          style={{transform: 'translate(0px, -80px)', height: '500px'}}
        >
          <Row className=''>
            <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
              <label className='label-color' style={{fontWeight: '700'}}>Days before payment is due</label>
              {/* <input
                type='text'
                className='form-control form-control-solid-bg'
                placeholder='Enter Name'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                  width: '80%'
                }}
              /> */}
            </Col>
            <Col lg={4} className='align-items-center ' style={{paddingLeft: '70px'}}>
              <label className='label-color' style={{fontWeight: '700'}}>Reminder Schedule</label>
            </Col>
          </Row>
          <div className="" style={{maxHeight: '480px', overflowY: 'scroll', overflowX: 'hidden'}} >

          {media.map((sch: any, index: any) => {
            return (
              <Row>
                <Col lg={4} md={4} className='align-items-center pe-5 mb-5 ps-6 '>
                  {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                  <div className='d-flex align-items-center' style={{color: '#516176', fontWeight: '500'}}>{sch.days}</div>
                </Col>
                <Col lg={4} className='align-items-center mb-0 mt-2' style={{paddingLeft: '70px'}}>
                  <div className='d-flex align-items-center' style={{color: '#516176', fontWeight: '500'}}>{sch.date}</div>
                </Col>
                <Col lg={4} md={4} className='align-items-center text-end'></Col>
              </Row>
            )
          })}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ViewAllReminders

import React from 'react'
import './style.scss' // Assuming we define CSS separately
import emailIcon from '../../../../img/mailnotification.svg'
import {useParams} from 'react-router-dom'

interface StepProps {
  isCompleted: boolean
  icon: React.ReactNode
}
interface StepLabelProps {
  label: string
}

const Step: React.FC<StepProps> = ({isCompleted, icon}) => {
  return (
    <div className='builder-step'>
      <div className={`circle ${isCompleted ? 'completed' : 'hollow'}`}>{icon}</div>
    </div>
  )
}

const StepLabel: React.FC<StepLabelProps> = ({label}) => {
  return (
    <div className='builder-step'>
      <p className='label white-dark-theme-color' style={{width: '120px', wordBreak: 'break-word'}}>
        {label}
      </p>
    </div>
  )
}

const Stepper: React.FC = () => {
  const {type} = useParams()
  return (
    <div>
      <div className='stepper-email-builder'>
        <Step isCompleted={true} icon={<div className='' />} />
        <div className='line'></div>
        <Step isCompleted={true} icon={<div className='' />} />
        <div className='line'></div>
        <Step isCompleted={false} icon={<img src={emailIcon} height={20} width={20} />} />
      </div>
      <div className='stepper-email-label'>
        <StepLabel
          label={`Prepare ${
            type == 'invoices'
              ? 'Invoice'
              : type == 'receipts'
              ? 'Receipt'
              : type == 'refunds'
              ? 'Refund'
              : type == 'credit notes'
              ? 'Credit Note'
              : type == 'debit notes'
              ? 'Debit Note'
              : ''
          }`}
        />
        <div className='line'></div>
        <StepLabel
          label={`Create and share ${
            type == 'invoices'
              ? 'invoice'
              : type == 'receipts'
              ? 'receipt'
              : type == 'refunds'
              ? 'refund'
              : type == 'credit notes'
              ? 'credit note'
              : type == 'debit notes'
              ? 'debit note'
              : ''
          } via email`}
        />
        <div className='line'></div>
        <StepLabel
          label={`${
            type == 'invoices'
              ? 'Invoice'
              : type == 'receipts'
              ? 'Receipt'
              : type == 'refunds'
              ? 'Refund'
              : type == 'credit notes'
              ? 'Credit Note'
              : type == 'debit notes'
              ? 'Debit Note'
              : ''
          } Email is dispatched`}
        />
      </div>
    </div>
  )
}

export default Stepper

import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from '../../../apiCommon/helpers/API/ApiData'
import {KTSVG} from '../../../_metronic/helpers'
import {register} from '../auth/core/_requests'
import AppVendormodal from './AppVendormodal'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {AiFillDelete} from 'react-icons/ai'

let vendorListArraydata: any = []

function EditVendorDetails() {
  const [formData, setFormData] = useState<any>({})
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const navigate = useNavigate()
  const [country, setCountry] = React.useState([])
  const [showAddVendorModal, setShowAppVendorModal] = React.useState<boolean>(false)
  const [city, setCity] = React.useState([])
  const [vendorList, setVendorList] = React.useState<any>()
  const [vendorsList, setVendorsList] = React.useState('Vendor List')
  const [countryName, setCountryName] = React.useState<any>('')
  const [uploadedFile, setUploadedFile] = React.useState<any>('')
  const [countryId, setCountryId] = React.useState<any>()
  const [vendorListArray, setVendrorListArray] = React.useState<any>()

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const getVendorDataById = async () => {
    await ApiGet(`corporate/vendor/${window.location.pathname?.split('/')[5]}`)
      .then((res) => {
        setFormData(res?.data?.data[0])
        setVendrorListArray(res?.data?.data[0]?.vendorListIds)
        setCountryName(
          res?.data?.data[0]?.country?.charAt(0).toUpperCase() +
            res?.data?.data[0]?.country.slice(1)
        )
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
        const data = res?.data?.data
          ?.filter((i: any) => i?.country === countryName)
          .map((item: any) => item?._id)
        setCountryId(data[0])
        ApiGet(`country/cities?countryId=${data[0]}`)
          .then((res) => {
            setCity(res?.data?.data)
          })
          .catch((e) => {
            console.log('e', e)
          })
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  React.useEffect(() => {
    getVendorDataById()
    getCountry()
  }, [])
  const vendorListItems = new Array(5).fill(0)

  const handleCountryChnage = async (e: any) => {
    const {name, value} = e.target
    const data = value.split('/')
    setCountryName(data[1])
    await ApiGet(`country/cities?countryId=${data[0]}`)
      .then((res) => {
        setCity(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }
  React.useEffect(() => {
    getCountry()
  }, [countryName])

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode = e.dialCode
    let PhoneNumber = Phone.split(CountryCode)[1]

    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }

  const getvendorList = () => {
    ApiGet('corporate/vendor_list')
      .then((res) => {
        setVendorList(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  React.useEffect(() => {
    getvendorList()
  }, [])

  const uploadFilelHandler = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/document', formData)
      .then((res: any) => setUploadedFile(res?.data?.data?.image))
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const onEditVendor = async () => {
    const body = {
      id: formData?._id,
      companyName: formData?.companyName,
      tradeLicenseNo: formData?.tradeLicenseNo,
      TRN: formData?.TRN,
      country: countryName,
      city: formData?.city,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
      phoneNumber: formData?.phoneNumber.toString(),
      countryCode: formData?.countryCode,
      vendorListIds: vendorListArray ? vendorListArray : formData?.vendorListIds[0],
      vendorType: 0,
      comments: formData?.comment,
      image: uploadedFile,
      saveStatus: 1,
    }
    await ApiPut(`corporate/vendor`, body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate('/vendor-management/management-vendors')
      })
      .catch((err) => ErrorToast(err.message))
  }
  const deleteVendorList = (id: any) => {
    const body = {
      vendorId: window.location.pathname?.split('/')[5],
      vendorListId: id,
    }
    ApiPost('corporate/vendor_list/remove_vendor/by_id', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        getVendorDataById()
      })
      .catch((err) => ErrorToast(err.message))
  }
  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-baseline flex-wrap mr-5'>
            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() => navigate('/vendor-management/management-vendors')}
                >
                  Vendor
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() =>
                    navigate(
                      `/vendor-management/management-vendors/view-vendors/${
                        window.location.pathname?.split('/')[5]
                      }`
                    )
                  }
                >
                  View Vendor
                </a>
              </li>
              <li className='breadcrumb-item active'>
                <a className='px-2'> Edit Vendor</a>
              </li>
            </ul>
          </div>
          <div className='d-flex align-items-center justify-content-end gap-2 mb-10 gap-lg-3'></div>
          <div className='mb-8 min-w-375px d-flex justify-content-end w-auto'>
            <div className=''>
              <button
                className='btn btn-sm fw-bold me-5 btn-primary btn-green w-80px mb-2 hover-none'
                onClick={() => onEditVendor()}
              >
                Save
              </button>
            </div>
            <div className=''>
              <button
                className='btn btn-sm fw-bold me-5 btn-primary btn-green w-80px hover-none'
                onClick={() => navigate(`/vendor-management/management-vendors`)}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className='card card-flush'>
            <Row className='py-2'>
              <div className='d-flex justify-content-between'>
                <span className='mx-10 mt-5'>
                  <b>Vendor Type: </b>Service Provider
                </span>
                <span className='mx-10 mt-5'>
                  <b>Vendor ID: </b>
                  {formData?.id}{' '}
                </span>
                <span className='mx-10 mt-5'>
                  <b>Date Added: </b>
                  {moment(formData?.createdAt).format('DD/MM/YYYY')}
                </span>
              </div>
              <Col lg={6}>
                <h3 className='mx-10 mb-10 mt-10'>Company Detail </h3>
                <div className='min-w-200px mx-10'>
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <h6 className='text-muted m-0 me-2 min-w-150px'>Company Name:</h6>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder=''
                      value={formData?.companyName}
                      name='companyName'
                      onChange={handleChnage}
                    />
                  </div>
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <h6 className='text-muted m-0 me-2'>Trade License No.:</h6>
                    <input
                      type='number'
                      className='form-control form-control-solid w-auto'
                      placeholder=''
                      value={formData?.tradeLicenseNo}
                      name='tradeLicenseNo'
                      onChange={handleChnage}
                    />
                    {formData?.image && (
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        href={`${Bucket}${formData?.image}`}
                        target='_blank'
                      >
                        Trade License
                      </a>
                    )}
                    <button className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative'>
                      Upload Trade License
                      <input
                        type='file'
                        {...register('contract', {required: true})}
                        className='form-control form-control-solid position-absolute '
                        placeholder=''
                        name='contract'
                        onChange={uploadFilelHandler}
                        style={{top: 0, left: 0, width: '100%', height: '100%', opacity: 0}}
                      />
                    </button>
                  </div>
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <h6 className='text-muted m-0 me-2'>TRN:</h6>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder=''
                      name='TRN'
                      value={formData?.TRN}
                      onChange={handleChnage}
                    />
                  </div>
                  {errors.companyName && (
                    <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                  )}
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <div className='pe-5 w-50 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>Country:</h6>
                      <select
                        name='country'
                        className='form-select form-select-solid'
                        onChange={handleCountryChnage}
                        value={`${countryId}/${countryName}`}
                      >
                        <option value='' disabled>
                          Select country
                        </option>
                        {country?.map((v: any) => (
                          <option value={`${v._id}/${v.country}`}>{v.country}</option>
                        ))}
                      </select>
                    </div>
                    <div className='w-50 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>City:</h6>
                      <select
                        {...register('city', {required: true})}
                        name='city'
                        className='form-select form-select-solid'
                        value={formData?.city}
                        onChange={handleChnage}
                      >
                        <option value='' disabled selected>
                          Select city
                        </option>
                        {city?.map((v: any) => (
                          <option value={v.name}>{v.city}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <h3 className='mx-10 mb-10 mt-10'>Main Contract Detail </h3>
                <div className='min-w-200px mx-10'>
                  <div className='payment-receipt-item mb-5'>
                    <h6 className='text-muted m-0 me-2 min-w-100px'>Contract Name</h6>
                    <div className='d-flex'>
                      <div className='w-50 pe-5 d-flex align-items-center'>
                        <h6 className='text-muted m-0 me-2 min-w-100px'>First Name:</h6>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='firstName'
                          value={formData?.firstName}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='w-50 d-flex align-items-center'>
                        <h6 className='text-muted m-0 me-2 min-w-100px'>Last Name:</h6>
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='lastName'
                          value={formData?.lastName}
                          onChange={handleChnage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <h6 className='text-muted m-0 me-2'>Contract Email:</h6>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder=''
                      name='email'
                      value={formData?.email}
                      onChange={handleChnage}
                    />
                  </div>
                  {errors.companyName && (
                    <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                  )}
                  <div className='payment-receipt-item d-flex align-items-center mb-5'>
                    <h6 className='text-muted m-0 me-2'>Contract (Mob No):</h6>
                    <PhoneInput
                      // {...register("phone", { required: true })}
                      placeholder='Enter phone number'
                      value={`${formData?.countryCode}${formData?.phoneNumber}`}
                      onChange={(phone, e) => handleNumber(phone, e)}
                      // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                    />
                  </div>
                </div>
              </Col>
              <div className='payment-receipt-item mb-5 mx-10'>
                <div className='d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Vendor List</h6>
                  <button
                    className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none'
                    onClick={() => setShowAppVendorModal(true)}
                  >
                    Add To List
                  </button>
                </div>
                <ul className='d-flex flex-wrap'>
                  {vendorList
                    ?.filter((v: any) => vendorListArray?.includes(v._id))
                    .map((item: any, index: any) => {
                      return (
                        <div className='d-flex pe-10'>
                          <li className='pe-2'>{item?.name}</li>
                          <AiFillDelete
                            color='#007a59'
                            fontSize={20}
                            onClick={() => deleteVendorList(item?._id)}
                          />
                        </div>
                      )
                    })}
                </ul>
              </div>
            </Row>
          </div>
        </div>
      </div>

      <AppVendormodal
        show={showAddVendorModal}
        handleClose={() => {
          setShowAppVendorModal(false)
        }}
        setFormData={setFormData}
        formData={formData}
        vendorsList={vendorsList}
        vendorList={vendorList}
        vendorListArray={vendorListArraydata}
        setVendrorListArray={setVendrorListArray}
        type={'editVendor'}
        getVendorDataById={getVendorDataById}
      />
    </>
  )
}

export default EditVendorDetails

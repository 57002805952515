import React, {useEffect, useRef, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {ApiPost, Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import trashImg from '../../../../../img/trash.png'
import ModalLoader from '../../../General/ModalLoader'
import {getFileIcon} from '../../../../../Utilities/utils'
import {MultiSelect} from 'react-multi-select-component'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {truncateFileName, formatFileSize} from '../../../../../Utilities/utils'

interface EmailAttachmentsProps {
  attachments: Array<Object>
  setAttachments: (val:any) => void
  dynamicAttachments: Array<Object>
  setDynamicAttachments: (val:any) => void
}

const EmailAttachments: React.FC<EmailAttachmentsProps> = ({attachments, setAttachments, dynamicAttachments, setDynamicAttachments}) => {
 

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  //
  const dynamicAttachmentOptions = [
    {
      label: 'PDF Web Preview',
      value: 'pdfURL',
      description:
        'Will include the URL generated for the PDF preview of the invoice. It will allow the email recipient to click on the field and preview the invoice that was generated.',
    },
  ]

  const [selectedDynamicAttachment, setSelectedDynamicAttachment] = useState<any>(dynamicAttachments)

  //
  const handleUploadAttachments = async (e: any) => {
    e.preventDefault()

    const values = [...attachments]

    if (e.target.files) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      const maxSize = 5 * 1024 * 1024 // 5MB file size limit

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        // Check the file size before proceeding
        if (fileURL.size > maxSize) {
          //   alert(`File ${fileURL.name} is too large. Maximum size allowed is 5MB.`);
          ErrorToast(
            `File ${truncateFileName(fileURL.name, 30)} is too large. Maximum size allowed is 5MB.`
          )
          continue // Skip to the next file if this one is too large
        }

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (attachments.some((chq: any) => chq?.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=email_attachments&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            values?.push({
              fileURL: res?.data?.data?.image,
              fileName: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
              fileSize: formatFileSize(fileURL.size),
              createdAt: new Date().toISOString(),
            })

            // Update the upload progress
            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }

      setAttachments(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  //
  const customOptionRenderer = (option: any) => {
    // console.log(option)
    return (
      <div
        style={{padding: '4px 8px'}}
        className='d-flex'
        onClick={() => {
          console.log(option?.option)

          if (selectedDynamicAttachment?.[0]?.label != option?.option?.label) {
            setSelectedDynamicAttachment([option?.option])
            setDynamicAttachments([option?.option])
          }
        }}
      >
        <div style={{height: '30px', width: '30px'}}>
          <input
            type='checkbox'
            className='me-3 cursor-pointer form-check-input mt-1'
            id='dynamicDocument'
            value='dynamicDocument'
            checked={option?.checked}
            onChange={() => {}}
          />
        </div>
        <div>
          <strong>{option?.option.label}</strong>
          <p style={{margin: '0', color: '#6c757d'}}>{option?.option.description}</p>
        </div>
      </div>
    )
  }

  // InfoCard Component
  const InfoCard = (selectedItem: any) => {
    console.log(selectedItem)
    return (
      <div className='card card-flush p-5 py-5 tst mt-10 mx-5 me-10'>
        <h3 className='my-2 '>
          <label className='fnt-600'> {selectedItem?.selectedItem?.label}</label>
        </h3>
        <p style={{color: '#6c757d'}} className='mb-3'>
          {selectedItem?.selectedItem?.description}
        </p>
      </div>
    )
  }

  //
  const handleAttachmentsClick = () => {
    const groupName = `group_attachments_others`

    attachments?.map((attach: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-src', `${Bucket}${attach?.fileURL}`)
        imgeNext.setAttribute('data-fancybox', groupName)

        // imgeNext.setAttribute('src', `${Bucket}${attach?.fileURL}`)
      }

      // Trigger the fancybox on the clicked image
      // if (imgeNext) {
      //   imgeNext.click()
      // }
    })
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  return (
    <>
      <div className='card card-flush gap-5 p-5 py-0 tst mt-3' style={{minHeight: '300px'}}>
        <div className='card-body  py-3 px-3'>
          <div className='d-flex mt-5'>
            <h3 className='fs-3 fnt-600 '> Email Attachments</h3>
          </div>

          <Row className='mt-3'>
            <Col md={6}>
              <h3 className='my-2 mt-5 me-5 '>
                <label className='fnt-600'> File Upload </label>
              </h3>
              <p className='labl-gry fs-4 mb-4 me-5'>
                Upload a file that you would like to attach to this email. This file will be
                included as an attachment every time this email is delivered:
              </p>

              <label
                title='Add New File'
                htmlFor='uploadCheque'
                className='btn btn-sm fw-bold px-2 green-submit-btn pe-5 status-w-110 d-flex justify-content-center'
              >
                Upload
              </label>
              <input
                type='file'
                hidden
                id='uploadCheque'
                className='form-control form-control-solid '
                placeholder='upload'
                name='passport'
                onChange={(e: any) => {
                  handleUploadAttachments(e)
                }}
                multiple
              />

              <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
                <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                  <tbody className='fw-semibold text-gray-600'>
                    {attachments?.map((chq: any, index: any) => {
                      return (
                        <tr className=''>
                          <td className='text-start ps-0' style={{maxWidth: '230px'}}>
                            <div
                              className=' card p-3 py-4 mt-3 ms-1'
                              style={{
                                boxShadow:
                                  '0 5px 8px rgba(0, 0, 0, 0.1), 0 0px 5px rgba(0, 0, 0, 0.08)',
                              }}
                            >
                              <div className='d-flex align-items-center'>
                                <img
                                  src={getFileIcon(chq?.fileURL)}
                                  height={30}
                                  width={30}
                                  className='ms-3 me-5 cursor-pointer'
                                  id={`clickedAttachments${index}`}
                                  onClick={() => {
                                    handleAttachmentsClick()
                                  }}
                                  // data-fancybox
                                  data-src={`${Bucket}${chq?.fileURL}`}
                                />

                                <div>
                                  <p className='mb-0 fnt-600 fs-4 text-dark'>
                                    {truncateFileName(chq?.fileName, 40)}{' '}
                                  </p>
                                  <p className='mb-0  fs-5 '>{chq?.fileSize} </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='text-start'>
                            <img
                              src={trashImg}
                              height={18}
                              width={18}
                              className='me-5 cursor-pointer'
                              onClick={() => {
                                let values = [...attachments]
                                values.splice(index, 1)
                                setAttachments(values)

                                // if (flg == '2') {
                                //   setTimeout(() => {
                                //     setAutoSaveData(Date.now())
                                //   }, 700)
                                // }
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6}>
              <h3 className='my-2 mt-5 mx-5'>
                <label className='fnt-600'> Dynamic File/Document</label>
              </h3>
              <p className='labl-gry fs-4 mb-4 mx-5'>
                Select a system generated file that you would like to attach to this email. The file
                generated will be included as an attachment every time this email is delivered:
              </p>

              <div className='property-test d-none-multi-checbox mx-5 text-editor mb-3'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5    ${
                    selectedDynamicAttachment.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={dynamicAttachmentOptions}
                    value={selectedDynamicAttachment}
                    ItemRenderer={customOptionRenderer}
                    onChange={(e: any) => {
                      let newVal: any = e
                      console.log(newVal)
                      if (e?.length > 1) newVal = e.slice(1)
                      setSelectedDynamicAttachment(newVal)

                    
                    }}
                    labelledBy='' // Empty as we are overriding the label below
                    overrideStrings={{
                      allItemsAreSelected: selectedDynamicAttachment?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                    }}
                    hasSelectAll={false}
                    closeOnChangedValue={true}
                  />
                </div>
              </div>

              {selectedDynamicAttachment?.length > 0 && (
                <InfoCard selectedItem={selectedDynamicAttachment[0]} />
              )}
            </Col>
          </Row>
        </div>
      </div>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default EmailAttachments

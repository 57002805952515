import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import backArrow from '../../../../../../img/back-arrow.png'
import domainIconGreen from '../../../../../../img/domainIconGreen.png'
import domainIconGray from '../../../../../../img/domainIconGray.png'
import passwordIconGreen from '../../../../../../img/passwordIconGreen.png'
import passwordIconGray from '../../../../../../img/passwordIconGray.png'
import emailVerificationIconGray from '../../../../../../img/emailVerificationIconGray.png'
import emailVerificationIconGreen from '../../../../../../img/emailVerificationIconGreen.png'
import gmailIcon from '../../../../../../img/gmailIcon.png'
import outlookIcon from '../../../../../../img/outlookIcon.png'
import atIcon from '../../../../../../img/atIcon.png'
import lockIcon from '../../../../../../img/lockIcon.png'
import informationIcon from '../../../../../../img/information.png'
import emailLinedIcon from '../../../../../../img/emailLinedIcon.png'
import emailVerifiedIcon from '../../../../../../img/emailVerifiedIcon.png'
import emailVerificationIcon from '../../../../../../img/emailVerificationIcon.png'
import emailTestIcon from '../../../../../../img/emailTestIcon.png'
import crmTicketTestIcon from '../../../../../../img/crmTicketTestIcon.png'
import checkMarkIconGreen from '../../../../../../img/checkMarkIconGreen.png'

import {useForm} from 'react-hook-form'
import TestCard from './TestCard'
import {ApiGet, ApiPost, ApiPut} from '../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../../apiCommon/helpers/Toast'
import CircularProgress from '@mui/material/CircularProgress'
import SentEmailPopup from './SenEmailPopup'

interface ButtonProps {
  show: any
  handleClose?: any
}

interface StepI {
  status: any
  icon: any
  isCheck: boolean
}

interface EmailProviderI {
  selectedProvider: any
  onSelectProvider: any
  email: any
  setEmail: any
  isEmailValid: any
}

interface ProviderI {
  name: any
  isSelected: any
  icon: any
  onClick: any
}

interface EmailI {
  email: any
  setEmail: any
  isEmailValid: any
}

const Step = ({status, icon, isCheck}: StepI) => (
  <div className={`step cursor-pointer ${status ? 'completed' : ''}`}>
    <div style={{height: '20px', position: 'absolute', top: -25}} className='mb-2'>
      {isCheck && <img src={checkMarkIconGreen} alt='Checkmark' width={20} />}
    </div>
    <div className='circle'>
      <img width={35} src={icon} alt='Step Icon' />
    </div>
  </div>
)


const ConnectEmailsDomain = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [selectedProvider, setSelectedProvider] = useState('')
  const [connectionStatus, setConnectionStatus] = useState(id ? '' : 'connectingDomain')
  const [email, setEmail] = useState('')
  const [appProviderCode, setAppProviderCode] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [isLinked, setIsLinked] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [timeLeft, setTimeLeft] = useState(60)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isEmailPopup, setIsEmailPopup] = useState<any>(false)

  // const [id, setId] = useState<any>('')

  const [isPageLoading, setIsPageLoading] = useState<any>(id ? true : false)

  const handleSelection = (provider: any) => {
    setSelectedProvider(provider)
    setEmail('')
    setIsEmailValid(true)
  }

  const handleConnectionStatus = (status: any) => {
    setConnectionStatus(status)
  }

  const onSubmit = (data: any) => {
    console.log(data)
  }

  const validateEmail = (email: any) => {
    // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    // const regex = selectedProvider === 'gmail' ? /^[a-zA-Z0-9._%+-]+@gmail\.com$/ : /^[a-zA-Z0-9._%+-]+@(outlook|hotmail)\.com$/
    const regex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|hotmail\.com|outlook\.com)$/
    return regex.test(email)
  }

  const handleEmailTest = () => {
    setIsEmailPopup(true)
  }

  const handleCrmTicketRasiseTest = () => {
    console.log(' handleCrmTicketRasiseTest')
  }

  const handleVerificationEmail = () => {
    setIsButtonDisabled(true)
    setShowTimer(true)
  }

  const addEmailSettings = async () => {
    setIsLoading(true)
    const isValid = validateEmail(email)
    if (email && isValid) {
      setIsEmailValid(isValid)
    } else {
      setIsEmailValid(false)
      return
    }

    let body = {
      email: email,
      emailProviderType: selectedProvider === 'gmail' ? 0 : 1,
    }

    await ApiPost('corporate/email_setting', body)
      .then((res) => {
        setIsLoading(false)
        handleConnectionStatus('checkingPassword')
        navigate(`/settings/crm-residents/channels/emails/connect-email-domain/${res?.data?.data?._id}`)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateEmailSettings = async () => {
    setIsLoading(true)

    let body = {
      id: id,
      appPassword: appProviderCode,
    }

    await ApiPut('corporate/email_setting', body)
      .then((res) => {
        emailVerification()
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updatePasswordStatus = async (passwordStatus: any) => {
    setIsLoading(true)

    let body = {
      id: id,
      isPasswordVerified: passwordStatus,
    }

    await ApiPut('corporate/email_setting', body)
      .then((res) => {
        // emailVerification()
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const emailVerification = async () => {
    setIsLoading(true)

    let body = {
      emailSettingId: id,
      redirectURL: `${window.location.href}`,
    }

    await ApiPost('corporate/email_setting/sent/email_verification', body)
      .then((res) => {
        console.log(res)
        // setRequestTableData(res?.data?.data?.email_setting)
        setTimeout(() => {
          setIsPasswordValid(true)
          setIsLinked(true)
          updatePasswordStatus(true)
        }, 200)
      })
      .catch((err) => {
        setIsLoading(false)
        setIsPasswordValid(false)
        updatePasswordStatus(false)
        // ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const resendVerification = async () => {
    setIsVerified(false)
    setIsButtonDisabled(true)
    setShowTimer(true)

    let body = {
      emailSettingId: id,
      redirectURL: `${window.location.href}`,
    }

    await ApiPost('corporate/email_setting/sent/email_verification', body)
      .then((res) => {
        //
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getSingleEmailSetting = async () => {
    // setIsLoading(true)
    setIsPageLoading(true)

    await ApiGet(`corporate/email_setting/${id}`)
      .then((res) => {
        const emailData = res?.data?.data?.[0]
        console.log(emailData)

        setSelectedProvider(emailData?.emailProviderType === 0 ? 'gmail' : 'outlook')
        setEmail(emailData?.email)
        setIsEmailValid(true)
        setAppProviderCode(emailData?.appPassword)
        setIsLinked(emailData?.isPasswordVerified ?? false)
        setIsVerified(emailData?.isEmailVerified ?? false)

        if (emailData?.email == '' && emailData?.emailProviderType == '') {
          handleConnectionStatus('connectingDomain')
        } else if (emailData?.appPassword == '' || emailData?.appPassword === null || emailData?.isPasswordVerified === false) {
          handleConnectionStatus('checkingPassword')
        } else {
          handleConnectionStatus('emailVerification')
        }

        setTimeout(() => {
          setIsPageLoading(false)
        }, 1000)
      })
      .catch((err) => {
        setIsLoading(false)
        setIsPageLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const completeEmailVerification = async (token: any) => {
    let body = {
      token: token,
    }

    await ApiPost('corporate/email_setting/complete/email_verification', body)
      .then((res) => {
        getSingleEmailSetting()
      })
      .catch((err) => {
        getSingleEmailSetting()
        setIsPageLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const goToVerification = () => {
    handleConnectionStatus('emailVerification')
    setIsVerified(false)
    setIsButtonDisabled(true)
    setShowTimer(true)
  }

  // useEffect(() => {
  //   if (!id) return
  //  setTimeout(() => {
  //   if (email == '' && selectedProvider == '') {
  //     handleConnectionStatus('connectingDomain')
  //   }
  //   else if(appProviderCode == ''){
  //     handleConnectionStatus('checkingPassword')
  //   }
  //   else{
  //     handleConnectionStatus('emailVerification')
  //   }

  //  },0)

  // }, [])

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     if (email) {
  //       setEmailValue(email)
  //       const isValid = validateEmail(email)
  //       setIsEmailValid(isValid)
  //     } else {
  //       setEmailValue('')
  //       setIsEmailValid(false)
  //     }
  //   }, 800) // delay time in milliseconds

  //   return () => {
  //     clearTimeout(handler)
  //   }
  // }, [email])

  useEffect(() => {
    let timer: any
    if (showTimer && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
    } else if (timeLeft === 0) {
      setIsButtonDisabled(false)
      setShowTimer(false)
      setTimeLeft(60) // Reset to 60 seconds
      clearInterval(timer)
    }
    return () => clearInterval(timer) // Cleanup interval on component unmount or when resetting
  }, [timeLeft, showTimer])

  useEffect(() => {
    if (id) {
      // console.log(id)
      const token = window.location.href.split('?token=')?.[1]
      // console.log(token)
      if (id && !token) {
        getSingleEmailSetting()
      }
      if (id && token) {
        completeEmailVerification(token)
      }
    }
  }, [])

  return (
    <>
      {!isPageLoading ? (
        <div id='' className='pt-0 mt-0 px-2 ps-1' style={{position: 'relative'}}>
          <div className='d-flex align-items-center mb-5'>
            <div className='d-flex align-items-start'>
              <span className='rounded-circle cursor-pointer' onClick={() => navigate(`/settings/crm-residents/channels/emails`)}>
                <img src={backArrow} height={14} width={14} className='me-4' />
              </span>
              <div>
                <h2 className='page-heading m-0 head-text'>{'Connect your Email Domain'}</h2>
              </div>
            </div>
          </div>
          <div className='card card-flush  px-7' style={{paddingBottom: '20px', paddingTop: '20px'}}>
            {/* Icons */}
            <div className='row px-7  m-0' style={{paddingTop: '30px', paddingBottom: '20px'}}>
              <div className='col-md-12 px-0'>
                <div className='step-progress'>
                  <Step
                    isCheck={selectedProvider !== '' && email !== '' && isEmailValid}
                    status={connectionStatus === 'connectingDomain'}
                    icon={connectionStatus === 'connectingDomain' ? domainIconGreen : domainIconGray}
                  />
                  <div className='line'></div>
                  <Step
                    isCheck={isLinked}
                    status={connectionStatus === 'checkingPassword'}
                    icon={connectionStatus === 'checkingPassword' ? passwordIconGreen : passwordIconGray}
                  />
                  <div className='line'></div>
                  <Step
                    isCheck={isVerified}
                    status={connectionStatus === 'emailVerification'}
                    icon={connectionStatus === 'emailVerification' ? emailVerificationIconGreen : emailVerificationIconGray}
                  />
                </div>
              </div>
            </div>

            {/* email provider section */}
            {connectionStatus === 'connectingDomain' && (
              <>
                <div className='row px-7 m-0' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                  <div className='col-md-12 px-0 '>
                    <div className='email-provider-selection'>
                      <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                        Select Email Provider
                      </h4>
                      <h4 className='my-2 light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                        <label>Which email provider is your email address currently connected with?</label>
                      </h4>
                      <div className='providers white-dark-theme-color'>
                        <div className={`provider ${selectedProvider === 'gmail' ? 'selected' : ''}`} onClick={() => handleSelection('gmail')}>
                          <span>Gmail</span>
                          <img src={gmailIcon} alt='Gmail' />
                        </div>
                        <div className={`provider ${selectedProvider === 'outlook' ? 'selected' : ''}`} onClick={() => handleSelection('outlook')}>
                          <span>Outlook</span>
                          <img src={outlookIcon} alt='Outlook' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* email provider section */}
                {selectedProvider && (
                  <>
                    <div className={`row px-7 m-0`} style={{paddingTop: '40px', paddingBottom: '20px'}}>
                      <div className='col-md-12 px-0 '>
                        <div className='email-provider-selection'>
                          <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                            Enter Email Address
                          </h4>
                          <h4 className='my-2 light-dark-theme-color' style={{color: '#7f7f7f', width: '100%'}}>
                            <label>Please enter the email address which you would like to connect on Propertise:</label>
                          </h4>
                        </div>
                      </div>

                      {/* form */}
                      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                      <div className='col-md-8 px-0 mx-auto'>
                        <div className='d-flex align-items-center justify-content-center' style={{columnGap: '20px'}}>
                          <img src={atIcon} alt='at Icon' width={20} />
                          {/* EMAIL */}
                          <h4 className='my-2 test' style={{width: '40%'}}>
                            <input
                              type='text'
                              className={`form-control form-control-solid mytest ${isEmailValid ? 'inputBorderGreen' : 'inputBorderRed'}`}
                              placeholder='Enter email address'
                              autoComplete='off'
                              style={{backgroundColor: '#fff'}}
                              name='email'
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value.replace(/\s+/g, ''))
                              }}
                            />
                          </h4>
                        </div>

                        <div style={{width: '40%', paddingLeft: '20px', margin: 'auto'}}>
                          {isEmailValid === false && (
                            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fs-4'>
                              Please enter a valid Email Address
                            </p>
                          )}
                        </div>

                        {/* button */}
                        <div className='row px-7' style={{paddingTop: '60px'}}>
                          <div className='col-md-8 px-0 mx-auto'>
                            <button
                              onClick={() => addEmailSettings()}
                              disabled={email?.trim() === ''}
                              type='button'
                              className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                              style={{width: '73%'}}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </>
                )}
              </>
            )}

            {connectionStatus === 'checkingPassword' && (
              <>
                {/* email provider section */}
                {!isLinked ? (
                  <>
                    <div className='row px-7 m-0' style={{paddingTop: '20px', paddingBottom: '20px', position: 'relative'}}>
                      <div className='col-md-5 px-0 mx-auto'>
                        <div className='email-provider-selection'>
                          <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                            Enter Application Password
                          </h4>
                          <p className='my-2 light-dark-theme-color' style={{color: '#7f7f7f', width: '100%', fontWeight: '500', fontSize: '12px'}}>
                            <label>
                              To link your email to your Propertise Account, please enter the application password generated by your email provider
                              for your email address account:
                            </label>
                          </p>
                        </div>
                      </div>

                      <div className='col-md-8 px-0 mx-auto'>
                        <div className='d-flex align-items-center justify-content-center' style={{columnGap: '20px'}}>
                          <img src={lockIcon} alt='Lock Icon' width={20} />
                          {/* password */}
                          <h4 className='my-2 test' style={{width: '40%'}}>
                            <input
                              type='text'
                              className='form-control form-control-solid mytest inputBorderGreen'
                              placeholder='Enter App Password'
                              autoComplete='off'
                              style={{
                                backgroundColor: '#fff',
                                textAlign: 'center',
                                padding: '0 10px',
                              }}
                              name='password'
                              value={appProviderCode}
                              onChange={(e) => {
                                setAppProviderCode(e.target.value.replace(/\s+/g, ''))
                              }}
                            />
                          </h4>
                        </div>
                        <div style={{width: '40%', paddingLeft: '20px', margin: 'auto'}}>
                          {appProviderCode && !isPasswordValid && (
                            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fs-4'>
                              Incorrect application password. Please enter the application password for the email address you are connecting.
                            </p>
                          )}
                        </div>

                        {/* button */}
                        <div className='col-md-8 px-0 mx-auto' style={{paddingTop: '60px'}}>
                          <button
                            disabled={appProviderCode?.trim() == ''}
                            onClick={() => updateEmailSettings()}
                            type='button'
                            className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                            style={{width: '73%'}}
                          >
                            Connect
                          </button>
                        </div>
                      </div>

                      {/* card */}
                      <div className='col-md-6 px-0 mx-auto'>
                        <div
                          className='card card-flush'
                          style={{
                            paddingBottom: '10px',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            marginTop: '40px',
                            backgroundColor: '#e2faf9',
                            boxShadow: '0px 0px 12px 0px rgba(18, 32, 78, 0.2)',
                          }}
                        >
                          {selectedProvider === 'gmail' ? (
                            <div className='row px-0 m-0' style={{paddingTop: '0px', paddingBottom: '0px'}}>
                              <Col xs={12} className='mb-4'>
                                <div className='d-flex align-items-center'>
                                  <img src={informationIcon} alt='Lock Icon' width={25} className='me-4' />
                                  <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                                    How to retrieve your application password from your Google Account:
                                  </h4>
                                </div>
                              </Col>

                              <Col xs={12} className='text-center mb-4'>
                                <div className='video-container'>
                                  {/* Replace the src with the path to your video */}
                                  <video controls className='video-content'>
                                    <source src='/path-to-video.mp4' type='video/mp4' />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              </Col>
                              <Col xs={12} className='p-0 m-0'>
                                <ol className='p-2 m-0 steps-list'>
                                  <li>
                                    <strong className='head-text'>Step 1:</strong>
                                    <p className='light-dark-theme-color'>
                                      Sign in to your Google Account using the email address you are connecting (
                                      <a href='https://myaccount.google.com' target='_blank' rel='noopener noreferrer'>
                                        www.myaccount.google.com
                                      </a>
                                      ).
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 2:</strong>
                                    <p className='light-dark-theme-color'>
                                      Head over to the left navigation panel and click on <strong className='head-text'>‘Security’.</strong>
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 3:</strong>
                                    <p className='light-dark-theme-color'>
                                      In the Security page, scroll down and click on <strong className='head-text'>‘2-Step Verification’</strong>{' '}
                                      found under the <strong className='head-text'>‘How you sign into Google’</strong> section.
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 4:</strong>
                                    <p className='light-dark-theme-color'>
                                      Once you’ve accessed the 2-Step Verification page, head down to the{' '}
                                      <strong className='head-text'>‘App Passwords’</strong> section and click the next{' '}
                                      <strong className='head-text'>{'‘>’'}</strong> arrow.
                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-dark-theme-color'>
                                      Note: If the <strong className='head-text'>‘App Passwords’</strong> section is not available, please ensure your
                                      2-step verification is switched <strong className='head-text'>‘on’</strong>, otherwise you will not be able to
                                      generate your ‘App Password’.
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 5:</strong>
                                    <p className='light-dark-theme-color'>
                                      Give your App Password a name under the ‘App name’ field and click{' '}
                                      <strong className='head-text'>‘Create’</strong>. A new password will be generated.
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 6:</strong>
                                    <p className='light-dark-theme-color'>
                                      Copy the App Password generated and head back to your ‘Email Domain Set Up’ page for the email you are
                                      connecting on Properties and paste the password in the ‘App Password’ field and click{' '}
                                      <strong className='head-text'>‘Connect’</strong>.
                                    </p>
                                  </li>
                                </ol>
                              </Col>
                            </div>
                          ) : (
                            <div className='row px-0 m-0' style={{paddingTop: '0px', paddingBottom: '0px'}}>
                              <Col xs={12} className='mb-4'>
                                <div className='d-flex align-items-center'>
                                  <img src={informationIcon} alt='Lock Icon' width={25} className='me-4' />
                                  <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                                    How to retrieve your application password from your Microsoft Account:
                                  </h4>
                                </div>
                              </Col>

                              <Col xs={12} className='text-center mb-4'>
                                <div className='video-container'>
                                  {/* Replace the src with the path to your video */}
                                  <video controls className='video-content'>
                                    <source src='/path-to-video.mp4' type='video/mp4' />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              </Col>
                              <Col xs={12} className='p-0 m-0'>
                                <ol className='p-2 m-0 steps-list'>
                                  <li>
                                    <strong className='head-text'>Step 1:</strong>
                                    <p className='light-dark-theme-color'>
                                      Sign in to your Microsoft Account (
                                      <a href='https://account.microsoft.com' target='_blank' rel='noopener noreferrer'>
                                        account.microsoft.com
                                      </a>
                                      ).
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 2:</strong>
                                    <p className='light-dark-theme-color'>
                                      Head over to the left navigation panel and click on <strong className='head-text'>‘Security’.</strong>
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 3:</strong>
                                    <p className='light-dark-theme-color'>
                                      Go to “Account” section There will be button <strong className='head-text'>“Mange how I sign in.”</strong> After
                                      clicking, check 2-step verification; it should be <strong className='head-text'>"ON.”</strong> Then
                                      automatically, <strong className='head-text'>“App passwords”</strong> appear in bottom web pages.
                                    </p>
                                  </li>
                                  <li>
                                    <strong className='head-text'>Step 4:</strong>
                                    <p className='light-dark-theme-color'>
                                      Please ensure you do not change the <strong className='head-text'>‘App Password’</strong> you have generated
                                      otherwise the connection will be lost as Outlook only premises one App Password at a time for each email
                                      address.
                                    </p>
                                  </li>
                                </ol>
                              </Col>
                            </div>
                          )}
                        </div>
                      </div>
                      {isLoading && <div className='layer'></div>}
                      {isLoading && (
                        <div
                          className='d-flex flex-column justify-content-center align-items-center w-100'
                          style={{height: '45vh', zIndex: 100, position: 'absolute'}}
                        >
                          <CircularProgress style={{color: '#0D9E91'}} />
                          <p className='my-2' style={{width: '100%', color: '#0D9E91', fontSize: '13px', textAlign: 'center'}}>
                            Connecting
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='row px-7 m-0 d-flex flex-column' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                      <div className='col-md-5 px-0 mx-auto text-center mb-7'>
                        <h4 className='my-2 head-text ' style={{width: '100%', fontWeight: 'bold'}}>
                          Email Linked Successfully!
                        </h4>
                      </div>
                      {/* card */}
                      <div className='col-md-6 px-0 mx-auto text-center'>
                        <div
                          className='card card-flush'
                          style={{
                            paddingBottom: '10px',
                            paddingTop: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',

                            // boxShadow: '0px 0px 12px 0px rgba(18, 32, 78, 0.2)',
                          }}
                        >
                          <Row>
                            <Col xs={12} className='mb-3'>
                              <img src={emailLinedIcon} alt='Email Linked' width={80} />
                            </Col>
                            <Col xs={12}>
                              <h4 className='my-2' style={{width: '100%', fontWeight: 'bold', color: '#146C6A', textDecoration: 'underline'}}>
                                {email ? email : '-'}
                              </h4>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                Your email was linked successfully.
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                Please check your email to complete the connection.
                                {/* Please verify your email to complete the connection. */}
                              </p>
                            </Col>
                            <Col xs={12}>
                              <div className='row px-7' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <div className='col-md-8 px-0 mx-auto'>
                                  <button
                                    // disabled={emailValue.trim() == '' && !isEmailValid}
                                    // onClick={() => emailVerification()}
                                    onClick={() => goToVerification()}
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                                    style={{width: '85%'}}
                                  >
                                    Verify Email
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            {connectionStatus === 'emailVerification' && (
              <>
                {isVerified ? (
                  <>
                    <div className='row px-7 m-0 d-flex flex-column' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                      <div className='col-md-5 px-0 mx-auto text-center mb-7'>
                        <h4 className='my-2 head-text ' style={{width: '100%', fontWeight: 'bold'}}>
                          Verified!
                        </h4>
                      </div>
                      {/* card */}
                      <div className='col-5 px-0 mx-auto text-center'>
                        <div
                          className='card card-flush'
                          style={{
                            paddingBottom: '20px',
                            paddingTop: '20px',
                            paddingLeft: '36px',
                            paddingRight: '36px',

                            // boxShadow: '0px 0px 12px 0px rgba(18, 32, 78, 0.2)',
                          }}
                        >
                          <Row>
                            <Col xs={12} className='mb-3'>
                              <img src={emailVerifiedIcon} alt='Email Verified' width={80} />
                            </Col>
                            {/* <Col xs={12}>
                            <h4 className='my-2' style={{width: '100%', fontWeight: 'bold', color: '#146C6A', textDecoration: 'underline'}}>
                              maintenance@client.com
                            </h4>
                          </Col> */}
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                Your email has been successfully verified and is now connected to your Propertise account.
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                You can now link this email to your request tickets on your CRM Module and start generating and responding to requests
                                received from this email.
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 head-text' style={{width: '100%', color: '#000000', fontSize: '13px', fontWeight: 'bold'}}>
                                Would you like to link this email to request tickets now?
                              </p>
                            </Col>
                            <Col xs={12}>
                              <div className='row px-7' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                                <div className='col-md-8 px-0 mx-auto'>
                                  <button
                                    // disabled={emailValue.trim() == '' && !isEmailValid}
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 blue-btn justify-content-center mx-auto'
                                    style={{width: '100%'}}
                                  >
                                    Link To Request Tickets
                                  </button>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className='row px-7' style={{paddingTop: '0px', paddingBottom: '20px'}}>
                                <div className='col-md-8 px-0 mx-auto'>
                                  <button
                                    // disabled={emailValue.trim() == '' && !isEmailValid}
                                    onClick={() => navigate(`/settings/crm-residents/channels/emails`)}
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                                    style={{width: '100%'}}
                                  >
                                    Later
                                  </button>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 light-dark-theme-color' style={{width: '100%', color: '#7f7f7f', fontSize: '13px'}}>
                                You can link your email to request tickets at any time by going to your <strong>‘Settings’</strong> and clicking on{' '}
                                <strong>‘Ticket Properties’</strong> under the CRM section.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='row px-7 m-0 d-flex flex-column' style={{paddingTop: '20px', paddingBottom: '20px'}}>
                      <div className='col-md-5 px-0 mx-auto text-center mb-7'>
                        <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                          Verification Sent!
                        </h4>
                      </div>
                      {/* card */}
                      <div className='col-5 px-0 mx-auto text-center'>
                        <div
                          className='card card-flush'
                          style={{
                            paddingBottom: '20px',
                            paddingTop: '20px',
                            paddingLeft: '36px',
                            paddingRight: '36px',

                            // boxShadow: '0px 0px 12px 0px rgba(18, 32, 78, 0.2)',
                          }}
                        >
                          <Row>
                            <Col xs={12} className='mb-3'>
                              <img src={emailVerificationIcon} alt='Email Verification' width={80} />
                            </Col>
                            {/* <Col xs={12}>
                            <h4 className='my-2' style={{width: '100%', fontWeight: 'bold', color: '#146C6A', textDecoration: 'underline'}}>
                              maintenance@client.com
                            </h4>
                          </Col> */}
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                We’ve sent an email to:
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p
                                className='my-2 white-dark-theme-color'
                                style={{width: '100%', color: '#000000', fontSize: '13px', fontWeight: 'bold', textDecoration: 'underline'}}
                              >
                                {email}
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                Head over to your inbox to complete your email verification. You will automatically be re-directed to this page.
                              </p>
                            </Col>
                            <Col xs={12}>
                              <p className='my-2 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                Once your verification is complete, your email will be connected to your Propertise account.
                              </p>
                            </Col>
                            <Col xs={12} style={{paddingTop: '20px'}}>
                              <div className='row px-7' style={{paddingTop: '0px', paddingBottom: '10px'}}>
                                <div className='col-md-8 px-0 mx-auto'>
                                  <button
                                    onClick={resendVerification}
                                    disabled={isButtonDisabled}
                                    type='button'
                                    className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                                    style={{width: '100%'}}
                                  >
                                    Resend Verification Email
                                  </button>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} style={{paddingTop: '0px', paddingBottom: '20px'}}>
                              {showTimer && (
                                <p className='m-0 white-dark-theme-color' style={{width: '100%', color: '#000000', fontSize: '13px'}}>
                                  {`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}
                                </p>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <Row style={{paddingBottom: '20px', position: 'relative'}}>
                  {!isVerified && <div className='layer2'></div>}
                  <div className='d-flex  align-items-center mt-7 pb-5 mb-7  mx-3' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>
                  <Row className='d-flex flex-column'>
                    <div className='col-md-5 px-0 mx-auto text-center'>
                      <h4 className='my-2 head-text' style={{width: '100%', fontWeight: 'bold'}}>
                        Run Test
                      </h4>
                    </div>
                    <div className='col-6 mx-auto text-center mt-7'>
                      <Row>
                        <TestCard
                          cardIcon={emailTestIcon}
                          cardHeading='Email'
                          cardSubHeading='Run a test to receive a ticket…'
                          handleClick={handleEmailTest}
                        />
                        <TestCard
                          cardIcon={crmTicketTestIcon}
                          cardHeading='Raise Ticket'
                          cardSubHeading='Run a test to receive a ticket as if one of your tenants has raised a request by emailing you on your email address.'
                          handleClick={handleCrmTicketRasiseTest}
                        />
                      </Row>
                    </div>
                  </Row>
                </Row>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#0D9E91'}} />
        </div>
      )}
      {isEmailPopup && (
        <SentEmailPopup
        emailId={id}
          show={isEmailPopup}
          handleClose={() => {
            setIsEmailPopup(false)
          }}
        />
      )}
    </>
  )
}

export default ConnectEmailsDomain

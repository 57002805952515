import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import close from '../../../img/close.png'
import submitImg from '../../../img/Submit-White.png'
import Select from 'react-select'
import {PhoneInput} from 'react-international-phone'
import 'react-international-phone/style.css'
import noData from '../../../img/NoData1.svg'
import {ApiGet, ApiPost, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import download from '../../../img/download.png'
import eye from '../../../img/eye-blue.png'
import trash from '../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import camera from '../../../img/camera.png'

interface ButtonProps {
  show: any
  handleClose: any
  getDetails: any
}

const EditCorporateDetails = ({handleClose, show, getDetails}: ButtonProps) => {
  const [title, setTitle] = useState<any>()
  const [description, setDescription] = useState<any>()
  const [images, setImages] = useState<any>([])

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  //
  const addFeedBack = () => {
    const body = {
      name: title,
      description: description,
      images: images,
    }

    ApiPost('corporate/feedback', body)
      .then((res: any) => {
        handleClose()
        SuccessToast('Feedback has been added successfully!!!')
        getDetails()
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const onFilesAddedSingle = async (event: any) => {

    let values = [...images]
    let ind : any = images?.length

    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {

    let fileLists_single: any = []
    const files = event.target.files
    fileLists_single = Array.from(files)

    let formData: any = new FormData()
    let data: any = fileLists_single[i]
    let fileURL: any = URL.createObjectURL(data)
    let filetypes: any = fileLists_single[i].type
    data.fileURL = fileURL
    formData.append('image', data)

    let url = 'upload/originalname/image/feedback'

    await ApiPost(`${url}`, formData)
      .then((res) => {
        values[ind] = res?.data?.data?.image
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })

      ind = ind + 1

    }

    setImages(values)

        let y: any = []
        for (let i = 0; i < values?.length; i++) {
          y[i] = {
            src: Bucket + values[i],
          }
        }

        setSlides(y)
        setTotalImages(y?.length)
        console.log(y)
  }
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '93px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'> Add Feedback</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className=''>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-2'></Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end'>
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center text-end pe-4'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => addFeedBack()}
              >
                <img
                  src={submitImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '15px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '}
                {'  '}
                Submit
              </button>
            </Col>
          </Row>
          <Row className='align-items-end mt-5'>
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center  px-3 mb-5'>
              <label className='required mb-2 label-color'>Title</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Title...'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
                value={title}
                onChange={(event: any) => {
                  setTitle(event?.target.value)
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className='align-items-center px-3  mb-5'>
              <label className='required mb-2 label-color'>Description</label>
              <textarea
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Desciption...'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
                value={description}
                onChange={(event: any) => {
                  setDescription(event?.target.value)
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className='d-flex'>
                <div className='ms-auto'>
                  <label
                    title='Add New File'
                    htmlFor='uploadPassport'
                    className='btn btn-sm fw-bold  ms-auto'
                    style={{backgroundColor: '', border: '2px solid #35475e', color: '#35475e'}}
                  >
                    <img src={download} height={15} width={15} className='me-3' />
                    Upload File{' '}
                  </label>
                  <input
                    type='file'
                    hidden
                    id='uploadPassport'
                    className='form-control form-control-solid'
                    placeholder='upload'
                    name='passport'
                    multiple
                    onChange={(e: any) => {
                      onFilesAddedSingle(e)
                    }}
                  />
                </div>
              </div>
            </Col>
            <Row style={{}} className='mt-3'>
              {images?.length > 0 && (
                <>
                  {/* <div className='mw-350px d-flex align-items-start'> */}
                    {images?.map((img: any, ind: any) => {
                      return (
                        <div className='imgs-set d-flex col-md-3 mb-4'>
                          <div className='d-flex mw-350px me-3'>
                          <img src={Bucket + img} width='80' height='80' />
                          </div>
                          <div className='d-grid'>
                            <img
                              style={{
                                // marginLeft: '-13px',
                                // marginTop: '-10px',
                                cursor: 'pointer',
                              }}
                              src={eye}
                              width='23'
                              height='23'
                              onClick={() => setIndex(ind)}
                            />
                            <img
                              style={{
                                // marginLeft: '-13px',
                                marginTop: '-25px',
                                cursor: 'pointer',
                              }}
                              src={trash}
                              width='22'
                              height='22'
                              onClick={(e) => {
                                const values = [...images]
                                values.splice(ind, 1)
                                setImages(values)
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  {/* </div> */}
                </>
              )}
            </Row>
          </Row>
        </div>
      </Modal>

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />
    </div>
  )
}

export default EditCorporateDetails

import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import {ApiPost, ApiGet, ApiPut, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import trashImg from '../../../../img/trash.png'
import moment from 'moment'
import notifyWhite from '../../../../img/notification-white.png'
import notifyBlack from '../../../../img/notification.png'
import {useParams} from 'react-router-dom'

interface ScheduleReminderProps {
  show: boolean
  handleClose: any
  updateData?: any
  expData?: any
}

const ScheduleReminder: React.FC<ScheduleReminderProps> = ({
  show,
  handleClose,
  updateData,
  expData,
}) => {
  const {flg, id} = useParams()
  const [open, setOpen] = useState<any>(false)
  const [singleSchedule, setSingleSchedule] = useState<any>({
    daysBefore: '',
    reminderDate: null,
    status: 0,
  })

  const [schedules, setSchedules] = React.useState(
    expData.reminders?.length > 0 ? expData.reminders : []
  )

  const handleSchedule = (e: any) => {
    if (e.target.value != '') {
      const values = {...singleSchedule}
      values.daysBefore = e.target.value

      let reminderDate: any = new Date(expData?.dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      if (new Date(reminderDate) < new Date()) {
        ErrorToast('Reminder cannot be set in a Past Date!')
        values.reminderDate = null
        setSingleSchedule(values)
      } else {
        values.reminderDate = new Date(reminderDate).toLocaleDateString('fr-CA')
        setSingleSchedule(values)
      }
    } else {
      const values = {...singleSchedule}
      values.daysBefore = ''

      let reminderDate: any = new Date(expData?.dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      values.reminderDate = null

      setSingleSchedule(values)
    }
  }

  const isDuplicateReminder = (reminderDate: string) => {
    return schedules.some((schedule: any) => schedule.reminderDate === reminderDate)
  }

  const deleteReminder = (val: any) => {
    const body = {
      expense: {
        _id: id,
        saveStatusDateTime: new Date().toISOString(),
        expenseStatusDateTime: new Date().toISOString(),
      },
      delete_expense_payment_item: [{id: val}],
    }
    ApiPost(`corporate/expense/add_all_in_one`, body)
      .then((res) => {})
      .catch((err) => {})
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          updateData(schedules, false)
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-7'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: '500px',
            }}
          >
            <div className='d-flex align-items-start'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                {'Schedule Payment Reminders'}
              </h2>{' '}
              <div className='d-flex ms-auto align-items-center'>
                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    updateData(schedules, false)
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <p className=' pt-3 mb-0'>
                Set the period in which you would like to be reminded for the payment of this
                expense:
              </p>
            </div>

            <div className='d-flex align-items-center'>
              <p className=' pt-3 mb-0 fs-3 fw-semibold'>
                Expense Due Date:{' '}
                {`${moment?.utc(expData?.dueDate)?.local()?.format('DD.MM.YYYY')}`}
              </p>
            </div>

            <div className='d-flex align-items-center mt-3'>
              <p className='my-2' style={{color: 'black'}}>
                <label style={{width: 'fit-content'}} className=' me-5'>
                  {' '}
                  Days before payment is due
                </label>
              </p>

              <input
                //   {...register('documentName', {required: true})}
                type='number'
                className='form-control form-control-solid mytest tenancy-control  bg-white'
                name='documentName'
                value={singleSchedule?.daysBefore}
                style={{height: '35px', width: '150px'}}
                placeholder={'Enter days'}
                onChange={(e: any) => {
                  const values = {...singleSchedule}
                  values.daysBefore = e.target.value
                  setSingleSchedule(values)

                  handleSchedule(e)
                }}
                // onBlur={(e) => handleSchedule(e)}
                 autoComplete="off"
              />
              <div className='d-flex align-items-center ms-auto min-w-175px'>
                <h4 className='me-7'>Reminder </h4>
                <h4 className='ms-auto'>
                  {singleSchedule?.reminderDate != null
                    ? `${moment?.utc(singleSchedule?.reminderDate)?.local()?.format('DD.MM.YYYY')}`
                    : '-'}
                </h4>
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-end my-5 mt-1'>
              <button
                type='button'
                className='btn btn-sm fw-bold  green-submit-btn ps-3 status-w-180 mt-10 mb-5'
                disabled={singleSchedule?.daysBefore == '' || singleSchedule?.reminderDate == null}
                onClick={() => {
                  if (isDuplicateReminder(singleSchedule.reminderDate)) {
                    ErrorToast('Reminder for this date already exists!')
                  } else {
                    let values: any = [...schedules]
                    values.push(singleSchedule)
                    setSchedules(values)
                    setSingleSchedule({daysBefore: '', reminderDate: null, status: 0})
                  }
                }}
              >
                <img src={notifyWhite} height={18} width={18} className='me-6' />
                {'Schedule'}
              </button>
            </div>

            <div className='row separator mt-1'></div>

            <div className='d-flex align-items-start mt-5'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                {'Reminder Schedule '}
              </h2>{' '}
            </div>

            <div
              className='table-responsive mt-1'
              style={{minHeight: '100px', maxHeight: '300px', overflow: 'auto'}}
            >
              <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                    <th className='text-start min-w-100px'>Reminder</th>
                    <th className='text-start min-w-100px'>Status</th>
                    <th className='text-start min-w-100px'></th>
                  </tr>
                </thead>

                <tbody className='fw-semibold text-gray-600'>
                  {schedules
                    ?.sort((a: any, b: any) => {
                      const dateA = moment(a.reminderDate)
                      const dateB = moment(b.reminderDate)
                      return dateA.diff(dateB)
                    })
                    ?.map((sch: any, index: any) => {
                      return (
                        <tr className='cursor-pointer'>
                          <td className='text-start '>
                            <div className='d-flex'>
                              {' '}
                              <img src={notifyBlack} height={18} width={18} className='me-6' />
                              {sch?.reminderDate != null
                                ? `${moment?.utc(sch?.reminderDate)?.local()?.format('DD.MM.YYYY')}`
                                : '-'}
                            </div>
                          </td>
                          <td className='text-start '>
                            <div className={sch?.status == 1 ? 'text-warning' : 'select-color'}>
                              <b>{sch?.status == 1 ? 'Notified' : 'Upcoming'}</b>
                            </div>
                          </td>
                          <td className='text-start '>
                            <img
                              src={trashImg}
                              height={18}
                              width={18}
                              className='mx-5 cursor-pointer'
                              onClick={() => {
                                let values = [...schedules]

                                if (flg == '1') values.splice(index, 1)
                                else updateData(sch?._id, true)
                                // deleteReminder(sch?._id)

                                setSchedules(values)
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
      </Modal>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </div>
  )
}

export default ScheduleReminder

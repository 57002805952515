import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import noData from '../../../../img/NoData1.svg'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import trashImg from '../../../../img/trash.png'
import swal from 'sweetalert2'
import {setComefrom} from '../../../../redux/counterSlice'
import ScheduleReminder from './ScheduleReminder'
import notifyGreen from '../../../../img/notification-green.png'
import notifyBlack from '../../../../img/notification.png'
import {useParams} from 'react-router-dom'

interface OtherAttachmentsProps {
  showw?: any
  handleClose?: any
  expenseData?: any
  updateParent?: any
  deleteReminder?: any
}

const OtherAttachments: React.FC<OtherAttachmentsProps> = ({
  showw,
  handleClose,
  expenseData,
  updateParent,
  deleteReminder,
}) => {
  const {state} = useLocation()
  const {flg, id} = useParams()
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [showSchedAdd, setShowSchedAdd] = React.useState<any>(false)

  let [expData, setExpData] = useState<any>(expenseData)

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})

  // console.log('/******************Reminders********************/')
  // console.log(expenseData)

  useEffect(() => {
    setExpData(expenseData)
  }, [expenseData])

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 me-3 min-hg-300 ms-3'}>
        <div className='d-flex  align-items-center mb-5'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Payment Reminders'}
          </h2>

          <div className='d-block ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-hollow-btn ms-auto btn-fit-content'
              onClick={() => {
                setMode('add')
                setSelectedRecord({
                  name: '',
                  URL: '',
                })
                setTimeout(() => {
                  setShowSchedAdd(true)
                }, 300)
              }}
              disabled={expData?.dueDate == null}
            >
              <img src={notifyGreen} height={18} width={18} className='me-4' /> Schedule Reminder
            </button>

            {expData?.dueDate == null && (
              <p className='text-danger mb-0 ms-5 pt-1'> Please select the due date.</p>
            )}
          </div>
        </div>

        <div className='table-responsive mt-7' style={{maxHeight: '300px', overflow: 'auto'}}>
          <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
            {/* <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px'>Reminder</th>
                <th className='text-start min-w-100px'>Status</th>
                <th className='text-start min-w-100px'></th>
              </tr>
            </thead> */}

            <tbody className='fw-semibold text-gray-600'>
              {expData.reminders
                ?.sort((a: any, b: any) => {
                  const dateA = moment(a.reminderDate)
                  const dateB = moment(b.reminderDate)
                  return dateA.diff(dateB)
                })
                ?.map((sch: any, index: any) => {
                  return (
                    <tr className=''>
                      <td className='text-start ps-0'>
                        <div className='d-flex'>
                          {' '}
                          <img src={notifyBlack} height={18} width={18} className='me-6' />
                          {sch?.reminderDate != null
                            ? `${moment?.utc(sch?.reminderDate)?.local()?.format('DD.MM.YYYY')}`
                            : '-'}
                        </div>
                      </td>
                      <td className='text-start '>
                        <div className={sch?.status == 1 ? 'text-warning' : 'select-color'}>
                          <b>{sch?.status == 1 ? 'Notified' : 'Upcoming'}</b>
                        </div>
                      </td>
                      <td className='text-start '>
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            let values = [...expData.reminders]
                            values.splice(index, 1)

                            updateParent(values)

                            if (flg != '1') deleteReminder(sch?._id)

                            setExpData(values)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>

      {showSchedAdd && expData && (
        <ScheduleReminder
          show={showSchedAdd}
          handleClose={() => setShowSchedAdd(false)}
          updateData={(data: any, check: any) => {
            if (check && flg != '1') {
              deleteReminder(data)
            } else {
              let values = {...expenseData}
              values.reminders = data
              setExpData(values)
              updateParent(data)
              setShowSchedAdd(false)
            }
          }}
          expData={expData}
        />
      )}
    </>
  )
}

export default OtherAttachments

import moment from 'moment'
import React, {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {Navigate} from 'react-router-dom'
import {ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import TenatsFilter from '../../Tenants/TenatsFilter'
import noData from '../../../../img/NoData1.svg'
import {BsArrowRightSquareFill} from 'react-icons/bs'

function MoveInOutTable({
  tabledata1,
  pageLimit1,
  handlePageClick1,
  calldatabyid,
  showTable,
  setmoveoutview,
  setmoveinview,
  index,
}: any) {
  // const calldatabyid = async (id: any, type: any) => {
  //   await ApiGet(`corporate/tenant_request?tenantRequestId=${id}&type=${type}`)
  //     .then((res) => {
  //       setbyiddata(res?.data?.data[0])
  //       //  setUnitDataApi(res?.data?.data)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  const openviewButton = (flg: any, i: number, val: boolean) => {
    console.log('\nflg123123123', flg, i, val)
    calldatabyid(flg, i)
    if (index == 0) {
      setmoveinview(val)
    } else {
      setmoveoutview(val)
    }
  }
  const nextPage = () => {
    handlePageClick1()
  }

  return (
    <div>
      {/* <div className='card card-flush container-xxl'> */}
      <div className='card-body pt-0 table-responsive mt-5'>
        <table
          className='table align-middle table-row-dashed fs-6 gy-5'
          id='kt_ecommerce_sales_table'
        >
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              {/* <th className='w-10px pe-2'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            data-kt-check='true'
                                            data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                                            value='1'
                                          />
                                        </div>
                                      </th> */}
              {/* move123 */}
              <th className='text-center min-w-100px'>Request ID</th>
              <th className='text-center min-w-150px'>Request Date</th>
              <th className={showTable == 1 ? 'text-center min-w-200px ' : 'd-none'}>
                Move In Schedule
              </th>
              <th className={showTable !== 1 ? 'text-center min-w-200px ' : 'd-none'}>
                Move Out Schedule
              </th>
              <th className='text-center min-w-100px'>Tenant ID</th>
              <th className='text-center min-w-200px'>Tenant Name</th>
              <th className='text-center min-w-100px'>Type</th>
              <th className='text-center min-w-100px'>Development</th>
              <th className='text-center min-w-100px'>Unit No. </th>
              <th className='text-center min-w-150px'>Contract No.</th>
              <th className='text-center min-w-200px'>Status</th>
              <th className='text-end min-w-100px'></th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {tabledata1?.length ? (
              tabledata1?.map((v: any, i: any) => {
                return (
                  <tr style={{height: '40px'}}>
                    <td className='text-center'>{v?.id}</td>
                    <td className='text-center'>{moment(v?.createdAt).format('DD-MM-YYYY')}</td>
                    <td className='text-center '>
                      {moment(v?.scheduleDate).format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className='text-center'>{v?.tenant?.[0]?.id}</td>
                    <td className='text-center'>
                      {v?.tenant?.[0]?.firstName + ' ' + v?.tenant?.[0]?.lastName}
                    </td>
                    <td className='text-center' data-order='2022-09-09'>
                      {v?.tenantType == 0 ? 'Main' : 'Sub'}
                    </td>
                    <td className='text-center' data-order='2022-09-11'>
                      {v?.unit?.[0]?.community?.length > 0
                        ? v?.unit?.[0]?.community?.[0]?.name
                        : v?.unit?.[0]?.building?.[0]?.name}
                    </td>
                    <td className='text-center' data-order='2022-09-11'>
                      {v?.unit?.[0]?.unitNo}
                    </td>
                    <td className='text-center' data-order='2022-09-11'>
                      {v?.unit?.[0]?.tenancy?.[0]?.contractNo}
                    </td>
                    <td className='text-center'>
                      {v?.status === 0
                        ? 'Received'
                        : v?.status === 1
                        ? 'Approved'
                        : v?.status === 2
                        ? 'Approved (Rescheduled)'
                        : v?.status === 3
                        ? 'Rejected'
                        : v?.status === 4
                        ? 'Cancelled'
                        : v?.status === 5
                        ? 'Awaiting Vendor Confirmation'
                        : v?.status === 6
                        ? 'Accepted'
                        : v?.status === 7
                        ? 'Booked'
                        : v?.status === 8
                        ? 'Variation Request'
                        : 'Canceled'}
                    </td>

                    <td className='text-center pe-0'>
                      <>
                      <BsArrowRightSquareFill
                                        color='#007a59'
                                        fontSize={18}
                                        style={{cursor: 'pointer'}}
                                        className='mx-3'
                                        onClick={() => {
                                          // setmoveinview(true)
                                          // calldatabyid(v?._id, 0)
                                          openviewButton(v?._id, index, true)
                                        }}
                                      />
                       
                      </>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={16} className='text-center'>
                  <img src={noData} alt='' width={350} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='row my-5'>
        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
          {/* <div className='dataTables_length' id='kt_ecommerce_sales_table_length'>
                                  <label>
                                    <select
                                      name='kt_ecommerce_sales_table_length'
                                      aria-controls='kt_ecommerce_sales_table'
                                      className='form-select form-select-sm form-select-solid'
                                    >
                                      <option value='10'>10</option>
                                      <option value='25'>25</option>
                                      <option value='50'>50</option>
                                      <option value='100'>100</option>
                                    </select>
                                  </label>
                                </div> */}
        </div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div
            className='dataTables_paginate paging_simple_numbers'
            id='kt_ecommerce_sales_table_paginate'
          >
            <ReactPaginate
              breakLabel='...'
              nextLabel='>'
              pageClassName='paginate_button page-item +'
              pageLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              previousClassName='paginate_button page-item previous disabled'
              nextClassName='paginate_button page-item next'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              onPageChange={nextPage}
              pageRangeDisplayed={5}
              pageCount={pageLimit1}
              previousLabel='<'
            />
          </div>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default MoveInOutTable

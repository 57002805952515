import React, {useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiPost,
  ApiPut,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import moment from 'moment'
import backArrow from '../../../img/back-arrow.png'
import trashImg from '../../../img/trash.png'
import Select from 'react-select'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import pencil from '../../../img/edit-pen.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ReactPaginate from 'react-paginate'
import addToListIcon from '../../../img/vendor-lists-add.png'
import Swal from 'sweetalert2'
import correct from '../../../img/correct.png'
import AddNewVL from './AddNewVL'
import { MultiSelect } from 'react-multi-select-component'

const ManageLists = () => {
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getVendors(event.selected + 1)
  }

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any>(false)

  const [open, setOpen] = React.useState(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [sortType, setSortType] = useState<any>([])
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [searchName, setSearchName] = useState<any>('')
  const [vendorsData, setVendorsData] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])

  let totalSelected: any = 0

  const getVendors = (pageVal:any) => {
    const body = {
      page: pageVal,
      limit: limit,
      searchName: searchName,
      sortType: sortType?.length > 0 ? sortType?.[0]?.value : 'A-Z',
    }

    ApiPost(`corporate/vendor_list/get`, body)
      .then((res: any) => {
        setVendorsData(res?.data?.data?.vendor_data)

        let values: any = []
        for (let i = 0; i < res?.data?.data?.vendor_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if(searchName?.trim()?.length > 0){
      setPage(1)
      getVendors(1)
     
    }
    else{
      getVendors(page)
    }
    
  }, [searchName, sortType])

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  //
  const deleteVendorList = () => {
    setIsLoading(true)
    let vIds: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = vendorsData[ind]?._id
        i++
      }
    })

    const body = {
      vendorListIds: vIds,
    }

    ApiPost(`corporate/vendor_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getVendors(page)

        let values = []

        for (let i = 0; i < vendorsData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const deleteSingleVendorList = (v: any) => {
    setIsLoading(true)
    let vIds: any = []

    vIds[0] = v?._id

    const body = {
      vendorListIds: vIds,
    }

    ApiPost(`corporate/vendor_list/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getVendors(page)

        let values = []

        for (let i = 0; i < vendorsData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const updateName = (data: any) => {
    setIsLoading(true)
    const body = {
      id: data?._id,
      name: newName,
    }
    ApiPut(`corporate/vendor_list`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        getVendors(page)

        setTimeout(() => {
          setEditFile(-1)
        }, 500)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const [showAddNewVL, setShowAddNewVL] = useState<any>(false)

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <div id='' className='pt-0 mt-0'>
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              {/* container-xxl */}
              <div className=''>
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      navigate('/vendor-management')
                    }}
                  >
                    <img src={backArrow}  height={14} width={14} />
                  </span>
                  <h2 className='m-0 head-text ms-2'>{'Manage Vendors Lists'}</h2>
                </div>
              </div>
            </div>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='card card-flush py-5 mt-5 pt-0 px-5'>
            <div
              className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'
              // style={{ justifyContent: "space-between" }}
            >
              <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
                Search{' '}
              </label>

              {/* Seach by Vendor */}
              <div className='me-4 xyz'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Search Name...'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchName}
                    onChange={(e: any) => setSearchName(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              {/* <div className='me-4 xyz'>
                <Select
                  placeholder={'Sort'}
                  styles={customStyles}
                  isSearchable={false}
                  options={sortOptions}
                  name='sort'
                  onChange={(e: any) => {
                    setSortType(e.value)
                  }}
                />
              </div> */}

              <div className='me-4 test jobs'>
              <div className='property-test d-none-multi-checbox'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                    sortType?.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={sortOptions}
                    value={sortType}
                    onChange={(e: any) => {
                      let newVal: any = e
                      if (e?.length > 1) newVal = e.slice(1)
                        setSortType(newVal)
                    }}
                    labelledBy=''
                    overrideStrings={{
                      allItemsAreSelected: '',
                    }}
                    hasSelectAll={false}
                    disableSearch
                    closeOnChangedValue={true}    
                  />
                </div>
              </div>
               

             </div>

              <div className=' d-flex ms-auto'>
                {totalSelected > 0 && (
                  <>
                    <div className='d-flex'>
                      <h3 className='my-2' style={{color: 'black'}}>
                        <label className='labl-gry light-dark-theme-color'>
                          {' '}
                          <i>Total Selected </i>
                        </label>
                      </h3>
                      <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                        <b>{totalSelected}</b>
                      </span>
                    </div>

                    {/* Delete CTA */}
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn px-2'
                      onClick={() => {
                        swal
                          .fire({
                            text: 'Are you sure you want to delete the selected vendors lists ?',
                            icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#D72852',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            // cancelButtonColor: "transparent",
                            cancelButtonText: 'Cancel',
                          })
                          .then((res) => {
                            if (res.isConfirmed) {
                              deleteVendorList()
                            }
                          })
                      }}
                      disabled={isLoading}
                    >
                      <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                    </button>
                  </>
                )}

                {/* Add to List CTA */}
                <button
                  type='button'
                  className='btn btn-sm fw-bold  mx-3 px-2 green-submit-btn'
                  onClick={() => {
                    setShowAddNewVL(true)
                  }}
                  disabled={isLoading}
                >
                  <img src={addToListIcon} height={20} width={20} className='me-4' />{' '}
                  New List
                </button>
              </div>
            </div>

            <div className='table-responsive'>
              <table
                className='table align-start fs-6 gy-5 table-row-dashed'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          totalSelected == vendorsData?.length && vendorsData?.length != 0
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues]
                          if (e.target.checked) {
                            for (let i = 0; i < vendorsData?.length; i++) {
                              values[i] = true
                            }
                          } else {
                            for (let i = 0; i < vendorsData?.length; i++) {
                              values[i] = false
                            }
                          }
                          setCheckedValues(values)
                        }}
                      />
                    </div>
                  </th>

                  <th className='text-start min-w-150px'>Name</th>
                  <th className='text-start min-w-200px'>Vendors</th>
                  <th className='text-start min-w-150px'>Created</th>
                  <th className='text-start min-w-150px'>Updated</th>
                  <th className='text-start min-w-150px'></th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {vendorsData?.length > 0 &&
                    vendorsData?.map((list: any, ind: any) => {
                      return (
                        <>
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex head-text align-items-center'>
                                <p className='user-head pe-2 mb-0 ps-0'>
                                  {(page - 1) * 10 + (ind + 1)}
                                  {'.   '}
                                </p>
                                <div
                                  className='bullet-div ms-2'
                                  style={{backgroundColor: `${list?.colorCode}`}}
                                ></div>

                                {editFile == ind ? (
                                  <div
                                    className='d-flex  vendor me-5 pe-2'
                                    style={{width: '-webkit-fill-available'}}
                                  >
                                    <input
                                      type='text'
                                      value={newName}
                                      className='form-control form-control-solid mytest ms-5'
                                      onChange={(e: any) => {
                                        setNewName(e.target.value)
                                      }}
                                      disabled={isLoading}
                                    />
                                  </div>
                                ) : (
                                  <p className='user-head pe-2 mb-0 ps-3'>
                                    {list.name ? list?.name : '-'}
                                  </p>
                                )}
                              </div>
                            </td>

                            <td
                              className='user-head py-3 ps-0 ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0 i_grey'>{list.count ? list?.count : '-'}</p>
                            </td>
                            <td
                              className=' py-3 ps-0  ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0 user-head i_grey'>
                                {list?.createdBy?.[0] &&
                                  `${list?.createdBy?.[0]?.firstName} ${list?.createdBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0 user-head i_grey'>
                                {list?.createdAt
                                  ? `${moment(list?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '-'}
                              </p>
                            </td>
                            <td
                              className='py-3 ps-0  ps-0 pe-5'
                              style={editFile == ind ? {opacity: '0.5'} : {opacity: '1'}}
                            >
                              <p className='my-0 user-head i_grey'>
                                {list?.updatedBy?.[0] &&
                                  `${list?.updatedBy?.[0]?.firstName} ${list?.updatedBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0 user-head i_grey'>
                                {list?.updatedAt
                                  ? ` ${moment(list?.updatedAt).format('DD/MM/YYYY hh:mm A')}`
                                  : '- -'}
                              </p>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <img
                                  src={pencil}
                                  height={16}
                                  width={16}
                                  style={
                                    editFile == ind
                                      ? {opacity: '0.4', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  className='me-5 '
                                  onClick={() => {
                                    setNewName(list?.name)
                                    setEditFile(ind)
                                  }}
                                />
                                <img
                                  src={trashImg}
                                  height={18}
                                  width={18}
                                  className='me-5'
                                  style={
                                    editFile == ind
                                      ? {opacity: '0.4', cursor: 'not-allowed'}
                                      : {opacity: '1', cursor: 'pointer'}
                                  }
                                  onClick={(e) => {
                                    Swal.fire({
                                      text: 'Are you sure you want to delete this vendors list?',
                                      icon: 'warning',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#D72852',
                                      confirmButtonText: 'Yes',
                                      showCancelButton: true,
                                      // cancelButtonColor: "transparent",
                                      cancelButtonText: 'Cancel',
                                    }).then((res: any) => {
                                      if (res.isConfirmed) {
                                        deleteSingleVendorList(list)
                                      }
                                    })
                                  }}
                                />
                                {editFile == ind && (
                                  <img
                                    src={correct}
                                    height={28}
                                    width={28}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => updateName(list)}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      {showAddNewVL &&
      <AddNewVL
        show={showAddNewVL}
        handleClose={() => {
          setShowAddNewVL(false)
        }}
        updateList={(data: any) => {
          getVendors(page)
        }}
      />
}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </>
  )
}

export default ManageLists

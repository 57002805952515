import {toPng} from 'html-to-image'
import pngIcon from '../img/png.png'
import jpgIcon from '../img/jpg-file.png'
import jpegIcon from '../img/jpeg.png'
import pdfIcon from '../img/pdf.svg'
import docIcon from '../img/doc.svg'
import pptIcon from '../img/ppt.png'
import xlsIcon from '../img/xls.png'
import txtIcon from '../img/txt.png'
import zipIcon from '../img/zip.png'
import csvIcon from '../img/csv-file.png'
import fileIcon from '../img/file-icon.png'
import axios from 'axios'
import { ErrorToast } from '../apiCommon/helpers/Toast'
import videoIcon from '../img/video.png'

// 
export function capitalizeFirstLetter(word: any): string {
  if (!word || typeof word !== 'string') {
    return word
  }
  const firstLetter = word.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = word.slice(1)
  const capitalizedWord = firstLetterCap + remainingLetters
  return capitalizedWord
}

//
export function getEmailHTMLFile(content: HTMLElement): any {
  const htmlContentForFile: any = `<!DOCTYPE html>
            <html>

            <head>
              <title>Propertise Account Setup</title>
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <style type="text/css" data-hse-inline-css="true">
                  .ql-toolbar {
                    display: none !important
                  }

                  .section-controls-style {
                    display: none !important
                  }

                  .ql-tooltip {
                    display: none !important
                  }

                  html {
                    zoom: 0.65;
                    color: #000
                  }

                  p{
                    color: #000 !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                  }
                  
                  ol{
                    color: #000 !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                  }

                  .im {
                     color: #000 !important;
                  }

                  .ql-align-justify {
                      text-align: justify !important;
                  }

                  .ql-align-right {
                      text-align: right !important;
                  }

                  .ql-align-left {
                      text-align: left !important;
                  }

                  .ql-align-center {
                      display: flex;
                      justify-content: center !important;
                      align-items: center !important;
                      text-align: center !important;
                  }

                  .ql-align-center u {
                      text-align: center !important;
                      margin-left: auto;
                      margin-right: auto;
                  }

                  .ql-indent-1 {
                      padding-left: 3em !important
                  }

                  .ql-indent-2 {
                      padding-left: 6em !important
                  }

                  .ql-indent-3 {
                      padding-left: 9em !important
                  }

                  .ql-indent-4 {
                      padding-left: 12em !important
                  }

                  .ql-indent-5 {
                      padding-left: 15em !important
                  }

                  .ql-indent-6 {
                      padding-left: 18em !important
                  }

                  .ql-indent-7 {
                      padding-left: 21em !important
                  }

                  .ql-indent-8 {
                      padding-left: 24em !important
                  }

                  .ql-syntax {
                      background-color: #23241f;
                      color: #f8f8f2;
                      overflow: visible;  
                  }

                  pre {
                      font-size: 14px;
                      white-space: pre-wrap;
                      margin-bottom: 5px;
                      margin-top: 5px;
                      padding: 5px 10px;
                      min-height: fit-content;
                  }

                  pre.ql-syntax {

                      border-radius: 0.475rem;
                  }

                  blockquote {
                      border-left: 4px solid #ccc;
                      margin-bottom: 5px;
                      margin-top: 5px;
                      padding-left: 16px;
                  }

                  a {
                      color: rgb(74, 144, 226);
                      font-style: italic;
                      font-weight: 600;
                  }

                  .top-banner{
                     border-radius: 0px !important;
                     clip-path: polygon(100% 78%, 48% 100%, 0 78%, 0 0, 100% 0);
                  }

                  .svg-section{
                    display: none
                  }

                  .ql-editor p{
                    line-height: 1.8
                  }

                  .templateButtonBoxOpacity {
                  display: none !important
                  }

                  #borderOfDynamicButton{
                  border: 0px solid transparent !important;
                  padding: 0px !important;}
            
              </style>
            </head>

            <body style="margin: 0px; padding: 0px; background-color: #f5f8fa">
                <div class="table-responsive" style="max-width:600px;margin:10px auto 10px;">
                  <div style="margin: 20px; box-shadow: rgba(10, 24, 64, 0) 0px 0px 20px 0px !important; padding: 20px 0px;" >
                      ${content?.innerHTML}
                  </div>
                </div>
            </body>

          </html>`

  // Create a Blob with the HTML content
  const blob = new Blob([htmlContentForFile], {type: 'text/html'})

  // Create a download link
  // const link = document.createElement('a')
  // link.href = URL.createObjectURL(blob)
  // link.download = 'email-template.html' // File name
  // link.click()

  return blob
}

//
export function convertHtmlToPng(element: HTMLElement): void {
  if (!element) {
    console.error('Element not found or is null')
    return
  }

  toPng(element, {skipFonts: true})
    .then((dataUrl) => {
      const link = document.createElement('a')
      link.href = dataUrl

      link.download = 'email-template.png'
      link.click()
    })
    .catch((error) => {
      console.error('Failed to convert HTML to image:', error)
    })
}

//
export function getEmailHTMLContent(content: HTMLElement): any {
  const htmlContentForFile: any = `<!DOCTYPE html>
            <html>

            <head>
              <title>Propertise Account Setup</title>
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <style type="text/css" data-hse-inline-css="true">
                  .ql-toolbar {
                    display: none !important
                  }

                  .section-controls-style {
                    display: none !important
                  }

                  .ql-tooltip {
                    display: none !important
                  }

                  html {
                    zoom: 0.65;
                    color: #000
                  }

                  p{
                    color: #000 !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                  }
                  
                  ol{
                    color: #000 !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                  }

                  .im {
                     color: #000 !important;
                  }

                  .ql-align-justify {
                      text-align: justify !important;
                  }

                  .ql-align-right {
                      text-align: right !important;
                  }

                  .ql-align-left {
                      text-align: left !important;
                  }

                  .ql-align-center {
                      text-align: center !important;
                  }

                  .ql-indent-1 {
                      padding-left: 3em !important
                  }

                  .ql-indent-2 {
                      padding-left: 6em !important
                  }

                  .ql-indent-3 {
                      padding-left: 9em !important
                  }

                  .ql-indent-4 {
                      padding-left: 12em !important
                  }

                  .ql-indent-5 {
                      padding-left: 15em !important
                  }

                  .ql-indent-6 {
                      padding-left: 18em !important
                  }

                  .ql-indent-7 {
                      padding-left: 21em !important
                  }

                  .ql-indent-8 {
                      padding-left: 24em !important
                  }

                  .ql-syntax {
                      background-color: #23241f;
                      color: #f8f8f2;
                      overflow: visible;  
                  }

                  pre {
                      font-size: 14px;
                      white-space: pre-wrap;
                      margin-bottom: 5px;
                      margin-top: 5px;
                      padding: 5px 10px;
                      min-height: fit-content;
                  }

                  pre.ql-syntax {

                      border-radius: 0.475rem;
                  }

                  blockquote {
                      border-left: 4px solid #ccc;
                      margin-bottom: 5px;
                      margin-top: 5px;
                      padding-left: 16px;
                  }

                  a {
                      color: rgb(74, 144, 226);
                      font-style: italic;
                      font-weight: 600;
                  }
              </style>
            </head>

            <body style="margin: 0px; padding: 0px; background-color: #f5f8fa">
                <div class="table-responsive" style="max-width:600px;margin:10px auto 10px;">
                  <div style="margin: 20px; box-shadow: rgba(10, 24, 64, 0) 0px 0px 20px 0px !important;" >
                      ${content?.innerHTML}
                  </div>
                </div>
            </body>

          </html>`

  return htmlContentForFile
}

// 
export function convertSvgToPng(element: HTMLElement): Promise<Blob | null> {
  if (!element) {
    console.error('Element not found or is null')
    return Promise.resolve(null)
  }

  return toPng(element, {skipFonts: true})
    .then((dataUrl) => {
      const base64Data = dataUrl.split(',')[1]
      const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]

      const binaryString = window.atob(base64Data)
      const len = binaryString.length
      const arrayBuffer = new ArrayBuffer(len)
      const uintArray = new Uint8Array(arrayBuffer)

      for (let i = 0; i < len; i++) {
        uintArray[i] = binaryString.charCodeAt(i)
      }

      const blob = new Blob([uintArray], {type: mimeString})

      // Optionally trigger a download (if required)
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = 'top.png';
      // link.click();

      return blob
    })
    .catch((error) => {
      console.error('Failed to convert HTML to image:', error)
      return null
    })
}

// 
export function getFileIcon (fileURL: string) {
  const extension = fileURL?.split('.')?.pop()?.toLowerCase(); // File extension in lowercase
  
  switch (extension) {
    case 'png':
      return pngIcon;
    case 'jpg':
      return jpgIcon;
    case 'jpeg':
      return jpegIcon;
    case 'pdf':
      return pdfIcon;
    case 'docx':    //docs
    case 'doc':
      return docIcon;
    case 'pptx':  //ppt files
    case 'ppt':
      return pptIcon;
    case 'xlsx':  // Excel files
    case 'xls':
      return xlsIcon;
    case 'txt':  // Text files
      return txtIcon;
    case 'zip':  // ZIP files
    case 'rar':  // RAR files
      return zipIcon;
    case 'csv':  // csv files
      return csvIcon;
    case 'mp4':     // Video files
    case 'avi':
    case 'mov':
    case 'mkv':
    case 'flv':
    case 'wmv':
      return videoIcon;
    default:
      return fileIcon; // Default icon if no match
  }
};

// 
export function removeLeadingZeros (val: string) {
  // Check if the value is empty or contains only zeros
  if (val.match(/^0+$/)) {
    return '0';
  }
  else if(!val){
    return '';
  }

  // Remove leading zeros
  const cleanedVal = val.replace(/^0+/, '');

  // Return the cleaned value, which will be '0' if all characters were zeros
  return cleanedVal || '0';
}

export function preventExpAndSign (e:any) {
  // Prevent 'e', 'E', '+', '-' keys from being typed
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
}

// 
export async function  downloadFile (imageUrl: any, fileName: any) {
  console.log("File Download Function")
  console.log(imageUrl)
  console.log(fileName)
  try {
    const response = await axios.get(imageUrl, {
      responseType: 'blob',
    })

    const contentType = response.headers['content-type']
    const blob = new Blob([response.data], {type: response.headers['content-type']})

    const extensionMap: {[key: string]: string} = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-excel': 'xls',
      'text/plain': 'txt',
      'application/x-msi': 'xls',

      // Add more mappings as needed
    }
    // let extension = extensionMap[contentType] || 'file'
    let extension = imageUrl?.split('.')?.pop()?.toLowerCase()

    if (contentType === 'application/zip' && fileName) {
      const fileNamePart = fileName.split('.')
      const fileExtension = imageUrl?.split('.')?.pop()?.toLowerCase()
      if (['docx', 'xlsx', 'pptx', 'doc', 'xls', 'ppt'].includes(fileExtension)) {
        extension = fileExtension
      }
    }

    const validExtensions :any= ['ppt', 'pptx', 'doc', 'docs', 'xlsx', 'xls', 'pdf', 'png', 'jpg', 'jpeg', 'zip','rar' , 'txt', 'csv']; // Add any other extensions you want to check

    const fileNameWithoutExtension = fileName && validExtensions.includes(fileName?.split('.')?.pop())
      ? fileName.split('.').slice(0, -1).join('.')
      : fileName;
      
    // Create a link element
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${fileNameWithoutExtension}.${extension}`

    // Append the link to the body
    document.body.appendChild(link)

    // Trigger the click event to start the download
    link.click()

    // Remove the link from the body
    document.body.removeChild(link)
   
  } catch (error) {
    ErrorToast('Error downloading image')
  }
}

// 
export function CustomValueRenderer (selected: any, _options: any)  {
  return selected.length > 0 ? `${selected.length} Selected` : 'Select'
}

// 
export function invertOutstandingAmount(amount: number): number {
  if (amount > 0) {
    return -Math.abs(amount);  // Ensures negative value
  } else if (amount < 0) {
    return Math.abs(amount);   // Ensures positive value
  } else {
    return 0;  // Keeps zero as zero
  }
}

// 
export function formatAmount(amount: number): string {
  // Check if the amount is a float
  if (amount % 1 !== 0) { // If there are decimal places
    return amount?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
    });
  } else {
    return amount?.toLocaleString('en-US'); // No decimal places, format without fraction digits
  }
}

// 
export function getUnitTypeName(unitType: any, unit:any) {
  const types = [
    'Apartment',
    'Penthouse',
    'Common Area',
    'Town House',
    'Villa',
    `Other${unit?.otherName ? ` - ${unit?.otherName}` : ''}`,
    'Office',
    'Restaurant',
    'Cafe',
    'Retail',
  ]
  return types[unitType] || '-'
}

// 
export function getUnitCategory(unitCat: any) {
  const categories = [
    'Residential',
    'Commercial'
  ]

  return categories[unitCat] || '-'
}

// 
export function truncateFileName(fileName: string, maxLength: number): string {
  const extension = fileName.substring(fileName.lastIndexOf('.')); // Get the file extension
  const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.')); // Get the name without extension

  // If the name is already shorter than the maxLength, return it as is
  if (nameWithoutExtension.length <= maxLength) {
    return fileName;
  }

  // Otherwise, truncate the name and add the ellipsis (...) and the extension
  const truncatedName = nameWithoutExtension.substring(0, maxLength) + '...';
  return truncatedName + extension;
}

// 
export const formatFileSize = (size: number) => {
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  } else if (size >= 1024) {
    return `${(size / 1024).toFixed(2)} KB`
  } else {
    return `${size} B`
  }
}







import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import toast from "react-hot-toast";
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import removeIcon from '../../../../../../../img/remove.png'
import refreshIcon from '../../../../../../../img/refreshIcon.png'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import ConfirmationPopup from '../../ConfirmationPopup'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {Row, Col} from 'react-bootstrap'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import downloadImg from '../../../../../../../img/download-img.png'
import share from '../../../../../../../img/email.png'
import print from '../../../../../../../img/printer.png'
import editPen from '../../../../../../../img/edit-pen.png'
import {useReactToPrint} from 'react-to-print'
import moment from 'moment'

enum ActionType {
    CreateAndShare = 'CS',
    Create = 'CR',
    UpdateAndShare = 'US',
    Update = 'UP',
    None = '-',
  }

const EditCreditNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'CREDIT NOTE',
    creditNoteNo: '',
    note: '',
    payorData: {},
    // items: [],
    payment_sub_item: [],
    creditNoteCompany: {},
    creditNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
    frontendStorage: {
      noteShowHide: false,
    },
  })

  //
  const updateSelectedInvoices = (val: any) => {
    let values: any = [...selectedInvoices]
    val?.map((vl: any, ind: number) => {
      values?.push(vl)
    })

    setSelectedInvoices(values)
  }

  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)
  const [open, setOpen] = React.useState(false)
  const [isShare, setIsShare] = useState<any>(true)

 
  const getCreditNoteById = async () => {
    let url = `corporate/payment_credit_note/${id}`

    await ApiGet(`${url}`)
      .then((res: any) => {
        setValue('documentName', res?.data?.data?.creditNoteTemplateHeader)
        setValue('documentReceiptNo', res?.data?.data?.creditNoteNo)

        let paymentItemsTemp: any = []
        res?.data?.data?.paymentSubItems?.forEach((pyItem: any, pyInd: number) => {
          paymentItemsTemp[pyInd] = {
            ...pyItem,
            outstandingAmount: pyItem?.totalAmount,
            amountPaid: pyItem?.amountPaid,
            addedCredit: {
              creditRateAmount: pyItem?.amount,
              quantity: pyItem?.quantity,
              creditAmount: pyItem?.amount,
              creditVATAmount: pyItem?.VATPercentage,
              totalCreditAmount: pyItem?.totalAmount,
            },
          }
        })

        const values: any = {
          name: res?.data?.data?.creditNoteTemplateHeader,
          creditNoteNo: res?.data?.data?.creditNoteNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          creditNoteCompany: res?.data?.data?.creditNoteCompany,
          creditNoteLogo: res?.data?.data?.creditNoteLogo,
          payment_sub_item: paymentItemsTemp,
          itemDescriptions: res?.data?.data?.itemDescriptions,
          formStatusDateTime: res?.data?.data?.createdAt,
          formStatus: res?.data?.data?.formStatus,
          showNotes: res?.data?.data?.frontendStorage?.noteShowHide,
          createdAt: res?.data?.data?.createdAt,
        }

       
        setExistingInvoice(res?.data?.data?.creditCategory)
        setCreditNoteData(values)

        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          const {tenant,...rest} = inv?.payment_invoice
          invValues?.push(rest)
          console.log(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            (tenant !== undefined && tenant !== null)
              ? [tenant]
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          // invValues[index].paymentAccountId = inv?.payment_invoice?.payment_account?.length > 0 
          //     ? inv?.payment_invoice?.payment_account?.[0]?._id 
          //     : null
          invValues[index].paymentId = inv?.paymentId
          invValues[index].building = inv?.payment_invoice?.building !== undefined && inv?.payment_invoice?.building !== null  ? [inv?.payment_invoice?.building] : []
          invValues[index].floor = inv?.payment_invoice?.floor !== undefined && inv?.payment_invoice?.floor !== null ? [inv?.payment_invoice?.floor] : []
          invValues[index].community = inv?.payment_invoice?.community !== undefined && inv?.payment_invoice?.community !== null ? [inv?.payment_invoice?.community] :[]
          invValues[index].unit = inv?.payment_invoice?.unit !== undefined && inv?.payment_invoice?.unit !== null ? [inv?.payment_invoice?.unit] : []
          invValues[index].tenant = inv?.payment_invoice?.tenant !== undefined && inv?.payment_invoice?.tenant !== null ? [inv?.payment_invoice?.tenant] : []
          invValues[index].tenancy = inv?.payment_invoice?.tenancy !== undefined && inv?.payment_invoice?.tenancy !== null ? [inv?.payment_invoice?.tenancy] :[]
        })

        setSelectedInvoices(invValues)
        setTimeout(() => {
          setIsSkeleton(false)
        }, 100)
      })
      .catch((err: any) => {
        console.log('err', err)
        setIsSkeleton(false)
      })
  }

  const addCreditNote = async (saveStatus: number, from: any) => {
    let isValid: any = true;
  
    // If saveStatus is 0, or valid items were processed, directly call the API
    if (saveStatus === 0 || saveStatus === 1 && isValid) {
      setIsLoading(true)
      setOpen(true)
      callApi();
    }
  
    // Function to call the API
    function callApi() {
      let newItems: any = [];
  
      let invoiceRecords: any = [];
      selectedInvoices?.map((sldInv: any, ind: number) => {
        invoiceRecords[ind] = {
          invoiceId: sldInv?._id,
          paymentId: sldInv?.paymentId,
          paymentAccountId: sldInv?.payment_account?.[0]?._id,
          paymentSubItems: sldInv?.paymentSubItems?.map((paymentItem: any, pyInd: any) => {
            return {
              _id: paymentItem?._id,
              name: paymentItem?.name,
              paymentItemListId: paymentItem?.paymentItemListId,
              amount: paymentItem?.amount,
              quantity: paymentItem?.quantity,
              totalAmount: paymentItem?.totalAmount,
              amountPaid: paymentItem?.amountPaid,
              outstandingAmount: paymentItem?.outstandingAmount,
              VATAmount: paymentItem?.VATAmount,
              VATPercentage: paymentItem?.VATPercentage,
              totalCreditAmount: 0,
              creditVATAmount: 0,
              creditAmount: 0,
              isSelected: paymentItem?.isSelected,
              addedCredit: {
                creditAmount: Number(paymentItem?.addedCredit?.creditRateAmount),
                creditRateAmount: Number(paymentItem?.addedCredit?.creditRateAmount),
                quantity: Number(paymentItem?.addedCredit?.quantity),
                creditVATAmount: Number(paymentItem?.addedCredit?.creditVATAmount),
                totalCreditAmount: Number(paymentItem?.addedCredit?.totalCreditAmount),
              },
            };
          }),
        };
      });
  
      newItems = [...creditNoteData?.payment_sub_item]?.map((itm: any, ind: any) => {
        let { addedCredit, ...rest } = itm;
        return {
          ...rest,
          quantity: Number(addedCredit?.quantity),
          amount: Number(addedCredit?.creditRateAmount),
          VATPercentage: Number(addedCredit?.creditVATAmount),
          totalAmount: Number(addedCredit?.totalCreditAmount),
          // outstandingAmount: flg === '1' ? addedCredit?.totalCreditAmount : addedCredit?.totalCreditAmount - itm?.amountPaid,
          VATAmount: 0,
          // amountPaid: flg === '1' ? 0 : itm?.amountPaid
        };
      });
  
      let subTotalAmount = 0;
      let totalVAT = 0;
      let nonVATAmount = 0;
  
      if (creditNoteData?.payment_sub_item?.length > 0) {
        creditNoteData?.payment_sub_item?.map((f: any, ind: any) => {
          if (f?.frontendStorage?.isLatePaymentItem) {
            totalVAT = totalVAT + (Number(f?.addedCredit?.creditRateAmount) / 100) * Number(f?.addedCredit?.quantity);
            nonVATAmount = nonVATAmount + f.amount;
          } else {
            totalVAT =
              totalVAT +
              ((Number(f?.addedCredit?.creditRateAmount) * Number(f?.addedCredit?.quantity)) / 100) *
                Number(f?.addedCredit?.creditVATAmount);
            nonVATAmount =
              nonVATAmount + Number(f?.addedCredit?.creditRateAmount) * Number(f?.addedCredit?.quantity);
          }
        });
      }
  
      const body = {
        _id: flg == '1' ? undefined : id,
        note: creditNoteData?.note ? creditNoteData?.note : '',
        tenantId: creditNoteData?.payorData?._id,
        datePaid: '',
        referenceNo: creditNoteData?.creditNoteNo,
        creditNoteNo: creditNoteData?.creditNoteNo,
        creditNoteTemplateHeader: creditNoteData?.name,
        formStatus: saveStatus,
        creditCategory: existingInvoice,
        currency: 'AED',
        subTotalAmount: totalVAT + nonVATAmount,
        totalVAT: totalVAT,
        totalAmount: nonVATAmount,
        invoiceRecords: invoiceRecords?.length > 0 ? invoiceRecords : [],
        paymentSubItems: newItems?.length > 0 ? newItems : [],
        itemDescriptions: creditNoteData?.itemDescriptions,
        frontendStorage: {
          noteShowHide : creditNoteData?.showNotes
        }
      };
  
      ApiPost(`corporate/payment_credit_note/add_update`, body)
        .then((res) => {
          // Handle success
          if (saveStatus == 0 ) {
            if (flg == '1') {
              navigate(`/finance-income/issue-credit-note/2/${res?.data?.data?._id}`)
              setTimeout(() => {
                setIsSaveFirst(true)
              }, 1000)
            }else {
              getCreditNoteById()
            }
            setTimeout(() => {
              setIsLoading(false)
              setOpen(false)
            }, 1500)
          }else if(saveStatus == 1 && from  === ActionType.Update){
            setAskForConfirmation(false)
            SuccessToast('Credit Note has been updated successfully..')
            navigate(`/finance-income/view-credit-note/${id}`)
          }else if(saveStatus == 1 && from === ActionType.UpdateAndShare){
            setAskForConfirmation(false)
            navigate(`/finance-income/share/issue-credit-note/${id}`)
          }else{
            setAskForConfirmation(false)
            SuccessToast('Credit Note has been updated successfully..')
            navigate(`/finance-income/view-credit-note/${id}`)
          }
        })
        .catch((err: any) => {
          ErrorToast(err?.message);
          setTimeout(() => {
            setIsLoading(false)
            setOpen(false)
          }, 1500)
        });
    }
  }

  //
  useEffect(() => {
    setIsSkeleton(true)
    getCreditNoteById()
  }, [])

  //
//   useEffect(() => {
//     if (autoSaveData != false && flg == '2') {
//       addCreditNote(0)
//     }
//   }, [autoSaveData])

  // delete credit note
  const deleteCreditNote = () => {
    ApiDelete(`corporate/payment_credit_note/${id}`)
      .then((res) => {
        SuccessToast('Payment Credit note has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const getCreditSettingDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting');
      console.log(res)
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getCreditNoteDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };


  return (
    <>
      {!isSkeleton ? 
      (<div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
          // onSubmit={handleSubmit((data: any, e: any) => {
          //   addCreditNote(1)
          // })}
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap mb-4'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                        navigate(`/finance-income/view-credit-note/${id}`)
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Edit Credit Note`}</h2>
                </div>

                <div className='d-flex ms-auto'>
                <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 red-hollow-btn btn-fit-content ps-3'
                    // disabled={isLoading}
                    onClick={() => {
                      navigate(`/finance-income/view-credit-note/${id}`)
                    }}
                  >
                    <img src={removeIcon} height={18} width={18} className='me-4' />{' '}
                    Cancel
                  </button>

                <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                    onClick={ async () => {
                      let isValid = false
                      isValid = await trigger();

                      if(!creditNoteData?.name?.trim()){
                        ErrorToast('Credit note name is required.');
                      }

                      const invoiceDetails = await getCreditSettingDetails();
                      if(invoiceDetails.creditNoteSequence?.activityStatus == 0 && !creditNoteData?.creditNoteNo?.trim()){
                        ErrorToast('Credit note number is required.');
                      }
                        

                      let addSectionErr : boolean = false
                        if(creditNoteData?.itemDescriptions?.length > 0  && creditNoteData?.itemDescriptions?.some((itm:any) => {
                          const tempElement = document.createElement('div');
                          tempElement.innerHTML = itm?.html;
                          return ((!tempElement?.textContent?.trim() || !tempElement?.innerText?.trim()) || !itm?.title?.trim() || itm?.html === undefined);
                        })){
                          addSectionErr = true
                        }else{
                          addSectionErr = false
                        }

                        if(addSectionErr){
                          ErrorToast('User cannot create credit note with empty title or content in additional sections.')
                          return
                        }

                      let haveErrorInItems : any = []

                      selectedInvoices?.forEach((sldInv: any) => {
                        sldInv?.paymentSubItems?.forEach((paymentItem: any) => {
                          const creditRateAmount = Number(paymentItem?.addedCredit?.creditRateAmount);
                          const quantity = Number(paymentItem?.addedCredit?.quantity);
                          const totalCreditAmount = Number(paymentItem?.addedCredit?.totalCreditAmount);
                          const totalAmount = Number(paymentItem?.totalAmount);
                          if(creditRateAmount > 0 && quantity > 0 && totalCreditAmount > totalAmount ){
                              haveErrorInItems.push(paymentItem)
                          }
                        });
                      });

                      if(haveErrorInItems?.length > 0){
                        ErrorToast('Total credit amount exceeds item amount.');
                        return
                      }

                      if(isValid){

                        let validItems: any = [];
                        let shouldEnableButton = false;
                    
                        // Loop through selected invoices and sub-items
                        // creditNoteData?.payment_sub_item?.forEach((subItem: any) => {
                          selectedInvoices?.forEach((sldInv: any,invInd:any) => {
                            sldInv?.paymentSubItems?.forEach((paymentItem: any,pyInd:any) => {
                              const creditRateAmount = Number(paymentItem?.addedCredit?.creditRateAmount);
                              const quantity = Number(paymentItem?.addedCredit?.quantity);
                              const totalCreditAmount = Number(paymentItem?.addedCredit?.totalCreditAmount);
                              const totalAmount = Number(paymentItem?.totalAmount);
                    
                              // Check if the item is valid and not already part of the credit note
                              if (creditRateAmount > 0 && quantity > 0 && totalCreditAmount <= totalAmount && !paymentItem?.isSelected) {
                                paymentItem.isSelected = true;
                                paymentItem.frontendStorage = {
                                 ...paymentItem.frontendStorage,
                                 invoiceDate: sldInv[invInd]?.date,
                                }
                                validItems.push(paymentItem);
                                shouldEnableButton = true;
                              }
                            });
                          });
                        // });
                    
                        // Show the popup if valid items are found
                        if (shouldEnableButton) {
                          Swal.fire({
                            html: ` 
                              <div class='fs-3'> <b>Items Missing</b></div>
                              <div class='fs-5 mt-4 fw-bold'> There are some items you recorded credit details for that were not included in the Credit Note.</div>
                              <div class='fs-5 mt-4 fw-bold'> Would you like to add them before creating? </div>
                              <p class='fs-5 mt-4 text-gray-400 fw-bold'> This only applies to items that have complete and correct credit details but were not included</p>
                              `,
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true,
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              console.log(validItems);
                              setAskForConfirmation(true)
                              toast('Items added successfully to your credit note!', {
                                icon: <img src={refreshIcon} alt="icon" width={14} height={14}/>,
                                style: {
                                  padding: "16px",
                                },
                              })

                              const updateCreditNote = {...creditNoteData}
                              validItems?.forEach((subItem:any) => {
                                subItem.frontendStorage = {
                                  ...subItem.frontendStorage,
                                  addedAt : new Date().getTime()
                                }
                              })
                              updateCreditNote.payment_sub_item = [...updateCreditNote.payment_sub_item,...validItems]
                              setCreditNoteData(updateCreditNote)

                            } else {
                              setAskForConfirmation(true)
                              selectedInvoices?.forEach((sldInv: any) => {
                                sldInv?.paymentSubItems?.forEach((paymentItem: any) => {
                                  if (validItems.includes(paymentItem)) {
                                    paymentItem.isSelected = false;
                                  }
                                });
                              });
                              validItems = [];
                            }
                          });
                    
                        }else{
                          setAskForConfirmation(true)
                        }
                      }
                      
                      
                    }}
                    // disabled={isLoading || !goToNext }
                  >
                    <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                    Update
                  </button>
                </div>
              </div>
            </div>


            {/* Credit Note Details Card */}
            <NoteDetailsCard
              isPrint={false}
              register={register}
              errors={errors}
              noteCardData={creditNoteData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateNoteData={(data: any) => {
                console.log(data)
                setCreditNoteData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              updateNote={(data: any) => {
                setCreditNoteData(data)
              }}
              slctdInvoices={selectedInvoices}
              editMode={true}
              from={'credit note'}
            />
          </div>
        </form>
      </div>) : ( <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>)}

        <ConfirmationPopup
        from='credit'
        show={askForConfirmation}
        isUpdate={true}
        isCreate={false}
        label={'Create'}
        type={'Credit Note'}
        payor={
          creditNoteData?.payorData?.firstName && creditNoteData?.payorData?.lastName ? `${creditNoteData?.payorData?.firstName} ${creditNoteData?.payorData?.lastName}` :'-'
        }
        typeOfNo={creditNoteData?.creditNoteNo}
        handleClose={() => {
          setAskForConfirmation(false)
        }}
        updateData={(actionType: string) => {
          console.log(actionType)

          if (actionType === ActionType.Create) {
            // run create fun
            
          } else if (actionType === ActionType.CreateAndShare) {
            // run create and share
            
          } else if (actionType === ActionType.Update) {
            // run update fun
            addCreditNote(1, actionType)
          } else if (actionType === ActionType.UpdateAndShare) {
            // run update and share
            addCreditNote(1, actionType)
          } else {
            // nothing
          }
        }}
        receiptData={[]}
        isShare={isShare}
        setIsShare={(val: any) => {
          setIsShare(val)
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default EditCreditNote

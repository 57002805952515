import React from 'react'
import {Row, Col} from 'react-bootstrap'

interface TestCard {
  cardIcon: any
  cardHeading: string
  cardSubHeading: string
  handleClick: any
}

const TestCard = ({cardHeading, cardSubHeading, cardIcon,handleClick}: TestCard) => {
  return (
    <div className='col-6 text-center'>
      <div
        className='card card-flush'
        style={{
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          // boxShadow: '0px 0px 12px 0px rgba(18, 32, 78, 0.2)',
        }}
      >
        <Row>
          <Col xs={12}>
            <img src={cardIcon} alt='Email Verified' width={70} />
          </Col>
          <Col xs={12}>
            <p
              className='p-0'
              style={{
                width: '100%',
                color: '#000000',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {cardHeading}
            </p>
          </Col>
          <Col xs={12} style={{height: '100px'}}>
            <p
              className='my-2'
              style={{
                width: '100%',
                color: '#7f7f7f',
                fontSize: '13px',
              }}
            >
              {cardSubHeading}
            </p>
          </Col>
          <Col xs={12}>
            <div className='row px-7' style={{paddingTop: '20px', paddingBottom: '0px'}}>
              <div className='col-md-8 px-0 mx-auto'>
                <button
                  onClick={handleClick}
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn justify-content-center mx-auto'
                  style={{width: '100%'}}
                >
                  Test
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TestCard

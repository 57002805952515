import React, {useState, useEffect, useRef} from 'react'
// import "./styles.scss"
import noData from '../../../../img/NoData1.svg'
import allProperties from '../../../../img/allProperties.png'
import allDevelopment from '../../../../img/allDevelopment.png'
import allBuilding from '../../../../img/small-building.png'
import allCommunities from '../../../../img/allCommunities.png'
import bulkImportWhite from '../../../../img/bulkImportWhite.png'
import unitsBlue from '../../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../../img/tenantBlue.png'
import leasesBlue from '../../../../img/leasesBlue.png'
import yellowStar from '../../../../img/yellow-star.png'
import occupancyBlue from '../../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../../img/add-white.png'
import {MultiSelect} from 'react-multi-select-component'
import CustomDateRangePicker from '../CustomDateRangePicker'

import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate, useLocation, useParams} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import activeTen from '../../../../img/active-tenc.png'
import expngTen from '../../../../img/expng-tenc.png'
import terminTen from '../../../../img/expired-tenc.png'
import trash from '../../../../img/trash-white.png'
import create from '../../../../img/create.png'
import closes from '../../../../img/close.svg'
import windowImg from '../../../../img/window.png'
import sync from '../../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {DatePicker, Space} from 'antd'
import Select from 'react-select'
import downGreen from '../../../../img/down-arrow-green.png'
import upGreen from '../../../../img/up-arrow-green.png'
import trashImg from '../../../../img/trash.png'
import cancelledTen from '../../../img/cancelled-tab.png'
import draftTen from '../../../img/draft.png'
import expiredTen from '../../../../img/expired-tab.png'
import {setComefrom} from '../../../../redux/counterSlice'

import backArrow from '../../../../img/back-arrow.png'
import editPen from '../../../../img/edit-pen.png'
import floorsBlue from '../../../../img/floorsBlue.png'

import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../../img/Apartment.svg'
import townhouseIcon from '../../../../img/TownHouse.svg'
import villaIcon from '../../../../img/Villa.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'

import PropertiesList from './PropertiesList'
import AddFloorModel from './AddFloorModel'
import redCross from '../../../../img/remove.png'
import saveW from '../../../../img/Save-White.png'
import communityBlue from '../../../../img/communityBlue.png'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import commercialFloorWhiteIcon from '../../../../img/commercialFloorWhiteIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import residentialWhiteIcon from '../../../../img/residentialWhiteIcon.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import dragDropIcon from '../../../../img/dragDropIcon.png'
import commonAreaIcon from '../../../../img/commonAreaIcon.png'
import alllBuildingIcon from '../../../../img/allBuildingBlue.png'
import ownerFieldIcon from '../../../../img/ownerFieldIcon.png'
import titleDeedIcon from '../../../../img/titleDeedIcon.png'
import uploadArrowBlue from '../../../../img/uploadArrowBlue.png'
import addIcon from '../../../../img/add.png'
import removeIcon from '../../../../img/remove.png'
import pngIcon from '../../../../img/png.png'
import jpgIcon from '../../../../img/jpg-file.png'
import jpegIcon from '../../../../img/jpeg.png'
import pdfIcon from '../../../../img/pdf.svg'
import docIcon from '../../../../img/doc.svg'


import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import ModalLoader from '../../General/ModalLoader'

import swal from 'sweetalert2'
import {group} from 'console'

interface ButtonProps {
  from: String
}

const EditProperty = () => {
  const [activeTab, setActiveTab] = useState<any>('all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>('allUnits')

  const [searchCommonArea, setSearchCommonArea] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])
  const navigate = useNavigate()
  const location: any = useLocation()
  const {propertyId} = useParams()
  const isEdit = propertyId !== undefined && propertyId !== '' ? true : false

  const goToMiantenance = (v: any) => {}

  // models
  const [addFloorModel, setAddFloorModel] = useState<any>(false)

  // date range
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const unitOptions = [
    {value: 'apartment', label: 'Apartment'},
    {value: 'pent_house', label: 'Penthouse'},
    {value: 'town_house', label: 'Townhouse'},
    {value: 'villa', label: 'Villa'},
    {value: 'common_area', label: 'Common Area'},
    {value: 'other', label: 'Other'},
  ]

  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ]
  const bathroomOptions = [
    {
      value: 1,
      label: '1 Bath',
    },
    {value: 2, label: '2 Bath'},
    {value: 3, label: '3 Bath'},
    {value: 4, label: '4 Bath'},
    {value: 5, label: '5 Bath'},
    {value: 6, label: '6 Bath'},
    {value: 7, label: '7 Bath'},
    {value: 8, label: '8 Bath'},
    {value: 9, label: '9 Bath'},
    {value: 10, label: '10 Bath'},
  ]
  const [floorsOptions, setFloorOptions] = useState<any>([])
  const [clusterOptions, setClusterOptions] = useState<any>([])
  const [groupOptions, setGroupOptions] = useState<any>([])
  const [buildingOptions, setBuildingOptions] = useState<any>([])
  const [developmentOptions, setDevelopmentOptions] = useState<any>([])
  const [commonAreaOptions, setCommonAreaOptions] = useState<any>([])
  const propertyTypeOptions: any = []

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    // padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '2px',
    // backgroundColor: '#fff',
  }
  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const customStylesSmall = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '105px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }
  const [selectResidentialBox, setSelectResidentialBox] = useState(false)
  const [selectCommercialBox, setSelectCommerciallBox] = useState(false)
  const [apartmentBox, setApartmentBox] = useState(false)
  const [pentHouseBox, setPentHouseBox] = useState(false)
  const [otherBox, setOtherBox] = useState(false)
  const [townhouseBox, setTownhouseBox] = useState(false)
  const [villaBox, setVillaBox] = useState(false)
  const [commonAreaBox, setCommonAreaBox] = useState(false)
  const [propertyUnitNo, setPropertyUnitNo] = useState('')
  const [propertySize, setPropertySize] = useState('')
  const [propertySizeType, setPropertySizeType] = useState<any>({value: 0, label: 'Sq ft'})
  const [sizeOptions, setSizeOptions] = useState([
    {value: 0, label: 'Sq ft'},
    {value: 1, label: 'Sq m'},
  ])
  const [propertyBedroom, setPropertyBedroom] = useState<any>('')
  const [propertyBathrooms, setPropertyBathroom] = useState<any>('')
  const [propertyFloors, setPropertyFloors] = useState<any>('')
  const [propertyCluster, setPropertyCluster] = useState<any>('')
  const [propertyGroup, setPropertyGroup] = useState<any>('')
  const [propertyBuilding, setPropertyBuilding] = useState<any>('')
  const [propertyUnitType, setPropertyUnitType] = useState('')
  const [propertyCommonAreaNo, setPropertyCommonAreaNo] = useState('')
  const [propertyCommonAreaName, setPropertyCommonAreaName] = useState('')
  const [propertyType, setPropertyType] = useState<any>('')
  const [propertyDevelopment, setPropertyDevelopment] = useState<any>('')
  const [isLoading, setIsLoading] = useState<any>(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState<any>(true)
  const [showPropertyCategory, setShowPropertyCategory] = useState<any>(false)
  const [attachments, setAttachments] = useState<any>([])
  const [isClusterDisabled, setIsClusterDisabled] = useState<any>(false)
  const [isCTAHidden, setIsCTAHidden] = useState(true)
  let tempOwnerOptions: any = []
  // const [buildingForCommunity, setBuildingForCommunity] = useState<any>([])

  const getBuildingForCommunity = async (developmentId:any,clusterId: any) => {
    setIsLoading(true)

    let body = {
      search: '',
      developmentIds: [developmentId],
      buildingIds: [],
      clusterIds: [`${clusterId}`],
    }

    await ApiPost('corporate/development/building_of_community/get', body)
      .then((res) => {
        // console.log(res?.data?.data)
        const buildingOptions = res?.data?.data?.map((buildingItem: any) => ({
          label: buildingItem?.name,
          value: buildingItem?._id,
        }))
        setBuildingOptions(buildingOptions)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const resetPropertyType = () => {
    setApartmentBox(false)
    setPentHouseBox(false)
    setOtherBox(false)
    setCommonAreaBox(false)
    setTownhouseBox(false)
  }
  const resetUnitType = () => {
    setAttachments([])
    setPropertyCommonAreaName('')
    setPropertyCommonAreaNo('')
    setPropertyType('')
    setPropertyUnitType('')
    setPropertySize('')
    setPropertyBathroom('')
    setPropertyBedroom('')
    setPropertyUnitNo('')
    setPropertyType('')

    resetOwner()
    setOwnerType('')
  }

  useEffect(() => {
    if (propertyCluster?.type == 0) {
      setShowPropertyCategory(true)
    } else if (propertyCluster?.type == 1 && propertyFloors !== '') {
      setShowPropertyCategory(true)
    } else if (propertyCluster?.type == 1 && propertyFloors == '') {
      setShowPropertyCategory(false)
    } else if (propertyCluster?.type == 2 && propertyGroup?.type === 'unitGroup') {
      setShowPropertyCategory(true)
    } else if (
      propertyCluster?.type == 2 &&
      propertyGroup?.type == 'building' &&
      propertyFloors !== ''
    ) {
      setShowPropertyCategory(true)
    } else if (propertyDevelopment?.type == 'building' && propertyFloors !== '') {
      setShowPropertyCategory(true)
    } else {
      setShowPropertyCategory(false)
    }
  }, [propertyCluster, propertyFloors, propertyGroup])

  useEffect(() => {
    if (propertyCluster?.type == 1) {
      getBuildingForCommunity(propertyDevelopment?.value,propertyCluster?.value)
    }
    if (isEdit) return
    setPropertyBuilding('')
    setPropertyFloors('')
    setPropertyGroup('')
    resetPropertyCategory()
  }, [propertyCluster])

  useEffect(() => {
    // if (isEdit) return
    if (propertyCluster?.type == 1 && propertyBuilding !== '') {
      getFloors(propertyBuilding?.value)
    }
  }, [propertyBuilding])

  useEffect(() => {
    if (apartmentBox || pentHouseBox || townhouseBox || villaBox) {
      if (
        propertyUnitNo == '' ||
        propertySize == '' ||
        propertyBedroom == '' ||
        propertyBathrooms == ''
      ) {
        setIsBtnDisabled(true)
      } else {
        setIsBtnDisabled(false)
      }
    } else if (otherBox) {
      if (
        propertyUnitType == '' ||
        propertyUnitNo == '' ||
        propertySize == '' ||
        propertyBedroom == '' ||
        propertyBathrooms == ''
      ) {
        setIsBtnDisabled(true)
      } else {
        setIsBtnDisabled(false)
      }
    } else if (commonAreaBox) {
      if (propertyCommonAreaNo == '' || propertyType == '') {
        setIsBtnDisabled(true)
      } else if (propertyType == 'Other') {
        if (propertyCommonAreaName == '') {
          setIsBtnDisabled(true)
        } else {
          setIsBtnDisabled(false)
        }
      } else {
        setIsBtnDisabled(false)
      }
    }
  }, [
    propertyBathrooms,
    propertyBedroom,
    propertyCommonAreaName,
    propertyCommonAreaNo,
    propertyType,
    propertyUnitNo,
    propertyUnitType,
    propertySize,
  ])

  const isFirstRender = useRef()

  useEffect(() => {
    if (isEdit) return
    resetPropertyType()
    resetUnitType()
  }, [selectResidentialBox, selectCommercialBox])

  useEffect(() => {
    if (isEdit) return
    resetUnitType()
  }, [apartmentBox, pentHouseBox, otherBox, commonAreaBox, villaBox, townhouseBox])

  useEffect(() => {
    if (commonAreaBox) {
      getCommonTypes()
    }
  }, [commonAreaBox])

  const addProperty = () => {
    setIsLoading(true)
    const propertyImages = attachments?.map((image: any) => image?.src)

    let body: any = {
      unitNo: commonAreaBox ? propertyCommonAreaNo : propertyUnitNo,
      unitType: apartmentBox
        ? 0
        : pentHouseBox
        ? 1
        : commonAreaBox
        ? 2
        : townhouseBox
        ? 3
        : townhouseBox
        ? 4
        : otherBox
        ? 5
        : null,
      propertyType: selectResidentialBox ? 0 : selectCommercialBox ? 1 : null,
      images: propertyImages,
    }
    if (commonAreaBox) {
      body.commonAreaName =
        propertyType?.label === 'Other' ? propertyCommonAreaName : propertyType?.label
    }
    if (apartmentBox || pentHouseBox || villaBox || townhouseBox || otherBox) {
      body.otherName = propertyUnitType
      body.bedrooms = Number(propertyBedroom?.value)
      body.bathrooms = Number(propertyBathrooms?.value)
      body.size = Number(propertySize)
      body.sizeType = Number(propertySizeType.value)
    }
    if (propertyDevelopment?.type === 'building') {
      body.buildingId = propertyDevelopment?.value
      body.floorId = propertyFloors?.value
    }
    if (propertyDevelopment?.type === 'community' && propertyCluster?.type === 0) {
      body.communityId = propertyDevelopment?.value
      body.clusterId = propertyCluster?.value
    }
    if (propertyDevelopment?.type === 'community' && propertyCluster?.type === 1) {
      body.communityId = propertyDevelopment?.value
      body.clusterId = propertyCluster?.value
      body.floorId = propertyFloors?.value
      body.buildingId = propertyBuilding?.value
    }
    if (propertyDevelopment?.type === 'community' && propertyCluster?.type === 2) {
      body.communityId = propertyDevelopment?.value
      body.clusterId = propertyCluster?.value
      if (propertyGroup?.type === 'building') {
        body.buildingId = propertyGroup?.value
        body.floorId = propertyFloors?.value
    } else {
        body.unitGroupId = propertyGroup?.value
    }

    }

    
    // if (ownerType) {
      body.ownerType = ownerType === 'singleOwner' ? 0 : ownerType === 'jointOwner' ? 1 : null
    // }

    // if (file) {
      body.titleDeedFile = file ? file.src : ''
    // }

    // if (ownerType === 'singleOwner' || ownerType === 'jointOwner') {
      const ownersTemp: any =
        ownerType === 'singleOwner'
          ? selectedOwner.map((owner: any) => ({ownerId: owner.value, ownershipPercentage: 100}))
          : owners.flatMap((row: any) => row.selectedOwner.map((owner: any) => ({ownerId: owner.value, ownershipPercentage: Number(row.percentage)})))
      const ownerIds: any =
        ownerType === 'singleOwner'
          ? selectedOwner.map((owner: any) => owner.value)
          : owners.flatMap((row: any) => row.selectedOwner.map((owner: any) => owner.value))

      body.owners = ownersTemp
      body.ownerIds = ownerIds
    // }

    if (
      ownerType &&
      file == null &&
      (!(ownerType === 'singleOwner' ? selectedOwner.length > 0 : owners.every((row: any) => row.selectedOwner.length > 0)) || (ownerType === 'singleOwner' ? selectedOwner.length > 0 : owners.every((row: any) => row.selectedOwner.length > 0)))
    ) {
      ErrorToast('Please upload your deed file.')
      sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      return
    } else if (ownerType && file && (ownerType === 'singleOwner' ? selectedOwner.length <= 0 : false)) {
      ErrorToast('Please select an owner.')
      sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      return
    } else if (ownerType && file && (ownerType === 'jointOwner'  ? !owners.every((row: any) => row.selectedOwner.length > 0) : false)) {
      ErrorToast('Please select owners.')
      sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      return
    }

  
    if ((percentEMsg === true && ownerType === 'jointOwner') || (ownerType === 'jointOwner' && owners.every((owner:any) => owner.percentage == ''))) {
      setPercentEMsg(true)  
      ErrorToast('Total Ownership % should be equal to 100%.')
      sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      return
    }else{
      setPercentEMsg(false)
      setPercentMsg(true)  
      // ErrorToast('Total Ownership % should be equal to 100%.')
      // sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
    if (percentMsg === true && ownerType === 'jointOwner') {
      sectionRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
      return
    }

    if (isEdit) {
      body.id = propertyId
      ApiPut(`corporate/unit`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          // navigate('/properties')
          if (location?.state.from === 'buildingDetail') {
            navigate(`/properties/building-details/${location?.state?.buildingId}`,{state:{
              area:location?.state?.area,
              city:location?.state?.city,
              buildingId: location?.state?.buildingId,
              buildingName:location?.state?.buildingName ,

            }})
          } else if (location?.state.from === 'buildingDetailFloor') {
            navigate(
              `/properties/building-details/${location?.state?.buildingId}/floors/${location?.state?.floorId}`,
              {
                state: {
                  floorName: location?.state.floorName,
                  buildingId: location?.state?.buildingId,
                  floorId: location?.state?.floorId,
                  area:location?.state?.area,
                  city:location?.state?.city,
                   buildingName:location?.state?.buildingName ,
                                  
                },
              }
            )
          } else if (location?.state.from === 'communityDetail') {
            navigate(`/properties/community-details/${location?.state?.communityId}`, {
              state: {
                communityName: location?.state?.communityName,
                communityId: location?.state.communityId,
                area:location?.state?.area,
                                  city:location?.state?.city
              },
            })
          } else if (location?.state?.type === 0 && location?.state?.from !== "propertyProfile") {
            navigate(
              `/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}`,
              {
                state: {
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  area:location?.state?.area,
                                  city:location?.state?.city
                },
              }
            )
          } else if (location?.state?.type === 1 && !location?.state.buildingId && location?.state?.from !== "propertyProfile") {
            navigate(
              `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}`,
              {
                state: {
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  area:location?.state?.area,
                                  city:location?.state?.city
                },
              }
            )
          } else if (location?.state?.type === 2 && !location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
            navigate(
              `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}`,
              {
                state: {
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  area:location?.state?.area,
                                  city:location?.state?.city
                },
              }
            )
          }else if (location?.state?.type === 1 && location?.state.buildingId && !location?.state.floorId && location?.state?.from !== "propertyProfile") {
            navigate(
              `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}`,
              {
                state: {
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  buildingName: location?.state.buildingName,
                  buildingId: location?.state.buildingId,
                  area:location?.state?.area,
                                  city:location?.state?.city
                },
              }
            )
          }else if (location?.state?.type === 1 && location?.state.buildingId && location?.state.floorId && location?.state?.from !== "propertyProfile") {
            navigate(
              `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}/floor/${location?.state.floorId}`,
              {
                state: {
                  type: location?.state?.type,
                  clusterName: location?.state?.clusterName,
                  communityId: location?.state?.communityId,
                  clusterId: location?.state?.clusterId,
                  communityName: location?.state?.communityName,
                  buildingName: location?.state.buildingName,
                  buildingId: location?.state.buildingId,
                  floorName: location?.state.floorName,
                  floorId: location?.state.floorId,
                  area:location?.state?.area,
                                  city:location?.state?.city
                },
              }
            )
          }else if (location?.state?.type === 2 && location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
            navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}`,
            { state: 
                { 
                        type: location?.state?.type,
                        clusterName: location?.state?.clusterName,
                        communityId: location?.state?.communityId,
                        clusterId: location?.state?.clusterId,
                        communityName:location?.state?.communityName,
                        groupName: location?.state?.groupName,
                        groupId: location?.state?.groupId,
                        groupType: location?.state?.groupType,
                        area:location?.state?.area,
                                  city:location?.state?.city
                     }})   
          }else if (location?.state?.type === 2 && location?.state?.groupId &&  location?.state.floorId && location?.state?.from !== "propertyProfile") {
            navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state.floorId}`,
            { state: 
                { 
                        type: location?.state?.type,
                        clusterName: location?.state?.clusterName,
                        communityId: location?.state?.communityId,
                        clusterId: location?.state?.clusterId,
                        communityName:location?.state?.communityName,
                        groupName: location?.state?.groupName,
                        groupId: location?.state?.groupId,
                        groupType: location?.state?.groupType,
                        floorName:location?.state?.floorName,
                        floorId:location?.state?.floorId,
                        area:location?.state?.area,
                                  city:location?.state?.city
                     }})  
          }else if(location?.state.from === 'propertyProfile'){
            navigate(-1)
           }
          setIsLoading(false)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setIsLoading(false)
        })
    } else {
      ApiPost(`corporate/unit`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          navigate('/properties')
          setIsLoading(false)
        })
        .catch((err: any) => {
          ErrorToast(err?.message)
          setIsLoading(false)
        })
    }
  }

  const getDevelopment = async () => {
    const body = {
      searchDevelopment: '',
      unitSearch: '',
      developmentIds: [],
      clusterIds: [],
      unitGroupIds: [],
      floorIds: [],
      unitIds: [],
    }
    setIsLoading(true)
    await ApiPost(`corporate/development/get`, body)
      .then((res) => {
        const developemnt = res?.data?.data?.map((developmentItem: any) => ({
          value: developmentItem?._id,
          label: developmentItem?.name,
          type:
            developmentItem?.isBuilding === 'true'
              ? 'building'
              : developmentItem?.isCommunity === 'true'
              ? 'community'
              : '',
        }))
        setDevelopmentOptions(developemnt)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getGroups = async (developmentId:any,clusterId:any) => {
    const body = {
      search: '',
      isCombineBuilding: true,
      developmentIds: [developmentId],
      'nitGroupIds ': [],
      clusterIds: [clusterId],
    }
    setIsLoading(true)
    await ApiPost(`corporate/development/unit_group/get`, body)
      .then((res) => {
        const groups = res?.data?.data?.map((groupItem: any) => ({
          value: groupItem?._id,
          label: groupItem?.name,
          type:
            groupItem?.isBuilding === 'true'
              ? 'building'
              : groupItem?.isUnitGroup
              ? 'unitGroup'
              : '',
        }))
        setGroupOptions(groups)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getCluster = async (developmentId:any) => {
    setIsLoading(true)
    await ApiGet(`corporate/cluster?communityId=${developmentId}`)
      .then((res) => {
        const clusters = res?.data?.data?.map((clusterItem: any) => ({
          value: clusterItem?._id,
          label: clusterItem?.name,
          type: clusterItem?.type,
        }))
        setClusterOptions(clusters)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getFloors = async (buildingId: any) => {
    setIsLoading(true)
    await ApiGet(`corporate/floor?buildingId=${buildingId}`)
      .then((res) => {
        const floors = res?.data?.data?.map((floorItem: any) => ({
          value: floorItem?._id,
          label: floorItem?.name,
        }))
        setFloorOptions(floors)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getCommonTypes = async () => {
    setIsLoading(true)
    await ApiGet(`test/common/type/`)
      .then((res) => {
        const commonAreas = res?.data?.data?.databaseTypes?.commonAreasList?.map(
          (commonAreaItem: any, i: any) => ({value: commonAreaItem, label: commonAreaItem})
        )
        setCommonAreaOptions(commonAreas)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (isEdit) return
    resetPropertyCategory()
    resetPropertyType()
    resetUnitType()
    setPropertyFloors('')
  }, [propertyGroup])

  useEffect(() => {
    // if (isEdit) return
    if (propertyGroup?.type === 'building') {
      getFloors(propertyGroup?.value)
    }
  }, [propertyGroup])

  const handleAttachmentChange = async (e: any) => {
    setIsCTAHidden(false)
    e.preventDefault()

    const values = [...attachments]
    let newVal = values?.length - 1

    if (e.target.files) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]
        // let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/compress_image/unit_image`, formdata)
          .then((res) => {
            // let x = res?.data?.data?.image?.split('/')[2]
            values[values.length] = {
              src: res?.data?.data?.image,
            }
            setUploadProgress(i + 1)
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      setAttachments(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)
      e.target.value = null
    }
  }

  const [dragActive, setDragActive] = React.useState(false)
  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  useEffect(() => {
    getOwners()
    getDevelopment()
  }, [])

  useEffect(() => {
    // if (isEdit) return
    if (propertyDevelopment?.type === 'community') {
      getCluster(propertyDevelopment?.value)
    }
    // getGroups()
  }, [propertyDevelopment])

  useEffect(() => {
    if (propertyCluster?.type === 2) {
      getGroups(propertyDevelopment?.value,propertyCluster?.value)
    }
  }, [propertyCluster])

  useEffect(() => {
    // if (isEdit) return
    if (propertyDevelopment?.type === 'building') {
      getFloors(propertyDevelopment?.value)
    }
  }, [propertyDevelopment])

  const resetForPropertyDevelopment = () => {
    setPropertyFloors('')
    setPropertyCluster('')
    setPropertyBuilding('')
    setPropertyGroup('')
    resetPropertyCategory()
    resetPropertyType()
    resetUnitType()
    resetOwner()
  }

  const resetPropertyCategory = () => {
    setSelectCommerciallBox(false)
    setSelectResidentialBox(false)
  }

  useEffect(() => {
    if (isEdit) return
    resetForPropertyDevelopment()
  }, [propertyDevelopment])

  

  const checkBeforeDelete = async () => {
    setIsLoading(true)
    await ApiGet(`corporate/unit/deletion_checker/${propertyId}`)
      .then((res) => {
        setIsLoading(false)
        let tenancyStatus = res?.data?.data?.validation?.tenancyData?.[0]?.tenancyStatus
        let isAbleToDelete = res?.data?.data?.isAbleToDelete
        if(tenancyStatus === 0 || tenancyStatus === 1 || tenancyStatus === 2 && isAbleToDelete === false) {
          ErrorToast('Unable to delete property.This property has an ongoing lease / pending payments, to delete this property you must end your lease and complete/close pending payments related to it.')
        }else{
          swal.fire({
            html: `
        <div class='fs-4 mt-4 fw-bold'> 
        Delete Property
         </div>
            <div class='fs-5 mt-4'>
            You are about to delete this property. Deleting this property will prevent you from managing the property, however, all past records of this property such as (expenses, announcements, leases, etc.) will be retained.
            </div>
            <div class='mt-4 fs-5 fw-bold'>
            Do you wish to continue?
            </div>
`,
            // icon: 'warning',
            showConfirmButton: true,
            confirmButtonColor: '#fff',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'No',
            cancelButtonColor: '#0d9e91',
            customClass: {
                confirmButton: 'custom-confirm-button-2',
                cancelButton: 'custom-cancel-button-2',
                popup: 'custom-popup',
            },
            reverseButtons: false, // Reverse the order of buttons reverseButtons: true,
        })
            .then((res: any) => {
                if (res.isConfirmed) {
                    handleDeleteUnit()

                }
            })
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const handleDeleteUnit = async () => {
    setIsLoading(true)
    await ApiDelete(`corporate/unit/${propertyId}`)
      .then((res) => {
        setIsLoading(false)
        if (location?.state.from === 'buildingDetail') {
          navigate(`/properties/building-details/${location?.state?.buildingId}`,
          {state:{
            area:location?.state?.area,
            city:location?.state?.city,
            buildingId: location?.state?.buildingId,
            buildingName: location?.state?.buildingName,
            
          }})
        } else if (location?.state.from === 'buildingDetailFloor') {
          navigate(
            `/properties/building-details/${location?.state?.buildingId}/floors/${location?.state?.floorId}`,
            {
              state: {
                buildingName:location?.state?.buildingName ,
                floorName: location?.state.floorName,
                buildingId: location?.state?.buildingId,
                floorId: location?.state?.floorId,
                area:location?.state?.area,
                      city:location?.state?.city
              },
            }
          )
        } else if (location?.state.from === 'communityDetail') {
          navigate(`/properties/community-details/${location?.state?.communityId}`, {
            state: {
              communityName: location?.state?.communityName,
              communityId: location?.state.communityId,
              area:location?.state?.area,
                      city:location?.state?.city
            },
          })
        } else if (location?.state?.type === 0 && location?.state?.from !== "propertyProfile") {
          navigate(
            `/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}`,
            {
              state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                area:location?.state?.area,
                city:location?.state?.city
              },
            }
          )
        } else if (location?.state?.type === 1  && !location?.state.buildingId && location?.state?.from !== "propertyProfile") {
          navigate(
            `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}`,
            {
              state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                area:location?.state?.area,
                      city:location?.state?.city
              },
            }
          )
        } else if (location?.state?.type === 2 && !location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
          navigate(
            `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}`,
            {
              state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                area:location?.state?.area,
                      city:location?.state?.city
              },
            }
          )
        } else if (location?.state?.type === 1 && location?.state.buildingId && !location?.state.floorId && location?.state?.from !== "propertyProfile") {
          navigate(
            `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}`,
            {
              state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                buildingName: location?.state.buildingName,
                buildingId: location?.state.buildingId,
                area:location?.state?.area,
                      city:location?.state?.city
              },
            }
          )
        }else if (location?.state?.type === 1 && location?.state.buildingId && location?.state.floorId && location?.state?.from !== "propertyProfile") {
          navigate(
            `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}/floor/${location?.state.floorId}`,
            {
              state: {
                type: location?.state?.type,
                clusterName: location?.state?.clusterName,
                communityId: location?.state?.communityId,
                clusterId: location?.state?.clusterId,
                communityName: location?.state?.communityName,
                buildingName: location?.state.buildingName,
                buildingId: location?.state.buildingId,
                floorName: location?.state.floorName,
                floorId: location?.state.floorId,
                area:location?.state?.area,
                city:location?.state?.city
              },
            }
          )
        }else if (location?.state?.type === 2 && location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
          navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}`,
          { state: 
              { 
                      type: location?.state?.type,
                      clusterName: location?.state?.clusterName,
                      communityId: location?.state?.communityId,
                      clusterId: location?.state?.clusterId,
                      communityName:location?.state?.communityName,
                      groupName: location?.state?.groupName,
                      groupId: location?.state?.groupId,
                      groupType: location?.state?.groupType,
                      area:location?.state?.area,
                      city:location?.state?.city
                   }})   
        }else if (location?.state?.type === 2 && location?.state?.groupId &&  location?.state.floorId && location?.state?.from !== "propertyProfile") {
          navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state.floorId}`,
          { state: 
              { 
                      type: location?.state?.type,
                      clusterName: location?.state?.clusterName,
                      communityId: location?.state?.communityId,
                      clusterId: location?.state?.clusterId,
                      communityName:location?.state?.communityName,
                      groupName: location?.state?.groupName,
                      groupId: location?.state?.groupId,
                      groupType: location?.state?.groupType,
                      floorName:location?.state?.floorName,
                      floorId:location?.state?.floorId,
                      area:location?.state?.area,
                      city:location?.state?.city
                   }})   
        }else if (location?.state?.from === 'propertyProfile'){
          navigate(-1)
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  function capitalizeFirstLetter(word: any) {
    if (!word || typeof word !== 'string') {
      return word
    }
    const firstLetter = word.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = word.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters
    return capitalizedWord
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)


  // property deed file and owner
  
  // Helper function to generate unique IDs for each owner
  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const [ownerType, setOwnerType] = useState<string>('')
  const [ownerOptions, setOwnerOptions] = useState<any>([])
  const [selectedOwner, setSelectedOwner] = React.useState<any>([])
  const [percentEMsg, setPercentEMsg] = useState<boolean>(false)
  const [percentMsg, setPercentMsg] = useState<boolean>(false)
  const sectionRef = React.useRef<any>()

  const [owners, setOwners] = useState<any>([
    {id: generateUUID(), selectedOwner: [], percentage: ''},
    {id: generateUUID(), selectedOwner: [], percentage: ''},
  ])

  const handleOwnerChange = (id: any, selectedValue: any) => {
    setIsCTAHidden(false)
    let newVal: any = selectedValue
    if (selectedValue?.length > 1) newVal = selectedValue.slice(1)
    setOwners((prevOwners: any) => prevOwners?.map((owner: any) => (owner?.id === id ? {...owner, selectedOwner: newVal} : owner)))
  }

  const handlePercentageChange = (id: any, value: string, index: number) => {
    setIsCTAHidden(false)
    const numericValue = value.replace(/[^0-9.]/g, '') // allow numeric and decimal values
    const totalPercentageTemp = owners.reduce((acc: any, owner: any) => acc + (owner.id === id ? 0 : parseFloat(owner.percentage || '0')), 0)

    if(parseFloat(numericValue) === 100 || parseFloat(numericValue) === 0 || numericValue === '' || owners.some((owner:any) => owner.id === id ? (parseInt(numericValue) === 100 || parseInt(numericValue) === 0 || numericValue === '') : (parseInt(owner?.percentage) === 100 || parseInt(owner?.percentage) === 0 || owner?.percentage === ''))){
      setPercentMsg(true)
      setPercentEMsg(false)
    }else{
      setPercentMsg(false)
      if (totalPercentageTemp + parseFloat(numericValue || '0') > 100) {
        setPercentEMsg(true)
        // return
      } else if (totalPercentageTemp + parseFloat(numericValue || '0') < 100) {
        setPercentEMsg(true)
      } else {
        setPercentEMsg(false)
      }
    }
   

    setOwners((prevOwners: any) => prevOwners.map((owner: any) => (owner.id === id ? {...owner, percentage: numericValue} : owner)))
    // Move the cursor to the correct position
    const inputField = percentageInputRefs.current[index]
    if (inputField) {
      const cursorPosition = numericValue.length // Place cursor right after numeric value
      setTimeout(() => inputField.setSelectionRange(cursorPosition, cursorPosition), 0)
    }
  }

  const calculateTotalPercentage = () => {
    // if (owners.some((row: any) => row.selectedOwner.length > 0) && owners.some((row: any) => row.percentage !== '')) {
    if (owners.some((row: any) => row.selectedOwner.length > 0)) {
      let totalPercentage = owners.reduce((acc: any, owner: any) => acc + (owner.percentage == '' ? 0 : parseFloat(owner.percentage)), 0)
      if(owners.some((row: any) => parseInt(row.percentage) === 100 || parseInt(row.percentage) === 0 || row.percentage === '')){
        setPercentMsg(true)
        setPercentEMsg(false)
      }else{
        setPercentMsg(false)
        if (totalPercentage > 100 || totalPercentage < 100) {
          setPercentEMsg(true)
        } else {
          setPercentEMsg(false)
        }
      }
     
    }
  }

  const resetOwner = () => {
    // reset deed and owner dropdown
  
    setPercentEMsg(false)
    setFile(null)
    setOwners([
      {id: generateUUID(), selectedOwner: [], percentage: ''},
      {id: generateUUID(), selectedOwner: [], percentage: ''},
    ])
    setSelectedOwner([])
    setPercentMsg(false)
  }

  const resetOwnerOther = () => {
    // reset deed and owner dropdown
  
    setPercentEMsg(false)
    setOwners([
      {id: generateUUID(), selectedOwner: [], percentage: ''},
      {id: generateUUID(), selectedOwner: [], percentage: ''},
    ])
    setSelectedOwner([])
    setPercentMsg(false)
  }

  useEffect(() => {
    if (isEdit) {
      calculateTotalPercentage()
    }
  }, [owners])

  const getAvailableOwnerOptions = (currentId: number) => {
    const selectedOwners = owners?.flatMap((owner: any) => owner?.selectedOwner?.map((selectedOwner: any) => selectedOwner?.value)).filter(Boolean)
    return ownerOptions?.filter(
      (option: any) => !selectedOwners.includes(option?.value)
      // || owners.find((owner:any) => owner.id === currentId)?.selectedOwner?.value === option.value
    )
  }
  const percentageInputRefs = useRef<Array<HTMLInputElement | null>>([])

  const deleteOwner = (index: number) => {
    const updateOwner = [...owners]
    updateOwner.splice(index, 1)
    setOwners(updateOwner)
    setIsCTAHidden(false)
    calculateTotalPercentage()
  }
  const addOwner = () => {
    const updateOwner = [...owners]
    updateOwner.push({id: generateUUID(), selectedOwner: [], percentage: ''})
    setOwners(updateOwner)
    calculateTotalPercentage()
    setIsCTAHidden(false)
  }

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected?.length > 0 ? `${selected?.[0]?.label}` : 'Select Owner'
  }

  const [file, setFile] = useState<any>(null)
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | null>(null)

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null

    if (selectedFile) {
      const allowedTypes = [
        'application/pdf',
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ]

      if (!allowedTypes.includes(selectedFile.type)) {
        setFile(null)
        return
      }

      setIsCTAHidden(false)
      setShowLoader(true)
      setTotalFiles(e.target.files?.length ?? 0)
      let formdata = new FormData()
      formdata.append('image', selectedFile)

      await ApiPost(`upload/originalname/image/title_deed`, formdata)
        .then((res) => {
          setFile({
            name: res?.data?.data?.image?.split('/')[3],
            size: res?.data?.data?.size,
            src: res?.data?.data?.image,
          })
          setUploadProgress(1)
        })
        .catch((err) => {
          ErrorToast(err?.message)

          setShowLoader(false)
          setUploadProgress(0) // Reset upload progress
          setTotalFiles(0)
        })

      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = ''
    }
  }

  const getOwners = async () => {
    await ApiGet(`corporate/owners`)
      .then((res: any) => {
        const owners = res?.data?.data?.map((owner: any) => ({label: `${owner?.firstName} ${owner?.lastName}`, value: owner?._id}))
        tempOwnerOptions = owners
        setOwnerOptions(owners)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }
  // property deed file and owner

  function handlePropertyData(res: any) {
    if (res?.data?.data?.propertyType === 0) {
      setSelectResidentialBox(true)
    } else {
      setSelectCommerciallBox(true)
    }

    switch (res?.data?.data?.unitType) {
      case 0:
        setApartmentBox(true)
        break
      case 1:
        setPentHouseBox(true)
        break
      case 2:
        setCommonAreaBox(true)
        break
      case 3:
      case 4:
        setTownhouseBox(true)
        break
      default:
        setOtherBox(true)
    }

    setTimeout(() => {
      setAttachments(res?.data?.data?.images.map((item: any) => ({src: item})))
      setPropertyUnitNo(res?.data?.data?.unitNo)
      setPropertySize(res?.data?.data?.size)
      if (res?.data?.data?.sizeType === 0) {
        setPropertySizeType({value: 0, label: 'Sq ft'})
      } else {
        setPropertySizeType({value: 1, label: 'Sq m'})
      }
      setPropertyBedroom({
        label: `${res?.data?.data?.bedrooms} BR`,
        value: res?.data?.data?.bedrooms,
      })
      setPropertyBathroom({
        label: `${res?.data?.data?.bathrooms} Bath`,
        value: res?.data?.data?.bathrooms,
      })
      setPropertyUnitType(res?.data?.data?.otherName)

      if (res?.data?.data?.unitType === 2) {
        setPropertyCommonAreaNo(res?.data?.data?.unitNo)
      }

      setPropertyType({
        label: res?.data?.data?.commonAreaName,
        value: res?.data?.data?.commonAreaName,
      })
      if (res?.data?.data?.commonAreaName === 'Other') {
        setPropertyCommonAreaName(res?.data?.data?.commonAreaName)
      }

      setOwnerType(res?.data?.data?.ownerType === 0 ? 'singleOwner' : res?.data?.data?.ownerType === 1 ? 'jointOwner' : '')
      if (res?.data?.data?.titleDeedFile) {
        setFile({
          name: res?.data?.data?.titleDeedFile?.split('/')[3],
          src: res?.data?.data?.titleDeedFile,
        })
      }

      if (res?.data?.data?.ownerType === 0 && res?.data?.data?.ownerData?.length > 0) {
        const selectedOwner = tempOwnerOptions?.find((owner: any) => owner?.value === res?.data?.data?.ownerData[0]?._id)
        setSelectedOwner([selectedOwner])
      } else if (res?.data?.data?.ownerType === 1 && res?.data?.data?.ownerData?.length > 0) {
        const matchedOwners = tempOwnerOptions
          .map((owner: any) => {
            const matchedOwner = res?.data?.data?.ownerData?.reduce((acc: any, person: any) => {
              let obj = {}
              if (person?._id === owner?.value) {
                obj = {
                  value: person?._id,
                  label: owner?.label,
                }
                acc = obj
              }
              return acc
            }, {})
            return matchedOwner !== undefined ? matchedOwner : null
          })
          .filter((owner: any) => Object.keys(owner).length > 0)

        const tempOnwer = res?.data?.data?.ownerData?.map((owner: any) => {
          return {
            id: owner?._id,
            selectedOwner: matchedOwners?.filter((person: any) => person?.value === owner?._id),
            percentage: String(owner?.ownershipPercentage),
          }
        })

        setOwners(tempOnwer)
      }

    }, 500)
  }

  const getPropertyById = async () => {
    setIsLoading(true)
    await ApiGet(`corporate/unit/${propertyId}`)
      .then((res: any) => {

        if (res?.data?.data?.communityId !== null && res?.data?.data?.community?.length > 0) {
          const development = res?.data?.data.community?.reduce((acc: any, item: any) => {
            const obj = {
              value: item?._id,
              label: item?.name,
              type: 'community',
            }
            acc = obj
            return acc
          }, {})
          setPropertyDevelopment(development)

          const cluster = res?.data?.data?.cluster?.reduce((acc: any, item: any) => {
            const obj = {
              value: item?._id,
              label: item?.name,
              type: item?.type,
            }
            acc = obj
            return acc
          }, {})
          setPropertyCluster(cluster)

          if (res?.data?.data?.cluster[0]?.type === 1) {
            getBuildingForCommunity(res?.data?.data?.communityId,res?.data?.data?.cluster[0]?._id)
            getFloors(res?.data?.data?.building[0]?._id)
            setTimeout(() => {
              const getBuilding = res?.data?.data?.building.reduce((acc: any, item: any) => {
                const obj = {
                  value: item?._id,
                  label: item?.name,
                }
                acc = obj
                return acc
              }, {})
              setPropertyBuilding(getBuilding)

              const getFloor = res?.data?.data.floor.reduce((acc: any, item: any) => {
                const obj = {
                  value: item?._id,
                  label: item?.name,
                }
                acc = obj
                return acc
              }, {})
              setPropertyFloors(getFloor)
            }, 300)
          } else if (res?.data?.data.cluster[0]?.type == 2) {
            if (res?.data?.data.buildingId) {
              getFloors(res?.data?.data?.building[0]?._id)

              const buildingGroup = res?.data?.data.building.reduce((acc: any, item: any) => {
                const obj = {
                  value: item?._id,
                  label: item?.name,
                  type: 'building',
                }
                acc = obj
                return acc
              }, {})
              setPropertyGroup(buildingGroup)

              const getFloor = res?.data?.data.floor.reduce((acc: any, item: any) => {
                const obj = {
                  value: item?._id,
                  label: item?.name,
                }
                acc = obj
                return acc
              }, {})
              setPropertyFloors(getFloor)
            } else {
              const unitGroup = res?.data?.data.unit_group.reduce((acc: any, item: any) => {
                const obj = {
                  value: item?._id,
                  label: item?.name,
                  type: 'unitGroup',
                }
                acc = obj
                return acc
              }, {})
              setPropertyGroup(unitGroup)
            }
          }

          handlePropertyData(res)
        } else {
          const development = res?.data?.data.building.reduce((acc: any, item: any) => {
            const obj = {
              value: item?._id,
              label: item?.name,
              type: 'building',
            }
            acc = obj
            return acc
          }, {})
          setPropertyDevelopment(development)

          getFloors(res?.data?.data?.buildingId)

          const floor = res?.data?.data?.floor?.reduce((acc: any, item: any) => {
            const obj = {
              value: item?._id,
              label: item?.name,
            }
            acc = obj
            return acc
          }, {})
          setPropertyFloors(floor)
        }
        handlePropertyData(res)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (isEdit) {
      // get data function
      getPropertyById()
    }
  }, [])

  // React.useEffect(() => {
  //   fancybox.bind('[data-fancybox]', {
  //     Toolbar: {
  //       display: {
  //         left: ['infobar'],
  //         middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
  //         right: ['slideshow', 'thumbs', 'close'],
  //       },
  //     },
  //   })

  //   // return () => {
  //   //   fancybox.destroy();
  //   // };
  // }, []);

  const handleImageClickOfJobsAttachments = (
    attachmentId: string,
    attachmentSrc: string,
    attachments: any,
    val: any
  ) => {

    const groupName = `group_property_attachments_${attachmentId}`
  
    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(attachmentId)

    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', attachmentSrc)
    }
  
    // Add data-fancybox attribute to the related attachments
    attachments?.forEach((attach: any, idx: number) => {
      const imageNext = document.getElementById(`${val}${idx}`)
      if (imageNext) {
        imageNext.setAttribute('data-fancybox', groupName)
        imageNext.setAttribute('data-src', `${Bucket}${attach?.src}`)
      }
    })
  
    // Destroy and reinitialize Fancybox to ensure it recognizes the new attributes
    fancybox.destroy()
    fancybox.bind("[data-fancybox]", {})
  
    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    return () => {
      fancybox.destroy();
    };
  }, [])
  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        <div className='row d-flex align-items-center'>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center mb-1'>
                  <h2 className='m-0 head-text ms-2'>
                    <b> {isEdit ? 'Edit a Property' : 'Add a Property'}</b>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center justify-content-end'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold red-hollow-btn ps-2  mt-2'
                  onClick={() => {
                    // navigate(`/properties`)
                    if (location?.state.from === 'buildingDetail') {
                      navigate(`/properties/building-details/${location?.state?.buildingId}`,
                      {state:{
                        area:location?.state?.area,
                        city:location?.state?.city,
                        buildingId: location?.state?.buildingId,
                        buildingName: location?.state?.buildingName,
                        
                      }})
                    } else if (location?.state.from === 'buildingDetailFloor') {
                      navigate(
                        `/properties/building-details/${location?.state?.buildingId}/floors/${location?.state?.floorId}`,
                        {
                          state: {
                            buildingName:location?.state?.buildingName ,
                            floorName: location?.state.floorName,
                            buildingId: location?.state?.buildingId,
                            floorId: location?.state?.floorId,
                            area:location?.state?.area,
                                  city:location?.state?.city
                          },
                        }
                      )
                    } else if (location?.state.from === 'communityDetail') {
                      navigate(`/properties/community-details/${location?.state?.communityId}`, {
                        state: {
                          communityName: location?.state?.communityName,
                          communityId: location?.state.communityId,
                          area:location?.state?.area,
                                  city:location?.state?.city
                        },
                      })
                    } else if (location?.state?.type === 0) {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area:location?.state?.area,
                            city:location?.state?.city
                          },
                        }
                      )
                    } else if (location?.state?.type === 1  && !location?.state.buildingId && location?.state?.from !== "propertyProfile") {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area:location?.state?.area,
                                  city:location?.state?.city
                          },
                        }
                      )
                    } else if (location?.state?.type === 2 && !location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            area:location?.state?.area,
                                  city:location?.state?.city
                          },
                        }
                      )
                    } else if (location?.state?.type === 1 && location?.state.buildingId && !location?.state.floorId && location?.state?.from !== "propertyProfile") {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            buildingName: location?.state.buildingName,
                            buildingId: location?.state.buildingId,
                            area:location?.state?.area,
                                  city:location?.state?.city
                          },
                        }
                      )
                    }else if (location?.state?.type === 1 && location?.state.buildingId && location?.state.floorId && location?.state?.from !== "propertyProfile") {
                      navigate(
                        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}/floor/${location?.state.floorId}`,
                        {
                          state: {
                            type: location?.state?.type,
                            clusterName: location?.state?.clusterName,
                            communityId: location?.state?.communityId,
                            clusterId: location?.state?.clusterId,
                            communityName: location?.state?.communityName,
                            buildingName: location?.state.buildingName,
                            buildingId: location?.state.buildingId,
                            floorName: location?.state.floorName,
                            floorId: location?.state.floorId,
                            area:location?.state?.area,
                            city:location?.state?.city
                          },
                        }
                      )
                    }else if (location?.state?.type === 2 && location?.state?.groupId &&  !location?.state.floorId && location?.state?.from !== "propertyProfile") {
                      navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}`,
                      { state: 
                          { 
                                  type: location?.state?.type,
                                  clusterName: location?.state?.clusterName,
                                  communityId: location?.state?.communityId,
                                  clusterId: location?.state?.clusterId,
                                  communityName:location?.state?.communityName,
                                  groupName: location?.state?.groupName,
                                  groupId: location?.state?.groupId,
                                  groupType: location?.state?.groupType,
                                  area:location?.state?.area,
                                  city:location?.state?.city
                               }})   
                    }else if (location?.state?.type === 2 && location?.state?.groupId &&  location?.state.floorId && location?.state?.from !== "propertyProfile") {
                      navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state.floorId}`,
                      { state: 
                          { 
                                  type: location?.state?.type,
                                  clusterName: location?.state?.clusterName,
                                  communityId: location?.state?.communityId,
                                  clusterId: location?.state?.clusterId,
                                  communityName:location?.state?.communityName,
                                  groupName: location?.state?.groupName,
                                  groupId: location?.state?.groupId,
                                  groupType: location?.state?.groupType,
                                  floorName:location?.state?.floorName,
                                  floorId:location?.state?.floorId,
                                  area:location?.state?.area,
                                  city:location?.state?.city
                               }})   
                    }else if(location?.state?.from === "propertyProfile"){
                      navigate(-1)
                    }
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                </button>

                {(isEdit && !isCTAHidden) && (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold green-submit-btn ms-4 ps-2 mt-2 ms-4'
                    onClick={() => addProperty()}
                  >
                    <img src={saveW} height={18} width={18} className='me-3' /> Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush  px-7' style={{paddingBottom: '80px', paddingTop: '30px'}}>
          <div className='d-flex justify-content-center mb-6'>
            <h2 className='m-0 head-text'>
              <b> Property Location</b>
            </h2>
          </div>

          <div className='card card-flush py-5 px-7'>
            
            <div className='row'>
              <div className='col 8'>
                <div className='row'>
                  <div className='col-4'>
                    <h3 className='m-0 head-text mb-3'>
                      <b>Development</b>
                    </h3>
                    <Select
                      isDisabled={location?.state?.buildingId || location?.state.communityId}
                      styles={customStyles}
                      isSearchable={false}
                      value={propertyDevelopment}
                      options={developmentOptions}
                      name='development'
                      onChange={(e: any) => {
                        setPropertyDevelopment(e)
                        setIsCTAHidden(false)
                        if (isEdit) {
                          resetForPropertyDevelopment()

                          if (e?.type === 'building') {
                            getFloors(e?.value)
                          }
                          if (e?.type === 'community') {
                            getCluster(e?.value)
                          }
                          //
                        }
                      }}
                      placeholder={'Development'}
                    />
                  </div>
                  {propertyDevelopment?.type === 'community' && (
                    <div className='col-4 '>
                      <h3 className='m-0 head-text mb-3'>
                        <b>Cluster</b>
                      </h3>

                      <Select
                        isDisabled={location?.state?.clusterId}
                        defaultValue={''}
                        value={propertyCluster}
                        styles={customStyles}
                        isSearchable={false}
                        options={clusterOptions}
                        name='cluster'
                        onChange={(e: any) => {
                          setPropertyCluster(e)
                          setIsCTAHidden(false)
                          if (isEdit) {
                            if (e?.type == 1) {
                              getBuildingForCommunity(propertyDevelopment?.value,e?.value)
                            }else if(e?.type == 2){
                              getGroups(propertyDevelopment?.value,e?.value)
                            }
                            setPropertyBuilding('')
                            setPropertyFloors('')
                            setPropertyGroup('')
                            resetPropertyCategory()
                            resetPropertyType()
                          }
                        }}
                        placeholder={'Cluster'}
                      />
                    </div>
                  )}

                  {propertyDevelopment?.type === 'building' && (
                    <div className='col-4 '>
                      <h3 className='m-0 head-text mb-3'>
                        <b>Floor</b>
                      </h3>
                      <Select
                        isDisabled={location?.state?.floorId}
                        defaultValue={''}
                        value={propertyFloors}
                        styles={customStyles}
                        isSearchable={false}
                        options={floorsOptions}
                        name='floor'
                        onChange={(e: any) => {
                          setPropertyFloors(e)
                          setIsCTAHidden(false)
                          if (isEdit) {
                            resetPropertyCategory()
                            resetPropertyType()
                            resetUnitType()
                          }
                        }}
                        placeholder={'Floor'}
                      />
                    </div>
                  )}
                </div>
                <div className='row mt-7'>
                  {propertyCluster?.type === 2 && (
                    <div className='col-4 '>
                      <h3 className='m-0 head-text mb-3'>
                        <b>Group</b>
                      </h3>
                      <Select
                      isDisabled={location?.state?.groupId}
                        styles={customStyles}
                        isSearchable={false}
                        options={groupOptions}
                        value={propertyGroup}
                        defaultValue={''}
                        name='group'
                        onChange={(e: any) => {
                          setPropertyGroup(e)
                          setIsCTAHidden(false)
                          if (isEdit) {
                            getFloors(e?.value)
                            setPropertyFloors('')
                            resetPropertyCategory()
                            resetPropertyType()
                            resetUnitType()
                          }
                        }}
                        placeholder={'Group'}
                      />
                    </div>
                  )}
                  {propertyCluster?.type === 1 && (
                    <div className='col-4 '>
                      <h3 className='m-0 head-text mb-3'>
                        <b>Building</b>
                      </h3>
                      <Select
                        isDisabled={location?.state?.buildingId}
                        styles={customStyles}
                        isSearchable={false}
                        options={buildingOptions}
                        value={propertyBuilding}
                        defaultValue={''}
                        name='building'
                        onChange={(e: any) => {
                          setPropertyBuilding(e)
                          setIsCTAHidden(false)
                          if (isEdit) {
                            getFloors(e?.value)
                            setPropertyFloors('')
                            resetPropertyCategory()
                            resetPropertyType()
                            resetUnitType()
                          }
                        }}
                        placeholder={'Building'}
                      />
                    </div>
                  )}
                  {(propertyGroup?.type === 'building' ||
                    (propertyCluster?.type === 1 && propertyBuilding !== '')) &&
                    propertyDevelopment?.type === 'community' && (
                      <div className='col-4 '>
                        <h3 className='m-0 head-text mb-3'>
                          <b>Floor</b>
                        </h3>
                        <Select
                          isDisabled={location?.state.floorId}
                          defaultValue={''}
                          value={propertyFloors}
                          styles={customStyles}
                          isSearchable={false}
                          options={floorsOptions}
                          name='floor'
                          onChange={(e: any) => {
                            setPropertyFloors(e)
                            setIsCTAHidden(false)
                            if (isEdit) {
                              resetPropertyCategory()
                              resetPropertyType()
                              resetUnitType()
                            }
                          }}
                          placeholder={'Floor'}
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className='col-4 d-flex flex-column align-items-end'>
                <div className='d-flex align-items-end justify-content-end mb-4'>
                  <img
                    src={
                      propertyDevelopment?.type === 'community'
                        ? communityBlue
                        : propertyDevelopment?.type === 'building'
                        ? alllBuildingIcon
                        : ''
                    }
                    width='40'
                    className='me-3'
                  />
                  <h3 className='m-0 head-text'>
                    {location?.state?.from === 'buildingDetail' ? location?.state?.buildingName : location?.state?.communityName}
                  </h3>
                </div>
                <h3 className='m-0 head-text mb-4'>{location?.state?.area ? location?.state?.area :'-'}</h3>
                <h3 className='m-0 head-text mb-4'>{location?.state?.city ? location?.state?.city :'-'}</h3>
              </div>
            </div>
          </div>

          <div className='row'>
            {showPropertyCategory && (
              <div className='col-8 mx-auto'>
                {/* select category */}
                <div className='row mt-7'>
                  <div className='col-12 d-flex justify-content-center mt-7'>
                    <h2 className='m-0 head-text mb-7'>
                      <b>Select Property Category</b>
                    </h2>
                  </div>
                  <div className='row'>
                    <div className='col-12 d-flex justify-content-center' style={{gap: '20px'}}>
                      <div
                        className={`${
                          selectResidentialBox ? 'requestBoxColor' : 'requestBox'
                        } mt-3`}
                        onClick={() => {
                          if (selectResidentialBox || selectCommercialBox) {
                            swal
                              .fire({
                                html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                                <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                            `,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  setIsCTAHidden(false)
                                  setSelectResidentialBox(!selectResidentialBox)
                                  setSelectCommerciallBox(false)
                                  if (isEdit) {
                                    resetPropertyType()
                                    resetUnitType()
                                  }
                                  // resetPropertyType()
                                }
                              })
                          } else {
                            setSelectResidentialBox(!selectResidentialBox)
                            setSelectCommerciallBox(false)
                          }
                        }}
                      >
                        <img
                          src={selectResidentialBox ? residentialWhiteIcon : residentialGreenIcon}
                          height={80}
                          width={80}
                          className='d-block'
                        />
                        <h2
                          className={`head-text mt-3 ${
                            selectResidentialBox ? 'headTextWhite' : ''
                          }`}
                        >
                          <b>Residential</b>
                        </h2>
                      </div>
                      <div
                        className={`${selectCommercialBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                        onClick={() => {
                          if (selectResidentialBox || selectCommercialBox) {
                            swal
                              .fire({
                                html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                    <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                `,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  setSelectCommerciallBox(!selectCommercialBox)
                                  setSelectResidentialBox(false)
                                  setIsCTAHidden(false)
                                  if (isEdit) {
                                    resetPropertyType()
                                    resetUnitType()
                                  }
                                  // resetPropertyType()
                                }
                              })
                          } else {
                            setSelectCommerciallBox(!selectCommercialBox)
                            setSelectResidentialBox(false)
                          }
                        }}
                      >
                        <img
                          src={
                            selectCommercialBox
                              ? commercialFloorWhiteIcon
                              : commercialFloorGreenIcon
                          }
                          height={80}
                          width={80}
                          className='d-block'
                        />
                        <h2
                          className={`head-text mt-3 ${selectCommercialBox ? 'headTextWhite' : ''}`}
                        >
                          <b>Commercial</b>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/* select property type */}
                {(selectResidentialBox || selectCommercialBox) && (
                  <div className='row mt-7'>
                    <div className='col-12 d-flex flex-column align-items-center justify-content-center mt-7'>
                      <h2 className='m-0 head-text mb-3'>
                        <b>Select Property Category</b>
                      </h2>
                      <h4 className='m-0 head-text mb-7'>
                        Select the type that best describes your property
                      </h4>
                    </div>
                    <div className='row'>
                      <div className='col-12 d-flex justify-content-center' style={{gap: '20px'}}>
                        {/* apartment  tab*/}
                        {(propertyCluster?.type === 1 ||
                          propertyGroup?.type === 'building' ||
                          propertyDevelopment?.type === 'building') && (
                          <div
                            className={`${apartmentBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                            onClick={() => {
                              if (apartmentBox || pentHouseBox || otherBox || commonAreaBox) {
                                swal
                                  .fire({
                                    html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                                <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                            `,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                  })
                                  .then((res: any) => {
                                    if (res.isConfirmed) {
                                      setApartmentBox(!apartmentBox)
                                      setPentHouseBox(false)
                                      setOtherBox(false)
                                      setCommonAreaBox(false)
                                      setTownhouseBox(false)
                                      setVillaBox(false)
                                      setIsCTAHidden(false)
                                      if (isEdit) {
                                        resetUnitType()
                                      }
                                    }
                                  })
                              } else {
                                setApartmentBox(!apartmentBox)
                                setPentHouseBox(false)
                                setOtherBox(false)
                                setCommonAreaBox(false)
                                setTownhouseBox(false)
                                setVillaBox(false)
                              }
                            }}
                          >
                            <img
                              src={apartmentBox ? appartment : appartment}
                              height={80}
                              width={80}
                              className='d-block'
                            />

                            <h2 className={`head-text mt-3 ${apartmentBox ? 'headTextWhite' : ''}`}>
                              <b>Apartment</b>
                            </h2>
                          </div>
                        )}
                        {/* penthouse tab */}
                        {(propertyCluster?.type === 1 ||
                          propertyGroup?.type === 'building' ||
                          propertyDevelopment?.type === 'building') && (
                          <div
                            className={`${pentHouseBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                            onClick={() => {
                              if (apartmentBox || pentHouseBox || otherBox || commonAreaBox) {
                                swal
                                  .fire({
                                    html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                    <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                `,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                  })
                                  .then((res: any) => {
                                    if (res.isConfirmed) {
                                      setApartmentBox(false)
                                      setPentHouseBox(!pentHouseBox)
                                      setOtherBox(false)
                                      setCommonAreaBox(false)
                                      setTownhouseBox(false)
                                      setVillaBox(false)
                                      setIsCTAHidden(false)
                                      if (isEdit) {
                                        resetUnitType()
                                      }
                                    }
                                  })
                              } else {
                                setApartmentBox(false)
                                setPentHouseBox(!pentHouseBox)
                                setOtherBox(false)
                                setCommonAreaBox(false)
                                setTownhouseBox(false)
                                setVillaBox(false)
                              }
                            }}
                          >
                            <img
                              src={pentHouseBox ? penthouse : penthouse}
                              height={80}
                              width={80}
                              className='d-block'
                            />
                            <h2 className={`head-text mt-3 ${pentHouseBox ? 'headTextWhite' : ''}`}>
                              <b>Penthouse</b>
                            </h2>
                          </div>
                        )}

                        {/* townhouse  tab*/}
                        {(propertyCluster?.type === 0 || propertyGroup?.type === 'unitGroup') && (
                          <div
                            className={`${townhouseBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                            onClick={() => {
                              if (townhouseBox || villaBox || otherBox || commonAreaBox) {
                                swal
                                  .fire({
                                    html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                                <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                            `,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                  })
                                  .then((res: any) => {
                                    if (res.isConfirmed) {
                                      setIsCTAHidden(false)
                                      setTownhouseBox(!townhouseBox)
                                      setVillaBox(false)
                                      setOtherBox(false)
                                      setCommonAreaBox(false)
                                      if (isEdit) {
                                        resetUnitType()
                                      }
                                    }
                                  })
                              } else {
                                setTownhouseBox(!townhouseBox)
                                setVillaBox(false)
                                setOtherBox(false)
                                setCommonAreaBox(false)
                              }
                            }}
                          >
                            <img
                              src={townhouseBox ? townhouseIcon : townhouseIcon}
                              height={80}
                              width={80}
                              className='d-block'
                            />

                            <h2 className={`head-text mt-3 ${townhouseBox ? 'headTextWhite' : ''}`}>
                              <b>Townhouse</b>
                            </h2>
                          </div>
                        )}

                        {/* townhouse  tab*/}
                        {(propertyCluster?.type === 0 || propertyGroup?.type === 'unitGroup') && (
                          <div
                            className={`${villaBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                            onClick={() => {
                              if (townhouseBox || villaBox || otherBox || commonAreaBox) {
                                swal
                                  .fire({
                                    html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                                <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                            `,
                                    // icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#35475e',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'No',
                                    cancelButtonColor: '#fff',
                                    customClass: {
                                      confirmButton: 'custom-confirm-button',
                                      cancelButton: 'custom-cancel-button',
                                      popup: 'custom-popup',
                                    },
                                    reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                  })
                                  .then((res: any) => {
                                    if (res.isConfirmed) {
                                      setIsCTAHidden(false)
                                      setTownhouseBox(false)
                                      setVillaBox(!villaBox)
                                      setOtherBox(false)
                                      setCommonAreaBox(false)
                                      if (isEdit) {
                                        resetUnitType()
                                      }
                                    }
                                  })
                              } else {
                                setTownhouseBox(false)
                                setVillaBox(!villaBox)
                                setOtherBox(false)
                                setCommonAreaBox(false)
                              }
                            }}
                          >
                            <img
                              src={villaBox ? villaIcon : villaIcon}
                              height={80}
                              width={80}
                              className='d-block'
                            />

                            <h2 className={`head-text mt-3 ${villaBox ? 'headTextWhite' : ''}`}>
                              <b>Villa</b>
                            </h2>
                          </div>
                        )}

                        {/* other tab */}
                        <div
                          className={`${otherBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                          onClick={() => {
                            if (
                              apartmentBox ||
                              pentHouseBox ||
                              otherBox ||
                              commonAreaBox ||
                              townhouseBox ||
                              villaBox
                            ) {
                              swal
                                .fire({
                                  html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                    <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                `,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    setIsCTAHidden(false)
                                    setApartmentBox(false)
                                    setPentHouseBox(false)
                                    setTownhouseBox(false)
                                    setVillaBox(false)
                                    setOtherBox(!otherBox)
                                    setCommonAreaBox(false)
                                    if (isEdit) {
                                      resetUnitType()
                                    }
                                  }
                                })
                            } else {
                              setApartmentBox(false)
                              setPentHouseBox(false)
                              setTownhouseBox(false)
                              setVillaBox(false)
                              setOtherBox(!otherBox)
                              setCommonAreaBox(false)
                            }
                          }}
                        >
                          <img
                            src={otherBox ? other : other}
                            height={80}
                            width={80}
                            className='d-block'
                          />
                          <h2 className={`head-text mt-3 ${otherBox ? 'headTextWhite' : ''}`}>
                            <b>Other</b>
                          </h2>
                        </div>

                        {/* common area tab */}
                        <div
                          className={`${commonAreaBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                          onClick={() => {
                            if (
                              apartmentBox ||
                              pentHouseBox ||
                              otherBox ||
                              commonAreaBox ||
                              townhouseBox ||
                              villaBox
                            ) {
                              swal
                                .fire({
                                  html: `<div class='fs-5 mt-4 fw-bold'> Are you sure you want to continue? </div>
                                    <div class='mt-2 fs-5'>Switching Property type or Category type will make loose your data.</div>
                                `,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    setIsCTAHidden(false)
                                    setApartmentBox(false)
                                    setPentHouseBox(false)
                                    setTownhouseBox(false)
                                    setVillaBox(false)
                                    setOtherBox(false)
                                    setCommonAreaBox(!commonAreaBox)
                                    if (isEdit) {
                                      resetUnitType()
                                    }
                                  }
                                })
                            } else {
                              setTownhouseBox(false)
                              setVillaBox(false)
                              setApartmentBox(false)
                              setPentHouseBox(false)
                              setOtherBox(false)
                              setCommonAreaBox(!commonAreaBox)
                            }
                          }}
                        >
                          <img
                            src={commonAreaBox ? common_area : common_area}
                            height={80}
                            width={80}
                            className='d-block'
                          />
                          <h2 className={`head-text mt-3 ${commonAreaBox ? 'headTextWhite' : ''}`}>
                            <b>Common Area</b>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* property input */}
                {(apartmentBox ||
                  pentHouseBox ||
                  otherBox ||
                  commonAreaBox ||
                  villaBox ||
                  townhouseBox) && (
                  <div className='row' style={{marginTop: '50px'}}>
                    <div className='col-12 d-flex flex-column align-items-center '>
                      <h2 className='m-0 head-text mb-3'>
                        <b>Property Details</b>
                      </h2>
                      <h4 className='m-0 head-text'>Provide the property details</h4>
                    </div>
                    <div className='col-12' style={{marginTop: '30px'}}>
                      <div className='row'>
                        {otherBox && (
                          <div className='col-12 mb-7'>
                            <div className='d-flex flex-column align-items-center'>
                              <h3 className='m-0 head-text mb-3'>Unit Type</h3>
                              <div className='xyz'>
                                <Paper component='form' style={searchContainerStyle}>
                                  <InputBase
                                    placeholder='Enter Unit Type'
                                    style={inputBaseStyle}
                                    inputProps={{'aria-label': 'Enter Unit Type'}}
                                    value={propertyUnitType}
                                    onChange={(e: any) => {
                                      const captilizeWord = capitalizeFirstLetter(e.target.value)
                                      setPropertyUnitType(captilizeWord)
                                   }}
                                  />
                                </Paper>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='col-6'>
                          {/* unit no */}
                          {(apartmentBox ||
                            pentHouseBox ||
                            otherBox ||
                            townhouseBox ||
                            villaBox) && (
                            <div className='d-flex align-items-center'>
                              <img src={unitNoIcon} height={20} width={20} className='me-3' />
                              <h3 className='m-0 head-text me-3' style={{width: '60px'}}>
                                Unit No.
                              </h3>
                              <div className='xyz'>
                                <Paper component='form' style={searchContainerStyle}>
                                  <InputBase
                                    placeholder='Enter unit number'
                                    style={inputBaseStyle}
                                    inputProps={{'aria-label': 'Unit Number'}}
                                    value={propertyUnitNo}
                                    onChange={(e: any) => {
                                      const captilizeWord = capitalizeFirstLetter(e.target.value)
                                      setPropertyUnitNo(captilizeWord)
                                      setIsCTAHidden(false)
                                    }}
                                  />
                                </Paper>
                              </div>
                            </div>
                          )}
                          {/* size */}
                          {(apartmentBox ||
                            pentHouseBox ||
                            otherBox ||
                            townhouseBox ||
                            villaBox) && (
                            <div className='d-flex align-items-center mt-7'>
                              <img src={sizeIcon} height={20} width={20} className='me-3' />
                              <h3 className='m-0 head-text me-3' style={{width: '60px'}}>
                                Size
                              </h3>
                              <div className='xyz'>
                                <Paper component='form' style={searchContainerStyle}>
                                  <InputBase
                                    placeholder='Enter unit size'
                                    style={inputBaseStyle}
                                    inputProps={{'aria-label': 'Unit Size'}}
                                    value={propertySize}
                                    onChange={(e: any) => {
                                      const captilizeWord = capitalizeFirstLetter(e.target.value.replace(/\D+/g, '').replace(/\s+/g, ''))
                                      setPropertySize(captilizeWord)
                                      setIsCTAHidden(false)
                                    }}
                                  />
                                </Paper>
                              </div>

                              <div className='ms-2'>
                                <Select
                                  styles={customStylesSmall}
                                  isSearchable={false}
                                  options={sizeOptions}
                                  value={propertySizeType}
                                  defaultValue={{value: 0, label: 'Sq ft'}}
                                  name='sizeType'
                                  onChange={(e: any) => {
                                    setPropertySizeType(e)
                                    setIsCTAHidden(false)
                                  }}
                                  placeholder={''}
                                />
                              </div>
                            </div>
                          )}
                          {/* common area no */}
                          {commonAreaBox && (
                            <div className='d-flex align-items-center'>
                              <h3 className='m-0 head-text me-3' style={{width: '120px'}}>
                                Common Area No.
                              </h3>
                              <div className='xyz'>
                                <Paper component='form' style={searchContainerStyle}>
                                  <InputBase
                                    placeholder='Enter number'
                                    style={inputBaseStyle}
                                    inputProps={{'aria-label': 'Common area'}}
                                    value={propertyCommonAreaNo}
                                    onChange={(e: any) => {
                                      let newVal:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                      setPropertyCommonAreaNo(newVal)
                                       setIsCTAHidden(false)
                                  }}
                                  />
                                </Paper>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-6 d-flex flex-column align-items-end'>
                          {/* size */}
                          {(apartmentBox ||
                            pentHouseBox ||
                            otherBox ||
                            townhouseBox ||
                            villaBox) && (
                            <div className='d-flex align-items-center'>
                              <img src={bedroomsIcon} height={20} width={20} className='me-3' />
                              <h3 className='m-0 head-text me-3' style={{width: '80px'}}>
                                Bedrooms
                              </h3>

                              <Select
                                styles={customStyles}
                                isSearchable={true}
                                defaultValue={''}
                                options={bedroomsOptions}
                                value={propertyBedroom}
                                name='bedrooms'
                                onChange={(e: any) => {
                                  setPropertyBedroom(e)
                                  setIsCTAHidden(false)
                                }}
                                placeholder={'Bedrooms'}
                              />
                            </div>
                          )}
                          {(apartmentBox ||
                            pentHouseBox ||
                            otherBox ||
                            townhouseBox ||
                            villaBox) && (
                            <div className='d-flex align-items-center mt-7'>
                              <img src={bathroomsIcon} height={20} width={20} className='me-3' />
                              <h3 className='m-0 head-text me-3' style={{width: '80px'}}>
                                Bathrooms
                              </h3>

                              <Select
                                styles={customStyles}
                                isSearchable={true}
                                defaultValue={''}
                                value={propertyBathrooms}
                                options={bathroomOptions}
                                name='bathrooms'
                                onChange={(e: any) => {
                                  setPropertyBathroom(e)
                                  setIsCTAHidden(false)
                                }}
                                placeholder={'Bathrooms'}
                              />
                            </div>
                          )}
                          {commonAreaBox && (
                            <div className=''>
                              <div className='d-flex align-items-center '>
                                <img src={commonAreaIcon} height={20} width={20} className='me-3' />
                                <h3 className='m-0 head-text' style={{width: '60px'}}>
                                  Type
                                </h3>

                                <Select
                                  styles={customStyles}
                                  isSearchable={true}
                                  options={commonAreaOptions}
                                  defaultValue={''}
                                  value={propertyType}
                                  name='Select'
                                  onChange={(e: any) => {
                                    setPropertyType(e)
                                    setIsCTAHidden(false)
                                  }}
                                  placeholder={'Select'}
                                />
                              </div>

                              {propertyType?.value === 'Other' && (
                                <div
                                  className='xyz mt-4'
                                  style={{width: '66%', marginLeft: 'auto'}}
                                >
                                  <Paper component='form' style={searchContainerStyle}>
                                    <InputBase
                                      placeholder='Common Area Name'
                                      style={inputBaseStyle}
                                      inputProps={{'aria-label': 'Common area name'}}
                                      value={propertyCommonAreaName}
                                      onChange={(e: any) => {
                                        const captilizeWord = capitalizeFirstLetter(e.target.value)
                                        setPropertyCommonAreaName(captilizeWord)
                                        setIsCTAHidden(false)
                                       }
                                      }
                                    />
                                  </Paper>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Property Deed & Owner */}
                {(apartmentBox ||
                  pentHouseBox ||
                  otherBox ||
                  townhouseBox ||
                  villaBox) && (
                  <div ref={sectionRef}>
                    <div className='row' style={{marginTop: '50px'}}>
                      <h2 className='m-0 head-text mb-1 text-center'>
                        <b>Owner</b>
                      </h2>
                      <h4 className='m-0 text-f7f7f7 text-center'>Select the owner/s of this property</h4>
                    </div>
                    <div className='row' style={{marginTop: '30px'}}>
                      <div className='d-flex align-items-center justify-content-center' style={{columnGap: '50px'}}>
                        <div className='d-flex align-items-center tst'>
                          <input
                            type='checkbox'
                            className='me-3 cursor-pointer form-check-input'
                            name='ownerType'
                            id='singleOwner'
                            value='singleOwner'
                            checked={ownerType === 'singleOwner'}
                            onChange={(e: any) => {
                              if (ownerType === 'singleOwner') {
                                setOwnerType(''); // Deselect if clicked again
                              } else {
                                setOwnerType('singleOwner');
                                // reset deed and owner dropdown
                                resetOwnerOther()
                                setIsCTAHidden(false);
                              }
                            }}
                          />
                          <h4 className='m-0'>
                            <label htmlFor='singleOwner' className='head-text fs-4'>
                              Single Owner
                            </label>
                          </h4>
                        </div>
                        <div className='d-flex align-items-center tst'>
                          <input
                            type='checkbox'
                            className='me-3 cursor-pointer form-check-input'
                            name='ownerType'
                            id='jointOwner'
                            value='jointOwner'
                            checked={ownerType === 'jointOwner'}
                            onChange={(e: any) => {
                              if (ownerType === 'jointOwner') {
                                setOwnerType(''); // Deselect if clicked again
                              } else {
                                setOwnerType('jointOwner');
                                // reset deed and owner dropdown
                                resetOwnerOther()
                                setIsCTAHidden(false);
                              }
                            }}
                          />
                          <h4 className='m-0'>
                            <label htmlFor='jointOwner' className='head-text fs-4'>
                              Joint Owner
                            </label>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className='row' style={{marginTop: '50px'}}>
                      <div className='ms-auto m-0 p-0' style={{width: '85%'}}>
                        {(ownerType === 'singleOwner' || ownerType === 'jointOwner') && (
                          <div className='d-flex align-items-center '>
                            <img src={titleDeedIcon} height={20} width={20} className='me-3' />
                            <h3 className='m-0 head-text' style={{width: '80px'}}>
                              Title Deed
                            </h3>
                            {file === null ? (
                              <>
                                <label
                                  title='Add New File'
                                  htmlFor='uploadDeedFile'
                                  className='btn btn-sm fw-bold upload-btn me-10 btn-fit-content ps-2 pe-5'
                                >
                                  <img src={uploadArrowBlue} height={18} width={18} className='me-4' />
                                  Upload{' '}
                                </label>
                                <input
                                  type='file'
                                  hidden
                                  id='uploadDeedFile'
                                  className='form-control form-control-solid'
                                  placeholder='upload'
                                  name='deedFile'
                                  onChange={handleFileChange}
                                  accept='.pdf,.doc,.docx,.png,.jpg,.jpeg'
                                />{' '}
                              </>
                            ) : (
                              <>
                                <div className='d-flex align-items-center'>
                                  <img
                                    src={
                                      file?.name?.split('.')[1] === 'png'
                                        ? pngIcon
                                        : file?.name?.split('.')[1] === 'jpg'
                                        ? jpgIcon
                                        : file?.name?.split('.')[1] === 'pdf'
                                        ? pdfIcon
                                        : file?.name?.split('.')[1] === 'jpeg'
                                        ? jpegIcon
                                        : file?.name?.split('.')[1] === 'docx'
                                        ? docIcon
                                        : file?.name?.split('.')[1] === 'doc'
                                        ? docIcon
                                        : pdfIcon
                                    }
                                    height={30}
                                    width={30}
                                    className='me-3 cursor-pointer'
                                    id={`titleDeedFile1`}
                                    onClick={() =>
                                      handleImageClickOfJobsAttachments(
                                        `titleDeedFile1`,
                                        `${Bucket}${file?.src}`,
                                        [],
                                        `titleDeedFile1`
                                      )
                                    }
                                  />
                                  {getFilename(file?.name, false)?.length > 30
                                    ? `${file?.name?.slice(0, 30)}...${getFilename(file?.name, true)}`
                                    : file?.name}
                                  <img
                                    src={redCross}
                                    height={13}
                                    width={13}
                                    className='cursor-pointer ms-3 mt-1'
                                    onClick={() => {
                                      setFile(null)
                                      setIsCTAHidden(false)
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {ownerType === 'singleOwner' && (
                          <div className='d-flex align-items-center mt-7'>
                            <img src={ownerFieldIcon} height={20} width={20} className='me-3' />
                            <h3 className='m-0 head-text' style={{width: '80px'}}>
                              Owner
                            </h3>
                            {/* part 1 */}
                            <div className='test property me-4 crm mt-2 tst'>
                              <div className='property-test d-none-multi-checbox me-4 '>
                                <div
                                  className={`multi-select-container  jobs-property crmDropdown owner ${
                                    selectedOwner?.length === 0 ? 'no-value' : 'has-value '
                                  }`}
                                >
                                  <MultiSelect
                                    options={ownerOptions}
                                    value={selectedOwner}
                                    onChange={(e: any) => {
                                      let updateSelectedOwner = [...selectedOwner]
                                      let newVal: any = e
                                      if (e?.length > 1) newVal = e.slice(1)
                                      updateSelectedOwner = newVal
                                      setSelectedOwner(updateSelectedOwner)
                                      setIsCTAHidden(false)
                                    }}
                                    labelledBy='Select Owner'
                                    // valueRenderer={CustomValueRenderer}
                                    hasSelectAll={false}
                                    closeOnChangedValue
                                  />
                                </div>
                              </div>
                            </div>
                            {/* 2nd part */}
                            <h4 className='m-0 head-text fs-4 me-7'>Ownership</h4>
                            <h4 className='m-0 head-text fs-4 ms-7'>100%</h4>
                          </div>
                        )}
                        {ownerType === 'jointOwner' && owners?.length > 0 && (
                          <>
                            {owners?.map((owner: any, index: any) => (
                              <div className='d-flex align-items-center mt-7' key={`owner-${index}`}>
                                <img src={ownerFieldIcon} height={22} width={22} className='me-3' />
                                <h3 className='m-0 head-text' style={{width: '80px'}}>
                                  Owner
                                </h3>

                                <div className='test property me-4 crm mt-2 tst'>
                                  <div className='property-test d-none-multi-checbox me-4 '>
                                    <div
                                      className={`multi-select-container jobs-property crmDropdown owner ${
                                        owner?.selectedOwner?.length === 0 ? 'no-value' : 'has-value '
                                      }`}
                                    >
                                      <MultiSelect
                                        options={getAvailableOwnerOptions(owner.id)}
                                        value={owner.selectedOwner}
                                        // ClearSelectedIcon={
                                        //   <img src={removeIcon} alt='Clear' style={{width: '16px', height: '16px', cursor: 'pointer'}} />
                                        // }
                                        valueRenderer={CustomValueRenderer}
                                        onChange={(e: any) => handleOwnerChange(owner.id, e)}
                                        labelledBy='Select Owner'
                                        hasSelectAll={false}
                                        closeOnChangedValue
                                      />
                                    </div>
                                  </div>
                                </div>

                                <h4 className='m-0 head-text fs-4 me-7'>Ownership</h4>
                                <input
                                  ref={(el) => (percentageInputRefs.current[index] = el)}
                                  type='text'
                                  value={owner.percentage ? `${owner.percentage}%` : ''}
                                  onChange={(e) => handlePercentageChange(owner.id, e.target.value, index)}
                                  className='form-control me-3'
                                  style={{width: '80px', borderRadius: '0.275rem'}}
                                  maxLength={7} // limit input to 3 digits
                                  placeholder='%'
                                />
                                {index >= 2 && (
                                  <img onClick={() => deleteOwner(index)} src={removeIcon} height={14} width={14} className='ms-2 cursor-pointer' />
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      {percentEMsg && ownerType === 'jointOwner' && (
                        <h4 style={{color: 'var(--kt-danger)'}} className='m-0 fs-4 text-center py-7'>
                          Total Ownership % should be equal to 100%.
                        </h4>
                      )}
                       {percentMsg && ownerType === 'jointOwner' && (
                        <h4 style={{color: 'var(--kt-danger)'}} className='m-0 fs-4 text-center py-7'>
                          In joint ownership, each owner must have a share. 100% ownership cannot be assigned to a single owner.
                        </h4>
                      )}
                      {ownerType === 'jointOwner' && owners?.length > 0 && (
                        <div className='row' style={{marginTop: '30px'}}>
                          <div className='m-0 p-0' style={{width: '100%'}}>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold px-2 green-hollow-btn mx-auto'
                              style={{width: '130px'}}
                              onClick={addOwner}
                            >
                              <img
                                src={addIcon}
                                height={18}
                                width={18}
                                style={{
                                  marginRight: '7px',
                                }}
                              />
                              Add Owner
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* property images */}
                {(apartmentBox ||
                  pentHouseBox ||
                  otherBox ||
                  commonAreaBox ||
                  townhouseBox ||
                  villaBox) && (
                  <div className='row' style={{marginTop: '150px'}}>
                    <div className='col-12 d-flex flex-column align-items-center '>
                      <h2 className='m-0 head-text mb-3'>
                        <b>Property Images</b>
                      </h2>
                      <h4 className='m-0 head-text'>Upload images of your property</h4>
                    </div>
                    <div className='col-12' style={{marginTop: '30px'}}>
                      <div className='row'>
                        <div className='col-9 mx-auto'>
                          {/* <div style={{ border: '0.1rem solid #bec3cb', borderRadius: '4px', padding: '40px' }}>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <img src={dragDropIcon} height={30} width={30} className='me-3' />
                                                    <h3 className='m-0' style={{ color: '#8A8AA2' }}>
                                                        Drag & drop your property images  or
                                                    </h3>
                                                    <div className='ms-3'>
                                                        <label
                                                            title='Add New File'
                                                            htmlFor='uploadNewTenant'
                                                            className='btn btn-sm fw-bold upload-solid-btn ms-auto me-10 btn-fit-content ps-2 pe-5'
                                                        >
                                                            Browser Files{' '}
                                                        </label>
                                                        <input
                                                            type='file'
                                                            hidden
                                                            id='uploadNewTenant'
                                                            className='form-control form-control-solid'
                                                            placeholder='upload'
                                                            name='newTenant'
                                                            onChange={(e: any) => {
                                                                handleAttachmentChange(e)
                                                            }}
                                                            multiple
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                          <div className='d-flex justify-content-center my-4 '>
                            <form
                              id='form-file-upload'
                              onDragEnter={handleDrag}
                              onSubmit={(e) => e.preventDefault()}
                              style={{height: '90px'}}
                            >
                              <input
                                type='file'
                                multiple={true}
                                onChange={handleAttachmentChange}
                                id='mediaAttach'
                                style={{
                                  height: '90px',
                                  opacity: '-0.9',
                                  position: 'relative',
                                  zIndex: '1',
                                }}
                              />

                              <label
                                id='label-file-upload-attach'
                                htmlFor='mediaAttach'
                                style={{
                                  fontSize: '16px',
                                  color: 'white',
                                  transform: 'translate(0px, -90px)',
                                }}
                                className={
                                  dragActive
                                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                }
                              >
                                <div
                                  style={{
                                    height: '100px',
                                    width: '450px',
                                    border: '2px solid #bec3cb',
                                    backgroundColor: '#ffffff',
                                  }}
                                  className='d-flex align-items-center justify-content-center'
                                >
                                  <div className='fs-6 d-flex justify-content-center align-items-center py-2'>
                                    <img
                                      src={dragDropIcon}
                                      height={30}
                                      width={30}
                                      className='me-3'
                                    />
                                    <h3 className='m-0' style={{color: '#8A8AA2'}}>
                                      Drag & drop your property images or
                                    </h3>
                                  </div>
                                  <div
                                    className=' fs-6 d-flex justify-content-center align-items-center my-3'
                                    style={{minWidth: '100px'}}
                                  >
                                    <div
                                      className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                      style={{
                                        fontSize: '1rem',
                                        paddingLeft: '15px',
                                        borderRadius: '7px',
                                        backgroundColor: '#35475e',
                                        boxShadow: '0px 0px 20px 0px #516176',
                                      }}
                                    >
                                      Browse Files
                                    </div>
                                  </div>
                                </div>
                              </label>

                              {dragActive && (
                                <div
                                  id='drag-file-element'
                                  onDragEnter={handleDrag}
                                  onDragLeave={handleDrag}
                                  onDragOver={handleDrag}
                                  onDrop={handleDrop}
                                ></div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 px-5  ps-0 my-7'>
                      <div className='d-flex '>
                        {attachments?.length > 0 && (
                          <div
                            className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                            style={{maxHeight: '350px'}}
                          >
                            {attachments?.map((file: any, ind: any) => {
                              return (
                                <>
                                  <div className='imgs-set'>
                                    <div className='d-flex mw-350px me-3'>
                                      <img
                                        src={Bucket + file?.src}
                                        width='100'
                                        height='100'
                                        className='main_img mb-5 cursor-pointer'
                                        id={`propertyAttachment${ind}`}
                                        onClick={() =>
                                          handleImageClickOfJobsAttachments(
                                            `propertyAttachment${ind}`,
                                            `${Bucket}${file?.src}`,
                                            attachments,
                                            `propertyAttachment`
                                          )
                                        }

                                      />
                                      <img
                                        src={trashImg}
                                        height={15}
                                        width={15}
                                        className='cursor-pointer ms-2 mt-1'
                                        onClick={() => {
                                          const values = [...attachments]
                                          values.splice(ind, 1)
                                          setAttachments(values)
                                          setIsCTAHidden(false)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    {isEdit && (
                      <div className='col-10 mx-auto ' style={{marginTop: '120px'}}>
                        <button
                          disabled={isBtnDisabled}
                          type='button'
                          className='btn btn-sm fw-bold delete-btn w-100 d-flex justify-content-center '
                          onClick={() => {
                            checkBeforeDelete()
                            }}
                        >
                          Delete Property
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default EditProperty

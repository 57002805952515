import {DatePicker, TimePicker} from 'antd'
import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'

import {Col, Row, OverlayTrigger, Tooltip} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaPopUp from './MediaPopUp'
import noData from '../../../img/NoData1.svg'
import leftArrow from '../../../img/arrowleft.svg'
import editDate from '../../../img/edit.svg'
import {useDispatch, useSelector} from 'react-redux'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'

import {setCreateNewService} from '../../../redux/counterSlice'
import Datechange from './Datechange'

import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material'
import './style.scss'
import {useForm} from 'react-hook-form'
import {Controller} from 'react-hook-form'
import vendorList from './VendorList'
import plus from '../../../img/plus.svg'
import saveAllImg from '../../../img/save-dark-green.png'

import submitAllImg from '../../../img/Submit-dark-gray.png'

import saveImg from '../../../img/Save-White.png'
import submitImg from '../../../img/Submit-White.png'

import deleteImg from '../../../img/delete-White.png'

import extGray from '../../../img/External-Request-Icon-(Gray).svg'

import {AiOutlineQuestionCircle, AiOutlineUser} from 'react-icons/ai'
import {TeamOutlined} from '@ant-design/icons'
import extImg from '../../../img/External-Request-Icon-green.png'
import {AiFillDelete} from 'react-icons/ai'


// interface CreateServiceProps {
//   index?: number
//   updateParent: (newValue: number) => void
//   updateSubmitAll: (newValue: number) => void
// }

interface FormData {
  subservice: string
  internalteam: string
  comment: string
  subNewServiceList: string
  internalcomment: string
}

const HandleTenantExternal = () => {
//   const {index, updateParent, updateSubmitAll} = props
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const {state} = useLocation()
  // const {status} = state

  const {flg}: any = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({})
  const [requestData, setRequestData] = useState<any>()
  const [mainService, setMainService] = useState<any>()
  const [subService, setSubService] = useState<any>('')
  const [requestTab, setRequestTab] = useState<any>([1])
  const [activeTab, setActiveTab] = useState<any>(1)
  const [time, setTime] = useState<any>()
  const [staffData, setStaffData] = useState<any>('')
  const [extStaffData, setExtStaffData] = useState<any>('')

  const [servicedata, setServicedata] = useState<any>()
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [propertyListValidate, setPropertyListValidate] = useState<boolean>(false)
  const [isRequested, setIsRequested] = useState<boolean>(false)

  const currenttab: any = localStorage.getItem('currentTab')
  const [date, setDate] = useState<any>()
  const totalTab: any = localStorage.getItem('totalTab')
  const [requestUrgency, setrequestUrgency]: any = useState(0)
  const userData: any = localStorage.getItem('userData')
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [page, setPage] = React.useState<any>(1)
  const [propertyList, setPropertyList] = React.useState<any>([])
  const [jobId, setJobId] = useState<any>()
  const [mediaPop, setMediaPop] = useState<any>()
  const [modalData, setModalData] = useState<any>()

  const [saveButton, setSaveButton] = useState<any>('Save')
  const [saveStatuss, setSaveStatus] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()
  const [isSave, setIsSave] = useState<any>(0)

  const [intCommentVal, setIntCommentVal] = useState<any>('')
  const [extCommentVal, setExtCommentVal] = useState<any>('')
  const [intExtCommentVal, setIntExtCommentVal] = useState<any>('')

  const [intAssigneVal, setIntAssigneeVal] = useState<any>('')
  const [intExtAssigneVal, setIntExtAssigneeVal] = useState<any>('')

  const [isMainService, setIsMainService] = useState<boolean>(false)
  const [isSubService, setIsSubService] = useState<boolean>(false)
  const [intAssignee, setIntAssignee] = useState<boolean>(false)
  const [intComment, setIntComment] = useState<boolean>(false)
  const [extAssignee, setExtAssignee] = useState<boolean>(false)
  const [extComment, setExtComment] = useState<boolean>(false)
  const [intExtAssignee, setIntExtAssignee] = useState<boolean>(false)
  const [intExtComment, setIntExtComment] = useState<boolean>(false)

  const [devplmnt, setdevplmnt] = useState<any>(0)
  const [totalUnits, setTotalUnits] =  useState<any>(0)
  const [totalJobs, setTotalJobs] = useState<any>(0)


  const serviceId = window.location.pathname?.split('/')[4]

  const [pError, setPError] = useState<any>('')
  const [sdError, setSdError] = useState<any>('')
  const [spError, setSpError] = useState<any>('')
  const [spIntError, setSpIntError] = useState<any>('')
  const [intError, setIntError] = useState<any>('')

  const tentReq = localStorage.getItem('tenantRequestId')

  const myRef: any = useRef()

  let reqUrg: any = ''
  let xyz: any = 'checked'

  // const [isJobSchedule, setIsJobSchedule] = useState<boolean>(false)

  var isJobSchedule: boolean = false

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const [schedule, setSchedule] = useState<any>([
    {preferScheduleDate: '', preferScheduleTime: ''},
    {preferScheduleDate: '', preferScheduleTime: ''},
  ])

  // const requestType = localStorage.getItem('requestType')
  const [requestType, setRequestType] = useState<number>(1)

  const [vendorList, setVendorList] = useState<any>()

  const options = [
    {label: 'Internal', value: '2'},
    {label: 'External', value: '1'},
  ]

  const data = JSON.parse(userData)

  let requestServiceId: any = localStorage.getItem('activeTab')
  let requestorType: any = localStorage.getItem('requestorType')
  const dispatch = useDispatch()
  const createNewService = useSelector((state: any) => state.counterReducer.createNewService)

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

 
  React.useEffect(() => {
    getServiceJob()
    // serviceRequestData()
    getRequestDataById()

  }, [])

  
  const getTotalMinutes = (totalMinutes: any) => {
    // Calculate the hours and minutes
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    // Create a moment object with the hours and minutes
    const momentTime = moment().hour(hours).minute(minutes)

    // Format the moment object into a string in the hour:minute format
    const formattedTime = momentTime.format('HH:mm')

    return formattedTime
  }
  const getRequestDataById = async () => {
    console.log(requestServiceId)
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    console.log(temp)

    await ApiGet(`corporate/service_request/${serviceId}`)
      .then((res) => {
        if (res?.data?.data[0]?.requestType === 0){
          setRequestData(res?.data?.data[0])
          if(state !== null){
          if(state.status !== 2){
            setrequestUrgency(state.status)
            reqUrg = state.status
           }
           else{
            setrequestUrgency(res?.data?.data[0]?.requestUrgency)
            reqUrg = res?.data?.data[0]?.requestUrgency

           }
          }

        }
        if (res?.data?.data[0]?.requestType === 2) {
          // res?.data?.data[0]?.schedule.forEach((element: any) => {
          //   Object.assign(element, {
          //     time: getTotalMinutes(element?.preferScheduleTime),
          //     date: new Date(element?.preferScheduleDate),
          //   })
          // })
          setRequestData(res?.data?.data[0])
          setSchedule(res?.data?.data[0]?.schedule)
          setMainService(res?.data?.data[0]?.main_service[0])
          setSubService(res?.data?.data[0]?.sub_service[0])
          setStaffData(res?.data?.data[0]?.staff[0])
          setExtStaffData(res?.data?.data[0]?.staff[0])
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          setDate(res?.data?.data[0]?.schedule)
          setTime(res?.data?.data[0]?.schedule)
          // localStorage.setItem('requestType', res?.data?.data[0]?.requestType)
          setRequestType(res?.data?.data[0]?.requestType)
          console.log('RequestTypeee ' + requestType)
          // setSaveStatus(res?.data?.data[0]?.saveStatus)
          setSaveStatus(localStorage.getItem('isSave' + activeTab))
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          reqUrg = res?.data?.data[0]?.requestUrgency

          if(state !== null){
            if(state.status !== 2){
              setrequestUrgency(state.status)
              reqUrg = state.status
             }
             else{
              setrequestUrgency(res?.data?.data[0]?.requestUrgency)
              reqUrg = res?.data?.data[0]?.requestUrgency
  
             }
            }
          

          var mainServ = res?.data?.data[0]?.main_service[0]
          var subServ = res?.data?.data[0]?.sub_service[0]
          var staff = res?.data?.data[0]?.staff[0]
          var intC

          console.log('mainServ ' + mainServ)
          console.log('subServ ' + subServ)
          console.log('staff ' + staff)
          console.log('intComment ' + intCommentVal)

          var temp = localStorage.getItem('serviceRequestId' + requestServiceId)
          if (temp !== null) {
            var x4 = localStorage.getItem('internalComment' + temp)
            if (x4 !== null) {
              setIntCommentVal(x4)
              intC = x4
            } else {
              setIntCommentVal('')
              intC = ''
            }
          }
          var temp1 = localStorage.getItem('fromSubmitAll')
          if (temp1 !== null) {
            if (mainServ === undefined) setIsMainService(true)
            else setIsMainService(false)
            if (subServ === undefined) setIsSubService(true)
            else setIsSubService(false)

            if (staff === undefined) setIntAssignee(true)
            else setIntAssignee(false)

            if (intC === '') setIntComment(true)
            else setIntComment(false)

            let isProp = localStorage.getItem('isPropertySelected_' + temp)
            if (isProp === null) {
              setPError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setPError('')
              }, 15000)
            } else {
              var isSchedule: any
              const body = {
                page: 1,
                limit: 10,
                serviceRequestId: temp,
              }
              ApiPost(`corporate/service_job/get`, body)
                .then((res) => {
                  if (res.data.data.service_job_data !== '') {
                    let totalProp = res?.data?.data?.service_job_data

                    for (let i = 0; i < totalProp.length; i++) {
                      if (
                        totalProp[i]?.schedule[0]?.date === null ||
                        totalProp[i]?.schedule[0]?.startTime === 0 ||
                        totalProp[i]?.schedule[0]?.endTime === 0
                      ) {
                        console.log(totalProp[i]?.schedule[0]?.date)
                        console.log(totalProp[i]?.schedule[0]?.startTime)
                        console.log(totalProp[i]?.schedule[0]?.endTime)

                        isSchedule = 2
                        i = totalProp.lengt
                      }
                    }
                    if (isSchedule === 2) {
                      setPError('1px solid red')
                      // myRef.current.scrollIntoView({behavior: 'smooth'})
                      setTimeout(() => {
                        setPError('')
                      }, 15000)
                    }
                  }
                })
                .catch()
            }

            // localStorage.removeItem('fromSubmitAll')
          }
        }

        if (res?.data?.data[0]?.requestType === 1) {
          // res?.data?.data[0]?.schedule.forEach((element: any) => {
          //   Object.assign(element, {
          //     time: getTotalMinutes(element?.preferScheduleTime),
          //     date: new Date(element?.preferScheduleDate),
          //   })
          // })

          setRequestData(res?.data?.data[0])
          setSchedule(res?.data?.data[0]?.schedule)

          setMainService(res?.data?.data[0]?.main_service[0])
          setSubService(res?.data?.data[0]?.sub_service[0])
          setStaffData(res?.data?.data[0]?.staff[0])
          setExtStaffData(res?.data?.data[0]?.staff[0])
          if(res?.data?.data[0]?.staff[0]){
          setIntExtAssigneeVal(res?.data?.data[0]?.staff[0]?.firstName + ' '+res?.data?.data[0]?.staff[0]?.lastName)
          }
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          setDate(res?.data?.data[0]?.schedule)
          setTime(res?.data?.data[0]?.schedule)
          setVendorList(res?.data?.data[0]?.vendor[0])
          // localStorage.setItem('requestType', res?.data?.data[0]?.requestType)
          setRequestType(res?.data?.data[0]?.requestType)
          setSaveStatus(res?.data?.data[0]?.saveStatus)
          setrequestUrgency(res?.data?.data[0]?.requestUrgency)
          reqUrg = res?.data?.data[0]?.requestUrgency

          if(state !== null){
            if(state.status !== 2){
              setrequestUrgency(state.status)
              reqUrg = state.status
             }
             else{
              setrequestUrgency(res?.data?.data[0]?.requestUrgency)
              reqUrg = res?.data?.data[0]?.requestUrgency
  
             }
            }
          var mainServ = res?.data?.data[0]?.main_service[0]
          var subServ = res?.data?.data[0]?.sub_service[0]
          var staff = res?.data?.data[0]?.staff[0]
          var extC, intExtC
          var vendor = res?.data?.data[0]?.vendor[0]
          var extStaff = res?.data?.data[0]?.staff[0]

          console.log('mainServ ' + mainServ)
          console.log('subServ ' + subServ)
          console.log('staff ' + staff)
          console.log('intComment ' + intCommentVal)

          var temp = localStorage.getItem('serviceRequestId' + requestServiceId)
          if (temp !== null) {
            var x3 = localStorage.getItem('externalComment' + temp)
            if (x3 !== null) {
              setExtCommentVal(x3)
              extC = x3
            } else {
              setExtCommentVal('')
              extC = ''
            }

            var x5 = localStorage.getItem('intExtComment' + temp)
            if (x5 !== null) {
              setIntExtCommentVal(x5)
              intExtC = x5
            } else {
              intExtC = ''
            }
          }
          var temp1 = localStorage.getItem('fromSubmitAll')
          if (temp1 !== null) {
            if (mainServ === undefined) setIsMainService(true)
            else setIsMainService(false)
            if (subServ === undefined) setIsSubService(true)
            else setIsSubService(false)

            if (vendor === undefined) setExtAssignee(true)
            else setExtAssignee(false)

            if (extC === '') setExtComment(true)
            else setExtComment(false)

            if (extStaff === undefined) setIntExtAssignee(true)
            else setIntExtAssignee(false)

            if (intExtC === '') setIntExtComment(true)
            else setIntExtComment(false)

            let isProp = localStorage.getItem('isPropertySelected_' + temp)
            if (isProp === null) {
              setPError('1px solid red')
              // myRef.current.scrollIntoView({behavior: 'smooth'})
              setTimeout(() => {
                setPError('')
              }, 15000)
            } else {
              var isSchedule: any
              const body = {
                page: 1,
                limit: 10,
                serviceRequestId: temp,
              }
              ApiPost(`corporate/service_job/get`, body)
                .then((res) => {
                  if (res.data.data.service_job_data !== '') {
                    let totalProp = res?.data?.data?.service_job_data

                    for (let i = 0; i < totalProp.length; i++) {
                      if (
                        totalProp[i]?.schedule[0]?.date === null ||
                        totalProp[i]?.schedule[0]?.startTime === 0 ||
                        totalProp[i]?.schedule[0]?.endTime === 0
                      ) {
                        console.log(totalProp[i]?.schedule[0]?.date)
                        console.log(totalProp[i]?.schedule[0]?.startTime)
                        console.log(totalProp[i]?.schedule[0]?.endTime)

                        isSchedule = 2
                        i = totalProp.lengt
                      }
                    }
                    if (isSchedule === 2) {
                      setPError('1px solid red')
                      // myRef.current.scrollIntoView({behavior: 'smooth'})
                      setTimeout(() => {
                        setPError('')
                      }, 15000)
                    }
                  }
                })
                .catch()
            }

            // localStorage.removeItem('fromSubmitAll')
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceJob = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    console.log(temp)
    const body = {
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
        setPropertyList(res?.data?.data?.service_development_list)
        setdevplmnt(res?.data?.data?.total_building+res?.data?.data?.total_community)
        setTotalUnits(res?.data?.data?.total_units)
        setTotalJobs(res?.data?.data?.service_development_list?.length)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  
  React.useEffect(() => {
    
    var temp = localStorage.getItem('serviceRequestId'+serviceId)
    if (temp !== null) {
      console.log("tempppppp")
      console.log(temp)
      var x1 = localStorage.getItem('internalExternalAssigneeName'+temp)
      if (x1 !== null) {
        console.log("x111111111111")
        console.log(x1)
        setIntExtAssigneeVal(x1)}

      var x2 = localStorage.getItem('internalAssigneeName' + temp)
      if (x2 !== null) setIntAssigneeVal(x2)

      var x3 = localStorage.getItem('externalComment' + temp)
      if (x3 !== null) setExtCommentVal(x3)

      var x4 = localStorage.getItem('internalComment' + temp)
      if (x4 !== null) setIntCommentVal(x4)

      var x5 = localStorage.getItem('intExtComment' + temp)
      if (x5 !== null) setIntExtCommentVal(x5)
    }

  }, [activeTab])

  const addComment = async (e: any) => {
    setRequestData({...requestData, comment: e})
    // const body = {
    //   id: requestServiceId,
    //   comment: e,
    //   requestType: 2,
    // }
    // await ApiPut(`corporate/service_request`, body)
    //   .then((res) => {
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  }
  // const addDate = async (e: any) => {
  //   const {name, value} = e.target
  //   setDate({...date, preferScheduleDate: value})
  //   const body = {
  //     id: requestServiceId,
  //     schedule: [
  //       {
  //         preferScheduleDate: value,
  //         preferScheduleTime: date?.preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }
  // const handleChangeTime = async (time: any, timeString: string) => {
  //   let hour = timeString.split(':')[0]
  //   let minute = timeString.split(':')[1]
  //   let replaceZero = minute.replace(/^0+/, '')
  //   let preferScheduleTime = parseInt(hour) * 60 + parseInt(replaceZero)
  //   setDate({...date, preferScheduleTime: preferScheduleTime})
  //   const body = {
  //     id: requestServiceId,
  //     requestType: 2,
  //     schedule: [
  //       {
  //         preferScheduleDate: date?.preferScheduleDate,
  //         preferScheduleTime: preferScheduleTime,
  //       },
  //     ],
  //   }

  //   await ApiPut(`corporate/service_request`, body)
  //     .then((res) => {})
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  //  Saving Request as Draft
  const saveStatus = async () => {
    console.log(propertyList.length)

    // if (propertyList.length !== 0) {
    var vendorID, staffID
    // console.log("Vendor " +vendorList)
    // console.log("Staff " +staffData)

    if (requestType === 1) {
      staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    var temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    if (requestServiceId) {
      const body = {
        serviceRequests: [
          {
            _id: temp,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 0,
            comment: requestData?.comment,
          },
        ],
        isSave: true,
        isDelete: false,
      }

      const newValToSubmit = {
        _id: temp,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        vendorId: vendorID,
        staffId: staffID,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: requestType,
        saveStatus: 1,
        comment: requestData?.comment,
      }

      const newValToSave = {
        _id: temp,
        mainServiceId: mainService?._id,
        subServiceId: subService?._id,
        vendorId: vendorID,
        staffId: staffID,
        requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
        requestType: requestType,
        saveStatus: 0,
        comment: requestData?.comment,
      }

      const newValToSaveFirst = [
        {
          _id: temp,
          mainServiceId: mainService?._id,
          subServiceId: subService?._id,
          vendorId: vendorID,
          staffId: staffID,
          requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
          requestType: requestType,
          saveStatus: 0,
          comment: requestData?.comment,
        },
      ]

      const newValToSubmitFirst = [
        {
          _id: temp,
          mainServiceId: mainService?._id,
          subServiceId: subService?._id,
          vendorId: vendorID,
          staffId: staffID,
          requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
          requestType: requestType,
          saveStatus: 1,
          comment: requestData?.comment,
        },
      ]

      await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          // console.log('\nres.data.data 279', res.data.data)

          localStorage.removeItem('serviceRequest')
          localStorage.setItem(activeTab, res?.data?.data?._id)
          SuccessToast(res?.data?.message)
          // localStorage.removeItem('requestType')
          localStorage.setItem('isSave', '1')
          // setSaveButton('Saved as Draft')
          // navigate('/request-management/service')

          var test = localStorage.getItem('isSave' + requestServiceId)
          if (test === undefined || test === null) {
            var x = localStorage.getItem('submitAll')
            if (x !== null) {
              var y = JSON.parse(x)
              // console.log(y)
              // console.log(y.length)
              y[y.length] = newValToSubmit
              // console.log(y)

              localStorage.setItem('submitAll', JSON.stringify(y))
            } else localStorage.setItem('submitAll', JSON.stringify(newValToSubmitFirst))
          }

          // save All Data
          var test1 = localStorage.getItem('isSave' + requestServiceId)
          if (test1 === undefined || test1 === null) {
            var x = localStorage.getItem('saveAll')
            if (x !== null) {
              var y = JSON.parse(x)
              // console.log(y)
              // console.log(y.length)
              y[y.length] = newValToSave
              // console.log(y)
              localStorage.setItem('saveAll', JSON.stringify(y))
            } else localStorage.setItem('saveAll', JSON.stringify(newValToSaveFirst))
          }

          localStorage.setItem('isSave' + requestServiceId, '1')
          // setIsRequested(true)
          setIsSave(0)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: formData.standard === '1' ? 1 : 0,
        requestType: 2,
        preferScheduleDate: date?.preferScheduleDate,
        preferScheduleTime: parseInt(time),
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: flg,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // } else {
    //   ErrorToast('Please Select some Property First!!!')
    // }
  }

  //  deleteRequest
  const deleteStatus = async () => {
    let vendorID, staffID
    // console.log("Vendor " +vendorList)
    // console.log("Staff " +staffData)

    if (requestType === 1) {
      staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    let temp = localStorage.getItem('serviceRequestId' + serviceId)

    if (requestServiceId) {
      const body = {
        serviceRequests: [
          {
            _id: serviceId,
            mainServiceId: mainService?._id,
            subServiceId: subService?._id,
            vendorId: vendorID,
            staffId: staffID,
            requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
            requestType: requestType,
            saveStatus: 0,
            comment: requestData?.comment,
          },
        ],
        isSave: false,
        isDelete: true,
      }

      await ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          // console.log('\nres.data.data 279', res.data.data)

          localStorage.removeItem('serviceRequest')
          SuccessToast(res?.data?.message)
          // localStorage.removeItem('requestType')
          navigate(`/request-management/service/create-request-service/${tentReq}`)
        

          
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      const body = {
        mainServiceId: mainService?.name,
        subServiceId: subService?.name,
        requestUrgency: formData.standard === '1' ? 1 : 0,
        requestType: 2,
        preferScheduleDate: date?.preferScheduleDate,
        preferScheduleTime: parseInt(time),
        staffId: requestData?._id,
        comment: formData?.comment,
        requestorType: flg,
        service_job: [
          {
            media: ['123', '234'],
            comment: 'Test',
            communityId: null,
            clusterId: null,
            buildingId: '638ca32b3f3c7b26bf2bd922',
            floorId: '638ca32b3f3c7b26bf2bd992',
            unitGroupId: null,
            unitId: '639058f42d31a79780548df2',
          },
        ],
      }
      await ApiPost(`corporate/service_request`, body)
        .then((res) => {
          navigate(`/request-management/service`)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  // submitting a single request
  const submitRequest = async () => {
    // setIsJobSchedule(false)
    // console.log(requestData)
    // console.log('mainService ' + mainService)
    // console.log('subService ' + subService)
    // console.log('staff Data' + staffData)
    // console.log('comment ' + intCommentVal + '   ' + intCommentVal.length)

    let temp = localStorage.getItem('serviceRequestId' +serviceId)
    let vendorID, staffID

    if (requestType === 1) {
      if (staffData) staffID = staffData._id
      else staffID = null
      if (vendorList) vendorID = vendorList._id
      else vendorID = null
    } else {
      vendorID = null
      if (staffData) staffID = staffData._id
      else staffID = null
    }

    // Validations for external request type
    if (requestType === 1) {
      if (
        mainService !== undefined &&
        subService !== undefined &&
        vendorList !== undefined &&
        extStaffData !== undefined 
      ) {
        if (propertyList.length !== 0) {
          var totalProp = propertyList.length
          // console.log('prop length: ' + totalProp)

          for (let i = 0; i < totalProp; i++) {
            if (
              propertyList[i]?.schedule[0]?.date === null ||
              propertyList[i]?.schedule[0]?.startTime === null ||
              propertyList[i]?.schedule[0]?.endTime === null
            ) {
              // console.log(propertyList[i]?.schedule[0]?.date)
              // console.log(propertyList[i]?.schedule[0]?.startTime)
              // console.log(propertyList[i]?.schedule[0]?.endTime)
              isJobSchedule = true
              i = totalProp
            }
          }

          if (isJobSchedule) {
            ErrorToast('Some Job Schedules Missing!!!')
            setPError('1px solid red')
            myRef.current.scrollIntoView({behavior: 'smooth'})
            setTimeout(() => {
              setPError('')
            }, 8000)
          } else {
            if (requestServiceId) {
              const body = {
                // serviceRequests:
                //  [
                //   {
                id: temp,
                // mainServiceId: mainService?._id,
                // subServiceId: subService?._id,
                // vendorId: vendorID,
                // staffId: staffID,
                // requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                // requestType: requestType,
                saveStatus: 1,
                // vendorComment: extCommentVal,
                // comment: intExtCommentVal,
                // }
                // ],
                // isSave: true,
                // isDelete: false,
              }

              await ApiPut(`corporate/service_request`, body)
                .then((res) => {
                  console.log('\nres.data.data 343', res.data.data)

                  localStorage.removeItem('serviceRequest')
                  SuccessToast(res?.data?.message)
                  // navigate('/request-management/service')
                  localStorage.removeItem('requestType')
                  // navigate(`/request-management/service/create-request-service/${tentReq}`)
                  navigate('/request-management/service')
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              const body = {
                mainServiceId: mainService?.name,
                subServiceId: subService?.name,
                requestUrgency: formData.standard === '1' ? 1 : 0,
                requestType: 2,
                preferScheduleDate: date?.preferScheduleDate,
                preferScheduleTime: parseInt(time),
                staffId: requestData?._id,
                comment: formData?.comment,
                service_job: [
                  {
                    media: ['123', '234'],
                    comment: 'Test',
                    communityId: null,
                    clusterId: null,
                    buildingId: '638ca32b3f3c7b26bf2bd922',
                    floorId: '638ca32b3f3c7b26bf2bd992',
                    unitGroupId: null,
                    unitId: '639058f42d31a79780548df2',
                  },
                ],
              }
              await ApiPost(`corporate/service_request`, body)
                .then((res) => {})
                .catch((e) => {
                  console.log(e)
                })
            }
          }

          // navigate(
          //   `/request-management/service/create-request-service/approve-request/${temp}`
          // )
        } else {
          ErrorToast('Please Select some Property First!!!')
          setPError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setPError('')
          }, 8000)
        }
      } else {
        if (mainService === undefined || mainService === '') setIsMainService(true)
        else setIsMainService(false)

        if (subService === undefined || subService === '') setIsSubService(true)
        else setIsSubService(false)

        if (vendorList === undefined) setExtAssignee(true)
        else setExtAssignee(false)

        if (extStaffData === undefined || extStaffData === '') setIntExtAssignee(true)
        else setIntExtAssignee(false)

        //  Service Details Card
        if(mainService === undefined || subService === undefined ){
          setSdError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSdError('')
          }, 6000)
        }
  
        //  ServiceProvider Card
        if(vendorList === undefined 
          // || extCommentVal === '' 
          ){
          setSpError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSpError('')
          }, 6000)
        }
  
        //  Internal Card
        if(extStaffData === undefined || extStaffData === ''
          // || intExtCommentVal === '' 
          ){
          setSpIntError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setSpIntError('')
          }, 6000)
        }

      }
    }

    // Validations for internal request type
    if (requestType === 2) {
      if (
        mainService !== undefined &&
        subService !== undefined &&
        staffData !== undefined 
      ) {
        if (propertyList.length !== 0) {
          var totalProp = propertyList.length
          // console.log('prop length: ' + totalProp)

          for (let i = 0; i < totalProp; i++) {
            if (
              propertyList[i]?.schedule[0]?.date === null ||
              propertyList[i]?.schedule[0]?.startTime === null ||
              propertyList[i]?.schedule[0]?.endTime === null
            ) {
              // console.log(propertyList[i]?.schedule[0]?.date)
              // console.log(propertyList[i]?.schedule[0]?.startTime)
              // console.log(propertyList[i]?.schedule[0]?.endTime)
              // setIsJobSchedule(true)
              isJobSchedule = true
              // console.log(isJobSchedule)
              i = totalProp
            }
          }
          // console.log(isJobSchedule)

          if (isJobSchedule === true) {
            ErrorToast('Some Job Schedules Missing!!!')
            myRef.current.scrollIntoView({behavior: 'smooth'})
            setPError('1px solid red')
            setTimeout(() => {
              setPError('')
            }, 8000)
          } else {
            if (requestServiceId) {
              const body = {
                // serviceRequests:
                //  [
                //   {
                id: temp,
                // mainServiceId: mainService?._id,
                // subServiceId: subService?._id,
                // vendorId: vendorID,
                // staffId: staffID,
                // requestUrgency: requestUrgency?.requestUrgency === '1' ? 1 : 0,
                // requestType: requestType,
                saveStatus: 1,
                // comment: intCommentVal,
                // vendotComment: null,
                //   }
                // ]
                // isSave: true,
                // isDelete: false,
              }

              await ApiPut(`corporate/service_request`, body)
                .then((res) => {
                  console.log('\nres.data.data 343', res.data.data)
                  SuccessToast(res?.data?.message)
                  navigate('/request-management/service')
                
                })
                .catch((e) => {
                  console.log(e)
                })
            } else {
              const body = {
                mainServiceId: mainService?.name,
                subServiceId: subService?.name,
                requestUrgency: formData.standard === '1' ? 1 : 0,
                requestType: 2,
                preferScheduleDate: date?.preferScheduleDate,
                preferScheduleTime: parseInt(time),
                staffId: requestData?._id,
                comment: formData?.comment,
                service_job: [
                  {
                    media: ['123', '234'],
                    comment: 'Test',
                    communityId: null,
                    clusterId: null,
                    buildingId: '638ca32b3f3c7b26bf2bd922',
                    floorId: '638ca32b3f3c7b26bf2bd992',
                    unitGroupId: null,
                    unitId: '639058f42d31a79780548df2',
                  },
                ],
              }
              await ApiPost(`corporate/service_request`, body)
                .then((res) => {})
                .catch((e) => {
                  console.log(e)
                })
            }
          }
          // navigate(
          //   `/request-management/service/create-request-service/approve-request/${temp}`
          // )
        } else {
          ErrorToast('Please Select some Property First!!!')
          setPError('1px solid red')
          // myRef.current.scrollIntoView({behavior: 'smooth'})
          setTimeout(() => {
            setPError('')
          }, 8000)
        }
      } else {
        if (mainService === undefined || mainService === '') setIsMainService(true)
        else setIsMainService(false)

        if (subService === undefined || subService === '') setIsSubService(true)
        else setIsSubService(false)

        if (staffData === undefined || staffData === '') setIntAssignee(true)
        else setIntAssignee(false)

      }
    }
  }


  const serviceRequestData = async () => {
    let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    console.log(temp)
    const body = {
      page: page,
      limit: 5,
      serviceRequestId: temp,
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        // res?.data?.data?.service_job_data.forEach((element: any) => {
        //   Object.assign(element, {
        //     time: getTotalMinutes(element?.schedule?.preferScheduleTime),
        //     date: new Date(element?.schedule?.preferScheduleDate),
        //   })
        // })
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const onSubmit = () => {}

  useEffect(() => {
    // if (flg == 1) {
    //   if (requestServiceId && requestType === 2) {
    //     getServiceJob()
    //     serviceRequestData()
    //   }
    // }
    // if (!localStorage.getItem(currenttab)) {
    //   localStorage.setItem('currentTab', '1')
    //   // localStorage.setItem('totalTab', '1')
    //   setRequestTab([1])
    //   setActiveTab(1)
    // }
  }, [requestType, page])

  const openModal = async (id: any) => {
    let temp = id._id

    console.log('temp: ' + temp)

    console.log('id', id)
    Object.assign(id, {jobId: id._id})
    setUnitNo(id.unit[0].unitNo)

    setJobId(id._id)
    console.log('Job ID: ' + jobId)
    setMediaPopup(true)
    await ApiGet(`corporate/service_job/${id?.jobId}`)
      .then((res) => {
        console.log('\nres.data.data', res.data.data[0])
        setMediaPop(res.data.data[0])
      })
      .catch((err) => ErrorToast(err.message))
  }

  const moveVendor = (flg: any) => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', flg)
  }
  const setScheduleEvent = (e: any, int: any, flg: any) => {
    if (flg === 'preferScheduleTime') {
      var hoursInMinutes = e.hours() * 60 // Convert hours to minutes
      const minutes = e.minutes() // Get the remaining minutes
      const totalMinutes = hoursInMinutes + minutes
      schedule[int][flg] = totalMinutes
    } else {
      schedule[int][flg] = e
    }
    setSchedule(schedule)
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal : any = temp.toLocaleTimeString()
    // console.log(newVal);

   let h1 : any = (newVal.slice(0,2)) * 60
   let newValHours = parseInt(h1)
    //  console.log( newValHours)

   let newValMins: any =  parseInt((newVal.slice(3,5)))
   //  console.log(newValMins)
    
    var num : number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    return fhour + ':' + fminutes
  }

  const updateRequestType = (num: number) => {
    setRequestType(num)
    console.log(requestType)
    localStorage.setItem('requestType', '' + num + '')
    saveComment()
  }

  // Save Comment
  const saveComment = async () => {
    console.log('requestTypeeeee ' + requestType)
    let rT = localStorage.getItem('requestType')
    let temp2: any
    if (rT !== null) {
      temp2 = rT
    }
    let temp = localStorage.getItem('serviceRequestId' + serviceId)
    let intC: any
    let extC: any
    if (requestType === 1) {
      intC = intExtCommentVal
      extC = extCommentVal
    } else {
      intC = intCommentVal
      extC = null
    }

    console.log(temp)
    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + temp)
      .then((res) => {
        console.log('In property list service by ID ')
        console.log(res?.data?.data)

        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: serviceId,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: reqUrg,
              requestType: 1,
              saveStatus: 0,
              comment: intC,
              vendorComment: extC,
            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            localStorage.setItem('savedOnce' + temp, '1')
            localStorage.setItem('reqType_' + temp, '' + requestType + '')

            localStorage.setItem('intComment_' + temp, intC)
            localStorage.setItem('extComment_' + temp, extC)
          })
          .catch()

          let tnt = localStorage.getItem('tenantRequestId')
          let tntType = localStorage.getItem('requestType')
      
          let idToSend : any
          let typeToSend : any
          if(tntType !== null){
            typeToSend = JSON.parse(tntType)
            
          }
      if(tnt !== null)
      idToSend = tnt

    const data ={
      id : idToSend,
      serviceRequestType : 1,
    }

    ApiPut(`corporate/tenant_request/`,data)
    .then((res) => {})
    .catch()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Save Request
  const saveRequest = async () => {
    let rT = localStorage.getItem('requestType')
    let temp2: any
    if (rT !== null) {
      temp2 = rT
    }
    let temp = localStorage.getItem('serviceRequestId' + serviceId)
    let intC: any
    let extC: any
    if (requestType === 1) {
      intC = intExtCommentVal
      extC = extCommentVal
    } else {
      intC = intCommentVal
      extC = null
    }

    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + temp)
      .then((res) => {
       
        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: serviceId,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: requestUrgency,
              requestType: 1,
              saveStatus: 0,
              comment: intC,
              vendorComment: extC,
            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
          .then((res) => {
            SuccessToast("Your request has been saved!!!")
            localStorage.setItem('savedOnce' + temp, '1')
            localStorage.setItem('reqType_' + temp, '' + requestType + '')

            localStorage.setItem('intComment_' + temp, intC)
            localStorage.setItem('extComment_' + temp, extC)
          })
          .catch()

          let tnt = localStorage.getItem('tenantRequestId')
          let tntType = localStorage.getItem('requestType')
      
          let idToSend : any
          let typeToSend : any
          if(tntType !== null){
            typeToSend = JSON.parse(tntType)
            
          }
      if(tnt !== null)
      idToSend = tnt

    const data ={
      id : idToSend,
      serviceRequestType : 1,
    }

    ApiPut(`corporate/tenant_request/`,data)
    .then((res) => {})
    .catch()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const updateRequestUrgency = (val: any) => {
    // console.log(event.target.value)
    setrequestUrgency(val)
    reqUrg = val
    console.log(reqUrg)
    saveComment()
  }

  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div
          id=''
          className='app-container container-xxl pt-0 mt-2'
        >
             <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 px-5'>
                <div className='d-flex align-items-center gap-5' >
                <span
                    className='rounded-circle'
                    style={{cursor: 'pointer'}}
                    onClick={()=> navigate(`/request-management/service/create-request-service/${tentReq}`)}
                >
                    <img src={leftArrow} style={{stroke: 'red'}} />
                </span>
                <h2 className='page-heading green_color m-0'>New Service Request</h2>
                </div>
            </div>
          {/* <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 px-5'>
            <div className='d-flex align-items-center gap-5'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  navigate(-1)
                }}
              >
                <img src={leftArrow} style={{stroke: 'red'}} />
              </span>
              <h1 className='page-heading green_color m-0'>Internal Service Request</h1>
            </div>
          </div> */}

          {/* {requestTab.map((item: any) => { */}
            {/* return (
              <> */}
                {/* <div className={`${activeTab === item ? 'd-show' : 'd-none'}`}> */}
                  <>
                    <div className='grid-out p-5 container-xxl'>
                     
                      <div
                        className='d-flex tops p-0 mt-0'
                        style={{
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                         
                        }}
                      >
                        <div className='d-flex'>
                          {/* Save Button Animated*/}
                          {/* <button
                              className={
                                isSave === 0
                                  ? 'btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  : 'btn btn-sm fw-bold btn-primary btn-green mx-3 abc'
                              }
                              type='submit'
                              onClick={handleSubmit(saveStatus)}
                            >
                              Save
                            </button> */}
                          {/* Save Button Without Animation */}
                          {/* <a
                            className='btn btn-sm fw-bold  mx-2 px-2'
                            onClick={saveAllRequestsUpdated}
                            style={{transform: 'translate(60px,-59px)', border: '1px solid #007a59', color:'#007a59'}}
                          >
                          </a> */}

                          <button
                            className={
                              isSave === 0
                                ? 'btn btn-sm fw-bold btn-primary btn-green me-3  ps-0 pe-4'
                                : 'btn btn-sm fw-bold btn-primary btn-green me-3 ps-0 pe-4'
                            }
                            type='submit'
                            onClick={() => saveRequest()}
                            style={{width: '95px', fontSize: '13px'}}
                          >
                            <img
                              src={saveImg}
                              style={{height: '20px', width: '20px', marginRight: '13px'}}
                            />{' '}
                            {'  '}
                            Save
                          </button>
                          {/* } */}
                          <a
                            className='btn btn-sm fw-bold  mx-3 ps-1 pe-4'
                            onClick={() => deleteStatus()}
                            style={{
                              width: '95px',
                              backgroundColor: '#D54645',
                              color: 'white',
                              fontSize: '13px',
                            }}
                          >
                            <img
                              src={deleteImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Delete
                          </a>
                          {/* onClick={() => removeTab(item)} */}
                        </div>
                        <div className='' style={{textAlign: 'end'}}>
                          <button
                            className='btn btn-sm fw-bold  ms-3 ps-2 pe-4'
                            onClick={() => submitRequest()}
                            style={{
                              border: '1px solid #48474D',
                              color: 'white',
                              backgroundColor: '#48474D',
                              width: '95px',
                            }}
                          >
                            <img
                              src={submitImg}
                              style={{height: '20px', width: '20px', marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Submit
                          </button>
                        </div>
                      </div>

                      <div
                        className='card side px-5 py-3 '  style={{border: sdError}}
                       
                      >
                        <h1 className='top green_color'>Service Details</h1>
                        {/* Request ID */}
                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='form-label'>
                            Request ID
                          </label>
                          <div className='mx-1 value_black'>{requestData?.id}</div>
                        </div>

                        {/* <h6 className='text-muted border-outline mx-0 my-2'>
                          Request ID <div className='mx-1 value_black'>{requestData?.id}</div>
                        </h6> */}

                        {/* Requestor */}
                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='form-label'>
                            Requestor
                          </label>
                          <div className='mx-1 value_black'>
                            {' '}
                            {data?.firstName} {data?.lastName}
                          </div>
                        </div>
                        {/* <h6 className='text-muted mx-0 my-2'>
                          Requestor{' '}
                          <div className='mx-1 value_black'>
                            {data?.firstName} {data?.lastName}
                          </div>
                        </h6> */}

                        {/* Main Service */}
                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Main Service
                          </label>
                          <input
                            type='text'
                            className={
                              isMainService === true
                                ? 'form-control form-control-solid  py-2  border-danger'
                                : 'form-control form-control-solid  py-2 '
                            }
                            placeholder=''
                            name='mainService'
                            value={mainService?.name}
                            // onChange={(e) => {
                            //   setIsMainService(false)
                            //   // if(mainService?.name !== undefined){
                            //   // mainService.name = e.target.value
                            //   // }
                            // }}
                            disabled
                          />
                        </div>

                        {/* <h6
                          className='text-muted border-outline mx-0 '
                          style={{alignItems: 'center'}}
                        >
                          <span className='required'>Main Service</span>
                          <input
                            type='text'
                            className={
                              isMainService === true
                                ? 'form-control form-control-solid py-2 border-danger'
                                : 'form-control form-control-solid py-2'
                            }
                            name='name'
                            value={mainService?.name}
                            onChange={(e) => {
                              setIsMainService(false)
                              // if(mainService?.name !== undefined){
                              // mainService.name = e.target.value
                              // }
                            }}
                            disabled
                          />
                        </h6> */}

                        {/* Sub Service */}
                        <div className=' text-muted grid_big_col py-2'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Sub Service
                          </label>
                          <div className='d-flex'>
                            <input
                              placeholder=''
                              name='subService'
                              type='text'
                              id='internal-teamm'
                              className={
                                isSubService
                                  ? 'form-control form-control-solid border-danger  py-2 '
                                  : 'form-control form-control-solid  py-2'
                              }
                              value={subService?.name}
                            />
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                              onClick={() =>
                                navigate(
                                  '/request-management/service/handle-tenant-request/'+requestData?._id+'/select-list'
                                )
                              }
                            >
                              Select
                            </a>
                          </div>
                        </div>

                        {/* <h6
                          className='text-muted border-outline mx-0 '
                          style={{alignItems: 'center'}}
                        >
                          <span className='required'>Sub Service</span>
                          <div className='d-flex  m-0 p-0'>
                            <input
                              type='text'
                              id='internal-teamm'
                              className={
                                isSubService
                                  ? 'form-control form-control-solid py-2 border-danger'
                                  : 'form-control form-control-solid py-2'
                              }
                              value={subService?.name}
                            />
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              onClick={() =>
                                navigate(
                                  '/request-management/service/create-request-service/select-list'
                                )
                              }
                            >
                              Select
                            </a>
                          </div>
                          <p></p>
                          {subService === '' && errors.subservice && (
                            <p style={{color: 'var(--kt-danger)'}} className='justify-content-end'>
                              This field is required
                            </p>
                          )}
                        </h6> */}

                        {/* Request Urgency */}

                        <div className=' text-muted grid_big_col py-2 justify-content-center align-items-center'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label pt-1'
                          >
                            Request Urgency
                          </label>

                          <div className='d-flex'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                              {requestUrgency === 0 ? (
                                <div
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    backgroundColor: '#007a59',
                                    borderRadius: '50%',
                                  }}
                                >
                                  <div
                                    style={{
                                      height: '10px',
                                      width: '10px',
                                      backgroundColor: '#eff2f5',
                                      borderRadius: '50%',
                                      margin: '5px',
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    height: '21px',
                                    width: '21px',
                                    backgroundColor: '#eff2f5',
                                    borderRadius: '50%',
                                  }}
                                  onClick={() => updateRequestUrgency(0)}
                                ></div>
                              )}

                              {/* <input
                              className='form-check-input '
                              type='radio'
                              value={requestUrgency === 0 ? 0 : 0}
                              id='requestUrgency'
                              name='requestUrgency'
                              // onChange={(e) =>
                              //   setrequestUrgency({
                              //     ...requestUrgency,
                              //     requestUrgency: e.target.value,
                              //   })
                              // }
                              checked
                              onChange={(e)=> updateRequestUrgency(e)
                              }
                             
                            /> */}

                              <label
                                className='form-check-label value_black'
                                htmlFor='requestUrgency'
                                style={{marginRight: '25px'}}
                              >
                                Standard
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              {/* <input
                              className='form-check-input'
                              id="Internally"
                              type='radio'
                              value={requestUrgency === 1 ? 1 : 1}
                              name='requestUrgency'
                              // onChange={(e) =>
                              //   setrequestUrgency({
                              //     ...requestUrgency,
                              //     requestUrgency: e.target.value,
                              //   })
                              // }
                              onChange={(e)=> updateRequestUrgency(e)
                              }
                            /> */}

                              {requestUrgency === 1 ? (
                                <div
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    backgroundColor: '#007a59',
                                    borderRadius: '50%',
                                  }}
                                >
                                  <div
                                    style={{
                                      height: '10px',
                                      width: '10px',
                                      backgroundColor: '#eff2f5',
                                      borderRadius: '50%',
                                      margin: '5px',
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    height: '21px',
                                    width: '21px',
                                    backgroundColor: '#eff2f5',
                                    borderRadius: '50%',
                                  }}
                                  onClick={() => updateRequestUrgency(1)}
                                ></div>
                              )}
                              <label
                                className='form-check-label value_black'
                                htmlFor='Internally'
                                style={{marginRight: '6px'}}
                              >
                                Emergency 
                              </label>
                            </div>
                            {/* <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                              <input
                                className='form-check-input '
                                type='radio'
                                value={requestUrgency === 0 ? 0 : 0}
                                id='requestUrgency'
                                name='requestUrgency'
                                onChange={(e) => {
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }}
                                checked
                              />
                              <label
                                className='form-check-label value_black'
                                htmlFor='requestUrgency'
                                style={{marginRight: '6px'}}
                              >
                                Standard
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value={requestUrgency === 1 ? 1 : 1}
                                id='requestStandard'
                                name='requestUrgency'
                                onChange={(e) => {
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }}
                              />
                              <label
                                className='form-check-label value_black'
                                htmlFor=''
                                style={{marginRight: '6px'}}
                              >
                                Emergency
                              </label>
                            </div> */}
                          </div>
                        </div>
                        {/* <h6
                          className='text-muted border-outline mx-0'
                          style={{alignItems: 'center'}}
                        >
                          Request Urgency
                          <div className='d-flex'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                              <input
                                className='form-check-input '
                                type='radio'
                                value={requestUrgency === 0 ? 0 : 0}
                                id='requestUrgency'
                                name='requestUrgency'
                                onChange={(e) =>
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }
                                checked
                              />
                              <label
                                className='form-check-label required value_black'
                                htmlFor='requestUrgency'
                                style={{marginRight: '6px'}}
                              >
                                Standard
                              </label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid form-check-sm'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value={requestUrgency === 1 ? 1 : 1}
                                name='requestUrgency'
                                onChange={(e) =>
                                  setrequestUrgency({
                                    ...requestUrgency,
                                    requestUrgency: e.target.value,
                                  })
                                }
                              />
                              <label
                                className='form-check-label required value_black'
                                htmlFor='Internally'
                                style={{marginRight: '6px'}}
                              >
                                Emergency
                              </label>
                            </div>
                          </div>
                        </h6> */}
                        {requestType === 1 && (
                          <>
                            {/* Internal Team for External Request */}
                            {/* <div className='card grid px-5 py-3 side'  style={{transform: 'translate(0px,-52px)'}}> */}

                            {/* <h6
                              className='text-muted border-outline mx-0'
                              style={{alignItems: 'center'}}
                            >
                              <span className='required'>Assigned To</span>
                              <div className='d-flex'>
                                <input
                                  type='text'
                                  {...register('internalteam', {required: true})}
                                  className={
                                    intExtAssignee
                                      ? 'form-control form-control-solid py-2 border-danger'
                                      : 'form-control form-control-solid py-2'
                                  }
                                  onChange={(e) =>
                                    setExtStaffData({...extStaffData, name: e.target.value})
                                  }
                                  // value={
                                  //   staffData?.firstName
                                  //     ? staffData?.firstName + ' ' + staffData?.lastName
                                  //     : ''
                                  // }
                                  value={intExtAssigneVal}
                                  name='internalteam'
                                  disabled={formData?.subServiceList ? true : false}
                                />
                                <a
                                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                  onClick={() =>
                                    navigate(
                                      '/request-management/service/create-request-service/internal-team'
                                    )
                                  }
                                >
                                  Select
                                </a>
                              </div>
                              <p></p>
                              {staffData === '' && errors.internalteam && (
                                <p
                                  style={{color: 'var(--kt-danger)'}}
                                  className='justify-content-end'
                                >
                                  This field is required
                                </p>
                              )}
                            </h6> */}

                            {/* <div>
                              <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                Comments (For Internal Reference)
                              </h6>
                              <textarea
                                {...register('internalcomment', {required: true})}
                                className={
                                  intExtComment
                                    ? 'form-control form-control-solid py-2 border-danger'
                                    : 'form-control form-control-solid py-2'
                                }
                                rows={3}
                                name='internalcomment'
                                onChange={(e) => {
                                  addComment(e.target.value)
                                  var x = localStorage.getItem(
                                    'serviceRequestId' + requestServiceId
                                  )
                                  localStorage.setItem('intExtComment' + x, e.target.value)
                                  setIntExtCommentVal(e.target.value)
                                  setIntExtComment(false)
                                }}
                               
                                value={intExtCommentVal}
                                placeholder='Type comments here'
                              ></textarea>

                              {errors.internalcomment && (
                                <p
                                  style={{color: 'var(--kt-danger)'}}
                                  className='justify-content-end'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>  */}

                            {/* </div> */}
                          </>
                        )}

                        {/* <div className='m-0'>
                        <h6 className='text-muted'>Preferred Schedules For Service</h6>
                        {requestData?.schedule?.map((scg: any, id: any) => (
                          <div className='payment-receipt-item d-flex align-items-center mb-5'>
                            <label className='text-muted  mr-2 required'>Date</label>
                            <DatePicker
                              className='mx-1 form-control form-control-solid'
                              name='fromDate'
                              defaultValue={moment(scg?.date, 'DD/MM/YYYY')}
                              onChange={(e: any) =>
                                setScheduleEvent(new Date(e), id, 'preferScheduleDate')
                              }
                            />
                            <label className='text-muted mr-2 required'>Time</label>
                            <TimePicker
                              className='form-control form-control-solid'
                              size='large'
                              defaultValue={moment(scg?.time, 'HH:mm')}
                              onChange={(e: any) => setScheduleEvent(e, id, 'preferScheduleTime')}
                            />
                          </div>
                        ))}
                      </div> */}
                      </div>


                      {/* External Team */}
                      {/* {requestType === 1 && ( */}
                        <>
                          <div className='side'>
                            <div className='card px-5 py-3 pb-5' style={{border: spError}}>
                              <h1 className='top green_color'>Service Provider Details</h1>
                              <div className=' text-muted grid_big_col py-2'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Service Provider
                                </label>
                                <div className='d-flex'>
                                  <input
                                    {...register('subNewServiceList', {required: true})}
                                    type='text'
                                    className={
                                      extAssignee
                                        ? 'form-control form-control-solid py-2 border-danger'
                                        : 'form-control form-control-solid py-2'
                                    }
                                    name='subNewServiceList'
                                    onChange={(e) =>
                                      setVendorList({...vendorList, name: e.target.value})
                                    }
                                    value={`${
                                      vendorList
                                        ? `${vendorList?.companyName }`
                                        : ''
                                    }`}
                                  />
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                    onClick={() =>
                                      navigate('/request-management/service/handle-tenant-request/'+serviceId+'/service-provider-list')
                                    }
                                  >
                                    Select
                                  </a>
                                </div>
                              </div>

                              {/* <div className='d-flex align-items-center me-10 mb-3'>
                              <h6
                                className='text-muted m-0 mr-2 min-w-100px mb-2'
                                style={{alignItems: 'center'}}
                              >
                                <span className='required'>Service Provider</span>
                                <div className='d-flex'>
                                  <input
                                    {...register('subNewServiceList', {required: true})}
                                    type='text'
                                    className={
                                      extAssignee
                                        ? 'form-control form-control-solid py-2 border-danger'
                                        : 'form-control form-control-solid py-2'
                                    }
                                    name='subNewServiceList'
                                    onChange={(e) =>
                                      setVendorList({...vendorList, name: e.target.value})
                                    }
                                    value={`${
                                      vendorList
                                        ? `${vendorList?.firstName || ''} ${vendorList?.lastName}`
                                        : ''
                                    }`}
                                  />
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                    onClick={() =>
                                      navigate('/request-management/service/service-provider-list')
                                    }
                                  >
                                    Select
                                  </a>
                                </div>
                                <p></p>
                                {vendorList === '' && errors.subNewServiceList && (
                                  <p
                                    style={{color: 'var(--kt-danger)'}}
                                    className='justify-content-end'
                                  >
                                    Please select Service Provider
                                  </p>
                                )}
                              </h6>
                            </div> */}

                              <div className=' text-muted grid_big_col py-2'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label'
                                >
                                  Request Details (For Selected Vendor) 
                                </label>
                                <textarea
                                  // value={extCommentVal}
                                  value={requestData?.vendorComment}
                                  {...register('comment', {required: true})}
                                  className={
                                    extComment
                                      ? 'form-control form-control-solid py-2 border-danger'
                                      : 'form-control form-control-solid py-2'
                                  }
                                  rows={4}
                                  name='comment'
                                  onChange={(e: any) => {
                                    handleChnage(e)
                                    setExtCommentVal(e.target.value)
                                    setExtComment(false)
                                    var x = localStorage.getItem(
                                      'serviceRequestId' + serviceId
                                    )
                                    localStorage.setItem('externalComment' + x, e.target.value)
                                    setRequestData({...requestData, vendorComment: e.target.value})
                                  }}
                                  onBlur={() => saveComment()}
                                ></textarea>
                              </div>

                              {/* <div className='mb-3'>
                              <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                Request Details (For Selected Vendor)
                              </h6>
                              <textarea
                                value={extCommentVal}
                                {...register('comment', {required: true})}
                                className={
                                  extComment
                                    ? 'form-control form-control-solid py-2 border-danger'
                                    : 'form-control form-control-solid py-2'
                                }
                                rows={3}
                                name='comment'
                                onChange={(e: any) => {
                                  handleChnage(e)
                                  setExtCommentVal(e.target.value)
                                  setExtComment(false)
                                  var x = localStorage.getItem(
                                    'serviceRequestId' + requestServiceId
                                  )
                                  localStorage.setItem('externalComment' + x, e.target.value)
                                }}
                              ></textarea>
                            </div> */}
                            </div>

                            <div className='card px-5 py-3 pb-5 mt-5' style={{border: spIntError}}>
                              <h1 className='top green_color'>Internal Team</h1>

                              {/* Internal Assigned To */}
                              <div className=' text-muted grid_big_col py-2'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Assigned To
                                </label>
                                <div className='d-flex'>
                                  <input
                                    type='text'
                                    {...register('internalteam', {required: true})}
                                    className={
                                      intExtAssignee
                                        ? 'form-control form-control-solid py-2 border-danger'
                                        : 'form-control form-control-solid py-2'
                                    }
                                    onChange={(e) =>
                                      setExtStaffData({...extStaffData, name: e.target.value})
                                    }
                                    value={
                                      staffData?.firstName
                                        ? staffData?.firstName + ' ' + staffData?.lastName
                                        : ''
                                    }
                                    // value={intExtAssigneVal}
                                    name='internalteam'
                                    disabled={formData?.subServiceList ? true : false}
                                  />
                                  <a
                                    className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                                    onClick={() =>
                                      navigate(
                                        '/request-management/service/handle-tenant-request/'+serviceId+'/internal-team'
                                      )
                                    }
                                  >
                                    Select
                                  </a>
                                </div>
                              </div>
                              <div className=' text-muted grid_big_col py-2'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='form-label'
                                >
                                  Comments (For Internal Reference)
                                </label>
                                <textarea
                                  {...register('internalcomment', {required: true})}
                                  className={
                                    intExtComment
                                      ? 'form-control form-control-solid py-2 border-danger'
                                      : 'form-control form-control-solid py-2'
                                  }
                                  rows={3}
                                  name='internalcomment'
                                  onChange={(e) => {
                                    addComment(e.target.value)
                                    var x = localStorage.getItem(
                                      'serviceRequestId' + serviceId
                                    )
                                    localStorage.setItem('intExtComment' + x, e.target.value)
                                    setIntExtCommentVal(e.target.value)
                                    setIntExtComment(false)
                                    setRequestData({...requestData, comment: e.target.value})
                                  }}
                                  /*                                                 onChange={(e)=>addComment(e.target.value) (e) => setRequestData({ ...requestData, 'comment': e.target.value })}
                                   */
                                  value={intExtCommentVal}
                                  placeholder='Type comments here'
                                  onBlur={() => saveComment()}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </>
                    

                      <div
                        className='card p-5 tops container-xxl'
                        style={{border: pError}}
                        ref={myRef}
                      >
                        <div className=' align-item-center pt-2'>
                        <Row className='mb-3'>
                            <Col md={8}>
                              <div className='d-flex gap-15'>
                                <h1 className='m-0 green_color pt-1'> Properties</h1>
                                {/* <div className=''>
                                  {requestorType !== '1' && (
                                    <a
                                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                      onClick={() =>
                                        navigate(
                                          '/request-management/service/create-request-service/select-property'
                                        )
                                      }
                                    >
                                      <img src={plus} /> Select Properties
                                    </a>
                                  )}
                                </div> */}
                              </div>
                            </Col>
                            
                            <Col md={4}>
                              <div
                                className='gap-5  align-items-left p-5 br-5'
                                style={{background: '#19ffbe1c'}}
                              >
                                <Row className='pb-2'>
                                  <Col md={6}>
                                    <h4 className='' style={{color: 'black'}}>
                                      {' '}
                                      <b>Job Summary</b>
                                    </h4>
                                  </Col>
                                  <Col md={6} className='text-end pr-1'>
                                  <h5 className='my-1'>Developments <b className='ms-2'>{devplmnt}</b></h5>
                                    <h5 className='mb-1'>Units <b className='ms-2'>{totalUnits}</b></h5>
                                    <h5 className='mb-1'>Jobs <b className='ms-2'>{totalJobs}</b></h5>
                                  </Col>
                                </Row>
                                {/* <div className='d-flex mt-3'>
                                  <h5 className="me-5"> <b >Development Name</b></h5>
                                  <h5 className="ms-auto">Jobs  <b className='ms-2'>###</b></h5>
                                </div>
                                <div className='d-flex mt-3'>
                                  <h5 className="me-5"> <b >Development Name</b></h5>
                                  <h5 className="ms-auto">Jobs  <b className='ms-2'>###</b></h5>
                                </div>
                                <div className='d-flex mt-3'>
                                  <h5 className="me-5"> <b >Development Name</b></h5>
                                  <h5 className="ms-auto">Jobs  <b className='ms-2'>###</b></h5>
                                </div> */}

                                {/* <div className='d-flex gap-5 align-items-center'>
                             
                              <h5 className='m-0'>Total Units : {servicedata?.total_units || 0}</h5>
                            </div> */}
                              </div>
                            </Col>
                          </Row>


                          {/* <h1 className='m-0 green_color'>
                            Properties
                            {requestorType !== '1' && (
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() =>
                                  navigate(
                                    '/request-management/service/create-request-service/select-property'
                                  )
                                }
                              >
                                Select Properties
                              </a>
                            )}
                          </h1>
                          {propertyListValidate && (
                            <p style={{color: 'var(--kt-danger)'}} className='justify-content-end'>
                              Please select properties
                            </p>
                          )} */}
                        </div>
                        {/* <div className='row mb-5'>
                          <div className='my-7 table-responsive'> */}
                        <table
                          className='table align-middle table-row-dashed fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className='min-w-100px'>Job Id</th>
                              <th className='min-w-100px'>Development</th>
                              <th className='min-w-100px'>Floor/Cluster</th>
                              <th className='min-w-100px'>Unit</th>
                              <th className='min-w-100px'>Unit Type</th>
                              <th className='min-w-100px'>Bedrooms</th>
                              <th className='min-w-200px'>Job Schedule</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            {propertyList.length ? (
                              propertyList?.map((f: any, i: any) => {
                                return (
                                  <tr className='text-center' style={{verticalAlign: 'baseline'}}>
                                    <td>{f?.id ?? '-'}</td>
                                    <td>{f?.communityId ? 'Community' : 'Building'}</td>
                                    <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                                    <td>{f?.unit[0]?.unitNo ?? '-'}</td>
                                    <td>{f?.unit[0]?.unitType ?? '-'}</td>
                                    <td>{f?.unit[0]?.bedrooms ?? '-'}</td>
                                    <td className='align-items-center gap-4 justify-content-center'>
                                      <p
                                        className='d-flex align-items-center gap-4 justify-content-center'
                                        style={{marginBottom: '0em'}}
                                      >
                                        {f?.schedule?.[0]?.date
                                          ? moment(f?.schedule?.[0]?.date).format('DD.MM.YYYY')
                                          : null}{' '}
                                        {f?.schedule?.[0]?.startTime
                                          ? timeConvert(f?.schedule?.[0]?.startTime) + '-'
                                          : null}
                                        {f?.schedule?.[0]?.endTime
                                          ? timeConvert(f?.schedule?.[0]?.endTime)
                                          : null}{' '}
                                        <a
                                          onClick={() => {
                                            setShowCreateAppModal1(true)
                                            setModalData(f)
                                          }}
                                        >
                                          <img src={editDate} alt='' style={{marginTop: '-5px'}} />
                                        </a>
                                      </p>
                                      {(f?.schedule?.[0]?.date === null ||
                                        f?.schedule?.[0]?.startTime === 0 ||
                                        f?.schedule?.[0]?.endTime === 0) && (
                                        <p style={{color: 'rgb(213, 70, 69)'}}>
                                          {' '}
                                          Please select job schedule{' '}
                                        </p>
                                      )}
                                      {f?.schedule?.[0]?.date && (
                                        <>
                                          {/* Same Day */}
                                          {new Date(f?.schedule?.[0]?.date).getFullYear() ===
                                            today.getFullYear() &&
                                          new Date(f?.schedule?.[0]?.date).getMonth() ===
                                            today.getMonth() &&
                                          new Date(f?.schedule?.[0]?.date).getDate() ===
                                            today.getDate() ? (
                                            <>
                                              {currentHours >
                                              parseInt(
                                                timeConvert(f?.schedule?.[0]?.startTime).split(
                                                  ':'
                                                )[0]
                                              ) ? (
                                                <p style={{color: 'rgb(213, 70, 69)'}}>
                                                  {' '}
                                                  Schedule has Expired
                                                </p>
                                              ) : (
                                                <>
                                                  {parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours <=
                                                  6 ? (
                                                    <p style={{color: 'rgb(213, 70, 69)'}}>
                                                    {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                                      Schedule is Approaching
                                                    </p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : 
                                             // next Day
                                          new Date(f?.schedule?.[0]?.date) > today ? (
                                            <>
                                              {new Date(f?.schedule?.[0]?.date).getDate() ===
                                              today.getDate() + 1 ? (
                                                <>
                                                  {24 -
                                                    currentHours +
                                                    parseInt(
                                                      timeConvert(
                                                        f?.schedule?.[0]?.startTime
                                                      ).split(':')[0]
                                                    ) <=
                                                    6 && (
                                                    <p style={{color: 'rgb(213, 70, 69)'}}>
                                                      Schedule is Approaching
                                                    </p>
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <></>
                                            </>
                                          ) : (
                                            <p style={{color: 'rgb(213, 70, 69)'}} >
                                            Schedule has expired
                                          </p>
                                )
                                
                                }
                              </>
                                      )}

                                    </td>
                                    <td>
                                      <button
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none min-w-150px'
                                        onClick={() => openModal(f)}
                                      >
                                        Media & Comments
                                      </button>
                                    </td>
                                    <td>
                                    <AiFillDelete
                                color='#D54645'
                                fontSize={20}
                               
                                style={{cursor: 'pointer'}}
                              />
                                    </td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* </div> */}
                        {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div> */}
                        <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end'>
                          <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                          >
                            <ReactPaginate
                              breakLabel='...'
                              nextLabel='>'
                              pageClassName='paginate_button page-item +'
                              pageLinkClassName='page-link'
                              containerClassName='pagination'
                              activeClassName='active'
                              previousClassName='paginate_button page-item previous disabled'
                              nextClassName='paginate_button page-item next'
                              previousLinkClassName='page-link'
                              nextLinkClassName='page-link'
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageLimit}
                              previousLabel='<'
                            />
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </>
                {/* </div> */}
              {/* </> */}
            {/* ) */}
          {/* })} */}
        </div>
      </div>

      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        propertyList={propertyList}
        handleClose={() => {
          setMediaPopup(false)
        }}
        id={jobId}
        mediaPop={mediaPop}
      />
      <Datechange
        show={showCreateAppModal1}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        serviceRequestData={serviceRequestData}
        modalData={modalData}
      />
    </>
  )
}

export default HandleTenantExternal

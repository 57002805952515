import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import noData from '../../../img/NoData1.svg'
import {setChatScreen, setChatSelectedDataList} from '../../../redux/counterSlice'

function Internal() {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.counterReducer.chatDataSearch)
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])

  const goToNewMessage = async () => {
    dispatch(setChatScreen(3))
    let body: any = {
      tenantIds: [],
      staffIds: selectedDataListStaff,
    }
    if (selectedDataListStaff.length == 1) {
      body['type'] = 0
    } else if (selectedDataListStaff.length > 1) {
      body['type'] = 1
    }
    console.log('\nbody', body)
    dispatch(setChatSelectedDataList(body))
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = data[i]?._id

    if (e.target.checked) {
      if (!selectedDataListStaff.includes(id)) {
        setSelectedDataListStaff((prev: any) => [...prev, id])
      }
    } else {
      setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
    }
  }

  return (
    <>
      <table
        className='table align-middle table-row-dashed fs-6 gy-5'
        id='kt_ecommerce_sales_table'
      >
        <thead>
          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
            <th className=' min-w-30px'>#</th>
            <th className=' min-w-100px'>Name</th>
            <th className=' min-w-100px'>Role</th>
          </tr>
        </thead>
        <tbody className='fw-semibold text-gray-600'>
          {data.length ? (
            data.map((v: any, i: any) => (
              <tr>
                <>
                  <td
                    data-kt-ecommerce-order-filter='order_id'
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    onChange={(e: any) => makeGroup(e, i)}
                  >
                    <input className='form-check-input' type='checkbox' />
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v.firstName} {v.lastName}
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v.role}
                  </td>
                </>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className='text-center'>
                <img src={noData} alt='' width={250} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='d-flex align-items-center justify-content-end'>
        <a
          className='btn btn-sm fw-bold btn-primary btn-green m-0'
          onClick={() => goToNewMessage()}
        >
          Done
        </a>
      </div>
    </>
  )
}

export default Internal

import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import workFlowsImg from '../../../../img/workflows.png'
import invoiceImg from '../../../../img/invoice-blue.png'
import receiptImg from '../../../../img/receipt-blue.png'
import refundImg from '../../../../img/refund-blue.png'
import creditNoteImg from '../../../../img/credit-note-blue.png'
import debitNoteImg from '../../../../img/debit-blue.png'
import leasingImg from '../../../../img/leasing.png'


// Reusable card component
// Reusable card component with imgHeight and imgWidth properly typed
const CommunicationCard = ({ imgSrc, title, onClick, imgHeight, imgWidth }: { 
    imgSrc: string, 
    title: string, 
    onClick: () => void, 
    imgHeight: number, 
    imgWidth: number 
  }) => {
    return (
      <div className="card card-flush gap-5 p-3 cursor-pointer light-gry-dark-shadow" style={{boxShadow: '0 5px 8px rgba(0, 0, 0, 0.1), 0 0px 5px rgba(0, 0, 0, 0.08)'}}
        onClick={onClick}>
        <div className="card-body p-3">
          <div className="d-flex align-items-center">
            <img src={imgSrc} height={imgHeight} width={imgWidth} alt={title} />
            <h4 className={`page-heading m-0 head-text ${title == "Invoices" ? 'ps-13' : 'ps-10'}`}>
              <b>{title}</b>
            </h4>
          </div>
        </div>
      </div>
    )
  }
  

const Communications = () => {
  const navigate = useNavigate()

  // Array of communication types with title and image source
  const communications = [
    { title: 'Invoices', imgSrc: invoiceImg, imgHeight: 35, imgWidth: 26, path: '/settings/communications/invoices' },
    { title: 'Receipts', imgSrc: receiptImg, imgHeight: 35, imgWidth: 35, path: '/settings/communications/receipts' },
    { title: 'Refunds', imgSrc: refundImg, imgHeight: 35, imgWidth: 35, path: '/settings/communications/refunds' },
    { title: 'Credit Notes', imgSrc: creditNoteImg,imgHeight: 35, imgWidth: 35,  path: '/settings/communications/credit notes' },
    { title: 'Debit Notes', imgSrc: debitNoteImg,imgHeight: 35, imgWidth: 35,  path: '/settings/communications/debit notes' },
    { title: 'New Lease Offer', imgSrc: leasingImg, imgHeight: 35, imgWidth: 35, path: '/settings/communications/new lease offer' },
    { title: 'Lease Renewal', imgSrc: leasingImg, imgHeight: 35, imgWidth: 35, path: '/settings/communications/lease renewal' },
    { title: 'Lease Termination', imgSrc: leasingImg, imgHeight: 35, imgWidth: 35, path: '/settings/communications/lease termination' }
  ]

  return (
    <div className="pt-0 mt-0 px-2 ps-1">
      <div className="d-flex align-items-center gap-2 mb-4 gap-lg-3">
        <div className="d-flex align-items-start gap-3">
          <span className="rounded-circle" style={{ cursor: 'pointer' }} onClick={() => navigate('/settings')}>
            <img src={backArrow} height={14} width={14} alt="Back" />
          </span>
          <div>
            <h2 className="page-heading m-0 head-text mb-1">Communications</h2>
            <p className="labl-gry fs-4 mb-0 light-dark-theme-color">
              Customize and set the emails you would like to use to communicate with your tenant
              base for each pre-defined flow:
            </p>
          </div>
        </div>
        <div className="ms-auto">
          <img src={workFlowsImg} height={30} width={30} alt="Workflow" />
        </div>
      </div>

      <div className="card card-flush gap-5 p-5 pt-3 pb-0 mt-8 px-3" style={{ minHeight: '75vh' }}>
        <div className="card-body pt-0 mt-5 py-0 px-3">
          <h3 className="white-dark-theme-color">Financials</h3>
          <Row className="my-5">
            {/* Map over the communications array to render each card */}
            {communications.slice(0, 5).map((comm, idx) => (
              <Col md={3} className="mb-5" key={idx}>
                <CommunicationCard
                  imgSrc={comm.imgSrc}
                  title={comm.title}
                  imgHeight={comm.imgHeight}
                  imgWidth={comm.imgWidth}
                  onClick={() => navigate(comm.path)}
                />
              </Col>
            ))}
          </Row>

          <h3 className="white-dark-theme-color">Leasing</h3>
          <Row className="my-5">
            {communications.slice(5).map((comm, idx) => (
              <Col md={3} className="mb-5" key={idx}>
                <CommunicationCard
                  imgSrc={comm.imgSrc}
                  title={comm.title}
                  onClick={() => navigate(comm.path)}
                  imgHeight={comm.imgHeight}
                  imgWidth={comm.imgWidth}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Communications

import React, { useEffect, useState, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

interface ButtonProps {
  value?: any;
  onChange?: any;
  autoSaveText?: any;
  refresh?: any;
  placeholder?: any;
  from?: any;
  isReadOnly?: boolean;
  onCursorEnter?: any
}

// Wrap the component with forwardRef
const TextEditor = forwardRef<ReactQuill, ButtonProps>(
  ({ value, onChange, autoSaveText, refresh, placeholder, from, isReadOnly = false, onCursorEnter }, ref) => {
    const [content, setContent] = useState<string>(value ? value : '');

    const [selectedRange, setSelectedRange] = useState(null);

    const handleChange = (text: string) => {
      setContent(text);
      onChange(text);
    };

    const modules = {
      toolbar:
        from == 'annoucements'
          ? {
              container: [
                [{ header: [4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
              ],
            }
          : from == 'email-template'
          ? {
              container: [
                [{ font: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                ['link'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                [{ script: 'sub' }, { script: 'super' }],
              ],
            }
          : {
              container: [
                [{ header: [4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ color: [] }, { background: [] }],
                ['image'],
                [{ align: [] }],
                ['clean'],
              ],
            },
    };

    useEffect(() => {
      setContent(value);
    }, [refresh]);

    const handleSelectionChange = (range:any, source:any, editor:any) => {
      if (range) {
        setSelectedRange(range);

        onCursorEnter(range)
        console.log("Cursor position:", range); // This will give you the cursor position.
      }
    };
  

    return (
      <div>
        <ReactQuill
          theme="snow" // You can change the theme here
          modules={modules}
          value={content}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={autoSaveText}
          readOnly={isReadOnly}
          ref={ref} // Forward the ref here
          onChangeSelection={handleSelectionChange} // Detects cursor position or selection changes
        />
      </div>
    );
  }
);

export default TextEditor;

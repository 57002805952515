import React from 'react'
import {Modal} from 'react-bootstrap'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  setFormData: any
  formData: any
  vendorsList?: any
  vendorList: any
  type: any
  vendorListArray: any
  setVendrorListArray: any
  getVendorDataById: any
}
function AppVendormodal({
  show,
  handleClose,
  setFormData,
  formData,
  vendorsList,
  vendorList,
  type,
  setVendrorListArray,
  vendorListArray,
  getVendorDataById,
}: ButtonProps) {
  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }
  const handleChangeVendorlist = (i: any) => {
    if (vendorListArray.includes(i?._id)) {
      vendorListArray = vendorListArray.filter((item: any) => item !== i?._id)
      setVendrorListArray(vendorListArray)
    } else {
      vendorListArray.push(i?._id)
      setVendrorListArray(vendorListArray)
    }
  }

  const handleAddvendor = async () => {
    const body = {
      id: formData?._id,
      vendorListIds: vendorListArray,
    }
    await ApiPut(`corporate/vendor`, body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getVendorDataById()
      })
      .catch((err) => ErrorToast(err.message))
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-center mb-5'>
          {type === 'manageVendor' || type === 'viewVendor' || type === 'approvalVendor' ? (
            <div
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() => handleAddvendor()}
            >
              Add
            </div>
          ) : (
            <div
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() => handleClose()}
            >
              Add
            </div>
          )}
        </div>
        <div>
          {vendorList?.map((i: any) => {
            return (
              <div className='d-flex align-items-center justify-content-start gap-2 mb-10 gap-lg-3'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='flexCheckDefault'
                    style={{cursor: 'pointer'}}
                    onChange={() => handleChangeVendorlist(i)}
                  />
                </div>
                <h6>{i?.name}</h6>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default AppVendormodal

import React, {useState} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import redCross from '../../../img/remove.png'
import './styles.scss'
import share from '../../../img/email.png'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import addWhite from '../../../img/add-white.png'
import { useParams } from 'react-router-dom'

interface ShareWithRecipientsProps {
  isVisible: boolean
  handleClose: any
}

const ShareWithRecipients: React.FC<ShareWithRecipientsProps> = ({isVisible, handleClose}) => {
const {id, poId} = useParams()
  const [recipientsEmails, setRecipientsEmails] = useState<any>([
    {
      email: '',
    },
  ])

  //
  const handleEmailsAddFields = () => {
    const values = [...recipientsEmails]
    values.push({
      email: '',
    })
    setRecipientsEmails(values)
  }

  //
  const handleEmailRemoveFields = (index: any) => {
    const values = [...recipientsEmails]
    values.splice(index, 1)
    setRecipientsEmails(values)
  }

  //
  const shareWithRecipients = async () => {
    let emailsList: any = []
    let j = 0
    for (let i = 0; i < recipientsEmails?.length; i++) {
      if (recipientsEmails[i]?.email?.length != 0) {
        emailsList[j] = recipientsEmails[i]?.email
        j++
      }
    }

    const body = {
      emailList: emailsList,
      maintenanceServiceRequestId: id, 
      maintenancePurchaseOrderId: poId
    }

    let url: any = 'corporate/maintenance_service_request/purchase_order/sent/other'

    await ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
        setRecipientsEmails([ {
            email: '',
          },])
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={isVisible}
        onHide={() => {
          handleClose()
          setRecipientsEmails([ {
            email: '',
          }])
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-9'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: '300px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                Share with Recipients
              </h2>

              <div className='d-flex ms-auto align-items-center'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn ps-3 status-w-100'
                  onClick={() => {
                    shareWithRecipients()
                  }}
                >
                  <img src={share} height={18} width={18} className='me-4' /> Share
                </button>

                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    setRecipientsEmails([ {
                        email: '',
                      }])
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div style={{maxHeight: '400px', overflow: 'auto'}} className='mt-5'>
              {recipientsEmails.map((rec: any, index: any) => {
                return (
                  <Row className='mx-1 mt-4 ms-0'>
                    <Col lg={6} md={6} className='align-items-center ps-0'>
                      {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                      <input
                        type='text'
                        className='form-control form-control-solid-bg mytest'
                        placeholder='Enter Recepient Email..'
                        name='item_id'
                        value={rec.email}
                        onChange={(e) => {
                          const values = [...recipientsEmails]
                          values[index].email = e.target.value
                          setRecipientsEmails(values)
                        }}
                        style={{
                          border: '0.1rem solid #bec3cb',
                          borderRadius: '2px',
                          padding: '0.4rem 1rem',
                        }}
                      />
                    </Col>
                    <Col lg={3} className='align-items-center mb-0  m-2'>
                      <div className='d-flex align-items-center mt-0'>
                        <img
                          src={redCross}
                          height={18}
                          width={18}
                          className='main_img cursor-pointer '
                          onClick={() => handleEmailRemoveFields(index)}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={3} className='align-items-center text-end'></Col>
                  </Row>
                )
              })}
            </div>

            <div
              className='d-flex align-items-center cursor-pointer m-5 ms-0 mt-7'
              onClick={() => handleEmailsAddFields()}
              style={{marginTop: '30px'}}
            >
              <img src={plusBlue} className='ms-0' height={16} width={16} />
              <p className='head-text ms-3 mb-0'>
                {' '}
                <i>Add another Email</i>
              </p>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default ShareWithRecipients

import React, {useEffect, useState, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ApiGet, ApiPost, Bucket, ApiDelete} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import redCross from '../../../../img/remove.png'
import trashImg from '../../../../img/trash.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import submitImg from '../../../../img/Submit-White.png'
import saveGreenImg from '../../../../img/save-dark-green.png'
import {DatePicker} from 'antd'
import {MultiSelect} from 'react-multi-select-component'
import {Moment} from 'moment'
import './style.scss'
import addGreen from '../../../../img/add.png'
import PaymentItems from './PaymentItems'
import PaymentRecords from './PaymentRecords'
import OtherAttachments from './OtherAttachments'
import PaymentReminders from './PaymentReminders'
import SelectPayorModal from './SelectPayorModal'
import AddInvoice from './AddInvoice'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import axios from 'axios'
import {Controller, useForm} from 'react-hook-form'
import ModalLoader from '../../General/ModalLoader'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import Invoices from './Invoices'
import Input from '../../General/Input'
import {getFileIcon} from '../../../../Utilities/utils'
import {downloadFile} from '../../../../Utilities/utils'

const AddIncomePayment = () => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)
  const [sendError, setSendError] = useState<any>(false)

  const [chargeTenant, setChargeTenant] = useState<any>(null)

  const [deleteInvoiceIds, setDeleteInvoiceIds] = useState<any>([])

  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  setTimeout(() => {
    setOpen(false)
  }, 10000)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [showSelectPayor, setShowSelectPayor] = useState<any>(false)
  const [showInvoiceAdd, setShowInvoiceAdd] = useState<any>(false)

  const [incomeAPIDetails, setIncomeAPIDetails] = useState<any>()

  const [incomeData, setIncomeData] = useState<any>({
    payorData: {},
    dueDate: null,
    paymentMethod: null,
    paymentAccountId: '',
    notes: '',
    development: '',
    invoices: [],
    items: [
      {
        id: '',
        name: '',
        paymentItemListId: '',
        amount: '',
        quantity: '',
        VATAmount: '',
        VATPercentage: '',
        totalAmount: '',
        outstandingAmount: 0,
        amountPaid: 0,
        totalAmountPaid: 0,
        frontendStorage: {
          isList: true,
          latePaymentObject: {},
          isLatePaymentItem: false,
          itemUniqueId: new Date().getTime(),
          paymentItemId: null,
          isLatePaymentItemAdded: false,
        },
        isLatePaymentItem: false,
        latePaymentFees: 0,
        latePaymentSelectionStatus: 0, // "notSelected": 0, "apply": 1, "notApply": 2
        latePaymentObject: {},
        oldLatePaymentCycleList: [],
        nextLatePaymentCycle: null,
        // paymentSubItemStatus:2
      },
    ],
    attachments: [],
    reminders: [],
    paymentRecords: [],
    category: 0,
    categoryDateTime: null,
    latePaymentType: null,
    invoiceData: [
      {
        createdOriginType: 0,
        name: 'INVOICE',
        invoiceNo: '',
        date: '',
        dueDate: null,
        invoiceLogo: '',
        invoiceCompany: {
          trn: '',
          name: '',
          address: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          _id: '',
        },
        note: '',
        paymentSubItems: [],
        paymentMethod: '',
        totalAmount: 0,
        saveStatus: 0,
        totalVAT: 0,
        subTotalAmount: 0,
        paymentAccountId: null,
        paymentId: null,
        status: 0,
        outstandingAmount: 0,
        itemDescriptions: [],
        frontendStorage: {
          showAccountDetails: true,
          showNotes: true,
        },
      },
    ],
    frontendStorage: {lateFeeForAll: {}},
  })

  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([
    {
      label: 'Bank Transfer',
      value: '2',
    },
    {
      label: 'Card',
      value: '0',
    },
    {
      label: 'Cash',
      value: '3',
    },
    {
      label: 'Cheque',
      value: '1',
    },
  ])

  //
  const getStatuss = async (val: any) => {
    if (!val) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = val.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  //
  const handleDateChange = async (date: any) => {
    let temp = await getStatuss(date)

    const values = {...incomeData}

    if (temp == 'Upcoming') {
      values.categories = 0
      values.categoryDateTime = new Date().toISOString()
    } else {
      values.categories = 1
      values.categoryDateTime = new Date().toISOString()
    }

    if (date != null) {
      values.dueDate = date.format('YYYY-MM-DD')
      setValue('dueDate', date.format('YYYY-MM-DD'), {shouldValidate: true})

      setIncomeData(values)
    } else {
      values.dueDate = null
      setValue('dueDate', '', {shouldValidate: true})
      setIncomeData(values)
    }

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }

    setRefreshChild(Date.now())
  }

  //
  const getAccounts = (val: any) => {
    // console.log(incomeData?.paymentAccountId)
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
            IBAN: acc?.IBAN,
            accountHolderName: acc?.accountHolderName,
            accountNumber: acc?.accountNumber,
            bankName: acc?.bankName,
            swiftCode: acc?.swiftCode,
          }

          if (val == acc?._id) setSelectedAccount([values[ind]])
        })

        setAccountOptions(values)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    if (flg == '1') getAccounts('')
  }, [])

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})
  const [isViewOnly, setIsViewOnly] = useState<any>(false)
  const [reminderWithID, setReminderWithID] = useState<any>([])

  const [tenantData, setTenantData] = useState<any>(false)

  // const getPaymentById = async () => {
  //   ApiGet(`corporate/payment/${id}`)
  //     .then((res: any) => {

  //       setChargeTenant(res?.data?.data?.cardChargePayBy)

  //       setValue('paymentName', res?.data?.data?.name)
  //       setValue('dueDate', res?.data?.data?.dueDate)
  //       setValue(
  //         'accountNumber',
  //         res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : ''
  //       )

  //       setValue(
  //         'paymentMethod',
  //         res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
  //       )

  //       if (res?.data?.data?.paymentAccountId) getAccounts(res?.data?.data?.paymentAccountId)
  //       else getAccounts('')

  //       let values: any = {
  //         name: res?.data?.data?.name,
  //         payorData: {},
  //         dueDate: res?.data?.data?.dueDate,
  //         categories: res?.data?.data?.categories,
  //         paymentMethod: res?.data?.data?.paymentMethod,
  //         paymentAccountId: res?.data?.data?.paymentAccountId,
  //         notes: res?.data?.data?.note,
  //         items: res?.data?.data?.payment_sub_item,
  //         attachments: res?.data?.data?.attachments,
  //         reminders: res?.data?.data?.payment_reminder,
  //         latePaymentType: res?.data?.data?.latePaymentType,
  //         frontendStorage: res?.data?.data?.frontendStorage,
  //         payment_invoice: res?.data?.data?.payment_invoice,
  //         payment_receipt: res?.data?.data?.payment_receipt,
  //         invoiceData: [
  //           {
  //             _id: res?.data?.data?.payment_invoice?.[0]?._id
  //               ? res?.data?.data?.payment_invoice?.[0]?._id
  //               : undefined,
  //             name: res?.data?.data?.payment_invoice?.[0]?.name
  //               ? res?.data?.data?.payment_invoice?.[0]?.name
  //               : 'INVOICE',
  //             invoiceNo: res?.data?.data?.payment_invoice?.[0]?.invoiceNo
  //               ? res?.data?.data?.payment_invoice?.[0]?.invoiceNo
  //               : '',
  //             date: res?.data?.data?.payment_invoice?.[0]?.date
  //               ? res?.data?.data?.payment_invoice?.[0]?.date
  //               : '',
  //             dueDate: res?.data?.data?.dueDate
  //               ? res?.data?.data?.dueDate
  //               : res?.data?.data?.payment_invoice?.[0]?.dueDate,
  //             invoiceLogo: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.invoiceLogo
  //               ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.invoiceLogo
  //               : logo,
  //             invoiceCompany: {
  //               trn: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.trn
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.trn
  //                 : invoiceCompany?.trn,
  //               name: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.name
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.name
  //                 : invoiceCompany?.name,
  //               address: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.address
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.address
  //                 : invoiceCompany?.address,
  //               countryCode: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.countryCode
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.countryCode
  //                 : invoiceCompany?.countryCode,
  //               phoneNumber: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.phoneNumber
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.phoneNumber
  //                 : invoiceCompany?.phoneNumber,
  //               email: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.email
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.email
  //                 : invoiceCompany?.email,
  //               _id: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?._id
  //                 ? res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?._id
  //                 : undefined,
  //             },
  //             note: res?.data?.data?.payment_invoice?.[0]?.note
  //               ? res?.data?.data?.payment_invoice?.[0]?.note
  //               : '',
  //             paymentSubItems: res?.data?.data?.payment_sub_item
  //               ? res?.data?.data?.payment_sub_item
  //               : '',
  //             paymentMethod: res?.data?.data?.payment_invoice?.[0]?.paymentMethod
  //               ? res?.data?.data?.payment_invoice?.[0]?.paymentMethod
  //               : res?.data?.data?.paymentMethod,
  //             totalAmount: res?.data?.data?.payment_invoice?.[0]?.totalAmount
  //               ? res?.data?.data?.payment_invoice?.[0]?.totalAmount
  //               : res?.data?.data?.totalAmount,
  //             saveStatus: res?.data?.data?.payment_invoice?.[0]?.saveStatus
  //               ? res?.data?.data?.payment_invoice?.[0]?.saveStatus
  //               : res?.data?.data?.saveStatus,
  //             totalVAT: res?.data?.data?.payment_invoice?.[0]?.totalVAt
  //               ? res?.data?.data?.payment_invoice?.[0]?.totalVAt
  //               : res?.data?.data?.totalVAt,
  //             subTotalAmount: res?.data?.data?.payment_invoice?.[0]?.subTotalAmount
  //               ? res?.data?.data?.payment_invoice?.[0]?.subTotalAmount
  //               : res?.data?.data?.subTotalAmount,
  //             paymentAccountId: res?.data?.data?.payment_invoice?.[0]?.paymentAccountId
  //               ? res?.data?.data?.payment_invoice?.[0]?.paymentAccountId
  //               : res?.data?.data?.paymentAccountId,
  //             paymentId: res?.data?.data?._id,
  //             status: 0,
  //             outstandingAmount: res?.data?.data?.payment_invoice?.[0]?.outstandingAmount,
  //             itemDescriptions: res?.data?.data?.payment_invoice?.[0]?.itemDescriptions,
  //           },
  //         ],
  //       }

  //       values?.items?.map((vl: any, ind: any) => {
  //         values.items[ind].outstandingAmount = vl.totalAmount - vl?.amountPaid
  //       })

  //       let tenancyIdToCompare: any = res?.data?.data?.tenancyId

  //       // console.log(res?.data?.data?.expense_payment_item)
  //       setReminderWithID(res?.data?.data?.payment_reminder)

  //       if (res?.data?.data?.paymentMethod == 1)
  //         setSelectedPaymentMethod([
  //           {
  //             value: '1',
  //             label: 'Cheque',
  //           },
  //         ])
  //       else if (res?.data?.data?.paymentMethod == 2)
  //         setSelectedPaymentMethod([
  //           {
  //             value: '2',
  //             label: 'Bank Transfer',
  //           },
  //         ])
  //       else if (res?.data?.data?.paymentMethod == 3)
  //         setSelectedPaymentMethod([
  //           {
  //             value: '3',
  //             label: 'Cash',
  //           },
  //         ])
  //       else if (res?.data?.data?.paymentMethod == 0)
  //         setSelectedPaymentMethod([
  //           {
  //             value: '0',
  //             label: 'Card',
  //           },
  //         ])

  //       // setIncomeData(values)

  //       if (res?.data?.data?.tenancyId != null) {
  //         const body = {
  //           page: 1,
  //           limit: 10000,
  //           searchTenant: '',
  //           searchDevelopment: '',
  //           searchContractNo: '',
  //           searchUnitNo: '',
  //         }

  //         ApiPost('corporate/tenancy/overview', body)
  //           .then((res: any) => {
  //             res?.data?.data?.tenancy_data?.map((rs: any, ind: any) => {
  //               if (rs?._id == tenancyIdToCompare) {
  //                 values.payorData = rs
  //               }
  //             })
  //           })
  //           .catch((err: any) => {})
  //       }

  //       console.log("Get Payment By ID")
  //       console.log(values)

  //       setIncomeAPIDetails(res?.data?.data)

  //       if(flg == '2' &&  res?.data?.data?.payment_invoice?.length == 0){
  //         const invoiceDetails = await getInvoiceDetails();

  //         let valuesInv = {...values?.invoiceData?.[0]}

  //       valuesInv.invoiceCompany = res?.data?.data?.invoiceCompany
  //       valuesInv.invoiceLogo = res?.data?.data?.invoiceLogo

  //       values.invoiceData[0] = valuesInv

  //       }

  //       setIncomeData(values)

  //       setIsSaveFirst(false)

  //       setRefreshChild(Date.now())

  //       setTimeout(() => {
  //         setIsSkeleton(false)
  //       }, 2000)
  //     })
  //     .catch((err: any) => {
  //       ErrorToast(err?.message)
  //       setIsSkeleton(false)
  //     })
  // }

  //

  const [receiptData, setReceiptData] = useState<any>([])

  const getPaymentById = async () => {
    try {
      const res = await ApiGet(`corporate/payment/${id}`)

      setReceiptData(res?.data?.data?.payment_receipt)

      setChargeTenant(res?.data?.data?.cardChargePayBy)

      setValue('paymentName', res?.data?.data?.name)
      setValue('dueDate', res?.data?.data?.dueDate)
      setValue(
        'accountNumber',
        res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : ''
      )

      setValue(
        'paymentMethod',
        res?.data?.data?.paymentMethod != null ? `${res?.data?.data?.paymentMethod}1` : ''
      )

      if (res?.data?.data?.paymentAccountId) {
        await getAccounts(res?.data?.data?.paymentAccountId)
      } else {
        await getAccounts('')
      }

      let values: any = {
        name: res?.data?.data?.name,
        payorData: {},
        dueDate: res?.data?.data?.dueDate,
        categories: res?.data?.data?.categories,
        paymentMethod: res?.data?.data?.paymentMethod,
        paymentAccountId: res?.data?.data?.paymentAccountId,
        notes: res?.data?.data?.note,
        items: res?.data?.data?.payment_sub_item,
        attachments: res?.data?.data?.attachments,
        reminders: res?.data?.data?.payment_reminder,
        latePaymentType: res?.data?.data?.latePaymentType,
        frontendStorage: res?.data?.data?.frontendStorage
          ? res?.data?.data?.frontendStorage
          : {lateFeeForAll: {}},
        payment_invoice: res?.data?.data?.payment_invoice,
        payment_receipt: res?.data?.data?.payment_receipt,
        invoiceData: [
          {
            _id: res?.data?.data?.payment_invoice?.[0]?._id,
            name: res?.data?.data?.payment_invoice?.[0]?.name || 'INVOICE',
            invoiceNo: res?.data?.data?.payment_invoice?.[0]?.invoiceNo || '',
            date: res?.data?.data?.payment_invoice?.[0]?.date || '',
            dueDate: res?.data?.data?.dueDate || res?.data?.data?.payment_invoice?.[0]?.dueDate,
            invoiceLogo: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.invoiceLogo || logo,
            invoiceCompany: {
              trn:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.trn || invoiceCompany?.trn,
              name:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.name || invoiceCompany?.name,
              address:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.address ||
                invoiceCompany?.address,
              countryCode:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.countryCode ||
                invoiceCompany?.countryCode,
              phoneNumber:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.phoneNumber ||
                invoiceCompany?.phoneNumber,
              email:
                res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?.email ||
                invoiceCompany?.email,
              _id: res?.data?.data?.payment_invoice?.[0]?.invoiceCompany?._id,
            },
            note: res?.data?.data?.payment_invoice?.[0]?.note || '',
            paymentSubItems: res?.data?.data?.payment_sub_item || '',
            paymentMethod:
              res?.data?.data?.payment_invoice?.[0]?.paymentMethod ||
              (res?.data?.data?.paymentMethod == null || res?.data?.data?.paymentMethod == ''
                ? ''
                : res?.data?.data?.paymentMethod),
            totalAmount:
              res?.data?.data?.payment_invoice?.[0]?.totalAmount || res?.data?.data?.totalAmount,
            saveStatus:
              res?.data?.data?.payment_invoice?.[0]?.saveStatus || res?.data?.data?.saveStatus,
            totalVAT: res?.data?.data?.payment_invoice?.[0]?.totalVAt || res?.data?.data?.totalVAt,
            subTotalAmount:
              res?.data?.data?.payment_invoice?.[0]?.subTotalAmount ||
              res?.data?.data?.subTotalAmount,
            paymentAccountId:
              res?.data?.data?.payment_invoice?.[0]?.paymentAccountId ||
              res?.data?.data?.paymentAccountId,
            paymentId: res?.data?.data?._id,
            status: 0,
            outstandingAmount: res?.data?.data?.payment_invoice?.[0]?.outstandingAmount,
            itemDescriptions: res?.data?.data?.payment_invoice?.[0]?.itemDescriptions,
            frontendStorage: res?.data?.data?.payment_invoice?.[0]?.frontendStorage,
            createdOriginType: res?.data?.data?.payment_invoice?.[0]?.createdOriginType,
          },
        ],
      }

      values?.items?.forEach((vl: any, ind: any) => {
        values.items[ind].outstandingAmount = vl.totalAmount - vl?.amountPaid
      })

      let tenancyIdToCompare: any = res?.data?.data?.tenancyId
      setReminderWithID(res?.data?.data?.payment_reminder)

      switch (res?.data?.data?.paymentMethod) {
        case 1:
          setSelectedPaymentMethod([{value: '1', label: 'Cheque'}])
          break
        case 2:
          setSelectedPaymentMethod([{value: '2', label: 'Bank Transfer'}])
          break
        case 3:
          setSelectedPaymentMethod([{value: '3', label: 'Cash'}])
          break
        case 0:
          setSelectedPaymentMethod([{value: '0', label: 'Card'}])
          break
        default:
          setSelectedPaymentMethod([])
      }

      if (res?.data?.data?.tenancyId != null) {
        const body = {
          page: 1,
          limit: 10000,
          searchTenant: '',
          searchDevelopment: '',
          searchContractNo: '',
          searchUnitNo: '',
        }

        try {
          const tenancyRes = await ApiPost('corporate/tenancy/overview', body)
          tenancyRes?.data?.data?.tenancy_data?.forEach((rs: any) => {
            if (rs?._id === tenancyIdToCompare) {
              values.payorData = rs
            }
          })
        } catch (err) {
          console.error('Error fetching tenancy data:', err)
        }
      }

      // console.log('Get Payment By ID')
      // console.log(values)

      setIncomeAPIDetails(res?.data?.data)

      if (flg === '2' && res?.data?.data?.payment_invoice?.length === 0) {
        const invoiceDetails = await getInvoiceDetails()
        let valuesInv = {...values?.invoiceData?.[0]}

        valuesInv.invoiceCompany = invoiceDetails?.invoiceCompany
        valuesInv.invoiceLogo = invoiceDetails?.invoiceLogo

        values.invoiceData[0] = valuesInv
      }

      setIncomeData(values)
      setIsSaveFirst(false)
      setRefreshChild(Date.now())

      setTimeout(() => {
        setIsSkeleton(false)
      }, 700)
    } catch (err: any) {
      console.error('Error:', err)
      ErrorToast(err?.message)
      setIsSkeleton(false)
    }
  }

  useEffect(() => {
    if (flg == '2') {
      setIsSkeleton(true)
      getPaymentById()
    }
  }, [])

  const getPayorList = (tenancyId: any) => {}

  //
  useEffect(() => {
    if (isSaveFirst) getPaymentById()
  }, [isSaveFirst])

  const [invRecDetails, setInvRecDetails] = useState<any>(null)
  const [invoiceCompany, setInvoiceCompany] = useState<any>()
  const [logo, setLogo] = useState<any>(null)

  const getInvoiceDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting')
      return res?.data?.data // Return the relevant data
    } catch (err) {
      console.log('Error in getInvoiceDetails:', err)
      throw err // Re-throw the error so it can be caught where it's called
    }
  }

  //
  const getInitialInvoiceDetail = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        setInvoiceCompany(res?.data?.data?.invoiceCompany)
        setLogo(res?.data?.data?.invoiceLogo)

        let values = {...incomeData}
        let valuesInv = {...values?.invoiceData?.[0]}

        valuesInv.invoiceCompany = res?.data?.data?.invoiceCompany
        valuesInv.invoiceLogo = res?.data?.data?.invoiceLogo

        values.invoiceData[0] = valuesInv
        setIncomeData(values)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (flg == '1') getInitialInvoiceDetail()
  }, [])

  const [idToDelete, setIDToDelete] = useState<any>([])

  //
  const addIncomePayment = async (saveStatus: any, from: any) => {
    console.log('{***************Income Add Function ****************}')
    console.log(incomeData)
    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }

    if (isValid) {
      let errors: any = false

      if (saveStatus == 1) {
        incomeData?.items?.map((itm: any, ind: any) => {
          let totalAmountOfItem: any = 0
          let vatAmnt: any =
            ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
          totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

          if (
            itm?.name?.trim()?.length === 0 ||
            itm?.amount === '' ||
            itm?.amount === undefined ||
            Number(itm?.amount) == 0 ||
            itm?.quantity === '' ||
            itm?.quantity === undefined ||
            Number(itm?.quantity) == 0 ||
            itm?.VATPercentage === '' ||
            itm?.VATPercentage === undefined
          ) {
            errors = true
          }
        })
      }

      let payorSelectionErr: any = false

      let amountLessErr: any = false

      if (saveStatus == 1 && incomeData?.payorData?._id == undefined) payorSelectionErr = true

      let noInvoiceError: any = false
      if (
        saveStatus == 1 &&
        (incomeData?.payment_invoice?.length == 0 || incomeData?.payment_invoice == undefined)
      )
        noInvoiceError = true

      if (!errors && !payorSelectionErr && !amountLessErr && !noInvoiceError) {
        setIsLoading(true)
        setOpen(true)

        // Payment Items
        let newItems: any = []
        let newItemInd: any = 0
        let updateItems: any = []
        let updateItemInd: any = 0

        let invoicePaymentItems: any = []

        var nonVat: any = 0
        var vatAmount: any = 0

        //
        let paidAmountOfItems: any = []

        incomeData?.items?.map((incItm: any, indOut: number) => {
          let paidAmount: any = 0

          receiptData?.map((rcpt: any, index: any) => {
            rcpt?.paymentSubItems?.map((rcptItm: any, indIn: number) => {
              if (incItm?.frontendStorage?.itemUniqueId == rcptItm?.frontendStorage?.itemUniqueId)
                paidAmount = paidAmount + rcptItm?.amountPaid
            })
          })

          paidAmountOfItems?.push(paidAmount)
        })

        incomeData?.items?.map((itm: any, ind: any) => {
          let totalAmountOfItem: any = 0
          let vatAmnt: any =
            ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
          totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

          let totalAmountPaidOfItem: any = 0

          incomeData?.paymentRecords?.map((prRec: any, prInd: any) => {
            prRec?.items?.map((prItm: any, prItmInd: any) => {
              if (
                prItm?.paymentItemListId == itm?.paymentItemListId &&
                prItm?.frontendStorage?.itemUniqueId == itm?.frontendStorage?.itemUniqueId
              )
                totalAmountPaidOfItem = totalAmountPaidOfItem + prItm?.amountPaid
            })
          })

          if (itm?.id == '') {
            newItems[newItemInd] = {
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              name: itm?.name ? itm?.name : '',
              amount: isNaN(itm?.amount) ? null : Number(itm?.amount),
              quantity: isNaN(itm?.quantity) ? null : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? null : Number(totalAmountOfItem),
              amountPaid: Number(paidAmountOfItems[ind]),
              outstandingAmount: Number(totalAmountOfItem) - Number(paidAmountOfItems[ind]),
              VATAmount: isNaN(itm?.VATPercentage) ? null : Number(vatAmnt),
              VATPercentage: isNaN(itm?.VATPercentage) ? null : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
            }

            invoicePaymentItems?.push(newItems[newItemInd])
            newItemInd++
          } else {
            updateItems[updateItemInd] = {
              _id: itm?._id,
              name: itm?.name ? itm?.name : '',
              paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
              amount: isNaN(itm?.amount) ? null : Number(itm?.amount),
              quantity: isNaN(itm?.quantity) ? null : Number(itm?.quantity),
              totalAmount: isNaN(itm?.totalAmount) ? null : Number(totalAmountOfItem),
              amountPaid: Number(paidAmountOfItems[ind]),
              outstandingAmount: Number(totalAmountOfItem) - Number(paidAmountOfItems[ind]),
              VATAmount: isNaN(itm?.VATPercentage) ? null : Number(vatAmnt),
              VATPercentage: isNaN(itm?.VATPercentage) ? null : Number(itm?.VATPercentage),
              latePaymentFees: itm?.latePaymentFees,
              latePaymentSelectionStatus: Number(itm?.latePaymentSelectionStatus),
              latePaymentObject: {},
              oldLatePaymentCycleList: [],
              nextLatePaymentCycle: null,
              paymentSubItemStatus: itm?.paymentSubItemStatus,
              frontendStorage: itm.frontendStorage,
            }

            invoicePaymentItems?.push(updateItems[updateItemInd])
            updateItemInd++
          }
        })

        // if (from == 'invoice') {
        let amountPaidForInvoice: any = 0
        invoicePaymentItems?.map((f: any, ind: any) => {
          if (f?.frontendStorage?.isLatePaymentItem) {
            vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
            nonVat = nonVat + f.amount
            amountPaidForInvoice = amountPaidForInvoice + f.amountPaid
          } else {
            vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
            nonVat = nonVat + f.amount * f.quantity
            amountPaidForInvoice = amountPaidForInvoice + f.amountPaid
          }
        })
        incomeData.invoiceData[0].subTotalAmount = nonVat
        incomeData.invoiceData[0].totalVAT = vatAmount
        incomeData.invoiceData[0].totalAmount = Number(nonVat) + Number(vatAmount)
        incomeData.invoiceData[0].outstandingAmount =
          Number(incomeData.invoiceData[0].totalAmount) + Number(amountPaidForInvoice)
        // }

        // Payment Reminders
        let newRemind: any = []
        let newRemindInd: any = 0
        let updateRemind: any = []
        let updateRemindInd: any = 0

        // console.log(incomeData?.reminders)

        incomeData?.reminders?.map((rm: any, ind: any) => {
          if (rm?._id) {
            updateRemind[updateRemindInd] = rm
            updateRemindInd++
          } else {
            newRemind[newRemindInd] = rm
            newRemindInd++
          }
        })

        let temp = incomeData?.reminders
        temp?.map((tmp: any, ind: any) => {
          if (temp[ind]?._id) {
            temp[ind]._id = undefined
            temp[ind].id = undefined
          }
        })

        let invValues: any = [...incomeData?.invoiceData]
        invValues[0].paymentSubItems = invoicePaymentItems
        invValues[0].categories = incomeData?.categories
        invValues[0].formStatus = saveStatus
        invValues[0].formStatusDateTime = new Date()

        if (incomeAPIDetails?.payment_invoice?.length == 0) invValues[0]._id = undefined

        deleteInvoiceIds?.forEach((dl: any) => {
          invValues = invValues?.filter((inv: any) => inv?._id !== dl?._id)
        })

        // console.log(invValues)

        if (incomeAPIDetails?.payment_invoice?.[0]?._id != undefined && invValues?.length > 0) {
          invValues[0].invoiceCompany = undefined
          invValues[0].invoiceLogo = undefined
        }

        if (from == 'invoice') {
          invValues[0].frontendStorage = {
            showAccountDetails: true,
            showNotes: true,
          }
          invValues[0].itemDescriptions = []
        }

        let deletePaymentItemsIds: any = []
        idToDelete?.map((itm: any, ind: any) => {
          if (itm?._id != undefined) deletePaymentItemsIds?.push(itm)
        })

        const body = {
          payment: {
            _id: flg == '1' ? undefined : id,
            name: incomeData?.name ? incomeData?.name : '',
            note: incomeData?.notes ? incomeData?.notes : '',
            currency: 'AED',
            // paymentStatus: 0,
            categories: incomeData?.categories,
            latePaymentType:
              incomeData?.latePaymentType != undefined ? incomeData?.latePaymentType : '',
            amountPaid: grandPaidAmount,
            // totalRefundAmount: 0,
            outstandingAmount: Number(grandTotalAmount) - Number(grandPaidAmount),
            totalAmount: grandTotalAmount,
            // subTotalAmount: 100,
            // totalVAT: 5,
            // totalAmount: 105,
            // totalLatePaymentAmount: 0,

            paymentType: 0,
            isCardVerificationEnable: false,
            isLock: false,
            chequeBankName: null,
            dueDate: incomeData?.dueDate,
            // datePaid: null,
            paymentMethod:
              selectedPaymentMethod?.length > 0 ? Number(selectedPaymentMethod?.[0]?.value) : '',
            attachments: incomeData?.attachments,
            chequeImages: Number(selectedPaymentMethod?.[0]?.value) == 1 ? chequeImages : [],
            paymentAccountId: selectedAccount?.length > 0 ? selectedAccount?.[0]?.value : null,
            tenantId:
              incomeData?.payorData?.tenant?.length > 0
                ? incomeData?.payorData?.tenant?.[0]?._id
                : null,
            tenancyId: incomeData?.payorData?._id ? incomeData?.payorData?._id : null,
            unitId: incomeData?.payorData?.unit?.[0]?._id
              ? incomeData?.payorData?.unit?.[0]?._id
              : null,
            clusterId: incomeData?.payorData?.cluster?.[0]?._id
              ? incomeData?.payorData?.cluster?.[0]?._id
              : null,
            unitGroupId: incomeData?.payorData?.unit_group?.[0]?._id
              ? incomeData?.payorData?.unit_group?.[0]?._id
              : null,
            floorId: incomeData?.payorData?.floor?.[0]?._id
              ? incomeData?.payorData?.floor?.[0]?._id
              : null,
            communityId: incomeData?.payorData?.community?.[0]?._id
              ? incomeData?.payorData?.community?.[0]?._id
              : null,
            buildingId: incomeData?.payorData?.building?.[0]?._id
              ? incomeData?.payorData?.building?.[0]?._id
              : null,
            tenantCardId: null,
            serviceRequestId: null,
            serviceJobIds: [],
            formStatus: saveStatus,
            formStatusDateTime: new Date(),
            cardChargePayBy: chargeTenant,
            frontendStorage: incomeData?.frontendStorage,
          },
          new_payment_sub_item: newItems,
          update_payment_sub_item: updateItems,
          delete_payment_sub_item: deletePaymentItemsIds,

          new_payment_reminder: newRemind,
          update_payment_reminder: [],
          new_payment_invoice: from == 'invoice' ? invValues : [],
          update_payment_invoice:
            incomeAPIDetails?.payment_invoice?.[0]?._id != undefined ? invValues : [],
          delete_payment_invoice: deleteInvoiceIds,
        }
        ApiPost(`corporate/payment/v1/add_update`, body)
          .then((res) => {
            if (from == 'invoice') {
              navigate(`/financials/add-invoice/1/${res?.data?.data?.payment?._id}`)
            } else {
              if (saveStatus == 0) {
                if (flg == '1') {
                  navigate(`/financials/add-income-payment/2/${res?.data?.data?.payment?._id}`)
                  setTimeout(() => {
                    setIsSaveFirst(true)
                  }, 1000)
                }

                setIsSaveFirst(true)
              } else {
                if (flg == '1') {
                }

                SuccessToast('Income Payment has been added successfully..')
                navigate('/finance-income')
                // navigate(-1)
              }

              setTimeout(() => {
                setIsLoading(false)
                setOpen(false)
              }, 1500)
            }
            newItems = []
            updateItems = []
            setIDToDelete([])
            setDeleteInvoiceIds([])
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setTimeout(() => {
              setIsLoading(false)
              setOpen(false)
            }, 1500)
          })
      } else {
        if (payorSelectionErr) ErrorToast('Please select a PAYOR before creating this payment')
        else if (errors)
          ErrorToast('Complete all payment items before creating this income payment')
        else if (noInvoiceError) ErrorToast('Please generate an invoice')
      }
    }
  }

  //
  useEffect(() => {
    if (autoSaveData != false && flg == '2') {
      addIncomePayment(0, '')
    }
  }, [autoSaveData])

  //
  const addPaymentRecord = (expId: any, data: any) => {
    const paymentItems: any = []
    let items: any = []
    data?.items?.map((itm: any, ind: any) => {
      items[ind] = {
        paymentItemListId: itm?.paymentItemListId,
        name: itm?.name,
        amount: Number(itm?.amount),
        quantity: Number(itm?.quantity),
        totalAmount: Number(itm?.totalAmount),
        amountPaid: itm?.totalAmountPaid ? Number(itm?.totalAmountPaid) : Number(itm?.amountPaid),
        VATPercentage: Number(itm?.VATPercentage),
        VATAmount:
          itm?.VATPercentage != ''
            ? ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
            : 0,
        outstandingAmount: Number(itm?.outstandingAmount),
        frontendStorage: itm?.frontendStorage,
      }

      incomeData?.items?.map((itmOfPayment: any, index: any) => {
        //
        let paidAmount: any = items[ind]?.amountPaid | 0
        receiptData?.map((rcpt: any, index: any) => {
          rcpt?.paymentSubItems?.map((rcptItm: any, indIn: number) => {
            if (
              itmOfPayment?.frontendStorage?.itemUniqueId == rcptItm?.frontendStorage?.itemUniqueId
            )
              paidAmount = paidAmount + rcptItm?.amountPaid
          })
        })

        let totalAmountOfItem: any = 0
        let vatAmnt: any =
          ((Number(itmOfPayment?.amount) * Number(itmOfPayment?.quantity)) / 100) *
          Number(itmOfPayment?.VATPercentage)

        totalAmountOfItem = Number(itmOfPayment?.amount) * Number(itmOfPayment?.quantity) + vatAmnt

        let totalAmountPaidOfItem: any = 0

        if (itm?.frontendStorage?.itemUniqueId == itmOfPayment?.frontendStorage?.itemUniqueId)
          totalAmountPaidOfItem = Number(itmOfPayment?.amountPaid) + Number(itm?.totalAmountPaid)

        paymentItems[index] = {
          _id: itmOfPayment?._id,
          name: itmOfPayment?.name ? itmOfPayment?.name : '',
          paymentItemListId: itmOfPayment?.paymentItemListId
            ? itmOfPayment?.paymentItemListId
            : null,
          amount: isNaN(itmOfPayment?.amount) ? undefined : Number(itmOfPayment?.amount),
          quantity: isNaN(itmOfPayment?.quantity) ? undefined : Number(itmOfPayment?.quantity),
          totalAmount: isNaN(itmOfPayment?.totalAmount) ? undefined : Number(totalAmountOfItem),
          amountPaid: Number(paidAmount),
          outstandingAmount: Number(totalAmountOfItem) - Number(paidAmount),
          VATAmount: Number(vatAmnt),
          VATPercentage: isNaN(itmOfPayment?.VATPercentage)
            ? undefined
            : Number(itmOfPayment?.VATPercentage),
          latePaymentFees: itmOfPayment?.latePaymentFees,
          latePaymentSelectionStatus: Number(itmOfPayment?.latePaymentSelectionStatus),
          latePaymentObject: {},
          oldLatePaymentCycleList: [],
          nextLatePaymentCycle: null,
          paymentSubItemStatus: itmOfPayment?.paymentSubItemStatus,
          frontendStorage: itmOfPayment?.frontendStorage,
        }

        index++
      })
    })

    const body = {
      payment: {
        _id: id,
      },
      new_payment_receipt: [
        {
          createdOriginType: 0,
          paymentId: id ? id : undefined,
          receiptNo: '',
          datePaid: data?.datePaid,
          note: data?.notes,
          amountPaid: Number(data?.totalAmountPaid),
          paymentSubItems: items,
          receiptAttachment:
            data?.receipts?.[0]?.fileURL?.trim()?.length > 0 ? data?.receipts?.[0] : {},
          otherAttachment:
            data?.attachments?.[0]?.fileURL?.trim()?.length > 0 ? data?.attachments : [],
          paymentInvoiceId: incomeData?.invoiceData?.[0]?._id,
        },
      ],

      update_payment_sub_item: paymentItems,
    }

    ApiPost(`corporate/payment/v1/add_update`, body)
      .then((res) => {
        setRefreshChild(Date.now())
        getPaymentById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const updatePaymentRecord = (expId: any, data: any) => {
    const paymentItems: any = []
    let items: any = []
    data?.items?.map((itm: any, ind: any) => {
      items[ind] = {
        paymentItemListId: itm?.paymentItemListId,
        name: itm?.name,
        amount: Number(itm?.amount),
        quantity: Number(itm?.quantity),
        totalAmount: Number(itm?.totalAmount),
        amountPaid: itm?.totalAmountPaid ? Number(itm?.totalAmountPaid) : Number(itm?.amountPaid),
        VATPercentage: Number(itm?.VATPercentage),
        VATAmount:
          itm?.VATPercentage != ''
            ? ((Number(itm?.amount) * Number(itm?.quantity)) / 100) * Number(itm?.VATPercentage)
            : 0,
        outstandingAmount: Number(itm?.outstandingAmount),
        frontendStorage: itm?.frontendStorage,
      }

      incomeData?.items?.map((itmOfPayment: any, index: any) => {
        //
        let paidAmount: any = items[ind]?.amountPaid | 0
        receiptData?.map((rcpt: any, index: any) => {
          if (rcpt?._id != data?._id) {
            rcpt?.paymentSubItems?.map((rcptItm: any, indIn: number) => {
              if (
                itmOfPayment?.frontendStorage?.itemUniqueId ==
                rcptItm?.frontendStorage?.itemUniqueId
              )
                paidAmount = paidAmount + rcptItm?.amountPaid
            })
          }
        })

        let totalAmountOfItem: any = 0
        let vatAmnt: any =
          ((Number(itmOfPayment?.amount) * Number(itmOfPayment?.quantity)) / 100) *
          Number(itmOfPayment?.VATPercentage)
        totalAmountOfItem = Number(itmOfPayment?.amount) * Number(itmOfPayment?.quantity) + vatAmnt

        let totalAmountPaidOfItem: any = 0

        if (itm?.frontendStorage?.itemUniqueId == itmOfPayment?.frontendStorage?.itemUniqueId)
          totalAmountPaidOfItem = Number(itmOfPayment?.amountPaid) + Number(itm?.totalAmountPaid)

        paymentItems[index] = {
          _id: itmOfPayment?._id,
          name: itmOfPayment?.name ? itmOfPayment?.name : '',
          paymentItemListId: itmOfPayment?.paymentItemListId
            ? itmOfPayment?.paymentItemListId
            : null,
          amount: isNaN(itmOfPayment?.amount) ? undefined : Number(itmOfPayment?.amount),
          quantity: isNaN(itmOfPayment?.quantity) ? undefined : Number(itmOfPayment?.quantity),
          totalAmount: isNaN(itmOfPayment?.totalAmount) ? undefined : Number(totalAmountOfItem),
          amountPaid: Number(paidAmount),
          outstandingAmount: Number(totalAmountOfItem) - Number(paidAmount),
          VATAmount: Number(vatAmnt),
          VATPercentage: isNaN(itmOfPayment?.VATPercentage)
            ? undefined
            : Number(itmOfPayment?.VATPercentage),
          latePaymentFees: itmOfPayment?.latePaymentFees,
          latePaymentSelectionStatus: Number(itmOfPayment?.latePaymentSelectionStatus),
          latePaymentObject: {},
          oldLatePaymentCycleList: [],
          nextLatePaymentCycle: null,
          paymentSubItemStatus: itmOfPayment?.paymentSubItemStatus,
          frontendStorage: itmOfPayment?.frontendStorage,
        }

        index++
      })
    })

    const body = {
      payment: {
        _id: id,
      },
      update_payment_receipt: [
        {
          _id: data?._id,
          paymentId: id ? id : undefined,
          receiptNo: '',
          datePaid: data?.datePaid,
          note: data?.notes,
          amountPaid: Number(data?.totalAmountPaid),
          paymentSubItems: items,
          receiptAttachment:
            data?.receipts?.[0]?.fileURL?.trim()?.length > 0 ? data?.receipts?.[0] : {},
          otherAttachment:
            data?.attachments?.[0]?.fileURL?.trim()?.length > 0 ? data?.attachments : [],
          paymentInvoiceId: incomeData?.invoiceData?.[0]?._id,
          createdOriginType: 0,
        },
      ],

      update_payment_sub_item: paymentItems,
    }
    ApiPost(`corporate/payment/v1/add_update`, body)
      .then((res) => {
        setRefreshChild(Date.now())
        getPaymentById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const [refreshChild, setRefreshChild] = useState<any>(true)

  //
  const deletePayment = async () => {
    setIsLoading(true)
    let url = `corporate/payment/${id}`

    await ApiDelete(`${url}`)
      .then((res: any) => {
        SuccessToast('Income Payment has been deleted successfully')
        navigate('/finance-income')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  let grandTotalAmount: any = 0
  let grandPaidAmount: any = 0
  let grandOutstandingAmount: any = 0

  //
  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  //
  const deleteReminder = (val: any) => {
    setIsLoading(true)
    setOpen(true)

    // let newRemind: any = []
    // let newRemindInd: any = 0
    // let updateRemind: any = []
    // let updateRemindInd: any = 0

    // console.log(incomeData?.reminders)

    // incomeData?.reminders?.map((rm: any, ind: any) => {
    //   if (rm?._id) {
    //     updateRemind[updateRemindInd] = rm
    //     updateRemindInd++
    //   } else {
    //     newRemind[newRemindInd] = rm
    //     newRemindInd++
    //   }
    // })

    const body = {
      payment: {
        _id: flg == '1' ? undefined : id,
      },
      new_payment_reminder: [],
      update_payment_reminder: [],
      delete_payment_reminder: [{_id: val}],
    }
    ApiPost(`corporate/payment/v1/add_update`, body)
      .then((res) => {
        getPaymentById()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
  }

  const [selectAccountError, setSelectAccountErr] = useState<any>(false)
  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const [chequeImages, setChequeImages] = useState<any>([])

  //
  const handleImageClickCheques = () => {
    const groupName = `group_attachments_others`

    chequeImages?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
        imgeNext.setAttribute('data-fancybox', groupName)

        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }

      // Trigger the fancybox on the clicked image
      // if (imgeNext) {
      //   imgeNext.click()
      // }
    })
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const [editFile, setEditFile] = useState<any>(-1)
  const [newName, setNewName] = useState<any>('')

  //
  const handleUploadCheque = async (e: any) => {
    e.preventDefault()

    const values = [...chequeImages]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (chequeImages.some((chq: any) => chq?.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            values?.push({
              URL: res?.data?.data?.image,
              name: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            })

            // update the upload progress
            setUploadProgress(i + 1)

            // console.log(values);
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }

      setChequeImages(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      if (flg == '2') {
        setTimeout(() => {
          setAutoSaveData(Date.now())
        }, 700)
      }

      e.target.value = null
    }
  }

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <form
            onSubmit={handleSubmit((data: any, e: any) => {
              // addIncomePayment(1)
            })}
          >
            <div id='' className='pt-0 mt-0'>
              {' '}
              {/* Header */}
              <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
                {' '}
                <div className='d-flex align-items-center mb-1'>
                  <div className='d-flex align-items-center'>
                    <span
                      className='rounded-circle cursor-pointer'
                      onClick={() => navigate('/finance-income')}
                    >
                      <img src={backArrow} height='14' width='14' />
                    </span>
                    <h2 className='m-0 head-text ms-2 text-capitalize'>{`Add Income Payment`}</h2>
                  </div>

                  <div className='d-flex ms-auto'>
                    {flg != '1' ? (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                        disabled={isLoading || isViewOnly}
                        onClick={() => {
                          swal
                            .fire({
                              html: ` 
                            <div class='fs-3'> <b>Delete Income Payment</></div>
                             <div class='fs-5 mt-4'>You are about to delete the income payment.</div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                              // icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                            })
                            .then((res: any) => {
                              if (res.isConfirmed) {
                                deletePayment()
                              }
                            })
                        }}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                      </button>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                        onClick={() => {
                          addIncomePayment(0, '')
                        }}
                        disabled={isLoading || sendError}
                      >
                        <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}
                        Save
                      </button>
                    )}

                    <button
                      type='submit'
                      className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                      onClick={() => {
                        addIncomePayment(1, '')
                      }}
                      disabled={isLoading || isViewOnly}
                    >
                      <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                      Create
                    </button>
                  </div>
                </div>
              </div>
              {/* GRAND TOTAL AMOUNT */}
              {incomeData?.items?.map((itm: any, ind: any) => {
                grandTotalAmount = Number(grandTotalAmount) + Number(itm?.totalAmount)
              })}
              {/* PAID AND OUTSTANDING AMOUNT */}
              {incomeData?.payment_receipt?.map((rc: any, ind: any) => {
                grandPaidAmount = Number(grandPaidAmount) + Number(rc?.amountPaid)
              })}
              {/* Details Card */}
              <div className='card card-flush py-5 mt-5'>
                <div className='row mx-1'>
                  {/* left section */}
                  <div className='col-6 px-5'>
                    <div className='row'>
                      {/* Expense Details */}
                      <div className='col-12 pe-5 ps-0 mt-3'>
                        <div className='row me-4 ms-0 mt-0'>
                          <div className='col-8 px-5'>
                            <h2 className='top ps-0'>
                              <b>Payment Details</b>
                            </h2>
                          </div>
                          {flg != '1' && (
                            <div className='col-4'>
                              {incomeAPIDetails?.formStatusDateTime ? (
                                <div className=' me-2'>
                                  <div
                                    className={
                                      incomeAPIDetails?.formStatus == 0
                                        ? 'status draft-st ms-5 status-w-120'
                                        : 'status create-st ms-5 status-w-120'
                                    }
                                  >
                                    {incomeAPIDetails?.formStatus == 0 ? 'Draft' : 'Created'}
                                  </div>
                                  <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                                    {' '}
                                    {incomeAPIDetails?.formStatusDateTime
                                      ? `${moment
                                          .utc(incomeAPIDetails?.formStatusDateTime)
                                          .local()
                                          .format('DD.MM.YYYY - hh:mm A')}`
                                      : ''}
                                  </i>
                                </div>
                              ) : (
                                <div className='status not-saved ms-5 status-w-120'>-</div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className='row me-4 ms-0 mt-4'>
                          <div className='col-12 px-5 d-flex'>
                            {/* <input
                              {...register('paymentName', {required: true})}
                              type='text'
                              className='form-control form-control-solid mytest tenancy-control bg-white'
                              name='paymentName'
                              value={incomeData?.name}
                              style={{height: '35px', width: '300px'}}
                              placeholder={'Enter Payment Name'}
                              onChange={(e: any) => {
                                let values = {...incomeData}
                                values.name =
                                  e.target.value != ''
                                    ? e.target.value.charAt(0).toUpperCase() +
                                      e.target.value.slice(1)
                                    : e.target.value

                                setIncomeData(values)
                                if (e.target.value?.trim()?.length != 0)
                                  setValue('paymentName', e.target.value, {shouldValidate: true})
                              }}
                              onBlur={() => {
                                if (flg == '2') {
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 700)
                                }
                              }}
                              autoComplete='off'
                            /> */}
                            <Input
                              register={register}
                              errors={errors}
                              incomeData={incomeData}
                              setIncomeData={setIncomeData}
                              setValue={setValue}
                              trigger={trigger} // Pass trigger function
                              setAutoSaveData={setAutoSaveData}
                              flg={flg}
                            />
                            {/* <h3 className='text-danger mb-0 ms-2'>
                              <b>*</b>
                            </h3> */}
                          </div>
                          {/* {errors.paymentName && (
                            <div className='col-12 px-5 d-flex'>
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0 mt-1'
                              >
                                This field is required
                              </p>
                            </div>
                          )} */}
                        </div>
                        <div className='row me-4 ms-0 mt-0'>
                          {/* Select Payee */}
                          <div className='col-12 px-5 mt-4'>
                            <div className='d-flex my-4 '>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Payor
                                </label>
                              </h4>
                              {incomeData?.payorData?._id == undefined ? (
                                <div className='expense-date d-flex justify-content-center'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center status-w-200'
                                    onClick={(e: any) => {
                                      setShowSelectPayor(true)
                                    }}
                                    disabled={isLoading || isViewOnly}
                                  >
                                    Select Payor
                                  </button>
                                  <h3 className='text-danger mb-0 ms-2'>
                                    <b>*</b>
                                  </h3>
                                </div>
                              ) : (
                                <div className='d-flex expense-date'>
                                  <span className='ms-1'>
                                    <b>
                                      {`${incomeData?.payorData?.tenant?.[0]?.firstName} ${incomeData?.payorData?.tenant?.[0]?.lastName}`}
                                    </b>
                                  </span>
                                  <img
                                    src={redCross}
                                    height={16}
                                    width={16}
                                    className='ms-auto cursor-pointer'
                                    onClick={() => {
                                      let values = {...incomeData}
                                      values.payorData = {}

                                      setIncomeData(values)

                                      if (flg == '2') {
                                        setTimeout(() => {
                                          setAutoSaveData(Date.now())
                                        }, 700)
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {/* Due date */}
                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Due Date
                                </label>
                              </h4>
                              <div>
                                <div>
                                  <Controller
                                    name='dueDate'
                                    control={control}
                                    rules={{
                                      required: 'this is required',
                                    }}
                                    render={({field}) => (
                                      <div className='expense-date'>
                                        {incomeData?.dueDate != null ? (
                                          <DatePicker
                                            name='dueDate'
                                            value={moment(
                                              new Date(incomeData?.dueDate),
                                              'DD.MM.YYYY'
                                            )}
                                            format={'DD.MM.YYYY'}
                                            // disabledDate={disabledDate}
                                            onChange={handleDateChange}
                                            placeholder='Select Due Date'
                                          />
                                        ) : (
                                          <DatePicker
                                            name='dueDate'
                                            // disabledDate={disabledDate}
                                            onChange={handleDateChange}
                                            placeholder='Select Due Date'
                                          />
                                        )}
                                      </div>
                                    )}
                                  />
                                </div>
                                {errors.dueDate && (
                                  <p
                                    style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                    className='m-0 mt-1'
                                  >
                                    This field is required
                                  </p>
                                )}
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div>
                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Receiving Account
                                </label>
                              </h4>

                              <div className='test expense property-test'>
                                <div
                                  className={`multi-select-container ${
                                    selectedAccount?.length === 0
                                      ? 'no-value expense-account'
                                      : 'has-value'
                                  }`}
                                >
                                  <Controller
                                    name='accountNumber'
                                    control={control}
                                    rules={{
                                      required: 'This field is required',
                                    }}
                                    render={({field}) => (
                                      <MultiSelect
                                        {...field}
                                        options={accountOptions}
                                        value={selectedAccount}
                                        onChange={(e: any) => {
                                          let newVal: any = e
                                          if (newVal?.length == 0) {
                                            setSelectedPaymentMethod([])
                                            setSelectedAccount(newVal)

                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          } else {
                                            if (e?.length > 1) newVal = e.slice(1)

                                            setValue('accountNumber', newVal, {
                                              shouldValidate: true,
                                            }) // Trigger validation

                                            setSelectedAccount(newVal)
                                            setSelectAccountErr(false)

                                            let values = {...incomeData}
                                            values.paymentAccountId = e.value

                                            setIncomeData(values)

                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          }
                                        }}
                                        labelledBy='Type'
                                        hasSelectAll={false}
                                        overrideStrings={{
                                          allItemsAreSelected: `${selectedAccount?.[0]?.label}`,
                                        }}
                                        closeOnChangedValue={true}
                                      />
                                    )}
                                  />
                                  {errors.accountNumber && (
                                    <p
                                      style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                </div>
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div>

                          <div className='col-11 col-md-9 px-5 mt-2'>
                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry'>
                                  {' '}
                                  Payment Method
                                </label>
                              </h4>

                              <div className='test expense  property-test'>
                                <div
                                  className={`multi-select-container ${
                                    selectedPaymentMethod?.length === 0
                                      ? 'no-value expense-payment-method'
                                      : 'has-value'
                                  }`}
                                >
                                  <Controller
                                    name='paymentMethod'
                                    control={control}
                                    rules={{
                                      required: 'This field is required',
                                    }}
                                    render={({field}) => (
                                      <MultiSelect
                                        {...field}
                                        options={paymentMethodOptions}
                                        value={selectedPaymentMethod}
                                        onChange={(e: any) => {
                                          if (selectedAccount?.length == 0) {
                                            setSelectAccountErr(true)
                                            setSelectedPaymentMethod([])
                                            let values = {...incomeData}
                                            values.invoiceData[0].paymentMethod = null
                                            setIncomeData(values)
                                          } else {
                                            let newVal: any = e
                                            if (e?.length > 1) newVal = e.slice(1)
                                            setSelectedPaymentMethod(newVal)

                                            setSelectAccountErr(false)

                                            let values = {...incomeData}
                                            values.paymentAccountId = e.value
                                            values.invoiceData[0].paymentMethod = newVal?.[0]?.value

                                            setValue('paymentMethod', `${newVal}1`, {
                                              shouldValidate: true,
                                            })

                                            setIncomeData(values)

                                            if (flg == '2') {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          }
                                        }}
                                        labelledBy='Type'
                                        hasSelectAll={false}
                                        overrideStrings={{
                                          allItemsAreSelected: `${selectedPaymentMethod?.[0]?.label}`,
                                        }}
                                        closeOnChangedValue={true}
                                      />
                                    )}
                                  />
                                  {errors.paymentMethod && (
                                    <p
                                      style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                      className='m-0 mt-1'
                                    >
                                      This field is required
                                    </p>
                                  )}
                                  {selectAccountError && (
                                    <p
                                      style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                      className='m-0 mt-1'
                                    >
                                      Please select the receiving account first.
                                    </p>
                                  )}
                                </div>
                              </div>
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </div>
                          </div>

                          <div className='col-12  px-5 mt-5'>
                            <div
                              className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 '}
                            >
                              <div className='d-flex'>
                                <h2 className='top ps-0'>
                                  <b>Receiving Account Details</b>
                                </h2>
                              </div>
                              <div className='d-flex mt-3 mb-0 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Account Name
                                  </label>
                                </h4>

                                <span className='ms-1'>
                                  <b>
                                    {selectedAccount?.[0]?.accountHolderName
                                      ? selectedAccount?.[0]?.accountHolderName
                                      : '-'}
                                  </b>
                                </span>
                              </div>
                              <div className='d-flex my-1 mb-0 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    IBAN
                                  </label>
                                </h4>

                                <span className='ms-1'>
                                  <b>
                                    {selectedAccount?.[0]?.IBAN ? selectedAccount?.[0]?.IBAN : '-'}
                                  </b>
                                </span>
                              </div>

                              <div className='d-flex my-1 mb-0 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    SWIFT
                                  </label>
                                </h4>

                                <span className='ms-1'>
                                  <b>
                                    {selectedAccount?.[0]?.swiftCode
                                      ? selectedAccount?.[0]?.swiftCode
                                      : '-'}
                                  </b>
                                </span>
                              </div>

                              <div className='d-flex my-1 mb-0 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Account No.
                                  </label>
                                </h4>

                                <span className='ms-1'>
                                  <b>
                                    {selectedAccount?.[0]?.accountNumber
                                      ? selectedAccount?.[0]?.accountNumber
                                      : '-'}
                                  </b>
                                </span>
                              </div>
                              <div className='d-flex my-1 mb-0 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Bank Name
                                  </label>
                                </h4>

                                <span className='ms-1'>
                                  <b>
                                    {selectedAccount?.[0]?.bankName
                                      ? selectedAccount?.[0]?.bankName
                                      : '-'}
                                  </b>
                                </span>
                              </div>

                              <div
                                className='d-flex align-items-center mt-2 pb-5 mb-4 mx-3'
                                style={{borderBottom: '0.1rem solid #bec3cb'}}
                              ></div>

                              {Number(selectedPaymentMethod?.[0]?.value) == 1 && (
                                <>
                                  <label
                                    title='Add New File'
                                    htmlFor='uploadCheque'
                                    className='btn btn-sm fw-bold px-2 green-hollow-btn btn-fit-content pe-5'
                                  >
                                    <img src={addGreen} height={18} width={18} className='me-3' />
                                    Add Cheque{' '}
                                  </label>
                                  <input
                                    type='file'
                                    hidden
                                    id='uploadCheque'
                                    className='form-control form-control-solid '
                                    placeholder='upload'
                                    name='passport'
                                    onChange={(e: any) => {
                                      handleUploadCheque(e)
                                    }}
                                    multiple
                                  />
                                </>
                              )}

                              {Number(selectedPaymentMethod?.[0]?.value) == 1 && (
                                <div
                                  className='table-responsive mt-4'
                                  style={{maxHeight: '300px', overflow: 'auto'}}
                                >
                                  <table
                                    className='table align-middle  fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    {/* <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                                        <th className='text-start min-w-100px'></th>
                                        <th className='text-start min-w-150px'></th>
                                        </tr>
                                     </thead> */}

                                    <tbody className='fw-semibold text-gray-600'>
                                      {chequeImages?.map((chq: any, index: any) => {
                                        return (
                                          <tr className=''>
                                            <td className='text-start ps-0'>
                                              {editFile == `${index}` ? (
                                                <div
                                                  className='d-flex  vendor me-5 pe-2'
                                                  style={{width: '-webkit-fill-available'}}
                                                >
                                                  <input
                                                    type='text'
                                                    value={newName}
                                                    placeholder='Enter receipt name'
                                                    className='form-control form-control-solid mytest ms-3'
                                                    onChange={(e: any) => {
                                                      let newValue: any =
                                                        e.target.value != ''
                                                          ? e.target.value.charAt(0).toUpperCase() +
                                                            e.target.value.slice(1)
                                                          : e.target.value
                                                      setNewName(newValue)
                                                    }}
                                                    disabled={isLoading}
                                                    autoComplete='off'
                                                  />
                                                </div>
                                              ) : (
                                                <div className='d-flex align-items-center'>
                                                  <div className='bullet-item me-3 mb-0 ms-1'></div>
                                                  <p
                                                    className='mb-0 cursor-pointer'
                                                    data-fancybox
                                                    data-src={`${Bucket}${chq?.URL}`}
                                                  >
                                                    {chq?.name}{' '}
                                                  </p>
                                                </div>
                                              )}
                                            </td>
                                            <td className='text-end'>
                                              <div
                                                className='d-flex align-items-center ms-auto'
                                                style={{width: 'max-content'}}
                                              >
                                                {editFile == `${index}` ? (
                                                  <>
                                                    <img
                                                      src={correct}
                                                      height={22}
                                                      width={22}
                                                      className='ms-5 cursor-pointer'
                                                      onClick={() => {
                                                        let values = [...chequeImages]
                                                        values[index].name = newName
                                                        setChequeImages(values)
                                                        setEditFile(-1)

                                                        if (flg == '2') {
                                                          setTimeout(() => {
                                                            setAutoSaveData(Date.now())
                                                          }, 700)
                                                        }
                                                      }}
                                                    />
                                                    <img
                                                      src={removed}
                                                      height={22}
                                                      width={22}
                                                      className='mx-5 cursor-pointer'
                                                      onClick={() => {
                                                        setEditFile(-1)
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src={getFileIcon(chq?.URL)}
                                                      height={30}
                                                      width={30}
                                                      className='ms-3'
                                                      id={`clickedAttachments${index}`}
                                                      onClick={() => {
                                                        handleImageClickCheques()
                                                      }}
                                                      // data-fancybox
                                                      data-src={`${Bucket}${chq?.URL}`}
                                                    />
                                                    {/* <a href={`${Bucket}${chq?.URL}`} target='_blank'> */}
                                                    <img
                                                      src={downloadIcon}
                                                      height={18}
                                                      width={18}
                                                      className='ms-5 cursor-pointer'
                                                      onClick={() => {
                                                        downloadFile(
                                                          `${Bucket}${chq?.URL}`,
                                                          chq?.name
                                                        )
                                                        // handleDownload(`${Bucket}${chq?.URL}`)
                                                      }}
                                                    />
                                                    {/* </a> */}
                                                    <img
                                                      src={greenPen}
                                                      height={18}
                                                      width={18}
                                                      className='ms-5 cursor-pointer'
                                                      onClick={() => {
                                                        setNewName(chq?.name)
                                                        setEditFile(index)
                                                      }}
                                                    />
                                                    <img
                                                      src={trashImg}
                                                      height={18}
                                                      width={18}
                                                      className='mx-5 cursor-pointer'
                                                      onClick={() => {
                                                        let values = [...chequeImages]
                                                        values.splice(index, 1)
                                                        setChequeImages(values)

                                                        if (flg == '2') {
                                                          setTimeout(() => {
                                                            setAutoSaveData(Date.now())
                                                          }, 700)
                                                        }
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              {Number(selectedPaymentMethod?.[0]?.value) == 0 && (
                                <>
                                  <h5 className='fs-4' style={{lineHeight: '1.5'}}>
                                    As part of the services offered by Propertise with card
                                    payments, there will be a fee on card transactions. How would
                                    you like to settle card processing fees?
                                  </h5>

                                  <div className='d-flex align-items-center tst ms-5 mb-4'>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('settleFees', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='chargeTenant'
                                      value='chargeTenant'
                                      checked={chargeTenant == 1}
                                      onChange={(e: any) => {
                                        setChargeTenant(1)

                                        if (flg == '2') {
                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 700)
                                        }
                                      }}
                                    />
                                    <h5 className='m-0'>
                                      <label htmlFor='chargeTenant' className='fs-4'>
                                        Charge Tenant
                                      </label>
                                    </h5>
                                  </div>

                                  <div className='d-flex align-items-center tst ms-5 mb-4'>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('settleFees', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='chargeCorporate'
                                      value='chargeCorporate'
                                      name='ownerType'
                                      checked={chargeTenant == 0}
                                      onChange={(e: any) => {
                                        setChargeTenant(0)

                                        if (flg == '2') {
                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 700)
                                        }
                                      }}
                                    />
                                    <h5 className='m-0'>
                                      <label htmlFor='chargeCorporate' className='fs-4'>
                                        Deduct card fees from the amount received from the tenant.
                                      </label>
                                    </h5>
                                  </div>

                                  {errors?.settleFees && (
                                    <p className='text-danger ms-5'>
                                      {'Please select an option to proceed'}
                                    </p>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 px-5 text end'>
                    <div className='row'>
                      {/* Payment ID Details */}
                      <div className='col-12 px-5 mt-3'>
                        <div className={'card card-flush py-5 container-xxl mt-2 mb-4 px-6'}>
                          <div className='d-flex align-items-center my-2'>
                            <h2 className='top ps-0' style={{width: '130px'}}>
                              Total
                            </h2>
                            <h2 className='top ps-0 ms-1'>
                              <b>
                                {grandTotalAmount
                                  ? `AED ${grandTotalAmount?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : 'AED -'}
                              </b>
                            </h2>

                            {flg != '1' && (
                              <h3 className='ms-auto' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  {' '}
                                  Payment ID
                                </label>
                                <span className='ms-1'>
                                  <b>{incomeAPIDetails?.id ? incomeAPIDetails?.id : '-'}</b>
                                </span>
                              </h3>
                            )}
                          </div>

                          <div className='d-flex'>
                            <div className='d-block px-0 py-2'>
                              <div className='d-flex my-4 mt-6'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    Paid
                                  </label>
                                  <span className='ms-1'>
                                    {/* <b> */}
                                    {grandPaidAmount
                                      ? `AED ${grandPaidAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : 'AED 0'}
                                    {/* </b> */}
                                  </span>
                                </h4>
                              </div>

                              <div className='d-flex my-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    Outstanding
                                  </label>
                                  <span className='ms-1'>
                                    {/* <b> */}
                                    {grandTotalAmount
                                      ? `AED ${(grandTotalAmount - grandPaidAmount)?.toLocaleString(
                                          'en-US',
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}`
                                      : 'AED 0'}
                                    {/* </b> */}
                                  </span>
                                </h4>
                              </div>
                            </div>

                            {flg != '1' && (
                              <div className='ms-auto me-11'>
                                {incomeAPIDetails?.expenseStatus != null &&
                                incomeAPIDetails?.expenseStatus != undefined ? (
                                  <div className='my-6 me-2'>
                                    <div
                                      className={
                                        flg == '2'
                                          ? 'status pend-st  status-w-120'
                                          : incomeAPIDetails?.expenseStatus === 2
                                          ? 'status pending-approval-st status-w-120'
                                          : incomeAPIDetails?.expenseStatus === 1
                                          ? 'status approve-st status-w-120'
                                          : incomeAPIDetails?.expenseStatus === 0
                                          ? 'status cancelled-st status-w-120'
                                          : 'status pend-st  status-w-120'
                                      }
                                    >
                                      <>
                                        {flg == '2'
                                          ? '-'
                                          : incomeAPIDetails?.expenseStatus === 2
                                          ? 'Partially Paid'
                                          : incomeAPIDetails?.expenseStatus === 1
                                          ? 'Paid'
                                          : incomeAPIDetails?.expenseStatus === 0
                                          ? 'Unpaid'
                                          : '-'}
                                      </>
                                    </div>
                                    <i style={{paddingRight: '3px'}}>
                                      {incomeAPIDetails?.expenseStatusDateTime && flg != '2'
                                        ? `${moment
                                            .utc(incomeAPIDetails?.expenseStatusDateTime)
                                            .local()
                                            .format('DD.MM.YYYY - hh:mm A')}`
                                        : '-'}
                                    </i>
                                  </div>
                                ) : (
                                  <div className='mb-7 text-end me-2'>
                                    <div className='status not-saved ms-auto mt-10 status-w-120'>
                                      -
                                    </div>
                                    <i style={{paddingRight: '3px'}}>
                                      {' '}
                                      {incomeAPIDetails?.expenseStatusDateTime
                                        ? `${moment
                                            .utc(incomeAPIDetails?.expenseStatusDateTime)
                                            .local()
                                            .format('DD.MM.YYYY - hh:mm A')}`
                                        : '-'}
                                    </i>
                                  </div>
                                )}

                                {incomeAPIDetails?.categoryDateTime ? (
                                  <div className='me-2'>
                                    <div
                                      className={
                                        flg == '2'
                                          ? 'status  req-bar status-w-120'
                                          : incomeAPIDetails?.category == 0
                                          ? 'status active-bar  status-w-120'
                                          : incomeAPIDetails?.category == 1
                                          ? 'status expired-bar  status-w-120'
                                          : incomeAPIDetails?.category == 2
                                          ? 'status complete-bar  status-w-120'
                                          : 'status  req-bar status-w-120'
                                      }
                                    >
                                      {flg == '2'
                                        ? '-'
                                        : incomeAPIDetails?.category == 0
                                        ? 'Upcoming'
                                        : incomeAPIDetails?.category == 1
                                        ? 'Overdue'
                                        : incomeAPIDetails?.category == 2
                                        ? 'Complete'
                                        : '-'}
                                    </div>
                                    <i style={{paddingRight: '3px'}}>
                                      {' '}
                                      {incomeAPIDetails?.categoryDateTime && flg != '2'
                                        ? `${moment
                                            .utc(incomeAPIDetails?.categoryDateTime)
                                            .local()
                                            .format('DD.MM.YYYY - hh:mm A')}`
                                        : ''}
                                    </i>
                                  </div>
                                ) : (
                                  <div className='status req-bar  mt-10 status-w-120'>-</div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Property & Lease Card*/}
                      <div className='col-12 px-5 mt-2'>
                        <div className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-4 px-6'}>
                          <div className='d-flex'>
                            <h2 className='top ps-0'>
                              <b>Property & Lease</b>
                            </h2>
                          </div>

                          <div className='row mx-1'>
                            <div className='col-4 px-0 mt-4'>
                              <div className='my-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Unit
                                  </label>
                                </h4>

                                <span className=''>
                                  <b>
                                    {incomeData?.payorData?.unit?.[0]?.unitNo
                                      ? incomeData?.payorData?.unit?.[0]?.unitNo
                                      : '-'}
                                  </b>
                                </span>
                              </div>
                            </div>

                            <div className='col-4 px-0 mt-4'>
                              <div className='my-4 align-items-center'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '130px'}} className='labl-gry '>
                                    {' '}
                                    Development
                                  </label>
                                </h4>

                                <span className=''>
                                  <b>
                                    {incomeData?.payorData?.community?.length > 0
                                      ? `${incomeData?.payorData?.community?.[0]?.name}`
                                      : incomeData?.payorData?.building?.length > 0
                                      ? `${incomeData?.payorData?.building?.[0]?.name}`
                                      : '-'}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='row mx-1'>
                            <div className='col-4 px-0 mt-1'>
                              <div className='mt-4 mb-2 d-flex align-items-end'>
                                <div>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                      Contract No.
                                    </label>
                                  </h4>

                                  <div className='d-flex align-items-center'>
                                    <span className=''>
                                      <b>
                                        {' '}
                                        {incomeData?.payorData?.contractNo
                                          ? `${incomeData?.payorData?.contractNo}`
                                          : '-'}
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-4 px-0 mt-1'>
                              <div className='my-4 d-flex align-items-end'>
                                <div>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                      Lease Value
                                    </label>
                                  </h4>

                                  <div className='d-flex align-items-center'>
                                    <span className=''>
                                      <b>
                                        {Number(incomeData?.payorData?.totalAmount) > 0
                                          ? `AED ${incomeData?.payorData?.totalAmount?.toLocaleString(
                                              'en-US',
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}`
                                          : 'AED  -'}
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-4 px-0 mt-1'>
                              <div className='my-4 d-flex align-items-end'>
                                <div>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{width: '130px'}} className='labl-gry '>
                                      {' '}
                                      Payments Created
                                    </label>
                                  </h4>

                                  <div className='d-flex align-items-center'>
                                    <span className=''>
                                      <b>
                                        {Number(incomeData?.payment?.length) > 0
                                          ? `${incomeData?.payment?.length}`
                                          : '0'}
                                      </b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-12 col-md-12 px-5 mt-2 ps-6'>
                        <h4 className='my-2 mb-3' style={{color: 'black'}}>
                          <label className={`labl-gry`}> Notes</label>
                        </h4>

                        <h4>
                          <textarea
                            className='form-control form-control-solid mytest bg-white'
                            placeholder='Enter payment related notes'
                            name='message'
                            value={incomeData?.notes}
                            style={{minHeight: '150px'}}
                            onChange={(e: any) => {
                              let values = {...incomeData}
                              let newValue: any =
                                e.target.value != ''
                                  ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                                  : e.target.value
                              values.notes = newValue

                              setIncomeData(values)
                            }}
                            onBlur={() => {
                              if (flg == '2') {
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700)
                              }
                            }}
                            autoComplete='off'
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                 {/* Payment Items Card */}
                {incomeData && refreshChild && (
                  <PaymentItems
                    incmData={incomeData}
                    updateParent={(data: any) => {
                      let values: any = {...incomeData}
                      values.items = data
                      setIncomeData(values)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                    updateParentAfterDelete={(data: any, idsToDlt: any) => {
                      let values: any = {...incomeData}
                      values.items = data
                      setIncomeData(values)

                      let deleteIDs: any = idToDelete

                      idsToDlt?.map((id: any, ind: any) => {
                        deleteIDs.push({_id: id})
                      })

                      setIDToDelete(deleteIDs)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                    
                    isEdit={true}
                    // updateLatePaymentType={(val: any) => {
                    //   let values = {...incomeData}
                    //   values.latePaymentType = val
                    //   // console.log(values)
                    //   setIncomeData(values)
                    // }}
                    updateLatePaymentType={(
                      val: any,
                      data: any,
                      lateFee: any,
                      deleteItemsIds: any
                    ) => {
                      setIDToDelete(deleteItemsIds)
                      let values = {...incomeData}
                      values.latePaymentType = val
                      values.items = data
                      values.frontendStorage.lateFeeForAll = lateFee
                      setIncomeData(values)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                    doNotApplyLatePaymentType={(
                      val: any,
                      data: any,
                      deleteItemsIds: any,
                      lateFee: any
                    ) => {
                      setIDToDelete(deleteItemsIds)
                      let values = {...incomeData}

                      values.items = data
                      values.frontendStorage.lateFeeForAll = lateFee

                      values.latePaymentType = val

                      setIncomeData(values)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                    incmAPIDetails={incomeAPIDetails}
                    updateParentForAll={(data: any, lateFee: any, deleteItemsIds: any) => {
                      setIDToDelete(deleteItemsIds)
                      let values: any = {...incomeData}
                      values.items = data
                      values.frontendStorage.lateFeeForAll = lateFee
                      setIncomeData(values)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                  />
                )}

                {/* Invoices Card */}
                {incomeData && (
                  <Invoices
                    incmData={incomeData}
                    updateParent={(val: any) => {}}
                    autoSaveParent={(val: any) => {}}
                    updateParentToGenerateInvoice={() => {
                      addIncomePayment(0, 'invoice')
                    }}
                    deleteInvoice={(id: any) => {
                      let values: any = [...deleteInvoiceIds]
                      values?.push({
                        _id: id,
                      })

                      setDeleteInvoiceIds(values)

                      if (flg == '2') {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }
                    }}
                    incmAPIDetails={incomeAPIDetails}
                  />
                )}

                {/* Payment Records and Receipt Card */}
                {incomeData && refreshChild && (
                  <PaymentRecords
                    incmData={incomeData}
                    updateParent={(val: any, valItm: any) => {
                      let values = {...incomeData}
                      values.paymentRecords = val
                      values.items = valItm

                      setIncomeData(values)
                    }}
                    autoSaveParent={(val: any) => {
                      if (flg == '2') addPaymentRecord(id, val)
                    }}
                    autoUpdateParent={(val: any) => {
                      if (flg == '2') {
                        updatePaymentRecord(id, val)
                      }
                    }}
                    updateItemsInParent={(val: any) => {}}
                    refreshChild={refreshChild}
                    updateParentAfterDelete={() => getPaymentById()}
                  />
                )}

                <div className='row mx-1 d-flex'>
                  <div
                    className='col-12 col-md-6'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    {incomeData && refreshChild && (
                      <PaymentReminders
                        incmData={incomeData}
                        updateParent={(val: any) => {
                          let values = {...incomeData}
                          values.reminders = val
                          setIncomeData(values)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                        deleteReminder={(data: any) => {
                          deleteReminder(data)
                        }}
                      />
                    )}
                  </div>
                  <div
                    className='col-12 col-md-6'
                    style={{display: 'flex', flexDirection: 'column'}}
                  >
                    {incomeData && (
                      <OtherAttachments
                        incmData={incomeData}
                        updateParent={(val: any) => {
                          let values = {...incomeData}
                          values.attachments = val
                          setIncomeData(values)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {/* Select Payor Modal */}
      {showSelectPayor && incomeData && (
        <SelectPayorModal
          show={showSelectPayor}
          handleClose={() => {
            setShowSelectPayor(false)
          }}
          updateDetails={(data: any) => {
            let values = {...incomeData}
            values.payorData = data

            setIncomeData(values)

            setRefreshChild(Date.now())

            if (flg == '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          expData={incomeData}
        />
      )}

      {showInvoiceAdd && mode && (
        <AddInvoice
          show={showInvoiceAdd}
          handleClose={() => setShowInvoiceAdd(false)}
          mode={mode}
          updateData={(data: any) => {
            let values = {...incomeData}
            if (mode == 'add') values.invoices.push(data)
            else values.invoices[selectedInd] = data
            setIncomeData(values)
            setShowInvoiceAdd(false)

            if (flg == '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          selectedItem={selectedRecord}
        />
      )}

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default AddIncomePayment

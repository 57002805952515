import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut, ApiUpload} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import AddUnits from './AddUnits'
import appartment from '../../../img/Apartment.svg'
import penthouse from '../../../img/PentHouse.svg'
import noData from '../../../img/NoData1.svg'
import common_area from '../../../img/common_area.svg'
import other from '../../../img/other.svg'
import {TbChevronDown} from 'react-icons/tb'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../apiCommon/helpers/AssetsHelpers'
import {Col, Dropdown, Row} from 'react-bootstrap'
import {BsExclamationCircleFill} from 'react-icons/bs'
import ReactPaginate from 'react-paginate'
import info from '../../../img/info.svg'
import rightarrow from '../../../img/rightArrow.svg'

const Units = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [propertiType, setPropertiType] = useState('Apartment')
  const [show, setShow] = useState(false)
  const [showPen, setShowPen] = useState(false)
  const [showCom, setShowCom] = useState(false)
  const [showOth, setShowOth] = useState(false)
  const [imgUrl, setImgUrl] = useState([])
  const [header, setHeader] = useState<any>({})
  const [headerB, setHeaderB] = useState<any>([])
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [page, setPage] = useState<any>(1)
  console.log('🚀 ~ file: Building.tsx ~ line 10 ~ Building ~ updateDataId', updateDataId)
  const [isEdit, setIsEdit] = useState(false)
  console.log('🚀 ~ file: Building.tsx ~ line 12 ~ Building ~ isEdit', isEdit)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  console.log('formData', imgUrl)

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }
  let ClusterId = headerB?.clusterId
  let CommunityId = headerB?.communityId
  console.log('111111111111111111111', ClusterId, CommunityId)
  // const building = async () => {
  //   const body = {
  //     page: 1,
  //     limit: 10,
  //     floorId: window.location.pathname?.split('/')[3],
  //   }
  //   await ApiPost(`corporate/unit/get`, body)
  //     .then((res) => {
  //       setTableData(res?.data?.data?.unit_data)
  //       console.log('res', res)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }
  const building = async () => {
    const body = {
      page: page,
      limit: 10,
      floorId: window.location.pathname?.split('/')[3],
    }
    await ApiPost(`corporate/unit/get`, body)
      .then((res) => {
        setTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        console.log('res', res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const deleteProperty = (id: any) => {
    console.log('deleteProperty', id)
    ApiDelete(`corporate/unit/${id}`)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        building()
      })
      .catch((err) => ErrorToast(err.message))
  }
  const imageChange = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/apartment', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangePen = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/penthouse', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangeCom = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/common_area', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangeOth = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/other', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const handleSubmit = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5', images: [imgUrl]}
      const body = {
        unitNo: formData?.unitNo,
        bedrooms: formData?.bedrooms,
        size: formData?.size,
        sizeType: formData?.sizeType,
        occupy: formData?.OccupancyType,
        images: imgUrl,
        unitType: 'apartment',
        premiseNo: formData?.premiseNo,
        managerId: formData?.managerId,
        // propertyId: formData?.propertyId,
        propertyAreaId: formData?.floorId,
      }
      ApiPost('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    }
  }
  const handleSubmitPen = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        unitNo: formData?.unitNo,
        bedrooms: formData?.bedrooms,
        size: formData?.size,
        sizeType: formData?.sizeType,
        occupy: formData?.OccupancyType,
        unitType: 'penthouse',
        images: [imgUrl],
        premiseNo: formData?.premiseNo,
        managerId: formData?.propertyManager,
        // propertyId: formData?.propertyId,
        propertyAreaId: formData?.floorId,
      }
      ApiPost('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    }
  }
  const handleSubmitCom = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        unitNo: formData?.commonAreaName,
        occupy: 0,
        unitType: 'common_area',
        images: [imgUrl],
        premiseNo: formData?.premiseNo,
        managerId: formData?.propertyManager,
        propertyId: formData?.propertyId,
        propertyAreaId: formData?.floorId,
      }
      ApiPost('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    }
  }
  const handleSubmitOth = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        unitNo: formData?.unitNo,
        bedrooms: formData?.bedrooms,
        size: formData?.size,
        sizeType: formData?.sizeType,
        occupy: formData?.OccupancyType,
        images: [imgUrl],
        unitType: formData?.unitType,
        premiseNo: formData?.premiseNo,
        managerId: formData?.propertyManager,
        propertyId: formData?.propertyId,
        propertyAreaId: formData?.floorId,
      }
      ApiPost('corporate/properties_area_unit', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  const getHeaderfData = () => {
    ApiGet(`corporate/floor/${formData?.propertyAreaId}`)
      .then((res) => {
        console.log('res', res?.data?.data)
        setHeader(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }
  const getHeaderbData = () => {
    ApiGet(`corporate/building/${formData?.propertyId}`)
      .then((res) => {
        console.log('res', res?.data?.data)
        setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    building()
    getHeaderfData()
    getHeaderbData()
    if (isEdit) {
      ApiGet(`corporate/properties_area_unit/${updateDataId}`)
        .then((response) => {
          console.log('response', response)
          setFormData(response?.data?.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [isEdit, page])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }
  console.log('\ntableData345', tableData)
  const navigate = useNavigate()


  // 
  const createTenant = (val: any) => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID',  res?.data?.data?._id)
        navigate('/create-tenancy/1',{
          state: {tenancyData: val,
            tenantData: res?.data?.data
           }
        })
      })
      .catch((err) => ErrorToast(err.message))
  }

  // 
  const createTenancy = ()=> {
    ApiPost('corporate/tenancy')
    .then((res) => {
      localStorage.setItem('newTenancyID', res?.data?.data?._id)
      createTenant(res?.data?.data)

    })
    .catch((err) => ErrorToast(err.message))
  }


  return (
    <>
      <div
        className='app-container container-xxl mx-auto d-flex gap-5 flex-column p-0 m-0'
        id='kt_app_main'
      >
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='d-flex m-0 p-0 gap-2 lists align-items-center'>
            <li className=''>
              <a className='gray_color px-2 cursor-pointer ' onClick={() => navigate('/dashboard')}>
                Manage Properties
              </a>
            </li>
            <li className='my-auto'>
              <img src={rightarrow} />
            </li>
            <li className=''>
              <a className='gray_color px-2 cursor-pointer' onClick={() => navigate('/building')}>
                Building
              </a>
            </li>
            <li className='my-auto'>
              <img src={rightarrow} />
            </li>
            <li className='my-auto'>
              <a className='gray_color px-2 cursor-pointer' onClick={() => navigate(-1)}>
                Floor
              </a>
            </li>
            <li className='my-auto'>
              <img src={rightarrow} />
            </li>
            <li className='active'>
              <a className='green_color' style={{borderBottom: '1px solid #007a59'}}>
                Unit
              </a>
            </li>
          </ul>
          {/* <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer' onClick={() => navigate('/dashboard')}>
                Manage Properties
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer' onClick={() => navigate('/building')}>
                Communities
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                // onClick={() => navigate('/building')}
                // onClick={() =>
                //   navigate(`/areaCom/${window.location.pathname.split('/')[2]}`)
                // }
              >
                Buildings
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                // onClick={() => navigate('/building')}
                onClick={() => navigate(`/area/${window.location.pathname.split('/')[2]}`)}
              >
                Floor
              </a>
            </li>
            <li className='breadcrumb-item  active'>
              <a className='px-2'>Unit </a>
            </li>
          </ul> */}
        </div>
        <div className='card p-5 flex-row justify-content-between'>
          <h1 className='m-0 green_color'>Units</h1>
          <a
            // onClick={() => setShowCreateAppModal(true)}
            className='btn btn-sm fw-bold btn-primary btn-green'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_create_app'
            onClick={() => {
              // localStorage.setItem('propertyId', window.location.pathname?.split('/')[2])
              // localStorage.setItem('floorId', window.location.pathname?.split('/')[3])
              navigate(
                `/unitform/${window.location.pathname?.split('/')[2]}/${
                  window.location.pathname?.split('/')[3]
                }`
              )
            }}
          >
            Add Unit
          </a>
        </div>
        <div className='card p-5 text-muted'>
          <div className='side'>
            <h6 className='text-muted mx-0 align-items-center'>
              Building Name
              <div className='mx-1 value_black'> {headerB?.name}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Floor
              <div className='mx-1 value_black'> {header?.name}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Total Properties <div className='mx-1 value_black'> {header?.totalProperties}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Total Apartments <div className='mx-1 value_black'> {header?.totalApartments}</div>
            </h6>
          </div>
          <div className='side'>
            <h6 className='text-muted mx-0 align-items-center'>
              Total Penthouses <div className='mx-1 value_black'>{header?.totalPenthouses}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Total Common Areas <div className='mx-1 value_black'>{header?.totalCommonAreas}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Total Other <div className='mx-1 value_black'> {header?.totalOthers}</div>
            </h6>
            <h6 className='text-muted mx-0 align-items-center'>
              Building Manager <div className='mx-1 value_black'> {header?.managerId}</div>
            </h6>
          </div>
        </div>

        {show && propertiType == 'Apartment' && (
          <>
            <div className='card grid-out-diff-gap p-5'>
              <h3 className='green_color tops'>Add New Apartment</h3>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Upload Image
                  <div className='mx-1 value_black'>
                    <input
                      type='file'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      onChange={imageChange}
                      // value={formData?.unitNo}
                      // onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Unit No
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      value={formData?.unitNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Bedrooms
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Bedrooms'
                      name='bedrooms'
                      value={formData?.bedrooms}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Size'
                      name='size'
                      value={formData?.size}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size Type
                  <div className='mx-1 value_black'>
                    <select
                      name='sizeType'
                      className='form-select form-select-solid'
                      value={formData?.sizeType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Size Type
                      </option>
                      <option value={0}>Meter</option>
                      <option value={1}>Sequre Meter</option>
                    </select>
                  </div>
                </h6>
              </div>
              <div className='side py-3 '>
                <h6 className='text-muted mx-0 align-items-center'>
                  Premise No
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Premise No'
                      name='premiseNo'
                      value={formData?.premiseNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Id
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Id'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Occupancy
                  <div className='mx-1 value_black'>
                    <select
                      name='OccupancyType'
                      className='form-select form-select-solid'
                      value={formData?.OccupancyType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Occupancy
                      </option>
                      <option value={0}>Vacant</option>
                      <option value={1}>Occupied</option>
                    </select>
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Manager
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Name'
                      name='propertyManager'
                      value={formData?.propertyManager}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_create_app'
                    onClick={handleSubmit}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {showPen && propertiType == 'Penthouse' && (
          <>
            <div className='card grid-out-diff-gap p-5'>
              <h3 className='green_color tops'>ADD NEW PENTHOUSE</h3>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Upload Image
                  <div className='mx-1 value_black'>
                    <input
                      type='file'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      onChange={imageChangePen}
                      // value={formData?.unitNo}
                      // onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Unit No
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      value={formData?.unitNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Bedrooms
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Bedrooms'
                      name='bedrooms'
                      value={formData?.bedrooms}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Size'
                      name='size'
                      value={formData?.size}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size Type
                  <div className='mx-1 value_black'>
                    <select
                      name='sizeType'
                      className='form-select form-select-solid'
                      value={formData?.sizeType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Size Type
                      </option>
                      <option value={0}>Meter</option>
                      <option value={1}>Sequre Meter</option>
                    </select>
                  </div>
                </h6>
              </div>
              <div className='side py-3 '>
                <h6 className='text-muted mx-0 align-items-center'>
                  Premise No
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Premise No'
                      name='premiseNo'
                      value={formData?.premiseNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Id
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Id'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Occupancy
                  <div className='mx-1 value_black'>
                    <select
                      name='OccupancyType'
                      className='form-select form-select-solid'
                      value={formData?.OccupancyType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Occupancy
                      </option>
                      <option value={0}>Vacant</option>
                      <option value={1}>Occupied</option>
                    </select>
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Manager
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Name'
                      name='propertyManager'
                      value={formData?.propertyManager}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_create_app'
                    onClick={handleSubmitPen}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {showCom && propertiType == 'CommonArea' && (
          <>
            <div className='card grid-out-diff-gap p-5'>
              <h3 className='green_color tops'>ADD NEW COMMON AREA</h3>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Upload Image
                  <div className='mx-1 value_black'>
                    <input
                      type='file'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      onChange={imageChangeCom}
                      // value={formData?.unitNo}
                      // onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Common Area Name
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Common Area Name'
                      name='commonAreaName'
                      value={formData?.commonAreaName}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Size'
                      name='size'
                      value={formData?.size}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Id
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Id'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>

                <h6 className='text-muted mx-0 align-items-center'>
                  Property Manager
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Name'
                      name='propertyManager'
                      value={formData?.propertyManager}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_create_app'
                    onClick={handleSubmitCom}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {showOth && propertiType == 'Other' && (
          <>
            <div className='card grid-out-diff-gap p-5'>
              <h3 className='green_color tops'>ADD NEW OTHER AREA</h3>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Upload Image
                  <div className='mx-1 value_black'>
                    <input
                      type='file'
                      className='form-control form-control-solid w-auto'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      onChange={imageChangeOth}
                      // value={formData?.unitNo}
                      // onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Unit No
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Unit No'
                      name='unitNo'
                      value={formData?.unitNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Bedrooms
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Bedrooms'
                      name='bedrooms'
                      value={formData?.bedrooms}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>

                <h6 className='text-muted mx-0 align-items-center'>
                  Size
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Size'
                      name='size'
                      value={formData?.size}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Size Type
                  <div className='mx-1 value_black'>
                    <select
                      name='sizeType'
                      className='form-select form-select-solid'
                      value={formData?.sizeType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Size Type
                      </option>
                      <option value={0}>Meter</option>
                      <option value={1}>Sequre Meter</option>
                    </select>
                  </div>
                </h6>
              </div>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Premise No
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Premise No'
                      name='premiseNo'
                      value={formData?.premiseNo}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Id
                  <div className='mx-1 value_black'>
                    <input
                      type='number'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Id'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Occupancy
                  <div className='mx-1 value_black'>
                    <select
                      name='OccupancyType'
                      className='form-select form-select-solid'
                      value={formData?.OccupancyType}
                      onChange={handleChnage}
                    >
                      <option disabled selected>
                        Select Occupancy
                      </option>
                      <option value={0}>Vacant</option>
                      <option value={1}>Occupied</option>
                    </select>
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Property Manager
                  <div className='mx-1 value_black'>
                    <input
                      type='text'
                      className='form-control form-control-solid'
                      placeholder='Enter Manager Name'
                      name='propertyManager'
                      value={formData?.propertyManager}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Unit Type
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      name='unitType'
                      placeholder='Enter Unit Type'
                      value={formData?.unitType}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_create_app'
                    onClick={handleSubmitOth}
                  >
                    Save
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='card p-5'>
          <div className='table-responsive mb-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  {/* <th className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='true'
                            data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                            value='1'
                          />
                        </div>
                      </th> */}
                  <th className='text-center min-w-100px'>#</th>
                  <th className='text-center min-w-100px'>Property ID</th>
                  <th className='text-center min-w-100px'>Unit No.</th>
                  <th className='text-center min-w-100px'>Unit Type</th>
                  <th className='text-center min-w-100px'>Bedrooms</th>
                  <th className='text-center min-w-100px'>Size</th>
                  <th className='text-center min-w-100px'>Premise No.</th>
                  <th className='text-center min-w-100px'>Occupancy</th>
                  <th className='text-center min-w-150px'>Property Manager</th>
                  <th className='text-center min-w-200px'>Tenancy Status</th>
                  <th className='text-end min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-800'>
                {tableData?.length ? (
                  tableData?.map((v: any, i: any) => {
                    return (
                      <tr>
                        {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input' type='checkbox' value='1' />
                        </div>
                      </td> */}
                        <td className='text-center'>{(page - 1) * 10 + (i + 1)}</td>
                        <td data-kt-ecommerce-order-filter='order_id' className='text-center'>
                          {v?.id}
                        </td>
                        <td className='text-center'>{v?.unitNo}</td>
                        <td className='text-center'>
                          {v?.unitType === 'apartment'
                            ? 'Apartment'
                            : v?.unitType === 'penthouse'
                            ? 'Penthouse'
                            : v?.unitType === 'common_area'
                            ? 'Common Area'
                            : 'Other'}
                        </td>
                        <td className='text-center'>
                          {v?.unitType === 'common_area' ? '-' : v?.bedrooms}
                        </td>
                        <td className='text-center'>
                          {v?.unitType === 'common_area' ? '-' : v?.size}{' '}
                          <span className='fst-italic'>
                            {v?.unitType === 'common_area'
                              ? ''
                              : v?.sizeType == '0'
                              ? 'Sq ft'
                              : 'sq m'}
                          </span>
                        </td>
                        <td className='text-center'>
                          {v?.unitType === 'common_area' ? '-' : v?.premiseNo}
                        </td>
                        <td className='text-center'>
                          {v?.unitType === 'common_area'
                            ? '-'
                            : v?.occupy == '0'
                            ? 'Vacant '
                            : 'Occupied'}
                        </td>
                        <td className='text-center'>{v?.managerId}</td>
                        { v?.unitType === 'common_area' ? (
                           <td className='text-center'>-</td>
                        
                        )
                        :
                        (
                        <td className='text-center' style={{border: 'none'}}>
                          {v?.id !== null ? (
                            (v?.occupy == '0' && v?.tenancyStatus === 3) ||
                            (v?.occupy == '0' && v?.tenancyStatus === 5) ? (
                              <a
                                // onClick={() => setShowCreateAppModal(true)}
                                className='btn btn-sm fw-bold btn-primary btn-green'
                                // data-bs-toggle='modal'
                                // data-bs-target='#kt_modal_create_app'
                                style={{width: '145px'}}
                                // onClick={handleSubmitCom}
                                onClick={() => {
                                  // setShowCreateAppModal(true)
                                  localStorage.setItem(
                                    'CommunityId',
                                    window.location.pathname?.split('/')[2]
                                  )
                                  localStorage.setItem(
                                    'ClusterId',
                                    window.location.pathname?.split('/')[3]
                                  )
                                  localStorage.setItem('UnitId', v?._id)
                                  localStorage.setItem('isDraft', '2')

                                  createTenancy()

                                  // navigate(`/create-tenancy/1`)
                                }}
                              >
                                Create Tenancy
                              </a>
                            ) : (v?.occupy == '1' && v?.tenancyStatus === 3) ||
                              (v?.occupy == '1' && v?.tenancyStatus === 5) ? (
                              <div className='d-flex align-items-center gap-2 justify-content-center'>
                                <span className='svg-icon svg-icon-2x svg-icon-warning ms-2'>
                                  <img src={info} alt='info' className='rotate' />
                                  {/* <svg
                                    // width='24'
                                    // height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    stroke-width='3'
                                    style={{
                                      border: '1px solid var(--kt-text-warning)',
                                      borderRadius: '50%',
                                    }}
                                  >
                                    <rect
                                      opacity='0.3'
                                      x='2'
                                      y='2'
                                      width='24'
                                      height='24'
                                      rx='10'
                                    ></rect>
                                    <rect
                                      x='11'
                                      y='14'
                                      width='10'
                                      height='4'
                                      rx='1'
                                      stroke-width='3'
                                      transform='rotate(-90 11 14)'
                                      fill='currentColor'
                                    ></rect>
                                    <rect
                                      x='11'
                                      y='17'
                                      width='5'
                                      height='5'
                                      rx='1'
                                      stroke-width='3'
                                      transform='rotate(-90 11 17)'
                                      fill='currentColor'
                                    ></rect>
                                  </svg> */}
                                </span>
                                <a
                                  // onClick={() => setShowCreateAppModal(true)}
                                  className='btn btn-sm fw-bold btn-primary btn-green'
                                  // data-bs-toggle='modal'
                                  // data-bs-target='#kt_modal_create_app'
                                  // onClick={handleSubmitCom}
                                  style={{width: '145px'}}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'CommunityId',
                                      window.location.pathname?.split('/')[2]
                                    )
                                    localStorage.setItem(
                                      'BuildingId',
                                      window.location.pathname?.split('/')[3]
                                    )
                                    localStorage.setItem('UnitId', v?._id)
                                    localStorage.setItem('isDraft', '2')
                                    // setShowCreateAppModal(true)
                                    // setPropertiType('tenancy')
                                    navigate(`/create-tenancy/1`)
                                  }}
                                >
                                  Create Tenancy
                                </a>
                              </div>
                            ) : v?.tenancyStatus === 0 ? (
                              'Draft'
                            ) : v?.tenancyStatus === 1 ? (
                              'Active'
                            ) : v?.tenancyStatus === 2 ? (
                              'Expiring'
                            ) : (
                              'Renewed'
                            )
                          ) : (
                            '-'
                          )}
                        </td>
                        )}

                        <td className='text-end ' style={{justifyContent: 'end', border: 'none'}}>
                          <Dropdown className='' style={{width: 'fit-content'}}>
                            <Dropdown.Toggle
                              variant='success'
                              id='dropdown-basic'
                              className='btn btn-sm btn-light btn-active-light-primary text-hover-green'
                            >
                              Actions <TbChevronDown />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href='#/action-1'
                                onClick={() =>
                                  navigate(
                                    `/unitUpform/${window.location.pathname?.split('/')[2]}/${
                                      window.location.pathname?.split('/')[3]
                                    }/${v?._id}`
                                  )
                                }
                                className='menu-link px-3'
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                href='#/action-2'
                                onClick={() => {
                                  swal
                                    .fire({
                                      text: 'Are you sure you want to permanently delete this building? Deleting this building will delete all the data registered under the building.',
                                      icon: 'warning',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#D72852',
                                      confirmButtonText: 'Yes',
                                      showCancelButton: true,
                                      // cancelButtonColor: "transparent",
                                      cancelButtonText: 'Cancel',
                                    })
                                    .then((res) => {
                                      if (res.isConfirmed) {
                                        deleteProperty(v?._id)
                                      }
                                    })
                                }}
                                className='menu-link px-3'
                                data-kt-ecommerce-order-filter='delete_row'
                              >
                                Delete{' '}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* <a
                              className='btn btn-sm btn-light btn-active-light-primary text-hover-green'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              <span className='text-hover-green'>Actions</span>
                              <span className='svg-icon svg-icon-5 m-0 text-hover-green'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            </a>
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              <div className='menu-item px-3'>
                                <a
                                  onClick={() => {
                                    console.log('clicked')

                                    setUpdateDataId(v?._id)
                                    setIsEdit(true)
                                    setShowCreateAppModal(true)
                                  }}
                                  className='menu-link px-3'
                                >
                                  Edit
                                </a>
                              </div>
                              <div className='menu-item px-3'>
                                <a
                                  // onClick={() => deleteProperty(v?._id)}
                                  onClick={() => {
                                    swal
                                      .fire({
                                        text: 'Are you sure you want to permanently delete this building? Deleting this building will delete all the data registered under the building.',
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        // cancelButtonColor: "transparent",
                                        cancelButtonText: 'Cancel',
                                      })
                                      .then((res) => {
                                        if (res.isConfirmed) {
                                          deleteProperty(v?._id)
                                        }
                                      })
                                  }}
                                  className='menu-link px-3'
                                  data-kt-ecommerce-order-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                            </div> */}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
              {/* <div className='dataTables_length' id='kt_ecommerce_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_sales_table_length'
                    aria-controls='kt_ecommerce_sales_table'
                    className='form-select form-select-sm form-select-solid'
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div> */}
            </div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddUnits
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        building={building}
        updateDataId={updateDataId}
        isEdit={isEdit}
        formData={formData}
        propertiType={propertiType}
        handleSubmit={handleSubmit}
        handleChnage={handleChnage}
        imageChange={imageChange}
      />
    </>
  )
}

export default Units

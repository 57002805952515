import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import noData from '../../../img/NoData1.svg'

let removeVendorList: any = []

function EditVendor() {
  const [formData, setFormData] = useState<any>([])
  const navigate = useNavigate()
  const [removeList, setRemoveList] = React.useState<any>()
  const [checkedState, setCheckedState] = useState<any>()
  const [search, setSearch] = useState<any>()

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const handleChnageSelect = (e: any) => {
    const {name, value} = e.target
    setSearch({...search, [name]: value})
  }

  const getVendorListById = () => {
    ApiGet(
      `corporate/vendor_list/${window.location.pathname?.split('/')[4]}?searchVendorName=${
        search?.name ? search?.name : ''
      }`
    )
      .then((res) => {
        setFormData(res?.data?.data[0])
        setRemoveList(res?.data?.data[0]?.vendorIds)
        setCheckedState(new Array(res?.data?.data[0]?.vendorIds.length).fill(false))
      })
      .catch((err) => ErrorToast(err.message))
  }

  React.useEffect(() => {
    getVendorListById()
  }, [search?.name])

  const removeVendor = (i: any, index: any) => {
    const updatedCheckedState = checkedState.map((item: any, i: any) =>
      i === index ? !item : item
    )
    setCheckedState(updatedCheckedState)

    if (removeVendorList.includes(i?._id)) {
      removeVendorList = removeVendorList.filter((item: any) => item !== i?._id)
    } else {
      removeVendorList.push(i?._id)
    }
  }

  const deleteVendor = () => {
    const body = {
      vendorIds: removeVendorList,
      vendorListId: window.location.pathname?.split('/')[4],
    }
    ApiPost(`corporate/vendor_list/remove_vendor`, body)
      .then((res) => {
        SuccessToast(res?.data.message)
        getVendorListById()
      })
      .catch((err) => ErrorToast(err.message))
  }

  const EditVendorList = () => {
    const body = {
      id: window.location.pathname?.split('/')[4],
      totalVendor: removeList?.length,
      comment: formData?.comment,
      name: formData?.name,
      vendorIds: removeList,
    }
    ApiPut(`corporate/vendor_list`, body)
      .then((res) => {
        SuccessToast(res?.data.message)
        navigate('/vendor-management/management-list')
      })
      .catch((err) => ErrorToast(err.message))
  }

  const deleteVendorList = () => {
    ApiDelete(`corporate/vendor_list/${window.location.pathname?.split('/')[4]}`)
      .then((res) => {
        SuccessToast(res?.data.message)
        navigate('/vendor-management/management-list')
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate('/vendor-management/management-list')}
              >
                Vendorlist
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer'> {formData?.name}</a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Edit Vendorlist</a>
            </li>
          </ul>
        </div>

        <div className='card flex-row justify-content-between p-5'>
          <div className='d-flex align-items-center gap-5'>
            <div
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() => navigate(`/vendor-management/management-list`)}
            >
              Back
            </div>
            <div
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() =>
                Swal.fire({
                  html: '<p class="text-start m-0">"Do you wish to save your updates?</p>',
                  showConfirmButton: true,
                  confirmButtonColor: '#D72852',
                  confirmButtonText: 'Yes',
                  showCancelButton: true,
                  cancelButtonText: 'Cancel',
                }).then((res) => {
                  if (res.isConfirmed) {
                    EditVendorList()
                  }
                })
              }
            >
              Save
            </div>
          </div>
          <div
            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
            onClick={() =>
              Swal.fire({
                html: '<p class="text-start m-0">Are you sure you want to permenantly delete this list?</p>',
                showConfirmButton: true,
                confirmButtonColor: '#D72852',
                confirmButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
              }).then((res) => {
                if (res.isConfirmed) {
                  deleteVendorList()
                }
              })
            }
          >
            Delete
          </div>
        </div>
        <div className='card p-5 my-5'>
          <div className='mx-0 text-muted align-items-start gap-5'>
            <div className='mx-0 p-0 text-muted align-items-center'>
              <label htmlFor='exampleFormControlInput1' className='form-label m-0'>
                <h6 className='p-0 m-0 text-muted align-items-center'>List Name</h6>
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder=''
                name='name'
                onChange={handleChnage}
                value={formData?.name}
              />
            </div>
            <div className='mx-0 p-0 text-muted align-items-start'>
              <label htmlFor='exampleFormControlInput1' className='form-label m-0'>
                <h6 className='p-0 m-0 text-muted align-items-center'>Comments</h6>
              </label>
              <textarea
                className='form-control form-control-solid min'
                placeholder='Type Your Notes....'
                name='comment'
                onChange={handleChnage}
                value={formData?.comment}
              />
            </div>
          </div>
          <div className='mx-0 text-muted align-items-center gap-5'>
            <h4 className='mx-0 p-0 text-muted align-items-center' style={{color: '#B5B5C3'}}>
              <h6 className='p-0 m-0 text-muted align-items-center'>Total Vendors</h6>
              <span className='' style={{color: 'black'}}>
                {formData?.vendor?.length}
              </span>
            </h4>
          </div>
        </div>
        <div className='card p-5'>
          <div className='mx-0 text-muted align-items-center gap-5'>
            <h4 className='mx-0 p-0 text-muted align-items-center' style={{color: '#B5B5C3'}}>
              <label htmlFor='exampleFormControlInput1' className=' form-label m-0'>
                <h6 className='p-0 m-0 text-muted align-items-center'>Search Vendor</h6>
              </label>
              <span className='' style={{color: 'black'}}>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='name'
                  onChange={handleChnageSelect}
                />
              </span>
            </h4>
            <div className='d-flex align-items-center justify-content-end'>
              <div
                className='btn btn-sm fw-bold btn-primary btn-green hover-none min-w-100px'
                onClick={() =>
                  Swal.fire({
                    html: '<p class="text-start m-0">Are you sure you want to remove the selected vendor from the list?</p>',
                    showConfirmButton: true,
                    confirmButtonColor: '#D72852',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    // cancelButtonColor: "transparent",
                    cancelButtonText: 'Cancel',
                  }).then((res) => {
                    if (res.isConfirmed) {
                      deleteVendor()
                    }
                  })
                }
              >
                Remove From List
              </div>
            </div>
          </div>
          {/* <Row className='justify-content-between mb-5'>
              <Col md={6} className='d-flex align-items-center me-10'>
                <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>
                  Search Vendor
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='name'
                  onChange={handleChnageSelect}
                />
                 <select
                                    name='searchVendor'
                                    className='form-select form-select-solid'
                                    onChange={handleChnageSelect}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    {formData?.vendor?.map((item: any, i: any) => {
                                        return (
                                            <option value={item?._id}>{item?.firstName}</option>)
                                    })}
                                </select>
              </Col>
              <div
                className='btn btn-sm w-auto d-flex align-items-center fw-bold btn-primary btn-green hover-none'
                onClick={() =>
                  Swal.fire({
                    html: '<p class="text-start m-0">Are you sure you want to remove the selected vendor from the list?</p>',
                    showConfirmButton: true,
                    confirmButtonColor: '#D72852',
                    confirmButtonText: 'Yes',
                    showCancelButton: true,
                    // cancelButtonColor: "transparent",
                    cancelButtonText: 'Cancel',
                  }).then((res) => {
                    if (res.isConfirmed) {
                      deleteVendor()
                    }
                  })
                }
              >
                Remove From List
              </div>
            </Row> */}
          <div className='table-responsive'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  {/* <th className='text-center min-w-10px'>
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                            </div>
                                        </th> */}
                  <th></th>
                  <th className='min-w-100px'>Vendor ID</th>
                  <th className='min-w-100px'>Vendor Name</th>
                  <th className='min-w-70px'>Type</th>
                  <th className='min-w-100px'>TRN</th>
                  <th className='min-w-100px'>Trade License</th>
                  <th className='min-w-100px'>City</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {formData?.vendor?.length ? (
                  formData?.vendor?.map((item: any, i: any) => {
                    return (
                      <tr>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexCheckDefault'
                              checked={checkedState[i]}
                              onChange={() => removeVendor(item, i)}
                              style={{cursor: 'pointer'}}
                            />
                          </div>
                        </td>
                        <td>{item?.id}</td>
                        <td>{item?.firstName + ' ' + item?.lastName}</td>
                        <td>{item?.vendorType === 0 ? 'Service Provider' : 'Custom'}</td>
                        <td>{item?.TRN}</td>
                        <td>{item?.tradeLicenseNo}</td>
                        <td>{item?.city}</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditVendor

import React from 'react'
import {useState, useEffect} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import bin from '../../../img/bin.png'
import Swal from 'sweetalert2'
import close from '../../../img/close.png'
import AddAccount from './AddAccount'
import backArrow from '../../../img/back-arrow.png'
import pen from '../../../img/pen.png'
import accountImgWhite from '../../../img/accounting-white.png'
import greenPlus from '../../../img/add.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import submitImg from '../../../img/Submit-White.png'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import accountsNewImg from '../../../img/statements.png'
import {capitalizeFirstLetter} from '../../../Utilities/utils'

const Accounts = () => {
  const navigate = useNavigate()
  const [accounts, setAccounts] = useState<any>([])

  const [accountModal, setAccountModal] = useState<any>(false)

  const [accountName, setAccountName] = useState<any>()
  const [accountNo, setAccountNo] = useState<any>()
  const [iban, setIban] = useState<any>()
  const [swift, setSwift] = useState<any>()
  const [bankName, setBankName] = useState<any>()
  const [id, setId] = useState<any>()

  const [editModal, setEditModal] = useState<any>(false)

  // list of accounts
  const getAccounts = () => {
    const body = {
      limit: 10,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        setAccounts(res?.data?.data?.payment_account_data)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const editDetails = (acc: any) => {
    setId(acc?._id)
    setAccountName(acc?.accountHolderName)
    setAccountNo(acc?.accountNumber)
    setIban(acc?.IBAN)
    setBankName(acc?.bankName)
    setSwift(acc?.swiftCode)
    setEditModal(true)
  }

  //  update Account
  const updateAccount = () => {
    const body = {
      id: id,
      bankName: bankName,
      bankAddress: '',
      accountHolderName: accountName,
      IBAN: iban,
      accountNumber: accountNo,
      activityStatus: 1,
      paymentMethod: 0,
      swiftCode: swift,
    }

    ApiPut(`corporate/payment_account`, body)
      .then((res) => {
        SuccessToast('Account has been updated successfully!!')
        setEditModal(false)
        getAccounts()
      })
      .catch((err) => console.log('err', err))
    // }
  }

  //  delete  Account
  const deleteAccount = (val: any) => {
    ApiDelete(`corporate/payment_account/${val}`)
      .then((res) => {
        SuccessToast('Account has been deleted successfully!!')
        getAccounts()
      })
      .catch((err) => console.log('err', err))
    // }
  }

  useEffect(() => {
    getAccounts()
  }, [])

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='px-2'>
        <div className='d-flex align-items-center mb-10 '>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <h2 className='page-heading m-0 head-text'>Accounts</h2>
          </div>
          <div className='ms-auto'>
            <a
              style={{
                fontSize: '1.15rem ',
              }}
              className='me-5 pe-4 fnt-500 text-decoration-none green-clr'
              onClick={() => setAccountModal(true)}
            >
              <img
                src={greenPlus}
                height={18}
                width={18}
                style={{
                  marginRight: '10px',
                  marginLeft: '-10px',
                  marginTop: '-3px',
                }}
              />
              Add Account
            </a>
          </div>
          <div className='ms-5'>
            <img src={accountsNewImg} height={35} width={32} />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 px-3'>
          <div className='card-body pt-0 table-responsive my-1 py-2 px-0'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-150px'>Account Name</th>
                  <th className='min-w-100px'>IBAN</th>
                  <th className='min-w-125px'>SWIFT Code</th>
                  <th className='min-w-125px'>Account No.</th>
                  <th className='min-w-125px'>Bank Name</th>
                  <th className='min-w-50px'></th>
                </tr>
              </thead>
              <tbody className='table-body fw-semibold head-text'>
                {accounts.map((acc: any) => {
                  return (
                    <tr className='align-items-center py-3' style={{height: '45px'}}>
                      <td>{acc?.accountHolderName}</td>
                      <td>{acc?.IBAN}</td>
                      <td>{acc?.swiftCode}</td>
                      <td>{acc?.accountNumber}</td>
                      <td>{acc?.bankName}</td>
                      <td>
                        {' '}
                        <div className='d-flex'>
                          <img
                            src={pen}
                            height='20px'
                            width='20px'
                            alt=''
                            className='me-4'
                            style={{cursor: 'pointer'}}
                            onClick={() => editDetails(acc)}
                          />
                          <img
                            src={bin}
                            alt=''
                            height='20px'
                            width='20px'
                            className='me-4'
                            style={{cursor: 'pointer'}}
                            onClick={() =>
                              Swal.fire({
                                html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the account, do you wish to proceed?</p>',
                                showConfirmButton: true,
                                confirmButtonColor: '#007a59',
                                confirmButtonText: 'Confirm',
                                showCancelButton: true,
                                cancelButtonText: 'Cancel',
                              }).then((res) => {
                                if (res.isConfirmed) {
                                  deleteAccount(acc?._id)
                                }
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add Account */}
      <AddAccount
        show={accountModal}
        handleClose={() => {
          setAccountModal(false)
        }}
        accountsList={getAccounts}
      />

      {/* Edit Account */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={editModal}
        onHide={() => {
          setEditModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Edit Account</h2>
            </Col>
            <Col xs={4} sm={4} md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setEditModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-2 text-end' style={{}}>
              <img src={accountImgWhite} height='80px' width='80px' className='me-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-5'
          style={{transform: 'translate(0px, -80px)', marginBottom: '-65px'}}
        >
          <Row className='align-items-end'>
            <Col lg={12} md={12} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 ps-4 text-white  generate-btn status-w-160 ms-auto'
                onClick={() => updateAccount()}
              >
                <img src={submitImg} height={20} width={20} className='ms-1 me-5' />
                Submit
              </button>
            </Col>
          </Row>
          <Row className='align-items-end mt-8'>
            <Col xs={12} sm={6} md={4} className='align-items-center px-3 mb-5'>
              <label className='required mb-2 label-color white-dark-theme-color'>Account Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Account Name'
                name='item_id'
                value={accountName}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setAccountName(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-3 mb-5'>
              <label className='required mb-2 label-color white-dark-theme-color'>Account No.</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Account No.'
                name='item_id'
                value={accountNo}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setAccountNo(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-3 mb-5'>
              <label className='required mb-2 label-color white-dark-theme-color'>Bank Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter Bank Name'
                name='item_id'
                value={bankName}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setBankName(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={4} className='align-items-center  px-3 mb-5'>
              <label className='required mb-2 label-color white-dark-theme-color'>IBAN</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter IBAN'
                name='item_id'
                value={iban}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setIban(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
            <Col xs={12} sm={6} md={4} className='align-items-center px-2 mb-5'>
              <label className='required mb-2 label-color white-dark-theme-color'>SWIFT Code</label>
              <input
                type='text'
                className='form-control form-control-solid-bg mytest'
                placeholder='Enter SWIFT Code'
                name='item_id'
                value={swift}
                onChange={(e) => {
                  let newVal: any = capitalizeFirstLetter(e.target.value)
                  setSwift(newVal)
                }}
                autoComplete='off'
                style={{width: '95%'}}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default Accounts

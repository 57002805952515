import React from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  tenantdata: any
  getMessageList: any
}

const NewMessage = ({show, handleClose, tenantdata, getMessageList}: ButtonProps) => {
  const [message, setMessage] = React.useState<any>('')

  const handleAddMessage = () => {
    const body = {
      subject: message?.subject,
      messageText: message?.message,
      tenantId: tenantdata?._id,
      saveStatus: 0,
    }
    ApiPost('corporate/message', body).then((res) => {
      // SuccessToast(res?.data?.message)
      getMessageList()
    })
    // .catch((err) => ErrorToast(err.message))
    handleClose()
  }
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
    >
      <div>
        <div className='modal-header'>
          <h1 className='top green_color'>New Message</h1>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG
              className='svg-icon-1 text-black'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </div>
        </div>

        <div className='modal-body'>
          <h6 className='text-muted-1-2-fr align-items-center'>
            Message Id
            <div className='mx-1 value_black'>###</div>
          </h6>

          <h6 className='text-muted-1-2-fr align-items-center'>
            To
            <span className='mx-1' style={{color: 'black'}}>
              {tenantdata?.firstName} {tenantdata?.firstName} 12313
            </span>
          </h6>

          <h6 className='mx-0 text-muted-1-2-fr align-items-center'>
            Subject
            <div className='mx-1 value_black'>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Subject'
                name='contractNo'
                onChange={(e) => setMessage({...message, subject: e.target.value})}
              />
            </div>
          </h6>
          <h6 className='mx-0 text-muted-1-2-fr'>
            Message
            <div className='mx-1 value_black'>
              <textarea
                className='form-control form-control-solid'
                placeholder='Type your message'
                name='contractNo'
                onChange={(e) => setMessage({...message, message: e.target.value})}
              />
            </div>
          </h6>
          {/* <div className='pt-0 table-responsive mt-5'>
              <div className='payment-reminder-item'>
                <div className='mb-5 d-flex' style={{alignItems: 'center'}}>
                  <label className='form-label me-10'>Subject </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='Subject'
                    name='contractNo'
                    onChange={(e) => setMessage({...message, subject: e.target.value})}
                  />
                </div>
                <div className='mb-5 d-flex' style={{alignItems: 'center'}}>
                  <label className='form-label me-10'>Message </label>
                  <textarea
                    className='form-control form-control-solid'
                    placeholder='Type your message'
                    name='contractNo'
                    onChange={(e) => setMessage({...message, message: e.target.value})}
                  />
                </div>
              </div>
            </div> */}

          <div className='d-flex' style={{justifyContent: 'flex-end'}}>
            <a
              // onClick={() => setShowCreateAppModal(true)}
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              // onClick={() => setShowSendRemModal(true)}
              // onClick={() => handleAddMessage()}
            >
              Add
            </a>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NewMessage

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../img/NoData1.svg'
import {ApiGet, ApiPost, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import plus from '../../../../img/add-white.png'
import {DatePicker, Space} from 'antd'
import TabsFinancials from '../TabsFinancials'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import trashImg from '../../../../img/trash.png'
import expenseImg from '../../../../img/expense-insight.png'
import totalExpenseImg from '../../../../img/total-expense.png'
import upcomingExpenseImg from '../../../../img/upcoming-expense.png'
import overdueExpenseImg from '../../../../img/overdue-expense.png'
import paidExpenseImg from '../../../../img/paid-expense.png'
import unpaidExpenseImg from '../../../../img/unpaid-expense.png'
import Filters from './Filters'
import allTen from '../../../../img/all-tenc.png'
import bookTen from '../../../../img/book-tenc.png'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import openImg from '../../../../img/black-collap-down.png'
import closeImg from '../../../../img/black-collap-up.png'
import maintenance from '../../../../img/maintenanceWhite.png'

function Expenses() {
  const navigate = useNavigate()

  const [showFilter, setShowFilter] = useState<any>(false)

  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)

  const [expensesTotalCount, setExpensesTotalCount] = useState<any>(0)
  const [incomeTotalCount, setIncomeTotalCount] = useState<any>(0)

  const [searchExpense, setSearchExpense] = useState<any>('')
  const [searchPayee, setSearchPayee] = useState<any>('')
  const [searchAccount, setSearchAccount] = useState<any>('')

  const [isLoading, setIsLoading] = useState<any>(false)
  const [show, setShow] = useState<any>([])

  const getAllExpenses = (data: any, pageVal:any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        searchExpense: searchExpense,
        searchPayee: searchPayee,
        searchPaymentAccount: searchAccount,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      values.searchExpense = searchExpense
      values.searchPayee = searchPayee
      values.searchPaymentAccount = searchAccount
      body = values
    }

    ApiPost(`corporate/expense/get`, body)
      .then((res) => {
        let values: any = []
        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.expense_data?.length; i++) {
          values[i] = false
          showValues[i] = false
        }
        setCheckedValues(values)
        setShow(showValues)

        setTableData(res?.data?.data?.expense_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setExpensesTotalCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  //
  const getAllPayments = () => {
    const body = {
      limit: 25,
      page: 1,
    }
    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setIncomeTotalCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllExpenses(null, event.selected + 1)
  }

  useEffect(() => {
    // getAllPayments()
    getExpensesCount()
  }, [])

  useEffect(() => {
    setPage(1)
    getAllExpenses(null, 1)
  }, [searchExpense, searchPayee, searchAccount])

  const [expensesCardCount, setExpensesCardCount] = useState<any>()

  const getExpensesCount = () => {
    ApiGet(`corporate/expense/count`)
      .then((res) => {
        setExpensesCardCount(res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [tableData, setTableData] = useState<any>([])

  const goToExpense = (data: any) => {
    if (data?.saveStatus == 0) navigate(`/financials/add-expense/2/${data?._id}`)
    else navigate(`/financials/view-expense/${data?._id}`)
  }

  const deleteExpense = () => {
    setIsLoading(true)
    let eIds: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        eIds[i] = tableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: eIds,
    }
    ApiPost(`corporate/expense/delete`, body)
      .then((res: any) => {
        SuccessToast('Expenses has been deleted successfully')
        getAllExpenses(null, page)
        getExpensesCount()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Financials </b>
            </h1>
          </div>
        </div>
        <div className='d-flex flex-wrap mt-5 financials'>
          {/* Old Income Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-green-card  h-45px`}
              onClick={() => {
                navigate(`/financials-income`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income-01</h4>
              </div>
              {/* </div> */}
            </div>
          </div>

          {/* New Incomes Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-green-card h-45px`}
              onClick={() => {
                navigate(`/finance-income`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income</h4>
              </div>

              {/* </div> */}
            </div>
          </div>

          {/* Expenses Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-active-blue-card h-45px`}
              onClick={() => {
                navigate(`/financials-expenses`)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={bookTen}
                  height={21}
                  width={21}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {expensesTotalCount ? expensesTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}

                <h4 className='mb-0 text-white'>Expenses</h4>
              </div>
            </div>
            <div className='blue-active-filter'></div>
          </div>

          {/* Tenant Requests*/}
          <div className='ms-auto'>
            <div
              className={'tenancy-green-card d-flex align-items-center gray-card h-45px'}
              onClick={() => {
                navigate(`/finance-crm-residents`)
              }}
            >
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={maintenance}
                  height={26}
                  width={26}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                    {tenantReqCount ? tenantReqCount?.toLocaleString('en-US', {}) : 0}
                  </h2> */}

                <h4 className='mb-0 text-white'>Tenant Requests</h4>
              </div>
            </div>
          </div>
        </div>

        {/* <TabsFinancials value={'expenses'} count={expensesTotalCount}/> */}

        <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap: '20px'}}>
          {/* Expenses */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Expenses</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={expenseImg} height={40} width={40} className='me-3 mt-3' />
              <h1 className='fw-bold ms-auto mt-3'>
                {expensesCardCount
                  ? Number(expensesCardCount?.totalCreatedExpense) +
                    Number(expensesCardCount?.totalDraftExpense)
                  : 0}
              </h1>
            </div>
          </div>
          {/* Total */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Total</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={totalExpenseImg} height={35} width={35} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {expensesCardCount?.totalAmount != 0
                  ? `${expensesCardCount?.totalAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
          {/* Upcoming */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Upcoming</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={upcomingExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {expensesCardCount?.totalUpcomingAmount != 0
                  ? `${expensesCardCount?.totalUpcomingAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
          {/* Overdue */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Overdue</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={overdueExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {expensesCardCount?.totalOverdueAmount != 0
                  ? `${expensesCardCount?.totalOverdueAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>

          {/* Paid */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Paid</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={paidExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {expensesCardCount?.totalPaidAmount != 0
                  ? `${expensesCardCount?.totalPaidAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>

          {/* Unpaid */}
          <div className='customCard py-4 px-7 shadow' style={{width: '13.5rem', height: '100px'}}>
            <h4 className='text-dark-500  mb-3'>Unpaid</h4>
            <div className='d-flex justify-content-start align-items-center'>
              <img src={unpaidExpenseImg} height={40} width={40} className='me-3' />
              <h3 className='fw-bold ms-auto mt-5'>{'AED'}</h3>
            </div>
            <div className='d-flex justify-content-start align-items-center'>
              <h3 className='fnt-500 ms-auto mt-1'>
                {expensesCardCount?.totalUnpaidAmount != 0
                  ? `${expensesCardCount?.totalUnpaidAmount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}`
                  : 0}
              </h3>
            </div>
          </div>
        </div>

        <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <label className='head-text' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Expense'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchExpense}
                onChange={(e: any) => setSearchExpense(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div className='mb-2 col-md-2 ps-5 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Payee'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchPayee}
                onChange={(e: any) => setSearchPayee(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className='mb-2 col-md-2 ps-5 pe-5 xyz '>     {/* job-modl */}
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Account'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchAccount}
                onChange={(e: any) => setSearchAccount(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
            <div className='d-flex justify-content-end align-items-center'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex '>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>

                      <span className='ms-5 ps-1 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </h4>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  mx-3 red-hollow-btn btn-fit-content'
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Expenses</></div>
                           <div class='fs-5 mt-4'>You are about to delete the selected expenses.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            deleteExpense()
                          }
                        })
                    }}
                  >
                    <img src={trashImg} height={20} width={20} className='me-3' /> Delete
                  </button>
                </>
              )}

              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn btn-fit-content ps-2'
                onClick={() => {
                  navigate('/financials/add-expense/1')
                }}
              >
                <img src={plus} height={18} width={18} className='me-3' /> Add Expense
              </button>

              <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
                <div
                  className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                  onClick={() => setShowFilter(true)}
                >
                  <svg
                    style={{
                      height: '20px',
                      width: '28px',
                      marginLeft: '3px',
                    }}
                    viewBox='0 0 1024 1024'
                    xmlns='http://www.w3.org/2000/svg'
                    className='me-1'
                  >
                    <path
                      fill='#ffff'
                      d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush py-5  px-7'>
          {' '}
          {/* container-xxl */}
          <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
            <div className='table-responsive pb-6'>
              {/*  */}

              <table
                className='table align-start table-row-dashed fs-8 gy-5 '
                id='kt_ecommerce_sales_table'
              >
                <thead className='fs-7'>
                  <>
                    <th className='text-start min-w-25px test px-0'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            totalSelected == tableData?.length && tableData?.length != 0
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            let values = [...checkedValues]
                            if (e.target.checked) {
                              for (let i = 0; i < tableData?.length; i++) {
                                values[i] = true
                              }
                            } else {
                              for (let i = 0; i < tableData?.length; i++) {
                                values[i] = false
                              }
                            }
                            setCheckedValues(values)
                          }}
                        />
                      </div>
                    </th>
                  </>

                  <th className='text-start min-w-50px px-3'>ID</th>
                  <th className='text-start min-w-125px px-3'>Expense</th>
                  <th className='text-start min-w-125px px-3'>Type</th>
                  <th className='text-start min-w-125px px-3'>Payee </th>
                  <th className='text-start min-w-125px px-3'>Amount</th>
                  <th className='text-start min-w-150px px-3'>Development </th>
                  <th className='text-start min-w-125px px-3'>Unit No.</th>
                  <th className='text-start min-w-125px px-3'>Unit Type</th>
                  <th className='text-start min-w-125px px-3'>Unit Category</th>
                  <th className='text-center min-w-150px px-3'>Status</th>
                  <th className='text-center min-w-150px px-3'>Category</th>
                  <th className='text-start min-w-100px px-3'>Due</th>
                  <th className='text-start min-w-125px px-3'>Paid Amount</th>
                  <th className='text-start min-w-125px px-3'>Paid Date</th>
                  <th className='text-start min-w-125px px-3'>Outstanding</th>
                  <th className='text-start min-w-125px px-3'>Method</th>
                  <th className='text-start min-w-150px px-3'>Payment Account</th>
                  <th className='text-center min-w-150px px-3'>Form Status</th>
                  <th className='text-start min-w-150px px-3'>Created By</th>
                  <th className='text-start min-w-150px px-3'>Last Updated</th>
                </thead>
                <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                  {tableData?.length > 0 ? (
                    tableData?.map((vd: any, i: any) => {
                      const unitTypes = new Set()
                      const unitNos = new Set()
                      const unitProperties = new Set()

                      vd.expense_payment_item.forEach((expenseItem: any) => {
                        if (expenseItem.unit != undefined && expenseItem?.unit?.length > 0) {
                          if (expenseItem?.unit?.[0]?.unitType !== undefined) {
                            unitTypes.add(expenseItem?.unit?.[0]?.unitType)
                          }
                          if (expenseItem?.unit?.[0]?.unitNo !== undefined) {
                            unitNos.add(expenseItem?.unit?.[0]?.unitNo)
                          }
                          if (expenseItem?.unit?.[0]?.propertyType !== undefined) {
                            unitProperties.add(expenseItem?.unit?.[0]?.propertyType)
                          }
                        }
                      })

                      const unitTypeCount = unitTypes.size
                      const unitNoCount = unitNos.size
                      const unitPropertyCount = unitProperties.size

                      return (
                        <>
                          <tr
                            style={
                              show[i] == true
                                ? {borderBottomStyle: 'none', height: '30px'}
                                : show[i] == false
                                ? {borderBottomStyle: 'dashed', height: '30px'}
                                : vd?.expense_payment_item?.length == 1
                                ? {borderBottomStyle: 'dashed', height: '30px'}
                                : {height: '30px'}
                            }
                          >
                            <td className='text-start px-0 test'>
                              <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[i] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[i] = !values[i]
                                    setCheckedValues(values)
                                  }}
                                />
                                {vd?.expense_payment_item?.length > 1 && (
                                  <img
                                    src={show[i] ? closeImg : openImg}
                                    height='18'
                                    width='18'
                                    className='cursor-pointer ms-2'
                                    onClick={() => {
                                      if (show[i] == true) {
                                        let values = [...show]
                                        values[i] = false
                                        setShow(values)
                                      } else {
                                        let values = [...show]

                                        values[i] = true

                                        setShow(values)
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </td>

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.id ? vd?.id : ''}
                            </td>
                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.name ? vd?.name : ''}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {'Maintenance'}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.payeeType == 0
                                ? `${
                                    vd?.payee?.length > 0
                                      ? `${vd?.payee?.[0]?.firstName} ${vd?.payee?.[0]?.lastName}`
                                      : '-'
                                  }`
                                : vd?.payeeType == 1
                                ? `${
                                    vd?.payee?.length > 0 ? `${vd?.payee?.[0]?.companyName} ` : '-'
                                  }`
                                : '-'}
                            </td>
                            <td
                              className='text-start  cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.totalAmount
                                ? `AED ${vd?.totalAmount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </td>
                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.community?.length > 0
                                ? `${vd?.community?.[0]?.name}`
                                : vd?.building?.length > 0
                                ? `${vd?.building?.[0]?.name}`
                                : '-'}
                            </td>
                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {unitNoCount > 1 ? (
                                <>{unitNoCount > 0 ? `+${unitNoCount}` : 0}</>
                              ) : (
                                <>{vd?.unit?.length > 0 ? `${vd?.unit?.[0]?.unitNo}` : '-'}</>
                              )}
                            </td>
                            <td className='text-start ' onClick={() => goToExpense(vd)}>
                              {unitTypeCount > 1 ? (
                                <>{unitTypeCount > 0 ? `+${unitTypeCount}` : 0}</>
                              ) : (
                                <>
                                  {vd?.unit?.[0]?.unitType === 0
                                    ? 'Apartment'
                                    : vd?.unit?.[0]?.unitType === 1
                                    ? 'Penthouse'
                                    : vd?.unit?.[0]?.unitType === 2
                                    ? 'Common Area'
                                    : vd?.unit?.[0]?.unitType === 3
                                    ? 'Townhouse'
                                    : vd?.unit?.[0]?.unitType === 4
                                    ? 'Villa'
                                    : vd?.unit?.[0]?.unitType === 5
                                    ? `Other - ${vd?.unit?.[0]?.otherName}`
                                    : vd?.unit?.[0]?.unitType === 6
                                    ? 'Office'
                                    : vd?.unit?.[0]?.unitType === 7
                                    ? 'Restaurant'
                                    : vd?.unit?.[0]?.unitType === 8
                                    ? 'Cafe'
                                    : vd?.unit?.[0]?.unitType === 9
                                    ? 'Retail'
                                    : '-'}
                                </>
                              )}
                            </td>
                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {unitPropertyCount > 1 ? (
                                <>{unitPropertyCount > 0 ? `+${unitPropertyCount}` : 0}</>
                              ) : (
                                <>
                                  {vd?.unit?.length > 0
                                    ? `${
                                        vd?.unit?.[0]?.propertyType == 0
                                          ? 'Residential'
                                          : 'Commercial'
                                      }`
                                    : '-'}
                                </>
                              )}
                            </td>

                            <td
                              className='text-center  cursor-pointer px-3'
                              data-order='Denied'
                              onClick={() => goToExpense(vd)}
                            >
                              <div className='d-flex justify-content-center mx-2'>
                                <div
                                  className={
                                    vd?.saveStatus == 0
                                      ? 'status pend-st status-w-120'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 2
                                      ? 'status pending-approval-st status-w-120'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 1
                                      ? 'status approve-st status-w-120'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 0
                                      ? 'status cancelled-st status-w-120'
                                      : 'status pend-st status-w-120'
                                  }
                                >
                                  <>
                                    {vd?.saveStatus == 0
                                      ? '-'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 2
                                      ? 'Partially Paid'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 1
                                      ? 'Paid'
                                      : vd?.saveStatus == 1 && vd?.expenseStatus === 0
                                      ? 'Unpaid'
                                      : '-'}
                                  </>
                                </div>
                              </div>
                            </td>
                            <td
                              className='text-center cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='Denied'
                            >
                              <div className='d-flex justify-content-center mx-2'>
                                <div
                                  className={
                                    vd?.saveStatus == 0
                                      ? 'status req-bar status-w-120'
                                      : vd?.saveStatus == 1 && vd?.category == 0
                                      ? 'status active-bar status-w-120'
                                      : vd?.saveStatus == 1 && vd?.category == 1
                                      ? 'status expired-bar status-w-120'
                                      : vd?.saveStatus == 1 && vd?.category == 2
                                      ? 'status complete-bar status-w-120'
                                      : 'status  req-bar status-w-120'
                                  }
                                >
                                  {vd?.saveStatus == 0
                                    ? '-'
                                    : vd?.saveStatus == 1 && vd?.category == 0
                                    ? 'Upcoming'
                                    : vd?.saveStatus == 1 && vd?.category == 1
                                    ? 'Overdue'
                                    : vd?.saveStatus == 1 && vd?.category == 2
                                    ? 'Complete'
                                    : '-'}
                                </div>
                              </div>
                            </td>
                            <td
                              className='text-start cursor-pointer  px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.dueDate ? `${moment(vd?.dueDate).format('DD.MM.YYYY')}` : '-'}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.amountPaid
                                ? `AED ${vd?.amountPaid?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.datePaid ? `${moment(vd?.datePaid).format('DD.MM.YYYY')}` : '-'}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.outstandingAmount
                                ? `AED ${vd?.outstandingAmount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </td>

                            <td
                              className='text-start cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.expenseMethod == 1
                                ? `Cheque`
                                : vd?.expenseMethod == 2
                                ? `Bank Transfer`
                                : vd?.expenseMethod == 3
                                ? `Cash`
                                : '-'}
                            </td>

                            <td
                              className='text-start cursor-pointer  px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='2022-09-11'
                            >
                              <div className='d-flex align-items-center'>
                                {vd?.payment_account?.length > 0
                                  ? `${vd?.payment_account?.[0]?.accountNumber}`
                                  : '-'}
                              </div>
                            </td>

                            <td
                              className='text-center  cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                              data-order='2022-09-11'
                            >
                              <div
                                className={
                                  vd?.saveStatus == 0
                                    ? 'status draft-st status-w-120'
                                    : vd?.saveStatus == 1
                                    ? 'status create-st status-w-120'
                                    : 'status not-saved status-w-120'
                                }
                              >
                                {vd?.saveStatus == 0
                                  ? 'Draft'
                                  : vd?.saveStatus == 1
                                  ? 'Created'
                                  : '-'}
                              </div>
                            </td>

                            <td
                              className='py-3 cursor-pointer px-3'
                              onClick={() => goToExpense(vd)}
                            >
                              <p className='my-0'>
                                {vd?.createdBy?.[0] &&
                                  `${vd?.createdBy?.[0]?.firstName} ${vd?.createdBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0'>
                                {vd?.createdAt
                                  ? `${moment(vd?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                                  : '-'}
                              </p>
                            </td>

                            <td
                              className='py-3 cursor-pointer pe-5'
                              onClick={() => goToExpense(vd)}
                            >
                              {vd?.updatedBy?.length > 0 ? (
                                <>
                                  <p className='my-0'>
                                    {vd?.updatedBy?.[0] &&
                                      `${vd?.updatedBy?.[0]?.firstName} ${vd?.updatedBy?.[0]?.lastName} - `}
                                  </p>
                                  <p className='my-0'>
                                    {vd?.updatedAt
                                      ? ` ${moment(vd?.updatedAt).format('DD.MM.YYYY - hh:mm a')}`
                                      : '- -'}
                                  </p>
                                </>
                              ) : (
                                <>{'-'}</>
                              )}
                            </td>
                          </tr>

                          {vd?.expense_payment_item?.length > 0 && show[i] && (
                            <>
                              {vd?.expense_payment_item?.map((itm: any, itmInd: any) => {
                                return (
                                  <tr style={{height: '35px'}}>
                                    <td className='text-start px-0 test'></td>

                                    <td
                                      data-kt-ecommerce-order-filter='order_id'
                                      className='text-start cursor-pointer px-3'
                                    ></td>
                                    <td className='text-start cursor-pointer px-3 light-gray-breakdown-clr'>
                                      {itm?.name ? itm?.name : '-'}
                                    </td>

                                    <td
                                      className='text-start cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                    ></td>

                                    <td
                                      className='text-start cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                    ></td>
                                    <td
                                      className='text-start  cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      {itm?.totalAmount
                                        ? `AED ${itm?.totalAmount?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}`
                                        : 'AED -'}
                                    </td>
                                    <td
                                      className='text-start cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                    ></td>
                                    <td
                                      className='text-start cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      {unitNoCount > 1 && (
                                        <>
                                          {itm?.unit?.length > 0
                                            ? `${itm?.unit?.[0]?.unitNo}`
                                            : '-'}
                                        </>
                                      )}
                                    </td>
                                    <td className='text-start light-gray-breakdown-clr'>
                                      {unitTypeCount > 1 && (
                                        <>
                                          {itm?.unit?.[0]?.unitType === 0
                                            ? 'Apartment'
                                            : itm?.unit?.[0]?.unitType === 1
                                            ? 'Penthouse'
                                            : itm?.unit?.[0]?.unitType === 2
                                            ? 'Common Area'
                                            : itm?.unit?.[0]?.unitType === 3
                                            ? 'Townhouse'
                                            : itm?.unit?.[0]?.unitType === 4
                                            ? 'Villa'
                                            : itm?.unit?.[0]?.unitType === 5
                                            ? `Other - ${itm?.unit?.[0]?.otherName}`
                                            : itm?.unit?.[0]?.unitType === 6
                                            ? 'Office'
                                            : itm?.unit?.[0]?.unitType === 7
                                            ? 'Restaurant'
                                            : itm?.unit?.[0]?.unitType === 8
                                            ? 'Cafe'
                                            : itm?.unit?.[0]?.unitType === 9
                                            ? 'Retail'
                                            : '-'}
                                        </>
                                      )}
                                    </td>
                                    <td
                                      className='text-start cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      {unitPropertyCount > 1 && (
                                        <>
                                          {itm?.unit?.length > 0
                                            ? `${
                                                itm?.unit?.[0]?.propertyType == 0
                                                  ? 'Residential'
                                                  : 'Commercial'
                                              }`
                                            : '-'}
                                        </>
                                      )}
                                    </td>

                                    <td
                                      className='text-center  cursor-pointer px-3 '
                                      data-order='Denied'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      <div className='d-flex justify-content-center mx-2'>
                                        <div
                                          // className={
                                          //   itm?.saveStatus == 0
                                          //     ? 'tenancy-stats pend-st'
                                          //     : itm?.saveStatus == 1 && itm?.expenseStatus === 2
                                          //     ? 'tenancy-stats pending-approval-st'
                                          //     : itm?.saveStatus == 1 && itm?.expenseStatus === 1
                                          //     ? 'tenancy-stats approve-st'
                                          //     : itm?.saveStatus == 1 && itm?.expenseStatus === 0
                                          //     ? 'tenancy-stats cancelled-st'
                                          //     : 'tenancy-stats pend-st '
                                          // }
                                          style={
                                            vd?.saveStatus == 0
                                              ? {color: 'dimgray'}
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 2
                                              ? {color: '#ff9a1e'}
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 1
                                              ? {color: '#0d9e91'}
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 0
                                              ? {color: '#d54645 '}
                                              : {color: 'dimgray'}
                                          }
                                        >
                                          <>
                                            {itm?.saveStatus == 0
                                              ? '-'
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 2
                                              ? 'Partially Paid'
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 1
                                              ? 'Paid'
                                              : vd?.saveStatus == 1 &&
                                                itm?.expensePaymentItemStatus == 0
                                              ? 'Unpaid'
                                              : '-'}
                                          </>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      className='text-center cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='Denied'
                                    ></td>
                                    <td
                                      className='text-start cursor-pointer  px-3'
                                      // onClick={() => goToExpense(vd)}
                                    ></td>

                                    <td
                                      className='text-start cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      {itm?.amountPaid
                                        ? `AED ${itm?.amountPaid?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}`
                                        : 'AED -'}
                                    </td>

                                    <td
                                      className='text-start cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='2022-09-11'
                                    >
                                      {itm?.datePaid
                                        ? `${moment(itm?.datePaid).format('DD.MM.YYYY')}`
                                        : '-'}
                                    </td>

                                    <td
                                      className='text-start cursor-pointer px-3 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='2022-09-11'
                                    >
                                      {itm?.outstandingAmount
                                        ? `AED ${itm?.outstandingAmount?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}`
                                        : 'AED -'}
                                    </td>

                                    <td
                                      className='text-start cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='2022-09-11'
                                    ></td>

                                    <td
                                      className='text-start cursor-pointer  px-3'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='2022-09-11'
                                    ></td>

                                    <td
                                      className='text-center  cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                      data-order='2022-09-11'
                                    ></td>

                                    <td
                                      className='py-3 cursor-pointer px-3'
                                      // onClick={() => goToExpense(vd)}
                                    ></td>

                                    <td
                                      className='py-3 cursor-pointer pe-5 light-gray-breakdown-clr'
                                      // onClick={() => goToExpense(vd)}
                                    >
                                      {itm?.updatedBy?.length > 0 ? (
                                        <>
                                          <p className='my-0'>
                                            {itm?.updatedBy?.[0] &&
                                              `${itm?.updatedBy?.[0]?.firstName} ${itm?.updatedBy?.[0]?.lastName} - `}
                                          </p>
                                          <p className='my-0'>
                                            {itm?.updatedAt
                                              ? ` ${moment(itm?.updatedAt).format(
                                                  'DD.MM.YYYY - hh:mm a'
                                                )}`
                                              : '- -'}
                                          </p>
                                        </>
                                      ) : (
                                        <>{'-'}</>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          )}
                        </>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel='...'
                      nextLabel='>'
                      pageClassName='paginate_button page-item +'
                      pageLinkClassName='page-link'
                      containerClassName='pagination'
                      activeClassName='active'
                      previousClassName='paginate_button page-item previous'
                      nextClassName='paginate_button page-item next'
                      previousLinkClassName='page-link'
                      nextLinkClassName='page-link'
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      forcePage={page - 1}
                      previousLabel='<'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Filters */}
        {showFilter && (
          <Filters
            showw={showFilter}
            handleClose={() => setShowFilter(false)}
            updateParent={(data: any) => {
              getAllExpenses(data, 1)
            }}
          />
        )}
      </div>
    </>
  )
}

export default Expenses

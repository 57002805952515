import React, {useState, useEffect} from 'react'
import {Button, Col, Row, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import close from '../../../img/close.png'
import invoiceBlue from '../../../img/invoice-blue.png'
import redCross from '../../../img/x-mark.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import recipient from '../../../img/recipient.png'
import './style.scss'
import creditNoteWhite from '../../../img/credit-note-white.png'
import editpen from '../../../img/pen.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import Switch from 'react-switch'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  closeEdit: any
  from:any
}

const CreditNote = ({show, handleClose, paymentId, tenantDetails, closeEdit, from}: ButtonProps) => {
  const [invoiceLinkData, setInvoiceLinkData] = useState<any>([])
  const [invoiceLinkChecked, setInvoiceLinkChecked] = useState<any>(-1)
  const [invoiceSubItemsModal, setInvoiceSubItemsModal] = useState<any>(false)
  const [invoiceToShowEdit, setInvoiceToShowEdit] = useState<any>()
  const [invoiceFields, setInvoiceFields] = useState<any>([])
  const [totalCreditAmount, setTotalCreditAmount] = useState<any>(0)
  const [creditNoteModal, setCreditNoteModal] = useState<any>(false)
  const [invRecDetails, setInvRecDetails] = useState<any>([])
  const [creditNoteCompany, setCreditNoteCompany] = useState<any>()
  const [creditNoteNo, setCreditNoteNo] = useState<any>('')
  const [creditNoteNoError, setCreditNoteNoError] = useState<any>(false)
  const [creditNotes, setCreditNotes] = useState<any>('')
  const [checkedRec, setCheckedRec] = useState<any>(false)

  var vatAmount: any = 0
  var nonVat: any = 0

  const getAllInvoices = async () => {
    const body = {
      limit: 25,
      page: 1,
      paymentId: paymentId,
      isForCreditNote: true,
    }
    
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_invoice/get?isCloneRecord=true`
    else url = `corporate/payment_invoice/get`

    await ApiPost(`${url}`, body)
      .then((res) => {
        setInvoiceLinkData(res?.data?.data?.payment_invoice_data)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getAllInvoices()
    getDetailsForCreditNote()
  }, [])

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getSum = (numb1: any, numb2: any) => {
    return numb1 + numb2
  }

  //
  const getDetailsForCreditNote = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        setCreditNoteCompany(res?.data?.data?.creditNoteCompany)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const generateCreditNote = () => {
    // total VAT to Credit
    let totVatCred: any = 0
    for (let i = 0; i < invoiceFields?.length; i++) {
      totVatCred = totVatCred + getPositive(invoiceFields[i]?.creditVat)
    }

    // total NonVat to Credit
    let totalSubCred: any = 0
    for (let i = 0; i < invoiceFields?.length; i++) {
      totalSubCred = totalSubCred + getPositive(invoiceFields[i]?.creditAmount)
    }

    // subItems
    let subItems: any = []
    let j: any = 0
    for (let i = 0; i < invoiceToShowEdit?.paymentSubItems?.length; i++) {
      if (getPositive(invoiceFields[i]?.creditAmount) > 0) {
        subItems[j] = {
          _id: invoiceToShowEdit?.paymentSubItems[i]?._id,
          id: invoiceToShowEdit?.paymentSubItems[i]?.id,
          workspaceId: invoiceToShowEdit?.paymentSubItems[i]?.workspaceId,
          name: invoiceToShowEdit?.paymentSubItems[i]?.name,
          creditAmount: parseFloat(getPositive(invoiceFields[i]?.creditAmount)),
          creditVATAmount: parseFloat(getPositive(invoiceFields[i]?.creditVat)),
          amount: invoiceToShowEdit?.paymentSubItems[i]?.amount,
          quantity: invoiceToShowEdit?.paymentSubItems[i]?.quantity,
          totalAmount: invoiceToShowEdit?.paymentSubItems[i]?.totalAmount,
          VATPercentage: invoiceToShowEdit?.paymentSubItems[i]?.VATPercentage,
          amountPaid: invoiceToShowEdit?.paymentSubItems[i]?.amountPaid,
          outstandingAmount: invoiceToShowEdit?.paymentSubItems[i]?.outstandingAmount,
          VATAmount: invoiceToShowEdit?.paymentSubItems[i]?.VATAmount,
          latePaymentFees: invoiceToShowEdit?.paymentSubItems[i]?.latePaymentFee,
          latePaymentObject: invoiceToShowEdit?.paymentSubItems[i]?.latePaymentObject,
          latePaymentSelectionStatus:
            invoiceToShowEdit?.paymentSubItems[i]?.latePaymentSelectionStatus,
          paymentStatus: invoiceToShowEdit?.paymentSubItems[i]?.paymentStatus,
          isActive: invoiceToShowEdit?.paymentSubItems[i]?.isActive,
          isBlock: invoiceToShowEdit?.paymentSubItems[i]?.isBlock,
          isLatePaymentFeesRecord: invoiceToShowEdit?.paymentSubItems[i]?.isLatePaymentFeesRecord,
          nextLatePaymentCycle: invoiceToShowEdit?.paymentSubItems[i]?.nextLatePaymentFeesRecord,
          datePaid: invoiceToShowEdit?.paymentSubItems[i]?.datePaid,
          oldLatePaymentCycleList: invoiceToShowEdit?.paymentSubItems[i]?.oldLatePaymentCycleList,
          paymentId: invoiceToShowEdit?.paymentSubItems[i]?.paymentId,
          paymentSubItemId: invoiceToShowEdit?.paymentSubItems[i]?.paymentSubItemId,
          latePaymentSubItemId: invoiceToShowEdit?.paymentSubItems[i]?.latePaymentSubItemId,
          paymentItemListId: invoiceToShowEdit?.paymentSubItems[i]?.paymentItemListId,
          createdBy: invoiceToShowEdit?.paymentSubItems[i]?.createdBy,
          createdAt: invoiceToShowEdit?.paymentSubItems[i]?.createdAt,
          updatedAt: invoiceToShowEdit?.paymentSubItems[i]?.updatedAt,
          __v: 0,
        }
        j++
      }
    }

    const body = {
      creditNoteNo: creditNoteNo,
      invoiceNo: invoiceToShowEdit?.invoiceNo,
      paymentId: paymentId,
      paymentInvoiceId: invoiceToShowEdit?._id,
      note: creditNotes,
      totalCreditAmount: totVatCred + totalSubCred,
      totalCreditVAT: totVatCred,
      subTotalCreditAmount: totalSubCred,
      paymentSubItems: subItems,
      isManualPDF: false,
      isShareToTenant: checkedRec,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_credit_note?isCloneRecord=true`
    else url = `corporate/payment_credit_note`

    ApiPost(`${url}`, body)
      .then((res) => {
        SuccessToast('Credit Note has been successfully generated!!!')
        handleClose()
        setCreditNoteModal(false)
        closeEdit()
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-2'>
            <div className='col-md-6 pt-3'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Select Invoice
              </label>
            </div>

            <div className='col-md-6 align-items-center text-end'>
              <button
                className='btn btn-md fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: 'inherit',
                }}
                onClick={(event) => {
                  if (invoiceLinkChecked < 0) ErrorToast('Please Select an Invoice!!!')
                  else {
                    for (let i = 0; i < invoiceFields?.length; i++) {
                      invoiceFields[i].creditAmount = 0
                      invoiceFields[i].creditVat = 0
                    }
                    setInvoiceSubItemsModal(true)
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>

          <div className='d-flex mt-5 row '>
            <h6 className='m-0 p-0 col-1'></h6>
            <h6
              className='m-0 p-0 col-1 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              #
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>

            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Invoice No
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Date Generated
            </h6>
            <h6
              className='m-0 p-0 col-4 head-text ps-5 text-decoration-underline fs-5 '
              style={{fontWeight: '600'}}
            >
              Receipt Amount
            </h6>
          </div>

          <div className='py-5'>
            {invoiceLinkData?.length > 0 &&
              invoiceLinkData.map((ut: any, index: any) => {
                return (
                  <>
                    {ut?.invoiceNo?.length > 0 && (
                      <>
                        <div className='d-flex my-5 row '>
                          <div className='col-1'>
                            {ut?.invoiceNo != null && (
                              <>
                                {invoiceLinkChecked == index ? (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: '#384a62',
                                      borderRadius: '50%',
                                    }}
                                    className='mx-5 '
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: 'transparent',
                                      borderRadius: '50%',
                                      border: '1px solid #384a62',
                                    }}
                                    onClick={() => {
                                      setInvoiceLinkChecked(index)

                                      ApiGet(`corporate/payment_invoice/${ut?._id}?isCreditNote=true`)
                                        .then((res) => {
                                          setInvoiceToShowEdit(res?.data?.data)
                                          const values = [...invoiceFields]
                                          for (
                                            let i = 0;
                                            i < res?.data?.data?.paymentSubItems?.length;
                                            i++
                                          ) {
                                            values[i] = {
                                              _id: res?.data?.data?.paymentSubItems[i]?._id,
                                              name: res?.data?.data?.paymentSubItems[i]?.name,
                                              amount: res?.data?.data?.paymentSubItems[i]?.amount,
                                              qty: res?.data?.data?.paymentSubItems[i]?.quantity,
                                              vat: res?.data?.data?.paymentSubItems[i]
                                                ?.VATPercentage,
                                              isLateItem:
                                                res?.data?.data?.paymentSubItems[i]
                                                  ?.isLatePaymentFeesRecord,
                                              total:
                                                res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                              amountPaid:
                                                res?.data?.data?.paymentSubItems[i]?.amountPaid,

                                              creditAmount: 0,
                                              creditVat: 0,
                                              vatAmount: res?.data?.data?.paymentSubItems[i]
                                                ?.isLatePaymentFeesRecord
                                                ? (res?.data?.data?.paymentSubItems[i]?.amount /
                                                    100) *
                                                  res?.data?.data?.paymentSubItems[i]?.VATPercentage
                                                : ((res?.data?.data?.paymentSubItems[i]?.amount *
                                                    res?.data?.data?.paymentSubItems[i]?.quantity) /
                                                    100) *
                                                  res?.data?.data?.paymentSubItems[i]
                                                    ?.VATPercentage,
                                              totalWithoutVat: res?.data?.data?.paymentSubItems[i]
                                                ?.isLatePaymentFeesRecord
                                                ? res?.data?.data?.paymentSubItems[i]?.amount
                                                : res?.data?.data?.paymentSubItems[i]?.amount *
                                                  res?.data?.data?.paymentSubItems[i]?.quantity,
                                              newTotal:
                                                res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                              newVat:
                                                res?.data?.data?.paymentSubItems[i]?.VATPercentage,
                                              newTotalWithoutVat: res?.data?.data?.paymentSubItems[
                                                i
                                              ]?.isLatePaymentFeesRecord
                                                ? res?.data?.data?.paymentSubItems[i]?.amount
                                                : res?.data?.data?.paymentSubItems[i]?.amount *
                                                  res?.data?.data?.paymentSubItems[i]?.quantity,
                                              previousCreditAmount:
                                                res?.data?.data?.paymentSubItems[i]?.creditAmount +
                                                res?.data?.data?.paymentSubItems[i]
                                                  ?.creditVATAmount,
                                              totalAmount: 0,
                                              finalAmount: 0,
                                              outstanding:
                                                res?.data?.data?.paymentSubItems[i]
                                                  ?.outstandingAmount,
                                            }
                                            values[i].totalAmount =
                                              values[i]?.totalWithoutVat -
                                              values[i]?.previousCreditAmount
                                            values[i].finalAmount =
                                              values[i].totalAmount + values[i]?.vatAmount
                                            values[i].outstanding =
                                              values[i].finalAmount - values[i]?.amountPaid
                                          }
                                          setInvoiceFields(values)
                                        })
                                        .catch((err: any) => {
                                          console.log('err', err)
                                        })
                                    }}
                                    className='mx-5 '
                                  ></div>
                                )}
                              </>
                            )}
                          </div>
                          <div className='col-1 head-text px-2'>{index + 1}</div>
                          <h6 className='m-0 p-0 col-2 head-text px-2'>
                            {ut?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>{ut?.invoiceNo}</h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>
                            {moment(ut?.createdAt).format('DD.MM.YYYY')}
                          </h6>
                          <h6 className='m-0 p-0 col-4 head-text ps-5'>
                            {ut?.receiptAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                        </div>
                      </>
                    )}
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* INVOICE SubItems Detail modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-950px mx-auto'
        show={invoiceSubItemsModal}
        onHide={() => {
          setInvoiceSubItemsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setInvoiceSubItemsModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-0'>
            <div className='col-md-6 ps-5'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Invoice {invoiceToShowEdit?.invoiceNo}
              </label>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Total{' '}
                {invoiceToShowEdit?.totalAmount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}
              </label>
            </div>

            <div className='col-md-3 align-items-center text-center ms-auto pe-0'>
              <button
                className='btn btn-md fw-bold ms-auto text-white me-1'
                style={{
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={(event) => {
                  if (getPositive(totalCreditAmount) == 0) {
                    ErrorToast('Please input some value to CREDIT !!!')
                  } else {
                    setCreditNoteModal(true)
                    setInvoiceSubItemsModal(false)
                  }
                }}
              >
                Generate Credit Note
              </button>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Credit Amount <span className='ms-2'>{totalCreditAmount} </span>
              </label>
            </div>
          </div>

          {/*  */}
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0 border-white'>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'
                  >
                    Item Name
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-50px '
                  >
                    Qty
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-125px'
                   
                  >
                    {' '}
                    Invoice Amount
                  </th> 
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-175px'
                   
                  >
                    {' '}
                    Previous Credit Amount
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'
                   
                  >
                    {' '}
                    Total Amount
                  </th>

                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-75px'
                   
                  >
                    VAT
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'
                    
                  >
                    {' '}
                    Final Amount
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'
                   
                  >
                    {' '}
                    Amount Paid
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'
                    style={{minWidth: '100px'}}
                  >
                    {' '}
                    Outstanding
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'
                   
                  >
                    Credit Amount{' '}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Enter the amount you wish to credit or reduce from the total amount of
                            the item in the selected invoice (Exc. VAT). Once credit amount is set
                            and credit note is generated, VAT will be automatically updated in the
                            payments page to reflect the new VAT amount on the new Total Amount. if
                            you have applied VAT on the item, then VAT is calculated separately
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <AiOutlineExclamationCircle
                            color='#35475e '
                            fontSize={14}
                            fontWeight={600}
                            className='ms-3 cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-75px'>VAT</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {invoiceFields?.length > 0 &&
                  invoiceFields?.map((field: any, index: any) => {
                    return (
                      <>
                        <tr style={{height: '45px', borderBottom: '2px solid lightgray'}}>
                          <td className='m-0 p-0 head-text p-3'>{field?.name}</td>
                          <td className='m-0 p-0 head-text p-3'>{field.qty}</td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalWithoutVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.previousCreditAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>

                          <td className='m-0 p-0 head-text p-3'>
                            {field?.vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {field?.finalAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>

                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.amountPaid?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.outstanding?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            <input
                              type='number'
                              className='form-control form-control-bg input-style mytest'
                              placeholder='Credit Amount'
                              name='item_id'
                              value={field?.creditAmount}
                              onChange={(event: any) => {
                                const values = [...invoiceFields]
                                values[index].creditAmount = getNegative(event.target.value)

                                if (event.target.value.length == 0) values[index].creditAmount = 0

                                let newTotal =
                                  parseFloat(values[index]?.totalWithoutVat) -
                                  getPositive(values[index].creditAmount)

                                values[index].newTotalWithoutVat = newTotal

                                let newVat: any = 0
                                // if vat applied then calculate Credit vat
                                if (values[index]?.vat > 0) {
                                  newVat = (newTotal / 100) * values[index]?.vat
                                  values[index].creditVat = getNegative(
                                    parseFloat(values[index]?.vatAmount) - newVat
                                  )
                                  values[index].newVat = newVat
                                }

                                values[index].newTotal = newTotal + newVat
                                setInvoiceFields(values)

                                let temp: any = 0
                                for (let i = 0; i < values?.length; i++) {
                                  if (values[i]?.creditAmount != undefined)
                                    temp =
                                      parseFloat(temp) +
                                      parseFloat(values[i]?.creditAmount) +
                                      parseFloat(values[i]?.creditVat)
                                }
                                setTotalCreditAmount(temp)
                              }}
                            />
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.creditVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* Credit Note Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto custom-modal'
        show={creditNoteModal}
        onHide={() => {
          setCreditNoteModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 credit-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Credit Note</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  setCreditNoteModal(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='credit-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={creditNoteWhite} height='80px' width='80px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-4'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row>
            <Col md={8}>
              <div className='d-flex'>
                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 credit-color'
                  style={{
                    width: '120px',

                    color: 'white',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    generateCreditNote()
                  }}
                >
                  Generate
                </button>
              </div>
            </Col>
            <Col md={4} className='py-3 '>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#006699'
                  offColor='#b0bec5'
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>
          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            <Row className='mt-2'>
              <Col md={6} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1>TAX CREDIT NOTE</h1>

                  <img
                    src={editpen}
                    alt=''
                    height='14'
                    width='14'
                    className='ms-2 cursor-pointer'
                    style={{marginTop: '-25px'}}
                  />
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  {invRecDetails?.creditNoteSequence?.activityStatus == 1 ? (
                    <>
                      {invRecDetails?.creditNoteSequence?.prefix != null && (
                        <>
                          <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                            Credit Note No.
                          </label>

                          <input
                            type='text'
                            className={
                              creditNoteNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            placeholder='Enter Invoice No...'
                            name='item_id'
                            value={invRecDetails?.creditNoteSequence?.prefix}
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                        Credit Note No.
                      </label>

                      <input
                        type='text'
                        className={
                          creditNoteNoError
                            ? 'form-control form-control-solid-bg input-style border-danger '
                            : 'form-control form-control-solid-bg input-style mytest'
                        }
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={creditNoteNo}
                        onChange={(event) => {
                          setCreditNoteNo(event.target.value)
                          setCreditNoteNoError(false)
                        }}
                      />
                    </>
                  )}
                </div>
                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Issue Date
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {moment(new Date()).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Invoice No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {invoiceToShowEdit?.invoiceNo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className='d-flex my-4 '>
                  {invRecDetails?.creditNoteLogo && (
                    <img
                      src={`${Bucket}${invRecDetails?.creditNoteLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='ms-auto'
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row className='mt-5 pb-5'>
              <Col md={6} className='text-start' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>{creditNoteCompany?.name}</p>
                <p style={{marginBottom: '0.1rem'}}>{creditNoteCompany?.address}</p>

                <p style={{marginBottom: '0.1rem'}}>
                  {creditNoteCompany?.countryCode} {creditNoteCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{creditNoteCompany?.email}</p>
              </Col>

              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>

            <Row className='mt-0 pb-5' style={{borderBottom: '2px dashed lightgray'}}>
              <Col sm={12}>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{creditNoteCompany?.trn ? creditNoteCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {invoiceFields?.map((field: any, index: any) => {
                  if (getPositive(field?.creditAmount) > 0) {
                    return (
                      <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                        <td className='py-2'>{index + 1}</td>
                        <td className='py-2'>{field.name}</td>
                        <td className='py-2'>{field.qty}</td>
                        <td className='py-2'>
                          {field?.creditAmount?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {field?.creditVat?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className='py-2'>
                          {getNegative(
                            getSum(getPositive(field?.creditAmount), getPositive(field?.creditVat))
                          )}
                        </td>
                      </tr>
                    )
                  }
                })}

                <>
                  {invoiceFields?.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>

                {invoiceFields?.map((f: any, ind: any) => {
                  nonVat = nonVat + getPositive(f?.creditAmount)
                  vatAmount = vatAmount + getPositive(f?.creditVat)

                  return <></>
                })}

                <tr style={{height: '55px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {getNegative(nonVat)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {getNegative(vatAmount)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4 fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {getNegative(nonVat + vatAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={6}>
                {/* Notes */}
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={creditNotes}
                    onChange={(e) => {
                      setCreditNotes(e.target.value)
                    }}
                    maxLength={1000}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CreditNote

import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import addGreen from '../../../../img/add.png'
import RecordAPayment from './RecordAPayment'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import trashImg from '../../../../img/trash.png'
import openImg from '../../../../img/black-collap-down.png'
import closeImg from '../../../../img/black-collap-up.png'
import ViewEditPaymentRecord from './ViewEditPaymentRecord'
import swal from 'sweetalert2'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import { getFileIcon } from '../../../../Utilities/utils'

interface PaymentRecordsProps {
  incmData?: any
  updateParent?: any
  autoSaveParent?: any
  updateItemsInParent?: any
  refreshChild?: any
  autoUpdateParent?: any
  updateParentAfterDelete?: any
}

const PaymentRecords: React.FC<PaymentRecordsProps> = ({
  incmData,
  updateParent,
  autoSaveParent,
  updateItemsInParent,
  refreshChild,
  autoUpdateParent,
  updateParentAfterDelete,
}) => {
  const {state} = useLocation()

  const {flg, type, id, tenancyId, unitId} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [paymentRecords, setPaymentRecords] = useState<any>([
    // {
    //   id: 200,
    //   _id: '668d0aee231e65d638a80476',
    //   rctId : 'RCT9827',
    //   items: [
    //     {
    //       paymentItemListId: '665cd669f0c97aa475d83fdd',
    //       name: 'Rent',
    //       frontendStorage: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //       amount: 2000,
    //       quantity: 1,
    //       totalAmount: 2200,
    //       amountPaid: 1000,
    //       VATPercentage: 10,
    //       VATAmount: 200,
    //       outstandingAmount: 1200,
    //       _id: '668d217b231e65d638ac54d5',
    //       property: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //     },
    //   ],
    //   datePaid: '2024-07-08T00:00:00.000Z',
    //   totalAmountPaid: 1000,
    //   receipts: [],
    //   attachments: [],
    //   createdAt: '2024-07-09T10:03:26.522Z',
    //   updatedAt: '2024-07-09T11:39:39.456Z',
    //   amountPaid: 1000,
    // },
    // {
    //   id: 201,
    //   _id: '66a32e4b4f68f82c82793f48',
    //   rctId : 'RCT9234',
    //   items: [
    //     {
    //       paymentItemListId: '665cd669f0c97aa475d83fdd',
    //       name: 'Rent',
    //       frontendStorage: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //       amount: 2000,
    //       quantity: 1,
    //       totalAmount: 2200,
    //       amountPaid: 500,
    //       VATPercentage: 10,
    //       VATAmount: 200,
    //       outstandingAmount: 700,
    //       _id: '66a32e4b4f68f82c82793f49',
    //       property: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //     },
    //     {
    //       paymentItemListId: '665cd669f0c97aa475d83fd4',
    //       name: 'Security Deposit',
    //       frontendStorage: {
    //         level: 2,
    //         details: {
    //           _id: '6669330ad20741c51976def5',
    //           unitNo: 'unit 3',
    //           id: '213',
    //         },
    //       },
    //       amount: 1000,
    //       quantity: 1,
    //       totalAmount: 1100,
    //       amountPaid: 500,
    //       VATPercentage: 10,
    //       VATAmount: 100,
    //       outstandingAmount: 600,
    //       _id: '66a32e4b4f68f82c82793f4a',
    //       property: {
    //         level: 2,
    //         details: {
    //           _id: '6669330ad20741c51976def5',
    //           unitNo: 'unit 3',
    //           id: '213',
    //         },
    //       },
    //     },
    //   ],
    //   datePaid: '2024-07-17T00:00:00.000Z',
    //   totalAmountPaid: 1000,
    //   receipts: [
    //     {
    //       name: '2024-06-04T15_23_40.647Z_1717514616395.jpg',
    //       URL: '6582bac50d9a049c6882be03/pdf/1717351774213.pdf',
    //       _id: '66a32e4b4f68f82c82793f4b',
    //     },
    //   ],
    //   attachments: [
    //     {
    //       name: 'Pdf-lib_creation_example.pdf',
    //       URL: '6582bac50d9a049c6882be03/invoice_6582bac50d9a049c6882be03/Pdf-lib_creation_example.pdf',
    //       _id: '66a32e4b4f68f82c82793f4c',
    //     },
    //   ],
    //   createdAt: '2024-07-26T05:04:11.712Z',
    //   updatedAt: '2024-07-26T05:04:11.712Z',
    //   amountPaid: 1000,
    // },
  ])
  const [showRecordAPayment, setShowRecordAPayment] = useState<any>(false)
  const [show, setShow] = useState<any>([])

  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>()
  const [showEditAPayment, setShowEditAPayment] = useState<any>(false)

  // console.log('/*******************Payment Records & Receipts********************/')
  // console.log(incmData)

  useEffect(() => {
    setPaymentRecords(incmData?.payment_receipt?.length > 0 ? incmData?.payment_receipt : [])
  }, [incmData])

  //
  const deletePaymentRecord = (val: any, index: any) => {
    const body = {
      payment: {
        _id: id,
      },
      delete_payment_receipt: [{_id: val}],
    }

    ApiPost(`corporate/payment/v1/add_update`, body)
      .then((res: any) => {
        SuccessToast('Payment Record has been deleted successfully')
        let values = [...paymentRecords]
        values.splice(index, 1)
        setPaymentRecords(values)

        updateParentAfterDelete()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  let [itemsForPayments, setItemsForPayments] = useState<any>([])

  const [itemsError, setItemsError] = useState<any>(false)
  const [noInvoiceError, setNoInvoiceError] = useState<any>(false)

  useEffect(() => {
    if (itemsError) {
      let err: any = false

      incmData?.items?.map((itm: any, ind: any) => {
        if (
          (itm?.name?.trim()?.length == 0 ||
          itm?.amount != '' ||
          itm?.quantity != '' ||
          itm?.VATPercentage != '')
        )
          err = true
      })
      if (!err) setItemsError(false)
    }
  }, [incmData])

  //
  const handleImageClick = (val: any, src: any, lst: any, lstInd: any) => {
    // Generate a unique group name for fancybox
    const groupName = `group_${lstInd}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', src)
      // image.setAttribute('src', src)
    }

    // Add data-fancybox attribute to the related receipts
    lst?.slice(1)?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`${lstInd}${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-fancybox', groupName)
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }
    })

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 min-hg-300'}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Payment Records & Receipts '}
          </h2>

          <div className='d-black ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-hollow-btn ms-auto btn-fit-content pe-5'
              onClick={() => {
                let error: any = false

                if (incmData?.payment_invoice?.length == 0) setNoInvoiceError(true)
                else {
                  setNoInvoiceError(false)

                 
                  

                  if (incmData?.items?.length > 0) {
                    setNoInvoiceError(false)

                    let valuesItems = {...incmData}
                    console.log(incmData)
                    valuesItems?.items?.forEach((itm: any, ind: any) => {
                     
                      if (itm?.frontendStorage?.isLatePaymentItem === false &&
                        (itm?.name?.trim()?.length === 0 ||
                        itm?.amount === '' ||
                        itm?.amount === undefined ||
                        Number(itm?.amount) == 0 ||
                        itm?.quantity === '' ||
                        itm?.quantity === undefined ||
                        Number(itm?.quantity) == 0 ||
                        itm?.VATPercentage === '' ||
                        itm?.VATPercentage === undefined )
                      ) {
                        error = true
                      }
                    })

                    //     // console.log(error)
                    if (error) setItemsError(true)
                    else setItemsError(false)

                    if (!error) {
                      valuesItems?.items?.map((itm: any, ind: any) => {
                        // console.log(valuesItems.items[ind])
                        // if (isNaN(valuesItems.items[ind].totalAmountPaid))
                        //   valuesItems.items[ind].totalAmountPaid = Number(
                        //     valuesItems?.items[ind].amountPaid
                        //   )
                        // else
                        //   valuesItems.items[ind].totalAmountPaid =
                        //     Number(valuesItems.items[ind].totalAmountPaid) +
                        //     Number(valuesItems?.items[ind].amountPaid)
                        // valuesItems.items[ind].amountPaid = 0
                        // valuesItems.items[ind].outstandingAmount =
                        //   Number(valuesItems?.items[ind].totalAmount) -
                        //   Number(valuesItems?.items[ind].totalAmountPaid)
                      })

                      setItemsForPayments(valuesItems)

                      setTimeout(() => {
                        setShowRecordAPayment(true)
                      }, 1000)
                    }
                  } else {
                    setItemsError(false)
                    
                  }
                }
              }}
              // disabled={incmData?.items?.length == 0}
            >
              <img src={addGreen} height={18} width={18} className='me-4' /> Record a Payment
            </button>

            {noInvoiceError && (
              <p className='text-danger mb-0 ms-5'> Please generate an invoice first.</p>
            )}

            {itemsError && (
              <p className='text-danger mb-0 ms-5'> Please complete all payment item fields.</p>
            )}
          </div>
        </div>

        <div className='table-responsive mt-4' style={{maxHeight: '700px'}}>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px '  style={{maxWidth:'100px'}}>Payment ID</th>
                <th className='text-start min-w-150px '  style={{maxWidth:'150px'}}>Payment Items</th>
                <th className='text-start min-w-200px '  style={{maxWidth:'200px'}}>Amount Paid</th>
                <th className='text-start min-w-125px '  style={{maxWidth:'125px'}}>Date Paid</th>
                <th className='text-start min-w-125px '  style={{maxWidth:'125px'}}>Receipt ID</th>
                <th className='text-start min-w-125px '  style={{maxWidth:'125px'}}>Receipt</th>
                <th className='text-start min-w-125px '  style={{maxWidth:'125px'}}>Other Docs</th>
                <th className='text-start min-w-200px '  style={{maxWidth:'200px'}}>Date Created</th>
                <th className='text-end min-w-100px'  style={{maxWidth:'100px'}}></th>
              </tr>
            </thead>

            <tbody className='fw-semibold text-gray-600 payment-records'>
              {paymentRecords?.map((pr: any, ind: any) => {
                return (
                  <>
                    <tr
                      style={
                        show[ind] == true
                          ? {borderBottomStyle: 'none', height: '30px'}
                          : show[ind] == false
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : pr?.items?.length == 1
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : {height: '30px'}
                      }
                    >
                      <td className='text-start'>
                        {' '}
                        <span className=''>
                          <a
                            className='green_color text-decoration-underline cursor-pointer'
                            onClick={() => {
                              setSelectedInd(ind)
                              setSelectedRecord(pr)
                              setShowEditAPayment(true)
                            }}
                          >
                            {pr?.id ? pr?.id : '-'}
                          </a>
                        </span>
                        <div className='d-block'>
                          {pr?.paymentSubItems?.length > 1 && (
                            <img
                              src={show[ind] ? closeImg : openImg}
                              height='15'
                              width='15'
                              className='cursor-pointer'
                              onClick={() => {
                                if (show[ind] == true) {
                                  let values = [...show]
                                  values[ind] = false
                                  setShow(values)
                                } else {
                                  let values = [...show]

                                  values[ind] = true

                                  setShow(values)
                                }
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td
                        className='text-start'
                        onClick={() => {
                          setSelectedInd(ind)
                          setSelectedRecord(pr)
                          setShowEditAPayment(true)
                        }}
                      >
                        {pr?.paymentSubItems?.length > 1 ? (
                          <>{pr?.items?.length} items</>
                        ) : (
                          <>{pr?.paymentSubItems?.[0]?.name}</>
                        )}
                      </td>
                      <td className='text-start'>
                        {pr?.amountPaid > 0
                          ? `AED ${pr?.amountPaid?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}`
                          : 'AED -'}
                      </td>
                      <td className='text-start'>
                        {pr?.datePaid != null
                          ? `${moment?.utc(pr?.datePaid)?.local()?.format('DD.MM.YYYY')}`
                          : '-'}
                      </td>
                      <td className='text-start'>{pr?.receiptNo ? pr?.receiptNo : '-'}</td>
                     

                      <td className='text-start'>
                        <div className='d-flex justify-content-start'>
                          {pr?.receiptAttachment?.fileURL?.trim()?.length > 0 ? (
                            <div className='d-flex align-items-baseline justify-content-start my-3'>
                              <img
                                src={getFileIcon(pr?.receiptAttachment?.fileURL)}
                                height={30}
                                width={30}
                                className='me-2 cursor-pointer'
                                id={`clickedImage${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedImage${ind}`,
                                    `${Bucket}${pr?.receiptAttachment?.fileURL}`,
                                    pr?.receipts,
                                    `clickedImageReceipt${ind}-`
                                  )
                                }
                              />
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>

                      <td className='text-start'>
                        <div className='d-flex justify-content-start'>
                          {pr?.otherAttachment?.length > 0 ? (
                            <div className='d-flex align-items-baseline justify-content-start my-3'>
                              {/* <a href={`${Bucket}${pr?.attachment?.URL}`} target='_blank'> */}
                              <img
                                src={getFileIcon(pr?.otherAttachment?.[0]?.fileURL)}
                                height={30}
                                width={30}
                                className='me-2 cursor-pointer'
                                // data-fancybox
                                // data-src={`${Bucket}${pr?.otherAttachment?.[0]?.fileURL}`}
                                id={`clickedImageAttachment${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedImageAttachment${ind}`,
                                    `${Bucket}${pr?.otherAttachment?.[0]?.fileURL}`,
                                    pr?.otherAttachment,
                                    `clickedImageAttach${ind}-`
                                  )
                                }
                              />
                              {pr?.otherAttachment?.slice(1).map((attachmnt: any, idx: any) => (
                                <img
                                  key={idx}
                                  src={getFileIcon(attachmnt?.fileURL)}
                                  height={30}
                                  width={30}
                                  className='d-none' // hide these images by default
                                  id={`clickedImageAttach${ind}-${idx}`}
                                />
                              ))}
                              {pr?.otherAttachment?.length > 1 && (
                                <div className='mb-1'> +{pr?.otherAttachment?.length - 1}</div>
                              )}
                              {/* </a> */}
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>
                      <td className='text-start'>
                        {pr?.createdAt != null
                          ? `${moment?.utc(pr?.createdAt)?.local()?.format('DD.MM.YYYY')}`
                          : '-'}
                      </td>

                      <td className='text-end '>
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            swal
                              .fire({
                                html: ` 
                                <div class='fs-3'> <b>Delete Payment Record</></div>
                                 <div class='fs-5 mt-4'>You are about to delete the payment record.</div>
                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  if (flg == '1') {
                                    let values = [...paymentRecords]
                                    values.splice(ind, 1)
                                    setPaymentRecords(values)
                                  } else deletePaymentRecord(pr?._id, ind)
                                }
                              })
                          }}
                        />
                      </td>
                    </tr>

                    {pr?.items?.length > 1 &&
                      show[ind] == true &&
                      pr?.items?.map((prSub: any, index: any) => {
                        return (
                          <>
                            <tr
                              style={
                                index == pr?.items?.length - 1
                                  ? {borderBottomStyle: 'dashed', height: '30px'}
                                  : {borderBottomStyle: 'none', height: '30px'}
                              }
                            >
                              <td style={{paddingLeft: '5px'}}></td>
                              <td>{prSub?.name}</td>
                              <td>
                                {flg == '1' ? (
                                  <>
                                    {prSub?.totalAmountPaid > 0
                                      ? `AED ${prSub?.totalAmountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : 'AED -'}
                                  </>
                                ) : (
                                  <>
                                    {prSub?.amountPaid > 0
                                      ? `AED ${prSub?.amountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : 'AED -'}
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Report Modal */}
      {showRecordAPayment && itemsForPayments && (
        <RecordAPayment
          show={showRecordAPayment}
          handleClose={() => {
            setShowRecordAPayment(false)
          }}
          expData={itemsForPayments}
          updateData={(dataPay: any, dataItem: any) => {
            // console.log(data)
            let values = [...paymentRecords]
            values.push(dataPay)

            // console.log(values)

            updateParent(values, dataItem)
            setPaymentRecords(values)

            autoSaveParent(dataPay, dataItem)

            for (let i = 0; i < values.length; i++) {
              let values = [...show]
              values[i] = false
              setShow(values)
            }

            setShowRecordAPayment(false)
          }}
          updateItems={(data: any) => {
            // console.log(data)
            // updateItemsInParent(data)
          }}
        />
      )}

      {/* Add Report Modal */}
      {showEditAPayment && selectedRecord && (
        <ViewEditPaymentRecord
          show={showEditAPayment}
          handleClose={() => {
            setShowEditAPayment(false)
          }}
          expData={incmData}
          updateData={(data: any) => {
            // console.log(data)
            let values = [...paymentRecords]
            values[selectedInd] = data
            setPaymentRecords(values)

            for (let i = 0; i < values.length; i++) {
              let values = [...show]
              values[i] = false
              setShow(values)
            }

            updateParent(values, values[selectedInd].items)

            autoUpdateParent(values[selectedInd])

            setShowRecordAPayment(false)
          }}
          updateDataAfterDelete={() => {
            let values = [...paymentRecords]
            values.splice(selectedInd, 1)
            setPaymentRecords(values)
            setShowEditAPayment(false)
          }}
          selectedItem={selectedRecord}
        />
      )}
    </>
  )
}

export default PaymentRecords

import React, {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'
import redCrossFilled from '../../../../../../img/remove-filled.png'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import addWhite from '../../../../../../img/add-white.png'
import moment, {Moment} from 'moment'
import TextEditor from '../../../../General/TextEditor'
import {ApiGet, Bucket, ApiPost} from '../../../../../../apiCommon/helpers/API/ApiData'
import {Backdrop, CircularProgress} from '@mui/material'
import moveUpImg from '../../../../../../img/scroll-up.png'
import movedownImg from '../../../../../../img/scroll-down.png'
import addIcon from '../../../../../../img/add-black.png'
import InputNotRequired from '../../../../General/InputNotRequired'
import {DatePicker} from 'antd'
import PaymentItems from './PaymentItems'
import {Controller} from 'react-hook-form'
import InputRequired from '../../../../General/InputRequired'
import {capitalizeFirstLetter} from '../../../../../../Utilities/utils'

interface InvoiceI {
  incomeData: any
  updateIncomeData: any
  incomeAPIDetails: any
  setIncomeAPIDetails: any
  idToDelete?: any
  setIDToDelete?: any
  register: any
  control: any
  setValue: any
  errors: any
  trigger: any
  autoSaveData?: any
  setAutoSaveData?: any
  open?: any
  setOpen?: any
  editMode?: boolean
  innerRef?: any
  isPrint?: any
  isEditAfterCreate?: boolean,
  isAlreadySaveData?: any,
  setIsAlreadySaveData?: any
}

const GenerateInvoice = ({
  innerRef,
  incomeData,
  updateIncomeData,
  incomeAPIDetails,
  setIncomeAPIDetails,
  idToDelete,
  setIDToDelete,
  control,
  errors,
  register,
  setValue,
  trigger,
  autoSaveData,
  setAutoSaveData,
  open,
  setOpen,
  editMode = true,
  isPrint = false,
  isEditAfterCreate = false,
  isAlreadySaveData = false,
  setIsAlreadySaveData = () => {}

}: InvoiceI) => {
  const {flg, id}: any = useParams()

  const [invRecDetails, setInvRecDetails] = useState<any>(null)

  // Sections
  const handleAddNewSections = () => {
    let values = {...incomeData}
    let valueItems: any = [...incomeData?.itemDescriptions]
    valueItems.push({
      name: '',
      content: '',
    })
    values.itemDescriptions = valueItems
    updateIncomeData(values)
  }

  //
  const handleRemoveSections = (index: any) => {
    let values = {...incomeData}
    let valueItems: any = [...incomeData?.itemDescriptions]
    valueItems.splice(index, 1)
    values.itemDescriptions = valueItems
    updateIncomeData(values)

    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)
  }

  //
  const getInvoiceDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        console.log(res?.data?.data)
        if (flg == '1') {
          let values = {...incomeData}
          values.invoiceCompany = res?.data?.data?.invoiceCompany
          values.invoiceLogo = res?.data?.data?.invoiceLogo
          updateIncomeData(values)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const swapItems = (prevInd: any, nextInd: any) => {
    const values = {...incomeData}
    const valuesSections = [...incomeData?.itemDescriptions]

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    updateIncomeData({...values, itemDescriptions: valuesSections})

    setTimeout(() => {
      let temp = valuesSections[prevInd]
      valuesSections[prevInd] = valuesSections[nextInd]
      valuesSections[nextInd] = temp
      valuesSections[prevInd].animationClass = ''
      valuesSections[nextInd].animationClass = ''

      values.itemDescriptions = valuesSections
      updateIncomeData(values)
    }, 300)

    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)

    // setTimeout(() => {
    //   valuesSections[prevInd].animationClass = ''
    //   valuesSections[nextInd].animationClass = ''

    //   values.itemDescriptions = valuesSections
    //   setInvoiceData(values)
    // }, 200)
  }

  useEffect(() => {
    if(flg == '1' || flg === '3')
      getInvoiceDetails()
  }, [])

  useEffect(() => {
    if(flg == '2')
      setInvRecDetails(incomeData?.settingDetails)
  }, [])

  const handleDateChange = async (date: any) => {
    setIsAlreadySaveData(Date?.now())
    const values = {...incomeData}

    if (date != null) {
      values.dueDate = date.format('YYYY-MM-DD')
      setValue('dueDate', date.format('YYYY-MM-DD'), {shouldValidate: true})

      updateIncomeData(values)
    } else {
      values.dueDate = null
      setValue('dueDate', '', {shouldValidate: true})
      updateIncomeData(values)
    }

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }

    // setRefreshChild(Date.now())
  }

  function disabledDate(current: Moment) {
    return current && current < moment().startOf('day')
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <div id='' className='pt-0 mt-0'>
          <div className='card mt-5 px-6 py-5' style={{minHeight: '75vh'}}>
            <div ref={innerRef} className='invoice-content'>
              <Row className=''>
                <Col xs={6} sm={6} md={9} lg={9}>
                  <div className='d-flex mb-3 mt-2 align-items-center'>
                    {incomeData?.invoiceLogo == null ||
                    incomeData?.invoiceLogo?.trim()?.length == 0 ? (
                      <></>
                    ) : (
                      <img
                        src={`${Bucket}${incomeData?.invoiceLogo}`}
                        height='110'
                        width='110'
                        style={{borderRadius: '7px'}}
                        className='me-4'
                      />
                    )}
                  </div>
                </Col>
                <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
                  <div className='d-flex align-items-center my-2'>
                    <label
                      className='mb-1 me-5 pe-5 ms-auto head-text light-dark-theme-color'
                      style={{minWidth: '50px', maxWidth: '50px'}}
                    >
                      <b>TRN</b>
                    </label>
                    <b className='white-dark-theme-color'>{incomeData?.invoiceCompany?.trn ? incomeData?.invoiceCompany?.trn : '-'}</b>
                  </div>
                 
                  <div className='text-end'>
                      {editMode == true ? (
                         <div className='my-5 '>
                            <InputNotRequired
                              required={true}
                              register={register}
                              errors={errors}
                              value={incomeData.invoiceName}
                              setValue={setValue}
                              trigger={trigger}
                              onBlur={() => {
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700)
                              }}
                              onChange={(val: any) => {
                                setIsAlreadySaveData(Date?.now())
                                updateIncomeData({...incomeData, ['invoiceName']: val})
                              }} // Pass the callback function to handle input changes
                              placeholder='Enter Document Name'
                              name='documentName'
                              pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                              maxLength={30} // Maximum length of 50 characters
                              // style={{height: '35px', width: '260px'}}
                              className='text-end document-name-input-field'
                            />
                        </div>
                      ) : (
                        <p className='ms-auto pt-1 document-name-input-field my-5 fnt-500 white-dark-theme-color'>
                          {incomeData?.invoiceName ? incomeData?.invoiceName : '-'}
                        </p>
                      )}
                    </div>
                  

                  {/*  */}
                  <div className='d-flex align-items-start justify-content-start my-2'>
                    <label
                      className='mb-1 me-3 ms-15 pt-2 head-text light-dark-theme-color fnt-600'
                      style={{
                        minWidth: '70px',
                        fontSize: '1rem',
                        // color: '#516176',
                        // fontWeight: '600',
                      }}
                    >
                      Invoice No.
                    </label>
                    {invRecDetails?.invoiceSequence?.activityStatus === 1 ? (
                      <>
                        {invRecDetails?.invoiceSequence?.prefix != null && (
                          <p className='pt-2 mb-0 ms-auto fnt-500 white-dark-theme-color'>
                            {incomeData?.invoiceNo ? incomeData?.invoiceNo : '-'}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {editMode == true ? (
                          <div className='ms-auto' style={{fontWeight: '500'}}>
                            <InputRequired
                              isRequired={true}
                              register={register}
                              errors={errors}
                              value={incomeData.invoiceNo}
                              setValue={setValue}
                              trigger={trigger}
                              onBlur={() => {
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700) 
                              }}
                              onChange={(val: any) => {
                                setIsAlreadySaveData(Date?.now())
                                updateIncomeData({...incomeData, ['invoiceNo']: val})
                              }} // Pass the callback functiodocumentInvoiceNon to handle input changes
                              placeholder='Invoice No.'
                              name='invoiceNo'
                              pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                              maxLength={15} // Maximum length of 50 characters
                              style={{height: '35px'}}
                              className='text-start'
                            />
                          </div>
                        ) : (
                          <p className='pt-2 mb-0 ms-auto fnt-500 white-dark-theme-color'>
                            {incomeData?.invoiceNo ? incomeData?.invoiceNo : '-'}
                          </p>
                        )}
                      </>
                    )}
                  </div>

                  {/*  */}
                  <div className='d-flex align-items-center justify-content-start my-4 head-text light-dark-theme-color fnt-600'>
                    <label
                      className='mb-1 me-3 ms-15'
                      style={{
                        minWidth: '70px',
                        fontSize: '1rem',
                        // color: '#516176',
                        // fontWeight: '600',
                      }}
                    >
                      Invoice Date
                    </label>
                    <div className='ms-auto fnt-500 white-dark-theme-color'>
                      {' '}
                      {Number(incomeData?.formStatus) === 1
                        ? moment(incomeData?.createdAt)?.format('DD.MM.YYYY')
                        : '-'}
                    </div>
                  </div>

                  {/*  */}
                  <div className='d-flex align-items-center justify-content-start mt-4 head-text light-dark-theme-color'>
                    <label
                      className='mb-1 me-3 ms-15'
                      style={{
                        minWidth: '70px',
                        fontSize: '1rem',
                        // color: '#516176',
                        // fontWeight: '600',
                      }}
                    >
                      Due Date
                    </label>
                    {editMode == true ? (
                      <div className='ms-auto' style={{fontWeight: '500'}}>
                        {/* Due date */}
                        <div className='d-flex'>
                          <div>
                            <div>
                              <Controller
                                name='dueDate'
                                control={control}
                                rules={{
                                  required: 'this is required',
                                }}
                                render={({field}) => (
                                  <div className='expense-date tst'>
                                    {incomeData?.dueDate != null ? (
                                      <DatePicker
                                        name='dueDate'
                                        value={moment(new Date(incomeData?.dueDate), 'DD.MM.YYYY')}
                                        format={'DD.MM.YYYY'}
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        placeholder='Select Due Date'
                                      />
                                    ) : (
                                      <DatePicker
                                        name='dueDate'
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        placeholder='Select Due Date'
                                      />
                                    )}
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <h3 className='text-danger mb-0'>
                            <b>*</b>
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <p className='pt-2 mb-0 ms-auto fnt-500 white-dark-theme-color'>
                        {moment(incomeData?.dueDate)?.format('DD.MM.YYYY')}
                      </p>
                    )}
                  </div>
                  {editMode == true ? (
                    <div className='ms-auto d-flex'>
                      <label
                        className=' me-3 ms-15'
                        style={{
                          minWidth: '70px',
                          fontSize: '1rem',
                          color: '#516176',
                          fontWeight: '600',
                        }}
                      ></label>
                      {errors.dueDate && (
                        <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                          This field is required
                        </p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>

              <Row className='pe-0 d-flex align-items-start mt-10'>
                <Col sm={6}>
                  <div style={{fontWeight: '600'}} className='w-100'>
                    <div className='d-flex align-items-center' style={{height: '35px'}}>
                      <h3 className='mb-3 fs-3 white-dark-theme-color'>
                        <b>Recipient</b>
                      </h3>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className='d-flex align-items-center justify-content-end'>
                    <h3 className='mb-3 fs-3 white-dark-theme-color'>
                      <b>
                        {incomeData?.invoiceCompany?.name != null &&
                          incomeData?.invoiceCompany?.name}
                      </b>
                    </h3>
                  </div>
                </Col>
              </Row>

              <Row className='pe-0 d-flex align-items-start mt-1'>
                <Col sm={6}>
                  {flg === '1' ? (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.payorData?.tenant?.[0]?.firstName &&
                      incomeData?.payorData?.tenant?.[0]?.lastName
                        ? `${incomeData?.payorData?.tenant?.[0]?.firstName} ${incomeData?.payorData?.tenant?.[0]?.lastName}`
                        : '-'}
                    </p>
                  ) : (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.tenant?.[0]?.firstName && incomeData?.tenant?.[0]?.lastName
                        ? `${incomeData?.tenant?.[0]?.firstName} ${incomeData?.tenant?.[0]?.lastName}`
                        : '-'}
                    </p>
                  )}
                </Col>
                <Col sm={6} className='d-flex justify-content-end'>
                  <p style={{marginBottom: '0.1rem'}} className='fs-7 fw-bold white-dark-theme-color'>
                    {' '}
                    {incomeData?.invoiceCompany?.address != null &&
                      incomeData?.invoiceCompany?.address}
                  </p>
                </Col>
              </Row>
              <Row className='pe-0 d-flex align-items-start mt-1'>
                <Col sm={6}>
                  {flg === '1' ? (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.payorData?.tenant?.[0]?.countryCode &&
                      incomeData?.payorData?.tenant?.[0]?.phoneNumber
                        ? `+${incomeData?.payorData?.tenant?.[0]?.countryCode} ${incomeData?.payorData?.tenant?.[0]?.phoneNumber}`
                        : '-'}
                    </p>
                  ) : (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.tenant?.[0]?.countryCode && incomeData?.tenant?.[0]?.phoneNumber
                        ? `+${incomeData?.tenant?.[0]?.countryCode} ${incomeData?.tenant?.[0]?.phoneNumber}`
                        : '-'}
                    </p>
                  )}
                </Col>
                <Col sm={6} className='d-flex justify-content-end'>
                  <p style={{marginBottom: '0.1rem'}} className='fs-7 fw-bold white-dark-theme-color'>
                    {incomeData?.invoiceCompany?.phoneNumber != null &&
                      incomeData?.invoiceCompany?.countryCode +
                        incomeData?.invoiceCompany?.phoneNumber}
                  </p>
                </Col>
              </Row>

              <Row className='pe-0 d-flex align-items-start mt-1'>
                <Col sm={6}>
                  {flg === '1' ? (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.payorData?.tenant?.[0]?.email
                        ? incomeData?.payorData?.tenant?.[0]?.email
                        : '-'}
                    </p>
                  ) : (
                    <p style={{marginBottom: '0.1rem'}} className='fw-bold white-dark-theme-color'>
                      {incomeData?.tenant?.[0]?.email ? incomeData?.tenant?.[0]?.email : '-'}
                    </p>
                  )}
                </Col>
                <Col sm={6} className='d-flex justify-content-end'>
                  <p style={{marginBottom: '0.1rem'}} className='fs-7 fw-bold white-dark-theme-color'>
                    {' '}
                    {incomeData?.invoiceCompany?.email != null && incomeData?.invoiceCompany?.email}
                  </p>
                </Col>
              </Row>

              {incomeData && (
                <PaymentItems
                isAlreadySaveData={isAlreadySaveData}
                setIsAlreadySaveData={setIsAlreadySaveData}
                  isEditAfterCreate={isEditAfterCreate}
                  isPrint={isPrint}
                  incmAPIDetails={incomeAPIDetails}
                  incmData={incomeData}
                  updateParent={(data: any) => {
                    let values: any = {...incomeData}
                    values.items = data
                    updateIncomeData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  updateRequest={(total: any, subTotal: any, vatTotal: any) => {
                    console.log(total, subTotal)
                    let values = {...incomeData}
                    values.totalAmount = total
                    values.subTotalAmount = subTotal
                    values.totalVAT = vatTotal
                    updateIncomeData(values)
                  }}
                  isEdit={editMode}
                  updateLatePaymentType={(val: any, data: any, lateFee: any) => {
                    let values = {...incomeData}
                    values.latePaymentType = val
                    values.items = data
                    values.frontendStorage.lateFeeForAll = lateFee
                    updateIncomeData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  updateParentForAll={(data: any, lateFee: any) => {
                    console.log(data)
                    let values: any = {...incomeData}
                    values.items = data
                    values.frontendStorage.lateFeeForAll = lateFee
                    updateIncomeData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  doNotApplyLatePaymentType={(
                    val: any,
                    data: any,
                    deleteItemsIds: any,
                    lateFee: any
                  ) => {
                    setIDToDelete(deleteItemsIds)
                    let values = {...incomeData}

                    values.items = data
                    values.frontendStorage.lateFeeForAll = lateFee

                    values.latePaymentType = val

                    updateIncomeData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                />
              )}

              <Row className='mt-5 separator mb-10 mx-0'></Row>
              {editMode ? (
                <Row>
                  {incomeData?.showAccountDetails ? (
                    <Col sm={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div
                        className={`card card-flush py-5 container-xxl mt-2  pe-5 mb-5  h-100 px-6 `}
                      >
                        <div className='d-flex'>
                          <h2 className='top ps-0 white-dark-theme-color'>
                            <b>Payment Method</b>
                          </h2>

                          <h3 className='head-text ms-auto min-w-200px'>
                            {Number(incomeData?.paymentMethod) === 0
                              ? 'Card'
                              : Number(incomeData?.paymentMethod) === 1
                              ? 'Cheque'
                              : Number(incomeData?.paymentMethod) === 2
                              ? 'Bank'
                              : 'Cash'}
                          </h3>

                          {editMode ? (
                            <img
                              src={redCrossFilled}
                              height={18}
                              width={18}
                              className='cursor-pointer'
                              onClick={() => {
                                let values = {...incomeData}
                                values.showAccountDetails = false
                                updateIncomeData(values)

                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700)
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className='d-flex mt-3 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Account Name
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                              <b>
                                {incomeData?.selectedAccount?.[0]?.accountHolderName
                                  ? incomeData?.selectedAccount?.[0]?.accountHolderName
                                  : '-'}
                              </b>
                          </span>
                        </div>
                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              IBAN
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.IBAN
                                ? incomeData?.selectedAccount?.[0]?.IBAN
                                : '-'}
                            </b>
                          </span>
                        </div>

                        {/* <div className='d-flex my-1 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          SWIFT
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.swiftCode
                            ? paymentData?.payment_account?.swiftCode
                            : '-'}
                        </b>
                      </span>
                    </div> */}

                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Account No.
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.accountNumber
                                ? incomeData?.selectedAccount?.[0]?.accountNumber
                                : '-'}
                            </b>
                          </span>
                        </div>
                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Bank Name
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.bankName
                                ? incomeData?.selectedAccount?.[0]?.bankName
                                : '-'}
                            </b>
                          </span>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col sm={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div
                        className='custom-section'
                        onClick={() => {
                          let values = {...incomeData}
                          values.showAccountDetails = true
                          updateIncomeData(values)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                      >
                        <div className='content'>
                          <img src={addIcon} height={18} width={18} className='me-4' />
                          <h4 className='my-2 mb-3'>
                            <label className={`text-gray-400 fw-bold fs-4`}>
                              {' '}
                              Add Payment Method &
                            </label>
                            <label className={`text-gray-400 fw-bold fs-4`}>
                              {' '}
                              Receiving Account Section.
                            </label>
                          </h4>
                        </div>
                      </div>
                    </Col>
                  )}
                  {incomeData?.showNotes ? (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div
                        className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}
                      >
                        <div className='mb-5 h-10'>
                          <div className='d-flex align-items-start mb-4'>
                            <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                              Notes
                            </h2>
                            {editMode ? (
                              <img
                                src={redCrossFilled}
                                height={18}
                                width={18}
                                className='cursor-pointer ms-auto'
                                onClick={() => {
                                  let values = {...incomeData}
                                  values.showNotes = false
                                  updateIncomeData(values)

                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 700)
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>

                          <textarea
                            className='form-control form-control-solid-bg input-style mytest bg-white'
                            name='comment'
                            placeholder='Enter any additional notes to the recipient'
                            style={{height: '150px', borderColor: 'lightgray'}}
                            value={incomeData?.note}
                            onChange={(e) => {
                              const values = {...incomeData}
                              values.note = e.target.value
                              setIsAlreadySaveData(Date?.now())
                              updateIncomeData(values)
                            }}
                            maxLength={1000}
                            onBlur={() => {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div
                        className='custom-section'
                        onClick={() => {
                          let values = {...incomeData}
                          values.showNotes = true
                          updateIncomeData(values)

                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                      >
                        <div className='content'>
                          <img src={addIcon} height={18} width={18} className='me-4' />
                          <h4 className='my-2 mb-3'>
                            <label className={`text-gray-400 fw-bold fs-4`}>
                              {' '}
                              Add Recipient Notes Section
                            </label>
                          </h4>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row>
                  {incomeData?.showAccountDetails && (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div
                        className={` py-5 container-xxl mt-2  pe-5 mb-5  h-100 ${
                          isPrint ? '' : 'px-6'
                        }`}
                      >
                        <div className='d-flex'>
                          <h2 className='top ps-0 white-dark-theme-color'>
                            <b>Payment Method</b>
                          </h2>

                          <h3
                            className={`head-text ms-auto  min-w-200px light-dark-theme-color ${isPrint ? 'ps-10' : ''}`}
                          >
                            {Number(incomeData?.paymentMethod) === 0
                              ? 'Card'
                              : Number(incomeData?.paymentMethod) === 1
                              ? 'Cheque'
                              : Number(incomeData?.paymentMethod) === 2
                              ? 'Bank'
                              : 'Cash'}
                          </h3>
                        </div>
                        <div className='d-flex mt-3 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Account Name
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.accountHolderName
                                ? incomeData?.selectedAccount?.[0]?.accountHolderName
                                : '-'}
                            </b>
                          </span>
                        </div>
                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              IBAN
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.IBAN
                                ? incomeData?.selectedAccount?.[0]?.IBAN
                                : '-'}
                            </b>
                          </span>
                        </div>

                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Account No.
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.accountNumber
                                ? incomeData?.selectedAccount?.[0]?.accountNumber
                                : '-'}
                            </b>
                          </span>
                        </div>
                        <div className='d-flex my-1 mb-0 align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry light-dark-theme-color'>
                              {' '}
                              Bank Name
                            </label>
                          </h4>

                          <span className='ms-1 white-dark-theme-color'>
                            <b>
                              {incomeData?.selectedAccount?.[0]?.bankName
                                ? incomeData?.selectedAccount?.[0]?.bankName
                                : '-'}
                            </b>
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}
                  {incomeData?.note && incomeData?.showNotes && !isPrint ? (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div className={'py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}>
                        <div className='mb-5 h-10'>
                          <div className='d-flex align-items-start mb-4'>
                            <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                              Notes
                            </h2>
                          </div>

                          <p className=' mb-0 ms-auto light-dark-theme-color'>
                            {incomeData?.note && incomeData?.showNotes ? incomeData?.note : '-'}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : incomeData?.note && incomeData?.showNotes && isPrint ? (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div className={'py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}>
                        <div className='mb-5 h-10'>
                          <div className='d-flex align-items-start mb-4'>
                            <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                              Notes
                            </h2>
                          </div>

                          <p className=' mb-0 ms-auto'>
                            {incomeData?.note && incomeData?.showNotes ? incomeData?.note : '-'}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : !incomeData?.note && incomeData?.showNotes && !isPrint ? (
                    <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                      <div className={'py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}>
                        <div className='mb-5 h-10'>
                          <div className='d-flex align-items-start mb-4'>
                            <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                              Notes
                            </h2>
                          </div>

                          <p className=' mb-0 ms-auto light-dark-theme-color'>
                            {incomeData?.note && incomeData?.showNotes ? incomeData?.note : '-'}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              )}

              {incomeData?.itemDescriptions?.map((pr: any, ind: any) => {
                return (
                  <div
                    className={`${pr.animationClass} px-6 ${editMode ? '' : 'po-editor-view pb-7'}`}
                  >
                    <div className={`d-flex mb-2 mt-7 align-items-center `}>
                      <h3 className='m-0 head-text text-capitalize'>
                        {editMode ? (
                          <input
                            type='text'
                            className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                            name='mainService'
                            value={pr?.title ? pr?.title : ''}
                            style={{height: '35px', width: '300px'}}
                            placeholder={'Add Section Title'}
                            onChange={(e: any) => {
                              const value = e.target.value
                              let values = {...incomeData}
                              let valuesSections = [...incomeData?.itemDescriptions]
                              valuesSections[ind].title = capitalizeFirstLetter(value)
                              values.itemDescriptions = valuesSections
                              setIsAlreadySaveData(Date?.now())
                              updateIncomeData(values)
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }}
                            autoComplete='off'
                          />
                        ) : (
                          <p className='pt-2 mb-0 ms-auto'>
                            <h2 className='top ps-0'>
                              <b>{pr?.title ? pr?.title : '-'}</b>
                            </h2>
                          </p>
                        )}
                      </h3>

                      <div className='d-flex ms-auto align-items-center'>
                        {incomeData?.itemDescriptions?.length > 1 && (
                          <>
                            {ind != incomeData?.itemDescriptions?.length - 1 && editMode && (
                              <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    {`Move this section one step down`}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <img
                                    src={movedownImg}
                                    height={20}
                                    width={20}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      swapItems(ind, ind + 1)
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            )}

                            {ind != 0 && editMode && (
                              <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    {`Move this section one step up`}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <img
                                    src={moveUpImg}
                                    height={20}
                                    width={20}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      swapItems(ind - 1, ind)
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            )}
                          </>
                        )}

                        {editMode ? (
                          <img
                            src={redCrossFilled}
                            height={18}
                            width={18}
                            className='ms-5 cursor-pointer'
                            onClick={() => {
                              handleRemoveSections(ind)
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <TextEditor
                      value={pr?.html}
                      onChange={(val: any) => {
                        let values = {...incomeData}
                        let valuesSections = [...incomeData?.itemDescriptions]
                        valuesSections[ind].html = val
                        values.itemDescriptions = valuesSections
                        setIsAlreadySaveData(Date?.now())
                        updateIncomeData(values)
                      }}
                      autoSaveText={() => {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }}
                      refresh={incomeData}
                      placeholder={'Add section details here...'}
                      isReadOnly={editMode ? false : true}
                      onCursorEnter={() => {}}
                    />
                  </div>
                )
              })}

              {editMode ? (
                <div className='d-flex justif-content-center mt-5 mb-10'>
                  <button
                    disabled={
                      incomeData?.itemDescriptions?.length > 0 &&
                      incomeData?.itemDescriptions?.some((itm: any) => {
                        const tempElement = document.createElement('div')
                        tempElement.innerHTML = itm?.html
                        return (
                          !tempElement.textContent?.trim() ||
                          !tempElement.innerText?.trim() ||
                          !itm?.title?.trim()
                        )
                      })
                    }
                    type='button'
                    className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 mx-auto pe-5 mb-5'
                    onClick={() => {
                      handleAddNewSections()
                    }}
                  >
                    <img src={addWhite} height={18} width={18} className='me-5' /> Add Section
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default GenerateInvoice

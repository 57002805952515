import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import ReactPhoneInput from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { ApiGet, ApiGetNoAuth, ApiPost, ApiUpload } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import AppVendormodal from './AppVendormodal'

let vendorListArrayData: any = []

const AddNewVendor = () => {

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<any>();
    const [addVendor, setAddVendor] = React.useState<any>('')
    const [country, setCountry] = React.useState([])
    const [city, setCity] = React.useState([])
    const [formData, setFormData] = useState<any>({})
    const [showAddVendorModal, setShowAppVendorModal] = React.useState<boolean>(false)
    const [vendorsList, setVendorsList] = React.useState('Vendor List')
    const [uploadedFile, setUploadedFile] = React.useState<any>("")
    const [countryName, setCountryName] = React.useState<any>("")
    const [vendorList, setVendorList] = React.useState<any>()
    const [approvalStatus, setApprovalStatus] = React.useState<any>()
    const [vendorListArray, setVendrorListArray] = React.useState<any>()

    const navigate = useNavigate()

    const getCountry = async () => {
        await ApiGetNoAuth('country')
            .then((res) => {
                setCountry(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    React.useEffect(() => {
        getCountry()
    }, [])


    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setAddVendor({ ...addVendor, [name]: value })
    }

    const handleCountryChnage = async (e: any) => {
        const { name, value } = e.target
        const data = value.split('/')
        setCountryName(data[1]);
        await ApiGet(`country/cities?countryId=${data[0]}`)
            .then((res) => {
                setCity(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }

    const handleNumber = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]

        setAddVendor({
            ...addVendor,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: parseInt(PhoneNumber),
        })
    }
    const onSubmit = async () => {
        if(addVendor?.phoneNumber.length<9){
            return ErrorToast("Phone number  digit  must be greater than 9 characters")
        }
        const body = {
            companyName: addVendor?.companyName,
            tradeLicenseNo: addVendor?.tradeLicenseNo,
            TRN: addVendor?.trn,
            ratting: 0,
            country: countryName,
            city: addVendor?.city,
            approvalStatus: 1,
            firstName: addVendor?.firstname,
            lastName: addVendor?.lastName,
            email: addVendor?.contractEmail,
            phoneNumber: addVendor?.phoneNumber + '',
            countryCode: addVendor?.countryCode,
            vendorListIds: vendorListArray,
            vendorType: 0,
            comments: addVendor?.comment,
            saveStatus: approvalStatus,
            image: uploadedFile

        }
        await ApiPost(`corporate/vendor`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate('/vendor-management/management-vendors')
                vendorListArrayData = []
            })
            .catch((err) => ErrorToast(err.message))

    }
    const SaveVendorsData = async () => {
        const body = {
            companyName: addVendor?.companyName ? addVendor?.companyName : '',
            tradeLicenseNo: addVendor?.tradeLicenseNo ? addVendor?.tradeLicenseNo : '',
            TRN: addVendor?.trn ? addVendor?.trn : '',
            ratting: 0,
            country: countryName ? countryName : '',
            city: addVendor?.city ? addVendor?.city : '',
            approvalStatus: 1,
            firstName: addVendor?.firstname ? addVendor?.firstnam : '',
            lastName: addVendor?.lastName ? addVendor?.lastName : '',
            email: addVendor?.contractEmail ? addVendor?.contractEmail : '',
            phoneNumber: addVendor?.phoneNumber ? addVendor?.phoneNumber : '',
            countryCode: addVendor?.countryCode ? addVendor?.countryCode : null,
            vendorListIds: vendorListArray ? vendorListArray : [],
            vendorType: 0,
            comments: addVendor?.comment ? addVendor?.comment : '',
            saveStatus: 0,
            image: uploadedFile ? uploadedFile : ''

        }
        await ApiPost(`corporate/vendor`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate('/vendor-management/management-vendors')
                vendorListArrayData = []
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getvendorList = () => {
        ApiGet('corporate/vendor_list')
            .then((res) => {
                setVendorList(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getvendorList()
    }, [])

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div id='' className='app-container container-xxl pt-0 mt-0'>
                        <div className='d-flex align-items-baseline flex-wrap mr-5'>
                            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                                <li className='breadcrumb-item '>
                                    <a
                                        className='text-muted px-2 cursor-pointer'
                                        onClick={() => navigate('/vendor-management/management-vendors')}
                                    >
                                        Vendor
                                    </a>
                                </li>
                                <li className='breadcrumb-item active'>
                                    <a className='px-2'>Add New Vendor</a>
                                </li>
                            </ul>
                        </div>
                        <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                            <div className='d-flex align-items-center'>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <button
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        type='submit'
                                        onClick={() => setApprovalStatus(1)}>
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='mb-2 px-5 d-flex w-auto'>
                                    <button
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        type='button'
                                        onClick={() => SaveVendorsData()}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                        onClick={() => navigate(`/vendor-management/management-vendors`)}
                                    >
                                        cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-flush'>
                        <>

                            <Row className='py-2'>
                                <Col lg={6}>
                                    <span className='mx-10 mt-5'><b>Vendor Type: Service Provider</b> </span>
                                    <h3 className='mx-10 mb-10 mt-10'>Company Detail </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Company Name
                                            </h6>
                                            <input
                                                type='text'
                                                {...register("companyName", { required: true })}
                                                className='form-control form-control-solid'
                                                name='companyName'
                                                onChange={handleChnage}

                                            />
                                        </div>
                                        {errors.companyName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Trade License No.
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("tradeLicenseNo", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={handleChnage}
                                                name='tradeLicenseNo'
                                            // onChange={uploadFilelHandler}
                                            />

                                            <button
                                                className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-300px'>
                                                Upload Trade License
                                                <input
                                                    type='file'
                                                    // {...register("contract", { required: true })}
                                                    className='form-control form-control-solid position-absolute '
                                                    placeholder=''
                                                    name='contract'
                                                    onChange={uploadFilelHandler}
                                                    // onChange={uploadFilelHandler}
                                                    style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                />
                                            </button>
                                        </div>
                                        {errors.contract && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                TRN
                                            </h6>
                                            <input
                                                type='text'
                                                {...register("trn", { required: true })}
                                                className='form-control form-control-solid'
                                                name='trn'
                                                onChange={handleChnage}
                                            />
                                        </div>
                                        {errors.trn && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Country
                                                    </h6>
                                                </div>
                                                <div>
                                                    <select
                                                        {...register("country", { required: true })}
                                                        name='country'
                                                        className='form-select form-select-solid'
                                                        onChange={handleCountryChnage}
                                                    >
                                                        <option value='' disabled selected>
                                                            Select country
                                                        </option>
                                                        {country?.map((v: any) => (
                                                            <option value={`${v._id}/${v.country}`}>{v.country}</option>
                                                        ))}
                                                    </select>
                                                    {errors.country && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    City
                                                </h6>
                                                <div>
                                                    <select
                                                        {...register("city", { required: true })}
                                                        name='city'
                                                        className='form-select form-select-solid'
                                                        value={addVendor?.city}
                                                        onChange={handleChnage}
                                                    >
                                                        <option value='' disabled selected>
                                                            Select city
                                                        </option>
                                                        {city?.map((v: any) => (
                                                            <option value={v.name}>{v.city}</option>
                                                        ))}
                                                    </select>
                                                    {errors.city && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2'>
                                                Vendor List
                                            </h6>
                                            <a
                                                className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none'
                                                onClick={() => setShowAppVendorModal(true)}>
                                                Add To List
                                            </a>
                                        </div>


                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <h3 className='mx-10 mb-10 mt-10'>Main Contact Detail </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <h6 className='text-muted m-0 mr-2'>
                                                Contact Name
                                            </h6>
                                            <div className='d-flex'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        First Name
                                                    </h6>
                                                    <div>
                                                        <input
                                                            type='text'
                                                            {...register("firstname", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='firstname'
                                                            onChange={handleChnage}

                                                        />
                                                        {errors.firstname && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        Last Name
                                                    </h6>
                                                    <div>
                                                        <input
                                                            type='text'
                                                            {...register("lastName", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='lastName'
                                                            onChange={handleChnage}

                                                        />
                                                        {errors.lastName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                Contact Email
                                            </h6>
                                            <input
                                                type='text'
                                                {...register("contractEmail", {
                                                    required: true, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "invalid email address"
                                                    }
                                                })}
                                                className='form-control form-control-solid'
                                                name='contractEmail'
                                                onChange={handleChnage}
                                            />
                                        </div>
                                        {errors.contractEmail && <p style={{ color: 'var(--kt-danger)' }}>invalid email address</p>}
                                        <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2 min-w-100px'>
                                                Contact (Mob No)
                                            </h6>
                                            {/* <Controller
                                                control={control}
                                                name="phone"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, onBlur, value, ref}}) => (
                                                    <ReactPhoneInput
                                                    
                                                        inputProps={{
                                                            ref,
                                                            required: true,
                                                        }}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber(phone, e)}
                                                    />
                                                )}
                                            /> */}
                                            <ReactPhoneInput
                                                placeholder='Enter phone number'
                                                onChange={(phone, e) => handleNumber(phone, e)}
                                            />
                                        </div>
                                        {/* {errors.phone && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>} */}

                                        <div>
                                            <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                                User Comments (For Internal Reference)
                                            </h6>
                                            <textarea
                                                className='form-control form-control-solid mb-8'
                                                rows={3}
                                                {...register("comment", { required: true })}
                                                name='comment'
                                                onChange={handleChnage}
                                                placeholder='Type or paste emails here'
                                            ></textarea>
                                            {errors.contractMobile && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                        </>
                    </div>
                </form>
            </div >

            <AppVendormodal
                show={showAddVendorModal}
                handleClose={() => {
                    setShowAppVendorModal(false)
                }}
                setFormData={setFormData}
                formData={formData}
                vendorsList={vendorsList}
                vendorList={vendorList}
                vendorListArray={vendorListArrayData}
                setVendrorListArray={setVendrorListArray}
                type={'newVendor'}
                getVendorDataById={''}
            />
        </>
    )
}

export default AddNewVendor

import React, {useEffect} from 'react'
import {useState, useRef} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import './style.scss'
import {Tabs, Tab, Paper} from '@mui/material'
import LeasingCommercial from './LeasingCommercial'
import LeasingResidential from './LeasingResedential'
import Tenants from './TenantsTab'
import PropertyPortfolio from './PropertyPortfolio'
import Maintenance from './Maintenance'
import Communications from './Communications'
import Financials from './Financials'
import whiteTick from '../../../img/tick-white.png'
import HelpAndSupport from './HelpAndSupport'
import AccountManagement from './AccountManagement'
import UserGuide from './UserGuide'
import { ContactsFilled } from '@ant-design/icons'

interface ButtonProps {
  show: any
  handleClose: any
  role: any
  from: any
  updateTabData: any
}

const AddFeature = ({show, handleClose, role, from, updateTabData}: ButtonProps) => {
  const [roleData, setRoleData] = useState<any>(role)
  const [tabValue, setTabValue] = useState('Account Management')

  const contentRef = useRef<HTMLDivElement | null>(null)

  const handleChange = (event: any, newValue: any) => {
    console.log(newValue)
    setTabValue(newValue)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const renderTabLabel = (index: any, label: any) => {
    if (label === 'Leasing (Residential)')
      return (
        <div className={`d-flex align-items-center ${isResSelected ? 'selected-tab' : ''}`}>
          {isResSelected && <img src={whiteTick} height={15} width={15} className='me-3' />}
          {label}
        </div>
      )
    else if (label === 'User Guide' || label === 'Help & Support' || label === 'Account Management')
      return (
        <div className={`d-flex align-items-center '}`}>
          <img src={whiteTick} height={15} width={15} className='me-3' />
          {label}
        </div>
      )
    // else
    // return (
    //   <div className={`d-flex align-items-center ${isResSelected ? 'selected-tab' : ''}`}>
    //     {isResSelected && <img src={whiteTick} height={15} width={15} className='me-3' />}
    //     {label}
    //   </div>
    // )
  }

  const isResSelected =
    roleData?.featureList?.leasing_residential?.lease_agreements?.view_list ||
    roleData?.featureList?.leasing_residential?.lease_agreements?.view_details ||
    roleData?.featureList?.leasing_residential?.lease_agreements?.create ||
    roleData?.featureList?.leasing_residential?.lease_agreements?.update ||
    roleData?.featureList?.leasing_residential?.lease_agreements?.delete ||
    roleData?.featureList?.leasing_residential?.lease_agreements?.approve ||
    roleData?.featureList?.leasing_residential?.renewals?.view_list ||
    roleData?.featureList?.leasing_residential?.renewals?.view_details ||
    roleData?.featureList?.leasing_residential?.renewals?.create ||
    roleData?.featureList?.leasing_residential?.renewals?.update ||
    roleData?.featureList?.leasing_residential?.renewals?.delete ||
    roleData?.featureList?.leasing_residential?.terminations?.view_list ||
    roleData?.featureList?.leasing_residential?.terminations?.view_details ||
    roleData?.featureList?.leasing_residential?.terminations?.create ||
    roleData?.featureList?.leasing_residential?.terminations?.update ||
    roleData?.featureList?.leasing_residential?.terminations?.delete ||
    roleData?.featureList?.leasing_residential?.terminations?.approve ||
    roleData?.featureList?.leasing_residential?.lease_financial?.view_list ||
    roleData?.featureList?.leasing_residential?.lease_financial?.view_details ||
    roleData?.featureList?.leasing_residential?.lease_financial?.create ||
    roleData?.featureList?.leasing_residential?.lease_financial?.update ||
    roleData?.featureList?.leasing_residential?.lease_financial?.delete ||
    roleData?.featureList?.leasing_residential?.lease_financial?.approve ||
    roleData?.featureList?.leasing_residential?.lease_deletion?.allow ||
    roleData?.featureList?.leasing_residential?.cancellation?.allow

    useEffect(()=>{
      let temp: any = localStorage?.getItem('actveTab')
      if(temp != null)
      setTabValue(temp)
    },[])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1200px mx-auto'
        show={show}
        onHide={() => {
          handleClose(isResSelected)
        }}
        backdrop={true}
        size='sm'
        // style={{minWidth: '1150px'}}
      >
        <div
          className='modal-body py-lg-0 px-lg-0 '
          style={{
            transform: 'translate(0px, -30px)',
            top: '30px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '570px',
            maxHeight: '570px',
            overflow: 'hidden',
          }}
        >
          <div id='' className='pt-0 mt-0 px-2 ps-1'>
            <Row>
              <Col md={2} className='add-feature ps-0'>
                <Paper style={{backgroundColor: '#146c6a', height: '670px'}} className='pe-2'>
                  <h2 className='text-white ps-5 ms-3 pt-5 mb-0'>
                    <b>Add Features</b>
                  </h2>
                  <Tabs
                    orientation='vertical'
                    // variant='scrollable'
                    value={tabValue}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        display: 'none', // This will hide the indicator line
                      },
                    }}
                    sx={{
                      borderRight: `1px solid divider`,
                      paddingTop: '15px',
                      paddingBottom: '20px',
                      color: '#fff',

                      '& .Mui-selected': {
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        border: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Tab
                      label={renderTabLabel(0, 'Account Management')}
                      value='Account Management'
                      sx={{
                        fontWeight: tabValue === 'Account Management' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Account Management' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Communications'
                      value='Communications'
                      sx={{
                        fontWeight: tabValue === 'Communications' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Communications' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Data Export'
                      value='Data Export'
                      sx={{
                        fontWeight: tabValue === 'Data Export' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Data Export' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Financials'
                      value='Financials'
                      sx={{
                        fontWeight: tabValue === 'Financials' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Financials' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label={renderTabLabel(4, 'Help & Support')}
                      value='Help & Support'
                      sx={{
                        fontWeight: tabValue === 'Help & Support' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Help & Support' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                    <Tab
                      label='Leasing (Commercial)'
                      value='Leasing (Commercial)'
                      sx={{
                        fontWeight: tabValue === 'Leasing (Commercial)' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Leasing (Commercial)' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label={renderTabLabel(6, 'Leasing (Residential)')}
                      value='Leasing (Residential)'
                      sx={{
                        fontWeight: tabValue === 'Leasing (Residential)' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Leasing (Residential)' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Maintenance'
                      value='Maintenance'
                      sx={{
                        fontWeight: tabValue === 'Maintenance' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Maintenance' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Property Portfolio'
                      value='Property Portfolio'
                      sx={{
                        fontWeight: tabValue === 'Property Portfolio' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Property Portfolio' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label='Tenants'
                      value='Tenants'
                      sx={{
                        fontWeight: tabValue === 'Tenants' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'Tenants' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />

                    <Tab
                      label={renderTabLabel(9, 'User Guide')}
                      value='User Guide'
                      sx={{
                        fontWeight: tabValue === 'User Guide' ? 'bold' : 'normal',
                        textDecoration: tabValue === 'User Guide' ? 'underline' : 'none',
                        border: 'none',
                        color: '#000',
                      }}
                    />
                  </Tabs>

                  <button
                    type='button'
                    className='btn btn-sm fw-bold  ms-7 me-0 bg-white'
                    onClick={() => {
                      localStorage.setItem('actveTab', tabValue)
                      if (isResSelected) updateTabData('residential', roleData)
                      else updateTabData('', roleData)
                    }}
                    style={{width: '140px', color: '#146c6a'}}
                  >
                    Update
                  </button>
                  {/* Content for each tab */}
                </Paper>
              </Col>
              <Col md={10} className='px-0'>
                <div
                  style={{flex: 1, padding: '10px 0px', width: '100%'}}
                  className='add-feature-tab-details'
                  ref={contentRef}
                >
                  {tabValue === 'Account Management' && (
                    <AccountManagement
                      roleData={roleData}
                      from={from}
                      updateRoleDate={(val: any) => setRoleData(val)}
                    />
                  )}
                  {tabValue === 'Communications' && <Communications />}
                  {tabValue === 'Data Export' && <div>{'Data Export'}</div>}
                  {tabValue == 'Financials' && <Financials />}
                  {tabValue ===  'Help & Support' && (
                    <HelpAndSupport
                      roleData={roleData}
                      from={from}
                      updateRoleDate={(val: any) => setRoleData(val)}
                    />
                  )}
                  {tabValue === 'Leasing (Commercial)' && <LeasingCommercial />}
                  {tabValue === 'Leasing (Residential)' && (
                    <LeasingResidential
                      roleData={roleData}
                      from={from}
                      updateRoleDate={(val: any) => setRoleData(val)}
                    />
                  )}
                  {tabValue === 'Maintenance' && <Maintenance />}
                  {tabValue === 'Property Portfolio' && <PropertyPortfolio />}
                  {tabValue === 'Tenants' && <Tenants />}
                  {tabValue === 'User Guide' && (
                    <UserGuide
                      roleData={roleData}
                      from={from}
                      updateRoleDate={(val: any) => setRoleData(val)}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddFeature

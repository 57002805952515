import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import {
  ApiPost,
  Bucket,
  ApiGet,
  ApiPut,
  ApiUpload,
  ApiDelete,
} from '../../../../apiCommon/helpers/API/ApiData'
import {DatePicker, Space} from 'antd'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import {AnyIfEmpty} from 'react-redux'
import file from '../../../../img/file.svg'
import {SuccessToast} from '../../../../apiCommon/helpers/Toast'
import info from '../../../../img/info.svg'
import pen from '../../../../img/pen.png'
import upload from '../../../../img/upload-green.png'
import closered from '../../../../img/closered.svg'
import pencil from '../../../../img/pencil.svg'
import {Col, Row, Tabs, Tab, Modal, Button} from 'react-bootstrap'
import '../style.scss'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import TerminateTenancy from './TerminateTenancyModal'
import {AiFillDelete} from 'react-icons/ai'
import Swal from 'sweetalert2'
import {useDispatch, useSelector} from 'react-redux'
import allTen from '../../../../img/all-tenc.png'

const Tenancy = () => {
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const navigate = useNavigate()
  const {state} = useLocation()

  const {unitId} = useParams()

  const [page, setPage] = useState<any>(1)
  const [page1, setPage1] = useState<any>(1)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [pageLimit1, setPageLimit1] = useState<any>(1)

  const [tenancy, setTenancy] = useState<any>()
  const [previousTenancy, setPreviousTenancy] = useState<any>()
  const [contractDetails, setContractDetails] = useState<any>()
  const [isEditContract, setIsEditContract] = useState<any>(false)
  const [showOther, setShowOther] = useState<any>(false)
  const [isEditDocument, setIsEditDocument] = useState<any>(false)
  const [documents, setDocuments] = useState<any>()

  const [isPrev, setIsPrev] = useState<any>(false)
  const [preTenId, setPreTenId] = useState<any>(null)

  const [showTerm, setShowTerm] = useState<any>(false)

  const {RangePicker} = DatePicker
  const dateFormat = 'YYYY-MM-DD'

  const [rentFin, setRentFin] = useState<any>({
    name: 'Rent',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [securityFin, setSecurityFin] = useState<any>({
    name: 'Security Deposit',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [otherFinancials, setOtherFinancials] = useState<any>([
    // {
    //   name: '',
    //   amount: 0,
    //   noOfPayments: 0
    // }
  ])

  //
  const getTenancy = () => {
    ApiGet(`corporate/tenancy/property_profile?unitId=${unitId}`)
      .then((res) => {
        setTenancy(res?.data?.data)

        const values: any = {
          contractNo: res?.data?.data?.contractNo,
          tenancyStatus: res?.data?.data?.tenancyStatus,
          start_date: res?.data?.data?.duration?.start_date,
          end_date: res?.data?.data?.duration?.end_date,
          days: res?.data?.data?.duration?.days,
          benefits: res?.data?.data?.benefits,
        }
        setContractDetails(values)
        if (res?.data?.data?.benefits?.other?.length > 0) setShowOther(true)
        else setShowOther(false)

        const values2: any = {
          contract: res?.data?.data?.document?.contract,
          other: res?.data?.data?.document?.other,
        }

        setDocuments(values2)

        const temp = [...uploadOtherImgTenancy]
        for (let i = 0; i < res?.data?.data?.document?.other?.length; i++) {
          temp[i] = res?.data?.data?.document?.other[i]
        }

        setuploadOtherImgTenancy(temp)

        let finance: any = res?.data?.data?.financialSummery
        let otherInd: any = 0
        let otherValues: any = []
        for (let i = 0; i < finance?.length; i++) {
          if (finance[i]?.name == 'Rent') setRentFin(finance[i])
          else if (finance[i]?.name == 'Security Deposit') setSecurityFin(finance[i])
          else {
            otherValues[otherInd] = finance[i]
            otherInd++
          }
        }
        setOtherFinancials(otherValues)
      })
      .catch((err) => console.log('err', err))
  }

  const [idToPass, setIdToPass] = useState(null)

  //
  const getTenancyByID = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}`)
      .then((res) => {
        setTenancy(res?.data?.data[0])
        setIsPrev(true)
        setPreTenId(id)
        setIdToPass(id)
      })
      .catch((err) => console.log('err', err))
  }

  // for upcoming tenancies
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  // for previous tenancies
  const handlePageClick1 = (event: any) => {
    setPage1(event.selected + 1)
  }

  const [upcomingTenancy, setUpcomingTenancy] = useState<any>([])

  //
  const getUpcomingTenancies = () => {
    const body = {
      page: page,
      limit: 10,
      unitId: unitId,
    }
    ApiPost(`corporate/tenancy/upcoming/get`, body)
      .then((res) => {
        setUpcomingTenancy(res?.data?.data?.tenancy_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getPreviousTenancies = () => {
    const body = {
      page: page,
      limit: 10,
      unitId: unitId,
    }
    ApiPost(`corporate/tenancy/previous/get`, body)
      .then((res) => {
        setPreviousTenancy(res?.data?.data?.tenancy_data)
        setPageLimit1(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  const tableRef = useRef<HTMLTableElement | null>(null)

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  //
  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = () => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  useEffect(() => {
    enableScrolling()
  }, [])

  useEffect(() => {
    if (state?.tenancyId != undefined) {
      setIdToPass(state?.tenancyId)
      getTenancyByID(state?.tenancyId)
    } else {
      setTimeout(() => {
        getTenancy()
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (state?.tenancyId == undefined) getUpcomingTenancies()
  }, [page])

  useEffect(() => {
    if (state?.tenancyId == undefined) getPreviousTenancies()
  }, [page1])

  //
  const createTenant = (val: any) => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID', res?.data?.data?._id)
        navigate('/create-tenancy/1', {
          state: {tenancyData: val, tenantData: res?.data?.data},
        })
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const createTenacy = () => {
    ApiPost('corporate/tenancy')
      .then((res) => {
        localStorage.setItem('newTenancyID', res?.data?.data?._id)
        createTenant(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const updateContractDetails = () => {
    const body = {
      id: tenancy?._id,
      contractNo: contractDetails?.contractNo,
      duration: {
        start_date: contractDetails?.start_date,
        end_date: contractDetails?.end_date,
        days: parseInt(contractDetails?.days),
        _id: tenancy?.duration?._id,
      },
      tenancyStatus: contractDetails?.tenancyStatus,
      benefits: contractDetails?.benefits,
    }

    ApiPut('corporate/tenancy', body)
      .then((res) => {
        setIsEditContract(false)
        SuccessToast('Tenancy Contract Details has been updated successfully..')
        getTenancy()
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const updateDocuments = () => {
    const body = {
      id: tenancy?._id,
      document: {
        contract: documents?.contract,
        other: uploadOtherImgTenancy,
        _id: tenancy?.ducument?._id,
      },
    }

    ApiPut('corporate/tenancy', body)
      .then((res) => {
        setIsEditDocument(false)
        SuccessToast('Tenancy Documents have been updated successfully..')
        getTenancy()
      })
      .catch((err) => ErrorToast(err.message))
  }

  let fileLists_single: any = []

  const onFilesAddedSingle = async (event: any, type: any, isTenancy: any) => {
    const files = event.target.files

    fileLists_single = Array.from(files)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      data.fileURL = fileURL
      formData.append('image', data)
      // console.log('\ntype', filetypes)

      let url = ''
      if (type == 'tenant') {
        url = 'upload/image/tenant_other'
      } else if (type == 'tenancy') {
        url = 'upload/image/tenancy_other'
      } else if (type == 'uploadId') {
        url = 'upload/image/tenant_id'
      } else if (type == 'uploadResidency') {
        url = 'upload/image/tenant_residency'
      } else if (type == 'uploadPassport') {
        url = 'upload/image/tenant_passport'
      } else if (type == 'uploadContract') {
        url = 'upload/image/tenancy_contract'
      }

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          if (type == 'uploadId') {
            // setuploadIdTenant([res?.data?.data?.image])
          } else if (type == 'uploadResidency') {
            // setuploadresidencyTenant([res?.data?.data?.image])
          } else if (type == 'uploadPassport') {
            // setuploadPassportTenant([res?.data?.data?.image])
          } else if (type == 'uploadContract') {
            const values = {...documents}
            values.contract = res?.data?.data?.image
            setDocuments(values)
            // setuploadContractTenancy([res?.data?.data?.image])
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  let fileLists: any = []
  const [fileFound, setFileFound] = useState<any>(false)
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()
  const [uploadOtherImgTenancy, setuploadOtherImgTenancy] = useState<any>([])
  const [newName, setnewName] = useState<any>()
  const [isEditImgName, setIsEditImgName] = useState<any>(false)
  const [editName, setEditName] = useState(false)
  const [modalBtnIsLoading, setModalBtnIsLoading] = useState<any>(false)

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl?.includes('blob')) {
      return true
    } else if (fileUrl?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const getFilename = (file: any) => {
    return file.split('/').pop()
  }

  const handleShow = () => setShowImageName(true)

  const onFilesAdded = async (event: any, type: any) => {
    setFileFound(false)
    const files = event.target.files
    fileLists = Array.from(files)
    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = type == 'tenancy' || type == 'tenant' ? '' : element.name
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    if (type == 'tenancy' || type == 'tenant') {
      handleShow()
    } else {
      saveImageFileName()
    }
  }

  const handleClose = () => {
    setModalBtnIsLoading(false)
    setShowImageName(false)
  }

  const saveImageFileName = async () => {
    // setModalBtnIsLoading(true)
    setFileFound(false)
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i]
      let fileURL: any = URL.createObjectURL(data.selctedfile)
      let extensionType = data?.selctedfile?.name?.split('.')[1]
      let type: any = finalFileUpload[i].type

      data.fileURL = fileURL
      formData.append('image', data?.selctedfile)
      let fileType: any = ''

      switch (type) {
        case 'tenancy':
          fileType = 'tenancy_other'
          break
        case 'tenant':
          fileType = 'tenant_other'
          break
        default:
          break
      }

      // if (type == 'tenancy' || type == 'tenant') {
      await ApiPost(
        `upload/file_checker?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then(async (res) => {
          if (res?.data?.data?.isFileExist) {
            setFileFound(true)
          } else {
            await ApiUpload(
              `upload/image/params?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
              formData
            )
              .then((res) => {
                if (type == 'tenant') {
                  // setuploadOtherImgTenant([...uploadOtherImgTenant, res?.data?.data?.image])
                } else if (type == 'tenancy') {
                  setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
                }
                handleClose()
              })
              .catch((err) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
              })
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
          setModalBtnIsLoading(false)
        })
    }
  }

  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }

  const changeFileNameOther = async (type: any, index: any) => {
    let body: any
    if (isEditImgName) {
      if (type == 'tenancy') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenancy[index].split('.').pop(),
          image: uploadOtherImgTenancy[index],
        }
      } else if (type == 'tenant') {
      }
      console.log('\nbody', body)

      await ApiPost('upload/rename_file', body)
        .then((res) => {
          if (type == 'tenancy') {
            setEditName(false)
            // setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
            const remove_index = uploadOtherImgTenancy.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenancy([...remove_index, res?.data?.data?.image])
          } else if (type == 'tenant') {
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    } else {
      setEditName(false)
    }
  }

  const deleteUploadImg = async (e: any, type: any, ele: any) => {
    const url = ele.replace(`${Bucket}`, '')
    await ApiPost('upload/delete_file', {url})
      .then(() => {
        if (type == 'tenant') {
        } else if (type == 'tenancy') {
          setuploadOtherImgTenancy(uploadOtherImgTenancy.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadId') {
        } else if (type == 'uploadResidency') {
        } else if (type == 'uploadPassport') {
        } else if (type == 'uploadContract') {
        }
      })

      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  const cancelTenancy = () => {
    ApiGet(`corporate/tenancy/cancel/${tenancy?._id}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        if (state?.tenancyId != undefined) {
          setIdToPass(state?.tenancyId)
          getTenancyByID(state?.tenancyId)
        } else {
          setTimeout(() => {
            getTenancy()
          }, 1000)
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const deleteTenancy = (id: any) => {
    ApiDelete(`corporate/tenancy/${id}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        getUpcomingTenancies()
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2' style={{padding: '0px 0px 0px 14px'}}>
        {' '}
        {/* container-xxl */}
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (state?.tenancyId != undefined) navigate('/tenancies')
                else {
                  if (isPrev) {
                    setIsPrev(false)
                    getTenancy()
                    getPreviousTenancies()
                  } else {
                    if (comefrom == 'properties') navigate('/dashboard')
                    else if (comefrom == 'build') navigate('/building-dashboard')
                    else navigate('/community-dashboard')
                  }
                }
              }}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            {isPrev ? (
              <h1 className='page-heading m-0 head-text'>
                Property Profile - {tenancy?.contractNo}
              </h1>
            ) : (
              <h1 className='page-heading m-0 head-text'>Property Profile</h1>
            )}
          </div>

          {/* Propert Profile Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center  mx-5 ps-5'
            style={{marginBottom: '28px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 me-5 px-5 e'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Overview
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 active'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenancy`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Tenancy
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenant`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Tenants
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/financials/income`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Financials
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/annoucements`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Annoucements
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: idToPass,
                      },
                    })
                  }
                >
                  Requests
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          {/* <OverlayTrigger
            overlay={
              <Tooltip>
                {tenancy?.tenancyStatus === 1 ? (
                  <span>
                    Unit is currently Booked. To create a new tenancy, this tenancy must be
                    completed or terminated
                  </span>
                ) : (
                  <span>
                    Unit is currently Booked. To create a new tenancy, this tenancy must be
                    completed or terminated
                  </span>
                )}
              </Tooltip>
            }
            placement='top'
          >
            <button
              onClick={() => {
                localStorage.setItem('BuildingId', state?.details?.buildingId)
                localStorage.setItem('CommunityId', state?.details?.communityId)
                localStorage.setItem('ClusterId', state?.details?.clusterId)
                localStorage.setItem('UnitId', state?.details?.unitId)
                createTenacy()
                // dispatch(setUnitIDfromProperties(false))
              }}
              className='btn btn-sm fw-bold btn-primary btn-green me-3'
              disabled={
                tenancy?.tenancyStatus === 0 ||
                tenancy?.tenancyStatus === 1 ||
                tenancy?.tenancyStatus === 2
                  ? true
                  : false
              }
            >
              Create Tenancy
            </button>
          </OverlayTrigger> */}

          <div className='ms-auto'>
            {/* Tenancy Status 0 = Booked */}
            {tenancy?.tenancyStatus == 0 && tenancy?.isCancel == false && (
              <button
                className='btn btn-sm fw-bold terminate-btn mx-3 my-4'
                onClick={() => cancelTenancy()}
              >
                Cancel Tenancy
              </button>
            )}

            {/* {tenancy?.renewal_request != null ? (
              <>
                <a
                  className='btn btn-sm fw-bold renewed-btn'
                  onClick={() => {
                    navigate(
                      `/overViewData/${unitId}/view/${tenancy?.renewal_request?._id}/${tenancy?.renewal_request?.type}`,
                      {
                        state: {
                          details: state.details,
                          id: unitId,
                        },
                      }
                    )
                  }}
                >
                  View Renewal Request
                </a>
              </>
            ) : (
              <>
                {tenancy?.draft_renewal_tenancy?.isDraft == true ? (
                  <>
                    <button
                      className='btn btn-sm fw-bold renewed-btn mx-3'
                      onClick={() => {
                        navigate(
                          `/create-tenant/2/${tenancy?.draft_renewal_tenancy?.unitId}/${tenancy?.draft_renewal_tenancy?._id}`
                        )
                      }}
                      disabled={tenancy?.terminate_request != null ? true : false}
                    >
                      Continue Renewal
                    </button>
                  </>
                ) : (
                  <>
                    {(tenancy?.tenancyStatus === 1 ||
                      tenancy?.tenancyStatus === 2 ||
                      tenancy?.tenancyStatus === 3) && (
                      <button
                        className='btn btn-sm fw-bold renewed-btn mx-3'
                        onClick={() => {
                          // navigate(`/create-tenant/2/${tenancy?._id}/${tenancy?._id}`)
                          localStorage.setItem('isDraft', '2')
                          localStorage.setItem('BuildingId', tenancy?.buildingId)
                          localStorage.setItem('CommunityId', tenancy?.communityId)
                          localStorage.setItem('ClusterId', tenancy?.clusterId)
                          localStorage.setItem('UnitId', tenancy?.unitId)
                          navigate(`/renew-tenancy/${unitId}/${tenancy?._id}`)
                        }}
                        disabled={tenancy?.terminate_request != null ? true : false}
                      >
                        Renew Tenancy
                      </button>
                    )}
                  </>
                )}
              </>
            )} */}

            {/* {tenancy?.terminate_request != null ? (
              <>
                <a
                  className='btn btn-sm fw-bold terminate-btn mx-3'
                  onClick={() => {
                    navigate(
                      `/overViewData/${unitId}/view/${tenancy?.terminate_request?._id}/${tenancy?.terminate_request?.type}`,
                      {
                        state: {
                          details: state.details,
                          id: unitId,
                        },
                      }
                    )
                  }}
                >
                  View Termination Request
                </a>
              </>
            ) : (
              <>
                {(tenancy?.tenancyStatus === 1 || tenancy?.tenancyStatus === 2) && (
                  <button
                    className='btn btn-sm fw-bold terminate-btn ms-3'
                    onClick={() => setShowTerm(true)}
                    // onClick={() => handleSubmit('terminate_tenancy')}
                    disabled={tenancy?.renewal_request != null ? true : false}
                  >
                    Terminate Tenancy
                  </button>
                )}
              </>
            )} */}

            {(tenancy?.tenancyStatus == 3 &&
              (tenancy?.renewalStatus == 0 ||
                tenancy?.renewalStatus == 2 ||
                tenancy?.renewalStatus == 3 ||
                tenancy?.renewalStatus == 4 ||
                tenancy?.renewalStatus == 5 ||
                tenancy?.renewalStatus == 7 ||
                tenancy?.renewalStatus == 8 ||
                tenancy?.renewalStatus == 9 ||
                tenancy?.renewalStatus == 10 ||
                tenancy?.renewalStatus == null)) ||
              (tenancy?.tenancyStatus == 0 &&
                tenancy?.isCancel == true &&
                tenancy?.renewalStatus == null && (
                  <>
                    <AiFillDelete
                      color='#D72852'
                      fontSize={20}
                      // onClick={() => deleteRow(i)}
                      onClick={() => {
                        Swal.fire({
                          text: 'Are you sure you want to permanently delete this Tenancy?',
                          icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#D72852',
                          confirmButtonText: 'Confirm',
                          showCancelButton: true,
                          // cancelButtonColor: "transparent",
                          cancelButtonText: 'Cancel',
                        }).then((res: any) => {
                          if (res.isConfirmed) {
                            ApiDelete(`corporate/tenancy/${tenancy?._id}`)
                              .then((response: any) => {
                                SuccessToast(response?.data?.message)
                                navigate(-1)
                              })
                              .catch((error: any) => {
                                console.log(error)
                              })
                          }
                        })
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </>
                ))}
          </div>
        </div>
        <div className='my-5' style={{backgroundColor: 'none'}}>
          <div className='card card-flush px-5 py-2 h-100'>
            <div className='d-flex  mt-2 mb-5 align-items-center'>
              <h2 className='mx-0 green_color mt-2  '>Current Tenancy</h2>

              <button
                type='button'
                className='btn btn-sm fw-bold  me-1  select-btn ms-auto px-5'
                onClick={() => {
                  localStorage.setItem('tenancyFlow', 'propertyProfile')
                  navigate(`/tenancy-view/2/${tenancy?.unitId}/${tenancy?._id}`)
                }}
                style={{width: 'fit-content'}}
              >
                <img src={allTen} height={20} width={20} style={{marginRight: '10px'}} /> View
                Tenancy
              </button>
            </div>
            {/* <div
                className='table-responsive mb-5'
                // onWheel={handleMouseWheel}
                // ref={tableRef}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
            <table
              className='table align-middle table-row-dashed fs-5 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <tbody className='fw-semibold text-gray-600'>
                <tr>
                  <td className='text-start min-w-100px'>Contract No.</td>
                  <td className='text-start min-w-200px'>
                    <span className='ms-4 head-text'>
                      <b> {tenancy?.contractNo}</b>
                    </span>
                  </td>
                  <td className='text-start min-w-100px'></td>
                  <td className='text-start min-w-100px'></td>
                  <td className='text-start min-w-100px'></td>
                  <td className='text-start min-w-100px'></td>
                  <td className='text-start min-w-75px'></td>
                  <td className='text-start min-w-75px'></td>
                  <td className='text-start min-w-150px'>
                    <div className='' style={{width: '143px'}}>
                      <div
                        className={
                          tenancy?.tenancyStatus == 0
                            ? 'status booked-bar '
                            : tenancy?.tenancyStatus == 1
                            ? 'status active-bar '
                            : tenancy?.tenancyStatus == 2
                            ? 'status expiring-bar '
                            : tenancy?.tenancyStatus == 3
                            ? 'status expired-bar '
                            : 'status  no-st-bar '
                        }
                      >
                        {tenancy?.tenancyStatus == 0
                          ? 'Booked'
                          : tenancy?.tenancyStatus == 1
                          ? 'Active'
                          : tenancy?.tenancyStatus == 2
                          ? 'Expiring'
                          : tenancy?.tenancyStatus == 3
                          ? 'Expired'
                          : '-'}
                      </div>
                      <i>
                        {' '}
                        {tenancy?.tenancyStatusDateTime
                          ? `${moment(tenancy?.tenancyStatusDateTime).format(
                              'DD.MM.YYYY - HH:mm A'
                            )} `
                          : ''}
                      </i>
                    </div>
                  </td>
                  <td className='text-start min-w-150px'>
                    <div className='' style={{width: '143px'}}>
                      <div
                        className={
                          tenancy?.renewalStatus == 0
                            ? 'status-view create-st'
                            : tenancy?.renewalStatus == 1
                            ? 'status-view create-st'
                            : tenancy?.renewalStatus == 2
                            ? 'status-view renewed-st'
                            : tenancy?.renewalStatus == 7
                            ? 'status-view cancelled-st '
                            : 'status-view not-saved '
                        }
                      >
                        {tenancy?.renewalStatus == 0
                          ? 'Not Renewed'
                          : tenancy?.renewalStatus == 1
                          ? 'Under Renewal'
                          : tenancy?.renewalStatus == 2
                          ? 'Renewed'
                          : tenancy?.renewalStatus == 7
                          ? 'Terminated'
                          : '-'}
                      </div>
                      <i>
                        {' '}
                        {tenancy?.renewalStatusDateTime
                          ? `${moment(tenancy?.renewalStatusDateTime).format(
                              'DD.MM.YYYY - HH:mm A'
                            )} `
                          : ''}
                      </i>
                    </div>
                  </td>
                  <td className='text-start min-w-150px'>
                    <div className='ms-auto' style={{width: '143px'}}>
                      <>
                        <div className={tenancy?.isDraft ? 'status draft-st ' : 'status create-st'}>
                          {tenancy?.isDraft ? 'Draft' : 'Created'}
                        </div>
                        <i>
                          {' '}
                          {tenancy?.draftStatusDateTime
                            ? `${moment(tenancy?.draftStatusDateTime).format(
                                'DD.MM.YYYY - HH:mm A'
                              )} `
                            : ''}
                        </i>
                      </>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='text-start min-w-100px'>Term</td>
                  <td className='text-start min-w-200px'>
                    <span className='ms-3 head-text'>
                      <b>
                        {' '}
                        {tenancy?.duration?.start_date
                          ? moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')
                          : '-'}
                        {' - '}
                        {tenancy?.duration?.end_date
                          ? moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')
                          : '-'}
                      </b>
                    </span>
                  </td>
                  <td className='text-start min-w-100px'>Duration</td>
                  <td className='text-start min-w-100px'>
                    {' '}
                    <span className='ms-5 ps-1 head-text'>
                      <b>
                        {' '}
                        {tenancy?.duration?.days === 1
                          ? tenancy?.duration?.days + ' Day'
                          : tenancy?.duration?.days >= 2
                          ? tenancy?.duration?.days + ' Days'
                          : tenancy?.duration?.days <= 0
                          ? 0 + ' Days'
                          : ''}
                      </b>
                    </span>
                  </td>
                  <td className='text-start min-w-100px'>Remaining </td>
                  <td className='text-start min-w-100px'>
                    <span className='ms-5 ps-1 head-text'>
                      <b>
                        {' '}
                        {tenancy?.remainingDays === 1
                          ? tenancy?.remainingDays + ' Day'
                          : tenancy?.remainingDays >= 2
                          ? tenancy?.remainingDays + ' Days'
                          : tenancy?.remainingDays <= 0
                          ? 0 + ' Days'
                          : ''}
                      </b>
                    </span>
                  </td>
                  <td className='text-start min-w-75px'></td>
                  <td className='text-start min-w-75px'></td>
                  <td className='text-start min-w-150px'></td>
                  <td className='text-start min-w-150px'></td>
                  <td className='text-start min-w-150px'></td>
                </tr>
              </tbody>
            </table>
            </div> */}
            <div className=' d-flex'>
              <div className='d-flex align-items-center'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label style={{minWidth: '95px'}} className='labl-gry'>
                    {' '}
                    Contract No.{' '}
                  </label>
                </h4>
                <span className='ms-5 ps-1 my-2'>
                  <b>{tenancy?.contractNo ? tenancy?.contractNo : '-'}</b>
                </span>
                {/* <h4 className='my-5 ' style={{color: '#a1a5b7'}}>
                  <label style={{width: '100px'}}> Contract No. </label>
                  <span className='ms-4 head-text'>
                    <b> {tenancy?.contractNo}</b>
                  </span>
                </h4> */}
              </div>
              <div className='d-flex ms-auto'>
                <div className='me-5 pe-2'>
                  <div className='ms-auto' style={{width: 'fit-content'}}>
                    <div
                      className={
                        tenancy?.tenancyStatus == 0
                          ? 'status booked-bar '
                          : tenancy?.tenancyStatus == 1
                          ? 'status active-bar '
                          : tenancy?.tenancyStatus == 2
                          ? 'status expiring-bar '
                          : tenancy?.tenancyStatus == 3
                          ? 'status expired-bar '
                          : 'status  no-st-bar '
                      }
                    >
                      {tenancy?.tenancyStatus == 0
                        ? 'Booked'
                        : tenancy?.tenancyStatus == 1
                        ? 'Active'
                        : tenancy?.tenancyStatus == 2
                        ? 'Expiring'
                        : tenancy?.tenancyStatus == 3
                        ? 'Expired'
                        : '-'}
                    </div>
                    <i>
                      {' '}
                      {tenancy?.tenancyStatusDateTime
                        ? `${moment(tenancy?.tenancyStatusDateTime).format(
                            'DD.MM.YYYY - HH:mm A'
                          )} `
                        : ''}
                    </i>
                  </div>
                </div>
                <div className=''>
                  <div className='me-5 pe-2' style={{width: 'fit-content'}}>
                    <div
                      className={
                        tenancy?.renewalStatus == 0
                          ? 'status-view create-st'
                          : tenancy?.renewalStatus == 1
                          ? 'status-view create-st'
                          : tenancy?.renewalStatus == 2
                          ? 'status-view renewed-st'
                          : tenancy?.renewalStatus == 7
                          ? 'status-view cancelled-st '
                          : 'status-view not-saved '
                      }
                    >
                      {tenancy?.renewalStatus == 0
                        ? 'Not Renewed'
                        : tenancy?.renewalStatus == 1
                        ? 'Under Renewal'
                        : tenancy?.renewalStatus == 2
                        ? 'Renewed'
                        : tenancy?.renewalStatus == 7
                        ? 'Terminated'
                        : '-'}
                    </div>
                    <i>
                      {' '}
                      {tenancy?.renewalStatusDateTime
                        ? `${moment(tenancy?.renewalStatusDateTime).format(
                            'DD.MM.YYYY - HH:mm A'
                          )} `
                        : ''}
                    </i>
                  </div>
                </div>
                <div className=''>
                  <div className='me-5 ' style={{width: 'fit-content'}}>
                    <>
                      <div className={tenancy?.isDraft ? 'status draft-st ' : 'status create-st'}>
                        {tenancy?.isDraft ? 'Draft' : 'Created'}
                      </div>
                      <i>
                        {' '}
                        {tenancy?.draftStatusDateTime
                          ? `${moment(tenancy?.draftStatusDateTime).format(
                              'DD.MM.YYYY - HH:mm A'
                            )} `
                          : ''}
                      </i>
                    </>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-5 pe-0'>
                <div className='d-flex'>
                  <div className='d-flex align-items-center'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '95px'}} className='labl-gry'>
                        {' '}
                        Term{' '}
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2'>
                      <b>
                        {' '}
                        {tenancy?.duration?.start_date
                          ? moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')
                          : '-'}
                        {' - '}
                        {tenancy?.duration?.end_date
                          ? moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')
                          : '-'}
                      </b>
                    </span>
                  </div>
                  {/* <div className='col-3'> */}
                  {/* <h4 className='mt-5 mb-4' style={{color: '#a1a5b7'}}>
                <label style={{width: '100px'}}> Term </label>
                <span className='ms-3 ps-1 head-text'>
                  <b>
                    {' '}
                    {tenancy?.duration?.start_date
                      ? moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')
                      : '-'}
                    {' - '}
                    {tenancy?.duration?.end_date
                      ? moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')
                      : '-'}
                  </b>
                </span>
              </h4> */}
                  {/* </div> */}
                  <div className='d-flex align-items-center ms-5 ps-5'>
                    <h4 className='my-2 ms-2' style={{color: 'black'}}>
                      <label style={{width: '80px'}} className='labl-gry'>
                        {' '}
                        Duration{' '}
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2'>
                      <b>
                        {' '}
                        {tenancy?.duration?.days === 1
                          ? tenancy?.duration?.days + ' Day'
                          : tenancy?.duration?.days >= 2
                          ? tenancy?.duration?.days + ' Days'
                          : tenancy?.duration?.days <= 0
                          ? 0 + ' Days'
                          : ''}
                      </b>
                    </span>
                  </div>
                  {/* <div className='col-3'> */}
                  {/* <h4 className='mt-5 ms-5 ps-5 me-5 pe-5' style={{color: '#a1a5b7'}}>
                <label style={{width: '80px'}} className=''>
                  {' '}
                  Duration{' '}
                </label>
                <span className='ms-5 ps-1 head-text'>
                  <b>
                    {' '}
                    {tenancy?.duration?.days === 1
                      ? tenancy?.duration?.days + ' Day'
                      : tenancy?.duration?.days >= 2
                      ? tenancy?.duration?.days + ' Days'
                      : tenancy?.duration?.days <= 0
                      ? 0 + ' Days'
                      : ''}
                  </b>
                </span>
              </h4> */}
                  {/* </div> */}
                </div>
              </div>

              <div className='col-7 ps-0'>
                <div className='d-flex align-items-center'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '80px'}} className='labl-gry'>
                      {' '}
                      Remaining{' '}
                    </label>
                  </h4>
                  <span className='ms-5 ps-1 my-2'>
                    <b>
                      {' '}
                      {tenancy?.remainingDays === 1
                        ? tenancy?.remainingDays + ' Day'
                        : tenancy?.remainingDays >= 2
                        ? tenancy?.remainingDays + ' Days'
                        : tenancy?.remainingDays <= 0
                        ? 0 + ' Days'
                        : ''}
                    </b>
                  </span>
                </div>
                {/* <div className='col-3'> */}
                {/* <h4 className='mt-5 ms-5 ps-5' style={{color: '#a1a5b7'}}>
                <label style={{width: '90px'}} className=''> Remaining </label>
                <span className='ms-5 ps-1 head-text'>
                  <b>
                    {' '}
                    {tenancy?.remainingDays === 1
                      ? tenancy?.remainingDays + ' Day'
                      : tenancy?.remainingDays >= 2
                      ? tenancy?.remainingDays + ' Days'
                      : tenancy?.remainingDays <= 0
                      ? 0 + ' Days'
                      : ''}
                  </b>
                </span>
              </h4> */}
                {/* </div> */}
              </div>
            </div>

            <div className='row '>
              <div className='col-5 pe-0'>
                <div className=' d-flex'>
                  {/* <div className=''> */}
                  <div className='d-flex '>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label
                        style={{minWidth: '90px'}}
                        className='labl-gry '
                        htmlFor='exampleFormControlInput1'
                      >
                        Benefits
                      </label>
                    </h4>
                    <div className='test ms-5 ps-3 my-2'>
                      {tenancy?.benefits?.none === true && (
                        <div className='d-flex align-items-center'>
                          <p className='bullet-ic mb-0'></p>
                          <p className=' ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            None
                          </p>
                        </div>
                      )}

                      {tenancy?.benefits?.chillerFree === true && (
                        <div className='d-flex align-items-center'>
                          <p className='bullet-ic mb-0'></p>
                          <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Chiller Free
                          </p>
                        </div>
                      )}

                      {tenancy?.benefits?.maintenanceFree === true && (
                        <div className='d-flex align-items-center'>
                          <p className='bullet-ic mb-0'></p>
                          <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                            {' '}
                            Maintenance Free
                          </p>
                        </div>
                      )}
                      {tenancy?.benefits?.other?.length > 0 &&
                        tenancy?.benefits?.other?.map((opt: any, ind: any) => (
                          <div className='d-flex align-items-center'>
                            <p className='bullet-ic mb-0'></p>
                            <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              {opt}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <h4 className='mt-5 d-flex' style={{color: '#a1a5b7'}}>
                <label style={{width: '100px'}}> Benefits </label>
                <span className=''>
                  <ul className='head-text d-flex my-0 ps-5' style={{color: 'black'}}>
                    {tenancy?.benefits?.none === true && (
                      <li className=''>
                        <b>None </b>
                      </li>
                    )}

                    {tenancy?.benefits?.chillerFree === true && (
                      <li className=''>
                        <b>Chiller Free {''} </b>
                      </li>
                    )}

                    {tenancy?.benefits?.maintenanceFree === true && (
                      <li className=''>
                        <b>{', '}Maintenance Free </b>
                      </li>
                    )}

                    {tenancy?.benefits?.other?.length > 0 && (
                      <li className='mb-2'>
                        <b>
                          {', '} {tenancy?.benefits?.other}
                        </b>
                      </li>
                    )}
                  </ul>
                </span>
              </h4> */}
                  {/* </div> */}

                  <div className='d-flex align-items-center ms-5 ps-5'>
                    <h4 className='my-2 ms-5 ps-3' style={{color: 'white'}}>
                      <label style={{width: '80px'}} className=''>
                        {' '}
                        Duration{' '}
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2' style={{color: 'white'}}>
                      <b>
                        {' '}
                        {tenancy?.duration?.days === 1
                          ? tenancy?.duration?.days + ' Day'
                          : tenancy?.duration?.days >= 2
                          ? tenancy?.duration?.days + ' Days'
                          : tenancy?.duration?.days <= 0
                          ? 0 + ' Days'
                          : ''}
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              <div className='col-7 ps-0'>
                <div className='row'>
                  <div className='col-3 pe-0'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{width: 'fit-content'}} className='labl-gry'>
                        {' '}
                        Financial Summary{' '}
                      </label>
                    </h4>
                  </div>
                  <div className='col-9 ps-0'>
                    <div className='d-flex'>
                      <div className='test' style={{width: '-webkit-fill-available'}}>
                        <div className='row mb-0'>
                          <div className='d-flex align-items-center col-3 ps-1'>
                            <p className='bullet-ic mb-0'></p>
                            <h4 className='my-1 ms-3' style={{color: 'black'}}>
                              {' '}
                              Rent
                            </h4>
                          </div>
                          <div className='col-4'>
                            <div className='d-flex align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{minWidth: '90px'}} className='labl-gry'>
                                  {' '}
                                  Total Amount{' '}
                                </label>
                              </h4>

                              <span className='ms-5 ps-1 my-2'>
                                <b>
                                  {rentFin?.totalAmount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </b>
                              </span>
                            </div>
                          </div>

                          <div className='col-4'>
                            {' '}
                            <div className='d-flex align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{minWidth: '90px'}} className='labl-gry'>
                                  {' '}
                                  No. of Payment{' '}
                                </label>
                              </h4>

                              <span className='ms-5 ps-1 my-2'>
                                <b>{rentFin?.noOfPayment}</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row mb-0'>
                          <div className='d-flex align-items-center col-3 ps-1'>
                            <p className='bullet-ic mb-0'></p>
                            <h4 className='my-1 ms-3' style={{color: 'black'}}>
                              {' '}
                              Security Deposit
                            </h4>
                          </div>
                          <div className='col-4'>
                            <div className='d-flex align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{minWidth: '90px'}} className='labl-gry'>
                                  {' '}
                                  Total Amount{' '}
                                </label>
                              </h4>

                              <span className='ms-5 ps-1 my-2'>
                                <b>
                                  {securityFin?.totalAmount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </b>
                              </span>
                            </div>
                          </div>

                          <div className='col-4'>
                            {' '}
                            <div className='d-flex align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{minWidth: '90px'}} className='labl-gry'>
                                  {' '}
                                  No. of Payment{' '}
                                </label>
                              </h4>

                              <span className='ms-5 ps-1 my-2'>
                                <b>{securityFin?.noOfPayment}</b>
                              </span>
                            </div>
                          </div>
                        </div>

                        {otherFinancials?.length > 0 &&
                          otherFinancials?.map((fn: any, ind: any) => (
                            <>
                              <div className='row mb-0'>
                                <div className='d-flex align-items-center col-3 ps-1'>
                                  <p className='bullet-ic mb-0'></p>
                                  <h4 className='my-1 ms-3' style={{color: 'black'}}>
                                    {' '}
                                    {fn?.name}
                                  </h4>
                                </div>
                                <div className='col-4'>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        Total Amount{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>
                                        {fn?.totalAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div className='col-4'>
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        No. of Payment{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>{fn?.noOfPayment}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex mb-2'>
              <h6 className='my-2 ms-auto' style={{color: '#a1a5b7', width: '40%'}}></h6>
            </div>
          </div>
        </div>
        {/* <div className='grid-out p-0 container-xxl' style={{backgroundColor: 'none'}}>
          {isEditContract ? (
            <div className='card side px-5 py-3'>
              <div className='d-flex mb-3'>
                <h2 className='mx-0 green_color'>Contract Details</h2>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3 ms-auto'
                  onClick={() => updateContractDetails()}
                >
                  Update
                </a>
                <a
                  className='btn btn-sm fw-bold ms-3 text-white'
                  onClick={() => {
                    const values: any = {
                      contractNo: tenancy?.contractNo,
                      tenancyStatus: tenancy?.tenancyStatus,
                      start_date: tenancy?.duration?.start_date,
                      end_date: tenancy?.duration?.end_date,
                      days: tenancy?.duration?.days,
                      benefits: tenancy?.benefits,
                    }
                    setContractDetails(values)
                    setIsEditContract(false)
                  }}
                  style={{
                    backgroundColor: '#D54645',
                    boxShadow: '0px 0px 20px 0px #6e8aa6',
                  }}
                >
                  Cancel
                </a>
              </div>

              <div className=' text-muted grid_big_col py-2'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Contract No.
                </label>
                <input
                  type='number'
                  className='form-control form-control-solid'
                  placeholder=''
                  name='contractNo'
                  value={contractDetails?.contractNo}
                  onChange={(e) => {
                    const values = {...contractDetails}
                    values.contractNo = e.target.value
                    setContractDetails(values)
                  }}
                />
              </div>

              <div className=' text-muted grid_big_col py-2'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Tenancy Status
                </label>
                <select
                  className='form-select form-select-solid'
                  value={Number(contractDetails?.tenancyStatus)}
                  onChange={(e) => {
                    const values = {...contractDetails}
                    values.tenancyStatus = e.target.value
                    setContractDetails(values)
                  }}
                  name='tenancyStatus'
                  disabled
                >
                  <option value='' disabled>
                    Select
                  </option>
                  <option value={0}>Booked</option>
                  <option value={1}>Active</option>
                  <option value={4}>Renewed</option>
                </select>
              </div>

              <div className=' text-muted grid_big_col py-2'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Start - End
                </label>

                <RangePicker
                  value={[
                    moment(
                      contractDetails?.start_date ? contractDetails?.start_date : new Date(),
                      dateFormat
                    ),
                    moment(
                      contractDetails?.end_date ? contractDetails?.end_date : new Date(),
                      dateFormat
                    ),
                  ]}
                  defaultValue={[
                    moment(
                      contractDetails?.start_date ? contractDetails?.start_date : new Date(),
                      dateFormat
                    ),
                    moment(
                      contractDetails?.end_date ? contractDetails?.end_date : new Date(),
                      dateFormat
                    ),
                  ]}
                  format={dateFormat}
                  className='form-control form-control-solid'
                  onChange={(dates: any) => {
                    const values = {...contractDetails}
                    values.start_date = dates?.[0].format('YYYY-MM-DD')
                    values.end_date = dates?.[1].format('YYYY-MM-DD')

                    var start = moment(dates?.[0])
                    var end = moment(dates?.[1])

                    values.days = end.diff(start, 'days', true)
                    setContractDetails(values)
                  }}
                  disabled={tenancy?.tenancyStatus === 1 ? true : false}
                />
              </div>

              <div className='text-muted grid_big_col py-2 ' style={{alignItems: 'flex-start'}}>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  Duration
                </label>
                <div className='form-control form-control-solid'>
                  {' '}
                  {contractDetails?.days}
                  {' days'}{' '}
                </div>
              </div>

              <div className='d-flex align-items-left py-2 gap-5 align-items-center'>
                <label className='required form-label'>
                  <span className=''>Benefits</span>
                </label>
                <div className='d-flex align-items-center py-2 gap-5 mx-2'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={contractDetails?.benefits?.none === true ? true : false}
                      onChange={(e: any) => {
                        const values = {...contractDetails}
                        values.benefits.none = !values.benefits.none
                        values.benefits.chillerFree = false
                        values.benefits.maintenanceFree = false
                        setContractDetails(values)
                      }}
                    />
                    <label className='form-check-label' htmlFor='vacant0'>
                      None
                    </label>
                  </div>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='occupied1'
                    name='chillerFree'
                    checked={contractDetails?.benefits?.chillerFree === true ? true : false}
                    disabled={contractDetails?.benefits?.none === true ? true : false}
                    onChange={(e: any) => {
                      const values = {...contractDetails}
                      values.benefits.chillerFree = !values.benefits.chillerFree
                      setContractDetails(values)
                    }}
                  />
                  <label className='form-check-label' htmlFor='occupied1'>
                    Chiller Free
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='occupied2'
                    name='maintenanceFree'
                    checked={contractDetails?.benefits?.maintenanceFree === true ? true : false}
                    disabled={contractDetails?.benefits?.none === true ? true : false}
                    onChange={(e: any) => {
                      const values = {...contractDetails}
                      values.benefits.maintenanceFree = !values.benefits.maintenanceFree
                      setContractDetails(values)
                    }}
                  />
                  <label className='form-check-label' htmlFor='occupied2'>
                    Maintenance Free
                  </label>
                </div>
                <div className='form-check form-check-custom form-check-solid form-check-sm '>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='occupied3'
                    name='other'
                    checked={
                      contractDetails?.benefits?.other?.length > 0 || showOther == true
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setShowOther(!showOther)
                      const values = {...contractDetails}

                      if (e.target.checked == false) {
                        values.benefits.other = []
                        setContractDetails(values)
                      } else {
                        values.benefits.none = false
                      }
                    }}
                  />
                  <div className=''>
                    <label className='form-check-label' htmlFor='occupied3'>
                      Other
                    </label>
                  </div>
                </div>
              </div>

              {showOther && (
                <>
                  <div className='d-flex gap-3'>
                    <img src={info} width='24px' height='24px' />
                    <p className='m-0 mb-4'>Please enter comma separated values</p>
                  </div>
                  <input
                    type='other'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='other'
                    value={contractDetails?.benefits?.other}
                    onChange={(e: any) => {
                      const values = {...contractDetails}
                      values.benefits.other = e.target.value
                      setContractDetails(values)
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <div className='card side px-5 py-3'>
              <div className='d-flex'>
                <h2 className='mx-0 green_color '>Contract Details</h2>
                {isPrev == false && tenancy?.length != 0 && (
                  <img
                    src={pen}
                    height='18'
                    width='18'
                    className='cursor-pointer ms-auto'
                    onClick={() => {
                      setIsEditContract(true)
                    }}
                    style={{cursor: 'pointer'}}
                  />
                )}
              </div>

              <div className='d-flex mb-2'>
                <h6 className='my-2' style={{color: '#a1a5b7', width: '60%'}}>
                  <label style={{width: '100px'}}>Contract ID</label>
                  <span className='ms-5 ps-1 head-text'>
                    <b> {tenancy?.id}</b>
                  </span>
                </h6>

                <h6 className='my-2 ms-auto' style={{color: '#a1a5b7', width: '40%'}}>
                  <label style={{width: '90px'}}> Tenancy Status </label>
                  <span
                    className={
                      tenancy?.tenancyStatus == 0
                        ? 'ms-5 ps-1 head-text booked-color'
                        : tenancy?.tenancyStatus == 1
                        ? 'ms-5 ps-1 head-text active-color'
                        : tenancy?.tenancyStatus === 2
                        ? 'ms-5 ps-1 head-text expiring-color'
                        : tenancy?.tenancyStatus === 3
                        ? 'ms-5 ps-1 head-text ended-color'
                        : tenancy?.tenancyStatus === 4
                        ? 'ms-5 ps-1 head-text renewed-color'
                        : 'ms-5 ps-1 head-text'
                    }
                  >
                    <b className='p-5'>
                      {' '}
                      {tenancy?.tenancyStatus === 0
                        ? 'Booked'
                        : tenancy?.tenancyStatus === 1
                        ? 'Active'
                        : tenancy?.tenancyStatus === 2
                        ? 'Expiring'
                        : tenancy?.tenancyStatus === 3
                        ? 'Expired'
                        : '-'}
                    </b>
                  </span>
                </h6>
              </div>

              <div className='d-flex mb-2'>
                <h6 className='my-2' style={{color: '#a1a5b7'}}>
                  <label style={{width: '100px'}}> Contract No. </label>
                  <span className='ms-5 ps-1 head-text'>
                    <b> {tenancy?.contractNo}</b>
                  </span>
                </h6>
                <h6 className='my-2 ms-auto' style={{color: '#a1a5b7', width: '40%'}}>
                  <label style={{width: '60px'}}> </label>
                  <span className='head-text ms-2'>
                    <i>
                      {' '}
                      {tenancy?.tenancyStatusDateTime
                        ? `${moment(tenancy?.tenancyStatusDateTime).format('DD.MM.YYYY HH mm A')}`
                        : '-'}
                    </i>
                  </span>
                </h6>
              </div>

              <h6 className='mb-2' style={{color: '#a1a5b7'}}>
                <label style={{width: '100px'}}> Start </label>
                <span className='ms-5 ps-1 head-text'>
                  <b>
                    {' '}
                    {tenancy?.duration?.start_date
                      ? moment(tenancy?.duration?.start_date).format('DD/MM/YYYY')
                      : '-'}
                  </b>
                </span>
              </h6>

              <h6 className='my-2' style={{color: '#a1a5b7'}}>
                <label style={{width: '100px'}}> End </label>
                <span className='ms-5 ps-1 head-text'>
                  <b>
                    {' '}
                    {tenancy?.duration?.end_date
                      ? moment(tenancy?.duration?.end_date).format('DD/MM/YYYY')
                      : '-'}
                  </b>
                </span>
              </h6>

              <div className='d-flex'>
                <h6 className='my-2' style={{color: '#a1a5b7', width: '60%'}}>
                  <label style={{width: '100px'}}> Duration </label>
                  <span className='ms-5 ps-1 head-text'>
                    <b>
                      {' '}
                      {tenancy?.duration?.days === 1
                        ? tenancy?.duration?.days + ' Day'
                        : tenancy?.duration?.days >= 2
                        ? tenancy?.duration?.days + ' Days'
                        : tenancy?.duration?.days <= 0
                        ? 0 + ' Days'
                        : ''}
                    </b>
                  </span>
                </h6>
                <h6 className='my-2 ms-auto' style={{color: '#a1a5b7', width: '40%'}}>
                  <label style={{width: '90px'}}> Remaining </label>
                  <span className='ms-5 ps-1 head-text'>
                    <b>
                      {' '}
                      {tenancy?.remainingDays === 1
                        ? tenancy?.remainingDays + ' Day'
                        : tenancy?.remainingDays >= 2
                        ? tenancy?.remainingDays + ' Days'
                        : tenancy?.remainingDays <= 0
                        ? 0 + ' Days'
                        : ''}
                    </b>
                  </span>
                </h6>
              </div>

              {(tenancy?.benefits?.chillerFree ||
                tenancy?.benefits?.maintenanceFree ||
                tenancy?.benefits?.other) && (
                <h6 className='my-2 d-flex ' style={{color: '#a1a5b7'}}>
                  <label style={{width: '100px'}}> Benefits</label>

                  <ul className='head-text' style={{color: 'black'}}>
                    {tenancy?.benefits?.none === true && (
                      <li className='mb-2'>
                        <b>None </b>
                      </li>
                    )}

                    {tenancy?.benefits?.chillerFree === true && (
                      <li className='mb-2'>
                        <b>Chiller Free </b>
                      </li>
                    )}

                    {tenancy?.benefits?.maintenanceFree === true && (
                      <li className='mb-2'>
                        <b>Maintenance Free </b>
                      </li>
                    )}

                    {tenancy?.benefits?.other?.length > 0 && (
                      <li className='mb-2'>
                        <b>{tenancy?.benefits?.other}</b>
                      </li>
                    )}
                  </ul>
                </h6>
              )}
            </div>
          )}

          {isEditDocument ? (
            <div className='card side px-5 py-3 gap-5 '>
              <div className='d-flex mb-3'>
                <h2 className='mx-0 green_color '>Documents</h2>

                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3 ms-auto'
                  onClick={() => updateDocuments()}
                >
                  Update
                </a>

                <a
                  className='btn btn-sm fw-bold ms-3 text-white'
                  onClick={() => {
                    setIsEditDocument(false)
                  }}
                  style={{
                    backgroundColor: '#D54645',
                    boxShadow: '0px 0px 20px 0px #6e8aa6',
                  }}
                >
                  Cancel
                </a>
              </div>
              <div className='img_link_big'>
                {documents?.contract ? (
                  <>
                    <div className='d-flex mb-2 align-items-center'>
                      <h5 className='mx-0 text-muted mt-2'>
                        <b>Contract</b>
                      </h5>
                      <label htmlFor='uploadcontract' className='mx-5 btn'>
                        <img src={upload} height={18} width={18} />
                      </label>
                      <input
                        type='file'
                        hidden
                        id='uploadcontract'
                        className='form-control form-control-solid'
                        placeholder=''
                        name='contract'
                        // value={documents?.contract}
                        onChange={(e: any) => onFilesAddedSingle(e, 'uploadContract', true)}

                        // onChange={(e: any) => onFilesAdded(e, 'uploadContract')}
                      />
                    </div>
                    <a className='mx-0 ' href={`${Bucket}${documents?.contract}`} target='_blank'>
                      {documents?.contract?.slice(-3) == 'jpg' ||
                      documents?.contract?.slice(-4) == 'jpeg' ? (
                        <img
                          src={`${Bucket}${documents?.contract}`}
                          alt='Img'
                          className='img_preview_small card m-0'
                        />
                      ) : (
                        <img src={file} width='80px' height='80px' className='main_img' />
                      )}
                    </a>
                  </>
                ) : (
                  <div className='d-flex mb-2 align-items-center'>
                    <h5 className='mx-0 text-muted mt-2'>
                      <b>Contract</b>
                    </h5>
                    <label htmlFor='uploadcontract' className='mx-5 btn'>
                      <img src={upload} height={18} width={18} />
                    </label>
                    <input
                      type='file'
                      hidden
                      id='uploadcontract'
                      className='form-control form-control-solid'
                      placeholder=''
                      name='contract'
                      // value={documents?.contract}
                      onChange={(e: any) => onFilesAddedSingle(e, 'uploadContract', true)}

                      // onChange={(e: any) => onFilesAdded(e, 'uploadContract')}
                    />
                  </div>
                )}
              </div>
              <div className='img_link_big'>
                <div className='d-flex mb-2 align-items-start'>
                  <h5 className='mx-0 text-muted mt-2'>
                    <b>Other</b>
                  </h5>
                  <label title='Upload Other' htmlFor='tenancy' className='btn'>
                    <img src={upload} height={18} width={18} />
                  </label>
                  <input
                    type='file'
                    hidden
                    id='tenancy'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='otherT'
                    onChange={(e: any) => onFilesAdded(e, 'tenancy')}
                  />
                </div>
                <div className='tops mw-350px d-flex flex-wrap gap-5 align-items-center'>
                  {uploadOtherImgTenancy.length
                    ? uploadOtherImgTenancy?.map((el: any, i: any) => (
                        <div className='img-sets'>
                          {checkIsImage(el) ? (
                            <>
                              <img
                                src={Bucket + el}
                                width='80px'
                                height='80px'
                                className='main_img'
                              />
                              {editName !== i ? (
                                <div
                                  className=' mt-3 d-flex align-items-center gap-3 flex-wrap'
                                  style={{width: '80px'}}
                                >
                                  <p className='m-0 p-0' style={{width: '50px'}}>
                                    {getFilename(el).length > 6
                                      ? getFilename(el).slice(0, 6) + '...'
                                      : getFilename(el)}
                                  </p>
                                  <img
                                    src={pencil}
                                    alt=''
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      setEditName(i)
                                      setnewName(
                                        uploadOtherImgTenancy[i].split('/').pop().split('.')[0]
                                      )
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  <br />
                                  <div className='d-flex gap-2 mt-2'>
                                    <input
                                      type='text'
                                      value={newName}
                                      style={{maxWidth: '100px'}}
                                      className='form-control form-control-solid p-2'
                                      onChange={(e: any) => {
                                        setnewName(e.target.value)
                                        setIsEditImgName(true)
                                      }}
                                    />
                                    <a
                                      className='btn btn-sm fw-bold btn-primary btn-green'
                                      onClick={() => changeFileNameOther('tenancy', i)}
                                    >
                                      Update
                                    </a>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <img src={file} width='80px' height='80px' className='main_img' />
                          )}
                          <img
                            src={closered}
                            className='closered'
                            width='25px'
                            height='25px'
                            onClick={() => deleteUploadImg(i, 'tenancy', el)}
                          />
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          ) : (
            <div className='card side px-5 py-3 gap-5 '>
              <div className='d-flex'>
                <h2 className='mx-0 green_color '>Documents</h2>
                {isPrev == false && tenancy?.length != 0 && (
                  <img
                    src={pen}
                    height='18'
                    width='18'
                    className='cursor-pointer ms-auto'
                    onClick={() => {
                      setIsEditDocument(true)
                    }}
                    style={{cursor: 'pointer'}}
                  />
                )}
              </div>
              <div className='img_link_big'>
                {tenancy?.document?.contract && (
                  <a
                    className='mx-0 '
                    href={`${Bucket}${tenancy?.document?.contract}`}
                    target='_blank'
                  >
                    <h6 className='mx-0 text-muted'>
                      <b>Contract</b>
                    </h6>
                    {tenancy?.document?.contract?.slice(-3) == 'jpg' ||
                    tenancy?.document?.contract?.slice(-4) == 'jpeg' ? (
                      <img
                        src={`${Bucket}${tenancy?.document?.contract}`}
                        alt='Img'
                        className='img_preview_small card m-0'
                      />
                    ) : (
                      <img src={file} width='80px' height='80px' className='main_img' />
                    )}
                  </a>
                )}
              </div>

              {tenancy?.document?.other?.length ? (
                <>
                  <h6 className='mx-0 text-muted'>Other</h6>
                  <div className=''>
                    {tenancy?.document?.other?.map((el: any) => (
                      <a className='mx-0' href={`${Bucket}${el}`} target='_blank'>
                        <img
                          src={Bucket + el}
                          width='90px'
                          height='90px'
                          className='main_img me-5'
                        />
                      </a>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div> */}
        {isPrev == false && (
          <div className='my-5' style={{backgroundColor: 'none'}}>
            <div className='card card-flush px-5 py-2 h-100'>
              <div className='d-flex '>
                <h2 className='mx-0 green_color mt-2  '>Upcoming Tenancies</h2>
              </div>
              <div
                className='table-responsive mb-5'
                // onWheel={handleMouseWheel}
                // ref={tableRef}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-start min-w-125px'>Contract No.</th>
                      <th className='text-start min-w-125px'>Tenancy Status</th>
                      <th className='text-start min-w-125px'>Tenant Source</th>
                      <th className='text-start min-w-100px'>Tenant</th>
                      <th className='text-start min-w-100px'>Duration</th>
                      <th className='text-start min-w-200px'>Start - End</th>
                      <th className='text-start min-w-100px'>Rating</th>
                      <th className='text-start min-w-75px'>Form</th>
                      {/* <th className='text-start min-w-100px'></th> */}
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {upcomingTenancy?.length > 0 ? (
                      <>
                        {upcomingTenancy?.map((tn: any, i: any) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  localStorage.setItem('tenancyContract', tn?.contractNo)

                                  if (tn?.isDraft) {
                                    navigate(`/tenancy/draft/2/${tn?.unitId}/${tn?._id}`)
                                    localStorage.setItem('isDraft', '2')
                                    localStorage.setItem('BuildingId', tn?.buildingId)
                                    localStorage.setItem('CommunityId', tn?.communityId)
                                    localStorage.setItem('ClusterId', tn?.clusterId)
                                    localStorage.setItem('UnitId', tn?.unitId)
                                    localStorage.setItem('newTenancyId', tn?._id)
                                  } else {
                                    localStorage.setItem('tenancyContract', tn?.contractNo)
                                    navigate(`/tenancy-view/2/${tn?.unitId}/${tn?._id}`)
                                    localStorage.setItem('isDraft', '2')
                                    localStorage.setItem('BuildingId', tn?.buildingId)
                                    localStorage.setItem('CommunityId', tn?.communityId)
                                    localStorage.setItem('ClusterId', tn?.clusterId)
                                    localStorage.setItem('UnitId', tn?.unitId)
                                    localStorage.setItem('newTenancyId', tn?._id)
                                  }
                                }}
                                className='cursor-pointer'
                              >
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start'
                                >
                                  {tn?.contractNo}
                                </td>
                                <td className='text-start'>
                                  <div
                                    className={
                                      tn?.tenancyStatus == 0
                                        ? 'status booked-bar me-5'
                                        : tn?.tenancyStatus == 1
                                        ? 'status active-bar me-5'
                                        : tn?.tenancyStatus == 2
                                        ? 'status expiring-bar me-5'
                                        : tn?.tenancyStatus == 3
                                        ? 'status expired-bar me-5'
                                        : 'status  no-st-bar me-5'
                                    }
                                  >
                                    {tn?.tenancyStatus == 0
                                      ? 'Booked'
                                      : tn?.tenancyStatus == 1
                                      ? 'Active'
                                      : tn?.tenancyStatus == 2
                                      ? 'Expiring'
                                      : tn?.tenancyStatus == 3
                                      ? 'Expired'
                                      : '-'}
                                  </div>{' '}
                                  {/* <p
                                    className={
                                      tn?.tenancyStatus == 0
                                        ? 'booked-color my-3'
                                        : tn?.tenancyStatus == 1
                                        ? 'active-color my-3'
                                        : tn?.tenancyStatus === 2
                                        ? 'expiring-color my-3'
                                        : tn?.tenancyStatus === 3
                                        ? 'ended-color my-3'
                                        : tn?.tenancyStatus === 4
                                        ? 'renewed-color my-3'
                                        : tn?.tenancyStatus === 5
                                        ? 'terminated-color my-3'
                                        : tn?.tenancyStatus === 6
                                        ? 'pending-color my-3'
                                        : ''
                                    }
                                  >
                                    {' '}
                                    <b className='p-5'>
                                      {tn?.tenancyStatus === 0
                                        ? 'Booked'
                                        : tn?.tenancyStatus === 1
                                        ? 'Active'
                                        : tn?.tenancyStatus === 2
                                        ? 'Expiring'
                                        : tn?.tenancyStatus === 3
                                        ? 'Ended'
                                        : tn?.tenancyStatus === 4
                                        ? 'Renewed'
                                        : tn?.tenancyStatus === 5
                                        ? 'Terminated'
                                        : tn?.tenancyStatus === 6
                                        ? 'Tenant pending approval'
                                        : '-'}
                                    </b>
                                  </p> */}
                                </td>
                                <td className='text-start' data-order='Denied'>
                                  {tn?.tenant[0]?.tenantSource == 0 ? 'Agent' : 'Direct'}
                                </td>
                                <td className='text-start'>
                                  {tn?.tenant[0]?.tenantType == 0 ? 'Main Tenant' : 'Sub Tenant'}
                                </td>
                                <td className='text-start' data-order='2022-09-09'>
                                  {tn?.duration?.days}
                                </td>
                                <td className='text-start' data-order='2022-09-11'>
                                  {tn?.duration?.start_date
                                    ? moment(tn?.duration?.start_date).format('YYYY.MM.DD')
                                    : '-'}
                                  {' - '}{' '}
                                  {tn?.duration?.end_date
                                    ? moment(tn?.duration?.end_date).format('YYYY.MM.DD')
                                    : '-'}
                                </td>
                                <td className='text-start' data-order='2022-09-11'>
                                  <span className='svg-icon svg-icon-2' style={{color: '#ffad0f'}}>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>{' '}
                                  {tn?.rating}
                                </td>
                                <td className='text-start'>
                                  {' '}
                                  {/* {tn?.isDraft == true ? 'Draft' : 'Created'} */}
                                  <>
                                    {tn?.isCancel ? (
                                      <>
                                        <div className='status cancelled-st me-5'>Cancelled</div>
                                      </>
                                    ) : (
                                      <div
                                        className={
                                          tn?.isDraft
                                            ? 'status draft-st me-5'
                                            : 'status create-st me-5'
                                        }
                                      >
                                        {tn?.isDraft ? 'Draft' : 'Created'}
                                      </div>
                                    )}
                                  </>
                                </td>

                                {/* <td className='text-start d-flex align-items-center'>
                                  <div className='menu-item px-3'>
                                    <div
                                      onClick={() => {
                                        localStorage.setItem('tenancyContract', tn?.contractNo)

                                        if (tn?.isDraft) {
                                          navigate(`/create-tenant/2/${tn?._id}/${tn?._id}`)
                                          localStorage.setItem('UnitId', tenancy?.unitId)
                                        } else {
                                          setTimeout(() => {
                                            getTenancyByID(tn?._id)
                                          }, 500)
                                        }
                                      }}
                                      className='btn btn-sm fw-bold btn-primary btn-green hover-none me-3'
                                    >
                                      View
                                    </div>
                                    {tn?.isCancel && (
                                      <AiFillDelete
                                        color='#D72852'
                                        fontSize={20}
                                        // onClick={() => deleteRow(i)}
                                        onClick={() => {
                                          Swal.fire({
                                            text: 'Are you sure you want to permanently delete this Tenancy?',
                                            icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#D72852',
                                            confirmButtonText: 'Confirm',
                                            showCancelButton: true,
                                            // cancelButtonColor: "transparent",
                                            cancelButtonText: 'Cancel',
                                          }).then((res: any) => {
                                            if (res.isConfirmed) {
                                              deleteTenancy(tn?._id)
                                            }
                                          })
                                        }}
                                        style={{cursor: 'pointer'}}
                                      />
                                    )}
                                  </div>
                                </td> */}
                              </tr>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={15} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end mt-1'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isPrev == false && (
          <div className='my-5' style={{backgroundColor: 'none'}}>
            <div className='card card-flush px-5 py-2 h-100'>
              <div className='d-flex '>
                <h2 className='mx-0 green_color mt-2  '>Previous Tenancies</h2>
              </div>
              <div
                className='table-responsive mb-5'
                // onWheel={handleMouseWheel}
                // ref={tableRef}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-start min-w-125px'>Contract No.</th>
                      <th className='text-start min-w-125px'>Tenancy Status</th>
                      <th className='text-start min-w-125px'>Tenant Source</th>
                      <th className='text-start min-w-100px'>Tenant</th>
                      <th className='text-start min-w-100px'>Duration</th>
                      <th className='text-start min-w-200px'>Start - End</th>
                      <th className='text-start min-w-100px'>Rating</th>
                      <th className='text-start min-w-75px'>Form</th>
                      {/* <th className='text-start min-w-100px'></th> */}
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {previousTenancy?.length > 0 ? (
                      <>
                        {previousTenancy?.map((tn: any, i: any) => {
                          return (
                            <>
                              <tr
                                onClick={() => {
                                  localStorage.setItem('tenancyContract', tn?.contractNo)

                                  if (tn?.isDraft) {
                                    navigate(`/tenancy/draft/2/${tn?.unitId}/${tn?._id}`)
                                    localStorage.setItem('isDraft', '2')
                                    localStorage.setItem('BuildingId', tn?.buildingId)
                                    localStorage.setItem('CommunityId', tn?.communityId)
                                    localStorage.setItem('ClusterId', tn?.clusterId)
                                    localStorage.setItem('UnitId', tn?.unitId)
                                    localStorage.setItem('newTenancyId', tn?._id)
                                  } else {
                                    localStorage.setItem('tenancyContract', tn?.contractNo)
                                    navigate(`/tenancy-view/2/${tn?.unitId}/${tn?._id}`)
                                    localStorage.setItem('isDraft', '2')
                                    localStorage.setItem('BuildingId', tn?.buildingId)
                                    localStorage.setItem('CommunityId', tn?.communityId)
                                    localStorage.setItem('ClusterId', tn?.clusterId)
                                    localStorage.setItem('UnitId', tn?.unitId)
                                    localStorage.setItem('newTenancyId', tn?._id)
                                  }
                                }}
                                className='cursor-pointer'
                              >
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start'
                                >
                                  {tn?.contractNo}
                                </td>
                                <td className='text-start'>
                                  {' '}
                                  {/* <p
                                    className={
                                      tn?.tenancyStatus == 0
                                        ? 'booked-color my-3'
                                        : tn?.tenancyStatus == 1
                                        ? 'active-color my-3'
                                        : tn?.tenancyStatus === 2
                                        ? 'expiring-color my-3'
                                        : tn?.tenancyStatus === 3
                                        ? 'ended-color my-3'
                                        : tn?.tenancyStatus === 4
                                        ? 'renewed-color my-3'
                                        : tn?.tenancyStatus === 5
                                        ? 'terminated-color my-3'
                                        : tn?.tenancyStatus === 6
                                        ? 'pending-color my-3'
                                        : ''
                                    }
                                  >
                                    {' '}
                                    <b className='p-5'>
                                      {tn?.tenancyStatus === 0
                                        ? 'Booked'
                                        : tn?.tenancyStatus === 1
                                        ? 'Active'
                                        : tn?.tenancyStatus === 2
                                        ? 'Expiring'
                                        : tn?.tenancyStatus === 3
                                        ? 'Ended'
                                        : tn?.tenancyStatus === 4
                                        ? 'Renewed'
                                        : tn?.tenancyStatus === 5
                                        ? 'Terminated'
                                        : tn?.tenancyStatus === 6
                                        ? 'Tenant pending approval'
                                        : '-'}
                                    </b>
                                  </p> */}
                                  <div
                                    className={
                                      tn?.tenancyStatus == 0
                                        ? 'status booked-bar me-5'
                                        : tn?.tenancyStatus == 1
                                        ? 'status active-bar me-5'
                                        : tn?.tenancyStatus == 2
                                        ? 'status expiring-bar me-5'
                                        : tn?.tenancyStatus == 3
                                        ? 'status expired-bar me-5'
                                        : 'status  no-st-bar me-5'
                                    }
                                  >
                                    {tn?.tenancyStatus == 0
                                      ? 'Booked'
                                      : tn?.tenancyStatus == 1
                                      ? 'Active'
                                      : tn?.tenancyStatus == 2
                                      ? 'Expiring'
                                      : tn?.tenancyStatus == 3
                                      ? 'Expired'
                                      : '-'}
                                  </div>
                                </td>
                                <td className='text-start' data-order='Denied'>
                                  {tn?.tenant[0]?.tenantSource == 0 ? 'Agent' : 'Direct'}
                                </td>
                                <td className='text-start'>
                                  {tn?.tenant[0]?.tenantType == 0 ? 'Main Tenant' : 'Sub Tenant'}
                                </td>
                                <td className='text-start' data-order='2022-09-09'>
                                  {tn?.duration?.days}
                                </td>
                                <td className='text-start' data-order='2022-09-11'>
                                  {tn?.duration?.start_date
                                    ? moment(tn?.duration?.start_date).format('YYYY.MM.DD')
                                    : '-'}
                                  {' - '}{' '}
                                  {tn?.duration?.end_date
                                    ? moment(tn?.duration?.end_date).format('YYYY.MM.DD')
                                    : '-'}
                                </td>
                                <td className='text-start' data-order='2022-09-11'>
                                  <span className='svg-icon svg-icon-2' style={{color: '#ffad0f'}}>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                        fill='currentColor'
                                      ></path>
                                    </svg>
                                  </span>{' '}
                                  {tn?.rating}
                                </td>
                                <td className='text-start'>
                                  {/* {tn?.isDraft == true ? 'Draft' : 'Created'} */}
                                  {tn?.isCancel ? (
                                    <>
                                      <div className='status cancelled-st me-5'>Cancelled</div>
                                    </>
                                  ) : (
                                    <div
                                      className={
                                        tn?.isDraft
                                          ? 'status draft-st me-5'
                                          : 'status create-st me-5'
                                      }
                                    >
                                      {tn?.isDraft ? 'Draft' : 'Created'}
                                    </div>
                                  )}
                                </td>

                                {/* <td className='text-start'>
                                  <div className='menu-item px-3'>
                                    <div
                                      onClick={() => {
                                        localStorage.setItem('tenancyContract', tn?.contractNo)

                                        if (tn?.isDraft) {
                                          navigate(`/create-tenant/2/${tn?._id}/${tn?._id}`)
                                          localStorage.setItem('UnitId', tenancy?.unitId)
                                        } else {
                                          setTimeout(() => {
                                            getTenancyByID(tn?._id)
                                          }, 500)
                                        }

                                        // navigate(`/overViewData/${v?._id}`, {
                                        //   state: {development: 'building', details: v, unitId: `${v?._id}`},
                                        // })
                                        // localStorage.setItem('page', 'building-dashboard')
                                      }}
                                      className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    >
                                      View
                                    </div>
                                  </div>
                                </td> */}
                              </tr>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={15} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end mt-1'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick1}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showTerm && (
        <TerminateTenancy
          show={showTerm}
          handleClose={() => setShowTerm(false)}
          id={tenancy?._id}
          startDate={tenancy?.duration?.start_date}
          endDate={tenancy?.duration?.end_date}
          fetchData={() => getTenancy()}
          unitId={tenancy?.unitId}
        />
      )}

      <Modal show={showImageName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUploadFile?.files.map((el: any, i: any) => (
            <>
              <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                Input File Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                onChange={(e: any) => uploadImageName(e, el, i)}
              />
              {fileFound ? (
                <p className='mt-2' style={{color: 'red'}}>
                  Filename Exist already
                </p>
              ) : null}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={saveImageFileName}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={modalBtnIsLoading}
          >
            Save {''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Tenancy

import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import trashImg from '../../../../img/trash.png'
import uploadFileIcon from '../../../../img/upload-file-icon.png'
import ModalLoader from '../../General/ModalLoader'
import {getFileIcon} from '../../../../Utilities/utils'

interface ButtonProps {
  show?: any
  handleClose?: any
  updateData?: any
  expData?: any
  mode?: any
  selectedItem?: any
  updateItems?: any
}

const RecordAPayment = ({
  show,
  handleClose,
  updateData,
  expData,
  mode,
  selectedItem,
  updateItems,
}: ButtonProps) => {
  const {id} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [goToNext, setGoToNext] = useState<any>(false)

  const [checkedValues, setCheckedValues] = useState<any>([])

  let totalSelected: any = 0

  let totalAmountPaid: any = 0

  const [recordItems, setRecordItems] = useState<any>(expData?.items)
  // console.log('********* Record a Payment ************')
  // console.log(expData)

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  const [paymentRecord, setPaymentRecord] = useState<any>({
    items: [],
    datePaid: null,
    totalAmountPaid: '',
    receipts: [
      {
        fileName: '',
        fileURL: '',
      },
    ],
    attachments: [
      {
        fileName: '',
        fileURL: '',
      },
    ],
    notes: '',
  })

  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...paymentRecord}
      values.datePaid = date.format('YYYY-MM-DD')
      setPaymentRecord(values)
    } else {
      let values = {...paymentRecord}
      values.datePaid = null
      setPaymentRecord(values)
    }
  }

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [isLoading, setIsLoading] = useState<any>(false)

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  //
  const handleUploadReceipt = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesReceipt = [...values.receipts]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (valuesReceipt.some((receipt) => receipt.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            valuesReceipt[0] = {
              fileURL: res?.data?.data?.image,
              fileName: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            }

            // update the upload progress
            setUploadProgress(i + 1)

            // console.log(values);
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }

      values.receipts = valuesReceipt

      setPaymentRecord(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  //
  const handleUploadAttchment = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesAttachments = [...values.attachments]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (
          valuesAttachments.some((attachment) => attachment.name === `${uniqueName}.${extension}`)
        ) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')

            const fileName = name[name.length - 1]

            valuesAttachments[0] = {
              fileURL: res?.data?.data?.image,
              fileName: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            }

            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      values.attachments = valuesAttachments

      setPaymentRecord(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  //
  const handleImageClick = (val: any, valSrc: any, type: any) => {
    const groupName = `group_${type}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', valSrc)
    }

    // Add data-fancybox attribute to the related receipts
    if (type == 'receipts') {
      paymentRecord?.receipts?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedReceipt${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    } else {
      paymentRecord?.attachments?.map((attch: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedAttachment${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${attch?.URL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    }

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  useEffect(() => {
    let values = [...checkedValues]

    expData?.items?.map((itm: any, ind: any) => {
      values[ind] = false
    })
    setCheckedValues(values)
    setRecordItems(expData?.items)
  }, [expData])

  const [isAmountExceeded, setIsAmountExceeded] = useState<any>(false)

  useEffect(() => {
    let isExceeded: any = false
    expData?.items?.map((itm: any, ind: any) => {
      if (itm?.amountPaid > itm?.outstandingAmount) isExceeded = true
    })

    setIsAmountExceeded(isExceeded)
  }, [recordItems])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '350px',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='d-flex align-items-top'>
            <h2 className=' pt-5 mb-0'>
              <b>Record a Payment</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          {!goToNext ? (
            <div className='d-flex align-items-center'>
              <h4 className=' pt-2 mb-0'>
                Select the payment items you wish to record a payment for:
              </h4>

              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-150 ms-auto'
                onClick={(e: any) => {
                  let values: any = [...recordItems]
                  recordItems?.map((rc: any, ind: any) => {
                    values[ind].amountPaid = 0
                  })
                  setRecordItems(values)
                  setGoToNext(true)
                }}
                disabled={totalSelected == 0}
              >
                Next
              </button>
            </div>
          ) : (
            <div className='d-flex align-items-center mt-2'>
              <div className='d-block'>
                <h4
                  className=' pt-2 mb-0 cursor-pointer'
                  onClick={() => {
                    setGoToNext(false)
                  }}
                >
                  {'< Back'}
                </h4>
                <h4 className=' pt-2 mb-0'>
                  {'Enter the details of your payment for the selected expense items:'}
                </h4>
              </div>

              {recordItems?.map((itm: any, ind: any) => {
                totalAmountPaid = Number(totalAmountPaid) + Number(itm?.amountPaid)
                return <></>
              })}

              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-150 ms-auto'
                onClick={(e: any) => {
                  let itms: any = []
                  let itmInd: any = 0
                  recordItems?.map((rt: any, ind: any) => {
                    if (checkedValues[ind]) {
                      itms[itmInd] = rt
                      itmInd++
                    }

                    return <></>
                  })

                  let values = {...paymentRecord}
                  values.items = itms
                  values.totalAmountPaid = totalAmountPaid

                  let valuesItems = [...expData?.items]

                  // console.log(expData?.items)
                  expData?.items?.map((itm: any, ind: any) => {
                    // valuesItems[ind].totalAmountPaid = valuesItems[ind].totalAmountPaid +  Number(valuesItems[ind].amountPaid)
                    let x: any = Number(valuesItems[ind].amountPaid)
                    valuesItems[ind].totalAmountPaid = Number(valuesItems[ind].amountPaid)

                    valuesItems[ind].amountPaid = 0
                    valuesItems[ind].outstandingAmount =
                      Number(valuesItems[ind].outstandingAmount) -
                      Number(valuesItems[ind].totalAmountPaid)

                    valuesItems[ind].paymentStatus =
                      valuesItems[ind].outstandingAmount == 0
                        ? 1
                        : x > 0 && valuesItems[ind].outstandingAmount > 0
                        ? 2
                        : 0
                  })
                  updateData(values, recordItems)
                  updateItems(valuesItems)
                }}
                disabled={
                  (totalAmountPaid == 0 || 
                  paymentRecord?.datePaid == null || 
                  paymentRecord?.receipts?.[0]?.fileURL?.trim()?.length == 0 || 
                  paymentRecord?.receipts?.[0]?.fileName?.trim()?.length == 0)
                  ? true
                  : false
                }
              >
                Submit
              </button>
            </div>
          )}

          <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
            <table className='table align-start  fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                  {!goToNext && (
                    <th className='text-start min-w-25px ps-0'>
                      {recordItems?.length > 0 && (
                        <>
                          <td className='text-start ps-0 test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={
                                  totalSelected == recordItems?.length && recordItems?.length != 0
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  let values: any = []
                                  if (e.target.checked) {
                                    for (let i = 0; i < checkedValues?.length; i++) {
                                      values[i] = true
                                    }
                                  } else {
                                    for (let i = 0; i < checkedValues?.length; i++) {
                                      values[i] = false
                                    }
                                  }

                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </td>
                        </>
                      )}
                    </th>
                  )}

                  <th className='text-start min-w-150px ps-0'>Item</th>
                  <th className='text-start min-w-150px ps-0'>Amount</th>
                  <th className='text-start min-w-75px ps-0'>QTY </th>
                  <th className='text-start min-w-75px ps-0'>VAT </th>
                  <th className='text-start min-w-100px ps-0'>Total Amount </th>
                  {!goToNext && <th className='text-start min-w-100px ps-0'>Paid </th>}
                  <th className='text-start min-w-100px ps-0'>Outstanding </th>

                  {goToNext && <th className='text-start min-w-150px ps-0'>Amount Paid </th>}
                </tr>
              </thead>

              <tbody className='fw-semibold'>
                {recordItems?.map((itm: any, ind: any) => {
                  let totalAmountOfItem: any = 0
                  let vatAmnt: any =
                    ((Number(itm?.amount) * Number(itm?.quantity)) / 100) *
                    Number(itm?.VATPercentage)
                  totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

                  return (
                    <>
                      {((checkedValues[ind] && goToNext) || !goToNext) && (
                        <tr className=''>
                          {!goToNext && (
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0 me-3 mt-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                          )}
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>{itm?.name ? itm?.name : '-'}</div>
                          </td>
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>
                              {itm?.amount
                                ? `AED ${itm?.amount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : 'AED -'}
                            </div>
                          </td>
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>{itm?.quantity ? itm?.quantity : '-'}</div>
                          </td>
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>
                              {itm.VATPercentage ? `${itm.VATPercentage}%` : '-'}
                              {/* {itm?.frontendStorage?.isLatePaymentItem ? (
                              <>{(itm.amount / 100) * itm.VATPercentage}</>
                            ) : (
                              <>{((itm.amount * itm.quantity) / 100) * itm.VATPercentage}</>
                            )} */}
                            </div>
                          </td>
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>
                              {'AED '}
                              <b>
                                {totalAmountOfItem
                                  ? ` ${totalAmountOfItem?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : ' -'}
                              </b>
                            </div>
                          </td>
                          {!goToNext && (
                            <td className='text-start  ps-0'>
                              <div className='pt-2'>
                                {itm?.amountPaid >= 0
                                  ? `AED ${itm?.amountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : 'AED -'}
                              </div>
                            </td>
                          )}
                          <td className='text-start  ps-0'>
                            <div className='pt-2'>
                              {'AED '}
                              <b>
                                {itm?.outstandingAmount <= totalAmountOfItem
                                  ? ` ${itm?.outstandingAmount?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : itm?.outstandingAmount > totalAmountOfItem
                                  ? ` ${totalAmountOfItem?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : ' -'}
                              </b>
                            </div>
                          </td>

                          {goToNext && (
                            <td className='text-start  ps-0'>
                              <input
                                //   {...register('documentName', {required: true})}
                                type='number'
                                className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                  itm?.amountPaid > itm?.outstandingAmount ? 'border-danger' : ''
                                }`}
                                name='documentName'
                                value={itm?.amountPaid}
                                style={{height: '35px'}}
                                placeholder={'AED'}
                                onChange={(e: any) => {
                                  const newValue = e.target.value.replace(/^0+/, '')
                                  let values = [...recordItems]
                                  values[ind].amountPaid = newValue != '' ? newValue : ''

                                  setRecordItems(values)
                                }}
                                autoComplete='off'
                              />
                              {/* {itm?.amountPaid > itm?.outstandingAmount && (
                                <p
                                  className='text-danger mb-0 ms-0'
                                  style={{maxWidth: '200px', wordBreak: 'break-word'}}
                                >
                                  {' '}
                                  Amount paid cannot exceed the outstanding amount of your expense
                                  item. Edit the total amount of your expense item to be able to
                                  enter a greater amount paid.
                                </p>
                              )} */}
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
          {goToNext && (
            <>
              <div className='separator my-5 m'></div>

              <div className='d-flex align-items-center mt-10'>
                <div className='d-block'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry '>
                      {' '}
                      Date Paid
                    </label>
                  </h4>
                  <div className='expense-date d-flex'>
                    {paymentRecord?.datePaid != null ? (
                      <DatePicker
                        name='deliveryDate'
                        defaultValue={moment(paymentRecord?.datePaid, 'YYYY-MM-DD')}
                        disabledDate={disabledDate}
                        onChange={handleDateChange}
                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                        placeholder='Select Payment Date'
                      />
                    ) : (
                      <DatePicker
                        name='deliveryDate'
                        disabledDate={disabledDate}
                        onChange={handleDateChange}
                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                        placeholder='Select Payment Date'
                      />
                    )}
                     <h3 className='text-danger mb-0 ms-2'>
                      <b>*</b>
                    </h3>
                </div>
                  </div>
                 
                <div className='d-block ms-auto text-end'>
                  <h2 className=' pt-2 mb-0 ms-auto'>
                    <b> Total Amount Paid</b>
                  </h2>
                  <h2 className=' pt-2 mb-0 '>
                    <b>
                      {totalAmountPaid > 0
                        ? `AED ${totalAmountPaid?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}`
                        : 'AED -'}
                    </b>
                  </h2>

                  <h3 className=' pt-2 mb-0'></h3>
                </div>
              </div>

              <div className='row mx-0 d-flex mt-10 payment-record'>
                <div
                  className='col-12 col-md-6 ps-0 '
                  style={{display: 'flex', flexDirection: 'column'}}
                >
                  <div className='card p-5 px-9 h-100' style={{}}>
                    <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                      <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                        Receipt
                      </h2>{' '}
                    </div>
                    <div className='d-flex justify-content-center xyz my-2 mt-0'>
                      <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center  mb-0'>
                        Add the receipt details for your payment:
                      </p>{' '}
                    </div>
                    <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                      <input
                        //   {...register('documentName', {required: true})}
                        type='text'
                        className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                        name='documentName'
                        value={paymentRecord?.receipts[0]?.fileName}
                        style={{height: '35px', width: '300px'}}
                        placeholder={'Receipt No.'}
                        onChange={(e: any) => {
                          let values = {...paymentRecord}
                          values.receipts[0].fileName = e.target.value
                          setPaymentRecord(values)
                        }}
                      />
                      <h3 className='text-danger mb-0 ms-2'>
                        <b>*</b>
                      </h3>
                    </div>

                    <div className='mt-5 '>
                      {paymentRecord?.receipts[0]?.fileURL?.trim()?.length == 0 ? (
                        <div className='d-flex align-items-start justify-content-center'>
                          <form
                            id='form-file-upload-po'
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                            style={{height: '110px'}}
                          >
                            <label
                              id='form-file-upload-po'
                              htmlFor='form-file-upload-po-receipt'
                              className={
                                dragActive
                                  ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                              }
                              style={{
                                fontSize: '16px',
                                color: 'white',
                              }}
                              ref={inputRef}
                            >
                              <div
                                className={`d-flex justify-content-center px-8 align-items-center ${
                                  isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                                }`}
                              >
                                <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                  style={{minWidth: '90px'}}
                                >
                                  <b>Drag & drop your file </b>{' '}
                                  <span className='mx-1 ps-1'>or </span>
                                </div>
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                  style={{minWidth: '90px'}}
                                >
                                  <div
                                    className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                    style={{}}
                                  >
                                    Browse Files
                                  </div>
                                </div>
                              </div>
                            </label>
                            <input
                              type='file'
                              // multiple={true}
                              onChange={(e: any) => {
                                handleUploadReceipt(e)
                              }}
                              id='form-file-upload-po-receipt'
                              ref={inputRef}
                              className='file-input-drag cursor-pointer'
                              // accept='application/pdf'
                            />
                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                          <h3 className='text-danger mb-0 ms-2'>
                            <b>*</b>
                          </h3>
                        </div>
                      ) : (
                        <div className='d-flex justify-content-center'>
                          <img
                            src={
                              paymentRecord?.receipts[0]?.fileURL?.split('.').pop() === 'jpeg' ||
                              paymentRecord?.receipts[0]?.fileURL?.split('.').pop() === 'jpg' ||
                              paymentRecord?.receipts[0]?.fileURL?.split('.').pop() === 'png' ||
                              paymentRecord?.receipts[0]?.fileURL?.split('.').pop() === 'svg' ||
                              paymentRecord?.receipts[0]?.fileURL?.split('.').pop() === 'gif'
                                ? `${Bucket}${paymentRecord?.receipts[0]?.fileURL}`
                                : getFileIcon(paymentRecord?.receipts[0]?.fileURL)
                            }
                            height={150}
                            width={120}
                            className='shadow object-fit-contain'
                          />
                          <img
                            src={trashImg}
                            height={16}
                            width={16}
                            onClick={() => {
                              let values = {...paymentRecord}
                              values.receipts[0].fileURL = ''
                              values.receipts[0].fileName = ''
                              setPaymentRecord(values)
                            }}
                            className='ms-2 cursor-pointer'
                          />{' '}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className='col-12 col-md-6 pe-0'
                  style={{display: 'flex', flexDirection: 'column'}}
                >
                  <div className='card p-5 px-9 h-100'>
                    <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                      <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                        Other Attachments
                      </h2>{' '}
                    </div>

                    <div className='d-flex justify-content-center xyz my-2 mt-0'>
                      <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center mb-0'>
                        Attach any other document related to your payment
                      </p>{' '}
                    </div>

                    <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                      <input
                        //   {...register('documentName', {required: true})}
                        type='text'
                        className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                        name='documentName'
                        value={paymentRecord?.attachments?.[0]?.fileName}
                        style={{height: '35px', width: '300px'}}
                        placeholder={'Attachment Name'}
                        onChange={(e: any) => {
                          let values = {...paymentRecord}
                          values.attachments[0].fileName = e.target.value
                          setPaymentRecord(values)
                        }}
                      />
                    </div>

                    <div className='mt-5'>
                      {paymentRecord?.attachments[0]?.fileURL?.trim()?.length == 0 ? (
                        <div className='d-flex align-items-start justify-content-center'>
                          <form
                            id='form-file-upload-po'
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                            style={{height: '110px'}}
                          >
                            <label
                              id='form-file-upload-po'
                              htmlFor='form-file-upload-poo'
                              className={
                                dragActive
                                  ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                              }
                              style={{
                                fontSize: '16px',
                                color: 'white',
                              }}
                              ref={inputRef}
                            >
                              <div
                                className={`d-flex justify-content-center px-8 align-items-center ${
                                  isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                                }`}
                              >
                                <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                  style={{minWidth: '90px'}}
                                >
                                  <b>Drag & drop your file </b>{' '}
                                  <span className='mx-1 ps-1'>or </span>
                                </div>
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                  style={{minWidth: '90px'}}
                                >
                                  <div
                                    className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                    style={{}}
                                  >
                                    Browse Files
                                  </div>
                                </div>
                              </div>
                            </label>
                            <input
                              type='file'
                              multiple={true}
                              onChange={(e: any) => {
                                handleUploadAttchment(e)
                              }}
                              id='form-file-upload-po'
                              ref={inputRef}
                              className='file-input-drag cursor-pointer'
                              // accept='application/pdf'
                            />
                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </div>
                      ) : (
                        <div className='d-flex  justify-content-center'>
                          <img
                            src={
                              paymentRecord?.attachments[0]?.fileURL?.split('.').pop() === 'jpeg' ||
                              paymentRecord?.attachments[0]?.fileURL?.split('.').pop() === 'jpg' ||
                              paymentRecord?.attachments[0]?.fileURL?.split('.').pop() === 'png' ||
                              paymentRecord?.attachments[0]?.fileURL?.split('.').pop() === 'svg' ||
                              paymentRecord?.attachments[0]?.fileURL?.split('.').pop() === 'gif'
                                ? `${Bucket}${paymentRecord?.attachments[0]?.fileURL}`
                                : getFileIcon(paymentRecord?.attachments[0]?.fileURL)
                            }
                            height={150}
                            width={120}
                            className='shadow object-fit-contain'
                          />
                          <img
                            src={trashImg}
                            height={16}
                            width={16}
                            onClick={() => {
                              let values = {...paymentRecord}
                              values.attachments[0].fileURL = ''
                              values.attachments[0].fileName = ''
                              setPaymentRecord(values)
                            }}
                            className='ms-2 cursor-pointer'
                          />{' '}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mx-0 mt-10'>
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-3 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Notes
                    </h2>{' '}
                  </div>
                  <textarea
                    className='form-control form-control-solid mytest bg-white'
                    placeholder='Enter notes related to this payment'
                    name='message'
                    style={{minHeight: '100px'}}
                    value={paymentRecord?.notes}
                    onChange={(e: any) => {
                      let values = {...paymentRecord}
                      let newValue: any =
                        e.target.value != ''
                          ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                          : e.target.value
                      values.notes = newValue
                      setPaymentRecord(values)
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>

      {/* <SingleFileLoader isVisible={showLoader} /> */}
      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default RecordAPayment

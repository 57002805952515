import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import toast from "react-hot-toast";
import submitImg from '../../../../../../../img/Submit-White.png'
import backArrow from '../../../../../../../img/back-arrow.png'
import trashImg from '../../../../../../../img/trash.png'
import saveGreenImg from '../../../../../../../img/save-dark-green.png'
import removeIcon from '../../../../../../../img/remove.png'
import refreshIcon from '../../../../../../../img/refreshIcon.png'
import NoteDetailsCard from '../components/NoteDetailsCard'
import {ApiGet, ApiPost, ApiDelete} from '../../../../../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../../../apiCommon/helpers/Toast'
import ConfirmationPopup from '../../ConfirmationPopup'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

enum ActionType {
    CreateAndShare = 'CS',
    Create = 'CR',
    UpdateAndShare = 'US',
    Update = 'UP',
    None = '-',
  }

const EditDebitNote = () => {
  const navigate = useNavigate()
  const {flg, id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [creditNoteData, setCreditNoteData] = useState<any>({
    name: 'DEBIT NOTE',
    dueDate:null,
    debitNoteNo: '',
    note: '',
    payorData: {},
    payment_sub_item: [],
    // items: [],
    debitNoteCompany: {},
    debitNoteLogo: null,
    showNotes: true,
    itemDescriptions: [],
    frontendStorage: {
      noteShowHide: false,
    },
  })


  const [askForConfirmation, setAskForConfirmation] = useState<boolean>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [existingInvoice, setExistingInvoice] = useState<any>(null)
  const [open, setOpen] = React.useState(false)
  const [isShare, setIsShare] = useState<any>(true)

 
  const getDebitNoteById = async () => {
    let url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then((res: any) => {
        setValue('documentName', res?.data?.data?.debitNoteTemplateHeader)
        setValue('documentReceiptNo', res?.data?.data?.debitNoteNo)
        setValue('dueDate', res?.data?.data?.dueDate);

        let paymentItemsTemp: any = []
        res?.data?.data?.paymentSubItems?.forEach((pyItem: any, pyInd: number) => {
          paymentItemsTemp[pyInd] = {
            ...pyItem,
            outstandingAmount: pyItem?.totalAmount,
            amountPaid: pyItem?.amountPaid,
            addedDebit: {
              debitRateAmount: pyItem?.amount,
              quantity: pyItem?.quantity,
              debitAmount: pyItem?.amount,
              debitVATAmount: pyItem?.VATPercentage,
              totalDebitAmount: pyItem?.totalAmount,
            },
          }
        })

        const values: any = {
          name: res?.data?.data?.debitNoteTemplateHeader,
          debitNoteNo: res?.data?.data?.debitNoteNo,
          note: res?.data?.data?.note,
          attachments: res?.data?.data?.otherAttachment,
          dueDate: res?.data?.data?.dueDate,
          payorData:
            res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant?.[0]
              : res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.length > 0
              ? res?.data?.data?.invoiceRecords?.[0]?.payment_invoice?.tenant?.[0]
              : {},
          datePaid: res?.data?.data?.datePaid,
          referenceNo: res?.data?.data?.referenceNo,
          debitNoteCompany: res?.data?.data?.debitNoteCompany,
          debitNoteLogo: res?.data?.data?.debitNoteLogo,
          payment_sub_item: paymentItemsTemp,
          itemDescriptions: res?.data?.data?.itemDescriptions ? res?.data?.data?.itemDescriptions : [],
          formStatusDateTime: res?.data?.data?.createdAt,
          formStatus: res?.data?.data?.formStatus,
          showNotes: res?.data?.data?.frontendStorage?.noteShowHide,
          createdAt: res?.data?.data?.createdAt,
          pdfURL:res?.data?.data?.pdfURL
        }


        setCreditNoteData(values)
        console.log(res?.data?.data?.invoiceRecords)
        let invValues: any = []
        res?.data?.data?.invoiceRecords?.map((inv: any, index: number) => {
          const {tenant,...rest} = inv?.payment_invoice
          invValues?.push(rest)
          console.log(inv?.payment_invoice)

          // invValues[index].payment_account = inv?.payment_account
          invValues[index].paymentSubItems = inv?.paymentSubItems
          invValues[index].tenant =
            (tenant !== undefined && tenant !== null)
              ? [tenant]
              : res?.data?.data?.tenant?.length > 0
              ? res?.data?.data?.tenant
              : []
          invValues[index].paymentId = inv?.paymentId
          invValues[index].building = inv?.payment_invoice?.building !== undefined && inv?.payment_invoice?.building !== null  ? [inv?.payment_invoice?.building] : []
          invValues[index].floor = inv?.payment_invoice?.floor !== undefined && inv?.payment_invoice?.floor !== null ? [inv?.payment_invoice?.floor] : []
          invValues[index].community = inv?.payment_invoice?.community !== undefined && inv?.payment_invoice?.community !== null ? [inv?.payment_invoice?.community] :[]
          invValues[index].unit = inv?.payment_invoice?.unit !== undefined && inv?.payment_invoice?.unit !== null ? [inv?.payment_invoice?.unit] : []
          invValues[index].tenant = inv?.payment_invoice?.tenant !== undefined && inv?.payment_invoice?.tenant !== null ? [inv?.payment_invoice?.tenant] : []
          invValues[index].tenancy = inv?.payment_invoice?.tenancy !== undefined && inv?.payment_invoice?.tenancy !== null ? [inv?.payment_invoice?.tenancy] :[]
        })


        setSelectedInvoices(invValues)
        setTimeout(() => {
            setIsSkeleton(false)
          }, 100)
      })
      .catch((err: any) => {
        console.log('err', err)
        setIsSkeleton(false)
      })
  }

  const addDebitNote = async (saveStatus: number, from: any) => {
    let isValid: any = true;
  
    // If saveStatus is 0, or valid items were processed, directly call the API
    if (saveStatus === 0 || saveStatus === 1 && isValid) {
      setIsLoading(true)
      setOpen(true)
      callApi();
    }
  
    // Function to call the API
    function callApi() {
      let newItems: any = [];
  
      let invoiceRecords: any = [];
      selectedInvoices?.map((sldInv: any, ind: number) => {
        invoiceRecords[ind] = {
          invoiceId: sldInv?._id,
          paymentId: sldInv?.paymentId,
          paymentAccountId: sldInv?.payment_account?.[0]?._id,
          paymentSubItems: sldInv?.paymentSubItems?.map((paymentItem: any, pyInd: any) => {
            return {
              _id: paymentItem?._id,
              name: paymentItem?.name,
              paymentItemListId: paymentItem?.paymentItemListId,
              amount: paymentItem?.amount === null ? 0 : paymentItem?.amount,
              quantity: paymentItem?.quantity === null ? 0 : paymentItem?.quantity,
              totalAmount: paymentItem?.totalAmount === null ? 0 : paymentItem?.totalAmount,
              amountPaid: paymentItem?.amountPaid === null ? 0 : paymentItem?.amountPaid,
              outstandingAmount: paymentItem?.outstandingAmount,
              VATAmount: paymentItem?.VATAmount === null ? 0 : paymentItem?.VATAmount,
              VATPercentage: paymentItem?.VATPercentage === null ? 0 : paymentItem?.VATPercentage,
              totalDebitAmount: paymentItem?.totalDebitAmount === null ? 0 : paymentItem?.totalDebitAmount,
              isSelected: paymentItem?.isSelected,
              addedDebit: {
                debitAmount: Number(paymentItem?.addedDebit?.debitRateAmount),
                debitRateAmount: Number(paymentItem?.addedDebit?.debitRateAmount),
                quantity: Number(paymentItem?.addedDebit?.quantity),
                debitVATAmount: Number(paymentItem?.addedDebit?.debitVATAmount),
                totalDebitAmount: Number(paymentItem?.addedDebit?.totalDebitAmount),
              },
            };
          }),
        };
      });
  
      newItems = [...creditNoteData?.payment_sub_item]?.map((itm: any, ind: any) => {
        let { addedDebit, ...rest } = itm;
        return {
          ...rest,
          quantity:  Number(addedDebit?.quantity),
          amount: Number(addedDebit?.debitRateAmount),
          VATPercentage: Number(addedDebit?.debitVATAmount) ,
          totalAmount: Number(addedDebit?.totalDebitAmount),
          // outstandingAmount: flg === '1' ? addedCredit?.totalCreditAmount : addedCredit?.totalCreditAmount - itm?.amountPaid,
          // VATAmount: 0,
          // amountPaid: flg === '1' ? 0 : itm?.amountPaid
        };
      });
  
      let subTotalAmount = 0;
      let totalVAT = 0;
      let nonVATAmount = 0;
  
      if (creditNoteData?.payment_sub_item?.length > 0) {
        creditNoteData?.payment_sub_item?.map((f: any, ind: any) => {
          if (f?.frontendStorage?.isLatePaymentItem) {
            totalVAT = totalVAT + (Number(f?.addedDebit?.debitRateAmount) / 100) * Number(f?.addedDebit?.quantity);
            nonVATAmount = nonVATAmount + f.amount;
          } else {
            totalVAT =
              totalVAT +
              ((Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity)) / 100) *
                Number(f?.addedDebit?.debitVATAmount);
            nonVATAmount =
              nonVATAmount + Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity);
          }
        });
      }
  
      const body = {
        _id: flg == '1' ? undefined : id,
        note: creditNoteData?.note ? creditNoteData?.note : '',
        tenantId: creditNoteData?.payorData?._id,
        datePaid: '',
        dueDate: creditNoteData?.dueDate ? creditNoteData?.dueDate : null,
        referenceNo: creditNoteData?.debitNoteNo,
        debitNoteNo: creditNoteData?.debitNoteNo,
        debitNoteTemplateHeader: creditNoteData?.name,
        formStatus: saveStatus,
        // creditCategory: existingInvoice,
        currency: 'AED',
        subTotalAmount: Number(nonVATAmount),
        totalVAT: Number(totalVAT),
        totalAmount: Number(totalVAT) + Number(nonVATAmount),
        invoiceRecords: invoiceRecords?.length > 0 ? invoiceRecords : [],
        paymentSubItems: newItems?.length > 0 ? newItems : [],
        itemDescriptions: creditNoteData?.itemDescriptions,
        frontendStorage: {
          noteShowHide : creditNoteData?.showNotes
        }
      };
  
      ApiPost(`corporate/payment_debit_note/add_update`, body)
        .then((res) => {
          // Handle success
          if (saveStatus == 0 ) {
            if (flg == '1') {
              navigate(`/finance-income/issue-debit-note/2/${res?.data?.data?._id}`)
              setTimeout(() => {
                setIsSaveFirst(true)
              }, 1000)
            }else {
              getDebitNoteById()
            }
            setTimeout(() => {
              setIsLoading(false)
              setOpen(false)
            }, 1500)
          }else if(saveStatus == 1 && from  === ActionType.Update){
            setAskForConfirmation(false)
            SuccessToast('Debit Note has been updated successfully..')
            navigate(`/finance-income/view-debit-note/${id}`)
          }else if(saveStatus == 1 && from === ActionType.UpdateAndShare){
            setAskForConfirmation(false)
            navigate(`/finance-income/share/issue-debit-note/${id}`)
          }else{
            setAskForConfirmation(false)
            SuccessToast('Debit Note has been updated successfully..')
            navigate(`/finance-income/view-debit-note/${id}`)
          }
        })
        .catch((err: any) => {
          ErrorToast(err?.message);
          setTimeout(() => {
            setIsLoading(false)
            setOpen(false)
          }, 1500)
        });
    }
  }

  //
  useEffect(() => {
    setIsSkeleton(true)
    getDebitNoteById()
  }, [])


  const getDebitSettingDetails = async () => {
    try {
      const res = await ApiGet('corporate/corporate_setting');
      console.log(res)
      return res?.data?.data;  // Return the relevant data
    } catch (err) {
      console.log('Error in getDebitNoteDetails:', err);
      throw err;  // Re-throw the error so it can be caught where it's called
    }
  };


  return (
    <>
      {!isSkeleton ? 
      (<div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form
        >
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap mb-4'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                        navigate(`/finance-income/view-debit-note/${id}`)
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Edit Debit Note`}</h2>
                </div>

                <div className='d-flex ms-auto'>
                <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 red-hollow-btn btn-fit-content ps-3'
                    // disabled={isLoading}
                    onClick={() => {
                      navigate(`/finance-income/view-debit-note/${id}`)
                    }}
                  >
                    <img src={removeIcon} height={18} width={18} className='me-4' />{' '}
                    Cancel
                  </button>

                <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3'
                    onClick={ async () => {
                        let isValid = false
                        isValid = await trigger();
  
                        if(!creditNoteData?.name?.trim()){
                          ErrorToast('Debit note name is required.');
                        }

                        const invoiceDetails = await getDebitSettingDetails();
                        if(invoiceDetails.debitNoteSequence?.activityStatus == 0 && !creditNoteData?.debitNoteNo?.trim()){
                          ErrorToast('Debit note number is required.');
                          return
                        }else if(!creditNoteData?.dueDate){
                          ErrorToast('Due date is required.');
                          return
                        }
  
                        let addSectionErr : boolean = false
                          if(creditNoteData?.itemDescriptions?.length > 0  && creditNoteData?.itemDescriptions?.some((itm:any) => {
                            const tempElement = document.createElement('div');
                            tempElement.innerHTML = itm?.html;
                            return ((!tempElement?.textContent?.trim() || !tempElement?.innerText?.trim()) || !itm?.title?.trim() || itm?.html === undefined);
                          })){
                            addSectionErr = true
                          }else{
                            addSectionErr = false
                          }
  
                          if(addSectionErr){
                            ErrorToast('User cannot create debit note with empty title or content in additional sections.')
                            return
                          }
  
                        if(isValid){
  
                          let validItems: any = [];
                          let shouldEnableButton = false;
                      
                          // Loop through selected invoices and sub-items
                          // creditNoteData?.payment_sub_item?.forEach((subItem: any) => {
                            selectedInvoices?.forEach((sldInv: any,invInd:any) => {
                              sldInv?.paymentSubItems?.forEach((paymentItem: any,pyInd:any) => {
                                const creditRateAmount = Number(paymentItem?.addedDebit?.debitRateAmount);
                                const quantity = Number(paymentItem?.addedDebit?.quantity);
                                const totalCreditAmount = Number(paymentItem?.addedDebit?.totalDebitAmount);
                                const totalAmount = Number(paymentItem?.totalAmount);
                      
                                // Check if the item is valid and not already part of the credit note
                                if (creditRateAmount > 0 && quantity > 0  && totalAmount >= 0 && !paymentItem?.isSelected) {
                                  paymentItem.isSelected = true;
                                  paymentItem.frontendStorage = {
                                   ...paymentItem.frontendStorage,
                                   invoiceDate: sldInv[invInd]?.date,
                                  }
                                  validItems.push(paymentItem);
                                  shouldEnableButton = true;
                                }
                              });
                            });
                          // });
                      
                          // Show the popup if valid items are found
                          if (shouldEnableButton) {
                            Swal.fire({
                              html: ` 
                                <div class='fs-3'> <b>Items Missing</b></div>
                                <div class='fs-5 mt-4 fw-bold'> There are some items you recorded debit details for that were not included in the Debit Note.</div>
                                <div class='fs-5 mt-4 fw-bold'> Would you like to add them before creating? </div>
                                <p class='fs-5 mt-4 text-gray-400 fw-bold'> This only applies to items that have complete and correct debit details but were not included.</p>
                                `,
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              reverseButtons: true,
                            }).then((res: any) => {
                              if (res.isConfirmed) {
                                console.log(validItems);
                                setAskForConfirmation(true)
                                toast('Items added successfully to your debit note!', {
                                  icon: <img src={refreshIcon} alt="icon" width={14} height={14}/>,
                                  style: {
                                    padding: "16px",
                                  },
                                })
  
                                const updateCreditNote = {...creditNoteData}
                                validItems?.forEach((subItem:any) => {
                                  subItem.frontendStorage = {
                                    ...subItem.frontendStorage,
                                    addedAt : new Date().getTime()
                                  }
                                })
                                updateCreditNote.payment_sub_item = [...updateCreditNote.payment_sub_item,...validItems]
                                setCreditNoteData(updateCreditNote)
  
                              } else {
                                setAskForConfirmation(true)
                                selectedInvoices?.forEach((sldInv: any) => {
                                  sldInv?.paymentSubItems?.forEach((paymentItem: any) => {
                                    if (validItems.includes(paymentItem)) {
                                      paymentItem.isSelected = false;
                                    }
                                  });
                                });
                                validItems = [];
                              }
                            });
                      
                          }else{
                            setAskForConfirmation(true)
                          }
                        }
                        
                        
                      }}
                    // disabled={isLoading || !goToNext }
                  >
                    <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                    Update
                  </button>
                </div>
              </div>
            </div>


            {/* Credit Note Details Card */}
            <NoteDetailsCard
              isPrint={false}
              register={register}
              errors={errors}
              noteCardData={creditNoteData}
              setValue={setValue}
              trigger={trigger}
              control={control}
              updateNoteData={(data: any) => {
                console.log(data)
                setCreditNoteData(data)

                if (flg == '2') {
                  setTimeout(() => {
                    setAutoSaveData(Date.now())
                  }, 700)
                }
              }}
              updateNote={(data: any) => {
                setCreditNoteData(data)
              }}
              slctdInvoices={selectedInvoices}
              editMode={true}
              from={'debit note'}
            />
          </div>
        </form>
      </div>) : ( <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>)}

        <ConfirmationPopup
        from='debit'
        show={askForConfirmation}
        isUpdate={true}
        isCreate={false}
        label={'Create'}
        type={'Debit Note'}
        payor={
          creditNoteData?.payorData?.firstName && creditNoteData?.payorData?.lastName ? `${creditNoteData?.payorData?.firstName} ${creditNoteData?.payorData?.lastName}` :'-'
        }
        typeOfNo={creditNoteData?.debitNoteNo}
        handleClose={() => {
          setAskForConfirmation(false)
        }}
        updateData={(actionType: string) => {
          console.log(actionType)

          if (actionType === ActionType.Create) {
            // run create fun
            
          } else if (actionType === ActionType.CreateAndShare) {
            // run create and share
            
          } else if (actionType === ActionType.Update) {
            // run update fun
            addDebitNote(1, actionType)
          } else if (actionType === ActionType.UpdateAndShare) {
            // run update and share
            addDebitNote(1, actionType)
          } else {
            // nothing
          }
        }}
        receiptData={[]}
        isShare={isShare}
        setIsShare={(val: any) => {
          setIsShare(val)
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default EditDebitNote

import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Modal} from 'react-bootstrap'
import {Controller, useForm} from 'react-hook-form'
import swal from 'sweetalert2'
import {DatePicker} from 'antd'
import 'antd/dist/antd.css'
import type {RangePickerProps} from 'antd/es/date-picker'
import moment from 'moment'
import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../img/other.svg'
import common_area from '../../../img/common_area.svg'
import penthouse from '../../../img/PentHouse.svg'
import plus from '../../../img/plus.svg'
import noData from '../../../img/NoData1.svg'
import IncomingPaymentModal from './IncomingPaymentModal'
import ReactPaginate from 'react-paginate'
import EditPaymentModal from '../../modules/Financials/EditPaymentModal'
import backArrow from '../../../img/back-arrow.png'
import folderDocument from '../../../img/folder-document.svg'
import submitImg from '../../../img/Submit-White.png'
import EditPaymentModalDraft from './EditPaymentModal'
import AddNewTenant from './AddNewTenantModal'
import profileImg from '../../../img/user.jpeg'
import closes from '../../../img/close.svg'
import filter from '../../../img/filter.svg'
import send from '../../../img/sendwhite.svg'
import saveGreenImg from '../../../img/save-dark-green.png'
import addFolder from '../../../img/add-fold-ehite.png'
import invoiceAdd from '../../../img/invoice-new.png'
import addWhite from '../../../img/add-white.png'
import profileGreen from '../../../img/profile-green.png'
import folderGreen from '../../../img/folder-green.png'
import chatGreen from '../../../img/chat-green.png'
import redCross from '../../../img/remove.png'
import redCrossFilled from '../../../img/remove-filled.png'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import EditTenant from './EditTenantModal'
import TenantFolders from './TenantFolderModal'
import './style.scss'
import SingleFileModal from './SingleFileModal'
import {onMessageListener} from '../../../apiCommon/helpers/firebase/firebase'
import io from 'socket.io-client'
import leftarrow from '../../../img/arrowleft.svg'
import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import download from '../../../img/upload-file-green.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import openImg from '../../../img/payment-col-open.png'
import closeImg from '../../../img/payment-col-close.png'
import Select from 'react-select'
import plusBlue from '../../../img/add-blue.png'
import trashImg from '../../../img/trash.png'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

let userData: any = localStorage.getItem('userData')
let user = JSON.parse(userData)

// const socket = io('https://api.propertise.com/room')
let socket: any = null

const TerminateTenancy = () => {
  const {flg, id, tenancyId} = useParams()
  const [open, setOpen] = React.useState(false)

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)
  const [hide, setHide] = React.useState<any>(false)
  const {state} = useLocation()
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>('Filter')
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [remainingDays, setRemainingDays] = useState('')
  const [ttlDays, setTtlDays] = useState<any>('0')

  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })

  const [unitData, setUnitData] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })

  const [tenancyData, setTenancyData] = useState<any>({})
  const [otherOptions, setOtherOptions] = useState<any>([])
  const [subTenantData, setSubTenantData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>()
  const [tenanatData, setTenantData] = useState<any>([])
  const [subTenantIds, setSubTenantIds] = useState<any>([])
  const [modalBtnIsLoading, setModalBtnIsLoading] = useState<any>(false)
  const [sendError, setSendError] = useState<any>(true)

  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)

  let isView: any = localStorage.getItem('isViewMode')
  const isViewMode = JSON.parse(isView)

  // const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const dateFormat = 'DD/MM/YYYY'

  const getUbitDataById = () => {
    ApiGet(`corporate/unit/${id}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        setSendError(false)
        setDisabledBtn(false)
      })
      .catch((err) => console.log('err', err))
  }

  const handleChnageTenancyDate = (date: any) => {
    if (date == null) {
      setEnd(null)
      setEndDatePayload(null)
    } else {
      setEnd(date.format('DD/MM/YYYY'))
      setEndDatePayload(date.format('YYYY-MM-DD'))

      setDateError(false)

      var start = moment(new Date(tenancyData.duration?.start_date))
      var end = moment(date)

      let today = moment(new Date())
      setTotalDays(Math.floor(end.diff(start, 'days', true)) + ' days')

      setTtlDays(Math.floor(end.diff(start, 'days', true)))
      setRemainingDays(Math.floor(end.diff(today, 'days', true)) + ' days')

      if (Math.floor(end.diff(start, 'days', true)) < tenancyData?.renewalDays)
        setRenewalError(true)
      else setRenewalError(false)

      setAutoSaveData(Date.now())

      const selectedDate = date.toDate()
      let x = selectedDate.toLocaleDateString('uk-UA')

      const values = [...terminatePaymentList]

      for (let i = 0; i < values?.length; i++) {
        let due = new Date(values[i]?.dueDate)
        let y = due.toLocaleDateString('uk-UA')

        // Set time component to midnight for both dates
        selectedDate.setHours(0, 0, 0, 0)
        due.setHours(0, 0, 0, 0)

        // For payments that are due BEFORE the ‘End Date’, the Outstanding Balance will pass to the ‘Collect’ field.
        if (x === y) {
          for (let j = 0; j < values[i]?.payment_sub_item?.length; j++) {
            values[i].payment_sub_item[j].terminationReduction.collect =
              values[i].payment_sub_item[j].outstandingAmount
            values[i].payment_sub_item[j].terminationReduction.cancel = 0
            values[i].terminationReduction.isUpdated = true
            values[i].payment_sub_item[j].terminationReduction.isUpdated = true
          }
        }
        //  All payments due AFTER the ‘End Date’ will automatically go in the ‘Cancelled Field’.
        else if (selectedDate < due) {
          for (let j = 0; j < values[i]?.payment_sub_item?.length; j++) {
            values[i].payment_sub_item[j].terminationReduction.cancel =
              values[i].payment_sub_item[j].totalAmount
            values[i].payment_sub_item[j].terminationReduction.collect = 0
            values[i].terminationReduction.isUpdated = true
            values[i].payment_sub_item[j].terminationReduction.isUpdated = true
          }
        }
        //  For payments that are due BEFORE the ‘End Date’, the Outstanding Balance will pass to the ‘Collect’ field.
        else if (selectedDate > due) {
          for (let j = 0; j < values[i]?.payment_sub_item?.length; j++) {
            values[i].payment_sub_item[j].terminationReduction.collect =
              values[i].payment_sub_item[j].outstandingAmount
            values[i].payment_sub_item[j].terminationReduction.cancel = 0
            values[i].terminationReduction.isUpdated = true
            values[i].payment_sub_item[j].terminationReduction.isUpdated = true
          }
        }
      }

      setIncomePaymentPayload(values)
    }
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
      width: '90%',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '31px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '31px',
      width: '90%',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',

      width: '90%',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',

      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '31px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '31px',
      width: '90%',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const [uploadOtherImgTenancy, setuploadOtherImgTenancy] = useState<any>([])
  const [uploadContractTenancy, setuploadContractTenancy] = useState<any>([])

  const [uploadOtherImgTenant, setuploadOtherImgTenant] = useState<any>([])
  const [uploadIdTenant, setuploadIdTenant] = useState<any>([])
  const [uploadresidencyTenant, setuploadresidencyTenant] = useState<any>([])
  const [uploadPassportTenant, setuploadPassportTenant] = useState<any>([])
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [fileFound, setFileFound] = useState<any>(false)
  const [newTenancyID, setNewTenancyID] = useState<any>(null)
  const [newTenantId, setNewTenantID] = useState<any>('')
  const [tenantDocModal, setTenantDocModal] = useState<any>(false)

  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [editIncomeModalDraft, setEditIncomeModalDraft] = useState<any>(false)
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [indexToEdit, setIndexToEdit] = useState<any>()

  const [addNewTenant, setAddNewTenant] = useState<any>(false)
  const [editTenantModal, setEditTenantModal] = useState<any>(false)
  const [tenantIdToView, setTenantIdToView] = useState<any>()
  const [tenantIdForFolder, setTenantIdForFolder] = useState<any>()

  const [tenantFolderModal, setTenantFolderModal] = useState<any>(false)
  const [tenantFileModal, setTenantFileModal] = useState<any>(false)
  const [targetFolder, setTargetFolder] = useState<any>()

  const getAllPayments = () => {
    const body = {
      limit: limit,
      page: page,
      tenancyId: tenancyId,
      includePaymentSubItem: true,
    }

    ApiPost(`corporate/payment/get/tenancy?isCloneRecord=true`, body)
      .then((res) => {
        setPageLimit(res?.data?.data?.state?.page_limit)
        let values: any = []

        for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
          values[i] = {
            payment: {
              _id: res?.data?.data?.payment_data?.[i]?._id,
              id: res?.data?.data?.payment_data?.[i]?.id,
              name: res?.data?.data?.payment_data?.[i]?.name,
              note: res?.data?.data?.payment_data?.[i]?.note,
              currency: 'AED',
              paymentStatus: res?.data?.data?.payment_data?.[i]?.paymentStatus,
              categories: res?.data?.data?.payment_data?.[i]?.categories,
              amountPaid: parseFloat(res?.data?.data?.payment_data?.[i]?.amountPaid),
              outstandingAmount: res?.data?.data?.payment_data?.[i]?.outstandingAmount,
              subTotalAmount: res?.data?.data?.payment_data?.[i]?.subTotalAmount,
              totalVAT: res?.data?.data?.payment_data?.[i]?.totalVAT,
              totalAmount: res?.data?.data?.payment_data?.[i]?.totalAmount,
              // paymentType: 0,
              paymentMethod: res?.data?.data?.payment_data?.[i]?.paymentMethod,
              paymentAccountId: res?.data?.data?.payment_data?.[i]?.paymentAccountId,
              chequeBankName: res?.data?.data?.payment_data?.[i]?.chequeBankName,
              isCardVerificationEnable:
                res?.data?.data?.payment_data?.[i]?.isCardVerificationEnable,
              dueDate: res?.data?.data?.payment_data?.[i]?.dueDate,
              datePaid: res?.data?.data?.payment_data?.[i]?.datePaid,
              attachments: res?.data?.data?.payment_data?.[i]?.attachments,
              chequeImages: res?.data?.data?.payment_data?.[i]?.chequeImages,
              tenancyId: id,
              createdAt: res?.data?.data?.payment_data?.[i]?.createdAt,
              terminationReduction: {
                refund: res?.data?.data?.payment_data?.[i]?.terminationReduction?.refund,
                cancel: res?.data?.data?.payment_data?.[i]?.terminationReduction?.cancel,
                collect: res?.data?.data?.payment_data?.[i]?.terminationReduction?.collect,
                isUpdated: false,
              },
              // unitId: unitDetails?._id,
              // clusterId: unitDetails?.clusterId,
              // unitGroupId: unitDetails?.unitGroupId,
              // communityId: unitDetails?.communityId,
              // floorId: unitDetails?.floorId,
              // buildingId: unitDetails?.buildingId,
            },
            new_payment_sub_item: res?.data?.data?.payment_data?.[i]?.payment_sub_item,
            // new_payment_invoice: payInv,
            // new_payment_receipt: prec,
            // new_payment_reminder: remindersSched,
          }

        }

        setIncomePaymentPayload(values)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setOpen(false)

      })
      .catch((err) => {
        console.log('err', err)
        setOpen(false)
      })
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}?isCloneRecord=true`)
      .then(async (res) => {
        setDataToEdit(res?.data?.data)
        setIncomingPaymentId(res?.data?.data?._id)
        setPaymentId(res?.data?.data?.id)

        await ApiGet(`corporate/unit/${res?.data?.data?.unitId}`)
          .then((res) => {
            setUnitDetails(res?.data?.data)
            setEditIncomeModal(true)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()

  useEffect(() => {
    let from: any = localStorage.getItem('fromList')
    if (from == null && flg == '3') {
      // let temp = localStorage.getItem('DiscardSession')
      // if (temp != null) {
      //   discardSession()
      // }

      let temp2 = localStorage.getItem('DeleteClone')
      if (temp2 != null) {
        deleteClone()
      }
    }

    setRandomDatabaseId(new Date().getTime())
  }, [])

  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }

  const getFilenameToSelect = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }
  const saveImageFileName = async () => {
    setModalBtnIsLoading(true)
    setFileFound(false)
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i]
      let fileURL: any = URL.createObjectURL(data.selctedfile)
      let extensionType = data?.selctedfile?.name?.split('.')[1]
      let type: any = finalFileUpload[i].type

      data.fileURL = fileURL
      formData.append('image', data?.selctedfile)
      let fileType: any = ''

      switch (type) {
        case 'tenancy':
          fileType = 'tenancy_other'
          break
        case 'tenant':
          fileType = 'tenant_other'
          break
        default:
          break
      }

      // if (type == 'tenancy' || type == 'tenant') {
      await ApiPost(
        `upload/file_checker?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then(async (res) => {
          if (res?.data?.data?.isFileExist) {
            setFileFound(true)
          } else {
            await ApiUpload(
              `upload/image/params?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
              formData
            )
              .then((res) => {
                if (type == 'tenant') {
                  setuploadOtherImgTenant([...uploadOtherImgTenant, res?.data?.data?.image])
                } else if (type == 'tenancy') {
                  setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
                }
                // setIsFormDirty(true)
                handleClose()
              })
              .catch((err) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
              })
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
          setModalBtnIsLoading(false)
        })
    }
  }

  const handleClose = () => {
    setModalBtnIsLoading(false)
    setShowImageName(false)
  }

  const [isEditTenant, setIsEditTenant] = useState<any>(false)

  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl?.includes('blob')) {
      return true
    } else if (fileUrl?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const removeBucket = (url: any) => {
    if (typeof url === 'string') {
      return url.replaceAll(Bucket, '')
    } else {
      let urlData: any = []
      url?.forEach((element: any) => {
        urlData?.push(element?.replaceAll(Bucket, ''))
      })
      return urlData
    }
  }

  const [tenantDocuments, setTenantDocuments] = useState<any>([])
  const [tenantDeletedDocuments, setTenantDeletedDocuments] = useState<any>([])

  const [contractDocuments, setContractDocuments] = useState<any>([])
  const [contractDeletedDocuments, setContractDeletedDocuments] = useState<any>([])

  const [renewalError, setRenewalError] = useState<any>(false)

  // Save as Draft
  const onSaveNewFlow = (data: any, event: any, isDraft: any) => {
    setIsLoading(true)

    //  tenant Documents
    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    tentDocInd = tentDoc.length

    for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
      if (tenantDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
            if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDeletedDocuments[i]?._id,
          name: tenantDeletedDocuments[i]?.name,
          isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    contractDocInd = contractDoc.length

    for (let i = 0; i < contractDeletedDocuments?.length; i++) {
      if (contractDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
            if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDeletedDocuments[i]?._id,
          name: contractDeletedDocuments[i]?.name,
          isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    // Benefits
    let temp: any = []
    for (let i = 0; i < otherOptions?.length; i++) {
      temp[i] = otherOptions[i]?.value
    }

    let valuesBenefit = {...optionArry}
    valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    // Financial Summary
    let finance: any = []
    finance[0] = rentFin
    finance[1] = securityFin
    let finInd = 2

    for (let i = 0; i < otherFinancials?.length; i++) {
      finance[finInd] = otherFinancials[i]
      finInd++
    }

    // Sub Tenants
    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3' || flg == '4') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    const body = {
      unitId: unitData?._id ? unitData?._id : null,
      mainTenantId: newTenantId,
      subTenantIds: subIds,
      tenancy: {
        _id: tenancyId,
        id: tenancyData?.id,
        tenantRequestId: tenantRequestId,
        isDraft: isDraft,
        contractNo: tenancyData?.contractNo,
        duration: {
          start_date: startDatePayload,
          end_date: endDatePayload,
          days: totalDays.split(' ')[0],
        },
        benefits: valuesBenefit,
        renewalDays: Number(tenancyData?.renewalDays),
        tenantDocument: tentDoc,
        contractDocument: contractDoc,
      },
      // paymentList: payment,
    }
    ApiPost('corporate/tenancy/terminate_form/handler', body)
      .then((res) => {
        setIsFormDirty(false)
        localStorage.removeItem('isFormDirty')

        const body = {
          tenancyId: tenancyId,
        }

        ApiPost(`corporate/tenancy/save/session?isCloneRecord=true`, body)
          .then(() => {
            localStorage.removeItem('DeleteClone')

            const body = {
              id: tenancyId,
              terminatePaymentList: terminatePaymentList,
            }

            ApiPut(`corporate/tenancy?isCloneRecord=true`, body)
              .then((res: any) => {
                setTimeout(() => {
                  getAllPayments()
                  setOpen(false)
                  setIsLoading(false)
                }, 1000)
              })
              .catch((err: any) => {
                setIsLoading(false)
                ErrorToast(err?.message)
                setOpen(false)
              })

            if (event == 'exit')
              navigate(`/lease-request/${tenantRequestId}/${id}/${tenancyId}/3`, {
                state: {
                  requestId: tenantRequestId,
                },
              })
            // navigate(`/tenants`, {
            //   state: {
            //     requestId: state?.tenantReqId,
            //   },
            // })
            else {
              setIsLoading(false)

              SuccessToast('Lease has been saved as DRAFT')
              navigate(`/tenancy-termination/2/${unitData?._id}/${res?.data?.data?._id}`)

              setTimeout(() => {
                setIsSaveFirst(true)
              }, 300)
            }
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
          })
      })
      .catch((err) => ErrorToast(err.message))
  }

  const [dateError, setDateError] = useState<any>(false)

  // Submit Request
  const onSubmitNewFlow = (data: any, event: any, isDraft: any) => {
    event.preventDefault()
    setIsLoading(true)
    if (end_date != null && !renewalError) {
      //  tenant Documents
      let tentDoc: any = []
      let tentDocInd: any = 0

      for (let i = 0; i < tenantDocuments?.length; i++) {
        if (tenantDocuments[i]?.isCheck) {
          let files: any = []
          if (tenantDocuments[i]?.fileList?.length > 0) {
            let ind: any = 0
            for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
              if (tenantDocuments[i]?.fileList[j].isChecked) {
                files[ind] = {
                  fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                  fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                  _id: tenantDocuments[i]?.fileList[j]?._id,
                }
                ind = ind + 1
              }
            }
          }
          tentDoc[tentDocInd] = {
            _id: tenantDocuments[i]?._id,
            name: tenantDocuments[i]?.name,
            isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
            fileList: files,
            tenantId: newTenantId,
          }
          tentDocInd = tentDocInd + 1
        }
      }

      tentDocInd = tentDoc.length

      for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
        if (tenantDeletedDocuments[i]?.isCheck) {
          let files: any = []
          if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
            let ind: any = 0
            for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
              if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
                files[ind] = {
                  fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
                  fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
                  _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
                }
                ind = ind + 1
              }
            }
          }
          tentDoc[tentDocInd] = {
            _id: tenantDeletedDocuments[i]?._id,
            name: tenantDeletedDocuments[i]?.name,
            isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
            fileList: files,
            tenantId: newTenantId,
          }
          tentDocInd = tentDocInd + 1
        }
      }

      // contract Documents
      let contractDoc: any = []
      let contractDocInd: any = 0

      for (let i = 0; i < contractDocuments?.length; i++) {
        if (contractDocuments[i]?.isCheck) {
          let files: any = []
          if (contractDocuments[i]?.fileList?.length > 0) {
            let ind: any = 0
            for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
              if (contractDocuments[i]?.fileList[j].isChecked) {
                files[ind] = {
                  fileName: contractDocuments[i]?.fileList[j]?.fileName,
                  fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                  _id: contractDocuments[i]?.fileList[j]?._id,
                }
                ind = ind + 1
              }
            }
          }
          contractDoc[contractDocInd] = {
            _id: contractDocuments[i]?._id,
            name: contractDocuments[i]?.name,
            isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
            fileList: files,
            tenantId: newTenantId,
          }
          contractDocInd = contractDocInd + 1
        }
      }

      contractDocInd = contractDoc.length

      for (let i = 0; i < contractDeletedDocuments?.length; i++) {
        if (contractDeletedDocuments[i]?.isCheck) {
          let files: any = []
          if (contractDeletedDocuments[i]?.fileList?.length > 0) {
            let ind: any = 0
            for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
              if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
                files[ind] = {
                  fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
                  fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
                  _id: contractDeletedDocuments[i]?.fileList[j]?._id,
                }
                ind = ind + 1
              }
            }
          }
          contractDoc[contractDocInd] = {
            _id: contractDeletedDocuments[i]?._id,
            name: contractDeletedDocuments[i]?.name,
            isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
            fileList: files,
            tenantId: newTenantId,
          }
          contractDocInd = contractDocInd + 1
        }
      }

      let temp: any = []
      for (let i = 0; i < otherOptions?.length; i++) {
        temp[i] = otherOptions[i]?.value
      }

      let valuesBenefit = {...optionArry}
      valuesBenefit.other = temp

      setOptionArry(valuesBenefit)

      let subIds: any = []
      let j: any = 0
      for (let i = 0; i < subTenantData?.length; i++) {
        if (subTenantData[i]?.id != '') {
          subIds[j] = subTenantData[i]?.id
          j++
        }
      }

      let benifit_optionArray: any = optionArry

      if (flg == '2' || flg == '3' || flg == '4') {
        delete valuesBenefit._id
        benifit_optionArray = optionArry
      }

      // Draft Request
      if (flg == '2') {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          mainTenantId: newTenantId,
          subTenantIds: subIds,
          tenancy: {
            _id: tenancyId,
            id: tenancyData?.id,
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            renewalDays: Number(tenancyData?.renewalDays),
            tenantDocument: tentDoc,
            contractDocument: contractDoc,
            tenantRequestId: tenantRequestId,
          },
          // paymentList: payment,
        }
        ApiPost('corporate/tenancy/terminate_form/handler', body)
          .then((res) => {
            setIsFormDirty(false)
            localStorage.removeItem('isFormDirty')

            const body = {
              tenancyId: tenancyId,
            }

            ApiPost(`corporate/tenancy/save/session?isCloneRecord=true`, body)
              .then(() => {
                setIsLoading(false)
                SuccessToast(res?.data?.message)
                setTimeout(() => {
                  navigate(-1)
                }, 500)
              })
              .catch((err: any) => {
                ErrorToast(err?.message)
              })
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '3') {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          mainTenantId: newTenantId,
          subTenantIds: subIds,
          tenancy: {
            _id: tenancyId,
            id: tenancyData?.id,
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            renewalDays: Number(tenancyData?.renewalDays),
            tenantDocument: tentDoc,
            contractDocument: contractDoc,
            tenantRequestId: tenantRequestId,
          },
          // paymentList: payment,
        }
        ApiPost('corporate/tenancy/terminate_form/handler', body)
          .then((res) => {
            setIsFormDirty(false)
            localStorage.removeItem('isFormDirty')
            const body = {
              tenancyId: tenancyId,
            }

            ApiPost(`corporate/tenancy/save/session?isCloneRecord=true`, body)
              .then(() => {
                setIsLoading(false)
                SuccessToast(res?.data?.message)
                setTimeout(() => {
                  navigate(-1)
                }, 500)
              })
              .catch((err: any) => {
                ErrorToast(err?.message)
              })
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else {
      if (end_date == null) {
        setDateError(true)
      }
    }
  }

  const submitTenantDocuments = (data: any, event: any, isDraft: any) => {
    event.preventDefault()

    //  tenant Documents
    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    let temp: any = []
    for (let i = 0; i < otherOptions?.length; i++) {
      temp[i] = otherOptions[i]?.value
    }

    let valuesBenefit = {...optionArry}
    valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    setIsLoading(true)

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    const body = {
      unitId: unitData?._id ? unitData?._id : null,
      mainTenantId: newTenantId,
      subTenantIds: subIds,
      tenancy: {
        _id: tenancyId,
        isDraft: false,
        contractNo: tenancyData?.contractNo,
        duration: {
          start_date: startDatePayload,
          end_date: endDatePayload,
          days: totalDays.split(' ')[0],
        },
        benefits: valuesBenefit,
        renewalDays: Number(tenancyData?.renewalDays),
        tenantDocument: tentDoc,
        // contractDocument: contractDoc,
      },
      // paymentList: incomePaymentPayload,
    }
    ApiPost('corporate/tenancy/form/handler', body)
      .then((res) => {
        setIsFormDirty(false)
        setIsLoading(false)

        SuccessToast(res?.data?.message)
        setTimeout(() => {
          setIsEditTenant(false)
          getTenancyData(tenancyId)
          setIsEdit(false)
        }, 1000)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const navigate = useNavigate()

  const getTenantData = () => {
    ApiGet(`corporate/tenant?isNullFilter=true&isCloneRecord=true`)
      .then((res) => {
        setTenantData(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const [tenantFolders, setTenantFolders] = useState<any>([])
  const [residencyFolder, setResidencyFolder] = useState<any>()
  const [passportFolder, setPassportFolder] = useState<any>()
  const [iDsFolder, setIDsFolder] = useState<any>()
  const [contractsFolder, setContractsFolder] = useState<any>()
  const [dataToSelectFrom, setDataToSelectFrom] = useState<any>()
  const [folderFileModal, setFolderFileModal] = useState<any>(false)
  const [fileType, setFileType] = useState<any>()
  const [showBck, setShowBck] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [selectedTenantName, setSelectedTenantName] = useState<any>()
  const [incomingPaymentId, setIncomingPaymentId] = useState<any>()
  const [paymentId, setPaymentId] = useState<any>()

  //
  const getTenantDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          for (let x = 0; x < dataTenant?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            //  Tenant Documents
            if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
              for (let x = 0; x < dataTenant?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                  incTenant = true
                  ind = x
                  x = dataTenant?.length
                } else {
                  incTenant = false
                }
              }

              if (incTenant) {
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false
                  let isAvailableTenant: any = false

                  for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataTenant[ind]?.fileList?.[x]?._id
                    ) {
                      // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                      isAvailableTenant = true
                      x = dataTenant[ind]?.fileList?.length
                    } else {
                      isAvailableTenant = false
                    }
                  }

                  // console.log(isAvailableTenant)

                  if (isAvailableTenant) {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                t++
              } else {
                // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                t++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataTenant?.length; x++) {
          for (let y = 0; y < tentFold?.length; y++) {
            if (tentFold[y]._id == dataTenant[x]?._id) {
              // console.log(tentFold[y]._id + ' ' + dataTenant[x]?._id)
              temp = true
              // tempInd = x
              y = tentFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (dataTenant?.[tempInd]?.name != 'Contracts') {
              deletedFolder[d] = {
                _id: dataTenant?.[tempInd]?._id,
                name: dataTenant?.[tempInd]?.name,
                isSystemGenerated: dataTenant?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataTenant?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataTenant?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataTenant?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataTenant?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataTenant?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        setTenantDocuments(tentFold)
        setTenantDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getContractDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    // setContractDocuments([])

    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          // Contract/Tenancy Documents
          // console.log(dataContract)
          // console.log(contractDocuments)
          for (let x = 0; x < dataContract?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            if (
              res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
              res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
              res?.data?.data?.tenant_folder?.[i].name != 'IDs'
            ) {
              for (let x = 0; x < dataContract?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                  isInc = true
                  indTen = x
                  x = dataContract?.length
                } else {
                  isInc = false
                }
              }

              if (isInc) {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false

                  for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataContract[indTen]?.fileList?.[x]?._id
                    ) {
                      isAvailable = true
                      x = dataContract[indTen]?.fileList?.length
                    } else {
                      isAvailable = false
                    }
                  }

                  if (isAvailable) {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                c++
              } else {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                c++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataContract?.length; x++) {
          for (let y = 0; y < contFold?.length; y++) {
            if (contFold[y]._id == dataContract[x]?._id) {
              // console.log(contFold[y]._id + ' ' + dataContract[x]?._id)
              temp = true
              // tempInd = x
              y = contFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (
              dataContract?.[tempInd]?.name != 'Passports' &&
              dataContract?.[tempInd]?.name != 'Residencies' &&
              dataContract?.[tempInd]?.name != 'IDs'
            ) {
              deletedFolder[d] = {
                _id: dataContract?.[tempInd]?._id,
                name: dataContract?.[tempInd]?.name,
                isSystemGenerated: dataContract?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataContract?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataContract?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataContract?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataContract?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataContract?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        setContractDocuments(contFold)
        setContractDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const [tenantRequestId, setTenantRequestId] = useState<any>()

  const [rentFin, setRentFin] = useState<any>({
    name: 'Rent',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [securityFin, setSecurityFin] = useState<any>({
    name: 'Security Deposit',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [otherFinancials, setOtherFinancials] = useState<any>([
    // {
    //   name: '',
    //   amount: 0,
    //   noOfPayments: 0
    // }
  ])

  useEffect(() => {
    getUbitDataById()
    // getTenantData()
    setSendError(false)
    getTenancyData(tenancyId)
    getAllPayments()

    let temp = localStorage.getItem('termTenantReqId')
    if (temp != null) {
      setTenantRequestId(temp)
    }
  }, [])

  // React.useEffect(() => {
  //   // reset(formData)
  // }, [formData])

  const [terminatePaymentList, setTerminatePaymentList] = useState<any>([])

  const getTenancyData = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}?isCloneRecord=true`)
      .then((res) => {
        // console.log('\nres.data.data', res.data.data)

        let finance: any = res?.data?.data?.[0]?.financialSummery
        let otherInd: any = 0
        let otherValues: any = []
        for (let i = 0; i < finance?.length; i++) {
          if (finance[i]?.name == 'Rent') setRentFin(finance[i])
          else if (finance[i]?.name == 'Security Deposit') setSecurityFin(finance[i])
          else {
            otherValues[otherInd] = finance[i]
            otherInd++
          }
        }
        setOtherFinancials(otherValues)

        if (res?.data?.data?.[0]?.tenant?.length > 0) {
          setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
          // console.log(res?.data?.data?.[0]?.tenant?.length)
          setFormData(res?.data?.data?.[0]?.tenant?.[0])
        }

        setNewTenancyID(res.data.data?.[0]?._id)
        setTenancyData(res?.data?.data[0])
        setTerminatePaymentList(res?.data?.data[0]?.terminatePaymentList)

        let term = [...show]
        for (let i = 0; i < res?.data?.data[0]?.terminatePaymentList.length; i++) {
          term[i] = true
          setShow(term)
        }

        // SubTenant Data
        let values: any = []
        for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
          values[i] = {
            id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
            firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
            lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
            email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
            countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
            phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
          }
        }
        setSubTenantData(values)

        if (res?.data?.data[0]?.duration?.start_date != null) {
          setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
          setStartDatePayload(moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD'))
        }

        if (res?.data?.data[0]?.duration?.end_date != null) {
          if (state?.terminationDate) {
            setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
            setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
          } else {
            if (flg != '3') {
              setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
              setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
            }
          }
        }

        setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
        setRemainingDays(res?.data?.data[0]?.remainingDays + ' days')

        setNewTenantID(res?.data?.data?.[0]?.tenantId)

        let final_obj: any = {
          none: false,
          maintenanceFree: false,
          chillerFree: false,
          other: [],
          ...res?.data?.data[0]?.benefits,
        }
        setOptionArry(final_obj)

        let temp: any = []
        for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
          temp[i] = {
            value: res?.data?.data[0]?.benefits?.other?.[i],
          }
        }
        setOtherOptions(temp)

        getTenantDocuments(
          res?.data?.data?.[0]?.tenant?.[0]?._id,
          res?.data?.data?.[0]?.tenantDocument,
          res?.data?.data?.[0]?.contractDocument
        )

        getContractDocuments(
          res?.data?.data?.[0]?.tenant?.[0]?._id,
          res?.data?.data?.[0]?.tenantDocument,
          res?.data?.data?.[0]?.contractDocument
        )
      })

      .catch((err) => console.log('err', err))
  }

  const getImage: any = () => {
    if (unitData?.images?.length > 0 && unitData?.images[0].length > 0) {
      return <img src={`${Bucket}${unitData?.images[0]}`} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'town_house') {
      return <img src={townhouse} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'other') {
      return <img src={other} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'common_area') {
      return <img src={common_area} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'villa') {
      return <img src={villa} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'apartment') {
      return <img src={appartment} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'penthouse') {
      return <img src={penthouse} alt='' width={170} height={150} />
    }
  }

  const [from, setFrom] = useState<any>()
  const [subInd, setSubInd] = useState<any>()

  const [disabledBtn, setDisabledBtn] = useState<any>(true)
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [tenantDetails, setTenantDetails] = useState<any>()

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [show, setShow] = useState<any>([])

  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])

  const updateIncomePaymentPayload = (val: any, from: any, ind: any) => {
    const values = [...incomePaymentPayload]
    if (from == 'add') values[values.length] = val
    else values[ind] = val

    setIncomePaymentPayload(values)

    for (let i = 0; i < values.length; i++) {
      let values = [...show]
      values[i] = false
      setShow(values)
    }

    setIsFormDirty(true)
    localStorage.removeItem('fromList')
    if (flg == '3') {
      let temp = {
        // discard : false,
        tenancyId: tenancyId,
        // delete: true,
        requestId: state?.tenantReqId,
      }
      localStorage.setItem('DeleteClone', JSON.stringify(temp))
    }
    // localStorage.setItem('isFormDirty', 'true')
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  //
  const getSubTenants = (id: any) => {
    let body: any

    if (flg == '1') {
      let newId = localStorage.getItem('newTenancyID')
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: newId,
      }
    } else {
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: tenancyId,
      }
    }

    if (flg == '3')
      ApiPost('corporate/tenant/sub_tenant/get?isCloneRecord=true', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              _id: res?.data?.data?.tenant_data?.[i]?._id,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPost('corporate/tenant/sub_tenant/get', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              _id: res?.data?.data?.tenant_data?.[i]?._id,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
  }

  const cancelTenancy = () => {
    ApiGet(`corporate/tenancy/cancel/${tenancyId}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate(-1)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      // if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      // }
      getTenantData()
      // getSubTenants(tenancyId)
      getAllPayments()
      ApiGet(`corporate/unit/${unitData?._id}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    }
  }, [isSaveFirst])

  // useEffect(() => {
  //   const handleBeforeUnload = (e: any) => {
  //     // if (isFormDirty) {
  //     // Standard for most browsers
  //     const message = 'Are you sure you want to leave?'
  //     e.returnValue = message // Standard
  //     return e.returnValue
  //   }
  //   //  }

  //   window.addEventListener('beforeunload', handleBeforeUnload)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //   }
  // }, [isFormDirty])

  const [showModall, setShowModall] = useState<any>(false)

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // setShowModall(true)

    const message = 'Are you sure you want to leave?'
    console.log(' I am in before unload')

    console.log(showModall)
    event.returnValue = message
  }

  const handleUnload2 = async () => {
    let temp = {
      discard: true,
      tenancyId: tenancyId,
      delete: false,
      requestId: flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId,
    }
    localStorage.setItem('DiscardSession', JSON.stringify(temp))
    localStorage.removeItem('isFormDirty')
    let data = await discardSession()

    // Handle cleanup or additional actions before the page is unloaded
    console.log('Page is being unloaded ...')
  }

  const handleUnload3 = async () => {
    let temp = {
      // discard : false,
      tenancyId: tenancyId,
      // delete: true,
      requestId: tenantRequestId,
    }
    localStorage.setItem('DeleteClone', JSON.stringify(temp))
    // localStorage.setItem('DeleteClone', 'true')
    localStorage.removeItem('isFormDirty')
    let data = await deleteClone()

    // Handle cleanup or additional actions before the page is unloaded
    console.log('Page is being unloaded...')
  }

  const handlePopstate = (event: PopStateEvent) => {
    const userChoice = window.confirm(
      'You are about to leave this page. Do you wish to save your updates?'
    )

    if (userChoice) {
      console.log('User chose to save updates')
      onSaveNewFlow(formData, 'exit', true)
      window.removeEventListener('popstate', handlePopstate)
    } else {
      deleteClone()
      console.log('User chose not to save updates')
      window.removeEventListener('popstate', handlePopstate)
    }
  }

  // window.addEventListener('popstate', handlePopstate);

  useEffect(() => {
    if (isFormDirty && flg == '3') {
      // window.addEventListener('popstate', handlePopstate)
      window.addEventListener('beforeunload', handleBeforeUnload)
      // if (flg == '2') window.addEventListener('unload', handleUnload2)
      // else
      window.addEventListener('unload', handleUnload3)

      return () => {
        // window.removeEventListener('popstate', handlePopstate)
        window.removeEventListener('beforeunload', handleBeforeUnload)
        // if (flg == '2') window.removeEventListener('unload', handleUnload2)
        // else
        window.removeEventListener('unload', handleUnload3)
      }
    }
  }, [isFormDirty])

  const handleConfirm = () => {
    // Handle the user's choice here
    // For example, you can update data or perform other actions
    // Then, close the modal and allow the page to be unloaded
    setShowModal(false)
    window.removeEventListener('beforeunload', handleBeforeUnload)
  }

  const handleCancel = () => {
    // Cancel the unloading process and close the modal
    setShowModal(false)
  }

  const [isEdit, setIsEdit] = useState<any>(true)
  var itemInd: any

  /* ---------------- Message ------------------*/
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)
  const [chatServiceJobId, setChatServiceJobId] = useState<any>()
  const [tenanttData, setTenanttData] = useState<any>()
  const [serviceJobId, setServiceJobId] = useState<any>()

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector(
    (state: any) => state.counterReducer.chatSelectedDataList
  )
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)

  const [serviceJob, setServiceJob] = useState<any>('')

  const [noShow, setNoShow] = useState<any>(0)
  const [chatSubject, setChatSubject] = useState<any>()

  const [chatRoomId, setChatRoomId] = useState<any>(null)
  const [roomData, setRoomData] = useState<any>(null)

  // const [pageLimit, setPageLimit] = useState<any>(false)
  // const [page, setPage] = useState<any>(1)

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours: any = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes: any = Math.round(minutes)
    var rh, rm

    if (rhours < 10) rh = '0' + rhours
    else rh = rhours

    if (rminutes < 10) rm = '0' + rminutes
    else rm = rminutes

    return rh + ':' + rm
  }

  useEffect(() => {
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  // useEffect(() => {
  //   socket?.once('online', handleOnline)
  //   socket?.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   return () => {
  //     socket?.off('online', handleOnline)
  //     socket?.close()
  //   }
  // }, [])
  // const handleOnline = (data: any) => {
  //   console.log('socket Online event received:', data)
  //   // Update state to reflect online status
  // }

  // useEffect(() => {
  //   socket?.on('receive_message', (newMessage: any) => {
  //     console.log('socket newMessage', newMessage)
  //     console.log('messages', messages)
  //     setMessages([
  //       ...messages,
  //       {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()},
  //     ])
  //   })
  //   socket?.on('new_user', (new_user) => {
  //     console.log('socket new_user', new_user)
  //   })
  // }, [])

  // useEffect(() => {
  //   socket?.off('receive_message')
  //   socket?.on('receive_message', (message: any) => {
  //     setMessages((prevMessages: any) => [...prevMessages, message])
  //   })
  // }, [])

  const handleJoinRoom = (room: any) => {
    socket?.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket?.off('send_message')
    socket?.emit('send_message', message)
  }

  // useEffect(() => {
  //   socket?.off('online')
  //   socket?.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  // }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }

  // const [chatId, setChatId] = useState<any>('')
  // const [msgPage, setMsgPage] = useState<any>(1)
  // const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    // await ApiGet(`corporate/chat_room/${chat._id}`)
    //   .then((res) => {
    setDefaultRoomData(roomData)
    fetchMessage(chat._id, 1, [])
    // })
    // .catch((err) => {
    //   console.log('res_blob', err)
    // })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages(res?.data?.data?.chat_message_data)
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err) => console.log('failed: ', err))
  }
  notificationEvent()

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   await ApiPost(`corporate/chat_message/get`, {
  //   limit: 10,
  //   page: 1,
  //   chatRoomId: chatRoomId,
  // })
  //   .then((res) => {
  //     setMessages(res?.data?.data?.chat_message_data)
  //     setMsgPage(msgPage + 1)
  //     setMsgPageLimit(res?.data?.data?.state?.page_limit)
  //   })
  //   .catch((err) => {
  //     console.log('res_blob', err)
  //   })
  // }

  const handleSubmitt = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let idsToSend: any = []
    for (let i = 0; i < tenanttData?.length; i++) {
      idsToSend[i] = tenanttData[i]._id
    }

    // const body = {
    //   subjectTitle: bodyWithSubject.subjectTitle,
    //   type: 0,
    //   serviceJobId: chatServiceJobId,
    //   tenantIds: idsToSend,
    // }

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      // await ApiPost(`corporate/chat_room`, body)
      //   .then((res) => {
      //     console.log('\nres.data.data', res.data.data)
      handleSendMessage({
        roomId: chatRoomId,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      openMessage(e, 0, {_id: chatRoomId})
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
      // })
      // .catch((err) => {
      //   console.log('res_blob', err)
      // })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    // let m = new Date(dd).toLocaleString()
    // let x = moment(m.format("DD-MM-YYYY HH:mm"))
    // return x;
    return new Date(dd).toLocaleString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const getChatList = async () => {
    // await ApiPost(`corporate/chat_room/get`, {
    //   limit: 10,
    //   page: page,
    // })
    //   .then((res) => {
    //     setChatData([...chatData, ...res?.data?.data?.chat_room_data])
    //     setPage(page + 1)
    //     setPageLimit(res?.data?.data?.state?.page_limit)
    //   })
    //   .catch((err) => {
    //     console.log('res_blob', err)
    //   })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 10,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    // searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const gotoChatList = async () => {
    const body = {
      limit: 10,
      page: 1,
      serviceJobId: serviceJob,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        setTenanttData(res.data.data.tenant_data)
        setChatData(res?.data?.data?.chat_room_data)
        setShowChat('message')
      })
      .catch()
  }

  // function called on clicking Message tenant Button
  const getChatRoomForTenant = async (val: any) => {
    let tenant: any = []
    tenant[0] = val
    setServiceJob(val)
    await ApiGet(`corporate/chat_room/personal_chat?tenantId=${val?._id}`)
      .then((res) => {
        SetBodyWithSubject({subjectTitle: res?.data?.data?.subjectTitle})
        showMessagePopUp()

        setTimeout(() => {
          fetchMessage(res?.data?.data?._id, 1, [])
        }, 300)

        // if (res.data.data.isNewChat === true)
        setShowChat('newMessage')

        setTenanttData(tenant)

        setChatRoomId(res.data.data._id)
        setRoomData(res?.data?.data)

        setDefaultRoomData(res?.data?.data)

        handleJoinRoom({
          roomId: res?.data?.data?._id,
          userId: user._id,
        })

        // setChatData(res?.data?.data?.chat_room_data)
      })
      .catch()
  }

  // const disabledDate = (current: any) => {
  //   // Disable dates before today
  //   return current ? current < moment().subtract(1, 'day') : false
  // }

  //
  const deleteClone = async () => {
    const body = {
      tenancyId: tenancyId,
    }

    ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
      .then(() => {
        // navigate(`/tenants`, {
        //   state: {
        //     requestId: state?.tenantReqId,
        //   },
        // })
        navigate(`/lease-request/${tenantRequestId}/${id}/${tenancyId}/3`, {
          state: {
            requestId: tenantRequestId,
          },
        })
        localStorage.removeItem('DeleteClone')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const discardSession = async () => {
    const body = {
      tenancyId: tenancyId,
    }

    ApiPost(`corporate/tenancy/apply/session?isCloneRecord=true`, body)
      .then(() => {
        let temp = localStorage.getItem('DiscardSession')
        if (temp != null) {
          localStorage.removeItem('DiscardSession')
        } else {
          navigate(`/tenants`, {
            state: {
              requestId: tenantRequestId,
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const autoSave = () => {
    setIsLoading(true)

    if (flg == '2') setOpen(true)

    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    tentDocInd = tentDoc.length

    for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
      if (tenantDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
            if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDeletedDocuments[i]?._id,
          name: tenantDeletedDocuments[i]?.name,
          isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    contractDocInd = contractDoc.length

    for (let i = 0; i < contractDeletedDocuments?.length; i++) {
      if (contractDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
            if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDeletedDocuments[i]?._id,
          name: contractDeletedDocuments[i]?.name,
          isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    let temp: any = []
    for (let i = 0; i < otherOptions?.length; i++) {
      temp[i] = otherOptions[i]?.value
    }

    let valuesBenefit = {...optionArry}
    valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    // Financial Summary
    let finance: any = []
    finance[0] = rentFin
    finance[1] = securityFin
    let finInd = 2

    for (let i = 0; i < otherFinancials?.length; i++) {
      finance[finInd] = otherFinancials[i]
      finInd++
    }

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3' || flg == '4') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    // Draft Request
    if (flg == '2') {
      const body = {
        unitId: unitData?._id ? unitData?._id : null,
        mainTenantId: newTenantId,
        subTenantIds: subIds,
        tenancy: {
          _id: tenancyId,
          id: tenancyData?.id,
          isDraft: true,
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: startDatePayload,
            end_date: endDatePayload,
            days: totalDays.split(' ')[0],
          },
          benefits: valuesBenefit,
          renewalDays: Number(tenancyData?.renewalDays),
          tenantDocument: tentDoc,
          contractDocument: contractDoc,
          tenantRequestId: tenantRequestId,
          financialSummery: finance,
        },
        // paymentList: payment,
      }
      ApiPost('corporate/tenancy/terminate_form/handler', body)
        .then((res) => {
          const body = {
            tenancyId: tenancyId,
          }

          ApiPost(`corporate/tenancy/save/session?isCloneRecord=true`, body)
            .then(() => {
              // for (let i = 0; i < incomePaymentPayload?.length; i++) {
              //   if (incomePaymentPayload?.[i]?.payment?.terminationReduction?.isUpdated) {
              //     for (let j = 0; j < incomePaymentPayload[i]?.new_payment_sub_item?.length; j++) {
              //       if (
              //         incomePaymentPayload?.[i]?.new_payment_sub_item?.[j]?.terminationReduction
              //           ?.isUpdated != undefined
              //       ) {
              //         const body = {
              //           id: incomePaymentPayload?.[i]?.new_payment_sub_item?.[j]?._id,
              //           terminationReduction: {
              //             refund: Number(
              //               incomePaymentPayload?.[i]?.new_payment_sub_item?.[j]
              //                 ?.terminationReduction?.refund
              //             ),
              //             cancel: Number(
              //               incomePaymentPayload?.[i]?.new_payment_sub_item?.[j]
              //                 ?.terminationReduction?.cancel
              //             ),
              //             collect: Number(
              //               incomePaymentPayload?.[i]?.new_payment_sub_item?.[j]
              //                 ?.terminationReduction?.collect
              //             ),
              //           },
              //         }
              //         ApiPut(`corporate/payment_sub_item?isCloneRecord=true`, body)
              //           .then((res: any) => {
              //             setIsLoading(false)
              //             setTimeout(() => {
              //               setOpen(false)
              //             }, 1000)
              //           })
              //           .catch((err: any) => {
              //             setIsLoading(false)
              //             ErrorToast(err?.message)
              //             setOpen(false)
              //           })
              //       }
              //     }

              //     const body = {
              //       id: incomePaymentPayload?.[i]?.payment?._id,
              //       terminationReduction: {
              //         refund: Number(
              //           incomePaymentPayload?.[i]?.payment?.terminationReduction?.refund
              //         ),
              //         cancel: Number(
              //           incomePaymentPayload?.[i]?.payment?.terminationReduction?.cancel
              //         ),
              //         collect: Number(
              //           incomePaymentPayload?.[i]?.payment?.terminationReduction?.collect
              //         ),
              //       },
              //     }
              //     ApiPut(`corporate/payment?isCloneRecord=true`, body)
              //       .then((res: any) => {
              //         setIsLoading(false)
              //         setTimeout(() => {
              //           setOpen(false)
              //         }, 1000)
              //       })
              //       .catch((err: any) => {
              //         setIsLoading(false)
              //         ErrorToast(err?.message)
              //         setOpen(false)
              //       })
              //   }
              // }

              setIsLoading(false)
              setTimeout(() => {
                setOpen(false)
              }, 1000)
            })
            .catch((err: any) => {
              setIsLoading(false)
              ErrorToast(err?.message)
              setOpen(false)
            })
        })
        .catch((err) => ErrorToast(err.message))
    } else if (flg == '3') {
      const body = {
        unitId: unitData?._id ? unitData?._id : null,
        mainTenantId: newTenantId,
        subTenantIds: subIds,
        tenancy: {
          _id: tenancyId,
          id: tenancyData?.id,
          tenantRequestId: tenantRequestId,
          isDraft: true,
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: startDatePayload,
            end_date: endDatePayload,
            days: totalDays.split(' ')[0],
          },
          benefits: valuesBenefit,
          renewalDays: Number(tenancyData?.renewalDays),
          tenantDocument: tentDoc,
          contractDocument: contractDoc,
        },
        // paymentList: payment,
      }
      ApiPost('corporate/tenancy/terminate_form/handler', body)
        .then((res) => {
          setIsLoading(false)
          setOpen(false)
        })
        .catch((err) => ErrorToast(err.message))
    }

    setIsFormDirty(true)
    localStorage.removeItem('fromList')
    if (flg == '3') {
      let temp = {
        // discard : false,
        tenancyId: tenancyId,
        // delete: true,
        requestId: state?.tenantReqId,
      }
      localStorage.setItem('DeleteClone', JSON.stringify(temp))
    }
    // localStorage.setItem('isFormDirty', 'true')
  }

  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  useEffect(() => {
    if (autoSaveData != false) autoSave()
  }, [autoSaveData])

  const [saveTermReduction, setSaveTermReduction] = useState<any>()
  const [saveMainTermReduction, setSaveMainTermReduction] = useState<any>()

  //
  // const updateTerminationReduction = () => {
  //   setIsLoading(true)
  //   setOpen(true)

  //   const body = {
  //     id: saveTermReduction?._id,
  //     terminationReduction: {
  //       refund: Number(saveTermReduction?.terminationReduction?.refund),
  //       cancel: Number(saveTermReduction?.terminationReduction?.cancel),
  //       collect: Number(saveTermReduction?.terminationReduction?.collect),
  //     },
  //   }

  //   ApiPut(`corporate/payment_sub_item?isCloneRecord=true`, body)
  //     .then((res: any) => {
  //       const body = {
  //         id: saveMainTermReduction?._id,
  //         terminationReduction: {
  //           refund: Number(saveMainTermReduction?.terminationReduction?.refund),
  //           cancel: Number(saveMainTermReduction?.terminationReduction?.cancel),
  //           collect: Number(saveMainTermReduction?.terminationReduction?.collect),
  //         },
  //       }
  //       ApiPut(`corporate/payment?isCloneRecord=true`, body)
  //         .then((res: any) => {
  //           setIsLoading(false)
  //           setTimeout(() => {
  //             getAllPayments()
  //             setOpen(false)
  //           }, 1000)
  //         })
  //         .catch((err: any) => {
  //           setIsLoading(false)
  //           ErrorToast(err?.message)
  //           setOpen(false)
  //         })
  //     })
  //     .catch((err: any) => {
  //       setIsLoading(false)
  //       ErrorToast(err?.message)
  //       setOpen(false)
  //     })
  // }

  const updateTerminationReduction = () => {
    setIsLoading(true)
    setOpen(true)

    const body = {
      id: tenancyId,
      terminatePaymentList: terminatePaymentList,
    }

    ApiPut(`corporate/tenancy?isCloneRecord=true`, body)
      .then((res: any) => {
        setTimeout(() => {
          // getTenancyData(tenancyId)
          setOpen(false)
          setIsLoading(false)
        }, 1000)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
        setOpen(false)
      })
  }

  useEffect(() => {
    if (saveMainTermReduction && flg == '2') updateTerminationReduction()
  }, [saveMainTermReduction])

  const disabledDate = (current: any) => {
    let temp = localStorage.getItem('OriginalTerm')
    if (temp != null) {
      let val = JSON.parse(temp)
      var startt = moment(val?.start)
      var endd = moment(val?.end)
      var today = moment()

      // Disable dates that are before the start date or after the end date
      return current && (current < startt || current >= endd || current < today.startOf('day'))
    }
  }

  const [otherItem, setOtherItem] = useState<any>({})

  const [financialItems, setFinancialItems] = useState<any>([])

  const getWorkFlowListByName = () => {
    ApiGet(`corporate/sub_workflow_list/get?name=Lease Termination`)
      .then((res: any) => {
        localStorage.setItem('leaseTerminationWithFinancials', res?.data?.data?.isFinancialIncluded)

        isFinance = res?.data?.data?.isFinancialIncluded
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    // getWorkFlowListByName()
  }, [])

  const finance: any = localStorage.getItem('leaseTerminationWithFinancials')
  let isFinance = JSON.parse(finance)

  const financialsRecord: any = localStorage.getItem('isFinanceSubmitted')
  let isFinanceSubmitted = JSON.parse(financialsRecord)

  // Handle Add New Termination Payment LIST
  const handleAddTerminationPaymentList = () => {
    const values = [...terminatePaymentList]
    values.push({
      amountPaid: 0,
      categories: 0,
      dueDate: null,
      id: '',
      name: '',
      outstandingAmount: 0,
      paymentStatus: 0,
      payment_sub_item: [
        {
          VATAmount: 0,
          VATPercentage: 0,
          amount: 0,
          amountPaid: 0,
          id: '',
          name: '',
          outstandingAmount: 0,
          paymentItemListId: '',
          paymentStatus: 0,
          // paymentSubItemStatus: 2,
          quantity: 1,
          terminationReduction: {refund: 0, cancel: 0, collect: 0},
          totalAmount: 0,
        },
      ],
      terminationReduction: {refund: 0, cancel: 0, collect: 0},
      totalAmount: 0,
    })
    setTerminatePaymentList(values)

    let term = [...show]
    term[values?.length - 1] = true
    setShow(term)
  }

  // Handle Remove Termination Payment LIST
  const handleRemoveTerminationPaymentList = (index: any) => {
    const values = [...terminatePaymentList]
    values.splice(index, 1)
    setTerminatePaymentList(values)

    setTimeout(() => {
      setSaveMainTermReduction(Date.now())
    }, 1000)
  }

  // Handle Add New Termination Payment LIST
  const handleAddTerminationPaymentListItem = (ind: any) => {
    const values = [...terminatePaymentList]
    const subItems = values[ind].payment_sub_item
    subItems.push({
      VATAmount: 0,
      VATPercentage: 0,
      amount: 0,
      amountPaid: 0,
      id: '',
      name: '',
      outstandingAmount: 0,
      paymentItemListId: '',
      paymentStatus: 0,
      // paymentSubItemStatus: 2,
      quantity: 1,
      terminationReduction: {refund: 0, cancel: 0, collect: 0},
      totalAmount: 0,
    })
    values[ind].payment_sub_item = subItems
    setTerminatePaymentList(values)
  }

  // Handle Remove Termination Payment LIST
  const handleRemoveTerminationPaymentListItem = (ind: any, subIndex: any) => {
    const values = [...terminatePaymentList]
    const subItems = values[ind].payment_sub_item
    subItems.splice(subIndex, 1)
    values[ind].payment_sub_item = subItems
    setTerminatePaymentList(values)

    setTimeout(() => {
      setSaveMainTermReduction(Date.now())
    }, 1000)
  }

  const getPaymentItemsList = () => {
    if (unitData?.communityId !== undefined && unitData?.communityId !== null) {
      ApiGet(`corporate/payment_item_list/payment?communityId=` + unitData.communityId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
          // let values: any = []
          // let x = 0
          // for (let i = 0; i < res?.data?.data?.length; i++) {
          //   if (
          //     res?.data?.data?.[i]?.name != 'Rent' &&
          //     res?.data?.data?.[i]?.name != 'Security Deposit'
          //   ) {
          //     values[x] = res?.data?.data?.[i]
          //     x++
          //   } else {
          //     if (res?.data?.data?.[i]?.name == 'Rent') {
          //       let values = {...rentFin}
          //       values.paymentItemListId = res?.data?.data?.[i]?._id
          //       setRentFin(values)
          //     }
          //     if (res?.data?.data?.[i]?.name == 'Security Deposit') {
          //       let values = {...securityFin}
          //       values.paymentItemListId = res?.data?.data?.[i]?._id
          //       setSecurityFin(values)
          //     }
          //   }
          // }
          // setFinancialItems(values)
          // setFilteredFinancialItems(values)
        })
        .catch((err) => console.log('err', err))
    } else if (unitData?.buildingId !== undefined && unitData?.buildingId !== null) {
      ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitData.buildingId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)

          // let values: any = []
          // let x = 0
          // for (let i = 0; i < res?.data?.data?.length; i++) {
          //   if (
          //     res?.data?.data?.[i]?.name != 'Rent' &&
          //     res?.data?.data?.[i]?.name != 'Security Deposit'
          //   ) {
          //     values[x] = res?.data?.data?.[i]
          //     x++
          //   } else {
          //     if (res?.data?.data?.[i]?.name == 'Rent') {
          //       let values = {...rentFin}
          //       values.paymentItemListId = res?.data?.data?.[i]?._id
          //       setRentFin(values)
          //     }
          //     if (res?.data?.data?.[i]?.name == 'Security Deposit') {
          //       let values = {...securityFin}
          //       values.paymentItemListId = res?.data?.data?.[i]?._id
          //       setSecurityFin(values)
          //     }
          //   }
          // }
          // setFinancialItems(values)
          // setFilteredFinancialItems(values)
        })
        .catch((err) => console.log('err', err))
    }
  }

  useEffect(() => {
    getPaymentItemsList()
  }, [unitData])

  const getStatuss = (val: any) => {
    if (!val) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = val.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  
  const handleImageClick = (val: any, valSrc: any, type: any,docs:any,docType:any) => {
    const groupName = `group_${type}`

    if(docType === 'TenantDocuments' || docType === 'TenantDeletedDocs' || docType ===  'ContactDocs' || docType === 'ContactDeletedDocs' || docType === 'OnboardDeleted'){
      docs?.map((doc: any, index: any) => {
        const imgeNext = document.getElementById(`clicked${type}${index}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${doc?.fileURL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })

    }
   

    
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => onSubmitNewFlow(formData, e, false))}>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className='d-flex flex-stack pt-1 mt-0 px-0'>
                <div className=''>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        // delete clone
                        if (flg == '3' && isFormDirty == true) {
                          swal
                            .fire({
                              text: 'You are about to leave this page. Do you wish to save your updates?',
                              icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#146c6a',
                              confirmButtonText: 'Yes',
                              showCancelButton: false,
                              cancelButtonText: 'Cancel',
                              showDenyButton: true,
                              denyButtonText: 'No',
                              denyButtonColor: '#d546452',
                            })
                            .then((res: any) => {
                              if (res.isConfirmed) {
                                onSaveNewFlow(formData, 'exit', true)
                              } else if (res.isDenied) {
                                deleteClone()
                              } else {
                              }
                            })
                        } else {
                          // if (
                          //   flg != '3' &&
                          //   isFormDirty
                          //   // && !window.confirm('Changes that you made may not be saved ?')
                          // ) {
                          //   swal
                          //     .fire({
                          //       text: 'You are about to leave this page. Do you wish to save your updates?',
                          //       icon: 'warning',
                          //       showConfirmButton: true,
                          //       confirmButtonColor: '#146c6a',
                          //       confirmButtonText: 'Yes',
                          //       showCancelButton: false,
                          //       cancelButtonText: 'Cancel',
                          //       showDenyButton: true,
                          //       denyButtonText: 'No',
                          //       denyButtonColor: '#d546452',
                          //     })
                          //     .then((res: any) => {
                          //       if (res.isConfirmed) {
                          //         onSaveNewFlow(formData, 'exit', true)
                          //       } else if (res.isDenied) {
                          //         discardSession()
                          //       } else {
                          //       }
                          //     })
                          // } else
                          // navigate(`/tenants`, {
                          //   state: {
                          //     requestId:
                          //     tenantRequestId,
                          //   },
                          // })

                          navigate(`/lease-request/${tenantRequestId}/${id}/${tenancyId}/3`, {
                            state: {
                              requestId: tenantRequestId,
                            },
                          })
                        }
                      }}
                      // onClick={() => {
                      //   if (
                      //     isFormDirty &&
                      //     !window.confirm('Changes that you made may not be saved ?')
                      //   ) {
                      //     return
                      //   }
                      //   navigate(`/tenants`,{
                      //       state: {
                      //         requestId: flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId
                      //       }
                      //     })
                      // }}
                    >
                      <img src={backArrow} height='14' width='14' />
                    </span>
                    <h2 className='m-0 head-text ms-2'>{'Termination'}</h2>
                  </div>
                </div>

                <div className='d-flex align-items-center'>
                  <>
                    {flg == '3' && (
                      <button
                        type='submit'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn'
                        onClick={(e) => {
                          onSaveNewFlow(formData, e, true)
                        }}
                        disabled={isLoading || isViewMode}
                      >
                        <img
                          src={saveGreenImg}
                          height={20}
                          width={20}
                          style={{marginRight: '13px'}}
                        />{' '}
                        {'  '}Save
                      </button>
                    )}

                    {/* <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3  green-submit-btn ms-3'
                      onClick={(e: any) => {
                        onSubmitNewFlow(formData, e, false)
                      }}
                      disabled={sendError || isLoading || isViewMode}
                    >
                      <img src={submitImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                      {'  '}
                      {'Validate'}
                    </button> */}
                  </>
                </div>
              </div>
            </div>

            <div className='card card-flush py-5 mt-5 px-1'>
              <div className='row mx-1'>
                <div className='col-12 pe-5'>
                  <div className='row'>
                    <div className='col-7'>
                      <div className='d-flex row align-items-center'>
                        <div className='col-7 px-5'>
                          <h2 className='top ps-0'>
                            <b>Contract Details</b>
                          </h2>
                        </div>
                        <div className='col-4 ms-auto'>
                          <div className='d-flex  mt-5'>
                            <div className='' style={{width: '180px'}}>
                              {tenancyData?.isCancel ? (
                                <div className='status-view cancelled-st me-5'>Cancelled</div>
                              ) : (
                                <>
                                  {flg == '3' ? (
                                    <>
                                      <div className='status-view not-saved'>-</div>
                                    </>
                                  ) : (
                                    <div
                                      className={
                                        tenancyData?.isDraft
                                          ? 'status-view draft-st '
                                          : 'status-view create-st'
                                      }
                                    >
                                      {tenancyData?.isDraft ? 'Draft' : 'Created'}
                                    </div>
                                  )}
                                </>
                              )}

                              <i>
                                {' '}
                                {flg != '3' && tenancyData?.draftStatusDateTime
                                  ? `${moment(tenancyData?.draftStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div>
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-4'>
                          <div className='d-flex '>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Contract No{' '}
                              </label>
                            </h4>

                            <span className='ms-5 ps-1 my-2'>
                              <b>{tenancyData?.contractNo ? tenancyData?.contractNo : '-'}</b>
                            </span>
                          </div>
                        </div>
                        <div className='col-4 ms-auto '></div>

                        <div className='col-7 px-5 mt-2 mb-1'>
                          <div className='d-flex align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Term
                              </label>
                            </h4>
                            <div className='d-flex align-items-center'>
                              <span className='ms-5 ps-1 my-2'>
                                <b>{start_date != null ? `${start_date}  -  ` : ' -'}</b>
                              </span>

                              <span>
                                {end_date != null ? (
                                  <Controller
                                    control={control}
                                    name='start'
                                    render={({
                                      field: {onChange, onBlur, value, name, ref},
                                      fieldState: {invalid, isTouched, isDirty, error},
                                      formState,
                                    }) => (
                                      <DatePicker
                                        defaultValue={moment(
                                          end_date ? end_date : new Date(),
                                          dateFormat
                                        )}
                                        value={moment(end_date ? end_date : new Date(), dateFormat)}
                                        // {...register('start', {required: true})}
                                        format={dateFormat}
                                        onChange={(e: any) => handleChnageTenancyDate(e)}
                                        className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                        disabledDate={disabledDate}
                                        inputReadOnly
                                        disabled={isViewMode || isFinanceSubmitted}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Controller
                                    control={control}
                                    name='start'
                                    render={({
                                      field: {onChange, onBlur, value, name, ref},
                                      fieldState: {invalid, isTouched, isDirty, error},
                                      formState,
                                    }) => (
                                      <DatePicker
                                        format={dateFormat}
                                        onChange={(e: any) => handleChnageTenancyDate(e)}
                                        className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                        disabledDate={disabledDate}
                                        inputReadOnly
                                        disabled={isViewMode || isFinanceSubmitted}
                                      />
                                    )}
                                  />
                                )}
                                {/* {errors.start && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )} */}
                              </span>
                            </div>

                            {/* )} */}
                          </div>
                          <div className='d-flex'>
                            <h4 className='' style={{color: 'black'}}>
                              <label style={{width: '200px'}} className='labl-gry'></label>
                            </h4>
                            {dateError && (
                              <p style={{color: 'var(--kt-danger)'}} className='ps-2 mb-0'>
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-0 mb-1'>
                          <div className='d-flex'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Duration
                              </label>
                            </h4>
                            <span className='ms-5 ps-1 my-2 '>
                              <b>{totalDays}</b>
                            </span>
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-3 mb-1'>
                          <div className='d-flex'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Remaining
                              </label>{' '}
                            </h4>
                            <span className='ms-5 ps-1 my-2'>
                              <b>
                                {parseFloat(remainingDays.split(' ')[0]) > 0
                                  ? remainingDays
                                  : '0 days'}
                              </b>
                            </span>
                          </div>
                        </div>

                        <div className='col-8 px-5 mt-3 mb-1'>
                          <div className='d-flex align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Renewal{' '}
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                                <input
                                  type='text'
                                  className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                  placeholder=''
                                  name='renewalDays'
                                  value={tenancyData?.renewalDays}
                                  onChange={(e: any) => {
                                    const {name, value} = e.target
                                    setTenancyData({...tenancyData, [name]: value})
                                    // setIsFormDirty(true)
                                    if (Math.floor(Number(ttlDays)) < Number(e.target.value))
                                      setRenewalError(true)
                                    else setRenewalError(false)
                                  }}
                                  onBlur={() => {
                                    setAutoSaveData(Date.now())
                                  }}
                                  style={{height: '35px', width: '20%'}}
                                  disabled={isViewMode || isFinanceSubmitted}
                                />{' '}
                                <span>
                                  {' '}
                                  <i className='mt-2 ms-3'>Days before term end</i>
                                </span>
                              </>
                            ) : (
                              <span className='ms-5 ps-1 my-2'>
                                <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>{' '}
                                <i className='mt-2 ms-3'>Days before term end</i>
                              </span>
                            )}
                          </div>

                          <div className='d-flex '>
                            <h4 className='' style={{color: 'black'}}>
                              <label style={{width: '100px'}} className='labl-gry'></label>
                            </h4>
                            {renewalError && (
                              <p style={{color: 'var(--kt-danger)'}} className='ms-2'>
                                Cannot exceed total duration
                              </p>
                            )}
                          </div>
                        </div>

                        <div className='col-4'></div>

                        <>
                          <div className='col-7 ps-5 mt-3 mb-2'>
                            <div className='d-flex '>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label
                                  style={{minWidth: '90px'}}
                                  className='labl-gry '
                                  htmlFor='exampleFormControlInput1'
                                >
                                  Benefits
                                </label>
                              </h4>
                              <div className='test ms-5 ps-1 my-2'>
                                {optionArry?.none && (
                                  <div className='d-flex align-items-center'>
                                    <p className='bullet-ic mb-0'></p>
                                    <p className=' ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      None
                                    </p>
                                  </div>
                                )}

                                {optionArry?.chillerFree === true && (
                                  <div className='d-flex align-items-center'>
                                    <p className='bullet-ic mb-0'></p>
                                    <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      Chiller Free
                                    </p>
                                  </div>
                                )}

                                {optionArry?.maintenanceFree === true && (
                                  <div className='d-flex align-items-center'>
                                    <p className='bullet-ic mb-0'></p>
                                    <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      Maintenance Free
                                    </p>
                                  </div>
                                )}
                                {otherOptions?.length > 0 &&
                                  otherOptions?.map((opt: any, ind: any) => (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        {opt?.value}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </>
                      </div>

                      <div className='row mx-1 mt-5 pt-4 ms-2'>
                        <div className='col-12 ps-0'>
                          <div className='d-flex  align-items-center'>
                            <h3 className='ms-0' style={{fontWeight: '700'}}>
                              Financial Summary
                            </h3>
                          </div>
                        </div>
                        <div className='col-12 ps-0 ms-2'>
                          <div className='d-flex'>
                            <div className='test mt-2' style={{width: '-webkit-fill-available'}}>
                              <div className='row mb-3'>
                                <div className='d-flex align-items-center col-3 ps-1'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    {' '}
                                    Rent
                                  </h4>
                                </div>
                                <div className='col-4'>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        Total Amount{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>
                                        {rentFin?.totalAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div className='col-4'>
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        No. of Payment{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>{rentFin?.noOfPayment}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='d-flex align-items-center col-3 ps-1'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    {' '}
                                    Security Deposit
                                  </h4>
                                </div>
                                <div className='col-4'>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        Total Amount{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>
                                        {securityFin?.totalAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div className='col-4'>
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        No. of Payment{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>{securityFin?.noOfPayment}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {otherFinancials?.length > 0 &&
                                otherFinancials?.map((fn: any, ind: any) => (
                                  <>
                                    <div className='row mb-3'>
                                      <div className='d-flex align-items-center col-3 ps-1'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          {' '}
                                          {fn?.name}
                                        </h4>
                                      </div>
                                      <div className='col-4'>
                                        <div className='d-flex align-items-center'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label style={{minWidth: '90px'}} className='labl-gry'>
                                              {' '}
                                              Total Amount{' '}
                                            </label>
                                          </h4>

                                          <span className='ms-5 ps-1 my-2'>
                                            <b>
                                              {fn?.totalAmount?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </b>
                                          </span>
                                        </div>
                                      </div>

                                      <div className='col-4'>
                                        {' '}
                                        <div className='d-flex align-items-center'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label style={{minWidth: '90px'}} className='labl-gry'>
                                              {' '}
                                              No. of Payment{' '}
                                            </label>
                                          </h4>

                                          <span className='ms-5 ps-1 my-2'>
                                            <b>{fn?.noOfPayment}</b>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Property header */}
                    <div className='col-5'>
                      <div
                        className='card card-flush py-5 container-xxl mt-2  min-res pe-5'
                        style={
                          isEdit
                            ? {position: 'relative', zIndex: '10', opacity: '0.4'}
                            : {position: 'relative', zIndex: '10', opacity: '1'}
                        }
                      >
                        <div className='d-flex  align-items-center'>
                          <h2 className='ms-3' style={{fontWeight: '700'}}>
                            Property
                          </h2>
                          <div className='ms-auto'>
                            <img
                              src={
                                unitData?.unitType === 'town_house'
                                  ? townhouse
                                  : unitData?.unitType === 'other'
                                  ? other
                                  : unitData?.unitType === 'common_area'
                                  ? common_area
                                  : unitData?.unitType === 'villa'
                                  ? villa
                                  : unitData?.unitType === 'apartment'
                                  ? appartment
                                  : unitData?.unitType === 'penthouse'
                                  ? penthouse
                                  : ''
                              }
                              height={40}
                              className='mb-2'
                            />
                          </div>
                        </div>

                        {unitData && (
                          <>
                            <div className='row d-flex me-5 mb-5 mt-0 px-0 ms-0'>
                              {unitData?.communityId && (
                                <div className='col-12 px-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                      {' '}
                                      Development{' '}
                                    </label>
                                    <span className='ms-5 ps-1'>
                                      <b>
                                        {unitData?.communityId
                                          ? unitData?.community?.[0]?.name
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              )}
                              {unitData?.communityId == null && unitData?.buildingId && (
                                <div className='col-12 px-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                      {' '}
                                      Development{' '}
                                    </label>
                                    <span className='ms-5 ps-1'>
                                      <b>
                                        {unitData?.buildingId ? unitData?.building?.[0]?.name : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              )}

                              <div className='col-12 px-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                    {' '}
                                    Unit No{' '}
                                  </label>
                                  <span className='ms-5 ps-1'>
                                    <b>{unitData?.unitNo ? unitData?.unitNo : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              {hide !== true && (
                                <>
                                  {unitData?.clusterId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Cluster{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.clusterId
                                              ? unitData?.cluster?.[0]?.name
                                              : unitData?.building?.[0]?.name}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.unitGroupId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Unit No{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.unitGroupId
                                              ? unitData?.unit_group?.[0]?.name
                                              : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.floorId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Floor{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.floorId ? unitData?.floor?.[0]?.name : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Type{' '}
                                      </label>
                                      <span className='ms-5 ps-0'>
                                        <b>
                                          {' '}
                                          {unitData?.unitType === 'town_house'
                                            ? 'TownHouse'
                                            : unitData?.unitType === 'other'
                                            ? 'Other'
                                            : unitData?.unitType === 'common_area'
                                            ? 'Common Area'
                                            : unitData?.unitType === 'villa'
                                            ? 'Villa'
                                            : unitData?.unitType === 'apartment'
                                            ? 'Apartment'
                                            : unitData?.unitType === 'penthouse'
                                            ? 'Penthouse'
                                            : '-'}
                                        </b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Bedroom{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.bedrooms ? unitData?.bedrooms : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Size{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.size ? `${unitData?.size} SqFt` : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2 mb-0' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Property Manager{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.managerId ? unitData?.managerId : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Tenancy Documents */}
                  <div className='row mt-5 ms-2 mx-3'>
                    <div className='col-7 ps-0 pe-1 mt-5'>
                      <div className='d-flex  align-items-center'>
                        <h3 className='ms-0' style={{fontWeight: '700'}}>
                          Documents
                        </h3>
                        {isEdit && (
                          <div className='ms-auto'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold  ms-3  blue-btn'
                              disabled={isViewMode || isFinanceSubmitted}
                              onClick={() => {
                                if (newTenantId) {
                                  setFrom('contract')

                                  let temp = [...contractDocuments]
                                  localStorage.setItem('contractCopy', JSON.stringify(temp))

                                  setTenantFolderModal(true)
                                } else {
                                  ErrorToast('Please select Main Tenant..')
                                }
                              }}
                            >
                              <img
                                src={addFolder}
                                height={20}
                                width={20}
                                style={{marginRight: '7px'}}
                              />{' '}
                              Folder
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {contractDocuments?.map((doc: any, index: any) => {
                      return (
                        <>
                          {doc.isCheck && (
                            <>
                              <div className='col-7 ps-0 pe-1 mt-4'>
                                <div className='d-flex  align-items-center'>
                                  <h4 className='' style={{fontWeight: '700'}}>
                                    {doc?.name}
                                  </h4>
                                  {isEdit && (
                                    <div className='ms-auto'>
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                        disabled={isViewMode || isFinanceSubmitted}
                                        onClick={() => {
                                          setFrom('contract')
                                          setTargetFolder(doc)
                                          setTimeout(() => {
                                            setTenantFileModal(true)
                                          }, 1000)
                                        }}
                                      >
                                        <img
                                          src={invoiceAdd}
                                          height={20}
                                          width={16}
                                          style={{marginRight: '15px'}}
                                        />{' '}
                                        File
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='col-5 mt-4 ps-1'>
                                {isEdit && !isViewMode && !isFinanceSubmitted && (
                                  <img
                                    src={redCrossFilled}
                                    height={15}
                                    width={15}
                                    className='cursor-pointer ms-0 mt-3'
                                    onClick={() => {
                                      const values = [...contractDocuments]
                                      values[index].isCheck = false
                                      setContractDocuments(values)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 500)
                                    }}
                                  />
                                )}
                              </div>
                              <div className='col-12 px-0 mt-5 pt-4'>
                                {doc?.fileList?.length > 0 ? (
                                  <div
                                    className='tops d-flex gap-5 align-items-start mx-5'
                                    style={{overflow: 'auto', overflowY: 'hidden'}}
                                  >
                                    {doc?.fileList?.map((file: any, ind: any) => {
                                      return (
                                        <>
                                          {file.isChecked && (
                                            <div className='imgs-set'>
                                              <div className='d-flex mw-350px me-3'>
                                                <img
                                                  src={Bucket + file?.fileURL}
                                                  width='100'
                                                  height='100'
                                                  className='main_img mb-5 cursor-pointer'
                                                  id={`clicked${doc?.name}${ind}`}
                                                  onClick={() =>
                                                    handleImageClick(
                                                      `clicked${doc?.name}${ind}`,
                                                      `${Bucket}${file?.fileURL}`,
                                                      `${doc?.name}`,
                                                      doc?.fileList,
                                                      'ContactDocs'
                                                    )
                                                  }
                                                />
                                                {isEdit && !isViewMode && !isFinanceSubmitted ? (
                                                  <img
                                                    src={redCross}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer ms-2 mt-1'
                                                    onClick={() => {
                                                      const values = [...contractDocuments]
                                                      console.log(values)
                                                      values[index].fileList[ind].isChecked = false
                                                      setContractDocuments(values)

                                                      setTimeout(() => {
                                                        setAutoSaveData(Date.now())
                                                      }, 500)
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src={download}
                                                      height={18}
                                                      width={16}
                                                      className='cursor-pointer ms-2 mt-0'
                                                      onClick={async () => {
                                                        const link = document.createElement('a')

                                                        // Set the href attribute to the file URL
                                                        link.href = Bucket + file?.fileURL

                                                        // Set the download attribute with the desired file name
                                                        link.download = ''

                                                        // Append the anchor element to the document
                                                        document.body.appendChild(link)

                                                        // Trigger a click event on the anchor element
                                                        link.click()

                                                        // Remove the anchor element from the document
                                                        document.body.removeChild(link)
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  <div className='d-flex justify-content-center'>
                                    {' '}
                                    <div className=''>
                                      {' '}
                                      {/* <h2 className='head-text'>No Files...</h2> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}

                    {contractDeletedDocuments?.map((doc: any, index: any) => {
                      return (
                        <>
                          {doc.isCheck && (
                            <>
                              <div className='col-7 ps-0 pe-1 mt-4'>
                                <div className='d-flex  align-items-center'>
                                  <h4 className='' style={{fontWeight: '700'}}>
                                    {doc?.name}
                                  </h4>
                                </div>
                              </div>
                              <div className='col-5 mt-4 ps-1'>
                                {isEdit && !isViewMode && !isFinanceSubmitted && (
                                  <img
                                    src={redCrossFilled}
                                    height={15}
                                    width={15}
                                    className='cursor-pointer ms-0 mt-3'
                                    onClick={() => {
                                      const values = [...contractDeletedDocuments]
                                      values[index].isCheck = false
                                      setContractDeletedDocuments(values)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 500)
                                    }}
                                  />
                                )}
                              </div>
                              <div className='col-12 px-0 mt-5 pt-4'>
                                {doc?.fileList?.length > 0 ? (
                                  <div
                                    className='tops d-flex gap-5 align-items-start mx-5'
                                    style={{overflow: 'auto', overflowY: 'hidden'}}
                                  >
                                    {doc?.fileList?.map((file: any, ind: any) => {
                                      return (
                                        <>
                                          {file.isChecked && (
                                            <div className='imgs-set'>
                                              <div className='d-flex mw-350px me-3'>
                                                <img
                                                  src={Bucket + file?.fileURL}
                                                  width='100'
                                                  height='100'
                                                  className='main_img mb-5 cursor-pointer'
                                                  id={`clicked${doc?.name}${ind}`}
                                                  onClick={() =>
                                                    handleImageClick(
                                                      `clicked${doc?.name}${ind}`,
                                                      `${Bucket}${file?.fileURL}`,
                                                      `${doc?.name}`,
                                                      doc?.fileList,
                                                      'ContactDeletedDocs'
                                                    )
                                                  }
                                                />
                                                {isEdit && !isViewMode && !isFinanceSubmitted ? (
                                                  <img
                                                    src={redCross}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer ms-2 mt-1'
                                                    onClick={() => {
                                                      const values = [...contractDeletedDocuments]
                                                      console.log(values)
                                                      values[index].fileList[ind].isChecked = false
                                                      setContractDeletedDocuments(values)

                                                      setTimeout(() => {
                                                        setAutoSaveData(Date.now())
                                                      }, 500)
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src={download}
                                                      height={18}
                                                      width={16}
                                                      className='cursor-pointer ms-2 mt-0'
                                                      onClick={async () => {
                                                        const link = document.createElement('a')

                                                        // Set the href attribute to the file URL
                                                        link.href = Bucket + file?.fileURL

                                                        // Set the download attribute with the desired file name
                                                        link.download = ''

                                                        // Append the anchor element to the document
                                                        document.body.appendChild(link)

                                                        // Trigger a click event on the anchor element
                                                        link.click()

                                                        // Remove the anchor element from the document
                                                        document.body.removeChild(link)
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  <div className='d-flex justify-content-center'>
                                    {' '}
                                    <div className=''>
                                      {' '}
                                      {/* <h2 className='head-text'>No Files...</h2> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className='row mx-1 mt-5' style={isEdit ? {opacity: '0.6'} : {opacity: '1'}}>
                <div className='col-7 ps-5 mt-5'>
                  <h2 className='top ps-0 mt-3'>
                    <b>Main Tenant</b>
                  </h2>
                </div>
              </div>
              <div className='row mx-0' style={isEdit ? {opacity: '0.6'} : {opacity: '1'}}>
                <div className='col-12 ps-5 pe-0'>
                  <div className='table-responsive'>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='text-start min-w-100px'>First Name</th>
                          <th className='text-start min-w-100px'>Last Name</th>
                          <th className='text-start min-w-100px'>Passport No.</th>
                          <th className='text-start min-w-100px'>ID No.</th>
                          <th className='text-start min-w-150px'>Email </th>
                          <th className='text-start min-w-100px'>Mobile No.</th>
                          <th className='text-start min-w-100px'></th>
                        </tr>
                      </thead>

                      <tbody className='fw-semibold text-gray-600'>
                        <tr className='mt-2 head-text'>
                          <td className='text-start min-w-100px'>
                            {`${formData?.firstName}` ? `${formData?.firstName}` : '-'}
                          </td>
                          <td className='text-start min-w-100px'>
                            {`${formData?.lastName}` ? `${formData?.lastName}` : '-'}
                          </td>
                          <td className='text-start min-w-100px'>
                            {`${formData?.passportNo}` ? `${formData?.passportNo}` : '-'}
                          </td>
                          <td className='text-start min-w-100px'>
                            {`${formData?.idNo}` ? `${formData?.idNo}` : '-'}
                          </td>

                          <td className='text-start min-w-150px'>
                            {`${formData?.email}` ? `${formData?.email}` : '-'}{' '}
                          </td>
                          <td className='text-start min-w-100px'>
                            {`${formData?.phoneNumber}}`
                              ? `${formData?.countryCode} ${formData?.phoneNumber}`
                              : '-'}
                          </td>
                          <td>
                            <div className='d-flex'>
                              <img
                                src={profileGreen}
                                height={18}
                                width={18}
                                className='me-3'
                                onClick={() => {
                                  if (!isEdit) {
                                    setFrom('main')
                                    setTenantIdToView(newTenantId)
                                    setEditTenantModal(true)
                                  }
                                }}
                              />
                              <img
                                src={folderGreen}
                                height={18}
                                width={18}
                                className='me-3'
                                onClick={() => {
                                  if (!isEdit) {
                                    setFrom('tenant')
                                    setIsEditTenant(false)
                                    setTimeout(() => {
                                      setTenantDocModal(true)
                                    }, 200)
                                  }
                                }}
                              />
                              <img
                                src={chatGreen}
                                height={17}
                                width={17}
                                // className='cursor-pointer'
                                onClick={() => {
                                  // setNewMessageModal(true)
                                  if (!isEdit) {
                                    SetBodyWithSubject({subjectTitle: newTenantId})
                                    setChatSubject(newTenantId)
                                    setChatServiceJobId(newTenantId)
                                    getChatRoomForTenant(formData)
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className='row mx-1 mt-5' style={isEdit ? {opacity: '0.6'} : {opacity: '1'}}>
                <div className='col-12 ps-5 mt-3'>
                  <div className='d-flex ms-auto'>
                    <h2 className='top ps-0'>
                      <b>Sub Tenants</b>
                    </h2>
                    {/* {isEdit && (
                      <div className='d-flex ms-auto'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  me-3  select-btn'
                          onClick={() => {
                            let values = [...subTenantData]
                            let temp = subTenantData?.length

                            if (values?.length == 0) handleAddFields()
                            else {
                              console.log(values[temp])
                              if (values[values?.length - 1]?.id != '') handleAddFields()
                              else
                                ErrorToast(
                                  'The subtenant selection dropdown is already available, please select the previous dropdown to add more subtenants first.'
                                )
                            }
                          }}
                          disabled={sendError}
                        >
                          <img
                            src={addWhite}
                            height={20}
                            width={20}
                            style={{marginRight: '10px'}}
                          />{' '}
                          Add
                        </button>

                        <button
                          type='button'
                          className='btn btn-sm fw-bold green-submit-btn'
                          onClick={() => {
                            setFrom('sub')
                            setAddNewTenant(true)
                          }}
                          style={{width: '160px'}}
                          disabled={sendError}
                        >
                          <img src={profileWhite} height={20} width={20} style={{margin: '5px'}} />{' '}
                          Add New Tenant
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className='row mx-0' style={isEdit ? {opacity: '0.6'} : {opacity: '1'}}>
                <div className='col-12 ps-5 pe-0'>
                  <div className='table-responsive' style={{overflow: 'unset'}}>
                    <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                          <th className='text-start min-w-100px'>First Name</th>
                          <th className='text-start min-w-100px'>Last Name</th>
                          <th className='text-start min-w-100px'>Passport No.</th>
                          <th className='text-start min-w-100px'>ID No.</th>
                          <th className='text-start min-w-150px'>Email </th>
                          <th className='text-start min-w-100px'>Mobile No.</th>
                          <th className='text-start min-w-100px'></th>
                          {isEdit && <th className='text-start min-w-25px'></th>}
                        </tr>
                      </thead>

                      <tbody className='fw-semibold text-gray-600'>
                        {subTenantData?.length ? (
                          subTenantData.map((sb: any, i: any) => {
                            return (
                              <>
                                {tenanatData &&
                                  tenanatData.map((pI: any, index: any) => {
                                    if (sb?.id == pI._id) itemInd = index
                                    return <></>
                                  })}

                                {sb?.id != '' && (
                                  <tr className='mt-3 head-text'>
                                    <td className='text-start min-w-100px'>
                                      {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {`${sb?.passportNo}` ? `${sb?.passportNo}` : '-'}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {`${sb?.idNo}` ? `${sb?.idNo}` : '-'}
                                    </td>
                                    <td className='text-start min-w-150px'>
                                      {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {`${sb?.phoneNumber}}`
                                        ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                        : '-'}
                                    </td>
                                    <td
                                      className='text-start min-w-100px'
                                      //   style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}
                                    >
                                      <div className='d-flex'>
                                        <img
                                          src={profileGreen}
                                          height={18}
                                          width={18}
                                          className='me-3'
                                          onClick={() => {
                                            if (!isEdit) {
                                              setFrom('sub')
                                              setSubInd(i)
                                              setTenantIdToView(sb?.id)
                                              setEditTenantModal(true)
                                            }
                                          }}
                                        />
                                        {/* <img
                                          src={folderGreen}
                                          height={18}
                                          width={18}
                                          className='me-3'
                                          onClick={() => {
                                            if (!isEdit) {
                                              setFrom('sub')
                                              setSubInd(i)
                                              setTenantIdToView(sb?.id)
                                              setEditTenantModal(true)
                                            }
                                          }}
                                        /> */}
                                        <img
                                          src={chatGreen}
                                          height={17}
                                          width={17}
                                          className=''
                                          onClick={() => {
                                            if (!isEdit) {
                                              SetBodyWithSubject({subjectTitle: sb?.id})
                                              setChatSubject(sb?.id)
                                              setChatServiceJobId(sb?.id)
                                              getChatRoomForTenant(sb)
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                    {/* {isEdit && (
                                      <td>
                                        {' '}
                                        <img
                                          src={redCross}
                                          height={16}
                                          width={16}
                                          style={{cursor: 'pointer'}}
                                          className='me-5'
                                          onClick={() => {
                                            swal
                                              .fire({
                                                text: 'Are you sure you want to remove this tenant from Sub Tenants List ?',
                                                icon: 'warning',
                                                showConfirmButton: true,
                                                confirmButtonColor: '#D72852',
                                                confirmButtonText: 'Yes',
                                                showCancelButton: true,
                                                // cancelButtonColor: "transparent",
                                                cancelButtonText: 'Cancel',
                                              })
                                              .then((res) => {
                                                if (res.isConfirmed) {
                                                  let temp = [...subTenantIds]
                                                  temp.splice(i, 1)
                                                  setSubTenantIds(temp)

                                                  let values = [...subTenantData]
                                                  values.splice(i, 1)
                                                  setSubTenantData(values)

                                                  setIsFormDirty(true)
                                                }
                                              })
                                          }}
                                        />{' '}
                                      </td>
                                    )} */}
                                  </tr>
                                )}
                              </>
                            )
                          })
                        ) : (
                          <>
                            <tr>
                              <td colSpan={10} className='text-center'>
                                <img src={noData} alt='' width={350} />
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* Final Settlement */}
              <>
                <div
                  className='row mx-4 mt-5'
                  style={
                    end_date == null || isViewMode || isFinanceSubmitted
                      ? {opacity: '0.7'}
                      : {opacity: '1'}
                  }
                >
                  <div className='col-12 px-4 mt-5'>
                    <div className='d-flex align-items-center'>
                      <h2 className='top ps-0'>
                        <b>Final Settlement</b>
                      </h2>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold green-submit-btn ms-auto status-w-170'
                        onClick={() => handleAddTerminationPaymentList()}
                        disabled={isViewMode || isFinanceSubmitted || end_date == null}
                      >
                        <img src={addWhite} height={16} width={16} style={{marginRight: '10px'}} />{' '}
                        Add New Payment
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className='row mx-4 mt-3 mb-5'
                  style={
                    end_date == null || isViewMode || isFinanceSubmitted
                      ? {opacity: '0.7'}
                      : {opacity: '1'}
                  }
                >
                  <div className='col-12 px-3 mt-3 mb-3'>
                    {terminatePaymentList?.length > 0 ? (
                      <>
                        <div
                          className='card px-3 test'
                          style={{boxShadow: 'lightgrey 0px 4px 6px 5px'}}
                        >
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-middle table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  {/* <th className='min-w-75px'>#</th> */}
                                  <th className='min-w-125px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-125px'>Due</th>
                                  <th className='min-w-125px'>Amount Paid</th>
                                  <th className='min-w-125px'>Outstanding</th>
                                  <th className='min-w-100px'>
                                    Refund{' '}
                                    <span className='ms-3'>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to refund from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                  <th className='min-w-125px'>
                                    Cancel
                                    <span className='ms-3'>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to cancel from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                  <th className='min-w-125px'>
                                    Collect
                                    <span className='ms-3'>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to collect from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                {terminatePaymentList?.map((tpl: any, ind: any) => {
                                  let refund = 0
                                  let cancel = 0
                                  let collect = 0
                                  for (let i = 0; i < tpl?.payment_sub_item?.length; i++) {
                                    refund =
                                      Number(refund) +
                                      Number(tpl?.payment_sub_item[i]?.terminationReduction?.refund)
                                    collect =
                                      Number(collect) +
                                      Number(
                                        tpl?.payment_sub_item[i]?.terminationReduction?.collect
                                      )
                                    cancel =
                                      Number(cancel) +
                                      Number(tpl?.payment_sub_item[i]?.terminationReduction?.cancel)
                                  }
                                  return (
                                    <>
                                      {tpl?.id !== '' && tpl?.id !== null ? (
                                        <>
                                          <tr
                                            style={
                                              show[ind]
                                                ? {
                                                    borderBottomStyle: 'none',
                                                    height: '30px',
                                                    fontWeight: '700',
                                                  }
                                                : !show[ind] || tpl?.payment_sub_item?.length === 0
                                                ? {
                                                    borderBottomStyle: 'dashed',
                                                    height: '30px',
                                                    fontWeight: '700',
                                                  }
                                                : {height: '30px', fontWeight: '700'}
                                            }
                                            className='head-text'
                                            key={ind}
                                          >
                                            {/* <td>
                                              <div className='d-flex align-items-center'>
                                                {tpl?.payment_sub_item?.length > 0 && (
                                                  <img
                                                    src={show[ind] ? closeImg : openImg}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer'
                                                    style={{marginRight: '10px'}}
                                                    onClick={() => {
                                                      if (show[ind] == true) {
                                                        let values = [...show]
                                                        values[ind] = false
                                                        setShow(values)
                                                      } else {
                                                        let values = [...show]
                                                        values[ind] = true
                                                        setShow(values)
                                                      }
                                                    }}
                                                  />
                                                )}{' '}
                                                {tpl?.id}
                                              </div>
                                            </td> */}
                                            <td className='py-2'>
                                              <div className='d-flex align-items-center'>
                                                {tpl?.name}
                                              </div>
                                            </td>

                                            <td className='py-2'>
                                              {parseFloat(tpl?.totalAmount)?.toLocaleString(
                                                'en-US',
                                                {
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </td>

                                            <td className='py-2'>
                                              {tpl?.paymentStatus === 0
                                                ? 'Unpaid'
                                                : tpl?.paymentStatus === 1
                                                ? 'Paid'
                                                : tpl?.paymentStatus === 2
                                                ? 'Partially paid'
                                                : tpl?.paymentStatus === 3
                                                ? 'Void'
                                                : 'Draft'}
                                            </td>

                                            <td className='py-2'>
                                              {tpl?.dueDate
                                                ? moment(tpl?.dueDate).format('DD.MM.YYYY')
                                                : '-'}
                                            </td>

                                            <td className='py-2'>
                                              {tpl?.amountPaid?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-2'>
                                              {tpl?.outstandingAmount <= 0 ? '' : '-'}
                                              {parseFloat(
                                                getPositive(tpl?.outstandingAmount)
                                              )?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-2'>
                                              {refund?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-2'>
                                              {cancel?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-2'>
                                              {collect?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                          </tr>
                                          {tpl?.payment_sub_item?.length > 0 && show[ind] ? (
                                            tpl?.payment_sub_item?.map(
                                              (pmSub: any, subIndex: any) => {
                                                return (
                                                  <>
                                                    <tr key={subIndex}>
                                                      {/* <td style={{paddingLeft: '10px'}}>
                                                        {tpl?.id}
                                                        {'_'}
                                                        {pmSub?.id}
                                                      </td> */}
                                                      <td className='py-1'>
                                                        <div className='d-flex align-items-center'>
                                                          <p className='bullet-item mb-0'></p>
                                                          <p
                                                            className=' ms-3 mb-0'
                                                            style={{fontWeight: '600'}}
                                                          >
                                                            {pmSub?.name}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td className='py-1'>
                                                        {pmSub?.totalAmount?.toLocaleString(
                                                          'en-US',
                                                          {
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </td>

                                                      <td className='py-1'>
                                                        {pmSub.paymentStatus === 0
                                                          ? 'Unpaid'
                                                          : pmSub.paymentStatus === 1
                                                          ? 'Paid'
                                                          : pmSub.paymentStatus === 2
                                                          ? 'Partially paid'
                                                          : pmSub.paymentStatus === 3
                                                          ? 'Void'
                                                          : 'Draft'}
                                                      </td>
                                                      <td></td>
                                                      <td className='py-1'>
                                                        {pmSub?.amountPaid?.toLocaleString(
                                                          'en-US',
                                                          {
                                                            minimumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </td>
                                                      <td className='py-1'>
                                                        {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                                        {parseFloat(
                                                          getPositive(pmSub?.outstandingAmount)
                                                        )?.toLocaleString('en-US', {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                      <td className='py-1'>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder=''
                                                          name={`refund${subIndex}`}
                                                          value={
                                                            pmSub?.terminationReduction?.refund
                                                              ? pmSub?.terminationReduction?.refund
                                                              : 0
                                                          }
                                                          // onChange={(e: any) => {
                                                          //   const values = [...incomePaymentPayload]

                                                          //   const newValue = e.target.value.replace(
                                                          //     /^0+/,
                                                          //     ''
                                                          //   )
                                                          //   if (
                                                          //     Number(newValue) <=
                                                          //     values[ind].new_payment_sub_item[subIndex]
                                                          //       ?.amountPaid
                                                          //   ) {

                                                          //     let newRefund = 0

                                                          //     for (
                                                          //       let i = 0;
                                                          //       i < pm?.new_payment_sub_item?.length;
                                                          //       i++
                                                          //     ) {

                                                          //       if (
                                                          //         pmSub?._id !=
                                                          //         pm?.new_payment_sub_item[i]?._id
                                                          //       ) {
                                                          //         values[ind].new_payment_sub_item[
                                                          //         i
                                                          //         ].terminationReduction.refund =
                                                          //           newValue === '' ? 0 : newValue
                                                          //         values[ind].new_payment_sub_item[
                                                          //         i
                                                          //         ].terminationReduction.isUpdated = true
                                                          //       }

                                                          //       newRefund =
                                                          //         Number(newRefund) +
                                                          //         Number(
                                                          //           pm?.new_payment_sub_item[i]
                                                          //             ?.terminationReduction?.refund
                                                          //         )

                                                          //     }
                                                          //     values[
                                                          //       ind
                                                          //     ].payment.terminationReduction.refund = newRefund
                                                          //     values[
                                                          //       ind
                                                          //     ].payment.terminationReduction.isUpdated =
                                                          //       true
                                                          //     console.log(values)

                                                          //     setIncomePaymentPayload(values)
                                                          //   } else {
                                                          //     ErrorToast(
                                                          //       'You can not refund amount greater than amount paid..'
                                                          //     )
                                                          //   }
                                                          // }}
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            if (
                                                              Number(newValue) <=
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ]?.amountPaid
                                                            ) {
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.refund =
                                                                newValue === '' ? 0 : newValue
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.isUpdated = true

                                                              // ...
                                                              let newRefund = 0

                                                              for (
                                                                let i = 0;
                                                                i < tpl?.payment_sub_item?.length;
                                                                i++
                                                              ) {
                                                                newRefund =
                                                                  Number(newRefund) +
                                                                  Number(
                                                                    tpl?.payment_sub_item[i]
                                                                      ?.terminationReduction?.refund
                                                                  )
                                                              }
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.refund = newRefund
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.isUpdated = true

                                                              if (
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                ) > 0
                                                              ) {
                                                                //   if( newValue > 0){
                                                                //   let temp = Number(updatedPayload[ind].new_payment_sub_item[
                                                                //         subIndex
                                                                //       ].terminationReduction.collect) - Number(newValue)
                                                                //       if(temp > 0)
                                                                //       updatedPayload[ind].new_payment_sub_item[
                                                                //         subIndex
                                                                //       ].terminationReduction.collect = temp
                                                                //       else
                                                                //       updatedPayload[ind].new_payment_sub_item[
                                                                //         subIndex
                                                                //       ].terminationReduction.collect = 0
                                                                //     }
                                                                //     else{
                                                                //    updatedPayload[ind].new_payment_sub_item[
                                                                //     subIndex
                                                                //   ].terminationReduction.collect = updatedPayload[ind].new_payment_sub_item[subIndex].outstandingAmount - Number(updatedPayload[ind].new_payment_sub_item[
                                                                //     subIndex
                                                                //   ].terminationReduction.collect)
                                                                // }
                                                              }

                                                              if (
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                ) > 0
                                                              ) {
                                                                let outstand = getNegative(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                )
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect = getPositive(
                                                                  Number(outstand) +
                                                                    Number(
                                                                      updatedPayload[ind]
                                                                        ?.payment_sub_item?.[
                                                                        subIndex
                                                                      ].terminationReduction.cancel
                                                                    ) +
                                                                    Number(newValue)
                                                                )
                                                              } else {
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect =
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount -
                                                                  Number(newValue)
                                                              }

                                                              setIncomePaymentPayload(
                                                                updatedPayload
                                                              )
                                                            } else {
                                                              ErrorToast(
                                                                'You can not refund amount greater than amount paid..'
                                                              )
                                                            }
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(tpl)
                                                            }, 1000)
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />{' '}
                                                      </td>

                                                      <td className='py-1'>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder=''
                                                          name='refund'
                                                          value={
                                                            pmSub?.terminationReduction?.cancel
                                                              ? pmSub?.terminationReduction?.cancel
                                                              : 0
                                                          }
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            if (
                                                              Number(newValue) <=
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ]?.totalAmount
                                                            ) {
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.cancel =
                                                                newValue === '' ? 0 : newValue
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.isUpdated = true

                                                              let newCancel = 0
                                                              for (
                                                                let i = 0;
                                                                i < tpl?.payment_sub_item?.length;
                                                                i++
                                                              ) {
                                                                newCancel =
                                                                  Number(newCancel) +
                                                                  Number(
                                                                    tpl?.payment_sub_item[i]
                                                                      ?.terminationReduction?.cancel
                                                                  )
                                                              }
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.cancel = newCancel
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.isUpdated = true

                                                              if (
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                ) > 0
                                                              ) {
                                                                let outstand = getNegative(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                )
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect = getPositive(
                                                                  Number(outstand) +
                                                                    Number(
                                                                      updatedPayload[ind]
                                                                        ?.payment_sub_item?.[
                                                                        subIndex
                                                                      ].terminationReduction.refund
                                                                    ) +
                                                                    Number(newValue)
                                                                )
                                                              } else {
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect =
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount -
                                                                  Number(newValue)
                                                              }
                                                              setIncomePaymentPayload(
                                                                updatedPayload
                                                              )
                                                            } else {
                                                              ErrorToast(
                                                                'You can not cancel amount greater than total amount..'
                                                              )
                                                            }
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(tpl)
                                                            }, 1000)
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />
                                                      </td>
                                                      <td className='py-1'>
                                                        {pmSub?.terminationReduction?.collect
                                                          ? pmSub?.terminationReduction?.collect
                                                          : 0}
                                                        {/* <input
                                                      type='number'
                                                      className='form-control form-control-solid mytest tenancy-control ps-2 my-2'
                                                      placeholder=''
                                                      name='refund'
                                                      value={
                                                        pmSub?.terminationReduction?.collect
                                                          ? pmSub?.terminationReduction?.collect
                                                          : 0
                                                      }
                                                      onChange={(e: any) => {
                                                        const newValue = e.target.value.replace(
                                                          /^0+/,
                                                          ''
                                                        )
                                                        const values = [...incomePaymentPayload]
                                                        let temp: any =
                                                          values[ind]?.new_payment_sub_item[
                                                            subIndex
                                                          ]?.terminationReduction

                                                        // if (
                                                        //   values[ind].new_payment_sub_item[subIndex]
                                                        //     .terminationReduction.collect == undefined
                                                        // ) {
                                                        //   values[ind].new_payment_sub_item[subIndex] = {
                                                        //     ...values[ind].new_payment_sub_item[
                                                        //       subIndex
                                                        //     ],
                                                        //     terminationReduction: {
                                                        //       ...temp,
                                                        //       collect: newValue === '' ? 0 : newValue,
                                                        //       isUpdated: true,
                                                        //     },
                                                        //   }
                                                        // }
                                                        // else {
                                                        values[ind].new_payment_sub_item[
                                                          subIndex
                                                        ].terminationReduction.collect =
                                                          newValue === '' ? 0 : newValue
                                                        values[ind].new_payment_sub_item[
                                                          subIndex
                                                        ].terminationReduction.isUpdated = true
                                                        let newCollect = 0

                                                        for (
                                                          let i = 0;
                                                          i < pm?.new_payment_sub_item?.length;
                                                          i++
                                                        ) {
                                                          newCollect =
                                                            Number(newCollect) +
                                                            Number(
                                                              pm?.new_payment_sub_item[i]
                                                                ?.terminationReduction?.collect
                                                            )
                                                        }
                                                        values[
                                                          ind
                                                        ].payment.terminationReduction.collect = newCollect
                                                        values[
                                                          ind
                                                        ].payment.terminationReduction.isUpdated = true
                                                        // }
                                                        setIncomePaymentPayload(values)
                                                      }}
                                                      onBlur={() => {
                                                        setSaveMainTermReduction(pm?.payment)
                                                        setSaveTermReduction(pmSub)
                                                      }}
                                                      style={{height: '35px', width: '90%'}}
                                                      disabled={isViewMode || end_date == null}
                                                    />*/}
                                                      </td>
                                                    </tr>
                                                  </>
                                                )
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <tr
                                            style={
                                              show[ind]
                                                ? {
                                                    borderBottomStyle: 'none',
                                                    height: '30px',
                                                    fontWeight: '700',
                                                  }
                                                : !show[ind] || tpl?.payment_sub_item?.length === 0
                                                ? {
                                                    borderBottomStyle: 'dashed',
                                                    height: '30px',
                                                    fontWeight: '700',
                                                  }
                                                : {height: '30px', fontWeight: '700'}
                                            }
                                            className='head-text'
                                            key={ind}
                                          >
                                            {/* <td>
                                              <div className='d-flex align-items-center'>
                                                {tpl?.payment_sub_item?.length > 0 && (
                                                  <img
                                                    src={show[ind] ? closeImg : openImg}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer'
                                                    style={{marginRight: '10px'}}
                                                    onClick={() => {
                                                      if (show[ind] == true) {
                                                        let values = [...show]
                                                        values[ind] = false
                                                        setShow(values)
                                                      } else {
                                                        let values = [...show]
                                                        values[ind] = true
                                                        setShow(values)
                                                      }
                                                    }}
                                                  />
                                                )}{' '}
                                                {tpl?.id}
                                              </div>
                                            </td> */}
                                            <td className='py-1'>
                                              <div className='d-flex align-items-center'>
                                                <input
                                                  type='text'
                                                  className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                  placeholder='Payment Name'
                                                  name={`payment-name${ind}`}
                                                  value={tpl?.name}
                                                  onChange={(e: any) => {
                                                    const updatedPayload = [...terminatePaymentList]
                                                    updatedPayload[ind].name = e.target.value
                                                    setTerminatePaymentList(updatedPayload)
                                                  }}
                                                  onBlur={() => {
                                                    setTimeout(() => {
                                                      setSaveMainTermReduction(Date.now())
                                                    }, 1000)
                                                  }}
                                                  style={{height: '35px', width: '90%'}}
                                                  disabled={
                                                    isViewMode ||
                                                    isFinanceSubmitted ||
                                                    end_date == null
                                                  }
                                                />{' '}
                                              </div>
                                            </td>

                                            <td className='py-1'>
                                              {parseFloat(tpl?.totalAmount)?.toLocaleString(
                                                'en-US',
                                                {
                                                  minimumFractionDigits: 2,
                                                }
                                              )}
                                            </td>

                                            <td className='py-1'>
                                              {tpl?.paymentStatus === 0
                                                ? 'Unpaid'
                                                : tpl?.paymentStatus === 1
                                                ? 'Paid'
                                                : tpl?.paymentStatus === 2
                                                ? 'Partially paid'
                                                : tpl?.paymentStatus === 3
                                                ? 'Void'
                                                : tpl?.paymentStatus === 4
                                                ? 'Draft'
                                                : '-'}
                                            </td>

                                            <td className='py-1'>
                                              {tpl?.dueDate == null ? (
                                                <DatePicker
                                                  onChange={(date: any) => {
                                                    let temp = getStatuss(date)
                                                    const updatedPayload = [...terminatePaymentList]
                                                    if (date != null)
                                                      updatedPayload[ind].dueDate =
                                                        date.format('YYYY-MM-DD')
                                                    else updatedPayload[ind].dueDate = null

                                                    if (temp == 'Upcoming')
                                                      updatedPayload[ind].categories = 0
                                                    else updatedPayload[ind].categories = 1
                                                    setTerminatePaymentList(updatedPayload)

                                                    setTimeout(() => {
                                                      setSaveMainTermReduction(Date.now())
                                                    }, 1000)
                                                  }}
                                                  className={tpl?.dueDate != null ? 'mytest' : ''}
                                                  disabled={isViewMode ||
                                                    isFinanceSubmitted ||
                                                    end_date == null }
                                                />
                                              ) : (
                                                <DatePicker
                                                  onChange={(date: any) => {
                                                    let temp = getStatuss(date)
                                                    const updatedPayload = [...terminatePaymentList]
                                                    if (date != null)
                                                      updatedPayload[ind].dueDate =
                                                        date.format('YYYY-MM-DD')
                                                    else updatedPayload[ind].dueDate = null

                                                    if (temp == 'Upcoming')
                                                      updatedPayload[ind].categories = 0
                                                    else updatedPayload[ind].categories = 1
                                                    setTerminatePaymentList(updatedPayload)

                                                    setTimeout(() => {
                                                      setSaveMainTermReduction(Date.now())
                                                    }, 1000)
                                                  }}
                                                  defaultValue={moment(
                                                    new Date(tpl?.dueDate),
                                                    'YYYY-MM-DD'
                                                  )}
                                                  className={tpl?.dueDate != null ? 'mytest' : ''}
                                                  disabled={isViewMode ||
                                                    isFinanceSubmitted ||
                                                    end_date == null}
                                                />
                                              )}
                                            </td>

                                            <td className='py-1'>
                                              {tpl?.amountPaid?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-1'>
                                              {tpl?.outstandingAmount <= 0 ? '' : '-'}
                                              {parseFloat(
                                                getPositive(tpl?.outstandingAmount)
                                              )?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-1'>
                                              {refund?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-1'>
                                              {cancel?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-1'>
                                              {collect?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </td>
                                            <td className='py-1'>
                                              <img
                                                src={trashImg}
                                                className='cursor-pointer'
                                                height={17}
                                                width={17}
                                                onClick={() =>
                                                  handleRemoveTerminationPaymentList(ind)
                                                }
                                              />
                                            </td>
                                          </tr>
                                          {tpl?.payment_sub_item?.length > 0 && show[ind] ? (
                                            tpl?.payment_sub_item?.map(
                                              (pmSub: any, subIndex: any) => {
                                                return (
                                                  <>
                                                    <tr
                                                      key={subIndex}
                                                      style={{
                                                        borderBottomStyle: 'none',
                                                        height: '30px',
                                                        fontWeight: '700',
                                                      }}
                                                    >
                                                      {/* <td style={{paddingLeft: '10px'}}>
                                                        {tpl?.id}
                                                        {'_'}
                                                        {pmSub?.id}
                                                      </td> */}
                                                      <td className='tst py-1'>
                                                        {filteredPaymentItems &&
                                                          filteredPaymentItems.map(
                                                            (pI: any, index: any) => {
                                                              if (pmSub?.name == pI.name)
                                                                itemInd = index
                                                              return <></>
                                                            }
                                                          )}{' '}
                                                        {pmSub?.name?.length > 0 ? (
                                                          <Select
                                                            options={
                                                              filteredPaymentItems &&
                                                              filteredPaymentItems?.map(
                                                                (pItem: any, index: any) => {
                                                                  return {
                                                                    label: `${pItem?.name}`,
                                                                    value: `${pItem?._id}`,
                                                                    id: pItem,
                                                                  }
                                                                }
                                                              )
                                                            }
                                                            defaultValue={{
                                                              label:
                                                                filteredPaymentItems[itemInd]?.name,
                                                              value:
                                                                filteredPaymentItems[itemInd]?._id,
                                                              id: filteredPaymentItems[itemInd],
                                                            }}
                                                            value={{
                                                              label:
                                                                filteredPaymentItems[itemInd]?.name,
                                                              value:
                                                                filteredPaymentItems[itemInd]?._id,
                                                              id: filteredPaymentItems[itemInd],
                                                            }}
                                                            styles={customStylesInputFilled}
                                                            isSearchable={false}
                                                            // defaultValue={paymentItems[itemInd]}
                                                            onChange={(event: any) => {
                                                              console.log(event)
                                                              const updatedPayload = [
                                                                ...terminatePaymentList,
                                                              ]
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].name = event.label
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentItemListId =
                                                                event.label.paymentItemListId
                                                              setTerminatePaymentList(
                                                                updatedPayload
                                                              )

                                                              setTimeout(() => {
                                                                setSaveMainTermReduction(Date.now())
                                                              }, 1000)
                                                            }}
                                                            isDisabled={isViewMode ||
                                                              isFinanceSubmitted ||
                                                              end_date == null}
                                                          />
                                                        ) : (
                                                          <Select
                                                            options={
                                                              filteredPaymentItems &&
                                                              filteredPaymentItems?.map(
                                                                (pItem: any, index: any) => {
                                                                  return {
                                                                    label: `${pItem?.name}`,
                                                                    value: `${pItem?._id}`,
                                                                    id: pItem,
                                                                  }
                                                                }
                                                              )
                                                            }
                                                            styles={customStyles}
                                                            isSearchable={false}
                                                            onChange={(event: any) => {
                                                              console.log(event)
                                                              const updatedPayload = [
                                                                ...terminatePaymentList,
                                                              ]
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].name = event.label
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentItemListId =
                                                                event.label.paymentItemListId
                                                              setTerminatePaymentList(
                                                                updatedPayload
                                                              )

                                                              setTimeout(() => {
                                                                setSaveMainTermReduction(Date.now())
                                                              }, 1000)
                                                            }}
                                                            isDisabled={isViewMode ||
                                                              isFinanceSubmitted ||
                                                              end_date == null}
                                                          />
                                                        )}
                                                      </td>
                                                      <td className='py-1'>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder='Amount'
                                                          name='payment-amount'
                                                          value={pmSub?.totalAmount}
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            updatedPayload[ind].payment_sub_item[
                                                              subIndex
                                                            ].totalAmount =
                                                              newValue === '' ? 0 : newValue
                                                            updatedPayload[ind].payment_sub_item[
                                                              subIndex
                                                            ].outstandingAmount =
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .totalAmount
                                                              ) -
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              )

                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.collect =
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .totalAmount
                                                                ) -
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .amountPaid
                                                                )

                                                            // Sub item payment status
                                                            if (
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              ) == 0
                                                            )
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 0
                                                            else if (
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              ) >=
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .totalAmount
                                                              )
                                                            )
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 1
                                                            else
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 2

                                                            let total: any = 0
                                                            for (
                                                              let i = 0;
                                                              i <
                                                              updatedPayload[ind].payment_sub_item
                                                                ?.length;
                                                              i++
                                                            ) {
                                                              total =
                                                                Number(total) +
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[i].totalAmount
                                                                )
                                                            }
                                                            updatedPayload[ind].totalAmount =
                                                              Number(total)

                                                            // payment status
                                                            if (updatedPayload[ind].amountPaid == 0)
                                                              updatedPayload[ind].paymentStatus = 0
                                                            else if (
                                                              updatedPayload[ind].amountPaid >=
                                                              updatedPayload[ind].totalAmount
                                                            )
                                                              updatedPayload[ind].paymentStatus = 1
                                                            else
                                                              updatedPayload[ind].paymentStatus = 2

                                                            updatedPayload[ind].outstandingAmount =
                                                              updatedPayload[ind].totalAmount -
                                                              updatedPayload[ind].amountPaid

                                                            setTerminatePaymentList(updatedPayload)
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(Date.now())
                                                            }, 1000)
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />{' '}
                                                      </td>

                                                      <td className='py-1'>
                                                        {pmSub.paymentStatus === 0
                                                          ? 'Unpaid'
                                                          : pmSub.paymentStatus === 1
                                                          ? 'Paid'
                                                          : pmSub.paymentStatus === 2
                                                          ? 'Partially paid'
                                                          : pmSub.paymentStatus === 3
                                                          ? 'Void'
                                                          : 'Draft'}
                                                      </td>
                                                      <td></td>
                                                      <td>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder='Amount'
                                                          name='payment-amount'
                                                          value={pmSub?.amountPaid}
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            updatedPayload[ind].payment_sub_item[
                                                              subIndex
                                                            ].amountPaid =
                                                              newValue === '' ? 0 : newValue
                                                            updatedPayload[ind].payment_sub_item[
                                                              subIndex
                                                            ].outstandingAmount =
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .totalAmount
                                                              ) -
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              )

                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.collect =
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .totalAmount
                                                                ) -
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .amountPaid
                                                                )

                                                            if (
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              ) == 0
                                                            )
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 0
                                                            else if (
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .amountPaid
                                                              ) >=
                                                              Number(
                                                                updatedPayload[ind]
                                                                  .payment_sub_item[subIndex]
                                                                  .totalAmount
                                                              )
                                                            )
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 1
                                                            else
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].paymentStatus = 2

                                                            let total: any = 0
                                                            for (
                                                              let i = 0;
                                                              i <
                                                              updatedPayload[ind].payment_sub_item
                                                                ?.length;
                                                              i++
                                                            ) {
                                                              total =
                                                                Number(total) +
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[i].amountPaid
                                                                )
                                                            }
                                                            updatedPayload[ind].amountPaid =
                                                              Number(total)

                                                            // payment status
                                                            if (updatedPayload[ind].amountPaid == 0)
                                                              updatedPayload[ind].paymentStatus = 0
                                                            else if (
                                                              updatedPayload[ind].amountPaid >=
                                                              updatedPayload[ind].totalAmount
                                                            )
                                                              updatedPayload[ind].paymentStatus = 1
                                                            else
                                                              updatedPayload[ind].paymentStatus = 2

                                                            updatedPayload[ind].outstandingAmount =
                                                              updatedPayload[ind].totalAmount -
                                                              updatedPayload[ind].amountPaid

                                                            setTerminatePaymentList(updatedPayload)
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(Date.now())
                                                            }, 1000)
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            pmSub?.totalAmount == 0 ||
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />{' '}
                                                      </td>
                                                      <td className='py-1'>
                                                        {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                                        {parseFloat(
                                                          getPositive(pmSub?.outstandingAmount)
                                                        )?.toLocaleString('en-US', {
                                                          minimumFractionDigits: 2,
                                                        })}
                                                      </td>
                                                      <td className='py-1'>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder=''
                                                          name={`refund${subIndex}`}
                                                          value={
                                                            pmSub?.terminationReduction?.refund
                                                              ? pmSub?.terminationReduction?.refund
                                                              : 0
                                                          }
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            if (
                                                              Number(newValue) <=
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ]?.amountPaid
                                                            ) {
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.refund =
                                                                newValue === '' ? 0 : newValue
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.isUpdated = true

                                                              // ...
                                                              let newRefund = 0

                                                              for (
                                                                let i = 0;
                                                                i < tpl?.payment_sub_item?.length;
                                                                i++
                                                              ) {
                                                                newRefund =
                                                                  Number(newRefund) +
                                                                  Number(
                                                                    tpl?.payment_sub_item[i]
                                                                      ?.terminationReduction?.refund
                                                                  )
                                                              }
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.refund = newRefund
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.isUpdated = true

                                                              if (
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                ) > 0
                                                              ) {
                                                                let outstand = getNegative(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                )
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect = getPositive(
                                                                  Number(outstand) +
                                                                    Number(
                                                                      updatedPayload[ind]
                                                                        ?.payment_sub_item?.[
                                                                        subIndex
                                                                      ].terminationReduction.cancel
                                                                    ) +
                                                                    Number(newValue)
                                                                )
                                                              } else {
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect =
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount -
                                                                  Number(newValue)
                                                              }

                                                              setIncomePaymentPayload(
                                                                updatedPayload
                                                              )
                                                            } else {
                                                              ErrorToast(
                                                                'You can not refund amount greater than amount paid..'
                                                              )
                                                            }
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(Date.now())
                                                            }, 1000)
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />{' '}
                                                      </td>
                                                      <td className='py-1'>
                                                        <input
                                                          type='number'
                                                          className='form-control form-control-solid mytest tenancy-control ps-2 my-0'
                                                          placeholder=''
                                                          name='refund'
                                                          value={
                                                            pmSub?.terminationReduction?.cancel
                                                              ? pmSub?.terminationReduction?.cancel
                                                              : 0
                                                          }
                                                          onChange={(e: any) => {
                                                            const newValue = e.target.value.replace(
                                                              /^0+/,
                                                              ''
                                                            )
                                                            const updatedPayload = [
                                                              ...terminatePaymentList,
                                                            ]
                                                            if (
                                                              Number(newValue) <=
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ]?.totalAmount
                                                            ) {
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.cancel =
                                                                newValue === '' ? 0 : newValue
                                                              updatedPayload[ind].payment_sub_item[
                                                                subIndex
                                                              ].terminationReduction.isUpdated = true

                                                              let newCancel = 0
                                                              for (
                                                                let i = 0;
                                                                i < tpl?.payment_sub_item?.length;
                                                                i++
                                                              ) {
                                                                newCancel =
                                                                  Number(newCancel) +
                                                                  Number(
                                                                    tpl?.payment_sub_item[i]
                                                                      ?.terminationReduction?.cancel
                                                                  )
                                                              }
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.cancel = newCancel
                                                              updatedPayload[
                                                                ind
                                                              ].terminationReduction.isUpdated = true

                                                              if (
                                                                Number(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                ) > 0
                                                              ) {
                                                                let outstand = getNegative(
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount
                                                                )
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect = getPositive(
                                                                  Number(outstand) +
                                                                    Number(
                                                                      updatedPayload[ind]
                                                                        ?.payment_sub_item?.[
                                                                        subIndex
                                                                      ].terminationReduction.refund
                                                                    ) +
                                                                    Number(newValue)
                                                                )
                                                              } else {
                                                                updatedPayload[
                                                                  ind
                                                                ].payment_sub_item[
                                                                  subIndex
                                                                ].terminationReduction.collect =
                                                                  updatedPayload[ind]
                                                                    .payment_sub_item[subIndex]
                                                                    .outstandingAmount -
                                                                  Number(newValue)
                                                              }
                                                              setIncomePaymentPayload(
                                                                updatedPayload
                                                              )
                                                            } else {
                                                              ErrorToast(
                                                                'You can not cancel amount greater than total amount..'
                                                              )
                                                            }
                                                          }}
                                                          onBlur={() => {
                                                            setTimeout(() => {
                                                              setSaveMainTermReduction(Date.now())
                                                            }, 1000)
                                                            
                                                          }}
                                                          style={{height: '35px', width: '90%'}}
                                                          disabled={
                                                            isViewMode ||
                                                            isFinanceSubmitted ||
                                                            end_date == null
                                                          }
                                                        />
                                                      </td>
                                                      <td className='py-1'>
                                                        {pmSub?.terminationReduction?.collect
                                                          ? pmSub?.terminationReduction?.collect
                                                          : 0}
                                                      </td>
                                                      <td className='py-1'>
                                                        {tpl?.payment_sub_item?.length > 1 && (
                                                          <img
                                                            src={redCross}
                                                            className='cursor-pointer ms-1'
                                                            height={14}
                                                            width={14}
                                                            onClick={() =>
                                                              handleRemoveTerminationPaymentListItem(
                                                                ind,
                                                                subIndex
                                                              )
                                                            }
                                                          />
                                                        )}
                                                      </td>
                                                    </tr>
                                                  </>
                                                )
                                              }
                                            )
                                          ) : (
                                            <></>
                                          )}

                                          <tr
                                            style={{
                                              borderBottomStyle: 'dashed',
                                              height: '30px',
                                              fontWeight: '700',
                                            }}
                                          >
                                            <div
                                              className='d-flex cursor-pointer'
                                              onClick={() =>
                                                handleAddTerminationPaymentListItem(ind)
                                              }
                                            >
                                              <img src={plusBlue} height={15} width={15} />
                                              <p className='head-text mb-2 ms-2'>
                                                <i>Add Another Item</i>
                                              </p>
                                            </div>
                                          </tr>
                                        </>
                                      )}
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='card'>
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Outstanding</th>
                                  <th className='min-w-150px'>
                                    Refund{' '}
                                    <span>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to refund from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                  <th className='min-w-150px'>
                                    Cancel
                                    <span>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to cancel from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                  <th className='min-w-150px'>
                                    Collect
                                    <span>
                                      <OverlayTrigger
                                        placement='top'
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                                            {' '}
                                            Enter the amount you wish to collect from the existing
                                            payment.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          {' '}
                                          <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                        </span>
                                      </OverlayTrigger>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>

              {/* Financials */}
              {isFinance && (
                <>
                  <div
                    className='row mx-4 mt-5'
                    // style={flg == '2' ? {opacity: '0.5'} : {opacity: '1'}}
                  >
                    <div className='col-12 px-4 mt-5'>
                      <div className='d-flex align-items-center'>
                        <h2 className='top ps-0'>
                          <b>Financials</b>
                        </h2>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold green-submit-btn ms-auto'
                          onClick={() => {
                            let values = {
                              firstName: formData?.firstName,
                              lastName: formData?.lastName,
                              email: formData?.email,
                              countryCode: formData?.countryCode,
                              phoneNumber: formData?.phoneNumber,
                            }
                            setTenantDetails(values)
                            setIncomeModal(true)
                          }}
                          style={{width: '200px'}}
                          // disabled={flg == '2' ? true : false}
                        >
                          <img
                            src={addWhite}
                            height={16}
                            width={16}
                            style={{marginRight: '10px'}}
                          />{' '}
                          Add Incoming Payment
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    className='row mx-4 mt-5 mb-5'
                    // style={flg == '2' ? {opacity: '0.8'} : {opacity: '1'}}
                  >
                    <div
                      className='col-12 px-3 mt-3 mb-3'
                      // style={flg == '2' ? {opacity: '0.8'} : {opacity: '1'}}
                    >
                      {incomePaymentPayload?.length > 0 ? (
                        <>
                          <div
                            className='card px-3'
                            style={{boxShadow: 'lightgrey 0px 4px 6px 5px'}}
                          >
                            <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                              <table
                                className='table align-start table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                              >
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='min-w-100px'>Payment</th>
                                    <th className='min-w-100px'>Amount</th>
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px'>Due</th>
                                    <th className='min-w-100px'>Created At</th>
                                    <th className='min-w-150px'>Amount Paid</th>
                                    <th className='min-w-150px'>Date Paid</th>
                                    <th className='min-w-100px'></th>
                                  </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                  {incomePaymentPayload?.map((pm: any, ind: any) => {
                                    return (
                                      <>
                                        <tr
                                          style={
                                            show[ind] == true
                                              ? {borderBottomStyle: 'none', height: '30px'}
                                              : show[ind] == false
                                              ? {borderBottomStyle: 'dashed', height: '30px'}
                                              : pm?.payment_sub_item?.length == 0
                                              ? {borderBottomStyle: 'dashed', height: '30px'}
                                              : {height: '30px'}
                                          }
                                        >
                                          <td>
                                            <div className='d-flex align-items-center'>
                                              {pm?.payment?.name}
                                            </div>
                                          </td>

                                          <td>
                                            {parseFloat(pm?.payment?.totalAmount)?.toLocaleString(
                                              'en-US',
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                          </td>

                                          <td>
                                            {pm?.payment?.paymentStatus === 0
                                              ? 'Unpaid'
                                              : pm?.payment?.paymentStatus === 1
                                              ? 'Paid'
                                              : pm?.payment?.paymentStatus === 2
                                              ? 'Partially paid'
                                              : pm?.payment?.paymentStatus === 3
                                              ? 'Void'
                                              : 'Draft'}
                                          </td>

                                          <td>
                                            {pm?.payment?.dueDate
                                              ? moment(pm?.payment?.dueDate).format(
                                                  'DD.MM.YYYY - hh:mm A'
                                                )
                                              : '-'}
                                          </td>
                                          <td>
                                            {pm?.payment?.createdAt
                                              ? moment(pm?.payment?.createdAt).format(
                                                  'DD.MM.YYYY - hh:mm A'
                                                )
                                              : '-'}
                                          </td>
                                          <td>
                                            {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                              minimumFractionDigits: 2,
                                            })}
                                          </td>
                                          <td>
                                            {pm?.payment?.datePaid
                                              ? moment(
                                                  pm?.payment?.datePaid
                                                ).format('DD.MM.YYYY - hh:mm A')
                                              : '-'}
                                          </td>
                                          <td>
                                            <BsArrowRightSquareFill
                                              color='#35475E '
                                              fontSize={18}
                                              style={
                                                flg == '3'
                                                  ? {cursor: 'pointer'}
                                                  : {cursor: 'not-allowed'}
                                              }
                                              className='mx-3 me-4 cursor-pointer'
                                              onClick={() => {
                                                // if (flg == '3') {
                                                if (pm?.payment?.paymentStatus == 4) {
                                                  ApiGet(
                                                    `corporate/payment/${pm?.payment?._id}?isCloneRecord=true`
                                                  )
                                                    .then(async (res) => {
                                                      setDataToEdit(res?.data?.data)
                                                      setIndexToEdit(ind)
                                                      setEditIncomeModalDraft(true)
                                                    })
                                                    .catch((err) => console.log('err', err))
                                                } else {
                                                  getPaymentById(pm?.payment?._id)
                                                }
                                                // }
                                              }}

                                              // onClick={() => {
                                              //   let values = {
                                              //     firstName: formData?.firstName,
                                              //     lastName: formData?.lastName,
                                              //     email: formData?.email,
                                              //     countryCode: formData?.countryCode,
                                              //     phoneNumber: formData?.phoneNumber,
                                              //   }
                                              //   setTenantDetails(values)

                                              //   console.log(pm?.payment?._id)
                                              //   if (pm?.payment?._id) {
                                              //     ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                              //       .then(async (res) => {
                                              //         setDataToEdit(res?.data?.data)
                                              //         setIndexToEdit(ind)
                                              //         setEditIncomeModalDraft(true)
                                              //       })
                                              //       .catch((err) => console.log('err', err))
                                              //   } else {
                                              //     console.log(pm)
                                              //     setDataToEdit(pm)
                                              //     setIndexToEdit(ind)
                                              //     setEditIncomeModalDraft(true)
                                              //   }
                                              // }}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {/* Pagination */}
                            <div className='row mb-5'>
                              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
                                <div
                                  className='dataTables_paginate paging_simple_numbers test'
                                  id='kt_ecommerce_sales_table_paginate'
                                >
                                  <ReactPaginate
                                    breakLabel='...'
                                    nextLabel='>'
                                    pageClassName='paginate_button page-item +'
                                    pageLinkClassName='page-link'
                                    containerClassName='pagination'
                                    activeClassName='active'
                                    previousClassName='paginate_button page-item previous'
                                    nextClassName='paginate_button page-item next'
                                    previousLinkClassName='page-link'
                                    nextLinkClassName='page-link'
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageLimit}
                                    previousLabel='<'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='card'>
                            <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                              <table
                                className='table align-start table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                              >
                                <thead>
                                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='min-w-100px'>Payment</th>
                                    <th className='min-w-100px'>Amount</th>
                                    <th className='min-w-100px'>Status</th>
                                    <th className='min-w-100px'>Due</th>
                                    <th className='min-w-100px'>Created At</th>
                                    <th className='min-w-150px'>Amount Paid</th>
                                    <th className='min-w-150px'>Date Paid</th>
                                    <th className='min-w-100px'></th>
                                  </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                  <tr>
                                    <td colSpan={10} className='text-center'>
                                      <img src={noData} alt='' width={350} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>

      <Modal show={showImageName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUploadFile?.files.map((el: any, i: any) => (
            <>
              <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                Input File Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                onChange={(e: any) => uploadImageName(e, el, i)}
              />
              {fileFound ? (
                <p className='mt-2' style={{color: 'red'}}>
                  Filename Exist already
                </p>
              ) : null}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={saveImageFileName}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={modalBtnIsLoading}
          >
            Save {''}
          </Button>
        </Modal.Footer>
      </Modal>

      {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => setIncomeModal(false)}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => {
            setOpen(true)
            setIsFormDirty(true)
            // localStorage.setItem('isFormDirty', 'true')
            localStorage.removeItem('fromList')
            if (flg == '3') {
              let temp = {
                // discard : false,
                tenancyId: tenancyId,
                // delete: true,
                requestId: state?.tenantReqId,
              }
              localStorage.setItem('DeleteClone', JSON.stringify(temp))
            }
            getAllPayments()
          }}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          from={'terminate'}
          otherFinancials={filteredPaymentItems}
        />
      )}

      {/* Edit Payment Modal */}
      {editIncomeModal && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={incomingPaymentId}
          id={paymentId}
          subItemId={''}
          subId={''}
          getAllPayments={() => {
            setOpen(true)
            setIsFormDirty(true)
            localStorage.removeItem('fromList')
            if (flg == '3') {
              let temp = {
                // discard : false,
                tenancyId: tenancyId,
                // delete: true,
                requestId: state?.tenantReqId,
              }
              localStorage.setItem('DeleteClone', JSON.stringify(temp))
            }
            // localStorage.setItem('isFormDirty', 'true')
            getAllPayments()
          }}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'terminate-tenancy'}  
        />
      )}

      {editIncomeModalDraft && (
        <EditPaymentModalDraft
          show={editIncomeModalDraft}
          handleClose={() => {
            setEditIncomeModalDraft(false)
          }}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => {
            if (flg == '2') setOpen(true)
            getAllPayments()
          }}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          detailsToEdit={dataToEdit}
          indexToUpdate={indexToEdit}
          otherFinancials={'termination'}
          from={'termination'}
        />
      )}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={folderFileModal}
        onHide={() => {
          setFolderFileModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setFolderFileModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          {showBck && (
            <div className='d-flex align-items-center mb-3'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setFileType('other')
                  setShowBck(false)
                }}
              >
                <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
              </span>
              <h2 className='m-0 head-text ms-2'>{selectedFolder?.name}</h2>
            </div>
          )}

          {fileType == 'other' ? (
            <>
              {tenantFolders?.length > 0 ? (
                <>
                  <div className='d-flex'>
                    {tenantFolders?.map((folder: any) => {
                      return (
                        <a
                          onClick={() => {}}
                          className='text-gray-800 text-hover-primary d-flex flex-column mx-5 text-center'
                        >
                          <div
                            className='symbol symbol-60px mb-5'
                            onClick={() => {
                              if (folder?.name == 'Passports') {
                                setDataToSelectFrom(passportFolder)
                                setFileType('passport')
                              } else if (folder?.name == 'Contracts') {
                                setDataToSelectFrom(contractsFolder)
                                setFileType('contract')
                              } else if (folder?.name == 'IDs') {
                                setDataToSelectFrom(iDsFolder)
                                setFileType('ids')
                              } else if (folder?.name == 'Residencies') {
                                setDataToSelectFrom(residencyFolder)
                                setFileType('residency')
                              }
                              setSelectedFolder(folder)
                              setShowBck(true)
                            }}
                          >
                            <img src={folderDocument} className='theme-light-show' alt='' />
                            <img
                              src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                              className='theme-dark-show'
                              alt=''
                            />
                          </div>
                          <div className='fs-5 fw-bold mb-2'>{folder?.name}</div>
                        </a>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center'>
                    {' '}
                    <div className=''>
                      {' '}
                      <h2 className='head-text'>No Files...</h2>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dataToSelectFrom?.fileList?.length > 0 ? (
                <div
                  className='tops d-flex gap-5 align-items-start mx-5'
                  style={{overflow: 'scroll', overflowY: 'hidden'}}
                >
                  {dataToSelectFrom?.fileList?.map((file: any, ind: any) => {
                    return (
                      <div className='imgs-set'>
                        <div className='d-flex mw-350px me-3'>
                          <img
                            src={Bucket + file?.fileURL}
                            width='100'
                            height='100'
                            className='main_img mb-5'
                            onClick={() => {
                              if (showBck) {
                                setuploadOtherImgTenant([...uploadOtherImgTenant, file?.fileURL])
                              } else {
                                if (fileType == 'passport') setuploadPassportTenant([file?.fileURL])
                                else if (fileType == 'contract')
                                  setuploadContractTenancy([file?.fileURL])
                                else if (fileType == 'residency')
                                  setuploadresidencyTenant([file?.fileURL])
                                else if (fileType == 'ids') setuploadIdTenant([file?.fileURL])
                              }

                              // setIsFormDirty(true)

                              setFolderFileModal(false)

                              // setTimeout(() => {
                              //   autoSaveTenancy()
                              // }, 2500)
                            }}
                          />
                        </div>
                        <p className=''>
                          {getFilenameToSelect(file?.fileName, false)?.length > 6
                            ? `${file?.fileName?.slice(0, 6)}...${getFilenameToSelect(
                                file?.fileName,
                                true
                              )}`
                            : file?.fileName}
                        </p>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='d-flex justify-content-center'>
                  {' '}
                  <div className=''>
                    {' '}
                    <h2 className='head-text'>No Files...</h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>

      {/* Add New tenant Modal */}
      {addNewTenant && (
        <AddNewTenant
          show={AddNewTenant}
          handleClose={() => setAddNewTenant(false)}
          refetchTenants={() => getTenantData()}
          onSuccess={(val: any) => {
            if (from == 'main') {
              setNewTenantID(val?._id)
              setTenantIdForFolder(val?._id)
              setIsFormDirty(true)
              let temp = val?.firstName + val?.lastName
              setSelectedTenantName(temp)

              setFormData(val)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0

              for (let i = 0; i < val?.tenant_folder?.length; i++) {
                if (val?.tenant_folder?.[i].name == 'Passports') {
                  setPassportFolder(val?.tenant_folder?.[i])
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                } else if (val?.tenant_folder?.[i].name == 'Contracts') {
                  contFold[c] = val?.tenant_folder?.[i]
                  c++
                  setContractsFolder(val?.tenant_folder?.[i])
                } else if (val?.tenant_folder?.[i].name == 'IDs') {
                  setIDsFolder(val?.tenant_folder?.[i])
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                } else if (val?.tenant_folder?.[i].name == 'Residencies') {
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                  setResidencyFolder(val?.tenant_folder?.[i])
                }
              }
              setTenantDocuments(tentFold)
              setContractDocuments(contFold)
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subTenantData?.length] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passport: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
                _id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])
            }
          }}
        />
      )}

      {/* Add New tenant Modal */}
      {editTenantModal && tenantIdToView && (
        <EditTenant
          show={AddNewTenant}
          handleClose={() => setEditTenantModal(false)}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdToView}
          onSuccess={(val: any) => {
            if (from == 'main') {
              setNewTenantID(val?._id)
              setTenantIdForFolder(val?._id)
              setIsFormDirty(true)
              let temp = val?.firstName + val?.lastName
              setSelectedTenantName(temp)

              setFormData(val)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subInd] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passport: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
                _id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])
            }
          }}
        />
      )}

      {/* Add Folder Modal */}
      {tenantFolderModal && (
        <TenantFolders
          show={tenantFolderModal}
          handleClose={(val: any, st: any) => {
            setTenantFolderModal(false)
            if (st) {
              if (from == 'tenant') setTenantDocuments(val)
              else setContractDocuments(val)
            } else {
              let temp = localStorage.getItem('contractCopy')
              if (temp != null) {
                let copy = JSON.parse(temp)
                setContractDocuments(copy)
              }

              let temp2 = localStorage.getItem('tenantCopy')
              if (temp2 != null) {
                let copy2 = JSON.parse(temp2)
                setTenantDocuments(copy2)
              }
            }
            // getTenancyData(tenancyId)
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          // tentFolder={tenantDocuments}
          contFolder={contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            setTenantFolderModal(false)
            if (from == 'tenant') setTenantDocuments(val)
            else setContractDocuments(val)

            setAutoSaveData(Date.now())
          }}
        />
      )}

      {/* Add File Modal */}
      {tenantFileModal && (
        <SingleFileModal
          show={tenantFileModal}
          handleClose={(val: any, st: any) => {
            setTenantFileModal(false)

            // if (st) {
            if (from == 'tenant') setTenantDocuments(val)
            else setContractDocuments(val)
            // }
            setAutoSaveData(Date.now())
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          targetFolder={targetFolder}
          contFolder={contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            if (from == 'tenant') setTenantDocuments(val)
            else setContractDocuments(val)

            setAutoSaveData(Date.now())
          }}
        />
      )}

      {/* Tenant Document Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={tenantDocModal}
        onHide={() => {
          setTenantDocModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            maxHeight: '620px',
            overflow: 'scroll',
          }}
        >
          <div className='row mx-1 mt-5'>
            <div className='col-12'>
              <div
                className='d-flex  align-items-center '
                style={{justifyContent: 'space-between'}}
              >
                <div className='d-flex'>
                  <h3 className='ms-0 me-2' style={{fontWeight: '700'}}>
                    Tenant Documents
                  </h3>
                  {/* {!isEditTenant && (
                    <img
                      src={pencil}
                      alt=''
                      height={16}
                      width={16}
                      className='ms-5 me-1 cursor-pointer'
                      onClick={() => {
                        setIsEditTenant(true)
                      }}
                    />
                  )} */}
                </div>

                <div className=' d-flex '>
                  {isEditTenant ? (
                    <>
                      <div className=' d-flex ms-auto'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  mx-3 red-hollow-btn ms-auto'
                          onClick={() => {
                            getTenancyData(tenancyId)
                            setIsEditTenant(false)
                          }}
                        >
                          <img src={redCross} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                          Cancel
                        </button>

                        <button
                          type='submit'
                          className='btn btn-sm fw-bold ms-3 green-hollow-btn'
                          onClick={(e) => {
                            submitTenantDocuments(formData, e, true)
                          }}
                        >
                          <img
                            src={saveGreenImg}
                            height={20}
                            width={20}
                            style={{marginRight: '13px'}}
                          />{' '}
                          {'  '}Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className='d-flex ms-auto'>
                      <label
                        className='head-text fs-3 cursor-pointer ms-auto'
                        style={{fontWeight: '800', transform: 'translate(0px, -10px)'}}
                        onClick={() => {
                          setTenantDocModal(false)
                        }}
                      >
                        X
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isEditTenant && (
              <div className='col-12'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  blue-btn'
                  disabled={sendError}
                  onClick={() => {
                    if (newTenantId) {
                      setFrom('tenant')
                      let temp = [...tenantDocuments]
                      localStorage.setItem('tenantCopy', JSON.stringify(temp))
                      setTenantFolderModal(true)
                    } else {
                      ErrorToast('Please select Main Tenant..')
                    }
                  }}
                >
                  <img src={addFolder} height={20} width={20} style={{marginRight: '7px'}} /> Folder
                </button>
              </div>
            )}

            {tenantDocuments?.map((doc: any, index: any) => {
              return (
                <>
                  {doc.isCheck && (
                    <>
                      <div className='col-12 ps-4 pe-3 mt-4'>
                        <div className='d-flex  align-items-center'>
                          <h4 className='' style={{fontWeight: '700'}}>
                            {doc?.name}
                          </h4>
                          {isEditTenant && (
                            <div className='ms-auto d-flex align-items-center'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                disabled={sendError}
                                onClick={() => {
                                  setFrom('tenant')
                                  setTargetFolder(doc)
                                  setTenantFileModal(true)
                                }}
                              >
                                <img
                                  src={invoiceAdd}
                                  height={20}
                                  width={16}
                                  style={{marginRight: '15px'}}
                                />{' '}
                                File
                              </button>
                              {/* {!doc?.isSystemGenerated && ( */}
                              <img
                                src={redCrossFilled}
                                height={15}
                                width={15}
                                className='cursor-pointer ms-2 '
                                onClick={() => {
                                  const values = [...tenantDocuments]
                                  values[index].isCheck = false
                                  setTenantDocuments(values)
                                }}
                              />
                              {/* )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-4 mt-4'>
                        {doc?.fileList?.length > 0 ? (
                          <div
                            className='tops d-flex gap-5 align-items-start mx-5'
                            style={{overflow: 'auto', overflowY: 'hidden'}}
                          >
                            {doc?.fileList?.map((file: any, ind: any) => {
                              return (
                                <>
                                  {file.isChecked && (
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3'>
                                        <img
                                          src={Bucket + file?.fileURL}
                                          width='100'
                                          height='100'
                                          className='main_img mb-5 cursor-pointer'
                                          id={`clicked${doc?.name}${ind}`}
                                          onClick={() =>
                                            handleImageClick(
                                              `clicked${doc?.name}${ind}`,
                                              `${Bucket}${file?.fileURL}`,
                                              `${doc?.name}`,
                                              doc?.fileList,
                                              'TenantDocuments'
                                            )
                                          }
                                        />
                                        {!isEditTenant && (
                                          <img
                                            src={download}
                                            height={18}
                                            width={16}
                                            className='cursor-pointer ms-2 mt-0'
                                            onClick={async () => {
                                              const link = document.createElement('a')

                                              // Set the href attribute to the file URL
                                              link.href = Bucket + file?.fileURL

                                              // Set the download attribute with the desired file name
                                              link.download = ''

                                              // Append the anchor element to the document
                                              document.body.appendChild(link)

                                              // Trigger a click event on the anchor element
                                              link.click()

                                              // Remove the anchor element from the document
                                              document.body.removeChild(link)
                                            }}
                                          />
                                        )}

                                        {isEditTenant && (
                                          <img
                                            src={redCross}
                                            height={15}
                                            width={15}
                                            className='cursor-pointer ms-2 mt-1'
                                            onClick={() => {
                                              const values = [...tenantDocuments]
                                              console.log(values)
                                              values[index].fileList[ind].isChecked = false
                                              setTenantDocuments(values)
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        ) : (
                          <div className='d-flex justify-content-center'>
                            {' '}
                            <div className=''>
                              {' '}
                              {/* <h2 className='head-text'>No Files...</h2> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )
            })}

            {tenantDeletedDocuments?.map((doc: any, index: any) => {
              return (
                <>
                  {doc.isCheck && (
                    <>
                      <div className='col-12 ps-4 pe-3 mt-4'>
                        <div className='d-flex  align-items-center'>
                          <h4 className='' style={{fontWeight: '700'}}>
                            {doc?.name}
                          </h4>
                          {isEditTenant && (
                            <div className='ms-auto d-flex align-items-center'>
                              {/* <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                disabled={sendError}
                                onClick={() => {
                                  setFrom('tenant')
                                  setTargetFolder(doc)
                                  setTenantFileModal(true)
                                }}
                              >
                                <img
                                  src={invoiceAdd}
                                  height={20}
                                  width={16}
                                  style={{marginRight: '15px'}}
                                />{' '}
                                File
                              </button> */}
                              {/* {!doc?.isSystemGenerated && ( */}
                              <img
                                src={redCrossFilled}
                                height={15}
                                width={15}
                                className='cursor-pointer ms-2 '
                                onClick={() => {
                                  const values = [...tenantDeletedDocuments]
                                  values[index].isCheck = false
                                  setTenantDeletedDocuments(values)
                                }}
                              />
                              {/* )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-4 mt-4'>
                        {doc?.fileList?.length > 0 ? (
                          <div
                            className='tops d-flex gap-5 align-items-start mx-5'
                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                          >
                            {doc?.fileList?.map((file: any, ind: any) => {
                              return (
                                <>
                                  {file.isChecked && (
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3'>
                                        <img
                                          src={Bucket + file?.fileURL}
                                          width='100'
                                          height='100'
                                          className='main_img mb-5 cursor-pointer'
                                          id={`clicked${doc?.name}${ind}`}
                                           onClick={() =>
                                           handleImageClick(
                                           `clicked${doc?.name}${ind}`,
                                            `${Bucket}${file?.fileURL}`,
                                              `${doc?.name}`,
                                               doc?.fileList,
                                             'TenantDeletedDocs'
                                            )}
                                        />
                                        {!isEditTenant && (
                                          <img
                                            src={download}
                                            height={18}
                                            width={16}
                                            className='cursor-pointer ms-2 mt-0'
                                            onClick={async () => {
                                              const link = document.createElement('a')

                                              // Set the href attribute to the file URL
                                              link.href = Bucket + file?.fileURL

                                              // Set the download attribute with the desired file name
                                              link.download = ''

                                              // Append the anchor element to the document
                                              document.body.appendChild(link)

                                              // Trigger a click event on the anchor element
                                              link.click()

                                              // Remove the anchor element from the document
                                              document.body.removeChild(link)
                                            }}
                                          />
                                        )}

                                        {isEditTenant && (
                                          <img
                                            src={redCross}
                                            height={15}
                                            width={15}
                                            className='cursor-pointer ms-2 mt-1'
                                            onClick={() => {
                                              const values = [...tenantDeletedDocuments]
                                              console.log(values)
                                              values[index].fileList[ind].isChecked = false
                                              setTenantDeletedDocuments(values)
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        ) : (
                          <div className='d-flex justify-content-center'>
                            {' '}
                            <div className=''>
                              {' '}
                              {/* <h2 className='head-text'>No Files...</h2> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      </Modal>

      {/* {showTerm && (
        <TerminateTenancy
          show={showTerm}
          handleClose={() => setShowTerm(false)}
          id={tenancyData?._id}
          startDate={tenancyData?.duration?.start_date}
          endDate={tenancyData?.duration?.end_date}
          fetchData={() => getTenancyData(tenancyId)}
          unitId={tenancyData?.unitId}
        />
      )} */}

      {/* Message modal */}
      <div
        className={showMessageCard ? 'message' : 'd-none'}
        style={{width: '400px', height: '600px', zIndex: '20', right: '0px'}}
      >
        <div
          className='card m-0 p-0 br-10'
          style={{
            overflow: 'hidden',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{justifyContent: 'space-between'}}
          >
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5'>
            {showChat == 'chat' ? (
              <>
                <div
                  className='symbol-group symbol-hover py-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  <div className='symbol-group symbol-hover mx-5'>
                    {/* <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoChatList()}
                      style={{cursor: 'pointer'}}
                    /> */}
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                          <img alt='' src={profileImg}></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a
                      href='#'
                      className='symbol symbol-45px symbol-circle'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : (
                    <>
                      {tenanttData &&
                        tenanttData?.map((tnt: any) => (
                          <h4 className='m-5 '>
                            {tnt.firstName} {tnt.lastName}
                          </h4>
                        ))}
                    </>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {defaultRoomData?.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    style={{backgroundColor: 'white'}}
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}}
                  ref={bottomRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {/* {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName} */}
                            </p>
                            <div className='px-3 pt-0'>
                              <h4 className='m-0 p-0 py-2'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <Card.Footer
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'p-0 text-start'
                                : 'p-0 text-end'
                            }
                            style={{backgroundColor: 'transparent', borderTop: '0px'}}
                          >
                            {' '}
                            <span className='m-0 small_font' style={{fontWeight: '400 !important'}}>
                              {dateTotime(msg?.createdAt)}
                            </span>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0'>
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input
                  type='text'
                  placeholder='Search'
                  className='form form-control m-0 msg_search'
                />
                <img
                  src={filter}
                  alt=''
                  width='24px'
                  className='filter'
                  onClick={() => setShowFilterCard(!showFilterCard)}
                />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  {/* <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li> */}
                </ul>
                <div
                  className='d-flex align-items-center my-5'
                  style={{justifyContent: 'space-between'}}
                >
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  {/* <div className='d-flex gap-3'>
                    <p className='m-0 cus-line' onClick={viewall}>
                      View All
                    </p>
                  </div> */}
                </div>
                <ul
                  className='notification min_height p-0 m-0'
                  ref={myDivRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg}></img>
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a
                                href='#'
                                className='symbol symbol-45px symbol-circle'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_view_users'
                              >
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            {/* <img alt='Pic' src='media/avatars/300-23.jpg' /> */}
                            <img alt='' src={profileImg}></img>
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey'>
                              {/* {moment(chat.createdAt).format('HH:MM')} */}
                              {timeConvert(chat?.createdAt)}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div
                  className='symbol-group symbol-hover p-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover mx-5'>
                        {/* <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        /> */}
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el.firstName} {el.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImg} />
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a
                          href='#'
                          className='symbol symbol-40px symbol-circle'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_view_users'
                        >
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div
                        className='symbol-group symbol-hover p-5 px-0 m-0'
                        style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                      >
                        <div className='symbol-group symbol-hover me-5 ms-0'>
                          {/* <img
                        src={leftarrow}
                        width='20px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      /> */}
                        </div>
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <div className='symbol-group symbol-hover m-0'>
                              <div className='symbol symbol-40px symbol-circle '>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {tnt.firstName} {tnt.lastName}
                                    </Tooltip>
                                  }
                                  placement='bottom'
                                >
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg} />
                                </OverlayTrigger>
                              </div>
                            </div>
                          ))}
                      </div>

                      <h6 className='m-0 p-0'>
                        {/* <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setShowChat('addNew')
                          }}
                        >
                          Select Recipient
                        </a> */}
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <h4 className='m-5 '>
                              {tnt.firstName} {tnt.lastName}
                            </h4>
                          ))}
                      </h6>
                    </div>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {bodyWithSubject.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={bodyWithSubject.subjectTitle}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}}
                  ref={bottomRef}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any, index: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0'>
                              <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div
                  style={{position: 'absolute', bottom: 25, width: '90%'}}
                  className='px-0 mx-auto'
                >
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    {/* <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    /> */}
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'All' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'All' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Internal' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Internal' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Tenants' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Tenants' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('all')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {showModall && (
        <div className='modal'>
          <div className='modal-content'>
            <p>Are you sure you want to leave?</p>
            <button onClick={handleConfirm}>Yes</button>
            <button onClick={handleCancel}>No</button>
          </div>
        </div>
      )}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={showModall}
        onHide={() => {
          setShowModall(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setShowModall(false)
                }}
              >
                X
              </label>
            </div>
          </div>
        </div>
      </Modal>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default TerminateTenancy

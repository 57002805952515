import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import redCross from '../../../img/x-mark.png'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import send from '../../../img/sendwhite.svg'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import download from '../../../img/download-white.png'
import chequeImg from '../../../img/cheque.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import recipient from '../../../img/recipient.png'
import './style.scss'
import ViewAllCreditNotes from './ViewAllCreditNotes'
import ViewCreditNote from './ViewCreditNote'
import ViewDebitNote from './ViewDebitNote'
import ViewAllDebitNotes from './ViewAllDebitNotes'
import ViewAllReceipts from './ViewAllReceipts'
import ViewReceipt from './ViewReceipt'
import Switch from 'react-switch'

interface ButtonProps {
  show: any
  handleClose: any
  invoice: any
  dueDate: any
  paymentId: any
  tenantDetails: any
  paymentMethod: any
  getPaymentInvoices: any
  invRecDetails: any
  from: any
}

const ViewInvoice = ({
  handleClose,
  show,
  invoice,
  dueDate,
  paymentId,
  tenantDetails,
  paymentMethod,
  getPaymentInvoices,
  invRecDetails,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [invoiceNoError, setInvoiceNoError] = useState<any>(false)
  const [invoiceNo, setInvoiceNo] = useState<any>('')
  var nonVat: any = 0
  var vatAmount: any = 0 
  const [invoiceNotes, setInvoiceNotes] = useState<any>('')
  const [recipientsModal, setRecipientsModal] = useState(false)
  const [viewAllCreditNotes, setViewAllCreditNotes] = useState<any>(false)
  const [viewAllDebitNotes, setViewAllDebitNotes] = useState<any>(false)

  const [chequeMedia, setChequeMedia] = useState<any>([])
  const [selectedAccount, setSelectedAccount] = useState<any>()
  const [viewAllReceipts, setViewAllReceipts] = useState<any>(false)
  const [viewReceipt, setViewReceipt] = useState<any>(false)
  const [selectedAccountReceipt, setSelectedAccountReceipt] = useState<any>()

  const [checkedRec, setCheckedRec] = useState<any>(invoice?.isShareToTenant)

  const [bankAccountItems, setBankAccountItems] = useState<any>([])

  const [recipientsEmails, setRecipientsEmails] = useState<any>([
    {
      email: '',
    },
  ])

  //
  const handleEmailsAddFields = () => {
    const values = [...recipientsEmails]
    values.push({
      email: '',
    })
    setRecipientsEmails(values)
  }

  //
  const handleEmailRemoveFields = (index: any) => {
    const values = [...recipientsEmails]
    values.splice(index, 1)
    setRecipientsEmails(values)
  }

  //
  const shareWithRecipients = async () => {
    let emailsList: any = []
    let j = 0
    for (let i = 0; i < recipientsEmails?.length; i++) {
      if (recipientsEmails[i]?.email?.length != 0) {
        emailsList[j] = recipientsEmails[i]?.email
        j++
      }
    }

    const body = {
      paymentId: paymentId,
      emails: emailsList,
      paymentInvoiceId: invoice?._id,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment/invoice/sent/other?isCloneRecord=true`
    else url = `corporate/payment/invoice/sent/other`

    await ApiPost(`${url}`, body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        setRecipientsModal(false)
        handleClose()
        setRecipientsEmails([
          {
            email: '',
          },
        ])
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const sentInvoiceToTenant = () => {
    const body = {
      paymentId: paymentId,
      paymentInvoiceId: invoice?._id,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment/invoice/sent?isCloneRecord=true`
    else url = `corporate/payment/invoice/sent`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
      })
      .catch((err: any) => {
        // ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const updatePaymentInvoice = (id: any) => {
    const body = {
      id: id,
      invoiceNo: invoiceNo,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_invoice?isCloneRecord=true`
    else url = `corporate/payment_invoice`

    ApiPut(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getPaymentInvoices(paymentId)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const [creditNoteToView, setCreditNoteToView] = useState<any>()
  const [viewCreditNote, setViewCreditNote] = useState<any>(false)

  //
  const getCreditNoteById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_credit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_credit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setCreditNoteToView(res?.data?.data)
        setViewCreditNote(true)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const [debitNoteToView, setDebitNoteToView] = useState<any>()
  const [viewDebitNote, setViewDebitNote] = useState<any>(false)

  //
  const getDebitNoteById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_debit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setDebitNoteToView(res?.data?.data)
        setViewDebitNote(true)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  // getting list of all bank accounts
  const getBankAccounts = () => {
    const body = {
      limit: 25,
      page: 1,
    }

    ApiPost(`corporate/payment_account/get`, body)
      .then((res: any) => {
        setBankAccountItems(res?.data?.data?.payment_account_data)
      })
      .catch((err: any) => console.log('err', err))
  }

  useEffect(() => {
    getBankAccounts()

    // Payment Method is Cheque
    if (invoice?.payment?.[0]?.paymentMethod == 1) {
      setChequeMedia([])
      if (invoice?.payment?.[0]?.chequeImages?.length > 0) {
        let values: any = []
        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)
        for (let i = 0; i < invoice?.payment?.[0]?.chequeImages?.length; i++) {
          // console.log(invoice?.payment?.[0]?.chequeImages[i])
          if (invoice?.payment?.[0]?.chequeImages[i] != null) {
            let x: any = invoice?.payment?.[0]?.chequeImages[i]?.split('/')[2]
            let y: any = x.split('.')[0]
            values[i] = {
              src: {
                name: invoice?.payment?.[0]?.chequeImages[i],
              },
              name: y,
              fileType: 'cheque',
              databaseId: data?._id,
              image: invoice?.payment?.[0]?.chequeImages[i],
            }
          }
        }
        setChequeMedia(values)
      }
    }

    // Payment Method is Bank
    if (invoice?.payment?.[0]?.paymentMethod == 2) {
      // console.log(invoice?.payment?.[0]?.payment_account[0])
      setSelectedAccount(invoice?.payment?.[0]?.payment_account[0])
      // console.log(selectedAccount)
    }
  }, [])

  //
  const getReceiptById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_receipt/${id}?isCloneRecord=true`
    else url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        for (let i = 0; i < bankAccountItems?.length; i++) {
          if (bankAccountItems[i]?._id == res?.data?.data?.paymentAccountId) {
            setSelectedAccountReceipt(bankAccountItems[i])
          }
        }
        setViewReceipt(true)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  //
  const updateShareStatus = (val: any) => {
    const body = {
      id: invoice?._id,
      isShareToTenant: val,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_invoice?isCloneRecord=true`
    else url = `corporate/payment_invoice`

    ApiPut(`${url}`, body)
      .then((res) => {})
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const isDraftDisabled =
    ((from == 'tenancy' || from == 'terminate-tenancy') &&
      ((user?.isSubUser && role?.leasing_residential?.lease_financial?.invoicing?.update) ||
        !user?.isSubUser)) ||
    from == 'propertyProfile' ||
    from == 'tenant' ||
    from == 'financials'

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => handleClose()}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height='80px' width='65px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-5 pt-lg-2'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-90px',
          }}
        >
          <Row className='mt-2'>
            <Col md={8}>
              {invoice?.invoiceNo == null || invoice?.invoiceNo == '' ? (
                <button
                  className='btn btn-sm fw-bold ps-2 pe-4 text-white'
                  style={{width: '140px', backgroundColor: '#008081'}}
                  onClick={() => {
                    if (invRecDetails?.invoiceSequence?.activityStatus == 0) {
                      if (invoiceNo == '' || invoiceNo == undefined) setInvoiceNoError(true)
                      else {
                        updatePaymentInvoice(invoice?._id)
                      }
                    } else {
                      updatePaymentInvoice(invoice?._id)
                    }
                  }}
                  disabled={!isDraftDisabled ? true : false}
                >
                  Generate
                </button>
              ) : (
                <>
                  <div className='d-flex'>
                    {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                      ((user?.isSubUser &&
                        role?.leasing_residential?.lease_financial?.invoicing?.update) ||
                        !user?.isSubUser)) ||
                      from == 'propertyProfile' ||
                      from == 'tenant' ||
                      from == 'financials') && (
                      <button
                        className='btn btn-sm fw-bold ps-1 pe-4 mt-2 action-btns'
                        style={{
                          backgroundColor: '#384a62',
                        }}
                        onClick={() => {
                          setRecipientsModal(true)
                        }}
                      >
                        <img
                          src={share}
                          height={18}
                          width={18}
                          style={{
                            marginRight: '12px',
                            marginTop: '-2px',
                          }}
                        />{' '}
                        {'  '}
                        Share
                      </button>
                    )}

                    <a
                      className='btn btn-sm fw-bold ps-2 pe-4 text-white d-block mt-2 ms-3 action-btns'
                      style={{backgroundColor: '#008081'}}
                      href={`${Bucket}${invoice?.pdfURL}`}
                      target='_blank'
                    >
                      <img
                        src={download}
                        height='18'
                        width='18'
                        style={{
                          marginRight: '3px',
                          marginTop: '-2px',
                        }}
                      />{' '}
                      Download
                    </a>

                    <button
                      className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 action-btns'
                      style={{
                        backgroundColor: '#8a8aa3',
                      }}
                      onClick={() => {
                        window.print()
                      }}
                    >
                      <img
                        src={print}
                        height='18'
                        width='18'
                        style={{
                          marginRight: '12px',
                          marginTop: '-2px',
                        }}
                      />{' '}
                      {'  '}
                      Print
                    </button>

                    {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                      ((user?.isSubUser &&
                        role?.leasing_residential?.lease_financial?.credit_notes?.view_list) ||
                        !user?.isSubUser)) ||
                      from == 'propertyProfile' ||
                      from == 'tenant' ||
                      from == 'financials') && (
                      <>
                        {invoice?.payment_credit_note?.length > 0 && (
                          <button
                            className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 action-btns'
                            style={{
                              backgroundColor: '#006699',
                            }}
                            onClick={() => {
                              if (invoice?.payment_credit_note?.length > 1) {
                                setViewAllCreditNotes(true)
                              } else {
                                if (
                                  ((from == 'tenancy' || from == 'terminate-tenancy') &&
                                    ((user?.isSubUser &&
                                      role?.leasing_residential?.lease_financial?.credit_notes
                                        ?.view_details) ||
                                      !user?.isSubUser)) ||
                                  from == 'propertyProfile' ||
                                  from == 'tenant' ||
                                  from == 'financials'
                                )
                                  getCreditNoteById(invoice?.payment_credit_note?.[0]?._id)
                                else
                                  ErrorToast(
                                    "You don't have the permissions to view Credit Note details..."
                                  )
                              }
                            }}
                          >
                            Credit Note
                          </button>
                        )}
                      </>
                    )}

                    {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                      ((user?.isSubUser &&
                        role?.leasing_residential?.lease_financial?.debit_notes?.view_list) ||
                        !user?.isSubUser)) ||
                      from == 'propertyProfile' ||
                      from == 'tenant' ||
                      from == 'financials') && (
                      <>
                        {invoice?.payment_debit_note?.length > 0 && (
                          <button
                            className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 action-btns'
                            style={{
                              backgroundColor: '#6560ff',
                            }}
                            onClick={() => {
                              if (invoice?.payment_debit_note?.length > 1) {
                                setViewAllDebitNotes(true)
                              } else {
                                if (
                                  ((from == 'tenancy' || from == 'terminate-tenancy') &&
                                    ((user?.isSubUser &&
                                      role?.leasing_residential?.lease_financial?.debit_notes
                                        ?.view_details) ||
                                      !user?.isSubUser)) ||
                                  from == 'propertyProfile' ||
                                  from == 'tenant' ||
                                  from == 'financials'
                                )
                                  getDebitNoteById(invoice?.payment_debit_note?.[0]?._id)
                                else
                                  ErrorToast(
                                    "You don't have the permissions to view Debit Note details..."
                                  )
                              }
                            }}
                          >
                            Debit Note
                          </button>
                        )}
                      </>
                    )}

                    {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                      ((user?.isSubUser &&
                        role?.leasing_residential?.lease_financial?.receipts?.view_list) ||
                        !user?.isSubUser)) ||
                      from == 'propertyProfile' ||
                      from == 'tenant' ||
                      from == 'financials') && (
                      <>
                        {invoice?.payment_receipt?.length > 0 && (
                          <button
                            className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 action-btns refund-color'
                            onClick={() => {
                              if (invoice?.payment_receipt?.length > 1) {
                                setViewAllReceipts(true)
                              } else {
                                if (
                                  ((from == 'tenancy' || from == 'terminate-tenancy') &&
                                    ((user?.isSubUser &&
                                      role?.leasing_residential?.lease_financial?.receipts
                                        ?.view_details) ||
                                      !user?.isSubUser)) ||
                                  from == 'propertyProfile' ||
                                  from == 'tenant' ||
                                  from == 'financials'
                                )
                                  getReceiptById(invoice?.payment_receipt?.[0]?._id)
                                else
                                  ErrorToast(
                                    "You don't have the permissions to view Receipt details..."
                                  )
                              }
                            }}
                          >
                            Receipt
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </Col>
            <Col md={4} className='py-3 '>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                    updateShareStatus(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#008081'
                  offColor='#b0bec5'
                  disabled={!isDraftDisabled ? true : false}
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>

          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            {invoice?.isVoid && (
              <Row>
                <Col md={8} className='pe-4'>
                  <div className='d-flex align-items-center mt-4 '>
                    <h1 className='text-danger mb-0'>VOID</h1>
                  </div>
                </Col>
                <Col md={4}></Col>
              </Row>
            )}

            <Row>
              <Col md={7} className='pe-4'>
                <div className='d-flex align-items-center my-2 mt-4'>
                  <h1 className=''>INVOICE</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5 blue-label fs-7' style={{minWidth: '100px'}}>
                    Invoice No.
                  </label>

                  {invoice?.invoiceNo == null || invoice?.invoiceNo == '' ? (
                    <>
                      {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                        <>
                          {invRecDetails?.invoiceSequence?.prefix != null && (
                            <>
                              <input
                                type='text'
                                className={
                                  invoiceNoError
                                    ? 'form-control form-control-solid-bg input-style border-danger '
                                    : 'form-control form-control-solid-bg input-style mytest'
                                }
                                placeholder='Enter Invoice No'
                                name='item_id'
                                value={invRecDetails?.invoiceSequence?.prefix}
                                disabled
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type='text'
                            className={
                              invoiceNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={invoiceNo}
                            onChange={(event) => {
                              setInvoiceNo(event.target.value)
                              setInvoiceNoError(false)
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <div style={{fontWeight: '500'}}> {invoice?.invoiceNo}</div>
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5  blue-label fs-7' style={{minWidth: '100px'}}>
                    Invoice Date
                  </label>
                  <div style={{fontWeight: '500'}}>
                    {' '}
                    {invoice?.invoiceNo == null || invoice?.invoiceNo == ''
                      ? moment(new Date()).format('DD-MM-YYYY')
                      : moment(invoice?.date).format('DD-MM-YYYY')}
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5  blue-label fs-7' style={{minWidth: '100px'}}>
                    Due Date
                  </label>
                  <div style={{fontWeight: '500'}}>{moment(dueDate).format('DD-MM-YYYY')}</div>
                </div>
              </Col>
              <Col md={5}>
                {invoice?.invoiceLogo != null && (
                  <div className='d-flex mb-3  '>
                    <img
                      src={`${Bucket}${invoice?.invoiceLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='mt-2 ms-auto'
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Row className='mt-5 pb-2'>
              <Col md={6} className='text-start pe-3' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.name != null && invoice?.invoiceCompany?.name}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.address != null && invoice?.invoiceCompany?.address}{' '}
                </p>

                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.phoneNumber &&
                    invoice?.invoiceCompany?.countryCode +
                      ' ' +
                      invoice?.invoiceCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.email && invoice?.invoiceCompany?.email}
                </p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                {/* <div className='d-flex align-items-center'> */}
                {invoice?.invoiceNo != null && invoice?.invoiceNo != '' && (
                  <></>
                  // <button
                  //   className='btn btn-sm fw-bold  mx-3 ps-1 pe-4 text-white ms-auto'
                  //   style={{
                  //     width: '125px',
                  //     backgroundColor: '#ff0000',
                  //     fontSize: '12px',
                  //   }}
                  //   onClick={() => sentInvoiceToTenant()}
                  // >
                  //   <img src={send} height='18' width='18' style={{marginRight: '3px'}} /> {'  '}
                  //   Send Invoice
                  // </button>
                )}
                <h4>
                  <b>Recipient</b>
                </h4>
                {/* </div> */}
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>
            <Row className='pb-5' style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={12} className='pe-4'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{invoice?.invoiceCompany?.trn ? invoice?.invoiceCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {invoice?.paymentSubItems.map((field: any, index: any) => {
                  let y: any = ((field.amount * field.quantity) / 100) * field.VATPercentage
                  let total: number = parseFloat(y) + field.amount * field.quantity
                  return (
                    <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                      <td className='py-2'>{index + 1}</td>
                      <td className='py-2'>{field.name}</td>
                      <td className='py-2'>{field.quantity}</td>
                      <td className='py-2'>
                        {field?.isLatePaymentFeesRecord
                          ? (field.amount / field?.quantity).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : field.amount.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                      <td className='py-2'>
                        {field?.isLatePaymentFeesRecord
                          ? ((field.amount / 100) * field.VATPercentage).toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : (
                              ((field.amount * field.quantity) / 100) *
                              field.VATPercentage
                            ).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                      <td className='py-2'>
                        {(field?.totalAmount).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                    </tr>
                  )
                })}

                <>
                  {invoice?.paymentSubItems.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>
                {invoice?.paymentSubItems?.length > 0 && (
                  <>
                    {invoice?.paymentSubItems.map((f: any, ind: any) => {
                      if (f?.isLatePaymentFeesRecord) {
                        vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                        nonVat = nonVat + f.amount
                      } else {
                        vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                        nonVat = nonVat + f.amount * f.quantity
                      }
                      return <></>
                    })}
                  </>
                )}

                <tr style={{height: '55px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2 fs-4'>
                    {nonVat.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2 fs-4'>
                    {vatAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4'>Total</td>
                  <td className='py-2 fs-3 fs-4' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED {(nonVat + vatAmount).toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={7}>
                <div className='row my-2 mb-5'>
                  <div className='col-md-6'>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Payment Method
                    </label>
                  </div>
                  <div className='col-md-6 text-center'>
                    <label
                      className='mb-1 me-1 fs-6 pt-1'
                      style={{minWidth: '160px', fontWeight: '500'}}
                    >
                      {invoice?.payment?.[0]?.paymentMethod == 0
                        ? 'Card'
                        : invoice?.payment?.[0]?.paymentMethod == 1
                        ? 'Cheque'
                        : invoice?.payment?.[0]?.paymentMethod == 2
                        ? 'Bank'
                        : 'Cash'}
                    </label>
                  </div>
                </div>
                {invoice?.payment?.[0]?.paymentMethod == 2 && (
                  <>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                      >
                        Account Name
                      </label>
                      <b> {selectedAccount?.accountHolderName}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        IBAN
                      </label>
                      <b> {selectedAccount?.IBAN}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Account No.
                      </label>
                      <b> {selectedAccount?.accountNumber}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Bank Name
                      </label>
                      <b> {selectedAccount?.bankName}</b>
                    </div>
                  </>
                )}

                {invoice?.payment?.[0]?.paymentMethod == 1 && (
                  <>
                    {chequeMedia.length > 0 && (
                      <div
                        className='row align-items-center my-2'
                        style={
                          invoice?.payment?.[0]?.paymentMethod == 1
                            ? {
                                overflowY: 'scroll',
                                minHeight: '96px',
                                maxHeight: '200px',
                                // transform: 'translate(-2px, -90px)',
                              }
                            : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                        }
                      >
                        {chequeMedia.map((chq: any, ind: any) => (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                              <div className='d-flex'>
                                <a
                                  href={`${Bucket}${chq?.image}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                  className='d-flex'
                                >
                                  <img
                                    src={chequeImg}
                                    width='80px'
                                    height='40px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{objectFit: 'cover'}}
                                  />
                                </a>
                              </div>
                              <p className='fs-7 ps-1'>{chq.name}</p>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Col>
              <Col md={5}>
                {/* Notes */}
                {invoice?.invoiceNo ? (
                  <>
                    {invoice?.note && (
                      <div className='my-2 mb-5 h-10'>
                        <label
                          className='mb-4 me-1 fs-4'
                          style={{minWidth: '160px', fontWeight: '700'}}
                        >
                          Notes
                        </label>
                        <p style={{marginBottom: '0.1rem'}}>{invoice?.note}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='my-2 mb-5 h-10'>
                    <label
                      className='mb-4 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Notes
                    </label>

                    <textarea
                      className='form-control form-control-solid-bg input-style mytest'
                      name='comment'
                      placeholder='Enter any additional notes to the recipient'
                      style={{height: '130px', borderColor: 'lightgray'}}
                      value={invoiceNotes}
                      onChange={(e) => setInvoiceNotes(e.target.value)}
                      maxLength={1000}
                    ></textarea>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* View Reciepts*/}
      {viewAllReceipts && (
        <ViewAllReceipts
          show={viewAllReceipts}
          handleClose={() => {
            setViewAllReceipts(false)
          }}
          receipts={invoice?.payment_receipt}
          bankAccountItems={bankAccountItems}
          paymentId={paymentId}
          from={from}
        />
      )}

      {/* View Single Receipt */}
      {viewReceipt && (
        <ViewReceipt
          show={viewReceipt}
          handleClose={() => {
            setViewReceipt(false)
          }}
          receipt={invoice?.payment_receipt?.[0]}
          paymentId={paymentId}
          selectedAccountReceipt={selectedAccountReceipt}
          tenantDetails={tenantDetails}
          from={from}
        />
      )}

      {/* Share with recipients */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={recipientsModal}
        onHide={() => {
          setRecipientsModal(false)
          setRecipientsEmails([
            {
              email: '',
            },
          ])
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: '#384a62 ',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Recipients</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross me-1'
                onClick={() => {
                  setRecipientsModal(false)
                  setRecipientsEmails([
                    {
                      email: '',
                    },
                  ])
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg-edit' style={{backgroundColor: '#384a62'}}>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2'>
              <img
                src={recipient}
                height='85px'
                width='85px'
                className='me-3 ms-5'
                style={{transform: 'translate(10px, -2px)'}}
              />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            marginBottom: '-93px',
            maxHeight: '500px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
          }}
        >
          <div className='d-flex mt-5 mx-3'>
            <h3 className='head-text mt-5' style={{fontWeight: '700'}}>
              Recipients Emails
            </h3>
            <button
              className='btn btn-sm fw-bold ps-1 pe-4 mt-3 ms-auto'
              style={{
                width: '150px',
                backgroundColor: '#384a62',
                color: 'white',
                fontSize: '13px',
              }}
              onClick={() => shareWithRecipients()}
            >
              <img
                src={share}
                style={{height: '18px', width: '18px', marginRight: '12px', marginTop: '-2px'}}
              />{' '}
              {'  '}
              Share
            </button>
          </div>

          {recipientsEmails.map((rec: any, index: any) => {
            return (
              <Row className='mx-1 mt-4'>
                <Col lg={4} md={4} className='align-items-center pe-5'>
                  {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                  <input
                    type='text'
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Recepient Email..'
                    name='item_id'
                    value={rec.email}
                    onChange={(e) => {
                      const values = [...recipientsEmails]
                      values[index].email = e.target.value
                      setRecipientsEmails(values)
                    }}
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.4rem 1rem',
                    }}
                  />
                </Col>
                <Col lg={4} className='align-items-center mb-0  m-2'>
                  <div className='d-flex align-items-center mt-0'>
                    <img
                      src={redCross}
                      width='18px'
                      height='18px'
                      className='main_img cursor-pointer '
                      onClick={() => handleEmailRemoveFields(index)}
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} className='align-items-center text-end'></Col>
              </Row>
            )
          })}

          <div
            className='d-flex align-items-center cursor-pointer m-5 ms-0'
            onClick={() => handleEmailsAddFields()}
            style={{marginTop: '30px'}}
          >
            <img
              src={plusBlue}
              className='ms-4'
              style={{
                height: '16px',
                width: '16px',
              }}
            />
            <p className='head-text ms-3 mb-0'>
              {' '}
              <i>Add another Email</i>
            </p>
          </div>
        </div>
      </Modal>

      {/* Screen for Print */}
      <div className='app-main print-invoice' id='kt_app_main'>
        <div id='' className=''>
          <div className='d-flex mt-3'>
            <div style={{width: '60%'}}>
              {invoice?.isVoid && (
                <div className='d-flex align-items-center my-2 '>
                  <h1 className='text-danger'>VOID</h1>
                </div>
              )}
              <div className='d-flex align-items-center my-2 '>
                <h1>INVOICE</h1>
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5 label-color'
                  style={{minWidth: '100px', fontSize: '1rem'}}
                >
                  Invoice No.
                </label>

                {invoice?.invoiceNo != null ? (
                  <div style={{fontWeight: '500'}}>
                    {' '}
                    {/* <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'> */}
                    {invoice?.invoiceNo}
                    {/* </p> */}
                  </div>
                ) : (
                  <input
                    type='text'
                    className={
                      invoiceNoError
                        ? 'form-control form-control-solid-bg input-style border-danger'
                        : 'form-control form-control-solid-bg input-style mytest'
                    }
                    // className='form-control form-control-solid-bg input-style'
                    placeholder='Enter Invoice No'
                    name='item_id'
                    value={invoiceNo}
                    onChange={(event) => {
                      setInvoiceNo(event.target.value)
                      setInvoiceNoError(false)
                    }}
                  />
                )}
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '100px', fontSize: '1rem'}}
                >
                  Invoice Date
                </label>
                <div style={{fontWeight: '500'}}>
                  {' '}
                  {invoice?.invoiceNo != null ? moment(invoice?.date).format('DD-MM-YYYY') : 'null'}
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '100px', fontSize: '1rem'}}
                >
                  Due Date
                </label>
                <div style={{fontWeight: '500'}}>{moment(dueDate).format('DD-MM-YYYY')}</div>
              </div>
            </div>
            <div className='text-end' style={{width: '40%'}}>
              {invoice?.invoiceLogo != null && (
                <img
                  src={`${Bucket}${invoice?.invoiceLogo}`}
                  height='120'
                  width='120'
                  style={{borderRadius: '7px'}}
                  className='mt-2 ms-auto'
                />
              )}
            </div>
          </div>

          <div className='mt-5 pb-5 d-flex'>
            <div className='text-start pe-4' style={{fontWeight: '600', width: '50%'}}>
              <p style={{marginBottom: '0.1rem'}}>{invoice?.invoiceCompany?.name}</p>
              <p style={{marginBottom: '0.1rem'}}>{invoice?.invoiceCompany?.address} </p>

              <p style={{marginBottom: '0.1rem'}}>
                {invoice?.invoiceCompany?.countryCode + ' ' + invoice?.invoiceCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{invoice?.invoiceCompany?.email}</p>
            </div>
            <div style={{fontWeight: '600', width: '50%'}} className='text-end ms-auto'>
              <h5>Recipient</h5>

              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.lastName} {tenantDetails?.firstName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
            </div>
          </div>

          <div
            className='d-flex align-items-center mb-5 pb-5'
            style={{borderBottom: '2px dashed lightgray'}}
          >
            <label
              className='mb-1 me-5 pe-5 head-text'
              style={{minWidth: '50px', maxWidth: '50px'}}
            >
              <b>TRN</b>
            </label>
            <b>{invoice?.invoiceCompany?.trn ? invoice?.invoiceCompany?.trn : '-'}</b>
          </div>

          <table
            className='table align-middle fs-6 gy-5 mt-3 pb-5'
            id='kt_ecommerce_sales_table'
            style={{borderBottom: '1.5px dashed lightgray'}}
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-50px'>#</th>
                <th className='min-w-150px'>Item</th>
                <th className='min-w-75px'>Qty</th>
                <th className='min-w-100px'>Rate</th>
                <th className='min-w-100px'>Vat</th>
                <th className='min-w-100px'>Amount</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}}>
              {invoice?.paymentSubItems.map((field: any, index: any) => {
                let y: any = ((field.amount * field.quantity) / 100) * field.VATPercentage
                let total: number = parseFloat(y) + field.amount * field.quantity
                return (
                  <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                    <td className='py-2'>{field?.id}</td>
                    <td className='py-2'>{field.name}</td>
                    <td className='py-2'>{field.quantity}</td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? (field.amount / field?.quantity).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : field.amount.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? ((field.amount / 100) * field.VATPercentage).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : (
                            ((field.amount * field.quantity) / 100) *
                            field.VATPercentage
                          ).toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                    <td className='py-2'>
                      {(field?.totalAmount).toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                  </tr>
                )
              })}

              <>
                {invoice?.paymentSubItems.map((f: any, ind: any) => {
                  vatAmount = 0
                  nonVat = 0
                  return <></>
                })}
              </>
              {invoice?.paymentSubItems?.length > 0 && (
                <>
                  {invoice?.paymentSubItems.map((f: any, ind: any) => {
                    if (f?.isLatePaymentFeesRecord) {
                      vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                      nonVat = nonVat + f.amount * f.quantity
                    }
                    return <></>
                  })}
                </>
              )}

              <tr style={{height: '55px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2'>
                  {nonVat.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>

              <tr style={{height: '30px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2'>
                  {vatAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>
              <tr style={{height: '55px'}} className='pb-4'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5'>Total</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>
                    AED {(nonVat + vatAmount).toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <div className='d-flex'>
            <div style={{width: '50%'}}>
              <div className='d-flex my-2 mb-5'>
                <div className=''>
                  <label className='mb-1 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Payment Method
                  </label>
                </div>
                <div className='text-center'>
                  <label
                    className='mb-1 me-1 fs-6 pt-1'
                    style={{minWidth: '160px', fontWeight: '500'}}
                  >
                    {invoice?.payment?.[0]?.paymentMethod == 0
                      ? 'Card'
                      : invoice?.payment?.[0]?.paymentMethod == 1
                      ? 'Cheque'
                      : invoice?.payment?.[0]?.paymentMethod == 2
                      ? 'Bank'
                      : 'Cash'}
                  </label>
                </div>
              </div>
              {invoice?.payment?.[0]?.paymentMethod == 2 && (
                <>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    <b> {selectedAccount?.accountHolderName}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    <b> {selectedAccount?.IBAN}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    <b> {selectedAccount?.accountNumber}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    <b> {selectedAccount?.bankName}</b>
                  </div>
                </>
              )}

              {invoice?.payment?.[0]?.paymentMethod == 1 && (
                <>
                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        paymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                                {/* <img
                                src={redCross}
                                width='12px'
                                height='12px'
                                className='main_img cursor-pointer'
                                style={{marginTop: '-12px'}}
                                onClick={() => deleteCheque(chq)}
                              /> */}
                              </a>
                            </div>
                            <p className='fs-7 ps-1'>{chq.name}</p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div style={{width: '50%'}}>
              {/* Notes */}
              {invoice?.note && (
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>
                  {invoice?.invoiceNo ? (
                    <p style={{marginBottom: '0.1rem'}}>{invoice?.note}</p>
                  ) : (
                    <textarea
                      className='form-control form-control-solid-bg input-style mytest'
                      name='comment'
                      placeholder='Enter any additional notes to the recipient'
                      style={{height: '130px', borderColor: 'lightgray'}}
                      value={invoiceNotes}
                      onChange={(e) => setInvoiceNotes(e.target.value)}
                      maxLength={1000}
                    ></textarea>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* View All Credit Notes */}
      <ViewAllCreditNotes
        show={viewAllCreditNotes}
        handleClose={() => {
          setViewAllCreditNotes(false)
        }}
        creditNotes={invoice?.payment_credit_note}
        paymentId={paymentId}
        tenantDetails={tenantDetails}
        from={from}
      />

      {/* View Single Credit Note */}
      {viewCreditNote && (
        <ViewCreditNote
          show={viewCreditNote}
          handleClose={() => {
            setViewCreditNote(false)
          }}
          creditNote={creditNoteToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={dueDate}
          from={from}
        />
      )}

      {/* View Single Debit Note */}
      {viewDebitNote && (
        <ViewDebitNote
          show={viewDebitNote}
          handleClose={() => {
            setViewDebitNote(false)
          }}
          debitNote={debitNoteToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={''}
          from={from}
        />
      )}

      {/* View All Credit Notes */}
      <ViewAllDebitNotes
        show={viewAllDebitNotes}
        handleClose={() => {
          setViewAllDebitNotes(false)
        }}
        debitNotes={invoice?.payment_debit_note}
        paymentId={paymentId}
        tenantDetails={tenantDetails}
        from={from}
      />
    </div>
  )
}

export default ViewInvoice

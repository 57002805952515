import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiUpload,
  ApiPut,
  ApiDelete,
  ApiGetNoAuth,
} from '../../../apiCommon/helpers/API/ApiData'
import img from '../../../img/300-1.jpg'
import folderDocument from '../../../img/folder-document.svg'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import backArrow from '../../../img/back-arrow.png'
import {Modal, Button} from 'react-bootstrap'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import eye from '../../../img/eye-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../img/check-white.png'
import {Controller, useForm} from 'react-hook-form'
import upload from '../../../img/upload-blue.png'
import trashImg from '../../../img/trash.png'
import downloadImg from '../../../img/download-img.png'
import pencil from '../../../img/pencil-blue.png'
import axios from 'axios'

import './style.scss'

interface ButtonProps {
  show: any
  handleClose: any
  refetchTenants: any
  tenant: any
  tentFolder: any
  contFolder: any
  onSuccess: any
  from: any
  targetFolder: any
}

const SingleFileModal = ({
  show,
  handleClose,
  refetchTenants,
  tenant,
  tentFolder,
  contFolder,
  onSuccess,
  from,
  targetFolder,
}: ButtonProps) => {
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])
  const [tenantId, setTenantId] = React.useState<any>('')
  const [open, setOpen] = React.useState(false)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  const [viewFolderInd, setViewFolderInd] = useState<any>()
  const [isDel, setIsDel] = React.useState(false)
  const [newName, setNewName] = useState<any>()
  const [newNameInd, setNewNameInd] = useState<any>(0)
  const [extension, setExtension] = useState<any>()

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${tenant}`)
      .then((res) => {
        setTenantData(res?.data?.data)

        console.log('\nres?.data?.data', res?.data?.data)
        //  setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')

        let values: any = []
        values = [...tentFolder]

        let temp: any

        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          // For Tenant Dpcuments
          if (from == 'tenant' && res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
            let isInc: any = false
            for (let j = 0; j < tentFolder?.length; j++) {
              if (tentFolder?.[j]?._id == res?.data?.data?.tenant_folder?.[i]?._id) {
                isInc = true
                temp = j
                j = tentFolder?.length
              }
            }
            if (isInc) {
              // console.log(tentFolder?.[temp])
              for (let m = 0; m < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; m++) {
                let isAvailable: any = false
                if (tentFolder?.[temp]?.fileList?.length == 0) isAvailable = true

                for (let k = 0; k < tentFolder?.[temp]?.fileList?.length; k++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList[m]?._id ==
                    tentFolder?.[temp]?.fileList[k]?._id
                  ) {
                    isAvailable = true
                    k = tentFolder?.[temp]?.fileList?.length
                  }
                }

                if (isAvailable == false) {
                  tentFolder[temp].fileList[tentFolder[temp].fileList?.length] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileURL,
                    isChecked: false,
                  }
                }
              }
            } else {
              values[values?.length] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                } else {
                  // tentFold[t].isCheck = false,
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
              }
            }
          }

          //  For Contract Documents
          if (
            from == 'contract' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
            res?.data?.data?.tenant_folder?.[i].name != 'IDs'
          ) {
            let isInc: any = false
            for (let j = 0; j < tentFolder?.length; j++) {
              if (tentFolder?.[j]?._id == res?.data?.data?.tenant_folder?.[i]?._id) {
                isInc = true
                temp = j
                j = tentFolder?.length
              }
            }
            if (isInc) {
              for (let m = 0; m < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; m++) {
                let isAvailable: any = false
                if (tentFolder?.[temp]?.fileList?.length == 0) isAvailable = true

                for (let k = 0; k < tentFolder?.[temp]?.fileList?.length; k++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList[m]?._id ==
                    tentFolder?.[temp]?.fileList[k]?._id
                  ) {
                    isAvailable = true
                    k = tentFolder?.[temp]?.fileList?.length
                  }
                }

                if (isAvailable == false) {
                  tentFolder[temp].fileList[tentFolder[temp].fileList?.length] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileURL,
                    isChecked: false,
                  }
                }
              }
            } else {
              values[values?.length] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                } else {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
              }
            }
          }
        }

        for (let i = 0; i < values?.length; i++) {
          if (values[i]._id == targetFolder?._id) {
            getFolderFiles(values[i])
            setViewFolderInd(i)
          }
        }
        setFolders(values)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getTenantData()
  }, [targetFolder, tentFolder])

  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(true)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()

  //
  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setViewFolder(true)

        for (let m = 0; m < res?.data?.data?.fileList?.length; m++) {
          let isAvailable: any = false
          if (folder?.fileList?.length == 0) isAvailable = true

          for (let k = 0; k < folder?.fileList?.length; k++) {
            if (res?.data?.data?.fileList[m]?._id == folder?.fileList[k]?._id) {
              isAvailable = true
              k = folder?.fileList?.length
            }
          }

          if (isAvailable == false) {
            folder.fileList[folder.fileList?.length] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
          if (folder.fileList?.length == 0 && res?.data?.data?.fileList?.length) {
            folder.fileList[0] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
        }

        setSelectedFolder(folder)

        if (res?.data?.data?.fileList?.length == 0) setFolderFiles([])
        else setFolderFiles(folder?.fileList)

        let values = [...folders]

        for (let x = 0; x < folders?.length; x++) {
          if (values[x]?._id == folder?._id) {
            values[x] = folder
            let isChk = false
            for (let y = 0; y < folder?.fileList?.length; y++) {
              if (folder?.fileList[y]?.isChecked == true) {
                isChk = true
              }
            }
            if (!isChk) values[x].isCheck = false
          }
        }

        setFolders(values)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }

        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  //
  const getFolderFilesAfterUpload = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setViewFolder(true)

        let temp: any = {...folder}
        let tempInd: any = 0

        for (let m = 0; m < res?.data?.data?.fileList?.length; m++) {
          let isAvailable: any = false
          if (folder?.fileList?.length == 0) isAvailable = true

          for (let k = 0; k < folder?.fileList?.length; k++) {
            if (res?.data?.data?.fileList[m]?._id == folder?.fileList[k]?._id) {
              isAvailable = true
              tempInd = k
              k = folder?.fileList?.length
            }
          }

          if (isAvailable == false) {
            temp.fileList[m] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          } else {
            temp.fileList[m] = {
              _id: folder.fileList?.[tempInd]?._id,
              fileName: folder.fileList?.[tempInd]?.fileName,
              fileURL: folder.fileList?.[tempInd]?.fileURL,
              isChecked: folder.fileList?.[tempInd]?.isChecked,
            }
          }
          if (folder.fileList?.length == 0 && res?.data?.data?.fileList?.length > 0) {
            temp.fileList[0] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
        }
        setSelectedFolder(temp)

        if (res?.data?.data?.fileList?.length == 0) setFolderFiles([])
        else setFolderFiles(folder?.fileList)

        let values = [...folders]

        for (let x = 0; x < folders?.length; x++) {
          if (values[x]?._id == temp?._id) {
            values[x] = temp
            let isChk = false
            for (let y = 0; y < folder?.fileList?.length; y++) {
              if (temp?.fileList[y]?.isChecked == true) {
                isChk = true
              }
            }
            if (!isChk) values[x].isCheck = false
          }
        }

        setFolders(values)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }

        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
      })
      .catch((err: any) => {})
  }

  //
  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  //
  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name == folderFiles[i]?.fileName) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              setOpen(true)

              // for (let i = 0; i < fileLists_single.length; i++) {
              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  let temp = [...folderFiles]
                  temp[folderFiles?.length] = {
                    fileName: event?.target?.files[m]?.name,
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                })
              // }
            } else {
              const userChoice = await confirmAction()
              console.log(userChoice)
              if (userChoice == 'keep') {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                // console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                // console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then(async (res) => {
                    // let temp = [...folderFiles]

                    var name = Date.now()
                    temp[temp?.length] = {
                      fileName: `${getFilename(filename, false)}-${name}.${getFilename(
                        filename,
                        true
                      )}`,
                      fileURL: res?.data?.data?.image,
                      fileSize: replaceFile.size,
                      createdAt: moment(new Date()).format('YYYY-MM-DD'),
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    await ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFilesAfterUpload(selectedFolder)
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('keep')
              else {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then((res) => {
                    // let temp = [...folderFiles]

                    for (let j = 0; j < folderFiles?.length; j++) {
                      if (filename == folderFiles[j]?.fileName) {
                        temp[j] = {
                          fileName: filename,
                          fileURL: res?.data?.data?.image,
                          fileSize: replaceFile.size,
                          createdAt: moment(new Date()).format('YYYY-MM-DD'),
                        }
                      }
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFilesAfterUpload(selectedFolder)
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('replace')
            }
            setOpen(false)
          }
          // }, 1000)
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
    }
    // delete file
    else {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) {
          if (temp[i].isChecked == true) temp[i].isChecked = false
          temp.splice(i, 1)
        }
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          setIsDel(true)
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
        })
        .catch((err: any) => {})
    }
  }

  //
  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  //
  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: `${newName}.${extension}`,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        // getFolderFiles(selectedFolder)
        setEditFile(-1)

        const values = [...folderFiles]
        values[newNameInd].fileName = `${newName}.${extension}`
        setFolderFiles(values)
      })
      .catch((err: any) => {})
  }

  // Replace OR Keep File
  const replaceKeepFile = async (val: any) => {
    const files = replaceFile
    // fileLists_single = Array.from(files)

    setOpen(true)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = files
      console.log(files)
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = files.type
      let filename: any = files.name
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = 'upload/image/tenant_folder'

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          let temp = [...folderFiles]
          if (val == 'replace') {
            for (let j = 0; j < folderFiles?.length; j++) {
              if (filename == folderFiles[j]?.fileName) {
                temp[j] = {
                  fileName: filename,
                  fileURL: res?.data?.data?.image,
                  fileSize: fileLists_single[i].size,
                  createdAt: moment(new Date()).format('YYYY-MM-DD'),
                }
              }
            }
          } else {
            temp[folderFiles?.length] = {
              fileName: `${getFilename(filename, false)}-copy.${getFilename(filename, true)}`,
              fileURL: res?.data?.data?.image,
              fileSize: fileLists_single[i].size,
              createdAt: moment(new Date()).format('YYYY-MM-DD'),
            }
          }

          const body = {
            id: selectedFolder?._id,
            name: selectedFolder?.name,
            fileList: temp,
          }
          ApiPut(`corporate/tenant_folder`, body)
            .then((res: any) => {
              getFolderFiles(selectedFolder)
              setOpen(false)
            })
            .catch((err: any) => {})
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], {type: response.headers['content-type']})

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'downloaded_image.png'

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={show}
        onHide={() => {
          handleClose(folders, isDel)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-3 px-lg-3  pt-lg-4'
          style={{
            transform: 'translate(0px, -70px)',
            maxHeight: '630px',
            overflow: 'auto',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row '>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-4 cursor-pointer'
                style={{fontWeight: '800'}}
                onClick={() => {
                  handleClose(folders, isDel)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='d-flex flex-column flex-column-fluid '>
            {/* <form onSubmit={handleSubmit((data: any, e: any) => addTenant(tenantData, e))}> */}
            <div id='kt_app_content' className='app-content flex-column-fluid py-0'>
              <div id='kt_app_content_container' className='container-xxl mt-1'>
                {/* <div className='container-xxl pt-0 mt-0 px-0 '>
                <div className='d-flex align-items-center'>
                  <h2 className='m-0  ms-2'>
                    <b>{selectedFolder?.name}</b>
                  </h2>
                  <div className='d-flex ms-auto'>
                    <button
                      className='btn btn-sm fw-bold select-btn'
                      type='submit'
                      // style={{
                      //   fontSize: '13px',
                      // }}
                      onClick={() => {
                        onSuccess(folders)
                        handleClose()
                      }}
                    >
                      <img src={addFolderImg} height={22} width={24} className='me-5' />
                      Add
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn'
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                      Cancel
                    </button>
                  </div>
                </div>
              </div> */}

                <div className='d-flex flex-column mt-5'>
                  <>
                    <div className='row g-6 g-xl-9 mb-6 mb-xl-12'>
                      <>
                        <div className='d-flex align-items-center'>
                          {/* <span
                            className='rounded-circle'
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              handleClose()
                              setEditFile(-1)
                            }}
                          >
                            <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
                          </span>*/}
                          <h2 className='m-0 ms-2'>
                            <b>{selectedFolder?.name}</b>
                          </h2>

                          <label
                            title='Add New File'
                            htmlFor='uploadPassport'
                            className='btn btn-sm fw-bold upload-btn ms-auto'
                          >
                            <img src={upload} height={18} width={18} className='me-3' />
                            Upload File{' '}
                          </label>
                          <input
                            type='file'
                            hidden
                            id='uploadPassport'
                            className='form-control form-control-solid'
                            placeholder='upload'
                            name='passport'
                            onChange={(e: any) => {
                              onFilesAddedSingle(e, 0, '')
                            }}
                            multiple
                          />
                        </div>
                        <div className='col-md-12 col-lg-12 col-xl-12 mt-2'>
                          <div className='h-100'>
                            <div className='d-flex justify-content-center flex-column py-4 px-2'>
                              {selectedFolder?.fileList?.length > 0 ? (
                                <>
                                  <div className='row py-3' style={{color: '#a1a5b7'}}>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-5 '>File</div>
                                    <div className='col-md-2 '>Date Added</div>
                                    <div className='col-md-4'></div>
                                  </div>

                                  {selectedFolder?.fileList?.map((file: any, ind: any) => {
                                    return (
                                      <div
                                        className='row py-3'
                                        style={{borderBottom: '1px solid lightgrey'}}
                                      >
                                        <div className='col-md-1 test'>
                                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 '>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              id='vacant01'
                                              name='none-1'
                                              checked={file?.isChecked ? true : false}
                                              // value={file.isChecked}
                                              onChange={() => {
                                                const values = {...selectedFolder}

                                                values.fileList[ind].isChecked =
                                                  !values.fileList[ind].isChecked

                                                let temp = [...folders]

                                                if (values.fileList[ind].isChecked)
                                                  temp[viewFolderInd].isCheck = true

                                                setSelectedFolder(values)
                                              }}
                                            />
                                          </div>
                                        </div>
                                        {editFile == ind ? (
                                          <>
                                            <div className='col-md-5 head-text pe-5'>
                                              <div className='d-flex'>
                                                <input
                                                  type='text'
                                                  className='form-control form-control-solid p-2'
                                                  value={newName}
                                                  onChange={(e: any) => {
                                                    let temp = getFilename(file?.fileName, true)
                                                    setNewName(e.target.value)

                                                    setExtension(temp)
                                                  }}
                                                />
                                                <a
                                                  className='btn btn-sm fw-bold btn-primary btn-green'
                                                  onClick={() => updateName()}
                                                >
                                                  <img src={check} height={18} width={18} />
                                                </a>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div className='col-md-5 head-text'>
                                            {getFilename(file?.fileName, false)?.length > 30
                                              ? `${file?.fileName?.slice(0, 30)}...${getFilename(
                                                  file?.fileName,
                                                  true
                                                )}`
                                              : file?.fileName}
                                          </div>
                                        )}
                                        <div className='col-md-2 head-text'>
                                          {moment(file?.createdAt).format('DD.MM.YYYY')}
                                        </div>
                                        <div className='col-md-4 head-text text-end'>
                                          <div className='d-flex justify-content-end'>
                                            {((user?.isSubUser &&
                                              role?.leasing_residential?.lease_agreements
                                                ?.tenant_detail?.create) ||
                                              !user?.isSubUser ||
                                              (user?.isSubUser &&
                                                role?.leasing_residential?.lease_agreements
                                                  ?.tenant_detail?.update) ||
                                              !user?.isSubUser) && (
                                              <img
                                                src={eye}
                                                height={20}
                                                width={20}
                                                className='me-3 cursor-pointer'
                                                onClick={() => {
                                                  setIndex(ind)
                                                }}
                                              />
                                            )}
                                            <img
                                              src={downloadImg}
                                              height={22}
                                              width={22}
                                              className='me-10 cursor-pointer'
                                              onClick={() => {
                                                if (!isDisable)
                                                  handleDownload(Bucket + file?.fileURL)
                                              }}
                                              style={isDisable ? {opacity: '0.6'} : {opacity: '1'}}
                                            />
                                            <img
                                              src={pencil}
                                              height={17}
                                              width={17}
                                              className='me-3 cursor-pointer'
                                              onClick={() => {
                                                setNewName(getFilename(file?.fileName, false))
                                                setEditFile(ind)
                                                setNewNameInd(ind)
                                              }}
                                            />

                                            <img
                                              src={trashImg}
                                              height={18}
                                              width={18}
                                              className='me-5'
                                              style={{cursor: 'pointer'}}
                                              onClick={(e) => {
                                                Swal.fire({
                                                  text: 'Are you sure you want to permanently delete this file?',
                                                  icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#D72852',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  // cancelButtonColor: "transparent",
                                                  cancelButtonText: 'Cancel',
                                                }).then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    onFilesAddedSingle(e, 1, file?._id)
                                                  }
                                                })
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <div className='text-center'> No Files</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/*  */}
      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default SingleFileModal

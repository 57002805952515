import React, {useState, useEffect} from 'react'
import {Modal, Row, Col} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import {ApiPost, ApiGet, ApiPut, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import Backdrop from '@mui/material/Backdrop'
import uploadFileIcon from '../../../../img/upload-file-icon.png'
import trashImg from '../../../../img/trash.png'
import SingleFileLoader from '../../General/SingleFileLoader'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'

interface AddAttachmentsProps {
  show: boolean
  handleClose: any
  mode?: any
  updateData?: any
  selectedItem?: any
}

const AddAttachments: React.FC<AddAttachmentsProps> = ({
  show,
  handleClose,
  mode,
  updateData,
  selectedItem,
}) => {
  const [isLoading, setIsLoading] = useState<any>(false)
  const [open, setOpen] = useState<any>(false)

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [attachementData, setAttachementData] = useState<any>(
    mode == 'add'
      ? {
          name: '',
          URL: '',
        }
      : selectedItem
  )

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  //
  const handleUploadInvoice = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      // if (e.target.files[0]?.type === 'application/pdf') {
      setShowLoader(true)
      var formdata = new FormData()
      formdata.append('image', e.target.files[0])

      let userData: any = localStorage.getItem('userData')
      const data = JSON.parse(userData)

      let name: any = e.target.files[0]?.name

      await ApiPost(
        `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
        formdata
      )
        .then((res) => {
          let values = {...attachementData}
          const name = res?.data?.data?.image.split('/');
          const fileName = name[name.length - 1];
          // const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));

          values ={
            URL: res?.data?.data?.image,
            name: (fileName?.charAt(0).toUpperCase() + fileName?.slice(1)) 
          }

          setAttachementData(values)
          setShowLoader(false)
          e.target.value = null;
        })
        .catch((err) => {
          setShowLoader(false)
          ErrorToast(err?.message)
          e.target.value = null;
        })
      // } else {
      //   ErrorToast('Files other than PDF are not allowed..')
      // }
    }
  }

  useEffect(() => {
    if (mode == 'add')
      setAttachementData({
        name: '',
        URL: '',
      })
    setAttachementData(selectedItem)
  }, [mode])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-9'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              // minHeight: '450px',
            }}
          >
            <div className='d-flex align-items-start'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                {mode == 'add' ? 'Add Attachment' : 'Edit Attachment'}
              </h2>{' '}
              <div className='d-flex ms-auto align-items-center'>
                <label
                  className='head-text fs-3 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div className='d-flex justify-content-center xyz mb-3 mt-8'>
              <input
                //   {...register('documentName', {required: true})}
                type='text'
                className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                name='documentName'
                value={attachementData?.name}
                style={{height: '35px', width: '300px'}}
                placeholder={'Attachment Name'}
                onChange={(e: any) => {
                  let values = {...attachementData}
                  let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                  values.name = newValue
                  setAttachementData(values)
                }}
                autoComplete="off"
              />
            </div>

            <div className='d-flex align-items-start justify-content-center mt-5'>
              {attachementData?.URL?.trim()?.length == 0 ? (
                <form
                  id='form-file-upload-po'
                  onDragEnter={handleDrag}
                  onSubmit={(e) => e.preventDefault()}
                  style={{height: '110px'}}
                >
                  <label
                    id='form-file-upload-po'
                    htmlFor='form-file-upload-poo'
                    className={
                      dragActive
                        ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                        : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                    }
                    style={{
                      fontSize: '16px',
                      color: 'white',
                    }}
                    ref={inputRef}
                  >
                    <div
                      className={`d-flex justify-content-center px-20 align-items-center ${
                        isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                      }`}
                    >
                      <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                      <div
                        className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                        style={{minWidth: '90px'}}
                      >
                        <b>Drag & drop your file </b> or{' '}
                      </div>
                      <div
                        className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                        style={{minWidth: '90px'}}
                      >
                        <div
                          className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                          style={{}}
                        >
                          Browse Files
                        </div>
                      </div>
                    </div>
                  </label>
                  <input
                    type='file'
                    // multiple={true}
                    onChange={(e: any) => {
                      handleUploadInvoice(e)
                    }}
                    id='form-file-upload-po'
                    ref={inputRef}
                    className='file-input-drag cursor-pointer'
                    // accept='application/pdf'
                  />
                  {dragActive && (
                    <div
                      id='drag-file-element'
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              ) : (
                <div className='d-flex'>
                  <img
                    // src={`${Bucket}${attachementData?.URL}`}
                    src={
                     attachementData?.URL?.split('.')[1] === 'pdf'
                        ? pdfDocument
                        : attachementData?.URL?.split('.').pop() === 'doc' ||
                         attachementData?.URL?.split('.').pop() === 'docx'
                        ? docCocument
                        : attachementData?.URL?.split('.').pop() === 'jpeg' ||
                         attachementData?.URL?.split('.').pop() === 'jpg' ||
                         attachementData?.URL?.split('.').pop() === 'png' ||
                         attachementData?.URL?.split('.').pop() === 'svg' ||
                         attachementData?.URL?.split('.').pop() === 'gif'
                        ? `${Bucket}${attachementData?.URL}`
                        : attachementData?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                         attachementData?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                        ? videoImg
                        : fileIcon
                    }
                    height={150}
                    width={120}
                    className='shadow'
                  />
                  <img
                    src={trashImg}
                    height={16}
                    width={16}
                    onClick={() => {
                      let values = {...attachementData}
                      values.URL = ''
                      setAttachementData(values)
                    }}
                    className='ms-2 cursor-pointer'
                  />{' '}
                </div>
              )}
            </div>

            <div className='d-flex align-items-center justify-content-center my-5 mt-3'>
              <button
                type='button'
                className='btn btn-sm fw-bold  green-submit-btn ps-3 status-w-230 d-flex justify-content-center mt-10 mb-5'
                onClick={() => {
                  updateData(attachementData)
                  setAttachementData({
                    name: '',
                    URL: '',
                  })
                }}
                disabled={attachementData?.name?.trim()?.length == 0 ||  attachementData?.URL?.trim()?.length == 0}
              >
                {/* <img src={plusWhite} height={18} width={18} className='me-4' />  */}
                {mode == 'add' ? 'Add' : 'Update'}
              </button>
            </div>
          </div>
        </Row>
      </Modal>

      <SingleFileLoader isVisible={showLoader} />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>
    </div>
  )
}

export default AddAttachments

// dateStatusUtils.js
import moment from 'moment';

export const calculateDateStatuses = (rawDates:any) => {
  return rawDates?.map((rawDate:any) => {
    const date = moment(rawDate.date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ');
    let status: any

    if (rawDate.status == null) {
      status =  'draft'
    } else if (rawDate.status  == 0) {
      status =   'booked'
    } else if (rawDate.status  == 1) {
      status =   'active'
    } else if (rawDate.status  == 2) {
      status =   'expiring'
    } else if (rawDate.status  == 3) {
      status =   'expired'
    } else {
      status =  null // No special status
    }

    return { date, status };
  });
};